import onboardingLocalization from '../components/Onboarding/localization/es-ES';
import ticketingLocalization from '../components/Ticketing/localization/es-ES';
import supportLocalization from '../components/Support/localization/es-ES';
import automationLocalization from '../components/Automation/localization/es-ES';
import sharedLocalization from '../components/Shared/localization/es-ES';
import analyticsLocalization from '../components/AnalyticsInvoices/localization/es-ES';
import failoverLocalization from '../components/Failover/localization/es-ES';
import reportsLocalization from '../components/Reports/localization/es-ES';
import eulaLocalization from '../components/Eula/localization/es-ES';
import accountOverviewLocalization from '../components/AccountOverview/localization/es-ES';
import analyticsChartsLocalization from '../components/Analytics/localization/es-ES';
import batchHistoryLocalization from '../components/BatchHistory/localization/es-ES';
import errorLocalization from '../components/ErrorPage/localization/es-ES';
import userFeedbackLocalization from '../components/UserFeedback/localization/es-ES';
import swaggerLocalization from '../components/Swagger/localization/es-ES';
import securityFeatures from '../components/SecurityFeatures/localization/es-ES';
import releaseNotesLocalization from '../components/ReleaseNotes/localization/es-ES';
import endpointsLocalization from '../components/Endpoint/localization/es-ES';
import singleEndpointLocalization from '../components/SingleEndpoint/localization/es-ES';

export default {
	...automationLocalization,
	...sharedLocalization,
	...analyticsLocalization,
	...supportLocalization,
	...onboardingLocalization,
	...ticketingLocalization,
	...failoverLocalization,
	...reportsLocalization,
	...accountOverviewLocalization,
	...eulaLocalization,
	...analyticsChartsLocalization,
	...batchHistoryLocalization,
	...errorLocalization,
	...userFeedbackLocalization,
	...swaggerLocalization,
	...securityFeatures,
	...endpointsLocalization,
	...releaseNotesLocalization,
	...singleEndpointLocalization,
	// GLOBAL SETTINGS
	DISPLAY_DATE_FORMAT: 'MM/dd/aaaa',

	// SIDEBAR
	HOME: 'Inicio',
	ENDPOINTS: 'Terminales',
	Endpoints: 'Terminales',
	REPORTS: 'Informes',
	INVOICES: 'Facturas',
	AUTOMATION: 'Automatización',
	PLATFORM_STATUS: 'Estado de plataforma',
	SUPPORT: 'Asistencia',
	COLLAPSE_SIDEBAR: 'Contraer barra lateral',
	COMPANIES: 'Empresas',
	FAQ: 'Preguntas frecuentes',
	HELP_SUPPORT: 'Ayuda y asistencia',
	BATCH_UPDATES: 'Actualizaciones de lote',
	FAQS: 'Preguntas frecuentes',
	RELEASE_NOTES: 'Notas de la versión',
	SYSTEM_NOTIFICATIONS: 'Notificaciones del sistema',
	ACCOUNT_SETTINGS: 'Parámetros de cuenta',
	COMPANY_ACCOUNTS: 'Cuenta de la empresa',
	CUSTOM_FIELDS: 'Campos personalizados',
	COMPANY_DETAILS: 'Detalles de la empresa',
	TESTING_ICON: 'Icono de prueba',
	ENTITIES: 'Entidades',
	IOTGW_FAILOVER: 'Conmutación por error de pasarela IoT',
	ACCESS_MANAGEMENT: 'Gestión de acceso',
	SIM_ORDERS: 'Pedidos de SIM',
	USER_MANAGEMENT: 'Gestión de usuario',

	// TOPBAR
	MORE_IOT: 'MÁS IoT',
	MORE_ATT: 'MÁS AT&T',
	LEARN_ABOUT_OFFERS: 'Info. de ofertas',
	BUY_DEVICES: 'Comprar equipos',
	ATT_SERVICES: 'Servicios de AT&T',
	USE_TOOLS_APPS: 'Usar herramientas y Apps',
	IOT_PLATFORMS_PRODUCTS_APPS_AND_APIS: 'Plataformas IoT, productos, Apps y API',
	AMOC: 'AMOC',
	M2X: 'M2X',
	FLOW_DESIGNER: 'Diseñador de flujo',
	IOT_STARTER_KITS: 'Kits básicos de IoT',
	IOT_DATA_PLANS: 'Planes de datos IoT',
	ENTERPRISE: 'Empresa',
	BUSINESS: 'Comercial',
	CONSUMER: 'Consumidor',

	// PROFILE MENU
	PROFILE: 'Perfil',
	APPS: 'Apps',
	LOGOUT: 'Cerrar sesión',
	LOG_IN: 'INICIAR SESIÓN',

	// FOOTER
	IOT_CUSTOMER_SOLUTIONS: 'Soluciones IoT para el cliente',
	IOT_PLATFORM_AND_SERVICES: 'Plataforma y servicios IoT',
	NETWORK_SOLUTIONS: 'Soluciones de red',
	PARTNERSHIP_PROGRAM: 'Programa de colaboración',
	BUSINESS_SUPPORT: 'Asistencia comercial',
	ABOUT_ATT: 'Acerca de AT&T',
	ABOUT_ATT_IOT: 'Acerca de IoT de AT&T',
	NEWS: 'Novedades',
	NETWORKING_EXCHANGE_BLOG: 'Blog de red de contactos',
	BUSINESS_COMMUNITY: 'Comunidad comercial',
	YOUTUBE_CHANNEL: 'Canal de YouTube',
	LEGAL_PRIVACY_ADVERTISING_ACCESSIBILITY: 'Legal, privacidad, publicidad, accesibilidad',
	LEGAL_POLICY_CENTER: 'Centro de normativa legal',
	PRIVACY_POLICY: 'Norma de privacidad',
	TERMS_OF_USE: 'Términos de uso',
	BROADBAND_DETAILS: 'Detalles de banda ancha',
	YOUR_CHOICES: 'Sus elecciones',
	ADVERTISING_CHOICES: 'Elecciones de publicidad',
	ACCESSIBILITY: 'Accesibilidad',
	COPYRIGHT_ATT_INTELECTUAL_PROPERTY: '©{year} AT&T Intellectual Property.',
	COPYRIGHT_MESSAGE: 'Todos los derechos reservados. AT&T, el logotipo del globo, Mobilizing Your World y DIRECTV son marcas comerciales registradas de AT&T Intellectual Property y/o de sus empresas afiliadas. Todas las otras marcas son propiedad de sus respectivos dueños.',
	CONNECT_WITH_ATT: 'Conéctese con AT&T',
	CURRENT_SESSION_USAGE: 'Uso de sesión actual',
	ENDPOINT_NETWORK_AND_LOCATION: 'Red y ubicación de terminal',

	// 'PERMISOS DE RED',
	ENDPOINT_DETAILS_SETUP: 'Configuración de detalles de terminal',
	NETWORK_ENTITLEMENT_DETAILS: 'Detalles de permiso de red',
	CHANGE_NETWORK_ENTITLEMENTS: 'Cambiar permisos de red',
	CONFIRM_NETWORK_ENTITLEMENT_CHANGE: 'Confirmar cambio de permiso de red',
	NETWORK_ENTITLEMENT_CHANGE_STATUS: 'Confirmar estado de permiso de red',
	NETWORK_ENTITLEMENT_CHANGE_TOOLTIP_SUPPORT: 'Error en permiso de red, comuníquese con Atención al cliente.',
	NETWORK_ENTITLEMENT_CHANGE_NOT_ALLOWED: 'Lamentablemente no se permite el cambio múltiple de permiso de red de terminal para las terminales de tipos de red diferentes o proveedores de servicio diferentes.',
	SCHEDULE_NETWORK_ENTITLEMENT_SUCCESSFUL: 'Cambio de permiso de red para múltiples SIM fijado con éxito.',
	SIMS_NETWORK_ENTITLEMENT_SUCCESSFULLY_UPDATED: 'Cambio de permiso de red de SIM actualizado con éxito',
	SIM_NETWORK_ENTITLEMENT_CHANGE_FOR: 'Cambio de permiso de red de SIM para ',
	MULTIPLE_NETWORK_ENTITLEMENT_CHANGE_SCHEDULE_ERROR: 'El cambio de permiso de red de SIM múltiples no se pudo fijar con éxito',
	MULTIPLE_NETWORK_ENTITLEMENT_CHANGE_ERROR: 'El cambio de permiso de red de SIM múltiples no se pudo solicitar con éxito',
	states: 'Estado',
	networkOperators: 'Operador de red',
	countries: 'País de red',
	SELECT_NETWORK_ENTITLEMENT: 'Seleccionar permiso de red',
	ACCOUNT_NAME: 'Nombre de cuenta',
	APN: 'APN',
	DESCRIPTION: 'Descripción',
	ROAMING: 'Roaming',
	CREATE_DATE: 'Fecha de creación',
	LAST_UPDATE_DATE: 'Fecha de última actualización',
	APN_LTE: 'APN EN LTE',
	SMS_MT: 'SMS MT',
	SMS_MO: 'SMS MO',
	VOICE_MO: 'Voz MO',
	VOICE_MT: 'Voz MT',
	INTERNATIONAL_VOICE: 'Voz internacional',
	FIXED_IP_ENABLED: 'IP fijo habilitado',
	LTE_DATA: 'Datos en LTE',
	LTE_ROAMING_RESTRICT_SET_ID: 'Roaming en LTE',
	LTE_APNS: 'APN en LTE',
	CAMEL_SUBSCRIPTION_INFO: 'Camel',
	CAMEL_SERVICE_TYPE: 'Tipo de servicio Camel',
	M2M_PLATFORM: 'Plataforma M2M',
	APNS_ID: 'ID DE APN',
	APNS_NAME: 'Nombre',
	APNS_LTE: 'LTE',
	APNS_PDPINDEX: 'Índice PDP',
	APNS_DESCRIPTION: 'Descripción de APN',
	APNS_FIXEDIP: 'IP fijo',
	APNS_SECURITY: 'Seguridad',
	APNS_SUBSCRIPTION: 'Suscripción',
	APNS_CREATEDATE: 'Fecha de creación',
	APNS_LASTUPDATEDATE: 'Fecha de última actualización',
	APNS_LASTUSER: 'Último usuario',
	APNS_APNTYPE: 'Tipo de APN',
	APNS_CREATIONTIMESTAMP: 'Sello temporal de creación',
	APNS_BMSYNCSTATUS: 'Estado de Sinc. de BM',
	APNS_HLRPROFILEREFERENCE: 'Referencia del perfil HLRHSSDetails',
	APNS_IPADDRESSALLOCATIONTYPE: 'Tipo de asignación de dirección de IP',
	APNS_ISLOCALBREAKOUTENABLEDINDICATOR: 'Indicador de Interruptor local activado',
	APNS_LASTUPDATETIMESTAMP: 'Sello temporal de última actualización',
	APNS_OUCCONNECTIONTYPE: 'Tipo de Conexión OUC',
	APNS_SECURITYPROFILESUPPORTEDINDICATOR: 'Indicador de Perfil de seguridad soportado',
	APNS_STATUS: 'Estado',
	APNS_VPNENABLEDINDICATOR: 'Indicador de VPN activado',
	CURRENTLY_ASSIGNED_TO_THIS_ENDPOINT: 'Actualmente asignado a esta terminal',
	CURRENTLY_ASSIGNED_TO_SELECTED_ENDPOINTS: 'Actualmente asignado a determinadas terminales',
	ELIGIBLE_TO_USE_WITH_SELECTED_ENDPOINTS: 'Elegible para usar con determinadas terminales',
	ELIGIBLE_TO_USE_WITH_THIS_ENDPOINT: 'Elegible para usar con esta terminal',
	ELIGIBLE_NETWORK_ENTITLEMENTS: 'Permisos de red elegibles',
	NETWORK_ENTITLEMENT_CHANGE_IS_SUCCESSFULLY_SUBMITTED: 'Cambio de permiso de red enviado con éxito.',
	NETWORK_ENTITLEMENT_CHANGE_COULD_NOT_BE_CARRIED_OUT_SUCCESSFULLY: 'El cambio de permiso de red no se pudo hacer en este momento.',
	NETWORK_ENTITLEMENT_CHANGE_IS_SUCCESSFULLY_SCHEDULED: 'Cambio de permiso de red fijado con éxito.',
	NETWORK_ENTITLEMENT_CHANGE_IS_SUCCESSFULLY_SCHEDULED_FOR: 'Cambio de permiso de red fijado con éxito para ',
	NETWORK_ENTITLEMENT_CHANGE_COULD_fNOT_BE_SCHEDULED_SUCCESSFULLY: 'El cambio de permiso de red no se pudo fijar en este momento.',
	ONCE_YOU_INITIATE_THIS_CHANGE: 'Cuando inicie este cambio, el permiso de red de la terminal seleccionada será ',
	WOULD_YOU_LIKE_TO_PROCEED_WITH_THIS_ACTION: '¿Desea continuar con esta acción?',
	OR_YOU_CAN_SCHEDULE_THE_CHANGE_FOR: 'O puede fijar el cambio para',
	FRIENDLY_NAME: 'Nombre descriptivo',
	NAME: 'Nombre',
	// 'Detalles de permiso de red',
	APN_DETAILS_ID: 'ID',
	APN_DETAILS_NAME: 'Nombre',
	APN_DETAILS_LTE: 'LTE',
	APN_DETAILS_PDPINDEX: 'Índice PDP',
	APN_DETAILS_DESCRIPTION: 'Descripción',
	APN_DETAILS_FIXEDIP: 'IP fijo',
	APN_DETAILS_SECURITY: 'Seguridad',
	APN_DETAILS_SUBSCRIPTION: 'Suscripción',

	// BUTTON LABELS
	CANCEL: 'Cancelar',
	CHANGE: 'Cambiar',
	TROUBLESHOOT: 'Solucionar problemas',
	CHANGE_STATUS: 'Cambiar estado',
	CLOSE: 'Cerrar',
	APPLY_CHANGE: 'Aplicar cambio',
	DONE: 'Listo',
	SAVE: 'Guardar',
	CONTINUE: 'Continuar',
	SAVEDASHBOARD: 'Guardar panel de control',
	SAVE_AND_CONTINUE: 'Guardar y continuar',
	SAVE_CHANGES: 'Guardar cambios',
	PULL_DATA: 'Recoger datos',
	I_DO_NOT_AGREE: 'No acepto',
	I_AGREE: 'Acepto',
	LOADING: 'Cargando',
	UPLOAD_LOGO: 'Cargar logo',
	ADD_NEW_CUSTOMER: 'Agregar cliente nuevo',
	EDIT: 'Editar',
	DELETE: 'Borrar',
	NEXT: 'Siguiente',

	// NOTIFICATIONS
	ENDPOINT_ID: 'ID de terminal',
	PROVISIONING_ERROR: 'Error de suministro',
	FAILED_TO_PROVISION_AFTER_IMSI_STATUS_CHANGE: ' fallo al suministrar después del cambio de estado IMSI. ',
	GET_SUPPORT: 'Obtener asistencia',
	PROVISIONING_SUCCESS: 'Suministro exitoso',
	IS_SUCCESSFULLY_PROVISIONED: ' se suministró con éxito.',
	IMSI_STATUS_IS_CHANGED_TO: 'El estado de IMSI cambió a ',
	PENDING_STATUS_CHANGE: '¡Cambio pendiente!',
	STATUS_CHANGE_FOR: 'Hay una actividad fijada para esta terminal',
	IS_PENDING: ' está pendiente',
	CUSTOM_FIELD_EDIT_SUCCESS: 'Campo personalizado editado con éxito',
	CUSTOM_FIELD_EDIT_ERROR: 'Error al editar campo personalizado',
	CUSTOM_FIELD_VALUES_UPDATED: 'Uno o más campos personalizados se actualizaron con éxito',
	CUSTOM_FIELD_VALUES_UPDATED_ERROR: 'Uno o más campos personalizados no se actualizaron en este momento. Inténtelo nuevamente.',
	CUSTOM_FIELD_LABEL_SUCCESS_TITLE: '¡Etiqueta de campo personalizado editada con éxito',
	CUSTOM_FIELD_LABEL_SUCCESS_MESSAGE: 'Una o más etiquetas de campo personalizado se actualizaron con éxito.',
	CUSTOM_FIELD_LABEL_ERROR_TITLE: '¡Error al editar etiqueta de campo personalizado!',
	CUSTOM_FIELD_LABEL_ERROR_MESSAGE: 'Una o más etiquetas de campo personalizado no se actualizaron en este momento. Inténtelo nuevamente.',
	TABLE_COLUMNS_CHANGE_SUCCESS_TITLE: 'iLos parámetros de tabla de terminal se cambiaron con éxito!',
	TABLE_COLUMNS_CHANGE_SUCCESS_MESSAGE: 'Los parámetros de tabla de terminal se actualizaron con éxito.',
	TABLE_COLUMNS_CHANGE_ERROR_TITLE: 'iError al cambiar las columnas de tabla de terminal!',
	TABLE_COLUMNS_CHANGE_ERROR_MESSAGE: 'Las columnas de tabla de terminal no se pudieron actualizar esta vez. Inténtelo nuevamente.',
	MC10515: 'Nombre de archivo incorrecto',
	MC10516: 'Error de datos de archivo',
	MC10517: 'El archivo no incluye encabezado o está vacío',
	DOWNLOAD_REPORT_ERROR_TITLE: 'Error de descarga',
	DOWNLOAD_REPORT_ERROR_MESSAGE: 'Error al tratar de descargar su archivo',
	FETCH_REPORT_ERROR_TITLE: 'No hay datos',
	FETCH_REPORT_ERROR_MESSAGE: 'No hay datos que coincidan con la solicitud de búsqueda/filtro',
	MARK_READ: 'Marcar como leído',
	VALIDATION_FAILED: 'Fallo de verificación',
	ACTION_COMPLETED_SUCCESSFULLY: 'Acción completada con éxito',
	ACTION_COULD_NOT_BE_COMPLETED: 'No se pudo completar esta acción',

	// SINGLE ENDPOINT
	ENDPOINT_DETAILS: 'Detalles de terminal',
	DETAILS: 'DETALLES',
	DIAGNOSTIC: 'Diagnóstico',
	CHANGES: 'Cambios',
	SESSIONS: 'Sesiones',
	END_USER: 'Usuario final',
	LIFE_CYCLE: 'Vida útil',
	SEND_SMS: 'Enviar SMS',
	OTA: 'OTA',
	RATING: 'Clasificación',
	CARRIER_INFORMATION: 'Información de operador',
	M2M_PLATFORM_STATUS_DESCRIPTION: 'Descripción de estado de plataforma M2M',
	VIEW_NOTE: 'Ver nota',
	SE_NOTES: 'Notas',
	CHANGE_NOTES: 'Cambiar notas',
	ENTER_NOTE_HERE: 'Ingresar nota aquí (máx. 2000 caracteres)',
	ADD_NOTE_BELOW: 'Agregar nota abajo: ',
	CHANGING_NOTES: 'Cambiando notas',
	MULTIPLE_NOTES_CHANGE_ERROR: 'No se pudo iniciar con éxito el cambio de notas de varias terminales',
	CHANGING_NOTES_NOT_ALLOWED: 'No se permite cambiar notas',
	ENDPOINTS_SUCCESSFULLY_CHANGED: 'Terminales cambiadas con éxito',
	ENDPOINT_DETAILS_SECOND_CARD_NO_DATA: 'Los datos de esta terminal no están disponibles temporalmente',

	// DATA OVERVIEW
	ICCID: 'ICCID',
	IMSI: 'IMSI',
	NETWORK_OPERATOR: 'Operador de red',
	NETWORK_COUNTRY: 'País de red',
	MSISDN: 'MSISDN',
	DUAL_MODE: 'Modo dual',
	CONNECTED_DEVICE: 'Equipo conectado',
	IMEI: 'IMEI',
	STATUS: 'Estado',
	STATUS_CHANGE_PENDING: 'Hay una actividad fijada para esta terminal',
	RATE_PLAN: 'Plan de tarifas',
	NETWORK_ENTITLEMENT: 'Permiso de red',
	SOURCE_PLATFORM: 'Plataforma M2M',
	SERVICE_PROVIDER: 'Plataforma M2M',
	NETWORK_ENTITLEMENT_ID: 'ID de permiso de red',

	// 'ESTADO DE TERMINAL',
	CURRENT_ENDPOINT_STATUS: 'Estado de terminal actual',

	// Cambiar modal de parámetro de SIM
	CHANGE_SIM_PARAMETER: 'Cambiar parámetro de SIM',
	SELECT_ATT_STATE: 'SELECCIONAR ESTADO DE AT&T',
	CURRENT: 'ACTUAL',

	BY: 'Por ',
	SCHEDULING: 'Fijando...',

	// EDIT TABLE MODAL
	EDIT_TABLE_PARAMETERS: 'Editar parámetros de tabla',
	EDIT_CUSTOM_FIELD_LABELS: 'Editar etiquetas de campo personalizado',
	CUSTOM_LABEL: 'Etiqueta personalizada',
	VALUE: 'Valor',
	SET_AS_REQUIRED: 'Configurar como obligatorio',
	SELECT_A_MINIMUM_OF_4_TO_APPLY_TO_THE_TABLE: 'Seleccionar un mín. de 2 para aplicar a la tabla. ',
	SELECTED: ' seleccionado',
	CLEAR_ALL: 'Restablecer a predeterminado',

	EOD: 'Enterprise on Demand (EOD)',
	ACC: 'Centro de control de AT&T (ACC)',
	GBCM: 'Speedcast',
	MC: 'IoT Console (IOT-C)',
	DCP: 'Plataforma de conexión de equipo (DCP)',
	CCIP: 'Programa de integración del centro de control (CCIP)',
	AIS: 'AIS',
	TWM: 'Taiwan Mobile (TWM)',
	ATT: 'AT&T',
	AUTOMATION_ACC: 'ACC',
	AUTOMATION_DCP: 'DCP',
	// 'AT&T', 'AT&T',
	WING: 'Nokia (WING)',
	VIVO: 'Centro de control Vivo',

	eod: 'Enterprise on Demand (EOD)',
	acc: 'Centro de control de AT&T (ACC)',
	gbcm: 'Speedcast',
	mc: 'IoT Console (IOT-C)',
	dcp: 'Plataforma de conexión de equipo (DCP)',
	ccip: 'Programa de integración del centro de control (CCIP)',
	ais: 'AIS',
	twm: 'Taiwan Mobile (TWM)',
	att: 'AT&T',
	// 'AT&T', 'AT&T',
	wing: 'Nokia (WING)',
	vivo: 'Centro de control Vivo',

	SELECTED_FILTERS: 'Filtros seleccionados',

	BY_WEEK: 'Por semana',
	BY_DAY: 'Por día',
	BY_HOUR: 'Por hora',
	BYTES_SENT: 'MB enviados',
	BYTES_RECEIVED: 'MB recibidos',
	TOTAL_MINS: 'Minutos totales',
	monthly: 'Mensual',
	daily: 'Diario',
	MONTHLY: 'Mensual',
	DAILY: 'Diario',

	// ACTION BAR
	SHOWING: 'Mostrando ',
	OF: ' de ',

	// 'INFORMES',
	TRANSFER: 'Transferencia',
	USAGE: 'Uso',
	DOWNLOAD: 'Descargar',
	OTHER: 'Otro',

	// INPUT ERRORS
	REQUIRED: 'Obligatorio',
	INVALID_COMPANY_NAME: 'Nombre de empresa inválido',
	INVALID_EMAIL_ADDRESS: 'Correo electrónico inválido',
	PHONE_MUST_BE_A_NUMBER: 'El teléfono debe ser un número',
	WRONG_PHONE_FORMAT: 'Formato de teléfono incorrecto',
	ONLY_LETTERS_AND_NUMBERS_ARE_ALLOWED: 'Solo se permiten letras y números',
	ONLY_LETTERS_ARE_ALLOWED: 'Solo se permiten letras',
	ONLY_LETTERS_AND_SPACES_ARE_ALLOWED: 'Solo se permiten letras y espacios',
	ONLY_NUMBERS_ARE_ALLOWED: 'Solo se permiten números',
	ONLY_POSITIVE_NUMBERS_ARE_ALLOWED: 'Solo se permiten números mayores a 0',
	NUMBER_HAS_TO_BE_LESS_THAN: 'El número debe ser inferior a {propName}',
	NUMBER_HAS_TO_BE_LESS_THAN_OR_EQUAL_TO: 'El número debe ser igual o inferior a {propName}',
	NUMBER_HAS_TO_BE_GREATER_THAN: 'El número debe ser superior a {propName}',
	NUMBER_HAS_TO_BE_GREATER_THAN_OR_EQUAL_TO: 'El número debe ser igual o superior a {propName}',
	NUMBER_HAS_TO_BE_IN_INCREMENTAL_STEPS: 'El número debe ser en incrementos de {increment}',
	INVALID_FORMAT: 'Formato inválido',
	MAX_CHARACTERS_EXCEEDED: 'Límite de caracteres superado',
	MUST_BE_LESS_THAN_OR_EQUAL_TO_CHARACTERS: 'Debe tener menos o tantos caracteres como {max} ',
	MUST_BE_CHARACTERS_LONG: 'Debe tener {number} caracteres',
	NO_ONLY_WHITE_SPACE: 'No puede tener solo espacios',
	OUT_OF_RANGE: 'Fuera de rango',
	NOT_BEFORE: 'La fecha no puede ser antes del {date}',
	INVALID_VERSION: 'Solo se permite el formato major.minor.patch, es decir, x.y.z',

	/* ------ OLD BUT STILL WORKING - DO NOT TOUCH - WORK IN PROGRESS --------- */
	ACCOUNT_OVERVIEW: 'Resumen de la cuenta',
	ACCOUNT_OVERVIEW_ADMIN_DASHBOARD: 'Panel de admin. del resumen de la cuenta',
	BILLING: 'Facturación',

	LOG_OUT: 'Cerrar sesión',

	DATA_PLAN: 'Plan de datos',
	ACTIVESESSION: 'SESIÓN ACTIVA',
	IPADDRESS: 'DIRECCIÓN DE IP',
	ANALYTICS: 'Analítica',
	NETWORK: 'RED',

	ASSET_ID: 'ID de recurso',
	SESSION_START: 'Comienzo de sesión',
	DATA_VOLUME: 'VOLUMEN DE DATOS',
	DURATION: 'Duración (min.)',
	'DATE&TIME': 'Fecha y hora',
	NOTE: 'Nota',
	LOGINTITLE: 'INICIAR SESIÓN',
	LOGINSUBTITLE: 'Ingrese su correo electrónico y contraseña.',
	ENDPOINTS_INVENTORY_STATUS: 'ESTADO DE INVENTARIO DE TERMINALES',
	INVENTORY_BY_CARRIER: 'INVENTARIO POR OPERADOR',
	INVENTORY_BY_COUNTRY: 'INVENTARIO POR PAÍS',
	ACCOUNT_USERS: 'USUARIOS DE CUENTA',
	USER_AUTOMATION_STATUS: 'ESTADO DE AUTOMATIZACIÓN DE USUARIO',
	AUTOMATION_PROCESS_STATUS: 'ESTADO DE PROCESO DE AUTOMATIZACIÓN',
	NETWORK_TYPE: 'Tipo de red',
	DEVICE_NAME: 'Nombre de equipo',
	DEVICE_ID: 'ID de equipo',
	DEVICE_MODEL_NUMBER: 'Núm. de modelo de equipo',
	ROAMING_RESTRICTED: 'ROAMING RESTRINGIDO',
	INVOICE_NUMBER: 'NÚMERO DE FACTURA',
	DATE: 'Fecha',
	SCHEDULE_DATE: 'Fecha',
	ISSUED_BY: 'EXPEDIDO POR',
	PAYMENT_STATUS: 'ESTADO DE PAGO',
	REPORT_NAME: 'Nombre del informe',
	DATE_CREATED: 'Fecha de creación',
	CREATED_BY: 'Creado por',
	PAY_NOW: 'PAGAR AHORA',
	EDIT_GRAPHS_VIEW: 'Editar vista de gráficos',
	EDIT_ENDPOINTS_LIST_VIEW: 'Editar vista de lista de terminales',
	CHANGE_LANGUAGE: 'Cambiar idioma',
	CHANGE_LANGUAGE_ICON: 'EN',
	NOT_APPLICABLE_FOR_THIS_ENDPOINT: 'No aplica a esta terminal',
	CREATE_NEW_RULE: 'Crear regla nueva',
	EDIT_RULE: 'Editar regla',
	CREATE_NEW_RULE_EXP: 'La automatización le permite realizar acciones masivas en su cuenta.',
	CREATE_NEW_RULE_ACTION: 'Seleccione el botón "Crear regla nueva" para crear una nueva acción automatizada',
	RULE_NAME: 'Nombre de regla',
	CATEGORY: 'Categoría',
	TRIGGER: 'Activador',
	ACTION: 'ACCIÓN',
	BULK_ACTIONS: 'Acciones masivas',
	MULTI_ENDPOINT_ACTIONS: 'Acciones de terminales múltiples',
	SET_ENDPOINT_STATE: 'Fijar estado de terminal',
	CHANGE_DATA_PLAN: 'Cambiar plan de datos',
	SWAP_OPERATOR: 'Cambiar operador',
	MULTI_EDIT: 'Edición múltiple',
	SEARCH_FOR_RULES: 'Buscar reglas',
	SEARCH_FOR_INVOICES: 'Buscar facturas',
	SEARCH_FOR_REPORTS: 'Buscar informe por nombre',
	SEARCH_ENDPOINTS: 'Buscar terminales',
	PAYABLE_INVOICES: 'FACTURAS POR PAGAR',
	RECEIVED_PAYMENTS: 'PAGOS RECIBIDOS',
	PAYMENT_METHODS: 'MÉTODOS DE PAGO',
	BILLERS: 'FACTURADORES',
	TIME_PERIOD: 'Período',
	FROM_DATEPICKER: 'De',
	TO_DATEPICKER: 'A',
	ENDPOINT_UI_TOOLTIP: 'Número de identificación de terminal o equipo',
	OPERATOR_TOOLTIP: 'Operador a quien pertenece la terminal y el perfil',
	NETWORK_TYPE_TOOLTIP: 'Tecnología de conectividad del operador',
	COUNTRY_TOOLTIP: 'País donde se originó la terminal',
	ROAMING_RESTRICTED_TOOLTIP: 'Si la terminal tiene restricción de roaming',
	STATUS_TOOLTIP: 'Estado de terminal actual',
	INITIATE_RULE: 'INICIAR REGLA',
	NEW_AUTOMATION_RULE: 'NUEVA REGLA DE AUTOMATIZACIÓN',
	ENDPOINT_PROVISIONING: 'SUMINISTRO DE TERMINAL',
	ENDPOINT_PROFILE: 'PERFIL DE TERMINAL',
	ENDPOINT_SECURITY: 'SEGURIDAD DE TERMINAL',
	ENDPOINT_SUBSCRIPTION: 'SUSCRIPCIÓN DE TERMINAL',
	NEW_RULE: 'REGLA NUEVA',
	CONDITION: 'CONDICIÓN',
	ENDPOINT_RANGE: 'El rango de terminal es',
	RANGE_START: 'COMIENZO DE RANGO',
	RANGE_END: 'FINAL DE RANGO',
	ASSIGN_ENDPOINT: 'Asignar terminal a operador',
	OPERATOR_NAME: 'NOMBRE DEL OPERADOR',
	ACCOUNT: 'CUENTA',
	ADD_ACTION: 'AGREGAR ACCIÓN',
	RUN_IMMEDIATELY: 'EJECUTAR DE INMEDIATO',
	TIME: 'HORA',
	SELECT_DATE: 'SELECCIONAR FECHA',
	SELECT_TIME: 'SELECCIONAR HORA',
	EVENT_TRIGGER: 'ACTIVADOR DE EVENTO',
	EVENT_TYPE: 'TIPO DE EVENTO',
	EVENT_LOCATION: 'UBICACIÓN DE EVENTO',
	DATA_PLAN_NAME: 'NOMBRE DE PLAN DE DATOS',
	LOGIN_VALIDATION_WRONG_CREDENTIALS_HEADER: '¡Credenciales incorrectas!',
	LOGIN_VALIDATION_WRONG_CREDENTIALS_BODY: 'La contraseña no coincide con el correo electrónico provisto.',
	EMAIL: 'Correo electrónico',
	PASSWORD: 'contraseña',
	FORGOT_PASSWORD: '¿Olvidó la contraseña?',
	REMEMBER_ME: 'Recordar',
	LOGIN_BUTTON: 'INICIAR SESIÓN',
	BACK: 'Atrás',
	ENDPOINT_USAGE: 'Uso de terminal',
	COUNTRY_AND_OPERATOR: 'País y operador',
	PAYMENTS_HISTORY: 'Historial de pago',
	SPENDING_THIS_YEAR: 'Gastos este año',
	ENDPOINT_DATA: 'DATOS DE TERMINAL',
	CURRENT_SESSION: 'SESIÓN ACTUAL',
	VIEW_LESS: 'Ver menos',
	VIEW_MORE: 'Ver más',
	ENDPOINT_STATUS: 'Estado de terminal',
	ACTIVATION_DATE: 'Fecha de activación',
	PRIMARY_IMS: 'IMS principal',

	SERVICE_PROVIDER_MODE: 'MODO DE PROVEEDOR DE SERVICIO',
	SUPER_USER_MODE_MESSAGE: '{mode}: {companyFriendlyName}.',
	SUPER_USER_MODE_MOBILE: '{companyFriendlyName}',
	POLICY: 'Norma',
	COMMUNICATION_PLAN: 'Plan de comunicación',
	COPY_RULE: 'Copiar regla',
	SECURE_ENDPOINT_ID: 'ID de terminal segura',
	CUSTOMER: 'Cliente',
	INVENTORY_STATUS: 'ESTADO DE INVENTARIO',
	RESOLUTION_NOT_SUPPORTED_FIRST_LINE: 'La vista en pantalla pequeña está desactivada en la demo.',
	RESOLUTION_NOT_SUPPORTED_SECOND_LINE: 'Para mejores resultados, use navegador Desktop Chrome maximizado o en pantalla completa',
	CHECKING_MOVE_ELIGIBILITY: 'VERIFICACIÓN DE ELEGIBILIDAD PARA MOVER',
	CHANGE_OPERATOR: 'CAMBIAR OPERADOR',
	SCHEDULE_CHANGE: 'FIJAR CAMBIO',
	SCHEDULE_CHANGE_BUTTON: 'FIJAR CAMBIO',
	INITIATE_CHANGE: 'Iniciar cambio',
	CONFIRM_CHANGE: 'Confirmar cambio',
	CONFIRM_SIM_STATE_CHANGE: 'Confirmar cambio de estado de SIM',
	IN_SESSION: 'EN SESIÓN',
	ENTITLEMENTS: 'Permisos',
	LOCALIZE_SERVICE: 'Localizar servicio',
	CHANGE_ENDPOINT_OPERATOR: 'CAMBIAR OPERADOR DE TERMINAL',
	PENDING_CHANGES_ENDPOINT: 'Hay algunos cambios pendientes para esta terminal, comenzando con',
	SEE_HISTORY_FOR_DETAILS: 'Ver historial abajo para detalles.',
	CHANGE_ENDPOINT_DATA_PLAN: 'Cambiar plan de datos de terminal',
	IS_SELECTED: ' está seleccionado.',
	PLEASE_SELECT_DIFFERENT_OPERATOR_TO_CHANGE_OPERATOR: 'Seleccione un operador diferente abajo para cambiarlo:',
	GENERATE_REPORT: 'GENERAR INFORME',
	REPORT_TYPE: 'TIPO DE INFORME',
	DATA_PLAN_SUMMARIES: 'Resúmenes de plan de datos',
	CHARGE_SUMMARIES: 'Resúmenes de cargos',
	INVOICE_REPORT: 'Informe de factura',
	PRORATION_REPORT: 'Informe de prorrateo',
	GROSS_ACTIVATIONS: 'Total de activaciones',
	MONTHLY_SUBSCRIBERS: 'Suscriptores mensuales',
	VIEW_YOUR_ACCOUT_SNAPSHOT: 'Ver instantánea de su cuenta.',
	LAUNCH_DASHBOARD: 'EJECUTAR PANEL DE CONTROL',
	VISUAL_DASHBOARD_VIEW_EXP: 'La vista del panel de control ofrece un vistazo del estado actual de su cuenta y actividad del equipo.',
	VIEW_CURRENT_STATUS_AND_HISTORICAL_DATA: 'Ver estado actual e historial de datos en línea de tiempo.',
	IF: 'SI',
	AND: 'Y',
	OR: 'O',
	TO: 'A',
	FROM: 'DE',
	INVOICES_STATUS: 'ESTADO DE FACTURAS',
	filter_category_m2mAccount: 'M2mAccount',
	PLEASE_CONFIRM_ENDPOINT_DATA_CHANGE: 'Confirmar cambio de datos de terminal.',
	SET_NEW_OPERATOR_AS_ACTIVE: 'FIJAR NUEVO OPERADOR COMO ACTIVO',
	SET_NEW_PLAN_AS_ACTIVE: 'FIJAR NUEVO PLAN COMO ACTIVO',
	PLEASE_SELECT_DATE_TO_SET_UP_YOUR_CHANGES_AND_CONFIRM: 'Seleccionar fecha para configurar sus cambios y confirmar.',
	DATE_REQUIRED: 'Fecha (obligatorio)',
	ADDITIONAL_COMMENT: 'Comentario adicional',
	TYPE_YOUR_CUSTOM_NOTES_HERE: 'Ingresar sus notas personalizadas aquí…',
	CONFIRM_CHANGES: 'Confirmar cambios',
	CHECKING_MOVE_ELIGIBILITY_LOADER: 'VERIFICACIÓN DE ELEGIBILIDAD PARA MOVER',
	ELIGIBLE: 'ELEGIBLE',
	CHECKING: 'VERIFICANDO',
	PLEASE_SELECT_DIFFERENT_DATA_PLAN_TO_CHANGE_DATA_PLAN: 'Seleccione un plan de datos diferente abajo para cambiarlo:',
	CONFIRM: 'Confirmar',
	ENDPOINT_RANGE_IS: 'El rango de terminal es',
	ENDPOINT_ID_IS: 'El ID de terminal es:',
	ENDPOINT_ID_IS_GREATER_THAN: 'El ID de terminal es superior a:',
	ENDPOINT_ID_IS_LESS_THAN: 'El ID de terminal es inferior a:',
	ENDPOINT_STATE_CHANGE: 'Cambio de estado de terminal',
	ENDPOINT_DATA_PLAN: 'Plan de datos de terminal',
	ENDPOINT_SECURITY_POLICY_CHANGE: 'Cambio de norma de seguridad de terminal',
	ASSIGN_ENDPOINT_TO_OPERATOR: 'Asignar terminal a operador',
	MOVE_TO_ANOTHER_ACCOUNT: 'Mover a otra cuenta',
	ADD_CUSTOM_FIELD_TO_ENDPOINT_DETAILS: 'Agregar campo personalizado a detalles de terminal',
	CREATE_NEW_ACTION: 'Crear acción nueva',
	AT_T: 'IoT de AT&T (ACC)',
	AT_T_EOD: 'IoT de AT&T (ACC)',
	GLOBECOMM: 'Speedcast',
	TAIWAN_MOBILE: 'Taiwan Mobile (TWM)',
	'0001': '***0001',
	'0002': '***0002',
	'0003': '***0003',
	'0004': '***0004',
	OTA_EVENT: 'Evento OTA',
	WI_FI: 'Wi-Fi',
	THAILAND: 'Tailandia (THA)',
	TAIWAN: 'Taiwán (TWN)',
	UNITED_STATES: 'Estados Unidos (USA)',
	DATA_PLAN_S: 'Plan de datos S',
	DATA_PLAN_M: 'Plan de datos M',
	DATA_PLAN_L: 'Plan de datos L',
	DATA_PLAN_XL: 'Plan de datos XL',
	// TO DO: Actualizar estados de terminales para corregir valores
	ACTIVATED: 'Activado',
	'TEST READY': 'Prueba lista',
	DEACTIVATED: 'Desactivado',
	PURGED: 'Depurado',
	RETIRED: 'Retirado',
	//
	USER: 'Usuario',
	ON: 'El',
	MADE: 'hecho',
	CHANGE_STATUS_ACTION: 'Cambiar estado',
	CHANGE_DATA_PLAN_ACTION: 'Cambiar plan de datos',
	LOCALIZE_SERVICE_ACTION: 'Servicio localizado',
	IMSI_TRANSFER: 'Transferencia de estado IMSI',
	TRANSFER_STATUS: 'Transferencia de estado IMSI',
	COUNTRY_OPERATOR: 'País de red',

	PROFILE_NAME: 'Nombre de perfil',
	NUMBER: 'Número',
	DATE_MODIFIED: 'Fecha de modificación',

	ENTITLEMENT_PLAN_ID: 'ID de plan de permiso',
	ENTITLEMENT_PLAN_NAME: 'Nombre de plan de permiso',
	ACCOUNT_ID: 'ID de cuenta',
	ACCOUNT_I_D: 'ID de cuenta',
	IMEI_NAME: 'Nombre IMEI',
	VIN: 'VIN',
	CHIP_ID: 'ID de chip',
	URL_SP: 'url SP',
	ADD_SINGLE_ENDPOINT: 'Agregar un solo terminal',
	UPLOAD_ICCID_BULK_FILE: 'Cargar archivo masivo ICCID',
	DOWNLOAD_BULK_FILE: 'Descargar archivo masivo',
	ENDPOINTS_SELECTED: 'terminales seleccionadas',
	SELECTED_ENDPOINTS: 'terminales seleccionadas',
	NEW_STATUS: 'Nuevo estado',
	MULTIPLE_ENDPOINT_EDIT: 'Edición de terminales múltiples',
	CLEAR_VALUES_FOR_ALL_ENDPOINTS: 'Eliminar valores de todas las terminales',
	KEEP_ORIGINAL_ENDPOINT_VALUES: 'Conservar valores de terminal originales',
	ACTIVE: 'Activado',
	INACTIVE: 'Inactivo',
	TERMINATE: 'Cancelado',
	TEST_READY: 'Prueba lista',
	TEST: 'Prueba',
	SELECT_OPTION_OR_TYPE_IN: 'Seleccionar opción o ingresarla',
	CLEAR_ALL_VALUES_FOR_ENDPOINTS: 'Eliminar todos los valores de terminales',
	BULK_STATUS_UPDATE: 'Actualización de estado masiva',
	TOTAL_ENDPOINTS: 'Terminales totales',
	TOTAL_FILES: 'Archivos totales',
	ENDPOINT_STATUS_UPDATED: 'Estado de terminal actualizado',
	MODIFIED_ENDPOINTS: 'Terminales modificadas',
	SOMETHING_WENT_WRONG: 'Algo falló',
	ENDPOINT_STATUS_IS_UPDATING: 'Actualizando estado de terminal',
	PLEASE_WAIT: 'Espere, por favor',
	SWAP_SIM: 'Cambiar SIM',
	SWAP_SIMS: 'Cambiar tarjetas SIM',
	SIM: 'SIM',
	NEW: 'Nuevo',
	OLD: 'Viejo',
	CHANGE_ORDER: 'Cambiar orden',
	ITEM: 'Elemento',
	ADDITIONAL_INFO: 'Info. adicional',
	UPDATE_LABEL: 'Actualizar etiqueta',
	ENDPOINT_LABEL_IS_UPDATING: 'Actualizando etiqueta de terminal',
	ENDPOINT_LABEL_UPDATED: 'Etiqueta de terminal actualizada',
	ENDPOINT_FIELDS_ARE_UPDATING: 'Actualizando campos de terminal',
	ENDPOINT_FIELDS_UPDATED: 'Campos de terminal actualizados',
	EDIT_CUSTOM_FIELDS: 'Editar campos personalizados',
	EDIT_CUSTOM_LABEL: 'Editar etiqueta personalizada',
	CHANGE_ENTITLEMENT: 'Cambiar permiso de red',
	CHANGE_NETWORK_ENTITLEMENT: 'Cambiar permiso de red',
	CURRENT_NETWORK_ENTITLEMENT: 'Permiso de red actual',
	NETWORK_ENTITLEMENT_UPDATED_SUCCESSFULLY: '¡Permiso de red actualizado con éxito!',
	LIST_VIEW_UPDATED: 'Vista de lista de terminales actualizada',
	NETWORK_ENTITLEMENT_UPDATING: 'Actualizando permiso de red. Espere, por favor',
	LIST_VIEW_UPDATING: 'Actualizando vista de lista de terminales. Espere, por favor',
	NEW_NETWORK_ENTITLEMENT: 'Nuevo permiso de red',
	NEW_NETWORK_ENTITLEMENT_DETAILS: 'Detalles de nuevo permiso de red',
	TABLE_COLUMNS: 'Elegir cant. de columnas para mostrar en la tabla.',
	TABLE_COLUMNS_OR: 'Elegir cant. de columnas para mostrar en la tabla o',
	COLUMN: 'Columna',
	COLUMNS: 'Columnas',
	SELECT: 'Seleccionar',
	DATA_TYPE: 'tipo de datos',
	ADVANCED_SEARCH: 'Búsqueda avanzada',
	SEARCH_RESULTS: 'Resultados de búsqueda',
	SUBMIT: 'Enviar',
	SEARCH_PARAMETER: 'Parámetros de búsqueda',
	ADD_ANOTHER_SEARCH_PARAMETER: 'Agregar otro parámetro de búsqueda',
	STATUS_DATE_RANGE: 'Rango de fecha de estado',
	SIM_STATUS_STATE: 'Estado de la SIM (estado)',
	NEW_PARAMETER_ACTION: 'Y / O',
	PLEASE_SELECT_VALUES_TO_FILTER_ENDPOINTS: 'Elija de los parámetros disponibles e ingrese el criterio para filtrar terminales.',
	RESET_SEARCH: 'Restablecer búsqueda',
	QUICK_SEARCH: 'Búsqueda rápida',
	ORIGINAL_SETTINGS: 'Restablecer parámetros originales',
	MONTH: 'Mes',
	DAY: 'Día',
	REFRESH_ENDPOINTS_PAGE: 'Actualizar página/tabla de terminales',
	CLEAR_SELECTED_FILTERS_TOOLTIP: 'Eliminar filtros seleccionados',
	SELECT_FILTERS_TOOLTIP: 'Seleccionar filtros',
	EDIT_TABLE_PARAMETERS_TOOLTIP: 'Editar parámetros de tabla',

	REPORT_DATE: 'Fecha',

	START_NEW_SEARCH: 'Iniciar búsqueda nueva',
	SUCCESS: 'Success',
	FAILED: 'Falló',
	RESULT_ACTION: 'Acción',
	REPORT_DETAILS: 'Detalles del informe',
	REPORT_LIST: 'Lista de informes en',
	VIEW_DETAILS: 'Ver detalles',
	DOWNLOAD_PDF: 'Descargar',
	FILE_NAME: 'Nombre de archivo',
	BATCH_FILES: 'Lote de archivos',
	BATCH_FILE_NAME: 'Nombre de lote de archivo',
	BATCH_FILE_ACTIONS: 'Acciones de lote de archivo',
	BATCH_FILE_UPLOAD: 'Acciones masivas',
	CHANGE_SIM_STATE: 'Cambiar estado de SIM',
	SELECT_SIM_STATE: 'Seleccionar estado de SIM',
	SIMS_SELECTED: 'SIM seleccionadas',
	SIM_SELECTED: 'SIM seleccionada',
	ONCE_YOU_INITIATE_CHANGE: 'Al iniciar este cambio, las SIM seleccionadas serán',
	PROCEED_ACTION: '¿Desea continuar con esta acción?',
	CAN_SCHEDULE: 'O puede fijar el cambio para',
	CURRENT_STATES: 'Estados actuales',
	SCHEDULE_SUCCESSFUL: '¡Cambio de estado de SIM múltiples fijado con éxito!',
	SIM_STATE_CHANGE_FOR: 'Cambio de estado de SIM para',
	SIMS_IS_SUCCESSFULLY_SCHEDULED_FOR: 'SIM fijadas con éxito para',
	SIMS_SUCCESSFULLY_REQUESTED: '¡Cambio de estado de SIM solicitado con éxito!',
	MULTIPLE_SIM_STATE_CHANGE_SCHEDULE_ERROR: '¡El cambio de estado de SIM múltiples no se pudo fijar con éxito!',
	MULTIPLE_SIM_STATE_CHANGE_ERROR: '¡El cambio de estado de SIM múltiples no se pudo solicitar con éxito!',
	SELECTED_TIP: 'Consejo: Si seleccionó SIM que están en diferentes estados, solo podrá cambiar a un estado disponible para todas las SIM.',
	VIEW_RULES: 'Ver reglas de cambio de estado para esta cuenta',
	BATCH_FILE_UPLOADING: 'Cargando lote de archivo',
	DATE_UPLOADED: 'Fecha de carga',
	SIMPLE_SEARCH: 'Búsqueda simple',
	NUMBER_OF_COLUMNS: 'Cant. de columnas',
	SELECT_SEARCH_PARAMETER: 'Seleccionar parámetro de búsqueda',

	DROPDOWN_BACK_TO_BUSINESS: 'Volver a comercio',
	'DROPDOWN_CONTACT_AT&T': 'Contactar a IoT de AT&T',
	DROPDOWN_MC: 'IoT Console',
	DROPDOWN_M2X: 'M2X',
	DROPDOWN_FD: 'Diseñador de flujo',
	DROPDOWN_SK: 'Kit básico de IoT',
	DROPDOWN_DP: 'Planes de datos IoT',
	MY_INFORMATION: 'Mi información',
	MY_APPLICATIONS: 'Mis solicitudes',
	TOPBAR_IDENTITY: 'AT&T IoT Identity',
	ERROR: 'Error',
	PAGE_NOT_FOUND_MESSAGE: 'No podemos encontrar la página que busca.',
	'RETURN TO HOME PAGE': 'VOLVER A PÁGINA DE INICIO',
	INTERNAL_SERVER_ERROR: 'Error interno del servidor',
	ACTIVE_ENDPOINT_PROFILE: 'PERFIL DE TERMINAL ACTIVA',
	CREATE_OR_EDIT: 'Crear o editar etiqueta personalizada para esta terminal',
	CREATE_CUSTOM_LABELS: 'Crear etiquetas personalizadas',
	SNAP: 'Vaya',
	SNAP_ERROR_MESSAGE: 'Sucedió un error desconocido. Si el error persiste, contacte al serv. de asistencia.',

	// definiciones de detalle de informe
	REPORT_DETAIL_DEFINITIONS: 'DEFINICIONES DE DETALLE DE INFORME',
	REPORT_DETAIL_DEFINITIONS_MODAL_TITLE: 'Definiciones de detalle de informe',
	REPORT_DETAIL_DEFINITIONS_DESC: 'Seleccione el botón de abajo para ver las definiciones de detalle de informe.',
	VIEW_DEFINITIONS_BUTTON: 'VER DEFINICIONES',

	// definition types
	'DATA SESSIONS DETAIL DEFINITION': 'Definición de detalle de sesiones de datos',
	'IMSI CHARGE DETAIL XML FIELDS': 'Detalle de cargo de campos XML de IMSI',
	'IMSI DETAIL DEFINITIONS': 'Definiciones de detalle de IMSI',
	'LBS USAGE DETAILS': 'Detalle de uso LBS',
	'DATA LAGG DETAIL DEFINITIONS': 'Definiciones de detalle de Lagg',
	'SMS CALL DETAIL DEFINITIONS': 'Definiciones de detalle de llamada SMS',
	'TAX DETAIL DEFINITIONS': 'Definiciones de detalle de impuestos',
	'SIM ACTIVITY DETAIL DEFINITION': 'Definiciones de detalle de actividad SIM',

	// Data Lagg Detail Definitions - KEYS -------------
	CUST_ID_KEY: 'CUST_ID',
	IMSI_KEY: 'IMSI',
	ICCID_KEY: 'ICCID',
	MSISDN_KEY: 'MSISDN',
	ON_NET_KB_USAGE_KEY: 'ON_NET_KB_USAGE',
	ROAMING_KB_USAGE_KEY: 'ROAMING_KB_USAGE',
	CAN_KB_USAGE_KEY: 'CAN_KB_USAGE',
	INT_KB_ROUNDED_KEY: 'INT_KB_ROUNDED',
	TOTAL_KB_USAGE_KEY: 'TOTAL_KB_USAGE',

	// Data Lagg Detail Definitions - DESCRIPTIONS
	CUST_ID_DESC: 'ID de cliente EOD.',
	IMSI_DESC: 'Identificador de estación móvil internacional. Es un número que la red usa para identificar al usuario.',
	ICCID_DESC: 'Identificador de tarjeta de circuito integrado. Es el número impreso en la SIM.',
	MSISDN_DESC: 'Número ISDN de estación móvil (núm. de teléfono) asignado a esa SIM.',
	ON_NET_KB_USAGE_DESC: 'Uso total en la red (kB)',
	ROAMING_KB_USAGE_DESC: 'Uso total nacional fuera de la red (kB).',
	CAN_KB_USAGE_DESC: 'Uso total en Canadá (kB).',
	INT_KB_ROUNDED_DESC: 'Uso total internacional (kB).',
	TOTAL_KB_USAGE_DESC: 'Uso total (kB).',

	// Data Sessions Detail Definition - KEYS ----------------
	IMPORT_DATE_KEY: 'Fecha de importación',
	// IMSI - use already defined key-desc
	// ICCID - use already defined key-desc
	GGSN_IP_KEY: 'IP DE GGSN',
	SGSN_IP_KEY: 'IP DE SGSN',
	APN_NAME_KEY: 'NOMBRE DEL APN',
	PDP_IP_KEY: 'IP DE PDP',
	UPLINK_VOLUME_KEY: 'VOLUMEN DE ENLACE ASCENDENTE',
	DOWNLINK_VOLUME_KEY: 'VOLUMEN DE ENLACE DE BAJADA',
	TRANSACTION_START_TIME_KEY: 'HORA DE INICIO DE TRANSACCIÓN',
	DURATION_KEY: 'DURACIÓN',
	SERVED_MSISDN_KEY: 'MSISDN SERVIDO',
	BID_KEY: 'BID',
	SERVING_LOCATION_KEY: 'UBICACIÓN DE SERVICIO',
	NETWORK_KEY: 'RED',
	ROUNDING_DATE_KEY: 'FECHA DE REDONDEO',

	// Data Sessions Detail Definition - DESCRIPTIONS
	IMPORT_DATE_DESC: 'La fecha y hora que EOD recibió este registro. Se usa para determinar a qué fecha de facturación se aplicará este registro.',
	// IMSI - use already defined key-desc
	// ICCID - use already defined key-desc
	GGSN_IP_DESC: 'Nodo de asistencia de pasarela GPRS. Es el equipo que se encuentra entre la PLMN y las redes externas para suministrar servicios de pasarela de frontera como seguridad y contabilidad.',
	SGSN_IP_DESC: 'Servicio de nodo de asistencia de GPRS. El equipo ubicado al mismo nivel jerárquico que el MSC/RPV para gestionar la movilidad y administrar el vínculo lógico de las terminales móviles.',
	APN_NAME_DESC: 'Nombre del APN usado por este equipo para esta transacción.',
	PDP_IP_DESC: 'El IP asignado al equipo al momento de la transacción.',
	UPLINK_VOLUME_DESC: 'La cant. de bytes (en octetos) que pasaron desde el equipo para este registro de detalle.',
	DOWNLINK_VOLUME_DESC: 'La cant. de bytes (en octetos) que pasaron al equipo para este registro de detalle.',
	TRANSACTION_START_TIME_DESC: 'La hora de inicio actual de la transacción. Esta fecha variará de la "fecha de importación" y "fecha de enrutamiento" según cuándo EOD recibió el registro.',
	DURATION_DESC: 'La cant. de tiempo (en segundos) para ese registro de datos.',
	SERVED_MSISDN_DESC: 'El número ISDN de estación móvil (núm. de teléfono) asignado a esa SIM.',
	BID_DESC: 'Identificador de facturación. Usado por EOD para determinar el mercado donde tuvo lugar la transacción.',
	SERVING_LOCATION_DESC: 'El nombre de ubicación donde ocurrió este registro de datos.',
	NETWORK_DESC: 'Cingular, roaming/fuera de la red, internacional o Canadá.',
	ROUNDING_DATE_DESC: 'La fecha usada por EOD para determinar con qué otros registros se agrupará este registro y calcular el redondeo diario de kB.',
	SESSION_HISTORY_DCP_NOT_APPLICABLE: 'No corresponde.',

	// IMSI Charge Detail XML Fields - KEYS AND DESC ------------
	CUSTOMER_KEY: 'CLIENTE',
	CUSTOMER_DESC: 'Prefijo del cliente',
	STARTCYCLE_KEY: 'STARTCYCLE',
	STARTCYCLE_DECS: 'Comienzo del ciclo de facturación.',
	ENDCYCLE_KEY: 'ENDCYCLE',
	ENDCYCLE_DESC: 'Final del ciclo de facturación.',
	WCO_KEY: 'WCO',
	WCO_DESC: 'Oferta de conectividad inalámbrica',
	EQUIPMENTORDERS_KEY: 'EQUIPMENTORDERS',
	EQUIPMENTORDERS_DESC: 'Pedido de equipo',
	ACCOUNTOCC_KEY: 'ACCOUNTOCC',
	ACCOUNTOCC_DESC: 'Otros cargos y créditos a nivel de cuenta (solo impuestos y recargos)',
	ACCOUNTTAXES_KEY: 'ACCOUNTTAXES',
	ACCOUNTTAXES_DESC: 'Impuestos y cargos del gobierno (solo impuestos) a nivel de cuenta',
	// ICCID - REUSE
	// IMSI - REUSE
	// MSISDN -REUSE
	RATEPLAN_KEY: 'RATEPLAN',
	RATEPLAN_DESC: 'Plan de tarifas facturado.',
	ACTIVATIONSTATE_KEY: 'ACTIVATIONSTATE',
	ACTIVATIONSTATE_DESC: 'Estado del equipo.',
	CUSTFILED1_KEY: 'CUSTFILED1',
	CUSTFILED1_DESC: 'Campo de cliente 1',
	CUSTFILED2_KEY: 'CUSTFILED2',
	CUSTFILED2_DESC: 'Campo de cliente 2',
	CUSTFILED3_KEY: 'CUSTFILED3',
	CUSTFILED3_DESC: 'Campo de cliente 3',
	CUSTFILED4_KEY: 'CUSTFILED4',
	CUSTFILED4_DESC: 'Campo de cliente 4',
	CUSTFILED5_KEY: 'CUSTFILED5',
	CUSTFILED5_DESC: 'Campo de cliente 5',
	ATTPOINTER_KEY: 'ATTPOINTER',
	ATTPOINTER_DESC: 'Puntero interno',
	CUSTPOINTER_KEY: 'CUSTPOINTER',
	CUSTPOINTER_DESC: 'Puntero del cliente',
	DATAMRC_KEY: 'DATAMRC',
	DATAMRC_DESC: 'MRC de datos',
	DATAUSAGE_KEY: 'DATAUSAGE',
	DATAUSAGE_DESC: 'Todos los cargos de uso de datos',
	SMSMRC_KEY: 'SMSMRC',
	SMSMRC_DESC: 'MRC SMS',
	SMSUSAGE_KEY: 'SMSUSAGE',
	SMSUSAGE_DESC: 'Todos los cargos de uso de SMS – excluyendo cargos de SMS masivo',
	VOICEMRC_KEY: 'VOICEMRC',
	VOICEMRC_DESC: 'MRC de voz',
	VOICEUSAGE_KEY: 'VOICEUSAGE',
	VOICEUSAGE_DESC: 'Todos los cargos de uso de voz',
	DEVICEACTIVITYFEE_KEY: 'DEVICEACTIVITYFEE',
	DEVICEACTIVITYFEE_DESC: 'Cargos por equipo (por activación, desactivación, reactivación, cambio de plan)',
	OCC_KEY: 'OCC',
	OCC_DESC: 'Otros cargos y créditos a nivel de suscriptor (solo impuestos y recargos)',
	TAXES_KEY: 'IMPUESTOS',
	TAXES_DESC: 'Impuestos y cargos del gobierno (solo impuestos) a nivel de suscriptor',

	// IMSI DETAIL KEYS VALUE -----------------------
	// ICCID - REUSE
	// MSISDN -REUSE
	PLAN_CODE_KEY: 'PLAN_CODE',
	PLAN_CODE_DESC: 'El código del plan asignado por el cliente a cada SIM.',
	ACT_ID_KEY: 'ACT_ID',
	ACT_ID_DESC: 'La acción realizada por el cliente para cada SIM (1 = Activar, 2 = Desactivar)',
	INCLUDED_USAGE_KEY: 'INCLUDED_USAGE',
	INCLUDED_USAGE_DESC: 'Uso total incluido (kB)',
	// ON_NET_KB_USAGE - REUSE
	// ROAMING_KB_USAGE - REUSE
	// CAN_KB_USAGE - REUSE
	// INT_KB_ROUNDED - REUSE
	// TOTAL_KB_USAGE - REUSE
	ACCESS_CHG_KEY: 'ACCESS_CHG',
	ACCESS_CHG_DESC: 'Cargo de acceso mensual recurrente.',
	ACTIVATION_CHG_KEY: 'ACTIVATION_CHG',
	ACTIVATION_CHG_DESC: 'Cargo de activación.',
	BEGPERIOD_KEY: 'BEGPERIOD',
	BEGPERIOD_DESC: 'Comienzo del ciclo de facturación.',
	ENDPERIOD_KEY: 'ENDPERIOD',
	ENDPERIOD_DESC: 'Final del ciclo de facturación.',
	CUST_PREFIX_KEY: 'CUST_PREFIX',
	CUST_PREFIX_DESC: 'Identificador del cliente.',
	CUST_FIELD_1_KEY: 'CUST_FIELD_1',
	CUST_FIELD_1_DESC: 'Campo de cliente 1',
	CUST_FIELD_2_KEY: 'CUST_FIELD_2',
	CUST_FIELD_2_DESC: 'Campo de cliente 2',
	CUST_FIELD_3_KEY: 'CUST_FIELD_3',
	CUST_FIELD_3_DESC: 'Campo de cliente 3',
	CUST_FIELD_4_KEY: 'CUST_FIELD_4',
	CUST_FIELD_4_DESC: 'Campo de cliente 4',
	CUST_FIELD_5_KEY: 'CUST_FIELD_5',
	CUST_FIELD_5_DESC: 'Campo de cliente 5',
	INTERNAL_POINTER_KEY: 'PUNTERO INTERNO',
	INTERNAL_POINTER_DESC: 'Puntero interno de AT&T.',
	CUSTOMER_POINTER_KEY: 'PUNTERO DEL CLIENTE',
	CUSTOMER_POINTER_DESC: 'Puntero del cliente',

	// LBS USAGE DEFINITION KEYS-VALUE
	RECORDINGENTITY_KEY: 'RECORDINGENTITY',
	RECORDINGENTITY_DESC: 'Puntero único para registros Agr. (Reservado para uso del agregador).',
	LCSCLIENTEXTERNALID_KEY: 'LCSCLIENTEXTERNALID',
	LCSCLIENTEXTERNALID_DESC: 'ExternalID de cliente LCS (no se usa en este momento).',
	TARGETIMSI_KEY: 'TARGETIMSI',
	TARGETIMSI_DESC: 'Identificador de estación móvil internacional. El número que usa la red para identificar al usuario.',
	TARGETMSISDN_KEY: 'TARGETMSISDN',
	TARGETMSISDN_DESC: 'MSDN ubicado si es SIM de ATT, XXXXXXXXXX si no es SIM de ATT (El número ISDN de estación móvil "núm. de teléfono" asignado a esa SIM).',
	RESULTCODE_KEY: 'RESULTCODE',
	RESULTCODE_DESC: 'Éxito o fallo de ubicación. 0 = éxito/ solo se usa 0 y 1 para comenzar como Agr. y Med. resultCodes pueden variar.',
	UTCRECORDOPENINGTIME_KEY: 'UTCRECORDOPENINGTIME',
	UTCRECORDOPENINGTIME_DESC: 'No se usa en este momento (hora UTC de apertura de registro).',
	INFORMATION_1_KEY: 'INFORMATION_1',
	INFORMATION_1_DESC: 'ID de aplicación AppID de 16 dígitos emitida por EAG (“1” en posición2 = Tecnocom, “2” en posición2 = ID de LocAid)',
	INFORMATION_3_KEY: 'INFORMATION_3',
	INFORMATION_3_DESC: '(Horizontal) Precisión solicitada: Precisión en metros deseada. <=800 es AGPS. (800 para indicar solicitud AGPS o 801 para indicar solicitud CID/ECID para trazado con éxito de nonATT y 0 para fallos (por respuesta)).',
	INFORMATION_4_KEY: 'INFORMATION_4 ',
	INFORMATION_4_DESC: '(Horizontal) Precisión de retorno: Precisión en metros de retorno efectivo. (800 para indicar solicitud AGPS o 801 para indicar solicitud CID/ECID para trazado con éxito de nonATT y 0 para fallos (según respuesta)).',
	PART_DATE_KEY: 'PART_DATE',
	PART_DATE_DESC: 'Fecha de ingreso a archivo de transmisión CDR por Agr. (PST: AAAA-MM-DD HH:mm:ss)',

	// SMS CALL DEFIONITIONS - KEYS AND DESC
	// 'IMSI',
	// 'MSISDN SERVIDO',
	SMS_TYPE_KEY: 'TIPO DE SMS',
	SMS_TYPE_DESC: 'Originado en móvil (MO) o terminado en móvil (MT).',
	CALLED_NUMBER_KEY: 'NÚMERO LLAMADO',
	CALLED_NUMBER_DESC: 'El número marcado.',
	START_TIME_KEY: 'HORA DE INICIO',
	START_TIME_DESC: 'La fecha y hora en que ocurrió la transacción.',
	// 'RED',
	PLMN_KEY: 'PLMN',
	PLMN_DESC: 'La lista PLMN es un identificador de facturación que usa EOD para determinar el mercado donde tuvo lugar la transacción.',
	CARRIER_KEY: 'OPERADOR',
	CARRIER_DESC: 'El dueño del mercado móvil asociado con la PLMN.',

	// SIM ACTIVITY DEFINITIONS - KEYS AND DESC
	// 'ICCID',
	// 'MSISDN',
	ACTION_DATE_KEY: 'ACTION_DATE',
	ACTION_DATE_DESC: 'Acción de fecha y hora completada.',
	ACT_KEY: 'ACT',
	ACT_DESC: 'La acción realizada por el cliente para cada SIM (ACTV = Activar, KILL = Desactivar, CHNG = Cambio de perfil de red, RATE = Cambio de plan de tarifas, CPPU = Cambio de lugar principal de uso).',
	PLAN_CODE_KEY_2: 'PLAN_CODE',
	PLAN_CODE_DESC_2: 'El código alfa del plan usado para esta transacción.',
	LOGIN_KEY: 'INICIO DE SESIÓN',
	LOGIN_DESC: 'Transacción asociada con el usuario.',

	// TAX DETAIL DEFINITIONS - KEYS AND DESC
	PPU_ADDRES_KEY: 'PPU_ADDRES',
	PPU_ADDRES_DESC: 'Lugar principal de uso – Donde el cliente usa su(s) servicio(s) principalmente.',
	SERVICE_KEY: 'SERVICIO',
	SERVICE_DESC: 'El producto u oferta gravado.',
	INVOICE_SECTION_KEY: 'SECCIÓN DE FACTURA',
	INVOICE_SECTION_DESC: 'TX – Cargos e impuestos del gobierno OC – Otros cargos y créditos.',
	AUTHORITY_LEVEL_KEY: 'NIVEL DE AUTORIDAD',
	AUTHORITY_LEVEL_DESC: 'Nivel asociado con el texto de la factura F – Federal S – Estado Co – País Ci – Ciudad DCo – Distrito de país DCi – Distrito de ciudad',
	INVOICE_TEXT_KEY: 'TEXTO DE FACTURA',
	INVOICE_TEXT_DESC: 'Texto relacionado con el cargo impreso en la factura de clientes.',
	CHARGE_AMMOUNT_KEY: 'MONTO DEL CARGO',
	CHARGE_AMMOUNT_DESC: 'El monto sobre el que se aplican impuestos.',
	AMMOUNT_KEY: 'MONTO',
	AMMOUNT_DESC: 'El monto gravado de un cargo imponible relacionado.',
	BATCH_FORMATS: 'Formatos de lote',

	ACTIVATION: 'Activación',
	TERMINATION: 'Cancelación',
	NETWORK_ENTITLEMENT_CHANGE: 'Cambio de permiso de red',
	SIM_SWAP: 'Cambio de SIM',
	CUSTOM_FIELD_UPDATES: 'Actualizaciones de campo personalizado',
	CUSTOM_FIELD_VALUE: 'Valor de campo personalizado',
	BATCH_FILE_UPLOAD_SUCCESS: 'iLote de archivos recibido con éxito!',
	BATCH_FILE_UPLOAD_SUCCESS_MESSAGE: 'Los cambios están pendientes mientras el sistema completa las actualizaciones',
	BATCH_FILE_SYSTEM_ERROR: 'iError del sistema en lote de archivos!',
	BATCH_FILE_SYSTEM_ERROR_MESSAGE: 'Hubo un problema al procesar sus actualizaciones. Inténtelo nuevamente.',
	TYPE_IN_OR_SELECT: 'Ingrese nuevo valor o seleccione la opción del menú desplegable para cambiar el parámetro de terminal específica.',

	SUBMITTED_STATUS: 'Enviado',
	SCHEDULED_STATUS: 'Fijado',
	IN_PROGRESS_STATUS: 'En progreso',
	COMPLETED_STATUS: 'Completado',
	'IN-PROGRESS_STATUS': 'En progreso',
	COMPLETED: 'Completado',
	CANCELLED: 'Cancelado',
	ERROR_STATUS: 'Error',

	FILE_NAME_CANNOT_CONTAIN_SPACES: 'El nombre del archivo no puede tener espacios',
	DRAG_FILE_OR_CLICK_TO_UPLOAD: 'Arrastre o haga clic en el archivo para cargarlo',
	FORBIDDEN_ACCESS: 'Acceso prohibido',
	REQUEST_TIMED_OUT: 'La solicitud superó el tiempo límite',
	TERMINATE_ACTION: 'Cancelar SIMS',
	ACTIVATE_ACTION: 'Activar SIMS',
	SWAP_SIMS_ACTION: 'Cambiar tarjetas SIM',
	CUSTOM_FIELDS_ACTION: 'Agregar o actualizar campos personalizados',
	REQUIRED_CUSTOM_FIELD: '¡Se requieren privilegios elevados!',
	FIELD_IS_REQUIRED_ACTION: 'Solo los admin. pueden modificar los tipos de campos personalizados porque tienen una config. que afecta todo el sistema. Contacte a su admin. para actualizar las opciones de campos personalizados.',
	PERMANENT_CUSTOM_FIELD_DELETION: '¡Borrado permanente de campo personalizado!',
	DELETING_THIS_CUSTOM_FIELD: '¡Borrar este campo personalizado borrará todos sus datos relacionados! ¡Esta acción no puede revertirse!',
	DELETE_FIELD: 'Borrar campo',

	WARNING: 'Advertencia',
	ARE_YOU_SURE: '¿Está seguro?',
	NETWORK_ENTITLEMENT_CHANGE_ACTION: 'Cambio de permiso de red de SIM',
	WHEN_SAVING: 'Al guardar su archivo, siga el formato de arriba.',
	EXAMPLE: 'Ejemplo',

	SELECT_NEW_STATE: 'Seleccionar estado nuevo',
	403: '403',

	AUTOMATION_PROCESS: 'Proceso de automatización',
	BATCH_UPLOAD: 'Carga de lote',
	DIAGRAM_COULD_NOT_BE: 'El diagrama no se pudo',
	LOADED_AT_THIS_TIME: 'cargar en este momento.',
	BATCH_ACTIONS: 'Acciones de lote',
	BATCH_ACTION: 'Acción de lote',
	BATCH_ACTIONS_SUBMITTED: 'El archivo se cargó con éxito para procesar',
	BATCH_ACTIONS_SCHEDULED: 'El archivo se cargó con éxito, programado para procesar',
	BATCH_ACTIONS_IN_PROGRESS: 'Procesamiento en curso',
	BATCH_ACTIONS_COMPLETED: 'El archivo se procesó con éxito',
	BATCH_ACTIONS_ERROR: 'Se produjo un error en el procesamiento',
	BATCH_REPORTING: 'Informe de lote',
	BATCH_TEXT: 'Para procesar sus cambios en lote, seleccione una acción a realizar y cargue el lote de archivo txt. Hay plantillas de ejemplo disponibles para formateo.',
	START_BATCH_PROCESS: 'Comenzar procesamiento de lote',
	DOWNLOAD_TEMPLATE: 'Descargar archivo de plantilla',
	AUTOMATION_RULES: 'Reglas de automatización',
	ACTION_FORMAT: 'Formato de acción',
	VIEW_MORE_DETAILS: 'Ver más detalles',
	UPLOAD_FILE_NOTE: 'Cargar aquí archivos para el lote de acción seleccionado:',
	UPLOAD_FILE: 'Cargar archivo',

	UNSUPPORTED_FILE_TYPE: 'Tipo de archivo no compatible',
	INVALID_FILE_NAME_FORMAT: 'El formato del nombre de archivo debe ser nombredeacción_MM-DD-AAAA_HH-mm-ss',
	DOWNLOAD_REPORT_FAILED: 'Fallo al descargar, inténtelo nuevamente.',
	PLEASE_TRY_AGAIN: 'Inténtelo nuevamente.',

	// REPORT DETAIL DEFINITION
	REPORT_SOURCE: 'Fuente de informe',
	DATA_SESSION_DETAIL_DEFINITION: 'Definición de detalle de sesión de datos',
	IMSI_DETAIL_DEFINITION: 'Definición de detalle de IMSI',
	SMS_DETAIL_DEFINITION: 'Definición de detalle de SMS',
	SIM_DETAIL_DEFINITION: 'Definición de detalle de SIM',
	USAGE_DATA_DETAILS: 'Detalle de uso de datos',
	USAGE_SMS_DETAILS: 'Detalle de uso de SMS',
	POOLED_USAGE: 'Uso combinado',
	USAGE_SUMMARY: 'Resumen de uso',
	SUBSCRIBER_SNAPSHOT: 'Instantánea de suscriptor',
	NEW_SUBSCRIBERS: 'Suscriptores nuevos',
	TOP_DATA_USAGE: 'Uso de datos máximo',
	TOP_SMS_USAGE: 'Uso de SMS máximo',
	PROVOSIONING_METRIC: 'Medida de suministro',

	// USAGE DATA DETAIL - KEYS AND DESC
	DATA_TRAFFIC_DETAIL_ID_KEY: 'ID de detalle de tráfico de datos',
	DATA_TRAFFIC_DETAIL_ID_DESC: 'Identificador único para registros de uso de datos',
	// "ICCID_KEY"
	ICCID_DESC_SHORT: 'ICCID que identifica la SIM',
	// "MSISDN_KEY"
	MSISDN_DESC_SHORT: 'MSISDN que identifica la SIM',
	// "IMSI_KEY"
	IMSI_DESC_SHORT: 'IMSI que identifica la SIM',
	ACCOUNT_ID_KEY: 'ID de cuenta',
	ACCOUNT_ID_DESC: 'Número de ID de cuenta Jasper',
	BILLABLE_FLAG_KEY: 'Indicador facturable',
	BILLABLE_FLAG_DESC: 'Identificador de tráfico facturable según estado de SIM',
	BILLING_CYCLE_KEY: 'Ciclo de facturación',
	BILLING_CYCLE_DESC: 'Ciclo de facturación cuando ocurrió el uso',
	SIM_STATE_KEY: 'Estado de SIM',
	SIM_STATE_DESC: 'Estado de SIM asignado a SIM al final del período del informe',
	SERVICE_TYPE_KEY: 'Tipo de servicio',
	SERVICE_TYPE_DESC: 'Tipo de servicio inalámbrico informado (por ej.: datos, SMS MO, SMS MT, voz)',
	ASSIGNED_RATE_PLAN_ID_KEY: 'ID de plan de tarifas asignado',
	ASSIGNED_RATE_PLAN_ID_DESC: 'Plan de tarifas asignado a la SIM cuando se informó el uso',
	ASSIGNED_RATING_ZONE_ID_KEY: 'ID de Zona de clasificación asignada',
	ASSIGNED_RATING_ZONE_ID_DESC: 'Zona de plan de tarifas al que se atribuyó el uso según la asignación del plan de tarifas cuando se informó el uso',
	OFFPEAK_USAGE_FLAG_KEY: 'Identificador de uso en hora no pico',
	OFFPEAK_USAGE_FLAG_DESC: 'Si el uso ocurrió en las horas no pico definidas por la cuenta',
	RECORD_RECEIVED_DATE_KEY: 'Fecha de recepción de registro',
	RECORD_RECEIVED_DATE_DESC: 'Fecha/Hora de recepción de registro de contabilidad',
	DATA_USAGE_RAW_TOTAL_KEY: 'Uso de datos - Total bruto',
	DATA_USAGE_RAW_TOTAL_DESC: 'Uso de datos sin redondear para el período - bytes',
	DATA_USAGE_RAW_UPLINK_KEY: 'Uso de datos - Ascendente bruto',
	DATA_USAGE_RAW_UPLINK_DESC: 'Uso de datos sin redondear para el período - bytes ascendentes',
	DATA_USAGE_RAW_DOWNLINK_KEY: 'Uso de datos - Descendiente bruto',
	DATA_USAGE_RAW_DOWNLINK_DESC: 'Uso de datos sin redondear para el período - bytes descendientes',
	DATA_USAGE_ROUNDED_KEY: 'Uso de datos - Redondeado',
	DATA_USAGE_ROUNDED_DESC: 'Uso de datos redondeado para el período - bytes; Redondeo definido según plan de datos',
	APN_DESC: 'Nombre de punto de acceso para la sesión',
	DEVICE_IP_ADDRESS_KEY: 'Dirección IP del equipo',
	DEVICE_IP_ADDRESS_DESC: 'La dirección IP asignada al equipo para la sesión de datos correspondiente',
	OPERATOR_NETWORK_KEY: 'Red del operador',
	OPERATOR_NETWORK_DESC: 'La red del operador visitada donde ocurrió el uso',
	RECORD_OPEN_TIME_KEY: 'Hora de apertura de registro',
	RECORD_OPEN_TIME_DESC: 'Fecha/Hora de comienzo de sesión o sesión parcial',
	SESSION_DURATION_KEY: 'Duración de sesión',
	SESSION_DURATION_DESC: 'Duración de sesión de datos - segundos',
	RECORD_SEQUENCE_NUMBER_KEY: 'Número secuencial de registro',
	RECORD_SEQUENCE_NUMBER_DESC: 'Número secuencial del registro dentro de la sesión matriz si el registro representa una sesión parcial',
	CHARGINGID_KEY: 'ChargingID',
	CHARGINGID_DESC: 'Un identificador de la sesión a la que aplica el registro de uso',
	SESSION_CLOSE_CAUSE_KEY: 'Causa de cierre de sesión',
	SESSION_CLOSE_CAUSE_DESC: 'Código numérico que representa la razón del cierre de sesión',
	TAP_CODE_KEY: 'Código TAP',
	TAP_CODE_DESC: 'Un código TAP de la red visitada',
	OPERATOR_ACCOUND_ID_KEY: 'ID de cuenta de operador',
	OPERATOR_ACCOUND_ID_DESC: 'ID usado por el operador para identificar una cuenta',
	RATE_PLAN_VERSION_ID_KEY: 'ID de versión de plan de tarifas',
	RATE_PLAN_VERSION_ID_DESC: 'Versión específica del plan de tarifas usado con fines de facturación en este momento',
	STREAM_ID_KEY: 'ID de flujo',
	STREAM_ID_DESC: 'Un identificador numérico que describe diferentes flujos de datos identificados por GGSN/PGW',
	CGI_KEY: 'CGI (Identificador global de la célula)',
	CGI_DESC: 'Parte de la info. de ubicación como se describe en TS29.274',
	SAI_KEY: 'SAI (Identificador de área de servicio)',
	SAI_DESC: 'Parte de la info. de ubicación como se describe en TS29.274',
	RAI_KEY: 'RAI (Identificador de zona de enrutamiento)',
	RAI_DESC: 'Parte de la info. de ubicación como se describe en TS29.274',
	TAI_KEY: 'TAI (Identificador de área de seguimiento)',
	TAI_DESC: 'Parte de la info. de ubicación como se describe en TS29.274',
	ECGI_KEY: 'ECGI(E-UTRAN CGI)',
	ECGI_DESC: 'Parte de la info. de ubicación como se describe en TS29.274',
	LAI_KEY: 'LAI (Identificador de área de localización)',
	LAI_DESC: 'Parte de la info. de ubicación como se describe en TS29.274',
	SERVING_SGSN_KEY: 'SGSN de servicio',
	SERVING_SGSN_DESC: 'Dirección del conmutador de servicio',
	CALL_TECHNOLOGY_TYPE_KEY: 'Tipo de tecnología de llamada',
	CALL_TECHNOLOGY_TYPE_DESC: 'Indicador numérico que identifica la tecnología de acceso de radio empleada para este uso',

	// USAGE SMS DETAIL - KEYS AND DESC
	SMS_TRAFFIC_DETAIL_ID_KEY: 'ID de detalle de tráfico de datos',
	SMS_TRAFFIC_DETAIL_ID_DESC: 'Identificador único para registros de uso de datos',
	// "ICCID_KEY"
	// "ICCID_DESC"
	// "MSISDN_KEY"
	// "MSISDN_DESC"
	// "IMSI_KEY"
	// "IMSI_DESC"
	// "ACCOUNT_ID_KEY"
	// "ACCOUNT_ID_KEY"
	// "BILLABLE_FLAG_KEY"
	// "BILLABLE_FLAG_DESC"
	// "BILLING_CYCLE_KEY"
	// "BILLING_CYCLE_DESC"
	// "SIM_STATE_KEY"
	// "SIM_STATE_DESC"
	// "SERVICE_TYPE_KEY"
	// "SERVICE_TYPE_DESC"
	// "ASSIGNED_RATE_PLAN_ID_KEY"
	// "ASSIGNED_RATE_PLAN_ID_DESC"
	// "ASSIGNED_RATING_ZONE_ID_KEY"
	// "ASSIGNED_RATING_ZONE_ID_DESC"
	RECORD_OPEN_TIME_SMS_KEY: 'Hora de apertura de registro',
	RECORD_OPEN_TIME_SMS_DESC: 'Fecha/Hora en que un mensaje SMS MO se envió; Fecha/Hora en que un mensaje SMS MT se recibió',
	// "RECORD_RECEIVED_DATE_KEY": 'Fecha de recepción de registro',
	// "RECORD_RECEIVED_DATE_DESC": 'Fecha/Hora de recepción de registro de contabilidad',
	NETWORK_NODE_KEY: 'Nodo de red',
	NETWORK_NODE_DESC: 'Dirección del nodo de red de servicio para el mensaje',
	FROM_KEY: 'De',
	FROM_DESC: 'MSISDN fuente o código corto',
	TO_KEY: 'A',
	TO_DESC: 'MSISDN de destino o código corto',
	// "OPERATOR_NETWORK_KEY": 'Red del operador',
	// "OPERATOR_NETWORK_DESC": 'La red del operador visitada donde ocurrió el uso',
	// "TAP_CODE_KEY": 'Código TAP',
	// "TAP_CODE_DESC": 'Un código TAP de la red visitada',
	// "OPERATOR_ACCOUND_ID_KEY": 'ID de cuenta de operador',
	// "OPERATOR_ACCOUND_ID_DESC": 'ID usado por el operador para identificar una cuenta',
	// "RATE_PLAN_VERSION_ID_KEY": 'ID de versión de plan de tarifas',
	// "RATE_PLAN_VERSION_ID_DESC": "Specific version of the rate plan,
	// being used for billing purposes at this point in time",

	// POOLED USAGE - KEYS AND DESC
	// "ACCOUNT_ID_KEY"
	// "ACCOUNT_ID_DESC"
	// "BILLING_CYCLE_KEY" />,
	// "BILLING_CYCLE_DESC" />,
	INVOICE_ID_KEY: 'ID de factura',
	INVOICE_ID_DESC: 'ID de factura a la que aplican los cargos',
	RATE_PLAN_ID_KEY: 'ID de plan de tarifas',
	RATE_PLAN_ID_DESC: 'ID de plan de tarifas asignado a SIM al final del período del informe',
	RATING_ZONE_ID_KEY: 'ID de Zona de clasificación',
	RATING_ZONE_ID_DESC: 'Zona de clasificación (definida en el plan de tarifas) donde ocurre el uso',
	TOTAL_DATA_USAGE_CHARGES_KEY: 'Cargos de uso total de datos',
	TOTAL_DATA_USAGE_CHARGES_DESC: 'Cargos de uso total de datos en zona de clasif., por encima del uso de datos combinados incluido en el cargo de inscripción',
	TOTAL_SMS_MO_USAGE_CHARGES_KEY: 'Cargos de uso total de SMS MO',
	TOTAL_SMS_MO_USAGE_CHARGES_DESC: 'Cargos de uso total de SMS MO en zona de clasif., por encima del uso de SMS combinado incluido en el cargo de inscripción',
	TOTAL_SMS_MT_USAGE_CHARGES_KEY: 'Cargos de uso total de SMS MT',
	TOTAL_SMS_MT_USAGE_CHARGES_DESC: 'Cargos de uso total de SMS MT en zona de clasif., por encima del uso de SMS combinado incluido en el cargo de inscripción',
	TOTAL_SMS_USAGE_CHARGES_KEY: 'Cargos de uso total de SMS',
	TOTAL_SMS_USAGE_CHARGES_DESC: 'Cargos de uso total de SMS en zona de clasif., por encima del uso de SMS combinado incluido en el cargo de inscripción',
	TOTAL_VOICE_MO_USAGE_CHARGES_KEY: 'Cargos de uso total de voz MO',
	TOTAL_VOICE_MO_USAGE_CHARGES_DESC: 'Cargos de uso total de voz MO en zona de clasif., por encima de cualquier uso de voz incluido en el cargo de inscripción',
	TOTAL_VOICE_MT_USAGE_CHARGES_KEY: 'Cargos de uso total de voz MT',
	TOTAL_VOICE_MT_USAGE_CHARGES_DESC: 'Cargos de uso total de voz MT en zona de clasif., por encima de cualquier uso de voz incluido en el cargo de inscripción',
	TOTAL_USAGE_CHARGES_KEY: 'Cargos de uso total',
	TOTAL_USAGE_CHARGES_DESC: 'Cargos de uso total en zona de clasif. por encima de cualquier uso combinado incluido en el cargo de inscripción',

	// USAGE SUMMARY - KEYS AND DESC
	// "ACCOUNT_ID_KEY"
	// "ACCOUNT_ID_KEY"
	OPERATOR_ID_KEY: 'ID de operador',
	OPERATOR_ID_DESC: 'Número de ID de operador Jasper',
	// "BILLING_CYCLE_KEY"
	// "BILLING_CYCLE_DESC"
	// "ICCID_KEY"
	// "ICCID_DESC"
	// "IMSI_KEY"
	// "IMSI_DESC"
	// "MSISDN_KEY"
	// "MSISDN_DESC"
	TOTAL_DATA_USAGE_KEY: 'Uso total de datos',
	TOTAL_DATA_USAGE_DESC: 'Uso total de datos del ciclo de facturación de este equipo',
	TOTAL_VOICE_USAGE_KEY: 'Uso total de voz',
	TOTAL_VOICE_USAGE_DESC: 'Uso total de voz del ciclo de facturación de este equipo',
	TOTAL_SMS_USAGE_KEY: 'Uso total de SMS',
	TOTAL_SMS_USAGE_DESC: 'Uso total de SMS del ciclo de facturación de este equipo',
	TOTAL_CSD_USAGE_KEY: 'Uso total de CSD',
	TOTAL_CSD_USAGE_DESC: 'Uso total de CSD del ciclo de facturación de este equipo',
	RESERVED_FIELD_KEY: 'Campo reservado',
	RESERVED_FIELD_DESC: 'Reservado para uso futuro',
	DEVICE_ID_KEY: 'ID de equipo',
	DEVICE_ID_DESC: 'Campo personalizado disponible para operadores',
	MODEM_ID_KEY: 'ID de módem',
	MODEM_ID_DESC: 'Campo personalizado disponible para operadores',
	END_CONSUMER_ID_KEY: 'ID de consumidor final',
	END_CONSUMER_ID_DESC: 'Identificador del consumidor final',
	USAGE_CUSTOMER_KEY: 'Cliente',
	USAGE_CUSTOMER_DESC: 'Nombre del cliente',
	CUSTOM1_KEY: 'Campo pers. 1',
	CUSTOM1_DEVICE_DESC: 'Campo personalizado 1 a nivel de equipo',
	CUSTOM2_KEY: 'Campo pers. 2',
	CUSTOM2_DEVICE_DESC: 'Campo personalizado 1 a nivel de equipo',
	CUSTOM3_KEY: 'Campo pers. 3',
	CUSTOM3_DEVICE_DESC: 'Campo personalizado 1 a nivel de equipo',

	// SUBSCRIBER SNAPSHOT - KEYS AND DESC
	SUBSCRIBER_SIM_STATE_KEY: 'Estado de SIM',
	SUBSCRIBER_SIM_STATE_DESC: 'Estado de SIM al final del período de informe',
	// "RATE_PLAN_ID_KEY"
	SUBSCRIBER_RATE_PLAN_ID_DESC: 'Plan de tarifas asignado a SIM al final del período de informe',
	SUBSCRIBER_CUSTOMER_KEY: 'Cliente',
	SUBSCRIBER_CUSTOMER_DESC: 'Cliente asignado a SIM al final del período de informe',
	// "DEVICE_ID_KEY",
	SUBSCRIBER_DEVICE_ID_DESC: 'Valor al final del período de informe',
	// "MODEM_ID_KEY"

	SUBSCRIBER_MODEM_ID_DESC: 'Valor al final del período de informe',
	COMMUNICATION_PLAN_ID_KEY: 'ID de plan de comunicación',
	COMMUNICATION_PLAN_ID_DESC: 'Valor al final del período de informe',
	INITIAL_ACTIVATION_DATE_KEY: 'Fecha de activación inicial',
	INITIAL_ACTIVATION_DATE_DESC: 'Fecha y hora de activación inicial de SIM en la cuenta actual',
	CUSTOM_SIM_DESC: 'Campo personalizado a nivel de SIM disponible para cuentas',
	LINE_PAYMENT_STATUS_KEY: 'Estado de pago de línea',
	LINE_PAYMENT_STATUS_DESC: 'Valores posibles: actual, suspendido, cancelado, cancelación final',
	OPERATOR_ACCOUNT_ID_KEY: 'ID de cuenta de operador',
	OPERATOR_ACCOUNT_ID_DESC: 'ID usado por el operador para identificar una cuenta',
	CUSTOM4_KEY: 'Campo pers. 4',
	CUSTOM5_KEY: 'Campo pers. 5',
	CUSTOM6_KEY: 'Campo pers. 6',
	CUSTOM7_KEY: 'Campo pers. 7',
	CUSTOM8_KEY: 'Campo pers. 8',
	CUSTOM9_KEY: 'Campo pers. 9',
	CUSTOM10_KEY: 'Campo pers. 10',

	ICCID_DESC_SHORT2: 'ICCID de equipo',
	IMSI_DESC_SHORT2: 'IMSI de equipo',
	RATE_PLAN_KEY: 'Rateplan',
	RATE_PLAN_DESC: 'EOD plan_code, ICCID de centro de control en blanco',
	SIM_STATE_KEY2: 'Simstate',
	SIM_STATE_DESC2: 'ACTIVO, INACTIVO, etc., si está disponible',
	BEGBILLINGCYCLEDATE_KEY: 'Begbillcycledate',
	BEGBILLINGCYCLEDATE_DESC: 'Fecha de inicio de ciclo de facturación si es EOD, en blanco si es centro de control',
	ENDBILLINGCYCLEDATE_KEY: 'Endbillcycledate',
	ENDBILLINGCYCLEDATE_DESC: 'Fecha de final de ciclo de facturación si es EOD, en blanco si es centro de control',
	TOTALDATAUSAGE_KEY: 'Totaldatausage',
	TOTALDATAUSAGE_DESC: 'Uso de datos del período en kB',
	TOTALINTERNATIONALUSAGE_KEY: 'Totalinternationalusage',
	TOTALINTERNATIONALUSAGE_DESC: 'Período de uso de datos internacional en kB si es EOD, en blanco si es centro de control',
	CUSTOMERNAME_KEY: 'Customername',
	CUSTOMERNAME_DESC: 'Nombre del cliente',
	CUSTOMFIELD1_KEY: 'Customfield1',
	CUSTOMERNAME1_DESC: 'EOD y centro de control pueden llenar',
	CUSTOMFIELD2_KEY: 'Customfield2',
	CUSTOMERNAME2_DESC: 'EOD y centro de control pueden llenar',
	CUSTOMFIELD3_KEY: 'Customfield3',
	CUSTOMERNAME3_DESC: 'EOD y centro de control pueden llenar',
	CUSTOMFIELD4_KEY: 'Customfield4',
	CUSTOMERNAME4_DESC: 'ID de equipo de EOD o centro de control',
	CUSTOMFIELD5_KEY: 'Customfield5',
	CUSTOMERNAME5_DESC: 'ID de módem de EOD o centro de control',
	M2MPLATFORM_KEY: 'M2MPlatform',
	M2MPLATFORM_DESC: '“EOD” o “Centro de control”',
	BILLINGCYCLE_KEY: 'Billingcycle',
	BILLINGCYCLE_DESC: 'Campo JPO de centro de control a llenar',
	CUSTOMERID_KEY: 'Customerid',
	CUSTOMERID_DESC: 'Para EOD, prefijo de cliente; para centro de control, ID de cliente',
	PROVISIONINGACTIVITY_KEY: 'ProvisioningActivity ',
	PROVISIONINGACTIVITY_DESC: 'Una opción: “activation”, “inactive”, “terminate”, “changeNetworkEntitlement”, “changeRatePlan”, “updateCustomFields”',
	COUNT_KEY: 'Conteo',
	COUNT_DESC: 'Cantidad de veces que se activó la actividad de suministro',
	SUCCESS_KEY: 'SUCCESS',
	SUCCESS_DESC: 'Cantidad de veces que se activó con éxito la actividad de suministro',
	FAILURE_KEY: 'Fallo',
	FAILURE_DESC: 'Cantidad de veces que se activó sin éxito la actividad de suministro',
	AVGTIMEMS_KEY: 'AvgTimeMs',
	AVGTIMEMS_DESC: 'Cant. de milisegundos en promedio que llevó la activación del suministro',
	LONGESTTIMEMS_KEY: 'LongestTimeMs',
	LONGESTTIMEMS_DESC: 'Cant. de milisegundos que llevó la activación más lenta de actividad de suministro',
	SHORTESTTIMEMS_KEY: 'ShortestTimeMs',
	SHORTESTTIMEMS_DESC: 'Cant. de milisegundos que llevó la activación más rápida de actividad de suministro',

	REPORT_SOURCE_DESC: 'Seleccionar fuente de informe para ver definiciones diferentes',

	SIM_SWAP_SUCCESSFUL: 'Éxito al cambiar SIM',
	SIM_SWAP_IN_PROGRESS: 'Cambio de SIM en progreso',
	REFRESH_PENDING_STATUS: 'Actualizar terminal para verificar cambio de estado',
	TEMPLATE_NOT_AVAILABLE: 'Plantilla no disponible',
	CUSTOM_FIELDS_UPDATE_SUCCESSFUL: 'Éxito al actualizar campos personalizados',
	CUSTOM_FIELDS_UPDATE_IN_PROGRESS: 'Actualización de campos personalizados en progreso',
	IOT_APPS: 'APPS DE IOT',
	INVOICES_DATE: 'Fecha de las facturas',
	PLATFORM: 'Plataforma',
	FREQUENCY: 'Frecuencia',
	M2M_ACC_ID: 'ID de cuenta M2M',
	REPORT: 'Informe',

	// Onboarding
	ADD_NEW_PLATFORM: 'Agregar plataforma nueva',
	BUSINESS_NAME: 'Nombre del comercio',
	ADMINISTRATOR: 'Administrador',
	MCC: 'MCC',
	MNC: 'IoT Console (IOT-C)',
	OPERATOR: 'Operador',
	POD: 'POD',
	RATE_PLANS: 'Planes de tarifas',
	RATE_PLAN_NAME: 'Nombre de plan de tarifas',
	RATE_PLAN_ID: 'Plan de tarifas',
	RATE_PLAN_ID_PLAN: 'ID de plan de tarifas',
	COMMUNICATION_PLANE_NAME: 'Nombre de plan de comunicación',
	ADD_ANOTHER_RATE_PLAN: 'Agregar otro plan de tarifas',
	ADD_ANOTHER_COMMUNICATION_PLAN: 'Agregar otro plan de comunicación',
	ADD_ANOTHER_PROFILE_NAME: 'Agregar otro nombre de perfil',
	SIM_STATE_MAPPING: 'Asignación de estado de SIM',
	ADD_RATE_PLAN: 'Agregar plan de tarifas',
	ACC_RULE: 'Regla ACC',
	MC_RULE: 'Regla IOT-C',
	WHAT_IS_THIS_TITLE: '¿Qué es esto?',
	PLAN_PROFILES: 'Perfiles de plan',
	COMPANY: 'Empresa',
	COMPANY_NAME: 'Nombre de la empresa',
	COMPANY_DOMAIN: 'Dominio de la empresa',
	PHONE_NUMBER: 'Número de teléfono',
	CITY: 'Ciudad',
	CITY_TOWN: 'Ciudad/Localidad',
	STATE_PROVINCE: 'Estado/Provincia',
	COUNTRY: 'País',
	ZIP_CODE: 'Código postal',
	ZIP_POSTAL_CODE: 'CP/Código postal',
	STREET: 'Calle',
	STREET_ADDRESS: 'Dirección',
	LAST_NAME: 'Apellido',
	FIRST_NAME: 'Nombre',
	ACTIONS: 'Acciones',
	PLEASE_SELECT_COUPLE_OF_SIMS: 'Seleccionar algunas SIM.',
	COMPANY_IDENTITY: 'Identidad de la empresa',
	ADD_USERS_OPTIONAL: 'Agregar usuarios (opcional)',
	REVIEW_AND_CREATE: 'Revisar y crear',
	PROJECTS_LIST: 'Lista de proyectos',
	MULTI_NETWORK_CONNECT_ONBOARDING_M2M_PLATFORMS: 'Incorporación de IoT Console - Plataformas M2M',
	MULTI_NETWORK_CONNECT: 'IoT Console',
	CUSTOMERS: 'Clientes',
	COMPANY_EMAIL_ADDRESS: 'Correo electrónico de la empresa',
	CONTACT_EMAIL_ADDRESS: 'Correo electrónico del contacto',
	CONTACT_PERSON_FULL_NAME: 'Nombre completo de persona de contacto',
	CONTACT_PERSON: 'Persona de contacto',
	ASSIGN_ANOTHER_PLATFORM: 'Asignar otra plataforma',
	USER_INFO: 'Info. de usuario',
	SELECT_USER_TYPE: 'Seleccionar tipo de usuario',
	USER_TYPE: 'Tipo de usuario',
	USER_NOTE: 'El usuario recibirá un correo electrónico de invitación para crear su cuenta',
	ADD_USER: 'Agregar usuario',
	ADD_ANOTHER_USER: 'Agregar otro usuario',
	WHAT_IS_THIS_MESSAGE1: 'Se debe cargar el cliente a las plataformas M2M antes de crear la cuenta IoT Console. Una vez que tenga el ID de cuenta, puede continuar.',
	WHAT_IS_THIS_MESSAGE2: 'Los clientes que son empresas pequeñas o nuevas y usen planes de datos IoT o kits básicos de IoT pueden agregarse automáticamente al llenar este formulario. Tras crear la cuenta, vuelva aquí para habilitar el acceso del cliente al portal IoT Console.',
	SELECT_PLATFORM: 'Seleccionar plataforma',
	EMAIL_EXISTS_OTHER: 'Este correo electrónico ya existe (otro usuario)',
	EMAIL_EXISTS_ADMIN: 'Este correo electrónico ya existe (admin.)',
	YOU_HAVE_SUCCESSFULLY_CREATED_A_MULTI_NETWORK_CONNECT_ACCOUNT_FOR: 'Creó con éxito una cuenta IoT Console para:',
	THE_IOT_GATEWAY_NUMBER_ASSOCIATED_WITH_THIS_ACCOUNT: 'Número de pasarela IoT asociado con esta cuenta:',
	COMMUNICATION_PROFILE_NAME: 'Nombre de perfil de comunicación',
	ADD_NETWORK_ENTITLEMENT: 'Agregar permiso de red',
	EDIT_NETWORK_ENTITLEMENT: 'Editar permiso de red',
	PLATFORMS: 'Plataformas',
	NO_NETWORK_ENTITLEMENTS: 'No hay permisos de red',
	NO_RATE_PLANS: 'No hay planes de tarifas',
	EDIT_RATE_PLAN: 'Editar plan de tarifas',
	ERROR_WHILE_ADDING_NETWORK_ENTITLEMENT: 'Error al agregar permiso de red',
	ERROR_WHILE_EDITING_NETWORK_ENTITLEMENT: 'Error al editar permiso de red',
	ERROR_WHILE_ADDING_RATE_PLAN: 'Error al agregar plan de tarifas',
	ERROR_WHILE_EDITING_RATE_PLAN: 'Error al editar el plan de tarifas',
	ERROR_WHILE_ADDING_COMPANY: 'Error al agregar empresa',
	ERROR_WHILE_EDITING_COMPANY: 'Error al editar empresa',
	ERROR_WHILE_ADDING_EDITING_USER: 'Error al agregar/editar usuario',
	ERROR_WHILE_ADDING_EDITING_PLATFORM: 'Error al agregar/editar plataforma',
	ERROR_WHILE_ADDING_PLATFORM: 'Error al agregar plataforma',
	ADD_PROFILE_NAME: 'Agregar nombre de perfil',
	ADD_PROFILE_ID: 'Agregar ID de perfil',
	EDIT_PLATFORM: 'Editar plataforma',
	SIM_STATE_CHANGE_IS_SUCCESSFULLY_SCHEDULED: '¡Cambio de estado de SIM fijado con éxito!',
	SIM_STATE_CHANGE_IS_SUCCESSFULLY_SCHEDULED_FOR: 'Cambio de estado de SIM fijado con éxito para',
	SIM_STATE_CHANGE_IS_SUCCESSFULLY_SCHEDULED_ERROR: '¡El cambio de estado de SIM no se pudo fijar con éxito!',
	SIM_STATE_CHANGED_TO: 'Eligió cambiar estado de SIM a',
	CHANGE_LATER: 'También puede fijar el cambio para más adelante.',
	SCHEDULED_CHANGE_WILL_HAPPEN: 'El cambio fijado sucederá al principio de la fecha que eligió.',
	TAP_INITIATE_CHANGE: 'Toque ‘Iniciar cambio’ para realizar acción inmediata.',
	SIM_STATE_CHANGE: 'Cambio en estado de SIM',
	SCHEDULE_SIM_STATE_CHANGE: 'Fijar cambio en estado de SIM',
	SYSTEM_CONFIRMATION: 'Confirmación de sistema',
	CHOOSE_NETWORK_ENTITLEMENT: 'Elegir permiso de red',
	HISTORY: 'Historial de lote',

	serviceProvider: 'Plataforma',
	reportFrequency: 'Frecuencia',

	// Onboarding
	ENDPOINT_SESSION_NO_RECORD: 'No hay datos para el período seleccionado.',
	TIME_START: 'Hora de comienzo',
	TIME_END: 'Hora de finalización',
	SESSION_DETAILS: 'Detalles de sesión',
	CARRIER: 'Operador',
	DEVICE_IP: 'IP del equipo',

	// chart datasets
	inventoryByStatus: 'Inventario por estado',
	inventoryByOperator: 'Inventario por operador',
	inventoryByCountry: 'Inventario por país',
	inventoryByStatusDescription: 'Esto mostrará la cant. de terminales activas e inactivas.',
	inventoryByOperatorDescription: 'Esto mostrará los operadores y la cant. de terminales con cada operador.',
	inventoryByCountryDescription: 'Esto mostrará un nombre de país y la cant. de terminales que funcionan en él.',
	// prompt modal
	addPromptHeaderInfo: '¿Aplicar cambios?',
	addChartPromptHeader: 'Los cambios del panel de control aun no se guardaron',
	addChartPromptFirst: 'Para guardar los cambios a la vista del panel de control, toque `Guardar panel de control`, o `cancelar` para volver a la vista anterior.',
	addChartPromptNotification: '¿Aplicar cambios?',

	// CHART Descriptions
	DASHBOARD_CHART_countByState_SATELLITEDescription: 'Muestra detalles de estado de terminales de satélite (equipos) en varias plataformas M2M',
	DASHBOARD_CHART_countByState_CELLULARDescription: 'Muestra detalles de estado de terminales de celular (equipos) en varias plataformas M2M',
	DASHBOARD_CHART_countByNetworkCarrier_SATELLITEDescription: 'Muestra división del porcentaje de terminales de satélite (equipos) en varios operadores de red',
	DASHBOARD_CHART_countByNetworkCarrier_CELLULARDescription: 'Muestra división del porcentaje de terminales de celular (equipos) en varios operadores de red',
	DASHBOARD_CHART_countByTechnologyDescription: 'Muestra un desglose total en porcentaje en las terminales de celular y satélite (equipos) en la cuenta',
	DASHBOARD_CHART_countByM2mPlatform_SATELLITEDescription: 'Muestra un desglose total en porcentaje en las terminales de satélite (equipos) en varias plataformas M2M',
	DASHBOARD_CHART_countByM2mPlatform_CELLULARDescription: 'Muestra un desglose total en porcentaje en las terminales de celular (equipos) en varias plataformas M2M',
	DASHBOARD_CHART_countByIMSI_SATELLITEDescription: 'Muestra un desglose total en porcentaje en las terminales de celular (equipos) en varias plataformas M2M',
	DASHBOARD_CHART_countByIMSI_CELLULARDescription: 'Muestra un desglose total en porcentaje en las terminales de satélite (equipos) en varias plataformas M2M',
	'DASHBOARD_CHART_IMSI Transfer Status_CELLULARDescription': 'Muestra los totales del estado de transferencia (Re-IMSI) en porcentaje entre dos plataformas M2M.',
	DASHBOARD_CHART_countByIMSITransferStatus_CELLULARDescription: 'Muestra los totales del estado de transferencia (Re-IMSI) en porcentaje entre dos plataformas M2M.',
	DASHBOARD_CHART_countByIMSITransferStatus_SATELLITEDescription: 'Muestra los totales del estado de transferencia (Re-IMSI) en porcentaje entre dos plataformas M2M.',
	DASHBOARD_CHART_summaryOfMonthlyEndpointUsageDescription: 'Muestra un resumen de uso de datos mensual en las terminales de celular y satélite (equipos)',
	DASHBOARD_CHART_countByStatebyPlatformACC_CELLULARDescription: 'Muestra detalles de estado de terminales de celular (equipos) para plataforma ACC',
	DASHBOARD_CHART_countByStatebyPlatformEOD_CELLULARDescription: 'Muestra detalles de estado de terminales de celular (equipos) para plataforma EOD',
	DASHBOARD_CHART_countByStatebyPlatformGBCM_SATELLITEDescription: 'Muestra detalles de estado de terminales de satélite (equipos) para plataforma GBCM',
	DASHBOARD_CHART_countByStatebyPlatformDCP_CELLULARDescription: 'Muestra detalles de estado de terminales de celular (equipos) para plataforma DCP',
	DASHBOARD_CHART_countByStatebyPlatformCCIP_CELLULARDescription: 'Muestra detalles de estado de terminales de celular (equipos) para plataforma CCIP',
	DASHBOARD_CHART_countByStatebyPlatformVCC_CELLULARDescription: 'Muestra detalles de estado de terminales de celular (equipos) para plataforma VCC',
	DASHBOARD_CHART_countByStatebyPlatformGDSP_CELLULARDescription: 'Muestra detalles de estado de terminales de celular (equipos) para plataforma GDSP',
	DASHBOARD_CHART_countByStatebyPlatformWING_CELLULARDescription: 'Muestra detalles de estado de terminales de celular (equipos) para plataforma WING',
	DASHBOARD_CHART_summaryOfMonthlyEndpointUsage_CELLULARDescription: 'Muestra un resumen de uso de datos mensual en las terminales de celular y satélite (equipos)',
	DASHBOARD_CHART_countByProfile_CELLULARDescription: 'Muestra detalles de estado de terminales de celular (equipos) en varios perfiles',

	// Pie Chart custom tooltip id
	PIE_CHART_TOOLTIP_Active: 'Activado',
	PIE_CHART_TOOLTIP_Test: 'Prueba',
	PIE_CHART_TOOLTIP_Inactive: 'Inactivo',
	PIE_CHART_TOOLTIP_Terminate: 'Cancelado',

	// Globecomm
	DEVICE_MODULE_NUMBER: 'Número de módulo de equipo',
	DEVICE_MANUFACTURER: 'Fabricante del equipo',
	DEVICE_STATUS: 'Estado del equipo',
	DEVICE_NETWORK_TYPE: 'Tipo de red del equipo',
	MODULE_IMEI: 'IMEI del módulo',
	SIM_STATUS: 'Estado de la SIM',
	MODULE_STATUS: 'Estado del módulo',
	TRESHOLD_OF_NOTIFICATION_BY_DATA_USAGE: 'Umbral de notificación por uso de datos',
	TRESHOLD_OF_SUSPENSION_BY_DATA_USAGE: 'Umbral de suspensión por uso de datos',
	NOTIFICATION_INTERVAL: 'Intervalo de notificación',
	DATA_USAGE: 'Ciclo hasta la fecha de uso de datos',
	MANUFACTURER: 'Fabricante',
	MODEL_NUMBER: 'Número de modelo',
	TYPE: 'Tipo',
	NETWORK_NAME: 'Nombre de red',
	SUPPORTED_NETWORKS: 'Redes compatibles',
	RENEWAL_PERIOD: 'Período de renovación',
	ACTIVATION_FEE: 'Cargo de activación',
	RECCURING_FEE: 'Cargo recurrente',
	OVERAGE_FEE: 'Cargo de uso adicional',
	INCLUDED_BYTES: 'Bytes incluidos',
	MINIMUM_BILLABLE_BYTES: 'Bytes mínimos facturables',
	NOTIFICATION_CONTACT: 'Contacto de notificación',
	NOTIFICATION_BYTE_CAP: 'Notificación por límite de bytes',
	NOTIFICATION_REFRESH: 'Actualización de notificación',
	OBSOLETE: 'Obsoleto',
	HARDWARE: 'Hardware',
	SPECIFICATIONS: 'Especificaciones',
	SPECIFICATION: 'Especificación',
	MODULE: 'Módulo',
	NO_HISTORY_DATA: 'No hay historial para esta terminal',

	// Dashboard default message
	NO_GRAPHS: 'LOS GRÁFICOS AGREGADOS SE MOSTRARÁN AQUÍ',
	dashboardDefault: 'LOS GRÁFICOS AGREGADOS SE MOSTRARÁN AQUÍ',
	REMOVE_CHART: 'Eliminar gráfico',
	CARD_DROPDOWN_TITLE: 'Tipo de gráfico',
	ADD_WIDGET_BUTTON: 'Agregar gráfico',
	ADD_WIDGET_BUTTON_MOBILE: 'Agregar',

	APP_NAME: 'IoT Console',
	NETWORK_TYPE_CHECK: 'Lamentablemente no se permite el cambio múltiple de estado de terminal para las terminales de tipos de red diferentes o proveedores de servicio diferentes.',
	SERVICE_PROVIDER_CHECK: 'Lamentablemente no se permite el cambio múltiple de terminales para las terminales de plataformas múltiples o con diferentes permisos de red.',

	// USER FEEDBACK MODAL
	feedbackTitle: 'Comentarios del usuario',
	feedbackPageLabel: 'Su comentario está relacionado con',
	feedbackFeedLabel: 'Con sus palabras, ¿cómo podemos mejorar la situación?',
	feedbackFeedPlaceholder: 'Ingrese sus comentarios aquí...',
	feedbackContactLabel: 'Si es necesario, ¿podemos contactarlo acerca de esto?',
	feedbackEmailLabel: 'Correo electrónico',
	feedbackPhoneLabel: 'Teléfono',
	feedbackMetaLabel: 'Metadatos',
	feedbackCancelButton: 'Cancelar',
	feedbackSendButton: 'Enviar comentario',
	feedbackSuccessThanks: 'Gracias por sus comentarios',
	feedbackSuccessMessage: 'Sus comentarios ayudarán a mejorar nuestro producto. Los evaluaremos detenidamente y tomaremos medidas.',
	feedbackSuccessMessageSub: 'Puede que lo contactemos por más información sobre su sugerencia.',
	feedbackSuccessMessageSign: 'El equipo de AT&T IoT Console',
	feedbackSuccessDoneButton: 'Listo',
	feedbackTriggerTitle: '¿Tiene comentarios?',
	feedbackTriggerSubtitle: 'Indíquenos aquí',
	feedbackThankYou: 'Gracias {user} por proporcionarnos sus comentarios para ayudarnos a mejorar nuestro producto.',
	feedbackSelect: 'Debe seleccionar un tema de la lista de arriba.',
	feedbackEnter: 'Debe ingresar un comentario.',
	feedbackIcon: 'Icono de envío exitoso de comentario',
	feedbackAdditionalFile: 'Archivo adicional',
	feedbackFileUploadFail: 'Falló la carga del archivo',
	feedbackMinLength: 'Ingrese al menos 10 caracteres.',
	FEEDBACK_FILE_UPLOAD_EXTENSIONS: 'Solo cargue archivos con extensión .jpg o .png',
	// Changes tab

	FIELD_NAME: 'Nombre de campo',

	DELETE_TAB: 'Borrar pestaña',
	TABLE_NAME: 'Nombre de tabla',
	EDIT_TABLE: 'Editar parámetros de tabla',
	CUSTOM_FIELD_LABEL: 'Etiqueta de campo personalizado',
	EDIT_TABLE_VIEW_NAME: 'Editar nombre de vista de tabla',
	PLEASE_ENTER_TABLE_NAME: 'Ingresar nombre de tabla',
	DEVICE_TYPE: 'Tipo de equipo',
	CELLULAR_COLUMNS: 'Parámetros específicos de celular',
	SATELLITE_SPECIFIC_COLUMNS: 'Parámetros específicos de satélite',

	// 'GESTIÓN DE TICKETS',
	TICKET_ID: 'ID de ticket',
	CATEGORY_NAME: 'Categoría',
	CATEGORY_TICKETING: 'Categoría',
	SUBCATEGORY_OPTIONAL: 'Subcategoría (opcional)',
	SELECT_SUBCATEGORY_OPTIONAL: 'Seleccionar subcategoría (opcional)',
	ROAMING_ISSUE: 'Problema de roaming',
	SEVERITY: 'Severidad',
	PRIORITY: 'Prioridad',
	ACCOUNT_NUMBER: 'Número de cuenta',
	CREATED_BY_TICKETING: 'Creado por',
	MULTI_NETWORK_CONNECT_ID: 'ID de IoT Console',
	ENDPOINT_MODEL: 'Modelo de la terminal',
	WHAT_IS_THE_ERROR_MESSAGE: '¿Cuál es el mensaje de error?',
	HOW_MANY_BARS_ARE_SHOWING: '¿Cuántas barras aparecen?',
	DESCRIBE_ENDPOINT_STATE_HERE: 'Describir el estado de la terminal aquí...',
	WAS_THE_TROUBLESHOOTING_DONE: '¿Se realizó solución de problemas?',
	DESCRIBE_TROUBLESHOOTING_DETAILS_HERE: 'Describir detalles de solución de problemas aquí…',
	DO_YOU_HAVE_ANY_ADDITIONAL_NOTES: '¿Tiene alguna nota adicional?',
	ADDITIONAL_NOTES: 'Notas adicionales',
	DASHBOARD_CHART_M2MSIMStatus_SATELLITE: 'terminales por estado IOT-C (satélite)',
	DASHBOARD_CHART_M2MSIMStatus_CELLULAR: 'terminales por estado IOT-C (celular)',
	DASHBOARD_CHART_Country_SATELLITE: 'Terminales por ubicación actual (satélite)',
	DASHBOARD_CHART_Countrys_CELLULAR: 'Terminales por ubicación actual (celular)',
	DASHBOARD_CHART_IMSI_SATELLITE: 'Terminales por plataforma M2M (satélite)',
	DASHBOARD_CHART_IMSI_CELLULAR: 'Terminales por plataforma M2M (celular)',
	DASHBOARD_CHART_Network_SATELLITE: 'Terminales por operador de red (satélite)',
	DASHBOARD_CHART_Network_CELLULAR: 'Terminales por operador de red (celular)',
	DASHBOARD_CHART_Technology: 'Terminales por tecnología',
	'DASHBOARD_CHART_IMSI Transfer Status_SATELLITE': 'Terminales por estado de transferencia (satélite)',
	'DASHBOARD_CHART_IMSI Transfer Status_CELLULAR': 'Terminales por estado de transferencia (celular)',
	DASHBOARD_CHART_countByIMSITransferStatus_SATELLITE: 'Terminales por estado de transferencia (satélite)',
	DASHBOARD_CHART_countByIMSITransferStatus_CELLULAR: 'Terminales por estado de transferencia (celular)',
	DASHBOARD_CHART_summaryOfMonthlyEndpointUsage_CELLULAR: 'Uso total de datos para todas las terminales de ACC',
	DASHBOARD_CHART_countByState_SATELLITE: 'Terminales por estado (satélite)',
	DASHBOARD_CHART_countByState_CELLULAR: 'Terminales por estado (celular)',
	DASHBOARD_CHART_countByServiceType_SATELLITE: 'Terminales por tecnología (satélite)',
	DASHBOARD_CHART_countByServiceType_CELLULAR: 'Terminales por tecnología (celular)',
	DASHBOARD_CHART_countByRatePlan_SATELLITE: 'Terminales por plan de tarifas (satélite)',
	DASHBOARD_CHART_countByRatePlan_CELLULAR: 'Terminales por plan de tarifas (celular)',
	DASHBOARD_CHART_countByNetworkEntitlement_SATELLITE: 'Terminales por permiso de red (satélite)',
	DASHBOARD_CHART_countByNetworkEntitlement_CELLULAR: 'Terminales por permiso de red (celular)',
	DASHBOARD_CHART_countByNetworkCarrier_SATELLITE: 'Terminales por operador de red (satélite)',
	DASHBOARD_CHART_countByNetworkCarrier_CELLULAR: 'Terminales por operador de red (celular)',
	DASHBOARD_CHART_countByTechnology: 'Terminales por tecnología',
	DASHBOARD_CHART_countByM2MPlatform_SATELLITE: 'Terminales por plataforma M2M (satélite)',
	DASHBOARD_CHART_countByM2MPlatform_CELLULAR: 'Terminales por plataforma M2M (celular)',
	DASHBOARD_CHART_countByIMSI_SATELLITE: 'Terminales por plataforma M2M',
	DASHBOARD_CHART_countByIMSI_CELLULAR: 'Terminales por plataforma M2M',
	DASHBOARD_CHART_countByM2mPlatform_SATELLITE: 'Terminales por plataforma M2M',
	DASHBOARD_CHART_countByM2mPlatform_CELLULAR: 'Terminales por plataforma M2M',
	DASHBOARD_CHART_countByStatebyPlatformACC_CELLULAR: 'Terminales por estado (ACC)',
	DASHBOARD_CHART_countByStatebyPlatformEOD_CELLULAR: 'Terminales por estado (EOD)',
	DASHBOARD_CHART_countByStatebyPlatformGBCM_SATELLITE: 'Terminales por estado (GBCM)',
	DASHBOARD_CHART_countByStatebyPlatformDCP_CELLULAR: 'Terminales por estado (DCP)',
	DASHBOARD_CHART_countByStatebyPlatformCCIP_CELLULAR: 'Terminales por estado (CCIP)',
	DASHBOARD_CHART_countByStatebyPlatformVCC_CELLULAR: 'Terminales por estado (VCC)',
	DASHBOARD_CHART_countByStatebyPlatformGDSP_CELLULAR: 'Terminales por estado (GDSP)',
	DASHBOARD_CHART_countByStatebyPlatformWING_CELLULAR: 'Terminales por estado (WING)',
	DASHBOARD_CHART_countByProfile_CELLULAR: 'Terminales por perfil',
	TICKET_STATUS: 'Estado del ticket',
	ENDPOINT_LOCATION: 'Ubicación de la terminal',
	ISSUE: 'Problema',
	SUGGESTIONS: 'Sugerencias',
	PHONE: 'Teléfono',
	FAILED_TO_PROVISION: 'Fallo de suministro',
	SUBMITTED_BY: 'Enviado por',
	NEW_TICKET: 'Nuevo ticket',
	ENDPOINT: 'Terminal',
	ERROR_MESSAGE: 'Mensaje de error',
	ATTACHED_FILE: 'Archivo adjunto',
	TICKETING: 'Gestión de tickets',
	GET_HELP_WITH_THIS_ENDPOINT: 'Reciba ayuda con esta terminal',
	CATEGORY_AND_SUGGESTIONS: 'Categoría y sugerencias',
	CONFIRMATION: 'Confirmación',
	RECORDED_STATUS: 'Estado registrado',
	ENDPOINT_NAME: 'ID de terminal',
	CONTACT_PHONE: 'Teléfono de contacto',
	CONTACT_EMAIL: 'Correo electrónico de contacto',
	SELECT_SUBCATEGORY: 'Seleccionar subcategoría',
	FILE_UPLOADED: 'Archivo cargado',
	FAQ_SUPPORT_INFO: 'Respuestas a las preguntas frecuentes de nuestros usuarios.',
	USER_GUIDE_SUPPORT_INFO: 'Lea las instrucciones detalladas de las funciones del portal. La guía está adaptada a su tipo de cuenta.',
	TICKETING_SUPPORT_INFO: 'Si tiene problemas con el servicio y las pruebas de diagnóstico no ofrecen una solución, puede crear un ticket del problema. ',
	MUST_BE: 'Debe tener ',
	LESS_THAN: 'menos de ',
	CHARACTERS: ' caracteres',
	LOADING_DATA: 'Cargando datos',
	GPRS: 'GPRS',

	//  'Asistencia',
	CONTENT: 'Contenido',
	CREATE_NEW_NOTIFICATION: 'Crear notificación nueva',
	EDIT_NOTIFICATION: 'Editar notificaciones',
	EDIT_FORM_TO_CREATE_NOTIFICATION: 'Editar formulario para crear notificación nueva.',
	NOTIFICATION_TYPE: '¿Tipo de notificación?',
	SELECT_NOTIFICATION_PLACEHOLDER: 'Seleccionar marcador de posición de notif.',
	CHOOSE_WHICH_USER_GROUPS_TO_NOTIFY: 'Elegir a qué grupos de usuario notificar:',
	NOTIFY_ALL_GROUPS: 'Notificar a todos los grupos',
	MANAGER: 'Gerente',
	DEVELOPER: 'Diseñador',
	ADD_NOTIFICATION_CONTENT: 'Ingresar contenido de notificación',
	SUSPEND: 'Suspender',
	SUSPENDED: 'Suspendido',
	DEACTIVE: 'Desactivado',
	PROVISIONED: 'Suministrado',
	ENDPOINT_COUNTRY: 'País de la terminal',
	ENDPOINT_STATE: 'Estado de la terminal',
	ENDPOINT_CITY: 'Ciudad de la terminal',
	ENDPOINT_STREET_ADDRESS: 'Dirección de la terminal',
	AS_OF: 'Procesado al',
	VIEW_ALL_NOTIFICATIONS: 'Ver todas las notificaciones',
	NOTIFICATION_SCOPE: 'Alcance de la notificación',
	NOTIFICATION1: 'Info.',
	NOTIFICATION2: 'Error',
	NOTIFICATION3: 'Advertencia',
	NOTIFICATION4: 'Confirmación',
	GLOBAL_NOTIFICATION: 'Notificación global',
	COMPANY_RELATED_NOTIFICATION: 'Notificación de la empresa',
	MUST_BE_MORE_THAN: 'Debe tener más de ',
	MUST_BE_LESS_THAN: 'Debe tener menos de ',
	MUST_BE_LESS_THAN_OR_EQUAL_TO: 'Debe tener menos o igual que ',
	GLOBAL: 'Global',
	COMPANY_RELATED: 'Relacionado con la empresa',
	RECENT_NOTIFICATIONS: 'Alertas y notificaciones del sistema',

	networkEntitlement: 'ID de permiso de red',
	networkType: 'Tipo de red',
	transferStatus: 'Transferencia de estado IMSI',
	rateplan: 'Plan de tarifas',
	FAILURE: 'Fallo',
	PENDING_REIMSI_ATTEMPT: 'Intento de Re-IMSI pendiente',
	REIMSI_QUEUED: 'Re-IMSI en espera',
	PENDING_REIMSI_DELIVERED: 'Re-IMSI enviado pendiente',
	'PENDING_RE-IMSI_DELIVERY_FAILED': 'Fallo de envío de Re-IMSI pendiente',

	'PENDING REIMSI ATTEMPT': 'Intento de Re-IMSI pendiente',
	'REIMSI PENDING ATTEMPT': 'Intento de Re-IMSI pendiente',
	'PENDING REIMSI DELIVERED': 'Transferencia pendiente enviada',
	'Re-IMSI Delivery Failed': 'Fallo de envío de transferencia',
	'REIMSI FAILED': 'Fallo de Re-IMSI',
	'REIMSI QUEUED': 'Re-IMSI en espera',
	'REIMSI PEDING ATEMPT': 'Intento de Re-IMSI pendiente',
	'REIMSI PENDING ATEMPT': 'Intento de Re-IMSI pendiente',
	'REIMSI PENDING DELIVERED': 'Re-IMSI enviado pendiente',
	'REIMSI SUCCESS': 'Re-IMSI realizado',
	failure: 'Fallo',

	'ID_PENDING REIMSI ATTEMPT': 'Intento de Re-IMSI pendiente',
	'ID_REIMSI PENDING ATTEMPT': 'Intento de Re-IMSI pendiente',
	'ID_PENDING REIMSI DELIVERED': 'Transferencia pendiente enviada',
	'ID_Re-IMSI Delivery Failed': 'Fallo de envío de transferencia',
	'ID_REIMSI FAILED': 'Fallo de Re-IMSI',
	'ID_REIMSI QUEUED': 'Re-IMSI en espera',
	'ID_REIMSI PEDING ATEMPT': 'Intento de Re-IMSI pendiente',
	'ID_REIMSI PENDING ATEMPT': 'Intento de Re-IMSI pendiente',
	'ID_REIMSI PENDING DELIVERED': 'Re-IMSI enviado pendiente',
	'ID_REIMSI SUCCESS': 'Re-IMSI realizado',

	CONSUMED_BYTES: 'MB consumidos',
	CONSUMED_SMSES: 'SMS consumidos',
	TO_DATE: 'hasta la fecha',

	CHART_EXISTS_IN_SETTINGS: 'Este gráfico ya existe en sus parámetros. Solo se permite un tipo de gráfico.',
	NO_CHART_DATA: 'Datos de gráfico faltantes',
	STATES_MESSAGE: 'No hay estado disponible',
	SELECT_RATE_PLAN: 'Seleccionar plan de tarifas',
	CHANGE_RATE_PLAN: 'Cambiar plan de tarifas',
	CURRENT_RATE_PLAN_FOR_ENDPOINT_ID: 'Plan de tarifas actual para ID de terminal',
	IS: 'es',
	CONFIRM_RATE_PLAN_CHANGE: 'Confirmar cambio de plan de tarifas',
	RATE_PLAN_WILL_BE: 'Cuando inicie este cambio, el plan de tarifas de la terminal seleccionada será',
	WOULD_YOU_LIKE_TO_INITIATE_CHANGE_NOW: '¿Desea iniciar el cambio ahora?',
	RATE_PLAN_CHANGE_IS_SUCCESSFULLY_SCHEDULED: 'Cambio de plan de tarifas fijado con éxito',
	FOR: 'para',
	RATE_PLAN_CHANGE_IS_SUCCESSFULLY_UPDATED: ' Cambio de plan de tarifas actualizado con éxito',
	RATE_PLAN_CHANGE_COULD_NOT_BE_SCHEDULED_SUCCESSFULLY: '¡El cambio de plan de tarifas no se pudo fijar con éxito!',
	RATE_PLAN_CHANGE_COULD_NOT_BE_CARRIED_OUT_SUCCESSFULLY: 'El cambio de plan de tarifas no se pudo hacer con éxito',
	ENDPOINT_RATE_PLAN: 'Plan de tarifas',
	DOWNLOAD_TEMPLATE_BATCH: 'Descargar plantilla',
	CONFIRM_BATCH_FILE_UPLOAD: 'Confirmar carga de archivo para lote',
	SCHEDULED_ACTION_ON: 'Acción fijada para el',
	FILE_MUST_BE_SUMITTED_WITH_CSV: 'Los archivos deben enviarse con la extensión .csv.',
	MAXIMUM_SIMS_PER_UPLOAD: 'SIM máximas por carga: 10 000.',
	SIMS_HAVE: 'SIM tienen',
	CHANGE_FOR_ALL_SELECTED_SIMS: 'Elegir otro plan de tarifas para aplicar cambio a todas las SIM seleccionadas',
	YOU_HAVE_CHOSEN_TO_CHANGE_THE_RATE_PLAN_OF_THE: 'Eligió cambiar plan de tarifas de las',
	SELECTED_SIMS_TO: 'SIM seleccionadas a',
	SIMS_RATE_PLAN_SUCCESSFULLY_REQUESTED: 'Planes de tarifas de SIM actualizados con éxito',
	SCHEDULE_RATE_PLAN_SUCCESSFUL: 'Plan de tarifas de varias SIM fijadas con éxito',
	SIM_RATE_PLAN_CHANGE_FOR: 'Cambio de plan de tarifas de SIM para',
	MULTIPLE_RATE_PLAN_CHANGE_SCHEDULE_ERROR: 'El cambio de plan de tarifas de SIM múltiples no se pudo fijar con éxito',
	MULTIPLE_RATE_PLAN_CHANGE_ERROR: 'El cambio de plan de tarifas de SIM múltiples no se pudo solicitar con éxito',
	SAME_RATE_PLAN_SELECTED: 'Mismo plan de tarifas seleccionado',
	REASON: 'motivo',

	API_DOCUMENTATION: 'Documentación de API',
	'Cellular - Active': 'Celular - Activado',
	'Satellite - Active': 'Satélite - Activado',
	'Cellular - Terminate': 'Celular - Cancelado',
	'Satellite - Terminate': 'Satélite - Cancelado',
	CHANGE_RATE_PLAN_NOT_ALLOWED: 'No se permite cambiar plan de tarifas',
	reimsiqueued: 'Re-IMSI en espera',
	reimsifailed: 'Fallo de Re-IMSI',
	reimsisuccess: 'Re-IMSI realizado',
	reimsipendingattempt: 'Intento de Re-IMSI pendiente',
	reimsipendingdelivered: 'Re-IMSI enviado pendiente',
	imsiPendingMessage: 'Si el equipo está en estado pendiente por más de 72 h, contacte a Atención al cliente',
	ADD_ANOTHER_ACCOUNT: 'Agregar otra cuenta',
	FILTER_DOES_NOT_HAVE_VALID_DATA: 'Error de API, busque asistencia.',
	FAILOVER: 'Conmutación por error',
	CANCEL_PENDING_FAIL_TITLE: 'Fallo de cancelación de cambio pendiente',
	CANCEL_PENDING_FAIL_TEXT: 'La cancelación de cambio pendiente no se pudo completar con éxito en este momento',
	CANCEL_PENDING_SUCCESS_TITLE: 'Éxito en la cancelación de cambio pendiente',
	CANCEL_PENDING_SUCCESS_TEXT: 'Cancelación de cambio pendiente realizada con éxito',
	CANCEL_CHANGE: 'Cancelar cambio',
	CAUTION: 'Precaución',
	CANCEL_PROMPT_TITLE: '¿Está seguro de que quiere cancelar el cambio pendiente?',
	CANCEL_PROMPT_TEXT: '¡Esta acción no puede revertirse!',
	CONFIRM_CANCELLATION: 'Confirmar cancelación',
	NO_RATE_PLANS_AVAILABLE: 'No hay planes de tarifas disponibles',
	BULK_UPLOAD: 'Carga masiva',
	MULTI_ENDPOINT_ACTION: 'Acción de terminales múltiples',
	PLEASE_SELECT_MORE_SIMSS: 'Seleccione dos o más terminales',
	M2M_ACCOUNT_ID: 'ID de cuenta M2M',
	REPORTS_PLATFORM: 'Plataforma M2M',
	ENDPOINT_CHART_SECOND_CARD: 'Sesiones recientes',
	ENDPOINT_CHART_SECOND_CARD_ON: 'El',
	ENDPOINT_CHART_THIRD_CARD: 'Ubicación de red de terminal',
	NO_AVAILABLE_FILTERS: 'No hay filtros disponibles.',
	NO_AVAILABLE_FILTERS_SUBTITLE: 'No hay No hay filtros disponibles para mostrarle ahora',

	// 'Diseñador API',
	GETTING_STARTED: 'Para comenzar.',
	API_SANDBOX: 'Entorno de pruebas API',
	DEVELOPER_API: 'Diseñador API',
	GETTING_STARTED_WITH_API_T: 'Para comenzar con la API.',
	GETTING_STARTED_WITH_API_P: 'Antes de que pueda hacer la primera llamada a la API, la dirección de IP de origen (servidor) debe agregarse a la lista blanca. El IP de origen normalmente se configura durante el proceso de incorporación cuando se crea el acceso a la API. Si recibe algún error, es posible que se relacione al bloqueo de acceso a la pasarela IoT de AT&T.',
	GETTING_STARTED_WITH_API_UL_T: 'Asegúrese de seguir los siguientes pasos para comenzar el desarrollo:',
	GETTING_STARTED_WITH_API_LI1: '- Configure un servidor de acceso público y proporcionarnos la dirección de IP en el proceso de incorporación.',
	GETTING_STARTED_WITH_API_LI2: '- Agregue el IP de origen a la lista blanca para acceder a la pasarela IoT de AT&T.',
	GETTING_STARTED_WITH_API_LI3: '- El admin. de su empresa debe darle su nombre de usuario y contraseña API que se haya creado al habilitar el servicio API.',
	GETTING_STARTED_WITH_API_LI4: '- Obtenga acceso a su servidor de recursos para ejecutar comandos curl o usar aplicaciones de conexión escritas en su lenguaje preferido a continuación.',
	GET_OAUTH_TOKEN_T: 'Obtener token de acceso OAuth',
	GET_OAUTH_TOKEN_P: 'Obtenga el token de acceso OAuth de AT&T IoT Identity usando el nombre de usuario y contraseña suministrado por su admin. mediante su método preferido a continuación. El token es válido por 7 días.',
	GETTING_STARTED_WITH_API_CONNECTORS_T: 'Conectores API',
	GETTING_STARTED_WITH_API_CONNECTORS_P: 'Elija entre los ejemplos de abajo cómo quiere conectarse a su API.',
	TEST_CONNECTION_T: 'Probar la conexión a la pasarela IoT',
	TEST_CONNECTION_P: 'Ejemplo de curl:',
	GETTING_STARTED_WITH_API_NEXT_STEP_T: 'Resultado deseado',
	GETTING_STARTED_WITH_API_NEXT_STEP_P: 'Si recibe el código de respuesta HTTP 200 OK, ya está listo para comenzar el desarrollo.',
	GETTING_STARTED_WITH_API_ERRORS_T: 'Solución de problemas de errores de conexión',
	GETTING_STARTED_WITH_API_ERRORS_P: 'Si recibe algún error, primero verifique si su permiso está configurado correctamente en la cabecera HTTP, y que el token de acceso no se haya vencido y deba actualizarse. Compruebe la disponibilidad de la pasarela IoT de AT&T para verificar que el IP de origen esté en la lista blanca. Si se producen resultados inesperados, cree un ticket de solución de problemas en la sección de asistencia.',
	API: 'API',
	SANDBOX_SPECIFIES_THE_LIST: 'El entorno de pruebas Swagger de abajo especifica la lista de recursos disponibles en REST API y las operaciones que pueden invocar en dichos recursos. El documento también detalla la lista de parámetros por operación, incluidos el nombre y tipo de parámetro, si los parámetros son obligatorios u opcionales e información sobre los valores aceptables de dichos parámetros.',
	JSON_SCHEMA_INCLUDES: 'Se incluye el esquema JSON que describe la estructura del cuerpo de la solicitud que se envía a una operación en un REST API junto con el esquema JSON que describe la estructura de cualquier cuerpo de respuesta de retorno de una operación.',

	GBCM_OPERATOR: 'Speedcast',
	ATT_OPERATOR: 'AT&T',

	CREATE_CUSTOM_REPORT: 'Crear informe personalizado',
	YOU_MUST_CREATE_FILTER_BEFORE_EXPORT: 'Para poder exportar una lista, debe crear un filtro.',
	CREATE_REPORT: 'Crear informe',
	CHART_LEGEND_Active: 'Activado',
	CHART_LEGEND_Terminate: 'Cancelado',
	WE_ARE_SORRY_THERE_IS_NO_DATA_TO_EXPORT: 'Lamentablemente no hay datos para exportar',
	EXPORT_FILTERED_DATA: 'Exportar datos filtrados',
	ID_DASHBOARD_CHARTS_summaryofmonthlyendpointusage: 'Resumen de uso mensual de terminal',
	ID: 'ID',
	TAB: 'Pestaña',
	SORT_BY: 'Ordenado por',
	SELECT_CHART_TYPE_TO_ADD: 'Seleccionar tipo de gráfico para agregar',
	RECREDENTIAL_STATUS: 'Estado de reacreditación',
	RECREDENTIAL_TRANSFER_STATUS: 'Estado de reacreditación',
	recredentialTransferStatus: 'Estado de reacreditación',
	M2MACCOUNTID: 'ID de cuenta M2M',
	HOLDING_ACCOUNT_ID: 'Retención de ID de cuenta',
	RE_CREDENTIAL: 'Reacreditación',
	SELECT_OPERATOR: 'Seleccionar operador',
	RE_CREDENTIAL_SIM: 'Reacreditar SIM',
	CURRENT_PLATFORM: 'Plataforma actual',
	YOU_HAVE_CHOSEN_TO_RECREDENTIAL_THIS_SIM: 'Eligió reacreditar esta SIM a',
	YOU_HAVE_CHOSEN_TO_RECREDENTIAL_THESE_SIMS: 'Eligió reacreditar estas SIM a',
	YOU_MAY_INTIATE_THE_CHANGE_NOW: 'Puede iniciar el cambio ahora',
	RE_CREDENTIALING_STATUS: 'Estado de reacreditación',
	RE_CREDENTIALING_IS_SUCCESSFULLY_INITIATED: 'Reacreditación iniciada con éxito',
	RE_CREDENTIALING_IS_SUCCESSFULLY_SCHEDULED: 'Reacreditación fijada con éxito',
	RE_CREDENTIALING_IS_SUCCESSFULLY_SCHEDULED_FOR: 'Reacreditación fijada con éxito para',
	RE_CREDENTIALING_COULD_NOT_BE_INITIATED_SUCCESSFULLY: 'No se pudo iniciar con éxito la reacreditación',
	RE_CREDENTIALING_COULD_NOT_BE_SCHEDULED_SUCCESSFULLY: 'No se pudo fijar con éxito la reacreditación',
	ENDPOINT_HISTORY: 'Historial de terminal',
	SHIPPED_DATE: 'Fecha de envío',
	DIAGNOSTIC_BUTTON: 'Iniciar diagnóstico',
	DEVICE_REGISTRATION_INFO_BUTTON: 'Info. de registro de equipo',
	ENDPOINT_REGISTRATION_DETAILS: 'Detalles de registro de terminal',
	eventTime: 'Registro de fecha y hora',
	carrierName: 'Nombre del Operador',
	registrationType: 'Tipo de registro',
	NO_DATA_TO_DISPLAY_AT_THE_MOMENT: 'No hay datos para mostrar ahora',
	status: 'Estado',
	iccid: 'ICCID',
	carrier: 'Operador',
	ATTACHED_CARRIER_NAME: 'Nombre del operador adjunto',
	M2MPlatform: 'Plataforma M2M',
	simState: 'Obtener estado del equipo',
	'Endpoint Provisioning Information': 'Info. de suministro de terminal',
	'M2M Platform': 'Plataforma M2M',
	'Network Connection': 'Conexión de red',
	simStatusM2M: 'Obtener estado del equipo',
	billingStatus: 'Obtener estado del equipo',
	EndpointProvisioningInformation: 'Info. de suministro de terminal',
	trafficAllowed: 'Obtener info. de suministro de equipo',
	permitPassingTraffic: 'Obtener info. de suministro de equipo',
	verifySimState: 'Obtener info. de equipo',
	NetworkConnection: 'Conexión de red',
	verifyConnection: 'Obtener red de equipo',
	successfullyConnectedToNetwork_inSession: 'Obtener red de equipo',
	verifyIPSession: 'Obtener IP del equipo',
	dataSessionCurrentlySuccessful: 'Obtener IP del equipo',
	dd_inventory: 'Inventario',
	dd_activated: 'Activado',
	dd_active: 'Activado',
	dd_inactivated: 'Inactivo',
	dd_inactive: 'Inactivo',
	dd_deactivated: 'Desactivado',
	dd_retired: 'Retirado',
	dd_purged: 'Depurado',
	dd_testready: 'Prueba lista',
	dd_activationready: 'Activación lista',
	dd_replaced: 'Reemplazado',
	dd_trial: 'Prueba',
	dd_start: 'Comenzar',
	dd_transfer: 'Transferencia',
	dd_globalintransitsource: 'Fuente global en tránsito',
	dd_globalintransitdestination: 'Destino global en tránsito',
	dd_globaltransferred: 'Global transferido',
	dd_other: 'Otro',
	dd_permitpassingtraffic: 'Paso de tráfico permitido',
	dd_notpermitpassingtraffic: 'Paso de tráfico no permitido',
	dd_allowedToUseWirelessNetworkResources: 'Recursos inalámbricos TollSec permitidos',
	dd_barredFromNetwork: 'Excluido de la red',
	dd_trafficnotallowed_simstate: 'Tráfico no permitido de estado de SIM',
	dd_suspendedByServiceProvider: 'Suspendido por proveedor de servicio',
	dd_suspendedDueToUsageLimit: 'Suspendido por límite de uso',
	dd_trafficNotAllowed_others: 'Tráfico no permitido (otro)',
	dd_successfullyconnectedtonetwork_insession: 'Conectado a la red con éxito (en sesión)',
	dd_notsuccessfullyconnectedtonetwork_insession: 'No conectado a la red (en sesión)',
	dd_validregistration_validnetworkactivity: 'Registro válido (Actividad de red válida)',
	dd_validregistration_nonetworkactivity: 'Registro válido (No hay actividad de red)',
	dd_validgsmregistrationonly: 'Solo registro GSM válido',
	dd_novalidregistration_hasactivityinlast24h: 'No hay registro válido (Tiene actividad en las últimas 24 h)',
	dd_novalidregistration_hasauthrequestinlast24h_nolocupdate: 'No hay registro válido (Tiene solicitud de permiso en las últimas 24 h)',
	dd_novalidregistration_haslocupdatesmorethan24hago: 'No hay registro válido (Tiene actualizaciones locales de hace más de 24 h)',
	dd_novalidregistration_hasauthrequestmorethan24hago_noactivity: 'No hay registro válido (Tiene solicitud de permiso hace más de 24 h)',
	dd_novalidregistration_nonetworkactivity: 'Hace más de 24 h (No hay actividad de red)',
	dd_datasessioncurrentlysuccessful: 'El dispositivo no está conectado actualmente. Las sesiones anteriores de IP tuvieron éxito.',
	dd_notdatasessioncurrentlysuccessful: 'Sesión de datos actual sin éxito',
	dd_datasessionpastsuccessful: 'Sesión de datos pasada con éxito',
	dd_connectionattemptfailed_invalidcredentials: 'Intento fallido de conexión (Credenciales inválidas)',
	dd_nodatasessionsuccessfulhistory: 'No hay historial de sesión de datos con éxito',
	dd_: '',
	dd_allowedtousewirelessnetworkresources: 'Recursos inalámbricos TollSec permitidos',
	attached: 'Conectado',
	detached: 'Desconectado',
	RECREDENTIALED: 'Reacreditado',
	RECREDENTIALING: 'Reacreditado',
	CHANGE_RECREDENTIAL: 'Solo se permite la reacreditación para las mismas plataformas',
	RECREDENTIAL_DESCRIPTION: 'Consejo: Cuando reacredite y seleccione varias SIM, solo puede seleccionar una plataforma de reacreditación para las SIM elegidas',
	RECREDENTIAL_NOT_ALLOWED: 'Reacreditación no permitida',
	RECREDENTIAL_SUCCESSFULLY_REQUESTED: 'SIM iniciadas con éxito',
	MULTIPLE_RECREDENTIAL_CHANGE_ERROR: 'No se pudo iniciar con éxito la reacreditación de varias SIM',
	MOBILE_NETWORK_REGISTRATION_HISTORY: 'Historial de registro de red móvil',
	ENDPOINT_DAILY_USAGE: 'Uso diario de terminal',
	ENDPOINT_CYCLE_USAGE: 'Uso de terminal en el ciclo hasta la fecha',
	CONFIRM_RE_CREDENTIALING: 'Confirmar reacreditación',
	PENDING_RECREDENTIAL: 'Esta terminal está pendiente de reacreditación',
	ENDPOINTS_ARE: 'terminales seleccionadas para exportar',
	ENDPOINT_IS: 'terminal seleccionada para exportar',
	CUSTOM_REPORT_WILL_BE_AVAILABLE: 'El informe personalizado aparecerá en la sección de informes',
	YOU_CAN_APPEND_CUSTOM_NAME: 'Puede agregar aquí el nombre personalizado para el informe',
	CUSTOM_REPORT_NAME_APPENDIX: 'Apéndice de nombre de informe personalizado',
	ADD_CUSTOM_REPORT_NAME_APPENDIX: 'Agregar apéndice de nombre de informe personalizado (solo dígitos y letras)',
	CUSTOM_REPORT: 'Informe personalizado',
	CUSTOM_REPORT_CREATED: 'Informe personalizado creado con éxito',
	GO_TO_REPORTS: 'Ir a informes',
	DIAGNOSTICS_FOR_THIS_ENDPOINT: 'Los diagnósticos para esta terminal no están disponibles ahora',
	ENDPOINT_DATA_INFORMATION: 'Información de datos de terminal',
	LAST_SESSION: 'Sesión reciente',
	CARRIER_ATTACHED: 'Operador adjunto',
	APN_DCP: 'APN',
	IP_ADDRESS: 'Dirección de IP',
	LAST_SENT_SMS_MO: 'Fecha y hora de último SMS MO enviado',
	LAST_SENT_SMS_MT: 'Fecha y hora de último SMS MT enviado',
	TEST_DETAILS: 'Detalles de la prueba',
	CHECK_BLOCKED_STATUS: 'Verificar estado bloqueado',
	BLOCKED_STATUS: 'Estado bloqueado',
	RETRY_TEST: 'Reintentar prueba',
	MOBILE_NETWORK_TEST: 'Prueba de red móvil',
	CHECK_BLOCKED_SUB: 'Verificar suscripción bloqueada',
	TROUBLESHOOT_DCP: 'Solucionar problema de DCP',
	locationUpdate: 'Actualización de ubicación',
	pdpStatus: 'Estado PDP',
	cancelLocation: 'Cancelar ubicación',
	CANCEL_LOCATION: 'Cancelar ubicación',

	DASHBOARD_CHART_CountofDailyEndpointSMSbyPlatformDCP_CELLULARDescription: 'Muestra la cant. total de mensajes SMS enviados por día cada mes en la plataforma DCP',
	DASHBOARD_CHART_CountofDailyEndpointSMSbyPlatformDCP_CELLULAR: 'SMS por día (DCP)',
	DASHBOARD_CHART_CountofMonthlyEndpointSMSbyPlatformDCP_CELLULAR: 'SMS per mes (DCP)',
	DASHBOARD_CHART_SumofDailyEndpointUsagebyPlatformDCP_CELLULAR: 'Uso total diario de terminal (DCP)',
	DASHBOARD_CHART_SumofDailyEndpointUsagebyPlatformACC_CELLULAR: 'Uso diario de datos (ACC)',
	DASHBOARD_CHART_SumofMonthlyEndpointUsagebyPlatformDCP_CELLULAR: 'Uso mensual de datos (DCP)',
	DASHBOARD_CHART_CountofMonthlyEndpointSMSbyPlatformDCP_CELLULARDescription: 'Muestra la cant. total de mensajes SMS enviados por mes en la plataforma DCP',
	DASHBOARD_CHART_SumofDailyEndpointUsagebyPlatformDCP_CELLULARDescription: 'Muestra el uso total de datos por día cada mes en la plataforma DCP',
	DASHBOARD_CHART_SumofDailyEndpointUsagebyPlatformACC_CELLULARDescription: 'Muestra el uso total de datos por día cada mes en la plataforma ACC',
	DASHBOARD_CHART_SumofMonthlyEndpointUsagebyPlatformDCP_CELLULARDescription: 'Muestra el uso total de datos por mes en la plataforma DCP',
	DASHBOARD_CHART_CountofMonthlyEndpointSMSbyPlatformCCIP_CELLULAR: 'SMS por mes (CCIP)',
	DASHBOARD_CHART_CountofMonthlyEndpointSMSbyPlatformCCIP_CELLULARDescription: 'Muestra la cant. total de mensajes SMS enviados por mes en la plataforma CCIP',
	DASHBOARD_CHART_CountofDailyEndpointSMSbyPlatformCCIP_CELLULAR: 'SMS por día (CCIP)',
	DASHBOARD_CHART_CountofDailyEndpointSMSbyPlatformCCIP_CELLULARDescription: 'Muestra la cant. total de mensajes SMS enviados por día en la plataforma CCIP',
	DASHBOARD_CHART_SumofDailyEndpointUsagebyPlatformCCIP_CELLULAR: 'Uso total diario de terminal (CCIP)',
	DASHBOARD_CHART_SumofDailyEndpointUsagebyPlatformCCIP_CELLULARDescription: 'Muestra el uso total de datos por día cada mes en la plataforma CCIP',
	DASHBOARD_CHART_SumofMonthlyEndpointUsagebyPlatformCCIP_CELLULAR: 'Uso mensual de datos (CCIP)',
	DASHBOARD_CHART_SumofMonthlyEndpointUsagebyPlatformCCIP_CELLULARDescription: 'Muestra el uso total de datos por mes en la plataforma CCIP',
	DASHBOARD_CHART_countByCountry_CELLULAR: 'Terminales por país',
	DASHBOARD_CHART_countByCountry_CELLULARDescription: 'Muestra la cant. de terminales en múltiples países.',
	DASHBOARD_CHART_countByRoamingCountry_CELLULAR: 'Terminales por país de roaming (celular)',
	DASHBOARD_CHART_countByRoamingCountry_CELLULARDescription: 'Muestra la cant. de terminales por país de roaming',
	DASHBOARD_CHART_countByRoamingCarrier_CELLULAR: 'Terminales por operador de roaming (celular)',
	DASHBOARD_CHART_countByRoamingCarrier_CELLULARDescription: 'Muestra la cant. de terminales por operador de roaming.',
	DASHBOARD_CHART_countByRoamingCarrier_SATELLITE: 'Terminales por operador de roaming (satélite)',
	DASHBOARD_CHART_countByRoamingCarrier_SATELLITEDescription: 'Muestra la cant. de terminales de satélite por operador de roaming.',

	SMS_HISTORY: 'Historial de SMS',
	MAXIMUM_NUMBER_OF_CHARACTERS: 'Cant. máxima de caracteres: ',
	SEND_SMS_MESSAGE: 'Enviar SMS',
	SMS_NOTIFICATION_TITLE: 'Notificación SMS',
	SMS_NOTIFICATION_MESSAGE: 'Respuesta de error:',
	SMS_NOTIFICATION_MESSAGE_SUCCESS: 'SMS enviado con éxito',

	REVIEW: 'Revisar',
	PLATFORM_DETAILS: 'Detalles de plataforma',

	UPDATING: 'Actualizando',
	AUTOMATION_RULE_SUCCESSFULLY_DELETED: 'Se borró con éxito la regla de automatización',
	AUTOMATION_DELETE_ERROR: 'Hubo un fallo al borrar la regla de automatización. Vuelva a intentarlo nuevamente.',
	FETCH_DATA_ERROR: 'No se puedo buscar datos',
	CHANGED_BY: 'Cambiado por',
	EMAIL_SUB: 'Enviar correo electrónico',
	CTD_USAGE: 'Ciclo hasta la fecha de uso de datos',
	PAST24H_DATA_USAGE_EXCEEDED: 'Se superó el uso de datos en las últimas 24 h',
	SMS_USAGE: 'Uso de SMS en el ciclo hasta la fecha',
	EXCEEDLIMIT: 'Supera',
	APPROACHLIMIT: 'Se acerca',
	trigger: 'Activador',
	TWENTYFOURHOURS: 'Últimas 24 h',
	CTD: 'Ciclo hasta la fecha',
	ADD_RULE_SUCCESS: 'Regla creada',
	ADD_RULE_ERROR: 'Fallo al crear la regla de automatización. Vuelva a intentarlo nuevamente.',
	EDIT_RULE_SUCCESS: 'Regla editada',
	EDIT_RULE_ERROR: 'Hubo un fallo al editar la regla de automatización. Vuelva a intentarlo nuevamente.',
	ACC_AUTOMATION: 'Automatización de ACC',
	ACC_ACCOUNT_ID: 'ID de cuenta de ACC',
	CREATE_NEW_AUTOMATION_RULE: 'Crear nueva regla de automatización',
	DCP_AUTOMATION: 'Automatización de DCP',
	DCP_ACCOUNT_ID: 'ID de cuenta de DCP',
	ACC_ACCOUNT: 'Cuenta de ACC',
	RULE: 'Regla',
	ACC_IF: 'Si',
	TRESHOLD: 'Umbral',
	THEN: 'Luego',
	ACC_RULE_NAME: 'Nombre de regla',
	SAVE_AND_ACTIVATE: 'Guardar y activar',
	DCP_ACCOUNT: 'Cuenta de DCP',
	SELECT_BY: 'Seleccionar por',
	DCP_BYTES_SENT: 'bytes enviados',
	SMSES_SENT: 'SMS enviados',
	SMSES: 'SMS',
	DASHBOARD_FEEDBACK: 'Panel de control',
	MUST_BE_BETWEEN: 'Debe tener entre ',
	COMPANIES_STEPS: 'Pasos de la empresas',
	USER_NAME: 'NOMBRE DEL USUARIO',
	ID_DASHBOARD_CHARTS_countofmonthlyendpointsmsbyplatformdcp: 'SMS por mes',
	ID_DASHBOARD_CHARTS_countofdailyendpointsmsbyplatformdcp: 'SMS por día',
	ID_DASHBOARD_CHARTS_sumofdailyendpointusagebyplatformdcp: 'Uso total diario de terminal',
	ID_DASHBOARD_CHARTS_sumofdailyendpointusagebyplatformacc: 'Uso de datos diario',
	ID_DASHBOARD_CHARTS_sumofmonthlyendpointusagebyplatformdcp: 'Uso mensual de datos',
	DASHBOARD_CHART_DeviceType_SATELLITE: 'Tipo de equipo (satélite)',
	DASHBOARD_CHART_DeviceType_CELLULAR: 'Tipo de equipo (celular)',
	DASHBOARD_CHART_DeviceType_SATELLITEDescription: 'Muestra la cant. total de equipos de diferentes tipos',
	DASHBOARD_CHART_DeviceType_CELLULARDescription: 'Muestra la cant. total de equipos de diferentes tipos',
	AUTOMATION_ALERTS: 'Alertas de automatización',
	STATE: 'Estado',
	COMPANY_EMAIL: 'Correo electrónico de la empresa',
	CUSTOM_FIELD_LABELS: 'Etiquetas de campo personalizado',
	MY_COMPANY: 'Mi empresa',
	AUTOMATION_ACCOUNT: 'Cuenta',
	UPDATE_CUSTOM_FIELD_LABELS_SUCCESS: 'Parámetros de etiquetas personalizadas guardados',
	UPDATE_CUSTOM_FIELD_LABELS_ERROR: 'No se pudo guardar los parámetros de etiqueta personalizada',
	WRITE_SMS_MESSAGE_HERE: 'Escriba el mensaje aquí...',
	RE_CREDENTIALING_ALLOWED: 'Reacreditación no permitida',
	DELETE_AUTOMATION_RULE_QUESTION: '¿Borrar regla de automatización?',
	DELETE_AUTOMATION_RULE_MESSAGE: '¿Está seguro de que quiere borrar la regla de automatización ',
	PLATFORM_SUCCESSFULLY_DELETED: 'Se borró con éxito la plataforma',
	PLATFORM_DELETE_ERROR: 'Hubo un fallo al borrar la plataforma, inténtelo nuevamente.',
	CELLULAR: 'Celular',
	SATELLITE: 'Satélite',
	SAVE_CUSTOM_LABELS: 'Guardar etiquetas personalizadas',

	// ccip
	ENDPOINT_PROVISIONING_INFORMATION: 'Info. de suministro de terminal',
	CCIP_3G: '3G',
	VOICE_ENABLED: 'Compatible con voz',
	DATA_ENABLED: 'Compatible con datos',
	SMS_ENABLED: 'Compatible con SMS',
	VOICE_ROAMING_ENABLED: 'Compatible con roaming de voz',
	DATA_ROAMING_ENABLED: 'Compatible con roaming de datos',
	CCIP_LTE: 'LTE',
	LTE_ENABLED: 'Compatible con LTE',
	ROAMING_ENABLED: 'Compatible con roaming',
	MOBILE_NETWORK_ACCESS: 'Acceso de red móvil',
	VOICE_ONLY: 'Solo voz',
	REGISTERED_ON_NETWORK: 'Registrado en red',
	REGISTERED_ON_3G_NETWORK: 'Registrado en red 3G',
	THIS_EMAIL_ADDRESS_IS_ALREADY_IN_USE: 'Este correo electrónico ya está en uso',
	THIS_EMAIL_ADDRESS_DOES_NOT_EXIST: 'Este correo electrónico no existe',
	USER_SUCCESSFULLY_DELETED: 'Se borró el usuario con éxito',
	USER_DELETE_ERROR: 'Fallo al borrar el usuario, inténtelo nuevamente.',
	CATEGORIES: 'Categorías',
	TRIGGERS: 'Activadores',
	FETCH_DATA_LOADING_MESSAGE: 'Espere mientras cargamos los datos',
	M2M_ACCOUNT_ID_PLATFORM: 'ID de cuenta M2M',
	M2MACCOUNTS: 'Cuenta M2M',

	FROM_VALUE: 'VALOR DE',
	TO_VALUE: 'VALOR A',

	SEARCH_REPORTS: 'Buscar informes',
	SEARCH_INVOICES: 'Buscar facturas',

	CUSTOM_DATA_FIELDS: 'Campos de datos personalizados',
	ENDPOINT_ACTIONS: 'Acciones de terminales de',
	RE_CREDENTIAL_NOT_ALLOWED: 'Reacreditación no permitida',
	EDIT_ENDPOINT: 'Acciones de terminales',
	CURRENT_SIM_STATE_FOR_ENDPOINT_ID: 'Estado actual de SIM para ID de terminal',

	NETWORK_ENTITLEMENT_NAME: 'Nombre de permiso de red',
	BLOCK_PREMIUM_NUMBERS: 'Bloquear números premium',
	BLOCK_INTERNATIONAL_MO: 'Bloquear SMS MO internacional excepto en país de origen',
	HLR_TEMLATE: 'Plantilla de Detalles HLRHSS',
	HOTLINE_NUMBER: 'Número de tel. directo',
	FACETIME: 'FACETIME',
	VOLTE: 'Volte',
	GDSP_SESSION_ACTIVE: 'Activo',
	GDSP_SESSION_BYTES_IN: 'Bytes de entrada',
	GDSP_SESSION_BYTES_OUT: 'Bytes de salida',
	GDSP_SESSION_LAST_IP_ADDRESS: 'Última dirección de IP',
	GDSP_SESSION_LAST_STARTED_TIMESTAMP: 'Último sello temporal comenzado',
	GDSP_SESSION_LAST_UPDATED_TIMESTAMP: 'Último sello temporal actualizado',
	GDSP_SESSION_LAST_TERMINATED_TIMESTAMP: 'Último sello temporal cancelado',
	GDSP_SESSION_LAST_DATA_TRANSMITED_TIMESTAMP: 'Último sello temporal de datos transmitidos',
	GDSP_SESSION_PREVIOUS_NORMAL_TIMESTAMP: 'Sello temporal normal anterior',
	GDSP_SESSION_LAST_SMS_MO_TIMESTAMP: 'Último sello temporal de SMS MO',
	GDSP_SESSION_LAST_SMS_MT_TIMESTAMP: 'Último sello temporal de SMS MT',
	GDSP_SESSION_LAST_SMS_MT_STATUS: 'Último estado de SMS MT',
	GDSP_SESSION_LAST_SMS_MO_STATUS: 'Último estado de SMS MO',
	GDSP_SESSION_LAST_WAKEUP_REQUEST_TIMESTAMP: 'Sello temporal de última solicitud de reactivación',
	GDSP_SESSION_LAST_WAKEUP_REQUEST_TYPE: 'Tipo de última solicitud de reactivación',
	GDSP_SESSION_LAST_WAKEUP_STATUS: 'Último estado de reactivación',
	GDSP_SESSION_LAST_WAKEUP_STATUS_TIMESTAMP: 'Último sello temporal de estado de reactivación',
	GDSP_SESSION_LAST_NOTIFICATION_FAILURE_TIMESTAMP: 'Último sello temporal de fallo de notificación',
	GDSP_SESSION_LAST_NOTIFICATION_FAILURE_REASON: 'Última causa de fallo de notificación',

	CUSTOM_FIELD_1: 'Campo personalizado 1',
	CUSTOM_FIELD_2: 'Campo personalizado 2',
	CUSTOM_FIELD_3: 'Campo personalizado 3',
	CUSTOM_FIELD_4: 'Campo personalizado 4',
	CUSTOM_FIELD_5: 'Campo personalizado 5',
	CUSTOM_FIELD_6: 'Campo personalizado 6',
	CUSTOM_FIELD_7: 'Campo personalizado 7',
	CUSTOM_FIELD_8: 'Campo personalizado 8',
	CUSTOM_FIELD_9: 'Campo personalizado 9',
	CUSTOM_FIELD_10: 'Campo personalizado 10',

	/* ----- GDSP ------- */
	REGISTRATION_STATUS: 'Estado de registro',
	NETWORK_CONNECTIONS: 'Conexiones de red',
	PROVISIONED_DATE_AND_TIME: 'Fecha y hora suministradas',

	// GDSP SIM STATE STATUS
	M2M_PLATFORM_STATUS_A: 'Activo - En vivo',
	M2M_PLATFORM_STATUS_B: 'Inactivo - Nuevo',
	M2M_PLATFORM_STATUS_C: 'Activo - En reposo',
	M2M_PLATFORM_STATUS_D: 'Inactivo - Detenido',
	M2M_PLATFORM_STATUS_I: 'Migró de otro entorno GDSP',
	M2M_PLATFORM_STATUS_N: 'No suministrado',
	M2M_PLATFORM_STATUS_O: 'Migró a otro entorno GDSP',
	M2M_PLATFORM_STATUS_R: 'Activo - Listo',
	M2M_PLATFORM_STATUS_S: 'Activo - Suspendido',
	M2M_PLATFORM_STATUS_T: 'Activo - Prueba',
	M2M_PLATFORM_STATUS_U: 'Activo - En espera',
	M2M_PLATFORM_STATUS_V: 'Inactivo - Transformado',
	M2M_PLATFORM_STATUS_W: 'Suspendido - Normativo',
	M2M_PLATFORM_STATUS_Z: 'Cancelado',
	M2M_PLATFORM_STATUS_NOT_AVAILABLE: 'No disponible',

	// GDSP Session tab Status Codes
	// lastWakeupStatus
	LASTWAKEUPSTATUS_IR: 'Llamada de reactivación inválida',
	LASTWAKEUPSTATUS_FI: 'Fallo al iniciar reactivación',
	LASTWAKEUPSTATUS_SI: 'Inicio de reactivación con éxito',
	LASTWAKEUPSTATUS_F: 'Fallo de envío de reactivación',
	LASTWAKEUPSTATUS_S: 'Envío de reactivación con éxito',
	TOOLTIP_LASTWAKEUPSTATUS_IR: 'Una solicitud API defectuosa',
	TOOLTIP_LASTWAKEUPSTATUS_FI: 'Fallo del SMSC',
	TOOLTIP_LASTWAKEUPSTATUS_SI: 'Envío al SMSC con éxito',
	TOOLTIP_LASTWAKEUPSTATUS_F: 'SMSC emitió una notif. de envío indicando fallo',
	TOOLTIP_LASTWAKEUPSTATUS_S: 'SMSC emitió una notif. de envío indicando éxito',

	// lastSmsMtStatus
	LASTSMSMTSTATUS_IR: 'Llamada de reactivación inválida',
	LASTSMSMTSTATUS_FI: 'Fallo al iniciar reactivación',
	LASTSMSMTSTATUS_SI: 'Inicio de reactivación con éxito',
	LASTSMSMTSTATUS_F: 'Fallo de envío de reactivación',
	LASTSMSMTSTATUS_S: 'Envío de reactivación con éxito',
	TOOLTIP_LASTSMSMTSTATUS_IR: 'Una solicitud API defectuosa',
	TOOLTIP_LASTSMSMTSTATUS_FI: 'Fallo del SMSC',
	TOOLTIP_LASTSMSMTSTATUS_SI: 'Envío al SMSC con éxito',
	TOOLTIP_LASTSMSMTSTATUS_F: 'SMSC emitió una notif. de envío indicando fallo',
	TOOLTIP_LASTSMSMTSTATUS_S: 'SMSC emitió una notif. de envío indicando éxito',

	/* ----- GDSP END ------- */

	/* API ERROR CODES  */

	CODE_400203: 'Falta un parámetro obligatorio',
	CODE_400204: 'Un parámetro no tiene el largo correcto',
	CODE_401205: 'Token inválido o vencido',
	CODE_400207: 'Un parámetro no tiene un valor válido de las opciones posibles',
	CODE_400208: 'Un parámetro no tiene el formato correcto',
	CODE_400209: 'Parámetro duplicado',
	CODE_400299: 'Solicitud defectuosa',
	CODE_400301: 'Errores de sistemas descendentes',
	CODE_401301: 'Fallo de credenciales de sistemas descendientes',
	CODE_404301: 'No se encontró DeviceID en el sistema descendente',
	CODE_404302: 'AccountID del sistema descendiente no se encontró o es inválido',
	CODE_502100: 'Cambio no permitido, ya se fijó el equipo',
	CODE_400100: 'Solicitud inválida: Acción de lote no permitida para equipos de satélite',
	CODE_404201: 'El archivo no existe',
	CODE_401202: 'Nombre de usuario o contraseña inválidos',
	CODE_400206: 'Formato de nombre de archivo inválido',
	CODE_502200: 'Error lote de archivos',
	CODE_502202: 'Error de recuperación de base de datos',
	CODE_502203: 'Error en base de datos la hacer cambios',
	CODE_502204: 'Error al crear cliente',
	CODE_502301: 'Error al procesar push API',
	CODE_404303: 'No se encontró DeviceID en la notificación de push API',
	CODE_502501: 'Error al procesar informes de SFTP',
	CODE_undefined: ' ',
	CODE_INTEGRATION_TIMEOUT: 'No se puede alcanzar la plataforma subyacente. Inténtelo nuevamente.',
	CODE_VTM_SVC_40003: 'The entered value was not recognized',
	CODE_VTM_SVC_40004: 'Unable to create the support ticket',
	CODE_VTM_SVC_40005: 'Unable to create the support ticket',
	CODE_VTM_SVC_40409: 'Ticket does not exist: It may have been removed due to AT&T data retention policy',
	CODE_VTM_SVC_40411: 'Ticket does not exist or it is closed by an agent: It may have been removed due to AT&T data retention policy',
	CODE_VTM_SVC_40027: 'Cannot update canceled or closed support ticket: This ticket is closed or canceled and cannot be updated.',
	CODE_VTM_SVC_40035: 'Unable to add engagement: Engagement ticket already exist',
	CODE_VTM_SVC_50001: 'Something went wrong: Try again. If the problem persists, please report your issue by calling AT&T',
	CODE_VTM_SVC_40038: 'Unable to update the support ticket: Circuit details are the same',
	CODE_VTM_SVC_40039: 'Cannot create the support ticket: Asset info is missing in the system',
	CODE_VTM_SVC_40040: 'Cannot add a PVC value to support ticket: pvcID details already exist in the system',
	CODE_VTM_SVC_40041: 'Unable to update support ticket: clkNumber does not exist',
	CODE_VTM_SVC_40042: 'Unable to update support ticket: pvcID does not exist',
	CODE_VTM_SVC_40043: 'Unable to retrieve ticket history: Please try again later',
	CODE_VTM_SVC_40010: 'Unable to create the support ticket: Field exceeded maximum of 199 characters. Please correct the length and try again',
	CODE_VTM_SVC_40053: 'Unable to create support ticket: Case ID does not have Ticket Role defined. or is invalid support role',
	CODE_EOD_11: 'Unable to process the request: Please try again in a few minutes.',
	CODE_EOD_59: 'Cannot deactivate device without static IP: Before a device can be deactivated (put on hold), it needs to have a static IP. You can still deactivate this device by deprovisioning it (purge) from the system.',
	CODE_EOD_60: 'Cannot deactivate inactive device',
	CODE_EOD_46: 'The SIM is already in the provisioning queue',
	CODE_EOD_30: 'The SIM cannot be suspended using a past date',
	CODE_EOD_32: 'Internal system error: Contact customer support or create a trouble ticket to get help with this issue',
	CODE_EOD_41: 'Cannot change profile for suspended devices: Contact customer support or create a trouble ticket to get help with this issue',
	CODE_EOD_45: 'Invalid SIM or SIM does not belong to your account: Contact customer support or create a trouble ticket to get help with this issue',
	CODE_EOD_16: 'SIM has reached the maximum number of Rate Plan changes allowed during the Billing Cycle: Could not complete the Rate Plan change request. Please try again in the next billing cycle.',
	CODE_EOD_17: 'SIM has reached the maximum number Rate Plan changes allowed for the day: Could not complete the Rate Plan change request. Please try again tomorrow.',
	CODE_EOD_25: 'The device is currently in a suspended state and so cannot resume before the suspended date',
	CODE_EOD_27: 'The SIM cannot be suspended using a past date',
	CODE_EOD_3: 'Device is already inactive',
	CODE_EOD_401: 'Unable to authorize user performing the batch action with device platform.',

	/* API ERROR CODES  */

	SERVICE_STATUS: 'Estado de 360L',
	SERVICESTATUS: 'Estado de 360L',
	OEM: 'Cuentas suscritas',
	ROAMING_COUNTRY: 'País de roaming',
	ROAMING_CARRIER: 'Operador de roaming',
	ROAMINGCOUNTRY: 'País de roaming',
	ROAMINGCARRIER: 'Operador de roaming',
	OEM_TOOLTIP: 'Cuentas 360L vinculadas',
	SIM_STATE: 'Estado de SIM',

	NO_DATA_ENDPOINT_HISTORY: 'No hay historial de terminal',
	PRINT_PDF: 'Imprimir PDF',
	USER_GUIDE: 'Guía del usuario',
	USER_GUIDE_MENU: 'Menú de guía del usuario',
	FAQ_MENU: 'Menú de preguntas frecuentes',
	TABLE_OF_CONTENTS: 'Índice',
	RATE_PLAN_TYPE: 'Tipo de plan de tarifas',
	SUBSCRIPTION_PRICE: 'Precio de suscripción',
	ACCOUNT_CHARGE: 'Cargo de la cuenta',
	PAYMENT_TYPE: 'Tipo de pago',
	TERM: 'Plazo',
	USAGE_LIMIT: 'Límite de uso',
	TIERING_FLAG: 'Indicador por niveles',

	RATE_PLAN_CHANGE: 'Cambio en plan de tarifas',
	RATE_PLAN_CHANGED_TO: 'Eligió cambiar el plan de tarifas a',
	SCHEDULE_RATE_PLAN_CHANGE: 'Fijar cambio de plan de tarifas',
	RATE_PLAN_CHANGE_IS_SUCCESSFULLY_SCHEDULED_ERROR: '¡El cambio de plan de tarifas no se pudo fijar con éxito!',
	RATE_PLAN_CHANGE_IS_SUCCESSFULLY_SCHEDULED_FOR: 'Cambio de plan de tarifas fijado con éxito para el',
	RATE_PLAN_IS_CHANGED_TO: 'El plan de tarifas cambió a ',
	FAILED_TO_PROVISION_AFTER_RATE_PLAN_CHANGE: ' fallo de suministro tras cambio en plan de tarifas. ',
	SEND_EMAIL_TO_CONSUMER: 'Enviar correo electrónico a consumidor',
	PUSH_API: 'Push API',
	INITIATE_OTA_SWAP: 'Iniciar intercambio de OTA',
	UPDATE_PCRF: 'Actualizar PCRF',
	CHANGE_DEVICES_NETWORK_ENTITLEMENT: 'Cambiar permiso de red del equipo',
	CHANGE_DEVICES_SIM_STATE: 'Cambiar estado de SIM del equipo',
	CHANGE_DEVICES_RATE_PLAN: 'Cambiar plan de tarifas del equipo',
	NUMBER_OF_DAYS: 'Cantidad de días',
	NUMBER_OF_HOURS: 'Cantidad de horas(máx. 24 h)',
	AT: 'A',
	FILTER: 'FILTRO',
	FOLLOW_UP_WITH: 'Hacer seguimiento con',
	CUSTOM: 'Personalizado',
	ELIGIBLE_RATE_PLAN: 'Plan de tarifas elegible',
	CHOOSE_RATE_PLAN: 'Elegir plan de tarifas',
	CUSTOMER_ID: 'ID de cliente',
	INVALID_URL_FORMAT: 'Formato de URL inválido',
	INVALID_PORT_NUMBER: 'Número de PUERTO inválido',
	INVALID_INPUT: 'Entrada inválida',
	RATE_PLAN_CHANGE_SUPPORT: 'Error en plan de tarifas, comuníquese con Atención al cliente.',
	NETWORK_ENTITLEMENT_PLACEHOLDER: 'XYX GPRS/LTE/SMS/IMS RES 165',
	M2M_SUB_ACCOUNT_ID: 'ID de cuenta suscrita M2M',

	/* SIM ORDERING */
	SIM_ORDERING: 'Pedido de SIM',
	CREATE_SIM_ORDER: 'Crear pedido de SIM',
	NEW_SIM_ORDER: 'Nuevo pedido',
	EXISTING_SIM_ORDER: 'ID de pedido',
	M2M_ACCOUNT: 'Cuenta M2M',
	M2M_ACCOUNT_PLACEHOLDER: 'Seleccione su cuenta M2M',
	ORDER_ID: 'ID de pedido',
	ORDER_NUMBER_ID: 'ID de número de pedido',
	ORDER_CONFIRMATION_NUMBER: 'Número de conf. de pedido',
	ORDER_DATE: 'Fecha de pedido',
	PURCHASE_ORDER_NUMBER: 'NÚM. DE PEDIDO',
	SKU: 'SKU',
	SKU_PLACEHOLDER: 'Seleccionar SKU',
	UNIT_PRICE: 'Precio unitario',
	QUANTITY: 'Cantidad',
	QUANTITY_LIMIT_TEMPORARILY_REACHED: 'Se alcanzó el límite de cantidad temporalmente',
	MIN_MAX_QUANTITY: 'Mín. {min} / Máx. {available} {max}',
	INCREMENT_QUANTITY: 'Incrementar',
	ORDER_STATUS: 'Estado de pedido',
	ORDER_STATUS_PENDING: 'Pendiente',
	ORDER_STATUS_RECEIVED: 'Recibido',
	ORDER_STATUS_SHIPPED: 'Enviado',
	ORDER_STATUS_COMPLETED: 'Completado',
	ORDER_STATUS_SUCCESSFUL: 'Éxito',
	ORDER_STATUS_FAILED: 'Falló',
	CONTACT_NAME: 'Nombre de contacto',
	TELEPHONE_NUMBER: 'Número de teléfono',
	TARGET_IMPLEMENTATION_DATE: 'Fecha prevista de implementación',
	EXPEDITED: 'Urgente',
	SHIPPING_ADDRESS: 'Dirección de envío',
	ADDRESS_LINE_1: 'Línea de dirección 1',
	ADDRESS_LINE_2: 'Línea de dirección 2',
	SHIPPING_NOTES: 'Notas de envío',
	SHIPPING_NUMBER: 'Número de envío',
	EXPEDITED_SHIPPING: 'Envío urgente',
	TOTAL_PRICE: 'Precio total',
	SUBMIT_ORDER_REQUEST: 'Enviar solicitud de pedido',
	SUBMIT_ORDER_REQUEST_CONFIRMATION: '¿Está seguro de que quiere enviar este pedido?',
	EDIT_ORDER_REQUEST: 'Editar solicitud de pedido',
	EDIT_ORDER_REQUEST_CONFIRMATION: '¿Está seguro de que quiere guardar los cambios?',
	SAVE_UPDATES: 'Guardar actualizaciones',
	ADD_SIM_ORDER_SUCCESS: 'Pedido de SIM creado',
	ADD_SIM_ORDER_ERROR: 'Fallo al crear pedido de SIM, inténtelo nuevamente.',
	EDIT_SIM_ORDER_SUCCESS: 'Pedido de SIM editado',
	EDIT_SIM_ORDER_ERROR: 'Fallo al editar pedido de SIM, inténtelo nuevamente.',
	PO_TOOLTIP: 'El Núm. de pedido se generará al enviar el pedido',

	FILTERS: 'Filtros',
	FILTER_CATEGORY_STATUS: 'Estado',
	FILTER_CATEGORY_PLATFORM: 'Plataforma M2M',
	FILTER_CATEGORY_SKU: 'SKU',
	FILTER_CATEGORY_EXPEDITED: 'Urgente',
	FILTER_VALUE_PENDING: 'Pendiente',
	FILTER_VALUE_RECEIVED: 'Recibido',
	FILTER_VALUE_SHIPPED: 'Enviado',
	FILTER_VALUE_COMPLETED: 'Completado',
	FILTER_VALUE_YES: 'Sí',
	FILTER_VALUE_NO: 'No',

	SIM_ORDERING_TITLE: 'Pedido de SIM',
	SIM_ORDERING_SUBTITLE: 'Seleccione abajo qué SIM están disponibles para el pedido.',
	SIM_ORDERING_CONTRACT_RESTRAINTS: 'Restricciones de contrato',
	SIM_ORDERING_CONTRACT_RESTRAINTS_SUBTITLE: 'Fije las restricciones en el proceso de pedido de SIM.',
	SIM_ORDERING_MIN_QUANTITY_ALLOWED: 'Cant. mín. permitida',
	SIM_ORDERING_MIN_QUANTITY_ALLOWED_PLACEOLDER: 'Cant. mín.',
	SIM_ORDERING_MAX_QUANTITY_ALLOWED: 'Cant. máx. permitida',
	SIM_ORDERING_PER_PERIOD: 'Por período',
	SIM_ORDERING_PER_PERIOD_PLACEHOLDER: 'Seleccionar período',
	SIM_ORDERING_MAX_QUANTITY_ALLOWED_PLACEOLDER: 'Cant. máx.',
	SIM_ORDERING_SIM_SKU: 'SKU DE SIM',
	SIM_ORDERING_SIM_SKU_PLACEHOLDER: 'Ingresar SKU de SIM aquí',
	SIM_ORDERING_UNIT_PRICE: 'Precio unitario',
	SIM_ORDERING_UNIT_PRICE_PLACEHOLDER: 'Precio unitario',
	SIM_ORDERING_ALLOWED_TO_ORDER: 'Autorizado para hacer pedido',
	SIM_ORDERING_SKU_DESCRIPTION: 'Descripción',
	SIM_ORDERING_SKU_DESCRIPTION_PLACEHOLDER: 'Ingresar descripción de SKU',
	SIM_ORDERING_SKU_ADD_SKU: '+ Agregar otro SKU de SIM',
	SIM_ORDERING_VALIDATOR_MAX_QUANTITY: 'Cant. máx.',
	SIM_ORDERING_VALIDATOR_MIN_QUANTITY: 'Cant. mín.',
	ADD_RESTRAINTS_SUCCESS: 'Restricciones de contrato agregadas',
	ADD_RESTRAINTS_ERROR: 'Fallo al agregar restricciones de contrato',
	UPDATE_RESTRAINTS_SUCCESS: 'Restricciones de contrato actualizadas',
	UPDATE_RESTRAINTS_ERROR: 'Fallo al actualizar restricciones de contrato',
	ADD_SIM_ORDERING_SKUS_SUCCESS: 'Se actualizaron los SKU del pedido de SIM',
	ADD_SIM_ORDERING_SKUS_ERROR: 'Hubo un fallo al agregar los SKU del pedido de SIM',

	COMPANY_REPORTS: 'Informes',
	COMPANY_REPORTS_SETTINGS_SUCCESSFULLY_UPDATED: 'Se guardaron los parámetros de informes de empresa',
	ERROR_WHILE_UPDATING_COMPANY_REPORTS: 'Error al actualizar informes de la empresa',
	ERROR_WHILE_FETCHING_COMPANY_REPORTS: 'Error al buscar la config. de los informes',
	COMPANY_REPORTS_SUBTITLE: 'Seleccione abajo qué informes quiere recibir.',
	DAILY_REPORTS: 'INFORMES DIARIOS',
	MONTHLY_REPORTS: 'INFORMES MENSUALES',
	PREFERENCES_MENU: 'Menú de preferencias',
	PREFERENCES: 'Preferencias',
	REFERENCE_REPORTS: 'Informes de referencia',
	M2M_DATE_ADDED: 'Fecha agregada a M2M',
	M2M_INITIAL_ACTIVATION_DATE: 'Fecha de activación de M2M',
	NOTES: 'Notas',
	SAVE_NOTE: 'Guardar notas',
	NOTE_TEXTAREA_PLACEHOLDER: 'Ingrese su nota aquí (máx. 2000 caracteres)',
	ADD_NOTE_SUCCESS: 'Notas guardadas',
	ADD_NOTE_ERROR: 'Fallo al guardar la nota, inténtelo nuevamente.',
	DELETE_NOTE_SUCCESS: 'Nota borrada',
	DELETE_NOTE_ERROR: 'Fallo al borrar la nota, inténtelo nuevamente.',
	DELETE_NOTE: 'Borrar nota',
	TICKETING_STATUS: 'Estado del ticket',
	UPDATE_NOTE_STATUS: 'Actualizar estado de nota',
	LAST_UPDATE: 'Última actualización',
	SUBMISSION_LOCATION: 'Lugar de envío',
	RULE_STATE: 'Estado de regla',
	RULE_STATE_ACTIVE: 'Activo',
	YES: 'Sí',
	NO: 'No',
	// 'ANALÍTICA',
	CHART_CATEGORIES: 'Categorías de gráfico',
	GROUP_DATA_BY: 'Agrupar datos por',
	CHART_OPTIONS: 'Opciones de gráfico',
	CREATE_NEW_CHART: 'Crear gráfico nuevo',
	EDIT_CHART: 'Editar gráfico',
	DISCLAIMER: 'Aviso legal: ',
	DATE_RANGE: 'RANGO DE FECHA',
	SHOW: 'MOSTRAR',
	CYCLE: 'CICLO',
	INCREMENT_BY: 'INCREMENTAR POR',
	SELECT_CUSTOM_DATE_RANGE: 'Seleccionar rango de fecha personalizado',

	// 'Facturación',
	INVOICE: 'Factura',
	INVOICE_DATE: 'Fecha de la factura',
	CUSTOMER_NAME: 'Nombre del cliente',
	DOCUMENT_ID: 'ID de documento',
	COUNTRY_NAME: 'Nombre del país',
	TABLE_COLUMNS_INVOICES_CHANGE_SUCCESS_TITLE: 'iLos parámetros de la tabla de facturas se cambiaron con éxito!',
	TABLE_COLUMNS_INVOICES_CHANGE_SUCCESS_MESSAGE: 'Los parámetros de la tabla de facturas se actualizaron con éxito.',
	TABLE_COLUMNS_INVOICES_CHANGE_ERROR_TITLE: 'iError al cambiar las columnas de tabla de facturas!',
	TABLE_COLUMNS_INVOICES_CHANGE_ERROR_MESSAGE: 'Las columnas de la tabla de facturas no se pudieron actualizar esta vez. Inténtelo nuevamente.',
	INVOICE_DETAILS: 'Detalles de la factura',

	// 'GESTIÓN DE TICKETS',
	TICKET_CREATED_DATE: 'Fecha de creación de ticket',
	UPDATED_NOTE_STATUS_DATE: 'Fecha de estado de nota actualizada',
	LAST_UPDATED_DATE: 'Fecha de última actualización',
	UPDATE_NOTE: 'Actualizar nota',
	TABLE_TICKETS_COLUMNS_CHANGE_SUCCESS_TITLE: 'iLos parámetros de la tabla de tickets se cambiaron con éxito!',
	TABLE_TICKETS_COLUMNS_CHANGE_SUCCESS_MESSAGE: 'Los parámetros de la tabla de tickets se actualizaron con éxito.',
	TABLE_TICKETS_COLUMNS_CHANGE_ERROR_TITLE: 'iError al cambiar las columnas de tabla de tickets!',
	TABLE_TICKETS_COLUMNS_CHANGE_ERROR_MESSAGE: 'Las columnas de la tabla de tickets no se pudieron actualizar esta vez. Inténtelo nuevamente.',

	TODAY: 'Hoy',
	PREVIOUS_DAY: 'Día anterior',
	LAST_10_DAYS: 'Últimos 10 días',
	LAST_30_DAYS: 'Últimos 30 días',
	CUSTOM_RANGE: 'Rango personalizado',
	CURRENT_CYCLE_TO_DATE: 'Ciclo actual hasta la fecha',
	HISTORICAL: 'Histórico',
	WEEK: 'Semana',
	SUM_TOTALS: 'Sumas globales',
	AVERAGE_TOTALS: 'Promedios totales',
	LINE_GRAPH: 'Gráfico lineal',
	BAR_GRAPH: 'Gráfico de barra',
	WORLD_MAP_GRAPH: 'Gráfico de mapa global',
	TOTAL_DATA_USAGE: 'Uso total de datos',
	ENDPOINT_COUNT_TOTALS: 'Recuento total de terminal',
	NO_USAGE_SIMS: 'SIM no usadas',
	TOTAL: 'Total',
	AVERAGE_USAGE: 'Promedio',
	DIRECTION_INDEX: 'Índice de dirección',
	PIE_CHART: 'Gráfico circular',
	ERROR_WHILE_ADDING_TICKET: 'Error al agregar ticket',

	SELECT_M2M_PLATFORM: 'Seleccione su plataforma M2M',
	SELECT_M2M_PLATFORM_ERROR: 'Seleccione la plataforma M2M primero para llenar los datos',
	SELECT_YOUR_ACCOUNT: 'Seleccione su cuenta',
	CHOOSE_M2M_PLATFORM: 'Elija su plataforma M2M',
	CHOOSE_YOUR_ACCOUNT: 'Elija su cuenta',

	TOTAL_VOICE_USAGE: 'Uso total de voz',
	TOTAL_SMS_USAGE: 'Uso total de SMS',
	SAVE_AND_CLOSE: 'Guardar y cerrar',
	// 'Funciones de seguridad',
	SECURITY_TABS_1: 'Lista blanca de IMEI',
	SECURITY_TABS_2: 'Lista blanca de SMS',
	SECURITY_TABS_3: 'Lista blanca de VOZ',
	SECURITY_TABS_4: 'Lista negra de IP, URL, PUERTO',
	SECURITY_TABS_5: 'Rango de lista blanca de IP',
	SECURITY_FEATURES_1: 'IMEI',
	SECURITY_FEATURES_2: 'SMS',
	SECURITY_FEATURES_3: 'VOZ',
	SECURITY_FEATURES_4: 'IP, URL, PUERTO',
	SECURITY_FEATURES_5: 'RANGO DE IP',
	SECURITY_FEATURES_SUCCESSFULLY_ADD: 'Plan de datos cambiado con éxito',
	SECURITY_FEATURES_ERROR_ADD: 'Error al actualizar los datos',
	SECURITY_FEATURES_ADD_1: 'Agregar IMEI nuevo a la lista blanca',
	SECURITY_FEATURES_ADD_2: 'Agregar SMS nuevo a la lista blanca',
	SECURITY_FEATURES_ADD_3: 'Agregar servicio de VOZ nuevo a la lista blanca',
	SECURITY_FEATURES_ADD_4: 'Agregar nuevo IP, URL, PUERTO a la lista negra',
	SECURITY_FEATURES_ADD_5: 'Agregar rango de IP nuevo a la lista blanca',
	SECURITY_FEATURES_EDIT_1: 'Editar lista blanca de IMEI',
	SECURITY_FEATURES_EDIT_2: 'Editar lista blanca de SMS',
	SECURITY_FEATURES_EDIT_3: 'Editar lista blanca de VOZ',
	SECURITY_FEATURES_EDIT_4: ' Editar lista negra de IP,URL,PUERTO',
	SECURITY_FEATURES_EDIT_5: 'Editar rango de lista blanca de IP',
	SECURITY_FEATURES_M2M: 'Seleccionar plataforma M2M',
	SECURITY_FEATURES_REMOVE: 'Eliminar de lista blanca',
	UPDATE: 'Actualizar',
	SECURITY_FEATURES: 'Funciones de seguridad',
	M2M_ACCOUNT_NAME: 'NOMBRE DE CUENTA M2M',
	DATA_SUBMITTED: 'DATOS ENVIADOS',
	FILTER_CATEGORY_M2MACCOUNT: 'Cuenta M2M',
	SECURITY_FEATURES_SUCCESSFULLY_EXPORT: 'Exportado con éxito',
	SECURITY_FEATURES_FAIL_EXPORT: 'Fallo al exportar',
	COUNTRYCODE: 'País',
	MINUTE: 'Minuto',
	MINUTES: 'Minutos',
	CHART_LEFT_AXIS_MINUTE: 'min.',
	CHART_LEFT_AXIS_MINUTES: 'min.',
	CONSUMED_MINUTE: 'Minutos consumidos',
	CONSUMED_MB: 'MB consumidos',
	CONSUMED_GB: 'GB consumidos',
	CONSUMED_TB: 'TB consumidos',
	CONSUMED_PB: 'PB consumidos',
	CONSUMED_SMS: 'SMS consumidos',
	NO_DESCRIPTION_FOR_GRAPH: 'No hay descripción para este gráfico',
	GRAPHS: 'Gráficos disponibles actuales',
	CONSUMED_COUNT: 'Cantidad de terminales',
	DOWNLOAD_CHART_AS_PNG: 'Descargar PNG',
	CUSTOMER_INVOICE_ID: 'ID de cliente',
	INVOICE_ID: 'ID DE FACTURA',
	CUSTOMER_INVOICE_NAME: 'Cta. suscrita',
	INVOICE_COUNTRY: 'País',
	ACCESSTECHNOLOGY: 'Tecnología de acceso',
	RATINGZONE: 'Zona de clasificación',
	RATEPLAN: 'Plan de tarifas',
	CHOOSE_FILTERS_ON_ANALYTICS_MODAL: 'Seleccione una categoría en el primer paso',
	NO_FILTERS_ON_ANALYTICS_MODAL: 'No hay filtros disponibles para esta categoría',
	BILLING_CYCLE: 'Ciclo de facturación',
	FETCHING_DATA: 'Espere, por favor, Buscando datos...',
	NO_DATA_RECEIVED: 'No se recibieron datos',
	NO_FILTERS_AVAILABLE: 'No hay filtros disponibles.',
	DATE_SUBMITTED: 'FECHA DE ENVÍO',
	NO_REPORTS_DEFINED: 'No se definió informes',
	APPLY: 'Aplicar',
	OPEN_CHART_SETTINGS_PAGE: 'Parámetros de gráfico',
	CUSTOMIZE_CHART_DATA: 'Personalizar datos de gráfico',
	ESTIMATED_AVERAGE_USAGE: 'Uso promedio estimado',
	DELETE_DEFINED_CHART_SETTING_QUESTION: '¿Borrar parámetro de gráfica definida?',
	DELETE_DEFINED_CHART_SETTING_MESSAGE: '¿Está seguro de que quiere borrar el parámetro',
	TOTAL_COUNT_BY_SEVERITY: 'Ticket por severidad',
	TOTAL_COUNT_BY_STATUS: 'Ticket por estado',
	INVALID_IP_ADDRESS: 'Dirección de IP inválida',
	INVOICENUMBER: 'Número de factura',
	CUSTOMERNAME: 'Nombre del cliente',
	COUNTRYNAME: 'Nombre del país',
	CUSTOMERID: 'ID de cliente',
	DEVICE_WAKE_UP: 'Reactivación del equipo',
	DEVICE_WAKE_UP_NOTIFICATION_TITLE: 'Reactivación del equipo',
	DEVICE_WAKE_UP_NOTIFICATION_MESSAGE_SUCCESS: 'Éxito al reactivar el equipo',
	DEVICE_WAKE_UP_NOTIFICATION_MESSAGE_FAIL: 'Fallo al reactivar el equipo',

	FILES_FAILED_TO_UPLOAD_MESSAGE: 'Fallo al cargar los archivos: ',
	ADD_ANOTHER_TRESHOLD: 'Agregar otro umbral',
	PIE_CHART_TOTAL: 'Total',
	ADD_ATTACHMENT: 'Agregar un adjunto',
	TICKET_FILE_UPLOAD_ERROR: 'Hubo un error en el sistema principal y algunos de sus archivos no se actualizaron.',
	FILE_TO_LARGE_TO_UPLOAD: 'El archivo es muy grande para cargar. El tamaño máx. es 3 MB.',
	RETRY: 'Reintentar',
	NO_CATEGORIES: 'No hay categorías disponibles para esta cuenta',
	ZONE_NAME: 'Nombre de Zona',
	POOL_ID: 'ID de Grupo',

	// NOTES INVOICES
	ADD_NEW_NOTE: 'Agregar nota',
	EDIT_NOTE: 'Editar nota',
	ADD_NOTE_PLACEHOLDER: 'Ingrese su nota aquí',
	INVOICE_NOTE_SUCCESSFULLY_UPDATED: 'Nota de factura actualizada con éxito',
	INVOICE_NOTE_UPDATE_FAIL: 'Fallo al actualizar factura',
	ATTACHMENTS_ADDED: 'Adjuntos agregados',
	EXTERNAL_LAST_UPDATED_BY: 'Última actualización por',
	EXTERNAL_LAST_UPDATED_DATE: 'Fecha/Hora de última actualización',
	REMOVE: 'Eliminar',
	EndpointStatus: 'Estado',
	NetworkOperator: 'Operador de red',
	NetworkCountry: 'País de red',
	NetworkType: 'Tipo de red',
	TransferStatus: 'Transferencia de estado IMSI',
	NetworkEntitlement: 'ID de permiso de red',
	RecredentialTransferStatus: 'Estado de reacreditación',
	M2MDateAdded: 'Fecha agregada a M2M',
	M2MInitialActivationDate: 'Fecha de activación de M2M',
	LAST_UPDATED_BY: 'Última actualización por',
	LAST_UPDATED: 'Última actualización',
	INVOICE_NOTE_FILE_ERROR: 'Hubo un error en el sistema principal y algunos de sus archivos no se cargaron.',

	BATCH_HISTORY: 'Historial de lote',
	// DIAGNOSTIC HLRHSSDetails
	GET_HLR_HSS_DETAILS: 'Obtener detalles HLR/HSS',
	APN_IDENTIFIER: 'Identificador de APN',
	IP_ADDRESS_TYPE: 'Tipo de dirección de IP',
	QOS_PROFILE: 'Perfil QOS',
	PDN_GW_DYNAMIC_ALLOCATION: 'Asignación dinámica de pasarela PDN',
	VPLMN_ADDRESS_ALLOWED: 'Dirección VPLMN permitida',
	MAX_UPLOAD: 'Carga máx.',
	MAX_DOWNLOAD: 'Descarga máx.',
	SUBSCRIBER_PROFILE: 'Perfil de suscriptor',
	ENDPOINT_ID_ICCID: 'ID de terminal - ICCID',
	BARRING_STATUS: 'Estado de exclusión',
	NETWORK_ACCESS: 'Acceso de red',
	CURRENT_VLR_NUMBER: 'Número VLR actual',
	PREVIOUS_VLR_NUMBER: 'Número VLR anterior',
	CURRENT_SGSN_NUMBER: 'Número SGSN actual',
	PREVIOUS_SGSN_NUMBER: 'Número SGSN anterior',
	ROAMING_RESTRICTION: 'Restricción de roaming',
	DATA_3G_ENABLED: 'Datos 3G habilitados',
	NETWORK_STATUS: 'Estado de red',
	APN_LIST: 'Lista de APN',

	// 'HISTORIAL DE DATOS',
	DATA_HISTORY: 'Historial de datos',
	DATA_VOLUME_DOWN_KB: 'Volumen de datos descendientes (kB)',
	DATA_VOLUME_UP_KB: 'Volumen de datos ascendentes (kB)',
	DURATION_MINUTES: 'Duración (minutos)',
	SESSION_CHARGING_ID: 'ID de cobro de sesión',
	ACCESS_POINT_NAME: 'APN',
	DEVICE_IP_V4: 'Dirección Ipv4 del equipo',
	DEVICE_IP_V6: 'Dirección IPv6 del equipo',
	RATING_ZONE_NAME: 'Nombre de zona de clasificación',
	RATING_GROUP_ID_NAME: 'Nombre de ID de grupo de clasificación',
	RATING_GROUP_ID: 'Nombre de ID de grupo',

	// SMS HISTORY WING
	MESSAGE_HISTORY: 'Historial de mensajes',
	PORTAL_MESSAGES: 'Mensajes del portal',
	SMS_LOG_ID: 'ID de registro SMS',
	DATE_SENT_RECEIVED: 'Fecha de envío/recepción',
	SENT_FROM: 'Enviado de',
	SENT_TO: 'Enviado a',
	MESSAGE_TYPE: 'Tipo de mensaje',
	ZONE_MODEL: 'Modelo de zona',

	CTD_DATA_USAGE: 'CTD de uso de datos (Megabytes)',
	CTD_SMS_MO_MT_USAGE: 'CTD de uso de SMS (MSJ.)',
	CTD_VOICE_USAGE: 'CTD de uso de voz (Min)',

	ACCOUNTS: 'Cuentas',
	MUST_BE_BETWEEN_CHARACTERS_LONG: 'Debe tener entre {min} y {max} caracteres',
	INVALID_DOMAIN_FORMAT: 'Formato de dominio inválido',
	LAST_UPDATE_DATE_TIME: 'Fecha/Hora de última actualización',
	LAST_UPDATED_BY_USER: 'Última actualización por el usuario',
	INSESSION: 'En sesión',
	PLEASE_WAIT_THIS_MAY_TAKE_A_FEW_MINUTES: 'Espere, por favor, Esto puede llevar unos minutos, según el tamaño del archivo.',
	NO_RESULTS: 'No hay resultados',
	SELECTED_RATE_PLAN: 'Plan de tarifas seleccionado',
	HSPA: 'HSPA',
	LTE: 'LTE',
	VLR: 'VLR',
	SGSN: 'SGSN',
	EXPORT: 'Exportar',
	FILE: 'Archivo',
	ERROR_CODE: 'Código de error',
	ERROR_TYPE: 'Tipo de error',
	AT_LINE: 'Línea A',
	EXPORT_TICKETS: 'Exportar ticket',
	EXPORT_USER_USAGE_DATA: 'Exportar datos de uso de los usuarios',
	PLACEHOLDER_MODEL: 'AT&T Z431',
	NO_BATCH_ACTIONS_AVAILABLE: 'No hay acciones de lote disponibles',
	NO_BATCH_ACTIONS_AVAILABLE_SUBTITLE: 'No hay acciones de lote para esta tabla para mostrarle ahora',
	NO_BATCH_REPORTS_AVAILABLE: 'No hay informes de lote disponibles',
	NO_BATCH_REPORTS_AVAILABLE_SUBTITLE: 'No hay informes de lote para esta tabla para mostrarle ahora',
	MOBILE_ORIGINATING: 'Originado en móvil',
	MOBILE_TERMINATING: 'Terminado en móvil',
	NA: 'N/C',
	GMNA: 'GM Norteamérica',
	POD3: 'ACC Pod3',
	POD19: 'Empresa',
	TABS_ADD_ICON: 'Crear tabla de terminales personalizada',
	USAGE_SUMMARIES: 'Resumen de uso',
	EMPTY_SPACE: ' ',
	// endpoints rate plans
	'ENDPOINTS_RATE_PLAN.RATE_PLAN_TYPE': 'Tipo de plan de tarifas',
	'ENDPOINTS_RATE_PLAN.INCLUDED_TEST_DATA': 'Datos de prueba incluidos',
	'ENDPOINTS_RATE_PLAN.BILLING_PLATFORM': 'Plataforma de facturación',
	'ENDPOINTS_RATE_PLAN.ACTIVATION_FEE': 'Cargo de activación',
	'ENDPOINTS_RATE_PLAN.INCLUDED_TEST_SMS': 'SMS de prueba incluidos',
	'ENDPOINTS_RATE_PLAN.BILLING_ACCOUNT_ID': 'ID de cuenta de facturación',
	'ENDPOINTS_RATE_PLAN.MSU_PRICE': 'Precio de MSU',
	'ENDPOINTS_RATE_PLAN.INCLUDED_TEST_VOICE': 'Voz de prueba incluida',
	'ENDPOINTS_RATE_PLAN.ZONE_MODEL_ID': 'ID de modelo de zona',
	'ENDPOINTS_RATE_PLAN.TYPE': 'Tipo',
	'ENDPOINTS_RATE_PLAN.INCLUDED_MSU': 'MSU incluido',
	'ENDPOINTS_RATE_PLAN.VALID_FROM': 'Válido de',
	'ENDPOINTS_RATE_PLAN.DEFAULT_RATE_PLAN': 'Plan de tarifas predeterminado',
	'ENDPOINTS_RATE_PLAN.BASE_RATE_PLAN': 'Plan de tarifas base',
	'ENDPOINTS_RATE_PLAN.POSTPAID': 'Pospago',
	'ENDPOINTS_RATE_PLAN.APPROVED': 'Aprobado',
	'ENDPOINTS_RATE_PLAN.MONTHLY': 'MENSUAL',
	PASSWORDS_DO_NOT_MATCH: 'Las contraseñas no coinciden',
	NO_WHITE_SPACE_ONLY: 'El texto debe contener letras y/o números',
	NO_RATE_PLAN_SELECTED: 'no hay plan de tarifas seleccionado',
	NO_RATE_PLANS_UNDERLYING_PLATFORM: 'Hay un error en la plataforma subyacente y el cambio del plan de tarifas no está disponible en este momento',
	ENDPOINT_PROVISIONED_STATE_CHANGE_SUCCESS: 'La ID de terminal {args1} se suministró con éxito. El estado de IMSI cambió a ',
	ENDPOINT_PROVISIONED_STATE_CHANGE_FAIL: 'La ID de terminal {args1} falló al suministrar después del cambio de estado IMSI. Obtener asistencia',

	// AUTH SIGN IN CALLBACK
	AUTHENTICATION_FAILED: 'Fallo de autenticación',
	AUTHENTICATION_IN_PROGRESS: 'Autenticación en progreso, Espere, por favor',
	MEGABYTES: 'Megabytes',
	TICKETS: 'Tickets',
	USER_GROUPS: 'Grupos de usuario',
	RECENT_SESSIONS: 'Sesiones recientes',
	ACTIVE_SESSION_SESSION_START_TIME: 'HORA DE INICIO DE SESIÓN',
	ACTIVE_SESSION_TOTAL_VOLUME: 'VOLUMEN TOTAL',
	ACTIVE_SESSION_TRANSMITTING: 'TRANSMITIENDO',
	ACTIVE_SESSION_RECEIVING: 'RECIBIENDO',
	CURRENTLY_ACTIVE_SESSION: 'Sesión activa actual',
	ACTIVE_SESSION_CARRIER: 'OPERADOR',
	ACTIVE_SESSION_IP_ADDRESS: 'DIRECCIÓN DE IP',
	RESET_TO_DEFAULT: 'Restablecer a predeterminado',
	DATE_TIME: 'Fecha/Hora',
	SESSION_END: 'Fin de sesión',
	SEND: 'Enviar',
	SEND_SMS_TO_THIS_DEVICE: 'Enviar SMS a este dispositivo.',
	TYPE_A_MESSAGE: 'Escriba el mensaje que desee enviar a este dispositivo utilizando el servicio de SMS. Se pueden aplicar tarifas estándar.',
	MESSAGES_WILL_APPEAR: 'Los mensajes se mostrarán como enviados y aparecerán en los informes de uso de SMS. Se pueden aplicar tarifas.',
	SENT_MESSAGES_HISTORY: 'Historial de mensajes enviados',
	CHARACTERS_REMAINING: 'quedan {remaining} caracteres...',
	EXPORT_SESSIONS_DATA_FOR_ID: 'Sesiones recientes de {id}',
	EXPORT_DATA: 'Exportar datos',
	BYTE_ABBREVIATION: 'B',
	UTC_TIME_ZONE: ' UTC ',
	SEND_SMS_TO_ENDPOINT: 'Enviar SMS a terminal',
	SMS: 'SMS',
	OFFPEAK_USAGE_FLAG: 'Identificador de uso en hora no pico',
	DATA_USAGE_TOTAL: 'Uso de datos - Total bruto',
	DATA_USAGE_UPLINK: 'Uso de datos - Ascendente bruto',
	DATA_USAGE_DOWNLINK: 'Uso de datos - Descendiente bruto',
	DATA_USAGE_ROUNDED: 'Uso de datos - Redondeado',
	DEVICE_IP_ADDRESS: 'Dirección IP del equipo',
	SESSION_DURATION: 'Duración de sesión',
	RECORD_SEQUENCE_NUMBER: 'Número secuencial de registro',
	CHARGING_ID: 'ID de cobro',
	SESSION_CLOSE_CAUSE: 'Causa de cierre de sesión',
	TECH_TYPE: 'TIPO DE TECNOLOGÍA',
	PGW_ADDRESS: 'Dirección de PGW',
	TIME_OF_FIRST_USAGE: 'Hora de primer uso',
	TIME_OF_LAST_USAGE: 'Hora de último uso',
	TIME_USAGE_DURATION: 'Duración de la hora de uso',
	STREAM_ID: 'ID de flujo',
	CELL_GLOBAL_IDENTIFIER: 'CGI (Identificador global de la célula)',
	SERVICE_AREA_IDENTIFIER: 'SAI (Identificador de área de servicio)',
	ROUTING_AREA_IDENTIFIER: 'RAI (Identificador de zona de enrutamiento)',
	TRACKING_AREA_IDENTITY: 'TAI (Identificador de área de seguimiento)',
	E_UTRAN_CGI: 'ECGI (E-UTRAN CGI)',
	LOCATION_AREA_IDENTIFIER: 'LAI (Identificador de área de localización)',
	SERVING_SGSN: 'SGSN de servicio',
	COUNTRY_LASTEST_SESSION: 'País (última sesión)',
	CARRIER_LASTEST_SESSION: 'Operador (última sesión)',
	BILLABLE_FLAG: 'Indicador facturable',
	CYCLE_MONTH: 'Mes del ciclo',
	CYCLE_YEAR: 'Año del ciclo',
	ASSIGNED_RATE_PLAN_ID: 'ID de plan de tarifas asignado',
	ASSIGNED_RATING_ZONE_ID: 'ID de Zona de clasificación asignada',
	RATE_PLAN_VERSION_ID: 'ID de versión de plan de tarifas',
	SERVICE_TYPE: 'Tipo de servicio',
	TRAFFIC_DETAIL_ID: 'ID de detalles de tráfico',
	RECORD_RECEIVED_DATE: 'Fecha de recepción de registro',
	RECORD_OPEN_TIME: 'Hora de apertura de registro',
	OPERATOR_NETWORK: 'Red del operador',
	OPERATOR_ACCOUNT_ID: 'ID de cuenta de operador',
	TAP_CODE: 'Código TAP',
	USAGE_HISTORY: 'Historial de uso',
	DATA: 'Datos',
	VOICE: 'VOZ',
	HLR_HSS_DETAILS_FOR_ENDPOINT: 'Detalles de HLR/HSS para la ID de terminal {id}',
	NEED_HELP: '¿Necesita ayuda?',
	MB: 'MB',
	CONNECTION_STATUS: 'Estado de conexión',
	MESSAGE_CONTENT: 'Contenido del mensaje',
	B: 'B',
	CYCLE_TO_DATE_USAGE_TOTAL: 'Uso total del ciclo hasta la fecha',
	AVERAGE_DAILY_USE: 'Promedio de uso diario',
	DATA_USAGE_DOWNLINK_CHART: 'Enlace descendente',
	DATA_USAGE_UPLINK_CHART: 'Enlace ascendente',
	GDSP: 'GDSP',
	SECOND: 'Segundo',
	SECONDS: 'Segundos',
	DURATION_RAW: 'Duración - Bruto',
	DURATION_ROUNDED: 'Duración - Aproximada',
	USAGE_TYPE: 'Tipo de uso',
	SELECT_BILLING_CYCLE: 'Seleccionar ciclo de facturación',
	RANGE: 'Rango',
	CUSTOM_FIELDS_VALUES_UPDATED: 'Se actualizaron los valores de campo personalizado',
	CUSTOM_FIELDS_VALUES_UPDATED_ERROR: 'Fallo al actualizar los valores de campo personalizado',
	SXMACC: 'SXMACC',
	CHARTS_BILLING_CYCLE_ABBREVIATION: 'BC {billcycle}',
	DATE_RANGE_INPUT_LABEL: 'De: {startDate} A: {endDate}',
	UH_OH_THERE_WERE_SOME_PROBLEMS_TRYING_TO_LOG_YOU_IN_PLEASE_RETRY_AGAIN_IN_A_FEW_MOMENTS: '¡Vaya! Hubo problemas al tratar de iniciar su sesión. Vuelva a intentarlo en unos momentos. Si el problema continúa, póngase en contacto con el equipo de asistencia.',

	//  LANGUAGE OPTIONS
	ENGLISH: 'Inglés (EE. UU.)',
	FRANCAIS: 'Francés (Francia)',
	ESPANOL: 'Español (España)',
	ITALIANO: 'Italiano (Italia)',
	NEDERLANDS: 'Holandés (Holanda)',
	DEUTCHE: 'Alemán (Alemania)',
	PORTUGUES: 'Portugués (Brasil)',
	CHINESE: '中文 (中国)',

	//  COUNTRIES
	COUNTRY_UNITED_STATES: 'Estados Unidos',
	COUNTRY_AFGHANISTAN: 'Afganistán',
	COUNTRY_ALAND_ISLANDS: 'Aland Islands',
	COUNTRY_ALBANIA: 'Albania',
	COUNTRY_ALGERIA: 'Argelia',
	COUNTRY_AMERICAN_SAMOA: 'American Samoa',
	COUNTRY_ANDORRA: 'Andorra',
	COUNTRY_ANGOLA: 'Angola',
	COUNTRY_ANGUILLA: 'Anguilla',
	COUNTRY_ANTARCTICA: 'Antártida',
	COUNTRY_ANTIGUA_AND_BARBUDA: 'Antigua And Barbuda',
	COUNTRY_ARGENTINA: 'Argentina',
	COUNTRY_ARMENIA: 'Armenia',
	COUNTRY_ARUBA: 'Aruba',
	COUNTRY_AUSTRALIA: 'Australia',
	COUNTRY_AUSTRIA: 'Austria',
	COUNTRY_AZERBAIJAN: 'Azerbaiyán',
	COUNTRY_BAHAMAS_THE: 'Bahamas',
	COUNTRY_BAHRAIN: 'Bahrain',
	COUNTRY_BANGLADESH: 'Bangladesh',
	COUNTRY_BARBADOS: 'Barbados',
	COUNTRY_BELARUS: 'Bielorrusia',
	COUNTRY_BELGIUM: 'Bélgica',
	COUNTRY_BELIZE: 'Belize',
	COUNTRY_BENIN: 'Benin',
	COUNTRY_BERMUDA: 'Bermuda',
	COUNTRY_BHUTAN: 'Bután',
	COUNTRY_BOLIVIA: 'Bolivia',
	COUNTRY_BOSNIA_AND_HERZEGOVINA: 'Bosnia Herzegovina',
	COUNTRY_BOTSWANA: 'Botswana',
	COUNTRY_BOUVET_ISLAND: 'Bouvet Island',
	COUNTRY_BRAZIL: 'Brasil',
	COUNTRY_BRITISH_INDIAN_OCEAN_TERRITORY: 'British Indian Ocean Territory',
	COUNTRY_BRUNEI: 'Brunei',
	COUNTRY_BULGARIA: 'Bulgaria',
	COUNTRY_BURKINA_FASO: 'Burkina Faso',
	COUNTRY_BURUNDI: 'Burundi',
	COUNTRY_CAMBODIA: 'Camboya',
	COUNTRY_CAMEROON: 'Camerún',
	COUNTRY_CANADA: 'Canadá',
	COUNTRY_CAPE_VERDE: 'Cabo Verde',
	COUNTRY_CAYMAN_ISLANDS: 'Islas Caimán',
	COUNTRY_CENTRAL_AFRICAN_REPUBLIC: 'República Centroafricana',
	COUNTRY_CHAD: 'Chad',
	COUNTRY_CHILE: 'Chile',
	COUNTRY_CHINA: 'China',
	COUNTRY_CHRISTMAS_ISLAND: 'Isla de Navidad',
	COUNTRY_COCOS_KEELING_ISLANDS: 'Cocos (Keeling) Islands',
	COUNTRY_COLOMBIA: 'Colombia',
	COUNTRY_COMOROS: 'Comoros',
	COUNTRY_CONGO: 'El Congo',
	COUNTRY_CONGO_THE_DEMOCRATIC_REPUBLIC_OF_THE: 'República Democrática de El Congo',
	COUNTRY_COOK_ISLANDS: 'Islas Cook',
	COUNTRY_COSTA_RICA: 'Costa Rica',
	COUNTRY_COTE_D__IVOIRE_IVORY_COAST_: 'Costa de Marfil',
	COUNTRY_CROATIA_HRVATSKA_: 'Croacia',
	COUNTRY_CUBA: 'Cuba',
	COUNTRY_CYPRUS: 'Chipre',
	COUNTRY_CZECH_REPUBLIC: 'República Checa',
	COUNTRY_DENMARK: 'Dinamarca',
	COUNTRY_DJIBOUTI: 'Djibouti',
	COUNTRY_DOMINICA: 'Dominica',
	COUNTRY_DOMINICAN_REPUBLIC: 'República Dominicana',
	COUNTRY_EAST_TIMOR: 'Timor Oriental',
	COUNTRY_ECUADOR: 'Ecuador',
	COUNTRY_EGYPT: 'Egipto',
	COUNTRY_EL_SALVADOR: 'El Salvador',
	COUNTRY_EQUATORIAL_GUINEA: 'Guinea Ecuatorial',
	COUNTRY_ERITREA: 'Eritrea',
	COUNTRY_ESTONIA: 'Estonia',
	COUNTRY_ETHIOPIA: 'Etiopía',
	COUNTRY_FALKLAND_ISLANDS: 'Islas Malvinas',
	COUNTRY_FAROE_ISLANDS: 'Islas Faroe',
	COUNTRY_FIJI_ISLANDS: 'Fiji',
	COUNTRY_FINLAND: 'Finlandia',
	COUNTRY_FRANCE: 'Francia',
	COUNTRY_FRENCH_GUIANA: 'Guayana Francesa',
	COUNTRY_FRENCH_POLYNESIA: 'Polinesia Francesa',
	COUNTRY_FRENCH_SOUTHERN_TERRITORIES: 'Territorios Franceses del Sur',
	COUNTRY_GABON: 'Gabón',
	COUNTRY_GAMBIA_THE: 'Gambia',
	COUNTRY_GEORGIA: 'Georgia',
	COUNTRY_GERMANY: 'Alemania',
	COUNTRY_GHANA: 'Ghana',
	COUNTRY_GIBRALTAR: 'Gibraltar',
	COUNTRY_GREECE: 'Grecia',
	COUNTRY_GREENLAND: 'Groenlandia',
	COUNTRY_GRENADA: 'Granada',
	COUNTRY_GUADELOUPE: 'Guadalupe',
	COUNTRY_GUAM: 'Guam',
	COUNTRY_GUATEMALA: 'Guatemala',
	COUNTRY_GUERNSEY_AND_ALDERNEY: 'Guernsey and Alderney',
	COUNTRY_GUINEA: 'Guinea',
	COUNTRY_GUINEA_BISSAU: 'Guinea-Bissau',
	COUNTRY_GUYANA: 'Guayana',
	COUNTRY_HAITI: 'Haití',
	COUNTRY_HEARD_AND_MCDONALD_ISLANDS: 'Heard and McDonald Islands',
	COUNTRY_HONDURAS: 'Honduras',
	'COUNTRY_HONG_KONG_S.A.R.': 'Hong Kong S.A.R.',
	COUNTRY_HUNGARY: 'Hungría',
	COUNTRY_ICELAND: 'Islandia',
	COUNTRY_INDIA: 'India',
	COUNTRY_INDONESIA: 'Indonesia',
	COUNTRY_IRAN: 'Irán',
	COUNTRY_IRAQ: 'Iraq',
	COUNTRY_IRELAND: 'Irlanda',
	COUNTRY_ISRAEL: 'Israel',
	COUNTRY_ITALY: 'Italia',
	COUNTRY_JAMAICA: 'Jamaica',
	COUNTRY_JAPAN: 'Japón',
	COUNTRY_JERSEY: 'Jersey',
	COUNTRY_JORDAN: 'Jordania',
	COUNTRY_KAZAKHSTAN: 'Kazajstán',
	COUNTRY_KENYA: 'Kenia',
	COUNTRY_KIRIBATI: 'Kiribati',
	COUNTRY_KOREA_NORTH: 'Corea del Norte',
	COUNTRY_KOREA_SOUTH: 'Corea del Sur',
	COUNTRY_KUWAIT: 'Kuwait',
	COUNTRY_KYRGYZSTAN: 'Kirguistán',
	COUNTRY_LAOS: 'Laos',
	COUNTRY_LATVIA: 'Latvia',
	COUNTRY_LEBANON: 'Líbano',
	COUNTRY_LESOTHO: 'Lesoto',
	COUNTRY_LIBERIA: 'Liberia',
	COUNTRY_LIBYA: 'Libia',
	COUNTRY_LIECHTENSTEIN: 'Liechtenstein',
	COUNTRY_LITHUANIA: 'Lituania',
	COUNTRY_LUXEMBOURG: 'Luxemburgo',
	'COUNTRY_MACAU_S.A.R.': 'Macau S.A.R.',
	COUNTRY_MACEDONIA: 'Macedonia',
	COUNTRY_MADAGASCAR: 'Madagascar',
	COUNTRY_MALAWI: 'Malawi',
	COUNTRY_MALAYSIA: 'Malasia',
	COUNTRY_MALDIVES: 'Maldivas',
	COUNTRY_MALI: 'Mali',
	COUNTRY_MALTA: 'Malta',
	COUNTRY_MAN_ISLE_OF_: 'Isla de Man',
	COUNTRY_MARSHALL_ISLANDS: 'Islas Marshall',
	COUNTRY_MARTINIQUE: 'Martinica',
	COUNTRY_MAURITANIA: 'Mauritania',
	COUNTRY_MAURITIUS: 'Mauricio',
	COUNTRY_MAYOTTE: 'Mayotte',
	COUNTRY_MEXICO: 'México',
	COUNTRY_MICRONESIA: 'Micronesia',
	COUNTRY_MOLDOVA: 'Moldavia',
	COUNTRY_MONACO: 'Mónaco',
	COUNTRY_MONGOLIA: 'Mongolia',
	COUNTRY_MONTSERRAT: 'Montserrat',
	COUNTRY_MOROCCO: 'Marruecos',
	COUNTRY_MOZAMBIQUE: 'Mozambique',
	COUNTRY_MYANMAR: 'Myanmar',
	COUNTRY_NAMIBIA: 'Namibia',
	COUNTRY_NAURU: 'Nauru',
	COUNTRY_NEPAL: 'Nepal',
	COUNTRY_NETHERLANDS: 'Holanda',
	COUNTRY_NETHERLANDS_ANTILLES: 'Antillas Holandesas',
	COUNTRY_NEW_CALEDONIA: 'Nueva Caledonia',
	COUNTRY_NEW_ZEALAND: 'Nueva Zelanda',
	COUNTRY_NICARAGUA: 'Nicaragua',
	COUNTRY_NIGER: 'Níger',
	COUNTRY_NIGERIA: 'Nigeria',
	COUNTRY_NIUE: 'Niue',
	COUNTRY_NORFOLK_ISLAND: 'Isla de Norfolk',
	COUNTRY_NORTHERN_MARIANA_ISLANDS: 'Islas Marianas del Norte',
	COUNTRY_NORWAY: 'Noruega',
	COUNTRY_OMAN: 'Omán',
	COUNTRY_PAKISTAN: 'Pakistán',
	COUNTRY_PALAU: 'Palau',
	COUNTRY_PALESTINIAN_TERRITORY_OCCUPIED: 'Territorio Palestino Ocupado',
	COUNTRY_PANAMA: 'Panamá',
	COUNTRY_PAPUA_NEW_GUINEA: 'Papúa Nueva Guinea',
	COUNTRY_PARAGUAY: 'Paraguay',
	COUNTRY_PERU: 'Perú',
	COUNTRY_PHILIPPINES: 'Filipinas',
	COUNTRY_PITCAIRN_ISLAND: 'Pitcairn Island',
	COUNTRY_POLAND: 'Polonia',
	COUNTRY_PORTUGAL: 'Portugal',
	COUNTRY_PUERTO_RICO: 'Puerto Rico',
	COUNTRY_QATAR: 'Qatar',
	COUNTRY_REUNION: 'Reunión',
	COUNTRY_ROMANIA: 'Rumanía',
	COUNTRY_RUSSIA: 'Rusia',
	COUNTRY_RWANDA: 'Ruanda',
	COUNTRY_SAINT_HELENA: 'Santa Helena',
	COUNTRY_SAINT_KITTS_AND_NEVIS: 'Saint Kitts And Nevis',
	COUNTRY_SAINT_LUCIA: 'Santa Lucía',
	COUNTRY_SAINT_PIERRE_AND_MIQUELON: 'Saint Pierre and Miquelon',
	COUNTRY_SAINT_VINCENT_AND_THE_GRENADINES: 'San Vicente y las Granadinas',
	COUNTRY_SAMOA: 'Samoa',
	COUNTRY_SAN_MARINO: 'San Marino',
	COUNTRY_SAO_TOME_AND_PRINCIPE: 'Santo Tomé y Príncipe',
	COUNTRY_SAUDI_ARABIA: 'Arabia Saudí',
	COUNTRY_SENEGAL: 'Senegal',
	COUNTRY_SERBIA: 'Serbia',
	COUNTRY_SEYCHELLES: 'Seychelles',
	COUNTRY_SIERRA_LEONE: 'Sierra Leona',
	COUNTRY_SINGAPORE: 'Singapur',
	COUNTRY_SLOVAKIA: 'Eslovaquia',
	COUNTRY_SLOVENIA: 'Eslovenia',
	COUNTRY_SOLOMON_ISLANDS: 'Islas Salomón',
	COUNTRY_SOMALIA: 'Somalia',
	COUNTRY_SOUTH_AFRICA: 'Sudáfrica',
	COUNTRY_SOUTH_GEORGIA: 'Georgias del Sur',
	COUNTRY_SOUTH_SUDAN: 'Sudán Meridional',
	COUNTRY_SPAIN: 'España',
	COUNTRY_SRI_LANKA: 'Sri Lanka',
	COUNTRY_SUDAN: 'Sudán',
	COUNTRY_SURINAME: 'Surinám',
	COUNTRY_SVALBARD_AND_JAN_MAYEN_ISLANDS: 'Svalbard And Jan Mayen Islands',
	COUNTRY_SWAZILAND: 'Swazilandia',
	COUNTRY_SWEDEN: 'Suecia',
	COUNTRY_SWITZERLAND: 'Suiza',
	COUNTRY_SYRIA: 'Siria',
	COUNTRY_TAIWAN: 'Taiwán',
	COUNTRY_TAJIKISTAN: 'Taykistán',
	COUNTRY_TANZANIA: 'Tanzania',
	COUNTRY_THAILAND: 'Tailandia',
	COUNTRY_TOGO: 'Togo',
	COUNTRY_TOKELAU: 'Tokelau',
	COUNTRY_TONGA: 'Tonga',
	COUNTRY_TRINIDAD_AND_TOBAGO: 'Trinidad Tobago',
	COUNTRY_TUNISIA: 'Túnez',
	COUNTRY_TURKEY: 'Turquía',
	COUNTRY_TURKMENISTAN: 'Turkmenistán',
	COUNTRY_TURKS_AND_CAICOS_ISLANDS: 'Islas Turcas y Caicos',
	COUNTRY_TUVALU: 'Tuvalu',
	COUNTRY_UGANDA: 'Uganda',
	COUNTRY_UKRAINE: 'Ucrania',
	COUNTRY_UNITED_ARAB_EMIRATES: 'Emiratos Árabes Unidos',
	COUNTRY_UNITED_KINGDOM: 'Reino Unido',
	COUNTRY_UNITED_STATES_MINOR_OUTLYING_ISLANDS: 'Islas Menores de los Estados Unidos',
	COUNTRY_URUGUAY: 'Uruguay',
	COUNTRY_UZBEKISTAN: 'Uzbekistán',
	COUNTRY_VANUATU: 'Vanuatu',
	COUNTRY_VATICAN_CITY_STATE_HOLY_SEE_: 'El Vaticano',
	COUNTRY_VENEZUELA: 'Venezuela',
	COUNTRY_VIETNAM: 'Vietnam',
	COUNTRY_VIRGIN_ISLANDS_BRITISH_: 'Islas Vírgenes (Británicas)',
	COUNTRY_VIRGIN_ISLANDS_US_: 'Virgin Islands (EE.UU.)',
	COUNTRY_WALLIS_AND_FUTUNA_ISLANDS: 'Wallis And Futuna Islands',
	COUNTRY_WESTERN_SAHARA: 'Sáhara Occidental',
	COUNTRY_YEMEN: 'Yemen',
	COUNTRY_ZAMBIA: 'Zambia',
	COUNTRY_ZIMBABWE: 'Zimbabwe',

	//  STATE/PROVINCE
	STATE_ANDAMAN_AND_NICOBAR_ISLANDS: 'Andaman and Nicobar Islands',
	STATE_ANDHRA_PRADESH: 'Andhra Pradesh',
	STATE_ARUNACHAL_PRADESH: 'Arunachal Pradesh',
	STATE_ASSAM: 'Assam',
	STATE_BIHAR: 'Bihar',
	STATE_CHANDIGARH: 'Chandigarh',
	STATE_CHHATTISGARH: 'Chhattisgarh',
	STATE_DADRA_AND_NAGAR_HAVELI: 'Dadra and Nagar Haveli',
	STATE_DAMAN_AND_DIU: 'Daman and Diu',
	STATE_DELHI: 'Delhi',
	STATE_GOA: 'Goa',
	STATE_GUJARAT: 'Gujarat',
	STATE_HARYANA: 'Haryana',
	STATE_HIMACHAL_PRADESH: 'Himachal Pradesh',
	STATE_JAMMU_AND_KASHMIR: 'Jammu and Kashmir',
	STATE_JHARKHAND: 'Jharkhand',
	STATE_KARNATAKA: 'Karnataka',
	STATE_KENMORE: 'Kenmore',
	STATE_KERALA: 'Kerala',
	STATE_LAKSHADWEEP: 'Lakshadweep',
	STATE_MADHYA_PRADESH: 'Madhya Pradesh',
	STATE_MAHARASHTRA: 'Maharashtra',
	STATE_MANIPUR: 'Manipur',
	STATE_MEGHALAYA: 'Meghalaya',
	STATE_MIZORAM: 'Mizoram',
	STATE_NAGALAND: 'Nagaland',
	STATE_NARORA: 'Narora',
	STATE_NATWAR: 'Natwar',
	STATE_ODISHA: 'Odisha',
	STATE_PASCHIM_MEDINIPUR: 'Paschim Medinipur',
	STATE_PONDICHERRY: 'Pondicherry',
	STATE_PUNJAB: 'Punjab',
	STATE_RAJASTHAN: 'Rajasthan',
	STATE_SIKKIM: 'Sikkim',
	STATE_TAMIL_NADU: 'Tamil Nadu',
	STATE_TELANGANA: 'Telangana',
	STATE_TRIPURA: 'Tripura',
	STATE_UTTAR_PRADESH: 'Uttar Pradesh',
	STATE_UTTARAKHAND: 'Uttarakhand',
	STATE_VAISHALI: 'Vaishali',
	STATE_WEST_BENGAL: 'West Bengal',
	STATE_BADAKHSHAN: 'Badakhshan',
	STATE_BADGIS: 'Badgis',
	STATE_BAGLAN: 'Baglan',
	STATE_BALKH: 'Balkh',
	STATE_BAMIYAN: 'Bamiyan',
	STATE_FARAH: 'Farah',
	STATE_FARYAB: 'Faryab',
	STATE_GAWR: 'Gawr',
	STATE_GAZNI: 'Gazni',
	STATE_HERAT: 'Herat',
	STATE_ALABAMA: 'Alabama',
	STATE_ALASKA: 'Alaska',
	STATE_ARIZONA: 'Arizona',
	STATE_ARKANSAS: 'Arkansas',
	STATE_GEORGIA: 'Georgia',
	STATE_NEW_YORK: 'Nueva York',
	STATE_VERMONT: 'Vermont',
	STATE_WASHINGTON: 'Washington',
	STATE_HILMAND: 'Hilmand',
	STATE_JAWZJAN: 'Jawzjan',
	STATE_KABUL: 'Kabul',
	STATE_KAPISA: 'Kapisa',
	STATE_KHAWST: 'Khawst',
	STATE_KUNAR: 'Kunar',
	STATE_LAGMAN: 'Lagman',
	STATE_LAWGHAR: 'Lawghar',
	STATE_NANGARHAR: 'Nangarhar',
	STATE_NIMRUZ: 'Nimruz',
	STATE_NURISTAN: 'Nuristan',
	STATE_PAKTIKA: 'Paktika',
	STATE_PAKTIYA: 'Paktiya',
	STATE_PARWAN: 'Parwan',
	STATE_QANDAHAR: 'Qandahar',
	STATE_QUNDUZ: 'Qunduz',
	STATE_SAMANGAN: 'Samangan',
	STATE_SAR_E_PUL: 'Sar-e Pul',
	STATE_TAKHAR: 'Takhar',
	STATE_URUZGAN: 'Uruzgan',
	STATE_WARDAG: 'Wardag',
	STATE_ZABUL: 'Zabul',
	STATE_BERAT: 'Berat',
	STATE_BULQIZE: 'Bulqize',
	STATE_DELVINE: 'Delvine',
	STATE_DEVOLL: 'Devoll',
	STATE_DIBRE: 'Dibre',
	STATE_DURRES: 'Durres',
	STATE_ELBASAN: 'Elbasan',
	STATE_FIER: 'Fier',
	STATE_GJIROKASTER: 'Gjirokaster',
	STATE_GRAMSH: 'Gramsh',
	STATE_HAS: 'Has',
	STATE_KAVAJE: 'Kavaje',
	STATE_KOLONJE: 'Kolonje',
	STATE_KORCE: 'Korce',
	STATE_KRUJE: 'Kruje',
	STATE_KUCOVE: 'Kucove',
	STATE_KUKES: 'Kukes',
	STATE_KURBIN: 'Kurbin',
	STATE_LEZHE: 'Lezhe',
	STATE_LIBRAZHD: 'Librazhd',
	STATE_LUSHNJE: 'Lushnje',
	STATE_MALLAKASTER: 'Mallakaster',
	STATE_MALSI_E_MADHE: 'Malsi e Madhe',
	STATE_MAT: 'Mat',
	STATE_MIRDITE: 'Mirdite',
	STATE_PEQIN: 'Peqin',
	STATE_PERMET: 'Permet',
	STATE_POGRADEC: 'Pogradec',
	STATE_PUKE: 'Puke',
	STATE_SARANDE: 'Sarande',
	STATE_SHKODER: 'Shkoder',
	STATE_SKRAPAR: 'Skrapar',
	STATE_TEPELENE: 'Tepelene',
	STATE_TIRANE: 'Tirane',
	STATE_TROPOJE: 'Tropoje',
	STATE_VLORE: 'Vlore',
	STATE_AYN_DAFLAH: 'Ayn Daflah',
	STATE_AYN_TAMUSHANAT: 'Ayn Tamushanat',
	STATE_ADRAR: 'Adrar',
	STATE_ALGIERS: 'Algiers',
	STATE_ANNABAH: 'Annabah',
	STATE_BASHSHAR: 'Bashshar',
	STATE_BATNAH: 'Batnah',
	STATE_BIJAYAH: 'Bijayah',
	STATE_BISKRAH: 'Biskrah',
	STATE_BLIDAH: 'Blidah',
	STATE_BUIRAH: 'Buirah',
	STATE_BUMARDAS: 'Bumardas',
	STATE_BURJ_BU_ARRIRIJ: 'Burj Bu Arririj',
	STATE_GHALIZAN: 'Ghalizan',
	STATE_GHARDAYAH: 'Ghardayah',
	STATE_ILIZI: 'Ilizi',
	STATE_JIJILI: 'Jijili',
	STATE_JILFAH: 'Jilfah',
	STATE_KHANSHALAH: 'Khanshalah',
	STATE_MASILAH: 'Masilah',
	STATE_MIDYAH: 'Midyah',
	STATE_MILAH: 'Milah',
	STATE_MUASKAR: 'Muaskar',
	STATE_MUSTAGHANAM: 'Mustaghanam',
	STATE_NAAMA: 'Naama',
	STATE_ORAN: 'Oran',
	STATE_OUARGLA: 'Ouargla',
	STATE_QALMAH: 'Qalmah',
	STATE_QUSTANTINAH: 'Qustantinah',
	STATE_SAKIKDAH: 'Sakikdah',
	STATE_SATIF: 'Satif',
	STATE_SAYDA: 'Sayda',
	STATE_SIDI_BAN_AL___ABBAS: "Sidi ban-al-'Abbas",
	STATE_SUQ_AHRAS: 'Suq Ahras',
	STATE_TAMANGHASAT: 'Tamanghasat',
	STATE_TIBAZAH: 'Tibazah',
	STATE_TIBISSAH: 'Tibissah',
	STATE_TILIMSAN: 'Tilimsan',
	STATE_TINDUF: 'Tinduf',
	STATE_TISAMSILT: 'Tisamsilt',
	STATE_TIYARAT: 'Tiyarat',
	STATE_TIZI_WAZU: 'Tizi Wazu',
	STATE_UMM_AL_BAWAGHI: 'Umm-al-Bawaghi',
	STATE_WAHRAN: 'Wahran',
	STATE_WARQLA: 'Warqla',
	STATE_WILAYA_D_ALGER: 'Wilaya d Alger',
	STATE_WILAYA_DE_BEJAIA: 'Wilaya de Bejaia',
	STATE_WILAYA_DE_CONSTANTINE: 'Wilaya de Constantine',
	STATE_AL_AGHWAT: 'al-Aghwat',
	STATE_AL_BAYADH: 'al-Bayadh',
	STATE_AL_JAZA__IR: "al-Jaza'ir",
	STATE_AL_WAD: 'al-Wad',
	STATE_ASH_SHALIF: 'ash-Shalif',
	STATE_AT_TARIF: 'at-Tarif',
	STATE_EASTERN: 'Eastern',
	STATE_MANU__A: "Manu'a",
	STATE_SWAINS_ISLAND: 'Swains Island',
	STATE_WESTERN: 'Western',
	STATE_ANDORRA_LA_VELLA: 'Andorra la Vella',
	STATE_CANILLO: 'Canillo',
	STATE_ENCAMP: 'Encamp',
	STATE_LA_MASSANA: 'La Massana',
	STATE_LES_ESCALDES: 'Les Escaldes',
	STATE_ORDINO: 'Ordino',
	STATE_SANT_JULIA_DE_LORIA: 'Sant Julia de Loria',
	STATE_BENGO: 'Bengo',
	STATE_BENGUELA: 'Benguela',
	STATE_BIE: 'Bie',
	STATE_CABINDA: 'Cabinda',
	STATE_CUNENE: 'Cunene',
	STATE_HUAMBO: 'Huambo',
	STATE_HUILA: 'Huila',
	STATE_KUANDO_KUBANGO: 'Kuando-Kubango',
	STATE_KWANZA_NORTE: 'Kwanza Norte',
	STATE_KWANZA_SUL: 'Kwanza Sul',
	STATE_LUANDA: 'Luanda',
	STATE_LUNDA_NORTE: 'Lunda Norte',
	STATE_LUNDA_SUL: 'Lunda Sul',
	STATE_MALANJE: 'Malanje',
	STATE_MOXICO: 'Moxico',
	STATE_NAMIBE: 'Namibe',
	STATE_UIGE: 'Uige',
	STATE_ZAIRE: 'Zaire',
	STATE_OTHER_PROVINCES: 'Otras provincias',
	STATE_SECTOR_CLAIMED_BY_ARGENTINA_CH: 'Área reclamada por Argentina/Ch',
	STATE_SECTOR_CLAIMED_BY_ARGENTINA_UK: 'Área reclamada por Argentina/RU',
	STATE_SECTOR_CLAIMED_BY_AUSTRALIA: 'Área reclamada por Australia',
	STATE_SECTOR_CLAIMED_BY_FRANCE: 'Área reclamada por Francia',
	STATE_SECTOR_CLAIMED_BY_NEW_ZEALAND: 'Área reclamada por Nueva Zelanda',
	STATE_SECTOR_CLAIMED_BY_NORWAY: 'Área reclamada por Noruega',
	STATE_UNCLAIMED_SECTOR: 'Área no reclamada',
	STATE_BARBUDA: 'Barbuda',
	STATE_SAINT_GEORGE: 'Saint George',
	STATE_SAINT_JOHN: 'Saint John',
	STATE_SAINT_MARY: 'Saint Mary',
	STATE_SAINT_PAUL: 'Saint Paul',
	STATE_SAINT_PETER: 'Saint Peter',
	STATE_SAINT_PHILIP: 'Saint Philip',
	STATE_BUENOS_AIRES: 'Buenos Aires',
	STATE_CATAMARCA: 'Catamarca',
	STATE_CHACO: 'Chaco',
	STATE_CHUBUT: 'Chubut',
	STATE_CORDOBA: 'Cordoba',
	STATE_CORRIENTES: 'Corrientes',
	STATE_DISTRITO_FEDERAL: 'Distrito Federal',
	STATE_ENTRE_RIOS: 'Entre Rios',
	STATE_FORMOSA: 'Formosa',
	STATE_JUJUY: 'Jujuy',
	STATE_LA_PAMPA: 'La Pampa',
	STATE_HIDD: 'Hidd',
	STATE_LA_RIOJA: 'La Rioja',
	STATE_MENDOZA: 'Mendoza',
	STATE_MISIONES: 'Misiones',
	STATE_NEUQUEN: 'Neuquen',
	STATE_RIO_NEGRO: 'Rio Negro',
	STATE_SALTA: 'Salta',
	STATE_SAN_JUAN: 'San Juan',
	STATE_SAN_LUIS: 'San Luis',
	STATE_SANTA_CRUZ: 'Santa Cruz',
	STATE_SANTA_FE: 'Santa Fe',
	STATE_SANTIAGO_DEL_ESTERO: 'Santiago del Estero',
	STATE_TIERRA_DEL_FUEGO: 'Tierra del Fuego',
	STATE_TUCUMAN: 'Tucuman',
	STATE_ARAGATSOTN: 'Aragatsotn',
	STATE_ARARAT: 'Ararat',
	STATE_ARMAVIR: 'Armavir',
	STATE_GEGHARKUNIK: 'Gegharkunik',
	STATE_KOTAIK: 'Kotaik',
	STATE_LORI: 'Lori',
	STATE_SHIRAK: 'Shirak',
	STATE_STEPANAKERT: 'Stepanakert',
	STATE_SYUNIK: 'Syunik',
	STATE_TAVUSH: 'Tavush',
	STATE_VAYOTS_DZOR: 'Vayots Dzor',
	STATE_YEREVAN: 'Yerevan',
	STATE_ARUBA: 'Aruba',
	STATE_AUCKLAND: 'Auckland',
	STATE_AUSTRALIAN_CAPITAL_TERRITORY: 'Australian Capital Territory',
	STATE_BALGOWLAH: 'Balgowlah',
	STATE_BALMAIN: 'Balmain',
	STATE_BANKSTOWN: 'Bankstown',
	STATE_BAULKHAM_HILLS: 'Baulkham Hills',
	STATE_BONNET_BAY: 'Bonnet Bay',
	STATE_CAMBERWELL: 'Camberwell',
	STATE_CAROLE_PARK: 'Carole Park',
	STATE_CASTLE_HILL: 'Castle Hill',
	STATE_CAULFIELD: 'Caulfield',
	STATE_CHATSWOOD: 'Chatswood',
	STATE_CHELTENHAM: 'Cheltenham',
	STATE_CHERRYBROOK: 'Cherrybrook',
	STATE_CLAYTON: 'Clayton',
	STATE_COLLINGWOOD: 'Collingwood',
	STATE_FRENCHS_FOREST: 'Frenchs Forest',
	STATE_HAWTHORN: 'Hawthorn',
	STATE_JANNNALI: 'Jannnali',
	STATE_KNOXFIELD: 'Knoxfield',
	STATE_MELBOURNE: 'Melbourne',
	STATE_NEW_SOUTH_WALES: 'New South Wales',
	STATE_NORTHERN_TERRITORY: 'Northern Territory',
	STATE_PERTH: 'Perth',
	STATE_QUEENSLAND: 'Queensland',
	STATE_SOUTH_AUSTRALIA: 'South Australia',
	STATE_TASMANIA: 'Tasmania',
	STATE_TEMPLESTOWE: 'Templestowe',
	STATE_VICTORIA: 'Victoria',
	STATE_WERRIBEE_SOUTH: 'Werribee south',
	STATE_WESTERN_AUSTRALIA: 'Western Australia',
	STATE_WHEELER: 'Wheeler',
	STATE_BUNDESLAND_SALZBURG: 'Bundesland Salzburg',
	STATE_BUNDESLAND_STEIERMARK: 'Bundesland Steiermark',
	STATE_BUNDESLAND_TIROL: 'Bundesland Tirol',
	STATE_BURGENLAND: 'Burgenland',
	STATE_CARINTHIA: 'Carinthia',
	STATE_KARNTEN: 'Karnten',
	STATE_LIEZEN: 'Liezen',
	STATE_LOWER_AUSTRIA: 'Lower Austria',
	STATE_NIEDEROSTERREICH: 'Niederosterreich',
	STATE_OBEROSTERREICH: 'Oberosterreich',
	STATE_SALZBURG: 'Salzburg',
	STATE_SCHLESWIG_HOLSTEIN: 'Schleswig-Holstein',
	STATE_STEIERMARK: 'Steiermark',
	STATE_STYRIA: 'Styria',
	STATE_TIROL: 'Tirol',
	STATE_UPPER_AUSTRIA: 'Upper Austria',
	STATE_VORARLBERG: 'Vorarlberg',
	STATE_WIEN: 'Wien',
	STATE_ABSERON: 'Abseron',
	STATE_BAKI_SAHARI: 'Baki Sahari',
	STATE_GANCA: 'Ganca',
	STATE_GANJA: 'Ganja',
	STATE_KALBACAR: 'Kalbacar',
	STATE_LANKARAN: 'Lankaran',
	STATE_MIL_QARABAX: 'Mil-Qarabax',
	STATE_MUGAN_SALYAN: 'Mugan-Salyan',
	STATE_NAGORNI_QARABAX: 'Nagorni-Qarabax',
	STATE_NAXCIVAN: 'Naxcivan',
	STATE_PRIARAKS: 'Priaraks',
	STATE_QAZAX: 'Qazax',
	STATE_SAKI: 'Saki',
	STATE_SIRVAN: 'Sirvan',
	STATE_XACMAZ: 'Xacmaz',
	STATE_ABACO: 'Abaco',
	STATE_ACKLINS_ISLAND: 'Acklins Island',
	STATE_ANDROS: 'Andros',
	STATE_BERRY_ISLANDS: 'Berry Islands',
	STATE_BIMINIS: 'Biminis',
	STATE_CAT_ISLAND: 'Cat Island',
	STATE_CROOKED_ISLAND: 'Crooked Island',
	STATE_ELEUTHERA: 'Eleuthera',
	STATE_EXUMA_AND_CAYS: 'Exuma and Cays',
	STATE_GRAND_BAHAMA: 'Grand Bahama',
	STATE_INAGUA_ISLANDS: 'Inagua Islands',
	STATE_LONG_ISLAND: 'Long Island',
	STATE_MAYAGUANA: 'Mayaguana',
	STATE_NEW_PROVIDENCE: 'New Providence',
	STATE_RAGGED_ISLAND: 'Ragged Island',
	STATE_RUM_CAY: 'Rum Cay',
	STATE_SAN_SALVADOR: 'San Salvador',
	STATE_ISA: 'Isa',
	STATE_BADIYAH: 'Badiyah',
	STATE_JIDD_HAFS: 'Jidd Hafs',
	STATE_MAHAMA: 'Mahama',
	STATE_MANAMA: 'Manama',
	STATE_SITRAH: 'Sitrah',
	STATE_AL_MANAMAH: 'al-Manamah',
	STATE_AL_MUHARRAQ: 'al-Muharraq',
	STATE_AR_RIFA__A: "ar-Rifa'a",
	STATE_BAGAR_HAT: 'Bagar Hat',
	STATE_BANDARBAN: 'Bandarban',
	STATE_BARGUNA: 'Barguna',
	STATE_BARISAL: 'Barisal',
	STATE_BHOLA: 'Bhola',
	STATE_BOGORA: 'Bogora',
	STATE_BRAHMAN_BARIYA: 'Brahman Bariya',
	STATE_CHANDPUR: 'Chandpur',
	STATE_CHATTAGAM: 'Chattagam',
	STATE_CHITTAGONG_DIVISION: 'Chittagong Division',
	STATE_CHUADANGA: 'Chuadanga',
	STATE_DHAKA: 'Dhaka',
	STATE_DINAJPUR: 'Dinajpur',
	STATE_FARIDPUR: 'Faridpur',
	STATE_FENI: 'Feni',
	STATE_GAYBANDA: 'Gaybanda',
	STATE_GAZIPUR: 'Gazipur',
	STATE_GOPALGANJ: 'Gopalganj',
	STATE_HABIGANJ: 'Habiganj',
	STATE_JAIPUR_HAT: 'Jaipur Hat',
	STATE_JAMALPUR: 'Jamalpur',
	STATE_JESSOR: 'Jessor',
	STATE_JHALAKATI: 'Jhalakati',
	STATE_JHANAYDAH: 'Jhanaydah',
	STATE_KHAGRACHHARI: 'Khagrachhari',
	STATE_KHULNA: 'Khulna',
	STATE_KISHORGANJ: 'Kishorganj',
	STATE_KOKS_BAZAR: 'Koks Bazar',
	STATE_KOMILLA: 'Komilla',
	STATE_KURIGRAM: 'Kurigram',
	STATE_KUSHTIYA: 'Kushtiya',
	STATE_LAKSHMIPUR: 'Lakshmipur',
	STATE_LALMANIR_HAT: 'Lalmanir Hat',
	STATE_MADARIPUR: 'Madaripur',
	STATE_MAGURA: 'Magura',
	STATE_MAIMANSINGH: 'Maimansingh',
	STATE_MANIKGANJ: 'Manikganj',
	STATE_MAULVI_BAZAR: 'Maulvi Bazar',
	STATE_MEHERPUR: 'Meherpur',
	STATE_MUNSHIGANJ: 'Munshiganj',
	STATE_NARAL: 'Naral',
	STATE_NARAYANGANJ: 'Narayanganj',
	STATE_NARSINGDI: 'Narsingdi',
	STATE_NATOR: 'Nator',
	STATE_NAUGAON: 'Naugaon',
	STATE_NAWABGANJ: 'Nawabganj',
	STATE_NETRAKONA: 'Netrakona',
	STATE_NILPHAMARI: 'Nilphamari',
	STATE_NOAKHALI: 'Noakhali',
	STATE_PABNA: 'Pabna',
	STATE_PANCHAGARH: 'Panchagarh',
	STATE_PATUAKHALI: 'Patuakhali',
	STATE_PIROJPUR: 'Pirojpur',
	STATE_RAJBARI: 'Rajbari',
	STATE_RAJSHAHI: 'Rajshahi',
	STATE_RANGAMATI: 'Rangamati',
	STATE_RANGPUR: 'Rangpur',
	STATE_SATKHIRA: 'Satkhira',
	STATE_SHARIATPUR: 'Shariatpur',
	STATE_SHERPUR: 'Sherpur',
	STATE_SILHAT: 'Silhat',
	STATE_SIRAJGANJ: 'Sirajganj',
	STATE_SUNAMGANJ: 'Sunamganj',
	STATE_TANGAYAL: 'Tangayal',
	STATE_THAKURGAON: 'Thakurgaon',
	STATE_CHRIST_CHURCH: 'Christ Church',
	STATE_SAINT_ANDREW: 'Saint Andrew',
	STATE_SAINT_JAMES: 'Saint James',
	STATE_SAINT_JOSEPH: 'Saint Joseph',
	STATE_SAINT_LUCY: 'Saint Lucy',
	STATE_SAINT_MICHAEL: 'Saint Michael',
	STATE_SAINT_THOMAS: 'Saint Thomas',
	STATE_BREST: 'Brest',
	STATE_HOMJEL: 'Homjel',
	STATE_HRODNA: 'Hrodna',
	STATE_MAHILJOW: 'Mahiljow',
	STATE_MAHILYOWSKAYA_VOBLASTS: 'Mahilyowskaya Voblasts',
	STATE_MINSK: 'Minsk',
	STATE_MINSKAJA_VOBLASTS: 'Minskaja Voblasts',
	STATE_PETRIK: 'Petrik',
	STATE_VICEBSK: 'Vicebsk',
	STATE_ANTWERPEN: 'Antwerpen',
	STATE_BERCHEM: 'Berchem',
	STATE_BRABANT: 'Brabant',
	STATE_BRABANT_WALLON: 'Brabant Wallon',
	STATE_BRUSSEL: 'Brussel',
	STATE_EAST_FLANDERS: 'East Flanders',
	STATE_HAINAUT: 'Hainaut',
	STATE_LIEGE: 'Liege',
	STATE_LIMBURG: 'Limburg',
	STATE_LUXEMBOURG: 'Luxemburgo',
	STATE_NAMUR: 'Namur',
	STATE_ONTARIO: 'Ontario',
	STATE_OOST_VLAANDEREN: 'Oost-Vlaanderen',
	STATE_PROVINCIE_BRABANT: 'Provincie Brabant',
	STATE_VLAAMS_BRABANT: 'Vlaams-Brabant',
	STATE_WALLONNE: 'Wallonne',
	STATE_WEST_VLAANDEREN: 'West-Vlaanderen',
	STATE_BELIZE: 'Belize',
	STATE_CAYO: 'Cayo',
	STATE_COROZAL: 'Corozal',
	STATE_ORANGE_WALK: 'Orange Walk',
	STATE_STANN_CREEK: 'Stann Creek',
	STATE_TOLEDO: 'Toledo',
	STATE_ALIBORI: 'Alibori',
	STATE_ATACORA: 'Atacora',
	STATE_ATLANTIQUE: 'Atlantique',
	STATE_BORGOU: 'Borgou',
	STATE_COLLINES: 'Collines',
	STATE_COUFFO: 'Couffo',
	STATE_DONGA: 'Donga',
	STATE_LITTORAL: 'Littoral',
	STATE_MONO: 'Mono',
	STATE_OUEME: 'Oueme',
	STATE_PLATEAU: 'Plateau',
	STATE_ZOU: 'Zou',
	STATE_HAMILTON: 'Hamilton',
	STATE_BUMTHANG: 'Bumthang',
	STATE_CHHUKHA: 'Chhukha',
	STATE_CHIRANG: 'Chirang',
	STATE_DAGA: 'Daga',
	STATE_GEYLEGPHUG: 'Geylegphug',
	STATE_HA: 'Ha',
	STATE_LHUNTSHI: 'Lhuntshi',
	STATE_MONGAR: 'Mongar',
	STATE_PEMAGATSEL: 'Pemagatsel',
	STATE_PUNAKHA: 'Punakha',
	STATE_RINPUNG: 'Rinpung',
	STATE_SAMCHI: 'Samchi',
	STATE_SAMDRUP_JONGKHAR: 'Samdrup Jongkhar',
	STATE_SHEMGANG: 'Shemgang',
	STATE_TASHIGANG: 'Tashigang',
	STATE_TIMPHU: 'Timphu',
	STATE_TONGSA: 'Tongsa',
	STATE_WANGDIPHODRANG: 'Wangdiphodrang',
	STATE_BENI: 'Beni',
	STATE_CHUQUISACA: 'Chuquisaca',
	STATE_COCHABAMBA: 'Cochabamba',
	STATE_LA_PAZ: 'La Paz',
	STATE_ORURO: 'Oruro',
	STATE_PANDO: 'Pando',
	STATE_POTOSI: 'Potosi',
	STATE_TARIJA: 'Tarija',
	STATE_FEDERACIJA_BOSNA_I_HERCEGOVINA: 'Federacija Bosna i Hercegovina',
	STATE_REPUBLIKA_SRPSKA: 'Republika Srpska',
	STATE_CENTRAL_BOBONONG: 'Central Bobonong',
	STATE_CENTRAL_BOTETI: 'Central Boteti',
	STATE_CENTRAL_MAHALAPYE: 'Central Mahalapye',
	STATE_CENTRAL_SEROWE_PALAPYE: 'Central Serowe-Palapye',
	STATE_CENTRAL_TUTUME: 'Central Tutume',
	STATE_CHOBE: 'Chobe',
	STATE_FRANCISTOWN: 'Francistown',
	STATE_GABORONE: 'Gaborone',
	STATE_GHANZI: 'Ghanzi',
	STATE_JWANENG: 'Jwaneng',
	STATE_KGALAGADI_NORTH: 'Kgalagadi North',
	STATE_KGALAGADI_SOUTH: 'Kgalagadi South',
	STATE_KGATLENG: 'Kgatleng',
	STATE_KWENENG: 'Kweneng',
	STATE_LOBATSE: 'Lobatse',
	STATE_NGAMILAND: 'Ngamiland',
	STATE_NGWAKETSE: 'Ngwaketse',
	STATE_NORTH_EAST: 'North East',
	STATE_OKAVANGO: 'Okavango',
	STATE_ORAPA: 'Orapa',
	STATE_SELIBE_PHIKWE: 'Selibe Phikwe',
	STATE_SOUTH_EAST: 'South East',
	STATE_SOWA: 'Sowa',
	STATE_BOUVET_ISLAND: 'Bouvet Island',
	STATE_ACRE: 'Acre',
	STATE_ALAGOAS: 'Alagoas',
	STATE_AMAPA: 'Amapa',
	STATE_AMAZONAS: 'Amazonas',
	STATE_BAHIA: 'Bahia',
	STATE_CEARA: 'Ceara',
	STATE_ESPIRITO_SANTO: 'Espirito Santo',
	STATE_ESTADO_DE_SAO_PAULO: 'Estado de Sao Paulo',
	STATE_GOIAS: 'Goias',
	STATE_MARANHAO: 'Maranhao',
	STATE_MATO_GROSSO: 'Mato Grosso',
	STATE_MATO_GROSSO_DO_SUL: 'Mato Grosso do Sul',
	STATE_MINAS_GERAIS: 'Minas Gerais',
	STATE_PARA: 'Para',
	STATE_PARAIBA: 'Paraiba',
	STATE_PARANA: 'Parana',
	STATE_PERNAMBUCO: 'Pernambuco',
	STATE_PIAUI: 'Piaui',
	STATE_RIO_GRANDE_DO_NORTE: 'Rio Grande do Norte',
	STATE_RIO_GRANDE_DO_SUL: 'Rio Grande do Sul',
	STATE_RIO_DE_JANEIRO: 'Rio de Janeiro',
	STATE_RONDONIA: 'Rondonia',
	STATE_RORAIMA: 'Roraima',
	STATE_SANTA_CATARINA: 'Santa Catarina',
	STATE_SAO_PAULO: 'Sao Paulo',
	STATE_SERGIPE: 'Sergipe',
	STATE_TOCANTINS: 'Tocantins',
	STATE_BRITISH_INDIAN_OCEAN_TERRITORY: 'British Indian Ocean Territory',
	STATE_BELAIT: 'Belait',
	STATE_BRUNEI_MUARA: 'Brunei-Muara',
	STATE_TEMBURONG: 'Temburong',
	STATE_TUTONG: 'Tutong',
	STATE_BLAGOEVGRAD: 'Blagoevgrad',
	STATE_BURGAS: 'Burgas',
	STATE_DOBRICH: 'Dobrich',
	STATE_GABROVO: 'Gabrovo',
	STATE_HASKOVO: 'Haskovo',
	STATE_JAMBOL: 'Jambol',
	STATE_KARDZHALI: 'Kardzhali',
	STATE_KJUSTENDIL: 'Kjustendil',
	STATE_LOVECH: 'Lovech',
	STATE_MONTANA: 'Montana',
	STATE_OBLAST_SOFIYA_GRAD: 'Oblast Sofiya-Grad',
	STATE_PAZARDZHIK: 'Pazardzhik',
	STATE_PERNIK: 'Pernik',
	STATE_PLEVEN: 'Pleven',
	STATE_PLOVDIV: 'Plovdiv',
	STATE_RAZGRAD: 'Razgrad',
	STATE_RUSE: 'Ruse',
	STATE_SHUMEN: 'Shumen',
	STATE_SILISTRA: 'Silistra',
	STATE_SLIVEN: 'Sliven',
	STATE_SMOLJAN: 'Smoljan',
	STATE_SOFIJA_GRAD: 'Sofija grad',
	STATE_SOFIJSKA_OBLAST: 'Sofijska oblast',
	STATE_STARA_ZAGORA: 'Stara Zagora',
	STATE_TARGOVISHTE: 'Targovishte',
	STATE_VARNA: 'Varna',
	STATE_VELIKO_TARNOVO: 'Veliko Tarnovo',
	STATE_VIDIN: 'Vidin',
	STATE_VRACA: 'Vraca',
	STATE_YABLANIZA: 'Yablaniza',
	STATE_BALE: 'Bale',
	STATE_BAM: 'Bam',
	STATE_BAZEGA: 'Bazega',
	STATE_BOUGOURIBA: 'Bougouriba',
	STATE_BOULGOU: 'Boulgou',
	STATE_BOULKIEMDE: 'Boulkiemde',
	STATE_COMOE: 'Comoe',
	STATE_GANZOURGOU: 'Ganzourgou',
	STATE_GNAGNA: 'Gnagna',
	STATE_GOURMA: 'Gourma',
	STATE_HOUET: 'Houet',
	STATE_IOBA: 'Ioba',
	STATE_KADIOGO: 'Kadiogo',
	STATE_KENEDOUGOU: 'Kenedougou',
	STATE_KOMANDJARI: 'Komandjari',
	STATE_KOMPIENGA: 'Kompienga',
	STATE_KOSSI: 'Kossi',
	STATE_KOURITENGA: 'Kouritenga',
	STATE_KOURWEOGO: 'Kourweogo',
	STATE_LERABA: 'Leraba',
	STATE_MOUHOUN: 'Mouhoun',
	STATE_NAHOURI: 'Nahouri',
	STATE_NAMENTENGA: 'Namentenga',
	STATE_NOUMBIEL: 'Noumbiel',
	STATE_OUBRITENGA: 'Oubritenga',
	STATE_OUDALAN: 'Oudalan',
	STATE_PASSORE: 'Passore',
	STATE_PONI: 'Poni',
	STATE_SANGUIE: 'Sanguie',
	STATE_SANMATENGA: 'Sanmatenga',
	STATE_SENO: 'Seno',
	STATE_SISSILI: 'Sissili',
	STATE_SOUM: 'Soum',
	STATE_SOUROU: 'Sourou',
	STATE_TAPOA: 'Tapoa',
	STATE_TUY: 'Tuy',
	STATE_YATENGA: 'Yatenga',
	STATE_ZONDOMA: 'Zondoma',
	STATE_ZOUNDWEOGO: 'Zoundweogo',
	STATE_BUBANZA: 'Bubanza',
	STATE_BUJUMBURA: 'Bujumbura',
	STATE_BURURI: 'Bururi',
	STATE_CANKUZO: 'Cankuzo',
	STATE_CIBITOKE: 'Cibitoke',
	STATE_GITEGA: 'Gitega',
	STATE_KARUZI: 'Karuzi',
	STATE_KAYANZA: 'Kayanza',
	STATE_KIRUNDO: 'Kirundo',
	STATE_MAKAMBA: 'Makamba',
	STATE_MURAMVYA: 'Muramvya',
	STATE_MUYINGA: 'Muyinga',
	STATE_NGOZI: 'Ngozi',
	STATE_RUTANA: 'Rutana',
	STATE_RUYIGI: 'Ruyigi',
	STATE_BANTEAY_MEAN_CHEY: 'Banteay Mean Chey',
	STATE_BAT_DAMBANG: 'Bat Dambang',
	STATE_KAMPONG_CHAM: 'Kampong Cham',
	STATE_KAMPONG_CHHNANG: 'Kampong Chhnang',
	STATE_KAMPONG_SPOEU: 'Kampong Spoeu',
	STATE_KAMPONG_THUM: 'Kampong Thum',
	STATE_KAMPOT: 'Kampot',
	STATE_KANDAL: 'Kandal',
	STATE_KAOH_KONG: 'Kaoh Kong',
	STATE_KRACHEH: 'Kracheh',
	STATE_KRONG_KAEB: 'Krong Kaeb',
	STATE_KRONG_PAILIN: 'Krong Pailin',
	STATE_KRONG_PREAH_SIHANOUK: 'Krong Preah Sihanouk',
	STATE_MONDOL_KIRI: 'Mondol Kiri',
	STATE_OTDAR_MEAN_CHEY: 'Otdar Mean Chey',
	STATE_PHNUM_PENH: 'Phnum Penh',
	STATE_POUSAT: 'Pousat',
	STATE_PREAH_VIHEAR: 'Preah Vihear',
	STATE_PREY_VEAENG: 'Prey Veaeng',
	STATE_ROTANAK_KIRI: 'Rotanak Kiri',
	STATE_SIEM_REAB: 'Siem Reab',
	STATE_STUENG_TRAENG: 'Stueng Traeng',
	STATE_SVAY_RIENG: 'Svay Rieng',
	STATE_TAKAEV: 'Takaev',
	STATE_ADAMAOUA: 'Adamaoua',
	STATE_CENTRE: 'Centre',
	STATE_EST: 'Est',
	STATE_NORD: 'Nord',
	STATE_NORD_EXTREME: 'Nord Extreme',
	STATE_NORDOUEST: 'Nordouest',
	STATE_OUEST: 'Ouest',
	STATE_SUD: 'Sud',
	STATE_SUDOUEST: 'Sudouest',
	STATE_ALBERTA: 'Alberta',
	STATE_BRITISH_COLUMBIA: 'British Columbia',
	STATE_MANITOBA: 'Manitoba',
	STATE_NEW_BRUNSWICK: 'New Brunswick',
	STATE_NEWFOUNDLAND_AND_LABRADOR: 'Newfoundland and Labrador',
	STATE_NORTHWEST_TERRITORIES: 'Northwest Territories',
	STATE_NOVA_SCOTIA: 'Nova Scotia',
	STATE_NUNAVUT: 'Nunavut',
	STATE_PRINCE_EDWARD_ISLAND: 'Prince Edward Island',
	STATE_QUEBEC: 'Quebec',
	STATE_SASKATCHEWAN: 'Saskatchewan',
	STATE_YUKON: 'Yukon',
	STATE_BOAVISTA: 'Boavista',
	STATE_BRAVA: 'Brava',
	STATE_FOGO: 'Fogo',
	STATE_MAIO: 'Maio',
	STATE_SAL: 'Sal',
	STATE_SANTO_ANTAO: 'Santo Antao',
	STATE_SAO_NICOLAU: 'Sao Nicolau',
	STATE_SAO_TIAGO: 'Sao Tiago',
	STATE_SAO_VICENTE: 'Sao Vicente',
	STATE_GRAND_CAYMAN: 'Grand Cayman',
	STATE_BAMINGUI_BANGORAN: 'Bamingui-Bangoran',
	STATE_BANGUI: 'Bangui',
	STATE_BASSE_KOTTO: 'Basse-Kotto',
	STATE_HAUT_MBOMOU: 'Haut-Mbomou',
	STATE_HAUTE_KOTTO: 'Haute-Kotto',
	STATE_KEMO: 'Kemo',
	STATE_LOBAYE: 'Lobaye',
	STATE_MAMBERE_KADEI: 'Mambere-Kadei',
	STATE_MBOMOU: 'Mbomou',
	STATE_NANA_GRIBIZI: 'Nana-Gribizi',
	STATE_NANA_MAMBERE: 'Nana-Mambere',
	STATE_OMBELLA_MPOKO: 'Ombella Mpoko',
	STATE_OUAKA: 'Ouaka',
	STATE_OUHAM: 'Ouham',
	STATE_OUHAM_PENDE: 'Ouham-Pende',
	STATE_SANGHA_MBAERE: 'Sangha-Mbaere',
	STATE_VAKAGA: 'Vakaga',
	STATE_BATHA: 'Batha',
	STATE_BILTINE: 'Biltine',
	STATE_BOURKOU_ENNEDI_TIBESTI: 'Bourkou-Ennedi-Tibesti',
	STATE_CHARI_BAGUIRMI: 'Chari-Baguirmi',
	STATE_GUERA: 'Guera',
	STATE_KANEM: 'Kanem',
	STATE_LAC: 'Lac',
	STATE_LOGONE_OCCIDENTAL: 'Logone Occidental',
	STATE_LOGONE_ORIENTAL: 'Logone Oriental',
	STATE_MAYO_KEBBI: 'Mayo-Kebbi',
	STATE_MOYEN_CHARI: 'Moyen-Chari',
	STATE_OUADDAI: 'Ouaddai',
	STATE_SALAMAT: 'Salamat',
	STATE_TANDJILE: 'Tandjile',
	STATE_AISEN: 'Aisen',
	STATE_ANTOFAGASTA: 'Antofagasta',
	STATE_ARAUCANIA: 'Araucania',
	STATE_ATACAMA: 'Atacama',
	STATE_BIO_BIO: 'Bio Bio',
	STATE_COQUIMBO: 'Coquimbo',
	STATE_LIBERTADOR_GENERAL_BERNARDO_O: 'Libertador General Bernardo O',
	STATE_LOS_LAGOS: 'Los Lagos',
	STATE_MAGELLANES: 'Magellanes',
	STATE_MAULE: 'Maule',
	STATE_METROPOLITANA: 'Metropolitana',
	STATE_METROPOLITANA_DE_SANTIAGO: 'Metropolitana de Santiago',
	STATE_TARAPACA: 'Tarapaca',
	STATE_VALPARAISO: 'Valparaiso',
	STATE_ANHUI: 'Anhui',
	STATE_ANHUI_PROVINCE: 'Anhui Province',
	STATE_ANHUI_SHENG: 'Anhui Sheng',
	STATE_AOMEN: 'Aomen',
	STATE_BEIJING: 'Beijing',
	STATE_BEIJING_SHI: 'Beijing Shi',
	STATE_CHONGQING: 'Chongqing',
	STATE_FUJIAN: 'Fujian',
	STATE_FUJIAN_SHENG: 'Fujian Sheng',
	STATE_GANSU: 'Gansu',
	STATE_GUANGDONG: 'Guangdong',
	STATE_GUANGDONG_SHENG: 'Guangdong Sheng',
	STATE_GUANGXI: 'Guangxi',
	STATE_GUIZHOU: 'Guizhou',
	STATE_HAINAN: 'Hainan',
	STATE_HEBEI: 'Hebei',
	STATE_HEILONGJIANG: 'Heilongjiang',
	STATE_HENAN: 'Henan',
	STATE_HUBEI: 'Hubei',
	STATE_HUNAN: 'Hunan',
	STATE_JIANGSU: 'Jiangsu',
	STATE_JIANGSU_SHENG: 'Jiangsu Sheng',
	STATE_JIANGXI: 'Jiangxi',
	STATE_JILIN: 'Jilin',
	STATE_LIAONING: 'Liaoning',
	STATE_LIAONING_SHENG: 'Liaoning Sheng',
	STATE_NEI_MONGGOL: 'Nei Monggol',
	STATE_NINGXIA_HUI: 'Ningxia Hui',
	STATE_QINGHAI: 'Qinghai',
	STATE_SHAANXI: 'Shaanxi',
	STATE_SHANDONG: 'Shandong',
	STATE_SHANDONG_SHENG: 'Shandong Sheng',
	STATE_SHANGHAI: 'Shanghai',
	STATE_SHANXI: 'Shanxi',
	STATE_SICHUAN: 'Sichuan',
	STATE_TIANJIN: 'Tianjin',
	STATE_XIANGGANG: 'Xianggang',
	STATE_XINJIANG: 'Xinjiang',
	STATE_XIZANG: 'Xizang',
	STATE_YUNNAN: 'Yunnan',
	STATE_ZHEJIANG: 'Zhejiang',
	STATE_ZHEJIANG_SHENG: 'Zhejiang Sheng',
	STATE_CHRISTMAS_ISLAND: 'Isla de Navidad',
	STATE_COCOS_KEELING_ISLANDS: 'Cocos (Keeling) Islands',
	STATE_ANTIOQUIA: 'Antioquia',
	STATE_ARAUCA: 'Arauca',
	STATE_ATLANTICO: 'Atlántico',
	STATE_BOGOTA: 'Bogotá',
	STATE_BOLIVAR: 'Bolívar',
	STATE_BOYACA: 'Boyaca',
	STATE_CALDAS: 'Caldas',
	STATE_CAQUETA: 'Caqueta',
	STATE_CASANARE: 'Casanare',
	STATE_CAUCA: 'Cauca',
	STATE_CESAR: 'César',
	STATE_CHOCO: 'Choco',
	STATE_CUNDINAMARCA: 'Cundinamarca',
	STATE_GUAINIA: 'Guainia',
	STATE_GUAVIARE: 'Guaviare',
	STATE_LA_GUAJIRA: 'La Guajira',
	STATE_MAGDALENA: 'Magdalena',
	STATE_META: 'Meta',
	STATE_NARINO: 'Narino',
	STATE_NORTE_DE_SANTANDER: 'Norte de Santander',
	STATE_PUTUMAYO: 'Putumayo',
	STATE_QUINDIO: 'Quindio',
	STATE_RISARALDA: 'Risaralda',
	STATE_SAN_ANDRES_Y_PROVIDENCIA: 'San Andrés y Providencia',
	STATE_SANTANDER: 'Santander',
	STATE_SUCRE: 'Sucre',
	STATE_TOLIMA: 'Tolima',
	STATE_VALLE_DEL_CAUCA: 'Valle del Cauca',
	STATE_VAUPES: 'Vaupes',
	STATE_VICHADA: 'Vichada',
	STATE_MWALI: 'Mwali',
	STATE_NJAZIDJA: 'Njazidja',
	STATE_NZWANI: 'Nzwani',
	STATE_BOUENZA: 'Bouenza',
	STATE_BRAZZAVILLE: 'Brazzaville',
	STATE_CUVETTE: 'Cuvette',
	STATE_KOUILOU: 'Kouilou',
	STATE_LEKOUMOU: 'Lekoumou',
	STATE_LIKOUALA: 'Likouala',
	STATE_NIARI: 'Niari',
	STATE_PLATEAUX: 'Plateaux',
	STATE_POOL: 'Pool',
	STATE_SANGHA: 'Sangha',
	STATE_BANDUNDU: 'Bandundu',
	STATE_BAS_CONGO: 'Bas-Congo',
	STATE_EQUATEUR: 'Equateur',
	STATE_HAUT_CONGO: 'Haut-Congo',
	STATE_KASAI_OCCIDENTAL: 'Kasai-Occidental',
	STATE_KASAI_ORIENTAL: 'Kasai-Oriental',
	STATE_KATANGA: 'Katanga',
	STATE_KINSHASA: 'Kinshasa',
	STATE_MANIEMA: 'Maniema',
	STATE_NORD_KIVU: 'Nord-Kivu',
	STATE_SUD_KIVU: 'Sud-Kivu',
	STATE_AITUTAKI: 'Aitutaki',
	STATE_ATIU: 'Atiu',
	STATE_MANGAIA: 'Mangaia',
	STATE_MANIHIKI: 'Manihiki',
	STATE_MAUKE: 'Mauke',
	STATE_MITIARO: 'Mitiaro',
	STATE_NASSAU: 'Nassau',
	STATE_PUKAPUKA: 'Pukapuka',
	STATE_RAKAHANGA: 'Rakahanga',
	STATE_RAROTONGA: 'Rarotonga',
	STATE_TONGAREVA: 'Tongareva',
	STATE_ALAJUELA: 'Alajuela',
	STATE_CARTAGO: 'Cartago',
	STATE_GUANACASTE: 'Guanacaste',
	STATE_HEREDIA: 'Heredia',
	STATE_LIMON: 'Limón',
	STATE_PUNTARENAS: 'Puntarenas',
	STATE_SAN_JOSE: 'San José',
	STATE_ABIDJAN: 'Abidjan',
	STATE_AGNEBY: 'Agneby',
	STATE_BAFING: 'Bafing',
	STATE_DENGUELE: 'Denguele',
	STATE_DIX_HUIT_MONTAGNES: 'Dix-huit Montagnes',
	STATE_FROMAGER: 'Fromager',
	STATE_HAUT_SASSANDRA: 'Haut-Sassandra',
	STATE_LACS: 'Lacs',
	STATE_LAGUNES: 'Lagunes',
	STATE_MARAHOUE: 'Marahoue',
	STATE_MOYEN_CAVALLY: 'Moyen-Cavally',
	STATE_MOYEN_COMOE: 'Moyen-Comoe',
	STATE_N__ZI_COMOE: "N'zi-Comoe",
	STATE_SASSANDRA: 'Haut-Sassandra',
	STATE_SAVANES: 'Savanes',
	STATE_SUD_BANDAMA: 'Sud-Bandama',
	STATE_SUD_COMOE: 'Sud-Comoe',
	STATE_VALLEE_DU_BANDAMA: 'Vallee du Bandama',
	STATE_WORODOUGOU: 'Worodougou',
	STATE_ZANZAN: 'Zanzan',
	STATE_BJELOVAR_BILOGORA: 'Bjelovar-Bilogora',
	STATE_DUBROVNIK_NERETVA: 'Dubrovnik-Neretva',
	STATE_GRAD_ZAGREB: 'Grad Zagreb',
	STATE_ISTRA: 'Istra',
	STATE_KARLOVAC: 'Karlovac',
	STATE_KOPRIVNICA_KRIZHEVCI: 'Koprivnica-Krizhevci',
	STATE_KRAPINA_ZAGORJE: 'Krapina-Zagorje',
	STATE_LIKA_SENJ: 'Lika-Senj',
	STATE_MEDHIMURJE: 'Medhimurje',
	STATE_MEDIMURSKA_ZUPANIJA: 'Medimurska Zupanija',
	STATE_OSIJEK_BARANJA: 'Osijek-Baranja',
	STATE_OSJECKO_BARANJSKA_ZUPANIJA: 'Osjecko-Baranjska Zupanija',
	STATE_POZHEGA_SLAVONIJA: 'Pozhega-Slavonija',
	STATE_PRIMORJE_GORSKI_KOTAR: 'Primorje-Gorski Kotar',
	STATE_SHIBENIK_KNIN: 'Shibenik-Knin',
	STATE_SISAK_MOSLAVINA: 'Sisak-Moslavina',
	STATE_SLAVONSKI_BROD_POSAVINA: 'Slavonski Brod-Posavina',
	STATE_SPLIT_DALMACIJA: 'Split-Dalmacija',
	STATE_VARAZHDIN: 'Varazhdin',
	STATE_VIROVITICA_PODRAVINA: 'Virovitica-Podravina',
	STATE_VUKOVAR_SRIJEM: 'Vukovar-Srijem',
	STATE_ZADAR: 'Zadar',
	STATE_ZAGREB: 'Zagreb',
	STATE_CAMAGUEY: 'Camagüey',
	STATE_CIEGO_DE_AVILA: 'Ciego de Ávila',
	STATE_CIENFUEGOS: 'Cienfuegos',
	STATE_CIUDAD_DE_LA_HABANA: 'Ciudad de la Habana',
	STATE_GRANMA: 'Granma',
	STATE_GUANTANAMO: 'Guantánamo',
	STATE_HABANA: 'Habana',
	STATE_HOLGUIN: 'Holguín',
	STATE_ISLA_DE_LA_JUVENTUD: 'Isla de la Juventud',
	STATE_LA_HABANA: 'La Habana',
	STATE_LAS_TUNAS: 'Las Tunas',
	STATE_MATANZAS: 'Matanzas',
	STATE_PINAR_DEL_RIO: 'Pinar del Río',
	STATE_SANCTI_SPIRITUS: 'Sancti Spiritus',
	STATE_SANTIAGO_DE_CUBA: 'Santiago de Cuba',
	STATE_VILLA_CLARA: 'Villa Clara',
	STATE_GOVERNMENT_CONTROLLED_AREA: 'Government controlled area',
	STATE_LIMASSOL: 'Limassol',
	STATE_NICOSIA_DISTRICT: 'Nicosia District',
	STATE_PAPHOS: 'Paphos',
	STATE_TURKISH_CONTROLLED_AREA: 'Turkish controlled area',
	STATE_CENTRAL_BOHEMIAN: 'Central Bohemian',
	STATE_FRYCOVICE: 'Frycovice',
	STATE_JIHOCESKY_KRAJ: 'Jihocesky Kraj',
	STATE_JIHOCHESKY: 'Jihochesky',
	STATE_JIHOMORAVSKY: 'Jihomoravsky',
	STATE_KARLOVARSKY: 'Karlovarsky',
	STATE_KLECANY: 'Klecany',
	STATE_KRALOVEHRADECKY: 'Kralovehradecky',
	STATE_LIBERECKY: 'Liberecky',
	STATE_LIPOV: 'Lipov',
	STATE_MORAVSKOSLEZSKY: 'Moravskoslezsky',
	STATE_OLOMOUCKY: 'Olomoucky',
	STATE_OLOMOUCKY_KRAJ: 'Olomoucky Kraj',
	STATE_PARDUBICKY: 'Pardubicky',
	STATE_PLZENSKY: 'Plzensky',
	STATE_PRAHA: 'Praha',
	STATE_RAJHRAD: 'Rajhrad',
	STATE_SMIRICE: 'Smirice',
	STATE_SOUTH_MORAVIAN: 'South Moravian',
	STATE_STRAZ_NAD_NISOU: 'Straz nad Nisou',
	STATE_STREDOCHESKY: 'Stredochesky',
	STATE_UNICOV: 'Unicov',
	STATE_USTECKY: 'Ustecky',
	STATE_VALLETTA: 'Valletta',
	STATE_VELESIN: 'Velesin',
	STATE_VYSOCHINA: 'Vysochina',
	STATE_ZLINSKY: 'Zlinsky',
	STATE_BORNHOLM: 'Bornholm',
	STATE_FREDERIKSBORG: 'Frederiksborg',
	STATE_FYN: 'Fyn',
	STATE_HOVEDSTADEN: 'Hovedstaden',
	STATE_KOBENHAVN: 'Kobenhavn',
	STATE_KOBENHAVNS_AMT: 'Kobenhavns Amt',
	STATE_KOBENHAVNS_KOMMUNE: 'Kobenhavns Kommune',
	STATE_NORDJYLLAND: 'Nordjylland',
	STATE_RIBE: 'Ribe',
	STATE_RINGKOBING: 'Ringkobing',
	STATE_ROERVIG: 'Roervig',
	STATE_ROSKILDE: 'Roskilde',
	STATE_ROSLEV: 'Roslev',
	STATE_SJAELLAND: 'Sjaelland',
	STATE_SOEBORG: 'Soeborg',
	STATE_SONDERJYLLAND: 'Sonderjylland',
	STATE_STORSTROM: 'Storstrom',
	STATE_SYDDANMARK: 'Syddanmark',
	STATE_TOELLOESE: 'Toelloese',
	STATE_VEJLE: 'Vejle',
	STATE_VESTSJALLAND: 'Vestsjalland',
	STATE_VIBORG: 'Viborg',
	STATE_ALI_SABIH: 'Ali Sabih',
	STATE_DIKHIL: 'Dikhil',
	STATE_JIBUTI: 'Jibuti',
	STATE_TAJURAH: 'Tajurah',
	STATE_UBUK: 'Ubuk',
	STATE_SAINT_DAVID: 'Saint David',
	STATE_SAINT_LUKE: 'Saint Luke',
	STATE_SAINT_MARK: 'Saint Mark',
	STATE_SAINT_PATRICK: 'Saint Patrick',
	STATE_AZUA: 'Azua',
	STATE_BAHORUCO: 'Bahoruco',
	STATE_BARAHONA: 'Barahona',
	STATE_DAJABON: 'Dajabon',
	STATE_DISTRITO_NACIONAL: 'Distrito Nacional',
	STATE_DUARTE: 'Duarte',
	STATE_EL_SEYBO: 'El Seybo',
	STATE_ELIAS_PINA: 'Elías Piña',
	STATE_ESPAILLAT: 'Espaillat',
	STATE_HATO_MAYOR: 'Hato Mayor',
	STATE_INDEPENDENCIA: 'Independencia',
	STATE_LA_ALTAGRACIA: 'La Altagracia',
	STATE_LA_ROMANA: 'La Romana',
	STATE_LA_VEGA: 'La Vega',
	STATE_MARIA_TRINIDAD_SANCHEZ: 'Maria Trinidad Sánchez',
	STATE_MONSENOR_NOUEL: 'Monseñor Nouel',
	STATE_MONTE_CRISTI: 'Monte Cristi',
	STATE_MONTE_PLATA: 'Monte Plata',
	STATE_PEDERNALES: 'Pedernales',
	STATE_PERAVIA: 'Peravia',
	STATE_PUERTO_PLATA: 'Puerto Plata',
	STATE_SALCEDO: 'Salcedo',
	STATE_SAMANA: 'Samana',
	STATE_SAN_CRISTOBAL: 'San Cristóbal',
	STATE_SAN_PEDRO_DE_MACORIS: 'San Pedro de Macoris',
	STATE_SANCHEZ_RAMIREZ: 'Sánchez Ramírez',
	STATE_SANTIAGO: 'Santiago',
	STATE_SANTIAGO_RODRIGUEZ: 'Santiago Rodríguez',
	STATE_VALVERDE: 'Valverde',
	STATE_AILEU: 'Aileu',
	STATE_AINARO: 'Ainaro',
	STATE_AMBENO: 'Ambeno',
	STATE_BAUCAU: 'Baucau',
	STATE_BOBONARO: 'Bobonaro',
	STATE_COVA_LIMA: 'Cova Lima',
	STATE_DILI: 'Dili',
	STATE_ERMERA: 'Ermera',
	STATE_LAUTEM: 'Lautem',
	STATE_LIQUICA: 'Liquica',
	STATE_MANATUTO: 'Manatuto',
	STATE_MANUFAHI: 'Manufahi',
	STATE_VIQUEQUE: 'Viqueque',
	STATE_AZUAY: 'Azuay',
	STATE_CANAR: 'Canar',
	STATE_CARCHI: 'Carchi',
	STATE_CHIMBORAZO: 'Chimborazo',
	STATE_COTOPAXI: 'Cotopaxi',
	STATE_EL_ORO: 'El Oro',
	STATE_ESMERALDAS: 'Esmeraldas',
	STATE_GALAPAGOS: 'Galápagos',
	STATE_GUAYAS: 'Guayas',
	STATE_IMBABURA: 'Imbabura',
	STATE_LOJA: 'Loja',
	STATE_LOS_RIOS: 'Los Ríos',
	STATE_MANABI: 'Manabi',
	STATE_MORONA_SANTIAGO: 'Morona Santiago',
	STATE_NAPO: 'Napo',
	STATE_ORELLANA: 'Orellana',
	STATE_PASTAZA: 'Pastaza',
	STATE_PICHINCHA: 'Pichincha',
	STATE_SUCUMBIOS: 'Sucumbios',
	STATE_TUNGURAHUA: 'Tungurahua',
	STATE_ZAMORA_CHINCHIPE: 'Zamora Chinchipe',
	STATE_ASWAN: 'Aswan',
	STATE_ASYUT: 'Asyut',
	STATE_BANI_SUWAYF: 'Bani Suwayf',
	STATE_BUR_SA__ID: "Bur Sa'id",
	STATE_CAIRO: 'Cairo',
	STATE_DUMYAT: 'Dumyat',
	STATE_KAFR_ASH_SHAYKH: 'Kafr-ash-Shaykh',
	STATE_MATRUH: 'Matruh',
	STATE_MUHAFAZAT_AD_DAQAHLIYAH: 'Muhafazat ad Daqahliyah',
	STATE_MUHAFAZAT_AL_FAYYUM: 'Muhafazat al Fayyum',
	STATE_MUHAFAZAT_AL_GHARBIYAH: 'Muhafazat al Gharbiyah',
	STATE_MUHAFAZAT_AL_ISKANDARIYAH: 'Muhafazat al Iskandariyah',
	STATE_MUHAFAZAT_AL_QAHIRAH: 'Muhafazat al Qahirah',
	STATE_QINA: 'Qina',
	STATE_SAWHAJ: 'Sawhaj',
	STATE_SINA_AL_JANUBIYAH: 'Sina al-Janubiyah',
	STATE_SINA_ASH_SHAMALIYAH: 'Sina ash-Shamaliyah',
	STATE_AD_DAQAHLIYAH: 'ad-Daqahliyah',
	STATE_AL_BAHR_AL_AHMAR: 'al-Bahr-al-Ahmar',
	STATE_AL_BUHAYRAH: 'al-Buhayrah',
	STATE_AL_FAYYUM: 'al-Fayyum',
	STATE_AL_GHARBIYAH: 'al-Gharbiyah',
	STATE_AL_ISKANDARIYAH: 'al-Iskandariyah',
	STATE_AL_ISMAILIYAH: 'al-Ismailiyah',
	STATE_AL_JIZAH: 'al-Jizah',
	STATE_AL_MINUFIYAH: 'al-Minufiyah',
	STATE_AL_MINYA: 'al-Minya',
	STATE_AL_QAHIRA: 'al-Qahira',
	STATE_AL_QALYUBIYAH: 'al-Qalyubiyah',
	STATE_AL_UQSUR: 'al-Uqsur',
	STATE_AL_WADI_AL_JADID: 'al-Wadi al-Jadid',
	STATE_AS_SUWAYS: 'as-Suways',
	STATE_ASH_SHARQIYAH: 'ash-Sharqiyah',
	STATE_AHUACHAPAN: 'Ahuachapán',
	STATE_CABANAS: 'Cabañas',
	STATE_CHALATENANGO: 'Chalatenango',
	STATE_CUSCATLAN: 'Cuscatlán',
	STATE_LA_LIBERTAD: 'La Libertad',
	STATE_LA_UNION: 'La Unión',
	STATE_MORAZAN: 'Morazán',
	STATE_SAN_MIGUEL: 'San Miguel',
	STATE_SAN_VICENTE: 'San Vicente',
	STATE_SANTA_ANA: 'Santa Ana',
	STATE_SONSONATE: 'Sonsonate',
	STATE_USULUTAN: 'Usulután',
	STATE_ANNOBON: 'Annobon',
	STATE_BIOKO_NORTE: 'Bioko Norte',
	STATE_BIOKO_SUR: 'Bioko Sur',
	STATE_CENTRO_SUR: 'Bioko Sur',
	STATE_KIE_NTEM: 'Kie-Ntem',
	STATE_LITORAL: 'Litoral',
	STATE_WELE_NZAS: 'Wele-Nzas',
	STATE_ANSEBA: 'Anseba',
	STATE_DEBUB: 'Debub',
	STATE_DEBUB_KEIH_BAHRI: 'Debub-Keih-Bahri',
	STATE_GASH_BARKA: 'Gash-Barka',
	STATE_MAEKEL: 'Maekel',
	STATE_SEMIEN_KEIH_BAHRI: 'Semien-Keih-Bahri',
	STATE_HARJU: 'Harju',
	STATE_HIIU: 'Hiiu',
	STATE_IDA_VIRU: 'Ida-Viru',
	STATE_JARVA: 'Jarva',
	STATE_JOGEVA: 'Jogeva',
	STATE_LAANE: 'Laane',
	STATE_LAANE_VIRU: 'Laane-Viru',
	STATE_PARNU: 'Parnu',
	STATE_POLVA: 'Polva',
	STATE_RAPLA: 'Rapla',
	STATE_SAARE: 'Saare',
	STATE_TARTU: 'Tartu',
	STATE_VALGA: 'Valga',
	STATE_VILJANDI: 'Viljandi',
	STATE_VORU: 'Voru',
	STATE_ADDIS_ABEBA: 'Addis Abeba',
	STATE_AFAR: 'Afar',
	STATE_AMHARA: 'Amhara',
	STATE_BENISHANGUL: 'Benishangul',
	STATE_DIREDAWA: 'Diredawa',
	STATE_GAMBELLA: 'Gambella',
	STATE_HARAR: 'Harar',
	STATE_JIGJIGA: 'Jigjiga',
	STATE_MEKELE: 'Mekele',
	STATE_OROMIA: 'Oromia',
	STATE_SOMALI: 'Somali',
	STATE_SOUTHERN: 'Southern',
	STATE_TIGRAY: 'Tigray',
	STATE_FALKLAND_ISLANDS: 'Islas Malvinas',
	STATE_SOUTH_GEORGIA: 'Georgias del Sur',
	STATE_KLAKSVIK: 'Klaksvik',
	STATE_NOR_ARA_EYSTUROY: 'Nor ara Eysturoy',
	STATE_NOR_OY: 'Nor oy',
	STATE_SANDOY: 'Sandoy',
	STATE_STREYMOY: 'Streymoy',
	STATE_SU_UROY: 'Su uroy',
	STATE_SY_RA_EYSTUROY: 'Sy ra Eysturoy',
	STATE_TORSHAVN: 'Torshavn',
	STATE_VAGA: 'Vaga',
	STATE_CENTRAL: 'Central',
	STATE_NORTHERN: 'Northern',
	STATE_SOUTH_PACIFIC: 'South Pacific',
	STATE_AHVENANMAA: 'Ahvenanmaa',
	STATE_ETELA_KARJALA: 'Etela-Karjala',
	STATE_ETELA_POHJANMAA: 'Etela-Pohjanmaa',
	STATE_ETELA_SAVO: 'Etela-Savo',
	STATE_ETELA_SUOMEN_LAANI: 'Etela-Suomen Laani',
	STATE_ITA_SUOMEN_LAANI: 'Ita-Suomen Laani',
	STATE_ITA_UUSIMAA: 'Ita-Uusimaa',
	STATE_KAINUU: 'Kainuu',
	STATE_KANTA_HAME: 'Kanta-Hame',
	STATE_KESKI_POHJANMAA: 'Keski-Pohjanmaa',
	STATE_KESKI_SUOMI: 'Keski-Suomi',
	STATE_KYMENLAAKSO: 'Kymenlaakso',
	STATE_LANSI_SUOMEN_LAANI: 'Lansi-Suomen Laani',
	STATE_LAPPI: 'Lappi',
	STATE_NORTHERN_SAVONIA: 'Northern Savonia',
	STATE_OSTROBOTHNIA: 'Ostrobothnia',
	STATE_OULUN_LAANI: 'Oulun Laani',
	STATE_PAIJAT_HAME: 'Paijat-Hame',
	STATE_PIRKANMAA: 'Pirkanmaa',
	STATE_POHJANMAA: 'Pohjanmaa',
	STATE_POHJOIS_KARJALA: 'Pohjois-Karjala',
	STATE_POHJOIS_POHJANMAA: 'Pohjois-Pohjanmaa',
	STATE_POHJOIS_SAVO: 'Pohjois-Savo',
	STATE_SAARIJARVI: 'Saarijarvi',
	STATE_SATAKUNTA: 'Satakunta',
	STATE_SOUTHERN_SAVONIA: 'Southern Savonia',
	STATE_TAVASTIA_PROPER: 'Tavastia Proper',
	STATE_ULEABORGS_LAN: 'Uleaborgs Lan',
	STATE_UUSIMAA: 'Uusimaa',
	STATE_VARSINAIS_SUOMI: 'Varsinais-Suomi',
	STATE_AIN: 'Ain',
	STATE_AISNE: 'Aisne',
	STATE_ALBI_LE_SEQUESTRE: 'Albi Le Sequestre',
	STATE_ALLIER: 'Allier',
	STATE_ALPES_COTE_DAZUR: 'Alpes-Cote dAzur',
	STATE_ALPES_MARITIMES: 'Alpes-Maritimes',
	STATE_ALPES_DE_HAUTE_PROVENCE: 'Alpes-de-Haute-Provence',
	STATE_ALSACE: 'Alsace',
	STATE_AQUITAINE: 'Aquitaine',
	STATE_ARDECHE: 'Ardeche',
	STATE_ARDENNES: 'Ardennes',
	STATE_ARIEGE: 'Ariege',
	STATE_AUBE: 'Aube',
	STATE_AUDE: 'Aude',
	STATE_AUVERGNE: 'Auvergne',
	STATE_AVEYRON: 'Aveyron',
	STATE_BAS_RHIN: 'Bas-Rhin',
	STATE_BASSE_NORMANDIE: 'Basse-Normandie',
	STATE_BOUCHES_DU_RHONE: 'Bouches-du-Rhone',
	STATE_BOURGOGNE: 'Bourgogne',
	STATE_BRETAGNE: 'Bretagne',
	STATE_BRITTANY: 'Brittany',
	STATE_BURGUNDY: 'Burgundy',
	STATE_CALVADOS: 'Calvados',
	STATE_CANTAL: 'Cantal',
	STATE_CEDEX: 'Cedex',
	STATE_CHARENTE: 'Charente',
	STATE_CHARENTE_MARITIME: 'Charente-Maritime',
	STATE_CHER: 'Cher',
	STATE_CORREZE: 'Correze',
	STATE_CORSE_DU_SUD: 'Corse-du-Sud',
	STATE_COTE_D__OR: "Cote-d'Or",
	STATE_COTES_D__ARMOR: "Cotes-d'Armor",
	STATE_CREUSE: 'Creuse',
	STATE_CROLLES: 'Crolles',
	STATE_DEUX_SEVRES: 'Deux-Sevres',
	STATE_DORDOGNE: 'Dordogne',
	STATE_DOUBS: 'Doubs',
	STATE_DROME: 'Drome',
	STATE_ESSONNE: 'Essonne',
	STATE_EURE: 'Eure',
	STATE_EURE_ET_LOIR: 'Eure-et-Loir',
	STATE_FEUCHEROLLES: 'Feucherolles',
	STATE_FINISTERE: 'Finistere',
	STATE_FRANCHE_COMTE: 'Franche-Comte',
	STATE_GARD: 'Gard',
	STATE_GERS: 'Gers',
	STATE_GIRONDE: 'Gironde',
	STATE_HAUT_RHIN: 'Haut-Rhin',
	STATE_HAUTE_CORSE: 'Haute-Corse',
	STATE_HAUTE_GARONNE: 'Haute-Garonne',
	STATE_HAUTE_LOIRE: 'Haute-Loire',
	STATE_HAUTE_MARNE: 'Haute-Marne',
	STATE_HAUTE_SAONE: 'Haute-Saone',
	STATE_HAUTE_SAVOIE: 'Haute-Savoie',
	STATE_HAUTE_VIENNE: 'Haute-Vienne',
	STATE_HAUTES_ALPES: 'Hautes-Alpes',
	STATE_HAUTES_PYRENEES: 'Hautes-Pyrenees',
	STATE_HAUTS_DE_SEINE: 'Hauts-de-Seine',
	STATE_HERAULT: 'Herault',
	STATE_ILE_DE_FRANCE: 'Ile-de-France',
	STATE_ILLE_ET_VILAINE: 'Ille-et-Vilaine',
	STATE_INDRE: 'Indre',
	STATE_INDRE_ET_LOIRE: 'Indre-et-Loire',
	STATE_ISERE: 'Isere',
	STATE_JURA: 'Jura',
	STATE_KLAGENFURT: 'Klagenfurt',
	STATE_LANDES: 'Landes',
	STATE_LANGUEDOC_ROUSSILLON: 'Languedoc-Roussillon',
	STATE_LARCAY: 'Larcay',
	STATE_LE_CASTELLET: 'Le Castellet',
	STATE_LE_CREUSOT: 'Le Creusot',
	STATE_LIMOUSIN: 'Limousin',
	STATE_LOIR_ET_CHER: 'Loir-et-Cher',
	STATE_LOIRE: 'Loire',
	STATE_LOIRE_ATLANTIQUE: 'Loire-Atlantique',
	STATE_LOIRET: 'Loiret',
	STATE_LORRAINE: 'Lorraine',
	STATE_LOT: 'Lot',
	STATE_LOT_ET_GARONNE: 'Lot-et-Garonne',
	STATE_LOWER_NORMANDY: 'Lower Normandy',
	STATE_LOZERE: 'Lozere',
	STATE_MAINE_ET_LOIRE: 'Maine-et-Loire',
	STATE_MANCHE: 'Manche',
	STATE_MARNE: 'Marne',
	STATE_MAYENNE: 'Mayenne',
	STATE_MEURTHE_ET_MOSELLE: 'Meurthe-et-Moselle',
	STATE_MEUSE: 'Meuse',
	STATE_MIDI_PYRENEES: 'Midi-Pyrenees',
	STATE_MORBIHAN: 'Morbihan',
	STATE_MOSELLE: 'Moselle',
	STATE_NIEVRE: 'Nievre',
	STATE_NORD_PAS_DE_CALAIS: 'Nord-Pas-de-Calais',
	STATE_OISE: 'Oise',
	STATE_ORNE: 'Orne',
	STATE_PARIS: 'Paris',
	STATE_PAS_DE_CALAIS: 'Pas-de-Calais',
	STATE_PAYS_DE_LA_LOIRE: 'Pays de la Loire',
	STATE_PICARDY: 'Picardy',
	STATE_PUY_DE_DOME: 'Puy-de-Dome',
	STATE_PYRENEES_ATLANTIQUES: 'Pyrenees-Atlantiques',
	STATE_PYRENEES_ORIENTALES: 'Pyrenees-Orientales',
	STATE_QUELMES: 'Quelmes',
	STATE_RHONE: 'Rhone',
	STATE_RHONE_ALPES: 'Rhone-Alpes',
	STATE_SAINT_OUEN: 'Saint Ouen',
	STATE_SAINT_VIATRE: 'Saint Viatre',
	STATE_SAONE_ET_LOIRE: 'Saone-et-Loire',
	STATE_SARTHE: 'Sarthe',
	STATE_SAVOIE: 'Savoie',
	STATE_SEINE_MARITIME: 'Seine-Maritime',
	STATE_SEINE_SAINT_DENIS: 'Seine-Saint-Denis',
	STATE_SEINE_ET_MARNE: 'Seine-et-Marne',
	STATE_SOMME: 'Somme',
	STATE_SOPHIA_ANTIPOLIS: 'Sophia Antipolis',
	STATE_SOUVANS: 'Souvans',
	STATE_TARN: 'Tarn',
	STATE_TARN_ET_GARONNE: 'Tarn-et-Garonne',
	STATE_TERRITOIRE_DE_BELFORT: 'Territoire de Belfort',
	STATE_TREIGNAC: 'Treignac',
	STATE_UPPER_NORMANDY: 'Upper Normandy',
	STATE_VAL_D__OISE: "Val-d'Oise",
	STATE_VAL_DE_MARNE: 'Val-de-Marne',
	STATE_VAR: 'Var',
	STATE_VAUCLUSE: 'Vaucluse',
	STATE_VELLISE: 'Vellise',
	STATE_VENDEE: 'Vendee',
	STATE_VIENNE: 'Vienne',
	STATE_VOSGES: 'Vosges',
	STATE_YONNE: 'Yonne',
	STATE_YVELINES: 'Yvelines',
	STATE_CAYENNE: 'Cayenne',
	STATE_SAINT_LAURENT_DU_MARONI: 'Saint-Laurent-du-Maroni',
	STATE_ILES_DU_VENT: 'Iles du Vent',
	STATE_ILES_SOUS_LE_VENT: 'Iles sous le Vent',
	STATE_MARQUESAS: 'Marquesas',
	STATE_TUAMOTU: 'Tuamotu',
	STATE_TUBUAI: 'Tubuai',
	STATE_AMSTERDAM: 'Amsterdam',
	STATE_CROZET_ISLANDS: 'Crozet Islands',
	STATE_KERGUELEN: 'Kerguelen',
	STATE_ESTUAIRE: 'Estuaire',
	STATE_HAUT_OGOOUE: 'Haut-Ogooue',
	STATE_MOYEN_OGOOUE: 'Moyen-Ogooue',
	STATE_NGOUNIE: 'Ngounie',
	STATE_NYANGA: 'Nyanga',
	STATE_OGOOUE_IVINDO: 'Ogooue-Ivindo',
	STATE_OGOOUE_LOLO: 'Ogooue-Lolo',
	STATE_OGOOUE_MARITIME: 'Ogooue-Maritime',
	STATE_WOLEU_NTEM: 'Woleu-Ntem',
	STATE_BANJUL: 'Banjul',
	STATE_BASSE: 'Basse',
	STATE_BRIKAMA: 'Brikama',
	STATE_JANJANBUREH: 'Janjanbureh',
	STATE_KANIFING: 'Kanifing',
	STATE_KEREWAN: 'Kerewan',
	STATE_KUNTAUR: 'Kuntaur',
	STATE_MANSAKONKO: 'Mansakonko',
	STATE_ABHASIA: 'Abhasia',
	STATE_AJARIA: 'Ajaria',
	STATE_GURIA: 'Guria',
	STATE_IMERETI: 'Imereti',
	STATE_KAHETI: 'Kaheti',
	STATE_KVEMO_KARTLI: 'Kvemo Kartli',
	STATE_MCHETA_MTIANETI: 'Mcheta-Mtianeti',
	STATE_RACHA: 'Racha',
	STATE_SAMAGRELO_ZEMO_SVANETI: 'Samagrelo-Zemo Svaneti',
	STATE_SAMCHE_ZHAVAHETI: 'Samche-Zhavaheti',
	STATE_SHIDA_KARTLI: 'Shida Kartli',
	STATE_TBILISI: 'Tbilisi',
	STATE_BADEN_WURTTEMBERG: 'Baden-Wurttemberg',
	STATE_BAVARIA: 'Bavaria',
	STATE_BAYERN: 'Bayern',
	STATE_BEILSTEIN_WURTT: 'Beilstein Wurtt',
	STATE_BERLIN: 'Berlin',
	STATE_BRANDENBURG: 'Brandenburg',
	STATE_BREMEN: 'Bremen',
	STATE_DREISBACH: 'Dreisbach',
	STATE_FREISTAAT_BAYERN: 'Freistaat Bayern',
	STATE_HAMBURG: 'Hamburg',
	STATE_HANNOVER: 'Hannover',
	STATE_HEROLDSTATT: 'Heroldstatt',
	STATE_HESSEN: 'Hessen',
	STATE_KORTENBERG: 'Kortenberg',
	STATE_LAASDORF: 'Laasdorf',
	STATE_LAND_BADEN_WURTTEMBERG: 'Land Baden-Wurttemberg',
	STATE_LAND_BAYERN: 'Land Bayern',
	STATE_LAND_BRANDENBURG: 'Land Brandenburg',
	STATE_LAND_HESSEN: 'Land Hessen',
	STATE_LAND_MECKLENBURG_VORPOMMERN: 'Land Mecklenburg-Vorpommern',
	STATE_LAND_NORDRHEIN_WESTFALEN: 'Land Nordrhein-Westfalen',
	STATE_LAND_RHEINLAND_PFALZ: 'Land Rheinland-Pfalz',
	STATE_LAND_SACHSEN: 'Land Sachsen',
	STATE_LAND_SACHSEN_ANHALT: 'Land Sachsen-Anhalt',
	STATE_LAND_THURINGEN: 'Land Thuringen',
	STATE_LOWER_SAXONY: 'Lower Saxony',
	STATE_MECKLENBURG_VORPOMMERN: 'Mecklenburg-Vorpommern',
	STATE_MULFINGEN: 'Mulfingen',
	STATE_MUNICH: 'Munich',
	STATE_NEUBEUERN: 'Neubeuern',
	STATE_NIEDERSACHSEN: 'Niedersachsen',
	STATE_NOORD_HOLLAND: 'Noord-Holland',
	STATE_NORDRHEIN_WESTFALEN: 'Nordrhein-Westfalen',
	STATE_NORTH_RHINE_WESTPHALIA: 'North Rhine-Westphalia',
	STATE_OSTERODE: 'Osterode',
	STATE_RHEINLAND_PFALZ: 'Rheinland-Pfalz',
	STATE_RHINELAND_PALATINATE: 'Rhineland-Palatinate',
	STATE_SAARLAND: 'Saarland',
	STATE_SACHSEN: 'Sachsen',
	STATE_SACHSEN_ANHALT: 'Sachsen-Anhalt',
	STATE_SAXONY: 'Saxony',
	STATE_THURINGIA: 'Thuringia',
	STATE_WEBLING: 'Webling',
	STATE_WEINSTRABE: 'Weinstrabe',
	STATE_SCHLOBBORN: 'schlobborn',
	STATE_ASHANTI: 'Ashanti',
	STATE_BRONG_AHAFO: 'Brong-Ahafo',
	STATE_GREATER_ACCRA: 'Greater Accra',
	STATE_UPPER_EAST: 'Upper East',
	STATE_UPPER_WEST: 'Upper West',
	STATE_VOLTA: 'Volta',
	STATE_GIBRALTAR: 'Gibraltar',
	STATE_ACHARNES: 'Acharnes',
	STATE_AHAIA: 'Ahaia',
	STATE_AITOLIA_KAI_AKARNANIA: 'Aitolia kai Akarnania',
	STATE_ARGOLIS: 'Argolis',
	STATE_ARKADIA: 'Arkadia',
	STATE_ARTA: 'Arta',
	STATE_ATTICA: 'Attica',
	STATE_ATTIKI: 'Attiki',
	STATE_AYION_OROS: 'Ayion Oros',
	STATE_CRETE: 'Crete',
	STATE_DODEKANISOS: 'Dodekanisos',
	STATE_DRAMA: 'Drama',
	STATE_EVIA: 'Evia',
	STATE_EVRITANIA: 'Evritania',
	STATE_EVROS: 'Evros',
	STATE_EVVOIA: 'Evvoia',
	STATE_FLORINA: 'Florina',
	STATE_FOKIS: 'Fokis',
	STATE_FTHIOTIS: 'Fthiotis',
	STATE_GREVENA: 'Grevena',
	STATE_HALANDRI: 'Halandri',
	STATE_HALKIDIKI: 'Halkidiki',
	STATE_HANIA: 'Hania',
	STATE_HERAKLION: 'Heraklion',
	STATE_HIOS: 'Hios',
	STATE_ILIA: 'Ilia',
	STATE_IMATHIA: 'Imathia',
	STATE_IOANNINA: 'Ioannina',
	STATE_IRAKLION: 'Iraklion',
	STATE_KARDITSA: 'Karditsa',
	STATE_KASTORIA: 'Kastoria',
	STATE_KAVALA: 'Kavala',
	STATE_KEFALLINIA: 'Kefallinia',
	STATE_KERKIRA: 'Kerkira',
	STATE_KIKLADES: 'Kiklades',
	STATE_KILKIS: 'Kilkis',
	STATE_KORINTHIA: 'Korinthia',
	STATE_KOZANI: 'Kozani',
	STATE_LAKONIA: 'Lakonia',
	STATE_LARISA: 'Larisa',
	STATE_LASITHI: 'Lasithi',
	STATE_LESVOS: 'Lesvos',
	STATE_LEVKAS: 'Levkas',
	STATE_MAGNISIA: 'Magnisia',
	STATE_MESSINIA: 'Messinia',
	STATE_NOMOS_ATTIKIS: 'Nomos Attikis',
	STATE_NOMOS_ZAKYNTHOU: 'Nomos Zakynthou',
	STATE_PELLA: 'Pella',
	STATE_PIERIA: 'Pieria',
	STATE_PIRAIOS: 'Piraios',
	STATE_PREVEZA: 'Preveza',
	STATE_RETHIMNI: 'Rethimni',
	STATE_RODOPI: 'Rodopi',
	STATE_SAMOS: 'Samos',
	STATE_SERRAI: 'Serrai',
	STATE_THESPROTIA: 'Thesprotia',
	STATE_THESSALONIKI: 'Thessaloniki',
	STATE_TRIKALA: 'Trikala',
	STATE_VOIOTIA: 'Voiotia',
	STATE_WEST_GREECE: 'West Greece',
	STATE_XANTHI: 'Xanthi',
	STATE_ZAKINTHOS: 'Zakinthos',
	STATE_AASIAAT: 'Aasiaat',
	STATE_AMMASSALIK: 'Ammassalik',
	STATE_ILLOQQORTOORMIUT: 'Illoqqortoormiut',
	STATE_ILULISSAT: 'Ilulissat',
	STATE_IVITTUUT: 'Ivittuut',
	STATE_KANGAATSIAQ: 'Kangaatsiaq',
	STATE_MANIITSOQ: 'Maniitsoq',
	STATE_NANORTALIK: 'Nanortalik',
	STATE_NARSAQ: 'Narsaq',
	STATE_NUUK: 'Nuuk',
	STATE_PAAMIUT: 'Paamiut',
	STATE_QAANAAQ: 'Qaanaaq',
	STATE_QAQORTOQ: 'Qaqortoq',
	STATE_QASIGIANNGUIT: 'Qasigiannguit',
	STATE_QEQERTARSUAQ: 'Qeqertarsuaq',
	STATE_SISIMIUT: 'Sisimiut',
	STATE_UDENFOR_KOMMUNAL_INDDELING: 'Udenfor kommunal inddeling',
	STATE_UPERNAVIK: 'Upernavik',
	STATE_UUMMANNAQ: 'Uummannaq',
	STATE_CARRIACOU_PETITE_MARTINIQUE: 'Carriacou-Petite Martinique',
	STATE_SAINT_DAVIDS: 'Saint Davids',
	STATE_SAINT_GEORGE__S: "Saint George's",
	STATE_BASSE_TERRE: 'Basse-Terre',
	STATE_GRANDE_TERRE: 'Grande-Terre',
	STATE_ILES_DES_SAINTES: 'Iles des Saintes',
	STATE_LA_DESIRADE: 'La Desirade',
	STATE_MARIE_GALANTE: 'Marie-Galante',
	STATE_SAINT_BARTHELEMY: 'Saint Barthelemy',
	STATE_SAINT_MARTIN: 'Saint Martin',
	STATE_AGANA_HEIGHTS: 'Agana Heights',
	STATE_AGAT: 'Agat',
	STATE_BARRIGADA: 'Barrigada',
	STATE_CHALAN_PAGO_ORDOT: 'Chalan-Pago-Ordot',
	STATE_DEDEDO: 'Dededo',
	STATE_HAGATNA: 'Hagatna',
	STATE_INARAJAN: 'Inarajan',
	STATE_MANGILAO: 'Mangilao',
	STATE_MERIZO: 'Merizo',
	STATE_MONGMONG_TOTO_MAITE: 'Mongmong-Toto-Maite',
	STATE_SANTA_RITA: 'Santa Rita',
	STATE_SINAJANA: 'Sinajana',
	STATE_TALOFOFO: 'Talofofo',
	STATE_TAMUNING: 'Tamuning',
	STATE_YIGO: 'Yigo',
	STATE_YONA: 'Yona',
	STATE_ALTA_VERAPAZ: 'Alta Verapaz',
	STATE_BAJA_VERAPAZ: 'Baja Verapaz',
	STATE_CHIMALTENANGO: 'Chimaltenango',
	STATE_CHIQUIMULA: 'Chiquimula',
	STATE_EL_PROGRESO: 'El Progreso',
	STATE_ESCUINTLA: 'Escuintla',
	STATE_GUATEMALA: 'Guatemala',
	STATE_HUEHUETENANGO: 'Huehuetenango',
	STATE_IZABAL: 'Izabal',
	STATE_JALAPA: 'Jalapa',
	STATE_JUTIAPA: 'Jutiapa',
	STATE_PETEN: 'Peten',
	STATE_QUEZALTENANGO: 'Quezaltenango',
	STATE_QUICHE: 'Quiche',
	STATE_RETALHULEU: 'Retalhuleu',
	STATE_SACATEPEQUEZ: 'Sacatepequez',
	STATE_SAN_MARCOS: 'San Marcos',
	STATE_SANTA_ROSA: 'Santa Rosa',
	STATE_SOLOLA: 'Solola',
	STATE_SUCHITEPEQUEZ: 'Suchitepequez',
	STATE_TOTONICAPAN: 'Totonicapan',
	STATE_ZACAPA: 'Zacapa',
	STATE_ALDERNEY: 'Alderney',
	STATE_CASTEL: 'Castel',
	STATE_FOREST: 'Forest',
	STATE_SAINT_PETER_PORT: 'Saint Peter Port',
	STATE_SAINT_PIERRE_DU_BOIS: 'Saint Pierre du Bois',
	STATE_SAINT_SAMPSON: 'Saint Sampson',
	STATE_SAINT_SAVIOUR: 'Saint Saviour',
	STATE_SARK: 'Sark',
	STATE_TORTEVAL: 'Torteval',
	STATE_VALE: 'Vale',
	STATE_BEYLA: 'Beyla',
	STATE_BOFFA: 'Boffa',
	STATE_BOKE: 'Boke',
	STATE_CONAKRY: 'Conakry',
	STATE_COYAH: 'Coyah',
	STATE_DABOLA: 'Dabola',
	STATE_DALABA: 'Dalaba',
	STATE_DINGUIRAYE: 'Dinguiraye',
	STATE_FARANAH: 'Faranah',
	STATE_FORECARIAH: 'Forecariah',
	STATE_FRIA: 'Fria',
	STATE_GAOUAL: 'Gaoual',
	STATE_GUECKEDOU: 'Gueckedou',
	STATE_KANKAN: 'Kankan',
	STATE_KEROUANE: 'Kerouane',
	STATE_KINDIA: 'Kindia',
	STATE_KISSIDOUGOU: 'Kissidougou',
	STATE_KOUBIA: 'Koubia',
	STATE_KOUNDARA: 'Koundara',
	STATE_KOUROUSSA: 'Kouroussa',
	STATE_LABE: 'Labe',
	STATE_LOLA: 'Lola',
	STATE_MACENTA: 'Macenta',
	STATE_MALI: 'Mali',
	STATE_MAMOU: 'Mamou',
	STATE_MANDIANA: 'Mandiana',
	STATE_NZEREKORE: 'Nzerekore',
	STATE_PITA: 'Pita',
	STATE_SIGUIRI: 'Siguiri',
	STATE_TELIMELE: 'Telimele',
	STATE_TOUGUE: 'Tougue',
	STATE_YOMOU: 'Yomou',
	STATE_BAFATA: 'Bafata',
	STATE_BISSAU: 'Bissau',
	STATE_BOLAMA: 'Bolama',
	STATE_CACHEU: 'Cacheu',
	STATE_GABU: 'Gabu',
	STATE_OIO: 'Oio',
	STATE_QUINARA: 'Quinara',
	STATE_TOMBALI: 'Tombali',
	STATE_BARIMA_WAINI: 'Barima-Waini',
	STATE_CUYUNI_MAZARUNI: 'Cuyuni-Mazaruni',
	STATE_DEMERARA_MAHAICA: 'Demerara-Mahaica',
	STATE_EAST_BERBICE_CORENTYNE: 'East Berbice-Corentyne',
	STATE_ESSEQUIBO_ISLANDS_WEST_DEMERAR: 'Essequibo Islands-West Demerar',
	STATE_MAHAICA_BERBICE: 'Mahaica-Berbice',
	STATE_POMEROON_SUPENAAM: 'Pomeroon-Supenaam',
	STATE_POTARO_SIPARUNI: 'Potaro-Siparuni',
	STATE_UPPER_DEMERARA_BERBICE: 'Upper Demerara-Berbice',
	STATE_UPPER_TAKUTU_UPPER_ESSEQUIBO: 'Upper Takutu-Upper Essequibo',
	STATE_ARTIBONITE: 'Artibonite',
	STATE_GRAND__ANSE: "Grand'Anse",
	STATE_NORD_EST: 'Nord-Est',
	STATE_NORD_OUEST: 'Nord-Ouest',
	STATE_SUD_EST: 'Sud-Est',
	STATE_HEARD_AND_MCDONALD_ISLANDS: 'Heard and McDonald Islands',
	STATE_ATLANTIDA: 'Atlántida',
	STATE_CHOLUTECA: 'Choluteca',
	STATE_COLON: 'Colón',
	STATE_COMAYAGUA: 'Comayagua',
	STATE_COPAN: 'Copán',
	STATE_CORTES: 'Cortés',
	STATE_DISTRITO_CENTRAL: 'Distrito Central',
	STATE_EL_PARAISO: 'El Paraíso',
	STATE_FRANCISCO_MORAZAN: 'Francisco Morazán',
	STATE_GRACIAS_A_DIOS: 'Gracias a Dios',
	STATE_INTIBUCA: 'Intibuca',
	STATE_ISLAS_DE_LA_BAHIA: 'Islas de la Bahía',
	STATE_LEMPIRA: 'Lempira',
	STATE_OCOTEPEQUE: 'Ocotepeque',
	STATE_OLANCHO: 'Olancho',
	STATE_SANTA_BARBARA: 'Santa Bárbara',
	STATE_VALLE: 'Valle',
	STATE_YORO: 'Yoro',
	STATE_HONG_KONG: 'Hong Kong',
	STATE_BACS_KISKUN: 'Bacs-Kiskun',
	STATE_BARANYA: 'Baranya',
	STATE_BEKES: 'Bekes',
	STATE_BORSOD_ABAUJ_ZEMPLEN: 'Borsod-Abauj-Zemplen',
	STATE_BUDAPEST: 'Budapest',
	STATE_CSONGRAD: 'Csongrad',
	STATE_FEJER: 'Fejer',
	STATE_GYOR_MOSON_SOPRON: 'Gyor-Moson-Sopron',
	STATE_CORK: 'Cork',
	STATE_HAJDU_BIHAR: 'Hajdu-Bihar',
	STATE_HEVES: 'Heves',
	STATE_JASZ_NAGYKUN_SZOLNOK: 'Jasz-Nagykun-Szolnok',
	STATE_KOMAROM_ESZTERGOM: 'Komarom-Esztergom',
	STATE_NOGRAD: 'Nograd',
	STATE_PEST: 'Pest',
	STATE_SOMOGY: 'Somogy',
	STATE_SZABOLCS_SZATMAR_BEREG: 'Szabolcs-Szatmar-Bereg',
	STATE_TOLNA: 'Tolna',
	STATE_VAS: 'Vas',
	STATE_VESZPREM: 'Veszprem',
	STATE_ZALA: 'Zala',
	STATE_AUSTURLAND: 'Austurland',
	STATE_GULLBRINGUSYSLA: 'Gullbringusysla',
	STATE_HOFU_BORGARSVA_I: 'Hofu borgarsva i',
	STATE_NOR_URLAND_EYSTRA: 'Nor urland eystra',
	STATE_NOR_URLAND_VESTRA: 'Nor urland vestra',
	STATE_SU_URLAND: 'Su urland',
	STATE_SU_URNES: 'Su urnes',
	STATE_VESTFIR_IR: 'Vestfir ir',
	STATE_VESTURLAND: 'Vesturland',
	STATE_ACEH: 'Aceh',
	STATE_BALI: 'Bali',
	STATE_BANGKA_BELITUNG: 'Bangka-Belitung',
	STATE_BANTEN: 'Banten',
	STATE_BENGKULU: 'Bengkulu',
	STATE_GANDARIA: 'Gandaria',
	STATE_GORONTALO: 'Gorontalo',
	STATE_JAKARTA: 'Jakarta',
	STATE_JAMBI: 'Jambi',
	STATE_JAWA_BARAT: 'Jawa Barat',
	STATE_JAWA_TENGAH: 'Jawa Tengah',
	STATE_JAWA_TIMUR: 'Jawa Timur',
	STATE_KALIMANTAN_BARAT: 'Kalimantan Barat',
	STATE_KALIMANTAN_SELATAN: 'Kalimantan Selatan',
	STATE_KALIMANTAN_TENGAH: 'Kalimantan Tengah',
	STATE_KALIMANTAN_TIMUR: 'Kalimantan Timur',
	STATE_KENDAL: 'Kendal',
	STATE_LAMPUNG: 'Lampung',
	STATE_MALUKU: 'Maluku',
	STATE_MALUKU_UTARA: 'Maluku Utara',
	STATE_NUSA_TENGGARA_BARAT: 'Nusa Tenggara Barat',
	STATE_NUSA_TENGGARA_TIMUR: 'Nusa Tenggara Timur',
	STATE_PAPUA: 'Papua',
	STATE_RIAU: 'Riau',
	STATE_RIAU_KEPULAUAN: 'Riau Kepulauan',
	STATE_SOLO: 'Solo',
	STATE_SULAWESI_SELATAN: 'Sulawesi Selatan',
	STATE_SULAWESI_TENGAH: 'Sulawesi Tengah',
	STATE_SULAWESI_TENGGARA: 'Sulawesi Tenggara',
	STATE_SULAWESI_UTARA: 'Sulawesi Utara',
	STATE_SUMATERA_BARAT: 'Sumatera Barat',
	STATE_SUMATERA_SELATAN: 'Sumatera Selatan',
	STATE_SUMATERA_UTARA: 'Sumatera Utara',
	STATE_YOGYAKARTA: 'Yogyakarta',
	STATE_ARDABIL: 'Ardabil',
	STATE_AZARBAYJAN_E_BAKHTARI: 'Azarbayjan-e Bakhtari',
	STATE_AZARBAYJAN_E_KHAVARI: 'Azarbayjan-e Khavari',
	STATE_BUSHEHR: 'Bushehr',
	STATE_CHAHAR_MAHAL_E_BAKHTIARI: 'Chahar Mahal-e Bakhtiari',
	STATE_ESFAHAN: 'Esfahan',
	STATE_FARS: 'Fars',
	STATE_GILAN: 'Gilan',
	STATE_GOLESTAN: 'Golestan',
	STATE_HAMADAN: 'Hamadan',
	STATE_HORMOZGAN: 'Hormozgan',
	STATE_ILAM: 'Ilam',
	STATE_KERMAN: 'Kerman',
	STATE_KERMANSHAH: 'Kermanshah',
	STATE_KHORASAN: 'Khorasan',
	STATE_KHUZESTAN: 'Khuzestan',
	STATE_KOHGILUYEH_E_BOYERAHMAD: 'Kohgiluyeh-e Boyerahmad',
	STATE_KORDESTAN: 'Kordestan',
	STATE_LORESTAN: 'Lorestan',
	STATE_MARKAZI: 'Markazi',
	STATE_MAZANDARAN: 'Mazandaran',
	STATE_OSTAN_E_ESFAHAN: 'Ostan-e Esfahan',
	STATE_QAZVIN: 'Qazvin',
	STATE_QOM: 'Qom',
	STATE_SEMNAN: 'Semnan',
	STATE_SISTAN_E_BALUCHESTAN: 'Sistan-e Baluchestan',
	STATE_TEHRAN: 'Tehran',
	STATE_YAZD: 'Yazd',
	STATE_ZANJAN: 'Zanjan',
	STATE_BABIL: 'Babil',
	STATE_BAGHDAD: 'Baghdad',
	STATE_DAHUK: 'Dahuk',
	STATE_DHI_QAR: 'Dhi Qar',
	STATE_DIYALA: 'Diyala',
	STATE_ERBIL: 'Erbil',
	STATE_IRBIL: 'Irbil',
	STATE_KARBALA: 'Karbala',
	STATE_KURDISTAN: 'Kurdistan',
	STATE_MAYSAN: 'Maysan',
	STATE_NINAWA: 'Ninawa',
	STATE_SALAH_AD_DIN: 'Salah-ad-Din',
	STATE_WASIT: 'Wasit',
	STATE_AL_ANBAR: 'al-Anbar',
	STATE_AL_BASRAH: 'al-Basrah',
	STATE_AL_MUTHANNA: 'al-Muthanna',
	STATE_AL_QADISIYAH: 'al-Qadisiyah',
	STATE_AN_NAJAF: 'an-Najaf',
	STATE_AS_SULAYMANIYAH: 'as-Sulaymaniyah',
	STATE_AT_TA__MIM: "at-Ta'mim",
	STATE_ARMAGH: 'Armagh',
	STATE_CARLOW: 'Carlow',
	STATE_CAVAN: 'Cavan',
	STATE_CLARE: 'Clare',
	STATE_DONEGAL: 'Donegal',
	STATE_DUBLIN: 'Dublin',
	STATE_GALWAY: 'Galway',
	STATE_KERRY: 'Kerry',
	STATE_KILDARE: 'Kildare',
	STATE_KILKENNY: 'Kilkenny',
	STATE_LAOIS: 'Laois',
	STATE_LEINSTER: 'Leinster',
	STATE_LEITRIM: 'Leitrim',
	STATE_LIMERICK: 'Limerick',
	STATE_LOCH_GARMAN: 'Loch Garman',
	STATE_LONGFORD: 'Longford',
	STATE_LOUTH: 'Louth',
	STATE_MAYO: 'Mayo',
	STATE_MEATH: 'Meath',
	STATE_MONAGHAN: 'Monaghan',
	STATE_OFFALY: 'Offaly',
	STATE_ROSCOMMON: 'Roscommon',
	STATE_SLIGO: 'Sligo',
	STATE_TIPPERARY_NORTH_RIDING: 'Tipperary North Riding',
	STATE_TIPPERARY_SOUTH_RIDING: 'Tipperary South Riding',
	STATE_ULSTER: 'Ulster',
	STATE_WATERFORD: 'Waterford',
	STATE_WESTMEATH: 'Westmeath',
	STATE_WEXFORD: 'Wexford',
	STATE_WICKLOW: 'Wicklow',
	STATE_BEIT_HANANIA: 'Beit Hanania',
	STATE_BEN_GURION_AIRPORT: 'Ben Gurion Airport',
	STATE_BETHLEHEM: 'Bethlehem',
	STATE_CAESAREA: 'Caesarea',
	STATE_GAZA: 'Gaza',
	STATE_HADARON: 'Hadaron',
	STATE_HAIFA_DISTRICT: 'Haifa District',
	STATE_HAMERKAZ: 'Hamerkaz',
	STATE_HAZAFON: 'Hazafon',
	STATE_HEBRON: 'Hebron',
	STATE_JAFFA: 'Jaffa',
	STATE_JERUSALEM: 'Jerusalem',
	STATE_KHEFA: 'Khefa',
	STATE_KIRYAT_YAM: 'Kiryat Yam',
	STATE_LOWER_GALILEE: 'Lower Galilee',
	STATE_QALQILYA: 'Qalqilya',
	STATE_TALME_ELAZAR: 'Talme Elazar',
	STATE_TEL_AVIV: 'Tel Aviv',
	STATE_TSAFON: 'Tsafon',
	STATE_UMM_EL_FAHEM: 'Umm El Fahem',
	STATE_YERUSHALAYIM: 'Yerushalayim',
	STATE_ABRUZZI: 'Abruzzi',
	STATE_ABRUZZO: 'Abruzzo',
	STATE_AGRIGENTO: 'Agrigento',
	STATE_ALESSANDRIA: 'Alessandria',
	STATE_ANCONA: 'Ancona',
	STATE_AREZZO: 'Arezzo',
	STATE_ASCOLI_PICENO: 'Ascoli Piceno',
	STATE_ASTI: 'Asti',
	STATE_AVELLINO: 'Avellino',
	STATE_BARI: 'Bari',
	STATE_BASILICATA: 'Basilicata',
	STATE_BELLUNO: 'Belluno',
	STATE_BENEVENTO: 'Benevento',
	STATE_BERGAMO: 'Bergamo',
	STATE_BIELLA: 'Biella',
	STATE_BOLOGNA: 'Bologna',
	STATE_BOLZANO: 'Bolzano',
	STATE_BRESCIA: 'Brescia',
	STATE_BRINDISI: 'Brindisi',
	STATE_CALABRIA: 'Calabria',
	STATE_CAMPANIA: 'Campania',
	STATE_CARTOCETO: 'Cartoceto',
	STATE_CASERTA: 'Caserta',
	STATE_CATANIA: 'Catania',
	STATE_CHIETI: 'Chieti',
	STATE_COMO: 'Como',
	STATE_COSENZA: 'Cosenza',
	STATE_CREMONA: 'Cremona',
	STATE_CUNEO: 'Cuneo',
	STATE_EMILIA_ROMAGNA: 'Emilia-Romagna',
	STATE_FERRARA: 'Ferrara',
	STATE_FIRENZE: 'Firenze',
	STATE_FLORENCE: 'Florence',
	STATE_FORLI_CESENA: 'Forli-Cesena',
	STATE_FRIULI_VENEZIA_GIULIA: 'Friuli-Venezia Giulia',
	STATE_FROSINONE: 'Frosinone',
	STATE_GENOA: 'Genoa',
	STATE_GORIZIA: 'Gorizia',
	STATE_L__AQUILA: "L'Aquila",
	STATE_LAZIO: 'Lazio',
	STATE_LECCE: 'Lecce',
	STATE_LECCO: 'Lecco',
	STATE_LECCO_PROVINCE: 'Lecco Province',
	STATE_LIGURIA: 'Liguria',
	STATE_LODI: 'Lodi',
	STATE_LOMBARDIA: 'Lombardia',
	STATE_LOMBARDY: 'Lombardy',
	STATE_MACERATA: 'Macerata',
	STATE_MANTOVA: 'Mantova',
	STATE_MARCHE: 'Marche',
	STATE_MESSINA: 'Messina',
	STATE_MILAN: 'Milan',
	STATE_MODENA: 'Modena',
	STATE_MOLISE: 'Molise',
	STATE_MOLTENO: 'Molteno',
	STATE_MONTENEGRO: 'Montenegro',
	STATE_MONZA_AND_BRIANZA: 'Monza and Brianza',
	STATE_NAPLES: 'Naples',
	STATE_NOVARA: 'Novara',
	STATE_PADOVA: 'Padova',
	STATE_PARMA: 'Parma',
	STATE_PAVIA: 'Pavia',
	STATE_RUMPHI: 'Rumphi',
	STATE_PERUGIA: 'Perugia',
	STATE_PESARO_URBINO: 'Pesaro-Urbino',
	STATE_PIACENZA: 'Piacenza',
	STATE_PIEDMONT: 'Piedmont',
	STATE_PIEMONTE: 'Piemonte',
	STATE_PISA: 'Pisa',
	STATE_PORDENONE: 'Pordenone',
	STATE_POTENZA: 'Potenza',
	STATE_PUGLIA: 'Puglia',
	STATE_REGGIO_EMILIA: 'Reggio Emilia',
	STATE_RIMINI: 'Rimini',
	STATE_ROMA: 'Roma',
	STATE_SALERNO: 'Salerno',
	STATE_SARDEGNA: 'Sardegna',
	STATE_SASSARI: 'Sassari',
	STATE_SAVONA: 'Savona',
	STATE_SICILIA: 'Sicilia',
	STATE_SIENA: 'Siena',
	STATE_SONDRIO: 'Sondrio',
	STATE_SOUTH_TYROL: 'South Tyrol',
	STATE_TARANTO: 'Taranto',
	STATE_TERAMO: 'Teramo',
	STATE_TORINO: 'Torino',
	STATE_TOSCANA: 'Toscana',
	STATE_TRAPANI: 'Trapani',
	STATE_TRENTINO_ALTO_ADIGE: 'Trentino-Alto Adige',
	STATE_TRENTO: 'Trento',
	STATE_TREVISO: 'Treviso',
	STATE_UDINE: 'Udine',
	STATE_UMBRIA: 'Umbria',
	STATE_VALLE_D__AOSTA: "Valle d'Aosta",
	STATE_VARESE: 'Varese',
	STATE_VENETO: 'Veneto',
	STATE_VENEZIA: 'Venezia',
	STATE_VERBANO_CUSIO_OSSOLA: 'Verbano-Cusio-Ossola',
	STATE_VERCELLI: 'Vercelli',
	STATE_VERONA: 'Verona',
	STATE_VICENZA: 'Vicenza',
	STATE_VITERBO: 'Viterbo',
	STATE_BUXORO_VILOYATI: 'Buxoro Viloyati',
	STATE_CLARENDON: 'Clarendon',
	STATE_HANOVER: 'Hanover',
	STATE_KINGSTON: 'Kingston',
	STATE_MANCHESTER: 'Manchester',
	STATE_PORTLAND: 'Portland',
	STATE_SAINT_ANDREWS: 'Saint Andrews',
	STATE_SAINT_ANN: 'Saint Ann',
	STATE_SAINT_CATHERINE: 'Saint Catherine',
	STATE_SAINT_ELIZABETH: 'Saint Elizabeth',
	STATE_TRELAWNEY: 'Trelawney',
	STATE_WESTMORELAND: 'Westmoreland',
	STATE_AICHI: 'Aichi',
	STATE_AKITA: 'Akita',
	STATE_AOMORI: 'Aomori',
	STATE_CHIBA: 'Chiba',
	STATE_EHIME: 'Ehime',
	STATE_FUKUI: 'Fukui',
	STATE_FUKUOKA: 'Fukuoka',
	STATE_FUKUSHIMA: 'Fukushima',
	STATE_GIFU: 'Gifu',
	STATE_GUMMA: 'Gumma',
	STATE_HIROSHIMA: 'Hiroshima',
	STATE_HOKKAIDO: 'Hokkaido',
	STATE_HYOGO: 'Hyogo',
	STATE_IBARAKI: 'Ibaraki',
	STATE_ISHIKAWA: 'Ishikawa',
	STATE_IWATE: 'Iwate',
	STATE_KAGAWA: 'Kagawa',
	STATE_KAGOSHIMA: 'Kagoshima',
	STATE_KANAGAWA: 'Kanagawa',
	STATE_KANTO: 'Kanto',
	STATE_KOCHI: 'Kochi',
	STATE_KUMAMOTO: 'Kumamoto',
	STATE_KYOTO: 'Kyoto',
	STATE_MIE: 'Mie',
	STATE_MIYAGI: 'Miyagi',
	STATE_MIYAZAKI: 'Miyazaki',
	STATE_NAGANO: 'Nagano',
	STATE_NAGASAKI: 'Nagasaki',
	STATE_NARA: 'Nara',
	STATE_NIIGATA: 'Niigata',
	STATE_OITA: 'Oita',
	STATE_OKAYAMA: 'Okayama',
	STATE_OKINAWA: 'Okinawa',
	STATE_OSAKA: 'Osaka',
	STATE_SAGA: 'Saga',
	STATE_SAITAMA: 'Saitama',
	STATE_SHIGA: 'Shiga',
	STATE_SHIMANE: 'Shimane',
	STATE_SHIZUOKA: 'Shizuoka',
	STATE_TOCHIGI: 'Tochigi',
	STATE_TOKUSHIMA: 'Tokushima',
	STATE_TOKYO: 'Tokyo',
	STATE_TOTTORI: 'Tottori',
	STATE_TOYAMA: 'Toyama',
	STATE_WAKAYAMA: 'Wakayama',
	STATE_YAMAGATA: 'Yamagata',
	STATE_YAMAGUCHI: 'Yamaguchi',
	STATE_YAMANASHI: 'Yamanashi',
	STATE_GROUVILLE: 'Grouville',
	STATE_SAINT_BRELADE: 'Saint Brelade',
	STATE_SAINT_CLEMENT: 'Saint Clement',
	STATE_SAINT_HELIER: 'Saint Helier',
	STATE_SAINT_LAWRENCE: 'Saint Lawrence',
	STATE_SALIMA: 'Salima',
	STATE_TRINITY: 'Trinity',
	STATE_AJLUN: 'Ajlun',
	STATE_AMMAN: 'Amman',
	STATE_IRBID: 'Irbid',
	STATE_JARASH: 'Jarash',
	STATE_MA__AN: "Ma'an",
	STATE_MADABA: 'Madaba',
	STATE_AL___AQABAH: "al-'Aqabah",
	STATE_AL_BALQA: 'al-Balqa',
	STATE_AL_KARAK: 'al-Karak',
	STATE_AL_MAFRAQ: 'al-Mafraq',
	STATE_AT_TAFILAH: 'at-Tafilah',
	STATE_AZ_ZARQA: 'az-Zarqa',
	STATE_AKMECET: 'Akmecet',
	STATE_AKMOLA: 'Akmola',
	STATE_AKTOBE: 'Aktobe',
	STATE_ALMATI: 'Almati',
	STATE_ATIRAU: 'Atirau',
	STATE_BATIS_KAZAKSTAN: 'Batis Kazakstan',
	STATE_BURLINSKY_REGION: 'Burlinsky Region',
	STATE_KARAGANDI: 'Karagandi',
	STATE_KOSTANAY: 'Kostanay',
	STATE_MANKISTAU: 'Mankistau',
	STATE_ONTUSTIK_KAZAKSTAN: 'Ontustik Kazakstan',
	STATE_PAVLODAR: 'Pavlodar',
	STATE_SIGIS_KAZAKSTAN: 'Sigis Kazakstan',
	STATE_SOLTUSTIK_KAZAKSTAN: 'Soltustik Kazakstan',
	STATE_TARAZ: 'Taraz',
	STATE_COAST: 'Coast',
	STATE_NAIROBI: 'Nairobi',
	STATE_NORTH_EASTERN: 'North Eastern',
	STATE_NYANZA: 'Nyanza',
	STATE_RIFT_VALLEY: 'Rift Valley',
	STATE_ABAIANG: 'Abaiang',
	STATE_ABEMANA: 'Abemana',
	STATE_ARANUKA: 'Aranuka',
	STATE_ARORAE: 'Arorae',
	STATE_BANABA: 'Banaba',
	STATE_BERU: 'Beru',
	STATE_BUTARITARI: 'Butaritari',
	STATE_KIRITIMATI: 'Kiritimati',
	STATE_KURIA: 'Kuria',
	STATE_MAIANA: 'Maiana',
	STATE_MAKIN: 'Makin',
	STATE_MARAKEI: 'Marakei',
	STATE_NIKUNAU: 'Nikunau',
	STATE_NONOUTI: 'Nonouti',
	STATE_ONOTOA: 'Onotoa',
	STATE_PHOENIX_ISLANDS: 'Phoenix Islands',
	STATE_TABITEUEA_NORTH: 'Tabiteuea North',
	STATE_TABITEUEA_SOUTH: 'Tabiteuea South',
	STATE_TABUAERAN: 'Tabuaeran',
	STATE_TAMANA: 'Tamana',
	STATE_TARAWA_NORTH: 'Tarawa North',
	STATE_TARAWA_SOUTH: 'Tarawa South',
	STATE_TERAINA: 'Teraina',
	STATE_CHAGANGDO: 'Chagangdo',
	STATE_HAMGYEONGBUKTO: 'Hamgyeongbukto',
	STATE_HAMGYEONGNAMDO: 'Hamgyeongnamdo',
	STATE_HWANGHAEBUKTO: 'Hwanghaebukto',
	STATE_HWANGHAENAMDO: 'Hwanghaenamdo',
	STATE_KAESEONG: 'Kaeseong',
	STATE_KANGWEON: 'Kangweon',
	STATE_NAMPO: 'Nampo',
	STATE_PYEONGANBUKTO: 'Pyeonganbukto',
	STATE_PYEONGANNAMDO: 'Pyeongannamdo',
	STATE_PYEONGYANG: 'Pyeongyang',
	STATE_YANGGANG: 'Yanggang',
	STATE_BUSAN: 'Busan',
	STATE_CHEJU: 'Cheju',
	STATE_CHOLLABUK: 'Chollabuk',
	STATE_CHOLLANAM: 'Chollanam',
	STATE_CHUNGBUK: 'Chungbuk',
	STATE_CHUNGCHEONGBUK: 'Chungcheongbuk',
	STATE_CHUNGCHEONGNAM: 'Chungcheongnam',
	STATE_CHUNGNAM: 'Chungnam',
	STATE_DAEGU: 'Daegu',
	STATE_GANGWON_DO: 'Gangwon-do',
	STATE_GOYANG_SI: 'Goyang-si',
	STATE_GYEONGGI_DO: 'Gyeonggi-do',
	STATE_GYEONGSANG: 'Gyeongsang',
	STATE_GYEONGSANGNAM_DO: 'Gyeongsangnam-do',
	STATE_INCHEON: 'Incheon',
	STATE_JEJU_SI: 'Jeju-Si',
	STATE_JEONBUK: 'Jeonbuk',
	STATE_KWANGJU: 'Kwangju',
	STATE_KYEONGGI: 'Kyeonggi',
	STATE_KYEONGSANGBUK: 'Kyeongsangbuk',
	STATE_KYEONGSANGNAM: 'Kyeongsangnam',
	STATE_KYONGGI_DO: 'Kyonggi-do',
	STATE_KYUNGBUK_DO: 'Kyungbuk-Do',
	STATE_KYUNGGI_DO: 'Kyunggi-Do',
	STATE_PUSAN: 'Pusan',
	STATE_SEOUL: 'Seoul',
	STATE_SUDOGWON: 'Sudogwon',
	STATE_TAEGU: 'Taegu',
	STATE_TAEJEON: 'Taejeon',
	STATE_TAEJON_GWANGYOKSI: 'Taejon-gwangyoksi',
	STATE_ULSAN: 'Ulsan',
	STATE_WONJU: 'Wonju',
	STATE_GWANGYOKSI: 'gwangyoksi',
	STATE_AL_ASIMAH: 'Al Asimah',
	STATE_HAWALLI: 'Hawalli',
	STATE_MISHREF: 'Mishref',
	STATE_QADESIYA: 'Qadesiya',
	STATE_SAFAT: 'Safat',
	STATE_SALMIYA: 'Salmiya',
	STATE_AL_AHMADI: 'al-Ahmadi',
	STATE_AL_FARWANIYAH: 'al-Farwaniyah',
	STATE_AL_JAHRA: 'al-Jahra',
	STATE_AL_KUWAYT: 'al-Kuwayt',
	STATE_BATKEN: 'Batken',
	STATE_BISHKEK: 'Bishkek',
	STATE_CHUI: 'Chui',
	STATE_ISSYK_KUL: 'Issyk-Kul',
	STATE_JALAL_ABAD: 'Jalal-Abad',
	STATE_NARYN: 'Naryn',
	STATE_OSH: 'Osh',
	STATE_TALAS: 'Talas',
	STATE_ATTOPU: 'Attopu',
	STATE_BOKEO: 'Bokeo',
	STATE_BOLIKHAMSAY: 'Bolikhamsay',
	STATE_CHAMPASAK: 'Champasak',
	STATE_HOUAPHANH: 'Houaphanh',
	STATE_KHAMMOUANE: 'Khammouane',
	STATE_LUANG_NAM_THA: 'Luang Nam Tha',
	STATE_LUANG_PRABANG: 'Luang Prabang',
	STATE_OUDOMXAY: 'Oudomxay',
	STATE_PHONGSALY: 'Phongsaly',
	STATE_SARAVAN: 'Saravan',
	STATE_SAVANNAKHET: 'Savannakhet',
	STATE_SEKONG: 'Sekong',
	STATE_VIANGCHAN_PREFECTURE: 'Viangchan Prefecture',
	STATE_VIANGCHAN_PROVINCE: 'Viangchan Province',
	STATE_XAIGNABURY: 'Xaignabury',
	STATE_XIANG_KHUANG: 'Xiang Khuang',
	STATE_AIZKRAUKLES: 'Aizkraukles',
	STATE_ALUKSNES: 'Aluksnes',
	STATE_BALVU: 'Balvu',
	STATE_BAUSKAS: 'Bauskas',
	STATE_CESU: 'Cesu',
	STATE_DAUGAVPILS: 'Daugavpils',
	STATE_DAUGAVPILS_CITY: 'Daugavpils City',
	STATE_DOBELES: 'Dobeles',
	STATE_GULBENES: 'Gulbenes',
	STATE_JEKABSPILS: 'Jekabspils',
	STATE_JELGAVA: 'Jelgava',
	STATE_JELGAVAS: 'Jelgavas',
	STATE_JURMALA_CITY: 'Jurmala City',
	STATE_KRASLAVAS: 'Kraslavas',
	STATE_KULDIGAS: 'Kuldigas',
	STATE_LIEPAJA: 'Liepaja',
	STATE_LIEPAJAS: 'Liepajas',
	STATE_LIMBAZHU: 'Limbazhu',
	STATE_LUDZAS: 'Ludzas',
	STATE_MADONAS: 'Madonas',
	STATE_OGRES: 'Ogres',
	STATE_PREILU: 'Preilu',
	STATE_REZEKNE: 'Rezekne',
	STATE_REZEKNES: 'Rezeknes',
	STATE_RIGA: 'Riga',
	STATE_RIGAS: 'Rigas',
	STATE_SALDUS: 'Saldus',
	STATE_TALSU: 'Talsu',
	STATE_TUKUMA: 'Tukuma',
	STATE_VALKAS: 'Valkas',
	STATE_VALMIERAS: 'Valmieras',
	STATE_VENTSPILS: 'Ventspils',
	STATE_VENTSPILS_CITY: 'Ventspils City',
	STATE_BEIRUT: 'Beirut',
	STATE_JABAL_LUBNAN: 'Jabal Lubnan',
	STATE_MOHAFAZAT_LIBAN_NORD: 'Mohafazat Liban-Nord',
	STATE_MOHAFAZAT_MONT_LIBAN: 'Mohafazat Mont-Liban',
	STATE_SIDON: 'Sidon',
	STATE_AL_BIQA: 'al-Biqa',
	STATE_AL_JANUB: 'al-Janub',
	STATE_AN_NABATIYAH: 'an-Nabatiyah',
	STATE_ASH_SHAMAL: 'ash-Shamal',
	STATE_BEREA: 'Berea',
	STATE_BUTHA_BUTHE: 'Butha-Buthe',
	STATE_LERIBE: 'Leribe',
	STATE_MAFETENG: 'Mafeteng',
	STATE_MASERU: 'Maseru',
	STATE_MOHALE__S_HOEK: "Mohale's Hoek",
	STATE_MOKHOTLONG: 'Mokhotlong',
	STATE_QACHA__S_NEK: "Qacha's Nek",
	STATE_QUTHING: 'Quthing',
	STATE_THABA_TSEKA: 'Thaba-Tseka',
	STATE_BOMI: 'Bomi',
	STATE_BONG: 'Bong',
	STATE_GRAND_BASSA: 'Grand Bassa',
	STATE_GRAND_CAPE_MOUNT: 'Grand Cape Mount',
	STATE_GRAND_GEDEH: 'Grand Gedeh',
	STATE_LOFFA: 'Loffa',
	STATE_MARGIBI: 'Margibi',
	STATE_MARYLAND_AND_GRAND_KRU: 'Maryland and Grand Kru',
	STATE_MONTSERRADO: 'Montserrado',
	STATE_NIMBA: 'Nimba',
	STATE_RIVERCESS: 'Rivercess',
	STATE_SINOE: 'Sinoe',
	STATE_AJDABIYA: 'Ajdabiya',
	STATE_FEZZAN: 'Fezzan',
	STATE_BANGHAZI: 'Banghazi',
	STATE_DARNAH: 'Darnah',
	STATE_GHADAMIS: 'Ghadamis',
	STATE_GHARYAN: 'Gharyan',
	STATE_MISRATAH: 'Misratah',
	STATE_MURZUQ: 'Murzuq',
	STATE_SABHA: 'Sabha',
	STATE_SAWFAJJIN: 'Sawfajjin',
	STATE_SURT: 'Surt',
	STATE_TARABULUS: 'Tarabulus',
	STATE_TARHUNAH: 'Tarhunah',
	STATE_TRIPOLITANIA: 'Tripolitania',
	STATE_TUBRUQ: 'Tubruq',
	STATE_YAFRAN: 'Yafran',
	STATE_ZLITAN: 'Zlitan',
	STATE_AL___AZIZIYAH: "al-'Aziziyah",
	STATE_AL_FATIH: 'al-Fatih',
	STATE_AL_JABAL_AL_AKHDAR: 'al-Jabal al Akhdar',
	STATE_AL_JUFRAH: 'al-Jufrah',
	STATE_AL_KHUMS: 'al-Khums',
	STATE_AL_KUFRAH: 'al-Kufrah',
	STATE_AN_NUQAT_AL_KHAMS: 'an-Nuqat al-Khams',
	STATE_ASH_SHATI: 'ash-Shati',
	STATE_AZ_ZAWIYAH: 'az-Zawiyah',
	STATE_BALZERS: 'Balzers',
	STATE_ESCHEN: 'Eschen',
	STATE_GAMPRIN: 'Gamprin',
	STATE_MAUREN: 'Mauren',
	STATE_PLANKEN: 'Planken',
	STATE_RUGGELL: 'Ruggell',
	STATE_SCHAAN: 'Schaan',
	STATE_SCHELLENBERG: 'Schellenberg',
	STATE_TRIESEN: 'Triesen',
	STATE_TRIESENBERG: 'Triesenberg',
	STATE_VADUZ: 'Vaduz',
	STATE_ALYTAUS: 'Alytaus',
	STATE_ANYKSCIAI: 'Anyksciai',
	STATE_KAUNO: 'Kauno',
	STATE_KLAIPEDOS: 'Klaipedos',
	STATE_MARIJAMPOLES: 'Marijampoles',
	STATE_PANEVEZHIO: 'Panevezhio',
	STATE_PANEVEZYS: 'Panevezys',
	STATE_SHIAULIU: 'Shiauliu',
	STATE_TAURAGES: 'Taurages',
	STATE_TELSHIU: 'Telshiu',
	STATE_TELSIAI: 'Telsiai',
	STATE_UTENOS: 'Utenos',
	STATE_VILNIAUS: 'Vilniaus',
	STATE_CAPELLEN: 'Capellen',
	STATE_CLERVAUX: 'Clervaux',
	STATE_DIEKIRCH: 'Diekirch',
	STATE_ECHTERNACH: 'Echternach',
	STATE_ESCH_SUR_ALZETTE: 'Esch-sur-Alzette',
	STATE_GREVENMACHER: 'Grevenmacher',
	STATE_MERSCH: 'Mersch',
	STATE_REDANGE: 'Redange',
	STATE_REMICH: 'Remich',
	STATE_VIANDEN: 'Vianden',
	STATE_WILTZ: 'Wiltz',
	STATE_MACAU: 'Macau',
	STATE_BEROVO: 'Berovo',
	STATE_BITOLA: 'Bitola',
	STATE_BROD: 'Brod',
	STATE_DEBAR: 'Debar',
	STATE_DELCHEVO: 'Delchevo',
	STATE_DEMIR_HISAR: 'Demir Hisar',
	STATE_GEVGELIJA: 'Gevgelija',
	STATE_GOSTIVAR: 'Gostivar',
	STATE_KAVADARCI: 'Kavadarci',
	STATE_KICHEVO: 'Kichevo',
	STATE_KOCHANI: 'Kochani',
	STATE_KRATOVO: 'Kratovo',
	STATE_KRIVA_PALANKA: 'Kriva Palanka',
	STATE_KRUSHEVO: 'Krushevo',
	STATE_KUMANOVO: 'Kumanovo',
	STATE_NEGOTINO: 'Negotino',
	STATE_OHRID: 'Ohrid',
	STATE_PRILEP: 'Prilep',
	STATE_PROBISHTIP: 'Probishtip',
	STATE_RADOVISH: 'Radovish',
	STATE_RESEN: 'Resen',
	STATE_SHTIP: 'Shtip',
	STATE_SKOPJE: 'Skopje',
	STATE_STRUGA: 'Struga',
	STATE_STRUMICA: 'Strumica',
	STATE_SVETI_NIKOLE: 'Sveti Nikole',
	STATE_TETOVO: 'Tetovo',
	STATE_VALANDOVO: 'Valandovo',
	STATE_VELES: 'Veles',
	STATE_VINICA: 'Vinica',
	STATE_ANTANANARIVO: 'Antananarivo',
	STATE_ANTSIRANANA: 'Antsiranana',
	STATE_FIANARANTSOA: 'Fianarantsoa',
	STATE_MAHAJANGA: 'Mahajanga',
	STATE_TOAMASINA: 'Toamasina',
	STATE_TOLIARY: 'Toliary',
	STATE_BALAKA: 'Balaka',
	STATE_BLANTYRE_CITY: 'Blantyre City',
	STATE_CHIKWAWA: 'Chikwawa',
	STATE_CHIRADZULU: 'Chiradzulu',
	STATE_CHITIPA: 'Chitipa',
	STATE_DEDZA: 'Dedza',
	STATE_DOWA: 'Dowa',
	STATE_KARONGA: 'Karonga',
	STATE_KASUNGU: 'Kasungu',
	STATE_LILONGWE_CITY: 'Lilongwe City',
	STATE_MACHINGA: 'Machinga',
	STATE_MANGOCHI: 'Mangochi',
	STATE_MCHINJI: 'Mchinji',
	STATE_MULANJE: 'Mulanje',
	STATE_MWANZA: 'Mwanza',
	STATE_MZIMBA: 'Mzimba',
	STATE_MZUZU_CITY: 'Mzuzu City',
	STATE_NKHATA_BAY: 'Nkhata Bay',
	STATE_NKHOTAKOTA: 'Nkhotakota',
	STATE_NSANJE: 'Nsanje',
	STATE_NTCHEU: 'Ntcheu',
	STATE_NTCHISI: 'Ntchisi',
	STATE_PHALOMBE: 'Phalombe',
	STATE_THYOLO: 'Thyolo',
	STATE_ZOMBA_MUNICIPALITY: 'Zomba Municipality',
	STATE_JOHOR: 'Johor',
	STATE_KEDAH: 'Kedah',
	STATE_KELANTAN: 'Kelantan',
	STATE_KUALA_LUMPUR: 'Kuala Lumpur',
	STATE_LABUAN: 'Labuan',
	STATE_MELAKA: 'Melaka',
	STATE_NEGERI_JOHOR: 'Negeri Johor',
	STATE_NEGERI_SEMBILAN: 'Negeri Sembilan',
	STATE_PAHANG: 'Pahang',
	STATE_PENANG: 'Penang',
	STATE_PERAK: 'Perak',
	STATE_PERLIS: 'Perlis',
	STATE_PULAU_PINANG: 'Pulau Pinang',
	STATE_SABAH: 'Sabah',
	STATE_SARAWAK: 'Sarawak',
	STATE_SELANGOR: 'Selangor',
	STATE_SEMBILAN: 'Sembilan',
	STATE_TERENGGANU: 'Terengganu',
	STATE_ALIF_ALIF: 'Alif Alif',
	STATE_ALIF_DHAAL: 'Alif Dhaal',
	STATE_BAA: 'Baa',
	STATE_DHAAL: 'Dhaal',
	STATE_FAAF: 'Faaf',
	STATE_GAAF_ALIF: 'Gaaf Alif',
	STATE_GAAF_DHAAL: 'Gaaf Dhaal',
	STATE_GHAVIYANI: 'Ghaviyani',
	STATE_HAA_ALIF: 'Haa Alif',
	STATE_HAA_DHAAL: 'Haa Dhaal',
	STATE_KAAF: 'Kaaf',
	STATE_LAAM: 'Laam',
	STATE_LHAVIYANI: 'Lhaviyani',
	STATE_MALE: 'Male',
	STATE_MIIM: 'Miim',
	STATE_NUUN: 'Nuun',
	STATE_RAA: 'Raa',
	STATE_SHAVIYANI: 'Shaviyani',
	STATE_SIIN: 'Siin',
	STATE_THAA: 'Thaa',
	STATE_VAAV: 'Vaav',
	STATE_BAMAKO: 'Bamako',
	STATE_GAO: 'Gao',
	STATE_KAYES: 'Kayes',
	STATE_KIDAL: 'Kidal',
	STATE_KOULIKORO: 'Koulikoro',
	STATE_MOPTI: 'Mopti',
	STATE_SEGOU: 'Segou',
	STATE_SIKASSO: 'Sikasso',
	STATE_TOMBOUCTOU: 'Tombouctou',
	STATE_GOZO_AND_COMINO: 'Gozo and Comino',
	STATE_INNER_HARBOUR: 'Inner Harbour',
	STATE_OUTER_HARBOUR: 'Outer Harbour',
	STATE_SOUTH_EASTERN: 'South Eastern',
	STATE_CASTLETOWN: 'Castletown',
	STATE_DOUGLAS: 'Douglas',
	STATE_LAXEY: 'Laxey',
	STATE_ONCHAN: 'Onchan',
	STATE_PEEL: 'Peel',
	STATE_PORT_ERIN: 'Port Erin',
	STATE_PORT_SAINT_MARY: 'Port Saint Mary',
	STATE_RAMSEY: 'Ramsey',
	STATE_AILINLAPLAP: 'Ailinlaplap',
	STATE_AILUK: 'Ailuk',
	STATE_ARNO: 'Arno',
	STATE_AUR: 'Aur',
	STATE_BIKINI: 'Bikini',
	STATE_EBON: 'Ebon',
	STATE_ENEWETAK: 'Enewetak',
	STATE_JABAT: 'Jabat',
	STATE_JALUIT: 'Jaluit',
	STATE_KILI: 'Kili',
	STATE_KWAJALEIN: 'Kwajalein',
	STATE_LAE: 'Lae',
	STATE_LIB: 'Lib',
	STATE_LIKIEP: 'Likiep',
	STATE_MAJURO: 'Majuro',
	STATE_MALOELAP: 'Maloelap',
	STATE_MEJIT: 'Mejit',
	STATE_MILI: 'Mili',
	STATE_NAMORIK: 'Namorik',
	STATE_NAMU: 'Namu',
	STATE_RONGELAP: 'Rongelap',
	STATE_UJAE: 'Ujae',
	STATE_UTRIK: 'Utrik',
	STATE_WOTHO: 'Wotho',
	STATE_WOTJE: 'Wotje',
	STATE_FORT_DE_FRANCE: 'Fort-de-France',
	STATE_LA_TRINITE: 'La Trinite',
	STATE_LE_MARIN: 'Le Marin',
	STATE_SAINT_PIERRE: 'Saint-Pierre',
	STATE_ASSABA: 'Assaba',
	STATE_BRAKNA: 'Brakna',
	STATE_DHAKHLAT_NAWADIBU: 'Dhakhlat Nawadibu',
	STATE_HUDH_AL_GHARBI: 'Hudh-al-Gharbi',
	STATE_HUDH_ASH_SHARQI: 'Hudh-ash-Sharqi',
	STATE_INSHIRI: 'Inshiri',
	STATE_NAWAKSHUT: 'Nawakshut',
	STATE_QIDIMAGHA: 'Qidimagha',
	STATE_QURQUL: 'Qurqul',
	STATE_TAQANT: 'Taqant',
	STATE_TIRIS_ZAMMUR: 'Tiris Zammur',
	STATE_TRARZA: 'Trarza',
	STATE_BLACK_RIVER: 'Black River',
	STATE_EAU_COULEE: 'Eau Coulee',
	STATE_FLACQ: 'Flacq',
	STATE_FLOREAL: 'Floreal',
	STATE_GRAND_PORT: 'Grand Port',
	STATE_MOKA: 'Moka',
	STATE_PAMPLEMPOUSSES: 'Pamplempousses',
	STATE_PLAINES_WILHELM: 'Plaines Wilhelm',
	STATE_PORT_LOUIS: 'Port Louis',
	STATE_RIVIERE_DU_REMPART: 'Riviere du Rempart',
	STATE_RODRIGUES: 'Rodrigues',
	STATE_ROSE_HILL: 'Rose Hill',
	STATE_SAVANNE: 'Savanne',
	STATE_MAYOTTE: 'Mayotte',
	STATE_PAMANZI: 'Pamanzi',
	STATE_AGUASCALIENTES: 'Aguascalientes',
	STATE_BAJA_CALIFORNIA: 'Baja California',
	STATE_BAJA_CALIFORNIA_SUR: 'Baja California Sur',
	STATE_CAMPECHE: 'Campeche',
	STATE_CHIAPAS: 'Chiapas',
	STATE_CHIHUAHUA: 'Chihuahua',
	STATE_COAHUILA: 'Coahuila',
	STATE_COLIMA: 'Colima',
	STATE_DURANGO: 'Durango',
	STATE_ESTADO_DE_MEXICO: 'Estado de México',
	STATE_GUANAJUATO: 'Guanajuato',
	STATE_GUERRERO: 'Guerrero',
	STATE_HIDALGO: 'Hidalgo',
	STATE_JALISCO: 'Jalisco',
	STATE_MEXICO: 'México',
	STATE_MICHOACAN: 'Michoacán',
	STATE_MORELOS: 'Morelos',
	STATE_NAYARIT: 'Nayarit',
	STATE_NUEVO_LEON: 'Nuevo León',
	STATE_OAXACA: 'Oaxaca',
	STATE_PUEBLA: 'Puebla',
	STATE_QUERETARO: 'Querétaro',
	STATE_QUINTANA_ROO: 'Quintana Roo',
	STATE_SAN_LUIS_POTOSI: 'San Luis Potosí',
	STATE_SINALOA: 'Sinaloa',
	STATE_SONORA: 'Sonora',
	STATE_TABASCO: 'Tabasco',
	STATE_TAMAULIPAS: 'Tamaulipas',
	STATE_TLAXCALA: 'Tlaxcala',
	STATE_VERACRUZ: 'Veracruz',
	STATE_YUCATAN: 'Yucatán',
	STATE_ZACATECAS: 'Zacatecas',
	STATE_CHUUK: 'Chuuk',
	STATE_KUSAIE: 'Kusaie',
	STATE_POHNPEI: 'Pohnpei',
	STATE_YAP: 'Yap',
	STATE_BALTI: 'Balti',
	STATE_CAHUL: 'Cahul',
	STATE_CHISINAU: 'Chisinau',
	STATE_CHISINAU_ORAS: 'Chisinau Oras',
	STATE_EDINET: 'Edinet',
	STATE_GAGAUZIA: 'Gagauzia',
	STATE_LAPUSNA: 'Lapusna',
	STATE_ORHEI: 'Orhei',
	STATE_SOROCA: 'Soroca',
	STATE_TARACLIA: 'Taraclia',
	STATE_TIGHINA: 'Tighina',
	STATE_TRANSNISTRIA: 'Transnistria',
	STATE_UNGHENI: 'Ungheni',
	STATE_FONTVIEILLE: 'Fontvieille',
	STATE_LA_CONDAMINE: 'La Condamine',
	STATE_MONACO_VILLE: 'Monaco-Ville',
	STATE_MONTE_CARLO: 'Monte Carlo',
	STATE_ARHANGAJ: 'Arhangaj',
	STATE_BAJAN_OLGIJ: 'Bajan-Olgij',
	STATE_BAJANHONGOR: 'Bajanhongor',
	STATE_BULGAN: 'Bulgan',
	STATE_DARHAN_UUL: 'Darhan-Uul',
	STATE_DORNOD: 'Dornod',
	STATE_DORNOGOVI: 'Dornogovi',
	STATE_DUNDGOVI: 'Dundgovi',
	STATE_GOVI_ALTAJ: 'Govi-Altaj',
	STATE_GOVISUMBER: 'Govisumber',
	STATE_HENTIJ: 'Hentij',
	STATE_HOVD: 'Hovd',
	STATE_HOVSGOL: 'Hovsgol',
	STATE_OMNOGOVI: 'Omnogovi',
	STATE_ORHON: 'Orhon',
	STATE_OVORHANGAJ: 'Ovorhangaj',
	STATE_SELENGE: 'Selenge',
	STATE_SUHBAATAR: 'Suhbaatar',
	STATE_TOV: 'Tov',
	STATE_ULAANBAATAR: 'Ulaanbaatar',
	STATE_UVS: 'Uvs',
	STATE_ZAVHAN: 'Zavhan',
	STATE_MONTSERRAT: 'Montserrat',
	STATE_AGADIR: 'Agadir',
	STATE_CASABLANCA: 'Casablanca',
	STATE_CHAOUIA_OUARDIGHA: 'Chaouia-Ouardigha',
	STATE_DOUKKALA_ABDA: 'Doukkala-Abda',
	STATE_FES_BOULEMANE: 'Fes-Boulemane',
	STATE_GHARB_CHRARDA_BENI_HSSEN: 'Gharb-Chrarda-Beni Hssen',
	STATE_GUELMIM: 'Guelmim',
	STATE_KENITRA: 'Kenitra',
	STATE_MARRAKECH_TENSIFT_AL_HAOUZ: 'Marrakech-Tensift-Al Haouz',
	STATE_MEKNES_TAFILALET: 'Meknes-Tafilalet',
	STATE_ORIENTAL: 'Oriental',
	STATE_OUJDA: 'Oujda',
	STATE_PROVINCE_DE_TANGER: 'Province de Tanger',
	STATE_RABAT_SALE_ZAMMOUR_ZAER: 'Rabat-Sale-Zammour-Zaer',
	STATE_SALA_AL_JADIDA: 'Sala Al Jadida',
	STATE_SETTAT: 'Settat',
	STATE_SOUSS_MASSA_DRAA: 'Souss Massa-Draa',
	STATE_TADLA_AZILAL: 'Tadla-Azilal',
	STATE_TANGIER_TETOUAN: 'Tangier-Tetouan',
	STATE_TAZA_AL_HOCEIMA_TAOUNATE: 'Taza-Al Hoceima-Taounate',
	STATE_WILAYA_DE_CASABLANCA: 'Wilaya de Casablanca',
	STATE_WILAYA_DE_RABAT_SALE: 'Wilaya de Rabat-Sale',
	STATE_CABO_DELGADO: 'Cabo Delgado',
	STATE_INHAMBANE: 'Inhambane',
	STATE_MANICA: 'Manica',
	STATE_MAPUTO: 'Maputo',
	STATE_MAPUTO_PROVINCIA: 'Maputo Provincia',
	STATE_NAMPULA: 'Nampula',
	STATE_NIASSA: 'Niassa',
	STATE_SOFALA: 'Sofala',
	STATE_TETE: 'Tete',
	STATE_ZAMBEZIA: 'Zambezia',
	STATE_AYEYARWADY: 'Ayeyarwady',
	STATE_BAGO: 'Bago',
	STATE_CHIN: 'Chin',
	STATE_KACHIN: 'Kachin',
	STATE_KAYAH: 'Kayah',
	STATE_KAYIN: 'Kayin',
	STATE_MAGWAY: 'Magway',
	STATE_MANDALAY: 'Mandalay',
	STATE_MON: 'Mon',
	STATE_NAY_PYI_TAW: 'Nay Pyi Taw',
	STATE_RAKHINE: 'Rakhine',
	STATE_SAGAING: 'Sagaing',
	STATE_SHAN: 'Shan',
	STATE_TANINTHARYI: 'Tanintharyi',
	STATE_YANGON: 'Yangon',
	STATE_CAPRIVI: 'Caprivi',
	STATE_ERONGO: 'Erongo',
	STATE_HARDAP: 'Hardap',
	STATE_KARAS: 'Karas',
	STATE_KAVANGO: 'Kavango',
	STATE_KHOMAS: 'Khomas',
	STATE_KUNENE: 'Kunene',
	STATE_OHANGWENA: 'Ohangwena',
	STATE_OMAHEKE: 'Omaheke',
	STATE_OMUSATI: 'Omusati',
	STATE_OSHANA: 'Oshana',
	STATE_OSHIKOTO: 'Oshikoto',
	STATE_OTJOZONDJUPA: 'Otjozondjupa',
	STATE_YAREN: 'Yaren',
	STATE_BAGMATI: 'Bagmati',
	STATE_BHERI: 'Bheri',
	STATE_DHAWALAGIRI: 'Dhawalagiri',
	STATE_GANDAKI: 'Gandaki',
	STATE_JANAKPUR: 'Janakpur',
	STATE_KARNALI: 'Karnali',
	STATE_KOSHI: 'Koshi',
	STATE_LUMBINI: 'Lumbini',
	STATE_MAHAKALI: 'Mahakali',
	STATE_MECHI: 'Mechi',
	STATE_NARAYANI: 'Narayani',
	STATE_RAPTI: 'Rapti',
	STATE_SAGARMATHA: 'Sagarmatha',
	STATE_SETI: 'Seti',
	STATE_BONAIRE: 'Bonaire',
	STATE_CURACAO: 'Curacao',
	STATE_SABA: 'Saba',
	STATE_SINT_EUSTATIUS: 'Sint Eustatius',
	STATE_SINT_MAARTEN: 'Sint Maarten',
	STATE_BENELUX: 'Benelux',
	STATE_DRENTHE: 'Drenthe',
	STATE_FLEVOLAND: 'Flevoland',
	STATE_FRIESLAND: 'Friesland',
	STATE_GELDERLAND: 'Gelderland',
	STATE_GRONINGEN: 'Groningen',
	STATE_NOORD_BRABANT: 'Noord-Brabant',
	STATE_OVERIJSSEL: 'Overijssel',
	STATE_SOUTH_HOLLAND: 'South Holland',
	STATE_UTRECHT: 'Utrecht',
	STATE_ZEELAND: 'Zeeland',
	STATE_ZUID_HOLLAND: 'Zuid-Holland',
	STATE_ILES: 'Iles',
	STATE_AREA_OUTSIDE_REGION: 'Area Outside Region',
	STATE_BAY_OF_PLENTY: 'Bay of Plenty',
	STATE_CANTERBURY: 'Canterbury',
	STATE_CHRISTCHURCH: 'Christchurch',
	STATE_GISBORNE: 'Gisborne',
	STATE_HAWKE__S_BAY: "Hawke's Bay",
	STATE_MANAWATU_WANGANUI: 'Manawatu-Wanganui',
	STATE_MARLBOROUGH: 'Marlborough',
	STATE_NELSON: 'Nelson',
	STATE_NORTHLAND: 'Northland',
	STATE_OTAGO: 'Otago',
	STATE_RODNEY: 'Rodney',
	STATE_SOUTHLAND: 'Southland',
	STATE_TARANAKI: 'Taranaki',
	STATE_TASMAN: 'Tasman',
	STATE_WAIKATO: 'Waikato',
	STATE_WELLINGTON: 'Wellington',
	STATE_WEST_COAST: 'West Coast',
	STATE_ATLANTICO_NORTE: 'Atlántico Norte',
	STATE_ATLANTICO_SUR: 'Atlántico Sur',
	STATE_BOACO: 'Boaco',
	STATE_CARAZO: 'Carazo',
	STATE_CHINANDEGA: 'Chinandega',
	STATE_CHONTALES: 'Chontales',
	STATE_ESTELI: 'Esteli',
	STATE_GRANADA: 'Granada',
	STATE_JINOTEGA: 'Jinotega',
	STATE_LEON: 'León',
	STATE_MADRIZ: 'Madriz',
	STATE_MANAGUA: 'Managua',
	STATE_MASAYA: 'Masaya',
	STATE_MATAGALPA: 'Matagalpa',
	STATE_NUEVA_SEGOVIA: 'Nueva Segovia',
	STATE_RIO_SAN_JUAN: 'Río San Juan',
	STATE_RIVAS: 'Rivas',
	STATE_AGADEZ: 'Agadez',
	STATE_DIFFA: 'Diffa',
	STATE_DOSSO: 'Dosso',
	STATE_MARADI: 'Maradi',
	STATE_NIAMEY: 'Niamey',
	STATE_TAHOUA: 'Tahoua',
	STATE_TILLABERY: 'Tillabery',
	STATE_ZINDER: 'Zinder',
	STATE_ABIA: 'Abia',
	STATE_ABUJA_FEDERAL_CAPITAL_TERRITOR: 'Abuja Federal Capital Territor',
	STATE_ADAMAWA: 'Adamawa',
	STATE_AKWA_IBOM: 'Akwa Ibom',
	STATE_ANAMBRA: 'Anambra',
	STATE_BAUCHI: 'Bauchi',
	STATE_BAYELSA: 'Bayelsa',
	STATE_BENUE: 'Benue',
	STATE_BORNO: 'Borno',
	STATE_CROSS_RIVER: 'Cross River',
	STATE_DELTA: 'Delta',
	STATE_EBONYI: 'Ebonyi',
	STATE_EDO: 'Edo',
	STATE_EKITI: 'Ekiti',
	STATE_ENUGU: 'Enugu',
	STATE_GOMBE: 'Gombe',
	STATE_IMO: 'Imo',
	STATE_JIGAWA: 'Jigawa',
	STATE_KADUNA: 'Kaduna',
	STATE_KANO: 'Kano',
	STATE_KATSINA: 'Katsina',
	STATE_KEBBI: 'Kebbi',
	STATE_KOGI: 'Kogi',
	STATE_KWARA: 'Kwara',
	STATE_LAGOS: 'Lagos',
	STATE_NASSARAWA: 'Nassarawa',
	STATE_NIGER: 'Níger',
	STATE_OGUN: 'Ogun',
	STATE_ONDO: 'Ondo',
	STATE_OSUN: 'Osun',
	STATE_OYO: 'Oyo',
	STATE_RIVERS: 'Rivers',
	STATE_SOKOTO: 'Sokoto',
	STATE_TARABA: 'Taraba',
	STATE_YOBE: 'Yobe',
	STATE_ZAMFARA: 'Zamfara',
	STATE_NIUE: 'Niue',
	STATE_NORFOLK_ISLAND: 'Isla de Norfolk',
	STATE_NORTHERN_ISLANDS: 'Northern Islands',
	STATE_ROTA: 'Rota',
	STATE_SAIPAN: 'Saipan',
	STATE_TINIAN: 'Tinian',
	STATE_AKERSHUS: 'Akershus',
	STATE_AUST_AGDER: 'Aust Agder',
	STATE_BERGEN: 'Bergen',
	STATE_BUSKERUD: 'Buskerud',
	STATE_FINNMARK: 'Finnmark',
	STATE_HEDMARK: 'Hedmark',
	STATE_HORDALAND: 'Hordaland',
	STATE_MOERE_OG_ROMSDAL: 'Moere og Romsdal',
	STATE_NORD_TRONDELAG: 'Nord Trondelag',
	STATE_NORDLAND: 'Nordland',
	STATE_OESTFOLD: 'Oestfold',
	STATE_OPPLAND: 'Oppland',
	STATE_OSLO: 'Oslo',
	STATE_ROGALAND: 'Rogaland',
	STATE_SOER_TROENDELAG: 'Soer Troendelag',
	STATE_SOGN_OG_FJORDANE: 'Sogn og Fjordane',
	STATE_STAVERN: 'Stavern',
	STATE_SYKKYLVEN: 'Sykkylven',
	STATE_TELEMARK: 'Telemark',
	STATE_TROMS: 'Troms',
	STATE_VEST_AGDER: 'Vest Agder',
	STATE_VESTFOLD: 'Vestfold',
	'STATE_ÃƑÆ’Ã‚ËŒSTFOLD': 'ÃƒÆ’Ã‚Ëœstfold',
	STATE_AL_BURAIMI: 'Al Buraimi',
	STATE_DHUFAR: 'Dhufar',
	STATE_MASQAT: 'Masqat',
	STATE_MUSANDAM: 'Musandam',
	STATE_RUSAYL: 'Rusayl',
	STATE_WADI_KABIR: 'Wadi Kabir',
	STATE_AD_DAKHILIYAH: 'ad-Dakhiliyah',
	STATE_ADH_DHAHIRAH: 'adh-Dhahirah',
	STATE_AL_BATINAH: 'al-Batinah',
	STATE_BALUCHISTAN: 'Baluchistan',
	STATE_FEDERAL_CAPITAL_AREA: 'Federal Capital Area',
	STATE_FEDERALLY_ADMINISTERED_TRIBAL: 'Federally administered Tribal',
	STATE_NORTH_WEST_FRONTIER: 'North-West Frontier',
	STATE_NORTHERN_AREAS: 'Northern Areas',
	STATE_SIND: 'Sind',
	STATE_AIMELIIK: 'Aimeliik',
	STATE_AIRAI: 'Airai',
	STATE_ANGAUR: 'Angaur',
	STATE_HATOBOHEI: 'Hatobohei',
	STATE_KAYANGEL: 'Kayangel',
	STATE_KOROR: 'Koror',
	STATE_MELEKEOK: 'Melekeok',
	STATE_NGARAARD: 'Ngaraard',
	STATE_NGARDMAU: 'Ngardmau',
	STATE_NGAREMLENGUI: 'Ngaremlengui',
	STATE_NGATPANG: 'Ngatpang',
	STATE_NGCHESAR: 'Ngchesar',
	STATE_NGERCHELONG: 'Ngerchelong',
	STATE_NGIWAL: 'Ngiwal',
	STATE_PELELIU: 'Peleliu',
	STATE_SONSOROL: 'Sonsorol',
	STATE_ARIHA: 'Ariha',
	STATE_BAYT_LAHM: 'Bayt Lahm',
	STATE_DAYR_AL_BALAH: 'Dayr-al-Balah',
	STATE_GHAZZAH: 'Ghazzah',
	STATE_GHAZZAH_ASH_SHAMALIYAH: 'Ghazzah ash-Shamaliyah',
	STATE_JANIN: 'Janin',
	STATE_KHAN_YUNIS: 'Khan Yunis',
	STATE_NABULUS: 'Nabulus',
	STATE_QALQILYAH: 'Qalqilyah',
	STATE_RAFAH: 'Rafah',
	STATE_RAM_ALLAH_WAL_BIRAH: 'Ram Allah wal-Birah',
	STATE_SALFIT: 'Salfit',
	STATE_TUBAS: 'Tubas',
	STATE_TULKARM: 'Tulkarm',
	STATE_AL_KHALIL: 'al-Khalil',
	STATE_AL_QUDS: 'al-Quds',
	STATE_BOCAS_DEL_TORO: 'Bocas del Toro',
	STATE_CHIRIQUI: 'Chiriqui',
	STATE_COCLE: 'Cocle',
	STATE_DARIEN: 'Darien',
	STATE_EMBERA: 'Embera',
	STATE_HERRERA: 'Herrera',
	STATE_KUNA_YALA: 'Kuna Yala',
	STATE_LOS_SANTOS: 'Los Santos',
	STATE_NGOBE_BUGLE: 'Ngobe Bugle',
	STATE_PANAMA: 'Panamá',
	STATE_VERAGUAS: 'Veraguas',
	STATE_EAST_NEW_BRITAIN: 'East New Britain',
	STATE_EAST_SEPIK: 'East Sepik',
	STATE_EASTERN_HIGHLANDS: 'Eastern Highlands',
	STATE_ENGA: 'Enga',
	STATE_FLY_RIVER: 'Fly River',
	STATE_GULF: 'Gulf',
	STATE_MADANG: 'Madang',
	STATE_MANUS: 'Manus',
	STATE_MILNE_BAY: 'Milne Bay',
	STATE_MOROBE: 'Morobe',
	STATE_NATIONAL_CAPITAL_DISTRICT: 'National Capital District',
	STATE_NEW_IRELAND: 'New Ireland',
	STATE_NORTH_SOLOMONS: 'North Solomons',
	STATE_ORO: 'Oro',
	STATE_SANDAUN: 'Sandaun',
	STATE_SIMBU: 'Simbu',
	STATE_SOUTHERN_HIGHLANDS: 'Southern Highlands',
	STATE_WEST_NEW_BRITAIN: 'West New Britain',
	STATE_WESTERN_HIGHLANDS: 'Western Highlands',
	STATE_ALTO_PARAGUAY: 'Alto Paraguay',
	STATE_ALTO_PARANA: 'Alto Paraná',
	STATE_AMAMBAY: 'Amambay',
	STATE_ASUNCION: 'Asunción',
	STATE_BOQUERON: 'Boquerón',
	STATE_CAAGUAZU: 'Caaguazu',
	STATE_CAAZAPA: 'Caazapa',
	STATE_CANENDIYU: 'Canendiyu',
	STATE_CONCEPCION: 'Concepción',
	STATE_CORDILLERA: 'Cordillera',
	STATE_GUAIRA: 'Guaira',
	STATE_ITAPUA: 'Itapua',
	STATE_NEEMBUCU: 'Neembucu',
	STATE_PARAGUARI: 'Paraguari',
	STATE_PRESIDENTE_HAYES: 'Presidente Hayes',
	STATE_SAN_PEDRO: 'San Pedro',
	STATE_ANCASH: 'Ancash',
	STATE_APURIMAC: 'Apurimac',
	STATE_AREQUIPA: 'Arequipa',
	STATE_AYACUCHO: 'Ayacucho',
	STATE_CAJAMARCA: 'Cajamarca',
	STATE_CUSCO: 'Cusco',
	STATE_HUANCAVELICA: 'Huancavelica',
	STATE_HUANUCO: 'Huanuco',
	STATE_ICA: 'Ica',
	STATE_JUNIN: 'Junín',
	STATE_LAMBAYEQUE: 'Lambayeque',
	STATE_LIMA_Y_CALLAO: 'Lima y Callao',
	STATE_LORETO: 'Loreto',
	STATE_MADRE_DE_DIOS: 'Madre de Dios',
	STATE_MOQUEGUA: 'Moquegua',
	STATE_PASCO: 'Pasco',
	STATE_PIURA: 'Piura',
	STATE_PUNO: 'Puno',
	STATE_SAN_MARTIN: 'San Martín',
	STATE_TACNA: 'Tacna',
	STATE_TUMBES: 'Tumbes',
	STATE_UCAYALI: 'Ucayali',
	STATE_BATANGAS: 'Batangas',
	STATE_BICOL: 'Bicol',
	STATE_BULACAN: 'Bulacan',
	STATE_CAGAYAN: 'Cagayan',
	STATE_CARAGA: 'Caraga',
	STATE_CENTRAL_LUZON: 'Central Luzón',
	STATE_CENTRAL_MINDANAO: 'Central Mindanao',
	STATE_CENTRAL_VISAYAS: 'Central Visayas',
	STATE_DAVAO: 'Davao',
	STATE_EASTERN_VISAYAS: 'Eastern Visayas',
	STATE_GREATER_METROPOLITAN_AREA: 'Greater Metropolitan Area',
	STATE_ILOCOS: 'Ilocos',
	STATE_LAGUNA: 'Laguna',
	STATE_LUZON: 'Luzón',
	STATE_MACTAN: 'Mactan',
	STATE_METROPOLITAN_MANILA_AREA: 'Metropolitan Manila Area',
	STATE_MUSLIM_MINDANAO: 'Muslim Mindanao',
	STATE_NORTHERN_MINDANAO: 'Northern Mindanao',
	STATE_SOUTHERN_MINDANAO: 'Southern Mindanao',
	STATE_SOUTHERN_TAGALOG: 'Southern Tagalog',
	STATE_WESTERN_MINDANAO: 'Western Mindanao',
	STATE_WESTERN_VISAYAS: 'Western Visayas',
	STATE_PITCAIRN_ISLAND: 'Pitcairn Island',
	STATE_BIALE_BLOTA: 'Biale Blota',
	STATE_DOBROSZYCE: 'Dobroszyce',
	STATE_DOLNOSLASKIE: 'Dolnoslaskie',
	STATE_DZIEKANOW_LESNY: 'Dziekanow Lesny',
	STATE_HOPOWO: 'Hopowo',
	STATE_KARTUZY: 'Kartuzy',
	STATE_KOSCIAN: 'Koscian',
	STATE_KRAKOW: 'Krakow',
	STATE_KUJAWSKO_POMORSKIE: 'Kujawsko-Pomorskie',
	STATE_LODZKIE: 'Lodzkie',
	STATE_LUBELSKIE: 'Lubelskie',
	STATE_LUBUSKIE: 'Lubuskie',
	STATE_MALOMICE: 'Malomice',
	STATE_MALOPOLSKIE: 'Malopolskie',
	STATE_MAZOWIECKIE: 'Mazowieckie',
	STATE_MIRKOW: 'Mirkow',
	STATE_OPOLSKIE: 'Opolskie',
	STATE_OSTROWIEC: 'Ostrowiec',
	STATE_PODKARPACKIE: 'Podkarpackie',
	STATE_PODLASKIE: 'Podlaskie',
	STATE_POLSKA: 'Polska',
	STATE_POMORSKIE: 'Pomorskie',
	STATE_POZNAN: 'Poznan',
	STATE_PRUSZKOW: 'Pruszkow',
	STATE_RYMANOWSKA: 'Rymanowska',
	STATE_RZESZOW: 'Rzeszow',
	STATE_SLASKIE: 'Slaskie',
	STATE_STARE_POLE: 'Stare Pole',
	STATE_SWIETOKRZYSKIE: 'Swietokrzyskie',
	STATE_WARMINSKO_MAZURSKIE: 'Warminsko-Mazurskie',
	STATE_WARSAW: 'Warsaw',
	STATE_WEJHEROWO: 'Wejherowo',
	STATE_WIELKOPOLSKIE: 'Wielkopolskie',
	STATE_WROCLAW: 'Wroclaw',
	STATE_ZACHODNIO_POMORSKIE: 'Zachodnio-Pomorskie',
	STATE_ZUKOWO: 'Zukowo',
	STATE_ABRANTES: 'Abrantes',
	STATE_ACORES: 'Azores',
	STATE_ALENTEJO: 'Alentejo',
	STATE_ALGARVE: 'Algarve',
	STATE_BRAGA: 'Braga',
	STATE_CENTRO: 'Centro',
	STATE_DISTRITO_DE_LEIRIA: 'Distrito de Leiria',
	STATE_DISTRITO_DE_VIANA_DO_CASTELO: 'Distrito de Viana do Castelo',
	STATE_DISTRITO_DE_VILA_REAL: 'Distrito de Vila Real',
	STATE_DISTRITO_DO_PORTO: 'Distrito do Porto',
	STATE_LISBOA_E_VALE_DO_TEJO: 'Lisboa e Vale do Tejo',
	STATE_MADEIRA: 'Madeira',
	STATE_NORTE: 'Norte',
	STATE_PAIVAS: 'Paivas',
	STATE_ARECIBO: 'Arecibo',
	STATE_BAYAMON: 'Bayamon',
	STATE_CAROLINA: 'Carolina',
	STATE_FLORIDA: 'Florida',
	STATE_GUAYAMA: 'Guayama',
	STATE_HUMACAO: 'Humacao',
	STATE_MAYAGUEZ_AGUADILLA: 'Mayaguez-Aguadilla',
	STATE_PONCE: 'Ponce',
	STATE_SALINAS: 'Salinas',
	STATE_DOHA: 'Doha',
	STATE_JARIAN_AL_BATNAH: 'Jarian-al-Batnah',
	STATE_UMM_SALAL: 'Umm Salal',
	STATE_AD_DAWHAH: 'ad-Dawhah',
	STATE_AL_GHUWAYRIYAH: 'al-Ghuwayriyah',
	STATE_AL_JUMAYLIYAH: 'al-Jumayliyah',
	STATE_AL_KHAWR: 'al-Khawr',
	STATE_AL_WAKRAH: 'al-Wakrah',
	STATE_AR_RAYYAN: 'ar-Rayyan',
	STATE_SAINT_BENOIT: 'Saint-Benoit',
	STATE_SAINT_DENIS: 'Saint-Denis',
	STATE_ALBA: 'Alba',
	STATE_ARAD: 'Arad',
	STATE_ARGES: 'Arges',
	STATE_BACAU: 'Bacau',
	STATE_BIHOR: 'Bihor',
	STATE_BISTRITA_NASAUD: 'Bistrita-Nasaud',
	STATE_BOTOSANI: 'Botosani',
	STATE_BRAILA: 'Braila',
	STATE_BRASOV: 'Brasov',
	STATE_BUCURESTI: 'Bucuresti',
	STATE_BUZAU: 'Buzau',
	STATE_CALARASI: 'Calarasi',
	STATE_CARAS_SEVERIN: 'Caras-Severin',
	STATE_CLUJ: 'Cluj',
	STATE_CONSTANTA: 'Constanta',
	STATE_COVASNA: 'Covasna',
	STATE_DAMBOVITA: 'Dambovita',
	STATE_DOLJ: 'Dolj',
	STATE_GALATI: 'Galati',
	STATE_GIURGIU: 'Giurgiu',
	STATE_GORJ: 'Gorj',
	STATE_HARGHITA: 'Harghita',
	STATE_HUNEDOARA: 'Hunedoara',
	STATE_IALOMITA: 'Ialomita',
	STATE_IASI: 'Iasi',
	STATE_ILFOV: 'Ilfov',
	STATE_MARAMURES: 'Maramures',
	STATE_MEHEDINTI: 'Mehedinti',
	STATE_MURES: 'Mures',
	STATE_NEAMT: 'Neamt',
	STATE_OLT: 'Olt',
	STATE_PRAHOVA: 'Prahova',
	STATE_SALAJ: 'Salaj',
	STATE_SATU_MARE: 'Satu Mare',
	STATE_SIBIU: 'Sibiu',
	STATE_SONDELOR: 'Sondelor',
	STATE_SUCEAVA: 'Suceava',
	STATE_TELEORMAN: 'Teleorman',
	STATE_TIMIS: 'Timis',
	STATE_TULCEA: 'Tulcea',
	STATE_VALCEA: 'Valcea',
	STATE_VASLUI: 'Vaslui',
	STATE_VRANCEA: 'Vrancea',
	STATE_ADYGEJA: 'Adygeja',
	STATE_AGA: 'Aga',
	STATE_ALANIJA: 'Alanija',
	STATE_ALTAJ: 'Altaj',
	STATE_AMUR: 'Amur',
	STATE_ARHANGELSK: 'Arhangelsk',
	STATE_ASTRAHAN: 'Astrahan',
	STATE_BASHKORTOSTAN: 'Bashkortostan',
	STATE_BELGOROD: 'Belgorod',
	STATE_BRJANSK: 'Brjansk',
	STATE_BURJATIJA: 'Burjatija',
	STATE_CHECHENIJA: 'Chechenija',
	STATE_CHELJABINSK: 'Cheljabinsk',
	STATE_CHITA: 'Chita',
	STATE_CHUKOTKA: 'Chukotka',
	STATE_CHUVASHIJA: 'Chuvashija',
	STATE_DAGESTAN: 'Dagestan',
	STATE_EVENKIJA: 'Evenkija',
	STATE_GORNO_ALTAJ: 'Gorno-Altaj',
	STATE_HABAROVSK: 'Habarovsk',
	STATE_HAKASIJA: 'Hakasija',
	STATE_HANTY_MANSIJA: 'Hanty-Mansija',
	STATE_INGUSETIJA: 'Ingusetija',
	STATE_IRKUTSK: 'Irkutsk',
	STATE_IVANOVO: 'Ivanovo',
	STATE_JAMALO_NENETS: 'Jamalo-Nenets',
	STATE_JAROSLAVL: 'Jaroslavl',
	STATE_JEVREJ: 'Jevrej',
	STATE_KABARDINO_BALKARIJA: 'Kabardino-Balkarija',
	STATE_KALININGRAD: 'Kaliningrad',
	STATE_KALMYKIJA: 'Kalmykija',
	STATE_KALUGA: 'Kaluga',
	STATE_KAMCHATKA: 'Kamchatka',
	STATE_KARACHAJ_CHERKESSIJA: 'Karachaj-Cherkessija',
	STATE_KARELIJA: 'Karelija',
	STATE_KEMEROVO: 'Kemerovo',
	STATE_KHABAROVSKIY_KRAY: 'Khabarovskiy Kray',
	STATE_KIROV: 'Kirov',
	STATE_KOMI: 'Komi',
	STATE_KOMI_PERMJAKIJA: 'Komi-Permjakija',
	STATE_KORJAKIJA: 'Korjakija',
	STATE_KOSTROMA: 'Kostroma',
	STATE_KRASNODAR: 'Krasnodar',
	STATE_KRASNOJARSK: 'Krasnojarsk',
	STATE_KRASNOYARSKIY_KRAY: 'Krasnoyarskiy Kray',
	STATE_KURGAN: 'Kurgan',
	STATE_KURSK: 'Kursk',
	STATE_LENINGRAD: 'Leningrad',
	STATE_LIPECK: 'Lipeck',
	STATE_MAGADAN: 'Magadan',
	STATE_MARIJ_EL: 'Marij El',
	STATE_MORDOVIJA: 'Mordovija',
	STATE_MOSCOW: 'Moscow',
	STATE_MOSKOVSKAJA_OBLAST: 'Moskovskaja Oblast',
	STATE_MOSKOVSKAYA_OBLAST: 'Moskovskaya Oblast',
	STATE_MOSKVA: 'Moskva',
	STATE_MURMANSK: 'Murmansk',
	STATE_NENETS: 'Nenets',
	STATE_NIZHNIJ_NOVGOROD: 'Nizhnij Novgorod',
	STATE_NOVGOROD: 'Novgorod',
	STATE_NOVOKUSNEZK: 'Novokusnezk',
	STATE_NOVOSIBIRSK: 'Novosibirsk',
	STATE_OMSK: 'Omsk',
	STATE_ORENBURG: 'Orenburg',
	STATE_ORJOL: 'Orjol',
	STATE_PENZA: 'Penza',
	STATE_PERM: 'Perm',
	STATE_PRIMORJE: 'Primorje',
	STATE_PSKOV: 'Pskov',
	STATE_PSKOVSKAYA_OBLAST: 'Pskovskaya Oblast',
	STATE_RJAZAN: 'Rjazan',
	STATE_ROSTOV: 'Rostov',
	STATE_SAHA: 'Saha',
	STATE_SAHALIN: 'Sahalin',
	STATE_SAMARA: 'Samara',
	STATE_SAMARSKAYA: 'Samarskaya',
	STATE_SANKT_PETERBURG: 'Sankt-Peterburg',
	STATE_SARATOV: 'Saratov',
	STATE_SMOLENSK: 'Smolensk',
	STATE_STAVROPOL: 'Stavropol',
	STATE_SVERDLOVSK: 'Sverdlovsk',
	STATE_TAJMYRIJA: 'Tajmyrija',
	STATE_TAMBOV: 'Tambov',
	STATE_TATARSTAN: 'Tatarstan',
	STATE_TJUMEN: 'Tjumen',
	STATE_TOMSK: 'Tomsk',
	STATE_TULA: 'Tula',
	STATE_TVER: 'Tver',
	STATE_TYVA: 'Tyva',
	STATE_UDMURTIJA: 'Udmurtija',
	STATE_ULJANOVSK: 'Uljanovsk',
	STATE_ULYANOVSKAYA_OBLAST: 'Ulyanovskaya Oblast',
	STATE_UST_ORDA: 'Ust-Orda',
	STATE_VLADIMIR: 'Vladimir',
	STATE_VOLGOGRAD: 'Volgograd',
	STATE_VOLOGDA: 'Vologda',
	STATE_VORONEZH: 'Voronezh',
	STATE_BUTARE: 'Butare',
	STATE_BYUMBA: 'Byumba',
	STATE_CYANGUGU: 'Cyangugu',
	STATE_GIKONGORO: 'Gikongoro',
	STATE_GISENYI: 'Gisenyi',
	STATE_GITARAMA: 'Gitarama',
	STATE_KIBUNGO: 'Kibungo',
	STATE_KIBUYE: 'Kibuye',
	STATE_KIGALI_NGALI: 'Kigali-ngali',
	STATE_RUHENGERI: 'Ruhengeri',
	STATE_ASCENSION: 'Ascension',
	STATE_GOUGH_ISLAND: 'Gough Island',
	STATE_SAINT_HELENA: 'Santa Helena',
	STATE_TRISTAN_DA_CUNHA: 'Tristan da Cunha',
	STATE_CHRIST_CHURCH_NICHOLA_TOWN: 'Christ Church Nichola Town',
	STATE_SAINT_ANNE_SANDY_POINT: 'Saint Anne Sandy Point',
	STATE_SAINT_GEORGE_BASSETERRE: 'Saint George Basseterre',
	STATE_SAINT_GEORGE_GINGERLAND: 'Saint George Gingerland',
	STATE_SAINT_JAMES_WINDWARD: 'Saint James Windward',
	STATE_SAINT_JOHN_CAPESTERRE: 'Saint John Capesterre',
	STATE_SAINT_JOHN_FIGTREE: 'Saint John Figtree',
	STATE_SAINT_MARY_CAYON: 'Saint Mary Cayon',
	STATE_SAINT_PAUL_CAPESTERRE: 'Saint Paul Capesterre',
	STATE_SAINT_PAUL_CHARLESTOWN: 'Saint Paul Charlestown',
	STATE_SAINT_PETER_BASSETERRE: 'Saint Peter Basseterre',
	STATE_SAINT_THOMAS_LOWLAND: 'Saint Thomas Lowland',
	STATE_SAINT_THOMAS_MIDDLE_ISLAND: 'Saint Thomas Middle Island',
	STATE_TRINITY_PALMETTO_POINT: 'Trinity Palmetto Point',
	STATE_ANSE_LA_RAYE: 'Anse-la-Raye',
	STATE_CANARIES: 'Canaries',
	STATE_CASTRIES: 'Castries',
	STATE_CHOISEUL: 'Choiseul',
	STATE_DENNERY: 'Dennery',
	STATE_GROS_INLET: 'Gros Inlet',
	STATE_LABORIE: 'Laborie',
	STATE_MICOUD: 'Micoud',
	STATE_SOUFRIERE: 'Soufriere',
	STATE_VIEUX_FORT: 'Vieux Fort',
	STATE_MIQUELON_LANGLADE: 'Miquelon-Langlade',
	STATE_CHARLOTTE: 'Charlotte',
	STATE_GRENADINES: 'Grenadines',
	STATE_A__ANA: "A'ana",
	STATE_AIGA_I_LE_TAI: 'Aiga-i-le-Tai',
	STATE_ATUA: 'Atua',
	STATE_FA__ASALELEAGA: "Fa'asaleleaga",
	STATE_GAGA__EMAUGA: "Gaga'emauga",
	STATE_GAGAIFOMAUGA: 'Gagaifomauga',
	STATE_PALAULI: 'Palauli',
	STATE_SATUPA__ITEA: "Satupa'itea",
	STATE_TUAMASAGA: 'Tuamasaga',
	STATE_VA__A_O_FONOTI: "Va'a-o-Fonoti",
	STATE_VAISIGANO: 'Vaisigano',
	STATE_ACQUAVIVA: 'Acquaviva',
	STATE_BORGO_MAGGIORE: 'Borgo Maggiore',
	STATE_CHIESANUOVA: 'Chiesanuova',
	STATE_DOMAGNANO: 'Domagnano',
	STATE_FAETANO: 'Faetano',
	STATE_FIORENTINO: 'Fiorentino',
	STATE_MONTEGIARDINO: 'Montegiardino',
	STATE_SAN_MARINO: 'San Marino',
	STATE_SERRAVALLE: 'Serravalle',
	STATE_AGUA_GRANDE: 'Agua Grande',
	STATE_CANTAGALO: 'Cantagalo',
	STATE_LEMBA: 'Lemba',
	STATE_LOBATA: 'Lobata',
	STATE_ME_ZOCHI: 'Me-Zochi',
	STATE_PAGUE: 'Pague',
	STATE_AL_KHOBAR: 'Al Khobar',
	STATE_ASEER: 'Aseer',
	STATE_ASIR: 'Asir',
	STATE_CENTRAL_PROVINCE: 'Central Province',
	STATE_EASTERN_PROVINCE: 'Eastern Province',
	STATE_HA__IL: "Ha'il",
	STATE_JAWF: 'Jawf',
	STATE_JIZAN: 'Jizan',
	STATE_MAKKAH: 'Makkah',
	STATE_NAJRAN: 'Najran',
	STATE_QASIM: 'Qasim',
	STATE_TABUK: 'Tabuk',
	STATE_WESTERN_PROVINCE: 'Western Province',
	STATE_AL_BAHAH: 'al-Bahah',
	STATE_AL_HUDUD_ASH_SHAMALIYAH: 'al-Hudud-ash-Shamaliyah',
	STATE_AL_MADINAH: 'al-Madinah',
	STATE_AR_RIYAD: 'ar-Riyad',
	STATE_DAKAR: 'Dakar',
	STATE_DIOURBEL: 'Diourbel',
	STATE_FATICK: 'Fatick',
	STATE_KAOLACK: 'Kaolack',
	STATE_KOLDA: 'Kolda',
	STATE_LOUGA: 'Louga',
	STATE_SAINT_LOUIS: 'Saint-Louis',
	STATE_TAMBACOUNDA: 'Tambacounda',
	STATE_THIES: 'Thies',
	STATE_ZIGUINCHOR: 'Ziguinchor',
	STATE_CENTRAL_SERBIA: 'Central Serbia',
	STATE_KOSOVO_AND_METOHIJA: 'Kosovo and Metohija',
	STATE_VOJVODINA: 'Vojvodina',
	STATE_ANSE_BOILEAU: 'Anse Boileau',
	STATE_ANSE_ROYALE: 'Anse Royale',
	STATE_CASCADE: 'Cascade',
	STATE_TAKAMAKA: 'Takamaka',
	STATE_SINGAPORE: 'Singapur',
	STATE_BANSKOBYSTRICKY: 'Banskobystricky',
	STATE_BRATISLAVSKY: 'Bratislavsky',
	STATE_KOSICKY: 'Kosicky',
	STATE_NITRIANSKY: 'Nitriansky',
	STATE_PRESOVSKY: 'Presovsky',
	STATE_TRENCIANSKY: 'Trenciansky',
	STATE_TRNAVSKY: 'Trnavsky',
	STATE_ZILINSKY: 'Zilinsky',
	STATE_BENEDIKT: 'Benedikt',
	STATE_GORENJSKA: 'Gorenjska',
	STATE_GORISHKA: 'Gorishka',
	STATE_JUGOVZHODNA_SLOVENIJA: 'Jugovzhodna Slovenija',
	STATE_KOROSHKA: 'Koroshka',
	STATE_NOTRANJSKO_KRASHKA: 'Notranjsko-krashka',
	STATE_OBALNO_KRASHKA: 'Obalno-krashka',
	STATE_OBCINA_DOMZALE: 'Obcina Domzale',
	STATE_OBCINA_VITANJE: 'Obcina Vitanje',
	STATE_OSREDNJESLOVENSKA: 'Osrednjeslovenska',
	STATE_PODRAVSKA: 'Podravska',
	STATE_POMURSKA: 'Pomurska',
	STATE_SAVINJSKA: 'Savinjska',
	STATE_SLOVENIAN_LITTORAL: 'Slovenian Littoral',
	STATE_SPODNJEPOSAVSKA: 'Spodnjeposavska',
	STATE_ZASAVSKA: 'Zasavska',
	STATE_GUADALCANAL: 'Guadalcanal',
	STATE_ISABEL: 'Isabel',
	STATE_MAKIRA_AND_ULAWA: 'Makira and Ulawa',
	STATE_MALAITA: 'Malaita',
	STATE_RENNELL_AND_BELLONA: 'Rennell and Bellona',
	STATE_TEMOTU: 'Temotu',
	STATE_AWDAL: 'Awdal',
	STATE_BAKOL: 'Bakol',
	STATE_BANADIR: 'Banadir',
	STATE_BAY: 'Bay',
	STATE_GALGUDUG: 'Galgudug',
	STATE_GEDO: 'Gedo',
	STATE_HIRAN: 'Hiran',
	STATE_JUBBADA_HOSE: 'Jubbada Hose',
	STATE_JUBBADHA_DEXE: 'Jubbadha Dexe',
	STATE_MUDUG: 'Mudug',
	STATE_NUGAL: 'Nugal',
	STATE_SANAG: 'Sanag',
	STATE_SHABELLAHA_DHEXE: 'Shabellaha Dhexe',
	STATE_SHABELLAHA_HOSE: 'Shabellaha Hose',
	STATE_TOGDHER: 'Togdher',
	STATE_WOQOYI_GALBED: 'Woqoyi Galbed',
	STATE_EASTERN_CAPE: 'Eastern Cape',
	STATE_FREE_STATE: 'Free State',
	STATE_GAUTENG: 'Gauteng',
	STATE_KEMPTON_PARK: 'Kempton Park',
	STATE_KRAMERVILLE: 'Kramerville',
	STATE_KWAZULU_NATAL: 'KwaZulu Natal',
	STATE_LIMPOPO: 'Limpopo',
	STATE_MPUMALANGA: 'Mpumalanga',
	STATE_NORTH_WEST: 'North West',
	STATE_NORTHERN_CAPE: 'Northern Cape',
	STATE_PAROW: 'Parow',
	STATE_TABLE_VIEW: 'Table View',
	STATE_UMTENTWENI: 'Umtentweni',
	STATE_WESTERN_CAPE: 'Western Cape',
	STATE_CENTRAL_EQUATORIA: 'Central Equatoria',
	STATE_A_CORUNA: 'A Coruna',
	STATE_ALACANT: 'Alacant',
	STATE_ALAVA: 'Álava',
	STATE_ALBACETE: 'Albacete',
	STATE_ALMERIA: 'Almería',
	STATE_ANDALUCIA: 'Andalucía',
	STATE_ASTURIAS: 'Asturias',
	STATE_AVILA: 'Ávila',
	STATE_BADAJOZ: 'Badajoz',
	STATE_BALEARS: 'Baleares',
	STATE_BARCELONA: 'Barcelona',
	STATE_BERTAMIRANS: 'Bertamirans',
	STATE_BISCAY: 'Vizcaya',
	STATE_BURGOS: 'Burgos',
	STATE_CACERES: 'Cáceres',
	STATE_CADIZ: 'Cádiz',
	STATE_CANTABRIA: 'Cantabria',
	STATE_CASTELLO: 'Castelló',
	STATE_CATALUNYA: 'Catalunya',
	STATE_CEUTA: 'Ceuta',
	STATE_CIUDAD_REAL: 'Ciudad Real',
	STATE_COMUNIDAD_AUTONOMA_DE_CANARIAS: 'Comunidad Autónoma de Canarias',
	STATE_COMUNIDAD_AUTONOMA_DE_CATALUNA: 'Comunidad Autónoma de Cataluña',
	STATE_COMUNIDAD_AUTONOMA_DE_GALICIA: 'Comunidad Autónoma de Galicia',
	STATE_COMUNIDAD_AUTONOMA_DE_LAS_ISLA: 'Comunidad Autónoma de las Islas',
	STATE_COMUNIDAD_AUTONOMA_DEL_PRINCIP: 'Comunidad Autónoma del Principado',
	STATE_COMUNIDAD_VALENCIANA: 'Comunidad Valenciana',
	STATE_CUENCA: 'Cuenca',
	STATE_GIPUZKOA: 'Gipuzkoa',
	STATE_GIRONA: 'Girona',
	STATE_GUADALAJARA: 'Guadalajara',
	STATE_GUIPUZCOA: 'Guipúzcoa',
	STATE_HUELVA: 'Huelva',
	STATE_HUESCA: 'Huesca',
	STATE_JAEN: 'Jaén',
	STATE_LAS_PALMAS: 'Las Palmas',
	STATE_LERIDA: 'Lérida',
	STATE_LLEIDA: 'Lleida',
	STATE_LUGO: 'Lugo',
	STATE_MADRID: 'Madrid',
	STATE_MALAGA: 'Málaga',
	STATE_MELILLA: 'Melilla',
	STATE_MURCIA: 'Murcia',
	STATE_NAVARRA: 'Navarra',
	STATE_OURENSE: 'Ourense',
	STATE_PAIS_VASCO: 'País Vasco',
	STATE_PALENCIA: 'Palencia',
	STATE_PONTEVEDRA: 'Pontevedra',
	STATE_SALAMANCA: 'Salamanca',
	STATE_SANTA_CRUZ_DE_TENERIFE: 'Santa Cruz de Tenerife',
	STATE_SEGOVIA: 'Segovia',
	STATE_SEVILLA: 'Sevilla',
	STATE_SORIA: 'Soria',
	STATE_TARRAGONA: 'Tarragona',
	STATE_TENERIFE: 'Tenerife',
	STATE_TERUEL: 'Teruel',
	STATE_VALENCIA: 'Valencia',
	STATE_VALLADOLID: 'Valladolid',
	STATE_VIZCAYA: 'Vizcaya',
	STATE_ZAMORA: 'Zamora',
	STATE_ZARAGOZA: 'Zaragoza',
	STATE_AMPARAI: 'Amparai',
	STATE_ANURADHAPURAYA: 'Anuradhapuraya',
	STATE_BADULLA: 'Badulla',
	STATE_BORALESGAMUWA: 'Boralesgamuwa',
	STATE_COLOMBO: 'Colombo',
	STATE_GALLA: 'Galla',
	STATE_GAMPAHA: 'Gampaha',
	STATE_HAMBANTOTA: 'Hambantota',
	STATE_KALATURA: 'Kalatura',
	STATE_KEGALLA: 'Kegalla',
	STATE_KILINOCHCHI: 'Kilinochchi',
	STATE_KURUNEGALA: 'Kurunegala',
	STATE_MADAKALPUWA: 'Madakalpuwa',
	STATE_MAHA_NUWARA: 'Maha Nuwara',
	STATE_MALWANA: 'Malwana',
	STATE_MANNARAMA: 'Mannarama',
	STATE_MATALE: 'Matale',
	STATE_MATARA: 'Matara',
	STATE_MONARAGALA: 'Monaragala',
	STATE_MULLAITIVU: 'Mullaitivu',
	STATE_NORTH_EASTERN_PROVINCE: 'North Eastern Province',
	STATE_NORTH_WESTERN_PROVINCE: 'North Western Province',
	STATE_NUWARA_ELIYA: 'Nuwara Eliya',
	STATE_POLONNARUWA: 'Polonnaruwa',
	STATE_PUTTALAMA: 'Puttalama',
	STATE_RATNAPURAYA: 'Ratnapuraya',
	STATE_SOUTHERN_PROVINCE: 'Southern Province',
	STATE_TIRIKUNAMALAYA: 'Tirikunamalaya',
	STATE_TUSCANY: 'Tuscany',
	STATE_VAVUNIYAWA: 'Vavuniyawa',
	STATE_YAPANAYA: 'Yapanaya',
	STATE_KADAWATHA: 'kadawatha',
	STATE_A__ALI_AN_NIL: "A'ali-an-Nil",
	STATE_BAHR_AL_JABAL: 'Bahr-al-Jabal',
	STATE_GHARB_BAHR_AL_GHAZAL: 'Gharb Bahr-al-Ghazal',
	STATE_GHARB_DARFUR: 'Gharb Darfur',
	STATE_GHARB_KURDUFAN: 'Gharb Kurdufan',
	STATE_GHARB_AL_ISTIWA__IYAH: "Gharb-al-Istiwa'iyah",
	STATE_JANUB_DARFUR: 'Janub Darfur',
	STATE_JANUB_KURDUFAN: 'Janub Kurdufan',
	STATE_JUNQALI: 'Junqali',
	STATE_KASSALA: 'Kassala',
	STATE_NAHR_AN_NIL: 'Nahr-an-Nil',
	STATE_SHAMAL_BAHR_AL_GHAZAL: 'Shamal Bahr-al-Ghazal',
	STATE_SHAMAL_DARFUR: 'Shamal Darfur',
	STATE_SHAMAL_KURDUFAN: 'Shamal Kurdufan',
	STATE_SHARQ_AL_ISTIWA__IYAH: "Sharq-al-Istiwa'iyah",
	STATE_SINNAR: 'Sinnar',
	STATE_WARAB: 'Warab',
  STATE_WILAYAT_AL_KHARTUM: 'Wilayat al Khartum',
	STATE_AL_BUHAYRAT: 'al-Buhayrat',
	STATE_AL_JAZIRAH: 'al-Jazirah',
	STATE_AL_KHARTUM: 'al-Khartum',
	STATE_AL_QADARIF: 'al-Qadarif',
	STATE_AL_WAHDAH: 'al-Wahdah',
	STATE_AN_NIL_AL_ABYAD: 'an-Nil-al-Abyad',
	STATE_AN_NIL_AL_AZRAQ: 'an-Nil-al-Azraq',
	STATE_ASH_SHAMALIYAH: 'ash-Shamaliyah',
	STATE_BROKOPONDO: 'Brokopondo',
	STATE_COMMEWIJNE: 'Commewijne',
	STATE_CORONIE: 'Coronie',
	STATE_MAROWIJNE: 'Marowijne',
	STATE_NICKERIE: 'Nickerie',
	STATE_PARAMARIBO: 'Paramaribo',
	STATE_SARAMACCA: 'Saramacca',
	STATE_WANICA: 'Wanica',
	STATE_SVALBARD: 'Svalbard',
	STATE_HHOHHO: 'Hhohho',
	STATE_LUBOMBO: 'Lubombo',
	STATE_MANZINI: 'Manzini',
	STATE_SHISELWENI: 'Shiselweni',
	STATE_ALVSBORGS_LAN: 'Alvsborgs Lan',
	STATE_ANGERMANLAND: 'Angermanland',
	STATE_BLEKINGE: 'Blekinge',
	STATE_BOHUSLAN: 'Bohuslan',
	STATE_DALARNA: 'Dalarna',
	STATE_GAVLEBORG: 'Gavleborg',
	STATE_GOTLAND: 'Gotland',
	STATE_HALLAND: 'Halland',
	STATE_JAMTLAND: 'Jamtland',
	STATE_JONKOPING: 'Jonkoping',
	STATE_KALMAR: 'Kalmar',
	STATE_KRISTIANSTADS: 'Kristianstads',
	STATE_KRONOBERG: 'Kronoberg',
	STATE_NORRBOTTEN: 'Norrbotten',
	STATE_OREBRO: 'Orebro',
	STATE_OSTERGOTLAND: 'Ostergotland',
	STATE_SALTSJO_BOO: 'Saltsjo-Boo',
	STATE_SKANE: 'Skane',
	STATE_SMALAND: 'Smaland',
	STATE_SODERMANLAND: 'Sodermanland',
	STATE_STOCKHOLM: 'Stockholm',
	STATE_UPPSALA: 'Uppsala',
	STATE_VARMLAND: 'Varmland',
	STATE_VASTERBOTTEN: 'Vasterbotten',
	STATE_VASTERGOTLAND: 'Vastergotland',
	STATE_VASTERNORRLAND: 'Vasternorrland',
	STATE_VASTMANLAND: 'Vastmanland',
	STATE_VASTRA_GOTALAND: 'Vastra Gotaland',
	STATE_AARGAU: 'Aargau',
	STATE_APPENZELL_INNER_RHODEN: 'Appenzell Inner-Rhoden',
	STATE_APPENZELL_AUSSER_RHODEN: 'Appenzell-Ausser Rhoden',
	STATE_BASEL_LANDSCHAFT: 'Basel-Landschaft',
	STATE_BASEL_STADT: 'Basel-Stadt',
	STATE_BERN: 'Bern',
	STATE_CANTON_TICINO: 'Canton Ticino',
	STATE_FRIBOURG: 'Fribourg',
	STATE_GENEVE: 'Geneve',
	STATE_GLARUS: 'Glarus',
	STATE_GRAUBUNDEN: 'Graubunden',
	STATE_HEERBRUGG: 'Heerbrugg',
	STATE_KANTON_AARGAU: 'Kanton Aargau',
	STATE_LUZERN: 'Luzern',
	STATE_MORBIO_INFERIORE: 'Morbio Inferiore',
	STATE_MUHEN: 'Muhen',
	STATE_NEUCHATEL: 'Neuchatel',
	STATE_NIDWALDEN: 'Nidwalden',
	STATE_OBWALDEN: 'Obwalden',
	STATE_SANKT_GALLEN: 'Sankt Gallen',
	STATE_SCHAFFHAUSEN: 'Schaffhausen',
	STATE_SCHWYZ: 'Schwyz',
	STATE_SOLOTHURN: 'Solothurn',
	STATE_THURGAU: 'Thurgau',
	STATE_TICINO: 'Ticino',
	STATE_URI: 'Uri',
	STATE_VALAIS: 'Valais',
	STATE_VAUD: 'Vaud',
	STATE_VAUFFELIN: 'Vauffelin',
	STATE_ZUG: 'Zug',
	STATE_ZURICH: 'Zurich',
	STATE_ALEPPO: 'Aleppo',
	STATE_DAR__A: "Dar'a",
	STATE_DAYR_AZ_ZAWR: 'Dayr-az-Zawr',
	STATE_DIMASHQ: 'Dimashq',
	STATE_HALAB: 'Halab',
	STATE_HAMAH: 'Hamah',
	STATE_HIMS: 'Hims',
	STATE_IDLIB: 'Idlib',
	STATE_MADINAT_DIMASHQ: 'Madinat Dimashq',
	STATE_TARTUS: 'Tartus',
	STATE_AL_HASAKAH: 'al-Hasakah',
	STATE_AL_LADHIQIYAH: 'al-Ladhiqiyah',
	STATE_AL_QUNAYTIRAH: 'al-Qunaytirah',
	STATE_AR_RAQQAH: 'ar-Raqqah',
	STATE_AS_SUWAYDA: 'as-Suwayda',
	STATE_CHANGHWA: 'Changhwa',
	STATE_CHIAYI_HSIEN: 'Chiayi Hsien',
	STATE_CHIAYI_SHIH: 'Chiayi Shih',
	STATE_EASTERN_TAIPEI: 'Eastern Taipei',
	STATE_HSINCHU_HSIEN: 'Hsinchu Hsien',
	STATE_HSINCHU_SHIH: 'Hsinchu Shih',
	STATE_HUALIEN: 'Hualien',
	STATE_ILAN: 'Ilan',
	STATE_KAOHSIUNG_HSIEN: 'Kaohsiung Hsien',
	STATE_KAOHSIUNG_SHIH: 'Kaohsiung Shih',
	STATE_KEELUNG_SHIH: 'Keelung Shih',
	STATE_KINMEN: 'Kinmen',
	STATE_MIAOLI: 'Miaoli',
	STATE_NANTOU: 'Nantou',
	STATE_NORTHERN_TAIWAN: 'Northern Taiwan',
	STATE_PENGHU: 'Penghu',
	STATE_PINGTUNG: 'Pingtung',
	STATE_TAICHUNG: 'Taichung',
	STATE_TAICHUNG_HSIEN: 'Taichung Hsien',
	STATE_TAICHUNG_SHIH: 'Taichung Shih',
	STATE_TAINAN_HSIEN: 'Tainan Hsien',
	STATE_TAINAN_SHIH: 'Tainan Shih',
	STATE_TAIPEI_HSIEN: 'Taipei Hsien',
	STATE_TAIPEI_SHIH___TAIPEI_HSIEN: 'Taipei Shih / Taipei Hsien',
	STATE_TAITUNG: 'Taitung',
	STATE_TAOYUAN: 'Taoyuan',
	STATE_YILAN: 'Yilan',
	STATE_YUN_LIN_HSIEN: 'Yun-Lin Hsien',
	STATE_YUNLIN: 'Yunlin',
	STATE_DUSHANBE: 'Dushanbe',
	STATE_GORNO_BADAKHSHAN: 'Gorno-Badakhshan',
	STATE_KAROTEGIN: 'Karotegin',
	STATE_KHATLON: 'Khatlon',
	STATE_SUGHD: 'Sughd',
	STATE_ARUSHA: 'Arusha',
	STATE_DAR_ES_SALAAM: 'Dar es Salaam',
	STATE_DODOMA: 'Dodoma',
	STATE_IRINGA: 'Iringa',
	STATE_KAGERA: 'Kagera',
	STATE_KIGOMA: 'Kigoma',
	STATE_KILIMANJARO: 'Kilimanjaro',
	STATE_LINDI: 'Lindi',
	STATE_MARA: 'Mara',
	STATE_MBEYA: 'Mbeya',
	STATE_MOROGORO: 'Morogoro',
	STATE_MTWARA: 'Mtwara',
	STATE_PWANI: 'Pwani',
	STATE_RUKWA: 'Rukwa',
	STATE_RUVUMA: 'Ruvuma',
	STATE_SHINYANGA: 'Shinyanga',
	STATE_SINGIDA: 'Singida',
	STATE_TABORA: 'Tabora',
	STATE_TANGA: 'Tanga',
	STATE_ZANZIBAR_AND_PEMBA: 'Zanzibar and Pemba',
	STATE_AMNAT_CHAROEN: 'Amnat Charoen',
	STATE_ANG_THONG: 'Ang Thong',
	STATE_BANGKOK: 'Bangkok',
	STATE_BURI_RAM: 'Buri Ram',
	STATE_CHACHOENGSAO: 'Chachoengsao',
	STATE_CHAI_NAT: 'Chai Nat',
	STATE_CHAIYAPHUM: 'Chaiyaphum',
	STATE_CHANGWAT_CHAIYAPHUM: 'Changwat Chaiyaphum',
	STATE_CHANTHABURI: 'Chanthaburi',
	STATE_CHIANG_MAI: 'Chiang Mai',
	STATE_CHIANG_RAI: 'Chiang Rai',
	STATE_CHON_BURI: 'Chon Buri',
	STATE_CHUMPHON: 'Chumphon',
	STATE_KALASIN: 'Kalasin',
	STATE_KAMPHAENG_PHET: 'Kamphaeng Phet',
	STATE_KANCHANABURI: 'Kanchanaburi',
	STATE_KHON_KAEN: 'Khon Kaen',
	STATE_KRABI: 'Krabi',
	STATE_KRUNG_THEP: 'Krung Thep',
	STATE_LAMPANG: 'Lampang',
	STATE_LAMPHUN: 'Lamphun',
	STATE_LOEI: 'Loei',
	STATE_LOP_BURI: 'Lop Buri',
	STATE_MAE_HONG_SON: 'Mae Hong Son',
	STATE_MAHA_SARAKHAM: 'Maha Sarakham',
	STATE_MUKDAHAN: 'Mukdahan',
	STATE_NAKHON_NAYOK: 'Nakhon Nayok',
	STATE_NAKHON_PATHOM: 'Nakhon Pathom',
	STATE_NAKHON_PHANOM: 'Nakhon Phanom',
	STATE_NAKHON_RATCHASIMA: 'Nakhon Ratchasima',
	STATE_NAKHON_SAWAN: 'Nakhon Sawan',
	STATE_NAKHON_SI_THAMMARAT: 'Nakhon Si Thammarat',
	STATE_NAN: 'Nan',
	STATE_NARATHIWAT: 'Narathiwat',
	STATE_NONG_BUA_LAM_PHU: 'Nong Bua Lam Phu',
	STATE_NONG_KHAI: 'Nong Khai',
	STATE_NONTHABURI: 'Nonthaburi',
	STATE_PATHUM_THANI: 'Pathum Thani',
	STATE_PATTANI: 'Pattani',
	STATE_PHANGNGA: 'Phangnga',
	STATE_PHATTHALUNG: 'Phatthalung',
	STATE_PHAYAO: 'Phayao',
	STATE_PHETCHABUN: 'Phetchabun',
	STATE_PHETCHABURI: 'Phetchaburi',
	STATE_PHICHIT: 'Phichit',
	STATE_PHITSANULOK: 'Phitsanulok',
	STATE_PHRA_NAKHON_SI_AYUTTHAYA: 'Phra Nakhon Si Ayutthaya',
	STATE_PHRAE: 'Phrae',
	STATE_PHUKET: 'Phuket',
	STATE_PRACHIN_BURI: 'Prachin Buri',
	STATE_PRACHUAP_KHIRI_KHAN: 'Prachuap Khiri Khan',
	STATE_RANONG: 'Ranong',
	STATE_RATCHABURI: 'Ratchaburi',
	STATE_RAYONG: 'Rayong',
	STATE_ROI_ET: 'Roi Et',
	STATE_SA_KAEO: 'Sa Kaeo',
	STATE_SAKON_NAKHON: 'Sakon Nakhon',
	STATE_SAMUT_PRAKAN: 'Samut Prakan',
	STATE_SAMUT_SAKHON: 'Samut Sakhon',
	STATE_SAMUT_SONGKHRAN: 'Samut Songkhran',
	STATE_SARABURI: 'Saraburi',
	STATE_SATUN: 'Satun',
	STATE_SI_SA_KET: 'Si Sa Ket',
	STATE_SING_BURI: 'Sing Buri',
	STATE_SONGKHLA: 'Songkhla',
	STATE_SUKHOTHAI: 'Sukhothai',
	STATE_SUPHAN_BURI: 'Suphan Buri',
	STATE_SURAT_THANI: 'Surat Thani',
	STATE_SURIN: 'Surin',
	STATE_TAK: 'Tak',
	STATE_TRANG: 'Trang',
	STATE_TRAT: 'Trat',
	STATE_UBON_RATCHATHANI: 'Ubon Ratchathani',
	STATE_UDON_THANI: 'Udon Thani',
	STATE_UTHAI_THANI: 'Uthai Thani',
	STATE_UTTARADIT: 'Uttaradit',
	STATE_YALA: 'Yala',
	STATE_YASOTHON: 'Yasothon',
	STATE_KARA: 'Kara',
	STATE_MARITIME: 'Maritime',
	STATE_ATAFU: 'Atafu',
	STATE_FAKAOFO: 'Fakaofo',
	STATE_NUKUNONU: 'Nukunonu',
	STATE_EUA: 'Eua',
	STATE_HA__APAI: "Ha'apai",
	STATE_NIUAS: 'Niuas',
	STATE_TONGATAPU: 'Tongatapu',
	STATE_VAVA__U: "Vava'u",
	STATE_ARIMA_TUNAPUNA_PIARCO: 'Arima-Tunapuna-Piarco',
	STATE_CARONI: 'Caroni',
	STATE_CHAGUANAS: 'Chaguanas',
	STATE_COUVA_TABAQUITE_TALPARO: 'Couva-Tabaquite-Talparo',
	STATE_DIEGO_MARTIN: 'Diego Martin',
	STATE_GLENCOE: 'Glencoe',
	STATE_PENAL_DEBE: 'Penal Debe',
	STATE_POINT_FORTIN: 'Point Fortin',
	STATE_PORT_OF_SPAIN: 'Port of Spain',
	STATE_PRINCES_TOWN: 'Princes Town',
	STATE_SAN_FERNANDO: 'San Fernando',
	STATE_SANGRE_GRANDE: 'Sangre Grande',
	STATE_SIPARIA: 'Siparia',
	STATE_TOBAGO: 'Tobago',
	STATE_ARYANAH: 'Aryanah',
	STATE_BAJAH: 'Bajah',
	STATE_BIN___ARUS: "Bin 'Arus",
	STATE_BINZART: 'Binzart',
	STATE_GOUVERNORAT_DE_ARIANA: 'Gouvernorat de Ariana',
	STATE_GOUVERNORAT_DE_NABEUL: 'Gouvernorat de Nabeul',
	STATE_GOUVERNORAT_DE_SOUSSE: 'Gouvernorat de Sousse',
	STATE_HAMMAMET_YASMINE: 'Hammamet Yasmine',
	STATE_JUNDUBAH: 'Jundubah',
	STATE_MADANIYIN: 'Madaniyin',
	STATE_MANUBAH: 'Manubah',
	STATE_MONASTIR: 'Monastir',
	STATE_NABUL: 'Nabul',
	STATE_QABIS: 'Qabis',
	STATE_QAFSAH: 'Qafsah',
	STATE_QIBILI: 'Qibili',
	STATE_SAFAQIS: 'Safaqis',
	STATE_SFAX: 'Sfax',
	STATE_SIDI_BU_ZAYD: 'Sidi Bu Zayd',
	STATE_SILYANAH: 'Silyanah',
	STATE_SUSAH: 'Susah',
	STATE_TATAWIN: 'Tatawin',
	STATE_TAWZAR: 'Tawzar',
	STATE_TUNIS: 'Tunis',
	STATE_ZAGHWAN: 'Zaghwan',
	STATE_AL_KAF: 'al-Kaf',
	STATE_AL_MAHDIYAH: 'al-Mahdiyah',
	STATE_AL_MUNASTIR: 'al-Munastir',
	STATE_AL_QASRAYN: 'al-Qasrayn',
	STATE_AL_QAYRAWAN: 'al-Qayrawan',
	STATE_ADANA: 'Adana',
	STATE_ADIYAMAN: 'Adiyaman',
	STATE_AFYON: 'Afyon',
	STATE_AGRI: 'Agri',
	STATE_AKSARAY: 'Aksaray',
	STATE_AMASYA: 'Amasya',
	STATE_ANKARA: 'Ankara',
	STATE_ANTALYA: 'Antalya',
	STATE_ARDAHAN: 'Ardahan',
	STATE_ARTVIN: 'Artvin',
	STATE_AYDIN: 'Aydin',
	STATE_BALIKESIR: 'Balikesir',
	STATE_BARTIN: 'Bartin',
	STATE_BATMAN: 'Batman',
	STATE_BAYBURT: 'Bayburt',
	STATE_BILECIK: 'Bilecik',
	STATE_BINGOL: 'Bingol',
	STATE_BITLIS: 'Bitlis',
	STATE_BOLU: 'Bolu',
	STATE_BURDUR: 'Burdur',
	STATE_BURSA: 'Bursa',
	STATE_CANAKKALE: 'Canakkale',
	STATE_CANKIRI: 'Cankiri',
	STATE_CORUM: 'Corum',
	STATE_DENIZLI: 'Denizli',
	STATE_DIYARBAKIR: 'Diyarbakir',
	STATE_DUZCE: 'Duzce',
	STATE_EDIRNE: 'Edirne',
	STATE_ELAZIG: 'Elazig',
	STATE_ERZINCAN: 'Erzincan',
	STATE_ERZURUM: 'Erzurum',
	STATE_ESKISEHIR: 'Eskisehir',
	STATE_GAZIANTEP: 'Gaziantep',
	STATE_GIRESUN: 'Giresun',
	STATE_GUMUSHANE: 'Gumushane',
	STATE_HAKKARI: 'Hakkari',
	STATE_HATAY: 'Hatay',
	STATE_ICEL: 'Icel',
	STATE_IGDIR: 'Igdir',
	STATE_ISPARTA: 'Isparta',
	STATE_ISTANBUL: 'Istanbul',
	STATE_IZMIR: 'Izmir',
	STATE_KAHRAMANMARAS: 'Kahramanmaras',
	STATE_KARABUK: 'Karabuk',
	STATE_KARAMAN: 'Karaman',
	STATE_KARS: 'Kars',
	STATE_KARSIYAKA: 'Karsiyaka',
	STATE_KASTAMONU: 'Kastamonu',
	STATE_KAYSERI: 'Kayseri',
	STATE_KILIS: 'Kilis',
	STATE_KIRIKKALE: 'Kirikkale',
	STATE_KIRKLARELI: 'Kirklareli',
	STATE_KIRSEHIR: 'Kirsehir',
	STATE_KOCAELI: 'Kocaeli',
	STATE_KONYA: 'Konya',
	STATE_KUTAHYA: 'Kutahya',
	STATE_LEFKOSA: 'Lefkosa',
	STATE_MALATYA: 'Malatya',
	STATE_MANISA: 'Manisa',
	STATE_MARDIN: 'Mardin',
	STATE_MUGLA: 'Mugla',
	STATE_MUS: 'Mus',
	STATE_NEVSEHIR: 'Nevsehir',
	STATE_NIGDE: 'Nigde',
	STATE_ORDU: 'Ordu',
	STATE_OSMANIYE: 'Osmaniye',
	STATE_RIZE: 'Rize',
	STATE_SAKARYA: 'Sakarya',
	STATE_SAMSUN: 'Samsun',
	STATE_SANLIURFA: 'Sanliurfa',
	STATE_SIIRT: 'Siirt',
	STATE_SINOP: 'Sinop',
	STATE_SIRNAK: 'Sirnak',
	STATE_SIVAS: 'Sivas',
	STATE_TEKIRDAG: 'Tekirdag',
	STATE_TOKAT: 'Tokat',
	STATE_TRABZON: 'Trabzon',
	STATE_TUNCELI: 'Tunceli',
	STATE_USAK: 'Usak',
	STATE_VAN: 'Van',
	STATE_YALOVA: 'Yalova',
	STATE_YOZGAT: 'Yozgat',
	STATE_ZONGULDAK: 'Zonguldak',
	STATE_AHAL: 'Ahal',
	STATE_ASGABAT: 'Asgabat',
	STATE_BALKAN: 'Balkan',
	STATE_DASOGUZ: 'Dasoguz',
	STATE_LEBAP: 'Lebap',
	STATE_MARI: 'Mari',
	STATE_GRAND_TURK: 'Grand Turk',
	STATE_SOUTH_CAICOS_AND_EAST_CAICOS: 'South Caicos and East Caicos',
	STATE_FUNAFUTI: 'Funafuti',
	STATE_NANUMANGA: 'Nanumanga',
	STATE_NANUMEA: 'Nanumea',
	STATE_NIUTAO: 'Niutao',
	STATE_NUI: 'Nui',
	STATE_NUKUFETAU: 'Nukufetau',
	STATE_NUKULAELAE: 'Nukulaelae',
	STATE_VAITUPU: 'Vaitupu',
	STATE_CHERKAS__KA: "Cherkas'ka",
	STATE_CHERNIHIVS__KA: "Chernihivs'ka",
	STATE_CHERNIVETS__KA: "Chernivets'ka",
	STATE_CRIMEA: 'Crimea',
	STATE_DNIPROPETROVSKA: 'Dnipropetrovska',
	STATE_DONETS__KA: "Donets'ka",
	STATE_IVANO_FRANKIVS__KA: "Ivano-Frankivs'ka",
	STATE_KHARKIV: 'Kharkiv',
	STATE_KHARKOV: 'Kharkov',
	STATE_KHERSONSKA: 'Khersonska',
	STATE_KHMEL__NYTS__KA: "Khmel'nyts'ka",
	STATE_KIROVOHRAD: 'Kirovohrad',
	STATE_KRYM: 'Krym',
	STATE_KYYIV: 'Kyyiv',
	STATE_KYYIVS__KA: "Kyyivs'ka",
	STATE_L__VIVS__KA: "L'vivs'ka",
	STATE_LUHANS__KA: "Luhans'ka",
	STATE_MYKOLAYIVS__KA: "Mykolayivs'ka",
	STATE_ODES__KA: "Odes'ka",
	STATE_ODESSA: 'Odessa',
	STATE_POLTAVS__KA: "Poltavs'ka",
	STATE_RIVNENS__KA: "Rivnens'ka",
	STATE_SEVASTOPOL: 'Sevastopol',
	STATE_SUMS__KA: "Sums'ka",
	STATE_TERNOPIL__S__KA: "Ternopil's'ka",
	STATE_VOLYNS__KA: "Volyns'ka",
	STATE_VYNNYTS__KA: "Vynnyts'ka",
	STATE_ZAKARPATS__KA: "Zakarpats'ka",
	STATE_ZAPORIZHIA: 'Zaporizhia',
	STATE_ZHYTOMYRS__KA: "Zhytomyrs'ka",
	STATE_ABU_ZABI: 'Abu Zabi',
	STATE_AJMAN: 'Ajman',
	STATE_DUBAI: 'Dubai',
	STATE_RAS_AL_KHAYMAH: 'Ras al-Khaymah',
	STATE_SHARJAH: 'Sharjah',
	STATE_SHARJHA: 'Sharjha',
	STATE_UMM_AL_QAYWAYN: 'Umm al Qaywayn',
	STATE_AL_FUJAYRAH: 'al-Fujayrah',
	STATE_ASH_SHARIQAH: 'ash-Shariqah',
	STATE_ABERDEEN: 'Aberdeen',
	STATE_ABERDEENSHIRE: 'Aberdeenshire',
	STATE_ARGYLL: 'Argyll',
	STATE_BEDFORDSHIRE: 'Bedfordshire',
	STATE_BELFAST: 'Belfast',
	STATE_BERKSHIRE: 'Berkshire',
	STATE_BIRMINGHAM: 'Birmingham',
	STATE_BRECHIN: 'Brechin',
	STATE_BRIDGNORTH: 'Bridgnorth',
	STATE_BRISTOL: 'Bristol',
	STATE_BUCKINGHAMSHIRE: 'Buckinghamshire',
	STATE_CAMBRIDGE: 'Cambridge',
	STATE_CAMBRIDGESHIRE: 'Cambridgeshire',
	STATE_CHANNEL_ISLANDS: 'Channel Islands',
	STATE_CHESHIRE: 'Cheshire',
	STATE_CLEVELAND: 'Cleveland',
	STATE_CO_FERMANAGH: 'Co Fermanagh',
	STATE_CONWY: 'Conwy',
	STATE_CORNWALL: 'Cornwall',
	STATE_COVENTRY: 'Coventry',
	STATE_CRAVEN_ARMS: 'Craven Arms',
	STATE_CUMBRIA: 'Cumbria',
	STATE_DENBIGHSHIRE: 'Denbighshire',
	STATE_DERBY: 'Derby',
	STATE_DERBYSHIRE: 'Derbyshire',
	STATE_DEVON: 'Devon',
	STATE_DIAL_CODE_DUNGANNON: 'Dial Code Dungannon',
	STATE_DIDCOT: 'Didcot',
	STATE_DORSET: 'Dorset',
	STATE_DUNBARTONSHIRE: 'Dunbartonshire',
	STATE_DURHAM: 'Durham',
	STATE_EAST_DUNBARTONSHIRE: 'East Dunbartonshire',
	STATE_EAST_LOTHIAN: 'East Lothian',
	STATE_EAST_MIDLANDS: 'East Midlands',
	STATE_EAST_SUSSEX: 'East Sussex',
	STATE_EAST_YORKSHIRE: 'East Yorkshire',
	STATE_ENGLAND: 'England',
	STATE_ESSEX: 'Essex',
	STATE_FERMANAGH: 'Fermanagh',
	STATE_FIFE: 'Fife',
	STATE_FLINTSHIRE: 'Flintshire',
	STATE_FULHAM: 'Fulham',
	STATE_GAINSBOROUGH: 'Gainsborough',
	STATE_GLOCESTERSHIRE: 'Glocestershire',
	STATE_GWENT: 'Gwent',
	STATE_HAMPSHIRE: 'Hampshire',
	STATE_HANTS: 'Hants',
	STATE_HEREFORDSHIRE: 'Herefordshire',
	STATE_HERTFORDSHIRE: 'Hertfordshire',
	STATE_IRELAND: 'Irlanda',
	STATE_ISLE_OF_MAN: 'Isle Of Man',
	STATE_ISLE_OF_WIGHT: 'Isle of Wight',
	STATE_KENFORD: 'Kenford',
	STATE_KENT: 'Kent',
	STATE_KILMARNOCK: 'Kilmarnock',
	STATE_LANARKSHIRE: 'Lanarkshire',
	STATE_LANCASHIRE: 'Lancashire',
	STATE_LEICESTERSHIRE: 'Leicestershire',
	STATE_LINCOLNSHIRE: 'Lincolnshire',
	STATE_LLANYMYNECH: 'Llanymynech',
	STATE_LONDON: 'London',
	STATE_LUDLOW: 'Ludlow',
	STATE_MAYFAIR: 'Mayfair',
	STATE_MERSEYSIDE: 'Merseyside',
	STATE_MID_GLAMORGAN: 'Mid Glamorgan',
	TATE_MIDDLESEX: 'Middlesex',
	STATE_MILDENHALL: 'Mildenhall',
	STATE_MONMOUTHSHIRE: 'Monmouthshire',
	STATE_NEWTON_STEWART: 'Newton Stewart',
	STATE_NORFOLK: 'Norfolk',
	STATE_NORTH_HUMBERSIDE: 'North Humberside',
	STATE_NORTH_YORKSHIRE: 'North Yorkshire',
	STATE_NORTHAMPTONSHIRE: 'Northamptonshire',
	STATE_NORTHANTS: 'Northants',
	STATE_NORTHERN_IRELAND: 'Northern Ireland',
	STATE_NORTHUMBERLAND: 'Northumberland',
	STATE_NOTTINGHAMSHIRE: 'Nottinghamshire',
	STATE_OXFORD: 'Oxford',
	STATE_POWYS: 'Powys',
	STATE_ROOS_SHIRE: 'Roos-shire',
	STATE_SUSSEX: 'SUSSEX',
	STATE_SCOTLAND: 'Scotland',
	STATE_SCOTTISH_BORDERS: 'Scottish Borders',
	STATE_SHROPSHIRE: 'Shropshire',
	STATE_SOMERSET: 'Somerset',
	STATE_SOUTH_GLAMORGAN: 'South Glamorgan',
	STATE_SOUTH_WALES: 'South Wales',
	STATE_SOUTH_YORKSHIRE: 'South Yorkshire',
	STATE_SOUTHWELL: 'Southwell',
	STATE_STAFFORDSHIRE: 'Staffordshire',
	STATE_STRABANE: 'Strabane',
	STATE_SUFFOLK: 'Suffolk',
	STATE_SURREY: 'Surrey',
	STATE_TWICKENHAM: 'Twickenham',
	STATE_TYNE_AND_WEAR: 'Tyne and Wear',
	STATE_TYRONE: 'Tyrone',
	STATE_UTAH: 'Utah',
	STATE_WALES: 'Wales',
	STATE_WARWICKSHIRE: 'Warwickshire',
	STATE_WEST_LOTHIAN: 'West Lothian',
	STATE_WEST_MIDLANDS: 'West Midlands',
	STATE_WEST_SUSSEX: 'West Sussex',
	STATE_WEST_YORKSHIRE: 'West Yorkshire',
	STATE_WHISSENDINE: 'Whissendine',
	STATE_WILTSHIRE: 'Wiltshire',
	STATE_WOKINGHAM: 'Wokingham',
	STATE_WORCESTERSHIRE: 'Worcestershire',
	STATE_WREXHAM: 'Wrexham',
	STATE_WURTTEMBERG: 'Wurttemberg',
	STATE_YORKSHIRE: 'Yorkshire',
	STATE_CALIFORNIA: 'California',
	STATE_COLORADO: 'Colorado',
	STATE_CONNECTICUT: 'Connecticut',
	STATE_DELAWARE: 'Delaware',
	STATE_DISTRICT_OF_COLUMBIA: 'District of Columbia',
	STATE_HAWAII: 'Hawaii',
	STATE_IDAHO: 'Idaho',
	STATE_ILLINOIS: 'Illinois',
	STATE_INDIANA: 'Indiana',
	STATE_IOWA: 'Iowa',
	STATE_KANSAS: 'Kansas',
	STATE_KENTUCKY: 'Kentucky',
	STATE_LOUISIANA: 'Louisiana',
	STATE_MAINE: 'Maine',
	STATE_MARYLAND: 'Maryland',
	STATE_MASSACHUSETTS: 'Massachusetts',
	STATE_MICHIGAN: 'Michigan',
	STATE_MINNESOTA: 'Minnesota',
	STATE_MISSISSIPPI: 'Mississippi',
	STATE_MISSOURI: 'Missouri',
	STATE_NEBRASKA: 'Nebraska',
	STATE_NEVADA: 'Nevada',
	STATE_NEW_HAMPSHIRE: 'New Hampshire',
	STATE_NEW_JERSEY: 'New Jersey',
	STATE_NEW_MEXICO: 'New Mexico',
	STATE_NORTH_CAROLINA: 'North Carolina',
	STATE_NORTH_DAKOTA: 'North Dakota',
	STATE_OHIO: 'Ohio',
	STATE_OKLAHOMA: 'Oklahoma',
	STATE_OREGON: 'Oregon',
	STATE_PENNSYLVANIA: 'Pennsylvania',
	STATE_RHODE_ISLAND: 'Rhode Island',
	STATE_SOUTH_CAROLINA: 'South Carolina',
	STATE_SOUTH_DAKOTA: 'South Dakota',
	STATE_TENNESSEE: 'Tennessee',
	STATE_TEXAS: 'Texas',
	STATE_VIRGINIA: 'Virginia',
	STATE_WEST_VIRGINIA: 'West Virginia',
	STATE_WISCONSIN: 'Wisconsin',
	STATE_WYOMING: 'Wyoming',
	STATE_UNITED_STATES_MINOR_OUTLYING_I: 'United States Minor Outlying I',
	STATE_ARTIGAS: 'Artigas',
	STATE_CANELONES: 'Canelones',
	STATE_CERRO_LARGO: 'Cerro Largo',
	STATE_COLONIA: 'Colonia',
	STATE_DURAZNO: 'Durazno',
	STATE_FLORES: 'Flores',
	STATE_LAVALLEJA: 'Lavalleja',
	STATE_MALDONADO: 'Maldonado',
	STATE_MONTEVIDEO: 'Montevideo',
	STATE_PAYSANDU: 'Paysandu',
	STATE_RIVERA: 'Rivera',
	STATE_ROCHA: 'Rocha',
	STATE_SALTO: 'Salto',
	STATE_SORIANO: 'Soriano',
	STATE_TACUAREMBO: 'Tacuarembo',
	STATE_TREINTA_Y_TRES: 'Treinta y Tres',
	STATE_ANDIJON: 'Andijon',
	STATE_BUHORO: 'Buhoro',
	STATE_CIZAH: 'Cizah',
	STATE_FARGONA: 'Fargona',
	STATE_HORAZM: 'Horazm',
	STATE_KASKADAR: 'Kaskadar',
	STATE_KORAKALPOGISTON: 'Korakalpogiston',
	STATE_NAMANGAN: 'Namangan',
	STATE_NAVOI: 'Navoi',
	STATE_SAMARKAND: 'Samarkand',
	STATE_SIRDARE: 'Sirdare',
	STATE_SURHONDAR: 'Surhondar',
	STATE_TOSKENT: 'Toskent',
	STATE_MALAMPA: 'Malampa',
	STATE_PENAMA: 'Penama',
	STATE_SANMA: 'Sanma',
	STATE_SHEFA: 'Shefa',
	STATE_TAFEA: 'Tafea',
	STATE_TORBA: 'Torba',
	STATE_VATICAN_CITY_STATE_HOLY_SEE_: 'El Vaticano',
	STATE_ANZOATEGUI: 'Anzoategui',
	STATE_APURE: 'Apure',
	STATE_ARAGUA: 'Aragua',
	STATE_BARINAS: 'Barinas',
	STATE_CARABOBO: 'Carabobo',
	STATE_COJEDES: 'Cojedes',
	STATE_DELTA_AMACURO: 'Delta Amacuro',
	STATE_FALCON: 'Falcon',
	STATE_GUARICO: 'Guarico',
	STATE_LARA: 'Lara',
	STATE_MERIDA: 'Mérida',
	STATE_MIRANDA: 'Miranda',
	STATE_MONAGAS: 'Monagas',
	STATE_NUEVA_ESPARTA: 'Nueva Esparta',
	STATE_PORTUGUESA: 'Portuguesa',
	STATE_TACHIRA: 'Tachira',
	STATE_TRUJILLO: 'Trujillo',
	STATE_VARGAS: 'Vargas',
	STATE_YARACUY: 'Yaracuy',
	STATE_ZULIA: 'Zulia',
	STATE_BAC_GIANG: 'Bac Giang',
	STATE_BINH_DINH: 'Binh Dinh',
	STATE_BINH_DUONG: 'Binh Duong',
	STATE_DA_NANG: 'Da Nang',
	STATE_DONG_BANG_SONG_CUU_LONG: 'Dong Bang Song Cuu Long',
	STATE_DONG_BANG_SONG_HONG: 'Dong Bang Song Hong',
	STATE_DONG_NAI: 'Dong Nai',
	STATE_DONG_NAM_BO: 'Dong Nam Bo',
	STATE_DUYEN_HAI_MIEN_TRUNG: 'Duyen Hai Mien Trung',
	STATE_HANOI: 'Hanoi',
	STATE_HUNG_YEN: 'Hung Yen',
	STATE_KHU_BON_CU: 'Khu Bon Cu',
	STATE_LONG_AN: 'Long An',
	STATE_MIEN_NUI_VA_TRUNG_DU: 'Mien Nui Va Trung Du',
	STATE_THAI_NGUYEN: 'Thai Nguyen',
	STATE_THANH_PHO_HO_CHI_MINH: 'Thanh Pho Ho Chi Minh',
	STATE_THU_DO_HA_NOI: 'Thu Do Ha Noi',
	STATE_TINH_CAN_THO: 'Tinh Can Tho',
	STATE_TINH_DA_NANG: 'Tinh Da Nang',
	STATE_TINH_GIA_LAI: 'Tinh Gia Lai',
	STATE_ANEGADA: 'Anegada',
	STATE_JOST_VAN_DYKE: 'Jost van Dyke',
	STATE_TORTOLA: 'Tortola',
	STATE_SAINT_CROIX: 'Saint Croix',
	STATE_ALO: 'Alo',
	STATE_SINGAVE: 'Singave',
	STATE_WALLIS: 'Wallis',
	STATE_BU_JAYDUR: 'Bu Jaydur',
	STATE_WAD_ADH_DHAHAB: 'Wad-adh-Dhahab',
	STATE_AL___AYUN: "al-'Ayun",
	STATE_AS_SAMARAH: 'as-Samarah',
	STATE_ADAN: 'Adan',
	STATE_ABYAN: 'Abyan',
	STATE_DHAMAR: 'Dhamar',
	STATE_HADRAMAUT: 'Hadramaut',
	STATE_HAJJAH: 'Hajjah',
	STATE_HUDAYDAH: 'Hudaydah',
	STATE_IBB: 'Ibb',
	STATE_LAHIJ: 'Lahij',
	STATE_MA__RIB: "Ma'rib",
	STATE_MADINAT_SAN__A: "Madinat San'a",
	STATE_SA__DAH: "Sa'dah",
	STATE_SANA: 'Sana',
	STATE_SHABWAH: 'Shabwah',
	STATE_TA__IZZ: "Ta'izz",
	STATE_AL_BAYDA: 'al-Bayda',
	STATE_AL_HUDAYDAH: 'al-Hudaydah',
	STATE_AL_JAWF: 'al-Jawf',
	STATE_AL_MAHRAH: 'al-Mahrah',
	STATE_AL_MAHWIT: 'al-Mahwit',
	STATE_COPPERBELT: 'Copperbelt',
	STATE_LUAPALA: 'Luapala',
	STATE_LUSAKA: 'Lusaka',
	STATE_NORTH_WESTERN: 'North-Western',
	STATE_BULAWAYO: 'Bulawayo',
	STATE_HARARE: 'Harare',
	STATE_MANICALAND: 'Manicaland',
	STATE_MASHONALAND_CENTRAL: 'Mashonaland Central',
	STATE_MASHONALAND_EAST: 'Mashonaland East',
	STATE_MASHONALAND_WEST: 'Mashonaland West',
	STATE_MASVINGO: 'Masvingo',
	STATE_MATABELELAND_NORTH: 'Matabeleland North',
	STATE_MATABELELAND_SOUTH: 'Matabeleland South',
	STATE_MIDLANDS: 'Midlands',
	STATE_AARHUS: 'Aarhus'
};
