import onboardingLocalization from '../components/Onboarding/localization/it-IT';
import ticketingLocalization from '../components/Ticketing/localization/it-IT';
import supportLocalization from '../components/Support/localization/it-IT';
import automationLocalization from '../components/Automation/localization/it-IT';
import sharedLocalization from '../components/Shared/localization/it-IT';
import analyticsLocalization from '../components/AnalyticsInvoices/localization/it-IT';
import failoverLocalization from '../components/Failover/localization/it-IT';
import reportsLocalization from '../components/Reports/localization/it-IT';
import eulaLocalization from '../components/Eula/localization/it-IT';
import accountOverviewLocalization from '../components/AccountOverview/localization/it-IT';
import analyticsChartsLocalization from '../components/Analytics/localization/it-IT';
import batchHistoryLocalization from '../components/BatchHistory/localization/it-IT';
import errorLocalization from '../components/ErrorPage/localization/it-IT';
import userFeedbackLocalization from '../components/UserFeedback/localization/it-IT';
import swaggerLocalization from '../components/Swagger/localization/it-IT';
import securityFeatures from '../components/SecurityFeatures/localization/it-IT';
import releaseNotesLocalization from '../components/ReleaseNotes/localization/it-IT';
import endpointsLocalization from '../components/Endpoint/localization/it-IT';
import singleEndpointLocalization from '../components/SingleEndpoint/localization/it-IT';

export default {
	...automationLocalization,
	...sharedLocalization,
	...analyticsLocalization,
	...supportLocalization,
	...onboardingLocalization,
	...ticketingLocalization,
	...failoverLocalization,
	...reportsLocalization,
	...accountOverviewLocalization,
	...eulaLocalization,
	...analyticsChartsLocalization,
	...batchHistoryLocalization,
	...errorLocalization,
	...userFeedbackLocalization,
	...swaggerLocalization,
	...securityFeatures,
	...endpointsLocalization,
	...releaseNotesLocalization,
	...singleEndpointLocalization,
	// GLOBAL SETTINGS
	DISPLAY_DATE_FORMAT: 'MM/gg/aaaa',

	// SIDEBAR
	HOME: 'Home',
	ENDPOINTS: 'Endpoint',
	Endpoints: 'Endpoint',
	REPORTS: 'Report',
	INVOICES: 'Fatture',
	AUTOMATION: 'Automazione',
	PLATFORM_STATUS: 'Stato piattaforma',
	SUPPORT: 'Assistenza',
	COLLAPSE_SIDEBAR: 'Comprimi barra laterale',
	COMPANIES: 'Aziende',
	FAQ: 'Domande frequenti',
	HELP_SUPPORT: 'Guida e assistenza',
	BATCH_UPDATES: 'Aggiornamenti batch',
	FAQS: 'Domande frequenti',
	RELEASE_NOTES: 'Note sulla versione',
	SYSTEM_NOTIFICATIONS: 'Notifiche di sistema',
	ACCOUNT_SETTINGS: 'Impostazioni account',
	COMPANY_ACCOUNTS: 'Account aziendale',
	CUSTOM_FIELDS: 'Campi personalizzati',
	COMPANY_DETAILS: 'Dettagli azienda',
	TESTING_ICON: 'Icona test',
	ENTITIES: 'Entità',
	IOTGW_FAILOVER: 'Failover IoTGW',
	ACCESS_MANAGEMENT: 'Gestione accessi',
	SIM_ORDERS: 'Ordini SIM',
	USER_MANAGEMENT: 'Gestione utenti',

	// TOPBAR
	MORE_IOT: 'ALTRO SU IoT',
	MORE_ATT: 'ALTRO SU AT&T',
	LEARN_ABOUT_OFFERS: 'Scopri di più sulle offerte',
	BUY_DEVICES: 'Acquista dispositivi',
	ATT_SERVICES: 'Servizi AT&T',
	USE_TOOLS_APPS: 'Usa strumenti e app',
	IOT_PLATFORMS_PRODUCTS_APPS_AND_APIS: 'Piattaforme, prodotti, app e API IoT',
	AMOC: 'AMOC',
	M2X: 'M2X',
	FLOW_DESIGNER: 'Progettazione flussi',
	IOT_STARTER_KITS: 'Starter kit IoT',
	IOT_DATA_PLANS: 'Piani dati IoT',
	ENTERPRISE: 'Enterprise',
	BUSINESS: 'Commerciale',
	CONSUMER: 'Consumatori',

	// PROFILE MENU
	PROFILE: 'Profilo',
	APPS: 'App',
	LOGOUT: 'Logout',
	LOG_IN: 'Accesso',

	// FOOTER
	IOT_CUSTOMER_SOLUTIONS: 'Soluzioni IoT per i clienti',
	IOT_PLATFORM_AND_SERVICES: 'Piattaforma e servizi IoT',
	NETWORK_SOLUTIONS: 'Soluzioni rete',
	PARTNERSHIP_PROGRAM: 'Programma per i partner',
	BUSINESS_SUPPORT: 'Assistenza commerciale',
	ABOUT_ATT: 'Informazioni su AT&T',
	ABOUT_ATT_IOT: 'Informazioni su IoT AT&T',
	NEWS: 'Notizie',
	NETWORKING_EXCHANGE_BLOG: 'Blog condivisione reti',
	BUSINESS_COMMUNITY: 'Comunità aziendale',
	YOUTUBE_CHANNEL: 'Canale YouTube',
	LEGAL_PRIVACY_ADVERTISING_ACCESSIBILITY: 'Legale, privacy, pubblicità, accessibilità',
	LEGAL_POLICY_CENTER: 'Centro politiche legali',
	PRIVACY_POLICY: 'Informativa sulla privacy',
	TERMS_OF_USE: 'Condizioni d’uso',
	BROADBAND_DETAILS: 'Dettagli banda larga',
	YOUR_CHOICES: 'Scelte personali',
	ADVERTISING_CHOICES: 'Scelte pubblicitarie',
	ACCESSIBILITY: 'Accessibilità',
	COPYRIGHT_ATT_INTELECTUAL_PROPERTY: '©{year} Proprietà intellettuale AT&T. ',
	COPYRIGHT_MESSAGE: 'Tutti i diritti riservati. AT&T, il logo del globo, Mobilizing Your World, e DIRECTV sono marchi registrati di proprietà intellettuale di AT&T e/o delle sue aziende affiliate. Tutti gli altri marchi sono di proprietà dei rispettivi proprietari.',
	CONNECT_WITH_ATT: 'Contattare AT&T',
	CURRENT_SESSION_USAGE: 'Utilizzo sessione corrente',
	ENDPOINT_NETWORK_AND_LOCATION: 'Rete e ubicazione endpoint',

	// NETWORK ENTITLEMENTS
	ENDPOINT_DETAILS_SETUP: 'Configurazione dettagli endpoint',
	NETWORK_ENTITLEMENT_DETAILS: 'Dettagli diritti di rete',
	CHANGE_NETWORK_ENTITLEMENTS: 'Cambia diritti di rete',
	CONFIRM_NETWORK_ENTITLEMENT_CHANGE: 'Conferma cambiamento diritti di rete',
	NETWORK_ENTITLEMENT_CHANGE_STATUS: 'Stato cambiamento diritti di rete',
	NETWORK_ENTITLEMENT_CHANGE_TOOLTIP_SUPPORT: 'Errore su diritti di rete, rivolgersi all’assistenza clienti.',
	NETWORK_ENTITLEMENT_CHANGE_NOT_ALLOWED: 'Siamo spiacenti, non è consentito il cambiamento di diritti di rete di più endpoint di diversi tipi di rete o diversi provider di servizi',
	SCHEDULE_NETWORK_ENTITLEMENT_SUCCESSFUL: 'Pianificazione diritti di rete per SIM multiple riuscita',
	SIMS_NETWORK_ENTITLEMENT_SUCCESSFULLY_UPDATED: 'Aggiornamento diritti di rete SIM riuscito',
	SIM_NETWORK_ENTITLEMENT_CHANGE_FOR: 'Cambiamento diritti di rete SIM per',
	MULTIPLE_NETWORK_ENTITLEMENT_CHANGE_SCHEDULE_ERROR: 'Pianificazione cambiamento diritti di rete per più SIM non riuscita',
	MULTIPLE_NETWORK_ENTITLEMENT_CHANGE_ERROR: 'Richiesta cambiamento diritti di rete per più SIM non riuscita',
	states: 'Stato',
	networkOperators: 'Operatore di rete',
	countries: 'Paese della rete',
	SELECT_NETWORK_ENTITLEMENT: 'Seleziona diritti di rete',
	ACCOUNT_NAME: 'Nome account',
	APN: 'APN',
	DESCRIPTION: 'Descrizione',
	ROAMING: 'Roaming',
	CREATE_DATE: 'Data creazione',
	LAST_UPDATE_DATE: 'Data ultimo aggiornamento',
	APN_LTE: 'APN LTE',
	SMS_MT: 'SMS MT',
	SMS_MO: 'SMS MO',
	VOICE_MO: 'Vocale MO',
	VOICE_MT: 'Vocale MT',
	INTERNATIONAL_VOICE: 'Vocale internazionale',
	FIXED_IP_ENABLED: 'IP fisso attivato',
	LTE_DATA: 'Dati LTE',
	LTE_ROAMING_RESTRICT_SET_ID: 'Roaming LTE',
	LTE_APNS: 'APN LTE',
	CAMEL_SUBSCRIPTION_INFO: 'Camel',
	CAMEL_SERVICE_TYPE: 'Tipo servizio Camel',
	M2M_PLATFORM: 'Piattaforma M2M',
	APNS_ID: 'ID APN',
	APNS_NAME: 'Nome',
	APNS_LTE: 'LTE',
	APNS_PDPINDEX: 'Indice PDP',
	APNS_DESCRIPTION: 'Descrizione APN',
	APNS_FIXEDIP: 'IP fisso',
	APNS_SECURITY: 'Sicurezza',
	APNS_SUBSCRIPTION: 'Abbonamento',
	APNS_CREATEDATE: 'Data creazione',
	APNS_LASTUPDATEDATE: 'Data ultimo aggiornamento',
	APNS_LASTUSER: 'Ultimo utente',
	APNS_APNTYPE: 'Tipo APN',
	APNS_CREATIONTIMESTAMP: 'Data e ora creazione',
	APNS_BMSYNCSTATUS: 'Stato sincronizzazione BM',
	APNS_HLRPROFILEREFERENCE: 'Riferimento profilo dettagli HLRHSS',
	APNS_IPADDRESSALLOCATIONTYPE: 'Tipo allocazione indirizzo IP',
	APNS_ISLOCALBREAKOUTENABLEDINDICATOR: 'Indicatore abilitazione breakout locale',
	APNS_LASTUPDATETIMESTAMP: 'Data e ora ultimo aggiornamento',
	APNS_OUCCONNECTIONTYPE: 'Tipo connessione OUC',
	APNS_SECURITYPROFILESUPPORTEDINDICATOR: 'Indicatore profilo sicurezza supportato',
	APNS_STATUS: 'Stato',
	APNS_VPNENABLEDINDICATOR: 'Indicatore abilitazione VPN',
	CURRENTLY_ASSIGNED_TO_THIS_ENDPOINT: 'Assegnato attualmente a questo endpoint',
	CURRENTLY_ASSIGNED_TO_SELECTED_ENDPOINTS: 'Assegnato attualmente agli endpoint selezionati',
	ELIGIBLE_TO_USE_WITH_SELECTED_ENDPOINTS: 'Idoneo all’utilizzo con gli endpoint selezionati',
	ELIGIBLE_TO_USE_WITH_THIS_ENDPOINT: 'Idoneo per l’utilizzo con questo endpoint',
	ELIGIBLE_NETWORK_ENTITLEMENTS: 'Diritti di rete idonei',
	NETWORK_ENTITLEMENT_CHANGE_IS_SUCCESSFULLY_SUBMITTED: 'Cambiamento diritti di rete inviato.',
	NETWORK_ENTITLEMENT_CHANGE_COULD_NOT_BE_CARRIED_OUT_SUCCESSFULLY: 'Al momento è impossibile effettuare il cambiamento dei diritti di rete.',
	NETWORK_ENTITLEMENT_CHANGE_IS_SUCCESSFULLY_SCHEDULED: 'Cambiamento diritti di rete pianificato.',
	NETWORK_ENTITLEMENT_CHANGE_IS_SUCCESSFULLY_SCHEDULED_FOR: 'Cambiamento diritti di rete pianificato per ',
	NETWORK_ENTITLEMENT_CHANGE_COULD_fNOT_BE_SCHEDULED_SUCCESSFULLY: 'Al momento è impossibile pianificare il cambiamento dei diritti di rete.',
	ONCE_YOU_INITIATE_THIS_CHANGE: 'Una volta avviato questo cambiamento, i diritti di rete dell’endpoint selezionato saranno ',
	WOULD_YOU_LIKE_TO_PROCEED_WITH_THIS_ACTION: 'Continuare?',
	OR_YOU_CAN_SCHEDULE_THE_CHANGE_FOR: 'Oppure è possibile pianificare il cambiamento per: ',
	FRIENDLY_NAME: 'Nome descrittivo',
	NAME: 'Nome',
	// Network Entitlement Details
	APN_DETAILS_ID: 'ID',
	APN_DETAILS_NAME: 'Nome',
	APN_DETAILS_LTE: 'LTE',
	APN_DETAILS_PDPINDEX: 'Indice PDP',
	APN_DETAILS_DESCRIPTION: 'Descrizione',
	APN_DETAILS_FIXEDIP: 'IP fisso',
	APN_DETAILS_SECURITY: 'Sicurezza',
	APN_DETAILS_SUBSCRIPTION: 'Abbonamento',

	// BUTTON LABELS
	CANCEL: 'Annulla',
	CHANGE: 'Cambia',
	TROUBLESHOOT: 'Risoluzione problemi',
	CHANGE_STATUS: 'Cambia stato',
	CLOSE: 'Chiudi',
	APPLY_CHANGE: 'Applica cambiamento',
	DONE: 'Fatto',
	SAVE: 'Salva',
	CONTINUE: 'Continua',
	SAVEDASHBOARD: 'Salva dashboard',
	SAVE_AND_CONTINUE: 'Salva e continua',
	SAVE_CHANGES: 'Salva cambiamenti',
	PULL_DATA: 'Estrai dati',
	I_DO_NOT_AGREE: 'Non sono d’accordo',
	I_AGREE: 'Sono d’accordo',
	LOADING: 'Caricamento in corso',
	UPLOAD_LOGO: 'Carica logo',
	ADD_NEW_CUSTOMER: 'Aggiungi nuovo cliente',
	EDIT: 'Modifica',
	DELETE: 'Elimina',
	NEXT: 'Successivo',

	// NOTIFICATIONS
	ENDPOINT_ID: 'ID endpoint',
	PROVISIONING_ERROR: 'Errore di provisioning',
	FAILED_TO_PROVISION_AFTER_IMSI_STATUS_CHANGE: ': provisioning non riuscito dopo cambiamento stato IMSI. ',
	GET_SUPPORT: 'Ottieni assistenza',
	PROVISIONING_SUCCESS: 'Provisioning riuscito',
	IS_SUCCESSFULLY_PROVISIONED: ': provisioning riuscito. ',
	IMSI_STATUS_IS_CHANGED_TO: 'Stato IMSI cambiato a ',
	PENDING_STATUS_CHANGE: 'Cambiamento in sospeso!',
	STATUS_CHANGE_FOR: 'Attività pianificata per questo endpoint',
	IS_PENDING: ' è in sospeso',
	CUSTOM_FIELD_EDIT_SUCCESS: 'Modifica campo personalizzato riuscita',
	CUSTOM_FIELD_EDIT_ERROR: 'Errore modifica campo personalizzato',
	CUSTOM_FIELD_VALUES_UPDATED: 'Aggiornamento di uno o più campi personalizzati riuscito',
	CUSTOM_FIELD_VALUES_UPDATED_ERROR: 'Al momento, è impossibile aggiornare uno o più campi personalizzati. Riprovare.',
	CUSTOM_FIELD_LABEL_SUCCESS_TITLE: 'Modifica etichetta campo personalizzata riuscita!',
	CUSTOM_FIELD_LABEL_SUCCESS_MESSAGE: 'Aggiornamento di una o più etichette campo personalizzate riuscito.',
	CUSTOM_FIELD_LABEL_ERROR_TITLE: 'Errore modifica etichetta campo personalizzata!',
	CUSTOM_FIELD_LABEL_ERROR_MESSAGE: 'Al momento, è impossibile aggiornare una o più etichette campo personalizzate. Riprovare.',
	TABLE_COLUMNS_CHANGE_SUCCESS_TITLE: 'Cambiamento impostazioni tabella endpoint riuscito!',
	TABLE_COLUMNS_CHANGE_SUCCESS_MESSAGE: 'Aggiornamento impostazioni tabella endpoint riuscito.',
	TABLE_COLUMNS_CHANGE_ERROR_TITLE: 'Errore cambiamento colonne tabella endpoint!',
	TABLE_COLUMNS_CHANGE_ERROR_MESSAGE: 'Al momento, è impossibile aggiornare le colonne della tabella endpoint. Riprovare.',
	MC10515: 'Nome file errato',
	MC10516: 'Errore dati file',
	MC10517: 'Il file non contiene un’intestazione o è vuoto',
	DOWNLOAD_REPORT_ERROR_TITLE: 'Errore di download',
	DOWNLOAD_REPORT_ERROR_MESSAGE: 'Errore durante il tentativo di download del file',
	FETCH_REPORT_ERROR_TITLE: 'Nessun dato',
	FETCH_REPORT_ERROR_MESSAGE: 'Non ci sono dati che corrispondono ai parametri di ricerca/filtro',
	MARK_READ: 'Segna come già letto',
	VALIDATION_FAILED: 'Convalida non riuscita',
	ACTION_COMPLETED_SUCCESSFULLY: 'Azione completata',
	ACTION_COULD_NOT_BE_COMPLETED: 'Impossibile completare quest’azione',

	// SINGLE ENDPOINT
	ENDPOINT_DETAILS: 'Dettagli endpoint',
	DETAILS: 'Dettagli',
	DIAGNOSTIC: 'Diagnostica',
	CHANGES: 'Cambiamenti',
	SESSIONS: 'Sessioni',
	END_USER: 'Utente finale',
	LIFE_CYCLE: 'Ciclo di vita',
	SEND_SMS: 'Invia SMS',
	OTA: 'OTA',
	RATING: 'Tariffazione',
	CARRIER_INFORMATION: 'Informazioni vettore',
	M2M_PLATFORM_STATUS_DESCRIPTION: 'Descrizione stato piattaforma M2M',
	VIEW_NOTE: 'Visualizza nota',
	SE_NOTES: 'Note',
	CHANGE_NOTES: 'Cambia note',
	ENTER_NOTE_HERE: 'Inserire nota qui (massimo 2000 caratteri)',
	ADD_NOTE_BELOW: 'Aggiungere nota qui sotto: ',
	CHANGING_NOTES: 'Modifica note',
	MULTIPLE_NOTES_CHANGE_ERROR: 'Avvio modifica note per più endpoint non riuscito',
	CHANGING_NOTES_NOT_ALLOWED: 'Non è permesso modificare le note',
	ENDPOINTS_SUCCESSFULLY_CHANGED: 'Modifica endpoint riuscita',
	ENDPOINT_DETAILS_SECOND_CARD_NO_DATA: 'I dati per questo endpoint sono temporaneamente non disponibili',

	// DATA OVERVIEW
	ICCID: 'ICCID',
	IMSI: 'IMSI',
	NETWORK_OPERATOR: 'Operatore di rete',
	NETWORK_COUNTRY: 'Paese della rete',
	MSISDN: 'MSISDN',
	DUAL_MODE: 'Modalità duale',
	CONNECTED_DEVICE: 'Dispositivo connesso',
	IMEI: 'IMEI',
	STATUS: 'Stato',
	STATUS_CHANGE_PENDING: 'Attività pianificata per questo endpoint',
	RATE_PLAN: 'Piano tariffario',
	NETWORK_ENTITLEMENT: 'Diritti di rete',
	SOURCE_PLATFORM: 'Piattaforma M2M',
	SERVICE_PROVIDER: 'Piattaforma M2M',
	NETWORK_ENTITLEMENT_ID: 'ID diritti di rete',

	// ENDPOINT STATUS
	CURRENT_ENDPOINT_STATUS: 'Stato endpoint corrente',

	// Change SIM parameter modal
	CHANGE_SIM_PARAMETER: 'Cambia parametro SIM',
	SELECT_ATT_STATE: 'SELEZIONA STATO AT&T',
	CURRENT: 'CORRENTE',

	BY: 'Entro il ',
	SCHEDULING: 'Pianificazione in corso...',

	// EDIT TABLE MODAL
	EDIT_TABLE_PARAMETERS: 'Modifica parametri tabella',
	EDIT_CUSTOM_FIELD_LABELS: 'Modifica etichette campi personalizzati',
	CUSTOM_LABEL: 'Etichetta personalizzata {labelIndex}',
	VALUE: 'Valore',
	SET_AS_REQUIRED: 'Impostare come necessario',
	SELECT_A_MINIMUM_OF_4_TO_APPLY_TO_THE_TABLE: 'Selezionarne almeno 2 da applicare alla tabella. ',
	SELECTED: ' selezionati',
	CLEAR_ALL: 'Ripristina impostazioni predefinite',

	EOD: 'Enterprise on Demand (EOD)',
	ACC: 'AT&T Control Center (ACC)',
	GBCM: 'Speedcast',
	MC: 'IoT Console (IOT-C)',
	DCP: 'Device Connection Platform (DCP)',
	CCIP: 'Control Center Integration Program (CCIP)',
	AIS: 'AIS',
	TWM: 'Taiwan Mobile (TWM)',
	ATT: 'AT&T',
	AUTOMATION_ACC: 'ACC',
	AUTOMATION_DCP: 'DCP',
	// AT&T: 'AT&T',
	WING: 'Nokia (WING)',
	VIVO: 'Vivo Control Center',

	eod: 'Enterprise on Demand (EOD)',
	acc: 'AT&T Control Center (ACC)',
	gbcm: 'Speedcast',
	mc: 'IoT Console (IOT-C)',
	dcp: 'Device Connection Platform (DCP)',
	ccip: 'Control Center Integration Program (CCIP)',
	ais: 'AIS',
	twm: 'Taiwan Mobile (TWM)',
	att: 'AT&T',
	// at&t: 'AT&T',
	wing: 'Nokia (WING)',
	vivo: 'Vivo Control Center',

	SELECTED_FILTERS: 'Filtri selezionati',

	BY_WEEK: 'Alla settimana',
	BY_DAY: 'Al giorno',
	BY_HOUR: 'All’ora',
	BYTES_SENT: 'MB inviati',
	BYTES_RECEIVED: 'MB ricevuti',
	TOTAL_MINS: 'Minuti totali',
	monthly: 'Al mese',
	daily: 'Al giorno',
	MONTHLY: 'Al mese',
	DAILY: 'Al giorno',

	// ACTION BAR
	SHOWING: 'Visualizzazione ',
	OF: ' di ',

	// REPORTS
	TRANSFER: 'Trasferimento',
	USAGE: 'Utilizzo',
	DOWNLOAD: 'Download',
	OTHER: 'Altro',

	// INPUT ERRORS
	REQUIRED: 'Obbligatorio',
	INVALID_COMPANY_NAME: 'Nome azienda non valido',
	INVALID_EMAIL_ADDRESS: 'Indirizzo e-mail non valido',
	PHONE_MUST_BE_A_NUMBER: 'Il numero telefonico deve essere un numero',
	WRONG_PHONE_FORMAT: 'Formato errato numero telefonico',
	ONLY_LETTERS_AND_NUMBERS_ARE_ALLOWED: 'Sono consentiti solo lettere e numeri',
	ONLY_LETTERS_ARE_ALLOWED: 'Sono consentite solo lettere',
	ONLY_LETTERS_AND_SPACES_ARE_ALLOWED: 'Sono consentiti solo lettere e spazi',
	ONLY_NUMBERS_ARE_ALLOWED: 'Sono consentiti solo numeri',
	ONLY_POSITIVE_NUMBERS_ARE_ALLOWED: 'Sono consentiti solo numeri maggiori di 0',
	NUMBER_HAS_TO_BE_LESS_THAN: 'Il numero deve essere minore di {propName}',
	NUMBER_HAS_TO_BE_LESS_THAN_OR_EQUAL_TO: 'Il numero deve essere minore o uguale a {propName}',
	NUMBER_HAS_TO_BE_GREATER_THAN: 'Il numero deve essere maggiore di {propName}',
	NUMBER_HAS_TO_BE_GREATER_THAN_OR_EQUAL_TO: 'Il numero deve essere maggiore o uguale a {propName}',
	NUMBER_HAS_TO_BE_IN_INCREMENTAL_STEPS: 'Il numero deve essere in incrementi di {increment}',
	INVALID_FORMAT: 'Formato non valido',
	MAX_CHARACTERS_EXCEEDED: 'Superato il numero massimo di caratteri',
	MUST_BE_LESS_THAN_OR_EQUAL_TO_CHARACTERS: 'Deve essere minore o uguale a {max} caratteri',
	MUST_BE_CHARACTERS_LONG: 'Deve avere una lunghezza di {number} caratteri',
	NO_ONLY_WHITE_SPACE: 'Non possono essere solo spazi vuoti',
	OUT_OF_RANGE: 'Fuori intervallo',
	NOT_BEFORE: 'La data non può essere precedente a {date}',
	INVALID_VERSION: 'È permesso solo il formato “major.minor.patch”, cioè x.y.z',

	/* ------ OLD BUT STILL WORKING - DO NOT TOUCH - WORK IN PROGRESS --------- */
	ACCOUNT_OVERVIEW: 'Panoramica account',
	ACCOUNT_OVERVIEW_ADMIN_DASHBOARD: 'Dashboard amministratore panoramica account',
	BILLING: 'Fatturazione',

	LOG_OUT: 'Logout',

	DATA_PLAN: 'Piano dati',
	ACTIVESESSION: 'SESSIONE ATTIVA',
	IPADDRESS: 'INDIRIZZO IP',
	ANALYTICS: 'Analisi',
	NETWORK: 'RETE',

	ASSET_ID: 'ID risorsa',
	SESSION_START: 'Inizio sessione',
	DATA_VOLUME: 'Volume dati',
	DURATION: 'Durata (min)',
	'DATE&TIME': 'Data e ora',
	NOTE: 'Nota',
	LOGINTITLE: 'Accesso',
	LOGINSUBTITLE: 'Inserire e-mail e password.',
	ENDPOINTS_INVENTORY_STATUS: 'STATO INVENTARIO ENDPOINT',
	INVENTORY_BY_CARRIER: 'INVENTARIO PER VETTORE',
	INVENTORY_BY_COUNTRY: 'INVENTARIO PER PAESE',
	ACCOUNT_USERS: 'UTENTI ACCOUNT',
	USER_AUTOMATION_STATUS: 'STATO AUTOMAZIONE UTENTE',
	AUTOMATION_PROCESS_STATUS: 'STATO PROCESSO AUTOMAZIONE',
	NETWORK_TYPE: 'Tipo di rete',
	DEVICE_NAME: 'Nome dispositivo',
	DEVICE_ID: 'ID dispositivo',
	DEVICE_MODEL_NUMBER: 'N. modello dispositivo',
	ROAMING_RESTRICTED: 'ROAMING CON RESTRIZIONI',
	INVOICE_NUMBER: 'NUMERO FATTURA',
	DATE: 'Data',
	SCHEDULE_DATE: 'Data',
	ISSUED_BY: 'EMESSA DA',
	PAYMENT_STATUS: 'STATO PAGAMENTO',
	REPORT_NAME: 'Nome report',
	DATE_CREATED: 'Data creazione',
	CREATED_BY: 'Creato da',
	PAY_NOW: 'PAGAMENTO IMMEDIATO',
	EDIT_GRAPHS_VIEW: 'Modifica vista grafici',
	EDIT_ENDPOINTS_LIST_VIEW: 'Modifica vista elenco endpoint',
	CHANGE_LANGUAGE: 'Cambia lingua',
	CHANGE_LANGUAGE_ICON: 'EN',
	NOT_APPLICABLE_FOR_THIS_ENDPOINT: 'Non applicabile per questo endpoint',
	CREATE_NEW_RULE: 'Crea nuova regola',
	EDIT_RULE: 'Modifica regola',
	CREATE_NEW_RULE_EXP: 'L’automazione permette di effettuare azioni in blocco sull’account.',
	CREATE_NEW_RULE_ACTION: 'Selezionare il pulsante “Crea nuova regola” per creare una nuova azione automatizzata',
	RULE_NAME: 'Nome regola',
	CATEGORY: 'Categoria',
	TRIGGER: 'Attivazione',
	ACTION: 'AZIONE',
	BULK_ACTIONS: 'Azioni in blocco',
	MULTI_ENDPOINT_ACTIONS: 'Azioni con endpoint multipli',
	SET_ENDPOINT_STATE: 'Imposta stato endpoint',
	CHANGE_DATA_PLAN: 'Cambia piano dati',
	SWAP_OPERATOR: 'Scambia operatore',
	MULTI_EDIT: 'Modifica multipla',
	SEARCH_FOR_RULES: 'Cerca regole',
	SEARCH_FOR_INVOICES: 'Cerca fatture',
	SEARCH_FOR_REPORTS: 'Ricerca report per nome',
	SEARCH_ENDPOINTS: 'Cerca endpoint',
	PAYABLE_INVOICES: 'FATTURE ESIGIBILI',
	RECEIVED_PAYMENTS: 'PAGAMENTI RICEVUTI',
	PAYMENT_METHODS: 'METODI DI PAGAMENTO',
	BILLERS: 'EMITTENTI',
	TIME_PERIOD: 'Periodo',
	FROM_DATEPICKER: 'Da',
	TO_DATEPICKER: 'A',
	ENDPOINT_UI_TOOLTIP: 'Numero identificativo per endpoint o dispositivo',
	OPERATOR_TOOLTIP: 'Operatore a cui appartengono endpoint e profilo',
	NETWORK_TYPE_TOOLTIP: 'Tecnologia di connettività dell’operatore',
	COUNTRY_TOOLTIP: 'Paese di origine dell’endpoint',
	ROAMING_RESTRICTED_TOOLTIP: 'Se esistono restrizioni al roaming per l’endpoint',
	STATUS_TOOLTIP: 'Stato corrente dell’endpoint',
	INITIATE_RULE: 'AVVIO REGOLA',
	NEW_AUTOMATION_RULE: 'NUOVA REGOLA AUTOMAZIONE',
	ENDPOINT_PROVISIONING: 'PROVISIONING ENDPOINT',
	ENDPOINT_PROFILE: 'PROFILO ENDPOINT',
	ENDPOINT_SECURITY: 'SICUREZZA ENDPOINT',
	ENDPOINT_SUBSCRIPTION: 'ABBONAMENTO ENDPOINT',
	NEW_RULE: 'NUOVA REGOLA',
	CONDITION: 'CONDIZIONE',
	ENDPOINT_RANGE: 'L’intervallo dell’endpoint è',
	RANGE_START: 'INIZIO INTERVALLO',
	RANGE_END: 'FINE INTERVALLO',
	ASSIGN_ENDPOINT: 'Assegna endpoint a operatore',
	OPERATOR_NAME: 'NOME OPERATORE',
	ACCOUNT: 'ACCOUNT',
	ADD_ACTION: 'AGGIUNGI AZIONE',
	RUN_IMMEDIATELY: 'ESEGUI IMMEDIATAMENTE',
	TIME: 'ORA',
	SELECT_DATE: 'SELEZIONA DATA',
	SELECT_TIME: 'SELEZIONA ORA',
	EVENT_TRIGGER: 'ATTIVAZIONE EVENTO',
	EVENT_TYPE: 'TIPO DI EVENTO',
	EVENT_LOCATION: 'UBICAZIONE EVENTO',
	DATA_PLAN_NAME: 'NOME PIANO DATI',
	LOGIN_VALIDATION_WRONG_CREDENTIALS_HEADER: 'Credenziali errate!',
	LOGIN_VALIDATION_WRONG_CREDENTIALS_BODY: 'La password non corrisponde all’e-mail fornita.',
	EMAIL: 'E-mail',
	PASSWORD: 'password',
	FORGOT_PASSWORD: 'Password dimenticata?',
	REMEMBER_ME: 'Memorizza credenziali',
	LOGIN_BUTTON: 'ACCEDI',
	BACK: 'Indietro',
	ENDPOINT_USAGE: 'Utilizzo endpoint',
	COUNTRY_AND_OPERATOR: 'Paese e operatore',
	PAYMENTS_HISTORY: 'Cronologia pagamenti',
	SPENDING_THIS_YEAR: 'Spese di quest’anno',
	ENDPOINT_DATA: 'DATI ENDPOINT',
	CURRENT_SESSION: 'SESSIONE CORRENTE',
	VIEW_LESS: 'Meno',
	VIEW_MORE: 'Più',
	ENDPOINT_STATUS: 'Stato endpoint',
	ACTIVATION_DATE: 'Data attivazione',
	PRIMARY_IMS: 'IMS primario',

	SERVICE_PROVIDER_MODE: 'MODALITÀ PROVIDER DI SERVIZI',
	SUPER_USER_MODE_MESSAGE: '{mode}: {companyFriendlyName}.',
	SUPER_USER_MODE_MOBILE: '{companyFriendlyName}',
	POLICY: 'Politica',
	COMMUNICATION_PLAN: 'Piano di comunicazione',
	COPY_RULE: 'Copia regola',
	SECURE_ENDPOINT_ID: 'ID endpoint sicuro',
	CUSTOMER: 'Cliente',
	INVENTORY_STATUS: 'STATO INVENTARIO',
	RESOLUTION_NOT_SUPPORTED_FIRST_LINE: 'Nella demo la vista a schermo ridotto è disabilitata.',
	RESOLUTION_NOT_SUPPORTED_SECOND_LINE: 'Per ottenere i risultati migliori, utilizzare il browser Chrome desktop a tutto schermo o ingrandito.',
	CHECKING_MOVE_ELIGIBILITY: 'CONTROLLO IDONEITÀ AL TRASFERIMENTO',
	CHANGE_OPERATOR: 'CAMBIA OPERATORE',
	SCHEDULE_CHANGE: 'PIANIFICA CAMBIAMENTO',
	SCHEDULE_CHANGE_BUTTON: 'Pianifica cambiamento',
	INITIATE_CHANGE: 'Avvia cambiamento',
	CONFIRM_CHANGE: 'Conferma cambiamento',
	CONFIRM_SIM_STATE_CHANGE: 'Conferma cambiamento stato SIM',
	IN_SESSION: 'IN USO',
	ENTITLEMENTS: 'Diritti',
	LOCALIZE_SERVICE: 'Localizza servizio',
	CHANGE_ENDPOINT_OPERATOR: 'CAMBIA OPERATORE ENDPOINT',
	PENDING_CHANGES_ENDPOINT: 'Ci sono cambiamenti in sospeso per questo endpoint, a partire da',
	SEE_HISTORY_FOR_DETAILS: 'Vedere dettagli nella cronologia indicata di seguito.',
	CHANGE_ENDPOINT_DATA_PLAN: 'Cambia piano dati endpoint',
	IS_SELECTED: ' selezionato.',
	PLEASE_SELECT_DIFFERENT_OPERATOR_TO_CHANGE_OPERATOR: 'Selezionare un operatore diverso qui sotto per cambiare l’operatore:',
	GENERATE_REPORT: 'GENERA REPORT',
	REPORT_TYPE: 'TIPO DI REPORT',
	DATA_PLAN_SUMMARIES: 'Riepiloghi piano dati',
	CHARGE_SUMMARIES: 'Riepiloghi addebiti',
	INVOICE_REPORT: 'Report fatture',
	PRORATION_REPORT: 'Report ripartizione',
	GROSS_ACTIVATIONS: 'Attivazioni lorde',
	MONTHLY_SUBSCRIBERS: 'Abbonati mensili',
	VIEW_YOUR_ACCOUT_SNAPSHOT: 'Visualizza istantanea account.',
	LAUNCH_DASHBOARD: 'AVVIA DASHBOARD',
	VISUAL_DASHBOARD_VIEW_EXP: 'La dashboard visuale offre un’occhiata sullo stato corrente del conto e sull’attività del dispositivo.',
	VIEW_CURRENT_STATUS_AND_HISTORICAL_DATA: 'Visualizzare lo stato corrente e i dati storici attraverso la sequenza temporale.',
	IF: 'SE',
	AND: 'E',
	OR: 'O',
	TO: 'A',
	FROM: 'DA',
	INVOICES_STATUS: 'STATO FATTURE',
	filter_category_m2mAccount: 'M2mAccount',
	PLEASE_CONFIRM_ENDPOINT_DATA_CHANGE: 'Conferma cambiamento dati endpoint.',
	SET_NEW_OPERATOR_AS_ACTIVE: 'IMPOSTA SU ATTIVO IL NUOVO OPERATORE',
	SET_NEW_PLAN_AS_ACTIVE: 'IMPOSTA SU ATTIVO IL NUOVO PIANO',
	PLEASE_SELECT_DATE_TO_SET_UP_YOUR_CHANGES_AND_CONFIRM: 'Selezionare la data per l’impostazione dei cambiamenti e confermare.',
	DATE_REQUIRED: 'Data (obbligatoria)',
	ADDITIONAL_COMMENT: 'Nota aggiuntiva',
	TYPE_YOUR_CUSTOM_NOTES_HERE: 'Scrivi qui le note personalizzate…',
	CONFIRM_CHANGES: 'Conferma cambiamenti',
	CHECKING_MOVE_ELIGIBILITY_LOADER: 'Controllo idoneità al trasferimento…',
	ELIGIBLE: 'IDONEO',
	CHECKING: 'CONTROLLO IN CORSO',
	PLEASE_SELECT_DIFFERENT_DATA_PLAN_TO_CHANGE_DATA_PLAN: 'Per cambiare il piano dati, selezionarne uno diverso qui di seguito:',
	CONFIRM: 'Conferma',
	ENDPOINT_RANGE_IS: 'L’intervallo dell’endpoint è:',
	ENDPOINT_ID_IS: 'L’ID dell’endpoint è:',
	ENDPOINT_ID_IS_GREATER_THAN: 'L’ID dell’endpoint è maggiore di:',
	ENDPOINT_ID_IS_LESS_THAN: 'L’ID dell’endpoint è minore di:',
	ENDPOINT_STATE_CHANGE: 'Cambiamento stato endpoint',
	ENDPOINT_DATA_PLAN: 'Piano dati endpoint',
	ENDPOINT_SECURITY_POLICY_CHANGE: 'Cambiamento politica sicurezza endpoint',
	ASSIGN_ENDPOINT_TO_OPERATOR: 'Assegna endpoint a operatore',
	MOVE_TO_ANOTHER_ACCOUNT: 'Trasferisci a un altro account',
	ADD_CUSTOM_FIELD_TO_ENDPOINT_DETAILS: 'Aggiungi campo personalizzato a dettagli endpoint',
	CREATE_NEW_ACTION: 'Crea nuova azione',
	AT_T: 'AT&T IoT (ACC)',
	AT_T_EOD: 'AT&T IoT (ACC)',
	GLOBECOMM: 'Speedcast',
	TAIWAN_MOBILE: 'Taiwan Mobile (TWM)',
	'0001': '***0001',
	'0002': '***0002',
	'0003': '***0003',
	'0004': '***0004',
	OTA_EVENT: 'Evento OTA',
	WI_FI: 'Wi-Fi',
	THAILAND: 'Tailandia (THA)',
	TAIWAN: 'Taiwan (TWN)',
	UNITED_STATES: 'Stati Uniti (USA)',
	DATA_PLAN_S: 'Piano dati S',
	DATA_PLAN_M: 'Piano dati M',
	DATA_PLAN_L: 'Piano dati L',
	DATA_PLAN_XL: 'Piano dati XL',
	// TO DO: Update Endpoint statuses to correct values
	ACTIVATED: 'Attivato',
	'TEST READY': 'Pronto alla prova',
	DEACTIVATED: 'Disattivato',
	PURGED: 'Cancellato',
	RETIRED: 'Ritirato',
	//
	USER: 'Utente',
	ON: 'il',
	MADE: 'fatto',
	CHANGE_STATUS_ACTION: 'Cambia stato',
	CHANGE_DATA_PLAN_ACTION: 'Cambia piano dati',
	LOCALIZE_SERVICE_ACTION: 'Servizio localizzato',
	IMSI_TRANSFER: 'Stato trasferimento IMSI',
	TRANSFER_STATUS: 'Stato trasferimento IMSI',
	COUNTRY_OPERATOR: 'Paese della rete',

	PROFILE_NAME: 'Nome profilo',
	NUMBER: 'Numero',
	DATE_MODIFIED: 'Data modifica',

	ENTITLEMENT_PLAN_ID: 'ID piano diritti',
	ENTITLEMENT_PLAN_NAME: 'Nome piano diritti',
	ACCOUNT_ID: 'ID account',
	ACCOUNT_I_D: 'ID account',
	IMEI_NAME: 'Nome IMEI',
	VIN: 'VIN',
	CHIP_ID: 'ID chip',
	URL_SP: 'url SP',
	ADD_SINGLE_ENDPOINT: 'Aggiungi endpoint singolo',
	UPLOAD_ICCID_BULK_FILE: 'Carica file per operazioni in blocco ICCID',
	DOWNLOAD_BULK_FILE: 'Scarica file per operazioni in blocco',
	ENDPOINTS_SELECTED: 'endpoint selezionati',
	SELECTED_ENDPOINTS: 'endpoint selezionati',
	NEW_STATUS: 'Nuovo stato',
	MULTIPLE_ENDPOINT_EDIT: 'Modifica endpoint multipli',
	CLEAR_VALUES_FOR_ALL_ENDPOINTS: 'Cancella valori per tutti gli endpoint',
	KEEP_ORIGINAL_ENDPOINT_VALUES: 'Mantieni i valori degli endpoint originali',
	ACTIVE: 'Attivato',
	INACTIVE: 'Inattivo',
	TERMINATE: 'Chiuso',
	TEST_READY: 'Pronto alla prova',
	TEST: 'Test',
	SELECT_OPTION_OR_TYPE_IN: 'Seleziona l’opzione o digita',
	CLEAR_ALL_VALUES_FOR_ENDPOINTS: 'Cancella tutti i valori per gli endpoint',
	BULK_STATUS_UPDATE: 'Aggiornamento globale stato',
	TOTAL_ENDPOINTS: 'Totale endpoint',
	TOTAL_FILES: 'Totale file',
	ENDPOINT_STATUS_UPDATED: 'Stato endpoint aggiornato',
	MODIFIED_ENDPOINTS: 'Endpoint modificati',
	SOMETHING_WENT_WRONG: 'Si è verificato un errore',
	ENDPOINT_STATUS_IS_UPDATING: 'Aggiornamento stato endpoint in corso',
	PLEASE_WAIT: 'Attendere',
	SWAP_SIM: 'Scambia SIM',
	SWAP_SIMS: 'Scambia SIM',
	SIM: 'SIM',
	NEW: 'Nuova',
	OLD: 'Vecchia',
	CHANGE_ORDER: 'Cambia ordine',
	ITEM: 'Articolo',
	ADDITIONAL_INFO: 'Informazioni aggiuntive',
	UPDATE_LABEL: 'Aggiorna etichetta',
	ENDPOINT_LABEL_IS_UPDATING: 'Aggiornamento etichetta endpoint in corso',
	ENDPOINT_LABEL_UPDATED: 'Etichetta endpoint aggiornata',
	ENDPOINT_FIELDS_ARE_UPDATING: 'Aggiornamento campi endpoint in corso',
	ENDPOINT_FIELDS_UPDATED: 'Campi endpoint aggiornati',
	EDIT_CUSTOM_FIELDS: 'Modifica campi personalizzati',
	EDIT_CUSTOM_LABEL: 'Modifica etichetta personalizzata',
	CHANGE_ENTITLEMENT: 'Cambia diritti di rete',
	CHANGE_NETWORK_ENTITLEMENT: 'Cambia diritti di rete',
	CURRENT_NETWORK_ENTITLEMENT: 'Diritti di rete correnti',
	NETWORK_ENTITLEMENT_UPDATED_SUCCESSFULLY: 'Aggiornamento diritti di rete riuscito!',
	LIST_VIEW_UPDATED: 'Vista elenco endpoint aggiornata',
	NETWORK_ENTITLEMENT_UPDATING: 'Aggiornamento diritti di rete in corso. Attendere',
	LIST_VIEW_UPDATING: 'Aggiornamento elenco endpoint in corso. Attendere',
	NEW_NETWORK_ENTITLEMENT: 'Nuovi diritti di rete',
	NEW_NETWORK_ENTITLEMENT_DETAILS: 'Dettagli nuovi diritti di rete',
	TABLE_COLUMNS: 'Scegliere il numero di colonne da mostrare nella tabella.',
	TABLE_COLUMNS_OR: 'Scegliere il numero di colonne da mostrare nella tabella o',
	COLUMN: 'Colonna',
	COLUMNS: 'Colonne',
	SELECT: 'Seleziona',
	DATA_TYPE: 'tipo di dati',
	ADVANCED_SEARCH: 'Ricerca avanzata',
	SEARCH_RESULTS: 'Risultati ricerca',
	SUBMIT: 'Invia',
	SEARCH_PARAMETER: 'Parametro di ricerca',
	ADD_ANOTHER_SEARCH_PARAMETER: 'Aggiungi un altro parametro di ricerca',
	STATUS_DATE_RANGE: 'Intervallo date stato',
	SIM_STATUS_STATE: 'Stato SIM (stato)',
	NEW_PARAMETER_ACTION: 'E / O',
	PLEASE_SELECT_VALUES_TO_FILTER_ENDPOINTS: 'Selezionare tra i parametri disponibili, quindi inserire i criteri per filtrare gli endpoint.',
	RESET_SEARCH: 'Reimposta ricerca',
	QUICK_SEARCH: 'Ricerca rapida',
	ORIGINAL_SETTINGS: 'Ripristina le impostazioni originali',
	MONTH: 'Mese',
	DAY: 'Giorno',
	REFRESH_ENDPOINTS_PAGE: 'Aggiorna tabella/pagina endpoint',
	CLEAR_SELECTED_FILTERS_TOOLTIP: 'Cancella filtri selezionati',
	SELECT_FILTERS_TOOLTIP: 'Seleziona filtri',
	EDIT_TABLE_PARAMETERS_TOOLTIP: 'Modifica parametri tabella',

	REPORT_DATE: 'Data',

	START_NEW_SEARCH: 'Avvia nuova ricerca',
	SUCCESS: 'Riuscito',
	FAILED: 'Non riuscito',
	RESULT_ACTION: 'Azione',
	REPORT_DETAILS: 'Dettagli report',
	REPORT_LIST: 'Elenco di report in',
	VIEW_DETAILS: 'Visualizza dettagli',
	DOWNLOAD_PDF: 'Download',
	FILE_NAME: 'Nome file',
	BATCH_FILES: 'File batch',
	BATCH_FILE_NAME: 'Nome file batch',
	BATCH_FILE_ACTIONS: 'Azioni file batch',
	BATCH_FILE_UPLOAD: 'Azioni in blocco',
	CHANGE_SIM_STATE: 'Cambia stato SIM',
	SELECT_SIM_STATE: 'Seleziona stato SIM',
	SIMS_SELECTED: 'SIM selezionate',
	SIM_SELECTED: 'SIM selezionata',
	ONCE_YOU_INITIATE_CHANGE: 'Una volta avviato questo cambiamento, le SIM selezionate saranno',
	PROCEED_ACTION: 'Continuare?',
	CAN_SCHEDULE: 'Oppure è possibile pianificare il cambiamento per',
	CURRENT_STATES: 'Stati correnti',
	SCHEDULE_SUCCESSFUL: 'Pianificazione cambiamento di stato di SIM multiple riuscita.',
	SIM_STATE_CHANGE_FOR: 'Cambiamento stato SIM per',
	SIMS_IS_SUCCESSFULLY_SCHEDULED_FOR: 'Le SIM sono state pianificate per',
	SIMS_SUCCESSFULLY_REQUESTED: 'Richiesta cambiamento di stato SIM riuscita.',
	MULTIPLE_SIM_STATE_CHANGE_SCHEDULE_ERROR: 'Pianificazione cambiamento di stato di SIM multiple non riuscita.',
	MULTIPLE_SIM_STATE_CHANGE_ERROR: 'Richiesta cambiamento di stato di SIM multiple non riuscita.',
	SELECTED_TIP: 'Suggerimento: Se si sono selezionate SIM con stati diversi, sarà possibile impostare solo uno stato disponibile per tutte le SIM scelte.',
	VIEW_RULES: 'Visualizza regole cambiamento di stato per questo account',
	BATCH_FILE_UPLOADING: 'Caricamento di file in batch',
	DATE_UPLOADED: 'Data caricamento',
	SIMPLE_SEARCH: 'Ricerca semplice',
	NUMBER_OF_COLUMNS: 'Numero di colonne',
	SELECT_SEARCH_PARAMETER: 'Seleziona parametro di ricerca',

	DROPDOWN_BACK_TO_BUSINESS: 'Ritorna a Commerciale',
	'DROPDOWN_CONTACT_AT&T': 'Contatta AT&T IoT',
	DROPDOWN_MC: 'IoT Console',
	DROPDOWN_M2X: 'M2X',
	DROPDOWN_FD: 'Progettazione flussi',
	DROPDOWN_SK: 'Starter kit IoT',
	DROPDOWN_DP: 'Piani dati IoT',
	MY_INFORMATION: 'Informazioni utente',
	MY_APPLICATIONS: 'Applicazioni utente',
	TOPBAR_IDENTITY: 'AT&T IoT Identity',
	ERROR: 'Errore',
	PAGE_NOT_FOUND_MESSAGE: 'Impossibile trovare la pagina richiesta.',
	'RETURN TO HOME PAGE': 'RITORNA ALLA HOME PAGE',
	INTERNAL_SERVER_ERROR: 'Errore interno server',
	ACTIVE_ENDPOINT_PROFILE: 'PROFILO ENDPOINT ATTIVO',
	CREATE_OR_EDIT: 'Crea o modifica etichetta personalizzata per questo endpoint',
	CREATE_CUSTOM_LABELS: 'Crea etichette personalizzate',
	SNAP: 'Aggancia',
	SNAP_ERROR_MESSAGE: 'Si è verificato un errore sconosciuto. Se il problema persiste, rivolgersi all’assistenza tecnica.',

	// report detail definitions
	REPORT_DETAIL_DEFINITIONS: 'COMUNICA DEFINIZIONI DETTAGLI',
	REPORT_DETAIL_DEFINITIONS_MODAL_TITLE: 'Comunica definizioni dettagli',
	REPORT_DETAIL_DEFINITIONS_DESC: 'Selezionare il pulsante indicato di seguito per visualizzare tutte le definizioni dettagli report.',
	VIEW_DEFINITIONS_BUTTON: 'VISUALIZZA DEFINIZIONI',

	// definition types
	'DATA SESSIONS DETAIL DEFINITION': 'Definizione dettagli sessioni dati',
	'IMSI CHARGE DETAIL XML FIELDS': 'Campi XML dettagli addebiti IMSI',
	'IMSI DETAIL DEFINITIONS': 'Definizioni dettagli IMSI',
	'LBS USAGE DETAILS': 'Dettagli utilizzo LBS',
	'DATA LAGG DETAIL DEFINITIONS': 'Definizioni dettagli dati Lagg',
	'SMS CALL DETAIL DEFINITIONS': 'Definizioni dettagli chiamate SMS',
	'TAX DETAIL DEFINITIONS': 'Definizioni dettagli imposte',
	'SIM ACTIVITY DETAIL DEFINITION': 'Definizione dettagli attività SIM',

	// Data Lagg Detail Definitions - KEYS -------------
	CUST_ID_KEY: 'CUST_ID',
	IMSI_KEY: 'IMSI',
	ICCID_KEY: 'ICCID',
	MSISDN_KEY: 'MSISDN',
	ON_NET_KB_USAGE_KEY: 'ON_NET_KB_USAGE',
	ROAMING_KB_USAGE_KEY: 'ROAMING_KB_USAGE',
	CAN_KB_USAGE_KEY: 'CAN_KB_USAGE',
	INT_KB_ROUNDED_KEY: 'INT_KB_ROUNDED',
	TOTAL_KB_USAGE_KEY: 'TOTAL_KB_USAGE',

	// Data Lagg Detail Definitions - DESCRIPTIONS
	CUST_ID_DESC: 'ID cliente EOD.',
	IMSI_DESC: 'Identificativo internazionale stazione mobile. Si tratta del numero utilizzato dalla rete per l’identificazione dell’utente.',
	ICCID_DESC: 'Identificativo scheda circuito integrato. Si tratta del numero stampato sulla scheda SIM.',
	MSISDN_DESC: 'Numero ISDN stazione mobile (numero telefonico) assegnato a tale SIM.',
	ON_NET_KB_USAGE_DESC: 'Utilizzo totale in rete (KB)',
	ROAMING_KB_USAGE_DESC: 'Utilizzo totale interno fuori rete (KB).',
	CAN_KB_USAGE_DESC: 'Utilizzo totale Canada (KB).',
	INT_KB_ROUNDED_DESC: 'Utilizzo totale internazionale (KB).',
	TOTAL_KB_USAGE_DESC: 'Utilizzo totale (KB).',

	// Data Sessions Detail Definition - KEYS ----------------
	IMPORT_DATE_KEY: 'Data importazione',
	// IMSI - use already defined key-desc
	// ICCID - use already defined key-desc
	GGSN_IP_KEY: 'GGSN IP',
	SGSN_IP_KEY: 'SGSN IP',
	APN_NAME_KEY: 'NOME APN',
	PDP_IP_KEY: 'PDP IP',
	UPLINK_VOLUME_KEY: 'VOLUME UPLINK',
	DOWNLINK_VOLUME_KEY: 'VOLUME DOWNLINK',
	TRANSACTION_START_TIME_KEY: 'ORA AVVIO OPERAZIONE',
	DURATION_KEY: 'DURATA',
	SERVED_MSISDN_KEY: 'MSISDN SERVITO',
	BID_KEY: 'BID',
	SERVING_LOCATION_KEY: 'UBICAZIONE SERVIZIO',
	NETWORK_KEY: 'RETE',
	ROUNDING_DATE_KEY: 'DATA ARROTONDAMENTO',

	// Data Sessions Detail Definition - DESCRIPTIONS
	IMPORT_DATE_DESC: 'Data e ora in cui EOD ha ricevuto questo record. Viene utilizzato per determinare la data di fatturazione applicata a questo record.',
	// IMSI - use already defined key-desc
	// ICCID - use already defined key-desc
	GGSN_IP_DESC: 'Nodo supporto GPRS gateway. Il dispositivo che si trova tra la rete mobile PLMN e le reti esterne al fine di fornire un border gateway per, ad esempio, sicurezza e contabilità.',
	SGSN_IP_DESC: 'Nodo supporto GPRS. Dispositivo che si trova allo stesso livello gerarchico del centro di commutazione mobile/registro dell’ubicazione dei visitatori (MSC/VLR, mobile switching center/visitor location register) per gestire la mobilità e il collegamento logico ai terminali mobili.',
	APN_NAME_DESC: 'Nome dell’APN utilizzato da questo dispositivo per questa operazione.',
	PDP_IP_DESC: 'IP assegnato al dispositivo al momento dell’operazione.',
	UPLINK_VOLUME_DESC: 'Numero di byte (in ottetti) passati dal dispositivo per questo record dettagli.',
	DOWNLINK_VOLUME_DESC: 'Numero di byte (in ottetti) passati al dispositivo per questo record dettagli.',
	TRANSACTION_START_TIME_DESC: 'L’ora di inizio effettiva dell’operazione. Questa data sarà diversa dalla “Data importazione” e dalla “Data di routing” a seconda di quando il record è stato ricevuto da EOD.',
	DURATION_DESC: 'Il tempo (in secondi) per tale record dati.',
	SERVED_MSISDN_DESC: 'Numero ISDN stazione mobile (numero telefonico) assegnato a tale SIM.',
	BID_DESC: 'Identificativo fatturazione. Questo è utilizzato da EOD per determinare il mercato in cui l’operazione ha avuto luogo.',
	SERVING_LOCATION_DESC: 'Nome dell’ubicazione dove si è verificato questo record dati.',
	NETWORK_DESC: 'Cingular, roaming/fuori rete, internazionale, o Canada.',
	ROUNDING_DATE_DESC: 'La data utilizzata da EOD per determinare con quali altri record questo record sarà raggruppato per calcolare l’arrotondamento giornaliero in kilobyte.',
	SESSION_HISTORY_DCP_NOT_APPLICABLE: 'Non applicabile.',

	// IMSI Charge Detail XML Fields - KEYS AND DESC ------------
	CUSTOMER_KEY: 'CLIENTE',
	CUSTOMER_DESC: 'Prefisso cliente',
	STARTCYCLE_KEY: 'STARTCYCLE',
	STARTCYCLE_DECS: 'Inizio ciclo di fatturazione',
	ENDCYCLE_KEY: 'ENDCYCLE',
	ENDCYCLE_DESC: 'Fine ciclo di fatturazione',
	WCO_KEY: 'WCO',
	WCO_DESC: 'Offerta di connettività wireless',
	EQUIPMENTORDERS_KEY: 'EQUIPMENTORDERS',
	EQUIPMENTORDERS_DESC: 'Ordinazione attrezzature',
	ACCOUNTOCC_KEY: 'ACCOUNTOCC',
	ACCOUNTOCC_DESC: 'Altri addebiti e crediti a livello di account (solo imposte e supplementi)',
	ACCOUNTTAXES_KEY: 'ACCOUNTTAXES',
	ACCOUNTTAXES_DESC: 'Imposte e canoni dalla PA (solo imposte) a livello di account',
	// ICCID - REUSE
	// IMSI - REUSE
	// MSISDN -REUSE
	RATEPLAN_KEY: 'RATEPLAN',
	RATEPLAN_DESC: 'Piano tariffario utilizzato per la fatturazione.',
	ACTIVATIONSTATE_KEY: 'ACTIVATIONSTATE',
	ACTIVATIONSTATE_DESC: 'Stato del dispositivo.',
	CUSTFILED1_KEY: 'CUSTFILED1',
	CUSTFILED1_DESC: 'Campo cliente 1',
	CUSTFILED2_KEY: 'CUSTFILED2',
	CUSTFILED2_DESC: 'Campo cliente 2',
	CUSTFILED3_KEY: 'CUSTFILED3',
	CUSTFILED3_DESC: 'Campo cliente 3',
	CUSTFILED4_KEY: 'CUSTFILED4',
	CUSTFILED4_DESC: 'Campo cliente 4',
	CUSTFILED5_KEY: 'CUSTFILED5',
	CUSTFILED5_DESC: 'Campo cliente 5',
	ATTPOINTER_KEY: 'ATTPOINTER',
	ATTPOINTER_DESC: 'Puntatore interno',
	CUSTPOINTER_KEY: 'CUSTPOINTER',
	CUSTPOINTER_DESC: 'Puntatore cliente',
	DATAMRC_KEY: 'DATAMRC',
	DATAMRC_DESC: 'MRC dati',
	DATAUSAGE_KEY: 'DATAUSAGE',
	DATAUSAGE_DESC: 'Somma di tutti gli addebiti per utilizzo dati',
	SMSMRC_KEY: 'SMSMRC',
	SMSMRC_DESC: 'SMS MRC',
	SMSUSAGE_KEY: 'SMSUSAGE',
	SMSUSAGE_DESC: 'Somma di tutti gli addebiti per utilizzo SMS, ad esclusione dell’addebito SMS in blocco',
	VOICEMRC_KEY: 'VOICEMRC',
	VOICEMRC_DESC: 'Vocale MRC',
	VOICEUSAGE_KEY: 'VOICEUSAGE',
	VOICEUSAGE_DESC: 'Somma di tutti gli addebiti per utilizzo vocale',
	DEVICEACTIVITYFEE_KEY: 'DEVICEACTIVITYFEE',
	DEVICEACTIVITYFEE_DESC: 'Canoni dispositivo (canoni di attivazione, disattivazione, riattivazione, cambiamento di piano una tantum)',
	OCC_KEY: 'OCC',
	OCC_DESC: 'Altri addebiti e crediti a livello di abbonato (solo imposte e supplementi)',
	TAXES_KEY: 'IMPOSTE',
	TAXES_DESC: 'Imposte e canoni della PA (solo imposte) a livello di abbonato',

	// IMSI DETAIL KEYS VALUE -----------------------
	// ICCID - REUSE
	// MSISDN -REUSE
	PLAN_CODE_KEY: 'PLAN_CODE',
	PLAN_CODE_DESC: 'Il Codice piano assegnato per cliente a ciascuna SIM.',
	ACT_ID_KEY: 'ACT_ID',
	ACT_ID_DESC: 'L’azione eseguita dal cliente per ciascuna SIM (1 = attivazione, 2 = disattivazione)',
	INCLUDED_USAGE_KEY: 'INCLUDED_USAGE',
	INCLUDED_USAGE_DESC: 'Utilizzo totale incluso (KB)',
	// ON_NET_KB_USAGE - REUSE
	// ROAMING_KB_USAGE - REUSE
	// CAN_KB_USAGE - REUSE
	// INT_KB_ROUNDED - REUSE
	// TOTAL_KB_USAGE - REUSE
	ACCESS_CHG_KEY: 'ACCESS_CHG',
	ACCESS_CHG_DESC: 'Addebito ricorrente mensile per l’accesso.',
	ACTIVATION_CHG_KEY: 'ACTIVATION_CHG',
	ACTIVATION_CHG_DESC: 'Addebito di attivazione.',
	BEGPERIOD_KEY: 'BEGPERIOD',
	BEGPERIOD_DESC: 'Inizio ciclo di fatturazione.',
	ENDPERIOD_KEY: 'ENDPERIOD',
	ENDPERIOD_DESC: 'Fine ciclo di fatturazione.',
	CUST_PREFIX_KEY: 'CUST_PREFIX',
	CUST_PREFIX_DESC: 'Identificativo cliente.',
	CUST_FIELD_1_KEY: 'CUST_FIELD_1',
	CUST_FIELD_1_DESC: 'Campo cliente 1.',
	CUST_FIELD_2_KEY: 'CUST_FIELD_2',
	CUST_FIELD_2_DESC: 'Campo cliente 2.',
	CUST_FIELD_3_KEY: 'CUST_FIELD_3',
	CUST_FIELD_3_DESC: 'Campo cliente 3.',
	CUST_FIELD_4_KEY: 'CUST_FIELD_4',
	CUST_FIELD_4_DESC: 'Campo cliente 4.',
	CUST_FIELD_5_KEY: 'CUST_FIELD_5',
	CUST_FIELD_5_DESC: 'Campo cliente 5.',
	INTERNAL_POINTER_KEY: 'PUNTATORE INTERNO',
	INTERNAL_POINTER_DESC: 'Puntatore interno AT&T.',
	CUSTOMER_POINTER_KEY: 'PUNTATORE CLIENTE',
	CUSTOMER_POINTER_DESC: 'Puntatore cliente.',

	// LBS USAGE DEFINITION KEYS-VALUE
	RECORDINGENTITY_KEY: 'RECORDINGENTITY',
	RECORDINGENTITY_DESC: 'Puntatore unico a record Agg. (Riservato per uso aggregatore).',
	LCSCLIENTEXTERNALID_KEY: 'LCSCLIENTEXTERNALID',
	LCSCLIENTEXTERNALID_DESC: 'LCS Client ExternalID (da non utilizzare per il momento).',
	TARGETIMSI_KEY: 'TARGETIMSI',
	TARGETIMSI_DESC: 'Identificativo internazionale stazione mobile. Si tratta del numero utilizzato dalla rete per identificare l’utente.',
	TARGETMSISDN_KEY: 'TARGETMSISDN',
	TARGETMSISDN_DESC: 'Individuazione MSDN in caso di SIM ATT, XXXXXXXXXX in caso di SIM non ATT (Numero ISDN stazione mobile “numero telefonico” assegnato a tale SIM).',
	RESULTCODE_KEY: 'RESULTCODE',
	RESULTCODE_DESC: 'Individuazione riuscita o meno. 0 = riuscita/ solo 0 e 1 utilizzati per l’avvio poiché gli Agg e Med resultCodes possono differire.',
	UTCRECORDOPENINGTIME_KEY: 'UTCRECORDOPENINGTIME',
	UTCRECORDOPENINGTIME_DESC: 'Da non utilizzare per il momento (registra ora UTC apertura).',
	INFORMATION_1_KEY: 'INFORMATION_1',
	INFORMATION_1_DESC: 'ID applicazione 16 cifre EAG AppID emesso (“1” in position2 = Technocom, “2” in position2 = LocAid Id)',
	INFORMATION_3_KEY: 'INFORMATION_3',
	INFORMATION_3_DESC: '(Orizzontale) accuratezza richiesta: Accuratezza desiderata in metri. <=800 è AGPS. (800 per denotare richiesta AGPS o 801 per denotare richiesta CID/ECID per individuazioni riuscite nonATT e 0 per quelle non riuscite (come da risposta)).',
	INFORMATION_4_KEY: 'INFORMATION_4 ',
	INFORMATION_4_DESC: '(Orizzontale) accuratezza restituita: Accuratezza in metri effettivamente restituita. (800 per denotare richiesta AGPS o 801 per denotare richiesta CID/ECID per individuazioni riuscite nonATT e 0 per quelle non riuscite (come da risposta)).',
	PART_DATE_KEY: 'PART_DATE',
	PART_DATE_DESC: 'Data immissione in file trasmissione CDR per Aggregatore (PST: AAAA-MM-GG HH:mm:ss)',

	// SMS CALL DEFIONITIONS - KEYS AND DESC
	// IMSI
	// SERVED MSISDN
	SMS_TYPE_KEY: 'TIPO SMS',
	SMS_TYPE_DESC: 'Originato da mobile (MO) or terminato in mobile (MT).',
	CALLED_NUMBER_KEY: 'NUMERO CHIAMATO',
	CALLED_NUMBER_DESC: 'Il numero composto.',
	START_TIME_KEY: 'ORA AVVIO',
	START_TIME_DESC: 'Data e ora in cui si è verificata l’operazione.',
	// NETWORK
	PLMN_KEY: 'PLMN',
	PLMN_DESC: 'L’elenco PLMN (Public Land Mobile Network, o rete mobile terrestre pubblica) è un identificativo di fatturazione utilizzato da EOD per determinare il mercato in cui si è verificata l’operazione.',
	CARRIER_KEY: 'VETTORE',
	CARRIER_DESC: 'Il proprietario del mercato mobile per la PLMN associata.',

	// SIM ACTIVITY DEFINITIONS - KEYS AND DESC
	// ICCID
	// MSISDN
	ACTION_DATE_KEY: 'ACTION_DATE',
	ACTION_DATE_DESC: 'Data e ora in cui è stata completata l’azione.',
	ACT_KEY: 'ACT',
	ACT_DESC: 'L’azione effettuata dal cliente per ciascuna SIM (ACTV = attivazione, KILL = disattivazione, CHNG = cambiamento profilo rete, RATE = cambiamento piano tariffario, CPPU = cambiamento località d’utilizzo primaria).',
	PLAN_CODE_KEY_2: 'PLAN_CODE',
	PLAN_CODE_DESC_2: 'L’alpha-code del piano utilizzato per questa operazione.',
	LOGIN_KEY: 'ACCESSO',
	LOGIN_DESC: 'Operazione associata all’utente.',

	// TAX DETAIL DEFINITIONS - KEYS AND DESC
	PPU_ADDRES_KEY: 'PPU_ADDRES',
	PPU_ADDRES_DESC: 'Località di utilizzo primario – Il luogo in cui il cliente utilizza principalmente i servizi.',
	SERVICE_KEY: 'SERVIZIO',
	SERVICE_DESC: 'Il prodotto o l’offerta soggetti a imposta.',
	INVOICE_SECTION_KEY: 'SEZIONE FATTURE',
	INVOICE_SECTION_DESC: 'TX – Canoni e imposte PA OC – Altri addebiti e crediti.',
	AUTHORITY_LEVEL_KEY: 'LIVELLO AUTORITÀ',
	AUTHORITY_LEVEL_DESC: 'Livello associato al testo della fattura F – federale S – statale Co – paese Ci – Città DCo – paese distretto DCi – città distretto',
	INVOICE_TEXT_KEY: 'TESTO FATTURA',
	INVOICE_TEXT_DESC: 'Testo associato all’addebito stampato sulla fattura dei clienti.',
	CHARGE_AMMOUNT_KEY: 'IMPORTO ADDEBITO',
	CHARGE_AMMOUNT_DESC: 'L’importo soggetto ad imposte.',
	AMMOUNT_KEY: 'IMPORTO',
	AMMOUNT_DESC: 'L’importo dell’imposta calcolata per un addebito soggetto a imposta associato.',
	BATCH_FORMATS: 'Formati batch',

	ACTIVATION: 'Attivazione',
	TERMINATION: 'Chiusura',
	NETWORK_ENTITLEMENT_CHANGE: 'Cambiamento diritti di rete',
	SIM_SWAP: 'Scambio SIM',
	CUSTOM_FIELD_UPDATES: 'Aggiornamenti campo personalizzato',
	CUSTOM_FIELD_VALUE: 'Valore campo personalizzato',
	BATCH_FILE_UPLOAD_SUCCESS: 'File batch ricevuti!',
	BATCH_FILE_UPLOAD_SUCCESS_MESSAGE: 'Le modifiche sono in sospeso in attesa del completamento degli aggiornamenti da parte del sistema',
	BATCH_FILE_SYSTEM_ERROR: 'Errore sistema file batch!',
	BATCH_FILE_SYSTEM_ERROR_MESSAGE: 'Si è verificato un problema durante l’elaborazione degli aggiornamenti. Riprovare più tardi.',
	TYPE_IN_OR_SELECT: 'Digitare un nuovo valore o selezionare l’opzione dall’elenco a discesa per cambiare il parametro specifico dell’endpoint.',

	SUBMITTED_STATUS: 'Inviato',
	SCHEDULED_STATUS: 'Pianificato',
	IN_PROGRESS_STATUS: 'In corso',
	COMPLETED_STATUS: 'Completato',
	'IN-PROGRESS_STATUS': 'In corso',
	COMPLETED: 'Completato',
	CANCELLED: 'Annullato',
	ERROR_STATUS: 'Errore',

	FILE_NAME_CANNOT_CONTAIN_SPACES: 'Il nome del file non può contenere spazi',
	DRAG_FILE_OR_CLICK_TO_UPLOAD: 'Trascinare il file o cliccare per caricare',
	FORBIDDEN_ACCESS: 'Accesso proibito',
	REQUEST_TIMED_OUT: 'Timeout della richiesta',
	TERMINATE_ACTION: 'Chiudi SIM',
	ACTIVATE_ACTION: 'Attiva SIM',
	SWAP_SIMS_ACTION: 'Scambia SIM',
	CUSTOM_FIELDS_ACTION: 'Aggiungi o aggiorna campi personalizzati',
	REQUIRED_CUSTOM_FIELD: 'È obbligatorio disporre di autorizzazioni elevate!',
	FIELD_IS_REQUIRED_ACTION: 'Solo gli amministratori possono modificare i tipi di campo personalizzati in quanto hanno un impatto sulla configurazione a livello di sistema. Contattare l’amministratore per aggiornare le opzioni dei campi personalizzati.',
	PERMANENT_CUSTOM_FIELD_DELETION: 'Eliminazione campo personalizzato permanente!',
	DELETING_THIS_CUSTOM_FIELD: 'Eliminando questo campo personalizzato saranno anche eliminati tutti i dati ad esso relativi! Questa azione non può essere annullata!',
	DELETE_FIELD: 'Elimina campo',

	WARNING: 'Avvertenza',
	ARE_YOU_SURE: 'Confermare l’operazione?',
	NETWORK_ENTITLEMENT_CHANGE_ACTION: 'Cambiamento diritti di rete SIM',
	WHEN_SAVING: 'Quando si salva il file, seguire il formato precedente.',
	EXAMPLE: 'Esempio',

	SELECT_NEW_STATE: 'Seleziona nuovo stato',
	403: '403',

	AUTOMATION_PROCESS: 'Processo automazione',
	BATCH_UPLOAD: 'Caricamento batch',
	DIAGRAM_COULD_NOT_BE: 'Non è stato possibile caricare il diagramma',
	LOADED_AT_THIS_TIME: 'al momento.',
	BATCH_ACTIONS: 'Azioni batch',
	BATCH_ACTION: 'Azione batch',
	BATCH_ACTIONS_SUBMITTED: 'Caricamento file per elaborazione riuscito',
	BATCH_ACTIONS_SCHEDULED: 'Caricamento file riuscito, pianificato per elaborazione',
	BATCH_ACTIONS_IN_PROGRESS: 'Elaborazione in corso',
	BATCH_ACTIONS_COMPLETED: 'Elaborazione file riuscita',
	BATCH_ACTIONS_ERROR: 'Si è verificato un errore durante l’elaborazione',
	BATCH_REPORTING: 'Report batch',
	BATCH_TEXT: 'Per l’elaborazione in batch dei cambiamenti, selezionare l’azione da eseguire e caricare il file txt batch. Sono disponibili modelli di esempio per la formattazione.',
	START_BATCH_PROCESS: 'Avvia processo batch',
	DOWNLOAD_TEMPLATE: 'Scarica file modello',
	AUTOMATION_RULES: 'Regole automazione',
	ACTION_FORMAT: 'Formato azione',
	VIEW_MORE_DETAILS: 'Visualizza più dettagli',
	UPLOAD_FILE_NOTE: 'Caricare qui i file per l’azione batch selezionata:',
	UPLOAD_FILE: 'Carica file',

	UNSUPPORTED_FILE_TYPE: 'Tipo di file non supportato',
	INVALID_FILE_NAME_FORMAT: 'Il formato del nome del file deve essere nomeAzione_MM-GG-AAAA_HH-mm-ss',
	DOWNLOAD_REPORT_FAILED: 'Download non riuscito, riprovare.',
	PLEASE_TRY_AGAIN: 'Riprovare.',

	// REPORT DETAIL DEFINITION
	REPORT_SOURCE: 'Sorgente report',
	DATA_SESSION_DETAIL_DEFINITION: 'Definizione dettagli sessione dati',
	IMSI_DETAIL_DEFINITION: 'Definizione dettagli IMSI',
	SMS_DETAIL_DEFINITION: 'Definizione dettagli SMS',
	SIM_DETAIL_DEFINITION: 'Definizione dettagli SIM',
	USAGE_DATA_DETAILS: 'Dettagli utilizzo dati',
	USAGE_SMS_DETAILS: 'Dettagli utilizzo SMS',
	POOLED_USAGE: 'Utilizzo in pool',
	USAGE_SUMMARY: 'Riepilogo utilizzo',
	SUBSCRIBER_SNAPSHOT: 'Istantanea abbonato',
	NEW_SUBSCRIBERS: 'Nuovi abbonati',
	TOP_DATA_USAGE: 'Utilizzo massimo dati',
	TOP_SMS_USAGE: 'Utilizzo massimo SMS',
	PROVOSIONING_METRIC: 'Metrica provisioning',

	// USAGE DATA DETAIL - KEYS AND DESC
	DATA_TRAFFIC_DETAIL_ID_KEY: 'ID dettagli traffico dati',
	DATA_TRAFFIC_DETAIL_ID_DESC: 'Identificativo univoco per record utilizzo dati',
	// 'ICCID_KEY'
	ICCID_DESC_SHORT: 'ICCID identificativo della SIM',
	// 'MSISDN_KEY'
	MSISDN_DESC_SHORT: 'MSISDN identificativo della SIM',
	// 'IMSI_KEY'
	IMSI_DESC_SHORT: 'IMSI identificativo della SIM',
	ACCOUNT_ID_KEY: 'ID account',
	ACCOUNT_ID_DESC: 'Numero ID account Jasper',
	BILLABLE_FLAG_KEY: 'Contrassegno fatturabile',
	BILLABLE_FLAG_DESC: 'Indicatore del traffico fatturabile sulla base dello stato della SIM',
	BILLING_CYCLE_KEY: 'Ciclo di fatturazione',
	BILLING_CYCLE_DESC: 'Ciclo di fatturazione in cui si è verificato l’utilizzo',
	SIM_STATE_KEY: 'Stato SIM',
	SIM_STATE_DESC: 'Stato assegnato alla SIM alla fine del periodo di riferimento',
	SERVICE_TYPE_KEY: 'Tipo di servizio',
	SERVICE_TYPE_DESC: 'Tipo di servizio wireless utilizzato (per es. dati, SMS MO, SMS MT, vocale)',
	ASSIGNED_RATE_PLAN_ID_KEY: 'ID piano tariffario assegnato',
	ASSIGNED_RATE_PLAN_ID_DESC: 'Piano tariffario assegnato alla SIM al momento della comunicazione dell’utilizzo',
	ASSIGNED_RATING_ZONE_ID_KEY: 'ID area tariffaria assegnata',
	ASSIGNED_RATING_ZONE_ID_DESC: 'Zona piano tariffario al quale è attribuito l’utilizzo sulla base del piano tariffario assegnato al momento della comunicazione dell’utilizzo',
	OFFPEAK_USAGE_FLAG_KEY: 'Contrassegno utilizzo fuori dalle ore di punta',
	OFFPEAK_USAGE_FLAG_DESC: 'Se l’utilizzo si è verificato fuori dalle ore di punta secondo quanto definito per l’account',
	RECORD_RECEIVED_DATE_KEY: 'Registra dati ricevuti',
	RECORD_RECEIVED_DATE_DESC: 'Data/ora in cui è stato ricevuto il record di contabilità',
	DATA_USAGE_RAW_TOTAL_KEY: 'Utilizzo dati - Totale non elaborato',
	DATA_USAGE_RAW_TOTAL_DESC: 'Utilizzo non arrotondato per il periodo, in byte',
	DATA_USAGE_RAW_UPLINK_KEY: 'Utilizzo dati - Uplink non elaborato',
	DATA_USAGE_RAW_UPLINK_DESC: 'Utilizzo non arrotondato per il periodo, byte uplink',
	DATA_USAGE_RAW_DOWNLINK_KEY: 'Utilizzo dati - Downlink non elaborato',
	DATA_USAGE_RAW_DOWNLINK_DESC: 'Utilizzo non arrotondato per il periodo, byte downlink',
	DATA_USAGE_ROUNDED_KEY: 'Utilizzo dati - Arrotondato',
	DATA_USAGE_ROUNDED_DESC: 'Utilizzo dati arrotondato per il periodo, in byte; il trattamento dell’arrotondamento è definito in base al piano tariffario',
	APN_DESC: 'Nome punto di accesso per la sessione',
	DEVICE_IP_ADDRESS_KEY: 'Indirizzo IP dispositivo',
	DEVICE_IP_ADDRESS_DESC: 'L’indirizzo IP assegnato al dispositivo per la sessione dati corrispondente',
	OPERATOR_NETWORK_KEY: 'Rete operatore',
	OPERATOR_NETWORK_DESC: 'La rete operatore visitata sulla quale si è verificato l’utilizzo',
	RECORD_OPEN_TIME_KEY: 'Registra ora apertura',
	RECORD_OPEN_TIME_DESC: 'Data/ora in cui è iniziata la sessione o la sessione parziale',
	SESSION_DURATION_KEY: 'Durata sessione',
	SESSION_DURATION_DESC: 'Durata della sessione dati, in secondi',
	RECORD_SEQUENCE_NUMBER_KEY: 'Numero sequenza record',
	RECORD_SEQUENCE_NUMBER_DESC: 'Numero di sequenza del record nella sessione principale se il record rappresenta una sessione parziale',
	CHARGINGID_KEY: 'ChargingID',
	CHARGINGID_DESC: 'Identificativo della sessione alla quale si applica un record utilizzo',
	SESSION_CLOSE_CAUSE_KEY: 'Causa chiusura sessione',
	SESSION_CLOSE_CAUSE_DESC: 'Codice numerico della ragione per cui è stata chiusa la sessione',
	TAP_CODE_KEY: 'Codice TAP',
	TAP_CODE_DESC: 'Codice TAP per la rete visitata',
	OPERATOR_ACCOUND_ID_KEY: 'ID account operatore',
	OPERATOR_ACCOUND_ID_DESC: 'ID utilizzato dall’operatore per l’identificazione di un account',
	RATE_PLAN_VERSION_ID_KEY: 'ID versione piano tariffario',
	RATE_PLAN_VERSION_ID_DESC: 'Versione specifica del piano tariffario attualmente utilizzato per la fatturazione',
	STREAM_ID_KEY: 'ID flusso',
	STREAM_ID_DESC: 'Identificativo numerico per descrivere diversi flussi di dati identificati da GGSN/PGW',
	CGI_KEY: 'CGI (Cell Global Identifier, identificativo globale cellula)',
	CGI_DESC: 'Parte delle informazioni sulla località descritte in TS29.274',
	SAI_KEY: 'SAI (Service Area Identifier, identificativo area servizio)',
	SAI_DESC: 'Parte delle informazioni sulla località descritte in TS29.274',
	RAI_KEY: 'RAI (Routing Area Identifier, identificativo area di routing)',
	RAI_DESC: 'Parte delle informazioni sulla località descritte in TS29.274',
	TAI_KEY: 'TAI (Tracking Area Identity, identità area rilevamento)',
	TAI_DESC: 'Parte delle informazioni sulla località descritte in TS29.274',
	ECGI_KEY: 'ECGI(E-UTRAN CGI)',
	ECGI_DESC: 'Parte delle informazioni sulla località descritte in TS29.274',
	LAI_KEY: 'LAI (Location Area Identifier, identificativo area posizione)',
	LAI_DESC: 'Parte delle informazioni sulla località descritte in TS29.274',
	SERVING_SGSN_KEY: 'SGSN di servizio',
	SERVING_SGSN_DESC: 'Indirizzo dello switch di servizio',
	CALL_TECHNOLOGY_TYPE_KEY: 'Tipo tecnologia chiamata',
	CALL_TECHNOLOGY_TYPE_DESC: 'Indicatore numerico per l’identificazione della tecnologia di accesso radio impiegata per questo utilizzo',

	// USAGE SMS DETAIL - KEYS AND DESC
	SMS_TRAFFIC_DETAIL_ID_KEY: 'ID dettagli traffico dati',
	SMS_TRAFFIC_DETAIL_ID_DESC: 'Identificativo univoco per record utilizzo dati',
	// 'ICCID_KEY'
	// 'ICCID_DESC'
	// 'MSISDN_KEY'
	// 'MSISDN_DESC'
	// 'IMSI_KEY'
	// 'IMSI_DESC'
	// 'ACCOUNT_ID_KEY'
	// 'ACCOUNT_ID_DESC'
	// 'BILLABLE_FLAG_KEY'
	// 'BILLABLE_FLAG_DESC'
	// 'BILLING_CYCLE_KEY'
	// 'BILLING_CYCLE_DESC'
	// 'SIM_STATE_KEY'
	// 'SIM_STATE_DESC'
	// 'SERVICE_TYPE_KEY'
	// 'SERVICE_TYPE_DESC'
	// 'ASSIGNED_RATE_PLAN_ID_KEY'
	// 'ASSIGNED_RATE_PLAN_ID_DESC'
	// 'ASSIGNED_RATING_ZONE_ID_KEY'
	// 'ASSIGNED_RATING_ZONE_ID_DESC'
	RECORD_OPEN_TIME_SMS_KEY: 'Registrare ora apertura',
	RECORD_OPEN_TIME_SMS_DESC: 'Data/ora in cui è stato inviato un messaggio SMS MO; data/ora in cui è stato ricevuto un messaggio MT',
	// 'RECORD_RECEIVED_DATE_KEY': 'Data ricezione record',
	// 'RECORD_RECEIVED_DATE_DESC': 'Data/ora in cui è stato ricevuto il record di contabilità',
	NETWORK_NODE_KEY: 'Nodo rete',
	NETWORK_NODE_DESC: 'Indirizzo del nodo di rete che gestisce il messaggio',
	FROM_KEY: 'Da',
	FROM_DESC: 'MSISDN o codice breve sorgente',
	TO_KEY: 'A',
	TO_DESC: 'MSISDN o codice breve destinazione',
	// 'OPERATOR_NETWORK_KEY': 'Rete operatore',
	// 'OPERATOR_NETWORK_DESC': 'La rete operatore visitata sulla quale si è verificato l’utilizzo',
	// 'TAP_CODE_KEY': 'Codice TAP',
	// 'TAP_CODE_DESC': 'Codice TAP per la rete visitata',
	// 'OPERATOR_ACCOUND_ID_KEY': 'ID account operatore',
	// 'OPERATOR_ACCOUND_ID_DESC': 'ID utilizzato dall’operatore per l’identificazione di un account',
	// 'RATE_PLAN_VERSION_ID_KEY': 'ID versione piano tariffario',
	// 'RATE_PLAN_VERSION_ID_DESC': 'Versione specifica del piano tariffario
	// utilizzata in questo momento per la fatturazione',

	// POOLED USAGE - KEYS AND DESC
	// 'ACCOUNT_ID_KEY'
	// 'ACCOUNT_ID_DESC
	// 'BILLING_CYCLE_KEY' />,
	// 'BILLING_CYCLE_DESC' />
	INVOICE_ID_KEY: 'ID fattura',
	INVOICE_ID_DESC: 'ID della fattura alla quale si applicano gli addebiti',
	RATE_PLAN_ID_KEY: 'ID piano tariffario',
	RATE_PLAN_ID_DESC: 'ID del piano tariffario assegnato alla SIM alla fine del periodo di riferimento',
	RATING_ZONE_ID_KEY: 'ID area tariffaria',
	RATING_ZONE_ID_DESC: 'Area tariffaria (definita nel piano tariffario) in cui si è verificato l’utilizzo',
	TOTAL_DATA_USAGE_CHARGES_KEY: 'Addebiti totali utilizzo dati',
	TOTAL_DATA_USAGE_CHARGES_DESC: 'Addebiti totali utilizzo dati in questa area tariffaria, oltre a eventuale utilizzo dati in pool incluso nel canone di abbonamento',
	TOTAL_SMS_MO_USAGE_CHARGES_KEY: 'Addebiti totali per utilizzo SMS MO',
	TOTAL_SMS_MO_USAGE_CHARGES_DESC: 'Addebiti totali per utilizzo SMS MO in questa area tariffaria, oltre a eventuale utilizzo di SMS in pool incluso nel canone di abbonamento',
	TOTAL_SMS_MT_USAGE_CHARGES_KEY: 'Addebiti totali per utilizzo SMS MT',
	TOTAL_SMS_MT_USAGE_CHARGES_DESC: 'Addebiti totali per utilizzo SMS MT in questa area tariffaria, oltre a eventuale utilizzo di SMS in pool incluso nel canone di abbonamento',
	TOTAL_SMS_USAGE_CHARGES_KEY: 'Addebiti totali utilizzo SMS',
	TOTAL_SMS_USAGE_CHARGES_DESC: 'Addebiti totali per utilizzo SMS in questa area tariffaria, oltre a eventuale utilizzo di SMS in pool incluso nel canone di abbonamento',
	TOTAL_VOICE_MO_USAGE_CHARGES_KEY: 'Addebiti totali utilizzo vocale MO',
	TOTAL_VOICE_MO_USAGE_CHARGES_DESC: 'Addebiti totali utilizzo vocale MO per questa SIM in questa area tariffaria, oltre a eventuale utilizzo vocale incluso nel canone di abbonamento',
	TOTAL_VOICE_MT_USAGE_CHARGES_KEY: 'Addebiti totali utilizzo vocale MT',
	TOTAL_VOICE_MT_USAGE_CHARGES_DESC: 'Addebiti totali utilizzo vocale MT per questa SIM in questa area tariffaria, oltre a eventuale utilizzo vocale incluso nel canone di abbonamento',
	TOTAL_USAGE_CHARGES_KEY: 'Addebiti totali utilizzo',
	TOTAL_USAGE_CHARGES_DESC: 'Addebiti totali per l’utilizzo in questa area tariffaria oltre a eventuale utilizzo incluso nel canone di abbonamento',

	// USAGE SUMMARY - KEYS AND DESC
	// 'ACCOUNT_ID_KEY'
	// 'ACCOUNT_ID_DESC'
	OPERATOR_ID_KEY: 'ID operatore',
	OPERATOR_ID_DESC: 'Numero ID operatore Jasper',
	// 'BILLING_CYCLE_KEY'
	// 'BILLING_CYCLE_DESC'
	// 'ICCID_KEY'
	// 'ICCID_DESC'
	// 'IMSI_KEY'
	// 'IMSI_DESC'
	// 'MSISDN_KEY'
	// 'MSISDN_DESC'
	TOTAL_DATA_USAGE_KEY: 'Utilizzo dati totale',
	TOTAL_DATA_USAGE_DESC: 'Utilizzo dati totale per il ciclo di fatturazione per questo dispositivo',
	TOTAL_VOICE_USAGE_KEY: 'Utilizzo vocale totale',
	TOTAL_VOICE_USAGE_DESC: 'Utilizzo vocale totale per il ciclo di fatturazione per questo dispositivo',
	TOTAL_SMS_USAGE_KEY: 'Utilizzo SMS totale',
	TOTAL_SMS_USAGE_DESC: 'Utilizzo SMS totale per il ciclo di fatturazione per questo dispositivo',
	TOTAL_CSD_USAGE_KEY: 'Utilizzo CSD totale',
	TOTAL_CSD_USAGE_DESC: 'Utilizzo CSD totale per il ciclo di fatturazione per questo dispositivo',
	RESERVED_FIELD_KEY: 'Campo riservato',
	RESERVED_FIELD_DESC: 'Riservato per uso futuro',
	DEVICE_ID_KEY: 'ID dispositivo',
	DEVICE_ID_DESC: 'Campo personalizzato a disposizione degli operatori',
	MODEM_ID_KEY: 'ID modem',
	MODEM_ID_DESC: 'Campo personalizzato a disposizione degli operatori',
	END_CONSUMER_ID_KEY: 'ID consumatore finale',
	END_CONSUMER_ID_DESC: 'Identificativo del consumatore finale',
	USAGE_CUSTOMER_KEY: 'Cliente',
	USAGE_CUSTOMER_DESC: 'Nome cliente',
	CUSTOM1_KEY: 'Personalizzato 1',
	CUSTOM1_DEVICE_DESC: 'Campo personalizzato a livello di dispositivo 1',
	CUSTOM2_KEY: 'Personalizzato 2',
	CUSTOM2_DEVICE_DESC: 'Campo personalizzato a livello di dispositivo 1',
	CUSTOM3_KEY: 'Personalizzato 3',
	CUSTOM3_DEVICE_DESC: 'Campo personalizzato a livello di dispositivo 1',

	// SUBSCRIBER SNAPSHOT - KEYS AND DESC
	SUBSCRIBER_SIM_STATE_KEY: 'Stato SIM',
	SUBSCRIBER_SIM_STATE_DESC: 'Stato SIM alla fine del periodo di riferimento',
	// 'RATE_PLAN_ID_KEY'
	SUBSCRIBER_RATE_PLAN_ID_DESC: 'Piano tariffario assegnato alla SIM alla fine del periodo di riferimento',
	SUBSCRIBER_CUSTOMER_KEY: 'Cliente',
	SUBSCRIBER_CUSTOMER_DESC: 'Cliente assegnato alla SIM alla fine del periodo di riferimento',
	// 'DEVICE_ID_KEY',
	SUBSCRIBER_DEVICE_ID_DESC: 'Valore alla fine del periodo di riferimento',
	// 'MODEM_ID_KEY'

	SUBSCRIBER_MODEM_ID_DESC: 'Valore alla fine del periodo di riferimento',
	COMMUNICATION_PLAN_ID_KEY: 'ID piano di comunicazione',
	COMMUNICATION_PLAN_ID_DESC: 'Valore alla fine del periodo di riferimento',
	INITIAL_ACTIVATION_DATE_KEY: 'Data di attivazione iniziale',
	INITIAL_ACTIVATION_DATE_DESC: 'Data e ora di quando la SIM è stata attivata per la prima volta nell’account corrente',
	CUSTOM_SIM_DESC: 'Campo personalizzato a livello di SIM a disposizione degli account',
	LINE_PAYMENT_STATUS_KEY: 'Stato pagamento linea',
	LINE_PAYMENT_STATUS_DESC: 'I valori possibili sono Corrente, Sospeso, Annullato, Annullamento definitivo',
	OPERATOR_ACCOUNT_ID_KEY: 'ID account operatore',
	OPERATOR_ACCOUNT_ID_DESC: 'ID utilizzato dall’operatore per l’identificazione di un account',
	CUSTOM4_KEY: 'Personalizzato 4',
	CUSTOM5_KEY: 'Personalizzato 5',
	CUSTOM6_KEY: 'Personalizzato 6',
	CUSTOM7_KEY: 'Personalizzato 7',
	CUSTOM8_KEY: 'Personalizzato 8',
	CUSTOM9_KEY: 'Personalizzato 9',
	CUSTOM10_KEY: 'Personalizzato 10',

	ICCID_DESC_SHORT2: 'ICCID dispositivo',
	IMSI_DESC_SHORT2: 'IMSI dispositivo',
	RATE_PLAN_KEY: 'Rateplan',
	RATE_PLAN_DESC: 'EOD plan_code, ICCID centro controllo vuoto',
	SIM_STATE_KEY2: 'Simstate',
	SIM_STATE_DESC2: 'ATTIVO, INATTIVO, ecc., se disponibile',
	BEGBILLINGCYCLEDATE_KEY: 'Begbillcycledate',
	BEGBILLINGCYCLEDATE_DESC: 'Data iniziale del ciclo di fatturazione se EOD, vuoto se Centro controllo',
	ENDBILLINGCYCLEDATE_KEY: 'Endbillcycledate',
	ENDBILLINGCYCLEDATE_DESC: 'Data finale del ciclo di fatturazione se EOD, vuoto se Centro controllo',
	TOTALDATAUSAGE_KEY: 'Totaldatausage',
	TOTALDATAUSAGE_DESC: 'Utilizzo dati periodo in kilobyte',
	TOTALINTERNATIONALUSAGE_KEY: 'Totalinternationalusage',
	TOTALINTERNATIONALUSAGE_DESC: 'Utilizzo dati internazionali periodo in kilobyte se EOD, vuoto se Centro controllo',
	CUSTOMERNAME_KEY: 'Customername',
	CUSTOMERNAME_DESC: 'Nome del cliente',
	CUSTOMFIELD1_KEY: 'Customfield1',
	CUSTOMERNAME1_DESC: 'Può essere popolato da EOD e dal Centro controllo',
	CUSTOMFIELD2_KEY: 'Customfield2',
	CUSTOMERNAME2_DESC: 'Può essere popolato da EOD e dal Centro controllo',
	CUSTOMFIELD3_KEY: 'Customfield3',
	CUSTOMERNAME3_DESC: 'Può essere popolato da EOD e dal Centro controllo',
	CUSTOMFIELD4_KEY: 'Customfield4',
	CUSTOMERNAME4_DESC: 'DeviceID EOD o Centro controllo',
	CUSTOMFIELD5_KEY: 'Customfield5',
	CUSTOMERNAME5_DESC: 'ModemID EOD o Centro controllo',
	M2MPLATFORM_KEY: 'M2MPlatform',
	M2MPLATFORM_DESC: '“EOD” o “Centro controllo”',
	BILLINGCYCLE_KEY: 'Billingcycle',
	BILLINGCYCLE_DESC: 'Campo Control Center JPO da popolare',
	CUSTOMERID_KEY: 'Customerid',
	CUSTOMERID_DESC: 'Per EOD, prefisso cliente; per Control Center, ID cliente',
	PROVISIONINGACTIVITY_KEY: 'ProvisioningActivity ',
	PROVISIONINGACTIVITY_DESC: 'Una tra “attivazione”, “inattivo”, “chiudi”, “changeNetworkEntitlement”, “changeRatePlan”, “updateCustomFields”',
	COUNT_KEY: 'Conteggio',
	COUNT_DESC: 'Numero di volte che è stata chiamata l’attività di provisioning',
	SUCCESS_KEY: 'Riuscito',
	SUCCESS_DESC: 'Numero di volte che l’attività di provisioning è stata chiamata con successo',
	FAILURE_KEY: 'Errore',
	FAILURE_DESC: 'Numero di volte che l’attività di provisioning è stata chiamata senza successo',
	AVGTIMEMS_KEY: 'AvgTimeMs',
	AVGTIMEMS_DESC: 'Numero di millisecondi necessari in media per la chiamata di provisioning',
	LONGESTTIMEMS_KEY: 'LongestTimeMs',
	LONGESTTIMEMS_DESC: 'Numero di millisecondi impiegati dalla chiamata più lenta dell’attività di provisioning',
	SHORTESTTIMEMS_KEY: 'ShortestTimeMs',
	SHORTESTTIMEMS_DESC: 'Numero di millisecondi impiegati dalla chiamata più veloce dell’attività di provisioning',

	REPORT_SOURCE_DESC: 'Selezionare la sorgente del report per vedere diverse definizioni',

	SIM_SWAP_SUCCESSFUL: 'Scambio SIM riuscito',
	SIM_SWAP_IN_PROGRESS: 'Scambio SIM in corso',
	REFRESH_PENDING_STATUS: 'Aggiorna l’endpoint per verificare se lo stato è cambiato',
	TEMPLATE_NOT_AVAILABLE: 'Modello non disponibile.',
	CUSTOM_FIELDS_UPDATE_SUCCESSFUL: 'Aggiornamento campi personalizzati completato',
	CUSTOM_FIELDS_UPDATE_IN_PROGRESS: 'Aggiornamento campi personalizzati in corso',
	IOT_APPS: 'APP IOT',
	INVOICES_DATE: 'Data fatture',
	PLATFORM: 'Piattaforma',
	FREQUENCY: 'Frequenza',
	M2M_ACC_ID: 'ID account M2M',
	REPORT: 'Report',

	// Onboarding
	ADD_NEW_PLATFORM: 'Aggiungi nuova piattaforma',
	BUSINESS_NAME: 'Nome azienda',
	ADMINISTRATOR: 'Amministratore',
	MCC: 'MCC',
	MNC: 'IoT Console (IOT-C)',
	OPERATOR: 'Operatore',
	POD: 'POD',
	RATE_PLANS: 'Piani tariffari',
	RATE_PLAN_NAME: 'Nome piano tariffario',
	RATE_PLAN_ID: 'Piano tariffario',
	RATE_PLAN_ID_PLAN: 'ID piano tariffario',
	COMMUNICATION_PLANE_NAME: 'Nome piano di comunicazione',
	ADD_ANOTHER_RATE_PLAN: 'Aggiungi un altro piano tariffario',
	ADD_ANOTHER_COMMUNICATION_PLAN: 'Aggiungi un altro piano di comunicazione',
	ADD_ANOTHER_PROFILE_NAME: 'Aggiungi un altro nome profilo',
	SIM_STATE_MAPPING: 'Mappature stato SIM',
	ADD_RATE_PLAN: 'Aggiungi piano tariffario',
	ACC_RULE: 'Regola ACC',
	MC_RULE: 'Regola IoT-C',
	WHAT_IS_THIS_TITLE: 'Cos’è questo?',
	PLAN_PROFILES: 'Profili piano',
	COMPANY: 'Azienda',
	COMPANY_NAME: 'Nome azienda',
	COMPANY_DOMAIN: 'Dominio azienda',
	PHONE_NUMBER: 'Numero telefonico',
	CITY: 'Città',
	CITY_TOWN: 'Città/comune',
	STATE_PROVINCE: 'Stato/provincia',
	COUNTRY: 'Paese',
	ZIP_CODE: 'Codice postale',
	ZIP_POSTAL_CODE: 'Codice postale',
	STREET: 'Via',
	STREET_ADDRESS: 'Indirizzo',
	LAST_NAME: 'Cognome',
	FIRST_NAME: 'Nome',
	ACTIONS: 'Azioni',
	PLEASE_SELECT_COUPLE_OF_SIMS: 'Selezionare alcune SIM.',
	COMPANY_IDENTITY: 'Identificativo azienda',
	ADD_USERS_OPTIONAL: 'Aggiungi utenti (opzionale)',
	REVIEW_AND_CREATE: 'Verifica e crea',
	PROJECTS_LIST: 'Elenco progetti',
	MULTI_NETWORK_CONNECT_ONBOARDING_M2M_PLATFORMS: 'Onboarding IoT Console - Piattaforma M2M',
	MULTI_NETWORK_CONNECT: 'IoT Console',
	CUSTOMERS: 'Clienti',
	COMPANY_EMAIL_ADDRESS: 'Indirizzo e-mail azienda',
	CONTACT_EMAIL_ADDRESS: 'Indirizzo e-mail contatto',
	CONTACT_PERSON_FULL_NAME: 'Nome per esteso del contatto',
	CONTACT_PERSON: 'Persona di contatto',
	ASSIGN_ANOTHER_PLATFORM: 'Assegna un’altra piattaforma',
	USER_INFO: 'Informazioni utente',
	SELECT_USER_TYPE: 'Seleziona tipo utente',
	USER_TYPE: 'Tipo utente',
	USER_NOTE: 'L’utente riceverà un’e-mail di invito per creare il proprio account',
	ADD_USER: 'Aggiungi utente',
	ADD_ANOTHER_USER: 'Aggiungi un altro utente',
	WHAT_IS_THIS_MESSAGE1: 'Il cliente deve essere caricato nelle piattaforme M2M prima di poter creare l’account IoT Console. Una volta ottenuto l’ID account, è possibile continuare.',
	WHAT_IS_THIS_MESSAGE2: 'I clienti delle piccole imprese o le start-up che utilizzano i piani dati IoT o gli starter kit IoT possono essere aggiunti automaticamente compilando questo modulo. Dopo la creazione dell’account, tornare qui per consentire al cliente l’accesso al portale della IoT Console.',
	SELECT_PLATFORM: 'Seleziona piattaforma',
	EMAIL_EXISTS_OTHER: 'Questo indirizzo e-mail esiste già (altro utente)',
	EMAIL_EXISTS_ADMIN: 'Questo indirizzo e-mail esiste già (amministratore)',
	YOU_HAVE_SUCCESSFULLY_CREATED_A_MULTI_NETWORK_CONNECT_ACCOUNT_FOR: 'Creazione riuscita di un account IoT Console per:',
	THE_IOT_GATEWAY_NUMBER_ASSOCIATED_WITH_THIS_ACCOUNT: 'Numero gateway IoT associato a questo account:',
	COMMUNICATION_PROFILE_NAME: 'Nome profilo comunicazione',
	ADD_NETWORK_ENTITLEMENT: 'Aggiungi diritti di rete',
	EDIT_NETWORK_ENTITLEMENT: 'Modifica diritti di rete',
	PLATFORMS: 'Piattaforme',
	NO_NETWORK_ENTITLEMENTS: 'Nessun diritto di rete',
	NO_RATE_PLANS: 'Nessun piano tariffario',
	EDIT_RATE_PLAN: 'Modifica piano tariffario',
	ERROR_WHILE_ADDING_NETWORK_ENTITLEMENT: 'Errore durante aggiunta diritti di rete',
	ERROR_WHILE_EDITING_NETWORK_ENTITLEMENT: 'Errore durante modifica diritti di rete',
	ERROR_WHILE_ADDING_RATE_PLAN: 'Errore durante aggiunta piano tariffario',
	ERROR_WHILE_EDITING_RATE_PLAN: 'Errore durante modifica piano tariffario',
	ERROR_WHILE_ADDING_COMPANY: 'Errore durante aggiunta azienda',
	ERROR_WHILE_EDITING_COMPANY: 'Errore durante modifica azienda',
	ERROR_WHILE_ADDING_EDITING_USER: 'Errore durante aggiunta/modifica utente',
	ERROR_WHILE_ADDING_EDITING_PLATFORM: 'Errore durante aggiunta/modifica piattaforma',
	ERROR_WHILE_ADDING_PLATFORM: 'Errore durante aggiunta piattaforma',
	ADD_PROFILE_NAME: 'Aggiungi nome profilo',
	ADD_PROFILE_ID: 'Aggiungi ID profilo',
	EDIT_PLATFORM: 'Modifica piattaforma',
	SIM_STATE_CHANGE_IS_SUCCESSFULLY_SCHEDULED: 'Pianificazione cambiamento di stato SIM riuscita.',
	SIM_STATE_CHANGE_IS_SUCCESSFULLY_SCHEDULED_FOR: 'Cambiamento di stato SIM pianificato per',
	SIM_STATE_CHANGE_IS_SUCCESSFULLY_SCHEDULED_ERROR: 'Pianificazione cambiamento di stato SIM non riuscita.',
	SIM_STATE_CHANGED_TO: 'Si è scelto di cambiare lo stato della SIM in',
	CHANGE_LATER: 'È anche possibile pianificare il cambiamento per dopo.',
	SCHEDULED_CHANGE_WILL_HAPPEN: 'Il cambiamento pianificato avverrà all’inizio della data selezionata.',
	TAP_INITIATE_CHANGE: 'Toccare “Avvia modifica” per avviare immediatamente l’azione.',
	SIM_STATE_CHANGE: 'Cambiamento stato SIM',
	SCHEDULE_SIM_STATE_CHANGE: 'Pianifica cambiamento stato SIM',
	SYSTEM_CONFIRMATION: 'Conferma del sistema',
	CHOOSE_NETWORK_ENTITLEMENT: 'Scegli diritti di rete',
	HISTORY: 'Cronologia batch',

	serviceProvider: 'Piattaforma',
	reportFrequency: 'Frequenza',

	// Onboarding
	ENDPOINT_SESSION_NO_RECORD: 'Non ci sono dati per il periodo selezionato.',
	TIME_START: 'Ora inizio',
	TIME_END: 'Ora fine',
	SESSION_DETAILS: 'Dettagli sessione',
	CARRIER: 'Vettore',
	DEVICE_IP: 'IP dispositivo',

	// chart datasets
	inventoryByStatus: 'Inventario per stato',
	inventoryByOperator: 'Inventario per operatore',
	inventoryByCountry: 'Inventario per paese',
	inventoryByStatusDescription: 'Questo visualizzerà il numero di endpoint attivi e inattivi.',
	inventoryByOperatorDescription: 'Questo visualizzerà gli operatori e il numero di endpoint per ciascun operatore.',
	inventoryByCountryDescription: 'Questo visualizzerà il nome di un paese e il numero di endpoint attivi in tale paese.',
	// prompt modal
	addPromptHeaderInfo: 'Applicare cambiamenti?',
	addChartPromptHeader: 'I cambiamenti al dashboard non sono ancora stati salvati',
	addChartPromptFirst: 'Se si desidera salvare i cambiamenti alla vista del proprio dashboard, toccare “Salva dashboard”, altrimenti “Annulla” per tornare alla vista precedente.',
	addChartPromptNotification: 'Applicare cambiamenti?',

	// CHART Descriptions
	DASHBOARD_CHART_countByState_SATELLITEDescription: 'Mostra i dettagli stato degli endpoint satellitari (dispositivi) su più piattaforme M2M',
	DASHBOARD_CHART_countByState_CELLULARDescription: 'Mostra i dettagli stato degli endpoint cellulari (dispositivi) su più piattaforme M2M',
	DASHBOARD_CHART_countByNetworkCarrier_SATELLITEDescription: 'Mostra la suddivisione percentuale degli endpoint satellitari (dispositivi) su più gestori di rete',
	DASHBOARD_CHART_countByNetworkCarrier_CELLULARDescription: 'Mostra la suddivisione percentuale degli endpoint cellulari (dispositivi) su più gestori di rete',
	DASHBOARD_CHART_countByTechnologyDescription: 'Mostra il totale della suddivisione in percentuale per gli endpoint cellulari e satellitari (dispositivi) nell’account',
	DASHBOARD_CHART_countByM2mPlatform_SATELLITEDescription: 'Mostra il totale della suddivisione in percentuale per gli endpoint satellitari (dispositivi) su più piattaforme M2M',
	DASHBOARD_CHART_countByM2mPlatform_CELLULARDescription: 'Mostra il totale della suddivisione in percentuale per gli endpoint cellulari (dispositivi) su più piattaforme M2M',
	DASHBOARD_CHART_countByIMSI_SATELLITEDescription: 'Mostra il totale della suddivisione in percentuale per gli endpoint cellulari (dispositivi) su più piattaforme M2M',
	DASHBOARD_CHART_countByIMSI_CELLULARDescription: 'Mostra il totale della suddivisione in percentuale per gli endpoint satellitari (dispositivi) su più piattaforme M2M',
	'DASHBOARD_CHART_IMSI Transfer Status_CELLULARDescription': 'Mostra i totali dello stato di trasferimento (Re-IMSI) in percentuale tra due piattaforme M2M.',
	DASHBOARD_CHART_countByIMSITransferStatus_CELLULARDescription: 'Mostra i totali dello stato di trasferimento (Re-IMSI) in percentuale tra due piattaforme M2M.',
	DASHBOARD_CHART_countByIMSITransferStatus_SATELLITEDescription: 'Mostra i totali dello stato di trasferimento (Re-IMSI) in percentuale tra due piattaforme M2M.',
	DASHBOARD_CHART_summaryOfMonthlyEndpointUsageDescription: 'Mostra il riepilogo dell’utilizzo mensile dati sugli endpoint cellulari e satellitari (dispositivi)',
	DASHBOARD_CHART_countByStatebyPlatformACC_CELLULARDescription: 'Mostra i dettagli stato degli endpoint cellulari (dispositivi) per piattaforma ACC',
	DASHBOARD_CHART_countByStatebyPlatformEOD_CELLULARDescription: 'Mostra i dettagli stato degli endpoint cellulari (dispositivi) per piattaforma EOD',
	DASHBOARD_CHART_countByStatebyPlatformGBCM_SATELLITEDescription: 'Mostra i dettagli stato degli endpoint satellitari (dispositivi) per piattaforma GBCM',
	DASHBOARD_CHART_countByStatebyPlatformDCP_CELLULARDescription: 'Mostra i dettagli stato degli endpoint cellulari (dispositivi) per piattaforma DCP',
	DASHBOARD_CHART_countByStatebyPlatformCCIP_CELLULARDescription: 'Mostra i dettagli stato degli endpoint cellulari (dispositivi) per piattaforma CCIP',
	DASHBOARD_CHART_countByStatebyPlatformVCC_CELLULARDescription: 'Mostra i dettagli stato degli endpoint cellulari (dispositivi) per piattaforma VCC',
	DASHBOARD_CHART_countByStatebyPlatformGDSP_CELLULARDescription: 'Mostra i dettagli stato degli endpoint cellulari (dispositivi) per piattaforma GDSP',
	DASHBOARD_CHART_countByStatebyPlatformWING_CELLULARDescription: 'Mostra i dettagli stato degli endpoint cellulari (dispositivi) per piattaforma WING',
	DASHBOARD_CHART_summaryOfMonthlyEndpointUsage_CELLULARDescription: 'Mostra il riepilogo dell’utilizzo mensile dati sugli endpoint cellulari e satellitari (dispositivi)',
	DASHBOARD_CHART_countByProfile_CELLULARDescription: 'Mostra i dettagli stato degli endpoint cellulari (dispositivi) su più profili',

	// Pie Chart custom tooltip id
	PIE_CHART_TOOLTIP_Active: 'Attivato',
	PIE_CHART_TOOLTIP_Test: 'Test',
	PIE_CHART_TOOLTIP_Inactive: 'Inattivo',
	PIE_CHART_TOOLTIP_Terminate: 'Chiuso',

	// Globecomm
	DEVICE_MODULE_NUMBER: 'Numero modulo dispositivo',
	DEVICE_MANUFACTURER: 'Produttore dispositivo',
	DEVICE_STATUS: 'Stato dispositivo',
	DEVICE_NETWORK_TYPE: 'Tipo di rete dispositivo',
	MODULE_IMEI: 'IMEI modulo',
	SIM_STATUS: 'Stato SIM',
	MODULE_STATUS: 'Stato modulo',
	TRESHOLD_OF_NOTIFICATION_BY_DATA_USAGE: 'Soglia di notifica per utilizzo dati',
	TRESHOLD_OF_SUSPENSION_BY_DATA_USAGE: 'Soglia di sospensione per utilizzo dati',
	NOTIFICATION_INTERVAL: 'Intervallo di notifica',
	DATA_USAGE: 'Utilizzo dati dal ciclo fino alla data',
	MANUFACTURER: 'Produttore',
	MODEL_NUMBER: 'Numero modello',
	TYPE: 'Tipo',
	NETWORK_NAME: 'Nome rete',
	SUPPORTED_NETWORKS: 'Reti supportate',
	RENEWAL_PERIOD: 'Periodo rinnovo',
	ACTIVATION_FEE: 'Canone di attivazione',
	RECCURING_FEE: 'Canone ricorrente',
	OVERAGE_FEE: 'Canone per eccedenza',
	INCLUDED_BYTES: 'Byte inclusi',
	MINIMUM_BILLABLE_BYTES: 'Minimo byte fatturabili',
	NOTIFICATION_CONTACT: 'Contatto notifica',
	NOTIFICATION_BYTE_CAP: 'Limite byte notifica',
	NOTIFICATION_REFRESH: 'Aggiornamento notifica',
	OBSOLETE: 'Obsoleto',
	HARDWARE: 'Hardware',
	SPECIFICATIONS: 'Specifiche',
	SPECIFICATION: 'Specifica',
	MODULE: 'Modulo',
	NO_HISTORY_DATA: 'Nessun record cronologico per questo endpoint',

	// Dashboard default message
	NO_GRAPHS: 'I GRAFICI AGGIUNTI SARANNO VISUALIZZATI QUI',
	dashboardDefault: 'I GRAFICI AGGIUNTI SARANNO VISUALIZZATI QUI',
	REMOVE_CHART: 'Rimuovi grafico',
	CARD_DROPDOWN_TITLE: 'Tipo grafico',
	ADD_WIDGET_BUTTON: 'Aggiungi grafico',
	ADD_WIDGET_BUTTON_MOBILE: 'Aggiungi',

	APP_NAME: 'IoT Console',
	NETWORK_TYPE_CHECK: 'Siamo spiacenti, non è consentito il cambiamento di stato di più endpoint di diversi tipi di rete o diversi provider di servizi.',
	SERVICE_PROVIDER_CHECK: 'Siamo spiacenti, non è consentito il cambiamento di diritti di rete di endpoint da più piattaforme o con diritti di rete diversi.',

	// USER FEEDBACK MODAL
	feedbackTitle: 'Feedback utenti',
	feedbackPageLabel: 'Il feedback dell’utente riguarda',
	feedbackFeedLabel: 'Descrivere in che modo possiamo migliorare.',
	feedbackFeedPlaceholder: 'Inserire qui il proprio feedback...',
	feedbackContactLabel: 'Se necessario, possiamo contattarLa al riguardo?',
	feedbackEmailLabel: 'E-mail',
	feedbackPhoneLabel: 'Telefono',
	feedbackMetaLabel: 'Metadati',
	feedbackCancelButton: 'Annulla',
	feedbackSendButton: 'Invia feedback',
	feedbackSuccessThanks: 'Grazie per il feedback.',
	feedbackSuccessMessage: 'Il feedback fornitoci ci permetterà di migliorare il prodotto. Valuteremo con attenzione i commenti e agiremo di conseguenza.',
	feedbackSuccessMessageSub: 'Potremmo anche contattarLa per ottenere maggiori chiarimenti riguardo al Suo suggerimento.',
	feedbackSuccessMessageSign: 'Team IoT Console',
	feedbackSuccessDoneButton: 'Fatto',
	feedbackTriggerTitle: 'Può fornirci del feedback?',
	feedbackTriggerSubtitle: 'Ce lo dica qui',
	feedbackThankYou: '{user}: grazie per aver contribuito al feedback utente e per averci aiutato a migliorare il prodotto.',
	feedbackSelect: 'Selezionare un argomento correlato dall’elenco qui sopra.',
	feedbackEnter: 'È necessario inserire il feedback.',
	feedbackIcon: 'Icona invio feedback riuscito',
	feedbackAdditionalFile: 'File aggiuntivo',
	feedbackFileUploadFail: 'Caricamento file non riuscito',
	feedbackMinLength: 'Inserire almeno 10 caratteri.',
	FEEDBACK_FILE_UPLOAD_EXTENSIONS: 'Caricare esclusivamente file con estensione .jpg o .png',
	// Changes tab

	FIELD_NAME: 'Nome campo',

	DELETE_TAB: 'Elimina scheda',
	TABLE_NAME: 'Nome tabella',
	EDIT_TABLE: 'Modifica parametri tabella',
	CUSTOM_FIELD_LABEL: 'Etichetta campo personalizzato',
	EDIT_TABLE_VIEW_NAME: 'Modifica nome vista tabella',
	PLEASE_ENTER_TABLE_NAME: 'Inserire nome tabella',
	DEVICE_TYPE: 'Tipo dispositivo',
	CELLULAR_COLUMNS: 'Parametri specifici cellulari',
	SATELLITE_SPECIFIC_COLUMNS: 'Parametri specifici satellitari',

	// TICKETING
	TICKET_ID: 'ID ticket',
	CATEGORY_NAME: 'Categoria',
	CATEGORY_TICKETING: 'Categoria',
	SUBCATEGORY_OPTIONAL: 'Sottocategoria (opzionale)',
	SELECT_SUBCATEGORY_OPTIONAL: 'Seleziona sottocategoria (opzionale)',
	ROAMING_ISSUE: 'Problema roaming',
	SEVERITY: 'Gravità',
	PRIORITY: 'Priorità',
	ACCOUNT_NUMBER: 'Numero account',
	CREATED_BY_TICKETING: 'Creato da',
	MULTI_NETWORK_CONNECT_ID: 'ID IoT Console',
	ENDPOINT_MODEL: 'Modello endpoint',
	WHAT_IS_THE_ERROR_MESSAGE: 'Qual è il messaggio di errore?',
	HOW_MANY_BARS_ARE_SHOWING: 'Quante barre si vedono?',
	DESCRIBE_ENDPOINT_STATE_HERE: 'Descrivere qui lo stato dell’endpoint...',
	WAS_THE_TROUBLESHOOTING_DONE: 'È stata effettuata la risoluzione dei problemi?',
	DESCRIBE_TROUBLESHOOTING_DETAILS_HERE: 'Descrivere qui i dettagli della risoluzione dei problemi…',
	DO_YOU_HAVE_ANY_ADDITIONAL_NOTES: 'Ci sono commenti aggiuntivi?',
	ADDITIONAL_NOTES: 'Commenti aggiuntivi',
	DASHBOARD_CHART_M2MSIMStatus_SATELLITE: 'endpoint per stato IOT-C (satellitare)',
	DASHBOARD_CHART_M2MSIMStatus_CELLULAR: 'endpoint per stato IOT-C (cellulare)',
	DASHBOARD_CHART_Country_SATELLITE: 'Endpoint per ubicazione corrente (satellitare)',
	DASHBOARD_CHART_Countrys_CELLULAR: 'Endpoint per ubicazione corrente (cellulare)',
	DASHBOARD_CHART_IMSI_SATELLITE: 'Endpoint per piattaforma M2M (satellitare)',
	DASHBOARD_CHART_IMSI_CELLULAR: 'Endpoint per piattaforma M2M (cellulare)',
	DASHBOARD_CHART_Network_SATELLITE: 'Endpoint per vettore rete (satellitare)',
	DASHBOARD_CHART_Network_CELLULAR: 'Endpoint per vettore rete (cellulare)',
	DASHBOARD_CHART_Technology: 'Endpoint per tecnologia',
	'DASHBOARD_CHART_IMSI Transfer Status_SATELLITE': 'Endpoint per stato trasferimento (satellitare)',
	'DASHBOARD_CHART_IMSI Transfer Status_CELLULAR': 'Endpoint per stato trasferimento (cellulare)',
	DASHBOARD_CHART_countByIMSITransferStatus_SATELLITE: 'Endpoint per stato trasferimento (satellitare)',
	DASHBOARD_CHART_countByIMSITransferStatus_CELLULAR: 'Endpoint per stato trasferimento (cellulare)',
	DASHBOARD_CHART_summaryOfMonthlyEndpointUsage_CELLULAR: 'Utilizzo dati totale per tutti gli endpoint ACC',
	DASHBOARD_CHART_countByState_SATELLITE: 'Endpoint per stato (satellitare)',
	DASHBOARD_CHART_countByState_CELLULAR: 'Endpoint per stato (cellulare)',
	DASHBOARD_CHART_countByServiceType_SATELLITE: 'Endpoint per tecnologia (satellitare)',
	DASHBOARD_CHART_countByServiceType_CELLULAR: 'Endpoint per tecnologia (cellulare)',
	DASHBOARD_CHART_countByRatePlan_SATELLITE: 'Endpoint per piano tariffario (satellitare)',
	DASHBOARD_CHART_countByRatePlan_CELLULAR: 'Endpoint per piano tariffario (cellulare)',
	DASHBOARD_CHART_countByNetworkEntitlement_SATELLITE: 'Endpoint per diritti di rete (satellitare)',
	DASHBOARD_CHART_countByNetworkEntitlement_CELLULAR: 'Endpoint per diritti di rete (cellulare)',
	DASHBOARD_CHART_countByNetworkCarrier_SATELLITE: 'Endpoint per vettore rete (satellitare)',
	DASHBOARD_CHART_countByNetworkCarrier_CELLULAR: 'Endpoint per vettore rete (cellulare)',
	DASHBOARD_CHART_countByTechnology: 'Endpoint per tecnologia',
	DASHBOARD_CHART_countByM2MPlatform_SATELLITE: 'Endpoint per piattaforma M2M (satellitare)',
	DASHBOARD_CHART_countByM2MPlatform_CELLULAR: 'Endpoint per piattaforma M2M (cellulare)',
	DASHBOARD_CHART_countByIMSI_SATELLITE: 'Endpoint per piattaforma M2M',
	DASHBOARD_CHART_countByIMSI_CELLULAR: 'Endpoint per piattaforma M2M',
	DASHBOARD_CHART_countByM2mPlatform_SATELLITE: 'Endpoint per piattaforma M2M',
	DASHBOARD_CHART_countByM2mPlatform_CELLULAR: 'Endpoint per piattaforma M2M',
	DASHBOARD_CHART_countByStatebyPlatformACC_CELLULAR: 'Endpoint per stato (ACC)',
	DASHBOARD_CHART_countByStatebyPlatformEOD_CELLULAR: 'Endpoint per stato (EOD)',
	DASHBOARD_CHART_countByStatebyPlatformGBCM_SATELLITE: 'Endpoint per stato (GBCM)',
	DASHBOARD_CHART_countByStatebyPlatformDCP_CELLULAR: 'Endpoint per stato (DCP)',
	DASHBOARD_CHART_countByStatebyPlatformCCIP_CELLULAR: 'Endpoint per stato (CCIP)',
	DASHBOARD_CHART_countByStatebyPlatformVCC_CELLULAR: 'Endpoint per stato (VCC)',
	DASHBOARD_CHART_countByStatebyPlatformGDSP_CELLULAR: 'Endpoint per stato (GDSP)',
	DASHBOARD_CHART_countByStatebyPlatformWING_CELLULAR: 'Endpoint per stato (WING)',
	DASHBOARD_CHART_countByProfile_CELLULAR: 'Endpoint per profilo',
	TICKET_STATUS: 'Stato ticket',
	ENDPOINT_LOCATION: 'Ubicazione endpoint',
	ISSUE: 'Problema',
	SUGGESTIONS: 'Suggerimenti',
	PHONE: 'Telefono',
	FAILED_TO_PROVISION: 'Provisioning non riuscito',
	SUBMITTED_BY: 'Inviato da',
	NEW_TICKET: 'Nuovo ticket',
	ENDPOINT: 'Endpoint',
	ERROR_MESSAGE: 'Messaggio di errore',
	ATTACHED_FILE: 'File allegato',
	TICKETING: 'Creazione di ticket',
	GET_HELP_WITH_THIS_ENDPOINT: 'Ottenere aiuto riguardo a questo endpoint',
	CATEGORY_AND_SUGGESTIONS: 'Categoria e suggerimenti',
	CONFIRMATION: 'Conferma',
	RECORDED_STATUS: 'Stato registrato',
	ENDPOINT_NAME: 'ID endpoint',
	CONTACT_PHONE: 'Telefono contatto',
	CONTACT_EMAIL: 'E-mail contatto',
	SELECT_SUBCATEGORY: 'Seleziona sottocategoria',
	FILE_UPLOADED: 'File caricato',
	FAQ_SUPPORT_INFO: 'Come trovare risposte rapide alle domande più frequenti dei nostri utenti.',
	USER_GUIDE_SUPPORT_INFO: 'Leggere le istruzioni dettagliate per le funzioni del portale. La guida è personalizzata in base al tipo di account.',
	TICKETING_SUPPORT_INFO: 'Se si verificano problemi di servizio e i test diagnostici non offrono una soluzione, è possibile creare un ticket per problema. ',
	MUST_BE: 'Deve essere ',
	LESS_THAN: 'minore di ',
	CHARACTERS: ' caratteri',
	LOADING_DATA: 'Caricamento dati',
	GPRS: 'GPRS',

	//  Support
	CONTENT: 'Contenuti',
	CREATE_NEW_NOTIFICATION: 'Crea nuova notifica',
	EDIT_NOTIFICATION: 'Modifica notifica',
	EDIT_FORM_TO_CREATE_NOTIFICATION: 'Modificare il modulo per creare una nuova notifica.',
	NOTIFICATION_TYPE: 'Tipo di notifica?',
	SELECT_NOTIFICATION_PLACEHOLDER: 'Selezionare segnaposto notifica',
	CHOOSE_WHICH_USER_GROUPS_TO_NOTIFY: 'Scegliere i gruppi utenti a cui inviare la notifica:',
	NOTIFY_ALL_GROUPS: 'Notifica a tutti i gruppi',
	MANAGER: 'Manager',
	DEVELOPER: 'Sviluppatore',
	ADD_NOTIFICATION_CONTENT: 'Aggiungi contenuti alla notifica',
	SUSPEND: 'Sospendi',
	SUSPENDED: 'Sospeso',
	DEACTIVE: 'Disattivo',
	PROVISIONED: 'Provisioning effettuato',
	ENDPOINT_COUNTRY: 'Paese endpoint',
	ENDPOINT_STATE: 'Stato endpoint',
	ENDPOINT_CITY: 'Città endpoint',
	ENDPOINT_STREET_ADDRESS: 'Indirizzo stradale endpoint',
	AS_OF: 'Elaborati al',
	VIEW_ALL_NOTIFICATIONS: 'Visualizza tutte le notifiche',
	NOTIFICATION_SCOPE: 'Ambito della notifica',
	NOTIFICATION1: 'Informazioni',
	NOTIFICATION2: 'Errore',
	NOTIFICATION3: 'Avvertenza',
	NOTIFICATION4: 'Conferma',
	GLOBAL_NOTIFICATION: 'Notifica globale',
	COMPANY_RELATED_NOTIFICATION: 'Notifica relativa all’azienda',
	MUST_BE_MORE_THAN: 'Deve essere maggiore di ',
	MUST_BE_LESS_THAN: 'Deve essere minore di ',
	MUST_BE_LESS_THAN_OR_EQUAL_TO: 'Deve essere minore o uguale a ',
	GLOBAL: 'Globale',
	COMPANY_RELATED: 'Relativa all’azienda',
	RECENT_NOTIFICATIONS: 'Avvisi e notifiche di sistema',

	networkEntitlement: 'ID diritti di rete',
	networkType: 'Tipo di rete',
	transferStatus: 'Stato trasferimento IMSI',
	rateplan: 'Piano tariffario',
	FAILURE: 'Errore',
	PENDING_REIMSI_ATTEMPT: 'Tentativo Re-IMSI in sospeso',
	REIMSI_QUEUED: 'Re-IMSI in coda',
	PENDING_REIMSI_DELIVERED: 'Re-IMSI in sospeso consegnato',
	'PENDING_RE-IMSI_DELIVERY_FAILED': 'Consegna Re-IMSI in sospeso non riuscita',

	'PENDING REIMSI ATTEMPT': 'Tentativo RE-IMSI in sospeso',
	'REIMSI PENDING ATTEMPT': 'Tentativo RE-IMSI in sospeso',
	'PENDING REIMSI DELIVERED': 'Trasferimento in sospeso consegnato',
	'Re-IMSI Delivery Failed': 'Consegna trasferimento non riuscita',
	'REIMSI FAILED': 'Re-IMSI non riuscito',
	'REIMSI QUEUED': 'Re-IMSI in coda',
	'REIMSI PEDING ATEMPT': 'Tentativo Re-IMSI in sospeso',
	'REIMSI PENDING ATEMPT': 'Tentativo Re-IMSI in sospeso',
	'REIMSI PENDING DELIVERED': 'Re-IMSI in sospeso consegnato',
	'REIMSI SUCCESS': 'Re-IMSI riuscito',
	failure: 'errore',

	'ID_PENDING REIMSI ATTEMPT': 'Tentativo RE-IMSI in sospeso',
	'ID_REIMSI PENDING ATTEMPT': 'Tentativo RE-IMSI in sospeso',
	'ID_PENDING REIMSI DELIVERED': 'Trasferimento in sospeso consegnato',
	'ID_Re-IMSI Delivery Failed': 'Consegna trasferimento non riuscita',
	'ID_REIMSI FAILED': 'Re-IMSI non riuscito',
	'ID_REIMSI QUEUED': 'Re-IMSI in coda',
	'ID_REIMSI PEDING ATEMPT': 'Tentativo Re-IMSI in sospeso',
	'ID_REIMSI PENDING ATEMPT': 'Tentativo Re-IMSI in sospeso',
	'ID_REIMSI PENDING DELIVERED': 'Re-IMSI in sospeso consegnato',
	'ID_REIMSI SUCCESS': 'Re-IMSI riuscito',

	CONSUMED_BYTES: 'MB utilizzati',
	CONSUMED_SMSES: 'SMS utilizzati',
	TO_DATE: 'ad oggi',

	CHART_EXISTS_IN_SETTINGS: 'Questo grafico è già presente nelle impostazioni dell’Utente. È permesso un solo tipo di grafico.',
	NO_CHART_DATA: 'Dati grafico mancanti',
	STATES_MESSAGE: 'Nessuno stato disponibile.',
	SELECT_RATE_PLAN: 'Seleziona piano tariffario',
	CHANGE_RATE_PLAN: 'Cambia piano tariffario',
	CURRENT_RATE_PLAN_FOR_ENDPOINT_ID: 'Il piano tariffario corrente per ID endpoint',
	IS: 'è',
	CONFIRM_RATE_PLAN_CHANGE: 'Confermare cambiamento piano tariffario',
	RATE_PLAN_WILL_BE: 'Una volta avviato questo cambiamento, il piano tariffario dell’endpoint selezionato sarà',
	WOULD_YOU_LIKE_TO_INITIATE_CHANGE_NOW: 'Avviare ora il cambiamento?',
	RATE_PLAN_CHANGE_IS_SUCCESSFULLY_SCHEDULED: 'Cambiamento del piano tariffario pianificato',
	FOR: 'per',
	RATE_PLAN_CHANGE_IS_SUCCESSFULLY_UPDATED: ' Aggiornamento cambiamento piano tariffario riuscito',
	RATE_PLAN_CHANGE_COULD_NOT_BE_SCHEDULED_SUCCESSFULLY: 'Pianificazione cambiamento piano tariffario non riuscita',
	RATE_PLAN_CHANGE_COULD_NOT_BE_CARRIED_OUT_SUCCESSFULLY: 'Cambiamento piano tariffario non riuscito',
	ENDPOINT_RATE_PLAN: 'Piano tariffario',
	DOWNLOAD_TEMPLATE_BATCH: 'Scarica modello',
	CONFIRM_BATCH_FILE_UPLOAD: 'Conferma caricamento file batch',
	SCHEDULED_ACTION_ON: 'Azione pianificata il',
	FILE_MUST_BE_SUMITTED_WITH_CSV: 'I file devono essere inviati con estensione .csv.',
	MAXIMUM_SIMS_PER_UPLOAD: 'Il numero massimo di SIM per caricamento è 10.000.',
	SIMS_HAVE: 'SIM hanno',
	CHANGE_FOR_ALL_SELECTED_SIMS: 'Selezionare un piano tariffario diverso in modo da applicare il cambiamento a tutte le SIM selezionate',
	YOU_HAVE_CHOSEN_TO_CHANGE_THE_RATE_PLAN_OF_THE: 'Si è scelto di cambiare il piano tariffario delle',
	SELECTED_SIMS_TO: 'SIM selezionate a',
	SIMS_RATE_PLAN_SUCCESSFULLY_REQUESTED: 'Aggiornamento piani tariffari SIM riuscito',
	SCHEDULE_RATE_PLAN_SUCCESSFUL: 'Pianificazione piano tariffario per più SIM riuscita',
	SIM_RATE_PLAN_CHANGE_FOR: 'Cambiamento piano tariffario SIM per',
	MULTIPLE_RATE_PLAN_CHANGE_SCHEDULE_ERROR: 'Pianificazione cambiamento piano tariffario per più SIM non riuscita',
	MULTIPLE_RATE_PLAN_CHANGE_ERROR: 'Richiesta cambiamento piano tariffario per più SIM non riuscita',
	SAME_RATE_PLAN_SELECTED: 'Stesso piano tariffario selezionato',
	REASON: 'motivo',

	API_DOCUMENTATION: 'Documentazione API',
	'Cellular - Active': 'Cellulare - Attivato',
	'Satellite - Active': 'Satellitare - Attivato',
	'Cellular - Terminate': 'Cellulare - Chiuso',
	'Satellite - Terminate': 'Satellitare - Chiuso',
	CHANGE_RATE_PLAN_NOT_ALLOWED: 'Cambiamento piano tariffario non permesso',
	reimsiqueued: 'Re-IMSI in coda',
	reimsifailed: 'Re-IMSI non riuscito',
	reimsisuccess: 'Re-IMSI riuscito',
	reimsipendingattempt: 'Tentativo Re-IMSI in sospeso',
	reimsipendingdelivered: 'Re-IMSI in sospeso consegnato',
	imsiPendingMessage: 'Se lo stato del dispositivo è in sospeso per più di 72 ore, contattare l’assistenza clienti',
	ADD_ANOTHER_ACCOUNT: 'Aggiungi un altro account',
	FILTER_DOES_NOT_HAVE_VALID_DATA: 'Errore API, contattare l’assistenza.',
	FAILOVER: 'Failover',
	CANCEL_PENDING_FAIL_TITLE: 'Annullamento cambiamento in sospeso non riuscito',
	CANCEL_PENDING_FAIL_TEXT: 'Al momento non è stato possibile completare l’annullamento del cambiamento in sospeso',
	CANCEL_PENDING_SUCCESS_TITLE: 'Annullamento cambiamento in sospeso riuscito',
	CANCEL_PENDING_SUCCESS_TEXT: 'Annullamento cambiamento in sospeso completato',
	CANCEL_CHANGE: 'Annulla cambiamento',
	CAUTION: 'Attenzione',
	CANCEL_PROMPT_TITLE: 'Confermare l’annullamento del cambiamento in sospeso?',
	CANCEL_PROMPT_TEXT: 'Questa azione non può essere annullata!',
	CONFIRM_CANCELLATION: 'Conferma annullamento',
	NO_RATE_PLANS_AVAILABLE: 'Nessun piano tariffario disponibile',
	BULK_UPLOAD: 'Caricamento in blocco',
	MULTI_ENDPOINT_ACTION: 'Azione su endpoint multipli',
	PLEASE_SELECT_MORE_SIMSS: 'Selezionare due o più endpoint',
	M2M_ACCOUNT_ID: 'ID account M2M',
	REPORTS_PLATFORM: 'Piattaforma M2M',
	ENDPOINT_CHART_SECOND_CARD: 'Sessioni recenti',
	ENDPOINT_CHART_SECOND_CARD_ON: 'Il',
	ENDPOINT_CHART_THIRD_CARD: 'Ubicazione rete endpoint',
	NO_AVAILABLE_FILTERS: 'Nessun filtro disponibile.',
	NO_AVAILABLE_FILTERS_SUBTITLE: 'Non ci sono attualmente filtri disponibili da mostrare',

	// Developer API
	GETTING_STARTED: 'Come iniziare',
	API_SANDBOX: 'Sandbox API',
	DEVELOPER_API: 'API sviluppatore',
	GETTING_STARTED_WITH_API_T: 'Come iniziare con le API',
	GETTING_STARTED_WITH_API_P: 'Prima di poter effettuare la prima chiamata API, l’indirizzo IP di origine (server) deve essere autorizzato. L’IP di origine viene normalmente configurato durante il processo di onboarding quando viene creato l’accesso API. Se si ricevono errori, ciò è probabilmente dovuto a un blocco dell’accesso al gateway IoT AT&T.',
	GETTING_STARTED_WITH_API_UL_T: 'Per avviare lo sviluppo, assicurarsi di aver effettuato le seguenti operazioni:',
	GETTING_STARTED_WITH_API_LI1: '- Aver impostato un server pubblicamente accessibile e averci fornito il relativo indirizzo IP durante l’onboarding.',
	GETTING_STARTED_WITH_API_LI2: '- L’IP di origine è stato autorizzato all’accesso del gateway IoT AT&T.',
	GETTING_STARTED_WITH_API_LI3: '- Il Suo amministratore aziendale ha fornito il nome utente e la password dell’API creati al momento dell’attivazione del servizio API.',
	GETTING_STARTED_WITH_API_LI4: '- Si ha accesso al proprio server delle risorse per l’esecuzione di comandi cURL, oppure si usano app di connessione scritte nel proprio linguaggio preferito indicate di seguito.',
	GET_OAUTH_TOKEN_T: 'Ottenere il token di accesso OAuth',
	GET_OAUTH_TOKEN_P: 'Ottenere il token OAuth da AT&T IoT Identity utilizzando nome utente e password forniti dal proprio amministratore utilizzando il metodo preferito tra quelli seguenti. Il token è valido per 7 giorni.',
	GETTING_STARTED_WITH_API_CONNECTORS_T: 'Connettori API',
	GETTING_STARTED_WITH_API_CONNECTORS_P: 'Selezionare tra gli esempi seguenti come si desidera connettersi alla nostra API.',
	TEST_CONNECTION_T: 'Provare la connessione al gateway IoT',
	TEST_CONNECTION_P: 'Esempio curl:',
	GETTING_STARTED_WITH_API_NEXT_STEP_T: 'Risultato desiderato',
	GETTING_STARTED_WITH_API_NEXT_STEP_P: 'Se si riceve il codice di risposta HTTP 200 OK, la configurazione è completa e si è pronti per avviare lo sviluppo.',
	GETTING_STARTED_WITH_API_ERRORS_T: 'Risoluzione dei problemi di connessione',
	GETTING_STARTED_WITH_API_ERRORS_P: 'In caso di errori, verificare innanzitutto se l’autenticazione è impostata correttamente nell’intestazione HTTP e che il token di accesso non sia scaduto (nel qual caso deve essere aggiornato). Inoltre, effettuare il ping del gateway IoT AT&T per verificare se il proprio IP di origine è stato autorizzato. Se si ottengono risultati imprevisti, presentare un ticket di risoluzione dei problemi all’assistenza.',
	API: 'API',
	SANDBOX_SPECIFIES_THE_LIST: 'Il sandbox Swagger seguente specifica l’elenco delle risorse disponibili nell’API REST e le operazioni che è possibile chiamare su tali risorse. Il documento specifica inoltre l’elenco dei parametri di un’operazione, compresi il nome e il tipo dei parametri, se i parametri sono obbligatori o facoltativi e informazioni sui valori accettabili per tali parametri.',
	JSON_SCHEMA_INCLUDES: 'È incluso uno schema JSON che descrive la struttura del corpo della richiesta inviato a un’operazione in un’API REST, insieme allo schema JSON che descrive la struttura di tutti i corpi di risposta restituiti da un’operazione.',

	GBCM_OPERATOR: 'Speedcast',
	ATT_OPERATOR: 'AT&T',

	CREATE_CUSTOM_REPORT: 'Crea report personalizzato',
	YOU_MUST_CREATE_FILTER_BEFORE_EXPORT: 'È necessario creare un filtro prima di poter esportare un elenco.',
	CREATE_REPORT: 'Crea report',
	CHART_LEGEND_Active: 'Attivato',
	CHART_LEGEND_Terminate: 'Chiuso',
	WE_ARE_SORRY_THERE_IS_NO_DATA_TO_EXPORT: 'Siamo spiacenti, non ci sono dati da esportare',
	EXPORT_FILTERED_DATA: 'Esporta dati filtrati',
	ID_DASHBOARD_CHARTS_summaryofmonthlyendpointusage: 'Riepilogo utilizzo mensile dell’endpoint',
	ID: 'ID',
	TAB: 'Scheda',
	SORT_BY: 'Ordina per',
	SELECT_CHART_TYPE_TO_ADD: 'Seleziona tipo grafico da aggiungere',
	RECREDENTIAL_STATUS: 'Stato rigenerazione credenziali',
	RECREDENTIAL_TRANSFER_STATUS: 'Stato rigenerazione credenziali',
	recredentialTransferStatus: 'Stato rigenerazione credenziali',
	M2MACCOUNTID: 'ID account M2M',
	HOLDING_ACCOUNT_ID: 'ID account di attesa',
	RE_CREDENTIAL: 'Rigenerazione credenziali',
	SELECT_OPERATOR: 'Seleziona operatore',
	RE_CREDENTIAL_SIM: 'Rigenerazione credenziali SIM',
	CURRENT_PLATFORM: 'Piattaforma corrente',
	YOU_HAVE_CHOSEN_TO_RECREDENTIAL_THIS_SIM: 'Si è scelto di effettuare la rigenerazione delle credenziali di questa SIM per',
	YOU_HAVE_CHOSEN_TO_RECREDENTIAL_THESE_SIMS: 'Si è scelto di effettuare la rigenerazione delle credenziali di queste SIM per',
	YOU_MAY_INTIATE_THE_CHANGE_NOW: 'È ora possibile avviare il cambiamento',
	RE_CREDENTIALING_STATUS: 'Stato rigenerazione credenziali',
	RE_CREDENTIALING_IS_SUCCESSFULLY_INITIATED: 'Rigenerazione credenziali avviata',
	RE_CREDENTIALING_IS_SUCCESSFULLY_SCHEDULED: 'Rigenerazione credenziali pianificata',
	RE_CREDENTIALING_IS_SUCCESSFULLY_SCHEDULED_FOR: 'Rigenerazione credenziali pianificata per il',
	RE_CREDENTIALING_COULD_NOT_BE_INITIATED_SUCCESSFULLY: 'Impossibile avviare la rigenerazione credenziali',
	RE_CREDENTIALING_COULD_NOT_BE_SCHEDULED_SUCCESSFULLY: 'Impossibile pianificare la rigenerazione credenziali',
	ENDPOINT_HISTORY: 'Cronologia endpoint',
	SHIPPED_DATE: 'Data spedizione',
	DIAGNOSTIC_BUTTON: 'Avvia diagnostica',
	DEVICE_REGISTRATION_INFO_BUTTON: 'Informazioni registrazione dispositivo',
	ENDPOINT_REGISTRATION_DETAILS: 'Dettagli registrazione endpoint',
	eventTime: 'Indicatore data e ora',
	carrierName: 'Nome vettore',
	registrationType: 'Tipo registrazione',
	NO_DATA_TO_DISPLAY_AT_THE_MOMENT: 'Nessun dato da visualizzare al momento',
	status: 'Stato',
	iccid: 'ICCID',
	carrier: 'Vettore',
	ATTACHED_CARRIER_NAME: 'Nome vettore collegato',
	M2MPlatform: 'Piattaforma M2M',
	simState: 'Ricezione stato dispositivo in corso',
	'Endpoint Provisioning Information': 'Informazioni provisioning endpoint',
	'M2M Platform': 'Piattaforma M2M',
	'Network Connection': 'Connessione di rete',
	simStatusM2M: 'Ricezione stato dispositivo in corso',
	billingStatus: 'Ricezione stato dispositivo in corso',
	EndpointProvisioningInformation: 'Informazioni provisioning endpoint',
	trafficAllowed: 'Rilevamento informazioni provisioning dispositivo in corso',
	permitPassingTraffic: 'Rilevamento informazioni provisioning dispositivo in corso',
	verifySimState: 'Ottieni informazioni dispositivo',
	NetworkConnection: 'Connessione di rete',
	verifyConnection: 'Ottieni rete dispositivo',
	successfullyConnectedToNetwork_inSession: 'Ottieni rete dispositivo',
	verifyIPSession: 'Ottieni IP dispositivo',
	dataSessionCurrentlySuccessful: 'Ottieni IP dispositivo',
	dd_inventory: 'Inventario',
	dd_activated: 'Attivato',
	dd_active: 'Attivato',
	dd_inactivated: 'Inattivo',
	dd_inactive: 'Inattivo',
	dd_deactivated: 'Disattivato',
	dd_retired: 'Ritirato',
	dd_purged: 'Cancellato',
	dd_testready: 'Pronto alla prova',
	dd_activationready: 'Pronto all’attivazione',
	dd_replaced: 'Sostituito',
	dd_trial: 'Prova',
	dd_start: 'Avvio',
	dd_transfer: 'Trasferimento',
	dd_globalintransitsource: 'Sorgente globale in transito',
	dd_globalintransitdestination: 'Destinazione globale in transito',
	dd_globaltransferred: 'Globale trasferito',
	dd_other: 'Altro',
	dd_permitpassingtraffic: 'Traffico di passaggio permesso',
	dd_notpermitpassingtraffic: 'Traffico di passaggio non permesso',
	dd_allowedToUseWirelessNetworkResources: 'Risorse wireless TollSec permesse',
	dd_barredFromNetwork: 'Escluso dalla rete',
	dd_trafficnotallowed_simstate: 'Stato SIM traffico non permesso',
	dd_suspendedByServiceProvider: 'Sospeso da provider di servizi',
	dd_suspendedDueToUsageLimit: 'Sospeso a causa di limiti di utilizzo',
	dd_trafficNotAllowed_others: 'Traffico non permesso (altro)',
	dd_successfullyconnectedtonetwork_insession: 'Connesso a rete (in sessione)',
	dd_notsuccessfullyconnectedtonetwork_insession: 'Non connesso a rete (in sessione)',
	dd_validregistration_validnetworkactivity: 'Registrazione valida (attività di rete valida)',
	dd_validregistration_nonetworkactivity: 'Registrazione valida (nessuna attività di rete)',
	dd_validgsmregistrationonly: 'Solo registrazione GSM valida',
	dd_novalidregistration_hasactivityinlast24h: 'Nessuna registrazione valida (attività presente nelle ultime 24 ore)',
	dd_novalidregistration_hasauthrequestinlast24h_nolocupdate: 'Nessuna registrazione valida (richiesta autorizzazione nelle ultime 24 ore)',
	dd_novalidregistration_haslocupdatesmorethan24hago: 'Nessuna registrazione valida (aggiornamenti locali più di 24 ore fa)',
	dd_novalidregistration_hasauthrequestmorethan24hago_noactivity: 'Nessuna registrazione valida (richiesta di autorizzazione più di 24 ore fa)',
	dd_novalidregistration_nonetworkactivity: 'Più di 24 ore fa (nessuna attività di rete)',
	dd_datasessioncurrentlysuccessful: 'Dispositivo non attualmente connesso. Sessioni IP precedenti riuscite.',
	dd_notdatasessioncurrentlysuccessful: 'Sessione dati attualmente non riuscita',
	dd_datasessionpastsuccessful: 'Sessione dati riuscita in passato',
	dd_connectionattemptfailed_invalidcredentials: 'Tentativo di connessione non riuscito (credenziali non valide)',
	dd_nodatasessionsuccessfulhistory: 'Nessun precedente di sessioni dati riuscite',
	dd_: '',
	dd_allowedtousewirelessnetworkresources: 'Risorse wireless TollSec permesse',
	attached: 'Collegato',
	detached: 'Non collegato',
	RECREDENTIALED: 'Credenziali rigenerate',
	RECREDENTIALING: 'Rigenerazione credenziali',
	CHANGE_RECREDENTIAL: 'Rigenerazione credenziali permessa solo per le stesse piattaforme',
	RECREDENTIAL_DESCRIPTION: 'Suggerimento: Quando si effettua la rigenerazione di credenziali e si selezionano più SIM, è possibile selezionare solo una piattaforma di rigenerazione credenziali per più SIM selezionate',
	RECREDENTIAL_NOT_ALLOWED: 'Rigenerazione credenziali non permessa',
	RECREDENTIAL_SUCCESSFULLY_REQUESTED: 'Inizializzazione SIM riuscita',
	MULTIPLE_RECREDENTIAL_CHANGE_ERROR: 'Inizializzazione richiesta rigenerazione credenziali per più SIM non riuscita',
	MOBILE_NETWORK_REGISTRATION_HISTORY: 'Cronologia registrazione rete mobile',
	ENDPOINT_DAILY_USAGE: 'Utilizzo giornaliero endpoint',
	ENDPOINT_CYCLE_USAGE: 'Utilizzo endpoint dal ciclo fino alla data',
	CONFIRM_RE_CREDENTIALING: 'Conferma rigenerazione credenziali',
	PENDING_RECREDENTIAL: 'Rigenerazione credenziali in sospeso per questo endpoint',
	ENDPOINTS_ARE: 'endpoint selezionati per esportazione',
	ENDPOINT_IS: 'endpoint selezionato per esportazione',
	CUSTOM_REPORT_WILL_BE_AVAILABLE: 'Questo report personalizzato sarà disponibile nella sezione report',
	YOU_CAN_APPEND_CUSTOM_NAME: 'È possibile aggiungere il nome personalizzato al report qui',
	CUSTOM_REPORT_NAME_APPENDIX: 'Appendice nome personalizzato report',
	ADD_CUSTOM_REPORT_NAME_APPENDIX: 'Aggiungi appendice nome personalizzato report (sono permesse solo cifre e lettere)',
	CUSTOM_REPORT: 'Report personalizzato',
	CUSTOM_REPORT_CREATED: 'Creazione report personalizzato riuscita',
	GO_TO_REPORTS: 'Vai a report',
	DIAGNOSTICS_FOR_THIS_ENDPOINT: 'La diagnostica per questo endpoint non è attualmente disponibile',
	ENDPOINT_DATA_INFORMATION: 'Informazioni dati endpoint',
	LAST_SESSION: 'Ultima sessione',
	CARRIER_ATTACHED: 'Vettore collegato',
	APN_DCP: 'APN',
	IP_ADDRESS: 'Indirizzo IP',
	LAST_SENT_SMS_MO: 'Data e ora di ultimo SMS MO inviato',
	LAST_SENT_SMS_MT: 'Data e ora di ultimo SMS MT inviato',
	TEST_DETAILS: 'Dettagli prova',
	CHECK_BLOCKED_STATUS: 'Controlla stato bloccato',
	BLOCKED_STATUS: 'Stato bloccato',
	RETRY_TEST: 'Riprova',
	MOBILE_NETWORK_TEST: 'Prova rete mobile',
	CHECK_BLOCKED_SUB: 'Controlla abbonamento bloccato',
	TROUBLESHOOT_DCP: 'Risoluzione problemi DCP',
	locationUpdate: 'Aggiornamento ubicazione',
	pdpStatus: 'Stato PDP',
	cancelLocation: 'Annulla ubicazione',
	CANCEL_LOCATION: 'Annulla ubicazione',

	DASHBOARD_CHART_CountofDailyEndpointSMSbyPlatformDCP_CELLULARDescription: 'Mostra il numero totale di messaggi SMS inviati giornalmente su base mensile sulla piattaforma DCP',
	DASHBOARD_CHART_CountofDailyEndpointSMSbyPlatformDCP_CELLULAR: 'SMS per giorno (DCP)',
	DASHBOARD_CHART_CountofMonthlyEndpointSMSbyPlatformDCP_CELLULAR: 'SMS per mese (DCP)',
	DASHBOARD_CHART_SumofDailyEndpointUsagebyPlatformDCP_CELLULAR: 'Utilizzo giornaliero endpoint totale (DCP)',
	DASHBOARD_CHART_SumofDailyEndpointUsagebyPlatformACC_CELLULAR: 'Utilizzo giornaliero dati (ACC)',
	DASHBOARD_CHART_SumofMonthlyEndpointUsagebyPlatformDCP_CELLULAR: 'Utilizzo mensile dati (DCP)',
	DASHBOARD_CHART_CountofMonthlyEndpointSMSbyPlatformDCP_CELLULARDescription: 'Mostra il numero totale di messaggi SMS inviati mensilmente sulla piattaforma DCP',
	DASHBOARD_CHART_SumofDailyEndpointUsagebyPlatformDCP_CELLULARDescription: 'Mostra l’utilizzo totale dati giornaliero su base mensile sulla piattaforma DCP',
	DASHBOARD_CHART_SumofDailyEndpointUsagebyPlatformACC_CELLULARDescription: 'Mostra l’utilizzo dati totale dati giornaliero su base mensile sulla piattaforma ACC',
	DASHBOARD_CHART_SumofMonthlyEndpointUsagebyPlatformDCP_CELLULARDescription: 'Mostra l’utilizzo dati totale mensile sulla piattaforma DCP',
	DASHBOARD_CHART_CountofMonthlyEndpointSMSbyPlatformCCIP_CELLULAR: 'SMS al mese (CCIP)',
	DASHBOARD_CHART_CountofMonthlyEndpointSMSbyPlatformCCIP_CELLULARDescription: 'Mostra il numero totale di messaggi SMS inviati mensilmente sulla piattaforma CCIP',
	DASHBOARD_CHART_CountofDailyEndpointSMSbyPlatformCCIP_CELLULAR: 'SMS al giorno (CCIP)',
	DASHBOARD_CHART_CountofDailyEndpointSMSbyPlatformCCIP_CELLULARDescription: 'Mostra il numero totale di messaggi SMS inviati giornalmente sulla piattaforma CCIP',
	DASHBOARD_CHART_SumofDailyEndpointUsagebyPlatformCCIP_CELLULAR: 'Utilizzo totale giornaliero endpoint (CCIP)',
	DASHBOARD_CHART_SumofDailyEndpointUsagebyPlatformCCIP_CELLULARDescription: 'Mostra l’utilizzo dati totale giornaliero su base mensile sulla piattaforma CCIP',
	DASHBOARD_CHART_SumofMonthlyEndpointUsagebyPlatformCCIP_CELLULAR: 'Utilizzo dati mensile (CCIP)',
	DASHBOARD_CHART_SumofMonthlyEndpointUsagebyPlatformCCIP_CELLULARDescription: 'Mostra l’utilizzo totale dati mensile sulla piattaforma CCIP',
	DASHBOARD_CHART_countByCountry_CELLULAR: 'Endpoint per paese',
	DASHBOARD_CHART_countByCountry_CELLULARDescription: 'Mostra il numero totale di endpoint in più paesi.',
	DASHBOARD_CHART_countByRoamingCountry_CELLULAR: 'Endpoint per paese roaming (cellulare)',
	DASHBOARD_CHART_countByRoamingCountry_CELLULARDescription: 'Mostra il numero di endpoint per paese roaming.',
	DASHBOARD_CHART_countByRoamingCarrier_CELLULAR: 'Endpoint per vettore roaming (cellulare)',
	DASHBOARD_CHART_countByRoamingCarrier_CELLULARDescription: 'Mostra il numero di endpoint cellulari per vettore roaming.',
	DASHBOARD_CHART_countByRoamingCarrier_SATELLITE: 'Endpoint per vettore roaming (satellitari)',
	DASHBOARD_CHART_countByRoamingCarrier_SATELLITEDescription: 'Mostra il numero di endpoint satellitari per vettore roaming.',

	SMS_HISTORY: 'Cronologia SMS',
	MAXIMUM_NUMBER_OF_CHARACTERS: 'Numero massimo di caratteri: ',
	SEND_SMS_MESSAGE: 'Invia SMS',
	SMS_NOTIFICATION_TITLE: 'Notifica SMS',
	SMS_NOTIFICATION_MESSAGE: 'Errore risposta: ',
	SMS_NOTIFICATION_MESSAGE_SUCCESS: 'Invio SMS riuscito',

	REVIEW: 'Revisione',
	PLATFORM_DETAILS: 'Dettagli piattaforma',

	UPDATING: 'Aggiornamento',
	AUTOMATION_RULE_SUCCESSFULLY_DELETED: 'Eliminazione regola automazione riuscita',
	AUTOMATION_DELETE_ERROR: 'Eliminazione regola automazione non riuscita, riprovare.',
	FETCH_DATA_ERROR: 'Impossibile recuperare i dati',
	CHANGED_BY: 'Cambiato da',
	EMAIL_SUB: 'Invia e-mail',
	CTD_USAGE: 'Utilizzo dati dal ciclo fino alla data',
	PAST24H_DATA_USAGE_EXCEEDED: 'Utilizzo dati superato nelle ultime 24 ore',
	SMS_USAGE: 'Utilizzo SMS per il ciclo fino alla data',
	EXCEEDLIMIT: 'Supera',
	APPROACHLIMIT: 'Si avvicina',
	trigger: 'Attivazione',
	TWENTYFOURHOURS: 'Ultime 24 ore',
	CTD: 'Per il ciclo fino alla data',
	ADD_RULE_SUCCESS: 'Regola creata',
	ADD_RULE_ERROR: 'Creazione regola automazione non riuscita, riprovare.',
	EDIT_RULE_SUCCESS: 'Regola modificata',
	EDIT_RULE_ERROR: 'Modifica regola automazione non riuscita, riprovare.',
	ACC_AUTOMATION: 'Automazione ACC',
	ACC_ACCOUNT_ID: 'ID account ACC',
	CREATE_NEW_AUTOMATION_RULE: 'Crea nuova regola automazione',
	DCP_AUTOMATION: 'Automazione DCP',
	DCP_ACCOUNT_ID: 'ID account DCP',
	ACC_ACCOUNT: 'Account ACC',
	RULE: 'Regola',
	ACC_IF: 'Se',
	TRESHOLD: 'Soglia',
	THEN: 'Allora',
	ACC_RULE_NAME: 'Nome regola',
	SAVE_AND_ACTIVATE: 'Salva e attiva',
	DCP_ACCOUNT: 'Account DCP',
	SELECT_BY: 'Selezionato da',
	DCP_BYTES_SENT: 'byte inviati',
	SMSES_SENT: 'SMS inviati',
	SMSES: 'SMS',
	DASHBOARD_FEEDBACK: 'Dashboard',
	MUST_BE_BETWEEN: 'Deve avere una lunghezza tra ',
	COMPANIES_STEPS: 'Passi aziende',
	USER_NAME: 'NOME UTENTE',
	ID_DASHBOARD_CHARTS_countofmonthlyendpointsmsbyplatformdcp: 'SMS al mese',
	ID_DASHBOARD_CHARTS_countofdailyendpointsmsbyplatformdcp: 'SMS al giorno',
	ID_DASHBOARD_CHARTS_sumofdailyendpointusagebyplatformdcp: 'Utilizzo endpoint giornaliero totale',
	ID_DASHBOARD_CHARTS_sumofdailyendpointusagebyplatformacc: 'Utilizzo dati giornaliero',
	ID_DASHBOARD_CHARTS_sumofmonthlyendpointusagebyplatformdcp: 'Utilizzo dati mensile',
	DASHBOARD_CHART_DeviceType_SATELLITE: 'Tipo dispositivo (satellitare)',
	DASHBOARD_CHART_DeviceType_CELLULAR: 'Tipo dispositivo (cellulare)',
	DASHBOARD_CHART_DeviceType_SATELLITEDescription: 'Mostra il numero totale di dispositivi tra diversi tipi',
	DASHBOARD_CHART_DeviceType_CELLULARDescription: 'Mostra il numero totale di dispositivi tra diversi tipi',
	AUTOMATION_ALERTS: 'Avvisi automazione',
	STATE: 'Stato',
	COMPANY_EMAIL: 'E-mail azienda',
	CUSTOM_FIELD_LABELS: 'Etichette campi personalizzati',
	MY_COMPANY: 'La mia azienda',
	AUTOMATION_ACCOUNT: 'Account',
	UPDATE_CUSTOM_FIELD_LABELS_SUCCESS: 'Impostazioni etichette personalizzate salvate',
	UPDATE_CUSTOM_FIELD_LABELS_ERROR: 'Salvataggio impostazioni etichette personalizzate non riuscito',
	WRITE_SMS_MESSAGE_HERE: 'Scrivere qui il messaggio...',
	RE_CREDENTIALING_ALLOWED: 'Rigenerazione credenziali non permessa.',
	DELETE_AUTOMATION_RULE_QUESTION: 'Eliminare regola automazione?',
	DELETE_AUTOMATION_RULE_MESSAGE: 'Confermare l’eliminazione della regola automazione ',
	PLATFORM_SUCCESSFULLY_DELETED: 'Eliminazione piattaforma riuscita',
	PLATFORM_DELETE_ERROR: 'Eliminazione piattaforma non riuscita, riprovare.',
	CELLULAR: 'Cellulare',
	SATELLITE: 'Satellitare',
	SAVE_CUSTOM_LABELS: 'Salva etichette personalizzate',

	// ccip
	ENDPOINT_PROVISIONING_INFORMATION: 'Informazioni provisioning endpoint',
	CCIP_3G: '3G',
	VOICE_ENABLED: 'Vocale attivato',
	DATA_ENABLED: 'Dati attivati',
	SMS_ENABLED: 'SMS attivati',
	VOICE_ROAMING_ENABLED: 'Roaming vocale attivato',
	DATA_ROAMING_ENABLED: 'Roaming dati attivato',
	CCIP_LTE: 'LTE',
	LTE_ENABLED: 'LTE attivato',
	ROAMING_ENABLED: 'Roaming attivato',
	MOBILE_NETWORK_ACCESS: 'Accesso rete mobile',
	VOICE_ONLY: 'Solo vocale',
	REGISTERED_ON_NETWORK: 'Registrato in rete',
	REGISTERED_ON_3G_NETWORK: 'Registrato in rete 3G',
	THIS_EMAIL_ADDRESS_IS_ALREADY_IN_USE: 'Questo indirizzo e-mail è già in uso',
	THIS_EMAIL_ADDRESS_DOES_NOT_EXIST: 'Indirizzo e-mail inesistente',
	USER_SUCCESSFULLY_DELETED: 'Eliminazione utente riuscita',
	USER_DELETE_ERROR: 'Eliminazione utente non riuscita, riprovare.',
	CATEGORIES: 'Categorie',
	TRIGGERS: 'Attivazioni',
	FETCH_DATA_LOADING_MESSAGE: 'Attendere, caricamento dati in corso',
	M2M_ACCOUNT_ID_PLATFORM: 'ID account M2M',
	M2MACCOUNTS: 'Account M2M',

	FROM_VALUE: 'DA VALORE',
	TO_VALUE: 'A VALORE',

	SEARCH_REPORTS: 'Ricerca report',
	SEARCH_INVOICES: 'Ricerca fatture',

	CUSTOM_DATA_FIELDS: 'Campi dati personalizzati',
	ENDPOINT_ACTIONS: 'Azioni endpoint per',
	RE_CREDENTIAL_NOT_ALLOWED: 'Rigenerazione credenziali non permessa',
	EDIT_ENDPOINT: 'Azioni endpoint',
	CURRENT_SIM_STATE_FOR_ENDPOINT_ID: 'Stato SIM corrente per ID endpoint',

	NETWORK_ENTITLEMENT_NAME: 'Nome diritti di rete',
	BLOCK_PREMIUM_NUMBERS: 'Blocca numeri premium',
	BLOCK_INTERNATIONAL_MO: 'Blocca SMS MO internazionali salvo paese di residenza',
	HLR_TEMLATE: 'Modello dettagli HLRHSS',
	HOTLINE_NUMBER: 'Numero linea diretta',
	FACETIME: 'FACETIME',
	VOLTE: 'Volte',
	GDSP_SESSION_ACTIVE: 'Attiva',
	GDSP_SESSION_BYTES_IN: 'Byte in ingresso',
	GDSP_SESSION_BYTES_OUT: 'Byte in uscita',
	GDSP_SESSION_LAST_IP_ADDRESS: 'Ultimo indirizzo IP',
	GDSP_SESSION_LAST_STARTED_TIMESTAMP: 'Data e ora ultimo avvio',
	GDSP_SESSION_LAST_UPDATED_TIMESTAMP: 'Data e ora ultimo aggiornamento',
	GDSP_SESSION_LAST_TERMINATED_TIMESTAMP: 'Data e ora ultima chiusura',
	GDSP_SESSION_LAST_DATA_TRANSMITED_TIMESTAMP: 'Data e ora ultimi dati trasmessi',
	GDSP_SESSION_PREVIOUS_NORMAL_TIMESTAMP: 'Data e ora normali precedenti',
	GDSP_SESSION_LAST_SMS_MO_TIMESTAMP: 'Data e ora ultimo SMS MO',
	GDSP_SESSION_LAST_SMS_MT_TIMESTAMP: 'Data e ora ultimo SMS MT',
	GDSP_SESSION_LAST_SMS_MT_STATUS: 'Stato ultimo SMS MT',
	GDSP_SESSION_LAST_SMS_MO_STATUS: 'Stato ultimo SMS MO',
	GDSP_SESSION_LAST_WAKEUP_REQUEST_TIMESTAMP: 'Data e ora ultima richiesta attivazione',
	GDSP_SESSION_LAST_WAKEUP_REQUEST_TYPE: 'Tipo ultima richiesta attivazione',
	GDSP_SESSION_LAST_WAKEUP_STATUS: 'Stato ultima attivazione',
	GDSP_SESSION_LAST_WAKEUP_STATUS_TIMESTAMP: 'Data e ora stato ultima attivazione',
	GDSP_SESSION_LAST_NOTIFICATION_FAILURE_TIMESTAMP: 'Data e ora ultimo errore di notifica',
	GDSP_SESSION_LAST_NOTIFICATION_FAILURE_REASON: 'Motivo ultimo errore di notifica',

	CUSTOM_FIELD_1: 'Campo personalizzato 1',
	CUSTOM_FIELD_2: 'Campo personalizzato 2',
	CUSTOM_FIELD_3: 'Campo personalizzato 3',
	CUSTOM_FIELD_4: 'Campo personalizzato 4',
	CUSTOM_FIELD_5: 'Campo personalizzato 5',
	CUSTOM_FIELD_6: 'Campo personalizzato 6',
	CUSTOM_FIELD_7: 'Campo personalizzato 7',
	CUSTOM_FIELD_8: 'Campo personalizzato 8',
	CUSTOM_FIELD_9: 'Campo personalizzato 9',
	CUSTOM_FIELD_10: 'Campo personalizzato 10',

	/* ----- GDSP ------- */
	REGISTRATION_STATUS: 'Stato registrazione',
	NETWORK_CONNECTIONS: 'Connessioni di rete',
	PROVISIONED_DATE_AND_TIME: 'Data e ora provisioning',

	// GDSP SIM STATE STATUS
	M2M_PLATFORM_STATUS_A: 'Attivo connesso',
	M2M_PLATFORM_STATUS_B: 'Inattivo nuovo',
	M2M_PLATFORM_STATUS_C: 'Attivo sospeso',
	M2M_PLATFORM_STATUS_D: 'Inattivo interrotto',
	M2M_PLATFORM_STATUS_I: 'Migrato da altro ambiente GDSP',
	M2M_PLATFORM_STATUS_N: 'Senza provisioning',
	M2M_PLATFORM_STATUS_O: 'Migrato ad altro ambiente GDSP',
	M2M_PLATFORM_STATUS_R: 'Attivo pronto',
	M2M_PLATFORM_STATUS_S: 'Attivo sospendi',
	M2M_PLATFORM_STATUS_T: 'Attivo prova',
	M2M_PLATFORM_STATUS_U: 'Attivo standby',
	M2M_PLATFORM_STATUS_V: 'Inattivo trasformato',
	M2M_PLATFORM_STATUS_W: 'Sospensione normativa',
	M2M_PLATFORM_STATUS_Z: 'Chiuso',
	M2M_PLATFORM_STATUS_NOT_AVAILABLE: 'Non disponibile',

	// GDSP Session tab Status Codes
	// lastWakeupStatus
	LASTWAKEUPSTATUS_IR: 'Chiamata attivazione non valida',
	LASTWAKEUPSTATUS_FI: 'Avvio attivazione non riuscito',
	LASTWAKEUPSTATUS_SI: 'Avvio attivazione riuscito',
	LASTWAKEUPSTATUS_F: 'Erogazione attivazione non riuscita',
	LASTWAKEUPSTATUS_S: 'Erogazione attivazione riuscita',
	TOOLTIP_LASTWAKEUPSTATUS_IR: 'Richiesta API non valida',
	TOOLTIP_LASTWAKEUPSTATUS_FI: 'Errore da SMSC',
	TOOLTIP_LASTWAKEUPSTATUS_SI: 'Invio riuscito a SMSC',
	TOOLTIP_LASTWAKEUPSTATUS_F: 'SMSC ha emesso una notifica di mancata consegna',
	TOOLTIP_LASTWAKEUPSTATUS_S: 'SMSC ha emesso una notifica di consegna riuscita',

	// lastSmsMtStatus
	LASTSMSMTSTATUS_IR: 'Chiamata attivazione non valida',
	LASTSMSMTSTATUS_FI: 'Avvio attivazione non riuscito',
	LASTSMSMTSTATUS_SI: 'Avvio attivazione riuscito',
	LASTSMSMTSTATUS_F: 'Erogazione attivazione non riuscita',
	LASTSMSMTSTATUS_S: 'Erogazione attivazione riuscita',
	TOOLTIP_LASTSMSMTSTATUS_IR: 'Richiesta API non valida',
	TOOLTIP_LASTSMSMTSTATUS_FI: 'Errore da SMSC',
	TOOLTIP_LASTSMSMTSTATUS_SI: 'Invio riuscito a SMSC',
	TOOLTIP_LASTSMSMTSTATUS_F: 'SMSC ha emesso una notifica di mancata consegna',
	TOOLTIP_LASTSMSMTSTATUS_S: 'SMSC ha emesso una notifica di consegna riuscita',

	/* ----- GDSP END ------- */

	/* API ERROR CODES  */

	CODE_400203: 'Manca parametro obbligatorio',
	CODE_400204: 'Lunghezza parametro errata',
	CODE_401205: 'Token non valido o scaduto',
	CODE_400207: 'Un parametro non ha un valore valido rientrante tra le opzioni possibili',
	CODE_400208: 'Un parametro non ha il formato corretto',
	CODE_400209: 'Parametro duplicato',
	CODE_400299: 'Richiesta non valida',
	CODE_400301: 'Errori dai sistemi a valle',
	CODE_401301: 'Errore credenziali dei sistemi a valle',
	CODE_404301: 'DeviceID non trovato nel sistema a valle',
	CODE_404302: 'AccountID del sistema a valle non valido o non trovato',
	CODE_502100: 'Cambiamento non consentito, dispositivo già pianificato',
	CODE_400100: 'Richiesta non valida: Azione batch non consentita per i dispositivi satellitari',
	CODE_404201: 'File inesistente',
	CODE_401202: 'Nome utente o password non validi',
	CODE_400206: 'Formato nome file non valido',
	CODE_502200: 'Errore file batch',
	CODE_502202: 'Errore del database in fase di recupero',
	CODE_502203: 'Errore del database durante le modifiche',
	CODE_502204: 'Errore durante la creazione cliente',
	CODE_502301: 'Errore elaborazione API PUSH',
	CODE_404303: 'DeviceID non trovato in notifica API PUSH',
	CODE_502501: 'Errore elaborazione report SFTP',
	CODE_undefined: ' ',
	CODE_INTEGRATION_TIMEOUT: 'Impossibile raggiungere la piattaforma sottostante. Riprovare più tardi.',
	CODE_VTM_SVC_40003: 'The entered value was not recognized',
	CODE_VTM_SVC_40004: 'Unable to create the support ticket',
	CODE_VTM_SVC_40005: 'Unable to create the support ticket',
	CODE_VTM_SVC_40409: 'Ticket does not exist: It may have been removed due to AT&T data retention policy',
	CODE_VTM_SVC_40411: 'Ticket does not exist or it is closed by an agent: It may have been removed due to AT&T data retention policy',
	CODE_VTM_SVC_40027: 'Cannot update canceled or closed support ticket: This ticket is closed or canceled and cannot be updated.',
	CODE_VTM_SVC_40035: 'Unable to add engagement: Engagement ticket already exist',
	CODE_VTM_SVC_50001: 'Something went wrong: Try again. If the problem persists, please report your issue by calling AT&T',
	CODE_VTM_SVC_40038: 'Unable to update the support ticket: Circuit details are the same',
	CODE_VTM_SVC_40039: 'Cannot create the support ticket: Asset info is missing in the system',
	CODE_VTM_SVC_40040: 'Cannot add a PVC value to support ticket: pvcID details already exist in the system',
	CODE_VTM_SVC_40041: 'Unable to update support ticket: clkNumber does not exist',
	CODE_VTM_SVC_40042: 'Unable to update support ticket: pvcID does not exist',
	CODE_VTM_SVC_40043: 'Unable to retrieve ticket history: Please try again later',
	CODE_VTM_SVC_40010: 'Unable to create the support ticket: Field exceeded maximum of 199 characters. Please correct the length and try again',
	CODE_VTM_SVC_40053: 'Unable to create support ticket: Case ID does not have Ticket Role defined. or is invalid support role',
	CODE_EOD_11: 'Unable to process the request: Please try again in a few minutes.',
	CODE_EOD_59: 'Cannot deactivate device without static IP: Before a device can be deactivated (put on hold), it needs to have a static IP. You can still deactivate this device by deprovisioning it (purge) from the system.',
	CODE_EOD_60: 'Cannot deactivate inactive device',
	CODE_EOD_46: 'The SIM is already in the provisioning queue',
	CODE_EOD_30: 'The SIM cannot be suspended using a past date',
	CODE_EOD_32: 'Internal system error: Contact customer support or create a trouble ticket to get help with this issue',
	CODE_EOD_41: 'Cannot change profile for suspended devices: Contact customer support or create a trouble ticket to get help with this issue',
	CODE_EOD_45: 'Invalid SIM or SIM does not belong to your account: Contact customer support or create a trouble ticket to get help with this issue',
	CODE_EOD_16: 'SIM has reached the maximum number of Rate Plan changes allowed during the Billing Cycle: Could not complete the Rate Plan change request. Please try again in the next billing cycle.',
	CODE_EOD_17: 'SIM has reached the maximum number Rate Plan changes allowed for the day: Could not complete the Rate Plan change request. Please try again tomorrow.',
	CODE_EOD_25: 'The device is currently in a suspended state and so cannot resume before the suspended date',
	CODE_EOD_27: 'The SIM cannot be suspended using a past date',
	CODE_EOD_3: 'Device is already inactive',
	CODE_EOD_401: 'Unable to authorize user performing the batch action with device platform.',

	/* API ERROR CODES  */

	SERVICE_STATUS: 'Stato 360L',
	SERVICESTATUS: 'Stato 360L',
	OEM: 'Sottoconti',
	ROAMING_COUNTRY: 'Paese roaming',
	ROAMING_CARRIER: 'Vettore roaming',
	ROAMINGCOUNTRY: 'Paese roaming',
	ROAMINGCARRIER: 'Vettore roaming',
	OEM_TOOLTIP: 'Account 360L collegati',
	SIM_STATE: 'Stato SIM',

	NO_DATA_ENDPOINT_HISTORY: 'Nessuna cronologia endpoint',
	PRINT_PDF: 'Stampa PDF',
	USER_GUIDE: 'Manuale dell’utente',
	USER_GUIDE_MENU: 'Menu manuale dell’utente',
	FAQ_MENU: 'Menu domande frequenti',
	TABLE_OF_CONTENTS: 'Sommario',
	RATE_PLAN_TYPE: 'Tipo piano tariffario',
	SUBSCRIPTION_PRICE: 'Prezzo abbonamento',
	ACCOUNT_CHARGE: 'Addebito account',
	PAYMENT_TYPE: 'Tipo di pagamento',
	TERM: 'Termine',
	USAGE_LIMIT: 'Limite utilizzo',
	TIERING_FLAG: 'Contrassegno livelli',

	RATE_PLAN_CHANGE: 'Cambiamento piano tariffario',
	RATE_PLAN_CHANGED_TO: 'Si è scelto di cambiare il piano tariffario a',
	SCHEDULE_RATE_PLAN_CHANGE: 'Pianifica cambiamento piano tariffario',
	RATE_PLAN_CHANGE_IS_SUCCESSFULLY_SCHEDULED_ERROR: 'Pianificazione cambiamento piano tariffario non riuscita.',
	RATE_PLAN_CHANGE_IS_SUCCESSFULLY_SCHEDULED_FOR: 'Cambiamento del piano tariffario pianificato per',
	RATE_PLAN_IS_CHANGED_TO: 'Piano tariffario cambiato a ',
	FAILED_TO_PROVISION_AFTER_RATE_PLAN_CHANGE: ' provisioning non riuscito dopo cambiamento piano tariffario. ',
	SEND_EMAIL_TO_CONSUMER: 'Invia e-mail a consumatore',
	PUSH_API: 'API push',
	INITIATE_OTA_SWAP: 'Avvia scambio OTA',
	UPDATE_PCRF: 'Aggiorna PCRF',
	CHANGE_DEVICES_NETWORK_ENTITLEMENT: 'Cambia diritti di rete del dispositivo',
	CHANGE_DEVICES_SIM_STATE: 'Cambia stato SIM del dispositivo',
	CHANGE_DEVICES_RATE_PLAN: 'Cambia piano tariffario del dispositivo',
	NUMBER_OF_DAYS: 'Numero di giorni',
	NUMBER_OF_HOURS: 'Numero di ore (max 24h)',
	AT: 'Alle',
	FILTER: 'FILTRO',
	FOLLOW_UP_WITH: 'Dare seguito a',
	CUSTOM: 'Personalizzato',
	ELIGIBLE_RATE_PLAN: 'Piano tariffario idoneo',
	CHOOSE_RATE_PLAN: 'Scegli piano tariffario',
	CUSTOMER_ID: 'ID cliente',
	INVALID_URL_FORMAT: 'Formato URL non valido',
	INVALID_PORT_NUMBER: 'Numero PORTA non valido',
	INVALID_INPUT: 'Ingresso non valido',
	RATE_PLAN_CHANGE_SUPPORT: 'Errore piano tariffario, rivolgersi all’assistenza clienti.',
	NETWORK_ENTITLEMENT_PLACEHOLDER: 'XYX GPRS/LTE/SMS/IMS RES 165',
	M2M_SUB_ACCOUNT_ID: 'ID sottoconto M2M',

	/* SIM ORDERING */
	SIM_ORDERING: 'Ordinazione SIM',
	CREATE_SIM_ORDER: 'Crea ordine SIM',
	NEW_SIM_ORDER: 'Nuovo ordine',
	EXISTING_SIM_ORDER: 'ID ordine: {id}',
	M2M_ACCOUNT: 'Account M2M',
	M2M_ACCOUNT_PLACEHOLDER: 'Selezionare il proprio account M2M',
	ORDER_ID: 'ID ordine',
	ORDER_NUMBER_ID: 'ID numero ordine',
	ORDER_CONFIRMATION_NUMBER: 'Numero conferma ordine',
	ORDER_DATE: 'Data ordine',
	PURCHASE_ORDER_NUMBER: 'N. OA',
	SKU: 'SKU',
	SKU_PLACEHOLDER: 'Seleziona SKU',
	UNIT_PRICE: 'Prezzo unitario',
	QUANTITY: 'Quantità',
	QUANTITY_LIMIT_TEMPORARILY_REACHED: 'Limite quantità temporaneamente raggiunto',
	MIN_MAX_QUANTITY: 'Min {min} / Max {available} {max}',
	INCREMENT_QUANTITY: 'Incremento {increment}',
	ORDER_STATUS: 'Stato ordine',
	ORDER_STATUS_PENDING: 'In sospeso',
	ORDER_STATUS_RECEIVED: 'Ricevuto',
	ORDER_STATUS_SHIPPED: 'Spedito',
	ORDER_STATUS_COMPLETED: 'Completato',
	ORDER_STATUS_SUCCESSFUL: 'Riuscito',
	ORDER_STATUS_FAILED: 'Non riuscito',
	CONTACT_NAME: 'Nome contatto',
	TELEPHONE_NUMBER: 'Numero di telefono',
	TARGET_IMPLEMENTATION_DATE: 'Data implementazione prevista',
	EXPEDITED: 'Accelerato',
	SHIPPING_ADDRESS: 'Indirizzo spedizione',
	ADDRESS_LINE_1: 'Riga indirizzo 1',
	ADDRESS_LINE_2: 'Riga indirizzo 2',
	SHIPPING_NOTES: 'Note sulla spedizione',
	SHIPPING_NUMBER: 'Numero spedizione',
	EXPEDITED_SHIPPING: 'Spedizione accelerata',
	TOTAL_PRICE: 'Prezzo totale',
	SUBMIT_ORDER_REQUEST: 'Invia richiesta ordine',
	SUBMIT_ORDER_REQUEST_CONFIRMATION: 'Confermare l’invio di questo ordine?',
	EDIT_ORDER_REQUEST: 'Richiesta modifica ordine',
	EDIT_ORDER_REQUEST_CONFIRMATION: 'Confermare il salvataggio dei cambiamenti?',
	SAVE_UPDATES: 'Salva aggiornamenti',
	ADD_SIM_ORDER_SUCCESS: 'Ordine SIM creato',
	ADD_SIM_ORDER_ERROR: 'Creazione ordine SIM non riuscita, riprovare.',
	EDIT_SIM_ORDER_SUCCESS: 'Ordine SIM modificato',
	EDIT_SIM_ORDER_ERROR: 'Modifica ordine SIM non riuscita, riprovare.',
	PO_TOOLTIP: 'Il N. OA verrà generato alll’invio dell’ordine SIM',

	FILTERS: 'Filtri',
	FILTER_CATEGORY_STATUS: 'Stato',
	FILTER_CATEGORY_PLATFORM: 'Piattaforma M2M',
	FILTER_CATEGORY_SKU: 'SKU',
	FILTER_CATEGORY_EXPEDITED: 'Accelerato',
	FILTER_VALUE_PENDING: 'In sospeso',
	FILTER_VALUE_RECEIVED: 'Ricevuto',
	FILTER_VALUE_SHIPPED: 'Spedito',
	FILTER_VALUE_COMPLETED: 'Completato',
	FILTER_VALUE_YES: 'Sì',
	FILTER_VALUE_NO: 'No',

	SIM_ORDERING_TITLE: 'Ordinazione SIM',
	SIM_ORDERING_SUBTITLE: 'Selezionare qui sotto le SIM che possono essere ordinate.',
	SIM_ORDERING_CONTRACT_RESTRAINTS: 'Limitazioni contrattuali',
	SIM_ORDERING_CONTRACT_RESTRAINTS_SUBTITLE: 'Impostare limitazioni per il processo di ordinazione SIM.',
	SIM_ORDERING_MIN_QUANTITY_ALLOWED: 'Quantità minima permessa',
	SIM_ORDERING_MIN_QUANTITY_ALLOWED_PLACEOLDER: 'Quantità minima',
	SIM_ORDERING_MAX_QUANTITY_ALLOWED: 'Quantità massima permessa',
	SIM_ORDERING_PER_PERIOD: 'Per periodo',
	SIM_ORDERING_PER_PERIOD_PLACEHOLDER: 'Selezionare un periodo',
	SIM_ORDERING_MAX_QUANTITY_ALLOWED_PLACEOLDER: 'Quantità massima',
	SIM_ORDERING_SIM_SKU: 'SKU SIM',
	SIM_ORDERING_SIM_SKU_PLACEHOLDER: 'Inserire qui lo SKU della SIM',
	SIM_ORDERING_UNIT_PRICE: 'Prezzo unitario',
	SIM_ORDERING_UNIT_PRICE_PLACEHOLDER: 'Prezzo unitario',
	SIM_ORDERING_ALLOWED_TO_ORDER: 'Ordinazione permessa',
	SIM_ORDERING_SKU_DESCRIPTION: 'Descrizione',
	SIM_ORDERING_SKU_DESCRIPTION_PLACEHOLDER: 'Inserire la descrizione dello SKU',
	SIM_ORDERING_SKU_ADD_SKU: '+ Aggiungi un altro SKU SIM',
	SIM_ORDERING_VALIDATOR_MAX_QUANTITY: 'quantità massima',
	SIM_ORDERING_VALIDATOR_MIN_QUANTITY: 'quantità minima',
	ADD_RESTRAINTS_SUCCESS: 'Aggiunte limitazioni contrattuali',
	ADD_RESTRAINTS_ERROR: 'Aggiunta limitazioni contrattuali non riuscita',
	UPDATE_RESTRAINTS_SUCCESS: 'Aggiornate limitazioni contrattuali',
	UPDATE_RESTRAINTS_ERROR: 'Aggiornamento limitazioni contrattuali non riuscito',
	ADD_SIM_ORDERING_SKUS_SUCCESS: 'SKU ordinazione SIM aggiornati',
	ADD_SIM_ORDERING_SKUS_ERROR: 'Aggiunta SKU ordinazione SIM non riuscita',

	COMPANY_REPORTS: 'Report',
	COMPANY_REPORTS_SETTINGS_SUCCESSFULLY_UPDATED: 'Impostazioni report aziendali salvate',
	ERROR_WHILE_UPDATING_COMPANY_REPORTS: 'Errore in fase di aggiornamento report aziendali',
	ERROR_WHILE_FETCHING_COMPANY_REPORTS: 'Errore durante il recupero della configurazione dei report',
	COMPANY_REPORTS_SUBTITLE: 'Selezionare qui sotto i report che si desidera ricevere.',
	DAILY_REPORTS: 'REPORT GIORNALIERI',
	MONTHLY_REPORTS: 'REPORT MENSILI',
	PREFERENCES_MENU: 'Menu preferenze',
	PREFERENCES: 'Preferenze',
	REFERENCE_REPORTS: 'Report riferimento',
	M2M_DATE_ADDED: 'Aggiunta data M2M',
	M2M_INITIAL_ACTIVATION_DATE: 'Data attivazione M2M',
	NOTES: 'Note',
	SAVE_NOTE: 'Salva nota',
	NOTE_TEXTAREA_PLACEHOLDER: 'Inserire qui la propria nota (max 2000 caratteri)',
	ADD_NOTE_SUCCESS: 'Nota salvata',
	ADD_NOTE_ERROR: 'Salvataggio nota non riuscito, riprovare.',
	DELETE_NOTE_SUCCESS: 'Nota eliminata',
	DELETE_NOTE_ERROR: 'Eliminazione nota non riuscita, riprovare.',
	DELETE_NOTE: 'Elimina nota',
	TICKETING_STATUS: 'Stato creazione ticket',
	UPDATE_NOTE_STATUS: 'Aggiorna stato nota',
	LAST_UPDATE: 'Ultimo aggiornamento',
	SUBMISSION_LOCATION: 'Ubicazione invio',
	RULE_STATE: 'Stato regola',
	RULE_STATE_ACTIVE: 'Attiva',
	YES: 'Sì',
	NO: 'No',
	// ANALYTICS
	CHART_CATEGORIES: 'Categorie grafico',
	GROUP_DATA_BY: 'Raggruppa i dati per',
	CHART_OPTIONS: 'Opzioni grafico',
	CREATE_NEW_CHART: 'Crea nuovo grafico',
	EDIT_CHART: 'Modifica grafico',
	DISCLAIMER: 'Esclusione di responsabilità: ',
	DATE_RANGE: 'INTERVALLO DATE',
	SHOW: 'MOSTRA',
	CYCLE: 'CICLO',
	INCREMENT_BY: 'INCREMENTO DI',
	SELECT_CUSTOM_DATE_RANGE: 'Seleziona intervallo date personalizzato',

	// Billing
	INVOICE: 'Fattura',
	INVOICE_DATE: 'Data fattura',
	CUSTOMER_NAME: 'Nome cliente',
	DOCUMENT_ID: 'ID documento',
	COUNTRY_NAME: 'Nome paese',
	TABLE_COLUMNS_INVOICES_CHANGE_SUCCESS_TITLE: 'Cambiamento impostazioni tabella fatture riuscito!',
	TABLE_COLUMNS_INVOICES_CHANGE_SUCCESS_MESSAGE: 'Le impostazioni della tabella fatture sono state aggiornate.',
	TABLE_COLUMNS_INVOICES_CHANGE_ERROR_TITLE: 'Errore cambiamento colonne tabella fatture!',
	TABLE_COLUMNS_INVOICES_CHANGE_ERROR_MESSAGE: 'Al momento è impossibile aggiornare le colonne della tabella fatture. Riprovare.',
	INVOICE_DETAILS: 'Dettagli fattura',

	// TICKETING
	TICKET_CREATED_DATE: 'Data creazione ticket',
	UPDATED_NOTE_STATUS_DATE: 'Data aggiornamento stato nota',
	LAST_UPDATED_DATE: 'Data ultimo aggiornamento',
	UPDATE_NOTE: 'Aggiorna nota',
	TABLE_TICKETS_COLUMNS_CHANGE_SUCCESS_TITLE: 'Cambiamento impostazioni tabella ticket riuscito!',
	TABLE_TICKETS_COLUMNS_CHANGE_SUCCESS_MESSAGE: 'Le impostazioni della tabella ticket sono state aggiornate.',
	TABLE_TICKETS_COLUMNS_CHANGE_ERROR_TITLE: 'Errore cambiamento colonne tabella ticket!',
	TABLE_TICKETS_COLUMNS_CHANGE_ERROR_MESSAGE: 'Impossibile aggiornare le colonne della tabella ticket in questo momento. Riprovare.',

	TODAY: 'Oggi',
	PREVIOUS_DAY: 'Giorno precedente',
	LAST_10_DAYS: 'Ultimi 10 giorni',
	LAST_30_DAYS: 'Ultimi 30 giorni',
	CUSTOM_RANGE: 'Intervallo personalizzato',
	CURRENT_CYCLE_TO_DATE: 'Per il ciclo corrente fino alla data',
	HISTORICAL: 'Cronologia',
	WEEK: 'Settimana',
	SUM_TOTALS: 'Totali somme',
	AVERAGE_TOTALS: 'Totali medie',
	LINE_GRAPH: 'Grafico lineare',
	BAR_GRAPH: 'Grafico a barre',
	WORLD_MAP_GRAPH: 'Grafico mappa del mondo',
	TOTAL_DATA_USAGE: 'Utilizzo dati totale',
	ENDPOINT_COUNT_TOTALS: 'Totali conteggio endpoint',
	NO_USAGE_SIMS: 'SIM senza utilizzo',
	TOTAL: 'Totale',
	AVERAGE_USAGE: 'Media',
	DIRECTION_INDEX: 'Indice direzione',
	PIE_CHART: 'Grafico a torta',
	ERROR_WHILE_ADDING_TICKET: 'Errore durante l’aggiunta del ticket',

	SELECT_M2M_PLATFORM: 'Selezionare la propria piattaforma M2M',
	SELECT_M2M_PLATFORM_ERROR: 'Selezionare la piattaforma M2M prima di inserire i dati',
	SELECT_YOUR_ACCOUNT: 'Selezionare il proprio account',
	CHOOSE_M2M_PLATFORM: 'Scegliere la propria piattaforma M2M',
	CHOOSE_YOUR_ACCOUNT: 'Scegliere il proprio account',

	TOTAL_VOICE_USAGE: 'Utilizzo vocale totale',
	TOTAL_SMS_USAGE: 'Utilizzo SMS totale',
	SAVE_AND_CLOSE: 'Salva e chiudi',
	// Security Features
	SECURITY_TABS_1: 'Elenco IMEI consentiti',
	SECURITY_TABS_2: 'Elenco SMS consentiti',
	SECURITY_TABS_3: 'Elenco VOCALE consentiti',
	SECURITY_TABS_4: 'Blacklist IP, URL, Porte',
	SECURITY_TABS_5: 'Elenco intervalli IP consentiti',
	SECURITY_FEATURES_1: 'IMEI',
	SECURITY_FEATURES_2: 'SMS',
	SECURITY_FEATURES_3: 'VOCALE',
	SECURITY_FEATURES_4: 'IP, URL, PORTA',
	SECURITY_FEATURES_5: 'INTERVALLO IP',
	SECURITY_FEATURES_SUCCESSFULLY_ADD: 'Cambiamento dati riuscito',
	SECURITY_FEATURES_ERROR_ADD: 'Errore in fase di aggiornamento dei dati',
	SECURITY_FEATURES_ADD_1: 'Aggiungere nuovo IMEI all’elenco dei consentiti',
	SECURITY_FEATURES_ADD_2: 'Aggiungere nuovo SMS all’elenco dei consentiti',
	SECURITY_FEATURES_ADD_3: 'Aggiungere nuovo VOCALE all’elenco dei consentiti',
	SECURITY_FEATURES_ADD_4: ' Aggiungere nuovo IP, URL, PORTA alla blacklist',
	SECURITY_FEATURES_ADD_5: 'Aggiungere nuovo intervallo IP all’elenco dei consentiti',
	SECURITY_FEATURES_EDIT_1: 'Modifica elenco IMEI consentiti',
	SECURITY_FEATURES_EDIT_2: 'Modifica elenco SMS consentiti',
	SECURITY_FEATURES_EDIT_3: 'Modifica elenco VOCALE consentito',
	SECURITY_FEATURES_EDIT_4: ' Modifica blacklist IP, URL, PORTE',
	SECURITY_FEATURES_EDIT_5: 'Modifica elenco intervallo di IP consentiti',
	SECURITY_FEATURES_M2M: 'Seleziona piattaforma M2M',
	SECURITY_FEATURES_REMOVE: 'Rimuovi da elenco consentiti',
	UPDATE: 'Aggiornamento',
	SECURITY_FEATURES: 'Funzionalità di sicurezza',
	M2M_ACCOUNT_NAME: 'NOME ACCOUNT M2M',
	DATA_SUBMITTED: 'DATA INVIO',
	FILTER_CATEGORY_M2MACCOUNT: 'Account M2M',
	SECURITY_FEATURES_SUCCESSFULLY_EXPORT: 'Esportazione riuscita',
	SECURITY_FEATURES_FAIL_EXPORT: 'Esportazione non riuscita',
	COUNTRYCODE: 'Paese',
	MINUTE: 'Minuto',
	MINUTES: 'Minuti',
	CHART_LEFT_AXIS_MINUTE: 'min',
	CHART_LEFT_AXIS_MINUTES: 'min',
	CONSUMED_MINUTE: 'Minuti utilizzati',
	CONSUMED_MB: 'MB utilizzati',
	CONSUMED_GB: 'GB utilizzati',
	CONSUMED_TB: 'TB utilizzati',
	CONSUMED_PB: 'PB utilizzati',
	CONSUMED_SMS: 'SMS utilizzati',
	NO_DESCRIPTION_FOR_GRAPH: 'Nessuna descrizione del presente grafico',
	GRAPHS: 'Grafici attualmente disponibili',
	CONSUMED_COUNT: 'Numero di endpoint',
	DOWNLOAD_CHART_AS_PNG: 'Scarica PNG',
	CUSTOMER_INVOICE_ID: 'ID cliente',
	INVOICE_ID: 'ID fattura',
	CUSTOMER_INVOICE_NAME: 'Sottoconto',
	INVOICE_COUNTRY: 'Paese',
	ACCESSTECHNOLOGY: 'Tecnologia di accesso',
	RATINGZONE: 'Area tariffaria',
	RATEPLAN: 'Piano tariffario',
	CHOOSE_FILTERS_ON_ANALYTICS_MODAL: 'Selezionare una categoria durante la prima operazione',
	NO_FILTERS_ON_ANALYTICS_MODAL: 'Nessun filtro disponibile per questa categoria',
	BILLING_CYCLE: 'Ciclo di fatturazione',
	FETCHING_DATA: 'Attendere. Recupero dati in corso...',
	NO_DATA_RECEIVED: 'Nessun dato ricevuto',
	NO_FILTERS_AVAILABLE: 'Nessun filtro disponibile',
	DATE_SUBMITTED: 'Data invio',
	NO_REPORTS_DEFINED: 'Nessun report definito',
	APPLY: 'Applica',
	OPEN_CHART_SETTINGS_PAGE: 'Impostazioni grafico',
	CUSTOMIZE_CHART_DATA: 'Personalizza dati grafico',
	ESTIMATED_AVERAGE_USAGE: 'Stima utilizzo medio',
	DELETE_DEFINED_CHART_SETTING_QUESTION: 'Eliminare impostazione grafico definito?',
	DELETE_DEFINED_CHART_SETTING_MESSAGE: 'Confermare l’eliminazione dell’impostazione',
	TOTAL_COUNT_BY_SEVERITY: 'Ticket per gravità',
	TOTAL_COUNT_BY_STATUS: 'Ticket per stato',
	INVALID_IP_ADDRESS: 'Indirizzo IP non valido',
	INVOICENUMBER: 'Numero fattura',
	CUSTOMERNAME: 'Nome cliente',
	COUNTRYNAME: 'Nome paese',
	CUSTOMERID: 'ID cliente',
	DEVICE_WAKE_UP: 'Attivazione dispositivo',
	DEVICE_WAKE_UP_NOTIFICATION_TITLE: 'Attivazione dispositivo',
	DEVICE_WAKE_UP_NOTIFICATION_MESSAGE_SUCCESS: 'Attivazione dispositivo riuscita',
	DEVICE_WAKE_UP_NOTIFICATION_MESSAGE_FAIL: 'Attivazione dispositivo non riuscita',

	FILES_FAILED_TO_UPLOAD_MESSAGE: 'Caricamento file non riuscito: ',
	ADD_ANOTHER_TRESHOLD: 'Aggiungere un’altra soglia',
	PIE_CHART_TOTAL: 'Totale',
	ADD_ATTACHMENT: 'Aggiungi allegato',
	TICKET_FILE_UPLOAD_ERROR: 'Si è verificato un errore nel sistema di backend e alcuni dei file dell’utente non sono stati aggiornati.',
	FILE_TO_LARGE_TO_UPLOAD: 'File troppo grande per essere caricato. La dimensione massima consentita per il file è 3 MB.',
	RETRY: 'Riprova',
	NO_CATEGORIES: 'Nessuna categoria disponibile per questo account',
	ZONE_NAME: 'Nome zona',
	POOL_ID: 'ID pool',

	// NOTES INVOICES
	ADD_NEW_NOTE: 'Aggiungi nota',
	EDIT_NOTE: 'Modifica nota',
	ADD_NOTE_PLACEHOLDER: 'Inserire qui la nota',
	INVOICE_NOTE_SUCCESSFULLY_UPDATED: 'Aggiornamento nota fattura riuscito',
	INVOICE_NOTE_UPDATE_FAIL: 'Aggiornamento fattura non riuscito',
	ATTACHMENTS_ADDED: 'Allegati aggiunti',
	EXTERNAL_LAST_UPDATED_BY: 'Ultimo aggiornamento da',
	EXTERNAL_LAST_UPDATED_DATE: 'Data/ora ultimo aggiornamento',
	REMOVE: 'Rimuovi',
	EndpointStatus: 'Stato',
	NetworkOperator: 'Operatore di rete',
	NetworkCountry: 'Paese della rete',
	NetworkType: 'Tipo di rete',
	TransferStatus: 'Stato trasferimento IMSI',
	NetworkEntitlement: 'ID diritti di rete',
	RecredentialTransferStatus: 'Stato rigenerazione credenziali',
	M2MDateAdded: 'Aggiunta data M2M',
	M2MInitialActivationDate: 'Data attivazione M2M',
	LAST_UPDATED_BY: 'Ultimo aggiornamento da',
	LAST_UPDATED: 'Ultimo aggiornamento',
	INVOICE_NOTE_FILE_ERROR: 'Si è verificato un errore nel sistema di back-end e alcuni dei file dell’utente non sono stati caricati.',

	BATCH_HISTORY: 'Cronologia batch',
	// DIAGNOSTIC HLRHSSDetails
	GET_HLR_HSS_DETAILS: 'Ottieni dettagli HLR/HSS',
	APN_IDENTIFIER: 'Identificatore APN',
	IP_ADDRESS_TYPE: 'Tipo indirizzo IP',
	QOS_PROFILE: 'Profilo QOS',
	PDN_GW_DYNAMIC_ALLOCATION: 'Allocazione dinamica PDN GW',
	VPLMN_ADDRESS_ALLOWED: 'Indirizzo VPLMN permesso',
	MAX_UPLOAD: 'Caricamento massimo',
	MAX_DOWNLOAD: 'Download massimo',
	SUBSCRIBER_PROFILE: 'Profilo abbonato',
	ENDPOINT_ID_ICCID: 'ID endpoint - ICCID',
	BARRING_STATUS: 'Stato esclusione',
	NETWORK_ACCESS: 'Accesso rete',
	CURRENT_VLR_NUMBER: 'Numero VLR corrente',
	PREVIOUS_VLR_NUMBER: 'Numero VLR precedente',
	CURRENT_SGSN_NUMBER: 'Numero SGSN corrente',
	PREVIOUS_SGSN_NUMBER: 'Numero SGSN precedente',
	ROAMING_RESTRICTION: 'Limitazione roaming',
	DATA_3G_ENABLED: 'Dati 3G attivati',
	NETWORK_STATUS: 'Stato rete',
	APN_LIST: 'Elenco APN',

	// DATA HISTORY
	DATA_HISTORY: 'Cronologia dati',
	DATA_VOLUME_DOWN_KB: 'Downlink volume dati (KB)',
	DATA_VOLUME_UP_KB: 'Uplink volume dati (KB)',
	DURATION_MINUTES: 'Durata (minuti)',
	SESSION_CHARGING_ID: 'ID caricamento sessione',
	ACCESS_POINT_NAME: 'APN',
	DEVICE_IP_V4: 'Indirizzo IPv4 dispositivo',
	DEVICE_IP_V6: 'Indirizzo IPv6 dispositivo',
	RATING_ZONE_NAME: 'Nome area tariffaria',
	RATING_GROUP_ID_NAME: 'Nome ID gruppo tariffario',
	RATING_GROUP_ID: 'ID gruppo tariffario',

	// SMS HISTORY WING
	MESSAGE_HISTORY: 'Cronologia messaggi',
	PORTAL_MESSAGES: 'Messaggi portale',
	SMS_LOG_ID: 'ID log SMS',
	DATE_SENT_RECEIVED: 'Data invio/ricezione',
	SENT_FROM: 'Inviato da',
	SENT_TO: 'Inviato a',
	MESSAGE_TYPE: 'Tipo messaggio',
	ZONE_MODEL: 'Modello zona',

	CTD_DATA_USAGE: 'Utilizzo dati CTD (MB)',
	CTD_SMS_MO_MT_USAGE: 'Utilizzo SMS CTD (MSG)',
	CTD_VOICE_USAGE: 'Utilizzo vocale CTD (Min)',

	ACCOUNTS: 'Account',
	MUST_BE_BETWEEN_CHARACTERS_LONG: 'La lunghezza deve essere tra {min} e {max} caratteri',
	INVALID_DOMAIN_FORMAT: 'Formato dominio non valido',
	LAST_UPDATE_DATE_TIME: 'Data/ora ultimo aggiornamento',
	LAST_UPDATED_BY_USER: 'Ultimo aggiornamento da parte dell’utente',
	INSESSION: 'In uso',
	PLEASE_WAIT_THIS_MAY_TAKE_A_FEW_MINUTES: 'Attendere. L’operazione potrebbe richiedere qualche minuto, a seconda delle dimensioni del file.',
	NO_RESULTS: 'Nessun risultato',
	SELECTED_RATE_PLAN: 'Piano tariffario selezionato',
	HSPA: 'Hspa',
	LTE: 'LTE',
	VLR: 'Vlr',
	SGSN: 'Sgsn',
	EXPORT: 'Esporta',
	FILE: 'File',
	ERROR_CODE: 'Codice errore',
	ERROR_TYPE: 'Tipo di errore',
	AT_LINE: 'Alla riga',
	EXPORT_TICKETS: 'Esporta ticket',
	EXPORT_USER_USAGE_DATA: 'Esporta i dati di utilizzo degli utenti',
	PLACEHOLDER_MODEL: 'AT&T Z431',
	NO_BATCH_ACTIONS_AVAILABLE: 'Nessuna azione batch disponibile',
	NO_BATCH_ACTIONS_AVAILABLE_SUBTITLE: 'Non ci sono attualmente azioni batch da mostrare per questa tabella',
	NO_BATCH_REPORTS_AVAILABLE: 'Nessun report batch disponibile',
	NO_BATCH_REPORTS_AVAILABLE_SUBTITLE: 'Non ci sono attualmente report batch da mostrare per questa tabella',
	MOBILE_ORIGINATING: 'Origine mobile',
	MOBILE_TERMINATING: 'Terminazione mobile',
	NA: 'NA',
	GMNA: 'GM America Settentrionale',
	POD3: 'ACC Pod3',
	POD19: 'Enterprise',
	TABS_ADD_ICON: 'Crea tabella personalizzata endpoint',
	USAGE_SUMMARIES: 'Riepilogo utilizzo',
	EMPTY_SPACE: ' ',
	// endpoints rate plans
	'ENDPOINTS_RATE_PLAN.RATE_PLAN_TYPE': 'Tipo piano tariffario',
	'ENDPOINTS_RATE_PLAN.INCLUDED_TEST_DATA': 'Dati prova inclusi',
	'ENDPOINTS_RATE_PLAN.BILLING_PLATFORM': 'Piattaforma fatturazione',
	'ENDPOINTS_RATE_PLAN.ACTIVATION_FEE': 'Canone di attivazione',
	'ENDPOINTS_RATE_PLAN.INCLUDED_TEST_SMS': 'SMS prova inclusi',
	'ENDPOINTS_RATE_PLAN.BILLING_ACCOUNT_ID': 'ID account fatturazione',
	'ENDPOINTS_RATE_PLAN.MSU_PRICE': 'Prezzo MSU',
	'ENDPOINTS_RATE_PLAN.INCLUDED_TEST_VOICE': 'Voce prova inclusa',
	'ENDPOINTS_RATE_PLAN.ZONE_MODEL_ID': 'ID modello zona',
	'ENDPOINTS_RATE_PLAN.TYPE': 'Tipo',
	'ENDPOINTS_RATE_PLAN.INCLUDED_MSU': 'MSU inclusa',
	'ENDPOINTS_RATE_PLAN.VALID_FROM': 'Valido da',
	'ENDPOINTS_RATE_PLAN.DEFAULT_RATE_PLAN': 'Piano tariffario predefinito',
	'ENDPOINTS_RATE_PLAN.BASE_RATE_PLAN': 'Piano tariffario base',
	'ENDPOINTS_RATE_PLAN.POSTPAID': 'Pagamento posticipato',
	'ENDPOINTS_RATE_PLAN.APPROVED': 'Approvato',
	'ENDPOINTS_RATE_PLAN.MONTHLY': 'AL MESE',
	PASSWORDS_DO_NOT_MATCH: 'Le password non corrispondono',
	NO_WHITE_SPACE_ONLY: 'Il testo deve contenere lettere e/o numeri',
	NO_RATE_PLAN_SELECTED: 'Nessun piano tariffario selezionato',
	NO_RATE_PLANS_UNDERLYING_PLATFORM: 'Si è verificato un errore sulla piattaforma sottostante e la modifica del piano tariffario non è attualmente disponibile.',
	ENDPOINT_PROVISIONED_STATE_CHANGE_SUCCESS: 'Provisioning endpoint ID {args1} riuscito. Stato IMSI cambiato in {args2}',
	ENDPOINT_PROVISIONED_STATE_CHANGE_FAIL: 'Provisioning ID endpoint {args1} non riuscito dopo cambiamento stato IMSI. Ottieni assistenza',

	// AUTH SIGN IN CALLBACK
	AUTHENTICATION_FAILED: 'Autenticazione non riuscita',
	AUTHENTICATION_IN_PROGRESS: 'Autenticazione in corso Attendere...',
	MEGABYTES: 'Megabyte',
	TICKETS: 'Ticket',
	USER_GROUPS: 'Gruppi utenti',
	RECENT_SESSIONS: 'Sessioni recenti',
	ACTIVE_SESSION_SESSION_START_TIME: 'ORA SESSIONE INIZIO',
	ACTIVE_SESSION_TOTAL_VOLUME: 'VOLUME TOTALE',
	ACTIVE_SESSION_TRANSMITTING: 'TRASMISSIONE',
	ACTIVE_SESSION_RECEIVING: 'RICEZIONE',
	CURRENTLY_ACTIVE_SESSION: 'Sessione attualmente attiva',
	ACTIVE_SESSION_CARRIER: 'VETTORE',
	ACTIVE_SESSION_IP_ADDRESS: 'INDIRIZZO IP',
	RESET_TO_DEFAULT: 'Ripristina impostazioni predefinite',
	DATE_TIME: 'Data/ora',
	SESSION_END: 'Fine sessione',
	SEND: 'Invia',
	SEND_SMS_TO_THIS_DEVICE: 'Invia SMS a questo dispositivo.',
	TYPE_A_MESSAGE: 'Digiti un messaggio che desidera inviare a questo dispositivo utilizzando il servizio di messaggistica SMS. Potranno essere applicate tariffe standard.',
	MESSAGES_WILL_APPEAR: 'I messaggi appariranno come inviati e compariranno nei rapporti di utilizzo degli SMS. Potranno essere applicate tariffe.',
	SENT_MESSAGES_HISTORY: 'Cronologia messaggi spediti',
	CHARACTERS_REMAINING: '{remaining} caratteri rimanenti...',
	EXPORT_SESSIONS_DATA_FOR_ID: 'Sessioni recenti per {id}',
	EXPORT_DATA: 'Esporta dati',
	BYTE_ABBREVIATION: 'B',
	UTC_TIME_ZONE: ' UTC ',
	SEND_SMS_TO_ENDPOINT: 'Invia SMS a endpoint',
	SMS: 'SMS',
	OFFPEAK_USAGE_FLAG: 'Contrassegno utilizzo fuori dalle ore di punta',
	DATA_USAGE_TOTAL: 'Utilizzo dati - Totale non elaborato',
	DATA_USAGE_UPLINK: 'Utilizzo dati - Uplink non elaborato',
	DATA_USAGE_DOWNLINK: 'Utilizzo dati - Downlink non elaborato',
	DATA_USAGE_ROUNDED: 'Utilizzo dati - Arrotondato',
	DEVICE_IP_ADDRESS: 'Indirizzo IP dispositivo',
	SESSION_DURATION: 'Durata sessione',
	RECORD_SEQUENCE_NUMBER: 'Numero sequenza record',
	CHARGING_ID: 'ID caricamento',
	SESSION_CLOSE_CAUSE: 'Causa chiusura sessione',
	TECH_TYPE: 'Tipo tecnologia',
	PGW_ADDRESS: 'Indirizzo PGW',
	TIME_OF_FIRST_USAGE: 'Ora primo utilizzo',
	TIME_OF_LAST_USAGE: 'Ora ultimo utilizzo',
	TIME_USAGE_DURATION: 'Durata utilizzo',
	STREAM_ID: 'ID flusso',
	CELL_GLOBAL_IDENTIFIER: 'CGI (Cell Global Identifier, identificativo globale cellula)',
	SERVICE_AREA_IDENTIFIER: 'SAI (Service Area Identifier, identificativo area servizio)',
	ROUTING_AREA_IDENTIFIER: 'RAI (Routing Area Identifier, identificativo area di routing)',
	TRACKING_AREA_IDENTITY: 'TAI (Tracking Area Identity, identità area rilevamento)',
	E_UTRAN_CGI: 'ECGI (E-UTRAN CGI)',
	LOCATION_AREA_IDENTIFIER: 'LAI (Location Area Identifier, identificativo area posizione)',
	SERVING_SGSN: 'SGSN di servizio',
	COUNTRY_LASTEST_SESSION: 'Paese (sessione più recente)',
	CARRIER_LASTEST_SESSION: 'Vettore (sessione più recente)',
	BILLABLE_FLAG: 'Contrassegno fatturabile',
	CYCLE_MONTH: 'Mese ciclo',
	CYCLE_YEAR: 'Anno ciclo',
	ASSIGNED_RATE_PLAN_ID: 'ID piano tariffario assegnato',
	ASSIGNED_RATING_ZONE_ID: 'ID area tariffaria assegnata',
	RATE_PLAN_VERSION_ID: 'ID versione piano tariffario',
	SERVICE_TYPE: 'Tipo di servizio',
	TRAFFIC_DETAIL_ID: 'ID dettagli traffico',
	RECORD_RECEIVED_DATE: 'Registra dati ricevuti',
	RECORD_OPEN_TIME: 'Ora apertura registrata',
	OPERATOR_NETWORK: 'Rete operatore',
	OPERATOR_ACCOUNT_ID: 'ID account operatore',
	TAP_CODE: 'Codice TAP',
	USAGE_HISTORY: 'Cronologia utilizzo',
	DATA: 'Dati',
	VOICE: 'Vocale',
	HLR_HSS_DETAILS_FOR_ENDPOINT: 'Dettagli HLR/HSS per ID endpoint {id}',
	NEED_HELP: 'Hai bisogno di aiuto?',
	MB: ' MB',
	CONNECTION_STATUS: 'Stato connessione',
	MESSAGE_CONTENT: 'Contenuto messaggio',
	B: 'B',
	CYCLE_TO_DATE_USAGE_TOTAL: 'Totale utilizzo ciclo fino alla data',
	AVERAGE_DAILY_USE: 'Utilizzo giornaliero medio',
	DATA_USAGE_DOWNLINK_CHART: 'Downlink',
	DATA_USAGE_UPLINK_CHART: 'Uplink',
	GDSP: 'GDSP',
	SECOND: 'Secondo',
	SECONDS: 'Secondi',
	DURATION_RAW: 'Durata - grezza',
	DURATION_ROUNDED: 'Durata - arrotondata',
	USAGE_TYPE: 'Tipo utilizzo',
	SELECT_BILLING_CYCLE: 'Seleziona ciclo di fatturazione',
	RANGE: 'Intervallo',
	CUSTOM_FIELDS_VALUES_UPDATED: 'Aggiornamento valori campi personalizzati riuscito',
	CUSTOM_FIELDS_VALUES_UPDATED_ERROR: 'Aggiornamento valori campi personalizzati non riuscito',
	SXMACC: 'SXMACC',
	CHARTS_BILLING_CYCLE_ABBREVIATION: 'BC {billcycle}',
	DATE_RANGE_INPUT_LABEL: 'Da: {startDate} A: {endDate}',
	UH_OH_THERE_WERE_SOME_PROBLEMS_TRYING_TO_LOG_YOU_IN_PLEASE_RETRY_AGAIN_IN_A_FEW_MOMENTS: 'Si sono verificati problemi durante il tentativo di accesso. Riprovare tra qualche minuto. Se il problema continua, rivolgersi all’assistenza tecnica.',

	//  LANGUAGE OPTIONS
	ENGLISH: 'English (USA)',
	FRANCAIS: 'Français (France)',
	ESPANOL: 'Español (España)',
	ITALIANO: 'Italiano (Italia)',
	NEDERLANDS: 'Netherlands (Holland)',
	DEUTCHE: 'Deutsche (Deutschland)',
	PORTUGUES: 'Português (Brasil)',
	CHINESE: '中文 (中国)',

	//  COUNTRIES
	COUNTRY_UNITED_STATES: 'Stati Uniti',
	COUNTRY_AFGHANISTAN: 'Afghanistan',
	COUNTRY_ALAND_ISLANDS: 'Aland, Isole',
	COUNTRY_ALBANIA: 'Albania',
	COUNTRY_ALGERIA: 'Algeria',
	COUNTRY_AMERICAN_SAMOA: 'Samoa Americane',
	COUNTRY_ANDORRA: 'Andorra',
	COUNTRY_ANGOLA: 'Angola',
	COUNTRY_ANGUILLA: 'Anguilla',
	COUNTRY_ANTARCTICA: 'Antartide',
	COUNTRY_ANTIGUA_AND_BARBUDA: 'Antigua e Barbuda',
	COUNTRY_ARGENTINA: 'Argentina',
	COUNTRY_ARMENIA: 'Armenia',
	COUNTRY_ARUBA: 'Aruba',
	COUNTRY_AUSTRALIA: 'Australia',
	COUNTRY_AUSTRIA: 'Austria',
	COUNTRY_AZERBAIJAN: 'Azerbaigian',
	COUNTRY_BAHAMAS_THE: 'Bahama',
	COUNTRY_BAHRAIN: 'Bahrain',
	COUNTRY_BANGLADESH: 'Bangladesh',
	COUNTRY_BARBADOS: 'Barbados',
	COUNTRY_BELARUS: 'Bielorussia',
	COUNTRY_BELGIUM: 'Belgio',
	COUNTRY_BELIZE: 'Belize',
	COUNTRY_BENIN: 'Benin',
	COUNTRY_BERMUDA: 'Bermuda',
	COUNTRY_BHUTAN: 'Bhutan',
	COUNTRY_BOLIVIA: 'Bolivia',
	COUNTRY_BOSNIA_AND_HERZEGOVINA: 'Bosnia ed Erzegovina',
	COUNTRY_BOTSWANA: 'Botswana',
	COUNTRY_BOUVET_ISLAND: 'Bouvet, Isola',
	COUNTRY_BRAZIL: 'Brasile',
	COUNTRY_BRITISH_INDIAN_OCEAN_TERRITORY: 'Territorio Britannico dell’Oceano Indiano',
	COUNTRY_BRUNEI: 'Brunei',
	COUNTRY_BULGARIA: 'Bulgaria',
	COUNTRY_BURKINA_FASO: 'Burkina Faso',
	COUNTRY_BURUNDI: 'Burundi',
	COUNTRY_CAMBODIA: 'Cambogia',
	COUNTRY_CAMEROON: 'Camerun',
	COUNTRY_CANADA: 'Canada',
	COUNTRY_CAPE_VERDE: 'Capo Verde',
	COUNTRY_CAYMAN_ISLANDS: 'Cayman, Isole',
	COUNTRY_CENTRAL_AFRICAN_REPUBLIC: 'Repubblica Centrafricana',
	COUNTRY_CHAD: 'Ciad',
	COUNTRY_CHILE: 'Cile',
	COUNTRY_CHINA: 'Cina',
	COUNTRY_CHRISTMAS_ISLAND: 'Natale, Isola',
	COUNTRY_COCOS_KEELING_ISLANDS: 'Cocos (Keeling), Isole',
	COUNTRY_COLOMBIA: 'Colombia',
	COUNTRY_COMOROS: 'Comore',
	COUNTRY_CONGO: 'Congo',
	COUNTRY_CONGO_THE_DEMOCRATIC_REPUBLIC_OF_THE: 'Congo, Repubblica Democratica',
	COUNTRY_COOK_ISLANDS: 'Cook, Isole',
	COUNTRY_COSTA_RICA: 'Costa Rica',
	COUNTRY_COTE_D__IVOIRE_IVORY_COAST_: 'Costa d’Avorio',
	COUNTRY_CROATIA_HRVATSKA_: 'Croazia',
	COUNTRY_CUBA: 'Cuba',
	COUNTRY_CYPRUS: 'Cipro',
	COUNTRY_CZECH_REPUBLIC: 'Repubblica Ceca',
	COUNTRY_DENMARK: 'Danimarca',
	COUNTRY_DJIBOUTI: 'Gibuti',
	COUNTRY_DOMINICA: 'Dominica',
	COUNTRY_DOMINICAN_REPUBLIC: 'Repubblica Dominicana',
	COUNTRY_EAST_TIMOR: 'Timor Orientale',
	COUNTRY_ECUADOR: 'Ecuador',
	COUNTRY_EGYPT: 'Egitto',
	COUNTRY_EL_SALVADOR: 'El Salvador',
	COUNTRY_EQUATORIAL_GUINEA: 'Guinea Equatoriale',
	COUNTRY_ERITREA: 'Eritrea',
	COUNTRY_ESTONIA: 'Estonia',
	COUNTRY_ETHIOPIA: 'Etiopia',
	COUNTRY_FALKLAND_ISLANDS: 'Falkland, Isole',
	COUNTRY_FAROE_ISLANDS: 'Fær Øer, Isole',
	COUNTRY_FIJI_ISLANDS: 'Figi, Isole',
	COUNTRY_FINLAND: 'Finlandia',
	COUNTRY_FRANCE: 'Francia',
	COUNTRY_FRENCH_GUIANA: 'Guyana Francese',
	COUNTRY_FRENCH_POLYNESIA: 'Polinesia Francese',
	COUNTRY_FRENCH_SOUTHERN_TERRITORIES: 'Terre australi e antartiche francesi',
	COUNTRY_GABON: 'Gabon',
	COUNTRY_GAMBIA_THE: 'Gambia',
	COUNTRY_GEORGIA: 'Georgia',
	COUNTRY_GERMANY: 'Germania',
	COUNTRY_GHANA: 'Ghana',
	COUNTRY_GIBRALTAR: 'Gibilterra',
	COUNTRY_GREECE: 'Grecia',
	COUNTRY_GREENLAND: 'Groenlandia',
	COUNTRY_GRENADA: 'Grenada',
	COUNTRY_GUADELOUPE: 'Guadalupa',
	COUNTRY_GUAM: 'Guam',
	COUNTRY_GUATEMALA: 'Guatemala',
	COUNTRY_GUERNSEY_AND_ALDERNEY: 'Guernsey e Alderney',
	COUNTRY_GUINEA: 'Guinea',
	COUNTRY_GUINEA_BISSAU: 'Guinea-Bissau',
	COUNTRY_GUYANA: 'Guyana',
	COUNTRY_HAITI: 'Haiti',
	COUNTRY_HEARD_AND_MCDONALD_ISLANDS: 'Heard e McDonald, Isole',
	COUNTRY_HONDURAS: 'Honduras',
	'COUNTRY_HONG_KONG_S.A.R.': 'Hong Kong, Cina',
	COUNTRY_HUNGARY: 'Ungheria',
	COUNTRY_ICELAND: 'Islanda',
	COUNTRY_INDIA: 'India',
	COUNTRY_INDONESIA: 'Indonesia',
	COUNTRY_IRAN: 'Iran',
	COUNTRY_IRAQ: 'Iraq',
	COUNTRY_IRELAND: 'Irlanda',
	COUNTRY_ISRAEL: 'Israele',
	COUNTRY_ITALY: 'Italia',
	COUNTRY_JAMAICA: 'Giamaica',
	COUNTRY_JAPAN: 'Giappone',
	COUNTRY_JERSEY: 'Jersey',
	COUNTRY_JORDAN: 'Giordania',
	COUNTRY_KAZAKHSTAN: 'Kazakistan',
	COUNTRY_KENYA: 'Kenya',
	COUNTRY_KIRIBATI: 'Kiribati',
	COUNTRY_KOREA_NORTH: 'Corea del Nord',
	COUNTRY_KOREA_SOUTH: 'Corea del Sud',
	COUNTRY_KUWAIT: 'Kuwait',
	COUNTRY_KYRGYZSTAN: 'Kirghizistan',
	COUNTRY_LAOS: 'Laos',
	COUNTRY_LATVIA: 'Lettonia',
	COUNTRY_LEBANON: 'Libano',
	COUNTRY_LESOTHO: 'Lesotho',
	COUNTRY_LIBERIA: 'Liberia',
	COUNTRY_LIBYA: 'Libia',
	COUNTRY_LIECHTENSTEIN: 'Liechtenstein',
	COUNTRY_LITHUANIA: 'Lituania',
	COUNTRY_LUXEMBOURG: 'Lussemburgo',
	'COUNTRY_MACAU_S.A.R.': 'Macao, Cina',
	COUNTRY_MACEDONIA: 'Macedonia',
	COUNTRY_MADAGASCAR: 'Madagascar',
	COUNTRY_MALAWI: 'Malawi',
	COUNTRY_MALAYSIA: 'Malaysia',
	COUNTRY_MALDIVES: 'Maldive',
	COUNTRY_MALI: 'Mali',
	COUNTRY_MALTA: 'Malta',
	COUNTRY_MAN_ISLE_OF_: 'Man, Isola',
	COUNTRY_MARSHALL_ISLANDS: 'Marshall, Isole',
	COUNTRY_MARTINIQUE: 'Martinica',
	COUNTRY_MAURITANIA: 'Mauritania',
	COUNTRY_MAURITIUS: 'Maurizio',
	COUNTRY_MAYOTTE: 'Mayotte',
	COUNTRY_MEXICO: 'Messico',
	COUNTRY_MICRONESIA: 'Micronesia',
	COUNTRY_MOLDOVA: 'Moldova',
	COUNTRY_MONACO: 'Monaco',
	COUNTRY_MONGOLIA: 'Mongolia',
	COUNTRY_MONTSERRAT: 'Montserrat',
	COUNTRY_MOROCCO: 'Marocco',
	COUNTRY_MOZAMBIQUE: 'Mozambico',
	COUNTRY_MYANMAR: 'Myanmar',
	COUNTRY_NAMIBIA: 'Namibia',
	COUNTRY_NAURU: 'Nauru',
	COUNTRY_NEPAL: 'Nepal',
	COUNTRY_NETHERLANDS: 'Paesi Bassi',
	COUNTRY_NETHERLANDS_ANTILLES: 'Antille Olandesi',
	COUNTRY_NEW_CALEDONIA: 'Nuova Caledonia',
	COUNTRY_NEW_ZEALAND: 'Nuova Zelanda',
	COUNTRY_NICARAGUA: 'Nicaragua',
	COUNTRY_NIGER: 'Niger',
	COUNTRY_NIGERIA: 'Nigeria',
	COUNTRY_NIUE: 'Niue',
	COUNTRY_NORFOLK_ISLAND: 'Norfolk, Isola',
	COUNTRY_NORTHERN_MARIANA_ISLANDS: 'Marianne Settentrionali, Isole',
	COUNTRY_NORWAY: 'Norvegia',
	COUNTRY_OMAN: 'Oman',
	COUNTRY_PAKISTAN: 'Pakistan',
	COUNTRY_PALAU: 'Palau',
	COUNTRY_PALESTINIAN_TERRITORY_OCCUPIED: 'Palestina',
	COUNTRY_PANAMA: 'Panama',
	COUNTRY_PAPUA_NEW_GUINEA: 'Papua Nuova Guinea',
	COUNTRY_PARAGUAY: 'Paraguay',
	COUNTRY_PERU: 'Perù',
	COUNTRY_PHILIPPINES: 'Filippine',
	COUNTRY_PITCAIRN_ISLAND: 'Pitcairn, Isola',
	COUNTRY_POLAND: 'Polonia',
	COUNTRY_PORTUGAL: 'Portogallo',
	COUNTRY_PUERTO_RICO: 'Puerto Rico',
	COUNTRY_QATAR: 'Qatar',
	COUNTRY_REUNION: 'Riunione',
	COUNTRY_ROMANIA: 'Romania',
	COUNTRY_RUSSIA: 'Russia',
	COUNTRY_RWANDA: 'Ruanda',
	COUNTRY_SAINT_HELENA: 'Sant’Elena',
	COUNTRY_SAINT_KITTS_AND_NEVIS: 'Saint Kitts e Nevis',
	COUNTRY_SAINT_LUCIA: 'Santa Lucia',
	COUNTRY_SAINT_PIERRE_AND_MIQUELON: 'Saint Pierre e Miquelon',
	COUNTRY_SAINT_VINCENT_AND_THE_GRENADINES: 'Saint Vincent e Grenadine',
	COUNTRY_SAMOA: 'Samoa',
	COUNTRY_SAN_MARINO: 'San Marino',
	COUNTRY_SAO_TOME_AND_PRINCIPE: 'São Tomé e Príncipe',
	COUNTRY_SAUDI_ARABIA: 'Arabia Saudita',
	COUNTRY_SENEGAL: 'Senegal',
	COUNTRY_SERBIA: 'Serbia',
	COUNTRY_SEYCHELLES: 'Seicelle',
	COUNTRY_SIERRA_LEONE: 'Sierra Leone',
	COUNTRY_SINGAPORE: 'Singapore',
	COUNTRY_SLOVAKIA: 'Slovacchia',
	COUNTRY_SLOVENIA: 'Slovenia',
	COUNTRY_SOLOMON_ISLANDS: 'Salomone, Isole',
	COUNTRY_SOMALIA: 'Somalia',
	COUNTRY_SOUTH_AFRICA: 'Sudafrica',
	COUNTRY_SOUTH_GEORGIA: 'Georgia Meridionale',
	COUNTRY_SOUTH_SUDAN: 'Sudan del Sud',
	COUNTRY_SPAIN: 'Spagna',
	COUNTRY_SRI_LANKA: 'Sri Lanka',
	COUNTRY_SUDAN: 'Sudan',
	COUNTRY_SURINAME: 'Suriname',
	COUNTRY_SVALBARD_AND_JAN_MAYEN_ISLANDS: 'Svalbard e Jan Mayen, Isole',
	COUNTRY_SWAZILAND: 'eSwatini',
	COUNTRY_SWEDEN: 'Svezia',
	COUNTRY_SWITZERLAND: 'Svizzera',
	COUNTRY_SYRIA: 'Siria',
	COUNTRY_TAIWAN: 'Taiwan',
	COUNTRY_TAJIKISTAN: 'Tagikistan',
	COUNTRY_TANZANIA: 'Tanzania',
	COUNTRY_THAILAND: 'Tailandia',
	COUNTRY_TOGO: 'Togo',
	COUNTRY_TOKELAU: 'Tokelau',
	COUNTRY_TONGA: 'Tonga',
	COUNTRY_TRINIDAD_AND_TOBAGO: 'Trinidad e Tobago',
	COUNTRY_TUNISIA: 'Tunisia',
	COUNTRY_TURKEY: 'Turchia',
	COUNTRY_TURKMENISTAN: 'Turkmenistan',
	COUNTRY_TURKS_AND_CAICOS_ISLANDS: 'Turks e Caicos, Isole',
	COUNTRY_TUVALU: 'Tuvalu',
	COUNTRY_UGANDA: 'Uganda',
	COUNTRY_UKRAINE: 'Ucraina',
	COUNTRY_UNITED_ARAB_EMIRATES: 'Emirati Arabi Uniti',
	COUNTRY_UNITED_KINGDOM: 'Regno Unito',
	COUNTRY_UNITED_STATES_MINOR_OUTLYING_ISLANDS: 'Isole minori esterne degli Stati Uniti d’America',
	COUNTRY_URUGUAY: 'Uruguay',
	COUNTRY_UZBEKISTAN: 'Uzbekistan',
	COUNTRY_VANUATU: 'Vanuatu',
	COUNTRY_VATICAN_CITY_STATE_HOLY_SEE_: 'Stato della Città del Vaticano (Santa Sede)',
	COUNTRY_VENEZUELA: 'Venezuela',
	COUNTRY_VIETNAM: 'Vietnam',
	COUNTRY_VIRGIN_ISLANDS_BRITISH_: 'Isole Vergini Britanniche',
	COUNTRY_VIRGIN_ISLANDS_US_: 'Isole Vergini Americane',
	COUNTRY_WALLIS_AND_FUTUNA_ISLANDS: 'Wallis e Futuna, Isole',
	COUNTRY_WESTERN_SAHARA: 'Sahara Occidentale',
	COUNTRY_YEMEN: 'Yemen',
	COUNTRY_ZAMBIA: 'Zambia',
	COUNTRY_ZIMBABWE: 'Zimbabwe',

	//  STATE/PROVINCE
	STATE_ANDAMAN_AND_NICOBAR_ISLANDS: 'Andamane e Nicobare, Isole',
	STATE_ANDHRA_PRADESH: 'Andhra Pradesh',
	STATE_ARUNACHAL_PRADESH: 'Arunachal Pradesh',
	STATE_ASSAM: 'Assam',
	STATE_BIHAR: 'Bihar',
	STATE_CHANDIGARH: 'Chandigarh',
	STATE_CHHATTISGARH: 'Chhattisgarh',
	STATE_DADRA_AND_NAGAR_HAVELI: 'Dadra e Nagar Haveli',
	STATE_DAMAN_AND_DIU: 'Daman e Diu',
	STATE_DELHI: 'Delhi',
	STATE_GOA: 'Goa',
	STATE_GUJARAT: 'Gujarat',
	STATE_HARYANA: 'Haryana',
	STATE_HIMACHAL_PRADESH: 'Himachal Pradesh',
	STATE_JAMMU_AND_KASHMIR: 'Jammu e Kashmir',
	STATE_JHARKHAND: 'Jharkhand',
	STATE_KARNATAKA: 'Karnataka',
	STATE_KENMORE: 'Kenmore',
	STATE_KERALA: 'Kerala',
	STATE_LAKSHADWEEP: 'Lakshadweep',
	STATE_MADHYA_PRADESH: 'Andhra Pradesh',
	STATE_MAHARASHTRA: 'Maharashtra',
	STATE_MANIPUR: 'Manipur',
	STATE_MEGHALAYA: 'Meghalaya',
	STATE_MIZORAM: 'Mizoram',
	STATE_NAGALAND: 'Nagaland',
	STATE_NARORA: 'Narora',
	STATE_NATWAR: 'Natwar',
	STATE_ODISHA: 'Odisha',
	STATE_PASCHIM_MEDINIPUR: 'Paschim Medinipur',
	STATE_PONDICHERRY: 'Pondicherry',
	STATE_PUNJAB: 'Punjab',
	STATE_RAJASTHAN: 'Rajasthan',
	STATE_SIKKIM: 'Sikkim',
	STATE_TAMIL_NADU: 'Tamil Nadu',
	STATE_TELANGANA: 'Telangana',
	STATE_TRIPURA: 'Tripura',
	STATE_UTTAR_PRADESH: 'Uttar Pradesh',
	STATE_UTTARAKHAND: 'Uttarakhand',
	STATE_VAISHALI: 'Vaishali',
	STATE_WEST_BENGAL: 'Bengala Occidentale',
	STATE_BADAKHSHAN: 'Badakhshan',
	STATE_BADGIS: 'Badgis',
	STATE_BAGLAN: 'Baglan',
	STATE_BALKH: 'Balkh',
	STATE_BAMIYAN: 'Bamiyan',
	STATE_FARAH: 'Farah',
	STATE_FARYAB: 'Faryab',
	STATE_GAWR: 'Gawr',
	STATE_GAZNI: 'Gazni',
	STATE_HERAT: 'Herat',
	STATE_ALABAMA: 'Alabama',
	STATE_ALASKA: 'Alaska',
	STATE_ARIZONA: 'Arizona',
	STATE_ARKANSAS: 'Arkansas',
	STATE_GEORGIA: 'Georgia',
	STATE_NEW_YORK: 'New York',
	STATE_VERMONT: 'Vermont',
	STATE_WASHINGTON: 'Washington',
	STATE_HILMAND: 'Hilmand',
	STATE_JAWZJAN: 'Jawzjan',
	STATE_KABUL: 'Kabul',
	STATE_KAPISA: 'Kapisa',
	STATE_KHAWST: 'Khawst',
	STATE_KUNAR: 'Kunar',
	STATE_LAGMAN: 'Lagman',
	STATE_LAWGHAR: 'Lawghar',
	STATE_NANGARHAR: 'Nangarhar',
	STATE_NIMRUZ: 'Nimruz',
	STATE_NURISTAN: 'Nuristan',
	STATE_PAKTIKA: 'Paktika',
	STATE_PAKTIYA: 'Paktiya',
	STATE_PARWAN: 'Parwan',
	STATE_QANDAHAR: 'Kandahar',
	STATE_QUNDUZ: 'Qunduz',
	STATE_SAMANGAN: 'Samangan',
	STATE_SAR_E_PUL: 'Sar-e Pul',
	STATE_TAKHAR: 'Takhar',
	STATE_URUZGAN: 'Uruzgan',
	STATE_WARDAG: 'Wardag',
	STATE_ZABUL: 'Zabul',
	STATE_BERAT: 'Berat',
	STATE_BULQIZE: 'Bulqize',
	STATE_DELVINE: 'Delvine',
	STATE_DEVOLL: 'Devoll',
	STATE_DIBRE: 'Dibre',
	STATE_DURRES: 'Durres',
	STATE_ELBASAN: 'Elbasan',
	STATE_FIER: 'Fier',
	STATE_GJIROKASTER: 'Argirocastro',
	STATE_GRAMSH: 'Gramsh',
	STATE_HAS: 'Has',
	STATE_KAVAJE: 'Kavaje',
	STATE_KOLONJE: 'Kolonje',
	STATE_KORCE: 'Korce',
	STATE_KRUJE: 'Kruje',
	STATE_KUCOVE: 'Kucove',
	STATE_KUKES: 'Kukes',
	STATE_KURBIN: 'Kurbin',
	STATE_LEZHE: 'Lezhe',
	STATE_LIBRAZHD: 'Librazhd',
	STATE_LUSHNJE: 'Lushnje',
	STATE_MALLAKASTER: 'Mallakaster',
	STATE_MALSI_E_MADHE: 'Malsi e Madhe',
	STATE_MAT: 'Mat',
	STATE_MIRDITE: 'Mirdite',
	STATE_PEQIN: 'Peqin',
	STATE_PERMET: 'Permet',
	STATE_POGRADEC: 'Pogradec',
	STATE_PUKE: 'Puke',
	STATE_SARANDE: 'Sarande',
	STATE_SHKODER: 'Shkoder',
	STATE_SKRAPAR: 'Skrapar',
	STATE_TEPELENE: 'Tepelene',
	STATE_TIRANE: 'Tirana',
	STATE_TROPOJE: 'Tropoje',
	STATE_VLORE: 'Vlore',
	STATE_AYN_DAFLAH: 'Ayn Daflah',
	STATE_AYN_TAMUSHANAT: 'Ayn Tamushanat',
	STATE_ADRAR: 'Adrar',
	STATE_ALGIERS: 'Algieri',
	STATE_ANNABAH: 'Annabah',
	STATE_BASHSHAR: 'Bashshar',
	STATE_BATNAH: 'Batnah',
	STATE_BIJAYAH: 'Bijayah',
	STATE_BISKRAH: 'Biskrah',
	STATE_BLIDAH: 'Blidah',
	STATE_BUIRAH: 'Buirah',
	STATE_BUMARDAS: 'Bumardas',
	STATE_BURJ_BU_ARRIRIJ: 'Burj Bu Arririj',
	STATE_GHALIZAN: 'Ghalizan',
	STATE_GHARDAYAH: 'Ghardayah',
	STATE_ILIZI: 'Ilizi',
	STATE_JIJILI: 'Jijili',
	STATE_JILFAH: 'Jilfah',
	STATE_KHANSHALAH: 'Khanshalah',
	STATE_MASILAH: 'Masilah',
	STATE_MIDYAH: 'Midyah',
	STATE_MILAH: 'Milah',
	STATE_MUASKAR: 'Muaskar',
	STATE_MUSTAGHANAM: 'Mustaghanam',
	STATE_NAAMA: 'Naama',
	STATE_ORAN: 'Orano',
	STATE_OUARGLA: 'Ouargla',
	STATE_QALMAH: 'Qalmah',
	STATE_QUSTANTINAH: 'Qustantinah',
	STATE_SAKIKDAH: 'Sakikdah',
	STATE_SATIF: 'Satif',
	STATE_SAYDA: 'Sayda',
	STATE_SIDI_BAN_AL___ABBAS: 'Sidi ban-al-’Abbas',
	STATE_SUQ_AHRAS: 'Suq Ahras',
	STATE_TAMANGHASAT: 'Tamanghasat',
	STATE_TIBAZAH: 'Tibazah',
	STATE_TIBISSAH: 'Tibissah',
	STATE_TILIMSAN: 'Tilimsan',
	STATE_TINDUF: 'Tinduf',
	STATE_TISAMSILT: 'Tisamsilt',
	STATE_TIYARAT: 'Tiyarat',
	STATE_TIZI_WAZU: 'Tizi Wazu',
	STATE_UMM_AL_BAWAGHI: 'Umm-al-Bawaghi',
	STATE_WAHRAN: 'Wahran',
	STATE_WARQLA: 'Warqla',
	STATE_WILAYA_D_ALGER: 'Provincia di Algieri',
	STATE_WILAYA_DE_BEJAIA: 'Provincia di Bejaia',
	STATE_WILAYA_DE_CONSTANTINE: 'Provincia di Costantina',
	STATE_AL_AGHWAT: 'al-Aghwat',
	STATE_AL_BAYADH: 'al-Bayadh',
	STATE_AL_JAZA__IR: 'al-Jaza’ir',
	STATE_AL_WAD: 'al-Wad',
	STATE_ASH_SHALIF: 'ash-Shalif',
	STATE_AT_TARIF: 'at-Tarif',
	STATE_EASTERN: 'Orientale',
	STATE_MANU__A: 'Manu’a',
	STATE_SWAINS_ISLAND: 'Swains, Isola',
	STATE_WESTERN: 'Occidentale',
	STATE_ANDORRA_LA_VELLA: 'Andorra la Vella',
	STATE_CANILLO: 'Canillo',
	STATE_ENCAMP: 'Encamp',
	STATE_LA_MASSANA: 'La Massana',
	STATE_LES_ESCALDES: 'Les Escaldes',
	STATE_ORDINO: 'Ordino',
	STATE_SANT_JULIA_DE_LORIA: 'Sant Julia de Loria',
	STATE_BENGO: 'Bengo',
	STATE_BENGUELA: 'Benguela',
	STATE_BIE: 'Bie',
	STATE_CABINDA: 'Cabinda',
	STATE_CUNENE: 'Cunene',
	STATE_HUAMBO: 'Huambo',
	STATE_HUILA: 'Huila',
	STATE_KUANDO_KUBANGO: 'Kuando-Kubango',
	STATE_KWANZA_NORTE: 'Kwanza Settentrionale',
	STATE_KWANZA_SUL: 'Kwanza Meridionale',
	STATE_LUANDA: 'Luanda',
	STATE_LUNDA_NORTE: 'Lunda Settentrionale',
	STATE_LUNDA_SUL: 'Lunda Meridionale',
	STATE_MALANJE: 'Malanje',
	STATE_MOXICO: 'Moxico',
	STATE_NAMIBE: 'Namibe',
	STATE_UIGE: 'Uige',
	STATE_ZAIRE: 'Zaire',
	STATE_OTHER_PROVINCES: 'Altre province',
	STATE_SECTOR_CLAIMED_BY_ARGENTINA_CH: 'Settore rivendicato dall’Argentina/Ch',
	STATE_SECTOR_CLAIMED_BY_ARGENTINA_UK: 'Settore rivendicato dall’Argentina/UK',
	STATE_SECTOR_CLAIMED_BY_AUSTRALIA: 'Settore rivendicato dall’Australia',
	STATE_SECTOR_CLAIMED_BY_FRANCE: 'Settore rivendicato dalla Francia',
	STATE_SECTOR_CLAIMED_BY_NEW_ZEALAND: 'Settore rivendicato dalla Nuova Zelanda',
	STATE_SECTOR_CLAIMED_BY_NORWAY: 'Settore rivendicato dalla Norvegia',
	STATE_UNCLAIMED_SECTOR: 'Settore non rivendicato',
	STATE_BARBUDA: 'Barbuda',
	STATE_SAINT_GEORGE: 'Saint George',
	STATE_SAINT_JOHN: 'Saint John',
	STATE_SAINT_MARY: 'Saint Mary',
	STATE_SAINT_PAUL: 'Saint Paul',
	STATE_SAINT_PETER: 'Saint Peter',
	STATE_SAINT_PHILIP: 'Saint Philip',
	STATE_BUENOS_AIRES: 'Buenos Aires',
	STATE_CATAMARCA: 'Catamarca',
	STATE_CHACO: 'Chaco',
	STATE_CHUBUT: 'Chubut',
	STATE_CORDOBA: 'Cordoba',
	STATE_CORRIENTES: 'Corrientes',
	STATE_DISTRITO_FEDERAL: 'Distretto Federale',
	STATE_ENTRE_RIOS: 'Entre Rios',
	STATE_FORMOSA: 'Formosa',
	STATE_JUJUY: 'Jujuy',
	STATE_LA_PAMPA: 'La Pampa',
	STATE_HIDD: 'Hidd',
	STATE_LA_RIOJA: 'La Rioja',
	STATE_MENDOZA: 'Mendoza',
	STATE_MISIONES: 'Misiones',
	STATE_NEUQUEN: 'Neuquen',
	STATE_RIO_NEGRO: 'Rio Negro',
	STATE_SALTA: 'Salta',
	STATE_SAN_JUAN: 'San Juan',
	STATE_SAN_LUIS: 'San Luis',
	STATE_SANTA_CRUZ: 'Santa Cruz',
	STATE_SANTA_FE: 'Santa Fe',
	STATE_SANTIAGO_DEL_ESTERO: 'Santiago del Estero',
	STATE_TIERRA_DEL_FUEGO: 'Terra del Fuoco',
	STATE_TUCUMAN: 'Tucuman',
	STATE_ARAGATSOTN: 'Aragatsotn',
	STATE_ARARAT: 'Ararat',
	STATE_ARMAVIR: 'Armavir',
	STATE_GEGHARKUNIK: 'Gegharkunik',
	STATE_KOTAIK: 'Kotaik',
	STATE_LORI: 'Lori',
	STATE_SHIRAK: 'Shirak',
	STATE_STEPANAKERT: 'Stepanakert',
	STATE_SYUNIK: 'Syunik',
	STATE_TAVUSH: 'Tavush',
	STATE_VAYOTS_DZOR: 'Vayots Dzor',
	STATE_YEREVAN: 'Erevan',
	STATE_ARUBA: 'Aruba',
	STATE_AUCKLAND: 'Auckland',
	STATE_AUSTRALIAN_CAPITAL_TERRITORY: 'Territorio della Capitale Australiana',
	STATE_BALGOWLAH: 'Balgowlah',
	STATE_BALMAIN: 'Balmain',
	STATE_BANKSTOWN: 'Bankstown',
	STATE_BAULKHAM_HILLS: 'Baulkham Hills',
	STATE_BONNET_BAY: 'Bonnet Bay',
	STATE_CAMBERWELL: 'Camberwell',
	STATE_CAROLE_PARK: 'Carole Park',
	STATE_CASTLE_HILL: 'Castle Hill',
	STATE_CAULFIELD: 'Caulfield',
	STATE_CHATSWOOD: 'Chatswood',
	STATE_CHELTENHAM: 'Cheltenham',
	STATE_CHERRYBROOK: 'Cherrybrook',
	STATE_CLAYTON: 'Clayton',
	STATE_COLLINGWOOD: 'Collingwood',
	STATE_FRENCHS_FOREST: 'Frenchs Forest',
	STATE_HAWTHORN: 'Hawthorn',
	STATE_JANNNALI: 'Jannnali',
	STATE_KNOXFIELD: 'Knoxfield',
	STATE_MELBOURNE: 'Melbourne',
	STATE_NEW_SOUTH_WALES: 'Nuovo Galles del Sud',
	STATE_NORTHERN_TERRITORY: 'Territorio del Nord',
	STATE_PERTH: 'Perth',
	STATE_QUEENSLAND: 'Queensland',
	STATE_SOUTH_AUSTRALIA: 'Australia Meridionale',
	STATE_TASMANIA: 'Tasmania',
	STATE_TEMPLESTOWE: 'Templestowe',
	STATE_VICTORIA: 'Victoria',
	STATE_WERRIBEE_SOUTH: 'Werribee South',
	STATE_WESTERN_AUSTRALIA: 'Australia Occidentale',
	STATE_WHEELER: 'Wheeler',
	STATE_BUNDESLAND_SALZBURG: 'Salisburghese',
	STATE_BUNDESLAND_STEIERMARK: 'Steiermark',
	STATE_BUNDESLAND_TIROL: 'Tirolo',
	STATE_BURGENLAND: 'Burgenland',
	STATE_CARINTHIA: 'Carinzia',
	STATE_KARNTEN: 'Karnten',
	STATE_LIEZEN: 'Liezen',
	STATE_LOWER_AUSTRIA: 'Bassa Austria',
	STATE_NIEDEROSTERREICH: 'Austria Inferiore',
	STATE_OBEROSTERREICH: 'Alta Austria',
	STATE_SALZBURG: 'Salisburgo',
	STATE_SCHLESWIG_HOLSTEIN: 'Schleswig-Holstein',
	STATE_STEIERMARK: 'Steiermark',
	STATE_STYRIA: 'Stiria',
	STATE_TIROL: 'Tirolo',
	STATE_UPPER_AUSTRIA: 'Austria Superiore',
	STATE_VORARLBERG: 'Vorarlberg',
	STATE_WIEN: 'Vienna',
	STATE_ABSERON: 'Abseron',
	STATE_BAKI_SAHARI: 'Baki Sahari',
	STATE_GANCA: 'Ganca',
	STATE_GANJA: 'Ganja',
	STATE_KALBACAR: 'Kalbacar',
	STATE_LANKARAN: 'Lankaran',
	STATE_MIL_QARABAX: 'Mil-Karabach',
	STATE_MUGAN_SALYAN: 'Mugan-Salyan',
	STATE_NAGORNI_QARABAX: 'Nagorno-Karabach',
	STATE_NAXCIVAN: 'Naxcivan',
	STATE_PRIARAKS: 'Priaraks',
	STATE_QAZAX: 'Qazax',
	STATE_SAKI: 'Saki',
	STATE_SIRVAN: 'Sirvan',
	STATE_XACMAZ: 'Xacmaz',
	STATE_ABACO: 'Abaco',
	STATE_ACKLINS_ISLAND: 'Acklins, Isola',
	STATE_ANDROS: 'Andros',
	STATE_BERRY_ISLANDS: 'Berry, Isole',
	STATE_BIMINIS: 'Bimini',
	STATE_CAT_ISLAND: 'Cat, Isola',
	STATE_CROOKED_ISLAND: 'Crooked, Isola',
	STATE_ELEUTHERA: 'Eleuthera',
	STATE_EXUMA_AND_CAYS: 'Exuma e Cays',
	STATE_GRAND_BAHAMA: 'Gran Bahama',
	STATE_INAGUA_ISLANDS: 'Inagua, Isole',
	STATE_LONG_ISLAND: 'Long Island',
	STATE_MAYAGUANA: 'Mayaguana',
	STATE_NEW_PROVIDENCE: 'New Providence',
	STATE_RAGGED_ISLAND: 'Ragged, Isola',
	STATE_RUM_CAY: 'Rum Cay',
	STATE_SAN_SALVADOR: 'San Salvador',
	STATE_ISA: 'Isa',
	STATE_BADIYAH: 'Badiyah',
	STATE_JIDD_HAFS: 'Jidd Hafs',
	STATE_MAHAMA: 'Mahama',
	STATE_MANAMA: 'Manama',
	STATE_SITRAH: 'Sitrah',
	STATE_AL_MANAMAH: 'al-Manamah',
	STATE_AL_MUHARRAQ: 'al-Muharraq',
	STATE_AR_RIFA__A: 'ar-Rifa’a',
	STATE_BAGAR_HAT: 'Bagar Hat',
	STATE_BANDARBAN: 'Bandarban',
	STATE_BARGUNA: 'Barguna',
	STATE_BARISAL: 'Barisal',
	STATE_BHOLA: 'Bhola',
	STATE_BOGORA: 'Bogora',
	STATE_BRAHMAN_BARIYA: 'Brahman Bariya',
	STATE_CHANDPUR: 'Chandpur',
	STATE_CHATTAGAM: 'Chattagam',
	STATE_CHITTAGONG_DIVISION: 'Chittagong, Divisione',
	STATE_CHUADANGA: 'Chuadanga',
	STATE_DHAKA: 'Dhaka',
	STATE_DINAJPUR: 'Dinajpur',
	STATE_FARIDPUR: 'Faridpur',
	STATE_FENI: 'Feni',
	STATE_GAYBANDA: 'Gaybanda',
	STATE_GAZIPUR: 'Gazipur',
	STATE_GOPALGANJ: 'Gopalganj',
	STATE_HABIGANJ: 'Habiganj',
	STATE_JAIPUR_HAT: 'Jaipur Hat',
	STATE_JAMALPUR: 'Jamalpur',
	STATE_JESSOR: 'Jessor',
	STATE_JHALAKATI: 'Jhalakati',
	STATE_JHANAYDAH: 'Jhanaydah',
	STATE_KHAGRACHHARI: 'Khagrachhari',
	STATE_KHULNA: 'Khulna',
	STATE_KISHORGANJ: 'Kishorganj',
	STATE_KOKS_BAZAR: 'Koks Bazar',
	STATE_KOMILLA: 'Komilla',
	STATE_KURIGRAM: 'Kurigram',
	STATE_KUSHTIYA: 'Kushtiya',
	STATE_LAKSHMIPUR: 'Lakshmipur',
	STATE_LALMANIR_HAT: 'Lalmanir Hat',
	STATE_MADARIPUR: 'Madaripur',
	STATE_MAGURA: 'Magura',
	STATE_MAIMANSINGH: 'Maimansingh',
	STATE_MANIKGANJ: 'Manikganj',
	STATE_MAULVI_BAZAR: 'Maulvi Bazar',
	STATE_MEHERPUR: 'Meherpur',
	STATE_MUNSHIGANJ: 'Munshiganj',
	STATE_NARAL: 'Naral',
	STATE_NARAYANGANJ: 'Narayanganj',
	STATE_NARSINGDI: 'Narsingdi',
	STATE_NATOR: 'Nator',
	STATE_NAUGAON: 'Naugaon',
	STATE_NAWABGANJ: 'Nawabganj',
	STATE_NETRAKONA: 'Netrakona',
	STATE_NILPHAMARI: 'Nilphamari',
	STATE_NOAKHALI: 'Noakhali',
	STATE_PABNA: 'Pabna',
	STATE_PANCHAGARH: 'Panchagarh',
	STATE_PATUAKHALI: 'Patuakhali',
	STATE_PIROJPUR: 'Pirojpur',
	STATE_RAJBARI: 'Rajbari',
	STATE_RAJSHAHI: 'Rajshahi',
	STATE_RANGAMATI: 'Rangamati',
	STATE_RANGPUR: 'Rangpur',
	STATE_SATKHIRA: 'Satkhira',
	STATE_SHARIATPUR: 'Shariatpur',
	STATE_SHERPUR: 'Sherpur',
	STATE_SILHAT: 'Silhat',
	STATE_SIRAJGANJ: 'Sirajganj',
	STATE_SUNAMGANJ: 'Sunamganj',
	STATE_TANGAYAL: 'Tangayal',
	STATE_THAKURGAON: 'Thakurgaon',
	STATE_CHRIST_CHURCH: 'Christ Church',
	STATE_SAINT_ANDREW: 'Saint Andrew',
	STATE_SAINT_JAMES: 'Saint James',
	STATE_SAINT_JOSEPH: 'Saint Joseph',
	STATE_SAINT_LUCY: 'Saint Lucy',
	STATE_SAINT_MICHAEL: 'Saint Michael',
	STATE_SAINT_THOMAS: 'Saint Thomas',
	STATE_BREST: 'Brest',
	STATE_HOMJEL: 'Homjel',
	STATE_HRODNA: 'Hrodna',
	STATE_MAHILJOW: 'Mahilëŭ',
	STATE_MAHILYOWSKAYA_VOBLASTS: 'Mahilëŭ, voblasc',
	STATE_MINSK: 'Minsk',
	STATE_MINSKAJA_VOBLASTS: 'Minsk, voblasc',
	STATE_PETRIK: 'Petrik',
	STATE_VICEBSK: 'Vicebsk',
	STATE_ANTWERPEN: 'Anversa',
	STATE_BERCHEM: 'Berchem',
	STATE_BRABANT: 'Brabante',
	STATE_BRABANT_WALLON: 'Brabante Vallone',
	STATE_BRUSSEL: 'Bruxelles',
	STATE_EAST_FLANDERS: 'Fiandre Orientali',
	STATE_HAINAUT: 'Hainaut',
	STATE_LIEGE: 'Liegi',
	STATE_LIMBURG: 'Limburgo',
	STATE_LUXEMBOURG: 'Lussemburgo',
	STATE_NAMUR: 'Namur',
	STATE_ONTARIO: 'Ontario',
	STATE_OOST_VLAANDEREN: 'Fiandre Orientali',
	STATE_PROVINCIE_BRABANT: 'Brabante, Provincia',
	STATE_VLAAMS_BRABANT: 'Brabante Fiammingo',
	STATE_WALLONNE: 'Vallonia',
	STATE_WEST_VLAANDEREN: 'Fiandre Occidentali',
	STATE_BELIZE: 'Belize',
	STATE_CAYO: 'Cayo',
	STATE_COROZAL: 'Corozal',
	STATE_ORANGE_WALK: 'Orange Walk',
	STATE_STANN_CREEK: 'Stann Creek',
	STATE_TOLEDO: 'Toledo',
	STATE_ALIBORI: 'Alibori',
	STATE_ATACORA: 'Atacora',
	STATE_ATLANTIQUE: 'Atlantique',
	STATE_BORGOU: 'Borgou',
	STATE_COLLINES: 'Collines',
	STATE_COUFFO: 'Couffo',
	STATE_DONGA: 'Donga',
	STATE_LITTORAL: 'Littoral',
	STATE_MONO: 'Mono',
	STATE_OUEME: 'Oueme',
	STATE_PLATEAU: 'Plateau',
	STATE_ZOU: 'Zou',
	STATE_HAMILTON: 'Hamilton',
	STATE_BUMTHANG: 'Bumthang',
	STATE_CHHUKHA: 'Chhukha',
	STATE_CHIRANG: 'Chirang',
	STATE_DAGA: 'Daga',
	STATE_GEYLEGPHUG: 'Geylegphug',
	STATE_HA: 'Ha',
	STATE_LHUNTSHI: 'Lhuntshi',
	STATE_MONGAR: 'Mongar',
	STATE_PEMAGATSEL: 'Pemagatsel',
	STATE_PUNAKHA: 'Punakha',
	STATE_RINPUNG: 'Rinpung',
	STATE_SAMCHI: 'Samchi',
	STATE_SAMDRUP_JONGKHAR: 'Samdrup Jongkhar',
	STATE_SHEMGANG: 'Shemgang',
	STATE_TASHIGANG: 'Tashigang',
	STATE_TIMPHU: 'Timphu',
	STATE_TONGSA: 'Tongsa',
	STATE_WANGDIPHODRANG: 'Wangdiphodrang',
	STATE_BENI: 'Beni',
	STATE_CHUQUISACA: 'Chuquisaca',
	STATE_COCHABAMBA: 'Cochabamba',
	STATE_LA_PAZ: 'La Paz',
	STATE_ORURO: 'Oruro',
	STATE_PANDO: 'Pando',
	STATE_POTOSI: 'Potosi',
	STATE_TARIJA: 'Tarija',
	STATE_FEDERACIJA_BOSNA_I_HERCEGOVINA: 'Bosnia ed Erzegovina, Federazione',
	STATE_REPUBLIKA_SRPSKA: 'Repubblica Serba di Bosnia ed Erzegovina',
	STATE_CENTRAL_BOBONONG: 'Central Bobonong',
	STATE_CENTRAL_BOTETI: 'Central Boteti',
	STATE_CENTRAL_MAHALAPYE: 'Central Mahalapye',
	STATE_CENTRAL_SEROWE_PALAPYE: 'Central Serowe-Palapye',
	STATE_CENTRAL_TUTUME: 'Central Tutume',
	STATE_CHOBE: 'Chobe',
	STATE_FRANCISTOWN: 'Francistown',
	STATE_GABORONE: 'Gaborone',
	STATE_GHANZI: 'Ghanzi',
	STATE_JWANENG: 'Jwaneng',
	STATE_KGALAGADI_NORTH: 'Kgalagadi Nord',
	STATE_KGALAGADI_SOUTH: 'Kgalagadi Sud',
	STATE_KGATLENG: 'Kgatleng',
	STATE_KWENENG: 'Kweneng',
	STATE_LOBATSE: 'Lobatse',
	STATE_NGAMILAND: 'Ngamiland',
	STATE_NGWAKETSE: 'Ngwaketse',
	STATE_NORTH_EAST: 'Nord Est',
	STATE_OKAVANGO: 'Okavango',
	STATE_ORAPA: 'Orapa',
	STATE_SELIBE_PHIKWE: 'Selibe Phikwe',
	STATE_SOUTH_EAST: 'Sud Est',
	STATE_SOWA: 'Sowa',
	STATE_BOUVET_ISLAND: 'Bouvet, Isola',
	STATE_ACRE: 'Acre',
	STATE_ALAGOAS: 'Alagoas',
	STATE_AMAPA: 'Amapa',
	STATE_AMAZONAS: 'Amazzonia',
	STATE_BAHIA: 'Bahia',
	STATE_CEARA: 'Ceara',
	STATE_ESPIRITO_SANTO: 'Espirito Santo',
	STATE_ESTADO_DE_SAO_PAULO: 'Stato di Sao Paulo',
	STATE_GOIAS: 'Goias',
	STATE_MARANHAO: 'Maranhao',
	STATE_MATO_GROSSO: 'Mato Grosso',
	STATE_MATO_GROSSO_DO_SUL: 'Mato Grosso do Sul',
	STATE_MINAS_GERAIS: 'Minas Gerais',
	STATE_PARA: 'Para',
	STATE_PARAIBA: 'Paraiba',
	STATE_PARANA: 'Parana',
	STATE_PERNAMBUCO: 'Pernambuco',
	STATE_PIAUI: 'Piaui',
	STATE_RIO_GRANDE_DO_NORTE: 'Rio Grande do Norte',
	STATE_RIO_GRANDE_DO_SUL: 'Rio Grande do Sul',
	STATE_RIO_DE_JANEIRO: 'Rio de Janeiro',
	STATE_RONDONIA: 'Rondonia',
	STATE_RORAIMA: 'Roraima',
	STATE_SANTA_CATARINA: 'Santa Catarina',
	STATE_SAO_PAULO: 'Sao Paulo',
	STATE_SERGIPE: 'Sergipe',
	STATE_TOCANTINS: 'Tocantins',
	STATE_BRITISH_INDIAN_OCEAN_TERRITORY: 'Territorio Britannico dell’Oceano Indiano',
	STATE_BELAIT: 'Belait',
	STATE_BRUNEI_MUARA: 'Brunei-Muara',
	STATE_TEMBURONG: 'Temburong',
	STATE_TUTONG: 'Tutong',
	STATE_BLAGOEVGRAD: 'Blagoevgrad',
	STATE_BURGAS: 'Burgas',
	STATE_DOBRICH: 'Dobrich',
	STATE_GABROVO: 'Gabrovo',
	STATE_HASKOVO: 'Haskovo',
	STATE_JAMBOL: 'Jambol',
	STATE_KARDZHALI: 'Kardzhali',
	STATE_KJUSTENDIL: 'Kjustendil',
	STATE_LOVECH: 'Lovech',
	STATE_MONTANA: 'Montana',
	STATE_OBLAST_SOFIYA_GRAD: 'Sofia, Distretto',
	STATE_PAZARDZHIK: 'Pazardzhik',
	STATE_PERNIK: 'Pernik',
	STATE_PLEVEN: 'Pleven',
	STATE_PLOVDIV: 'Plovdiv',
	STATE_RAZGRAD: 'Razgrad',
	STATE_RUSE: 'Ruse',
	STATE_SHUMEN: 'Shumen',
	STATE_SILISTRA: 'Silistra',
	STATE_SLIVEN: 'Sliven',
	STATE_SMOLJAN: 'Smoljan',
	STATE_SOFIJA_GRAD: 'Sofia',
	STATE_SOFIJSKA_OBLAST: 'Sofia, Distretto',
	STATE_STARA_ZAGORA: 'Stara Zagora',
	STATE_TARGOVISHTE: 'Targovishte',
	STATE_VARNA: 'Varna',
	STATE_VELIKO_TARNOVO: 'Veliko Tarnovo',
	STATE_VIDIN: 'Vidin',
	STATE_VRACA: 'Vraca',
	STATE_YABLANIZA: 'Yablaniza',
	STATE_BALE: 'Bale',
	STATE_BAM: 'Bam',
	STATE_BAZEGA: 'Bazega',
	STATE_BOUGOURIBA: 'Bougouriba',
	STATE_BOULGOU: 'Boulgou',
	STATE_BOULKIEMDE: 'Boulkiemde',
	STATE_COMOE: 'Comoe',
	STATE_GANZOURGOU: 'Ganzourgou',
	STATE_GNAGNA: 'Gnagna',
	STATE_GOURMA: 'Gourma',
	STATE_HOUET: 'Houet',
	STATE_IOBA: 'Ioba',
	STATE_KADIOGO: 'Kadiogo',
	STATE_KENEDOUGOU: 'Kenedougou',
	STATE_KOMANDJARI: 'Komandjari',
	STATE_KOMPIENGA: 'Kompienga',
	STATE_KOSSI: 'Kossi',
	STATE_KOURITENGA: 'Kouritenga',
	STATE_KOURWEOGO: 'Kourweogo',
	STATE_LERABA: 'Leraba',
	STATE_MOUHOUN: 'Mouhoun',
	STATE_NAHOURI: 'Nahouri',
	STATE_NAMENTENGA: 'Namentenga',
	STATE_NOUMBIEL: 'Noumbiel',
	STATE_OUBRITENGA: 'Oubritenga',
	STATE_OUDALAN: 'Oudalan',
	STATE_PASSORE: 'Passore',
	STATE_PONI: 'Poni',
	STATE_SANGUIE: 'Sanguie',
	STATE_SANMATENGA: 'Sanmatenga',
	STATE_SENO: 'Seno',
	STATE_SISSILI: 'Sissili',
	STATE_SOUM: 'Soum',
	STATE_SOUROU: 'Sourou',
	STATE_TAPOA: 'Tapoa',
	STATE_TUY: 'Tuy',
	STATE_YATENGA: 'Yatenga',
	STATE_ZONDOMA: 'Zondoma',
	STATE_ZOUNDWEOGO: 'Zoundweogo',
	STATE_BUBANZA: 'Bubanza',
	STATE_BUJUMBURA: 'Bujumbura',
	STATE_BURURI: 'Bururi',
	STATE_CANKUZO: 'Cankuzo',
	STATE_CIBITOKE: 'Cibitoke',
	STATE_GITEGA: 'Gitega',
	STATE_KARUZI: 'Karuzi',
	STATE_KAYANZA: 'Kayanza',
	STATE_KIRUNDO: 'Kirundo',
	STATE_MAKAMBA: 'Makamba',
	STATE_MURAMVYA: 'Muramvya',
	STATE_MUYINGA: 'Muyinga',
	STATE_NGOZI: 'Ngozi',
	STATE_RUTANA: 'Rutana',
	STATE_RUYIGI: 'Ruyigi',
	STATE_BANTEAY_MEAN_CHEY: 'Banteay Mean Chey',
	STATE_BAT_DAMBANG: 'Bat Dambang',
	STATE_KAMPONG_CHAM: 'Kampong Cham',
	STATE_KAMPONG_CHHNANG: 'Kampong Chhnang',
	STATE_KAMPONG_SPOEU: 'Kampong Spoeu',
	STATE_KAMPONG_THUM: 'Kampong Thum',
	STATE_KAMPOT: 'Kampot',
	STATE_KANDAL: 'Kandal',
	STATE_KAOH_KONG: 'Kaoh Kong',
	STATE_KRACHEH: 'Kracheh',
	STATE_KRONG_KAEB: 'Krong Kaeb',
	STATE_KRONG_PAILIN: 'Krong Pailin',
	STATE_KRONG_PREAH_SIHANOUK: 'Krong Preah Sihanouk',
	STATE_MONDOL_KIRI: 'Mondol Kiri',
	STATE_OTDAR_MEAN_CHEY: 'Otdar Mean Chey',
	STATE_PHNUM_PENH: 'Phnum Penh',
	STATE_POUSAT: 'Pousat',
	STATE_PREAH_VIHEAR: 'Preah Vihear',
	STATE_PREY_VEAENG: 'Prey Veaeng',
	STATE_ROTANAK_KIRI: 'Rotanak Kiri',
	STATE_SIEM_REAB: 'Siem Reab',
	STATE_STUENG_TRAENG: 'Stueng Traeng',
	STATE_SVAY_RIENG: 'Svay Rieng',
	STATE_TAKAEV: 'Takaev',
	STATE_ADAMAOUA: 'Adamaoua',
	STATE_CENTRE: 'Centro',
	STATE_EST: 'Est',
	STATE_NORD: 'Nord',
	STATE_NORD_EXTREME: 'Estremo Nord',
	STATE_NORDOUEST: 'Nordovest',
	STATE_OUEST: 'Ovest',
	STATE_SUD: 'Sud',
	STATE_SUDOUEST: 'Sudovest',
	STATE_ALBERTA: 'Alberta',
	STATE_BRITISH_COLUMBIA: 'Columbia Britannica',
	STATE_MANITOBA: 'Manitoba',
	STATE_NEW_BRUNSWICK: 'Nuovo Brunswick',
	STATE_NEWFOUNDLAND_AND_LABRADOR: 'Terranova e Labrador',
	STATE_NORTHWEST_TERRITORIES: 'Territori del Nordovest',
	STATE_NOVA_SCOTIA: 'Nuova Scozia',
	STATE_NUNAVUT: 'Nunavut',
	STATE_PRINCE_EDWARD_ISLAND: 'Principe Edoardo, Isola',
	STATE_QUEBEC: 'Quebec',
	STATE_SASKATCHEWAN: 'Saskatchewan',
	STATE_YUKON: 'Yukon',
	STATE_BOAVISTA: 'Boavista',
	STATE_BRAVA: 'Brava',
	STATE_FOGO: 'Fogo',
	STATE_MAIO: 'Maio',
	STATE_SAL: 'Sal',
	STATE_SANTO_ANTAO: 'Santo Antao',
	STATE_SAO_NICOLAU: 'Sao Nicolau',
	STATE_SAO_TIAGO: 'Sao Tiago',
	STATE_SAO_VICENTE: 'Sao Vicente',
	STATE_GRAND_CAYMAN: 'Grand Cayman',
	STATE_BAMINGUI_BANGORAN: 'Bamingui-Bangoran',
	STATE_BANGUI: 'Bangui',
	STATE_BASSE_KOTTO: 'Basse-Kotto',
	STATE_HAUT_MBOMOU: 'Haut-Mbomou',
	STATE_HAUTE_KOTTO: 'Haute-Kotto',
	STATE_KEMO: 'Kemo',
	STATE_LOBAYE: 'Lobaye',
	STATE_MAMBERE_KADEI: 'Mambere-Kadei',
	STATE_MBOMOU: 'Mbomou',
	STATE_NANA_GRIBIZI: 'Nana-Gribizi',
	STATE_NANA_MAMBERE: 'Nana-Mambere',
	STATE_OMBELLA_MPOKO: 'Ombella Mpoko',
	STATE_OUAKA: 'Ouaka',
	STATE_OUHAM: 'Ouham',
	STATE_OUHAM_PENDE: 'Ouham-Pende',
	STATE_SANGHA_MBAERE: 'Sangha-Mbaere',
	STATE_VAKAGA: 'Vakaga',
	STATE_BATHA: 'Batha',
	STATE_BILTINE: 'Biltine',
	STATE_BOURKOU_ENNEDI_TIBESTI: 'Bourkou-Ennedi-Tibesti',
	STATE_CHARI_BAGUIRMI: 'Chari-Baguirmi',
	STATE_GUERA: 'Guera',
	STATE_KANEM: 'Kanem',
	STATE_LAC: 'Lac',
	STATE_LOGONE_OCCIDENTAL: 'Logone Occidentale',
	STATE_LOGONE_ORIENTAL: 'Logone Orientale',
	STATE_MAYO_KEBBI: 'Mayo-Kebbi',
	STATE_MOYEN_CHARI: 'Moyen-Chari',
	STATE_OUADDAI: 'Ouaddai',
	STATE_SALAMAT: 'Salamat',
	STATE_TANDJILE: 'Tandjile',
	STATE_AISEN: 'Aisen',
	STATE_ANTOFAGASTA: 'Antofagasta',
	STATE_ARAUCANIA: 'Araucania',
	STATE_ATACAMA: 'Atacama',
	STATE_BIO_BIO: 'Bio Bio',
	STATE_COQUIMBO: 'Coquimbo',
	STATE_LIBERTADOR_GENERAL_BERNARDO_O: 'Libertador General Bernardo O’Higgins',
	STATE_LOS_LAGOS: 'Los Lagos',
	STATE_MAGELLANES: 'Magellanes',
	STATE_MAULE: 'Maule',
	STATE_METROPOLITANA: 'Metropolitana',
	STATE_METROPOLITANA_DE_SANTIAGO: 'Metropolitana di Santiago',
	STATE_TARAPACA: 'Tarapaca',
	STATE_VALPARAISO: 'Valparaiso',
	STATE_ANHUI: 'Anhui',
	STATE_ANHUI_PROVINCE: 'Anhui, Provincia',
	STATE_ANHUI_SHENG: 'Anhui Sheng',
	STATE_AOMEN: 'Aomen',
	STATE_BEIJING: 'Beijing',
	STATE_BEIJING_SHI: 'Beijing Shi',
	STATE_CHONGQING: 'Chongqing',
	STATE_FUJIAN: 'Fujian',
	STATE_FUJIAN_SHENG: 'Fujian Sheng',
	STATE_GANSU: 'Gansu',
	STATE_GUANGDONG: 'Guangdong',
	STATE_GUANGDONG_SHENG: 'Guangdong Sheng',
	STATE_GUANGXI: 'Guangxi',
	STATE_GUIZHOU: 'Guizhou',
	STATE_HAINAN: 'Hainan',
	STATE_HEBEI: 'Hebei',
	STATE_HEILONGJIANG: 'Heilongjiang',
	STATE_HENAN: 'Henan',
	STATE_HUBEI: 'Hubei',
	STATE_HUNAN: 'Hunan',
	STATE_JIANGSU: 'Jiangsu',
	STATE_JIANGSU_SHENG: 'Jiangsu Sheng',
	STATE_JIANGXI: 'Jiangxi',
	STATE_JILIN: 'Jilin',
	STATE_LIAONING: 'Liaoning',
	STATE_LIAONING_SHENG: 'Liaoning Sheng',
	STATE_NEI_MONGGOL: 'Nei Monggol',
	STATE_NINGXIA_HUI: 'Ningxia Hui',
	STATE_QINGHAI: 'Qinghai',
	STATE_SHAANXI: 'Shaanxi',
	STATE_SHANDONG: 'Shandong',
	STATE_SHANDONG_SHENG: 'Shandong Sheng',
	STATE_SHANGHAI: 'Shanghai',
	STATE_SHANXI: 'Shanxi',
	STATE_SICHUAN: 'Sichuan',
	STATE_TIANJIN: 'Tianjin',
	STATE_XIANGGANG: 'Xianggang',
	STATE_XINJIANG: 'Xinjiang',
	STATE_XIZANG: 'Xizang',
	STATE_YUNNAN: 'Yunnan',
	STATE_ZHEJIANG: 'Zhejiang',
	STATE_ZHEJIANG_SHENG: 'Zhejiang Sheng',
	STATE_CHRISTMAS_ISLAND: 'Natale, Isola',
	STATE_COCOS_KEELING_ISLANDS: 'Cocos (Keeling), Isole',
	STATE_ANTIOQUIA: 'Antioquia',
	STATE_ARAUCA: 'Arauca',
	STATE_ATLANTICO: 'Atlantico',
	STATE_BOGOTA: 'Bogotà',
	STATE_BOLIVAR: 'Bolivar',
	STATE_BOYACA: 'Boyaca',
	STATE_CALDAS: 'Caldas',
	STATE_CAQUETA: 'Caqueta',
	STATE_CASANARE: 'Casanare',
	STATE_CAUCA: 'Cauca',
	STATE_CESAR: 'Cesar',
	STATE_CHOCO: 'Choco',
	STATE_CUNDINAMARCA: 'Cundinamarca',
	STATE_GUAINIA: 'Guainia',
	STATE_GUAVIARE: 'Guaviare',
	STATE_LA_GUAJIRA: 'La Guajira',
	STATE_MAGDALENA: 'Magdalena',
	STATE_META: 'Meta',
	STATE_NARINO: 'Narino',
	STATE_NORTE_DE_SANTANDER: 'Norte de Santander',
	STATE_PUTUMAYO: 'Putumayo',
	STATE_QUINDIO: 'Quindio',
	STATE_RISARALDA: 'Risaralda',
	STATE_SAN_ANDRES_Y_PROVIDENCIA: 'San Andres y Providencia',
	STATE_SANTANDER: 'Santander',
	STATE_SUCRE: 'Sucre',
	STATE_TOLIMA: 'Tolima',
	STATE_VALLE_DEL_CAUCA: 'Valle del Cauca',
	STATE_VAUPES: 'Vaupes',
	STATE_VICHADA: 'Vichada',
	STATE_MWALI: 'Mwali',
	STATE_NJAZIDJA: 'Njazidja',
	STATE_NZWANI: 'Nzwani',
	STATE_BOUENZA: 'Bouenza',
	STATE_BRAZZAVILLE: 'Brazzaville',
	STATE_CUVETTE: 'Cuvette',
	STATE_KOUILOU: 'Kouilou',
	STATE_LEKOUMOU: 'Lekoumou',
	STATE_LIKOUALA: 'Likouala',
	STATE_NIARI: 'Niari',
	STATE_PLATEAUX: 'Plateaux',
	STATE_POOL: 'Pool',
	STATE_SANGHA: 'Sangha',
	STATE_BANDUNDU: 'Bandundu',
	STATE_BAS_CONGO: 'Basso Congo',
	STATE_EQUATEUR: 'Equateur',
	STATE_HAUT_CONGO: 'Alto Congo',
	STATE_KASAI_OCCIDENTAL: 'Kasai Occidentale',
	STATE_KASAI_ORIENTAL: 'Kasai Orientale',
	STATE_KATANGA: 'Katanga',
	STATE_KINSHASA: 'Kinshasa',
	STATE_MANIEMA: 'Maniema',
	STATE_NORD_KIVU: 'Nord-Kivu',
	STATE_SUD_KIVU: 'Sud-Kivu',
	STATE_AITUTAKI: 'Aitutaki',
	STATE_ATIU: 'Atiu',
	STATE_MANGAIA: 'Mangaia',
	STATE_MANIHIKI: 'Manihiki',
	STATE_MAUKE: 'Mauke',
	STATE_MITIARO: 'Mitiaro',
	STATE_NASSAU: 'Nassau',
	STATE_PUKAPUKA: 'Pukapuka',
	STATE_RAKAHANGA: 'Rakahanga',
	STATE_RAROTONGA: 'Rarotonga',
	STATE_TONGAREVA: 'Tongareva',
	STATE_ALAJUELA: 'Alajuela',
	STATE_CARTAGO: 'Cartago',
	STATE_GUANACASTE: 'Guanacaste',
	STATE_HEREDIA: 'Heredia',
	STATE_LIMON: 'Limon',
	STATE_PUNTARENAS: 'Puntarenas',
	STATE_SAN_JOSE: 'San Jose',
	STATE_ABIDJAN: 'Abidjan',
	STATE_AGNEBY: 'Agneby',
	STATE_BAFING: 'Bafing',
	STATE_DENGUELE: 'Denguele',
	STATE_DIX_HUIT_MONTAGNES: 'Dix-huit Montagnes',
	STATE_FROMAGER: 'Fromager',
	STATE_HAUT_SASSANDRA: 'Haut-Sassandra',
	STATE_LACS: 'Lacs',
	STATE_LAGUNES: 'Lagunes',
	STATE_MARAHOUE: 'Marahoue',
	STATE_MOYEN_CAVALLY: 'Moyen-Cavally',
	STATE_MOYEN_COMOE: 'Moyen-Comoe',
	STATE_N__ZI_COMOE: 'N’zi-Comoe',
	STATE_SASSANDRA: 'Sassandra',
	STATE_SAVANES: 'Savanes',
	STATE_SUD_BANDAMA: 'Sud-Bandama',
	STATE_SUD_COMOE: 'Sud-Comoe',
	STATE_VALLEE_DU_BANDAMA: 'Vallee du Bandama',
	STATE_WORODOUGOU: 'Worodougou',
	STATE_ZANZAN: 'Zanzan',
	STATE_BJELOVAR_BILOGORA: 'Bjelovar-Bilogora',
	STATE_DUBROVNIK_NERETVA: 'Dubrovnik-Neretva',
	STATE_GRAD_ZAGREB: 'Zagabria',
	STATE_ISTRA: 'Istria',
	STATE_KARLOVAC: 'Karlovac',
	STATE_KOPRIVNICA_KRIZHEVCI: 'Koprivnica-Krizhevci',
	STATE_KRAPINA_ZAGORJE: 'Krapina-Zagorje',
	STATE_LIKA_SENJ: 'Lika-Senj',
	STATE_MEDHIMURJE: 'Medhimurje',
	STATE_MEDIMURSKA_ZUPANIJA: 'Medimurska Zupanija',
	STATE_OSIJEK_BARANJA: 'Osijek-Baranja',
	STATE_OSJECKO_BARANJSKA_ZUPANIJA: 'Osjecko-Baranjska Zupanija',
	STATE_POZHEGA_SLAVONIJA: 'Pozhega-Slavonija',
	STATE_PRIMORJE_GORSKI_KOTAR: 'Primorje-Gorski Kotar',
	STATE_SHIBENIK_KNIN: 'Shibenik-Knin',
	STATE_SISAK_MOSLAVINA: 'Sisak-Moslavina',
	STATE_SLAVONSKI_BROD_POSAVINA: 'Slavonski Brod-Posavina',
	STATE_SPLIT_DALMACIJA: 'Split-Dalmacija',
	STATE_VARAZHDIN: 'Varazhdin',
	STATE_VIROVITICA_PODRAVINA: 'Virovitica-Podravina',
	STATE_VUKOVAR_SRIJEM: 'Vukovar-Srijem',
	STATE_ZADAR: 'Zadar',
	STATE_ZAGREB: 'Zagabria',
	STATE_CAMAGUEY: 'Camaguey',
	STATE_CIEGO_DE_AVILA: 'Ciego de Avila',
	STATE_CIENFUEGOS: 'Cienfuegos',
	STATE_CIUDAD_DE_LA_HABANA: 'L’Avana',
	STATE_GRANMA: 'Granma',
	STATE_GUANTANAMO: 'Guantanamo',
	STATE_HABANA: 'Avana',
	STATE_HOLGUIN: 'Holguin',
	STATE_ISLA_DE_LA_JUVENTUD: 'Isla de la Juventud',
	STATE_LA_HABANA: 'L’Avana',
	STATE_LAS_TUNAS: 'Las Tunas',
	STATE_MATANZAS: 'Matanzas',
	STATE_PINAR_DEL_RIO: 'Pinar del Rio',
	STATE_SANCTI_SPIRITUS: 'Sancti Spiritus',
	STATE_SANTIAGO_DE_CUBA: 'Santiago de Cuba',
	STATE_VILLA_CLARA: 'Villa Clara',
	STATE_GOVERNMENT_CONTROLLED_AREA: 'Area sotto controllo del Governo',
	STATE_LIMASSOL: 'Limassol',
	STATE_NICOSIA_DISTRICT: 'Nicosia, Distretto',
	STATE_PAPHOS: 'Paphos',
	STATE_TURKISH_CONTROLLED_AREA: 'Area sotto controllo turco',
	STATE_CENTRAL_BOHEMIAN: 'Boemia Centrale',
	STATE_FRYCOVICE: 'Frycovice',
	STATE_JIHOCESKY_KRAJ: 'Jihocesky Kraj',
	STATE_JIHOCHESKY: 'Jihochesky',
	STATE_JIHOMORAVSKY: 'Jihomoravsky',
	STATE_KARLOVARSKY: 'Karlovarsky',
	STATE_KLECANY: 'Klecany',
	STATE_KRALOVEHRADECKY: 'Kralovehradecky',
	STATE_LIBERECKY: 'Liberecky',
	STATE_LIPOV: 'Lipov',
	STATE_MORAVSKOSLEZSKY: 'Moravskoslezsky',
	STATE_OLOMOUCKY: 'Olomoucky',
	STATE_OLOMOUCKY_KRAJ: 'Olomoucky Kraj',
	STATE_PARDUBICKY: 'Pardubicky',
	STATE_PLZENSKY: 'Plzensky',
	STATE_PRAHA: 'Praga',
	STATE_RAJHRAD: 'Rajhrad',
	STATE_SMIRICE: 'Smirice',
	STATE_SOUTH_MORAVIAN: 'Moravia Meridionale',
	STATE_STRAZ_NAD_NISOU: 'Straz nad Nisou',
	STATE_STREDOCHESKY: 'Stredochesky',
	STATE_UNICOV: 'Unicov',
	STATE_USTECKY: 'Ustecky',
	STATE_VALLETTA: 'Valletta',
	STATE_VELESIN: 'Velesin',
	STATE_VYSOCHINA: 'Vysochina',
	STATE_ZLINSKY: 'Zlinsky',
	STATE_BORNHOLM: 'Bornholm',
	STATE_FREDERIKSBORG: 'Frederiksborg',
	STATE_FYN: 'Fyn',
	STATE_HOVEDSTADEN: 'Hovedstaden',
	STATE_KOBENHAVN: 'Copenaghen',
	STATE_KOBENHAVNS_AMT: 'Copenaghen, Contea',
	STATE_KOBENHAVNS_KOMMUNE: 'Copenaghen, Comune',
	STATE_NORDJYLLAND: 'Nordjylland',
	STATE_RIBE: 'Ribe',
	STATE_RINGKOBING: 'Ringkobing',
	STATE_ROERVIG: 'Roervig',
	STATE_ROSKILDE: 'Roskilde',
	STATE_ROSLEV: 'Roslev',
	STATE_SJAELLAND: 'Sjaelland',
	STATE_SOEBORG: 'Soeborg',
	STATE_SONDERJYLLAND: 'Sonderjylland',
	STATE_STORSTROM: 'Storstrom',
	STATE_SYDDANMARK: 'Syddanmark',
	STATE_TOELLOESE: 'Toelloese',
	STATE_VEJLE: 'Vejle',
	STATE_VESTSJALLAND: 'Vestsjalland',
	STATE_VIBORG: 'Viborg',
	STATE_ALI_SABIH: 'Ali Sabih',
	STATE_DIKHIL: 'Dikhil',
	STATE_JIBUTI: 'Gibuti',
	STATE_TAJURAH: 'Tajurah',
	STATE_UBUK: 'Ubuk',
	STATE_SAINT_DAVID: 'Saint David',
	STATE_SAINT_LUKE: 'Saint Luke',
	STATE_SAINT_MARK: 'Saint Mark',
	STATE_SAINT_PATRICK: 'Saint Patrick',
	STATE_AZUA: 'Azua',
	STATE_BAHORUCO: 'Bahoruco',
	STATE_BARAHONA: 'Barahona',
	STATE_DAJABON: 'Dajabon',
	STATE_DISTRITO_NACIONAL: 'Distrito Nacional',
	STATE_DUARTE: 'Duarte',
	STATE_EL_SEYBO: 'El Seybo',
	STATE_ELIAS_PINA: 'Elias Pina',
	STATE_ESPAILLAT: 'Espaillat',
	STATE_HATO_MAYOR: 'Hato Mayor',
	STATE_INDEPENDENCIA: 'Independencia',
	STATE_LA_ALTAGRACIA: 'La Altagracia',
	STATE_LA_ROMANA: 'La Romana',
	STATE_LA_VEGA: 'La Vega',
	STATE_MARIA_TRINIDAD_SANCHEZ: 'Maria Trinidad Sanchez',
	STATE_MONSENOR_NOUEL: 'Monsenor Nouel',
	STATE_MONTE_CRISTI: 'Monte Cristi',
	STATE_MONTE_PLATA: 'Monte Plata',
	STATE_PEDERNALES: 'Pedernales',
	STATE_PERAVIA: 'Peravia',
	STATE_PUERTO_PLATA: 'Puerto Plata',
	STATE_SALCEDO: 'Salcedo',
	STATE_SAMANA: 'Samana',
	STATE_SAN_CRISTOBAL: 'San Cristobal',
	STATE_SAN_PEDRO_DE_MACORIS: 'San Pedro de Macoris',
	STATE_SANCHEZ_RAMIREZ: 'Sanchez Ramirez',
	STATE_SANTIAGO: 'Santiago',
	STATE_SANTIAGO_RODRIGUEZ: 'Santiago Rodriguez',
	STATE_VALVERDE: 'Valverde',
	STATE_AILEU: 'Aileu',
	STATE_AINARO: 'Ainaro',
	STATE_AMBENO: 'Ambeno',
	STATE_BAUCAU: 'Baucau',
	STATE_BOBONARO: 'Bobonaro',
	STATE_COVA_LIMA: 'Cova Lima',
	STATE_DILI: 'Dili',
	STATE_ERMERA: 'Ermera',
	STATE_LAUTEM: 'Lautem',
	STATE_LIQUICA: 'Liquica',
	STATE_MANATUTO: 'Manatuto',
	STATE_MANUFAHI: 'Manufahi',
	STATE_VIQUEQUE: 'Viqueque',
	STATE_AZUAY: 'Azuay',
	STATE_CANAR: 'Canar',
	STATE_CARCHI: 'Carchi',
	STATE_CHIMBORAZO: 'Chimborazo',
	STATE_COTOPAXI: 'Cotopaxi',
	STATE_EL_ORO: 'El Oro',
	STATE_ESMERALDAS: 'Esmeraldas',
	STATE_GALAPAGOS: 'Galapagos',
	STATE_GUAYAS: 'Guayas',
	STATE_IMBABURA: 'Imbabura',
	STATE_LOJA: 'Loja',
	STATE_LOS_RIOS: 'Los Rios',
	STATE_MANABI: 'Manabi',
	STATE_MORONA_SANTIAGO: 'Morona Santiago',
	STATE_NAPO: 'Napo',
	STATE_ORELLANA: 'Orellana',
	STATE_PASTAZA: 'Pastaza',
	STATE_PICHINCHA: 'Pichincha',
	STATE_SUCUMBIOS: 'Sucumbios',
	STATE_TUNGURAHUA: 'Tungurahua',
	STATE_ZAMORA_CHINCHIPE: 'Zamora Chinchipe',
	STATE_ASWAN: 'Aswan',
	STATE_ASYUT: 'Asyut',
	STATE_BANI_SUWAYF: 'Bani Suwayf',
	STATE_BUR_SA__ID: 'Bur Sa’id',
	STATE_CAIRO: 'Cairo',
	STATE_DUMYAT: 'Dumyat',
	STATE_KAFR_ASH_SHAYKH: 'Kafr-ash-Shaykh',
	STATE_MATRUH: 'Matruh',
	STATE_MUHAFAZAT_AD_DAQAHLIYAH: 'Muhafazat ad Daqahliyah',
	STATE_MUHAFAZAT_AL_FAYYUM: 'Muhafazat al Fayyum',
	STATE_MUHAFAZAT_AL_GHARBIYAH: 'Muhafazat al Gharbiyah',
	STATE_MUHAFAZAT_AL_ISKANDARIYAH: 'Muhafazat al Iskandariyah',
	STATE_MUHAFAZAT_AL_QAHIRAH: 'Muhafazat al Qahirah',
	STATE_QINA: 'Qina',
	STATE_SAWHAJ: 'Sawhaj',
	STATE_SINA_AL_JANUBIYAH: 'Sina al-Janubiyah',
	STATE_SINA_ASH_SHAMALIYAH: 'Sina ash-Shamaliyah',
	STATE_AD_DAQAHLIYAH: 'ad-Daqahliyah',
	STATE_AL_BAHR_AL_AHMAR: 'al-Bahr-al-Ahmar',
	STATE_AL_BUHAYRAH: 'al-Buhayrah',
	STATE_AL_FAYYUM: 'al-Fayyum',
	STATE_AL_GHARBIYAH: 'al-Gharbiyah',
	STATE_AL_ISKANDARIYAH: 'al-Iskandariyah',
	STATE_AL_ISMAILIYAH: 'al-Ismailiyah',
	STATE_AL_JIZAH: 'al-Jizah',
	STATE_AL_MINUFIYAH: 'al-Minufiyah',
	STATE_AL_MINYA: 'al-Minya',
	STATE_AL_QAHIRA: 'al-Qahira',
	STATE_AL_QALYUBIYAH: 'al-Qalyubiyah',
	STATE_AL_UQSUR: 'al-Uqsur',
	STATE_AL_WADI_AL_JADID: 'al-Wadi al-Jadid',
	STATE_AS_SUWAYS: 'as-Suways',
	STATE_ASH_SHARQIYAH: 'ash-Sharqiyah',
	STATE_AHUACHAPAN: 'Ahuachapan',
	STATE_CABANAS: 'Cabanas',
	STATE_CHALATENANGO: 'Chalatenango',
	STATE_CUSCATLAN: 'Cuscatlan',
	STATE_LA_LIBERTAD: 'La Libertad',
	STATE_LA_UNION: 'La Union',
	STATE_MORAZAN: 'Morazan',
	STATE_SAN_MIGUEL: 'San Miguel',
	STATE_SAN_VICENTE: 'San Vicente',
	STATE_SANTA_ANA: 'Santa Ana',
	STATE_SONSONATE: 'Sonsonate',
	STATE_USULUTAN: 'Usulutan',
	STATE_ANNOBON: 'Annobon',
	STATE_BIOKO_NORTE: 'Bioko Nord',
	STATE_BIOKO_SUR: 'Bioko Sud',
	STATE_CENTRO_SUR: 'Centro Sud',
	STATE_KIE_NTEM: 'Kie-Ntem',
	STATE_LITORAL: 'Litoral',
	STATE_WELE_NZAS: 'Wele-Nzas',
	STATE_ANSEBA: 'Anseba',
	STATE_DEBUB: 'Debub',
	STATE_DEBUB_KEIH_BAHRI: 'Debub-Keih-Bahri',
	STATE_GASH_BARKA: 'Gash-Barka',
	STATE_MAEKEL: 'Maekel',
	STATE_SEMIEN_KEIH_BAHRI: 'Semien-Keih-Bahri',
	STATE_HARJU: 'Harju',
	STATE_HIIU: 'Hiiu',
	STATE_IDA_VIRU: 'Ida-Viru',
	STATE_JARVA: 'Jarva',
	STATE_JOGEVA: 'Jogeva',
	STATE_LAANE: 'Laane',
	STATE_LAANE_VIRU: 'Laane-Viru',
	STATE_PARNU: 'Parnu',
	STATE_POLVA: 'Polva',
	STATE_RAPLA: 'Rapla',
	STATE_SAARE: 'Saare',
	STATE_TARTU: 'Tartu',
	STATE_VALGA: 'Valga',
	STATE_VILJANDI: 'Viljandi',
	STATE_VORU: 'Voru',
	STATE_ADDIS_ABEBA: 'Addis Abeba',
	STATE_AFAR: 'Afar',
	STATE_AMHARA: 'Amhara',
	STATE_BENISHANGUL: 'Benishangul',
	STATE_DIREDAWA: 'Diredawa',
	STATE_GAMBELLA: 'Gambella',
	STATE_HARAR: 'Harar',
	STATE_JIGJIGA: 'Jigjiga',
	STATE_MEKELE: 'Mekele',
	STATE_OROMIA: 'Oromia',
	STATE_SOMALI: 'Somali',
	STATE_SOUTHERN: 'Meridionale',
	STATE_TIGRAY: 'Tigray',
	STATE_FALKLAND_ISLANDS: 'Falkland, Isole',
	STATE_SOUTH_GEORGIA: 'Georgia Meridionale',
	STATE_KLAKSVIK: 'Klaksvik',
	STATE_NOR_ARA_EYSTUROY: 'Nor ara Eysturoy',
	STATE_NOR_OY: 'Nor oy',
	STATE_SANDOY: 'Sandoy',
	STATE_STREYMOY: 'Streymoy',
	STATE_SU_UROY: 'Su uroy',
	STATE_SY_RA_EYSTUROY: 'Sy ra Eysturoy',
	STATE_TORSHAVN: 'Torshavn',
	STATE_VAGA: 'Vaga',
	STATE_CENTRAL: 'Centrale',
	STATE_NORTHERN: 'Settentrionale',
	STATE_SOUTH_PACIFIC: 'Sud Pacifico',
	STATE_AHVENANMAA: 'Ahvenanmaa',
	STATE_ETELA_KARJALA: 'Etela-Karjala',
	STATE_ETELA_POHJANMAA: 'Etela-Pohjanmaa',
	STATE_ETELA_SAVO: 'Etela-Savo',
	STATE_ETELA_SUOMEN_LAANI: 'Etela-Suomen Laani',
	STATE_ITA_SUOMEN_LAANI: 'Ita-Suomen Laani',
	STATE_ITA_UUSIMAA: 'Ita-Uusimaa',
	STATE_KAINUU: 'Kainuu',
	STATE_KANTA_HAME: 'Kanta-Hame',
	STATE_KESKI_POHJANMAA: 'Keski-Pohjanmaa',
	STATE_KESKI_SUOMI: 'Keski-Suomi',
	STATE_KYMENLAAKSO: 'Kymenlaakso',
	STATE_LANSI_SUOMEN_LAANI: 'Lansi-Suomen Laani',
	STATE_LAPPI: 'Lappi',
	STATE_NORTHERN_SAVONIA: 'Savo Settentrionale',
	STATE_OSTROBOTHNIA: 'Ostrobotnia',
	STATE_OULUN_LAANI: 'Oulun Laani',
	STATE_PAIJAT_HAME: 'Paijat-Hame',
	STATE_PIRKANMAA: 'Pirkanmaa',
	STATE_POHJANMAA: 'Pohjanmaa',
	STATE_POHJOIS_KARJALA: 'Pohjois-Karjala',
	STATE_POHJOIS_POHJANMAA: 'Pohjois-Pohjanmaa',
	STATE_POHJOIS_SAVO: 'Pohjois-Savo',
	STATE_SAARIJARVI: 'Saarijarvi',
	STATE_SATAKUNTA: 'Satakunta',
	STATE_SOUTHERN_SAVONIA: 'Savo Meridionale',
	STATE_TAVASTIA_PROPER: 'Tavastia Proper',
	STATE_ULEABORGS_LAN: 'Uleaborgs Lan',
	STATE_UUSIMAA: 'Uusimaa',
	STATE_VARSINAIS_SUOMI: 'Varsinais-Suomi',
	STATE_AIN: 'Ain',
	STATE_AISNE: 'Aisne',
	STATE_ALBI_LE_SEQUESTRE: 'Albi Le Sequestre',
	STATE_ALLIER: 'Allier',
	STATE_ALPES_COTE_DAZUR: 'Alpi Costa Azzurra',
	STATE_ALPES_MARITIMES: 'Alpi Marittime',
	STATE_ALPES_DE_HAUTE_PROVENCE: 'Alpi dell’Alta Provenza',
	STATE_ALSACE: 'Alsazia',
	STATE_AQUITAINE: 'Aquitania',
	STATE_ARDECHE: 'Ardeche',
	STATE_ARDENNES: 'Ardenne',
	STATE_ARIEGE: 'Ariege',
	STATE_AUBE: 'Aube',
	STATE_AUDE: 'Aude',
	STATE_AUVERGNE: 'Alvernia',
	STATE_AVEYRON: 'Aveyron',
	STATE_BAS_RHIN: 'Basso Reno',
	STATE_BASSE_NORMANDIE: 'Bassa Normandia',
	STATE_BOUCHES_DU_RHONE: 'Bocche del Rodano',
	STATE_BOURGOGNE: 'Borgogna',
	STATE_BRETAGNE: 'Bretagna',
	STATE_BRITTANY: 'Bretagna',
	STATE_BURGUNDY: 'Borgogna',
	STATE_CALVADOS: 'Calvados',
	STATE_CANTAL: 'Cantal',
	STATE_CEDEX: 'Cedex',
	STATE_CHARENTE: 'Charente',
	STATE_CHARENTE_MARITIME: 'Charente Marittima',
	STATE_CHER: 'Cher',
	STATE_CORREZE: 'Correze',
	STATE_CORSE_DU_SUD: 'Corsica Meridionale',
	STATE_COTE_D__OR: 'Cote-d’Or',
	STATE_COTES_D__ARMOR: 'Cotes-d’Armor',
	STATE_CREUSE: 'Creuse',
	STATE_CROLLES: 'Crolles',
	STATE_DEUX_SEVRES: 'Deux-Sevres',
	STATE_DORDOGNE: 'Dordogna',
	STATE_DOUBS: 'Doubs',
	STATE_DROME: 'Drome',
	STATE_ESSONNE: 'Essonne',
	STATE_EURE: 'Eure',
	STATE_EURE_ET_LOIR: 'Eure-et-Loir',
	STATE_FEUCHEROLLES: 'Feucherolles',
	STATE_FINISTERE: 'Finistere',
	STATE_FRANCHE_COMTE: 'Franca Contea',
	STATE_GARD: 'Gard',
	STATE_GERS: 'Gers',
	STATE_GIRONDE: 'Gironda',
	STATE_HAUT_RHIN: 'Alto Reno',
	STATE_HAUTE_CORSE: 'Alta Corsica',
	STATE_HAUTE_GARONNE: 'Alta Garonna',
	STATE_HAUTE_LOIRE: 'Alta Loira',
	STATE_HAUTE_MARNE: 'Alta Marna',
	STATE_HAUTE_SAONE: 'Alta Saona',
	STATE_HAUTE_SAVOIE: 'Alta Savoia',
	STATE_HAUTE_VIENNE: 'Alta Vienne',
	STATE_HAUTES_ALPES: 'Alte Alpi',
	STATE_HAUTES_PYRENEES: 'Alti Pirenei',
	STATE_HAUTS_DE_SEINE: 'Hauts-de-Seine',
	STATE_HERAULT: 'Herault',
	STATE_ILE_DE_FRANCE: 'Ile-de-France',
	STATE_ILLE_ET_VILAINE: 'Ille-et-Vilaine',
	STATE_INDRE: 'Indre',
	STATE_INDRE_ET_LOIRE: 'Indre-et-Loire',
	STATE_ISERE: 'Isere',
	STATE_JURA: 'Jura',
	STATE_KLAGENFURT: 'Klagenfurt',
	STATE_LANDES: 'Landes',
	STATE_LANGUEDOC_ROUSSILLON: 'Linguadoca Rossiglione',
	STATE_LARCAY: 'Larcay',
	STATE_LE_CASTELLET: 'Le Castellet',
	STATE_LE_CREUSOT: 'Le Creusot',
	STATE_LIMOUSIN: 'Limosino',
	STATE_LOIR_ET_CHER: 'Loira e Caro',
	STATE_LOIRE: 'Loira',
	STATE_LOIRE_ATLANTIQUE: 'Loira Atlantica',
	STATE_LOIRET: 'Loiret',
	STATE_LORRAINE: 'Lorena',
	STATE_LOT: 'Lot',
	STATE_LOT_ET_GARONNE: 'Lot e Garonna',
	STATE_LOWER_NORMANDY: 'Bassa Normandia',
	STATE_LOZERE: 'Lozere',
	STATE_MAINE_ET_LOIRE: 'Maine e Loira',
	STATE_MANCHE: 'Manica',
	STATE_MARNE: 'Marna',
	STATE_MAYENNE: 'Mayenne',
	STATE_MEURTHE_ET_MOSELLE: 'Meurthe e Mosella',
	STATE_MEUSE: 'Mosa',
	STATE_MIDI_PYRENEES: 'Midi Pirenei',
	STATE_MORBIHAN: 'Morbihan',
	STATE_MOSELLE: 'Mosella',
	STATE_NIEVRE: 'Nievre',
	STATE_NORD_PAS_DE_CALAIS: 'Nord Passo di Calais',
	STATE_OISE: 'Oise',
	STATE_ORNE: 'Orne',
	STATE_PARIS: 'Parigi',
	STATE_PAS_DE_CALAIS: 'Passo di Calais',
	STATE_PAYS_DE_LA_LOIRE: 'Paese della Loira',
	STATE_PICARDY: 'Piccardia',
	STATE_PUY_DE_DOME: 'Puy-de-Dome',
	STATE_PYRENEES_ATLANTIQUES: 'Pirenei Atlantici',
	STATE_PYRENEES_ORIENTALES: 'Pirenei Orientali',
	STATE_QUELMES: 'Quelmes',
	STATE_RHONE: 'Rodano',
	STATE_RHONE_ALPES: 'Rodano Alpi',
	STATE_SAINT_OUEN: 'Saint Ouen',
	STATE_SAINT_VIATRE: 'Saint Viatre',
	STATE_SAONE_ET_LOIRE: 'Saona e Loira',
	STATE_SARTHE: 'Sarthe',
	STATE_SAVOIE: 'Savoia',
	STATE_SEINE_MARITIME: 'Senna Marittima',
	STATE_SEINE_SAINT_DENIS: 'Senna Saint-Denis',
	STATE_SEINE_ET_MARNE: 'Senna e Marna',
	STATE_SOMME: 'Somme',
	STATE_SOPHIA_ANTIPOLIS: 'Sophia Antipolis',
	STATE_SOUVANS: 'Souvans',
	STATE_TARN: 'Tarn',
	STATE_TARN_ET_GARONNE: 'Tarn e Garonna',
	STATE_TERRITOIRE_DE_BELFORT: 'Territorio di Belfort',
	STATE_TREIGNAC: 'Treignac',
	STATE_UPPER_NORMANDY: 'Alta Normandia',
	STATE_VAL_D__OISE: 'Valle dell’Oise',
	STATE_VAL_DE_MARNE: 'Valle della Marna',
	STATE_VAR: 'Var',
	STATE_VAUCLUSE: 'Vaucluse',
	STATE_VELLISE: 'Vellise',
	STATE_VENDEE: 'Vandea',
	STATE_VIENNE: 'Vienne',
	STATE_VOSGES: 'Vosgi',
	STATE_YONNE: 'Yonne',
	STATE_YVELINES: 'Yvelines',
	STATE_CAYENNE: 'Caienna',
	STATE_SAINT_LAURENT_DU_MARONI: 'Saint-Laurent-du-Maroni',
	STATE_ILES_DU_VENT: 'Vento, Isole del',
	STATE_ILES_SOUS_LE_VENT: 'Sottovento, Isole',
	STATE_MARQUESAS: 'Marchesi',
	STATE_TUAMOTU: 'Tuamotu',
	STATE_TUBUAI: 'Tubuai',
	STATE_AMSTERDAM: 'Amsterdam',
	STATE_CROZET_ISLANDS: 'Crozet, Isole',
	STATE_KERGUELEN: 'Kerguelen',
	STATE_ESTUAIRE: 'Estuaire',
	STATE_HAUT_OGOOUE: 'Alto Ogooue',
	STATE_MOYEN_OGOOUE: 'Medio Ogooue',
	STATE_NGOUNIE: 'Ngounie',
	STATE_NYANGA: 'Nyanga',
	STATE_OGOOUE_IVINDO: 'Ogooue-Ivindo',
	STATE_OGOOUE_LOLO: 'Ogooue-Lolo',
	STATE_OGOOUE_MARITIME: 'Ogooue Marittimo',
	STATE_WOLEU_NTEM: 'Woleu-Ntem',
	STATE_BANJUL: 'Banjul',
	STATE_BASSE: 'Basse',
	STATE_BRIKAMA: 'Brikama',
	STATE_JANJANBUREH: 'Janjanbureh',
	STATE_KANIFING: 'Kanifing',
	STATE_KEREWAN: 'Kerewan',
	STATE_KUNTAUR: 'Kuntaur',
	STATE_MANSAKONKO: 'Mansakonko',
	STATE_ABHASIA: 'Abhasia',
	STATE_AJARIA: 'Ajaria',
	STATE_GURIA: 'Guria',
	STATE_IMERETI: 'Imereti',
	STATE_KAHETI: 'Kaheti',
	STATE_KVEMO_KARTLI: 'Kvemo Kartli',
	STATE_MCHETA_MTIANETI: 'Mcheta-Mtianeti',
	STATE_RACHA: 'Racha',
	STATE_SAMAGRELO_ZEMO_SVANETI: 'Samagrelo-Zemo Svaneti',
	STATE_SAMCHE_ZHAVAHETI: 'Samche-Zhavaheti',
	STATE_SHIDA_KARTLI: 'Shida Kartli',
	STATE_TBILISI: 'Tbilisi',
	STATE_BADEN_WURTTEMBERG: 'Baden-Wurttemberg',
	STATE_BAVARIA: 'Baviera',
	STATE_BAYERN: 'Baviera',
	STATE_BEILSTEIN_WURTT: 'Beilstein Wurtt',
	STATE_BERLIN: 'Berlino',
	STATE_BRANDENBURG: 'Brandenburgo',
	STATE_BREMEN: 'Brema',
	STATE_DREISBACH: 'Dreisbach',
	STATE_FREISTAAT_BAYERN: 'Baviera',
	STATE_HAMBURG: 'Amburgo',
	STATE_HANNOVER: 'Hannover',
	STATE_HEROLDSTATT: 'Heroldstatt',
	STATE_HESSEN: 'Hessen',
	STATE_KORTENBERG: 'Kortenberg',
	STATE_LAASDORF: 'Laasdorf',
	STATE_LAND_BADEN_WURTTEMBERG: 'Land Baden-Wurttemberg',
	STATE_LAND_BAYERN: 'Land Baviera',
	STATE_LAND_BRANDENBURG: 'Land Brandenburgo',
	STATE_LAND_HESSEN: 'Land Hessen',
	STATE_LAND_MECKLENBURG_VORPOMMERN: 'Land Mecklenburg-Vorpommern',
	STATE_LAND_NORDRHEIN_WESTFALEN: 'Land Nord Reno-Westfalia',
	STATE_LAND_RHEINLAND_PFALZ: 'Land Renania-Palatinato',
	STATE_LAND_SACHSEN: 'Land Sachsen',
	STATE_LAND_SACHSEN_ANHALT: 'Land Sachsen-Anhalt',
	STATE_LAND_THURINGEN: 'Land Turingia',
	STATE_LOWER_SAXONY: 'Sassonia Meridionale',
	STATE_MECKLENBURG_VORPOMMERN: 'Mecklenburgo-Vorpommern',
	STATE_MULFINGEN: 'Mulfingen',
	STATE_MUNICH: 'Monaco di Baviera',
	STATE_NEUBEUERN: 'Neubeuern',
	STATE_NIEDERSACHSEN: 'Niedersachsen',
	STATE_NOORD_HOLLAND: 'Olanda Settentrionale',
	STATE_NORDRHEIN_WESTFALEN: 'Nord Reno-Westfalia',
	STATE_NORTH_RHINE_WESTPHALIA: 'Nord Reno-Westfalia',
	STATE_OSTERODE: 'Osterode',
	STATE_RHEINLAND_PFALZ: 'Renania-Palatinato',
	STATE_RHINELAND_PALATINATE: 'Renania-Palatinato',
	STATE_SAARLAND: 'Saarland',
	STATE_SACHSEN: 'Sachsen',
	STATE_SACHSEN_ANHALT: 'Sachsen-Anhalt',
	STATE_SAXONY: 'Sassonia',
	STATE_THURINGIA: 'Turingia',
	STATE_WEBLING: 'Webling',
	STATE_WEINSTRABE: 'Weinstrabe',
	STATE_SCHLOBBORN: 'schlobborn',
	STATE_ASHANTI: 'Ashanti',
	STATE_BRONG_AHAFO: 'Brong-Ahafo',
	STATE_GREATER_ACCRA: 'Grande Accra',
	STATE_UPPER_EAST: 'Est Superiore',
	STATE_UPPER_WEST: 'Ovest Superiore',
	STATE_VOLTA: 'Volta',
	STATE_GIBRALTAR: 'Gibilterra',
	STATE_ACHARNES: 'Acharnes',
	STATE_AHAIA: 'Ahaia',
	STATE_AITOLIA_KAI_AKARNANIA: 'Aitolia kai Akarnania',
	STATE_ARGOLIS: 'Argolis',
	STATE_ARKADIA: 'Arcadia',
	STATE_ARTA: 'Arta',
	STATE_ATTICA: 'Attica',
	STATE_ATTIKI: 'Attica',
	STATE_AYION_OROS: 'Ayion Oros',
	STATE_CRETE: 'Creta',
	STATE_DODEKANISOS: 'Dodecaneso',
	STATE_DRAMA: 'Drama',
	STATE_EVIA: 'Evia',
	STATE_EVRITANIA: 'Evritania',
	STATE_EVROS: 'Evros',
	STATE_EVVOIA: 'Evvoia',
	STATE_FLORINA: 'Florina',
	STATE_FOKIS: 'Fokis',
	STATE_FTHIOTIS: 'Fthiotis',
	STATE_GREVENA: 'Grevena',
	STATE_HALANDRI: 'Halandri',
	STATE_HALKIDIKI: 'Halkidiki',
	STATE_HANIA: 'Hania',
	STATE_HERAKLION: 'Candia',
	STATE_HIOS: 'Hios',
	STATE_ILIA: 'Ilia',
	STATE_IMATHIA: 'Imathia',
	STATE_IOANNINA: 'Ioannina',
	STATE_IRAKLION: 'Candia',
	STATE_KARDITSA: 'Karditsa',
	STATE_KASTORIA: 'Kastoria',
	STATE_KAVALA: 'Kavala',
	STATE_KEFALLINIA: 'Kefallinia',
	STATE_KERKIRA: 'Kerkira',
	STATE_KIKLADES: 'Cicladi',
	STATE_KILKIS: 'Kilkis',
	STATE_KORINTHIA: 'Corinzia',
	STATE_KOZANI: 'Kozani',
	STATE_LAKONIA: 'Lakonia',
	STATE_LARISA: 'Larissa',
	STATE_LASITHI: 'Lasithi',
	STATE_LESVOS: 'Lesbo',
	STATE_LEVKAS: 'Levkas',
	STATE_MAGNISIA: 'Magnisia',
	STATE_MESSINIA: 'Messinia',
	STATE_NOMOS_ATTIKIS: 'Nomos Attica',
	STATE_NOMOS_ZAKYNTHOU: 'Nomos Zacinto',
	STATE_PELLA: 'Pella',
	STATE_PIERIA: 'Pieria',
	STATE_PIRAIOS: 'Pireo',
	STATE_PREVEZA: 'Preveza',
	STATE_RETHIMNI: 'Rethimni',
	STATE_RODOPI: 'Rodopi',
	STATE_SAMOS: 'Samo',
	STATE_SERRAI: 'Serrai',
	STATE_THESPROTIA: 'Thesprotia',
	STATE_THESSALONIKI: 'Tessalonica',
	STATE_TRIKALA: 'Trikala',
	STATE_VOIOTIA: 'Voiotia',
	STATE_WEST_GREECE: 'Grecia Occidentale',
	STATE_XANTHI: 'Xanthi',
	STATE_ZAKINTHOS: 'Zacinto',
	STATE_AASIAAT: 'Aasiaat',
	STATE_AMMASSALIK: 'Ammassalik',
	STATE_ILLOQQORTOORMIUT: 'Illoqqortoormiut',
	STATE_ILULISSAT: 'Ilulissat',
	STATE_IVITTUUT: 'Ivittuut',
	STATE_KANGAATSIAQ: 'Kangaatsiaq',
	STATE_MANIITSOQ: 'Maniitsoq',
	STATE_NANORTALIK: 'Nanortalik',
	STATE_NARSAQ: 'Narsaq',
	STATE_NUUK: 'Nuuk',
	STATE_PAAMIUT: 'Paamiut',
	STATE_QAANAAQ: 'Qaanaaq',
	STATE_QAQORTOQ: 'Qaqortoq',
	STATE_QASIGIANNGUIT: 'Qasigiannguit',
	STATE_QEQERTARSUAQ: 'Qeqertarsuaq',
	STATE_SISIMIUT: 'Sisimiut',
	STATE_UDENFOR_KOMMUNAL_INDDELING: 'Udenfor kommunal inddeling',
	STATE_UPERNAVIK: 'Upernavik',
	STATE_UUMMANNAQ: 'Uummannaq',
	STATE_CARRIACOU_PETITE_MARTINIQUE: 'Carriacou-Petite Martinique',
	STATE_SAINT_DAVIDS: 'Saint Davids',
	STATE_SAINT_GEORGE__S: 'Saint George’s',
	STATE_BASSE_TERRE: 'Basse-Terre',
	STATE_GRANDE_TERRE: 'Grande-Terre',
	STATE_ILES_DES_SAINTES: 'Iles des Saintes',
	STATE_LA_DESIRADE: 'La Desirade',
	STATE_MARIE_GALANTE: 'Marie-Galante',
	STATE_SAINT_BARTHELEMY: 'Saint Barthelemy',
	STATE_SAINT_MARTIN: 'Saint Martin',
	STATE_AGANA_HEIGHTS: 'Agana Heights',
	STATE_AGAT: 'Agat',
	STATE_BARRIGADA: 'Barrigada',
	STATE_CHALAN_PAGO_ORDOT: 'Chalan-Pago-Ordot',
	STATE_DEDEDO: 'Dededo',
	STATE_HAGATNA: 'Hagatna',
	STATE_INARAJAN: 'Inarajan',
	STATE_MANGILAO: 'Mangilao',
	STATE_MERIZO: 'Merizo',
	STATE_MONGMONG_TOTO_MAITE: 'Mongmong-Toto-Maite',
	STATE_SANTA_RITA: 'Santa Rita',
	STATE_SINAJANA: 'Sinajana',
	STATE_TALOFOFO: 'Talofofo',
	STATE_TAMUNING: 'Tamuning',
	STATE_YIGO: 'Yigo',
	STATE_YONA: 'Yona',
	STATE_ALTA_VERAPAZ: 'Alta Verapaz',
	STATE_BAJA_VERAPAZ: 'Baja Verapaz',
	STATE_CHIMALTENANGO: 'Chimaltenango',
	STATE_CHIQUIMULA: 'Chiquimula',
	STATE_EL_PROGRESO: 'El Progreso',
	STATE_ESCUINTLA: 'Escuintla',
	STATE_GUATEMALA: 'Guatemala',
	STATE_HUEHUETENANGO: 'Huehuetenango',
	STATE_IZABAL: 'Izabal',
	STATE_JALAPA: 'Jalapa',
	STATE_JUTIAPA: 'Jutiapa',
	STATE_PETEN: 'Peten',
	STATE_QUEZALTENANGO: 'Quezaltenango',
	STATE_QUICHE: 'Quiche',
	STATE_RETALHULEU: 'Retalhuleu',
	STATE_SACATEPEQUEZ: 'Sacatepequez',
	STATE_SAN_MARCOS: 'San Marcos',
	STATE_SANTA_ROSA: 'Santa Rosa',
	STATE_SOLOLA: 'Solola',
	STATE_SUCHITEPEQUEZ: 'Suchitepequez',
	STATE_TOTONICAPAN: 'Totonicapan',
	STATE_ZACAPA: 'Zacapa',
	STATE_ALDERNEY: 'Alderney',
	STATE_CASTEL: 'Castel',
	STATE_FOREST: 'Forest',
	STATE_SAINT_PETER_PORT: 'Saint Peter Port',
	STATE_SAINT_PIERRE_DU_BOIS: 'Saint Pierre du Bois',
	STATE_SAINT_SAMPSON: 'Saint Sampson',
	STATE_SAINT_SAVIOUR: 'Saint Saviour',
	STATE_SARK: 'Sark',
	STATE_TORTEVAL: 'Torteval',
	STATE_VALE: 'Vale',
	STATE_BEYLA: 'Beyla',
	STATE_BOFFA: 'Boffa',
	STATE_BOKE: 'Boke',
	STATE_CONAKRY: 'Conakry',
	STATE_COYAH: 'Coyah',
	STATE_DABOLA: 'Dabola',
	STATE_DALABA: 'Dalaba',
	STATE_DINGUIRAYE: 'Dinguiraye',
	STATE_FARANAH: 'Faranah',
	STATE_FORECARIAH: 'Forecariah',
	STATE_FRIA: 'Fria',
	STATE_GAOUAL: 'Gaoual',
	STATE_GUECKEDOU: 'Gueckedou',
	STATE_KANKAN: 'Kankan',
	STATE_KEROUANE: 'Kerouane',
	STATE_KINDIA: 'Kindia',
	STATE_KISSIDOUGOU: 'Kissidougou',
	STATE_KOUBIA: 'Koubia',
	STATE_KOUNDARA: 'Koundara',
	STATE_KOUROUSSA: 'Kouroussa',
	STATE_LABE: 'Labe',
	STATE_LOLA: 'Lola',
	STATE_MACENTA: 'Macenta',
	STATE_MALI: 'Mali',
	STATE_MAMOU: 'Mamou',
	STATE_MANDIANA: 'Mandiana',
	STATE_NZEREKORE: 'Nzerekore',
	STATE_PITA: 'Pita',
	STATE_SIGUIRI: 'Siguiri',
	STATE_TELIMELE: 'Telimele',
	STATE_TOUGUE: 'Tougue',
	STATE_YOMOU: 'Yomou',
	STATE_BAFATA: 'Bafata',
	STATE_BISSAU: 'Bissau',
	STATE_BOLAMA: 'Bolama',
	STATE_CACHEU: 'Cacheu',
	STATE_GABU: 'Gabu',
	STATE_OIO: 'Oio',
	STATE_QUINARA: 'Quinara',
	STATE_TOMBALI: 'Tombali',
	STATE_BARIMA_WAINI: 'Barima-Waini',
	STATE_CUYUNI_MAZARUNI: 'Cuyuni-Mazaruni',
	STATE_DEMERARA_MAHAICA: 'Demerara-Mahaica',
	STATE_EAST_BERBICE_CORENTYNE: 'Berbice Orientale-Corentyne',
	STATE_ESSEQUIBO_ISLANDS_WEST_DEMERAR: 'Essequibo, Isole -Demerar Occidentale',
	STATE_MAHAICA_BERBICE: 'Mahaica-Berbice',
	STATE_POMEROON_SUPENAAM: 'Pomeroon-Supenaam',
	STATE_POTARO_SIPARUNI: 'Potaro-Siparuni',
	STATE_UPPER_DEMERARA_BERBICE: 'Demerara-Berbice Superiore',
	STATE_UPPER_TAKUTU_UPPER_ESSEQUIBO: 'Takutu Superiore - Essequibo Superiore',
	STATE_ARTIBONITE: 'Artibonite',
	STATE_GRAND__ANSE: 'Grand’Anse',
	STATE_NORD_EST: 'Nord Est',
	STATE_NORD_OUEST: 'Nord Ovest',
	STATE_SUD_EST: 'Sud Est',
	STATE_HEARD_AND_MCDONALD_ISLANDS: 'Heard e McDonald, Isole',
	STATE_ATLANTIDA: 'Atlantida',
	STATE_CHOLUTECA: 'Choluteca',
	STATE_COLON: 'Colon',
	STATE_COMAYAGUA: 'Comayagua',
	STATE_COPAN: 'Copan',
	STATE_CORTES: 'Cortes',
	STATE_DISTRITO_CENTRAL: 'Distrito Central',
	STATE_EL_PARAISO: 'El Paraiso',
	STATE_FRANCISCO_MORAZAN: 'Francisco Morazan',
	STATE_GRACIAS_A_DIOS: 'Gracias a Dios',
	STATE_INTIBUCA: 'Intibuca',
	STATE_ISLAS_DE_LA_BAHIA: 'Islas de la Bahia',
	STATE_LEMPIRA: 'Lempira',
	STATE_OCOTEPEQUE: 'Ocotepeque',
	STATE_OLANCHO: 'Olancho',
	STATE_SANTA_BARBARA: 'Santa Barbara',
	STATE_VALLE: 'Valle',
	STATE_YORO: 'Yoro',
	STATE_HONG_KONG: 'Hong Kong',
	STATE_BACS_KISKUN: 'Bacs-Kiskun',
	STATE_BARANYA: 'Baranya',
	STATE_BEKES: 'Bekes',
	STATE_BORSOD_ABAUJ_ZEMPLEN: 'Borsod-Abauj-Zemplen',
	STATE_BUDAPEST: 'Budapest',
	STATE_CSONGRAD: 'Csongrad',
	STATE_FEJER: 'Fejer',
	STATE_GYOR_MOSON_SOPRON: 'Gyor-Moson-Sopron',
	STATE_CORK: 'Cork',
	STATE_HAJDU_BIHAR: 'Hajdu-Bihar',
	STATE_HEVES: 'Heves',
	STATE_JASZ_NAGYKUN_SZOLNOK: 'Jasz-Nagykun-Szolnok',
	STATE_KOMAROM_ESZTERGOM: 'Komarom-Esztergom',
	STATE_NOGRAD: 'Nograd',
	STATE_PEST: 'Pest',
	STATE_SOMOGY: 'Somogy',
	STATE_SZABOLCS_SZATMAR_BEREG: 'Szabolcs-Szatmar-Bereg',
	STATE_TOLNA: 'Tolna',
	STATE_VAS: 'Vas',
	STATE_VESZPREM: 'Veszprem',
	STATE_ZALA: 'Zala',
	STATE_AUSTURLAND: 'Austurland',
	STATE_GULLBRINGUSYSLA: 'Gullbringusysla',
	STATE_HOFU_BORGARSVA_I: 'Hofu borgarsva i',
	STATE_NOR_URLAND_EYSTRA: 'Nor urland eystra',
	STATE_NOR_URLAND_VESTRA: 'Nor urland vestra',
	STATE_SU_URLAND: 'Su urland',
	STATE_SU_URNES: 'Su urnes',
	STATE_VESTFIR_IR: 'Vestfir ir',
	STATE_VESTURLAND: 'Vesturland',
	STATE_ACEH: 'Aceh',
	STATE_BALI: 'Bali',
	STATE_BANGKA_BELITUNG: 'Bangka-Belitung',
	STATE_BANTEN: 'Banten',
	STATE_BENGKULU: 'Bengkulu',
	STATE_GANDARIA: 'Gandaria',
	STATE_GORONTALO: 'Gorontalo',
	STATE_JAKARTA: 'Giacarta',
	STATE_JAMBI: 'Jambi',
	STATE_JAWA_BARAT: 'Jawa Barat',
	STATE_JAWA_TENGAH: 'Jawa Tengah',
	STATE_JAWA_TIMUR: 'Jawa Timur',
	STATE_KALIMANTAN_BARAT: 'Kalimantan Barat',
	STATE_KALIMANTAN_SELATAN: 'Kalimantan Selatan',
	STATE_KALIMANTAN_TENGAH: 'Kalimantan Tengah',
	STATE_KALIMANTAN_TIMUR: 'Kalimantan Timur',
	STATE_KENDAL: 'Kendal',
	STATE_LAMPUNG: 'Lampung',
	STATE_MALUKU: 'Maluku',
	STATE_MALUKU_UTARA: 'Maluku Utara',
	STATE_NUSA_TENGGARA_BARAT: 'Nusa Tenggara Barat',
	STATE_NUSA_TENGGARA_TIMUR: 'Nusa Tenggara Timur',
	STATE_PAPUA: 'Papua',
	STATE_RIAU: 'Riau',
	STATE_RIAU_KEPULAUAN: 'Riau Kepulauan',
	STATE_SOLO: 'Solo',
	STATE_SULAWESI_SELATAN: 'Sulawesi Selatan',
	STATE_SULAWESI_TENGAH: 'Sulawesi Tengah',
	STATE_SULAWESI_TENGGARA: 'Sulawesi Tenggara',
	STATE_SULAWESI_UTARA: 'Sulawesi Utara',
	STATE_SUMATERA_BARAT: 'Sumatera Barat',
	STATE_SUMATERA_SELATAN: 'Sumatera Selatan',
	STATE_SUMATERA_UTARA: 'Sumatera Utara',
	STATE_YOGYAKARTA: 'Yogyakarta',
	STATE_ARDABIL: 'Ardabil',
	STATE_AZARBAYJAN_E_BAKHTARI: 'Azarbayjan-e Bakhtari',
	STATE_AZARBAYJAN_E_KHAVARI: 'Azarbayjan-e Khavari',
	STATE_BUSHEHR: 'Bushehr',
	STATE_CHAHAR_MAHAL_E_BAKHTIARI: 'Chahar Mahal-e Bakhtiari',
	STATE_ESFAHAN: 'Esfahan',
	STATE_FARS: 'Fars',
	STATE_GILAN: 'Gilan',
	STATE_GOLESTAN: 'Golestan',
	STATE_HAMADAN: 'Hamadan',
	STATE_HORMOZGAN: 'Hormozgan',
	STATE_ILAM: 'Ilam',
	STATE_KERMAN: 'Kerman',
	STATE_KERMANSHAH: 'Kermanshah',
	STATE_KHORASAN: 'Khorasan',
	STATE_KHUZESTAN: 'Khuzestan',
	STATE_KOHGILUYEH_E_BOYERAHMAD: 'Kohgiluyeh-e Boyerahmad',
	STATE_KORDESTAN: 'Kordestan',
	STATE_LORESTAN: 'Lorestan',
	STATE_MARKAZI: 'Markazi',
	STATE_MAZANDARAN: 'Mazandaran',
	STATE_OSTAN_E_ESFAHAN: 'Ostan-e Esfahan',
	STATE_QAZVIN: 'Qazvin',
	STATE_QOM: 'Qom',
	STATE_SEMNAN: 'Semnan',
	STATE_SISTAN_E_BALUCHESTAN: 'Sistan-e Baluchestan',
	STATE_TEHRAN: 'Tehran',
	STATE_YAZD: 'Yazd',
	STATE_ZANJAN: 'Zanjan',
	STATE_BABIL: 'Babil',
	STATE_BAGHDAD: 'Baghdad',
	STATE_DAHUK: 'Dahuk',
	STATE_DHI_QAR: 'Dhi Qar',
	STATE_DIYALA: 'Diyala',
	STATE_ERBIL: 'Erbil',
	STATE_IRBIL: 'Irbil',
	STATE_KARBALA: 'Karbala',
	STATE_KURDISTAN: 'Kurdistan',
	STATE_MAYSAN: 'Maysan',
	STATE_NINAWA: 'Ninawa',
	STATE_SALAH_AD_DIN: 'Salah-ad-Din',
	STATE_WASIT: 'Wasit',
	STATE_AL_ANBAR: 'al-Anbar',
	STATE_AL_BASRAH: 'al-Basrah',
	STATE_AL_MUTHANNA: 'al-Muthanna',
	STATE_AL_QADISIYAH: 'al-Qadisiyah',
	STATE_AN_NAJAF: 'an-Najaf',
	STATE_AS_SULAYMANIYAH: 'as-Sulaymaniyah',
	STATE_AT_TA__MIM: 'at-Ta’mim',
	STATE_ARMAGH: 'Armagh',
	STATE_CARLOW: 'Carlow',
	STATE_CAVAN: 'Cavan',
	STATE_CLARE: 'Clare',
	STATE_DONEGAL: 'Donegal',
	STATE_DUBLIN: 'Dublino',
	STATE_GALWAY: 'Galway',
	STATE_KERRY: 'Kerry',
	STATE_KILDARE: 'Kildare',
	STATE_KILKENNY: 'Kilkenny',
	STATE_LAOIS: 'Laois',
	STATE_LEINSTER: 'Leinster',
	STATE_LEITRIM: 'Leitrim',
	STATE_LIMERICK: 'Limerick',
	STATE_LOCH_GARMAN: 'Loch Garman',
	STATE_LONGFORD: 'Longford',
	STATE_LOUTH: 'Louth',
	STATE_MAYO: 'Mayo',
	STATE_MEATH: 'Meath',
	STATE_MONAGHAN: 'Monaghan',
	STATE_OFFALY: 'Offaly',
	STATE_ROSCOMMON: 'Roscommon',
	STATE_SLIGO: 'Sligo',
	STATE_TIPPERARY_NORTH_RIDING: 'Tipperary North Riding',
	STATE_TIPPERARY_SOUTH_RIDING: 'Tipperary South Riding',
	STATE_ULSTER: 'Ulster',
	STATE_WATERFORD: 'Waterford',
	STATE_WESTMEATH: 'Westmeath',
	STATE_WEXFORD: 'Wexford',
	STATE_WICKLOW: 'Wicklow',
	STATE_BEIT_HANANIA: 'Beit Hanania',
	STATE_BEN_GURION_AIRPORT: 'Ben Gurion, Aeroporto',
	STATE_BETHLEHEM: 'Betlemme',
	STATE_CAESAREA: 'Cesarea',
	STATE_GAZA: 'Gaza',
	STATE_HADARON: 'Hadaron',
	STATE_HAIFA_DISTRICT: 'Haifa, Distretto',
	STATE_HAMERKAZ: 'Hamerkaz',
	STATE_HAZAFON: 'Hazafon',
	STATE_HEBRON: 'Hebron',
	STATE_JAFFA: 'Giaffa',
	STATE_JERUSALEM: 'Gerusalemme',
	STATE_KHEFA: 'Khefa',
	STATE_KIRYAT_YAM: 'Kiryat Yam',
	STATE_LOWER_GALILEE: 'Bassa Galilea',
	STATE_QALQILYA: 'Qalqilya',
	STATE_TALME_ELAZAR: 'Talme Elazar',
	STATE_TEL_AVIV: 'Tel Aviv',
	STATE_TSAFON: 'Tsafon',
	STATE_UMM_EL_FAHEM: 'Umm El Fahem',
	STATE_YERUSHALAYIM: 'Gerusalemme',
	STATE_ABRUZZI: 'Abruzzo',
	STATE_ABRUZZO: 'Abruzzo',
	STATE_AGRIGENTO: 'Agrigento',
	STATE_ALESSANDRIA: 'Alessandria',
	STATE_ANCONA: 'Ancona',
	STATE_AREZZO: 'Arezzo',
	STATE_ASCOLI_PICENO: 'Ascoli Piceno',
	STATE_ASTI: 'Asti',
	STATE_AVELLINO: 'Avellino',
	STATE_BARI: 'Bari',
	STATE_BASILICATA: 'Basilicata',
	STATE_BELLUNO: 'Belluno',
	STATE_BENEVENTO: 'Benevento',
	STATE_BERGAMO: 'Bergamo',
	STATE_BIELLA: 'Biella',
	STATE_BOLOGNA: 'Bologna',
	STATE_BOLZANO: 'Bolzano',
	STATE_BRESCIA: 'Brescia',
	STATE_BRINDISI: 'Brindisi',
	STATE_CALABRIA: 'Calabria',
	STATE_CAMPANIA: 'Campania',
	STATE_CARTOCETO: 'Cartoceto',
	STATE_CASERTA: 'Caserta',
	STATE_CATANIA: 'Catania',
	STATE_CHIETI: 'Chieti',
	STATE_COMO: 'Como',
	STATE_COSENZA: 'Cosenza',
	STATE_CREMONA: 'Cremona',
	STATE_CUNEO: 'Cuneo',
	STATE_EMILIA_ROMAGNA: 'Emilia-Romagna',
	STATE_FERRARA: 'Ferrara',
	STATE_FIRENZE: 'Firenze',
	STATE_FLORENCE: 'Firenze',
	STATE_FORLI_CESENA: 'Forlì-Cesena',
	STATE_FRIULI_VENEZIA_GIULIA: 'Friuli-Venezia Giulia',
	STATE_FROSINONE: 'Frosinone',
	STATE_GENOA: 'Genova',
	STATE_GORIZIA: 'Gorizia',
	STATE_L__AQUILA: 'L’Aquila',
	STATE_LAZIO: 'Lazio',
	STATE_LECCE: 'Lecce',
	STATE_LECCO: 'Lecco',
	STATE_LECCO_PROVINCE: 'Lecco, Provincia',
	STATE_LIGURIA: 'Liguria',
	STATE_LODI: 'Lodi',
	STATE_LOMBARDIA: 'Lombardia',
	STATE_LOMBARDY: 'Lombardia',
	STATE_MACERATA: 'Macerata',
	STATE_MANTOVA: 'Mantova',
	STATE_MARCHE: 'Marche',
	STATE_MESSINA: 'Messina',
	STATE_MILAN: 'Milano',
	STATE_MODENA: 'Modena',
	STATE_MOLISE: 'Molise',
	STATE_MOLTENO: 'Molteno',
	STATE_MONTENEGRO: 'Montenegro',
	STATE_MONZA_AND_BRIANZA: 'Monza e Brianza',
	STATE_NAPLES: 'Napoli',
	STATE_NOVARA: 'Novara',
	STATE_PADOVA: 'Padova',
	STATE_PARMA: 'Parma',
	STATE_PAVIA: 'Pavia',
	STATE_RUMPHI: 'Rumphi',
	STATE_PERUGIA: 'Perugia',
	STATE_PESARO_URBINO: 'Pesaro-Urbino',
	STATE_PIACENZA: 'Piacenza',
	STATE_PIEDMONT: 'Piemonte',
	STATE_PIEMONTE: 'Piemonte',
	STATE_PISA: 'Pisa',
	STATE_PORDENONE: 'Pordenone',
	STATE_POTENZA: 'Potenza',
	STATE_PUGLIA: 'Puglia',
	STATE_REGGIO_EMILIA: 'Reggio Emilia',
	STATE_RIMINI: 'Rimini',
	STATE_ROMA: 'Roma',
	STATE_SALERNO: 'Salerno',
	STATE_SARDEGNA: 'Sardegna',
	STATE_SASSARI: 'Sassari',
	STATE_SAVONA: 'Savona',
	STATE_SICILIA: 'Sicilia',
	STATE_SIENA: 'Siena',
	STATE_SONDRIO: 'Sondrio',
	STATE_SOUTH_TYROL: 'Sud Tirolo',
	STATE_TARANTO: 'Taranto',
	STATE_TERAMO: 'Teramo',
	STATE_TORINO: 'Torino',
	STATE_TOSCANA: 'Toscana',
	STATE_TRAPANI: 'Trapani',
	STATE_TRENTINO_ALTO_ADIGE: 'Trentino-Alto Adige',
	STATE_TRENTO: 'Trento',
	STATE_TREVISO: 'Treviso',
	STATE_UDINE: 'Udine',
	STATE_UMBRIA: 'Umbria',
	STATE_VALLE_D__AOSTA: 'Valle d’Aosta',
	STATE_VARESE: 'Varese',
	STATE_VENETO: 'Veneto',
	STATE_VENEZIA: 'Venezia',
	STATE_VERBANO_CUSIO_OSSOLA: 'Verbano-Cusio-Ossola',
	STATE_VERCELLI: 'Vercelli',
	STATE_VERONA: 'Verona',
	STATE_VICENZA: 'Vicenza',
	STATE_VITERBO: 'Viterbo',
	STATE_BUXORO_VILOYATI: 'Buxoro Viloyati',
	STATE_CLARENDON: 'Clarendon',
	STATE_HANOVER: 'Hanover',
	STATE_KINGSTON: 'Kingston',
	STATE_MANCHESTER: 'Manchester',
	STATE_PORTLAND: 'Portland',
	STATE_SAINT_ANDREWS: 'Saint Andrews',
	STATE_SAINT_ANN: 'Saint Ann',
	STATE_SAINT_CATHERINE: 'Saint Catherine',
	STATE_SAINT_ELIZABETH: 'Saint Elizabeth',
	STATE_TRELAWNEY: 'Trelawney',
	STATE_WESTMORELAND: 'Westmoreland',
	STATE_AICHI: 'Aichi',
	STATE_AKITA: 'Akita',
	STATE_AOMORI: 'Aomori',
	STATE_CHIBA: 'Chiba',
	STATE_EHIME: 'Ehime',
	STATE_FUKUI: 'Fukui',
	STATE_FUKUOKA: 'Fukuoka',
	STATE_FUKUSHIMA: 'Fukushima',
	STATE_GIFU: 'Gifu',
	STATE_GUMMA: 'Gumma',
	STATE_HIROSHIMA: 'Hiroshima',
	STATE_HOKKAIDO: 'Hokkaido',
	STATE_HYOGO: 'Hyogo',
	STATE_IBARAKI: 'Ibaraki',
	STATE_ISHIKAWA: 'Ishikawa',
	STATE_IWATE: 'Iwate',
	STATE_KAGAWA: 'Kagawa',
	STATE_KAGOSHIMA: 'Kagoshima',
	STATE_KANAGAWA: 'Kanagawa',
	STATE_KANTO: 'Kanto',
	STATE_KOCHI: 'Kochi',
	STATE_KUMAMOTO: 'Kumamoto',
	STATE_KYOTO: 'Kyoto',
	STATE_MIE: 'Mie',
	STATE_MIYAGI: 'Miyagi',
	STATE_MIYAZAKI: 'Miyazaki',
	STATE_NAGANO: 'Nagano',
	STATE_NAGASAKI: 'Nagasaki',
	STATE_NARA: 'Nara',
	STATE_NIIGATA: 'Niigata',
	STATE_OITA: 'Oita',
	STATE_OKAYAMA: 'Okayama',
	STATE_OKINAWA: 'Okinawa',
	STATE_OSAKA: 'Osaka',
	STATE_SAGA: 'Saga',
	STATE_SAITAMA: 'Saitama',
	STATE_SHIGA: 'Shiga',
	STATE_SHIMANE: 'Shimane',
	STATE_SHIZUOKA: 'Shizuoka',
	STATE_TOCHIGI: 'Tochigi',
	STATE_TOKUSHIMA: 'Tokushima',
	STATE_TOKYO: 'Tokyo',
	STATE_TOTTORI: 'Tottori',
	STATE_TOYAMA: 'Toyama',
	STATE_WAKAYAMA: 'Wakayama',
	STATE_YAMAGATA: 'Yamagata',
	STATE_YAMAGUCHI: 'Yamaguchi',
	STATE_YAMANASHI: 'Yamanashi',
	STATE_GROUVILLE: 'Grouville',
	STATE_SAINT_BRELADE: 'Saint Brelade',
	STATE_SAINT_CLEMENT: 'Saint Clement',
	STATE_SAINT_HELIER: 'Saint Helier',
	STATE_SAINT_LAWRENCE: 'Saint Lawrence',
	STATE_SALIMA: 'Salima',
	STATE_TRINITY: 'Trinity',
	STATE_AJLUN: 'Ajlun',
	STATE_AMMAN: 'Amman',
	STATE_IRBID: 'Irbid',
	STATE_JARASH: 'Jarash',
	STATE_MA__AN: 'Ma’an',
	STATE_MADABA: 'Madaba',
	STATE_AL___AQABAH: 'al-’Aqabah',
	STATE_AL_BALQA: 'al-Balqa',
	STATE_AL_KARAK: 'al-Karak',
	STATE_AL_MAFRAQ: 'al-Mafraq',
	STATE_AT_TAFILAH: 'at-Tafilah',
	STATE_AZ_ZARQA: 'az-Zarqa',
	STATE_AKMECET: 'Akmecet',
	STATE_AKMOLA: 'Akmola',
	STATE_AKTOBE: 'Aktobe',
	STATE_ALMATI: 'Almati',
	STATE_ATIRAU: 'Atirau',
	STATE_BATIS_KAZAKSTAN: 'Batis Kazakistan',
	STATE_BURLINSKY_REGION: 'Burlinsky, Regione',
	STATE_KARAGANDI: 'Karagandi',
	STATE_KOSTANAY: 'Kostanay',
	STATE_MANKISTAU: 'Mankistau',
	STATE_ONTUSTIK_KAZAKSTAN: 'Ontustik Kazakistan',
	STATE_PAVLODAR: 'Pavlodar',
	STATE_SIGIS_KAZAKSTAN: 'Sigis Kazakistan',
	STATE_SOLTUSTIK_KAZAKSTAN: 'Soltustik Kazakistan',
	STATE_TARAZ: 'Taraz',
	STATE_COAST: 'Coast',
	STATE_NAIROBI: 'Nairobi',
	STATE_NORTH_EASTERN: 'Nord Est',
	STATE_NYANZA: 'Nyanza',
	STATE_RIFT_VALLEY: 'Rift Valley',
	STATE_ABAIANG: 'Abaiang',
	STATE_ABEMANA: 'Abemana',
	STATE_ARANUKA: 'Aranuka',
	STATE_ARORAE: 'Arorae',
	STATE_BANABA: 'Banaba',
	STATE_BERU: 'Beru',
	STATE_BUTARITARI: 'Butaritari',
	STATE_KIRITIMATI: 'Kiritimati',
	STATE_KURIA: 'Kuria',
	STATE_MAIANA: 'Maiana',
	STATE_MAKIN: 'Makin',
	STATE_MARAKEI: 'Marakei',
	STATE_NIKUNAU: 'Nikunau',
	STATE_NONOUTI: 'Nonouti',
	STATE_ONOTOA: 'Onotoa',
	STATE_PHOENIX_ISLANDS: 'Phoenix, Isole',
	STATE_TABITEUEA_NORTH: 'Tabiteuea Nord',
	STATE_TABITEUEA_SOUTH: 'Tabiteuea Sud',
	STATE_TABUAERAN: 'Tabuaeran',
	STATE_TAMANA: 'Tamana',
	STATE_TARAWA_NORTH: 'Tarawa Nord',
	STATE_TARAWA_SOUTH: 'Tarawa Sud',
	STATE_TERAINA: 'Teraina',
	STATE_CHAGANGDO: 'Chagangdo',
	STATE_HAMGYEONGBUKTO: 'Hamgyeongbukto',
	STATE_HAMGYEONGNAMDO: 'Hamgyeongnamdo',
	STATE_HWANGHAEBUKTO: 'Hwanghaebukto',
	STATE_HWANGHAENAMDO: 'Hwanghaenamdo',
	STATE_KAESEONG: 'Kaeseong',
	STATE_KANGWEON: 'Kangweon',
	STATE_NAMPO: 'Nampo',
	STATE_PYEONGANBUKTO: 'Pyeonganbukto',
	STATE_PYEONGANNAMDO: 'Pyeongannamdo',
	STATE_PYEONGYANG: 'Pyongyang',
	STATE_YANGGANG: 'Yanggang',
	STATE_BUSAN: 'Busan',
	STATE_CHEJU: 'Cheju',
	STATE_CHOLLABUK: 'Chollabuk',
	STATE_CHOLLANAM: 'Chollanam',
	STATE_CHUNGBUK: 'Chungbuk',
	STATE_CHUNGCHEONGBUK: 'Chungcheongbuk',
	STATE_CHUNGCHEONGNAM: 'Chungcheongnam',
	STATE_CHUNGNAM: 'Chungnam',
	STATE_DAEGU: 'Daegu',
	STATE_GANGWON_DO: 'Gangwon-do',
	STATE_GOYANG_SI: 'Goyang-si',
	STATE_GYEONGGI_DO: 'Gyeonggi-do',
	STATE_GYEONGSANG: 'Gyeongsang',
	STATE_GYEONGSANGNAM_DO: 'Gyeongsangnam-do',
	STATE_INCHEON: 'Incheon',
	STATE_JEJU_SI: 'Jeju-Si',
	STATE_JEONBUK: 'Jeonbuk',
	STATE_KWANGJU: 'Kwangju',
	STATE_KYEONGGI: 'Kyeonggi',
	STATE_KYEONGSANGBUK: 'Kyeongsangbuk',
	STATE_KYEONGSANGNAM: 'Kyeongsangnam',
	STATE_KYONGGI_DO: 'Kyonggi-do',
	STATE_KYUNGBUK_DO: 'Kyungbuk-Do',
	STATE_KYUNGGI_DO: 'Kyunggi-Do',
	STATE_PUSAN: 'Pusan',
	STATE_SEOUL: 'Seul',
	STATE_SUDOGWON: 'Sudogwon',
	STATE_TAEGU: 'Taegu',
	STATE_TAEJEON: 'Taejeon',
	STATE_TAEJON_GWANGYOKSI: 'Taejon-gwangyoksi',
	STATE_ULSAN: 'Ulsan',
	STATE_WONJU: 'Wonju',
	STATE_GWANGYOKSI: 'gwangyoksi',
	STATE_AL_ASIMAH: 'Al Asimah',
	STATE_HAWALLI: 'Hawalli',
	STATE_MISHREF: 'Mishref',
	STATE_QADESIYA: 'Qadesiya',
	STATE_SAFAT: 'Safat',
	STATE_SALMIYA: 'Salmiya',
	STATE_AL_AHMADI: 'al-Ahmadi',
	STATE_AL_FARWANIYAH: 'al-Farwaniyah',
	STATE_AL_JAHRA: 'al-Jahra',
	STATE_AL_KUWAYT: 'al-Kuwayt',
	STATE_BATKEN: 'Batken',
	STATE_BISHKEK: 'Bishkek',
	STATE_CHUI: 'Chui',
	STATE_ISSYK_KUL: 'Issyk-Kul',
	STATE_JALAL_ABAD: 'Jalal-Abad',
	STATE_NARYN: 'Naryn',
	STATE_OSH: 'Osh',
	STATE_TALAS: 'Talas',
	STATE_ATTOPU: 'Attopu',
	STATE_BOKEO: 'Bokeo',
	STATE_BOLIKHAMSAY: 'Bolikhamsay',
	STATE_CHAMPASAK: 'Champasak',
	STATE_HOUAPHANH: 'Houaphanh',
	STATE_KHAMMOUANE: 'Khammouane',
	STATE_LUANG_NAM_THA: 'Luang Nam Tha',
	STATE_LUANG_PRABANG: 'Luang Prabang',
	STATE_OUDOMXAY: 'Oudomxay',
	STATE_PHONGSALY: 'Phongsaly',
	STATE_SARAVAN: 'Saravan',
	STATE_SAVANNAKHET: 'Savannakhet',
	STATE_SEKONG: 'Sekong',
	STATE_VIANGCHAN_PREFECTURE: 'Viangchan, Prefettura',
	STATE_VIANGCHAN_PROVINCE: 'Viangchan, Provincia',
	STATE_XAIGNABURY: 'Xaignabury',
	STATE_XIANG_KHUANG: 'Xiang Khuang',
	STATE_AIZKRAUKLES: 'Aizkraukles',
	STATE_ALUKSNES: 'Aluksnes',
	STATE_BALVU: 'Balvu',
	STATE_BAUSKAS: 'Bauskas',
	STATE_CESU: 'Cesu',
	STATE_DAUGAVPILS: 'Daugavpils',
	STATE_DAUGAVPILS_CITY: 'Daugavpils, Città',
	STATE_DOBELES: 'Dobeles',
	STATE_GULBENES: 'Gulbenes',
	STATE_JEKABSPILS: 'Jekabspils',
	STATE_JELGAVA: 'Jelgava',
	STATE_JELGAVAS: 'Jelgavas',
	STATE_JURMALA_CITY: 'Jurmala, Città',
	STATE_KRASLAVAS: 'Kraslavas',
	STATE_KULDIGAS: 'Kuldigas',
	STATE_LIEPAJA: 'Liepaja',
	STATE_LIEPAJAS: 'Liepajas',
	STATE_LIMBAZHU: 'Limbazhu',
	STATE_LUDZAS: 'Ludzas',
	STATE_MADONAS: 'Madonas',
	STATE_OGRES: 'Ogres',
	STATE_PREILU: 'Preilu',
	STATE_REZEKNE: 'Rezekne',
	STATE_REZEKNES: 'Rezeknes',
	STATE_RIGA: 'Riga',
	STATE_RIGAS: 'Rigas',
	STATE_SALDUS: 'Saldus',
	STATE_TALSU: 'Talsu',
	STATE_TUKUMA: 'Tukuma',
	STATE_VALKAS: 'Valkas',
	STATE_VALMIERAS: 'Valmieras',
	STATE_VENTSPILS: 'Ventspils',
	STATE_VENTSPILS_CITY: 'Ventspils, Città',
	STATE_BEIRUT: 'Beirut',
	STATE_JABAL_LUBNAN: 'Jabal Lubnan',
	STATE_MOHAFAZAT_LIBAN_NORD: 'Mohafazat Libano Nord',
	STATE_MOHAFAZAT_MONT_LIBAN: 'Mohafazat Monte Libano',
	STATE_SIDON: 'Sidone',
	STATE_AL_BIQA: 'al-Biqa',
	STATE_AL_JANUB: 'al-Janub',
	STATE_AN_NABATIYAH: 'an-Nabatiyah',
	STATE_ASH_SHAMAL: 'ash-Shamal',
	STATE_BEREA: 'Berea',
	STATE_BUTHA_BUTHE: 'Butha-Buthe',
	STATE_LERIBE: 'Leribe',
	STATE_MAFETENG: 'Mafeteng',
	STATE_MASERU: 'Maseru',
	STATE_MOHALE__S_HOEK: 'Mohale’s Hoek',
	STATE_MOKHOTLONG: 'Mokhotlong',
	STATE_QACHA__S_NEK: 'Qacha’s Nek',
	STATE_QUTHING: 'Quthing',
	STATE_THABA_TSEKA: 'Thaba-Tseka',
	STATE_BOMI: 'Bomi',
	STATE_BONG: 'Bong',
	STATE_GRAND_BASSA: 'Grand Bassa',
	STATE_GRAND_CAPE_MOUNT: 'Grand Cape Mount',
	STATE_GRAND_GEDEH: 'Grand Gedeh',
	STATE_LOFFA: 'Loffa',
	STATE_MARGIBI: 'Margibi',
	STATE_MARYLAND_AND_GRAND_KRU: 'Maryland e Grand Kru',
	STATE_MONTSERRADO: 'Montserrado',
	STATE_NIMBA: 'Nimba',
	STATE_RIVERCESS: 'Rivercess',
	STATE_SINOE: 'Sinoe',
	STATE_AJDABIYA: 'Ajdabiya',
	STATE_FEZZAN: 'Fezzan',
	STATE_BANGHAZI: 'Bengasi',
	STATE_DARNAH: 'Darnah',
	STATE_GHADAMIS: 'Ghadamis',
	STATE_GHARYAN: 'Gharyan',
	STATE_MISRATAH: 'Misurata',
	STATE_MURZUQ: 'Murzuq',
	STATE_SABHA: 'Sabha',
	STATE_SAWFAJJIN: 'Sawfajjin',
	STATE_SURT: 'Surt',
	STATE_TARABULUS: 'Tarabulus',
	STATE_TARHUNAH: 'Tarhunah',
	STATE_TRIPOLITANIA: 'Tripolitania',
	STATE_TUBRUQ: 'Tobruch',
	STATE_YAFRAN: 'Yafran',
	STATE_ZLITAN: 'Zlitan',
	STATE_AL___AZIZIYAH: 'al-’Aziziyah',
	STATE_AL_FATIH: 'al-Fatih',
	STATE_AL_JABAL_AL_AKHDAR: 'al-Jabal al Akhdar',
	STATE_AL_JUFRAH: 'al-Jufrah',
	STATE_AL_KHUMS: 'al-Khums',
	STATE_AL_KUFRAH: 'al-Kufrah',
	STATE_AN_NUQAT_AL_KHAMS: 'an-Nuqat al-Khams',
	STATE_ASH_SHATI: 'ash-Shati',
	STATE_AZ_ZAWIYAH: 'az-Zawiyah',
	STATE_BALZERS: 'Balzers',
	STATE_ESCHEN: 'Eschen',
	STATE_GAMPRIN: 'Gamprin',
	STATE_MAUREN: 'Mauren',
	STATE_PLANKEN: 'Planken',
	STATE_RUGGELL: 'Ruggell',
	STATE_SCHAAN: 'Schaan',
	STATE_SCHELLENBERG: 'Schellenberg',
	STATE_TRIESEN: 'Triesen',
	STATE_TRIESENBERG: 'Triesenberg',
	STATE_VADUZ: 'Vaduz',
	STATE_ALYTAUS: 'Alytaus',
	STATE_ANYKSCIAI: 'Anyksciai',
	STATE_KAUNO: 'Kauno',
	STATE_KLAIPEDOS: 'Klaipedos',
	STATE_MARIJAMPOLES: 'Marijampoles',
	STATE_PANEVEZHIO: 'Panevezhio',
	STATE_PANEVEZYS: 'Panevezys',
	STATE_SHIAULIU: 'Shiauliu',
	STATE_TAURAGES: 'Taurages',
	STATE_TELSHIU: 'Telshiu',
	STATE_TELSIAI: 'Telsiai',
	STATE_UTENOS: 'Utenos',
	STATE_VILNIAUS: 'Vilnius',
	STATE_CAPELLEN: 'Capellen',
	STATE_CLERVAUX: 'Clervaux',
	STATE_DIEKIRCH: 'Diekirch',
	STATE_ECHTERNACH: 'Echternach',
	STATE_ESCH_SUR_ALZETTE: 'Esch-sur-Alzette',
	STATE_GREVENMACHER: 'Grevenmacher',
	STATE_MERSCH: 'Mersch',
	STATE_REDANGE: 'Redange',
	STATE_REMICH: 'Remich',
	STATE_VIANDEN: 'Vianden',
	STATE_WILTZ: 'Wiltz',
	STATE_MACAU: 'Macao',
	STATE_BEROVO: 'Berovo',
	STATE_BITOLA: 'Bitola',
	STATE_BROD: 'Brod',
	STATE_DEBAR: 'Debar',
	STATE_DELCHEVO: 'Delchevo',
	STATE_DEMIR_HISAR: 'Demir Hisar',
	STATE_GEVGELIJA: 'Gevgelija',
	STATE_GOSTIVAR: 'Gostivar',
	STATE_KAVADARCI: 'Kavadarci',
	STATE_KICHEVO: 'Kichevo',
	STATE_KOCHANI: 'Kochani',
	STATE_KRATOVO: 'Kratovo',
	STATE_KRIVA_PALANKA: 'Kriva Palanka',
	STATE_KRUSHEVO: 'Krushevo',
	STATE_KUMANOVO: 'Kumanovo',
	STATE_NEGOTINO: 'Negotino',
	STATE_OHRID: 'Ohrid',
	STATE_PRILEP: 'Prilep',
	STATE_PROBISHTIP: 'Probishtip',
	STATE_RADOVISH: 'Radovish',
	STATE_RESEN: 'Resen',
	STATE_SHTIP: 'Shtip',
	STATE_SKOPJE: 'Skopje',
	STATE_STRUGA: 'Struga',
	STATE_STRUMICA: 'Strumica',
	STATE_SVETI_NIKOLE: 'Sveti Nikole',
	STATE_TETOVO: 'Tetovo',
	STATE_VALANDOVO: 'Valandovo',
	STATE_VELES: 'Veles',
	STATE_VINICA: 'Vinica',
	STATE_ANTANANARIVO: 'Antananarivo',
	STATE_ANTSIRANANA: 'Antsiranana',
	STATE_FIANARANTSOA: 'Fianarantsoa',
	STATE_MAHAJANGA: 'Mahajanga',
	STATE_TOAMASINA: 'Toamasina',
	STATE_TOLIARY: 'Toliary',
	STATE_BALAKA: 'Balaka',
	STATE_BLANTYRE_CITY: 'Blantyre, Città',
	STATE_CHIKWAWA: 'Chikwawa',
	STATE_CHIRADZULU: 'Chiradzulu',
	STATE_CHITIPA: 'Chitipa',
	STATE_DEDZA: 'Dedza',
	STATE_DOWA: 'Dowa',
	STATE_KARONGA: 'Karonga',
	STATE_KASUNGU: 'Kasungu',
	STATE_LILONGWE_CITY: 'Lilongwe, Città',
	STATE_MACHINGA: 'Machinga',
	STATE_MANGOCHI: 'Mangochi',
	STATE_MCHINJI: 'Mchinji',
	STATE_MULANJE: 'Mulanje',
	STATE_MWANZA: 'Mwanza',
	STATE_MZIMBA: 'Mzimba',
	STATE_MZUZU_CITY: 'Mzuzu, Città',
	STATE_NKHATA_BAY: 'Nkhata Bay',
	STATE_NKHOTAKOTA: 'Nkhotakota',
	STATE_NSANJE: 'Nsanje',
	STATE_NTCHEU: 'Ntcheu',
	STATE_NTCHISI: 'Ntchisi',
	STATE_PHALOMBE: 'Phalombe',
	STATE_THYOLO: 'Thyolo',
	STATE_ZOMBA_MUNICIPALITY: 'Zomba, Municipalità',
	STATE_JOHOR: 'Johor',
	STATE_KEDAH: 'Kedah',
	STATE_KELANTAN: 'Kelantan',
	STATE_KUALA_LUMPUR: 'Kuala Lumpur',
	STATE_LABUAN: 'Labuan',
	STATE_MELAKA: 'Melaka',
	STATE_NEGERI_JOHOR: 'Negeri Johor',
	STATE_NEGERI_SEMBILAN: 'Negeri Sembilan',
	STATE_PAHANG: 'Pahang',
	STATE_PENANG: 'Penang',
	STATE_PERAK: 'Perak',
	STATE_PERLIS: 'Perlis',
	STATE_PULAU_PINANG: 'Pulau Pinang',
	STATE_SABAH: 'Sabah',
	STATE_SARAWAK: 'Sarawak',
	STATE_SELANGOR: 'Selangor',
	STATE_SEMBILAN: 'Sembilan',
	STATE_TERENGGANU: 'Terengganu',
	STATE_ALIF_ALIF: 'Alif Alif',
	STATE_ALIF_DHAAL: 'Alif Dhaal',
	STATE_BAA: 'Baa',
	STATE_DHAAL: 'Dhaal',
	STATE_FAAF: 'Faaf',
	STATE_GAAF_ALIF: 'Gaaf Alif',
	STATE_GAAF_DHAAL: 'Gaaf Dhaal',
	STATE_GHAVIYANI: 'Ghaviyani',
	STATE_HAA_ALIF: 'Haa Alif',
	STATE_HAA_DHAAL: 'Haa Dhaal',
	STATE_KAAF: 'Kaaf',
	STATE_LAAM: 'Laam',
	STATE_LHAVIYANI: 'Lhaviyani',
	STATE_MALE: 'Male',
	STATE_MIIM: 'Miim',
	STATE_NUUN: 'Nuun',
	STATE_RAA: 'Raa',
	STATE_SHAVIYANI: 'Shaviyani',
	STATE_SIIN: 'Siin',
	STATE_THAA: 'Thaa',
	STATE_VAAV: 'Vaav',
	STATE_BAMAKO: 'Bamako',
	STATE_GAO: 'Gao',
	STATE_KAYES: 'Kayes',
	STATE_KIDAL: 'Kidal',
	STATE_KOULIKORO: 'Koulikoro',
	STATE_MOPTI: 'Mopti',
	STATE_SEGOU: 'Segou',
	STATE_SIKASSO: 'Sikasso',
	STATE_TOMBOUCTOU: 'Timbuctù',
	STATE_GOZO_AND_COMINO: 'Gozo e Comino',
	STATE_INNER_HARBOUR: 'Porto interno',
	STATE_OUTER_HARBOUR: 'Porto esterno',
	STATE_SOUTH_EASTERN: 'Sud Est',
	STATE_CASTLETOWN: 'Castletown',
	STATE_DOUGLAS: 'Douglas',
	STATE_LAXEY: 'Laxey',
	STATE_ONCHAN: 'Onchan',
	STATE_PEEL: 'Peel',
	STATE_PORT_ERIN: 'Port Erin',
	STATE_PORT_SAINT_MARY: 'Port Saint Mary',
	STATE_RAMSEY: 'Ramsey',
	STATE_AILINLAPLAP: 'Ailinlaplap',
	STATE_AILUK: 'Ailuk',
	STATE_ARNO: 'Arno',
	STATE_AUR: 'Aur',
	STATE_BIKINI: 'Bikini',
	STATE_EBON: 'Ebon',
	STATE_ENEWETAK: 'Enewetak',
	STATE_JABAT: 'Jabat',
	STATE_JALUIT: 'Jaluit',
	STATE_KILI: 'Kili',
	STATE_KWAJALEIN: 'Kwajalein',
	STATE_LAE: 'Lae',
	STATE_LIB: 'Lib',
	STATE_LIKIEP: 'Likiep',
	STATE_MAJURO: 'Majuro',
	STATE_MALOELAP: 'Maloelap',
	STATE_MEJIT: 'Mejit',
	STATE_MILI: 'Mili',
	STATE_NAMORIK: 'Namorik',
	STATE_NAMU: 'Namu',
	STATE_RONGELAP: 'Rongelap',
	STATE_UJAE: 'Ujae',
	STATE_UTRIK: 'Utrik',
	STATE_WOTHO: 'Wotho',
	STATE_WOTJE: 'Wotje',
	STATE_FORT_DE_FRANCE: 'Fort-de-France',
	STATE_LA_TRINITE: 'La Trinite',
	STATE_LE_MARIN: 'Le Marin',
	STATE_SAINT_PIERRE: 'Saint-Pierre',
	STATE_ASSABA: 'Assaba',
	STATE_BRAKNA: 'Brakna',
	STATE_DHAKHLAT_NAWADIBU: 'Dhakhlat Nawadibu',
	STATE_HUDH_AL_GHARBI: 'Hudh-al-Gharbi',
	STATE_HUDH_ASH_SHARQI: 'Hudh-ash-Sharqi',
	STATE_INSHIRI: 'Inshiri',
	STATE_NAWAKSHUT: 'Nawakshut',
	STATE_QIDIMAGHA: 'Qidimagha',
	STATE_QURQUL: 'Qurqul',
	STATE_TAQANT: 'Taqant',
	STATE_TIRIS_ZAMMUR: 'Tiris Zammur',
	STATE_TRARZA: 'Trarza',
	STATE_BLACK_RIVER: 'Black River',
	STATE_EAU_COULEE: 'Eau Coulee',
	STATE_FLACQ: 'Flacq',
	STATE_FLOREAL: 'Floreal',
	STATE_GRAND_PORT: 'Grand Port',
	STATE_MOKA: 'Moka',
	STATE_PAMPLEMPOUSSES: 'Pamplempousses',
	STATE_PLAINES_WILHELM: 'Plaines Wilhelm',
	STATE_PORT_LOUIS: 'Port Louis',
	STATE_RIVIERE_DU_REMPART: 'Riviere du Rempart',
	STATE_RODRIGUES: 'Rodrigues',
	STATE_ROSE_HILL: 'Rose Hill',
	STATE_SAVANNE: 'Savanne',
	STATE_MAYOTTE: 'Mayotte',
	STATE_PAMANZI: 'Pamanzi',
	STATE_AGUASCALIENTES: 'Aguascalientes',
	STATE_BAJA_CALIFORNIA: 'Bassa California',
	STATE_BAJA_CALIFORNIA_SUR: 'Bassa California del Sud',
	STATE_CAMPECHE: 'Campeche',
	STATE_CHIAPAS: 'Chiapas',
	STATE_CHIHUAHUA: 'Chihuahua',
	STATE_COAHUILA: 'Coahuila',
	STATE_COLIMA: 'Colima',
	STATE_DURANGO: 'Durango',
	STATE_ESTADO_DE_MEXICO: 'Stato del Messico',
	STATE_GUANAJUATO: 'Guanajuato',
	STATE_GUERRERO: 'Guerrero',
	STATE_HIDALGO: 'Hidalgo',
	STATE_JALISCO: 'Jalisco',
	STATE_MEXICO: 'Messico',
	STATE_MICHOACAN: 'Michoacan',
	STATE_MORELOS: 'Morelos',
	STATE_NAYARIT: 'Nayarit',
	STATE_NUEVO_LEON: 'Nuevo Leon',
	STATE_OAXACA: 'Oaxaca',
	STATE_PUEBLA: 'Puebla',
	STATE_QUERETARO: 'Queretaro',
	STATE_QUINTANA_ROO: 'Quintana Roo',
	STATE_SAN_LUIS_POTOSI: 'San Luis Potosi',
	STATE_SINALOA: 'Sinaloa',
	STATE_SONORA: 'Sonora',
	STATE_TABASCO: 'Tabasco',
	STATE_TAMAULIPAS: 'Tamaulipas',
	STATE_TLAXCALA: 'Tlaxcala',
	STATE_VERACRUZ: 'Veracruz',
	STATE_YUCATAN: 'Yucatan',
	STATE_ZACATECAS: 'Zacatecas',
	STATE_CHUUK: 'Chuuk',
	STATE_KUSAIE: 'Kusaie',
	STATE_POHNPEI: 'Pohnpei',
	STATE_YAP: 'Yap',
	STATE_BALTI: 'Balti',
	STATE_CAHUL: 'Cahul',
	STATE_CHISINAU: 'Chisinau',
	STATE_CHISINAU_ORAS: 'Chisinau Oras',
	STATE_EDINET: 'Edinet',
	STATE_GAGAUZIA: 'Gagauzia',
	STATE_LAPUSNA: 'Lapusna',
	STATE_ORHEI: 'Orhei',
	STATE_SOROCA: 'Soroca',
	STATE_TARACLIA: 'Taraclia',
	STATE_TIGHINA: 'Tighina',
	STATE_TRANSNISTRIA: 'Transnistria',
	STATE_UNGHENI: 'Ungheni',
	STATE_FONTVIEILLE: 'Fontvieille',
	STATE_LA_CONDAMINE: 'La Condamine',
	STATE_MONACO_VILLE: 'Monaco Vecchia',
	STATE_MONTE_CARLO: 'Monte Carlo',
	STATE_ARHANGAJ: 'Arhangaj',
	STATE_BAJAN_OLGIJ: 'Bajan-Olgij',
	STATE_BAJANHONGOR: 'Bajanhongor',
	STATE_BULGAN: 'Bulgan',
	STATE_DARHAN_UUL: 'Darhan-Uul',
	STATE_DORNOD: 'Dornod',
	STATE_DORNOGOVI: 'Dornogovi',
	STATE_DUNDGOVI: 'Dundgovi',
	STATE_GOVI_ALTAJ: 'Govi-Altaj',
	STATE_GOVISUMBER: 'Govisumber',
	STATE_HENTIJ: 'Hentij',
	STATE_HOVD: 'Hovd',
	STATE_HOVSGOL: 'Hovsgol',
	STATE_OMNOGOVI: 'Omnogovi',
	STATE_ORHON: 'Orhon',
	STATE_OVORHANGAJ: 'Ovorhangaj',
	STATE_SELENGE: 'Selenge',
	STATE_SUHBAATAR: 'Suhbaatar',
	STATE_TOV: 'Tov',
	STATE_ULAANBAATAR: 'Ulan Bator',
	STATE_UVS: 'Uvs',
	STATE_ZAVHAN: 'Zavhan',
	STATE_MONTSERRAT: 'Montserrat',
	STATE_AGADIR: 'Agadir',
	STATE_CASABLANCA: 'Casablanca',
	STATE_CHAOUIA_OUARDIGHA: 'Chaouia-Ouardigha',
	STATE_DOUKKALA_ABDA: 'Doukkala-Abda',
	STATE_FES_BOULEMANE: 'Fes-Boulemane',
	STATE_GHARB_CHRARDA_BENI_HSSEN: 'Gharb-Chrarda-Beni Hssen',
	STATE_GUELMIM: 'Guelmim',
	STATE_KENITRA: 'Kenitra',
	STATE_MARRAKECH_TENSIFT_AL_HAOUZ: 'Marrakech-Tensift-Al Haouz',
	STATE_MEKNES_TAFILALET: 'Meknes-Tafilalet',
	STATE_ORIENTAL: 'Oriental',
	STATE_OUJDA: 'Oujda',
	STATE_PROVINCE_DE_TANGER: 'Tangeri, Provincia',
	STATE_RABAT_SALE_ZAMMOUR_ZAER: 'Rabat-Sale-Zammour-Zaer',
	STATE_SALA_AL_JADIDA: 'Sala Al Jadida',
	STATE_SETTAT: 'Settat',
	STATE_SOUSS_MASSA_DRAA: 'Souss Massa-Draa',
	STATE_TADLA_AZILAL: 'Tadla-Azilal',
	STATE_TANGIER_TETOUAN: 'Tangeri-Tetouan',
	STATE_TAZA_AL_HOCEIMA_TAOUNATE: 'Taza-Al Hoceima-Taounate',
	STATE_WILAYA_DE_CASABLANCA: 'Wilaya de Casablanca',
	STATE_WILAYA_DE_RABAT_SALE: 'Wilaya de Rabat-Sale',
	STATE_CABO_DELGADO: 'Cabo Delgado',
	STATE_INHAMBANE: 'Inhambane',
	STATE_MANICA: 'Manica',
	STATE_MAPUTO: 'Maputo',
	STATE_MAPUTO_PROVINCIA: 'Maputo, Provincia',
	STATE_NAMPULA: 'Nampula',
	STATE_NIASSA: 'Niassa',
	STATE_SOFALA: 'Sofala',
	STATE_TETE: 'Tete',
	STATE_ZAMBEZIA: 'Zambezia',
	STATE_AYEYARWADY: 'Ayeyarwady',
	STATE_BAGO: 'Bago',
	STATE_CHIN: 'Chin',
	STATE_KACHIN: 'Kachin',
	STATE_KAYAH: 'Kayah',
	STATE_KAYIN: 'Kayin',
	STATE_MAGWAY: 'Magway',
	STATE_MANDALAY: 'Mandalay',
	STATE_MON: 'Mon',
	STATE_NAY_PYI_TAW: 'Nay Pyi Taw',
	STATE_RAKHINE: 'Rakhine',
	STATE_SAGAING: 'Sagaing',
	STATE_SHAN: 'Shan',
	STATE_TANINTHARYI: 'Tanintharyi',
	STATE_YANGON: 'Yangon',
	STATE_CAPRIVI: 'Caprivi',
	STATE_ERONGO: 'Erongo',
	STATE_HARDAP: 'Hardap',
	STATE_KARAS: 'Karas',
	STATE_KAVANGO: 'Kavango',
	STATE_KHOMAS: 'Khomas',
	STATE_KUNENE: 'Kunene',
	STATE_OHANGWENA: 'Ohangwena',
	STATE_OMAHEKE: 'Omaheke',
	STATE_OMUSATI: 'Omusati',
	STATE_OSHANA: 'Oshana',
	STATE_OSHIKOTO: 'Oshikoto',
	STATE_OTJOZONDJUPA: 'Otjozondjupa',
	STATE_YAREN: 'Yaren',
	STATE_BAGMATI: 'Bagmati',
	STATE_BHERI: 'Bheri',
	STATE_DHAWALAGIRI: 'Dhawalagiri',
	STATE_GANDAKI: 'Gandaki',
	STATE_JANAKPUR: 'Janakpur',
	STATE_KARNALI: 'Karnali',
	STATE_KOSHI: 'Koshi',
	STATE_LUMBINI: 'Lumbini',
	STATE_MAHAKALI: 'Mahakali',
	STATE_MECHI: 'Mechi',
	STATE_NARAYANI: 'Narayani',
	STATE_RAPTI: 'Rapti',
	STATE_SAGARMATHA: 'Sagarmatha',
	STATE_SETI: 'Seti',
	STATE_BONAIRE: 'Bonaire',
	STATE_CURACAO: 'Curaçao',
	STATE_SABA: 'Saba',
	STATE_SINT_EUSTATIUS: 'Sint Eustatius',
	STATE_SINT_MAARTEN: 'Sint Maarten',
	STATE_BENELUX: 'Benelux',
	STATE_DRENTHE: 'Drenthe',
	STATE_FLEVOLAND: 'Flevoland',
	STATE_FRIESLAND: 'Friesland',
	STATE_GELDERLAND: 'Gelderland',
	STATE_GRONINGEN: 'Groningen',
	STATE_NOORD_BRABANT: 'Brabante Settentrionale',
	STATE_OVERIJSSEL: 'Overijssel',
	STATE_SOUTH_HOLLAND: 'Olanda Meridionale',
	STATE_UTRECHT: 'Utrecht',
	STATE_ZEELAND: 'Zeeland',
	STATE_ZUID_HOLLAND: 'Olanda Meridionale',
	STATE_ILES: 'Iles',
	STATE_AREA_OUTSIDE_REGION: 'Area al di fuori della regione',
	STATE_BAY_OF_PLENTY: 'Bay of Plenty',
	STATE_CANTERBURY: 'Canterbury',
	STATE_CHRISTCHURCH: 'Christchurch',
	STATE_GISBORNE: 'Gisborne',
	STATE_HAWKE__S_BAY: 'Hawke’s Bay',
	STATE_MANAWATU_WANGANUI: 'Manawatu-Wanganui',
	STATE_MARLBOROUGH: 'Marlborough',
	STATE_NELSON: 'Nelson',
	STATE_NORTHLAND: 'Northland',
	STATE_OTAGO: 'Otago',
	STATE_RODNEY: 'Rodney',
	STATE_SOUTHLAND: 'Southland',
	STATE_TARANAKI: 'Taranaki',
	STATE_TASMAN: 'Tasman',
	STATE_WAIKATO: 'Waikato',
	STATE_WELLINGTON: 'Wellington',
	STATE_WEST_COAST: 'West Coast',
	STATE_ATLANTICO_NORTE: 'Atlantico Norte',
	STATE_ATLANTICO_SUR: 'Atlantico Sur',
	STATE_BOACO: 'Boaco',
	STATE_CARAZO: 'Carazo',
	STATE_CHINANDEGA: 'Chinandega',
	STATE_CHONTALES: 'Chontales',
	STATE_ESTELI: 'Esteli',
	STATE_GRANADA: 'Granada',
	STATE_JINOTEGA: 'Jinotega',
	STATE_LEON: 'Leon',
	STATE_MADRIZ: 'Madriz',
	STATE_MANAGUA: 'Managua',
	STATE_MASAYA: 'Masaya',
	STATE_MATAGALPA: 'Matagalpa',
	STATE_NUEVA_SEGOVIA: 'Nueva Segovia',
	STATE_RIO_SAN_JUAN: 'Rio San Juan',
	STATE_RIVAS: 'Rivas',
	STATE_AGADEZ: 'Agadez',
	STATE_DIFFA: 'Diffa',
	STATE_DOSSO: 'Dosso',
	STATE_MARADI: 'Maradi',
	STATE_NIAMEY: 'Niamey',
	STATE_TAHOUA: 'Tahoua',
	STATE_TILLABERY: 'Tillabery',
	STATE_ZINDER: 'Zinder',
	STATE_ABIA: 'Abia',
	STATE_ABUJA_FEDERAL_CAPITAL_TERRITOR: 'Abuja, Territorio della capitale federale',
	STATE_ADAMAWA: 'Adamawa',
	STATE_AKWA_IBOM: 'Akwa Ibom',
	STATE_ANAMBRA: 'Anambra',
	STATE_BAUCHI: 'Bauchi',
	STATE_BAYELSA: 'Bayelsa',
	STATE_BENUE: 'Benue',
	STATE_BORNO: 'Borno',
	STATE_CROSS_RIVER: 'Cross River',
	STATE_DELTA: 'Delta',
	STATE_EBONYI: 'Ebonyi',
	STATE_EDO: 'Edo',
	STATE_EKITI: 'Ekiti',
	STATE_ENUGU: 'Enugu',
	STATE_GOMBE: 'Gombe',
	STATE_IMO: 'Imo',
	STATE_JIGAWA: 'Jigawa',
	STATE_KADUNA: 'Kaduna',
	STATE_KANO: 'Kano',
	STATE_KATSINA: 'Katsina',
	STATE_KEBBI: 'Kebbi',
	STATE_KOGI: 'Kogi',
	STATE_KWARA: 'Kwara',
	STATE_LAGOS: 'Lagos',
	STATE_NASSARAWA: 'Nassarawa',
	STATE_NIGER: 'Niger',
	STATE_OGUN: 'Ogun',
	STATE_ONDO: 'Ondo',
	STATE_OSUN: 'Osun',
	STATE_OYO: 'Oyo',
	STATE_RIVERS: 'Rivers',
	STATE_SOKOTO: 'Sokoto',
	STATE_TARABA: 'Taraba',
	STATE_YOBE: 'Yobe',
	STATE_ZAMFARA: 'Zamfara',
	STATE_NIUE: 'Niue',
	STATE_NORFOLK_ISLAND: 'Norfolk, Isola',
	STATE_NORTHERN_ISLANDS: 'Isole Settentrionali',
	STATE_ROTA: 'Rota',
	STATE_SAIPAN: 'Saipan',
	STATE_TINIAN: 'Tinian',
	STATE_AKERSHUS: 'Akershus',
	STATE_AUST_AGDER: 'Aust Agder',
	STATE_BERGEN: 'Bergen',
	STATE_BUSKERUD: 'Buskerud',
	STATE_FINNMARK: 'Finnmark',
	STATE_HEDMARK: 'Hedmark',
	STATE_HORDALAND: 'Hordaland',
	STATE_MOERE_OG_ROMSDAL: 'Moere og Romsdal',
	STATE_NORD_TRONDELAG: 'Trøndelag del Nord',
	STATE_NORDLAND: 'Nordland',
	STATE_OESTFOLD: 'Oestfold',
	STATE_OPPLAND: 'Oppland',
	STATE_OSLO: 'Oslo',
	STATE_ROGALAND: 'Rogaland',
	STATE_SOER_TROENDELAG: 'Trøndelag del Sud',
	STATE_SOGN_OG_FJORDANE: 'Sogn og Fjordane',
	STATE_STAVERN: 'Stavern',
	STATE_SYKKYLVEN: 'Sykkylven',
	STATE_TELEMARK: 'Telemark',
	STATE_TROMS: 'Troms',
	STATE_VEST_AGDER: 'Vest Agder',
	STATE_VESTFOLD: 'Vestfold',
	'STATE_ÃƑÆ’Ã‚ËŒSTFOLD': 'ÃƒÆ’Ã‚Ëœstfold',
	STATE_AL_BURAIMI: 'Al Buraimi',
	STATE_DHUFAR: 'Dhufar',
	STATE_MASQAT: 'Masqat',
	STATE_MUSANDAM: 'Musandam',
	STATE_RUSAYL: 'Rusayl',
	STATE_WADI_KABIR: 'Wadi Kabir',
	STATE_AD_DAKHILIYAH: 'ad-Dakhiliyah',
	STATE_ADH_DHAHIRAH: 'adh-Dhahirah',
	STATE_AL_BATINAH: 'al-Batinah',
	STATE_BALUCHISTAN: 'Baluchistan',
	STATE_FEDERAL_CAPITAL_AREA: 'Area della Capitale Federale',
	STATE_FEDERALLY_ADMINISTERED_TRIBAL: 'Aree tribali amministrate federalmente',
	STATE_NORTH_WEST_FRONTIER: 'Frontiera del Nord Ovest',
	STATE_NORTHERN_AREAS: 'Aree Settentrionali',
	STATE_SIND: 'Sind',
	STATE_AIMELIIK: 'Aimeliik',
	STATE_AIRAI: 'Airai',
	STATE_ANGAUR: 'Angaur',
	STATE_HATOBOHEI: 'Hatobohei',
	STATE_KAYANGEL: 'Kayangel',
	STATE_KOROR: 'Koror',
	STATE_MELEKEOK: 'Melekeok',
	STATE_NGARAARD: 'Ngaraard',
	STATE_NGARDMAU: 'Ngardmau',
	STATE_NGAREMLENGUI: 'Ngaremlengui',
	STATE_NGATPANG: 'Ngatpang',
	STATE_NGCHESAR: 'Ngchesar',
	STATE_NGERCHELONG: 'Ngerchelong',
	STATE_NGIWAL: 'Ngiwal',
	STATE_PELELIU: 'Peleliu',
	STATE_SONSOROL: 'Sonsorol',
	STATE_ARIHA: 'Ariha',
	STATE_BAYT_LAHM: 'Bayt Lahm',
	STATE_DAYR_AL_BALAH: 'Dayr-al-Balah',
	STATE_GHAZZAH: 'Ghazzah',
	STATE_GHAZZAH_ASH_SHAMALIYAH: 'Ghazzah ash-Shamaliyah',
	STATE_JANIN: 'Janin',
	STATE_KHAN_YUNIS: 'Khan Yunis',
	STATE_NABULUS: 'Nabulus',
	STATE_QALQILYAH: 'Qalqilyah',
	STATE_RAFAH: 'Rafah',
	STATE_RAM_ALLAH_WAL_BIRAH: 'Ram Allah wal-Birah',
	STATE_SALFIT: 'Salfit',
	STATE_TUBAS: 'Tubas',
	STATE_TULKARM: 'Tulkarm',
	STATE_AL_KHALIL: 'al-Khalil',
	STATE_AL_QUDS: 'al-Quds',
	STATE_BOCAS_DEL_TORO: 'Bocas del Toro',
	STATE_CHIRIQUI: 'Chiriqui',
	STATE_COCLE: 'Cocle',
	STATE_DARIEN: 'Darien',
	STATE_EMBERA: 'Embera',
	STATE_HERRERA: 'Herrera',
	STATE_KUNA_YALA: 'Kuna Yala',
	STATE_LOS_SANTOS: 'Los Santos',
	STATE_NGOBE_BUGLE: 'Ngobe Bugle',
	STATE_PANAMA: 'Panama',
	STATE_VERAGUAS: 'Veraguas',
	STATE_EAST_NEW_BRITAIN: 'Nuova Britannia Orientale',
	STATE_EAST_SEPIK: 'Sepik Orientale',
	STATE_EASTERN_HIGHLANDS: 'Altopiani Orientali',
	STATE_ENGA: 'Enga',
	STATE_FLY_RIVER: 'Fly River',
	STATE_GULF: 'Gulf',
	STATE_MADANG: 'Madang',
	STATE_MANUS: 'Manus',
	STATE_MILNE_BAY: 'Milne Bay',
	STATE_MOROBE: 'Morobe',
	STATE_NATIONAL_CAPITAL_DISTRICT: 'Distretto Capitale Nazionale',
	STATE_NEW_IRELAND: 'Nuova Irlanda',
	STATE_NORTH_SOLOMONS: 'Salomone Settentrionali',
	STATE_ORO: 'Oro',
	STATE_SANDAUN: 'Sandaun',
	STATE_SIMBU: 'Simbu',
	STATE_SOUTHERN_HIGHLANDS: 'Altopiani Meridionali',
	STATE_WEST_NEW_BRITAIN: 'Nuova Britannia Occidentale',
	STATE_WESTERN_HIGHLANDS: 'Altopiani Occidentali',
	STATE_ALTO_PARAGUAY: 'Alto Paraguay',
	STATE_ALTO_PARANA: 'Alto Paranà',
	STATE_AMAMBAY: 'Amambay',
	STATE_ASUNCION: 'Asuncion',
	STATE_BOQUERON: 'Boqueron',
	STATE_CAAGUAZU: 'Caaguazu',
	STATE_CAAZAPA: 'Caazapa',
	STATE_CANENDIYU: 'Canendiyu',
	STATE_CONCEPCION: 'Concepcion',
	STATE_CORDILLERA: 'Cordillera',
	STATE_GUAIRA: 'Guaira',
	STATE_ITAPUA: 'Itapua',
	STATE_NEEMBUCU: 'Neembucu',
	STATE_PARAGUARI: 'Paraguari',
	STATE_PRESIDENTE_HAYES: 'Presidente Hayes',
	STATE_SAN_PEDRO: 'San Pedro',
	STATE_ANCASH: 'Ancash',
	STATE_APURIMAC: 'Apurimac',
	STATE_AREQUIPA: 'Arequipa',
	STATE_AYACUCHO: 'Ayacucho',
	STATE_CAJAMARCA: 'Cajamarca',
	STATE_CUSCO: 'Cusco',
	STATE_HUANCAVELICA: 'Huancavelica',
	STATE_HUANUCO: 'Huanuco',
	STATE_ICA: 'Ica',
	STATE_JUNIN: 'Junin',
	STATE_LAMBAYEQUE: 'Lambayeque',
	STATE_LIMA_Y_CALLAO: 'Lima e Callao',
	STATE_LORETO: 'Loreto',
	STATE_MADRE_DE_DIOS: 'Madre de Dios',
	STATE_MOQUEGUA: 'Moquegua',
	STATE_PASCO: 'Pasco',
	STATE_PIURA: 'Piura',
	STATE_PUNO: 'Puno',
	STATE_SAN_MARTIN: 'San Martin',
	STATE_TACNA: 'Tacna',
	STATE_TUMBES: 'Tumbes',
	STATE_UCAYALI: 'Ucayali',
	STATE_BATANGAS: 'Batangas',
	STATE_BICOL: 'Bicol',
	STATE_BULACAN: 'Bulacan',
	STATE_CAGAYAN: 'Cagayan',
	STATE_CARAGA: 'Caraga',
	STATE_CENTRAL_LUZON: 'Luzon Centrale',
	STATE_CENTRAL_MINDANAO: 'Mindanao Centrale',
	STATE_CENTRAL_VISAYAS: 'Visayas Centrale',
	STATE_DAVAO: 'Davao',
	STATE_EASTERN_VISAYAS: 'Visayas Orientale',
	STATE_GREATER_METROPOLITAN_AREA: 'Area metropolitana allargata',
	STATE_ILOCOS: 'Ilocos',
	STATE_LAGUNA: 'Laguna',
	STATE_LUZON: 'Luzon',
	STATE_MACTAN: 'Mactan',
	STATE_METROPOLITAN_MANILA_AREA: 'Manila, Area metropolitana',
	STATE_MUSLIM_MINDANAO: 'Mindanao Musulmano',
	STATE_NORTHERN_MINDANAO: 'Mindanao Settentrionale',
	STATE_SOUTHERN_MINDANAO: 'Mindanao Meridionale',
	STATE_SOUTHERN_TAGALOG: 'Tagalog Meridionale',
	STATE_WESTERN_MINDANAO: 'Mindanao Occidentale',
	STATE_WESTERN_VISAYAS: 'Visayas Occidentale',
	STATE_PITCAIRN_ISLAND: 'Pitcairn, Isola',
	STATE_BIALE_BLOTA: 'Biale Blota',
	STATE_DOBROSZYCE: 'Dobroszyce',
	STATE_DOLNOSLASKIE: 'Dolnoslaskie',
	STATE_DZIEKANOW_LESNY: 'Dziekanow Lesny',
	STATE_HOPOWO: 'Hopowo',
	STATE_KARTUZY: 'Kartuzy',
	STATE_KOSCIAN: 'Koscian',
	STATE_KRAKOW: 'Cracovia',
	STATE_KUJAWSKO_POMORSKIE: 'Cuiavia-Pomerania',
	STATE_LODZKIE: 'Lodz, Voivodato',
	STATE_LUBELSKIE: 'Lublino, Voivodato',
	STATE_LUBUSKIE: 'Lubusz, Voivodato',
	STATE_MALOMICE: 'Malomice',
	STATE_MALOPOLSKIE: 'Piccola Polonia, Voivodato',
	STATE_MAZOWIECKIE: 'Masovia, Voivodato',
	STATE_MIRKOW: 'Mirkow',
	STATE_OPOLSKIE: 'Opolskie',
	STATE_OSTROWIEC: 'Ostrowiec',
	STATE_PODKARPACKIE: 'Precarpazia, Voivodato',
	STATE_PODLASKIE: 'Podlachia, Voivodato',
	STATE_POLSKA: 'Polonia',
	STATE_POMORSKIE: 'Pomerania, Voivodato',
	STATE_POZNAN: 'Poznan',
	STATE_PRUSZKOW: 'Pruszkow',
	STATE_RYMANOWSKA: 'Rymanowska',
	STATE_RZESZOW: 'Rzeszow',
	STATE_SLASKIE: 'Slaskie',
	STATE_STARE_POLE: 'Stare Pole',
	STATE_SWIETOKRZYSKIE: 'Santacroce, Voivodato',
	STATE_WARMINSKO_MAZURSKIE: 'Varmia-Masuria, Voivodato',
	STATE_WARSAW: 'Varsavia',
	STATE_WEJHEROWO: 'Wejherowo',
	STATE_WIELKOPOLSKIE: 'Grande Polonia, Voivodato',
	STATE_WROCLAW: 'Breslavia',
	STATE_ZACHODNIO_POMORSKIE: 'Zachodnio-Pomorskie',
	STATE_ZUKOWO: 'Zukowo',
	STATE_ABRANTES: 'Abrantes',
	STATE_ACORES: 'Azzorre',
	STATE_ALENTEJO: 'Alentejo',
	STATE_ALGARVE: 'Algarve',
	STATE_BRAGA: 'Braga',
	STATE_CENTRO: 'Centro',
	STATE_DISTRITO_DE_LEIRIA: 'Leiria, Distretto',
	STATE_DISTRITO_DE_VIANA_DO_CASTELO: 'Viana do Castelo, Distretto',
	STATE_DISTRITO_DE_VILA_REAL: 'Vila Real, Distretto',
	STATE_DISTRITO_DO_PORTO: 'Oporto, Distretto',
	STATE_LISBOA_E_VALE_DO_TEJO: 'Lisbona e Valle del Tago',
	STATE_MADEIRA: 'Madeira',
	STATE_NORTE: 'Nord',
	STATE_PAIVAS: 'Paivas',
	STATE_ARECIBO: 'Arecibo',
	STATE_BAYAMON: 'Bayamon',
	STATE_CAROLINA: 'Carolina',
	STATE_FLORIDA: 'Florida',
	STATE_GUAYAMA: 'Guayama',
	STATE_HUMACAO: 'Humacao',
	STATE_MAYAGUEZ_AGUADILLA: 'Mayaguez-Aguadilla',
	STATE_PONCE: 'Ponce',
	STATE_SALINAS: 'Salinas',
	STATE_DOHA: 'Doha',
	STATE_JARIAN_AL_BATNAH: 'Jarian-al-Batnah',
	STATE_UMM_SALAL: 'Umm Salal',
	STATE_AD_DAWHAH: 'ad-Dawhah',
	STATE_AL_GHUWAYRIYAH: 'al-Ghuwayriyah',
	STATE_AL_JUMAYLIYAH: 'al-Jumayliyah',
	STATE_AL_KHAWR: 'al-Khawr',
	STATE_AL_WAKRAH: 'al-Wakrah',
	STATE_AR_RAYYAN: 'ar-Rayyan',
	STATE_SAINT_BENOIT: 'Saint-Benoit',
	STATE_SAINT_DENIS: 'Saint-Denis',
	STATE_ALBA: 'Alba',
	STATE_ARAD: 'Arad',
	STATE_ARGES: 'Arges',
	STATE_BACAU: 'Bacau',
	STATE_BIHOR: 'Bihor',
	STATE_BISTRITA_NASAUD: 'Bistrita-Nasaud',
	STATE_BOTOSANI: 'Botosani',
	STATE_BRAILA: 'Braila',
	STATE_BRASOV: 'Brasov',
	STATE_BUCURESTI: 'Bucarest',
	STATE_BUZAU: 'Buzau',
	STATE_CALARASI: 'Calarasi',
	STATE_CARAS_SEVERIN: 'Caras-Severin',
	STATE_CLUJ: 'Cluj',
	STATE_CONSTANTA: 'Costanza',
	STATE_COVASNA: 'Covasna',
	STATE_DAMBOVITA: 'Dambovita',
	STATE_DOLJ: 'Dolj',
	STATE_GALATI: 'Galati',
	STATE_GIURGIU: 'Giurgiu',
	STATE_GORJ: 'Gorj',
	STATE_HARGHITA: 'Harghita',
	STATE_HUNEDOARA: 'Hunedoara',
	STATE_IALOMITA: 'Ialomita',
	STATE_IASI: 'Iasi',
	STATE_ILFOV: 'Ilfov',
	STATE_MARAMURES: 'Maramures',
	STATE_MEHEDINTI: 'Mehedinti',
	STATE_MURES: 'Mures',
	STATE_NEAMT: 'Neamt',
	STATE_OLT: 'Olt',
	STATE_PRAHOVA: 'Prahova',
	STATE_SALAJ: 'Salaj',
	STATE_SATU_MARE: 'Satu Mare',
	STATE_SIBIU: 'Sibiu',
	STATE_SONDELOR: 'Sondelor',
	STATE_SUCEAVA: 'Suceava',
	STATE_TELEORMAN: 'Teleorman',
	STATE_TIMIS: 'Timis',
	STATE_TULCEA: 'Tulcea',
	STATE_VALCEA: 'Valcea',
	STATE_VASLUI: 'Vaslui',
	STATE_VRANCEA: 'Vrancea',
	STATE_ADYGEJA: 'Adygeja',
	STATE_AGA: 'Aga',
	STATE_ALANIJA: 'Alanija',
	STATE_ALTAJ: 'Altaj',
	STATE_AMUR: 'Amur',
	STATE_ARHANGELSK: 'Arcangelo',
	STATE_ASTRAHAN: 'Astrakan',
	STATE_BASHKORTOSTAN: 'Bashkortostan',
	STATE_BELGOROD: 'Belgorod',
	STATE_BRJANSK: 'Brjansk',
	STATE_BURJATIJA: 'Burjatija',
	STATE_CHECHENIJA: 'Cecenia',
	STATE_CHELJABINSK: 'Cheljabinsk',
	STATE_CHITA: 'Chita',
	STATE_CHUKOTKA: 'Chukotka',
	STATE_CHUVASHIJA: 'Chuvashija',
	STATE_DAGESTAN: 'Dagestan',
	STATE_EVENKIJA: 'Evenkija',
	STATE_GORNO_ALTAJ: 'Gorno-Altaj',
	STATE_HABAROVSK: 'Habarovsk',
	STATE_HAKASIJA: 'Hakasija',
	STATE_HANTY_MANSIJA: 'Hanty-Mansija',
	STATE_INGUSETIJA: 'Ingusetija',
	STATE_IRKUTSK: 'Irkutsk',
	STATE_IVANOVO: 'Ivanovo',
	STATE_JAMALO_NENETS: 'Jamalo-Nenets',
	STATE_JAROSLAVL: 'Jaroslavl',
	STATE_JEVREJ: 'Jevrej',
	STATE_KABARDINO_BALKARIJA: 'Cabardino-Balcaria',
	STATE_KALININGRAD: 'Kaliningrad',
	STATE_KALMYKIJA: 'Kalmykija',
	STATE_KALUGA: 'Kaluga',
	STATE_KAMCHATKA: 'Kamchatka',
	STATE_KARACHAJ_CHERKESSIJA: 'Karachaj-Cherkessija',
	STATE_KARELIJA: 'Carelia',
	STATE_KEMEROVO: 'Kemerovo',
	STATE_KHABAROVSKIY_KRAY: 'Khabarovskiy Kray',
	STATE_KIROV: 'Kirov',
	STATE_KOMI: 'Komi',
	STATE_KOMI_PERMJAKIJA: 'Komi-Permjakija',
	STATE_KORJAKIJA: 'Korjakija',
	STATE_KOSTROMA: 'Kostroma',
	STATE_KRASNODAR: 'Krasnodar',
	STATE_KRASNOJARSK: 'Krasnojarsk',
	STATE_KRASNOYARSKIY_KRAY: 'Krasnoyarskiy Kray',
	STATE_KURGAN: 'Kurgan',
	STATE_KURSK: 'Kursk',
	STATE_LENINGRAD: 'Leningrado',
	STATE_LIPECK: 'Lipeck',
	STATE_MAGADAN: 'Magadan',
	STATE_MARIJ_EL: 'Marij El',
	STATE_MORDOVIJA: 'Mordovija',
	STATE_MOSCOW: 'Mosca',
	STATE_MOSKOVSKAJA_OBLAST: 'Mosca, Oblast',
	STATE_MOSKOVSKAYA_OBLAST: 'Mosca, Oblast',
	STATE_MOSKVA: 'Mosca',
	STATE_MURMANSK: 'Murmansk',
	STATE_NENETS: 'Nenets',
	STATE_NIZHNIJ_NOVGOROD: 'Nizhnij Novgorod',
	STATE_NOVGOROD: 'Novgorod',
	STATE_NOVOKUSNEZK: 'Novokusnezk',
	STATE_NOVOSIBIRSK: 'Novosibirsk',
	STATE_OMSK: 'Omsk',
	STATE_ORENBURG: 'Orenburg',
	STATE_ORJOL: 'Orjol',
	STATE_PENZA: 'Penza',
	STATE_PERM: 'Perm',
	STATE_PRIMORJE: 'Primorje',
	STATE_PSKOV: 'Pskov',
	STATE_PSKOVSKAYA_OBLAST: 'Pskov, Oblast',
	STATE_RJAZAN: 'Rjazan',
	STATE_ROSTOV: 'Rostov',
	STATE_SAHA: 'Saha',
	STATE_SAHALIN: 'Sachalin',
	STATE_SAMARA: 'Samara',
	STATE_SAMARSKAYA: 'Samarskaya',
	STATE_SANKT_PETERBURG: 'Santo Pietroburgo',
	STATE_SARATOV: 'Saratov',
	STATE_SMOLENSK: 'Smolensk',
	STATE_STAVROPOL: 'Stavropol',
	STATE_SVERDLOVSK: 'Sverdlovsk',
	STATE_TAJMYRIJA: 'Tajmyrija',
	STATE_TAMBOV: 'Tambov',
	STATE_TATARSTAN: 'Tatarstan',
	STATE_TJUMEN: 'Tjumen',
	STATE_TOMSK: 'Tomsk',
	STATE_TULA: 'Tula',
	STATE_TVER: 'Tver',
	STATE_TYVA: 'Tyva',
	STATE_UDMURTIJA: 'Udmurtija',
	STATE_ULJANOVSK: 'Uljanovsk',
	STATE_ULYANOVSKAYA_OBLAST: 'Uljanovsk, Oblast',
	STATE_UST_ORDA: 'Ust-Orda',
	STATE_VLADIMIR: 'Vladimir',
	STATE_VOLGOGRAD: 'Volgograd',
	STATE_VOLOGDA: 'Vologda',
	STATE_VORONEZH: 'Voronezh',
	STATE_BUTARE: 'Butare',
	STATE_BYUMBA: 'Byumba',
	STATE_CYANGUGU: 'Cyangugu',
	STATE_GIKONGORO: 'Gikongoro',
	STATE_GISENYI: 'Gisenyi',
	STATE_GITARAMA: 'Gitarama',
	STATE_KIBUNGO: 'Kibungo',
	STATE_KIBUYE: 'Kibuye',
	STATE_KIGALI_NGALI: 'Kigali-ngali',
	STATE_RUHENGERI: 'Ruhengeri',
	STATE_ASCENSION: 'Ascension',
	STATE_GOUGH_ISLAND: 'Gough, Isola',
	STATE_SAINT_HELENA: 'Sant’Elena',
	STATE_TRISTAN_DA_CUNHA: 'Tristan da Cunha',
	STATE_CHRIST_CHURCH_NICHOLA_TOWN: 'Christ Church Nichola Town',
	STATE_SAINT_ANNE_SANDY_POINT: 'Saint Anne Sandy Point',
	STATE_SAINT_GEORGE_BASSETERRE: 'Saint George Basseterre',
	STATE_SAINT_GEORGE_GINGERLAND: 'Saint George Gingerland',
	STATE_SAINT_JAMES_WINDWARD: 'Saint James Windward',
	STATE_SAINT_JOHN_CAPESTERRE: 'Saint John Capesterre',
	STATE_SAINT_JOHN_FIGTREE: 'Saint John Figtree',
	STATE_SAINT_MARY_CAYON: 'Saint Mary Cayon',
	STATE_SAINT_PAUL_CAPESTERRE: 'Saint Paul Capesterre',
	STATE_SAINT_PAUL_CHARLESTOWN: 'Saint Paul Charlestown',
	STATE_SAINT_PETER_BASSETERRE: 'Saint Peter Basseterre',
	STATE_SAINT_THOMAS_LOWLAND: 'Saint Thomas Lowland',
	STATE_SAINT_THOMAS_MIDDLE_ISLAND: 'Saint Thomas Middle Island',
	STATE_TRINITY_PALMETTO_POINT: 'Trinity Palmetto Point',
	STATE_ANSE_LA_RAYE: 'Anse-la-Raye',
	STATE_CANARIES: 'Canarie',
	STATE_CASTRIES: 'Castries',
	STATE_CHOISEUL: 'Choiseul',
	STATE_DENNERY: 'Dennery',
	STATE_GROS_INLET: 'Gros Inlet',
	STATE_LABORIE: 'Laborie',
	STATE_MICOUD: 'Micoud',
	STATE_SOUFRIERE: 'Soufriere',
	STATE_VIEUX_FORT: 'Vieux Fort',
	STATE_MIQUELON_LANGLADE: 'Miquelon-Langlade',
	STATE_CHARLOTTE: 'Charlotte',
	STATE_GRENADINES: 'Grenadine',
	STATE_A__ANA: 'A’ana',
	STATE_AIGA_I_LE_TAI: 'Aiga-i-le-Tai',
	STATE_ATUA: 'Atua',
	STATE_FA__ASALELEAGA: 'Fa’asaleleaga',
	STATE_GAGA__EMAUGA: 'Gaga’emauga',
	STATE_GAGAIFOMAUGA: 'Gagaifomauga',
	STATE_PALAULI: 'Palauli',
	STATE_SATUPA__ITEA: 'Satupa’itea',
	STATE_TUAMASAGA: 'Tuamasaga',
	STATE_VA__A_O_FONOTI: 'Va’a-o-Fonoti',
	STATE_VAISIGANO: 'Vaisigano',
	STATE_ACQUAVIVA: 'Acquaviva',
	STATE_BORGO_MAGGIORE: 'Borgo Maggiore',
	STATE_CHIESANUOVA: 'Chiesanuova',
	STATE_DOMAGNANO: 'Domagnano',
	STATE_FAETANO: 'Faetano',
	STATE_FIORENTINO: 'Fiorentino',
	STATE_MONTEGIARDINO: 'Montegiardino',
	STATE_SAN_MARINO: 'San Marino',
	STATE_SERRAVALLE: 'Serravalle',
	STATE_AGUA_GRANDE: 'Agua Grande',
	STATE_CANTAGALO: 'Cantagalo',
	STATE_LEMBA: 'Lemba',
	STATE_LOBATA: 'Lobata',
	STATE_ME_ZOCHI: 'Me-Zochi',
	STATE_PAGUE: 'Pague',
	STATE_AL_KHOBAR: 'Al Khobar',
	STATE_ASEER: 'Aseer',
	STATE_ASIR: 'Asir',
	STATE_CENTRAL_PROVINCE: 'Provincia Centrale',
	STATE_EASTERN_PROVINCE: 'Provincia Orientale',
	STATE_HA__IL: 'Ha’il',
	STATE_JAWF: 'Jawf',
	STATE_JIZAN: 'Jizan',
	STATE_MAKKAH: 'Mecca',
	STATE_NAJRAN: 'Najran',
	STATE_QASIM: 'Qasim',
	STATE_TABUK: 'Tabuk',
	STATE_WESTERN_PROVINCE: 'Provincia Occidentale',
	STATE_AL_BAHAH: 'al-Bahah',
	STATE_AL_HUDUD_ASH_SHAMALIYAH: 'al-Hudud-ash-Shamaliyah',
	STATE_AL_MADINAH: 'Medina',
	STATE_AR_RIYAD: 'Riyad',
	STATE_DAKAR: 'Dakar',
	STATE_DIOURBEL: 'Diourbel',
	STATE_FATICK: 'Fatick',
	STATE_KAOLACK: 'Kaolack',
	STATE_KOLDA: 'Kolda',
	STATE_LOUGA: 'Louga',
	STATE_SAINT_LOUIS: 'Saint-Louis',
	STATE_TAMBACOUNDA: 'Tambacounda',
	STATE_THIES: 'Thies',
	STATE_ZIGUINCHOR: 'Ziguinchor',
	STATE_CENTRAL_SERBIA: 'Serbia Centrale',
	STATE_KOSOVO_AND_METOHIJA: 'Kosovo e Metohija',
	STATE_VOJVODINA: 'Voivodina',
	STATE_ANSE_BOILEAU: 'Anse Boileau',
	STATE_ANSE_ROYALE: 'Anse Royale',
	STATE_CASCADE: 'Cascade',
	STATE_TAKAMAKA: 'Takamaka',
	STATE_SINGAPORE: 'Singapore',
	STATE_BANSKOBYSTRICKY: 'Banska Bystrica, Regione',
	STATE_BRATISLAVSKY: 'Bratislava, Regione',
	STATE_KOSICKY: 'Kosice, Regione',
	STATE_NITRIANSKY: 'Nitra, Regione',
	STATE_PRESOVSKY: 'Presov, Regione',
	STATE_TRENCIANSKY: 'Trencin, Regione',
	STATE_TRNAVSKY: 'Trnava, Regione',
	STATE_ZILINSKY: 'Zilina, Regione',
	STATE_BENEDIKT: 'Benedikt',
	STATE_GORENJSKA: 'Alta Carniola',
	STATE_GORISHKA: 'Goriziano',
	STATE_JUGOVZHODNA_SLOVENIJA: 'Slovenia Sudorientale',
	STATE_KOROSHKA: 'Carinzia',
	STATE_NOTRANJSKO_KRASHKA: 'Carniola Interna-Carso',
	STATE_OBALNO_KRASHKA: 'Litorale-Carso',
	STATE_OBCINA_DOMZALE: 'Domzale',
	STATE_OBCINA_VITANJE: 'Vitanje',
	STATE_OSREDNJESLOVENSKA: 'Slovenia Centrale',
	STATE_PODRAVSKA: 'Oltredrava',
	STATE_POMURSKA: 'Murania',
	STATE_SAVINJSKA: 'Savinia',
	STATE_SLOVENIAN_LITTORAL: 'Slovenia, Litorale',
	STATE_SPODNJEPOSAVSKA: 'Oltresava Inferiore',
	STATE_ZASAVSKA: 'Sava Centrale',
	STATE_GUADALCANAL: 'Guadalcanal',
	STATE_ISABEL: 'Isabel',
	STATE_MAKIRA_AND_ULAWA: 'Makira-Ulawa',
	STATE_MALAITA: 'Malaita',
	STATE_RENNELL_AND_BELLONA: 'Rennell e Bellona',
	STATE_TEMOTU: 'Temotu',
	STATE_AWDAL: 'Awdal',
	STATE_BAKOL: 'Bakol',
	STATE_BANADIR: 'Banadir',
	STATE_BAY: 'Bay',
	STATE_GALGUDUG: 'Galgudug',
	STATE_GEDO: 'Gedo',
	STATE_HIRAN: 'Hiran',
	STATE_JUBBADA_HOSE: 'Jubbada Hose',
	STATE_JUBBADHA_DEXE: 'Jubbadha Dexe',
	STATE_MUDUG: 'Mudug',
	STATE_NUGAL: 'Nugal',
	STATE_SANAG: 'Sanag',
	STATE_SHABELLAHA_DHEXE: 'Shabellaha Dhexe',
	STATE_SHABELLAHA_HOSE: 'Shabellaha Hose',
	STATE_TOGDHER: 'Togdher',
	STATE_WOQOYI_GALBED: 'Woqoyi Galbed',
	STATE_EASTERN_CAPE: 'Capo Orientale',
	STATE_FREE_STATE: 'Stato Libero',
	STATE_GAUTENG: 'Gauteng',
	STATE_KEMPTON_PARK: 'Kempton Park',
	STATE_KRAMERVILLE: 'Kramerville',
	STATE_KWAZULU_NATAL: 'KwaZulu Natal',
	STATE_LIMPOPO: 'Limpopo',
	STATE_MPUMALANGA: 'Mpumalanga',
	STATE_NORTH_WEST: 'Nord Ovest',
	STATE_NORTHERN_CAPE: 'Capo Settentrionale',
	STATE_PAROW: 'Parow',
	STATE_TABLE_VIEW: 'Table View',
	STATE_UMTENTWENI: 'Umtentweni',
	STATE_WESTERN_CAPE: 'Capo Occidentale',
	STATE_CENTRAL_EQUATORIA: 'Central Equatoria',
	STATE_A_CORUNA: 'A Coruña',
	STATE_ALACANT: 'Alicante',
	STATE_ALAVA: 'Alava',
	STATE_ALBACETE: 'Albacete',
	STATE_ALMERIA: 'Almeria',
	STATE_ANDALUCIA: 'Andalusia',
	STATE_ASTURIAS: 'Asturie',
	STATE_AVILA: 'Avila',
	STATE_BADAJOZ: 'Badajoz',
	STATE_BALEARS: 'Baleari',
	STATE_BARCELONA: 'Barcellona',
	STATE_BERTAMIRANS: 'Bertamirans',
	STATE_BISCAY: 'Biscay',
	STATE_BURGOS: 'Burgos',
	STATE_CACERES: 'Caceres',
	STATE_CADIZ: 'Cadice',
	STATE_CANTABRIA: 'Cantabria',
	STATE_CASTELLO: 'Castello',
	STATE_CATALUNYA: 'Catalogna',
	STATE_CEUTA: 'Ceuta',
	STATE_CIUDAD_REAL: 'Ciudad Real',
	STATE_COMUNIDAD_AUTONOMA_DE_CANARIAS: 'Comunità Autonoma delle Canarie',
	STATE_COMUNIDAD_AUTONOMA_DE_CATALUNA: 'Comunità Autonoma della Catalogna',
	STATE_COMUNIDAD_AUTONOMA_DE_GALICIA: 'Comunità Autonoma della Galizia',
	STATE_COMUNIDAD_AUTONOMA_DE_LAS_ISLA: 'Comunità Autonoma delle Isole',
	STATE_COMUNIDAD_AUTONOMA_DEL_PRINCIP: 'Comunità Autonoma del Princip',
	STATE_COMUNIDAD_VALENCIANA: 'Comunità Valenziana',
	STATE_CUENCA: 'Cuenca',
	STATE_GIPUZKOA: 'Guipuzcoa',
	STATE_GIRONA: 'Girona',
	STATE_GUADALAJARA: 'Guadalajara',
	STATE_GUIPUZCOA: 'Guipuzcoa',
	STATE_HUELVA: 'Huelva',
	STATE_HUESCA: 'Huesca',
	STATE_JAEN: 'Jaen',
	STATE_LAS_PALMAS: 'Las Palmas',
	STATE_LERIDA: 'Lerida',
	STATE_LLEIDA: 'Lleida',
	STATE_LUGO: 'Lugo',
	STATE_MADRID: 'Madrid',
	STATE_MALAGA: 'Malaga',
	STATE_MELILLA: 'Melilla',
	STATE_MURCIA: 'Murcia',
	STATE_NAVARRA: 'Navarra',
	STATE_OURENSE: 'Ourense',
	STATE_PAIS_VASCO: 'Paesi Baschi',
	STATE_PALENCIA: 'Palencia',
	STATE_PONTEVEDRA: 'Pontevedra',
	STATE_SALAMANCA: 'Salamanca',
	STATE_SANTA_CRUZ_DE_TENERIFE: 'Santa Cruz de Tenerife',
	STATE_SEGOVIA: 'Segovia',
	STATE_SEVILLA: 'Sevilla',
	STATE_SORIA: 'Soria',
	STATE_TARRAGONA: 'Tarragona',
	STATE_TENERIFE: 'Tenerife',
	STATE_TERUEL: 'Teruel',
	STATE_VALENCIA: 'Valenza',
	STATE_VALLADOLID: 'Valladolid',
	STATE_VIZCAYA: 'Biscaglia',
	STATE_ZAMORA: 'Zamora',
	STATE_ZARAGOZA: 'Saragozza',
	STATE_AMPARAI: 'Amparai',
	STATE_ANURADHAPURAYA: 'Anuradhapuraya',
	STATE_BADULLA: 'Badulla',
	STATE_BORALESGAMUWA: 'Boralesgamuwa',
	STATE_COLOMBO: 'Colombo',
	STATE_GALLA: 'Galla',
	STATE_GAMPAHA: 'Gampaha',
	STATE_HAMBANTOTA: 'Hambantota',
	STATE_KALATURA: 'Kalatura',
	STATE_KEGALLA: 'Kegalla',
	STATE_KILINOCHCHI: 'Kilinochchi',
	STATE_KURUNEGALA: 'Kurunegala',
	STATE_MADAKALPUWA: 'Madakalpuwa',
	STATE_MAHA_NUWARA: 'Maha Nuwara',
	STATE_MALWANA: 'Malwana',
	STATE_MANNARAMA: 'Mannarama',
	STATE_MATALE: 'Matale',
	STATE_MATARA: 'Matara',
	STATE_MONARAGALA: 'Monaragala',
	STATE_MULLAITIVU: 'Mullaitivu',
	STATE_NORTH_EASTERN_PROVINCE: 'Provincia Nordorientale',
	STATE_NORTH_WESTERN_PROVINCE: 'Provincia Nordoccidentale',
	STATE_NUWARA_ELIYA: 'Nuwara Eliya',
	STATE_POLONNARUWA: 'Polonnaruwa',
	STATE_PUTTALAMA: 'Puttalama',
	STATE_RATNAPURAYA: 'Ratnapuraya',
	STATE_SOUTHERN_PROVINCE: 'Provincia Meridionale',
	STATE_TIRIKUNAMALAYA: 'Tirikunamalaya',
	STATE_TUSCANY: 'Toscana',
	STATE_VAVUNIYAWA: 'Vavuniyawa',
	STATE_YAPANAYA: 'Yapanaya',
	STATE_KADAWATHA: 'kadawatha',
	STATE_A__ALI_AN_NIL: 'A’ali-an-Nil',
	STATE_BAHR_AL_JABAL: 'Bahr-al-Jabal',
	STATE_GHARB_BAHR_AL_GHAZAL: 'Gharb Bahr-al-Ghazal',
	STATE_GHARB_DARFUR: 'Gharb Darfur',
	STATE_GHARB_KURDUFAN: 'Gharb Kurdufan',
	STATE_GHARB_AL_ISTIWA__IYAH: 'Gharb-al-Istiwa’iyah',
	STATE_JANUB_DARFUR: 'Janub Darfur',
	STATE_JANUB_KURDUFAN: 'Janub Kurdufan',
	STATE_JUNQALI: 'Junqali',
	STATE_KASSALA: 'Kassala',
	STATE_NAHR_AN_NIL: 'Nahr-an-Nil',
	STATE_SHAMAL_BAHR_AL_GHAZAL: 'Shamal Bahr-al-Ghazal',
	STATE_SHAMAL_DARFUR: 'Shamal Darfur',
	STATE_SHAMAL_KURDUFAN: 'Shamal Kurdufan',
	STATE_SHARQ_AL_ISTIWA__IYAH: 'Sharq-al-Istiwa’iyah',
	STATE_SINNAR: 'Sinnar',
	STATE_WARAB: 'Warab',
  STATE_WILAYAT_AL_KHARTUM: 'Wilayat al Khartum',
	STATE_AL_BUHAYRAT: 'al-Buhayrat',
	STATE_AL_JAZIRAH: 'al-Jazirah',
	STATE_AL_KHARTUM: 'al-Khartum',
	STATE_AL_QADARIF: 'al-Qadarif',
	STATE_AL_WAHDAH: 'al-Wahdah',
	STATE_AN_NIL_AL_ABYAD: 'an-Nil-al-Abyad',
	STATE_AN_NIL_AL_AZRAQ: 'an-Nil-al-Azraq',
	STATE_ASH_SHAMALIYAH: 'ash-Shamaliyah',
	STATE_BROKOPONDO: 'Brokopondo',
	STATE_COMMEWIJNE: 'Commewijne',
	STATE_CORONIE: 'Coronie',
	STATE_MAROWIJNE: 'Marowijne',
	STATE_NICKERIE: 'Nickerie',
	STATE_PARAMARIBO: 'Paramaribo',
	STATE_SARAMACCA: 'Saramacca',
	STATE_WANICA: 'Wanica',
	STATE_SVALBARD: 'Svalbard',
	STATE_HHOHHO: 'Hhohho',
	STATE_LUBOMBO: 'Lubombo',
	STATE_MANZINI: 'Manzini',
	STATE_SHISELWENI: 'Shiselweni',
	STATE_ALVSBORGS_LAN: 'Alvsborgs Lan',
	STATE_ANGERMANLAND: 'Angermanland',
	STATE_BLEKINGE: 'Blekinge',
	STATE_BOHUSLAN: 'Bohuslan',
	STATE_DALARNA: 'Dalarna',
	STATE_GAVLEBORG: 'Gavleborg',
	STATE_GOTLAND: 'Gotland',
	STATE_HALLAND: 'Halland',
	STATE_JAMTLAND: 'Jamtland',
	STATE_JONKOPING: 'Jonkoping',
	STATE_KALMAR: 'Kalmar',
	STATE_KRISTIANSTADS: 'Kristianstads',
	STATE_KRONOBERG: 'Kronoberg',
	STATE_NORRBOTTEN: 'Norrbotten',
	STATE_OREBRO: 'Orebro',
	STATE_OSTERGOTLAND: 'Ostergotland',
	STATE_SALTSJO_BOO: 'Saltsjo-Boo',
	STATE_SKANE: 'Skane',
	STATE_SMALAND: 'Smaland',
	STATE_SODERMANLAND: 'Sodermanland',
	STATE_STOCKHOLM: 'Stoccolma',
	STATE_UPPSALA: 'Uppsala',
	STATE_VARMLAND: 'Varmland',
	STATE_VASTERBOTTEN: 'Vasterbotten',
	STATE_VASTERGOTLAND: 'Vastergotland',
	STATE_VASTERNORRLAND: 'Vasternorrland',
	STATE_VASTMANLAND: 'Vastmanland',
	STATE_VASTRA_GOTALAND: 'Vastra Gotaland',
	STATE_AARGAU: 'Aargau',
	STATE_APPENZELL_INNER_RHODEN: 'Appenzell Interno',
	STATE_APPENZELL_AUSSER_RHODEN: 'Appenzello Esterno',
	STATE_BASEL_LANDSCHAFT: 'Basilea Campagna',
	STATE_BASEL_STADT: 'Basilea Città',
	STATE_BERN: 'Berna',
	STATE_CANTON_TICINO: 'Canton Ticino',
	STATE_FRIBOURG: 'Friburgo',
	STATE_GENEVE: 'Ginevra',
	STATE_GLARUS: 'Glarona',
	STATE_GRAUBUNDEN: 'Canton dei Grigioni',
	STATE_HEERBRUGG: 'Heerbrugg',
	STATE_KANTON_AARGAU: 'Aargau',
	STATE_LUZERN: 'Lucerna',
	STATE_MORBIO_INFERIORE: 'Morbio Inferiore',
	STATE_MUHEN: 'Muhen',
	STATE_NEUCHATEL: 'Neuchatel',
	STATE_NIDWALDEN: 'Nidvaldo',
	STATE_OBWALDEN: 'Obvaldo',
	STATE_SANKT_GALLEN: 'San Gallo',
	STATE_SCHAFFHAUSEN: 'Sciaffusa',
	STATE_SCHWYZ: 'Svitto',
	STATE_SOLOTHURN: 'Soletta',
	STATE_THURGAU: 'Turgovia',
	STATE_TICINO: 'Ticino',
	STATE_URI: 'Uri',
	STATE_VALAIS: 'Vallese',
	STATE_VAUD: 'Vaud',
	STATE_VAUFFELIN: 'Vauffelin',
	STATE_ZUG: 'Zugo',
	STATE_ZURICH: 'Zurigo',
	STATE_ALEPPO: 'Aleppo',
	STATE_DAR__A: 'Dar’a',
	STATE_DAYR_AZ_ZAWR: 'Dayr-az-Zawr',
	STATE_DIMASHQ: 'Damasco',
	STATE_HALAB: 'Halab',
	STATE_HAMAH: 'Hamah',
	STATE_HIMS: 'Hims',
	STATE_IDLIB: 'Idlib',
	STATE_MADINAT_DIMASHQ: 'Madinat Dimashq',
	STATE_TARTUS: 'Tartus',
	STATE_AL_HASAKAH: 'al-Hasakah',
	STATE_AL_LADHIQIYAH: 'al-Ladhiqiyah',
	STATE_AL_QUNAYTIRAH: 'al-Qunaytirah',
	STATE_AR_RAQQAH: 'ar-Raqqah',
	STATE_AS_SUWAYDA: 'as-Suwayda',
	STATE_CHANGHWA: 'Changhwa',
	STATE_CHIAYI_HSIEN: 'Chiayi Hsien',
	STATE_CHIAYI_SHIH: 'Chiayi Shih',
	STATE_EASTERN_TAIPEI: 'Taipei Orientale',
	STATE_HSINCHU_HSIEN: 'Hsinchu Hsien',
	STATE_HSINCHU_SHIH: 'Hsinchu Shih',
	STATE_HUALIEN: 'Hualien',
	STATE_ILAN: 'Ilan',
	STATE_KAOHSIUNG_HSIEN: 'Kaohsiung Hsien',
	STATE_KAOHSIUNG_SHIH: 'Kaohsiung Shih',
	STATE_KEELUNG_SHIH: 'Keelung Shih',
	STATE_KINMEN: 'Kinmen',
	STATE_MIAOLI: 'Miaoli',
	STATE_NANTOU: 'Nantou',
	STATE_NORTHERN_TAIWAN: 'Taiwan Settentrionale',
	STATE_PENGHU: 'Penghu',
	STATE_PINGTUNG: 'Pingtung',
	STATE_TAICHUNG: 'Taichung',
	STATE_TAICHUNG_HSIEN: 'Taichung Hsien',
	STATE_TAICHUNG_SHIH: 'Taichung Shih',
	STATE_TAINAN_HSIEN: 'Tainan Hsien',
	STATE_TAINAN_SHIH: 'Tainan Shih',
	STATE_TAIPEI_HSIEN: 'Taipei Hsien',
	STATE_TAIPEI_SHIH___TAIPEI_HSIEN: 'Taipei Shih / Taipei Hsien',
	STATE_TAITUNG: 'Taitung',
	STATE_TAOYUAN: 'Taoyuan',
	STATE_YILAN: 'Yilan',
	STATE_YUN_LIN_HSIEN: 'Yun-Lin Hsien',
	STATE_YUNLIN: 'Yunlin',
	STATE_DUSHANBE: 'Dushanbe',
	STATE_GORNO_BADAKHSHAN: 'Gorno-Badakhshan',
	STATE_KAROTEGIN: 'Karotegin',
	STATE_KHATLON: 'Khatlon',
	STATE_SUGHD: 'Sughd',
	STATE_ARUSHA: 'Arusha',
	STATE_DAR_ES_SALAAM: 'Dar es Salaam',
	STATE_DODOMA: 'Dodoma',
	STATE_IRINGA: 'Iringa',
	STATE_KAGERA: 'Kagera',
	STATE_KIGOMA: 'Kigoma',
	STATE_KILIMANJARO: 'Kilimangiaro',
	STATE_LINDI: 'Lindi',
	STATE_MARA: 'Mara',
	STATE_MBEYA: 'Mbeya',
	STATE_MOROGORO: 'Morogoro',
	STATE_MTWARA: 'Mtwara',
	STATE_PWANI: 'Pwani',
	STATE_RUKWA: 'Rukwa',
	STATE_RUVUMA: 'Ruvuma',
	STATE_SHINYANGA: 'Shinyanga',
	STATE_SINGIDA: 'Singida',
	STATE_TABORA: 'Tabora',
	STATE_TANGA: 'Tanga',
	STATE_ZANZIBAR_AND_PEMBA: 'Zanzibar e Pemba',
	STATE_AMNAT_CHAROEN: 'Amnat Charoen',
	STATE_ANG_THONG: 'Ang Thong',
	STATE_BANGKOK: 'Bangkok',
	STATE_BURI_RAM: 'Buri Ram',
	STATE_CHACHOENGSAO: 'Chachoengsao',
	STATE_CHAI_NAT: 'Chai Nat',
	STATE_CHAIYAPHUM: 'Chaiyaphum',
	STATE_CHANGWAT_CHAIYAPHUM: 'Changwat Chaiyaphum',
	STATE_CHANTHABURI: 'Chanthaburi',
	STATE_CHIANG_MAI: 'Chiang Mai',
	STATE_CHIANG_RAI: 'Chiang Rai',
	STATE_CHON_BURI: 'Chon Buri',
	STATE_CHUMPHON: 'Chumphon',
	STATE_KALASIN: 'Kalasin',
	STATE_KAMPHAENG_PHET: 'Kamphaeng Phet',
	STATE_KANCHANABURI: 'Kanchanaburi',
	STATE_KHON_KAEN: 'Khon Kaen',
	STATE_KRABI: 'Krabi',
	STATE_KRUNG_THEP: 'Krung Thep',
	STATE_LAMPANG: 'Lampang',
	STATE_LAMPHUN: 'Lamphun',
	STATE_LOEI: 'Loei',
	STATE_LOP_BURI: 'Lop Buri',
	STATE_MAE_HONG_SON: 'Mae Hong Son',
	STATE_MAHA_SARAKHAM: 'Maha Sarakham',
	STATE_MUKDAHAN: 'Mukdahan',
	STATE_NAKHON_NAYOK: 'Nakhon Nayok',
	STATE_NAKHON_PATHOM: 'Nakhon Pathom',
	STATE_NAKHON_PHANOM: 'Nakhon Phanom',
	STATE_NAKHON_RATCHASIMA: 'Nakhon Ratchasima',
	STATE_NAKHON_SAWAN: 'Nakhon Sawan',
	STATE_NAKHON_SI_THAMMARAT: 'Nakhon Si Thammarat',
	STATE_NAN: 'Nan',
	STATE_NARATHIWAT: 'Narathiwat',
	STATE_NONG_BUA_LAM_PHU: 'Nong Bua Lam Phu',
	STATE_NONG_KHAI: 'Nong Khai',
	STATE_NONTHABURI: 'Nonthaburi',
	STATE_PATHUM_THANI: 'Pathum Thani',
	STATE_PATTANI: 'Pattani',
	STATE_PHANGNGA: 'Phangnga',
	STATE_PHATTHALUNG: 'Phatthalung',
	STATE_PHAYAO: 'Phayao',
	STATE_PHETCHABUN: 'Phetchabun',
	STATE_PHETCHABURI: 'Phetchaburi',
	STATE_PHICHIT: 'Phichit',
	STATE_PHITSANULOK: 'Phitsanulok',
	STATE_PHRA_NAKHON_SI_AYUTTHAYA: 'Phra Nakhon Si Ayutthaya',
	STATE_PHRAE: 'Phrae',
	STATE_PHUKET: 'Phuket',
	STATE_PRACHIN_BURI: 'Prachin Buri',
	STATE_PRACHUAP_KHIRI_KHAN: 'Prachuap Khiri Khan',
	STATE_RANONG: 'Ranong',
	STATE_RATCHABURI: 'Ratchaburi',
	STATE_RAYONG: 'Rayong',
	STATE_ROI_ET: 'Roi Et',
	STATE_SA_KAEO: 'Sa Kaeo',
	STATE_SAKON_NAKHON: 'Sakon Nakhon',
	STATE_SAMUT_PRAKAN: 'Samut Prakan',
	STATE_SAMUT_SAKHON: 'Samut Sakhon',
	STATE_SAMUT_SONGKHRAN: 'Samut Songkhran',
	STATE_SARABURI: 'Saraburi',
	STATE_SATUN: 'Satun',
	STATE_SI_SA_KET: 'Si Sa Ket',
	STATE_SING_BURI: 'Sing Buri',
	STATE_SONGKHLA: 'Songkhla',
	STATE_SUKHOTHAI: 'Sukhothai',
	STATE_SUPHAN_BURI: 'Suphan Buri',
	STATE_SURAT_THANI: 'Surat Thani',
	STATE_SURIN: 'Surin',
	STATE_TAK: 'Tak',
	STATE_TRANG: 'Trang',
	STATE_TRAT: 'Trat',
	STATE_UBON_RATCHATHANI: 'Ubon Ratchathani',
	STATE_UDON_THANI: 'Udon Thani',
	STATE_UTHAI_THANI: 'Uthai Thani',
	STATE_UTTARADIT: 'Uttaradit',
	STATE_YALA: 'Yala',
	STATE_YASOTHON: 'Yasothon',
	STATE_KARA: 'Kara',
	STATE_MARITIME: 'Marittimo',
	STATE_ATAFU: 'Atafu',
	STATE_FAKAOFO: 'Fakaofo',
	STATE_NUKUNONU: 'Nukunonu',
	STATE_EUA: 'Eua',
	STATE_HA__APAI: 'Ha’apai',
	STATE_NIUAS: 'Niuas',
	STATE_TONGATAPU: 'Tongatapu',
	STATE_VAVA__U: 'Vava’u',
	STATE_ARIMA_TUNAPUNA_PIARCO: 'Arima-Tunapuna-Piarco',
	STATE_CARONI: 'Caroni',
	STATE_CHAGUANAS: 'Chaguanas',
	STATE_COUVA_TABAQUITE_TALPARO: 'Couva-Tabaquite-Talparo',
	STATE_DIEGO_MARTIN: 'Diego Martin',
	STATE_GLENCOE: 'Glencoe',
	STATE_PENAL_DEBE: 'Penal Debe',
	STATE_POINT_FORTIN: 'Point Fortin',
	STATE_PORT_OF_SPAIN: 'Port of Spain',
	STATE_PRINCES_TOWN: 'Princes Town',
	STATE_SAN_FERNANDO: 'San Fernando',
	STATE_SANGRE_GRANDE: 'Sangre Grande',
	STATE_SIPARIA: 'Siparia',
	STATE_TOBAGO: 'Tobago',
	STATE_ARYANAH: 'Aryanah',
	STATE_BAJAH: 'Bajah',
	STATE_BIN___ARUS: 'Bin ’Arus',
	STATE_BINZART: 'Binzart',
	STATE_GOUVERNORAT_DE_ARIANA: 'Ariana, Governatorato',
	STATE_GOUVERNORAT_DE_NABEUL: 'Nabeul, Governatorato',
	STATE_GOUVERNORAT_DE_SOUSSE: 'Susa, Governatorato',
	STATE_HAMMAMET_YASMINE: 'Hammamet Yasmine',
	STATE_JUNDUBAH: 'Jundubah',
	STATE_MADANIYIN: 'Madaniyin',
	STATE_MANUBAH: 'Manubah',
	STATE_MONASTIR: 'Monastir',
	STATE_NABUL: 'Nabul',
	STATE_QABIS: 'Qabis',
	STATE_QAFSAH: 'Qafsah',
	STATE_QIBILI: 'Qibili',
	STATE_SAFAQIS: 'Safaqis',
	STATE_SFAX: 'Sfax',
	STATE_SIDI_BU_ZAYD: 'Sidi Bu Zayd',
	STATE_SILYANAH: 'Silyanah',
	STATE_SUSAH: 'Susah',
	STATE_TATAWIN: 'Tatawin',
	STATE_TAWZAR: 'Tawzar',
	STATE_TUNIS: 'Tunisi',
	STATE_ZAGHWAN: 'Zaghwan',
	STATE_AL_KAF: 'al-Kaf',
	STATE_AL_MAHDIYAH: 'al-Mahdiyah',
	STATE_AL_MUNASTIR: 'al-Munastir',
	STATE_AL_QASRAYN: 'al-Qasrayn',
	STATE_AL_QAYRAWAN: 'al-Qayrawan',
	STATE_ADANA: 'Adana',
	STATE_ADIYAMAN: 'Adiyaman',
	STATE_AFYON: 'Afyon',
	STATE_AGRI: 'Agri',
	STATE_AKSARAY: 'Aksaray',
	STATE_AMASYA: 'Amasya',
	STATE_ANKARA: 'Ankara',
	STATE_ANTALYA: 'Antalya',
	STATE_ARDAHAN: 'Ardahan',
	STATE_ARTVIN: 'Artvin',
	STATE_AYDIN: 'Aydin',
	STATE_BALIKESIR: 'Balikesir',
	STATE_BARTIN: 'Bartin',
	STATE_BATMAN: 'Batman',
	STATE_BAYBURT: 'Bayburt',
	STATE_BILECIK: 'Bilecik',
	STATE_BINGOL: 'Bingol',
	STATE_BITLIS: 'Bitlis',
	STATE_BOLU: 'Bolu',
	STATE_BURDUR: 'Burdur',
	STATE_BURSA: 'Bursa',
	STATE_CANAKKALE: 'Canakkale',
	STATE_CANKIRI: 'Cankiri',
	STATE_CORUM: 'Corum',
	STATE_DENIZLI: 'Denizli',
	STATE_DIYARBAKIR: 'Diyarbakir',
	STATE_DUZCE: 'Duzce',
	STATE_EDIRNE: 'Edirne',
	STATE_ELAZIG: 'Elazig',
	STATE_ERZINCAN: 'Erzincan',
	STATE_ERZURUM: 'Erzurum',
	STATE_ESKISEHIR: 'Eskisehir',
	STATE_GAZIANTEP: 'Gaziantep',
	STATE_GIRESUN: 'Giresun',
	STATE_GUMUSHANE: 'Gumushane',
	STATE_HAKKARI: 'Hakkari',
	STATE_HATAY: 'Hatay',
	STATE_ICEL: 'Icel',
	STATE_IGDIR: 'Igdir',
	STATE_ISPARTA: 'Isparta',
	STATE_ISTANBUL: 'Istanbul',
	STATE_IZMIR: 'Izmir',
	STATE_KAHRAMANMARAS: 'Kahramanmaras',
	STATE_KARABUK: 'Karabuk',
	STATE_KARAMAN: 'Karaman',
	STATE_KARS: 'Kars',
	STATE_KARSIYAKA: 'Karsiyaka',
	STATE_KASTAMONU: 'Kastamonu',
	STATE_KAYSERI: 'Kayseri',
	STATE_KILIS: 'Kilis',
	STATE_KIRIKKALE: 'Kirikkale',
	STATE_KIRKLARELI: 'Kirklareli',
	STATE_KIRSEHIR: 'Kirsehir',
	STATE_KOCAELI: 'Kocaeli',
	STATE_KONYA: 'Konya',
	STATE_KUTAHYA: 'Kutahya',
	STATE_LEFKOSA: 'Lefkosa',
	STATE_MALATYA: 'Malatya',
	STATE_MANISA: 'Manisa',
	STATE_MARDIN: 'Mardin',
	STATE_MUGLA: 'Mugla',
	STATE_MUS: 'Mus',
	STATE_NEVSEHIR: 'Nevsehir',
	STATE_NIGDE: 'Nigde',
	STATE_ORDU: 'Ordu',
	STATE_OSMANIYE: 'Osmaniye',
	STATE_RIZE: 'Rize',
	STATE_SAKARYA: 'Sakarya',
	STATE_SAMSUN: 'Samsun',
	STATE_SANLIURFA: 'Sanliurfa',
	STATE_SIIRT: 'Siirt',
	STATE_SINOP: 'Sinop',
	STATE_SIRNAK: 'Sirnak',
	STATE_SIVAS: 'Sivas',
	STATE_TEKIRDAG: 'Tekirdag',
	STATE_TOKAT: 'Tokat',
	STATE_TRABZON: 'Trebisonda',
	STATE_TUNCELI: 'Tunceli',
	STATE_USAK: 'Usak',
	STATE_VAN: 'Van',
	STATE_YALOVA: 'Yalova',
	STATE_YOZGAT: 'Yozgat',
	STATE_ZONGULDAK: 'Zonguldak',
	STATE_AHAL: 'Ahal',
	STATE_ASGABAT: 'Asgabat',
	STATE_BALKAN: 'Balkan',
	STATE_DASOGUZ: 'Dasoguz',
	STATE_LEBAP: 'Lebap',
	STATE_MARI: 'Mari',
	STATE_GRAND_TURK: 'Grand Turk',
	STATE_SOUTH_CAICOS_AND_EAST_CAICOS: 'South Caicos e East Caicos',
	STATE_FUNAFUTI: 'Funafuti',
	STATE_NANUMANGA: 'Nanumanga',
	STATE_NANUMEA: 'Nanumea',
	STATE_NIUTAO: 'Niutao',
	STATE_NUI: 'Nui',
	STATE_NUKUFETAU: 'Nukufetau',
	STATE_NUKULAELAE: 'Nukulaelae',
	STATE_VAITUPU: 'Vaitupu',
	STATE_CHERKAS__KA: 'Cherkas’ka',
	STATE_CHERNIHIVS__KA: 'Chernihivs’ka',
	STATE_CHERNIVETS__KA: 'Chernivets’ka',
	STATE_CRIMEA: 'Crimea',
	STATE_DNIPROPETROVSKA: 'Dnipropetrovska',
	STATE_DONETS__KA: 'Donets’ka',
	STATE_IVANO_FRANKIVS__KA: 'Ivano-Frankivs’ka',
	STATE_KHARKIV: 'Kharkiv',
	STATE_KHARKOV: 'Kharkov',
	STATE_KHERSONSKA: 'Khersonska',
	STATE_KHMEL__NYTS__KA: 'Khmel’nyts’ka',
	STATE_KIROVOHRAD: 'Kirovohrad',
	STATE_KRYM: 'Krym',
	STATE_KYYIV: 'Kiev',
	STATE_KYYIVS__KA: 'Kyyivs’ka',
	STATE_L__VIVS__KA: 'L’vivs’ka',
	STATE_LUHANS__KA: 'Luhans’ka',
	STATE_MYKOLAYIVS__KA: 'Mykolayivs’ka',
	STATE_ODES__KA: 'Odes’ka',
	STATE_ODESSA: 'Odessa',
	STATE_POLTAVS__KA: 'Poltavs’ka',
	STATE_RIVNENS__KA: 'Rivnens’ka',
	STATE_SEVASTOPOL: 'Sevastopoli',
	STATE_SUMS__KA: 'Sums’ka',
	STATE_TERNOPIL__S__KA: 'Ternopil’s’ka',
	STATE_VOLYNS__KA: 'Volyns’ka',
	STATE_VYNNYTS__KA: 'Vynnyts’ka',
	STATE_ZAKARPATS__KA: 'Zakarpats’ka',
	STATE_ZAPORIZHIA: 'Zaporizhia',
	STATE_ZHYTOMYRS__KA: 'Zhytomyrs’ka',
	STATE_ABU_ZABI: 'Abu Zabi',
	STATE_AJMAN: 'Ajman',
	STATE_DUBAI: 'Dubai',
	STATE_RAS_AL_KHAYMAH: 'Ras al-Khaymah',
	STATE_SHARJAH: 'Sharjah',
	STATE_SHARJHA: 'Sharjha',
	STATE_UMM_AL_QAYWAYN: 'Umm al Qaywayn',
	STATE_AL_FUJAYRAH: 'al-Fujayrah',
	STATE_ASH_SHARIQAH: 'ash-Shariqah',
	STATE_ABERDEEN: 'Aberdeen',
	STATE_ABERDEENSHIRE: 'Aberdeenshire',
	STATE_ARGYLL: 'Argyll',
	STATE_BEDFORDSHIRE: 'Bedfordshire',
	STATE_BELFAST: 'Belfast',
	STATE_BERKSHIRE: 'Berkshire',
	STATE_BIRMINGHAM: 'Birmingham',
	STATE_BRECHIN: 'Brechin',
	STATE_BRIDGNORTH: 'Bridgnorth',
	STATE_BRISTOL: 'Bristol',
	STATE_BUCKINGHAMSHIRE: 'Buckinghamshire',
	STATE_CAMBRIDGE: 'Cambridge',
	STATE_CAMBRIDGESHIRE: 'Cambridgeshire',
	STATE_CHANNEL_ISLANDS: 'Isole del Canale',
	STATE_CHESHIRE: 'Cheshire',
	STATE_CLEVELAND: 'Cleveland',
	STATE_CO_FERMANAGH: 'Co Fermanagh',
	STATE_CONWY: 'Conwy',
	STATE_CORNWALL: 'Cornavaglia',
	STATE_COVENTRY: 'Coventry',
	STATE_CRAVEN_ARMS: 'Craven Arms',
	STATE_CUMBRIA: 'Cumbria',
	STATE_DENBIGHSHIRE: 'Denbighshire',
	STATE_DERBY: 'Derby',
	STATE_DERBYSHIRE: 'Derbyshire',
	STATE_DEVON: 'Devon',
	STATE_DIAL_CODE_DUNGANNON: 'Dungannon, Prefisso',
	STATE_DIDCOT: 'Didcot',
	STATE_DORSET: 'Dorset',
	STATE_DUNBARTONSHIRE: 'Dunbartonshire',
	STATE_DURHAM: 'Durham',
	STATE_EAST_DUNBARTONSHIRE: 'Dunbartonshire Orientale',
	STATE_EAST_LOTHIAN: 'Lothian Orientale',
	STATE_EAST_MIDLANDS: 'Midlands Orientali',
	STATE_EAST_SUSSEX: 'Sussex Orientale',
	STATE_EAST_YORKSHIRE: 'Yorkshire Orientale',
	STATE_ENGLAND: 'Inghilterra',
	STATE_ESSEX: 'Essex',
	STATE_FERMANAGH: 'Fermanagh',
	STATE_FIFE: 'Fife',
	STATE_FLINTSHIRE: 'Flintshire',
	STATE_FULHAM: 'Fulham',
	STATE_GAINSBOROUGH: 'Gainsborough',
	STATE_GLOCESTERSHIRE: 'Glocestershire',
	STATE_GWENT: 'Gwent',
	STATE_HAMPSHIRE: 'Hampshire',
	STATE_HANTS: 'Hants',
	STATE_HEREFORDSHIRE: 'Herefordshire',
	STATE_HERTFORDSHIRE: 'Hertfordshire',
	STATE_IRELAND: 'Irlanda',
	STATE_ISLE_OF_MAN: 'Isola di Man',
	STATE_ISLE_OF_WIGHT: 'Isola di Wight',
	STATE_KENFORD: 'Kenford',
	STATE_KENT: 'Kent',
	STATE_KILMARNOCK: 'Kilmarnock',
	STATE_LANARKSHIRE: 'Lanarkshire',
	STATE_LANCASHIRE: 'Lancashire',
	STATE_LEICESTERSHIRE: 'Leicestershire',
	STATE_LINCOLNSHIRE: 'Lincolnshire',
	STATE_LLANYMYNECH: 'Llanymynech',
	STATE_LONDON: 'Londra',
	STATE_LUDLOW: 'Ludlow',
	STATE_MAYFAIR: 'Mayfair',
	STATE_MERSEYSIDE: 'Merseyside',
	STATE_MID_GLAMORGAN: 'Mid Glamorgan',
	TATE_MIDDLESEX: 'Middlesex',
	STATE_MILDENHALL: 'Mildenhall',
	STATE_MONMOUTHSHIRE: 'Monmouthshire',
	STATE_NEWTON_STEWART: 'Newton Stewart',
	STATE_NORFOLK: 'Norfolk',
	STATE_NORTH_HUMBERSIDE: 'Humberside Settentrionale',
	STATE_NORTH_YORKSHIRE: 'Yorkshire Settentrionale',
	STATE_NORTHAMPTONSHIRE: 'Northamptonshire',
	STATE_NORTHANTS: 'Northants',
	STATE_NORTHERN_IRELAND: 'Irlanda del Nord',
	STATE_NORTHUMBERLAND: 'Northumberland',
	STATE_NOTTINGHAMSHIRE: 'Nottinghamshire',
	STATE_OXFORD: 'Oxford',
	STATE_POWYS: 'Powys',
	STATE_ROOS_SHIRE: 'Roos-shire',
	STATE_SUSSEX: 'SUSSEX',
	STATE_SCOTLAND: 'Scozia',
	STATE_SCOTTISH_BORDERS: 'Marche Scozzesi',
	STATE_SHROPSHIRE: 'Shropshire',
	STATE_SOMERSET: 'Somerset',
	STATE_SOUTH_GLAMORGAN: 'Glamorgan Meridionale',
	STATE_SOUTH_WALES: 'Galles Meridionale',
	STATE_SOUTH_YORKSHIRE: 'Yorkshire Meridionale',
	STATE_SOUTHWELL: 'Southwell',
	STATE_STAFFORDSHIRE: 'Staffordshire',
	STATE_STRABANE: 'Strabane',
	STATE_SUFFOLK: 'Suffolk',
	STATE_SURREY: 'Surrey',
	STATE_TWICKENHAM: 'Twickenham',
	STATE_TYNE_AND_WEAR: 'Tyne and Wear',
	STATE_TYRONE: 'Tyrone',
	STATE_UTAH: 'Utah',
	STATE_WALES: 'Galles',
	STATE_WARWICKSHIRE: 'Warwickshire',
	STATE_WEST_LOTHIAN: 'Lothian Occidentale',
	STATE_WEST_MIDLANDS: 'Midlands Occidentali',
	STATE_WEST_SUSSEX: 'Sussex Occidentale',
	STATE_WEST_YORKSHIRE: 'Yorkshire Occidentale',
	STATE_WHISSENDINE: 'Whissendine',
	STATE_WILTSHIRE: 'Wiltshire',
	STATE_WOKINGHAM: 'Wokingham',
	STATE_WORCESTERSHIRE: 'Worcestershire',
	STATE_WREXHAM: 'Wrexham',
	STATE_WURTTEMBERG: 'Wurttemberg',
	STATE_YORKSHIRE: 'Yorkshire',
	STATE_CALIFORNIA: 'California',
	STATE_COLORADO: 'Colorado',
	STATE_CONNECTICUT: 'Connecticut',
	STATE_DELAWARE: 'Delaware',
	STATE_DISTRICT_OF_COLUMBIA: 'Distretto di Columbia',
	STATE_HAWAII: 'Hawaii',
	STATE_IDAHO: 'Idaho',
	STATE_ILLINOIS: 'Illinois',
	STATE_INDIANA: 'Indiana',
	STATE_IOWA: 'Iowa',
	STATE_KANSAS: 'Kansas',
	STATE_KENTUCKY: 'Kentucky',
	STATE_LOUISIANA: 'Louisiana',
	STATE_MAINE: 'Maine',
	STATE_MARYLAND: 'Maryland',
	STATE_MASSACHUSETTS: 'Massachusetts',
	STATE_MICHIGAN: 'Michigan',
	STATE_MINNESOTA: 'Minnesota',
	STATE_MISSISSIPPI: 'Mississippi',
	STATE_MISSOURI: 'Missouri',
	STATE_NEBRASKA: 'Nebraska',
	STATE_NEVADA: 'Nevada',
	STATE_NEW_HAMPSHIRE: 'New Hampshire',
	STATE_NEW_JERSEY: 'New Jersey',
	STATE_NEW_MEXICO: 'Nuovo Messico',
	STATE_NORTH_CAROLINA: 'Carolina del Nord',
	STATE_NORTH_DAKOTA: 'Dakota del Nord',
	STATE_OHIO: 'Ohio',
	STATE_OKLAHOMA: 'Oklahoma',
	STATE_OREGON: 'Oregon',
	STATE_PENNSYLVANIA: 'Pennsylvania',
	STATE_RHODE_ISLAND: 'Rhode Island',
	STATE_SOUTH_CAROLINA: 'Carolina del Sud',
	STATE_SOUTH_DAKOTA: 'Dakota del Sud',
	STATE_TENNESSEE: 'Tennessee',
	STATE_TEXAS: 'Texas',
	STATE_VIRGINIA: 'Virginia',
	STATE_WEST_VIRGINIA: 'Virginia Occidentale',
	STATE_WISCONSIN: 'Wisconsin',
	STATE_WYOMING: 'Wyoming',
	STATE_UNITED_STATES_MINOR_OUTLYING_I: 'Isole Minori Esterne degli Stati Uniti d’America',
	STATE_ARTIGAS: 'Artigas',
	STATE_CANELONES: 'Canelones',
	STATE_CERRO_LARGO: 'Cerro Largo',
	STATE_COLONIA: 'Colonia',
	STATE_DURAZNO: 'Durazno',
	STATE_FLORES: 'Flores',
	STATE_LAVALLEJA: 'Lavalleja',
	STATE_MALDONADO: 'Maldonado',
	STATE_MONTEVIDEO: 'Montevideo',
	STATE_PAYSANDU: 'Paysandu',
	STATE_RIVERA: 'Rivera',
	STATE_ROCHA: 'Rocha',
	STATE_SALTO: 'Salto',
	STATE_SORIANO: 'Soriano',
	STATE_TACUAREMBO: 'Tacuarembo',
	STATE_TREINTA_Y_TRES: 'Treinta y Tres',
	STATE_ANDIJON: 'Andijon',
	STATE_BUHORO: 'Buhoro',
	STATE_CIZAH: 'Cizah',
	STATE_FARGONA: 'Fargona',
	STATE_HORAZM: 'Horazm',
	STATE_KASKADAR: 'Kaskadar',
	STATE_KORAKALPOGISTON: 'Korakalpogiston',
	STATE_NAMANGAN: 'Namangan',
	STATE_NAVOI: 'Navoi',
	STATE_SAMARKAND: 'Samarcanda',
	STATE_SIRDARE: 'Sirdare',
	STATE_SURHONDAR: 'Surhondar',
	STATE_TOSKENT: 'Toskent',
	STATE_MALAMPA: 'Malampa',
	STATE_PENAMA: 'Penama',
	STATE_SANMA: 'Sanma',
	STATE_SHEFA: 'Shefa',
	STATE_TAFEA: 'Tafea',
	STATE_TORBA: 'Torba',
	STATE_VATICAN_CITY_STATE_HOLY_SEE_: 'Stato della Città del Vaticano (Santa Sede)',
	STATE_ANZOATEGUI: 'Anzoategui',
	STATE_APURE: 'Apure',
	STATE_ARAGUA: 'Aragua',
	STATE_BARINAS: 'Barinas',
	STATE_CARABOBO: 'Carabobo',
	STATE_COJEDES: 'Cojedes',
	STATE_DELTA_AMACURO: 'Delta Amacuro',
	STATE_FALCON: 'Falcon',
	STATE_GUARICO: 'Guarico',
	STATE_LARA: 'Lara',
	STATE_MERIDA: 'Merida',
	STATE_MIRANDA: 'Miranda',
	STATE_MONAGAS: 'Monagas',
	STATE_NUEVA_ESPARTA: 'Nueva Esparta',
	STATE_PORTUGUESA: 'Portuguesa',
	STATE_TACHIRA: 'Tachira',
	STATE_TRUJILLO: 'Trujillo',
	STATE_VARGAS: 'Vargas',
	STATE_YARACUY: 'Yaracuy',
	STATE_ZULIA: 'Zulia',
	STATE_BAC_GIANG: 'Bac Giang',
	STATE_BINH_DINH: 'Binh Dinh',
	STATE_BINH_DUONG: 'Binh Duong',
	STATE_DA_NANG: 'Da Nang',
	STATE_DONG_BANG_SONG_CUU_LONG: 'Dong Bang Song Cuu Long',
	STATE_DONG_BANG_SONG_HONG: 'Dong Bang Song Hong',
	STATE_DONG_NAI: 'Dong Nai',
	STATE_DONG_NAM_BO: 'Dong Nam Bo',
	STATE_DUYEN_HAI_MIEN_TRUNG: 'Duyen Hai Mien Trung',
	STATE_HANOI: 'Hanoi',
	STATE_HUNG_YEN: 'Hung Yen',
	STATE_KHU_BON_CU: 'Khu Bon Cu',
	STATE_LONG_AN: 'Long An',
	STATE_MIEN_NUI_VA_TRUNG_DU: 'Mien Nui Va Trung Du',
	STATE_THAI_NGUYEN: 'Thai Nguyen',
	STATE_THANH_PHO_HO_CHI_MINH: 'Thanh Pho Ho Chi Minh',
	STATE_THU_DO_HA_NOI: 'Thu Do Ha Noi',
	STATE_TINH_CAN_THO: 'Tinh Can Tho',
	STATE_TINH_DA_NANG: 'Tinh Da Nang',
	STATE_TINH_GIA_LAI: 'Tinh Gia Lai',
	STATE_ANEGADA: 'Anegada',
	STATE_JOST_VAN_DYKE: 'Jost van Dyke',
	STATE_TORTOLA: 'Tortola',
	STATE_SAINT_CROIX: 'Saint Croix',
	STATE_ALO: 'Alo',
	STATE_SINGAVE: 'Singave',
	STATE_WALLIS: 'Wallis',
	STATE_BU_JAYDUR: 'Bu Jaydur',
	STATE_WAD_ADH_DHAHAB: 'Wad-adh-Dhahab',
	STATE_AL___AYUN: 'al-’Ayun',
	STATE_AS_SAMARAH: 'as-Samarah',
	STATE_ADAN: 'Adan',
	STATE_ABYAN: 'Abyan',
	STATE_DHAMAR: 'Dhamar',
	STATE_HADRAMAUT: 'Hadramaut',
	STATE_HAJJAH: 'Hajjah',
	STATE_HUDAYDAH: 'Hudaydah',
	STATE_IBB: 'Ibb',
	STATE_LAHIJ: 'Lahij',
	STATE_MA__RIB: 'Ma’rib',
	STATE_MADINAT_SAN__A: 'Madinat San’a',
	STATE_SA__DAH: 'Sa’dah',
	STATE_SANA: 'Sana',
	STATE_SHABWAH: 'Shabwah',
	STATE_TA__IZZ: 'Ta’izz',
	STATE_AL_BAYDA: 'al-Bayda',
	STATE_AL_HUDAYDAH: 'al-Hudaydah',
	STATE_AL_JAWF: 'al-Jawf',
	STATE_AL_MAHRAH: 'al-Mahrah',
	STATE_AL_MAHWIT: 'al-Mahwit',
	STATE_COPPERBELT: 'Copperbelt',
	STATE_LUAPALA: 'Luapala',
	STATE_LUSAKA: 'Lusaka',
	STATE_NORTH_WESTERN: 'Nordoccidentale',
	STATE_BULAWAYO: 'Bulawayo',
	STATE_HARARE: 'Harare',
	STATE_MANICALAND: 'Manicaland',
	STATE_MASHONALAND_CENTRAL: 'Mashonaland Centrale',
	STATE_MASHONALAND_EAST: 'Mashonaland Orientale',
	STATE_MASHONALAND_WEST: 'Mashonaland Occidentale',
	STATE_MASVINGO: 'Masvingo',
	STATE_MATABELELAND_NORTH: 'Matabeleland Settentrionale',
	STATE_MATABELELAND_SOUTH: 'Matabeleland Meridionale',
	STATE_MIDLANDS: 'Midlands',
	STATE_AARHUS: 'Aarhus'
};
