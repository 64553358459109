import onboardingLocalization from '../components/Onboarding/localization/nl-NL';
import ticketingLocalization from '../components/Ticketing/localization/nl-NL';
import supportLocalization from '../components/Support/localization/nl-NL';
import automationLocalization from '../components/Automation/localization/nl-NL';
import sharedLocalization from '../components/Shared/localization/nl-NL';
import analyticsLocalization from '../components/AnalyticsInvoices/localization/nl-NL';
import failoverLocalization from '../components/Failover/localization/nl-NL';
import reportsLocalization from '../components/Reports/localization/nl-NL';
import eulaLocalization from '../components/Eula/localization/nl-NL';
import accountOverviewLocalization from '../components/AccountOverview/localization/nl-NL';
import analyticsChartsLocalization from '../components/Analytics/localization/nl-NL';
import batchHistoryLocalization from '../components/BatchHistory/localization/nl-NL';
import errorLocalization from '../components/ErrorPage/localization/nl-NL';
import userFeedbackLocalization from '../components/UserFeedback/localization/nl-NL';
import swaggerLocalization from '../components/Swagger/localization/nl-NL';
import securityFeatures from '../components/SecurityFeatures/localization/nl-NL';
import releaseNotesLocalization from '../components/ReleaseNotes/localization/nl-NL';
import endpointsLocalization from '../components/Endpoint/localization/nl-NL';
import singleEndpointLocalization from '../components/SingleEndpoint/localization/nl-NL';

export default {
	...automationLocalization,
	...sharedLocalization,
	...analyticsLocalization,
	...supportLocalization,
	...onboardingLocalization,
	...ticketingLocalization,
	...failoverLocalization,
	...reportsLocalization,
	...accountOverviewLocalization,
	...eulaLocalization,
	...analyticsChartsLocalization,
	...batchHistoryLocalization,
	...errorLocalization,
	...userFeedbackLocalization,
	...swaggerLocalization,
	...securityFeatures,
	...endpointsLocalization,
	...releaseNotesLocalization,
	...singleEndpointLocalization,
	// GLOBAL SETTINGS
	DISPLAY_DATE_FORMAT: 'MM/dd/jjjj',

	// SIDEBAR
	HOME: 'Home',
	ENDPOINTS: 'Eindpunten',
	Endpoints: 'Eindpunten',
	REPORTS: 'Rapporten',
	INVOICES: 'Facturen',
	AUTOMATION: 'Automatisering',
	PLATFORM_STATUS: 'Platformstatus',
	SUPPORT: 'Ondersteuning',
	COLLAPSE_SIDEBAR: 'Zijbalk samenvouwen',
	COMPANIES: 'Bedrijven',
	FAQ: 'Veelgestelde vragen',
	HELP_SUPPORT: 'Help en ondersteuning',
	BATCH_UPDATES: 'Batch-updates',
	FAQS: 'Veelgestelde vragen',
	RELEASE_NOTES: 'Releaseopmerkingen',
	SYSTEM_NOTIFICATIONS: 'Systeemmeldingen',
	ACCOUNT_SETTINGS: 'Account-instellingen',
	COMPANY_ACCOUNTS: 'Bedrijfsaccount',
	CUSTOM_FIELDS: 'Aangepaste velden',
	COMPANY_DETAILS: 'Bedrijfsgegevens',
	TESTING_ICON: 'Testpictogram',
	ENTITIES: 'Entiteiten',
	IOTGW_FAILOVER: 'Failover IoTGW',
	ACCESS_MANAGEMENT: 'Toegangsbeheer',
	SIM_ORDERS: 'SIM-bestellingen',
	USER_MANAGEMENT: 'Gebruikersbeheer',

	// TOPBAR
	MORE_IOT: 'MEER IoT',
	MORE_ATT: 'MEER AT&T',
	LEARN_ABOUT_OFFERS: 'Meer over aanbiedingen',
	BUY_DEVICES: 'Apparaten kopen',
	ATT_SERVICES: 'AT&T Services',
	USE_TOOLS_APPS: 'Gebruik Tools & Apps',
	IOT_PLATFORMS_PRODUCTS_APPS_AND_APIS: 'Platformen, producten, apps en API’s van IoT',
	AMOC: 'AMOC',
	M2X: 'M2X',
	FLOW_DESIGNER: 'Flow designer',
	IOT_STARTER_KITS: 'IoT-startpakketten',
	IOT_DATA_PLANS: 'Data-abonnementen IoT',
	ENTERPRISE: 'Onderneming',
	BUSINESS: 'Bedrijf',
	CONSUMER: 'Consument',

	// PROFILE MENU
	PROFILE: 'Profiel',
	APPS: 'Apps',
	LOGOUT: 'Afmelden',
	LOG_IN: 'Aanmelden',

	// FOOTER
	IOT_CUSTOMER_SOLUTIONS: 'IoT-klantoplossingen',
	IOT_PLATFORM_AND_SERVICES: 'IoT-platform en services',
	NETWORK_SOLUTIONS: 'Netwerkoplossingen',
	PARTNERSHIP_PROGRAM: 'Samenwerkingsprogramma’s',
	BUSINESS_SUPPORT: 'Bedrijfsondersteuning',
	ABOUT_ATT: 'Over AT&T',
	ABOUT_ATT_IOT: 'Over AT&T IoT',
	NEWS: 'Nieuws',
	NETWORKING_EXCHANGE_BLOG: 'Informatieblog',
	BUSINESS_COMMUNITY: 'Bedrijfscommunity',
	YOUTUBE_CHANNEL: 'YouTube-kanaal',
	LEGAL_PRIVACY_ADVERTISING_ACCESSIBILITY: 'Juridisch, privacy, advertenties, toegankelijkheid',
	LEGAL_POLICY_CENTER: 'Juridisch beleidscentrum',
	PRIVACY_POLICY: 'Privacybeleid',
	TERMS_OF_USE: 'Gebruiksvoorwaarden',
	BROADBAND_DETAILS: 'Breedbandgegevens',
	YOUR_CHOICES: 'Uw keuzes',
	ADVERTISING_CHOICES: 'Advertentiekeuzes',
	ACCESSIBILITY: 'Toegankelijkheid',
	COPYRIGHT_ATT_INTELECTUAL_PROPERTY: '©{year} AT&T Intellectual Property. ',
	COPYRIGHT_MESSAGE: 'Alle rechten voorbehouden. AT&T, het wereldbollogo, Mobilizing Your World en DIRECTV zijn gedeponeerde handelsmerken van AT&T Intellectual Property en/of gelieerde bedrijven. Alle andere merken zijn het eigendom van hun respectievelijke eigenaars.',
	CONNECT_WITH_ATT: 'Neem contact op met AT&T',
	CURRENT_SESSION_USAGE: 'Verbruik huidige sessie',
	ENDPOINT_NETWORK_AND_LOCATION: 'Netwerk en locatie eindpunt',

	// NETWORK ENTITLEMENTS
	ENDPOINT_DETAILS_SETUP: 'Configuratie eindpuntgegevens',
	NETWORK_ENTITLEMENT_DETAILS: 'Gegevens netwerkrecht',
	CHANGE_NETWORK_ENTITLEMENTS: 'Netwerkrechten wijzigen',
	CONFIRM_NETWORK_ENTITLEMENT_CHANGE: 'Wijziging netwerkrecht bevestigen',
	NETWORK_ENTITLEMENT_CHANGE_STATUS: 'Status wijziging netwerkrecht',
	NETWORK_ENTITLEMENT_CHANGE_TOOLTIP_SUPPORT: 'Fout in netwerkrecht, neem contact op met klantenservice.',
	NETWORK_ENTITLEMENT_CHANGE_NOT_ALLOWED: 'Wijzigen van meerdere netwerkrechten voor eindpunten is helaas niet toegestaan als het eindpunten van verschillende netwerktypen of verschillende serviceproviders betreft',
	SCHEDULE_NETWORK_ENTITLEMENT_SUCCESSFUL: 'Netwerkrechten voor meerdere SIM’s zijn gepland',
	SIMS_NETWORK_ENTITLEMENT_SUCCESSFULLY_UPDATED: 'SIM-netwerkrechten zijn geüpdatet',
	SIM_NETWORK_ENTITLEMENT_CHANGE_FOR: 'Wijziging in SIM-netwerkrechten voor ',
	MULTIPLE_NETWORK_ENTITLEMENT_CHANGE_SCHEDULE_ERROR: 'Kan geen wijziging plannen voor meerdere SIM-netwerkrechten',
	MULTIPLE_NETWORK_ENTITLEMENT_CHANGE_ERROR: 'Kan geen wijziging aanvragen voor meerdere SIM-netwerkrechten',
	states: 'Status',
	networkOperators: 'Netwerkoperator',
	countries: 'Land netwerk',
	SELECT_NETWORK_ENTITLEMENT: 'Netwerkrecht selecteren',
	ACCOUNT_NAME: 'Naam account',
	APN: 'APN',
	DESCRIPTION: 'Beschrijving',
	ROAMING: 'Roaming',
	CREATE_DATE: 'Gemaakt op',
	LAST_UPDATE_DATE: 'Datum laatste update',
	APN_LTE: 'APN LTE',
	SMS_MT: 'SMS MT',
	SMS_MO: 'SMS MO',
	VOICE_MO: 'Voice MO',
	VOICE_MT: 'Voice MT',
	INTERNATIONAL_VOICE: 'Voice internationaal',
	FIXED_IP_ENABLED: 'Vast IP-adres ingeschakeld',
	LTE_DATA: 'LTE-data',
	LTE_ROAMING_RESTRICT_SET_ID: 'Roaming LTE',
	LTE_APNS: 'LTE APN’s',
	CAMEL_SUBSCRIPTION_INFO: 'Camel',
	CAMEL_SERVICE_TYPE: 'Type Camel-service',
	M2M_PLATFORM: 'M2M-platform',
	APNS_ID: 'APN-id',
	APNS_NAME: 'Naam',
	APNS_LTE: 'LTE',
	APNS_PDPINDEX: 'PDP-index',
	APNS_DESCRIPTION: 'Beschrijving APN',
	APNS_FIXEDIP: 'Vast IP-adres',
	APNS_SECURITY: 'Beveiliging',
	APNS_SUBSCRIPTION: 'Abonnement',
	APNS_CREATEDATE: 'Gemaakt op',
	APNS_LASTUPDATEDATE: 'Datum laatste update',
	APNS_LASTUSER: 'Laatste gebruiker',
	APNS_APNTYPE: 'Type APN',
	APNS_CREATIONTIMESTAMP: 'Tijdstempel maken',
	APNS_BMSYNCSTATUS: 'Status BM-synchronisatie',
	APNS_HLRPROFILEREFERENCE: 'Profielreferentie HLRHSSDetails',
	APNS_IPADDRESSALLOCATIONTYPE: 'Toewijzingstype IP-adres',
	APNS_ISLOCALBREAKOUTENABLEDINDICATOR: 'Is lokale indicator voor Opsplitsen ingeschakeld',
	APNS_LASTUPDATETIMESTAMP: 'Laatste update tijdstempel',
	APNS_OUCCONNECTIONTYPE: 'Verbindingstype OUC',
	APNS_SECURITYPROFILESUPPORTEDINDICATOR: 'Indicator ondersteund beveiligingsprofiel',
	APNS_STATUS: 'Status',
	APNS_VPNENABLEDINDICATOR: 'Indicator VPN ingeschakeld',
	CURRENTLY_ASSIGNED_TO_THIS_ENDPOINT: 'Momenteel toegewezen aan dit eindpunt',
	CURRENTLY_ASSIGNED_TO_SELECTED_ENDPOINTS: 'Momenteel toegewezen aan geselecteerde eindpunten',
	ELIGIBLE_TO_USE_WITH_SELECTED_ENDPOINTS: 'Geschikt voor gebruik met geselecteerde eindpunten',
	ELIGIBLE_TO_USE_WITH_THIS_ENDPOINT: 'Geschikt voor gebruik met dit eindpunt',
	ELIGIBLE_NETWORK_ENTITLEMENTS: 'Geschikte netwerkrechten',
	NETWORK_ENTITLEMENT_CHANGE_IS_SUCCESSFULLY_SUBMITTED: 'Wijziging netwerkrecht is verzonden.',
	NETWORK_ENTITLEMENT_CHANGE_COULD_NOT_BE_CARRIED_OUT_SUCCESSFULLY: 'Wijziging netwerkrecht kan op dit moment niet worden uitgevoerd.',
	NETWORK_ENTITLEMENT_CHANGE_IS_SUCCESSFULLY_SCHEDULED: 'Wijziging netwerkrecht is gepland.',
	NETWORK_ENTITLEMENT_CHANGE_IS_SUCCESSFULLY_SCHEDULED_FOR: 'Wijziging netwerkrecht is gepland voor ',
	NETWORK_ENTITLEMENT_CHANGE_COULD_fNOT_BE_SCHEDULED_SUCCESSFULLY: 'Wijziging netwerkrecht kan op dit moment niet worden gepland.',
	ONCE_YOU_INITIATE_THIS_CHANGE: 'Nadat deze wijziging is geïnitieerd, wordt het geselecteerde netwerkrecht van het eindpunt ',
	WOULD_YOU_LIKE_TO_PROCEED_WITH_THIS_ACTION: 'Wilt u doorgaan met deze actie?',
	OR_YOU_CAN_SCHEDULE_THE_CHANGE_FOR: 'Of u kunt de wijziging plannen voor: ',
	FRIENDLY_NAME: 'Beschrijvende naam',
	NAME: 'Naam',
	// Gegevens netwerkrecht
	APN_DETAILS_ID: 'Id',
	APN_DETAILS_NAME: 'Naam',
	APN_DETAILS_LTE: 'LTE',
	APN_DETAILS_PDPINDEX: 'PDP-index',
	APN_DETAILS_DESCRIPTION: 'Beschrijving',
	APN_DETAILS_FIXEDIP: 'Vast IP-adres',
	APN_DETAILS_SECURITY: 'Beveiliging',
	APN_DETAILS_SUBSCRIPTION: 'Abonnement',

	// BUTTON LABELS
	CANCEL: 'Annuleren',
	CHANGE: 'Wijzigen',
	TROUBLESHOOT: 'Problemen oplossen',
	CHANGE_STATUS: 'Status wijzigen',
	CLOSE: 'Sluiten',
	APPLY_CHANGE: 'Wijziging toepassen',
	DONE: 'Gereed',
	SAVE: 'Opslaan',
	CONTINUE: 'Doorgaan',
	SAVEDASHBOARD: 'Dashboard opslaan',
	SAVE_AND_CONTINUE: 'Opslaan en doorgaan',
	SAVE_CHANGES: 'Wijzigingen opslaan',
	PULL_DATA: 'Data opvragen',
	I_DO_NOT_AGREE: 'Niet akkoord',
	I_AGREE: 'Akkoord',
	LOADING: 'Laden',
	UPLOAD_LOGO: 'Logo uploaden',
	ADD_NEW_CUSTOMER: 'Nieuwe klant toevoegen',
	EDIT: 'Bewerken',
	DELETE: 'Verwijderen',
	NEXT: 'Volgende',

	// NOTIFICATIONS
	ENDPOINT_ID: 'Eindpunt-id',
	PROVISIONING_ERROR: 'Fout inrichting',
	FAILED_TO_PROVISION_AFTER_IMSI_STATUS_CHANGE: ' kon niet worden ingericht na IMSI-statuswijziging. ',
	GET_SUPPORT: 'Ondersteuning vragen',
	PROVISIONING_SUCCESS: 'Inrichting geslaagd',
	IS_SUCCESSFULLY_PROVISIONED: ' is ingericht. ',
	IMSI_STATUS_IS_CHANGED_TO: 'IMSI-status is gewijzigd in ',
	PENDING_STATUS_CHANGE: 'Wijziging in behandeling!',
	STATUS_CHANGE_FOR: 'Er is een geplande activiteit voor dit eindpunt',
	IS_PENDING: ' is in behandeling',
	CUSTOM_FIELD_EDIT_SUCCESS: 'Aangepast veld is bewerkt',
	CUSTOM_FIELD_EDIT_ERROR: 'Fout tijdens bewerking aangepast veld',
	CUSTOM_FIELD_VALUES_UPDATED: 'Een of meer aangepaste velden zijn geüpdatet',
	CUSTOM_FIELD_VALUES_UPDATED_ERROR: 'Een of meer aangepaste velden kunnen op dit moment niet worden geüpdatet. Probeer het opnieuw.',
	CUSTOM_FIELD_LABEL_SUCCESS_TITLE: 'Label aangepast veld is bewerkt!',
	CUSTOM_FIELD_LABEL_SUCCESS_MESSAGE: 'Een of meer labels van aangepaste velden zijn geüpdatet.',
	CUSTOM_FIELD_LABEL_ERROR_TITLE: 'Fout tijdens bewerking label aangepast veld!',
	CUSTOM_FIELD_LABEL_ERROR_MESSAGE: 'Een of meer labels van aangepaste velden kunnen op dit moment niet worden geüpdatet. Probeer het opnieuw.',
	TABLE_COLUMNS_CHANGE_SUCCESS_TITLE: 'Instellingen eindpunttabel zijn gewijzigd!',
	TABLE_COLUMNS_CHANGE_SUCCESS_MESSAGE: 'De instellingen van de eindpunttabel zijn geüpdatet.',
	TABLE_COLUMNS_CHANGE_ERROR_TITLE: 'Fout tijdens kolomwijziging eindpunttabel!',
	TABLE_COLUMNS_CHANGE_ERROR_MESSAGE: 'Kan de kolommen van de eindpunttabel op dit moment niet updaten. Probeer het opnieuw.',
	MC10515: 'Onjuiste bestandsnaam',
	MC10516: 'Fout bestandsdata',
	MC10517: 'Bestand heeft geen header of het bestand is leeg',
	DOWNLOAD_REPORT_ERROR_TITLE: 'Downloadfout',
	DOWNLOAD_REPORT_ERROR_MESSAGE: 'Er is een fout opgetreden tijdens het downloaden van uw bestand',
	FETCH_REPORT_ERROR_TITLE: 'Geen data',
	FETCH_REPORT_ERROR_MESSAGE: 'Er zijn geen data die overeenkomen met de zoek- of filteropdracht',
	MARK_READ: 'Als gelezen markeren',
	VALIDATION_FAILED: 'Valideren mislukt',
	ACTION_COMPLETED_SUCCESSFULLY: 'Actie voltooid',
	ACTION_COULD_NOT_BE_COMPLETED: 'Kan deze actie niet voltooien',

	// SINGLE ENDPOINT
	ENDPOINT_DETAILS: 'Gegevens eindpunt',
	DETAILS: 'Gegevens',
	DIAGNOSTIC: 'Diagnose',
	CHANGES: 'Wijzigingen',
	SESSIONS: 'Sessies',
	END_USER: 'Eindgebruiker',
	LIFE_CYCLE: 'Levenscyclus',
	SEND_SMS: 'Sms verzenden',
	OTA: 'OTA',
	RATING: 'Rating',
	CARRIER_INFORMATION: 'Informatie provider',
	M2M_PLATFORM_STATUS_DESCRIPTION: 'Beschrijving M2M-platformstatus',
	VIEW_NOTE: 'Opmerking weergeven',
	SE_NOTES: 'Opmerkingen',
	CHANGE_NOTES: 'Opmerkingen wijzigen',
	ENTER_NOTE_HERE: 'Noteer hier uw opmerking (max. 2000 tekens)',
	ADD_NOTE_BELOW: 'Voeg uw opmerking hieronder toe: ',
	CHANGING_NOTES: 'Berichtswijzigingen',
	MULTIPLE_NOTES_CHANGE_ERROR: 'Kan wijzigen van berichten voor meerdere eindpunten niet initiëren',
	CHANGING_NOTES_NOT_ALLOWED: 'Berichtswijzigingen zijn niet toegestaan',
	ENDPOINTS_SUCCESSFULLY_CHANGED: 'Eindpunten zijn gewijzigd',
	ENDPOINT_DETAILS_SECOND_CARD_NO_DATA: 'De data voor dit eindpunt zijn tijdelijk niet beschikbaar',

	// DATA OVERVIEW
	ICCID: 'ICCID',
	IMSI: 'IMSI',
	NETWORK_OPERATOR: 'Netwerkoperator',
	NETWORK_COUNTRY: 'Land netwerk',
	MSISDN: 'MSISDN',
	DUAL_MODE: 'Dual-modus',
	CONNECTED_DEVICE: 'Verbonden apparaat',
	IMEI: 'IMEI',
	STATUS: 'Status',
	STATUS_CHANGE_PENDING: 'Er is een geplande activiteit voor dit eindpunt',
	RATE_PLAN: 'Tariefplan',
	NETWORK_ENTITLEMENT: 'Netwerkrecht',
	SOURCE_PLATFORM: 'M2M-platform',
	SERVICE_PROVIDER: 'M2M-platform',
	NETWORK_ENTITLEMENT_ID: 'Netwerkrecht-id',

	// ENDPOINT STATUS
	CURRENT_ENDPOINT_STATUS: 'Huidige status eindpunt',

	// Modaal SIM-parameter wijzigen
	CHANGE_SIM_PARAMETER: 'SIM-parameter wijzigen',
	SELECT_ATT_STATE: 'SELECTEER AT&T-STATUS',
	CURRENT: 'HUIDIG',

	BY: 'Door ',
	SCHEDULING: 'Wordt gepland...',

	// EDIT TABLE MODAL
	EDIT_TABLE_PARAMETERS: 'Tabelparameters bewerken',
	EDIT_CUSTOM_FIELD_LABELS: 'Labels aangepast veld bewerken',
	CUSTOM_LABEL: 'Aangepast label {labelIndex}',
	VALUE: 'Waarde',
	SET_AS_REQUIRED: 'Instellen zoals vereist',
	SELECT_A_MINIMUM_OF_4_TO_APPLY_TO_THE_TABLE: 'Selecteer minimaal twee om op de tabel toe te passen. ',
	SELECTED: ' geselecteerd',
	CLEAR_ALL: 'Standaardinstellingen herstellen',

	EOD: 'Enterprise on Demand (EOD)',
	ACC: 'AT&T Control Center (ACC)',
	GBCM: 'Speedcast',
	MC: 'IoT Console (IOT-C)',
	DCP: 'Apparaatverbindingsplatform (DCP)',
	CCIP: 'Control Center Integration Program (CCIP)',
	AIS: 'AIS',
	TWM: 'Taiwan Mobile (TWM)',
	ATT: 'AT&T',
	AUTOMATION_ACC: 'ACC',
	AUTOMATION_DCP: 'DCP',
	// AT&T: "AT&T",
	WING: 'Nokia (WING)',
	VIVO: 'Vivo Control Center',

	eod: 'Enterprise on Demand (EOD)',
	acc: 'AT&T Control Center (ACC)',
	gbcm: 'Speedcast',
	mc: 'IoT Console (IOT-C)',
	dcp: 'Apparaatverbindingsplatform (DCP)',
	ccip: 'Control Center Integration Program (CCIP)',
	ais: 'AIS',
	twm: 'Taiwan Mobile (TWM)',
	att: 'AT&T',
	// at&t: "AT&T",
	wing: 'Nokia (WING)',
	vivo: 'Vivo Control Center',

	SELECTED_FILTERS: 'Geselecteerde filters',

	BY_WEEK: 'Op week',
	BY_DAY: 'Op dag',
	BY_HOUR: 'Op uur',
	BYTES_SENT: 'MB verzonden',
	BYTES_RECEIVED: 'MB ontvangen',
	TOTAL_MINS: 'Totale aantal minuten',
	monthly: 'Maandelijks',
	daily: 'Dagelijks',
	MONTHLY: 'Maandelijks',
	DAILY: 'Dagelijks',

	// ACTION BAR
	SHOWING: 'Weergegeven ',
	OF: ' van ',

	// REPORTS
	TRANSFER: 'Overdracht',
	USAGE: 'Verbruik',
	DOWNLOAD: 'Downloaden',
	OTHER: 'Overig',

	// INPUT ERRORS
	REQUIRED: 'Verplicht',
	INVALID_COMPANY_NAME: 'Ongeldige bedrijfsnaam',
	INVALID_EMAIL_ADDRESS: 'Ongeldig e-mailadres',
	PHONE_MUST_BE_A_NUMBER: 'Telefoon moet een nummer zijn',
	WRONG_PHONE_FORMAT: 'Onjuiste indeling telefoonnummer',
	ONLY_LETTERS_AND_NUMBERS_ARE_ALLOWED: 'Alleen letters en cijfers zijn toegestaan',
	ONLY_LETTERS_ARE_ALLOWED: 'Alleen letters zijn toegestaan',
	ONLY_LETTERS_AND_SPACES_ARE_ALLOWED: 'Alleen letters en spaties zijn toegestaan',
	ONLY_NUMBERS_ARE_ALLOWED: 'Alleen getallen zijn toegestaan',
	ONLY_POSITIVE_NUMBERS_ARE_ALLOWED: 'Alleen getallen groter dan 0 zijn toegestaan',
	NUMBER_HAS_TO_BE_LESS_THAN: 'Getal moet kleiner zijn dan {propName}',
	NUMBER_HAS_TO_BE_LESS_THAN_OR_EQUAL_TO: 'Getal moet kleiner zijn dan of gelijk zijn aan {propName}',
	NUMBER_HAS_TO_BE_GREATER_THAN: 'Getal moet groter zijn dan {propName}',
	NUMBER_HAS_TO_BE_GREATER_THAN_OR_EQUAL_TO: 'Getal moet groter zijn dan of gelijk zijn aan {propName}',
	NUMBER_HAS_TO_BE_IN_INCREMENTAL_STEPS: 'Getal moet toenemen in stappen van {propName}',
	INVALID_FORMAT: 'Ongeldige indeling',
	MAX_CHARACTERS_EXCEEDED: 'Tekenlimiet overschreden',
	MUST_BE_LESS_THAN_OR_EQUAL_TO_CHARACTERS: 'Getal moet kleiner zijn dan of gelijk zijn aan {max} tekens',
	MUST_BE_CHARACTERS_LONG: 'Moet {number} tekens bevatten',
	NO_ONLY_WHITE_SPACE: 'Mag niet alleen spaties bevatten',
	OUT_OF_RANGE: 'Buiten bereik',
	NOT_BEFORE: 'Datum mag niet eerder zijn dan {date}',
	INVALID_VERSION: 'Alleen indeling hoog.laag.patch toegestaan, bijv. x.y.z',

	/* ------ OUD MAAR WERKT NOG - NIETS VERANDEREN - WORDT AAN GEWERKT --------- */
	ACCOUNT_OVERVIEW: 'Accountoverzicht',
	ACCOUNT_OVERVIEW_ADMIN_DASHBOARD: 'Accountoverzicht Admin-dashboard',
	BILLING: 'Facturering',

	LOG_OUT: 'Afmelden',

	DATA_PLAN: 'Data-abonnement',
	ACTIVESESSION: 'ACTIEVE SESSIE',
	IPADDRESS: 'IP-ADRES',
	ANALYTICS: 'Analyse',
	NETWORK: 'NETWERK',

	ASSET_ID: 'Asset-id',
	SESSION_START: 'Begin sessie',
	DATA_VOLUME: 'Datavolume',
	DURATION: 'Duur (min.)',
	'DATE&TIME': 'Datum en tijd',
	NOTE: 'Opmerking',
	LOGINTITLE: 'Aanmelden',
	LOGINSUBTITLE: 'Voer uw e-mailadres en wachtwoord in.',
	ENDPOINTS_INVENTORY_STATUS: 'STATUS EINDPUNTVOORRAAD',
	INVENTORY_BY_CARRIER: 'VOORRAAD PER PROVIDER',
	INVENTORY_BY_COUNTRY: 'VOORRAAD PER LAND',
	ACCOUNT_USERS: 'ACCOUNTGEBRUIKERS',
	USER_AUTOMATION_STATUS: 'STATUS GEBRUIKERSAUTOMATISERING',
	AUTOMATION_PROCESS_STATUS: 'STATUS AUTOMATISERINGSPROCES',
	NETWORK_TYPE: 'Type netwerk',
	DEVICE_NAME: 'Naam apparaat',
	DEVICE_ID: 'Apparaat-id',
	DEVICE_MODEL_NUMBER: 'Modelnr. apparaat',
	ROAMING_RESTRICTED: 'ROAMING BEPERKT',
	INVOICE_NUMBER: 'FACTUURNUMMER',
	DATE: 'Datum',
	SCHEDULE_DATE: 'Datum',
	ISSUED_BY: 'VERSTREKT DOOR',
	PAYMENT_STATUS: 'STATUS BETALING',
	REPORT_NAME: 'Naam rapport',
	DATE_CREATED: 'Gemaakt op',
	CREATED_BY: 'Gemaakt door',
	PAY_NOW: 'NU BETALEN',
	EDIT_GRAPHS_VIEW: 'Grafiekweergave bewerken',
	EDIT_ENDPOINTS_LIST_VIEW: 'Weergave eindpuntenlijst bewerken',
	CHANGE_LANGUAGE: 'Taal wijzigen',
	CHANGE_LANGUAGE_ICON: 'NL',
	NOT_APPLICABLE_FOR_THIS_ENDPOINT: 'Niet van toepassing voor dit eindpunt',
	CREATE_NEW_RULE: 'Nieuwe regel maken',
	EDIT_RULE: 'Regel bewerken',
	CREATE_NEW_RULE_EXP: 'U kunt automatisering gebruiken om bulkacties op uw account uit te voeren.',
	CREATE_NEW_RULE_ACTION: 'Selecteer de knop ‘Nieuwe regel’ maken om een nieuwe geautomatiseerde actie te maken',
	RULE_NAME: 'Naam regel',
	CATEGORY: 'Categorie',
	TRIGGER: 'Trigger',
	ACTION: 'ACTIE',
	BULK_ACTIONS: 'Bulkacties',
	MULTI_ENDPOINT_ACTIONS: 'Meerdere eindpuntacties',
	SET_ENDPOINT_STATE: 'Eindpuntstatus instellen',
	CHANGE_DATA_PLAN: 'Data-abonnement wijzigen',
	SWAP_OPERATOR: 'Operator verwisselen',
	MULTI_EDIT: 'Meerdere bewerkingen',
	SEARCH_FOR_RULES: 'Zoeken naar regels',
	SEARCH_FOR_INVOICES: 'Zoeken naar facturen',
	SEARCH_FOR_REPORTS: 'Op naam zoeken naar rapport',
	SEARCH_ENDPOINTS: 'Eindpunten zoeken',
	PAYABLE_INVOICES: 'TE BETALEN FACTUREN',
	RECEIVED_PAYMENTS: 'ONTVANGEN BETALINGEN',
	PAYMENT_METHODS: 'BETAALMETHODEN',
	BILLERS: 'CREDITEUREN',
	TIME_PERIOD: 'Tijdsperiode',
	FROM_DATEPICKER: 'Van',
	TO_DATEPICKER: 'Tot',
	ENDPOINT_UI_TOOLTIP: 'Id-nummer voor eindpunt of apparaat',
	OPERATOR_TOOLTIP: 'De operator aan wie het eindpunt en profiel toebehoren',
	NETWORK_TYPE_TOOLTIP: 'Technologie operatorconnectiviteit',
	COUNTRY_TOOLTIP: 'Land waarvan eindpunt afkomstig is',
	ROAMING_RESTRICTED_TOOLTIP: 'Als eindpunt een roaming-beperking heeft',
	STATUS_TOOLTIP: 'Huidige status eindpunt',
	INITIATE_RULE: 'REGEL INITIËREN',
	NEW_AUTOMATION_RULE: 'NIEUWE AUTOMATISERINGSREGEL',
	ENDPOINT_PROVISIONING: 'INRICHTING EINDPUNT',
	ENDPOINT_PROFILE: 'PROFIEL EINDPUNT',
	ENDPOINT_SECURITY: 'BEVEILIGING EINDPUNT',
	ENDPOINT_SUBSCRIPTION: 'ABONNEMENT EINDPUNT',
	NEW_RULE: 'NIEUWE REGEL',
	CONDITION: 'VOORWAARDE',
	ENDPOINT_RANGE: 'Eindpuntbereik is',
	RANGE_START: 'BEGIN BEREIK',
	RANGE_END: 'EINDE BEREIK',
	ASSIGN_ENDPOINT: 'Eindpunt toewijzen aan operator',
	OPERATOR_NAME: 'NAAM OPERATOR',
	ACCOUNT: 'ACCOUNT',
	ADD_ACTION: 'ACTIE TOEVOEGEN',
	RUN_IMMEDIATELY: 'ONMIDDELLIJK UITVOEREN',
	TIME: 'TIJD',
	SELECT_DATE: 'DATUM SELECTEREN',
	SELECT_TIME: 'TIJD SELECTEREN',
	EVENT_TRIGGER: 'GEBEURTENISTRIGGER',
	EVENT_TYPE: 'TYPE GEBEURTENIS',
	EVENT_LOCATION: 'LOCATIE GEBEURTENIS',
	DATA_PLAN_NAME: 'NAAM DATA-ABONNEMENT',
	LOGIN_VALIDATION_WRONG_CREDENTIALS_HEADER: 'Onjuiste referenties!',
	LOGIN_VALIDATION_WRONG_CREDENTIALS_BODY: 'Wachtwoord komt niet overeen met het verstrekte e-mailadres.',
	EMAIL: 'E-mail',
	PASSWORD: 'wachtwoord',
	FORGOT_PASSWORD: 'Wachtwoord vergeten?',
	REMEMBER_ME: 'Mijn gegevens onthouden',
	LOGIN_BUTTON: 'AANMELDEN',
	BACK: 'Terug',
	ENDPOINT_USAGE: 'Verbruik eindpunt',
	COUNTRY_AND_OPERATOR: 'Land en operator',
	PAYMENTS_HISTORY: 'Betalingsgeschiedenis',
	SPENDING_THIS_YEAR: 'Uitgaven dit jaar',
	ENDPOINT_DATA: 'DATA EINDPUNT',
	CURRENT_SESSION: 'HUIDIGE SESSIE',
	VIEW_LESS: 'Minder weergeven',
	VIEW_MORE: 'Meer weergeven',
	ENDPOINT_STATUS: 'Status eindpunt',
	ACTIVATION_DATE: 'Datum activering',
	PRIMARY_IMS: 'Primaire IMS',

	SERVICE_PROVIDER_MODE: 'MODUS SERVICEPROVIDER',
	SUPER_USER_MODE_MESSAGE: '{mode}: {companyFriendlyName}.',
	SUPER_USER_MODE_MOBILE: '{companyFriendlyName}',
	POLICY: 'Beleid',
	COMMUNICATION_PLAN: 'Communicatieplan',
	COPY_RULE: 'Regel kopiëren',
	SECURE_ENDPOINT_ID: 'Beveiligd eindpunt-id',
	CUSTOMER: 'Klant',
	INVENTORY_STATUS: 'VOORRAADSTATUS',
	RESOLUTION_NOT_SUPPORTED_FIRST_LINE: 'Weergave kleine schermen is uitgeschakeld in de demo.',
	RESOLUTION_NOT_SUPPORTED_SECOND_LINE: 'U kunt het beste Chrome gemaximaliseerd of de modus Volledig scherm gebruiken',
	CHECKING_MOVE_ELIGIBILITY: 'VERPLAATSINGSGESCHIKTHEID CONTROLEREN',
	CHANGE_OPERATOR: 'OPERATOR WIJZIGEN',
	SCHEDULE_CHANGE: 'WIJZIGING PLANNEN',
	SCHEDULE_CHANGE_BUTTON: 'Wijziging plannen',
	INITIATE_CHANGE: 'Wijziging initiëren',
	CONFIRM_CHANGE: 'Wijziging bevestigen',
	CONFIRM_SIM_STATE_CHANGE: 'Wijziging SIM-status bevestigen',
	IN_SESSION: 'IN SESSIE',
	ENTITLEMENTS: 'Rechten',
	LOCALIZE_SERVICE: 'Service lokaliseren',
	CHANGE_ENDPOINT_OPERATOR: 'OPERATOR EINDPUNT WIJZIGEN',
	PENDING_CHANGES_ENDPOINT: 'Er zijn een aantal wijzigingen in behandeling voor dit eindpunt, beginnend op ',
	SEE_HISTORY_FOR_DETAILS: 'Zie onderstaande geschiedenis voor meer informatie.',
	CHANGE_ENDPOINT_DATA_PLAN: 'Data-abonnement eindpunt wijzigen',
	IS_SELECTED: ' is geselecteerd.',
	PLEASE_SELECT_DIFFERENT_OPERATOR_TO_CHANGE_OPERATOR: 'Selecteer hieronder een andere operator van operator te wisselen:',
	GENERATE_REPORT: 'RAPPORT GENEREREN',
	REPORT_TYPE: 'TYPE RAPPORT',
	DATA_PLAN_SUMMARIES: 'Overzichten data-abonnement',
	CHARGE_SUMMARIES: 'Overzichten wijzigen',
	INVOICE_REPORT: 'Factuurrapport',
	PRORATION_REPORT: 'Rapport Pro rata',
	GROSS_ACTIVATIONS: 'Brutoactiveringen',
	MONTHLY_SUBSCRIBERS: 'Maandelijkse abonnees',
	VIEW_YOUR_ACCOUT_SNAPSHOT: 'Bekijk een snapshot van uw account.',
	LAUNCH_DASHBOARD: 'DASHBOARD STARTEN',
	VISUAL_DASHBOARD_VIEW_EXP: 'De weergave Visueel dashboard geeft een overzicht van de huidige status van uw account- en apparaatactiviteit.',
	VIEW_CURRENT_STATUS_AND_HISTORICAL_DATA: 'Huidige status en historische gegevens via tijdlijn bekijken.',
	IF: 'ALS',
	AND: 'EN',
	OR: 'OF',
	TO: 'NAAR',
	FROM: 'VAN',
	INVOICES_STATUS: 'STATUS FACTUUR',
	filter_category_m2mAccount: 'M2mAccount',
	PLEASE_CONFIRM_ENDPOINT_DATA_CHANGE: 'Bevestig de wijziging van eindpuntdata.',
	SET_NEW_OPERATOR_AS_ACTIVE: 'NIEUWE OPERATOR ALS ACTIEF INSTELLEN',
	SET_NEW_PLAN_AS_ACTIVE: 'NIEUW PLAN ALS ACTIEF INSTELLEN',
	PLEASE_SELECT_DATE_TO_SET_UP_YOUR_CHANGES_AND_CONFIRM: 'Selecteer de datum voor het instellen van uw wijzigingen en bevestig.',
	DATE_REQUIRED: 'Datum (verplicht)',
	ADDITIONAL_COMMENT: 'Aanvullende opmerking',
	TYPE_YOUR_CUSTOM_NOTES_HERE: 'Typ hier uw aangepaste opmerkingen …',
	CONFIRM_CHANGES: 'Wijzigingen bevestigen',
	CHECKING_MOVE_ELIGIBILITY_LOADER: 'Verplaatsingsgeschiktheid wordt gecontroleerd …',
	ELIGIBLE: 'GESCHIKT',
	CHECKING: 'CONTROLEREN',
	PLEASE_SELECT_DIFFERENT_DATA_PLAN_TO_CHANGE_DATA_PLAN: 'Selecteer hieronder een ander abonnement om van data-abonnement te wisselen:',
	CONFIRM: 'Bevestigen',
	ENDPOINT_RANGE_IS: 'Eindpuntbereik is:',
	ENDPOINT_ID_IS: 'Eindpunt-id is:',
	ENDPOINT_ID_IS_GREATER_THAN: 'Eindpunt-id is groter dan:',
	ENDPOINT_ID_IS_LESS_THAN: 'Eindpunt-id is kleiner dan:',
	ENDPOINT_STATE_CHANGE: 'Wijziging eindpuntstatus',
	ENDPOINT_DATA_PLAN: 'Data-abonnement eindpunt',
	ENDPOINT_SECURITY_POLICY_CHANGE: 'Wijziging beveiligingsbeleid eindpunt',
	ASSIGN_ENDPOINT_TO_OPERATOR: 'Eindpunt toewijzen aan operator',
	MOVE_TO_ANOTHER_ACCOUNT: 'Naar een ander account overbrengen',
	ADD_CUSTOM_FIELD_TO_ENDPOINT_DETAILS: 'Aangepast veld aan eindpuntgegevens toevoegen',
	CREATE_NEW_ACTION: 'Nieuwe actie maken',
	AT_T: 'AT&T IoT (ACC)',
	AT_T_EOD: 'AT&T IoT (ACC)',
	GLOBECOMM: 'Speedcast',
	TAIWAN_MOBILE: 'Taiwan Mobile (TWM)',
	'0001': '***0001',
	'0002': '***0002',
	'0003': '***0003',
	'0004': '***0004',
	OTA_EVENT: 'OTA-gebeurtenis',
	WI_FI: 'Wifi',
	THAILAND: 'Thailand (THA)',
	TAIWAN: 'Taiwan (TWN)',
	UNITED_STATES: 'Verenigde Staten (VS)',
	DATA_PLAN_S: 'Data-abonnement S',
	DATA_PLAN_M: 'Data-abonnement M',
	DATA_PLAN_L: 'Data-abonnement L',
	DATA_PLAN_XL: 'Data-abonnement XL',
	// TO DO: Update eindpuntstatussen om waarden te corrigeren
	ACTIVATED: 'Geactiveerd',
	'TEST READY': 'Test gereed',
	DEACTIVATED: 'Gedeactiveerd',
	PURGED: 'Leeggemaakt',
	RETIRED: 'Buiten gebruik gesteld',
	//
	USER: 'Gebruiker',
	ON: 'op',
	MADE: 'gemaakt',
	CHANGE_STATUS_ACTION: 'Status wijzigen',
	CHANGE_DATA_PLAN_ACTION: 'Data-abonnement wijzigen',
	LOCALIZE_SERVICE_ACTION: 'Gelokaliseerde service',
	IMSI_TRANSFER: 'Status IMSI-overdracht',
	TRANSFER_STATUS: 'Status IMSI-overdracht',
	COUNTRY_OPERATOR: 'Land netwerk',

	PROFILE_NAME: 'Naam profiel',
	NUMBER: 'Nummer',
	DATE_MODIFIED: 'Gewijzigd op',

	ENTITLEMENT_PLAN_ID: 'Rechtenplan-id',
	ENTITLEMENT_PLAN_NAME: 'Naam rechtenplan',
	ACCOUNT_ID: 'Account-id',
	ACCOUNT_I_D: 'Account-id',
	IMEI_NAME: 'Naam IMEI',
	VIN: 'VIN',
	CHIP_ID: 'Chip-id',
	URL_SP: 'url SP',
	ADD_SINGLE_ENDPOINT: 'Enkel eindpunt toevoegen',
	UPLOAD_ICCID_BULK_FILE: 'ICCID-bulkbestand uploaden',
	DOWNLOAD_BULK_FILE: 'Bulkbestand downloaden',
	ENDPOINTS_SELECTED: 'eindpunten geselecteerd',
	SELECTED_ENDPOINTS: 'geselecteerde eindpunten',
	NEW_STATUS: 'Nieuwe status',
	MULTIPLE_ENDPOINT_EDIT: 'Meerdere eindpunten bewerken',
	CLEAR_VALUES_FOR_ALL_ENDPOINTS: 'Waarden voor alle eindpunten wissen',
	KEEP_ORIGINAL_ENDPOINT_VALUES: 'Oorspronkelijke eindpuntwaarden behouden',
	ACTIVE: 'Geactiveerd',
	INACTIVE: 'Inactief',
	TERMINATE: 'Beëindigd',
	TEST_READY: 'Test gereed',
	TEST: 'Test',
	SELECT_OPTION_OR_TYPE_IN: 'Selecteer of typ een optie',
	CLEAR_ALL_VALUES_FOR_ENDPOINTS: 'Alle waarden voor eindpunten wissen',
	BULK_STATUS_UPDATE: 'Update bulkstatus',
	TOTAL_ENDPOINTS: 'Totale aantal eindpunten',
	TOTAL_FILES: 'Totale aantal bestanden',
	ENDPOINT_STATUS_UPDATED: 'Eindpuntstatus geüpdatet',
	MODIFIED_ENDPOINTS: 'Gewijzigde eindpunten',
	SOMETHING_WENT_WRONG: 'Er is iets misgegaan',
	ENDPOINT_STATUS_IS_UPDATING: 'Eindpuntstatus is geüpdatet',
	PLEASE_WAIT: 'Even geduld',
	SWAP_SIM: 'SIM omwisselen',
	SWAP_SIMS: 'SIM’s omwisselen',
	SIM: 'SIM',
	NEW: 'Nieuw',
	OLD: 'Oud',
	CHANGE_ORDER: 'Volgorde wijzigen',
	ITEM: 'Item',
	ADDITIONAL_INFO: 'Aanvullende info',
	UPDATE_LABEL: 'Label updaten',
	ENDPOINT_LABEL_IS_UPDATING: 'Eindpuntlabel wordt geüpdatet',
	ENDPOINT_LABEL_UPDATED: 'Eindpuntlabel geüpdatet',
	ENDPOINT_FIELDS_ARE_UPDATING: 'Eindpuntvelden worden geüpdatet',
	ENDPOINT_FIELDS_UPDATED: 'Eindpuntvelden geüpdatet',
	EDIT_CUSTOM_FIELDS: 'Aangepaste velden bewerken',
	EDIT_CUSTOM_LABEL: 'Aangepast label bewerken',
	CHANGE_ENTITLEMENT: 'Netwerkrecht wijzigen',
	CHANGE_NETWORK_ENTITLEMENT: 'Netwerkrecht wijzigen',
	CURRENT_NETWORK_ENTITLEMENT: 'Huidig netwerkrecht',
	NETWORK_ENTITLEMENT_UPDATED_SUCCESSFULLY: 'Netwerkrecht is geüpdatet!',
	LIST_VIEW_UPDATED: 'Weergave eindpuntenlijst geüpdatet',
	NETWORK_ENTITLEMENT_UPDATING: 'Netwerkrecht wordt geüpdatet. Even geduld',
	LIST_VIEW_UPDATING: 'Weergave eindpuntenlijst wordt geüpdatet. Even geduld',
	NEW_NETWORK_ENTITLEMENT: 'Nieuw netwerkrecht',
	NEW_NETWORK_ENTITLEMENT_DETAILS: 'Gegevens nieuw netwerkrecht',
	TABLE_COLUMNS: 'Kies het aantal kolommen dat u in de tabel wilt weergeven.',
	TABLE_COLUMNS_OR: 'Kies het aantal kolommen dat u in de tabel wilt weergeven of',
	COLUMN: 'Kolom',
	COLUMNS: 'Kolommen',
	SELECT: 'Selecteren',
	DATA_TYPE: 'type data',
	ADVANCED_SEARCH: 'Geavanceerd zoeken',
	SEARCH_RESULTS: 'Zoekresultaten',
	SUBMIT: 'Verzenden',
	SEARCH_PARAMETER: 'Zoekparameter',
	ADD_ANOTHER_SEARCH_PARAMETER: 'Nog een zoekparameter toevoegen',
	STATUS_DATE_RANGE: 'Datumbereik status',
	SIM_STATUS_STATE: 'SIM-status (toestand)',
	NEW_PARAMETER_ACTION: 'En/of',
	PLEASE_SELECT_VALUES_TO_FILTER_ENDPOINTS: 'Selecteer een van de beschikbare parameters en voer vervolgens de criteria in waarop u de eindpunten wilt filteren.',
	RESET_SEARCH: 'Zoeken resetten',
	QUICK_SEARCH: 'Snel zoeken',
	ORIGINAL_SETTINGS: 'Originele instellingen herstellen',
	MONTH: 'Maand',
	DAY: 'Dag',
	REFRESH_ENDPOINTS_PAGE: 'Eindpunttabel/pagina vernieuwen',
	CLEAR_SELECTED_FILTERS_TOOLTIP: 'Geselecteerde filters wissen',
	SELECT_FILTERS_TOOLTIP: 'Filters selecteren',
	EDIT_TABLE_PARAMETERS_TOOLTIP: 'Tabelparameters bewerken',

	REPORT_DATE: 'Datum',

	START_NEW_SEARCH: 'Nieuwe zoekopdracht starten',
	SUCCESS: 'Geslaagd',
	FAILED: 'Mislukt',
	RESULT_ACTION: 'Actie',
	REPORT_DETAILS: 'Rapportgegevens',
	REPORT_LIST: 'Lijst van rapporten in',
	VIEW_DETAILS: 'Gegevens bekijken',
	DOWNLOAD_PDF: 'Downloaden',
	FILE_NAME: 'Naam bestand',
	BATCH_FILES: 'Batchbestanden',
	BATCH_FILE_NAME: 'Naam batchbestand',
	BATCH_FILE_ACTIONS: 'Acties batchbestand',
	BATCH_FILE_UPLOAD: 'Bulkacties',
	CHANGE_SIM_STATE: 'SIM-status wijzigen',
	SELECT_SIM_STATE: 'SIM-status selecteren',
	SIMS_SELECTED: 'Geselecteerde SIM’s',
	SIM_SELECTED: 'Geselecteerde SIM',
	ONCE_YOU_INITIATE_CHANGE: 'Nadat u deze wijziging initieert, worden de geselecteerde SIM’s',
	PROCEED_ACTION: 'Wilt u doorgaan met deze actie?',
	CAN_SCHEDULE: 'Of u kunt de wijziging plannen voor ',
	CURRENT_STATES: 'Huidige status',
	SCHEDULE_SUCCESSFUL: 'Er zijn meerdere SIM-statuswijzigingen gepland!',
	SIM_STATE_CHANGE_FOR: 'SIM-statuswijziging voor',
	SIMS_IS_SUCCESSFULLY_SCHEDULED_FOR: 'SIM is gepland voor',
	SIMS_SUCCESSFULLY_REQUESTED: 'SIM-statuswijziging is aangevraagd!',
	MULTIPLE_SIM_STATE_CHANGE_SCHEDULE_ERROR: 'Kan statuswijziging van meerdere SIM’s niet plannen!',
	MULTIPLE_SIM_STATE_CHANGE_ERROR: 'Kan statuswijziging van meerdere SIM’s niet aanvragen!',
	SELECTED_TIP: 'Tip: Als u SIM’s in verschillende statussen hebt geselecteerd, kunt u ze alleen naar een status wijzigen die voor alle geselecteerde SIM’s beschikbaar is.',
	VIEW_RULES: 'Regels voor statuswijziging voor deze account bekijken',
	BATCH_FILE_UPLOADING: 'Batchbestand wordt geüpload',
	DATE_UPLOADED: 'Uploaddatum',
	SIMPLE_SEARCH: 'Eenvoudig zoeken',
	NUMBER_OF_COLUMNS: 'Aantal kolommen',
	SELECT_SEARCH_PARAMETER: 'Zoekparameter selecteren',

	DROPDOWN_BACK_TO_BUSINESS: 'Terug naar Bedrijf',
	'DROPDOWN_CONTACT_AT&T': 'Contactpersoon AT&T IoT',
	DROPDOWN_MC: 'IoT Console',
	DROPDOWN_M2X: 'M2X',
	DROPDOWN_FD: 'Flow designer',
	DROPDOWN_SK: 'IoT-startpakket',
	DROPDOWN_DP: 'Data-abonnementen IoT',
	MY_INFORMATION: 'Mijn informatie',
	MY_APPLICATIONS: 'Mijn applicaties',
	TOPBAR_IDENTITY: 'AT&T IoT Identity',
	ERROR: 'Fout',
	PAGE_NOT_FOUND_MESSAGE: 'We kunnen de pagina die u zoekt niet vinden.',
	'RETURN TO HOME PAGE': 'TERUG NAAR HOMEPAGE',
	INTERNAL_SERVER_ERROR: 'Interne serverfout',
	ACTIVE_ENDPOINT_PROFILE: 'ACTIEF PROFIEL EINDPUNT',
	CREATE_OR_EDIT: 'Aangepast label voor dit eindpunt maken of bewerken',
	CREATE_CUSTOM_LABELS: 'Aangepaste labels maken',
	SNAP: 'Asjemenou',
	SNAP_ERROR_MESSAGE: 'Er is een onbekende fout opgetreden. Neem contact op met ondersteuning als deze fout zich blijft voordoen.',

	// report detail definitions
	REPORT_DETAIL_DEFINITIONS: 'DEFINITIES RAPPORTGEGEVENS',
	REPORT_DETAIL_DEFINITIONS_MODAL_TITLE: 'Definities rapportgegevens',
	REPORT_DETAIL_DEFINITIONS_DESC: 'Selecteer de onderstaande knop om alle definities van rapportgegevens te bekijken.',
	VIEW_DEFINITIONS_BUTTON: 'DEFINITIES BEKIJKEN',

	// definition types
	'DATA SESSIONS DETAIL DEFINITION': 'Definitie gegevens datasessies',
	'IMSI CHARGE DETAIL XML FIELDS': 'XML-velden IMSI-kostengegevens',
	'IMSI DETAIL DEFINITIONS': 'Definities IMSI-gegevens',
	'LBS USAGE DETAILS': 'Gegevens LBS-verbruik',
	'DATA LAGG DETAIL DEFINITIONS': 'Definities data Lagg-gegevens',
	'SMS CALL DETAIL DEFINITIONS': 'Definities gegevens sms-oproep',
	'TAX DETAIL DEFINITIONS': 'Definities btw-gegevens',
	'SIM ACTIVITY DETAIL DEFINITION': 'Definitie gegevens SIM-activiteit',

	// Data Lagg Detail Definitions - KEYS -------------
	CUST_ID_KEY: 'CUST_ID',
	IMSI_KEY: 'IMSI',
	ICCID_KEY: 'ICCID',
	MSISDN_KEY: 'MSISDN',
	ON_NET_KB_USAGE_KEY: 'ON_NET_KB_USAGE',
	ROAMING_KB_USAGE_KEY: 'ROAMING_KB_USAGE',
	CAN_KB_USAGE_KEY: 'CAN_KB_USAGE',
	INT_KB_ROUNDED_KEY: 'INT_KB_ROUNDED',
	TOTAL_KB_USAGE_KEY: 'TOTAL_KB_USAGE',

	// Data Lagg Detail Definitions - DESCRIPTIONS
	CUST_ID_DESC: 'Id EOD-klant.',
	IMSI_DESC: 'Id internationaal mobiel station. Dit is het nummer dat het netwerk gebruikt om gebruikers te identificeren.',
	ICCID_DESC: 'Integrated Circuit Card Identifier. Dit is het nummer dat op de SIM-kaart is geprint.',
	MSISDN_DESC: 'ISDN-nummer mobiel station (telefoonnummer) dat aan die SIM is toegewezen.',
	ON_NET_KB_USAGE_DESC: 'Totaal verbruik on-net (KB).',
	ROAMING_KB_USAGE_DESC: 'Totaal binnenlands verbruik off-net (KB).',
	CAN_KB_USAGE_DESC: 'Totaal verbruik in Canada (KB).',
	INT_KB_ROUNDED_DESC: 'Totaal internationaal verbruik (KB).',
	TOTAL_KB_USAGE_DESC: 'Totaal verbruik (KB).',

	// Data Sessions Detail Definition - KEYS ----------------
	IMPORT_DATE_KEY: 'Datum importeren',
	// IMSI - use already defined key-desc
	// ICCID - use already defined key-desc
	GGSN_IP_KEY: 'GGSN IP',
	SGSN_IP_KEY: 'SGSN IP',
	APN_NAME_KEY: 'APN NAME',
	PDP_IP_KEY: 'PDP IP',
	UPLINK_VOLUME_KEY: 'VOLUME UPLINK',
	DOWNLINK_VOLUME_KEY: 'VOLUME DOWNLINK',
	TRANSACTION_START_TIME_KEY: 'BEGINTIJD TRANSACTIE',
	DURATION_KEY: 'DUUR',
	SERVED_MSISDN_KEY: 'BEDIENDE MSISDN',
	BID_KEY: 'OFFERTE',
	SERVING_LOCATION_KEY: 'BEDIENDE LOCATIE',
	NETWORK_KEY: 'NETWERK',
	ROUNDING_DATE_KEY: 'DATUM AFRONDING',

	// Data Sessions Detail Definition - DESCRIPTIONS
	IMPORT_DATE_DESC: 'De datum en tijd waarop EOD dit record ontving. Dit wordt gebruikt om te bepalen op welke factureringsdatum dit record wordt toegepast.',
	// IMSI - use already defined key-desc
	// ICCID - use already defined key-desc
	GGSN_IP_DESC: 'Ondersteuningsknooppunt GPRS (Gateway General Packet Radio Service). Het apparaat dat zich tussen de PLMN (Public Land Mobile Network) en externe netwerken bevindt om in grensgateway te voorzien zoals beveiliging en accounting.',
	SGSN_IP_DESC: 'Uitvoerende ondersteuningsknooppunt GPRS (Gateway General Packet Radio Service). Het apparaat dat zich op hetzelfde hiërarchische niveau bevindt als de MSC/VLR (mobile switching center/visitor location register) om mobiliteitsbeheer uit te voeren en de logische link naar mobiele eindapparaten te beheren.',
	APN_NAME_DESC: 'De naam van APN die door dit apparaat voor deze transactie is gebruikt.',
	PDP_IP_DESC: 'Het IP-adres dat op het tijdstip van de transactie aan het apparaat is toegewezen.',
	UPLINK_VOLUME_DESC: 'Het aantal bytes (in achttallen) dat voor dit gegevensrecord door het apparaat is doorgegeven.',
	DOWNLINK_VOLUME_DESC: 'Het aantal bytes (in achttallen) dat voor dit gegevensrecord aan het apparaat is doorgegeven.',
	TRANSACTION_START_TIME_DESC: 'De daadwerkelijke begintijd van de transactie. Deze datum is afhankelijk van wanneer het record door EOD is ontvangen en is anders dan de ‘Datum importeren’ en ‘Datum routeren’.',
	DURATION_DESC: 'De hoeveelheid tijd (in seconden) voor dat datarecord.',
	SERVED_MSISDN_DESC: 'Het ISDN-nummer van het mobiele station (telefoonnummer) dat aan die SIM is toegewezen.',
	BID_DESC: 'Facturerings-id. Deze wordt door EOD gebruikt om te bepalen in welke markt de transactie plaatsvond.',
	SERVING_LOCATION_DESC: 'De naam van de locatie waar dit datarecord plaatsvond.',
	NETWORK_DESC: 'Cingular, roaming/off-netwerk, internationaal of Canada.',
	ROUNDING_DATE_DESC: 'De datum die door EOD wordt gebruikt om te bepalen met welke andere records dit record wordt gegroepeerd om de dagelijkse afronding van kilobytes te berekenen.',
	SESSION_HISTORY_DCP_NOT_APPLICABLE: 'Niet van toepassing.',

	// IMSI Charge Detail XML Fields - KEYS AND DESC ------------
	CUSTOMER_KEY: 'KLANT',
	CUSTOMER_DESC: 'Titel klant',
	STARTCYCLE_KEY: 'STARTCYCLE',
	STARTCYCLE_DECS: 'Begin factureringscyclus',
	ENDCYCLE_KEY: 'ENDCYCLE',
	ENDCYCLE_DESC: 'Einde factureringscyclus',
	WCO_KEY: 'WCO',
	WCO_DESC: 'Aanbieding draadloze connectiviteit',
	EQUIPMENTORDERS_KEY: 'EQUIPMENTORDERS',
	EQUIPMENTORDERS_DESC: 'Bestellen van apparatuur',
	ACCOUNTOCC_KEY: 'ACCOUNTOCC',
	ACCOUNTOCC_DESC: 'Overige kosten en credits op accountniveau (alleen btw en toeslagen)',
	ACCOUNTTAXES_KEY: 'ACCOUNTTAXES',
	ACCOUNTTAXES_DESC: 'Btw en heffingen (alleen belastingen) op accountniveau',
	// ICCID - REUSE
	// IMSI - REUSE
	// MSISDN -REUSE
	RATEPLAN_KEY: 'RATEPLAN',
	RATEPLAN_DESC: 'Het gebruikte tariefplan voor facturering.',
	ACTIVATIONSTATE_KEY: 'ACTIVATIONSTATE',
	ACTIVATIONSTATE_DESC: 'Status van apparaat.',
	CUSTFILED1_KEY: 'CUSTFILED1',
	CUSTFILED1_DESC: 'Klantveld 1',
	CUSTFILED2_KEY: 'CUSTFILED2',
	CUSTFILED2_DESC: 'Klantveld 2',
	CUSTFILED3_KEY: 'CUSTFILED3',
	CUSTFILED3_DESC: 'Klantveld 3',
	CUSTFILED4_KEY: 'CUSTFILED4',
	CUSTFILED4_DESC: 'Klantveld 4',
	CUSTFILED5_KEY: 'CUSTFILED5',
	CUSTFILED5_DESC: 'Klantveld 5',
	ATTPOINTER_KEY: 'ATTPOINTER',
	ATTPOINTER_DESC: 'Interne aanwijzer',
	CUSTPOINTER_KEY: 'CUSTPOINTER',
	CUSTPOINTER_DESC: 'Klantaanwijzer',
	DATAMRC_KEY: 'DATAMRC',
	DATAMRC_DESC: 'Data-MRC',
	DATAUSAGE_KEY: 'DATAUSAGE',
	DATAUSAGE_DESC: 'De som van alle kosten voor dataverbruik',
	SMSMRC_KEY: 'SMSMRC',
	SMSMRC_DESC: 'Sms-MRC',
	SMSUSAGE_KEY: 'SMSUSAGE',
	SMSUSAGE_DESC: 'De som van alle kosten voor sms-verbruik, met uitzondering van bulk-sms-kosten',
	VOICEMRC_KEY: 'VOICEMRC',
	VOICEMRC_DESC: 'Voice-MRC',
	VOICEUSAGE_KEY: 'VOICEUSAGE',
	VOICEUSAGE_DESC: 'De som van alle kosten voor voice-verbruik',
	DEVICEACTIVITYFEE_KEY: 'DEVICEACTIVITYFEE',
	DEVICEACTIVITYFEE_DESC: 'Apparaatkosten (activeringskosten, deactiveringskosten, heractiveringskosten, kosten eenmalige abonnementswijzigingen)',
	OCC_KEY: 'OCC',
	OCC_DESC: 'Overige kosten en credits op abonneeniveau (alleen btw en toeslagen)',
	TAXES_KEY: 'BTW',
	TAXES_DESC: 'Btw en heffingen (alleen belastingen) op abonneeniveau',

	// IMSI DETAIL KEYS VALUE -----------------------
	// ICCID - REUSE
	// MSISDN -REUSE
	PLAN_CODE_KEY: 'PLAN_CODE',
	PLAN_CODE_DESC: 'De abonnementscode die de klant aan elke SIM heeft toegewezen.',
	ACT_ID_KEY: 'ACT_ID',
	ACT_ID_DESC: 'De actie die de klant op elke SIM heeft uitgevoerd (1 = activeren, 2 = deactiveren)',
	INCLUDED_USAGE_KEY: 'INCLUDED_USAGE',
	INCLUDED_USAGE_DESC: 'Totaal bundelgebruik (KB)',
	// ON_NET_KB_USAGE - REUSE
	// ROAMING_KB_USAGE - REUSE
	// CAN_KB_USAGE - REUSE
	// INT_KB_ROUNDED - REUSE
	// CAN_KB_USAGE - REUSE
	ACCESS_CHG_KEY: 'ACCESS_CHG',
	ACCESS_CHG_DESC: 'Vaste maandelijkse toegangskosten.',
	ACTIVATION_CHG_KEY: 'ACTIVATION_CHG',
	ACTIVATION_CHG_DESC: 'Activeringskosten.',
	BEGPERIOD_KEY: 'BEGPERIOD',
	BEGPERIOD_DESC: 'Begin factureringscyclus.',
	ENDPERIOD_KEY: 'ENDPERIOD',
	ENDPERIOD_DESC: 'Einde factureringscyclus.',
	CUST_PREFIX_KEY: 'CUST_PREFIX',
	CUST_PREFIX_DESC: 'Klant-id.',
	CUST_FIELD_1_KEY: 'CUST_FIELD_1',
	CUST_FIELD_1_DESC: 'Klantveld 1.',
	CUST_FIELD_2_KEY: 'CUST_FIELD_2',
	CUST_FIELD_2_DESC: 'Klantveld 2.',
	CUST_FIELD_3_KEY: 'CUST_FIELD_3',
	CUST_FIELD_3_DESC: 'Klantveld 3.',
	CUST_FIELD_4_KEY: 'CUST_FIELD_4',
	CUST_FIELD_4_DESC: 'Klantveld 4.',
	CUST_FIELD_5_KEY: 'CUST_FIELD_5',
	CUST_FIELD_5_DESC: 'Klantveld 5.',
	INTERNAL_POINTER_KEY: 'INTERNE AANWIJZER',
	INTERNAL_POINTER_DESC: 'Interne aanwijzer AT&T.',
	CUSTOMER_POINTER_KEY: 'KLANTAANWIJZER',
	CUSTOMER_POINTER_DESC: 'Klantaanwijzer.',

	// LBS USAGE DEFINITION KEYS-VALUE
	RECORDINGENTITY_KEY: 'RECORDINGENTITY',
	RECORDINGENTITY_DESC: 'Unieke aanwijzer naar Agg-records. (Gereserveerd voor gebruik door aggregator).',
	LCSCLIENTEXTERNALID_KEY: 'LCSCLIENTEXTERNALID',
	LCSCLIENTEXTERNALID_DESC: 'ExternalID LCS-client (momenteel niet in gebruik).',
	TARGETIMSI_KEY: 'TARGETIMSI',
	TARGETIMSI_DESC: 'Id internationaal mobiel station. Dit is het nummer dat het netwerk gebruikt om de gebruiker te identificeren.',
	TARGETMSISDN_KEY: 'TARGETMSISDN',
	TARGETMSISDN_DESC: 'Gevonden MSDN als ATT SIM, XXXXXXXXXX als niet-ATT SIM (het ISDN-nummer of ‘telefoonnummer’ van het mobiele station dat aan die SIM is toegewezen).',
	RESULTCODE_KEY: 'RESULTCODE',
	RESULTCODE_DESC: 'Wel of niet gevonden. 0 = gevonden/ alleen 0 en 1 gebruikt om te starten omdat resultCodes Agg en Med kunnen verschillen.',
	UTCRECORDOPENINGTIME_KEY: 'UTCRECORDOPENINGTIME',
	UTCRECORDOPENINGTIME_DESC: 'Momenteel niet in gebruik (UTC openingstijd record).',
	INFORMATION_1_KEY: 'INFORMATION_1',
	INFORMATION_1_DESC: 'Application ID 16 digit EAG issued AppID (“1” in position2 = Technocom, “2” in position2 = LocAid Id)',
	INFORMATION_3_KEY: 'INFORMATION_3',
	INFORMATION_3_DESC: 'Aangevraagde (horizontale) nauwkeurigheid: Nauwkeurigheid in meters gewenst. <=800 is AGPS. (800 om AGPS-aanvraag aan te geven of 801 om CID/ECID-aanvraag aan te geven voor niet-ATT gevonden en 0 indien mislukt (volgens respons)).',
	INFORMATION_4_KEY: 'INFORMATION_4 ',
	INFORMATION_4_DESC: 'Geretourneerde (horizontale) nauwkeurigheid: Werkelijk geretourneerde nauwkeurigheid in meters. (800 om AGPS-aanvraag aan te geven of 801 om CID/ECID-aanvraag aan te geven voor niet-ATT gevonden en 0 indien mislukt (volgens respons)).',
	PART_DATE_KEY: 'PART_DATE',
	PART_DATE_DESC: 'Datum van invoer in CDR-overdrachtsbestand door Agg. (PST: JJJJ-MM-DD UU:mm:ss)',

	// SMS CALL DEFIONITIONS - KEYS AND DESC
	// IMSI
	// SERVED MSISDN
	SMS_TYPE_KEY: 'SMS TYPE',
	SMS_TYPE_DESC: 'Mobile Originated (MO) of Mobile Terminated (MT).',
	CALLED_NUMBER_KEY: 'GEBELDE NUMMER',
	CALLED_NUMBER_DESC: 'Het nummer dat is gebeld.',
	START_TIME_KEY: 'BEGINTIJD',
	START_TIME_DESC: 'De datum en tijd waarop de transactie plaatsvond.',
	// NETWORK
	PLMN_KEY: 'PLMN',
	PLMN_DESC: 'De lijst Public Land Mobile Network is een facturerings-id die door EOD wordt gebruikt om te bepalen in welke markt de transactie plaatsvond.',
	CARRIER_KEY: 'PROVIDER',
	CARRIER_DESC: 'De eigenaar van de mobiliteitsmarkt voor de bijbehorende PLMN.',

	// SIM ACTIVITY DEFINITIONS - KEYS AND DESC
	// ICCID
	// MSISDN
	ACTION_DATE_KEY: 'ACTION_DATE',
	ACTION_DATE_DESC: 'Datum en tijd waarop de actie is voltooid.',
	ACT_KEY: 'ACT',
	ACT_DESC: 'De actie die de klant op elke SIM heeft uitgevoerd (ACTV = activeren, KILL = deactiveren, CHNG = wijziging netwerkprofiel, RATE = wijziging tariefplan, CPPU = wijziging primaire plaats van gebruik).',
	PLAN_CODE_KEY_2: 'PLAN_CODE',
	PLAN_CODE_DESC_2: 'De alfacode van het abonnement dat voor deze transactie is gebruikt.',
	LOGIN_KEY: 'AANMELDEN',
	LOGIN_DESC: 'Gebruiker die bij de transactie hoort.',

	// TAX DETAIL DEFINITIONS - KEYS AND DESC
	PPU_ADDRES_KEY: 'PPU_ADDRES',
	PPU_ADDRES_DESC: 'Plaats van primair gebruik – Waar de klant de service(s) voornamelijk gebruikt.',
	SERVICE_KEY: 'SERVICE',
	SERVICE_DESC: 'Het product of de aanbieding waarover belasting wordt geheven.',
	INVOICE_SECTION_KEY: 'FACTUURGEDEELTE',
	INVOICE_SECTION_DESC: 'TX – Heffingen en btw OC – Overig kosten en credits.',
	AUTHORITY_LEVEL_KEY: 'INSTANTIENIVEAU',
	AUTHORITY_LEVEL_DESC: 'Niveau van de betreffende factuurtekst F – Federal S – State Co – Country Ci – City DCo – District Country DCi – District City',
	INVOICE_TEXT_KEY: 'FACTUURTEKST',
	INVOICE_TEXT_DESC: 'De bijbehorende tekst van kosten die op de factuur van klanten wordt geprint.',
	CHARGE_AMMOUNT_KEY: 'BEDRAG VOOR TOESLAGEN',
	CHARGE_AMMOUNT_DESC: 'Het bedrag waarover belasting is geheven.',
	AMMOUNT_KEY: 'BEDRAG',
	AMMOUNT_DESC: 'Het berekende btw-bedrag voor de betreffende belastbare kosten.',
	BATCH_FORMATS: 'Batchindelingen',

	ACTIVATION: 'Activering',
	TERMINATION: 'Beëindiging',
	NETWORK_ENTITLEMENT_CHANGE: 'Wijziging in netwerkrechten',
	SIM_SWAP: 'SIM-omwisseling',
	CUSTOM_FIELD_UPDATES: 'Updates aangepast veld',
	CUSTOM_FIELD_VALUE: 'Waarde aangepast veld',
	BATCH_FILE_UPLOAD_SUCCESS: 'De batchbestanden zijn ontvangen!',
	BATCH_FILE_UPLOAD_SUCCESS_MESSAGE: 'Wijzigingen blijven in behandeling tot het systeem uw updates heeft voltooid',
	BATCH_FILE_SYSTEM_ERROR: 'Systeemfout batchbestand!',
	BATCH_FILE_SYSTEM_ERROR_MESSAGE: 'Er is een probleem opgetreden tijdens het verwerken van uw updates. Probeer het later opnieuw.',
	TYPE_IN_OR_SELECT: 'Typ de nieuwe waarde of selecteer een optie in het keuzemenu om een specifieke eindpuntparameter te wijzigen.',

	SUBMITTED_STATUS: 'Verzonden',
	SCHEDULED_STATUS: 'Gepland',
	IN_PROGRESS_STATUS: 'Wordt uitgevoerd',
	COMPLETED_STATUS: 'Voltooid',
	'IN-PROGRESS_STATUS': 'In behandeling',
	COMPLETED: 'Voltooid',
	CANCELLED: 'Geannuleerd',
	ERROR_STATUS: 'Fout',

	FILE_NAME_CANNOT_CONTAIN_SPACES: 'Bestandsnaam mag geen spaties bevatten',
	DRAG_FILE_OR_CLICK_TO_UPLOAD: 'Versleep het bestand of klik om te uploaden',
	FORBIDDEN_ACCESS: 'Verboden toegang',
	REQUEST_TIMED_OUT: 'Er is een time-out opgetreden voor de aanvraag',
	TERMINATE_ACTION: 'SIM’s beëindigen',
	ACTIVATE_ACTION: 'SIM’s activeren',
	SWAP_SIMS_ACTION: 'SIM’s omwisselen',
	CUSTOM_FIELDS_ACTION: 'Aangepaste velden toevoegen of updaten',
	REQUIRED_CUSTOM_FIELD: 'Verhoogde machtigingen nodig!',
	FIELD_IS_REQUIRED_ACTION: 'Alleen beheerders kunnen aangepaste veldtypen wijzigen, omdat ze de configuratie voor het hele systeem beïnvloeden. Neem contact op met uw beheerder om de opties voor aangepaste velden te updaten.',
	PERMANENT_CUSTOM_FIELD_DELETION: 'Permanente verwijdering aangepast veld!',
	DELETING_THIS_CUSTOM_FIELD: 'Bij verwijdering van dit aangepaste veld worden ook alle bijbehorende data verwijderd. Deze actie kan niet ongedaan worden gemaakt!',
	DELETE_FIELD: 'Veld verwijderen',

	WARNING: 'Waarschuwing',
	ARE_YOU_SURE: 'Weet u het zeker?',
	NETWORK_ENTITLEMENT_CHANGE_ACTION: 'Wijziging in SIM-netwerkrechten',
	WHEN_SAVING: 'Volg de bovenstaande indeling bij het opslaan van uw bestand.',
	EXAMPLE: 'Voorbeeld',

	SELECT_NEW_STATE: 'Nieuwe status selecteren',
	403: '403',

	AUTOMATION_PROCESS: 'Automatiseringsproces',
	BATCH_UPLOAD: 'Batchupload',
	DIAGRAM_COULD_NOT_BE: 'Kan diagram op dit moment',
	LOADED_AT_THIS_TIME: 'niet laden.',
	BATCH_ACTIONS: 'Batchacties',
	BATCH_ACTION: 'Batchactie',
	BATCH_ACTIONS_SUBMITTED: 'Het bestand is geüpload voor verwerking',
	BATCH_ACTIONS_SCHEDULED: 'Het bestand is geüpload en gepland voor verwerking',
	BATCH_ACTIONS_IN_PROGRESS: 'Verwerking is begonnen',
	BATCH_ACTIONS_COMPLETED: 'Het bestand is verwerkt',
	BATCH_ACTIONS_ERROR: 'Er is een fout opgetreden tijdens de verwerking',
	BATCH_REPORTING: 'Batchrapporten',
	BATCH_TEXT: 'U kunt uw wijzigingen in batch verwerken door een uit te voeren actie te selecteren en uw batchtekstbestand te uploaden. Er zijn voorbeeldsjablonen beschikbaar voor de opmaak.',
	START_BATCH_PROCESS: 'Batchproces beginnen',
	DOWNLOAD_TEMPLATE: 'Sjabloonbestand downloaden',
	AUTOMATION_RULES: 'Automatiseringsregels',
	ACTION_FORMAT: 'Indeling actie',
	VIEW_MORE_DETAILS: 'Meer gegevens bekijken',
	UPLOAD_FILE_NOTE: 'Upload hier bestanden voor de geselecteerde batchactie:',
	UPLOAD_FILE: 'Bestand uploaden',

	UNSUPPORTED_FILE_TYPE: 'Niet-ondersteund bestandstype',
	INVALID_FILE_NAME_FORMAT: 'De indeling van de bestandsnaam moet actionName_MM-DD-YYYY_HH-mm-ss zijn',
	DOWNLOAD_REPORT_FAILED: 'Download mislukt, probeer het opnieuw.',
	PLEASE_TRY_AGAIN: 'Probeer het opnieuw.',

	// REPORT DETAIL DEFINITION
	REPORT_SOURCE: 'Bron rapport',
	DATA_SESSION_DETAIL_DEFINITION: 'Definitie gegevens datasessie',
	IMSI_DETAIL_DEFINITION: 'Definitie IMSI-gegevens',
	SMS_DETAIL_DEFINITION: 'Definitie sms-gegevens',
	SIM_DETAIL_DEFINITION: 'Definitie SIM-gegevens',
	USAGE_DATA_DETAILS: 'Gegevens dataverbruik',
	USAGE_SMS_DETAILS: 'Gegevens sms-verbruik',
	POOLED_USAGE: 'Gepoold verbruik',
	USAGE_SUMMARY: 'Verbruiksoverzicht',
	SUBSCRIBER_SNAPSHOT: 'Snapshot abonnee',
	NEW_SUBSCRIBERS: 'Nieuwe abonnees',
	TOP_DATA_USAGE: 'Hoogste dataverbruik',
	TOP_SMS_USAGE: 'Hoogste sms-verbruik',
	PROVOSIONING_METRIC: 'Inrichtingsmetriek',

	// USAGE DATA DETAIL - KEYS AND DESC
	DATA_TRAFFIC_DETAIL_ID_KEY: 'Data Traffic Detail ID',
	DATA_TRAFFIC_DETAIL_ID_DESC: 'Uniek id voor dataverbruikrecords',
	// "ICCID_KEY"
	ICCID_DESC_SHORT: 'ICCID die de SIM identificeert',
	// "MSISDN_KEY"
	MSISDN_DESC_SHORT: 'MSISDN die de SIM identificeert',
	// "IMSI_KEY"
	IMSI_DESC_SHORT: 'IMSI die de SIM identificeert',
	ACCOUNT_ID_KEY: 'Account ID',
	ACCOUNT_ID_DESC: 'Id-nummer Jasper-account',
	BILLABLE_FLAG_KEY: 'Billable Flag',
	BILLABLE_FLAG_DESC: 'Indicator van factureerbaar verkeer op basis van SIM-status',
	BILLING_CYCLE_KEY: 'Factureringscyclus',
	BILLING_CYCLE_DESC: 'Factureringscyclus waarin het verbruik plaatsvond',
	SIM_STATE_KEY: 'SIM State',
	SIM_STATE_DESC: 'SIM-status die is toegewezen aan de SIM aan het einde van de rapportperiode',
	SERVICE_TYPE_KEY: 'Type service',
	SERVICE_TYPE_DESC: 'Type gerapporteerde draadloze service (bv. Data, SMS MO, SMS MT, Voice)',
	ASSIGNED_RATE_PLAN_ID_KEY: 'Toegewezen tariefplan-id',
	ASSIGNED_RATE_PLAN_ID_DESC: 'Tariefplan dat is toegewezen aan de SIM op het tijdstip dat het verbruik is gerapporteerd',
	ASSIGNED_RATING_ZONE_ID_KEY: 'Toegewezen tariefzone-id',
	ASSIGNED_RATING_ZONE_ID_DESC: 'Tariefplanzone waaraan het verbruik is toegewezen op basis van de tariefplantoewijzing op het tijdstip dat het verbruik is gerapporteerd',
	OFFPEAK_USAGE_FLAG_KEY: 'Vlag Verbruik buiten piektijden',
	OFFPEAK_USAGE_FLAG_DESC: 'Of het verbruik buiten piektijden plaatsvond volgens de definitie voor de account',
	RECORD_RECEIVED_DATE_KEY: 'Ontvangstdatum record',
	RECORD_RECEIVED_DATE_DESC: 'Datum/tijd de accountingrecord is ontvangen',
	DATA_USAGE_RAW_TOTAL_KEY: 'Dataverbruik - Onbewerkt totaal',
	DATA_USAGE_RAW_TOTAL_DESC: 'Niet-afgerond dataverbruik voor de periode - bytes',
	DATA_USAGE_RAW_UPLINK_KEY: 'Dataverbruik - Onbewerkte uplink',
	DATA_USAGE_RAW_UPLINK_DESC: 'Niet-afgerond dataverbruik voor de periode - uplinkbytes',
	DATA_USAGE_RAW_DOWNLINK_KEY: 'Dataverbruik - Onbewerkte downlink',
	DATA_USAGE_RAW_DOWNLINK_DESC: 'Niet-afgerond dataverbruik voor de periode - downlinkbytes',
	DATA_USAGE_ROUNDED_KEY: 'Dataverbruik - Afgerond',
	DATA_USAGE_ROUNDED_DESC: 'Afgerond dataverbruik voor de periode - bytes; Afrondingsafhandeling wordt per tariefplan gedefinieerd',
	APN_DESC: 'Naam toegangspunt voor de sessie',
	DEVICE_IP_ADDRESS_KEY: 'IP-adres apparaat',
	DEVICE_IP_ADDRESS_DESC: 'Het IP-adres dat aan het apparaat is toegewezen voor de betreffende datasessie',
	OPERATOR_NETWORK_KEY: 'Operatornetwerk',
	OPERATOR_NETWORK_DESC: 'Het bezochte operatornetwerk waarop het verbruik plaatsvond',
	RECORD_OPEN_TIME_KEY: 'Record Tijd open',
	RECORD_OPEN_TIME_DESC: 'Datum/tijd waarop de gehele of een deel van de sessie begon',
	SESSION_DURATION_KEY: 'Duur sessie',
	SESSION_DURATION_DESC: 'Duur van de datasessie - seconden',
	RECORD_SEQUENCE_NUMBER_KEY: 'Volgnummer record',
	RECORD_SEQUENCE_NUMBER_DESC: 'Volgnummer van het record binnen de bovenliggende sessie als het record een gedeeltelijke sessie vertegenwoordigt',
	CHARGINGID_KEY: 'ChargingID',
	CHARGINGID_DESC: 'Een id van de sessie waarop een verbruiksrecord van toepassing is',
	SESSION_CLOSE_CAUSE_KEY: 'Sluitingsreden sessie',
	SESSION_CLOSE_CAUSE_DESC: 'Numerieke code die de sluitingsreden van een sessie aangeeft',
	TAP_CODE_KEY: 'TAP-code',
	TAP_CODE_DESC: 'Een TAP-code voor het bezochte netwerk',
	OPERATOR_ACCOUND_ID_KEY: 'Account-id operator',
	OPERATOR_ACCOUND_ID_DESC: 'Id dat door de operator wordt gebruikt om een account te identificeren',
	RATE_PLAN_VERSION_ID_KEY: 'Versie-id tariefplan',
	RATE_PLAN_VERSION_ID_DESC: 'Bepaalde versie van een tariefplan dat op dit tijdstip wordt gebruikt voor factureringsdoeleinden',
	STREAM_ID_KEY: 'Stream-id',
	STREAM_ID_DESC: 'Een numerieke id om verschillende datastreams te beschrijven die door GGSN/PGW zijn geïdentificeerd',
	CGI_KEY: 'CGI (Cell Global Identifier)',
	CGI_DESC: 'Onderdeel van locatiegegevens zoals beschreven in TS29.274',
	SAI_KEY: 'SAI (Service Area Identifier)',
	SAI_DESC: 'Onderdeel van locatiegegevens zoals beschreven in TS29.274',
	RAI_KEY: 'RAI (Routing Area Identifier)',
	RAI_DESC: 'Onderdeel van locatiegegevens zoals beschreven in TS29.274',
	TAI_KEY: 'TAI (Tracking Area Identity)',
	TAI_DESC: 'Onderdeel van locatiegegevens zoals beschreven in TS29.274',
	ECGI_KEY: 'ECGI(E-UTRAN CGI)',
	ECGI_DESC: 'Onderdeel van locatiegegevens zoals beschreven in TS29.274',
	LAI_KEY: 'LAI (Location Area Identifier)',
	LAI_DESC: 'Onderdeel van locatiegegevens zoals beschreven in TS29.274',
	SERVING_SGSN_KEY: 'Uitvoerende SGSN',
	SERVING_SGSN_DESC: 'Adres van de uitvoerende centrale',
	CALL_TECHNOLOGY_TYPE_KEY: 'Type commandotechnologie',
	CALL_TECHNOLOGY_TYPE_DESC: 'Numerieke indicator om de gebruikte technologie voor radiotoegang voor dit gebruik te identificeren',

	// USAGE SMS DETAIL - KEYS AND DESC
	SMS_TRAFFIC_DETAIL_ID_KEY: 'Gegevens-id dataverkeer',
	SMS_TRAFFIC_DETAIL_ID_DESC: 'Uniek id voor dataverbruikrecords',
	// "ICCID_KEY"
	// "ICCID_DESC"
	// "MSISDN_KEY"
	// "MSISDN_DESC"
	// "IMSI_KEY"
	// "IMSI_DESC"
	// "ACCOUNT_ID_KEY"
	// "ACCOUNT_ID_DESC"
	// "BILLABLE_FLAG_KEY"
	// "BILLABLE_FLAG_DESC"
	// "BILLING_CYCLE_KEY"
	// "BILLING_CYCLE_DESC"
	// "SIM_STATE_KEY"
	// "SIM_STATE_DESC"
	// "SERVICE_TYPE_KEY"
	// "SERVICE_TYPE_DESC"
	// "ASSIGNED_RATE_PLAN_ID_KEY"
	// "ASSIGNED_RATE_PLAN_ID_DESC"
	// "ASSIGNED_RATING_ZONE_ID_KEY"
	// "ASSIGNED_RATING_ZONE_ID_DESC"
	RECORD_OPEN_TIME_SMS_KEY: 'Record Tijd open',
	RECORD_OPEN_TIME_SMS_DESC: 'De datum/tijd waarop een MO-sms-bericht is verzonden; De datum/tijd waarop een MT-bericht is ontvangen',
	// "RECORD_RECEIVED_DATE_KEY": "Ontvangstdatum record",
	// "RECORD_RECEIVED_DATE_DESC": "De datum/tijd waarop het accountingrecord is ontvangen",
	NETWORK_NODE_KEY: 'Netwerkknooppunt',
	NETWORK_NODE_DESC: 'Adres van het uitvoerende netwerkknooppunt voor het bericht',
	FROM_KEY: 'Van',
	FROM_DESC: 'Bron-MSISDN of sms-nummer',
	TO_KEY: 'Naar',
	TO_DESC: 'Bestemmings-MSISDN of sms-nummer',
	// "OPERATOR_NETWORK_KEY": "Operatornetwerk",
	// "OPERATOR_NETWORK_DESC": "Het bezochte operatornetwerk waarop het verbruik plaatsvond",
	// "TAP_CODE_KEY": "TAP-code",
	// "TAP_CODE_DESC": "Een TAP-code voor het bezochte netwerk",
	// "OPERATOR_ACCOUND_ID_KEY": "Account-id operator",
	// "RATE_PLAN_VERSION_ID_KEY": "Versie-id tariefplan",
	// "RATE_PLAN_VERSION_ID_DESC": "Bepaalde versie van een tariefplan
	// dat op dit tijdstip wordt gebruikt voor factureringsdoeleinden',

	// POOLED USAGE - KEYS AND DESC
	// "ACCOUNT_ID_KEY"
	// "ACCOUNT_ID_DESC
	// "BILLING_CYCLE_KEY" />,
	// "BILLING_CYCLE_DESC" />
	INVOICE_ID_KEY: 'Factuur-id',
	INVOICE_ID_DESC: 'Id van de factuur waarop de kosten van toepassing zijn',
	RATE_PLAN_ID_KEY: 'Tariefplan-id',
	RATE_PLAN_ID_DESC: 'Id van het tariefplan dat is toegewezen aan de SIM aan het einde van de rapportperiode',
	RATING_ZONE_ID_KEY: 'Tariefzone-id',
	RATING_ZONE_ID_DESC: 'Tariefzone (gedefinieerd op het tariefplan) waarin het verbruik plaatsvond',
	TOTAL_DATA_USAGE_CHARGES_KEY: 'Totale kosten dataverbruik',
	TOTAL_DATA_USAGE_CHARGES_DESC: 'Totale kosten voor het dataverbruik in deze tariefzone, buiten gepoold dataverbruik dat in de abonnementsprijs is opgenomen',
	TOTAL_SMS_MO_USAGE_CHARGES_KEY: 'Totale kosten SMS MO-verbruik',
	TOTAL_SMS_MO_USAGE_CHARGES_DESC: 'Totale kosten voor het SMS MO-verbruik in deze tariefzone, buiten gepoold sms-verbruik dat in de abonnementsprijs is opgenomen',
	TOTAL_SMS_MT_USAGE_CHARGES_KEY: 'Totale kosten SMS MT-verbruik',
	TOTAL_SMS_MT_USAGE_CHARGES_DESC: 'Totale kosten voor het SMS MT-verbruik in deze tariefzone, buiten gepoold sms-verbruik dat in de abonnementsprijs is opgenomen',
	TOTAL_SMS_USAGE_CHARGES_KEY: 'Totale kosten sms-verbruik',
	TOTAL_SMS_USAGE_CHARGES_DESC: 'Totale kosten voor het sms-verbruik in deze tariefzone, buiten gepoold sms-verbruik dat in de abonnementsprijs is opgenomen',
	TOTAL_VOICE_MO_USAGE_CHARGES_KEY: 'Totale kosten Voice MO-verbruik',
	TOTAL_VOICE_MO_USAGE_CHARGES_DESC: 'Totale kosten voor het Voice MO-verbruik voor deze SIM, buiten gepoold voice-verbruik dat in de abonnementsprijs is opgenomen',
	TOTAL_VOICE_MT_USAGE_CHARGES_KEY: 'Totale kosten Voice MT-verbruik',
	TOTAL_VOICE_MT_USAGE_CHARGES_DESC: 'Totale kosten voor het Voice MT-verbruik voor deze SIM, buiten gepoold voice-verbruik dat in de abonnementsprijs is opgenomen',
	TOTAL_USAGE_CHARGES_KEY: 'Totale verbruikskosten',
	TOTAL_USAGE_CHARGES_DESC: 'Totale kosten voor het verbruik in deze tariefzone buiten gepoold verbruik dat in de abonnementsprijs is opgenomen',

	// USAGE SUMMARY - KEYS AND DESC
	// "ACCOUNT_ID_KEY"
	// "ACCOUNT_ID_DESC"
	OPERATOR_ID_KEY: 'Operator-id',
	OPERATOR_ID_DESC: 'Id-nummer Jasper-operator',
	// "BILLING_CYCLE_KEY"
	// "BILLING_CYCLE_DESC"
	// "ICCID_KEY"
	// "ICCID_DESC"
	// "IMSI_KEY"
	// "IMSI_DESC"
	// "MSISDN_KEY"
	// "MSISDN_DESC"
	TOTAL_DATA_USAGE_KEY: 'Totaal dataverbruik',
	TOTAL_DATA_USAGE_DESC: 'Totaal dataverbruik voor de factureringscyclus voor dit apparaat',
	TOTAL_VOICE_USAGE_KEY: 'Totaal voice-verbruik',
	TOTAL_VOICE_USAGE_DESC: 'Totaal voice-verbruik voor de factureringscyclus voor dit apparaat',
	TOTAL_SMS_USAGE_KEY: 'Totaal sms-verbruik',
	TOTAL_SMS_USAGE_DESC: 'Totaal sms-verbruik voor de factureringscyclus voor dit apparaat',
	TOTAL_CSD_USAGE_KEY: 'Totaal CSD-verbruik',
	TOTAL_CSD_USAGE_DESC: 'Totaal CSD-verbruik voor de factureringscyclus voor dit apparaat',
	RESERVED_FIELD_KEY: 'Reserveveld',
	RESERVED_FIELD_DESC: 'Gereserveerd voor toekomstig gebruik',
	DEVICE_ID_KEY: 'Apparaat-id',
	DEVICE_ID_DESC: 'Aangepast veld beschikbaar voor operators',
	MODEM_ID_KEY: 'Modem-id',
	MODEM_ID_DESC: 'Aangepast veld beschikbaar voor operators',
	END_CONSUMER_ID_KEY: 'Eindconsument-id',
	END_CONSUMER_ID_DESC: 'Id van de eindconsument',
	USAGE_CUSTOMER_KEY: 'Klant',
	USAGE_CUSTOMER_DESC: 'Naam klant',
	CUSTOM1_KEY: 'Aangepast 1',
	CUSTOM1_DEVICE_DESC: 'Aangepast veld 1 apparaatniveau',
	CUSTOM2_KEY: 'Aangepast 2',
	CUSTOM2_DEVICE_DESC: 'Aangepast veld 1 apparaatniveau',
	CUSTOM3_KEY: 'Aangepast 3',
	CUSTOM3_DEVICE_DESC: 'Aangepast veld 1 apparaatniveau',

	// SUBSCRIBER SNAPSHOT - KEYS AND DESC
	SUBSCRIBER_SIM_STATE_KEY: 'SIM-status',
	SUBSCRIBER_SIM_STATE_DESC: 'SIM-status aan het eind van de rapportageperiode',
	// "RATE_PLAN_ID_KEY"
	SUBSCRIBER_RATE_PLAN_ID_DESC: 'Tariefplan dat aan de SIM is toegewezen aan het einde van de rapportageperiode',
	SUBSCRIBER_CUSTOMER_KEY: 'Klant',
	SUBSCRIBER_CUSTOMER_DESC: 'Klant die aan de SIM is toegewezen aan het einde van de rapportageperiode',
	// "DEVICE_ID_KEY",
	SUBSCRIBER_DEVICE_ID_DESC: 'Waarde aan het einde van de rapportageperiode',
	// "MODEM_ID_KEY"

	SUBSCRIBER_MODEM_ID_DESC: 'Waarde aan het einde van de rapportageperiode',
	COMMUNICATION_PLAN_ID_KEY: 'Communicatieplan-id',
	COMMUNICATION_PLAN_ID_DESC: 'Waarde aan het einde van de rapportageperiode',
	INITIAL_ACTIVATION_DATE_KEY: 'Eerste activeringsdatum',
	INITIAL_ACTIVATION_DATE_DESC: 'Datum en tijd waarop de SIM voor het eerst is geactiveerd in de huidige account',
	CUSTOM_SIM_DESC: 'Aangepast veld op SIM-niveau beschikbaar voor accounts',
	LINE_PAYMENT_STATUS_KEY: 'Betalingsstatus regel',
	LINE_PAYMENT_STATUS_DESC: 'Mogelijke waarden zijn Betaald, Opgeschort, Geannuleerd, Definitief geannuleerd',
	OPERATOR_ACCOUNT_ID_KEY: 'Account-id operator',
	OPERATOR_ACCOUNT_ID_DESC: 'Id dat door de operator wordt gebruikt om een account te identificeren',
	CUSTOM4_KEY: 'Aangepast 4',
	CUSTOM5_KEY: 'Aangepast 5',
	CUSTOM6_KEY: 'Aangepast 6',
	CUSTOM7_KEY: 'Aangepast 7',
	CUSTOM8_KEY: 'Aangepast 8',
	CUSTOM9_KEY: 'Aangepast 9',
	CUSTOM10_KEY: 'Aangepast 10',

	ICCID_DESC_SHORT2: 'ICCID apparaat',
	IMSI_DESC_SHORT2: 'IMSI apparaat',
	RATE_PLAN_KEY: 'Tariefplan',
	RATE_PLAN_DESC: 'EOD plan_code, ICCID Control Center blanco',
	SIM_STATE_KEY2: 'Sim-status',
	SIM_STATE_DESC2: 'ACTIEF, INACTIEF, enz. indien beschikbaar',
	BEGBILLINGCYCLEDATE_KEY: 'Begbillcycledate',
	BEGBILLINGCYCLEDATE_DESC: 'Voor EOD, de datum waarop de factureringscyclus begon; voor Control Center, blanco',
	ENDBILLINGCYCLEDATE_KEY: 'Endbillcycledate',
	ENDBILLINGCYCLEDATE_DESC: 'Voor EOD, de datum waarop de factureringscyclus eindigde; voor Control Center, blanco',
	TOTALDATAUSAGE_KEY: 'Totaldatausage',
	TOTALDATAUSAGE_DESC: 'Dataverbruik in kilobytes voor periode',
	TOTALINTERNATIONALUSAGE_KEY: 'Totalinternationalusage',
	TOTALINTERNATIONALUSAGE_DESC: 'Voor EOD, internationaal dataverbruik in kilobytes; voor Control Center, blanco',
	CUSTOMERNAME_KEY: 'Customername',
	CUSTOMERNAME_DESC: 'Naam van de klant',
	CUSTOMFIELD1_KEY: 'Customfield1',
	CUSTOMERNAME1_DESC: 'EOD en Control Center kunnen worden ingevuld',
	CUSTOMFIELD2_KEY: 'Customfield2',
	CUSTOMERNAME2_DESC: 'EOD en Control Center kunnen worden ingevuld',
	CUSTOMFIELD3_KEY: 'Customfield3',
	CUSTOMERNAME3_DESC: 'EOD en Control Center kunnen worden ingevuld',
	CUSTOMFIELD4_KEY: 'Customfield4',
	CUSTOMERNAME4_DESC: 'Apparaat-id EOD of Control Center',
	CUSTOMFIELD5_KEY: 'Customfield5',
	CUSTOMERNAME5_DESC: 'Modem-id EOD of Control Center',
	M2MPLATFORM_KEY: 'M2MPlatform',
	M2MPLATFORM_DESC: '‘EOD’ of ‘Control Center’',
	BILLINGCYCLE_KEY: 'Billingcycle',
	BILLINGCYCLE_DESC: 'JPO-veld Control Center in te vullen',
	CUSTOMERID_KEY: 'Customerid',
	CUSTOMERID_DESC: 'Voor EOD, titel klant; voor Control Center, klant-id',
	PROVISIONINGACTIVITY_KEY: 'ProvisioningActivity ',
	PROVISIONINGACTIVITY_DESC: 'Een van de volgende: ‘activation’, ‘inactive’, ‘terminate’, ‘changeNetworkEntitlement’, ‘changeRatePlan’, ‘updateCustomFields’',
	COUNT_KEY: 'Aantal',
	COUNT_DESC: 'Aantal keer dat de inrichtingsactiviteit is aangeroepen',
	SUCCESS_KEY: 'Geslaagd',
	SUCCESS_DESC: 'Aantal keer dat de inrichtingsactiviteit met succes is aangeroepen',
	FAILURE_KEY: 'Mislukt',
	FAILURE_DESC: 'Aantal keer dat de inrichtingsactiviteit zonder succes is aangeroepen',
	AVGTIMEMS_KEY: 'AvgTimeMs',
	AVGTIMEMS_DESC: 'Aantal milliseconden dat het inrichtingscommando gemiddeld duurde',
	LONGESTTIMEMS_KEY: 'LongestTimeMs',
	LONGESTTIMEMS_DESC: 'Aantal milliseconden dat het langzaamste commando van die inrichtingsactiviteit duurde',
	SHORTESTTIMEMS_KEY: 'ShortestTimeMs',
	SHORTESTTIMEMS_DESC: 'Aantal milliseconden dat het snelste commando van die inrichtingsactiviteit duurde',

	REPORT_SOURCE_DESC: 'Selecteer de rapportbron om verschillende definities te zien',

	SIM_SWAP_SUCCESSFUL: 'SIM omgewisseld',
	SIM_SWAP_IN_PROGRESS: 'SIM-omwisseling wordt uitgevoerd',
	REFRESH_PENDING_STATUS: 'Vernieuw het eindpunt om te controleren of de status is gewijzigd',
	TEMPLATE_NOT_AVAILABLE: 'Sjabloon niet beschikbaar.',
	CUSTOM_FIELDS_UPDATE_SUCCESSFUL: 'Aangepaste velden zijn geüpdatet',
	CUSTOM_FIELDS_UPDATE_IN_PROGRESS: 'Update aangepaste velden wordt uitgevoerd',
	IOT_APPS: 'IOT-APPS',
	INVOICES_DATE: 'Datum facturen',
	PLATFORM: 'Platform',
	FREQUENCY: 'Frequentie',
	M2M_ACC_ID: 'Id M2M-account',
	REPORT: 'Rapport',

	// Onboarding
	ADD_NEW_PLATFORM: 'Nieuw platform toevoegen',
	BUSINESS_NAME: 'Bedrijfsnaam',
	ADMINISTRATOR: 'Beheerder',
	MCC: 'MCC',
	MNC: 'IoT Console (IOT-C)',
	OPERATOR: 'Operator',
	POD: 'POD',
	RATE_PLANS: 'Tariefplannen',
	RATE_PLAN_NAME: 'Naam tariefplan',
	RATE_PLAN_ID: 'Tariefplan',
	RATE_PLAN_ID_PLAN: 'Tariefplan-id',
	COMMUNICATION_PLANE_NAME: 'Naam communicatieplan',
	ADD_ANOTHER_RATE_PLAN: 'Nog een tariefplan toevoegen',
	ADD_ANOTHER_COMMUNICATION_PLAN: 'Nog een communicatieplan toevoegen',
	ADD_ANOTHER_PROFILE_NAME: 'Nog een profielnaam toevoegen',
	SIM_STATE_MAPPING: 'Toewijzingen SIM-status',
	ADD_RATE_PLAN: 'Tariefplan toevoegen',
	ACC_RULE: 'ACC-regel',
	MC_RULE: 'Regel IOT-C',
	WHAT_IS_THIS_TITLE: 'Wat is dit?',
	PLAN_PROFILES: 'Planprofielen',
	COMPANY: 'Bedrijf',
	COMPANY_NAME: 'Naam bedrijf',
	COMPANY_DOMAIN: 'Bedrijfsdomein',
	PHONE_NUMBER: 'Telefoonnummer',
	CITY: 'Plaatsnaam',
	CITY_TOWN: 'Plaatsnaam',
	STATE_PROVINCE: 'Staat/provincie',
	COUNTRY: 'Land',
	ZIP_CODE: 'Postcode',
	ZIP_POSTAL_CODE: 'Postcode',
	STREET: 'Adres',
	STREET_ADDRESS: 'Adres',
	LAST_NAME: 'Achternaam',
	FIRST_NAME: 'Voornaam',
	ACTIONS: 'Acties',
	PLEASE_SELECT_COUPLE_OF_SIMS: 'Selecteer een aantal SIM’s.',
	COMPANY_IDENTITY: 'Bedrijfs-id',
	ADD_USERS_OPTIONAL: 'Gebruikers toevoegen (optioneel)',
	REVIEW_AND_CREATE: 'Controleer en maak',
	PROJECTS_LIST: 'Projectlijst',
	MULTI_NETWORK_CONNECT_ONBOARDING_M2M_PLATFORMS: 'Onboarden IoT Console - M2M-platforms',
	MULTI_NETWORK_CONNECT: 'IoT Console',
	CUSTOMERS: 'Klanten',
	COMPANY_EMAIL_ADDRESS: 'E-mailadres bedrijf',
	CONTACT_EMAIL_ADDRESS: 'E-mailadres contactpersoon',
	CONTACT_PERSON_FULL_NAME: 'Volledige naam contactpersoon',
	CONTACT_PERSON: 'Contactpersoon',
	ASSIGN_ANOTHER_PLATFORM: 'Nog een platform toewijzen',
	USER_INFO: 'Info gebruiker',
	SELECT_USER_TYPE: 'Selecteer een gebruikerstype',
	USER_TYPE: 'Gebruikerstype',
	USER_NOTE: 'De gebruiker ontvangt een e-mail met een uitnodiging om een account te maken',
	ADD_USER: 'Gebruiker toevoegen',
	ADD_ANOTHER_USER: 'Nog een gebruiker toevoegen',
	WHAT_IS_THIS_MESSAGE1: 'U moet de klant in de M2M-platforms laden, voordat u het IoT Console-account kunt maken. U kunt doorgaan zodra u het account-id hebt.',
	WHAT_IS_THIS_MESSAGE2: 'Klanten van kleine bedrijven en startups die IoT-data-abonnementen of IoT-startpakketten gebruiken, kunnen automatisch worden toegevoegd door dit formulier in te vullen. Nadat een account is gemaakt, komt u hier terug om de klantentoegang tot de portal van IoT Console in te schakelen.',
	SELECT_PLATFORM: 'Platform selecteren',
	EMAIL_EXISTS_OTHER: 'Deze e-mail bestaat al (andere gebruiker)',
	EMAIL_EXISTS_ADMIN: 'Deze e-mail bestaat al (admin)',
	YOU_HAVE_SUCCESSFULLY_CREATED_A_MULTI_NETWORK_CONNECT_ACCOUNT_FOR: 'U hebt een IoT Console-account gemaakt voor:',
	THE_IOT_GATEWAY_NUMBER_ASSOCIATED_WITH_THIS_ACCOUNT: 'Het bijbehorende IoT-gatewaynummer van deze account is:',
	COMMUNICATION_PROFILE_NAME: 'Naam communicatieprofiel',
	ADD_NETWORK_ENTITLEMENT: 'Netwerkrecht toevoegen',
	EDIT_NETWORK_ENTITLEMENT: 'Netwerkrecht bewerken',
	PLATFORMS: 'Platforms',
	NO_NETWORK_ENTITLEMENTS: 'Geen netwerkrechten',
	NO_RATE_PLANS: 'Geen tariefplannen',
	EDIT_RATE_PLAN: 'Tariefplan bewerken',
	ERROR_WHILE_ADDING_NETWORK_ENTITLEMENT: 'Fout tijdens toevoegen netwerkrecht',
	ERROR_WHILE_EDITING_NETWORK_ENTITLEMENT: 'Fout tijdens bewerken netwerkrecht',
	ERROR_WHILE_ADDING_RATE_PLAN: 'Fout tijdens toevoegen tariefplan',
	ERROR_WHILE_EDITING_RATE_PLAN: 'Fout tijdens bewerken tariefplan',
	ERROR_WHILE_ADDING_COMPANY: 'Fout tijdens toevoegen bedrijf',
	ERROR_WHILE_EDITING_COMPANY: 'Fout tijdens bewerken bedrijf',
	ERROR_WHILE_ADDING_EDITING_USER: 'Fout tijdens toevoegen/bewerken gebruiker',
	ERROR_WHILE_ADDING_EDITING_PLATFORM: 'Fout tijdens toevoegen/bewerken platform',
	ERROR_WHILE_ADDING_PLATFORM: 'Fout tijdens toevoegen platform',
	ADD_PROFILE_NAME: 'Profielnaam toevoegen',
	ADD_PROFILE_ID: 'Profiel-id toevoegen',
	EDIT_PLATFORM: 'Platform bewerken',
	SIM_STATE_CHANGE_IS_SUCCESSFULLY_SCHEDULED: 'Wijziging SIM-status is gepland!',
	SIM_STATE_CHANGE_IS_SUCCESSFULLY_SCHEDULED_FOR: 'Wijziging SIM-status is gepland voor',
	SIM_STATE_CHANGE_IS_SUCCESSFULLY_SCHEDULED_ERROR: 'Kan SIM-statuswijziging niet plannen!',
	SIM_STATE_CHANGED_TO: 'U hebt ervoor gekozen om de SIM-status te wijzigen in',
	CHANGE_LATER: 'U kunt de wijziging ook voor later plannen.',
	SCHEDULED_CHANGE_WILL_HAPPEN: 'De geplande wijziging wordt uitgevoerd aan het begin van de geselecteerde datum.',
	TAP_INITIATE_CHANGE: 'Tik op ‘Wijziging initiëren’ om onmiddellijk actie te nemen.',
	SIM_STATE_CHANGE: 'Wijziging SIM-status',
	SCHEDULE_SIM_STATE_CHANGE: 'Wijziging SIM-status plannen',
	SYSTEM_CONFIRMATION: 'Systeembevestiging',
	CHOOSE_NETWORK_ENTITLEMENT: 'Netwerkrecht kiezen',
	HISTORY: 'Batchgeschiedenis',

	serviceProvider: 'Platform',
	reportFrequency: 'Frequentie',

	// Onboarding
	ENDPOINT_SESSION_NO_RECORD: 'Er zijn geen data voor de geselecteerde periode.',
	TIME_START: 'Begintijd',
	TIME_END: 'Eindtijd',
	SESSION_DETAILS: 'Sessiegegevens',
	CARRIER: 'Provider',
	DEVICE_IP: 'IP-adres apparaat',

	// chart datasets
	inventoryByStatus: 'Voorraad op status',
	inventoryByOperator: 'Voorraad op operator',
	inventoryByCountry: 'Voorraad per land',
	inventoryByStatusDescription: 'Dit geeft het aantal actieve en inactieve eindpunten weer.',
	inventoryByOperatorDescription: 'Dit geeft het de operators weer en het aantal eindpunten per operator.',
	inventoryByCountryDescription: 'Dit geeft een landnaam weer en het aantal eindpunten dat in dat land actief is.',
	// prompt modal
	addPromptHeaderInfo: 'Wijzigingen toepassen?',
	addChartPromptHeader: 'Dashboardwijzigingen zijn nog niet opgeslagen',
	addChartPromptFirst: 'Als u de wijzigingen in de dashboardweergave wilt opslaan, tikt u op ‘Dashboard opslaan’ of tik op ‘Annuleren’ om de vorige weergave te herstellen.',
	addChartPromptNotification: 'Wijzigingen toepassen?',

	// CHART Descriptions
	DASHBOARD_CHART_countByState_SATELLITEDescription: 'Toont statusgegevens van satellieteindpunten (apparaten) voor meerdere M2M-platformen',
	DASHBOARD_CHART_countByState_CELLULARDescription: 'Toont statusgegevens van mobiele eindpunten (apparaten) voor meerdere M2M-platformen',
	DASHBOARD_CHART_countByNetworkCarrier_SATELLITEDescription: 'Toont het percentage splitsingen van satellieteindpunten (apparaten) voor meerdere netwerkproviders',
	DASHBOARD_CHART_countByNetworkCarrier_CELLULARDescription: 'Toont het percentage splitsingen van mobiele eindpunten (apparaten) voor meerdere netwerkproviders',
	DASHBOARD_CHART_countByTechnologyDescription: 'Toont de totale uitsplitsing op percentage van mobiele en satellieteindpunten (apparaten) in de account',
	DASHBOARD_CHART_countByM2mPlatform_SATELLITEDescription: 'Toont de totale uitsplitsing op percentage van satellieteindpunten (apparaten) voor meerdere M2M-platforms',
	DASHBOARD_CHART_countByM2mPlatform_CELLULARDescription: 'Toont de totale uitsplitsing op percentage van mobiele eindpunten (apparaten) voor meerdere M2M-platforms',
	DASHBOARD_CHART_countByIMSI_SATELLITEDescription: 'Toont de totale uitsplitsing op percentage van mobiele eindpunten (apparaten) voor meerdere M2M-platforms',
	DASHBOARD_CHART_countByIMSI_CELLULARDescription: 'Toont de totale uitsplitsing op percentage van satellieteindpunten (apparaten) voor meerdere M2M-platforms',
	'DASHBOARD_CHART_IMSI Transfer Status_CELLULARDescription': 'Toont de status van de totale overdracht (Re-IMSI) op percentage tussen twee M2M-platformen.',
	DASHBOARD_CHART_countByIMSITransferStatus_CELLULARDescription: 'Toont de status van de totale overdracht (Re-IMSI) op percentage tussen twee M2M-platformen.',
	DASHBOARD_CHART_countByIMSITransferStatus_SATELLITEDescription: 'Toont de status van de totale overdracht (Re-IMSI) op percentage tussen twee M2M-platformen.',
	DASHBOARD_CHART_summaryOfMonthlyEndpointUsageDescription: 'Toont een overzicht van maandelijks dataverbruik tussen mobiele en satellieteindpunten (apparaten)',
	DASHBOARD_CHART_countByStatebyPlatformACC_CELLULARDescription: 'Toont statusgegevens van mobiele eindpunten (apparaten) voor het ACC-platform',
	DASHBOARD_CHART_countByStatebyPlatformEOD_CELLULARDescription: 'Toont statusgegevens van mobiele eindpunten (apparaten) voor het EOD-platform',
	DASHBOARD_CHART_countByStatebyPlatformGBCM_SATELLITEDescription: 'Toont statusgegevens van satellieteindpunten (apparaten) voor het GBCM-platform',
	DASHBOARD_CHART_countByStatebyPlatformDCP_CELLULARDescription: 'Toont statusgegevens van mobiele eindpunten (apparaten) voor het DCP-platform',
	DASHBOARD_CHART_countByStatebyPlatformCCIP_CELLULARDescription: 'Toont statusgegevens van mobiele eindpunten (apparaten) voor het CCIP-platform',
	DASHBOARD_CHART_countByStatebyPlatformVCC_CELLULARDescription: 'Toont statusgegevens van mobiele eindpunten (apparaten) voor het VCC-platform',
	DASHBOARD_CHART_countByStatebyPlatformGDSP_CELLULARDescription: 'Toont statusgegevens van mobiele eindpunten (apparaten) voor het GDSP-platform',
	DASHBOARD_CHART_countByStatebyPlatformWING_CELLULARDescription: 'Toont statusgegevens van mobiele eindpunten (apparaten) voor het WING-platform',
	DASHBOARD_CHART_summaryOfMonthlyEndpointUsage_CELLULARDescription: 'Toont een overzicht van maandelijks dataverbruik tussen mobiele en satellieteindpunten (apparaten)',
	DASHBOARD_CHART_countByProfile_CELLULARDescription: 'Toont statusgegevens van mobiele eindpunten (apparaten) voor meerdere profielen',

	// Pie Chart custom tooltip id
	PIE_CHART_TOOLTIP_Active: 'Geactiveerd',
	PIE_CHART_TOOLTIP_Test: 'Test',
	PIE_CHART_TOOLTIP_Inactive: 'Inactief',
	PIE_CHART_TOOLTIP_Terminate: 'Beëindigd',

	// Globecomm
	DEVICE_MODULE_NUMBER: 'Modulenummer apparaat',
	DEVICE_MANUFACTURER: 'Fabrikant apparaat',
	DEVICE_STATUS: 'Status apparaat',
	DEVICE_NETWORK_TYPE: 'Type apparaatnetwerk',
	MODULE_IMEI: 'IMEI-module',
	SIM_STATUS: 'SIM-status',
	MODULE_STATUS: 'Status module',
	TRESHOLD_OF_NOTIFICATION_BY_DATA_USAGE: 'Drempel voor melding op dataverbruik',
	TRESHOLD_OF_SUSPENSION_BY_DATA_USAGE: 'Drempel voor opschorting op dataverbruik',
	NOTIFICATION_INTERVAL: 'Meldingsinterval',
	DATA_USAGE: 'Dataverbruik in cyclus tot op heden',
	MANUFACTURER: 'Fabrikant',
	MODEL_NUMBER: 'Modelnummer',
	TYPE: 'Type',
	NETWORK_NAME: 'Naam netwerk',
	SUPPORTED_NETWORKS: 'Ondersteunde netwerken',
	RENEWAL_PERIOD: 'Verlengingsperiode',
	ACTIVATION_FEE: 'Activeringskosten',
	RECCURING_FEE: 'Vaste kosten',
	OVERAGE_FEE: 'Kosten overschrijding',
	INCLUDED_BYTES: 'Bundelbytes',
	MINIMUM_BILLABLE_BYTES: 'Minimaal factureerbare bytes',
	NOTIFICATION_CONTACT: 'Contactpersoon melding',
	NOTIFICATION_BYTE_CAP: 'Melding byte-limiet',
	NOTIFICATION_REFRESH: 'Vernieuwing melding',
	OBSOLETE: 'Verouderd',
	HARDWARE: 'Hardware',
	SPECIFICATIONS: 'Specificaties',
	SPECIFICATION: 'Specificatie',
	MODULE: 'Module',
	NO_HISTORY_DATA: 'Geen geschiedenisrecords voor dit eindpunt',

	// Dashboard default message
	NO_GRAPHS: 'TOEGEVOEGDE GRAFIEKEN WORDEN HIER WEERGEGEVEN',
	dashboardDefault: 'TOEGEVOEGDE GRAFIEKEN WORDEN HIER WEERGEGEVEN',
	REMOVE_CHART: 'Grafiek verwijderen',
	CARD_DROPDOWN_TITLE: 'Type grafiek',
	ADD_WIDGET_BUTTON: 'Grafiek toevoegen',
	ADD_WIDGET_BUTTON_MOBILE: 'Toevoegen',

	APP_NAME: 'IoT Console',
	NETWORK_TYPE_CHECK: 'Statuswijzigingen van meerdere eindpunten van verschillende netwerktypen of verschillende serviceproviders zijn helaas niet toegestaan.',
	SERVICE_PROVIDER_CHECK: 'Wijzigen van meerdere eindpunten is helaas niet toegestaan als het eindpunten van verschillende platforms zijn of als ze verschillende netwerkrechten hebben',

	// USER FEEDBACK MODAL
	feedbackTitle: 'Gebruikersfeedback',
	feedbackPageLabel: 'Uw feedback betreft',
	feedbackFeedLabel: 'Vertel in uw eigen woorden wat we kunnen verbeteren?',
	feedbackFeedPlaceholder: 'Noteer hier uw feedback ...',
	feedbackContactLabel: 'Mogen we hierover zo nodig contact met u opnemen?',
	feedbackEmailLabel: 'E-mail',
	feedbackPhoneLabel: 'Telefoon',
	feedbackMetaLabel: 'Metadata',
	feedbackCancelButton: 'Annuleren',
	feedbackSendButton: 'Feedback verzenden',
	feedbackSuccessThanks: 'Bedankt voor de feedback.',
	feedbackSuccessMessage: 'Met uw feedback kunnen we ons product verbeteren. We zullen uw opmerkingen zorgvuldig doornemen en navenant handelen.',
	feedbackSuccessMessageSub: 'Ook kunnen we contact met u opnemen voor meer informatie over uw suggestie.',
	feedbackSuccessMessageSign: 'IoT Console-team',
	feedbackSuccessDoneButton: 'Gereed',
	feedbackTriggerTitle: 'Hebt u feedback?',
	feedbackTriggerSubtitle: 'Vertel het ons hier',
	feedbackThankYou: '{user}, bedankt voor uw deelname aan onze gebruikersfeedback en voor uw hulp bij het verbeteren van onze producten.',
	feedbackSelect: 'U moet het betreffende onderwerp kiezen uit de lijst hierboven.',
	feedbackEnter: 'U moet feedback noteren.',
	feedbackIcon: 'Pictogram Feedback verzonden',
	feedbackAdditionalFile: 'Aanvullend bestand',
	feedbackFileUploadFail: 'File uploaden mislukt',
	feedbackMinLength: 'Voer minstens 10 tekens in.',
	FEEDBACK_FILE_UPLOAD_EXTENSIONS: 'Upload alleen bestanden met de extensies .jpg of .png',
	// Changes tab

	FIELD_NAME: 'Veldnaam',

	DELETE_TAB: 'Tabblad verwijderen',
	TABLE_NAME: 'Tabelnaam',
	EDIT_TABLE: 'Tabelparameters bewerken',
	CUSTOM_FIELD_LABEL: 'Label aangepast veld',
	EDIT_TABLE_VIEW_NAME: 'Naam tabelweergave bewerken',
	PLEASE_ENTER_TABLE_NAME: 'Voer de tabelnaam in',
	DEVICE_TYPE: 'Type apparaat',
	CELLULAR_COLUMNS: 'Mobielspecifieke parameters',
	SATELLITE_SPECIFIC_COLUMNS: 'Satellietspecifieke parameters',

	// TICKETING
	TICKET_ID: 'Ticket-id',
	CATEGORY_NAME: 'Categorie',
	CATEGORY_TICKETING: 'Categorie',
	SUBCATEGORY_OPTIONAL: 'Subcategorie (optioneel)',
	SELECT_SUBCATEGORY_OPTIONAL: 'Subcategorie selecteren (optioneel)',
	ROAMING_ISSUE: 'Roamingprobleem',
	SEVERITY: 'Ernst',
	PRIORITY: 'Prioriteit',
	ACCOUNT_NUMBER: 'Accountnummer',
	CREATED_BY_TICKETING: 'Gemaakt door',
	MULTI_NETWORK_CONNECT_ID: 'IoT Console-id',
	ENDPOINT_MODEL: 'Model eindpunt',
	WHAT_IS_THE_ERROR_MESSAGE: 'Wat zegt de foutmelding?',
	HOW_MANY_BARS_ARE_SHOWING: 'Hoeveel streepjes worden er weergegeven?',
	DESCRIBE_ENDPOINT_STATE_HERE: 'Beschrijf hier de eindpuntstatus...',
	WAS_THE_TROUBLESHOOTING_DONE: 'Hebt u probleemoplossing uitgevoerd?',
	DESCRIBE_TROUBLESHOOTING_DETAILS_HERE: 'Noteer hier de gegevens over de probleemoplossing…',
	DO_YOU_HAVE_ANY_ADDITIONAL_NOTES: 'Hebt u meer opmerkingen?',
	ADDITIONAL_NOTES: 'Aanvullende opmerkingen',
	DASHBOARD_CHART_M2MSIMStatus_SATELLITE: 'eindpunten op IOT-C-status (satelliet)',
	DASHBOARD_CHART_M2MSIMStatus_CELLULAR: 'eindpunten op IOT-C-status (mobiel)',
	DASHBOARD_CHART_Country_SATELLITE: 'Eindpunten op huidige locatie (satelliet)',
	DASHBOARD_CHART_Countrys_CELLULAR: 'Eindpunten op huidige locatie (mobiel)',
	DASHBOARD_CHART_IMSI_SATELLITE: 'Eindpunten op M2M-platform (satelliet)',
	DASHBOARD_CHART_IMSI_CELLULAR: 'Eindpunten op M2M-platform (mobiel)',
	DASHBOARD_CHART_Network_SATELLITE: 'Eindpunten op netwerkprovider (satelliet)',
	DASHBOARD_CHART_Network_CELLULAR: 'Eindpunten op netwerkprovider (mobiel)',
	DASHBOARD_CHART_Technology: 'Eindpunten op technologie',
	'DASHBOARD_CHART_IMSI Transfer Status_SATELLITE': 'Eindpunten op overdrachtsstatus (satelliet)',
	'DASHBOARD_CHART_IMSI Transfer Status_CELLULAR': 'Eindpunten op overdrachtsstatus (mobiel)',
	DASHBOARD_CHART_countByIMSITransferStatus_SATELLITE: 'Eindpunten op overdrachtsstatus (satelliet)',
	DASHBOARD_CHART_countByIMSITransferStatus_CELLULAR: 'Eindpunten op overdrachtsstatus (mobiel)',
	DASHBOARD_CHART_summaryOfMonthlyEndpointUsage_CELLULAR: 'Totaal dataverbruik voor alle ACC-eindpunten',
	DASHBOARD_CHART_countByState_SATELLITE: 'Eindpunten op status (satelliet)',
	DASHBOARD_CHART_countByState_CELLULAR: 'Eindpunten op status (mobiel)',
	DASHBOARD_CHART_countByServiceType_SATELLITE: 'Eindpunten op technologie (satelliet)',
	DASHBOARD_CHART_countByServiceType_CELLULAR: 'Eindpunten op technologie (mobiel)',
	DASHBOARD_CHART_countByRatePlan_SATELLITE: 'Eindpunten op tariefplan (satelliet)',
	DASHBOARD_CHART_countByRatePlan_CELLULAR: 'Eindpunten op tariefplan (mobiel)',
	DASHBOARD_CHART_countByNetworkEntitlement_SATELLITE: 'Eindpunten op netwerkrecht (satelliet)',
	DASHBOARD_CHART_countByNetworkEntitlement_CELLULAR: 'Eindpunten op netwerkrecht (mobiel)',
	DASHBOARD_CHART_countByNetworkCarrier_SATELLITE: 'Eindpunten op netwerkprovider (satelliet)',
	DASHBOARD_CHART_countByNetworkCarrier_CELLULAR: 'Eindpunten op netwerkprovider (mobiel)',
	DASHBOARD_CHART_countByTechnology: 'Eindpunten op technologie',
	DASHBOARD_CHART_countByM2MPlatform_SATELLITE: 'Eindpunten op M2M-platform (satelliet)',
	DASHBOARD_CHART_countByM2MPlatform_CELLULAR: 'Eindpunten op M2M-platform (mobiel)',
	DASHBOARD_CHART_countByIMSI_SATELLITE: 'Eindpunten op M2M-platform',
	DASHBOARD_CHART_countByIMSI_CELLULAR: 'Eindpunten op M2M-platform',
	DASHBOARD_CHART_countByM2mPlatform_SATELLITE: 'Eindpunten op M2M-platform',
	DASHBOARD_CHART_countByM2mPlatform_CELLULAR: 'Eindpunten op M2M-platform',
	DASHBOARD_CHART_countByStatebyPlatformACC_CELLULAR: 'Eindpunten op status (ACC)',
	DASHBOARD_CHART_countByStatebyPlatformEOD_CELLULAR: 'Eindpunten op status (EOD)',
	DASHBOARD_CHART_countByStatebyPlatformGBCM_SATELLITE: 'Eindpunten op status (GBCM)',
	DASHBOARD_CHART_countByStatebyPlatformDCP_CELLULAR: 'Eindpunten op status (DCP)',
	DASHBOARD_CHART_countByStatebyPlatformCCIP_CELLULAR: 'Eindpunten op status (CCIP)',
	DASHBOARD_CHART_countByStatebyPlatformVCC_CELLULAR: 'Eindpunten op status (VCC)',
	DASHBOARD_CHART_countByStatebyPlatformGDSP_CELLULAR: 'Eindpunten op status (GDSP)',
	DASHBOARD_CHART_countByStatebyPlatformWING_CELLULAR: 'Eindpunten op status (WING)',
	DASHBOARD_CHART_countByProfile_CELLULAR: 'Eindpunten op profiel',
	TICKET_STATUS: 'Status ticket',
	ENDPOINT_LOCATION: 'Locatie eindpunt',
	ISSUE: 'Probleem',
	SUGGESTIONS: 'Suggesties',
	PHONE: 'Telefoon',
	FAILED_TO_PROVISION: 'Inrichten mislukt',
	SUBMITTED_BY: 'Verzonden door',
	NEW_TICKET: 'Nieuw ticket',
	ENDPOINT: 'Eindpunt',
	ERROR_MESSAGE: 'Foutmelding',
	ATTACHED_FILE: 'Bijgevoegd bestand',
	TICKETING: 'Ticketsysteem',
	GET_HELP_WITH_THIS_ENDPOINT: 'Hulp vragen voor dit eindpunt',
	CATEGORY_AND_SUGGESTIONS: 'Categorie en suggesties',
	CONFIRMATION: 'Bevestiging',
	RECORDED_STATUS: 'Vastgelegde status',
	ENDPOINT_NAME: 'Eindpunt-id',
	CONTACT_PHONE: 'Telefoon contactpersoon',
	CONTACT_EMAIL: 'E-mail contactpersoon',
	SELECT_SUBCATEGORY: 'Subcategorie selecteren',
	FILE_UPLOADED: 'Bestand geüpload',
	FAQ_SUPPORT_INFO: 'Vind snel antwoorden op vragen die onze gebruikers vaak stellen.',
	USER_GUIDE_SUPPORT_INFO: 'Lees gedetailleerde stapsgewijze instructies voor portalfuncties. De handleiding is afgestemd op uw accounttype.',
	TICKETING_SUPPORT_INFO: 'Als er serviceproblemen zijn en de diagnosetests bieden geen oplossing, dan kunt u een trouble ticket maken. ',
	MUST_BE: 'Moet ',
	LESS_THAN: 'minder zijn dan ',
	CHARACTERS: ' tekens bevatten',
	LOADING_DATA: 'Laden van data',
	GPRS: 'GPRS',

	//  Support
	CONTENT: 'Inhoud',
	CREATE_NEW_NOTIFICATION: 'Nieuwe melding maken',
	EDIT_NOTIFICATION: 'Melding bewerken',
	EDIT_FORM_TO_CREATE_NOTIFICATION: 'Vul het formulier in om een nieuwe melding te maken.',
	NOTIFICATION_TYPE: 'Type melding?',
	SELECT_NOTIFICATION_PLACEHOLDER: 'Selecteer het type melding',
	CHOOSE_WHICH_USER_GROUPS_TO_NOTIFY: 'Kies welke gebruikersgroepen u wilt inlichten:',
	NOTIFY_ALL_GROUPS: 'Melding naar alle groepen',
	MANAGER: 'Manager',
	DEVELOPER: 'Ontwikkelaar',
	ADD_NOTIFICATION_CONTENT: 'Noteer hier uw melding',
	SUSPEND: 'Opschorten',
	SUSPENDED: 'Opgeschort',
	DEACTIVE: 'Niet-actief',
	PROVISIONED: 'Ingericht',
	ENDPOINT_COUNTRY: 'Land eindpunt',
	ENDPOINT_STATE: 'Provincie eindpunt',
	ENDPOINT_CITY: 'Plaatsnaam eindpunt',
	ENDPOINT_STREET_ADDRESS: 'Adres eindpunt',
	AS_OF: 'Verwerkt vanaf',
	VIEW_ALL_NOTIFICATIONS: 'Alle meldingen weergeven',
	NOTIFICATION_SCOPE: 'Toepassingsgebied',
	NOTIFICATION1: 'Info',
	NOTIFICATION2: 'Fout',
	NOTIFICATION3: 'Waarschuwing',
	NOTIFICATION4: 'Bevestiging',
	GLOBAL_NOTIFICATION: 'Wereldwijde melding',
	COMPANY_RELATED_NOTIFICATION: 'Bedrijfsmelding',
	MUST_BE_MORE_THAN: 'Moet groter zijn dan ',
	MUST_BE_LESS_THAN: 'Moet kleiner zijn dan ',
	MUST_BE_LESS_THAN_OR_EQUAL_TO: 'Moet kleiner zijn dan of gelijk zijn aan ',
	GLOBAL: 'Wereldwijd',
	COMPANY_RELATED: 'Bedrijf',
	RECENT_NOTIFICATIONS: 'Systeemwaarschuwingen en -meldingen',

	networkEntitlement: 'Netwerkrecht-id',
	networkType: 'Type netwerk',
	transferStatus: 'Status IMSI-overdracht',
	rateplan: 'Tariefplan',
	FAILURE: 'Mislukt',
	PENDING_REIMSI_ATTEMPT: 'In afwachting van re-IMSI-poging',
	REIMSI_QUEUED: 'Re-IMSI in wachtrij',
	PENDING_REIMSI_DELIVERED: 'In afwachting van re-IMSI afgeleverd',
	'PENDING_RE-IMSI_DELIVERY_FAILED': 'In afwachting van re-IMSI-aflevering mislukt',

	'PENDING REIMSI ATTEMPT': 'In afwachting van re-IMSI-poging',
	'REIMSI PENDING ATTEMPT': 'In afwachting van re-IMSI-poging',
	'PENDING REIMSI DELIVERED': 'In afwachting van overdracht afgeleverd',
	'Re-IMSI Delivery Failed': 'Aflevering overdracht mislukt',
	'REIMSI FAILED': 'Re-IMSI mislukt',
	'REIMSI QUEUED': 'Re-IMSI in wachtrij',
	'REIMSI PEDING ATEMPT': 'In afwachting van re-IMSI-poging',
	'REIMSI PENDING ATEMPT': 'In afwachting van re-IMSI-poging',
	'REIMSI PENDING DELIVERED': 'In afwachting van re-IMSI afgeleverd',
	'REIMSI SUCCESS': 'Re-IMSI geslaagd',
	failure: 'mislukt',

	'ID_PENDING REIMSI ATTEMPT': 'In afwachting van re-IMSI-poging',
	'ID_REIMSI PENDING ATTEMPT': 'In afwachting van re-IMSI-poging',
	'ID_PENDING REIMSI DELIVERED': 'In afwachting van overdracht afgeleverd',
	'ID_Re-IMSI Delivery Failed': 'Aflevering overdracht mislukt',
	'ID_REIMSI FAILED': 'Re-IMSI mislukt',
	'ID_REIMSI QUEUED': 'Re-IMSI in wachtrij',
	'ID_REIMSI PEDING ATEMPT': 'In afwachting van re-IMSI-poging',
	'ID_REIMSI PENDING ATEMPT': 'In afwachting van re-IMSI-poging',
	'ID_REIMSI PENDING DELIVERED': 'In afwachting van re-IMSI afgeleverd',
	'ID_REIMSI SUCCESS': 'Re-IMSI geslaagd',

	CONSUMED_BYTES: 'Verbruikte megabytes',
	CONSUMED_SMSES: 'Verbruikte sms',
	TO_DATE: 'tot op heden',

	CHART_EXISTS_IN_SETTINGS: 'Deze grafiek bestaat al in uw instellingen. Er is slechts één type grafiek toegestaan.',
	NO_CHART_DATA: 'Er ontbreken grafiekdata',
	STATES_MESSAGE: 'Geen status beschikbaar.',
	SELECT_RATE_PLAN: 'Tariefplan selecteren',
	CHANGE_RATE_PLAN: 'Tariefplan wijzigen',
	CURRENT_RATE_PLAN_FOR_ENDPOINT_ID: 'Huidig tariefplan voor eindpunt-id ',
	IS: 'is',
	CONFIRM_RATE_PLAN_CHANGE: 'Wijziging tariefplan bevestigen',
	RATE_PLAN_WILL_BE: 'Nadat deze wijziging is geïnitieerd, wordt het tariefplan van het eindpunt',
	WOULD_YOU_LIKE_TO_INITIATE_CHANGE_NOW: 'Wilt u de wijziging nu initiëren?',
	RATE_PLAN_CHANGE_IS_SUCCESSFULLY_SCHEDULED: 'Wijziging tariefplan is gepland',
	FOR: 'voor',
	RATE_PLAN_CHANGE_IS_SUCCESSFULLY_UPDATED: 'Wijziging tariefplan is geüpdatet',
	RATE_PLAN_CHANGE_COULD_NOT_BE_SCHEDULED_SUCCESSFULLY: 'Kan wijziging tariefplan niet plannen',
	RATE_PLAN_CHANGE_COULD_NOT_BE_CARRIED_OUT_SUCCESSFULLY: 'Kan wijziging tariefplan niet uitvoeren',
	ENDPOINT_RATE_PLAN: 'Tariefplan',
	DOWNLOAD_TEMPLATE_BATCH: 'Sjabloon downloaden',
	CONFIRM_BATCH_FILE_UPLOAD: 'Upload batchbestand bevestigen',
	SCHEDULED_ACTION_ON: 'Geplande actie op',
	FILE_MUST_BE_SUMITTED_WITH_CSV: 'Bestanden moeten met de extensie .csv worden verzonden.',
	MAXIMUM_SIMS_PER_UPLOAD: 'Maximale aantal SIM’s per upload is 10.000.',
	SIMS_HAVE: 'SIM’s hebben',
	CHANGE_FOR_ALL_SELECTED_SIMS: 'Selecteer een ander tariefplan om de wijziging op alle geselecteerde SIM’s toe te passen',
	YOU_HAVE_CHOSEN_TO_CHANGE_THE_RATE_PLAN_OF_THE: 'U hebt ervoor gekozen om het tariefplan te wijzigen van de',
	SELECTED_SIMS_TO: 'geselecteerde SIM’s in',
	SIMS_RATE_PLAN_SUCCESSFULLY_REQUESTED: 'Tariefplannen SIM geüpdatet',
	SCHEDULE_RATE_PLAN_SUCCESSFUL: 'Tariefplan voor meerdere SIM’s gepland',
	SIM_RATE_PLAN_CHANGE_FOR: 'Wijziging tariefplan SIM voor',
	MULTIPLE_RATE_PLAN_CHANGE_SCHEDULE_ERROR: 'Kan wijziging van tariefplan voor meerdere SIM’s niet plannen',
	MULTIPLE_RATE_PLAN_CHANGE_ERROR: 'Kan wijziging van tariefplan voor meerdere SIM’s niet aanvragen',
	SAME_RATE_PLAN_SELECTED: 'Hetzelfde tariefplan geselecteerd',
	REASON: 'reden',

	API_DOCUMENTATION: 'API-documentatie',
	'Cellular - Active': 'Mobiel - Geactiveerd',
	'Satellite - Active': 'Satelliet - Geactiveerd',
	'Cellular - Terminate': 'Mobiel - Beëindigd',
	'Satellite - Terminate': 'Satelliet - Beëindigd',
	CHANGE_RATE_PLAN_NOT_ALLOWED: 'Tariefplan mag niet worden gewijzigd',
	reimsiqueued: 'Re-IMSI in wachtrij',
	reimsifailed: 'Re-IMSI mislukt',
	reimsisuccess: 'Re-IMSI geslaagd',
	reimsipendingattempt: 'Re-IMSI-poging in behandeling',
	reimsipendingdelivered: 'Re-IMSI-aflevering in behandeling',
	imsiPendingMessage: 'Neem contact op met klantenservice als een apparaat langer dan 72 uur de status ‘In behandeling’ heeft',
	ADD_ANOTHER_ACCOUNT: 'Nog een account toevoegen',
	FILTER_DOES_NOT_HAVE_VALID_DATA: 'API-fout, neem contact op met ondersteuning.',
	FAILOVER: 'Failover',
	CANCEL_PENDING_FAIL_TITLE: 'Annuleren van de in behandeling zijnde wijziging is mislukt',
	CANCEL_PENDING_FAIL_TEXT: 'Kan de in behandeling zijnde wijziging momenteel niet annuleren',
	CANCEL_PENDING_SUCCESS_TITLE: 'De in behandeling zijnde wijziging is geannuleerd',
	CANCEL_PENDING_SUCCESS_TEXT: 'Annuleren van de in behandeling zijnde wijziging is voltooid',
	CANCEL_CHANGE: 'Wijziging annuleren',
	CAUTION: 'Let op!',
	CANCEL_PROMPT_TITLE: 'Weet u zeker dat u de in behandeling zijnde wijziging wilt annuleren?',
	CANCEL_PROMPT_TEXT: 'Deze actie kan niet ongedaan worden gemaakt.',
	CONFIRM_CANCELLATION: 'Annulering bevestigen',
	NO_RATE_PLANS_AVAILABLE: 'Geen tariefplannen beschikbaar',
	BULK_UPLOAD: 'Bulkupload',
	MULTI_ENDPOINT_ACTION: 'Actie meerdere eindpunten',
	PLEASE_SELECT_MORE_SIMSS: 'Selecteer twee of meer eindpunten',
	M2M_ACCOUNT_ID: 'Id M2M-account',
	REPORTS_PLATFORM: 'M2M-platform',
	ENDPOINT_CHART_SECOND_CARD: 'Recente sessies',
	ENDPOINT_CHART_SECOND_CARD_ON: 'Op',
	ENDPOINT_CHART_THIRD_CARD: 'Locatie eindpuntnetwerk',
	NO_AVAILABLE_FILTERS: 'Geen beschikbare filters.',
	NO_AVAILABLE_FILTERS_SUBTITLE: 'Er zijn momenteel geen beschikbare filters om te laten zien',

	// Developer API
	GETTING_STARTED: 'Aan de slag',
	API_SANDBOX: 'API-sandbox',
	DEVELOPER_API: 'API voor ontwikkelaars',
	GETTING_STARTED_WITH_API_T: 'Aan de slag met API',
	GETTING_STARTED_WITH_API_P: 'Voordat u uw eerste API-commando kunt maken, moet u het adres van de bron-IP (server) op de whitelist plaatsen. De bron-IP wordt gewoonlijk tijdens het onboardingproces geconfigureerd bij het maken van API-toegang. Als u een foutmelding ontvangt, heeft dat waarschijnlijk te maken met een geblokkeerde toegang tot AT&T IoT-gateway.',
	GETTING_STARTED_WITH_API_UL_T: 'Controleer of u de volgende stappen hebt genomen voordat u met ontwikkeling aan de slag gaat:',
	GETTING_STARTED_WITH_API_LI1: '- U hebt een openbaar toegankelijke server ingesteld en tijdens het onboarden het IP-adres opgegeven.',
	GETTING_STARTED_WITH_API_LI2: '- Uw bron-IP staat op de whitelist voor toegang tot AT&T IoT-gateway.',
	GETTING_STARTED_WITH_API_LI3: '- Uw bedrijfsbeheerder heeft u een API-gebruikersnaam en -wachtwoord gegeven die zijn gecreëerd toen de API-service werd ingeschakeld.',
	GETTING_STARTED_WITH_API_LI4: '- U hebt toegang tot uw bronserver om cURL-opdrachten uit te voeren of verbindingsapps te gebruiken in de onderstaande voorkeurstaal.',
	GET_OAUTH_TOKEN_T: 'OAuth-toegangstoken ophalen',
	GET_OAUTH_TOKEN_P: 'Kies hieronder uw voorkeursmethode en haal een OAuth-token op van AT&T IoT Identity met de gebruikersnaam en het wachtwoord die u van uw beheerder hebt gekregen. Het token is 7 dagen geldig.',
	GETTING_STARTED_WITH_API_CONNECTORS_T: 'API-connectors',
	GETTING_STARTED_WITH_API_CONNECTORS_P: 'Selecteer een van de onderstaande voorbeelden om verbinding te maken met uw API.',
	TEST_CONNECTION_T: 'Test de verbinding met de IoT-gateway',
	TEST_CONNECTION_P: 'Voorbeeld cURL:',
	GETTING_STARTED_WITH_API_NEXT_STEP_T: 'Gewenst resultaat',
	GETTING_STARTED_WITH_API_NEXT_STEP_P: 'Als u HTTP-statuscode 200 OK krijgt, is alles in orde en kunt u met ontwikkelen beginnen.',
	GETTING_STARTED_WITH_API_ERRORS_T: 'Verbindingsfouten oplossen',
	GETTING_STARTED_WITH_API_ERRORS_P: 'Als u een foutmelding ontvangt, controleer dan eerst of de verificatie goed is ingesteld in de HTTPHeader en of het toegangstoken niet verlopen is en vernieuwd moet worden. Ping ook AT&T IoT-gateway om te controleren of uw bron-IP op de whitelist staat. Als u onverwachte resultaten krijgt, kunt u een trouble ticket maken in het gedeelte Ondersteuning.',
	API: 'API',
	SANDBOX_SPECIFIES_THE_LIST: 'De onderstaande Swagger-sandbox specificeert de lijst met bronnen die in de rest-API beschikbaar zijn en de bewerkingen die voor die bronnen aangeroepen kunnen worden. Ook specificeert het document de parameterlijst voor een bewerking met onder meer de naam en het type parameter, of de parameters verplicht of optioneel zijn en informatie over aanvaardbare waarden voor die parameters.',
	JSON_SCHEMA_INCLUDES: 'Het inbegrepen JSON-schema beschrijft de structuur van de aanvraagtekst die naar een bewerking wordt verzonden in een REST-API, samen met het JSON-schema dat de structuur van een responstekst beschrijft die vanaf een bewerking wordt geretourneerd.',

	GBCM_OPERATOR: 'Speedcast',
	ATT_OPERATOR: 'AT&T',

	CREATE_CUSTOM_REPORT: 'Aangepast rapport maken',
	YOU_MUST_CREATE_FILTER_BEFORE_EXPORT: 'U moet een filter maken, voordat u een lijst kunt exporteren.',
	CREATE_REPORT: 'Rapport maken',
	CHART_LEGEND_Active: 'Geactiveerd',
	CHART_LEGEND_Terminate: 'Beëindigd',
	WE_ARE_SORRY_THERE_IS_NO_DATA_TO_EXPORT: 'Er zijn helaas geen data om te exporteren',
	EXPORT_FILTERED_DATA: 'Gefilterde data exporteren',
	ID_DASHBOARD_CHARTS_summaryofmonthlyendpointusage: 'Overzicht van maandelijks eindpuntverbruik',
	ID: 'ID',
	TAB: 'Tabblad',
	SORT_BY: 'Sorteren op',
	SELECT_CHART_TYPE_TO_ADD: 'Selecteer het grafiektype dat u wilt toevoegen',
	RECREDENTIAL_STATUS: 'Status hertoewijzing referenties',
	RECREDENTIAL_TRANSFER_STATUS: 'Status hertoewijzing referenties',
	recredentialTransferStatus: 'Status hertoewijzing referenties',
	M2MACCOUNTID: 'Id M2M-account',
	HOLDING_ACCOUNT_ID: 'Account-id in de wacht',
	RE_CREDENTIAL: 'Hertoewijzing referenties',
	SELECT_OPERATOR: 'Operator selecteren',
	RE_CREDENTIAL_SIM: 'Hertoewijzing referenties SIM',
	CURRENT_PLATFORM: 'Huidig platform',
	YOU_HAVE_CHOSEN_TO_RECREDENTIAL_THIS_SIM: 'U hebt ervoor gekozen om de referenties van deze SIM opnieuw toe te wijzen aan',
	YOU_HAVE_CHOSEN_TO_RECREDENTIAL_THESE_SIMS: 'U hebt ervoor gekozen om de referenties van deze SIM’s opnieuw toe te wijzen aan',
	YOU_MAY_INTIATE_THE_CHANGE_NOW: 'U kunt de wijziging nu initiëren',
	RE_CREDENTIALING_STATUS: 'Status hertoewijzing referenties',
	RE_CREDENTIALING_IS_SUCCESSFULLY_INITIATED: 'Hertoewijzing referenties is geïnitieerd',
	RE_CREDENTIALING_IS_SUCCESSFULLY_SCHEDULED: 'Hertoewijzing referenties is gepland',
	RE_CREDENTIALING_IS_SUCCESSFULLY_SCHEDULED_FOR: 'Hertoewijzing referenties is gepland voor',
	RE_CREDENTIALING_COULD_NOT_BE_INITIATED_SUCCESSFULLY: 'Kan hertoewijzing referenties niet initiëren',
	RE_CREDENTIALING_COULD_NOT_BE_SCHEDULED_SUCCESSFULLY: 'Kan hertoewijzing referenties niet plannen',
	ENDPOINT_HISTORY: 'Geschiedenis eindpunt',
	SHIPPED_DATE: 'Verzonden op',
	DIAGNOSTIC_BUTTON: 'Diagnose starten',
	DEVICE_REGISTRATION_INFO_BUTTON: 'Info apparaatregistratie',
	ENDPOINT_REGISTRATION_DETAILS: 'Registratiegegevens eindpunt',
	eventTime: 'Datum- en tijdstempel',
	carrierName: 'Naam provider',
	registrationType: 'Type registratie',
	NO_DATA_TO_DISPLAY_AT_THE_MOMENT: 'Er zijn momenteel geen data om weer te geven',
	status: 'Status',
	iccid: 'ICCID',
	carrier: 'Provider',
	ATTACHED_CARRIER_NAME: 'Naam gekoppelde provider',
	M2MPlatform: 'M2M-platform',
	simState: 'Ophalen van apparaatstatus',
	'Endpoint Provisioning Information': 'Inrichtingsinformatie eindpunt',
	'M2M Platform': 'M2M-platform',
	'Network Connection': 'Netwerkverbinding',
	simStatusM2M: 'Ophalen van apparaatstatus',
	billingStatus: 'Ophalen apparaatstatus',
	EndpointProvisioningInformation: 'Inrichtingsinformatie eindpunt',
	trafficAllowed: 'Inrichtingsinformatie apparaat ophalen',
	permitPassingTraffic: 'Inrichtingsinformatie apparaat ophalen',
	verifySimState: 'Apparaatinformatie ophalen',
	NetworkConnection: 'Netwerkverbinding',
	verifyConnection: 'Apparaatnetwerk ophalen',
	successfullyConnectedToNetwork_inSession: 'Apparaatnetwerk ophalen',
	verifyIPSession: 'IP-adres apparaat ophalen',
	dataSessionCurrentlySuccessful: 'IP-adres apparaat ophalen',
	dd_inventory: 'Voorraad',
	dd_activated: 'Geactiveerd',
	dd_active: 'Geactiveerd',
	dd_inactivated: 'Inactief',
	dd_inactive: 'Inactief',
	dd_deactivated: 'Gedeactiveerd',
	dd_retired: 'Buiten gebruik gesteld',
	dd_purged: 'Leeggemaakt',
	dd_testready: 'Test gereed',
	dd_activationready: 'Activering gereed',
	dd_replaced: 'Vervangen',
	dd_trial: 'Proef',
	dd_start: 'Starten',
	dd_transfer: 'Overdragen',
	dd_globalintransitsource: 'Bron wereldwijd in transit',
	dd_globalintransitdestination: 'Bestemming wereldwijd in transit',
	dd_globaltransferred: 'Wereldwijd overgedragen',
	dd_other: 'Overig',
	dd_permitpassingtraffic: 'Doorgaand verkeer toegestaan',
	dd_notpermitpassingtraffic: 'Doorgaand verkeer niet toegestaan',
	dd_allowedToUseWirelessNetworkResources: 'Toegestane draadloze TollSec-resources',
	dd_barredFromNetwork: 'Geweerd van netwerk',
	dd_trafficnotallowed_simstate: 'Niet-toegestaan verkeer SIM-status',
	dd_suspendedByServiceProvider: 'Opgeschort door serviceprovider',
	dd_suspendedDueToUsageLimit: 'Opgeschort wegens verbruikslimiet',
	dd_trafficNotAllowed_others: 'Niet-toegestaan verkeer (overig)',
	dd_successfullyconnectedtonetwork_insession: 'Verbonden met netwerk (in sessie)',
	dd_notsuccessfullyconnectedtonetwork_insession: 'Niet-verbonden met netwerk (in sessie)',
	dd_validregistration_validnetworkactivity: 'Geldige registratie (geldige netwerkactiviteit)',
	dd_validregistration_nonetworkactivity: 'Geldige registratie (geen netwerkactiviteit)',
	dd_validgsmregistrationonly: 'Alleen geldige gsm-registratie',
	dd_novalidregistration_hasactivityinlast24h: 'Geen geldige registratie (heeft activiteit in afgelopen 24 uur)',
	dd_novalidregistration_hasauthrequestinlast24h_nolocupdate: 'Geen geldige registratie (heeft auth-aanvraag in afgelopen 24 uur)',
	dd_novalidregistration_haslocupdatesmorethan24hago: 'Geen geldige registratie (heeft meer dan 24 uur geleden lokale updates)',
	dd_novalidregistration_hasauthrequestmorethan24hago_noactivity: 'Geen geldige registratie (heeft meer dan 24 uur geleden een auth-aanvraag)',
	dd_novalidregistration_nonetworkactivity: 'Meer dan 24 uur geleden (geen netwerkactiviteit)',
	dd_datasessioncurrentlysuccessful: 'Apparaat is momenteel niet verbonden. Eerdere IP-sessie geslaagd.',
	dd_notdatasessioncurrentlysuccessful: 'Datasessie momenteel niet geslaagd',
	dd_datasessionpastsuccessful: 'Datasessie in verleden geslaagd',
	dd_connectionattemptfailed_invalidcredentials: 'Verbindingspoging mislukt (ongeldige referenties)',
	dd_nodatasessionsuccessfulhistory: 'Geen geschiedenis geslaagde datasessies',
	dd_: '',
	dd_allowedtousewirelessnetworkresources: 'Toegestane draadloze TollSec-resources',
	attached: 'Gekoppeld',
	detached: 'Ontkoppeld',
	RECREDENTIALED: 'Referenties opnieuw toegewezen',
	RECREDENTIALING: 'Hertoewijzen referenties',
	CHANGE_RECREDENTIAL: 'Hertoewijzing referenties alleen toegestaan voor dezelfde platformen',
	RECREDENTIAL_DESCRIPTION: 'Tip: Als u meerdere SIM’s selecteert bij hertoewijzing van referenties, kunt u slechts één platform voor hertoewijzing van referenties voor meerdere geselecteerde SIM’s selecteren',
	RECREDENTIAL_NOT_ALLOWED: 'Hertoewijzing referenties is niet toegestaan',
	RECREDENTIAL_SUCCESSFULLY_REQUESTED: 'SIM’s zijn geïnitieerd',
	MULTIPLE_RECREDENTIAL_CHANGE_ERROR: 'Kan hertoewijzing van referenties voor meerdere SIM’s niet initiëren',
	MOBILE_NETWORK_REGISTRATION_HISTORY: 'Registratiegeschiedenis mobiel netwerk',
	ENDPOINT_DAILY_USAGE: 'Dagelijks verbruik eindpunt',
	ENDPOINT_CYCLE_USAGE: 'Verbruik eindpunt in cyclus tot op heden',
	CONFIRM_RE_CREDENTIALING: 'Hertoewijzing referenties bevestigen',
	PENDING_RECREDENTIAL: 'Er is een hertoewijzing van referenties in behandeling voor dit eindpunt',
	ENDPOINTS_ARE: 'eindpunten zijn geselecteerd voor export',
	ENDPOINT_IS: 'eindpunt is geselecteerd voor export',
	CUSTOM_REPORT_WILL_BE_AVAILABLE: 'Dit aangepaste rapport is beschikbaar in het rapportgedeelte',
	YOU_CAN_APPEND_CUSTOM_NAME: 'U kunt hier een aangepaste naam aan het rapport toevoegen',
	CUSTOM_REPORT_NAME_APPENDIX: 'Appendix naam aangepast rapport',
	ADD_CUSTOM_REPORT_NAME_APPENDIX: 'Voeg een appendix toe aan naam aangepast rapport (alleen nummers en letters toegestaan)',
	CUSTOM_REPORT: 'Aangepast rapport',
	CUSTOM_REPORT_CREATED: 'Aangepast rapport is gemaakt',
	GO_TO_REPORTS: 'Naar rapporten',
	DIAGNOSTICS_FOR_THIS_ENDPOINT: 'Diagnose voor dit eindpunt is tijdelijk niet beschikbaar',
	ENDPOINT_DATA_INFORMATION: 'Informatie eindpuntdata',
	LAST_SESSION: 'Laatste sessie',
	CARRIER_ATTACHED: 'Gekoppelde provider',
	APN_DCP: 'APN',
	IP_ADDRESS: 'IP-adres',
	LAST_SENT_SMS_MO: 'Datum en tijd van laatst verzonden SMS MO',
	LAST_SENT_SMS_MT: 'Datum en tijd van laatst verzonden SMS MT',
	TEST_DETAILS: 'Testgegevens',
	CHECK_BLOCKED_STATUS: 'Blokkeerstatus controleren',
	BLOCKED_STATUS: 'Blokkeerstatus',
	RETRY_TEST: 'Test opnieuw proberen',
	MOBILE_NETWORK_TEST: 'Test mobiel netwerk',
	CHECK_BLOCKED_SUB: 'Geblokkeerd abo controleren',
	TROUBLESHOOT_DCP: 'DCP probleemoplossing',
	locationUpdate: 'Locatie-update',
	pdpStatus: 'PDP-status',
	cancelLocation: 'Locatie annuleren',
	CANCEL_LOCATION: 'Locatie annuleren',

	DASHBOARD_CHART_CountofDailyEndpointSMSbyPlatformDCP_CELLULARDescription: 'Toont het totale aantal per dag verzonden sms-berichten per maand voor het gehele DCP-platform',
	DASHBOARD_CHART_CountofDailyEndpointSMSbyPlatformDCP_CELLULAR: 'Sms per dag (DCP)',
	DASHBOARD_CHART_CountofMonthlyEndpointSMSbyPlatformDCP_CELLULAR: 'Sms per maand (DCP)',
	DASHBOARD_CHART_SumofDailyEndpointUsagebyPlatformDCP_CELLULAR: 'Totaal dagelijks verbruik eindpunt (DCP)',
	DASHBOARD_CHART_SumofDailyEndpointUsagebyPlatformACC_CELLULAR: 'Dagelijks dataverbruik (ACC)',
	DASHBOARD_CHART_SumofMonthlyEndpointUsagebyPlatformDCP_CELLULAR: 'Maandelijks dataverbruik (DCP)',
	DASHBOARD_CHART_CountofMonthlyEndpointSMSbyPlatformDCP_CELLULARDescription: 'Toont het totale aantal verzonden sms-berichten per maand voor het gehele DCP-platform',
	DASHBOARD_CHART_SumofDailyEndpointUsagebyPlatformDCP_CELLULARDescription: 'Toont het totale dataverbruik per dag op maandbasis voor het gehele DCP-platform',
	DASHBOARD_CHART_SumofDailyEndpointUsagebyPlatformACC_CELLULARDescription: 'Toont het totale dataverbruik per dag op maandbasis voor het gehele ACC-platform',
	DASHBOARD_CHART_SumofMonthlyEndpointUsagebyPlatformDCP_CELLULARDescription: 'Toont het totale dataverbruik per maand voor het gehele DCP-platform',
	DASHBOARD_CHART_CountofMonthlyEndpointSMSbyPlatformCCIP_CELLULAR: 'Sms per maand (CCIP)',
	DASHBOARD_CHART_CountofMonthlyEndpointSMSbyPlatformCCIP_CELLULARDescription: 'Toont het totale aantal verzonden sms-berichten per maand voor het gehele CCIP-platform',
	DASHBOARD_CHART_CountofDailyEndpointSMSbyPlatformCCIP_CELLULAR: 'Sms per dag (CCIP)',
	DASHBOARD_CHART_CountofDailyEndpointSMSbyPlatformCCIP_CELLULARDescription: 'Toont het totale aantal verzonden sms-berichten per dag voor het gehele CCIP-platform',
	DASHBOARD_CHART_SumofDailyEndpointUsagebyPlatformCCIP_CELLULAR: 'Totaal dagelijks verbruik eindpunt (CCIP)',
	DASHBOARD_CHART_SumofDailyEndpointUsagebyPlatformCCIP_CELLULARDescription: 'Toont het totale dataverbruik per dag op maandbasis voor het gehele CCIP-platform',
	DASHBOARD_CHART_SumofMonthlyEndpointUsagebyPlatformCCIP_CELLULAR: 'Maandelijks dataverbruik (CCIP)',
	DASHBOARD_CHART_SumofMonthlyEndpointUsagebyPlatformCCIP_CELLULARDescription: 'Toont het totale dataverbruik per maand voor het gehele CCIP-platform',
	DASHBOARD_CHART_countByCountry_CELLULAR: 'Eindpunten per land',
	DASHBOARD_CHART_countByCountry_CELLULARDescription: 'Toont het aantal eindpunten voor meerdere landen.',
	DASHBOARD_CHART_countByRoamingCountry_CELLULAR: 'Eindpunten per roamingland (mobiel)',
	DASHBOARD_CHART_countByRoamingCountry_CELLULARDescription: 'Toont het aantal eindpunten per roamingland.',
	DASHBOARD_CHART_countByRoamingCarrier_CELLULAR: 'Eindpunten per roamingprovider (mobiel)',
	DASHBOARD_CHART_countByRoamingCarrier_CELLULARDescription: 'Toont het aantal mobiele eindpunten per roamingprovider.',
	DASHBOARD_CHART_countByRoamingCarrier_SATELLITE: 'Eindpunten per roamingprovider (satelliet)',
	DASHBOARD_CHART_countByRoamingCarrier_SATELLITEDescription: 'Toont het aantal satellieteindpunten per roamingprovider.',

	SMS_HISTORY: 'Sms-geschiedenis',
	MAXIMUM_NUMBER_OF_CHARACTERS: 'Maximale aantal tekens: ',
	SEND_SMS_MESSAGE: 'Sms verzenden',
	SMS_NOTIFICATION_TITLE: 'Sms-melding',
	SMS_NOTIFICATION_MESSAGE: 'Responsfout: ',
	SMS_NOTIFICATION_MESSAGE_SUCCESS: 'Sms is verzonden',

	REVIEW: 'Controleren',
	PLATFORM_DETAILS: 'Gegevens platform',

	UPDATING: 'Updaten',
	AUTOMATION_RULE_SUCCESSFULLY_DELETED: 'Automatiseringsregel verwijderd',
	AUTOMATION_DELETE_ERROR: 'Kan automatiseringsregel niet verwijderen. Probeer het opnieuw.',
	FETCH_DATA_ERROR: 'Kan data niet ophalen',
	CHANGED_BY: 'Gewijzigd door',
	EMAIL_SUB: 'E-mail verzenden',
	CTD_USAGE: 'Dataverbruik in cyclus tot op heden',
	PAST24H_DATA_USAGE_EXCEEDED: 'Overschrijding dataverbruik in de afgelopen 24 uur',
	SMS_USAGE: 'Sms-verbruik in cyclus tot op heden',
	EXCEEDLIMIT: 'Overschrijdt',
	APPROACHLIMIT: 'Nadert',
	trigger: 'Trigger',
	TWENTYFOURHOURS: 'Afgelopen 24 uur',
	CTD: 'Cyclus tot op heden',
	ADD_RULE_SUCCESS: 'Regel gemaakt',
	ADD_RULE_ERROR: 'Kan automatiseringsregel niet maken. Probeer het opnieuw.',
	EDIT_RULE_SUCCESS: 'Regel bewerkt',
	EDIT_RULE_ERROR: 'Kan automatiseringsregel niet bewerken. Probeer het opnieuw.',
	ACC_AUTOMATION: 'Automatisering ACC',
	ACC_ACCOUNT_ID: 'Account-id ACC',
	CREATE_NEW_AUTOMATION_RULE: 'Nieuwe automatiseringsregel maken',
	DCP_AUTOMATION: 'Automatisering DCP',
	DCP_ACCOUNT_ID: 'Account-id DCP',
	ACC_ACCOUNT: 'ACC-account',
	RULE: 'Regel',
	ACC_IF: 'Als',
	TRESHOLD: 'Drempel',
	THEN: 'Dan',
	ACC_RULE_NAME: 'Naam regel',
	SAVE_AND_ACTIVATE: 'Opslaan en activeren',
	DCP_ACCOUNT: 'DCP-account',
	SELECT_BY: 'Selecteren op',
	DCP_BYTES_SENT: 'verzonden bytes',
	SMSES_SENT: 'verzonden sms-berichten',
	SMSES: 'Sms-berichten',
	DASHBOARD_FEEDBACK: 'Dashboard',
	MUST_BE_BETWEEN: 'Moet liggen tussen ',
	COMPANIES_STEPS: 'Stappen bedrijf',
	USER_NAME: 'GEBRUIKERSNAAM',
	ID_DASHBOARD_CHARTS_countofmonthlyendpointsmsbyplatformdcp: 'Sms per maand',
	ID_DASHBOARD_CHARTS_countofdailyendpointsmsbyplatformdcp: 'Sms per dag',
	ID_DASHBOARD_CHARTS_sumofdailyendpointusagebyplatformdcp: 'Totaal dagelijks verbruik eindpunt',
	ID_DASHBOARD_CHARTS_sumofdailyendpointusagebyplatformacc: 'Dagelijks dataverbruik',
	ID_DASHBOARD_CHARTS_sumofmonthlyendpointusagebyplatformdcp: 'Maandelijks dataverbruik',
	DASHBOARD_CHART_DeviceType_SATELLITE: 'Type apparaat (satelliet)',
	DASHBOARD_CHART_DeviceType_CELLULAR: 'Type apparaat (mobiel)',
	DASHBOARD_CHART_DeviceType_SATELLITEDescription: 'Toont het totale aantal apparaten van verschillende typen',
	DASHBOARD_CHART_DeviceType_CELLULARDescription: 'Toont het totale aantal apparaten van verschillende typen',
	AUTOMATION_ALERTS: 'Automatiseringswaarschuwingen',
	STATE: 'Status',
	COMPANY_EMAIL: 'Zakelijk e-mailadres',
	CUSTOM_FIELD_LABELS: 'Labels aangepast veld',
	MY_COMPANY: 'Mijn bedrijf',
	AUTOMATION_ACCOUNT: 'Account',
	UPDATE_CUSTOM_FIELD_LABELS_SUCCESS: 'Instellingen aangepaste labels opgeslagen',
	UPDATE_CUSTOM_FIELD_LABELS_ERROR: 'Kan instellingen aangepast label niet opslaan',
	WRITE_SMS_MESSAGE_HERE: 'Noteer hier uw bericht ...',
	RE_CREDENTIALING_ALLOWED: 'Hertoewijzing referenties niet toegestaan.',
	DELETE_AUTOMATION_RULE_QUESTION: 'Automatiseringsregel verwijderen?',
	DELETE_AUTOMATION_RULE_MESSAGE: 'Weet u zeker dat u de automatiseringsregel wilt verwijderen?',
	PLATFORM_SUCCESSFULLY_DELETED: 'Platform verwijderd',
	PLATFORM_DELETE_ERROR: 'Kan platform niet verwijderen. Probeer het opnieuw.',
	CELLULAR: 'Mobiel',
	SATELLITE: 'Satelliet',
	SAVE_CUSTOM_LABELS: 'Aangepaste labels opslaan',

	// ccip
	ENDPOINT_PROVISIONING_INFORMATION: 'Inrichtingsinformatie eindpunt',
	CCIP_3G: '3G',
	VOICE_ENABLED: 'Voice ingeschakeld',
	DATA_ENABLED: 'Data ingeschakeld',
	SMS_ENABLED: 'Sms ingeschakeld',
	VOICE_ROAMING_ENABLED: 'Voice-roaming ingeschakeld',
	DATA_ROAMING_ENABLED: 'Dataroaming ingeschakeld',
	CCIP_LTE: 'LTE',
	LTE_ENABLED: 'LTE ingeschakeld',
	ROAMING_ENABLED: 'Roaming ingeschakeld',
	MOBILE_NETWORK_ACCESS: 'Toegang tot mobiel netwerk',
	VOICE_ONLY: 'Alleen voice',
	REGISTERED_ON_NETWORK: 'Geregistreerd op netwerk',
	REGISTERED_ON_3G_NETWORK: 'Geregistreerd op 3G-netwerk',
	THIS_EMAIL_ADDRESS_IS_ALREADY_IN_USE: 'Dit e-mailadres is al in gebruik',
	THIS_EMAIL_ADDRESS_DOES_NOT_EXIST: 'Dit e-mailadres bestaat niet',
	USER_SUCCESSFULLY_DELETED: 'Gebruiker verwijderd',
	USER_DELETE_ERROR: 'Kan gebruiker niet verwijderen. Probeer het opnieuw.',
	CATEGORIES: 'Categorieën',
	TRIGGERS: 'Triggers',
	FETCH_DATA_LOADING_MESSAGE: 'Even geduld graag tot de data zijn geladen',
	M2M_ACCOUNT_ID_PLATFORM: 'Id M2M-account',
	M2MACCOUNTS: 'M2M-account',

	FROM_VALUE: 'VAN WAARDE',
	TO_VALUE: 'TOT WAARDE',

	SEARCH_REPORTS: 'In rapporten zoeken',
	SEARCH_INVOICES: 'In facturen zoeken',

	CUSTOM_DATA_FIELDS: 'Aangepaste datavelden',
	ENDPOINT_ACTIONS: 'Eindpuntacties voor',
	RE_CREDENTIAL_NOT_ALLOWED: 'Hertoewijzing referenties niet toegestaan',
	EDIT_ENDPOINT: 'Eindpuntacties',
	CURRENT_SIM_STATE_FOR_ENDPOINT_ID: 'Huidige SIM-status voor eindpunt-id',

	NETWORK_ENTITLEMENT_NAME: 'Naam netwerkrecht',
	BLOCK_PREMIUM_NUMBERS: 'Blokkeer 09XY- of 18XY-nummers',
	BLOCK_INTERNATIONAL_MO: 'Blokkeer internationaal SMS MO, uitgezonderd eigen land',
	HLR_TEMLATE: 'Sjabloon HLRHSS-gegevens',
	HOTLINE_NUMBER: 'Helpdesk-nummer',
	FACETIME: 'FACETIME',
	VOLTE: 'VoLTE',
	GDSP_SESSION_ACTIVE: 'Actief',
	GDSP_SESSION_BYTES_IN: 'Bytes in',
	GDSP_SESSION_BYTES_OUT: 'Bytes uit',
	GDSP_SESSION_LAST_IP_ADDRESS: 'Laatste IP-adres',
	GDSP_SESSION_LAST_STARTED_TIMESTAMP: 'Tijdstempel laatste keer gestart',
	GDSP_SESSION_LAST_UPDATED_TIMESTAMP: 'Tijdstempel laatste update',
	GDSP_SESSION_LAST_TERMINATED_TIMESTAMP: 'Tijdstempel laatste keer beëindigd',
	GDSP_SESSION_LAST_DATA_TRANSMITED_TIMESTAMP: 'Tijdstempel laatste dataoverdracht',
	GDSP_SESSION_PREVIOUS_NORMAL_TIMESTAMP: 'Vorige normale tijdstempel',
	GDSP_SESSION_LAST_SMS_MO_TIMESTAMP: 'Tijdstempel laatste SMS MO',
	GDSP_SESSION_LAST_SMS_MT_TIMESTAMP: 'Tijdstempel laatste SMS MT',
	GDSP_SESSION_LAST_SMS_MT_STATUS: 'Status laatste SMS MT',
	GDSP_SESSION_LAST_SMS_MO_STATUS: 'Status laatste SMS MO',
	GDSP_SESSION_LAST_WAKEUP_REQUEST_TIMESTAMP: 'Tijdstempel laatste ontwaakaanvraag',
	GDSP_SESSION_LAST_WAKEUP_REQUEST_TYPE: 'Type laatste ontwaakaanvraag',
	GDSP_SESSION_LAST_WAKEUP_STATUS: 'Laatste ontwaakstatus',
	GDSP_SESSION_LAST_WAKEUP_STATUS_TIMESTAMP: 'Tijdstempel laatste ontwaakstatus',
	GDSP_SESSION_LAST_NOTIFICATION_FAILURE_TIMESTAMP: 'Tijdstempel laatste mislukt melding',
	GDSP_SESSION_LAST_NOTIFICATION_FAILURE_REASON: 'Reden laatste melding mislukt',

	CUSTOM_FIELD_1: 'Aangepast veld 1',
	CUSTOM_FIELD_2: 'Aangepast veld 2',
	CUSTOM_FIELD_3: 'Aangepast veld 3',
	CUSTOM_FIELD_4: 'Aangepast veld 4',
	CUSTOM_FIELD_5: 'Aangepast veld 5',
	CUSTOM_FIELD_6: 'Aangepast veld 6',
	CUSTOM_FIELD_7: 'Aangepast veld 7',
	CUSTOM_FIELD_8: 'Aangepast veld 8',
	CUSTOM_FIELD_9: 'Aangepast veld 9',
	CUSTOM_FIELD_10: 'Aangepast veld 10',

	/* ----- GDSP ------- */
	REGISTRATION_STATUS: 'Status registratie',
	NETWORK_CONNECTIONS: 'Netwerkverbindingen',
	PROVISIONED_DATE_AND_TIME: 'Inrichtingsdatum en -tijd',

	// GDSP SIM STATE STATUS
	M2M_PLATFORM_STATUS_A: 'Actief live',
	M2M_PLATFORM_STATUS_B: 'Inactief nieuw',
	M2M_PLATFORM_STATUS_C: 'Actief slaapstand',
	M2M_PLATFORM_STATUS_D: 'Inactief gestopt',
	M2M_PLATFORM_STATUS_I: 'Gemigreerd van andere GDSP-omgeving',
	M2M_PLATFORM_STATUS_N: 'Niet ingericht',
	M2M_PLATFORM_STATUS_O: 'Gemigreerd naar andere GDSP-omgeving',
	M2M_PLATFORM_STATUS_R: 'Actief gereed',
	M2M_PLATFORM_STATUS_S: 'Actief opgeschort',
	M2M_PLATFORM_STATUS_T: 'Actief test',
	M2M_PLATFORM_STATUS_U: 'Actief stand-by',
	M2M_PLATFORM_STATUS_V: 'Inactief getransformeerd',
	M2M_PLATFORM_STATUS_W: 'Wettelijke opschorting',
	M2M_PLATFORM_STATUS_Z: 'Beëindigd',
	M2M_PLATFORM_STATUS_NOT_AVAILABLE: 'Niet beschikbaar',

	// GDSP Session tab Status Codes
	// lastWakeupStatus
	LASTWAKEUPSTATUS_IR: 'Ontwaakcommando ongeldig',
	LASTWAKEUPSTATUS_FI: 'Ontwaakinitiëring mislukt',
	LASTWAKEUPSTATUS_SI: 'Ontwaakinitiëring geslaagd',
	LASTWAKEUPSTATUS_F: 'Ontwaakaflevering mislukt',
	LASTWAKEUPSTATUS_S: 'Ontwaakaflevering geslaagd',
	TOOLTIP_LASTWAKEUPSTATUS_IR: 'Een ongeldig API-verzoek',
	TOOLTIP_LASTWAKEUPSTATUS_FI: 'Fout van de SMSC',
	TOOLTIP_LASTWAKEUPSTATUS_SI: 'Verzonden naar de SMSC',
	TOOLTIP_LASTWAKEUPSTATUS_F: 'SMSC heeft gemeld dat de aflevering is mislukt',
	TOOLTIP_LASTWAKEUPSTATUS_S: 'SMSC heeft gemeld dat de aflevering is geslaagd',

	// lastSmsMtStatus
	LASTSMSMTSTATUS_IR: 'Ontwaakcommando ongeldig',
	LASTSMSMTSTATUS_FI: 'Ontwaakinitiëring mislukt',
	LASTSMSMTSTATUS_SI: 'Ontwaakinitiëring geslaagd',
	LASTSMSMTSTATUS_F: 'Ontwaakaflevering mislukt',
	LASTSMSMTSTATUS_S: 'Ontwaakaflevering geslaagd',
	TOOLTIP_LASTSMSMTSTATUS_IR: 'Een ongeldig API-verzoek',
	TOOLTIP_LASTSMSMTSTATUS_FI: 'Fout van de SMSC',
	TOOLTIP_LASTSMSMTSTATUS_SI: 'Verzonden naar de SMSC',
	TOOLTIP_LASTSMSMTSTATUS_F: 'SMSC heeft gemeld dat de aflevering is mislukt',
	TOOLTIP_LASTSMSMTSTATUS_S: 'SMSC heeft gemeld dat de aflevering is geslaagd',

	/* ----- GDSP END ------- */

	/* API ERROR CODES  */

	CODE_400203: 'Er ontbreekt een vereiste parameter',
	CODE_400204: 'Een van de parameters heeft een onjuiste lengte',
	CODE_401205: 'Token ongeldig of verlopen',
	CODE_400207: 'Een van de parameters heeft geen geldige waarde uit de mogelijke waarde-opties',
	CODE_400208: 'Een van de parameters heeft een onjuiste indeling',
	CODE_400209: 'Dubbele parameter',
	CODE_400299: 'Onjuiste aanvraag',
	CODE_400301: 'Fouten van downstreamsystemen',
	CODE_401301: 'Fout in referenties downstreamsystemen',
	CODE_404301: 'Kan DeviceID niet vinden in downstreamsysteem',
	CODE_404302: 'AccountID in downstreamsysteem ongeldig of niet gevonden',
	CODE_502100: 'Wijziging is niet toegestaan, apparaat is al gepland',
	CODE_400100: 'Ongeldige aanvraag: batchactie niet toegestaan voor satellietapparaten',
	CODE_404201: 'Bestand bestaat niet',
	CODE_401202: 'Gebruikersnaam of wachtwoord is ongeldig',
	CODE_400206: 'Indeling bestandsnaam is ongeldig',
	CODE_502200: 'Fout batchbestand',
	CODE_502202: 'Databasefout tijdens ophalen',
	CODE_502203: 'Databasefout tijdens het aanbrengen van wijzigingen',
	CODE_502204: 'Fout tijdens maken van klant',
	CODE_502301: 'Fout tijdens verwerken PUSH-API',
	CODE_404303: 'Kan DeviceID niet vinden in PUSH-API-melding',
	CODE_502501: 'Fout tijdens verwerken SFTP-rapporten',
	CODE_undefined: ' ',
	CODE_INTEGRATION_TIMEOUT: 'Kan het onderliggende platform niet bereiken. Probeer het later opnieuw.',
	CODE_VTM_SVC_40003: 'The entered value was not recognized',
	CODE_VTM_SVC_40004: 'Unable to create the support ticket',
	CODE_VTM_SVC_40005: 'Unable to create the support ticket',
	CODE_VTM_SVC_40409: 'Ticket does not exist: It may have been removed due to AT&T data retention policy',
	CODE_VTM_SVC_40411: 'Ticket does not exist or it is closed by an agent: It may have been removed due to AT&T data retention policy',
	CODE_VTM_SVC_40027: 'Cannot update canceled or closed support ticket: This ticket is closed or canceled and cannot be updated.',
	CODE_VTM_SVC_40035: 'Unable to add engagement: Engagement ticket already exist',
	CODE_VTM_SVC_50001: 'Something went wrong: Try again. If the problem persists, please report your issue by calling AT&T',
	CODE_VTM_SVC_40038: 'Unable to update the support ticket: Circuit details are the same',
	CODE_VTM_SVC_40039: 'Cannot create the support ticket: Asset info is missing in the system',
	CODE_VTM_SVC_40040: 'Cannot add a PVC value to support ticket: pvcID details already exist in the system',
	CODE_VTM_SVC_40041: 'Unable to update support ticket: clkNumber does not exist',
	CODE_VTM_SVC_40042: 'Unable to update support ticket: pvcID does not exist',
	CODE_VTM_SVC_40043: 'Unable to retrieve ticket history: Please try again later',
	CODE_VTM_SVC_40010: 'Unable to create the support ticket: Field exceeded maximum of 199 characters. Please correct the length and try again',
	CODE_VTM_SVC_40053: 'Unable to create support ticket: Case ID does not have Ticket Role defined. or is invalid support role',
	CODE_EOD_11: 'Unable to process the request: Please try again in a few minutes.',
	CODE_EOD_59: 'Cannot deactivate device without static IP: Before a device can be deactivated (put on hold), it needs to have a static IP. You can still deactivate this device by deprovisioning it (purge) from the system.',
	CODE_EOD_60: 'Cannot deactivate inactive device',
	CODE_EOD_46: 'The SIM is already in the provisioning queue',
	CODE_EOD_30: 'The SIM cannot be suspended using a past date',
	CODE_EOD_32: 'Internal system error: Contact customer support or create a trouble ticket to get help with this issue',
	CODE_EOD_41: 'Cannot change profile for suspended devices: Contact customer support or create a trouble ticket to get help with this issue',
	CODE_EOD_45: 'Invalid SIM or SIM does not belong to your account: Contact customer support or create a trouble ticket to get help with this issue',
	CODE_EOD_16: 'SIM has reached the maximum number of Rate Plan changes allowed during the Billing Cycle: Could not complete the Rate Plan change request. Please try again in the next billing cycle.',
	CODE_EOD_17: 'SIM has reached the maximum number Rate Plan changes allowed for the day: Could not complete the Rate Plan change request. Please try again tomorrow.',
	CODE_EOD_25: 'The device is currently in a suspended state and so cannot resume before the suspended date',
	CODE_EOD_27: 'The SIM cannot be suspended using a past date',
	CODE_EOD_3: 'Device is already inactive',
	CODE_EOD_401: 'Unable to authorize user performing the batch action with device platform.',

	/* API ERROR CODES  */

	SERVICE_STATUS: 'Status 360L',
	SERVICESTATUS: 'Status 360L',
	OEM: 'Subaccounts',
	ROAMING_COUNTRY: 'Roamingland',
	ROAMING_CARRIER: 'Roamingprovider',
	ROAMINGCOUNTRY: 'Roamingland',
	ROAMINGCARRIER: 'Roamingprovider',
	OEM_TOOLTIP: 'Gekoppelde 360L-accounts',
	SIM_STATE: 'SIM-status',

	NO_DATA_ENDPOINT_HISTORY: 'Geen eindpuntgeschiedenis',
	PRINT_PDF: 'Naar PDF printen',
	USER_GUIDE: 'Gebruikershandleiding',
	USER_GUIDE_MENU: 'Menu Gebruikershandleiding',
	FAQ_MENU: 'Menu Veelgestelde vragen',
	TABLE_OF_CONTENTS: 'Inhoud',
	RATE_PLAN_TYPE: 'Type tariefplan',
	SUBSCRIPTION_PRICE: 'Abonnementsprijs',
	ACCOUNT_CHARGE: 'Accountkosten',
	PAYMENT_TYPE: 'Type betaling',
	TERM: 'Tijdvak',
	USAGE_LIMIT: 'Verbruikslimiet',
	TIERING_FLAG: 'Vlag opslaglagen',

	RATE_PLAN_CHANGE: 'Wijziging tariefplan',
	RATE_PLAN_CHANGED_TO: 'U hebt ervoor gekozen om het tariefplan te wijzigen in',
	SCHEDULE_RATE_PLAN_CHANGE: 'Wijziging tariefplan plannen',
	RATE_PLAN_CHANGE_IS_SUCCESSFULLY_SCHEDULED_ERROR: 'Kan wijziging tariefplan niet plannen!',
	RATE_PLAN_CHANGE_IS_SUCCESSFULLY_SCHEDULED_FOR: 'Wijziging tariefplan is gepland voor',
	RATE_PLAN_IS_CHANGED_TO: 'Status tariefplan is gewijzigd in ',
	FAILED_TO_PROVISION_AFTER_RATE_PLAN_CHANGE: ' kon niet worden ingericht na wijziging tariefplan. ',
	SEND_EMAIL_TO_CONSUMER: 'E-mail naar consument verzenden',
	PUSH_API: 'Push-API',
	INITIATE_OTA_SWAP: 'OTA-omwisseling initiëren',
	UPDATE_PCRF: 'PCRF updaten',
	CHANGE_DEVICES_NETWORK_ENTITLEMENT: 'Netwerkrecht van apparaat wijzigen',
	CHANGE_DEVICES_SIM_STATE: 'SIM-status van apparaat wijzigen',
	CHANGE_DEVICES_RATE_PLAN: 'Tariefplan van apparaat wijzigen',
	NUMBER_OF_DAYS: 'Aantal dagen',
	NUMBER_OF_HOURS: 'Aantal uur (max 24 uur)',
	AT: 'Om',
	FILTER: 'FILTER',
	FOLLOW_UP_WITH: 'Follow-up met',
	CUSTOM: 'Aangepast',
	ELIGIBLE_RATE_PLAN: 'Geschikt tariefplan',
	CHOOSE_RATE_PLAN: 'Tariefplan kiezen',
	CUSTOMER_ID: 'Klant-id',
	INVALID_URL_FORMAT: 'Ongeldige URL-indeling',
	INVALID_PORT_NUMBER: 'Ongeldig poortnummer',
	INVALID_INPUT: 'Ongeldige input',
	RATE_PLAN_CHANGE_SUPPORT: 'Fout in tariefplan, neem contact op met klantenservice.',
	NETWORK_ENTITLEMENT_PLACEHOLDER: 'XYX GPRS/LTE/SMS/IMS RES 165',
	M2M_SUB_ACCOUNT_ID: 'M2M-subaccount-id',

	/* SIM ORDERING */
	SIM_ORDERING: 'SIM-bestelling',
	CREATE_SIM_ORDER: 'SIM-bestelling maken',
	NEW_SIM_ORDER: 'Nieuwe bestelling',
	EXISTING_SIM_ORDER: 'Bestellings-id: {id}',
	M2M_ACCOUNT: 'M2M-account',
	M2M_ACCOUNT_PLACEHOLDER: 'Selecteer uw M2M-account',
	ORDER_ID: 'Bestellings-id',
	ORDER_NUMBER_ID: 'Bestelnummer-id',
	ORDER_CONFIRMATION_NUMBER: 'Bevestigingsnummer bestelling',
	ORDER_DATE: 'Datum bestelling',
	PURCHASE_ORDER_NUMBER: 'Inkoopordernummer',
	SKU: 'SKU',
	SKU_PLACEHOLDER: 'SKU selecteren',
	UNIT_PRICE: 'Prijs per stuk',
	QUANTITY: 'Aantal',
	QUANTITY_LIMIT_TEMPORARILY_REACHED: 'Limiet aantal tijdelijk bereikt',
	MIN_MAX_QUANTITY: 'Min. {min} / Max. {available} {max}',
	INCREMENT_QUANTITY: 'Toename {increment}',
	ORDER_STATUS: 'Status bestelling',
	ORDER_STATUS_PENDING: 'In behandeling',
	ORDER_STATUS_RECEIVED: 'Ontvangen',
	ORDER_STATUS_SHIPPED: 'Verzonden',
	ORDER_STATUS_COMPLETED: 'Voltooid',
	ORDER_STATUS_SUCCESSFUL: 'Geslaagd',
	ORDER_STATUS_FAILED: 'Mislukt',
	CONTACT_NAME: 'Naam contactpersoon',
	TELEPHONE_NUMBER: 'Telefoonnummer',
	TARGET_IMPLEMENTATION_DATE: 'Streefdatum implementatie',
	EXPEDITED: 'Spoed',
	SHIPPING_ADDRESS: 'Verzendadres',
	ADDRESS_LINE_1: 'Adresregel 1',
	ADDRESS_LINE_2: 'Adresregel 2',
	SHIPPING_NOTES: 'Verzendnotities',
	SHIPPING_NUMBER: 'Verzendnummer',
	EXPEDITED_SHIPPING: 'Spoedverzending',
	TOTAL_PRICE: 'Totaalprijs',
	SUBMIT_ORDER_REQUEST: 'Bestelaanvraag verzenden',
	SUBMIT_ORDER_REQUEST_CONFIRMATION: 'Weet u zeker dat u deze bestelling wilt verzenden?',
	EDIT_ORDER_REQUEST: 'Bestelaanvraag bewerken',
	EDIT_ORDER_REQUEST_CONFIRMATION: 'Weet u zeker dat u de wijzigingen wilt opslaan?',
	SAVE_UPDATES: 'Updates opslaan',
	ADD_SIM_ORDER_SUCCESS: 'SIM-bestelling gemaakt',
	ADD_SIM_ORDER_ERROR: 'Kan SIM-bestelling niet maken. Probeer het opnieuw.',
	EDIT_SIM_ORDER_SUCCESS: 'SIM-bestelling bewerkt',
	EDIT_SIM_ORDER_ERROR: 'Kan SIM-bestelling niet bewerken. Probeer het opnieuw.',
	PO_TOOLTIP: 'Inkoopordernummer wordt gegenereerd nadat de SIM-bestelling is verzonden',

	FILTERS: 'Filters',
	FILTER_CATEGORY_STATUS: 'Status',
	FILTER_CATEGORY_PLATFORM: 'M2M-platform',
	FILTER_CATEGORY_SKU: 'SKU',
	FILTER_CATEGORY_EXPEDITED: 'Spoed',
	FILTER_VALUE_PENDING: 'In behandeling',
	FILTER_VALUE_RECEIVED: 'Ontvangen',
	FILTER_VALUE_SHIPPED: 'Verzonden',
	FILTER_VALUE_COMPLETED: 'Voltooid',
	FILTER_VALUE_YES: 'Ja',
	FILTER_VALUE_NO: 'Nee',

	SIM_ORDERING_TITLE: 'SIM-bestelling',
	SIM_ORDERING_SUBTITLE: 'Selecteer hieronder welke SIM’s besteld kunnen worden.',
	SIM_ORDERING_CONTRACT_RESTRAINTS: 'Contractbeperkingen',
	SIM_ORDERING_CONTRACT_RESTRAINTS_SUBTITLE: 'Stel de beperkingen in voor het SIM-bestellingsproces.',
	SIM_ORDERING_MIN_QUANTITY_ALLOWED: 'Min. toegestane aantal',
	SIM_ORDERING_MIN_QUANTITY_ALLOWED_PLACEOLDER: 'Min. aantal',
	SIM_ORDERING_MAX_QUANTITY_ALLOWED: 'Max. toegestane aantal',
	SIM_ORDERING_PER_PERIOD: 'Per periode',
	SIM_ORDERING_PER_PERIOD_PLACEHOLDER: 'Selecteer een tijdsperiode',
	SIM_ORDERING_MAX_QUANTITY_ALLOWED_PLACEOLDER: 'Max. aantal',
	SIM_ORDERING_SIM_SKU: 'SIM-SKU',
	SIM_ORDERING_SIM_SKU_PLACEHOLDER: 'Voer hier SIM-SKU in',
	SIM_ORDERING_UNIT_PRICE: 'Prijs per stuk',
	SIM_ORDERING_UNIT_PRICE_PLACEHOLDER: 'Prijs per stuk',
	SIM_ORDERING_ALLOWED_TO_ORDER: 'Mag worden besteld',
	SIM_ORDERING_SKU_DESCRIPTION: 'Beschrijving',
	SIM_ORDERING_SKU_DESCRIPTION_PLACEHOLDER: 'Voer SKU-beschrijving in',
	SIM_ORDERING_SKU_ADD_SKU: '+ Nog een SIM-SKU toevoegen',
	SIM_ORDERING_VALIDATOR_MAX_QUANTITY: 'max. aantal',
	SIM_ORDERING_VALIDATOR_MIN_QUANTITY: 'min. aantal',
	ADD_RESTRAINTS_SUCCESS: 'Contractbeperkingen toegevoegd',
	ADD_RESTRAINTS_ERROR: 'Kan contractbeperkingen niet toevoegen',
	UPDATE_RESTRAINTS_SUCCESS: 'Contractbeperkingen geüpdatet',
	UPDATE_RESTRAINTS_ERROR: 'Kan contractbeperkingen niet updaten',
	ADD_SIM_ORDERING_SKUS_SUCCESS: 'SKU’s van SIM-bestelling geüpdatet',
	ADD_SIM_ORDERING_SKUS_ERROR: 'Kan SKU’s van SIM-bestelling niet updaten',

	COMPANY_REPORTS: 'Rapporten',
	COMPANY_REPORTS_SETTINGS_SUCCESSFULLY_UPDATED: 'Instellingen bedrijfsrapporten opgeslagen',
	ERROR_WHILE_UPDATING_COMPANY_REPORTS: 'Fout tijdens updaten bedrijfsrapporten',
	ERROR_WHILE_FETCHING_COMPANY_REPORTS: 'Fout tijdens ophalen rapportconfiguratie',
	COMPANY_REPORTS_SUBTITLE: 'Selecteer hieronder welke rapporten u wilt ontvangen.',
	DAILY_REPORTS: 'DAGELIJKSE RAPPORTEN',
	MONTHLY_REPORTS: 'MAANDELIJKSE RAPPORTEN',
	PREFERENCES_MENU: 'Menu Voorkeuren',
	PREFERENCES: 'Voorkeuren',
	REFERENCE_REPORTS: 'Referentierapporten',
	M2M_DATE_ADDED: 'Datum M2M toegevoegd',
	M2M_INITIAL_ACTIVATION_DATE: 'Datum activering M2M',
	NOTES: 'Opmerkingen',
	SAVE_NOTE: 'Opmerking opslaan',
	NOTE_TEXTAREA_PLACEHOLDER: 'Noteer hier uw opmerking (max. 2000 tekens)',
	ADD_NOTE_SUCCESS: 'Opmerking opgeslagen',
	ADD_NOTE_ERROR: 'Kan opmerking niet opslaan. Probeer het opnieuw.',
	DELETE_NOTE_SUCCESS: 'Opmerking verwijderd',
	DELETE_NOTE_ERROR: 'Kan opmerking niet verwijderen. Probeer het opnieuw.',
	DELETE_NOTE: 'Opmerking verwijderen',
	TICKETING_STATUS: 'Ticketstatus',
	UPDATE_NOTE_STATUS: 'Status opmerking updaten',
	LAST_UPDATE: 'Laatste update',
	SUBMISSION_LOCATION: 'Verzendlocatie',
	RULE_STATE: 'Status regel',
	RULE_STATE_ACTIVE: 'Actief',
	YES: 'Ja',
	NO: 'Nee',
	// ANALYTICS
	CHART_CATEGORIES: 'Grafiekcategorieën',
	GROUP_DATA_BY: 'Data groeperen op',
	CHART_OPTIONS: 'Opties grafiek',
	CREATE_NEW_CHART: 'Nieuwe grafiek maken',
	EDIT_CHART: 'Grafiek bewerken',
	DISCLAIMER: 'Disclaimer: ',
	DATE_RANGE: 'DATUMBEREIK',
	SHOW: 'WEERGEVEN',
	CYCLE: 'CYCLUS',
	INCREMENT_BY: 'VERHOGEN MET',
	SELECT_CUSTOM_DATE_RANGE: 'Aangepast datumbereik selecteren',

	// Billing
	INVOICE: 'Factuur',
	INVOICE_DATE: 'Factuurdatum',
	CUSTOMER_NAME: 'Naam klant',
	DOCUMENT_ID: 'Document-id',
	COUNTRY_NAME: 'Naam land',
	TABLE_COLUMNS_INVOICES_CHANGE_SUCCESS_TITLE: 'Instellingen factuurtabel zijn gewijzigd!',
	TABLE_COLUMNS_INVOICES_CHANGE_SUCCESS_MESSAGE: 'De instellingen van de factuurtabel zijn geüpdatet.',
	TABLE_COLUMNS_INVOICES_CHANGE_ERROR_TITLE: 'Fout tijdens kolomwijziging factuurtabel!',
	TABLE_COLUMNS_INVOICES_CHANGE_ERROR_MESSAGE: 'Kan de kolommen van de factuurtabel op dit moment niet updaten. Probeer het opnieuw.',
	INVOICE_DETAILS: 'Factureringsgegevens',

	// TICKETING
	TICKET_CREATED_DATE: 'Ticket gemaakt op',
	UPDATED_NOTE_STATUS_DATE: 'Datum update opmerkingstatus',
	LAST_UPDATED_DATE: 'Datum laatste update',
	UPDATE_NOTE: 'Opmerking updaten',
	TABLE_TICKETS_COLUMNS_CHANGE_SUCCESS_TITLE: 'Instellingen tickettabel zijn gewijzigd!',
	TABLE_TICKETS_COLUMNS_CHANGE_SUCCESS_MESSAGE: 'De instellingen van de tickettabel zijn geüpdatet.',
	TABLE_TICKETS_COLUMNS_CHANGE_ERROR_TITLE: 'Fout tijdens kolomwijziging tickettabel!',
	TABLE_TICKETS_COLUMNS_CHANGE_ERROR_MESSAGE: 'Kan de kolommen van de tickettabel op dit moment niet updaten. Probeer het opnieuw.',

	TODAY: 'Vandaag',
	PREVIOUS_DAY: 'Vorige dag',
	LAST_10_DAYS: 'Afgelopen 10 dagen',
	LAST_30_DAYS: 'Afgelopen 30 dagen',
	CUSTOM_RANGE: 'Aangepast bereik',
	CURRENT_CYCLE_TO_DATE: 'Huidige cyclus tot op heden',
	HISTORICAL: 'Historisch',
	WEEK: 'Week',
	SUM_TOTALS: 'Totalen',
	AVERAGE_TOTALS: 'Gemiddelde totalen',
	LINE_GRAPH: 'Lijngrafiek',
	BAR_GRAPH: 'Staafdiagram',
	WORLD_MAP_GRAPH: 'Wereldkaartgrafiek',
	TOTAL_DATA_USAGE: 'Totaal dataverbruik',
	ENDPOINT_COUNT_TOTALS: 'Totale aantal eindpunten',
	NO_USAGE_SIMS: 'SIM’s zonder verbruik',
	TOTAL: 'Totaal',
	AVERAGE_USAGE: 'Gemiddeld',
	DIRECTION_INDEX: 'Indexrichting',
	PIE_CHART: 'Cirkeldiagram',
	ERROR_WHILE_ADDING_TICKET: 'Fout tijdens toevoegen ticket',

	SELECT_M2M_PLATFORM: 'Selecteer uw M2M-platform',
	SELECT_M2M_PLATFORM_ERROR: 'Selecteer eerst een M2M-platform om data automatisch in te vullen',
	SELECT_YOUR_ACCOUNT: 'Selecteer uw account',
	CHOOSE_M2M_PLATFORM: 'Kies uw M2M-platform',
	CHOOSE_YOUR_ACCOUNT: 'Kies uw account',

	TOTAL_VOICE_USAGE: 'Totaal voice-verbruik',
	TOTAL_SMS_USAGE: 'Totaal sms-verbruik',
	SAVE_AND_CLOSE: 'Opslaan en sluiten',
	// Security Features
	SECURITY_TABS_1: 'Whitelist IMEI',
	SECURITY_TABS_2: 'Whitelist sms',
	SECURITY_TABS_3: 'Whitelist voice',
	SECURITY_TABS_4: 'Blacklist IP, URL, POORT',
	SECURITY_TABS_5: 'Whitelist IP-bereik',
	SECURITY_FEATURES_1: 'IMEI',
	SECURITY_FEATURES_2: 'Sms',
	SECURITY_FEATURES_3: 'VOICE',
	SECURITY_FEATURES_4: 'IP, URL, POORT',
	SECURITY_FEATURES_5: 'IP-BEREIK',
	SECURITY_FEATURES_SUCCESSFULLY_ADD: 'Data gewijzigd',
	SECURITY_FEATURES_ERROR_ADD: 'Fout tijdens updaten data',
	SECURITY_FEATURES_ADD_1: 'Nieuwe IMEI aan de whitelist toevoegen',
	SECURITY_FEATURES_ADD_2: 'Nieuwe sms aan de whitelist toevoegen',
	SECURITY_FEATURES_ADD_3: 'Nieuwe voice aan de whitelist toevoegen',
	SECURITY_FEATURES_ADD_4: 'Nieuwe IP, URL, POORT aan de blacklist toevoegen',
	SECURITY_FEATURES_ADD_5: 'Nieuw IP-bereik aan de whitelist toevoegen',
	SECURITY_FEATURES_EDIT_1: 'Whitelist IMEI bewerken',
	SECURITY_FEATURES_EDIT_2: 'Whitelist sms bewerken',
	SECURITY_FEATURES_EDIT_3: 'Whitelist voice bewerken',
	SECURITY_FEATURES_EDIT_4: 'Blacklist IP, URL, POORT bewerken',
	SECURITY_FEATURES_EDIT_5: 'Whitelist IP-bereik bewerken',
	SECURITY_FEATURES_M2M: 'M2M-platform selecteren',
	SECURITY_FEATURES_REMOVE: 'Van whitelist verwijderen',
	UPDATE: 'Updaten',
	SECURITY_FEATURES: 'Beveiligingsfuncties',
	M2M_ACCOUNT_NAME: 'NAAM M2M-ACCOUNT',
	DATA_SUBMITTED: 'DATA VERZONDEN',
	FILTER_CATEGORY_M2MACCOUNT: 'M2M-account',
	SECURITY_FEATURES_SUCCESSFULLY_EXPORT: 'Geëxporteerd',
	SECURITY_FEATURES_FAIL_EXPORT: 'Exporteren mislukt',
	COUNTRYCODE: 'Land',
	MINUTE: 'Minuut',
	MINUTES: 'Minuten',
	CHART_LEFT_AXIS_MINUTE: 'min',
	CHART_LEFT_AXIS_MINUTES: 'min',
	CONSUMED_MINUTE: 'Verbruikte minuten',
	CONSUMED_MB: 'Verbruikte megabytes',
	CONSUMED_GB: 'Verbruikte gigabytes',
	CONSUMED_TB: 'Verbruikte terabytes',
	CONSUMED_PB: 'Verbruikte petabytes',
	CONSUMED_SMS: 'Verbruikte sms',
	NO_DESCRIPTION_FOR_GRAPH: 'Geen beschrijving voor deze grafiek',
	GRAPHS: 'Huidige beschikbare grafieken',
	CONSUMED_COUNT: 'Aantal eindpunten',
	DOWNLOAD_CHART_AS_PNG: 'PNG downloaden',
	CUSTOMER_INVOICE_ID: 'Klant-id',
	INVOICE_ID: 'Factuur-id',
	CUSTOMER_INVOICE_NAME: 'Subaccount',
	INVOICE_COUNTRY: 'Land',
	ACCESSTECHNOLOGY: 'Toegangstechnologie',
	RATINGZONE: 'Tariefzone',
	RATEPLAN: 'Tariefplan',
	CHOOSE_FILTERS_ON_ANALYTICS_MODAL: 'Selecteer een categorie in de eerste stap',
	NO_FILTERS_ON_ANALYTICS_MODAL: 'Er zijn geen filters beschikbaar voor deze categorie',
	BILLING_CYCLE: 'Factureringscyclus',
	FETCHING_DATA: 'Even geduld graag. Ophalen data ...',
	NO_DATA_RECEIVED: 'Geen data ontvangen',
	NO_FILTERS_AVAILABLE: 'Geen filters beschikbaar',
	DATE_SUBMITTED: 'Datum verzonden',
	NO_REPORTS_DEFINED: 'Geen rapporten gedefinieerd',
	APPLY: 'Toepassen',
	OPEN_CHART_SETTINGS_PAGE: 'Instellingen grafiek',
	CUSTOMIZE_CHART_DATA: 'Grafiekdata aanpassen',
	ESTIMATED_AVERAGE_USAGE: 'Geschat gemiddeld verbruik',
	DELETE_DEFINED_CHART_SETTING_QUESTION: 'Gedefinieerde grafiekinstelling verwijderen?',
	DELETE_DEFINED_CHART_SETTING_MESSAGE: 'Weet u zeker dat u de instelling wilt verwijderen?',
	TOTAL_COUNT_BY_SEVERITY: 'Tickets op ernst',
	TOTAL_COUNT_BY_STATUS: 'Tickets op status',
	INVALID_IP_ADDRESS: 'Ongeldig IP-adres',
	INVOICENUMBER: 'Factuurnummer',
	CUSTOMERNAME: 'Naam klant',
	COUNTRYNAME: 'Naam land',
	CUSTOMERID: 'Klant-id',
	DEVICE_WAKE_UP: 'Apparaat ontwaken',
	DEVICE_WAKE_UP_NOTIFICATION_TITLE: 'Apparaat ontwaken',
	DEVICE_WAKE_UP_NOTIFICATION_MESSAGE_SUCCESS: 'Apparaat is ontwaakt',
	DEVICE_WAKE_UP_NOTIFICATION_MESSAGE_FAIL: 'Apparaat ontwaken mislukt',

	FILES_FAILED_TO_UPLOAD_MESSAGE: 'Kan de volgende bestanden niet uploaden: ',
	ADD_ANOTHER_TRESHOLD: 'Nog een drempel toevoegen',
	PIE_CHART_TOTAL: 'Totaal',
	ADD_ATTACHMENT: 'Bijlage toevoegen',
	TICKET_FILE_UPLOAD_ERROR: 'Er is een fout opgetreden in het back-endsysteem, waardoor sommige bestanden niet zijn geüpdatet.',
	FILE_TO_LARGE_TO_UPLOAD: 'Bestand is te groot om te uploaden. Maximale grootte voor bestanden is 3 MB.',
	RETRY: 'Opnieuw proberen',
	NO_CATEGORIES: 'Er zijn geen categorieën beschikbaar voor deze account',
	ZONE_NAME: 'Naam zone',
	POOL_ID: 'Pool-id',

	// NOTES INVOICES
	ADD_NEW_NOTE: 'Opmerking toevoegen',
	EDIT_NOTE: 'Opmerking bewerken',
	ADD_NOTE_PLACEHOLDER: 'Noteer hier uw opmerking',
	INVOICE_NOTE_SUCCESSFULLY_UPDATED: 'Factuuropmerking is geüpdatet',
	INVOICE_NOTE_UPDATE_FAIL: 'Update factuuropmerking is mislukt',
	ATTACHMENTS_ADDED: 'Bijlagen toegevoegd',
	EXTERNAL_LAST_UPDATED_BY: 'Laatste update door',
	EXTERNAL_LAST_UPDATED_DATE: 'Datum/tijd laatste update',
	REMOVE: 'Verwijderen',
	EndpointStatus: 'Status',
	NetworkOperator: 'Netwerkoperator',
	NetworkCountry: 'Land netwerk',
	NetworkType: 'Type netwerk',
	TransferStatus: 'Status IMSI-overdracht',
	NetworkEntitlement: 'Netwerkrecht-id',
	RecredentialTransferStatus: 'Status hertoewijzing referenties',
	M2MDateAdded: 'Datum M2M toegevoegd',
	M2MInitialActivationDate: 'Datum activering M2M',
	LAST_UPDATED_BY: 'Laatste update door',
	LAST_UPDATED: 'Laatste update',
	INVOICE_NOTE_FILE_ERROR: 'Er is een fout opgetreden in het back-endsysteem, waardoor sommige bestanden niet zijn geüpload.',

	BATCH_HISTORY: 'Batchgeschiedenis',
	// DIAGNOSTIC HLRHSSDetails
	GET_HLR_HSS_DETAILS: 'HLR/HSS-gegevens ophalen',
	APN_IDENTIFIER: 'APN-id',
	IP_ADDRESS_TYPE: 'Type IP-adres',
	QOS_PROFILE: 'QoS-profiel',
	PDN_GW_DYNAMIC_ALLOCATION: 'Dynamische toewijzing PDN GW',
	VPLMN_ADDRESS_ALLOWED: 'VPLMN-adres toegestaan',
	MAX_UPLOAD: 'Max. upload',
	MAX_DOWNLOAD: 'Max. download',
	SUBSCRIBER_PROFILE: 'Profiel abonnee',
	ENDPOINT_ID_ICCID: 'Eindpunt-id - ICCID',
	BARRING_STATUS: 'Blokkeerstatus',
	NETWORK_ACCESS: 'Netwerktoegang',
	CURRENT_VLR_NUMBER: 'Huidig VLR-nummer',
	PREVIOUS_VLR_NUMBER: 'Vorig VLR-nummer',
	CURRENT_SGSN_NUMBER: 'Huidig SGSN-nummer',
	PREVIOUS_SGSN_NUMBER: 'Vorig SGSN-nummer',
	ROAMING_RESTRICTION: 'Roamingbeperking',
	DATA_3G_ENABLED: '3G-data ingeschakeld',
	NETWORK_STATUS: 'Netwerkstatus',
	APN_LIST: 'APN-lijst',

	// DATA HISTORY
	DATA_HISTORY: 'Datageschiedenis',
	DATA_VOLUME_DOWN_KB: 'Datavolume downlink (KB)',
	DATA_VOLUME_UP_KB: 'Datavolume uplink (KB)',
	DURATION_MINUTES: 'Duur (minuten)',
	SESSION_CHARGING_ID: 'Kosten-id sessie',
	ACCESS_POINT_NAME: 'APN',
	DEVICE_IP_V4: 'IPv4-adres apparaat',
	DEVICE_IP_V6: 'IPv6-adres apparaat',
	RATING_ZONE_NAME: 'Naam tariefzone',
	RATING_GROUP_ID_NAME: 'Naam tariefgroep-id',
	RATING_GROUP_ID: 'Tariefgroep-id',

	// SMS HISTORY WING
	MESSAGE_HISTORY: 'Berichtgeschiedenis',
	PORTAL_MESSAGES: 'Portalberichten',
	SMS_LOG_ID: 'Logboek-id sms',
	DATE_SENT_RECEIVED: 'Verzend/ontvangstdatum',
	SENT_FROM: 'Verzonden van',
	SENT_TO: 'Verzonden naar',
	MESSAGE_TYPE: 'Type bericht',
	ZONE_MODEL: 'Model zone',

	CTD_DATA_USAGE: 'Dataverbruik CTD (megabytes)',
	CTD_SMS_MO_MT_USAGE: 'Sms-verbruik CTD (berichten)',
	CTD_VOICE_USAGE: 'Voice-verbruik CTD (minuten)',

	ACCOUNTS: 'Accounts',
	MUST_BE_BETWEEN_CHARACTERS_LONG: 'Moet tussen {min} en {max} tekens bevatten',
	INVALID_DOMAIN_FORMAT: 'Ongeldige domeinindeling',
	LAST_UPDATE_DATE_TIME: 'Datum/tijd laatst geüpdatet',
	LAST_UPDATED_BY_USER: 'Laatste update door gebruiker',
	INSESSION: 'In sessie',
	PLEASE_WAIT_THIS_MAY_TAKE_A_FEW_MINUTES: 'Even geduld. Dit kan een paar minuten duren, afhankelijk van de bestandsgrootte.',
	NO_RESULTS: 'Geen resultaten',
	SELECTED_RATE_PLAN: 'Geselecteerd tariefplan',
	HSPA: 'HSPA',
	LTE: 'LTE',
	VLR: 'VLR',
	SGSN: 'SGSN',
	EXPORT: 'Exporteren',
	FILE: 'Bestand',
	ERROR_CODE: 'Foutcode',
	ERROR_TYPE: 'Type fout',
	AT_LINE: 'Op regel',
	EXPORT_TICKETS: 'Tickets exporteren',
	EXPORT_USER_USAGE_DATA: 'Gebruiksgegevens van gebruikers exporteren',
	PLACEHOLDER_MODEL: 'AT&T Z431',
	NO_BATCH_ACTIONS_AVAILABLE: 'Geen batchacties beschikbaar',
	NO_BATCH_ACTIONS_AVAILABLE_SUBTITLE: 'Er zijn momenteel geen batchacties voor deze tabel om te laten zien',
	NO_BATCH_REPORTS_AVAILABLE: 'Geen batchrapporten beschikbaar',
	NO_BATCH_REPORTS_AVAILABLE_SUBTITLE: 'Er zijn momenteel geen batchrapporten voor deze tabel om te laten zien',
	MOBILE_ORIGINATING: 'Van mobiel',
	MOBILE_TERMINATING: 'Naar mobiel',
	NA: 'N.v.t.',
	GMNA: 'GM Noord-Amerika',
	POD3: 'ACC Pod3',
	POD19: 'Onderneming',
	TABS_ADD_ICON: 'Aangepaste eindpunttabel maken',
	USAGE_SUMMARIES: 'Overzicht verbruik',
	EMPTY_SPACE: ' ',
	// endpoints rate plans
	'ENDPOINTS_RATE_PLAN.RATE_PLAN_TYPE': 'Type tariefplan',
	'ENDPOINTS_RATE_PLAN.INCLUDED_TEST_DATA': 'Datapakket test',
	'ENDPOINTS_RATE_PLAN.BILLING_PLATFORM': 'Factureringsplatform',
	'ENDPOINTS_RATE_PLAN.ACTIVATION_FEE': 'Activeringskosten',
	'ENDPOINTS_RATE_PLAN.INCLUDED_TEST_SMS': 'Sms-pakket test',
	'ENDPOINTS_RATE_PLAN.BILLING_ACCOUNT_ID': 'Factureringsaccount-id',
	'ENDPOINTS_RATE_PLAN.MSU_PRICE': 'Prijs MSU',
	'ENDPOINTS_RATE_PLAN.INCLUDED_TEST_VOICE': 'Voice-pakket test',
	'ENDPOINTS_RATE_PLAN.ZONE_MODEL_ID': 'Model-id zone',
	'ENDPOINTS_RATE_PLAN.TYPE': 'Type',
	'ENDPOINTS_RATE_PLAN.INCLUDED_MSU': 'MSU-bundel',
	'ENDPOINTS_RATE_PLAN.VALID_FROM': 'Geldig vanaf',
	'ENDPOINTS_RATE_PLAN.DEFAULT_RATE_PLAN': 'Standaard tariefplan',
	'ENDPOINTS_RATE_PLAN.BASE_RATE_PLAN': 'Basistariefplan',
	'ENDPOINTS_RATE_PLAN.POSTPAID': 'Betaling achteraf',
	'ENDPOINTS_RATE_PLAN.APPROVED': 'Goedgekeurd',
	'ENDPOINTS_RATE_PLAN.MONTHLY': 'MAANDELIJKS',
	PASSWORDS_DO_NOT_MATCH: 'Wachtwoorden komen niet overeen',
	NO_WHITE_SPACE_ONLY: 'Tekst mag geen letters en/of nummers bevatten',
	NO_RATE_PLAN_SELECTED: 'geen tariefplan geselecteerd',
	NO_RATE_PLANS_UNDERLYING_PLATFORM: 'Er is een fout op het onderliggende platform en Wijziging tariefplan is momenteel niet beschikbaar',
	ENDPOINT_PROVISIONED_STATE_CHANGE_SUCCESS: 'Eindpunt-id {args1} is ingericht. IMSI-status is gewijzigd in {args2}',
	ENDPOINT_PROVISIONED_STATE_CHANGE_FAIL: 'Kan eindpunt-id {args1} niet inrichten na IMSI-statuswijziging. Ondersteuning vragen',

	// AUTH SIGN IN CALLBACK
	AUTHENTICATION_FAILED: 'Verificatie mislukt',
	AUTHENTICATION_IN_PROGRESS: 'Verificatie in behandeling. Even geduld...',
	MEGABYTES: 'Megabytes',
	TICKETS: 'Tickets',
	USER_GROUPS: 'Gebruikersgroepen',
	RECENT_SESSIONS: 'Recente sessies',
	ACTIVE_SESSION_SESSION_START_TIME: 'BEGINTIJD SESSIE',
	ACTIVE_SESSION_TOTAL_VOLUME: 'TOTALE VOLUME',
	ACTIVE_SESSION_TRANSMITTING: 'WORDT OVERGEDRAGEN',
	ACTIVE_SESSION_RECEIVING: 'WORDT ONTVANGEN',
	CURRENTLY_ACTIVE_SESSION: 'Huidig aantal actieve sessies',
	ACTIVE_SESSION_CARRIER: 'PROVIDER',
	ACTIVE_SESSION_IP_ADDRESS: 'IP-ADRES',
	RESET_TO_DEFAULT: 'Standaardinstellingen herstellen',
	DATE_TIME: 'Datum/tijd',
	SESSION_END: 'Einde sessie',
	SEND: 'Verzenden',
	SEND_SMS_TO_THIS_DEVICE: 'Verzend een sms naar dit apparaat.',
	TYPE_A_MESSAGE: 'Typ een bericht dat u wilt verzenden naar dit apparaat via sms. Hiervoor kunnen standaardtarieven worden berekend.',
	MESSAGES_WILL_APPEAR: 'Bij berichten wordt weergegeven dat ze zijn verzonden en komen op sms-verbruiksrapporten te staan. Hiervoor kunnen tarieven worden berekend.',
	SENT_MESSAGES_HISTORY: 'Geschiedenis verzonden berichten',
	CHARACTERS_REMAINING: '{remaining} resterende tekens...',
	EXPORT_SESSIONS_DATA_FOR_ID: 'Recente sessies voor {id}',
	EXPORT_DATA: 'Data exporteren',
	BYTE_ABBREVIATION: 'B',
	UTC_TIME_ZONE: ' UTC ',
	SEND_SMS_TO_ENDPOINT: 'Sms verzenden naar eindpunt',
	SMS: 'Sms',
	OFFPEAK_USAGE_FLAG: 'Vlag verbruik buiten piektijden',
	DATA_USAGE_TOTAL: 'Dataverbruik - Onbewerkt totaal',
	DATA_USAGE_UPLINK: 'Dataverbruik - Onbewerkte uplink',
	DATA_USAGE_DOWNLINK: 'Dataverbruik - Onbewerkte downlink',
	DATA_USAGE_ROUNDED: 'Dataverbruik - Afgerond',
	DEVICE_IP_ADDRESS: 'IP-adres apparaat',
	SESSION_DURATION: 'Duur sessie',
	RECORD_SEQUENCE_NUMBER: 'Volgnummer record',
	CHARGING_ID: 'Kosten-id',
	SESSION_CLOSE_CAUSE: 'Sluitingsreden sessie',
	TECH_TYPE: 'Tech-type',
	PGW_ADDRESS: 'PGW-adres',
	TIME_OF_FIRST_USAGE: 'Tijd eerste verbruik',
	TIME_OF_LAST_USAGE: 'Tijd laatste verbruik',
	TIME_USAGE_DURATION: 'Tijd verbruiksduur',
	STREAM_ID: 'Stream-id',
	CELL_GLOBAL_IDENTIFIER: 'CGI (Cell Global Identifier)',
	SERVICE_AREA_IDENTIFIER: 'SAI (Service Area Identifier)',
	ROUTING_AREA_IDENTIFIER: 'RAI (Routing Area Identifier)',
	TRACKING_AREA_IDENTITY: 'TAI (Tracking Area Identity)',
	E_UTRAN_CGI: 'ECGI (E-UTRAN CGI)',
	LOCATION_AREA_IDENTIFIER: 'LAI (Location Area Identifier)',
	SERVING_SGSN: 'Uitvoerende SGSN',
	COUNTRY_LASTEST_SESSION: 'Land (laatste sessie)',
	CARRIER_LASTEST_SESSION: 'Provider (laatste sessie)',
	BILLABLE_FLAG: 'Vlag Factureerbaar',
	CYCLE_MONTH: 'Maand cyclus',
	CYCLE_YEAR: 'Jaar cyclus',
	ASSIGNED_RATE_PLAN_ID: 'Toegewezen tariefplan-id',
	ASSIGNED_RATING_ZONE_ID: 'Toegewezen tariefzone-id',
	RATE_PLAN_VERSION_ID: 'Versie-id tariefplan',
	SERVICE_TYPE: 'Type service',
	TRAFFIC_DETAIL_ID: 'Verkeersgegevens-id',
	RECORD_RECEIVED_DATE: 'Ontvangstdatum record',
	RECORD_OPEN_TIME: 'Open tijd vastleggen',
	OPERATOR_NETWORK: 'Operatornetwerk',
	OPERATOR_ACCOUNT_ID: 'Account-id operator',
	TAP_CODE: 'TAP-code',
	USAGE_HISTORY: 'Verbruiksgeschiedenis',
	DATA: 'Data',
	VOICE: 'Voice',
	HLR_HSS_DETAILS_FOR_ENDPOINT: 'HLR/HSS-gegevens voor eindpunt-id {id}',
	NEED_HELP: 'Hulp nodig?',
	MB: ' MB',
	CONNECTION_STATUS: 'Status verbinding',
	MESSAGE_CONTENT: 'Inhoud bericht',
	B: 'B',
	CYCLE_TO_DATE_USAGE_TOTAL: 'Verbruikstotaal cyclus tot op heden',
	AVERAGE_DAILY_USE: 'Gemiddeld dagelijks verbruik',
	DATA_USAGE_DOWNLINK_CHART: 'Downlink',
	DATA_USAGE_UPLINK_CHART: 'Uplink',
	GDSP: 'GDSP',
	SECOND: 'Seconde',
	SECONDS: 'Seconden',
	DURATION_RAW: 'Duur - Onbewerkt',
	DURATION_ROUNDED: 'Duur - Afgerond',
	USAGE_TYPE: 'Type Verbruik',
	SELECT_BILLING_CYCLE: 'Factureringscyclus selecteren',
	RANGE: 'Bereik',
	CUSTOM_FIELDS_VALUES_UPDATED: 'Waarden aangepast veld zijn geüpdatet',
	CUSTOM_FIELDS_VALUES_UPDATED_ERROR: 'Updaten waarden aangepast veld is mislukt',
	SXMACC: 'SXMACC',
	CHARTS_BILLING_CYCLE_ABBREVIATION: 'FC {billcycle}',
	DATE_RANGE_INPUT_LABEL: 'Vanaf: {startDate} Tot: {endDate}',
	UH_OH_THERE_WERE_SOME_PROBLEMS_TRYING_TO_LOG_YOU_IN_PLEASE_RETRY_AGAIN_IN_A_FEW_MOMENTS: 'Ojee, er zijn problemen opgetreden bij de poging om u aan te melden. Probeer het straks opnieuw. Neem contact op met het ondersteuningsteam als het probleem zich blijft voordoen.',

	//  LANGUAGE OPTIONS
	ENGLISH: 'English (USA)',
	FRANCAIS: 'Français (France)',
	ESPANOL: 'Español (España)',
	ITALIANO: 'Italiano (Italia)',
	NEDERLANDS: 'Nederlands (Nederland)',
	DEUTCHE: 'Deutsch (Deutschland)',
	PORTUGUES: 'Português (Brasil)',
	CHINESE: '中文 (中国)',

	//  COUNTRIES
	COUNTRY_UNITED_STATES: 'Verenigde Staten',
	COUNTRY_AFGHANISTAN: 'Afghanistan',
	COUNTRY_ALAND_ISLANDS: 'Åland',
	COUNTRY_ALBANIA: 'Albanië',
	COUNTRY_ALGERIA: 'Algerije',
	COUNTRY_AMERICAN_SAMOA: 'Amerikaans-Samoa',
	COUNTRY_ANDORRA: 'Andorra',
	COUNTRY_ANGOLA: 'Angola',
	COUNTRY_ANGUILLA: 'Anguilla',
	COUNTRY_ANTARCTICA: 'Antarctica',
	COUNTRY_ANTIGUA_AND_BARBUDA: 'Antigua en Barbuda',
	COUNTRY_ARGENTINA: 'Argentinië',
	COUNTRY_ARMENIA: 'Armenië',
	COUNTRY_ARUBA: 'Aruba',
	COUNTRY_AUSTRALIA: 'Australië',
	COUNTRY_AUSTRIA: 'Oostenrijk',
	COUNTRY_AZERBAIJAN: 'Azerbeidzjan',
	COUNTRY_BAHAMAS_THE: 'Bahama’s De',
	COUNTRY_BAHRAIN: 'Bahrein',
	COUNTRY_BANGLADESH: 'Bangladesh',
	COUNTRY_BARBADOS: 'Barbados',
	COUNTRY_BELARUS: 'Wit-Rusland',
	COUNTRY_BELGIUM: 'België',
	COUNTRY_BELIZE: 'Belize',
	COUNTRY_BENIN: 'Benin',
	COUNTRY_BERMUDA: 'Bermuda',
	COUNTRY_BHUTAN: 'Bhutan',
	COUNTRY_BOLIVIA: 'Bolivia',
	COUNTRY_BOSNIA_AND_HERZEGOVINA: 'Bosnië en Herzegovina',
	COUNTRY_BOTSWANA: 'Botswana',
	COUNTRY_BOUVET_ISLAND: 'Bouveteiland',
	COUNTRY_BRAZIL: 'Brazilië',
	COUNTRY_BRITISH_INDIAN_OCEAN_TERRITORY: 'Brits Indische Oceaanterritorium',
	COUNTRY_BRUNEI: 'Brunei',
	COUNTRY_BULGARIA: 'Bulgarije',
	COUNTRY_BURKINA_FASO: 'Burkina Faso',
	COUNTRY_BURUNDI: 'Burundi',
	COUNTRY_CAMBODIA: 'Cambodja',
	COUNTRY_CAMEROON: 'Kameroen',
	COUNTRY_CANADA: 'Canada',
	COUNTRY_CAPE_VERDE: 'Kaapverdië',
	COUNTRY_CAYMAN_ISLANDS: 'Caymaneilanden',
	COUNTRY_CENTRAL_AFRICAN_REPUBLIC: 'Centraal-Afrikaanse Republiek',
	COUNTRY_CHAD: 'Tsjaad',
	COUNTRY_CHILE: 'Chili',
	COUNTRY_CHINA: 'China',
	COUNTRY_CHRISTMAS_ISLAND: 'Christmaseiland',
	COUNTRY_COCOS_KEELING_ISLANDS: 'Cocoseilanden',
	COUNTRY_COLOMBIA: 'Colombia',
	COUNTRY_COMOROS: 'Comoren',
	COUNTRY_CONGO: 'Congo',
	COUNTRY_CONGO_THE_DEMOCRATIC_REPUBLIC_OF_THE: 'Congo Democratische Republiek',
	COUNTRY_COOK_ISLANDS: 'Cookeilanden',
	COUNTRY_COSTA_RICA: 'Costa Rica',
	COUNTRY_COTE_D__IVOIRE_IVORY_COAST_: 'Ivoorkust',
	COUNTRY_CROATIA_HRVATSKA_: 'Kroatië (Hrvatska)',
	COUNTRY_CUBA: 'Cuba',
	COUNTRY_CYPRUS: 'Cyprus',
	COUNTRY_CZECH_REPUBLIC: 'Tsjechië',
	COUNTRY_DENMARK: 'Denemarken',
	COUNTRY_DJIBOUTI: 'Djibouti',
	COUNTRY_DOMINICA: 'Dominica',
	COUNTRY_DOMINICAN_REPUBLIC: 'Dominicaanse Republiek',
	COUNTRY_EAST_TIMOR: 'Oost-Timor',
	COUNTRY_ECUADOR: 'Ecuador',
	COUNTRY_EGYPT: 'Egypte',
	COUNTRY_EL_SALVADOR: 'El Salvador',
	COUNTRY_EQUATORIAL_GUINEA: 'Equatoriaal-Guinea',
	COUNTRY_ERITREA: 'Eritrea',
	COUNTRY_ESTONIA: 'Estland',
	COUNTRY_ETHIOPIA: 'Ethiopië',
	COUNTRY_FALKLAND_ISLANDS: 'Falklandeilanden',
	COUNTRY_FAROE_ISLANDS: 'Faeröer',
	COUNTRY_FIJI_ISLANDS: 'Fiji',
	COUNTRY_FINLAND: 'Finland',
	COUNTRY_FRANCE: 'Frankrijk',
	COUNTRY_FRENCH_GUIANA: 'Frans-Guyana',
	COUNTRY_FRENCH_POLYNESIA: 'Frans-Polynesië',
	COUNTRY_FRENCH_SOUTHERN_TERRITORIES: 'Franse Zuidelijke en Antarctische Gebieden',
	COUNTRY_GABON: 'Gabon',
	COUNTRY_GAMBIA_THE: 'Gambia',
	COUNTRY_GEORGIA: 'Georgië',
	COUNTRY_GERMANY: 'Duitsland',
	COUNTRY_GHANA: 'Ghana',
	COUNTRY_GIBRALTAR: 'Gibraltar',
	COUNTRY_GREECE: 'Griekenland',
	COUNTRY_GREENLAND: 'Groenland',
	COUNTRY_GRENADA: 'Grenada',
	COUNTRY_GUADELOUPE: 'Guadeloupe',
	COUNTRY_GUAM: 'Guam',
	COUNTRY_GUATEMALA: 'Guatemala',
	COUNTRY_GUERNSEY_AND_ALDERNEY: 'Guernsey en Alderney',
	COUNTRY_GUINEA: 'Guinee',
	COUNTRY_GUINEA_BISSAU: 'Guinee-Bissau',
	COUNTRY_GUYANA: 'Guyana',
	COUNTRY_HAITI: 'Haïti',
	COUNTRY_HEARD_AND_MCDONALD_ISLANDS: 'Heard en McDonaldeilanden',
	COUNTRY_HONDURAS: 'Honduras',
	'COUNTRY_HONG_KONG_S.A.R.': 'Hongkong SAR',
	COUNTRY_HUNGARY: 'Hongarije',
	COUNTRY_ICELAND: 'IJsland',
	COUNTRY_INDIA: 'India',
	COUNTRY_INDONESIA: 'Indonesië',
	COUNTRY_IRAN: 'Iran',
	COUNTRY_IRAQ: 'Irak',
	COUNTRY_IRELAND: 'Ierland',
	COUNTRY_ISRAEL: 'Israël',
	COUNTRY_ITALY: 'Italië',
	COUNTRY_JAMAICA: 'Jamaica',
	COUNTRY_JAPAN: 'Japan',
	COUNTRY_JERSEY: 'Jersey',
	COUNTRY_JORDAN: 'Jordanië',
	COUNTRY_KAZAKHSTAN: 'Kazachstan',
	COUNTRY_KENYA: 'Kenia',
	COUNTRY_KIRIBATI: 'Kiribati',
	COUNTRY_KOREA_NORTH: 'Korea Noord',
	COUNTRY_KOREA_SOUTH: 'Korea Zuid',
	COUNTRY_KUWAIT: 'Kuweit',
	COUNTRY_KYRGYZSTAN: 'Kirgistan',
	COUNTRY_LAOS: 'Laos',
	COUNTRY_LATVIA: 'Letland',
	COUNTRY_LEBANON: 'Libanon',
	COUNTRY_LESOTHO: 'Lesotho',
	COUNTRY_LIBERIA: 'Liberia',
	COUNTRY_LIBYA: 'Libië',
	COUNTRY_LIECHTENSTEIN: 'Liechtenstein',
	COUNTRY_LITHUANIA: 'Litouwen',
	COUNTRY_LUXEMBOURG: 'Luxemburg',
	'COUNTRY_MACAU_S.A.R.': 'Macau SAR',
	COUNTRY_MACEDONIA: 'Macedonië',
	COUNTRY_MADAGASCAR: 'Madagaskar',
	COUNTRY_MALAWI: 'Malawi',
	COUNTRY_MALAYSIA: 'Maleisië',
	COUNTRY_MALDIVES: 'Malediven',
	COUNTRY_MALI: 'Mali',
	COUNTRY_MALTA: 'Malta',
	COUNTRY_MAN_ISLE_OF_: 'Man',
	COUNTRY_MARSHALL_ISLANDS: 'Marshalleilanden',
	COUNTRY_MARTINIQUE: 'Martinique',
	COUNTRY_MAURITANIA: 'Mauritanië',
	COUNTRY_MAURITIUS: 'Mauritius',
	COUNTRY_MAYOTTE: 'Mayotte',
	COUNTRY_MEXICO: 'Mexico',
	COUNTRY_MICRONESIA: 'Micronesia',
	COUNTRY_MOLDOVA: 'Moldavië',
	COUNTRY_MONACO: 'Monaco',
	COUNTRY_MONGOLIA: 'Mongolië',
	COUNTRY_MONTSERRAT: 'Montserrat',
	COUNTRY_MOROCCO: 'Marokko',
	COUNTRY_MOZAMBIQUE: 'Mozambique',
	COUNTRY_MYANMAR: 'Myanmar',
	COUNTRY_NAMIBIA: 'Namibië',
	COUNTRY_NAURU: 'Nauru',
	COUNTRY_NEPAL: 'Nepal',
	COUNTRY_NETHERLANDS: 'Nederland',
	COUNTRY_NETHERLANDS_ANTILLES: 'Nederlandse Antillen',
	COUNTRY_NEW_CALEDONIA: 'Nieuw-Caledonië',
	COUNTRY_NEW_ZEALAND: 'Nieuw-Zeeland',
	COUNTRY_NICARAGUA: 'Nicaragua',
	COUNTRY_NIGER: 'Niger',
	COUNTRY_NIGERIA: 'Nigeria',
	COUNTRY_NIUE: 'Niue',
	COUNTRY_NORFOLK_ISLAND: 'Norfolk',
	COUNTRY_NORTHERN_MARIANA_ISLANDS: 'Noordelijke Marianen',
	COUNTRY_NORWAY: 'Noorwegen',
	COUNTRY_OMAN: 'Oman',
	COUNTRY_PAKISTAN: 'Pakistan',
	COUNTRY_PALAU: 'Palau',
	COUNTRY_PALESTINIAN_TERRITORY_OCCUPIED: 'Palestijnse Gebieden',
	COUNTRY_PANAMA: 'Panama',
	COUNTRY_PAPUA_NEW_GUINEA: 'Papoea-Nieuw-Guinea',
	COUNTRY_PARAGUAY: 'Paraguay',
	COUNTRY_PERU: 'Peru',
	COUNTRY_PHILIPPINES: 'Filipijnen',
	COUNTRY_PITCAIRN_ISLAND: 'Pitcairneilanden',
	COUNTRY_POLAND: 'Polen',
	COUNTRY_PORTUGAL: 'Portugal',
	COUNTRY_PUERTO_RICO: 'Puerto Rico',
	COUNTRY_QATAR: 'Qatar',
	COUNTRY_REUNION: 'Réunion',
	COUNTRY_ROMANIA: 'Roemenië',
	COUNTRY_RUSSIA: 'Rusland',
	COUNTRY_RWANDA: 'Rwanda',
	COUNTRY_SAINT_HELENA: 'Sint-Helena',
	COUNTRY_SAINT_KITTS_AND_NEVIS: 'Saint Kitts en Nevis',
	COUNTRY_SAINT_LUCIA: 'Saint Lucia',
	COUNTRY_SAINT_PIERRE_AND_MIQUELON: 'Saint-Pierre en Miquelon',
	COUNTRY_SAINT_VINCENT_AND_THE_GRENADINES: 'Saint Vincent en de Grenadines',
	COUNTRY_SAMOA: 'Samoa',
	COUNTRY_SAN_MARINO: 'San Marino',
	COUNTRY_SAO_TOME_AND_PRINCIPE: 'Sao Tomé en Principe',
	COUNTRY_SAUDI_ARABIA: 'Saudi-Arabië',
	COUNTRY_SENEGAL: 'Senegal',
	COUNTRY_SERBIA: 'Servië',
	COUNTRY_SEYCHELLES: 'Seychellen',
	COUNTRY_SIERRA_LEONE: 'Sierra Leone',
	COUNTRY_SINGAPORE: 'Singapore',
	COUNTRY_SLOVAKIA: 'Slowakije',
	COUNTRY_SLOVENIA: 'Slovenië',
	COUNTRY_SOLOMON_ISLANDS: 'Salomonseilanden',
	COUNTRY_SOMALIA: 'Somalië',
	COUNTRY_SOUTH_AFRICA: 'Zuid-Afrika',
	COUNTRY_SOUTH_GEORGIA: 'Zuid-Georgia',
	COUNTRY_SOUTH_SUDAN: 'Zuid-Soedan',
	COUNTRY_SPAIN: 'Spanje',
	COUNTRY_SRI_LANKA: 'Sri Lanka',
	COUNTRY_SUDAN: 'Soedan',
	COUNTRY_SURINAME: 'Suriname',
	COUNTRY_SVALBARD_AND_JAN_MAYEN_ISLANDS: 'Spitsbergen en Jan Mayen',
	COUNTRY_SWAZILAND: 'Swaziland',
	COUNTRY_SWEDEN: 'Zweden',
	COUNTRY_SWITZERLAND: 'Zwitserland',
	COUNTRY_SYRIA: 'Syrië',
	COUNTRY_TAIWAN: 'Taiwan',
	COUNTRY_TAJIKISTAN: 'Tadzjikistan',
	COUNTRY_TANZANIA: 'Tanzania',
	COUNTRY_THAILAND: 'Thailand',
	COUNTRY_TOGO: 'Togo',
	COUNTRY_TOKELAU: 'Tokelau',
	COUNTRY_TONGA: 'Tonga',
	COUNTRY_TRINIDAD_AND_TOBAGO: 'Trinidad en Tobago',
	COUNTRY_TUNISIA: 'Tunisië',
	COUNTRY_TURKEY: 'Turkije',
	COUNTRY_TURKMENISTAN: 'Turkmenistan',
	COUNTRY_TURKS_AND_CAICOS_ISLANDS: 'Turks- en Caicoseilanden',
	COUNTRY_TUVALU: 'Tuvalu',
	COUNTRY_UGANDA: 'Uganda',
	COUNTRY_UKRAINE: 'Oekraïne',
	COUNTRY_UNITED_ARAB_EMIRATES: 'Verenigde Arabische Emiraten',
	COUNTRY_UNITED_KINGDOM: 'Verenigd Koninkrijk',
	COUNTRY_UNITED_STATES_MINOR_OUTLYING_ISLANDS: 'Kleine afgelegen eilanden van de Verenigde Staten',
	COUNTRY_URUGUAY: 'Uruguay',
	COUNTRY_UZBEKISTAN: 'Oezbekistan',
	COUNTRY_VANUATU: 'Vanuatu',
	COUNTRY_VATICAN_CITY_STATE_HOLY_SEE_: 'Vaticaanstad (Heilige Stoel)',
	COUNTRY_VENEZUELA: 'Venezuela',
	COUNTRY_VIETNAM: 'Vietnam',
	COUNTRY_VIRGIN_ISLANDS_BRITISH_: 'Britse Maagdeneilanden',
	COUNTRY_VIRGIN_ISLANDS_US_: 'Amerikaanse Maagdeneilanden',
	COUNTRY_WALLIS_AND_FUTUNA_ISLANDS: 'Wallis en Futuna',
	COUNTRY_WESTERN_SAHARA: 'Westelijke Sahara',
	COUNTRY_YEMEN: 'Jemen',
	COUNTRY_ZAMBIA: 'Zambia',
	COUNTRY_ZIMBABWE: 'Zimbabwe',

	//  STATE/PROVINCE
	STATE_ANDAMAN_AND_NICOBAR_ISLANDS: 'Andamanen en Nicobaren',
	STATE_ANDHRA_PRADESH: 'Andhra Pradesh',
	STATE_ARUNACHAL_PRADESH: 'Arunachal Pradesh',
	STATE_ASSAM: 'Assam',
	STATE_BIHAR: 'Bihar',
	STATE_CHANDIGARH: 'Chandigarh',
	STATE_CHHATTISGARH: 'Chhattisgarh',
	STATE_DADRA_AND_NAGAR_HAVELI: 'Dadra en Nagar Haveli',
	STATE_DAMAN_AND_DIU: 'Daman en Diu',
	STATE_DELHI: 'Delhi',
	STATE_GOA: 'Goa',
	STATE_GUJARAT: 'Gujarat',
	STATE_HARYANA: 'Haryana',
	STATE_HIMACHAL_PRADESH: 'Himachal Pradesh',
	STATE_JAMMU_AND_KASHMIR: 'Jammu en Kashmir',
	STATE_JHARKHAND: 'Jharkhand',
	STATE_KARNATAKA: 'Karnataka',
	STATE_KENMORE: 'Kenmore',
	STATE_KERALA: 'Kerala',
	STATE_LAKSHADWEEP: 'Lakshadweep',
	STATE_MADHYA_PRADESH: 'Madhya Pradesh',
	STATE_MAHARASHTRA: 'Maharashtra',
	STATE_MANIPUR: 'Manipur',
	STATE_MEGHALAYA: 'Meghalaya',
	STATE_MIZORAM: 'Mizoram',
	STATE_NAGALAND: 'Nagaland',
	STATE_NARORA: 'Narora',
	STATE_NATWAR: 'Natwar',
	STATE_ODISHA: 'Odisha',
	STATE_PASCHIM_MEDINIPUR: 'Paschim Medinipur',
	STATE_PONDICHERRY: 'Pondicherry',
	STATE_PUNJAB: 'Punjab',
	STATE_RAJASTHAN: 'Rajasthan',
	STATE_SIKKIM: 'Sikkim',
	STATE_TAMIL_NADU: 'Tamil Nadu',
	STATE_TELANGANA: 'Telangana',
	STATE_TRIPURA: 'Tripura',
	STATE_UTTAR_PRADESH: 'Uttar Pradesh',
	STATE_UTTARAKHAND: 'Uttarakhand',
	STATE_VAISHALI: 'Vaishali',
	STATE_WEST_BENGAL: 'West-Bengalen',
	STATE_BADAKHSHAN: 'Badakhshan',
	STATE_BADGIS: 'Bāḏḡīs',
	STATE_BAGLAN: 'Baglan',
	STATE_BALKH: 'Balch',
	STATE_BAMIYAN: 'Bamiyan',
	STATE_FARAH: 'Farah',
	STATE_FARYAB: 'Faryab',
	STATE_GAWR: 'Gawr',
	STATE_GAZNI: 'Gazni',
	STATE_HERAT: 'Herāt',
	STATE_ALABAMA: 'Alabama',
	STATE_ALASKA: 'Alaska',
	STATE_ARIZONA: 'Arizona',
	STATE_ARKANSAS: 'Arkansas',
	STATE_GEORGIA: 'Georgia',
	STATE_NEW_YORK: 'New York',
	STATE_VERMONT: 'Vermont',
	STATE_WASHINGTON: 'Washington',
	STATE_HILMAND: 'Hilmand',
	STATE_JAWZJAN: 'Jowzjan',
	STATE_KABUL: 'Kabul',
	STATE_KAPISA: 'Kapisa',
	STATE_KHAWST: 'Khawst',
	STATE_KUNAR: 'Kunar',
	STATE_LAGMAN: 'Lagman',
	STATE_LAWGHAR: 'Lawghar',
	STATE_NANGARHAR: 'Nangarhar',
	STATE_NIMRUZ: 'Nimruz',
	STATE_NURISTAN: 'Nuristan',
	STATE_PAKTIKA: 'Paktika',
	STATE_PAKTIYA: 'Paktiya',
	STATE_PARWAN: 'Parwan',
	STATE_QANDAHAR: 'Qandahâr',
	STATE_QUNDUZ: 'Kunduz',
	STATE_SAMANGAN: 'Samangan',
	STATE_SAR_E_PUL: 'Sar-e Pul',
	STATE_TAKHAR: 'Takhar',
	STATE_URUZGAN: 'Uruzgan',
	STATE_WARDAG: 'Wardag',
	STATE_ZABUL: 'Zābul',
	STATE_BERAT: 'Berat',
	STATE_BULQIZE: 'Bulqizë',
	STATE_DELVINE: 'Delvinë',
	STATE_DEVOLL: 'Devoll',
	STATE_DIBRE: 'Dibre',
	STATE_DURRES: 'Durrës',
	STATE_ELBASAN: 'Elbasan',
	STATE_FIER: 'Fier',
	STATE_GJIROKASTER: 'Gjirokaster',
	STATE_GRAMSH: 'Gramsh',
	STATE_HAS: 'Has',
	STATE_KAVAJE: 'Kavajë',
	STATE_KOLONJE: 'Kolonjë',
	STATE_KORCE: 'Korçë',
	STATE_KRUJE: 'Krujë',
	STATE_KUCOVE: 'Kuçovë',
	STATE_KUKES: 'Kukës',
	STATE_KURBIN: 'Kurbin',
	STATE_LEZHE: 'Lezhë',
	STATE_LIBRAZHD: 'Librazhd',
	STATE_LUSHNJE: 'Lushnjë',
	STATE_MALLAKASTER: 'Mallakastër',
	STATE_MALSI_E_MADHE: 'Malësi e Madhe',
	STATE_MAT: 'Mat',
	STATE_MIRDITE: 'Mirditë',
	STATE_PEQIN: 'Peqin',
	STATE_PERMET: 'Përmet',
	STATE_POGRADEC: 'Pogradec',
	STATE_PUKE: 'Pukë',
	STATE_SARANDE: 'Sarandë',
	STATE_SHKODER: 'Shkodër',
	STATE_SKRAPAR: 'Skrapar',
	STATE_TEPELENE: 'Tepelenë',
	STATE_TIRANE: 'Tirana',
	STATE_TROPOJE: 'Tropojë',
	STATE_VLORE: 'Vlorë',
	STATE_AYN_DAFLAH: 'Ayn ad-Daflah',
	STATE_AYN_TAMUSHANAT: 'Ayn Tamushanat',
	STATE_ADRAR: 'Adrar',
	STATE_ALGIERS: 'Algiers',
	STATE_ANNABAH: 'Annaba',
	STATE_BASHSHAR: 'Bahshar',
	STATE_BATNAH: 'Batnah',
	STATE_BIJAYAH: 'Bijayah',
	STATE_BISKRAH: 'Biskrah',
	STATE_BLIDAH: 'Blidah',
	STATE_BUIRAH: 'Buirah',
	STATE_BUMARDAS: 'Bumardas',
	STATE_BURJ_BU_ARRIRIJ: 'Burj Bu Arririj',
	STATE_GHALIZAN: 'Ghalizan',
	STATE_GHARDAYAH: 'Ghardaia',
	STATE_ILIZI: 'Ilizi',
	STATE_JIJILI: 'Jijili',
	STATE_JILFAH: 'Djelfa',
	STATE_KHANSHALAH: 'Khanshalah',
	STATE_MASILAH: 'Masilah',
	STATE_MIDYAH: 'Midyah',
	STATE_MILAH: 'Milah',
	STATE_MUASKAR: 'Muaskar',
	STATE_MUSTAGHANAM: 'Mustaghanam',
	STATE_NAAMA: 'Naama',
	STATE_ORAN: 'Oran',
	STATE_OUARGLA: 'Ouargla',
	STATE_QALMAH: 'Guelma',
	STATE_QUSTANTINAH: 'Qustantinah',
	STATE_SAKIKDAH: 'Sakikdah',
	STATE_SATIF: 'Satif',
	STATE_SAYDA: 'Sayda',
	STATE_SIDI_BAN_AL___ABBAS: "Sidi ban-al-'Abbas",
	STATE_SUQ_AHRAS: 'Suq Ahras',
	STATE_TAMANGHASAT: 'Tamanghasat',
	STATE_TIBAZAH: 'Tibazah',
	STATE_TIBISSAH: 'Tibissah',
	STATE_TILIMSAN: 'Tilimsan',
	STATE_TINDUF: 'Tinduf',
	STATE_TISAMSILT: 'Tisamsilt',
	STATE_TIYARAT: 'Tiyarat',
	STATE_TIZI_WAZU: 'Tizi Wazu',
	STATE_UMM_AL_BAWAGHI: 'Umm-al-Bawaghi',
	STATE_WAHRAN: 'Wahran',
	STATE_WARQLA: 'Warqla',
	STATE_WILAYA_D_ALGER: 'Wilaya d’Alger',
	STATE_WILAYA_DE_BEJAIA: 'Wilaya de Bejaia',
	STATE_WILAYA_DE_CONSTANTINE: 'Wilaya de Constantine',
	STATE_AL_AGHWAT: 'al-Aghwat',
	STATE_AL_BAYADH: 'al-Bayadh',
	STATE_AL_JAZA__IR: 'al-Jaza’ir',
	STATE_AL_WAD: 'al-Wad',
	STATE_ASH_SHALIF: 'ash-Shalif',
	STATE_AT_TARIF: 'at-Tarif',
	STATE_EASTERN: 'Oost',
	STATE_MANU__A: 'Manu’a',
	STATE_SWAINS_ISLAND: 'Swains',
	STATE_WESTERN: 'West',
	STATE_ANDORRA_LA_VELLA: 'Andorra la Vella',
	STATE_CANILLO: 'Canillo',
	STATE_ENCAMP: 'Encamp',
	STATE_LA_MASSANA: 'La Massana',
	STATE_LES_ESCALDES: 'Les Escaldes',
	STATE_ORDINO: 'Ordino',
	STATE_SANT_JULIA_DE_LORIA: 'Sant Julia de Loria',
	STATE_BENGO: 'Bengo',
	STATE_BENGUELA: 'Benguela',
	STATE_BIE: 'Bie',
	STATE_CABINDA: 'Cabinda',
	STATE_CUNENE: 'Cunene',
	STATE_HUAMBO: 'Huambo',
	STATE_HUILA: 'Huila',
	STATE_KUANDO_KUBANGO: 'Kuando-Kubango',
	STATE_KWANZA_NORTE: 'Kwanza Norte',
	STATE_KWANZA_SUL: 'Kwanza Sul',
	STATE_LUANDA: 'Luanda',
	STATE_LUNDA_NORTE: 'Kwanza Norte',
	STATE_LUNDA_SUL: 'Lunda Sul',
	STATE_MALANJE: 'Malanje',
	STATE_MOXICO: 'Moxico',
	STATE_NAMIBE: 'Namibe',
	STATE_UIGE: 'Uige',
	STATE_ZAIRE: 'Zaïre',
	STATE_OTHER_PROVINCES: 'Overige provincies',
	STATE_SECTOR_CLAIMED_BY_ARGENTINA_CH: 'Sector geclaimd door Argentinië/Ch',
	STATE_SECTOR_CLAIMED_BY_ARGENTINA_UK: 'Sector geclaimd door Argentinië/VK',
	STATE_SECTOR_CLAIMED_BY_AUSTRALIA: 'Sector geclaimd door Australië',
	STATE_SECTOR_CLAIMED_BY_FRANCE: 'Sector geclaimd door Frankrijk',
	STATE_SECTOR_CLAIMED_BY_NEW_ZEALAND: 'Sector geclaimd door Nieuw-Zeeland',
	STATE_SECTOR_CLAIMED_BY_NORWAY: 'Sector geclaimd door Noorwegen',
	STATE_UNCLAIMED_SECTOR: 'Niet-geclaimde sector',
	STATE_BARBUDA: 'Barbuda',
	STATE_SAINT_GEORGE: 'Saint George',
	STATE_SAINT_JOHN: 'Saint John',
	STATE_SAINT_MARY: 'Saint Mary',
	STATE_SAINT_PAUL: 'Saint Paul',
	STATE_SAINT_PETER: 'Saint Peter',
	STATE_SAINT_PHILIP: 'Saint Philip',
	STATE_BUENOS_AIRES: 'Buenos Aires',
	STATE_CATAMARCA: 'Catamarca',
	STATE_CHACO: 'Chaco',
	STATE_CHUBUT: 'Chubut',
	STATE_CORDOBA: 'Córdoba',
	STATE_CORRIENTES: 'Corrientes',
	STATE_DISTRITO_FEDERAL: 'Distrito Federal',
	STATE_ENTRE_RIOS: 'Entre Rios',
	STATE_FORMOSA: 'Formosa',
	STATE_JUJUY: 'Jujuy',
	STATE_LA_PAMPA: 'La Pampa',
	STATE_HIDD: 'Hidd',
	STATE_LA_RIOJA: 'La Rioja',
	STATE_MENDOZA: 'Mendoza',
	STATE_MISIONES: 'Misiones',
	STATE_NEUQUEN: 'Neuquen',
	STATE_RIO_NEGRO: 'Rio Negro',
	STATE_SALTA: 'Salta',
	STATE_SAN_JUAN: 'San Juan',
	STATE_SAN_LUIS: 'San Luis',
	STATE_SANTA_CRUZ: 'Santa Cruz',
	STATE_SANTA_FE: 'Santa Fe',
	STATE_SANTIAGO_DEL_ESTERO: 'Santiago del Estero',
	STATE_TIERRA_DEL_FUEGO: 'Tierra del Fuego',
	STATE_TUCUMAN: 'Tucuman',
	STATE_ARAGATSOTN: 'Aragatsotn',
	STATE_ARARAT: 'Ararat',
	STATE_ARMAVIR: 'Armavir',
	STATE_GEGHARKUNIK: 'Gegharkunik',
	STATE_KOTAIK: 'Kotaik',
	STATE_LORI: 'Lori',
	STATE_SHIRAK: 'Shirak',
	STATE_STEPANAKERT: 'Stepanakert',
	STATE_SYUNIK: 'Syunik',
	STATE_TAVUSH: 'Tavush',
	STATE_VAYOTS_DZOR: 'Vayots Dzor',
	STATE_YEREVAN: 'Yerevan',
	STATE_ARUBA: 'Aruba',
	STATE_AUCKLAND: 'Auckland',
	STATE_AUSTRALIAN_CAPITAL_TERRITORY: 'Australisch Hoofdstedelijk Territorium',
	STATE_BALGOWLAH: 'Balgowlah',
	STATE_BALMAIN: 'Balmain',
	STATE_BANKSTOWN: 'Bankstown',
	STATE_BAULKHAM_HILLS: 'Baulkham Hills',
	STATE_BONNET_BAY: 'Bonnet Bay',
	STATE_CAMBERWELL: 'Camberwell',
	STATE_CAROLE_PARK: 'Carole Park',
	STATE_CASTLE_HILL: 'Castle Hill',
	STATE_CAULFIELD: 'Caulfield',
	STATE_CHATSWOOD: 'Chatswood',
	STATE_CHELTENHAM: 'Cheltenham',
	STATE_CHERRYBROOK: 'Cherrybrook',
	STATE_CLAYTON: 'Clayton',
	STATE_COLLINGWOOD: 'Collingwood',
	STATE_FRENCHS_FOREST: 'Frenchs Forest',
	STATE_HAWTHORN: 'Hawthorn',
	STATE_JANNNALI: 'Jannnali',
	STATE_KNOXFIELD: 'Knoxfield',
	STATE_MELBOURNE: 'Melbourne',
	STATE_NEW_SOUTH_WALES: 'Nieuw-Zuid-Wales',
	STATE_NORTHERN_TERRITORY: 'Noordelijk Territorium',
	STATE_PERTH: 'Perth',
	STATE_QUEENSLAND: 'Queensland',
	STATE_SOUTH_AUSTRALIA: 'Zuid-Australië',
	STATE_TASMANIA: 'Tasmanië',
	STATE_TEMPLESTOWE: 'Templestowe',
	STATE_VICTORIA: 'Victoria',
	STATE_WERRIBEE_SOUTH: 'Werribee',
	STATE_WESTERN_AUSTRALIA: 'West-Australië',
	STATE_WHEELER: 'Wheeler',
	STATE_BUNDESLAND_SALZBURG: 'Deelstaat Salzburg',
	STATE_BUNDESLAND_STEIERMARK: 'Deelstaat Steiermark',
	STATE_BUNDESLAND_TIROL: 'Deelstaat Steiermark',
	STATE_BURGENLAND: 'Burgenland',
	STATE_CARINTHIA: 'Karinthië',
	STATE_KARNTEN: 'Kärnten',
	STATE_LIEZEN: 'Liezen',
	STATE_LOWER_AUSTRIA: 'Neder-Oostenrijk',
	STATE_NIEDEROSTERREICH: 'Niederosterreich',
	STATE_OBEROSTERREICH: 'Oberosterreich',
	STATE_SALZBURG: 'Salzburg',
	STATE_SCHLESWIG_HOLSTEIN: 'Schleswig-Holstein',
	STATE_STEIERMARK: 'Steiermark',
	STATE_STYRIA: 'Stiermarken',
	STATE_TIROL: 'Tirol',
	STATE_UPPER_AUSTRIA: 'Opper-Oostenrijk',
	STATE_VORARLBERG: 'Vorarlberg',
	STATE_WIEN: 'Wenen',
	STATE_ABSERON: 'Abseron',
	STATE_BAKI_SAHARI: 'Baki Sahari',
	STATE_GANCA: 'Ganca',
	STATE_GANJA: 'Ganja',
	STATE_KALBACAR: 'Kalbacar',
	STATE_LANKARAN: 'Lankaran',
	STATE_MIL_QARABAX: 'Mil-Qarabax',
	STATE_MUGAN_SALYAN: 'Mugan-Salyan',
	STATE_NAGORNI_QARABAX: 'Nagorni-Qarabax',
	STATE_NAXCIVAN: 'Naxcivan',
	STATE_PRIARAKS: 'Priaraks',
	STATE_QAZAX: 'Qazax',
	STATE_SAKI: 'Saki',
	STATE_SIRVAN: 'Sirvan',
	STATE_XACMAZ: 'Xacmaz',
	STATE_ABACO: 'Abaco',
	STATE_ACKLINS_ISLAND: 'Acklins',
	STATE_ANDROS: 'Andros',
	STATE_BERRY_ISLANDS: 'Berry Islands',
	STATE_BIMINIS: 'Bimini',
	STATE_CAT_ISLAND: 'Katteneiland',
	STATE_CROOKED_ISLAND: 'Crooked Island',
	STATE_ELEUTHERA: 'Eleuthera',
	STATE_EXUMA_AND_CAYS: 'Exuma en Cays',
	STATE_GRAND_BAHAMA: 'Grand Bahama',
	STATE_INAGUA_ISLANDS: 'Inagua',
	STATE_LONG_ISLAND: 'Long Island',
	STATE_MAYAGUANA: 'Mayaguana',
	STATE_NEW_PROVIDENCE: 'New Providence',
	STATE_RAGGED_ISLAND: 'Ragged Island',
	STATE_RUM_CAY: 'Rum Cay',
	STATE_SAN_SALVADOR: 'San Salvador',
	STATE_ISA: 'Isa',
	STATE_BADIYAH: 'Badiyah',
	STATE_JIDD_HAFS: 'Jidd Hafs',
	STATE_MAHAMA: 'Mahama',
	STATE_MANAMA: 'Manama',
	STATE_SITRAH: 'Sitrah',
	STATE_AL_MANAMAH: 'al-Manamah',
	STATE_AL_MUHARRAQ: 'al-Muharraq',
	STATE_AR_RIFA__A: "ar-Rifa'a",
	STATE_BAGAR_HAT: 'Bagar Hat',
	STATE_BANDARBAN: 'Bandarban',
	STATE_BARGUNA: 'Barguna',
	STATE_BARISAL: 'Barisal',
	STATE_BHOLA: 'Bhola',
	STATE_BOGORA: 'Bogora',
	STATE_BRAHMAN_BARIYA: 'Brahman Bariya',
	STATE_CHANDPUR: 'Chandpur',
	STATE_CHATTAGAM: 'Chattagam',
	STATE_CHITTAGONG_DIVISION: 'Chittagong-divisie',
	STATE_CHUADANGA: 'Chuadanga',
	STATE_DHAKA: 'Dhaka',
	STATE_DINAJPUR: 'Dinajpur',
	STATE_FARIDPUR: 'Faridpur',
	STATE_FENI: 'Feni',
	STATE_GAYBANDA: 'Gaybanda',
	STATE_GAZIPUR: 'Gazipur',
	STATE_GOPALGANJ: 'Gopalganj',
	STATE_HABIGANJ: 'Habiganj',
	STATE_JAIPUR_HAT: 'Jaipur Hat',
	STATE_JAMALPUR: 'Jamalpur',
	STATE_JESSOR: 'Jessor',
	STATE_JHALAKATI: 'Jhalakati',
	STATE_JHANAYDAH: 'Jhanaydah',
	STATE_KHAGRACHHARI: 'Khagrachhari',
	STATE_KHULNA: 'Khulna',
	STATE_KISHORGANJ: 'Kishorganj',
	STATE_KOKS_BAZAR: 'Koks Bazar',
	STATE_KOMILLA: 'Komilla',
	STATE_KURIGRAM: 'Kurigram',
	STATE_KUSHTIYA: 'Kushtiya',
	STATE_LAKSHMIPUR: 'Lakshmipur',
	STATE_LALMANIR_HAT: 'Lalmanir Hat',
	STATE_MADARIPUR: 'Madaripur',
	STATE_MAGURA: 'Magura',
	STATE_MAIMANSINGH: 'Maimansingh',
	STATE_MANIKGANJ: 'Manikganj',
	STATE_MAULVI_BAZAR: 'Maulvi Bazar',
	STATE_MEHERPUR: 'Meherpur',
	STATE_MUNSHIGANJ: 'Munshiganj',
	STATE_NARAL: 'Naral',
	STATE_NARAYANGANJ: 'Narayanganj',
	STATE_NARSINGDI: 'Narsingdi',
	STATE_NATOR: 'Nator',
	STATE_NAUGAON: 'Naugaon',
	STATE_NAWABGANJ: 'Nawabganj',
	STATE_NETRAKONA: 'Netrakona',
	STATE_NILPHAMARI: 'Nilphamari',
	STATE_NOAKHALI: 'Noakhali',
	STATE_PABNA: 'Pabna',
	STATE_PANCHAGARH: 'Panchagarh',
	STATE_PATUAKHALI: 'Patuakhali',
	STATE_PIROJPUR: 'Pirojpur',
	STATE_RAJBARI: 'Rajbari',
	STATE_RAJSHAHI: 'Rajshahi',
	STATE_RANGAMATI: 'Rangamati',
	STATE_RANGPUR: 'Rangpur',
	STATE_SATKHIRA: 'Satkhira',
	STATE_SHARIATPUR: 'Shariatpur',
	STATE_SHERPUR: 'Sherpur',
	STATE_SILHAT: 'Silhat',
	STATE_SIRAJGANJ: 'Sirajganj',
	STATE_SUNAMGANJ: 'Sunamganj',
	STATE_TANGAYAL: 'Tangayal',
	STATE_THAKURGAON: 'Thakurgaon',
	STATE_CHRIST_CHURCH: 'Christchurch',
	STATE_SAINT_ANDREW: 'Saint Andrew',
	STATE_SAINT_JAMES: 'Saint James',
	STATE_SAINT_JOSEPH: 'Saint Joseph',
	STATE_SAINT_LUCY: 'Saint Lucy',
	STATE_SAINT_MICHAEL: 'Saint Michael',
	STATE_SAINT_THOMAS: 'Saint Thomas',
	STATE_BREST: 'Brest',
	STATE_HOMJEL: 'Homjel',
	STATE_HRODNA: 'Hrodna',
	STATE_MAHILJOW: 'Mahiljow',
	STATE_MAHILYOWSKAYA_VOBLASTS: 'Mahilyowskaya Voblasts',
	STATE_MINSK: 'Minsk',
	STATE_MINSKAJA_VOBLASTS: 'Minskaja Voblasts',
	STATE_PETRIK: 'Petrik',
	STATE_VICEBSK: 'Vicebsk',
	STATE_ANTWERPEN: 'Antwerpen',
	STATE_BERCHEM: 'Berchem',
	STATE_BRABANT: 'Brabant',
	STATE_BRABANT_WALLON: 'Brabant Wallonië',
	STATE_BRUSSEL: 'Brussel',
	STATE_EAST_FLANDERS: 'Oost-Vlaanderen',
	STATE_HAINAUT: 'Hainaut',
	STATE_LIEGE: 'Luik',
	STATE_LIMBURG: 'Limburg',
	STATE_LUXEMBOURG: 'Luxemburg',
	STATE_NAMUR: 'Namen',
	STATE_ONTARIO: 'Ontario',
	STATE_OOST_VLAANDEREN: 'Oost-Vlaanderen',
	STATE_PROVINCIE_BRABANT: 'Provincie Brabant',
	STATE_VLAAMS_BRABANT: 'Vlaams-Brabant',
	STATE_WALLONNE: 'Wallonië',
	STATE_WEST_VLAANDEREN: 'West-Vlaanderen',
	STATE_BELIZE: 'Belize',
	STATE_CAYO: 'Cayo',
	STATE_COROZAL: 'Corozal',
	STATE_ORANGE_WALK: 'Orange Walk',
	STATE_STANN_CREEK: 'Stann Creek',
	STATE_TOLEDO: 'Toledo',
	STATE_ALIBORI: 'Alibori',
	STATE_ATACORA: 'Atacora',
	STATE_ATLANTIQUE: 'Atlantique',
	STATE_BORGOU: 'Borgou',
	STATE_COLLINES: 'Collines',
	STATE_COUFFO: 'Couffo',
	STATE_DONGA: 'Donga',
	STATE_LITTORAL: 'Littoral',
	STATE_MONO: 'Mono',
	STATE_OUEME: 'Oueme',
	STATE_PLATEAU: 'Plateau',
	STATE_ZOU: 'Zou',
	STATE_HAMILTON: 'Hamilton',
	STATE_BUMTHANG: 'Bumthang',
	STATE_CHHUKHA: 'Chhukha',
	STATE_CHIRANG: 'Chirang',
	STATE_DAGA: 'Daga',
	STATE_GEYLEGPHUG: 'Geylegphug',
	STATE_HA: 'Ha',
	STATE_LHUNTSHI: 'Lhuntshi',
	STATE_MONGAR: 'Mongar',
	STATE_PEMAGATSEL: 'Pemagatsel',
	STATE_PUNAKHA: 'Punakha',
	STATE_RINPUNG: 'Rinpung',
	STATE_SAMCHI: 'Samchi',
	STATE_SAMDRUP_JONGKHAR: 'Samdrup Jongkhar',
	STATE_SHEMGANG: 'Shemgang',
	STATE_TASHIGANG: 'Tashigang',
	STATE_TIMPHU: 'Timphu',
	STATE_TONGSA: 'Tongsa',
	STATE_WANGDIPHODRANG: 'Wangdiphodrang',
	STATE_BENI: 'Beni',
	STATE_CHUQUISACA: 'Chuquisaca',
	STATE_COCHABAMBA: 'Cochabamba',
	STATE_LA_PAZ: 'La Paz',
	STATE_ORURO: 'Oruro',
	STATE_PANDO: 'Pando',
	STATE_POTOSI: 'Potosi',
	STATE_TARIJA: 'Tarija',
	STATE_FEDERACIJA_BOSNA_I_HERCEGOVINA: 'Federacija Bosnië en Herzegovina',
	STATE_REPUBLIKA_SRPSKA: 'Servische Republiek',
	STATE_CENTRAL_BOBONONG: 'Central Bobonong',
	STATE_CENTRAL_BOTETI: 'Central Boteti',
	STATE_CENTRAL_MAHALAPYE: 'Central Mahalapye',
	STATE_CENTRAL_SEROWE_PALAPYE: 'Central Serowe-Palapye',
	STATE_CENTRAL_TUTUME: 'Central Tutume',
	STATE_CHOBE: 'Chobe',
	STATE_FRANCISTOWN: 'Francistown',
	STATE_GABORONE: 'Gaborone',
	STATE_GHANZI: 'Ghanzi',
	STATE_JWANENG: 'Jwaneng',
	STATE_KGALAGADI_NORTH: 'Kgalagadi Noord',
	STATE_KGALAGADI_SOUTH: 'Kgalagadi Zuid',
	STATE_KGATLENG: 'Kgatleng',
	STATE_KWENENG: 'Kweneng',
	STATE_LOBATSE: 'Lobatse',
	STATE_NGAMILAND: 'Ngamiland',
	STATE_NGWAKETSE: 'Ngwaketse',
	STATE_NORTH_EAST: 'Noord-Oost',
	STATE_OKAVANGO: 'Okavango',
	STATE_ORAPA: 'Orapa',
	STATE_SELIBE_PHIKWE: 'Selibe Phikwe',
	STATE_SOUTH_EAST: 'Zuid-Oost',
	STATE_SOWA: 'Sowa',
	STATE_BOUVET_ISLAND: 'Bouveteiland',
	STATE_ACRE: 'Acre',
	STATE_ALAGOAS: 'Alagoas',
	STATE_AMAPA: 'Amapa',
	STATE_AMAZONAS: 'Amazonas',
	STATE_BAHIA: 'Bahia',
	STATE_CEARA: 'Ceara',
	STATE_ESPIRITO_SANTO: 'Espirito Santo',
	STATE_ESTADO_DE_SAO_PAULO: 'Estado de Sao Paulo',
	STATE_GOIAS: 'Goias',
	STATE_MARANHAO: 'Maranhao',
	STATE_MATO_GROSSO: 'Mato Grosso',
	STATE_MATO_GROSSO_DO_SUL: 'Mato Grosso do Sul',
	STATE_MINAS_GERAIS: 'Minas Gerais',
	STATE_PARA: 'Para',
	STATE_PARAIBA: 'Paraiba',
	STATE_PARANA: 'Parana',
	STATE_PERNAMBUCO: 'Pernambuco',
	STATE_PIAUI: 'Piaui',
	STATE_RIO_GRANDE_DO_NORTE: 'Rio Grande do Norte',
	STATE_RIO_GRANDE_DO_SUL: 'Rio Grande do Sul',
	STATE_RIO_DE_JANEIRO: 'Rio de Janeiro',
	STATE_RONDONIA: 'Rondonia',
	STATE_RORAIMA: 'Roraima',
	STATE_SANTA_CATARINA: 'Santa Catarina',
	STATE_SAO_PAULO: 'Sao Paulo',
	STATE_SERGIPE: 'Sergipe',
	STATE_TOCANTINS: 'Tocantins',
	STATE_BRITISH_INDIAN_OCEAN_TERRITORY: 'Brits Indische Oceaanterritorium',
	STATE_BELAIT: 'Belait',
	STATE_BRUNEI_MUARA: 'Brunei-Muara',
	STATE_TEMBURONG: 'Temburong',
	STATE_TUTONG: 'Tutong',
	STATE_BLAGOEVGRAD: 'Blagoevgrad',
	STATE_BURGAS: 'Burgas',
	STATE_DOBRICH: 'Dobrich',
	STATE_GABROVO: 'Gabrovo',
	STATE_HASKOVO: 'Haskovo',
	STATE_JAMBOL: 'Jambol',
	STATE_KARDZHALI: 'Kardzhali',
	STATE_KJUSTENDIL: 'Kjustendil',
	STATE_LOVECH: 'Lovech',
	STATE_MONTANA: 'Montana',
	STATE_OBLAST_SOFIYA_GRAD: 'Oblast Sofiya-Grad',
	STATE_PAZARDZHIK: 'Pazardzhik',
	STATE_PERNIK: 'Pernik',
	STATE_PLEVEN: 'Pleven',
	STATE_PLOVDIV: 'Plovdiv',
	STATE_RAZGRAD: 'Razgrad',
	STATE_RUSE: 'Ruse',
	STATE_SHUMEN: 'Shumen',
	STATE_SILISTRA: 'Silistra',
	STATE_SLIVEN: 'Sliven',
	STATE_SMOLJAN: 'Smoljan',
	STATE_SOFIJA_GRAD: 'Sofija grad',
	STATE_SOFIJSKA_OBLAST: 'Sofijska oblast',
	STATE_STARA_ZAGORA: 'Stara Zagora',
	STATE_TARGOVISHTE: 'Targovishte',
	STATE_VARNA: 'Varna',
	STATE_VELIKO_TARNOVO: 'Veliko Tarnovo',
	STATE_VIDIN: 'Vidin',
	STATE_VRACA: 'Vraca',
	STATE_YABLANIZA: 'Yablaniza',
	STATE_BALE: 'Bale',
	STATE_BAM: 'Bam',
	STATE_BAZEGA: 'Bazega',
	STATE_BOUGOURIBA: 'Bougouriba',
	STATE_BOULGOU: 'Boulgou',
	STATE_BOULKIEMDE: 'Boulkiemde',
	STATE_COMOE: 'Comoe',
	STATE_GANZOURGOU: 'Ganzourgou',
	STATE_GNAGNA: 'Gnagna',
	STATE_GOURMA: 'Gourma',
	STATE_HOUET: 'Houet',
	STATE_IOBA: 'Ioba',
	STATE_KADIOGO: 'Kadiogo',
	STATE_KENEDOUGOU: 'Kenedougou',
	STATE_KOMANDJARI: 'Komandjari',
	STATE_KOMPIENGA: 'Kompienga',
	STATE_KOSSI: 'Kossi',
	STATE_KOURITENGA: 'Kouritenga',
	STATE_KOURWEOGO: 'Kourweogo',
	STATE_LERABA: 'Leraba',
	STATE_MOUHOUN: 'Mouhoun',
	STATE_NAHOURI: 'Nahouri',
	STATE_NAMENTENGA: 'Namentenga',
	STATE_NOUMBIEL: 'Noumbiel',
	STATE_OUBRITENGA: 'Oubritenga',
	STATE_OUDALAN: 'Oudalan',
	STATE_PASSORE: 'Passore',
	STATE_PONI: 'Poni',
	STATE_SANGUIE: 'Sanguie',
	STATE_SANMATENGA: 'Sanmatenga',
	STATE_SENO: 'Seno',
	STATE_SISSILI: 'Sissili',
	STATE_SOUM: 'Soum',
	STATE_SOUROU: 'Sourou',
	STATE_TAPOA: 'Tapoa',
	STATE_TUY: 'Tuy',
	STATE_YATENGA: 'Yatenga',
	STATE_ZONDOMA: 'Zondoma',
	STATE_ZOUNDWEOGO: 'Zoundweogo',
	STATE_BUBANZA: 'Bubanza',
	STATE_BUJUMBURA: 'Bujumbura',
	STATE_BURURI: 'Bururi',
	STATE_CANKUZO: 'Cankuzo',
	STATE_CIBITOKE: 'Cibitoke',
	STATE_GITEGA: 'Gitega',
	STATE_KARUZI: 'Karuzi',
	STATE_KAYANZA: 'Kayanza',
	STATE_KIRUNDO: 'Kirundo',
	STATE_MAKAMBA: 'Makamba',
	STATE_MURAMVYA: 'Muramvya',
	STATE_MUYINGA: 'Muyinga',
	STATE_NGOZI: 'Ngozi',
	STATE_RUTANA: 'Rutana',
	STATE_RUYIGI: 'Ruyigi',
	STATE_BANTEAY_MEAN_CHEY: 'Banteay Mean Chey',
	STATE_BAT_DAMBANG: 'Bat Dambang',
	STATE_KAMPONG_CHAM: 'Kampong Cham',
	STATE_KAMPONG_CHHNANG: 'Kampong Chhnang',
	STATE_KAMPONG_SPOEU: 'Kampong Spoeu',
	STATE_KAMPONG_THUM: 'Kampong Thum',
	STATE_KAMPOT: 'Kampot',
	STATE_KANDAL: 'Kandal',
	STATE_KAOH_KONG: 'Kaoh Kong',
	STATE_KRACHEH: 'Kracheh',
	STATE_KRONG_KAEB: 'Krong Kaeb',
	STATE_KRONG_PAILIN: 'Krong Pailin',
	STATE_KRONG_PREAH_SIHANOUK: 'Krong Preah Sihanouk',
	STATE_MONDOL_KIRI: 'Mondol Kiri',
	STATE_OTDAR_MEAN_CHEY: 'Otdar Mean Chey',
	STATE_PHNUM_PENH: 'Phnum Penh',
	STATE_POUSAT: 'Pousat',
	STATE_PREAH_VIHEAR: 'Preah Vihear',
	STATE_PREY_VEAENG: 'Prey Veaeng',
	STATE_ROTANAK_KIRI: 'Rotanak Kiri',
	STATE_SIEM_REAB: 'Siem Reab',
	STATE_STUENG_TRAENG: 'Stueng Traeng',
	STATE_SVAY_RIENG: 'Svay Rieng',
	STATE_TAKAEV: 'Takaev',
	STATE_ADAMAOUA: 'Adamaoua',
	STATE_CENTRE: 'Centre',
	STATE_EST: 'Est',
	STATE_NORD: 'Nord',
	STATE_NORD_EXTREME: 'Nord Extreme',
	STATE_NORDOUEST: 'Nordouest',
	STATE_OUEST: 'Ouest',
	STATE_SUD: 'Sud',
	STATE_SUDOUEST: 'Sudouest',
	STATE_ALBERTA: 'Alberta',
	STATE_BRITISH_COLUMBIA: 'British Columbia',
	STATE_MANITOBA: 'Manitoba',
	STATE_NEW_BRUNSWICK: 'New Brunswick',
	STATE_NEWFOUNDLAND_AND_LABRADOR: 'Newfoundland en Labrador',
	STATE_NORTHWEST_TERRITORIES: 'Northwest Territories',
	STATE_NOVA_SCOTIA: 'Nova Scotia',
	STATE_NUNAVUT: 'Nunavut',
	STATE_PRINCE_EDWARD_ISLAND: 'Prince Edward Island',
	STATE_QUEBEC: 'Quebec',
	STATE_SASKATCHEWAN: 'Saskatchewan',
	STATE_YUKON: 'Yukon',
	STATE_BOAVISTA: 'Boavista',
	STATE_BRAVA: 'Brava',
	STATE_FOGO: 'Fogo',
	STATE_MAIO: 'Maio',
	STATE_SAL: 'Sal',
	STATE_SANTO_ANTAO: 'Santo Antao',
	STATE_SAO_NICOLAU: 'Sao Nicolau',
	STATE_SAO_TIAGO: 'Sao Tiago',
	STATE_SAO_VICENTE: 'Sao Vicente',
	STATE_GRAND_CAYMAN: 'Grand Cayman',
	STATE_BAMINGUI_BANGORAN: 'Bamingui-Bangoran',
	STATE_BANGUI: 'Bangui',
	STATE_BASSE_KOTTO: 'Basse-Kotto',
	STATE_HAUT_MBOMOU: 'Haut-Mbomou',
	STATE_HAUTE_KOTTO: 'Haute-Kotto',
	STATE_KEMO: 'Kemo',
	STATE_LOBAYE: 'Lobaye',
	STATE_MAMBERE_KADEI: 'Mambere-Kadei',
	STATE_MBOMOU: 'Mbomou',
	STATE_NANA_GRIBIZI: 'Nana-Gribizi',
	STATE_NANA_MAMBERE: 'Nana-Mambere',
	STATE_OMBELLA_MPOKO: 'Ombella Mpoko',
	STATE_OUAKA: 'Ouaka',
	STATE_OUHAM: 'Ouham',
	STATE_OUHAM_PENDE: 'Ouham-Pende',
	STATE_SANGHA_MBAERE: 'Sangha-Mbaere',
	STATE_VAKAGA: 'Vakaga',
	STATE_BATHA: 'Batha',
	STATE_BILTINE: 'Biltine',
	STATE_BOURKOU_ENNEDI_TIBESTI: 'Bourkou-Ennedi-Tibesti',
	STATE_CHARI_BAGUIRMI: 'Chari-Baguirmi',
	STATE_GUERA: 'Guera',
	STATE_KANEM: 'Kanem',
	STATE_LAC: 'Lac',
	STATE_LOGONE_OCCIDENTAL: 'Logone Occidental',
	STATE_LOGONE_ORIENTAL: 'Logone Oriental',
	STATE_MAYO_KEBBI: 'Mayo-Kebbi',
	STATE_MOYEN_CHARI: 'Moyen-Chari',
	STATE_OUADDAI: 'Ouaddai',
	STATE_SALAMAT: 'Salamat',
	STATE_TANDJILE: 'Tandjile',
	STATE_AISEN: 'Aisen',
	STATE_ANTOFAGASTA: 'Antofagasta',
	STATE_ARAUCANIA: 'Araucania',
	STATE_ATACAMA: 'Atacama',
	STATE_BIO_BIO: 'Bio Bio',
	STATE_COQUIMBO: 'Coquimbo',
	STATE_LIBERTADOR_GENERAL_BERNARDO_O: 'Libertador General Bernardo O',
	STATE_LOS_LAGOS: 'Los Lagos',
	STATE_MAGELLANES: 'Magellanes',
	STATE_MAULE: 'Maule',
	STATE_METROPOLITANA: 'Metropolitana',
	STATE_METROPOLITANA_DE_SANTIAGO: 'Metropolitana de Santiago',
	STATE_TARAPACA: 'Tarapaca',
	STATE_VALPARAISO: 'Valparaiso',
	STATE_ANHUI: 'Anhui',
	STATE_ANHUI_PROVINCE: 'Anhui Province',
	STATE_ANHUI_SHENG: 'Anhui Sheng',
	STATE_AOMEN: 'Aomen',
	STATE_BEIJING: 'Beijing',
	STATE_BEIJING_SHI: 'Beijing Shi',
	STATE_CHONGQING: 'Chongqing',
	STATE_FUJIAN: 'Fujian',
	STATE_FUJIAN_SHENG: 'Fujian Sheng',
	STATE_GANSU: 'Gansu',
	STATE_GUANGDONG: 'Guangdong',
	STATE_GUANGDONG_SHENG: 'Guangdong Sheng',
	STATE_GUANGXI: 'Guangxi',
	STATE_GUIZHOU: 'Guizhou',
	STATE_HAINAN: 'Hainan',
	STATE_HEBEI: 'Hebei',
	STATE_HEILONGJIANG: 'Heilongjiang',
	STATE_HENAN: 'Henan',
	STATE_HUBEI: 'Hubei',
	STATE_HUNAN: 'Hunan',
	STATE_JIANGSU: 'Jiangsu',
	STATE_JIANGSU_SHENG: 'Jiangsu Sheng',
	STATE_JIANGXI: 'Jiangxi',
	STATE_JILIN: 'Jilin',
	STATE_LIAONING: 'Liaoning',
	STATE_LIAONING_SHENG: 'Liaoning Sheng',
	STATE_NEI_MONGGOL: 'Nei Monggol',
	STATE_NINGXIA_HUI: 'Ningxia Hui',
	STATE_QINGHAI: 'Qinghai',
	STATE_SHAANXI: 'Shaanxi',
	STATE_SHANDONG: 'Shandong',
	STATE_SHANDONG_SHENG: 'Shandong Sheng',
	STATE_SHANGHAI: 'Shanghai',
	STATE_SHANXI: 'Shanxi',
	STATE_SICHUAN: 'Sichuan',
	STATE_TIANJIN: 'Tianjin',
	STATE_XIANGGANG: 'Xianggang',
	STATE_XINJIANG: 'Xinjiang',
	STATE_XIZANG: 'Xizang',
	STATE_YUNNAN: 'Yunnan',
	STATE_ZHEJIANG: 'Zhejiang',
	STATE_ZHEJIANG_SHENG: 'Zhejiang Sheng',
	STATE_CHRISTMAS_ISLAND: 'Christmaseiland',
	STATE_COCOS_KEELING_ISLANDS: 'Cocoseilanden',
	STATE_ANTIOQUIA: 'Antioquia',
	STATE_ARAUCA: 'Arauca',
	STATE_ATLANTICO: 'Atlantico',
	STATE_BOGOTA: 'Bogota',
	STATE_BOLIVAR: 'Bolivar',
	STATE_BOYACA: 'Boyaca',
	STATE_CALDAS: 'Caldas',
	STATE_CAQUETA: 'Caqueta',
	STATE_CASANARE: 'Casanare',
	STATE_CAUCA: 'Cauca',
	STATE_CESAR: 'Cesar',
	STATE_CHOCO: 'Choco',
	STATE_CUNDINAMARCA: 'Cundinamarca',
	STATE_GUAINIA: 'Guainia',
	STATE_GUAVIARE: 'Guaviare',
	STATE_LA_GUAJIRA: 'La Guajira',
	STATE_MAGDALENA: 'Magdalena',
	STATE_META: 'Meta',
	STATE_NARINO: 'Narino',
	STATE_NORTE_DE_SANTANDER: 'Norte de Santander',
	STATE_PUTUMAYO: 'Putumayo',
	STATE_QUINDIO: 'Quindio',
	STATE_RISARALDA: 'Risaralda',
	STATE_SAN_ANDRES_Y_PROVIDENCIA: 'San Andres y Providencia',
	STATE_SANTANDER: 'Santander',
	STATE_SUCRE: 'Sucre',
	STATE_TOLIMA: 'Tolima',
	STATE_VALLE_DEL_CAUCA: 'Valle del Cauca',
	STATE_VAUPES: 'Vaupes',
	STATE_VICHADA: 'Vichada',
	STATE_MWALI: 'Mwali',
	STATE_NJAZIDJA: 'Njazidja',
	STATE_NZWANI: 'Nzwani',
	STATE_BOUENZA: 'Bouenza',
	STATE_BRAZZAVILLE: 'Brazzaville',
	STATE_CUVETTE: 'Cuvette',
	STATE_KOUILOU: 'Kouilou',
	STATE_LEKOUMOU: 'Lekoumou',
	STATE_LIKOUALA: 'Likouala',
	STATE_NIARI: 'Niari',
	STATE_PLATEAUX: 'Plateaux',
	STATE_POOL: 'Pool',
	STATE_SANGHA: 'Sangha',
	STATE_BANDUNDU: 'Bandundu',
	STATE_BAS_CONGO: 'Bas-Congo',
	STATE_EQUATEUR: 'Equateur',
	STATE_HAUT_CONGO: 'Haut-Congo',
	STATE_KASAI_OCCIDENTAL: 'Kasai-Occidental',
	STATE_KASAI_ORIENTAL: 'Kasai-Oriental',
	STATE_KATANGA: 'Katanga',
	STATE_KINSHASA: 'Kinshasa',
	STATE_MANIEMA: 'Maniema',
	STATE_NORD_KIVU: 'Nord-Kivu',
	STATE_SUD_KIVU: 'Sud-Kivu',
	STATE_AITUTAKI: 'Aitutaki',
	STATE_ATIU: 'Atiu',
	STATE_MANGAIA: 'Mangaia',
	STATE_MANIHIKI: 'Manihiki',
	STATE_MAUKE: 'Mauke',
	STATE_MITIARO: 'Mitiaro',
	STATE_NASSAU: 'Nassau',
	STATE_PUKAPUKA: 'Pukapuka',
	STATE_RAKAHANGA: 'Rakahanga',
	STATE_RAROTONGA: 'Rarotonga',
	STATE_TONGAREVA: 'Tongareva',
	STATE_ALAJUELA: 'Alajuela',
	STATE_CARTAGO: 'Cartago',
	STATE_GUANACASTE: 'Guanacaste',
	STATE_HEREDIA: 'Heredia',
	STATE_LIMON: 'Limon',
	STATE_PUNTARENAS: 'Puntarenas',
	STATE_SAN_JOSE: 'San Jose',
	STATE_ABIDJAN: 'Abidjan',
	STATE_AGNEBY: 'Agneby',
	STATE_BAFING: 'Bafing',
	STATE_DENGUELE: 'Denguele',
	STATE_DIX_HUIT_MONTAGNES: 'Dix-huit Montagnes',
	STATE_FROMAGER: 'Fromager',
	STATE_HAUT_SASSANDRA: 'Haut-Sassandra',
	STATE_LACS: 'Lacs',
	STATE_LAGUNES: 'Lagunes',
	STATE_MARAHOUE: 'Marahoue',
	STATE_MOYEN_CAVALLY: 'Moyen-Cavally',
	STATE_MOYEN_COMOE: 'Moyen-Comoe',
	STATE_N__ZI_COMOE: "N'zi-Comoe",
	STATE_SASSANDRA: 'Sassandra',
	STATE_SAVANES: 'Savanes',
	STATE_SUD_BANDAMA: 'Sud-Bandama',
	STATE_SUD_COMOE: 'Sud-Comoe',
	STATE_VALLEE_DU_BANDAMA: 'Vallee du Bandama',
	STATE_WORODOUGOU: 'Worodougou',
	STATE_ZANZAN: 'Zanzan',
	STATE_BJELOVAR_BILOGORA: 'Bjelovar-Bilogora',
	STATE_DUBROVNIK_NERETVA: 'Dubrovnik-Neretva',
	STATE_GRAD_ZAGREB: 'Grad Zagreb',
	STATE_ISTRA: 'Istra',
	STATE_KARLOVAC: 'Karlovac',
	STATE_KOPRIVNICA_KRIZHEVCI: 'Koprivnica-Krizhevci',
	STATE_KRAPINA_ZAGORJE: 'Krapina-Zagorje',
	STATE_LIKA_SENJ: 'Lika-Senj',
	STATE_MEDHIMURJE: 'Medhimurje',
	STATE_MEDIMURSKA_ZUPANIJA: 'Medimurska Zupanija',
	STATE_OSIJEK_BARANJA: 'Osijek-Baranja',
	STATE_OSJECKO_BARANJSKA_ZUPANIJA: 'Osjecko-Baranjska Zupanija',
	STATE_POZHEGA_SLAVONIJA: 'Pozhega-Slavonija',
	STATE_PRIMORJE_GORSKI_KOTAR: 'Primorje-Gorski Kotar',
	STATE_SHIBENIK_KNIN: 'Shibenik-Knin',
	STATE_SISAK_MOSLAVINA: 'Sisak-Moslavina',
	STATE_SLAVONSKI_BROD_POSAVINA: 'Slavonski Brod-Posavina',
	STATE_SPLIT_DALMACIJA: 'Split-Dalmacija',
	STATE_VARAZHDIN: 'Varazhdin',
	STATE_VIROVITICA_PODRAVINA: 'Virovitica-Podravina',
	STATE_VUKOVAR_SRIJEM: 'Vukovar-Srijem',
	STATE_ZADAR: 'Zadar',
	STATE_ZAGREB: 'Zagreb',
	STATE_CAMAGUEY: 'Camaguey',
	STATE_CIEGO_DE_AVILA: 'Ciego de Avila',
	STATE_CIENFUEGOS: 'Cienfuegos',
	STATE_CIUDAD_DE_LA_HABANA: 'Ciudad de la Habana',
	STATE_GRANMA: 'Granma',
	STATE_GUANTANAMO: 'Guantanamo',
	STATE_HABANA: 'Habana',
	STATE_HOLGUIN: 'Holguin',
	STATE_ISLA_DE_LA_JUVENTUD: 'Isla de la Juventud',
	STATE_LA_HABANA: 'La Habana',
	STATE_LAS_TUNAS: 'Las Tunas',
	STATE_MATANZAS: 'Matanzas',
	STATE_PINAR_DEL_RIO: 'Pinar del Rio',
	STATE_SANCTI_SPIRITUS: 'Sancti Spiritus',
	STATE_SANTIAGO_DE_CUBA: 'Santiago de Cuba',
	STATE_VILLA_CLARA: 'Villa Clara',
	STATE_GOVERNMENT_CONTROLLED_AREA: 'Door de overheid beheerde gebieden',
	STATE_LIMASSOL: 'Limassol',
	STATE_NICOSIA_DISTRICT: 'Nicosia-district',
	STATE_PAPHOS: 'Paphos',
	STATE_TURKISH_CONTROLLED_AREA: 'Door Turkije beheerde gebieden',
	STATE_CENTRAL_BOHEMIAN: 'Central Boteti',
	STATE_FRYCOVICE: 'Frycovice',
	STATE_JIHOCESKY_KRAJ: 'Jihocesky Kraj',
	STATE_JIHOCHESKY: 'Jihochesky',
	STATE_JIHOMORAVSKY: 'Jihomoravsky',
	STATE_KARLOVARSKY: 'Karlovarsky',
	STATE_KLECANY: 'Klecany',
	STATE_KRALOVEHRADECKY: 'Kralovehradecky',
	STATE_LIBERECKY: 'Liberecky',
	STATE_LIPOV: 'Lipov',
	STATE_MORAVSKOSLEZSKY: 'Moravskoslezsky',
	STATE_OLOMOUCKY: 'Olomoucky',
	STATE_OLOMOUCKY_KRAJ: 'Olomoucky Kraj',
	STATE_PARDUBICKY: 'Pardubicky',
	STATE_PLZENSKY: 'Plzensky',
	STATE_PRAHA: 'Praag',
	STATE_RAJHRAD: 'Rajhrad',
	STATE_SMIRICE: 'Smirice',
	STATE_SOUTH_MORAVIAN: 'Zuid-Moravië',
	STATE_STRAZ_NAD_NISOU: 'Straz nad Nisou',
	STATE_STREDOCHESKY: 'Stredochesky',
	STATE_UNICOV: 'Unicov',
	STATE_USTECKY: 'Ustecky',
	STATE_VALLETTA: 'Valletta',
	STATE_VELESIN: 'Velesin',
	STATE_VYSOCHINA: 'Vysochina',
	STATE_ZLINSKY: 'Zlinsky',
	STATE_BORNHOLM: 'Bornholm',
	STATE_FREDERIKSBORG: 'Frederiksborg',
	STATE_FYN: 'Fyn',
	STATE_HOVEDSTADEN: 'Hovedstaden',
	STATE_KOBENHAVN: 'Kopenhagen',
	STATE_KOBENHAVNS_AMT: 'Kopenhagen (provincie)',
	STATE_KOBENHAVNS_KOMMUNE: 'Kopenhagen (gemeente)',
	STATE_NORDJYLLAND: 'Nordjylland',
	STATE_RIBE: 'Ribe',
	STATE_RINGKOBING: 'Ringkobing',
	STATE_ROERVIG: 'Roervig',
	STATE_ROSKILDE: 'Roskilde',
	STATE_ROSLEV: 'Roslev',
	STATE_SJAELLAND: 'Sjaelland',
	STATE_SOEBORG: 'Soeborg',
	STATE_SONDERJYLLAND: 'Sonderjylland',
	STATE_STORSTROM: 'Storstrom',
	STATE_SYDDANMARK: 'Syddanmark',
	STATE_TOELLOESE: 'Toelloese',
	STATE_VEJLE: 'Vejle',
	STATE_VESTSJALLAND: 'Vestsjalland',
	STATE_VIBORG: 'Viborg',
	STATE_ALI_SABIH: 'Ali Sabih',
	STATE_DIKHIL: 'Dikhil',
	STATE_JIBUTI: 'Djibouti',
	STATE_TAJURAH: 'Tajurah',
	STATE_UBUK: 'Ubuk',
	STATE_SAINT_DAVID: 'Saint David',
	STATE_SAINT_LUKE: 'Saint Luke',
	STATE_SAINT_MARK: 'Saint Mark',
	STATE_SAINT_PATRICK: 'Saint Patrick',
	STATE_AZUA: 'Azua',
	STATE_BAHORUCO: 'Bahoruco',
	STATE_BARAHONA: 'Barahona',
	STATE_DAJABON: 'Dajabon',
	STATE_DISTRITO_NACIONAL: 'Distrito Nacional',
	STATE_DUARTE: 'Duarte',
	STATE_EL_SEYBO: 'El Seybo',
	STATE_ELIAS_PINA: 'Elias Pina',
	STATE_ESPAILLAT: 'Espaillat',
	STATE_HATO_MAYOR: 'Hato Mayor',
	STATE_INDEPENDENCIA: 'Independencia',
	STATE_LA_ALTAGRACIA: 'La Altagracia',
	STATE_LA_ROMANA: 'La Romana',
	STATE_LA_VEGA: 'La Vega',
	STATE_MARIA_TRINIDAD_SANCHEZ: 'Maria Trinidad Sanchez',
	STATE_MONSENOR_NOUEL: 'Monsenor Nouel',
	STATE_MONTE_CRISTI: 'Monte Cristi',
	STATE_MONTE_PLATA: 'Monte Plata',
	STATE_PEDERNALES: 'Pedernales',
	STATE_PERAVIA: 'Peravia',
	STATE_PUERTO_PLATA: 'Puerto Plata',
	STATE_SALCEDO: 'Salcedo',
	STATE_SAMANA: 'Samana',
	STATE_SAN_CRISTOBAL: 'San Cristobal',
	STATE_SAN_PEDRO_DE_MACORIS: 'San Pedro de Macoris',
	STATE_SANCHEZ_RAMIREZ: 'Sanchez Ramirez',
	STATE_SANTIAGO: 'Santiago',
	STATE_SANTIAGO_RODRIGUEZ: 'Santiago Rodriguez',
	STATE_VALVERDE: 'Valverde',
	STATE_AILEU: 'Aileu',
	STATE_AINARO: 'Ainaro',
	STATE_AMBENO: 'Ambeno',
	STATE_BAUCAU: 'Baucau',
	STATE_BOBONARO: 'Bobonaro',
	STATE_COVA_LIMA: 'Cova Lima',
	STATE_DILI: 'Dili',
	STATE_ERMERA: 'Ermera',
	STATE_LAUTEM: 'Lautem',
	STATE_LIQUICA: 'Liquica',
	STATE_MANATUTO: 'Manatuto',
	STATE_MANUFAHI: 'Manufahi',
	STATE_VIQUEQUE: 'Viqueque',
	STATE_AZUAY: 'Azuay',
	STATE_CANAR: 'Canar',
	STATE_CARCHI: 'Carchi',
	STATE_CHIMBORAZO: 'Chimborazo',
	STATE_COTOPAXI: 'Cotopaxi',
	STATE_EL_ORO: 'El Oro',
	STATE_ESMERALDAS: 'Esmeraldas',
	STATE_GALAPAGOS: 'Galapagos',
	STATE_GUAYAS: 'Guayas',
	STATE_IMBABURA: 'Imbabura',
	STATE_LOJA: 'Loja',
	STATE_LOS_RIOS: 'Los Rios',
	STATE_MANABI: 'Manabi',
	STATE_MORONA_SANTIAGO: 'Morona Santiago',
	STATE_NAPO: 'Napo',
	STATE_ORELLANA: 'Orellana',
	STATE_PASTAZA: 'Pastaza',
	STATE_PICHINCHA: 'Pichincha',
	STATE_SUCUMBIOS: 'Sucumbios',
	STATE_TUNGURAHUA: 'Tungurahua',
	STATE_ZAMORA_CHINCHIPE: 'Zamora Chinchipe',
	STATE_ASWAN: 'Aswan',
	STATE_ASYUT: 'Asyut',
	STATE_BANI_SUWAYF: 'Bani Suwayf',
	STATE_BUR_SA__ID: "Bur Sa'id",
	STATE_CAIRO: 'Cairo',
	STATE_DUMYAT: 'Dumyat',
	STATE_KAFR_ASH_SHAYKH: 'Kafr-ash-Shaykh',
	STATE_MATRUH: 'Matruh',
	STATE_MUHAFAZAT_AD_DAQAHLIYAH: 'Muhafazat ad Daqahliyah',
	STATE_MUHAFAZAT_AL_FAYYUM: 'Muhafazat al Fayyum',
	STATE_MUHAFAZAT_AL_GHARBIYAH: 'Muhafazat al Gharbiyah',
	STATE_MUHAFAZAT_AL_ISKANDARIYAH: 'Muhafazat al Iskandariyah',
	STATE_MUHAFAZAT_AL_QAHIRAH: 'Muhafazat al Qahirah',
	STATE_QINA: 'Qina',
	STATE_SAWHAJ: 'Sawhaj',
	STATE_SINA_AL_JANUBIYAH: 'Sina al-Janubiyah',
	STATE_SINA_ASH_SHAMALIYAH: 'Sina ash-Shamaliyah',
	STATE_AD_DAQAHLIYAH: 'ad-Daqahliyah',
	STATE_AL_BAHR_AL_AHMAR: 'al-Bahr-al-Ahmar',
	STATE_AL_BUHAYRAH: 'al-Buhayrah',
	STATE_AL_FAYYUM: 'al-Fayyum',
	STATE_AL_GHARBIYAH: 'al-Gharbiyah',
	STATE_AL_ISKANDARIYAH: 'al-Iskandariyah',
	STATE_AL_ISMAILIYAH: 'al-Ismailiyah',
	STATE_AL_JIZAH: 'al-Jizah',
	STATE_AL_MINUFIYAH: 'al-Minufiyah',
	STATE_AL_MINYA: 'al-Minya',
	STATE_AL_QAHIRA: 'al-Qahira',
	STATE_AL_QALYUBIYAH: 'al-Qalyubiyah',
	STATE_AL_UQSUR: 'al-Uqsur',
	STATE_AL_WADI_AL_JADID: 'al-Wadi al-Jadid',
	STATE_AS_SUWAYS: 'as-Suways',
	STATE_ASH_SHARQIYAH: 'ash-Sharqiyah',
	STATE_AHUACHAPAN: 'Ahuachapan',
	STATE_CABANAS: 'Cabanas',
	STATE_CHALATENANGO: 'Chalatenango',
	STATE_CUSCATLAN: 'Cuscatlan',
	STATE_LA_LIBERTAD: 'La Libertad',
	STATE_LA_UNION: 'La Union',
	STATE_MORAZAN: 'Morazan',
	STATE_SAN_MIGUEL: 'San Miguel',
	STATE_SAN_VICENTE: 'San Vicente',
	STATE_SANTA_ANA: 'Santa Ana',
	STATE_SONSONATE: 'Sonsonate',
	STATE_USULUTAN: 'Usulutan',
	STATE_ANNOBON: 'Annobon',
	STATE_BIOKO_NORTE: 'Bioko Norte',
	STATE_BIOKO_SUR: 'Bioko Sur',
	STATE_CENTRO_SUR: 'Centro Sur',
	STATE_KIE_NTEM: 'Kie-Ntem',
	STATE_LITORAL: 'Litoral',
	STATE_WELE_NZAS: 'Wele-Nzas',
	STATE_ANSEBA: 'Anseba',
	STATE_DEBUB: 'Debub',
	STATE_DEBUB_KEIH_BAHRI: 'Debub-Keih-Bahri',
	STATE_GASH_BARKA: 'Gash-Barka',
	STATE_MAEKEL: 'Maekel',
	STATE_SEMIEN_KEIH_BAHRI: 'Semien-Keih-Bahri',
	STATE_HARJU: 'Harju',
	STATE_HIIU: 'Hiiu',
	STATE_IDA_VIRU: 'Ida-Viru',
	STATE_JARVA: 'Jarva',
	STATE_JOGEVA: 'Jogeva',
	STATE_LAANE: 'Laane',
	STATE_LAANE_VIRU: 'Laane-Viru',
	STATE_PARNU: 'Parnu',
	STATE_POLVA: 'Polva',
	STATE_RAPLA: 'Rapla',
	STATE_SAARE: 'Saare',
	STATE_TARTU: 'Tartu',
	STATE_VALGA: 'Valga',
	STATE_VILJANDI: 'Viljandi',
	STATE_VORU: 'Voru',
	STATE_ADDIS_ABEBA: 'Addis Abeba',
	STATE_AFAR: 'Afar',
	STATE_AMHARA: 'Amhara',
	STATE_BENISHANGUL: 'Benishangul',
	STATE_DIREDAWA: 'Diredawa',
	STATE_GAMBELLA: 'Gambella',
	STATE_HARAR: 'Harar',
	STATE_JIGJIGA: 'Jigjiga',
	STATE_MEKELE: 'Mekele',
	STATE_OROMIA: 'Oromia',
	STATE_SOMALI: 'Somalië',
	STATE_SOUTHERN: 'Zuidelijk',
	STATE_TIGRAY: 'Tigray',
	STATE_FALKLAND_ISLANDS: 'Falklandeilanden',
	STATE_SOUTH_GEORGIA: 'Zuid-Georgia',
	STATE_KLAKSVIK: 'Klaksvik',
	STATE_NOR_ARA_EYSTUROY: 'Nor ara Eysturoy',
	STATE_NOR_OY: 'Nor oy',
	STATE_SANDOY: 'Sandoy',
	STATE_STREYMOY: 'Streymoy',
	STATE_SU_UROY: 'Su uroy',
	STATE_SY_RA_EYSTUROY: 'Sy ra Eysturoy',
	STATE_TORSHAVN: 'Torshavn',
	STATE_VAGA: 'Vaga',
	STATE_CENTRAL: 'Central',
	STATE_NORTHERN: 'Noordelijk',
	STATE_SOUTH_PACIFIC: 'Stille Oceaan',
	STATE_AHVENANMAA: 'Ahvenanmaa',
	STATE_ETELA_KARJALA: 'Etela-Karjala',
	STATE_ETELA_POHJANMAA: 'Etela-Pohjanmaa',
	STATE_ETELA_SAVO: 'Etela-Savo',
	STATE_ETELA_SUOMEN_LAANI: 'Etela-Suomen Laani',
	STATE_ITA_SUOMEN_LAANI: 'Ita-Suomen Laani',
	STATE_ITA_UUSIMAA: 'Ita-Uusimaa',
	STATE_KAINUU: 'Kainuu',
	STATE_KANTA_HAME: 'Kanta-Hame',
	STATE_KESKI_POHJANMAA: 'Keski-Pohjanmaa',
	STATE_KESKI_SUOMI: 'Keski-Suomi',
	STATE_KYMENLAAKSO: 'Kymenlaakso',
	STATE_LANSI_SUOMEN_LAANI: 'Lansi-Suomen Laani',
	STATE_LAPPI: 'Lappi',
	STATE_NORTHERN_SAVONIA: 'Noord-Savonië',
	STATE_OSTROBOTHNIA: 'Ostrobothnia',
	STATE_OULUN_LAANI: 'Oulun Laani',
	STATE_PAIJAT_HAME: 'Paijat-Hame',
	STATE_PIRKANMAA: 'Pirkanmaa',
	STATE_POHJANMAA: 'Pohjanmaa',
	STATE_POHJOIS_KARJALA: 'Pohjois-Karjala',
	STATE_POHJOIS_POHJANMAA: 'Pohjois-Pohjanmaa',
	STATE_POHJOIS_SAVO: 'Pohjois-Savo',
	STATE_SAARIJARVI: 'Saarijarvi',
	STATE_SATAKUNTA: 'Satakunta',
	STATE_SOUTHERN_SAVONIA: 'Zuid-Savonië',
	STATE_TAVASTIA_PROPER: 'Tavastia Proper',
	STATE_ULEABORGS_LAN: 'Uleaborgs Lan',
	STATE_UUSIMAA: 'Uusimaa',
	STATE_VARSINAIS_SUOMI: 'Varsinais-Suomi',
	STATE_AIN: 'Ain',
	STATE_AISNE: 'Aisne',
	STATE_ALBI_LE_SEQUESTRE: 'Albi Le Sequestre',
	STATE_ALLIER: 'Allier',
	STATE_ALPES_COTE_DAZUR: 'Alpes-Cote dAzur',
	STATE_ALPES_MARITIMES: 'Alpes-Maritimes',
	STATE_ALPES_DE_HAUTE_PROVENCE: 'Alpes-de-Haute-Provence',
	STATE_ALSACE: 'Alsace',
	STATE_AQUITAINE: 'Aquitaine',
	STATE_ARDECHE: 'Ardeche',
	STATE_ARDENNES: 'Ardennen',
	STATE_ARIEGE: ' Ariège',
	STATE_AUBE: 'Aube',
	STATE_AUDE: 'Aude',
	STATE_AUVERGNE: 'Auvergne',
	STATE_AVEYRON: 'Aveyron',
	STATE_BAS_RHIN: 'Bas-Rhin',
	STATE_BASSE_NORMANDIE: 'Basse-Normandie',
	STATE_BOUCHES_DU_RHONE: 'Bouches-du-Rhone',
	STATE_BOURGOGNE: 'Bourgogne',
	STATE_BRETAGNE: 'Bretagne',
	STATE_BRITTANY: 'Bretagne',
	STATE_BURGUNDY: 'Boergondië',
	STATE_CALVADOS: 'Calvados',
	STATE_CANTAL: 'Cantal',
	STATE_CEDEX: 'Cedex',
	STATE_CHARENTE: 'Charente',
	STATE_CHARENTE_MARITIME: 'Charente-Maritime',
	STATE_CHER: 'Cher',
	STATE_CORREZE: 'Correze',
	STATE_CORSE_DU_SUD: 'Corse-du-Sud',
	STATE_COTE_D__OR: "Cote-d'Or",
	STATE_COTES_D__ARMOR: "Cotes-d'Armor",
	STATE_CREUSE: 'Creuse',
	STATE_CROLLES: 'Crolles',
	STATE_DEUX_SEVRES: 'Deux-Sevres',
	STATE_DORDOGNE: 'Dordogne',
	STATE_DOUBS: 'Doubs',
	STATE_DROME: 'Drôme',
	STATE_ESSONNE: 'Essonne',
	STATE_EURE: 'Eure',
	STATE_EURE_ET_LOIR: 'Eure-et-Loir',
	STATE_FEUCHEROLLES: 'Feucherolles',
	STATE_FINISTERE: 'Finistere',
	STATE_FRANCHE_COMTE: 'Franche-Comte',
	STATE_GARD: 'Gard',
	STATE_GERS: 'Gers',
	STATE_GIRONDE: 'Gironde',
	STATE_HAUT_RHIN: 'Haut-Rhin',
	STATE_HAUTE_CORSE: 'Haute-Corse',
	STATE_HAUTE_GARONNE: 'Haute-Garonne',
	STATE_HAUTE_LOIRE: 'Haute-Loire',
	STATE_HAUTE_MARNE: 'Haute-Marne',
	STATE_HAUTE_SAONE: 'Haute-Saone',
	STATE_HAUTE_SAVOIE: 'Haute-Savoie',
	STATE_HAUTE_VIENNE: 'Haute-Vienne',
	STATE_HAUTES_ALPES: 'Hautes-Alpes',
	STATE_HAUTES_PYRENEES: 'Hautes-Pyrenees',
	STATE_HAUTS_DE_SEINE: 'Hauts-de-Seine',
	STATE_HERAULT: 'Herault',
	STATE_ILE_DE_FRANCE: 'Ile-de-France',
	STATE_ILLE_ET_VILAINE: 'Ille-et-Vilaine',
	STATE_INDRE: 'Indre',
	STATE_INDRE_ET_LOIRE: 'Indre-et-Loire',
	STATE_ISERE: 'Isere',
	STATE_JURA: 'Jura',
	STATE_KLAGENFURT: 'Klagenfurt',
	STATE_LANDES: 'Landes',
	STATE_LANGUEDOC_ROUSSILLON: 'Languedoc-Roussillon',
	STATE_LARCAY: 'Larcay',
	STATE_LE_CASTELLET: 'Le Castellet',
	STATE_LE_CREUSOT: 'Le Creusot',
	STATE_LIMOUSIN: 'Limousin',
	STATE_LOIR_ET_CHER: 'Loir-et-Cher',
	STATE_LOIRE: 'Loire',
	STATE_LOIRE_ATLANTIQUE: 'Loire-Atlantique',
	STATE_LOIRET: 'Loiret',
	STATE_LORRAINE: 'Lorraine',
	STATE_LOT: 'Lot',
	STATE_LOT_ET_GARONNE: 'Lot-et-Garonne',
	STATE_LOWER_NORMANDY: 'Laag-Normandië',
	STATE_LOZERE: 'Lozère',
	STATE_MAINE_ET_LOIRE: 'Maine-et-Loire',
	STATE_MANCHE: 'Manche',
	STATE_MARNE: 'Marne',
	STATE_MAYENNE: 'Mayenne',
	STATE_MEURTHE_ET_MOSELLE: 'Meurthe-et-Moselle',
	STATE_MEUSE: 'Meuse',
	STATE_MIDI_PYRENEES: 'Midi-Pyrenees',
	STATE_MORBIHAN: 'Morbihan',
	STATE_MOSELLE: 'Moselle',
	STATE_NIEVRE: 'Nièvre',
	STATE_NORD_PAS_DE_CALAIS: 'Nord-Pas-de-Calais',
	STATE_OISE: 'Oise',
	STATE_ORNE: 'Orne',
	STATE_PARIS: 'Parijs',
	STATE_PAS_DE_CALAIS: 'Pas-de-Calais',
	STATE_PAYS_DE_LA_LOIRE: 'Pays de la Loire',
	STATE_PICARDY: 'Picardy',
	STATE_PUY_DE_DOME: 'Puy-de-Dome',
	STATE_PYRENEES_ATLANTIQUES: 'Pyrenees-Atlantiques',
	STATE_PYRENEES_ORIENTALES: 'Pyrenees-Orientales',
	STATE_QUELMES: 'Quelmes',
	STATE_RHONE: 'Rhône',
	STATE_RHONE_ALPES: 'Rhône-Alpes',
	STATE_SAINT_OUEN: 'Saint Ouen',
	STATE_SAINT_VIATRE: 'Saint Viatre',
	STATE_SAONE_ET_LOIRE: 'Saône-et-Loire',
	STATE_SARTHE: 'Sarthe',
	STATE_SAVOIE: 'Savoie',
	STATE_SEINE_MARITIME: 'Seine-Maritime',
	STATE_SEINE_SAINT_DENIS: 'Seine-Saint-Denis',
	STATE_SEINE_ET_MARNE: 'Seine-et-Marne',
	STATE_SOMME: 'Somme',
	STATE_SOPHIA_ANTIPOLIS: 'Sophia Antipolis',
	STATE_SOUVANS: 'Souvans',
	STATE_TARN: 'Tarn',
	STATE_TARN_ET_GARONNE: 'Tarn-et-Garonne',
	STATE_TERRITOIRE_DE_BELFORT: 'Territoire de Belfort',
	STATE_TREIGNAC: 'Treignac',
	STATE_UPPER_NORMANDY: 'Opper-Normandië',
	STATE_VAL_D__OISE: "Val-d'Oise",
	STATE_VAL_DE_MARNE: 'Val-de-Marne',
	STATE_VAR: 'Var',
	STATE_VAUCLUSE: 'Vaucluse',
	STATE_VELLISE: 'Vellise',
	STATE_VENDEE: 'Vendee',
	STATE_VIENNE: 'Vienne',
	STATE_VOSGES: 'Vogezen',
	STATE_YONNE: 'Yonne',
	STATE_YVELINES: 'Yvelines',
	STATE_CAYENNE: 'Cayenne',
	STATE_SAINT_LAURENT_DU_MARONI: 'Saint-Laurent-du-Maroni',
	STATE_ILES_DU_VENT: 'Iles du Vent',
	STATE_ILES_SOUS_LE_VENT: 'Iles sous le Vent',
	STATE_MARQUESAS: 'Marquesas',
	STATE_TUAMOTU: 'Tuamotu',
	STATE_TUBUAI: 'Tubuai',
	STATE_AMSTERDAM: 'Amsterdam',
	STATE_CROZET_ISLANDS: 'Crozeteilanden',
	STATE_KERGUELEN: 'Kerguelen',
	STATE_ESTUAIRE: 'Estuaire',
	STATE_HAUT_OGOOUE: 'Haut-Ogooue',
	STATE_MOYEN_OGOOUE: 'Moyen-Ogooue',
	STATE_NGOUNIE: 'Ngounie',
	STATE_NYANGA: 'Nyanga',
	STATE_OGOOUE_IVINDO: 'Ogooue-Ivindo',
	STATE_OGOOUE_LOLO: 'Ogooue-Lolo',
	STATE_OGOOUE_MARITIME: 'Ogooue-Maritime',
	STATE_WOLEU_NTEM: 'Woleu-Ntem',
	STATE_BANJUL: 'Banjul',
	STATE_BASSE: 'Basse',
	STATE_BRIKAMA: 'Brikama',
	STATE_JANJANBUREH: 'Janjanbureh',
	STATE_KANIFING: 'Kanifing',
	STATE_KEREWAN: 'Kerewan',
	STATE_KUNTAUR: 'Kuntaur',
	STATE_MANSAKONKO: 'Mansakonko',
	STATE_ABHASIA: 'Abhasia',
	STATE_AJARIA: 'Ajaria',
	STATE_GURIA: 'Guria',
	STATE_IMERETI: 'Imereti',
	STATE_KAHETI: 'Kaheti',
	STATE_KVEMO_KARTLI: 'Kvemo Kartli',
	STATE_MCHETA_MTIANETI: 'Mcheta-Mtianeti',
	STATE_RACHA: 'Racha',
	STATE_SAMAGRELO_ZEMO_SVANETI: 'Samagrelo-Zemo Svaneti',
	STATE_SAMCHE_ZHAVAHETI: 'Samche-Zhavaheti',
	STATE_SHIDA_KARTLI: 'Shida Kartli',
	STATE_TBILISI: 'Tbilisi',
	STATE_BADEN_WURTTEMBERG: 'Baden-Württemberg,',
	STATE_BAVARIA: 'Bavaria',
	STATE_BAYERN: 'Beieren',
	STATE_BEILSTEIN_WURTT: 'Beilstein Württemberg,',
	STATE_BERLIN: 'Berlijn',
	STATE_BRANDENBURG: 'Brandenburg',
	STATE_BREMEN: 'Bremen',
	STATE_DREISBACH: 'Dreisbach',
	STATE_FREISTAAT_BAYERN: 'Vrijstaat Beieren',
	STATE_HAMBURG: 'Hamburg',
	STATE_HANNOVER: 'Hannover',
	STATE_HEROLDSTATT: 'Heroldstatt',
	STATE_HESSEN: 'Hessen',
	STATE_KORTENBERG: 'Kortenberg',
	STATE_LAASDORF: 'Laasdorf',
	STATE_LAND_BADEN_WURTTEMBERG: 'Deelstaat Baden-Württemberg',
	STATE_LAND_BAYERN: 'Deelstaat Beieren',
	STATE_LAND_BRANDENBURG: 'Deelstaat Brandenburg',
	STATE_LAND_HESSEN: 'Deelstaat Hessen',
	STATE_LAND_MECKLENBURG_VORPOMMERN: 'Deelstaat Mecklenburg-Voor-Pommeren',
	STATE_LAND_NORDRHEIN_WESTFALEN: 'Deelstaat Noordrijn-Westfalen',
	STATE_LAND_RHEINLAND_PFALZ: 'Deelstaat Rijnland-Palts',
	STATE_LAND_SACHSEN: 'Deelstaat Saksen',
	STATE_LAND_SACHSEN_ANHALT: 'Deelstaat Saksen-Anhalt',
	STATE_LAND_THURINGEN: 'Deelstaat Thüringen',
	STATE_LOWER_SAXONY: 'Nedersaksen',
	STATE_MECKLENBURG_VORPOMMERN: 'Mecklenburg-Voor-Pommeren',
	STATE_MULFINGEN: 'Mulfingen',
	STATE_MUNICH: 'München',
	STATE_NEUBEUERN: 'Neubeuern',
	STATE_NIEDERSACHSEN: 'Nedersaksen',
	STATE_NOORD_HOLLAND: 'Noord-Holland',
	STATE_NORDRHEIN_WESTFALEN: 'Noordrijn-Westfalen',
	STATE_NORTH_RHINE_WESTPHALIA: 'Noordrijn-Westfalen',
	STATE_OSTERODE: 'Osterode',
	STATE_RHEINLAND_PFALZ: 'Rijnland-Palts',
	STATE_RHINELAND_PALATINATE: 'Rijnland-Paltz',
	STATE_SAARLAND: 'Saarland',
	STATE_SACHSEN: 'Saksen',
	STATE_SACHSEN_ANHALT: 'Saksen-Anhalt',
	STATE_SAXONY: 'Saksen',
	STATE_THURINGIA: 'Thüringen',
	STATE_WEBLING: 'Webling',
	STATE_WEINSTRABE: 'Weinstraße',
	STATE_SCHLOBBORN: 'Schloßborn',
	STATE_ASHANTI: 'Ashanti',
	STATE_BRONG_AHAFO: 'Brong-Ahafo',
	STATE_GREATER_ACCRA: 'Greater Accra',
	STATE_UPPER_EAST: 'Upper East',
	STATE_UPPER_WEST: 'Upper West',
	STATE_VOLTA: 'Volta',
	STATE_GIBRALTAR: 'Gibraltar',
	STATE_ACHARNES: 'Acharnés',
	STATE_AHAIA: 'Ahaia',
	STATE_AITOLIA_KAI_AKARNANIA: 'Aitolia kai Akarnania',
	STATE_ARGOLIS: 'Argolis',
	STATE_ARKADIA: 'Arkadia',
	STATE_ARTA: 'Arta',
	STATE_ATTICA: 'Attica',
	STATE_ATTIKI: 'Attiki',
	STATE_AYION_OROS: 'Ayion Oros',
	STATE_CRETE: 'Kreta',
	STATE_DODEKANISOS: 'Dodekanisos',
	STATE_DRAMA: 'Drama',
	STATE_EVIA: 'Evia',
	STATE_EVRITANIA: 'Evritania',
	STATE_EVROS: 'Evros',
	STATE_EVVOIA: 'Evvoia',
	STATE_FLORINA: 'Florina',
	STATE_FOKIS: 'Fokis',
	STATE_FTHIOTIS: 'Fthiotis',
	STATE_GREVENA: 'Grevena',
	STATE_HALANDRI: 'Halandri',
	STATE_HALKIDIKI: 'Halkidiki',
	STATE_HANIA: 'Hania',
	STATE_HERAKLION: 'Heraklion',
	STATE_HIOS: 'Hios',
	STATE_ILIA: 'Ilia',
	STATE_IMATHIA: 'Imathia',
	STATE_IOANNINA: 'Ioannina',
	STATE_IRAKLION: 'Iraklion',
	STATE_KARDITSA: 'Karditsa',
	STATE_KASTORIA: 'Kastoria',
	STATE_KAVALA: 'Kavala',
	STATE_KEFALLINIA: 'Kefallinia',
	STATE_KERKIRA: 'Kerkira',
	STATE_KIKLADES: 'Kiklades',
	STATE_KILKIS: 'Kilkis',
	STATE_KORINTHIA: 'Korinthië',
	STATE_KOZANI: 'Kozani',
	STATE_LAKONIA: 'Lakonia',
	STATE_LARISA: 'Larisa',
	STATE_LASITHI: 'Lasithi',
	STATE_LESVOS: 'Lesbos',
	STATE_LEVKAS: 'Levkas',
	STATE_MAGNISIA: 'Magnesië',
	STATE_MESSINIA: 'Messinië',
	STATE_NOMOS_ATTIKIS: 'Nomos Attikis',
	STATE_NOMOS_ZAKYNTHOU: 'Nomos Zakynthou',
	STATE_PELLA: 'Pella',
	STATE_PIERIA: 'Pieria',
	STATE_PIRAIOS: 'Piraeus',
	STATE_PREVEZA: 'Preveza',
	STATE_RETHIMNI: 'Rethimni',
	STATE_RODOPI: 'Rodopi',
	STATE_SAMOS: 'Samos',
	STATE_SERRAI: 'Serrai',
	STATE_THESPROTIA: 'Thesprotia',
	STATE_THESSALONIKI: 'Thessaloniki',
	STATE_TRIKALA: 'Trikala',
	STATE_VOIOTIA: 'Voiotia',
	STATE_WEST_GREECE: 'West-Griekenland',
	STATE_XANTHI: 'Xanthi',
	STATE_ZAKINTHOS: 'Zakinthos',
	STATE_AASIAAT: 'Aasiaat',
	STATE_AMMASSALIK: 'Ammassalik',
	STATE_ILLOQQORTOORMIUT: 'Illoqqortoormiut',
	STATE_ILULISSAT: 'Ilulissat',
	STATE_IVITTUUT: 'Ivittuut',
	STATE_KANGAATSIAQ: 'Kangaatsiaq',
	STATE_MANIITSOQ: 'Maniitsoq',
	STATE_NANORTALIK: 'Nanortalik',
	STATE_NARSAQ: 'Narsaq',
	STATE_NUUK: 'Nuuk',
	STATE_PAAMIUT: 'Paamiut',
	STATE_QAANAAQ: 'Qaanaaq',
	STATE_QAQORTOQ: 'Qaqortoq',
	STATE_QASIGIANNGUIT: 'Qasigiannguit',
	STATE_QEQERTARSUAQ: 'Qeqertarsuaq',
	STATE_SISIMIUT: 'Sisimiut',
	STATE_UDENFOR_KOMMUNAL_INDDELING: 'Udenfor kommunal inddeling',
	STATE_UPERNAVIK: 'Upernavik',
	STATE_UUMMANNAQ: 'Uummannaq',
	STATE_CARRIACOU_PETITE_MARTINIQUE: 'Carriacou-Petite Martinique',
	STATE_SAINT_DAVIDS: 'Saint Davids',
	STATE_SAINT_GEORGE__S: "Saint George's",
	STATE_BASSE_TERRE: 'Basse-Terre',
	STATE_GRANDE_TERRE: 'Grande-Terre',
	STATE_ILES_DES_SAINTES: 'Iles des Saintes',
	STATE_LA_DESIRADE: 'La Desirade',
	STATE_MARIE_GALANTE: 'Marie-Galante',
	STATE_SAINT_BARTHELEMY: 'Saint-Barthélemy',
	STATE_SAINT_MARTIN: 'Sint Maarten',
	STATE_AGANA_HEIGHTS: 'Agana Heights',
	STATE_AGAT: 'Agat',
	STATE_BARRIGADA: 'Barrigada',
	STATE_CHALAN_PAGO_ORDOT: 'Chalan-Pago-Ordot',
	STATE_DEDEDO: 'Dededo',
	STATE_HAGATNA: 'Hagatna',
	STATE_INARAJAN: 'Inarajan',
	STATE_MANGILAO: 'Mangilao',
	STATE_MERIZO: 'Merizo',
	STATE_MONGMONG_TOTO_MAITE: 'Mongmong-Toto-Maite',
	STATE_SANTA_RITA: 'Santa Rita',
	STATE_SINAJANA: 'Sinajana',
	STATE_TALOFOFO: 'Talofofo',
	STATE_TAMUNING: 'Tamuning',
	STATE_YIGO: 'Yigo',
	STATE_YONA: 'Yona',
	STATE_ALTA_VERAPAZ: 'Alta Verapaz',
	STATE_BAJA_VERAPAZ: 'Baja Verapaz',
	STATE_CHIMALTENANGO: 'Chimaltenango',
	STATE_CHIQUIMULA: 'Chiquimula',
	STATE_EL_PROGRESO: 'El Progreso',
	STATE_ESCUINTLA: 'Escuintla',
	STATE_GUATEMALA: 'Guatemala',
	STATE_HUEHUETENANGO: 'Huehuetenango',
	STATE_IZABAL: 'Izabal',
	STATE_JALAPA: 'Jalapa',
	STATE_JUTIAPA: 'Jutiapa',
	STATE_PETEN: 'Peten',
	STATE_QUEZALTENANGO: 'Quezaltenango',
	STATE_QUICHE: 'Quiche',
	STATE_RETALHULEU: 'Retalhuleu',
	STATE_SACATEPEQUEZ: 'Sacatepequez',
	STATE_SAN_MARCOS: 'San Marcos',
	STATE_SANTA_ROSA: 'Santa Rosa',
	STATE_SOLOLA: 'Solola',
	STATE_SUCHITEPEQUEZ: 'Suchitepequez',
	STATE_TOTONICAPAN: 'Totonicapan',
	STATE_ZACAPA: 'Zacapa',
	STATE_ALDERNEY: 'Alderney',
	STATE_CASTEL: 'Castel',
	STATE_FOREST: 'Forest',
	STATE_SAINT_PETER_PORT: 'Saint Peter Port',
	STATE_SAINT_PIERRE_DU_BOIS: 'Saint Pierre du Bois',
	STATE_SAINT_SAMPSON: 'Saint Sampson',
	STATE_SAINT_SAVIOUR: 'Saint Saviour',
	STATE_SARK: 'Sark',
	STATE_TORTEVAL: 'Torteval',
	STATE_VALE: 'Vale',
	STATE_BEYLA: 'Beyla',
	STATE_BOFFA: 'Boffa',
	STATE_BOKE: 'Boke',
	STATE_CONAKRY: 'Conakry',
	STATE_COYAH: 'Coyah',
	STATE_DABOLA: 'Dabola',
	STATE_DALABA: 'Dalaba',
	STATE_DINGUIRAYE: 'Dinguiraye',
	STATE_FARANAH: 'Faranah',
	STATE_FORECARIAH: 'Forecariah',
	STATE_FRIA: 'Fria',
	STATE_GAOUAL: 'Gaoual',
	STATE_GUECKEDOU: 'Gueckedou',
	STATE_KANKAN: 'Kankan',
	STATE_KEROUANE: 'Kerouane',
	STATE_KINDIA: 'Kindia',
	STATE_KISSIDOUGOU: 'Kissidougou',
	STATE_KOUBIA: 'Koubia',
	STATE_KOUNDARA: 'Koundara',
	STATE_KOUROUSSA: 'Kouroussa',
	STATE_LABE: 'Labe',
	STATE_LOLA: 'Lola',
	STATE_MACENTA: 'Macenta',
	STATE_MALI: 'Mali',
	STATE_MAMOU: 'Mamou',
	STATE_MANDIANA: 'Mandiana',
	STATE_NZEREKORE: 'Nzerekore',
	STATE_PITA: 'Pita',
	STATE_SIGUIRI: 'Siguiri',
	STATE_TELIMELE: 'Telimele',
	STATE_TOUGUE: 'Tougue',
	STATE_YOMOU: 'Yomou',
	STATE_BAFATA: 'Bafata',
	STATE_BISSAU: 'Bissau',
	STATE_BOLAMA: 'Bolama',
	STATE_CACHEU: 'Cacheu',
	STATE_GABU: 'Gabu',
	STATE_OIO: 'Oio',
	STATE_QUINARA: 'Quinara',
	STATE_TOMBALI: 'Tombali',
	STATE_BARIMA_WAINI: 'Barima-Waini',
	STATE_CUYUNI_MAZARUNI: 'Cuyuni-Mazaruni',
	STATE_DEMERARA_MAHAICA: 'Demerara-Mahaica',
	STATE_EAST_BERBICE_CORENTYNE: 'East Berbice-Corentyne',
	STATE_ESSEQUIBO_ISLANDS_WEST_DEMERAR: 'Essequebo-West Demerary',
	STATE_MAHAICA_BERBICE: 'Mahaica-Berbice',
	STATE_POMEROON_SUPENAAM: 'Pomeroon-Supenaam',
	STATE_POTARO_SIPARUNI: 'Potaro-Siparuni',
	STATE_UPPER_DEMERARA_BERBICE: 'Demerary-Berbice',
	STATE_UPPER_TAKUTU_UPPER_ESSEQUIBO: 'Upper Takutu-Upper Essequibo',
	STATE_ARTIBONITE: 'Artibonite',
	STATE_GRAND__ANSE: "Grand'Anse",
	STATE_NORD_EST: 'Nord-Est',
	STATE_NORD_OUEST: 'Nord-Ouest',
	STATE_SUD_EST: 'Sud-Est',
	STATE_HEARD_AND_MCDONALD_ISLANDS: 'Heard en McDonaldeilanden',
	STATE_ATLANTIDA: 'Atlantida',
	STATE_CHOLUTECA: 'Choluteca',
	STATE_COLON: 'Colón',
	STATE_COMAYAGUA: 'Comayagua',
	STATE_COPAN: 'Copan',
	STATE_CORTES: 'Cortes',
	STATE_DISTRITO_CENTRAL: 'Distrito Central',
	STATE_EL_PARAISO: 'El Paraiso',
	STATE_FRANCISCO_MORAZAN: 'Francisco Morazan',
	STATE_GRACIAS_A_DIOS: 'Gracias a Dios',
	STATE_INTIBUCA: 'Intibuca',
	STATE_ISLAS_DE_LA_BAHIA: 'Islas de la Bahia',
	STATE_LEMPIRA: 'Lempira',
	STATE_OCOTEPEQUE: 'Ocotepeque',
	STATE_OLANCHO: 'Olancho',
	STATE_SANTA_BARBARA: 'Santa Barbara',
	STATE_VALLE: 'Valle',
	STATE_YORO: 'Yoro',
	STATE_HONG_KONG: 'Hongkong',
	STATE_BACS_KISKUN: 'Bacs-Kiskun',
	STATE_BARANYA: 'Baranya',
	STATE_BEKES: 'Bekes',
	STATE_BORSOD_ABAUJ_ZEMPLEN: 'Borsod-Abauj-Zemplen',
	STATE_BUDAPEST: 'Boedapest',
	STATE_CSONGRAD: 'Csongrad',
	STATE_FEJER: 'Fejer',
	STATE_GYOR_MOSON_SOPRON: 'Gyor-Moson-Sopron',
	STATE_CORK: 'Cork',
	STATE_HAJDU_BIHAR: 'Hajdu-Bihar',
	STATE_HEVES: 'Heves',
	STATE_JASZ_NAGYKUN_SZOLNOK: 'Jasz-Nagykun-Szolnok',
	STATE_KOMAROM_ESZTERGOM: 'Komarom-Esztergom',
	STATE_NOGRAD: 'Nograd',
	STATE_PEST: 'Pest',
	STATE_SOMOGY: 'Somogy',
	STATE_SZABOLCS_SZATMAR_BEREG: 'Szabolcs-Szatmar-Bereg',
	STATE_TOLNA: 'Tolna',
	STATE_VAS: 'Vas',
	STATE_VESZPREM: 'Veszprem',
	STATE_ZALA: 'Zala',
	STATE_AUSTURLAND: 'Austurland',
	STATE_GULLBRINGUSYSLA: 'Gullbringusysla',
	STATE_HOFU_BORGARSVA_I: 'Hofu borgarsva i',
	STATE_NOR_URLAND_EYSTRA: 'Nor urland eystra',
	STATE_NOR_URLAND_VESTRA: 'Nor urland vestra',
	STATE_SU_URLAND: 'Su urland',
	STATE_SU_URNES: 'Su urnes',
	STATE_VESTFIR_IR: 'Vestfir ir',
	STATE_VESTURLAND: 'Vesturland',
	STATE_ACEH: 'Aceh',
	STATE_BALI: 'Bali',
	STATE_BANGKA_BELITUNG: 'Bangka-Belitung',
	STATE_BANTEN: 'Banten',
	STATE_BENGKULU: 'Bengkulu',
	STATE_GANDARIA: 'Gandaria',
	STATE_GORONTALO: 'Gorontalo',
	STATE_JAKARTA: 'Jakarta',
	STATE_JAMBI: 'Jambi',
	STATE_JAWA_BARAT: 'West-Java',
	STATE_JAWA_TENGAH: 'Midden-Java',
	STATE_JAWA_TIMUR: 'Oost-Java',
	STATE_KALIMANTAN_BARAT: 'West-Kalimantan',
	STATE_KALIMANTAN_SELATAN: 'Zuid-Kalimantan',
	STATE_KALIMANTAN_TENGAH: 'Midden-Kalimantan',
	STATE_KALIMANTAN_TIMUR: 'Oost-Kalimantan',
	STATE_KENDAL: 'Kendal',
	STATE_LAMPUNG: 'Lampung',
	STATE_MALUKU: 'Molukken',
	STATE_MALUKU_UTARA: 'Noord-Molukken',
	STATE_NUSA_TENGGARA_BARAT: 'West-Nusa Tenggara',
	STATE_NUSA_TENGGARA_TIMUR: 'Oost-Nusa Tenggara',
	STATE_PAPUA: 'Papoea',
	STATE_RIAU: 'Riau',
	STATE_RIAU_KEPULAUAN: 'Riau Kepulauan',
	STATE_SOLO: 'Solo',
	STATE_SULAWESI_SELATAN: 'Zuid-Sulawesi',
	STATE_SULAWESI_TENGAH: 'Midden-Sulawesi',
	STATE_SULAWESI_TENGGARA: 'Zuidoost-Sulawesi',
	STATE_SULAWESI_UTARA: 'Noord-Sulawesi',
	STATE_SUMATERA_BARAT: 'West-Sumatra',
	STATE_SUMATERA_SELATAN: 'Zuid-Sumatra',
	STATE_SUMATERA_UTARA: 'Noord-Sumatra',
	STATE_YOGYAKARTA: 'Yogyakarta',
	STATE_ARDABIL: 'Ardabil',
	STATE_AZARBAYJAN_E_BAKHTARI: 'Azerbeidzjan-e Bakhtari',
	STATE_AZARBAYJAN_E_KHAVARI: 'Azerbeidzjan-e Khavari',
	STATE_BUSHEHR: 'Bushehr',
	STATE_CHAHAR_MAHAL_E_BAKHTIARI: 'Chahar Mahal-e Bakhtiari',
	STATE_ESFAHAN: 'Esfahan',
	STATE_FARS: 'Fars',
	STATE_GILAN: 'Gilan',
	STATE_GOLESTAN: 'Golestan',
	STATE_HAMADAN: 'Hamadan',
	STATE_HORMOZGAN: 'Hormozgan',
	STATE_ILAM: 'Ilam',
	STATE_KERMAN: 'Kerman',
	STATE_KERMANSHAH: 'Kermanshah',
	STATE_KHORASAN: 'Khorasan',
	STATE_KHUZESTAN: 'Khuzestan',
	STATE_KOHGILUYEH_E_BOYERAHMAD: 'Kohgiluyeh-e Boyerahmad',
	STATE_KORDESTAN: 'Koerdistan',
	STATE_LORESTAN: 'Lorestan',
	STATE_MARKAZI: 'Markazi',
	STATE_MAZANDARAN: 'Mazandaran',
	STATE_OSTAN_E_ESFAHAN: 'Ostan-e Esfahan',
	STATE_QAZVIN: 'Qazvin',
	STATE_QOM: 'Qom',
	STATE_SEMNAN: 'Semnan',
	STATE_SISTAN_E_BALUCHESTAN: 'Sistan-e Baluchestan',
	STATE_TEHRAN: 'Teheran',
	STATE_YAZD: 'Yazd',
	STATE_ZANJAN: 'Zanjan',
	STATE_BABIL: 'Babil',
	STATE_BAGHDAD: 'Bagdad',
	STATE_DAHUK: 'Dahuk',
	STATE_DHI_QAR: 'Dhi Qar',
	STATE_DIYALA: 'Diyala',
	STATE_ERBIL: 'Erbil',
	STATE_IRBIL: 'Irbil',
	STATE_KARBALA: 'Karbala',
	STATE_KURDISTAN: 'Koerdistan',
	STATE_MAYSAN: 'Maysan',
	STATE_NINAWA: 'Ninawa',
	STATE_SALAH_AD_DIN: 'Salah-ad-Din',
	STATE_WASIT: 'Wasit',
	STATE_AL_ANBAR: 'al-Anbar',
	STATE_AL_BASRAH: 'al-Basrah',
	STATE_AL_MUTHANNA: 'al-Muthanna',
	STATE_AL_QADISIYAH: 'al-Qadisiyah',
	STATE_AN_NAJAF: 'an-Najaf',
	STATE_AS_SULAYMANIYAH: 'as-Sulaymaniyah',
	STATE_AT_TA__MIM: "at-Ta'mim",
	STATE_ARMAGH: 'Armagh',
	STATE_CARLOW: 'Carlow',
	STATE_CAVAN: 'Cavan',
	STATE_CLARE: 'Clare',
	STATE_DONEGAL: 'Donegal',
	STATE_DUBLIN: 'Dublin',
	STATE_GALWAY: 'Galway',
	STATE_KERRY: 'Kerry',
	STATE_KILDARE: 'Kildare',
	STATE_KILKENNY: 'Kilkenny',
	STATE_LAOIS: 'Laois',
	STATE_LEINSTER: 'Leinster',
	STATE_LEITRIM: 'Leitrim',
	STATE_LIMERICK: 'Limerick',
	STATE_LOCH_GARMAN: 'Loch Garman',
	STATE_LONGFORD: 'Longford',
	STATE_LOUTH: 'Louth',
	STATE_MAYO: 'Mayo',
	STATE_MEATH: 'Meath',
	STATE_MONAGHAN: 'Monaghan',
	STATE_OFFALY: 'Offaly',
	STATE_ROSCOMMON: 'Roscommon',
	STATE_SLIGO: 'Sligo',
	STATE_TIPPERARY_NORTH_RIDING: 'Tipperary North Riding',
	STATE_TIPPERARY_SOUTH_RIDING: 'Tipperary South Riding',
	STATE_ULSTER: 'Ulster',
	STATE_WATERFORD: 'Waterford',
	STATE_WESTMEATH: 'Westmeath',
	STATE_WEXFORD: 'Wexford',
	STATE_WICKLOW: 'Wicklow',
	STATE_BEIT_HANANIA: 'Beit Hanania',
	STATE_BEN_GURION_AIRPORT: 'Ben Gurion Airport',
	STATE_BETHLEHEM: 'Bethlehem',
	STATE_CAESAREA: 'Caesarea',
	STATE_GAZA: 'Gaza',
	STATE_HADARON: 'Hadaron',
	STATE_HAIFA_DISTRICT: 'Haïfa-district',
	STATE_HAMERKAZ: 'Hamerkaz',
	STATE_HAZAFON: 'Hazafon',
	STATE_HEBRON: 'Hebron',
	STATE_JAFFA: 'Jaffa',
	STATE_JERUSALEM: 'Jeruzalem',
	STATE_KHEFA: 'Khefa',
	STATE_KIRYAT_YAM: 'Kiryat Yam',
	STATE_LOWER_GALILEE: 'Neder-Galilea',
	STATE_QALQILYA: 'Qalqilya',
	STATE_TALME_ELAZAR: 'Talme Elazar',
	STATE_TEL_AVIV: 'Tel Aviv',
	STATE_TSAFON: 'Tsafon',
	STATE_UMM_EL_FAHEM: 'Umm El Fahem',
	STATE_YERUSHALAYIM: 'Yerushalayim',
	STATE_ABRUZZI: 'Abruzzi',
	STATE_ABRUZZO: 'Abruzzo',
	STATE_AGRIGENTO: 'Agrigento',
	STATE_ALESSANDRIA: 'Alexandria',
	STATE_ANCONA: 'Ancona',
	STATE_AREZZO: 'Arezzo',
	STATE_ASCOLI_PICENO: 'Ascoli Piceno',
	STATE_ASTI: 'Asti',
	STATE_AVELLINO: 'Avellino',
	STATE_BARI: 'Bari',
	STATE_BASILICATA: 'Basilicata',
	STATE_BELLUNO: 'Belluno',
	STATE_BENEVENTO: 'Benevento',
	STATE_BERGAMO: 'Bergamo',
	STATE_BIELLA: 'Biella',
	STATE_BOLOGNA: 'Bologna',
	STATE_BOLZANO: 'Bolzano',
	STATE_BRESCIA: 'Brescia',
	STATE_BRINDISI: 'Brindisi',
	STATE_CALABRIA: 'Calabria',
	STATE_CAMPANIA: 'Campania',
	STATE_CARTOCETO: 'Cartoceto',
	STATE_CASERTA: 'Caserta',
	STATE_CATANIA: 'Catania',
	STATE_CHIETI: 'Chieti',
	STATE_COMO: 'Como',
	STATE_COSENZA: 'Cosenza',
	STATE_CREMONA: 'Cremona',
	STATE_CUNEO: 'Cuneo',
	STATE_EMILIA_ROMAGNA: 'Emilia-Romagna',
	STATE_FERRARA: 'Ferrara',
	STATE_FIRENZE: 'Firenze',
	STATE_FLORENCE: 'Florence',
	STATE_FORLI_CESENA: 'Forli-Cesena',
	STATE_FRIULI_VENEZIA_GIULIA: 'Friuli-Venezia Giulia',
	STATE_FROSINONE: 'Frosinone',
	STATE_GENOA: 'Genua',
	STATE_GORIZIA: 'Gorizia',
	STATE_L__AQUILA: "L'Aquila",
	STATE_LAZIO: 'Lazio',
	STATE_LECCE: 'Lecce',
	STATE_LECCO: 'Lecco',
	STATE_LECCO_PROVINCE: 'Lecco - Provincie',
	STATE_LIGURIA: 'Ligurië',
	STATE_LODI: 'Lodi',
	STATE_LOMBARDIA: 'Lombardije',
	STATE_LOMBARDY: 'Lombardije',
	STATE_MACERATA: 'Macerata',
	STATE_MANTOVA: 'Mantova',
	STATE_MARCHE: 'Marche',
	STATE_MESSINA: 'Messina',
	STATE_MILAN: 'Milaan',
	STATE_MODENA: 'Modena',
	STATE_MOLISE: 'Molise',
	STATE_MOLTENO: 'Molteno',
	STATE_MONTENEGRO: 'Montenegro',
	STATE_MONZA_AND_BRIANZA: 'Monza en Brianza',
	STATE_NAPLES: 'Napels',
	STATE_NOVARA: 'Novara',
	STATE_PADOVA: 'Padova',
	STATE_PARMA: 'Parma',
	STATE_PAVIA: 'Pavia',
	STATE_RUMPHI: 'Rumphi',
	STATE_PERUGIA: 'Perugia',
	STATE_PESARO_URBINO: 'Pesaro-Urbino',
	STATE_PIACENZA: 'Piacenza',
	STATE_PIEDMONT: 'Piedmont',
	STATE_PIEMONTE: 'Piemonte',
	STATE_PISA: 'Pisa',
	STATE_PORDENONE: 'Pordenone',
	STATE_POTENZA: 'Potenza',
	STATE_PUGLIA: 'Puglia',
	STATE_REGGIO_EMILIA: 'Reggio Emilia',
	STATE_RIMINI: 'Rimini',
	STATE_ROMA: 'Rome',
	STATE_SALERNO: 'Salerno',
	STATE_SARDEGNA: 'Sardegna',
	STATE_SASSARI: 'Sassari',
	STATE_SAVONA: 'Savona',
	STATE_SICILIA: 'Sicilië',
	STATE_SIENA: 'Siena',
	STATE_SONDRIO: 'Sondrio',
	STATE_SOUTH_TYROL: 'Zuid-Tirol',
	STATE_TARANTO: 'Taranto',
	STATE_TERAMO: 'Teramo',
	STATE_TORINO: 'Torino',
	STATE_TOSCANA: 'Toscana',
	STATE_TRAPANI: 'Trapani',
	STATE_TRENTINO_ALTO_ADIGE: 'Trentino-Alto Adige',
	STATE_TRENTO: 'Trento',
	STATE_TREVISO: 'Treviso',
	STATE_UDINE: 'Udine',
	STATE_UMBRIA: 'Umbria',
	STATE_VALLE_D__AOSTA: "Valle d'Aosta",
	STATE_VARESE: 'Varese',
	STATE_VENETO: 'Veneto',
	STATE_VENEZIA: 'Venetië',
	STATE_VERBANO_CUSIO_OSSOLA: 'Verbano-Cusio-Ossola',
	STATE_VERCELLI: 'Vercelli',
	STATE_VERONA: 'Verona',
	STATE_VICENZA: 'Vicenza',
	STATE_VITERBO: 'Viterbo',
	STATE_BUXORO_VILOYATI: 'Buxoro Viloyati',
	STATE_CLARENDON: 'Clarendon',
	STATE_HANOVER: 'Hanover',
	STATE_KINGSTON: 'Kingston',
	STATE_MANCHESTER: 'Manchester',
	STATE_PORTLAND: 'Portland',
	STATE_SAINT_ANDREWS: 'Saint Andrews',
	STATE_SAINT_ANN: 'Saint Ann',
	STATE_SAINT_CATHERINE: 'Saint Catherine',
	STATE_SAINT_ELIZABETH: 'Saint Elizabeth',
	STATE_TRELAWNEY: 'Trelawney',
	STATE_WESTMORELAND: 'Westmoreland',
	STATE_AICHI: 'Aichi',
	STATE_AKITA: 'Akita',
	STATE_AOMORI: 'Aomori',
	STATE_CHIBA: 'Chiba',
	STATE_EHIME: 'Ehime',
	STATE_FUKUI: 'Fukui',
	STATE_FUKUOKA: 'Fukuoka',
	STATE_FUKUSHIMA: 'Fukushima',
	STATE_GIFU: 'Gifu',
	STATE_GUMMA: 'Gumma',
	STATE_HIROSHIMA: 'Hiroshima',
	STATE_HOKKAIDO: 'Hokkaido',
	STATE_HYOGO: 'Hyogo',
	STATE_IBARAKI: 'Ibaraki',
	STATE_ISHIKAWA: 'Ishikawa',
	STATE_IWATE: 'Iwate',
	STATE_KAGAWA: 'Kagawa',
	STATE_KAGOSHIMA: 'Kagoshima',
	STATE_KANAGAWA: 'Kanagawa',
	STATE_KANTO: 'Kanto',
	STATE_KOCHI: 'Kochi',
	STATE_KUMAMOTO: 'Kumamoto',
	STATE_KYOTO: 'Kyoto',
	STATE_MIE: 'Mie',
	STATE_MIYAGI: 'Miyagi',
	STATE_MIYAZAKI: 'Miyazaki',
	STATE_NAGANO: 'Nagano',
	STATE_NAGASAKI: 'Nagasaki',
	STATE_NARA: 'Nara',
	STATE_NIIGATA: 'Niigata',
	STATE_OITA: 'Oita',
	STATE_OKAYAMA: 'Okayama',
	STATE_OKINAWA: 'Okinawa',
	STATE_OSAKA: 'Osaka',
	STATE_SAGA: 'Saga',
	STATE_SAITAMA: 'Saitama',
	STATE_SHIGA: 'Shiga',
	STATE_SHIMANE: 'Shimane',
	STATE_SHIZUOKA: 'Shizuoka',
	STATE_TOCHIGI: 'Tochigi',
	STATE_TOKUSHIMA: 'Tokushima',
	STATE_TOKYO: 'Tokio',
	STATE_TOTTORI: 'Tottori',
	STATE_TOYAMA: 'Toyama',
	STATE_WAKAYAMA: 'Wakayama',
	STATE_YAMAGATA: 'Yamagata',
	STATE_YAMAGUCHI: 'Yamaguchi',
	STATE_YAMANASHI: 'Yamanashi',
	STATE_GROUVILLE: 'Grouville',
	STATE_SAINT_BRELADE: 'Saint Brelade',
	STATE_SAINT_CLEMENT: 'Saint Clement',
	STATE_SAINT_HELIER: 'Saint Helier',
	STATE_SAINT_LAWRENCE: 'Saint Lawrence',
	STATE_SALIMA: 'Salima',
	STATE_TRINITY: 'Trinity',
	STATE_AJLUN: 'Ajlun',
	STATE_AMMAN: 'Amman',
	STATE_IRBID: 'Irbid',
	STATE_JARASH: 'Jarash',
	STATE_MA__AN: "Ma'an",
	STATE_MADABA: 'Madaba',
	STATE_AL___AQABAH: "al-'Aqabah",
	STATE_AL_BALQA: 'al-Balqa',
	STATE_AL_KARAK: 'al-Karak',
	STATE_AL_MAFRAQ: 'al-Mafraq',
	STATE_AT_TAFILAH: 'at-Tafilah',
	STATE_AZ_ZARQA: 'az-Zarqa',
	STATE_AKMECET: 'Akmecet',
	STATE_AKMOLA: 'Akmola',
	STATE_AKTOBE: 'Aktobe',
	STATE_ALMATI: 'Almati',
	STATE_ATIRAU: 'Atirau',
	STATE_BATIS_KAZAKSTAN: 'Batis Kazakstan',
	STATE_BURLINSKY_REGION: 'Burlinsky-regio',
	STATE_KARAGANDI: 'Karagandi',
	STATE_KOSTANAY: 'Kostanay',
	STATE_MANKISTAU: 'Mankistau',
	STATE_ONTUSTIK_KAZAKSTAN: 'Ontustik Kazachstan',
	STATE_PAVLODAR: 'Pavlodar',
	STATE_SIGIS_KAZAKSTAN: 'Sigis Kazachstan',
	STATE_SOLTUSTIK_KAZAKSTAN: 'Soltustik Kazachstan',
	STATE_TARAZ: 'Taraz',
	STATE_COAST: 'Kust',
	STATE_NAIROBI: 'Nairobi',
	STATE_NORTH_EASTERN: 'Noordoost',
	STATE_NYANZA: 'Nyanza',
	STATE_RIFT_VALLEY: 'Rift Valley',
	STATE_ABAIANG: 'Abaiang',
	STATE_ABEMANA: 'Abemana',
	STATE_ARANUKA: 'Aranuka',
	STATE_ARORAE: 'Arorae',
	STATE_BANABA: 'Banaba',
	STATE_BERU: 'Beru',
	STATE_BUTARITARI: 'Butaritari',
	STATE_KIRITIMATI: 'Kiritimati',
	STATE_KURIA: 'Kuria',
	STATE_MAIANA: 'Maiana',
	STATE_MAKIN: 'Makin',
	STATE_MARAKEI: 'Marakei',
	STATE_NIKUNAU: 'Nikunau',
	STATE_NONOUTI: 'Nonouti',
	STATE_ONOTOA: 'Onotoa',
	STATE_PHOENIX_ISLANDS: 'Phoenixeilanden',
	STATE_TABITEUEA_NORTH: 'Tabiteuea-Noord',
	STATE_TABITEUEA_SOUTH: 'Tabiteuea-Zuid',
	STATE_TABUAERAN: 'Tabuaeran',
	STATE_TAMANA: 'Tamana',
	STATE_TARAWA_NORTH: 'Tarawa-Noord',
	STATE_TARAWA_SOUTH: 'Tarawa-Zuid',
	STATE_TERAINA: 'Teraina',
	STATE_CHAGANGDO: 'Chagangdo',
	STATE_HAMGYEONGBUKTO: 'Hamgyeongbukto',
	STATE_HAMGYEONGNAMDO: 'Hamgyeongnamdo',
	STATE_HWANGHAEBUKTO: 'Hwanghaebukto',
	STATE_HWANGHAENAMDO: 'Hwanghaenamdo',
	STATE_KAESEONG: 'Kaeseong',
	STATE_KANGWEON: 'Kangweon',
	STATE_NAMPO: 'Nampo',
	STATE_PYEONGANBUKTO: 'Pyeonganbukto',
	STATE_PYEONGANNAMDO: 'Pyeongannamdo',
	STATE_PYEONGYANG: 'Pyeongyang',
	STATE_YANGGANG: 'Yanggang',
	STATE_BUSAN: 'Busan',
	STATE_CHEJU: 'Cheju',
	STATE_CHOLLABUK: 'Chollabuk',
	STATE_CHOLLANAM: 'Chollanam',
	STATE_CHUNGBUK: 'Chungbuk',
	STATE_CHUNGCHEONGBUK: 'Chungcheongbuk',
	STATE_CHUNGCHEONGNAM: 'Chungcheongnam',
	STATE_CHUNGNAM: 'Chungnam',
	STATE_DAEGU: 'Daegu',
	STATE_GANGWON_DO: 'Gangwon-do',
	STATE_GOYANG_SI: 'Goyang-si',
	STATE_GYEONGGI_DO: 'Gyeonggi-do',
	STATE_GYEONGSANG: 'Gyeongsang',
	STATE_GYEONGSANGNAM_DO: 'Gyeongsangnam-do',
	STATE_INCHEON: 'Incheon',
	STATE_JEJU_SI: 'Jeju-Si',
	STATE_JEONBUK: 'Jeonbuk',
	STATE_KWANGJU: 'Kwangju',
	STATE_KYEONGGI: 'Kyeonggi',
	STATE_KYEONGSANGBUK: 'Kyeongsangbuk',
	STATE_KYEONGSANGNAM: 'Kyeongsangnam',
	STATE_KYONGGI_DO: 'Kyonggi-do',
	STATE_KYUNGBUK_DO: 'Kyungbuk-Do',
	STATE_KYUNGGI_DO: 'Kyunggi-Do',
	STATE_PUSAN: 'Pusan',
	STATE_SEOUL: 'Seoul',
	STATE_SUDOGWON: 'Sudogwon',
	STATE_TAEGU: 'Taegu',
	STATE_TAEJEON: 'Taejeon',
	STATE_TAEJON_GWANGYOKSI: 'Taejon-gwangyoksi',
	STATE_ULSAN: 'Ulsan',
	STATE_WONJU: 'Wonju',
	STATE_GWANGYOKSI: 'gwangyoksi',
	STATE_AL_ASIMAH: 'Al Asimah',
	STATE_HAWALLI: 'Hawalli',
	STATE_MISHREF: 'Mishref',
	STATE_QADESIYA: 'Qadesiya',
	STATE_SAFAT: 'Safat',
	STATE_SALMIYA: 'Salmiya',
	STATE_AL_AHMADI: 'al-Ahmadi',
	STATE_AL_FARWANIYAH: 'al-Farwaniyah',
	STATE_AL_JAHRA: 'al-Jahra',
	STATE_AL_KUWAYT: 'al-Kuwayt',
	STATE_BATKEN: 'Batken',
	STATE_BISHKEK: 'Bishkek',
	STATE_CHUI: 'Chui',
	STATE_ISSYK_KUL: 'Issyk-Kul',
	STATE_JALAL_ABAD: 'Jalal-Abad',
	STATE_NARYN: 'Naryn',
	STATE_OSH: 'Osh',
	STATE_TALAS: 'Talas',
	STATE_ATTOPU: 'Attopu',
	STATE_BOKEO: 'Bokeo',
	STATE_BOLIKHAMSAY: 'Bolikhamsay',
	STATE_CHAMPASAK: 'Champasak',
	STATE_HOUAPHANH: 'Houaphanh',
	STATE_KHAMMOUANE: 'Khammouane',
	STATE_LUANG_NAM_THA: 'Luang Nam Tha',
	STATE_LUANG_PRABANG: 'Luang Prabang',
	STATE_OUDOMXAY: 'Oudomxay',
	STATE_PHONGSALY: 'Phongsaly',
	STATE_SARAVAN: 'Saravan',
	STATE_SAVANNAKHET: 'Savannakhet',
	STATE_SEKONG: 'Sekong',
	STATE_VIANGCHAN_PREFECTURE: 'Viangchan Prefecture',
	STATE_VIANGCHAN_PROVINCE: 'Viangchan Provincie',
	STATE_XAIGNABURY: 'Xaignabury',
	STATE_XIANG_KHUANG: 'Xiang Khuang',
	STATE_AIZKRAUKLES: 'Aizkraukles',
	STATE_ALUKSNES: 'Aluksnes',
	STATE_BALVU: 'Balvu',
	STATE_BAUSKAS: 'Bauskas',
	STATE_CESU: 'Cesu',
	STATE_DAUGAVPILS: 'Daugavpils',
	STATE_DAUGAVPILS_CITY: 'Daugavpils-Stad',
	STATE_DOBELES: 'Dobeles',
	STATE_GULBENES: 'Gulbenes',
	STATE_JEKABSPILS: 'Jekabspils',
	STATE_JELGAVA: 'Jelgava',
	STATE_JELGAVAS: 'Jelgavas',
	STATE_JURMALA_CITY: 'Jurmala-Stad',
	STATE_KRASLAVAS: 'Kraslavas',
	STATE_KULDIGAS: 'Kuldigas',
	STATE_LIEPAJA: 'Liepaja',
	STATE_LIEPAJAS: 'Liepajas',
	STATE_LIMBAZHU: 'Limbazhu',
	STATE_LUDZAS: 'Ludzas',
	STATE_MADONAS: 'Madonas',
	STATE_OGRES: 'Ogres',
	STATE_PREILU: 'Preilu',
	STATE_REZEKNE: 'Rezekne',
	STATE_REZEKNES: 'Rezeknes',
	STATE_RIGA: 'Riga',
	STATE_RIGAS: 'Rigas',
	STATE_SALDUS: 'Saldus',
	STATE_TALSU: 'Talsu',
	STATE_TUKUMA: 'Tukuma',
	STATE_VALKAS: 'Valkas',
	STATE_VALMIERAS: 'Valmieras',
	STATE_VENTSPILS: 'Ventspils',
	STATE_VENTSPILS_CITY: 'Ventspils-Stad',
	STATE_BEIRUT: 'Beirut',
	STATE_JABAL_LUBNAN: 'Jabal Lubnan',
	STATE_MOHAFAZAT_LIBAN_NORD: 'Mohafazat Liban-Nord',
	STATE_MOHAFAZAT_MONT_LIBAN: 'Mohafazat Mont-Liban',
	STATE_SIDON: 'Sidon',
	STATE_AL_BIQA: 'al-Biqa',
	STATE_AL_JANUB: 'al-Janub',
	STATE_AN_NABATIYAH: 'an-Nabatiyah',
	STATE_ASH_SHAMAL: 'ash-Shamal',
	STATE_BEREA: 'Berea',
	STATE_BUTHA_BUTHE: 'Butha-Buthe',
	STATE_LERIBE: 'Leribe',
	STATE_MAFETENG: 'Mafeteng',
	STATE_MASERU: 'Maseru',
	STATE_MOHALE__S_HOEK: "Mohale's Hoek",
	STATE_MOKHOTLONG: 'Mokhotlong',
	STATE_QACHA__S_NEK: "Qacha's Nek",
	STATE_QUTHING: 'Quthing',
	STATE_THABA_TSEKA: 'Thaba-Tseka',
	STATE_BOMI: 'Bomi',
	STATE_BONG: 'Bong',
	STATE_GRAND_BASSA: 'Grand Bassa',
	STATE_GRAND_CAPE_MOUNT: 'Grand Cape Mount',
	STATE_GRAND_GEDEH: 'Grand Gedeh',
	STATE_LOFFA: 'Loffa',
	STATE_MARGIBI: 'Margibi',
	STATE_MARYLAND_AND_GRAND_KRU: 'Maryland en Grand Kru',
	STATE_MONTSERRADO: 'Montserrat',
	STATE_NIMBA: 'Nimba',
	STATE_RIVERCESS: 'Rivercess',
	STATE_SINOE: 'Sinoe',
	STATE_AJDABIYA: 'Ajdabiya',
	STATE_FEZZAN: 'Fezzan',
	STATE_BANGHAZI: 'Banghazi',
	STATE_DARNAH: 'Darnah',
	STATE_GHADAMIS: 'Ghadamis',
	STATE_GHARYAN: 'Gharyan',
	STATE_MISRATAH: 'Misratah',
	STATE_MURZUQ: 'Murzuq',
	STATE_SABHA: 'Sabha',
	STATE_SAWFAJJIN: 'Sawfajjin',
	STATE_SURT: 'Surt',
	STATE_TARABULUS: 'Tarabulus',
	STATE_TARHUNAH: 'Tarhunah',
	STATE_TRIPOLITANIA: 'Tripolitania',
	STATE_TUBRUQ: 'Tubruq',
	STATE_YAFRAN: 'Yafran',
	STATE_ZLITAN: 'Zlitan',
	STATE_AL___AZIZIYAH: "al-'Aziziyah",
	STATE_AL_FATIH: 'al-Fatih',
	STATE_AL_JABAL_AL_AKHDAR: 'al-Jabal al Akhdar',
	STATE_AL_JUFRAH: 'al-Jufrah',
	STATE_AL_KHUMS: 'al-Khums',
	STATE_AL_KUFRAH: 'al-Kufrah',
	STATE_AN_NUQAT_AL_KHAMS: 'an-Nuqat al-Khams',
	STATE_ASH_SHATI: 'ash-Shati',
	STATE_AZ_ZAWIYAH: 'az-Zawiyah',
	STATE_BALZERS: 'Balzers',
	STATE_ESCHEN: 'Eschen',
	STATE_GAMPRIN: 'Gamprin',
	STATE_MAUREN: 'Mauren',
	STATE_PLANKEN: 'Planken',
	STATE_RUGGELL: 'Ruggell',
	STATE_SCHAAN: 'Schaan',
	STATE_SCHELLENBERG: 'Schellenberg',
	STATE_TRIESEN: 'Triesen',
	STATE_TRIESENBERG: 'Triesenberg',
	STATE_VADUZ: 'Vaduz',
	STATE_ALYTAUS: 'Alytaus',
	STATE_ANYKSCIAI: 'Anyksciai',
	STATE_KAUNO: 'Kauno',
	STATE_KLAIPEDOS: 'Klaipedos',
	STATE_MARIJAMPOLES: 'Marijampoles',
	STATE_PANEVEZHIO: 'Panevezhio',
	STATE_PANEVEZYS: 'Panevezys',
	STATE_SHIAULIU: 'Shiauliu',
	STATE_TAURAGES: 'Taurages',
	STATE_TELSHIU: 'Telshiu',
	STATE_TELSIAI: 'Telsiai',
	STATE_UTENOS: 'Utenos',
	STATE_VILNIAUS: 'Vilniaus',
	STATE_CAPELLEN: 'Capellen',
	STATE_CLERVAUX: 'Clervaux',
	STATE_DIEKIRCH: 'Diekirch',
	STATE_ECHTERNACH: 'Echternach',
	STATE_ESCH_SUR_ALZETTE: 'Esch-sur-Alzette',
	STATE_GREVENMACHER: 'Grevenmacher',
	STATE_MERSCH: 'Mersch',
	STATE_REDANGE: 'Redange',
	STATE_REMICH: 'Remich',
	STATE_VIANDEN: 'Vianden',
	STATE_WILTZ: 'Wiltz',
	STATE_MACAU: 'Macau',
	STATE_BEROVO: 'Berovo',
	STATE_BITOLA: 'Bitola',
	STATE_BROD: 'Brod',
	STATE_DEBAR: 'Debar',
	STATE_DELCHEVO: 'Delchevo',
	STATE_DEMIR_HISAR: 'Demir Hisar',
	STATE_GEVGELIJA: 'Gevgelija',
	STATE_GOSTIVAR: 'Gostivar',
	STATE_KAVADARCI: 'Kavadarci',
	STATE_KICHEVO: 'Kichevo',
	STATE_KOCHANI: 'Kochani',
	STATE_KRATOVO: 'Kratovo',
	STATE_KRIVA_PALANKA: 'Kriva Palanka',
	STATE_KRUSHEVO: 'Krushevo',
	STATE_KUMANOVO: 'Kumanovo',
	STATE_NEGOTINO: 'Negotino',
	STATE_OHRID: 'Ohrid',
	STATE_PRILEP: 'Prilep',
	STATE_PROBISHTIP: 'Probishtip',
	STATE_RADOVISH: 'Radovish',
	STATE_RESEN: 'Resen',
	STATE_SHTIP: 'Shtip',
	STATE_SKOPJE: 'Skopje',
	STATE_STRUGA: 'Struga',
	STATE_STRUMICA: 'Strumica',
	STATE_SVETI_NIKOLE: 'Sveti Nikole',
	STATE_TETOVO: 'Tetovo',
	STATE_VALANDOVO: 'Valandovo',
	STATE_VELES: 'Veles',
	STATE_VINICA: 'Vinica',
	STATE_ANTANANARIVO: 'Antananarivo',
	STATE_ANTSIRANANA: 'Antsiranana',
	STATE_FIANARANTSOA: 'Fianarantsoa',
	STATE_MAHAJANGA: 'Mahajanga',
	STATE_TOAMASINA: 'Toamasina',
	STATE_TOLIARY: 'Toliary',
	STATE_BALAKA: 'Balaka',
	STATE_BLANTYRE_CITY: 'Blantyre-Stad',
	STATE_CHIKWAWA: 'Chikwawa',
	STATE_CHIRADZULU: 'Chiradzulu',
	STATE_CHITIPA: 'Chitipa',
	STATE_DEDZA: 'Dedza',
	STATE_DOWA: 'Dowa',
	STATE_KARONGA: 'Karonga',
	STATE_KASUNGU: 'Kasungu',
	STATE_LILONGWE_CITY: 'Lilongwe-Stad',
	STATE_MACHINGA: 'Machinga',
	STATE_MANGOCHI: 'Mangochi',
	STATE_MCHINJI: 'Mchinji',
	STATE_MULANJE: 'Mulanje',
	STATE_MWANZA: 'Mwanza',
	STATE_MZIMBA: 'Mzimba',
	STATE_MZUZU_CITY: 'Mzuzu-Stad',
	STATE_NKHATA_BAY: 'Nkhata Bay',
	STATE_NKHOTAKOTA: 'Nkhotakota',
	STATE_NSANJE: 'Nsanje',
	STATE_NTCHEU: 'Ntcheu',
	STATE_NTCHISI: 'Ntchisi',
	STATE_PHALOMBE: 'Phalombe',
	STATE_THYOLO: 'Thyolo',
	STATE_ZOMBA_MUNICIPALITY: 'Zomba-Gemeente',
	STATE_JOHOR: 'Johor',
	STATE_KEDAH: 'Kedah',
	STATE_KELANTAN: 'Kelantan',
	STATE_KUALA_LUMPUR: 'Kuala Lumpur',
	STATE_LABUAN: 'Labuan',
	STATE_MELAKA: 'Melaka',
	STATE_NEGERI_JOHOR: 'Negeri Johor',
	STATE_NEGERI_SEMBILAN: 'Negeri Sembilan',
	STATE_PAHANG: 'Pahang',
	STATE_PENANG: 'Penang',
	STATE_PERAK: 'Perak',
	STATE_PERLIS: 'Perlis',
	STATE_PULAU_PINANG: 'Pulau Pinang',
	STATE_SABAH: 'Sabah',
	STATE_SARAWAK: 'Sarawak',
	STATE_SELANGOR: 'Selangor',
	STATE_SEMBILAN: 'Sembilan',
	STATE_TERENGGANU: 'Terengganu',
	STATE_ALIF_ALIF: 'Alif Alif',
	STATE_ALIF_DHAAL: 'Alif Dhaal',
	STATE_BAA: 'Baa',
	STATE_DHAAL: 'Dhaal',
	STATE_FAAF: 'Faaf',
	STATE_GAAF_ALIF: 'Gaaf Alif',
	STATE_GAAF_DHAAL: 'Gaaf Dhaal',
	STATE_GHAVIYANI: 'Ghaviyani',
	STATE_HAA_ALIF: 'Haa Alif',
	STATE_HAA_DHAAL: 'Haa Dhaal',
	STATE_KAAF: 'Kaaf',
	STATE_LAAM: 'Laam',
	STATE_LHAVIYANI: 'Lhaviyani',
	STATE_MALE: 'Male',
	STATE_MIIM: 'Miim',
	STATE_NUUN: 'Nuun',
	STATE_RAA: 'Raa',
	STATE_SHAVIYANI: 'Shaviyani',
	STATE_SIIN: 'Siin',
	STATE_THAA: 'Thaa',
	STATE_VAAV: 'Vaav',
	STATE_BAMAKO: 'Bamako',
	STATE_GAO: 'Gao',
	STATE_KAYES: 'Kayes',
	STATE_KIDAL: 'Kidal',
	STATE_KOULIKORO: 'Koulikoro',
	STATE_MOPTI: 'Mopti',
	STATE_SEGOU: 'Segou',
	STATE_SIKASSO: 'Sikasso',
	STATE_TOMBOUCTOU: 'Tombouctou',
	STATE_GOZO_AND_COMINO: 'Gozo en Comino',
	STATE_INNER_HARBOUR: 'Binnenhaven',
	STATE_OUTER_HARBOUR: 'Buitenhaven',
	STATE_SOUTH_EASTERN: 'Zuidoost',
	STATE_CASTLETOWN: 'Castletown',
	STATE_DOUGLAS: 'Douglas',
	STATE_LAXEY: 'Laxey',
	STATE_ONCHAN: 'Onchan',
	STATE_PEEL: 'Peel',
	STATE_PORT_ERIN: 'Port Erin',
	STATE_PORT_SAINT_MARY: 'Port Saint Mary',
	STATE_RAMSEY: 'Ramsey',
	STATE_AILINLAPLAP: 'Ailinlaplap',
	STATE_AILUK: 'Ailuk',
	STATE_ARNO: 'Arno',
	STATE_AUR: 'Aur',
	STATE_BIKINI: 'Bikini',
	STATE_EBON: 'Ebon',
	STATE_ENEWETAK: 'Enewetak',
	STATE_JABAT: 'Jabat',
	STATE_JALUIT: 'Jaluit',
	STATE_KILI: 'Kili',
	STATE_KWAJALEIN: 'Kwajalein',
	STATE_LAE: 'Lae',
	STATE_LIB: 'Lib',
	STATE_LIKIEP: 'Likiep',
	STATE_MAJURO: 'Majuro',
	STATE_MALOELAP: 'Maloelap',
	STATE_MEJIT: 'Mejit',
	STATE_MILI: 'Mili',
	STATE_NAMORIK: 'Namorik',
	STATE_NAMU: 'Namu',
	STATE_RONGELAP: 'Rongelap',
	STATE_UJAE: 'Ujae',
	STATE_UTRIK: 'Utrik',
	STATE_WOTHO: 'Wotho',
	STATE_WOTJE: 'Wotje',
	STATE_FORT_DE_FRANCE: 'Fort-de-France',
	STATE_LA_TRINITE: 'La Trinite',
	STATE_LE_MARIN: 'Le Marin',
	STATE_SAINT_PIERRE: 'Saint-Pierre',
	STATE_ASSABA: 'Assaba',
	STATE_BRAKNA: 'Brakna',
	STATE_DHAKHLAT_NAWADIBU: 'Dhakhlat Nawadibu',
	STATE_HUDH_AL_GHARBI: 'Hudh-al-Gharbi',
	STATE_HUDH_ASH_SHARQI: 'Hudh-ash-Sharqi',
	STATE_INSHIRI: 'Inshiri',
	STATE_NAWAKSHUT: 'Nawakshut',
	STATE_QIDIMAGHA: 'Qidimagha',
	STATE_QURQUL: 'Qurqul',
	STATE_TAQANT: 'Taqant',
	STATE_TIRIS_ZAMMUR: 'Tiris Zammur',
	STATE_TRARZA: 'Trarza',
	STATE_BLACK_RIVER: 'Black River',
	STATE_EAU_COULEE: 'Eau Coulee',
	STATE_FLACQ: 'Flacq',
	STATE_FLOREAL: 'Floreal',
	STATE_GRAND_PORT: 'Grand Port',
	STATE_MOKA: 'Moka',
	STATE_PAMPLEMPOUSSES: 'Pamplempousses',
	STATE_PLAINES_WILHELM: 'Plaines Wilhelm',
	STATE_PORT_LOUIS: 'Port Louis',
	STATE_RIVIERE_DU_REMPART: 'Riviere du Rempart',
	STATE_RODRIGUES: 'Rodrigues',
	STATE_ROSE_HILL: 'Rose Hill',
	STATE_SAVANNE: 'Savanne',
	STATE_MAYOTTE: 'Mayotte',
	STATE_PAMANZI: 'Pamanzi',
	STATE_AGUASCALIENTES: 'Aguascalientes',
	STATE_BAJA_CALIFORNIA: 'Baja California',
	STATE_BAJA_CALIFORNIA_SUR: 'Baja California Sur',
	STATE_CAMPECHE: 'Campeche',
	STATE_CHIAPAS: 'Chiapas',
	STATE_CHIHUAHUA: 'Chihuahua',
	STATE_COAHUILA: 'Coahuila',
	STATE_COLIMA: 'Colima',
	STATE_DURANGO: 'Durango',
	STATE_ESTADO_DE_MEXICO: 'Estado de Mexico',
	STATE_GUANAJUATO: 'Guanajuato',
	STATE_GUERRERO: 'Guerrero',
	STATE_HIDALGO: 'Hidalgo',
	STATE_JALISCO: 'Jalisco',
	STATE_MEXICO: 'Mexico',
	STATE_MICHOACAN: 'Michoacan',
	STATE_MORELOS: 'Morelos',
	STATE_NAYARIT: 'Nayarit',
	STATE_NUEVO_LEON: 'Nuevo Leon',
	STATE_OAXACA: 'Oaxaca',
	STATE_PUEBLA: 'Puebla',
	STATE_QUERETARO: 'Queretaro',
	STATE_QUINTANA_ROO: 'Quintana Roo',
	STATE_SAN_LUIS_POTOSI: 'San Luis Potosi',
	STATE_SINALOA: 'Sinaloa',
	STATE_SONORA: 'Sonora',
	STATE_TABASCO: 'Tabasco',
	STATE_TAMAULIPAS: 'Tamaulipas',
	STATE_TLAXCALA: 'Tlaxcala',
	STATE_VERACRUZ: 'Veracruz',
	STATE_YUCATAN: 'Yucatan',
	STATE_ZACATECAS: 'Zacatecas',
	STATE_CHUUK: 'Chuuk',
	STATE_KUSAIE: 'Kusaie',
	STATE_POHNPEI: 'Pohnpei',
	STATE_YAP: 'Yap',
	STATE_BALTI: 'Balti',
	STATE_CAHUL: 'Cahul',
	STATE_CHISINAU: 'Chisinau',
	STATE_CHISINAU_ORAS: 'Chisinau Oras',
	STATE_EDINET: 'Edinet',
	STATE_GAGAUZIA: 'Gagauzia',
	STATE_LAPUSNA: 'Lapusna',
	STATE_ORHEI: 'Orhei',
	STATE_SOROCA: 'Soroca',
	STATE_TARACLIA: 'Taraclia',
	STATE_TIGHINA: 'Tighina',
	STATE_TRANSNISTRIA: 'Transnistria',
	STATE_UNGHENI: 'Ungheni',
	STATE_FONTVIEILLE: 'Fontvieille',
	STATE_LA_CONDAMINE: 'La Condamine',
	STATE_MONACO_VILLE: 'Monaco-Ville',
	STATE_MONTE_CARLO: 'Monte Carlo',
	STATE_ARHANGAJ: 'Arhangaj',
	STATE_BAJAN_OLGIJ: 'Bajan-Olgij',
	STATE_BAJANHONGOR: 'Bajanhongor',
	STATE_BULGAN: 'Bulgan',
	STATE_DARHAN_UUL: 'Darhan-Uul',
	STATE_DORNOD: 'Dornod',
	STATE_DORNOGOVI: 'Dornogovi',
	STATE_DUNDGOVI: 'Dundgovi',
	STATE_GOVI_ALTAJ: 'Govi-Altaj',
	STATE_GOVISUMBER: 'Govisumber',
	STATE_HENTIJ: 'Hentij',
	STATE_HOVD: 'Hovd',
	STATE_HOVSGOL: 'Hovsgol',
	STATE_OMNOGOVI: 'Omnogovi',
	STATE_ORHON: 'Orhon',
	STATE_OVORHANGAJ: 'Ovorhangaj',
	STATE_SELENGE: 'Selenge',
	STATE_SUHBAATAR: 'Suhbaatar',
	STATE_TOV: 'Tov',
	STATE_ULAANBAATAR: 'Ulaanbaatar',
	STATE_UVS: 'Uvs',
	STATE_ZAVHAN: 'Zavhan',
	STATE_MONTSERRAT: 'Montserrat',
	STATE_AGADIR: 'Agadir',
	STATE_CASABLANCA: 'Casablanca',
	STATE_CHAOUIA_OUARDIGHA: 'Chaouia-Ouardigha',
	STATE_DOUKKALA_ABDA: 'Doukkala-Abda',
	STATE_FES_BOULEMANE: 'Fes-Boulemane',
	STATE_GHARB_CHRARDA_BENI_HSSEN: 'Gharb-Chrarda-Beni Hssen',
	STATE_GUELMIM: 'Guelmim',
	STATE_KENITRA: 'Kenitra',
	STATE_MARRAKECH_TENSIFT_AL_HAOUZ: 'Marrakech-Tensift-Al Haouz',
	STATE_MEKNES_TAFILALET: 'Meknes-Tafilalet',
	STATE_ORIENTAL: 'Oriental',
	STATE_OUJDA: 'Oujda',
	STATE_PROVINCE_DE_TANGER: 'Province de Tanger',
	STATE_RABAT_SALE_ZAMMOUR_ZAER: 'Rabat-Sale-Zammour-Zaer',
	STATE_SALA_AL_JADIDA: 'Sala Al Jadida',
	STATE_SETTAT: 'Settat',
	STATE_SOUSS_MASSA_DRAA: 'Souss Massa-Draa',
	STATE_TADLA_AZILAL: 'Tadla-Azilal',
	STATE_TANGIER_TETOUAN: 'Tangier-Tetouan',
	STATE_TAZA_AL_HOCEIMA_TAOUNATE: 'Taza-Al Hoceima-Taounate',
	STATE_WILAYA_DE_CASABLANCA: 'Wilaya de Casablanca',
	STATE_WILAYA_DE_RABAT_SALE: 'Wilaya de Rabat-Sale',
	STATE_CABO_DELGADO: 'Cabo Delgado',
	STATE_INHAMBANE: 'Inhambane',
	STATE_MANICA: 'Manica',
	STATE_MAPUTO: 'Maputo',
	STATE_MAPUTO_PROVINCIA: 'Maputo Provincia',
	STATE_NAMPULA: 'Nampula',
	STATE_NIASSA: 'Niassa',
	STATE_SOFALA: 'Sofala',
	STATE_TETE: 'Tete',
	STATE_ZAMBEZIA: 'Zambezia',
	STATE_AYEYARWADY: 'Ayeyarwady',
	STATE_BAGO: 'Bago',
	STATE_CHIN: 'Chin',
	STATE_KACHIN: 'Kachin',
	STATE_KAYAH: 'Kayah',
	STATE_KAYIN: 'Kayin',
	STATE_MAGWAY: 'Magway',
	STATE_MANDALAY: 'Mandalay',
	STATE_MON: 'Mon',
	STATE_NAY_PYI_TAW: 'Nay Pyi Taw',
	STATE_RAKHINE: 'Rakhine',
	STATE_SAGAING: 'Sagaing',
	STATE_SHAN: 'Shan',
	STATE_TANINTHARYI: 'Tanintharyi',
	STATE_YANGON: 'Yangon',
	STATE_CAPRIVI: 'Caprivi',
	STATE_ERONGO: 'Erongo',
	STATE_HARDAP: 'Hardap',
	STATE_KARAS: 'Karas',
	STATE_KAVANGO: 'Kavango',
	STATE_KHOMAS: 'Khomas',
	STATE_KUNENE: 'Kunene',
	STATE_OHANGWENA: 'Ohangwena',
	STATE_OMAHEKE: 'Omaheke',
	STATE_OMUSATI: 'Omusati',
	STATE_OSHANA: 'Oshana',
	STATE_OSHIKOTO: 'Oshikoto',
	STATE_OTJOZONDJUPA: 'Otjozondjupa',
	STATE_YAREN: 'Yaren',
	STATE_BAGMATI: 'Bagmati',
	STATE_BHERI: 'Bheri',
	STATE_DHAWALAGIRI: 'Dhawalagiri',
	STATE_GANDAKI: 'Gandaki',
	STATE_JANAKPUR: 'Janakpur',
	STATE_KARNALI: 'Karnali',
	STATE_KOSHI: 'Koshi',
	STATE_LUMBINI: 'Lumbini',
	STATE_MAHAKALI: 'Mahakali',
	STATE_MECHI: 'Mechi',
	STATE_NARAYANI: 'Narayani',
	STATE_RAPTI: 'Rapti',
	STATE_SAGARMATHA: 'Sagarmatha',
	STATE_SETI: 'Seti',
	STATE_BONAIRE: 'Bonaire',
	STATE_CURACAO: 'Curaçao',
	STATE_SABA: 'Saba',
	STATE_SINT_EUSTATIUS: 'Sint Eustatius',
	STATE_SINT_MAARTEN: 'Sint Maarten',
	STATE_BENELUX: 'Benelux',
	STATE_DRENTHE: 'Drenthe',
	STATE_FLEVOLAND: 'Flevoland',
	STATE_FRIESLAND: 'Friesland',
	STATE_GELDERLAND: 'Gelderland',
	STATE_GRONINGEN: 'Groningen',
	STATE_NOORD_BRABANT: 'Noord-Brabant',
	STATE_OVERIJSSEL: 'Overijssel',
	STATE_SOUTH_HOLLAND: 'Zuid-Holland',
	STATE_UTRECHT: 'Utrecht',
	STATE_ZEELAND: 'Zeeland',
	STATE_ZUID_HOLLAND: 'Zuid-Holland',
	STATE_ILES: 'Iles',
	STATE_AREA_OUTSIDE_REGION: 'Area Outside Region',
	STATE_BAY_OF_PLENTY: 'Bay of Plenty',
	STATE_CANTERBURY: 'Canterbury',
	STATE_CHRISTCHURCH: 'Christchurch',
	STATE_GISBORNE: 'Gisborne',
	STATE_HAWKE__S_BAY: "Hawke's Bay",
	STATE_MANAWATU_WANGANUI: 'Manawatu-Wanganui',
	STATE_MARLBOROUGH: 'Marlborough',
	STATE_NELSON: 'Nelson',
	STATE_NORTHLAND: 'Northland',
	STATE_OTAGO: 'Otago',
	STATE_RODNEY: 'Rodney',
	STATE_SOUTHLAND: 'Southland',
	STATE_TARANAKI: 'Taranaki',
	STATE_TASMAN: 'Tasman',
	STATE_WAIKATO: 'Waikato',
	STATE_WELLINGTON: 'Wellington',
	STATE_WEST_COAST: 'West Coast',
	STATE_ATLANTICO_NORTE: 'Atlantico Norte',
	STATE_ATLANTICO_SUR: 'Atlantico Sur',
	STATE_BOACO: 'Boaco',
	STATE_CARAZO: 'Carazo',
	STATE_CHINANDEGA: 'Chinandega',
	STATE_CHONTALES: 'Chontales',
	STATE_ESTELI: 'Esteli',
	STATE_GRANADA: 'Granada',
	STATE_JINOTEGA: 'Jinotega',
	STATE_LEON: 'Leon',
	STATE_MADRIZ: 'Madriz',
	STATE_MANAGUA: 'Managua',
	STATE_MASAYA: 'Masaya',
	STATE_MATAGALPA: 'Matagalpa',
	STATE_NUEVA_SEGOVIA: 'Nueva Segovia',
	STATE_RIO_SAN_JUAN: 'Rio San Juan',
	STATE_RIVAS: 'Rivas',
	STATE_AGADEZ: 'Agadez',
	STATE_DIFFA: 'Diffa',
	STATE_DOSSO: 'Dosso',
	STATE_MARADI: 'Maradi',
	STATE_NIAMEY: 'Niamey',
	STATE_TAHOUA: 'Tahoua',
	STATE_TILLABERY: 'Tillabery',
	STATE_ZINDER: 'Zinder',
	STATE_ABIA: 'Abia',
	STATE_ABUJA_FEDERAL_CAPITAL_TERRITOR: 'Abuja Federal Capital Territor',
	STATE_ADAMAWA: 'Adamawa',
	STATE_AKWA_IBOM: 'Akwa Ibom',
	STATE_ANAMBRA: 'Anambra',
	STATE_BAUCHI: 'Bauchi',
	STATE_BAYELSA: 'Bayelsa',
	STATE_BENUE: 'Benue',
	STATE_BORNO: 'Borno',
	STATE_CROSS_RIVER: 'Cross River',
	STATE_DELTA: 'Delta',
	STATE_EBONYI: 'Ebonyi',
	STATE_EDO: 'Edo',
	STATE_EKITI: 'Ekiti',
	STATE_ENUGU: 'Enugu',
	STATE_GOMBE: 'Gombe',
	STATE_IMO: 'Imo',
	STATE_JIGAWA: 'Jigawa',
	STATE_KADUNA: 'Kaduna',
	STATE_KANO: 'Kano',
	STATE_KATSINA: 'Katsina',
	STATE_KEBBI: 'Kebbi',
	STATE_KOGI: 'Kogi',
	STATE_KWARA: 'Kwara',
	STATE_LAGOS: 'Lagos',
	STATE_NASSARAWA: 'Nassarawa',
	STATE_NIGER: 'Niger',
	STATE_OGUN: 'Ogun',
	STATE_ONDO: 'Ondo',
	STATE_OSUN: 'Osun',
	STATE_OYO: 'Oyo',
	STATE_RIVERS: 'Rivers',
	STATE_SOKOTO: 'Sokoto',
	STATE_TARABA: 'Taraba',
	STATE_YOBE: 'Yobe',
	STATE_ZAMFARA: 'Zamfara',
	STATE_NIUE: 'Niue',
	STATE_NORFOLK_ISLAND: 'Norfolk',
	STATE_NORTHERN_ISLANDS: 'Noordelijke eilanden',
	STATE_ROTA: 'Rota',
	STATE_SAIPAN: 'Saipan',
	STATE_TINIAN: 'Tinian',
	STATE_AKERSHUS: 'Akershus',
	STATE_AUST_AGDER: 'Aust Agder',
	STATE_BERGEN: 'Bergen',
	STATE_BUSKERUD: 'Buskerud',
	STATE_FINNMARK: 'Finnmark',
	STATE_HEDMARK: 'Hedmark',
	STATE_HORDALAND: 'Hordaland',
	STATE_MOERE_OG_ROMSDAL: 'Moere og Romsdal',
	STATE_NORD_TRONDELAG: 'Nord Trondelag',
	STATE_NORDLAND: 'Nordland',
	STATE_OESTFOLD: 'Oestfold',
	STATE_OPPLAND: 'Oppland',
	STATE_OSLO: 'Oslo',
	STATE_ROGALAND: 'Rogaland',
	STATE_SOER_TROENDELAG: 'Soer Troendelag',
	STATE_SOGN_OG_FJORDANE: 'Sogn og Fjordane',
	STATE_STAVERN: 'Stavern',
	STATE_SYKKYLVEN: 'Sykkylven',
	STATE_TELEMARK: 'Telemark',
	STATE_TROMS: 'Troms',
	STATE_VEST_AGDER: 'Vest Agder',
	STATE_VESTFOLD: 'Vestfold',
	'STATE_ÃƑÆ’Ã‚ËŒSTFOLD': 'ÃƒÆ’Ã‚Ëœstfold',
	STATE_AL_BURAIMI: 'Al Buraimi',
	STATE_DHUFAR: 'Dhufar',
	STATE_MASQAT: 'Masqat',
	STATE_MUSANDAM: 'Musandam',
	STATE_RUSAYL: 'Rusayl',
	STATE_WADI_KABIR: 'Wadi Kabir',
	STATE_AD_DAKHILIYAH: 'ad-Dakhiliyah',
	STATE_ADH_DHAHIRAH: 'adh-Dhahirah',
	STATE_AL_BATINAH: 'al-Batinah',
	STATE_BALUCHISTAN: 'Baluchistan',
	STATE_FEDERAL_CAPITAL_AREA: 'Federal Capital Area',
	STATE_FEDERALLY_ADMINISTERED_TRIBAL: 'Federaal beheerde Trible',
	STATE_NORTH_WEST_FRONTIER: 'Noordwestgrens',
	STATE_NORTHERN_AREAS: 'Noordelijke gebieden',
	STATE_SIND: 'Sind',
	STATE_AIMELIIK: 'Aimeliik',
	STATE_AIRAI: 'Airai',
	STATE_ANGAUR: 'Angaur',
	STATE_HATOBOHEI: 'Hatobohei',
	STATE_KAYANGEL: 'Kayangel',
	STATE_KOROR: 'Koror',
	STATE_MELEKEOK: 'Melekeok',
	STATE_NGARAARD: 'Ngaraard',
	STATE_NGARDMAU: 'Ngardmau',
	STATE_NGAREMLENGUI: 'Ngaremlengui',
	STATE_NGATPANG: 'Ngatpang',
	STATE_NGCHESAR: 'Ngchesar',
	STATE_NGERCHELONG: 'Ngerchelong',
	STATE_NGIWAL: 'Ngiwal',
	STATE_PELELIU: 'Peleliu',
	STATE_SONSOROL: 'Sonsorol',
	STATE_ARIHA: 'Ariha',
	STATE_BAYT_LAHM: 'Bayt Lahm',
	STATE_DAYR_AL_BALAH: 'Dayr-al-Balah',
	STATE_GHAZZAH: 'Ghazzah',
	STATE_GHAZZAH_ASH_SHAMALIYAH: 'Ghazzah ash-Shamaliyah',
	STATE_JANIN: 'Janin',
	STATE_KHAN_YUNIS: 'Khan Yunis',
	STATE_NABULUS: 'Nabulus',
	STATE_QALQILYAH: 'Qalqilyah',
	STATE_RAFAH: 'Rafah',
	STATE_RAM_ALLAH_WAL_BIRAH: 'Ram Allah wal-Birah',
	STATE_SALFIT: 'Salfit',
	STATE_TUBAS: 'Tubas',
	STATE_TULKARM: 'Tulkarm',
	STATE_AL_KHALIL: 'al-Khalil',
	STATE_AL_QUDS: 'al-Quds',
	STATE_BOCAS_DEL_TORO: 'Bocas del Toro',
	STATE_CHIRIQUI: 'Chiriqui',
	STATE_COCLE: 'Cocle',
	STATE_DARIEN: 'Darien',
	STATE_EMBERA: 'Embera',
	STATE_HERRERA: 'Herrera',
	STATE_KUNA_YALA: 'Kuna Yala',
	STATE_LOS_SANTOS: 'Los Santos',
	STATE_NGOBE_BUGLE: 'Ngobe Bugle',
	STATE_PANAMA: 'Panama',
	STATE_VERAGUAS: 'Veraguas',
	STATE_EAST_NEW_BRITAIN: 'Oost-Nieuw-Brittannië',
	STATE_EAST_SEPIK: 'Oost-Sepik',
	STATE_EASTERN_HIGHLANDS: 'Oostelijke Hooglanden',
	STATE_ENGA: 'Enga',
	STATE_FLY_RIVER: 'Fly River',
	STATE_GULF: 'Gulf',
	STATE_MADANG: 'Madang',
	STATE_MANUS: 'Manus',
	STATE_MILNE_BAY: 'Milne Bay',
	STATE_MOROBE: 'Morobe',
	STATE_NATIONAL_CAPITAL_DISTRICT: 'Nationaal Hoofdstedelijk District',
	STATE_NEW_IRELAND: 'Nieuw-Ierland',
	STATE_NORTH_SOLOMONS: 'Noordelijke Salomonseilanden',
	STATE_ORO: 'Oro',
	STATE_SANDAUN: 'Sandaun',
	STATE_SIMBU: 'Simbu',
	STATE_SOUTHERN_HIGHLANDS: 'Zuidelijke Hooglanden',
	STATE_WEST_NEW_BRITAIN: 'West-Nieuw-Brittannië',
	STATE_WESTERN_HIGHLANDS: 'Westelijke Hooglanden',
	STATE_ALTO_PARAGUAY: 'Alto Paraguay',
	STATE_ALTO_PARANA: 'Alto Parana',
	STATE_AMAMBAY: 'Amambay',
	STATE_ASUNCION: 'Asuncion',
	STATE_BOQUERON: 'Boqueron',
	STATE_CAAGUAZU: 'Caaguazu',
	STATE_CAAZAPA: 'Caazapa',
	STATE_CANENDIYU: 'Canendiyu',
	STATE_CONCEPCION: 'Concepcion',
	STATE_CORDILLERA: 'Cordillera',
	STATE_GUAIRA: 'Guaira',
	STATE_ITAPUA: 'Itapua',
	STATE_NEEMBUCU: 'Neembucu',
	STATE_PARAGUARI: 'Paraguari',
	STATE_PRESIDENTE_HAYES: 'Presidente Hayes',
	STATE_SAN_PEDRO: 'San Pedro',
	STATE_ANCASH: 'Ancash',
	STATE_APURIMAC: 'Apurimac',
	STATE_AREQUIPA: 'Arequipa',
	STATE_AYACUCHO: 'Ayacucho',
	STATE_CAJAMARCA: 'Cajamarca',
	STATE_CUSCO: 'Cusco',
	STATE_HUANCAVELICA: 'Huancavelica',
	STATE_HUANUCO: 'Huanuco',
	STATE_ICA: 'Ica',
	STATE_JUNIN: 'Junin',
	STATE_LAMBAYEQUE: 'Lambayeque',
	STATE_LIMA_Y_CALLAO: 'Lima y Callao',
	STATE_LORETO: 'Loreto',
	STATE_MADRE_DE_DIOS: 'Madre de Dios',
	STATE_MOQUEGUA: 'Moquegua',
	STATE_PASCO: 'Pasco',
	STATE_PIURA: 'Piura',
	STATE_PUNO: 'Puno',
	STATE_SAN_MARTIN: 'San Martin',
	STATE_TACNA: 'Tacna',
	STATE_TUMBES: 'Tumbes',
	STATE_UCAYALI: 'Ucayali',
	STATE_BATANGAS: 'Batangas',
	STATE_BICOL: 'Bicol',
	STATE_BULACAN: 'Bulacan',
	STATE_CAGAYAN: 'Cagayan',
	STATE_CARAGA: 'Caraga',
	STATE_CENTRAL_LUZON: 'Central Luzon',
	STATE_CENTRAL_MINDANAO: 'Central Mindanao',
	STATE_CENTRAL_VISAYAS: 'Central Visayas',
	STATE_DAVAO: 'Davao',
	STATE_EASTERN_VISAYAS: 'Eastern Visayas',
	STATE_GREATER_METROPOLITAN_AREA: 'Grootstedelijk gebied',
	STATE_ILOCOS: 'Ilocos',
	STATE_LAGUNA: 'Laguna',
	STATE_LUZON: 'Luzon',
	STATE_MACTAN: 'Mactan',
	STATE_METROPOLITAN_MANILA_AREA: 'Grootstedelijk Manilla',
	STATE_MUSLIM_MINDANAO: ' Moslim Mindanao',
	STATE_NORTHERN_MINDANAO: 'Noordelijk Mindanao',
	STATE_SOUTHERN_MINDANAO: 'Zuidelijk Mindanao',
	STATE_SOUTHERN_TAGALOG: 'Zuidelijk Tagalon',
	STATE_WESTERN_MINDANAO: 'Westelijk Mindanao',
	STATE_WESTERN_VISAYAS: 'Westelijke Visayas',
	STATE_PITCAIRN_ISLAND: 'Pitcairneilanden',
	STATE_BIALE_BLOTA: 'Biale Blota',
	STATE_DOBROSZYCE: 'Dobroszyce',
	STATE_DOLNOSLASKIE: 'Dolnoslaskie',
	STATE_DZIEKANOW_LESNY: 'Dziekanow Lesny',
	STATE_HOPOWO: 'Hopowo',
	STATE_KARTUZY: 'Kartuzy',
	STATE_KOSCIAN: 'Koscian',
	STATE_KRAKOW: 'Krakov',
	STATE_KUJAWSKO_POMORSKIE: 'Kujawsko-Pomorskie',
	STATE_LODZKIE: 'Lodzkie',
	STATE_LUBELSKIE: 'Lubelskie',
	STATE_LUBUSKIE: 'Lubuskie',
	STATE_MALOMICE: 'Malomice',
	STATE_MALOPOLSKIE: 'Malopolskie',
	STATE_MAZOWIECKIE: 'Mazowieckie',
	STATE_MIRKOW: 'Mirkow',
	STATE_OPOLSKIE: 'Opolskie',
	STATE_OSTROWIEC: 'Ostrowiec',
	STATE_PODKARPACKIE: 'Podkarpackie',
	STATE_PODLASKIE: 'Podlaskie',
	STATE_POLSKA: 'Polska',
	STATE_POMORSKIE: 'Pomorskie',
	STATE_POZNAN: 'Poznan',
	STATE_PRUSZKOW: 'Pruszkow',
	STATE_RYMANOWSKA: 'Rymanowska',
	STATE_RZESZOW: 'Rzeszow',
	STATE_SLASKIE: 'Slaskie',
	STATE_STARE_POLE: 'Stare Pole',
	STATE_SWIETOKRZYSKIE: 'Swietokrzyskie',
	STATE_WARMINSKO_MAZURSKIE: 'Warminsko-Mazurskie',
	STATE_WARSAW: 'Warschau',
	STATE_WEJHEROWO: 'Wejherowo',
	STATE_WIELKOPOLSKIE: 'Wielkopolskie',
	STATE_WROCLAW: 'Wroclaw',
	STATE_ZACHODNIO_POMORSKIE: 'Zachodnio-Pomorskie',
	STATE_ZUKOWO: 'Zukowo',
	STATE_ABRANTES: 'Abrantes',
	STATE_ACORES: 'Acores',
	STATE_ALENTEJO: 'Alentejo',
	STATE_ALGARVE: 'Algarve',
	STATE_BRAGA: 'Braga',
	STATE_CENTRO: 'Centro',
	STATE_DISTRITO_DE_LEIRIA: 'Distrito de Leiria',
	STATE_DISTRITO_DE_VIANA_DO_CASTELO: 'Distrito de Viana do Castelo',
	STATE_DISTRITO_DE_VILA_REAL: 'Distrito de Vila Real',
	STATE_DISTRITO_DO_PORTO: 'Distrito do Porto',
	STATE_LISBOA_E_VALE_DO_TEJO: 'Lisboa e Vale do Tejo',
	STATE_MADEIRA: 'Madeira',
	STATE_NORTE: 'Norte',
	STATE_PAIVAS: 'Paivas',
	STATE_ARECIBO: 'Arecibo',
	STATE_BAYAMON: 'Bayamon',
	STATE_CAROLINA: 'Carolina',
	STATE_FLORIDA: 'Florida',
	STATE_GUAYAMA: 'Guayama',
	STATE_HUMACAO: 'Humacao',
	STATE_MAYAGUEZ_AGUADILLA: 'Mayaguez-Aguadilla',
	STATE_PONCE: 'Ponce',
	STATE_SALINAS: 'Salinas',
	STATE_DOHA: 'Doha',
	STATE_JARIAN_AL_BATNAH: 'Jarian-al-Batnah',
	STATE_UMM_SALAL: 'Umm Salal',
	STATE_AD_DAWHAH: 'ad-Dawhah',
	STATE_AL_GHUWAYRIYAH: 'al-Ghuwayriyah',
	STATE_AL_JUMAYLIYAH: 'al-Jumayliyah',
	STATE_AL_KHAWR: 'al-Khawr',
	STATE_AL_WAKRAH: 'al-Wakrah',
	STATE_AR_RAYYAN: 'ar-Rayyan',
	STATE_SAINT_BENOIT: 'Saint-Benoit',
	STATE_SAINT_DENIS: 'Saint-Denis',
	STATE_ALBA: 'Alba',
	STATE_ARAD: 'Arad',
	STATE_ARGES: 'Arges',
	STATE_BACAU: 'Bacau',
	STATE_BIHOR: 'Bihor',
	STATE_BISTRITA_NASAUD: 'Bistrita-Nasaud',
	STATE_BOTOSANI: 'Botosani',
	STATE_BRAILA: 'Braila',
	STATE_BRASOV: 'Brasov',
	STATE_BUCURESTI: 'Bucuresti',
	STATE_BUZAU: 'Buzau',
	STATE_CALARASI: 'Calarasi',
	STATE_CARAS_SEVERIN: 'Caras-Severin',
	STATE_CLUJ: 'Cluj',
	STATE_CONSTANTA: 'Constanta',
	STATE_COVASNA: 'Covasna',
	STATE_DAMBOVITA: 'Dambovita',
	STATE_DOLJ: 'Dolj',
	STATE_GALATI: 'Galati',
	STATE_GIURGIU: 'Giurgiu',
	STATE_GORJ: 'Gorj',
	STATE_HARGHITA: 'Harghita',
	STATE_HUNEDOARA: 'Hunedoara',
	STATE_IALOMITA: 'Ialomita',
	STATE_IASI: 'Iasi',
	STATE_ILFOV: 'Ilfov',
	STATE_MARAMURES: 'Maramures',
	STATE_MEHEDINTI: 'Mehedinti',
	STATE_MURES: 'Mures',
	STATE_NEAMT: 'Neamt',
	STATE_OLT: 'Olt',
	STATE_PRAHOVA: 'Prahova',
	STATE_SALAJ: 'Salaj',
	STATE_SATU_MARE: 'Satu Mare',
	STATE_SIBIU: 'Sibiu',
	STATE_SONDELOR: 'Sondelor',
	STATE_SUCEAVA: 'Suceava',
	STATE_TELEORMAN: 'Teleorman',
	STATE_TIMIS: 'Timis',
	STATE_TULCEA: 'Tulcea',
	STATE_VALCEA: 'Valcea',
	STATE_VASLUI: 'Vaslui',
	STATE_VRANCEA: 'Vrancea',
	STATE_ADYGEJA: 'Adygeja',
	STATE_AGA: 'Aga',
	STATE_ALANIJA: 'Alanija',
	STATE_ALTAJ: 'Altaj',
	STATE_AMUR: 'Amur',
	STATE_ARHANGELSK: 'Arhangelsk',
	STATE_ASTRAHAN: 'Astrahan',
	STATE_BASHKORTOSTAN: 'Bashkortostan',
	STATE_BELGOROD: 'Belgorod',
	STATE_BRJANSK: 'Brjansk',
	STATE_BURJATIJA: 'Burjatija',
	STATE_CHECHENIJA: 'Chechenija',
	STATE_CHELJABINSK: 'Cheljabinsk',
	STATE_CHITA: 'Chita',
	STATE_CHUKOTKA: 'Chukotka',
	STATE_CHUVASHIJA: 'Chuvashija',
	STATE_DAGESTAN: 'Dagestan',
	STATE_EVENKIJA: 'Evenkija',
	STATE_GORNO_ALTAJ: 'Gorno-Altaj',
	STATE_HABAROVSK: 'Habarovsk',
	STATE_HAKASIJA: 'Hakasija',
	STATE_HANTY_MANSIJA: 'Hanty-Mansija',
	STATE_INGUSETIJA: 'Ingusetija',
	STATE_IRKUTSK: 'Irkoetsk',
	STATE_IVANOVO: 'Ivanovo',
	STATE_JAMALO_NENETS: 'Jamalo-Nenets',
	STATE_JAROSLAVL: 'Jaroslavl',
	STATE_JEVREJ: 'Jevrej',
	STATE_KABARDINO_BALKARIJA: 'Kabardino-Balkarija',
	STATE_KALININGRAD: 'Kaliningrad',
	STATE_KALMYKIJA: 'Kalmykija',
	STATE_KALUGA: 'Kaluga',
	STATE_KAMCHATKA: 'Kamchatka',
	STATE_KARACHAJ_CHERKESSIJA: 'Karachaj-Cherkessija',
	STATE_KARELIJA: 'Karelija',
	STATE_KEMEROVO: 'Kemerovo',
	STATE_KHABAROVSKIY_KRAY: 'Khabarovskiy Kray',
	STATE_KIROV: 'Kirov',
	STATE_KOMI: 'Komi',
	STATE_KOMI_PERMJAKIJA: 'Komi-Permjakija',
	STATE_KORJAKIJA: 'Korjakija',
	STATE_KOSTROMA: 'Kostroma',
	STATE_KRASNODAR: 'Krasnodar',
	STATE_KRASNOJARSK: 'Krasnojarsk',
	STATE_KRASNOYARSKIY_KRAY: 'Krasnoyarskiy Kray',
	STATE_KURGAN: 'Kurgan',
	STATE_KURSK: 'Kursk',
	STATE_LENINGRAD: 'Leningrad',
	STATE_LIPECK: 'Lipeck',
	STATE_MAGADAN: 'Magadan',
	STATE_MARIJ_EL: 'Marij El',
	STATE_MORDOVIJA: 'Mordovija',
	STATE_MOSCOW: 'Moskou',
	STATE_MOSKOVSKAJA_OBLAST: 'Moskovskaja Oblast',
	STATE_MOSKOVSKAYA_OBLAST: 'Moskovskaya Oblast',
	STATE_MOSKVA: 'Moskva',
	STATE_MURMANSK: 'Moermansk',
	STATE_NENETS: 'Nenets',
	STATE_NIZHNIJ_NOVGOROD: 'Nizhnij Novgorod',
	STATE_NOVGOROD: 'Novgorod',
	STATE_NOVOKUSNEZK: 'Novokusnezk',
	STATE_NOVOSIBIRSK: 'Novosibirsk',
	STATE_OMSK: 'Omsk',
	STATE_ORENBURG: 'Orenburg',
	STATE_ORJOL: 'Orjol',
	STATE_PENZA: 'Penza',
	STATE_PERM: 'Perm',
	STATE_PRIMORJE: 'Primorje',
	STATE_PSKOV: 'Pskov',
	STATE_PSKOVSKAYA_OBLAST: 'Pskovskaya Oblast',
	STATE_RJAZAN: 'Rjazan',
	STATE_ROSTOV: 'Rostov',
	STATE_SAHA: 'Saha',
	STATE_SAHALIN: 'Sahalin',
	STATE_SAMARA: 'Samara',
	STATE_SAMARSKAYA: 'Samarskaya',
	STATE_SANKT_PETERBURG: 'Sankt-Peterburg',
	STATE_SARATOV: 'Saratov',
	STATE_SMOLENSK: 'Smolensk',
	STATE_STAVROPOL: 'Stavropol',
	STATE_SVERDLOVSK: 'Sverdlovsk',
	STATE_TAJMYRIJA: 'Tajmyrija',
	STATE_TAMBOV: 'Tambov',
	STATE_TATARSTAN: 'Tatarstan',
	STATE_TJUMEN: 'Tjumen',
	STATE_TOMSK: 'Tomsk',
	STATE_TULA: 'Tula',
	STATE_TVER: 'Tver',
	STATE_TYVA: 'Tyva',
	STATE_UDMURTIJA: 'Udmurtija',
	STATE_ULJANOVSK: 'Uljanovsk',
	STATE_ULYANOVSKAYA_OBLAST: 'Ulyanovskaya Oblast',
	STATE_UST_ORDA: 'Ust-Orda',
	STATE_VLADIMIR: 'Vladimir',
	STATE_VOLGOGRAD: 'Wolgograd',
	STATE_VOLOGDA: 'Vologda',
	STATE_VORONEZH: 'Voronezh',
	STATE_BUTARE: 'Butare',
	STATE_BYUMBA: 'Byumba',
	STATE_CYANGUGU: 'Cyangugu',
	STATE_GIKONGORO: 'Gikongoro',
	STATE_GISENYI: 'Gisenyi',
	STATE_GITARAMA: 'Gitarama',
	STATE_KIBUNGO: 'Kibungo',
	STATE_KIBUYE: 'Kibuye',
	STATE_KIGALI_NGALI: 'Kigali-ngali',
	STATE_RUHENGERI: 'Ruhengeri',
	STATE_ASCENSION: 'Ascension',
	STATE_GOUGH_ISLAND: 'Gough',
	STATE_SAINT_HELENA: 'Sint-Helena',
	STATE_TRISTAN_DA_CUNHA: 'Tristan da Cunha',
	STATE_CHRIST_CHURCH_NICHOLA_TOWN: 'Christchurch Nichola Town',
	STATE_SAINT_ANNE_SANDY_POINT: 'Saint Anne Sandy Point',
	STATE_SAINT_GEORGE_BASSETERRE: 'Saint George Basseterre',
	STATE_SAINT_GEORGE_GINGERLAND: 'Saint George Gingerland',
	STATE_SAINT_JAMES_WINDWARD: 'Saint James Windward',
	STATE_SAINT_JOHN_CAPESTERRE: 'Saint John Capesterre',
	STATE_SAINT_JOHN_FIGTREE: 'Saint John Figtree',
	STATE_SAINT_MARY_CAYON: 'Saint Mary Cayon',
	STATE_SAINT_PAUL_CAPESTERRE: 'Saint Paul Capesterre',
	STATE_SAINT_PAUL_CHARLESTOWN: 'Saint Paul Charlestown',
	STATE_SAINT_PETER_BASSETERRE: 'Saint Peter Basseterre',
	STATE_SAINT_THOMAS_LOWLAND: 'Saint Thomas Lage landen',
	STATE_SAINT_THOMAS_MIDDLE_ISLAND: 'Saint Thomas Midden eiland',
	STATE_TRINITY_PALMETTO_POINT: 'Trinity Palmetto Point',
	STATE_ANSE_LA_RAYE: 'Anse-la-Raye',
	STATE_CANARIES: 'Canarische Eilanden',
	STATE_CASTRIES: 'Castries',
	STATE_CHOISEUL: 'Choiseul',
	STATE_DENNERY: 'Dennery',
	STATE_GROS_INLET: 'Gros Inlet',
	STATE_LABORIE: 'Laborie',
	STATE_MICOUD: 'Micoud',
	STATE_SOUFRIERE: 'Soufriere',
	STATE_VIEUX_FORT: 'Vieux Fort',
	STATE_MIQUELON_LANGLADE: 'Miquelon-Langlade',
	STATE_CHARLOTTE: 'Charlotte',
	STATE_GRENADINES: 'Grenadinen',
	STATE_A__ANA: "A'ana",
	STATE_AIGA_I_LE_TAI: 'Aiga-i-le-Tai',
	STATE_ATUA: 'Atua',
	STATE_FA__ASALELEAGA: "Fa'asaleleaga",
	STATE_GAGA__EMAUGA: "Gaga'emauga",
	STATE_GAGAIFOMAUGA: 'Gagaifomauga',
	STATE_PALAULI: 'Palauli',
	STATE_SATUPA__ITEA: "Satupa'itea",
	STATE_TUAMASAGA: 'Tuamasaga',
	STATE_VA__A_O_FONOTI: "Va'a-o-Fonoti",
	STATE_VAISIGANO: 'Vaisigano',
	STATE_ACQUAVIVA: 'Acquaviva',
	STATE_BORGO_MAGGIORE: 'Borgo Maggiore',
	STATE_CHIESANUOVA: 'Chiesanuova',
	STATE_DOMAGNANO: 'Domagnano',
	STATE_FAETANO: 'Faetano',
	STATE_FIORENTINO: 'Fiorentino',
	STATE_MONTEGIARDINO: 'Montegiardino',
	STATE_SAN_MARINO: 'San Marino',
	STATE_SERRAVALLE: 'Serravalle',
	STATE_AGUA_GRANDE: 'Agua Grande',
	STATE_CANTAGALO: 'Cantagalo',
	STATE_LEMBA: 'Lemba',
	STATE_LOBATA: 'Lobata',
	STATE_ME_ZOCHI: 'Me-Zochi',
	STATE_PAGUE: 'Pague',
	STATE_AL_KHOBAR: 'Al Khobar',
	STATE_ASEER: 'Aseer',
	STATE_ASIR: 'Asir',
	STATE_CENTRAL_PROVINCE: 'Centrale provincie',
	STATE_EASTERN_PROVINCE: 'Oostelijke provincie',
	STATE_HA__IL: "Ha'il",
	STATE_JAWF: 'Jawf',
	STATE_JIZAN: 'Jizan',
	STATE_MAKKAH: 'Makkah',
	STATE_NAJRAN: 'Najran',
	STATE_QASIM: 'Qasim',
	STATE_TABUK: 'Tabuk',
	STATE_WESTERN_PROVINCE: 'Westelijke provincie',
	STATE_AL_BAHAH: 'al-Bahah',
	STATE_AL_HUDUD_ASH_SHAMALIYAH: 'al-Hudud-ash-Shamaliyah',
	STATE_AL_MADINAH: 'al-Madinah',
	STATE_AR_RIYAD: 'ar-Riyad',
	STATE_DAKAR: 'Dakar',
	STATE_DIOURBEL: 'Diourbel',
	STATE_FATICK: 'Fatick',
	STATE_KAOLACK: 'Kaolack',
	STATE_KOLDA: 'Kolda',
	STATE_LOUGA: 'Louga',
	STATE_SAINT_LOUIS: 'Saint-Louis',
	STATE_TAMBACOUNDA: 'Tambacounda',
	STATE_THIES: 'Thies',
	STATE_ZIGUINCHOR: 'Ziguinchor',
	STATE_CENTRAL_SERBIA: 'Centraal Servië',
	STATE_KOSOVO_AND_METOHIJA: 'Kosovo en Metohija',
	STATE_VOJVODINA: 'Vojvodina',
	STATE_ANSE_BOILEAU: 'Anse Boileau',
	STATE_ANSE_ROYALE: 'Anse Royale',
	STATE_CASCADE: 'Cascade',
	STATE_TAKAMAKA: 'Takamaka',
	STATE_SINGAPORE: 'Singapore',
	STATE_BANSKOBYSTRICKY: 'Banskobystricky',
	STATE_BRATISLAVSKY: 'Bratislavsky',
	STATE_KOSICKY: 'Kosicky',
	STATE_NITRIANSKY: 'Nitriansky',
	STATE_PRESOVSKY: 'Presovsky',
	STATE_TRENCIANSKY: 'Trenciansky',
	STATE_TRNAVSKY: 'Trnavsky',
	STATE_ZILINSKY: 'Zilinsky',
	STATE_BENEDIKT: 'Benedikt',
	STATE_GORENJSKA: 'Gorenjska',
	STATE_GORISHKA: 'Gorishka',
	STATE_JUGOVZHODNA_SLOVENIJA: 'Jugovzhodna Slovenija',
	STATE_KOROSHKA: 'Koroshka',
	STATE_NOTRANJSKO_KRASHKA: 'Notranjsko-krashka',
	STATE_OBALNO_KRASHKA: 'Obalno-krashka',
	STATE_OBCINA_DOMZALE: 'Obcina Domzale',
	STATE_OBCINA_VITANJE: 'Obcina Vitanje',
	STATE_OSREDNJESLOVENSKA: 'Osrednjeslovenska',
	STATE_PODRAVSKA: 'Podravska',
	STATE_POMURSKA: 'Pomurska',
	STATE_SAVINJSKA: 'Savinjska',
	STATE_SLOVENIAN_LITTORAL: 'Slovenian Littoral',
	STATE_SPODNJEPOSAVSKA: 'Spodnjeposavska',
	STATE_ZASAVSKA: 'Zasavska',
	STATE_GUADALCANAL: 'Guadalcanal',
	STATE_ISABEL: 'Isabel',
	STATE_MAKIRA_AND_ULAWA: 'Makira en Ulawa',
	STATE_MALAITA: 'Malaita',
	STATE_RENNELL_AND_BELLONA: 'Rennell en Bellona',
	STATE_TEMOTU: 'Temotu',
	STATE_AWDAL: 'Awdal',
	STATE_BAKOL: 'Bakol',
	STATE_BANADIR: 'Banadir',
	STATE_BAY: 'Bay',
	STATE_GALGUDUG: 'Galgudug',
	STATE_GEDO: 'Gedo',
	STATE_HIRAN: 'Hiran',
	STATE_JUBBADA_HOSE: 'Jubbada Hose',
	STATE_JUBBADHA_DEXE: 'Jubbadha Dexe',
	STATE_MUDUG: 'Mudug',
	STATE_NUGAL: 'Nugal',
	STATE_SANAG: 'Sanag',
	STATE_SHABELLAHA_DHEXE: 'Shabellaha Dhexe',
	STATE_SHABELLAHA_HOSE: 'Shabellaha Hose',
	STATE_TOGDHER: 'Togdher',
	STATE_WOQOYI_GALBED: 'Woqoyi Galbed',
	STATE_EASTERN_CAPE: 'Oostelijke Kaap',
	STATE_FREE_STATE: 'Vrijstaat',
	STATE_GAUTENG: 'Gauteng',
	STATE_KEMPTON_PARK: 'Kempton Park',
	STATE_KRAMERVILLE: 'Kramerville',
	STATE_KWAZULU_NATAL: 'KwaZulu Natal',
	STATE_LIMPOPO: 'Limpopo',
	STATE_MPUMALANGA: 'Mpumalanga',
	STATE_NORTH_WEST: 'Noord-West',
	STATE_NORTHERN_CAPE: 'Noordelijke Kaap',
	STATE_PAROW: 'Parow',
	STATE_TABLE_VIEW: 'Table View',
	STATE_UMTENTWENI: 'Umtentweni',
	STATE_WESTERN_CAPE: 'Westelijke Kaap',
	STATE_CENTRAL_EQUATORIA: 'Central Equatoria',
	STATE_A_CORUNA: 'A Coruna',
	STATE_ALACANT: 'Alacant',
	STATE_ALAVA: 'Alava',
	STATE_ALBACETE: 'Albacete',
	STATE_ALMERIA: 'Almeria',
	STATE_ANDALUCIA: 'Andalucia',
	STATE_ASTURIAS: 'Asturias',
	STATE_AVILA: 'Avila',
	STATE_BADAJOZ: 'Badajoz',
	STATE_BALEARS: 'Balears',
	STATE_BARCELONA: 'Barcelona',
	STATE_BERTAMIRANS: 'Bertamirans',
	STATE_BISCAY: 'Biscay',
	STATE_BURGOS: 'Burgos',
	STATE_CACERES: 'Caceres',
	STATE_CADIZ: 'Cadiz',
	STATE_CANTABRIA: 'Cantabria',
	STATE_CASTELLO: 'Castello',
	STATE_CATALUNYA: 'Catalunya',
	STATE_CEUTA: 'Ceuta',
	STATE_CIUDAD_REAL: 'Ciudad Real',
	STATE_COMUNIDAD_AUTONOMA_DE_CANARIAS: 'Comunidad Autonoma de Canarias',
	STATE_COMUNIDAD_AUTONOMA_DE_CATALUNA: 'Comunidad Autonoma de Cataluna',
	STATE_COMUNIDAD_AUTONOMA_DE_GALICIA: 'Comunidad Autonoma de Galicia',
	STATE_COMUNIDAD_AUTONOMA_DE_LAS_ISLA: 'Comunidad Autonoma de las Isla',
	STATE_COMUNIDAD_AUTONOMA_DEL_PRINCIP: 'Comunidad Autonoma del Princip',
	STATE_COMUNIDAD_VALENCIANA: 'Comunidad Valenciana',
	STATE_CUENCA: 'Cuenca',
	STATE_GIPUZKOA: 'Gipuzkoa',
	STATE_GIRONA: 'Girona',
	STATE_GUADALAJARA: 'Guadalajara',
	STATE_GUIPUZCOA: 'Guipuzcoa',
	STATE_HUELVA: 'Huelva',
	STATE_HUESCA: 'Huesca',
	STATE_JAEN: 'Jaen',
	STATE_LAS_PALMAS: 'Las Palmas',
	STATE_LERIDA: 'Lerida',
	STATE_LLEIDA: 'Lleida',
	STATE_LUGO: 'Lugo',
	STATE_MADRID: 'Madrid',
	STATE_MALAGA: 'Malaga',
	STATE_MELILLA: 'Melilla',
	STATE_MURCIA: 'Murcia',
	STATE_NAVARRA: 'Navarra',
	STATE_OURENSE: 'Ourense',
	STATE_PAIS_VASCO: 'Pais Vasco',
	STATE_PALENCIA: 'Palencia',
	STATE_PONTEVEDRA: 'Pontevedra',
	STATE_SALAMANCA: 'Salamanca',
	STATE_SANTA_CRUZ_DE_TENERIFE: 'Santa Cruz de Tenerife',
	STATE_SEGOVIA: 'Segovia',
	STATE_SEVILLA: 'Sevilla',
	STATE_SORIA: 'Soria',
	STATE_TARRAGONA: 'Tarragona',
	STATE_TENERIFE: 'Tenerife',
	STATE_TERUEL: 'Teruel',
	STATE_VALENCIA: 'Valencia',
	STATE_VALLADOLID: 'Valladolid',
	STATE_VIZCAYA: 'Vizcaya',
	STATE_ZAMORA: 'Zamora',
	STATE_ZARAGOZA: 'Zaragoza',
	STATE_AMPARAI: 'Amparai',
	STATE_ANURADHAPURAYA: 'Anuradhapuraya',
	STATE_BADULLA: 'Badulla',
	STATE_BORALESGAMUWA: 'Boralesgamuwa',
	STATE_COLOMBO: 'Colombo',
	STATE_GALLA: 'Galla',
	STATE_GAMPAHA: 'Gampaha',
	STATE_HAMBANTOTA: 'Hambantota',
	STATE_KALATURA: 'Kalatura',
	STATE_KEGALLA: 'Kegalla',
	STATE_KILINOCHCHI: 'Kilinochchi',
	STATE_KURUNEGALA: 'Kurunegala',
	STATE_MADAKALPUWA: 'Madakalpuwa',
	STATE_MAHA_NUWARA: 'Maha Nuwara',
	STATE_MALWANA: 'Malwana',
	STATE_MANNARAMA: 'Mannarama',
	STATE_MATALE: 'Matale',
	STATE_MATARA: 'Matara',
	STATE_MONARAGALA: 'Monaragala',
	STATE_MULLAITIVU: 'Mullaitivu',
	STATE_NORTH_EASTERN_PROVINCE: 'Noordoostelijke provincie',
	STATE_NORTH_WESTERN_PROVINCE: 'Noordwestelijke provincie',
	STATE_NUWARA_ELIYA: 'Nuwara Eliya',
	STATE_POLONNARUWA: 'Polonnaruwa',
	STATE_PUTTALAMA: 'Puttalama',
	STATE_RATNAPURAYA: 'Ratnapuraya',
	STATE_SOUTHERN_PROVINCE: 'Zuidelijke provincie',
	STATE_TIRIKUNAMALAYA: 'Tirikunamalaya',
	STATE_TUSCANY: 'Tuscane',
	STATE_VAVUNIYAWA: 'Vavuniyawa',
	STATE_YAPANAYA: 'Yapanaya',
	STATE_KADAWATHA: 'kadawatha',
	STATE_A__ALI_AN_NIL: "A'ali-an-Nil",
	STATE_BAHR_AL_JABAL: 'Bahr-al-Jabal',
	STATE_GHARB_BAHR_AL_GHAZAL: 'Gharb Bahr-al-Ghazal',
	STATE_GHARB_DARFUR: 'Gharb Darfur',
	STATE_GHARB_KURDUFAN: 'Gharb Kurdufan',
	STATE_GHARB_AL_ISTIWA__IYAH: "Gharb-al-Istiwa'iyah",
	STATE_JANUB_DARFUR: 'Janub Darfur',
	STATE_JANUB_KURDUFAN: 'Janub Kurdufan',
	STATE_JUNQALI: 'Junqali',
	STATE_KASSALA: 'Kassala',
	STATE_NAHR_AN_NIL: 'Nahr-an-Nil',
	STATE_SHAMAL_BAHR_AL_GHAZAL: 'Shamal Bahr-al-Ghazal',
	STATE_SHAMAL_DARFUR: 'Shamal Darfur',
	STATE_SHAMAL_KURDUFAN: 'Shamal Kurdufan',
	STATE_SHARQ_AL_ISTIWA__IYAH: "Sharq-al-Istiwa'iyah",
	STATE_SINNAR: 'Sinnar',
	STATE_WARAB: 'Warab',
  STATE_WILAYAT_AL_KHARTUM: 'Wilayat al Khartum',
	STATE_AL_BUHAYRAT: 'al-Buhayrat',
	STATE_AL_JAZIRAH: 'al-Jazirah',
	STATE_AL_KHARTUM: 'al-Khartum',
	STATE_AL_QADARIF: 'al-Qadarif',
	STATE_AL_WAHDAH: 'al-Wahdah',
	STATE_AN_NIL_AL_ABYAD: 'an-Nil-al-Abyad',
	STATE_AN_NIL_AL_AZRAQ: 'an-Nil-al-Azraq',
	STATE_ASH_SHAMALIYAH: 'ash-Shamaliyah',
	STATE_BROKOPONDO: 'Brokopondo',
	STATE_COMMEWIJNE: 'Commewijne',
	STATE_CORONIE: 'Coronie',
	STATE_MAROWIJNE: 'Marowijne',
	STATE_NICKERIE: 'Nickerie',
	STATE_PARAMARIBO: 'Paramaribo',
	STATE_SARAMACCA: 'Saramacca',
	STATE_WANICA: 'Wanica',
	STATE_SVALBARD: 'Svalbard',
	STATE_HHOHHO: 'Hhohho',
	STATE_LUBOMBO: 'Lubombo',
	STATE_MANZINI: 'Manzini',
	STATE_SHISELWENI: 'Shiselweni',
	STATE_ALVSBORGS_LAN: 'Alvsborgs Lan',
	STATE_ANGERMANLAND: 'Angermanland',
	STATE_BLEKINGE: 'Blekinge',
	STATE_BOHUSLAN: 'Bohuslan',
	STATE_DALARNA: 'Dalarna',
	STATE_GAVLEBORG: 'Gavleborg',
	STATE_GOTLAND: 'Gotland',
	STATE_HALLAND: 'Halland',
	STATE_JAMTLAND: 'Jamtland',
	STATE_JONKOPING: 'Jonkoping',
	STATE_KALMAR: 'Kalmar',
	STATE_KRISTIANSTADS: 'Kristianstads',
	STATE_KRONOBERG: 'Kronoberg',
	STATE_NORRBOTTEN: 'Norrbotten',
	STATE_OREBRO: 'Orebro',
	STATE_OSTERGOTLAND: 'Ostergotland',
	STATE_SALTSJO_BOO: 'Saltsjo-Boo',
	STATE_SKANE: 'Skane',
	STATE_SMALAND: 'Smaland',
	STATE_SODERMANLAND: 'Sodermanland',
	STATE_STOCKHOLM: 'Stockholm',
	STATE_UPPSALA: 'Uppsala',
	STATE_VARMLAND: 'Varmland',
	STATE_VASTERBOTTEN: 'Vasterbotten',
	STATE_VASTERGOTLAND: 'Vastergotland',
	STATE_VASTERNORRLAND: 'Vasternorrland',
	STATE_VASTMANLAND: 'Vastmanland',
	STATE_VASTRA_GOTALAND: 'Vastra Gotaland',
	STATE_AARGAU: 'Aargau',
	STATE_APPENZELL_INNER_RHODEN: 'Appenzell Inner-Rhoden',
	STATE_APPENZELL_AUSSER_RHODEN: 'Appenzell-Ausser Rhoden',
	STATE_BASEL_LANDSCHAFT: 'Basel-Landschaft',
	STATE_BASEL_STADT: 'Basel-Stad',
	STATE_BERN: 'Bern',
	STATE_CANTON_TICINO: 'Canton Ticino',
	STATE_FRIBOURG: 'Fribourg',
	STATE_GENEVE: 'Genève',
	STATE_GLARUS: 'Glarus',
	STATE_GRAUBUNDEN: 'Graubunden',
	STATE_HEERBRUGG: 'Heerbrugg',
	STATE_KANTON_AARGAU: 'Kanton Aargau',
	STATE_LUZERN: 'Luzern',
	STATE_MORBIO_INFERIORE: 'Morbio Inferiore',
	STATE_MUHEN: 'Muhen',
	STATE_NEUCHATEL: 'Neuchatel',
	STATE_NIDWALDEN: 'Nidwalden',
	STATE_OBWALDEN: 'Obwalden',
	STATE_SANKT_GALLEN: 'Sankt Gallen',
	STATE_SCHAFFHAUSEN: 'Schaffhausen',
	STATE_SCHWYZ: 'Schwyz',
	STATE_SOLOTHURN: 'Solothurn',
	STATE_THURGAU: 'Thurgau',
	STATE_TICINO: 'Ticino',
	STATE_URI: 'Uri',
	STATE_VALAIS: 'Valais',
	STATE_VAUD: 'Vaud',
	STATE_VAUFFELIN: 'Vauffelin',
	STATE_ZUG: 'Zug',
	STATE_ZURICH: 'Zürich',
	STATE_ALEPPO: 'Aleppo',
	STATE_DAR__A: "Dar'a",
	STATE_DAYR_AZ_ZAWR: 'Dayr-az-Zawr',
	STATE_DIMASHQ: 'Dimashq',
	STATE_HALAB: 'Halab',
	STATE_HAMAH: 'Hamah',
	STATE_HIMS: 'Hims',
	STATE_IDLIB: 'Idlib',
	STATE_MADINAT_DIMASHQ: 'Madinat Dimashq',
	STATE_TARTUS: 'Tartus',
	STATE_AL_HASAKAH: 'al-Hasakah',
	STATE_AL_LADHIQIYAH: 'al-Ladhiqiyah',
	STATE_AL_QUNAYTIRAH: 'al-Qunaytirah',
	STATE_AR_RAQQAH: 'ar-Raqqah',
	STATE_AS_SUWAYDA: 'as-Suwayda',
	STATE_CHANGHWA: 'Changhwa',
	STATE_CHIAYI_HSIEN: 'Chiayi Hsien',
	STATE_CHIAYI_SHIH: 'Chiayi Shih',
	STATE_EASTERN_TAIPEI: 'Oostelijk Taipei',
	STATE_HSINCHU_HSIEN: 'Hsinchu Hsien',
	STATE_HSINCHU_SHIH: 'Hsinchu Hsien',
	STATE_HUALIEN: 'Hualien',
	STATE_ILAN: 'Ilan',
	STATE_KAOHSIUNG_HSIEN: 'Kaohsiung Hsien',
	STATE_KAOHSIUNG_SHIH: 'Kaohsiung Shih',
	STATE_KEELUNG_SHIH: 'Keelung Shih',
	STATE_KINMEN: 'Kinmen',
	STATE_MIAOLI: 'Miaoli',
	STATE_NANTOU: 'Nantou',
	STATE_NORTHERN_TAIWAN: 'Noord-Taiwan',
	STATE_PENGHU: 'Penghu',
	STATE_PINGTUNG: 'Pingtung',
	STATE_TAICHUNG: 'Taichung',
	STATE_TAICHUNG_HSIEN: 'Taichung Hsien',
	STATE_TAICHUNG_SHIH: 'Taichung Shih',
	STATE_TAINAN_HSIEN: 'Tainan Hsien',
	STATE_TAINAN_SHIH: 'Tainan Shih',
	STATE_TAIPEI_HSIEN: 'Taipei Hsien',
	STATE_TAIPEI_SHIH___TAIPEI_HSIEN: 'Taipei Shih / Taipei Hsien',
	STATE_TAITUNG: 'Taitung',
	STATE_TAOYUAN: 'Taoyuan',
	STATE_YILAN: 'Yilan',
	STATE_YUN_LIN_HSIEN: 'Yun-Lin Hsien',
	STATE_YUNLIN: 'Yunlin',
	STATE_DUSHANBE: 'Dushanbe',
	STATE_GORNO_BADAKHSHAN: 'Gorno-Badakhshan',
	STATE_KAROTEGIN: 'Karotegin',
	STATE_KHATLON: 'Khatlon',
	STATE_SUGHD: 'Sughd',
	STATE_ARUSHA: 'Arusha',
	STATE_DAR_ES_SALAAM: 'Dar es Salaam',
	STATE_DODOMA: 'Dodoma',
	STATE_IRINGA: 'Iringa',
	STATE_KAGERA: 'Kagera',
	STATE_KIGOMA: 'Kigoma',
	STATE_KILIMANJARO: 'Kilimanjaro',
	STATE_LINDI: 'Lindi',
	STATE_MARA: 'Mara',
	STATE_MBEYA: 'Mbeya',
	STATE_MOROGORO: 'Morogoro',
	STATE_MTWARA: 'Mtwara',
	STATE_PWANI: 'Pwani',
	STATE_RUKWA: 'Rukwa',
	STATE_RUVUMA: 'Ruvuma',
	STATE_SHINYANGA: 'Shinyanga',
	STATE_SINGIDA: 'Singida',
	STATE_TABORA: 'Tabora',
	STATE_TANGA: 'Tanga',
	STATE_ZANZIBAR_AND_PEMBA: 'Zanzibar en Pemba',
	STATE_AMNAT_CHAROEN: 'Amnat Charoen',
	STATE_ANG_THONG: 'Ang Thong',
	STATE_BANGKOK: 'Bangkok',
	STATE_BURI_RAM: 'Buri Ram',
	STATE_CHACHOENGSAO: 'Chachoengsao',
	STATE_CHAI_NAT: 'Chai Nat',
	STATE_CHAIYAPHUM: 'Chaiyaphum',
	STATE_CHANGWAT_CHAIYAPHUM: 'Changwat Chaiyaphum',
	STATE_CHANTHABURI: 'Chanthaburi',
	STATE_CHIANG_MAI: 'Chiang Mai',
	STATE_CHIANG_RAI: 'Chiang Rai',
	STATE_CHON_BURI: 'Chon Buri',
	STATE_CHUMPHON: 'Chumphon',
	STATE_KALASIN: 'Kalasin',
	STATE_KAMPHAENG_PHET: 'Kamphaeng Phet',
	STATE_KANCHANABURI: 'Kanchanaburi',
	STATE_KHON_KAEN: 'Khon Kaen',
	STATE_KRABI: 'Krabi',
	STATE_KRUNG_THEP: 'Krung Thep',
	STATE_LAMPANG: 'Lampang',
	STATE_LAMPHUN: 'Lamphun',
	STATE_LOEI: 'Loei',
	STATE_LOP_BURI: 'Lop Buri',
	STATE_MAE_HONG_SON: 'Mae Hong Son',
	STATE_MAHA_SARAKHAM: 'Maha Sarakham',
	STATE_MUKDAHAN: 'Mukdahan',
	STATE_NAKHON_NAYOK: 'Nakhon Nayok',
	STATE_NAKHON_PATHOM: 'Nakhon Pathom',
	STATE_NAKHON_PHANOM: 'Nakhon Phanom',
	STATE_NAKHON_RATCHASIMA: 'Nakhon Ratchasima',
	STATE_NAKHON_SAWAN: 'Nakhon Sawan',
	STATE_NAKHON_SI_THAMMARAT: 'Nakhon Si Thammarat',
	STATE_NAN: 'Nan',
	STATE_NARATHIWAT: 'Narathiwat',
	STATE_NONG_BUA_LAM_PHU: 'Nong Bua Lam Phu',
	STATE_NONG_KHAI: 'Nong Khai',
	STATE_NONTHABURI: 'Nonthaburi',
	STATE_PATHUM_THANI: 'Pathum Thani',
	STATE_PATTANI: 'Pattani',
	STATE_PHANGNGA: 'Phangnga',
	STATE_PHATTHALUNG: 'Phatthalung',
	STATE_PHAYAO: 'Phayao',
	STATE_PHETCHABUN: 'Phetchabun',
	STATE_PHETCHABURI: 'Phetchaburi',
	STATE_PHICHIT: 'Phichit',
	STATE_PHITSANULOK: 'Phitsanulok',
	STATE_PHRA_NAKHON_SI_AYUTTHAYA: 'Phra Nakhon Si Ayutthaya',
	STATE_PHRAE: 'Phrae',
	STATE_PHUKET: 'Phuket',
	STATE_PRACHIN_BURI: 'Prachin Buri',
	STATE_PRACHUAP_KHIRI_KHAN: 'Prachuap Khiri Khan',
	STATE_RANONG: 'Ranong',
	STATE_RATCHABURI: 'Ratchaburi',
	STATE_RAYONG: 'Rayong',
	STATE_ROI_ET: 'Roi Et',
	STATE_SA_KAEO: 'Sa Kaeo',
	STATE_SAKON_NAKHON: 'Sakon Nakhon',
	STATE_SAMUT_PRAKAN: 'Samut Prakan',
	STATE_SAMUT_SAKHON: 'Samut Sakhon',
	STATE_SAMUT_SONGKHRAN: 'Samut Songkhran',
	STATE_SARABURI: 'Saraburi',
	STATE_SATUN: 'Satun',
	STATE_SI_SA_KET: 'Si Sa Ket',
	STATE_SING_BURI: 'Sing Buri',
	STATE_SONGKHLA: 'Songkhla',
	STATE_SUKHOTHAI: 'Sukhothai',
	STATE_SUPHAN_BURI: 'Suphan Buri',
	STATE_SURAT_THANI: 'Surat Thani',
	STATE_SURIN: 'Surin',
	STATE_TAK: 'Tak',
	STATE_TRANG: 'Trang',
	STATE_TRAT: 'Trat',
	STATE_UBON_RATCHATHANI: 'Ubon Ratchathani',
	STATE_UDON_THANI: 'Udon Thani',
	STATE_UTHAI_THANI: 'Uthai Thani',
	STATE_UTTARADIT: 'Uttaradit',
	STATE_YALA: 'Yala',
	STATE_YASOTHON: 'Yasothon',
	STATE_KARA: 'Kara',
	STATE_MARITIME: 'Maritime',
	STATE_ATAFU: 'Atafu',
	STATE_FAKAOFO: 'Fakaofo',
	STATE_NUKUNONU: 'Nukunonu',
	STATE_EUA: 'Eua',
	STATE_HA__APAI: "Ha'apai",
	STATE_NIUAS: 'Niuas',
	STATE_TONGATAPU: 'Tongatapu',
	STATE_VAVA__U: "Vava'u",
	STATE_ARIMA_TUNAPUNA_PIARCO: 'Arima-Tunapuna-Piarco',
	STATE_CARONI: 'Caroni',
	STATE_CHAGUANAS: 'Chaguanas',
	STATE_COUVA_TABAQUITE_TALPARO: 'Couva-Tabaquite-Talparo',
	STATE_DIEGO_MARTIN: 'Diego Martin',
	STATE_GLENCOE: 'Glencoe',
	STATE_PENAL_DEBE: 'Penal Debe',
	STATE_POINT_FORTIN: 'Point Fortin',
	STATE_PORT_OF_SPAIN: 'Port of Spain',
	STATE_PRINCES_TOWN: 'Princes Town',
	STATE_SAN_FERNANDO: 'San Fernando',
	STATE_SANGRE_GRANDE: 'Sangre Grande',
	STATE_SIPARIA: 'Siparia',
	STATE_TOBAGO: 'Tobago',
	STATE_ARYANAH: 'Aryanah',
	STATE_BAJAH: 'Bajah',
	STATE_BIN___ARUS: "Bin 'Arus",
	STATE_BINZART: 'Binzart',
	STATE_GOUVERNORAT_DE_ARIANA: 'Gouvernorat de Ariana',
	STATE_GOUVERNORAT_DE_NABEUL: 'Gouvernorat de Nabeul',
	STATE_GOUVERNORAT_DE_SOUSSE: 'Gouvernorat de Sousse',
	STATE_HAMMAMET_YASMINE: 'Hammamet Yasmine',
	STATE_JUNDUBAH: 'Jundubah',
	STATE_MADANIYIN: 'Madaniyin',
	STATE_MANUBAH: 'Manubah',
	STATE_MONASTIR: 'Monastir',
	STATE_NABUL: 'Nabul',
	STATE_QABIS: 'Qabis',
	STATE_QAFSAH: 'Qafsah',
	STATE_QIBILI: 'Qibili',
	STATE_SAFAQIS: 'Safaqis',
	STATE_SFAX: 'Sfax',
	STATE_SIDI_BU_ZAYD: 'Sidi Bu Zayd',
	STATE_SILYANAH: 'Silyanah',
	STATE_SUSAH: 'Susah',
	STATE_TATAWIN: 'Tatawin',
	STATE_TAWZAR: 'Tawzar',
	STATE_TUNIS: 'Tunis',
	STATE_ZAGHWAN: 'Zaghwan',
	STATE_AL_KAF: 'al-Kaf',
	STATE_AL_MAHDIYAH: 'al-Mahdiyah',
	STATE_AL_MUNASTIR: 'al-Munastir',
	STATE_AL_QASRAYN: 'al-Qasrayn',
	STATE_AL_QAYRAWAN: 'al-Qayrawan',
	STATE_ADANA: 'Adana',
	STATE_ADIYAMAN: 'Adiyaman',
	STATE_AFYON: 'Afyon',
	STATE_AGRI: 'Agri',
	STATE_AKSARAY: 'Aksaray',
	STATE_AMASYA: 'Amasya',
	STATE_ANKARA: 'Ankara',
	STATE_ANTALYA: 'Antalya',
	STATE_ARDAHAN: 'Ardahan',
	STATE_ARTVIN: 'Artvin',
	STATE_AYDIN: 'Aydin',
	STATE_BALIKESIR: 'Balikesir',
	STATE_BARTIN: 'Bartin',
	STATE_BATMAN: 'Batman',
	STATE_BAYBURT: 'Bayburt',
	STATE_BILECIK: 'Bilecik',
	STATE_BINGOL: 'Bingol',
	STATE_BITLIS: 'Bitlis',
	STATE_BOLU: 'Bolu',
	STATE_BURDUR: 'Burdur',
	STATE_BURSA: 'Bursa',
	STATE_CANAKKALE: 'Canakkale',
	STATE_CANKIRI: 'Cankiri',
	STATE_CORUM: 'Corum',
	STATE_DENIZLI: 'Denizli',
	STATE_DIYARBAKIR: 'Diyarbakir',
	STATE_DUZCE: 'Duzce',
	STATE_EDIRNE: 'Edirne',
	STATE_ELAZIG: 'Elazig',
	STATE_ERZINCAN: 'Erzincan',
	STATE_ERZURUM: 'Erzurum',
	STATE_ESKISEHIR: 'Eskisehir',
	STATE_GAZIANTEP: 'Gaziantep',
	STATE_GIRESUN: 'Giresun',
	STATE_GUMUSHANE: 'Gumushane',
	STATE_HAKKARI: 'Hakkari',
	STATE_HATAY: 'Hatay',
	STATE_ICEL: 'Icel',
	STATE_IGDIR: 'Igdir',
	STATE_ISPARTA: 'Isparta',
	STATE_ISTANBUL: 'Istanbul',
	STATE_IZMIR: 'Izmir',
	STATE_KAHRAMANMARAS: 'Kahramanmaras',
	STATE_KARABUK: 'Karabuk',
	STATE_KARAMAN: 'Karaman',
	STATE_KARS: 'Kars',
	STATE_KARSIYAKA: 'Karsiyaka',
	STATE_KASTAMONU: 'Kastamonu',
	STATE_KAYSERI: 'Kayseri',
	STATE_KILIS: 'Kilis',
	STATE_KIRIKKALE: 'Kirikkale',
	STATE_KIRKLARELI: 'Kirklareli',
	STATE_KIRSEHIR: 'Kirsehir',
	STATE_KOCAELI: 'Kocaeli',
	STATE_KONYA: 'Konya',
	STATE_KUTAHYA: 'Kutahya',
	STATE_LEFKOSA: 'Lefkosa',
	STATE_MALATYA: 'Malatya',
	STATE_MANISA: 'Manisa',
	STATE_MARDIN: 'Mardin',
	STATE_MUGLA: 'Mugla',
	STATE_MUS: 'Mus',
	STATE_NEVSEHIR: 'Nevsehir',
	STATE_NIGDE: 'Nigde',
	STATE_ORDU: 'Ordu',
	STATE_OSMANIYE: 'Osmaniye',
	STATE_RIZE: 'Rize',
	STATE_SAKARYA: 'Sakarya',
	STATE_SAMSUN: 'Samsun',
	STATE_SANLIURFA: 'Sanliurfa',
	STATE_SIIRT: 'Siirt',
	STATE_SINOP: 'Sinop',
	STATE_SIRNAK: 'Sirnak',
	STATE_SIVAS: 'Sivas',
	STATE_TEKIRDAG: 'Tekirdag',
	STATE_TOKAT: 'Tokat',
	STATE_TRABZON: 'Trabzon',
	STATE_TUNCELI: 'Tunceli',
	STATE_USAK: 'Usak',
	STATE_VAN: 'Van',
	STATE_YALOVA: 'Yalova',
	STATE_YOZGAT: 'Yozgat',
	STATE_ZONGULDAK: 'Zonguldak',
	STATE_AHAL: 'Ahal',
	STATE_ASGABAT: 'Asgabat',
	STATE_BALKAN: 'Balkan',
	STATE_DASOGUZ: 'Dasoguz',
	STATE_LEBAP: 'Lebap',
	STATE_MARI: 'Mari',
	STATE_GRAND_TURK: 'Grand Turk',
	STATE_SOUTH_CAICOS_AND_EAST_CAICOS: 'Zuid-Caicos en Oost-Caicos',
	STATE_FUNAFUTI: 'Funafuti',
	STATE_NANUMANGA: 'Nanumanga',
	STATE_NANUMEA: 'Nanumea',
	STATE_NIUTAO: 'Niutao',
	STATE_NUI: 'Nui',
	STATE_NUKUFETAU: 'Nukufetau',
	STATE_NUKULAELAE: 'Nukulaelae',
	STATE_VAITUPU: 'Vaitupu',
	STATE_CHERKAS__KA: "Cherkas'ka",
	STATE_CHERNIHIVS__KA: "Chernihivs'ka",
	STATE_CHERNIVETS__KA: "Chernivets'ka",
	STATE_CRIMEA: 'Crimea',
	STATE_DNIPROPETROVSKA: 'Dnipropetrovska',
	STATE_DONETS__KA: "Donets'ka",
	STATE_IVANO_FRANKIVS__KA: "Ivano-Frankivs'ka",
	STATE_KHARKIV: 'Kharkiv',
	STATE_KHARKOV: 'Kharkov',
	STATE_KHERSONSKA: 'Khersonska',
	STATE_KHMEL__NYTS__KA: "Khmel'nyts'ka",
	STATE_KIROVOHRAD: 'Kirovohrad',
	STATE_KRYM: 'Krym',
	STATE_KYYIV: 'Kyyiv',
	STATE_KYYIVS__KA: "Kyyivs'ka",
	STATE_L__VIVS__KA: "L'vivs'ka",
	STATE_LUHANS__KA: "Luhans'ka",
	STATE_MYKOLAYIVS__KA: "Mykolayivs'ka",
	STATE_ODES__KA: "Odes'ka",
	STATE_ODESSA: 'Odessa',
	STATE_POLTAVS__KA: "Poltavs'ka",
	STATE_RIVNENS__KA: "Rivnens'ka",
	STATE_SEVASTOPOL: 'Sevastopol',
	STATE_SUMS__KA: "Sums'ka",
	STATE_TERNOPIL__S__KA: "Ternopil's'ka",
	STATE_VOLYNS__KA: "Volyns'ka",
	STATE_VYNNYTS__KA: "Vynnyts'ka",
	STATE_ZAKARPATS__KA: "Zakarpats'ka",
	STATE_ZAPORIZHIA: 'Zaporizhia',
	STATE_ZHYTOMYRS__KA: "Zhytomyrs'ka",
	STATE_ABU_ZABI: 'Abu Zabi',
	STATE_AJMAN: 'Ajman',
	STATE_DUBAI: 'Dubai',
	STATE_RAS_AL_KHAYMAH: 'Ras al-Khaymah',
	STATE_SHARJAH: 'Sharjah',
	STATE_SHARJHA: 'Sharjha',
	STATE_UMM_AL_QAYWAYN: 'Umm al Qaywayn',
	STATE_AL_FUJAYRAH: 'al-Fujayrah',
	STATE_ASH_SHARIQAH: 'ash-Shariqah',
	STATE_ABERDEEN: 'Aberdeen',
	STATE_ABERDEENSHIRE: 'Aberdeenshire',
	STATE_ARGYLL: 'Argyll',
	STATE_BEDFORDSHIRE: 'Bedfordshire',
	STATE_BELFAST: 'Belfast',
	STATE_BERKSHIRE: 'Berkshire',
	STATE_BIRMINGHAM: 'Birmingham',
	STATE_BRECHIN: 'Brechin',
	STATE_BRIDGNORTH: 'Bridgnorth',
	STATE_BRISTOL: 'Bristol',
	STATE_BUCKINGHAMSHIRE: 'Buckinghamshire',
	STATE_CAMBRIDGE: 'Cambridge',
	STATE_CAMBRIDGESHIRE: 'Cambridgeshire',
	STATE_CHANNEL_ISLANDS: 'Kanaaleilanden',
	STATE_CHESHIRE: 'Cheshire',
	STATE_CLEVELAND: 'Cleveland',
	STATE_CO_FERMANAGH: 'Co Fermanagh',
	STATE_CONWY: 'Conwy',
	STATE_CORNWALL: 'Cornwall',
	STATE_COVENTRY: 'Coventry',
	STATE_CRAVEN_ARMS: 'Craven Arms',
	STATE_CUMBRIA: 'Cumbria',
	STATE_DENBIGHSHIRE: 'Denbighshire',
	STATE_DERBY: 'Derby',
	STATE_DERBYSHIRE: 'Derbyshire',
	STATE_DEVON: 'Devon',
	STATE_DIAL_CODE_DUNGANNON: 'Dial Code Dungannon',
	STATE_DIDCOT: 'Didcot',
	STATE_DORSET: 'Dorset',
	STATE_DUNBARTONSHIRE: 'Dunbartonshire',
	STATE_DURHAM: 'Durham',
	STATE_EAST_DUNBARTONSHIRE: 'East Dunbartonshire',
	STATE_EAST_LOTHIAN: 'East Lothian',
	STATE_EAST_MIDLANDS: 'East Midlands',
	STATE_EAST_SUSSEX: 'East Sussex',
	STATE_EAST_YORKSHIRE: 'East Yorkshire',
	STATE_ENGLAND: 'England',
	STATE_ESSEX: 'Essex',
	STATE_FERMANAGH: 'Fermanagh',
	STATE_FIFE: 'Fife',
	STATE_FLINTSHIRE: 'Flintshire',
	STATE_FULHAM: 'Fulham',
	STATE_GAINSBOROUGH: 'Gainsborough',
	STATE_GLOCESTERSHIRE: 'Glocestershire',
	STATE_GWENT: 'Gwent',
	STATE_HAMPSHIRE: 'Hampshire',
	STATE_HANTS: 'Hants',
	STATE_HEREFORDSHIRE: 'Herefordshire',
	STATE_HERTFORDSHIRE: 'Hertfordshire',
	STATE_IRELAND: 'Ierland',
	STATE_ISLE_OF_MAN: 'Eialnd Man',
	STATE_ISLE_OF_WIGHT: 'Eialnd Wight',
	STATE_KENFORD: 'Kenford',
	STATE_KENT: 'Kent',
	STATE_KILMARNOCK: 'Kilmarnock',
	STATE_LANARKSHIRE: 'Lanarkshire',
	STATE_LANCASHIRE: 'Lancashire',
	STATE_LEICESTERSHIRE: 'Leicestershire',
	STATE_LINCOLNSHIRE: 'Lincolnshire',
	STATE_LLANYMYNECH: 'Llanymynech',
	STATE_LONDON: 'Londen',
	STATE_LUDLOW: 'Ludlow',
	STATE_MAYFAIR: 'Mayfair',
	STATE_MERSEYSIDE: 'Merseyside',
	STATE_MID_GLAMORGAN: 'Mid Glamorgan',
	TATE_MIDDLESEX: 'Middlesex',
	STATE_MILDENHALL: 'Mildenhall',
	STATE_MONMOUTHSHIRE: 'Monmouthshire',
	STATE_NEWTON_STEWART: 'Newton Stewart',
	STATE_NORFOLK: 'Norfolk',
	STATE_NORTH_HUMBERSIDE: 'North Humberside',
	STATE_NORTH_YORKSHIRE: 'North Yorkshire',
	STATE_NORTHAMPTONSHIRE: 'Northamptonshire',
	STATE_NORTHANTS: 'Northants',
	STATE_NORTHERN_IRELAND: 'Noord-Ireland',
	STATE_NORTHUMBERLAND: 'Northumberland',
	STATE_NOTTINGHAMSHIRE: 'Nottinghamshire',
	STATE_OXFORD: 'Oxford',
	STATE_POWYS: 'Powys',
	STATE_ROOS_SHIRE: 'Roos-shire',
	STATE_SUSSEX: 'SUSSEX',
	STATE_SCOTLAND: 'Schotland',
	STATE_SCOTTISH_BORDERS: 'Scottish Borders',
	STATE_SHROPSHIRE: 'Shropshire',
	STATE_SOMERSET: 'Somerset',
	STATE_SOUTH_GLAMORGAN: 'Zuid-Glamorgan',
	STATE_SOUTH_WALES: 'Zuid-Wales',
	STATE_SOUTH_YORKSHIRE: 'Zuid-Yorkshire',
	STATE_SOUTHWELL: 'Southwell',
	STATE_STAFFORDSHIRE: 'Staffordshire',
	STATE_STRABANE: 'Strabane',
	STATE_SUFFOLK: 'Suffolk',
	STATE_SURREY: 'Surrey',
	STATE_TWICKENHAM: 'Twickenham',
	STATE_TYNE_AND_WEAR: 'Tyne and Wear',
	STATE_TYRONE: 'Tyrone',
	STATE_UTAH: 'Utah',
	STATE_WALES: 'Wales',
	STATE_WARWICKSHIRE: 'Warwickshire',
	STATE_WEST_LOTHIAN: 'West Lothian',
	STATE_WEST_MIDLANDS: 'West Midlands',
	STATE_WEST_SUSSEX: 'West Sussex',
	STATE_WEST_YORKSHIRE: 'West Yorkshire',
	STATE_WHISSENDINE: 'Whissendine',
	STATE_WILTSHIRE: 'Wiltshire',
	STATE_WOKINGHAM: 'Wokingham',
	STATE_WORCESTERSHIRE: 'Worcestershire',
	STATE_WREXHAM: 'Wrexham',
	STATE_WURTTEMBERG: 'Wurttemberg',
	STATE_YORKSHIRE: 'Yorkshire',
	STATE_CALIFORNIA: 'Californië',
	STATE_COLORADO: 'Colorado',
	STATE_CONNECTICUT: 'Connecticut',
	STATE_DELAWARE: 'Delaware',
	STATE_DISTRICT_OF_COLUMBIA: 'District van Columbia',
	STATE_HAWAII: 'Hawaï',
	STATE_IDAHO: 'Idaho',
	STATE_ILLINOIS: 'Illinois',
	STATE_INDIANA: 'Indiana',
	STATE_IOWA: 'Iowa',
	STATE_KANSAS: 'Kansas',
	STATE_KENTUCKY: 'Kentucky',
	STATE_LOUISIANA: 'Louisiana',
	STATE_MAINE: 'Maine',
	STATE_MARYLAND: 'Maryland',
	STATE_MASSACHUSETTS: 'Massachusetts',
	STATE_MICHIGAN: 'Michigan',
	STATE_MINNESOTA: 'Minnesota',
	STATE_MISSISSIPPI: 'Mississippi',
	STATE_MISSOURI: 'Missouri',
	STATE_NEBRASKA: 'Nebraska',
	STATE_NEVADA: 'Nevada',
	STATE_NEW_HAMPSHIRE: 'New Hampshire',
	STATE_NEW_JERSEY: 'New Jersey',
	STATE_NEW_MEXICO: 'New Mexico',
	STATE_NORTH_CAROLINA: 'Noord-Carolina',
	STATE_NORTH_DAKOTA: 'Noord-Dakota',
	STATE_OHIO: 'Ohio',
	STATE_OKLAHOMA: 'Oklahoma',
	STATE_OREGON: 'Oregon',
	STATE_PENNSYLVANIA: 'Pennsylvania',
	STATE_RHODE_ISLAND: 'Rhode Island',
	STATE_SOUTH_CAROLINA: 'Zuid-Carolina',
	STATE_SOUTH_DAKOTA: 'Zuid-Dakota',
	STATE_TENNESSEE: 'Tennessee',
	STATE_TEXAS: 'Texas',
	STATE_VIRGINIA: 'Virginia',
	STATE_WEST_VIRGINIA: 'West-Virginia',
	STATE_WISCONSIN: 'Wisconsin',
	STATE_WYOMING: 'Wyoming',
	STATE_UNITED_STATES_MINOR_OUTLYING_I: 'Kleine afgelegen eilanden van de Verenigde Staten',
	STATE_ARTIGAS: 'Artigas',
	STATE_CANELONES: 'Canelones',
	STATE_CERRO_LARGO: 'Cerro Largo',
	STATE_COLONIA: 'Colonia',
	STATE_DURAZNO: 'Durazno',
	STATE_FLORES: 'Flores',
	STATE_LAVALLEJA: 'Lavalleja',
	STATE_MALDONADO: 'Maldonado',
	STATE_MONTEVIDEO: 'Montevideo',
	STATE_PAYSANDU: 'Paysandu',
	STATE_RIVERA: 'Rivera',
	STATE_ROCHA: 'Rocha',
	STATE_SALTO: 'Salto',
	STATE_SORIANO: 'Soriano',
	STATE_TACUAREMBO: 'Tacuarembo',
	STATE_TREINTA_Y_TRES: 'Treinta y Tres',
	STATE_ANDIJON: 'Andijon',
	STATE_BUHORO: 'Buhoro',
	STATE_CIZAH: 'Cizah',
	STATE_FARGONA: 'Fargona',
	STATE_HORAZM: 'Horazm',
	STATE_KASKADAR: 'Kaskadar',
	STATE_KORAKALPOGISTON: 'Korakalpogiston',
	STATE_NAMANGAN: 'Namangan',
	STATE_NAVOI: 'Navoi',
	STATE_SAMARKAND: 'Samarkand',
	STATE_SIRDARE: 'Sirdare',
	STATE_SURHONDAR: 'Surhondar',
	STATE_TOSKENT: 'Toskent',
	STATE_MALAMPA: 'Malampa',
	STATE_PENAMA: 'Penama',
	STATE_SANMA: 'Sanma',
	STATE_SHEFA: 'Shefa',
	STATE_TAFEA: 'Tafea',
	STATE_TORBA: 'Torba',
	STATE_VATICAN_CITY_STATE_HOLY_SEE_: 'Vaticaanstad (Heilige Stoel)',
	STATE_ANZOATEGUI: 'Anzoategui',
	STATE_APURE: 'Apure',
	STATE_ARAGUA: 'Aragua',
	STATE_BARINAS: 'Barinas',
	STATE_CARABOBO: 'Carabobo',
	STATE_COJEDES: 'Cojedes',
	STATE_DELTA_AMACURO: 'Delta Amacuro',
	STATE_FALCON: 'Falcon',
	STATE_GUARICO: 'Guarico',
	STATE_LARA: 'Lara',
	STATE_MERIDA: 'Merida',
	STATE_MIRANDA: 'Miranda',
	STATE_MONAGAS: 'Monagas',
	STATE_NUEVA_ESPARTA: 'Nueva Esparta',
	STATE_PORTUGUESA: 'Portuguesa',
	STATE_TACHIRA: 'Tachira',
	STATE_TRUJILLO: 'Trujillo',
	STATE_VARGAS: 'Vargas',
	STATE_YARACUY: 'Yaracuy',
	STATE_ZULIA: 'Zulia',
	STATE_BAC_GIANG: 'Bac Giang',
	STATE_BINH_DINH: 'Binh Dinh',
	STATE_BINH_DUONG: 'Binh Duong',
	STATE_DA_NANG: 'Da Nang',
	STATE_DONG_BANG_SONG_CUU_LONG: 'Dong Bang Song Cuu Long',
	STATE_DONG_BANG_SONG_HONG: 'Dong Bang Song Hong',
	STATE_DONG_NAI: 'Dong Nai',
	STATE_DONG_NAM_BO: 'Dong Nam Bo',
	STATE_DUYEN_HAI_MIEN_TRUNG: 'Duyen Hai Mien Trung',
	STATE_HANOI: 'Hanoi',
	STATE_HUNG_YEN: 'Hung Yen',
	STATE_KHU_BON_CU: 'Khu Bon Cu',
	STATE_LONG_AN: 'Long An',
	STATE_MIEN_NUI_VA_TRUNG_DU: 'Mien Nui Va Trung Du',
	STATE_THAI_NGUYEN: 'Thai Nguyen',
	STATE_THANH_PHO_HO_CHI_MINH: 'Thanh Pho Ho Chi Minh',
	STATE_THU_DO_HA_NOI: 'Thu Do Ha Noi',
	STATE_TINH_CAN_THO: 'Tinh Can Tho',
	STATE_TINH_DA_NANG: 'Tinh Da Nang',
	STATE_TINH_GIA_LAI: 'Tinh Gia Lai',
	STATE_ANEGADA: 'Anegada',
	STATE_JOST_VAN_DYKE: 'Jost van Dyke',
	STATE_TORTOLA: 'Tortola',
	STATE_SAINT_CROIX: 'Saint Croix',
	STATE_ALO: 'Alo',
	STATE_SINGAVE: 'Singave',
	STATE_WALLIS: 'Wallis',
	STATE_BU_JAYDUR: 'Bu Jaydur',
	STATE_WAD_ADH_DHAHAB: 'Wad-adh-Dhahab',
	STATE_AL___AYUN: "al-'Ayun",
	STATE_AS_SAMARAH: 'as-Samarah',
	STATE_ADAN: 'Adan',
	STATE_ABYAN: 'Abyan',
	STATE_DHAMAR: 'Dhamar',
	STATE_HADRAMAUT: 'Hadramaut',
	STATE_HAJJAH: 'Hajjah',
	STATE_HUDAYDAH: 'Hudaydah',
	STATE_IBB: 'Ibb',
	STATE_LAHIJ: 'Lahij',
	STATE_MA__RIB: "Ma'rib",
	STATE_MADINAT_SAN__A: "Madinat San'a",
	STATE_SA__DAH: "Sa'dah",
	STATE_SANA: 'Sana',
	STATE_SHABWAH: 'Shabwah',
	STATE_TA__IZZ: "Ta'izz",
	STATE_AL_BAYDA: 'al-Bayda',
	STATE_AL_HUDAYDAH: 'al-Hudaydah',
	STATE_AL_JAWF: 'al-Jawf',
	STATE_AL_MAHRAH: 'al-Mahrah',
	STATE_AL_MAHWIT: 'al-Mahwit',
	STATE_COPPERBELT: 'Copperbelt',
	STATE_LUAPALA: 'Luapala',
	STATE_LUSAKA: 'Lusaka',
	STATE_NORTH_WESTERN: 'Noordwest',
	STATE_BULAWAYO: 'Bulawayo',
	STATE_HARARE: 'Harare',
	STATE_MANICALAND: 'Manicaland',
	STATE_MASHONALAND_CENTRAL: 'Mashonaland Centraal',
	STATE_MASHONALAND_EAST: 'Mashonaland Oost',
	STATE_MASHONALAND_WEST: 'Mashonaland West',
	STATE_MASVINGO: 'Masvingo',
	STATE_MATABELELAND_NORTH: 'Matabeleland Noord',
	STATE_MATABELELAND_SOUTH: 'Matabeleland Zuid',
	STATE_MIDLANDS: 'Midlands',
	STATE_AARHUS: 'Aarhus'
};
