import onboardingLocalization from '../components/Onboarding/localization/de-DE';
import ticketingLocalization from '../components/Ticketing/localization/de-DE';
import supportLocalization from '../components/Support/localization/de-DE';
import automationLocalization from '../components/Automation/localization/de-DE';
import sharedLocalization from '../components/Shared/localization/de-DE';
import analyticsLocalization from '../components/AnalyticsInvoices/localization/de-DE';
import failoverLocalization from '../components/Failover/localization/de-DE';
import reportsLocalization from '../components/Reports/localization/de-DE';
import eulaLocalization from '../components/Eula/localization/de-DE';
import accountOverviewLocalization from '../components/AccountOverview/localization/de-DE';
import analyticsChartsLocalization from '../components/Analytics/localization/de-DE';
import batchHistoryLocalization from '../components/BatchHistory/localization/de-DE';
import errorLocalization from '../components/ErrorPage/localization/de-DE';
import userFeedbackLocalization from '../components/UserFeedback/localization/de-DE';
import swaggerLocalization from '../components/Swagger/localization/de-DE';
import securityFeatures from '../components/SecurityFeatures/localization/de-DE';
import releaseNotesLocalization from '../components/ReleaseNotes/localization/de-DE';
import endpointsLocalization from '../components/Endpoint/localization/de-DE';
import singleEndpointLocalization from '../components/SingleEndpoint/localization/de-DE';

export default {
	...automationLocalization,
	...sharedLocalization,
	...analyticsLocalization,
	...supportLocalization,
	...onboardingLocalization,
	...ticketingLocalization,
	...failoverLocalization,
	...reportsLocalization,
	...accountOverviewLocalization,
	...eulaLocalization,
	...analyticsChartsLocalization,
	...batchHistoryLocalization,
	...errorLocalization,
	...userFeedbackLocalization,
	...swaggerLocalization,
	...securityFeatures,
	...endpointsLocalization,
	...releaseNotesLocalization,
	...singleEndpointLocalization,
	// GLOBALE EINSTELLUNGEN
	DISPLAY_DATE_FORMAT: 'MM/TT/JJJJ',

	// SEITENLEISTE
	HOME: 'Home',
	ENDPOINTS: 'Endpunkte',
	Endpoints: 'Endpunkte',
	REPORTS: 'Berichte',
	INVOICES: 'Rechnungen',
	AUTOMATION: 'Automatisierung',
	PLATFORM_STATUS: 'Plattformstatus',
	SUPPORT: 'Support',
	COLLAPSE_SIDEBAR: 'Seitenleiste ausblenden',
	COMPANIES: 'Unternehmen',
	FAQ: 'Häufig gestellte Fragen (FAQ)',
	HELP_SUPPORT: 'Hilfe und Support',
	BATCH_UPDATES: 'Batch-Aktualisierungen',
	FAQS: 'FAQ',
	RELEASE_NOTES: 'Versionshinweise',
	SYSTEM_NOTIFICATIONS: 'Systembenachrichtigung',
	ACCOUNT_SETTINGS: 'Kontoeinstellungen',
	COMPANY_ACCOUNTS: 'Unternehmenskonto',
	CUSTOM_FIELDS: 'Benutzerdefinierte Felder',
	COMPANY_DETAILS: 'Unternehmensdetails',
	TESTING_ICON: 'Testsymbol',
	ENTITIES: 'Entitäten',
	IOTGW_FAILOVER: 'IoTGW Failover',
	ACCESS_MANAGEMENT: 'Zugriffsverwaltung',
	SIM_ORDERS: 'SIM-Bestellungen',
	USER_MANAGEMENT: 'Benutzerverwaltung',

	// KOPFLEISTE
	MORE_IOT: 'MEHR IoT',
	MORE_ATT: 'MEHR AT&T',
	LEARN_ABOUT_OFFERS: 'Informationen über Angebote',
	BUY_DEVICES: 'Geräte kaufen',
	ATT_SERVICES: 'AT&T-Services',
	USE_TOOLS_APPS: 'Tools und Apps verwenden',
	IOT_PLATFORMS_PRODUCTS_APPS_AND_APIS: 'IoT-Plattformen, Produkte, Apps und APIs',
	AMOC: 'AMOC',
	M2X: 'M2X',
	FLOW_DESIGNER: 'Flow Designer',
	IOT_STARTER_KITS: 'IoT-Starter-Kits',
	IOT_DATA_PLANS: 'IoT-Datenpläne',
	ENTERPRISE: 'Enterprise',
	BUSINESS: 'Business',
	CONSUMER: 'Verbraucher',

	// PROFILMENÜ
	PROFILE: 'Profil',
	APPS: 'Apps',
	LOGOUT: 'Abmelden',
	LOG_IN: 'Anmelden',

	// FUSSZEILE
	IOT_CUSTOMER_SOLUTIONS: 'IoT-Kundenlösungen',
	IOT_PLATFORM_AND_SERVICES: 'IoT-Plattform und Dienste',
	NETWORK_SOLUTIONS: 'Netzwerklösungen',
	PARTNERSHIP_PROGRAM: 'Partnerprogramm',
	BUSINESS_SUPPORT: 'Geschäftsunterstützung',
	ABOUT_ATT: 'Über AT&T',
	ABOUT_ATT_IOT: 'Über AT&T IoT',
	NEWS: 'Nachrichten',
	NETWORKING_EXCHANGE_BLOG: 'Networking-Austausch-Blog',
	BUSINESS_COMMUNITY: 'Geschäfts-Community',
	YOUTUBE_CHANNEL: 'YouTube-Channel',
	LEGAL_PRIVACY_ADVERTISING_ACCESSIBILITY: 'Juristisches, Datenschutz, Werbung, Barrierefreiheit',
	LEGAL_POLICY_CENTER: 'Juristische Richtlinienzentrale',
	PRIVACY_POLICY: 'Datenschutzrichtlinie',
	TERMS_OF_USE: 'Nutzungsbedingungen',
	BROADBAND_DETAILS: 'Breitbanddetails',
	YOUR_CHOICES: 'Ihre Optionen',
	ADVERTISING_CHOICES: 'Werbeoptionen',
	ACCESSIBILITY: 'Barrierefreiheit',
	COPYRIGHT_ATT_INTELECTUAL_PROPERTY: '©{year} AT&T Intellectual Property. ',
	COPYRIGHT_MESSAGE: 'Alle Rechte vorbehalten. AT&T, das Globus-Logo, Mobilizing Your World und DIRECTV sind Marken von AT&T und/oder verbundener Unternehmen. Alle anderen Marken sind Eigentum der jeweiligen Firmen.',
	CONNECT_WITH_ATT: 'Mit AT&T verbinden',
	CURRENT_SESSION_USAGE: 'Aktuelle Sitzungsnutzung',
	ENDPOINT_NETWORK_AND_LOCATION: 'Endpunktnetzwerk und -standort',

	// NETZWERKANSPRÜCHE
	ENDPOINT_DETAILS_SETUP: 'Einrichtung der Endpunktdetails',
	NETWORK_ENTITLEMENT_DETAILS: 'Netzwerkanspruchsdetails',
	CHANGE_NETWORK_ENTITLEMENTS: 'Netzwerkansprüche ändern',
	CONFIRM_NETWORK_ENTITLEMENT_CHANGE: 'Netzwerkanspruchsänderung bestätigen',
	NETWORK_ENTITLEMENT_CHANGE_STATUS: 'Netzwerkanspruchsänderungs-Status',
	NETWORK_ENTITLEMENT_CHANGE_TOOLTIP_SUPPORT: 'Fehler bei Netzwerkanspruch, bitte Kundensupport kontaktieren.',
	NETWORK_ENTITLEMENT_CHANGE_NOT_ALLOWED: 'Leider ist die Änderung der Ansprüche mehrerer Endpunkte gleichzeitig für Endpunkte von verschiedenen Netzwerktypen oder Dienstanbietern nicht gestattet.',
	SCHEDULE_NETWORK_ENTITLEMENT_SUCCESSFUL: 'Netzwerkanspruch für mehrere SIMs erfolgreich eingeplant',
	SIMS_NETWORK_ENTITLEMENT_SUCCESSFULLY_UPDATED: 'SIM-Netzwerkanspruchsänderung mit Erfolg aktualisiert',
	SIM_NETWORK_ENTITLEMENT_CHANGE_FOR: '‚SIM-Netzwerkanspruchsänderung für',
	MULTIPLE_NETWORK_ENTITLEMENT_CHANGE_SCHEDULE_ERROR: 'Mehrfachänderung für SIM-Netzwerkanspruch konnte nicht erfolgreich eingeplant werden',
	MULTIPLE_NETWORK_ENTITLEMENT_CHANGE_ERROR: 'Mehrfachänderung für SIM-Netzwerkanspruch konnte nicht mit Erfolg angefordert werden',
	states: 'Status',
	networkOperators: 'Netzbetreiber',
	countries: 'Netzland',
	SELECT_NETWORK_ENTITLEMENT: 'Netzwerkanspruch auswählen',
	ACCOUNT_NAME: 'Kontoname',
	APN: 'APN',
	DESCRIPTION: 'Beschreibung',
	ROAMING: 'Roaming',
	CREATE_DATE: 'Erstellungsdatum',
	LAST_UPDATE_DATE: 'Datum der letzten Aktualisierung',
	APN_LTE: 'APN LTE',
	SMS_MT: 'SMS MT',
	SMS_MO: 'SMS MO',
	VOICE_MO: 'Voice MO',
	VOICE_MT: 'Voice MT',
	INTERNATIONAL_VOICE: 'Voice international',
	FIXED_IP_ENABLED: 'Feste IP aktiviert',
	LTE_DATA: 'LTE Daten',
	LTE_ROAMING_RESTRICT_SET_ID: 'Roaming LTE',
	LTE_APNS: 'LTE-APNs',
	CAMEL_SUBSCRIPTION_INFO: 'Camel',
	CAMEL_SERVICE_TYPE: 'Camel-Servicetyp',
	M2M_PLATFORM: 'M2M-Plattform',
	APNS_ID: 'APN-ID',
	APNS_NAME: 'Name',
	APNS_LTE: 'LTE',
	APNS_PDPINDEX: 'PDP-Index',
	APNS_DESCRIPTION: 'APN-Beschreibung',
	APNS_FIXEDIP: 'Feste IP',
	APNS_SECURITY: 'Sicherheit',
	APNS_SUBSCRIPTION: 'Abonnement',
	APNS_CREATEDATE: 'Erstellungsdatum',
	APNS_LASTUPDATEDATE: 'Datum der letzten Aktualisierung',
	APNS_LASTUSER: 'Letzter Benutzer',
	APNS_APNTYPE: 'APN-Typ',
	APNS_CREATIONTIMESTAMP: 'Erstellung Zeitstempel',
	APNS_BMSYNCSTATUS: 'BM-Synchronisationsstatus',
	APNS_HLRPROFILEREFERENCE: 'HLRHSSDetails Profilereferenz',
	APNS_IPADDRESSALLOCATIONTYPE: 'IP-Adresse Zuweisungstyp',
	APNS_ISLOCALBREAKOUTENABLEDINDICATOR: 'Indikator für aktivierten lokalen Breakout',
	APNS_LASTUPDATETIMESTAMP: 'Zeitstempel letzte Aktualisierung',
	APNS_OUCCONNECTIONTYPE: 'OUC-Verbindungstyp',
	APNS_SECURITYPROFILESUPPORTEDINDICATOR: 'Indikator für unterstütztes Sicherheitsprofil',
	APNS_STATUS: 'Status',
	APNS_VPNENABLEDINDICATOR: 'Indikator aktiviertes VPN',
	CURRENTLY_ASSIGNED_TO_THIS_ENDPOINT: 'Aktuell diesem Endpunkt zugewiesen',
	CURRENTLY_ASSIGNED_TO_SELECTED_ENDPOINTS: 'Aktuell ausgewählten Endpunkten zugewiesen',
	ELIGIBLE_TO_USE_WITH_SELECTED_ENDPOINTS: 'Für die Verwendung mit ausgewählten Endpunkten qualifiziert',
	ELIGIBLE_TO_USE_WITH_THIS_ENDPOINT: 'Für die Verwendung mit diesem Endpunkt qualifiziert',
	ELIGIBLE_NETWORK_ENTITLEMENTS: 'Qualifizierte Netzwerkansprüche',
	NETWORK_ENTITLEMENT_CHANGE_IS_SUCCESSFULLY_SUBMITTED: 'Netzwerkanspruchsänderung erfolgreich eingereicht.',
	NETWORK_ENTITLEMENT_CHANGE_COULD_NOT_BE_CARRIED_OUT_SUCCESSFULLY: 'Netzwerkanspruchsänderung konnte derzeit nicht ausgeführt werden.',
	NETWORK_ENTITLEMENT_CHANGE_IS_SUCCESSFULLY_SCHEDULED: 'Netzwerkanspruchsänderung erfolgreich eingeplant.',
	NETWORK_ENTITLEMENT_CHANGE_IS_SUCCESSFULLY_SCHEDULED_FOR: 'Netzwerkanspruchsänderung erfolgreich eingeplant für ',
	NETWORK_ENTITLEMENT_CHANGE_COULD_fNOT_BE_SCHEDULED_SUCCESSFULLY: 'Netzwerkanspruchsänderung konnte derzeit nicht eingeplant werden.',
	ONCE_YOU_INITIATE_THIS_CHANGE: 'Wenn Sie diese Änderung einleiten, lautet der Netzwerkanspruch des ausgewählten Endpunkts ',
	WOULD_YOU_LIKE_TO_PROCEED_WITH_THIS_ACTION: 'Mit dieser Aktion fortfahren?',
	OR_YOU_CAN_SCHEDULE_THE_CHANGE_FOR: 'Sie können die Änderung auch planen für: ',
	FRIENDLY_NAME: 'Anzeigename',
	NAME: 'Name',
	// Netzwerkanspruchsdetails
	APN_DETAILS_ID: 'ID',
	APN_DETAILS_NAME: 'Name',
	APN_DETAILS_LTE: 'LTE',
	APN_DETAILS_PDPINDEX: 'PDP-Index',
	APN_DETAILS_DESCRIPTION: 'Beschreibung',
	APN_DETAILS_FIXEDIP: 'Feste IP',
	APN_DETAILS_SECURITY: 'Sicherheit',
	APN_DETAILS_SUBSCRIPTION: 'Abonnement',

	// SCHALTFLÄCHENBEZEICHNUNGEN
	CANCEL: 'Abbrechen',
	CHANGE: 'Ändern',
	TROUBLESHOOT: 'Fehlersuche',
	CHANGE_STATUS: 'Status ändern',
	CLOSE: 'Schließen',
	APPLY_CHANGE: 'Änderung übernehmen',
	DONE: 'Fertig',
	SAVE: 'Speichern',
	CONTINUE: 'Weiter',
	SAVEDASHBOARD: 'Dashboard speichern',
	SAVE_AND_CONTINUE: 'Speichern und fortfahren',
	SAVE_CHANGES: 'Änderungen speichern',
	PULL_DATA: 'Daten abrufen',
	I_DO_NOT_AGREE: 'Stimme nicht zu',
	I_AGREE: 'Stimme zu',
	LOADING: 'Ladevorgang',
	UPLOAD_LOGO: 'Logo hochladen',
	ADD_NEW_CUSTOMER: 'Neuen Kunden hinzufügen',
	EDIT: 'Bearbeiten',
	DELETE: 'Löschen',
	NEXT: 'Weiter',

	// BENACHRICHTIGUNGEN
	ENDPOINT_ID: 'Endpunkt-ID ',
	PROVISIONING_ERROR: 'Bereitstellungsfehler',
	FAILED_TO_PROVISION_AFTER_IMSI_STATUS_CHANGE: ' Bereitstellung nach IMSI-Statusänderung fehlgeschlagen. ',
	GET_SUPPORT: 'Support erhalten',
	PROVISIONING_SUCCESS: 'Bereitstellungserfolg',
	IS_SUCCESSFULLY_PROVISIONED: ' ist erfolgreich bereitgestellt. ',
	IMSI_STATUS_IS_CHANGED_TO: 'IMSI-Status geändert zu ',
	PENDING_STATUS_CHANGE: 'Änderung ausstehend!',
	STATUS_CHANGE_FOR: 'Für diesen Endpunkt ist eine geplante Aktivität vorhanden',
	IS_PENDING: ' ist ausstehend',
	CUSTOM_FIELD_EDIT_SUCCESS: 'Benutzerdefiniertes Feld erfolgreich bearbeitet',
	CUSTOM_FIELD_EDIT_ERROR: 'Fehler bei Bearbeitung des benutzerdefinierten Feldes',
	CUSTOM_FIELD_VALUES_UPDATED: 'Ein oder mehrere benutzerdefinierte Felder wurden erfolgreich aktualisiert',
	CUSTOM_FIELD_VALUES_UPDATED_ERROR: 'Ein oder mehrere benutzerdefinierte Felder konnten derzeit nicht aktualisiert werden. Bitte versuchen Sie es noch einmal.',
	CUSTOM_FIELD_LABEL_SUCCESS_TITLE: 'Benutzerdefiniertes Feld erfolgreich bearbeitet!',
	CUSTOM_FIELD_LABEL_SUCCESS_MESSAGE: 'Ein oder mehrere benutzerdefinierte Feldbezeichnungen wurden erfolgreich aktualisiert',
	CUSTOM_FIELD_LABEL_ERROR_TITLE: 'Fehler bei Bearbeitung einer benutzerdefinierten Feldbezeichnung!',
	CUSTOM_FIELD_LABEL_ERROR_MESSAGE: 'Ein oder mehrere benutzerdefinierte Feldbezeichnungen konnten derzeit nicht aktualisiert werden. Bitte versuchen Sie es noch einmal.',
	TABLE_COLUMNS_CHANGE_SUCCESS_TITLE: 'Einstellungen der Endpunkt-Tabelle erfolgreich geändert!',
	TABLE_COLUMNS_CHANGE_SUCCESS_MESSAGE: 'Einstellungen der Endpunkt-Tabelle wurden erfolgreich aktualisiert.',
	TABLE_COLUMNS_CHANGE_ERROR_TITLE: 'Fehler beim Ändern von Endpunkt-Tabellenspalten!',
	TABLE_COLUMNS_CHANGE_ERROR_MESSAGE: 'Endpunkt-Tabellenspalten konnten zu diesem Zeitpunkt nicht aktualisiert werden. Bitte versuchen Sie es noch einmal.',
	MC10515: 'Falscher Dateiname',
	MC10516: 'Dateidatenfehler',
	MC10517: 'Datei enthält keine Kopfzeile oder ist leer',
	DOWNLOAD_REPORT_ERROR_TITLE: 'Fehler beim Herunterladen',
	DOWNLOAD_REPORT_ERROR_MESSAGE: 'Fehler beim Versuch, Ihre Datei herunterzuladen',
	FETCH_REPORT_ERROR_TITLE: 'Keine Daten',
	FETCH_REPORT_ERROR_MESSAGE: 'Es sind keine Daten vorhanden, die der Such-/Filteraufforderung entsprechen',
	MARK_READ: 'Als gelesen markieren',
	VALIDATION_FAILED: 'Validierung fehlgeschlagen',
	ACTION_COMPLETED_SUCCESSFULLY: 'Aktion erfolgreich abgeschlossen',
	ACTION_COULD_NOT_BE_COMPLETED: 'Diese Aktion konnte nicht abgeschlossen werden',

	// EINZELNER ENDPUNKT
	ENDPOINT_DETAILS: 'Endpunkt-Details',
	DETAILS: 'Details',
	DIAGNOSTIC: 'Diagnostik',
	CHANGES: 'Änderungen',
	SESSIONS: 'Sitzungen',
	END_USER: 'Endbenutzer',
	LIFE_CYCLE: 'Lebenszyklus',
	SEND_SMS: 'SMS senden',
	OTA: 'OTA',
	RATING: 'Bewertung',
	CARRIER_INFORMATION: 'Betreiberinformationen',
	M2M_PLATFORM_STATUS_DESCRIPTION: 'M2M-Plattform-Statusbeschreibung',
	VIEW_NOTE: 'Anmerkung anzeigen',
	SE_NOTES: 'Anmerkungen',
	CHANGE_NOTES: 'Anmerkungen ändern',
	ENTER_NOTE_HERE: 'Anmerkung hier eingeben (max. 2000 Zeichen)',
	ADD_NOTE_BELOW: 'Anmerkung nachstehend hinzufügen: ',
	CHANGING_NOTES: 'Anmerkungen werden geändert',
	MULTIPLE_NOTES_CHANGE_ERROR: 'Änderung von Anmerkungen für mehrere Endpunkte konnte nicht erfolgreich eingeleitet werden',
	CHANGING_NOTES_NOT_ALLOWED: 'Änderung von Anmerkungen nicht zulässig',
	ENDPOINTS_SUCCESSFULLY_CHANGED: 'Endpunkte erfolgreich geändert',
	ENDPOINT_DETAILS_SECOND_CARD_NO_DATA: 'Die Daten für diesen Endpunkt sind kurzzeitig nicht verfügbar',

	// DATEN ÜBERSICHT
	ICCID: 'ICCID',
	IMSI: 'IMSI',
	NETWORK_OPERATOR: 'Netzbetreiber',
	NETWORK_COUNTRY: 'Netzland',
	MSISDN: 'MSISDN',
	DUAL_MODE: 'Dualmodus',
	CONNECTED_DEVICE: 'Verbundenes Gerät',
	IMEI: 'IMEI',
	STATUS: 'Status',
	STATUS_CHANGE_PENDING: 'Für diesen Endpunkt ist eine geplante Aktivität vorhanden',
	RATE_PLAN: 'Tarifplan',
	NETWORK_ENTITLEMENT: 'Netzwerkanspruch',
	SOURCE_PLATFORM: 'M2M-Plattform',
	SERVICE_PROVIDER: 'M2M-Plattform',
	NETWORK_ENTITLEMENT_ID: 'Netzwerkanspruchs-ID',

	// ENDPUNKTSTATUS
	CURRENT_ENDPOINT_STATUS: 'Aktueller Endpunktstatus',

	// SIM-Parameter ändern modal
	CHANGE_SIM_PARAMETER: 'SIM-Parameter ändern',
	SELECT_ATT_STATE: 'AT&T STATUS AUSWÄHLEN',
	CURRENT: 'AKTUELL',

	BY: 'Von ',
	SCHEDULING: 'Planung ...',

	// TABELLEN BEARBEITEN MODAL
	EDIT_TABLE_PARAMETERS: 'Tabellenparameter bearbeiten',
	EDIT_CUSTOM_FIELD_LABELS: 'Benutzerdefinierte Feldbezeichnungen bearbeiten',
	CUSTOM_LABEL: 'Benutzerdefinierte Bezeichnung {labelIndex}',
	VALUE: 'Wert',
	SET_AS_REQUIRED: 'Wie erforderlich festlegen',
	SELECT_A_MINIMUM_OF_4_TO_APPLY_TO_THE_TABLE: 'Mindestens 2 auswählen und auf Tabelle anwenden. ',
	SELECTED: ' ausgewählt',
	CLEAR_ALL: 'Auf Voreinstellung zurücksetzen',

	EOD: 'Enterprise On Demand (EOD)',
	ACC: 'AT&T Control Center (ACC)',
	GBCM: 'Speedcast',
	MC: 'IoT Console (IOT-C)',
	DCP: 'Geräteverbindungsplattform (DCP)',
	CCIP: 'Control Center Integration Program (CCIP)',
	AIS: 'AIS',
	TWM: 'Taiwan Mobile (TWM)',
	ATT: 'AT&T',
	AUTOMATION_ACC: 'ACC',
	AUTOMATION_DCP: 'DCP',
	// AT&T: 'AT&T',
	WING: 'Nokia (WING)',
	VIVO: 'Vivo Control Center',

	eod: 'Enterprise On Demand (EOD)',
	acc: 'AT&T Control Center (ACC)',
	gbcm: 'Speedcast',
	mc: 'IoT Console (IOT-C)',
	dcp: 'Geräteverbindungsplattform (DCP)',
	ccip: 'Control Center Integration Program (CCIP)',
	ais: 'AIS',
	twm: 'Taiwan Mobile (TWM)',
	att: 'AT&T',
	// at&t: 'AT&T',
	wing: 'Nokia (WING)',
	vivo: 'Vivo Control Center',

	SELECTED_FILTERS: 'Ausgewählte Filter',

	BY_WEEK: 'Nach Woche',
	BY_DAY: 'Nach Tag',
	BY_HOUR: 'Nach Stunde',
	BYTES_SENT: 'Gesendete MB',
	BYTES_RECEIVED: 'Empfangene MB',
	TOTAL_MINS: 'Minuten insgesamt',
	monthly: 'Monatlich',
	daily: 'Täglich',
	MONTHLY: 'Monatlich',
	DAILY: 'Täglich',

	// AKTIONSLEISTE
	SHOWING: 'Anzeige ',
	OF: ' von ',

	// BERICHTE
	TRANSFER: 'Übertragung',
	USAGE: 'Nutzung',
	DOWNLOAD: 'Herunterladen',
	OTHER: 'Sonstiges',

	// EINGABEFEHLER
	REQUIRED: 'Erforderlich',
	INVALID_COMPANY_NAME: 'Ungültiger Unternehmensname',
	INVALID_EMAIL_ADDRESS: 'Ungültige E-Mail-Adresse',
	PHONE_MUST_BE_A_NUMBER: 'Telefon muss eine Nummer sein',
	WRONG_PHONE_FORMAT: 'Falsches Telefonformat',
	ONLY_LETTERS_AND_NUMBERS_ARE_ALLOWED: 'Nur Buchstaben und Ziffern zulässig',
	ONLY_LETTERS_ARE_ALLOWED: 'Nur Buchstaben zulässig',
	ONLY_LETTERS_AND_SPACES_ARE_ALLOWED: 'Nur Buchstaben und Leerzeichen zulässig',
	ONLY_NUMBERS_ARE_ALLOWED: 'Nur Ziffern zulässig',
	ONLY_POSITIVE_NUMBERS_ARE_ALLOWED: 'Nur Ziffern über 0 zulässig',
	NUMBER_HAS_TO_BE_LESS_THAN: 'Nummer muss kleiner sein als {propName}',
	NUMBER_HAS_TO_BE_LESS_THAN_OR_EQUAL_TO: 'Nummer muss kleiner/gleich {propName} sein',
	NUMBER_HAS_TO_BE_GREATER_THAN: 'Nummer muss größer sein als {propName}',
	NUMBER_HAS_TO_BE_GREATER_THAN_OR_EQUAL_TO: 'Nummer muss größer/gleich {propName} sein',
	NUMBER_HAS_TO_BE_IN_INCREMENTAL_STEPS: 'Nummer muss in Inkrementschritten von {increment} sein',
	INVALID_FORMAT: 'Ungültiges Format',
	MAX_CHARACTERS_EXCEEDED: 'Zeichenlimit überschritten',
	MUST_BE_LESS_THAN_OR_EQUAL_TO_CHARACTERS: 'Muss kleiner/gleich {max} Zeichen sein',
	MUST_BE_CHARACTERS_LONG: 'Muss {number} Zeichen lang sein',
	NO_ONLY_WHITE_SPACE: 'Es können nicht nur Whitespaces eingegeben werden',
	OUT_OF_RANGE: 'Außerhalb des Bereichs',
	NOT_BEFORE: 'Datum darf nicht vor {date} liegen',
	INVALID_VERSION: 'Nur Format major.minor.patch erlaubt, d.h. x.y.z',

	/* ------ ALT ABER NOCH FUNKTIONSTÜCHTIG - NICHT ÄNDERN - WIRD BEARBEITET --------- */
	ACCOUNT_OVERVIEW: 'Kontoübersicht',
	ACCOUNT_OVERVIEW_ADMIN_DASHBOARD: 'Kontoübersicht Admin-Dashboard',
	BILLING: 'Abrechnung',

	LOG_OUT: 'Abmelden',

	DATA_PLAN: 'Datenplan',
	ACTIVESESSION: 'AKTIVE SITZUNG',
	IPADDRESS: 'IP-ADRESSE',
	ANALYTICS: 'Analysen',
	NETWORK: 'NETZWERK',

	ASSET_ID: 'Asset-ID',
	SESSION_START: 'Sitzungsbeginn',
	DATA_VOLUME: 'Datenvolumen',
	DURATION: 'Dauer (Min)',
	'DATE&TIME': 'Datum und Uhrzeit',
	NOTE: 'Anmerkung',
	LOGINTITLE: 'Anmelden',
	LOGINSUBTITLE: 'Bitte geben Sie Ihre E-Mail-Adresse und Ihr Passwort ein.',
	ENDPOINTS_INVENTORY_STATUS: 'ENDPUNKTINVENTAR-STATUS',
	INVENTORY_BY_CARRIER: 'INVENTAR NACH BETREIBER',
	INVENTORY_BY_COUNTRY: 'INVENTAR NACH LAND',
	ACCOUNT_USERS: 'KONTOBENUTZER',
	USER_AUTOMATION_STATUS: 'BENUTZERAUTOMATISIERUNGSSTATUS',
	AUTOMATION_PROCESS_STATUS: 'AUTOMATISIERUNGSPROZESSSTATUS',
	NETWORK_TYPE: 'Netzwerktyp',
	DEVICE_NAME: 'Gerätename',
	DEVICE_ID: 'Geräte-ID',
	DEVICE_MODEL_NUMBER: 'Gerätemodellnr.',
	ROAMING_RESTRICTED: 'ROAMING EINGESCHRÄNKT',
	INVOICE_NUMBER: 'RECHNUNGSNUMMER',
	DATE: 'Datum',
	SCHEDULE_DATE: 'Datum',
	ISSUED_BY: 'AUSGEGEBEN VON',
	PAYMENT_STATUS: 'ZAHLUNGSSTATUS',
	REPORT_NAME: 'Berichtname',
	DATE_CREATED: 'Erstellungsdatum',
	CREATED_BY: 'Erstellt von',
	PAY_NOW: 'JETZT ZAHLEN',
	EDIT_GRAPHS_VIEW: 'Diagrammansicht bearbeiten',
	EDIT_ENDPOINTS_LIST_VIEW: 'Endpunkt-Listenansicht bearbeiten',
	CHANGE_LANGUAGE: 'Sprache ändern',
	CHANGE_LANGUAGE_ICON: 'DE',
	NOT_APPLICABLE_FOR_THIS_ENDPOINT: 'Für diesen Endpunkt nicht zutreffend',
	CREATE_NEW_RULE: 'Neue Regel erstellen',
	EDIT_RULE: 'Regel bearbeiten',
	CREATE_NEW_RULE_EXP: 'Anhand der Automatisierung können Sie an Ihrem Konto Massenaktionen durchführen.',
	CREATE_NEW_RULE_ACTION: 'Wählen Sie die Schaltfläche „Neue Regel erstellen“, um eine neue automatisierte Aktion zu erstellen',
	RULE_NAME: 'Regelname',
	CATEGORY: 'Kategorie',
	TRIGGER: 'Auslöser',
	ACTION: 'AKTION',
	BULK_ACTIONS: 'Sammelaktionen',
	MULTI_ENDPOINT_ACTIONS: 'Multi-Endpunkt-Aktionen',
	SET_ENDPOINT_STATE: 'Endpunktstatus festlegen',
	CHANGE_DATA_PLAN: 'Datenplan ändern',
	SWAP_OPERATOR: 'Betreiber wechseln',
	MULTI_EDIT: 'Mehrfachbearbeitung',
	SEARCH_FOR_RULES: 'Nach Regeln suchen',
	SEARCH_FOR_INVOICES: 'Nach Rechnungen suchen',
	SEARCH_FOR_REPORTS: 'Bericht nach Namen suchen',
	SEARCH_ENDPOINTS: 'Endpunkte durchsuchen',
	PAYABLE_INVOICES: 'FÄLLIGE RECHNUNGEN',
	RECEIVED_PAYMENTS: 'ERHALTENE ZAHLUNGEN',
	PAYMENT_METHODS: 'ZAHLUNGSMETHODEN',
	BILLERS: 'RECHNUNGSSTELLER',
	TIME_PERIOD: 'Zeitraum',
	FROM_DATEPICKER: 'Von',
	TO_DATEPICKER: 'Bis',
	ENDPOINT_UI_TOOLTIP: 'ID-Nummer für Endpunkt oder Gerät',
	OPERATOR_TOOLTIP: 'Betreiber, zu dem Endpunkt und Profil gehören',
	NETWORK_TYPE_TOOLTIP: 'Betreiber Konnektivitätstechnologie',
	COUNTRY_TOOLTIP: 'Endpunkt-Ursprungsland',
	ROAMING_RESTRICTED_TOOLTIP: 'Wenn Endpunkt für Roaming eingeschränkt ist',
	STATUS_TOOLTIP: 'Aktueller Endpunktstatus',
	INITIATE_RULE: 'REGEL EINLEITEN',
	NEW_AUTOMATION_RULE: 'NEUE AUTOMATISIERUNGSREGEL',
	ENDPOINT_PROVISIONING: 'ENDPUNKTBEREITSTELLUNG',
	ENDPOINT_PROFILE: 'ENDPUNKTPROFIL',
	ENDPOINT_SECURITY: 'ENDPUNKTSICHERHEIT',
	ENDPOINT_SUBSCRIPTION: 'ENDPUNKTABONNEMENT',
	NEW_RULE: 'NEUE REGEL',
	CONDITION: 'BEDINGUNG',
	ENDPOINT_RANGE: 'Endpunktbereich ist',
	RANGE_START: 'BEREICHSSTART',
	RANGE_END: 'BEREICHSENDE',
	ASSIGN_ENDPOINT: 'Endpunkt Betreiber zuweisen',
	OPERATOR_NAME: 'BEDIENERNAME',
	ACCOUNT: 'KONTO',
	ADD_ACTION: 'AKTION HINZUFÜGEN',
	RUN_IMMEDIATELY: 'SOFORT AUSFÜHREN',
	TIME: 'UHRZEIT',
	SELECT_DATE: 'DATUM AUSWÄHLEN',
	SELECT_TIME: 'UHRZEIT AUSWÄHLEN',
	EVENT_TRIGGER: 'EREIGNISAUSLÖSER',
	EVENT_TYPE: 'EREIGNISTYP',
	EVENT_LOCATION: 'EREIGNISORT',
	DATA_PLAN_NAME: 'DATENPLAN-NAME',
	LOGIN_VALIDATION_WRONG_CREDENTIALS_HEADER: 'Falsche Anmeldedaten!',
	LOGIN_VALIDATION_WRONG_CREDENTIALS_BODY: 'Passwort entspricht nicht der angegebenen E-Mail.',
	EMAIL: 'E-Mail',
	PASSWORD: 'Passwort',
	FORGOT_PASSWORD: 'Passwort vergessen?',
	REMEMBER_ME: 'Meine Daten speichern',
	LOGIN_BUTTON: 'ANMELDEN',
	BACK: 'Zurück',
	ENDPOINT_USAGE: 'Endpunktnutzung',
	COUNTRY_AND_OPERATOR: 'Land und Betreiber',
	PAYMENTS_HISTORY: 'Zahlungsverlauf',
	SPENDING_THIS_YEAR: 'Ausgaben dieses Jahr',
	ENDPOINT_DATA: 'ENDPUNKTDATEN',
	CURRENT_SESSION: 'AKTUELLE SITZUNG',
	VIEW_LESS: 'Weniger anzeigen',
	VIEW_MORE: 'Mehr anzeigen',
	ENDPOINT_STATUS: 'Endpunktstatus',
	ACTIVATION_DATE: 'Aktivierungsdatum',
	PRIMARY_IMS: 'Primär-IMS',

	SERVICE_PROVIDER_MODE: 'DIENSTANBIETER-MODUS',
	SUPER_USER_MODE_MESSAGE: '{mode}: {companyFriendlyName}.',
	SUPER_USER_MODE_MOBILE: '{companyFriendlyName}',
	POLICY: 'Richtlinie',
	COMMUNICATION_PLAN: 'Kommunikationsplan',
	COPY_RULE: 'Regel kopieren',
	SECURE_ENDPOINT_ID: 'Sichere Endpunkt-ID',
	CUSTOMER: 'Kunde',
	INVENTORY_STATUS: 'INVENTAR-STATUS',
	RESOLUTION_NOT_SUPPORTED_FIRST_LINE: 'Kleine Anzeige ist in der Demo deaktiviert.',
	RESOLUTION_NOT_SUPPORTED_SECOND_LINE: 'Für optimale Ergebnisse sollte Chrome auf dem Desktop maximiert oder im Vollbildschirmmodus verwendet werden.',
	CHECKING_MOVE_ELIGIBILITY: 'ÜBERTRAGBARKEIT WIRD GEPRÜFT',
	CHANGE_OPERATOR: 'BETREIBER ÄNDERN',
	SCHEDULE_CHANGE: 'ÄNDERUNG PLANEN',
	SCHEDULE_CHANGE_BUTTON: 'Änderung planen',
	INITIATE_CHANGE: 'Änderung einleiten',
	CONFIRM_CHANGE: 'Änderung bestätigen',
	CONFIRM_SIM_STATE_CHANGE: 'SIM-Statusänderung bestätigen',
	IN_SESSION: 'AKTIV',
	ENTITLEMENTS: 'Ansprüche',
	LOCALIZE_SERVICE: 'Service lokalisieren',
	CHANGE_ENDPOINT_OPERATOR: 'ENDPUNKTBETREIBER ÄNDERN',
	PENDING_CHANGES_ENDPOINT: 'Es stehen Änderungen für diesen Endpunkt aus, mit Beginn am ',
	SEE_HISTORY_FOR_DETAILS: 'Details siehe nachfolgende Angaben.',
	CHANGE_ENDPOINT_DATA_PLAN: 'Endpunkt-Datenplan ändern',
	IS_SELECTED: ' ist ausgewählt.',
	PLEASE_SELECT_DIFFERENT_OPERATOR_TO_CHANGE_OPERATOR: 'Bitte einen anderen Betreiber auswählen, um den Betreiber zu ändern:',
	GENERATE_REPORT: 'BERICHT ERSTELLEN',
	REPORT_TYPE: 'BERICHTTYP',
	DATA_PLAN_SUMMARIES: 'Datenplan-Zusammenfassungen',
	CHARGE_SUMMARIES: 'Gebührenzusammenfassungen',
	INVOICE_REPORT: 'Rechnungsbericht',
	PRORATION_REPORT: 'Bericht zur anteiligen Verrechnung',
	GROSS_ACTIVATIONS: 'Brutto-Aktivierungen',
	MONTHLY_SUBSCRIBERS: 'Monatliche Abonnenten',
	VIEW_YOUR_ACCOUT_SNAPSHOT: 'Konto-Momentaufnahme anzeigen.',
	LAUNCH_DASHBOARD: 'DASHBOARD STARTEN',
	VISUAL_DASHBOARD_VIEW_EXP: 'Dashboard-Grafiken zeigen eine Übersicht über den aktuellen Kontostand und die Geräteaktivität.',
	VIEW_CURRENT_STATUS_AND_HISTORICAL_DATA: 'Status- und Verlaufsdaten als Chronik anzeigen.',
	IF: 'WENN',
	AND: 'UND',
	OR: 'ODER',
	TO: 'BIS',
	FROM: 'VON',
	INVOICES_STATUS: 'RECHNUNGSSTATUS',
	filter_category_m2mAccount: 'M2mAccount',
	PLEASE_CONFIRM_ENDPOINT_DATA_CHANGE: 'Bitte bestätigen Sie die Endpunkt-Datenänderung.',
	SET_NEW_OPERATOR_AS_ACTIVE: 'NEUEN BETREIBER ALS AKTIV MARKIEREN',
	SET_NEW_PLAN_AS_ACTIVE: 'NEUEN PLAN ALS AKTIV MARKIEREN',
	PLEASE_SELECT_DATE_TO_SET_UP_YOUR_CHANGES_AND_CONFIRM: 'Bitte Datum für Änderungen auswählen und bestätigen.',
	DATE_REQUIRED: 'Datum (erforderlich)',
	ADDITIONAL_COMMENT: 'Weiterer Kommentar',
	TYPE_YOUR_CUSTOM_NOTES_HERE: 'Benutzerdefinierte Anmerkungen hier eingeben...',
	CONFIRM_CHANGES: 'Änderungen bestätigen',
	CHECKING_MOVE_ELIGIBILITY_LOADER: 'Übertragbarkeit wird geprüft...',
	ELIGIBLE: 'QUALIFIZIERT',
	CHECKING: 'PRÜFUNG LÄUFT',
	PLEASE_SELECT_DIFFERENT_DATA_PLAN_TO_CHANGE_DATA_PLAN: 'Bitte unten einen anderen Plan auswählen, um den Datenplan zu ändern:',
	CONFIRM: 'Bestätigen',
	ENDPOINT_RANGE_IS: 'Endpunktbereich ist:',
	ENDPOINT_ID_IS: 'Endpunkt-ID ist:',
	ENDPOINT_ID_IS_GREATER_THAN: 'Endpunkt-ID ist größer als:',
	ENDPOINT_ID_IS_LESS_THAN: 'Endpunkt-ID ist kleiner als:',
	ENDPOINT_STATE_CHANGE: 'Endpunktstatusänderung',
	ENDPOINT_DATA_PLAN: 'Endpunktdatenplan',
	ENDPOINT_SECURITY_POLICY_CHANGE: 'Endpunktsicherheit – Richtlinienänderung',
	ASSIGN_ENDPOINT_TO_OPERATOR: 'Endpunkt Betreiber zuweisen',
	MOVE_TO_ANOTHER_ACCOUNT: 'In ein anderes Konto verschieben',
	ADD_CUSTOM_FIELD_TO_ENDPOINT_DETAILS: 'Benutzerdefiniertes Feld zu Endpunkt-Details hinzufügen',
	CREATE_NEW_ACTION: 'Neue Aktion erstellen',
	AT_T: 'AT&T IoT (ACC)',
	AT_T_EOD: 'AT&T IoT (ACC)',
	GLOBECOMM: 'Speedcast',
	TAIWAN_MOBILE: 'Taiwan Mobile (TWM)',
	'0001': '***0001',
	'0002': '***0002',
	'0003': '***0003',
	'0004': '***0004',
	OTA_EVENT: 'OTA-Ereignis',
	WI_FI: 'WLAN',
	THAILAND: 'Thailand (THA)',
	TAIWAN: 'Taiwan (TWN)',
	UNITED_STATES: 'Vereinigte Staaten (USA)',
	DATA_PLAN_S: 'Datenplan S',
	DATA_PLAN_M: 'Datenplan M',
	DATA_PLAN_L: 'Datenplan L',
	DATA_PLAN_XL: 'Datenplan XL',
	// ZU ERLEDIGEN: Endpunkt-Status mit korrekten Werten aktualisieren
	ACTIVATED: 'Aktiviert',
	'TEST READY': 'Test bereit',
	DEACTIVATED: 'Deaktiviert',
	PURGED: 'Bereinigt',
	RETIRED: 'Stillgelegt',
	//
	USER: 'Benutzer',
	ON: 'an',
	MADE: 'hat vorgenommen',
	CHANGE_STATUS_ACTION: 'Status ändern',
	CHANGE_DATA_PLAN_ACTION: 'Datenplan ändern',
	LOCALIZE_SERVICE_ACTION: 'Lokalisierter Service',
	IMSI_TRANSFER: 'IMSI-Übertragungsstatus',
	TRANSFER_STATUS: 'IMSI-Übertragungsstatus',
	COUNTRY_OPERATOR: 'Netzland',

	PROFILE_NAME: 'Profilname',
	NUMBER: 'Nummer',
	DATE_MODIFIED: 'Änderungsdatum',

	ENTITLEMENT_PLAN_ID: 'Anspruchsplan-ID',
	ENTITLEMENT_PLAN_NAME: 'Anspruchsplanname',
	ACCOUNT_ID: 'Konto-ID',
	ACCOUNT_I_D: 'Konto-ID',
	IMEI_NAME: 'IMEI-Name',
	VIN: 'VIN',
	CHIP_ID: 'Chip-ID',
	URL_SP: 'URL SP',
	ADD_SINGLE_ENDPOINT: 'Einzelnen Endpunkt hinzufügen',
	UPLOAD_ICCID_BULK_FILE: 'ICCID-Sammeldatei hochladen',
	DOWNLOAD_BULK_FILE: 'Sammeldatei herunterladen',
	ENDPOINTS_SELECTED: 'Endpunkte ausgewählt',
	SELECTED_ENDPOINTS: 'ausgewählte Endpunkte',
	NEW_STATUS: 'Neuer Status',
	MULTIPLE_ENDPOINT_EDIT: 'Mehrere Endpunkte bearbeiten',
	CLEAR_VALUES_FOR_ALL_ENDPOINTS: 'Werte für alle Endpunkte löschen',
	KEEP_ORIGINAL_ENDPOINT_VALUES: 'Original-Endpunktwerte beibehalten',
	ACTIVE: 'Aktiviert',
	INACTIVE: 'Inaktiv',
	TERMINATE: 'Beendet',
	TEST_READY: 'Test bereit',
	TEST: 'Test',
	SELECT_OPTION_OR_TYPE_IN: 'Option auswählen oder eingeben',
	CLEAR_ALL_VALUES_FOR_ENDPOINTS: 'Alle Werte für Endpunkte löschen',
	BULK_STATUS_UPDATE: 'Sammelstatusaktualisierung',
	TOTAL_ENDPOINTS: 'Endpunkte insgesamt',
	TOTAL_FILES: 'Dateien insgesamt',
	ENDPOINT_STATUS_UPDATED: 'Endpunktstatus aktualisiert',
	MODIFIED_ENDPOINTS: 'Geänderte Endpunkte',
	SOMETHING_WENT_WRONG: 'Etwas ist schief gelaufen',
	ENDPOINT_STATUS_IS_UPDATING: 'Endpunktstatus wird aktualisiert',
	PLEASE_WAIT: 'Bitte warten',
	SWAP_SIM: 'SIM austauschen',
	SWAP_SIMS: 'SIMS austauschen',
	SIM: 'SIM',
	NEW: 'Neu',
	OLD: 'Alt',
	CHANGE_ORDER: 'Reihenfolge ändern',
	ITEM: 'Objekt',
	ADDITIONAL_INFO: 'Weitere Info',
	UPDATE_LABEL: 'Bezeichnung aktualisieren',
	ENDPOINT_LABEL_IS_UPDATING: 'Endpunktbezeichnung wird aktualisiert',
	ENDPOINT_LABEL_UPDATED: 'Endpunktbezeichnung aktualisiert',
	ENDPOINT_FIELDS_ARE_UPDATING: 'Endpunktfelder werden aktualisiert',
	ENDPOINT_FIELDS_UPDATED: 'Endpunktfelder aktualisiert',
	EDIT_CUSTOM_FIELDS: 'Benutzerdefinierte Felder bearbeiten',
	EDIT_CUSTOM_LABEL: 'Benutzerdefinierte Bezeichnung bearbeiten',
	CHANGE_ENTITLEMENT: 'Netzwerkanspruch ändern',
	CHANGE_NETWORK_ENTITLEMENT: 'Netzwerkanspruch ändern',
	CURRENT_NETWORK_ENTITLEMENT: 'Aktueller Netzwerkanspruch',
	NETWORK_ENTITLEMENT_UPDATED_SUCCESSFULLY: 'Netzwerkanspruch erfolgreich aktualisiert!',
	LIST_VIEW_UPDATED: 'Endpunkt-Listenansicht aktualisiert',
	NETWORK_ENTITLEMENT_UPDATING: 'Netzwerkanspruch wird aktualisiert. Bitte warten',
	LIST_VIEW_UPDATING: 'Endpunkt-Listenansicht wird aktualisiert. Bitte warten',
	NEW_NETWORK_ENTITLEMENT: 'Neuer Netzwerkanspruch',
	NEW_NETWORK_ENTITLEMENT_DETAILS: 'Neue Netzwerkanspruchsdetails',
	TABLE_COLUMNS: 'Anzahl der in der Tabelle anzuzeigenden Spalten auswählen.',
	TABLE_COLUMNS_OR: 'Anzahl der in der Tabelle anzuzeigenden Spalten auswählen oder',
	COLUMN: 'Spalte',
	COLUMNS: 'Spalten',
	SELECT: 'Auswählen',
	DATA_TYPE: 'Datentyp',
	ADVANCED_SEARCH: 'Erweiterte Suche',
	SEARCH_RESULTS: 'Suchergebnisse',
	SUBMIT: 'Absenden',
	SEARCH_PARAMETER: 'Suchparameter',
	ADD_ANOTHER_SEARCH_PARAMETER: 'Weiteren Suchparameter hinzufügen',
	STATUS_DATE_RANGE: 'Datumsbereich für Status',
	SIM_STATUS_STATE: 'SIM-Status (Zustand)',
	NEW_PARAMETER_ACTION: 'Und / Oder',
	PLEASE_SELECT_VALUES_TO_FILTER_ENDPOINTS: 'Aus verfügbaren Parametern auswählen, dann Kriterien für Endpunktfilterung eingeben.',
	RESET_SEARCH: 'Suche zurücksetzen',
	QUICK_SEARCH: 'Schnellsuche',
	ORIGINAL_SETTINGS: 'Auf Originaleinstellungen zurücksetzen',
	MONTH: 'Monat',
	DAY: 'Tag',
	REFRESH_ENDPOINTS_PAGE: 'Endpunkttabelle/-seite aktualisieren',
	CLEAR_SELECTED_FILTERS_TOOLTIP: 'Ausgewählte Filter löschen',
	SELECT_FILTERS_TOOLTIP: 'Filter auswählen',
	EDIT_TABLE_PARAMETERS_TOOLTIP: 'Tabellenparameter bearbeiten',

	REPORT_DATE: 'Datum',

	START_NEW_SEARCH: 'Neue Suche starten',
	SUCCESS: 'Erfolg',
	FAILED: 'Fehlgeschlagen',
	RESULT_ACTION: 'Aktion',
	REPORT_DETAILS: 'Berichtdetails',
	REPORT_LIST: 'Liste der Berichte in',
	VIEW_DETAILS: 'Details anzeigen',
	DOWNLOAD_PDF: 'Herunterladen',
	FILE_NAME: 'Dateiname',
	BATCH_FILES: 'Batch-Dateien',
	BATCH_FILE_NAME: 'Batch-Dateiname',
	BATCH_FILE_ACTIONS: 'Batch-Dateiaktionen',
	BATCH_FILE_UPLOAD: 'Sammelaktionen',
	CHANGE_SIM_STATE: 'SIM-Status ändern',
	SELECT_SIM_STATE: 'SIM-Status auswählen',
	SIMS_SELECTED: 'SIMs ausgewählt',
	SIM_SELECTED: 'SIM ausgewählt',
	ONCE_YOU_INITIATE_CHANGE: 'Wenn Sie diese Änderung einleiten, lautet die ausgewählte SIMS',
	PROCEED_ACTION: 'Mit dieser Aktion fortfahren?',
	CAN_SCHEDULE: 'Sie können die Änderung auch planen für',
	CURRENT_STATES: 'Aktuelle Status',
	SCHEDULE_SUCCESSFUL: 'Mehrfach-SIM-Statusänderung erfolgreich eingeplant!',
	SIM_STATE_CHANGE_FOR: 'SIM-Statusänderung für',
	SIMS_IS_SUCCESSFULLY_SCHEDULED_FOR: 'SIMS erfolgreich eingeplant für ',
	SIMS_SUCCESSFULLY_REQUESTED: 'SIM-Statusänderung erfolgreich angefordert!',
	MULTIPLE_SIM_STATE_CHANGE_SCHEDULE_ERROR: 'Mehrfach-SIM-Statusänderung konnte nicht erfolgreich eingeplant werden.',
	MULTIPLE_SIM_STATE_CHANGE_ERROR: 'Mehrfach-SIM-Statusänderung konnte nicht erfolgreich angefordert werden.',
	SELECTED_TIP: 'Tipp: Falls Sie SIMs in verschiedenen Zuständen ausgewählt haben, können Sie nur auf einen Zustand ändern, der für alle SIMs verfügbar ist.',
	VIEW_RULES: 'Statusänderungsregeln für dieses Konto anzeigen',
	BATCH_FILE_UPLOADING: 'Batch-Datei wird hochgeladen',
	DATE_UPLOADED: 'Upload-Datum',
	SIMPLE_SEARCH: 'Einfache Suche',
	NUMBER_OF_COLUMNS: 'Anzahl der Spalten',
	SELECT_SEARCH_PARAMETER: 'Suchparameter auswählen',

	DROPDOWN_BACK_TO_BUSINESS: 'Zurück zu Unternehmen',
	'DROPDOWN_CONTACT_AT&T': 'AT&T IoT kontaktieren',
	DROPDOWN_MC: 'IoT Console',
	DROPDOWN_M2X: 'M2X',
	DROPDOWN_FD: 'Flow Designer',
	DROPDOWN_SK: 'IoT-Starter-Kit',
	DROPDOWN_DP: 'IoT-Datenpläne',
	MY_INFORMATION: 'Meine Informationen',
	MY_APPLICATIONS: 'Meine Anwendungen',
	TOPBAR_IDENTITY: 'AT&T IoT Identity',
	ERROR: 'Fehler',
	PAGE_NOT_FOUND_MESSAGE: 'Wir können die gesuchte Seite nicht finden.',
	'RETURN TO HOME PAGE': 'ZURÜCK ZUR STARTSEITE',
	INTERNAL_SERVER_ERROR: 'Interner Serverfehler',
	ACTIVE_ENDPOINT_PROFILE: 'AKTIVES ENDPUNKTPROFIL',
	CREATE_OR_EDIT: 'Benutzerdefinierte Bezeichnung für diesen Endpunkt erstellen oder bearbeiten',
	CREATE_CUSTOM_LABELS: 'Benutzerdefinierte Bezeichnungen erstellen',
	SNAP: 'Verflixt',
	SNAP_ERROR_MESSAGE: 'Unbekannter Fehler aufgetreten. Besteht dieser Fehler weiterhin, wenden Sie sich bitte an den Support.',

	// Berichtsdetaidefinitionen
	REPORT_DETAIL_DEFINITIONS: 'BERICHTSDETAILDEFINITIONEN',
	REPORT_DETAIL_DEFINITIONS_MODAL_TITLE: 'Berichtsdetaildefinitionen',
	REPORT_DETAIL_DEFINITIONS_DESC: 'Schaltfläche unten auswählen, um alle Berichtsdetaildefinitionen anzuzeigen.',
	VIEW_DEFINITIONS_BUTTON: 'DEFINITIONEN ANZEIGEN',

	// Definitionstypen
	'DATA SESSIONS DETAIL DEFINITION': 'Datensitzungsdetaildefinition',
	'IMSI CHARGE DETAIL XML FIELDS': 'IMSI Kostendetail-XML-Felder',
	'IMSI DETAIL DEFINITIONS': 'IMSI-Detaildefinitionen',
	'LBS USAGE DETAILS': 'LBS-Nutzungsdetails',
	'DATA LAGG DETAIL DEFINITIONS': 'Daten-Lagg-Detaildefinitionen',
	'SMS CALL DETAIL DEFINITIONS': 'SMS-Anrufsdetaildefinitionen',
	'TAX DETAIL DEFINITIONS': 'Steuerdetaildefinitionen',
	'SIM ACTIVITY DETAIL DEFINITION': 'SIM-Aktivitätsdetaildefinition',

	// Daten-Lagg-Detaildefinitionen - SCHLÜSSEL -------------
	CUST_ID_KEY: 'CUST_ID',
	IMSI_KEY: 'IMSI',
	ICCID_KEY: 'ICCID',
	MSISDN_KEY: 'MSISDN',
	ON_NET_KB_USAGE_KEY: 'ON_NET_KB_USAGE',
	ROAMING_KB_USAGE_KEY: 'ROAMING_KB_USAGE',
	CAN_KB_USAGE_KEY: 'CAN_KB_USAGE',
	INT_KB_ROUNDED_KEY: 'INT_KB_ROUNDED',
	TOTAL_KB_USAGE_KEY: 'TOTAL_KB_USAGE',

	// Daten-Lagg-Detaildefinitionen - BESCHREIBUNGEN
	CUST_ID_DESC: 'EOD-Kunden-ID.',
	IMSI_DESC: 'International Mobile Station Identifier. Die vom Netzwerk verwendete Nummer zur Identifizierung des Benutzers.',
	ICCID_DESC: 'Integrated Circuit Card Identifier. Die auf der SIM-Karte aufgedruckte Nummer.',
	MSISDN_DESC: 'Dieser SIM zugewiesene Mobile Station ISDN-Nummer (Telefonnummer).',
	ON_NET_KB_USAGE_DESC: 'Gesamtnutzung netzintern (KB)',
	ROAMING_KB_USAGE_DESC: 'Inländische Gesamtnutzung netzextern (KB).',
	CAN_KB_USAGE_DESC: 'Gesamtnutzung Kanada (KB).',
	INT_KB_ROUNDED_DESC: 'Gesamtnutzung international (KB).',
	TOTAL_KB_USAGE_DESC: 'Gesamtnutzung (KB).',

	// Datensitzungsdetaildefinition - SCHLÜSSEL -------------
	IMPORT_DATE_KEY: 'Importdatum',
	// IMSI - bereits definierte Schlüsselbeschreibung verwenden
	// ICCID - bereits definierte Schlüsselbeschreibung verwenden
	GGSN_IP_KEY: 'GGSN IP',
	SGSN_IP_KEY: 'SGSN IP',
	APN_NAME_KEY: 'APN-NAME',
	PDP_IP_KEY: 'PDP IP',
	UPLINK_VOLUME_KEY: 'UPLINK-VOLUMEN',
	DOWNLINK_VOLUME_KEY: 'DOWNLINK-VOLUMEN',
	TRANSACTION_START_TIME_KEY: 'TRANSAKTIONS-STARTZEIT',
	DURATION_KEY: 'DAUER',
	SERVED_MSISDN_KEY: 'MSISDN GELIEFERT',
	BID_KEY: 'BID',
	SERVING_LOCATION_KEY: 'LIEFERORT',
	NETWORK_KEY: 'NETZWERK',
	ROUNDING_DATE_KEY: 'RUNDUNGSDATUM',

	// Datensitzungsdetaildefinition - BESCHREIBUNGEN
	IMPORT_DATE_DESC: 'Datum und Uhrzeit des Erhalts dieses Datensatzes durch das EOD. Damit wird bestimmt, welches Abrechnungsdatum für diesen Datensatz zutrifft.',
	// IMSI - bereits definierte Schlüsselbeschreibung verwenden
	// ICCID - bereits definierte Schlüsselbeschreibung verwenden
	GGSN_IP_DESC: 'Gateway General Packet Radio Service (GPRS) Support-Knoten. Das Gerät, das zwischen dem Public Land Mobile Network (PLMN) und externen Netzen geschaltet ist, um als Border-Gateway für z. B. Sicherheit und Buchhaltung zu dienen.',
	SGSN_IP_DESC: 'Serving General Packet Radio Service (GPRS) Support-Knoten. Das Gerät auf der gleichen hierarchischen Ebene wie das Mobile Switching Center/Visitor Location Register (MSC/VLR) zur Durchführung von Mobilitätsmanagement und Verwaltung der logischen Verknüpfung mit Mobilterminals.',
	APN_NAME_DESC: 'Der APN-Name, der von diesem Gerät für diese Transaktion benutzt wurde.',
	PDP_IP_DESC: 'Die dem Gerät zum Zeitpunkt der Transaktion zugewiesene IP.',
	UPLINK_VOLUME_DESC: 'Die Anzahl der Byte (in Oktetten), die vom Gerät für diesen Detaildatensatz weitergegeben wurde.',
	DOWNLINK_VOLUME_DESC: 'Die Anzahl der Byte (in Oktetten), die zum Gerät für diesen Detaildatensatz weitergegeben wurde.',
	TRANSACTION_START_TIME_DESC: 'Die tatsächliche Startzeit der Transaktion. Dieses Datum unterscheidet sich vom „Importdatum“ und „Routing-Datum“ je nachdem, wann der Datensatz vom EOD empfangen wurde.',
	DURATION_DESC: 'Die Zeit (in Sekunden) für diesen Datensatz.',
	SERVED_MSISDN_DESC: 'Die dieser SIM zugewiesene Mobile Station ISDN-Nummer (Telefonnummer).',
	BID_DESC: 'Abrechnungsidentifikator. Wird vom EOD dazu verwendet, den Markt zu bestimmen, in dem die Transaktion stattfindet.',
	SERVING_LOCATION_DESC: 'Der Name des Standorts, an dem dieser Datensatz aufgetreten ist.',
	NETWORK_DESC: 'Cingular, Roaming/Off-Network, International oder Kanada.',
	ROUNDING_DATE_DESC: 'Das vom EOD verwendete Datum, anhand dessen bestimmt wird, mit welchen anderen Datensätzen dieser Datensatz gruppiert wird, um die tägliche Rundung der Kilobyte zu berechnen.',
	SESSION_HISTORY_DCP_NOT_APPLICABLE: 'Nicht zutreffend.',

	// IMSI Kostendetail-XML-Felder - SCHLÜSSEL UND BESCHR. -----------
	CUSTOMER_KEY: 'CUSTOMER',
	CUSTOMER_DESC: 'Kundenpräfix',
	STARTCYCLE_KEY: 'STARTCYCLE',
	STARTCYCLE_DECS: 'Beginn des Abrechnungszyklus',
	ENDCYCLE_KEY: 'ENDCYCLE',
	ENDCYCLE_DESC: 'Ende des Abrechnungszyklus',
	WCO_KEY: 'WCO',
	WCO_DESC: 'Wireless Connectivity Offer',
	EQUIPMENTORDERS_KEY: 'EQUIPMENTORDERS',
	EQUIPMENTORDERS_DESC: 'Bestellung von Ausrüstung',
	ACCOUNTOCC_KEY: 'ACCOUNTOCC',
	ACCOUNTOCC_DESC: 'Sonstige Gebühren und Gutschriften auf Kontoebene (nur Steuern und Gebühren)',
	ACCOUNTTAXES_KEY: 'ACCOUNTTAXES',
	ACCOUNTTAXES_DESC: 'Staatliche Steuern und Gebühren (nur Steuern) auf Kontoebene',
	// ICCID - WIEDERVERWENDEN
	// IMSI - WIEDERVERWENDEN
	// MISISDIN - WIEDERVERWENDEN
	RATEPLAN_KEY: 'RATEPLAN',
	RATEPLAN_DESC: 'Tarifplan, nach dem abgerechnet wird.',
	ACTIVATIONSTATE_KEY: 'ACTIVATIONSTATE',
	ACTIVATIONSTATE_DESC: 'Gerätezustand.',
	CUSTFILED1_KEY: 'CUSTFILED1',
	CUSTFILED1_DESC: 'Kundenfeld 1',
	CUSTFILED2_KEY: 'CUSTFILED2',
	CUSTFILED2_DESC: 'Kundenfeld 2',
	CUSTFILED3_KEY: 'CUSTFILED3',
	CUSTFILED3_DESC: 'Kundenfeld 3',
	CUSTFILED4_KEY: 'CUSTFILED4',
	CUSTFILED4_DESC: 'Kundenfeld 4',
	CUSTFILED5_KEY: 'CUSTFILED5',
	CUSTFILED5_DESC: 'Kundenfeld 5',
	ATTPOINTER_KEY: 'ATTPOINTER',
	ATTPOINTER_DESC: 'Interner Pointer',
	CUSTPOINTER_KEY: 'CUSTPOINTER',
	CUSTPOINTER_DESC: 'Kunden-Pointer',
	DATAMRC_KEY: 'DATAMRC',
	DATAMRC_DESC: 'Daten-MRC',
	DATAUSAGE_KEY: 'DATAUSAGE',
	DATAUSAGE_DESC: 'Summe aller Datennutzungsgebühren',
	SMSMRC_KEY: 'SMSMRC',
	SMSMRC_DESC: 'SMS MRC',
	SMSUSAGE_KEY: 'SMSUSAGE',
	SMSUSAGE_DESC: 'Summe aller SMS-Nutzungsgebühren – ausschließlich SMS-Sammelgebühr',
	VOICEMRC_KEY: 'VOICEMRC',
	VOICEMRC_DESC: 'Voice MRC',
	VOICEUSAGE_KEY: 'VOICEUSAGE',
	VOICEUSAGE_DESC: 'Summe aller Voice-Nutzungsgebühren',
	DEVICEACTIVITYFEE_KEY: 'DEVICEACTIVITYFEE',
	DEVICEACTIVITYFEE_DESC: 'Gerätegebühren (Aktivierungsgebühren, Deaktivierungsgebühren, Reaktivierungsgebühren, einmalige Planänderungsgebühren)',
	OCC_KEY: 'OCC',
	OCC_DESC: 'Sonstige Gebühren und Gutschriften auf Abonnentenebene (nur Steuern und Gebühren)',
	TAXES_KEY: 'TAXES',
	TAXES_DESC: 'Staatliche Steuern und Gebühren (nur Steuern) auf Abonnentenebene',

	// IMSI-DETAILS SCHLLÜSSELWERT -----------------------
	// ICCID - WIEDERVERWENDEN
	// MISISDIN - WIEDERVERWENDEN
	PLAN_CODE_KEY: 'PLAN_CODE',
	PLAN_CODE_DESC: 'Die vom Kunden den einzelnen SIMs zugewiesenen Plancodes.',
	ACT_ID_KEY: 'ACT_ID',
	ACT_ID_DESC: 'Die vom Kunden an den einzelnen SIMs durchgeführten Aktionen (1 = Aktivieren, 2 = Deaktivieren)',
	INCLUDED_USAGE_KEY: 'INCLUDED_USAGE',
	INCLUDED_USAGE_DESC: 'Insgesamt enthaltene Nutzung (KB)',
	// ON_NET_KB_USAGE - REUSE
	// ROAMING_KB_USAGE - REUSE
	// CAN_KB_USAGE - REUSE
	// INT_KB_ROUNDED - REUSE
	// TOTAL_KB_USAGE - REUSE
	ACCESS_CHG_KEY: 'ACCESS_CHG',
	ACCESS_CHG_DESC: 'Monatlich wiederkehrende Zugangsgebühr.',
	ACTIVATION_CHG_KEY: 'ACTIVATION_CHG',
	ACTIVATION_CHG_DESC: 'Aktivierungsgebühr.',
	BEGPERIOD_KEY: 'BEGPERIOD',
	BEGPERIOD_DESC: 'Beginn des Abrechnungszyklus.',
	ENDPERIOD_KEY: 'ENDPERIOD',
	ENDPERIOD_DESC: 'Ende des Abrechnungszyklus.',
	CUST_PREFIX_KEY: 'CUST_PREFIX',
	CUST_PREFIX_DESC: 'Kundenidentifikator.',
	CUST_FIELD_1_KEY: 'CUST_FIELD_1',
	CUST_FIELD_1_DESC: 'Kundenfeld 1.',
	CUST_FIELD_2_KEY: 'CUST_FIELD_2',
	CUST_FIELD_2_DESC: 'Kundenfeld 2.',
	CUST_FIELD_3_KEY: 'CUST_FIELD_3',
	CUST_FIELD_3_DESC: 'Kundenfeld 3.',
	CUST_FIELD_4_KEY: 'CUST_FIELD_4',
	CUST_FIELD_4_DESC: 'Kundenfeld 4.',
	CUST_FIELD_5_KEY: 'CUST_FIELD_5',
	CUST_FIELD_5_DESC: 'Kundenfeld 5.',
	INTERNAL_POINTER_KEY: 'INTERNER POINTER',
	INTERNAL_POINTER_DESC: 'AT&T Interner Pointer',
	CUSTOMER_POINTER_KEY: 'KUNDEN-POINTER',
	CUSTOMER_POINTER_DESC: 'Kunden-Pointer.',

	// LBS-NUTZUNGSDEFINITION SCHLÜSSELWERT
	RECORDINGENTITY_KEY: 'RECORDINGENTITY',
	RECORDINGENTITY_DESC: 'Einzigartiger Pointer zu Agg-Datensätzen. (Für Aggregatorverwendung reserviert).',
	LCSCLIENTEXTERNALID_KEY: 'LCSCLIENTEXTERNALID',
	LCSCLIENTEXTERNALID_DESC: 'LCS Client ExternalID (derzeit nicht verwendet).',
	TARGETIMSI_KEY: 'TARGETIMSI',
	TARGETIMSI_DESC: 'International Mobile Station Identifier. Die vom Netzwerk verwendete Nummer zur Identifizierung des Benutzers.',
	TARGETMSISDN_KEY: 'TARGETMSISDN',
	TARGETMSISDN_DESC: 'MSDN gefunden, falls ATT-SIM, XXXXXXXXXX, falls Nicht-ATT-SIM (Die dieser SIM zugewiesene Mobile Station ISDN-Nummer „Telefonnummer“).',
	RESULTCODE_KEY: 'RESULTCODE',
	RESULTCODE_DESC: 'Erfolg oder Nichterfolg der Lokalisierung. 0 = Erfolg/ nur 0 und 1 verwendet, um als Agg und Med zu starten Ergebniscodes können unterschiedlich sein.',
	UTCRECORDOPENINGTIME_KEY: 'UTCRECORDOPENINGTIME',
	UTCRECORDOPENINGTIME_DESC: 'Derzeit nicht verwendet (UTC-Öffnungszeit).',
	INFORMATION_1_KEY: 'INFORMATION_1',
	INFORMATION_1_DESC: 'Anwendungs-ID 16 Stellen EAG ausgegeben AppID („1“ in position2 = Technocom, „2“ in position2 = LocAid Id)',
	INFORMATION_3_KEY: 'INFORMATION_3',
	INFORMATION_3_DESC: '(Horizontal) Angeforderte Genauigkeit: Gewünschte Genauigkeit in Metern. <=800 ist AGPS. (800 kennzeichnet AGPS-Anforderung oder 801 kennzeichnet CID/ECID-Anforderung für nonATT erfolgreiche Lokalisierungen und 0 für erfolglose (gemäß Antwort)).',
	INFORMATION_4_KEY: 'INFORMATION_4 ',
	INFORMATION_4_DESC: '(Horizontal) Zurückgegebene Genauigkeit: Zurückgegebene Genauigkeit in Metern. (800 kennzeichnet AGPS-Anforderung oder 801 kennzeichnet CID/ECID-anforderung für nonATT erfolgreiche Lokalisierungen und 0 für erfolglose (gemäß Antwort)).',
	PART_DATE_KEY: 'PART_DATE',
	PART_DATE_DESC: 'Datum der Eingabe in CDR-Übertragungsdatei durch Agg. (PST: JJJJ-MM-TT HH:mm:ss)',

	// SMS-ANRUFDEFINITIONEN - SCHLÜSSEL UND BESCHR.
	// IMSI
	// GELIEFERTE MSISDN
	SMS_TYPE_KEY: 'SMS-TYP',
	SMS_TYPE_DESC: 'Mobil-Versand (Mobil Originated, MO) oder Mobil-Empfang (Mobile Terminated, MT).',
	CALLED_NUMBER_KEY: 'ANGERUFENE NUMMER',
	CALLED_NUMBER_DESC: 'Die gewählte Nummer.',
	START_TIME_KEY: 'STARTZEIT',
	START_TIME_DESC: 'Datum und Uhrzeit der Transaktion.',
	// NETZWERK
	PLMN_KEY: 'PLMN',
	PLMN_DESC: 'Public Land Mobile Network List ist ein Abrechnungidentifikator, der vom EOD dazu verwendet wird, den Markt zu bestimmen, in dem die Transaktion stattfindet.',
	CARRIER_KEY: 'BETREIBER',
	CARRIER_DESC: 'Der Mobilitätsmarkteigner für die zugeordnete PLMN.',

	// SMS-AKTIVITÄTSDEFINITIONEN - SCHLÜSSEL UND BESCHR.
	// ICCID
	// MSISDN
	ACTION_DATE_KEY: 'ACTION_DATE',
	ACTION_DATE_DESC: 'Datum und Uhrzeit des Abschlusses der Aktion.',
	ACT_KEY: 'ACT',
	ACT_DESC: 'Die vom Kunden an den einzelnen SIMs durchgeführten Aktionen (ACTV = Aktivieren, KILL = Deaktivieren, CHNG = Netzwerkprofiländerung RATE = Tarifplanänderung, CPPU = Change Primary Place of Use, Änderung des primären Verwendungsorts).',
	PLAN_CODE_KEY_2: 'PLAN_CODE',
	PLAN_CODE_DESC_2: 'Der Alpha-Code des Plans, der für diese Transaktion verwendet wurde.',
	LOGIN_KEY: 'ANMELDUNG',
	LOGIN_DESC: 'Benutzerzugeordnete Transaktion.',

	// STEUERDETAIL-DEFINITIONEN - SCHLÜSSEL UND BESCHR.
	PPU_ADDRES_KEY: 'PPU_ADDRES',
	PPU_ADDRES_DESC: 'Ort der primären Verwendung – wo der Kunde die Dienstleistung(en) primär nutzt.',
	SERVICE_KEY: 'DIENST',
	SERVICE_DESC: 'Das zu besteuernde Produkt oder Angebot.',
	INVOICE_SECTION_KEY: 'RECHNUNGSABSCHNITT',
	INVOICE_SECTION_DESC: 'TX – Staatliche Gebühren und Steuern OC – Sonstige Gebühren und Gutschriften.',
	AUTHORITY_LEVEL_KEY: 'AUTORITÄTSSTUFE',
	AUTHORITY_LEVEL_DESC: 'Der Rechnung zugeordnete Stufe Text F – Federal (Bund) – State (Bundesland) Co – Country (Land) – Ci – City (Ort) DCo – Distrikt Land DCi – Distrikt Ort',
	INVOICE_TEXT_KEY: 'RECHNUNGSTEXT',
	INVOICE_TEXT_DESC: 'Text zur berechneten Gebühr auf der Kundenrechnung.',
	CHARGE_AMMOUNT_KEY: 'GEBÜHRENBETRAG',
	CHARGE_AMMOUNT_DESC: 'Der Betrag, auf den Steuern angewandt werden.',
	AMMOUNT_KEY: 'BETRAG',
	AMMOUNT_DESC: 'Der berechnete Steuerbetrag für eine zugeordnete steuerpflichtige Gebühr.',
	BATCH_FORMATS: 'Batch-Formate',

	ACTIVATION: 'Aktivierung',
	TERMINATION: 'Beendigung',
	NETWORK_ENTITLEMENT_CHANGE: 'Netzwerkanspruchsänderung',
	SIM_SWAP: 'SIM-Austausch',
	CUSTOM_FIELD_UPDATES: 'Benutzerdefiniertes Feld – Aktualisierungen',
	CUSTOM_FIELD_VALUE: 'Benutzerdefiniertes Feld – Wert',
	BATCH_FILE_UPLOAD_SUCCESS: 'Batch-Dateien erfolgreich empfangen!',
	BATCH_FILE_UPLOAD_SUCCESS_MESSAGE: 'Es stehen Änderungen aus, während das System Ihre Aktualisierungen abschließt',
	BATCH_FILE_SYSTEM_ERROR: 'Batch-Dateisystemfehler!',
	BATCH_FILE_SYSTEM_ERROR_MESSAGE: 'Bei der Verarbeitung Ihrer Aktualisierungen ist ein Problem aufgetreten. Bitte versuchen Sie es später noch einmal.',
	TYPE_IN_OR_SELECT: 'Bitte geben Sie einen neuen Wert ein oder wählen Sie eine Option aus der Dropdown-Liste aus, um spezifische Endpunktparameter zu ändern.',

	SUBMITTED_STATUS: 'Abgesendet',
	SCHEDULED_STATUS: 'Geplant',
	IN_PROGRESS_STATUS: 'Findet statt',
	COMPLETED_STATUS: 'Abgeschlossen',
	'IN-PROGRESS_STATUS': 'Findet statt',
	COMPLETED: 'Abgeschlossen',
	CANCELLED: 'Abgebrochen',
	ERROR_STATUS: 'Fehler',

	FILE_NAME_CANNOT_CONTAIN_SPACES: 'Dateiname darf keine Leerzeichen enthalten',
	DRAG_FILE_OR_CLICK_TO_UPLOAD: 'Datei zum Hochladen ziehen oder klicken',
	FORBIDDEN_ACCESS: 'Zugriff verboten',
	REQUEST_TIMED_OUT: 'Zeitüberschreitung bei Anforderung',
	TERMINATE_ACTION: 'SIMS beenden',
	ACTIVATE_ACTION: 'SIMS aktivieren',
	SWAP_SIMS_ACTION: 'SIMS austauschen',
	CUSTOM_FIELDS_ACTION: 'Benutzerdefinierte Felder hinzufügen oder aktualisieren',
	REQUIRED_CUSTOM_FIELD: 'Erhöhte Berechtigungen erforderlich!',
	FIELD_IS_REQUIRED_ACTION: 'Nur Administratoren können benutzerdefinierte Feldtypen ändern, weil sich diese systemweit auf die Konfiguration auswirken. Wenden Sie sich an Ihren Administrator, um benutzerdefinierte Feldoptionen zu aktualisieren.',
	PERMANENT_CUSTOM_FIELD_DELETION: 'Benutzerdefiniertes Feld wird permanent gelöscht!',
	DELETING_THIS_CUSTOM_FIELD: 'Durch Löschen dieses benutzerdefinierten Felds werden alle damit verbundenen Daten gelöscht! Diese Aktion kann nicht rückgängig gemacht werden!',
	DELETE_FIELD: 'Feld löschen',

	WARNING: 'Warnung',
	ARE_YOU_SURE: 'Sind Sie sicher?',
	NETWORK_ENTITLEMENT_CHANGE_ACTION: 'SIM-Netzwerkanspruchsänderung',
	WHEN_SAVING: 'Beim Speichern Ihrer Datei bitte das oben angegebene Format befolgen.',
	EXAMPLE: 'Beispiel',

	SELECT_NEW_STATE: 'Neuen Status auswählen',
	403: '403',

	AUTOMATION_PROCESS: 'Automatisierungsprozess',
	BATCH_UPLOAD: 'Batch-Upload',
	DIAGRAM_COULD_NOT_BE: 'Diagramm konnte',
	LOADED_AT_THIS_TIME: 'zu diesem Zeitpunkt nicht geladen werden.',
	BATCH_ACTIONS: 'Batch-Aktionen',
	BATCH_ACTION: 'Batch-Aktion',
	BATCH_ACTIONS_SUBMITTED: 'Die Datei wurde erfolgreich zur Verarbeitung hochgeladen',
	BATCH_ACTIONS_SCHEDULED: 'Die Datei wurde erfolgreich hochgeladen, Verarbeitung ist eingeplant',
	BATCH_ACTIONS_IN_PROGRESS: 'Verarbeitung findet statt',
	BATCH_ACTIONS_COMPLETED: 'Die Datei wurde erfolgreich verarbeitet',
	BATCH_ACTIONS_ERROR: 'Bei der Verarbeitung ist ein Fehler aufgetreten',
	BATCH_REPORTING: 'Batch-Berichte',
	BATCH_TEXT: 'Um Ihre Änderungen kombiniert als Batch zu verarbeiten, wählen Sie bitte eine Aktion aus und laden Sie Ihre Batch-TXT-Datei hoch. Für die Formatierung sind Beispielvorlagen verfügbar.',
	START_BATCH_PROCESS: 'Batch-Vorgang starten',
	DOWNLOAD_TEMPLATE: 'Vorlagendatei herunterladen',
	AUTOMATION_RULES: 'Automatisierungsregeln',
	ACTION_FORMAT: 'Aktionsformat',
	VIEW_MORE_DETAILS: 'Weitere Details anzeigen',
	UPLOAD_FILE_NOTE: 'Dateien für ausgewählte Batch-Aktion hier hochladen: ',
	UPLOAD_FILE: 'Datei hochladen',

	UNSUPPORTED_FILE_TYPE: 'Dateityp wird nicht unterstützt',
	INVALID_FILE_NAME_FORMAT: 'Dateinamensformat sollte Aktionsname_MM-TT-JJJJ-HH-mm-ss sein',
	DOWNLOAD_REPORT_FAILED: 'Herunterladen fehlgeschlagen, bitte versuchen Sie es noch einmal.',
	PLEASE_TRY_AGAIN: 'Bitte versuchen Sie es noch einmal.',

	// BERICHTSDETAILDEFINITION
	REPORT_SOURCE: 'Berichtquelle',
	DATA_SESSION_DETAIL_DEFINITION: 'Datensitzungsdetaildefinition',
	IMSI_DETAIL_DEFINITION: 'IMSI-Detaildefinition',
	SMS_DETAIL_DEFINITION: 'SMS-Detaildefinition',
	SIM_DETAIL_DEFINITION: 'SIM-Detaildefinition',
	USAGE_DATA_DETAILS: 'Nutungsdatendetail',
	USAGE_SMS_DETAILS: 'Nutzungs-SMS-Detail',
	POOLED_USAGE: 'Gepoolte Nutzung',
	USAGE_SUMMARY: 'Nutzungszusammenfassung',
	SUBSCRIBER_SNAPSHOT: 'Abonnenten-Snapshot',
	NEW_SUBSCRIBERS: 'Neue Abonnenten',
	TOP_DATA_USAGE: 'Top-Datennutzung',
	TOP_SMS_USAGE: 'Top-SMS-Nutzung',
	PROVOSIONING_METRIC: 'Bereitstellungsmetrik',

	// NUTZUNGSDATENDETAIL - SCHLÜSSEL UND BESCHR.
	DATA_TRAFFIC_DETAIL_ID_KEY: 'Datentraffic-Detail-ID',
	DATA_TRAFFIC_DETAIL_ID_DESC: 'Eindeutiger Identifikator für Datennutzungs-Datensätze',
	// 'ICCID_KEY'
	ICCID_DESC_SHORT: 'SIM identifizierende ICCID',
	// 'MSISDN_KEY'
	MSISDN_DESC_SHORT: 'SIM identifizierende MSISDN',
	// 'IMSI_KEY'
	IMSI_DESC_SHORT: 'SIM identifizierende IMSI',
	ACCOUNT_ID_KEY: 'Konto-ID',
	ACCOUNT_ID_DESC: 'Jasper-Konto-ID-Nummer',
	BILLABLE_FLAG_KEY: 'Flag für „In Rechnung zu stellen“',
	BILLABLE_FLAG_DESC: 'Indikator für in Rechnung zu stellenden Traffic basierend auf SIM-Status',
	BILLING_CYCLE_KEY: 'Abrechnungszyklus',
	BILLING_CYCLE_DESC: 'Abrechnungszyklus, in dem Nutzung stattgefunden hat',
	SIM_STATE_KEY: 'SIM-Status',
	SIM_STATE_DESC: 'Der SIM am Ende des Berichtszeitraums zugewiesene SIM-Status',
	SERVICE_TYPE_KEY: 'Servicetyp',
	SERVICE_TYPE_DESC: 'Typ des gemeldeten kabellosen Dienstes (z. B. Daten, SMS MO, SMS MT, Voice)',
	ASSIGNED_RATE_PLAN_ID_KEY: 'Zugewiesene Tarifplan-ID',
	ASSIGNED_RATE_PLAN_ID_DESC: 'Der SIM zum Zeitpunkt der Nutzungsmeldung zugewiesene Tarifplan',
	ASSIGNED_RATING_ZONE_ID_KEY: 'Zugewiesene Bewertungszonen-ID',
	ASSIGNED_RATING_ZONE_ID_DESC: 'Tarifplanzone, der die Nutzung zugeordnet wird, basierend auf der Tarifplanzuweisung zum Zeitpunkt der Nutzungsmeldung',
	OFFPEAK_USAGE_FLAG_KEY: 'Off-Peak-Nutzungs-Flag',
	OFFPEAK_USAGE_FLAG_DESC: 'Ob die Nutzung Zeiten stattgefunden hat, die für das Konto als Off-Peak definiert wurden',
	RECORD_RECEIVED_DATE_KEY: 'Datum des Datensatzempfangs',
	RECORD_RECEIVED_DATE_DESC: 'Datum/Uhrzeit des Empfangs des Abrechnungsdatensatzes',
	DATA_USAGE_RAW_TOTAL_KEY: 'Datennutzung – roh gesamt',
	DATA_USAGE_RAW_TOTAL_DESC: 'Nicht gerundete Datennutzung für den Zeitraum – Byte',
	DATA_USAGE_RAW_UPLINK_KEY: 'Datennutzung – roh Uplink',
	DATA_USAGE_RAW_UPLINK_DESC: 'Nicht gerundete Datennutzung für den Zeitraum – Uplink-Byte',
	DATA_USAGE_RAW_DOWNLINK_KEY: 'Datennutzung – roh Downlink',
	DATA_USAGE_RAW_DOWNLINK_DESC: 'Nicht gerundete Datennutzung für den Zeitraum – Downlink-Byte',
	DATA_USAGE_ROUNDED_KEY: 'Datennutzung – gerundet',
	DATA_USAGE_ROUNDED_DESC: 'Gerundete Datennutzung für den Zeitraum – Byte; Rundungsanwendung je nach Tarifplan',
	APN_DESC: 'Zugriffspunktname für die Sitzung',
	DEVICE_IP_ADDRESS_KEY: 'Geräte-IP-Adresse',
	DEVICE_IP_ADDRESS_DESC: 'Die dem Gerät für die jeweilige Datensitzung zugeordnete IP-Adresse',
	OPERATOR_NETWORK_KEY: 'Bedienernetzwerk',
	OPERATOR_NETWORK_DESC: 'Das besuchte Bedienernetzwerk, in dem die Nutzung stattgefunden hat',
	RECORD_OPEN_TIME_KEY: 'Datensatz-Eröffnungszeit',
	RECORD_OPEN_TIME_DESC: 'Datum/Uhrzeit des Beginns der Sitzung oder Teilsitzung',
	SESSION_DURATION_KEY: 'Sitzungsdauer',
	SESSION_DURATION_DESC: 'Dauer der Datensitzung – Sekunden',
	RECORD_SEQUENCE_NUMBER_KEY: 'Datensatzsequenznummer',
	RECORD_SEQUENCE_NUMBER_DESC: 'Sequenznummer des Datensatzes in der übergeordneten Sitzung, falls der Datensatz eine Teilsitzung darstellt',
	CHARGINGID_KEY: 'ChargingID',
	CHARGINGID_DESC: 'Identifikator der Sitzung, auf die ein Nutzungsdatensatz zutrifft',
	SESSION_CLOSE_CAUSE_KEY: 'Ursache des Schließens der Sitzung',
	SESSION_CLOSE_CAUSE_DESC: 'Numerischer Code, der die Ursache des Schließens der Sitzung angibt',
	TAP_CODE_KEY: 'TAP-Code',
	TAP_CODE_DESC: 'Ein TAP-Code für das besuchte Netzwerk',
	OPERATOR_ACCOUND_ID_KEY: 'Bedienerkonto-ID',
	OPERATOR_ACCOUND_ID_DESC: 'Vom Bediener zur Identifizierung eines Kontos verwendete ID',
	RATE_PLAN_VERSION_ID_KEY: 'Tarifplan-Versions-ID',
	RATE_PLAN_VERSION_ID_DESC: 'Spezifische Version des Tarifplans, wird zu diesem Zeitpunkt für Abrechnungszwecke verwendet',
	STREAM_ID_KEY: 'Stream-ID',
	STREAM_ID_DESC: 'Numerischer Identifikator zur Beschreibung der verschiedenen durch GGSN/PGW identifizierten Datenströme',
	CGI_KEY: 'CGI (Cell Global Identifier)',
	CGI_DESC: 'Teil der Standortinformationen gemäß Beschreibung in TS29.274',
	SAI_KEY: 'SAI (Service Area Identifier)',
	SAI_DESC: 'Teil der Standortinformationen gemäß Beschreibung in TS29.274',
	RAI_KEY: 'RAI (Routing Area Identifier)',
	RAI_DESC: 'Teil der Standortinformationen gemäß Beschreibung in TS29.274',
	TAI_KEY: 'TAI (Tracking Area Identity)',
	TAI_DESC: 'Teil der Standortinformationen gemäß Beschreibung in TS29.274',
	ECGI_KEY: 'ECGI(E-UTRAN CGI)',
	ECGI_DESC: 'Teil der Standortinformationen gemäß Beschreibung in TS29.274',
	LAI_KEY: 'LAI (Location Area Identifier)',
	LAI_DESC: 'Teil der Standortinformationen gemäß Beschreibung in TS29.274',
	SERVING_SGSN_KEY: 'Serving-SGSN',
	SERVING_SGSN_DESC: 'Adresse des Serving-Switch',
	CALL_TECHNOLOGY_TYPE_KEY: 'Anruftechnologietyp',
	CALL_TECHNOLOGY_TYPE_DESC: 'Numerischer Identifikator zur Identifizierung der für diese Nutzung verwendeten Funkzugriffstechnologie',

	// NUTZUNGS-SMS-DETAIL - SCHLÜSSEL UND BESCHR.
	SMS_TRAFFIC_DETAIL_ID_KEY: 'Datentraffic-Detail-ID',
	SMS_TRAFFIC_DETAIL_ID_DESC: 'Eindeutiger Identifikator für Datennutzungs-Datensätze',
	// 'ICCID_KEY'
	// 'ICCID_DESC'
	// 'MSISDN_KEY'
	// 'MSISDN_DESC'
	// 'IMSI_KEY'
	// 'IMSI_DESC'
	// 'ACCOUNT_ID_KEY'
	// 'ACCOUNT_ID_DESC'
	// 'BILLABLE_FLAG_KEY'
	// 'BILLABLE_FLAG_DESC'
	// 'BILLING_CYCLE_KEY'
	// 'BILLING_CYCLE_DESC'
	// 'SIM_STATE_KEY'
	// 'SIM_STATE_DESC'
	// 'SERVICE_TYPE_KEY'
	// 'SERVICE_TYPE_DESC'
	// 'ASSIGNED_RATE_PLAN_ID_KEY'
	// 'ASSIGNED_RATE_PLAN_ID_DESC'
	// 'ASSIGNED_RATING_ZONE_ID_KEY'
	// 'ASSIGNED_RATING_ZONE_ID_DESC'
	RECORD_OPEN_TIME_SMS_KEY: 'Datensatz-Eröffnungszeit',
	RECORD_OPEN_TIME_SMS_DESC: 'Datum/Uhrzeit des Sendens einer MO SMS-Nachricht; Datum/Uhrzeit des Empfangs einer MT-Nachricht',
	// 'RECORD_RECEIVED_DATE_KEY': 'Empfangsdatum Datensatz',
	// 'RECORD_RECEIVED_DATE_DESC': 'Datum/Uhrzeit des Empfangs des Abrechnungsdatensatzes',
	NETWORK_NODE_KEY: 'Netzwerkknoten',
	NETWORK_NODE_DESC: 'Adresse des Serving-Netzwerkknotens für die Nachricht',
	FROM_KEY: 'Von',
	FROM_DESC: 'Quell-MSISDN oder Kurzcode',
	TO_KEY: 'Bis',
	TO_DESC: 'Ziel-MSISDN oder Kurzcode',
	// 'OPERATOR_NETWORK_KEY': 'Bedienernetzwerk',
	// 'OPERATOR_NETWORK_DESC': 'Das besuchte Bedienernetzwerk, in dem die Nutzung stattgefunden hat',
	// 'TAP_CODE_KEY': 'TAP-Code',
	// 'TAP_CODE_DESC': 'Ein TAP-Code für das besuchte Netzwerk',
	// 'OPERATOR_ACCOUND_ID_KEY': 'Bedienerkonto-ID',
	// 'OPERATOR_ACCOUND_ID_DESC': 'Vom Bediener zur Identifizierung eines Kontos verwendete ID',
	// 'RATE_PLAN_VERSION_ID_KEY': 'Tarifplan-Versions-ID',
	// 'RATE_PLAN_VERSION_ID_DESC': 'Spezifische Version des Tarifplans,
	// zu diesem Zeitpunkt für Abrechnungszwecke verwendet“,

	// GEPOOLTE NUTZUNG - SCHLÜSSEL UND BESCHR.
	// 'ACCOUNT_ID_KEY'
	// 'ACCOUNT_ID_DESC
	// 'BILLING_CYCLE_KEY' />,
	// 'BILLING_CYCLE_DESC' />
	INVOICE_ID_KEY: 'Rechnungs-ID',
	INVOICE_ID_DESC: 'ID der Rechnung, auf die die Gebühren zutreffen',
	RATE_PLAN_ID_KEY: 'Tarifplan-ID',
	RATE_PLAN_ID_DESC: 'Der SIM am Ende des Berichtszeitraums zugewiesene ID des Tarifplans',
	RATING_ZONE_ID_KEY: 'Bewertungszonen-ID',
	RATING_ZONE_ID_DESC: 'Bewertungszone (im Tarifplan definiert), in der die Nutzung stattgefunden hat',
	TOTAL_DATA_USAGE_CHARGES_KEY: 'Gesamtdatennutzungsgebühren',
	TOTAL_DATA_USAGE_CHARGES_DESC: 'Gesamtdatennutzungsgebühren in dieser Bewertungszone zusätzlich zur gesamten gepoolten Datennutzung, die in der Abonnementgebühr enthalten ist',
	TOTAL_SMS_MO_USAGE_CHARGES_KEY: 'Gesamt-SMS-MO-Nutzungsgebühren',
	TOTAL_SMS_MO_USAGE_CHARGES_DESC: 'Gesamt-SMS-MO-Nutzungsgebühren in dieser Bewertungszone zusätzlich zur gesamten gepoolten Datennutzung, die in der Abonnementgebühr enthalten ist',
	TOTAL_SMS_MT_USAGE_CHARGES_KEY: 'Gesamt-SMS-MT-Nutzungsgebühren',
	TOTAL_SMS_MT_USAGE_CHARGES_DESC: 'Gesamt-SMS-MT-Nutzungsgebühren in dieser Bewertungszone zusätzlich zur gesamten gepoolten Datennutzung, die in der Abonnementgebühr enthalten ist',
	TOTAL_SMS_USAGE_CHARGES_KEY: 'Gesamt-SMS-Nutzungsgebühren',
	TOTAL_SMS_USAGE_CHARGES_DESC: 'Gesamt-SMS-Nutzungsgebühren in dieser Bewertungszone zusätzlich zur gesamten gepoolten Datennutzung, die in der Abonnementgebühr enthalten ist',
	TOTAL_VOICE_MO_USAGE_CHARGES_KEY: 'Gesamt-Voice-MO-Nutzungsgebühren',
	TOTAL_VOICE_MO_USAGE_CHARGES_DESC: 'Gesamt-VOICE-MO-Nutzungsgebühren in dieser Bewertungszone zusätzlich zur gesamten gepoolten Datennutzung, die in der Abonnementgebühr enthalten ist',
	TOTAL_VOICE_MT_USAGE_CHARGES_KEY: 'Gesamt-Voice-MT-Nutzungsgebühren',
	TOTAL_VOICE_MT_USAGE_CHARGES_DESC: 'Gesamt-VOICE-MT-Nutzungsgebühren in dieser Bewertungszone zusätzlich zur gesamten gepoolten Datennutzung, die in der Abonnementgebühr enthalten ist',
	TOTAL_USAGE_CHARGES_KEY: 'Gesamtnutzungsgebühren',
	TOTAL_USAGE_CHARGES_DESC: 'Gesamtgebühren für die Nutzung in dieser Bewertungszone zusätzlich zur gesamten gepoolten Datennutzung, die in der Abonnementgebühr enthalten ist',

	// NUTZUNGSÜBERBLICK - SCHLÜSSEL UND BESCHR.
	// 'ACCOUNT_ID_KEY'
	// 'ACCOUNT_ID_DESC'
	OPERATOR_ID_KEY: 'Bediener-ID',
	OPERATOR_ID_DESC: 'Jasper-Bediener-ID-Nummer',
	// 'BILLING_CYCLE_KEY'
	// 'BILLING_CYCLE_DESC'
	// 'ICCID_KEY'
	// 'ICCID_DESC'
	// 'IMSI_KEY'
	// 'IMSI_DESC'
	// 'MSISDN_KEY'
	// 'MSISDN_DESC'
	TOTAL_DATA_USAGE_KEY: 'Gesamtdatennutzung',
	TOTAL_DATA_USAGE_DESC: 'Gesamtdatennutzung für den Abrechnungszyklus für dieses Gerät',
	TOTAL_VOICE_USAGE_KEY: 'Gesamt-Voice-Nutzung',
	TOTAL_VOICE_USAGE_DESC: 'Gesamt-Voice-Nutzung für den Abrechnungszyklus für dieses Gerät',
	TOTAL_SMS_USAGE_KEY: 'Gesamt-SMS-Nutzung',
	TOTAL_SMS_USAGE_DESC: 'Gesamt-SMS-Nutzung für den Abrechnungszyklus für dieses Gerät',
	TOTAL_CSD_USAGE_KEY: 'Gesamt-CSD-Nutzung',
	TOTAL_CSD_USAGE_DESC: 'Gesamt-CSD-Nutzung für den Abrechnungszyklus für dieses Gerät',
	RESERVED_FIELD_KEY: 'Reserviertes Feld',
	RESERVED_FIELD_DESC: 'Für die zukünftige Verwendung reserviert',
	DEVICE_ID_KEY: 'Geräte-ID',
	DEVICE_ID_DESC: 'Benutzerdefiniertes, für Bediener verfügbares Feld',
	MODEM_ID_KEY: 'Modem-ID',
	MODEM_ID_DESC: 'Benutzerdefiniertes, für Bediener verfügbares Feld',
	END_CONSUMER_ID_KEY: 'Endverbraucher-ID',
	END_CONSUMER_ID_DESC: 'Identifikator für den Endverbraucher',
	USAGE_CUSTOMER_KEY: 'Kunde',
	USAGE_CUSTOMER_DESC: 'Kundenname',
	CUSTOM1_KEY: 'Benutzerdef. 1',
	CUSTOM1_DEVICE_DESC: 'Benutzerdefiniertes Feld 1 auf Geräteebene',
	CUSTOM2_KEY: 'Benutzerdef. 2',
	CUSTOM2_DEVICE_DESC: 'Benutzerdefiniertes Feld 1 auf Geräteebene',
	CUSTOM3_KEY: 'Benutzerdef. 3',
	CUSTOM3_DEVICE_DESC: 'Benutzerdefiniertes Feld 1 auf Geräteebene',

	// ABONNENTEN-SNAPSHOT - SCHLÜSSEL UND BESCHR.
	SUBSCRIBER_SIM_STATE_KEY: 'SIM-Status',
	SUBSCRIBER_SIM_STATE_DESC: 'SIM-Status am Ende des Berichtszeitraums',
	// 'RATE_PLAN_ID_KEY'
	SUBSCRIBER_RATE_PLAN_ID_DESC: 'Der SIM am Ende des Berichtszeitraums zugewiesener Tarifplan',
	SUBSCRIBER_CUSTOMER_KEY: 'Kunde',
	SUBSCRIBER_CUSTOMER_DESC: 'Der SIM am Ende des Berichtszeitraums zugewiesener Kunde',
	// 'DEVICE_ID_KEY',
	SUBSCRIBER_DEVICE_ID_DESC: 'Wert am Ende des Berichtszeitraums',
	// 'MODEM_ID_KEY'

	SUBSCRIBER_MODEM_ID_DESC: 'Wert am Ende des Berichtszeitraums',
	COMMUNICATION_PLAN_ID_KEY: 'Kommunikationsplan-ID',
	COMMUNICATION_PLAN_ID_DESC: 'Wert am Ende des Berichtszeitraums',
	INITIAL_ACTIVATION_DATE_KEY: 'Datum erste Aktivierung',
	INITIAL_ACTIVATION_DATE_DESC: 'Datum und Uhrzeit der erstmaligen Aktivierung der SIM im aktuellen Konto',
	CUSTOM_SIM_DESC: 'Benutzerdefiniertes, für Konten verfügbares SIM-Ebenen-Feld',
	LINE_PAYMENT_STATUS_KEY: 'Leitungszahlungsstatus',
	LINE_PAYMENT_STATUS_DESC: 'Mögliche Werte sind Aktuell, Suspendiert, Abgebrochen, Endgültige Stornierung',
	OPERATOR_ACCOUNT_ID_KEY: 'Bedienerkonto-ID',
	OPERATOR_ACCOUNT_ID_DESC: 'Vom Bediener zur Identifizierung eines Kontos verwendete ID',
	CUSTOM4_KEY: 'Benutzerdef. 4',
	CUSTOM5_KEY: 'Benutzerdef. 5',
	CUSTOM6_KEY: 'Benutzerdef. 6',
	CUSTOM7_KEY: 'Benutzerdef. 7',
	CUSTOM8_KEY: 'Benutzerdef. 8',
	CUSTOM9_KEY: 'Benutzerdef. 9',
	CUSTOM10_KEY: 'Benutzerdef. 10',

	ICCID_DESC_SHORT2: 'Geräte-ICCID',
	IMSI_DESC_SHORT2: 'Geräte-IMSI',
	RATE_PLAN_KEY: 'Tarifplan',
	RATE_PLAN_DESC: 'EOD plan_code, Control Center ICCID leer',
	SIM_STATE_KEY2: 'Simstate',
	SIM_STATE_DESC2: 'AKTIV, INAKTIV usw., falls verfügbar',
	BEGBILLINGCYCLEDATE_KEY: 'Begbillcycledate',
	BEGBILLINGCYCLEDATE_DESC: 'Datum des Beginns des Abrechnungszyklus falls EOD, leer falls Control Center',
	ENDBILLINGCYCLEDATE_KEY: 'Endbillcycledate',
	ENDBILLINGCYCLEDATE_DESC: 'Datum des Endes des Abrechnungszyklus falls EOD, leer falls Control Center',
	TOTALDATAUSAGE_KEY: 'Totaldatausage',
	TOTALDATAUSAGE_DESC: 'Datennutzung im Zeitraum in Kilobyte',
	TOTALINTERNATIONALUSAGE_KEY: 'Totalinternationalusage',
	TOTALINTERNATIONALUSAGE_DESC: 'Internationale Datennutzung im Zeitraum in Kilobyte falls EOD, leer falls Control Center',
	CUSTOMERNAME_KEY: 'Customername',
	CUSTOMERNAME_DESC: 'Name des Kunden',
	CUSTOMFIELD1_KEY: 'Customfield1',
	CUSTOMERNAME1_DESC: 'Kann von EOD und Control Center populiert werden',
	CUSTOMFIELD2_KEY: 'Customfield2',
	CUSTOMERNAME2_DESC: 'Kann von EOD und Control Center populiert werden',
	CUSTOMFIELD3_KEY: 'Customfield3',
	CUSTOMERNAME3_DESC: 'Kann von EOD und Control Center populiert werden',
	CUSTOMFIELD4_KEY: 'Customfield4',
	CUSTOMERNAME4_DESC: 'EOD- oder Control Center-Geräte-ID',
	CUSTOMFIELD5_KEY: 'Customfield5',
	CUSTOMERNAME5_DESC: 'EOD- oder Control Center-Modem-ID',
	M2MPLATFORM_KEY: 'M2MPlatform',
	M2MPLATFORM_DESC: '„EOD“ oder „Control Center“',
	BILLINGCYCLE_KEY: 'Billingcycle',
	BILLINGCYCLE_DESC: 'Zu populierendes Feld',
	CUSTOMERID_KEY: 'Customerid',
	CUSTOMERID_DESC: 'Für EOD Kundenpräfix; für Control Center Kunden-ID',
	PROVISIONINGACTIVITY_KEY: 'ProvisioningActivity ',
	PROVISIONINGACTIVITY_DESC: '„Aktivierung“, „inaktiv“, „beenden“, „changeNetworkEntitlement“, „changeRatePlan“ oder „updateCustomFields“',
	COUNT_KEY: 'Anzahl',
	COUNT_DESC: 'Anzahl der Aufrufe der Bereitstellungsaktivität',
	SUCCESS_KEY: 'Erfolg',
	SUCCESS_DESC: 'Anzahl der erfolgreichen Aufrufe der Bereitstellungsaktivität',
	FAILURE_KEY: 'Kein Erfolg',
	FAILURE_DESC: 'Anzahl der erfolglosen Aufrufe der Bereitstellungsaktivität',
	AVGTIMEMS_KEY: 'AvgTimeMs',
	AVGTIMEMS_DESC: 'Dauer des durchschnittlichen Aufrufs, in Millisekunden',
	LONGESTTIMEMS_KEY: 'LongestTimeMs',
	LONGESTTIMEMS_DESC: 'Dauer des langsamsten Aufrufs der Bereitstellungsaktivität, in Millisekunden',
	SHORTESTTIMEMS_KEY: 'ShortestTimeMs',
	SHORTESTTIMEMS_DESC: 'Dauer des schnellsten Aufrufs der Bereitstellungsaktivität, in Millisekunden',

	REPORT_SOURCE_DESC: 'Berichtsquelle auswählen, um andere Definitionen zu sehen',

	SIM_SWAP_SUCCESSFUL: 'SIM-Austausch erfolgreich',
	SIM_SWAP_IN_PROGRESS: 'SIM-Austausch findet statt',
	REFRESH_PENDING_STATUS: 'Endpunkt aktualisieren, um zu prüfen, ob Status geändert wurde',
	TEMPLATE_NOT_AVAILABLE: 'Vorlage nicht verfügbar.',
	CUSTOM_FIELDS_UPDATE_SUCCESSFUL: 'Benutzerdefinierte Felder erfolgreich aktualisiert',
	CUSTOM_FIELDS_UPDATE_IN_PROGRESS: 'Aktualisierung benutzerdefinierter Felder findet statt',
	IOT_APPS: 'IOT-APPS',
	INVOICES_DATE: 'Rechnungsdatum',
	PLATFORM: 'Plattform',
	FREQUENCY: 'Frequenz',
	M2M_ACC_ID: 'M2M-Konto-ID',
	REPORT: 'Bericht',

	// Onboarding
	ADD_NEW_PLATFORM: 'Neue Plattform hinzufügen',
	BUSINESS_NAME: 'Unternehmensname',
	ADMINISTRATOR: 'Administrator',
	MCC: 'MCC',
	MNC: 'IoT Console (IOT-C)',
	OPERATOR: 'Bediener',
	POD: 'POD',
	RATE_PLANS: 'Tarifpläne',
	RATE_PLAN_NAME: 'Tarifplanname',
	RATE_PLAN_ID: 'Tarifplan',
	RATE_PLAN_ID_PLAN: 'Tarifplan-ID',
	COMMUNICATION_PLANE_NAME: 'Kommunikationsplanname',
	ADD_ANOTHER_RATE_PLAN: 'Weiteren Tarifplan hinzufügen',
	ADD_ANOTHER_COMMUNICATION_PLAN: 'Weiteren Kommunikationsplan hinzufügen',
	ADD_ANOTHER_PROFILE_NAME: 'Weiteren Profilnamen hinzufügen',
	SIM_STATE_MAPPING: 'SIM-Statuszuordnungen',
	ADD_RATE_PLAN: 'Tarifplan hinzufügen',
	ACC_RULE: 'ACC-Regel',
	MC_RULE: 'IOT-C-Regel',
	WHAT_IS_THIS_TITLE: 'Was ist das?',
	PLAN_PROFILES: 'Planprofile',
	COMPANY: 'Unternehmen',
	COMPANY_NAME: 'Unternehmensname',
	COMPANY_DOMAIN: 'Unternehmensdomäne',
	PHONE_NUMBER: 'Telefonnummer',
	CITY: 'Ort',
	CITY_TOWN: 'Ort',
	STATE_PROVINCE: 'Bundesland/Kanton',
	COUNTRY: 'Land',
	ZIP_CODE: 'Postleitzahl',
	ZIP_POSTAL_CODE: 'Postleitzahl',
	STREET: 'Straße',
	STREET_ADDRESS: 'Straße/Hausnr.',
	LAST_NAME: 'Nachname',
	FIRST_NAME: 'Vorname',
	ACTIONS: 'Aktionen',
	PLEASE_SELECT_COUPLE_OF_SIMS: 'Bitte wählen Sie einige SIMS aus.',
	COMPANY_IDENTITY: 'Unternehmensidentität',
	ADD_USERS_OPTIONAL: 'Benutzer hinzufügen (optional)',
	REVIEW_AND_CREATE: 'Prüfen und erstellen',
	PROJECTS_LIST: 'Projektliste',
	MULTI_NETWORK_CONNECT_ONBOARDING_M2M_PLATFORMS: 'IoT Console Onboarding - M2M-Plattformen',
	MULTI_NETWORK_CONNECT: 'IoT Console',
	CUSTOMERS: 'Kunden',
	COMPANY_EMAIL_ADDRESS: 'Unternehmens-E-Mail-Adresse',
	CONTACT_EMAIL_ADDRESS: 'Kontakt-E-Mail-Adresse',
	CONTACT_PERSON_FULL_NAME: 'Vollständiger Name Kontaktperson',
	CONTACT_PERSON: 'Kontaktperson',
	ASSIGN_ANOTHER_PLATFORM: 'Weitere Plattform zuweisen',
	USER_INFO: 'Benutzerinfo',
	SELECT_USER_TYPE: 'Benutzertyp auswählen',
	USER_TYPE: 'Benutzertyp',
	USER_NOTE: 'Der Benutzer erhält eine Einladungs-E-Mail, um sein Konto zu erstellen',
	ADD_USER: 'Benutzer hinzufügen',
	ADD_ANOTHER_USER: 'Weiteren Benutzer hinzufügen',
	WHAT_IS_THIS_MESSAGE1: 'Das Multi-Network Connect-Konto kann erst erstellt werden, wenn der Kunde in die M2M-Plattformen geladen wurde. Wenn Sie die Konto-ID haben, können Sie fortfahren.',
	WHAT_IS_THIS_MESSAGE2: 'Kleinunternehmenskunden und Startups, die IoT-Datenpläne oder IoT-IoT-Starter-Kits verwenden, können durch Ausfüllen dieses Formulars automatisch hinzugefügt werden. Kehren Sie nach der Kontoerstellung hierher zurück, um den Kundenzugriff auf das IoT Console-Portal zu aktivieren.',
	SELECT_PLATFORM: 'Plattform auswählen',
	EMAIL_EXISTS_OTHER: 'Diese E-Mail-Adresse ist bereits vorhanden (anderer Benutzer)',
	EMAIL_EXISTS_ADMIN: 'Diese E-Mail-Adresse ist bereits vorhanden (Administrator)',
	YOU_HAVE_SUCCESSFULLY_CREATED_A_MULTI_NETWORK_CONNECT_ACCOUNT_FOR: 'Sie haben erfolgreich ein IoT Console-Konto erstellt für:',
	THE_IOT_GATEWAY_NUMBER_ASSOCIATED_WITH_THIS_ACCOUNT: 'Die diesem Konto zugeordnete IoT-Gateway-Nummer:',
	COMMUNICATION_PROFILE_NAME: 'Kommunikationsprofilname',
	ADD_NETWORK_ENTITLEMENT: 'Netzwerkanspruch hinzufügen',
	EDIT_NETWORK_ENTITLEMENT: 'Netzwerkanspruch bearbeiten',
	PLATFORMS: 'Plattformen',
	NO_NETWORK_ENTITLEMENTS: 'Keine Netzwerkansprüche',
	NO_RATE_PLANS: 'Keine Tarifpläne',
	EDIT_RATE_PLAN: 'Tarifplan bearbeiten',
	ERROR_WHILE_ADDING_NETWORK_ENTITLEMENT: 'Fehler beim Hinzufügen des Netzwerkanspruchs',
	ERROR_WHILE_EDITING_NETWORK_ENTITLEMENT: 'Fehler beim Bearbeiten des Netzwerkanspruchs',
	ERROR_WHILE_ADDING_RATE_PLAN: 'Fehler beim Hinzufügen des Tarifplans',
	ERROR_WHILE_EDITING_RATE_PLAN: 'Fehler beim Bearbeiten des Tarifplans',
	ERROR_WHILE_ADDING_COMPANY: 'Fehler beim Hinzufügen des Unternehmens',
	ERROR_WHILE_EDITING_COMPANY: 'Fehler beim Bearbeiten des Unternehmens',
	ERROR_WHILE_ADDING_EDITING_USER: 'Fehler beim Hinzufügen/Bearbeiten des Benutzers',
	ERROR_WHILE_ADDING_EDITING_PLATFORM: 'Fehler beim Hinzufügen/Bearbeiten der Plattform',
	ERROR_WHILE_ADDING_PLATFORM: 'Fehler beim Hinzufügen der Plattform',
	ADD_PROFILE_NAME: 'Profilnamen hinzufügen',
	ADD_PROFILE_ID: 'Profil-ID hinzufügen',
	EDIT_PLATFORM: 'Plattform bearbeiten',
	SIM_STATE_CHANGE_IS_SUCCESSFULLY_SCHEDULED: 'SIM-Statusänderung erfolgreich eingeplant!',
	SIM_STATE_CHANGE_IS_SUCCESSFULLY_SCHEDULED_FOR: 'SIM-Statusänderung erfolgreich eingeplant für',
	SIM_STATE_CHANGE_IS_SUCCESSFULLY_SCHEDULED_ERROR: 'SIM-Statusänderung konnte nicht erfolgreich eingeplant werden!',
	SIM_STATE_CHANGED_TO: 'Sie ändern den SIM-Status auf',
	CHANGE_LATER: 'Sie können die Änderung auch für später einplanen.',
	SCHEDULED_CHANGE_WILL_HAPPEN: 'Geplante Änderung findet zu Beginn des Datums Ihrer Wahl statt.',
	TAP_INITIATE_CHANGE: 'Wählen Sie ’Änderung einleiten’, um sofort eine Aktion zu ergreifen.',
	SIM_STATE_CHANGE: 'SIM-Statusänderung',
	SCHEDULE_SIM_STATE_CHANGE: 'SIM-Statusänderung einplanen',
	SYSTEM_CONFIRMATION: 'Systembestätigung',
	CHOOSE_NETWORK_ENTITLEMENT: 'Netzwerkanspruch wählen',
	HISTORY: 'Batch-Verlauf',

	serviceProvider: 'Plattform',
	reportFrequency: 'Frequenz',

	// Onboarding
	ENDPOINT_SESSION_NO_RECORD: 'Keine Daten für den ausgewählten Zeitraum vorhanden.',
	TIME_START: 'Startzeit',
	TIME_END: 'Endzeit',
	SESSION_DETAILS: 'Sitzungsdetails',
	CARRIER: 'Betreiber',
	DEVICE_IP: 'Geräte-IP',

	// Diagramm-Datensätze
	inventoryByStatus: 'Inventar nach Status',
	inventoryByOperator: 'Inventar nach Bediener',
	inventoryByCountry: 'Inventar nach Land',
	inventoryByStatusDescription: 'Damit wird die Anzahl aktiver und inaktiver Endpunkte angezeigt.',
	inventoryByOperatorDescription: 'Damit werden die Bediener sowie die Anzahl der Endpunkte mit jedem Bediener angezeigt.',
	inventoryByCountryDescription: 'Damit werden ein Ländername und die Anzahl der in diesem Land operierenden Endpunkte angezeigt.',
	// prompt modal
	addPromptHeaderInfo: 'Änderung übernehmen?',
	addChartPromptHeader: 'Dashboard-Änderungen wurden noch nicht gespeichert',
	addChartPromptFirst: 'Falls Sie die Änderungen in Ihrer Dashboard-Ansicht speichern möchten, tippen Sie auf „Dashboard speichern“. Klicken Sie ansonsten auf „Abbrechen“, um zur vorherigen Ansicht zurückzukehren.',
	addChartPromptNotification: 'Änderung übernehmen?',

	// DIAGRAMM-Beschreibungen
	DASHBOARD_CHART_countByState_SATELLITEDescription: 'Zeigt die Statusdetails der Satellitenendpunkte (Geräte) für die verschiedenen M2M-Plattformen an',
	DASHBOARD_CHART_countByState_CELLULARDescription: 'Zeigt die Statusdetails der Mobilfunkendpunkte (Geräte) für die verschiedenen M2M-Plattformen an',
	DASHBOARD_CHART_countByNetworkCarrier_SATELLITEDescription: 'Zeigt den prozentualen Anteil der Satellitenendpunkte (Geräte) für die verschiedenen Netzbetreiber an',
	DASHBOARD_CHART_countByNetworkCarrier_CELLULARDescription: 'Zeigt den prozentualen Anteil der Mobilfunkendpunkte (Geräte) für die verschiedenen Netzbetreiber an',
	DASHBOARD_CHART_countByTechnologyDescription: 'Zeigt die Aufstellungssumme nach Prozentsatz für alle Mobilfunk- und Satellitenendpunkte (Geräte) im Konto an',
	DASHBOARD_CHART_countByM2mPlatform_SATELLITEDescription: 'Zeigt Aufstellungssumme nach Prozentsatz für alle Satellitenendpunkte (Geräte) und die verschiedenen M2M-Plattformen an',
	DASHBOARD_CHART_countByM2mPlatform_CELLULARDescription: 'Zeigt die Aufstellungssumme nach Prozentsatz für alle Mobilfunkendpunkte (Geräte) und die verschiedenen M2M-Plattformen an',
	DASHBOARD_CHART_countByIMSI_SATELLITEDescription: 'Zeigt die Aufstellungssumme nach Prozentsatz für alle Mobilfunkendpunkte (Geräte) und die verschiedenen M2M-Plattformen an',
	DASHBOARD_CHART_countByIMSI_CELLULARDescription: 'Zeigt Aufstellungssumme nach Prozentsatz für alle Satellitenendpunkte (Geräte) und die verschiedenen M2M-Plattformen an',
	'DASHBOARD_CHART_IMSI Transfer Status_CELLULARDescription': 'Zeigt Übertragungsstatussummen (Re-IMSI) nach Prozentsatz zwischen zwei M2M-Plattformen an.',
	DASHBOARD_CHART_countByIMSITransferStatus_CELLULARDescription: 'Zeigt Übertragungsstatussummen (Re-IMSI) nach Prozentsatz zwischen zwei M2M-Plattformen an.',
	DASHBOARD_CHART_countByIMSITransferStatus_SATELLITEDescription: 'Zeigt Übertragungsstatussummen (Re-IMSI) nach Prozentsatz zwischen zwei M2M-Plattformen an.',
	DASHBOARD_CHART_summaryOfMonthlyEndpointUsageDescription: 'Zeigt die monatliche Datennutzungszusammenfassung für alle Mobilfunk- und Satellitenendpunkte (Geräte) an',
	DASHBOARD_CHART_countByStatebyPlatformACC_CELLULARDescription: 'Zeigt die Statusdetails der Mobilfunkendpunkte (Geräte) für die ACC-Plattform an',
	DASHBOARD_CHART_countByStatebyPlatformEOD_CELLULARDescription: 'Zeigt die Statusdetails der Mobilfunkendpunkte (Geräte) für die EOD-Plattform an',
	DASHBOARD_CHART_countByStatebyPlatformGBCM_SATELLITEDescription: 'Zeigt die Statusdetails der Satellitenendpunkte (Geräte) für die GBCM-Plattform an',
	DASHBOARD_CHART_countByStatebyPlatformDCP_CELLULARDescription: 'Zeigt die Statusdetails der Mobilfunkendpunkte (Geräte) für die DCP-Plattform an',
	DASHBOARD_CHART_countByStatebyPlatformCCIP_CELLULARDescription: 'Zeigt die Statusdetails der Mobilfunkendpunkte (Geräte) für die CCIP-Plattform an',
	DASHBOARD_CHART_countByStatebyPlatformVCC_CELLULARDescription: 'Zeigt die Statusdetails der Mobilfunkendpunkte (Geräte) für die VCC-Plattform an',
	DASHBOARD_CHART_countByStatebyPlatformGDSP_CELLULARDescription: 'Zeigt die Statusdetails der Mobilfunkendpunkte (Geräte) für die GDSP-Plattform an',
	DASHBOARD_CHART_countByStatebyPlatformWING_CELLULARDescription: 'Zeigt die Statusdetails der Mobilfunkendpunkte (Geräte) für die WING-Plattform an',
	DASHBOARD_CHART_summaryOfMonthlyEndpointUsage_CELLULARDescription: 'Zeigt die monatliche Datennutzungszusammenfassung für alle Mobilfunk- und Satellitenendpunkte (Geräte) an',
	DASHBOARD_CHART_countByProfile_CELLULARDescription: 'Zeigt die Statusdetails der Mobilfunkendpunkte (Geräte) für die verschiedenen Profile an',

	// Benutzerdef. Tooltip-ID für Tortendiagramm
	PIE_CHART_TOOLTIP_Active: 'Aktiviert',
	PIE_CHART_TOOLTIP_Test: 'Test',
	PIE_CHART_TOOLTIP_Inactive: 'Inaktiv',
	PIE_CHART_TOOLTIP_Terminate: 'Beendet',

	// GlobeComm
	DEVICE_MODULE_NUMBER: 'Gerätemodulnummer',
	DEVICE_MANUFACTURER: 'Gerätehersteller',
	DEVICE_STATUS: 'Gerätestatus',
	DEVICE_NETWORK_TYPE: 'Gerätenetzwerktyp',
	MODULE_IMEI: 'Modul-IMEI',
	SIM_STATUS: 'SIM-Status',
	MODULE_STATUS: 'Modulstatus',
	TRESHOLD_OF_NOTIFICATION_BY_DATA_USAGE: 'Schwellenwert der Benachrichtigung nach Datennutzung',
	TRESHOLD_OF_SUSPENSION_BY_DATA_USAGE: 'Schwellenwert der Suspendierung nach Datennutzung',
	NOTIFICATION_INTERVAL: 'Benachrichtigungsintervall',
	DATA_USAGE: 'Datennutzung Zyklus bis dato',
	MANUFACTURER: 'Hersteller',
	MODEL_NUMBER: 'Modellnummer',
	TYPE: 'Typ',
	NETWORK_NAME: 'Netzwerkname',
	SUPPORTED_NETWORKS: 'Unterstützte Netzwerke',
	RENEWAL_PERIOD: 'Verlängerungszeitraum',
	ACTIVATION_FEE: 'Aktivierungsgebühr',
	RECCURING_FEE: 'Laufende Gebühr',
	OVERAGE_FEE: 'Überschreitungsgebühr',
	INCLUDED_BYTES: 'Inbegriffene Byte',
	MINIMUM_BILLABLE_BYTES: 'Mindestwert in Rechnung zu stellende Byte',
	NOTIFICATION_CONTACT: 'Benachrichtigungskontakt',
	NOTIFICATION_BYTE_CAP: 'Benachrichtigungen Byte-Obergrenze',
	NOTIFICATION_REFRESH: 'Benachrichtigungsaktualisierung',
	OBSOLETE: 'Obsolet',
	HARDWARE: 'Hardware',
	SPECIFICATIONS: 'Spezifikationen',
	SPECIFICATION: 'Spezifikation',
	MODULE: 'Modul',
	NO_HISTORY_DATA: 'Keine Verlaufsdatensätze für diesen Endpunkt',

	// Dashboard Standardnachricht
	NO_GRAPHS: 'HIER WERDEN HINZUGEFÜGTE DIAGRAMME ANGEZEIGT',
	dashboardDefault: 'HIER WERDEN HINZUGEFÜGTE DIAGRAMME ANGEZEIGT',
	REMOVE_CHART: 'Diagramm entfernen',
	CARD_DROPDOWN_TITLE: 'Diagrammtyp',
	ADD_WIDGET_BUTTON: 'Diagramm hinzufügen',
	ADD_WIDGET_BUTTON_MOBILE: 'Hinzufügen',

	APP_NAME: 'IoT Console',
	NETWORK_TYPE_CHECK: 'Leider ist die Statusänderung mehrerer Endpunkte gleichzeitig für Endpunkte von verschiedenen Netzwerktypen oder Dienstanbietern nicht gestattet.',
	SERVICE_PROVIDER_CHECK: 'Leider ist die Änderung mehrerer Endpunkte gleichzeitig für Endpunkte von verschiedenen Plattformen oder bei verschiedenen Netzwerkansprüchen nicht gestattet.',

	// BENUTZERFEEDBACK MODAL
	feedbackTitle: 'Benutzerfeedback',
	feedbackPageLabel: 'Ihr Feedback bezieht sich auf',
	feedbackFeedLabel: 'Geben Sie bitte mit eigenen Worten an, wie wir Verbesserungen vornehmen können.',
	feedbackFeedPlaceholder: 'Ihr Feedback kommt hierhin...',
	feedbackContactLabel: 'Dürfen wir Sie bei Bedarf dazu kontaktieren?',
	feedbackEmailLabel: 'E-Mail',
	feedbackPhoneLabel: 'Telefon',
	feedbackMetaLabel: 'Metadaten',
	feedbackCancelButton: 'Abbrechen',
	feedbackSendButton: 'Feedback senden',
	feedbackSuccessThanks: 'Vielen Dank für Ihr Feedback.',
	feedbackSuccessMessage: 'Ihr Feedback wird für ein besseres Produkt sorgen. Wir werden Ihre Anmerkungen sorgfältig evaluieren und entsprechend agieren.',
	feedbackSuccessMessageSub: 'Evtl. kontaktieren wir Sie auch, um weitere Informationen zu Ihrem Vorschlag einzuholen.',
	feedbackSuccessMessageSign: 'IoT Console-Team',
	feedbackSuccessDoneButton: 'Fertig',
	feedbackTriggerTitle: 'Sie haben Feedback?',
	feedbackTriggerSubtitle: 'Teilen Sie es uns hier mit',
	feedbackThankYou: 'Vielen Dank, {user}. Ihr Feedback hilft uns bei der Optimierung unserer Produkte.',
	feedbackSelect: 'Sie müssen das jeweilige Thema aus der Liste oben auswählen.',
	feedbackEnter: 'Sie müssen Feedback eingeben.',
	feedbackIcon: 'Symbol für erfolgreiches Einreichen von Feedback',
	feedbackAdditionalFile: 'Weitere Datei',
	feedbackFileUploadFail: 'Datei-Upload fehlgeschlagen',
	feedbackMinLength: 'Bitte geben Sie mindestens 10 Zeichen ein.',
	FEEDBACK_FILE_UPLOAD_EXTENSIONS: 'Nur Dateien mit der Erweiterung .jpg oder .png hochladen',
	// Änderungen Register

	FIELD_NAME: 'Feldname',

	DELETE_TAB: 'Register löschen',
	TABLE_NAME: 'Tabellenname',
	EDIT_TABLE: 'Tabellenparameter bearbeiten',
	CUSTOM_FIELD_LABEL: 'Benutzerdefinierte Feldbezeichnung',
	EDIT_TABLE_VIEW_NAME: 'Tabellenansichtsname bearbeiten',
	PLEASE_ENTER_TABLE_NAME: 'Bitte Tabellennamen eingeben',
	DEVICE_TYPE: 'Gerätetyp',
	CELLULAR_COLUMNS: 'Mobilfunkspezifische Parameter',
	SATELLITE_SPECIFIC_COLUMNS: 'Satellitenspezifische Parameter',

	// TICKETING
	TICKET_ID: 'Ticket-ID',
	CATEGORY_NAME: 'Kategorie',
	CATEGORY_TICKETING: 'Kategorie',
	SUBCATEGORY_OPTIONAL: 'Unterkategorie (optional)',
	SELECT_SUBCATEGORY_OPTIONAL: 'Unterkategorie auswählen (optional)',
	ROAMING_ISSUE: 'Roaming-Problem',
	SEVERITY: 'Schweregrad',
	PRIORITY: 'Priorität',
	ACCOUNT_NUMBER: 'Kontonummer',
	CREATED_BY_TICKETING: 'Erstellt von',
	MULTI_NETWORK_CONNECT_ID: 'IoT Console-ID',
	ENDPOINT_MODEL: 'Endpunktmodell',
	WHAT_IS_THE_ERROR_MESSAGE: 'Wie lautet die Fehlermeldung?',
	HOW_MANY_BARS_ARE_SHOWING: 'Wie viele Balken werden angezeigt?',
	DESCRIBE_ENDPOINT_STATE_HERE: 'Endpunktstsatus hier beschreiben ...',
	WAS_THE_TROUBLESHOOTING_DONE: 'Wurde eine Fehlersuche durchgeführt?',
	DESCRIBE_TROUBLESHOOTING_DETAILS_HERE: 'Fehlersuchdetails hier beschreiben …',
	DO_YOU_HAVE_ANY_ADDITIONAL_NOTES: 'Haben Sie weitere Anmerkungen?',
	ADDITIONAL_NOTES: 'Weitere Anmerkungen',
	DASHBOARD_CHART_M2MSIMStatus_SATELLITE: 'Endpunkte nach IOT-C-Status (Satellit)',
	DASHBOARD_CHART_M2MSIMStatus_CELLULAR: 'Endpunkte nach IOT-C-Status (Mobilfunk)',
	DASHBOARD_CHART_Country_SATELLITE: 'Endpunkte nach aktuellem Standort (Satellit)',
	DASHBOARD_CHART_Countrys_CELLULAR: 'Endpunkte nach aktuellem Standort (Mobilfunk)',
	DASHBOARD_CHART_IMSI_SATELLITE: 'Endpunkte nach M2M-Plattform (Satellit)',
	DASHBOARD_CHART_IMSI_CELLULAR: 'Endpunkte nach M2M-Plattform (Mobilfunk)',
	DASHBOARD_CHART_Network_SATELLITE: 'Endpunkte nach Netzbetreiber (Satellit)',
	DASHBOARD_CHART_Network_CELLULAR: 'Endpunkte nach Netzbetreiber (Mobilfunk)',
	DASHBOARD_CHART_Technology: 'Endpunkte nach Technologie',
	'DASHBOARD_CHART_IMSI Transfer Status_SATELLITE': 'Endpunkte nach Übertragungsstatus (Satellit)',
	'DASHBOARD_CHART_IMSI Transfer Status_CELLULAR': 'Endpunkte nach Übertragungsstatus (Mobilfunk)',
	DASHBOARD_CHART_countByIMSITransferStatus_SATELLITE: 'Endpunkte nach Übertragungsstatus (Satellit)',
	DASHBOARD_CHART_countByIMSITransferStatus_CELLULAR: 'Endpunkte nach Übertragungsstatus (Mobilfunk)',
	DASHBOARD_CHART_summaryOfMonthlyEndpointUsage_CELLULAR: 'Gesamtdatennutzung für alle ACC-Endpunkte',
	DASHBOARD_CHART_countByState_SATELLITE: 'Endpunkte nach Status (Satellit)',
	DASHBOARD_CHART_countByState_CELLULAR: 'Endpunkte nach Status (Mobilfunk)',
	DASHBOARD_CHART_countByServiceType_SATELLITE: 'Endpunkte nach Technologie (Satellit)',
	DASHBOARD_CHART_countByServiceType_CELLULAR: 'Endpunkte nach Technologie (Mobilfunk)',
	DASHBOARD_CHART_countByRatePlan_SATELLITE: 'Endpunkte nach Tarifplan (Satellit)',
	DASHBOARD_CHART_countByRatePlan_CELLULAR: 'Endpunkte nach Tarifplan (Mobilfunk)',
	DASHBOARD_CHART_countByNetworkEntitlement_SATELLITE: 'Endpunkte nach Netzwerkanspruch (Satellit)',
	DASHBOARD_CHART_countByNetworkEntitlement_CELLULAR: 'Endpunkte nach Netzwerkanspruch (Mobilfunk)',
	DASHBOARD_CHART_countByNetworkCarrier_SATELLITE: 'Endpunkte nach Netzbetreiber (Satellit)',
	DASHBOARD_CHART_countByNetworkCarrier_CELLULAR: 'Endpunkte nach Netzbetreiber (Mobilfunk)',
	DASHBOARD_CHART_countByTechnology: 'Endpunkte nach Technologie',
	DASHBOARD_CHART_countByM2MPlatform_SATELLITE: 'Endpunkte nach M2M-Plattform (Satellit)',
	DASHBOARD_CHART_countByM2MPlatform_CELLULAR: 'Endpunkte nach M2M-Plattform (Mobilfunk)',
	DASHBOARD_CHART_countByIMSI_SATELLITE: 'Endpunkte nach M2M-Plattform',
	DASHBOARD_CHART_countByIMSI_CELLULAR: 'Endpunkte nach M2M-Plattform',
	DASHBOARD_CHART_countByM2mPlatform_SATELLITE: 'Endpunkte nach M2M-Plattform',
	DASHBOARD_CHART_countByM2mPlatform_CELLULAR: 'Endpunkte nach M2M-Plattform',
	DASHBOARD_CHART_countByStatebyPlatformACC_CELLULAR: 'Endpunkte nach Status (ACC)',
	DASHBOARD_CHART_countByStatebyPlatformEOD_CELLULAR: 'Endpunkte nach Status (EOD)',
	DASHBOARD_CHART_countByStatebyPlatformGBCM_SATELLITE: 'Endpunkte nach Status (GBCM)',
	DASHBOARD_CHART_countByStatebyPlatformDCP_CELLULAR: 'Endpunkte nach Status (DCP)',
	DASHBOARD_CHART_countByStatebyPlatformCCIP_CELLULAR: 'Endpunkte nach Status (CCIP)',
	DASHBOARD_CHART_countByStatebyPlatformVCC_CELLULAR: 'Endpunkte nach Status (VCC)',
	DASHBOARD_CHART_countByStatebyPlatformGDSP_CELLULAR: 'Endpunkte nach Status (GDSP)',
	DASHBOARD_CHART_countByStatebyPlatformWING_CELLULAR: 'Endpunkte nach Status (WING)',
	DASHBOARD_CHART_countByProfile_CELLULAR: 'Endpunkte nach Profil',
	TICKET_STATUS: 'Ticket-Status',
	ENDPOINT_LOCATION: 'Endpunkt Standort',
	ISSUE: 'Problem',
	SUGGESTIONS: 'Empfehlungen',
	PHONE: 'Telefon',
	FAILED_TO_PROVISION: 'Bereitstellung nicht erfolgt',
	SUBMITTED_BY: 'Abgesendet von',
	NEW_TICKET: 'Neues Ticket',
	ENDPOINT: 'Endpunkt',
	ERROR_MESSAGE: 'Fehlermeldung',
	ATTACHED_FILE: 'Angehängte Datei',
	TICKETING: 'Ticketing',
	GET_HELP_WITH_THIS_ENDPOINT: 'Für die Verwendung mit diesem Endpunkt Hilfe anfordern',
	CATEGORY_AND_SUGGESTIONS: 'Kategorie und Empfehlungen',
	CONFIRMATION: 'Bestätigung',
	RECORDED_STATUS: 'Aufgezeichneter Status',
	ENDPOINT_NAME: 'Endpunkt-ID',
	CONTACT_PHONE: 'Telefon Kontakt',
	CONTACT_EMAIL: 'E-Mail Kontakt',
	SELECT_SUBCATEGORY: 'Unterkategorie auswählen',
	FILE_UPLOADED: 'Datei hochgeladen',
	FAQ_SUPPORT_INFO: 'Finden Sie schnell Antworten auf häufig gestellte Fragen unserer Benutzer.',
	USER_GUIDE_SUPPORT_INFO: 'Lesen Sie detaillierte, schrittweise Anleitungen zu den Portalfunktionen. Die Anleitung ist speziell auf Ihren Kontotyp zugeschnitten.',
	TICKETING_SUPPORT_INFO: 'Falls es zu Serviceproblemen kommt und Diagnosetests keinen Lösungspfad ergeben, können Sie ein Fehlerticket erstellen. ',
	MUST_BE: 'Muss sein: ',
	LESS_THAN: 'kleiner als ',
	CHARACTERS: ' Zeichen',
	LOADING_DATA: 'Daten werden geladen',
	GPRS: 'GPRS',

	//  Support
	CONTENT: 'Inhalt',
	CREATE_NEW_NOTIFICATION: 'Neue Benachrichtigung erstellen',
	EDIT_NOTIFICATION: 'Benachrichtigung bearbeiten',
	EDIT_FORM_TO_CREATE_NOTIFICATION: 'Formular bearbeiten, um neue Benachrichtigung zu erstellen.',
	NOTIFICATION_TYPE: 'Benachrichtigungstyp?',
	SELECT_NOTIFICATION_PLACEHOLDER: 'Benachrichtigungsplatzhalter auswählen',
	CHOOSE_WHICH_USER_GROUPS_TO_NOTIFY: 'Wählen Sie, welche Benutzergruppen benachrichtigt werden:',
	NOTIFY_ALL_GROUPS: 'Alle Gruppen benachrichtigen',
	MANAGER: 'Manager',
	DEVELOPER: 'Entwickler',
	ADD_NOTIFICATION_CONTENT: 'Benachrichtigungsinhalt hinzufügen',
	SUSPEND: 'Suspendieren',
	SUSPENDED: 'Suspendiert',
	DEACTIVE: 'Inaktiv',
	PROVISIONED: 'Bereitgestellt',
	ENDPOINT_COUNTRY: 'Endpunkt Land',
	ENDPOINT_STATE: 'Endpunkt Bundesland',
	ENDPOINT_CITY: 'Endpunkt Stadt',
	ENDPOINT_STREET_ADDRESS: 'Endpunkt Straße/Hausnr.',
	AS_OF: 'Verarbeitet mit Stand vom',
	VIEW_ALL_NOTIFICATIONS: 'Alle Benachrichtigungen anzeigen',
	NOTIFICATION_SCOPE: 'Benachrichtigungsumfang',
	NOTIFICATION1: 'Info',
	NOTIFICATION2: 'Fehler',
	NOTIFICATION3: 'Warnung',
	NOTIFICATION4: 'Bestätigung',
	GLOBAL_NOTIFICATION: 'Globale Benachrichtigung',
	COMPANY_RELATED_NOTIFICATION: 'Unternehmensbezogene Benachrichtigung',
	MUST_BE_MORE_THAN: 'Muss größer sein als ',
	MUST_BE_LESS_THAN: 'Muss weniger sein als ',
	MUST_BE_LESS_THAN_OR_EQUAL_TO: 'Muss kleiner/gleich sein wie ',
	GLOBAL: 'Global',
	COMPANY_RELATED: 'Unternehmensbezogen',
	RECENT_NOTIFICATIONS: 'Systemmeldungen und -benachrichtigungen',

	networkEntitlement: 'Netzwerkanspruchs-ID',
	networkType: 'Netzwerktyp',
	transferStatus: 'IMSI-Übertragungsstatus',
	rateplan: 'Tarifplan',
	FAILURE: 'Kein Erfolg',
	PENDING_REIMSI_ATTEMPT: 'Re-IMSI-Versuch steht aus',
	REIMSI_QUEUED: 'Re-IMSI in Warteschlange eingereiht',
	PENDING_REIMSI_DELIVERED: 'Ausstehende Re-IMSI geliefert',
	'PENDING_RE-IMSI_DELIVERY_FAILED': 'Lieferung ausstehender Re-IMSI fehlgeschlagen',

	'PENDING REIMSI ATTEMPT': 'Re-IMSI-Versuch steht aus',
	'REIMSI PENDING ATTEMPT': 'Re-IMSI-Versuch steht aus',
	'PENDING REIMSI DELIVERED': 'Ausstehende Übertragung geliefert',
	'Re-IMSI Delivery Failed': 'Übertragungslieferung fehlgeschlagen',
	'REIMSI FAILED': 'Re-IMSI fehlgeschlagen',
	'REIMSI QUEUED': 'Re-IMSI in Warteschlange eingereiht',
	'REIMSI PEDING ATEMPT': 'Re-IMSI-Versuch steht aus',
	'REIMSI PENDING ATEMPT': 'Re-IMSI-Versuch steht aus',
	'REIMSI PENDING DELIVERED': 'Ausstehende Re-IMSI geliefert',
	'REIMSI SUCCESS': 'Re-IMSI Erfolg',
	failure: 'kein Erfolg',

	'ID_PENDING REIMSI ATTEMPT': 'Re-IMSI-Versuch steht aus',
	'ID_REIMSI PENDING ATTEMPT': 'Re-IMSI-Versuch steht aus',
	'ID_PENDING REIMSI DELIVERED': 'Ausstehende Übertragung geliefert',
	'ID_Re-IMSI Delivery Failed': 'Übertragungslieferung fehlgeschlagen',
	'ID_REIMSI FAILED': 'Re-IMSI fehlgeschlagen',
	'ID_REIMSI QUEUED': 'Re-IMSI in Warteschlange eingereiht',
	'ID_REIMSI PEDING ATEMPT': 'Re-IMSI-Versuch steht aus',
	'ID_REIMSI PENDING ATEMPT': 'Re-IMSI-Versuch steht aus',
	'ID_REIMSI PENDING DELIVERED': 'Ausstehende Re-IMSI geliefert',
	'ID_REIMSI SUCCESS': 'Re-IMSI Erfolg',

	CONSUMED_BYTES: 'Verbrauchte Megabyte',
	CONSUMED_SMSES: 'Verbrauchte SMS',
	TO_DATE: 'bis dato',

	CHART_EXISTS_IN_SETTINGS: 'Dieses Diagramm ist in Ihren Einstellungen bereits vorhanden. Es ist nur ein Diagrammtyp zulässig.',
	NO_CHART_DATA: 'Diagrammdaten fehlen',
	STATES_MESSAGE: 'Kein Status verfügbar.',
	SELECT_RATE_PLAN: 'Tarifplan auswählen',
	CHANGE_RATE_PLAN: 'Tarifplan ändern',
	CURRENT_RATE_PLAN_FOR_ENDPOINT_ID: 'Aktueller Tarifplan für Endpunkt-ID',
	IS: 'ist',
	CONFIRM_RATE_PLAN_CHANGE: 'Tarifplanänderung bestätigen',
	RATE_PLAN_WILL_BE: 'Wenn Sie diese Änderung einleiten, lautet der Tarifplan des ausgewählten Endpunkts ',
	WOULD_YOU_LIKE_TO_INITIATE_CHANGE_NOW: 'Änderung jetzt einleiten?',
	RATE_PLAN_CHANGE_IS_SUCCESSFULLY_SCHEDULED: 'Tarifplanänderung erfolgreich eingeplant',
	FOR: 'für',
	RATE_PLAN_CHANGE_IS_SUCCESSFULLY_UPDATED: ' Tarifplanänderung erfolgreich aktualisiert',
	RATE_PLAN_CHANGE_COULD_NOT_BE_SCHEDULED_SUCCESSFULLY: 'Tarifplanänderung konnte nicht erfolgreich eingeplant werden',
	RATE_PLAN_CHANGE_COULD_NOT_BE_CARRIED_OUT_SUCCESSFULLY: 'Tarifplanänderung konnte nicht erfolgreich ausgeführt werden',
	ENDPOINT_RATE_PLAN: 'Tarifplan',
	DOWNLOAD_TEMPLATE_BATCH: 'Vorlage herunterladen',
	CONFIRM_BATCH_FILE_UPLOAD: 'Hochladen der Batch-Datei bestätigen',
	SCHEDULED_ACTION_ON: 'Geplante Aktion am',
	FILE_MUST_BE_SUMITTED_WITH_CSV: 'Dateien müssen mit Erweiterung .csv eingereicht werden.',
	MAXIMUM_SIMS_PER_UPLOAD: 'Maximale SIMs pro Upload: 10.000.',
	SIMS_HAVE: 'SIMs haben',
	CHANGE_FOR_ALL_SELECTED_SIMS: 'Anderen Tarifplan auswählen, um Änderung auf alle ausgewählten SIMs anzuwenden',
	YOU_HAVE_CHOSEN_TO_CHANGE_THE_RATE_PLAN_OF_THE: 'Sie ändern den Tarifplan der',
	SELECTED_SIMS_TO: 'ausgewählten SIMs auf',
	SIMS_RATE_PLAN_SUCCESSFULLY_REQUESTED: 'SIM-Tarifpläne erfolgreich aktualisiert',
	SCHEDULE_RATE_PLAN_SUCCESSFUL: 'Tarifplan für mehrere SIMs erfolgreich eingeplant',
	SIM_RATE_PLAN_CHANGE_FOR: 'SIM-Tarifplanänderung für',
	MULTIPLE_RATE_PLAN_CHANGE_SCHEDULE_ERROR: 'Tarifplanänderung mehrerer SIMs konnte nicht erfolgreich eingeplant werden',
	MULTIPLE_RATE_PLAN_CHANGE_ERROR: 'Tarifplanänderung mehrerer SIMs konnte nicht erfolgreich angefordert werden',
	SAME_RATE_PLAN_SELECTED: 'Gleicher Tarifplan ausgewählt',
	REASON: 'Grund',

	API_DOCUMENTATION: 'API-Dokumentation',
	'Cellular - Active': 'Mobilfunk – aktiviert',
	'Satellite - Active': 'Satellit – aktiviert',
	'Cellular - Terminate': 'Mobilfunk – beendet',
	'Satellite - Terminate': 'Satellit – beendet',
	CHANGE_RATE_PLAN_NOT_ALLOWED: 'Tarifplanänderung nicht zulässig',
	reimsiqueued: 'Re-IMSI in Warteschlange eingereiht',
	reimsifailed: 'Re-IMSI fehlgeschlagen',
	reimsisuccess: 'Re-IMSI Erfolg',
	reimsipendingattempt: 'Re-IMSI-Versuch steht aus',
	reimsipendingdelivered: 'Ausstehende Re-IMSI geliefert',
	imsiPendingMessage: 'Hat das Gerät mehr als 72 Stunden lang den Status „Ausstehend“, bitte Kundensupport kontaktieren',
	ADD_ANOTHER_ACCOUNT: 'Weiteres Konto hinzufügen',
	FILTER_DOES_NOT_HAVE_VALID_DATA: 'API-Fehler, bitte Support kontaktieren.',
	FAILOVER: 'Failover',
	CANCEL_PENDING_FAIL_TITLE: 'Ausstehende Änderungsstornierung fehlgeschlagen',
	CANCEL_PENDING_FAIL_TEXT: 'Ausstehende Änderungsstornierung konnte nicht erfolgreich abgeschlossen werden',
	CANCEL_PENDING_SUCCESS_TITLE: 'Ausstehende Änderungsstornierung erfolgreich',
	CANCEL_PENDING_SUCCESS_TEXT: 'Ausstehende Änderungsstornierung erfolgreich abgeschlossen',
	CANCEL_CHANGE: 'Änderung stornieren',
	CAUTION: 'Vorsicht',
	CANCEL_PROMPT_TITLE: 'Die ausstehende Änderung wirklich stornieren?',
	CANCEL_PROMPT_TEXT: 'Diese Aktion kann nicht rückgängig gemacht werden.',
	CONFIRM_CANCELLATION: 'Stornieren bestätigen',
	NO_RATE_PLANS_AVAILABLE: 'Keine Tarifpläne verfügbar',
	BULK_UPLOAD: 'Sammel-Upload',
	MULTI_ENDPOINT_ACTION: 'Multi-Endpunkt-Aktion',
	PLEASE_SELECT_MORE_SIMSS: 'Bitte wählen Sie zwei oder mehr Endpunkte aus',
	M2M_ACCOUNT_ID: 'M2M-Konto-ID',
	REPORTS_PLATFORM: 'M2M-Plattform',
	ENDPOINT_CHART_SECOND_CARD: 'Jüngste Sitzungen',
	ENDPOINT_CHART_SECOND_CARD_ON: 'Am',
	ENDPOINT_CHART_THIRD_CARD: 'Endpunktnetzwerkstandort',
	NO_AVAILABLE_FILTERS: 'Keine Filter verfügbar.',
	NO_AVAILABLE_FILTERS_SUBTITLE: 'Es sind derzeit keine Filter zur Ansicht verfügbar',

	// Entwickler-API
	GETTING_STARTED: 'Erste Schritte',
	API_SANDBOX: 'API-Sandbox',
	DEVELOPER_API: 'Entwickler-API',
	GETTING_STARTED_WITH_API_T: 'Erste Schritte mit API',
	GETTING_STARTED_WITH_API_P: 'Bevor Sie Ihren ersten API-Aufruf tätigen, muss Ihre Quell-IP-Adresse (Server) auf der zulässigen Liste stehen (whitelisted). Die Quell-IP-Adresse wird in der Regel beim Onboarding-Prozess konfiguriert, wenn der API-Zugriff erstellt wird. Falls Sie Fehler erhalten, liegt das wahrscheinlich daran, dass der Zugriff auf AT&T IoT Gateway gesperrt ist.',
	GETTING_STARTED_WITH_API_UL_T: 'Bitte stellen Sie sicher, dass die folgenden Schritte ergriffen wurden, um mit der Entwicklung anfangen zu können:',
	GETTING_STARTED_WITH_API_LI1: '- Sie haben einen öffentlich zugänglichen Server eingerichtet und uns beim Onboarding dessen IP-Adresse bereitgestellt.',
	GETTING_STARTED_WITH_API_LI2: '- Ihre Quell-IP ist für den Zugriff auf AT&T IoT Gateway zugelassen („whitelisted“).',
	GETTING_STARTED_WITH_API_LI3: '- Ihr Unternehmensadministrator hat Ihnen einen API-Benutzernamen und ein Passwort gegeben, die bei der Aktivierung des API-Dienstes aktiviert wurden.',
	GETTING_STARTED_WITH_API_LI4: '- Sie haben Zugriff auf den Ressourcenserver, um cURL-Befehle auszuführen oder Verbindungs-Apps zu verwenden, die in Ihrer nachstehend aufgeführten bevorzugten Sprache verfasst wurden.',
	GET_OAUTH_TOKEN_T: 'OAuth-Zugriffstoken erhalten',
	GET_OAUTH_TOKEN_P: 'OAuth-Token von AT&T IoT Identity mithilfe des Benutzernamens und Passworts, die Sie von Ihrem Administrator erhalten haben, über die bevorzugte nachstehende Methode abrufen. Token ist 7 Tage lang gültig.',
	GETTING_STARTED_WITH_API_CONNECTORS_T: 'API-Konnektoren',
	GETTING_STARTED_WITH_API_CONNECTORS_P: 'Wählen Sie aus den nachstehenden Beispielen aus, wie Sie eine Verbindung mit unserer API herstellen möchten.',
	TEST_CONNECTION_T: 'Testverbindung mit dem IoT-Gateway',
	TEST_CONNECTION_P: 'Curl-Beispiel:',
	GETTING_STARTED_WITH_API_NEXT_STEP_T: 'Gewünschtes Ergebnis',
	GETTING_STARTED_WITH_API_NEXT_STEP_P: 'Falls Sie HTTP-Antwortcode 200 OK erhalten, können Sie mit der Entwicklung beginnen.',
	GETTING_STARTED_WITH_API_ERRORS_T: 'Fehlersuche bei Verbindungsfehlern',
	GETTING_STARTED_WITH_API_ERRORS_P: 'Falls Sie Fehler erhalten, prüfen Sie zunächst, ob Ihre Authentifizierung im HTTPHeader korrekt eingerichtet ist und das Zugriffs-Token nicht abgelaufen ist bzw. aktualisiert werden muss. Pingen Sie außerdem AT&T IoT Gateway, um zu prüfen ob Ihre Quell-IP zugelassen („whitelisted“) ist. Falls Sie unerwartete Ergebnisse erhalten, können Sie im Support-Abschnitt ein Ticket zur Fehlersuche und -behebung einreichen.',
	API: 'API',
	SANDBOX_SPECIFIES_THE_LIST: 'Die Swagger-Sandbox unten gibt die Liste der in der REST-API verfügbaren Ressourcen sowie die Operationen an, die an diesen Ressourcen aufgerufen werden können. Das Dokument enthält auch die Liste der Parameter der jeweiligen Operation einschließlich Name und Typ der Parameter, ob die Parameter obligatorisch oder optional sind sowie Informationen über für diese Parameter zulässige Werte.',
	JSON_SCHEMA_INCLUDES: 'JSON Schema ist eingeschlossen und beschreibt die Struktur Anforderungskörpers, der an eine Operation in einer REST-API gesendet wird, sowie das JSON-Schema, das die Struktur aller Antwortkörper beschreibt, die von einer Operation zurückgegeben werden.',

	GBCM_OPERATOR: 'Speedcast',
	ATT_OPERATOR: 'AT&T',

	CREATE_CUSTOM_REPORT: 'Benutzerdefinierten Bericht erstellen',
	YOU_MUST_CREATE_FILTER_BEFORE_EXPORT: 'Vor dem Export der Liste muss ein Filter erstellt werden.',
	CREATE_REPORT: 'Bericht erstellen',
	CHART_LEGEND_Active: 'Aktiviert',
	CHART_LEGEND_Terminate: 'Beendet',
	WE_ARE_SORRY_THERE_IS_NO_DATA_TO_EXPORT: 'Leider sind keine Daten für den Export vorhanden',
	EXPORT_FILTERED_DATA: 'Diagrammdaten exportieren',
	ID_DASHBOARD_CHARTS_summaryofmonthlyendpointusage: 'Zusammenfassung der monatlichen Endpunktnutzung',
	ID: 'ID',
	TAB: 'Register',
	SORT_BY: 'Sortieren nach',
	SELECT_CHART_TYPE_TO_ADD: 'Hinzuzufügenden Diagrammtyp auswählen',
	RECREDENTIAL_STATUS: 'Status erneute Zulassung',
	RECREDENTIAL_TRANSFER_STATUS: 'Status erneute Zulassung',
	recredentialTransferStatus: 'Status erneute Zulassung',
	M2MACCOUNTID: 'M2M-Konto-ID',
	HOLDING_ACCOUNT_ID: 'Holding Konto-ID',
	RE_CREDENTIAL: 'Erneute Zulassung',
	SELECT_OPERATOR: 'Bediener auswählen',
	RE_CREDENTIAL_SIM: 'SIM erneut zulassen',
	CURRENT_PLATFORM: 'Aktuelle Plattform',
	YOU_HAVE_CHOSEN_TO_RECREDENTIAL_THIS_SIM: 'Sie lassen diese SIMs neu zu auf',
	YOU_HAVE_CHOSEN_TO_RECREDENTIAL_THESE_SIMS: 'Sie lassen diese SIMs neu zu auf',
	YOU_MAY_INTIATE_THE_CHANGE_NOW: 'Sie können die Änderung jetzt einleiten',
	RE_CREDENTIALING_STATUS: 'Status der erneuten Zulassung',
	RE_CREDENTIALING_IS_SUCCESSFULLY_INITIATED: 'Erneute Zulassung erfolgreich eingeleitet',
	RE_CREDENTIALING_IS_SUCCESSFULLY_SCHEDULED: 'Erneute Zulassung erfolgreich eingeplant',
	RE_CREDENTIALING_IS_SUCCESSFULLY_SCHEDULED_FOR: 'Erneute Zulassung erfolgreich eingeplant für',
	RE_CREDENTIALING_COULD_NOT_BE_INITIATED_SUCCESSFULLY: 'Erneute Zulassung konnte nicht erfolgreich eingeleitet werden',
	RE_CREDENTIALING_COULD_NOT_BE_SCHEDULED_SUCCESSFULLY: 'Erneute Zulassung konnte nicht erfolgreich eingeplant werden',
	ENDPOINT_HISTORY: 'Endpunktverlauf',
	SHIPPED_DATE: 'Versanddatum',
	DIAGNOSTIC_BUTTON: 'Diagnose starten',
	DEVICE_REGISTRATION_INFO_BUTTON: 'Geräteregistrierungsinfo',
	ENDPOINT_REGISTRATION_DETAILS: 'Endpunkt-Registrierungsdetails',
	eventTime: 'Datums- und Zeitstempel',
	carrierName: 'Betreibername',
	registrationType: 'Registrierungstyp',
	NO_DATA_TO_DISPLAY_AT_THE_MOMENT: 'Derzeit keine Daten zur Anzeige vorhanden',
	status: 'Status',
	iccid: 'ICCID',
	carrier: 'Betreiber',
	ATTACHED_CARRIER_NAME: 'Name des angebundenen Betreibers',
	M2MPlatform: 'M2M-Plattform',
	simState: 'Gerätestatus wird abgerufen',
	'Endpoint Provisioning Information': 'Endpunktbereitstellungs-Informationen',
	'M2M Platform': 'M2M-Plattform',
	'Network Connection': 'Netzwerkverbindung',
	simStatusM2M: 'Gerätestatus wird abgerufen',
	billingStatus: 'Gerätestatus wird abgerufen',
	EndpointProvisioningInformation: 'Endpunktbereitstellungs-Informationen',
	trafficAllowed: 'Gerätebereitstellungs-Informationen abrufen',
	permitPassingTraffic: 'Gerätebereitstellungs-Informationen abrufen',
	verifySimState: 'Geräteinformationen abrufen',
	NetworkConnection: 'Netzwerkverbindung',
	verifyConnection: 'Gerätenetzwerk abrufen',
	successfullyConnectedToNetwork_inSession: 'Gerätenetzwerk abrufen',
	verifyIPSession: 'Geräte-IP abrufen',
	dataSessionCurrentlySuccessful: 'Geräte-IP abrufen',
	dd_inventory: 'Inventar',
	dd_activated: 'Aktiviert',
	dd_active: 'Aktiviert',
	dd_inactivated: 'Inaktiv',
	dd_inactive: 'Inaktiv',
	dd_deactivated: 'Deaktiviert',
	dd_retired: 'Stillgelegt',
	dd_purged: 'Bereinigt',
	dd_testready: 'Test bereit',
	dd_activationready: 'Aktivierung bereit',
	dd_replaced: 'Ersetzt',
	dd_trial: 'Versuch',
	dd_start: 'Start',
	dd_transfer: 'Übertragung',
	dd_globalintransitsource: 'Globale In-Transit-Quelle',
	dd_globalintransitdestination: 'Globales In-Transit-Ziel',
	dd_globaltransferred: 'Global übertragen',
	dd_other: 'Sonstiges',
	dd_permitpassingtraffic: 'Weiterleitung von Traffic zugelassen',
	dd_notpermitpassingtraffic: 'Weiterleitung von Traffic nicht zugelassen',
	dd_allowedToUseWirelessNetworkResources: 'Zulässige TollSec Wireless-Ressourcen',
	dd_barredFromNetwork: 'Aus Netzwerk ausgesperrt',
	dd_trafficnotallowed_simstate: 'Traffic nicht zulässig SIM-Status',
	dd_suspendedByServiceProvider: 'Durch Dienstanbieter suspendiert',
	dd_suspendedDueToUsageLimit: 'Aufgrund Nutzungslimit suspendiert',
	dd_trafficNotAllowed_others: 'Traffic nicht zulässig (Sonstiges)',
	dd_successfullyconnectedtonetwork_insession: 'Erfolgreich mit Netzwerk verbunden (in Sitzung)',
	dd_notsuccessfullyconnectedtonetwork_insession: 'Nicht mit Netzwerk verbunden (in Sitzung)',
	dd_validregistration_validnetworkactivity: 'Gültige Registrierung (gültige Netzwerkaktivität)',
	dd_validregistration_nonetworkactivity: 'Gültige Registrierung (keine Netzwerkaktivität)',
	dd_validgsmregistrationonly: 'Nur gültige GSM-Registrierung',
	dd_novalidregistration_hasactivityinlast24h: 'Keine gültige Registrierung (Aktivität in den letzten 24 Stunden)',
	dd_novalidregistration_hasauthrequestinlast24h_nolocupdate: 'Keine gültige Registrierung (Auth.-Anforderung in den letzten 24 Stunden)',
	dd_novalidregistration_haslocupdatesmorethan24hago: 'Keine gültige Registrierung (lokale Aktualisierungen vor mehr als 24 Stunden)',
	dd_novalidregistration_hasauthrequestmorethan24hago_noactivity: 'Keine gültige Registrierung (Auth.-Anforderung vor mehr als 24 Stunden)',
	dd_novalidregistration_nonetworkactivity: 'Vor mehr als 24 Stunden (keine Netzwerkaktivität)',
	dd_datasessioncurrentlysuccessful: 'Gerät aktuell nicht angebunden. Vorherige IP-Sitzungen erfolgreich.',
	dd_notdatasessioncurrentlysuccessful: 'Datensitzung derzeit nicht erfolgreich',
	dd_datasessionpastsuccessful: 'Datensitzung in der Vergangenheit erfolgreich',
	dd_connectionattemptfailed_invalidcredentials: 'Verbindungsversuch fehlgeschlagen (ungültige Anmeldedaten)',
	dd_nodatasessionsuccessfulhistory: 'Keine erfolgreiche Datensitzung in der Vergangenheit',
	dd_: '',
	dd_allowedtousewirelessnetworkresources: 'Zulässige TollSec Wireless-Ressourcen',
	attached: 'Angehängt',
	detached: 'Abgetrennt',
	RECREDENTIALED: 'Erneut zugelassen',
	RECREDENTIALING: 'Erneute Zulassung findet statt',
	CHANGE_RECREDENTIAL: 'Erneute Zulassung nur für die gleichen Plattformen gestattet',
	RECREDENTIAL_DESCRIPTION: 'Tipp: Wenn Sie neu zulassen und mehrere SIMs auswählen, können Sie für mehrere ausgewählte SIMs nur eine Neuzulassungs-Plattform auswählen',
	RECREDENTIAL_NOT_ALLOWED: 'Erneute Zulassung nicht gestattet',
	RECREDENTIAL_SUCCESSFULLY_REQUESTED: 'SIMs erfolgreich initiiert',
	MULTIPLE_RECREDENTIAL_CHANGE_ERROR: 'Erneute Zulassung für mehrere SIMs konnte nicht erfolgreich initiiert werden',
	MOBILE_NETWORK_REGISTRATION_HISTORY: 'Mobilnetz-Registrierungsverlauf',
	ENDPOINT_DAILY_USAGE: 'Tägliche Nutzung Endpunkt',
	ENDPOINT_CYCLE_USAGE: 'Endpunktnutzung Zyklus bis dato',
	CONFIRM_RE_CREDENTIALING: 'Erneute Zulassung bestätigen',
	PENDING_RECREDENTIAL: 'Für diesen Endpunkt steht die erneute Zulassung aus',
	ENDPOINTS_ARE: 'Endpunkte sind für den Export ausgewählt',
	ENDPOINT_IS: 'Endpunkt ist für den Export ausgewählt',
	CUSTOM_REPORT_WILL_BE_AVAILABLE: 'Dieser benutzerdefinierte Bericht wird im Berichteabschnitt zur Verfügung stehen',
	YOU_CAN_APPEND_CUSTOM_NAME: 'Sie können hier einen benutzerdefinierten Namen an den Bericht anhängen',
	CUSTOM_REPORT_NAME_APPENDIX: 'Benutzerdefinierter Berichtsnamenanhang',
	ADD_CUSTOM_REPORT_NAME_APPENDIX: 'Benutzerdefinierten Berichtsnamenanhang hinzufügen (nur Ziffern und Buchstaben zulässig)',
	CUSTOM_REPORT: 'Benutzerdefinierter Bericht',
	CUSTOM_REPORT_CREATED: 'Benutzerdefinierter Bericht erfolgreich erstellt',
	GO_TO_REPORTS: 'Zu Berichten wechseln',
	DIAGNOSTICS_FOR_THIS_ENDPOINT: 'Diagnostik für diesen Endpunkt derzeit nicht verfügbar',
	ENDPOINT_DATA_INFORMATION: 'Endpunkt-Dateninformationen',
	LAST_SESSION: 'Letzte Sitzung',
	CARRIER_ATTACHED: 'Betreiber angehängt',
	APN_DCP: 'APN',
	IP_ADDRESS: 'IP-Adresse',
	LAST_SENT_SMS_MO: 'Datum und Uhrzeit des zuletzt gesendeten SMS MO',
	LAST_SENT_SMS_MT: 'Datum und Uhrzeit der zuletzt gesendeten SMS MT',
	TEST_DETAILS: 'Testdetails',
	CHECK_BLOCKED_STATUS: 'Gesperrten Status prüfen',
	BLOCKED_STATUS: 'Gesperrter Status',
	RETRY_TEST: 'Test erneut versuchen',
	MOBILE_NETWORK_TEST: 'Mobilnetztest',
	CHECK_BLOCKED_SUB: 'Gesperrtes Abonn. prüfen',
	TROUBLESHOOT_DCP: 'DCP-Fehlersuche',
	locationUpdate: 'Standortaktualisierung',
	pdpStatus: 'PDP-Status',
	cancelLocation: 'Ort stornieren',
	CANCEL_LOCATION: 'Ort stornieren',

	DASHBOARD_CHART_CountofDailyEndpointSMSbyPlatformDCP_CELLULARDescription: 'Zeigt DCP-plattformweit auf monatlicher Basis die Gesamtzahl der SMS-Nachrichten an, die pro Tag gesendet wurden',
	DASHBOARD_CHART_CountofDailyEndpointSMSbyPlatformDCP_CELLULAR: 'SMS pro Tag (DCP)',
	DASHBOARD_CHART_CountofMonthlyEndpointSMSbyPlatformDCP_CELLULAR: 'SMS pro Monat (DCP)',
	DASHBOARD_CHART_SumofDailyEndpointUsagebyPlatformDCP_CELLULAR: 'Tägliche Endpunktnutzung insgesamt (DCP)',
	DASHBOARD_CHART_SumofDailyEndpointUsagebyPlatformACC_CELLULAR: 'Tägliche Datennutzung (ACC)',
	DASHBOARD_CHART_SumofMonthlyEndpointUsagebyPlatformDCP_CELLULAR: 'Monatliche Datennutzung (DCP)',
	DASHBOARD_CHART_CountofMonthlyEndpointSMSbyPlatformDCP_CELLULARDescription: 'Zeigt DCP-plattformweit die Gesamtzahl der SMS-Nachrichten an, die pro Monat gesendet wurden',
	DASHBOARD_CHART_SumofDailyEndpointUsagebyPlatformDCP_CELLULARDescription: 'Zeigt die Gesamtdatennutzung pro Tag auf Monatsbasis für die gesamte DCP-Plattform an',
	DASHBOARD_CHART_SumofDailyEndpointUsagebyPlatformACC_CELLULARDescription: 'Zeigt die Gesamtdatennutzung pro Tag auf Monatsbasis für die gesamte ACC-Plattform an',
	DASHBOARD_CHART_SumofMonthlyEndpointUsagebyPlatformDCP_CELLULARDescription: 'Zeigt die Gesamtdatennutzung pro Monat für die gesamte DCP-Plattform an',
	DASHBOARD_CHART_CountofMonthlyEndpointSMSbyPlatformCCIP_CELLULAR: 'SMS pro Monat (CCIP)',
	DASHBOARD_CHART_CountofMonthlyEndpointSMSbyPlatformCCIP_CELLULARDescription: 'Zeigt CCIP-plattformweit die Gesamtzahl der SMS-Nachrichten an, die pro Monat gesendet wurden',
	DASHBOARD_CHART_CountofDailyEndpointSMSbyPlatformCCIP_CELLULAR: 'SMS pro Tag (CCIP)',
	DASHBOARD_CHART_CountofDailyEndpointSMSbyPlatformCCIP_CELLULARDescription: 'Zeigt CCIP-plattformweit die Gesamtzahl der SMS-Nachrichten an, die pro Tag gesendet wurden',
	DASHBOARD_CHART_SumofDailyEndpointUsagebyPlatformCCIP_CELLULAR: 'Tägliche Endpunktnutzung insgesamt (CCIP)',
	DASHBOARD_CHART_SumofDailyEndpointUsagebyPlatformCCIP_CELLULARDescription: 'Zeigt die Gesamtdatennutzung pro Tag auf Monatsbasis für die gesamte CCIP-Plattform an',
	DASHBOARD_CHART_SumofMonthlyEndpointUsagebyPlatformCCIP_CELLULAR: 'Monatliche Datennutzung (CCIP)',
	DASHBOARD_CHART_SumofMonthlyEndpointUsagebyPlatformCCIP_CELLULARDescription: 'Zeigt die Gesamtdatennutzung pro Monat für die gesamte CCIP-Plattform an',
	DASHBOARD_CHART_countByCountry_CELLULAR: 'Endpunkte nach Land',
	DASHBOARD_CHART_countByCountry_CELLULARDescription: 'Zeigt Anzahl der Endpunkte über mehrere Länder hinweg an.',
	DASHBOARD_CHART_countByRoamingCountry_CELLULAR: 'Endpunkte nach Roaming-Land (Mobilfunk)',
	DASHBOARD_CHART_countByRoamingCountry_CELLULARDescription: 'Zeigt die Anzahl der Endpunkte nach Roaming-Land an.',
	DASHBOARD_CHART_countByRoamingCarrier_CELLULAR: 'Endpunkte nach Roaming-Betreiber (Mobilfunk)',
	DASHBOARD_CHART_countByRoamingCarrier_CELLULARDescription: 'Zeigt die Anzahl der Mobilfunk-Endpunkte nach Roaming-Betreiber an.',
	DASHBOARD_CHART_countByRoamingCarrier_SATELLITE: 'Endpunkte nach Roaming-Betreiber (Satellit)',
	DASHBOARD_CHART_countByRoamingCarrier_SATELLITEDescription: 'Zeigt die Anzahl der Satelliten-Endpunkte nach Roaming-Betreiber an.',

	SMS_HISTORY: 'SMS-Verlauf',
	MAXIMUM_NUMBER_OF_CHARACTERS: 'Maximale Zeichenanzahl: ',
	SEND_SMS_MESSAGE: 'SMS senden',
	SMS_NOTIFICATION_TITLE: 'SMS-Benachrichtigung',
	SMS_NOTIFICATION_MESSAGE: 'Antwortfehler: ',
	SMS_NOTIFICATION_MESSAGE_SUCCESS: 'SMS erfolgreich gesendet',

	REVIEW: 'Prüfen',
	PLATFORM_DETAILS: 'Plattformdetails',

	UPDATING: 'Aktualisierung läuft',
	AUTOMATION_RULE_SUCCESSFULLY_DELETED: 'Automatisierungsregel erfolgreich gelöscht',
	AUTOMATION_DELETE_ERROR: 'Automatisierungsregel konnte nicht gelöscht werden, bitte erneut versuchen.',
	FETCH_DATA_ERROR: 'Daten können nicht abgerufen werden',
	CHANGED_BY: 'Geändert durch',
	EMAIL_SUB: 'E-Mail senden',
	CTD_USAGE: 'Datennutzung Zyklus bis dato',
	PAST24H_DATA_USAGE_EXCEEDED: 'Datennutzung in vergangenen 24 Stunden überschritten',
	SMS_USAGE: 'SMS-Nutzung Zyklus bis dato',
	EXCEEDLIMIT: 'Überschreitet',
	APPROACHLIMIT: 'Nähert sich',
	trigger: 'Auslöser',
	TWENTYFOURHOURS: 'Letzte 24 Stunden',
	CTD: 'Zyklus bis dato',
	ADD_RULE_SUCCESS: 'Regel erstellt',
	ADD_RULE_ERROR: 'Automatisierungsregel konnte nicht erstellt werden, bitte erneut versuchen.',
	EDIT_RULE_SUCCESS: 'Regel bearbeitet',
	EDIT_RULE_ERROR: 'Automatisierungsregel konnte nicht bearbeitet werden, bitte erneut versuchen.',
	ACC_AUTOMATION: 'ACC-Automatisierung',
	ACC_ACCOUNT_ID: 'ACC-Konto-ID',
	CREATE_NEW_AUTOMATION_RULE: 'Neue Automatisierungsregel erstellen',
	DCP_AUTOMATION: 'DCP-Automatisierung',
	DCP_ACCOUNT_ID: 'DCP-Konto-ID',
	ACC_ACCOUNT: 'ACC-Konto',
	RULE: 'Regel',
	ACC_IF: 'Wenn',
	TRESHOLD: 'Schwellenwert',
	THEN: 'Dann',
	ACC_RULE_NAME: 'Regelname',
	SAVE_AND_ACTIVATE: 'Speichern und aktivieren',
	DCP_ACCOUNT: 'DCP-Konto',
	SELECT_BY: 'Auswählen nach',
	DCP_BYTES_SENT: 'Byte gesendet',
	SMSES_SENT: 'SMS gesendet',
	SMSES: 'SMS',
	DASHBOARD_FEEDBACK: 'Dashboard',
	MUST_BE_BETWEEN: 'Muss zwischen Folgendem liegen: ',
	COMPANIES_STEPS: 'Unternehmensschritte',
	USER_NAME: 'BENUTZERNAME',
	ID_DASHBOARD_CHARTS_countofmonthlyendpointsmsbyplatformdcp: 'SMS pro Monat',
	ID_DASHBOARD_CHARTS_countofdailyendpointsmsbyplatformdcp: 'SMS pro Tag',
	ID_DASHBOARD_CHARTS_sumofdailyendpointusagebyplatformdcp: 'Tägliche Endpunktnutzung insgesamt',
	ID_DASHBOARD_CHARTS_sumofdailyendpointusagebyplatformacc: 'Tägliche Datennutzung',
	ID_DASHBOARD_CHARTS_sumofmonthlyendpointusagebyplatformdcp: 'Monatliche Datennutzung',
	DASHBOARD_CHART_DeviceType_SATELLITE: 'Gerätetyp (Satellit)',
	DASHBOARD_CHART_DeviceType_CELLULAR: 'Gerätetyp (Mobilfunk)',
	DASHBOARD_CHART_DeviceType_SATELLITEDescription: 'Zeigt die Gesamtzahl der Geräte aller verschiedenen Typen an',
	DASHBOARD_CHART_DeviceType_CELLULARDescription: 'Zeigt die Gesamtzahl der Geräte aller verschiedenen Typen an',
	AUTOMATION_ALERTS: 'Automatisierungsbenachrichtigungen',
	STATE: 'Status',
	COMPANY_EMAIL: 'Unternehmens-E-Mail',
	CUSTOM_FIELD_LABELS: 'Benutzerdefinierte Feldbezeichnungen',
	MY_COMPANY: 'Mein Unternehmen',
	AUTOMATION_ACCOUNT: 'Konto',
	UPDATE_CUSTOM_FIELD_LABELS_SUCCESS: 'Benutzerdefinierte Bezeichnungseinstellungen gespeichert',
	UPDATE_CUSTOM_FIELD_LABELS_ERROR: 'Benutzerdefinierte Bezeichnungseinstellungen konnten nicht gespeichert werden',
	WRITE_SMS_MESSAGE_HERE: 'Nachricht hier eingeben ...',
	RE_CREDENTIALING_ALLOWED: 'Erneute Zulassung nicht gestattet.',
	DELETE_AUTOMATION_RULE_QUESTION: 'Automatisierungsregel löschen?',
	DELETE_AUTOMATION_RULE_MESSAGE: 'Möchten Sie die folgende Automatisierungsregel wirklich löschen: ',
	PLATFORM_SUCCESSFULLY_DELETED: 'Plattform erfolgreich gelöscht',
	PLATFORM_DELETE_ERROR: 'Plattform konnte nicht gelöscht werden, bitte erneut versuchen.',
	CELLULAR: 'Mobilfunk',
	SATELLITE: 'Satellit',
	SAVE_CUSTOM_LABELS: 'Benutzerdefinierte Bezeichnungen speichern',

	// ccip
	ENDPOINT_PROVISIONING_INFORMATION: 'Endpunktbereitstellungs-Informationen',
	CCIP_3G: '3G',
	VOICE_ENABLED: 'Voice aktiviert',
	DATA_ENABLED: 'Daten aktiviert',
	SMS_ENABLED: 'SMS aktiviert',
	VOICE_ROAMING_ENABLED: 'Voice-Roaming aktiviert',
	DATA_ROAMING_ENABLED: 'Daten-Roaming aktiviert',
	CCIP_LTE: 'LTE',
	LTE_ENABLED: 'LTE aktiviert',
	ROAMING_ENABLED: 'Roaming aktiviert',
	MOBILE_NETWORK_ACCESS: 'Mobilnetzzugriff',
	VOICE_ONLY: 'Nur Voice',
	REGISTERED_ON_NETWORK: 'In Netz registriert',
	REGISTERED_ON_3G_NETWORK: 'In 3G-Netz registriert',
	THIS_EMAIL_ADDRESS_IS_ALREADY_IN_USE: 'Diese E-Mail-Adresse wird bereits verwendet',
	THIS_EMAIL_ADDRESS_DOES_NOT_EXIST: 'Diese E-Mail-Adresse ist nicht vorhanden',
	USER_SUCCESSFULLY_DELETED: 'Benutzer erfolgreich gelöscht',
	USER_DELETE_ERROR: 'Benutzer konnte nicht gelöscht werden, bitte erneut versuchen.',
	CATEGORIES: 'Kategorien',
	TRIGGERS: 'Auslöser',
	FETCH_DATA_LOADING_MESSAGE: 'Bitte warten, während die Daten geladen werden',
	M2M_ACCOUNT_ID_PLATFORM: 'M2M-Konto-ID',
	M2MACCOUNTS: 'M2M-Konto',

	FROM_VALUE: 'VON-WERT',
	TO_VALUE: 'BIS-WERT',

	SEARCH_REPORTS: 'Berichte durchsuchen',
	SEARCH_INVOICES: 'Rechnungen durchsuchen',

	CUSTOM_DATA_FIELDS: 'Benutzerdefinierte Datenfelder',
	ENDPOINT_ACTIONS: 'Endpunktaktionen für',
	RE_CREDENTIAL_NOT_ALLOWED: 'Erneute Zulassung nicht gestattet',
	EDIT_ENDPOINT: 'Endpunktaktionen',
	CURRENT_SIM_STATE_FOR_ENDPOINT_ID: 'Aktueller SIM-Status für Endpunkt-ID',

	NETWORK_ENTITLEMENT_NAME: 'Netzwerkanspruchsname',
	BLOCK_PREMIUM_NUMBERS: 'Premium-Nummern sperren',
	BLOCK_INTERNATIONAL_MO: 'Internationale SMS MO sperren außer Heimatland',
	HLR_TEMLATE: 'HLRHSS-Details - Vorlage',
	HOTLINE_NUMBER: 'Hotline-Nummer',
	FACETIME: 'FACETIME',
	VOLTE: 'Volte',
	GDSP_SESSION_ACTIVE: 'Aktiv',
	GDSP_SESSION_BYTES_IN: 'Byte Eingang',
	GDSP_SESSION_BYTES_OUT: 'Byte Ausgang',
	GDSP_SESSION_LAST_IP_ADDRESS: 'Letzte IP-Adresse',
	GDSP_SESSION_LAST_STARTED_TIMESTAMP: 'Letzter Start-Zeitstempel',
	GDSP_SESSION_LAST_UPDATED_TIMESTAMP: 'Letzter Aktualisierungs-Zeitstempel',
	GDSP_SESSION_LAST_TERMINATED_TIMESTAMP: 'Letzter Beendigungs-Zeitstempel',
	GDSP_SESSION_LAST_DATA_TRANSMITED_TIMESTAMP: 'Zeitstempel der letzten Datenübertragung',
	GDSP_SESSION_PREVIOUS_NORMAL_TIMESTAMP: 'Vorheriger normaler Zeitstempel',
	GDSP_SESSION_LAST_SMS_MO_TIMESTAMP: 'Letzter SMS MO-Zeitstempel',
	GDSP_SESSION_LAST_SMS_MT_TIMESTAMP: 'Letzter SMS MT-Zeitstempel',
	GDSP_SESSION_LAST_SMS_MT_STATUS: 'Letzter SMS MT-Status',
	GDSP_SESSION_LAST_SMS_MO_STATUS: 'Letzter SMS MO-Status',
	GDSP_SESSION_LAST_WAKEUP_REQUEST_TIMESTAMP: 'Zeitstempel für letzte Weckruf-Anforderung',
	GDSP_SESSION_LAST_WAKEUP_REQUEST_TYPE: 'Letzter Weckruf-Anforderungstyp',
	GDSP_SESSION_LAST_WAKEUP_STATUS: 'Letzter Weckruf-Status',
	GDSP_SESSION_LAST_WAKEUP_STATUS_TIMESTAMP: 'Zeitstempel für letzten Weckruf-Status',
	GDSP_SESSION_LAST_NOTIFICATION_FAILURE_TIMESTAMP: 'Zeitstempel für letzten Benachrichtigungsmisserfolg',
	GDSP_SESSION_LAST_NOTIFICATION_FAILURE_REASON: 'Grund für letzten Benachrichtigungsmisserfolg',

	CUSTOM_FIELD_1: 'Benutzerdefiniertes Feld 1',
	CUSTOM_FIELD_2: 'Benutzerdefiniertes Feld 2',
	CUSTOM_FIELD_3: 'Benutzerdefiniertes Feld 3',
	CUSTOM_FIELD_4: 'Benutzerdefiniertes Feld 4',
	CUSTOM_FIELD_5: 'Benutzerdefiniertes Feld 5',
	CUSTOM_FIELD_6: 'Benutzerdefiniertes Feld 6',
	CUSTOM_FIELD_7: 'Benutzerdefiniertes Feld 7',
	CUSTOM_FIELD_8: 'Benutzerdefiniertes Feld 8',
	CUSTOM_FIELD_9: 'Benutzerdefiniertes Feld 9',
	CUSTOM_FIELD_10: 'Benutzerdefiniertes Feld 10',

	/* ----- GDSP ------- */
	REGISTRATION_STATUS: 'Registrierungsstatus',
	NETWORK_CONNECTIONS: 'Netzwerkverbindungen',
	PROVISIONED_DATE_AND_TIME: 'Datum und Uhrzeit der Bereitstellung',

	// GDSP SIM-STATUS STATUS
	M2M_PLATFORM_STATUS_A: 'Aktiv live',
	M2M_PLATFORM_STATUS_B: 'Inaktiv neu',
	M2M_PLATFORM_STATUS_C: 'Aktiv Ruhe',
	M2M_PLATFORM_STATUS_D: 'Inaktiv angehalten',
	M2M_PLATFORM_STATUS_I: 'Aus anderer GDSP-Umgebung migriert',
	M2M_PLATFORM_STATUS_N: 'Nicht bereitgestellt',
	M2M_PLATFORM_STATUS_O: 'Zu anderer GDSP-Umgebung migriert',
	M2M_PLATFORM_STATUS_R: 'Aktiv bereit',
	M2M_PLATFORM_STATUS_S: 'Aktiv suspendieren',
	M2M_PLATFORM_STATUS_T: 'Aktiv Test',
	M2M_PLATFORM_STATUS_U: 'Aktiv Standby',
	M2M_PLATFORM_STATUS_V: 'Inaktiv umgewandelt',
	M2M_PLATFORM_STATUS_W: 'Regulatorische Suspendierung',
	M2M_PLATFORM_STATUS_Z: 'Beendet',
	M2M_PLATFORM_STATUS_NOT_AVAILABLE: 'Nicht verfügbar',

	// GDSP-Sitzungsregister Statuscodes
	// lastWakeupStatus
	LASTWAKEUPSTATUS_IR: 'Weckruf ungültig',
	LASTWAKEUPSTATUS_FI: 'Weckruf-Initialisierung fehlgeschlagen',
	LASTWAKEUPSTATUS_SI: 'Weckruf-Initialisierung erfolgreich',
	LASTWAKEUPSTATUS_F: 'Weckrufzustellung fehlgeschlagen',
	LASTWAKEUPSTATUS_S: 'Weckrufzustellung erfolgreich',
	TOOLTIP_LASTWAKEUPSTATUS_IR: 'Fehlerhafte API-Anforderung',
	TOOLTIP_LASTWAKEUPSTATUS_FI: 'Fehler von SMSC',
	TOOLTIP_LASTWAKEUPSTATUS_SI: 'Erfolgreich bei SMSC eingereicht',
	TOOLTIP_LASTWAKEUPSTATUS_F: 'SMSC hat eine Lieferbenachrichtigung ausgegeben, die auf einen Fehlschlag hinweist',
	TOOLTIP_LASTWAKEUPSTATUS_S: 'SMSC hat eine Lieferbenachrichtigung ausgegeben, die auf einen Erfolg hinweist',

	// lastSmsMtStatus
	LASTSMSMTSTATUS_IR: 'Weckruf ungültig',
	LASTSMSMTSTATUS_FI: 'Weckruf-Initialisierung fehlgeschlagen',
	LASTSMSMTSTATUS_SI: 'Weckruf-Initialisierung erfolgreich',
	LASTSMSMTSTATUS_F: 'Weckrufzustellung fehlgeschlagen',
	LASTSMSMTSTATUS_S: 'Weckrufzustellung erfolgreich',
	TOOLTIP_LASTSMSMTSTATUS_IR: 'Fehlerhafte API-Anforderung',
	TOOLTIP_LASTSMSMTSTATUS_FI: 'Fehler von SMSC',
	TOOLTIP_LASTSMSMTSTATUS_SI: 'Erfolgreich bei SMSC eingereicht',
	TOOLTIP_LASTSMSMTSTATUS_F: 'SMSC hat eine Lieferbenachrichtigung ausgegeben, die auf einen Fehlschlag hinweist',
	TOOLTIP_LASTSMSMTSTATUS_S: 'SMSC hat eine Lieferbenachrichtigung ausgegeben, die auf einen Erfolg hinweist',

	/* ----- GDSP ENDE ------- */

	/* API FEHLERCODES  */

	CODE_400203: 'Ein erforderlicher Parameter fehlt',
	CODE_400204: 'Ein Parameter hat nicht die korrekte Länge',
	CODE_401205: 'Token ungültig oder abgelaufen',
	CODE_400207: 'Ein Parameter hat keine der möglichen gültigen Wertoptionen',
	CODE_400208: 'Ein Parameter hat nicht das korrekte Format',
	CODE_400209: 'Duplizierter Parameter',
	CODE_400299: 'Fehlerhafte Anforderung',
	CODE_400301: 'Fehler von nachgeschalteten Systemen',
	CODE_401301: 'Anmeldedatenfehler bei nachgeschalteten Systemen',
	CODE_404301: 'Geräte-ID nicht auf nachgeschaltetem System gefunden',
	CODE_404302: 'Konto-ID des nachgeschalteten Systems ungültig oder nicht gefunden',
	CODE_502100: 'Änderung nicht zulässig, Gerät ist bereits eingeplant',
	CODE_400100: 'Ungültige Anforderung: Batch-Aktion für Satellitengeräte nicht zulässig',
	CODE_404201: 'Datei nicht vorhanden',
	CODE_401202: 'Benutzername oder Passwort ungültig',
	CODE_400206: 'Dateinamensformat ungültig',
	CODE_502200: 'Batch-Dateifehler',
	CODE_502202: 'Datenbankfehler in Abruf',
	CODE_502203: 'Datenbankfehler beim Vornehmen von Änderungen',
	CODE_502204: 'Fehler beim Erstellen des Kunden',
	CODE_502301: 'Fehler bei der Verarbeitung der PUSH-API',
	CODE_404303: 'Geräte-ID nicht in PUSH-API-Benachrichtigung gefunden',
	CODE_502501: 'Fehler bei der Verarbeitung von SFTP-Berichten',
	CODE_undefined: ' ',
	CODE_INTEGRATION_TIMEOUT: 'Zugrunde liegende Plattform nicht erreicht. Bitte versuchen Sie es später noch einmal.',
	CODE_VTM_SVC_40003: 'The entered value was not recognized',
	CODE_VTM_SVC_40004: 'Unable to create the support ticket',
	CODE_VTM_SVC_40005: 'Unable to create the support ticket',
	CODE_VTM_SVC_40409: 'Ticket does not exist: It may have been removed due to AT&T data retention policy',
	CODE_VTM_SVC_40411: 'Ticket does not exist or it is closed by an agent: It may have been removed due to AT&T data retention policy',
	CODE_VTM_SVC_40027: 'Cannot update canceled or closed support ticket: This ticket is closed or canceled and cannot be updated.',
	CODE_VTM_SVC_40035: 'Unable to add engagement: Engagement ticket already exist',
	CODE_VTM_SVC_50001: 'Something went wrong: Try again. If the problem persists, please report your issue by calling AT&T',
	CODE_VTM_SVC_40038: 'Unable to update the support ticket: Circuit details are the same',
	CODE_VTM_SVC_40039: 'Cannot create the support ticket: Asset info is missing in the system',
	CODE_VTM_SVC_40040: 'Cannot add a PVC value to support ticket: pvcID details already exist in the system',
	CODE_VTM_SVC_40041: 'Unable to update support ticket: clkNumber does not exist',
	CODE_VTM_SVC_40042: 'Unable to update support ticket: pvcID does not exist',
	CODE_VTM_SVC_40043: 'Unable to retrieve ticket history: Please try again later',
	CODE_VTM_SVC_40010: 'Unable to create the support ticket: Field exceeded maximum of 199 characters. Please correct the length and try again',
	CODE_VTM_SVC_40053: 'Unable to create support ticket: Case ID does not have Ticket Role defined. or is invalid support role',
	CODE_EOD_11: 'Unable to process the request: Please try again in a few minutes.',
	CODE_EOD_59: 'Cannot deactivate device without static IP: Before a device can be deactivated (put on hold), it needs to have a static IP. You can still deactivate this device by deprovisioning it (purge) from the system.',
	CODE_EOD_60: 'Cannot deactivate inactive device',
	CODE_EOD_46: 'The SIM is already in the provisioning queue',
	CODE_EOD_30: 'The SIM cannot be suspended using a past date',
	CODE_EOD_32: 'Internal system error: Contact customer support or create a trouble ticket to get help with this issue',
	CODE_EOD_41: 'Cannot change profile for suspended devices: Contact customer support or create a trouble ticket to get help with this issue',
	CODE_EOD_45: 'Invalid SIM or SIM does not belong to your account: Contact customer support or create a trouble ticket to get help with this issue',
	CODE_EOD_16: 'SIM has reached the maximum number of Rate Plan changes allowed during the Billing Cycle: Could not complete the Rate Plan change request. Please try again in the next billing cycle.',
	CODE_EOD_17: 'SIM has reached the maximum number Rate Plan changes allowed for the day: Could not complete the Rate Plan change request. Please try again tomorrow.',
	CODE_EOD_25: 'The device is currently in a suspended state and so cannot resume before the suspended date',
	CODE_EOD_27: 'The SIM cannot be suspended using a past date',
	CODE_EOD_3: 'Device is already inactive',
	CODE_EOD_401: 'Unable to authorize user performing the batch action with device platform.',

	/* API FEHLERCODES  */

	SERVICE_STATUS: '360L-Status',
	SERVICESTATUS: '360L-Status',
	OEM: 'Unterkonten',
	ROAMING_COUNTRY: 'Roaming-Land',
	ROAMING_CARRIER: 'Roaming-Betreiber',
	ROAMINGCOUNTRY: 'Roaming-Land',
	ROAMINGCARRIER: 'Roaming-Betreiber',
	OEM_TOOLTIP: 'Verknüpfte 360L-Konten',
	SIM_STATE: 'SIM-Status',

	NO_DATA_ENDPOINT_HISTORY: 'Kein Endpunktverlauf',
	PRINT_PDF: 'PDF drucken',
	USER_GUIDE: 'Benutzerhandbuch',
	USER_GUIDE_MENU: 'Benutzerhandbuchmenü',
	FAQ_MENU: 'FAQ-Menü',
	TABLE_OF_CONTENTS: 'Inhaltsverzeichnis',
	RATE_PLAN_TYPE: 'Tarifplantyp',
	SUBSCRIPTION_PRICE: 'Abonnementpreis',
	ACCOUNT_CHARGE: 'Kontogebühr',
	PAYMENT_TYPE: 'Zahlungstyp',
	TERM: 'Laufzeit',
	USAGE_LIMIT: 'Nutzungslimit',
	TIERING_FLAG: 'Tiering-Flag',

	RATE_PLAN_CHANGE: 'Tarifplanänderung',
	RATE_PLAN_CHANGED_TO: 'Sie wechseln ihren Tarifplan zu',
	SCHEDULE_RATE_PLAN_CHANGE: 'Tarifplanänderung einplanen',
	RATE_PLAN_CHANGE_IS_SUCCESSFULLY_SCHEDULED_ERROR: 'Tarifplanänderung konnte nicht erfolgreich eingeplant werden',
	RATE_PLAN_CHANGE_IS_SUCCESSFULLY_SCHEDULED_FOR: 'Tarifplanänderung erfolgreich eingeplant für',
	RATE_PLAN_IS_CHANGED_TO: 'Tarifplan wurde geändert zu ',
	FAILED_TO_PROVISION_AFTER_RATE_PLAN_CHANGE: ' konnte nach Tarifplanänderung nicht bereitgestellt werden. ',
	SEND_EMAIL_TO_CONSUMER: 'E-Mail an Verbraucher senden',
	PUSH_API: 'Push-API',
	INITIATE_OTA_SWAP: 'OTA-Austausch initiieren',
	UPDATE_PCRF: 'PCRF aktualisieren',
	CHANGE_DEVICES_NETWORK_ENTITLEMENT: 'Netzwerkanspruch des Geräts ändern',
	CHANGE_DEVICES_SIM_STATE: 'SIM-Status des Geräts ändern',
	CHANGE_DEVICES_RATE_PLAN: 'Tarifplan des Geräts ändern',
	NUMBER_OF_DAYS: 'Anzahl der Tage',
	NUMBER_OF_HOURS: 'Anzahl der Stunden (max. 24)',
	AT: 'Am',
	FILTER: 'FILTER',
	FOLLOW_UP_WITH: 'Nachfassen mit',
	CUSTOM: 'Benutzerdef.',
	ELIGIBLE_RATE_PLAN: 'Qualifizierter Tarifplan',
	CHOOSE_RATE_PLAN: 'Tarifplan wählen',
	CUSTOMER_ID: 'Kunden-ID',
	INVALID_URL_FORMAT: 'Ungültiges URL-Format',
	INVALID_PORT_NUMBER: 'Ungültige PORT-Nummer',
	INVALID_INPUT: 'Ungültiger Input',
	RATE_PLAN_CHANGE_SUPPORT: 'Fehler bei Tarifplan, bitte Kundensupport kontaktieren.',
	NETWORK_ENTITLEMENT_PLACEHOLDER: 'XYX GPRS/LTE/SMS/IMS RES 165',
	M2M_SUB_ACCOUNT_ID: 'M2M-Unterkonto-ID',

	/* SIM-BESTELLUNGS */
	SIM_ORDERING: 'SIM-Bestellung',
	CREATE_SIM_ORDER: 'SIM-Bestellung erstellen',
	NEW_SIM_ORDER: 'Neue Bestellung',
	EXISTING_SIM_ORDER: 'Bestell-ID: {id}',
	M2M_ACCOUNT: 'M2M-Konto',
	M2M_ACCOUNT_PLACEHOLDER: 'Wählen Sie Ihr M2M-Konto',
	ORDER_ID: 'Bestell-ID',
	ORDER_NUMBER_ID: 'Bestellnummer-ID',
	ORDER_CONFIRMATION_NUMBER: 'Bestellbestätigungsnummer',
	ORDER_DATE: 'Bestelldatum',
	PURCHASE_ORDER_NUMBER: 'Bestellnr.',
	SKU: 'SKU',
	SKU_PLACEHOLDER: 'SKU auswählen',
	UNIT_PRICE: 'Preis pro Einheit',
	QUANTITY: 'Menge',
	QUANTITY_LIMIT_TEMPORARILY_REACHED: 'Mengenlimit temporär erreicht',
	MIN_MAX_QUANTITY: 'Min. {min} / Max. {available} {max}',
	INCREMENT_QUANTITY: 'Inkrement {increment}',
	ORDER_STATUS: 'Bestellstatus',
	ORDER_STATUS_PENDING: 'Ausstehend',
	ORDER_STATUS_RECEIVED: 'Empfangen',
	ORDER_STATUS_SHIPPED: 'Versandt',
	ORDER_STATUS_COMPLETED: 'Abgeschlossen',
	ORDER_STATUS_SUCCESSFUL: 'Erfolgreich',
	ORDER_STATUS_FAILED: 'Fehlgeschlagen',
	CONTACT_NAME: 'Kontaktname',
	TELEPHONE_NUMBER: 'Telefonnummer',
	TARGET_IMPLEMENTATION_DATE: 'Zielimplementierungsdatum',
	EXPEDITED: 'Beschleunigt',
	SHIPPING_ADDRESS: 'Versandadresse',
	ADDRESS_LINE_1: 'Adresszeile 1',
	ADDRESS_LINE_2: 'Adresszeile 2',
	SHIPPING_NOTES: 'Versandanmerkungen',
	SHIPPING_NUMBER: 'Versandnummer',
	EXPEDITED_SHIPPING: 'Expressversand',
	TOTAL_PRICE: 'Gesamtpreis',
	SUBMIT_ORDER_REQUEST: 'Bestellanforderung absenden',
	SUBMIT_ORDER_REQUEST_CONFIRMATION: 'Diese Bestellung wirklich absenden?',
	EDIT_ORDER_REQUEST: 'Bestellanforderung bearbeiten',
	EDIT_ORDER_REQUEST_CONFIRMATION: 'Änderungen wirklich speichern?',
	SAVE_UPDATES: 'Aktualisierungen speichern',
	ADD_SIM_ORDER_SUCCESS: 'SIM-Bestellung erstellt',
	ADD_SIM_ORDER_ERROR: 'SIM-Bestellung konnte nicht erstellt werden, bitte erneut versuchen.',
	EDIT_SIM_ORDER_SUCCESS: 'SIM-Bestellung bearbeitet',
	EDIT_SIM_ORDER_ERROR: 'SIM-Bestellung konnte nicht bearbeitet werden, bitte erneut versuchen.',
	PO_TOOLTIP: 'Bestellnr. wird nach Übergabe der SIM-Bestellung generiert',

	FILTERS: 'Filter',
	FILTER_CATEGORY_STATUS: 'Status',
	FILTER_CATEGORY_PLATFORM: 'M2M-Plattform',
	FILTER_CATEGORY_SKU: 'SKU',
	FILTER_CATEGORY_EXPEDITED: 'Beschleunigt',
	FILTER_VALUE_PENDING: 'Ausstehend',
	FILTER_VALUE_RECEIVED: 'Empfangen',
	FILTER_VALUE_SHIPPED: 'Versandt',
	FILTER_VALUE_COMPLETED: 'Abgeschlossen',
	FILTER_VALUE_YES: 'Ja',
	FILTER_VALUE_NO: 'Nein',

	SIM_ORDERING_TITLE: 'SIM-Bestellung',
	SIM_ORDERING_SUBTITLE: 'Bitte nachstehend auswählen, welche SIMs zur Bestellung verfügbar sind.',
	SIM_ORDERING_CONTRACT_RESTRAINTS: 'Vertragsbeschränkungen',
	SIM_ORDERING_CONTRACT_RESTRAINTS_SUBTITLE: 'Bitte legen Sie Einschränkungen für den SIM-Bestellvorgang fest.',
	SIM_ORDERING_MIN_QUANTITY_ALLOWED: 'Zulässige Mindestmenge',
	SIM_ORDERING_MIN_QUANTITY_ALLOWED_PLACEOLDER: 'Mindestmenge',
	SIM_ORDERING_MAX_QUANTITY_ALLOWED: 'Zulässige Höchstmenge',
	SIM_ORDERING_PER_PERIOD: 'Pro Zeitraum',
	SIM_ORDERING_PER_PERIOD_PLACEHOLDER: 'Einen Zeitraum auswählen',
	SIM_ORDERING_MAX_QUANTITY_ALLOWED_PLACEOLDER: 'Höchstmenge',
	SIM_ORDERING_SIM_SKU: 'SIM-SKU',
	SIM_ORDERING_SIM_SKU_PLACEHOLDER: 'SIM-SKU hier eingeben',
	SIM_ORDERING_UNIT_PRICE: 'Preis pro Einheit',
	SIM_ORDERING_UNIT_PRICE_PLACEHOLDER: 'Preis pro Einheit',
	SIM_ORDERING_ALLOWED_TO_ORDER: 'Bestellung zulässig',
	SIM_ORDERING_SKU_DESCRIPTION: 'Beschreibung',
	SIM_ORDERING_SKU_DESCRIPTION_PLACEHOLDER: 'SKU-Beschreibung eingeben',
	SIM_ORDERING_SKU_ADD_SKU: '+ Weitere SIM-SKU hinzufügen',
	SIM_ORDERING_VALIDATOR_MAX_QUANTITY: 'Höchstmenge',
	SIM_ORDERING_VALIDATOR_MIN_QUANTITY: 'Mindestmenge',
	ADD_RESTRAINTS_SUCCESS: 'Vertragsbeschränkungen hinzugefügt',
	ADD_RESTRAINTS_ERROR: 'Vertragsbeschränkungen konnten nicht hinzugefügt werden',
	UPDATE_RESTRAINTS_SUCCESS: 'Vertragsbeschränkungen aktualisiert',
	UPDATE_RESTRAINTS_ERROR: 'Vertragsbeschränkungen konnten nicht aktualisiert werden',
	ADD_SIM_ORDERING_SKUS_SUCCESS: 'SIM-Bestellungs-SKUs aktualisiert',
	ADD_SIM_ORDERING_SKUS_ERROR: 'SIM-Bestellungs-SKUs konnten nicht hinzugefügt werden',

	COMPANY_REPORTS: 'Berichte',
	COMPANY_REPORTS_SETTINGS_SUCCESSFULLY_UPDATED: 'Unternehmensberichtseinstellungen gespeichert',
	ERROR_WHILE_UPDATING_COMPANY_REPORTS: 'Fehler bei der Aktualisierung der Unternehmensberichte',
	ERROR_WHILE_FETCHING_COMPANY_REPORTS: 'Fehler beim Abrufen der Berichtskonfiguration',
	COMPANY_REPORTS_SUBTITLE: 'Bitte wählen Sie unten, welche Berichte Sie erhalten möchten.',
	DAILY_REPORTS: 'TÄGLICHE BERICHTE',
	MONTHLY_REPORTS: 'MONATLICHE BERICHTE',
	PREFERENCES_MENU: 'Einstellungsmenü',
	PREFERENCES: 'Einstellungen',
	REFERENCE_REPORTS: 'Referenzberichte',
	M2M_DATE_ADDED: 'M2M-Datum hinzugefügt',
	M2M_INITIAL_ACTIVATION_DATE: 'M2M-Aktivierungsdatum',
	NOTES: 'Anmerkungen',
	SAVE_NOTE: 'Anmerkung speichern',
	NOTE_TEXTAREA_PLACEHOLDER: 'Anmerkung hier eingeben (max. 2000 Zeichen)',
	ADD_NOTE_SUCCESS: 'Anmerkung gespeichert',
	ADD_NOTE_ERROR: 'Anmerkung konnte nicht gespeichert werden, bitte erneut versuchen.',
	DELETE_NOTE_SUCCESS: 'Anmerkung gelöscht',
	DELETE_NOTE_ERROR: 'Anmerkung konnte nicht gelöscht werden, bitte erneut versuchen.',
	DELETE_NOTE: 'Anmerkung löschen',
	TICKETING_STATUS: 'Ticketing-Status',
	UPDATE_NOTE_STATUS: 'Anmerkungs-Aktualisierungsstatus',
	LAST_UPDATE: 'Letzte Aktualisierung',
	SUBMISSION_LOCATION: 'Ort der Einreichung',
	RULE_STATE: 'Regelstatus',
	RULE_STATE_ACTIVE: 'Aktiv',
	YES: 'Ja',
	NO: 'Nein',
	// ANALYSEN
	CHART_CATEGORIES: 'Diagrammkategorien',
	GROUP_DATA_BY: 'Daten gruppieren nach',
	CHART_OPTIONS: 'Diagrammoptionen',
	CREATE_NEW_CHART: 'Neues Diagramm erstellen',
	EDIT_CHART: 'Diagramm bearbeiten',
	DISCLAIMER: 'Ausschlusserklärung: ',
	DATE_RANGE: 'DATUMSBEREICH',
	SHOW: 'ANZEIGEN',
	CYCLE: 'ZYKLUS',
	INCREMENT_BY: 'ERHÖHEN UM',
	SELECT_CUSTOM_DATE_RANGE: 'Benutzerdefinierten Datumsbereich auswählen',

	// Rechnungsstellung
	INVOICE: 'Rechnung',
	INVOICE_DATE: 'Rechnungsdatum',
	CUSTOMER_NAME: 'Kundenname',
	DOCUMENT_ID: 'Dokument-ID',
	COUNTRY_NAME: 'Ländername',
	TABLE_COLUMNS_INVOICES_CHANGE_SUCCESS_TITLE: 'Einstellungen der Rechnungstabelle erfolgreich geändert!',
	TABLE_COLUMNS_INVOICES_CHANGE_SUCCESS_MESSAGE: 'Einstellungen der Rechnungstabelle wurden erfolgreich aktualisiert.',
	TABLE_COLUMNS_INVOICES_CHANGE_ERROR_TITLE: 'Fehler beim Ändern von Rechnungstabellenspalten!',
	TABLE_COLUMNS_INVOICES_CHANGE_ERROR_MESSAGE: 'Rechnungstabellenspalten konnten diesmal nicht aktualisiert werden. Bitte versuchen Sie es noch einmal.',
	INVOICE_DETAILS: 'Rechnungsdetails',

	// TICKETING
	TICKET_CREATED_DATE: 'Ticket-Erstellungsdatum',
	UPDATED_NOTE_STATUS_DATE: 'Datum der Anmerkungs-Statusaktualisierung',
	LAST_UPDATED_DATE: 'Datum der letzten Aktualisierung',
	UPDATE_NOTE: 'Anmerkung aktualisieren',
	TABLE_TICKETS_COLUMNS_CHANGE_SUCCESS_TITLE: 'Einstellungen der Ticket-Tabelle erfolgreich geändert!',
	TABLE_TICKETS_COLUMNS_CHANGE_SUCCESS_MESSAGE: 'Einstellungen der Ticket-Tabelle wurden erfolgreich aktualisiert.',
	TABLE_TICKETS_COLUMNS_CHANGE_ERROR_TITLE: 'Fehler beim Ändern von Ticket-Tabellenspalten!',
	TABLE_TICKETS_COLUMNS_CHANGE_ERROR_MESSAGE: 'Die Ticket-Tabellenspalten konnten zu diesem Zeitpunkt nicht aktualisiert werden. Bitte versuchen Sie es noch einmal.',

	TODAY: 'Heute',
	PREVIOUS_DAY: 'Vorheriger Tag',
	LAST_10_DAYS: 'Letzte 10 Tage',
	LAST_30_DAYS: 'Letzte 30 Tage',
	CUSTOM_RANGE: 'Benutzerdefinierter Bereich',
	CURRENT_CYCLE_TO_DATE: 'Aktueller Zyklus bis dato',
	HISTORICAL: 'Historisch',
	WEEK: 'Woche',
	SUM_TOTALS: 'Gesamtsumme',
	AVERAGE_TOTALS: 'Summendurchschnitt',
	LINE_GRAPH: 'Liniendiagramm',
	BAR_GRAPH: 'Balkendiagramm',
	WORLD_MAP_GRAPH: 'Weltkartendiagramm',
	TOTAL_DATA_USAGE: 'Gesamtdatennutzung',
	ENDPOINT_COUNT_TOTALS: 'Gesamtzahl Endpunkte',
	NO_USAGE_SIMS: 'SIMs ohne Nutzung',
	TOTAL: 'Summe',
	AVERAGE_USAGE: 'Durchschnitt',
	DIRECTION_INDEX: 'Richtungsindex',
	PIE_CHART: 'Kreisdiagramm',
	ERROR_WHILE_ADDING_TICKET: 'Fehler beim Hinzufügen des Tickets',

	SELECT_M2M_PLATFORM: 'Wählen Sie Ihre M2M-Plattform',
	SELECT_M2M_PLATFORM_ERROR: 'Bitte wählen Sie zunächst die Plattform aus, um Daten zu populieren',
	SELECT_YOUR_ACCOUNT: 'Wählen Sie Ihr Konto',
	CHOOSE_M2M_PLATFORM: 'Wählen Sie Ihre M2M-Plattform',
	CHOOSE_YOUR_ACCOUNT: 'Wählen Sie Ihr Konto',

	TOTAL_VOICE_USAGE: 'Gesamt-Voice-Nutzung',
	TOTAL_SMS_USAGE: 'Gesamt-SMS-Nutzung',
	SAVE_AND_CLOSE: 'Speichern und schließen',
	// Sicherheitsfunktionen
	SECURITY_TABS_1: 'IMEI-Whitelist',
	SECURITY_TABS_2: 'SMS-Whitelist',
	SECURITY_TABS_3: 'VOICE-Whitelist',
	SECURITY_TABS_4: 'IP, URL, PORT-Blacklist',
	SECURITY_TABS_5: 'IP-Bereichs-Whitelist',
	SECURITY_FEATURES_1: 'IMEI',
	SECURITY_FEATURES_2: 'SMS',
	SECURITY_FEATURES_3: 'VOICE',
	SECURITY_FEATURES_4: 'IP, URL, PORT',
	SECURITY_FEATURES_5: 'IP-BEREICH',
	SECURITY_FEATURES_SUCCESSFULLY_ADD: 'Daten erfolgreich geändert',
	SECURITY_FEATURES_ERROR_ADD: 'Fehler beim Aktualisieren der Daten',
	SECURITY_FEATURES_ADD_1: 'Neue IMEI der Whitelist hinzufügen',
	SECURITY_FEATURES_ADD_2: 'Neue SMS der Whitelist hinzufügen',
	SECURITY_FEATURES_ADD_3: 'Neue VOICE der Whitelist hinzufügen',
	SECURITY_FEATURES_ADD_4: ' Neue(n/s) IP, URL, PORT der Blacklist hinzufügen',
	SECURITY_FEATURES_ADD_5: 'Neuen IP-Bereich der Whitelist hinzufügen',
	SECURITY_FEATURES_EDIT_1: 'IMEI-Whitelist bearbeiten',
	SECURITY_FEATURES_EDIT_2: 'SMS-Whitelist bearbeiten',
	SECURITY_FEATURES_EDIT_3: 'VOICE-Whitelist bearbeiten',
	SECURITY_FEATURES_EDIT_4: ' IP,URL,PORT-Blacklist bearbeiten',
	SECURITY_FEATURES_EDIT_5: 'IP-Bereichs-Whitelist bearbeiten',
	SECURITY_FEATURES_M2M: 'M2M-Plattform auswählen',
	SECURITY_FEATURES_REMOVE: 'Aus Whitelist entfernen',
	UPDATE: 'Aktualisieren',
	SECURITY_FEATURES: 'Sicherheitsfunktionen',
	M2M_ACCOUNT_NAME: 'M2M-KONTONAME',
	DATA_SUBMITTED: 'DATEN ABGESENDET',
	FILTER_CATEGORY_M2MACCOUNT: 'M2M-Konto',
	SECURITY_FEATURES_SUCCESSFULLY_EXPORT: 'Erfolgreich exportiert',
	SECURITY_FEATURES_FAIL_EXPORT: 'Export fehlgeschlagen',
	COUNTRYCODE: 'Land',
	MINUTE: 'Minute',
	MINUTES: 'Minuten',
	CHART_LEFT_AXIS_MINUTE: 'Min.',
	CHART_LEFT_AXIS_MINUTES: 'Min.',
	CONSUMED_MINUTE: 'Verbrauchte Minuten',
	CONSUMED_MB: 'Verbrauchte Megabyte',
	CONSUMED_GB: 'Verbrauchte Gigabyte',
	CONSUMED_TB: 'Verbrauchte Terabyte',
	CONSUMED_PB: 'Verbrauchte Petabyte',
	CONSUMED_SMS: 'Verbrauchte SMS',
	NO_DESCRIPTION_FOR_GRAPH: 'Keine Beschreibung für dieses Diagramm',
	GRAPHS: 'Aktuell verfügbare Diagramme',
	CONSUMED_COUNT: 'Anzahl der Endpunkte',
	DOWNLOAD_CHART_AS_PNG: 'PNG herunterladen',
	CUSTOMER_INVOICE_ID: 'Kunden-ID',
	INVOICE_ID: 'Rechnungs-ID',
	CUSTOMER_INVOICE_NAME: 'Unterkonto',
	INVOICE_COUNTRY: 'Land',
	ACCESSTECHNOLOGY: 'Zugangstechnologie',
	RATINGZONE: 'Bewertungszone',
	RATEPLAN: 'Tarifplan',
	CHOOSE_FILTERS_ON_ANALYTICS_MODAL: 'Bitte wählen Sie im ersten Schritt eine Kategorie aus',
	NO_FILTERS_ON_ANALYTICS_MODAL: 'Keine Filter für diese Kategorie verfügbar',
	BILLING_CYCLE: 'Abrechnungszyklus',
	FETCHING_DATA: 'Bitte warten. Daten werden abgerufen ...',
	NO_DATA_RECEIVED: 'Keine Daten empfangen',
	NO_FILTERS_AVAILABLE: 'Keine Filter verfügbar',
	DATE_SUBMITTED: 'Sendedatum',
	NO_REPORTS_DEFINED: 'Keine Berichte definiert',
	APPLY: 'Übernehmen',
	OPEN_CHART_SETTINGS_PAGE: 'Diagrammeinstellungen',
	CUSTOMIZE_CHART_DATA: 'Diagrammdaten spezifisch anpassen',
	ESTIMATED_AVERAGE_USAGE: 'Geschätzte durchschnittliche Nutzung',
	DELETE_DEFINED_CHART_SETTING_QUESTION: 'Definierte Diagrammeinstellung löschen?',
	DELETE_DEFINED_CHART_SETTING_MESSAGE: 'Einstellung wirklich löschen',
	TOTAL_COUNT_BY_SEVERITY: 'Tickets nach Schweregrad',
	TOTAL_COUNT_BY_STATUS: 'Tickets nach Status',
	INVALID_IP_ADDRESS: 'Ungültige IP-Adresse',
	INVOICENUMBER: 'Rechnungsnummer',
	CUSTOMERNAME: 'Kundenname',
	COUNTRYNAME: 'Ländername',
	CUSTOMERID: 'Kunden-ID',
	DEVICE_WAKE_UP: 'Geräteweckruf',
	DEVICE_WAKE_UP_NOTIFICATION_TITLE: 'Geräteweckruf',
	DEVICE_WAKE_UP_NOTIFICATION_MESSAGE_SUCCESS: 'Geräteweckruf erfolgreich',
	DEVICE_WAKE_UP_NOTIFICATION_MESSAGE_FAIL: 'Geräteweckruf fehlgeschlagen',

	FILES_FAILED_TO_UPLOAD_MESSAGE: 'Dateien konnten nicht hochgeladen werden: ',
	ADD_ANOTHER_TRESHOLD: 'Weiteren Schwellenwert hinzufügen',
	PIE_CHART_TOTAL: 'Summe',
	ADD_ATTACHMENT: 'Anlage hinzufügen',
	TICKET_FILE_UPLOAD_ERROR: 'Auf dem Backend-System ist ein Fehler aufgetreten. Einige Dateien wurden daher nicht aktualisiert.',
	FILE_TO_LARGE_TO_UPLOAD: 'Datei zu groß für Upload. Maximale Dateigröße beträgt 3 MB.',
	RETRY: 'Erneut versuchen',
	NO_CATEGORIES: 'Für dieses Konto sind keine Kategorien verfügbar',
	ZONE_NAME: 'Zonenname',
	POOL_ID: 'Pool-ID',

	// ANMERKUNGEN RECHNUNGEN
	ADD_NEW_NOTE: 'Anmerkung hinzufügen',
	EDIT_NOTE: 'Anmerkung bearbeiten',
	ADD_NOTE_PLACEHOLDER: 'Anmerkung hier eingeben',
	INVOICE_NOTE_SUCCESSFULLY_UPDATED: 'Rechnungsanmerkung erfolgreich aktualisiert',
	INVOICE_NOTE_UPDATE_FAIL: 'Rechnungsaktualisierung fehlgeschlagen',
	ATTACHMENTS_ADDED: 'Anlagen hinzugefügt',
	EXTERNAL_LAST_UPDATED_BY: 'Letzte Aktualisierung durch',
	EXTERNAL_LAST_UPDATED_DATE: 'Datum/Uhrzeit letzte Aktualisierung',
	REMOVE: 'Entfernen',
	EndpointStatus: 'Status',
	NetworkOperator: 'Netzbetreiber',
	NetworkCountry: 'Netzland',
	NetworkType: 'Netzwerktyp',
	TransferStatus: 'IMSI-Übertragungsstatus',
	NetworkEntitlement: 'Netzwerkanspruchs-ID',
	RecredentialTransferStatus: 'Status erneute Zulassung',
	M2MDateAdded: 'M2M-Datum hinzugefügt',
	M2MInitialActivationDate: 'M2M-Aktivierungsdatum',
	LAST_UPDATED_BY: 'Letzte Aktualisierung durch',
	LAST_UPDATED: 'Letzte Aktualisierung',
	INVOICE_NOTE_FILE_ERROR: 'Auf dem Backend-System ist ein Fehler aufgetreten. Einige Dateien wurden daher nicht hochgeladen.',

	BATCH_HISTORY: 'Batch-Verlauf',
	// DIAGNOSTIK HLRHSSDetails
	GET_HLR_HSS_DETAILS: 'HLR/HSS-Details abrufen',
	APN_IDENTIFIER: 'APN-Identifikator',
	IP_ADDRESS_TYPE: 'IP-Adresstyp',
	QOS_PROFILE: 'QOS-Profil',
	PDN_GW_DYNAMIC_ALLOCATION: 'PDN GW dynamische Zuordnung',
	VPLMN_ADDRESS_ALLOWED: 'VPLMN-Adresse zulässig',
	MAX_UPLOAD: 'Max. Upload',
	MAX_DOWNLOAD: 'Max. Download',
	SUBSCRIBER_PROFILE: 'Abonnentenprofil',
	ENDPOINT_ID_ICCID: 'Endpunkt-ID – ICCID',
	BARRING_STATUS: 'Ausgenommen Status',
	NETWORK_ACCESS: 'Netzwerkzugang',
	CURRENT_VLR_NUMBER: 'Aktuelle VLR-Nummer',
	PREVIOUS_VLR_NUMBER: 'Vorherige VLR-Nummer',
	CURRENT_SGSN_NUMBER: 'Aktuelle SGSN-Nummer',
	PREVIOUS_SGSN_NUMBER: 'Vorherige SGSN-Nummer',
	ROAMING_RESTRICTION: 'Roaming-Einschränkung',
	DATA_3G_ENABLED: '3G-Daten aktiviert',
	NETWORK_STATUS: 'Netzwerkstatus',
	APN_LIST: 'APN-Liste',

	// DATENVERLAUF
	DATA_HISTORY: 'Datenverlauf',
	DATA_VOLUME_DOWN_KB: 'Datenvolumen Downlink (KB)',
	DATA_VOLUME_UP_KB: 'Datenvolumen Uplink (KB)',
	DURATION_MINUTES: 'Dauer (Minuten)',
	SESSION_CHARGING_ID: 'Berechnungs-ID Sitzung',
	ACCESS_POINT_NAME: 'APN',
	DEVICE_IP_V4: 'Geräte-IPv4-Adresse',
	DEVICE_IP_V6: 'Geräte-IPv6-Adresse',
	RATING_ZONE_NAME: 'Name der Bewertungszone',
	RATING_GROUP_ID_NAME: 'Bewertungsgruppen-ID-Name',
	RATING_GROUP_ID: 'Bewertungsgruppen-ID',

	// SMS-VERLAUF WING
	MESSAGE_HISTORY: 'Nachrichtenverlauf',
	PORTAL_MESSAGES: 'Portal-Nachrichten',
	SMS_LOG_ID: 'SMS-Protokoll-ID',
	DATE_SENT_RECEIVED: 'Sende-/Empfangsdatum',
	SENT_FROM: 'Gesendet von',
	SENT_TO: 'Gesendet an',
	MESSAGE_TYPE: 'Nachrichtentyp',
	ZONE_MODEL: 'Zonenmodell',

	CTD_DATA_USAGE: 'CTD-Datennutzung (Megabyte)',
	CTD_SMS_MO_MT_USAGE: 'CTD SMS-Nutzung (MSG)',
	CTD_VOICE_USAGE: 'CTD-Voice-Nutzung',

	ACCOUNTS: 'Konten',
	MUST_BE_BETWEEN_CHARACTERS_LONG: 'Muss zwischen {min} und {max} Zeichen lang sein',
	INVALID_DOMAIN_FORMAT: 'Ungültiges Domänenformat',
	LAST_UPDATE_DATE_TIME: 'Datum/Uhrzeit letzte Aktualisierung',
	LAST_UPDATED_BY_USER: 'Letzte Aktualisierung durch Benutzer',
	INSESSION: 'In Sitzung',
	PLEASE_WAIT_THIS_MAY_TAKE_A_FEW_MINUTES: 'Bitte warten. Dies kann je nach Dateigröße einige Minuten dauern.',
	NO_RESULTS: 'Keine Ergebnisse',
	SELECTED_RATE_PLAN: 'Ausgewählter Tarifplan',
	HSPA: 'HSPA',
	LTE: 'LTE',
	VLR: 'VLR',
	SGSN: 'SGSN',
	EXPORT: 'Exportieren',
	FILE: 'Datei',
	ERROR_CODE: 'Fehlercode',
	ERROR_TYPE: 'Fehlertyp',
	AT_LINE: 'Auf Zeile',
	EXPORT_TICKETS: 'Tickets exportieren',
	EXPORT_USER_USAGE_DATA: 'Nutzungsdaten der Benutzer exportieren',
	PLACEHOLDER_MODEL: 'AT&T Z431',
	NO_BATCH_ACTIONS_AVAILABLE: 'Keine Batch-Aktionen verfügbar',
	NO_BATCH_ACTIONS_AVAILABLE_SUBTITLE: 'Für diese Tabelle sind derzeit keine Batch-Aktionen zur Ansicht verfügbar',
	NO_BATCH_REPORTS_AVAILABLE: 'Keine Batch-Berichte verfügbar',
	NO_BATCH_REPORTS_AVAILABLE_SUBTITLE: 'Für diese Tabelle sind derzeit keine Batch-Berichte zur Ansicht verfügbar',
	MOBILE_ORIGINATING: 'Mobil-Versand',
	MOBILE_TERMINATING: 'Mobil-Empfang',
	NA: 'n. z.',
	GMNA: 'GM North America',
	POD3: 'ACC Pod3',
	POD19: 'Enterprise',
	TABS_ADD_ICON: 'Tabelle benutzerdef. Endpunkte erstellen',
	USAGE_SUMMARIES: 'Nutzungszusammenfassung',
	EMPTY_SPACE: ' ',
	// Endpunkttarifplan
	'ENDPOINTS_RATE_PLAN.RATE_PLAN_TYPE': 'Tarifplantyp',
	'ENDPOINTS_RATE_PLAN.INCLUDED_TEST_DATA': 'Inbegriffene Testdaten',
	'ENDPOINTS_RATE_PLAN.BILLING_PLATFORM': 'Abrechnungsplattform',
	'ENDPOINTS_RATE_PLAN.ACTIVATION_FEE': 'Aktivierungsgebühr',
	'ENDPOINTS_RATE_PLAN.INCLUDED_TEST_SMS': 'Inbegriffene Test-SMS',
	'ENDPOINTS_RATE_PLAN.BILLING_ACCOUNT_ID': 'Rechnungskonto-ID',
	'ENDPOINTS_RATE_PLAN.MSU_PRICE': 'MSU-Preis',
	'ENDPOINTS_RATE_PLAN.INCLUDED_TEST_VOICE': 'Inbegriffene Test-Voice',
	'ENDPOINTS_RATE_PLAN.ZONE_MODEL_ID': 'Zonenmodell-ID',
	'ENDPOINTS_RATE_PLAN.TYPE': 'Typ',
	'ENDPOINTS_RATE_PLAN.INCLUDED_MSU': 'Inbegriffene MSU',
	'ENDPOINTS_RATE_PLAN.VALID_FROM': 'Gültig ab',
	'ENDPOINTS_RATE_PLAN.DEFAULT_RATE_PLAN': 'Standard-Tarifplan',
	'ENDPOINTS_RATE_PLAN.BASE_RATE_PLAN': 'Basis-Tarifplan',
	'ENDPOINTS_RATE_PLAN.POSTPAID': 'Nachträgliche Zahlung',
	'ENDPOINTS_RATE_PLAN.APPROVED': 'Genehmigt',
	'ENDPOINTS_RATE_PLAN.MONTHLY': 'MONATLICH',
	PASSWORDS_DO_NOT_MATCH: 'Passwörter stimmen nicht überein',
	NO_WHITE_SPACE_ONLY: 'Text sollte Buchstaben und/oder Zahlen enthalten',
	NO_RATE_PLAN_SELECTED: 'kein Tarifplan ausgewählt',
	NO_RATE_PLANS_UNDERLYING_PLATFORM: 'Die zugrunde liegende Plattform ist fehlerhaft und der Tarifplans kann derzeit nicht geändert werden',
	ENDPOINT_PROVISIONED_STATE_CHANGE_SUCCESS: 'Endpunkt-ID {args1} erfolgreich bereitgestellt. IMSI-Status geändert zu {args2}',
	ENDPOINT_PROVISIONED_STATE_CHANGE_FAIL: 'Endpunkt-ID {args1} Bereitstellung nach IMSI-Statusänderung fehlgeschlagen.  Support erhalten',

	// AUTH ANMELDUNG RÜCKRUF
	AUTHENTICATION_FAILED: 'Authentifizierung fehlgeschlagen',
	AUTHENTICATION_IN_PROGRESS: 'Authentifizierung läuft. Bitte warten...',
	MEGABYTES: 'Megabytes',
	TICKETS: 'Tickets',
	USER_GROUPS: 'Benutzergruppen',
	RECENT_SESSIONS: 'Jüngste Sitzungen',
	ACTIVE_SESSION_SESSION_START_TIME: 'SITZUNGS-STARTZEIT',
	ACTIVE_SESSION_TOTAL_VOLUME: 'GESAMTVOLUMEN',
	ACTIVE_SESSION_TRANSMITTING: 'ÜBERTRAGUNG',
	ACTIVE_SESSION_RECEIVING: 'EMPFANG',
	CURRENTLY_ACTIVE_SESSION: 'Aktuell aktive Sitzung',
	ACTIVE_SESSION_CARRIER: 'BETREIBER',
	ACTIVE_SESSION_IP_ADDRESS: 'IP-ADRESSE',
	RESET_TO_DEFAULT: 'Auf Voreinstellung zurücksetzen',
	DATE_TIME: 'Datum/Uhrzeit',
	SESSION_END: 'Sitzungsende',
	SEND: 'Senden',
	SEND_SMS_TO_THIS_DEVICE: 'SMS an dieses Gerät senden.',
	TYPE_A_MESSAGE: 'Geben Sie eine Nachricht ein, die Sie per SMS an dieses Gerät schicken möchten. Es gelten die gegebenen Standardtarife',
	MESSAGES_WILL_APPEAR: 'Nachrichten werden als gesendet aufgeführt und erscheinen in den SMS-Nutzungsberichten. Ggf. fallen Gebühren an.',
	SENT_MESSAGES_HISTORY: 'Verlauf gesendeter Nachrichten',
	CHARACTERS_REMAINING: '{remaining} Zeichen verbleibend...',
	EXPORT_SESSIONS_DATA_FOR_ID: 'Jüngste Sitzungen für {id}',
	EXPORT_DATA: 'Daten exportieren',
	BYTE_ABBREVIATION: 'B',
	UTC_TIME_ZONE: ' UTC ',
	SEND_SMS_TO_ENDPOINT: 'SMS an Endpunkt senden',
	SMS: 'SMS',
	OFFPEAK_USAGE_FLAG: 'OffPeak-Nutzungs-Flag',
	DATA_USAGE_TOTAL: 'Datennutzung – roh gesamt',
	DATA_USAGE_UPLINK: 'Datennutzung – roh Uplink',
	DATA_USAGE_DOWNLINK: 'Datennutzung – roh Downlink',
	DATA_USAGE_ROUNDED: 'Datennutzung – gerundet',
	DEVICE_IP_ADDRESS: 'Geräte-IP-Adresse',
	SESSION_DURATION: 'Sitzungsdauer',
	RECORD_SEQUENCE_NUMBER: 'Datensatzsequenznummer',
	CHARGING_ID: 'Berechnungs-ID',
	SESSION_CLOSE_CAUSE: 'Ursache des Schließens der Sitzung',
	TECH_TYPE: 'Tech-Typ',
	PGW_ADDRESS: 'PGW-Adresse',
	TIME_OF_FIRST_USAGE: 'Zeitpunkt der ersten Nutzung',
	TIME_OF_LAST_USAGE: 'Zeitpunkt der letzten Nutzung',
	TIME_USAGE_DURATION: 'Zeitpunkt der Nutzungsdauer',
	STREAM_ID: 'Stream-ID',
	CELL_GLOBAL_IDENTIFIER: 'CGI (Cell Global Identifier)',
	SERVICE_AREA_IDENTIFIER: 'SAI (Service Area Identifier)',
	ROUTING_AREA_IDENTIFIER: 'RAI (Routing Area Identifier)',
	TRACKING_AREA_IDENTITY: 'TAI (Tracking Area Identity)',
	E_UTRAN_CGI: 'ECGI (E-UTRAN CGI)',
	LOCATION_AREA_IDENTIFIER: 'LAI (Location Area Identifier)',
	SERVING_SGSN: 'Serving-SGSN',
	COUNTRY_LASTEST_SESSION: 'Land (letzte Sitzung)',
	CARRIER_LASTEST_SESSION: 'Betreiber (letzte Sitzung)',
	BILLABLE_FLAG: 'Flag für „In Rechnung zu stellen“',
	CYCLE_MONTH: 'Zyklus Monat',
	CYCLE_YEAR: 'Zyklus Jahr',
	ASSIGNED_RATE_PLAN_ID: 'Zugewiesene Tarifplan-ID',
	ASSIGNED_RATING_ZONE_ID: 'Zugewiesene Bewertungszonen-ID',
	RATE_PLAN_VERSION_ID: 'Tarifplan-Versions-ID',
	SERVICE_TYPE: 'Servicetyp',
	TRAFFIC_DETAIL_ID: 'Traffic-Detail-ID',
	RECORD_RECEIVED_DATE: 'Datum des Datensatzempfangs',
	RECORD_OPEN_TIME: 'Zeitpunkt der Datensatzöffnung',
	OPERATOR_NETWORK: 'Bedienernetzwerk',
	OPERATOR_ACCOUNT_ID: 'Bedienerkonto-ID',
	TAP_CODE: 'TAP-Code',
	USAGE_HISTORY: 'Nutzungsverlauf',
	DATA: 'Daten',
	VOICE: 'Voice',
	HLR_HSS_DETAILS_FOR_ENDPOINT: 'HLR/HSS-Details für Endpunkt-ID{id}',
	NEED_HELP: 'Brauchen Sie Hilfe?',
	MB: 'MB',
	CONNECTION_STATUS: 'Verbindungsstatus',
	MESSAGE_CONTENT: 'Nachrichteninhalte',
	B: 'B',
	CYCLE_TO_DATE_USAGE_TOTAL: 'Zyklus bis dato - Nutzung gesamt',
	AVERAGE_DAILY_USE: 'Durchschn. tägliche Nutzung',
	DATA_USAGE_DOWNLINK_CHART: 'Downlink',
	DATA_USAGE_UPLINK_CHART: 'Uplink',
	GDSP: 'GDSP',
	SECOND: 'Sekunde',
	SECONDS: 'Sekunden',
	DURATION_RAW: 'Dauer - Roh',
	DURATION_ROUNDED: 'Dauer - Gerundet',
	USAGE_TYPE: 'Nutzungstyp',
	SELECT_BILLING_CYCLE: 'Abrechnungszyklus auswählen',
	RANGE: 'Bereich',
	CUSTOM_FIELDS_VALUES_UPDATED: 'Werte für benutzerdefinierte Felder wurden aktualisiert',
	CUSTOM_FIELDS_VALUES_UPDATED_ERROR: 'Werte für benutzerdefinierte Felder wurden nicht aktualisiert',
	SXMACC: 'SXMACC',
	CHARTS_BILLING_CYCLE_ABBREVIATION: 'RZ {billcycle}',
	DATE_RANGE_INPUT_LABEL: 'Von: {startDate} Bis: {endDate}',
	UH_OH_THERE_WERE_SOME_PROBLEMS_TRYING_TO_LOG_YOU_IN_PLEASE_RETRY_AGAIN_IN_A_FEW_MOMENTS: 'Beim Anmelden sind leider Probleme aufgetreten. Versuchen Sie es bitte nach kurzem Warten erneut.  Sollte das Problem weiter bestehen, wenden Sie sich bitte an den Support.',

	//  SPRACHOPTIONEN
	ENGLISH: 'English (USA)',
	FRANCAIS: 'Français (France)',
	ESPANOL: 'Español (España)',
	ITALIANO: 'Italiano (Italia)',
	NEDERLANDS: 'Netherlands (Holland)',
	DEUTCHE: 'Deutsch (Deutschland)',
	PORTUGUES: 'Português (Brasil)',
	CHINESE: '中文 (中国)',

	//  LÄNDER
	COUNTRY_UNITED_STATES: 'USA',
	COUNTRY_AFGHANISTAN: 'Afghanistan',
	COUNTRY_ALAND_ISLANDS: 'Aland-Inseln',
	COUNTRY_ALBANIA: 'Albanien',
	COUNTRY_ALGERIA: 'Algerien',
	COUNTRY_AMERICAN_SAMOA: 'Amerikanisch-Samoa',
	COUNTRY_ANDORRA: 'Andorra',
	COUNTRY_ANGOLA: 'Angola',
	COUNTRY_ANGUILLA: 'Anguilla',
	COUNTRY_ANTARCTICA: 'Antarktis',
	COUNTRY_ANTIGUA_AND_BARBUDA: 'Antigua und Barbuda',
	COUNTRY_ARGENTINA: 'Argentinien',
	COUNTRY_ARMENIA: 'Armenien',
	COUNTRY_ARUBA: 'Aruba',
	COUNTRY_AUSTRALIA: 'Australien',
	COUNTRY_AUSTRIA: 'Österreich',
	COUNTRY_AZERBAIJAN: 'Aserbaidschan',
	COUNTRY_BAHAMAS_THE: 'Bahamas',
	COUNTRY_BAHRAIN: 'Bahrain',
	COUNTRY_BANGLADESH: 'Bangladesch',
	COUNTRY_BARBADOS: 'Barbados',
	COUNTRY_BELARUS: 'Weißrussland',
	COUNTRY_BELGIUM: 'Belgien',
	COUNTRY_BELIZE: 'Belize',
	COUNTRY_BENIN: 'Benin',
	COUNTRY_BERMUDA: 'Bermuda',
	COUNTRY_BHUTAN: 'Bhutan',
	COUNTRY_BOLIVIA: 'Bolivien',
	COUNTRY_BOSNIA_AND_HERZEGOVINA: 'Bosnien und Herzegowina',
	COUNTRY_BOTSWANA: 'Botswana',
	COUNTRY_BOUVET_ISLAND: 'Bouvetinsel',
	COUNTRY_BRAZIL: 'Brasilien',
	COUNTRY_BRITISH_INDIAN_OCEAN_TERRITORY: 'Britisches Territorium im Indischen Ozean',
	COUNTRY_BRUNEI: 'Brunei',
	COUNTRY_BULGARIA: 'Bulgarien',
	COUNTRY_BURKINA_FASO: 'Burkina Faso',
	COUNTRY_BURUNDI: 'Burundi',
	COUNTRY_CAMBODIA: 'Kambodscha',
	COUNTRY_CAMEROON: 'Kamerun',
	COUNTRY_CANADA: 'Kanada',
	COUNTRY_CAPE_VERDE: 'Kap Verde',
	COUNTRY_CAYMAN_ISLANDS: 'Caymaninseln',
	COUNTRY_CENTRAL_AFRICAN_REPUBLIC: 'Zentralafrikanische Republik',
	COUNTRY_CHAD: 'Tschad',
	COUNTRY_CHILE: 'Chile',
	COUNTRY_CHINA: 'China',
	COUNTRY_CHRISTMAS_ISLAND: 'Weihnachtsinsel',
	COUNTRY_COCOS_KEELING_ISLANDS: 'Cocos (Keeling)-Inseln',
	COUNTRY_COLOMBIA: 'Kolumbien',
	COUNTRY_COMOROS: 'Comoros',
	COUNTRY_CONGO: 'Kongo',
	COUNTRY_CONGO_THE_DEMOCRATIC_REPUBLIC_OF_THE: 'Demokratische Republik Kongo',
	COUNTRY_COOK_ISLANDS: 'Cookinseln',
	COUNTRY_COSTA_RICA: 'Costa Rica',
	COUNTRY_COTE_D__IVOIRE_IVORY_COAST_: 'Cote D’Ivoire (Elfenbeinküste)',
	COUNTRY_CROATIA_HRVATSKA_: 'Kroatien (Hrvatska)',
	COUNTRY_CUBA: 'Kuba',
	COUNTRY_CYPRUS: 'Zypern',
	COUNTRY_CZECH_REPUBLIC: 'Tschechische Republik',
	COUNTRY_DENMARK: 'Dänemark',
	COUNTRY_DJIBOUTI: 'Dschibuti',
	COUNTRY_DOMINICA: 'Dominica',
	COUNTRY_DOMINICAN_REPUBLIC: 'Dominikanische Republik',
	COUNTRY_EAST_TIMOR: 'Osttimor',
	COUNTRY_ECUADOR: 'Ecuador',
	COUNTRY_EGYPT: 'Ägypten',
	COUNTRY_EL_SALVADOR: 'El Salvador',
	COUNTRY_EQUATORIAL_GUINEA: 'Äquatorialguinea',
	COUNTRY_ERITREA: 'Eritrea',
	COUNTRY_ESTONIA: 'Estland',
	COUNTRY_ETHIOPIA: 'Äthiopien',
	COUNTRY_FALKLAND_ISLANDS: 'Falklandinseln',
	COUNTRY_FAROE_ISLANDS: 'Färöer',
	COUNTRY_FIJI_ISLANDS: 'Fidschi',
	COUNTRY_FINLAND: 'Finnland',
	COUNTRY_FRANCE: 'Frankreich',
	COUNTRY_FRENCH_GUIANA: 'Französisch Guyana',
	COUNTRY_FRENCH_POLYNESIA: 'Französisch-Polynesien',
	COUNTRY_FRENCH_SOUTHERN_TERRITORIES: 'Französische Südgebiete',
	COUNTRY_GABON: 'Gabun',
	COUNTRY_GAMBIA_THE: 'Gambia',
	COUNTRY_GEORGIA: 'Georgien',
	COUNTRY_GERMANY: 'Deutschland',
	COUNTRY_GHANA: 'Ghana',
	COUNTRY_GIBRALTAR: 'Gibraltar',
	COUNTRY_GREECE: 'Griechenland',
	COUNTRY_GREENLAND: 'Grönland',
	COUNTRY_GRENADA: 'Grenada',
	COUNTRY_GUADELOUPE: 'Guadeloupe',
	COUNTRY_GUAM: 'Guam',
	COUNTRY_GUATEMALA: 'Guatemala',
	COUNTRY_GUERNSEY_AND_ALDERNEY: 'Guernsey und Alderney',
	COUNTRY_GUINEA: 'Guinea',
	COUNTRY_GUINEA_BISSAU: 'Guinea-Bissau',
	COUNTRY_GUYANA: 'Guyana',
	COUNTRY_HAITI: 'Haiti',
	COUNTRY_HEARD_AND_MCDONALD_ISLANDS: 'Heard und McDonaldinseln',
	COUNTRY_HONDURAS: 'Honduras',
	'COUNTRY_HONG_KONG_S.A.R.': 'Hongkong',
	COUNTRY_HUNGARY: 'Ungarn',
	COUNTRY_ICELAND: 'Island',
	COUNTRY_INDIA: 'Indien',
	COUNTRY_INDONESIA: 'Indonesien',
	COUNTRY_IRAN: 'Iran',
	COUNTRY_IRAQ: 'Irak',
	COUNTRY_IRELAND: 'Irland',
	COUNTRY_ISRAEL: 'Israel',
	COUNTRY_ITALY: 'Italien',
	COUNTRY_JAMAICA: 'Jamaika',
	COUNTRY_JAPAN: 'Japan',
	COUNTRY_JERSEY: 'Jersey',
	COUNTRY_JORDAN: 'Jordanien',
	COUNTRY_KAZAKHSTAN: 'Kasachstan',
	COUNTRY_KENYA: 'Kenia',
	COUNTRY_KIRIBATI: 'Kiribati',
	COUNTRY_KOREA_NORTH: 'Korea, Nord',
	COUNTRY_KOREA_SOUTH: 'Korea, Süd',
	COUNTRY_KUWAIT: 'Kuwait',
	COUNTRY_KYRGYZSTAN: 'Kirgisistan',
	COUNTRY_LAOS: 'Laos',
	COUNTRY_LATVIA: 'Lettland',
	COUNTRY_LEBANON: 'Libanon',
	COUNTRY_LESOTHO: 'Lesotho',
	COUNTRY_LIBERIA: 'Liberia',
	COUNTRY_LIBYA: 'Libyen',
	COUNTRY_LIECHTENSTEIN: 'Liechtenstein',
	COUNTRY_LITHUANIA: 'Litauen',
	COUNTRY_LUXEMBOURG: 'Luxemburg',
	'COUNTRY_MACAU_S.A.R.': 'Macau',
	COUNTRY_MACEDONIA: 'Mazedonien',
	COUNTRY_MADAGASCAR: 'Madagaskar',
	COUNTRY_MALAWI: 'Malawi',
	COUNTRY_MALAYSIA: 'Malaysia',
	COUNTRY_MALDIVES: 'Malediven',
	COUNTRY_MALI: 'Mali',
	COUNTRY_MALTA: 'Malta',
	COUNTRY_MAN_ISLE_OF_: 'Isle of Man',
	COUNTRY_MARSHALL_ISLANDS: 'Marshallinseln',
	COUNTRY_MARTINIQUE: 'Martinique',
	COUNTRY_MAURITANIA: 'Mauretanien',
	COUNTRY_MAURITIUS: 'Mauritius',
	COUNTRY_MAYOTTE: 'Mayotte',
	COUNTRY_MEXICO: 'Mexiko',
	COUNTRY_MICRONESIA: 'Mikronesien',
	COUNTRY_MOLDOVA: 'Moldau',
	COUNTRY_MONACO: 'Monaco',
	COUNTRY_MONGOLIA: 'Mongolei',
	COUNTRY_MONTSERRAT: 'Montserrat',
	COUNTRY_MOROCCO: 'Marokko',
	COUNTRY_MOZAMBIQUE: 'Mosambik',
	COUNTRY_MYANMAR: 'Myanmar',
	COUNTRY_NAMIBIA: 'Namibia',
	COUNTRY_NAURU: 'Nauru',
	COUNTRY_NEPAL: 'Nepal',
	COUNTRY_NETHERLANDS: 'Niederlande',
	COUNTRY_NETHERLANDS_ANTILLES: 'Niederländische Antillen',
	COUNTRY_NEW_CALEDONIA: 'Neukaledonien',
	COUNTRY_NEW_ZEALAND: 'Neuseeland',
	COUNTRY_NICARAGUA: 'Nicaragua',
	COUNTRY_NIGER: 'Niger',
	COUNTRY_NIGERIA: 'Nigeria',
	COUNTRY_NIUE: 'Niue',
	COUNTRY_NORFOLK_ISLAND: 'Norfolkinsel',
	COUNTRY_NORTHERN_MARIANA_ISLANDS: 'Nördliche Marianen',
	COUNTRY_NORWAY: 'Norwegen',
	COUNTRY_OMAN: 'Oman',
	COUNTRY_PAKISTAN: 'Pakistan',
	COUNTRY_PALAU: 'Palau',
	COUNTRY_PALESTINIAN_TERRITORY_OCCUPIED: 'Palästina',
	COUNTRY_PANAMA: 'Panama',
	COUNTRY_PAPUA_NEW_GUINEA: 'Papua-Neuguinea',
	COUNTRY_PARAGUAY: 'Paraguay',
	COUNTRY_PERU: 'Peru',
	COUNTRY_PHILIPPINES: 'Philippinen',
	COUNTRY_PITCAIRN_ISLAND: 'Pitcairninsel',
	COUNTRY_POLAND: 'Polen',
	COUNTRY_PORTUGAL: 'Portugal',
	COUNTRY_PUERTO_RICO: 'Puerto Rico',
	COUNTRY_QATAR: 'Qatar',
	COUNTRY_REUNION: 'Reunion',
	COUNTRY_ROMANIA: 'Rumänien',
	COUNTRY_RUSSIA: 'Russland',
	COUNTRY_RWANDA: 'Ruanda',
	COUNTRY_SAINT_HELENA: 'St. Helena',
	COUNTRY_SAINT_KITTS_AND_NEVIS: 'St. Kitts und Nevis',
	COUNTRY_SAINT_LUCIA: 'Saint Lucia',
	COUNTRY_SAINT_PIERRE_AND_MIQUELON: 'St. Pierre und Miquelon',
	COUNTRY_SAINT_VINCENT_AND_THE_GRENADINES: 'St. Vincent und die Grenadinen',
	COUNTRY_SAMOA: 'Samoa',
	COUNTRY_SAN_MARINO: 'San Marino',
	COUNTRY_SAO_TOME_AND_PRINCIPE: 'Sao Tomé und Principe',
	COUNTRY_SAUDI_ARABIA: 'Saudi-Arabien',
	COUNTRY_SENEGAL: 'Senegal',
	COUNTRY_SERBIA: 'Serbien',
	COUNTRY_SEYCHELLES: 'Seychellen',
	COUNTRY_SIERRA_LEONE: 'Sierra Leone',
	COUNTRY_SINGAPORE: 'Singapur',
	COUNTRY_SLOVAKIA: 'Slowakei',
	COUNTRY_SLOVENIA: 'Slowenien',
	COUNTRY_SOLOMON_ISLANDS: 'Salomoninseln',
	COUNTRY_SOMALIA: 'Somalia',
	COUNTRY_SOUTH_AFRICA: 'Südafrika',
	COUNTRY_SOUTH_GEORGIA: 'Südgeorgien',
	COUNTRY_SOUTH_SUDAN: 'Südsudan',
	COUNTRY_SPAIN: 'Spanien',
	COUNTRY_SRI_LANKA: 'Sri Lanka',
	COUNTRY_SUDAN: 'Sudan',
	COUNTRY_SURINAME: 'Suriname',
	COUNTRY_SVALBARD_AND_JAN_MAYEN_ISLANDS: 'Spitzbergen und Jan-Mayen-Inseln',
	COUNTRY_SWAZILAND: 'Swasiland',
	COUNTRY_SWEDEN: 'Schweden',
	COUNTRY_SWITZERLAND: 'Schweiz',
	COUNTRY_SYRIA: 'Syrien',
	COUNTRY_TAIWAN: 'Taiwan',
	COUNTRY_TAJIKISTAN: 'Tadschikistan',
	COUNTRY_TANZANIA: 'Tansania',
	COUNTRY_THAILAND: 'Thailand',
	COUNTRY_TOGO: 'Togo',
	COUNTRY_TOKELAU: 'Tokelau',
	COUNTRY_TONGA: 'Tonga',
	COUNTRY_TRINIDAD_AND_TOBAGO: 'Trinidad und Tobago',
	COUNTRY_TUNISIA: 'Tunesien',
	COUNTRY_TURKEY: 'Türkei',
	COUNTRY_TURKMENISTAN: 'Turkmenistan',
	COUNTRY_TURKS_AND_CAICOS_ISLANDS: 'Turks- und Caicosinseln',
	COUNTRY_TUVALU: 'Tuvalu',
	COUNTRY_UGANDA: 'Uganda',
	COUNTRY_UKRAINE: 'Ukraine',
	COUNTRY_UNITED_ARAB_EMIRATES: 'Vereinigte Arabische Emirate',
	COUNTRY_UNITED_KINGDOM: 'Vereinigtes Königreich',
	COUNTRY_UNITED_STATES_MINOR_OUTLYING_ISLANDS: 'United States Minor Outlying Islands',
	COUNTRY_URUGUAY: 'Uruguay',
	COUNTRY_UZBEKISTAN: 'Usbekistan',
	COUNTRY_VANUATU: 'Vanuatu',
	COUNTRY_VATICAN_CITY_STATE_HOLY_SEE_: 'Vatikanstadt',
	COUNTRY_VENEZUELA: 'Venezuela',
	COUNTRY_VIETNAM: 'Vietnam',
	COUNTRY_VIRGIN_ISLANDS_BRITISH_: 'Jungferninseln (Britisch)',
	COUNTRY_VIRGIN_ISLANDS_US_: 'Jungferninseln (USA)',
	COUNTRY_WALLIS_AND_FUTUNA_ISLANDS: 'Wallis- und Futunainseln',
	COUNTRY_WESTERN_SAHARA: 'Westsahara',
	COUNTRY_YEMEN: 'Jemen',
	COUNTRY_ZAMBIA: 'Sambia',
	COUNTRY_ZIMBABWE: 'Simbabwe',

	//  BUNDESSTAAT/PROVINZ
	STATE_ANDAMAN_AND_NICOBAR_ISLANDS: 'Andaman- und Nicobarinseln',
	STATE_ANDHRA_PRADESH: 'Andhra Pradesh',
	STATE_ARUNACHAL_PRADESH: 'Arunachal Pradesh',
	STATE_ASSAM: 'Assam',
	STATE_BIHAR: 'Bihar',
	STATE_CHANDIGARH: 'Chandigarh',
	STATE_CHHATTISGARH: 'Chhattisgarh',
	STATE_DADRA_AND_NAGAR_HAVELI: 'Dadra and Nagar Haveli',
	STATE_DAMAN_AND_DIU: 'Daman and Diu',
	STATE_DELHI: 'Delhi',
	STATE_GOA: 'Goa',
	STATE_GUJARAT: 'Gujarat',
	STATE_HARYANA: 'Haryana',
	STATE_HIMACHAL_PRADESH: 'Himachal Pradesh',
	STATE_JAMMU_AND_KASHMIR: 'Jammu and Kashmir',
	STATE_JHARKHAND: 'Jharkhand',
	STATE_KARNATAKA: 'Karnataka',
	STATE_KENMORE: 'Kenmore',
	STATE_KERALA: 'Kerala',
	STATE_LAKSHADWEEP: 'Lakshadweep',
	STATE_MADHYA_PRADESH: 'Madhya Pradesh',
	STATE_MAHARASHTRA: 'Maharashtra',
	STATE_MANIPUR: 'Manipur',
	STATE_MEGHALAYA: 'Meghalaya',
	STATE_MIZORAM: 'Mizoram',
	STATE_NAGALAND: 'Nagaland',
	STATE_NARORA: 'Narora',
	STATE_NATWAR: 'Natwar',
	STATE_ODISHA: 'Odisha',
	STATE_PASCHIM_MEDINIPUR: 'Paschim Medinipur',
	STATE_PONDICHERRY: 'Pondicherry',
	STATE_PUNJAB: 'Punjab',
	STATE_RAJASTHAN: 'Rajasthan',
	STATE_SIKKIM: 'Sikkim',
	STATE_TAMIL_NADU: 'Tamil Nadu',
	STATE_TELANGANA: 'Telangana',
	STATE_TRIPURA: 'Tripura',
	STATE_UTTAR_PRADESH: 'Uttar Pradesh',
	STATE_UTTARAKHAND: 'Uttarakhand',
	STATE_VAISHALI: 'Vaishali',
	STATE_WEST_BENGAL: 'West Bengal',
	STATE_BADAKHSHAN: 'Badakhshan',
	STATE_BADGIS: 'Badgis',
	STATE_BAGLAN: 'Baglan',
	STATE_BALKH: 'Balkh',
	STATE_BAMIYAN: 'Bamiyan',
	STATE_FARAH: 'Farah',
	STATE_FARYAB: 'Faryab',
	STATE_GAWR: 'Gawr',
	STATE_GAZNI: 'Gazni',
	STATE_HERAT: 'Herat',
	STATE_ALABAMA: 'Alabama',
	STATE_ALASKA: 'Alaska',
	STATE_ARIZONA: 'Arizona',
	STATE_ARKANSAS: 'Arkansas',
	STATE_GEORGIA: 'Georgia',
	STATE_NEW_YORK: 'New York',
	STATE_VERMONT: 'Vermont',
	STATE_WASHINGTON: 'Washington',
	STATE_HILMAND: 'Hilmand',
	STATE_JAWZJAN: 'Jawzjan',
	STATE_KABUL: 'Kabul',
	STATE_KAPISA: 'Kapisa',
	STATE_KHAWST: 'Khawst',
	STATE_KUNAR: 'Kunar',
	STATE_LAGMAN: 'Lagman',
	STATE_LAWGHAR: 'Lawghar',
	STATE_NANGARHAR: 'Nangarhar',
	STATE_NIMRUZ: 'Nimruz',
	STATE_NURISTAN: 'Nuristan',
	STATE_PAKTIKA: 'Paktika',
	STATE_PAKTIYA: 'Paktiya',
	STATE_PARWAN: 'Parwan',
	STATE_QANDAHAR: 'Qandahar',
	STATE_QUNDUZ: 'Qunduz',
	STATE_SAMANGAN: 'Samangan',
	STATE_SAR_E_PUL: 'Sar-e Pul',
	STATE_TAKHAR: 'Takhar',
	STATE_URUZGAN: 'Uruzgan',
	STATE_WARDAG: 'Wardag',
	STATE_ZABUL: 'Zabul',
	STATE_BERAT: 'Berat',
	STATE_BULQIZE: 'Bulqize',
	STATE_DELVINE: 'Delvine',
	STATE_DEVOLL: 'Devoll',
	STATE_DIBRE: 'Dibre',
	STATE_DURRES: 'Durres',
	STATE_ELBASAN: 'Elbasan',
	STATE_FIER: 'Fier',
	STATE_GJIROKASTER: 'Gjirokaster',
	STATE_GRAMSH: 'Gramsh',
	STATE_HAS: 'Has',
	STATE_KAVAJE: 'Kavaje',
	STATE_KOLONJE: 'Kolonje',
	STATE_KORCE: 'Korce',
	STATE_KRUJE: 'Kruje',
	STATE_KUCOVE: 'Kucove',
	STATE_KUKES: 'Kukes',
	STATE_KURBIN: 'Kurbin',
	STATE_LEZHE: 'Lezhe',
	STATE_LIBRAZHD: 'Librazhd',
	STATE_LUSHNJE: 'Lushnje',
	STATE_MALLAKASTER: 'Mallakaster',
	STATE_MALSI_E_MADHE: 'Malsi e Madhe',
	STATE_MAT: 'Mat',
	STATE_MIRDITE: 'Mirdite',
	STATE_PEQIN: 'Peqin',
	STATE_PERMET: 'Permet',
	STATE_POGRADEC: 'Pogradec',
	STATE_PUKE: 'Puke',
	STATE_SARANDE: 'Sarande',
	STATE_SHKODER: 'Shkoder',
	STATE_SKRAPAR: 'Skrapar',
	STATE_TEPELENE: 'Tepelene',
	STATE_TIRANE: 'Tirane',
	STATE_TROPOJE: 'Tropoje',
	STATE_VLORE: 'Vlore',
	STATE_AYN_DAFLAH: 'Ayn Daflah',
	STATE_AYN_TAMUSHANAT: 'Ayn Tamushanat',
	STATE_ADRAR: 'Adrar',
	STATE_ALGIERS: 'Algiers',
	STATE_ANNABAH: 'Annabah',
	STATE_BASHSHAR: 'Bashshar',
	STATE_BATNAH: 'Batnah',
	STATE_BIJAYAH: 'Bijayah',
	STATE_BISKRAH: 'Biskrah',
	STATE_BLIDAH: 'Blidah',
	STATE_BUIRAH: 'Buirah',
	STATE_BUMARDAS: 'Bumardas',
	STATE_BURJ_BU_ARRIRIJ: 'Burj Bu Arririj',
	STATE_GHALIZAN: 'Ghalizan',
	STATE_GHARDAYAH: 'Ghardayah',
	STATE_ILIZI: 'Ilizi',
	STATE_JIJILI: 'Jijili',
	STATE_JILFAH: 'Jilfah',
	STATE_KHANSHALAH: 'Khanshalah',
	STATE_MASILAH: 'Masilah',
	STATE_MIDYAH: 'Midyah',
	STATE_MILAH: 'Milah',
	STATE_MUASKAR: 'Muaskar',
	STATE_MUSTAGHANAM: 'Mustaghanam',
	STATE_NAAMA: 'Naama',
	STATE_ORAN: 'Oran',
	STATE_OUARGLA: 'Ouargla',
	STATE_QALMAH: 'Qalmah',
	STATE_QUSTANTINAH: 'Qustantinah',
	STATE_SAKIKDAH: 'Sakikdah',
	STATE_SATIF: 'Satif',
	STATE_SAYDA: 'Sayda',
	STATE_SIDI_BAN_AL___ABBAS: 'Sidi ban-al-’Abbas',
	STATE_SUQ_AHRAS: 'Suq Ahras',
	STATE_TAMANGHASAT: 'Tamanghasat',
	STATE_TIBAZAH: 'Tibazah',
	STATE_TIBISSAH: 'Tibissah',
	STATE_TILIMSAN: 'Tilimsan',
	STATE_TINDUF: 'Tinduf',
	STATE_TISAMSILT: 'Tisamsilt',
	STATE_TIYARAT: 'Tiyarat',
	STATE_TIZI_WAZU: 'Tizi Wazu',
	STATE_UMM_AL_BAWAGHI: 'Umm-al-Bawaghi',
	STATE_WAHRAN: 'Wahran',
	STATE_WARQLA: 'Warqla',
	STATE_WILAYA_D_ALGER: 'Wilaya d Alger',
	STATE_WILAYA_DE_BEJAIA: 'Wilaya de Bejaia',
	STATE_WILAYA_DE_CONSTANTINE: 'Wilaya de Constantine',
	STATE_AL_AGHWAT: 'al-Aghwat',
	STATE_AL_BAYADH: 'al-Bayadh',
	STATE_AL_JAZA__IR: 'al-Jaza’ir',
	STATE_AL_WAD: 'al-Wad',
	STATE_ASH_SHALIF: 'ash-Shalif',
	STATE_AT_TARIF: 'at-Tarif',
	STATE_EASTERN: 'Eastern',
	STATE_MANU__A: 'Manu’a',
	STATE_SWAINS_ISLAND: 'Swains Island',
	STATE_WESTERN: 'Western',
	STATE_ANDORRA_LA_VELLA: 'Andorra la Vella',
	STATE_CANILLO: 'Canillo',
	STATE_ENCAMP: 'Encamp',
	STATE_LA_MASSANA: 'La Massana',
	STATE_LES_ESCALDES: 'Les Escaldes',
	STATE_ORDINO: 'Ordino',
	STATE_SANT_JULIA_DE_LORIA: 'Sant Julia de Loria',
	STATE_BENGO: 'Bengo',
	STATE_BENGUELA: 'Benguela',
	STATE_BIE: 'Bie',
	STATE_CABINDA: 'Cabinda',
	STATE_CUNENE: 'Cunene',
	STATE_HUAMBO: 'Huambo',
	STATE_HUILA: 'Huila',
	STATE_KUANDO_KUBANGO: 'Kuando-Kubango',
	STATE_KWANZA_NORTE: 'Kwanza Norte',
	STATE_KWANZA_SUL: 'Kwanza Sul',
	STATE_LUANDA: 'Luanda',
	STATE_LUNDA_NORTE: 'Lunda Norte',
	STATE_LUNDA_SUL: 'Lunda Sul',
	STATE_MALANJE: 'Malanje',
	STATE_MOXICO: 'Moxico',
	STATE_NAMIBE: 'Namibe',
	STATE_UIGE: 'Uige',
	STATE_ZAIRE: 'Zaire',
	STATE_OTHER_PROVINCES: 'Andere Provinzen',
	STATE_SECTOR_CLAIMED_BY_ARGENTINA_CH: 'Von Argentinen/Ch beanspruchter Sektor',
	STATE_SECTOR_CLAIMED_BY_ARGENTINA_UK: 'Von Argentinen/UK beanspruchter Sektor',
	STATE_SECTOR_CLAIMED_BY_AUSTRALIA: 'Von Australien beanspruchter Sektor',
	STATE_SECTOR_CLAIMED_BY_FRANCE: 'Von Frankreich beanspruchter Sektor',
	STATE_SECTOR_CLAIMED_BY_NEW_ZEALAND: 'Von Neuseeland beanspruchter Sektor',
	STATE_SECTOR_CLAIMED_BY_NORWAY: 'Von Norwegen beanspruchter Sektor',
	STATE_UNCLAIMED_SECTOR: 'Nicht beanspruchter Sektor',
	STATE_BARBUDA: 'Barbuda',
	STATE_SAINT_GEORGE: 'Saint George',
	STATE_SAINT_JOHN: 'Saint John',
	STATE_SAINT_MARY: 'Saint Mary',
	STATE_SAINT_PAUL: 'Saint Paul',
	STATE_SAINT_PETER: 'Saint Peter',
	STATE_SAINT_PHILIP: 'Saint Philip',
	STATE_BUENOS_AIRES: 'Buenos Aires',
	STATE_CATAMARCA: 'Catamarca',
	STATE_CHACO: 'Chaco',
	STATE_CHUBUT: 'Chubut',
	STATE_CORDOBA: 'Cordoba',
	STATE_CORRIENTES: 'Corrientes',
	STATE_DISTRITO_FEDERAL: 'Distrito Federal',
	STATE_ENTRE_RIOS: 'Entre Rios',
	STATE_FORMOSA: 'Formosa',
	STATE_JUJUY: 'Jujuy',
	STATE_LA_PAMPA: 'La Pampa',
	STATE_HIDD: 'Hidd',
	STATE_LA_RIOJA: 'La Rioja',
	STATE_MENDOZA: 'Mendoza',
	STATE_MISIONES: 'Misiones',
	STATE_NEUQUEN: 'Neuquen',
	STATE_RIO_NEGRO: 'Rio Negro',
	STATE_SALTA: 'Salta',
	STATE_SAN_JUAN: 'San Juan',
	STATE_SAN_LUIS: 'San Luis',
	STATE_SANTA_CRUZ: 'Santa Cruz',
	STATE_SANTA_FE: 'Santa Fe',
	STATE_SANTIAGO_DEL_ESTERO: 'Santiago del Estero',
	STATE_TIERRA_DEL_FUEGO: 'Tierra del Fuego',
	STATE_TUCUMAN: 'Tucuman',
	STATE_ARAGATSOTN: 'Aragatsotn',
	STATE_ARARAT: 'Ararat',
	STATE_ARMAVIR: 'Armavir',
	STATE_GEGHARKUNIK: 'Gegharkunik',
	STATE_KOTAIK: 'Kotaik',
	STATE_LORI: 'Lori',
	STATE_SHIRAK: 'Shirak',
	STATE_STEPANAKERT: 'Stepanakert',
	STATE_SYUNIK: 'Syunik',
	STATE_TAVUSH: 'Tavush',
	STATE_VAYOTS_DZOR: 'Vayots Dzor',
	STATE_YEREVAN: 'Yerevan',
	STATE_ARUBA: 'Aruba',
	STATE_AUCKLAND: 'Auckland',
	STATE_AUSTRALIAN_CAPITAL_TERRITORY: 'Australian Capital Territory',
	STATE_BALGOWLAH: 'Balgowlah',
	STATE_BALMAIN: 'Balmain',
	STATE_BANKSTOWN: 'Bankstown',
	STATE_BAULKHAM_HILLS: 'Baulkham Hills',
	STATE_BONNET_BAY: 'Bonnet Bay',
	STATE_CAMBERWELL: 'Camberwell',
	STATE_CAROLE_PARK: 'Carole Park',
	STATE_CASTLE_HILL: 'Castle Hill',
	STATE_CAULFIELD: 'Caulfield',
	STATE_CHATSWOOD: 'Chatswood',
	STATE_CHELTENHAM: 'Cheltenham',
	STATE_CHERRYBROOK: 'Cherrybrook',
	STATE_CLAYTON: 'Clayton',
	STATE_COLLINGWOOD: 'Collingwood',
	STATE_FRENCHS_FOREST: 'Frenchs Forest',
	STATE_HAWTHORN: 'Hawthorn',
	STATE_JANNNALI: 'Jannnali',
	STATE_KNOXFIELD: 'Knoxfield',
	STATE_MELBOURNE: 'Melbourne',
	STATE_NEW_SOUTH_WALES: 'New South Wales',
	STATE_NORTHERN_TERRITORY: 'Northern Territory',
	STATE_PERTH: 'Perth',
	STATE_QUEENSLAND: 'Queensland',
	STATE_SOUTH_AUSTRALIA: 'South Australia',
	STATE_TASMANIA: 'Tasmania',
	STATE_TEMPLESTOWE: 'Templestowe',
	STATE_VICTORIA: 'Victoria',
	STATE_WERRIBEE_SOUTH: 'Werribee south',
	STATE_WESTERN_AUSTRALIA: 'Western Australia',
	STATE_WHEELER: 'Wheeler',
	STATE_BUNDESLAND_SALZBURG: 'Bundesland Salzburg',
	STATE_BUNDESLAND_STEIERMARK: 'Bundesland Steiermark',
	STATE_BUNDESLAND_TIROL: 'Bundesland Tirol',
	STATE_BURGENLAND: 'Burgenland',
	STATE_CARINTHIA: 'Kärnten',
	STATE_KARNTEN: 'Kärnten',
	STATE_LIEZEN: 'Liezen',
	STATE_LOWER_AUSTRIA: 'Niederösterreich',
	STATE_NIEDEROSTERREICH: 'Niederösterreich',
	STATE_OBEROSTERREICH: 'Oberösterreich',
	STATE_SALZBURG: 'Salzburg',
	STATE_SCHLESWIG_HOLSTEIN: 'Schleswig-Holstein',
	STATE_STEIERMARK: 'Steiermark',
	STATE_STYRIA: 'Steiermark',
	STATE_TIROL: 'Tirol',
	STATE_UPPER_AUSTRIA: 'Oberösterreich',
	STATE_VORARLBERG: 'Vorarlberg',
	STATE_WIEN: 'Wien',
	STATE_ABSERON: 'Abseron',
	STATE_BAKI_SAHARI: 'Baki Sahari',
	STATE_GANCA: 'Ganca',
	STATE_GANJA: 'Ganja',
	STATE_KALBACAR: 'Kalbacar',
	STATE_LANKARAN: 'Lankaran',
	STATE_MIL_QARABAX: 'Mil-Qarabax',
	STATE_MUGAN_SALYAN: 'Mugan-Salyan',
	STATE_NAGORNI_QARABAX: 'Nagorni-Qarabax',
	STATE_NAXCIVAN: 'Naxcivan',
	STATE_PRIARAKS: 'Priaraks',
	STATE_QAZAX: 'Qazax',
	STATE_SAKI: 'Saki',
	STATE_SIRVAN: 'Sirvan',
	STATE_XACMAZ: 'Xacmaz',
	STATE_ABACO: 'Abaco',
	STATE_ACKLINS_ISLAND: 'Acklins Island',
	STATE_ANDROS: 'Andros',
	STATE_BERRY_ISLANDS: 'Berry Islands',
	STATE_BIMINIS: 'Biminis',
	STATE_CAT_ISLAND: 'Cat Island',
	STATE_CROOKED_ISLAND: 'Crooked Island',
	STATE_ELEUTHERA: 'Eleuthera',
	STATE_EXUMA_AND_CAYS: 'Exuma and Cays',
	STATE_GRAND_BAHAMA: 'Grand Bahama',
	STATE_INAGUA_ISLANDS: 'Inagua Islands',
	STATE_LONG_ISLAND: 'Long Island',
	STATE_MAYAGUANA: 'Mayaguana',
	STATE_NEW_PROVIDENCE: 'New Providence',
	STATE_RAGGED_ISLAND: 'Ragged Island',
	STATE_RUM_CAY: 'Rum Cay',
	STATE_SAN_SALVADOR: 'San Salvador',
	STATE_ISA: 'Isa',
	STATE_BADIYAH: 'Badiyah',
	STATE_JIDD_HAFS: 'Jidd Hafs',
	STATE_MAHAMA: 'Mahama',
	STATE_MANAMA: 'Manama',
	STATE_SITRAH: 'Sitrah',
	STATE_AL_MANAMAH: 'al-Manamah',
	STATE_AL_MUHARRAQ: 'al-Muharraq',
	STATE_AR_RIFA__A: 'ar-Rifa’a',
	STATE_BAGAR_HAT: 'Bagar Hat',
	STATE_BANDARBAN: 'Bandarban',
	STATE_BARGUNA: 'Barguna',
	STATE_BARISAL: 'Barisal',
	STATE_BHOLA: 'Bhola',
	STATE_BOGORA: 'Bogora',
	STATE_BRAHMAN_BARIYA: 'Brahman Bariya',
	STATE_CHANDPUR: 'Chandpur',
	STATE_CHATTAGAM: 'Chattagam',
	STATE_CHITTAGONG_DIVISION: 'Chittagong Division',
	STATE_CHUADANGA: 'Chuadanga',
	STATE_DHAKA: 'Dhaka',
	STATE_DINAJPUR: 'Dinajpur',
	STATE_FARIDPUR: 'Faridpur',
	STATE_FENI: 'Feni',
	STATE_GAYBANDA: 'Gaybanda',
	STATE_GAZIPUR: 'Gazipur',
	STATE_GOPALGANJ: 'Gopalganj',
	STATE_HABIGANJ: 'Habiganj',
	STATE_JAIPUR_HAT: 'Jaipur Hat',
	STATE_JAMALPUR: 'Jamalpur',
	STATE_JESSOR: 'Jessor',
	STATE_JHALAKATI: 'Jhalakati',
	STATE_JHANAYDAH: 'Jhanaydah',
	STATE_KHAGRACHHARI: 'Khagrachhari',
	STATE_KHULNA: 'Khulna',
	STATE_KISHORGANJ: 'Kishorganj',
	STATE_KOKS_BAZAR: 'Koks Bazar',
	STATE_KOMILLA: 'Komilla',
	STATE_KURIGRAM: 'Kurigram',
	STATE_KUSHTIYA: 'Kushtiya',
	STATE_LAKSHMIPUR: 'Lakshmipur',
	STATE_LALMANIR_HAT: 'Lalmanir Hat',
	STATE_MADARIPUR: 'Madaripur',
	STATE_MAGURA: 'Magura',
	STATE_MAIMANSINGH: 'Maimansingh',
	STATE_MANIKGANJ: 'Manikganj',
	STATE_MAULVI_BAZAR: 'Maulvi Bazar',
	STATE_MEHERPUR: 'Meherpur',
	STATE_MUNSHIGANJ: 'Munshiganj',
	STATE_NARAL: 'Naral',
	STATE_NARAYANGANJ: 'Narayanganj',
	STATE_NARSINGDI: 'Narsingdi',
	STATE_NATOR: 'Nator',
	STATE_NAUGAON: 'Naugaon',
	STATE_NAWABGANJ: 'Nawabganj',
	STATE_NETRAKONA: 'Netrakona',
	STATE_NILPHAMARI: 'Nilphamari',
	STATE_NOAKHALI: 'Noakhali',
	STATE_PABNA: 'Pabna',
	STATE_PANCHAGARH: 'Panchagarh',
	STATE_PATUAKHALI: 'Patuakhali',
	STATE_PIROJPUR: 'Pirojpur',
	STATE_RAJBARI: 'Rajbari',
	STATE_RAJSHAHI: 'Rajshahi',
	STATE_RANGAMATI: 'Rangamati',
	STATE_RANGPUR: 'Rangpur',
	STATE_SATKHIRA: 'Satkhira',
	STATE_SHARIATPUR: 'Shariatpur',
	STATE_SHERPUR: 'Sherpur',
	STATE_SILHAT: 'Silhat',
	STATE_SIRAJGANJ: 'Sirajganj',
	STATE_SUNAMGANJ: 'Sunamganj',
	STATE_TANGAYAL: 'Tangayal',
	STATE_THAKURGAON: 'Thakurgaon',
	STATE_CHRIST_CHURCH: 'Christ Church',
	STATE_SAINT_ANDREW: 'Saint Andrew',
	STATE_SAINT_JAMES: 'Saint James',
	STATE_SAINT_JOSEPH: 'Saint Joseph',
	STATE_SAINT_LUCY: 'Saint Lucy',
	STATE_SAINT_MICHAEL: 'Saint Michael',
	STATE_SAINT_THOMAS: 'Saint Thomas',
	STATE_BREST: 'Brest',
	STATE_HOMJEL: 'Homjel',
	STATE_HRODNA: 'Hrodna',
	STATE_MAHILJOW: 'Mahiljow',
	STATE_MAHILYOWSKAYA_VOBLASTS: 'Mahilyowskaya Voblasts',
	STATE_MINSK: 'Minsk',
	STATE_MINSKAJA_VOBLASTS: 'Minskaja Voblasts',
	STATE_PETRIK: 'Petrik',
	STATE_VICEBSK: 'Vicebsk',
	STATE_ANTWERPEN: 'Antwerpen',
	STATE_BERCHEM: 'Berchem',
	STATE_BRABANT: 'Brabant',
	STATE_BRABANT_WALLON: 'Brabant Wallon',
	STATE_BRUSSEL: 'Brüssel',
	STATE_EAST_FLANDERS: 'Ost-Flandern',
	STATE_HAINAUT: 'Hainaut',
	STATE_LIEGE: 'Lüttich',
	STATE_LIMBURG: 'Limburg',
	STATE_LUXEMBOURG: 'Luxemburg',
	STATE_NAMUR: 'Namur',
	STATE_ONTARIO: 'Ontario',
	STATE_OOST_VLAANDEREN: 'Oost-Vlaanderen',
	STATE_PROVINCIE_BRABANT: 'Provincie Brabant',
	STATE_VLAAMS_BRABANT: 'Vlaams-Brabant',
	STATE_WALLONNE: 'Wallonne',
	STATE_WEST_VLAANDEREN: 'West-Vlaanderen',
	STATE_BELIZE: 'Belize',
	STATE_CAYO: 'Cayo',
	STATE_COROZAL: 'Corozal',
	STATE_ORANGE_WALK: 'Orange Walk',
	STATE_STANN_CREEK: 'Stann Creek',
	STATE_TOLEDO: 'Toledo',
	STATE_ALIBORI: 'Alibori',
	STATE_ATACORA: 'Atacora',
	STATE_ATLANTIQUE: 'Atlantique',
	STATE_BORGOU: 'Borgou',
	STATE_COLLINES: 'Collines',
	STATE_COUFFO: 'Couffo',
	STATE_DONGA: 'Donga',
	STATE_LITTORAL: 'Littoral',
	STATE_MONO: 'Mono',
	STATE_OUEME: 'Oueme',
	STATE_PLATEAU: 'Plateau',
	STATE_ZOU: 'Zou',
	STATE_HAMILTON: 'Hamilton',
	STATE_BUMTHANG: 'Bumthang',
	STATE_CHHUKHA: 'Chhukha',
	STATE_CHIRANG: 'Chirang',
	STATE_DAGA: 'Daga',
	STATE_GEYLEGPHUG: 'Geylegphug',
	STATE_HA: 'Ha',
	STATE_LHUNTSHI: 'Lhuntshi',
	STATE_MONGAR: 'Mongar',
	STATE_PEMAGATSEL: 'Pemagatsel',
	STATE_PUNAKHA: 'Punakha',
	STATE_RINPUNG: 'Rinpung',
	STATE_SAMCHI: 'Samchi',
	STATE_SAMDRUP_JONGKHAR: 'Samdrup Jongkhar',
	STATE_SHEMGANG: 'Shemgang',
	STATE_TASHIGANG: 'Tashigang',
	STATE_TIMPHU: 'Timphu',
	STATE_TONGSA: 'Tongsa',
	STATE_WANGDIPHODRANG: 'Wangdiphodrang',
	STATE_BENI: 'Beni',
	STATE_CHUQUISACA: 'Chuquisaca',
	STATE_COCHABAMBA: 'Cochabamba',
	STATE_LA_PAZ: 'La Paz',
	STATE_ORURO: 'Oruro',
	STATE_PANDO: 'Pando',
	STATE_POTOSI: 'Potosi',
	STATE_TARIJA: 'Tarija',
	STATE_FEDERACIJA_BOSNA_I_HERCEGOVINA: 'Federacija Bosna i Hercegovina',
	STATE_REPUBLIKA_SRPSKA: 'Republika Srpska',
	STATE_CENTRAL_BOBONONG: 'Central Bobonong',
	STATE_CENTRAL_BOTETI: 'Central Boteti',
	STATE_CENTRAL_MAHALAPYE: 'Central Mahalapye',
	STATE_CENTRAL_SEROWE_PALAPYE: 'Central Serowe-Palapye',
	STATE_CENTRAL_TUTUME: 'Central Tutume',
	STATE_CHOBE: 'Chobe',
	STATE_FRANCISTOWN: 'Francistown',
	STATE_GABORONE: 'Gaborone',
	STATE_GHANZI: 'Ghanzi',
	STATE_JWANENG: 'Jwaneng',
	STATE_KGALAGADI_NORTH: 'Kgalagadi North',
	STATE_KGALAGADI_SOUTH: 'Kgalagadi South',
	STATE_KGATLENG: 'Kgatleng',
	STATE_KWENENG: 'Kweneng',
	STATE_LOBATSE: 'Lobatse',
	STATE_NGAMILAND: 'Ngamiland',
	STATE_NGWAKETSE: 'Ngwaketse',
	STATE_NORTH_EAST: 'North East',
	STATE_OKAVANGO: 'Okavango',
	STATE_ORAPA: 'Orapa',
	STATE_SELIBE_PHIKWE: 'Selibe Phikwe',
	STATE_SOUTH_EAST: 'South East',
	STATE_SOWA: 'Sowa',
	STATE_BOUVET_ISLAND: 'Bouvetinsel',
	STATE_ACRE: 'Acre',
	STATE_ALAGOAS: 'Alagoas',
	STATE_AMAPA: 'Amapa',
	STATE_AMAZONAS: 'Amazonas',
	STATE_BAHIA: 'Bahia',
	STATE_CEARA: 'Ceara',
	STATE_ESPIRITO_SANTO: 'Espirito Santo',
	STATE_ESTADO_DE_SAO_PAULO: 'Estado de Sao Paulo',
	STATE_GOIAS: 'Goias',
	STATE_MARANHAO: 'Maranhao',
	STATE_MATO_GROSSO: 'Mato Grosso',
	STATE_MATO_GROSSO_DO_SUL: 'Mato Grosso do Sul',
	STATE_MINAS_GERAIS: 'Minas Gerais',
	STATE_PARA: 'Para',
	STATE_PARAIBA: 'Paraiba',
	STATE_PARANA: 'Parana',
	STATE_PERNAMBUCO: 'Pernambuco',
	STATE_PIAUI: 'Piaui',
	STATE_RIO_GRANDE_DO_NORTE: 'Rio Grande do Norte',
	STATE_RIO_GRANDE_DO_SUL: 'Rio Grande do Sul',
	STATE_RIO_DE_JANEIRO: 'Rio de Janeiro',
	STATE_RONDONIA: 'Rondonia',
	STATE_RORAIMA: 'Roraima',
	STATE_SANTA_CATARINA: 'Santa Catarina',
	STATE_SAO_PAULO: 'Sao Paulo',
	STATE_SERGIPE: 'Sergipe',
	STATE_TOCANTINS: 'Tocantins',
	STATE_BRITISH_INDIAN_OCEAN_TERRITORY: 'Britisches Territorium im Indischen Ozean',
	STATE_BELAIT: 'Belait',
	STATE_BRUNEI_MUARA: 'Brunei-Muara',
	STATE_TEMBURONG: 'Temburong',
	STATE_TUTONG: 'Tutong',
	STATE_BLAGOEVGRAD: 'Blagoevgrad',
	STATE_BURGAS: 'Burgas',
	STATE_DOBRICH: 'Dobrich',
	STATE_GABROVO: 'Gabrovo',
	STATE_HASKOVO: 'Haskovo',
	STATE_JAMBOL: 'Jambol',
	STATE_KARDZHALI: 'Kardzhali',
	STATE_KJUSTENDIL: 'Kjustendil',
	STATE_LOVECH: 'Lovech',
	STATE_MONTANA: 'Montana',
	STATE_OBLAST_SOFIYA_GRAD: 'Oblast Sofiya-Grad',
	STATE_PAZARDZHIK: 'Pazardzhik',
	STATE_PERNIK: 'Pernik',
	STATE_PLEVEN: 'Pleven',
	STATE_PLOVDIV: 'Plovdiv',
	STATE_RAZGRAD: 'Razgrad',
	STATE_RUSE: 'Ruse',
	STATE_SHUMEN: 'Shumen',
	STATE_SILISTRA: 'Silistra',
	STATE_SLIVEN: 'Sliven',
	STATE_SMOLJAN: 'Smoljan',
	STATE_SOFIJA_GRAD: 'Sofija grad',
	STATE_SOFIJSKA_OBLAST: 'Sofijska oblast',
	STATE_STARA_ZAGORA: 'Stara Zagora',
	STATE_TARGOVISHTE: 'Targovishte',
	STATE_VARNA: 'Varna',
	STATE_VELIKO_TARNOVO: 'Veliko Tarnovo',
	STATE_VIDIN: 'Vidin',
	STATE_VRACA: 'Vraca',
	STATE_YABLANIZA: 'Yablaniza',
	STATE_BALE: 'Bale',
	STATE_BAM: 'Bam',
	STATE_BAZEGA: 'Bazega',
	STATE_BOUGOURIBA: 'Bougouriba',
	STATE_BOULGOU: 'Boulgou',
	STATE_BOULKIEMDE: 'Boulkiemde',
	STATE_COMOE: 'Comoe',
	STATE_GANZOURGOU: 'Ganzourgou',
	STATE_GNAGNA: 'Gnagna',
	STATE_GOURMA: 'Gourma',
	STATE_HOUET: 'Houet',
	STATE_IOBA: 'Ioba',
	STATE_KADIOGO: 'Kadiogo',
	STATE_KENEDOUGOU: 'Kenedougou',
	STATE_KOMANDJARI: 'Komandjari',
	STATE_KOMPIENGA: 'Kompienga',
	STATE_KOSSI: 'Kossi',
	STATE_KOURITENGA: 'Kouritenga',
	STATE_KOURWEOGO: 'Kourweogo',
	STATE_LERABA: 'Leraba',
	STATE_MOUHOUN: 'Mouhoun',
	STATE_NAHOURI: 'Nahouri',
	STATE_NAMENTENGA: 'Namentenga',
	STATE_NOUMBIEL: 'Noumbiel',
	STATE_OUBRITENGA: 'Oubritenga',
	STATE_OUDALAN: 'Oudalan',
	STATE_PASSORE: 'Passore',
	STATE_PONI: 'Poni',
	STATE_SANGUIE: 'Sanguie',
	STATE_SANMATENGA: 'Sanmatenga',
	STATE_SENO: 'Seno',
	STATE_SISSILI: 'Sissili',
	STATE_SOUM: 'Soum',
	STATE_SOUROU: 'Sourou',
	STATE_TAPOA: 'Tapoa',
	STATE_TUY: 'Tuy',
	STATE_YATENGA: 'Yatenga',
	STATE_ZONDOMA: 'Zondoma',
	STATE_ZOUNDWEOGO: 'Zoundweogo',
	STATE_BUBANZA: 'Bubanza',
	STATE_BUJUMBURA: 'Bujumbura',
	STATE_BURURI: 'Bururi',
	STATE_CANKUZO: 'Cankuzo',
	STATE_CIBITOKE: 'Cibitoke',
	STATE_GITEGA: 'Gitega',
	STATE_KARUZI: 'Karuzi',
	STATE_KAYANZA: 'Kayanza',
	STATE_KIRUNDO: 'Kirundo',
	STATE_MAKAMBA: 'Makamba',
	STATE_MURAMVYA: 'Muramvya',
	STATE_MUYINGA: 'Muyinga',
	STATE_NGOZI: 'Ngozi',
	STATE_RUTANA: 'Rutana',
	STATE_RUYIGI: 'Ruyigi',
	STATE_BANTEAY_MEAN_CHEY: 'Banteay Mean Chey',
	STATE_BAT_DAMBANG: 'Bat Dambang',
	STATE_KAMPONG_CHAM: 'Kampong Cham',
	STATE_KAMPONG_CHHNANG: 'Kampong Chhnang',
	STATE_KAMPONG_SPOEU: 'Kampong Spoeu',
	STATE_KAMPONG_THUM: 'Kampong Thum',
	STATE_KAMPOT: 'Kampot',
	STATE_KANDAL: 'Kandal',
	STATE_KAOH_KONG: 'Kaoh Kong',
	STATE_KRACHEH: 'Kracheh',
	STATE_KRONG_KAEB: 'Krong Kaeb',
	STATE_KRONG_PAILIN: 'Krong Pailin',
	STATE_KRONG_PREAH_SIHANOUK: 'Krong Preah Sihanouk',
	STATE_MONDOL_KIRI: 'Mondol Kiri',
	STATE_OTDAR_MEAN_CHEY: 'Otdar Mean Chey',
	STATE_PHNUM_PENH: 'Phnum Penh',
	STATE_POUSAT: 'Pousat',
	STATE_PREAH_VIHEAR: 'Preah Vihear',
	STATE_PREY_VEAENG: 'Prey Veaeng',
	STATE_ROTANAK_KIRI: 'Rotanak Kiri',
	STATE_SIEM_REAB: 'Siem Reab',
	STATE_STUENG_TRAENG: 'Stueng Traeng',
	STATE_SVAY_RIENG: 'Svay Rieng',
	STATE_TAKAEV: 'Takaev',
	STATE_ADAMAOUA: 'Adamaoua',
	STATE_CENTRE: 'Centre',
	STATE_EST: 'Est',
	STATE_NORD: 'Nord',
	STATE_NORD_EXTREME: 'Nord Extreme',
	STATE_NORDOUEST: 'Nordouest',
	STATE_OUEST: 'Ouest',
	STATE_SUD: 'Sud',
	STATE_SUDOUEST: 'Sudouest',
	STATE_ALBERTA: 'Alberta',
	STATE_BRITISH_COLUMBIA: 'British Columbia',
	STATE_MANITOBA: 'Manitoba',
	STATE_NEW_BRUNSWICK: 'New Brunswick',
	STATE_NEWFOUNDLAND_AND_LABRADOR: 'Newfoundland and Labrador',
	STATE_NORTHWEST_TERRITORIES: 'Northwest Territories',
	STATE_NOVA_SCOTIA: 'Nova Scotia',
	STATE_NUNAVUT: 'Nunavut',
	STATE_PRINCE_EDWARD_ISLAND: 'Prince Edward Island',
	STATE_QUEBEC: 'Quebec',
	STATE_SASKATCHEWAN: 'Saskatchewan',
	STATE_YUKON: 'Yukon',
	STATE_BOAVISTA: 'Boavista',
	STATE_BRAVA: 'Brava',
	STATE_FOGO: 'Fogo',
	STATE_MAIO: 'Maio',
	STATE_SAL: 'Sal',
	STATE_SANTO_ANTAO: 'Santo Antao',
	STATE_SAO_NICOLAU: 'Sao Nicolau',
	STATE_SAO_TIAGO: 'Sao Tiago',
	STATE_SAO_VICENTE: 'Sao Vicente',
	STATE_GRAND_CAYMAN: 'Grand Cayman',
	STATE_BAMINGUI_BANGORAN: 'Bamingui-Bangoran',
	STATE_BANGUI: 'Bangui',
	STATE_BASSE_KOTTO: 'Basse-Kotto',
	STATE_HAUT_MBOMOU: 'Haut-Mbomou',
	STATE_HAUTE_KOTTO: 'Haute-Kotto',
	STATE_KEMO: 'Kemo',
	STATE_LOBAYE: 'Lobaye',
	STATE_MAMBERE_KADEI: 'Mambere-Kadei',
	STATE_MBOMOU: 'Mbomou',
	STATE_NANA_GRIBIZI: 'Nana-Gribizi',
	STATE_NANA_MAMBERE: 'Nana-Mambere',
	STATE_OMBELLA_MPOKO: 'Ombella Mpoko',
	STATE_OUAKA: 'Ouaka',
	STATE_OUHAM: 'Ouham',
	STATE_OUHAM_PENDE: 'Ouham-Pende',
	STATE_SANGHA_MBAERE: 'Sangha-Mbaere',
	STATE_VAKAGA: 'Vakaga',
	STATE_BATHA: 'Batha',
	STATE_BILTINE: 'Biltine',
	STATE_BOURKOU_ENNEDI_TIBESTI: 'Bourkou-Ennedi-Tibesti',
	STATE_CHARI_BAGUIRMI: 'Chari-Baguirmi',
	STATE_GUERA: 'Guera',
	STATE_KANEM: 'Kanem',
	STATE_LAC: 'Lac',
	STATE_LOGONE_OCCIDENTAL: 'Logone Occidental',
	STATE_LOGONE_ORIENTAL: 'Logone Oriental',
	STATE_MAYO_KEBBI: 'Mayo-Kebbi',
	STATE_MOYEN_CHARI: 'Moyen-Chari',
	STATE_OUADDAI: 'Ouaddai',
	STATE_SALAMAT: 'Salamat',
	STATE_TANDJILE: 'Tandjile',
	STATE_AISEN: 'Aisen',
	STATE_ANTOFAGASTA: 'Antofagasta',
	STATE_ARAUCANIA: 'Araucania',
	STATE_ATACAMA: 'Atacama',
	STATE_BIO_BIO: 'Bio Bio',
	STATE_COQUIMBO: 'Coquimbo',
	STATE_LIBERTADOR_GENERAL_BERNARDO_O: 'Libertador General Bernardo O',
	STATE_LOS_LAGOS: 'Los Lagos',
	STATE_MAGELLANES: 'Magellanes',
	STATE_MAULE: 'Maule',
	STATE_METROPOLITANA: 'Metropolitana',
	STATE_METROPOLITANA_DE_SANTIAGO: 'Metropolitana de Santiago',
	STATE_TARAPACA: 'Tarapaca',
	STATE_VALPARAISO: 'Valparaiso',
	STATE_ANHUI: 'Anhui',
	STATE_ANHUI_PROVINCE: 'Anhui Province',
	STATE_ANHUI_SHENG: 'Anhui Sheng',
	STATE_AOMEN: 'Aomen',
	STATE_BEIJING: 'Beijing',
	STATE_BEIJING_SHI: 'Beijing Shi',
	STATE_CHONGQING: 'Chongqing',
	STATE_FUJIAN: 'Fujian',
	STATE_FUJIAN_SHENG: 'Fujian Sheng',
	STATE_GANSU: 'Gansu',
	STATE_GUANGDONG: 'Guangdong',
	STATE_GUANGDONG_SHENG: 'Guangdong Sheng',
	STATE_GUANGXI: 'Guangxi',
	STATE_GUIZHOU: 'Guizhou',
	STATE_HAINAN: 'Hainan',
	STATE_HEBEI: 'Hebei',
	STATE_HEILONGJIANG: 'Heilongjiang',
	STATE_HENAN: 'Henan',
	STATE_HUBEI: 'Hubei',
	STATE_HUNAN: 'Hunan',
	STATE_JIANGSU: 'Jiangsu',
	STATE_JIANGSU_SHENG: 'Jiangsu Sheng',
	STATE_JIANGXI: 'Jiangxi',
	STATE_JILIN: 'Jilin',
	STATE_LIAONING: 'Liaoning',
	STATE_LIAONING_SHENG: 'Liaoning Sheng',
	STATE_NEI_MONGGOL: 'Nei Monggol',
	STATE_NINGXIA_HUI: 'Ningxia Hui',
	STATE_QINGHAI: 'Qinghai',
	STATE_SHAANXI: 'Shaanxi',
	STATE_SHANDONG: 'Shandong',
	STATE_SHANDONG_SHENG: 'Shandong Sheng',
	STATE_SHANGHAI: 'Shanghai',
	STATE_SHANXI: 'Shanxi',
	STATE_SICHUAN: 'Sichuan',
	STATE_TIANJIN: 'Tianjin',
	STATE_XIANGGANG: 'Xianggang',
	STATE_XINJIANG: 'Xinjiang',
	STATE_XIZANG: 'Xizang',
	STATE_YUNNAN: 'Yunnan',
	STATE_ZHEJIANG: 'Zhejiang',
	STATE_ZHEJIANG_SHENG: 'Zhejiang Sheng',
	STATE_CHRISTMAS_ISLAND: 'Weihnachtsinsel',
	STATE_COCOS_KEELING_ISLANDS: 'Cocos (Keeling)-Inseln',
	STATE_ANTIOQUIA: 'Antioquia',
	STATE_ARAUCA: 'Arauca',
	STATE_ATLANTICO: 'Atlantico',
	STATE_BOGOTA: 'Bogota',
	STATE_BOLIVAR: 'Bolivar',
	STATE_BOYACA: 'Boyaca',
	STATE_CALDAS: 'Caldas',
	STATE_CAQUETA: 'Caqueta',
	STATE_CASANARE: 'Casanare',
	STATE_CAUCA: 'Cauca',
	STATE_CESAR: 'Cesar',
	STATE_CHOCO: 'Choco',
	STATE_CUNDINAMARCA: 'Cundinamarca',
	STATE_GUAINIA: 'Guainia',
	STATE_GUAVIARE: 'Guaviare',
	STATE_LA_GUAJIRA: 'La Guajira',
	STATE_MAGDALENA: 'Magdalena',
	STATE_META: 'Meta',
	STATE_NARINO: 'Narino',
	STATE_NORTE_DE_SANTANDER: 'Norte de Santander',
	STATE_PUTUMAYO: 'Putumayo',
	STATE_QUINDIO: 'Quindio',
	STATE_RISARALDA: 'Risaralda',
	STATE_SAN_ANDRES_Y_PROVIDENCIA: 'San Andres y Providencia',
	STATE_SANTANDER: 'Santander',
	STATE_SUCRE: 'Sucre',
	STATE_TOLIMA: 'Tolima',
	STATE_VALLE_DEL_CAUCA: 'Valle del Cauca',
	STATE_VAUPES: 'Vaupes',
	STATE_VICHADA: 'Vichada',
	STATE_MWALI: 'Mwali',
	STATE_NJAZIDJA: 'Njazidja',
	STATE_NZWANI: 'Nzwani',
	STATE_BOUENZA: 'Bouenza',
	STATE_BRAZZAVILLE: 'Brazzaville',
	STATE_CUVETTE: 'Cuvette',
	STATE_KOUILOU: 'Kouilou',
	STATE_LEKOUMOU: 'Lekoumou',
	STATE_LIKOUALA: 'Likouala',
	STATE_NIARI: 'Niari',
	STATE_PLATEAUX: 'Plateaux',
	STATE_POOL: 'Pool',
	STATE_SANGHA: 'Sangha',
	STATE_BANDUNDU: 'Bandundu',
	STATE_BAS_CONGO: 'Bas-Congo',
	STATE_EQUATEUR: 'Equateur',
	STATE_HAUT_CONGO: 'Haut-Congo',
	STATE_KASAI_OCCIDENTAL: 'Kasai-Occidental',
	STATE_KASAI_ORIENTAL: 'Kasai-Oriental',
	STATE_KATANGA: 'Katanga',
	STATE_KINSHASA: 'Kinshasa',
	STATE_MANIEMA: 'Maniema',
	STATE_NORD_KIVU: 'Nord-Kivu',
	STATE_SUD_KIVU: 'Sud-Kivu',
	STATE_AITUTAKI: 'Aitutaki',
	STATE_ATIU: 'Atiu',
	STATE_MANGAIA: 'Mangaia',
	STATE_MANIHIKI: 'Manihiki',
	STATE_MAUKE: 'Mauke',
	STATE_MITIARO: 'Mitiaro',
	STATE_NASSAU: 'Nassau',
	STATE_PUKAPUKA: 'Pukapuka',
	STATE_RAKAHANGA: 'Rakahanga',
	STATE_RAROTONGA: 'Rarotonga',
	STATE_TONGAREVA: 'Tongareva',
	STATE_ALAJUELA: 'Alajuela',
	STATE_CARTAGO: 'Cartago',
	STATE_GUANACASTE: 'Guanacaste',
	STATE_HEREDIA: 'Heredia',
	STATE_LIMON: 'Limon',
	STATE_PUNTARENAS: 'Puntarenas',
	STATE_SAN_JOSE: 'San Jose',
	STATE_ABIDJAN: 'Abidjan',
	STATE_AGNEBY: 'Agneby',
	STATE_BAFING: 'Bafing',
	STATE_DENGUELE: 'Denguele',
	STATE_DIX_HUIT_MONTAGNES: 'Dix-huit Montagnes',
	STATE_FROMAGER: 'Fromager',
	STATE_HAUT_SASSANDRA: 'Haut-Sassandra',
	STATE_LACS: 'Lacs',
	STATE_LAGUNES: 'Lagunes',
	STATE_MARAHOUE: 'Marahoue',
	STATE_MOYEN_CAVALLY: 'Moyen-Cavally',
	STATE_MOYEN_COMOE: 'Moyen-Comoe',
	STATE_N__ZI_COMOE: 'N’zi-Comoe',
	STATE_SASSANDRA: 'Sassandra',
	STATE_SAVANES: 'Savanes',
	STATE_SUD_BANDAMA: 'Sud-Bandama',
	STATE_SUD_COMOE: 'Sud-Comoe',
	STATE_VALLEE_DU_BANDAMA: 'Vallee du Bandama',
	STATE_WORODOUGOU: 'Worodougou',
	STATE_ZANZAN: 'Zanzan',
	STATE_BJELOVAR_BILOGORA: 'Bjelovar-Bilogora',
	STATE_DUBROVNIK_NERETVA: 'Dubrovnik-Neretva',
	STATE_GRAD_ZAGREB: 'Grad Zagreb',
	STATE_ISTRA: 'Istra',
	STATE_KARLOVAC: 'Karlovac',
	STATE_KOPRIVNICA_KRIZHEVCI: 'Koprivnica-Krizhevci',
	STATE_KRAPINA_ZAGORJE: 'Krapina-Zagorje',
	STATE_LIKA_SENJ: 'Lika-Senj',
	STATE_MEDHIMURJE: 'Medhimurje',
	STATE_MEDIMURSKA_ZUPANIJA: 'Medimurska Zupanija',
	STATE_OSIJEK_BARANJA: 'Osijek-Baranja',
	STATE_OSJECKO_BARANJSKA_ZUPANIJA: 'Osjecko-Baranjska Zupanija',
	STATE_POZHEGA_SLAVONIJA: 'Pozhega-Slavonija',
	STATE_PRIMORJE_GORSKI_KOTAR: 'Primorje-Gorski Kotar',
	STATE_SHIBENIK_KNIN: 'Shibenik-Knin',
	STATE_SISAK_MOSLAVINA: 'Sisak-Moslavina',
	STATE_SLAVONSKI_BROD_POSAVINA: 'Slavonski Brod-Posavina',
	STATE_SPLIT_DALMACIJA: 'Split-Dalmacija',
	STATE_VARAZHDIN: 'Varazhdin',
	STATE_VIROVITICA_PODRAVINA: 'Virovitica-Podravina',
	STATE_VUKOVAR_SRIJEM: 'Vukovar-Srijem',
	STATE_ZADAR: 'Zadar',
	STATE_ZAGREB: 'Zagreb',
	STATE_CAMAGUEY: 'Camaguey',
	STATE_CIEGO_DE_AVILA: 'Ciego de Avila',
	STATE_CIENFUEGOS: 'Cienfuegos',
	STATE_CIUDAD_DE_LA_HABANA: 'Ciudad de la Habana',
	STATE_GRANMA: 'Granma',
	STATE_GUANTANAMO: 'Guantanamo',
	STATE_HABANA: 'Habana',
	STATE_HOLGUIN: 'Holguin',
	STATE_ISLA_DE_LA_JUVENTUD: 'Isla de la Juventud',
	STATE_LA_HABANA: 'La Habana',
	STATE_LAS_TUNAS: 'Las Tunas',
	STATE_MATANZAS: 'Matanzas',
	STATE_PINAR_DEL_RIO: 'Pinar del Rio',
	STATE_SANCTI_SPIRITUS: 'Sancti Spiritus',
	STATE_SANTIAGO_DE_CUBA: 'Santiago de Cuba',
	STATE_VILLA_CLARA: 'Villa Clara',
	STATE_GOVERNMENT_CONTROLLED_AREA: 'Government controlled area',
	STATE_LIMASSOL: 'Limassol',
	STATE_NICOSIA_DISTRICT: 'Nicosia District',
	STATE_PAPHOS: 'Paphos',
	STATE_TURKISH_CONTROLLED_AREA: 'Turkish controlled area',
	STATE_CENTRAL_BOHEMIAN: 'Central Bohemian',
	STATE_FRYCOVICE: 'Frycovice',
	STATE_JIHOCESKY_KRAJ: 'Jihocesky Kraj',
	STATE_JIHOCHESKY: 'Jihochesky',
	STATE_JIHOMORAVSKY: 'Jihomoravsky',
	STATE_KARLOVARSKY: 'Karlovarsky',
	STATE_KLECANY: 'Klecany',
	STATE_KRALOVEHRADECKY: 'Kralovehradecky',
	STATE_LIBERECKY: 'Liberecky',
	STATE_LIPOV: 'Lipov',
	STATE_MORAVSKOSLEZSKY: 'Moravskoslezsky',
	STATE_OLOMOUCKY: 'Olomoucky',
	STATE_OLOMOUCKY_KRAJ: 'Olomoucky Kraj',
	STATE_PARDUBICKY: 'Pardubicky',
	STATE_PLZENSKY: 'Plzensky',
	STATE_PRAHA: 'Praha',
	STATE_RAJHRAD: 'Rajhrad',
	STATE_SMIRICE: 'Smirice',
	STATE_SOUTH_MORAVIAN: 'South Moravian',
	STATE_STRAZ_NAD_NISOU: 'Straz nad Nisou',
	STATE_STREDOCHESKY: 'Stredochesky',
	STATE_UNICOV: 'Unicov',
	STATE_USTECKY: 'Ustecky',
	STATE_VALLETTA: 'Valletta',
	STATE_VELESIN: 'Velesin',
	STATE_VYSOCHINA: 'Vysochina',
	STATE_ZLINSKY: 'Zlinsky',
	STATE_BORNHOLM: 'Bornholm',
	STATE_FREDERIKSBORG: 'Frederiksborg',
	STATE_FYN: 'Fyn',
	STATE_HOVEDSTADEN: 'Hovedstaden',
	STATE_KOBENHAVN: 'Kobenhavn',
	STATE_KOBENHAVNS_AMT: 'Kobenhavns Amt',
	STATE_KOBENHAVNS_KOMMUNE: 'Kobenhavns Kommune',
	STATE_NORDJYLLAND: 'Nordjylland',
	STATE_RIBE: 'Ribe',
	STATE_RINGKOBING: 'Ringkobing',
	STATE_ROERVIG: 'Roervig',
	STATE_ROSKILDE: 'Roskilde',
	STATE_ROSLEV: 'Roslev',
	STATE_SJAELLAND: 'Sjaelland',
	STATE_SOEBORG: 'Soeborg',
	STATE_SONDERJYLLAND: 'Sonderjylland',
	STATE_STORSTROM: 'Storstrom',
	STATE_SYDDANMARK: 'Syddanmark',
	STATE_TOELLOESE: 'Toelloese',
	STATE_VEJLE: 'Vejle',
	STATE_VESTSJALLAND: 'Vestsjalland',
	STATE_VIBORG: 'Viborg',
	STATE_ALI_SABIH: 'Ali Sabih',
	STATE_DIKHIL: 'Dikhil',
	STATE_JIBUTI: 'Jibuti',
	STATE_TAJURAH: 'Tajurah',
	STATE_UBUK: 'Ubuk',
	STATE_SAINT_DAVID: 'Saint David',
	STATE_SAINT_LUKE: 'Saint Luke',
	STATE_SAINT_MARK: 'Saint Mark',
	STATE_SAINT_PATRICK: 'Saint Patrick',
	STATE_AZUA: 'Azua',
	STATE_BAHORUCO: 'Bahoruco',
	STATE_BARAHONA: 'Barahona',
	STATE_DAJABON: 'Dajabon',
	STATE_DISTRITO_NACIONAL: 'Distrito Nacional',
	STATE_DUARTE: 'Duarte',
	STATE_EL_SEYBO: 'El Seybo',
	STATE_ELIAS_PINA: 'Elias Pina',
	STATE_ESPAILLAT: 'Espaillat',
	STATE_HATO_MAYOR: 'Hato Mayor',
	STATE_INDEPENDENCIA: 'Independencia',
	STATE_LA_ALTAGRACIA: 'La Altagracia',
	STATE_LA_ROMANA: 'La Romana',
	STATE_LA_VEGA: 'La Vega',
	STATE_MARIA_TRINIDAD_SANCHEZ: 'Maria Trinidad Sanchez',
	STATE_MONSENOR_NOUEL: 'Monsenor Nouel',
	STATE_MONTE_CRISTI: 'Monte Cristi',
	STATE_MONTE_PLATA: 'Monte Plata',
	STATE_PEDERNALES: 'Pedernales',
	STATE_PERAVIA: 'Peravia',
	STATE_PUERTO_PLATA: 'Puerto Plata',
	STATE_SALCEDO: 'Salcedo',
	STATE_SAMANA: 'Samana',
	STATE_SAN_CRISTOBAL: 'San Cristobal',
	STATE_SAN_PEDRO_DE_MACORIS: 'San Pedro de Macoris',
	STATE_SANCHEZ_RAMIREZ: 'Sanchez Ramirez',
	STATE_SANTIAGO: 'Santiago',
	STATE_SANTIAGO_RODRIGUEZ: 'Santiago Rodriguez',
	STATE_VALVERDE: 'Valverde',
	STATE_AILEU: 'Aileu',
	STATE_AINARO: 'Ainaro',
	STATE_AMBENO: 'Ambeno',
	STATE_BAUCAU: 'Baucau',
	STATE_BOBONARO: 'Bobonaro',
	STATE_COVA_LIMA: 'Cova Lima',
	STATE_DILI: 'Dili',
	STATE_ERMERA: 'Ermera',
	STATE_LAUTEM: 'Lautem',
	STATE_LIQUICA: 'Liquica',
	STATE_MANATUTO: 'Manatuto',
	STATE_MANUFAHI: 'Manufahi',
	STATE_VIQUEQUE: 'Viqueque',
	STATE_AZUAY: 'Azuay',
	STATE_CANAR: 'Canar',
	STATE_CARCHI: 'Carchi',
	STATE_CHIMBORAZO: 'Chimborazo',
	STATE_COTOPAXI: 'Cotopaxi',
	STATE_EL_ORO: 'El Oro',
	STATE_ESMERALDAS: 'Esmeraldas',
	STATE_GALAPAGOS: 'Galapagos',
	STATE_GUAYAS: 'Guayas',
	STATE_IMBABURA: 'Imbabura',
	STATE_LOJA: 'Loja',
	STATE_LOS_RIOS: 'Los Rios',
	STATE_MANABI: 'Manabi',
	STATE_MORONA_SANTIAGO: 'Morona Santiago',
	STATE_NAPO: 'Napo',
	STATE_ORELLANA: 'Orellana',
	STATE_PASTAZA: 'Pastaza',
	STATE_PICHINCHA: 'Pichincha',
	STATE_SUCUMBIOS: 'Sucumbios',
	STATE_TUNGURAHUA: 'Tungurahua',
	STATE_ZAMORA_CHINCHIPE: 'Zamora Chinchipe',
	STATE_ASWAN: 'Aswan',
	STATE_ASYUT: 'Asyut',
	STATE_BANI_SUWAYF: 'Bani Suwayf',
	STATE_BUR_SA__ID: 'Bur Sa’id',
	STATE_CAIRO: 'Cairo',
	STATE_DUMYAT: 'Dumyat',
	STATE_KAFR_ASH_SHAYKH: 'Kafr-ash-Shaykh',
	STATE_MATRUH: 'Matruh',
	STATE_MUHAFAZAT_AD_DAQAHLIYAH: 'Muhafazat ad Daqahliyah',
	STATE_MUHAFAZAT_AL_FAYYUM: 'Muhafazat al Fayyum',
	STATE_MUHAFAZAT_AL_GHARBIYAH: 'Muhafazat al Gharbiyah',
	STATE_MUHAFAZAT_AL_ISKANDARIYAH: 'Muhafazat al Iskandariyah',
	STATE_MUHAFAZAT_AL_QAHIRAH: 'Muhafazat al Qahirah',
	STATE_QINA: 'Qina',
	STATE_SAWHAJ: 'Sawhaj',
	STATE_SINA_AL_JANUBIYAH: 'Sina al-Janubiyah',
	STATE_SINA_ASH_SHAMALIYAH: 'Sina ash-Shamaliyah',
	STATE_AD_DAQAHLIYAH: 'ad-Daqahliyah',
	STATE_AL_BAHR_AL_AHMAR: 'al-Bahr-al-Ahmar',
	STATE_AL_BUHAYRAH: 'al-Buhayrah',
	STATE_AL_FAYYUM: 'al-Fayyum',
	STATE_AL_GHARBIYAH: 'al-Gharbiyah',
	STATE_AL_ISKANDARIYAH: 'al-Iskandariyah',
	STATE_AL_ISMAILIYAH: 'al-Ismailiyah',
	STATE_AL_JIZAH: 'al-Jizah',
	STATE_AL_MINUFIYAH: 'al-Minufiyah',
	STATE_AL_MINYA: 'al-Minya',
	STATE_AL_QAHIRA: 'al-Qahira',
	STATE_AL_QALYUBIYAH: 'al-Qalyubiyah',
	STATE_AL_UQSUR: 'al-Uqsur',
	STATE_AL_WADI_AL_JADID: 'al-Wadi al-Jadid',
	STATE_AS_SUWAYS: 'as-Suways',
	STATE_ASH_SHARQIYAH: 'ash-Sharqiyah',
	STATE_AHUACHAPAN: 'Ahuachapan',
	STATE_CABANAS: 'Cabanas',
	STATE_CHALATENANGO: 'Chalatenango',
	STATE_CUSCATLAN: 'Cuscatlan',
	STATE_LA_LIBERTAD: 'La Libertad',
	STATE_LA_UNION: 'La Union',
	STATE_MORAZAN: 'Morazan',
	STATE_SAN_MIGUEL: 'San Miguel',
	STATE_SAN_VICENTE: 'San Vicente',
	STATE_SANTA_ANA: 'Santa Ana',
	STATE_SONSONATE: 'Sonsonate',
	STATE_USULUTAN: 'Usulutan',
	STATE_ANNOBON: 'Annobon',
	STATE_BIOKO_NORTE: 'Bioko Norte',
	STATE_BIOKO_SUR: 'Bioko Sur',
	STATE_CENTRO_SUR: 'Centro Sur',
	STATE_KIE_NTEM: 'Kie-Ntem',
	STATE_LITORAL: 'Litoral',
	STATE_WELE_NZAS: 'Wele-Nzas',
	STATE_ANSEBA: 'Anseba',
	STATE_DEBUB: 'Debub',
	STATE_DEBUB_KEIH_BAHRI: 'Debub-Keih-Bahri',
	STATE_GASH_BARKA: 'Gash-Barka',
	STATE_MAEKEL: 'Maekel',
	STATE_SEMIEN_KEIH_BAHRI: 'Semien-Keih-Bahri',
	STATE_HARJU: 'Harju',
	STATE_HIIU: 'Hiiu',
	STATE_IDA_VIRU: 'Ida-Viru',
	STATE_JARVA: 'Jarva',
	STATE_JOGEVA: 'Jogeva',
	STATE_LAANE: 'Laane',
	STATE_LAANE_VIRU: 'Laane-Viru',
	STATE_PARNU: 'Parnu',
	STATE_POLVA: 'Polva',
	STATE_RAPLA: 'Rapla',
	STATE_SAARE: 'Saare',
	STATE_TARTU: 'Tartu',
	STATE_VALGA: 'Valga',
	STATE_VILJANDI: 'Viljandi',
	STATE_VORU: 'Voru',
	STATE_ADDIS_ABEBA: 'Addis Abeba',
	STATE_AFAR: 'Afar',
	STATE_AMHARA: 'Amhara',
	STATE_BENISHANGUL: 'Benishangul',
	STATE_DIREDAWA: 'Diredawa',
	STATE_GAMBELLA: 'Gambella',
	STATE_HARAR: 'Harar',
	STATE_JIGJIGA: 'Jigjiga',
	STATE_MEKELE: 'Mekele',
	STATE_OROMIA: 'Oromia',
	STATE_SOMALI: 'Somali',
	STATE_SOUTHERN: 'Southern',
	STATE_TIGRAY: 'Tigray',
	STATE_FALKLAND_ISLANDS: 'Falklandinseln',
	STATE_SOUTH_GEORGIA: 'Südgeorgien',
	STATE_KLAKSVIK: 'Klaksvik',
	STATE_NOR_ARA_EYSTUROY: 'Nor ara Eysturoy',
	STATE_NOR_OY: 'Nor oy',
	STATE_SANDOY: 'Sandoy',
	STATE_STREYMOY: 'Streymoy',
	STATE_SU_UROY: 'Su uroy',
	STATE_SY_RA_EYSTUROY: 'Sy ra Eysturoy',
	STATE_TORSHAVN: 'Torshavn',
	STATE_VAGA: 'Vaga',
	STATE_CENTRAL: 'Central',
	STATE_NORTHERN: 'Northern',
	STATE_SOUTH_PACIFIC: 'South Pacific',
	STATE_AHVENANMAA: 'Ahvenanmaa',
	STATE_ETELA_KARJALA: 'Etela-Karjala',
	STATE_ETELA_POHJANMAA: 'Etela-Pohjanmaa',
	STATE_ETELA_SAVO: 'Etela-Savo',
	STATE_ETELA_SUOMEN_LAANI: 'Etela-Suomen Laani',
	STATE_ITA_SUOMEN_LAANI: 'Ita-Suomen Laani',
	STATE_ITA_UUSIMAA: 'Ita-Uusimaa',
	STATE_KAINUU: 'Kainuu',
	STATE_KANTA_HAME: 'Kanta-Hame',
	STATE_KESKI_POHJANMAA: 'Keski-Pohjanmaa',
	STATE_KESKI_SUOMI: 'Keski-Suomi',
	STATE_KYMENLAAKSO: 'Kymenlaakso',
	STATE_LANSI_SUOMEN_LAANI: 'Lansi-Suomen Laani',
	STATE_LAPPI: 'Lappi',
	STATE_NORTHERN_SAVONIA: 'Northern Savonia',
	STATE_OSTROBOTHNIA: 'Ostrobothnia',
	STATE_OULUN_LAANI: 'Oulun Laani',
	STATE_PAIJAT_HAME: 'Paijat-Hame',
	STATE_PIRKANMAA: 'Pirkanmaa',
	STATE_POHJANMAA: 'Pohjanmaa',
	STATE_POHJOIS_KARJALA: 'Pohjois-Karjala',
	STATE_POHJOIS_POHJANMAA: 'Pohjois-Pohjanmaa',
	STATE_POHJOIS_SAVO: 'Pohjois-Savo',
	STATE_SAARIJARVI: 'Saarijarvi',
	STATE_SATAKUNTA: 'Satakunta',
	STATE_SOUTHERN_SAVONIA: 'Southern Savonia',
	STATE_TAVASTIA_PROPER: 'Tavastia Proper',
	STATE_ULEABORGS_LAN: 'Uleaborgs Lan',
	STATE_UUSIMAA: 'Uusimaa',
	STATE_VARSINAIS_SUOMI: 'Varsinais-Suomi',
	STATE_AIN: 'Ain',
	STATE_AISNE: 'Aisne',
	STATE_ALBI_LE_SEQUESTRE: 'Albi Le Sequestre',
	STATE_ALLIER: 'Allier',
	STATE_ALPES_COTE_DAZUR: 'Alpes-Cote dAzur',
	STATE_ALPES_MARITIMES: 'Alpes-Maritimes',
	STATE_ALPES_DE_HAUTE_PROVENCE: 'Alpes-de-Haute-Provence',
	STATE_ALSACE: 'Alsace',
	STATE_AQUITAINE: 'Aquitaine',
	STATE_ARDECHE: 'Ardeche',
	STATE_ARDENNES: 'Ardennes',
	STATE_ARIEGE: 'Ariege',
	STATE_AUBE: 'Aube',
	STATE_AUDE: 'Aude',
	STATE_AUVERGNE: 'Auvergne',
	STATE_AVEYRON: 'Aveyron',
	STATE_BAS_RHIN: 'Bas-Rhin',
	STATE_BASSE_NORMANDIE: 'Basse-Normandie',
	STATE_BOUCHES_DU_RHONE: 'Bouches-du-Rhone',
	STATE_BOURGOGNE: 'Bourgogne',
	STATE_BRETAGNE: 'Bretagne',
	STATE_BRITTANY: 'Bretagne',
	STATE_BURGUNDY: 'Burgundy',
	STATE_CALVADOS: 'Calvados',
	STATE_CANTAL: 'Cantal',
	STATE_CEDEX: 'Cedex',
	STATE_CHARENTE: 'Charente',
	STATE_CHARENTE_MARITIME: 'Charente-Maritime',
	STATE_CHER: 'Cher',
	STATE_CORREZE: 'Correze',
	STATE_CORSE_DU_SUD: 'Corse-du-Sud',
	STATE_COTE_D__OR: 'Cote-d’Or',
	STATE_COTES_D__ARMOR: 'Cotes-d’Armor',
	STATE_CREUSE: 'Creuse',
	STATE_CROLLES: 'Crolles',
	STATE_DEUX_SEVRES: 'Deux-Sevres',
	STATE_DORDOGNE: 'Dordogne',
	STATE_DOUBS: 'Doubs',
	STATE_DROME: 'Drome',
	STATE_ESSONNE: 'Essonne',
	STATE_EURE: 'Eure',
	STATE_EURE_ET_LOIR: 'Eure-et-Loir',
	STATE_FEUCHEROLLES: 'Feucherolles',
	STATE_FINISTERE: 'Finistere',
	STATE_FRANCHE_COMTE: 'Franche-Comte',
	STATE_GARD: 'Gard',
	STATE_GERS: 'Gers',
	STATE_GIRONDE: 'Gironde',
	STATE_HAUT_RHIN: 'Haut-Rhin',
	STATE_HAUTE_CORSE: 'Haute-Corse',
	STATE_HAUTE_GARONNE: 'Haute-Garonne',
	STATE_HAUTE_LOIRE: 'Haute-Loire',
	STATE_HAUTE_MARNE: 'Haute-Marne',
	STATE_HAUTE_SAONE: 'Haute-Saone',
	STATE_HAUTE_SAVOIE: 'Haute-Savoie',
	STATE_HAUTE_VIENNE: 'Haute-Vienne',
	STATE_HAUTES_ALPES: 'Hautes-Alpes',
	STATE_HAUTES_PYRENEES: 'Hautes-Pyrenees',
	STATE_HAUTS_DE_SEINE: 'Hauts-de-Seine',
	STATE_HERAULT: 'Herault',
	STATE_ILE_DE_FRANCE: 'Ile-de-France',
	STATE_ILLE_ET_VILAINE: 'Ille-et-Vilaine',
	STATE_INDRE: 'Indre',
	STATE_INDRE_ET_LOIRE: 'Indre-et-Loire',
	STATE_ISERE: 'Isere',
	STATE_JURA: 'Jura',
	STATE_KLAGENFURT: 'Klagenfurt',
	STATE_LANDES: 'Landes',
	STATE_LANGUEDOC_ROUSSILLON: 'Languedoc-Roussillon',
	STATE_LARCAY: 'Larcay',
	STATE_LE_CASTELLET: 'Le Castellet',
	STATE_LE_CREUSOT: 'Le Creusot',
	STATE_LIMOUSIN: 'Limousin',
	STATE_LOIR_ET_CHER: 'Loir-et-Cher',
	STATE_LOIRE: 'Loire',
	STATE_LOIRE_ATLANTIQUE: 'Loire-Atlantique',
	STATE_LOIRET: 'Loiret',
	STATE_LORRAINE: 'Lorraine',
	STATE_LOT: 'Lot',
	STATE_LOT_ET_GARONNE: 'Lot-et-Garonne',
	STATE_LOWER_NORMANDY: 'Basse-Normandie',
	STATE_LOZERE: 'Lozere',
	STATE_MAINE_ET_LOIRE: 'Maine-et-Loire',
	STATE_MANCHE: 'Manche',
	STATE_MARNE: 'Marne',
	STATE_MAYENNE: 'Mayenne',
	STATE_MEURTHE_ET_MOSELLE: 'Meurthe-et-Moselle',
	STATE_MEUSE: 'Meuse',
	STATE_MIDI_PYRENEES: 'Midi-Pyrenees',
	STATE_MORBIHAN: 'Morbihan',
	STATE_MOSELLE: 'Moselle',
	STATE_NIEVRE: 'Nievre',
	STATE_NORD_PAS_DE_CALAIS: 'Nord-Pas-de-Calais',
	STATE_OISE: 'Oise',
	STATE_ORNE: 'Orne',
	STATE_PARIS: 'Paris',
	STATE_PAS_DE_CALAIS: 'Pas-de-Calais',
	STATE_PAYS_DE_LA_LOIRE: 'Pays de la Loire',
	STATE_PICARDY: 'Picardy',
	STATE_PUY_DE_DOME: 'Puy-de-Dome',
	STATE_PYRENEES_ATLANTIQUES: 'Pyrenees-Atlantiques',
	STATE_PYRENEES_ORIENTALES: 'Pyrenees-Orientales',
	STATE_QUELMES: 'Quelmes',
	STATE_RHONE: 'Rhone',
	STATE_RHONE_ALPES: 'Rhone-Alpes',
	STATE_SAINT_OUEN: 'Saint Ouen',
	STATE_SAINT_VIATRE: 'Saint Viatre',
	STATE_SAONE_ET_LOIRE: 'Saone-et-Loire',
	STATE_SARTHE: 'Sarthe',
	STATE_SAVOIE: 'Savoie',
	STATE_SEINE_MARITIME: 'Seine-Maritime',
	STATE_SEINE_SAINT_DENIS: 'Seine-Saint-Denis',
	STATE_SEINE_ET_MARNE: 'Seine-et-Marne',
	STATE_SOMME: 'Somme',
	STATE_SOPHIA_ANTIPOLIS: 'Sophia Antipolis',
	STATE_SOUVANS: 'Souvans',
	STATE_TARN: 'Tarn',
	STATE_TARN_ET_GARONNE: 'Tarn-et-Garonne',
	STATE_TERRITOIRE_DE_BELFORT: 'Territoire de Belfort',
	STATE_TREIGNAC: 'Treignac',
	STATE_UPPER_NORMANDY: 'Upper Normandy',
	STATE_VAL_D__OISE: 'Val-d’Oise',
	STATE_VAL_DE_MARNE: 'Val-de-Marne',
	STATE_VAR: 'Var',
	STATE_VAUCLUSE: 'Vaucluse',
	STATE_VELLISE: 'Vellise',
	STATE_VENDEE: 'Vendee',
	STATE_VIENNE: 'Vienne',
	STATE_VOSGES: 'Vosges',
	STATE_YONNE: 'Yonne',
	STATE_YVELINES: 'Yvelines',
	STATE_CAYENNE: 'Cayenne',
	STATE_SAINT_LAURENT_DU_MARONI: 'Saint-Laurent-du-Maroni',
	STATE_ILES_DU_VENT: 'Iles du Vent',
	STATE_ILES_SOUS_LE_VENT: 'Iles sous le Vent',
	STATE_MARQUESAS: 'Marquesas',
	STATE_TUAMOTU: 'Tuamotu',
	STATE_TUBUAI: 'Tubuai',
	STATE_AMSTERDAM: 'Amsterdam',
	STATE_CROZET_ISLANDS: 'Crozet Islands',
	STATE_KERGUELEN: 'Kerguelen',
	STATE_ESTUAIRE: 'Estuaire',
	STATE_HAUT_OGOOUE: 'Haut-Ogooue',
	STATE_MOYEN_OGOOUE: 'Moyen-Ogooue',
	STATE_NGOUNIE: 'Ngounie',
	STATE_NYANGA: 'Nyanga',
	STATE_OGOOUE_IVINDO: 'Ogooue-Ivindo',
	STATE_OGOOUE_LOLO: 'Ogooue-Lolo',
	STATE_OGOOUE_MARITIME: 'Ogooue-Maritime',
	STATE_WOLEU_NTEM: 'Woleu-Ntem',
	STATE_BANJUL: 'Banjul',
	STATE_BASSE: 'Basse',
	STATE_BRIKAMA: 'Brikama',
	STATE_JANJANBUREH: 'Janjanbureh',
	STATE_KANIFING: 'Kanifing',
	STATE_KEREWAN: 'Kerewan',
	STATE_KUNTAUR: 'Kuntaur',
	STATE_MANSAKONKO: 'Mansakonko',
	STATE_ABHASIA: 'Abhasia',
	STATE_AJARIA: 'Ajaria',
	STATE_GURIA: 'Guria',
	STATE_IMERETI: 'Imereti',
	STATE_KAHETI: 'Kaheti',
	STATE_KVEMO_KARTLI: 'Kvemo Kartli',
	STATE_MCHETA_MTIANETI: 'Mcheta-Mtianeti',
	STATE_RACHA: 'Racha',
	STATE_SAMAGRELO_ZEMO_SVANETI: 'Samagrelo-Zemo Svaneti',
	STATE_SAMCHE_ZHAVAHETI: 'Samche-Zhavaheti',
	STATE_SHIDA_KARTLI: 'Shida Kartli',
	STATE_TBILISI: 'Tbilisi',
	STATE_BADEN_WURTTEMBERG: 'Baden-Württemberg',
	STATE_BAVARIA: 'Bayern',
	STATE_BAYERN: 'Bayern',
	STATE_BEILSTEIN_WURTT: 'Beilstein Wurtt',
	STATE_BERLIN: 'Berlin',
	STATE_BRANDENBURG: 'Brandenburg',
	STATE_BREMEN: 'Bremen',
	STATE_DREISBACH: 'Dreisbach',
	STATE_FREISTAAT_BAYERN: 'Freistaat Bayern',
	STATE_HAMBURG: 'Hamburg',
	STATE_HANNOVER: 'Hannover',
	STATE_HEROLDSTATT: 'Heroldstatt',
	STATE_HESSEN: 'Hessen',
	STATE_KORTENBERG: 'Kortenberg',
	STATE_LAASDORF: 'Laasdorf',
	STATE_LAND_BADEN_WURTTEMBERG: 'Land Baden-Württemberg',
	STATE_LAND_BAYERN: 'Land Bayern',
	STATE_LAND_BRANDENBURG: 'Land Brandenburg',
	STATE_LAND_HESSEN: 'Land Hessen',
	STATE_LAND_MECKLENBURG_VORPOMMERN: 'Land Mecklenburg-Vorpommern',
	STATE_LAND_NORDRHEIN_WESTFALEN: 'Land Nordrhein-Westfalen',
	STATE_LAND_RHEINLAND_PFALZ: 'Land Rheinland-Pfalz',
	STATE_LAND_SACHSEN: 'Land Sachsen',
	STATE_LAND_SACHSEN_ANHALT: 'Land Sachsen-Anhalt',
	STATE_LAND_THURINGEN: 'Land Thuringen',
	STATE_LOWER_SAXONY: 'Niedersachsen',
	STATE_MECKLENBURG_VORPOMMERN: 'Mecklenburg-Vorpommern',
	STATE_MULFINGEN: 'Mulfingen',
	STATE_MUNICH: 'München',
	STATE_NEUBEUERN: 'Neubeuern',
	STATE_NIEDERSACHSEN: 'Niedersachsen',
	STATE_NOORD_HOLLAND: 'Noord-Holland',
	STATE_NORDRHEIN_WESTFALEN: 'Nordrhein-Westfalen',
	STATE_NORTH_RHINE_WESTPHALIA: 'Nordrhein-Westfalen',
	STATE_OSTERODE: 'Osterode',
	STATE_RHEINLAND_PFALZ: 'Rheinland-Pfalz',
	STATE_RHINELAND_PALATINATE: 'Rheinland-Pfalz',
	STATE_SAARLAND: 'Saarland',
	STATE_SACHSEN: 'Sachsen',
	STATE_SACHSEN_ANHALT: 'Sachsen-Anhalt',
	STATE_SAXONY: 'Sachsen',
	STATE_THURINGIA: 'Thüringen',
	STATE_WEBLING: 'Webling',
	STATE_WEINSTRABE: 'Weinstrabe',
	STATE_SCHLOBBORN: 'schlobborn',
	STATE_ASHANTI: 'Ashanti',
	STATE_BRONG_AHAFO: 'Brong-Ahafo',
	STATE_GREATER_ACCRA: 'Greater Accra',
	STATE_UPPER_EAST: 'Upper East',
	STATE_UPPER_WEST: 'Upper West',
	STATE_VOLTA: 'Volta',
	STATE_GIBRALTAR: 'Gibraltar',
	STATE_ACHARNES: 'Acharnes',
	STATE_AHAIA: 'Ahaia',
	STATE_AITOLIA_KAI_AKARNANIA: 'Aitolia kai Akarnania',
	STATE_ARGOLIS: 'Argolis',
	STATE_ARKADIA: 'Arkadia',
	STATE_ARTA: 'Arta',
	STATE_ATTICA: 'Attica',
	STATE_ATTIKI: 'Attiki',
	STATE_AYION_OROS: 'Ayion Oros',
	STATE_CRETE: 'Crete',
	STATE_DODEKANISOS: 'Dodekanisos',
	STATE_DRAMA: 'Drama',
	STATE_EVIA: 'Evia',
	STATE_EVRITANIA: 'Evritania',
	STATE_EVROS: 'Evros',
	STATE_EVVOIA: 'Evvoia',
	STATE_FLORINA: 'Florina',
	STATE_FOKIS: 'Fokis',
	STATE_FTHIOTIS: 'Fthiotis',
	STATE_GREVENA: 'Grevena',
	STATE_HALANDRI: 'Halandri',
	STATE_HALKIDIKI: 'Halkidiki',
	STATE_HANIA: 'Hania',
	STATE_HERAKLION: 'Heraklion',
	STATE_HIOS: 'Hios',
	STATE_ILIA: 'Ilia',
	STATE_IMATHIA: 'Imathia',
	STATE_IOANNINA: 'Ioannina',
	STATE_IRAKLION: 'Iraklion',
	STATE_KARDITSA: 'Karditsa',
	STATE_KASTORIA: 'Kastoria',
	STATE_KAVALA: 'Kavala',
	STATE_KEFALLINIA: 'Kefallinia',
	STATE_KERKIRA: 'Kerkira',
	STATE_KIKLADES: 'Kiklades',
	STATE_KILKIS: 'Kilkis',
	STATE_KORINTHIA: 'Korinthia',
	STATE_KOZANI: 'Kozani',
	STATE_LAKONIA: 'Lakonia',
	STATE_LARISA: 'Larisa',
	STATE_LASITHI: 'Lasithi',
	STATE_LESVOS: 'Lesvos',
	STATE_LEVKAS: 'Levkas',
	STATE_MAGNISIA: 'Magnisia',
	STATE_MESSINIA: 'Messinia',
	STATE_NOMOS_ATTIKIS: 'Nomos Attikis',
	STATE_NOMOS_ZAKYNTHOU: 'Nomos Zakynthou',
	STATE_PELLA: 'Pella',
	STATE_PIERIA: 'Pieria',
	STATE_PIRAIOS: 'Piraios',
	STATE_PREVEZA: 'Preveza',
	STATE_RETHIMNI: 'Rethimni',
	STATE_RODOPI: 'Rodopi',
	STATE_SAMOS: 'Samos',
	STATE_SERRAI: 'Serrai',
	STATE_THESPROTIA: 'Thesprotia',
	STATE_THESSALONIKI: 'Thessaloniki',
	STATE_TRIKALA: 'Trikala',
	STATE_VOIOTIA: 'Voiotia',
	STATE_WEST_GREECE: 'Westgriechenland',
	STATE_XANTHI: 'Xanthi',
	STATE_ZAKINTHOS: 'Zakinthos',
	STATE_AASIAAT: 'Aasiaat',
	STATE_AMMASSALIK: 'Ammassalik',
	STATE_ILLOQQORTOORMIUT: 'Illoqqortoormiut',
	STATE_ILULISSAT: 'Ilulissat',
	STATE_IVITTUUT: 'Ivittuut',
	STATE_KANGAATSIAQ: 'Kangaatsiaq',
	STATE_MANIITSOQ: 'Maniitsoq',
	STATE_NANORTALIK: 'Nanortalik',
	STATE_NARSAQ: 'Narsaq',
	STATE_NUUK: 'Nuuk',
	STATE_PAAMIUT: 'Paamiut',
	STATE_QAANAAQ: 'Qaanaaq',
	STATE_QAQORTOQ: 'Qaqortoq',
	STATE_QASIGIANNGUIT: 'Qasigiannguit',
	STATE_QEQERTARSUAQ: 'Qeqertarsuaq',
	STATE_SISIMIUT: 'Sisimiut',
	STATE_UDENFOR_KOMMUNAL_INDDELING: 'Udenfor kommunal inddeling',
	STATE_UPERNAVIK: 'Upernavik',
	STATE_UUMMANNAQ: 'Uummannaq',
	STATE_CARRIACOU_PETITE_MARTINIQUE: 'Carriacou-Petite Martinique',
	STATE_SAINT_DAVIDS: 'Saint Davids',
	STATE_SAINT_GEORGE__S: 'Saint George’s',
	STATE_BASSE_TERRE: 'Basse-Terre',
	STATE_GRANDE_TERRE: 'Grande-Terre',
	STATE_ILES_DES_SAINTES: 'Iles des Saintes',
	STATE_LA_DESIRADE: 'La Desirade',
	STATE_MARIE_GALANTE: 'Marie-Galante',
	STATE_SAINT_BARTHELEMY: 'Saint Barthelemy',
	STATE_SAINT_MARTIN: 'Saint Martin',
	STATE_AGANA_HEIGHTS: 'Agana Heights',
	STATE_AGAT: 'Agat',
	STATE_BARRIGADA: 'Barrigada',
	STATE_CHALAN_PAGO_ORDOT: 'Chalan-Pago-Ordot',
	STATE_DEDEDO: 'Dededo',
	STATE_HAGATNA: 'Hagatna',
	STATE_INARAJAN: 'Inarajan',
	STATE_MANGILAO: 'Mangilao',
	STATE_MERIZO: 'Merizo',
	STATE_MONGMONG_TOTO_MAITE: 'Mongmong-Toto-Maite',
	STATE_SANTA_RITA: 'Santa Rita',
	STATE_SINAJANA: 'Sinajana',
	STATE_TALOFOFO: 'Talofofo',
	STATE_TAMUNING: 'Tamuning',
	STATE_YIGO: 'Yigo',
	STATE_YONA: 'Yona',
	STATE_ALTA_VERAPAZ: 'Alta Verapaz',
	STATE_BAJA_VERAPAZ: 'Baja Verapaz',
	STATE_CHIMALTENANGO: 'Chimaltenango',
	STATE_CHIQUIMULA: 'Chiquimula',
	STATE_EL_PROGRESO: 'El Progreso',
	STATE_ESCUINTLA: 'Escuintla',
	STATE_GUATEMALA: 'Guatemala',
	STATE_HUEHUETENANGO: 'Huehuetenango',
	STATE_IZABAL: 'Izabal',
	STATE_JALAPA: 'Jalapa',
	STATE_JUTIAPA: 'Jutiapa',
	STATE_PETEN: 'Peten',
	STATE_QUEZALTENANGO: 'Quezaltenango',
	STATE_QUICHE: 'Quiche',
	STATE_RETALHULEU: 'Retalhuleu',
	STATE_SACATEPEQUEZ: 'Sacatepequez',
	STATE_SAN_MARCOS: 'San Marcos',
	STATE_SANTA_ROSA: 'Santa Rosa',
	STATE_SOLOLA: 'Solola',
	STATE_SUCHITEPEQUEZ: 'Suchitepequez',
	STATE_TOTONICAPAN: 'Totonicapan',
	STATE_ZACAPA: 'Zacapa',
	STATE_ALDERNEY: 'Alderney',
	STATE_CASTEL: 'Castel',
	STATE_FOREST: 'Forest',
	STATE_SAINT_PETER_PORT: 'Saint Peter Port',
	STATE_SAINT_PIERRE_DU_BOIS: 'Saint Pierre du Bois',
	STATE_SAINT_SAMPSON: 'Saint Sampson',
	STATE_SAINT_SAVIOUR: 'Saint Saviour',
	STATE_SARK: 'Sark',
	STATE_TORTEVAL: 'Torteval',
	STATE_VALE: 'Vale',
	STATE_BEYLA: 'Beyla',
	STATE_BOFFA: 'Boffa',
	STATE_BOKE: 'Boke',
	STATE_CONAKRY: 'Conakry',
	STATE_COYAH: 'Coyah',
	STATE_DABOLA: 'Dabola',
	STATE_DALABA: 'Dalaba',
	STATE_DINGUIRAYE: 'Dinguiraye',
	STATE_FARANAH: 'Faranah',
	STATE_FORECARIAH: 'Forecariah',
	STATE_FRIA: 'Fria',
	STATE_GAOUAL: 'Gaoual',
	STATE_GUECKEDOU: 'Gueckedou',
	STATE_KANKAN: 'Kankan',
	STATE_KEROUANE: 'Kerouane',
	STATE_KINDIA: 'Kindia',
	STATE_KISSIDOUGOU: 'Kissidougou',
	STATE_KOUBIA: 'Koubia',
	STATE_KOUNDARA: 'Koundara',
	STATE_KOUROUSSA: 'Kouroussa',
	STATE_LABE: 'Labe',
	STATE_LOLA: 'Lola',
	STATE_MACENTA: 'Macenta',
	STATE_MALI: 'Mali',
	STATE_MAMOU: 'Mamou',
	STATE_MANDIANA: 'Mandiana',
	STATE_NZEREKORE: 'Nzerekore',
	STATE_PITA: 'Pita',
	STATE_SIGUIRI: 'Siguiri',
	STATE_TELIMELE: 'Telimele',
	STATE_TOUGUE: 'Tougue',
	STATE_YOMOU: 'Yomou',
	STATE_BAFATA: 'Bafata',
	STATE_BISSAU: 'Bissau',
	STATE_BOLAMA: 'Bolama',
	STATE_CACHEU: 'Cacheu',
	STATE_GABU: 'Gabu',
	STATE_OIO: 'Oio',
	STATE_QUINARA: 'Quinara',
	STATE_TOMBALI: 'Tombali',
	STATE_BARIMA_WAINI: 'Barima-Waini',
	STATE_CUYUNI_MAZARUNI: 'Cuyuni-Mazaruni',
	STATE_DEMERARA_MAHAICA: 'Demerara-Mahaica',
	STATE_EAST_BERBICE_CORENTYNE: 'East Berbice-Corentyne',
	STATE_ESSEQUIBO_ISLANDS_WEST_DEMERAR: 'Essequibo Islands-West Demerar',
	STATE_MAHAICA_BERBICE: 'Mahaica-Berbice',
	STATE_POMEROON_SUPENAAM: 'Pomeroon-Supenaam',
	STATE_POTARO_SIPARUNI: 'Potaro-Siparuni',
	STATE_UPPER_DEMERARA_BERBICE: 'Upper Demerara-Berbice',
	STATE_UPPER_TAKUTU_UPPER_ESSEQUIBO: 'Upper Takutu-Upper Essequibo',
	STATE_ARTIBONITE: 'Artibonite',
	STATE_GRAND__ANSE: 'Grand’Anse',
	STATE_NORD_EST: 'Nord-Est',
	STATE_NORD_OUEST: 'Nord-Ouest',
	STATE_SUD_EST: 'Sud-Est',
	STATE_HEARD_AND_MCDONALD_ISLANDS: 'Heard und McDonaldinseln',
	STATE_ATLANTIDA: 'Atlantida',
	STATE_CHOLUTECA: 'Choluteca',
	STATE_COLON: 'Colon',
	STATE_COMAYAGUA: 'Comayagua',
	STATE_COPAN: 'Copan',
	STATE_CORTES: 'Cortes',
	STATE_DISTRITO_CENTRAL: 'Distrito Central',
	STATE_EL_PARAISO: 'El Paraiso',
	STATE_FRANCISCO_MORAZAN: 'Francisco Morazan',
	STATE_GRACIAS_A_DIOS: 'Gracias a Dios',
	STATE_INTIBUCA: 'Intibuca',
	STATE_ISLAS_DE_LA_BAHIA: 'Islas de la Bahia',
	STATE_LEMPIRA: 'Lempira',
	STATE_OCOTEPEQUE: 'Ocotepeque',
	STATE_OLANCHO: 'Olancho',
	STATE_SANTA_BARBARA: 'Santa Barbara',
	STATE_VALLE: 'Valle',
	STATE_YORO: 'Yoro',
	STATE_HONG_KONG: 'Hong Kong',
	STATE_BACS_KISKUN: 'Bacs-Kiskun',
	STATE_BARANYA: 'Baranya',
	STATE_BEKES: 'Bekes',
	STATE_BORSOD_ABAUJ_ZEMPLEN: 'Borsod-Abauj-Zemplen',
	STATE_BUDAPEST: 'Budapest',
	STATE_CSONGRAD: 'Csongrad',
	STATE_FEJER: 'Fejer',
	STATE_GYOR_MOSON_SOPRON: 'Gyor-Moson-Sopron',
	STATE_CORK: 'Cork',
	STATE_HAJDU_BIHAR: 'Hajdu-Bihar',
	STATE_HEVES: 'Heves',
	STATE_JASZ_NAGYKUN_SZOLNOK: 'Jasz-Nagykun-Szolnok',
	STATE_KOMAROM_ESZTERGOM: 'Komarom-Esztergom',
	STATE_NOGRAD: 'Nograd',
	STATE_PEST: 'Pest',
	STATE_SOMOGY: 'Somogy',
	STATE_SZABOLCS_SZATMAR_BEREG: 'Szabolcs-Szatmar-Bereg',
	STATE_TOLNA: 'Tolna',
	STATE_VAS: 'Vas',
	STATE_VESZPREM: 'Veszprem',
	STATE_ZALA: 'Zala',
	STATE_AUSTURLAND: 'Austurland',
	STATE_GULLBRINGUSYSLA: 'Gullbringusysla',
	STATE_HOFU_BORGARSVA_I: 'Hofu borgarsva i',
	STATE_NOR_URLAND_EYSTRA: 'Nor urland eystra',
	STATE_NOR_URLAND_VESTRA: 'Nor urland vestra',
	STATE_SU_URLAND: 'Su urland',
	STATE_SU_URNES: 'Su urnes',
	STATE_VESTFIR_IR: 'Vestfir ir',
	STATE_VESTURLAND: 'Vesturland',
	STATE_ACEH: 'Aceh',
	STATE_BALI: 'Bali',
	STATE_BANGKA_BELITUNG: 'Bangka-Belitung',
	STATE_BANTEN: 'Banten',
	STATE_BENGKULU: 'Bengkulu',
	STATE_GANDARIA: 'Gandaria',
	STATE_GORONTALO: 'Gorontalo',
	STATE_JAKARTA: 'Jakarta',
	STATE_JAMBI: 'Jambi',
	STATE_JAWA_BARAT: 'Jawa Barat',
	STATE_JAWA_TENGAH: 'Jawa Tengah',
	STATE_JAWA_TIMUR: 'Jawa Timur',
	STATE_KALIMANTAN_BARAT: 'Kalimantan Barat',
	STATE_KALIMANTAN_SELATAN: 'Kalimantan Selatan',
	STATE_KALIMANTAN_TENGAH: 'Kalimantan Tengah',
	STATE_KALIMANTAN_TIMUR: 'Kalimantan Timur',
	STATE_KENDAL: 'Kendal',
	STATE_LAMPUNG: 'Lampung',
	STATE_MALUKU: 'Maluku',
	STATE_MALUKU_UTARA: 'Maluku Utara',
	STATE_NUSA_TENGGARA_BARAT: 'Nusa Tenggara Barat',
	STATE_NUSA_TENGGARA_TIMUR: 'Nusa Tenggara Timur',
	STATE_PAPUA: 'Papua',
	STATE_RIAU: 'Riau',
	STATE_RIAU_KEPULAUAN: 'Riau Kepulauan',
	STATE_SOLO: 'Solo',
	STATE_SULAWESI_SELATAN: 'Sulawesi Selatan',
	STATE_SULAWESI_TENGAH: 'Sulawesi Tengah',
	STATE_SULAWESI_TENGGARA: 'Sulawesi Tenggara',
	STATE_SULAWESI_UTARA: 'Sulawesi Utara',
	STATE_SUMATERA_BARAT: 'Sumatera Barat',
	STATE_SUMATERA_SELATAN: 'Sumatera Selatan',
	STATE_SUMATERA_UTARA: 'Sumatera Utara',
	STATE_YOGYAKARTA: 'Yogyakarta',
	STATE_ARDABIL: 'Ardabil',
	STATE_AZARBAYJAN_E_BAKHTARI: 'Azarbayjan-e Bakhtari',
	STATE_AZARBAYJAN_E_KHAVARI: 'Azarbayjan-e Khavari',
	STATE_BUSHEHR: 'Bushehr',
	STATE_CHAHAR_MAHAL_E_BAKHTIARI: 'Chahar Mahal-e Bakhtiari',
	STATE_ESFAHAN: 'Esfahan',
	STATE_FARS: 'Fars',
	STATE_GILAN: 'Gilan',
	STATE_GOLESTAN: 'Golestan',
	STATE_HAMADAN: 'Hamadan',
	STATE_HORMOZGAN: 'Hormozgan',
	STATE_ILAM: 'Ilam',
	STATE_KERMAN: 'Kerman',
	STATE_KERMANSHAH: 'Kermanshah',
	STATE_KHORASAN: 'Khorasan',
	STATE_KHUZESTAN: 'Khuzestan',
	STATE_KOHGILUYEH_E_BOYERAHMAD: 'Kohgiluyeh-e Boyerahmad',
	STATE_KORDESTAN: 'Kordestan',
	STATE_LORESTAN: 'Lorestan',
	STATE_MARKAZI: 'Markazi',
	STATE_MAZANDARAN: 'Mazandaran',
	STATE_OSTAN_E_ESFAHAN: 'Ostan-e Esfahan',
	STATE_QAZVIN: 'Qazvin',
	STATE_QOM: 'Qom',
	STATE_SEMNAN: 'Semnan',
	STATE_SISTAN_E_BALUCHESTAN: 'Sistan-e Baluchestan',
	STATE_TEHRAN: 'Tehran',
	STATE_YAZD: 'Yazd',
	STATE_ZANJAN: 'Zanjan',
	STATE_BABIL: 'Babil',
	STATE_BAGHDAD: 'Baghdad',
	STATE_DAHUK: 'Dahuk',
	STATE_DHI_QAR: 'Dhi Qar',
	STATE_DIYALA: 'Diyala',
	STATE_ERBIL: 'Erbil',
	STATE_IRBIL: 'Irbil',
	STATE_KARBALA: 'Karbala',
	STATE_KURDISTAN: 'Kurdistan',
	STATE_MAYSAN: 'Maysan',
	STATE_NINAWA: 'Ninawa',
	STATE_SALAH_AD_DIN: 'Salah-ad-Din',
	STATE_WASIT: 'Wasit',
	STATE_AL_ANBAR: 'al-Anbar',
	STATE_AL_BASRAH: 'al-Basrah',
	STATE_AL_MUTHANNA: 'al-Muthanna',
	STATE_AL_QADISIYAH: 'al-Qadisiyah',
	STATE_AN_NAJAF: 'an-Najaf',
	STATE_AS_SULAYMANIYAH: 'as-Sulaymaniyah',
	STATE_AT_TA__MIM: 'at-Ta’mim',
	STATE_ARMAGH: 'Armagh',
	STATE_CARLOW: 'Carlow',
	STATE_CAVAN: 'Cavan',
	STATE_CLARE: 'Clare',
	STATE_DONEGAL: 'Donegal',
	STATE_DUBLIN: 'Dublin',
	STATE_GALWAY: 'Galway',
	STATE_KERRY: 'Kerry',
	STATE_KILDARE: 'Kildare',
	STATE_KILKENNY: 'Kilkenny',
	STATE_LAOIS: 'Laois',
	STATE_LEINSTER: 'Leinster',
	STATE_LEITRIM: 'Leitrim',
	STATE_LIMERICK: 'Limerick',
	STATE_LOCH_GARMAN: 'Loch Garman',
	STATE_LONGFORD: 'Longford',
	STATE_LOUTH: 'Louth',
	STATE_MAYO: 'Mayo',
	STATE_MEATH: 'Meath',
	STATE_MONAGHAN: 'Monaghan',
	STATE_OFFALY: 'Offaly',
	STATE_ROSCOMMON: 'Roscommon',
	STATE_SLIGO: 'Sligo',
	STATE_TIPPERARY_NORTH_RIDING: 'Tipperary North Riding',
	STATE_TIPPERARY_SOUTH_RIDING: 'Tipperary South Riding',
	STATE_ULSTER: 'Ulster',
	STATE_WATERFORD: 'Waterford',
	STATE_WESTMEATH: 'Westmeath',
	STATE_WEXFORD: 'Wexford',
	STATE_WICKLOW: 'Wicklow',
	STATE_BEIT_HANANIA: 'Beit Hanania',
	STATE_BEN_GURION_AIRPORT: 'Ben Gurion Airport',
	STATE_BETHLEHEM: 'Bethlehem',
	STATE_CAESAREA: 'Caesarea',
	STATE_GAZA: 'Gaza',
	STATE_HADARON: 'Hadaron',
	STATE_HAIFA_DISTRICT: 'Haifa District',
	STATE_HAMERKAZ: 'Hamerkaz',
	STATE_HAZAFON: 'Hazafon',
	STATE_HEBRON: 'Hebron',
	STATE_JAFFA: 'Jaffa',
	STATE_JERUSALEM: 'Jerusalem',
	STATE_KHEFA: 'Khefa',
	STATE_KIRYAT_YAM: 'Kiryat Yam',
	STATE_LOWER_GALILEE: 'Lower Galilee',
	STATE_QALQILYA: 'Qalqilya',
	STATE_TALME_ELAZAR: 'Talme Elazar',
	STATE_TEL_AVIV: 'Tel Aviv',
	STATE_TSAFON: 'Tsafon',
	STATE_UMM_EL_FAHEM: 'Umm El Fahem',
	STATE_YERUSHALAYIM: 'Yerushalayim',
	STATE_ABRUZZI: 'Abruzzi',
	STATE_ABRUZZO: 'Abruzzo',
	STATE_AGRIGENTO: 'Agrigento',
	STATE_ALESSANDRIA: 'Alessandria',
	STATE_ANCONA: 'Ancona',
	STATE_AREZZO: 'Arezzo',
	STATE_ASCOLI_PICENO: 'Ascoli Piceno',
	STATE_ASTI: 'Asti',
	STATE_AVELLINO: 'Avellino',
	STATE_BARI: 'Bari',
	STATE_BASILICATA: 'Basilicata',
	STATE_BELLUNO: 'Belluno',
	STATE_BENEVENTO: 'Benevento',
	STATE_BERGAMO: 'Bergamo',
	STATE_BIELLA: 'Biella',
	STATE_BOLOGNA: 'Bologna',
	STATE_BOLZANO: 'Bolzano',
	STATE_BRESCIA: 'Brescia',
	STATE_BRINDISI: 'Brindisi',
	STATE_CALABRIA: 'Calabria',
	STATE_CAMPANIA: 'Campania',
	STATE_CARTOCETO: 'Cartoceto',
	STATE_CASERTA: 'Caserta',
	STATE_CATANIA: 'Catania',
	STATE_CHIETI: 'Chieti',
	STATE_COMO: 'Como',
	STATE_COSENZA: 'Cosenza',
	STATE_CREMONA: 'Cremona',
	STATE_CUNEO: 'Cuneo',
	STATE_EMILIA_ROMAGNA: 'Emilia-Romagna',
	STATE_FERRARA: 'Ferrara',
	STATE_FIRENZE: 'Firenze',
	STATE_FLORENCE: 'Florenz',
	STATE_FORLI_CESENA: 'Forli-Cesena',
	STATE_FRIULI_VENEZIA_GIULIA: 'Friuli-Venezia Giulia',
	STATE_FROSINONE: 'Frosinone',
	STATE_GENOA: 'Genoa',
	STATE_GORIZIA: 'Gorizia',
	STATE_L__AQUILA: 'L’Aquila',
	STATE_LAZIO: 'Lazio',
	STATE_LECCE: 'Lecce',
	STATE_LECCO: 'Lecco',
	STATE_LECCO_PROVINCE: 'Lecco Province',
	STATE_LIGURIA: 'Liguria',
	STATE_LODI: 'Lodi',
	STATE_LOMBARDIA: 'Lombardia',
	STATE_LOMBARDY: 'Lombardy',
	STATE_MACERATA: 'Macerata',
	STATE_MANTOVA: 'Mantova',
	STATE_MARCHE: 'Marche',
	STATE_MESSINA: 'Messina',
	STATE_MILAN: 'Mailand',
	STATE_MODENA: 'Modena',
	STATE_MOLISE: 'Molise',
	STATE_MOLTENO: 'Molteno',
	STATE_MONTENEGRO: 'Montenegro',
	STATE_MONZA_AND_BRIANZA: 'Monza and Brianza',
	STATE_NAPLES: 'Neapel',
	STATE_NOVARA: 'Novara',
	STATE_PADOVA: 'Padova',
	STATE_PARMA: 'Parma',
	STATE_PAVIA: 'Pavia',
	STATE_RUMPHI: 'Rumphi',
	STATE_PERUGIA: 'Perugia',
	STATE_PESARO_URBINO: 'Pesaro-Urbino',
	STATE_PIACENZA: 'Piacenza',
	STATE_PIEDMONT: 'Piedmont',
	STATE_PIEMONTE: 'Piemonte',
	STATE_PISA: 'Pisa',
	STATE_PORDENONE: 'Pordenone',
	STATE_POTENZA: 'Potenza',
	STATE_PUGLIA: 'Puglia',
	STATE_REGGIO_EMILIA: 'Reggio Emilia',
	STATE_RIMINI: 'Rimini',
	STATE_ROMA: 'Roma',
	STATE_SALERNO: 'Salerno',
	STATE_SARDEGNA: 'Sardegna',
	STATE_SASSARI: 'Sassari',
	STATE_SAVONA: 'Savona',
	STATE_SICILIA: 'Sicilia',
	STATE_SIENA: 'Siena',
	STATE_SONDRIO: 'Sondrio',
	STATE_SOUTH_TYROL: 'Südtirol',
	STATE_TARANTO: 'Taranto',
	STATE_TERAMO: 'Teramo',
	STATE_TORINO: 'Torino',
	STATE_TOSCANA: 'Toscana',
	STATE_TRAPANI: 'Trapani',
	STATE_TRENTINO_ALTO_ADIGE: 'Trentino-Alto Adige',
	STATE_TRENTO: 'Trento',
	STATE_TREVISO: 'Treviso',
	STATE_UDINE: 'Udine',
	STATE_UMBRIA: 'Umbria',
	STATE_VALLE_D__AOSTA: 'Valle d’Aosta',
	STATE_VARESE: 'Varese',
	STATE_VENETO: 'Veneto',
	STATE_VENEZIA: 'Venezia',
	STATE_VERBANO_CUSIO_OSSOLA: 'Verbano-Cusio-Ossola',
	STATE_VERCELLI: 'Vercelli',
	STATE_VERONA: 'Verona',
	STATE_VICENZA: 'Vicenza',
	STATE_VITERBO: 'Viterbo',
	STATE_BUXORO_VILOYATI: 'Buxoro Viloyati',
	STATE_CLARENDON: 'Clarendon',
	STATE_HANOVER: 'Hanover',
	STATE_KINGSTON: 'Kingston',
	STATE_MANCHESTER: 'Manchester',
	STATE_PORTLAND: 'Portland',
	STATE_SAINT_ANDREWS: 'Saint Andrews',
	STATE_SAINT_ANN: 'Saint Ann',
	STATE_SAINT_CATHERINE: 'Saint Catherine',
	STATE_SAINT_ELIZABETH: 'Saint Elizabeth',
	STATE_TRELAWNEY: 'Trelawney',
	STATE_WESTMORELAND: 'Westmoreland',
	STATE_AICHI: 'Aichi',
	STATE_AKITA: 'Akita',
	STATE_AOMORI: 'Aomori',
	STATE_CHIBA: 'Chiba',
	STATE_EHIME: 'Ehime',
	STATE_FUKUI: 'Fukui',
	STATE_FUKUOKA: 'Fukuoka',
	STATE_FUKUSHIMA: 'Fukushima',
	STATE_GIFU: 'Gifu',
	STATE_GUMMA: 'Gumma',
	STATE_HIROSHIMA: 'Hiroshima',
	STATE_HOKKAIDO: 'Hokkaido',
	STATE_HYOGO: 'Hyogo',
	STATE_IBARAKI: 'Ibaraki',
	STATE_ISHIKAWA: 'Ishikawa',
	STATE_IWATE: 'Iwate',
	STATE_KAGAWA: 'Kagawa',
	STATE_KAGOSHIMA: 'Kagoshima',
	STATE_KANAGAWA: 'Kanagawa',
	STATE_KANTO: 'Kanto',
	STATE_KOCHI: 'Kochi',
	STATE_KUMAMOTO: 'Kumamoto',
	STATE_KYOTO: 'Kyoto',
	STATE_MIE: 'Mie',
	STATE_MIYAGI: 'Miyagi',
	STATE_MIYAZAKI: 'Miyazaki',
	STATE_NAGANO: 'Nagano',
	STATE_NAGASAKI: 'Nagasaki',
	STATE_NARA: 'Nara',
	STATE_NIIGATA: 'Niigata',
	STATE_OITA: 'Oita',
	STATE_OKAYAMA: 'Okayama',
	STATE_OKINAWA: 'Okinawa',
	STATE_OSAKA: 'Osaka',
	STATE_SAGA: 'Saga',
	STATE_SAITAMA: 'Saitama',
	STATE_SHIGA: 'Shiga',
	STATE_SHIMANE: 'Shimane',
	STATE_SHIZUOKA: 'Shizuoka',
	STATE_TOCHIGI: 'Tochigi',
	STATE_TOKUSHIMA: 'Tokushima',
	STATE_TOKYO: 'Tokyo',
	STATE_TOTTORI: 'Tottori',
	STATE_TOYAMA: 'Toyama',
	STATE_WAKAYAMA: 'Wakayama',
	STATE_YAMAGATA: 'Yamagata',
	STATE_YAMAGUCHI: 'Yamaguchi',
	STATE_YAMANASHI: 'Yamanashi',
	STATE_GROUVILLE: 'Grouville',
	STATE_SAINT_BRELADE: 'Saint Brelade',
	STATE_SAINT_CLEMENT: 'Saint Clement',
	STATE_SAINT_HELIER: 'Saint Helier',
	STATE_SAINT_LAWRENCE: 'Saint Lawrence',
	STATE_SALIMA: 'Salima',
	STATE_TRINITY: 'Trinity',
	STATE_AJLUN: 'Ajlun',
	STATE_AMMAN: 'Amman',
	STATE_IRBID: 'Irbid',
	STATE_JARASH: 'Jarash',
	STATE_MA__AN: 'Ma’an',
	STATE_MADABA: 'Madaba',
	STATE_AL___AQABAH: 'al-’Aqabah',
	STATE_AL_BALQA: 'al-Balqa',
	STATE_AL_KARAK: 'al-Karak',
	STATE_AL_MAFRAQ: 'al-Mafraq',
	STATE_AT_TAFILAH: 'at-Tafilah',
	STATE_AZ_ZARQA: 'az-Zarqa',
	STATE_AKMECET: 'Akmecet',
	STATE_AKMOLA: 'Akmola',
	STATE_AKTOBE: 'Aktobe',
	STATE_ALMATI: 'Almati',
	STATE_ATIRAU: 'Atirau',
	STATE_BATIS_KAZAKSTAN: 'Batis Kazakstan',
	STATE_BURLINSKY_REGION: 'Burlinsky Region',
	STATE_KARAGANDI: 'Karagandi',
	STATE_KOSTANAY: 'Kostanay',
	STATE_MANKISTAU: 'Mankistau',
	STATE_ONTUSTIK_KAZAKSTAN: 'Ontustik Kazakstan',
	STATE_PAVLODAR: 'Pavlodar',
	STATE_SIGIS_KAZAKSTAN: 'Sigis Kazakstan',
	STATE_SOLTUSTIK_KAZAKSTAN: 'Soltustik Kazakstan',
	STATE_TARAZ: 'Taraz',
	STATE_COAST: 'Coast',
	STATE_NAIROBI: 'Nairobi',
	STATE_NORTH_EASTERN: 'North Eastern',
	STATE_NYANZA: 'Nyanza',
	STATE_RIFT_VALLEY: 'Rift Valley',
	STATE_ABAIANG: 'Abaiang',
	STATE_ABEMANA: 'Abemana',
	STATE_ARANUKA: 'Aranuka',
	STATE_ARORAE: 'Arorae',
	STATE_BANABA: 'Banaba',
	STATE_BERU: 'Beru',
	STATE_BUTARITARI: 'Butaritari',
	STATE_KIRITIMATI: 'Kiritimati',
	STATE_KURIA: 'Kuria',
	STATE_MAIANA: 'Maiana',
	STATE_MAKIN: 'Makin',
	STATE_MARAKEI: 'Marakei',
	STATE_NIKUNAU: 'Nikunau',
	STATE_NONOUTI: 'Nonouti',
	STATE_ONOTOA: 'Onotoa',
	STATE_PHOENIX_ISLANDS: 'Phoenix Islands',
	STATE_TABITEUEA_NORTH: 'Tabiteuea North',
	STATE_TABITEUEA_SOUTH: 'Tabiteuea South',
	STATE_TABUAERAN: 'Tabuaeran',
	STATE_TAMANA: 'Tamana',
	STATE_TARAWA_NORTH: 'Tarawa North',
	STATE_TARAWA_SOUTH: 'Tarawa South',
	STATE_TERAINA: 'Teraina',
	STATE_CHAGANGDO: 'Chagangdo',
	STATE_HAMGYEONGBUKTO: 'Hamgyeongbukto',
	STATE_HAMGYEONGNAMDO: 'Hamgyeongnamdo',
	STATE_HWANGHAEBUKTO: 'Hwanghaebukto',
	STATE_HWANGHAENAMDO: 'Hwanghaenamdo',
	STATE_KAESEONG: 'Kaeseong',
	STATE_KANGWEON: 'Kangweon',
	STATE_NAMPO: 'Nampo',
	STATE_PYEONGANBUKTO: 'Pyeonganbukto',
	STATE_PYEONGANNAMDO: 'Pyeongannamdo',
	STATE_PYEONGYANG: 'Pyeongyang',
	STATE_YANGGANG: 'Yanggang',
	STATE_BUSAN: 'Busan',
	STATE_CHEJU: 'Cheju',
	STATE_CHOLLABUK: 'Chollabuk',
	STATE_CHOLLANAM: 'Chollanam',
	STATE_CHUNGBUK: 'Chungbuk',
	STATE_CHUNGCHEONGBUK: 'Chungcheongbuk',
	STATE_CHUNGCHEONGNAM: 'Chungcheongnam',
	STATE_CHUNGNAM: 'Chungnam',
	STATE_DAEGU: 'Daegu',
	STATE_GANGWON_DO: 'Gangwon-do',
	STATE_GOYANG_SI: 'Goyang-si',
	STATE_GYEONGGI_DO: 'Gyeonggi-do',
	STATE_GYEONGSANG: 'Gyeongsang',
	STATE_GYEONGSANGNAM_DO: 'Gyeongsangnam-do',
	STATE_INCHEON: 'Incheon',
	STATE_JEJU_SI: 'Jeju-Si',
	STATE_JEONBUK: 'Jeonbuk',
	STATE_KWANGJU: 'Kwangju',
	STATE_KYEONGGI: 'Kyeonggi',
	STATE_KYEONGSANGBUK: 'Kyeongsangbuk',
	STATE_KYEONGSANGNAM: 'Kyeongsangnam',
	STATE_KYONGGI_DO: 'Kyonggi-do',
	STATE_KYUNGBUK_DO: 'Kyungbuk-Do',
	STATE_KYUNGGI_DO: 'Kyunggi-Do',
	STATE_PUSAN: 'Pusan',
	STATE_SEOUL: 'Seoul',
	STATE_SUDOGWON: 'Sudogwon',
	STATE_TAEGU: 'Taegu',
	STATE_TAEJEON: 'Taejeon',
	STATE_TAEJON_GWANGYOKSI: 'Taejon-gwangyoksi',
	STATE_ULSAN: 'Ulsan',
	STATE_WONJU: 'Wonju',
	STATE_GWANGYOKSI: 'gwangyoksi',
	STATE_AL_ASIMAH: 'Al Asimah',
	STATE_HAWALLI: 'Hawalli',
	STATE_MISHREF: 'Mishref',
	STATE_QADESIYA: 'Qadesiya',
	STATE_SAFAT: 'Safat',
	STATE_SALMIYA: 'Salmiya',
	STATE_AL_AHMADI: 'al-Ahmadi',
	STATE_AL_FARWANIYAH: 'al-Farwaniyah',
	STATE_AL_JAHRA: 'al-Jahra',
	STATE_AL_KUWAYT: 'al-Kuwayt',
	STATE_BATKEN: 'Batken',
	STATE_BISHKEK: 'Bishkek',
	STATE_CHUI: 'Chui',
	STATE_ISSYK_KUL: 'Issyk-Kul',
	STATE_JALAL_ABAD: 'Jalal-Abad',
	STATE_NARYN: 'Naryn',
	STATE_OSH: 'Osh',
	STATE_TALAS: 'Talas',
	STATE_ATTOPU: 'Attopu',
	STATE_BOKEO: 'Bokeo',
	STATE_BOLIKHAMSAY: 'Bolikhamsay',
	STATE_CHAMPASAK: 'Champasak',
	STATE_HOUAPHANH: 'Houaphanh',
	STATE_KHAMMOUANE: 'Khammouane',
	STATE_LUANG_NAM_THA: 'Luang Nam Tha',
	STATE_LUANG_PRABANG: 'Luang Prabang',
	STATE_OUDOMXAY: 'Oudomxay',
	STATE_PHONGSALY: 'Phongsaly',
	STATE_SARAVAN: 'Saravan',
	STATE_SAVANNAKHET: 'Savannakhet',
	STATE_SEKONG: 'Sekong',
	STATE_VIANGCHAN_PREFECTURE: 'Viangchan Prefecture',
	STATE_VIANGCHAN_PROVINCE: 'Viangchan Province',
	STATE_XAIGNABURY: 'Xaignabury',
	STATE_XIANG_KHUANG: 'Xiang Khuang',
	STATE_AIZKRAUKLES: 'Aizkraukles',
	STATE_ALUKSNES: 'Aluksnes',
	STATE_BALVU: 'Balvu',
	STATE_BAUSKAS: 'Bauskas',
	STATE_CESU: 'Cesu',
	STATE_DAUGAVPILS: 'Daugavpils',
	STATE_DAUGAVPILS_CITY: 'Daugavpils City',
	STATE_DOBELES: 'Dobeles',
	STATE_GULBENES: 'Gulbenes',
	STATE_JEKABSPILS: 'Jekabspils',
	STATE_JELGAVA: 'Jelgava',
	STATE_JELGAVAS: 'Jelgavas',
	STATE_JURMALA_CITY: 'Jurmala City',
	STATE_KRASLAVAS: 'Kraslavas',
	STATE_KULDIGAS: 'Kuldigas',
	STATE_LIEPAJA: 'Liepaja',
	STATE_LIEPAJAS: 'Liepajas',
	STATE_LIMBAZHU: 'Limbazhu',
	STATE_LUDZAS: 'Ludzas',
	STATE_MADONAS: 'Madonas',
	STATE_OGRES: 'Ogres',
	STATE_PREILU: 'Preilu',
	STATE_REZEKNE: 'Rezekne',
	STATE_REZEKNES: 'Rezeknes',
	STATE_RIGA: 'Riga',
	STATE_RIGAS: 'Rigas',
	STATE_SALDUS: 'Saldus',
	STATE_TALSU: 'Talsu',
	STATE_TUKUMA: 'Tukuma',
	STATE_VALKAS: 'Valkas',
	STATE_VALMIERAS: 'Valmieras',
	STATE_VENTSPILS: 'Ventspils',
	STATE_VENTSPILS_CITY: 'Ventspils City',
	STATE_BEIRUT: 'Beirut',
	STATE_JABAL_LUBNAN: 'Jabal Lubnan',
	STATE_MOHAFAZAT_LIBAN_NORD: 'Mohafazat Liban-Nord',
	STATE_MOHAFAZAT_MONT_LIBAN: 'Mohafazat Mont-Liban',
	STATE_SIDON: 'Sidon',
	STATE_AL_BIQA: 'al-Biqa',
	STATE_AL_JANUB: 'al-Janub',
	STATE_AN_NABATIYAH: 'an-Nabatiyah',
	STATE_ASH_SHAMAL: 'ash-Shamal',
	STATE_BEREA: 'Berea',
	STATE_BUTHA_BUTHE: 'Butha-Buthe',
	STATE_LERIBE: 'Leribe',
	STATE_MAFETENG: 'Mafeteng',
	STATE_MASERU: 'Maseru',
	STATE_MOHALE__S_HOEK: 'Mohale’s Hoek',
	STATE_MOKHOTLONG: 'Mokhotlong',
	STATE_QACHA__S_NEK: 'Qacha’s Nek',
	STATE_QUTHING: 'Quthing',
	STATE_THABA_TSEKA: 'Thaba-Tseka',
	STATE_BOMI: 'Bomi',
	STATE_BONG: 'Bong',
	STATE_GRAND_BASSA: 'Grand Bassa',
	STATE_GRAND_CAPE_MOUNT: 'Grand Cape Mount',
	STATE_GRAND_GEDEH: 'Grand Gedeh',
	STATE_LOFFA: 'Loffa',
	STATE_MARGIBI: 'Margibi',
	STATE_MARYLAND_AND_GRAND_KRU: 'Maryland and Grand Kru',
	STATE_MONTSERRADO: 'Montserrado',
	STATE_NIMBA: 'Nimba',
	STATE_RIVERCESS: 'Rivercess',
	STATE_SINOE: 'Sinoe',
	STATE_AJDABIYA: 'Ajdabiya',
	STATE_FEZZAN: 'Fezzan',
	STATE_BANGHAZI: 'Banghazi',
	STATE_DARNAH: 'Darnah',
	STATE_GHADAMIS: 'Ghadamis',
	STATE_GHARYAN: 'Gharyan',
	STATE_MISRATAH: 'Misratah',
	STATE_MURZUQ: 'Murzuq',
	STATE_SABHA: 'Sabha',
	STATE_SAWFAJJIN: 'Sawfajjin',
	STATE_SURT: 'Surt',
	STATE_TARABULUS: 'Tarabulus',
	STATE_TARHUNAH: 'Tarhunah',
	STATE_TRIPOLITANIA: 'Tripolitania',
	STATE_TUBRUQ: 'Tubruq',
	STATE_YAFRAN: 'Yafran',
	STATE_ZLITAN: 'Zlitan',
	STATE_AL___AZIZIYAH: 'al-’Aziziyah',
	STATE_AL_FATIH: 'al-Fatih',
	STATE_AL_JABAL_AL_AKHDAR: 'al-Jabal al Akhdar',
	STATE_AL_JUFRAH: 'al-Jufrah',
	STATE_AL_KHUMS: 'al-Khums',
	STATE_AL_KUFRAH: 'al-Kufrah',
	STATE_AN_NUQAT_AL_KHAMS: 'an-Nuqat al-Khams',
	STATE_ASH_SHATI: 'ash-Shati',
	STATE_AZ_ZAWIYAH: 'az-Zawiyah',
	STATE_BALZERS: 'Balzers',
	STATE_ESCHEN: 'Eschen',
	STATE_GAMPRIN: 'Gamprin',
	STATE_MAUREN: 'Mauren',
	STATE_PLANKEN: 'Planken',
	STATE_RUGGELL: 'Ruggell',
	STATE_SCHAAN: 'Schaan',
	STATE_SCHELLENBERG: 'Schellenberg',
	STATE_TRIESEN: 'Triesen',
	STATE_TRIESENBERG: 'Triesenberg',
	STATE_VADUZ: 'Vaduz',
	STATE_ALYTAUS: 'Alytaus',
	STATE_ANYKSCIAI: 'Anyksciai',
	STATE_KAUNO: 'Kauno',
	STATE_KLAIPEDOS: 'Klaipedos',
	STATE_MARIJAMPOLES: 'Marijampoles',
	STATE_PANEVEZHIO: 'Panevezhio',
	STATE_PANEVEZYS: 'Panevezys',
	STATE_SHIAULIU: 'Shiauliu',
	STATE_TAURAGES: 'Taurages',
	STATE_TELSHIU: 'Telshiu',
	STATE_TELSIAI: 'Telsiai',
	STATE_UTENOS: 'Utenos',
	STATE_VILNIAUS: 'Vilniaus',
	STATE_CAPELLEN: 'Capellen',
	STATE_CLERVAUX: 'Clervaux',
	STATE_DIEKIRCH: 'Diekirch',
	STATE_ECHTERNACH: 'Echternach',
	STATE_ESCH_SUR_ALZETTE: 'Esch-sur-Alzette',
	STATE_GREVENMACHER: 'Grevenmacher',
	STATE_MERSCH: 'Mersch',
	STATE_REDANGE: 'Redange',
	STATE_REMICH: 'Remich',
	STATE_VIANDEN: 'Vianden',
	STATE_WILTZ: 'Wiltz',
	STATE_MACAU: 'Macau',
	STATE_BEROVO: 'Berovo',
	STATE_BITOLA: 'Bitola',
	STATE_BROD: 'Brod',
	STATE_DEBAR: 'Debar',
	STATE_DELCHEVO: 'Delchevo',
	STATE_DEMIR_HISAR: 'Demir Hisar',
	STATE_GEVGELIJA: 'Gevgelija',
	STATE_GOSTIVAR: 'Gostivar',
	STATE_KAVADARCI: 'Kavadarci',
	STATE_KICHEVO: 'Kichevo',
	STATE_KOCHANI: 'Kochani',
	STATE_KRATOVO: 'Kratovo',
	STATE_KRIVA_PALANKA: 'Kriva Palanka',
	STATE_KRUSHEVO: 'Krushevo',
	STATE_KUMANOVO: 'Kumanovo',
	STATE_NEGOTINO: 'Negotino',
	STATE_OHRID: 'Ohrid',
	STATE_PRILEP: 'Prilep',
	STATE_PROBISHTIP: 'Probishtip',
	STATE_RADOVISH: 'Radovish',
	STATE_RESEN: 'Resen',
	STATE_SHTIP: 'Shtip',
	STATE_SKOPJE: 'Skopje',
	STATE_STRUGA: 'Struga',
	STATE_STRUMICA: 'Strumica',
	STATE_SVETI_NIKOLE: 'Sveti Nikole',
	STATE_TETOVO: 'Tetovo',
	STATE_VALANDOVO: 'Valandovo',
	STATE_VELES: 'Veles',
	STATE_VINICA: 'Vinica',
	STATE_ANTANANARIVO: 'Antananarivo',
	STATE_ANTSIRANANA: 'Antsiranana',
	STATE_FIANARANTSOA: 'Fianarantsoa',
	STATE_MAHAJANGA: 'Mahajanga',
	STATE_TOAMASINA: 'Toamasina',
	STATE_TOLIARY: 'Toliary',
	STATE_BALAKA: 'Balaka',
	STATE_BLANTYRE_CITY: 'Blantyre City',
	STATE_CHIKWAWA: 'Chikwawa',
	STATE_CHIRADZULU: 'Chiradzulu',
	STATE_CHITIPA: 'Chitipa',
	STATE_DEDZA: 'Dedza',
	STATE_DOWA: 'Dowa',
	STATE_KARONGA: 'Karonga',
	STATE_KASUNGU: 'Kasungu',
	STATE_LILONGWE_CITY: 'Lilongwe City',
	STATE_MACHINGA: 'Machinga',
	STATE_MANGOCHI: 'Mangochi',
	STATE_MCHINJI: 'Mchinji',
	STATE_MULANJE: 'Mulanje',
	STATE_MWANZA: 'Mwanza',
	STATE_MZIMBA: 'Mzimba',
	STATE_MZUZU_CITY: 'Mzuzu City',
	STATE_NKHATA_BAY: 'Nkhata Bay',
	STATE_NKHOTAKOTA: 'Nkhotakota',
	STATE_NSANJE: 'Nsanje',
	STATE_NTCHEU: 'Ntcheu',
	STATE_NTCHISI: 'Ntchisi',
	STATE_PHALOMBE: 'Phalombe',
	STATE_THYOLO: 'Thyolo',
	STATE_ZOMBA_MUNICIPALITY: 'Zomba Municipality',
	STATE_JOHOR: 'Johor',
	STATE_KEDAH: 'Kedah',
	STATE_KELANTAN: 'Kelantan',
	STATE_KUALA_LUMPUR: 'Kuala Lumpur',
	STATE_LABUAN: 'Labuan',
	STATE_MELAKA: 'Melaka',
	STATE_NEGERI_JOHOR: 'Negeri Johor',
	STATE_NEGERI_SEMBILAN: 'Negeri Sembilan',
	STATE_PAHANG: 'Pahang',
	STATE_PENANG: 'Penang',
	STATE_PERAK: 'Perak',
	STATE_PERLIS: 'Perlis',
	STATE_PULAU_PINANG: 'Pulau Pinang',
	STATE_SABAH: 'Sabah',
	STATE_SARAWAK: 'Sarawak',
	STATE_SELANGOR: 'Selangor',
	STATE_SEMBILAN: 'Sembilan',
	STATE_TERENGGANU: 'Terengganu',
	STATE_ALIF_ALIF: 'Alif Alif',
	STATE_ALIF_DHAAL: 'Alif Dhaal',
	STATE_BAA: 'Baa',
	STATE_DHAAL: 'Dhaal',
	STATE_FAAF: 'Faaf',
	STATE_GAAF_ALIF: 'Gaaf Alif',
	STATE_GAAF_DHAAL: 'Gaaf Dhaal',
	STATE_GHAVIYANI: 'Ghaviyani',
	STATE_HAA_ALIF: 'Haa Alif',
	STATE_HAA_DHAAL: 'Haa Dhaal',
	STATE_KAAF: 'Kaaf',
	STATE_LAAM: 'Laam',
	STATE_LHAVIYANI: 'Lhaviyani',
	STATE_MALE: 'Male',
	STATE_MIIM: 'Miim',
	STATE_NUUN: 'Nuun',
	STATE_RAA: 'Raa',
	STATE_SHAVIYANI: 'Shaviyani',
	STATE_SIIN: 'Siin',
	STATE_THAA: 'Thaa',
	STATE_VAAV: 'Vaav',
	STATE_BAMAKO: 'Bamako',
	STATE_GAO: 'Gao',
	STATE_KAYES: 'Kayes',
	STATE_KIDAL: 'Kidal',
	STATE_KOULIKORO: 'Koulikoro',
	STATE_MOPTI: 'Mopti',
	STATE_SEGOU: 'Segou',
	STATE_SIKASSO: 'Sikasso',
	STATE_TOMBOUCTOU: 'Tombouctou',
	STATE_GOZO_AND_COMINO: 'Gozo and Comino',
	STATE_INNER_HARBOUR: 'Inner Harbour',
	STATE_OUTER_HARBOUR: 'Outer Harbour',
	STATE_SOUTH_EASTERN: 'South Eastern',
	STATE_CASTLETOWN: 'Castletown',
	STATE_DOUGLAS: 'Douglas',
	STATE_LAXEY: 'Laxey',
	STATE_ONCHAN: 'Onchan',
	STATE_PEEL: 'Peel',
	STATE_PORT_ERIN: 'Port Erin',
	STATE_PORT_SAINT_MARY: 'Port Saint Mary',
	STATE_RAMSEY: 'Ramsey',
	STATE_AILINLAPLAP: 'Ailinlaplap',
	STATE_AILUK: 'Ailuk',
	STATE_ARNO: 'Arno',
	STATE_AUR: 'Aur',
	STATE_BIKINI: 'Bikini',
	STATE_EBON: 'Ebon',
	STATE_ENEWETAK: 'Enewetak',
	STATE_JABAT: 'Jabat',
	STATE_JALUIT: 'Jaluit',
	STATE_KILI: 'Kili',
	STATE_KWAJALEIN: 'Kwajalein',
	STATE_LAE: 'Lae',
	STATE_LIB: 'Lib',
	STATE_LIKIEP: 'Likiep',
	STATE_MAJURO: 'Majuro',
	STATE_MALOELAP: 'Maloelap',
	STATE_MEJIT: 'Mejit',
	STATE_MILI: 'Mili',
	STATE_NAMORIK: 'Namorik',
	STATE_NAMU: 'Namu',
	STATE_RONGELAP: 'Rongelap',
	STATE_UJAE: 'Ujae',
	STATE_UTRIK: 'Utrik',
	STATE_WOTHO: 'Wotho',
	STATE_WOTJE: 'Wotje',
	STATE_FORT_DE_FRANCE: 'Fort-de-France',
	STATE_LA_TRINITE: 'La Trinite',
	STATE_LE_MARIN: 'Le Marin',
	STATE_SAINT_PIERRE: 'Saint-Pierre',
	STATE_ASSABA: 'Assaba',
	STATE_BRAKNA: 'Brakna',
	STATE_DHAKHLAT_NAWADIBU: 'Dhakhlat Nawadibu',
	STATE_HUDH_AL_GHARBI: 'Hudh-al-Gharbi',
	STATE_HUDH_ASH_SHARQI: 'Hudh-ash-Sharqi',
	STATE_INSHIRI: 'Inshiri',
	STATE_NAWAKSHUT: 'Nawakshut',
	STATE_QIDIMAGHA: 'Qidimagha',
	STATE_QURQUL: 'Qurqul',
	STATE_TAQANT: 'Taqant',
	STATE_TIRIS_ZAMMUR: 'Tiris Zammur',
	STATE_TRARZA: 'Trarza',
	STATE_BLACK_RIVER: 'Black River',
	STATE_EAU_COULEE: 'Eau Coulee',
	STATE_FLACQ: 'Flacq',
	STATE_FLOREAL: 'Floreal',
	STATE_GRAND_PORT: 'Grand Port',
	STATE_MOKA: 'Moka',
	STATE_PAMPLEMPOUSSES: 'Pamplempousses',
	STATE_PLAINES_WILHELM: 'Plaines Wilhelm',
	STATE_PORT_LOUIS: 'Port Louis',
	STATE_RIVIERE_DU_REMPART: 'Riviere du Rempart',
	STATE_RODRIGUES: 'Rodrigues',
	STATE_ROSE_HILL: 'Rose Hill',
	STATE_SAVANNE: 'Savanne',
	STATE_MAYOTTE: 'Mayotte',
	STATE_PAMANZI: 'Pamanzi',
	STATE_AGUASCALIENTES: 'Aguascalientes',
	STATE_BAJA_CALIFORNIA: 'Baja California',
	STATE_BAJA_CALIFORNIA_SUR: 'Baja California Sur',
	STATE_CAMPECHE: 'Campeche',
	STATE_CHIAPAS: 'Chiapas',
	STATE_CHIHUAHUA: 'Chihuahua',
	STATE_COAHUILA: 'Coahuila',
	STATE_COLIMA: 'Colima',
	STATE_DURANGO: 'Durango',
	STATE_ESTADO_DE_MEXICO: 'Estado de Mexico',
	STATE_GUANAJUATO: 'Guanajuato',
	STATE_GUERRERO: 'Guerrero',
	STATE_HIDALGO: 'Hidalgo',
	STATE_JALISCO: 'Jalisco',
	STATE_MEXICO: 'Mexico',
	STATE_MICHOACAN: 'Michoacan',
	STATE_MORELOS: 'Morelos',
	STATE_NAYARIT: 'Nayarit',
	STATE_NUEVO_LEON: 'Nuevo Leon',
	STATE_OAXACA: 'Oaxaca',
	STATE_PUEBLA: 'Puebla',
	STATE_QUERETARO: 'Queretaro',
	STATE_QUINTANA_ROO: 'Quintana Roo',
	STATE_SAN_LUIS_POTOSI: 'San Luis Potosi',
	STATE_SINALOA: 'Sinaloa',
	STATE_SONORA: 'Sonora',
	STATE_TABASCO: 'Tabasco',
	STATE_TAMAULIPAS: 'Tamaulipas',
	STATE_TLAXCALA: 'Tlaxcala',
	STATE_VERACRUZ: 'Veracruz',
	STATE_YUCATAN: 'Yucatan',
	STATE_ZACATECAS: 'Zacatecas',
	STATE_CHUUK: 'Chuuk',
	STATE_KUSAIE: 'Kusaie',
	STATE_POHNPEI: 'Pohnpei',
	STATE_YAP: 'Yap',
	STATE_BALTI: 'Balti',
	STATE_CAHUL: 'Cahul',
	STATE_CHISINAU: 'Chisinau',
	STATE_CHISINAU_ORAS: 'Chisinau Oras',
	STATE_EDINET: 'Edinet',
	STATE_GAGAUZIA: 'Gagauzia',
	STATE_LAPUSNA: 'Lapusna',
	STATE_ORHEI: 'Orhei',
	STATE_SOROCA: 'Soroca',
	STATE_TARACLIA: 'Taraclia',
	STATE_TIGHINA: 'Tighina',
	STATE_TRANSNISTRIA: 'Transnistria',
	STATE_UNGHENI: 'Ungheni',
	STATE_FONTVIEILLE: 'Fontvieille',
	STATE_LA_CONDAMINE: 'La Condamine',
	STATE_MONACO_VILLE: 'Monaco-Ville',
	STATE_MONTE_CARLO: 'Monte Carlo',
	STATE_ARHANGAJ: 'Arhangaj',
	STATE_BAJAN_OLGIJ: 'Bajan-Olgij',
	STATE_BAJANHONGOR: 'Bajanhongor',
	STATE_BULGAN: 'Bulgan',
	STATE_DARHAN_UUL: 'Darhan-Uul',
	STATE_DORNOD: 'Dornod',
	STATE_DORNOGOVI: 'Dornogovi',
	STATE_DUNDGOVI: 'Dundgovi',
	STATE_GOVI_ALTAJ: 'Govi-Altaj',
	STATE_GOVISUMBER: 'Govisumber',
	STATE_HENTIJ: 'Hentij',
	STATE_HOVD: 'Hovd',
	STATE_HOVSGOL: 'Hovsgol',
	STATE_OMNOGOVI: 'Omnogovi',
	STATE_ORHON: 'Orhon',
	STATE_OVORHANGAJ: 'Ovorhangaj',
	STATE_SELENGE: 'Selenge',
	STATE_SUHBAATAR: 'Suhbaatar',
	STATE_TOV: 'Tov',
	STATE_ULAANBAATAR: 'Ulaanbaatar',
	STATE_UVS: 'Uvs',
	STATE_ZAVHAN: 'Zavhan',
	STATE_MONTSERRAT: 'Montserrat',
	STATE_AGADIR: 'Agadir',
	STATE_CASABLANCA: 'Casablanca',
	STATE_CHAOUIA_OUARDIGHA: 'Chaouia-Ouardigha',
	STATE_DOUKKALA_ABDA: 'Doukkala-Abda',
	STATE_FES_BOULEMANE: 'Fes-Boulemane',
	STATE_GHARB_CHRARDA_BENI_HSSEN: 'Gharb-Chrarda-Beni Hssen',
	STATE_GUELMIM: 'Guelmim',
	STATE_KENITRA: 'Kenitra',
	STATE_MARRAKECH_TENSIFT_AL_HAOUZ: 'Marrakech-Tensift-Al Haouz',
	STATE_MEKNES_TAFILALET: 'Meknes-Tafilalet',
	STATE_ORIENTAL: 'Oriental',
	STATE_OUJDA: 'Oujda',
	STATE_PROVINCE_DE_TANGER: 'Province de Tanger',
	STATE_RABAT_SALE_ZAMMOUR_ZAER: 'Rabat-Sale-Zammour-Zaer',
	STATE_SALA_AL_JADIDA: 'Sala Al Jadida',
	STATE_SETTAT: 'Settat',
	STATE_SOUSS_MASSA_DRAA: 'Souss Massa-Draa',
	STATE_TADLA_AZILAL: 'Tadla-Azilal',
	STATE_TANGIER_TETOUAN: 'Tangier-Tetouan',
	STATE_TAZA_AL_HOCEIMA_TAOUNATE: 'Taza-Al Hoceima-Taounate',
	STATE_WILAYA_DE_CASABLANCA: 'Wilaya de Casablanca',
	STATE_WILAYA_DE_RABAT_SALE: 'Wilaya de Rabat-Sale',
	STATE_CABO_DELGADO: 'Cabo Delgado',
	STATE_INHAMBANE: 'Inhambane',
	STATE_MANICA: 'Manica',
	STATE_MAPUTO: 'Maputo',
	STATE_MAPUTO_PROVINCIA: 'Maputo Provincia',
	STATE_NAMPULA: 'Nampula',
	STATE_NIASSA: 'Niassa',
	STATE_SOFALA: 'Sofala',
	STATE_TETE: 'Tete',
	STATE_ZAMBEZIA: 'Zambezia',
	STATE_AYEYARWADY: 'Ayeyarwady',
	STATE_BAGO: 'Bago',
	STATE_CHIN: 'Chin',
	STATE_KACHIN: 'Kachin',
	STATE_KAYAH: 'Kayah',
	STATE_KAYIN: 'Kayin',
	STATE_MAGWAY: 'Magway',
	STATE_MANDALAY: 'Mandalay',
	STATE_MON: 'Mon',
	STATE_NAY_PYI_TAW: 'Nay Pyi Taw',
	STATE_RAKHINE: 'Rakhine',
	STATE_SAGAING: 'Sagaing',
	STATE_SHAN: 'Shan',
	STATE_TANINTHARYI: 'Tanintharyi',
	STATE_YANGON: 'Yangon',
	STATE_CAPRIVI: 'Caprivi',
	STATE_ERONGO: 'Erongo',
	STATE_HARDAP: 'Hardap',
	STATE_KARAS: 'Karas',
	STATE_KAVANGO: 'Kavango',
	STATE_KHOMAS: 'Khomas',
	STATE_KUNENE: 'Kunene',
	STATE_OHANGWENA: 'Ohangwena',
	STATE_OMAHEKE: 'Omaheke',
	STATE_OMUSATI: 'Omusati',
	STATE_OSHANA: 'Oshana',
	STATE_OSHIKOTO: 'Oshikoto',
	STATE_OTJOZONDJUPA: 'Otjozondjupa',
	STATE_YAREN: 'Yaren',
	STATE_BAGMATI: 'Bagmati',
	STATE_BHERI: 'Bheri',
	STATE_DHAWALAGIRI: 'Dhawalagiri',
	STATE_GANDAKI: 'Gandaki',
	STATE_JANAKPUR: 'Janakpur',
	STATE_KARNALI: 'Karnali',
	STATE_KOSHI: 'Koshi',
	STATE_LUMBINI: 'Lumbini',
	STATE_MAHAKALI: 'Mahakali',
	STATE_MECHI: 'Mechi',
	STATE_NARAYANI: 'Narayani',
	STATE_RAPTI: 'Rapti',
	STATE_SAGARMATHA: 'Sagarmatha',
	STATE_SETI: 'Seti',
	STATE_BONAIRE: 'Bonaire',
	STATE_CURACAO: 'Curacao',
	STATE_SABA: 'Saba',
	STATE_SINT_EUSTATIUS: 'Sint Eustatius',
	STATE_SINT_MAARTEN: 'Sint Maarten',
	STATE_BENELUX: 'Benelux',
	STATE_DRENTHE: 'Drenthe',
	STATE_FLEVOLAND: 'Flevoland',
	STATE_FRIESLAND: 'Friesland',
	STATE_GELDERLAND: 'Gelderland',
	STATE_GRONINGEN: 'Groningen',
	STATE_NOORD_BRABANT: 'Noord-Brabant',
	STATE_OVERIJSSEL: 'Overijssel',
	STATE_SOUTH_HOLLAND: 'South Holland',
	STATE_UTRECHT: 'Utrecht',
	STATE_ZEELAND: 'Zeeland',
	STATE_ZUID_HOLLAND: 'Zuid-Holland',
	STATE_ILES: 'Iles',
	STATE_AREA_OUTSIDE_REGION: 'Area Outside Region',
	STATE_BAY_OF_PLENTY: 'Bay of Plenty',
	STATE_CANTERBURY: 'Canterbury',
	STATE_CHRISTCHURCH: 'Christchurch',
	STATE_GISBORNE: 'Gisborne',
	STATE_HAWKE__S_BAY: 'Hawke’s Bay',
	STATE_MANAWATU_WANGANUI: 'Manawatu-Wanganui',
	STATE_MARLBOROUGH: 'Marlborough',
	STATE_NELSON: 'Nelson',
	STATE_NORTHLAND: 'Northland',
	STATE_OTAGO: 'Otago',
	STATE_RODNEY: 'Rodney',
	STATE_SOUTHLAND: 'Southland',
	STATE_TARANAKI: 'Taranaki',
	STATE_TASMAN: 'Tasman',
	STATE_WAIKATO: 'Waikato',
	STATE_WELLINGTON: 'Wellington',
	STATE_WEST_COAST: 'West Coast',
	STATE_ATLANTICO_NORTE: 'Atlantico Norte',
	STATE_ATLANTICO_SUR: 'Atlantico Sur',
	STATE_BOACO: 'Boaco',
	STATE_CARAZO: 'Carazo',
	STATE_CHINANDEGA: 'Chinandega',
	STATE_CHONTALES: 'Chontales',
	STATE_ESTELI: 'Esteli',
	STATE_GRANADA: 'Granada',
	STATE_JINOTEGA: 'Jinotega',
	STATE_LEON: 'Leon',
	STATE_MADRIZ: 'Madriz',
	STATE_MANAGUA: 'Managua',
	STATE_MASAYA: 'Masaya',
	STATE_MATAGALPA: 'Matagalpa',
	STATE_NUEVA_SEGOVIA: 'Nueva Segovia',
	STATE_RIO_SAN_JUAN: 'Rio San Juan',
	STATE_RIVAS: 'Rivas',
	STATE_AGADEZ: 'Agadez',
	STATE_DIFFA: 'Diffa',
	STATE_DOSSO: 'Dosso',
	STATE_MARADI: 'Maradi',
	STATE_NIAMEY: 'Niamey',
	STATE_TAHOUA: 'Tahoua',
	STATE_TILLABERY: 'Tillabery',
	STATE_ZINDER: 'Zinder',
	STATE_ABIA: 'Abia',
	STATE_ABUJA_FEDERAL_CAPITAL_TERRITOR: 'Abuja Federal Capital Territor',
	STATE_ADAMAWA: 'Adamawa',
	STATE_AKWA_IBOM: 'Akwa Ibom',
	STATE_ANAMBRA: 'Anambra',
	STATE_BAUCHI: 'Bauchi',
	STATE_BAYELSA: 'Bayelsa',
	STATE_BENUE: 'Benue',
	STATE_BORNO: 'Borno',
	STATE_CROSS_RIVER: 'Cross River',
	STATE_DELTA: 'Delta',
	STATE_EBONYI: 'Ebonyi',
	STATE_EDO: 'Edo',
	STATE_EKITI: 'Ekiti',
	STATE_ENUGU: 'Enugu',
	STATE_GOMBE: 'Gombe',
	STATE_IMO: 'Imo',
	STATE_JIGAWA: 'Jigawa',
	STATE_KADUNA: 'Kaduna',
	STATE_KANO: 'Kano',
	STATE_KATSINA: 'Katsina',
	STATE_KEBBI: 'Kebbi',
	STATE_KOGI: 'Kogi',
	STATE_KWARA: 'Kwara',
	STATE_LAGOS: 'Lagos',
	STATE_NASSARAWA: 'Nassarawa',
	STATE_NIGER: 'Niger',
	STATE_OGUN: 'Ogun',
	STATE_ONDO: 'Ondo',
	STATE_OSUN: 'Osun',
	STATE_OYO: 'Oyo',
	STATE_RIVERS: 'Rivers',
	STATE_SOKOTO: 'Sokoto',
	STATE_TARABA: 'Taraba',
	STATE_YOBE: 'Yobe',
	STATE_ZAMFARA: 'Zamfara',
	STATE_NIUE: 'Niue',
	STATE_NORFOLK_ISLAND: 'Norfolk Island',
	STATE_NORTHERN_ISLANDS: 'Northern Islands',
	STATE_ROTA: 'Rota',
	STATE_SAIPAN: 'Saipan',
	STATE_TINIAN: 'Tinian',
	STATE_AKERSHUS: 'Akershus',
	STATE_AUST_AGDER: 'Aust Agder',
	STATE_BERGEN: 'Bergen',
	STATE_BUSKERUD: 'Buskerud',
	STATE_FINNMARK: 'Finnmark',
	STATE_HEDMARK: 'Hedmark',
	STATE_HORDALAND: 'Hordaland',
	STATE_MOERE_OG_ROMSDAL: 'Moere og Romsdal',
	STATE_NORD_TRONDELAG: 'Nord Trondelag',
	STATE_NORDLAND: 'Nordland',
	STATE_OESTFOLD: 'Oestfold',
	STATE_OPPLAND: 'Oppland',
	STATE_OSLO: 'Oslo',
	STATE_ROGALAND: 'Rogaland',
	STATE_SOER_TROENDELAG: 'Soer Troendelag',
	STATE_SOGN_OG_FJORDANE: 'Sogn og Fjordane',
	STATE_STAVERN: 'Stavern',
	STATE_SYKKYLVEN: 'Sykkylven',
	STATE_TELEMARK: 'Telemark',
	STATE_TROMS: 'Troms',
	STATE_VEST_AGDER: 'Vest Agder',
	STATE_VESTFOLD: 'Vestfold',
	'STATE_ÃƑÆ’Ã‚ËŒSTFOLD': 'ÃƒÆ’Ã‚Ëœstfold',
	STATE_AL_BURAIMI: 'Al Buraimi',
	STATE_DHUFAR: 'Dhufar',
	STATE_MASQAT: 'Masqat',
	STATE_MUSANDAM: 'Musandam',
	STATE_RUSAYL: 'Rusayl',
	STATE_WADI_KABIR: 'Wadi Kabir',
	STATE_AD_DAKHILIYAH: 'ad-Dakhiliyah',
	STATE_ADH_DHAHIRAH: 'adh-Dhahirah',
	STATE_AL_BATINAH: 'al-Batinah',
	STATE_BALUCHISTAN: 'Baluchistan',
	STATE_FEDERAL_CAPITAL_AREA: 'Federal Capital Area',
	STATE_FEDERALLY_ADMINISTERED_TRIBAL: 'Federally administered Tribal',
	STATE_NORTH_WEST_FRONTIER: 'North-West Frontier',
	STATE_NORTHERN_AREAS: 'Northern Areas',
	STATE_SIND: 'Sind',
	STATE_AIMELIIK: 'Aimeliik',
	STATE_AIRAI: 'Airai',
	STATE_ANGAUR: 'Angaur',
	STATE_HATOBOHEI: 'Hatobohei',
	STATE_KAYANGEL: 'Kayangel',
	STATE_KOROR: 'Koror',
	STATE_MELEKEOK: 'Melekeok',
	STATE_NGARAARD: 'Ngaraard',
	STATE_NGARDMAU: 'Ngardmau',
	STATE_NGAREMLENGUI: 'Ngaremlengui',
	STATE_NGATPANG: 'Ngatpang',
	STATE_NGCHESAR: 'Ngchesar',
	STATE_NGERCHELONG: 'Ngerchelong',
	STATE_NGIWAL: 'Ngiwal',
	STATE_PELELIU: 'Peleliu',
	STATE_SONSOROL: 'Sonsorol',
	STATE_ARIHA: 'Ariha',
	STATE_BAYT_LAHM: 'Bayt Lahm',
	STATE_DAYR_AL_BALAH: 'Dayr-al-Balah',
	STATE_GHAZZAH: 'Ghazzah',
	STATE_GHAZZAH_ASH_SHAMALIYAH: 'Ghazzah ash-Shamaliyah',
	STATE_JANIN: 'Janin',
	STATE_KHAN_YUNIS: 'Khan Yunis',
	STATE_NABULUS: 'Nabulus',
	STATE_QALQILYAH: 'Qalqilyah',
	STATE_RAFAH: 'Rafah',
	STATE_RAM_ALLAH_WAL_BIRAH: 'Ram Allah wal-Birah',
	STATE_SALFIT: 'Salfit',
	STATE_TUBAS: 'Tubas',
	STATE_TULKARM: 'Tulkarm',
	STATE_AL_KHALIL: 'al-Khalil',
	STATE_AL_QUDS: 'al-Quds',
	STATE_BOCAS_DEL_TORO: 'Bocas del Toro',
	STATE_CHIRIQUI: 'Chiriqui',
	STATE_COCLE: 'Cocle',
	STATE_DARIEN: 'Darien',
	STATE_EMBERA: 'Embera',
	STATE_HERRERA: 'Herrera',
	STATE_KUNA_YALA: 'Kuna Yala',
	STATE_LOS_SANTOS: 'Los Santos',
	STATE_NGOBE_BUGLE: 'Ngobe Bugle',
	STATE_PANAMA: 'Panama',
	STATE_VERAGUAS: 'Veraguas',
	STATE_EAST_NEW_BRITAIN: 'East New Britain',
	STATE_EAST_SEPIK: 'East Sepik',
	STATE_EASTERN_HIGHLANDS: 'Eastern Highlands',
	STATE_ENGA: 'Enga',
	STATE_FLY_RIVER: 'Fly River',
	STATE_GULF: 'Gulf',
	STATE_MADANG: 'Madang',
	STATE_MANUS: 'Manus',
	STATE_MILNE_BAY: 'Milne Bay',
	STATE_MOROBE: 'Morobe',
	STATE_NATIONAL_CAPITAL_DISTRICT: 'National Capital District',
	STATE_NEW_IRELAND: 'New Ireland',
	STATE_NORTH_SOLOMONS: 'North Solomons',
	STATE_ORO: 'Oro',
	STATE_SANDAUN: 'Sandaun',
	STATE_SIMBU: 'Simbu',
	STATE_SOUTHERN_HIGHLANDS: 'Southern Highlands',
	STATE_WEST_NEW_BRITAIN: 'West New Britain',
	STATE_WESTERN_HIGHLANDS: 'Western Highlands',
	STATE_ALTO_PARAGUAY: 'Alto Paraguay',
	STATE_ALTO_PARANA: 'Alto Parana',
	STATE_AMAMBAY: 'Amambay',
	STATE_ASUNCION: 'Asuncion',
	STATE_BOQUERON: 'Boqueron',
	STATE_CAAGUAZU: 'Caaguazu',
	STATE_CAAZAPA: 'Caazapa',
	STATE_CANENDIYU: 'Canendiyu',
	STATE_CONCEPCION: 'Concepcion',
	STATE_CORDILLERA: 'Cordillera',
	STATE_GUAIRA: 'Guaira',
	STATE_ITAPUA: 'Itapua',
	STATE_NEEMBUCU: 'Neembucu',
	STATE_PARAGUARI: 'Paraguari',
	STATE_PRESIDENTE_HAYES: 'Presidente Hayes',
	STATE_SAN_PEDRO: 'San Pedro',
	STATE_ANCASH: 'Ancash',
	STATE_APURIMAC: 'Apurimac',
	STATE_AREQUIPA: 'Arequipa',
	STATE_AYACUCHO: 'Ayacucho',
	STATE_CAJAMARCA: 'Cajamarca',
	STATE_CUSCO: 'Cusco',
	STATE_HUANCAVELICA: 'Huancavelica',
	STATE_HUANUCO: 'Huanuco',
	STATE_ICA: 'Ica',
	STATE_JUNIN: 'Junin',
	STATE_LAMBAYEQUE: 'Lambayeque',
	STATE_LIMA_Y_CALLAO: 'Lima y Callao',
	STATE_LORETO: 'Loreto',
	STATE_MADRE_DE_DIOS: 'Madre de Dios',
	STATE_MOQUEGUA: 'Moquegua',
	STATE_PASCO: 'Pasco',
	STATE_PIURA: 'Piura',
	STATE_PUNO: 'Puno',
	STATE_SAN_MARTIN: 'San Martin',
	STATE_TACNA: 'Tacna',
	STATE_TUMBES: 'Tumbes',
	STATE_UCAYALI: 'Ucayali',
	STATE_BATANGAS: 'Batangas',
	STATE_BICOL: 'Bicol',
	STATE_BULACAN: 'Bulacan',
	STATE_CAGAYAN: 'Cagayan',
	STATE_CARAGA: 'Caraga',
	STATE_CENTRAL_LUZON: 'Central Luzon',
	STATE_CENTRAL_MINDANAO: 'Central Mindanao',
	STATE_CENTRAL_VISAYAS: 'Central Visayas',
	STATE_DAVAO: 'Davao',
	STATE_EASTERN_VISAYAS: 'Eastern Visayas',
	STATE_GREATER_METROPOLITAN_AREA: 'Greater Metropolitan Area',
	STATE_ILOCOS: 'Ilocos',
	STATE_LAGUNA: 'Laguna',
	STATE_LUZON: 'Luzon',
	STATE_MACTAN: 'Mactan',
	STATE_METROPOLITAN_MANILA_AREA: 'Metropolitan Manila Area',
	STATE_MUSLIM_MINDANAO: 'Muslim Mindanao',
	STATE_NORTHERN_MINDANAO: 'Northern Mindanao',
	STATE_SOUTHERN_MINDANAO: 'Southern Mindanao',
	STATE_SOUTHERN_TAGALOG: 'Southern Tagalog',
	STATE_WESTERN_MINDANAO: 'Western Mindanao',
	STATE_WESTERN_VISAYAS: 'Western Visayas',
	STATE_PITCAIRN_ISLAND: 'Pitcairn Island',
	STATE_BIALE_BLOTA: 'Biale Blota',
	STATE_DOBROSZYCE: 'Dobroszyce',
	STATE_DOLNOSLASKIE: 'Dolnoslaskie',
	STATE_DZIEKANOW_LESNY: 'Dziekanow Lesny',
	STATE_HOPOWO: 'Hopowo',
	STATE_KARTUZY: 'Kartuzy',
	STATE_KOSCIAN: 'Koscian',
	STATE_KRAKOW: 'Krakow',
	STATE_KUJAWSKO_POMORSKIE: 'Kujawsko-Pomorskie',
	STATE_LODZKIE: 'Lodzkie',
	STATE_LUBELSKIE: 'Lubelskie',
	STATE_LUBUSKIE: 'Lubuskie',
	STATE_MALOMICE: 'Malomice',
	STATE_MALOPOLSKIE: 'Malopolskie',
	STATE_MAZOWIECKIE: 'Mazowieckie',
	STATE_MIRKOW: 'Mirkow',
	STATE_OPOLSKIE: 'Opolskie',
	STATE_OSTROWIEC: 'Ostrowiec',
	STATE_PODKARPACKIE: 'Podkarpackie',
	STATE_PODLASKIE: 'Podlaskie',
	STATE_POLSKA: 'Polska',
	STATE_POMORSKIE: 'Pomorskie',
	STATE_POZNAN: 'Poznan',
	STATE_PRUSZKOW: 'Pruszkow',
	STATE_RYMANOWSKA: 'Rymanowska',
	STATE_RZESZOW: 'Rzeszow',
	STATE_SLASKIE: 'Slaskie',
	STATE_STARE_POLE: 'Stare Pole',
	STATE_SWIETOKRZYSKIE: 'Swietokrzyskie',
	STATE_WARMINSKO_MAZURSKIE: 'Warminsko-Mazurskie',
	STATE_WARSAW: 'Warschau',
	STATE_WEJHEROWO: 'Wejherowo',
	STATE_WIELKOPOLSKIE: 'Wielkopolskie',
	STATE_WROCLAW: 'Wroclaw',
	STATE_ZACHODNIO_POMORSKIE: 'Zachodnio-Pomorskie',
	STATE_ZUKOWO: 'Zukowo',
	STATE_ABRANTES: 'Abrantes',
	STATE_ACORES: 'Acores',
	STATE_ALENTEJO: 'Alentejo',
	STATE_ALGARVE: 'Algarve',
	STATE_BRAGA: 'Braga',
	STATE_CENTRO: 'Centro',
	STATE_DISTRITO_DE_LEIRIA: 'Distrito de Leiria',
	STATE_DISTRITO_DE_VIANA_DO_CASTELO: 'Distrito de Viana do Castelo',
	STATE_DISTRITO_DE_VILA_REAL: 'Distrito de Vila Real',
	STATE_DISTRITO_DO_PORTO: 'Distrito do Porto',
	STATE_LISBOA_E_VALE_DO_TEJO: 'Lisboa e Vale do Tejo',
	STATE_MADEIRA: 'Madeira',
	STATE_NORTE: 'Norte',
	STATE_PAIVAS: 'Paivas',
	STATE_ARECIBO: 'Arecibo',
	STATE_BAYAMON: 'Bayamon',
	STATE_CAROLINA: 'Carolina',
	STATE_FLORIDA: 'Florida',
	STATE_GUAYAMA: 'Guayama',
	STATE_HUMACAO: 'Humacao',
	STATE_MAYAGUEZ_AGUADILLA: 'Mayaguez-Aguadilla',
	STATE_PONCE: 'Ponce',
	STATE_SALINAS: 'Salinas',
	STATE_DOHA: 'Doha',
	STATE_JARIAN_AL_BATNAH: 'Jarian-al-Batnah',
	STATE_UMM_SALAL: 'Umm Salal',
	STATE_AD_DAWHAH: 'ad-Dawhah',
	STATE_AL_GHUWAYRIYAH: 'al-Ghuwayriyah',
	STATE_AL_JUMAYLIYAH: 'al-Jumayliyah',
	STATE_AL_KHAWR: 'al-Khawr',
	STATE_AL_WAKRAH: 'al-Wakrah',
	STATE_AR_RAYYAN: 'ar-Rayyan',
	STATE_SAINT_BENOIT: 'Saint-Benoit',
	STATE_SAINT_DENIS: 'Saint-Denis',
	STATE_ALBA: 'Alba',
	STATE_ARAD: 'Arad',
	STATE_ARGES: 'Arges',
	STATE_BACAU: 'Bacau',
	STATE_BIHOR: 'Bihor',
	STATE_BISTRITA_NASAUD: 'Bistrita-Nasaud',
	STATE_BOTOSANI: 'Botosani',
	STATE_BRAILA: 'Braila',
	STATE_BRASOV: 'Brasov',
	STATE_BUCURESTI: 'Bucuresti',
	STATE_BUZAU: 'Buzau',
	STATE_CALARASI: 'Calarasi',
	STATE_CARAS_SEVERIN: 'Caras-Severin',
	STATE_CLUJ: 'Cluj',
	STATE_CONSTANTA: 'Constanta',
	STATE_COVASNA: 'Covasna',
	STATE_DAMBOVITA: 'Dambovita',
	STATE_DOLJ: 'Dolj',
	STATE_GALATI: 'Galati',
	STATE_GIURGIU: 'Giurgiu',
	STATE_GORJ: 'Gorj',
	STATE_HARGHITA: 'Harghita',
	STATE_HUNEDOARA: 'Hunedoara',
	STATE_IALOMITA: 'Ialomita',
	STATE_IASI: 'Iasi',
	STATE_ILFOV: 'Ilfov',
	STATE_MARAMURES: 'Maramures',
	STATE_MEHEDINTI: 'Mehedinti',
	STATE_MURES: 'Mures',
	STATE_NEAMT: 'Neamt',
	STATE_OLT: 'Olt',
	STATE_PRAHOVA: 'Prahova',
	STATE_SALAJ: 'Salaj',
	STATE_SATU_MARE: 'Satu Mare',
	STATE_SIBIU: 'Sibiu',
	STATE_SONDELOR: 'Sondelor',
	STATE_SUCEAVA: 'Suceava',
	STATE_TELEORMAN: 'Teleorman',
	STATE_TIMIS: 'Timis',
	STATE_TULCEA: 'Tulcea',
	STATE_VALCEA: 'Valcea',
	STATE_VASLUI: 'Vaslui',
	STATE_VRANCEA: 'Vrancea',
	STATE_ADYGEJA: 'Adygeja',
	STATE_AGA: 'Aga',
	STATE_ALANIJA: 'Alanija',
	STATE_ALTAJ: 'Altaj',
	STATE_AMUR: 'Amur',
	STATE_ARHANGELSK: 'Arhangelsk',
	STATE_ASTRAHAN: 'Astrahan',
	STATE_BASHKORTOSTAN: 'Bashkortostan',
	STATE_BELGOROD: 'Belgorod',
	STATE_BRJANSK: 'Brjansk',
	STATE_BURJATIJA: 'Burjatija',
	STATE_CHECHENIJA: 'Chechenija',
	STATE_CHELJABINSK: 'Cheljabinsk',
	STATE_CHITA: 'Chita',
	STATE_CHUKOTKA: 'Chukotka',
	STATE_CHUVASHIJA: 'Chuvashija',
	STATE_DAGESTAN: 'Dagestan',
	STATE_EVENKIJA: 'Evenkija',
	STATE_GORNO_ALTAJ: 'Gorno-Altaj',
	STATE_HABAROVSK: 'Habarovsk',
	STATE_HAKASIJA: 'Hakasija',
	STATE_HANTY_MANSIJA: 'Hanty-Mansija',
	STATE_INGUSETIJA: 'Ingusetija',
	STATE_IRKUTSK: 'Irkutsk',
	STATE_IVANOVO: 'Ivanovo',
	STATE_JAMALO_NENETS: 'Jamalo-Nenets',
	STATE_JAROSLAVL: 'Jaroslavl',
	STATE_JEVREJ: 'Jevrej',
	STATE_KABARDINO_BALKARIJA: 'Kabardino-Balkarija',
	STATE_KALININGRAD: 'Kaliningrad',
	STATE_KALMYKIJA: 'Kalmykija',
	STATE_KALUGA: 'Kaluga',
	STATE_KAMCHATKA: 'Kamchatka',
	STATE_KARACHAJ_CHERKESSIJA: 'Karachaj-Cherkessija',
	STATE_KARELIJA: 'Karelija',
	STATE_KEMEROVO: 'Kemerovo',
	STATE_KHABAROVSKIY_KRAY: 'Khabarovskiy Kray',
	STATE_KIROV: 'Kirov',
	STATE_KOMI: 'Komi',
	STATE_KOMI_PERMJAKIJA: 'Komi-Permjakija',
	STATE_KORJAKIJA: 'Korjakija',
	STATE_KOSTROMA: 'Kostroma',
	STATE_KRASNODAR: 'Krasnodar',
	STATE_KRASNOJARSK: 'Krasnojarsk',
	STATE_KRASNOYARSKIY_KRAY: 'Krasnoyarskiy Kray',
	STATE_KURGAN: 'Kurgan',
	STATE_KURSK: 'Kursk',
	STATE_LENINGRAD: 'Leningrad',
	STATE_LIPECK: 'Lipeck',
	STATE_MAGADAN: 'Magadan',
	STATE_MARIJ_EL: 'Marij El',
	STATE_MORDOVIJA: 'Mordovija',
	STATE_MOSCOW: 'Moscow',
	STATE_MOSKOVSKAJA_OBLAST: 'Moskovskaja Oblast',
	STATE_MOSKOVSKAYA_OBLAST: 'Moskovskaya Oblast',
	STATE_MOSKVA: 'Moskva',
	STATE_MURMANSK: 'Murmansk',
	STATE_NENETS: 'Nenets',
	STATE_NIZHNIJ_NOVGOROD: 'Nizhnij Novgorod',
	STATE_NOVGOROD: 'Novgorod',
	STATE_NOVOKUSNEZK: 'Novokusnezk',
	STATE_NOVOSIBIRSK: 'Novosibirsk',
	STATE_OMSK: 'Omsk',
	STATE_ORENBURG: 'Orenburg',
	STATE_ORJOL: 'Orjol',
	STATE_PENZA: 'Penza',
	STATE_PERM: 'Perm',
	STATE_PRIMORJE: 'Primorje',
	STATE_PSKOV: 'Pskov',
	STATE_PSKOVSKAYA_OBLAST: 'Pskovskaya Oblast',
	STATE_RJAZAN: 'Rjazan',
	STATE_ROSTOV: 'Rostov',
	STATE_SAHA: 'Saha',
	STATE_SAHALIN: 'Sahalin',
	STATE_SAMARA: 'Samara',
	STATE_SAMARSKAYA: 'Samarskaya',
	STATE_SANKT_PETERBURG: 'Sankt Petersburg',
	STATE_SARATOV: 'Saratov',
	STATE_SMOLENSK: 'Smolensk',
	STATE_STAVROPOL: 'Stavropol',
	STATE_SVERDLOVSK: 'Sverdlovsk',
	STATE_TAJMYRIJA: 'Tajmyrija',
	STATE_TAMBOV: 'Tambov',
	STATE_TATARSTAN: 'Tatarstan',
	STATE_TJUMEN: 'Tjumen',
	STATE_TOMSK: 'Tomsk',
	STATE_TULA: 'Tula',
	STATE_TVER: 'Tver',
	STATE_TYVA: 'Tyva',
	STATE_UDMURTIJA: 'Udmurtija',
	STATE_ULJANOVSK: 'Uljanovsk',
	STATE_ULYANOVSKAYA_OBLAST: 'Ulyanovskaya Oblast',
	STATE_UST_ORDA: 'Ust-Orda',
	STATE_VLADIMIR: 'Vladimir',
	STATE_VOLGOGRAD: 'Volgograd',
	STATE_VOLOGDA: 'Vologda',
	STATE_VORONEZH: 'Voronezh',
	STATE_BUTARE: 'Butare',
	STATE_BYUMBA: 'Byumba',
	STATE_CYANGUGU: 'Cyangugu',
	STATE_GIKONGORO: 'Gikongoro',
	STATE_GISENYI: 'Gisenyi',
	STATE_GITARAMA: 'Gitarama',
	STATE_KIBUNGO: 'Kibungo',
	STATE_KIBUYE: 'Kibuye',
	STATE_KIGALI_NGALI: 'Kigali-ngali',
	STATE_RUHENGERI: 'Ruhengeri',
	STATE_ASCENSION: 'Ascension',
	STATE_GOUGH_ISLAND: 'Gough Island',
	STATE_SAINT_HELENA: 'Saint Helena',
	STATE_TRISTAN_DA_CUNHA: 'Tristan da Cunha',
	STATE_CHRIST_CHURCH_NICHOLA_TOWN: 'Christ Church Nichola Town',
	STATE_SAINT_ANNE_SANDY_POINT: 'Saint Anne Sandy Point',
	STATE_SAINT_GEORGE_BASSETERRE: 'Saint George Basseterre',
	STATE_SAINT_GEORGE_GINGERLAND: 'Saint George Gingerland',
	STATE_SAINT_JAMES_WINDWARD: 'Saint James Windward',
	STATE_SAINT_JOHN_CAPESTERRE: 'Saint John Capesterre',
	STATE_SAINT_JOHN_FIGTREE: 'Saint John Figtree',
	STATE_SAINT_MARY_CAYON: 'Saint Mary Cayon',
	STATE_SAINT_PAUL_CAPESTERRE: 'Saint Paul Capesterre',
	STATE_SAINT_PAUL_CHARLESTOWN: 'Saint Paul Charlestown',
	STATE_SAINT_PETER_BASSETERRE: 'Saint Peter Basseterre',
	STATE_SAINT_THOMAS_LOWLAND: 'Saint Thomas Lowland',
	STATE_SAINT_THOMAS_MIDDLE_ISLAND: 'Saint Thomas Middle Island',
	STATE_TRINITY_PALMETTO_POINT: 'Trinity Palmetto Point',
	STATE_ANSE_LA_RAYE: 'Anse-la-Raye',
	STATE_CANARIES: 'Canaries',
	STATE_CASTRIES: 'Castries',
	STATE_CHOISEUL: 'Choiseul',
	STATE_DENNERY: 'Dennery',
	STATE_GROS_INLET: 'Gros Inlet',
	STATE_LABORIE: 'Laborie',
	STATE_MICOUD: 'Micoud',
	STATE_SOUFRIERE: 'Soufriere',
	STATE_VIEUX_FORT: 'Vieux Fort',
	STATE_MIQUELON_LANGLADE: 'Miquelon-Langlade',
	STATE_CHARLOTTE: 'Charlotte',
	STATE_GRENADINES: 'Grenadines',
	STATE_A__ANA: 'A’ana',
	STATE_AIGA_I_LE_TAI: 'Aiga-i-le-Tai',
	STATE_ATUA: 'Atua',
	STATE_FA__ASALELEAGA: 'Fa’asaleleaga',
	STATE_GAGA__EMAUGA: 'Gaga’emauga',
	STATE_GAGAIFOMAUGA: 'Gagaifomauga',
	STATE_PALAULI: 'Palauli',
	STATE_SATUPA__ITEA: 'Satupa’itea',
	STATE_TUAMASAGA: 'Tuamasaga',
	STATE_VA__A_O_FONOTI: 'Va’a-o-Fonoti',
	STATE_VAISIGANO: 'Vaisigano',
	STATE_ACQUAVIVA: 'Acquaviva',
	STATE_BORGO_MAGGIORE: 'Borgo Maggiore',
	STATE_CHIESANUOVA: 'Chiesanuova',
	STATE_DOMAGNANO: 'Domagnano',
	STATE_FAETANO: 'Faetano',
	STATE_FIORENTINO: 'Fiorentino',
	STATE_MONTEGIARDINO: 'Montegiardino',
	STATE_SAN_MARINO: 'San Marino',
	STATE_SERRAVALLE: 'Serravalle',
	STATE_AGUA_GRANDE: 'Agua Grande',
	STATE_CANTAGALO: 'Cantagalo',
	STATE_LEMBA: 'Lemba',
	STATE_LOBATA: 'Lobata',
	STATE_ME_ZOCHI: 'Me-Zochi',
	STATE_PAGUE: 'Pague',
	STATE_AL_KHOBAR: 'Al Khobar',
	STATE_ASEER: 'Aseer',
	STATE_ASIR: 'Asir',
	STATE_CENTRAL_PROVINCE: 'Central Province',
	STATE_EASTERN_PROVINCE: 'Eastern Province',
	STATE_HA__IL: 'Ha’il',
	STATE_JAWF: 'Jawf',
	STATE_JIZAN: 'Jizan',
	STATE_MAKKAH: 'Makkah',
	STATE_NAJRAN: 'Najran',
	STATE_QASIM: 'Qasim',
	STATE_TABUK: 'Tabuk',
	STATE_WESTERN_PROVINCE: 'Western Province',
	STATE_AL_BAHAH: 'al-Bahah',
	STATE_AL_HUDUD_ASH_SHAMALIYAH: 'al-Hudud-ash-Shamaliyah',
	STATE_AL_MADINAH: 'al-Madinah',
	STATE_AR_RIYAD: 'ar-Riyad',
	STATE_DAKAR: 'Dakar',
	STATE_DIOURBEL: 'Diourbel',
	STATE_FATICK: 'Fatick',
	STATE_KAOLACK: 'Kaolack',
	STATE_KOLDA: 'Kolda',
	STATE_LOUGA: 'Louga',
	STATE_SAINT_LOUIS: 'Saint-Louis',
	STATE_TAMBACOUNDA: 'Tambacounda',
	STATE_THIES: 'Thies',
	STATE_ZIGUINCHOR: 'Ziguinchor',
	STATE_CENTRAL_SERBIA: 'Central Serbia',
	STATE_KOSOVO_AND_METOHIJA: 'Kosovo and Metohija',
	STATE_VOJVODINA: 'Vojvodina',
	STATE_ANSE_BOILEAU: 'Anse Boileau',
	STATE_ANSE_ROYALE: 'Anse Royale',
	STATE_CASCADE: 'Cascade',
	STATE_TAKAMAKA: 'Takamaka',
	STATE_SINGAPORE: 'Singapore',
	STATE_BANSKOBYSTRICKY: 'Banskobystricky',
	STATE_BRATISLAVSKY: 'Bratislavsky',
	STATE_KOSICKY: 'Kosicky',
	STATE_NITRIANSKY: 'Nitriansky',
	STATE_PRESOVSKY: 'Presovsky',
	STATE_TRENCIANSKY: 'Trenciansky',
	STATE_TRNAVSKY: 'Trnavsky',
	STATE_ZILINSKY: 'Zilinsky',
	STATE_BENEDIKT: 'Benedikt',
	STATE_GORENJSKA: 'Gorenjska',
	STATE_GORISHKA: 'Gorishka',
	STATE_JUGOVZHODNA_SLOVENIJA: 'Jugovzhodna Slovenija',
	STATE_KOROSHKA: 'Koroshka',
	STATE_NOTRANJSKO_KRASHKA: 'Notranjsko-krashka',
	STATE_OBALNO_KRASHKA: 'Obalno-krashka',
	STATE_OBCINA_DOMZALE: 'Obcina Domzale',
	STATE_OBCINA_VITANJE: 'Obcina Vitanje',
	STATE_OSREDNJESLOVENSKA: 'Osrednjeslovenska',
	STATE_PODRAVSKA: 'Podravska',
	STATE_POMURSKA: 'Pomurska',
	STATE_SAVINJSKA: 'Savinjska',
	STATE_SLOVENIAN_LITTORAL: 'Slovenian Littoral',
	STATE_SPODNJEPOSAVSKA: 'Spodnjeposavska',
	STATE_ZASAVSKA: 'Zasavska',
	STATE_GUADALCANAL: 'Guadalcanal',
	STATE_ISABEL: 'Isabel',
	STATE_MAKIRA_AND_ULAWA: 'Makira and Ulawa',
	STATE_MALAITA: 'Malaita',
	STATE_RENNELL_AND_BELLONA: 'Rennell and Bellona',
	STATE_TEMOTU: 'Temotu',
	STATE_AWDAL: 'Awdal',
	STATE_BAKOL: 'Bakol',
	STATE_BANADIR: 'Banadir',
	STATE_BAY: 'Bay',
	STATE_GALGUDUG: 'Galgudug',
	STATE_GEDO: 'Gedo',
	STATE_HIRAN: 'Hiran',
	STATE_JUBBADA_HOSE: 'Jubbada Hose',
	STATE_JUBBADHA_DEXE: 'Jubbadha Dexe',
	STATE_MUDUG: 'Mudug',
	STATE_NUGAL: 'Nugal',
	STATE_SANAG: 'Sanag',
	STATE_SHABELLAHA_DHEXE: 'Shabellaha Dhexe',
	STATE_SHABELLAHA_HOSE: 'Shabellaha Hose',
	STATE_TOGDHER: 'Togdher',
	STATE_WOQOYI_GALBED: 'Woqoyi Galbed',
	STATE_EASTERN_CAPE: 'Eastern Cape',
	STATE_FREE_STATE: 'Free State',
	STATE_GAUTENG: 'Gauteng',
	STATE_KEMPTON_PARK: 'Kempton Park',
	STATE_KRAMERVILLE: 'Kramerville',
	STATE_KWAZULU_NATAL: 'KwaZulu Natal',
	STATE_LIMPOPO: 'Limpopo',
	STATE_MPUMALANGA: 'Mpumalanga',
	STATE_NORTH_WEST: 'North West',
	STATE_NORTHERN_CAPE: 'Northern Cape',
	STATE_PAROW: 'Parow',
	STATE_TABLE_VIEW: 'Table View',
	STATE_UMTENTWENI: 'Umtentweni',
	STATE_WESTERN_CAPE: 'Western Cape',
	STATE_CENTRAL_EQUATORIA: 'Central Equatoria',
	STATE_A_CORUNA: 'A Coruna',
	STATE_ALACANT: 'Alacant',
	STATE_ALAVA: 'Alava',
	STATE_ALBACETE: 'Albacete',
	STATE_ALMERIA: 'Almeria',
	STATE_ANDALUCIA: 'Andalucia',
	STATE_ASTURIAS: 'Asturias',
	STATE_AVILA: 'Avila',
	STATE_BADAJOZ: 'Badajoz',
	STATE_BALEARS: 'Balears',
	STATE_BARCELONA: 'Barcelona',
	STATE_BERTAMIRANS: 'Bertamirans',
	STATE_BISCAY: 'Biscay',
	STATE_BURGOS: 'Burgos',
	STATE_CACERES: 'Caceres',
	STATE_CADIZ: 'Cadiz',
	STATE_CANTABRIA: 'Cantabria',
	STATE_CASTELLO: 'Castello',
	STATE_CATALUNYA: 'Catalunya',
	STATE_CEUTA: 'Ceuta',
	STATE_CIUDAD_REAL: 'Ciudad Real',
	STATE_COMUNIDAD_AUTONOMA_DE_CANARIAS: 'Comunidad Autonoma de Canarias',
	STATE_COMUNIDAD_AUTONOMA_DE_CATALUNA: 'Comunidad Autonoma de Cataluna',
	STATE_COMUNIDAD_AUTONOMA_DE_GALICIA: 'Comunidad Autonoma de Galicia',
	STATE_COMUNIDAD_AUTONOMA_DE_LAS_ISLA: 'Comunidad Autonoma de las Isla',
	STATE_COMUNIDAD_AUTONOMA_DEL_PRINCIP: 'Comunidad Autonoma del Princip',
	STATE_COMUNIDAD_VALENCIANA: 'Comunidad Valenciana',
	STATE_CUENCA: 'Cuenca',
	STATE_GIPUZKOA: 'Gipuzkoa',
	STATE_GIRONA: 'Girona',
	STATE_GUADALAJARA: 'Guadalajara',
	STATE_GUIPUZCOA: 'Guipuzcoa',
	STATE_HUELVA: 'Huelva',
	STATE_HUESCA: 'Huesca',
	STATE_JAEN: 'Jaen',
	STATE_LAS_PALMAS: 'Las Palmas',
	STATE_LERIDA: 'Lerida',
	STATE_LLEIDA: 'Lleida',
	STATE_LUGO: 'Lugo',
	STATE_MADRID: 'Madrid',
	STATE_MALAGA: 'Malaga',
	STATE_MELILLA: 'Melilla',
	STATE_MURCIA: 'Murcia',
	STATE_NAVARRA: 'Navarra',
	STATE_OURENSE: 'Ourense',
	STATE_PAIS_VASCO: 'Pais Vasco',
	STATE_PALENCIA: 'Palencia',
	STATE_PONTEVEDRA: 'Pontevedra',
	STATE_SALAMANCA: 'Salamanca',
	STATE_SANTA_CRUZ_DE_TENERIFE: 'Santa Cruz de Tenerife',
	STATE_SEGOVIA: 'Segovia',
	STATE_SEVILLA: 'Sevilla',
	STATE_SORIA: 'Soria',
	STATE_TARRAGONA: 'Tarragona',
	STATE_TENERIFE: 'Tenerife',
	STATE_TERUEL: 'Teruel',
	STATE_VALENCIA: 'Valencia',
	STATE_VALLADOLID: 'Valladolid',
	STATE_VIZCAYA: 'Vizcaya',
	STATE_ZAMORA: 'Zamora',
	STATE_ZARAGOZA: 'Zaragoza',
	STATE_AMPARAI: 'Amparai',
	STATE_ANURADHAPURAYA: 'Anuradhapuraya',
	STATE_BADULLA: 'Badulla',
	STATE_BORALESGAMUWA: 'Boralesgamuwa',
	STATE_COLOMBO: 'Colombo',
	STATE_GALLA: 'Galla',
	STATE_GAMPAHA: 'Gampaha',
	STATE_HAMBANTOTA: 'Hambantota',
	STATE_KALATURA: 'Kalatura',
	STATE_KEGALLA: 'Kegalla',
	STATE_KILINOCHCHI: 'Kilinochchi',
	STATE_KURUNEGALA: 'Kurunegala',
	STATE_MADAKALPUWA: 'Madakalpuwa',
	STATE_MAHA_NUWARA: 'Maha Nuwara',
	STATE_MALWANA: 'Malwana',
	STATE_MANNARAMA: 'Mannarama',
	STATE_MATALE: 'Matale',
	STATE_MATARA: 'Matara',
	STATE_MONARAGALA: 'Monaragala',
	STATE_MULLAITIVU: 'Mullaitivu',
	STATE_NORTH_EASTERN_PROVINCE: 'North Eastern Province',
	STATE_NORTH_WESTERN_PROVINCE: 'North Western Province',
	STATE_NUWARA_ELIYA: 'Nuwara Eliya',
	STATE_POLONNARUWA: 'Polonnaruwa',
	STATE_PUTTALAMA: 'Puttalama',
	STATE_RATNAPURAYA: 'Ratnapuraya',
	STATE_SOUTHERN_PROVINCE: 'Southern Province',
	STATE_TIRIKUNAMALAYA: 'Tirikunamalaya',
	STATE_TUSCANY: 'Tuscany',
	STATE_VAVUNIYAWA: 'Vavuniyawa',
	STATE_YAPANAYA: 'Yapanaya',
	STATE_KADAWATHA: 'kadawatha',
	STATE_A__ALI_AN_NIL: 'A’ali-an-Nil',
	STATE_BAHR_AL_JABAL: 'Bahr-al-Jabal',
	STATE_GHARB_BAHR_AL_GHAZAL: 'Gharb Bahr-al-Ghazal',
	STATE_GHARB_DARFUR: 'Gharb Darfur',
	STATE_GHARB_KURDUFAN: 'Gharb Kurdufan',
	STATE_GHARB_AL_ISTIWA__IYAH: 'Gharb-al-Istiwa’iyah',
	STATE_JANUB_DARFUR: 'Janub Darfur',
	STATE_JANUB_KURDUFAN: 'Janub Kurdufan',
	STATE_JUNQALI: 'Junqali',
	STATE_KASSALA: 'Kassala',
	STATE_NAHR_AN_NIL: 'Nahr-an-Nil',
	STATE_SHAMAL_BAHR_AL_GHAZAL: 'Shamal Bahr-al-Ghazal',
	STATE_SHAMAL_DARFUR: 'Shamal Darfur',
	STATE_SHAMAL_KURDUFAN: 'Shamal Kurdufan',
	STATE_SHARQ_AL_ISTIWA__IYAH: 'Sharq-al-Istiwa’iyah',
	STATE_SINNAR: 'Sinnar',
	STATE_WARAB: 'Warab',
  STATE_WILAYAT_AL_KHARTUM: 'Wilayat al Khartum',
	STATE_AL_BUHAYRAT: 'al-Buhayrat',
	STATE_AL_JAZIRAH: 'al-Jazirah',
	STATE_AL_KHARTUM: 'al-Khartum',
	STATE_AL_QADARIF: 'al-Qadarif',
	STATE_AL_WAHDAH: 'al-Wahdah',
	STATE_AN_NIL_AL_ABYAD: 'an-Nil-al-Abyad',
	STATE_AN_NIL_AL_AZRAQ: 'an-Nil-al-Azraq',
	STATE_ASH_SHAMALIYAH: 'ash-Shamaliyah',
	STATE_BROKOPONDO: 'Brokopondo',
	STATE_COMMEWIJNE: 'Commewijne',
	STATE_CORONIE: 'Coronie',
	STATE_MAROWIJNE: 'Marowijne',
	STATE_NICKERIE: 'Nickerie',
	STATE_PARAMARIBO: 'Paramaribo',
	STATE_SARAMACCA: 'Saramacca',
	STATE_WANICA: 'Wanica',
	STATE_SVALBARD: 'Svalbard',
	STATE_HHOHHO: 'Hhohho',
	STATE_LUBOMBO: 'Lubombo',
	STATE_MANZINI: 'Manzini',
	STATE_SHISELWENI: 'Shiselweni',
	STATE_ALVSBORGS_LAN: 'Alvsborgs Lan',
	STATE_ANGERMANLAND: 'Angermanland',
	STATE_BLEKINGE: 'Blekinge',
	STATE_BOHUSLAN: 'Bohuslan',
	STATE_DALARNA: 'Dalarna',
	STATE_GAVLEBORG: 'Gavleborg',
	STATE_GOTLAND: 'Gotland',
	STATE_HALLAND: 'Halland',
	STATE_JAMTLAND: 'Jamtland',
	STATE_JONKOPING: 'Jonkoping',
	STATE_KALMAR: 'Kalmar',
	STATE_KRISTIANSTADS: 'Kristianstads',
	STATE_KRONOBERG: 'Kronoberg',
	STATE_NORRBOTTEN: 'Norrbotten',
	STATE_OREBRO: 'Orebro',
	STATE_OSTERGOTLAND: 'Ostergotland',
	STATE_SALTSJO_BOO: 'Saltsjo-Boo',
	STATE_SKANE: 'Skane',
	STATE_SMALAND: 'Smaland',
	STATE_SODERMANLAND: 'Sodermanland',
	STATE_STOCKHOLM: 'Stockholm',
	STATE_UPPSALA: 'Uppsala',
	STATE_VARMLAND: 'Varmland',
	STATE_VASTERBOTTEN: 'Vasterbotten',
	STATE_VASTERGOTLAND: 'Vastergotland',
	STATE_VASTERNORRLAND: 'Vasternorrland',
	STATE_VASTMANLAND: 'Vastmanland',
	STATE_VASTRA_GOTALAND: 'Vastra Gotaland',
	STATE_AARGAU: 'Aargau',
	STATE_APPENZELL_INNER_RHODEN: 'Appenzell Innerrhoden',
	STATE_APPENZELL_AUSSER_RHODEN: 'Appenzell-Ausserrhoden',
	STATE_BASEL_LANDSCHAFT: 'Basel-Landschaft',
	STATE_BASEL_STADT: 'Basel-Stadt',
	STATE_BERN: 'Bern',
	STATE_CANTON_TICINO: 'Canton Ticino',
	STATE_FRIBOURG: 'Fribourg',
	STATE_GENEVE: 'Geneve',
	STATE_GLARUS: 'Glarus',
	STATE_GRAUBUNDEN: 'Graubünden',
	STATE_HEERBRUGG: 'Heerbrugg',
	STATE_KANTON_AARGAU: 'Kanton Aargau',
	STATE_LUZERN: 'Luzern',
	STATE_MORBIO_INFERIORE: 'Morbio Inferiore',
	STATE_MUHEN: 'Muhen',
	STATE_NEUCHATEL: 'Neuchatel',
	STATE_NIDWALDEN: 'Nidwalden',
	STATE_OBWALDEN: 'Obwalden',
	STATE_SANKT_GALLEN: 'Sankt Gallen',
	STATE_SCHAFFHAUSEN: 'Schaffhausen',
	STATE_SCHWYZ: 'Schwyz',
	STATE_SOLOTHURN: 'Solothurn',
	STATE_THURGAU: 'Thurgau',
	STATE_TICINO: 'Ticino',
	STATE_URI: 'Uri',
	STATE_VALAIS: 'Valais',
	STATE_VAUD: 'Vaud',
	STATE_VAUFFELIN: 'Vauffelin',
	STATE_ZUG: 'Zug',
	STATE_ZURICH: 'Zürich',
	STATE_ALEPPO: 'Aleppo',
	STATE_DAR__A: 'Dar’a',
	STATE_DAYR_AZ_ZAWR: 'Dayr-az-Zawr',
	STATE_DIMASHQ: 'Dimashq',
	STATE_HALAB: 'Halab',
	STATE_HAMAH: 'Hamah',
	STATE_HIMS: 'Hims',
	STATE_IDLIB: 'Idlib',
	STATE_MADINAT_DIMASHQ: 'Madinat Dimashq',
	STATE_TARTUS: 'Tartus',
	STATE_AL_HASAKAH: 'al-Hasakah',
	STATE_AL_LADHIQIYAH: 'al-Ladhiqiyah',
	STATE_AL_QUNAYTIRAH: 'al-Qunaytirah',
	STATE_AR_RAQQAH: 'ar-Raqqah',
	STATE_AS_SUWAYDA: 'as-Suwayda',
	STATE_CHANGHWA: 'Changhwa',
	STATE_CHIAYI_HSIEN: 'Chiayi Hsien',
	STATE_CHIAYI_SHIH: 'Chiayi Shih',
	STATE_EASTERN_TAIPEI: 'Eastern Taipei',
	STATE_HSINCHU_HSIEN: 'Hsinchu Hsien',
	STATE_HSINCHU_SHIH: 'Hsinchu Shih',
	STATE_HUALIEN: 'Hualien',
	STATE_ILAN: 'Ilan',
	STATE_KAOHSIUNG_HSIEN: 'Kaohsiung Hsien',
	STATE_KAOHSIUNG_SHIH: 'Kaohsiung Shih',
	STATE_KEELUNG_SHIH: 'Keelung Shih',
	STATE_KINMEN: 'Kinmen',
	STATE_MIAOLI: 'Miaoli',
	STATE_NANTOU: 'Nantou',
	STATE_NORTHERN_TAIWAN: 'Northern Taiwan',
	STATE_PENGHU: 'Penghu',
	STATE_PINGTUNG: 'Pingtung',
	STATE_TAICHUNG: 'Taichung',
	STATE_TAICHUNG_HSIEN: 'Taichung Hsien',
	STATE_TAICHUNG_SHIH: 'Taichung Shih',
	STATE_TAINAN_HSIEN: 'Tainan Hsien',
	STATE_TAINAN_SHIH: 'Tainan Shih',
	STATE_TAIPEI_HSIEN: 'Taipei Hsien',
	STATE_TAIPEI_SHIH___TAIPEI_HSIEN: 'Taipei Shih / Taipei Hsien',
	STATE_TAITUNG: 'Taitung',
	STATE_TAOYUAN: 'Taoyuan',
	STATE_YILAN: 'Yilan',
	STATE_YUN_LIN_HSIEN: 'Yun-Lin Hsien',
	STATE_YUNLIN: 'Yunlin',
	STATE_DUSHANBE: 'Dushanbe',
	STATE_GORNO_BADAKHSHAN: 'Gorno-Badakhshan',
	STATE_KAROTEGIN: 'Karotegin',
	STATE_KHATLON: 'Khatlon',
	STATE_SUGHD: 'Sughd',
	STATE_ARUSHA: 'Arusha',
	STATE_DAR_ES_SALAAM: 'Dar es Salaam',
	STATE_DODOMA: 'Dodoma',
	STATE_IRINGA: 'Iringa',
	STATE_KAGERA: 'Kagera',
	STATE_KIGOMA: 'Kigoma',
	STATE_KILIMANJARO: 'Kilimanjaro',
	STATE_LINDI: 'Lindi',
	STATE_MARA: 'Mara',
	STATE_MBEYA: 'Mbeya',
	STATE_MOROGORO: 'Morogoro',
	STATE_MTWARA: 'Mtwara',
	STATE_PWANI: 'Pwani',
	STATE_RUKWA: 'Rukwa',
	STATE_RUVUMA: 'Ruvuma',
	STATE_SHINYANGA: 'Shinyanga',
	STATE_SINGIDA: 'Singida',
	STATE_TABORA: 'Tabora',
	STATE_TANGA: 'Tanga',
	STATE_ZANZIBAR_AND_PEMBA: 'Zanzibar and Pemba',
	STATE_AMNAT_CHAROEN: 'Amnat Charoen',
	STATE_ANG_THONG: 'Ang Thong',
	STATE_BANGKOK: 'Bangkok',
	STATE_BURI_RAM: 'Buri Ram',
	STATE_CHACHOENGSAO: 'Chachoengsao',
	STATE_CHAI_NAT: 'Chai Nat',
	STATE_CHAIYAPHUM: 'Chaiyaphum',
	STATE_CHANGWAT_CHAIYAPHUM: 'Changwat Chaiyaphum',
	STATE_CHANTHABURI: 'Chanthaburi',
	STATE_CHIANG_MAI: 'Chiang Mai',
	STATE_CHIANG_RAI: 'Chiang Rai',
	STATE_CHON_BURI: 'Chon Buri',
	STATE_CHUMPHON: 'Chumphon',
	STATE_KALASIN: 'Kalasin',
	STATE_KAMPHAENG_PHET: 'Kamphaeng Phet',
	STATE_KANCHANABURI: 'Kanchanaburi',
	STATE_KHON_KAEN: 'Khon Kaen',
	STATE_KRABI: 'Krabi',
	STATE_KRUNG_THEP: 'Krung Thep',
	STATE_LAMPANG: 'Lampang',
	STATE_LAMPHUN: 'Lamphun',
	STATE_LOEI: 'Loei',
	STATE_LOP_BURI: 'Lop Buri',
	STATE_MAE_HONG_SON: 'Mae Hong Son',
	STATE_MAHA_SARAKHAM: 'Maha Sarakham',
	STATE_MUKDAHAN: 'Mukdahan',
	STATE_NAKHON_NAYOK: 'Nakhon Nayok',
	STATE_NAKHON_PATHOM: 'Nakhon Pathom',
	STATE_NAKHON_PHANOM: 'Nakhon Phanom',
	STATE_NAKHON_RATCHASIMA: 'Nakhon Ratchasima',
	STATE_NAKHON_SAWAN: 'Nakhon Sawan',
	STATE_NAKHON_SI_THAMMARAT: 'Nakhon Si Thammarat',
	STATE_NAN: 'Nan',
	STATE_NARATHIWAT: 'Narathiwat',
	STATE_NONG_BUA_LAM_PHU: 'Nong Bua Lam Phu',
	STATE_NONG_KHAI: 'Nong Khai',
	STATE_NONTHABURI: 'Nonthaburi',
	STATE_PATHUM_THANI: 'Pathum Thani',
	STATE_PATTANI: 'Pattani',
	STATE_PHANGNGA: 'Phangnga',
	STATE_PHATTHALUNG: 'Phatthalung',
	STATE_PHAYAO: 'Phayao',
	STATE_PHETCHABUN: 'Phetchabun',
	STATE_PHETCHABURI: 'Phetchaburi',
	STATE_PHICHIT: 'Phichit',
	STATE_PHITSANULOK: 'Phitsanulok',
	STATE_PHRA_NAKHON_SI_AYUTTHAYA: 'Phra Nakhon Si Ayutthaya',
	STATE_PHRAE: 'Phrae',
	STATE_PHUKET: 'Phuket',
	STATE_PRACHIN_BURI: 'Prachin Buri',
	STATE_PRACHUAP_KHIRI_KHAN: 'Prachuap Khiri Khan',
	STATE_RANONG: 'Ranong',
	STATE_RATCHABURI: 'Ratchaburi',
	STATE_RAYONG: 'Rayong',
	STATE_ROI_ET: 'Roi Et',
	STATE_SA_KAEO: 'Sa Kaeo',
	STATE_SAKON_NAKHON: 'Sakon Nakhon',
	STATE_SAMUT_PRAKAN: 'Samut Prakan',
	STATE_SAMUT_SAKHON: 'Samut Sakhon',
	STATE_SAMUT_SONGKHRAN: 'Samut Songkhran',
	STATE_SARABURI: 'Saraburi',
	STATE_SATUN: 'Satun',
	STATE_SI_SA_KET: 'Si Sa Ket',
	STATE_SING_BURI: 'Sing Buri',
	STATE_SONGKHLA: 'Songkhla',
	STATE_SUKHOTHAI: 'Sukhothai',
	STATE_SUPHAN_BURI: 'Suphan Buri',
	STATE_SURAT_THANI: 'Surat Thani',
	STATE_SURIN: 'Surin',
	STATE_TAK: 'Tak',
	STATE_TRANG: 'Trang',
	STATE_TRAT: 'Trat',
	STATE_UBON_RATCHATHANI: 'Ubon Ratchathani',
	STATE_UDON_THANI: 'Udon Thani',
	STATE_UTHAI_THANI: 'Uthai Thani',
	STATE_UTTARADIT: 'Uttaradit',
	STATE_YALA: 'Yala',
	STATE_YASOTHON: 'Yasothon',
	STATE_KARA: 'Kara',
	STATE_MARITIME: 'Maritime',
	STATE_ATAFU: 'Atafu',
	STATE_FAKAOFO: 'Fakaofo',
	STATE_NUKUNONU: 'Nukunonu',
	STATE_EUA: 'Eua',
	STATE_HA__APAI: 'Ha’apai',
	STATE_NIUAS: 'Niuas',
	STATE_TONGATAPU: 'Tongatapu',
	STATE_VAVA__U: 'Vava’u',
	STATE_ARIMA_TUNAPUNA_PIARCO: 'Arima-Tunapuna-Piarco',
	STATE_CARONI: 'Caroni',
	STATE_CHAGUANAS: 'Chaguanas',
	STATE_COUVA_TABAQUITE_TALPARO: 'Couva-Tabaquite-Talparo',
	STATE_DIEGO_MARTIN: 'Diego Martin',
	STATE_GLENCOE: 'Glencoe',
	STATE_PENAL_DEBE: 'Penal Debe',
	STATE_POINT_FORTIN: 'Point Fortin',
	STATE_PORT_OF_SPAIN: 'Port of Spain',
	STATE_PRINCES_TOWN: 'Princes Town',
	STATE_SAN_FERNANDO: 'San Fernando',
	STATE_SANGRE_GRANDE: 'Sangre Grande',
	STATE_SIPARIA: 'Siparia',
	STATE_TOBAGO: 'Tobago',
	STATE_ARYANAH: 'Aryanah',
	STATE_BAJAH: 'Bajah',
	STATE_BIN___ARUS: 'Bin ’Arus',
	STATE_BINZART: 'Binzart',
	STATE_GOUVERNORAT_DE_ARIANA: 'Gouvernorat de Ariana',
	STATE_GOUVERNORAT_DE_NABEUL: 'Gouvernorat de Nabeul',
	STATE_GOUVERNORAT_DE_SOUSSE: 'Gouvernorat de Sousse',
	STATE_HAMMAMET_YASMINE: 'Hammamet Yasmine',
	STATE_JUNDUBAH: 'Jundubah',
	STATE_MADANIYIN: 'Madaniyin',
	STATE_MANUBAH: 'Manubah',
	STATE_MONASTIR: 'Monastir',
	STATE_NABUL: 'Nabul',
	STATE_QABIS: 'Qabis',
	STATE_QAFSAH: 'Qafsah',
	STATE_QIBILI: 'Qibili',
	STATE_SAFAQIS: 'Safaqis',
	STATE_SFAX: 'Sfax',
	STATE_SIDI_BU_ZAYD: 'Sidi Bu Zayd',
	STATE_SILYANAH: 'Silyanah',
	STATE_SUSAH: 'Susah',
	STATE_TATAWIN: 'Tatawin',
	STATE_TAWZAR: 'Tawzar',
	STATE_TUNIS: 'Tunis',
	STATE_ZAGHWAN: 'Zaghwan',
	STATE_AL_KAF: 'al-Kaf',
	STATE_AL_MAHDIYAH: 'al-Mahdiyah',
	STATE_AL_MUNASTIR: 'al-Munastir',
	STATE_AL_QASRAYN: 'al-Qasrayn',
	STATE_AL_QAYRAWAN: 'al-Qayrawan',
	STATE_ADANA: 'Adana',
	STATE_ADIYAMAN: 'Adiyaman',
	STATE_AFYON: 'Afyon',
	STATE_AGRI: 'Agri',
	STATE_AKSARAY: 'Aksaray',
	STATE_AMASYA: 'Amasya',
	STATE_ANKARA: 'Ankara',
	STATE_ANTALYA: 'Antalya',
	STATE_ARDAHAN: 'Ardahan',
	STATE_ARTVIN: 'Artvin',
	STATE_AYDIN: 'Aydin',
	STATE_BALIKESIR: 'Balikesir',
	STATE_BARTIN: 'Bartin',
	STATE_BATMAN: 'Batman',
	STATE_BAYBURT: 'Bayburt',
	STATE_BILECIK: 'Bilecik',
	STATE_BINGOL: 'Bingol',
	STATE_BITLIS: 'Bitlis',
	STATE_BOLU: 'Bolu',
	STATE_BURDUR: 'Burdur',
	STATE_BURSA: 'Bursa',
	STATE_CANAKKALE: 'Canakkale',
	STATE_CANKIRI: 'Cankiri',
	STATE_CORUM: 'Corum',
	STATE_DENIZLI: 'Denizli',
	STATE_DIYARBAKIR: 'Diyarbakir',
	STATE_DUZCE: 'Duzce',
	STATE_EDIRNE: 'Edirne',
	STATE_ELAZIG: 'Elazig',
	STATE_ERZINCAN: 'Erzincan',
	STATE_ERZURUM: 'Erzurum',
	STATE_ESKISEHIR: 'Eskisehir',
	STATE_GAZIANTEP: 'Gaziantep',
	STATE_GIRESUN: 'Giresun',
	STATE_GUMUSHANE: 'Gumushane',
	STATE_HAKKARI: 'Hakkari',
	STATE_HATAY: 'Hatay',
	STATE_ICEL: 'Icel',
	STATE_IGDIR: 'Igdir',
	STATE_ISPARTA: 'Isparta',
	STATE_ISTANBUL: 'Istanbul',
	STATE_IZMIR: 'Izmir',
	STATE_KAHRAMANMARAS: 'Kahramanmaras',
	STATE_KARABUK: 'Karabuk',
	STATE_KARAMAN: 'Karaman',
	STATE_KARS: 'Kars',
	STATE_KARSIYAKA: 'Karsiyaka',
	STATE_KASTAMONU: 'Kastamonu',
	STATE_KAYSERI: 'Kayseri',
	STATE_KILIS: 'Kilis',
	STATE_KIRIKKALE: 'Kirikkale',
	STATE_KIRKLARELI: 'Kirklareli',
	STATE_KIRSEHIR: 'Kirsehir',
	STATE_KOCAELI: 'Kocaeli',
	STATE_KONYA: 'Konya',
	STATE_KUTAHYA: 'Kutahya',
	STATE_LEFKOSA: 'Lefkosa',
	STATE_MALATYA: 'Malatya',
	STATE_MANISA: 'Manisa',
	STATE_MARDIN: 'Mardin',
	STATE_MUGLA: 'Mugla',
	STATE_MUS: 'Mus',
	STATE_NEVSEHIR: 'Nevsehir',
	STATE_NIGDE: 'Nigde',
	STATE_ORDU: 'Ordu',
	STATE_OSMANIYE: 'Osmaniye',
	STATE_RIZE: 'Rize',
	STATE_SAKARYA: 'Sakarya',
	STATE_SAMSUN: 'Samsun',
	STATE_SANLIURFA: 'Sanliurfa',
	STATE_SIIRT: 'Siirt',
	STATE_SINOP: 'Sinop',
	STATE_SIRNAK: 'Sirnak',
	STATE_SIVAS: 'Sivas',
	STATE_TEKIRDAG: 'Tekirdag',
	STATE_TOKAT: 'Tokat',
	STATE_TRABZON: 'Trabzon',
	STATE_TUNCELI: 'Tunceli',
	STATE_USAK: 'Usak',
	STATE_VAN: 'Van',
	STATE_YALOVA: 'Yalova',
	STATE_YOZGAT: 'Yozgat',
	STATE_ZONGULDAK: 'Zonguldak',
	STATE_AHAL: 'Ahal',
	STATE_ASGABAT: 'Asgabat',
	STATE_BALKAN: 'Balkan',
	STATE_DASOGUZ: 'Dasoguz',
	STATE_LEBAP: 'Lebap',
	STATE_MARI: 'Mari',
	STATE_GRAND_TURK: 'Grand Turk',
	STATE_SOUTH_CAICOS_AND_EAST_CAICOS: 'South Caicos and East Caicos',
	STATE_FUNAFUTI: 'Funafuti',
	STATE_NANUMANGA: 'Nanumanga',
	STATE_NANUMEA: 'Nanumea',
	STATE_NIUTAO: 'Niutao',
	STATE_NUI: 'Nui',
	STATE_NUKUFETAU: 'Nukufetau',
	STATE_NUKULAELAE: 'Nukulaelae',
	STATE_VAITUPU: 'Vaitupu',
	STATE_CHERKAS__KA: 'Cherkas’ka',
	STATE_CHERNIHIVS__KA: 'Chernihivs’ka',
	STATE_CHERNIVETS__KA: 'Chernivets’ka',
	STATE_CRIMEA: 'Crimea',
	STATE_DNIPROPETROVSKA: 'Dnipropetrovska',
	STATE_DONETS__KA: 'Donets’ka',
	STATE_IVANO_FRANKIVS__KA: 'Ivano-Frankivs’ka',
	STATE_KHARKIV: 'Kharkiv',
	STATE_KHARKOV: 'Kharkov',
	STATE_KHERSONSKA: 'Khersonska',
	STATE_KHMEL__NYTS__KA: 'Khmel’nyts’ka',
	STATE_KIROVOHRAD: 'Kirovohrad',
	STATE_KRYM: 'Krym',
	STATE_KYYIV: 'Kyyiv',
	STATE_KYYIVS__KA: 'Kyyivs’ka',
	STATE_L__VIVS__KA: 'L’vivs’ka',
	STATE_LUHANS__KA: 'Luhans’ka',
	STATE_MYKOLAYIVS__KA: 'Mykolayivs’ka',
	STATE_ODES__KA: 'Odes’ka',
	STATE_ODESSA: 'Odessa',
	STATE_POLTAVS__KA: 'Poltavs’ka',
	STATE_RIVNENS__KA: 'Rivnens’ka',
	STATE_SEVASTOPOL: 'Sevastopol',
	STATE_SUMS__KA: 'Sums’ka',
	STATE_TERNOPIL__S__KA: 'Ternopil’s’ka',
	STATE_VOLYNS__KA: 'Volyns’ka',
	STATE_VYNNYTS__KA: 'Vynnyts’ka',
	STATE_ZAKARPATS__KA: 'Zakarpats’ka',
	STATE_ZAPORIZHIA: 'Zaporizhia',
	STATE_ZHYTOMYRS__KA: 'Zhytomyrs’ka',
	STATE_ABU_ZABI: 'Abu Zabi',
	STATE_AJMAN: 'Ajman',
	STATE_DUBAI: 'Dubai',
	STATE_RAS_AL_KHAYMAH: 'Ras al-Khaymah',
	STATE_SHARJAH: 'Sharjah',
	STATE_SHARJHA: 'Sharjha',
	STATE_UMM_AL_QAYWAYN: 'Umm al Qaywayn',
	STATE_AL_FUJAYRAH: 'al-Fujayrah',
	STATE_ASH_SHARIQAH: 'ash-Shariqah',
	STATE_ABERDEEN: 'Aberdeen',
	STATE_ABERDEENSHIRE: 'Aberdeenshire',
	STATE_ARGYLL: 'Argyll',
	STATE_BEDFORDSHIRE: 'Bedfordshire',
	STATE_BELFAST: 'Belfast',
	STATE_BERKSHIRE: 'Berkshire',
	STATE_BIRMINGHAM: 'Birmingham',
	STATE_BRECHIN: 'Brechin',
	STATE_BRIDGNORTH: 'Bridgnorth',
	STATE_BRISTOL: 'Bristol',
	STATE_BUCKINGHAMSHIRE: 'Buckinghamshire',
	STATE_CAMBRIDGE: 'Cambridge',
	STATE_CAMBRIDGESHIRE: 'Cambridgeshire',
	STATE_CHANNEL_ISLANDS: 'Channel Islands',
	STATE_CHESHIRE: 'Cheshire',
	STATE_CLEVELAND: 'Cleveland',
	STATE_CO_FERMANAGH: 'Co Fermanagh',
	STATE_CONWY: 'Conwy',
	STATE_CORNWALL: 'Cornwall',
	STATE_COVENTRY: 'Coventry',
	STATE_CRAVEN_ARMS: 'Craven Arms',
	STATE_CUMBRIA: 'Cumbria',
	STATE_DENBIGHSHIRE: 'Denbighshire',
	STATE_DERBY: 'Derby',
	STATE_DERBYSHIRE: 'Derbyshire',
	STATE_DEVON: 'Devon',
	STATE_DIAL_CODE_DUNGANNON: 'Dial Code Dungannon',
	STATE_DIDCOT: 'Didcot',
	STATE_DORSET: 'Dorset',
	STATE_DUNBARTONSHIRE: 'Dunbartonshire',
	STATE_DURHAM: 'Durham',
	STATE_EAST_DUNBARTONSHIRE: 'East Dunbartonshire',
	STATE_EAST_LOTHIAN: 'East Lothian',
	STATE_EAST_MIDLANDS: 'East Midlands',
	STATE_EAST_SUSSEX: 'East Sussex',
	STATE_EAST_YORKSHIRE: 'East Yorkshire',
	STATE_ENGLAND: 'England',
	STATE_ESSEX: 'Essex',
	STATE_FERMANAGH: 'Fermanagh',
	STATE_FIFE: 'Fife',
	STATE_FLINTSHIRE: 'Flintshire',
	STATE_FULHAM: 'Fulham',
	STATE_GAINSBOROUGH: 'Gainsborough',
	STATE_GLOCESTERSHIRE: 'Glocestershire',
	STATE_GWENT: 'Gwent',
	STATE_HAMPSHIRE: 'Hampshire',
	STATE_HANTS: 'Hants',
	STATE_HEREFORDSHIRE: 'Herefordshire',
	STATE_HERTFORDSHIRE: 'Hertfordshire',
	STATE_IRELAND: 'Ireland',
	STATE_ISLE_OF_MAN: 'Isle Of Man',
	STATE_ISLE_OF_WIGHT: 'Isle of Wight',
	STATE_KENFORD: 'Kenford',
	STATE_KENT: 'Kent',
	STATE_KILMARNOCK: 'Kilmarnock',
	STATE_LANARKSHIRE: 'Lanarkshire',
	STATE_LANCASHIRE: 'Lancashire',
	STATE_LEICESTERSHIRE: 'Leicestershire',
	STATE_LINCOLNSHIRE: 'Lincolnshire',
	STATE_LLANYMYNECH: 'Llanymynech',
	STATE_LONDON: 'London',
	STATE_LUDLOW: 'Ludlow',
	STATE_MAYFAIR: 'Mayfair',
	STATE_MERSEYSIDE: 'Merseyside',
	STATE_MID_GLAMORGAN: 'Mid Glamorgan',
	TATE_MIDDLESEX: 'Middlesex',
	STATE_MILDENHALL: 'Mildenhall',
	STATE_MONMOUTHSHIRE: 'Monmouthshire',
	STATE_NEWTON_STEWART: 'Newton Stewart',
	STATE_NORFOLK: 'Norfolk',
	STATE_NORTH_HUMBERSIDE: 'North Humberside',
	STATE_NORTH_YORKSHIRE: 'North Yorkshire',
	STATE_NORTHAMPTONSHIRE: 'Northamptonshire',
	STATE_NORTHANTS: 'Northants',
	STATE_NORTHERN_IRELAND: 'Northern Ireland',
	STATE_NORTHUMBERLAND: 'Northumberland',
	STATE_NOTTINGHAMSHIRE: 'Nottinghamshire',
	STATE_OXFORD: 'Oxford',
	STATE_POWYS: 'Powys',
	STATE_ROOS_SHIRE: 'Roos-shire',
	STATE_SUSSEX: 'SUSSEX',
	STATE_SCOTLAND: 'Scotland',
	STATE_SCOTTISH_BORDERS: 'Scottish Borders',
	STATE_SHROPSHIRE: 'Shropshire',
	STATE_SOMERSET: 'Somerset',
	STATE_SOUTH_GLAMORGAN: 'South Glamorgan',
	STATE_SOUTH_WALES: 'South Wales',
	STATE_SOUTH_YORKSHIRE: 'South Yorkshire',
	STATE_SOUTHWELL: 'Southwell',
	STATE_STAFFORDSHIRE: 'Staffordshire',
	STATE_STRABANE: 'Strabane',
	STATE_SUFFOLK: 'Suffolk',
	STATE_SURREY: 'Surrey',
	STATE_TWICKENHAM: 'Twickenham',
	STATE_TYNE_AND_WEAR: 'Tyne and Wear',
	STATE_TYRONE: 'Tyrone',
	STATE_UTAH: 'Utah',
	STATE_WALES: 'Wales',
	STATE_WARWICKSHIRE: 'Warwickshire',
	STATE_WEST_LOTHIAN: 'West Lothian',
	STATE_WEST_MIDLANDS: 'West Midlands',
	STATE_WEST_SUSSEX: 'West Sussex',
	STATE_WEST_YORKSHIRE: 'West Yorkshire',
	STATE_WHISSENDINE: 'Whissendine',
	STATE_WILTSHIRE: 'Wiltshire',
	STATE_WOKINGHAM: 'Wokingham',
	STATE_WORCESTERSHIRE: 'Worcestershire',
	STATE_WREXHAM: 'Wrexham',
	STATE_WURTTEMBERG: 'Wurttemberg',
	STATE_YORKSHIRE: 'Yorkshire',
	STATE_CALIFORNIA: 'California',
	STATE_COLORADO: 'Colorado',
	STATE_CONNECTICUT: 'Connecticut',
	STATE_DELAWARE: 'Delaware',
	STATE_DISTRICT_OF_COLUMBIA: 'District of Columbia',
	STATE_HAWAII: 'Hawaii',
	STATE_IDAHO: 'Idaho',
	STATE_ILLINOIS: 'Illinois',
	STATE_INDIANA: 'Indiana',
	STATE_IOWA: 'Iowa',
	STATE_KANSAS: 'Kansas',
	STATE_KENTUCKY: 'Kentucky',
	STATE_LOUISIANA: 'Louisiana',
	STATE_MAINE: 'Maine',
	STATE_MARYLAND: 'Maryland',
	STATE_MASSACHUSETTS: 'Massachusetts',
	STATE_MICHIGAN: 'Michigan',
	STATE_MINNESOTA: 'Minnesota',
	STATE_MISSISSIPPI: 'Mississippi',
	STATE_MISSOURI: 'Missouri',
	STATE_NEBRASKA: 'Nebraska',
	STATE_NEVADA: 'Nevada',
	STATE_NEW_HAMPSHIRE: 'New Hampshire',
	STATE_NEW_JERSEY: 'New Jersey',
	STATE_NEW_MEXICO: 'New Mexico',
	STATE_NORTH_CAROLINA: 'North Carolina',
	STATE_NORTH_DAKOTA: 'North Dakota',
	STATE_OHIO: 'Ohio',
	STATE_OKLAHOMA: 'Oklahoma',
	STATE_OREGON: 'Oregon',
	STATE_PENNSYLVANIA: 'Pennsylvania',
	STATE_RHODE_ISLAND: 'Rhode Island',
	STATE_SOUTH_CAROLINA: 'South Carolina',
	STATE_SOUTH_DAKOTA: 'South Dakota',
	STATE_TENNESSEE: 'Tennessee',
	STATE_TEXAS: 'Texas',
	STATE_VIRGINIA: 'Virginia',
	STATE_WEST_VIRGINIA: 'West Virginia',
	STATE_WISCONSIN: 'Wisconsin',
	STATE_WYOMING: 'Wyoming',
	STATE_UNITED_STATES_MINOR_OUTLYING_I: 'United States Minor Outlying I',
	STATE_ARTIGAS: 'Artigas',
	STATE_CANELONES: 'Canelones',
	STATE_CERRO_LARGO: 'Cerro Largo',
	STATE_COLONIA: 'Colonia',
	STATE_DURAZNO: 'Durazno',
	STATE_FLORES: 'Flores',
	STATE_LAVALLEJA: 'Lavalleja',
	STATE_MALDONADO: 'Maldonado',
	STATE_MONTEVIDEO: 'Montevideo',
	STATE_PAYSANDU: 'Paysandu',
	STATE_RIVERA: 'Rivera',
	STATE_ROCHA: 'Rocha',
	STATE_SALTO: 'Salto',
	STATE_SORIANO: 'Soriano',
	STATE_TACUAREMBO: 'Tacuarembo',
	STATE_TREINTA_Y_TRES: 'Treinta y Tres',
	STATE_ANDIJON: 'Andijon',
	STATE_BUHORO: 'Buhoro',
	STATE_CIZAH: 'Cizah',
	STATE_FARGONA: 'Fargona',
	STATE_HORAZM: 'Horazm',
	STATE_KASKADAR: 'Kaskadar',
	STATE_KORAKALPOGISTON: 'Korakalpogiston',
	STATE_NAMANGAN: 'Namangan',
	STATE_NAVOI: 'Navoi',
	STATE_SAMARKAND: 'Samarkand',
	STATE_SIRDARE: 'Sirdare',
	STATE_SURHONDAR: 'Surhondar',
	STATE_TOSKENT: 'Toskent',
	STATE_MALAMPA: 'Malampa',
	STATE_PENAMA: 'Penama',
	STATE_SANMA: 'Sanma',
	STATE_SHEFA: 'Shefa',
	STATE_TAFEA: 'Tafea',
	STATE_TORBA: 'Torba',
	STATE_VATICAN_CITY_STATE_HOLY_SEE_: 'Vatican City State (Holy See)',
	STATE_ANZOATEGUI: 'Anzoategui',
	STATE_APURE: 'Apure',
	STATE_ARAGUA: 'Aragua',
	STATE_BARINAS: 'Barinas',
	STATE_CARABOBO: 'Carabobo',
	STATE_COJEDES: 'Cojedes',
	STATE_DELTA_AMACURO: 'Delta Amacuro',
	STATE_FALCON: 'Falcon',
	STATE_GUARICO: 'Guarico',
	STATE_LARA: 'Lara',
	STATE_MERIDA: 'Merida',
	STATE_MIRANDA: 'Miranda',
	STATE_MONAGAS: 'Monagas',
	STATE_NUEVA_ESPARTA: 'Nueva Esparta',
	STATE_PORTUGUESA: 'Portuguesa',
	STATE_TACHIRA: 'Tachira',
	STATE_TRUJILLO: 'Trujillo',
	STATE_VARGAS: 'Vargas',
	STATE_YARACUY: 'Yaracuy',
	STATE_ZULIA: 'Zulia',
	STATE_BAC_GIANG: 'Bac Giang',
	STATE_BINH_DINH: 'Binh Dinh',
	STATE_BINH_DUONG: 'Binh Duong',
	STATE_DA_NANG: 'Da Nang',
	STATE_DONG_BANG_SONG_CUU_LONG: 'Dong Bang Song Cuu Long',
	STATE_DONG_BANG_SONG_HONG: 'Dong Bang Song Hong',
	STATE_DONG_NAI: 'Dong Nai',
	STATE_DONG_NAM_BO: 'Dong Nam Bo',
	STATE_DUYEN_HAI_MIEN_TRUNG: 'Duyen Hai Mien Trung',
	STATE_HANOI: 'Hanoi',
	STATE_HUNG_YEN: 'Hung Yen',
	STATE_KHU_BON_CU: 'Khu Bon Cu',
	STATE_LONG_AN: 'Long An',
	STATE_MIEN_NUI_VA_TRUNG_DU: 'Mien Nui Va Trung Du',
	STATE_THAI_NGUYEN: 'Thai Nguyen',
	STATE_THANH_PHO_HO_CHI_MINH: 'Thanh Pho Ho Chi Minh',
	STATE_THU_DO_HA_NOI: 'Thu Do Ha Noi',
	STATE_TINH_CAN_THO: 'Tinh Can Tho',
	STATE_TINH_DA_NANG: 'Tinh Da Nang',
	STATE_TINH_GIA_LAI: 'Tinh Gia Lai',
	STATE_ANEGADA: 'Anegada',
	STATE_JOST_VAN_DYKE: 'Jost van Dyke',
	STATE_TORTOLA: 'Tortola',
	STATE_SAINT_CROIX: 'Saint Croix',
	STATE_ALO: 'Alo',
	STATE_SINGAVE: 'Singave',
	STATE_WALLIS: 'Wallis',
	STATE_BU_JAYDUR: 'Bu Jaydur',
	STATE_WAD_ADH_DHAHAB: 'Wad-adh-Dhahab',
	STATE_AL___AYUN: 'al-’Ayun',
	STATE_AS_SAMARAH: 'as-Samarah',
	STATE_ADAN: 'Adan',
	STATE_ABYAN: 'Abyan',
	STATE_DHAMAR: 'Dhamar',
	STATE_HADRAMAUT: 'Hadramaut',
	STATE_HAJJAH: 'Hajjah',
	STATE_HUDAYDAH: 'Hudaydah',
	STATE_IBB: 'Ibb',
	STATE_LAHIJ: 'Lahij',
	STATE_MA__RIB: 'Ma’rib',
	STATE_MADINAT_SAN__A: 'Madinat San’a',
	STATE_SA__DAH: 'Sa’dah',
	STATE_SANA: 'Sana',
	STATE_SHABWAH: 'Shabwah',
	STATE_TA__IZZ: 'Ta’izz',
	STATE_AL_BAYDA: 'al-Bayda',
	STATE_AL_HUDAYDAH: 'al-Hudaydah',
	STATE_AL_JAWF: 'al-Jawf',
	STATE_AL_MAHRAH: 'al-Mahrah',
	STATE_AL_MAHWIT: 'al-Mahwit',
	STATE_COPPERBELT: 'Copperbelt',
	STATE_LUAPALA: 'Luapala',
	STATE_LUSAKA: 'Lusaka',
	STATE_NORTH_WESTERN: 'North-Western',
	STATE_BULAWAYO: 'Bulawayo',
	STATE_HARARE: 'Harare',
	STATE_MANICALAND: 'Manicaland',
	STATE_MASHONALAND_CENTRAL: 'Mashonaland Central',
	STATE_MASHONALAND_EAST: 'Mashonaland East',
	STATE_MASHONALAND_WEST: 'Mashonaland West',
	STATE_MASVINGO: 'Masvingo',
	STATE_MATABELELAND_NORTH: 'Matabeleland North',
	STATE_MATABELELAND_SOUTH: 'Matabeleland South',
	STATE_MIDLANDS: 'Midlands',
	STATE_AARHUS: 'Aarhus'
};
