import onboardingLocalization from '../components/Onboarding/localization/zh-CN';
import ticketingLocalization from '../components/Ticketing/localization/zh-CN';
import supportLocalization from '../components/Support/localization/zh-CN';
import automationLocalization from '../components/Automation/localization/zh-CN';
import sharedLocalization from '../components/Shared/localization/zh-CN';
import analyticsLocalization from '../components/AnalyticsInvoices/localization/zh-CN';
import failoverLocalization from '../components/Failover/localization/zh-CN';
import reportsLocalization from '../components/Reports/localization/zh-CN';
import eulaLocalization from '../components/Eula/localization/zh-CN';
import accountOverviewLocalization from '../components/AccountOverview/localization/zh-CN';
import analyticsChartsLocalization from '../components/Analytics/localization/zh-CN';
import batchHistoryLocalization from '../components/BatchHistory/localization/zh-CN';
import errorLocalization from '../components/ErrorPage/localization/zh-CN';
import userFeedbackLocalization from '../components/UserFeedback/localization/zh-CN';
import swaggerLocalization from '../components/Swagger/localization/zh-CN';
import securityFeatures from '../components/SecurityFeatures/localization/zh-CN';
import releaseNotesLocalization from '../components/ReleaseNotes/localization/zh-CN';
import endpointsLocalization from '../components/Endpoint/localization/zh-CN';
import singleEndpointLocalization from '../components/SingleEndpoint/localization/zh-CN';

export default {
	...automationLocalization,
	...sharedLocalization,
	...analyticsLocalization,
	...supportLocalization,
	...onboardingLocalization,
	...ticketingLocalization,
	...failoverLocalization,
	...reportsLocalization,
	...accountOverviewLocalization,
	...eulaLocalization,
	...analyticsChartsLocalization,
	...batchHistoryLocalization,
	...errorLocalization,
	...userFeedbackLocalization,
	...swaggerLocalization,
	...securityFeatures,
	...endpointsLocalization,
	...releaseNotesLocalization,
	...singleEndpointLocalization,
	// GLOBAL SETTINGS
	DISPLAY_DATE_FORMAT: 'MM/dd/yyyy',

	// SIDEBAR
	HOME: '主页',
	ENDPOINTS: '端点',
	Endpoints: '端点',
	REPORTS: '报告',
	INVOICES: '账单',
	AUTOMATION: '自动化',
	PLATFORM_STATUS: '平台状态',
	SUPPORT: '支持',
	COLLAPSE_SIDEBAR: '收起侧边栏',
	COMPANIES: '公司',
	FAQ: '常见问题',
	HELP_SUPPORT: '帮助与支持',
	BATCH_UPDATES: '批处理更新',
	FAQS: '常见问题',
	RELEASE_NOTES: '版本说明',
	SYSTEM_NOTIFICATIONS: '系统通知',
	ACCOUNT_SETTINGS: '账户设置',
	COMPANY_ACCOUNTS: '公司账户',
	CUSTOM_FIELDS: '定制字段',
	COMPANY_DETAILS: '公司详情',
	TESTING_ICON: '测试图标',
	ENTITIES: '实体',
	IOTGW_FAILOVER: 'IoT网关故障切换',
	ACCESS_MANAGEMENT: '访问管理',
	SIM_ORDERS: 'SIM卡订购',
	USER_MANAGEMENT: '用户管理',

	// TOPBAR
	MORE_IOT: '更多IoT',
	MORE_ATT: '更多AT&T',
	LEARN_ABOUT_OFFERS: '了解优惠',
	BUY_DEVICES: '购买设备',
	ATT_SERVICES: 'AT&T服务',
	USE_TOOLS_APPS: '使用工具与应用程序',
	IOT_PLATFORMS_PRODUCTS_APPS_AND_APIS: 'IoT平台、产品、应用程序和API',
	AMOC: 'AMOC',
	M2X: 'M2X',
	FLOW_DESIGNER: 'Flow Designer',
	IOT_STARTER_KITS: 'IoT入门套装',
	IOT_DATA_PLANS: 'IoT数据计划',
	ENTERPRISE: '企业',
	BUSINESS: '业务',
	CONSUMER: '消费者',

	// PROFILE MENU
	PROFILE: '概况',
	APPS: '应用程序',
	LOGOUT: '登出',
	LOG_IN: '登录',

	// FOOTER
	IOT_CUSTOMER_SOLUTIONS: 'IoT客户解决方案',
	IOT_PLATFORM_AND_SERVICES: 'IoT平台与服务',
	NETWORK_SOLUTIONS: '网络解决方案',
	PARTNERSHIP_PROGRAM: '合作伙伴项目',
	BUSINESS_SUPPORT: '业务支持',
	ABOUT_ATT: '关于AT&T',
	ABOUT_ATT_IOT: '关于AT&T IoT',
	NEWS: '新闻',
	NETWORKING_EXCHANGE_BLOG: '网络交换博客',
	BUSINESS_COMMUNITY: '业务社区',
	YOUTUBE_CHANNEL: 'YouTube频道',
	LEGAL_PRIVACY_ADVERTISING_ACCESSIBILITY: '法律、隐私、广告、无障碍',
	LEGAL_POLICY_CENTER: '法律政策中心',
	PRIVACY_POLICY: '隐私政策',
	TERMS_OF_USE: '使用条款',
	BROADBAND_DETAILS: '宽带详情',
	YOUR_CHOICES: '您的选择',
	ADVERTISING_CHOICES: '广告选择',
	ACCESSIBILITY: '无障碍',
	COPYRIGHT_ATT_INTELECTUAL_PROPERTY: '©{year} AT&T的知识财产。',
	COPYRIGHT_MESSAGE: '保留所有权利。AT&T、地球标识、Mobilizing Your World、DIRECTV均为AT&T知识产权和/或其附属公司的注册商标。 所有其他标识均为各自所有者的产权。',
	CONNECT_WITH_ATT: '连接AT&T',
	CURRENT_SESSION_USAGE: '当前会话用量',
	ENDPOINT_NETWORK_AND_LOCATION: '端点网络与地点',

	// NETWORK ENTITLEMENTS
	ENDPOINT_DETAILS_SETUP: '端点详情设置',
	NETWORK_ENTITLEMENT_DETAILS: '网络权利详情',
	CHANGE_NETWORK_ENTITLEMENTS: '更改网络权限',
	CONFIRM_NETWORK_ENTITLEMENT_CHANGE: '确认网络权限更改',
	NETWORK_ENTITLEMENT_CHANGE_STATUS: '网络权限更改状态',
	NETWORK_ENTITLEMENT_CHANGE_TOOLTIP_SUPPORT: '网络权限出错，请与客户支持联系。',
	NETWORK_ENTITLEMENT_CHANGE_NOT_ALLOWED: '抱歉，不允许对来自不同网络类型或服务提供商的端点执行多个端点网络权限更改。',
	SCHEDULE_NETWORK_ENTITLEMENT_SUCCESSFUL: '已成功地为多张SIM卡安排网络权限',
	SIMS_NETWORK_ENTITLEMENT_SUCCESSFULLY_UPDATED: '已成功更新SIM卡网络权限',
	SIM_NETWORK_ENTITLEMENT_CHANGE_FOR: 'SIM卡网络权限更改于',
	MULTIPLE_NETWORK_ENTITLEMENT_CHANGE_SCHEDULE_ERROR: '目前无法成功地安排多个SIM卡网络权限更改',
	MULTIPLE_NETWORK_ENTITLEMENT_CHANGE_ERROR: '目前无法成功地安排多个SIM卡网络权限更改',
	states: '状态',
	networkOperators: '网络运营者',
	countries: '网络国家',
	SELECT_NETWORK_ENTITLEMENT: '选择网络权限',
	ACCOUNT_NAME: '账户名称',
	APN: 'APN',
	DESCRIPTION: '描述',
	ROAMING: '漫游',
	CREATE_DATE: '创建日期',
	LAST_UPDATE_DATE: '最后一次更新日期',
	APN_LTE: 'APN LTE',
	SMS_MT: '移动端终止短信（SMS MT）',
	SMS_MO: '移动端始发短信（SMS MO）',
	VOICE_MO: '语音移动始发（MO）',
	VOICE_MT: '语音移动终端（MT）',
	INTERNATIONAL_VOICE: '国际语音',
	FIXED_IP_ENABLED: '已启用固定IP',
	LTE_DATA: 'LTE数据',
	LTE_ROAMING_RESTRICT_SET_ID: '漫游LTE',
	LTE_APNS: 'LTE APN',
	CAMEL_SUBSCRIPTION_INFO: 'Camel',
	CAMEL_SERVICE_TYPE: 'Camel服务类型',
	M2M_PLATFORM: '机对机（M2M） 平台',
	APNS_ID: 'APN ID',
	APNS_NAME: '名称',
	APNS_LTE: 'LTE',
	APNS_PDPINDEX: 'PDP索引',
	APNS_DESCRIPTION: 'APN描述',
	APNS_FIXEDIP: '固定IP',
	APNS_SECURITY: '安全',
	APNS_SUBSCRIPTION: '订阅',
	APNS_CREATEDATE: '创建日期',
	APNS_LASTUPDATEDATE: '上次更新日期',
	APNS_LASTUSER: '最近一次用户',
	APNS_APNTYPE: 'APN类型',
	APNS_CREATIONTIMESTAMP: '创建的时间标记',
	APNS_BMSYNCSTATUS: 'BM同步状态',
	APNS_HLRPROFILEREFERENCE: 'HLRHSS详情档案参考',
	APNS_IPADDRESSALLOCATIONTYPE: 'IP地址分配类型',
	APNS_ISLOCALBREAKOUTENABLEDINDICATOR: '是本地突破启动标记',
	APNS_LASTUPDATETIMESTAMP: '最后一次更新的时间标记',
	APNS_OUCCONNECTIONTYPE: 'OUC连接类型',
	APNS_SECURITYPROFILESUPPORTEDINDICATOR: '安全档案支持的标记',
	APNS_STATUS: '状态',
	APNS_VPNENABLEDINDICATOR: 'VPN启用标记',
	CURRENTLY_ASSIGNED_TO_THIS_ENDPOINT: '当前已指派给此端点',
	CURRENTLY_ASSIGNED_TO_SELECTED_ENDPOINTS: '当前已指派给选中的端点',
	ELIGIBLE_TO_USE_WITH_SELECTED_ENDPOINTS: '可用于选中的端点',
	ELIGIBLE_TO_USE_WITH_THIS_ENDPOINT: '可用于此端点',
	ELIGIBLE_NETWORK_ENTITLEMENTS: '符合条件的网络权限',
	NETWORK_ENTITLEMENT_CHANGE_IS_SUCCESSFULLY_SUBMITTED: '已成功提交网络权限更改。',
	NETWORK_ENTITLEMENT_CHANGE_COULD_NOT_BE_CARRIED_OUT_SUCCESSFULLY: '目前无法完成网络权限更改。',
	NETWORK_ENTITLEMENT_CHANGE_IS_SUCCESSFULLY_SCHEDULED: '已成功安排网络权限更改。',
	NETWORK_ENTITLEMENT_CHANGE_IS_SUCCESSFULLY_SCHEDULED_FOR: '已成功将网络权限更改安排在 ',
	NETWORK_ENTITLEMENT_CHANGE_COULD_fNOT_BE_SCHEDULED_SUCCESSFULLY: '目前无法安排网络权限更改。',
	ONCE_YOU_INITIATE_THIS_CHANGE: '一旦开始更改操作，被选中端点的网络权限将 ',
	WOULD_YOU_LIKE_TO_PROCEED_WITH_THIS_ACTION: '您确定要继续此项行动吗？',
	OR_YOU_CAN_SCHEDULE_THE_CHANGE_FOR: '您也可以将此更改安排在： ',
	FRIENDLY_NAME: '昵称',
	NAME: '名称',
	// 网络权限详情
	APN_DETAILS_ID: 'ID',
	APN_DETAILS_NAME: '名称',
	APN_DETAILS_LTE: 'LTE',
	APN_DETAILS_PDPINDEX: 'PDP索引',
	APN_DETAILS_DESCRIPTION: '描述',
	APN_DETAILS_FIXEDIP: '固定IP',
	APN_DETAILS_SECURITY: '安全',
	APN_DETAILS_SUBSCRIPTION: '订阅',

	// BUTTON LABELS
	CANCEL: '取消',
	CHANGE: '更改',
	TROUBLESHOOT: '故障排除',
	CHANGE_STATUS: '更改状态',
	CLOSE: '关闭',
	APPLY_CHANGE: '应用更改',
	DONE: '完成',
	SAVE: '保存',
	CONTINUE: '继续',
	SAVEDASHBOARD: '保存仪表板',
	SAVE_AND_CONTINUE: '保存并继续',
	SAVE_CHANGES: '保存更改',
	PULL_DATA: '提取数据',
	I_DO_NOT_AGREE: '我不同意',
	I_AGREE: '我同意',
	LOADING: '正在载入',
	UPLOAD_LOGO: '上载标识',
	ADD_NEW_CUSTOMER: '添加新客户',
	EDIT: '编辑',
	DELETE: '删除',
	NEXT: '下一项',

	// NOTIFICATIONS
	ENDPOINT_ID: '端点ID',
	PROVISIONING_ERROR: '配给错误',
	FAILED_TO_PROVISION_AFTER_IMSI_STATUS_CHANGE: '更改IMSI状态后配给失败。 ',
	GET_SUPPORT: '获得支持',
	PROVISIONING_SUCCESS: '配给成功',
	IS_SUCCESSFULLY_PROVISIONED: '已成功配给。',
	IMSI_STATUS_IS_CHANGED_TO: 'IMSI状态被更改为',
	PENDING_STATUS_CHANGE: '待决更改！',
	STATUS_CHANGE_FOR: '已经有一项为此端点安排的活动',
	IS_PENDING: '处于待决状态',
	CUSTOM_FIELD_EDIT_SUCCESS: '定制字段编辑成功',
	CUSTOM_FIELD_EDIT_ERROR: '定制字段编辑出错',
	CUSTOM_FIELD_VALUES_UPDATED: '已成功更新一个或多个定制字段',
	CUSTOM_FIELD_VALUES_UPDATED_ERROR: '本次未能更新一个或多个定制字段。请稍后重试。',
	CUSTOM_FIELD_LABEL_SUCCESS_TITLE: '定制字段标签编辑成功！',
	CUSTOM_FIELD_LABEL_SUCCESS_MESSAGE: '已成功更新一个或多个定制字段标签。',
	CUSTOM_FIELD_LABEL_ERROR_TITLE: '定制字段标签编辑出错！',
	CUSTOM_FIELD_LABEL_ERROR_MESSAGE: '本次未能更新一个或多个定制字段标签。请稍后重试。',
	TABLE_COLUMNS_CHANGE_SUCCESS_TITLE: '已成功更改端点表格设置！',
	TABLE_COLUMNS_CHANGE_SUCCESS_MESSAGE: '已成功更新端点表格设置。',
	TABLE_COLUMNS_CHANGE_ERROR_TITLE: '更改端点表格列时出错！',
	TABLE_COLUMNS_CHANGE_ERROR_MESSAGE: '本次未能更新端点表格列。请稍后重试。',
	MC10515: '文件名不正确',
	MC10516: '文件数据错误',
	MC10517: '文件中不包含文件头，或为空文件',
	DOWNLOAD_REPORT_ERROR_TITLE: '下载错误',
	DOWNLOAD_REPORT_ERROR_MESSAGE: '尝试下载您的文件时出错',
	FETCH_REPORT_ERROR_TITLE: '没有数据',
	FETCH_REPORT_ERROR_MESSAGE: '没有与搜索或过滤要求相匹配的数据',
	MARK_READ: '标记为已读',
	VALIDATION_FAILED: '验证失败',
	ACTION_COMPLETED_SUCCESSFULLY: '已成功完成行动',
	ACTION_COULD_NOT_BE_COMPLETED: '无法完成此行动',

	// SINGLE ENDPOINT
	ENDPOINT_DETAILS: '端点详情',
	DETAILS: '详情',
	DIAGNOSTIC: '诊断',
	CHANGES: '更改',
	SESSIONS: '会话',
	END_USER: '终端用户',
	LIFE_CYCLE: '使用周期',
	SEND_SMS: '发送短信（SMS）',
	OTA: 'OTA',
	RATING: '费率',
	CARRIER_INFORMATION: '服务运营商信息',
	M2M_PLATFORM_STATUS_DESCRIPTION: '机对机（M2M） 平台状态描述',
	VIEW_NOTE: '查看注释',
	SE_NOTES: '注释',
	CHANGE_NOTES: '更改注释',
	ENTER_NOTE_HERE: '在这里输入注释（最多2000个字符）',
	ADD_NOTE_BELOW: '在下面添加注释：',
	CHANGING_NOTES: '更改注释',
	MULTIPLE_NOTES_CHANGE_ERROR: '无法成功地开始为多个端点更改注释',
	CHANGING_NOTES_NOT_ALLOWED: '不允许更改注释',
	ENDPOINTS_SUCCESSFULLY_CHANGED: '已成功更改端点',
	ENDPOINT_DETAILS_SECOND_CARD_NO_DATA: '此端点的数据暂时不可用',

	// DATA OVERVIEW
	ICCID: '集成线路卡识别符（ICCID）',
	IMSI: 'IMSI',
	NETWORK_OPERATOR: '网络运营者',
	NETWORK_COUNTRY: '网络国家',
	MSISDN: '移动台国际订户目录号（MSISDN）',
	DUAL_MODE: '双模式',
	CONNECTED_DEVICE: '已连接的设备',
	IMEI: 'IMEI',
	STATUS: '状态',
	STATUS_CHANGE_PENDING: '已有一项为此端点安排的活动',
	RATE_PLAN: '费率计划',
	NETWORK_ENTITLEMENT: '网络权利',
	SOURCE_PLATFORM: '机对机（M2M） 平台',
	SERVICE_PROVIDER: '机对机（M2M） 平台',
	NETWORK_ENTITLEMENT_ID: '网络权利ID',

	// ENDPOINT STATUS
	CURRENT_ENDPOINT_STATUS: '当前端点状态',

	// 更改SIM卡参数模式
	CHANGE_SIM_PARAMETER: '更改SIM卡参数',
	SELECT_ATT_STATE: '选择AT&T状态',
	CURRENT: '当前',

	BY: '操作者 ',
	SCHEDULING: '正在安排……',

	// EDIT TABLE MODAL
	EDIT_TABLE_PARAMETERS: '编辑表格参数',
	EDIT_CUSTOM_FIELD_LABELS: '编辑定制字段标签',
	CUSTOM_LABEL: '定制标签{labelIndex}',
	VALUE: '值',
	SET_AS_REQUIRED: '按照要求设置',
	SELECT_A_MINIMUM_OF_4_TO_APPLY_TO_THE_TABLE: '至少选择应用于此表格的2个项目。',
	SELECTED: '已选择',
	CLEAR_ALL: '恢复默认',

	EOD: '按需服务企业（EOD）',
	ACC: 'AT&T控制中心（ACC）',
	GBCM: 'Speedcast',
	MC: 'IoT Console（IoT-C）',
	DCP: '设备连接平台（DCP）',
	CCIP: '控制中心整合计划（CCIP）',
	AIS: 'AIS',
	TWM: '台湾大哥大（TWM）',
	ATT: 'AT&T',
	AUTOMATION_ACC: 'ACC',
	AUTOMATION_DCP: '设备连接平台（DCP）',
	// AT&T："AT&T",
	WING: '诺基亚（WING）',
	VIVO: 'Vivo控制中心',

	eod: '按需服务企业（EOD）',
	acc: 'AT&T控制中心（ACC）',
	gbcm: 'Speedcast',
	mc: 'IoT Console（IoT-C）',
	dcp: '设备连接平台（DCP）',
	ccip: '控制中心整合计划（CCIP）',
	ais: 'AIS',
	twm: '台湾大哥大（TWM）',
	att: 'AT&T',
	// at&t:"AT&T",
	wing: '诺基亚（WING）',
	vivo: 'Vivo控制中心',

	SELECTED_FILTERS: '已选择的过滤器',

	BY_WEEK: '按周',
	BY_DAY: '按日',
	BY_HOUR: '按小时',
	BYTES_SENT: 'MB已发送',
	BYTES_RECEIVED: 'MB已接收',
	TOTAL_MINS: '分钟总计',
	monthly: '每月',
	daily: '每日',
	MONTHLY: '每月',
	DAILY: '每日',

	// ACTION BAR
	SHOWING: '正在显示',
	OF: '共',

	// REPORTS
	TRANSFER: '转让',
	USAGE: '用量',
	DOWNLOAD: '下载',
	OTHER: '其他',

	// INPUT ERRORS
	REQUIRED: '要求',
	INVALID_COMPANY_NAME: '无效公司名称',
	INVALID_EMAIL_ADDRESS: '无效电子邮件地址',
	PHONE_MUST_BE_A_NUMBER: '电话号码必须是数字',
	WRONG_PHONE_FORMAT: '错误电话号码格式',
	ONLY_LETTERS_AND_NUMBERS_ARE_ALLOWED: '只允许使用字母和数字',
	ONLY_LETTERS_ARE_ALLOWED: '只允许使用字母',
	ONLY_LETTERS_AND_SPACES_ARE_ALLOWED: '只允许使用字母和空格',
	ONLY_NUMBERS_ARE_ALLOWED: '只允许使用数字',
	ONLY_POSITIVE_NUMBERS_ARE_ALLOWED: '只允许使用大于零的数字',
	NUMBER_HAS_TO_BE_LESS_THAN: '数字必须小于{propName}',
	NUMBER_HAS_TO_BE_LESS_THAN_OR_EQUAL_TO: '数字必须小于或等于{propName}',
	NUMBER_HAS_TO_BE_GREATER_THAN: '数字必须大于{propName}',
	NUMBER_HAS_TO_BE_GREATER_THAN_OR_EQUAL_TO: '数字必须大于或等于{propName}',
	NUMBER_HAS_TO_BE_IN_INCREMENTAL_STEPS: '数字必须在{increment}的基础上逐级增加',
	INVALID_FORMAT: '无效格式',
	MAX_CHARACTERS_EXCEEDED: '超出字符上限',
	MUST_BE_LESS_THAN_OR_EQUAL_TO_CHARACTERS: '必须小于或等于{max}个字符',
	MUST_BE_CHARACTERS_LONG: '必须为{number}个字符',
	NO_ONLY_WHITE_SPACE: '不可仅包含空格',
	OUT_OF_RANGE: '超出范围',
	NOT_BEFORE: '不允许{date}之前的日期',
	INVALID_VERSION: '仅允许【大版本.小版本.补丁】格式的编号，即：x.y.z',

	/* ------ OLD BUT STILL WORKING - DO NOT TOUCH - WORK IN PROGRESS --------- */
	ACCOUNT_OVERVIEW: '账户概述',
	ACCOUNT_OVERVIEW_ADMIN_DASHBOARD: '账户概述管理员仪表板',
	BILLING: '账单',

	LOG_OUT: '登出',

	DATA_PLAN: '数据计划',
	ACTIVESESSION: '使用中的会话',
	IPADDRESS: 'IP地址',
	ANALYTICS: '分析',
	NETWORK: '网络',

	ASSET_ID: '资产ID',
	SESSION_START: '会话开始',
	DATA_VOLUME: '数据量',
	DURATION: '持续时间（分钟）',
	'DATE&TIME': '日期和时间',
	NOTE: '注释',
	LOGINTITLE: '登录',
	LOGINSUBTITLE: '请输入您的电子邮件与密码。',
	ENDPOINTS_INVENTORY_STATUS: '端点库存状态',
	INVENTORY_BY_CARRIER: '按照服务运营商显示库存',
	INVENTORY_BY_COUNTRY: '按照国家显示库存',
	ACCOUNT_USERS: '账户用户',
	USER_AUTOMATION_STATUS: '用户自动化状态',
	AUTOMATION_PROCESS_STATUS: '自动化过程状态',
	NETWORK_TYPE: '网络类型',
	DEVICE_NAME: '设备名称',
	DEVICE_ID: '设备ID',
	DEVICE_MODEL_NUMBER: '设备型号#',
	ROAMING_RESTRICTED: '漫游受限',
	INVOICE_NUMBER: '账单号',
	DATE: '日期',
	SCHEDULE_DATE: '日期',
	ISSUED_BY: '签发',
	PAYMENT_STATUS: '付款状态',
	REPORT_NAME: '报告名称',
	DATE_CREATED: '创建日期',
	CREATED_BY: '创建者',
	PAY_NOW: '立即支付',
	EDIT_GRAPHS_VIEW: '编辑图视图',
	EDIT_ENDPOINTS_LIST_VIEW: '编辑端点清单视图',
	CHANGE_LANGUAGE: '更改语言',
	CHANGE_LANGUAGE_ICON: '英语',
	NOT_APPLICABLE_FOR_THIS_ENDPOINT: '对此端点不适用',
	CREATE_NEW_RULE: '创建新规则',
	EDIT_RULE: '编辑规则',
	CREATE_NEW_RULE_EXP: '自动化允许您对自己的账户采取批量行动。',
	CREATE_NEW_RULE_ACTION: '请选择“创建新规则”按钮，以创建新的自动化行动',
	RULE_NAME: '规则名称',
	CATEGORY: '类别',
	TRIGGER: '触发',
	ACTION: '行动',
	BULK_ACTIONS: '批量行动',
	MULTI_ENDPOINT_ACTIONS: '多个端点行动',
	SET_ENDPOINT_STATE: '设置端点状态',
	CHANGE_DATA_PLAN: '更改数据计划',
	SWAP_OPERATOR: '切换运营者',
	MULTI_EDIT: '多重编辑',
	SEARCH_FOR_RULES: '搜索规则',
	SEARCH_FOR_INVOICES: '搜索账单',
	SEARCH_FOR_REPORTS: '按名称搜索报告',
	SEARCH_ENDPOINTS: '搜索端点',
	PAYABLE_INVOICES: '应付账单',
	RECEIVED_PAYMENTS: '已收到的付款',
	PAYMENT_METHODS: '付款方式',
	BILLERS: '收款人',
	TIME_PERIOD: '时间期限',
	FROM_DATEPICKER: '发件人',
	TO_DATEPICKER: '收件人',
	ENDPOINT_UI_TOOLTIP: '端点或设备的身份识别号',
	OPERATOR_TOOLTIP: '运营者端点和概况属于',
	NETWORK_TYPE_TOOLTIP: '运营者连接技术',
	COUNTRY_TOOLTIP: '端点来源国家',
	ROAMING_RESTRICTED_TOOLTIP: '如果端点漫游受限',
	STATUS_TOOLTIP: '当前端点状态',
	INITIATE_RULE: '启动规则',
	NEW_AUTOMATION_RULE: '新的自动化规则',
	ENDPOINT_PROVISIONING: '端点配给',
	ENDPOINT_PROFILE: '端点概况',
	ENDPOINT_SECURITY: '端点安全',
	ENDPOINT_SUBSCRIPTION: '端点订阅',
	NEW_RULE: '新规则',
	CONDITION: '条件',
	ENDPOINT_RANGE: '端点范围是',
	RANGE_START: '范围开始',
	RANGE_END: '范围结束',
	ASSIGN_ENDPOINT: '将端点指派给运营者',
	OPERATOR_NAME: '运营者名称',
	ACCOUNT: '账户',
	ADD_ACTION: '添加行动',
	RUN_IMMEDIATELY: '立即运行',
	TIME: '时间',
	SELECT_DATE: '选择日期',
	SELECT_TIME: '选择时间',
	EVENT_TRIGGER: '事件触发',
	EVENT_TYPE: '事件类型',
	EVENT_LOCATION: '事件地点',
	DATA_PLAN_NAME: '数据计划名称',
	LOGIN_VALIDATION_WRONG_CREDENTIALS_HEADER: '错误认证信息！',
	LOGIN_VALIDATION_WRONG_CREDENTIALS_BODY: '密码与提供的电子邮件地址不匹配。',
	EMAIL: '电子邮件',
	PASSWORD: '密码',
	FORGOT_PASSWORD: '忘记密码？',
	REMEMBER_ME: '记住我',
	LOGIN_BUTTON: '登录',
	BACK: '返回',
	ENDPOINT_USAGE: '端点用量',
	COUNTRY_AND_OPERATOR: '国家与运营者',
	PAYMENTS_HISTORY: '付款历史记录',
	SPENDING_THIS_YEAR: '本年度花销',
	ENDPOINT_DATA: '端点数据',
	CURRENT_SESSION: '当前会话',
	VIEW_LESS: '查看缩略',
	VIEW_MORE: '查看详情',
	ENDPOINT_STATUS: '端点状态',
	ACTIVATION_DATE: '激活日期',
	PRIMARY_IMS: '主要IMS',

	SERVICE_PROVIDER_MODE: '服务提供者模式',
	SUPER_USER_MODE_MESSAGE: '{mode}：{companyFriendlyName}。',
	SUPER_USER_MODE_MOBILE: '{companyFriendlyName}',
	POLICY: '政策',
	COMMUNICATION_PLAN: '通讯计划',
	COPY_RULE: '复制规则',
	SECURE_ENDPOINT_ID: '安全端点ID',
	CUSTOMER: '客户',
	INVENTORY_STATUS: '库存状态',
	RESOLUTION_NOT_SUPPORTED_FIRST_LINE: '本演示中已禁用小屏幕视图。',
	RESOLUTION_NOT_SUPPORTED_SECOND_LINE: '为了获得最佳效果，请使用桌面Chrome浏览器的最大化功能或全屏模式',
	CHECKING_MOVE_ELIGIBILITY: '正在检查移动资格',
	CHANGE_OPERATOR: '更改运营者',
	SCHEDULE_CHANGE: '安排更改',
	SCHEDULE_CHANGE_BUTTON: '安排更改',
	INITIATE_CHANGE: '启动更改',
	CONFIRM_CHANGE: '确认更改',
	CONFIRM_SIM_STATE_CHANGE: '确认更改SIM卡状态',
	IN_SESSION: '会话中',
	ENTITLEMENTS: '权限',
	LOCALIZE_SERVICE: '本地化服务',
	CHANGE_ENDPOINT_OPERATOR: '更改端点运营者',
	PENDING_CHANGES_ENDPOINT: '此端点有待决的更改，计划开始时间 ',
	SEE_HISTORY_FOR_DETAILS: '查看下面的历史记录，了解详情。',
	CHANGE_ENDPOINT_DATA_PLAN: '更改端点数据计划',
	IS_SELECTED: '已选择。',
	PLEASE_SELECT_DIFFERENT_OPERATOR_TO_CHANGE_OPERATOR: '如需更改运营者，请在下面选择新的运营者：',
	GENERATE_REPORT: '生成报告',
	REPORT_TYPE: '报告类型',
	DATA_PLAN_SUMMARIES: '数据计划总结',
	CHARGE_SUMMARIES: '收费总结',
	INVOICE_REPORT: '账单报告',
	PRORATION_REPORT: '配额报告',
	GROSS_ACTIVATIONS: '总激活',
	MONTHLY_SUBSCRIBERS: '每月订户',
	VIEW_YOUR_ACCOUT_SNAPSHOT: '查看您的账户快照。',
	LAUNCH_DASHBOARD: '启动仪表板',
	VISUAL_DASHBOARD_VIEW_EXP: '可视化仪表板视图为您提供了速览模式，可查看当前账户状态和设备活动。',
	VIEW_CURRENT_STATUS_AND_HISTORICAL_DATA: '在时间线上查看当前状态和历史数据。',
	IF: '如果',
	AND: '和',
	OR: '或',
	TO: '收件人',
	FROM: '发件人',
	INVOICES_STATUS: '账单状态',
	filter_category_m2mAccount: 'M2mAccount',
	PLEASE_CONFIRM_ENDPOINT_DATA_CHANGE: '请确认端点数据更改。',
	SET_NEW_OPERATOR_AS_ACTIVE: '将新运营者设为使用中状态',
	SET_NEW_PLAN_AS_ACTIVE: '将新计划设为使用中状态',
	PLEASE_SELECT_DATE_TO_SET_UP_YOUR_CHANGES_AND_CONFIRM: '请选择设置更改的日期并确认。',
	DATE_REQUIRED: '日期（要求）',
	ADDITIONAL_COMMENT: '附加备注',
	TYPE_YOUR_CUSTOM_NOTES_HERE: '请在此处输入您的定制注释……',
	CONFIRM_CHANGES: '确认更改',
	CHECKING_MOVE_ELIGIBILITY_LOADER: '正在检查移动资格……',
	ELIGIBLE: '符合资格',
	CHECKING: '正在检查',
	PLEASE_SELECT_DIFFERENT_DATA_PLAN_TO_CHANGE_DATA_PLAN: '如需更改数据计划，请在下面选择新计划：',
	CONFIRM: '确认',
	ENDPOINT_RANGE_IS: '端点范围是：',
	ENDPOINT_ID_IS: '端点ID是：',
	ENDPOINT_ID_IS_GREATER_THAN: '端点ID大于：',
	ENDPOINT_ID_IS_LESS_THAN: '端点ID小于：',
	ENDPOINT_STATE_CHANGE: '端点状态更改',
	ENDPOINT_DATA_PLAN: '端点数据计划',
	ENDPOINT_SECURITY_POLICY_CHANGE: '端点安全政策更改',
	ASSIGN_ENDPOINT_TO_OPERATOR: '将端点指派给运营者',
	MOVE_TO_ANOTHER_ACCOUNT: '移动到另一个账户',
	ADD_CUSTOM_FIELD_TO_ENDPOINT_DETAILS: '向端点详情中添加定制字段',
	CREATE_NEW_ACTION: '创建新行动',
	AT_T: 'AT&T IoT（ACC）',
	AT_T_EOD: 'AT&T IoT（ACC）',
	GLOBECOMM: 'Speedcast',
	TAIWAN_MOBILE: '台湾大哥大（TWM）',
	'0001': '***0001',
	'0002': '***0002',
	'0003': '***0003',
	'0004': '***0004',
	OTA_EVENT: 'OTA事件',
	WI_FI: 'Wi-Fi',
	THAILAND: '泰国（THA）',
	TAIWAN: '台湾（TWN）',
	UNITED_STATES: '美国（USA）',
	DATA_PLAN_S: '数据计划S',
	DATA_PLAN_M: '数据计划M',
	DATA_PLAN_L: '数据计划L',
	DATA_PLAN_XL: '数据计划XL',
	// TO DO:将端点状态更新为正确的值
	ACTIVATED: '已激活',
	'TEST READY': '准备好测试',
	DEACTIVATED: '已停用',
	PURGED: '已清除',
	RETIRED: '已淘汰',
	//
	USER: '用户',
	ON: '于',
	MADE: '执行',
	CHANGE_STATUS_ACTION: '更改状态',
	CHANGE_DATA_PLAN_ACTION: '更改数据计划',
	LOCALIZE_SERVICE_ACTION: '本地化服务',
	IMSI_TRANSFER: 'IMSI转让状态',
	TRANSFER_STATUS: 'IMSI转让状态',
	COUNTRY_OPERATOR: '网络国家',

	PROFILE_NAME: '概况名称',
	NUMBER: '号码',
	DATE_MODIFIED: '修改日期',

	ENTITLEMENT_PLAN_ID: '权限计划ID',
	ENTITLEMENT_PLAN_NAME: '权限计划名称',
	ACCOUNT_ID: '账户ID',
	ACCOUNT_I_D: '账户ID',
	IMEI_NAME: 'IMEI名称',
	VIN: 'VIN',
	CHIP_ID: '芯片ID',
	URL_SP: 'URL SP',
	ADD_SINGLE_ENDPOINT: '添加单个端点',
	UPLOAD_ICCID_BULK_FILE: '上载集成线路卡识别符（ICCID）批处理文件',
	DOWNLOAD_BULK_FILE: '下载批处理文件',
	ENDPOINTS_SELECTED: '已选择端点',
	SELECTED_ENDPOINTS: '已选择的端点',
	NEW_STATUS: '新状态',
	MULTIPLE_ENDPOINT_EDIT: '编辑多个端点',
	CLEAR_VALUES_FOR_ALL_ENDPOINTS: '清除所有端点的值',
	KEEP_ORIGINAL_ENDPOINT_VALUES: '保留原始端点值',
	ACTIVE: '已激活',
	INACTIVE: '未使用',
	TERMINATE: '已终止',
	TEST_READY: '准备好测试',
	TEST: '测试',
	SELECT_OPTION_OR_TYPE_IN: '请选择或键入',
	CLEAR_ALL_VALUES_FOR_ENDPOINTS: '清除端点的所有值',
	BULK_STATUS_UPDATE: '批处理状态更新',
	TOTAL_ENDPOINTS: '全部端点',
	TOTAL_FILES: '全部文件',
	ENDPOINT_STATUS_UPDATED: '端点状态更新',
	MODIFIED_ENDPOINTS: '已修改的端点',
	SOMETHING_WENT_WRONG: '出错',
	ENDPOINT_STATUS_IS_UPDATING: '正在更新端点状态',
	PLEASE_WAIT: '请稍候',
	SWAP_SIM: '切换SIM卡',
	SWAP_SIMS: '切换SIM卡',
	SIM: 'SIM卡',
	NEW: '新',
	OLD: '旧',
	CHANGE_ORDER: '更改订购',
	ITEM: '项目',
	ADDITIONAL_INFO: '更多信息',
	UPDATE_LABEL: '更新标签',
	ENDPOINT_LABEL_IS_UPDATING: '正在更新端点标签',
	ENDPOINT_LABEL_UPDATED: '已更新端点标签',
	ENDPOINT_FIELDS_ARE_UPDATING: '正在更新端点字段',
	ENDPOINT_FIELDS_UPDATED: '已更新端点字段',
	EDIT_CUSTOM_FIELDS: '编辑定制字段',
	EDIT_CUSTOM_LABEL: '编辑定制标签',
	CHANGE_ENTITLEMENT: '更改网络权限',
	CHANGE_NETWORK_ENTITLEMENT: '更改网络权限',
	CURRENT_NETWORK_ENTITLEMENT: '当前网络权限',
	NETWORK_ENTITLEMENT_UPDATED_SUCCESSFULLY: '已成功更新网络权限',
	LIST_VIEW_UPDATED: '已更新端点清单视图',
	NETWORK_ENTITLEMENT_UPDATING: '正在更新网络权限。请稍候',
	LIST_VIEW_UPDATING: '正在更新端点清单视图。请稍候',
	NEW_NETWORK_ENTITLEMENT: '新网络权限',
	NEW_NETWORK_ENTITLEMENT_DETAILS: '新网络权限详情',
	TABLE_COLUMNS: '请选择在此表格中显示的列数。',
	TABLE_COLUMNS_OR: '请选择在此表格中显示的列数，或',
	COLUMN: '列',
	COLUMNS: '列',
	SELECT: '选择',
	DATA_TYPE: '数据类型',
	ADVANCED_SEARCH: '高级搜索',
	SEARCH_RESULTS: '搜索结果',
	SUBMIT: '提交',
	SEARCH_PARAMETER: '搜索参数',
	ADD_ANOTHER_SEARCH_PARAMETER: '添加另一个搜索参数',
	STATUS_DATE_RANGE: '状态日期范围',
	SIM_STATUS_STATE: 'SIM卡状态',
	NEW_PARAMETER_ACTION: '和/或',
	PLEASE_SELECT_VALUES_TO_FILTER_ENDPOINTS: '从可用的参数中选择，然后输入过滤端点的标准。',
	RESET_SEARCH: '重设搜索',
	QUICK_SEARCH: '快速搜索',
	ORIGINAL_SETTINGS: '重设为原始设置',
	MONTH: '月',
	DAY: '日',
	REFRESH_ENDPOINTS_PAGE: '刷新端点表格/页面',
	CLEAR_SELECTED_FILTERS_TOOLTIP: '清空已选择的过滤器',
	SELECT_FILTERS_TOOLTIP: '选择过滤器',
	EDIT_TABLE_PARAMETERS_TOOLTIP: '编辑表格参数',

	REPORT_DATE: '日期',

	START_NEW_SEARCH: '开始新搜索',
	SUCCESS: '成功',
	FAILED: '失败',
	RESULT_ACTION: '行动',
	REPORT_DETAILS: '报告详情',
	REPORT_LIST: '报告清单',
	VIEW_DETAILS: '查看详情',
	DOWNLOAD_PDF: '下载',
	FILE_NAME: '文件名',
	BATCH_FILES: '批处理文件',
	BATCH_FILE_NAME: '批处理文件名',
	BATCH_FILE_ACTIONS: '批处理文件行动',
	BATCH_FILE_UPLOAD: '批处理行动',
	CHANGE_SIM_STATE: '更改SIM卡状态',
	SELECT_SIM_STATE: '选择SIM卡状态',
	SIMS_SELECTED: '已选择SIM卡',
	SIM_SELECTED: '已选择SIM卡',
	ONCE_YOU_INITIATE_CHANGE: '一旦开始此更改，被选中的SIM卡将',
	PROCEED_ACTION: '您确定要继续此项行动吗？',
	CAN_SCHEDULE: '或者您也可以将此更改安排在',
	CURRENT_STATES: '当前状态',
	SCHEDULE_SUCCESSFUL: '已成功安排多个SIM卡状态更改！',
	SIM_STATE_CHANGE_FOR: 'SIM卡状态更改为',
	SIMS_IS_SUCCESSFULLY_SCHEDULED_FOR: '已成功将SIM卡安排在',
	SIMS_SUCCESSFULLY_REQUESTED: '已成功请求更改SIM卡状态！',
	MULTIPLE_SIM_STATE_CHANGE_SCHEDULE_ERROR: '目前无法成功地安排多个SIM卡状态更改！',
	MULTIPLE_SIM_STATE_CHANGE_ERROR: '目前无法成功地安排多个SIM卡状态更改！',
	SELECTED_TIP: '提示：如果您选择了处于不同状态的SIM卡，您只能将所有选择的SIM卡更改到一个可用的状态。',
	VIEW_RULES: '查看此账户适用的状态更改规则',
	BATCH_FILE_UPLOADING: '正在上载批处理文件',
	DATE_UPLOADED: '上载日期',
	SIMPLE_SEARCH: '简易搜索',
	NUMBER_OF_COLUMNS: '列数',
	SELECT_SEARCH_PARAMETER: '请选择搜索参数',

	DROPDOWN_BACK_TO_BUSINESS: '返回业务',
	'DROPDOWN_CONTACT_AT&T': '联系AT&T IoT',
	DROPDOWN_MC: 'IoT Console',
	DROPDOWN_M2X: 'M2X',
	DROPDOWN_FD: 'Flow Designer',
	DROPDOWN_SK: 'IoT入门套装',
	DROPDOWN_DP: 'IoT数据计划',
	MY_INFORMATION: '我的信息',
	MY_APPLICATIONS: '我的应用程序',
	TOPBAR_IDENTITY: 'AT&T IoT身份',
	ERROR: '错误',
	PAGE_NOT_FOUND_MESSAGE: '我们似乎无法找到您在搜素的页面。',
	'RETURN TO HOME PAGE': '返回主页',
	INTERNAL_SERVER_ERROR: '内部服务器错误',
	ACTIVE_ENDPOINT_PROFILE: '使用中的端点概况',
	CREATE_OR_EDIT: '为此端点创建或编辑定制标签',
	CREATE_CUSTOM_LABELS: '创建定制标签',
	SNAP: '出错了',
	SNAP_ERROR_MESSAGE: '发生了未知错误。如持续出现该错误，请联系用户支持。',

	// report detail definitions
	REPORT_DETAIL_DEFINITIONS: '报告详情定义',
	REPORT_DETAIL_DEFINITIONS_MODAL_TITLE: '报告详情定义',
	REPORT_DETAIL_DEFINITIONS_DESC: '选择下面的按钮，查看所有报告详情定义。',
	VIEW_DEFINITIONS_BUTTON: '查看定义',

	// definition types
	'DATA SESSIONS DETAIL DEFINITION': '数据会话详情定义',
	'IMSI CHARGE DETAIL XML FIELDS': 'IMSI收费详情XML字段',
	'IMSI DETAIL DEFINITIONS': 'IMSI详情定义',
	'LBS USAGE DETAILS': '地理位置服务（LBS）用量详情',
	'DATA LAGG DETAIL DEFINITIONS': '数据延迟详情定义',
	'SMS CALL DETAIL DEFINITIONS': '短信服务（SMS）呼叫详情定义',
	'TAX DETAIL DEFINITIONS': '税务详情定义',
	'SIM ACTIVITY DETAIL DEFINITION': 'SIM卡活动详情定义',

	// 数据延迟详情定义 - 键-------------
	CUST_ID_KEY: 'CUST_ID',
	IMSI_KEY: 'IMSI',
	ICCID_KEY: '集成线路卡识别符（ICCID）',
	MSISDN_KEY: '移动台国际订户目录号（MSISDN）',
	ON_NET_KB_USAGE_KEY: 'ON_NET_KB_USAGE',
	ROAMING_KB_USAGE_KEY: 'ROAMING_KB_USAGE',
	CAN_KB_USAGE_KEY: 'CAN_KB_USAGE',
	INT_KB_ROUNDED_KEY: 'INT_KB_ROUNDED',
	TOTAL_KB_USAGE_KEY: 'TOTAL_KB_USAGE',

	// 数据延迟详情定义 - 描述
	CUST_ID_DESC: 'EOD客户ID。',
	IMSI_DESC: '国际移动站点识别符”。这是网络用于识别用户的号码。',
	ICCID_DESC: '集成线路卡识别符。这是印刷在SIM卡上的号码。',
	MSISDN_DESC: '指派给该SIM卡的移动站点ISDN号码（电话号码）。',
	ON_NET_KB_USAGE_DESC: '网内总用量（KB）',
	ROAMING_KB_USAGE_DESC: '国内网外总用量（KB）。',
	CAN_KB_USAGE_DESC: '加拿大总用量（KB）。',
	INT_KB_ROUNDED_DESC: '国际总用量（KB）。',
	TOTAL_KB_USAGE_DESC: '总用量（KB）。',

	// 数据会话详情定义 - 键-------------
	IMPORT_DATE_KEY: '导入日期',
	// IMSI - 使用已定义的键描述
	// ICCID - 使用已定义的键描述
	GGSN_IP_KEY: 'GGSN IP',
	SGSN_IP_KEY: 'SGSN IP',
	APN_NAME_KEY: 'APN名称',
	PDP_IP_KEY: 'PDP IP',
	UPLINK_VOLUME_KEY: '上行量',
	DOWNLINK_VOLUME_KEY: '下行量',
	TRANSACTION_START_TIME_KEY: '交易开始时间',
	DURATION_KEY: '持续时间',
	SERVED_MSISDN_KEY: '服务的移动台国际订户目录号（MSISDN）',
	BID_KEY: 'BID',
	SERVING_LOCATION_KEY: '服务地点',
	NETWORK_KEY: '网络',
	ROUNDING_DATE_KEY: '舍入日期',

	// 数据会话详情定义 - 描述
	IMPORT_DATE_DESC: 'EOD收到此记录的日期和时间。根据此数据决定此记录适用的账单日期。',
	// IMSI - 使用已定义的键描述
	// ICCID - 使用已定义的键描述
	GGSN_IP_DESC: '网关通用分组无线业务（GPRS）支持节点。该设备位于公用陆地移动网络（PLMN）和外部网络之间，为安全和会计等服务提供边界网关。',
	SGSN_IP_DESC: '服务通用分组无线业务（GPRS）支持节点。该设备与移动交换中心/来访位置登记器（MSC/VLR）位于同一级别，负责执行移动管理，以及管理通往移动终端的逻辑链路。',
	APN_NAME_DESC: '此设备在本次交易中使用的APN名称。',
	PDP_IP_DESC: '交易发生时指派给设备的IP地址。',
	UPLINK_VOLUME_DESC: '设备发送此详情记录所产生的（8位）字节数。',
	DOWNLINK_VOLUME_DESC: '设备接收此详情记录所产生的（8位）字节数。',
	TRANSACTION_START_TIME_DESC: '交易开始的实际时间。根据EOD收到记录的时间，此日期可能与“导入日期”和“路由日期”不同。',
	DURATION_DESC: '该数据记录的时间（以秒为单位）。',
	SERVED_MSISDN_DESC: '指派给该SIM卡的移动站点ISDN号码（电话号码）。',
	BID_DESC: '账单识别符。EOD根据此识别符判断该交易发生的市场。',
	SERVING_LOCATION_DESC: '该数据记录发生时的地点名称。',
	NETWORK_DESC: 'Cingular、漫游/网外、国际或加拿大。',
	ROUNDING_DATE_DESC: 'EOD根据此日期判断该记录应与其他哪些记录分组、计算每日舍入的数据KB数。',
	SESSION_HISTORY_DCP_NOT_APPLICABLE: '不适用',

	// IMSI收费详情XML字段 - 键与描述 ------------
	CUSTOMER_KEY: 'CUSTOMER',
	CUSTOMER_DESC: '客户前缀',
	STARTCYCLE_KEY: 'STARTCYCLE',
	STARTCYCLE_DECS: '账单周期起点',
	ENDCYCLE_KEY: 'ENDCYCLE',
	ENDCYCLE_DESC: '账单周期终点',
	WCO_KEY: 'WCO',
	WCO_DESC: '无线连接优惠',
	EQUIPMENTORDERS_KEY: 'EQUIPMENTORDERS',
	EQUIPMENTORDERS_DESC: '订购设备',
	ACCOUNTOCC_KEY: 'ACCOUNTOCC',
	ACCOUNTOCC_DESC: '账户级别的其他收费和账面余额（仅适用于税款和附加费）',
	ACCOUNTTAXES_KEY: 'ACCOUNTTAXES',
	ACCOUNTTAXES_DESC: '账户级别的政府税款（仅适用于税款）',
	// ICCID - 重用
	// IMSI - 重用
	// MSISDN - 重用
	RATEPLAN_KEY: 'RATEPLAN',
	RATEPLAN_DESC: '账单依据的费率计划。',
	ACTIVATIONSTATE_KEY: 'ACTIVATIONSTATE',
	ACTIVATIONSTATE_DESC: '设备状态。',
	CUSTFILED1_KEY: 'CUSTFILED1',
	CUSTFILED1_DESC: '定制字段1',
	CUSTFILED2_KEY: 'CUSTFILED2',
	CUSTFILED2_DESC: '定制字段2',
	CUSTFILED3_KEY: 'CUSTFILED3',
	CUSTFILED3_DESC: '定制字段3',
	CUSTFILED4_KEY: 'CUSTFILED4',
	CUSTFILED4_DESC: '定制字段4',
	CUSTFILED5_KEY: 'CUSTFILED5',
	CUSTFILED5_DESC: '定制字段5',
	ATTPOINTER_KEY: 'ATTPOINTER',
	ATTPOINTER_DESC: '内部指示字',
	CUSTPOINTER_KEY: 'CUSTPOINTER',
	CUSTPOINTER_DESC: '客户指示字',
	DATAMRC_KEY: 'DATAMRC',
	DATAMRC_DESC: '数据每月重复收费（MRC）',
	DATAUSAGE_KEY: 'DATAUSAGE',
	DATAUSAGE_DESC: '所有数据用量收费总和',
	SMSMRC_KEY: 'SMSMRC',
	SMSMRC_DESC: '短信服务（SMS）每月重复收费（MRC）',
	SMSUSAGE_KEY: 'SMSUSAGE',
	SMSUSAGE_DESC: '所有短信服务（SMS）用量费用总和 — 不包括批处理短信服务（SMS）费用',
	VOICEMRC_KEY: 'VOICEMRC',
	VOICEMRC_DESC: '语音每月重复收费（MRC）',
	VOICEUSAGE_KEY: 'VOICEUSAGE',
	VOICEUSAGE_DESC: '所有语音用量费用总和',
	DEVICEACTIVITYFEE_KEY: 'DEVICEACTIVITYFEE',
	DEVICEACTIVITYFEE_DESC: '设备收费（激活费、停用费、重新激活费、一次性计划更改费）',
	OCC_KEY: 'OCC',
	OCC_DESC: '订户级别的其他收费和账面余额（仅适用于税款和附加费）',
	TAXES_KEY: 'TAXES',
	TAXES_DESC: '订户级别的政府税款（仅适用于税款）',

	// IMSI详细键值 -----------------------
	// ICCID - 重用
	// MSISDN - 重用
	PLAN_CODE_KEY: 'PLAN_CODE',
	PLAN_CODE_DESC: '客户为每张SIM卡指派的计划代码。',
	ACT_ID_KEY: 'ACT_ID',
	ACT_ID_DESC: '客户对每张SIM卡采取的行动（1 = 激活，2 = 停用）',
	INCLUDED_USAGE_KEY: 'INCLUDED_USAGE',
	INCLUDED_USAGE_DESC: '包含的总用量（KB）',
	// ON_NET_KB_USAGE - 重用
	// ROAMING_KB_USAGE - 重用
	// CAN_KB_USAGE - 重用
	// INT_KB_ROUNDED - 重用
	// TOTAL_KB_USAGE - 重用
	ACCESS_CHG_KEY: 'ACCESS_CHG',
	ACCESS_CHG_DESC: '每月产生的访问费用。',
	ACTIVATION_CHG_KEY: 'ACTIVATION_CHG',
	ACTIVATION_CHG_DESC: '激活费用。',
	BEGPERIOD_KEY: 'BEGPERIOD',
	BEGPERIOD_DESC: '账单周期起点。',
	ENDPERIOD_KEY: 'ENDPERIOD',
	ENDPERIOD_DESC: '账单周期终点。',
	CUST_PREFIX_KEY: 'CUST_PREFIX',
	CUST_PREFIX_DESC: '客户识别符。',
	CUST_FIELD_1_KEY: 'CUST_FIELD_1',
	CUST_FIELD_1_DESC: '定制字段1。',
	CUST_FIELD_2_KEY: 'CUST_FIELD_2',
	CUST_FIELD_2_DESC: '定制字段2。',
	CUST_FIELD_3_KEY: 'CUST_FIELD_3',
	CUST_FIELD_3_DESC: '定制字段3。',
	CUST_FIELD_4_KEY: 'CUST_FIELD_4',
	CUST_FIELD_4_DESC: '定制字段4。',
	CUST_FIELD_5_KEY: 'CUST_FIELD_5',
	CUST_FIELD_5_DESC: '定制字段5。',
	INTERNAL_POINTER_KEY: 'INTERNAL POINTER',
	INTERNAL_POINTER_DESC: 'AT&T内部指示字。',
	CUSTOMER_POINTER_KEY: 'CUSTOMER POINTER',
	CUSTOMER_POINTER_DESC: '客户指示字。',

	// LBS用量定义键值
	RECORDINGENTITY_KEY: 'RECORDINGENTITY',
	RECORDINGENTITY_DESC: '指向聚合记录的唯一指示字。（预留，供聚合器使用）。',
	LCSCLIENTEXTERNALID_KEY: 'LCSCLIENTEXTERNALID',
	LCSCLIENTEXTERNALID_DESC: 'LCS客户端ExternalID（当前不可用）。',
	TARGETIMSI_KEY: 'TARGETIMSI',
	TARGETIMSI_DESC: '国际移动站点识别符”。这是移动网络用于识别用户的号码。',
	TARGETMSISDN_KEY: 'TARGETMSISDN',
	TARGETMSISDN_DESC: '如为AT&T的SIM卡，则为定位的MSDN。如为非AT&T的SIM卡，则为XXXXXXXXXX（指派给该SIM卡的移动站点ISDN号码，即“电话号码”）。',
	RESULTCODE_KEY: 'RESULTCODE',
	RESULTCODE_DESC: '定位成功或失败。0 = 成功/赋值仅可为0或1，Agg和Med的resultCode值可能不同。',
	UTCRECORDOPENINGTIME_KEY: 'UTCRECORDOPENINGTIME',
	UTCRECORDOPENINGTIME_DESC: '当前不可用（开启记录的UTC时间）。',
	INFORMATION_1_KEY: 'INFORMATION_1',
	INFORMATION_1_DESC: 'EAG颁发的16位应用程序ID（position2值为“1”时 = Technocom，position2值为“2”时　=　LocAid Id）',
	INFORMATION_3_KEY: 'INFORMATION_3',
	INFORMATION_3_DESC: '请求的（水平）精确度：以米为单位的精确度。<=800时为AGPS。（800代表AGPS请求，801代表非AT&T成功定位时的CID/ECID，0代表定位失败（根据响应决定））。',
	INFORMATION_4_KEY: 'INFORMATION_4 ',
	INFORMATION_4_DESC: '返回的（水平）精确度：实际返回的精确度（以米为单位）。（800代表AGPS请求，801代表非AT&T成功定位时的CID/ECID，0代表定位失败（根据响应决定））。',
	PART_DATE_KEY: 'PART_DATE',
	PART_DATE_DESC: 'Agg输入CDR传输文件的日期。（太平洋标准时间，格式为：YYYY-MM-DD HH:mm:ss）',

	// 短信呼叫定义 - 键与描述
	// IMSI
	// 服务的MSISDN
	SMS_TYPE_KEY: 'SMS TYPE',
	SMS_TYPE_DESC: '移动端始发（MO）或移动端终止（MT）。',
	CALLED_NUMBER_KEY: 'CALLED NUMBER',
	CALLED_NUMBER_DESC: '拨叫的号码。',
	START_TIME_KEY: 'START TIME',
	START_TIME_DESC: '交易发生的日期和时间。',
	// 网络
	PLMN_KEY: 'PLMN',
	PLMN_DESC: '公用陆地移动网络列表是EOD用于判断交易发生市场的账单识别符。',
	CARRIER_KEY: 'CARRIER',
	CARRIER_DESC: '用于相关PLMN的移动市场所有者。',

	// SIM卡活动定义 - 键与描述
	// ICCID
	// MSISDN
	ACTION_DATE_KEY: 'ACTION_DATE',
	ACTION_DATE_DESC: '完成行动的日期和时间。',
	ACT_KEY: 'ACT',
	ACT_DESC: '客户对每张SIM卡采取的行动（ACTV = 激活，KILL = 停用，CHNG = 网络概况更改，RATE = 费率计划更改，CPPU = 主要使用地点更改）。',
	PLAN_CODE_KEY_2: 'PLAN_CODE',
	PLAN_CODE_DESC_2: '用于此交易的计划的字母代码。',
	LOGIN_KEY: 'LOGIN',
	LOGIN_DESC: '与交易相关的用户',

	// 税务详情定义 - 键与描述
	PPU_ADDRES_KEY: 'PPU_ADDRES',
	PPU_ADDRES_DESC: '主要使用地点　—　即客户主要使用其服务的地方。',
	SERVICE_KEY: 'SERVICE',
	SERVICE_DESC: '征税的产品或服务。',
	INVOICE_SECTION_KEY: 'INVOICE SECTION',
	INVOICE_SECTION_DESC: 'TX — 政府收费，OC — 其他收费和账面余额。',
	AUTHORITY_LEVEL_KEY: 'AUTHORITY LEVEL',
	AUTHORITY_LEVEL_DESC: '与账单相关的级别：F — 联邦，S — 州，Co — 县，Ci — 市，DCo — 区县，DCi — 区市',
	INVOICE_TEXT_KEY: 'INVOICE TEXT',
	INVOICE_TEXT_DESC: '与打印在客户账单上的收费相关的文本。',
	CHARGE_AMMOUNT_KEY: 'CHARGE AMMOUNT',
	CHARGE_AMMOUNT_DESC: '已经计入税金的金额。',
	AMMOUNT_KEY: 'AMOUNT',
	AMMOUNT_DESC: '为相关可征税收费计算得出的税款金额。',
	BATCH_FORMATS: '批处理格式',

	ACTIVATION: '激活',
	TERMINATION: '终止',
	NETWORK_ENTITLEMENT_CHANGE: '网络权限更改',
	SIM_SWAP: 'SIM卡切换',
	CUSTOM_FIELD_UPDATES: '定制字段更新',
	CUSTOM_FIELD_VALUE: '定制字段值',
	BATCH_FILE_UPLOAD_SUCCESS: '已成功收到批处理文件！',
	BATCH_FILE_UPLOAD_SUCCESS_MESSAGE: '更改处于待决状态，系统正在完成您的更新',
	BATCH_FILE_SYSTEM_ERROR: '批处理文件系统错误！',
	BATCH_FILE_SYSTEM_ERROR_MESSAGE: '处理您的更新时出现问题。请稍后重试。',
	TYPE_IN_OR_SELECT: '请输入新值，或从下拉列表中选择，以更改特定的端点参数。',

	SUBMITTED_STATUS: '已提交',
	SCHEDULED_STATUS: '已安排',
	IN_PROGRESS_STATUS: '处理中',
	COMPLETED_STATUS: '已完成',
	'IN-PROGRESS_STATUS': '处理',
	COMPLETED: '已完成',
	CANCELLED: '已取消',
	ERROR_STATUS: '错误',

	FILE_NAME_CANNOT_CONTAIN_SPACES: '文件名中不可包含空格',
	DRAG_FILE_OR_CLICK_TO_UPLOAD: '拖放文件或点击上载',
	FORBIDDEN_ACCESS: '禁止访问',
	REQUEST_TIMED_OUT: '请求超时',
	TERMINATE_ACTION: '终止SIM卡',
	ACTIVATE_ACTION: '激活SIM卡',
	SWAP_SIMS_ACTION: '切换SIM卡',
	CUSTOM_FIELDS_ACTION: '添加或更新定制字段',
	REQUIRED_CUSTOM_FIELD: '要求升级许可！',
	FIELD_IS_REQUIRED_ACTION: '修改定制字段会影响整个系统配置，仅限管理员可执行此操作。请联系您的管理员，要求其更新定制字段选项。',
	PERMANENT_CUSTOM_FIELD_DELETION: '永久删除定制字段！',
	DELETING_THIS_CUSTOM_FIELD: '删除此定制字段将会删除所有相关的数据！此操作不可逆！',
	DELETE_FIELD: '删除字段',

	WARNING: '警告',
	ARE_YOU_SURE: '您确定吗？',
	NETWORK_ENTITLEMENT_CHANGE_ACTION: 'SIM卡网络权限更改',
	WHEN_SAVING: '保存文件时，请遵守上述格式。',
	EXAMPLE: '范例',

	SELECT_NEW_STATE: '选择新状态',
	403: '403',

	AUTOMATION_PROCESS: '自动化过程',
	BATCH_UPLOAD: '批处理上载',
	DIAGRAM_COULD_NOT_BE: '图表不得',
	LOADED_AT_THIS_TIME: '当前加载。',
	BATCH_ACTIONS: '批处理行动',
	BATCH_ACTION: '批处理行动',
	BATCH_ACTIONS_SUBMITTED: '文件上载成功，等待处理',
	BATCH_ACTIONS_SCHEDULED: '文件上载成功，已安排处理',
	BATCH_ACTIONS_IN_PROGRESS: '正在处理中',
	BATCH_ACTIONS_COMPLETED: '文件处理成功',
	BATCH_ACTIONS_ERROR: '处理过程中出错',
	BATCH_REPORTING: '批处理报告',
	BATCH_TEXT: '如需在批处理中处理更改，请选择一项要执行的行动，并上载您的批处理txt文件。可提供格式化范例模板。',
	START_BATCH_PROCESS: '开始批处理过程',
	DOWNLOAD_TEMPLATE: '下载模板文件',
	AUTOMATION_RULES: '自动化规则',
	ACTION_FORMAT: '行动格式',
	VIEW_MORE_DETAILS: '查看更多详情',
	UPLOAD_FILE_NOTE: '在此处为选择的批处理行动上载文件：',
	UPLOAD_FILE: '上载文件',

	UNSUPPORTED_FILE_TYPE: '不支持的文件类型',
	INVALID_FILE_NAME_FORMAT: '文件名格式应为actionName_MM-DD-YYYY_HH-mm-ss',
	DOWNLOAD_REPORT_FAILED: '下载失败，请重试。',
	PLEASE_TRY_AGAIN: '请重试。',

	// 报告详情定义
	REPORT_SOURCE: '报告来源',
	DATA_SESSION_DETAIL_DEFINITION: '数据会话详情定义',
	IMSI_DETAIL_DEFINITION: 'IMSI详情定义',
	SMS_DETAIL_DEFINITION: '短信服务（SMS）详情定义',
	SIM_DETAIL_DEFINITION: 'SIM卡详情定义',
	USAGE_DATA_DETAILS: '用量数据详情',
	USAGE_SMS_DETAILS: '短信服务（SMS）用量详情',
	POOLED_USAGE: '汇总用量',
	USAGE_SUMMARY: '用量总结',
	SUBSCRIBER_SNAPSHOT: '订户快照',
	NEW_SUBSCRIBERS: '新订户',
	TOP_DATA_USAGE: '最高数据用量',
	TOP_SMS_USAGE: '最高短信服务（SMS）用量',
	PROVOSIONING_METRIC: '配给指标',

	// 用量数据详情 - 键与描述
	DATA_TRAFFIC_DETAIL_ID_KEY: '数据流量详情ID',
	DATA_TRAFFIC_DETAIL_ID_DESC: '数据用量记录的唯一识别符',
	// "ICCID_KEY"
	ICCID_DESC_SHORT: '用于识别SIM卡的集成线路卡识别符（ICCID）',
	// "MSISDN_KEY"
	MSISDN_DESC_SHORT: '用于识别SIM卡的移动台国际订户目录号（MSISDN）',
	// "IMSI_KEY"
	IMSI_DESC_SHORT: '用于识别SIM卡的IMSI',
	ACCOUNT_ID_KEY: '账户ID',
	ACCOUNT_ID_DESC: 'Jasper账户ID号',
	BILLABLE_FLAG_KEY: '可计费标记',
	BILLABLE_FLAG_DESC: '根据SIM卡状态标记可计费流量的标志',
	BILLING_CYCLE_KEY: '账单周期',
	BILLING_CYCLE_DESC: '用量发生的账单周期',
	SIM_STATE_KEY: 'SIM卡状态',
	SIM_STATE_DESC: '在报告周期末尾时指派给SIM卡的SIM卡状态',
	SERVICE_TYPE_KEY: '服务类型',
	SERVICE_TYPE_DESC: '报告的无线服务类型（例如数据、移动端始发短信、移动端终止短信、语音）',
	ASSIGNED_RATE_PLAN_ID_KEY: '指派的费率计划ID',
	ASSIGNED_RATE_PLAN_ID_DESC: '在报告用量时指派给SIM卡的费率计划',
	ASSIGNED_RATING_ZONE_ID_KEY: '指派的费率区间ID',
	ASSIGNED_RATING_ZONE_ID_DESC: '根据报告用量时的费率计划指派将使用归入的费率计划区间',
	OFFPEAK_USAGE_FLAG_KEY: '非高峰时段用量标记',
	OFFPEAK_USAGE_FLAG_DESC: '用量是否发生在为账户定义的非高峰时段',
	RECORD_RECEIVED_DATE_KEY: '收到记录的日期',
	RECORD_RECEIVED_DATE_DESC: '收到会计记录的日期/时间',
	DATA_USAGE_RAW_TOTAL_KEY: '数据用量 - 原始总计',
	DATA_USAGE_RAW_TOTAL_DESC: '该阶段未经舍入的数据用量 — 单位为字节',
	DATA_USAGE_RAW_UPLINK_KEY: '数据用量 — 原始上行',
	DATA_USAGE_RAW_UPLINK_DESC: '该阶段未经舍入的上行数据用量 — 单位为字节',
	DATA_USAGE_RAW_DOWNLINK_KEY: '数据用量 — 原始下行',
	DATA_USAGE_RAW_DOWNLINK_DESC: '该阶段未经舍入的下行数据用量 — 单位为字节',
	DATA_USAGE_ROUNDED_KEY: '数据用量 — 舍入',
	DATA_USAGE_ROUNDED_DESC: '该阶段经舍入的数据用量 — 单位为字节；舍入规则详见每项费率计划',
	APN_DESC: '会话访问点名称',
	DEVICE_IP_ADDRESS_KEY: '设备IP地址',
	DEVICE_IP_ADDRESS_DESC: '为相应的数据会话指派给设备的IP地址',
	OPERATOR_NETWORK_KEY: '运营者网络',
	OPERATOR_NETWORK_DESC: '用量发生时访问的运营者网络',
	RECORD_OPEN_TIME_KEY: '记录开启时间',
	RECORD_OPEN_TIME_DESC: '完整或部分会话开始的日期/时间',
	SESSION_DURATION_KEY: '会话持续时间',
	SESSION_DURATION_DESC: '数据会话持续时间 — 单位为秒',
	RECORD_SEQUENCE_NUMBER_KEY: '记录序列号',
	RECORD_SEQUENCE_NUMBER_DESC: '如果记录代表部分会话，则为上级会话中的记录序列号',
	CHARGINGID_KEY: '收费ID',
	CHARGINGID_DESC: '用量记录适用的会话识别符',
	SESSION_CLOSE_CAUSE_KEY: '会话关闭原因',
	SESSION_CLOSE_CAUSE_DESC: '代表会话关闭原因的数字代码',
	TAP_CODE_KEY: 'TAP代码',
	TAP_CODE_DESC: '访问网络的TAP代码',
	OPERATOR_ACCOUND_ID_KEY: '运营者账户ID',
	OPERATOR_ACCOUND_ID_DESC: '运营者用于识别账户的ID',
	RATE_PLAN_VERSION_ID_KEY: '费率计划版本ID',
	RATE_PLAN_VERSION_ID_DESC: '费率计划的具体版本，目前派作账单用途',
	STREAM_ID_KEY: '流ID',
	STREAM_ID_DESC: '数字识别符，用于描述GGSN/PGW识别的各种数据流',
	CGI_KEY: 'CGI（全球蜂窝识别符）',
	CGI_DESC: 'TS29.274中描述的部分地点信息',
	SAI_KEY: 'SAI（服务区域识别符）',
	SAI_DESC: 'TS29.274中描述的部分地点信息',
	RAI_KEY: 'RAI（路由区域识别符）',
	RAI_DESC: 'TS29.274中描述的部分地点信息',
	TAI_KEY: 'TAI（追踪区域识别符）',
	TAI_DESC: 'TS29.274中描述的部分地点信息',
	ECGI_KEY: 'ECGI（E-UTRAN CGI）',
	ECGI_DESC: 'TS29.274中描述的部分地点信息',
	LAI_KEY: 'LAI（地点区域识别符）',
	LAI_DESC: 'TS29.274中描述的部分地点信息',
	SERVING_SGSN_KEY: '提供服务的SGSN',
	SERVING_SGSN_DESC: '提供服务的交换机地址',
	CALL_TECHNOLOGY_TYPE_KEY: '呼叫技术类型',
	CALL_TECHNOLOGY_TYPE_DESC: '数字标志，用于识别本次使用中使用的无线电访问技术',

	// 用量短信详情 - 键与描述
	SMS_TRAFFIC_DETAIL_ID_KEY: '数据流量详情ID',
	SMS_TRAFFIC_DETAIL_ID_DESC: '数据使用记录的唯一识别符',
	// "ICCID_KEY"
	// "ICCID_DESC"
	// "MSISDN_KEY"
	// "MSISDN_DESC"
	// "IMSI_KEY"
	// "IMSI_DESC"
	// "ACCOUNT_ID_KEY"
	// "ACCOUNT_ID_DESC"
	// "BILLABLE_FLAG_KEY"
	// "BILLABLE_FLAG_DESC"
	// "BILLING_CYCLE_KEY"
	// "BILLING_CYCLE_DESC"
	// "SIM_STATE_KEY"
	// "SIM_STATE_DESC"
	// "SERVICE_TYPE_KEY"
	// "SERVICE_TYPE_DESC"
	// "ASSIGNED_RATE_PLAN_ID_KEY"
	// "ASSIGNED_RATE_PLAN_ID_DESC"
	// "ASSIGNED_RATING_ZONE_ID_KEY"
	// "ASSIGNED_RATING_ZONE_ID_DESC"
	RECORD_OPEN_TIME_SMS_KEY: '记录开启时间',
	RECORD_OPEN_TIME_SMS_DESC: '移动端始发短信服务（SMS）讯息发出日期/时间、移动端终止讯息的接收日期/时间',
	// "RECORD_RECEIVED_DATE_KEY":'收到记录的日期',
	// "RECORD_RECEIVED_DATE_DESC":'收到会计记录的日期/时间',
	NETWORK_NODE_KEY: '网络节点',
	NETWORK_NODE_DESC: '讯息提供服务的网络节点地址',
	FROM_KEY: '发件人',
	FROM_DESC: '来源移动台国际订户目录号（MSISDN），或短代码',
	TO_KEY: '收件人',
	TO_DESC: '目的地移动台国际订户目录号（MSISDN），或短代码',
	// "OPERATOR_NETWORK_KEY":'运营者网络',
	// "OPERATOR_NETWORK_DESC":'用量发生时访问的运营者网络',
	// "TAP_CODE_KEY":'TAP代码',
	// "TAP_CODE_DESC":'访问网络的TAP代码',
	// "OPERATOR_ACCOUND_ID_KEY":'运营者账户ID',
	// "OPERATOR_ACCOUND_ID_DESC":'运营者用于识别账户的ID',
	// "RATE_PLAN_VERSION_ID_KEY":'费率计划版本ID',
	// "RATE_PLAN_VERSION_ID_DESC":'费率计划的具体版本，
	// 目前派作账单用途",

	// 汇总用量 - 键与描述
	// "ACCOUNT_ID_KEY"
	// "ACCOUNT_ID_DESC
	// "BILLING_CYCLE_KEY" />,
	// "BILLING_CYCLE_DESC" />
	INVOICE_ID_KEY: '账单ID',
	INVOICE_ID_DESC: '收费适用的账单ID',
	RATE_PLAN_ID_KEY: '费率计划ID',
	RATE_PLAN_ID_DESC: '在报告周期末尾时指派给SIM卡的费率计划ID',
	RATING_ZONE_ID_KEY: '费率区间ID',
	RATING_ZONE_ID_DESC: '用量发生的费率区间（根据费率计划定义）',
	TOTAL_DATA_USAGE_CHARGES_KEY: '数据总用费',
	TOTAL_DATA_USAGE_CHARGES_DESC: '本费率区间中的数据总用费，超出订阅费中包括的任何汇总数据用量以外的部分',
	TOTAL_SMS_MO_USAGE_CHARGES_KEY: '移动端始发短信（SMS MO）总用费',
	TOTAL_SMS_MO_USAGE_CHARGES_DESC: '本费率区间中的移动端始发短信（SMS MO）总用费，超出订阅费中包括的任何汇总短信服务（SMS）用量以外的部分',
	TOTAL_SMS_MT_USAGE_CHARGES_KEY: '移动端终止短信（SMS MT）总用费',
	TOTAL_SMS_MT_USAGE_CHARGES_DESC: '本费率区间中的移动端终止短信（SMS MT）总用费，超出订阅费中包括的任何汇总短信服务（SMS）用量以外的部分',
	TOTAL_SMS_USAGE_CHARGES_KEY: '短信服务（SMS）总用费',
	TOTAL_SMS_USAGE_CHARGES_DESC: '本费率区间中的短信服务（SMS）总用费，超出订阅费中包括的任何汇总短信服务（SMS）用量以外的部分',
	TOTAL_VOICE_MO_USAGE_CHARGES_KEY: '语音移动始发（MO）总用费',
	TOTAL_VOICE_MO_USAGE_CHARGES_DESC: '本SIM卡在该费率区间中的移动始发（MO）语音总用费，超出订阅费中包括的任何语音用量以外的部分',
	TOTAL_VOICE_MT_USAGE_CHARGES_KEY: '语音移动终端（MT）总用费',
	TOTAL_VOICE_MT_USAGE_CHARGES_DESC: '本SIM卡在该费率区间中的移动终端（MT）语音总用费，超出订阅费中包括的任何语音用量以外的部分',
	TOTAL_USAGE_CHARGES_KEY: '总用费',
	TOTAL_USAGE_CHARGES_DESC: '本费率区间中的总用费，超出订阅费中包括的任何汇总用量以外的部分',

	// USAGE SUMMARY - KEYS AND DESC
	// "ACCOUNT_ID_KEY"
	// "ACCOUNT_ID_DESC"
	OPERATOR_ID_KEY: '运营者ID',
	OPERATOR_ID_DESC: 'Jasper运营者ID号码',
	// "BILLING_CYCLE_KEY"
	// "BILLING_CYCLE_DESC"
	// "ICCID_KEY"
	// "ICCID_DESC"
	// "IMSI_KEY"
	// "IMSI_DESC"
	// "MSISDN_KEY"
	// "MSISDN_DESC"
	TOTAL_DATA_USAGE_KEY: '数据总用量',
	TOTAL_DATA_USAGE_DESC: '本设备在账单周期内的数据总用量',
	TOTAL_VOICE_USAGE_KEY: '语音总用量',
	TOTAL_VOICE_USAGE_DESC: '本设备在账单周期内的语音总用量',
	TOTAL_SMS_USAGE_KEY: '短信服务（SMS）总用量',
	TOTAL_SMS_USAGE_DESC: '本设备在账单周期内的短信服务（SMS）总用量',
	TOTAL_CSD_USAGE_KEY: 'CSD总用量',
	TOTAL_CSD_USAGE_DESC: '本设备在账单周期内的CSD总用量',
	RESERVED_FIELD_KEY: '保留的字段',
	RESERVED_FIELD_DESC: '保留，供未来使用',
	DEVICE_ID_KEY: '设备ID',
	DEVICE_ID_DESC: '供运营者使用的定制字段',
	MODEM_ID_KEY: '调制解调器ID',
	MODEM_ID_DESC: '供运营者使用的定制字段',
	END_CONSUMER_ID_KEY: '终端消费者ID',
	END_CONSUMER_ID_DESC: '终端消费者识别符',
	USAGE_CUSTOMER_KEY: '客户',
	USAGE_CUSTOMER_DESC: '客户名称',
	CUSTOM1_KEY: '定制1',
	CUSTOM1_DEVICE_DESC: '设备级别定制字段1',
	CUSTOM2_KEY: '定制2',
	CUSTOM2_DEVICE_DESC: '设备级别定制字段1',
	CUSTOM3_KEY: '定制3',
	CUSTOM3_DEVICE_DESC: '设备级别定制字段1',

	// 订户快照 - 键与描述
	SUBSCRIBER_SIM_STATE_KEY: 'SIM卡状态',
	SUBSCRIBER_SIM_STATE_DESC: '报告阶段末尾时的SIM卡状态',
	// "RATE_PLAN_ID_KEY"
	SUBSCRIBER_RATE_PLAN_ID_DESC: '报告阶段末尾时指派给SIM卡的费率计划',
	SUBSCRIBER_CUSTOMER_KEY: '客户',
	SUBSCRIBER_CUSTOMER_DESC: '报告阶段末尾时指派给SIM卡的客户',
	// "DEVICE_ID_KEY",
	SUBSCRIBER_DEVICE_ID_DESC: '报告阶段末尾时的值',
	// "MODEM_ID_KEY"

	SUBSCRIBER_MODEM_ID_DESC: '在报告周期末尾时的值',
	COMMUNICATION_PLAN_ID_KEY: '通讯计划ID',
	COMMUNICATION_PLAN_ID_DESC: '报告阶段末尾时的值',
	INITIAL_ACTIVATION_DATE_KEY: '初始激活日期',
	INITIAL_ACTIVATION_DATE_DESC: '当前账户中的SIM卡首次被激活的日期和时间',
	CUSTOM_SIM_DESC: '可供账户使用的SIM卡级别定制字段',
	LINE_PAYMENT_STATUS_KEY: '线路付款状态',
	LINE_PAYMENT_STATUS_DESC: '可能的值包括当前、暂停、已取消、最终取消',
	OPERATOR_ACCOUNT_ID_KEY: '运营者账户ID',
	OPERATOR_ACCOUNT_ID_DESC: '运营者用于识别账户的ID',
	CUSTOM4_KEY: '定制4',
	CUSTOM5_KEY: '定制5',
	CUSTOM6_KEY: '定制6',
	CUSTOM7_KEY: '定制7',
	CUSTOM8_KEY: '定制8',
	CUSTOM9_KEY: '定制9',
	CUSTOM10_KEY: '定制10',

	ICCID_DESC_SHORT2: '设备集成线路卡识别符（ICCID）',
	IMSI_DESC_SHORT2: '设备IMSI',
	RATE_PLAN_KEY: 'Rateplan',
	RATE_PLAN_DESC: 'EOD plan_code， 控制中心ICCID留白',
	SIM_STATE_KEY2: 'Simstate',
	SIM_STATE_DESC2: '如有，则为使用中、未使用等',
	BEGBILLINGCYCLEDATE_KEY: 'Begbillcycledate',
	BEGBILLINGCYCLEDATE_DESC: '如是EOD，则为账单周期起始日期；如是控制中心，则留白',
	ENDBILLINGCYCLEDATE_KEY: 'Begbillcycledate',
	ENDBILLINGCYCLEDATE_DESC: '如是EOD，则为账单周期终止日期；如是控制中心，则留白',
	TOTALDATAUSAGE_KEY: 'Totaldatausage',
	TOTALDATAUSAGE_DESC: '阶段数据用量（单位为KB）',
	TOTALINTERNATIONALUSAGE_KEY: 'Totalinternationalusage',
	TOTALINTERNATIONALUSAGE_DESC: '如是EOD，则为阶段国际数据用量（单位为KB）；如是控制中心，则留白',
	CUSTOMERNAME_KEY: 'Customername',
	CUSTOMERNAME_DESC: '客户名称',
	CUSTOMFIELD1_KEY: 'Customfield1',
	CUSTOMERNAME1_DESC: '由EOD和控制中心填充',
	CUSTOMFIELD2_KEY: 'Customfield2',
	CUSTOMERNAME2_DESC: '由EOD和控制中心填写',
	CUSTOMFIELD3_KEY: 'Customfield3',
	CUSTOMERNAME3_DESC: '由EOD和控制中心填写',
	CUSTOMFIELD4_KEY: 'Customfield4',
	CUSTOMERNAME4_DESC: 'EOD或控制中心DeviceID',
	CUSTOMFIELD5_KEY: 'Customfield5',
	CUSTOMERNAME5_DESC: 'EOD或控制中心ModemID',
	M2MPLATFORM_KEY: 'M2MPlatform',
	M2MPLATFORM_DESC: '“EOD”或“控制中心”',
	BILLINGCYCLE_KEY: 'Billingcycle',
	BILLINGCYCLE_DESC: '需要填充的控制中心JPO字段',
	CUSTOMERID_KEY: 'Customerid',
	CUSTOMERID_DESC: '如是EOD，则为客户前缀；如是控制中心，则为客户ID。',
	PROVISIONINGACTIVITY_KEY: 'ProvisioningActivity ',
	PROVISIONINGACTIVITY_DESC: '值为下列之一：“activation”（激活）、“inactive”（停用）、“terminate”（终止）、“changeNetworkEntitlement”（更改网络权限）、“changeRatePlan”（更改费率计划）、“updateCustomFields”（更新定制字段）',
	COUNT_KEY: 'Count',
	COUNT_DESC: '调用配给活动的次数',
	SUCCESS_KEY: 'Success',
	SUCCESS_DESC: '成功调用配给活动的次数',
	FAILURE_KEY: 'Failure',
	FAILURE_DESC: '调用配给活动失败的次数',
	AVGTIMEMS_KEY: 'AvgTimeMs',
	AVGTIMEMS_DESC: '配给调用的平均耗时（单位为毫秒）',
	LONGESTTIMEMS_KEY: 'LongestTimeMs',
	LONGESTTIMEMS_DESC: '调用配给活动花费的最长时间（单位为毫秒）',
	SHORTESTTIMEMS_KEY: 'ShortestTimeMs',
	SHORTESTTIMEMS_DESC: '调用配给活动花费的最短时间（单位为毫秒）',

	REPORT_SOURCE_DESC: '请选择报告来源，以查看各种定义',

	SIM_SWAP_SUCCESSFUL: '切换SIM卡成功',
	SIM_SWAP_IN_PROGRESS: 'SIM卡切换正在处理中',
	REFRESH_PENDING_STATUS: '刷新端点，以检查状态是否更改',
	TEMPLATE_NOT_AVAILABLE: '模板不可用。',
	CUSTOM_FIELDS_UPDATE_SUCCESSFUL: '定制字段更新成功',
	CUSTOM_FIELDS_UPDATE_IN_PROGRESS: '定制字段更新正在处理中',
	IOT_APPS: 'IoT应用程序',
	INVOICES_DATE: '账单日期',
	PLATFORM: '平台',
	FREQUENCY: '频率',
	M2M_ACC_ID: '机对机（M2M）账户ID',
	REPORT: '报告',

	// 载入
	ADD_NEW_PLATFORM: '添加新平台',
	BUSINESS_NAME: '业务名称',
	ADMINISTRATOR: '管理员',
	MCC: '移动国家代码（MCC）',
	MNC: 'IoT Console (IOT-C)',
	OPERATOR: '运营者',
	POD: 'POD',
	RATE_PLANS: '费率计划',
	RATE_PLAN_NAME: '费率计划名称',
	RATE_PLAN_ID: '费率计划',
	RATE_PLAN_ID_PLAN: '费率计划ID',
	COMMUNICATION_PLANE_NAME: '通讯计划名称',
	ADD_ANOTHER_RATE_PLAN: '添加另一项费率计划',
	ADD_ANOTHER_COMMUNICATION_PLAN: '添加另一项通讯计划',
	ADD_ANOTHER_PROFILE_NAME: '添加另一个概况名称',
	SIM_STATE_MAPPING: 'SIM卡状态映射',
	ADD_RATE_PLAN: '添加费率计划',
	ACC_RULE: 'ACC规则',
	MC_RULE: 'IOT-C规则',
	WHAT_IS_THIS_TITLE: '这是什么？',
	PLAN_PROFILES: '计划概况',
	COMPANY: '公司',
	COMPANY_NAME: '公司名称',
	COMPANY_DOMAIN: '公司域名',
	PHONE_NUMBER: '电话号码',
	CITY: '市',
	CITY_TOWN: '市/镇',
	STATE_PROVINCE: '州/省',
	COUNTRY: '国家',
	ZIP_CODE: '邮政编码',
	ZIP_POSTAL_CODE: '邮政编码',
	STREET: '街道',
	STREET_ADDRESS: '街道地址',
	LAST_NAME: '姓',
	FIRST_NAME: '名',
	ACTIONS: '行动',
	PLEASE_SELECT_COUPLE_OF_SIMS: '请选择几个SIM卡。',
	COMPANY_IDENTITY: '公司身份',
	ADD_USERS_OPTIONAL: '添加用户（选填）',
	REVIEW_AND_CREATE: '审核并创建',
	PROJECTS_LIST: '项目清单',
	MULTI_NETWORK_CONNECT_ONBOARDING_M2M_PLATFORMS: 'IoT Console载入 - M2M平台',
	MULTI_NETWORK_CONNECT: 'IoT Console',
	CUSTOMERS: '客户',
	COMPANY_EMAIL_ADDRESS: '公司电子邮件地址',
	CONTACT_EMAIL_ADDRESS: '联系人电子邮件地址',
	CONTACT_PERSON_FULL_NAME: '联系人全名',
	CONTACT_PERSON: '联系人',
	ASSIGN_ANOTHER_PLATFORM: '指派另一个平台',
	USER_INFO: '用户信息',
	SELECT_USER_TYPE: '选择用户类型',
	USER_TYPE: '用户类型',
	USER_NOTE: '用户将收到创建账户的电子邮件邀请函',
	ADD_USER: '添加用户',
	ADD_ANOTHER_USER: '添加另一个用户',
	WHAT_IS_THIS_MESSAGE1: '必须先将客户载入机对机（M2M）平台，然后才能创建IoT Console账户。一旦获得账户ID即可继续。',
	WHAT_IS_THIS_MESSAGE2: '可通过填写此表单，自动添加使用IoT数据计划或IoT入门套装的小型企业客户和初创企业。创建账户后，请返回此页，为客户启用访问IoT Console门户网站的权限。',
	SELECT_PLATFORM: '选择平台',
	EMAIL_EXISTS_OTHER: '此电子邮件地址已经存在（其他用户）',
	EMAIL_EXISTS_ADMIN: '此电子邮件地址已经存在（管理员）',
	YOU_HAVE_SUCCESSFULLY_CREATED_A_MULTI_NETWORK_CONNECT_ACCOUNT_FOR: '您已经成功创建了IoT Console账户：',
	THE_IOT_GATEWAY_NUMBER_ASSOCIATED_WITH_THIS_ACCOUNT: '与该账户关联的IoT网关号码为：',
	COMMUNICATION_PROFILE_NAME: '通讯概况名称',
	ADD_NETWORK_ENTITLEMENT: '添加网络权限',
	EDIT_NETWORK_ENTITLEMENT: '编辑网络权限',
	PLATFORMS: '平台',
	NO_NETWORK_ENTITLEMENTS: '无网络权限',
	NO_RATE_PLANS: '无费率计划',
	EDIT_RATE_PLAN: '编辑费率计划',
	ERROR_WHILE_ADDING_NETWORK_ENTITLEMENT: '添加网络权限时出错',
	ERROR_WHILE_EDITING_NETWORK_ENTITLEMENT: '编辑网络权限时出错',
	ERROR_WHILE_ADDING_RATE_PLAN: '添加费率计划时出错',
	ERROR_WHILE_EDITING_RATE_PLAN: '编辑费率计划时出错',
	ERROR_WHILE_ADDING_COMPANY: '添加公司时出错',
	ERROR_WHILE_EDITING_COMPANY: '编辑公司时出错。',
	ERROR_WHILE_ADDING_EDITING_USER: '添加/编辑用户时出错',
	ERROR_WHILE_ADDING_EDITING_PLATFORM: '添加/编辑平台时出错',
	ERROR_WHILE_ADDING_PLATFORM: '添加平台时出错',
	ADD_PROFILE_NAME: '添加概况名称',
	ADD_PROFILE_ID: '添加概况ID',
	EDIT_PLATFORM: '编辑平台',
	SIM_STATE_CHANGE_IS_SUCCESSFULLY_SCHEDULED: '已成功安排SIM卡状态更改！',
	SIM_STATE_CHANGE_IS_SUCCESSFULLY_SCHEDULED_FOR: '已成功安排SIM卡状态更改',
	SIM_STATE_CHANGE_IS_SUCCESSFULLY_SCHEDULED_ERROR: '无法成功地安排SIM卡状态更改！',
	SIM_STATE_CHANGED_TO: '您已经选择将SIM卡状态更改为',
	CHANGE_LATER: '您也可以安排稍后再更改。',
	SCHEDULED_CHANGE_WILL_HAPPEN: '安排的更改将在您选择的日期开始时执行。',
	TAP_INITIATE_CHANGE: '点按“开始更改”即可立即开始行动。',
	SIM_STATE_CHANGE: 'SIM卡状态更改',
	SCHEDULE_SIM_STATE_CHANGE: '安排SIM卡状态更改',
	SYSTEM_CONFIRMATION: '系统确认',
	CHOOSE_NETWORK_ENTITLEMENT: '选择网络权限',
	HISTORY: '批处理历史记录',

	serviceProvider: '平台',
	reportFrequency: '频率',

	// 载入
	ENDPOINT_SESSION_NO_RECORD: '选择的阶段没有数据。',
	TIME_START: '时间开始',
	TIME_END: '时间终止',
	SESSION_DETAILS: '会话详情',
	CARRIER: '运营商',
	DEVICE_IP: '设备IP',

	// 图表数据集
	inventoryByStatus: '按状态显示库存',
	inventoryByOperator: '按运营者显示库存',
	inventoryByCountry: '按国家显示库存',
	inventoryByStatusDescription: '这将显示使用中和未使用的端点数量。',
	inventoryByOperatorDescription: '这将显示运营者以及每个运营者的端点数量。',
	inventoryByCountryDescription: '这将显示国家名称以及在该国家运营的端点数量。',
	// 提示模式
	addPromptHeaderInfo: '要应用更改吗？',
	addChartPromptHeader: '仪表板更改尚未保存',
	addChartPromptFirst: '如果您希望保存对仪表板视图所作的更改，请点按“保存仪表板”，否则可点按“取消”回到前一个视图。',
	addChartPromptNotification: '要应用更改吗？',

	// 图表描述
	DASHBOARD_CHART_countByState_SATELLITEDescription: '显示多个机对机（M2M）平台上卫星端点（设备）的状态详情',
	DASHBOARD_CHART_countByState_CELLULARDescription: '显示多个机对机（M2M）平台上蜂窝端点（设备）的状态详情',
	DASHBOARD_CHART_countByNetworkCarrier_SATELLITEDescription: '显示多个网络运营商卫星端点（设备）的百分比分布',
	DASHBOARD_CHART_countByNetworkCarrier_CELLULARDescription: '显示多个网络运营商蜂窝端点（设备）的百分比分布',
	DASHBOARD_CHART_countByTechnologyDescription: '显示账户中蜂窝和卫星端点（设备）按百分比列出的总数明细',
	DASHBOARD_CHART_countByM2mPlatform_SATELLITEDescription: '显示多个机对机（M2M）平台上卫星端点（设备）按百分比显示的总数明细',
	DASHBOARD_CHART_countByM2mPlatform_CELLULARDescription: '显示多个机对机（M2M）平台上蜂窝端点（设备）按百分比显示的总数明细',
	DASHBOARD_CHART_countByIMSI_SATELLITEDescription: '显示多个机对机（M2M）平台上蜂窝端点（设备）按百分比显示的总数明细',
	DASHBOARD_CHART_countByIMSI_CELLULARDescription: '显示多个机对机（M2M）平台上卫星端点（设备）按百分比显示的总数明细',
	'DASHBOARD_CHART_IMSI Transfer Status_CELLULARDescription': '显示两个机对机（M2M）平台之间按百分比显示的转让（Re-IMSI）状态总数。',
	DASHBOARD_CHART_countByIMSITransferStatus_CELLULARDescription: '显示两个机对机（M2M）平台之间按百分比显示的转让（Re-IMSI）状态总数。',
	DASHBOARD_CHART_countByIMSITransferStatus_SATELLITEDescription: '显示两个机对机（M2M）平台之间按百分比显示的转让（Re-IMSI）状态总数。',
	DASHBOARD_CHART_summaryOfMonthlyEndpointUsageDescription: '显示蜂窝和卫星端点（设备）的每月数据用量总结',
	DASHBOARD_CHART_countByStatebyPlatformACC_CELLULARDescription: '显示ACC平台上蜂窝端点（设备）的状态详情',
	DASHBOARD_CHART_countByStatebyPlatformEOD_CELLULARDescription: '显示EOD平台上蜂窝端点（设备）的状态详情',
	DASHBOARD_CHART_countByStatebyPlatformGBCM_SATELLITEDescription: '显示GBCM平台上卫星端点（设备）的状态详情',
	DASHBOARD_CHART_countByStatebyPlatformDCP_CELLULARDescription: '显示设备连接平台（DCP）上蜂窝端点（设备）的状态详情',
	DASHBOARD_CHART_countByStatebyPlatformCCIP_CELLULARDescription: '显示CCIP平台上蜂窝端点（设备）的状态详情',
	DASHBOARD_CHART_countByStatebyPlatformVCC_CELLULARDescription: '显示VCC平台上蜂窝端点（设备）的状态详情',
	DASHBOARD_CHART_countByStatebyPlatformGDSP_CELLULARDescription: '显示GDSP平台上蜂窝端点（设备）的状态详情',
	DASHBOARD_CHART_countByStatebyPlatformWING_CELLULARDescription: '显示WING平台上蜂窝端点（设备）的状态详情',
	DASHBOARD_CHART_summaryOfMonthlyEndpointUsage_CELLULARDescription: '显示蜂窝和卫星端点（设备）的每月数据用量总结',
	DASHBOARD_CHART_countByProfile_CELLULARDescription: '显示多个概况中蜂窝端点（设备）的状态详情',

	// 饼图定制工具提示ID
	PIE_CHART_TOOLTIP_Active: '已激活',
	PIE_CHART_TOOLTIP_Test: '测试',
	PIE_CHART_TOOLTIP_Inactive: '未使用',
	PIE_CHART_TOOLTIP_Terminate: '已终结',

	// Globecomm
	DEVICE_MODULE_NUMBER: '设备模块号码',
	DEVICE_MANUFACTURER: '设备制造商',
	DEVICE_STATUS: '设备状态',
	DEVICE_NETWORK_TYPE: '设备网络类型',
	MODULE_IMEI: '模块IMEI',
	SIM_STATUS: 'SIM卡状态',
	MODULE_STATUS: '模块状态',
	TRESHOLD_OF_NOTIFICATION_BY_DATA_USAGE: '按数据用量设置的通知阈值',
	TRESHOLD_OF_SUSPENSION_BY_DATA_USAGE: '按数据用量设置的暂停阈值',
	NOTIFICATION_INTERVAL: '通知间隔',
	DATA_USAGE: '周期至今数据用量',
	MANUFACTURER: '制造商',
	MODEL_NUMBER: '型号',
	TYPE: '类型',
	NETWORK_NAME: '网络名称',
	SUPPORTED_NETWORKS: '受支持的网络',
	RENEWAL_PERIOD: '续约期限',
	ACTIVATION_FEE: '激活费',
	RECCURING_FEE: '重复产生的费用',
	OVERAGE_FEE: '超额费',
	INCLUDED_BYTES: '包含的字节',
	MINIMUM_BILLABLE_BYTES: '可计费的最低字节数',
	NOTIFICATION_CONTACT: '通知联系人',
	NOTIFICATION_BYTE_CAP: '通知字节上限',
	NOTIFICATION_REFRESH: '通知刷新',
	OBSOLETE: '过期',
	HARDWARE: '硬件',
	SPECIFICATIONS: '规格',
	SPECIFICATION: '规格',
	MODULE: '模块',
	NO_HISTORY_DATA: '没有此端点的历史记录',

	// 仪表板默认消息
	NO_GRAPHS: '添加的图将在此显示',
	dashboardDefault: '添加的图将在此显示',
	REMOVE_CHART: '移除图',
	CARD_DROPDOWN_TITLE: '图类型',
	ADD_WIDGET_BUTTON: '添加图',
	ADD_WIDGET_BUTTON_MOBILE: '添加',

	APP_NAME: 'IoT Console',
	NETWORK_TYPE_CHECK: '抱歉，不允许对来自不同网络类型或服务提供商的端点执行多个端点状态更改。',
	SERVICE_PROVIDER_CHECK: '抱歉，不允许对来自多个平台或具有不同网权限的端点执行多个端点更改。',

	// USER FEEDBACK MODAL
	feedbackTitle: '用户反馈',
	feedbackPageLabel: '您的反馈是关于',
	feedbackFeedLabel: '请用您自己的话告诉我们应当如何改善？',
	feedbackFeedPlaceholder: '在此处填写您的反馈……',
	feedbackContactLabel: '我们可以在需要时就此事与您联系吗？',
	feedbackEmailLabel: '电子邮件',
	feedbackPhoneLabel: '电话',
	feedbackMetaLabel: '元数据',
	feedbackCancelButton: '取消',
	feedbackSendButton: '发送反馈',
	feedbackSuccessThanks: '感谢您的反馈。',
	feedbackSuccessMessage: '您的反馈会让我们的产品更加完善。我们会认真评估您的意见，并采取相应行动。',
	feedbackSuccessMessageSub: '我们也可能会与您联系，详细探讨您的建议。',
	feedbackSuccessMessageSign: 'IoT Console团队',
	feedbackSuccessDoneButton: '完成',
	feedbackTriggerTitle: '有反馈意见吗？',
	feedbackTriggerSubtitle: '请在此处给我们留言',
	feedbackThankYou: '感谢您{user}参与用户反馈。您的意见将帮助我们改善产品。',
	feedbackSelect: '您必须在上面的列表中选择相关主题。',
	feedbackEnter: '您必须输入反馈内容。',
	feedbackIcon: '成功提交反馈图标',
	feedbackAdditionalFile: '更多文件',
	feedbackFileUploadFail: '上载失败',
	feedbackMinLength: '请至少输入10个字符。',
	FEEDBACK_FILE_UPLOAD_EXTENSIONS: '只允许上载扩展名为.jpg或.png的文件',
	// 更改标签页

	FIELD_NAME: '字段名称',

	DELETE_TAB: '删除标签',
	TABLE_NAME: '表格名称',
	EDIT_TABLE: '编辑表格参数',
	CUSTOM_FIELD_LABEL: '定制字段标签',
	EDIT_TABLE_VIEW_NAME: '编辑表格视图名称',
	PLEASE_ENTER_TABLE_NAME: '请输入表格名称',
	DEVICE_TYPE: '设备类型',
	CELLULAR_COLUMNS: '蜂窝特定参数',
	SATELLITE_SPECIFIC_COLUMNS: '卫星特定参数',

	// TICKETING
	TICKET_ID: '通知单ID',
	CATEGORY_NAME: '类别',
	CATEGORY_TICKETING: '类别',
	SUBCATEGORY_OPTIONAL: '子类（选填）',
	SELECT_SUBCATEGORY_OPTIONAL: '选择子类（选填）',
	ROAMING_ISSUE: '漫游问题',
	SEVERITY: '严重性',
	PRIORITY: '优先级',
	ACCOUNT_NUMBER: '账号',
	CREATED_BY_TICKETING: '创建者',
	MULTI_NETWORK_CONNECT_ID: 'IoT Console ID',
	ENDPOINT_MODEL: '端点型号',
	WHAT_IS_THE_ERROR_MESSAGE: '报错讯息是什么？',
	HOW_MANY_BARS_ARE_SHOWING: '显示多少个条形图？',
	DESCRIBE_ENDPOINT_STATE_HERE: '请在此处描述端点状态……',
	WAS_THE_TROUBLESHOOTING_DONE: '进行过故障排除吗?',
	DESCRIBE_TROUBLESHOOTING_DETAILS_HERE: '请在此处描述故障排除详情……',
	DO_YOU_HAVE_ANY_ADDITIONAL_NOTES: '您还有任何其他的注释吗？',
	ADDITIONAL_NOTES: '其他的注释',
	DASHBOARD_CHART_M2MSIMStatus_SATELLITE: '按IoT-C状态（卫星）显示端点',
	DASHBOARD_CHART_M2MSIMStatus_CELLULAR: '按IoT-C状态（蜂窝）显示端点',
	DASHBOARD_CHART_Country_SATELLITE: '按当前地点（卫星）显示端点',
	DASHBOARD_CHART_Countrys_CELLULAR: '按当前地点（蜂窝）显示端点',
	DASHBOARD_CHART_IMSI_SATELLITE: '按机对机（M2M）平台（卫星）显示端点',
	DASHBOARD_CHART_IMSI_CELLULAR: '按机对机（M2M）平台（蜂窝）显示端点',
	DASHBOARD_CHART_Network_SATELLITE: '按网络运营商（卫星）显示端点',
	DASHBOARD_CHART_Network_CELLULAR: '按网络运营商（蜂窝）显示端点',
	DASHBOARD_CHART_Technology: '按技术显示端点',
	'DASHBOARD_CHART_IMSI Transfer Status_SATELLITE': '按转让状态（卫星）显示端点',
	'DASHBOARD_CHART_IMSI Transfer Status_CELLULAR': '按转让状态（蜂窝）显示端点',
	DASHBOARD_CHART_countByIMSITransferStatus_SATELLITE: '按转让状态（卫星）显示端点',
	DASHBOARD_CHART_countByIMSITransferStatus_CELLULAR: '按转让状态（蜂窝）显示端点',
	DASHBOARD_CHART_summaryOfMonthlyEndpointUsage_CELLULAR: '所有ACC端点的数据总用量',
	DASHBOARD_CHART_countByState_SATELLITE: '按状态（卫星）显示端点',
	DASHBOARD_CHART_countByState_CELLULAR: '按状态（蜂窝）显示端点',
	DASHBOARD_CHART_countByServiceType_SATELLITE: '按技术（卫星）显示端点',
	DASHBOARD_CHART_countByServiceType_CELLULAR: '按技术（蜂窝）显示端点',
	DASHBOARD_CHART_countByRatePlan_SATELLITE: '按费率计划（卫星）显示端点',
	DASHBOARD_CHART_countByRatePlan_CELLULAR: '按费率计划（蜂窝）显示端点',
	DASHBOARD_CHART_countByNetworkEntitlement_SATELLITE: '按网络权限（卫星）显示端点',
	DASHBOARD_CHART_countByNetworkEntitlement_CELLULAR: '按网络权限（蜂窝）显示端点',
	DASHBOARD_CHART_countByNetworkCarrier_SATELLITE: '按网络运营商（卫星）显示端点',
	DASHBOARD_CHART_countByNetworkCarrier_CELLULAR: '按网络运营商（蜂窝）显示端点',
	DASHBOARD_CHART_countByTechnology: '按技术显示端点',
	DASHBOARD_CHART_countByM2MPlatform_SATELLITE: '按机对机（M2M）平台（卫星）显示端点',
	DASHBOARD_CHART_countByM2MPlatform_CELLULAR: '按机对机（M2M）平台（蜂窝）显示端点',
	DASHBOARD_CHART_countByIMSI_SATELLITE: '按机对机（M2M）平台显示端点',
	DASHBOARD_CHART_countByIMSI_CELLULAR: '按机对机（M2M）平台显示端点',
	DASHBOARD_CHART_countByM2mPlatform_SATELLITE: '按机对机（M2M）平台显示端点',
	DASHBOARD_CHART_countByM2mPlatform_CELLULAR: '按机对机（M2M）平台显示端点',
	DASHBOARD_CHART_countByStatebyPlatformACC_CELLULAR: '按状态（ACC）显示端点',
	DASHBOARD_CHART_countByStatebyPlatformEOD_CELLULAR: '按状态（EOD）显示端点',
	DASHBOARD_CHART_countByStatebyPlatformGBCM_SATELLITE: '按状态（GBCM）显示端点',
	DASHBOARD_CHART_countByStatebyPlatformDCP_CELLULAR: '按状态（DCP）显示端点',
	DASHBOARD_CHART_countByStatebyPlatformCCIP_CELLULAR: '按状态（CCIP）显示端点',
	DASHBOARD_CHART_countByStatebyPlatformVCC_CELLULAR: '按状态（VCC）显示端点',
	DASHBOARD_CHART_countByStatebyPlatformGDSP_CELLULAR: '按状态（GDSP）显示端点',
	DASHBOARD_CHART_countByStatebyPlatformWING_CELLULAR: '按状态（WING）显示端点',
	DASHBOARD_CHART_countByProfile_CELLULAR: '按概况显示端点',
	TICKET_STATUS: '通知单状态',
	ENDPOINT_LOCATION: '端点地点',
	ISSUE: '问题',
	SUGGESTIONS: '建议',
	PHONE: '电话',
	FAILED_TO_PROVISION: '配给失败',
	SUBMITTED_BY: '提交者',
	NEW_TICKET: '新通知单',
	ENDPOINT: '端点',
	ERROR_MESSAGE: '报错讯息',
	ATTACHED_FILE: '附件',
	TICKETING: '客服工单',
	GET_HELP_WITH_THIS_ENDPOINT: '为此端点获得帮助',
	CATEGORY_AND_SUGGESTIONS: '类别与建议',
	CONFIRMATION: '确认',
	RECORDED_STATUS: '记录的状态',
	ENDPOINT_NAME: '端点ID',
	CONTACT_PHONE: '联系人电话',
	CONTACT_EMAIL: '联系人电子邮件',
	SELECT_SUBCATEGORY: '选择子类',
	FILE_UPLOADED: '文件已上载',
	FAQ_SUPPORT_INFO: '浏览用户常见问题，快速找到答案。',
	USER_GUIDE_SUPPORT_INFO: '阅读关于门户网站功能的逐步详细说明。本指南专门针对您的账户类型而编写。',
	TICKETING_SUPPORT_INFO: '如果您遇到服务问题，且诊断测试无法向您提供解决途径，您可以创建一份故障通知单。',
	MUST_BE: '必须',
	LESS_THAN: '小于',
	CHARACTERS: ' 个字符',
	LOADING_DATA: '正在载入数据',
	GPRS: '通用分组无线业务（GPRS）',

	//  支持
	CONTENT: '内容',
	CREATE_NEW_NOTIFICATION: '创建新通知',
	EDIT_NOTIFICATION: '编辑通知',
	EDIT_FORM_TO_CREATE_NOTIFICATION: '编辑创建新通知的表单。',
	NOTIFICATION_TYPE: '通知类型？',
	SELECT_NOTIFICATION_PLACEHOLDER: '选择通知占位符',
	CHOOSE_WHICH_USER_GROUPS_TO_NOTIFY: '选择需要通知的用户群组：',
	NOTIFY_ALL_GROUPS: '通知所有群组',
	MANAGER: '经理',
	DEVELOPER: '开发者',
	ADD_NOTIFICATION_CONTENT: '添加通知内容',
	SUSPEND: '暂停',
	SUSPENDED: '已暂停',
	DEACTIVE: '停用',
	PROVISIONED: '已配给',
	ENDPOINT_COUNTRY: '端点所在国家',
	ENDPOINT_STATE: '端点所在州',
	ENDPOINT_CITY: '端点所在城市',
	ENDPOINT_STREET_ADDRESS: '端点所在街道地址',
	AS_OF: '处理时间',
	VIEW_ALL_NOTIFICATIONS: '查看全部通知',
	NOTIFICATION_SCOPE: '通知范围',
	NOTIFICATION1: '信息',
	NOTIFICATION2: '错误',
	NOTIFICATION3: '警告',
	NOTIFICATION4: '确认',
	GLOBAL_NOTIFICATION: '全球通知',
	COMPANY_RELATED_NOTIFICATION: '与公司相关的通知',
	MUST_BE_MORE_THAN: '必须大于 ',
	MUST_BE_LESS_THAN: '必须小于 ',
	MUST_BE_LESS_THAN_OR_EQUAL_TO: '必须小于或等于 ',
	GLOBAL: '全球',
	COMPANY_RELATED: '与公司相关',
	RECENT_NOTIFICATIONS: '系统警示与通知',

	networkEntitlement: '网络权限ID',
	networkType: '网络类型',
	transferStatus: 'IMSI转让状态',
	rateplan: '费率计划',
	FAILURE: '故障',
	PENDING_REIMSI_ATTEMPT: '待决Re-IMSI尝试',
	REIMSI_QUEUED: 'Re-IMSI已排队',
	PENDING_REIMSI_DELIVERED: '待决Re-IMSI已发送',
	'PENDING_RE-IMSI_DELIVERY_FAILED': '待决Re-IMSI发送失败',

	'PENDING REIMSI ATTEMPT': '待决Re-IMSI尝试',
	'REIMSI PENDING ATTEMPT': '待决Re-IMSI尝试',
	'PENDING REIMSI DELIVERED': '待决转让已发送',
	'Re-IMSI Delivery Failed': '转让发送失败',
	'REIMSI FAILED': 'Re-IMSI失败',
	'REIMSI QUEUED': 'Re-IMSI已排队',
	'REIMSI PEDING ATEMPT': '待决Re-IMSI尝试',
	'REIMSI PENDING ATEMPT': '待决Re-IMSI尝试',
	'REIMSI PENDING DELIVERED': '待决Re-IMSI已发送',
	'REIMSI SUCCESS': 'Re-IMSI成功',
	failure: '故障',

	'ID_PENDING REIMSI ATTEMPT': '待决Re-IMSI尝试',
	'ID_REIMSI PENDING ATTEMPT': '待决Re-IMSI尝试',
	'ID_PENDING REIMSI DELIVERED': '待决转让已发送',
	'ID_Re-IMSI Delivery Failed': '转让发送失败',
	'ID_REIMSI FAILED': 'Re-IMSI失败',
	'ID_REIMSI QUEUED': 'Re-IMSI已排队',
	'ID_REIMSI PEDING ATEMPT': '待决Re-IMSI尝试',
	'ID_REIMSI PENDING ATEMPT': '待决Re-IMSI尝试',
	'ID_REIMSI PENDING DELIVERED': '待决Re-IMSI已发送',
	'ID_REIMSI SUCCESS': 'Re-IMSI成功',

	CONSUMED_BYTES: '消费的数据量（MB）',
	CONSUMED_SMSES: '消费的短信服务（SMS）',
	TO_DATE: '至今',

	CHART_EXISTS_IN_SETTINGS: '您的设置中已有此图。只允许一种类型的图。',
	NO_CHART_DATA: '图数据缺失',
	STATES_MESSAGE: '没有可用的状态',
	SELECT_RATE_PLAN: '选择费率计划',
	CHANGE_RATE_PLAN: '更改费率计划',
	CURRENT_RATE_PLAN_FOR_ENDPOINT_ID: '端点ID当前的费率计划ID',
	IS: '是',
	CONFIRM_RATE_PLAN_CHANGE: '确认费率计划更改',
	RATE_PLAN_WILL_BE: '一旦开始此项更改，选择的端点费率计划将',
	WOULD_YOU_LIKE_TO_INITIATE_CHANGE_NOW: '您确定要开始更改吗？',
	RATE_PLAN_CHANGE_IS_SUCCESSFULLY_SCHEDULED: '已成功安排费率计划更改',
	FOR: '对象为',
	RATE_PLAN_CHANGE_IS_SUCCESSFULLY_UPDATED: ' 已成功更新费率计划更改',
	RATE_PLAN_CHANGE_COULD_NOT_BE_SCHEDULED_SUCCESSFULLY: '无法成功地安排费率计划更改',
	RATE_PLAN_CHANGE_COULD_NOT_BE_CARRIED_OUT_SUCCESSFULLY: '无法成功地执行费率计划更改',
	ENDPOINT_RATE_PLAN: '费率计划',
	DOWNLOAD_TEMPLATE_BATCH: '下载模板',
	CONFIRM_BATCH_FILE_UPLOAD: '确认批处理文件上载',
	SCHEDULED_ACTION_ON: '已安排行动于',
	FILE_MUST_BE_SUMITTED_WITH_CSV: '提交的文件扩展名必须为.csv。',
	MAXIMUM_SIMS_PER_UPLOAD: '每次上载的SIM卡数量上限为10,000。',
	SIMS_HAVE: 'SIM卡已经',
	CHANGE_FOR_ALL_SELECTED_SIMS: '选择不同的费率计划，应用于所有选择的SIM卡',
	YOU_HAVE_CHOSEN_TO_CHANGE_THE_RATE_PLAN_OF_THE: '您已经选择更改费率计划，',
	SELECTED_SIMS_TO: '即选择的SIM卡的费率计划，更改为',
	SIMS_RATE_PLAN_SUCCESSFULLY_REQUESTED: '已成功地上载SIM卡费率计划',
	SCHEDULE_RATE_PLAN_SUCCESSFUL: '已成功地为多张SIM卡安排费率计划',
	SIM_RATE_PLAN_CHANGE_FOR: 'SIM卡费率计划更改',
	MULTIPLE_RATE_PLAN_CHANGE_SCHEDULE_ERROR: '未能成功安排多张SIM卡的费率计划更改',
	MULTIPLE_RATE_PLAN_CHANGE_ERROR: '未能成功要求多张SIM卡的费率计划更改',
	SAME_RATE_PLAN_SELECTED: '选择了相同的费率计划',
	REASON: '原因',

	API_DOCUMENTATION: 'API文档',
	'Cellular - Active': '蜂窝 — 已激活',
	'Satellite - Active': '卫星 — 已激活',
	'Cellular - Terminate': '蜂窝 — 已终止',
	'Satellite - Terminate': '卫星 — 已终止',
	CHANGE_RATE_PLAN_NOT_ALLOWED: '不允许更改费率计划',
	reimsiqueued: 'Re-IMSI已排队',
	reimsifailed: 'Re-IMSI失败',
	reimsisuccess: 'Re-IMSI成功',
	reimsipendingattempt: '待决Re-IMSI尝试',
	reimsipendingdelivered: '待决Re-IMSI已发送',
	imsiPendingMessage: '如果设备处于待决状态超过72小时，请联系客户支持',
	ADD_ANOTHER_ACCOUNT: '添加另一个账户',
	FILTER_DOES_NOT_HAVE_VALID_DATA: 'API出错，请联系客户支持。',
	FAILOVER: '故障切换',
	CANCEL_PENDING_FAIL_TITLE: '取消待决更改失败',
	CANCEL_PENDING_FAIL_TEXT: '目前无法成功地取消待决更改',
	CANCEL_PENDING_SUCCESS_TITLE: '取消待决更改成功',
	CANCEL_PENDING_SUCCESS_TEXT: '已成功完成取消待决更改',
	CANCEL_CHANGE: '取消更改',
	CAUTION: '注意',
	CANCEL_PROMPT_TITLE: '您确定想要取消待决更改吗？',
	CANCEL_PROMPT_TEXT: '此行动不可逆。',
	CONFIRM_CANCELLATION: '确认取消',
	NO_RATE_PLANS_AVAILABLE: '无可用的费率计划',
	BULK_UPLOAD: '成批上载',
	MULTI_ENDPOINT_ACTION: '多个端点行动',
	PLEASE_SELECT_MORE_SIMSS: '请选择两个或多个端点',
	M2M_ACCOUNT_ID: '机对机（M2M）账户ID',
	REPORTS_PLATFORM: '机对机（M2M） 平台',
	ENDPOINT_CHART_SECOND_CARD: '最近会话',
	ENDPOINT_CHART_SECOND_CARD_ON: '于',
	ENDPOINT_CHART_THIRD_CARD: '端点网络地点',
	NO_AVAILABLE_FILTERS: '无可用的过滤器。',
	NO_AVAILABLE_FILTERS_SUBTITLE: '目前没有可供显示的过滤器',

	// 开发商API
	GETTING_STARTED: '入门指南',
	API_SANDBOX: 'API沙盒',
	DEVELOPER_API: '开发商API',
	GETTING_STARTED_WITH_API_T: 'API入门指南',
	GETTING_STARTED_WITH_API_P: '首次调用API之前，您的源IP地址（服务器）必须被列入白名单。配置源IP的步骤通常在新用户引导过程中、创建API访问时完成。如果您收到任何错误讯息，很可能与AT&T IoT网关访问受阻有关。',
	GETTING_STARTED_WITH_API_UL_T: '请先确保您已经完成下列步骤，再着手开发：',
	GETTING_STARTED_WITH_API_LI1: '- 您已经设置可公开访问的服务器，并在新用户引导过程中向我们提供该服务器的IP地址。',
	GETTING_STARTED_WITH_API_LI2: '- 您的源IP已经被列入白名单，可以访问AT&T IoT网关。',
	GETTING_STARTED_WITH_API_LI3: '- 贵公司的管理员已经向您提供了启用API服务时创建的API用户名和密码。',
	GETTING_STARTED_WITH_API_LI4: '- 您可以访问资源服务器、执行curl命令，或使用您偏好的语言（详见下文）编写的连接应用程序。',
	GET_OAUTH_TOKEN_T: '获取OAuth访问令牌',
	GET_OAUTH_TOKEN_P: '使用贵公司管理员提供的用户名和密码，通过AT&T IoT身份获取OAuth令牌。可根据您的偏好，选用下列方法。令牌有效期为7天。',
	GETTING_STARTED_WITH_API_CONNECTORS_T: 'API连接器',
	GETTING_STARTED_WITH_API_CONNECTORS_P: '在下列范例中选择您希望如何连接到我们的API。',
	TEST_CONNECTION_T: '测试IoT网关连接',
	TEST_CONNECTION_P: 'Curl命令范例：',
	GETTING_STARTED_WITH_API_NEXT_STEP_T: '希望得到的结果',
	GETTING_STARTED_WITH_API_NEXT_STEP_P: '如果您得到的HTTP响应代码是200 OK，您就可以开始开发了。',
	GETTING_STARTED_WITH_API_ERRORS_T: '故障排除连接错误',
	GETTING_STARTED_WITH_API_ERRORS_P: '如果您收到任何错误讯息，请首先查看您是否在HTTP头字段中正确设置了身份验证、访问令牌是否已过期需要更新。同时，请对AT&T IoT网关使用ping命令，检查您的源IP是否在白名单中。如果得到意外的结果，您也可以在用户支持部分提交故障排除通知单。',
	API: 'API',
	SANDBOX_SPECIFIES_THE_LIST: '下面的Swagger沙盒列出了可在REST API中使用的资源清单，以及可从这些资源调用的操作。本文档还列出了操作参数清单，包括参数的名称和类型、是必需的参数还是选择参数、以及这些参数可接受的值等信息。',
	JSON_SCHEMA_INCLUDES: '文档还包括JSON模式，描述向REST API操作发送请求时的正文结构，以及操作返回的任何响应正文结构。',

	GBCM_OPERATOR: 'Speedcast',
	ATT_OPERATOR: 'AT&T',

	CREATE_CUSTOM_REPORT: '创建定制报告',
	YOU_MUST_CREATE_FILTER_BEFORE_EXPORT: '在开始导出清单前，您必须先创建过滤器。',
	CREATE_REPORT: '创建报告',
	CHART_LEGEND_Active: '已激活',
	CHART_LEGEND_Terminate: '已终止',
	WE_ARE_SORRY_THERE_IS_NO_DATA_TO_EXPORT: '抱歉，没有可供导出的数据',
	EXPORT_FILTERED_DATA: '输出过滤数据',
	ID_DASHBOARD_CHARTS_summaryofmonthlyendpointusage: '每月端点用量总结',
	ID: 'ID',
	TAB: '标签',
	SORT_BY: '排序标准',
	SELECT_CHART_TYPE_TO_ADD: '选择要添加的图类型',
	RECREDENTIAL_STATUS: '重新设定端点状态',
	RECREDENTIAL_TRANSFER_STATUS: '重新设定端点状态',
	recredentialTransferStatus: '重新设定端点状态',
	M2MACCOUNTID: '机对机（M2M）账户ID',
	HOLDING_ACCOUNT_ID: '保留账户ID',
	RE_CREDENTIAL: '重新设定端点',
	SELECT_OPERATOR: '选择运营者',
	RE_CREDENTIAL_SIM: '重新设定端点SIM卡',
	CURRENT_PLATFORM: '当前平台',
	YOU_HAVE_CHOSEN_TO_RECREDENTIAL_THIS_SIM: '您已经选择将此SIM卡重新设定为',
	YOU_HAVE_CHOSEN_TO_RECREDENTIAL_THESE_SIMS: '您已经选择将这些SIM卡重新设定为',
	YOU_MAY_INTIATE_THE_CHANGE_NOW: '您现在可以开始更改',
	RE_CREDENTIALING_STATUS: '重新设定端点状态',
	RE_CREDENTIALING_IS_SUCCESSFULLY_INITIATED: '已成功开始重新设定端点',
	RE_CREDENTIALING_IS_SUCCESSFULLY_SCHEDULED: '已成功安排重新设定端点',
	RE_CREDENTIALING_IS_SUCCESSFULLY_SCHEDULED_FOR: '已将重新设定端点成功安排在',
	RE_CREDENTIALING_COULD_NOT_BE_INITIATED_SUCCESSFULLY: '无法成功开始重新设定端点',
	RE_CREDENTIALING_COULD_NOT_BE_SCHEDULED_SUCCESSFULLY: '无法成功安排重新设定端点',
	ENDPOINT_HISTORY: '端点历史记录',
	SHIPPED_DATE: '发货日期',
	DIAGNOSTIC_BUTTON: '开始诊断',
	DEVICE_REGISTRATION_INFO_BUTTON: '设备注册信息',
	ENDPOINT_REGISTRATION_DETAILS: '端点注册详情',
	eventTime: '日期和时间标记',
	carrierName: '服务运营商名称',
	registrationType: '注册类型',
	NO_DATA_TO_DISPLAY_AT_THE_MOMENT: '目前没有可显示的数据',
	status: '状态',
	iccid: '集成线路卡识别符（ICCID）',
	carrier: '服务运营商',
	ATTACHED_CARRIER_NAME: '附加的服务运营商名称',
	M2MPlatform: '机对机（M2M） 平台',
	simState: '正在获取设备状态',
	'Endpoint Provisioning Information': '端点配给信息',
	'M2M Platform': '机对机（M2M）平台',
	'Network Connection': '网络连接',
	simStatusM2M: '正在获取设备状态',
	billingStatus: '正在获取设备状态',
	EndpointProvisioningInformation: '端点配给信息',
	trafficAllowed: '获取设备配给信息',
	permitPassingTraffic: '获取设备配给信息',
	verifySimState: '获取设备信息',
	NetworkConnection: '网络连接',
	verifyConnection: '获取设备网络',
	successfullyConnectedToNetwork_inSession: '获取设备网络',
	verifyIPSession: '获取设备IP',
	dataSessionCurrentlySuccessful: '获取设备IP',
	dd_inventory: '库存',
	dd_activated: '已激活',
	dd_active: '已激活',
	dd_inactivated: '未使用',
	dd_inactive: '未使用',
	dd_deactivated: '已停用',
	dd_retired: '已淘汰',
	dd_purged: '已清除',
	dd_testready: '准备好测试',
	dd_activationready: '准备好激活',
	dd_replaced: '已替换',
	dd_trial: '试用',
	dd_start: '开始',
	dd_transfer: '转让',
	dd_globalintransitsource: '全球运送中来源',
	dd_globalintransitdestination: '全球运送中目的地',
	dd_globaltransferred: '全球已转移',
	dd_other: '其他',
	dd_permitpassingtraffic: '已允许传递流量',
	dd_notpermitpassingtraffic: '未允许传递流量',
	dd_allowedToUseWirelessNetworkResources: '获得许可的TollSec无线资源',
	dd_barredFromNetwork: '被网络禁止',
	dd_trafficnotallowed_simstate: 'SIM卡状态不允许流量',
	dd_suspendedByServiceProvider: '已被服务提供者暂停',
	dd_suspendedDueToUsageLimit: '已因用量限制暂停',
	dd_trafficNotAllowed_others: '不允许流量（其他）',
	dd_successfullyconnectedtonetwork_insession: '已成功连接到网络（会话中）',
	dd_notsuccessfullyconnectedtonetwork_insession: '未连接到网络（会话中）',
	dd_validregistration_validnetworkactivity: '有效的注册（有效的网络活动）',
	dd_validregistration_nonetworkactivity: '有效的注册（无网络活动）',
	dd_validgsmregistrationonly: '仅GSM注册有效',
	dd_novalidregistration_hasactivityinlast24h: '无有效注册（过去24小时内有活动）',
	dd_novalidregistration_hasauthrequestinlast24h_nolocupdate: '无有效注册（过去24小时内有验证请求）',
	dd_novalidregistration_haslocupdatesmorethan24hago: '无有效注册（超过24小时以前有本地更新）',
	dd_novalidregistration_hasauthrequestmorethan24hago_noactivity: '无有效注册（超过24小时以前有验证请求）',
	dd_novalidregistration_nonetworkactivity: '超过24小时以前（无网络活动）',
	dd_datasessioncurrentlysuccessful: '设备当前未联网。之前的IP会话成功。',
	dd_notdatasessioncurrentlysuccessful: '数据会话当前不成功',
	dd_datasessionpastsuccessful: '数据会话过去成功',
	dd_connectionattemptfailed_invalidcredentials: '连接尝试失败（无效凭据）',
	dd_nodatasessionsuccessfulhistory: '无数据会话成功历史记录',
	dd_: '',
	dd_allowedtousewirelessnetworkresources: '获得许可的TollSec无线资源',
	attached: '已附加',
	detached: '已分离',
	RECREDENTIALED: '重新设定端点',
	RECREDENTIALING: '正在重新设定端点',
	CHANGE_RECREDENTIAL: '仅允许对同一平台重新设定端点',
	RECREDENTIAL_DESCRIPTION: '提示：如果重新设定端点时需选择多张SIM卡，您仅可以为选择的多张SIM卡选择一个重新设定平台',
	RECREDENTIAL_NOT_ALLOWED: '不允许重新设定端点',
	RECREDENTIAL_SUCCESSFULLY_REQUESTED: 'SIM卡初始化成功',
	MULTIPLE_RECREDENTIAL_CHANGE_ERROR: '无法成功开始为多张SIM卡重新设定端点',
	MOBILE_NETWORK_REGISTRATION_HISTORY: '移动网络注册历史记录',
	ENDPOINT_DAILY_USAGE: '端点每日用量',
	ENDPOINT_CYCLE_USAGE: '当前周期至今的端点用量',
	CONFIRM_RE_CREDENTIALING: '确认重新设定端点',
	PENDING_RECREDENTIAL: '本端点的重新设定正处于待决状态',
	ENDPOINTS_ARE: '已为导出选择端点',
	ENDPOINT_IS: '已为导出选择端点',
	CUSTOM_REPORT_WILL_BE_AVAILABLE: '此定制报告将在报告部分提供',
	YOU_CAN_APPEND_CUSTOM_NAME: '您可以在此处为报告附加定制名称',
	CUSTOM_REPORT_NAME_APPENDIX: '定制报告名称附录',
	ADD_CUSTOM_REPORT_NAME_APPENDIX: '添加定制报告名称附录（只允许使用数字和字母）',
	CUSTOM_REPORT: '定制报告',
	CUSTOM_REPORT_CREATED: '已成功创建定制报告',
	GO_TO_REPORTS: '前往报告',
	DIAGNOSTICS_FOR_THIS_ENDPOINT: '此端点的诊断目前不可用',
	ENDPOINT_DATA_INFORMATION: '端点数据信息',
	LAST_SESSION: '最近一次会话',
	CARRIER_ATTACHED: '已附加服务运营商',
	APN_DCP: 'APN',
	IP_ADDRESS: 'IP地址',
	LAST_SENT_SMS_MO: '最后一次发送移动端始发短信（SMS MO）的日期和时间',
	LAST_SENT_SMS_MT: '最后一次发送移动端终止短信（SMS MT）的日期和时间',
	TEST_DETAILS: '测试详情',
	CHECK_BLOCKED_STATUS: '检查受阻状态',
	BLOCKED_STATUS: '受阻状态',
	RETRY_TEST: '重试测试',
	MOBILE_NETWORK_TEST: '移动网络测试',
	CHECK_BLOCKED_SUB: '检查受阻订阅',
	TROUBLESHOOT_DCP: '设备连接平台（DCP）故障排除',
	locationUpdate: '地点更新',
	pdpStatus: 'PDP状态',
	cancelLocation: '取消地点',
	CANCEL_LOCATION: '取消地点',

	DASHBOARD_CHART_CountofDailyEndpointSMSbyPlatformDCP_CELLULARDescription: '逐月显示整个设备连接平台（DCP）上每天发送的短信服务（SMS）讯息总数',
	DASHBOARD_CHART_CountofDailyEndpointSMSbyPlatformDCP_CELLULAR: '每日短信服务（DCP）',
	DASHBOARD_CHART_CountofMonthlyEndpointSMSbyPlatformDCP_CELLULAR: '每月短信服务（DCP）',
	DASHBOARD_CHART_SumofDailyEndpointUsagebyPlatformDCP_CELLULAR: '每日端点总用量（DCP）',
	DASHBOARD_CHART_SumofDailyEndpointUsagebyPlatformACC_CELLULAR: '每日数据用量（ACC）',
	DASHBOARD_CHART_SumofMonthlyEndpointUsagebyPlatformDCP_CELLULAR: '每月数据用量（DCP）',
	DASHBOARD_CHART_CountofMonthlyEndpointSMSbyPlatformDCP_CELLULARDescription: '显示整个设备连接平台（DCP）上每月发送的短信服务（SMS）讯息总数',
	DASHBOARD_CHART_SumofDailyEndpointUsagebyPlatformDCP_CELLULARDescription: '逐月显示整个设备连接平台（DCP）上的每天数据总用量',
	DASHBOARD_CHART_SumofDailyEndpointUsagebyPlatformACC_CELLULARDescription: '逐月显示整个ACC平台上的每天数据总用量',
	DASHBOARD_CHART_SumofMonthlyEndpointUsagebyPlatformDCP_CELLULARDescription: '显示整个设备连接平台（DCP）上的每月数据总用量',
	DASHBOARD_CHART_CountofMonthlyEndpointSMSbyPlatformCCIP_CELLULAR: '每月短信服务（CCIP）',
	DASHBOARD_CHART_CountofMonthlyEndpointSMSbyPlatformCCIP_CELLULARDescription: '显示整个CCIP平台上每月发送的短信服务（SMS）讯息总数',
	DASHBOARD_CHART_CountofDailyEndpointSMSbyPlatformCCIP_CELLULAR: '每日短信服务（CCIP）',
	DASHBOARD_CHART_CountofDailyEndpointSMSbyPlatformCCIP_CELLULARDescription: '显示整个CCIP平台上每日发送的短信服务（SMS）讯息总数',
	DASHBOARD_CHART_SumofDailyEndpointUsagebyPlatformCCIP_CELLULAR: '每日端点总用量（CCIP）',
	DASHBOARD_CHART_SumofDailyEndpointUsagebyPlatformCCIP_CELLULARDescription: '逐月显示整个CCIP平台上的每天数据总用量',
	DASHBOARD_CHART_SumofMonthlyEndpointUsagebyPlatformCCIP_CELLULAR: '每月数据用量（CCIP）',
	DASHBOARD_CHART_SumofMonthlyEndpointUsagebyPlatformCCIP_CELLULARDescription: '显示整个CCIP平台上的每月数据总用量',
	DASHBOARD_CHART_countByCountry_CELLULAR: '按国家显示端点',
	DASHBOARD_CHART_countByCountry_CELLULARDescription: '显示多个国家的端点数量。',
	DASHBOARD_CHART_countByRoamingCountry_CELLULAR: '按漫游国家显示端点（蜂窝）',
	DASHBOARD_CHART_countByRoamingCountry_CELLULARDescription: '按漫游国家显示端点数量。',
	DASHBOARD_CHART_countByRoamingCarrier_CELLULAR: '按漫游服务运营商显示端点（蜂窝）',
	DASHBOARD_CHART_countByRoamingCarrier_CELLULARDescription: '按漫游服务运营商显示蜂窝端点数量。',
	DASHBOARD_CHART_countByRoamingCarrier_SATELLITE: '按漫游服务运营商显示端点（卫星）',
	DASHBOARD_CHART_countByRoamingCarrier_SATELLITEDescription: '按漫游服务运营商显示卫星端点数量。',

	SMS_HISTORY: '短信服务（SMS）历史记录',
	MAXIMUM_NUMBER_OF_CHARACTERS: '字符数上限：',
	SEND_SMS_MESSAGE: '发送短信（SMS）',
	SMS_NOTIFICATION_TITLE: '短信服务（SMS）通知',
	SMS_NOTIFICATION_MESSAGE: '响应错误：',
	SMS_NOTIFICATION_MESSAGE_SUCCESS: '已成功发送短信',

	REVIEW: '审核',
	PLATFORM_DETAILS: '平台详情',

	UPDATING: '正在更新',
	AUTOMATION_RULE_SUCCESSFULLY_DELETED: '已成功删除自动化规则',
	AUTOMATION_DELETE_ERROR: '删除自动化规则失败，请重试。',
	FETCH_DATA_ERROR: '未能取回数据',
	CHANGED_BY: '更改者',
	EMAIL_SUB: '发送电子邮件',
	CTD_USAGE: '周期至今数据用量',
	PAST24H_DATA_USAGE_EXCEEDED: '过去24小时内数据使用超量',
	SMS_USAGE: '周期至今短信服务（SMS）用量',
	EXCEEDLIMIT: '超过',
	APPROACHLIMIT: '接近',
	trigger: '触发',
	TWENTYFOURHOURS: '过去24小时',
	CTD: '周期至今',
	ADD_RULE_SUCCESS: '已创建规则',
	ADD_RULE_ERROR: '创建自动化规则失败，请重试。',
	EDIT_RULE_SUCCESS: '已编辑规则',
	EDIT_RULE_ERROR: '编辑自动化规则失败，请重试。',
	ACC_AUTOMATION: 'ACC自动化',
	ACC_ACCOUNT_ID: 'ACC账户ID',
	CREATE_NEW_AUTOMATION_RULE: '创建新自动化规则',
	DCP_AUTOMATION: '设备连接平台（DCP）自动化',
	DCP_ACCOUNT_ID: '设备连接平台（DCP）账户ID',
	ACC_ACCOUNT: 'ACC账户',
	RULE: '规则',
	ACC_IF: '如果',
	TRESHOLD: '阈值',
	THEN: '则',
	ACC_RULE_NAME: '规则名称',
	SAVE_AND_ACTIVATE: '保存并激活',
	DCP_ACCOUNT: '设备连接平台（DCP）账户',
	SELECT_BY: '选择依据的条件',
	DCP_BYTES_SENT: '已发送的字节数',
	SMSES_SENT: '已发送的短信',
	SMSES: '短信',
	DASHBOARD_FEEDBACK: '仪表板',
	MUST_BE_BETWEEN: '必须在此范围内 ',
	COMPANIES_STEPS: '公司步骤',
	USER_NAME: '用户名',
	ID_DASHBOARD_CHARTS_countofmonthlyendpointsmsbyplatformdcp: '每月短信服务（SMS）',
	ID_DASHBOARD_CHARTS_countofdailyendpointsmsbyplatformdcp: '每日短信服务（SMS）',
	ID_DASHBOARD_CHARTS_sumofdailyendpointusagebyplatformdcp: '每日端点总用量',
	ID_DASHBOARD_CHARTS_sumofdailyendpointusagebyplatformacc: '每日数据用量',
	ID_DASHBOARD_CHARTS_sumofmonthlyendpointusagebyplatformdcp: '每月数据用量',
	DASHBOARD_CHART_DeviceType_SATELLITE: '设备类型（卫星）',
	DASHBOARD_CHART_DeviceType_CELLULAR: '设备类型（蜂窝）',
	DASHBOARD_CHART_DeviceType_SATELLITEDescription: '显示不同类型设备总数',
	DASHBOARD_CHART_DeviceType_CELLULARDescription: '显示不同类型设备总数',
	AUTOMATION_ALERTS: '自动化警报',
	STATE: '状态',
	COMPANY_EMAIL: '公司电子邮件',
	CUSTOM_FIELD_LABELS: '定制字段标签',
	MY_COMPANY: '我的公司',
	AUTOMATION_ACCOUNT: '账户',
	UPDATE_CUSTOM_FIELD_LABELS_SUCCESS: '定制标签设置已保存',
	UPDATE_CUSTOM_FIELD_LABELS_ERROR: '无法保存定制标签设置',
	WRITE_SMS_MESSAGE_HERE: '在此处写讯息……',
	RE_CREDENTIALING_ALLOWED: '不允许重新设定端点。',
	DELETE_AUTOMATION_RULE_QUESTION: '要删除自动化规则吗？',
	DELETE_AUTOMATION_RULE_MESSAGE: '您是否确定想要删除自动化规则 ',
	PLATFORM_SUCCESSFULLY_DELETED: '已成功删除平台',
	PLATFORM_DELETE_ERROR: '删除平台失败，请重试。',
	CELLULAR: '蜂窝',
	SATELLITE: '卫星',
	SAVE_CUSTOM_LABELS: '保存定制标签',

	// CCIP
	ENDPOINT_PROVISIONING_INFORMATION: '端点配给信息',
	CCIP_3G: '3G',
	VOICE_ENABLED: '语音已启用',
	DATA_ENABLED: '数据已启用',
	SMS_ENABLED: '短信服务（SMS）已启用',
	VOICE_ROAMING_ENABLED: '语音漫游已启用',
	DATA_ROAMING_ENABLED: '数据漫游已启用',
	CCIP_LTE: 'Lte',
	LTE_ENABLED: 'LTE已启用',
	ROAMING_ENABLED: '漫游已启用',
	MOBILE_NETWORK_ACCESS: '移动网络访问',
	VOICE_ONLY: '仅限语音',
	REGISTERED_ON_NETWORK: '已在网络中注册',
	REGISTERED_ON_3G_NETWORK: '已在3G网络中注册',
	THIS_EMAIL_ADDRESS_IS_ALREADY_IN_USE: '此电子邮件地址已被占用',
	THIS_EMAIL_ADDRESS_DOES_NOT_EXIST: '此电子邮件地址不存在',
	USER_SUCCESSFULLY_DELETED: '已成功删除用户',
	USER_DELETE_ERROR: '删除用户失败，请重试。',
	CATEGORIES: '类别',
	TRIGGERS: '触发',
	FETCH_DATA_LOADING_MESSAGE: '请稍候，我们正在载入数据',
	M2M_ACCOUNT_ID_PLATFORM: '机对机（M2M）账户ID',
	M2MACCOUNTS: '机对机（M2M）账户',

	FROM_VALUE: '起始值',
	TO_VALUE: '目标值',

	SEARCH_REPORTS: '搜索报告',
	SEARCH_INVOICES: '搜索账单',

	CUSTOM_DATA_FIELDS: '定制数据字段',
	ENDPOINT_ACTIONS: '端点行动',
	RE_CREDENTIAL_NOT_ALLOWED: '不允许重新设定端点',
	EDIT_ENDPOINT: '端点行动',
	CURRENT_SIM_STATE_FOR_ENDPOINT_ID: '端点ID的当前SIM卡状态',

	NETWORK_ENTITLEMENT_NAME: '网络权限名称',
	BLOCK_PREMIUM_NUMBERS: '阻止付费号码',
	BLOCK_INTERNATIONAL_MO: '阻止国际移动端始发短信（SMS MO）（所在国除外）',
	HLR_TEMLATE: 'HLRHSS详情模板',
	HOTLINE_NUMBER: '热线电话号码',
	FACETIME: 'FACETIME',
	VOLTE: 'Volte',
	GDSP_SESSION_ACTIVE: '使用中',
	GDSP_SESSION_BYTES_IN: '收到字节',
	GDSP_SESSION_BYTES_OUT: '发出字节',
	GDSP_SESSION_LAST_IP_ADDRESS: '最后一个IP地址',
	GDSP_SESSION_LAST_STARTED_TIMESTAMP: '最后一次开始的时间标记',
	GDSP_SESSION_LAST_UPDATED_TIMESTAMP: '最后一次更新的时间标记',
	GDSP_SESSION_LAST_TERMINATED_TIMESTAMP: '最后一次终止的时间标记',
	GDSP_SESSION_LAST_DATA_TRANSMITED_TIMESTAMP: '最后一次传输的时间标记',
	GDSP_SESSION_PREVIOUS_NORMAL_TIMESTAMP: '前一次正常的时间标记',
	GDSP_SESSION_LAST_SMS_MO_TIMESTAMP: '最后一次移动端始发短信（SMS MO）时间标记',
	GDSP_SESSION_LAST_SMS_MT_TIMESTAMP: '最后一次移动端终止短信（SMS MT）时间标记',
	GDSP_SESSION_LAST_SMS_MT_STATUS: '最后一次移动端终止短信（SMS MT）状态',
	GDSP_SESSION_LAST_SMS_MO_STATUS: '最后一次移动端始发短信（SMS MO）状态',
	GDSP_SESSION_LAST_WAKEUP_REQUEST_TIMESTAMP: '最后一次唤醒请求时间标记',
	GDSP_SESSION_LAST_WAKEUP_REQUEST_TYPE: '最后一次唤醒请求类型',
	GDSP_SESSION_LAST_WAKEUP_STATUS: '最后一次唤醒状态',
	GDSP_SESSION_LAST_WAKEUP_STATUS_TIMESTAMP: '最后一次唤醒状态时间标记',
	GDSP_SESSION_LAST_NOTIFICATION_FAILURE_TIMESTAMP: '最后一次通知失败时间标记',
	GDSP_SESSION_LAST_NOTIFICATION_FAILURE_REASON: '最后一次通知失败原因',

	CUSTOM_FIELD_1: '定制字段1',
	CUSTOM_FIELD_2: '定制字段2',
	CUSTOM_FIELD_3: '定制字段3',
	CUSTOM_FIELD_4: '定制字段4',
	CUSTOM_FIELD_5: '定制字段5',
	CUSTOM_FIELD_6: '定制字段6',
	CUSTOM_FIELD_7: '定制字段7',
	CUSTOM_FIELD_8: '定制字段8',
	CUSTOM_FIELD_9: '定制字段9',
	CUSTOM_FIELD_10: '定制字段10',

	/* ----- GDSP ------- */
	REGISTRATION_STATUS: '注册状态',
	NETWORK_CONNECTIONS: '网络连接',
	PROVISIONED_DATE_AND_TIME: '配给日期和时间',

	// GDSP SIM STATE STATUS
	M2M_PLATFORM_STATUS_A: '使用中活跃',
	M2M_PLATFORM_STATUS_B: '新未使用',
	M2M_PLATFORM_STATUS_C: '使用中睡眠',
	M2M_PLATFORM_STATUS_D: '未使用已停止',
	M2M_PLATFORM_STATUS_I: '从其他GDSP环境迁移',
	M2M_PLATFORM_STATUS_N: '未配给',
	M2M_PLATFORM_STATUS_O: '向其他GDSP环境迁移',
	M2M_PLATFORM_STATUS_R: '使用中就绪',
	M2M_PLATFORM_STATUS_S: '使用中暂停',
	M2M_PLATFORM_STATUS_T: '使用中测试',
	M2M_PLATFORM_STATUS_U: '使用中待机',
	M2M_PLATFORM_STATUS_V: '未使用已转化',
	M2M_PLATFORM_STATUS_W: '暂停监管',
	M2M_PLATFORM_STATUS_Z: '已终止',
	M2M_PLATFORM_STATUS_NOT_AVAILABLE: '不可用',

	// GDSP Session tab Status Codes
	// lastWakeupStatus
	LASTWAKEUPSTATUS_IR: '唤醒呼叫无效',
	LASTWAKEUPSTATUS_FI: '唤醒初始化失败',
	LASTWAKEUPSTATUS_SI: '唤醒初始化成功',
	LASTWAKEUPSTATUS_F: '唤醒发送失败',
	LASTWAKEUPSTATUS_S: '唤醒发送成功',
	TOOLTIP_LASTWAKEUPSTATUS_IR: '错误的API请求',
	TOOLTIP_LASTWAKEUPSTATUS_FI: '短信交换中心（SMSC）故障',
	TOOLTIP_LASTWAKEUPSTATUS_SI: '成功提交至短信交换中心（SMSC）',
	TOOLTIP_LASTWAKEUPSTATUS_F: '短信交换中心（SMSC）已发出失败通知',
	TOOLTIP_LASTWAKEUPSTATUS_S: '短信交换中心（SMSC）已发出成功通知',

	// lastSmsMtStatus
	LASTSMSMTSTATUS_IR: '唤醒呼叫无效',
	LASTSMSMTSTATUS_FI: '唤醒初始化失败',
	LASTSMSMTSTATUS_SI: '唤醒初始化成功',
	LASTSMSMTSTATUS_F: '唤醒发送失败',
	LASTSMSMTSTATUS_S: '唤醒发送成功',
	TOOLTIP_LASTSMSMTSTATUS_IR: '错误的API请求',
	TOOLTIP_LASTSMSMTSTATUS_FI: '短信交换中心（SMSC）故障',
	TOOLTIP_LASTSMSMTSTATUS_SI: '成功提交至短信交换中心（SMSC）',
	TOOLTIP_LASTSMSMTSTATUS_F: '短信交换中心（SMSC）已发出失败通知',
	TOOLTIP_LASTSMSMTSTATUS_S: '短信交换中心（SMSC）已发出成功通知',

	/* ----- GDSP END ------- */

	/* API ERROR CODES  */

	CODE_400203: '缺少要求的参数',
	CODE_400204: '参数没有正确的长度',
	CODE_401205: '令牌无效或已失效',
	CODE_400207: '参数没有来自可能值选项的有效值',
	CODE_400208: '参数没有正确的格式',
	CODE_400209: '重复参数',
	CODE_400299: '错误的请求',
	CODE_400301: '错误来自下游系统',
	CODE_401301: '下游系统凭据验证失败',
	CODE_404301: '未能在下游系统中找到设备ID',
	CODE_404302: '未能在下游系统中找到账户ID，或ID无效',
	CODE_502100: '不允许更改，设备已接受安排',
	CODE_400100: '无效请求：不允许对卫星设备采取批处理行动',
	CODE_404201: '文件不存在',
	CODE_401202: '用户名或密码无效',
	CODE_400206: '文件名格式无效',
	CODE_502200: '批处理文件错误',
	CODE_502202: '检索数据库错误',
	CODE_502203: '更改时数据库出错',
	CODE_502204: '创建客户时出错',
	CODE_502301: '处理PUSH API时出错',
	CODE_404303: '未能在PUSH API通知中找到设备ID',
	CODE_502501: '处理SFTP报告时出错',
	CODE_undefined: ' ',
	CODE_INTEGRATION_TIMEOUT: '无法联系底层平台。请稍后重试。',
	CODE_VTM_SVC_40003: 'The entered value was not recognized',
	CODE_VTM_SVC_40004: 'Unable to create the support ticket',
	CODE_VTM_SVC_40005: 'Unable to create the support ticket',
	CODE_VTM_SVC_40409: 'Ticket does not exist: It may have been removed due to AT&T data retention policy',
	CODE_VTM_SVC_40411: 'Ticket does not exist or it is closed by an agent: It may have been removed due to AT&T data retention policy',
	CODE_VTM_SVC_40027: 'Cannot update canceled or closed support ticket: This ticket is closed or canceled and cannot be updated.',
	CODE_VTM_SVC_40035: 'Unable to add engagement: Engagement ticket already exist',
	CODE_VTM_SVC_50001: 'Something went wrong: Try again. If the problem persists, please report your issue by calling AT&T',
	CODE_VTM_SVC_40038: 'Unable to update the support ticket: Circuit details are the same',
	CODE_VTM_SVC_40039: 'Cannot create the support ticket: Asset info is missing in the system',
	CODE_VTM_SVC_40040: 'Cannot add a PVC value to support ticket: pvcID details already exist in the system',
	CODE_VTM_SVC_40041: 'Unable to update support ticket: clkNumber does not exist',
	CODE_VTM_SVC_40042: 'Unable to update support ticket: pvcID does not exist',
	CODE_VTM_SVC_40043: 'Unable to retrieve ticket history: Please try again later',
	CODE_VTM_SVC_40010: 'Unable to create the support ticket: Field exceeded maximum of 199 characters. Please correct the length and try again',
	CODE_VTM_SVC_40053: 'Unable to create support ticket: Case ID does not have Ticket Role defined. or is invalid support role',
	CODE_EOD_11: 'Unable to process the request: Please try again in a few minutes.',
	CODE_EOD_59: 'Cannot deactivate device without static IP: Before a device can be deactivated (put on hold), it needs to have a static IP. You can still deactivate this device by deprovisioning it (purge) from the system.',
	CODE_EOD_60: 'Cannot deactivate inactive device',
	CODE_EOD_46: 'The SIM is already in the provisioning queue',
	CODE_EOD_30: 'The SIM cannot be suspended using a past date',
	CODE_EOD_32: 'Internal system error: Contact customer support or create a trouble ticket to get help with this issue',
	CODE_EOD_41: 'Cannot change profile for suspended devices: Contact customer support or create a trouble ticket to get help with this issue',
	CODE_EOD_45: 'Invalid SIM or SIM does not belong to your account: Contact customer support or create a trouble ticket to get help with this issue',
	CODE_EOD_16: 'SIM has reached the maximum number of Rate Plan changes allowed during the Billing Cycle: Could not complete the Rate Plan change request. Please try again in the next billing cycle.',
	CODE_EOD_17: 'SIM has reached the maximum number Rate Plan changes allowed for the day: Could not complete the Rate Plan change request. Please try again tomorrow.',
	CODE_EOD_25: 'The device is currently in a suspended state and so cannot resume before the suspended date',
	CODE_EOD_27: 'The SIM cannot be suspended using a past date',
	CODE_EOD_3: 'Device is already inactive',
	CODE_EOD_401: 'Unable to authorize user performing the batch action with device platform.',

	/* API ERROR CODES  */

	SERVICE_STATUS: '360L状态',
	SERVICESTATUS: '360L状态',
	OEM: '子账户',
	ROAMING_COUNTRY: '漫游国家',
	ROAMING_CARRIER: '漫游服务运营商',
	ROAMINGCOUNTRY: '漫游国家',
	ROAMINGCARRIER: '漫游服务运营商',
	OEM_TOOLTIP: '关联的360L账户',
	SIM_STATE: 'SIM卡状态',

	NO_DATA_ENDPOINT_HISTORY: '无端点历史记录',
	PRINT_PDF: '打印PDF',
	USER_GUIDE: '用户指南',
	USER_GUIDE_MENU: '用户指南菜单',
	FAQ_MENU: '常见问题菜单',
	TABLE_OF_CONTENTS: '目录',
	RATE_PLAN_TYPE: '费率计划类型',
	SUBSCRIPTION_PRICE: '订阅价格',
	ACCOUNT_CHARGE: '账户收费',
	PAYMENT_TYPE: '付款类型',
	TERM: '期限',
	USAGE_LIMIT: '用量限制',
	TIERING_FLAG: '层级标记',

	RATE_PLAN_CHANGE: '费率计划更改',
	RATE_PLAN_CHANGED_TO: '您已经选择将费率计划更改为',
	SCHEDULE_RATE_PLAN_CHANGE: '安排费率计划更改',
	RATE_PLAN_CHANGE_IS_SUCCESSFULLY_SCHEDULED_ERROR: '无法成功地安排费率计划更改！',
	RATE_PLAN_CHANGE_IS_SUCCESSFULLY_SCHEDULED_FOR: '已成功地安排费率计划更改',
	RATE_PLAN_IS_CHANGED_TO: '费率计划已更改为 ',
	FAILED_TO_PROVISION_AFTER_RATE_PLAN_CHANGE: '未能在费率计划更改后完成配给。',
	SEND_EMAIL_TO_CONSUMER: '向消费者发送电子邮件',
	PUSH_API: 'Push API',
	INITIATE_OTA_SWAP: '初始化OTA互换',
	UPDATE_PCRF: '更新PCRF',
	CHANGE_DEVICES_NETWORK_ENTITLEMENT: '更改设备网络权限',
	CHANGE_DEVICES_SIM_STATE: '更改设备SIM卡状态',
	CHANGE_DEVICES_RATE_PLAN: '更改设备费率计划',
	NUMBER_OF_DAYS: '天数',
	NUMBER_OF_HOURS: '小时数（最长24小时）',
	AT: '目标',
	FILTER: '过滤',
	FOLLOW_UP_WITH: '跟进',
	CUSTOM: '定制',
	ELIGIBLE_RATE_PLAN: '合格费率计划',
	CHOOSE_RATE_PLAN: '选择费率计划',
	CUSTOMER_ID: '客户ID',
	INVALID_URL_FORMAT: '无效URL格式',
	INVALID_PORT_NUMBER: '无效端口号码',
	INVALID_INPUT: '无效输入',
	RATE_PLAN_CHANGE_SUPPORT: '费率计划错误，请与客户支持联系。',
	NETWORK_ENTITLEMENT_PLACEHOLDER: 'XYX GPRS/LTE/SMS/IMS RES 165',
	M2M_SUB_ACCOUNT_ID: '机对机（M2M）子账户ID',

	/* SIM ORDERING */
	SIM_ORDERING: 'SIM卡订购',
	CREATE_SIM_ORDER: '创建SIM卡订单',
	NEW_SIM_ORDER: '新订单',
	EXISTING_SIM_ORDER: '订单ID：{id}',
	M2M_ACCOUNT: '机对机（M2M）账户',
	M2M_ACCOUNT_PLACEHOLDER: '选择您的机对机（M2M）账户',
	ORDER_ID: '订单ID',
	ORDER_NUMBER_ID: '订单号码ID',
	ORDER_CONFIRMATION_NUMBER: '订单确认号码',
	ORDER_DATE: '订单日期',
	PURCHASE_ORDER_NUMBER: '采购单号码',
	SKU: '储存单位（SKU）',
	SKU_PLACEHOLDER: '选择储存单位（SKU）',
	UNIT_PRICE: '单价',
	QUANTITY: '数量',
	QUANTITY_LIMIT_TEMPORARILY_REACHED: '暂时达到数量限制',
	MIN_MAX_QUANTITY: '最低限制{min} / 最高限制{available} {max}',
	INCREMENT_QUANTITY: '增量{increment}',
	ORDER_STATUS: '订单状态',
	ORDER_STATUS_PENDING: '待决',
	ORDER_STATUS_RECEIVED: '已收到',
	ORDER_STATUS_SHIPPED: '已运送',
	ORDER_STATUS_COMPLETED: '已完成',
	ORDER_STATUS_SUCCESSFUL: '成功',
	ORDER_STATUS_FAILED: '失败',
	CONTACT_NAME: '联系人姓名',
	TELEPHONE_NUMBER: '电话号码',
	TARGET_IMPLEMENTATION_DATE: '目标实施日期',
	EXPEDITED: '已加急',
	SHIPPING_ADDRESS: '街道地址',
	ADDRESS_LINE_1: '地址行1',
	ADDRESS_LINE_2: '地址行2',
	SHIPPING_NOTES: '运送备注',
	SHIPPING_NUMBER: '运送号码',
	EXPEDITED_SHIPPING: '加急运送',
	TOTAL_PRICE: '总价',
	SUBMIT_ORDER_REQUEST: '提交订单请求',
	SUBMIT_ORDER_REQUEST_CONFIRMATION: '您确定想要提交本订单吗？',
	EDIT_ORDER_REQUEST: '编辑订单请求',
	EDIT_ORDER_REQUEST_CONFIRMATION: '您确定想要保存更改吗？',
	SAVE_UPDATES: '保存更新',
	ADD_SIM_ORDER_SUCCESS: '已创建SIM卡订单',
	ADD_SIM_ORDER_ERROR: '创建SIM卡订单失败，请重试。',
	EDIT_SIM_ORDER_SUCCESS: '已编辑SIM卡订单',
	EDIT_SIM_ORDER_ERROR: '编辑SIM卡订单失败，请重试。',
	PO_TOOLTIP: '提交SIM卡订单后会生成采购单号码',

	FILTERS: '过滤器',
	FILTER_CATEGORY_STATUS: '状态',
	FILTER_CATEGORY_PLATFORM: '机对机（M2M） 平台',
	FILTER_CATEGORY_SKU: '储存单位（SKU）',
	FILTER_CATEGORY_EXPEDITED: '已加急',
	FILTER_VALUE_PENDING: '待决',
	FILTER_VALUE_RECEIVED: '已收到',
	FILTER_VALUE_SHIPPED: '已运送',
	FILTER_VALUE_COMPLETED: '已完成',
	FILTER_VALUE_YES: '是',
	FILTER_VALUE_NO: '否',

	SIM_ORDERING_TITLE: 'SIM卡订购',
	SIM_ORDERING_SUBTITLE: '请在下面选择哪些SIM卡可用于订单。',
	SIM_ORDERING_CONTRACT_RESTRAINTS: '合同限制',
	SIM_ORDERING_CONTRACT_RESTRAINTS_SUBTITLE: '请为SIM卡订单流程设置限制。',
	SIM_ORDERING_MIN_QUANTITY_ALLOWED: '允许的最低数量',
	SIM_ORDERING_MIN_QUANTITY_ALLOWED_PLACEOLDER: '最低数量',
	SIM_ORDERING_MAX_QUANTITY_ALLOWED: '允许的最高数量',
	SIM_ORDERING_PER_PERIOD: '每个期限',
	SIM_ORDERING_PER_PERIOD_PLACEHOLDER: '选择时间期限',
	SIM_ORDERING_MAX_QUANTITY_ALLOWED_PLACEOLDER: '最高数量',
	SIM_ORDERING_SIM_SKU: 'SIM卡储存单位（SKU）',
	SIM_ORDERING_SIM_SKU_PLACEHOLDER: '在此处输入SIM卡储存单位（SKU）',
	SIM_ORDERING_UNIT_PRICE: '单价',
	SIM_ORDERING_UNIT_PRICE_PLACEHOLDER: '单价',
	SIM_ORDERING_ALLOWED_TO_ORDER: '允许订购',
	SIM_ORDERING_SKU_DESCRIPTION: '描述',
	SIM_ORDERING_SKU_DESCRIPTION_PLACEHOLDER: '输入储存单位（SKU）描述',
	SIM_ORDERING_SKU_ADD_SKU: '+添加另一个SIM卡储存单位（SKU）',
	SIM_ORDERING_VALIDATOR_MAX_QUANTITY: '最高数量',
	SIM_ORDERING_VALIDATOR_MIN_QUANTITY: '最低数量',
	ADD_RESTRAINTS_SUCCESS: '已添加合同限制',
	ADD_RESTRAINTS_ERROR: '添加合同限制失败',
	UPDATE_RESTRAINTS_SUCCESS: '合同限制已更新',
	UPDATE_RESTRAINTS_ERROR: '更新合同限制失败',
	ADD_SIM_ORDERING_SKUS_SUCCESS: 'SIM卡订购储存单位（SKU）已更新',
	ADD_SIM_ORDERING_SKUS_ERROR: '添加SIM卡订购储存单位（SKU）失败',

	COMPANY_REPORTS: '报告',
	COMPANY_REPORTS_SETTINGS_SUCCESSFULLY_UPDATED: '已保存公司报告设置',
	ERROR_WHILE_UPDATING_COMPANY_REPORTS: '更新公司报告时出错',
	ERROR_WHILE_FETCHING_COMPANY_REPORTS: '检索报告配置时出错',
	COMPANY_REPORTS_SUBTITLE: '请在下面选择您希望收到的报告。',
	DAILY_REPORTS: '每日报告',
	MONTHLY_REPORTS: '每月报告',
	PREFERENCES_MENU: '偏好设置菜单',
	PREFERENCES: '偏好设置',
	REFERENCE_REPORTS: '参考报告',
	M2M_DATE_ADDED: '机对机（M2M）日期已添加',
	M2M_INITIAL_ACTIVATION_DATE: '机对机（M2M）激活日期',
	NOTES: '注释',
	SAVE_NOTE: '保存注释',
	NOTE_TEXTAREA_PLACEHOLDER: '在此处输入您的注释（最多2000个字符）',
	ADD_NOTE_SUCCESS: '已保存注释',
	ADD_NOTE_ERROR: '保存注释失败，请重试。',
	DELETE_NOTE_SUCCESS: '已删除注释',
	DELETE_NOTE_ERROR: '删除注释失败，请重试。',
	DELETE_NOTE: '删除注释',
	TICKETING_STATUS: '通知单状态',
	UPDATE_NOTE_STATUS: '更新注释状态',
	LAST_UPDATE: '最后一次更新',
	SUBMISSION_LOCATION: '提交地点',
	RULE_STATE: '规则状态',
	RULE_STATE_ACTIVE: '使用中',
	YES: '是',
	NO: '否',
	// ANALYTICS
	CHART_CATEGORIES: '图类别',
	GROUP_DATA_BY: '分组数据依据标准',
	CHART_OPTIONS: '图选项',
	CREATE_NEW_CHART: '创建新图',
	EDIT_CHART: '编辑图',
	DISCLAIMER: '免责声明： ',
	DATE_RANGE: '日期范围',
	SHOW: '显示',
	CYCLE: '周期',
	INCREMENT_BY: '递增依据标准',
	SELECT_CUSTOM_DATE_RANGE: '选择定制日期范围',

	// 账单
	INVOICE: '账单',
	INVOICE_DATE: '账单日期',
	CUSTOMER_NAME: '客户名称',
	DOCUMENT_ID: '文档ID',
	COUNTRY_NAME: '国家名称',
	TABLE_COLUMNS_INVOICES_CHANGE_SUCCESS_TITLE: '已成功更改账单表格设置！',
	TABLE_COLUMNS_INVOICES_CHANGE_SUCCESS_MESSAGE: '已成功更新账单表格设置。',
	TABLE_COLUMNS_INVOICES_CHANGE_ERROR_TITLE: '更改账单表格列时出错！',
	TABLE_COLUMNS_INVOICES_CHANGE_ERROR_MESSAGE: '目前无法更新账单表格列。请稍后重试。',
	INVOICE_DETAILS: '账单详情',

	// TICKETING
	TICKET_CREATED_DATE: '通知单创建日期',
	UPDATED_NOTE_STATUS_DATE: '更新注释状态日期',
	LAST_UPDATED_DATE: '最后一次更新日期',
	UPDATE_NOTE: '更新注释',
	TABLE_TICKETS_COLUMNS_CHANGE_SUCCESS_TITLE: '已成功更改通知单表格设置！',
	TABLE_TICKETS_COLUMNS_CHANGE_SUCCESS_MESSAGE: '已成功更新通知单表格设置。',
	TABLE_TICKETS_COLUMNS_CHANGE_ERROR_TITLE: '更改通知单表格列时出错！',
	TABLE_TICKETS_COLUMNS_CHANGE_ERROR_MESSAGE: '目前无法更新通知单表格列。请稍后重试。',

	TODAY: '今天',
	PREVIOUS_DAY: '前一天',
	LAST_10_DAYS: '过去10天',
	LAST_30_DAYS: '过去30天',
	CUSTOM_RANGE: '定制范围',
	CURRENT_CYCLE_TO_DATE: '当前周期至今',
	HISTORICAL: '历史',
	WEEK: '周',
	SUM_TOTALS: '总和',
	AVERAGE_TOTALS: '总平均',
	LINE_GRAPH: '线状图',
	BAR_GRAPH: '条形图',
	WORLD_MAP_GRAPH: '世界地图',
	TOTAL_DATA_USAGE: '数据总用量',
	ENDPOINT_COUNT_TOTALS: '端点总数',
	NO_USAGE_SIMS: '无用量的SIM卡',
	TOTAL: '总数',
	AVERAGE_USAGE: '平均',
	DIRECTION_INDEX: '趋势指数',
	PIE_CHART: '饼图',
	ERROR_WHILE_ADDING_TICKET: '添加通知单时出错',

	SELECT_M2M_PLATFORM: '选择您的机对机（M2M）平台',
	SELECT_M2M_PLATFORM_ERROR: '请先选择机对机（M2M）平台，然后再填充数据',
	SELECT_YOUR_ACCOUNT: '选择您的账户',
	CHOOSE_M2M_PLATFORM: '选择您的机对机（M2M）平台',
	CHOOSE_YOUR_ACCOUNT: '选择您的账户',

	TOTAL_VOICE_USAGE: '语音总用量',
	TOTAL_SMS_USAGE: '短信服务（SMS）总用量',
	SAVE_AND_CLOSE: '保存并关闭',
	// 安全特性
	SECURITY_TABS_1: 'IMEI白名单',
	SECURITY_TABS_2: '短信服务（SMS）白名单',
	SECURITY_TABS_3: '语音白名单',
	SECURITY_TABS_4: 'IP、URL、端口黑名单',
	SECURITY_TABS_5: 'IP范围白名单',
	SECURITY_FEATURES_1: 'IMEI',
	SECURITY_FEATURES_2: '短信服务（SMS）',
	SECURITY_FEATURES_3: '语音',
	SECURITY_FEATURES_4: 'IP、URL、端口',
	SECURITY_FEATURES_5: 'IP范围',
	SECURITY_FEATURES_SUCCESSFULLY_ADD: '已成功更改数据',
	SECURITY_FEATURES_ERROR_ADD: '更新数据时出错',
	SECURITY_FEATURES_ADD_1: '将新IMEI添加至白名单',
	SECURITY_FEATURES_ADD_2: '将新短信服务（SMS）添加至白名单',
	SECURITY_FEATURES_ADD_3: '将新语音添加至白名单',
	SECURITY_FEATURES_ADD_4: '将新IP、URL、端口添加至黑名单',
	SECURITY_FEATURES_ADD_5: '将新IP范围添加至白名单',
	SECURITY_FEATURES_EDIT_1: '编辑IMEI白名单',
	SECURITY_FEATURES_EDIT_2: '编辑短信服务（SMS）白名单',
	SECURITY_FEATURES_EDIT_3: '编辑语音白名单',
	SECURITY_FEATURES_EDIT_4: '编辑IP、URL、端口黑名单',
	SECURITY_FEATURES_EDIT_5: '编辑IP范围白名单',
	SECURITY_FEATURES_M2M: '选择机对机（M2M） 平台',
	SECURITY_FEATURES_REMOVE: '从白名单中移除',
	UPDATE: '更新',
	SECURITY_FEATURES: '安全特性',
	M2M_ACCOUNT_NAME: '机对机（M2M）账户名称',
	DATA_SUBMITTED: '已提交数据',
	FILTER_CATEGORY_M2MACCOUNT: '机对机（M2M）账户',
	SECURITY_FEATURES_SUCCESSFULLY_EXPORT: '已成功导出',
	SECURITY_FEATURES_FAIL_EXPORT: '输出失败',
	COUNTRYCODE: '国家',
	MINUTE: '分钟',
	MINUTES: '分钟',
	CHART_LEFT_AXIS_MINUTE: '分',
	CHART_LEFT_AXIS_MINUTES: '分',
	CONSUMED_MINUTE: '消费的分钟数',
	CONSUMED_MB: '消费的数据量（MB）',
	CONSUMED_GB: '消费的数据量（GB）',
	CONSUMED_TB: '消费的数据量（TB）',
	CONSUMED_PB: '消费的数据量（PB）',
	CONSUMED_SMS: '消费的短信服务（SMS）',
	NO_DESCRIPTION_FOR_GRAPH: '此图没有描述',
	GRAPHS: '当前可用的图',
	CONSUMED_COUNT: '端点数',
	DOWNLOAD_CHART_AS_PNG: '下载PNG',
	CUSTOMER_INVOICE_ID: '客户ID',
	INVOICE_ID: '账单ID',
	CUSTOMER_INVOICE_NAME: '子账户',
	INVOICE_COUNTRY: '国家',
	ACCESSTECHNOLOGY: '访问技术',
	RATINGZONE: '费率区间',
	RATEPLAN: '费率计划',
	CHOOSE_FILTERS_ON_ANALYTICS_MODAL: '第一步，请选择一个类别',
	NO_FILTERS_ON_ANALYTICS_MODAL: '此类别没有可用的过滤器',
	BILLING_CYCLE: '账单周期',
	FETCHING_DATA: '请稍候。正在检索数据……',
	NO_DATA_RECEIVED: '未收到数据',
	NO_FILTERS_AVAILABLE: '没有可用的过滤器',
	DATE_SUBMITTED: '已提交日期',
	NO_REPORTS_DEFINED: '未定义报告',
	APPLY: '应用',
	OPEN_CHART_SETTINGS_PAGE: '图设置',
	CUSTOMIZE_CHART_DATA: '定制图数据',
	ESTIMATED_AVERAGE_USAGE: '估计的平均用量',
	DELETE_DEFINED_CHART_SETTING_QUESTION: '要删除定义的的图表设置吗？',
	DELETE_DEFINED_CHART_SETTING_MESSAGE: '您是否确实想要删除设置',
	TOTAL_COUNT_BY_SEVERITY: '按严重性显示通知单',
	TOTAL_COUNT_BY_STATUS: '按状态显示通知单',
	INVALID_IP_ADDRESS: '无效IP地址',
	INVOICENUMBER: '账单号',
	CUSTOMERNAME: '客户名称',
	COUNTRYNAME: '国家名称',
	CUSTOMERID: '客户ID',
	DEVICE_WAKE_UP: '设备唤醒',
	DEVICE_WAKE_UP_NOTIFICATION_TITLE: '设备唤醒',
	DEVICE_WAKE_UP_NOTIFICATION_MESSAGE_SUCCESS: '设备唤醒成功',
	DEVICE_WAKE_UP_NOTIFICATION_MESSAGE_FAIL: '设备唤醒失败',

	FILES_FAILED_TO_UPLOAD_MESSAGE: '文件上载失败： ',
	ADD_ANOTHER_TRESHOLD: '添加另一个阈值',
	PIE_CHART_TOTAL: '总数',
	ADD_ATTACHMENT: '添加附件',
	TICKET_FILE_UPLOAD_ERROR: '后台系统出错，您的部分文件尚未更新。',
	FILE_TO_LARGE_TO_UPLOAD: '文件过大，无法上载。允许的最大文件尺寸为3MB。',
	RETRY: '重试',
	NO_CATEGORIES: '本账户没有可用的类别',
	ZONE_NAME: '区名称',
	POOL_ID: '汇总ID',

	// NOTES INVOICES
	ADD_NEW_NOTE: '添加注释',
	EDIT_NOTE: '编辑注释',
	ADD_NOTE_PLACEHOLDER: '在此处输入您的注释',
	INVOICE_NOTE_SUCCESSFULLY_UPDATED: '已成功更新账单注释',
	INVOICE_NOTE_UPDATE_FAIL: '更新账单失败',
	ATTACHMENTS_ADDED: '已添加附件',
	EXTERNAL_LAST_UPDATED_BY: '最后一次更新执行者',
	EXTERNAL_LAST_UPDATED_DATE: '最后一次更新时间/日期',
	REMOVE: '移除',
	EndpointStatus: '状态',
	NetworkOperator: '网络运营者',
	NetworkCountry: '网络国家',
	NetworkType: '网络类型',
	TransferStatus: 'IMSI转让状态',
	NetworkEntitlement: '网络权限ID',
	RecredentialTransferStatus: '重新设定端点状态',
	M2MDateAdded: '机对机（M2M）日期已添加',
	M2MInitialActivationDate: '机对机（M2M）激活日期',
	LAST_UPDATED_BY: '最后一次更新执行者',
	LAST_UPDATED: '最后一次更新',
	INVOICE_NOTE_FILE_ERROR: '后台系统出错，您的部分文件尚未上载。',

	BATCH_HISTORY: '批处理历史记录',
	// DIAGNOSTIC HLRHSSDetails
	GET_HLR_HSS_DETAILS: '获取HLR/HSS详情',
	APN_IDENTIFIER: 'APN识别符',
	IP_ADDRESS_TYPE: 'IP地址类型',
	QOS_PROFILE: 'QOS概况',
	PDN_GW_DYNAMIC_ALLOCATION: 'PDN网关动态分配',
	VPLMN_ADDRESS_ALLOWED: '允许的VPLMN地址',
	MAX_UPLOAD: '最大上载',
	MAX_DOWNLOAD: '最大下载',
	SUBSCRIBER_PROFILE: '订户概况',
	ENDPOINT_ID_ICCID: '端点ID - 集成线路卡识别符（ICCID）',
	BARRING_STATUS: '禁止状态',
	NETWORK_ACCESS: '网络访问',
	CURRENT_VLR_NUMBER: '当前来访位置登记器（VLR）号码',
	PREVIOUS_VLR_NUMBER: '前一个来访位置登记器（VLR）号码',
	CURRENT_SGSN_NUMBER: '当前SGSN号码',
	PREVIOUS_SGSN_NUMBER: '前一个SGSN号码',
	ROAMING_RESTRICTION: '漫游限制',
	DATA_3G_ENABLED: '3G数据已启用',
	NETWORK_STATUS: '网络状态',
	APN_LIST: 'APN列表',

	// DATA HISTORY
	DATA_HISTORY: '数据历史记录',
	DATA_VOLUME_DOWN_KB: '下行数据量（KB）',
	DATA_VOLUME_UP_KB: '上行数据量（KB）',
	DURATION_MINUTES: '持续时间（分钟）',
	SESSION_CHARGING_ID: '会话收费ID',
	ACCESS_POINT_NAME: 'APN',
	DEVICE_IP_V4: '设备IPv4地址',
	DEVICE_IP_V6: '设备IPv6地址',
	RATING_ZONE_NAME: '费率区间名称',
	RATING_GROUP_ID_NAME: '费率分组ID名称',
	RATING_GROUP_ID: '费率分组ID',

	// SMS HISTORY WING
	MESSAGE_HISTORY: '讯息历史记录',
	PORTAL_MESSAGES: '门户讯息',
	SMS_LOG_ID: '短信服务（SMS）记录ID',
	DATE_SENT_RECEIVED: '发出/收到日期',
	SENT_FROM: '发信人',
	SENT_TO: '收信人',
	MESSAGE_TYPE: '讯息类型',
	ZONE_MODEL: '区型号',

	CTD_DATA_USAGE: 'CTD数据用量（MB）',
	CTD_SMS_MO_MT_USAGE: 'CTD短信用量（条）',
	CTD_VOICE_USAGE: 'CTD语音用量（分钟）',

	ACCOUNTS: '账户',
	MUST_BE_BETWEEN_CHARACTERS_LONG: '长度必须在{min}和{max}个字符之间',
	INVALID_DOMAIN_FORMAT: '无效域名格式',
	LAST_UPDATE_DATE_TIME: '最后一次更新时间/日期',
	LAST_UPDATED_BY_USER: '最后一次执行更新的用户',
	INSESSION: '会话中',
	PLEASE_WAIT_THIS_MAY_TAKE_A_FEW_MINUTES: '请稍候。取决于文件大小，本操作可能需要数分钟。',
	NO_RESULTS: '无结果',
	SELECTED_RATE_PLAN: '选择的费率计划',
	HSPA: 'HSPA',
	LTE: 'Lte',
	VLR: 'Vlr',
	SGSN: 'Sgsn',
	EXPORT: '导出',
	FILE: '文件',
	ERROR_CODE: '错误代码',
	ERROR_TYPE: '错误类型',
	AT_LINE: '行数',
	EXPORT_TICKETS: '导出通知单',
	EXPORT_USER_USAGE_DATA: '导出用户使用数据',
	PLACEHOLDER_MODEL: 'AT&T Z431',
	NO_BATCH_ACTIONS_AVAILABLE: '无可用的批处理行动',
	NO_BATCH_ACTIONS_AVAILABLE_SUBTITLE: '本表格目前无可显示的批处理行动',
	NO_BATCH_REPORTS_AVAILABLE: '无可用的批处理报告',
	NO_BATCH_REPORTS_AVAILABLE_SUBTITLE: '本表格目前无可显示的批处理报告',
	MOBILE_ORIGINATING: '移动始发',
	MOBILE_TERMINATING: '移动终止',
	NA: '北美',
	GMNA: 'GM北美',
	POD3: 'ACC Pod3',
	POD19: '企业',
	TABS_ADD_ICON: '创建定制端点表格',
	USAGE_SUMMARIES: '用量总结',
	EMPTY_SPACE: ' ',
	// endpoints rate plans
	'ENDPOINTS_RATE_PLAN.RATE_PLAN_TYPE': '费率计划类型',
	'ENDPOINTS_RATE_PLAN.INCLUDED_TEST_DATA': '包含的测试数据',
	'ENDPOINTS_RATE_PLAN.BILLING_PLATFORM': '账单平台',
	'ENDPOINTS_RATE_PLAN.ACTIVATION_FEE': '激活费',
	'ENDPOINTS_RATE_PLAN.INCLUDED_TEST_SMS': '包含的测试短信',
	'ENDPOINTS_RATE_PLAN.BILLING_ACCOUNT_ID': '账单账户ID',
	'ENDPOINTS_RATE_PLAN.MSU_PRICE': 'MSU价格',
	'ENDPOINTS_RATE_PLAN.INCLUDED_TEST_VOICE': '包含的测试语音',
	'ENDPOINTS_RATE_PLAN.ZONE_MODEL_ID': '区型号ID',
	'ENDPOINTS_RATE_PLAN.TYPE': '类型',
	'ENDPOINTS_RATE_PLAN.INCLUDED_MSU': '包含的MSU',
	'ENDPOINTS_RATE_PLAN.VALID_FROM': '生效起始时间',
	'ENDPOINTS_RATE_PLAN.DEFAULT_RATE_PLAN': '默认费率计划',
	'ENDPOINTS_RATE_PLAN.BASE_RATE_PLAN': '基础费率计划',
	'ENDPOINTS_RATE_PLAN.POSTPAID': '后付费',
	'ENDPOINTS_RATE_PLAN.APPROVED': '已批准',
	'ENDPOINTS_RATE_PLAN.MONTHLY': '每月',
	PASSWORDS_DO_NOT_MATCH: '密码不匹配',
	NO_WHITE_SPACE_ONLY: '文本中仅可包含字母和数字',
	NO_RATE_PLAN_SELECTED: '未选择费率计划',
	NO_RATE_PLANS_UNDERLYING_PLATFORM: '底层平台出现错误，目前不可更改费率计划',
	ENDPOINT_PROVISIONED_STATE_CHANGE_SUCCESS: '端点ID {args1} 已成功配给。IMSI状态被更改为{args2}',
	ENDPOINT_PROVISIONED_STATE_CHANGE_FAIL: '端点ID {args1} 更改IMSI状态后配给失败。 获得支持',

	// AUTH SIGN IN CALLBACK
	AUTHENTICATION_FAILED: '认证失败',
	AUTHENTICATION_IN_PROGRESS: '认证正在进行中。请稍候...',
	MEGABYTES: 'MB',
	TICKETS: '通知单',
	USER_GROUPS: '用户群组',
	RECENT_SESSIONS: '最近会话',
	ACTIVE_SESSION_SESSION_START_TIME: '会话开始时间',
	ACTIVE_SESSION_TOTAL_VOLUME: '总量',
	ACTIVE_SESSION_TRANSMITTING: '传输中',
	ACTIVE_SESSION_RECEIVING: '接收中',
	CURRENTLY_ACTIVE_SESSION: '当前活跃会话',
	ACTIVE_SESSION_CARRIER: 'CARRIER',
	ACTIVE_SESSION_IP_ADDRESS: 'IP地址',
	RESET_TO_DEFAULT: '恢复默认',
	DATE_TIME: '时间/日期',
	SESSION_END: '会话结束',
	SEND: '发送',
	SEND_SMS_TO_THIS_DEVICE: '向该设备发送短信。',
	TYPE_A_MESSAGE: '请输入您希望通过短信服务向该设备发送的消息。可能需要缴纳标准费率。',
	MESSAGES_WILL_APPEAR: '消息将记录在短信用量报告中，状态为已发送。可能需要缴纳费用。',
	SENT_MESSAGES_HISTORY: '已发消息历史记录',
	CHARACTERS_REMAINING: '剩余 {remaining} 个字符...',
	EXPORT_SESSIONS_DATA_FOR_ID: '{id} 的最近会话',
	EXPORT_DATA: '输出数据',
	BYTE_ABBREVIATION: 'B',
	UTC_TIME_ZONE: ' UTC ',
	SEND_SMS_TO_ENDPOINT: '向端点发送短信',
	SMS: '短信服务（SMS）',
	OFFPEAK_USAGE_FLAG: '非高峰时段用量标记',
	DATA_USAGE_TOTAL: '数据用量 - 原始总计',
	DATA_USAGE_UPLINK: '数据用量 — 原始上行',
	DATA_USAGE_DOWNLINK: '数据用量 — 原始下行',
	DATA_USAGE_ROUNDED: '数据用量 — 舍入',
	DEVICE_IP_ADDRESS: '设备IP地址',
	SESSION_DURATION: '会话持续时间',
	RECORD_SEQUENCE_NUMBER: '记录序列号',
	CHARGING_ID: '收费ID',
	SESSION_CLOSE_CAUSE: '会话关闭原因',
	TECH_TYPE: '技术类型',
	PGW_ADDRESS: 'PGW地址',
	TIME_OF_FIRST_USAGE: '首次使用时间',
	TIME_OF_LAST_USAGE: '末次使用时间',
	TIME_USAGE_DURATION: '用量持续时间',
	STREAM_ID: '流ID',
	CELL_GLOBAL_IDENTIFIER: 'CGI（全球蜂窝识别符）',
	SERVICE_AREA_IDENTIFIER: 'SAI（服务区域识别符）',
	ROUTING_AREA_IDENTIFIER: 'RAI（路由区域识别符）',
	TRACKING_AREA_IDENTITY: 'TAI（追踪区域识别符）',
	E_UTRAN_CGI: 'ECGI（E-UTRAN CGI）',
	LOCATION_AREA_IDENTIFIER: 'LAI（地点区域识别符）',
	SERVING_SGSN: '提供服务的SGSN',
	COUNTRY_LASTEST_SESSION: '国家（最近一次会话）',
	CARRIER_LASTEST_SESSION: '运营商（最近一次会话）',
	BILLABLE_FLAG: '可计费标记',
	CYCLE_MONTH: '周期月',
	CYCLE_YEAR: '周期年',
	ASSIGNED_RATE_PLAN_ID: '指派的费率计划ID',
	ASSIGNED_RATING_ZONE_ID: '指派的费率区间ID',
	RATE_PLAN_VERSION_ID: '费率计划版本ID',
	SERVICE_TYPE: '服务类型',
	TRAFFIC_DETAIL_ID: '流量详情ID',
	RECORD_RECEIVED_DATE: '收到记录的日期',
	RECORD_OPEN_TIME: '记录开启时间',
	OPERATOR_NETWORK: '运营者网络',
	OPERATOR_ACCOUNT_ID: '运营者账户ID',
	TAP_CODE: 'TAP代码',
	USAGE_HISTORY: '用量历史',
	DATA: '数据',
	VOICE: '语音',
	HLR_HSS_DETAILS_FOR_ENDPOINT: '端点ID {id} 的HLR/HSS详情',
	NEED_HELP: '需要帮助吗？',
	MB: ' MB',
	CONNECTION_STATUS: '连接状态',
	MESSAGE_CONTENT: '消息正文',
	B: 'B',
	CYCLE_TO_DATE_USAGE_TOTAL: '当前周期至今的总用量',
	AVERAGE_DAILY_USE: '平均每日用量',
	DATA_USAGE_DOWNLINK_CHART: '下行',
	DATA_USAGE_UPLINK_CHART: '上行',
	GDSP: 'GDSP',
	SECOND: '秒',
	SECONDS: '秒',
	DURATION_RAW: '持续时间 - 原始',
	DURATION_ROUNDED: '持续时间 - 取整',
	USAGE_TYPE: '用量类型',
	SELECT_BILLING_CYCLE: '选择账单周期',
	RANGE: '范围',
	CUSTOM_FIELDS_VALUES_UPDATED: '定制字段值已更新',
	CUSTOM_FIELDS_VALUES_UPDATED_ERROR: '定制字段值更新失败',
	SXMACC: 'SXMACC',
	CHARTS_BILLING_CYCLE_ABBREVIATION: '账单周期 {billcycle}',
	DATE_RANGE_INPUT_LABEL: '从: {startDate} 到: {endDate}',
	UH_OH_THERE_WERE_SOME_PROBLEMS_TRYING_TO_LOG_YOU_IN_PLEASE_RETRY_AGAIN_IN_A_FEW_MOMENTS: '哎呀，某些错误导致您无法登录。请稍后再重试。如果同样的问题依然出现，请联系客户支持团队。',

	//  LANGUAGE OPTIONS
	ENGLISH: 'English (USA)',
	FRANCAIS: 'Français (France)',
	ESPANOL: 'Español (España)',
	ITALIANO: 'Italiano (Italia)',
	NEDERLANDS: 'Netherlands (Holland)',
	DEUTCHE: 'Deutsche (Deutschland)',
	PORTUGUES: 'Português (Brasil)',
	CHINESE: '中文 (中国)',

	//  COUNTRIES
	COUNTRY_UNITED_STATES: 'United States',
	COUNTRY_AFGHANISTAN: 'Afghanistan',
	COUNTRY_ALAND_ISLANDS: 'Aland Islands',
	COUNTRY_ALBANIA: 'Albania',
	COUNTRY_ALGERIA: 'Algeria',
	COUNTRY_AMERICAN_SAMOA: 'American Samoa',
	COUNTRY_ANDORRA: 'Andorra',
	COUNTRY_ANGOLA: 'Angola',
	COUNTRY_ANGUILLA: 'Anguilla',
	COUNTRY_ANTARCTICA: 'Antarctica',
	COUNTRY_ANTIGUA_AND_BARBUDA: 'Antigua And Barbuda',
	COUNTRY_ARGENTINA: 'Argentina',
	COUNTRY_ARMENIA: 'Armenia',
	COUNTRY_ARUBA: 'Aruba',
	COUNTRY_AUSTRALIA: 'Australia',
	COUNTRY_AUSTRIA: 'Austria',
	COUNTRY_AZERBAIJAN: 'Azerbaijan',
	COUNTRY_BAHAMAS_THE: 'Bahamas The',
	COUNTRY_BAHRAIN: 'Bahrain',
	COUNTRY_BANGLADESH: 'Bangladesh',
	COUNTRY_BARBADOS: 'Barbados',
	COUNTRY_BELARUS: 'Belarus',
	COUNTRY_BELGIUM: 'Belgium',
	COUNTRY_BELIZE: 'Belize',
	COUNTRY_BENIN: 'Benin',
	COUNTRY_BERMUDA: 'Bermuda',
	COUNTRY_BHUTAN: 'Bhutan',
	COUNTRY_BOLIVIA: 'Bolivia',
	COUNTRY_BOSNIA_AND_HERZEGOVINA: 'Bosnia and Herzegovina',
	COUNTRY_BOTSWANA: 'Botswana',
	COUNTRY_BOUVET_ISLAND: 'Bouvet Island',
	COUNTRY_BRAZIL: 'Brazil',
	COUNTRY_BRITISH_INDIAN_OCEAN_TERRITORY: 'British Indian Ocean Territory',
	COUNTRY_BRUNEI: 'Brunei',
	COUNTRY_BULGARIA: 'Bulgaria',
	COUNTRY_BURKINA_FASO: 'Burkina Faso',
	COUNTRY_BURUNDI: 'Burundi',
	COUNTRY_CAMBODIA: 'Cambodia',
	COUNTRY_CAMEROON: 'Cameroon',
	COUNTRY_CANADA: 'Canada',
	COUNTRY_CAPE_VERDE: 'Cape Verde',
	COUNTRY_CAYMAN_ISLANDS: 'Cayman Islands',
	COUNTRY_CENTRAL_AFRICAN_REPUBLIC: 'Central African Republic',
	COUNTRY_CHAD: 'Chad',
	COUNTRY_CHILE: 'Chile',
	COUNTRY_CHINA: '中国',
	COUNTRY_CHRISTMAS_ISLAND: 'Christmas Island',
	COUNTRY_COCOS_KEELING_ISLANDS: 'Cocos (Keeling) Islands',
	COUNTRY_COLOMBIA: 'Colombia',
	COUNTRY_COMOROS: 'Comoros',
	COUNTRY_CONGO: 'Congo',
	COUNTRY_CONGO_THE_DEMOCRATIC_REPUBLIC_OF_THE: 'Congo The Democratic Republic Of The',
	COUNTRY_COOK_ISLANDS: 'Cook Islands',
	COUNTRY_COSTA_RICA: 'Costa Rica',
	COUNTRY_COTE_D__IVOIRE_IVORY_COAST_: "Cote D'Ivoire (Ivory Coast)",
	COUNTRY_CROATIA_HRVATSKA_: 'Croatia (Hrvatska)',
	COUNTRY_CUBA: 'Cuba',
	COUNTRY_CYPRUS: 'Cyprus',
	COUNTRY_CZECH_REPUBLIC: 'Czech Republic',
	COUNTRY_DENMARK: 'Denmark',
	COUNTRY_DJIBOUTI: 'Djibouti',
	COUNTRY_DOMINICA: 'Dominica',
	COUNTRY_DOMINICAN_REPUBLIC: 'Dominican Republic',
	COUNTRY_EAST_TIMOR: 'East Timor',
	COUNTRY_ECUADOR: 'Ecuador',
	COUNTRY_EGYPT: 'Egypt',
	COUNTRY_EL_SALVADOR: 'El Salvador',
	COUNTRY_EQUATORIAL_GUINEA: 'Equatorial Guinea',
	COUNTRY_ERITREA: 'Eritrea',
	COUNTRY_ESTONIA: 'Estonia',
	COUNTRY_ETHIOPIA: 'Ethiopia',
	COUNTRY_FALKLAND_ISLANDS: 'Falkland Islands',
	COUNTRY_FAROE_ISLANDS: 'Faroe Islands',
	COUNTRY_FIJI_ISLANDS: 'Fiji Islands',
	COUNTRY_FINLAND: 'Finland',
	COUNTRY_FRANCE: 'France',
	COUNTRY_FRENCH_GUIANA: 'French Guiana',
	COUNTRY_FRENCH_POLYNESIA: 'French Polynesia',
	COUNTRY_FRENCH_SOUTHERN_TERRITORIES: 'French Southern Territories',
	COUNTRY_GABON: 'Gabon',
	COUNTRY_GAMBIA_THE: 'Gambia The',
	COUNTRY_GEORGIA: 'Georgia',
	COUNTRY_GERMANY: 'Germany',
	COUNTRY_GHANA: 'Ghana',
	COUNTRY_GIBRALTAR: 'Gibraltar',
	COUNTRY_GREECE: 'Greece',
	COUNTRY_GREENLAND: 'Greenland',
	COUNTRY_GRENADA: 'Grenada',
	COUNTRY_GUADELOUPE: 'Guadeloupe',
	COUNTRY_GUAM: 'Guam',
	COUNTRY_GUATEMALA: 'Guatemala',
	COUNTRY_GUERNSEY_AND_ALDERNEY: 'Guernsey and Alderney',
	COUNTRY_GUINEA: 'Guinea',
	COUNTRY_GUINEA_BISSAU: 'Guinea-Bissau',
	COUNTRY_GUYANA: 'Guyana',
	COUNTRY_HAITI: 'Haiti',
	COUNTRY_HEARD_AND_MCDONALD_ISLANDS: 'Heard and McDonald Islands',
	COUNTRY_HONDURAS: 'Honduras',
	'COUNTRY_HONG_KONG_S.A.R.': 'Hong Kong S.A.R.',
	COUNTRY_HUNGARY: 'Hungary',
	COUNTRY_ICELAND: 'Iceland',
	COUNTRY_INDIA: 'India',
	COUNTRY_INDONESIA: 'Indonesia',
	COUNTRY_IRAN: 'Iran',
	COUNTRY_IRAQ: 'Iraq',
	COUNTRY_IRELAND: 'Ireland',
	COUNTRY_ISRAEL: 'Israel',
	COUNTRY_ITALY: 'Italy',
	COUNTRY_JAMAICA: 'Jamaica',
	COUNTRY_JAPAN: 'Japan',
	COUNTRY_JERSEY: 'Jersey',
	COUNTRY_JORDAN: 'Jordan',
	COUNTRY_KAZAKHSTAN: 'Kazakhstan',
	COUNTRY_KENYA: 'Kenya',
	COUNTRY_KIRIBATI: 'Kiribati',
	COUNTRY_KOREA_NORTH: 'Korea North',
	COUNTRY_KOREA_SOUTH: 'Korea South',
	COUNTRY_KUWAIT: 'Kuwait',
	COUNTRY_KYRGYZSTAN: 'Kyrgyzstan',
	COUNTRY_LAOS: 'Laos',
	COUNTRY_LATVIA: 'Latvia',
	COUNTRY_LEBANON: 'Lebanon',
	COUNTRY_LESOTHO: 'Lesotho',
	COUNTRY_LIBERIA: 'Liberia',
	COUNTRY_LIBYA: 'Libya',
	COUNTRY_LIECHTENSTEIN: 'Liechtenstein',
	COUNTRY_LITHUANIA: 'Lithuania',
	COUNTRY_LUXEMBOURG: 'Luxembourg',
	'COUNTRY_MACAU_S.A.R.': 'Macau S.A.R.',
	COUNTRY_MACEDONIA: 'Macedonia',
	COUNTRY_MADAGASCAR: 'Madagascar',
	COUNTRY_MALAWI: 'Malawi',
	COUNTRY_MALAYSIA: '马来西亚站',
	COUNTRY_MALDIVES: 'Maldives',
	COUNTRY_MALI: 'Mali',
	COUNTRY_MALTA: 'Malta',
	COUNTRY_MAN_ISLE_OF_: 'Man (Isle of)',
	COUNTRY_MARSHALL_ISLANDS: 'Marshall Islands',
	COUNTRY_MARTINIQUE: 'Martinique',
	COUNTRY_MAURITANIA: 'Mauritania',
	COUNTRY_MAURITIUS: 'Mauritius',
	COUNTRY_MAYOTTE: 'Mayotte',
	COUNTRY_MEXICO: 'Mexico',
	COUNTRY_MICRONESIA: 'Micronesia',
	COUNTRY_MOLDOVA: 'Moldova',
	COUNTRY_MONACO: 'Monaco',
	COUNTRY_MONGOLIA: 'Mongolia',
	COUNTRY_MONTSERRAT: 'Montserrat',
	COUNTRY_MOROCCO: 'Morocco',
	COUNTRY_MOZAMBIQUE: 'Mozambique',
	COUNTRY_MYANMAR: 'Myanmar',
	COUNTRY_NAMIBIA: 'Namibia',
	COUNTRY_NAURU: 'Nauru',
	COUNTRY_NEPAL: 'Nepal',
	COUNTRY_NETHERLANDS: 'Netherlands',
	COUNTRY_NETHERLANDS_ANTILLES: 'Netherlands Antilles',
	COUNTRY_NEW_CALEDONIA: 'New Caledonia',
	COUNTRY_NEW_ZEALAND: 'New Zealand',
	COUNTRY_NICARAGUA: 'Nicaragua',
	COUNTRY_NIGER: 'Niger',
	COUNTRY_NIGERIA: 'Nigeria',
	COUNTRY_NIUE: 'Niue',
	COUNTRY_NORFOLK_ISLAND: 'Norfolk Island',
	COUNTRY_NORTHERN_MARIANA_ISLANDS: 'Northern Mariana Islands',
	COUNTRY_NORWAY: 'Norway',
	COUNTRY_OMAN: 'Oman',
	COUNTRY_PAKISTAN: 'Pakistan',
	COUNTRY_PALAU: 'Palau',
	COUNTRY_PALESTINIAN_TERRITORY_OCCUPIED: 'Palestinian Territory Occupied',
	COUNTRY_PANAMA: 'Panama',
	COUNTRY_PAPUA_NEW_GUINEA: 'Papua new Guinea',
	COUNTRY_PARAGUAY: 'Paraguay',
	COUNTRY_PERU: 'Peru',
	COUNTRY_PHILIPPINES: 'Philippines',
	COUNTRY_PITCAIRN_ISLAND: 'Pitcairn Island',
	COUNTRY_POLAND: 'Poland',
	COUNTRY_PORTUGAL: 'Portugal',
	COUNTRY_PUERTO_RICO: 'Puerto Rico',
	COUNTRY_QATAR: 'Qatar',
	COUNTRY_REUNION: 'Reunion',
	COUNTRY_ROMANIA: 'Romania',
	COUNTRY_RUSSIA: 'Russia',
	COUNTRY_RWANDA: 'Rwanda',
	COUNTRY_SAINT_HELENA: 'Saint Helena',
	COUNTRY_SAINT_KITTS_AND_NEVIS: 'Saint Kitts And Nevis',
	COUNTRY_SAINT_LUCIA: 'Saint Lucia',
	COUNTRY_SAINT_PIERRE_AND_MIQUELON: 'Saint Pierre and Miquelon',
	COUNTRY_SAINT_VINCENT_AND_THE_GRENADINES: 'Saint Vincent And The Grenadines',
	COUNTRY_SAMOA: 'Samoa',
	COUNTRY_SAN_MARINO: 'San Marino',
	COUNTRY_SAO_TOME_AND_PRINCIPE: 'Sao Tome and Principe',
	COUNTRY_SAUDI_ARABIA: 'Saudi Arabia',
	COUNTRY_SENEGAL: 'Senegal',
	COUNTRY_SERBIA: 'Serbia',
	COUNTRY_SEYCHELLES: 'Seychelles',
	COUNTRY_SIERRA_LEONE: 'Sierra Leone',
	COUNTRY_SINGAPORE: 'Singapore',
	COUNTRY_SLOVAKIA: 'Slovakia',
	COUNTRY_SLOVENIA: 'Slovenia',
	COUNTRY_SOLOMON_ISLANDS: 'Solomon Islands',
	COUNTRY_SOMALIA: 'Somalia',
	COUNTRY_SOUTH_AFRICA: 'South Africa',
	COUNTRY_SOUTH_GEORGIA: 'South Georgia',
	COUNTRY_SOUTH_SUDAN: 'South Sudan',
	COUNTRY_SPAIN: 'Spain',
	COUNTRY_SRI_LANKA: 'Sri Lanka',
	COUNTRY_SUDAN: 'Sudan',
	COUNTRY_SURINAME: 'Suriname',
	COUNTRY_SVALBARD_AND_JAN_MAYEN_ISLANDS: 'Svalbard And Jan Mayen Islands',
	COUNTRY_SWAZILAND: 'Swaziland',
	COUNTRY_SWEDEN: 'Sweden',
	COUNTRY_SWITZERLAND: 'Switzerland',
	COUNTRY_SYRIA: 'Syria',
	COUNTRY_TAIWAN: 'Taiwan',
	COUNTRY_TAJIKISTAN: 'Tajikistan',
	COUNTRY_TANZANIA: 'Tanzania',
	COUNTRY_THAILAND: 'Thailand',
	COUNTRY_TOGO: 'Togo',
	COUNTRY_TOKELAU: 'Tokelau',
	COUNTRY_TONGA: 'Tonga',
	COUNTRY_TRINIDAD_AND_TOBAGO: 'Trinidad And Tobago',
	COUNTRY_TUNISIA: 'Tunisia',
	COUNTRY_TURKEY: 'Turkey',
	COUNTRY_TURKMENISTAN: 'Turkmenistan',
	COUNTRY_TURKS_AND_CAICOS_ISLANDS: 'Turks And Caicos Islands',
	COUNTRY_TUVALU: 'Tuvalu',
	COUNTRY_UGANDA: 'Uganda',
	COUNTRY_UKRAINE: 'Ukraine',
	COUNTRY_UNITED_ARAB_EMIRATES: 'United Arab Emirates',
	COUNTRY_UNITED_KINGDOM: 'United Kingdom',
	COUNTRY_UNITED_STATES_MINOR_OUTLYING_ISLANDS: 'United States Minor Outlying Islands',
	COUNTRY_URUGUAY: 'Uruguay',
	COUNTRY_UZBEKISTAN: 'Uzbekistan',
	COUNTRY_VANUATU: 'Vanuatu',
	COUNTRY_VATICAN_CITY_STATE_HOLY_SEE_: 'Vatican City State (Holy See)',
	COUNTRY_VENEZUELA: 'Venezuela',
	COUNTRY_VIETNAM: 'Vietnam',
	COUNTRY_VIRGIN_ISLANDS_BRITISH_: 'Virgin Islands (British)',
	COUNTRY_VIRGIN_ISLANDS_US_: 'Virgin Islands (US)',
	COUNTRY_WALLIS_AND_FUTUNA_ISLANDS: 'Wallis And Futuna Islands',
	COUNTRY_WESTERN_SAHARA: 'Western Sahara',
	COUNTRY_YEMEN: 'Yemen',
	COUNTRY_ZAMBIA: 'Zambia',
	COUNTRY_ZIMBABWE: 'Zimbabwe',

	//  州/省
	STATE_ANDAMAN_AND_NICOBAR_ISLANDS: 'Andaman and Nicobar Islands',
	STATE_ANDHRA_PRADESH: 'Andhra Pradesh',
	STATE_ARUNACHAL_PRADESH: 'Arunachal Pradesh',
	STATE_ASSAM: 'Assam',
	STATE_BIHAR: 'Bihar',
	STATE_CHANDIGARH: 'Chandigarh',
	STATE_CHHATTISGARH: 'Chhattisgarh',
	STATE_DADRA_AND_NAGAR_HAVELI: 'Dadra and Nagar Haveli',
	STATE_DAMAN_AND_DIU: 'Daman and Diu',
	STATE_DELHI: 'Delhi',
	STATE_GOA: 'Goa',
	STATE_GUJARAT: 'Gujarat',
	STATE_HARYANA: 'Haryana',
	STATE_HIMACHAL_PRADESH: 'Himachal Pradesh',
	STATE_JAMMU_AND_KASHMIR: 'Jammu and Kashmir',
	STATE_JHARKHAND: 'Jharkhand',
	STATE_KARNATAKA: 'Karnataka',
	STATE_KENMORE: 'Kenmore',
	STATE_KERALA: 'Kerala',
	STATE_LAKSHADWEEP: 'Lakshadweep',
	STATE_MADHYA_PRADESH: 'Madhya Pradesh',
	STATE_MAHARASHTRA: 'Maharashtra',
	STATE_MANIPUR: 'Manipur',
	STATE_MEGHALAYA: 'Meghalaya',
	STATE_MIZORAM: 'Mizoram',
	STATE_NAGALAND: 'Nagaland',
	STATE_NARORA: 'Narora',
	STATE_NATWAR: 'Natwar',
	STATE_ODISHA: 'Odisha',
	STATE_PASCHIM_MEDINIPUR: 'Paschim Medinipur',
	STATE_PONDICHERRY: 'Pondicherry',
	STATE_PUNJAB: 'Punjab',
	STATE_RAJASTHAN: 'Rajasthan',
	STATE_SIKKIM: 'Sikkim',
	STATE_TAMIL_NADU: 'Tamil Nadu',
	STATE_TELANGANA: 'Telangana',
	STATE_TRIPURA: 'Tripura',
	STATE_UTTAR_PRADESH: 'Uttar Pradesh',
	STATE_UTTARAKHAND: 'Uttarakhand',
	STATE_VAISHALI: 'Vaishali',
	STATE_WEST_BENGAL: 'West Bengal',
	STATE_BADAKHSHAN: 'Badakhshan',
	STATE_BADGIS: 'Badgis',
	STATE_BAGLAN: 'Baglan',
	STATE_BALKH: 'Balkh',
	STATE_BAMIYAN: 'Bamiyan',
	STATE_FARAH: 'Farah',
	STATE_FARYAB: 'Faryab',
	STATE_GAWR: 'Gawr',
	STATE_GAZNI: 'Gazni',
	STATE_HERAT: 'Herat',
	STATE_ALABAMA: 'Alabama',
	STATE_ALASKA: 'Alaska',
	STATE_ARIZONA: 'Arizona',
	STATE_ARKANSAS: 'Arkansas',
	STATE_GEORGIA: 'Georgia',
	STATE_NEW_YORK: 'New York',
	STATE_VERMONT: 'Vermont',
	STATE_WASHINGTON: 'Washington',
	STATE_HILMAND: 'Hilmand',
	STATE_JAWZJAN: 'Jawzjan',
	STATE_KABUL: 'Kabul',
	STATE_KAPISA: 'Kapisa',
	STATE_KHAWST: 'Khawst',
	STATE_KUNAR: 'Kunar',
	STATE_LAGMAN: 'Lagman',
	STATE_LAWGHAR: 'Lawghar',
	STATE_NANGARHAR: 'Nangarhar',
	STATE_NIMRUZ: 'Nimruz',
	STATE_NURISTAN: 'Nuristan',
	STATE_PAKTIKA: 'Paktika',
	STATE_PAKTIYA: 'Paktiya',
	STATE_PARWAN: 'Parwan',
	STATE_QANDAHAR: 'Qandahar',
	STATE_QUNDUZ: 'Qunduz',
	STATE_SAMANGAN: 'Samangan',
	STATE_SAR_E_PUL: 'Sar-e Pul',
	STATE_TAKHAR: 'Takhar',
	STATE_URUZGAN: 'Uruzgan',
	STATE_WARDAG: 'Wardag',
	STATE_ZABUL: 'Zabul',
	STATE_BERAT: 'Berat',
	STATE_BULQIZE: 'Bulqize',
	STATE_DELVINE: 'Delvine',
	STATE_DEVOLL: 'Devoll',
	STATE_DIBRE: 'Dibre',
	STATE_DURRES: 'Durres',
	STATE_ELBASAN: 'Elbasan',
	STATE_FIER: 'Fier',
	STATE_GJIROKASTER: 'Gjirokaster',
	STATE_GRAMSH: 'Gramsh',
	STATE_HAS: 'Has',
	STATE_KAVAJE: 'Kavaje',
	STATE_KOLONJE: 'Kolonje',
	STATE_KORCE: 'Korce',
	STATE_KRUJE: 'Kruje',
	STATE_KUCOVE: 'Kucove',
	STATE_KUKES: 'Kukes',
	STATE_KURBIN: 'Kurbin',
	STATE_LEZHE: 'Lezhe',
	STATE_LIBRAZHD: 'Librazhd',
	STATE_LUSHNJE: 'Lushnje',
	STATE_MALLAKASTER: 'Mallakaster',
	STATE_MALSI_E_MADHE: 'Malsi e Madhe',
	STATE_MAT: 'Mat',
	STATE_MIRDITE: 'Mirdite',
	STATE_PEQIN: 'Peqin',
	STATE_PERMET: 'Permet',
	STATE_POGRADEC: 'Pogradec',
	STATE_PUKE: 'Puke',
	STATE_SARANDE: 'Sarande',
	STATE_SHKODER: 'Shkoder',
	STATE_SKRAPAR: 'Skrapar',
	STATE_TEPELENE: 'Tepelene',
	STATE_TIRANE: 'Tirane',
	STATE_TROPOJE: 'Tropoje',
	STATE_VLORE: 'Vlore',
	STATE_AYN_DAFLAH: 'Ayn Daflah',
	STATE_AYN_TAMUSHANAT: 'Ayn Tamushanat',
	STATE_ADRAR: 'Adrar',
	STATE_ALGIERS: 'Algiers',
	STATE_ANNABAH: 'Annabah',
	STATE_BASHSHAR: 'Bashshar',
	STATE_BATNAH: 'Batnah',
	STATE_BIJAYAH: 'Bijayah',
	STATE_BISKRAH: 'Biskrah',
	STATE_BLIDAH: 'Blidah',
	STATE_BUIRAH: 'Buirah',
	STATE_BUMARDAS: 'Bumardas',
	STATE_BURJ_BU_ARRIRIJ: 'Burj Bu Arririj',
	STATE_GHALIZAN: 'Ghalizan',
	STATE_GHARDAYAH: 'Ghardayah',
	STATE_ILIZI: 'Ilizi',
	STATE_JIJILI: 'Jijili',
	STATE_JILFAH: 'Jilfah',
	STATE_KHANSHALAH: 'Khanshalah',
	STATE_MASILAH: 'Masilah',
	STATE_MIDYAH: 'Midyah',
	STATE_MILAH: 'Milah',
	STATE_MUASKAR: 'Muaskar',
	STATE_MUSTAGHANAM: 'Mustaghanam',
	STATE_NAAMA: 'Naama',
	STATE_ORAN: 'Oran',
	STATE_OUARGLA: 'Ouargla',
	STATE_QALMAH: 'Qalmah',
	STATE_QUSTANTINAH: 'Qustantinah',
	STATE_SAKIKDAH: 'Sakikdah',
	STATE_SATIF: 'Satif',
	STATE_SAYDA: 'Sayda',
	STATE_SIDI_BAN_AL___ABBAS: "Sidi ban-al-'Abbas",
	STATE_SUQ_AHRAS: 'Suq Ahras',
	STATE_TAMANGHASAT: 'Tamanghasat',
	STATE_TIBAZAH: 'Tibazah',
	STATE_TIBISSAH: 'Tibissah',
	STATE_TILIMSAN: 'Tilimsan',
	STATE_TINDUF: 'Tinduf',
	STATE_TISAMSILT: 'Tisamsilt',
	STATE_TIYARAT: 'Tiyarat',
	STATE_TIZI_WAZU: 'Tizi Wazu',
	STATE_UMM_AL_BAWAGHI: 'Umm-al-Bawaghi',
	STATE_WAHRAN: 'Wahran',
	STATE_WARQLA: 'Warqla',
	STATE_WILAYA_D_ALGER: 'Wilaya d Alger',
	STATE_WILAYA_DE_BEJAIA: 'Wilaya de Bejaia',
	STATE_WILAYA_DE_CONSTANTINE: 'Wilaya de Constantine',
	STATE_AL_AGHWAT: 'al-Aghwat',
	STATE_AL_BAYADH: 'al-Bayadh',
	STATE_AL_JAZA__IR: "al-Jaza'ir",
	STATE_AL_WAD: 'al-Wad',
	STATE_ASH_SHALIF: 'ash-Shalif',
	STATE_AT_TARIF: 'at-Tarif',
	STATE_EASTERN: 'Eastern',
	STATE_MANU__A: "Manu'a",
	STATE_SWAINS_ISLAND: 'Swains Island',
	STATE_WESTERN: 'Western',
	STATE_ANDORRA_LA_VELLA: 'Andorra la Vella',
	STATE_CANILLO: 'Canillo',
	STATE_ENCAMP: 'Encamp',
	STATE_LA_MASSANA: 'La Massana',
	STATE_LES_ESCALDES: 'Les Escaldes',
	STATE_ORDINO: 'Ordino',
	STATE_SANT_JULIA_DE_LORIA: 'Sant Julia de Loria',
	STATE_BENGO: 'Bengo',
	STATE_BENGUELA: 'Benguela',
	STATE_BIE: 'Bie',
	STATE_CABINDA: 'Cabinda',
	STATE_CUNENE: 'Cunene',
	STATE_HUAMBO: 'Huambo',
	STATE_HUILA: 'Huila',
	STATE_KUANDO_KUBANGO: 'Kuando-Kubango',
	STATE_KWANZA_NORTE: 'Kwanza Norte',
	STATE_KWANZA_SUL: 'Kwanza Sul',
	STATE_LUANDA: 'Luanda',
	STATE_LUNDA_NORTE: 'Lunda Norte',
	STATE_LUNDA_SUL: 'Lunda Sul',
	STATE_MALANJE: 'Malanje',
	STATE_MOXICO: 'Moxico',
	STATE_NAMIBE: 'Namibe',
	STATE_UIGE: 'Uige',
	STATE_ZAIRE: 'Zaire',
	STATE_OTHER_PROVINCES: 'Other Provinces',
	STATE_SECTOR_CLAIMED_BY_ARGENTINA_CH: 'Sector claimed by Argentina/Ch',
	STATE_SECTOR_CLAIMED_BY_ARGENTINA_UK: 'Sector claimed by Argentina/UK',
	STATE_SECTOR_CLAIMED_BY_AUSTRALIA: 'Sector claimed by Australia',
	STATE_SECTOR_CLAIMED_BY_FRANCE: 'Sector claimed by France',
	STATE_SECTOR_CLAIMED_BY_NEW_ZEALAND: 'Sector claimed by New Zealand',
	STATE_SECTOR_CLAIMED_BY_NORWAY: 'Sector claimed by Norway',
	STATE_UNCLAIMED_SECTOR: 'Unclaimed Sector',
	STATE_BARBUDA: 'Barbuda',
	STATE_SAINT_GEORGE: 'Saint George',
	STATE_SAINT_JOHN: 'Saint John',
	STATE_SAINT_MARY: 'Saint Mary',
	STATE_SAINT_PAUL: 'Saint Paul',
	STATE_SAINT_PETER: 'Saint Peter',
	STATE_SAINT_PHILIP: 'Saint Philip',
	STATE_BUENOS_AIRES: 'Buenos Aires',
	STATE_CATAMARCA: 'Catamarca',
	STATE_CHACO: 'Chaco',
	STATE_CHUBUT: 'Chubut',
	STATE_CORDOBA: 'Cordoba',
	STATE_CORRIENTES: 'Corrientes',
	STATE_DISTRITO_FEDERAL: 'Distrito Federal',
	STATE_ENTRE_RIOS: 'Entre Rios',
	STATE_FORMOSA: 'Formosa',
	STATE_JUJUY: 'Jujuy',
	STATE_LA_PAMPA: 'La Pampa',
	STATE_HIDD: 'Hidd',
	STATE_LA_RIOJA: 'La Rioja',
	STATE_MENDOZA: 'Mendoza',
	STATE_MISIONES: 'Misiones',
	STATE_NEUQUEN: 'Neuquen',
	STATE_RIO_NEGRO: 'Rio Negro',
	STATE_SALTA: 'Salta',
	STATE_SAN_JUAN: 'San Juan',
	STATE_SAN_LUIS: 'San Luis',
	STATE_SANTA_CRUZ: 'Santa Cruz',
	STATE_SANTA_FE: 'Santa Fe',
	STATE_SANTIAGO_DEL_ESTERO: 'Santiago del Estero',
	STATE_TIERRA_DEL_FUEGO: 'Tierra del Fuego',
	STATE_TUCUMAN: 'Tucuman',
	STATE_ARAGATSOTN: 'Aragatsotn',
	STATE_ARARAT: 'Ararat',
	STATE_ARMAVIR: 'Armavir',
	STATE_GEGHARKUNIK: 'Gegharkunik',
	STATE_KOTAIK: 'Kotaik',
	STATE_LORI: 'Lori',
	STATE_SHIRAK: 'Shirak',
	STATE_STEPANAKERT: 'Stepanakert',
	STATE_SYUNIK: 'Syunik',
	STATE_TAVUSH: 'Tavush',
	STATE_VAYOTS_DZOR: 'Vayots Dzor',
	STATE_YEREVAN: 'Yerevan',
	STATE_ARUBA: 'Aruba',
	STATE_AUCKLAND: 'Auckland',
	STATE_AUSTRALIAN_CAPITAL_TERRITORY: 'Australian Capital Territory',
	STATE_BALGOWLAH: 'Balgowlah',
	STATE_BALMAIN: 'Balmain',
	STATE_BANKSTOWN: 'Bankstown',
	STATE_BAULKHAM_HILLS: 'Baulkham Hills',
	STATE_BONNET_BAY: 'Bonnet Bay',
	STATE_CAMBERWELL: 'Camberwell',
	STATE_CAROLE_PARK: 'Carole Park',
	STATE_CASTLE_HILL: 'Castle Hill',
	STATE_CAULFIELD: 'Caulfield',
	STATE_CHATSWOOD: 'Chatswood',
	STATE_CHELTENHAM: 'Cheltenham',
	STATE_CHERRYBROOK: 'Cherrybrook',
	STATE_CLAYTON: 'Clayton',
	STATE_COLLINGWOOD: 'Collingwood',
	STATE_FRENCHS_FOREST: 'Frenchs Forest',
	STATE_HAWTHORN: 'Hawthorn',
	STATE_JANNNALI: 'Jannnali',
	STATE_KNOXFIELD: 'Knoxfield',
	STATE_MELBOURNE: 'Melbourne',
	STATE_NEW_SOUTH_WALES: 'New South Wales',
	STATE_NORTHERN_TERRITORY: 'Northern Territory',
	STATE_PERTH: 'Perth',
	STATE_QUEENSLAND: 'Queensland',
	STATE_SOUTH_AUSTRALIA: 'South Australia',
	STATE_TASMANIA: 'Tasmania',
	STATE_TEMPLESTOWE: 'Templestowe',
	STATE_VICTORIA: 'Victoria',
	STATE_WERRIBEE_SOUTH: 'Werribee south',
	STATE_WESTERN_AUSTRALIA: 'Western Australia',
	STATE_WHEELER: 'Wheeler',
	STATE_BUNDESLAND_SALZBURG: 'Bundesland Salzburg',
	STATE_BUNDESLAND_STEIERMARK: 'Bundesland Steiermark',
	STATE_BUNDESLAND_TIROL: 'Bundesland Tirol',
	STATE_BURGENLAND: 'Burgenland',
	STATE_CARINTHIA: 'Carinthia',
	STATE_KARNTEN: 'Karnten',
	STATE_LIEZEN: 'Liezen',
	STATE_LOWER_AUSTRIA: 'Lower Austria',
	STATE_NIEDEROSTERREICH: 'Niederosterreich',
	STATE_OBEROSTERREICH: 'Oberosterreich',
	STATE_SALZBURG: 'Salzburg',
	STATE_SCHLESWIG_HOLSTEIN: 'Schleswig-Holstein',
	STATE_STEIERMARK: 'Steiermark',
	STATE_STYRIA: 'Styria',
	STATE_TIROL: 'Tirol',
	STATE_UPPER_AUSTRIA: 'Upper Austria',
	STATE_VORARLBERG: 'Vorarlberg',
	STATE_WIEN: 'Wien',
	STATE_ABSERON: 'Abseron',
	STATE_BAKI_SAHARI: 'Baki Sahari',
	STATE_GANCA: 'Ganca',
	STATE_GANJA: 'Ganja',
	STATE_KALBACAR: 'Kalbacar',
	STATE_LANKARAN: 'Lankaran',
	STATE_MIL_QARABAX: 'Mil-Qarabax',
	STATE_MUGAN_SALYAN: 'Mugan-Salyan',
	STATE_NAGORNI_QARABAX: 'Nagorni-Qarabax',
	STATE_NAXCIVAN: 'Naxcivan',
	STATE_PRIARAKS: 'Priaraks',
	STATE_QAZAX: 'Qazax',
	STATE_SAKI: 'Saki',
	STATE_SIRVAN: 'Sirvan',
	STATE_XACMAZ: 'Xacmaz',
	STATE_ABACO: 'Abaco',
	STATE_ACKLINS_ISLAND: 'Acklins Island',
	STATE_ANDROS: 'Andros',
	STATE_BERRY_ISLANDS: 'Berry Islands',
	STATE_BIMINIS: 'Biminis',
	STATE_CAT_ISLAND: 'Cat Island',
	STATE_CROOKED_ISLAND: 'Crooked Island',
	STATE_ELEUTHERA: 'Eleuthera',
	STATE_EXUMA_AND_CAYS: 'Exuma and Cays',
	STATE_GRAND_BAHAMA: 'Grand Bahama',
	STATE_INAGUA_ISLANDS: 'Inagua Islands',
	STATE_LONG_ISLAND: 'Long Island',
	STATE_MAYAGUANA: 'Mayaguana',
	STATE_NEW_PROVIDENCE: 'New Providence',
	STATE_RAGGED_ISLAND: 'Ragged Island',
	STATE_RUM_CAY: 'Rum Cay',
	STATE_SAN_SALVADOR: 'San Salvador',
	STATE_ISA: 'Isa',
	STATE_BADIYAH: 'Badiyah',
	STATE_JIDD_HAFS: 'Jidd Hafs',
	STATE_MAHAMA: 'Mahama',
	STATE_MANAMA: 'Manama',
	STATE_SITRAH: 'Sitrah',
	STATE_AL_MANAMAH: 'al-Manamah',
	STATE_AL_MUHARRAQ: 'al-Muharraq',
	STATE_AR_RIFA__A: "ar-Rifa'a",
	STATE_BAGAR_HAT: 'Bagar Hat',
	STATE_BANDARBAN: 'Bandarban',
	STATE_BARGUNA: 'Barguna',
	STATE_BARISAL: 'Barisal',
	STATE_BHOLA: 'Bhola',
	STATE_BOGORA: 'Bogora',
	STATE_BRAHMAN_BARIYA: 'Brahman Bariya',
	STATE_CHANDPUR: 'Chandpur',
	STATE_CHATTAGAM: 'Chattagam',
	STATE_CHITTAGONG_DIVISION: 'Chittagong Division',
	STATE_CHUADANGA: 'Chuadanga',
	STATE_DHAKA: 'Dhaka',
	STATE_DINAJPUR: 'Dinajpur',
	STATE_FARIDPUR: 'Faridpur',
	STATE_FENI: 'Feni',
	STATE_GAYBANDA: 'Gaybanda',
	STATE_GAZIPUR: 'Gazipur',
	STATE_GOPALGANJ: 'Gopalganj',
	STATE_HABIGANJ: 'Habiganj',
	STATE_JAIPUR_HAT: 'Jaipur Hat',
	STATE_JAMALPUR: 'Jamalpur',
	STATE_JESSOR: 'Jessor',
	STATE_JHALAKATI: 'Jhalakati',
	STATE_JHANAYDAH: 'Jhanaydah',
	STATE_KHAGRACHHARI: 'Khagrachhari',
	STATE_KHULNA: 'Khulna',
	STATE_KISHORGANJ: 'Kishorganj',
	STATE_KOKS_BAZAR: 'Koks Bazar',
	STATE_KOMILLA: 'Komilla',
	STATE_KURIGRAM: 'Kurigram',
	STATE_KUSHTIYA: 'Kushtiya',
	STATE_LAKSHMIPUR: 'Lakshmipur',
	STATE_LALMANIR_HAT: 'Lalmanir Hat',
	STATE_MADARIPUR: 'Madaripur',
	STATE_MAGURA: 'Magura',
	STATE_MAIMANSINGH: 'Maimansingh',
	STATE_MANIKGANJ: 'Manikganj',
	STATE_MAULVI_BAZAR: 'Maulvi Bazar',
	STATE_MEHERPUR: 'Meherpur',
	STATE_MUNSHIGANJ: 'Munshiganj',
	STATE_NARAL: 'Naral',
	STATE_NARAYANGANJ: 'Narayanganj',
	STATE_NARSINGDI: 'Narsingdi',
	STATE_NATOR: 'Nator',
	STATE_NAUGAON: 'Naugaon',
	STATE_NAWABGANJ: 'Nawabganj',
	STATE_NETRAKONA: 'Netrakona',
	STATE_NILPHAMARI: 'Nilphamari',
	STATE_NOAKHALI: 'Noakhali',
	STATE_PABNA: 'Pabna',
	STATE_PANCHAGARH: 'Panchagarh',
	STATE_PATUAKHALI: 'Patuakhali',
	STATE_PIROJPUR: 'Pirojpur',
	STATE_RAJBARI: 'Rajbari',
	STATE_RAJSHAHI: 'Rajshahi',
	STATE_RANGAMATI: 'Rangamati',
	STATE_RANGPUR: 'Rangpur',
	STATE_SATKHIRA: 'Satkhira',
	STATE_SHARIATPUR: 'Shariatpur',
	STATE_SHERPUR: 'Sherpur',
	STATE_SILHAT: 'Silhat',
	STATE_SIRAJGANJ: 'Sirajganj',
	STATE_SUNAMGANJ: 'Sunamganj',
	STATE_TANGAYAL: 'Tangayal',
	STATE_THAKURGAON: 'Thakurgaon',
	STATE_CHRIST_CHURCH: 'Christ Church',
	STATE_SAINT_ANDREW: 'Saint Andrew',
	STATE_SAINT_JAMES: 'Saint James',
	STATE_SAINT_JOSEPH: 'Saint Joseph',
	STATE_SAINT_LUCY: 'Saint Lucy',
	STATE_SAINT_MICHAEL: 'Saint Michael',
	STATE_SAINT_THOMAS: 'Saint Thomas',
	STATE_BREST: 'Brest',
	STATE_HOMJEL: 'Homjel',
	STATE_HRODNA: 'Hrodna',
	STATE_MAHILJOW: 'Mahiljow',
	STATE_MAHILYOWSKAYA_VOBLASTS: 'Mahilyowskaya Voblasts',
	STATE_MINSK: 'Minsk',
	STATE_MINSKAJA_VOBLASTS: 'Minskaja Voblasts',
	STATE_PETRIK: 'Petrik',
	STATE_VICEBSK: 'Vicebsk',
	STATE_ANTWERPEN: 'Antwerpen',
	STATE_BERCHEM: 'Berchem',
	STATE_BRABANT: 'Brabant',
	STATE_BRABANT_WALLON: 'Brabant Wallon',
	STATE_BRUSSEL: 'Brussel',
	STATE_EAST_FLANDERS: 'East Flanders',
	STATE_HAINAUT: 'Hainaut',
	STATE_LIEGE: 'Liege',
	STATE_LIMBURG: 'Limburg',
	STATE_LUXEMBOURG: 'Luxembourg',
	STATE_NAMUR: 'Namur',
	STATE_ONTARIO: 'Ontario',
	STATE_OOST_VLAANDEREN: 'Oost-Vlaanderen',
	STATE_PROVINCIE_BRABANT: 'Provincie Brabant',
	STATE_VLAAMS_BRABANT: 'Vlaams-Brabant',
	STATE_WALLONNE: 'Wallonne',
	STATE_WEST_VLAANDEREN: 'West-Vlaanderen',
	STATE_BELIZE: 'Belize',
	STATE_CAYO: 'Cayo',
	STATE_COROZAL: 'Corozal',
	STATE_ORANGE_WALK: 'Orange Walk',
	STATE_STANN_CREEK: 'Stann Creek',
	STATE_TOLEDO: 'Toledo',
	STATE_ALIBORI: 'Alibori',
	STATE_ATACORA: 'Atacora',
	STATE_ATLANTIQUE: 'Atlantique',
	STATE_BORGOU: 'Borgou',
	STATE_COLLINES: 'Collines',
	STATE_COUFFO: 'Couffo',
	STATE_DONGA: 'Donga',
	STATE_LITTORAL: 'Littoral',
	STATE_MONO: 'Mono',
	STATE_OUEME: 'Oueme',
	STATE_PLATEAU: 'Plateau',
	STATE_ZOU: 'Zou',
	STATE_HAMILTON: 'Hamilton',
	STATE_BUMTHANG: 'Bumthang',
	STATE_CHHUKHA: 'Chhukha',
	STATE_CHIRANG: 'Chirang',
	STATE_DAGA: 'Daga',
	STATE_GEYLEGPHUG: 'Geylegphug',
	STATE_HA: 'Ha',
	STATE_LHUNTSHI: 'Lhuntshi',
	STATE_MONGAR: 'Mongar',
	STATE_PEMAGATSEL: 'Pemagatsel',
	STATE_PUNAKHA: 'Punakha',
	STATE_RINPUNG: 'Rinpung',
	STATE_SAMCHI: 'Samchi',
	STATE_SAMDRUP_JONGKHAR: 'Samdrup Jongkhar',
	STATE_SHEMGANG: 'Shemgang',
	STATE_TASHIGANG: 'Tashigang',
	STATE_TIMPHU: 'Timphu',
	STATE_TONGSA: 'Tongsa',
	STATE_WANGDIPHODRANG: 'Wangdiphodrang',
	STATE_BENI: 'Beni',
	STATE_CHUQUISACA: 'Chuquisaca',
	STATE_COCHABAMBA: 'Cochabamba',
	STATE_LA_PAZ: 'La Paz',
	STATE_ORURO: 'Oruro',
	STATE_PANDO: 'Pando',
	STATE_POTOSI: 'Potosi',
	STATE_TARIJA: 'Tarija',
	STATE_FEDERACIJA_BOSNA_I_HERCEGOVINA: 'Federacija Bosna i Hercegovina',
	STATE_REPUBLIKA_SRPSKA: 'Republika Srpska',
	STATE_CENTRAL_BOBONONG: 'Central Bobonong',
	STATE_CENTRAL_BOTETI: 'Central Boteti',
	STATE_CENTRAL_MAHALAPYE: 'Central Mahalapye',
	STATE_CENTRAL_SEROWE_PALAPYE: 'Central Serowe-Palapye',
	STATE_CENTRAL_TUTUME: 'Central Tutume',
	STATE_CHOBE: 'Chobe',
	STATE_FRANCISTOWN: 'Francistown',
	STATE_GABORONE: 'Gaborone',
	STATE_GHANZI: 'Ghanzi',
	STATE_JWANENG: 'Jwaneng',
	STATE_KGALAGADI_NORTH: 'Kgalagadi North',
	STATE_KGALAGADI_SOUTH: 'Kgalagadi South',
	STATE_KGATLENG: 'Kgatleng',
	STATE_KWENENG: 'Kweneng',
	STATE_LOBATSE: 'Lobatse',
	STATE_NGAMILAND: 'Ngamiland',
	STATE_NGWAKETSE: 'Ngwaketse',
	STATE_NORTH_EAST: 'North East',
	STATE_OKAVANGO: 'Okavango',
	STATE_ORAPA: 'Orapa',
	STATE_SELIBE_PHIKWE: 'Selibe Phikwe',
	STATE_SOUTH_EAST: 'South East',
	STATE_SOWA: 'Sowa',
	STATE_BOUVET_ISLAND: 'Bouvet Island',
	STATE_ACRE: 'Acre',
	STATE_ALAGOAS: 'Alagoas',
	STATE_AMAPA: 'Amapa',
	STATE_AMAZONAS: 'Amazonas',
	STATE_BAHIA: 'Bahia',
	STATE_CEARA: 'Ceara',
	STATE_ESPIRITO_SANTO: 'Espirito Santo',
	STATE_ESTADO_DE_SAO_PAULO: 'Estado de Sao Paulo',
	STATE_GOIAS: 'Goias',
	STATE_MARANHAO: 'Maranhao',
	STATE_MATO_GROSSO: 'Mato Grosso',
	STATE_MATO_GROSSO_DO_SUL: 'Mato Grosso do Sul',
	STATE_MINAS_GERAIS: 'Minas Gerais',
	STATE_PARA: 'Para',
	STATE_PARAIBA: 'Paraiba',
	STATE_PARANA: 'Parana',
	STATE_PERNAMBUCO: 'Pernambuco',
	STATE_PIAUI: 'Piaui',
	STATE_RIO_GRANDE_DO_NORTE: 'Rio Grande do Norte',
	STATE_RIO_GRANDE_DO_SUL: 'Rio Grande do Sul',
	STATE_RIO_DE_JANEIRO: 'Rio de Janeiro',
	STATE_RONDONIA: 'Rondonia',
	STATE_RORAIMA: 'Roraima',
	STATE_SANTA_CATARINA: 'Santa Catarina',
	STATE_SAO_PAULO: 'Sao Paulo',
	STATE_SERGIPE: 'Sergipe',
	STATE_TOCANTINS: 'Tocantins',
	STATE_BRITISH_INDIAN_OCEAN_TERRITORY: 'British Indian Ocean Territory',
	STATE_BELAIT: 'Belait',
	STATE_BRUNEI_MUARA: 'Brunei-Muara',
	STATE_TEMBURONG: 'Temburong',
	STATE_TUTONG: 'Tutong',
	STATE_BLAGOEVGRAD: 'Blagoevgrad',
	STATE_BURGAS: 'Burgas',
	STATE_DOBRICH: 'Dobrich',
	STATE_GABROVO: 'Gabrovo',
	STATE_HASKOVO: 'Haskovo',
	STATE_JAMBOL: 'Jambol',
	STATE_KARDZHALI: 'Kardzhali',
	STATE_KJUSTENDIL: 'Kjustendil',
	STATE_LOVECH: 'Lovech',
	STATE_MONTANA: 'Montana',
	STATE_OBLAST_SOFIYA_GRAD: 'Oblast Sofiya-Grad',
	STATE_PAZARDZHIK: 'Pazardzhik',
	STATE_PERNIK: 'Pernik',
	STATE_PLEVEN: 'Pleven',
	STATE_PLOVDIV: 'Plovdiv',
	STATE_RAZGRAD: 'Razgrad',
	STATE_RUSE: 'Ruse',
	STATE_SHUMEN: 'Shumen',
	STATE_SILISTRA: 'Silistra',
	STATE_SLIVEN: 'Sliven',
	STATE_SMOLJAN: 'Smoljan',
	STATE_SOFIJA_GRAD: 'Sofija grad',
	STATE_SOFIJSKA_OBLAST: 'Sofijska oblast',
	STATE_STARA_ZAGORA: 'Stara Zagora',
	STATE_TARGOVISHTE: 'Targovishte',
	STATE_VARNA: 'Varna',
	STATE_VELIKO_TARNOVO: 'Veliko Tarnovo',
	STATE_VIDIN: 'Vidin',
	STATE_VRACA: 'Vraca',
	STATE_YABLANIZA: 'Yablaniza',
	STATE_BALE: 'Bale',
	STATE_BAM: 'Bam',
	STATE_BAZEGA: 'Bazega',
	STATE_BOUGOURIBA: 'Bougouriba',
	STATE_BOULGOU: 'Boulgou',
	STATE_BOULKIEMDE: 'Boulkiemde',
	STATE_COMOE: 'Comoe',
	STATE_GANZOURGOU: 'Ganzourgou',
	STATE_GNAGNA: 'Gnagna',
	STATE_GOURMA: 'Gourma',
	STATE_HOUET: 'Houet',
	STATE_IOBA: 'Ioba',
	STATE_KADIOGO: 'Kadiogo',
	STATE_KENEDOUGOU: 'Kenedougou',
	STATE_KOMANDJARI: 'Komandjari',
	STATE_KOMPIENGA: 'Kompienga',
	STATE_KOSSI: 'Kossi',
	STATE_KOURITENGA: 'Kouritenga',
	STATE_KOURWEOGO: 'Kourweogo',
	STATE_LERABA: 'Leraba',
	STATE_MOUHOUN: 'Mouhoun',
	STATE_NAHOURI: 'Nahouri',
	STATE_NAMENTENGA: 'Namentenga',
	STATE_NOUMBIEL: 'Noumbiel',
	STATE_OUBRITENGA: 'Oubritenga',
	STATE_OUDALAN: 'Oudalan',
	STATE_PASSORE: 'Passore',
	STATE_PONI: 'Poni',
	STATE_SANGUIE: 'Sanguie',
	STATE_SANMATENGA: 'Sanmatenga',
	STATE_SENO: 'Seno',
	STATE_SISSILI: 'Sissili',
	STATE_SOUM: 'Soum',
	STATE_SOUROU: 'Sourou',
	STATE_TAPOA: 'Tapoa',
	STATE_TUY: 'Tuy',
	STATE_YATENGA: 'Yatenga',
	STATE_ZONDOMA: 'Zondoma',
	STATE_ZOUNDWEOGO: 'Zoundweogo',
	STATE_BUBANZA: 'Bubanza',
	STATE_BUJUMBURA: 'Bujumbura',
	STATE_BURURI: 'Bururi',
	STATE_CANKUZO: 'Cankuzo',
	STATE_CIBITOKE: 'Cibitoke',
	STATE_GITEGA: 'Gitega',
	STATE_KARUZI: 'Karuzi',
	STATE_KAYANZA: 'Kayanza',
	STATE_KIRUNDO: 'Kirundo',
	STATE_MAKAMBA: 'Makamba',
	STATE_MURAMVYA: 'Muramvya',
	STATE_MUYINGA: 'Muyinga',
	STATE_NGOZI: 'Ngozi',
	STATE_RUTANA: 'Rutana',
	STATE_RUYIGI: 'Ruyigi',
	STATE_BANTEAY_MEAN_CHEY: 'Banteay Mean Chey',
	STATE_BAT_DAMBANG: 'Bat Dambang',
	STATE_KAMPONG_CHAM: 'Kampong Cham',
	STATE_KAMPONG_CHHNANG: 'Kampong Chhnang',
	STATE_KAMPONG_SPOEU: 'Kampong Spoeu',
	STATE_KAMPONG_THUM: 'Kampong Thum',
	STATE_KAMPOT: 'Kampot',
	STATE_KANDAL: 'Kandal',
	STATE_KAOH_KONG: 'Kaoh Kong',
	STATE_KRACHEH: 'Kracheh',
	STATE_KRONG_KAEB: 'Krong Kaeb',
	STATE_KRONG_PAILIN: 'Krong Pailin',
	STATE_KRONG_PREAH_SIHANOUK: 'Krong Preah Sihanouk',
	STATE_MONDOL_KIRI: 'Mondol Kiri',
	STATE_OTDAR_MEAN_CHEY: 'Otdar Mean Chey',
	STATE_PHNUM_PENH: 'Phnum Penh',
	STATE_POUSAT: 'Pousat',
	STATE_PREAH_VIHEAR: 'Preah Vihear',
	STATE_PREY_VEAENG: 'Prey Veaeng',
	STATE_ROTANAK_KIRI: 'Rotanak Kiri',
	STATE_SIEM_REAB: 'Siem Reab',
	STATE_STUENG_TRAENG: 'Stueng Traeng',
	STATE_SVAY_RIENG: 'Svay Rieng',
	STATE_TAKAEV: 'Takaev',
	STATE_ADAMAOUA: 'Adamaoua',
	STATE_CENTRE: 'Centre',
	STATE_EST: 'Est',
	STATE_NORD: 'Nord',
	STATE_NORD_EXTREME: 'Nord Extreme',
	STATE_NORDOUEST: 'Nordouest',
	STATE_OUEST: 'Ouest',
	STATE_SUD: 'Sud',
	STATE_SUDOUEST: 'Sudouest',
	STATE_ALBERTA: 'Alberta',
	STATE_BRITISH_COLUMBIA: 'British Columbia',
	STATE_MANITOBA: 'Manitoba',
	STATE_NEW_BRUNSWICK: 'New Brunswick',
	STATE_NEWFOUNDLAND_AND_LABRADOR: 'Newfoundland and Labrador',
	STATE_NORTHWEST_TERRITORIES: 'Northwest Territories',
	STATE_NOVA_SCOTIA: 'Nova Scotia',
	STATE_NUNAVUT: 'Nunavut',
	STATE_PRINCE_EDWARD_ISLAND: 'Prince Edward Island',
	STATE_QUEBEC: 'Quebec',
	STATE_SASKATCHEWAN: 'Saskatchewan',
	STATE_YUKON: 'Yukon',
	STATE_BOAVISTA: 'Boavista',
	STATE_BRAVA: 'Brava',
	STATE_FOGO: 'Fogo',
	STATE_MAIO: 'Maio',
	STATE_SAL: 'Sal',
	STATE_SANTO_ANTAO: 'Santo Antao',
	STATE_SAO_NICOLAU: 'Sao Nicolau',
	STATE_SAO_TIAGO: 'Sao Tiago',
	STATE_SAO_VICENTE: 'Sao Vicente',
	STATE_GRAND_CAYMAN: 'Grand Cayman',
	STATE_BAMINGUI_BANGORAN: 'Bamingui-Bangoran',
	STATE_BANGUI: 'Bangui',
	STATE_BASSE_KOTTO: 'Basse-Kotto',
	STATE_HAUT_MBOMOU: 'Haut-Mbomou',
	STATE_HAUTE_KOTTO: 'Haute-Kotto',
	STATE_KEMO: 'Kemo',
	STATE_LOBAYE: 'Lobaye',
	STATE_MAMBERE_KADEI: 'Mambere-Kadei',
	STATE_MBOMOU: 'Mbomou',
	STATE_NANA_GRIBIZI: 'Nana-Gribizi',
	STATE_NANA_MAMBERE: 'Nana-Mambere',
	STATE_OMBELLA_MPOKO: 'Ombella Mpoko',
	STATE_OUAKA: 'Ouaka',
	STATE_OUHAM: 'Ouham',
	STATE_OUHAM_PENDE: 'Ouham-Pende',
	STATE_SANGHA_MBAERE: 'Sangha-Mbaere',
	STATE_VAKAGA: 'Vakaga',
	STATE_BATHA: 'Batha',
	STATE_BILTINE: 'Biltine',
	STATE_BOURKOU_ENNEDI_TIBESTI: 'Bourkou-Ennedi-Tibesti',
	STATE_CHARI_BAGUIRMI: 'Chari-Baguirmi',
	STATE_GUERA: 'Guera',
	STATE_KANEM: 'Kanem',
	STATE_LAC: 'Lac',
	STATE_LOGONE_OCCIDENTAL: 'Logone Occidental',
	STATE_LOGONE_ORIENTAL: 'Logone Oriental',
	STATE_MAYO_KEBBI: 'Mayo-Kebbi',
	STATE_MOYEN_CHARI: 'Moyen-Chari',
	STATE_OUADDAI: 'Ouaddai',
	STATE_SALAMAT: 'Salamat',
	STATE_TANDJILE: 'Tandjile',
	STATE_AISEN: 'Aisen',
	STATE_ANTOFAGASTA: 'Antofagasta',
	STATE_ARAUCANIA: 'Araucania',
	STATE_ATACAMA: 'Atacama',
	STATE_BIO_BIO: 'Bio Bio',
	STATE_COQUIMBO: 'Coquimbo',
	STATE_LIBERTADOR_GENERAL_BERNARDO_O: 'Libertador General Bernardo O',
	STATE_LOS_LAGOS: 'Los Lagos',
	STATE_MAGELLANES: 'Magellanes',
	STATE_MAULE: 'Maule',
	STATE_METROPOLITANA: 'Metropolitana',
	STATE_METROPOLITANA_DE_SANTIAGO: 'Metropolitana de Santiago',
	STATE_TARAPACA: 'Tarapaca',
	STATE_VALPARAISO: 'Valparaiso',
	STATE_ANHUI: 'Anhui',
	STATE_ANHUI_PROVINCE: 'Anhui Province',
	STATE_ANHUI_SHENG: 'Anhui Sheng',
	STATE_AOMEN: 'Aomen',
	STATE_BEIJING: 'Beijing',
	STATE_BEIJING_SHI: 'Beijing Shi',
	STATE_CHONGQING: 'Chongqing',
	STATE_FUJIAN: 'Fujian',
	STATE_FUJIAN_SHENG: 'Fujian Sheng',
	STATE_GANSU: 'Gansu',
	STATE_GUANGDONG: 'Guangdong',
	STATE_GUANGDONG_SHENG: 'Guangdong Sheng',
	STATE_GUANGXI: 'Guangxi',
	STATE_GUIZHOU: 'Guizhou',
	STATE_HAINAN: 'Hainan',
	STATE_HEBEI: 'Hebei',
	STATE_HEILONGJIANG: 'Heilongjiang',
	STATE_HENAN: 'Henan',
	STATE_HUBEI: 'Hubei',
	STATE_HUNAN: 'Hunan',
	STATE_JIANGSU: 'Jiangsu',
	STATE_JIANGSU_SHENG: 'Jiangsu Sheng',
	STATE_JIANGXI: 'Jiangxi',
	STATE_JILIN: 'Jilin',
	STATE_LIAONING: 'Liaoning',
	STATE_LIAONING_SHENG: 'Liaoning Sheng',
	STATE_NEI_MONGGOL: 'Nei Monggol',
	STATE_NINGXIA_HUI: 'Ningxia Hui',
	STATE_QINGHAI: 'Qinghai',
	STATE_SHAANXI: 'Shaanxi',
	STATE_SHANDONG: 'Shandong',
	STATE_SHANDONG_SHENG: 'Shandong Sheng',
	STATE_SHANGHAI: 'Shanghai',
	STATE_SHANXI: 'Shanxi',
	STATE_SICHUAN: 'Sichuan',
	STATE_TIANJIN: 'Tianjin',
	STATE_XIANGGANG: 'Xianggang',
	STATE_XINJIANG: 'Xinjiang',
	STATE_XIZANG: 'Xizang',
	STATE_YUNNAN: 'Yunnan',
	STATE_ZHEJIANG: 'Zhejiang',
	STATE_ZHEJIANG_SHENG: 'Zhejiang Sheng',
	STATE_CHRISTMAS_ISLAND: 'Christmas Island',
	STATE_COCOS_KEELING_ISLANDS: 'Cocos (Keeling) Islands',
	STATE_ANTIOQUIA: 'Antioquia',
	STATE_ARAUCA: 'Arauca',
	STATE_ATLANTICO: 'Atlantico',
	STATE_BOGOTA: 'Bogota',
	STATE_BOLIVAR: 'Bolivar',
	STATE_BOYACA: 'Boyaca',
	STATE_CALDAS: 'Caldas',
	STATE_CAQUETA: 'Caqueta',
	STATE_CASANARE: 'Casanare',
	STATE_CAUCA: 'Cauca',
	STATE_CESAR: 'Cesar',
	STATE_CHOCO: 'Choco',
	STATE_CUNDINAMARCA: 'Cundinamarca',
	STATE_GUAINIA: 'Guainia',
	STATE_GUAVIARE: 'Guaviare',
	STATE_LA_GUAJIRA: 'La Guajira',
	STATE_MAGDALENA: 'Magdalena',
	STATE_META: 'Meta',
	STATE_NARINO: 'Narino',
	STATE_NORTE_DE_SANTANDER: 'Norte de Santander',
	STATE_PUTUMAYO: 'Putumayo',
	STATE_QUINDIO: 'Quindio',
	STATE_RISARALDA: 'Risaralda',
	STATE_SAN_ANDRES_Y_PROVIDENCIA: 'San Andres y Providencia',
	STATE_SANTANDER: 'Santander',
	STATE_SUCRE: 'Sucre',
	STATE_TOLIMA: 'Tolima',
	STATE_VALLE_DEL_CAUCA: 'Valle del Cauca',
	STATE_VAUPES: 'Vaupes',
	STATE_VICHADA: 'Vichada',
	STATE_MWALI: 'Mwali',
	STATE_NJAZIDJA: 'Njazidja',
	STATE_NZWANI: 'Nzwani',
	STATE_BOUENZA: 'Bouenza',
	STATE_BRAZZAVILLE: 'Brazzaville',
	STATE_CUVETTE: 'Cuvette',
	STATE_KOUILOU: 'Kouilou',
	STATE_LEKOUMOU: 'Lekoumou',
	STATE_LIKOUALA: 'Likouala',
	STATE_NIARI: 'Niari',
	STATE_PLATEAUX: 'Plateaux',
	STATE_POOL: 'Pool',
	STATE_SANGHA: 'Sangha',
	STATE_BANDUNDU: 'Bandundu',
	STATE_BAS_CONGO: 'Bas-Congo',
	STATE_EQUATEUR: 'Equateur',
	STATE_HAUT_CONGO: 'Haut-Congo',
	STATE_KASAI_OCCIDENTAL: 'Kasai-Occidental',
	STATE_KASAI_ORIENTAL: 'Kasai-Oriental',
	STATE_KATANGA: 'Katanga',
	STATE_KINSHASA: 'Kinshasa',
	STATE_MANIEMA: 'Maniema',
	STATE_NORD_KIVU: 'Nord-Kivu',
	STATE_SUD_KIVU: 'Sud-Kivu',
	STATE_AITUTAKI: 'Aitutaki',
	STATE_ATIU: 'Atiu',
	STATE_MANGAIA: 'Mangaia',
	STATE_MANIHIKI: 'Manihiki',
	STATE_MAUKE: 'Mauke',
	STATE_MITIARO: 'Mitiaro',
	STATE_NASSAU: 'Nassau',
	STATE_PUKAPUKA: 'Pukapuka',
	STATE_RAKAHANGA: 'Rakahanga',
	STATE_RAROTONGA: 'Rarotonga',
	STATE_TONGAREVA: 'Tongareva',
	STATE_ALAJUELA: 'Alajuela',
	STATE_CARTAGO: 'Cartago',
	STATE_GUANACASTE: 'Guanacaste',
	STATE_HEREDIA: 'Heredia',
	STATE_LIMON: 'Limon',
	STATE_PUNTARENAS: 'Puntarenas',
	STATE_SAN_JOSE: 'San Jose',
	STATE_ABIDJAN: 'Abidjan',
	STATE_AGNEBY: 'Agneby',
	STATE_BAFING: 'Bafing',
	STATE_DENGUELE: 'Denguele',
	STATE_DIX_HUIT_MONTAGNES: 'Dix-huit Montagnes',
	STATE_FROMAGER: 'Fromager',
	STATE_HAUT_SASSANDRA: 'Haut-Sassandra',
	STATE_LACS: 'Lacs',
	STATE_LAGUNES: 'Lagunes',
	STATE_MARAHOUE: 'Marahoue',
	STATE_MOYEN_CAVALLY: 'Moyen-Cavally',
	STATE_MOYEN_COMOE: 'Moyen-Comoe',
	STATE_N__ZI_COMOE: "N'zi-Comoe",
	STATE_SASSANDRA: 'Sassandra',
	STATE_SAVANES: 'Savanes',
	STATE_SUD_BANDAMA: 'Sud-Bandama',
	STATE_SUD_COMOE: 'Sud-Comoe',
	STATE_VALLEE_DU_BANDAMA: 'Vallee du Bandama',
	STATE_WORODOUGOU: 'Worodougou',
	STATE_ZANZAN: 'Zanzan',
	STATE_BJELOVAR_BILOGORA: 'Bjelovar-Bilogora',
	STATE_DUBROVNIK_NERETVA: 'Dubrovnik-Neretva',
	STATE_GRAD_ZAGREB: 'Grad Zagreb',
	STATE_ISTRA: 'Istra',
	STATE_KARLOVAC: 'Karlovac',
	STATE_KOPRIVNICA_KRIZHEVCI: 'Koprivnica-Krizhevci',
	STATE_KRAPINA_ZAGORJE: 'Krapina-Zagorje',
	STATE_LIKA_SENJ: 'Lika-Senj',
	STATE_MEDHIMURJE: 'Medhimurje',
	STATE_MEDIMURSKA_ZUPANIJA: 'Medimurska Zupanija',
	STATE_OSIJEK_BARANJA: 'Osijek-Baranja',
	STATE_OSJECKO_BARANJSKA_ZUPANIJA: 'Osjecko-Baranjska Zupanija',
	STATE_POZHEGA_SLAVONIJA: 'Pozhega-Slavonija',
	STATE_PRIMORJE_GORSKI_KOTAR: 'Primorje-Gorski Kotar',
	STATE_SHIBENIK_KNIN: 'Shibenik-Knin',
	STATE_SISAK_MOSLAVINA: 'Sisak-Moslavina',
	STATE_SLAVONSKI_BROD_POSAVINA: 'Slavonski Brod-Posavina',
	STATE_SPLIT_DALMACIJA: 'Split-Dalmacija',
	STATE_VARAZHDIN: 'Varazhdin',
	STATE_VIROVITICA_PODRAVINA: 'Virovitica-Podravina',
	STATE_VUKOVAR_SRIJEM: 'Vukovar-Srijem',
	STATE_ZADAR: 'Zadar',
	STATE_ZAGREB: 'Zagreb',
	STATE_CAMAGUEY: 'Camaguey',
	STATE_CIEGO_DE_AVILA: 'Ciego de Avila',
	STATE_CIENFUEGOS: 'Cienfuegos',
	STATE_CIUDAD_DE_LA_HABANA: 'Ciudad de la Habana',
	STATE_GRANMA: 'Granma',
	STATE_GUANTANAMO: 'Guantanamo',
	STATE_HABANA: 'Habana',
	STATE_HOLGUIN: 'Holguin',
	STATE_ISLA_DE_LA_JUVENTUD: 'Isla de la Juventud',
	STATE_LA_HABANA: 'La Habana',
	STATE_LAS_TUNAS: 'Las Tunas',
	STATE_MATANZAS: 'Matanzas',
	STATE_PINAR_DEL_RIO: 'Pinar del Rio',
	STATE_SANCTI_SPIRITUS: 'Sancti Spiritus',
	STATE_SANTIAGO_DE_CUBA: 'Santiago de Cuba',
	STATE_VILLA_CLARA: 'Villa Clara',
	STATE_GOVERNMENT_CONTROLLED_AREA: 'Government controlled area',
	STATE_LIMASSOL: 'Limassol',
	STATE_NICOSIA_DISTRICT: 'Nicosia District',
	STATE_PAPHOS: 'Paphos',
	STATE_TURKISH_CONTROLLED_AREA: 'Turkish controlled area',
	STATE_CENTRAL_BOHEMIAN: 'Central Bohemian',
	STATE_FRYCOVICE: 'Frycovice',
	STATE_JIHOCESKY_KRAJ: 'Jihocesky Kraj',
	STATE_JIHOCHESKY: 'Jihochesky',
	STATE_JIHOMORAVSKY: 'Jihomoravsky',
	STATE_KARLOVARSKY: 'Karlovarsky',
	STATE_KLECANY: 'Klecany',
	STATE_KRALOVEHRADECKY: 'Kralovehradecky',
	STATE_LIBERECKY: 'Liberecky',
	STATE_LIPOV: 'Lipov',
	STATE_MORAVSKOSLEZSKY: 'Moravskoslezsky',
	STATE_OLOMOUCKY: 'Olomoucky',
	STATE_OLOMOUCKY_KRAJ: 'Olomoucky Kraj',
	STATE_PARDUBICKY: 'Pardubicky',
	STATE_PLZENSKY: 'Plzensky',
	STATE_PRAHA: 'Praha',
	STATE_RAJHRAD: 'Rajhrad',
	STATE_SMIRICE: 'Smirice',
	STATE_SOUTH_MORAVIAN: 'South Moravian',
	STATE_STRAZ_NAD_NISOU: 'Straz nad Nisou',
	STATE_STREDOCHESKY: 'Stredochesky',
	STATE_UNICOV: 'Unicov',
	STATE_USTECKY: 'Ustecky',
	STATE_VALLETTA: 'Valletta',
	STATE_VELESIN: 'Velesin',
	STATE_VYSOCHINA: 'Vysochina',
	STATE_ZLINSKY: 'Zlinsky',
	STATE_BORNHOLM: 'Bornholm',
	STATE_FREDERIKSBORG: 'Frederiksborg',
	STATE_FYN: 'Fyn',
	STATE_HOVEDSTADEN: 'Hovedstaden',
	STATE_KOBENHAVN: 'Kobenhavn',
	STATE_KOBENHAVNS_AMT: 'Kobenhavns Amt',
	STATE_KOBENHAVNS_KOMMUNE: 'Kobenhavns Kommune',
	STATE_NORDJYLLAND: 'Nordjylland',
	STATE_RIBE: 'Ribe',
	STATE_RINGKOBING: 'Ringkobing',
	STATE_ROERVIG: 'Roervig',
	STATE_ROSKILDE: 'Roskilde',
	STATE_ROSLEV: 'Roslev',
	STATE_SJAELLAND: 'Sjaelland',
	STATE_SOEBORG: 'Soeborg',
	STATE_SONDERJYLLAND: 'Sonderjylland',
	STATE_STORSTROM: 'Storstrom',
	STATE_SYDDANMARK: 'Syddanmark',
	STATE_TOELLOESE: 'Toelloese',
	STATE_VEJLE: 'Vejle',
	STATE_VESTSJALLAND: 'Vestsjalland',
	STATE_VIBORG: 'Viborg',
	STATE_ALI_SABIH: 'Ali Sabih',
	STATE_DIKHIL: 'Dikhil',
	STATE_JIBUTI: 'Jibuti',
	STATE_TAJURAH: 'Tajurah',
	STATE_UBUK: 'Ubuk',
	STATE_SAINT_DAVID: 'Saint David',
	STATE_SAINT_LUKE: 'Saint Luke',
	STATE_SAINT_MARK: 'Saint Mark',
	STATE_SAINT_PATRICK: 'Saint Patrick',
	STATE_AZUA: 'Azua',
	STATE_BAHORUCO: 'Bahoruco',
	STATE_BARAHONA: 'Barahona',
	STATE_DAJABON: 'Dajabon',
	STATE_DISTRITO_NACIONAL: 'Distrito Nacional',
	STATE_DUARTE: 'Duarte',
	STATE_EL_SEYBO: 'El Seybo',
	STATE_ELIAS_PINA: 'Elias Pina',
	STATE_ESPAILLAT: 'Espaillat',
	STATE_HATO_MAYOR: 'Hato Mayor',
	STATE_INDEPENDENCIA: 'Independencia',
	STATE_LA_ALTAGRACIA: 'La Altagracia',
	STATE_LA_ROMANA: 'La Romana',
	STATE_LA_VEGA: 'La Vega',
	STATE_MARIA_TRINIDAD_SANCHEZ: 'Maria Trinidad Sanchez',
	STATE_MONSENOR_NOUEL: 'Monsenor Nouel',
	STATE_MONTE_CRISTI: 'Monte Cristi',
	STATE_MONTE_PLATA: 'Monte Plata',
	STATE_PEDERNALES: 'Pedernales',
	STATE_PERAVIA: 'Peravia',
	STATE_PUERTO_PLATA: 'Puerto Plata',
	STATE_SALCEDO: 'Salcedo',
	STATE_SAMANA: 'Samana',
	STATE_SAN_CRISTOBAL: 'San Cristobal',
	STATE_SAN_PEDRO_DE_MACORIS: 'San Pedro de Macoris',
	STATE_SANCHEZ_RAMIREZ: 'Sanchez Ramirez',
	STATE_SANTIAGO: 'Santiago',
	STATE_SANTIAGO_RODRIGUEZ: 'Santiago Rodriguez',
	STATE_VALVERDE: 'Valverde',
	STATE_AILEU: 'Aileu',
	STATE_AINARO: 'Ainaro',
	STATE_AMBENO: 'Ambeno',
	STATE_BAUCAU: 'Baucau',
	STATE_BOBONARO: 'Bobonaro',
	STATE_COVA_LIMA: 'Cova Lima',
	STATE_DILI: 'Dili',
	STATE_ERMERA: 'Ermera',
	STATE_LAUTEM: 'Lautem',
	STATE_LIQUICA: 'Liquica',
	STATE_MANATUTO: 'Manatuto',
	STATE_MANUFAHI: 'Manufahi',
	STATE_VIQUEQUE: 'Viqueque',
	STATE_AZUAY: 'Azuay',
	STATE_CANAR: 'Canar',
	STATE_CARCHI: 'Carchi',
	STATE_CHIMBORAZO: 'Chimborazo',
	STATE_COTOPAXI: 'Cotopaxi',
	STATE_EL_ORO: 'El Oro',
	STATE_ESMERALDAS: 'Esmeraldas',
	STATE_GALAPAGOS: 'Galapagos',
	STATE_GUAYAS: 'Guayas',
	STATE_IMBABURA: 'Imbabura',
	STATE_LOJA: 'Loja',
	STATE_LOS_RIOS: 'Los Rios',
	STATE_MANABI: 'Manabi',
	STATE_MORONA_SANTIAGO: 'Morona Santiago',
	STATE_NAPO: 'Napo',
	STATE_ORELLANA: 'Orellana',
	STATE_PASTAZA: 'Pastaza',
	STATE_PICHINCHA: 'Pichincha',
	STATE_SUCUMBIOS: 'Sucumbios',
	STATE_TUNGURAHUA: 'Tungurahua',
	STATE_ZAMORA_CHINCHIPE: 'Zamora Chinchipe',
	STATE_ASWAN: 'Aswan',
	STATE_ASYUT: 'Asyut',
	STATE_BANI_SUWAYF: 'Bani Suwayf',
	STATE_BUR_SA__ID: "Bur Sa'id",
	STATE_CAIRO: 'Cairo',
	STATE_DUMYAT: 'Dumyat',
	STATE_KAFR_ASH_SHAYKH: 'Kafr-ash-Shaykh',
	STATE_MATRUH: 'Matruh',
	STATE_MUHAFAZAT_AD_DAQAHLIYAH: 'Muhafazat ad Daqahliyah',
	STATE_MUHAFAZAT_AL_FAYYUM: 'Muhafazat al Fayyum',
	STATE_MUHAFAZAT_AL_GHARBIYAH: 'Muhafazat al Gharbiyah',
	STATE_MUHAFAZAT_AL_ISKANDARIYAH: 'Muhafazat al Iskandariyah',
	STATE_MUHAFAZAT_AL_QAHIRAH: 'Muhafazat al Qahirah',
	STATE_QINA: 'Qina',
	STATE_SAWHAJ: 'Sawhaj',
	STATE_SINA_AL_JANUBIYAH: 'Sina al-Janubiyah',
	STATE_SINA_ASH_SHAMALIYAH: 'Sina ash-Shamaliyah',
	STATE_AD_DAQAHLIYAH: 'ad-Daqahliyah',
	STATE_AL_BAHR_AL_AHMAR: 'al-Bahr-al-Ahmar',
	STATE_AL_BUHAYRAH: 'al-Buhayrah',
	STATE_AL_FAYYUM: 'al-Fayyum',
	STATE_AL_GHARBIYAH: 'al-Gharbiyah',
	STATE_AL_ISKANDARIYAH: 'al-Iskandariyah',
	STATE_AL_ISMAILIYAH: 'al-Ismailiyah',
	STATE_AL_JIZAH: 'al-Jizah',
	STATE_AL_MINUFIYAH: 'al-Minufiyah',
	STATE_AL_MINYA: 'al-Minya',
	STATE_AL_QAHIRA: 'al-Qahira',
	STATE_AL_QALYUBIYAH: 'al-Qalyubiyah',
	STATE_AL_UQSUR: 'al-Uqsur',
	STATE_AL_WADI_AL_JADID: 'al-Wadi al-Jadid',
	STATE_AS_SUWAYS: 'as-Suways',
	STATE_ASH_SHARQIYAH: 'ash-Sharqiyah',
	STATE_AHUACHAPAN: 'Ahuachapan',
	STATE_CABANAS: 'Cabanas',
	STATE_CHALATENANGO: 'Chalatenango',
	STATE_CUSCATLAN: 'Cuscatlan',
	STATE_LA_LIBERTAD: 'La Libertad',
	STATE_LA_UNION: 'La Union',
	STATE_MORAZAN: 'Morazan',
	STATE_SAN_MIGUEL: 'San Miguel',
	STATE_SAN_VICENTE: 'San Vicente',
	STATE_SANTA_ANA: 'Santa Ana',
	STATE_SONSONATE: 'Sonsonate',
	STATE_USULUTAN: 'Usulutan',
	STATE_ANNOBON: 'Annobon',
	STATE_BIOKO_NORTE: 'Bioko Norte',
	STATE_BIOKO_SUR: 'Bioko Sur',
	STATE_CENTRO_SUR: 'Centro Sur',
	STATE_KIE_NTEM: 'Kie-Ntem',
	STATE_LITORAL: 'Litoral',
	STATE_WELE_NZAS: 'Wele-Nzas',
	STATE_ANSEBA: 'Anseba',
	STATE_DEBUB: 'Debub',
	STATE_DEBUB_KEIH_BAHRI: 'Debub-Keih-Bahri',
	STATE_GASH_BARKA: 'Gash-Barka',
	STATE_MAEKEL: 'Maekel',
	STATE_SEMIEN_KEIH_BAHRI: 'Semien-Keih-Bahri',
	STATE_HARJU: 'Harju',
	STATE_HIIU: 'Hiiu',
	STATE_IDA_VIRU: 'Ida-Viru',
	STATE_JARVA: 'Jarva',
	STATE_JOGEVA: 'Jogeva',
	STATE_LAANE: 'Laane',
	STATE_LAANE_VIRU: 'Laane-Viru',
	STATE_PARNU: 'Parnu',
	STATE_POLVA: 'Polva',
	STATE_RAPLA: 'Rapla',
	STATE_SAARE: 'Saare',
	STATE_TARTU: 'Tartu',
	STATE_VALGA: 'Valga',
	STATE_VILJANDI: 'Viljandi',
	STATE_VORU: 'Voru',
	STATE_ADDIS_ABEBA: 'Addis Abeba',
	STATE_AFAR: 'Afar',
	STATE_AMHARA: 'Amhara',
	STATE_BENISHANGUL: 'Benishangul',
	STATE_DIREDAWA: 'Diredawa',
	STATE_GAMBELLA: 'Gambella',
	STATE_HARAR: 'Harar',
	STATE_JIGJIGA: 'Jigjiga',
	STATE_MEKELE: 'Mekele',
	STATE_OROMIA: 'Oromia',
	STATE_SOMALI: 'Somali',
	STATE_SOUTHERN: 'Southern',
	STATE_TIGRAY: 'Tigray',
	STATE_FALKLAND_ISLANDS: 'Falkland Islands',
	STATE_SOUTH_GEORGIA: 'South Georgia',
	STATE_KLAKSVIK: 'Klaksvik',
	STATE_NOR_ARA_EYSTUROY: 'Nor ara Eysturoy',
	STATE_NOR_OY: 'Nor oy',
	STATE_SANDOY: 'Sandoy',
	STATE_STREYMOY: 'Streymoy',
	STATE_SU_UROY: 'Su uroy',
	STATE_SY_RA_EYSTUROY: 'Sy ra Eysturoy',
	STATE_TORSHAVN: 'Torshavn',
	STATE_VAGA: 'Vaga',
	STATE_CENTRAL: 'Central',
	STATE_NORTHERN: 'Northern',
	STATE_SOUTH_PACIFIC: 'South Pacific',
	STATE_AHVENANMAA: 'Ahvenanmaa',
	STATE_ETELA_KARJALA: 'Etela-Karjala',
	STATE_ETELA_POHJANMAA: 'Etela-Pohjanmaa',
	STATE_ETELA_SAVO: 'Etela-Savo',
	STATE_ETELA_SUOMEN_LAANI: 'Etela-Suomen Laani',
	STATE_ITA_SUOMEN_LAANI: 'Ita-Suomen Laani',
	STATE_ITA_UUSIMAA: 'Ita-Uusimaa',
	STATE_KAINUU: 'Kainuu',
	STATE_KANTA_HAME: 'Kanta-Hame',
	STATE_KESKI_POHJANMAA: 'Keski-Pohjanmaa',
	STATE_KESKI_SUOMI: 'Keski-Suomi',
	STATE_KYMENLAAKSO: 'Kymenlaakso',
	STATE_LANSI_SUOMEN_LAANI: 'Lansi-Suomen Laani',
	STATE_LAPPI: 'Lappi',
	STATE_NORTHERN_SAVONIA: 'Northern Savonia',
	STATE_OSTROBOTHNIA: 'Ostrobothnia',
	STATE_OULUN_LAANI: 'Oulun Laani',
	STATE_PAIJAT_HAME: 'Paijat-Hame',
	STATE_PIRKANMAA: 'Pirkanmaa',
	STATE_POHJANMAA: 'Pohjanmaa',
	STATE_POHJOIS_KARJALA: 'Pohjois-Karjala',
	STATE_POHJOIS_POHJANMAA: 'Pohjois-Pohjanmaa',
	STATE_POHJOIS_SAVO: 'Pohjois-Savo',
	STATE_SAARIJARVI: 'Saarijarvi',
	STATE_SATAKUNTA: 'Satakunta',
	STATE_SOUTHERN_SAVONIA: 'Southern Savonia',
	STATE_TAVASTIA_PROPER: 'Tavastia Proper',
	STATE_ULEABORGS_LAN: 'Uleaborgs Lan',
	STATE_UUSIMAA: 'Uusimaa',
	STATE_VARSINAIS_SUOMI: 'Varsinais-Suomi',
	STATE_AIN: 'Ain',
	STATE_AISNE: 'Aisne',
	STATE_ALBI_LE_SEQUESTRE: 'Albi Le Sequestre',
	STATE_ALLIER: 'Allier',
	STATE_ALPES_COTE_DAZUR: 'Alpes-Cote dAzur',
	STATE_ALPES_MARITIMES: 'Alpes-Maritimes',
	STATE_ALPES_DE_HAUTE_PROVENCE: 'Alpes-de-Haute-Provence',
	STATE_ALSACE: 'Alsace',
	STATE_AQUITAINE: 'Aquitaine',
	STATE_ARDECHE: 'Ardeche',
	STATE_ARDENNES: 'Ardennes',
	STATE_ARIEGE: 'Ariege',
	STATE_AUBE: 'Aube',
	STATE_AUDE: 'Aude',
	STATE_AUVERGNE: 'Auvergne',
	STATE_AVEYRON: 'Aveyron',
	STATE_BAS_RHIN: 'Bas-Rhin',
	STATE_BASSE_NORMANDIE: 'Basse-Normandie',
	STATE_BOUCHES_DU_RHONE: 'Bouches-du-Rhone',
	STATE_BOURGOGNE: 'Bourgogne',
	STATE_BRETAGNE: 'Bretagne',
	STATE_BRITTANY: 'Brittany',
	STATE_BURGUNDY: 'Burgundy',
	STATE_CALVADOS: 'Calvados',
	STATE_CANTAL: 'Cantal',
	STATE_CEDEX: 'Cedex',
	STATE_CHARENTE: 'Charente',
	STATE_CHARENTE_MARITIME: 'Charente-Maritime',
	STATE_CHER: 'Cher',
	STATE_CORREZE: 'Correze',
	STATE_CORSE_DU_SUD: 'Corse-du-Sud',
	STATE_COTE_D__OR: "Cote-d'Or",
	STATE_COTES_D__ARMOR: "Cotes-d'Armor",
	STATE_CREUSE: 'Creuse',
	STATE_CROLLES: 'Crolles',
	STATE_DEUX_SEVRES: 'Deux-Sevres',
	STATE_DORDOGNE: 'Dordogne',
	STATE_DOUBS: 'Doubs',
	STATE_DROME: 'Drome',
	STATE_ESSONNE: 'Essonne',
	STATE_EURE: 'Eure',
	STATE_EURE_ET_LOIR: 'Eure-et-Loir',
	STATE_FEUCHEROLLES: 'Feucherolles',
	STATE_FINISTERE: 'Finistere',
	STATE_FRANCHE_COMTE: 'Franche-Comte',
	STATE_GARD: 'Gard',
	STATE_GERS: 'Gers',
	STATE_GIRONDE: 'Gironde',
	STATE_HAUT_RHIN: 'Haut-Rhin',
	STATE_HAUTE_CORSE: 'Haute-Corse',
	STATE_HAUTE_GARONNE: 'Haute-Garonne',
	STATE_HAUTE_LOIRE: 'Haute-Loire',
	STATE_HAUTE_MARNE: 'Haute-Marne',
	STATE_HAUTE_SAONE: 'Haute-Saone',
	STATE_HAUTE_SAVOIE: 'Haute-Savoie',
	STATE_HAUTE_VIENNE: 'Haute-Vienne',
	STATE_HAUTES_ALPES: 'Hautes-Alpes',
	STATE_HAUTES_PYRENEES: 'Hautes-Pyrenees',
	STATE_HAUTS_DE_SEINE: 'Hauts-de-Seine',
	STATE_HERAULT: 'Herault',
	STATE_ILE_DE_FRANCE: 'Ile-de-France',
	STATE_ILLE_ET_VILAINE: 'Ille-et-Vilaine',
	STATE_INDRE: 'Indre',
	STATE_INDRE_ET_LOIRE: 'Indre-et-Loire',
	STATE_ISERE: 'Isere',
	STATE_JURA: 'Jura',
	STATE_KLAGENFURT: 'Klagenfurt',
	STATE_LANDES: 'Landes',
	STATE_LANGUEDOC_ROUSSILLON: 'Languedoc-Roussillon',
	STATE_LARCAY: 'Larcay',
	STATE_LE_CASTELLET: 'Le Castellet',
	STATE_LE_CREUSOT: 'Le Creusot',
	STATE_LIMOUSIN: 'Limousin',
	STATE_LOIR_ET_CHER: 'Loir-et-Cher',
	STATE_LOIRE: 'Loire',
	STATE_LOIRE_ATLANTIQUE: 'Loire-Atlantique',
	STATE_LOIRET: 'Loiret',
	STATE_LORRAINE: 'Lorraine',
	STATE_LOT: 'Lot',
	STATE_LOT_ET_GARONNE: 'Lot-et-Garonne',
	STATE_LOWER_NORMANDY: 'Lower Normandy',
	STATE_LOZERE: 'Lozere',
	STATE_MAINE_ET_LOIRE: 'Maine-et-Loire',
	STATE_MANCHE: 'Manche',
	STATE_MARNE: 'Marne',
	STATE_MAYENNE: 'Mayenne',
	STATE_MEURTHE_ET_MOSELLE: 'Meurthe-et-Moselle',
	STATE_MEUSE: 'Meuse',
	STATE_MIDI_PYRENEES: 'Midi-Pyrenees',
	STATE_MORBIHAN: 'Morbihan',
	STATE_MOSELLE: 'Moselle',
	STATE_NIEVRE: 'Nievre',
	STATE_NORD_PAS_DE_CALAIS: 'Nord-Pas-de-Calais',
	STATE_OISE: 'Oise',
	STATE_ORNE: 'Orne',
	STATE_PARIS: 'Paris',
	STATE_PAS_DE_CALAIS: 'Pas-de-Calais',
	STATE_PAYS_DE_LA_LOIRE: 'Pays de la Loire',
	STATE_PICARDY: 'Picardy',
	STATE_PUY_DE_DOME: 'Puy-de-Dome',
	STATE_PYRENEES_ATLANTIQUES: 'Pyrenees-Atlantiques',
	STATE_PYRENEES_ORIENTALES: 'Pyrenees-Orientales',
	STATE_QUELMES: 'Quelmes',
	STATE_RHONE: 'Rhone',
	STATE_RHONE_ALPES: 'Rhone-Alpes',
	STATE_SAINT_OUEN: 'Saint Ouen',
	STATE_SAINT_VIATRE: 'Saint Viatre',
	STATE_SAONE_ET_LOIRE: 'Saone-et-Loire',
	STATE_SARTHE: 'Sarthe',
	STATE_SAVOIE: 'Savoie',
	STATE_SEINE_MARITIME: 'Seine-Maritime',
	STATE_SEINE_SAINT_DENIS: 'Seine-Saint-Denis',
	STATE_SEINE_ET_MARNE: 'Seine-et-Marne',
	STATE_SOMME: 'Somme',
	STATE_SOPHIA_ANTIPOLIS: 'Sophia Antipolis',
	STATE_SOUVANS: 'Souvans',
	STATE_TARN: 'Tarn',
	STATE_TARN_ET_GARONNE: 'Tarn-et-Garonne',
	STATE_TERRITOIRE_DE_BELFORT: 'Territoire de Belfort',
	STATE_TREIGNAC: 'Treignac',
	STATE_UPPER_NORMANDY: 'Upper Normandy',
	STATE_VAL_D__OISE: "Val-d'Oise",
	STATE_VAL_DE_MARNE: 'Val-de-Marne',
	STATE_VAR: 'Var',
	STATE_VAUCLUSE: 'Vaucluse',
	STATE_VELLISE: 'Vellise',
	STATE_VENDEE: 'Vendee',
	STATE_VIENNE: 'Vienne',
	STATE_VOSGES: 'Vosges',
	STATE_YONNE: 'Yonne',
	STATE_YVELINES: 'Yvelines',
	STATE_CAYENNE: 'Cayenne',
	STATE_SAINT_LAURENT_DU_MARONI: 'Saint-Laurent-du-Maroni',
	STATE_ILES_DU_VENT: 'Iles du Vent',
	STATE_ILES_SOUS_LE_VENT: 'Iles sous le Vent',
	STATE_MARQUESAS: 'Marquesas',
	STATE_TUAMOTU: 'Tuamotu',
	STATE_TUBUAI: 'Tubuai',
	STATE_AMSTERDAM: 'Amsterdam',
	STATE_CROZET_ISLANDS: 'Crozet Islands',
	STATE_KERGUELEN: 'Kerguelen',
	STATE_ESTUAIRE: 'Estuaire',
	STATE_HAUT_OGOOUE: 'Haut-Ogooue',
	STATE_MOYEN_OGOOUE: 'Moyen-Ogooue',
	STATE_NGOUNIE: 'Ngounie',
	STATE_NYANGA: 'Nyanga',
	STATE_OGOOUE_IVINDO: 'Ogooue-Ivindo',
	STATE_OGOOUE_LOLO: 'Ogooue-Lolo',
	STATE_OGOOUE_MARITIME: 'Ogooue-Maritime',
	STATE_WOLEU_NTEM: 'Woleu-Ntem',
	STATE_BANJUL: 'Banjul',
	STATE_BASSE: 'Basse',
	STATE_BRIKAMA: 'Brikama',
	STATE_JANJANBUREH: 'Janjanbureh',
	STATE_KANIFING: 'Kanifing',
	STATE_KEREWAN: 'Kerewan',
	STATE_KUNTAUR: 'Kuntaur',
	STATE_MANSAKONKO: 'Mansakonko',
	STATE_ABHASIA: 'Abhasia',
	STATE_AJARIA: 'Ajaria',
	STATE_GURIA: 'Guria',
	STATE_IMERETI: 'Imereti',
	STATE_KAHETI: 'Kaheti',
	STATE_KVEMO_KARTLI: 'Kvemo Kartli',
	STATE_MCHETA_MTIANETI: 'Mcheta-Mtianeti',
	STATE_RACHA: 'Racha',
	STATE_SAMAGRELO_ZEMO_SVANETI: 'Samagrelo-Zemo Svaneti',
	STATE_SAMCHE_ZHAVAHETI: 'Samche-Zhavaheti',
	STATE_SHIDA_KARTLI: 'Shida Kartli',
	STATE_TBILISI: 'Tbilisi',
	STATE_BADEN_WURTTEMBERG: 'Baden-Wurttemberg',
	STATE_BAVARIA: 'Bavaria',
	STATE_BAYERN: 'Bayern',
	STATE_BEILSTEIN_WURTT: 'Beilstein Wurtt',
	STATE_BERLIN: 'Berlin',
	STATE_BRANDENBURG: 'Brandenburg',
	STATE_BREMEN: 'Bremen',
	STATE_DREISBACH: 'Dreisbach',
	STATE_FREISTAAT_BAYERN: 'Freistaat Bayern',
	STATE_HAMBURG: 'Hamburg',
	STATE_HANNOVER: 'Hannover',
	STATE_HEROLDSTATT: 'Heroldstatt',
	STATE_HESSEN: 'Hessen',
	STATE_KORTENBERG: 'Kortenberg',
	STATE_LAASDORF: 'Laasdorf',
	STATE_LAND_BADEN_WURTTEMBERG: 'Land Baden-Wurttemberg',
	STATE_LAND_BAYERN: 'Land Bayern',
	STATE_LAND_BRANDENBURG: 'Land Brandenburg',
	STATE_LAND_HESSEN: 'Land Hessen',
	STATE_LAND_MECKLENBURG_VORPOMMERN: 'Land Mecklenburg-Vorpommern',
	STATE_LAND_NORDRHEIN_WESTFALEN: 'Land Nordrhein-Westfalen',
	STATE_LAND_RHEINLAND_PFALZ: 'Land Rheinland-Pfalz',
	STATE_LAND_SACHSEN: 'Land Sachsen',
	STATE_LAND_SACHSEN_ANHALT: 'Land Sachsen-Anhalt',
	STATE_LAND_THURINGEN: 'Land Thuringen',
	STATE_LOWER_SAXONY: 'Lower Saxony',
	STATE_MECKLENBURG_VORPOMMERN: 'Mecklenburg-Vorpommern',
	STATE_MULFINGEN: 'Mulfingen',
	STATE_MUNICH: 'Munich',
	STATE_NEUBEUERN: 'Neubeuern',
	STATE_NIEDERSACHSEN: 'Niedersachsen',
	STATE_NOORD_HOLLAND: 'Noord-Holland',
	STATE_NORDRHEIN_WESTFALEN: 'Nordrhein-Westfalen',
	STATE_NORTH_RHINE_WESTPHALIA: 'North Rhine-Westphalia',
	STATE_OSTERODE: 'Osterode',
	STATE_RHEINLAND_PFALZ: 'Rheinland-Pfalz',
	STATE_RHINELAND_PALATINATE: 'Rhineland-Palatinate',
	STATE_SAARLAND: 'Saarland',
	STATE_SACHSEN: 'Sachsen',
	STATE_SACHSEN_ANHALT: 'Sachsen-Anhalt',
	STATE_SAXONY: 'Saxony',
	STATE_THURINGIA: 'Thuringia',
	STATE_WEBLING: 'Webling',
	STATE_WEINSTRABE: 'Weinstrabe',
	STATE_SCHLOBBORN: 'schlobborn',
	STATE_ASHANTI: 'Ashanti',
	STATE_BRONG_AHAFO: 'Brong-Ahafo',
	STATE_GREATER_ACCRA: 'Greater Accra',
	STATE_UPPER_EAST: 'Upper East',
	STATE_UPPER_WEST: 'Upper West',
	STATE_VOLTA: 'Volta',
	STATE_GIBRALTAR: 'Gibraltar',
	STATE_ACHARNES: 'Acharnes',
	STATE_AHAIA: 'Ahaia',
	STATE_AITOLIA_KAI_AKARNANIA: 'Aitolia kai Akarnania',
	STATE_ARGOLIS: 'Argolis',
	STATE_ARKADIA: 'Arkadia',
	STATE_ARTA: 'Arta',
	STATE_ATTICA: 'Attica',
	STATE_ATTIKI: 'Attiki',
	STATE_AYION_OROS: 'Ayion Oros',
	STATE_CRETE: 'Crete',
	STATE_DODEKANISOS: 'Dodekanisos',
	STATE_DRAMA: 'Drama',
	STATE_EVIA: 'Evia',
	STATE_EVRITANIA: 'Evritania',
	STATE_EVROS: 'Evros',
	STATE_EVVOIA: 'Evvoia',
	STATE_FLORINA: 'Florina',
	STATE_FOKIS: 'Fokis',
	STATE_FTHIOTIS: 'Fthiotis',
	STATE_GREVENA: 'Grevena',
	STATE_HALANDRI: 'Halandri',
	STATE_HALKIDIKI: 'Halkidiki',
	STATE_HANIA: 'Hania',
	STATE_HERAKLION: 'Heraklion',
	STATE_HIOS: 'Hios',
	STATE_ILIA: 'Ilia',
	STATE_IMATHIA: 'Imathia',
	STATE_IOANNINA: 'Ioannina',
	STATE_IRAKLION: 'Iraklion',
	STATE_KARDITSA: 'Karditsa',
	STATE_KASTORIA: 'Kastoria',
	STATE_KAVALA: 'Kavala',
	STATE_KEFALLINIA: 'Kefallinia',
	STATE_KERKIRA: 'Kerkira',
	STATE_KIKLADES: 'Kiklades',
	STATE_KILKIS: 'Kilkis',
	STATE_KORINTHIA: 'Korinthia',
	STATE_KOZANI: 'Kozani',
	STATE_LAKONIA: 'Lakonia',
	STATE_LARISA: 'Larisa',
	STATE_LASITHI: 'Lasithi',
	STATE_LESVOS: 'Lesvos',
	STATE_LEVKAS: 'Levkas',
	STATE_MAGNISIA: 'Magnisia',
	STATE_MESSINIA: 'Messinia',
	STATE_NOMOS_ATTIKIS: 'Nomos Attikis',
	STATE_NOMOS_ZAKYNTHOU: 'Nomos Zakynthou',
	STATE_PELLA: 'Pella',
	STATE_PIERIA: 'Pieria',
	STATE_PIRAIOS: 'Piraios',
	STATE_PREVEZA: 'Preveza',
	STATE_RETHIMNI: 'Rethimni',
	STATE_RODOPI: 'Rodopi',
	STATE_SAMOS: 'Samos',
	STATE_SERRAI: 'Serrai',
	STATE_THESPROTIA: 'Thesprotia',
	STATE_THESSALONIKI: 'Thessaloniki',
	STATE_TRIKALA: 'Trikala',
	STATE_VOIOTIA: 'Voiotia',
	STATE_WEST_GREECE: 'West Greece',
	STATE_XANTHI: 'Xanthi',
	STATE_ZAKINTHOS: 'Zakinthos',
	STATE_AASIAAT: 'Aasiaat',
	STATE_AMMASSALIK: 'Ammassalik',
	STATE_ILLOQQORTOORMIUT: 'Illoqqortoormiut',
	STATE_ILULISSAT: 'Ilulissat',
	STATE_IVITTUUT: 'Ivittuut',
	STATE_KANGAATSIAQ: 'Kangaatsiaq',
	STATE_MANIITSOQ: 'Maniitsoq',
	STATE_NANORTALIK: 'Nanortalik',
	STATE_NARSAQ: 'Narsaq',
	STATE_NUUK: 'Nuuk',
	STATE_PAAMIUT: 'Paamiut',
	STATE_QAANAAQ: 'Qaanaaq',
	STATE_QAQORTOQ: 'Qaqortoq',
	STATE_QASIGIANNGUIT: 'Qasigiannguit',
	STATE_QEQERTARSUAQ: 'Qeqertarsuaq',
	STATE_SISIMIUT: 'Sisimiut',
	STATE_UDENFOR_KOMMUNAL_INDDELING: 'Udenfor kommunal inddeling',
	STATE_UPERNAVIK: 'Upernavik',
	STATE_UUMMANNAQ: 'Uummannaq',
	STATE_CARRIACOU_PETITE_MARTINIQUE: 'Carriacou-Petite Martinique',
	STATE_SAINT_DAVIDS: 'Saint Davids',
	STATE_SAINT_GEORGE__S: "Saint George's",
	STATE_BASSE_TERRE: 'Basse-Terre',
	STATE_GRANDE_TERRE: 'Grande-Terre',
	STATE_ILES_DES_SAINTES: 'Iles des Saintes',
	STATE_LA_DESIRADE: 'La Desirade',
	STATE_MARIE_GALANTE: 'Marie-Galante',
	STATE_SAINT_BARTHELEMY: 'Saint Barthelemy',
	STATE_SAINT_MARTIN: 'Saint Martin',
	STATE_AGANA_HEIGHTS: 'Agana Heights',
	STATE_AGAT: 'Agat',
	STATE_BARRIGADA: 'Barrigada',
	STATE_CHALAN_PAGO_ORDOT: 'Chalan-Pago-Ordot',
	STATE_DEDEDO: 'Dededo',
	STATE_HAGATNA: 'Hagatna',
	STATE_INARAJAN: 'Inarajan',
	STATE_MANGILAO: 'Mangilao',
	STATE_MERIZO: 'Merizo',
	STATE_MONGMONG_TOTO_MAITE: 'Mongmong-Toto-Maite',
	STATE_SANTA_RITA: 'Santa Rita',
	STATE_SINAJANA: 'Sinajana',
	STATE_TALOFOFO: 'Talofofo',
	STATE_TAMUNING: 'Tamuning',
	STATE_YIGO: 'Yigo',
	STATE_YONA: 'Yona',
	STATE_ALTA_VERAPAZ: 'Alta Verapaz',
	STATE_BAJA_VERAPAZ: 'Baja Verapaz',
	STATE_CHIMALTENANGO: 'Chimaltenango',
	STATE_CHIQUIMULA: 'Chiquimula',
	STATE_EL_PROGRESO: 'El Progreso',
	STATE_ESCUINTLA: 'Escuintla',
	STATE_GUATEMALA: 'Guatemala',
	STATE_HUEHUETENANGO: 'Huehuetenango',
	STATE_IZABAL: 'Izabal',
	STATE_JALAPA: 'Jalapa',
	STATE_JUTIAPA: 'Jutiapa',
	STATE_PETEN: 'Peten',
	STATE_QUEZALTENANGO: 'Quezaltenango',
	STATE_QUICHE: 'Quiche',
	STATE_RETALHULEU: 'Retalhuleu',
	STATE_SACATEPEQUEZ: 'Sacatepequez',
	STATE_SAN_MARCOS: 'San Marcos',
	STATE_SANTA_ROSA: 'Santa Rosa',
	STATE_SOLOLA: 'Solola',
	STATE_SUCHITEPEQUEZ: 'Suchitepequez',
	STATE_TOTONICAPAN: 'Totonicapan',
	STATE_ZACAPA: 'Zacapa',
	STATE_ALDERNEY: 'Alderney',
	STATE_CASTEL: 'Castel',
	STATE_FOREST: 'Forest',
	STATE_SAINT_PETER_PORT: 'Saint Peter Port',
	STATE_SAINT_PIERRE_DU_BOIS: 'Saint Pierre du Bois',
	STATE_SAINT_SAMPSON: 'Saint Sampson',
	STATE_SAINT_SAVIOUR: 'Saint Saviour',
	STATE_SARK: 'Sark',
	STATE_TORTEVAL: 'Torteval',
	STATE_VALE: 'Vale',
	STATE_BEYLA: 'Beyla',
	STATE_BOFFA: 'Boffa',
	STATE_BOKE: 'Boke',
	STATE_CONAKRY: 'Conakry',
	STATE_COYAH: 'Coyah',
	STATE_DABOLA: 'Dabola',
	STATE_DALABA: 'Dalaba',
	STATE_DINGUIRAYE: 'Dinguiraye',
	STATE_FARANAH: 'Faranah',
	STATE_FORECARIAH: 'Forecariah',
	STATE_FRIA: 'Fria',
	STATE_GAOUAL: 'Gaoual',
	STATE_GUECKEDOU: 'Gueckedou',
	STATE_KANKAN: 'Kankan',
	STATE_KEROUANE: 'Kerouane',
	STATE_KINDIA: 'Kindia',
	STATE_KISSIDOUGOU: 'Kissidougou',
	STATE_KOUBIA: 'Koubia',
	STATE_KOUNDARA: 'Koundara',
	STATE_KOUROUSSA: 'Kouroussa',
	STATE_LABE: 'Labe',
	STATE_LOLA: 'Lola',
	STATE_MACENTA: 'Macenta',
	STATE_MALI: 'Mali',
	STATE_MAMOU: 'Mamou',
	STATE_MANDIANA: 'Mandiana',
	STATE_NZEREKORE: 'Nzerekore',
	STATE_PITA: 'Pita',
	STATE_SIGUIRI: 'Siguiri',
	STATE_TELIMELE: 'Telimele',
	STATE_TOUGUE: 'Tougue',
	STATE_YOMOU: 'Yomou',
	STATE_BAFATA: 'Bafata',
	STATE_BISSAU: 'Bissau',
	STATE_BOLAMA: 'Bolama',
	STATE_CACHEU: 'Cacheu',
	STATE_GABU: 'Gabu',
	STATE_OIO: 'Oio',
	STATE_QUINARA: 'Quinara',
	STATE_TOMBALI: 'Tombali',
	STATE_BARIMA_WAINI: 'Barima-Waini',
	STATE_CUYUNI_MAZARUNI: 'Cuyuni-Mazaruni',
	STATE_DEMERARA_MAHAICA: 'Demerara-Mahaica',
	STATE_EAST_BERBICE_CORENTYNE: 'East Berbice-Corentyne',
	STATE_ESSEQUIBO_ISLANDS_WEST_DEMERAR: 'Essequibo Islands-West Demerar',
	STATE_MAHAICA_BERBICE: 'Mahaica-Berbice',
	STATE_POMEROON_SUPENAAM: 'Pomeroon-Supenaam',
	STATE_POTARO_SIPARUNI: 'Potaro-Siparuni',
	STATE_UPPER_DEMERARA_BERBICE: 'Upper Demerara-Berbice',
	STATE_UPPER_TAKUTU_UPPER_ESSEQUIBO: 'Upper Takutu-Upper Essequibo',
	STATE_ARTIBONITE: 'Artibonite',
	STATE_GRAND__ANSE: "Grand'Anse",
	STATE_NORD_EST: 'Nord-Est',
	STATE_NORD_OUEST: 'Nord-Ouest',
	STATE_SUD_EST: 'Sud-Est',
	STATE_HEARD_AND_MCDONALD_ISLANDS: 'Heard and McDonald Islands',
	STATE_ATLANTIDA: 'Atlantida',
	STATE_CHOLUTECA: 'Choluteca',
	STATE_COLON: 'Colon',
	STATE_COMAYAGUA: 'Comayagua',
	STATE_COPAN: 'Copan',
	STATE_CORTES: 'Cortes',
	STATE_DISTRITO_CENTRAL: 'Distrito Central',
	STATE_EL_PARAISO: 'El Paraiso',
	STATE_FRANCISCO_MORAZAN: 'Francisco Morazan',
	STATE_GRACIAS_A_DIOS: 'Gracias a Dios',
	STATE_INTIBUCA: 'Intibuca',
	STATE_ISLAS_DE_LA_BAHIA: 'Islas de la Bahia',
	STATE_LEMPIRA: 'Lempira',
	STATE_OCOTEPEQUE: 'Ocotepeque',
	STATE_OLANCHO: 'Olancho',
	STATE_SANTA_BARBARA: 'Santa Barbara',
	STATE_VALLE: 'Valle',
	STATE_YORO: 'Yoro',
	STATE_HONG_KONG: 'Hong Kong',
	STATE_BACS_KISKUN: 'Bacs-Kiskun',
	STATE_BARANYA: 'Baranya',
	STATE_BEKES: 'Bekes',
	STATE_BORSOD_ABAUJ_ZEMPLEN: 'Borsod-Abauj-Zemplen',
	STATE_BUDAPEST: 'Budapest',
	STATE_CSONGRAD: 'Csongrad',
	STATE_FEJER: 'Fejer',
	STATE_GYOR_MOSON_SOPRON: 'Gyor-Moson-Sopron',
	STATE_CORK: 'Cork',
	STATE_HAJDU_BIHAR: 'Hajdu-Bihar',
	STATE_HEVES: 'Heves',
	STATE_JASZ_NAGYKUN_SZOLNOK: 'Jasz-Nagykun-Szolnok',
	STATE_KOMAROM_ESZTERGOM: 'Komarom-Esztergom',
	STATE_NOGRAD: 'Nograd',
	STATE_PEST: 'Pest',
	STATE_SOMOGY: 'Somogy',
	STATE_SZABOLCS_SZATMAR_BEREG: 'Szabolcs-Szatmar-Bereg',
	STATE_TOLNA: 'Tolna',
	STATE_VAS: 'Vas',
	STATE_VESZPREM: 'Veszprem',
	STATE_ZALA: 'Zala',
	STATE_AUSTURLAND: 'Austurland',
	STATE_GULLBRINGUSYSLA: 'Gullbringusysla',
	STATE_HOFU_BORGARSVA_I: 'Hofu borgarsva i',
	STATE_NOR_URLAND_EYSTRA: 'Nor urland eystra',
	STATE_NOR_URLAND_VESTRA: 'Nor urland vestra',
	STATE_SU_URLAND: 'Su urland',
	STATE_SU_URNES: 'Su urnes',
	STATE_VESTFIR_IR: 'Vestfir ir',
	STATE_VESTURLAND: 'Vesturland',
	STATE_ACEH: 'Aceh',
	STATE_BALI: 'Bali',
	STATE_BANGKA_BELITUNG: 'Bangka-Belitung',
	STATE_BANTEN: 'Banten',
	STATE_BENGKULU: 'Bengkulu',
	STATE_GANDARIA: 'Gandaria',
	STATE_GORONTALO: 'Gorontalo',
	STATE_JAKARTA: 'Jakarta',
	STATE_JAMBI: 'Jambi',
	STATE_JAWA_BARAT: 'Jawa Barat',
	STATE_JAWA_TENGAH: 'Jawa Tengah',
	STATE_JAWA_TIMUR: 'Jawa Timur',
	STATE_KALIMANTAN_BARAT: 'Kalimantan Barat',
	STATE_KALIMANTAN_SELATAN: 'Kalimantan Selatan',
	STATE_KALIMANTAN_TENGAH: 'Kalimantan Tengah',
	STATE_KALIMANTAN_TIMUR: 'Kalimantan Timur',
	STATE_KENDAL: 'Kendal',
	STATE_LAMPUNG: 'Lampung',
	STATE_MALUKU: 'Maluku',
	STATE_MALUKU_UTARA: 'Maluku Utara',
	STATE_NUSA_TENGGARA_BARAT: 'Nusa Tenggara Barat',
	STATE_NUSA_TENGGARA_TIMUR: 'Nusa Tenggara Timur',
	STATE_PAPUA: 'Papua',
	STATE_RIAU: 'Riau',
	STATE_RIAU_KEPULAUAN: 'Riau Kepulauan',
	STATE_SOLO: 'Solo',
	STATE_SULAWESI_SELATAN: 'Sulawesi Selatan',
	STATE_SULAWESI_TENGAH: 'Sulawesi Tengah',
	STATE_SULAWESI_TENGGARA: 'Sulawesi Tenggara',
	STATE_SULAWESI_UTARA: 'Sulawesi Utara',
	STATE_SUMATERA_BARAT: 'Sumatera Barat',
	STATE_SUMATERA_SELATAN: 'Sumatera Selatan',
	STATE_SUMATERA_UTARA: 'Sumatera Utara',
	STATE_YOGYAKARTA: 'Yogyakarta',
	STATE_ARDABIL: 'Ardabil',
	STATE_AZARBAYJAN_E_BAKHTARI: 'Azarbayjan-e Bakhtari',
	STATE_AZARBAYJAN_E_KHAVARI: 'Azarbayjan-e Khavari',
	STATE_BUSHEHR: 'Bushehr',
	STATE_CHAHAR_MAHAL_E_BAKHTIARI: 'Chahar Mahal-e Bakhtiari',
	STATE_ESFAHAN: 'Esfahan',
	STATE_FARS: 'Fars',
	STATE_GILAN: 'Gilan',
	STATE_GOLESTAN: 'Golestan',
	STATE_HAMADAN: 'Hamadan',
	STATE_HORMOZGAN: 'Hormozgan',
	STATE_ILAM: 'Ilam',
	STATE_KERMAN: 'Kerman',
	STATE_KERMANSHAH: 'Kermanshah',
	STATE_KHORASAN: 'Khorasan',
	STATE_KHUZESTAN: 'Khuzestan',
	STATE_KOHGILUYEH_E_BOYERAHMAD: 'Kohgiluyeh-e Boyerahmad',
	STATE_KORDESTAN: 'Kordestan',
	STATE_LORESTAN: 'Lorestan',
	STATE_MARKAZI: 'Markazi',
	STATE_MAZANDARAN: 'Mazandaran',
	STATE_OSTAN_E_ESFAHAN: 'Ostan-e Esfahan',
	STATE_QAZVIN: 'Qazvin',
	STATE_QOM: 'Qom',
	STATE_SEMNAN: 'Semnan',
	STATE_SISTAN_E_BALUCHESTAN: 'Sistan-e Baluchestan',
	STATE_TEHRAN: 'Tehran',
	STATE_YAZD: 'Yazd',
	STATE_ZANJAN: 'Zanjan',
	STATE_BABIL: 'Babil',
	STATE_BAGHDAD: 'Baghdad',
	STATE_DAHUK: 'Dahuk',
	STATE_DHI_QAR: 'Dhi Qar',
	STATE_DIYALA: 'Diyala',
	STATE_ERBIL: 'Erbil',
	STATE_IRBIL: 'Irbil',
	STATE_KARBALA: 'Karbala',
	STATE_KURDISTAN: 'Kurdistan',
	STATE_MAYSAN: 'Maysan',
	STATE_NINAWA: 'Ninawa',
	STATE_SALAH_AD_DIN: 'Salah-ad-Din',
	STATE_WASIT: 'Wasit',
	STATE_AL_ANBAR: 'al-Anbar',
	STATE_AL_BASRAH: 'al-Basrah',
	STATE_AL_MUTHANNA: 'al-Muthanna',
	STATE_AL_QADISIYAH: 'al-Qadisiyah',
	STATE_AN_NAJAF: 'an-Najaf',
	STATE_AS_SULAYMANIYAH: 'as-Sulaymaniyah',
	STATE_AT_TA__MIM: "at-Ta'mim",
	STATE_ARMAGH: 'Armagh',
	STATE_CARLOW: 'Carlow',
	STATE_CAVAN: 'Cavan',
	STATE_CLARE: 'Clare',
	STATE_DONEGAL: 'Donegal',
	STATE_DUBLIN: 'Dublin',
	STATE_GALWAY: 'Galway',
	STATE_KERRY: 'Kerry',
	STATE_KILDARE: 'Kildare',
	STATE_KILKENNY: 'Kilkenny',
	STATE_LAOIS: 'Laois',
	STATE_LEINSTER: 'Leinster',
	STATE_LEITRIM: 'Leitrim',
	STATE_LIMERICK: 'Limerick',
	STATE_LOCH_GARMAN: 'Loch Garman',
	STATE_LONGFORD: 'Longford',
	STATE_LOUTH: 'Louth',
	STATE_MAYO: 'Mayo',
	STATE_MEATH: 'Meath',
	STATE_MONAGHAN: 'Monaghan',
	STATE_OFFALY: 'Offaly',
	STATE_ROSCOMMON: 'Roscommon',
	STATE_SLIGO: 'Sligo',
	STATE_TIPPERARY_NORTH_RIDING: 'Tipperary North Riding',
	STATE_TIPPERARY_SOUTH_RIDING: 'Tipperary South Riding',
	STATE_ULSTER: 'Ulster',
	STATE_WATERFORD: 'Waterford',
	STATE_WESTMEATH: 'Westmeath',
	STATE_WEXFORD: 'Wexford',
	STATE_WICKLOW: 'Wicklow',
	STATE_BEIT_HANANIA: 'Beit Hanania',
	STATE_BEN_GURION_AIRPORT: 'Ben Gurion Airport',
	STATE_BETHLEHEM: 'Bethlehem',
	STATE_CAESAREA: 'Caesarea',
	STATE_GAZA: 'Gaza',
	STATE_HADARON: 'Hadaron',
	STATE_HAIFA_DISTRICT: 'Haifa District',
	STATE_HAMERKAZ: 'Hamerkaz',
	STATE_HAZAFON: 'Hazafon',
	STATE_HEBRON: 'Hebron',
	STATE_JAFFA: 'Jaffa',
	STATE_JERUSALEM: 'Jerusalem',
	STATE_KHEFA: 'Khefa',
	STATE_KIRYAT_YAM: 'Kiryat Yam',
	STATE_LOWER_GALILEE: 'Lower Galilee',
	STATE_QALQILYA: 'Qalqilya',
	STATE_TALME_ELAZAR: 'Talme Elazar',
	STATE_TEL_AVIV: 'Tel Aviv',
	STATE_TSAFON: 'Tsafon',
	STATE_UMM_EL_FAHEM: 'Umm El Fahem',
	STATE_YERUSHALAYIM: 'Yerushalayim',
	STATE_ABRUZZI: 'Abruzzi',
	STATE_ABRUZZO: 'Abruzzo',
	STATE_AGRIGENTO: 'Agrigento',
	STATE_ALESSANDRIA: 'Alessandria',
	STATE_ANCONA: 'Ancona',
	STATE_AREZZO: 'Arezzo',
	STATE_ASCOLI_PICENO: 'Ascoli Piceno',
	STATE_ASTI: 'Asti',
	STATE_AVELLINO: 'Avellino',
	STATE_BARI: 'Bari',
	STATE_BASILICATA: 'Basilicata',
	STATE_BELLUNO: 'Belluno',
	STATE_BENEVENTO: 'Benevento',
	STATE_BERGAMO: 'Bergamo',
	STATE_BIELLA: 'Biella',
	STATE_BOLOGNA: 'Bologna',
	STATE_BOLZANO: 'Bolzano',
	STATE_BRESCIA: 'Brescia',
	STATE_BRINDISI: 'Brindisi',
	STATE_CALABRIA: 'Calabria',
	STATE_CAMPANIA: 'Campania',
	STATE_CARTOCETO: 'Cartoceto',
	STATE_CASERTA: 'Caserta',
	STATE_CATANIA: 'Catania',
	STATE_CHIETI: 'Chieti',
	STATE_COMO: 'Como',
	STATE_COSENZA: 'Cosenza',
	STATE_CREMONA: 'Cremona',
	STATE_CUNEO: 'Cuneo',
	STATE_EMILIA_ROMAGNA: 'Emilia-Romagna',
	STATE_FERRARA: 'Ferrara',
	STATE_FIRENZE: 'Firenze',
	STATE_FLORENCE: 'Florence',
	STATE_FORLI_CESENA: 'Forli-Cesena',
	STATE_FRIULI_VENEZIA_GIULIA: 'Friuli-Venezia Giulia',
	STATE_FROSINONE: 'Frosinone',
	STATE_GENOA: 'Genoa',
	STATE_GORIZIA: 'Gorizia',
	STATE_L__AQUILA: "L'Aquila",
	STATE_LAZIO: 'Lazio',
	STATE_LECCE: 'Lecce',
	STATE_LECCO: 'Lecco',
	STATE_LECCO_PROVINCE: 'Lecco Province',
	STATE_LIGURIA: 'Liguria',
	STATE_LODI: 'Lodi',
	STATE_LOMBARDIA: 'Lombardia',
	STATE_LOMBARDY: 'Lombardy',
	STATE_MACERATA: 'Macerata',
	STATE_MANTOVA: 'Mantova',
	STATE_MARCHE: 'Marche',
	STATE_MESSINA: 'Messina',
	STATE_MILAN: 'Milan',
	STATE_MODENA: 'Modena',
	STATE_MOLISE: 'Molise',
	STATE_MOLTENO: 'Molteno',
	STATE_MONTENEGRO: 'Montenegro',
	STATE_MONZA_AND_BRIANZA: 'Monza and Brianza',
	STATE_NAPLES: 'Naples',
	STATE_NOVARA: 'Novara',
	STATE_PADOVA: 'Padova',
	STATE_PARMA: 'Parma',
	STATE_PAVIA: 'Pavia',
	STATE_RUMPHI: 'Rumphi',
	STATE_PERUGIA: 'Perugia',
	STATE_PESARO_URBINO: 'Pesaro-Urbino',
	STATE_PIACENZA: 'Piacenza',
	STATE_PIEDMONT: 'Piedmont',
	STATE_PIEMONTE: 'Piemonte',
	STATE_PISA: 'Pisa',
	STATE_PORDENONE: 'Pordenone',
	STATE_POTENZA: 'Potenza',
	STATE_PUGLIA: 'Puglia',
	STATE_REGGIO_EMILIA: 'Reggio Emilia',
	STATE_RIMINI: 'Rimini',
	STATE_ROMA: 'Roma',
	STATE_SALERNO: 'Salerno',
	STATE_SARDEGNA: 'Sardegna',
	STATE_SASSARI: 'Sassari',
	STATE_SAVONA: 'Savona',
	STATE_SICILIA: 'Sicilia',
	STATE_SIENA: 'Siena',
	STATE_SONDRIO: 'Sondrio',
	STATE_SOUTH_TYROL: 'South Tyrol',
	STATE_TARANTO: 'Taranto',
	STATE_TERAMO: 'Teramo',
	STATE_TORINO: 'Torino',
	STATE_TOSCANA: 'Toscana',
	STATE_TRAPANI: 'Trapani',
	STATE_TRENTINO_ALTO_ADIGE: 'Trentino-Alto Adige',
	STATE_TRENTO: 'Trento',
	STATE_TREVISO: 'Treviso',
	STATE_UDINE: 'Udine',
	STATE_UMBRIA: 'Umbria',
	STATE_VALLE_D__AOSTA: "Valle d'Aosta",
	STATE_VARESE: 'Varese',
	STATE_VENETO: 'Veneto',
	STATE_VENEZIA: 'Venezia',
	STATE_VERBANO_CUSIO_OSSOLA: 'Verbano-Cusio-Ossola',
	STATE_VERCELLI: 'Vercelli',
	STATE_VERONA: 'Verona',
	STATE_VICENZA: 'Vicenza',
	STATE_VITERBO: 'Viterbo',
	STATE_BUXORO_VILOYATI: 'Buxoro Viloyati',
	STATE_CLARENDON: 'Clarendon',
	STATE_HANOVER: 'Hanover',
	STATE_KINGSTON: 'Kingston',
	STATE_MANCHESTER: 'Manchester',
	STATE_PORTLAND: 'Portland',
	STATE_SAINT_ANDREWS: 'Saint Andrews',
	STATE_SAINT_ANN: 'Saint Ann',
	STATE_SAINT_CATHERINE: 'Saint Catherine',
	STATE_SAINT_ELIZABETH: 'Saint Elizabeth',
	STATE_TRELAWNEY: 'Trelawney',
	STATE_WESTMORELAND: 'Westmoreland',
	STATE_AICHI: 'Aichi',
	STATE_AKITA: 'Akita',
	STATE_AOMORI: 'Aomori',
	STATE_CHIBA: 'Chiba',
	STATE_EHIME: 'Ehime',
	STATE_FUKUI: 'Fukui',
	STATE_FUKUOKA: 'Fukuoka',
	STATE_FUKUSHIMA: 'Fukushima',
	STATE_GIFU: 'Gifu',
	STATE_GUMMA: 'Gumma',
	STATE_HIROSHIMA: 'Hiroshima',
	STATE_HOKKAIDO: 'Hokkaido',
	STATE_HYOGO: 'Hyogo',
	STATE_IBARAKI: 'Ibaraki',
	STATE_ISHIKAWA: 'Ishikawa',
	STATE_IWATE: 'Iwate',
	STATE_KAGAWA: 'Kagawa',
	STATE_KAGOSHIMA: 'Kagoshima',
	STATE_KANAGAWA: 'Kanagawa',
	STATE_KANTO: 'Kanto',
	STATE_KOCHI: 'Kochi',
	STATE_KUMAMOTO: 'Kumamoto',
	STATE_KYOTO: 'Kyoto',
	STATE_MIE: 'Mie',
	STATE_MIYAGI: 'Miyagi',
	STATE_MIYAZAKI: 'Miyazaki',
	STATE_NAGANO: 'Nagano',
	STATE_NAGASAKI: 'Nagasaki',
	STATE_NARA: 'Nara',
	STATE_NIIGATA: 'Niigata',
	STATE_OITA: 'Oita',
	STATE_OKAYAMA: 'Okayama',
	STATE_OKINAWA: 'Okinawa',
	STATE_OSAKA: 'Osaka',
	STATE_SAGA: 'Saga',
	STATE_SAITAMA: 'Saitama',
	STATE_SHIGA: 'Shiga',
	STATE_SHIMANE: 'Shimane',
	STATE_SHIZUOKA: 'Shizuoka',
	STATE_TOCHIGI: 'Tochigi',
	STATE_TOKUSHIMA: 'Tokushima',
	STATE_TOKYO: 'Tokyo',
	STATE_TOTTORI: 'Tottori',
	STATE_TOYAMA: 'Toyama',
	STATE_WAKAYAMA: 'Wakayama',
	STATE_YAMAGATA: 'Yamagata',
	STATE_YAMAGUCHI: 'Yamaguchi',
	STATE_YAMANASHI: 'Yamanashi',
	STATE_GROUVILLE: 'Grouville',
	STATE_SAINT_BRELADE: 'Saint Brelade',
	STATE_SAINT_CLEMENT: 'Saint Clement',
	STATE_SAINT_HELIER: 'Saint Helier',
	STATE_SAINT_LAWRENCE: 'Saint Lawrence',
	STATE_SALIMA: 'Salima',
	STATE_TRINITY: 'Trinity',
	STATE_AJLUN: 'Ajlun',
	STATE_AMMAN: 'Amman',
	STATE_IRBID: 'Irbid',
	STATE_JARASH: 'Jarash',
	STATE_MA__AN: "Ma'an",
	STATE_MADABA: 'Madaba',
	STATE_AL___AQABAH: "al-'Aqabah",
	STATE_AL_BALQA: 'al-Balqa',
	STATE_AL_KARAK: 'al-Karak',
	STATE_AL_MAFRAQ: 'al-Mafraq',
	STATE_AT_TAFILAH: 'at-Tafilah',
	STATE_AZ_ZARQA: 'az-Zarqa',
	STATE_AKMECET: 'Akmecet',
	STATE_AKMOLA: 'Akmola',
	STATE_AKTOBE: 'Aktobe',
	STATE_ALMATI: 'Almati',
	STATE_ATIRAU: 'Atirau',
	STATE_BATIS_KAZAKSTAN: 'Batis Kazakstan',
	STATE_BURLINSKY_REGION: 'Burlinsky Region',
	STATE_KARAGANDI: 'Karagandi',
	STATE_KOSTANAY: 'Kostanay',
	STATE_MANKISTAU: 'Mankistau',
	STATE_ONTUSTIK_KAZAKSTAN: 'Ontustik Kazakstan',
	STATE_PAVLODAR: 'Pavlodar',
	STATE_SIGIS_KAZAKSTAN: 'Sigis Kazakstan',
	STATE_SOLTUSTIK_KAZAKSTAN: 'Soltustik Kazakstan',
	STATE_TARAZ: 'Taraz',
	STATE_COAST: 'Coast',
	STATE_NAIROBI: 'Nairobi',
	STATE_NORTH_EASTERN: 'North Eastern',
	STATE_NYANZA: 'Nyanza',
	STATE_RIFT_VALLEY: 'Rift Valley',
	STATE_ABAIANG: 'Abaiang',
	STATE_ABEMANA: 'Abemana',
	STATE_ARANUKA: 'Aranuka',
	STATE_ARORAE: 'Arorae',
	STATE_BANABA: 'Banaba',
	STATE_BERU: 'Beru',
	STATE_BUTARITARI: 'Butaritari',
	STATE_KIRITIMATI: 'Kiritimati',
	STATE_KURIA: 'Kuria',
	STATE_MAIANA: 'Maiana',
	STATE_MAKIN: 'Makin',
	STATE_MARAKEI: 'Marakei',
	STATE_NIKUNAU: 'Nikunau',
	STATE_NONOUTI: 'Nonouti',
	STATE_ONOTOA: 'Onotoa',
	STATE_PHOENIX_ISLANDS: 'Phoenix Islands',
	STATE_TABITEUEA_NORTH: 'Tabiteuea North',
	STATE_TABITEUEA_SOUTH: 'Tabiteuea South',
	STATE_TABUAERAN: 'Tabuaeran',
	STATE_TAMANA: 'Tamana',
	STATE_TARAWA_NORTH: 'Tarawa North',
	STATE_TARAWA_SOUTH: 'Tarawa South',
	STATE_TERAINA: 'Teraina',
	STATE_CHAGANGDO: 'Chagangdo',
	STATE_HAMGYEONGBUKTO: 'Hamgyeongbukto',
	STATE_HAMGYEONGNAMDO: 'Hamgyeongnamdo',
	STATE_HWANGHAEBUKTO: 'Hwanghaebukto',
	STATE_HWANGHAENAMDO: 'Hwanghaenamdo',
	STATE_KAESEONG: 'Kaeseong',
	STATE_KANGWEON: 'Kangweon',
	STATE_NAMPO: 'Nampo',
	STATE_PYEONGANBUKTO: 'Pyeonganbukto',
	STATE_PYEONGANNAMDO: 'Pyeongannamdo',
	STATE_PYEONGYANG: 'Pyeongyang',
	STATE_YANGGANG: 'Yanggang',
	STATE_BUSAN: 'Busan',
	STATE_CHEJU: 'Cheju',
	STATE_CHOLLABUK: 'Chollabuk',
	STATE_CHOLLANAM: 'Chollanam',
	STATE_CHUNGBUK: 'Chungbuk',
	STATE_CHUNGCHEONGBUK: 'Chungcheongbuk',
	STATE_CHUNGCHEONGNAM: 'Chungcheongnam',
	STATE_CHUNGNAM: 'Chungnam',
	STATE_DAEGU: 'Daegu',
	STATE_GANGWON_DO: 'Gangwon-do',
	STATE_GOYANG_SI: 'Goyang-si',
	STATE_GYEONGGI_DO: 'Gyeonggi-do',
	STATE_GYEONGSANG: 'Gyeongsang',
	STATE_GYEONGSANGNAM_DO: 'Gyeongsangnam-do',
	STATE_INCHEON: 'Incheon',
	STATE_JEJU_SI: 'Jeju-Si',
	STATE_JEONBUK: 'Jeonbuk',
	STATE_KWANGJU: 'Kwangju',
	STATE_KYEONGGI: 'Kyeonggi',
	STATE_KYEONGSANGBUK: 'Kyeongsangbuk',
	STATE_KYEONGSANGNAM: 'Kyeongsangnam',
	STATE_KYONGGI_DO: 'Kyonggi-do',
	STATE_KYUNGBUK_DO: 'Kyungbuk-Do',
	STATE_KYUNGGI_DO: 'Kyunggi-Do',
	STATE_PUSAN: 'Pusan',
	STATE_SEOUL: 'Seoul',
	STATE_SUDOGWON: 'Sudogwon',
	STATE_TAEGU: 'Taegu',
	STATE_TAEJEON: 'Taejeon',
	STATE_TAEJON_GWANGYOKSI: 'Taejon-gwangyoksi',
	STATE_ULSAN: 'Ulsan',
	STATE_WONJU: 'Wonju',
	STATE_GWANGYOKSI: 'gwangyoksi',
	STATE_AL_ASIMAH: 'Al Asimah',
	STATE_HAWALLI: 'Hawalli',
	STATE_MISHREF: 'Mishref',
	STATE_QADESIYA: 'Qadesiya',
	STATE_SAFAT: 'Safat',
	STATE_SALMIYA: 'Salmiya',
	STATE_AL_AHMADI: 'al-Ahmadi',
	STATE_AL_FARWANIYAH: 'al-Farwaniyah',
	STATE_AL_JAHRA: 'al-Jahra',
	STATE_AL_KUWAYT: 'al-Kuwayt',
	STATE_BATKEN: 'Batken',
	STATE_BISHKEK: 'Bishkek',
	STATE_CHUI: 'Chui',
	STATE_ISSYK_KUL: 'Issyk-Kul',
	STATE_JALAL_ABAD: 'Jalal-Abad',
	STATE_NARYN: 'Naryn',
	STATE_OSH: 'Osh',
	STATE_TALAS: 'Talas',
	STATE_ATTOPU: 'Attopu',
	STATE_BOKEO: 'Bokeo',
	STATE_BOLIKHAMSAY: 'Bolikhamsay',
	STATE_CHAMPASAK: 'Champasak',
	STATE_HOUAPHANH: 'Houaphanh',
	STATE_KHAMMOUANE: 'Khammouane',
	STATE_LUANG_NAM_THA: 'Luang Nam Tha',
	STATE_LUANG_PRABANG: 'Luang Prabang',
	STATE_OUDOMXAY: 'Oudomxay',
	STATE_PHONGSALY: 'Phongsaly',
	STATE_SARAVAN: 'Saravan',
	STATE_SAVANNAKHET: 'Savannakhet',
	STATE_SEKONG: 'Sekong',
	STATE_VIANGCHAN_PREFECTURE: 'Viangchan Prefecture',
	STATE_VIANGCHAN_PROVINCE: 'Viangchan Province',
	STATE_XAIGNABURY: 'Xaignabury',
	STATE_XIANG_KHUANG: 'Xiang Khuang',
	STATE_AIZKRAUKLES: 'Aizkraukles',
	STATE_ALUKSNES: 'Aluksnes',
	STATE_BALVU: 'Balvu',
	STATE_BAUSKAS: 'Bauskas',
	STATE_CESU: 'Cesu',
	STATE_DAUGAVPILS: 'Daugavpils',
	STATE_DAUGAVPILS_CITY: 'Daugavpils City',
	STATE_DOBELES: 'Dobeles',
	STATE_GULBENES: 'Gulbenes',
	STATE_JEKABSPILS: 'Jekabspils',
	STATE_JELGAVA: 'Jelgava',
	STATE_JELGAVAS: 'Jelgavas',
	STATE_JURMALA_CITY: 'Jurmala City',
	STATE_KRASLAVAS: 'Kraslavas',
	STATE_KULDIGAS: 'Kuldigas',
	STATE_LIEPAJA: 'Liepaja',
	STATE_LIEPAJAS: 'Liepajas',
	STATE_LIMBAZHU: 'Limbazhu',
	STATE_LUDZAS: 'Ludzas',
	STATE_MADONAS: 'Madonas',
	STATE_OGRES: 'Ogres',
	STATE_PREILU: 'Preilu',
	STATE_REZEKNE: 'Rezekne',
	STATE_REZEKNES: 'Rezeknes',
	STATE_RIGA: 'Riga',
	STATE_RIGAS: 'Rigas',
	STATE_SALDUS: 'Saldus',
	STATE_TALSU: 'Talsu',
	STATE_TUKUMA: 'Tukuma',
	STATE_VALKAS: 'Valkas',
	STATE_VALMIERAS: 'Valmieras',
	STATE_VENTSPILS: 'Ventspils',
	STATE_VENTSPILS_CITY: 'Ventspils City',
	STATE_BEIRUT: 'Beirut',
	STATE_JABAL_LUBNAN: 'Jabal Lubnan',
	STATE_MOHAFAZAT_LIBAN_NORD: 'Mohafazat Liban-Nord',
	STATE_MOHAFAZAT_MONT_LIBAN: 'Mohafazat Mont-Liban',
	STATE_SIDON: 'Sidon',
	STATE_AL_BIQA: 'al-Biqa',
	STATE_AL_JANUB: 'al-Janub',
	STATE_AN_NABATIYAH: 'an-Nabatiyah',
	STATE_ASH_SHAMAL: 'ash-Shamal',
	STATE_BEREA: 'Berea',
	STATE_BUTHA_BUTHE: 'Butha-Buthe',
	STATE_LERIBE: 'Leribe',
	STATE_MAFETENG: 'Mafeteng',
	STATE_MASERU: 'Maseru',
	STATE_MOHALE__S_HOEK: "Mohale's Hoek",
	STATE_MOKHOTLONG: 'Mokhotlong',
	STATE_QACHA__S_NEK: "Qacha's Nek",
	STATE_QUTHING: 'Quthing',
	STATE_THABA_TSEKA: 'Thaba-Tseka',
	STATE_BOMI: 'Bomi',
	STATE_BONG: 'Bong',
	STATE_GRAND_BASSA: 'Grand Bassa',
	STATE_GRAND_CAPE_MOUNT: 'Grand Cape Mount',
	STATE_GRAND_GEDEH: 'Grand Gedeh',
	STATE_LOFFA: 'Loffa',
	STATE_MARGIBI: 'Margibi',
	STATE_MARYLAND_AND_GRAND_KRU: 'Maryland and Grand Kru',
	STATE_MONTSERRADO: 'Montserrado',
	STATE_NIMBA: 'Nimba',
	STATE_RIVERCESS: 'Rivercess',
	STATE_SINOE: 'Sinoe',
	STATE_AJDABIYA: 'Ajdabiya',
	STATE_FEZZAN: 'Fezzan',
	STATE_BANGHAZI: 'Banghazi',
	STATE_DARNAH: 'Darnah',
	STATE_GHADAMIS: 'Ghadamis',
	STATE_GHARYAN: 'Gharyan',
	STATE_MISRATAH: 'Misratah',
	STATE_MURZUQ: 'Murzuq',
	STATE_SABHA: 'Sabha',
	STATE_SAWFAJJIN: 'Sawfajjin',
	STATE_SURT: 'Surt',
	STATE_TARABULUS: 'Tarabulus',
	STATE_TARHUNAH: 'Tarhunah',
	STATE_TRIPOLITANIA: 'Tripolitania',
	STATE_TUBRUQ: 'Tubruq',
	STATE_YAFRAN: 'Yafran',
	STATE_ZLITAN: 'Zlitan',
	STATE_AL___AZIZIYAH: "al-'Aziziyah",
	STATE_AL_FATIH: 'al-Fatih',
	STATE_AL_JABAL_AL_AKHDAR: 'al-Jabal al Akhdar',
	STATE_AL_JUFRAH: 'al-Jufrah',
	STATE_AL_KHUMS: 'al-Khums',
	STATE_AL_KUFRAH: 'al-Kufrah',
	STATE_AN_NUQAT_AL_KHAMS: 'an-Nuqat al-Khams',
	STATE_ASH_SHATI: 'ash-Shati',
	STATE_AZ_ZAWIYAH: 'az-Zawiyah',
	STATE_BALZERS: 'Balzers',
	STATE_ESCHEN: 'Eschen',
	STATE_GAMPRIN: 'Gamprin',
	STATE_MAUREN: 'Mauren',
	STATE_PLANKEN: 'Planken',
	STATE_RUGGELL: 'Ruggell',
	STATE_SCHAAN: 'Schaan',
	STATE_SCHELLENBERG: 'Schellenberg',
	STATE_TRIESEN: 'Triesen',
	STATE_TRIESENBERG: 'Triesenberg',
	STATE_VADUZ: 'Vaduz',
	STATE_ALYTAUS: 'Alytaus',
	STATE_ANYKSCIAI: 'Anyksciai',
	STATE_KAUNO: 'Kauno',
	STATE_KLAIPEDOS: 'Klaipedos',
	STATE_MARIJAMPOLES: 'Marijampoles',
	STATE_PANEVEZHIO: 'Panevezhio',
	STATE_PANEVEZYS: 'Panevezys',
	STATE_SHIAULIU: 'Shiauliu',
	STATE_TAURAGES: 'Taurages',
	STATE_TELSHIU: 'Telshiu',
	STATE_TELSIAI: 'Telsiai',
	STATE_UTENOS: 'Utenos',
	STATE_VILNIAUS: 'Vilniaus',
	STATE_CAPELLEN: 'Capellen',
	STATE_CLERVAUX: 'Clervaux',
	STATE_DIEKIRCH: 'Diekirch',
	STATE_ECHTERNACH: 'Echternach',
	STATE_ESCH_SUR_ALZETTE: 'Esch-sur-Alzette',
	STATE_GREVENMACHER: 'Grevenmacher',
	STATE_MERSCH: 'Mersch',
	STATE_REDANGE: 'Redange',
	STATE_REMICH: 'Remich',
	STATE_VIANDEN: 'Vianden',
	STATE_WILTZ: 'Wiltz',
	STATE_MACAU: 'Macau',
	STATE_BEROVO: 'Berovo',
	STATE_BITOLA: 'Bitola',
	STATE_BROD: 'Brod',
	STATE_DEBAR: 'Debar',
	STATE_DELCHEVO: 'Delchevo',
	STATE_DEMIR_HISAR: 'Demir Hisar',
	STATE_GEVGELIJA: 'Gevgelija',
	STATE_GOSTIVAR: 'Gostivar',
	STATE_KAVADARCI: 'Kavadarci',
	STATE_KICHEVO: 'Kichevo',
	STATE_KOCHANI: 'Kochani',
	STATE_KRATOVO: 'Kratovo',
	STATE_KRIVA_PALANKA: 'Kriva Palanka',
	STATE_KRUSHEVO: 'Krushevo',
	STATE_KUMANOVO: 'Kumanovo',
	STATE_NEGOTINO: 'Negotino',
	STATE_OHRID: 'Ohrid',
	STATE_PRILEP: 'Prilep',
	STATE_PROBISHTIP: 'Probishtip',
	STATE_RADOVISH: 'Radovish',
	STATE_RESEN: 'Resen',
	STATE_SHTIP: 'Shtip',
	STATE_SKOPJE: 'Skopje',
	STATE_STRUGA: 'Struga',
	STATE_STRUMICA: 'Strumica',
	STATE_SVETI_NIKOLE: 'Sveti Nikole',
	STATE_TETOVO: 'Tetovo',
	STATE_VALANDOVO: 'Valandovo',
	STATE_VELES: 'Veles',
	STATE_VINICA: 'Vinica',
	STATE_ANTANANARIVO: 'Antananarivo',
	STATE_ANTSIRANANA: 'Antsiranana',
	STATE_FIANARANTSOA: 'Fianarantsoa',
	STATE_MAHAJANGA: 'Mahajanga',
	STATE_TOAMASINA: 'Toamasina',
	STATE_TOLIARY: 'Toliary',
	STATE_BALAKA: 'Balaka',
	STATE_BLANTYRE_CITY: 'Blantyre City',
	STATE_CHIKWAWA: 'Chikwawa',
	STATE_CHIRADZULU: 'Chiradzulu',
	STATE_CHITIPA: 'Chitipa',
	STATE_DEDZA: 'Dedza',
	STATE_DOWA: 'Dowa',
	STATE_KARONGA: 'Karonga',
	STATE_KASUNGU: 'Kasungu',
	STATE_LILONGWE_CITY: 'Lilongwe City',
	STATE_MACHINGA: 'Machinga',
	STATE_MANGOCHI: 'Mangochi',
	STATE_MCHINJI: 'Mchinji',
	STATE_MULANJE: 'Mulanje',
	STATE_MWANZA: 'Mwanza',
	STATE_MZIMBA: 'Mzimba',
	STATE_MZUZU_CITY: 'Mzuzu City',
	STATE_NKHATA_BAY: 'Nkhata Bay',
	STATE_NKHOTAKOTA: 'Nkhotakota',
	STATE_NSANJE: 'Nsanje',
	STATE_NTCHEU: 'Ntcheu',
	STATE_NTCHISI: 'Ntchisi',
	STATE_PHALOMBE: 'Phalombe',
	STATE_THYOLO: 'Thyolo',
	STATE_ZOMBA_MUNICIPALITY: 'Zomba Municipality',
	STATE_JOHOR: 'Johor',
	STATE_KEDAH: 'Kedah',
	STATE_KELANTAN: 'Kelantan',
	STATE_KUALA_LUMPUR: 'Kuala Lumpur',
	STATE_LABUAN: 'Labuan',
	STATE_MELAKA: 'Melaka',
	STATE_NEGERI_JOHOR: 'Negeri Johor',
	STATE_NEGERI_SEMBILAN: 'Negeri Sembilan',
	STATE_PAHANG: 'Pahang',
	STATE_PENANG: 'Penang',
	STATE_PERAK: 'Perak',
	STATE_PERLIS: 'Perlis',
	STATE_PULAU_PINANG: 'Pulau Pinang',
	STATE_SABAH: 'Sabah',
	STATE_SARAWAK: 'Sarawak',
	STATE_SELANGOR: 'Selangor',
	STATE_SEMBILAN: 'Sembilan',
	STATE_TERENGGANU: 'Terengganu',
	STATE_ALIF_ALIF: 'Alif Alif',
	STATE_ALIF_DHAAL: 'Alif Dhaal',
	STATE_BAA: 'Baa',
	STATE_DHAAL: 'Dhaal',
	STATE_FAAF: 'Faaf',
	STATE_GAAF_ALIF: 'Gaaf Alif',
	STATE_GAAF_DHAAL: 'Gaaf Dhaal',
	STATE_GHAVIYANI: 'Ghaviyani',
	STATE_HAA_ALIF: 'Haa Alif',
	STATE_HAA_DHAAL: 'Haa Dhaal',
	STATE_KAAF: 'Kaaf',
	STATE_LAAM: 'Laam',
	STATE_LHAVIYANI: 'Lhaviyani',
	STATE_MALE: 'Male',
	STATE_MIIM: 'Miim',
	STATE_NUUN: 'Nuun',
	STATE_RAA: 'Raa',
	STATE_SHAVIYANI: 'Shaviyani',
	STATE_SIIN: 'Siin',
	STATE_THAA: 'Thaa',
	STATE_VAAV: 'Vaav',
	STATE_BAMAKO: 'Bamako',
	STATE_GAO: 'Gao',
	STATE_KAYES: 'Kayes',
	STATE_KIDAL: 'Kidal',
	STATE_KOULIKORO: 'Koulikoro',
	STATE_MOPTI: 'Mopti',
	STATE_SEGOU: 'Segou',
	STATE_SIKASSO: 'Sikasso',
	STATE_TOMBOUCTOU: 'Tombouctou',
	STATE_GOZO_AND_COMINO: 'Gozo and Comino',
	STATE_INNER_HARBOUR: 'Inner Harbour',
	STATE_OUTER_HARBOUR: 'Outer Harbour',
	STATE_SOUTH_EASTERN: 'South Eastern',
	STATE_CASTLETOWN: 'Castletown',
	STATE_DOUGLAS: 'Douglas',
	STATE_LAXEY: 'Laxey',
	STATE_ONCHAN: 'Onchan',
	STATE_PEEL: 'Peel',
	STATE_PORT_ERIN: 'Port Erin',
	STATE_PORT_SAINT_MARY: 'Port Saint Mary',
	STATE_RAMSEY: 'Ramsey',
	STATE_AILINLAPLAP: 'Ailinlaplap',
	STATE_AILUK: 'Ailuk',
	STATE_ARNO: 'Arno',
	STATE_AUR: 'Aur',
	STATE_BIKINI: 'Bikini',
	STATE_EBON: 'Ebon',
	STATE_ENEWETAK: 'Enewetak',
	STATE_JABAT: 'Jabat',
	STATE_JALUIT: 'Jaluit',
	STATE_KILI: 'Kili',
	STATE_KWAJALEIN: 'Kwajalein',
	STATE_LAE: 'Lae',
	STATE_LIB: 'Lib',
	STATE_LIKIEP: 'Likiep',
	STATE_MAJURO: 'Majuro',
	STATE_MALOELAP: 'Maloelap',
	STATE_MEJIT: 'Mejit',
	STATE_MILI: 'Mili',
	STATE_NAMORIK: 'Namorik',
	STATE_NAMU: 'Namu',
	STATE_RONGELAP: 'Rongelap',
	STATE_UJAE: 'Ujae',
	STATE_UTRIK: 'Utrik',
	STATE_WOTHO: 'Wotho',
	STATE_WOTJE: 'Wotje',
	STATE_FORT_DE_FRANCE: 'Fort-de-France',
	STATE_LA_TRINITE: 'La Trinite',
	STATE_LE_MARIN: 'Le Marin',
	STATE_SAINT_PIERRE: 'Saint-Pierre',
	STATE_ASSABA: 'Assaba',
	STATE_BRAKNA: 'Brakna',
	STATE_DHAKHLAT_NAWADIBU: 'Dhakhlat Nawadibu',
	STATE_HUDH_AL_GHARBI: 'Hudh-al-Gharbi',
	STATE_HUDH_ASH_SHARQI: 'Hudh-ash-Sharqi',
	STATE_INSHIRI: 'Inshiri',
	STATE_NAWAKSHUT: 'Nawakshut',
	STATE_QIDIMAGHA: 'Qidimagha',
	STATE_QURQUL: 'Qurqul',
	STATE_TAQANT: 'Taqant',
	STATE_TIRIS_ZAMMUR: 'Tiris Zammur',
	STATE_TRARZA: 'Trarza',
	STATE_BLACK_RIVER: 'Black River',
	STATE_EAU_COULEE: 'Eau Coulee',
	STATE_FLACQ: 'Flacq',
	STATE_FLOREAL: 'Floreal',
	STATE_GRAND_PORT: 'Grand Port',
	STATE_MOKA: 'Moka',
	STATE_PAMPLEMPOUSSES: 'Pamplempousses',
	STATE_PLAINES_WILHELM: 'Plaines Wilhelm',
	STATE_PORT_LOUIS: 'Port Louis',
	STATE_RIVIERE_DU_REMPART: 'Riviere du Rempart',
	STATE_RODRIGUES: 'Rodrigues',
	STATE_ROSE_HILL: 'Rose Hill',
	STATE_SAVANNE: 'Savanne',
	STATE_MAYOTTE: 'Mayotte',
	STATE_PAMANZI: 'Pamanzi',
	STATE_AGUASCALIENTES: 'Aguascalientes',
	STATE_BAJA_CALIFORNIA: 'Baja California',
	STATE_BAJA_CALIFORNIA_SUR: 'Baja California Sur',
	STATE_CAMPECHE: 'Campeche',
	STATE_CHIAPAS: 'Chiapas',
	STATE_CHIHUAHUA: 'Chihuahua',
	STATE_COAHUILA: 'Coahuila',
	STATE_COLIMA: 'Colima',
	STATE_DURANGO: 'Durango',
	STATE_ESTADO_DE_MEXICO: 'Estado de Mexico',
	STATE_GUANAJUATO: 'Guanajuato',
	STATE_GUERRERO: 'Guerrero',
	STATE_HIDALGO: 'Hidalgo',
	STATE_JALISCO: 'Jalisco',
	STATE_MEXICO: 'Mexico',
	STATE_MICHOACAN: 'Michoacan',
	STATE_MORELOS: 'Morelos',
	STATE_NAYARIT: 'Nayarit',
	STATE_NUEVO_LEON: 'Nuevo Leon',
	STATE_OAXACA: 'Oaxaca',
	STATE_PUEBLA: 'Puebla',
	STATE_QUERETARO: 'Queretaro',
	STATE_QUINTANA_ROO: 'Quintana Roo',
	STATE_SAN_LUIS_POTOSI: 'San Luis Potosi',
	STATE_SINALOA: 'Sinaloa',
	STATE_SONORA: 'Sonora',
	STATE_TABASCO: 'Tabasco',
	STATE_TAMAULIPAS: 'Tamaulipas',
	STATE_TLAXCALA: 'Tlaxcala',
	STATE_VERACRUZ: 'Veracruz',
	STATE_YUCATAN: 'Yucatan',
	STATE_ZACATECAS: 'Zacatecas',
	STATE_CHUUK: 'Chuuk',
	STATE_KUSAIE: 'Kusaie',
	STATE_POHNPEI: 'Pohnpei',
	STATE_YAP: 'Yap',
	STATE_BALTI: 'Balti',
	STATE_CAHUL: 'Cahul',
	STATE_CHISINAU: 'Chisinau',
	STATE_CHISINAU_ORAS: 'Chisinau Oras',
	STATE_EDINET: 'Edinet',
	STATE_GAGAUZIA: 'Gagauzia',
	STATE_LAPUSNA: 'Lapusna',
	STATE_ORHEI: 'Orhei',
	STATE_SOROCA: 'Soroca',
	STATE_TARACLIA: 'Taraclia',
	STATE_TIGHINA: 'Tighina',
	STATE_TRANSNISTRIA: 'Transnistria',
	STATE_UNGHENI: 'Ungheni',
	STATE_FONTVIEILLE: 'Fontvieille',
	STATE_LA_CONDAMINE: 'La Condamine',
	STATE_MONACO_VILLE: 'Monaco-Ville',
	STATE_MONTE_CARLO: 'Monte Carlo',
	STATE_ARHANGAJ: 'Arhangaj',
	STATE_BAJAN_OLGIJ: 'Bajan-Olgij',
	STATE_BAJANHONGOR: 'Bajanhongor',
	STATE_BULGAN: 'Bulgan',
	STATE_DARHAN_UUL: 'Darhan-Uul',
	STATE_DORNOD: 'Dornod',
	STATE_DORNOGOVI: 'Dornogovi',
	STATE_DUNDGOVI: 'Dundgovi',
	STATE_GOVI_ALTAJ: 'Govi-Altaj',
	STATE_GOVISUMBER: 'Govisumber',
	STATE_HENTIJ: 'Hentij',
	STATE_HOVD: 'Hovd',
	STATE_HOVSGOL: 'Hovsgol',
	STATE_OMNOGOVI: 'Omnogovi',
	STATE_ORHON: 'Orhon',
	STATE_OVORHANGAJ: 'Ovorhangaj',
	STATE_SELENGE: 'Selenge',
	STATE_SUHBAATAR: 'Suhbaatar',
	STATE_TOV: 'Tov',
	STATE_ULAANBAATAR: 'Ulaanbaatar',
	STATE_UVS: 'Uvs',
	STATE_ZAVHAN: 'Zavhan',
	STATE_MONTSERRAT: 'Montserrat',
	STATE_AGADIR: 'Agadir',
	STATE_CASABLANCA: 'Casablanca',
	STATE_CHAOUIA_OUARDIGHA: 'Chaouia-Ouardigha',
	STATE_DOUKKALA_ABDA: 'Doukkala-Abda',
	STATE_FES_BOULEMANE: 'Fes-Boulemane',
	STATE_GHARB_CHRARDA_BENI_HSSEN: 'Gharb-Chrarda-Beni Hssen',
	STATE_GUELMIM: 'Guelmim',
	STATE_KENITRA: 'Kenitra',
	STATE_MARRAKECH_TENSIFT_AL_HAOUZ: 'Marrakech-Tensift-Al Haouz',
	STATE_MEKNES_TAFILALET: 'Meknes-Tafilalet',
	STATE_ORIENTAL: 'Oriental',
	STATE_OUJDA: 'Oujda',
	STATE_PROVINCE_DE_TANGER: 'Province de Tanger',
	STATE_RABAT_SALE_ZAMMOUR_ZAER: 'Rabat-Sale-Zammour-Zaer',
	STATE_SALA_AL_JADIDA: 'Sala Al Jadida',
	STATE_SETTAT: 'Settat',
	STATE_SOUSS_MASSA_DRAA: 'Souss Massa-Draa',
	STATE_TADLA_AZILAL: 'Tadla-Azilal',
	STATE_TANGIER_TETOUAN: 'Tangier-Tetouan',
	STATE_TAZA_AL_HOCEIMA_TAOUNATE: 'Taza-Al Hoceima-Taounate',
	STATE_WILAYA_DE_CASABLANCA: 'Wilaya de Casablanca',
	STATE_WILAYA_DE_RABAT_SALE: 'Wilaya de Rabat-Sale',
	STATE_CABO_DELGADO: 'Cabo Delgado',
	STATE_INHAMBANE: 'Inhambane',
	STATE_MANICA: 'Manica',
	STATE_MAPUTO: 'Maputo',
	STATE_MAPUTO_PROVINCIA: 'Maputo Provincia',
	STATE_NAMPULA: 'Nampula',
	STATE_NIASSA: 'Niassa',
	STATE_SOFALA: 'Sofala',
	STATE_TETE: 'Tete',
	STATE_ZAMBEZIA: 'Zambezia',
	STATE_AYEYARWADY: 'Ayeyarwady',
	STATE_BAGO: 'Bago',
	STATE_CHIN: 'Chin',
	STATE_KACHIN: 'Kachin',
	STATE_KAYAH: 'Kayah',
	STATE_KAYIN: 'Kayin',
	STATE_MAGWAY: 'Magway',
	STATE_MANDALAY: 'Mandalay',
	STATE_MON: 'Mon',
	STATE_NAY_PYI_TAW: 'Nay Pyi Taw',
	STATE_RAKHINE: 'Rakhine',
	STATE_SAGAING: 'Sagaing',
	STATE_SHAN: 'Shan',
	STATE_TANINTHARYI: 'Tanintharyi',
	STATE_YANGON: 'Yangon',
	STATE_CAPRIVI: 'Caprivi',
	STATE_ERONGO: 'Erongo',
	STATE_HARDAP: 'Hardap',
	STATE_KARAS: 'Karas',
	STATE_KAVANGO: 'Kavango',
	STATE_KHOMAS: 'Khomas',
	STATE_KUNENE: 'Kunene',
	STATE_OHANGWENA: 'Ohangwena',
	STATE_OMAHEKE: 'Omaheke',
	STATE_OMUSATI: 'Omusati',
	STATE_OSHANA: 'Oshana',
	STATE_OSHIKOTO: 'Oshikoto',
	STATE_OTJOZONDJUPA: 'Otjozondjupa',
	STATE_YAREN: 'Yaren',
	STATE_BAGMATI: 'Bagmati',
	STATE_BHERI: 'Bheri',
	STATE_DHAWALAGIRI: 'Dhawalagiri',
	STATE_GANDAKI: 'Gandaki',
	STATE_JANAKPUR: 'Janakpur',
	STATE_KARNALI: 'Karnali',
	STATE_KOSHI: 'Koshi',
	STATE_LUMBINI: 'Lumbini',
	STATE_MAHAKALI: 'Mahakali',
	STATE_MECHI: 'Mechi',
	STATE_NARAYANI: 'Narayani',
	STATE_RAPTI: 'Rapti',
	STATE_SAGARMATHA: 'Sagarmatha',
	STATE_SETI: 'Seti',
	STATE_BONAIRE: 'Bonaire',
	STATE_CURACAO: 'Curacao',
	STATE_SABA: 'Saba',
	STATE_SINT_EUSTATIUS: 'Sint Eustatius',
	STATE_SINT_MAARTEN: 'Sint Maarten',
	STATE_BENELUX: 'Benelux',
	STATE_DRENTHE: 'Drenthe',
	STATE_FLEVOLAND: 'Flevoland',
	STATE_FRIESLAND: 'Friesland',
	STATE_GELDERLAND: 'Gelderland',
	STATE_GRONINGEN: 'Groningen',
	STATE_NOORD_BRABANT: 'Noord-Brabant',
	STATE_OVERIJSSEL: 'Overijssel',
	STATE_SOUTH_HOLLAND: 'South Holland',
	STATE_UTRECHT: 'Utrecht',
	STATE_ZEELAND: 'Zeeland',
	STATE_ZUID_HOLLAND: 'Zuid-Holland',
	STATE_ILES: 'Iles',
	STATE_AREA_OUTSIDE_REGION: 'Area Outside Region',
	STATE_BAY_OF_PLENTY: 'Bay of Plenty',
	STATE_CANTERBURY: 'Canterbury',
	STATE_CHRISTCHURCH: 'Christchurch',
	STATE_GISBORNE: 'Gisborne',
	STATE_HAWKE__S_BAY: "Hawke's Bay",
	STATE_MANAWATU_WANGANUI: 'Manawatu-Wanganui',
	STATE_MARLBOROUGH: 'Marlborough',
	STATE_NELSON: 'Nelson',
	STATE_NORTHLAND: 'Northland',
	STATE_OTAGO: 'Otago',
	STATE_RODNEY: 'Rodney',
	STATE_SOUTHLAND: 'Southland',
	STATE_TARANAKI: 'Taranaki',
	STATE_TASMAN: 'Tasman',
	STATE_WAIKATO: 'Waikato',
	STATE_WELLINGTON: 'Wellington',
	STATE_WEST_COAST: 'West Coast',
	STATE_ATLANTICO_NORTE: 'Atlantico Norte',
	STATE_ATLANTICO_SUR: 'Atlantico Sur',
	STATE_BOACO: 'Boaco',
	STATE_CARAZO: 'Carazo',
	STATE_CHINANDEGA: 'Chinandega',
	STATE_CHONTALES: 'Chontales',
	STATE_ESTELI: 'Esteli',
	STATE_GRANADA: 'Granada',
	STATE_JINOTEGA: 'Jinotega',
	STATE_LEON: 'Leon',
	STATE_MADRIZ: 'Madriz',
	STATE_MANAGUA: 'Managua',
	STATE_MASAYA: 'Masaya',
	STATE_MATAGALPA: 'Matagalpa',
	STATE_NUEVA_SEGOVIA: 'Nueva Segovia',
	STATE_RIO_SAN_JUAN: 'Rio San Juan',
	STATE_RIVAS: 'Rivas',
	STATE_AGADEZ: 'Agadez',
	STATE_DIFFA: 'Diffa',
	STATE_DOSSO: 'Dosso',
	STATE_MARADI: 'Maradi',
	STATE_NIAMEY: 'Niamey',
	STATE_TAHOUA: 'Tahoua',
	STATE_TILLABERY: 'Tillabery',
	STATE_ZINDER: 'Zinder',
	STATE_ABIA: 'Abia',
	STATE_ABUJA_FEDERAL_CAPITAL_TERRITOR: 'Abuja Federal Capital Territor',
	STATE_ADAMAWA: 'Adamawa',
	STATE_AKWA_IBOM: 'Akwa Ibom',
	STATE_ANAMBRA: 'Anambra',
	STATE_BAUCHI: 'Bauchi',
	STATE_BAYELSA: 'Bayelsa',
	STATE_BENUE: 'Benue',
	STATE_BORNO: 'Borno',
	STATE_CROSS_RIVER: 'Cross River',
	STATE_DELTA: 'Delta',
	STATE_EBONYI: 'Ebonyi',
	STATE_EDO: 'Edo',
	STATE_EKITI: 'Ekiti',
	STATE_ENUGU: 'Enugu',
	STATE_GOMBE: 'Gombe',
	STATE_IMO: 'Imo',
	STATE_JIGAWA: 'Jigawa',
	STATE_KADUNA: 'Kaduna',
	STATE_KANO: 'Kano',
	STATE_KATSINA: 'Katsina',
	STATE_KEBBI: 'Kebbi',
	STATE_KOGI: 'Kogi',
	STATE_KWARA: 'Kwara',
	STATE_LAGOS: 'Lagos',
	STATE_NASSARAWA: 'Nassarawa',
	STATE_NIGER: 'Niger',
	STATE_OGUN: 'Ogun',
	STATE_ONDO: 'Ondo',
	STATE_OSUN: 'Osun',
	STATE_OYO: 'Oyo',
	STATE_RIVERS: 'Rivers',
	STATE_SOKOTO: 'Sokoto',
	STATE_TARABA: 'Taraba',
	STATE_YOBE: 'Yobe',
	STATE_ZAMFARA: 'Zamfara',
	STATE_NIUE: 'Niue',
	STATE_NORFOLK_ISLAND: 'Norfolk Island',
	STATE_NORTHERN_ISLANDS: 'Northern Islands',
	STATE_ROTA: 'Rota',
	STATE_SAIPAN: 'Saipan',
	STATE_TINIAN: 'Tinian',
	STATE_AKERSHUS: 'Akershus',
	STATE_AUST_AGDER: 'Aust Agder',
	STATE_BERGEN: 'Bergen',
	STATE_BUSKERUD: 'Buskerud',
	STATE_FINNMARK: 'Finnmark',
	STATE_HEDMARK: 'Hedmark',
	STATE_HORDALAND: 'Hordaland',
	STATE_MOERE_OG_ROMSDAL: 'Moere og Romsdal',
	STATE_NORD_TRONDELAG: 'Nord Trondelag',
	STATE_NORDLAND: 'Nordland',
	STATE_OESTFOLD: 'Oestfold',
	STATE_OPPLAND: 'Oppland',
	STATE_OSLO: 'Oslo',
	STATE_ROGALAND: 'Rogaland',
	STATE_SOER_TROENDELAG: 'Soer Troendelag',
	STATE_SOGN_OG_FJORDANE: 'Sogn og Fjordane',
	STATE_STAVERN: 'Stavern',
	STATE_SYKKYLVEN: 'Sykkylven',
	STATE_TELEMARK: 'Telemark',
	STATE_TROMS: 'Troms',
	STATE_VEST_AGDER: 'Vest Agder',
	STATE_VESTFOLD: 'Vestfold',
	STATE_AL_BURAIMI: 'Al Buraimi',
	STATE_DHUFAR: 'Dhufar',
	STATE_MASQAT: 'Masqat',
	STATE_MUSANDAM: 'Musandam',
	STATE_RUSAYL: 'Rusayl',
	STATE_WADI_KABIR: 'Wadi Kabir',
	STATE_AD_DAKHILIYAH: 'ad-Dakhiliyah',
	STATE_ADH_DHAHIRAH: 'adh-Dhahirah',
	STATE_AL_BATINAH: 'al-Batinah',
	STATE_BALUCHISTAN: 'Baluchistan',
	STATE_FEDERAL_CAPITAL_AREA: 'Federal Capital Area',
	STATE_FEDERALLY_ADMINISTERED_TRIBAL: 'Federally administered Tribal',
	STATE_NORTH_WEST_FRONTIER: 'North-West Frontier',
	STATE_NORTHERN_AREAS: 'Northern Areas',
	STATE_SIND: 'Sind',
	STATE_AIMELIIK: 'Aimeliik',
	STATE_AIRAI: 'Airai',
	STATE_ANGAUR: 'Angaur',
	STATE_HATOBOHEI: 'Hatobohei',
	STATE_KAYANGEL: 'Kayangel',
	STATE_KOROR: 'Koror',
	STATE_MELEKEOK: 'Melekeok',
	STATE_NGARAARD: 'Ngaraard',
	STATE_NGARDMAU: 'Ngardmau',
	STATE_NGAREMLENGUI: 'Ngaremlengui',
	STATE_NGATPANG: 'Ngatpang',
	STATE_NGCHESAR: 'Ngchesar',
	STATE_NGERCHELONG: 'Ngerchelong',
	STATE_NGIWAL: 'Ngiwal',
	STATE_PELELIU: 'Peleliu',
	STATE_SONSOROL: 'Sonsorol',
	STATE_ARIHA: 'Ariha',
	STATE_BAYT_LAHM: 'Bayt Lahm',
	STATE_DAYR_AL_BALAH: 'Dayr-al-Balah',
	STATE_GHAZZAH: 'Ghazzah',
	STATE_GHAZZAH_ASH_SHAMALIYAH: 'Ghazzah ash-Shamaliyah',
	STATE_JANIN: 'Janin',
	STATE_KHAN_YUNIS: 'Khan Yunis',
	STATE_NABULUS: 'Nabulus',
	STATE_QALQILYAH: 'Qalqilyah',
	STATE_RAFAH: 'Rafah',
	STATE_RAM_ALLAH_WAL_BIRAH: 'Ram Allah wal-Birah',
	STATE_SALFIT: 'Salfit',
	STATE_TUBAS: 'Tubas',
	STATE_TULKARM: 'Tulkarm',
	STATE_AL_KHALIL: 'al-Khalil',
	STATE_AL_QUDS: 'al-Quds',
	STATE_BOCAS_DEL_TORO: 'Bocas del Toro',
	STATE_CHIRIQUI: 'Chiriqui',
	STATE_COCLE: 'Cocle',
	STATE_DARIEN: 'Darien',
	STATE_EMBERA: 'Embera',
	STATE_HERRERA: 'Herrera',
	STATE_KUNA_YALA: 'Kuna Yala',
	STATE_LOS_SANTOS: 'Los Santos',
	STATE_NGOBE_BUGLE: 'Ngobe Bugle',
	STATE_PANAMA: 'Panama',
	STATE_VERAGUAS: 'Veraguas',
	STATE_EAST_NEW_BRITAIN: 'East New Britain',
	STATE_EAST_SEPIK: 'East Sepik',
	STATE_EASTERN_HIGHLANDS: 'Eastern Highlands',
	STATE_ENGA: 'Enga',
	STATE_FLY_RIVER: 'Fly River',
	STATE_GULF: 'Gulf',
	STATE_MADANG: 'Madang',
	STATE_MANUS: 'Manus',
	STATE_MILNE_BAY: 'Milne Bay',
	STATE_MOROBE: 'Morobe',
	STATE_NATIONAL_CAPITAL_DISTRICT: 'National Capital District',
	STATE_NEW_IRELAND: 'New Ireland',
	STATE_NORTH_SOLOMONS: 'North Solomons',
	STATE_ORO: 'Oro',
	STATE_SANDAUN: 'Sandaun',
	STATE_SIMBU: 'Simbu',
	STATE_SOUTHERN_HIGHLANDS: 'Southern Highlands',
	STATE_WEST_NEW_BRITAIN: 'West New Britain',
	STATE_WESTERN_HIGHLANDS: 'Western Highlands',
	STATE_ALTO_PARAGUAY: 'Alto Paraguay',
	STATE_ALTO_PARANA: 'Alto Parana',
	STATE_AMAMBAY: 'Amambay',
	STATE_ASUNCION: 'Asuncion',
	STATE_BOQUERON: 'Boqueron',
	STATE_CAAGUAZU: 'Caaguazu',
	STATE_CAAZAPA: 'Caazapa',
	STATE_CANENDIYU: 'Canendiyu',
	STATE_CONCEPCION: 'Concepcion',
	STATE_CORDILLERA: 'Cordillera',
	STATE_GUAIRA: 'Guaira',
	STATE_ITAPUA: 'Itapua',
	STATE_NEEMBUCU: 'Neembucu',
	STATE_PARAGUARI: 'Paraguari',
	STATE_PRESIDENTE_HAYES: 'Presidente Hayes',
	STATE_SAN_PEDRO: 'San Pedro',
	STATE_ANCASH: 'Ancash',
	STATE_APURIMAC: 'Apurimac',
	STATE_AREQUIPA: 'Arequipa',
	STATE_AYACUCHO: 'Ayacucho',
	STATE_CAJAMARCA: 'Cajamarca',
	STATE_CUSCO: 'Cusco',
	STATE_HUANCAVELICA: 'Huancavelica',
	STATE_HUANUCO: 'Huanuco',
	STATE_ICA: 'Ica',
	STATE_JUNIN: 'Junin',
	STATE_LAMBAYEQUE: 'Lambayeque',
	STATE_LIMA_Y_CALLAO: 'Lima y Callao',
	STATE_LORETO: 'Loreto',
	STATE_MADRE_DE_DIOS: 'Madre de Dios',
	STATE_MOQUEGUA: 'Moquegua',
	STATE_PASCO: 'Pasco',
	STATE_PIURA: 'Piura',
	STATE_PUNO: 'Puno',
	STATE_SAN_MARTIN: 'San Martin',
	STATE_TACNA: 'Tacna',
	STATE_TUMBES: 'Tumbes',
	STATE_UCAYALI: 'Ucayali',
	STATE_BATANGAS: 'Batangas',
	STATE_BICOL: 'Bicol',
	STATE_BULACAN: 'Bulacan',
	STATE_CAGAYAN: 'Cagayan',
	STATE_CARAGA: 'Caraga',
	STATE_CENTRAL_LUZON: 'Central Luzon',
	STATE_CENTRAL_MINDANAO: 'Central Mindanao',
	STATE_CENTRAL_VISAYAS: 'Central Visayas',
	STATE_DAVAO: 'Davao',
	STATE_EASTERN_VISAYAS: 'Eastern Visayas',
	STATE_GREATER_METROPOLITAN_AREA: 'Greater Metropolitan Area',
	STATE_ILOCOS: 'Ilocos',
	STATE_LAGUNA: 'Laguna',
	STATE_LUZON: 'Luzon',
	STATE_MACTAN: 'Mactan',
	STATE_METROPOLITAN_MANILA_AREA: 'Metropolitan Manila Area',
	STATE_MUSLIM_MINDANAO: 'Muslim Mindanao',
	STATE_NORTHERN_MINDANAO: 'Northern Mindanao',
	STATE_SOUTHERN_MINDANAO: 'Southern Mindanao',
	STATE_SOUTHERN_TAGALOG: 'Southern Tagalog',
	STATE_WESTERN_MINDANAO: 'Western Mindanao',
	STATE_WESTERN_VISAYAS: 'Western Visayas',
	STATE_PITCAIRN_ISLAND: 'Pitcairn Island',
	STATE_BIALE_BLOTA: 'Biale Blota',
	STATE_DOBROSZYCE: 'Dobroszyce',
	STATE_DOLNOSLASKIE: 'Dolnoslaskie',
	STATE_DZIEKANOW_LESNY: 'Dziekanow Lesny',
	STATE_HOPOWO: 'Hopowo',
	STATE_KARTUZY: 'Kartuzy',
	STATE_KOSCIAN: 'Koscian',
	STATE_KRAKOW: 'Krakow',
	STATE_KUJAWSKO_POMORSKIE: 'Kujawsko-Pomorskie',
	STATE_LODZKIE: 'Lodzkie',
	STATE_LUBELSKIE: 'Lubelskie',
	STATE_LUBUSKIE: 'Lubuskie',
	STATE_MALOMICE: 'Malomice',
	STATE_MALOPOLSKIE: 'Malopolskie',
	STATE_MAZOWIECKIE: 'Mazowieckie',
	STATE_MIRKOW: 'Mirkow',
	STATE_OPOLSKIE: 'Opolskie',
	STATE_OSTROWIEC: 'Ostrowiec',
	STATE_PODKARPACKIE: 'Podkarpackie',
	STATE_PODLASKIE: 'Podlaskie',
	STATE_POLSKA: 'Polska',
	STATE_POMORSKIE: 'Pomorskie',
	STATE_POZNAN: 'Poznan',
	STATE_PRUSZKOW: 'Pruszkow',
	STATE_RYMANOWSKA: 'Rymanowska',
	STATE_RZESZOW: 'Rzeszow',
	STATE_SLASKIE: 'Slaskie',
	STATE_STARE_POLE: 'Stare Pole',
	STATE_SWIETOKRZYSKIE: 'Swietokrzyskie',
	STATE_WARMINSKO_MAZURSKIE: 'Warminsko-Mazurskie',
	STATE_WARSAW: 'Warsaw',
	STATE_WEJHEROWO: 'Wejherowo',
	STATE_WIELKOPOLSKIE: 'Wielkopolskie',
	STATE_WROCLAW: 'Wroclaw',
	STATE_ZACHODNIO_POMORSKIE: 'Zachodnio-Pomorskie',
	STATE_ZUKOWO: 'Zukowo',
	STATE_ABRANTES: 'Abrantes',
	STATE_ACORES: 'Acores',
	STATE_ALENTEJO: 'Alentejo',
	STATE_ALGARVE: 'Algarve',
	STATE_BRAGA: 'Braga',
	STATE_CENTRO: 'Centro',
	STATE_DISTRITO_DE_LEIRIA: 'Distrito de Leiria',
	STATE_DISTRITO_DE_VIANA_DO_CASTELO: 'Distrito de Viana do Castelo',
	STATE_DISTRITO_DE_VILA_REAL: 'Distrito de Vila Real',
	STATE_DISTRITO_DO_PORTO: 'Distrito do Porto',
	STATE_LISBOA_E_VALE_DO_TEJO: 'Lisboa e Vale do Tejo',
	STATE_MADEIRA: 'Madeira',
	STATE_NORTE: 'Norte',
	STATE_PAIVAS: 'Paivas',
	STATE_ARECIBO: 'Arecibo',
	STATE_BAYAMON: 'Bayamon',
	STATE_CAROLINA: 'Carolina',
	STATE_FLORIDA: 'Florida',
	STATE_GUAYAMA: 'Guayama',
	STATE_HUMACAO: 'Humacao',
	STATE_MAYAGUEZ_AGUADILLA: 'Mayaguez-Aguadilla',
	STATE_PONCE: 'Ponce',
	STATE_SALINAS: 'Salinas',
	STATE_DOHA: 'Doha',
	STATE_JARIAN_AL_BATNAH: 'Jarian-al-Batnah',
	STATE_UMM_SALAL: 'Umm Salal',
	STATE_AD_DAWHAH: 'ad-Dawhah',
	STATE_AL_GHUWAYRIYAH: 'al-Ghuwayriyah',
	STATE_AL_JUMAYLIYAH: 'al-Jumayliyah',
	STATE_AL_KHAWR: 'al-Khawr',
	STATE_AL_WAKRAH: 'al-Wakrah',
	STATE_AR_RAYYAN: 'ar-Rayyan',
	STATE_SAINT_BENOIT: 'Saint-Benoit',
	STATE_SAINT_DENIS: 'Saint-Denis',
	STATE_ALBA: 'Alba',
	STATE_ARAD: 'Arad',
	STATE_ARGES: 'Arges',
	STATE_BACAU: 'Bacau',
	STATE_BIHOR: 'Bihor',
	STATE_BISTRITA_NASAUD: 'Bistrita-Nasaud',
	STATE_BOTOSANI: 'Botosani',
	STATE_BRAILA: 'Braila',
	STATE_BRASOV: 'Brasov',
	STATE_BUCURESTI: 'Bucuresti',
	STATE_BUZAU: 'Buzau',
	STATE_CALARASI: 'Calarasi',
	STATE_CARAS_SEVERIN: 'Caras-Severin',
	STATE_CLUJ: 'Cluj',
	STATE_CONSTANTA: 'Constanta',
	STATE_COVASNA: 'Covasna',
	STATE_DAMBOVITA: 'Dambovita',
	STATE_DOLJ: 'Dolj',
	STATE_GALATI: 'Galati',
	STATE_GIURGIU: 'Giurgiu',
	STATE_GORJ: 'Gorj',
	STATE_HARGHITA: 'Harghita',
	STATE_HUNEDOARA: 'Hunedoara',
	STATE_IALOMITA: 'Ialomita',
	STATE_IASI: 'Iasi',
	STATE_ILFOV: 'Ilfov',
	STATE_MARAMURES: 'Maramures',
	STATE_MEHEDINTI: 'Mehedinti',
	STATE_MURES: 'Mures',
	STATE_NEAMT: 'Neamt',
	STATE_OLT: 'Olt',
	STATE_PRAHOVA: 'Prahova',
	STATE_SALAJ: 'Salaj',
	STATE_SATU_MARE: 'Satu Mare',
	STATE_SIBIU: 'Sibiu',
	STATE_SONDELOR: 'Sondelor',
	STATE_SUCEAVA: 'Suceava',
	STATE_TELEORMAN: 'Teleorman',
	STATE_TIMIS: 'Timis',
	STATE_TULCEA: 'Tulcea',
	STATE_VALCEA: 'Valcea',
	STATE_VASLUI: 'Vaslui',
	STATE_VRANCEA: 'Vrancea',
	STATE_ADYGEJA: 'Adygeja',
	STATE_AGA: 'Aga',
	STATE_ALANIJA: 'Alanija',
	STATE_ALTAJ: 'Altaj',
	STATE_AMUR: 'Amur',
	STATE_ARHANGELSK: 'Arhangelsk',
	STATE_ASTRAHAN: 'Astrahan',
	STATE_BASHKORTOSTAN: 'Bashkortostan',
	STATE_BELGOROD: 'Belgorod',
	STATE_BRJANSK: 'Brjansk',
	STATE_BURJATIJA: 'Burjatija',
	STATE_CHECHENIJA: 'Chechenija',
	STATE_CHELJABINSK: 'Cheljabinsk',
	STATE_CHITA: 'Chita',
	STATE_CHUKOTKA: 'Chukotka',
	STATE_CHUVASHIJA: 'Chuvashija',
	STATE_DAGESTAN: 'Dagestan',
	STATE_EVENKIJA: 'Evenkija',
	STATE_GORNO_ALTAJ: 'Gorno-Altaj',
	STATE_HABAROVSK: 'Habarovsk',
	STATE_HAKASIJA: 'Hakasija',
	STATE_HANTY_MANSIJA: 'Hanty-Mansija',
	STATE_INGUSETIJA: 'Ingusetija',
	STATE_IRKUTSK: 'Irkutsk',
	STATE_IVANOVO: 'Ivanovo',
	STATE_JAMALO_NENETS: 'Jamalo-Nenets',
	STATE_JAROSLAVL: 'Jaroslavl',
	STATE_JEVREJ: 'Jevrej',
	STATE_KABARDINO_BALKARIJA: 'Kabardino-Balkarija',
	STATE_KALININGRAD: 'Kaliningrad',
	STATE_KALMYKIJA: 'Kalmykija',
	STATE_KALUGA: 'Kaluga',
	STATE_KAMCHATKA: 'Kamchatka',
	STATE_KARACHAJ_CHERKESSIJA: 'Karachaj-Cherkessija',
	STATE_KARELIJA: 'Karelija',
	STATE_KEMEROVO: 'Kemerovo',
	STATE_KHABAROVSKIY_KRAY: 'Khabarovskiy Kray',
	STATE_KIROV: 'Kirov',
	STATE_KOMI: 'Komi',
	STATE_KOMI_PERMJAKIJA: 'Komi-Permjakija',
	STATE_KORJAKIJA: 'Korjakija',
	STATE_KOSTROMA: 'Kostroma',
	STATE_KRASNODAR: 'Krasnodar',
	STATE_KRASNOJARSK: 'Krasnojarsk',
	STATE_KRASNOYARSKIY_KRAY: 'Krasnoyarskiy Kray',
	STATE_KURGAN: 'Kurgan',
	STATE_KURSK: 'Kursk',
	STATE_LENINGRAD: 'Leningrad',
	STATE_LIPECK: 'Lipeck',
	STATE_MAGADAN: 'Magadan',
	STATE_MARIJ_EL: 'Marij El',
	STATE_MORDOVIJA: 'Mordovija',
	STATE_MOSCOW: 'Moscow',
	STATE_MOSKOVSKAJA_OBLAST: 'Moskovskaja Oblast',
	STATE_MOSKOVSKAYA_OBLAST: 'Moskovskaya Oblast',
	STATE_MOSKVA: 'Moskva',
	STATE_MURMANSK: 'Murmansk',
	STATE_NENETS: 'Nenets',
	STATE_NIZHNIJ_NOVGOROD: 'Nizhnij Novgorod',
	STATE_NOVGOROD: 'Novgorod',
	STATE_NOVOKUSNEZK: 'Novokusnezk',
	STATE_NOVOSIBIRSK: 'Novosibirsk',
	STATE_OMSK: 'Omsk',
	STATE_ORENBURG: 'Orenburg',
	STATE_ORJOL: 'Orjol',
	STATE_PENZA: 'Penza',
	STATE_PERM: 'Perm',
	STATE_PRIMORJE: 'Primorje',
	STATE_PSKOV: 'Pskov',
	STATE_PSKOVSKAYA_OBLAST: 'Pskovskaya Oblast',
	STATE_RJAZAN: 'Rjazan',
	STATE_ROSTOV: 'Rostov',
	STATE_SAHA: 'Saha',
	STATE_SAHALIN: 'Sahalin',
	STATE_SAMARA: 'Samara',
	STATE_SAMARSKAYA: 'Samarskaya',
	STATE_SANKT_PETERBURG: 'Sankt-Peterburg',
	STATE_SARATOV: 'Saratov',
	STATE_SMOLENSK: 'Smolensk',
	STATE_STAVROPOL: 'Stavropol',
	STATE_SVERDLOVSK: 'Sverdlovsk',
	STATE_TAJMYRIJA: 'Tajmyrija',
	STATE_TAMBOV: 'Tambov',
	STATE_TATARSTAN: 'Tatarstan',
	STATE_TJUMEN: 'Tjumen',
	STATE_TOMSK: 'Tomsk',
	STATE_TULA: 'Tula',
	STATE_TVER: 'Tver',
	STATE_TYVA: 'Tyva',
	STATE_UDMURTIJA: 'Udmurtija',
	STATE_ULJANOVSK: 'Uljanovsk',
	STATE_ULYANOVSKAYA_OBLAST: 'Ulyanovskaya Oblast',
	STATE_UST_ORDA: 'Ust-Orda',
	STATE_VLADIMIR: 'Vladimir',
	STATE_VOLGOGRAD: 'Volgograd',
	STATE_VOLOGDA: 'Vologda',
	STATE_VORONEZH: 'Voronezh',
	STATE_BUTARE: 'Butare',
	STATE_BYUMBA: 'Byumba',
	STATE_CYANGUGU: 'Cyangugu',
	STATE_GIKONGORO: 'Gikongoro',
	STATE_GISENYI: 'Gisenyi',
	STATE_GITARAMA: 'Gitarama',
	STATE_KIBUNGO: 'Kibungo',
	STATE_KIBUYE: 'Kibuye',
	STATE_KIGALI_NGALI: 'Kigali-ngali',
	STATE_RUHENGERI: 'Ruhengeri',
	STATE_ASCENSION: 'Ascension',
	STATE_GOUGH_ISLAND: 'Gough Island',
	STATE_SAINT_HELENA: 'Saint Helena',
	STATE_TRISTAN_DA_CUNHA: 'Tristan da Cunha',
	STATE_CHRIST_CHURCH_NICHOLA_TOWN: 'Christ Church Nichola Town',
	STATE_SAINT_ANNE_SANDY_POINT: 'Saint Anne Sandy Point',
	STATE_SAINT_GEORGE_BASSETERRE: 'Saint George Basseterre',
	STATE_SAINT_GEORGE_GINGERLAND: 'Saint George Gingerland',
	STATE_SAINT_JAMES_WINDWARD: 'Saint James Windward',
	STATE_SAINT_JOHN_CAPESTERRE: 'Saint John Capesterre',
	STATE_SAINT_JOHN_FIGTREE: 'Saint John Figtree',
	STATE_SAINT_MARY_CAYON: 'Saint Mary Cayon',
	STATE_SAINT_PAUL_CAPESTERRE: 'Saint Paul Capesterre',
	STATE_SAINT_PAUL_CHARLESTOWN: 'Saint Paul Charlestown',
	STATE_SAINT_PETER_BASSETERRE: 'Saint Peter Basseterre',
	STATE_SAINT_THOMAS_LOWLAND: 'Saint Thomas Lowland',
	STATE_SAINT_THOMAS_MIDDLE_ISLAND: 'Saint Thomas Middle Island',
	STATE_TRINITY_PALMETTO_POINT: 'Trinity Palmetto Point',
	STATE_ANSE_LA_RAYE: 'Anse-la-Raye',
	STATE_CANARIES: 'Canaries',
	STATE_CASTRIES: 'Castries',
	STATE_CHOISEUL: 'Choiseul',
	STATE_DENNERY: 'Dennery',
	STATE_GROS_INLET: 'Gros Inlet',
	STATE_LABORIE: 'Laborie',
	STATE_MICOUD: 'Micoud',
	STATE_SOUFRIERE: 'Soufriere',
	STATE_VIEUX_FORT: 'Vieux Fort',
	STATE_MIQUELON_LANGLADE: 'Miquelon-Langlade',
	STATE_CHARLOTTE: 'Charlotte',
	STATE_GRENADINES: 'Grenadines',
	STATE_A__ANA: "A'ana",
	STATE_AIGA_I_LE_TAI: 'Aiga-i-le-Tai',
	STATE_ATUA: 'Atua',
	STATE_FA__ASALELEAGA: "Fa'asaleleaga",
	STATE_GAGA__EMAUGA: "Gaga'emauga",
	STATE_GAGAIFOMAUGA: 'Gagaifomauga',
	STATE_PALAULI: 'Palauli',
	STATE_SATUPA__ITEA: "Satupa'itea",
	STATE_TUAMASAGA: 'Tuamasaga',
	STATE_VA__A_O_FONOTI: "Va'a-o-Fonoti",
	STATE_VAISIGANO: 'Vaisigano',
	STATE_ACQUAVIVA: 'Acquaviva',
	STATE_BORGO_MAGGIORE: 'Borgo Maggiore',
	STATE_CHIESANUOVA: 'Chiesanuova',
	STATE_DOMAGNANO: 'Domagnano',
	STATE_FAETANO: 'Faetano',
	STATE_FIORENTINO: 'Fiorentino',
	STATE_MONTEGIARDINO: 'Montegiardino',
	STATE_SAN_MARINO: 'San Marino',
	STATE_SERRAVALLE: 'Serravalle',
	STATE_AGUA_GRANDE: 'Agua Grande',
	STATE_CANTAGALO: 'Cantagalo',
	STATE_LEMBA: 'Lemba',
	STATE_LOBATA: 'Lobata',
	STATE_ME_ZOCHI: 'Me-Zochi',
	STATE_PAGUE: 'Pague',
	STATE_AL_KHOBAR: 'Al Khobar',
	STATE_ASEER: 'Aseer',
	STATE_ASIR: 'Asir',
	STATE_CENTRAL_PROVINCE: 'Central Province',
	STATE_EASTERN_PROVINCE: 'Eastern Province',
	STATE_HA__IL: "Ha'il",
	STATE_JAWF: 'Jawf',
	STATE_JIZAN: 'Jizan',
	STATE_MAKKAH: 'Makkah',
	STATE_NAJRAN: 'Najran',
	STATE_QASIM: 'Qasim',
	STATE_TABUK: 'Tabuk',
	STATE_WESTERN_PROVINCE: 'Western Province',
	STATE_AL_BAHAH: 'al-Bahah',
	STATE_AL_HUDUD_ASH_SHAMALIYAH: 'al-Hudud-ash-Shamaliyah',
	STATE_AL_MADINAH: 'al-Madinah',
	STATE_AR_RIYAD: 'ar-Riyad',
	STATE_DAKAR: 'Dakar',
	STATE_DIOURBEL: 'Diourbel',
	STATE_FATICK: 'Fatick',
	STATE_KAOLACK: 'Kaolack',
	STATE_KOLDA: 'Kolda',
	STATE_LOUGA: 'Louga',
	STATE_SAINT_LOUIS: 'Saint-Louis',
	STATE_TAMBACOUNDA: 'Tambacounda',
	STATE_THIES: 'Thies',
	STATE_ZIGUINCHOR: 'Ziguinchor',
	STATE_CENTRAL_SERBIA: 'Central Serbia',
	STATE_KOSOVO_AND_METOHIJA: 'Kosovo and Metohija',
	STATE_VOJVODINA: 'Vojvodina',
	STATE_ANSE_BOILEAU: 'Anse Boileau',
	STATE_ANSE_ROYALE: 'Anse Royale',
	STATE_CASCADE: 'Cascade',
	STATE_TAKAMAKA: 'Takamaka',
	STATE_SINGAPORE: 'Singapore',
	STATE_BANSKOBYSTRICKY: 'Banskobystricky',
	STATE_BRATISLAVSKY: 'Bratislavsky',
	STATE_KOSICKY: 'Kosicky',
	STATE_NITRIANSKY: 'Nitriansky',
	STATE_PRESOVSKY: 'Presovsky',
	STATE_TRENCIANSKY: 'Trenciansky',
	STATE_TRNAVSKY: 'Trnavsky',
	STATE_ZILINSKY: 'Zilinsky',
	STATE_BENEDIKT: 'Benedikt',
	STATE_GORENJSKA: 'Gorenjska',
	STATE_GORISHKA: 'Gorishka',
	STATE_JUGOVZHODNA_SLOVENIJA: 'Jugovzhodna Slovenija',
	STATE_KOROSHKA: 'Koroshka',
	STATE_NOTRANJSKO_KRASHKA: 'Notranjsko-krashka',
	STATE_OBALNO_KRASHKA: 'Obalno-krashka',
	STATE_OBCINA_DOMZALE: 'Obcina Domzale',
	STATE_OBCINA_VITANJE: 'Obcina Vitanje',
	STATE_OSREDNJESLOVENSKA: 'Osrednjeslovenska',
	STATE_PODRAVSKA: 'Podravska',
	STATE_POMURSKA: 'Pomurska',
	STATE_SAVINJSKA: 'Savinjska',
	STATE_SLOVENIAN_LITTORAL: 'Slovenian Littoral',
	STATE_SPODNJEPOSAVSKA: 'Spodnjeposavska',
	STATE_ZASAVSKA: 'Zasavska',
	STATE_GUADALCANAL: 'Guadalcanal',
	STATE_ISABEL: 'Isabel',
	STATE_MAKIRA_AND_ULAWA: 'Makira and Ulawa',
	STATE_MALAITA: 'Malaita',
	STATE_RENNELL_AND_BELLONA: 'Rennell and Bellona',
	STATE_TEMOTU: 'Temotu',
	STATE_AWDAL: 'Awdal',
	STATE_BAKOL: 'Bakol',
	STATE_BANADIR: 'Banadir',
	STATE_BAY: 'Bay',
	STATE_GALGUDUG: 'Galgudug',
	STATE_GEDO: 'Gedo',
	STATE_HIRAN: 'Hiran',
	STATE_JUBBADA_HOSE: 'Jubbada Hose',
	STATE_JUBBADHA_DEXE: 'Jubbadha Dexe',
	STATE_MUDUG: 'Mudug',
	STATE_NUGAL: 'Nugal',
	STATE_SANAG: 'Sanag',
	STATE_SHABELLAHA_DHEXE: 'Shabellaha Dhexe',
	STATE_SHABELLAHA_HOSE: 'Shabellaha Hose',
	STATE_TOGDHER: 'Togdher',
	STATE_WOQOYI_GALBED: 'Woqoyi Galbed',
	STATE_EASTERN_CAPE: 'Eastern Cape',
	STATE_FREE_STATE: 'Free State',
	STATE_GAUTENG: 'Gauteng',
	STATE_KEMPTON_PARK: 'Kempton Park',
	STATE_KRAMERVILLE: 'Kramerville',
	STATE_KWAZULU_NATAL: 'KwaZulu Natal',
	STATE_LIMPOPO: 'Limpopo',
	STATE_MPUMALANGA: 'Mpumalanga',
	STATE_NORTH_WEST: 'North West',
	STATE_NORTHERN_CAPE: 'Northern Cape',
	STATE_PAROW: 'Parow',
	STATE_TABLE_VIEW: 'Table View',
	STATE_UMTENTWENI: 'Umtentweni',
	STATE_WESTERN_CAPE: 'Western Cape',
	STATE_CENTRAL_EQUATORIA: 'Central Equatoria',
	STATE_A_CORUNA: 'A Coruna',
	STATE_ALACANT: 'Alacant',
	STATE_ALAVA: 'Alava',
	STATE_ALBACETE: 'Albacete',
	STATE_ALMERIA: 'Almeria',
	STATE_ANDALUCIA: 'Andalucia',
	STATE_ASTURIAS: 'Asturias',
	STATE_AVILA: 'Avila',
	STATE_BADAJOZ: 'Badajoz',
	STATE_BALEARS: 'Balears',
	STATE_BARCELONA: 'Barcelona',
	STATE_BERTAMIRANS: 'Bertamirans',
	STATE_BISCAY: 'Biscay',
	STATE_BURGOS: 'Burgos',
	STATE_CACERES: 'Caceres',
	STATE_CADIZ: 'Cadiz',
	STATE_CANTABRIA: 'Cantabria',
	STATE_CASTELLO: 'Castello',
	STATE_CATALUNYA: 'Catalunya',
	STATE_CEUTA: 'Ceuta',
	STATE_CIUDAD_REAL: 'Ciudad Real',
	STATE_COMUNIDAD_AUTONOMA_DE_CANARIAS: 'Comunidad Autonoma de Canarias',
	STATE_COMUNIDAD_AUTONOMA_DE_CATALUNA: 'Comunidad Autonoma de Cataluna',
	STATE_COMUNIDAD_AUTONOMA_DE_GALICIA: 'Comunidad Autonoma de Galicia',
	STATE_COMUNIDAD_AUTONOMA_DE_LAS_ISLA: 'Comunidad Autonoma de las Isla',
	STATE_COMUNIDAD_AUTONOMA_DEL_PRINCIP: 'Comunidad Autonoma del Princip',
	STATE_COMUNIDAD_VALENCIANA: 'Comunidad Valenciana',
	STATE_CUENCA: 'Cuenca',
	STATE_GIPUZKOA: 'Gipuzkoa',
	STATE_GIRONA: 'Girona',
	STATE_GUADALAJARA: 'Guadalajara',
	STATE_GUIPUZCOA: 'Guipuzcoa',
	STATE_HUELVA: 'Huelva',
	STATE_HUESCA: 'Huesca',
	STATE_JAEN: 'Jaen',
	STATE_LAS_PALMAS: 'Las Palmas',
	STATE_LERIDA: 'Lerida',
	STATE_LLEIDA: 'Lleida',
	STATE_LUGO: 'Lugo',
	STATE_MADRID: 'Madrid',
	STATE_MALAGA: 'Malaga',
	STATE_MELILLA: 'Melilla',
	STATE_MURCIA: 'Murcia',
	STATE_NAVARRA: 'Navarra',
	STATE_OURENSE: 'Ourense',
	STATE_PAIS_VASCO: 'Pais Vasco',
	STATE_PALENCIA: 'Palencia',
	STATE_PONTEVEDRA: 'Pontevedra',
	STATE_SALAMANCA: 'Salamanca',
	STATE_SANTA_CRUZ_DE_TENERIFE: 'Santa Cruz de Tenerife',
	STATE_SEGOVIA: 'Segovia',
	STATE_SEVILLA: 'Sevilla',
	STATE_SORIA: 'Soria',
	STATE_TARRAGONA: 'Tarragona',
	STATE_TENERIFE: 'Tenerife',
	STATE_TERUEL: 'Teruel',
	STATE_VALENCIA: 'Valencia',
	STATE_VALLADOLID: 'Valladolid',
	STATE_VIZCAYA: 'Vizcaya',
	STATE_ZAMORA: 'Zamora',
	STATE_ZARAGOZA: 'Zaragoza',
	STATE_AMPARAI: 'Amparai',
	STATE_ANURADHAPURAYA: 'Anuradhapuraya',
	STATE_BADULLA: 'Badulla',
	STATE_BORALESGAMUWA: 'Boralesgamuwa',
	STATE_COLOMBO: 'Colombo',
	STATE_GALLA: 'Galla',
	STATE_GAMPAHA: 'Gampaha',
	STATE_HAMBANTOTA: 'Hambantota',
	STATE_KALATURA: 'Kalatura',
	STATE_KEGALLA: 'Kegalla',
	STATE_KILINOCHCHI: 'Kilinochchi',
	STATE_KURUNEGALA: 'Kurunegala',
	STATE_MADAKALPUWA: 'Madakalpuwa',
	STATE_MAHA_NUWARA: 'Maha Nuwara',
	STATE_MALWANA: 'Malwana',
	STATE_MANNARAMA: 'Mannarama',
	STATE_MATALE: 'Matale',
	STATE_MATARA: 'Matara',
	STATE_MONARAGALA: 'Monaragala',
	STATE_MULLAITIVU: 'Mullaitivu',
	STATE_NORTH_EASTERN_PROVINCE: 'North Eastern Province',
	STATE_NORTH_WESTERN_PROVINCE: 'North Western Province',
	STATE_NUWARA_ELIYA: 'Nuwara Eliya',
	STATE_POLONNARUWA: 'Polonnaruwa',
	STATE_PUTTALAMA: 'Puttalama',
	STATE_RATNAPURAYA: 'Ratnapuraya',
	STATE_SOUTHERN_PROVINCE: 'Southern Province',
	STATE_TIRIKUNAMALAYA: 'Tirikunamalaya',
	STATE_TUSCANY: 'Tuscany',
	STATE_VAVUNIYAWA: 'Vavuniyawa',
	STATE_YAPANAYA: 'Yapanaya',
	STATE_KADAWATHA: 'kadawatha',
	STATE_A__ALI_AN_NIL: "A'ali-an-Nil",
	STATE_BAHR_AL_JABAL: 'Bahr-al-Jabal',
	STATE_GHARB_BAHR_AL_GHAZAL: 'Gharb Bahr-al-Ghazal',
	STATE_GHARB_DARFUR: 'Gharb Darfur',
	STATE_GHARB_KURDUFAN: 'Gharb Kurdufan',
	STATE_GHARB_AL_ISTIWA__IYAH: "Gharb-al-Istiwa'iyah",
	STATE_JANUB_DARFUR: 'Janub Darfur',
	STATE_JANUB_KURDUFAN: 'Janub Kurdufan',
	STATE_JUNQALI: 'Junqali',
	STATE_KASSALA: 'Kassala',
	STATE_NAHR_AN_NIL: 'Nahr-an-Nil',
	STATE_SHAMAL_BAHR_AL_GHAZAL: 'Shamal Bahr-al-Ghazal',
	STATE_SHAMAL_DARFUR: 'Shamal Darfur',
	STATE_SHAMAL_KURDUFAN: 'Shamal Kurdufan',
	STATE_SHARQ_AL_ISTIWA__IYAH: "Sharq-al-Istiwa'iyah",
	STATE_SINNAR: 'Sinnar',
	STATE_WARAB: 'Warab',
  STATE_WILAYAT_AL_KHARTUM: 'Wilayat al Khartum',
	STATE_AL_BUHAYRAT: 'al-Buhayrat',
	STATE_AL_JAZIRAH: 'al-Jazirah',
	STATE_AL_KHARTUM: 'al-Khartum',
	STATE_AL_QADARIF: 'al-Qadarif',
	STATE_AL_WAHDAH: 'al-Wahdah',
	STATE_AN_NIL_AL_ABYAD: 'an-Nil-al-Abyad',
	STATE_AN_NIL_AL_AZRAQ: 'an-Nil-al-Azraq',
	STATE_ASH_SHAMALIYAH: 'ash-Shamaliyah',
	STATE_BROKOPONDO: 'Brokopondo',
	STATE_COMMEWIJNE: 'Commewijne',
	STATE_CORONIE: 'Coronie',
	STATE_MAROWIJNE: 'Marowijne',
	STATE_NICKERIE: 'Nickerie',
	STATE_PARAMARIBO: 'Paramaribo',
	STATE_SARAMACCA: 'Saramacca',
	STATE_WANICA: 'Wanica',
	STATE_SVALBARD: 'Svalbard',
	STATE_HHOHHO: 'Hhohho',
	STATE_LUBOMBO: 'Lubombo',
	STATE_MANZINI: 'Manzini',
	STATE_SHISELWENI: 'Shiselweni',
	STATE_ALVSBORGS_LAN: 'Alvsborgs Lan',
	STATE_ANGERMANLAND: 'Angermanland',
	STATE_BLEKINGE: 'Blekinge',
	STATE_BOHUSLAN: 'Bohuslan',
	STATE_DALARNA: 'Dalarna',
	STATE_GAVLEBORG: 'Gavleborg',
	STATE_GOTLAND: 'Gotland',
	STATE_HALLAND: 'Halland',
	STATE_JAMTLAND: 'Jamtland',
	STATE_JONKOPING: 'Jonkoping',
	STATE_KALMAR: 'Kalmar',
	STATE_KRISTIANSTADS: 'Kristianstads',
	STATE_KRONOBERG: 'Kronoberg',
	STATE_NORRBOTTEN: 'Norrbotten',
	STATE_OREBRO: 'Orebro',
	STATE_OSTERGOTLAND: 'Ostergotland',
	STATE_SALTSJO_BOO: 'Saltsjo-Boo',
	STATE_SKANE: 'Skane',
	STATE_SMALAND: 'Smaland',
	STATE_SODERMANLAND: 'Sodermanland',
	STATE_STOCKHOLM: 'Stockholm',
	STATE_UPPSALA: 'Uppsala',
	STATE_VARMLAND: 'Varmland',
	STATE_VASTERBOTTEN: 'Vasterbotten',
	STATE_VASTERGOTLAND: 'Vastergotland',
	STATE_VASTERNORRLAND: 'Vasternorrland',
	STATE_VASTMANLAND: 'Vastmanland',
	STATE_VASTRA_GOTALAND: 'Vastra Gotaland',
	STATE_AARGAU: 'Aargau',
	STATE_APPENZELL_INNER_RHODEN: 'Appenzell Inner-Rhoden',
	STATE_APPENZELL_AUSSER_RHODEN: 'Appenzell-Ausser Rhoden',
	STATE_BASEL_LANDSCHAFT: 'Basel-Landschaft',
	STATE_BASEL_STADT: 'Basel-Stadt',
	STATE_BERN: 'Bern',
	STATE_CANTON_TICINO: 'Canton Ticino',
	STATE_FRIBOURG: 'Fribourg',
	STATE_GENEVE: 'Geneve',
	STATE_GLARUS: 'Glarus',
	STATE_GRAUBUNDEN: 'Graubunden',
	STATE_HEERBRUGG: 'Heerbrugg',
	STATE_KANTON_AARGAU: 'Kanton Aargau',
	STATE_LUZERN: 'Luzern',
	STATE_MORBIO_INFERIORE: 'Morbio Inferiore',
	STATE_MUHEN: 'Muhen',
	STATE_NEUCHATEL: 'Neuchatel',
	STATE_NIDWALDEN: 'Nidwalden',
	STATE_OBWALDEN: 'Obwalden',
	STATE_SANKT_GALLEN: 'Sankt Gallen',
	STATE_SCHAFFHAUSEN: 'Schaffhausen',
	STATE_SCHWYZ: 'Schwyz',
	STATE_SOLOTHURN: 'Solothurn',
	STATE_THURGAU: 'Thurgau',
	STATE_TICINO: 'Ticino',
	STATE_URI: 'Uri',
	STATE_VALAIS: 'Valais',
	STATE_VAUD: 'Vaud',
	STATE_VAUFFELIN: 'Vauffelin',
	STATE_ZUG: 'Zug',
	STATE_ZURICH: 'Zurich',
	STATE_ALEPPO: 'Aleppo',
	STATE_DAR__A: "Dar'a",
	STATE_DAYR_AZ_ZAWR: 'Dayr-az-Zawr',
	STATE_DIMASHQ: 'Dimashq',
	STATE_HALAB: 'Halab',
	STATE_HAMAH: 'Hamah',
	STATE_HIMS: 'Hims',
	STATE_IDLIB: 'Idlib',
	STATE_MADINAT_DIMASHQ: 'Madinat Dimashq',
	STATE_TARTUS: 'Tartus',
	STATE_AL_HASAKAH: 'al-Hasakah',
	STATE_AL_LADHIQIYAH: 'al-Ladhiqiyah',
	STATE_AL_QUNAYTIRAH: 'al-Qunaytirah',
	STATE_AR_RAQQAH: 'ar-Raqqah',
	STATE_AS_SUWAYDA: 'as-Suwayda',
	STATE_CHANGHWA: 'Changhwa',
	STATE_CHIAYI_HSIEN: 'Chiayi Hsien',
	STATE_CHIAYI_SHIH: 'Chiayi Shih',
	STATE_EASTERN_TAIPEI: 'Eastern Taipei',
	STATE_HSINCHU_HSIEN: 'Hsinchu Hsien',
	STATE_HSINCHU_SHIH: 'Hsinchu Shih',
	STATE_HUALIEN: 'Hualien',
	STATE_ILAN: 'Ilan',
	STATE_KAOHSIUNG_HSIEN: 'Kaohsiung Hsien',
	STATE_KAOHSIUNG_SHIH: 'Kaohsiung Shih',
	STATE_KEELUNG_SHIH: 'Keelung Shih',
	STATE_KINMEN: 'Kinmen',
	STATE_MIAOLI: 'Miaoli',
	STATE_NANTOU: 'Nantou',
	STATE_NORTHERN_TAIWAN: 'Northern Taiwan',
	STATE_PENGHU: 'Penghu',
	STATE_PINGTUNG: 'Pingtung',
	STATE_TAICHUNG: 'Taichung',
	STATE_TAICHUNG_HSIEN: 'Taichung Hsien',
	STATE_TAICHUNG_SHIH: 'Taichung Shih',
	STATE_TAINAN_HSIEN: 'Tainan Hsien',
	STATE_TAINAN_SHIH: 'Tainan Shih',
	STATE_TAIPEI_HSIEN: 'Taipei Hsien',
	STATE_TAIPEI_SHIH___TAIPEI_HSIEN: 'Taipei Shih / Taipei Hsien',
	STATE_TAITUNG: 'Taitung',
	STATE_TAOYUAN: 'Taoyuan',
	STATE_YILAN: 'Yilan',
	STATE_YUN_LIN_HSIEN: 'Yun-Lin Hsien',
	STATE_YUNLIN: 'Yunlin',
	STATE_DUSHANBE: 'Dushanbe',
	STATE_GORNO_BADAKHSHAN: 'Gorno-Badakhshan',
	STATE_KAROTEGIN: 'Karotegin',
	STATE_KHATLON: 'Khatlon',
	STATE_SUGHD: 'Sughd',
	STATE_ARUSHA: 'Arusha',
	STATE_DAR_ES_SALAAM: 'Dar es Salaam',
	STATE_DODOMA: 'Dodoma',
	STATE_IRINGA: 'Iringa',
	STATE_KAGERA: 'Kagera',
	STATE_KIGOMA: 'Kigoma',
	STATE_KILIMANJARO: 'Kilimanjaro',
	STATE_LINDI: 'Lindi',
	STATE_MARA: 'Mara',
	STATE_MBEYA: 'Mbeya',
	STATE_MOROGORO: 'Morogoro',
	STATE_MTWARA: 'Mtwara',
	STATE_PWANI: 'Pwani',
	STATE_RUKWA: 'Rukwa',
	STATE_RUVUMA: 'Ruvuma',
	STATE_SHINYANGA: 'Shinyanga',
	STATE_SINGIDA: 'Singida',
	STATE_TABORA: 'Tabora',
	STATE_TANGA: 'Tanga',
	STATE_ZANZIBAR_AND_PEMBA: 'Zanzibar and Pemba',
	STATE_AMNAT_CHAROEN: 'Amnat Charoen',
	STATE_ANG_THONG: 'Ang Thong',
	STATE_BANGKOK: 'Bangkok',
	STATE_BURI_RAM: 'Buri Ram',
	STATE_CHACHOENGSAO: 'Chachoengsao',
	STATE_CHAI_NAT: 'Chai Nat',
	STATE_CHAIYAPHUM: 'Chaiyaphum',
	STATE_CHANGWAT_CHAIYAPHUM: 'Changwat Chaiyaphum',
	STATE_CHANTHABURI: 'Chanthaburi',
	STATE_CHIANG_MAI: 'Chiang Mai',
	STATE_CHIANG_RAI: 'Chiang Rai',
	STATE_CHON_BURI: 'Chon Buri',
	STATE_CHUMPHON: 'Chumphon',
	STATE_KALASIN: 'Kalasin',
	STATE_KAMPHAENG_PHET: 'Kamphaeng Phet',
	STATE_KANCHANABURI: 'Kanchanaburi',
	STATE_KHON_KAEN: 'Khon Kaen',
	STATE_KRABI: 'Krabi',
	STATE_KRUNG_THEP: 'Krung Thep',
	STATE_LAMPANG: 'Lampang',
	STATE_LAMPHUN: 'Lamphun',
	STATE_LOEI: 'Loei',
	STATE_LOP_BURI: 'Lop Buri',
	STATE_MAE_HONG_SON: 'Mae Hong Son',
	STATE_MAHA_SARAKHAM: 'Maha Sarakham',
	STATE_MUKDAHAN: 'Mukdahan',
	STATE_NAKHON_NAYOK: 'Nakhon Nayok',
	STATE_NAKHON_PATHOM: 'Nakhon Pathom',
	STATE_NAKHON_PHANOM: 'Nakhon Phanom',
	STATE_NAKHON_RATCHASIMA: 'Nakhon Ratchasima',
	STATE_NAKHON_SAWAN: 'Nakhon Sawan',
	STATE_NAKHON_SI_THAMMARAT: 'Nakhon Si Thammarat',
	STATE_NAN: 'Nan',
	STATE_NARATHIWAT: 'Narathiwat',
	STATE_NONG_BUA_LAM_PHU: 'Nong Bua Lam Phu',
	STATE_NONG_KHAI: 'Nong Khai',
	STATE_NONTHABURI: 'Nonthaburi',
	STATE_PATHUM_THANI: 'Pathum Thani',
	STATE_PATTANI: 'Pattani',
	STATE_PHANGNGA: 'Phangnga',
	STATE_PHATTHALUNG: 'Phatthalung',
	STATE_PHAYAO: 'Phayao',
	STATE_PHETCHABUN: 'Phetchabun',
	STATE_PHETCHABURI: 'Phetchaburi',
	STATE_PHICHIT: 'Phichit',
	STATE_PHITSANULOK: 'Phitsanulok',
	STATE_PHRA_NAKHON_SI_AYUTTHAYA: 'Phra Nakhon Si Ayutthaya',
	STATE_PHRAE: 'Phrae',
	STATE_PHUKET: 'Phuket',
	STATE_PRACHIN_BURI: 'Prachin Buri',
	STATE_PRACHUAP_KHIRI_KHAN: 'Prachuap Khiri Khan',
	STATE_RANONG: 'Ranong',
	STATE_RATCHABURI: 'Ratchaburi',
	STATE_RAYONG: 'Rayong',
	STATE_ROI_ET: 'Roi Et',
	STATE_SA_KAEO: 'Sa Kaeo',
	STATE_SAKON_NAKHON: 'Sakon Nakhon',
	STATE_SAMUT_PRAKAN: 'Samut Prakan',
	STATE_SAMUT_SAKHON: 'Samut Sakhon',
	STATE_SAMUT_SONGKHRAN: 'Samut Songkhran',
	STATE_SARABURI: 'Saraburi',
	STATE_SATUN: 'Satun',
	STATE_SI_SA_KET: 'Si Sa Ket',
	STATE_SING_BURI: 'Sing Buri',
	STATE_SONGKHLA: 'Songkhla',
	STATE_SUKHOTHAI: 'Sukhothai',
	STATE_SUPHAN_BURI: 'Suphan Buri',
	STATE_SURAT_THANI: 'Surat Thani',
	STATE_SURIN: 'Surin',
	STATE_TAK: 'Tak',
	STATE_TRANG: 'Trang',
	STATE_TRAT: 'Trat',
	STATE_UBON_RATCHATHANI: 'Ubon Ratchathani',
	STATE_UDON_THANI: 'Udon Thani',
	STATE_UTHAI_THANI: 'Uthai Thani',
	STATE_UTTARADIT: 'Uttaradit',
	STATE_YALA: 'Yala',
	STATE_YASOTHON: 'Yasothon',
	STATE_KARA: 'Kara',
	STATE_MARITIME: 'Maritime',
	STATE_ATAFU: 'Atafu',
	STATE_FAKAOFO: 'Fakaofo',
	STATE_NUKUNONU: 'Nukunonu',
	STATE_EUA: 'Eua',
	STATE_HA__APAI: "Ha'apai",
	STATE_NIUAS: 'Niuas',
	STATE_TONGATAPU: 'Tongatapu',
	STATE_VAVA__U: "Vava'u",
	STATE_ARIMA_TUNAPUNA_PIARCO: 'Arima-Tunapuna-Piarco',
	STATE_CARONI: 'Caroni',
	STATE_CHAGUANAS: 'Chaguanas',
	STATE_COUVA_TABAQUITE_TALPARO: 'Couva-Tabaquite-Talparo',
	STATE_DIEGO_MARTIN: 'Diego Martin',
	STATE_GLENCOE: 'Glencoe',
	STATE_PENAL_DEBE: 'Penal Debe',
	STATE_POINT_FORTIN: 'Point Fortin',
	STATE_PORT_OF_SPAIN: 'Port of Spain',
	STATE_PRINCES_TOWN: 'Princes Town',
	STATE_SAN_FERNANDO: 'San Fernando',
	STATE_SANGRE_GRANDE: 'Sangre Grande',
	STATE_SIPARIA: 'Siparia',
	STATE_TOBAGO: 'Tobago',
	STATE_ARYANAH: 'Aryanah',
	STATE_BAJAH: 'Bajah',
	STATE_BIN___ARUS: "Bin 'Arus",
	STATE_BINZART: 'Binzart',
	STATE_GOUVERNORAT_DE_ARIANA: 'Gouvernorat de Ariana',
	STATE_GOUVERNORAT_DE_NABEUL: 'Gouvernorat de Nabeul',
	STATE_GOUVERNORAT_DE_SOUSSE: 'Gouvernorat de Sousse',
	STATE_HAMMAMET_YASMINE: 'Hammamet Yasmine',
	STATE_JUNDUBAH: 'Jundubah',
	STATE_MADANIYIN: 'Madaniyin',
	STATE_MANUBAH: 'Manubah',
	STATE_MONASTIR: 'Monastir',
	STATE_NABUL: 'Nabul',
	STATE_QABIS: 'Qabis',
	STATE_QAFSAH: 'Qafsah',
	STATE_QIBILI: 'Qibili',
	STATE_SAFAQIS: 'Safaqis',
	STATE_SFAX: 'Sfax',
	STATE_SIDI_BU_ZAYD: 'Sidi Bu Zayd',
	STATE_SILYANAH: 'Silyanah',
	STATE_SUSAH: 'Susah',
	STATE_TATAWIN: 'Tatawin',
	STATE_TAWZAR: 'Tawzar',
	STATE_TUNIS: 'Tunis',
	STATE_ZAGHWAN: 'Zaghwan',
	STATE_AL_KAF: 'al-Kaf',
	STATE_AL_MAHDIYAH: 'al-Mahdiyah',
	STATE_AL_MUNASTIR: 'al-Munastir',
	STATE_AL_QASRAYN: 'al-Qasrayn',
	STATE_AL_QAYRAWAN: 'al-Qayrawan',
	STATE_ADANA: 'Adana',
	STATE_ADIYAMAN: 'Adiyaman',
	STATE_AFYON: 'Afyon',
	STATE_AGRI: 'Agri',
	STATE_AKSARAY: 'Aksaray',
	STATE_AMASYA: 'Amasya',
	STATE_ANKARA: 'Ankara',
	STATE_ANTALYA: 'Antalya',
	STATE_ARDAHAN: 'Ardahan',
	STATE_ARTVIN: 'Artvin',
	STATE_AYDIN: 'Aydin',
	STATE_BALIKESIR: 'Balikesir',
	STATE_BARTIN: 'Bartin',
	STATE_BATMAN: 'Batman',
	STATE_BAYBURT: 'Bayburt',
	STATE_BILECIK: 'Bilecik',
	STATE_BINGOL: 'Bingol',
	STATE_BITLIS: 'Bitlis',
	STATE_BOLU: 'Bolu',
	STATE_BURDUR: 'Burdur',
	STATE_BURSA: 'Bursa',
	STATE_CANAKKALE: 'Canakkale',
	STATE_CANKIRI: 'Cankiri',
	STATE_CORUM: 'Corum',
	STATE_DENIZLI: 'Denizli',
	STATE_DIYARBAKIR: 'Diyarbakir',
	STATE_DUZCE: 'Duzce',
	STATE_EDIRNE: 'Edirne',
	STATE_ELAZIG: 'Elazig',
	STATE_ERZINCAN: 'Erzincan',
	STATE_ERZURUM: 'Erzurum',
	STATE_ESKISEHIR: 'Eskisehir',
	STATE_GAZIANTEP: 'Gaziantep',
	STATE_GIRESUN: 'Giresun',
	STATE_GUMUSHANE: 'Gumushane',
	STATE_HAKKARI: 'Hakkari',
	STATE_HATAY: 'Hatay',
	STATE_ICEL: 'Icel',
	STATE_IGDIR: 'Igdir',
	STATE_ISPARTA: 'Isparta',
	STATE_ISTANBUL: 'Istanbul',
	STATE_IZMIR: 'Izmir',
	STATE_KAHRAMANMARAS: 'Kahramanmaras',
	STATE_KARABUK: 'Karabuk',
	STATE_KARAMAN: 'Karaman',
	STATE_KARS: 'Kars',
	STATE_KARSIYAKA: 'Karsiyaka',
	STATE_KASTAMONU: 'Kastamonu',
	STATE_KAYSERI: 'Kayseri',
	STATE_KILIS: 'Kilis',
	STATE_KIRIKKALE: 'Kirikkale',
	STATE_KIRKLARELI: 'Kirklareli',
	STATE_KIRSEHIR: 'Kirsehir',
	STATE_KOCAELI: 'Kocaeli',
	STATE_KONYA: 'Konya',
	STATE_KUTAHYA: 'Kutahya',
	STATE_LEFKOSA: 'Lefkosa',
	STATE_MALATYA: 'Malatya',
	STATE_MANISA: 'Manisa',
	STATE_MARDIN: 'Mardin',
	STATE_MUGLA: 'Mugla',
	STATE_MUS: 'Mus',
	STATE_NEVSEHIR: 'Nevsehir',
	STATE_NIGDE: 'Nigde',
	STATE_ORDU: 'Ordu',
	STATE_OSMANIYE: 'Osmaniye',
	STATE_RIZE: 'Rize',
	STATE_SAKARYA: 'Sakarya',
	STATE_SAMSUN: 'Samsun',
	STATE_SANLIURFA: 'Sanliurfa',
	STATE_SIIRT: 'Siirt',
	STATE_SINOP: 'Sinop',
	STATE_SIRNAK: 'Sirnak',
	STATE_SIVAS: 'Sivas',
	STATE_TEKIRDAG: 'Tekirdag',
	STATE_TOKAT: 'Tokat',
	STATE_TRABZON: 'Trabzon',
	STATE_TUNCELI: 'Tunceli',
	STATE_USAK: 'Usak',
	STATE_VAN: 'Van',
	STATE_YALOVA: 'Yalova',
	STATE_YOZGAT: 'Yozgat',
	STATE_ZONGULDAK: 'Zonguldak',
	STATE_AHAL: 'Ahal',
	STATE_ASGABAT: 'Asgabat',
	STATE_BALKAN: 'Balkan',
	STATE_DASOGUZ: 'Dasoguz',
	STATE_LEBAP: 'Lebap',
	STATE_MARI: 'Mari',
	STATE_GRAND_TURK: 'Grand Turk',
	STATE_SOUTH_CAICOS_AND_EAST_CAICOS: 'South Caicos and East Caicos',
	STATE_FUNAFUTI: 'Funafuti',
	STATE_NANUMANGA: 'Nanumanga',
	STATE_NANUMEA: 'Nanumea',
	STATE_NIUTAO: 'Niutao',
	STATE_NUI: 'Nui',
	STATE_NUKUFETAU: 'Nukufetau',
	STATE_NUKULAELAE: 'Nukulaelae',
	STATE_VAITUPU: 'Vaitupu',
	STATE_CHERKAS__KA: "Cherkas'ka",
	STATE_CHERNIHIVS__KA: "Chernihivs'ka",
	STATE_CHERNIVETS__KA: "Chernivets'ka",
	STATE_CRIMEA: 'Crimea',
	STATE_DNIPROPETROVSKA: 'Dnipropetrovska',
	STATE_DONETS__KA: "Donets'ka",
	STATE_IVANO_FRANKIVS__KA: "Ivano-Frankivs'ka",
	STATE_KHARKIV: 'Kharkiv',
	STATE_KHARKOV: 'Kharkov',
	STATE_KHERSONSKA: 'Khersonska',
	STATE_KHMEL__NYTS__KA: "Khmel'nyts'ka",
	STATE_KIROVOHRAD: 'Kirovohrad',
	STATE_KRYM: 'Krym',
	STATE_KYYIV: 'Kyyiv',
	STATE_KYYIVS__KA: "Kyyivs'ka",
	STATE_L__VIVS__KA: "L'vivs'ka",
	STATE_LUHANS__KA: "Luhans'ka",
	STATE_MYKOLAYIVS__KA: "Mykolayivs'ka",
	STATE_ODES__KA: "Odes'ka",
	STATE_ODESSA: 'Odessa',
	STATE_POLTAVS__KA: "Poltavs'ka",
	STATE_RIVNENS__KA: "Rivnens'ka",
	STATE_SEVASTOPOL: 'Sevastopol',
	STATE_SUMS__KA: "Sums'ka",
	STATE_TERNOPIL__S__KA: "Ternopil's'ka",
	STATE_VOLYNS__KA: "Volyns'ka",
	STATE_VYNNYTS__KA: "Vynnyts'ka",
	STATE_ZAKARPATS__KA: "Zakarpats'ka",
	STATE_ZAPORIZHIA: 'Zaporizhia',
	STATE_ZHYTOMYRS__KA: "Zhytomyrs'ka",
	STATE_ABU_ZABI: 'Abu Zabi',
	STATE_AJMAN: 'Ajman',
	STATE_DUBAI: 'Dubai',
	STATE_RAS_AL_KHAYMAH: 'Ras al-Khaymah',
	STATE_SHARJAH: 'Sharjah',
	STATE_SHARJHA: 'Sharjha',
	STATE_UMM_AL_QAYWAYN: 'Umm al Qaywayn',
	STATE_AL_FUJAYRAH: 'al-Fujayrah',
	STATE_ASH_SHARIQAH: 'ash-Shariqah',
	STATE_ABERDEEN: 'Aberdeen',
	STATE_ABERDEENSHIRE: 'Aberdeenshire',
	STATE_ARGYLL: 'Argyll',
	STATE_BEDFORDSHIRE: 'Bedfordshire',
	STATE_BELFAST: 'Belfast',
	STATE_BERKSHIRE: 'Berkshire',
	STATE_BIRMINGHAM: 'Birmingham',
	STATE_BRECHIN: 'Brechin',
	STATE_BRIDGNORTH: 'Bridgnorth',
	STATE_BRISTOL: 'Bristol',
	STATE_BUCKINGHAMSHIRE: 'Buckinghamshire',
	STATE_CAMBRIDGE: 'Cambridge',
	STATE_CAMBRIDGESHIRE: 'Cambridgeshire',
	STATE_CHANNEL_ISLANDS: 'Channel Islands',
	STATE_CHESHIRE: 'Cheshire',
	STATE_CLEVELAND: 'Cleveland',
	STATE_CO_FERMANAGH: 'Co Fermanagh',
	STATE_CONWY: 'Conwy',
	STATE_CORNWALL: 'Cornwall',
	STATE_COVENTRY: 'Coventry',
	STATE_CRAVEN_ARMS: 'Craven Arms',
	STATE_CUMBRIA: 'Cumbria',
	STATE_DENBIGHSHIRE: 'Denbighshire',
	STATE_DERBY: 'Derby',
	STATE_DERBYSHIRE: 'Derbyshire',
	STATE_DEVON: 'Devon',
	STATE_DIAL_CODE_DUNGANNON: 'Dial Code Dungannon',
	STATE_DIDCOT: 'Didcot',
	STATE_DORSET: 'Dorset',
	STATE_DUNBARTONSHIRE: 'Dunbartonshire',
	STATE_DURHAM: 'Durham',
	STATE_EAST_DUNBARTONSHIRE: 'East Dunbartonshire',
	STATE_EAST_LOTHIAN: 'East Lothian',
	STATE_EAST_MIDLANDS: 'East Midlands',
	STATE_EAST_SUSSEX: 'East Sussex',
	STATE_EAST_YORKSHIRE: 'East Yorkshire',
	STATE_ENGLAND: 'England',
	STATE_ESSEX: 'Essex',
	STATE_FERMANAGH: 'Fermanagh',
	STATE_FIFE: 'Fife',
	STATE_FLINTSHIRE: 'Flintshire',
	STATE_FULHAM: 'Fulham',
	STATE_GAINSBOROUGH: 'Gainsborough',
	STATE_GLOCESTERSHIRE: 'Glocestershire',
	STATE_GWENT: 'Gwent',
	STATE_HAMPSHIRE: 'Hampshire',
	STATE_HANTS: 'Hants',
	STATE_HEREFORDSHIRE: 'Herefordshire',
	STATE_HERTFORDSHIRE: 'Hertfordshire',
	STATE_IRELAND: 'Ireland',
	STATE_ISLE_OF_MAN: 'Isle Of Man',
	STATE_ISLE_OF_WIGHT: 'Isle of Wight',
	STATE_KENFORD: 'Kenford',
	STATE_KENT: 'Kent',
	STATE_KILMARNOCK: 'Kilmarnock',
	STATE_LANARKSHIRE: 'Lanarkshire',
	STATE_LANCASHIRE: 'Lancashire',
	STATE_LEICESTERSHIRE: 'Leicestershire',
	STATE_LINCOLNSHIRE: 'Lincolnshire',
	STATE_LLANYMYNECH: 'Llanymynech',
	STATE_LONDON: 'London',
	STATE_LUDLOW: 'Ludlow',
	STATE_MAYFAIR: 'Mayfair',
	STATE_MERSEYSIDE: 'Merseyside',
	STATE_MID_GLAMORGAN: 'Mid Glamorgan',
	TATE_MIDDLESEX: 'Middlesex',
	STATE_MILDENHALL: 'Mildenhall',
	STATE_MONMOUTHSHIRE: 'Monmouthshire',
	STATE_NEWTON_STEWART: 'Newton Stewart',
	STATE_NORFOLK: 'Norfolk',
	STATE_NORTH_HUMBERSIDE: 'North Humberside',
	STATE_NORTH_YORKSHIRE: 'North Yorkshire',
	STATE_NORTHAMPTONSHIRE: 'Northamptonshire',
	STATE_NORTHANTS: 'Northants',
	STATE_NORTHERN_IRELAND: 'Northern Ireland',
	STATE_NORTHUMBERLAND: 'Northumberland',
	STATE_NOTTINGHAMSHIRE: 'Nottinghamshire',
	STATE_OXFORD: 'Oxford',
	STATE_POWYS: 'Powys',
	STATE_ROOS_SHIRE: 'Roos-shire',
	STATE_SUSSEX: 'SUSSEX',
	STATE_SCOTLAND: 'Scotland',
	STATE_SCOTTISH_BORDERS: 'Scottish Borders',
	STATE_SHROPSHIRE: 'Shropshire',
	STATE_SOMERSET: 'Somerset',
	STATE_SOUTH_GLAMORGAN: 'South Glamorgan',
	STATE_SOUTH_WALES: 'South Wales',
	STATE_SOUTH_YORKSHIRE: 'South Yorkshire',
	STATE_SOUTHWELL: 'Southwell',
	STATE_STAFFORDSHIRE: 'Staffordshire',
	STATE_STRABANE: 'Strabane',
	STATE_SUFFOLK: 'Suffolk',
	STATE_SURREY: 'Surrey',
	STATE_TWICKENHAM: 'Twickenham',
	STATE_TYNE_AND_WEAR: 'Tyne and Wear',
	STATE_TYRONE: 'Tyrone',
	STATE_UTAH: 'Utah',
	STATE_WALES: 'Wales',
	STATE_WARWICKSHIRE: 'Warwickshire',
	STATE_WEST_LOTHIAN: 'West Lothian',
	STATE_WEST_MIDLANDS: 'West Midlands',
	STATE_WEST_SUSSEX: 'West Sussex',
	STATE_WEST_YORKSHIRE: 'West Yorkshire',
	STATE_WHISSENDINE: 'Whissendine',
	STATE_WILTSHIRE: 'Wiltshire',
	STATE_WOKINGHAM: 'Wokingham',
	STATE_WORCESTERSHIRE: 'Worcestershire',
	STATE_WREXHAM: 'Wrexham',
	STATE_WURTTEMBERG: 'Wurttemberg',
	STATE_YORKSHIRE: 'Yorkshire',
	STATE_CALIFORNIA: 'California',
	STATE_COLORADO: 'Colorado',
	STATE_CONNECTICUT: 'Connecticut',
	STATE_DELAWARE: 'Delaware',
	STATE_DISTRICT_OF_COLUMBIA: 'District of Columbia',
	STATE_HAWAII: 'Hawaii',
	STATE_IDAHO: 'Idaho',
	STATE_ILLINOIS: 'Illinois',
	STATE_INDIANA: 'Indiana',
	STATE_IOWA: 'Iowa',
	STATE_KANSAS: 'Kansas',
	STATE_KENTUCKY: 'Kentucky',
	STATE_LOUISIANA: 'Louisiana',
	STATE_MAINE: 'Maine',
	STATE_MARYLAND: 'Maryland',
	STATE_MASSACHUSETTS: 'Massachusetts',
	STATE_MICHIGAN: 'Michigan',
	STATE_MINNESOTA: 'Minnesota',
	STATE_MISSISSIPPI: 'Mississippi',
	STATE_MISSOURI: 'Missouri',
	STATE_NEBRASKA: 'Nebraska',
	STATE_NEVADA: 'Nevada',
	STATE_NEW_HAMPSHIRE: 'New Hampshire',
	STATE_NEW_JERSEY: 'New Jersey',
	STATE_NEW_MEXICO: 'New Mexico',
	STATE_NORTH_CAROLINA: 'North Carolina',
	STATE_NORTH_DAKOTA: 'North Dakota',
	STATE_OHIO: 'Ohio',
	STATE_OKLAHOMA: 'Oklahoma',
	STATE_OREGON: 'Oregon',
	STATE_PENNSYLVANIA: 'Pennsylvania',
	STATE_RHODE_ISLAND: 'Rhode Island',
	STATE_SOUTH_CAROLINA: 'South Carolina',
	STATE_SOUTH_DAKOTA: 'South Dakota',
	STATE_TENNESSEE: 'Tennessee',
	STATE_TEXAS: 'Texas',
	STATE_VIRGINIA: 'Virginia',
	STATE_WEST_VIRGINIA: 'West Virginia',
	STATE_WISCONSIN: 'Wisconsin',
	STATE_WYOMING: 'Wyoming',
	STATE_UNITED_STATES_MINOR_OUTLYING_I: 'United States Minor Outlying I',
	STATE_ARTIGAS: 'Artigas',
	STATE_CANELONES: 'Canelones',
	STATE_CERRO_LARGO: 'Cerro Largo',
	STATE_COLONIA: 'Colonia',
	STATE_DURAZNO: 'Durazno',
	STATE_FLORES: 'Flores',
	STATE_LAVALLEJA: 'Lavalleja',
	STATE_MALDONADO: 'Maldonado',
	STATE_MONTEVIDEO: 'Montevideo',
	STATE_PAYSANDU: 'Paysandu',
	STATE_RIVERA: 'Rivera',
	STATE_ROCHA: 'Rocha',
	STATE_SALTO: 'Salto',
	STATE_SORIANO: 'Soriano',
	STATE_TACUAREMBO: 'Tacuarembo',
	STATE_TREINTA_Y_TRES: 'Treinta y Tres',
	STATE_ANDIJON: 'Andijon',
	STATE_BUHORO: 'Buhoro',
	STATE_CIZAH: 'Cizah',
	STATE_FARGONA: 'Fargona',
	STATE_HORAZM: 'Horazm',
	STATE_KASKADAR: 'Kaskadar',
	STATE_KORAKALPOGISTON: 'Korakalpogiston',
	STATE_NAMANGAN: 'Namangan',
	STATE_NAVOI: 'Navoi',
	STATE_SAMARKAND: 'Samarkand',
	STATE_SIRDARE: 'Sirdare',
	STATE_SURHONDAR: 'Surhondar',
	STATE_TOSKENT: 'Toskent',
	STATE_MALAMPA: 'Malampa',
	STATE_PENAMA: 'Penama',
	STATE_SANMA: 'Sanma',
	STATE_SHEFA: 'Shefa',
	STATE_TAFEA: 'Tafea',
	STATE_TORBA: 'Torba',
	STATE_VATICAN_CITY_STATE_HOLY_SEE_: 'Vatican City State (Holy See)',
	STATE_ANZOATEGUI: 'Anzoategui',
	STATE_APURE: 'Apure',
	STATE_ARAGUA: 'Aragua',
	STATE_BARINAS: 'Barinas',
	STATE_CARABOBO: 'Carabobo',
	STATE_COJEDES: 'Cojedes',
	STATE_DELTA_AMACURO: 'Delta Amacuro',
	STATE_FALCON: 'Falcon',
	STATE_GUARICO: 'Guarico',
	STATE_LARA: 'Lara',
	STATE_MERIDA: 'Merida',
	STATE_MIRANDA: 'Miranda',
	STATE_MONAGAS: 'Monagas',
	STATE_NUEVA_ESPARTA: 'Nueva Esparta',
	STATE_PORTUGUESA: 'Portuguesa',
	STATE_TACHIRA: 'Tachira',
	STATE_TRUJILLO: 'Trujillo',
	STATE_VARGAS: 'Vargas',
	STATE_YARACUY: 'Yaracuy',
	STATE_ZULIA: 'Zulia',
	STATE_BAC_GIANG: 'Bac Giang',
	STATE_BINH_DINH: 'Binh Dinh',
	STATE_BINH_DUONG: 'Binh Duong',
	STATE_DA_NANG: 'Da Nang',
	STATE_DONG_BANG_SONG_CUU_LONG: 'Dong Bang Song Cuu Long',
	STATE_DONG_BANG_SONG_HONG: 'Dong Bang Song Hong',
	STATE_DONG_NAI: 'Dong Nai',
	STATE_DONG_NAM_BO: 'Dong Nam Bo',
	STATE_DUYEN_HAI_MIEN_TRUNG: 'Duyen Hai Mien Trung',
	STATE_HANOI: 'Hanoi',
	STATE_HUNG_YEN: 'Hung Yen',
	STATE_KHU_BON_CU: 'Khu Bon Cu',
	STATE_LONG_AN: 'Long An',
	STATE_MIEN_NUI_VA_TRUNG_DU: 'Mien Nui Va Trung Du',
	STATE_THAI_NGUYEN: 'Thai Nguyen',
	STATE_THANH_PHO_HO_CHI_MINH: 'Thanh Pho Ho Chi Minh',
	STATE_THU_DO_HA_NOI: 'Thu Do Ha Noi',
	STATE_TINH_CAN_THO: 'Tinh Can Tho',
	STATE_TINH_DA_NANG: 'Tinh Da Nang',
	STATE_TINH_GIA_LAI: 'Tinh Gia Lai',
	STATE_ANEGADA: 'Anegada',
	STATE_JOST_VAN_DYKE: 'Jost van Dyke',
	STATE_TORTOLA: 'Tortola',
	STATE_SAINT_CROIX: 'Saint Croix',
	STATE_ALO: 'Alo',
	STATE_SINGAVE: 'Singave',
	STATE_WALLIS: 'Wallis',
	STATE_BU_JAYDUR: 'Bu Jaydur',
	STATE_WAD_ADH_DHAHAB: 'Wad-adh-Dhahab',
	STATE_AL___AYUN: "al-'Ayun",
	STATE_AS_SAMARAH: 'as-Samarah',
	STATE_ADAN: 'Adan',
	STATE_ABYAN: 'Abyan',
	STATE_DHAMAR: 'Dhamar',
	STATE_HADRAMAUT: 'Hadramaut',
	STATE_HAJJAH: 'Hajjah',
	STATE_HUDAYDAH: 'Hudaydah',
	STATE_IBB: 'Ibb',
	STATE_LAHIJ: 'Lahij',
	STATE_MA__RIB: "Ma'rib",
	STATE_MADINAT_SAN__A: "Madinat San'a",
	STATE_SA__DAH: "Sa'dah",
	STATE_SANA: 'Sana',
	STATE_SHABWAH: 'Shabwah',
	STATE_TA__IZZ: "Ta'izz",
	STATE_AL_BAYDA: 'al-Bayda',
	STATE_AL_HUDAYDAH: 'al-Hudaydah',
	STATE_AL_JAWF: 'al-Jawf',
	STATE_AL_MAHRAH: 'al-Mahrah',
	STATE_AL_MAHWIT: 'al-Mahwit',
	STATE_COPPERBELT: 'Copperbelt',
	STATE_LUAPALA: 'Luapala',
	STATE_LUSAKA: 'Lusaka',
	STATE_NORTH_WESTERN: 'North-Western',
	STATE_BULAWAYO: 'Bulawayo',
	STATE_HARARE: 'Harare',
	STATE_MANICALAND: 'Manicaland',
	STATE_MASHONALAND_CENTRAL: 'Mashonaland Central',
	STATE_MASHONALAND_EAST: 'Mashonaland East',
	STATE_MASHONALAND_WEST: 'Mashonaland West',
	STATE_MASVINGO: 'Masvingo',
	STATE_MATABELELAND_NORTH: 'Matabeleland North',
	STATE_MATABELELAND_SOUTH: 'Matabeleland South',
	STATE_MIDLANDS: 'Midlands',
	STATE_AARHUS: 'Aarhus'
};
