import onboardingLocalization from '../components/Onboarding/localization/pt-BR';
import ticketingLocalization from '../components/Ticketing/localization/pt-BR';
import supportLocalization from '../components/Support/localization/pt-BR';
import automationLocalization from '../components/Automation/localization/pt-BR';
import sharedLocalization from '../components/Shared/localization/pt-BR';
import analyticsLocalization from '../components/AnalyticsInvoices/localization/pt-BR';
import failoverLocalization from '../components/Failover/localization/pt-BR';
import reportsLocalization from '../components/Reports/localization/pt-BR';
import eulaLocalization from '../components/Eula/localization/pt-BR';
import accountOverviewLocalization from '../components/AccountOverview/localization/pt-BR';
import analyticsChartsLocalization from '../components/Analytics/localization/pt-BR';
import batchHistoryLocalization from '../components/BatchHistory/localization/pt-BR';
import errorLocalization from '../components/ErrorPage/localization/pt-BR';
import userFeedbackLocalization from '../components/UserFeedback/localization/pt-BR';
import swaggerLocalization from '../components/Swagger/localization/pt-BR';
import securityFeatures from '../components/SecurityFeatures/localization/pt-BR';
import releaseNotesLocalization from '../components/ReleaseNotes/localization/pt-BR';
import endpointsLocalization from '../components/Endpoint/localization/pt-BR';
import singleEndpointLocalization from '../components/SingleEndpoint/localization/pt-BR';

export default {
	...automationLocalization,
	...sharedLocalization,
	...analyticsLocalization,
	...supportLocalization,
	...onboardingLocalization,
	...ticketingLocalization,
	...failoverLocalization,
	...reportsLocalization,
	...accountOverviewLocalization,
	...eulaLocalization,
	...analyticsChartsLocalization,
	...batchHistoryLocalization,
	...errorLocalization,
	...userFeedbackLocalization,
	...swaggerLocalization,
	...securityFeatures,
	...endpointsLocalization,
	...releaseNotesLocalization,
	...singleEndpointLocalization,
	// GLOBAL SETTINGS
	DISPLAY_DATE_FORMAT: 'mm/dd/aaaa',

	// SIDEBAR
	HOME: 'Página inicial',
	ENDPOINTS: 'Endpoints',
	Endpoints: 'Endpoints',
	REPORTS: 'Relatórios',
	INVOICES: 'Faturas',
	AUTOMATION: 'Automação',
	PLATFORM_STATUS: 'Status da plataforma',
	SUPPORT: 'Suporte',
	COLLAPSE_SIDEBAR: 'Recolher barra lateral',
	COMPANIES: 'Empresas',
	FAQ: 'Perguntas frequentes',
	HELP_SUPPORT: 'Ajuda e suporte',
	BATCH_UPDATES: 'Atualização de lotes',
	FAQS: 'Perguntas frequentes',
	RELEASE_NOTES: 'Notas de lançamento',
	SYSTEM_NOTIFICATIONS: 'Notificações do sistema',
	ACCOUNT_SETTINGS: 'Configurações da conta',
	COMPANY_ACCOUNTS: 'Contas da empresa',
	CUSTOM_FIELDS: 'Campos personalizados',
	COMPANY_DETAILS: 'Detalhes da empresa',
	TESTING_ICON: 'Testando ícone',
	ENTITIES: 'Entidades',
	IOTGW_FAILOVER: 'Failover de IoTGW',
	ACCESS_MANAGEMENT: 'Gerenciamento de acesso',
	SIM_ORDERS: 'Pedidos de SIM',
	USER_MANAGEMENT: 'Gerenciamento de usuários',

	// TOPBAR
	MORE_IOT: 'MAIS IoT',
	MORE_ATT: 'MAIS AT&T',
	LEARN_ABOUT_OFFERS: 'Aprender sobre as ofertas',
	BUY_DEVICES: 'Comprar dispositivos',
	ATT_SERVICES: 'Serviços da AT&T',
	USE_TOOLS_APPS: 'Usar ferramentas e aplicativos',
	IOT_PLATFORMS_PRODUCTS_APPS_AND_APIS: 'Plataformas da IoT, produtos, aplicativos e APIs',
	AMOC: 'AMOC',
	M2X: 'M2X',
	FLOW_DESIGNER: 'Designer de fluxo',
	IOT_STARTER_KITS: 'Kits para Iniciante na IoT',
	IOT_DATA_PLANS: 'Planos de dados da IoT',
	ENTERPRISE: 'Enterprise',
	BUSINESS: 'Empresa',
	CONSUMER: 'Consumidor',

	// PROFILE MENU
	PROFILE: 'Perfil',
	APPS: 'Aplicativos',
	LOGOUT: 'Logoff',
	LOG_IN: 'Logon',

	// FOOTER
	IOT_CUSTOMER_SOLUTIONS: 'Soluções da IoT para clientes',
	IOT_PLATFORM_AND_SERVICES: 'Pataforma e serviços da IoT',
	NETWORK_SOLUTIONS: 'Soluções de rede',
	PARTNERSHIP_PROGRAM: 'Programa de parcerias',
	BUSINESS_SUPPORT: 'Suporte para empresas',
	ABOUT_ATT: 'Sobre a AT&T',
	ABOUT_ATT_IOT: 'Sobre a IoT da AT&T',
	NEWS: 'Novidades',
	NETWORKING_EXCHANGE_BLOG: 'Blog de intercâmbio da rede',
	BUSINESS_COMMUNITY: 'Comunidade empresarial',
	YOUTUBE_CHANNEL: 'Canal do YouTube',
	LEGAL_PRIVACY_ADVERTISING_ACCESSIBILITY: 'Assuntos jurídicos, de privacidade, publicidade e acessibilidade',
	LEGAL_POLICY_CENTER: 'Central de políticas legais',
	PRIVACY_POLICY: 'Política de privacidade',
	TERMS_OF_USE: 'Termos de uso',
	BROADBAND_DETAILS: 'Detalhes da banda larga',
	YOUR_CHOICES: 'Suas opções',
	ADVERTISING_CHOICES: 'Opções de publicidade',
	ACCESSIBILITY: 'Acessibilidade',
	COPYRIGHT_ATT_INTELECTUAL_PROPERTY: '©{year} AT&T Intellectual Property. ',
	COPYRIGHT_MESSAGE: 'Todos os direitos reservados. AT&T, o logotipo do Globo, Mobilizing Your World e DIRECTV são marcas registradas da AT&T Intellectual Property e/ou de empresas afiliadas. Todas as demais marcas são propriedade dos seus respectivos proprietários.',
	CONNECT_WITH_ATT: 'Conecte com a AT&T',
	CURRENT_SESSION_USAGE: 'Uso da sessão atual',
	ENDPOINT_NETWORK_AND_LOCATION: 'Local e rede de endpoint',

	// NETWORK ENTITLEMENTS
	ENDPOINT_DETAILS_SETUP: 'Configurações de detalhes de Endpoint',
	NETWORK_ENTITLEMENT_DETAILS: 'Detalhes do direito de acesso à rede',
	CHANGE_NETWORK_ENTITLEMENTS: 'Alterar direitos de acesso à rede',
	CONFIRM_NETWORK_ENTITLEMENT_CHANGE: 'Confirmar alteração de direitos de acesso à rede',
	NETWORK_ENTITLEMENT_CHANGE_STATUS: 'Status de alteração dos direitos de acesso à rede',
	NETWORK_ENTITLEMENT_CHANGE_TOOLTIP_SUPPORT: 'Erro nos direitos de acesso à rede, entre em contato com serviço de atendimento ao cliente.',
	NETWORK_ENTITLEMENT_CHANGE_NOT_ALLOWED: 'Infelizmente não é permitida a alteração do direito de acesso à rede de endpoints múltiplos para endpoints de diferentes tipos de redes ou diferentes provedores de serviços.',
	SCHEDULE_NETWORK_ENTITLEMENT_SUCCESSFUL: 'Direito de acesso à rede de múltiplos SIMs foi agendado com êxito',
	SIMS_NETWORK_ENTITLEMENT_SUCCESSFULLY_UPDATED: 'Direito de acesso à rede de múltiplos SIMs foi atualizado com êxito',
	SIM_NETWORK_ENTITLEMENT_CHANGE_FOR: 'Alteração no direito de acesso à rede do SIM para',
	MULTIPLE_NETWORK_ENTITLEMENT_CHANGE_SCHEDULE_ERROR: 'Não foi possível agendar a alteração do direito de acesso à rede de múltiplos SIMs com êxito',
	MULTIPLE_NETWORK_ENTITLEMENT_CHANGE_ERROR: 'Não foi possível solicitar a alteração do direito de acesso à rede de múltiplos SIMs com êxito',
	states: 'Status',
	networkOperators: 'Operador de rede',
	countries: 'País da rede',
	SELECT_NETWORK_ENTITLEMENT: 'Selecionar direitos de acesso à rede',
	ACCOUNT_NAME: 'Nome da conta',
	APN: 'APN',
	DESCRIPTION: 'Descrição',
	ROAMING: 'Roaming',
	CREATE_DATE: 'Data de criação',
	LAST_UPDATE_DATE: 'Data da última atualização',
	APN_LTE: 'APN LTE',
	SMS_MT: 'MT de SMS',
	SMS_MO: 'MO de SMS',
	VOICE_MO: 'MO de voz',
	VOICE_MT: 'MT de voz',
	INTERNATIONAL_VOICE: 'Voz internacional',
	FIXED_IP_ENABLED: 'IP fixo habilitado',
	LTE_DATA: 'Dados de LTE',
	LTE_ROAMING_RESTRICT_SET_ID: 'Roaming LTE',
	LTE_APNS: 'APNs de LTE',
	CAMEL_SUBSCRIPTION_INFO: 'Camel',
	CAMEL_SERVICE_TYPE: 'Tipo de serviço Camel',
	M2M_PLATFORM: 'Plataforma M2M',
	APNS_ID: 'ID do APN',
	APNS_NAME: 'Nome',
	APNS_LTE: 'LTE',
	APNS_PDPINDEX: 'Índice PDP',
	APNS_DESCRIPTION: 'Descrição do APN',
	APNS_FIXEDIP: 'IP fixo',
	APNS_SECURITY: 'Segurança',
	APNS_SUBSCRIPTION: 'Assinatura',
	APNS_CREATEDATE: 'Data de criação',
	APNS_LASTUPDATEDATE: 'Data da última atualização',
	APNS_LASTUSER: 'Último usuário',
	APNS_APNTYPE: 'Tipo de APN',
	APNS_CREATIONTIMESTAMP: 'Carimbo de tempo de criação',
	APNS_BMSYNCSTATUS: ' Status do BM Sync',
	APNS_HLRPROFILEREFERENCE: 'Referência do perfil HLRHSSDetails',
	APNS_IPADDRESSALLOCATIONTYPE: 'Tipo de alocação do endereço de IP',
	APNS_ISLOCALBREAKOUTENABLEDINDICATOR: 'É indicador habilitado de breakout local',
	APNS_LASTUPDATETIMESTAMP: 'Último carimbo de data atualizado',
	APNS_OUCCONNECTIONTYPE: 'Tipo de conexão OUC',
	APNS_SECURITYPROFILESUPPORTEDINDICATOR: 'Indicador de perfil de segurança com suporte',
	APNS_STATUS: 'Status',
	APNS_VPNENABLEDINDICATOR: 'Indicador de VPN habilitado',
	CURRENTLY_ASSIGNED_TO_THIS_ENDPOINT: 'Atualmente atribuído para este endpoint',
	CURRENTLY_ASSIGNED_TO_SELECTED_ENDPOINTS: 'Atualmente atribuído para os endpoints selecionados',
	ELIGIBLE_TO_USE_WITH_SELECTED_ENDPOINTS: 'Qualificado para usar com os endpoints selecionados',
	ELIGIBLE_TO_USE_WITH_THIS_ENDPOINT: 'Qualificado para usar com este endpoint',
	ELIGIBLE_NETWORK_ENTITLEMENTS: 'Direitos qualificados de acesso à rede',
	NETWORK_ENTITLEMENT_CHANGE_IS_SUCCESSFULLY_SUBMITTED: 'Alteração de direito de acesso à rede foi enviada com êxito.',
	NETWORK_ENTITLEMENT_CHANGE_COULD_NOT_BE_CARRIED_OUT_SUCCESSFULLY: 'Alteração de direito de acesso à rede não pôde ser realizada neste momento.',
	NETWORK_ENTITLEMENT_CHANGE_IS_SUCCESSFULLY_SCHEDULED: 'Alteração de direito de acesso à rede foi agendada com êxito.',
	NETWORK_ENTITLEMENT_CHANGE_IS_SUCCESSFULLY_SCHEDULED_FOR: 'Alteração de direito de acesso à rede foi agendada com êxito para',
	NETWORK_ENTITLEMENT_CHANGE_COULD_fNOT_BE_SCHEDULED_SUCCESSFULLY: 'Alteração do direito de acesso à rede não pôde ser agendada neste momento.',
	ONCE_YOU_INITIATE_THIS_CHANGE: 'Depois de iniciar esta alteração, o direito de acesso à rede selecionado do endpoint será ',
	WOULD_YOU_LIKE_TO_PROCEED_WITH_THIS_ACTION: 'Deseja continuar com esta ação?',
	OR_YOU_CAN_SCHEDULE_THE_CHANGE_FOR: 'Ou você pode agendar a alteração para: ',
	FRIENDLY_NAME: 'Apelido',
	NAME: 'Nome',
	// Detalhes do direito de acesso à rede
	APN_DETAILS_ID: 'ID',
	APN_DETAILS_NAME: 'Nome',
	APN_DETAILS_LTE: 'LTE',
	APN_DETAILS_PDPINDEX: 'Índice PDP',
	APN_DETAILS_DESCRIPTION: 'Descrição',
	APN_DETAILS_FIXEDIP: 'IP fixo',
	APN_DETAILS_SECURITY: 'Segurança',
	APN_DETAILS_SUBSCRIPTION: 'Assinatura',

	// BUTTON LABELS
	CANCEL: 'Cancelar',
	CHANGE: 'Alterar',
	TROUBLESHOOT: 'Solucionar problemas',
	CHANGE_STATUS: 'Alterar Status',
	CLOSE: 'Fechar',
	APPLY_CHANGE: 'Aplicar alteração',
	DONE: 'Concluído',
	SAVE: 'Salvar',
	CONTINUE: 'Continuar',
	SAVEDASHBOARD: 'Salvar painel',
	SAVE_AND_CONTINUE: 'Salvar e continuar',
	SAVE_CHANGES: 'Salvar alterações',
	PULL_DATA: 'Obter dados',
	I_DO_NOT_AGREE: 'Não concordo',
	I_AGREE: 'Concordo',
	LOADING: 'Carregando',
	UPLOAD_LOGO: 'Carregar logo',
	ADD_NEW_CUSTOMER: 'Adicionar novo cliente',
	EDIT: 'Revisar',
	DELETE: 'Excluir',
	NEXT: 'Avançar',

	// NOTIFICATIONS
	ENDPOINT_ID: 'ID do endpoint',
	PROVISIONING_ERROR: 'Erro de provisionamento',
	FAILED_TO_PROVISION_AFTER_IMSI_STATUS_CHANGE: ' falha no provisionamento após alteração do status de IMSI. ',
	GET_SUPPORT: 'Obter suporte',
	PROVISIONING_SUCCESS: 'Sucesso de provisionamento',
	IS_SUCCESSFULLY_PROVISIONED: ' foi provisionado com êxito. ',
	IMSI_STATUS_IS_CHANGED_TO: 'Status de IMSI mudou para ',
	PENDING_STATUS_CHANGE: 'Alteração pendente!',
	STATUS_CHANGE_FOR: 'Há uma atividade agendada para este endpoint',
	IS_PENDING: ' está pendente',
	CUSTOM_FIELD_EDIT_SUCCESS: 'Êxito na revisão do campo personalizado',
	CUSTOM_FIELD_EDIT_ERROR: 'Erro na revisão do campo personalizado',
	CUSTOM_FIELD_VALUES_UPDATED: 'Um ou mais campos personalizados foram atualizados com êxito',
	CUSTOM_FIELD_VALUES_UPDATED_ERROR: 'Não foi possível atualizar um ou mais campos personalizados neste momento. Tentar novamente.',
	CUSTOM_FIELD_LABEL_SUCCESS_TITLE: 'Êxito na revisão da etiqueta de campos personalizados!',
	CUSTOM_FIELD_LABEL_SUCCESS_MESSAGE: 'Uma ou mais etiquetas de campos personalizados foram atualizadas com êxito.',
	CUSTOM_FIELD_LABEL_ERROR_TITLE: 'Erro na revisão da etiqueta de campos personalizados!',
	CUSTOM_FIELD_LABEL_ERROR_MESSAGE: 'Uma ou mais etiquetas de campos personalizados não puderam ser atualizadas neste momento. Tentar novamente.',
	TABLE_COLUMNS_CHANGE_SUCCESS_TITLE: 'Êxito na alteração das configurações da tabela de endpoint!',
	TABLE_COLUMNS_CHANGE_SUCCESS_MESSAGE: 'Configurações da tabela de endpoint foram atualizadas com êxito.',
	TABLE_COLUMNS_CHANGE_ERROR_TITLE: 'Erro na alteração das colunas da tabela de endpoint!',
	TABLE_COLUMNS_CHANGE_ERROR_MESSAGE: 'As colunas da tabela de endpoint não puderam ser atualizadas neste momento. Tentar novamente.',
	MC10515: 'Nome incorreto do arquivo',
	MC10516: 'Erro de dados do arquivo',
	MC10517: 'O arquivo não contém cabeçalho ou está vazio',
	DOWNLOAD_REPORT_ERROR_TITLE: 'Erro de download',
	DOWNLOAD_REPORT_ERROR_MESSAGE: 'Erro ao tentar baixar o arquivo',
	FETCH_REPORT_ERROR_TITLE: 'Sem dados',
	FETCH_REPORT_ERROR_MESSAGE: 'Não há dados que correspondam à solicitação pesquisa/filtro',
	MARK_READ: 'Marcar como lido',
	VALIDATION_FAILED: 'Falha na validação',
	ACTION_COMPLETED_SUCCESSFULLY: 'Ação concluída com êxito',
	ACTION_COULD_NOT_BE_COMPLETED: 'Esta ação não pôde ser concluída',

	// SINGLE ENDPOINT
	ENDPOINT_DETAILS: 'Detalhes de endpoint',
	DETAILS: 'Detalhes',
	DIAGNOSTIC: 'Diagnóstico',
	CHANGES: 'Alterações',
	SESSIONS: 'Sessões',
	END_USER: 'Usuário final',
	LIFE_CYCLE: 'Ciclo de vida',
	SEND_SMS: 'Enviar SMS',
	OTA: 'OTA',
	RATING: 'Tarifação',
	CARRIER_INFORMATION: 'Informações da operadora',
	M2M_PLATFORM_STATUS_DESCRIPTION: 'Descrição do status da plataforma M2M',
	VIEW_NOTE: 'Visualizar nota',
	SE_NOTES: 'Notas',
	CHANGE_NOTES: 'Alterar notas',
	ENTER_NOTE_HERE: 'Digitar a nota aqui (máx. de 2000 caracteres)',
	ADD_NOTE_BELOW: 'Adicionar nota abaixo:',
	CHANGING_NOTES: 'Alterando as notas',
	MULTIPLE_NOTES_CHANGE_ERROR: 'Não foi possível iniciar a alteração de múltiplos endpoints com êxito',
	CHANGING_NOTES_NOT_ALLOWED: 'Não é permitida a alteração de notas',
	ENDPOINTS_SUCCESSFULLY_CHANGED: 'Endpoints alterados com êxito',
	ENDPOINT_DETAILS_SECOND_CARD_NO_DATA: 'Os dados deste endpoint estão temporariamente indisponíveis',

	// DATA OVERVIEW
	ICCID: 'ICCID',
	IMSI: 'IMSI',
	NETWORK_OPERATOR: 'Operador de rede',
	NETWORK_COUNTRY: 'País da rede',
	MSISDN: 'MSISDN',
	DUAL_MODE: 'Modo dual',
	CONNECTED_DEVICE: 'Dispositivo conectado',
	IMEI: 'IMEI',
	STATUS: 'Status',
	STATUS_CHANGE_PENDING: 'Há uma atividade agendada para este endpoint',
	RATE_PLAN: 'Plano de tarifas',
	NETWORK_ENTITLEMENT: 'Direito de acesso à rede',
	SOURCE_PLATFORM: 'Plataforma M2M',
	SERVICE_PROVIDER: 'Plataforma M2M',
	NETWORK_ENTITLEMENT_ID: 'ID do direito de acesso à rede',

	// ENDPOINT STATUS
	CURRENT_ENDPOINT_STATUS: 'Status atual do endpoint',

	// Alterar parâmetro de SIM modal
	CHANGE_SIM_PARAMETER: 'Alterar parâmetro de SIM',
	SELECT_ATT_STATE: 'SELECIONAR ESTADO DA AT&T',
	CURRENT: 'ATUAL',

	BY: 'Por ',
	SCHEDULING: 'Agendando...',

	// EDIT TABLE MODAL
	EDIT_TABLE_PARAMETERS: 'Revisar parâmetros da tabela',
	EDIT_CUSTOM_FIELD_LABELS: 'Revisar etiquetas de campos personalizados',
	CUSTOM_LABEL: 'Etiqueta personalizada {labelIndex}',
	VALUE: 'Valor',
	SET_AS_REQUIRED: 'Definir conforme necessário',
	SELECT_A_MINIMUM_OF_4_TO_APPLY_TO_THE_TABLE: 'Selecionar pelo menos dois para aplicar na tabela. ',
	SELECTED: ' selecionado',
	CLEAR_ALL: 'Redefinir para o padrão',

	EOD: 'Enterprise on Demand (EOD)',
	ACC: 'AT&T Control Center (ACC)',
	GBCM: 'Speedcast',
	MC: 'IoT Console (IOT-C)',
	DCP: 'Plataforma de conexão de dispositivos (DCP)',
	CCIP: 'Programa de Integração de Centrais de Controle (CCIP)',
	AIS: 'AIS',
	TWM: 'Taiwan Mobile (TWM)',
	ATT: 'AT&T',
	AUTOMATION_ACC: 'ACC',
	AUTOMATION_DCP: 'DCP',
	// AT&T: "AT&T",
	WING: 'Nokia (WING)',
	VIVO: 'Central de controle da Vivo',

	eod: 'Enterprise on Demand (EOD)',
	acc: 'AT&T Control Center (ACC)',
	gbcm: 'Speedcast',
	mc: 'IoT Console (IOT-C)',
	dcp: 'Plataforma de conexão de dispositivos (DCP)',
	ccip: 'Programa de Integração de Centrais de Controle (CCIP)',
	ais: 'AIS',
	twm: 'Taiwan Mobile (TWM)',
	att: 'AT&T',
	// at&t: "AT&T",
	wing: 'Nokia (WING)',
	vivo: 'Central de controle da Vivo',

	SELECTED_FILTERS: 'Filtros selecionados',

	BY_WEEK: 'Por semana',
	BY_DAY: 'Por dia',
	BY_HOUR: 'Por hora',
	BYTES_SENT: 'MB enviados',
	BYTES_RECEIVED: 'MB recebidos',
	TOTAL_MINS: 'Total de minutos',
	monthly: 'Mensalmente',
	daily: 'Diariamente',
	MONTHLY: 'Mensalmente',
	DAILY: 'Diariamente',

	// ACTION BAR
	SHOWING: 'Mostrando ',
	OF: ' de ',

	// REPORTS
	TRANSFER: 'Transferir',
	USAGE: 'Uso',
	DOWNLOAD: 'Baixar',
	OTHER: 'Outro',

	// INPUT ERRORS
	REQUIRED: 'Necessário',
	INVALID_COMPANY_NAME: 'Nome da empresa inválido',
	INVALID_EMAIL_ADDRESS: 'Endereço de e-mail inválido',
	PHONE_MUST_BE_A_NUMBER: 'O telefone deve ser um número',
	WRONG_PHONE_FORMAT: 'Formato de telefone errado',
	ONLY_LETTERS_AND_NUMBERS_ARE_ALLOWED: 'São permitidos somente letras e números',
	ONLY_LETTERS_ARE_ALLOWED: 'São permitidas somente letras',
	ONLY_LETTERS_AND_SPACES_ARE_ALLOWED: 'São permitidos somente letras e espaços',
	ONLY_NUMBERS_ARE_ALLOWED: 'São permitidos somente números',
	ONLY_POSITIVE_NUMBERS_ARE_ALLOWED: 'São permitidos somente números maiores que 0',
	NUMBER_HAS_TO_BE_LESS_THAN: 'O número tem que ter menos de {propName}',
	NUMBER_HAS_TO_BE_LESS_THAN_OR_EQUAL_TO: 'Número deve ser menor ou igual a {propName}',
	NUMBER_HAS_TO_BE_GREATER_THAN: 'Número deve ser maior do que {propName}',
	NUMBER_HAS_TO_BE_GREATER_THAN_OR_EQUAL_TO: 'Número deve ser maior ou igual a {propName}',
	NUMBER_HAS_TO_BE_IN_INCREMENTAL_STEPS: 'Número deve ser em passos incrementais de {increment}',
	INVALID_FORMAT: 'Formato inválido',
	MAX_CHARACTERS_EXCEEDED: 'Limite de caracteres excedido',
	MUST_BE_LESS_THAN_OR_EQUAL_TO_CHARACTERS: 'Deve ser menor ou igual a {max} caracteres',
	MUST_BE_CHARACTERS_LONG: 'Deve ter {number} caracteres',
	NO_ONLY_WHITE_SPACE: 'Não pode ter somente espaços em branco',
	OUT_OF_RANGE: 'Fora da faixa',
	NOT_BEFORE: 'A data não pode ser anterior a {date}',
	INVALID_VERSION: 'Somente o formato major.minor.patch é permitido: p.ex., x.y.z',

	/* ------ OLD BUT STILL WORKING - DO NOT TOUCH - WORK IN PROGRESS --------- */
	ACCOUNT_OVERVIEW: 'Visão geral da conta',
	ACCOUNT_OVERVIEW_ADMIN_DASHBOARD: 'Painel do administrador de visão geral da conta',
	BILLING: 'Cobrança',

	LOG_OUT: 'Fazer log off',

	DATA_PLAN: 'Plano de dados',
	ACTIVESESSION: 'SESSÃO ATIVA',
	IPADDRESS: 'Endereço de IP',
	ANALYTICS: 'Dados de análise',
	NETWORK: 'REDE',

	ASSET_ID: 'ID de ativos',
	SESSION_START: 'Início da sessão',
	DATA_VOLUME: 'Volume de dados',
	DURATION: 'Duração (min)',
	'DATE&TIME': 'Data e hora',
	NOTE: 'Nota',
	LOGINTITLE: 'Logon',
	LOGINSUBTITLE: 'Digite seu e-mail e senha.',
	ENDPOINTS_INVENTORY_STATUS: 'STATUS DE INVENTÁRIO DE ENDPOINTS',
	INVENTORY_BY_CARRIER: 'INVENTÁRIO POR OPERADORA',
	INVENTORY_BY_COUNTRY: 'INVENTÁRIO POR PAÍS',
	ACCOUNT_USERS: 'USUÁRIOS DA CONTA',
	USER_AUTOMATION_STATUS: 'STATUS DE AUTOMAÇÃO DE USUÁRIOS',
	AUTOMATION_PROCESS_STATUS: 'STATUS DO PROCESSO DE AUTOMAÇÃO',
	NETWORK_TYPE: 'Tipo de rede',
	DEVICE_NAME: 'Nome do dispositivo',
	DEVICE_ID: 'ID do dispositivo',
	DEVICE_MODEL_NUMBER: 'Número de modelo do dispositivo',
	ROAMING_RESTRICTED: 'ROAMING RESTRITO',
	INVOICE_NUMBER: 'NÚMERO DA FATURA',
	DATE: 'Data',
	SCHEDULE_DATE: 'Data',
	ISSUED_BY: 'EMITIDO POR',
	PAYMENT_STATUS: 'STATUS DO PAGAMENTO',
	REPORT_NAME: 'Nome do relatório',
	DATE_CREATED: 'Data de criação',
	CREATED_BY: 'Criado por',
	PAY_NOW: 'PAGAR AGORA',
	EDIT_GRAPHS_VIEW: 'Revisar modo de exibição de gráficos',
	EDIT_ENDPOINTS_LIST_VIEW: 'Revisar exibição da lista de endpoints',
	CHANGE_LANGUAGE: 'Alterar idioma',
	CHANGE_LANGUAGE_ICON: 'EN',
	NOT_APPLICABLE_FOR_THIS_ENDPOINT: 'Não se aplica a este endpoint',
	CREATE_NEW_RULE: 'Criar nova regra',
	EDIT_RULE: 'Revisar regra',
	CREATE_NEW_RULE_EXP: 'A automação permite realizar ações em massa na sua conta.',
	CREATE_NEW_RULE_ACTION: 'Selecione o botão "Criar nova regra" para criar uma ação automatizada',
	RULE_NAME: 'Nome da regra',
	CATEGORY: 'Categoria',
	TRIGGER: 'Acionamento',
	ACTION: 'AÇÃO',
	BULK_ACTIONS: 'Ações em massa',
	MULTI_ENDPOINT_ACTIONS: 'Ações de endpoints múltiplos',
	SET_ENDPOINT_STATE: 'Definir estado do endpoint',
	CHANGE_DATA_PLAN: 'Alterar plano de dados',
	SWAP_OPERATOR: 'Trocar operador',
	MULTI_EDIT: 'Revisões múltiplas',
	SEARCH_FOR_RULES: 'Procurar regras',
	SEARCH_FOR_INVOICES: 'Procurar faturas',
	SEARCH_FOR_REPORTS: 'Procurar relatório por nome',
	SEARCH_ENDPOINTS: 'Procurar endpoints',
	PAYABLE_INVOICES: 'FATURAS A PAGAR',
	RECEIVED_PAYMENTS: 'PAGAMENTOS RECEBIDOS',
	PAYMENT_METHODS: 'MÉTODOS DE PAGAMENTO',
	BILLERS: 'COBRADORES',
	TIME_PERIOD: 'Período',
	FROM_DATEPICKER: 'De',
	TO_DATEPICKER: 'Para',
	ENDPOINT_UI_TOOLTIP: 'Número de identificação para Endpoint ou dispositivo',
	OPERATOR_TOOLTIP: 'Operador ao qual o endpoint e perfil pertencem',
	NETWORK_TYPE_TOOLTIP: 'Tecnologia de conectividade do operador',
	COUNTRY_TOOLTIP: 'País de origem do endpoint',
	ROAMING_RESTRICTED_TOOLTIP: 'Se o endpoint está restrito para roaming',
	STATUS_TOOLTIP: 'Estado atual do endpoint',
	INITIATE_RULE: 'INICIAR REGRA',
	NEW_AUTOMATION_RULE: 'NOVA REGRA DE AUTOMATIZAÇÃO',
	ENDPOINT_PROVISIONING: 'PROVISIONAMENTO DE ENDPOINT',
	ENDPOINT_PROFILE: 'PERFIL DE ENDPOINT',
	ENDPOINT_SECURITY: 'SEGURANÇA DE ENDPOINT',
	ENDPOINT_SUBSCRIPTION: 'ASSINATURA DE ENDPOINT',
	NEW_RULE: 'NOVA REGRA',
	CONDITION: 'CONDIÇÃO',
	ENDPOINT_RANGE: 'A faixa do endpoint é',
	RANGE_START: 'INÍCIO DA FAIXA',
	RANGE_END: 'TÉRMINO DA FAIXA',
	ASSIGN_ENDPOINT: 'Atribuir endpoint ao operador',
	OPERATOR_NAME: 'NOME DO OPERADOR',
	ACCOUNT: 'CONTA',
	ADD_ACTION: 'ADICIONAR AÇÃO',
	RUN_IMMEDIATELY: 'EXECUTAR IMEDIATAMENTE',
	TIME: 'HORA',
	SELECT_DATE: 'SELECIONAR DATA',
	SELECT_TIME: 'SELECIONAR HORA',
	EVENT_TRIGGER: 'ACIONA EVENTOS',
	EVENT_TYPE: 'TIPO DE EVENTO',
	EVENT_LOCATION: 'LOCAL DO EVENTO',
	DATA_PLAN_NAME: 'NOME DO PLANO DE DADOS',
	LOGIN_VALIDATION_WRONG_CREDENTIALS_HEADER: 'Credenciais erradas!',
	LOGIN_VALIDATION_WRONG_CREDENTIALS_BODY: 'A senha não corresponde ao e-mail fornecido.',
	EMAIL: 'E-mail',
	PASSWORD: 'senha',
	FORGOT_PASSWORD: 'Esqueceu a senha?',
	REMEMBER_ME: 'Lembre de mim',
	LOGIN_BUTTON: 'LOGON',
	BACK: 'Voltar',
	ENDPOINT_USAGE: 'Uso de endpoint',
	COUNTRY_AND_OPERATOR: 'País e Operador',
	PAYMENTS_HISTORY: 'Histórico de pagamentos',
	SPENDING_THIS_YEAR: 'Despesas deste ano',
	ENDPOINT_DATA: 'DADOS DE ENDPOINT',
	CURRENT_SESSION: 'SESSÃO ATUAL',
	VIEW_LESS: 'Visualizar menos',
	VIEW_MORE: 'Visualizar mais',
	ENDPOINT_STATUS: 'Status do endpoint',
	ACTIVATION_DATE: 'Data de ativação',
	PRIMARY_IMS: 'IMS principal',

	SERVICE_PROVIDER_MODE: 'MODO DE PROVEDOR DE SERVIÇO',
	SUPER_USER_MODE_MESSAGE: '{mode}: {companyFriendlyName}.',
	SUPER_USER_MODE_MOBILE: '{companyFriendlyName}',
	POLICY: 'Política',
	COMMUNICATION_PLAN: 'Plano de comunicação',
	COPY_RULE: 'Copiar regra',
	SECURE_ENDPOINT_ID: 'Proteger ID de endpoint',
	CUSTOMER: 'Cliente',
	INVENTORY_STATUS: 'STATUS DE INVENTÁRIO',
	RESOLUTION_NOT_SUPPORTED_FIRST_LINE: 'Visualização de tela pequena desativada na demonstração.',
	RESOLUTION_NOT_SUPPORTED_SECOND_LINE: 'Para melhores resultados, use o navegador de desktop do Chrome ou modo de tela inteira',
	CHECKING_MOVE_ELIGIBILITY: 'VERIFICANDO QUALIFICAÇÃO DE MUDANÇA',
	CHANGE_OPERATOR: 'ALTERAR OPERADOR',
	SCHEDULE_CHANGE: 'AGENDAR ALTERAÇÃO',
	SCHEDULE_CHANGE_BUTTON: 'Agendar alteração',
	INITIATE_CHANGE: 'Iniciar alteração',
	CONFIRM_CHANGE: 'Confirmar alteração',
	CONFIRM_SIM_STATE_CHANGE: 'Confirmar alteração do estado do SIM',
	IN_SESSION: 'NA SESSÃO',
	ENTITLEMENTS: 'Direitos de acesso',
	LOCALIZE_SERVICE: 'Localizar serviço',
	CHANGE_ENDPOINT_OPERATOR: 'ALTERAR OPERADOR DE ENDPOINT',
	PENDING_CHANGES_ENDPOINT: 'Há algumas alterações pendentes para este endpoint, iniciando em ',
	SEE_HISTORY_FOR_DETAILS: 'Ver os detalhes no histórico abaixo.',
	CHANGE_ENDPOINT_DATA_PLAN: 'Alteração do plano de dados de endpoint',
	IS_SELECTED: ' está selecionada.',
	PLEASE_SELECT_DIFFERENT_OPERATOR_TO_CHANGE_OPERATOR: 'Selecione um operador diferente abaixo para alterar o operador:',
	GENERATE_REPORT: 'GERAR RELATÓRIO',
	REPORT_TYPE: 'TIPO DE RELATÓRIOS',
	DATA_PLAN_SUMMARIES: 'Resumos do plano de dados',
	CHARGE_SUMMARIES: 'Resumos das cobranças',
	INVOICE_REPORT: 'Relatório de fatura',
	PRORATION_REPORT: 'Relatório de rateio',
	GROSS_ACTIVATIONS: 'Ativações brutas',
	MONTHLY_SUBSCRIBERS: 'Assinantes mensais',
	VIEW_YOUR_ACCOUT_SNAPSHOT: 'Visualizar instantâneo de sua conta.',
	LAUNCH_DASHBOARD: 'INICIAR PAINEL DE CONTROLE',
	VISUAL_DASHBOARD_VIEW_EXP: 'Visualização do painel de controle oferece uma ideia do status atual de sua conta e da atividade dos dispositivos.',
	VIEW_CURRENT_STATUS_AND_HISTORICAL_DATA: 'Visualizar status atual e dados históricos pela linha do tempo.',
	IF: 'SE',
	AND: 'E',
	OR: 'OU',
	TO: 'PARA',
	FROM: 'DE',
	INVOICES_STATUS: 'STATUS DAS FATURAS',
	filter_category_m2mAccount: 'M2mAccount',
	PLEASE_CONFIRM_ENDPOINT_DATA_CHANGE: 'Confirmar alteração de dados do endpoint.',
	SET_NEW_OPERATOR_AS_ACTIVE: 'DEFINIR NOVO OPERADOR COMO ATIVO',
	SET_NEW_PLAN_AS_ACTIVE: 'DEFINIR NOVO PLANO COMO ATIVO',
	PLEASE_SELECT_DATE_TO_SET_UP_YOUR_CHANGES_AND_CONFIRM: 'Selecionar data para configurar suas alterações e confirmar.',
	DATE_REQUIRED: 'Data (obrigatória)',
	ADDITIONAL_COMMENT: 'Comentário adicional',
	TYPE_YOUR_CUSTOM_NOTES_HERE: 'Digitar suas notas personalizadas aqui…',
	CONFIRM_CHANGES: 'Confirmar alterações',
	CHECKING_MOVE_ELIGIBILITY_LOADER: 'Verificando qualificação de mudança',
	ELIGIBLE: 'QUALIFICADO',
	CHECKING: 'VERIFICANDO',
	PLEASE_SELECT_DIFFERENT_DATA_PLAN_TO_CHANGE_DATA_PLAN: 'Selecionar um plano diferente abaixo para alterar plano de dados:',
	CONFIRM: 'Confirmar',
	ENDPOINT_RANGE_IS: 'Faixa de endpoint é:',
	ENDPOINT_ID_IS: 'ID de endpoint é:',
	ENDPOINT_ID_IS_GREATER_THAN: 'ID de endpoint é maior que:',
	ENDPOINT_ID_IS_LESS_THAN: 'ID de endpoint é menor que:',
	ENDPOINT_STATE_CHANGE: 'Alteração do estado de endpoint',
	ENDPOINT_DATA_PLAN: 'Plano de dados de endpoint',
	ENDPOINT_SECURITY_POLICY_CHANGE: 'Alteração da política de segurança de endpoint',
	ASSIGN_ENDPOINT_TO_OPERATOR: 'Atribuir endpoint ao operador',
	MOVE_TO_ANOTHER_ACCOUNT: 'Passar para outra conta',
	ADD_CUSTOM_FIELD_TO_ENDPOINT_DETAILS: 'Adicionar campo personalizado para detalhes de endpoint',
	CREATE_NEW_ACTION: 'Criar nova ação',
	AT_T: 'IoT da AT&T (ACC)',
	AT_T_EOD: 'IoT da AT&T (ACC)',
	GLOBECOMM: 'Speedcast',
	TAIWAN_MOBILE: 'Taiwan Mobile (TWM)',
	'0001': '***0001',
	'0002': '***0002',
	'0003': '***0003',
	'0004': '***0004',
	OTA_EVENT: 'Evento da OTA',
	WI_FI: 'Wi-Fi',
	THAILAND: 'Tailândia (THA)',
	TAIWAN: 'Taiwan (TWN)',
	UNITED_STATES: 'Estados Unidos (USA)',
	DATA_PLAN_S: 'Plano de dados S',
	DATA_PLAN_M: 'Plano de dados M',
	DATA_PLAN_L: 'Plano de dados L',
	DATA_PLAN_XL: 'Plano de dados XL',
	// TO DO: Atualizar status do endpoint para corrigir valores
	ACTIVATED: 'Ativado',
	'PRONTO PARA TESTE': 'Pronto para teste',
	DEACTIVATED: 'Desativado',
	PURGED: 'Limpo',
	RETIRED: 'Aposentado',
	//
	USER: 'Usuário',
	ON: 'em',
	MADE: 'feito',
	CHANGE_STATUS_ACTION: 'Alterar Status',
	CHANGE_DATA_PLAN_ACTION: 'Alterar plano de dados',
	LOCALIZE_SERVICE_ACTION: 'Serviço localizado',
	IMSI_TRANSFER: 'Status da transferência de IMSI',
	TRANSFER_STATUS: 'Status da transferência de IMSI',
	COUNTRY_OPERATOR: 'País da rede',

	PROFILE_NAME: 'Nome do perfil',
	NUMBER: 'Número',
	DATE_MODIFIED: 'Data da modificação',

	ENTITLEMENT_PLAN_ID: 'ID do plano de qualificação',
	ENTITLEMENT_PLAN_NAME: 'Nome do plano de qualificação',
	ACCOUNT_ID: 'ID da conta',
	ACCOUNT_I_D: 'ID da conta',
	IMEI_NAME: 'Nome do IMEI',
	VIN: 'VIN',
	CHIP_ID: 'ID do chip',
	URL_SP: 'url SP',
	ADD_SINGLE_ENDPOINT: 'Adicionar endpoint único',
	UPLOAD_ICCID_BULK_FILE: 'Carregar arquivo em massa do ICCID',
	DOWNLOAD_BULK_FILE: 'Baixar arquivo em massa',
	ENDPOINTS_SELECTED: 'endpoints selecionados',
	SELECTED_ENDPOINTS: 'endpoints selecionados',
	NEW_STATUS: 'Novo status',
	MULTIPLE_ENDPOINT_EDIT: 'Revisão de múltiplos endpoints',
	CLEAR_VALUES_FOR_ALL_ENDPOINTS: 'Limpar os valores de todos endpoints',
	KEEP_ORIGINAL_ENDPOINT_VALUES: 'Manter os valores originais de endpoints',
	ACTIVE: 'Ativado',
	INACTIVE: 'Inativo',
	TERMINATE: 'Encerrado',
	TEST_READY: 'Pronto para teste',
	TEST: 'Testar',
	SELECT_OPTION_OR_TYPE_IN: 'Selecionar opção ou digitar em',
	CLEAR_ALL_VALUES_FOR_ENDPOINTS: 'Limpar todos os valores de endpoints',
	BULK_STATUS_UPDATE: 'Atualização de status em massa',
	TOTAL_ENDPOINTS: 'Endpoints totais',
	TOTAL_FILES: 'Arquivos totais',
	ENDPOINT_STATUS_UPDATED: 'Status de endpoints atualizados',
	MODIFIED_ENDPOINTS: 'Endpoints modificados',
	SOMETHING_WENT_WRONG: 'Ocorreu um problema',
	ENDPOINT_STATUS_IS_UPDATING: 'Status do endpoint sendo atualizado',
	PLEASE_WAIT: 'Aguarde',
	SWAP_SIM: 'Trocar SIM',
	SWAP_SIMS: 'Trocar SIMS',
	SIM: 'SIM',
	NEW: 'Novo',
	OLD: 'Antigo',
	CHANGE_ORDER: 'Pedido de alteração',
	ITEM: 'Item',
	ADDITIONAL_INFO: 'Informação adicional',
	UPDATE_LABEL: 'Atualizar etiqueta',
	ENDPOINT_LABEL_IS_UPDATING: 'Atualizando etiqueta de endpoint',
	ENDPOINT_LABEL_UPDATED: 'Etiqueta de endpoint atualizada',
	ENDPOINT_FIELDS_ARE_UPDATING: 'Atualizando campos de endpoints',
	ENDPOINT_FIELDS_UPDATED: 'Campos de endpoints atualizados',
	EDIT_CUSTOM_FIELDS: 'Revisar campos personalizados',
	EDIT_CUSTOM_LABEL: 'Revisar etiqueta personalizada',
	CHANGE_ENTITLEMENT: 'Alterar direito de acesso à rede',
	CHANGE_NETWORK_ENTITLEMENT: 'Alterar direito de acesso à rede',
	CURRENT_NETWORK_ENTITLEMENT: 'Direito de acesso à rede atual',
	NETWORK_ENTITLEMENT_UPDATED_SUCCESSFULLY: 'Direito de acesso à rede atualizado com êxito!',
	LIST_VIEW_UPDATED: 'Exibição da lista de endpoints atualizada',
	NETWORK_ENTITLEMENT_UPDATING: 'Atualizando direito de acesso à rede. Aguarde',
	LIST_VIEW_UPDATING: 'Atualizando exibição da lista de endpoints. Aguarde',
	NEW_NETWORK_ENTITLEMENT: 'Novo direito de acesso à rede',
	NEW_NETWORK_ENTITLEMENT_DETAILS: 'Detalhes do novo direito de acesso à rede',
	TABLE_COLUMNS: 'Selecionar o número de colunas para exibição na tabela.',
	TABLE_COLUMNS_OR: 'Selecionar o número de colunas para exibição na tabela ou',
	COLUMN: 'Coluna',
	COLUMNS: 'Colunas',
	SELECT: 'Selecionar',
	DATA_TYPE: 'tipo de dados',
	ADVANCED_SEARCH: 'Pesquisa avançada',
	SEARCH_RESULTS: 'Resultados da pesquisa',
	SUBMIT: 'Enviar',
	SEARCH_PARAMETER: 'Pesquisar parâmetro',
	ADD_ANOTHER_SEARCH_PARAMETER: 'Adicionar outro parâmetro de pesquisa',
	STATUS_DATE_RANGE: 'Faixa de datas do status',
	SIM_STATUS_STATE: 'Status de SIM (estado)',
	NEW_PARAMETER_ACTION: 'E / Ou',
	PLEASE_SELECT_VALUES_TO_FILTER_ENDPOINTS: 'Selecionar a partir dos parâmetros disponíveis e depois digitar os critérios para filtrar endpoints por.',
	RESET_SEARCH: 'Redefinir pesquisa',
	QUICK_SEARCH: 'Pesquisa rápida',
	ORIGINAL_SETTINGS: 'Redefinir configurações originais',
	MONTH: 'Mês',
	DAY: 'Dia',
	REFRESH_ENDPOINTS_PAGE: 'Atualizar tabela/página de endpoints',
	CLEAR_SELECTED_FILTERS_TOOLTIP: 'Limpar filtros selecionados',
	SELECT_FILTERS_TOOLTIP: 'Selecionar filtros',
	EDIT_TABLE_PARAMETERS_TOOLTIP: 'Revisar parâmetros da tabela',

	REPORT_DATE: 'Data',

	START_NEW_SEARCH: 'Iniciar nova pesquisa',
	SUCCESS: 'Êxito',
	FAILED: 'Falhou',
	RESULT_ACTION: 'Ação',
	REPORT_DETAILS: 'Detalhes do relatório',
	REPORT_LIST: 'Lista de relatórios em',
	VIEW_DETAILS: 'Visualizar detalhes',
	DOWNLOAD_PDF: 'Baixar',
	FILE_NAME: 'Nome do arquivo',
	BATCH_FILES: 'Arquivos em lotes',
	BATCH_FILE_NAME: 'Nome do arquivo em lotes',
	BATCH_FILE_ACTIONS: 'Ações do arquivo em lotes',
	BATCH_FILE_UPLOAD: 'Ações em massa',
	CHANGE_SIM_STATE: 'Alterar estado de SIM',
	SELECT_SIM_STATE: 'Selecionar estado de SIM',
	SIMS_SELECTED: 'SIMs selecionados',
	SIM_SELECTED: 'SIM selecionado',
	ONCE_YOU_INITIATE_CHANGE: 'Depois de iniciar esta alteração, os SIMs selecionados serão',
	PROCEED_ACTION: 'Deseja continuar com esta ação?',
	CAN_SCHEDULE: 'Ou você pode agendar a alteração para',
	CURRENT_STATES: 'Estados atuais',
	SCHEDULE_SUCCESSFUL: 'Alteração do estado de múltiplos SIM foi agendada com êxito!',
	SIM_STATE_CHANGE_FOR: 'Alteração do estado do SIM para',
	SIMS_IS_SUCCESSFULLY_SCHEDULED_FOR: 'SIMS agendados com êxito para',
	SIMS_SUCCESSFULLY_REQUESTED: 'Alteração do status dos SIMS solicitada com êxito!',
	MULTIPLE_SIM_STATE_CHANGE_SCHEDULE_ERROR: 'Não foi possível agendar alteração do estado de múltiplos SIMs com êxito!',
	MULTIPLE_SIM_STATE_CHANGE_ERROR: 'Não foi possível solicitar alteração do estado de múltiplos SIMs com êxito!',
	SELECTED_TIP: 'Dica: Se você selecionou SIM que se encontrem em diferentes estados, só será possível alterar todos os SIMS selecionados para um estado no qual esteja disponível.',
	VIEW_RULES: 'Visualizar regras da alteração do estado para esta conta',
	BATCH_FILE_UPLOADING: 'Carregando arquivo em lotes',
	DATE_UPLOADED: 'Data carregada',
	SIMPLE_SEARCH: 'Pesquisa simples',
	NUMBER_OF_COLUMNS: 'Número de colunas',
	SELECT_SEARCH_PARAMETER: 'Selecionar parâmetro de pesquisa',

	DROPDOWN_BACK_TO_BUSINESS: 'Voltar à empresa',
	'DROPDOWN_CONTACT_AT&T': 'Contatar a IoT da AT&T',
	DROPDOWN_MC: 'IoT Console',
	DROPDOWN_M2X: 'M2X',
	DROPDOWN_FD: 'Designer de fluxo',
	DROPDOWN_SK: 'Kit de Iniciante na IoT',
	DROPDOWN_DP: 'Planos de dados da IoT',
	MY_INFORMATION: 'Minhas informações',
	MY_APPLICATIONS: 'Meus aplicativos',
	TOPBAR_IDENTITY: 'AT&T IoT Identity',
	ERROR: 'Erro',
	PAGE_NOT_FOUND_MESSAGE: 'Não conseguimos encontrar a página que você está procurando.',
	'RETURN TO HOME PAGE': 'Voltar à Página inicial',
	INTERNAL_SERVER_ERROR: 'Erro de servidor interno',
	ACTIVE_ENDPOINT_PROFILE: 'PERFIL DE ENDPOINT ATIVO',
	CREATE_OR_EDIT: 'Criar ou revisar etiqueta personalizada para este endpoint',
	CREATE_CUSTOM_LABELS: 'Criar etiquetas personalizadas',
	SNAP: 'Ajustar',
	SNAP_ERROR_MESSAGE: 'Ocorreu um erro desconhecido. Se o erro persistir, entre em contato com o suporte.',

	// definições dos detalhes do relatório
	REPORT_DETAIL_DEFINITIONS: 'DEFINIÇÕES DOS DETALHES DO RELATÓRIO',
	REPORT_DETAIL_DEFINITIONS_MODAL_TITLE: 'Definições dos detalhes do relatório',
	REPORT_DETAIL_DEFINITIONS_DESC: 'Selecionar o botão abaixo para visualizar todas as definições dos detalhes do relatório.',
	VIEW_DEFINITIONS_BUTTON: 'VISUALIZAR DEFINIÇÕES',

	// tipos de definições
	'DEFINIÇÃO DE DETALHES DAS SESSÕES DE DADOS': 'Definição de detalhes das sessões de dados',
	'CAMPOS XML COM DETALHES SOBRE COBRANÇAS DE IMSI': 'Campos XML com detalhes sobre cobranças de IMSI',
	'DEFINIÇÕES DE DETALHES DE IMSI': 'Definições de detalhes de IMSI',
	'DETALHES DE USO DE LBS': 'Detalhes de uso de LBS',
	'DEFINIÇÕES DOS DETALHES DE LAGG DE DADOS': 'Definições dos detalhes de Lagg de dados',
	'DEFINIÇÕES DOS DETALHES DE SMS': 'Definições dos detalhes de SMS',
	'DEFINIÇÕES DE DETALHES DOS IMPOSTOS': 'Definições de detalhes dos impostos',
	'DEFINIÇÕES DOS DETALHES DE ATIVIDADES DO SIM': 'Definições dos detalhes de atividades do SIM',

	// Definições dos detalhes de Lagg de dados - CHAVES -------------
	CUST_ID_KEY: 'CUST_ID',
	IMSI_KEY: 'IMSI',
	ICCID_KEY: 'ICCID',
	MSISDN_KEY: 'MSISDN',
	ON_NET_KB_USAGE_KEY: 'ON_NET_KB_USAGE',
	ROAMING_KB_USAGE_KEY: 'ROAMING_KB_USAGE',
	CAN_KB_USAGE_KEY: 'CAN_KB_USAGE',
	INT_KB_ROUNDED_KEY: 'INT_KB_ROUNDED',
	TOTAL_KB_USAGE_KEY: 'TOTAL_KB_USAGE',

	// Definições dos detalhes de Lagg de dados - DESCRIÇÕES
	CUST_ID_DESC: 'ID do cliente de EOD.',
	IMSI_DESC: 'International Mobile Station Identifier. Este é o número que a rede usa para identificar o usuário.',
	ICCID_DESC: 'Integrated Circuit Card Identifier. Este é o número impresso no cartão SIM.',
	MSISDN_DESC: 'Mobile Station ISDN Number (número do telefone) atribuído para aquele SIM.',
	ON_NET_KB_USAGE_DESC: 'Uso total na rede (KB)',
	ROAMING_KB_USAGE_DESC: 'Uso doméstico total fora da rede (KB).',
	CAN_KB_USAGE_DESC: 'Uso total no Canadá (KB).',
	INT_KB_ROUNDED_DESC: 'Uso internacional total (KB).',
	TOTAL_KB_USAGE_DESC: 'Uso total (KB).',

	// Definição de detalhes das sessões de dados - CHAVES -------------
	IMPORT_DATE_KEY: 'Importar data',
	// IMSI - uso já definiu chave-desc
	// ICCID - uso já definiu chave-desc
	GGSN_IP_KEY: 'IP DE GGSN',
	SGSN_IP_KEY: 'IP DE SGSN',
	APN_NAME_KEY: 'NOME DO APN',
	PDP_IP_KEY: 'IP DE PDP',
	UPLINK_VOLUME_KEY: 'VOLUME DE UPLINK',
	DOWNLINK_VOLUME_KEY: 'VOLUME DE DOWNLINK',
	TRANSACTION_START_TIME_KEY: 'HORÁRIO DE INÍCIO DA TRANSAÇÃO',
	DURATION_KEY: 'DURAÇÃO',
	SERVED_MSISDN_KEY: 'MSISDN ATENDIDO',
	BID_KEY: 'BID',
	SERVING_LOCATION_KEY: 'LOCAL DO SERVIÇO',
	NETWORK_KEY: 'REDE',
	ROUNDING_DATE_KEY: 'DATA APROXIMADA',

	// Definição de detalhes das sessões de dados - DESCRIÇÕES
	IMPORT_DATE_DESC: 'Date e hora em que EOD recebeu este registro. É usado para determinar a data de cobrança na qual esse registro será aplicado.',
	// IMSI - uso já definiu chave-desc
	// ICCID - uso já definiu chave-desc
	GGSN_IP_DESC: 'Nó de suporte de serviço de rádio de pacote geral (GPRS) de Gateway. O dispositivo que fica entre a rede móvel pública terrestre (PLMN) e redes externas para oferecer gateway de borda como segurança e contabilidade.',
	SGSN_IP_DESC: 'Nó de suporte de serviço de rádio de pacote geral (GPRS) de serviço. O dispositivo que fica no mesmo nível hierárquico que o centro de comutação móvel/registro de localização do visitante (MSC/VLR) para realizar a gestão de mobilidade e o link lógico para terminais móveis.',
	APN_NAME_DESC: 'O nome do APN que foi usado por este dispositivo para esta transação.',
	PDP_IP_DESC: 'O IP atribuído ao dispositivo por ocasião da transação.',
	UPLINK_VOLUME_DESC: 'O número de bytes (em octetos) que passou deste dispositivo para este registro de detalhes.',
	DOWNLINK_VOLUME_DESC: 'O número de bytes (em octetos) que passou deste dispositivo para este registro de detalhes.',
	TRANSACTION_START_TIME_DESC: 'O horário de início efetivo da transação. Esta data será diferente da "Data de importação" e "Data de roteamento" dependendo de quando o registro tenha sido recebido pelo EOD.',
	DURATION_DESC: 'O temo (em segundos) para aquele registro de dados.',
	SERVED_MSISDN_DESC: 'O Mobile Station ISDN Number (número do telefone) atribuído para aquele SIM.',
	BID_DESC: 'Identificador da cobrança. É utilizado pela EOD para determinar o mercado no qual ocorreu a transação.',
	SERVING_LOCATION_DESC: 'O nome do local onde ocorreu este registro de dados.',
	NETWORK_DESC: 'Cingular, Roaming/Fora da rede, Internacional ou Canadá.',
	ROUNDING_DATE_DESC: 'A data usada pela EOD para determinar com que outros registros este registro será agrupado para calcular o arredondamento diário de quilobytes.',
	SESSION_HISTORY_DCP_NOT_APPLICABLE: 'Não aplicável.',

	// Campos XML com detalhes sobre cobranças de IMSI - CHAVES E DESC ------------
	CUSTOMER_KEY: 'CLIENTE',
	CUSTOMER_DESC: 'Prefixo do cliente',
	STARTCYCLE_KEY: 'STARTCYCLE',
	STARTCYCLE_DECS: 'Início do ciclo de cobrança',
	ENDCYCLE_KEY: 'ENDCYCLE',
	ENDCYCLE_DESC: 'Término do ciclo de cobrança',
	WCO_KEY: 'WCO',
	WCO_DESC: 'Oferta de conectividade sem fio',
	EQUIPMENTORDERS_KEY: 'EQUIPMENTORDERS',
	EQUIPMENTORDERS_DESC: 'Pedidos de equipamentos',
	ACCOUNTOCC_KEY: 'ACCOUNTOCC',
	ACCOUNTOCC_DESC: 'Outras cobranças e crédito no nível da conta (taxas e sobretaxas)',
	ACCOUNTTAXES_KEY: 'ACCOUNTTAXES',
	ACCOUNTTAXES_DESC: 'Impostos e tarifas governamentais (taxas somente) no nível da conta',
	// ICCID - REUSO
	// IMSI - REUSO
	// MSISDN - REUSO
	RATEPLAN_KEY: 'RATEPLAN',
	RATEPLAN_DESC: 'Plano de tarifas sendo cobrado.',
	ACTIVATIONSTATE_KEY: 'ACTIVATIONSTATE',
	ACTIVATIONSTATE_DESC: 'Estado do dispositivo.',
	CUSTFILED1_KEY: 'CUSTFILED1',
	CUSTFILED1_DESC: 'Campo do cliente 1',
	CUSTFILED2_KEY: 'CUSTFILED2',
	CUSTFILED2_DESC: 'Campo do cliente 2',
	CUSTFILED3_KEY: 'CUSTFILED3',
	CUSTFILED3_DESC: 'Campo do cliente 3',
	CUSTFILED4_KEY: 'CUSTFILED4',
	CUSTFILED4_DESC: 'Campo do cliente 4',
	CUSTFILED5_KEY: 'CUSTFILED5',
	CUSTFILED5_DESC: 'Campo do cliente 5',
	ATTPOINTER_KEY: 'ATTPOINTER',
	ATTPOINTER_DESC: 'Indicador interno',
	CUSTPOINTER_KEY: 'CUSTPOINTER',
	CUSTPOINTER_DESC: 'Indicador do cliente',
	DATAMRC_KEY: 'DATAMRC',
	DATAMRC_DESC: 'MRC de dados',
	DATAUSAGE_KEY: 'DATAUSAGE',
	DATAUSAGE_DESC: 'Soma de todas as cobranças pelo uso de dados',
	SMSMRC_KEY: 'SMSMRC',
	SMSMRC_DESC: 'MRC de SMS',
	SMSUSAGE_KEY: 'SMSUSAGE',
	SMSUSAGE_DESC: 'Soma de todas as cobranças pelo uso de SMS – excluindo cobrança de SMS em massa',
	VOICEMRC_KEY: 'VOICEMRC',
	VOICEMRC_DESC: 'MRC de voz',
	VOICEUSAGE_KEY: 'VOICEUSAGE',
	VOICEUSAGE_DESC: 'Soma de todas as cobranças pelo uso de voz',
	DEVICEACTIVITYFEE_KEY: 'DEVICEACTIVITYFEE',
	DEVICEACTIVITYFEE_DESC: 'Tarifas do dispositivo (tarifas de ativação, tarifas de desativação, tarifas de reativação, tarifa única de alteração do plano)',
	OCC_KEY: 'OCC',
	OCC_DESC: 'Outras cobranças e créditos no nível do assinante (somente taxas e sobretaxas)',
	TAXES_KEY: 'IMPOSTOS',
	TAXES_DESC: 'Impostos e taxas governamentais (impostos somente) no nível do assinante',

	// VALOR DAS CHAVES DO DETALHE IMSI -----------------------
	// ICCID - REUSO
	// MSISDN - REUSO
	PLAN_CODE_KEY: 'PLAN_CODE',
	PLAN_CODE_DESC: 'O código do plano atribuído para cada SIM pelo cliente.',
	ACT_ID_KEY: 'ACT_ID',
	ACT_ID_DESC: 'A ação realizada pelo cliente para cada SIM (1 = Ativar, 2 = Desativar)',
	INCLUDED_USAGE_KEY: 'INCLUDED_USAGE',
	INCLUDED_USAGE_DESC: 'Uso total incluído (KB)',
	// ON_NET_KB_USAGE - REUSO
	// ROAMING_KB_USAGE - REUSO
	// CAN_KB_USAGE - REUSO
	// INT_KB_ROUNDED - REUSO
	// TOTAL_KB_USAGE - REUSO
	ACCESS_CHG_KEY: 'ACCESS_CHG',
	ACCESS_CHG_DESC: 'Cobrança de acesso mensal recorrente.',
	ACTIVATION_CHG_KEY: 'ACTIVATION_CHG',
	ACTIVATION_CHG_DESC: 'Cobrança de ativação.',
	BEGPERIOD_KEY: 'BEGPERIOD',
	BEGPERIOD_DESC: 'Início do ciclo de cobrança',
	ENDPERIOD_KEY: 'ENDPERIOD',
	ENDPERIOD_DESC: 'Término do ciclo de cobrança',
	CUST_PREFIX_KEY: 'CUST_PREFIX',
	CUST_PREFIX_DESC: 'Identificador do cliente.',
	CUST_FIELD_1_KEY: 'CUST_FIELD_1',
	CUST_FIELD_1_DESC: 'Campo do cliente 1.',
	CUST_FIELD_2_KEY: 'CUST_FIELD_2',
	CUST_FIELD_2_DESC: 'Campo do cliente 2.',
	CUST_FIELD_3_KEY: 'CUST_FIELD_3',
	CUST_FIELD_3_DESC: 'Campo do cliente 3.',
	CUST_FIELD_4_KEY: 'CUST_FIELD_4',
	CUST_FIELD_4_DESC: 'Campo do cliente 4.',
	CUST_FIELD_5_KEY: 'CUST_FIELD_5',
	CUST_FIELD_5_DESC: 'Campo do cliente 5.',
	INTERNAL_POINTER_KEY: 'INTERNAL POINTER',
	INTERNAL_POINTER_DESC: 'Indicador interno da AT&T.',
	CUSTOMER_POINTER_KEY: 'CUSTOMER POINTER',
	CUSTOMER_POINTER_DESC: 'Indicador do cliente.',

	// LBS USAGE DEFINITION KEYS-VALUE
	RECORDINGENTITY_KEY: 'RECORDINGENTITY',
	RECORDINGENTITY_DESC: 'Indicador exclusivo para registros do agregador. (Reservado para uso do agregador).',
	LCSCLIENTEXTERNALID_KEY: 'LCSCLIENTEXTERNALID',
	LCSCLIENTEXTERNALID_DESC: 'ID externa de cliente de LCS (sem uso neste momento).',
	TARGETIMSI_KEY: 'TARGETIMSI',
	TARGETIMSI_DESC: 'International Mobile Station Identifier. Este é o número que a rede usa para identificar o usuário.',
	TARGETMSISDN_KEY: 'TARGETMSISDN',
	TARGETMSISDN_DESC: 'MSDN localizado se SIM da ATT, XXXXXXXXXX se SIM não for da AT&T (o número de ISDN da estação móvel - número de telefone atribuído àquele SIM).',
	RESULTCODE_KEY: 'RESULTCODE',
	RESULTCODE_DESC: 'Êxito ou falha em localizar. 0 = êxito/ somente 0 e 1 usados para iniciar, já que os códigos dos resultados de Agg e Med podem ser diferentes.',
	UTCRECORDOPENINGTIME_KEY: 'UTCRECORDOPENINGTIME',
	UTCRECORDOPENINGTIME_DESC: 'Não está disponível para uso neste momento (hora UTC de abertura do registro).',
	INFORMATION_1_KEY: 'INFORMATION_1',
	INFORMATION_1_DESC: 'EAG de 16 dígitos da ID do aplicativo emitiu AppID (“1” na posição2 = Technocom, “2” na posição2 = LocAid Id)',
	INFORMATION_3_KEY: 'INFORMATION_3',
	INFORMATION_3_DESC: 'Exatidão solicitada (Horizontal): Deseja-se exatidão em metros. <=800 é AGPS. (800 para denotar solicitação de AGPS ou 801 para denotar solicitação de CID/ECID para localizações bem-sucedidas que não são da ATT e 0 para falhas (como pré-resposta)).',
	INFORMATION_4_KEY: 'INFORMATION_4 ',
	INFORMATION_4_DESC: 'Exatidão (Horizontal) retornada: Exatidão em metros efetivamente retornada. (800 para denotar solicitação de AGPS ou 801 para denotar solicitação de CID/ECID para localizações bem-sucedidas que não são da ATT e 0 para falhas (como por resposta)).',
	PART_DATE_KEY: 'PART_DATE',
	PART_DATE_DESC: 'Data da entrada no arquivo de transmissão de CDR por agreg. (PST: AAAA-MM-DD HH:mm:ss)',

	// DEFINIÇÕES DE CHAMADA SMS - CHAVES E DESC
	// IMSI
	// SERVED MSISDN
	SMS_TYPE_KEY: 'TIPO DE SMS',
	SMS_TYPE_DESC: 'Originada em dispositivo móvel (MO) ou encerrada por dispositivo móvel (MT).',
	CALLED_NUMBER_KEY: 'NÚMERO CHAMADO',
	CALLED_NUMBER_DESC: 'O número que foi discado.',
	START_TIME_KEY: 'HORÁRIO DE INÍCIO',
	START_TIME_DESC: 'Data e hora em que ocorreu a transação.',
	// NETWORK
	PLMN_KEY: 'PLMN',
	PLMN_DESC: 'Lista de rede móvel terrestre pública é um identificador de cobrança usado por EOD para determinar o mercado onde ocorreu a transação.',
	CARRIER_KEY: 'OPERADORA',
	CARRIER_DESC: 'Proprietário do mercado de mobilidade para a PLMN associada.',

	// DEFINIÇÕES DE CHAMADA SIM - CHAVES E DESC
	// ICCID
	// MSISDN
	ACTION_DATE_KEY: 'ACTION_DATE',
	ACTION_DATE_DESC: 'Data e hora em que a ação foi concluída.',
	ACT_KEY: 'ACT',
	ACT_DESC: 'A ação realizada pelo cliente para cada SIM (ACTV = Ativar, KILL = Desativar, CHNG = Alteração no perfil da rede, RATE = Alteração no plano de tarifas, CPPU = Alterar local principal da utilização).',
	PLAN_CODE_KEY_2: 'PLAN_CODE',
	PLAN_CODE_DESC_2: 'O código alfabético do plano usado para esta transação.',
	LOGIN_KEY: 'LOGIN',
	LOGIN_DESC: 'Usuário associado com a transação.',

	// DEFINIÇÕES DE DETALHES DOS IMPOSTOS - CHAVES E DESC
	PPU_ADDRES_KEY: 'PPU_ADDRES',
	PPU_ADDRES_DESC: 'Local de utilização principal – Onde o cliente utiliza primordialmente seu(s) serviço(s).',
	SERVICE_KEY: 'SERVIÇO',
	SERVICE_DESC: 'O produto ou oferta tributados.',
	INVOICE_SECTION_KEY: 'SEÇÃO DA FATURA',
	INVOICE_SECTION_DESC: 'TX – Tarifas e impostos governamentais OC – Outras cobranças e créditos.',
	AUTHORITY_LEVEL_KEY: 'NÍVEL DE AUTORIDADE',
	AUTHORITY_LEVEL_DESC: 'Nível associado com o texto da fatura F – Federal S – Estadual Co – Comarca Ci – Cidade DCo – País do distrito DCi – Cidade do distrito',
	INVOICE_TEXT_KEY: 'TEXTO DA FATURA',
	INVOICE_TEXT_DESC: 'Texto associado com a cobrança impressa na fatura do cliente.',
	CHARGE_AMMOUNT_KEY: 'VALOR DA COBRANÇA',
	CHARGE_AMMOUNT_DESC: 'O valor sobre o qual foi aplicado impostos.',
	AMMOUNT_KEY: 'VALOR',
	AMMOUNT_DESC: 'O valor de imposto computado para uma cobrança tributável associada.',
	BATCH_FORMATS: 'Formatos do lote',

	ACTIVATION: 'Ativação',
	TERMINATION: 'Rescisão',
	NETWORK_ENTITLEMENT_CHANGE: 'Alteração no direito de acesso à rede',
	SIM_SWAP: 'Troca de SIM',
	CUSTOM_FIELD_UPDATES: 'Atualizações de campos personalizados',
	CUSTOM_FIELD_VALUE: 'Valor do campo personalizado',
	BATCH_FILE_UPLOAD_SUCCESS: 'Arquivos em lotes recebidos com êxito!',
	BATCH_FILE_UPLOAD_SUCCESS_MESSAGE: 'As alterações estão pendentes enquanto o sistema conclui as atualizações',
	BATCH_FILE_SYSTEM_ERROR: 'Erro no sistema de arquivo em lotes!',
	BATCH_FILE_SYSTEM_ERROR_MESSAGE: 'Ocorreu um problema no processamento de atualizações. Tente de novo mais tarde.',
	TYPE_IN_OR_SELECT: 'Digitar um novo valor ou selecionar uma opção do menu suspenso para alterar o parâmetro de endpoint específico.',

	SUBMITTED_STATUS: 'Enviado',
	SCHEDULED_STATUS: 'Agendado',
	IN_PROGRESS_STATUS: 'Em andamento',
	COMPLETED_STATUS: 'Concluído',
	'IN-PROGRESS_STATUS': 'Em andamento',
	COMPLETED: 'Concluído',
	CANCELLED: 'Cancelado',
	ERROR_STATUS: 'Erro',

	FILE_NAME_CANNOT_CONTAIN_SPACES: 'O nome do arquivo não pode conter espaços',
	DRAG_FILE_OR_CLICK_TO_UPLOAD: 'Arrastar o arquivo ou clicar para carregar',
	FORBIDDEN_ACCESS: 'Acesso proibido',
	REQUEST_TIMED_OUT: 'Solicitação atingiu tempo limite',
	TERMINATE_ACTION: 'Encerrar SIMS',
	ACTIVATE_ACTION: 'Ativar SIMS',
	SWAP_SIMS_ACTION: 'Trocar SIMS',
	CUSTOM_FIELDS_ACTION: 'Adicionar ou atualizar campos personalizados',
	REQUIRED_CUSTOM_FIELD: 'São necessárias permissões elevadas!',
	FIELD_IS_REQUIRED_ACTION: 'Somente os administradores podem modificar os tipos de campos personalizados, porque esses campos afetam a configuração de todo o sistema. Entrar em contato com seu administrador para atualizar as opções dos campos personalizados.',
	PERMANENT_CUSTOM_FIELD_DELETION: 'Exclusão permanente do campo personalizado!',
	DELETING_THIS_CUSTOM_FIELD: 'A exclusão deste campo personalizado excluirá todos os dados associados! Esta ação não pode ser desfeita!',
	DELETE_FIELD: 'Excluir campo',

	WARNING: 'Aviso',
	ARE_YOU_SURE: 'Tem certeza?',
	NETWORK_ENTITLEMENT_CHANGE_ACTION: 'Alteração no direito de acesso à rede do SIM',
	WHEN_SAVING: 'Observar o formato acima ao salvar o arquivo.',
	EXAMPLE: 'Exemplo',

	SELECT_NEW_STATE: 'Selecionar novo estado',
	403: '403',

	AUTOMATION_PROCESS: 'Processo de automação',
	BATCH_UPLOAD: 'Carregamento do lote',
	DIAGRAM_COULD_NOT_BE: 'O diagrama não pôde ser',
	LOADED_AT_THIS_TIME: 'carregado neste momento.',
	BATCH_ACTIONS: 'Ações de lotes',
	BATCH_ACTION: 'Ação de lotes',
	BATCH_ACTIONS_SUBMITTED: 'O arquivo foi carregado com êxito para processamento',
	BATCH_ACTIONS_SCHEDULED: 'O arquivo foi carregado com êxito; agendado para processamento',
	BATCH_ACTIONS_IN_PROGRESS: 'Processamento em andamento',
	BATCH_ACTIONS_COMPLETED: 'Arquivo processado com êxito',
	BATCH_ACTIONS_ERROR: 'Ocorreu erro durante o processamento',
	BATCH_REPORTING: 'Relatórios de lotes',
	BATCH_TEXT: 'Para processar suas alterações no lote, selecionar uma ação a ser realizada e carregar o arquivo txt do lote. Há modelos de exemplos disponíveis para a formatação.',
	START_BATCH_PROCESS: 'Iniciar o processo do lote',
	DOWNLOAD_TEMPLATE: 'Baixar o arquivo de modelo',
	AUTOMATION_RULES: 'Regras de automação',
	ACTION_FORMAT: 'Formato da ação',
	VIEW_MORE_DETAILS: 'Exibir mais detalhes',
	UPLOAD_FILE_NOTE: 'Carregar aqui os arquivos para a ação de lotes selecionados:',
	UPLOAD_FILE: 'Carregar arquivo',

	UNSUPPORTED_FILE_TYPE: 'Tipo de arquivo sem suporte',
	INVALID_FILE_NAME_FORMAT: 'O formato do nome do arquivo deve ser actionName_MM-DD-YYYY_HH-mm-ss',
	DOWNLOAD_REPORT_FAILED: 'Falha no download; tentar novamente.',
	PLEASE_TRY_AGAIN: 'Tentar novamente.',

	// REPORT DETAIL DEFINITION
	REPORT_SOURCE: 'Fonte do relatório',
	DATA_SESSION_DETAIL_DEFINITION: 'Definição dos detalhes da sessão de dados',
	IMSI_DETAIL_DEFINITION: 'Definição de detalhes de IMSI ',
	SMS_DETAIL_DEFINITION: 'Definição de detalhes de SMS',
	SIM_DETAIL_DEFINITION: 'Definição de detalhes de SIM',
	USAGE_DATA_DETAILS: 'Detalhe de dados de uso',
	USAGE_SMS_DETAILS: 'Detalhe de uso de SMS',
	POOLED_USAGE: 'Uso em conjunto',
	USAGE_SUMMARY: 'Resumo de uso',
	SUBSCRIBER_SNAPSHOT: 'Instantâneo de assinantes',
	NEW_SUBSCRIBERS: 'Novos assinantes',
	TOP_DATA_USAGE: 'Uso principal de dados',
	TOP_SMS_USAGE: 'Uso principal de SMS',
	PROVOSIONING_METRIC: 'Provisionamento de métrica',

	// DETALHE DOS DADOS DE USO - CHAVES E DESC
	DATA_TRAFFIC_DETAIL_ID_KEY: 'ID do detalhe de tráfego de dados',
	DATA_TRAFFIC_DETAIL_ID_DESC: 'Identificador exclusivo para registro do uso de dados',
	// "ICCID_KEY"
	ICCID_DESC_SHORT: 'ICCID que identifica o SIM',
	// "MSISDN_KEY"
	MSISDN_DESC_SHORT: 'MSISDN que identifica o SIM',
	// "IMSI_KEY"
	IMSI_DESC_SHORT: 'IMSI que identifica o SIM',
	ACCOUNT_ID_KEY: 'ID da conta',
	ACCOUNT_ID_DESC: 'Número de ID da conta Jaspe',
	BILLABLE_FLAG_KEY: 'Sinalizador faturável',
	BILLABLE_FLAG_DESC: 'Indicador de tráfego faturável com base no estado do SIM',
	BILLING_CYCLE_KEY: 'Ciclo de cobrança',
	BILLING_CYCLE_DESC: 'Ciclo de cobrança no qual ocorreu o uso',
	SIM_STATE_KEY: 'Estado do SIM',
	SIM_STATE_DESC: 'Status atribuído ao SIM no final do período do relatório',
	SERVICE_TYPE_KEY: 'Tipo de serviço',
	SERVICE_TYPE_DESC: 'Tipo de serviço sem fio relatado (p.ex. dados, MO de SMS, MT de SMS, voz)',
	ASSIGNED_RATE_PLAN_ID_KEY: 'ID do plano de tarifas atribuído',
	ASSIGNED_RATE_PLAN_ID_DESC: 'Plano de tarifas atribuído ao SIM quando o uso foi relatado',
	ASSIGNED_RATING_ZONE_ID_KEY: 'ID da zona de tarifação atribuída',
	ASSIGNED_RATING_ZONE_ID_DESC: 'Zona do plano de tarifas para a qual é atribuído o uso baseando-se na atribuição do plano de tarifas quando o uso foi relatado',
	OFFPEAK_USAGE_FLAG_KEY: 'Sinalizador de uso fora do horário de pico',
	OFFPEAK_USAGE_FLAG_DESC: 'Se o uso ocorreu fora do horário de pico definido para a conta',
	RECORD_RECEIVED_DATE_KEY: 'Data de recebimento do registro',
	RECORD_RECEIVED_DATE_DESC: 'Data/hora na qual o registro da conta foi recebido',
	DATA_USAGE_RAW_TOTAL_KEY: 'Uso de dados - Total bruto',
	DATA_USAGE_RAW_TOTAL_DESC: 'Uso de dados não arredondado para o período - bytes',
	DATA_USAGE_RAW_UPLINK_KEY: 'Uso de dados - Uplink bruto',
	DATA_USAGE_RAW_UPLINK_DESC: 'Uso de dados não arredondado para o período - Bytes de uplink',
	DATA_USAGE_RAW_DOWNLINK_KEY: 'Uso de dados - Downlink bruto',
	DATA_USAGE_RAW_DOWNLINK_DESC: 'Uso de dados não arredondado para o período - Bytes de downlink',
	DATA_USAGE_ROUNDED_KEY: 'Uso de dados- Arredondado',
	DATA_USAGE_ROUNDED_DESC: 'Uso de dados arredondado para o período - bytes; o tratamento do arredondamento é definido de acordo com o plano de tarifas',
	APN_DESC: 'Nome do ponto de acesso para a sessão',
	DEVICE_IP_ADDRESS_KEY: 'Endereço de IP do dispositivo',
	DEVICE_IP_ADDRESS_DESC: 'O endereço de IP atribuído ao dispositivo para a sessão de dados correspondente',
	OPERATOR_NETWORK_KEY: 'Rede do operador',
	OPERATOR_NETWORK_DESC: 'A rede do operador visitada na qual ocorreu o uso',
	RECORD_OPEN_TIME_KEY: 'Horário de abertura do registro',
	RECORD_OPEN_TIME_DESC: 'Data/hora de início da sessão ou sessão parcial',
	SESSION_DURATION_KEY: 'Duração da sessão',
	SESSION_DURATION_DESC: 'Duração da sessão de dados - em segundos',
	RECORD_SEQUENCE_NUMBER_KEY: 'Número da sequência de registro',
	RECORD_SEQUENCE_NUMBER_DESC: 'Número de sequência do registro dentro da sessão principal se o registro representar sessão parcial',
	CHARGINGID_KEY: 'ChargingID',
	CHARGINGID_DESC: 'Um identificador da sessão ao qual se aplica o registro de uso',
	SESSION_CLOSE_CAUSE_KEY: 'Causa do encerramento da sessão',
	SESSION_CLOSE_CAUSE_DESC: 'Código numérico que representa a razão pela qual foi encerrada a sessão',
	TAP_CODE_KEY: 'Código de TAP',
	TAP_CODE_DESC: 'Um código de TAP para a rede visitada',
	OPERATOR_ACCOUND_ID_KEY: 'ID da conta do operador',
	OPERATOR_ACCOUND_ID_DESC: 'ID que o operador usa para identificar a conta',
	RATE_PLAN_VERSION_ID_KEY: 'ID da versão do plano de tarifas',
	RATE_PLAN_VERSION_ID_DESC: 'Versão específica do plano de tarifas utilizada para fins de cobrança neste momento',
	STREAM_ID_KEY: 'ID do fluxo',
	STREAM_ID_DESC: 'Um identificador numérico para descrever diferentes fluxos de dados identificador por GGSN/PGW',
	CGI_KEY: 'CGI (Identificador global de célula)',
	CGI_DESC: 'Parte das informações de localização, conforme descrita em TS29.274',
	SAI_KEY: 'SAI (Identificador da área de serviço)',
	SAI_DESC: 'Parte das informações de localização, conforme descrita em TS29.274',
	RAI_KEY: 'RAI (Identificador da área de roteamento)',
	RAI_DESC: 'Parte das informações de localização, conforme descrita em TS29.274',
	TAI_KEY: 'TAI (Identidade da área de rastreamento)',
	TAI_DESC: 'Parte das informações de localização, conforme descrita em TS29.274',
	ECGI_KEY: 'ECGI(E-UTRAN CGI)',
	ECGI_DESC: 'Parte das informações de localização, conforme descrita em TS29.274',
	LAI_KEY: 'LAI (Identificador da área de localização)',
	LAI_DESC: 'Parte das informações de localização, conforme descrita em TS29.274',
	SERVING_SGSN_KEY: 'Atendendo SGSN',
	SERVING_SGSN_DESC: 'Endereço da troca do servidor',
	CALL_TECHNOLOGY_TYPE_KEY: 'Tipo de tecnologia da chamada',
	CALL_TECHNOLOGY_TYPE_DESC: 'Indicador numérico para identificar a tecnologia de acesso de rádio usada para este uso',

	// DETALHE DE SMS DE USO - CHAVES E DESC
	SMS_TRAFFIC_DETAIL_ID_KEY: 'ID do detalhe de tráfego de dados',
	SMS_TRAFFIC_DETAIL_ID_DESC: 'Identificador exclusivo para registro do uso de dados',
	// "ICCID_KEY"
	// "ICCID_DESC"
	// "MSISDN_KEY"
	// "MSISDN_DESC"
	// "IMSI_KEY"
	// "IMSI_DESC"
	// "ACCOUNT_ID_KEY"
	// "ACCOUNT_ID_DESC"
	// "BILLABLE_FLAG_KEY"
	// "BILLABLE_FLAG_DESC"
	// "BILLING_CYCLE_KEY"
	// "BILLING_CYCLE_DESC"
	// "SIM_STATE_KEY"
	// "SIM_STATE_DESC"
	// "SERVICE_TYPE_KEY"
	// "SERVICE_TYPE_DESC"
	// "ASSIGNED_RATE_PLAN_ID_KEY"
	// "ASSIGNED_RATE_PLAN_ID_DESC"
	// "ASSIGNED_RATING_ZONE_ID_KEY"
	// "ASSIGNED_RATING_ZONE_ID_DESC"
	RECORD_OPEN_TIME_SMS_KEY: 'Horário de abertura do registro',
	RECORD_OPEN_TIME_SMS_DESC: 'Data/hora em que foi enviada uma mensagem SMS de MO; hora/data em que foi recebida uma mensagem de MT',
	// "RECORD_RECEIVED_DATE_KEY": “Data de recebimento do registro”,
	// "RECORD_RECEIVED_DATE_DESC": “Data/hora em que o registro da conta foi recebido”,
	NETWORK_NODE_KEY: 'Nó da rede',
	NETWORK_NODE_DESC: 'Endereço do nó da rede de atendimento para a mensagem',
	FROM_KEY: 'De',
	FROM_DESC: 'MSISDN de origem ou código curto',
	TO_KEY: 'Para',
	TO_DESC: 'MSISDN de destino ou código curto',
	// "OPERATOR_NETWORK_KEY": “Rede do operador”,
	// "OPERATOR_NETWORK_DESC": “A rede do operador visitada na qual ocorreu o uso”,
	// "TAP_CODE_KEY": “Código de TAP”,
	// "TAP_CODE_DESC": “Um código de TAP para a rede visitada”,
	// "OPERATOR_ACCOUND_ID_KEY": “ID da conta do operador”,
	// "OPERATOR_ACCOUND_ID_DESC": “ID que o operador usa para identificar a conta”,
	// "RATE_PLAN_VERSION_ID_KEY": “ID da versão do plano de tarifas”,
	// "RATE_PLAN_VERSION_ID_DESC": “Versão específica do plano de tarifas,
	// utilizada para fins de cobrança neste momento”,

	// USO EM CONJUNTO - CHAVES EUso por grupo DESC
	// "ACCOUNT_ID_KEY"
	// "ACCOUNT_ID_DESC
	// "BILLING_CYCLE_KEY" />,
	// "BILLING_CYCLE_DESC" />
	INVOICE_ID_KEY: 'ID da fatura',
	INVOICE_ID_DESC: 'ID da fatura para a qual se aplicam as cobranças',
	RATE_PLAN_ID_KEY: 'ID do plano de tarifas',
	RATE_PLAN_ID_DESC: 'ID do plano de tarifas atribuído ao SIM no final do período do relatório',
	RATING_ZONE_ID_KEY: 'ID da zona de tarifação',
	RATING_ZONE_ID_DESC: 'Zona de tarifação (definida no plano de tarifas) onde ocorreu o uso',
	TOTAL_DATA_USAGE_CHARGES_KEY: 'Cobranças totais do uso de dados',
	TOTAL_DATA_USAGE_CHARGES_DESC: 'Cobranças totais do uso de dados nesta zona de tarifação, além de uso de dados em conjunto incluído com a taxa de assinatura.',
	TOTAL_SMS_MO_USAGE_CHARGES_KEY: 'Cobranças totais do uso de MO de SMS',
	TOTAL_SMS_MO_USAGE_CHARGES_DESC: 'As cobranças totais do uso de MO de SMS nesta zona de tarifação, além de uso de SMS incluído com a taxa de assinatura',
	TOTAL_SMS_MT_USAGE_CHARGES_KEY: 'Cobranças totais pelo uso de MT de SMS',
	TOTAL_SMS_MT_USAGE_CHARGES_DESC: 'As cobranças totais pelo uso de MT de SMS nesta zona de tarifação, além de uso de SMS em conjunto incluído com a taxa de assinatura',
	TOTAL_SMS_USAGE_CHARGES_KEY: 'Cobranças totais pelo uso de SMS',
	TOTAL_SMS_USAGE_CHARGES_DESC: 'As cobranças totais pelo uso de SMS nesta zona de tarifação, além de uso de SMS em conjunto incluído com a taxa de assinatura',
	TOTAL_VOICE_MO_USAGE_CHARGES_KEY: 'Cobranças totais do uso de MO de voz',
	TOTAL_VOICE_MO_USAGE_CHARGES_DESC: 'As cobranças totais pelo uso de MO de voz para este SIM nesta zona de tarifação, além de uso de voz incluído com a taxa de assinatura',
	TOTAL_VOICE_MT_USAGE_CHARGES_KEY: 'Cobranças totais pelo uso de MT de voz',
	TOTAL_VOICE_MT_USAGE_CHARGES_DESC: 'As cobranças totais pelo uso de voz de MT para este SIM nesta zona de tarifação, além de uso de voz incluído com a taxa de assinatura',
	TOTAL_USAGE_CHARGES_KEY: 'Cobranças totais pelo uso',
	TOTAL_USAGE_CHARGES_DESC: 'As cobranças totais pelo uso nesta zona de tarifação, além de uso em conjunto incluído com a taxa de assinatura',

	// RESUMO DE USO - CHAVES E DESC
	// "ACCOUNT_ID_KEY"
	// "ACCOUNT_ID_DESC"
	OPERATOR_ID_KEY: 'ID do operador',
	OPERATOR_ID_DESC: 'Número da ID do operador da Jasper',
	// "BILLING_CYCLE_KEY"
	// "BILLING_CYCLE_DESC"
	// "ICCID_KEY"
	// "ICCID_DESC"
	// "IMSI_KEY"
	// "IMSI_DESC"
	// "MSISDN_KEY"
	// "MSISDN_DESC"
	TOTAL_DATA_USAGE_KEY: 'Uso total de dados',
	TOTAL_DATA_USAGE_DESC: 'Uso total de dados para o ciclo de cobrança deste dispositivo',
	TOTAL_VOICE_USAGE_KEY: 'Uso total de voz',
	TOTAL_VOICE_USAGE_DESC: 'Uso total de voz para o ciclo de cobrança deste dispositivo',
	TOTAL_SMS_USAGE_KEY: 'Uso total de SMS',
	TOTAL_SMS_USAGE_DESC: 'Uso total de SMS para o ciclo de cobrança deste dispositivo',
	TOTAL_CSD_USAGE_KEY: 'Uso total de CSD',
	TOTAL_CSD_USAGE_DESC: 'Uso total de CSD para o ciclo de cobrança deste dispositivo',
	RESERVED_FIELD_KEY: 'Campo reservado',
	RESERVED_FIELD_DESC: 'Reservado para uso futuro',
	DEVICE_ID_KEY: 'ID do dispositivo',
	DEVICE_ID_DESC: 'Campo personalizado disponível para os operadores',
	MODEM_ID_KEY: 'ID do modem',
	MODEM_ID_DESC: 'Campo personalizado disponível para os operadores',
	END_CONSUMER_ID_KEY: 'ID do consumidor final',
	END_CONSUMER_ID_DESC: 'Identificador do consumidor final',
	USAGE_CUSTOMER_KEY: 'Cliente',
	USAGE_CUSTOMER_DESC: 'Nome do cliente',
	CUSTOM1_KEY: 'Personalizado 1',
	CUSTOM1_DEVICE_DESC: 'Campo personalizado de nível do dispositivo 1',
	CUSTOM2_KEY: 'Personalizado 2',
	CUSTOM2_DEVICE_DESC: 'Campo personalizado de nível do dispositivo 1',
	CUSTOM3_KEY: 'Personalizado 3',
	CUSTOM3_DEVICE_DESC: 'Campo personalizado de nível do dispositivo 1',

	// INSTANTÂNEO DE ASSINANTES - CHAVES E DESC
	SUBSCRIBER_SIM_STATE_KEY: 'Estado do SIM',
	SUBSCRIBER_SIM_STATE_DESC: 'Estado de SIM no final do período do relatório',
	// "RATE_PLAN_ID_KEY"
	SUBSCRIBER_RATE_PLAN_ID_DESC: 'Plano de tarifas atribuído ao SIM no final do período do relatório',
	SUBSCRIBER_CUSTOMER_KEY: 'Cliente',
	SUBSCRIBER_CUSTOMER_DESC: 'Cliente atribuído ao SIM no final do período do relatório',
	// "DEVICE_ID_KEY",
	SUBSCRIBER_DEVICE_ID_DESC: 'Valor no final do período do relatório',
	// "MODEM_ID_KEY"

	SUBSCRIBER_MODEM_ID_DESC: 'Valor no final do período do relatório',
	COMMUNICATION_PLAN_ID_KEY: 'ID do plano de comunicação',
	COMMUNICATION_PLAN_ID_DESC: 'Valor no final do período do relatório',
	INITIAL_ACTIVATION_DATE_KEY: 'Data inicial de ativação',
	INITIAL_ACTIVATION_DATE_DESC: 'Data e hora da primeira ativação do SIM na conta atual',
	CUSTOM_SIM_DESC: 'Campo personalizado de nível do SIM para as contas',
	LINE_PAYMENT_STATUS_KEY: 'Status de pagamento da linha',
	LINE_PAYMENT_STATUS_DESC: 'Os possíveis valores são Atual, Suspensa, Cancelada, Cancelamento final',
	OPERATOR_ACCOUNT_ID_KEY: 'ID da conta do operador',
	OPERATOR_ACCOUNT_ID_DESC: 'ID que o operador usa para identificar a conta',
	CUSTOM4_KEY: 'Personalizado 4',
	CUSTOM5_KEY: 'Personalizado 5',
	CUSTOM6_KEY: 'Personalizado 6',
	CUSTOM7_KEY: 'Personalizado 7',
	CUSTOM8_KEY: 'Personalizado 8',
	CUSTOM9_KEY: 'Personalizado 9',
	CUSTOM10_KEY: 'Personalizado 10',

	ICCID_DESC_SHORT2: 'ICCID do dispositivo',
	IMSI_DESC_SHORT2: 'IMSI do dispositivo',
	RATE_PLAN_KEY: 'Rateplan',
	RATE_PLAN_DESC: 'EOD plan_code, Control Center ICCID blank',
	SIM_STATE_KEY2: 'Simstate',
	SIM_STATE_DESC2: 'ATIVO, INATIVO, etc., se disponível',
	BEGBILLINGCYCLEDATE_KEY: 'Begbillcycledate',
	BEGBILLINGCYCLEDATE_DESC: 'Data de início do ciclo de cobrança se EOD, em branco se Central de Controle',
	ENDBILLINGCYCLEDATE_KEY: 'Endbillcycledate',
	ENDBILLINGCYCLEDATE_DESC: 'Data de término do ciclo de cobrança se EOD, em branco se Central de Controle',
	TOTALDATAUSAGE_KEY: 'Totaldatausage',
	TOTALDATAUSAGE_DESC: 'Uso de dados no período em quilobytes',
	TOTALINTERNATIONALUSAGE_KEY: 'Totalinternationalusage',
	TOTALINTERNATIONALUSAGE_DESC: 'Uso de dados internacionais no período em quilobytes se EOD, em branco se Central de Controle',
	CUSTOMERNAME_KEY: 'Customername',
	CUSTOMERNAME_DESC: 'Nome do cliente',
	CUSTOMFIELD1_KEY: 'Customfield1',
	CUSTOMERNAME1_DESC: 'EOD e Central de controle podem ser preenchidos',
	CUSTOMFIELD2_KEY: 'Customfield2',
	CUSTOMERNAME2_DESC: 'EOD e Central de controle podem ser preenchidos',
	CUSTOMFIELD3_KEY: 'Customfield3',
	CUSTOMERNAME3_DESC: 'EOD e Central de controle podem ser preenchidos',
	CUSTOMFIELD4_KEY: 'Customfield4',
	CUSTOMERNAME4_DESC: 'ID do dispositivo da Central de Controle ou EOD',
	CUSTOMFIELD5_KEY: 'Customfield5',
	CUSTOMERNAME5_DESC: 'ID do modem da Central de Controle ou EOD',
	M2MPLATFORM_KEY: 'M2MPlatform',
	M2MPLATFORM_DESC: '“EOD” ou “Central de Controle”',
	BILLINGCYCLE_KEY: 'Billingcycle',
	BILLINGCYCLE_DESC: 'Campo de JPO da Central de Controle a ser preenchido',
	CUSTOMERID_KEY: 'Customerid',
	CUSTOMERID_DESC: 'Para EOD, prefixo do cliente; para Central de Controle, ID do cliente',
	PROVISIONINGACTIVITY_KEY: 'ProvisioningActivity ',
	PROVISIONINGACTIVITY_DESC: 'Um dos campos: “ativação”, “inativo”, “encerrar”, “changeNetworkEntitlement”, “changeRatePlan”, “updateCustomFields”',
	COUNT_KEY: 'Contagem',
	COUNT_DESC: 'Número de vezes em que a atividade de provisionamento foi chamada',
	SUCCESS_KEY: 'Êxito',
	SUCCESS_DESC: 'Número de vezes em que a atividade de provisionamento foi chamada com êxito',
	FAILURE_KEY: 'Falha',
	FAILURE_DESC: 'Número de vezes em que não foi possível realizar a atividade de provisionamento com êxito',
	AVGTIMEMS_KEY: 'AvgTimeMs',
	AVGTIMEMS_DESC: 'Tempo em milissegundos que levou, em média, para a chamada de provisionamento',
	LONGESTTIMEMS_KEY: 'LongestTimeMs',
	LONGESTTIMEMS_DESC: 'Tempo em milissegundos que levou para a atividade de provisionamento mais lenta',
	SHORTESTTIMEMS_KEY: 'ShortestTimeMs',
	SHORTESTTIMEMS_DESC: 'Tempo em milissegundos que levou para a atividade de provisionamento mais rápida',

	REPORT_SOURCE_DESC: 'Selecionar a fonte do relatório para ver as diferentes definições',

	SIM_SWAP_SUCCESSFUL: 'Troca de SIM bem-sucedida',
	SIM_SWAP_IN_PROGRESS: 'Troca de SIM em andamento',
	REFRESH_PENDING_STATUS: 'Atualizar endpoint para verificar se o estado foi alterado',
	TEMPLATE_NOT_AVAILABLE: 'Modelo não está disponível.',
	CUSTOM_FIELDS_UPDATE_SUCCESSFUL: 'Atualização bem-sucedida dos campos personalizados',
	CUSTOM_FIELDS_UPDATE_IN_PROGRESS: 'Atualizações de campos personalizados em andamento',
	IOT_APPS: 'APLICATIVOS DA IOT',
	INVOICES_DATE: 'Data das faturas',
	PLATFORM: 'Plataforma',
	FREQUENCY: 'Frequência',
	M2M_ACC_ID: 'ID da conta do M2M',
	REPORT: 'Relatório',

	// Integração
	ADD_NEW_PLATFORM: 'Adicionar nova plataforma',
	BUSINESS_NAME: 'Nome da empresa',
	ADMINISTRATOR: 'Administrador',
	MCC: 'MCC',
	MNC: 'IoT Console (IOT-C)',
	OPERATOR: 'Operador',
	POD: 'POD',
	RATE_PLANS: 'Planos de tarifas',
	RATE_PLAN_NAME: 'Nome do plano de tarifas',
	RATE_PLAN_ID: 'Plano de tarifas',
	RATE_PLAN_ID_PLAN: 'ID do plano de tarifas',
	COMMUNICATION_PLANE_NAME: 'Nome do plano de comunicação',
	ADD_ANOTHER_RATE_PLAN: 'Adicionar outro plano de tarifas',
	ADD_ANOTHER_COMMUNICATION_PLAN: 'Adicionar outro plano de comunicação',
	ADD_ANOTHER_PROFILE_NAME: 'Adicionar outro nome do perfil',
	SIM_STATE_MAPPING: 'Mapeamentos do estado do SIM',
	ADD_RATE_PLAN: 'Adicionar planos de tarifas',
	ACC_RULE: 'Regra de ACC',
	MC_RULE: 'Regra de IOT-C',
	WHAT_IS_THIS_TITLE: 'O que é isso?',
	PLAN_PROFILES: 'Perfiis do plano',
	COMPANY: 'Empresa',
	COMPANY_NAME: 'Nome da empresa',
	COMPANY_DOMAIN: 'Domínio da empresa',
	PHONE_NUMBER: 'Número do telefone',
	CITY: 'Cidade',
	CITY_TOWN: 'Cidade/Município',
	STATE_PROVINCE: 'Estado/Província',
	COUNTRY: 'País',
	ZIP_CODE: 'CEP',
	ZIP_POSTAL_CODE: 'CEP/Código Postal',
	STREET: 'Logradouro',
	STREET_ADDRESS: 'Endereço do logradouro',
	LAST_NAME: 'Sobrenome',
	FIRST_NAME: 'Nome',
	ACTIONS: 'Ações',
	PLEASE_SELECT_COUPLE_OF_SIMS: 'Selecione alguns SIMS.',
	COMPANY_IDENTITY: 'Identidade da empresa',
	ADD_USERS_OPTIONAL: 'Adicionar usuários (opcional)',
	REVIEW_AND_CREATE: 'Revisar e criar',
	PROJECTS_LIST: 'Lista de projetos',
	MULTI_NETWORK_CONNECT_ONBOARDING_M2M_PLATFORMS: 'Integração do IoT Console - Plataformas M2M',
	MULTI_NETWORK_CONNECT: 'IoT Console',
	CUSTOMERS: 'Clientes',
	COMPANY_EMAIL_ADDRESS: 'Endereço de e-mail da empresa',
	CONTACT_EMAIL_ADDRESS: 'Endereço de e-mail para contato',
	CONTACT_PERSON_FULL_NAME: 'Nome completo da pessoa de contato',
	CONTACT_PERSON: 'Pessoa de contato',
	ASSIGN_ANOTHER_PLATFORM: 'Atribuir outra plataforma',
	USER_INFO: 'Informações do usuário',
	SELECT_USER_TYPE: 'Selecionar tipo de usuário',
	USER_TYPE: 'Tipo de usuário',
	USER_NOTE: 'O usuário receberá um e-mail de convite para criar sua conta',
	ADD_USER: 'Adicionar usuário',
	ADD_ANOTHER_USER: 'Adicionar outro usuário',
	WHAT_IS_THIS_MESSAGE1: 'O cliente deve ser carregado nas plataformas M2M antes que se possa criar uma conta do IoT Console. Você poderá continuar após ter a ID da conta.',
	WHAT_IS_THIS_MESSAGE2: 'Clientes de pequenas empresas e inovadoras (start-ups) usando planos de dados ou Starter Kits da IoT podem ser adicionados automaticamente mediante preenchimento deste formulário. Depois de criar a conta, volte aqui para habilitar o acesso do cliente ao portal do IoT Console.',
	SELECT_PLATFORM: 'Selecionar plataforma',
	EMAIL_EXISTS_OTHER: 'Este e-mail já existe (outro usuário)',
	EMAIL_EXISTS_ADMIN: 'Este e-mail já existe (Admin.)',
	YOU_HAVE_SUCCESSFULLY_CREATED_A_MULTI_NETWORK_CONNECT_ACCOUNT_FOR: 'Você criou com êxito uma conta do IoT Console para:',
	THE_IOT_GATEWAY_NUMBER_ASSOCIATED_WITH_THIS_ACCOUNT: 'Número do Gateway IoT associado com esta conta:',
	COMMUNICATION_PROFILE_NAME: 'Nome do perfil de comunicação',
	ADD_NETWORK_ENTITLEMENT: 'Adicionar do direito de acesso à rede',
	EDIT_NETWORK_ENTITLEMENT: 'Revisar direito de acesso à rede',
	PLATFORMS: 'Plataformas',
	NO_NETWORK_ENTITLEMENTS: 'Nenhum direito de acesso à rede',
	NO_RATE_PLANS: 'Nenhum plano de tarifas',
	EDIT_RATE_PLAN: 'Revisar plano de tarifas',
	ERROR_WHILE_ADDING_NETWORK_ENTITLEMENT: 'Erro ao adicionar o direito de acesso à rede',
	ERROR_WHILE_EDITING_NETWORK_ENTITLEMENT: 'Erro ao revisar o direito de acesso à rede',
	ERROR_WHILE_ADDING_RATE_PLAN: 'Erro ao adicionar o plano de tarifas',
	ERROR_WHILE_EDITING_RATE_PLAN: 'Erro ao revisar o plano de tarifas',
	ERROR_WHILE_ADDING_COMPANY: 'Erro ao adicionar a empresa',
	ERROR_WHILE_EDITING_COMPANY: 'Erro ao revisar a empresa',
	ERROR_WHILE_ADDING_EDITING_USER: 'Erro ao adicionar/revisar usuário',
	ERROR_WHILE_ADDING_EDITING_PLATFORM: 'Erro ao adicionar/revisar plataforma',
	ERROR_WHILE_ADDING_PLATFORM: 'Erro ao adicionara plataforma',
	ADD_PROFILE_NAME: 'Adicionar nome do perfil',
	ADD_PROFILE_ID: 'Adicionar ID do perfil',
	EDIT_PLATFORM: 'Revisar plataforma',
	SIM_STATE_CHANGE_IS_SUCCESSFULLY_SCHEDULED: 'Alteração do estado do SIM agendado com êxito!',
	SIM_STATE_CHANGE_IS_SUCCESSFULLY_SCHEDULED_FOR: 'Alteração do estado do SIM agendado para!',
	SIM_STATE_CHANGE_IS_SUCCESSFULLY_SCHEDULED_ERROR: 'Não foi possível agendar alteração do estado do SIM com êxito!',
	SIM_STATE_CHANGED_TO: 'Você optou por alterar o estado do SIM para',
	CHANGE_LATER: 'Você pode também agendar a alteração para mais tarde.',
	SCHEDULED_CHANGE_WILL_HAPPEN: 'A alteração agendada ocorrerá no início da data selecionada.',
	TAP_INITIATE_CHANGE: "Toque em 'Iniciar alteração' para tomar uma ação imediata.",
	SIM_STATE_CHANGE: 'Mudança do estado do SIM',
	SCHEDULE_SIM_STATE_CHANGE: 'Agendar alteração do estado do SIM',
	SYSTEM_CONFIRMATION: 'Confirmação do sistema',
	CHOOSE_NETWORK_ENTITLEMENT: 'Selecionar direito de acesso à rede',
	HISTORY: 'Histórico de lotes',

	serviceProvider: 'Plataforma',
	reportFrequency: 'Frequência',

	// Integração
	ENDPOINT_SESSION_NO_RECORD: 'Não há dados para o período selecionado.',
	TIME_START: 'Hora de início',
	TIME_END: 'Hora de término',
	SESSION_DETAILS: 'Detalhes da sessão',
	CARRIER: 'Operadora',
	DEVICE_IP: 'IP do dispositivo',

	// conjunto de dados da tabela
	inventoryByStatus: 'Inventário por status',
	inventoryByOperator: 'Inventário por operador',
	inventoryByCountry: 'Inventário por país',
	inventoryByStatusDescription: 'Isto exibirá o número de endpoints ativos e inativos.',
	inventoryByOperatorDescription: 'Isto exibirá os operadores e número de endpoints com cada operador.',
	inventoryByCountryDescription: 'Isso exibirá o nome de um país e o número de endpoint operando naquele país.',
	// prompt modal
	addPromptHeaderInfo: 'Aplicar as alterações?',
	addChartPromptHeader: 'As alterações do painel ainda não foram salvas',
	addChartPromptFirst: 'Se você quer salvar as alterações na exibição do painel, toque em `Salvar painel`. Caso contrário `cancele` para voltar à exibição anterior.',
	addChartPromptNotification: 'Aplicar as alterações?',

	// Descrições da TABELA
	DASHBOARD_CHART_countByState_SATELLITEDescription: 'Mostra os detalhes de status dos endpoints de satélites (dispositivos) em diversas plataformas M2M',
	DASHBOARD_CHART_countByState_CELLULARDescription: 'Mostra os detalhes de status dos endpoints de celulares (dispositivos) em diversas plataformas M2M',
	DASHBOARD_CHART_countByNetworkCarrier_SATELLITEDescription: 'Mostra a divisão de porcentagem dos endpoints de satélites (dispositivos) em diversas operadoras de rede',
	DASHBOARD_CHART_countByNetworkCarrier_CELLULARDescription: 'Mostra a divisão de porcentagem dos endpoints de celulares (dispositivos) em diversas operadoras de rede',
	DASHBOARD_CHART_countByTechnologyDescription: 'Mostra a divisão total por porcentagem pelos endpoints de celulares e satélites (dispositivos) na conta',
	DASHBOARD_CHART_countByM2mPlatform_SATELLITEDescription: 'Mostra a divisão total por porcentagem pelos endpoints de satélites (dispositivos) por diversas plataformas M2M',
	DASHBOARD_CHART_countByM2mPlatform_CELLULARDescription: 'Mostra a divisão total por porcentagem pelos endpoints de celulares (dispositivos) por diversas plataformas M2M',
	DASHBOARD_CHART_countByIMSI_SATELLITEDescription: 'Mostra a divisão total por porcentagem pelos endpoints de celulares (dispositivos) por diversas plataformas M2M',
	DASHBOARD_CHART_countByIMSI_CELLULARDescription: 'Mostra a divisão total por porcentagem pelos endpoints de satélites (dispositivos) por diversas plataformas M2M',
	'DASHBOARD_CHART_IMSI Transfer Status_CELLULARDescription': 'Mostra os totais do status de transferência (Re-IMSI) por porcentagem entre duas plataformas M2M.',
	DASHBOARD_CHART_countByIMSITransferStatus_CELLULARDescription: 'Mostra os totais do status de transferência (Re-IMSI) por porcentagem entre duas plataformas M2M.',
	DASHBOARD_CHART_countByIMSITransferStatus_SATELLITEDescription: 'Mostra os totais do status de transferência (Re-IMSI) por porcentagem entre duas plataformas M2M.',
	DASHBOARD_CHART_summaryOfMonthlyEndpointUsageDescription: 'Mostra o resumo do uso de dados mensal de endpoints (dispositivos) celulares e satélites',
	DASHBOARD_CHART_countByStatebyPlatformACC_CELLULARDescription: 'Mostra detalhes do status de endpoints (dispositivos) celulares para plataforma de ACC',
	DASHBOARD_CHART_countByStatebyPlatformEOD_CELLULARDescription: 'Mostra detalhes do status de endpoints (dispositivos) celulares para plataforma de EOD',
	DASHBOARD_CHART_countByStatebyPlatformGBCM_SATELLITEDescription: 'Mostra detalhes do status de endpoints (dispositivos) satélites para plataforma de GBCM',
	DASHBOARD_CHART_countByStatebyPlatformDCP_CELLULARDescription: 'Mostra detalhes do status de endpoints (dispositivos) celulares para plataforma de DCP',
	DASHBOARD_CHART_countByStatebyPlatformCCIP_CELLULARDescription: 'Mostra detalhes do status de endpoints (dispositivos) celulares para plataforma de CCIP',
	DASHBOARD_CHART_countByStatebyPlatformVCC_CELLULARDescription: 'Mostra detalhes do status de endpoints (dispositivos) celulares para plataforma de VCC',
	DASHBOARD_CHART_countByStatebyPlatformGDSP_CELLULARDescription: 'Mostra detalhes do status de endpoints (dispositivos) celulares para plataforma de GDSP',
	DASHBOARD_CHART_countByStatebyPlatformWING_CELLULARDescription: 'Mostra detalhes do status de endpoints (dispositivos) celulares para plataforma de WING',
	DASHBOARD_CHART_summaryOfMonthlyEndpointUsage_CELLULARDescription: 'Mostra o resumo do uso de dados mensal de endpoints (dispositivos) celulares e satélites',
	DASHBOARD_CHART_countByProfile_CELLULARDescription: 'Mostra os detalhes de status dos endpoints (dispositivos) de celulares em diversos perfis',

	// id do tooltip personalizado da tabela de setores
	PIE_CHART_TOOLTIP_Active: 'Ativado',
	PIE_CHART_TOOLTIP_Test: 'Testar',
	PIE_CHART_TOOLTIP_Inactive: 'Inativo',
	PIE_CHART_TOOLTIP_Terminate: 'Encerrado',

	// Globecomm
	DEVICE_MODULE_NUMBER: 'Número do módulo do dispositivo',
	DEVICE_MANUFACTURER: 'Fabricante do dispositivo',
	DEVICE_STATUS: 'Status do dispositivo',
	DEVICE_NETWORK_TYPE: 'Tipo de rede do dispositivo',
	MODULE_IMEI: 'IMEI do módulo',
	SIM_STATUS: 'Status do SIM',
	MODULE_STATUS: 'Status do módulo',
	TRESHOLD_OF_NOTIFICATION_BY_DATA_USAGE: 'Limite de notificação por uso de dados',
	TRESHOLD_OF_SUSPENSION_BY_DATA_USAGE: 'Limite de suspensão por uso de dados',
	NOTIFICATION_INTERVAL: 'Intervalo de notificação',
	DATA_USAGE: 'Uso de dados do ciclo até o momento',
	MANUFACTURER: 'Fabricante',
	MODEL_NUMBER: 'Número do modelo',
	TYPE: 'Tipo',
	NETWORK_NAME: 'Nome da rede',
	SUPPORTED_NETWORKS: 'Redes suportadas',
	RENEWAL_PERIOD: 'Período de renovação',
	ACTIVATION_FEE: 'Taxa de ativação',
	RECCURING_FEE: 'Taxa recorrente',
	OVERAGE_FEE: 'Taxa excedente',
	INCLUDED_BYTES: 'Bytes incluídos',
	MINIMUM_BILLABLE_BYTES: 'Bytes mínimos faturáveis',
	NOTIFICATION_CONTACT: 'Contato de notificação',
	NOTIFICATION_BYTE_CAP: 'Notificação do limite de bytes',
	NOTIFICATION_REFRESH: 'Atualizar notificação',
	OBSOLETE: 'Obsoleto',
	HARDWARE: 'Hardware',
	SPECIFICATIONS: 'Especificações',
	SPECIFICATION: 'Especificação',
	MODULE: 'Módulo',
	NO_HISTORY_DATA: 'Não há registros históricos para este endpoint',

	// Mensagem padrão do painel de controle
	NO_GRAPHS: 'OS GRÁFICOS ADICIONADOS SERÃO EXIBIDOS AQUI',
	dashboardDefault: 'OS GRÁFICOS ADICIONADOS SERÃO EXIBIDOS AQUI',
	REMOVE_CHART: 'Remover gráfico',
	CARD_DROPDOWN_TITLE: 'Tipo de gráfico',
	ADD_WIDGET_BUTTON: 'Adicionar gráfico',
	ADD_WIDGET_BUTTON_MOBILE: 'Adicionar',

	APP_NAME: 'IoT Console',
	NETWORK_TYPE_CHECK: 'Infelizmente não é permitida a alteração do estado de endpoints múltiplos para endpoints de diferentes tipos de redes ou diferentes provedores de serviços.',
	SERVICE_PROVIDER_CHECK: 'Infelizmente não é permitida a alteração de endpoints múltiplos para endpoints de várias plataformas ou com diferentes direitos de acesso à rede.',

	// USER FEEDBACK MODAL
	feedbackTitle: 'Feedback do usuário',
	feedbackPageLabel: 'Seu feedback está relacionado com',
	feedbackFeedLabel: 'Em suas próprias palavras, como podemos tornar as coisas melhores?',
	feedbackFeedPlaceholder: 'Escreva seu feedback aqui...',
	feedbackContactLabel: 'Se necessário, podemos entrar em contato para discutir este assunto?',
	feedbackEmailLabel: 'E-mail',
	feedbackPhoneLabel: 'Telefone',
	feedbackMetaLabel: 'Metadados',
	feedbackCancelButton: 'Cancelar',
	feedbackSendButton: 'Enviar feedback',
	feedbackSuccessThanks: 'Obrigado pelo feedback.',
	feedbackSuccessMessage: 'Seu feedback aperfeiçoará o nosso produto. Avaliaremos seus comentários atentamente e tomaremos as devidas providências.',
	feedbackSuccessMessageSub: 'Poderemos entrar em contato para obter mais informações sobre suas sugestões.',
	feedbackSuccessMessageSign: 'Equipe do IoT Console',
	feedbackSuccessDoneButton: 'Concluído',
	feedbackTriggerTitle: 'Quer dar feedback?',
	feedbackTriggerSubtitle: 'Escreva aqui',
	feedbackThankYou: 'Obrigado {user} por sua participação no feedback de usuário e por ajudar-nos a aperfeiçoar os nossos produtos.',
	feedbackSelect: 'Você deve selecionar um tópico relacionado da lista acima.',
	feedbackEnter: 'Digite o feedback.',
	feedbackIcon: 'Ícone de envio de feedback realizado',
	feedbackAdditionalFile: 'Arquivo adicional',
	feedbackFileUploadFail: 'Falha no carregamento',
	feedbackMinLength: 'Digitar pelo menos 10 caracteres.',
	FEEDBACK_FILE_UPLOAD_EXTENSIONS: 'Carregar somente arquivos com as extensões .jpg ou .png',
	// Guia de alterações

	FIELD_NAME: 'Nome do campo',

	DELETE_TAB: 'Excluir guia',
	TABLE_NAME: 'Nome da tabela',
	EDIT_TABLE: 'Revisar parâmetros da tabela',
	CUSTOM_FIELD_LABEL: 'Etiqueta de campo personalizado',
	EDIT_TABLE_VIEW_NAME: 'Revisar nome de exibição da tabela',
	PLEASE_ENTER_TABLE_NAME: 'Digitar nome da tabela',
	DEVICE_TYPE: 'Tipo de dispositivo',
	CELLULAR_COLUMNS: 'Parâmetros específicos do celular',
	SATELLITE_SPECIFIC_COLUMNS: 'Parâmetros específicos do satélite',

	// TICKETING
	TICKET_ID: 'ID do tíquete',
	CATEGORY_NAME: 'Categoria',
	CATEGORY_TICKETING: 'Categoria',
	SUBCATEGORY_OPTIONAL: 'Subcategoria (opcional)',
	SELECT_SUBCATEGORY_OPTIONAL: 'Selecionar subcategoria (opcional)',
	ROAMING_ISSUE: 'Problema de roaming',
	SEVERITY: 'Gravidade',
	PRIORITY: 'Prioridade',
	ACCOUNT_NUMBER: 'Número da conta',
	CREATED_BY_TICKETING: 'Criado por',
	MULTI_NETWORK_CONNECT_ID: 'ID do IoT Console',
	ENDPOINT_MODEL: 'Modelo de endpoint',
	WHAT_IS_THE_ERROR_MESSAGE: 'Qual é a mensagem de erro?',
	HOW_MANY_BARS_ARE_SHOWING: 'Aparecem quantas barras?',
	DESCRIBE_ENDPOINT_STATE_HERE: 'Descrever o estado do endpoint aqui...',
	WAS_THE_TROUBLESHOOTING_DONE: 'A resolução de problemas foi concluída?',
	DESCRIBE_TROUBLESHOOTING_DETAILS_HERE: 'Descrever os detalhes da resolução de problemas aqui…',
	DO_YOU_HAVE_ANY_ADDITIONAL_NOTES: 'Você tem alguma nota adicional?',
	ADDITIONAL_NOTES: 'Notas adicionais',
	DASHBOARD_CHART_M2MSIMStatus_SATELLITE: 'endpoints por estado de IOT-C state (satélite)',
	DASHBOARD_CHART_M2MSIMStatus_CELLULAR: 'endpoints por estado de IOT-C state (celular)',
	DASHBOARD_CHART_Country_SATELLITE: 'Endpoints por localização atual (satélite)',
	DASHBOARD_CHART_Countrys_CELLULAR: 'Endpoints por localização atual (celular)',
	DASHBOARD_CHART_IMSI_SATELLITE: 'Endpoints por plataforma M2M (satélite)',
	DASHBOARD_CHART_IMSI_CELLULAR: 'Endpoints por plataforma M2M (celular)',
	DASHBOARD_CHART_Network_SATELLITE: 'Endpoints por operadora de rede (satélite)',
	DASHBOARD_CHART_Network_CELLULAR: 'Endpoints por operadora de rede (celular)',
	DASHBOARD_CHART_Technology: 'Endpoints por tecnologia',
	'DASHBOARD_CHART_IMSI Transfer Status_SATELLITE': 'Endpoints por status de transferência (satélite)',
	'DASHBOARD_CHART_IMSI Transfer Status_CELLULAR': 'Endpoints por status de transferência (celular)',
	DASHBOARD_CHART_countByIMSITransferStatus_SATELLITE: 'Endpoints por status de transferência (satélite)',
	DASHBOARD_CHART_countByIMSITransferStatus_CELLULAR: 'Endpoints por status de transferência (celular)',
	DASHBOARD_CHART_summaryOfMonthlyEndpointUsage_CELLULAR: 'Uso total de dados para todos endpoints do ACC',
	DASHBOARD_CHART_countByState_SATELLITE: 'Endpoints por status (satélite)',
	DASHBOARD_CHART_countByState_CELLULAR: 'Endpoints por status (celular)',
	DASHBOARD_CHART_countByServiceType_SATELLITE: 'Endpoints por tecnologia (satélite)',
	DASHBOARD_CHART_countByServiceType_CELLULAR: 'Endpoints por tecnologia (celular)',
	DASHBOARD_CHART_countByRatePlan_SATELLITE: 'Endpoints por plano de tarifas (satélite)',
	DASHBOARD_CHART_countByRatePlan_CELLULAR: 'Endpoints por plano de tarifas (celular)',
	DASHBOARD_CHART_countByNetworkEntitlement_SATELLITE: 'Endpoints por direito de acesso à rede (satélite)',
	DASHBOARD_CHART_countByNetworkEntitlement_CELLULAR: 'Endpoints por direito de acesso à rede (celular)',
	DASHBOARD_CHART_countByNetworkCarrier_SATELLITE: 'Endpoints por operadora de rede (satélite)',
	DASHBOARD_CHART_countByNetworkCarrier_CELLULAR: 'Endpoints por operadora de rede (celular)',
	DASHBOARD_CHART_countByTechnology: 'Endpoints por tecnologia',
	DASHBOARD_CHART_countByM2MPlatform_SATELLITE: 'Endpoints por plataforma M2M (satélite)',
	DASHBOARD_CHART_countByM2MPlatform_CELLULAR: 'Endpoints por plataforma M2M (celular)',
	DASHBOARD_CHART_countByIMSI_SATELLITE: 'Endpoints por plataforma M2M',
	DASHBOARD_CHART_countByIMSI_CELLULAR: 'Endpoints por plataforma M2M',
	DASHBOARD_CHART_countByM2mPlatform_SATELLITE: 'Endpoints por plataforma M2M',
	DASHBOARD_CHART_countByM2mPlatform_CELLULAR: 'Endpoints por plataforma M2M',
	DASHBOARD_CHART_countByStatebyPlatformACC_CELLULAR: 'Endpoints por status (ACC)',
	DASHBOARD_CHART_countByStatebyPlatformEOD_CELLULAR: 'Endpoints por status (EOD)',
	DASHBOARD_CHART_countByStatebyPlatformGBCM_SATELLITE: 'Endpoints por status (GBCM)',
	DASHBOARD_CHART_countByStatebyPlatformDCP_CELLULAR: 'Endpoints por status (DCP)',
	DASHBOARD_CHART_countByStatebyPlatformCCIP_CELLULAR: 'Endpoints por status (CCIP)',
	DASHBOARD_CHART_countByStatebyPlatformVCC_CELLULAR: 'Endpoints por status (VCC)',
	DASHBOARD_CHART_countByStatebyPlatformGDSP_CELLULAR: 'Endpoints por status (GDSP)',
	DASHBOARD_CHART_countByStatebyPlatformWING_CELLULAR: 'Endpoints por status (WING)',
	DASHBOARD_CHART_countByProfile_CELLULAR: 'Endpoints por perfil',
	TICKET_STATUS: 'Status do tíquete',
	ENDPOINT_LOCATION: 'Localização do endpoint',
	ISSUE: 'Problema',
	SUGGESTIONS: 'Sugestões',
	PHONE: 'Telefone',
	FAILED_TO_PROVISION: 'Falha ao provisionar',
	SUBMITTED_BY: 'Enviado por',
	NEW_TICKET: 'Novo tíquete',
	ENDPOINT: 'Endpoint',
	ERROR_MESSAGE: 'Mensagem de erro',
	ATTACHED_FILE: 'Arquivo anexo',
	TICKETING: 'Emissão de tíquetes',
	GET_HELP_WITH_THIS_ENDPOINT: 'Obter ajuda para este endpoint',
	CATEGORY_AND_SUGGESTIONS: 'Categoria e sugestões',
	CONFIRMATION: 'Confirmação',
	RECORDED_STATUS: 'Status registrado',
	ENDPOINT_NAME: 'ID do endpoint',
	CONTACT_PHONE: 'Telefone de contato',
	CONTACT_EMAIL: 'E-mail de contato',
	SELECT_SUBCATEGORY: 'Selecionar subcategoria',
	FILE_UPLOADED: 'Arquivo carregado',
	FAQ_SUPPORT_INFO: 'Encontre respostas rápidas para as perguntas frequentes de seus usuários.',
	USER_GUIDE_SUPPORT_INFO: 'Leia instruções detalhadas para as funções do portal. O guia é personalizado para o seu tipo de conta.',
	TICKETING_SUPPORT_INFO: 'Caso esteja enfrentando problemas no serviço e os testes de diagnóstico não oferecerem um caminho de resolução, você pode criar um tíquete para o problema.',
	MUST_BE: 'Deve ter ',
	LESS_THAN: 'menos de ',
	CHARACTERS: ' caracteres',
	LOADING_DATA: 'Carregando dados',
	GPRS: 'GPRS',

	//  Suporte
	CONTENT: 'Conteúdo',
	CREATE_NEW_NOTIFICATION: 'Criar nova notificação',
	EDIT_NOTIFICATION: 'Revisar notificação',
	EDIT_FORM_TO_CREATE_NOTIFICATION: 'Revisar formulário para criar nova notificação.',
	NOTIFICATION_TYPE: 'Tipo de notificação?',
	SELECT_NOTIFICATION_PLACEHOLDER: 'Selecionar espaço reservado para notificação',
	CHOOSE_WHICH_USER_GROUPS_TO_NOTIFY: 'Selecionar grupos de usuários a serem notificados:',
	NOTIFY_ALL_GROUPS: 'Notificar todos os grupos',
	MANAGER: 'Gerente',
	DEVELOPER: 'Desenvolvedor',
	ADD_NOTIFICATION_CONTENT: 'Adicionar conteúdo da notificação',
	SUSPEND: 'Suspender',
	SUSPENDED: 'Suspenso',
	DEACTIVE: 'Desativo',
	PROVISIONED: 'Provisionado',
	ENDPOINT_COUNTRY: 'País do endpoint',
	ENDPOINT_STATE: 'Estado do endpoint',
	ENDPOINT_CITY: 'Cidade do enpoint',
	ENDPOINT_STREET_ADDRESS: 'Endereço do logradouro do endpoint',
	AS_OF: 'Processado em',
	VIEW_ALL_NOTIFICATIONS: 'Visualizar todas as notificações',
	NOTIFICATION_SCOPE: 'Escopo da notificação',
	NOTIFICATION1: 'Informação',
	NOTIFICATION2: 'Erro',
	NOTIFICATION3: 'Aviso',
	NOTIFICATION4: 'Confirmação',
	GLOBAL_NOTIFICATION: 'Notificação global',
	COMPANY_RELATED_NOTIFICATION: 'Notificação relacionada à empresa',
	MUST_BE_MORE_THAN: 'Deve ser maior que ',
	MUST_BE_LESS_THAN: 'Deve ser menor que',
	MUST_BE_LESS_THAN_OR_EQUAL_TO: 'Deve ser menor ou igual a',
	GLOBAL: 'Global',
	COMPANY_RELATED: 'Relacionado à empresa',
	RECENT_NOTIFICATIONS: 'Alertas e notificações do sistema',

	networkEntitlement: 'ID do direito de acesso à rede',
	networkType: 'Tipo de rede',
	transferStatus: 'Status da transferência de IMSI',
	rateplan: 'Plano de tarifas',
	FAILURE: 'Falha',
	PENDING_REIMSI_ATTEMPT: 'Tentativa de reemissão de IMSI pendente',
	REIMSI_QUEUED: 'Reemissão de IMSI em fila',
	PENDING_REIMSI_DELIVERED: 'Reemissão de IMSI pendente foi entregue',
	'PENDING_RE-IMSI_DELIVERY_FAILED': 'Falha ao entregar reemissão de IMSI pendente',

	'PENDING REIMSI ATTEMPT': 'Tentativa de reemissão de IMSI pendente',
	'REIMSI PENDING ATTEMPT': 'Tentativa de reemissão de IMSI pendente',
	'PENDING REIMSI DELIVERED': 'Transferência pendente foi entregue',
	'Re-IMSI Delivery Failed': 'Falha ao enviar a transferência',
	'REIMSI FAILED': 'Falha de reemissão de IMSI',
	'REIMSI QUEUED': 'Reemissão de IMSI em fila',
	'REIMSI PEDING ATEMPT': 'Tentativa de reemissão de IMSI pendente',
	'REIMSI PENDING ATEMPT': 'Tentativa de reemissão de IMSI pendente',
	'REIMSI PENDING DELIVERED': 'Reemissão de IMSI pendente foi entregue',
	'REIMSI SUCCESS': 'Reemissão de IMSI BEM-SUCEDIDA',
	failure: 'falha',

	'ID_PENDING REIMSI ATTEMPT': 'Tentativa de reemissão de IMSI pendente',
	'ID_REIMSI PENDING ATTEMPT': 'Tentativa de reemissão de IMSI pendente',
	'ID_PENDING REIMSI DELIVERED': 'Transferência pendente foi entregue',
	'ID_Re-IMSI Delivery Failed': 'Falha ao enviar a transferência',
	'ID_REIMSI FAILED': 'Falha de reemissão de IMSI',
	'ID_REIMSI QUEUED': 'Reemissão de IMSI em fila',
	'ID_REIMSI PEDING ATEMPT': 'Tentativa de reemissão de IMSI pendente',
	'ID_REIMSI PENDING ATEMPT': 'Tentativa de reemissão de IMSI pendente',
	'ID_REIMSI PENDING DELIVERED': 'Reemissão de IMSI pendente foi entregue',
	'ID_REIMSI SUCCESS': 'Reemissão de IMSI bem-sucedida',

	CONSUMED_BYTES: 'Megabytes consumidos',
	CONSUMED_SMSES: 'SMS consumidos',
	TO_DATE: 'até a presente data',

	CHART_EXISTS_IN_SETTINGS: 'Já existe este gráfico em suas configurações. Somente um tipo de gráfico é permitido.',
	NO_CHART_DATA: 'Faltam dados do gráfico',
	STATES_MESSAGE: 'Nenhum estado disponível',
	SELECT_RATE_PLAN: 'Selecionar plano de tarifas',
	CHANGE_RATE_PLAN: 'Alterar plano de tarifas',
	CURRENT_RATE_PLAN_FOR_ENDPOINT_ID: 'Plano atual de tarifas para ID do endpoint',
	IS: 'é',
	CONFIRM_RATE_PLAN_CHANGE: 'Confirmar alteração do plano de tarifas',
	RATE_PLAN_WILL_BE: 'Depois de iniciar esta alteração, o plano de tarifas do endpoint selecionado será ',
	WOULD_YOU_LIKE_TO_INITIATE_CHANGE_NOW: 'Você deseja iniciar a alteração agora?',
	RATE_PLAN_CHANGE_IS_SUCCESSFULLY_SCHEDULED: 'A alteração do plano de tarifas foi agendada com êxito',
	FOR: 'para',
	RATE_PLAN_CHANGE_IS_SUCCESSFULLY_UPDATED: 'A alteração do plano de tarifas foi atualizada com êxito',
	RATE_PLAN_CHANGE_COULD_NOT_BE_SCHEDULED_SUCCESSFULLY: 'Não foi possível agendar a alteração do plano de tarifas com êxito',
	RATE_PLAN_CHANGE_COULD_NOT_BE_CARRIED_OUT_SUCCESSFULLY: 'Não foi possível executar a alteração do plano de tarifas com êxito',
	ENDPOINT_RATE_PLAN: 'Plano de tarifas',
	DOWNLOAD_TEMPLATE_BATCH: 'Baixar modelo',
	CONFIRM_BATCH_FILE_UPLOAD: 'Confirmar carregamento do arquivo de lotes',
	SCHEDULED_ACTION_ON: 'Ação agendada em',
	FILE_MUST_BE_SUMITTED_WITH_CSV: 'Os arquivos devem ser enviados com a extensão .csv.',
	MAXIMUM_SIMS_PER_UPLOAD: 'Máxima quantidade de SIMs por carregamento é 10.000.',
	SIMS_HAVE: 'SIMs têm',
	CHANGE_FOR_ALL_SELECTED_SIMS: 'Selecionar plano de tarifas diferente para aplicar alteração em todos os SIMs selecionados',
	YOU_HAVE_CHOSEN_TO_CHANGE_THE_RATE_PLAN_OF_THE: 'Você optou por alterar o plano de tarifas dos',
	SELECTED_SIMS_TO: 'SIMs selecionados para',
	SIMS_RATE_PLAN_SUCCESSFULLY_REQUESTED: 'Planos de tarifas do SIM atualizados com êxito',
	SCHEDULE_RATE_PLAN_SUCCESSFUL: 'Plano de tarifas para múltiplos SIMs agendado com êxito',
	SIM_RATE_PLAN_CHANGE_FOR: 'Alteração do plano de tarifas do SIM para',
	MULTIPLE_RATE_PLAN_CHANGE_SCHEDULE_ERROR: 'Não foi possível alterar o plano de tarifas para múltiplos SIMs com êxito',
	MULTIPLE_RATE_PLAN_CHANGE_ERROR: 'Não foi possível solicitar alteração do plano de tarifas para múltiplos SIMs com êxito',
	SAME_RATE_PLAN_SELECTED: 'Foi selecionado o mesmo plano de tarifas',
	REASON: 'razão',

	API_DOCUMENTATION: 'Documentação de API',
	'Celular - Ativo': 'Celular - Ativado',
	'Satélite - Ativo': 'Satélite - Ativado',
	'Celular - Encerrar': 'Celular - Encerrado',
	'Satélite - Encerrar': 'Satélite - Encerrado',
	CHANGE_RATE_PLAN_NOT_ALLOWED: 'Não é permitida a alteração do plano de tarifas',
	reimsiqueued: 'Reemissão de IMSI em fila',
	reimsifailed: 'Falha de reemissão de IMSI',
	reimsisuccess: 'Reemissão de IMSI com êxito',
	reimsipendingattempt: 'Tentativa de reemissão de IMSI pendente',
	reimsipendingdelivered: 'Reemissão de IMSI pendente foi entregue',
	imsiPendingMessage: 'Se o dispositivo estiver no status pendente por mais de 72 horas, entre em contato com o serviço de atendimento ao cliente',
	ADD_ANOTHER_ACCOUNT: 'Adicionar outra conta',
	FILTER_DOES_NOT_HAVE_VALID_DATA: 'Erro de API, contatar o suporte.',
	FAILOVER: 'Failover',
	CANCEL_PENDING_FAIL_TITLE: 'Falha de cancelamento da alteração pendente',
	CANCEL_PENDING_FAIL_TEXT: 'Não foi possível concluir o cancelamento da alteração pendente com êxito neste momento',
	CANCEL_PENDING_SUCCESS_TITLE: 'Cancelamento da alteração pendente com êxito',
	CANCEL_PENDING_SUCCESS_TEXT: 'Cancelamento da alteração pendente concluído com êxito',
	CANCEL_CHANGE: 'Cancelar alteração',
	CAUTION: 'Cuidado',
	CANCEL_PROMPT_TITLE: 'Tem certeza que deseja cancelar a alteração pendente?',
	CANCEL_PROMPT_TEXT: 'Esta ação não pode ser desfeita.',
	CONFIRM_CANCELLATION: 'Confirmar cancelamento',
	NO_RATE_PLANS_AVAILABLE: 'Nenhum plano de tarifas disponível',
	BULK_UPLOAD: 'Upload em massa',
	MULTI_ENDPOINT_ACTION: 'Ação de endpoints múltiplos',
	PLEASE_SELECT_MORE_SIMSS: 'Selecionar dois ou mais endpoints',
	M2M_ACCOUNT_ID: 'ID da conta do M2M',
	REPORTS_PLATFORM: 'Plataforma M2M',
	ENDPOINT_CHART_SECOND_CARD: 'Sessões recentes',
	ENDPOINT_CHART_SECOND_CARD_ON: 'Em',
	ENDPOINT_CHART_THIRD_CARD: 'Local da rede de endpoint',
	NO_AVAILABLE_FILTERS: 'Nenhum filtro disponível',
	NO_AVAILABLE_FILTERS_SUBTITLE: 'Não há filtros disponíveis para mostrar no momento',

	// API de desenvolvedor
	GETTING_STARTED: 'Introdução',
	API_SANDBOX: 'Área restrita de API',
	DEVELOPER_API: 'API de desenvolvedor',
	GETTING_STARTED_WITH_API_T: 'Introdução à API',
	GETTING_STARTED_WITH_API_P: 'Antes que se possa fazer a primeira chamada de API, o endereço da IP de origem (servidor) deve constar da lista de permissões. A API de origem é normalmente configurada durante o processo de integração quando se cria o acesso à API. Se você receber mensagens de erro, provavelmente estão relacionados ao acesso bloqueado para o Gateway IoT da AT&T.',
	GETTING_STARTED_WITH_API_UL_T: 'Assegure-se de já ter executado os passos abaixo para dar início ao desenvolvimento:',
	GETTING_STARTED_WITH_API_LI1: '- Você configurou um servidor acessível publicamente e nos forneceu o endereço de IP durante a integração.',
	GETTING_STARTED_WITH_API_LI2: '- Sua IP de origem consta na lista de permissões para acessar o Gateway IoT da AT&T.',
	GETTING_STARTED_WITH_API_LI3: '- O administrador de sua empresa forneceu seu nome de usuário e senha de API criados ao habilitar o serviço de API.',
	GETTING_STARTED_WITH_API_LI4: '- Você tem acesso ao servidor de recursos para executar comandos curl, ou usar aplicativos de conexão redigidos no seu idioma preferido abaixo.',
	GET_OAUTH_TOKEN_T: 'Obter token Oauth de acesso',
	GET_OAUTH_TOKEN_P: 'Obter token OAuth da AT&T IoT Identity usando o nome do usuário e a senha fornecidos pelo seu administrador usando o método escolhido abaixo. O token é válido por 7 dias.',
	GETTING_STARTED_WITH_API_CONNECTORS_T: 'Conectores de API',
	GETTING_STARTED_WITH_API_CONNECTORS_P: 'Selecionar como você deseja se conectar à nossa API, entre os exemplos abaixo.',
	TEST_CONNECTION_T: 'Testar a conexão ao Gateway IoT',
	TEST_CONNECTION_P: 'Exemplo de Curl:',
	GETTING_STARTED_WITH_API_NEXT_STEP_T: 'Resultado desejado',
	GETTING_STARTED_WITH_API_NEXT_STEP_P: 'Se você receber o código de resposta HTTP 200 OK, tudo está pronto para iniciar o desenvolvimento.',
	GETTING_STARTED_WITH_API_ERRORS_T: 'Como solucionar problemas de erros de conexão',
	GETTING_STARTED_WITH_API_ERRORS_P: 'Se receber mensagens de erro, verifique primeiro se sua autenticação está definida corretamente no cabeçalho HTTP e se o token de acesso não está vencido e precisa ser atualizado. Além disso, executar o ping do Gateway IoT da AT&T para verificar se o IP de origem consta da lista de permissões. Caso obtenha resultados inesperados, você pode registrar um tíquete de resolução de problemas na seção de suporte.',
	API: 'API',
	SANDBOX_SPECIFIES_THE_LIST: 'A área restrita do Swagger abaixo especifica a lista de recursos disponíveis no API REST e as operações que podem ser efetuadas nesses recursos. O documento também especifica a lista de parâmetros para uma operação, incluindo o nome e tipo de parâmetros, se são opcionais ou obrigatórios, e informações dos valores aceitáveis para tais parâmetros.',
	JSON_SCHEMA_INCLUDES: 'O esquema JSON é incluído, descrevendo a estrutura do corpo de solicitação enviado para uma operação no API REST, juntamente com o esquema JSON que descreve a estrutura de corpos de resposta de pesquisas a respeito de uma operação.',

	GBCM_OPERATOR: 'Speedcast',
	ATT_OPERATOR: 'AT&T',

	CREATE_CUSTOM_REPORT: 'Criar relatório personalizado',
	YOU_MUST_CREATE_FILTER_BEFORE_EXPORT: 'Você deve criar um filtro antes que uma lista possa ser exportada.',
	CREATE_REPORT: 'Criar relatório',
	CHART_LEGEND_Active: 'Ativado',
	CHART_LEGEND_Terminate: 'Encerrado',
	WE_ARE_SORRY_THERE_IS_NO_DATA_TO_EXPORT: 'Infelizmente não há dados para exportar',
	EXPORT_FILTERED_DATA: 'Exportar dados filtrados',
	ID_DASHBOARD_CHARTS_summaryofmonthlyendpointusage: 'Resumo do uso mensal de endpoints',
	ID: 'ID',
	TAB: 'Guia',
	SORT_BY: 'Classificar por',
	SELECT_CHART_TYPE_TO_ADD: 'Selecionar tipo de gráfico a adicionar',
	RECREDENTIAL_STATUS: 'Status de recredenciamento',
	RECREDENTIAL_TRANSFER_STATUS: 'Status de recredenciamento',
	recredentialTransferStatus: 'Status de recredenciamento',
	M2MACCOUNTID: 'ID da conta do M2M',
	HOLDING_ACCOUNT_ID: 'ID da conta em espera',
	RE_CREDENTIAL: 'Recredenciamento',
	SELECT_OPERATOR: 'Selecionar operador',
	RE_CREDENTIAL_SIM: 'SIM de recredenciamento',
	CURRENT_PLATFORM: 'Plataforma atual',
	YOU_HAVE_CHOSEN_TO_RECREDENTIAL_THIS_SIM: 'Você optou por recredenciar este SIM para',
	YOU_HAVE_CHOSEN_TO_RECREDENTIAL_THESE_SIMS: 'Você optou por recredenciar estes SIMs para',
	YOU_MAY_INTIATE_THE_CHANGE_NOW: 'Você pode iniciar a alteração agora',
	RE_CREDENTIALING_STATUS: 'Status de recredenciamento',
	RE_CREDENTIALING_IS_SUCCESSFULLY_INITIATED: 'Recredenciamento iniciado com êxito',
	RE_CREDENTIALING_IS_SUCCESSFULLY_SCHEDULED: 'Recredenciamento agendado com êxito',
	RE_CREDENTIALING_IS_SUCCESSFULLY_SCHEDULED_FOR: 'Recredenciamento agendado com êxito para',
	RE_CREDENTIALING_COULD_NOT_BE_INITIATED_SUCCESSFULLY: 'Não foi possível iniciar o recredenciamento com êxito',
	RE_CREDENTIALING_COULD_NOT_BE_SCHEDULED_SUCCESSFULLY: 'Não foi possível agendar o recredenciamento com êxito',
	ENDPOINT_HISTORY: 'Histórico do endpoint',
	SHIPPED_DATE: 'Data de envio',
	DIAGNOSTIC_BUTTON: 'Iniciar diagnóstico',
	DEVICE_REGISTRATION_INFO_BUTTON: 'Informação de registro do dispositivo',
	ENDPOINT_REGISTRATION_DETAILS: 'Detalhes de registro de endpoint',
	eventTime: 'Carimbo de data e hora',
	carrierName: 'Nome da operadora',
	registrationType: 'Tipo de registro',
	NO_DATA_TO_DISPLAY_AT_THE_MOMENT: 'Não há dados para exibir no momento',
	status: 'Status',
	iccid: 'ICCID',
	carrier: 'Operadora',
	ATTACHED_CARRIER_NAME: 'Nome da operadora anexado',
	M2MPlatform: 'Plataforma M2M',
	simState: 'Obtendo status do dispositivo',
	'Endpoint Provisioning Information': 'Informação de provisionamento de endpoint',
	'M2M Platform': 'Plataforma M2M',
	'Network Connection': 'Conexão da rede',
	simStatusM2M: 'Obtendo status do dispositivo',
	billingStatus: 'Obtendo status do dispositivo',
	EndpointProvisioningInformation: 'Informação de provisionamento de endpoint',
	trafficAllowed: 'Obter informação de provisionamento de endpoint',
	permitPassingTraffic: 'Obter informação de provisionamento de endpoint',
	verifySimState: 'Obter informação do dispositivo',
	NetworkConnection: 'Conexão da rede',
	verifyConnection: 'Obter rede do dispositivo',
	successfullyConnectedToNetwork_inSession: 'Obter rede do dispositivo',
	verifyIPSession: 'Obter IP do dispositivo',
	dataSessionCurrentlySuccessful: 'Obter IP do dispositivo',
	dd_inventory: 'Inventário',
	dd_activated: 'Ativado',
	dd_active: 'Ativado',
	dd_inactivated: 'Inativo',
	dd_inactive: 'Inativo',
	dd_deactivated: 'Desativado',
	dd_retired: 'Aposentado',
	dd_purged: 'Limpo',
	dd_testready: 'Pronto para teste',
	dd_activationready: 'Data de ativação',
	dd_replaced: 'Substituído',
	dd_trial: 'Avaliação',
	dd_start: 'Iniciar',
	dd_transfer: 'Transferir',
	dd_globalintransitsource: 'Global em origem de trânsito',
	dd_globalintransitdestination: 'Global em destino de trânsito',
	dd_globaltransferred: 'Global transferido',
	dd_other: 'Outro',
	dd_permitpassingtraffic: 'Passagem de tráfego permitida',
	dd_notpermitpassingtraffic: 'Passagem de tráfego não permitida',
	dd_allowedToUseWirelessNetworkResources: 'Permitiu recursos sem fio TollSec',
	dd_barredFromNetwork: 'Barrado da rede',
	dd_trafficnotallowed_simstate: 'Tráfego não permitiu estado de SIM',
	dd_suspendedByServiceProvider: 'Suspenso pelo provedor de serviço',
	dd_suspendedDueToUsageLimit: 'Suspenso devido ao limite de uso',
	dd_trafficNotAllowed_others: 'Tráfego não permitido (outro)',
	dd_successfullyconnectedtonetwork_insession: 'Conectado à rede com êxito (em sessão)',
	dd_notsuccessfullyconnectedtonetwork_insession: 'Não conectado à rede (em sessão)',
	dd_validregistration_validnetworkactivity: 'Registro válido (atividade de rede válida)',
	dd_validregistration_nonetworkactivity: 'Registro válido (sem atividade de rede)',
	dd_validgsmregistrationonly: 'Somente registro de GSM válido',
	dd_novalidregistration_hasactivityinlast24h: 'Nenhum registro válido (apresentou atividade nas últimas 24 horas)',
	dd_novalidregistration_hasauthrequestinlast24h_nolocupdate: 'Nenhum registro válido (fez solicitação de autorização nas últimas 24 horas)',
	dd_novalidregistration_haslocupdatesmorethan24hago: 'Nenhum registro válido (possui atualizações locais há mais de 24 horas)',
	dd_novalidregistration_hasauthrequestmorethan24hago_noactivity: 'Nenhum registro válido (fez solicitação de autorização há mais de 24 horas)',
	dd_novalidregistration_nonetworkactivity: 'Há mais de 24 horas (sem atividade de rede)',
	dd_datasessioncurrentlysuccessful: 'Dispositivo não está conectado no momento. Sessões IP anteriores bem-sucedidas.',
	dd_notdatasessioncurrentlysuccessful: 'Sessão de dados atual mal sucedida',
	dd_datasessionpastsuccessful: 'Sessão de dados passou com êxito',
	dd_connectionattemptfailed_invalidcredentials: 'Falha na tentativa de conexão (credenciais inválidas)',
	dd_nodatasessionsuccessfulhistory: 'Nenhum histórico bem-sucedido da sessão de dados',
	dd_: '',
	dd_allowedtousewirelessnetworkresources: 'Permitiu recursos sem fio TollSec',
	attached: 'Anexado',
	detached: 'Desanexado',
	RECREDENTIALED: 'Recredenciado',
	RECREDENTIALING: 'Recredenciando',
	CHANGE_RECREDENTIAL: 'Recredenciamento só é permitido para as mesmas plataformas',
	RECREDENTIAL_DESCRIPTION: 'Dica: Ao recredenciar e selecionar SIMs múltiplos, você pode selecionar somente uma plataforma de recredenciamento para os SIMs múltiplos selecionados',
	RECREDENTIAL_NOT_ALLOWED: 'Não é permitido fazer o recredenciamento',
	RECREDENTIAL_SUCCESSFULLY_REQUESTED: 'SIMs iniciados com êxito',
	MULTIPLE_RECREDENTIAL_CHANGE_ERROR: 'Não foi possível iniciar o recredenciamento SIMs múltiplos com êxito',
	MOBILE_NETWORK_REGISTRATION_HISTORY: 'Histórico de registro da rede móvel',
	ENDPOINT_DAILY_USAGE: 'Uso diário de endpoints',
	ENDPOINT_CYCLE_USAGE: 'Uso do ciclo do endpoint até o momento ',
	CONFIRM_RE_CREDENTIALING: 'Confirmar recredenciamento',
	PENDING_RECREDENTIAL: 'Recredenciamento do endpoint está pendente',
	ENDPOINTS_ARE: 'endpoints estão selecionados para a exportação',
	ENDPOINT_IS: 'endpoint está selecionado para a exportação',
	CUSTOM_REPORT_WILL_BE_AVAILABLE: 'Este relatório personalizado estará disponível na seção de relatórios',
	YOU_CAN_APPEND_CUSTOM_NAME: 'Você pode acrescentar um nome de relatório personalizado aqui',
	CUSTOM_REPORT_NAME_APPENDIX: 'Apêndice do nome de relatório personalizado',
	ADD_CUSTOM_REPORT_NAME_APPENDIX: 'Adicionar apêndice do nome do relatório personalizado (somente dígitos e letras são permitidos)',
	CUSTOM_REPORT: 'Relatório personalizado',
	CUSTOM_REPORT_CREATED: 'Relatório personalizado criado com êxito',
	GO_TO_REPORTS: 'Ir para os relatórios',
	DIAGNOSTICS_FOR_THIS_ENDPOINT: 'Os diagnósticos deste endpoint estão atualmente indisponíveis',
	ENDPOINT_DATA_INFORMATION: 'Informações de dados de endpoints',
	LAST_SESSION: 'Última sessão',
	CARRIER_ATTACHED: 'Operadora anexada',
	APN_DCP: 'APN',
	IP_ADDRESS: 'Endereço de IP',
	LAST_SENT_SMS_MO: 'Data e hora do envio da última MO de SMS',
	LAST_SENT_SMS_MT: 'Data e hora do envio do último MT de SMS',
	TEST_DETAILS: 'Detalhes do teste',
	CHECK_BLOCKED_STATUS: 'Verificar status bloqueado',
	BLOCKED_STATUS: 'Status bloqueado',
	RETRY_TEST: 'Tentar teste novamente',
	MOBILE_NETWORK_TEST: 'Teste da rede móvel',
	CHECK_BLOCKED_SUB: 'Verificar assinatura bloqueada',
	TROUBLESHOOT_DCP: 'Solucionar problemas da DCP',
	locationUpdate: 'Atualização da localização',
	pdpStatus: 'Status de PDP',
	cancelLocation: 'Cancelar localização',
	CANCEL_LOCATION: 'Cancelar localização',

	DASHBOARD_CHART_CountofDailyEndpointSMSbyPlatformDCP_CELLULARDescription: 'Mostra o número total de mensagens SMS enviadas por dia ou mensalmente pela plataforma DCP',
	DASHBOARD_CHART_CountofDailyEndpointSMSbyPlatformDCP_CELLULAR: 'SMS por dia (DCP)',
	DASHBOARD_CHART_CountofMonthlyEndpointSMSbyPlatformDCP_CELLULAR: 'SMS por mês (DCP)',
	DASHBOARD_CHART_SumofDailyEndpointUsagebyPlatformDCP_CELLULAR: 'Total diário do uso de endpoints (DCP)',
	DASHBOARD_CHART_SumofDailyEndpointUsagebyPlatformACC_CELLULAR: 'Uso diário de dados (ACC)',
	DASHBOARD_CHART_SumofMonthlyEndpointUsagebyPlatformDCP_CELLULAR: 'Uso mensal de dados (DCP)',
	DASHBOARD_CHART_CountofMonthlyEndpointSMSbyPlatformDCP_CELLULARDescription: 'Mostra o número total de mensagens SMS enviadas por mês pela plataforma DCP',
	DASHBOARD_CHART_SumofDailyEndpointUsagebyPlatformDCP_CELLULARDescription: 'Mostra o uso total de dados por dia em um mês pela plataforma DCP',
	DASHBOARD_CHART_SumofDailyEndpointUsagebyPlatformACC_CELLULARDescription: 'Mostra o uso total de dados por dia em um mês pela plataforma ACC',
	DASHBOARD_CHART_SumofMonthlyEndpointUsagebyPlatformDCP_CELLULARDescription: 'Mostra o uso total de dados por mês pela plataforma DCP',
	DASHBOARD_CHART_CountofMonthlyEndpointSMSbyPlatformCCIP_CELLULAR: 'SMS por mês (CCIP)',
	DASHBOARD_CHART_CountofMonthlyEndpointSMSbyPlatformCCIP_CELLULARDescription: 'Mostra o número total de mensagens SMS enviadas por mês pela plataforma CCIP',
	DASHBOARD_CHART_CountofDailyEndpointSMSbyPlatformCCIP_CELLULAR: 'SMS por dia (CCIP)',
	DASHBOARD_CHART_CountofDailyEndpointSMSbyPlatformCCIP_CELLULARDescription: 'Mostra o número total de mensagens SMS enviadas por dia pela plataforma CCIP',
	DASHBOARD_CHART_SumofDailyEndpointUsagebyPlatformCCIP_CELLULAR: 'Uso diário total de endpoint (CCIP)',
	DASHBOARD_CHART_SumofDailyEndpointUsagebyPlatformCCIP_CELLULARDescription: 'Mostra o uso total de dados por dia em um mês pela plataforma CCIP',
	DASHBOARD_CHART_SumofMonthlyEndpointUsagebyPlatformCCIP_CELLULAR: 'Uso de dados mensal (CCIP)',
	DASHBOARD_CHART_SumofMonthlyEndpointUsagebyPlatformCCIP_CELLULARDescription: 'Mostra o uso total de dados por mês pela plataforma CCIP',
	DASHBOARD_CHART_countByCountry_CELLULAR: 'Endpoints por país',
	DASHBOARD_CHART_countByCountry_CELLULARDescription: 'Mostra o número de endpoints em diversos países.',
	DASHBOARD_CHART_countByRoamingCountry_CELLULAR: 'Endpoints por país de roaming (celular)',
	DASHBOARD_CHART_countByRoamingCountry_CELLULARDescription: 'Mostra o número de endpoints por país de roaming.',
	DASHBOARD_CHART_countByRoamingCarrier_CELLULAR: 'Endpoints por operadora de roaming (celular)',
	DASHBOARD_CHART_countByRoamingCarrier_CELLULARDescription: 'Mostra o número de endpoints de celulares por operadora de roaming.',
	DASHBOARD_CHART_countByRoamingCarrier_SATELLITE: 'Endpoints por operadora de roaming (satélite)',
	DASHBOARD_CHART_countByRoamingCarrier_SATELLITEDescription: 'Mostra o número de endpoints de satélite por operadora de roaming.',

	SMS_HISTORY: 'Histórico de SMS',
	MAXIMUM_NUMBER_OF_CHARACTERS: 'Número máximo de caracteres: ',
	SEND_SMS_MESSAGE: 'Enviar SMS',
	SMS_NOTIFICATION_TITLE: 'Notificação de SMS',
	SMS_NOTIFICATION_MESSAGE: 'Erro de resposta: ',
	SMS_NOTIFICATION_MESSAGE_SUCCESS: 'SMS enviado com êxito',

	REVIEW: 'Revisão',
	PLATFORM_DETAILS: 'Detalhes da plataforma',

	UPDATING: 'Atualizando',
	AUTOMATION_RULE_SUCCESSFULLY_DELETED: 'Regra de automação excluída com êxito',
	AUTOMATION_DELETE_ERROR: 'Falha ao excluir regra de automação, tente novamente.',
	FETCH_DATA_ERROR: 'Não é possível buscar dados',
	CHANGED_BY: 'Alterado por',
	EMAIL_SUB: 'Enviar e-mail',
	CTD_USAGE: 'Uso de dados do ciclo até o momento',
	PAST24H_DATA_USAGE_EXCEEDED: 'Uso de dados excedido nas últimas 24 horas',
	SMS_USAGE: 'Uso de SMS do ciclo até o momento',
	EXCEEDLIMIT: 'Excede',
	APPROACHLIMIT: 'Aproxima-se',
	trigger: 'Acionamento',
	TWENTYFOURHOURS: 'Últimas 24 horas',
	CTD: 'Ciclo até o momento',
	ADD_RULE_SUCCESS: 'Regra criada',
	ADD_RULE_ERROR: 'Falha ao criar regra de automação, tente novamente.',
	EDIT_RULE_SUCCESS: 'Regra revisada',
	EDIT_RULE_ERROR: 'Falha ao revisar regra de automação, tente novamente.',
	ACC_AUTOMATION: 'Automação de ACC',
	ACC_ACCOUNT_ID: 'ID da conta ACC',
	CREATE_NEW_AUTOMATION_RULE: 'Criar nova regra de automação',
	DCP_AUTOMATION: 'Automação de DCP',
	DCP_ACCOUNT_ID: 'ID da conta DCP',
	ACC_ACCOUNT: 'Conta ACC',
	RULE: 'Regra',
	ACC_IF: 'Se',
	TRESHOLD: 'Limite',
	THEN: 'Então',
	ACC_RULE_NAME: 'Nome da regra',
	SAVE_AND_ACTIVATE: 'Salvar e ativar',
	DCP_ACCOUNT: 'Conta DCP',
	SELECT_BY: 'Selecionar por',
	DCP_BYTES_SENT: 'bytes enviados',
	SMSES_SENT: 'SMSes enviados',
	SMSES: 'SMSes',
	DASHBOARD_FEEDBACK: 'Painel',
	MUST_BE_BETWEEN: 'Deve ter entre ',
	COMPANIES_STEPS: 'Etapas das empresas',
	USER_NAME: 'NOME DO USUÁRIO',
	ID_DASHBOARD_CHARTS_countofmonthlyendpointsmsbyplatformdcp: 'SMS por mês',
	ID_DASHBOARD_CHARTS_countofdailyendpointsmsbyplatformdcp: 'SMS por dia',
	ID_DASHBOARD_CHARTS_sumofdailyendpointusagebyplatformdcp: 'Uso diário total de endpoint',
	ID_DASHBOARD_CHARTS_sumofdailyendpointusagebyplatformacc: 'Uso de dados diário',
	ID_DASHBOARD_CHARTS_sumofmonthlyendpointusagebyplatformdcp: 'Uso de dados mensal',
	DASHBOARD_CHART_DeviceType_SATELLITE: 'Tipo de dispositivo (satélite)',
	DASHBOARD_CHART_DeviceType_CELLULAR: 'Tipo de dispositivo (celular)',
	DASHBOARD_CHART_DeviceType_SATELLITEDescription: 'Mostra o número total de dispositivos de diferentes tipos',
	DASHBOARD_CHART_DeviceType_CELLULARDescription: 'Mostra o número total de dispositivos de diferentes tipos',
	AUTOMATION_ALERTS: 'Alertas de automação',
	STATE: 'Estado',
	COMPANY_EMAIL: 'E-mail da empresa',
	CUSTOM_FIELD_LABELS: 'Etiquetas de campos personalizados',
	MY_COMPANY: 'Minha empresa',
	AUTOMATION_ACCOUNT: 'Conta',
	UPDATE_CUSTOM_FIELD_LABELS_SUCCESS: 'Configurações de etiquetas personalizadas salvas',
	UPDATE_CUSTOM_FIELD_LABELS_ERROR: 'Não foi possível salvar as configurações de etiquetas personalizadas',
	WRITE_SMS_MESSAGE_HERE: 'Escreva a mensagem aqui...',
	RE_CREDENTIALING_ALLOWED: 'Recredenciamento não é permitido',
	DELETE_AUTOMATION_RULE_QUESTION: 'Excluir regra de automação?',
	DELETE_AUTOMATION_RULE_MESSAGE: 'Tem certeza de que deseja excluir a regra de automação ',
	PLATFORM_SUCCESSFULLY_DELETED: 'Plataforma excluída com êxito',
	PLATFORM_DELETE_ERROR: 'Falha ao excluir a plataforma, tente novamente.',
	CELLULAR: 'Celular',
	SATELLITE: 'Satélite',
	SAVE_CUSTOM_LABELS: 'Salvar etiquetas personalizadas',

	// ccip
	ENDPOINT_PROVISIONING_INFORMATION: 'Informação de provisionamento de endpoint',
	CCIP_3G: '3G',
	VOICE_ENABLED: 'Habilitado para voz',
	DATA_ENABLED: 'Habilitado para dados',
	SMS_ENABLED: 'Habilitado para SMS',
	VOICE_ROAMING_ENABLED: 'Habilitado para roaming de voz',
	DATA_ROAMING_ENABLED: 'Habilitado para roaming de dados',
	CCIP_LTE: 'Lte',
	LTE_ENABLED: 'Habilitado para LTE',
	ROAMING_ENABLED: 'Habilitado para roaming',
	MOBILE_NETWORK_ACCESS: 'Acesso à rede móvel',
	VOICE_ONLY: 'Somente voz',
	REGISTERED_ON_NETWORK: 'Registrado na rede',
	REGISTERED_ON_3G_NETWORK: 'Registrado na rede 3G',
	THIS_EMAIL_ADDRESS_IS_ALREADY_IN_USE: 'Este endereço de e-mail já está em uso',
	THIS_EMAIL_ADDRESS_DOES_NOT_EXIST: 'Este endereço de e-mail não existe',
	USER_SUCCESSFULLY_DELETED: 'Usuário excluído com êxito',
	USER_DELETE_ERROR: 'Falha ao excluir o usuário, tente novamente.',
	CATEGORIES: 'Categorias',
	TRIGGERS: 'Acionamento',
	FETCH_DATA_LOADING_MESSAGE: 'Aguarde enquanto carregamos os dados',
	M2M_ACCOUNT_ID_PLATFORM: 'ID da conta do M2M',
	M2MACCOUNTS: 'Conta do M2M',

	FROM_VALUE: 'DO VALOR',
	TO_VALUE: 'PARA O VALOR',

	SEARCH_REPORTS: 'Pesquisar relatórios',
	SEARCH_INVOICES: 'Pesquisar faturas',

	CUSTOM_DATA_FIELDS: 'Campos de dados personalizados',
	ENDPOINT_ACTIONS: 'Ações de endpoint para',
	RE_CREDENTIAL_NOT_ALLOWED: 'Recredenciamento não é permitido',
	EDIT_ENDPOINT: 'Ações de endpoint',
	CURRENT_SIM_STATE_FOR_ENDPOINT_ID: 'Atual estado do Sim ID do endpoint',

	NETWORK_ENTITLEMENT_NAME: 'Nome do direito de acesso à rede',
	BLOCK_PREMIUM_NUMBERS: 'Bloquear números premium',
	BLOCK_INTERNATIONAL_MO: 'Bloquear MO de SMS internacional, exceto no país de residência',
	HLR_TEMLATE: 'Detalhes do modelo HLRHSS',
	HOTLINE_NUMBER: 'Número da linha direta',
	FACETIME: 'FACETIME',
	VOLTE: 'Volte',
	GDSP_SESSION_ACTIVE: 'Ativo',
	GDSP_SESSION_BYTES_IN: 'Bytes de entrada',
	GDSP_SESSION_BYTES_OUT: 'Bytes de saída',
	GDSP_SESSION_LAST_IP_ADDRESS: 'Último endereço de IP',
	GDSP_SESSION_LAST_STARTED_TIMESTAMP: 'Carimbo de data/hora iniciado pela última vez',
	GDSP_SESSION_LAST_UPDATED_TIMESTAMP: 'Último carimbo de data/hora atualizado',
	GDSP_SESSION_LAST_TERMINATED_TIMESTAMP: 'Último carimbo de data/hora encerrado',
	GDSP_SESSION_LAST_DATA_TRANSMITED_TIMESTAMP: 'Último carimbo de data/hora com dados transmitidos',
	GDSP_SESSION_PREVIOUS_NORMAL_TIMESTAMP: 'Carimbo de data/hora normal anterior',
	GDSP_SESSION_LAST_SMS_MO_TIMESTAMP: 'Último carimbo de data/hora de MO de SMS',
	GDSP_SESSION_LAST_SMS_MT_TIMESTAMP: 'Último carimbo de data/hora de MT de SMS',
	GDSP_SESSION_LAST_SMS_MT_STATUS: 'Último status de MT de SMS',
	GDSP_SESSION_LAST_SMS_MO_STATUS: 'Último status de MO de SMS',
	GDSP_SESSION_LAST_WAKEUP_REQUEST_TIMESTAMP: 'Último carimbo de data/hora com solicitação de despertar',
	GDSP_SESSION_LAST_WAKEUP_REQUEST_TYPE: 'Último tipo de solicitação de despertar',
	GDSP_SESSION_LAST_WAKEUP_STATUS: 'Último status de despertar',
	GDSP_SESSION_LAST_WAKEUP_STATUS_TIMESTAMP: 'Último carimbo de data/hora do status de despertar',
	GDSP_SESSION_LAST_NOTIFICATION_FAILURE_TIMESTAMP: 'Último carimbo de data/hora da falha de notificação',
	GDSP_SESSION_LAST_NOTIFICATION_FAILURE_REASON: 'Última razão da falha de notificação',

	CUSTOM_FIELD_1: 'Campo personalizado 1',
	CUSTOM_FIELD_2: 'Campo personalizado 2',
	CUSTOM_FIELD_3: 'Campo personalizado 3',
	CUSTOM_FIELD_4: 'Campo personalizado 4',
	CUSTOM_FIELD_5: 'Campo personalizado 5',
	CUSTOM_FIELD_6: 'Campo personalizado 6',
	CUSTOM_FIELD_7: 'Campo personalizado 7',
	CUSTOM_FIELD_8: 'Campo personalizado 8',
	CUSTOM_FIELD_9: 'Campo personalizado 9',
	CUSTOM_FIELD_10: 'Campo personalizado 10',

	/* ----- GDSP ------- */
	REGISTRATION_STATUS: 'Status do registro',
	NETWORK_CONNECTIONS: 'Conexões da rede',
	PROVISIONED_DATE_AND_TIME: 'Data e hora provisionadas',

	// GDSP SIM STATE STATUS
	M2M_PLATFORM_STATUS_A: 'Vida ativa',
	M2M_PLATFORM_STATUS_B: 'Novo inativo',
	M2M_PLATFORM_STATUS_C: 'Suspensão ativa',
	M2M_PLATFORM_STATUS_D: 'Parado inativo',
	M2M_PLATFORM_STATUS_I: 'Migrado de outro ambiente GDSP',
	M2M_PLATFORM_STATUS_N: 'Não provisionado',
	M2M_PLATFORM_STATUS_O: 'Migrado para outro ambiente GDSP',
	M2M_PLATFORM_STATUS_R: 'Pronto ativo',
	M2M_PLATFORM_STATUS_S: 'Suspender ativo',
	M2M_PLATFORM_STATUS_T: 'Teste ativo',
	M2M_PLATFORM_STATUS_U: 'Em standby ativo',
	M2M_PLATFORM_STATUS_V: 'Transformado inativo',
	M2M_PLATFORM_STATUS_W: 'Suspender regulatório',
	M2M_PLATFORM_STATUS_Z: 'Encerrado',
	M2M_PLATFORM_STATUS_NOT_AVAILABLE: 'Indisponível',

	// Códigos do status da tab da sessão GDSP
	// lastWakeupStatus
	LASTWAKEUPSTATUS_IR: 'Chamada de despertar inválida',
	LASTWAKEUPSTATUS_FI: 'Iniciação de despertar falhou',
	LASTWAKEUPSTATUS_SI: 'Iniciação de despertar com êxito',
	LASTWAKEUPSTATUS_F: 'Falha ao enviar despertar',
	LASTWAKEUPSTATUS_S: 'Êxito ao enviar despertar',
	TOOLTIP_LASTWAKEUPSTATUS_IR: 'Solicitação incorreta de API',
	TOOLTIP_LASTWAKEUPSTATUS_FI: 'Falha de SMSC',
	TOOLTIP_LASTWAKEUPSTATUS_SI: 'Envio bem-sucedido para SMSC',
	TOOLTIP_LASTWAKEUPSTATUS_F: 'SMSC emitiu notificação de envio indicando falha',
	TOOLTIP_LASTWAKEUPSTATUS_S: 'SMSC emitiu notificação de envio indicando êxito',

	// lastSmsMtStatus
	LASTSMSMTSTATUS_IR: 'Chamada de despertar inválida',
	LASTSMSMTSTATUS_FI: 'Iniciação de despertar falhou',
	LASTSMSMTSTATUS_SI: 'Iniciação de despertar com êxito',
	LASTSMSMTSTATUS_F: 'Falha ao enviar despertar',
	LASTSMSMTSTATUS_S: 'Êxito ao enviar despertar',
	TOOLTIP_LASTSMSMTSTATUS_IR: 'Solicitação incorreta de API',
	TOOLTIP_LASTSMSMTSTATUS_FI: 'Falha de SMSC',
	TOOLTIP_LASTSMSMTSTATUS_SI: 'Envio bem-sucedido para SMSC',
	TOOLTIP_LASTSMSMTSTATUS_F: 'SMSC emitiu notificação de envio indicando falha',
	TOOLTIP_LASTSMSMTSTATUS_S: 'SMSC emitiu notificação de envio indicando êxito',

	/* ----- GDSP END ------- */

	/* API ERROR CODES  */

	CODE_400203: 'Falta um parâmetro necessário',
	CODE_400204: 'Um parâmetro não tem o tamanho correto',
	CODE_401205: 'Token inválido ou vencido',
	CODE_400207: 'Um parâmetro não tem o valor válido entra as possíveis opções de valores',
	CODE_400208: 'Um parâmetro não tem o formato correto',
	CODE_400209: 'Parâmetro duplicado',
	CODE_400299: 'Solicitação incorreta',
	CODE_400301: 'Erros de sistemas a jusante',
	CODE_401301: 'Falha de credencial de sistemas a jusante',
	CODE_404301: 'ID do dispositivo não encontrado no sistema a jusante',
	CODE_404302: 'ID da conta inválida ou não encontrada no sistema a jusante',
	CODE_502100: 'Alteração não permitida, o dispositivo já está agendado',
	CODE_400100: 'Solicitação inválida: Ação de lotes não é permitida para dispositivos de satélite',
	CODE_404201: 'O arquivo não existe',
	CODE_401202: 'Nome do usuário ou senha inválidos',
	CODE_400206: 'O formato do nome do arquivo é inválido',
	CODE_502200: 'Erro de arquivo em lotes',
	CODE_502202: 'Erro na recuperação do banco de dados',
	CODE_502203: 'Erro no banco de dados ao fazer alterações',
	CODE_502204: 'Erro ao criar cliente',
	CODE_502301: 'Erro no processamento de API PUSH',
	CODE_404303: 'ID do dispositivo não encontrado na notificação de API PUSH',
	CODE_502501: 'Erro no processamento de relatórios do SFTP',
	CODE_undefined: ' ',
	CODE_INTEGRATION_TIMEOUT: 'Não é possível alcançar a plataforma subjacente Tente de novo mais tarde.',
	CODE_VTM_SVC_40003: 'The entered value was not recognized',
	CODE_VTM_SVC_40004: 'Unable to create the support ticket',
	CODE_VTM_SVC_40005: 'Unable to create the support ticket',
	CODE_VTM_SVC_40409: 'Ticket does not exist: It may have been removed due to AT&T data retention policy',
	CODE_VTM_SVC_40411: 'Ticket does not exist or it is closed by an agent: It may have been removed due to AT&T data retention policy',
	CODE_VTM_SVC_40027: 'Cannot update canceled or closed support ticket: This ticket is closed or canceled and cannot be updated.',
	CODE_VTM_SVC_40035: 'Unable to add engagement: Engagement ticket already exist',
	CODE_VTM_SVC_50001: 'Something went wrong: Try again. If the problem persists, please report your issue by calling AT&T',
	CODE_VTM_SVC_40038: 'Unable to update the support ticket: Circuit details are the same',
	CODE_VTM_SVC_40039: 'Cannot create the support ticket: Asset info is missing in the system',
	CODE_VTM_SVC_40040: 'Cannot add a PVC value to support ticket: pvcID details already exist in the system',
	CODE_VTM_SVC_40041: 'Unable to update support ticket: clkNumber does not exist',
	CODE_VTM_SVC_40042: 'Unable to update support ticket: pvcID does not exist',
	CODE_VTM_SVC_40043: 'Unable to retrieve ticket history: Please try again later',
	CODE_VTM_SVC_40010: 'Unable to create the support ticket: Field exceeded maximum of 199 characters. Please correct the length and try again',
	CODE_VTM_SVC_40053: 'Unable to create support ticket: Case ID does not have Ticket Role defined. or is invalid support role',
	CODE_EOD_11: 'Unable to process the request: Please try again in a few minutes.',
	CODE_EOD_59: 'Cannot deactivate device without static IP: Before a device can be deactivated (put on hold), it needs to have a static IP. You can still deactivate this device by deprovisioning it (purge) from the system.',
	CODE_EOD_60: 'Cannot deactivate inactive device',
	CODE_EOD_46: 'The SIM is already in the provisioning queue',
	CODE_EOD_30: 'The SIM cannot be suspended using a past date',
	CODE_EOD_32: 'Internal system error: Contact customer support or create a trouble ticket to get help with this issue',
	CODE_EOD_41: 'Cannot change profile for suspended devices: Contact customer support or create a trouble ticket to get help with this issue',
	CODE_EOD_45: 'Invalid SIM or SIM does not belong to your account: Contact customer support or create a trouble ticket to get help with this issue',
	CODE_EOD_16: 'SIM has reached the maximum number of Rate Plan changes allowed during the Billing Cycle: Could not complete the Rate Plan change request. Please try again in the next billing cycle.',
	CODE_EOD_17: 'SIM has reached the maximum number Rate Plan changes allowed for the day: Could not complete the Rate Plan change request. Please try again tomorrow.',
	CODE_EOD_25: 'The device is currently in a suspended state and so cannot resume before the suspended date',
	CODE_EOD_27: 'The SIM cannot be suspended using a past date',
	CODE_EOD_3: 'Device is already inactive',
	CODE_EOD_401: 'Unable to authorize user performing the batch action with device platform.',

	/* API ERROR CODES  */

	SERVICE_STATUS: 'Status de 360L',
	SERVICESTATUS: 'Status de 360L',
	OEM: 'Contas de assinatura',
	ROAMING_COUNTRY: 'País de roaming',
	ROAMING_CARRIER: 'Operadora de roaming',
	ROAMINGCOUNTRY: 'País de roaming',
	ROAMINGCARRIER: 'Operadora de roaming',
	OEM_TOOLTIP: 'Contas 360L vinculadas',
	SIM_STATE: 'Estado do SIM',

	NO_DATA_ENDPOINT_HISTORY: 'Nenhum histórico do endpoint',
	PRINT_PDF: 'Imprimir PDF',
	USER_GUIDE: 'Guia do usuário',
	USER_GUIDE_MENU: 'Menu do guia do usuário',
	FAQ_MENU: 'Menu de perguntas frequentes',
	TABLE_OF_CONTENTS: 'Índice',
	RATE_PLAN_TYPE: 'Tipo do plano de tarifas',
	SUBSCRIPTION_PRICE: 'Preço de assinaturas',
	ACCOUNT_CHARGE: 'Cobrança da conta',
	PAYMENT_TYPE: 'Tipo de pagamento',
	TERM: 'Prazo',
	USAGE_LIMIT: 'Limite de uso',
	TIERING_FLAG: 'Sinalizador de camada',

	RATE_PLAN_CHANGE: 'Alteração no plano de tarifas',
	RATE_PLAN_CHANGED_TO: 'Você optou por alterar o plano de tarifas para',
	SCHEDULE_RATE_PLAN_CHANGE: 'Agendar alteração do plano de tarifas',
	RATE_PLAN_CHANGE_IS_SUCCESSFULLY_SCHEDULED_ERROR: 'Não foi possível agendar a alteração do plano de tarifas com êxito!',
	RATE_PLAN_CHANGE_IS_SUCCESSFULLY_SCHEDULED_FOR: 'A alteração do plano de tarifas foi agendada com êxito para',
	RATE_PLAN_IS_CHANGED_TO: 'O plano de tarifas foi mudado para ',
	FAILED_TO_PROVISION_AFTER_RATE_PLAN_CHANGE: ' Falha ao provisionar após alteração do plano de tarifas. ',
	SEND_EMAIL_TO_CONSUMER: 'Enviar e-mail para consumidor',
	PUSH_API: 'API push',
	INITIATE_OTA_SWAP: 'Iniciar troca de OTA',
	UPDATE_PCRF: 'Atualizar PCRF',
	CHANGE_DEVICES_NETWORK_ENTITLEMENT: 'Alterar direito de acesso à rede do dispositivo',
	CHANGE_DEVICES_SIM_STATE: 'Alterar estado de SIM do dispositivo',
	CHANGE_DEVICES_RATE_PLAN: 'Alterar plano de tarifas do dispositivo',
	NUMBER_OF_DAYS: 'Número de dias',
	NUMBER_OF_HOURS: 'Número de horas (máx. 24 horas)',
	AT: 'Em',
	FILTER: 'FILTRO',
	FOLLOW_UP_WITH: 'Acompanhar com',
	CUSTOM: 'Personalizar',
	ELIGIBLE_RATE_PLAN: 'Plano de tarifas qualificado',
	CHOOSE_RATE_PLAN: 'Selecionar plano de tarifas',
	CUSTOMER_ID: 'ID do cliente',
	INVALID_URL_FORMAT: 'Formato de URL inválido',
	INVALID_PORT_NUMBER: 'Número da PORTA inválido',
	INVALID_INPUT: 'Entrada inválida',
	RATE_PLAN_CHANGE_SUPPORT: 'Erro no plano de tarifas, entre em contato com o serviço de atendimento ao cliente.',
	NETWORK_ENTITLEMENT_PLACEHOLDER: 'XYX GPRS/LTE/SMS/IMS RES 165',
	M2M_SUB_ACCOUNT_ID: 'ID da conta de assinatura M2M',

	/* SIM ORDERING */
	SIM_ORDERING: 'Pedido de SIM',
	CREATE_SIM_ORDER: 'Criar pedido de SIM',
	NEW_SIM_ORDER: 'Novo pedido',
	EXISTING_SIM_ORDER: 'ID do pedido: {id}',
	M2M_ACCOUNT: 'Conta do M2M',
	M2M_ACCOUNT_PLACEHOLDER: 'Selecionar sua conta do M2M',
	ORDER_ID: 'ID do pedido',
	ORDER_NUMBER_ID: 'Pedir ID do número',
	ORDER_CONFIRMATION_NUMBER: 'Pedir número da confirmação',
	ORDER_DATE: 'Data do pedido',
	PURCHASE_ORDER_NUMBER: 'Número da ordem de compra',
	SKU: 'SKU',
	SKU_PLACEHOLDER: 'Selecionar SKU',
	UNIT_PRICE: 'Preço unitário',
	QUANTITY: 'Quantidade',
	QUANTITY_LIMIT_TEMPORARILY_REACHED: 'Limite da quantidade foi temporariamente alcançado',
	MIN_MAX_QUANTITY: 'Mín. {min} / Máx. {available} {max}',
	INCREMENT_QUANTITY: 'Incremento {increment}',
	ORDER_STATUS: 'Status do pedido',
	ORDER_STATUS_PENDING: 'Pendente',
	ORDER_STATUS_RECEIVED: 'Recebido',
	ORDER_STATUS_SHIPPED: 'Enviado',
	ORDER_STATUS_COMPLETED: 'Concluído',
	ORDER_STATUS_SUCCESSFUL: 'Bem-sucedido',
	ORDER_STATUS_FAILED: 'Falhou',
	CONTACT_NAME: 'Nome de contato',
	TELEPHONE_NUMBER: 'Número do telefone',
	TARGET_IMPLEMENTATION_DATE: 'Data de implementação prevista',
	EXPEDITED: 'Emitido',
	SHIPPING_ADDRESS: 'Endereço para remessa',
	ADDRESS_LINE_1: 'Linha de endereço 1',
	ADDRESS_LINE_2: 'Linha de endereço 2',
	SHIPPING_NOTES: 'Notas para remessa',
	SHIPPING_NUMBER: 'Número da remessa',
	EXPEDITED_SHIPPING: 'Remessa acelerada',
	TOTAL_PRICE: 'Preço total',
	SUBMIT_ORDER_REQUEST: 'Enviar solicitação de pedido',
	SUBMIT_ORDER_REQUEST_CONFIRMATION: 'Tem certeza que deseja enviar este pedido?',
	EDIT_ORDER_REQUEST: 'Revisar solicitação de pedido',
	EDIT_ORDER_REQUEST_CONFIRMATION: 'Tem certeza que deseja salvar as alterações?',
	SAVE_UPDATES: 'Salvar alterações',
	ADD_SIM_ORDER_SUCCESS: 'Pedido de SIM criado',
	ADD_SIM_ORDER_ERROR: 'Falha ao criar o pedido de SIM, tente novamente.',
	EDIT_SIM_ORDER_SUCCESS: 'Pedido de SIM revisado',
	EDIT_SIM_ORDER_ERROR: 'Falha ao revisar o pedido de SIM, tente novamente.',
	PO_TOOLTIP: 'Número da ordem de compra será gerado ao enviar o pedido de SIM',

	FILTERS: 'Filtros',
	FILTER_CATEGORY_STATUS: 'Status',
	FILTER_CATEGORY_PLATFORM: 'Plataforma M2M',
	FILTER_CATEGORY_SKU: 'SKU',
	FILTER_CATEGORY_EXPEDITED: 'Emitido',
	FILTER_VALUE_PENDING: 'Pendente',
	FILTER_VALUE_RECEIVED: 'Recebido',
	FILTER_VALUE_SHIPPED: 'Enviado',
	FILTER_VALUE_COMPLETED: 'Concluído',
	FILTER_VALUE_YES: 'Sim',
	FILTER_VALUE_NO: 'Não',

	SIM_ORDERING_TITLE: 'Pedido de SIM',
	SIM_ORDERING_SUBTITLE: 'Selecionar abaixo os SIMs que estão disponíveis para pedir.',
	SIM_ORDERING_CONTRACT_RESTRAINTS: 'Restrições contratuais',
	SIM_ORDERING_CONTRACT_RESTRAINTS_SUBTITLE: 'Definir as restrições no processo de pedido do SIM.',
	SIM_ORDERING_MIN_QUANTITY_ALLOWED: 'Quantidade mín. permitida',
	SIM_ORDERING_MIN_QUANTITY_ALLOWED_PLACEOLDER: 'Quantidade mín.',
	SIM_ORDERING_MAX_QUANTITY_ALLOWED: 'Quantidade máx. permitida',
	SIM_ORDERING_PER_PERIOD: 'Por período',
	SIM_ORDERING_PER_PERIOD_PLACEHOLDER: 'Selecionar um período',
	SIM_ORDERING_MAX_QUANTITY_ALLOWED_PLACEOLDER: 'Quantidade máx.',
	SIM_ORDERING_SIM_SKU: 'SKU do SIM',
	SIM_ORDERING_SIM_SKU_PLACEHOLDER: 'Digitar SKU do SIM aqui',
	SIM_ORDERING_UNIT_PRICE: 'Preço unitário',
	SIM_ORDERING_UNIT_PRICE_PLACEHOLDER: 'Preço unitário',
	SIM_ORDERING_ALLOWED_TO_ORDER: 'Autorizado a pedir',
	SIM_ORDERING_SKU_DESCRIPTION: 'Descrição',
	SIM_ORDERING_SKU_DESCRIPTION_PLACEHOLDER: 'Digitar descrição do SIM',
	SIM_ORDERING_SKU_ADD_SKU: '+ Adicionar outro SKU do SIM',
	SIM_ORDERING_VALIDATOR_MAX_QUANTITY: 'quantidade máx.',
	SIM_ORDERING_VALIDATOR_MIN_QUANTITY: 'quantidade mín.',
	ADD_RESTRAINTS_SUCCESS: 'Restrições contratuais adicionadas',
	ADD_RESTRAINTS_ERROR: 'Falha ao adicionar restrições contratuais',
	UPDATE_RESTRAINTS_SUCCESS: 'Restrições contratuais atualizadas',
	UPDATE_RESTRAINTS_ERROR: 'Falha ao atualizar restrições contratuais',
	ADD_SIM_ORDERING_SKUS_SUCCESS: 'SKUs de pedidos de SIM atualizados',
	ADD_SIM_ORDERING_SKUS_ERROR: 'Falha as adicionar SKUs de pedidos de SIM',

	COMPANY_REPORTS: 'Relatórios',
	COMPANY_REPORTS_SETTINGS_SUCCESSFULLY_UPDATED: 'Configurações dos relatórios da empresa salvas',
	ERROR_WHILE_UPDATING_COMPANY_REPORTS: 'Erro ao atualizar os relatórios da empresa',
	ERROR_WHILE_FETCHING_COMPANY_REPORTS: 'Erro ao buscar a configuração dos relatórios',
	COMPANY_REPORTS_SUBTITLE: 'Selecionar os relatórios que deseja receber.',
	DAILY_REPORTS: 'RELATÓRIOS DIÁRIOS',
	MONTHLY_REPORTS: 'RELATÓRIOS MENSAIS',
	PREFERENCES_MENU: 'Menu de preferências',
	PREFERENCES: 'Preferências',
	REFERENCE_REPORTS: 'Relatórios de referência',
	M2M_DATE_ADDED: 'Data de M2M adicionada',
	M2M_INITIAL_ACTIVATION_DATE: 'Data de ativação de M2M',
	NOTES: 'Notas',
	SAVE_NOTE: 'Salvar nota',
	NOTE_TEXTAREA_PLACEHOLDER: 'Digitar sua nota aqui (máx. de 2000 caracteres)',
	ADD_NOTE_SUCCESS: 'Nota salva',
	ADD_NOTE_ERROR: 'Falha ao salvar nota, tente novamente.',
	DELETE_NOTE_SUCCESS: 'Nota excluída',
	DELETE_NOTE_ERROR: 'Falha ao excluir nota, tente novamente.',
	DELETE_NOTE: 'Excluir nota',
	TICKETING_STATUS: 'Status da emissão de tíquetes',
	UPDATE_NOTE_STATUS: 'Atualizar status da nota',
	LAST_UPDATE: 'Última atualização',
	SUBMISSION_LOCATION: 'Local de envio',
	RULE_STATE: 'Estado da regra',
	RULE_STATE_ACTIVE: 'Ativo',
	YES: 'Sim',
	NO: 'Não',
	// ANALYTICS
	CHART_CATEGORIES: 'Categorias de gráficos',
	GROUP_DATA_BY: 'Agrupar dados por',
	CHART_OPTIONS: 'Opções do gráfico',
	CREATE_NEW_CHART: 'Criar novo gráfico',
	EDIT_CHART: 'Revisar gráfico',
	DISCLAIMER: 'Renúncia: ',
	DATE_RANGE: 'INTERVALO DE DATAS',
	SHOW: 'MOSTRAR',
	CYCLE: 'CICLO',
	INCREMENT_BY: 'INCREMENTAR POR',
	SELECT_CUSTOM_DATE_RANGE: 'Selecionar intervalo de datas personalizado',

	// Cobrança
	INVOICE: 'Fatura',
	INVOICE_DATE: 'Data da fatura',
	CUSTOMER_NAME: 'Nome do cliente',
	DOCUMENT_ID: 'ID do documento',
	COUNTRY_NAME: 'Nome do país',
	TABLE_COLUMNS_INVOICES_CHANGE_SUCCESS_TITLE: 'Êxito na alteração das configurações da tabela de faturas!',
	TABLE_COLUMNS_INVOICES_CHANGE_SUCCESS_MESSAGE: 'Configurações da tabela de faturas foram atualizadas com êxito.',
	TABLE_COLUMNS_INVOICES_CHANGE_ERROR_TITLE: 'Erro ao alterar colunas da tabela de faturas!',
	TABLE_COLUMNS_INVOICES_CHANGE_ERROR_MESSAGE: 'Não foi possível atualizar as colunas da tabela de faturas neste momento. Tentar novamente.',
	INVOICE_DETAILS: 'Detalhes da fatura',

	// TICKETING
	TICKET_CREATED_DATE: 'Data de criação do tíquete',
	UPDATED_NOTE_STATUS_DATE: 'Data de status da nota atualizada',
	LAST_UPDATED_DATE: 'Data da última atualização',
	UPDATE_NOTE: 'Atualizar nota',
	TABLE_TICKETS_COLUMNS_CHANGE_SUCCESS_TITLE: 'Êxito na alteração das configurações da tabela de tíquetes!',
	TABLE_TICKETS_COLUMNS_CHANGE_SUCCESS_MESSAGE: 'Configurações da tabela de tíquetes foram atualizadas com êxito.',
	TABLE_TICKETS_COLUMNS_CHANGE_ERROR_TITLE: 'Erro na alteração das colunas da tabela de tíquetes!',
	TABLE_TICKETS_COLUMNS_CHANGE_ERROR_MESSAGE: 'As colunas da tabela de tíquetes não puderam ser atualizadas neste momento. Tentar novamente.',

	TODAY: 'Hoje',
	PREVIOUS_DAY: 'Dia anterior',
	LAST_10_DAYS: 'Últimos 10 dias',
	LAST_30_DAYS: 'Últimos 30 dias',
	CUSTOM_RANGE: 'Intervalo personalizado',
	CURRENT_CYCLE_TO_DATE: 'Ciclo atual até o momento',
	HISTORICAL: 'Histórico',
	WEEK: 'Semana',
	SUM_TOTALS: 'Totais da soma',
	AVERAGE_TOTALS: 'Calcular média dos totais',
	LINE_GRAPH: 'Gráfico de linhas',
	BAR_GRAPH: 'Gráfico de barra',
	WORLD_MAP_GRAPH: 'Gráfico do mapa mundi',
	TOTAL_DATA_USAGE: 'Uso total de dados',
	ENDPOINT_COUNT_TOTALS: 'Totais da contagem de endpoints',
	NO_USAGE_SIMS: 'SIMs sem utilização',
	TOTAL: 'Total',
	AVERAGE_USAGE: 'Média',
	DIRECTION_INDEX: 'Índice do sentido',
	PIE_CHART: 'Gráfico de setores',
	ERROR_WHILE_ADDING_TICKET: 'Erro ao adicionar tíquete',

	SELECT_M2M_PLATFORM: 'Selecionar sua plataforma M2M',
	SELECT_M2M_PLATFORM_ERROR: 'Selecionar primeiro a plataforma M2M para preencher os dados',
	SELECT_YOUR_ACCOUNT: 'Selecionar a conta',
	CHOOSE_M2M_PLATFORM: 'Selecionar a plataforma M2M',
	CHOOSE_YOUR_ACCOUNT: 'Selecionar a conta',

	TOTAL_VOICE_USAGE: 'Uso total de voz',
	TOTAL_SMS_USAGE: 'Uso total de SMS',
	SAVE_AND_CLOSE: 'Salvar e fechar',
	// Recursos de segurança
	SECURITY_TABS_1: 'Lista de permissão de IMEI',
	SECURITY_TABS_2: 'Lista de permissão de SMS',
	SECURITY_TABS_3: 'Lista de permissão de voz',
	SECURITY_TABS_4: 'Lista de bloqueio de IP, URL, Porta',
	SECURITY_TABS_5: 'Lista de permissão de intervalo de IP',
	SECURITY_FEATURES_1: 'IMEI',
	SECURITY_FEATURES_2: 'SMS',
	SECURITY_FEATURES_3: 'VOZ',
	SECURITY_FEATURES_4: 'IP, URL, PORTA',
	SECURITY_FEATURES_5: 'INTERVALO DE IP',
	SECURITY_FEATURES_SUCCESSFULLY_ADD: 'Dados alterados com êxito',
	SECURITY_FEATURES_ERROR_ADD: 'Erro ao atualizar os dados',
	SECURITY_FEATURES_ADD_1: 'Acrescentar novo IMEI à lista de permissão',
	SECURITY_FEATURES_ADD_2: 'Acrescentar nova SMS à lista de permissão',
	SECURITY_FEATURES_ADD_3: 'Acrescentar nova VOZ à lista de permissão',
	SECURITY_FEATURES_ADD_4: ' Acrescentar novo IP, URL, PORTA à lista de bloqueio',
	SECURITY_FEATURES_ADD_5: 'Acrescentar novo intervalo de IP à lista de permissão',
	SECURITY_FEATURES_EDIT_1: 'Revisar lista de permissão de IMEI',
	SECURITY_FEATURES_EDIT_2: 'Revisar lista de permissão de SMS',
	SECURITY_FEATURES_EDIT_3: 'Revisar lista de permissão de Voz',
	SECURITY_FEATURES_EDIT_4: 'Revisar lista de bloqueio de PORTA, IP, URL',
	SECURITY_FEATURES_EDIT_5: 'Revisar lista de permissão de intervalo de IP',
	SECURITY_FEATURES_M2M: 'Selecionar plataforma M2M',
	SECURITY_FEATURES_REMOVE: 'Remover da lista de permissão',
	UPDATE: 'Atualizar',
	SECURITY_FEATURES: 'Recursos de segurança',
	M2M_ACCOUNT_NAME: 'NOME DA CONTA DE M2M',
	DATA_SUBMITTED: 'DADOS ENVIADOS',
	FILTER_CATEGORY_M2MACCOUNT: 'Conta de M2M',
	SECURITY_FEATURES_SUCCESSFULLY_EXPORT: 'Exportado com êxito',
	SECURITY_FEATURES_FAIL_EXPORT: 'Falha ao exportar',
	COUNTRYCODE: 'País',
	MINUTE: 'Minuto',
	MINUTES: 'Minutos',
	CHART_LEFT_AXIS_MINUTE: 'min',
	CHART_LEFT_AXIS_MINUTES: 'min',
	CONSUMED_MINUTE: 'Minutos consumidos',
	CONSUMED_MB: 'Megabytes consumidos',
	CONSUMED_GB: 'Gigabytes consumidos',
	CONSUMED_TB: 'Terabytes consumidos',
	CONSUMED_PB: 'Petabytes consumidos',
	CONSUMED_SMS: 'SMS consumidos',
	NO_DESCRIPTION_FOR_GRAPH: 'Não há descrição deste gráfico',
	GRAPHS: 'Gráficos atualmente disponíveis',
	CONSUMED_COUNT: 'Número de endpoints',
	DOWNLOAD_CHART_AS_PNG: 'Baixar PNG',
	CUSTOMER_INVOICE_ID: 'ID do cliente',
	INVOICE_ID: 'ID da fatura',
	CUSTOMER_INVOICE_NAME: 'Conta de assinaturas',
	INVOICE_COUNTRY: 'País',
	ACCESSTECHNOLOGY: 'Tecnologia de acesso',
	RATINGZONE: 'Zona de tarifação',
	RATEPLAN: 'Plano de tarifas',
	CHOOSE_FILTERS_ON_ANALYTICS_MODAL: 'Selecionar uma categoria na primeira etapa',
	NO_FILTERS_ON_ANALYTICS_MODAL: 'Não há filtros disponíveis para esta categoria',
	BILLING_CYCLE: 'Ciclo de cobrança',
	FETCHING_DATA: 'Aguarde. Obtendo dados...',
	NO_DATA_RECEIVED: 'Nenhum dado recebido',
	NO_FILTERS_AVAILABLE: 'Nenhum filtro disponível',
	DATE_SUBMITTED: 'Data de envio',
	NO_REPORTS_DEFINED: 'Nenhum relatório definido',
	APPLY: 'Aplicar',
	OPEN_CHART_SETTINGS_PAGE: 'Configurações do gráfico',
	CUSTOMIZE_CHART_DATA: 'Personalizar os dados do gráfico',
	ESTIMATED_AVERAGE_USAGE: 'Uso médio estimado',
	DELETE_DEFINED_CHART_SETTING_QUESTION: 'Excluir configuração definida da tabela?',
	DELETE_DEFINED_CHART_SETTING_MESSAGE: 'Tem certeza de que deseja excluir a configuração?',
	TOTAL_COUNT_BY_SEVERITY: 'Tíquetes por gravidade',
	TOTAL_COUNT_BY_STATUS: 'Tíquetes por status',
	INVALID_IP_ADDRESS: 'Endereço de IP inválido',
	INVOICENUMBER: 'Número da fatura',
	CUSTOMERNAME: 'Nome do cliente',
	COUNTRYNAME: 'Nome do país',
	CUSTOMERID: 'ID do cliente',
	DEVICE_WAKE_UP: 'Ativação do dispositivo',
	DEVICE_WAKE_UP_NOTIFICATION_TITLE: 'Ativação do dispositivo',
	DEVICE_WAKE_UP_NOTIFICATION_MESSAGE_SUCCESS: 'Ativação bem-sucedida do dispositivo',
	DEVICE_WAKE_UP_NOTIFICATION_MESSAGE_FAIL: 'Falha ao ativar o dispositivo',

	FILES_FAILED_TO_UPLOAD_MESSAGE: 'Falhar ao carregar arquivos: ',
	ADD_ANOTHER_TRESHOLD: 'Adicionar outro limite',
	PIE_CHART_TOTAL: 'Total',
	ADD_ATTACHMENT: 'Adicionar anexo',
	TICKET_FILE_UPLOAD_ERROR: 'Ocorreu um erro no sistema de backend e alguns de seus arquivos não foram atualizados.',
	FILE_TO_LARGE_TO_UPLOAD: 'O arquivo é grande demais para carregar. O tamanho máximo do arquivo é 3MB.',
	RETRY: 'Tente novamente',
	NO_CATEGORIES: 'Não há nenhuma categoria disponível para esta conta',
	ZONE_NAME: 'Nome da zona',
	POOL_ID: 'ID do grupo',

	// NOTES INVOICES
	ADD_NEW_NOTE: 'Adicionar nota',
	EDIT_NOTE: 'Revisar nota',
	ADD_NOTE_PLACEHOLDER: 'Digitar sua nota aqui',
	INVOICE_NOTE_SUCCESSFULLY_UPDATED: 'Nota da fatura atualizada com êxito',
	INVOICE_NOTE_UPDATE_FAIL: 'Fallha ao atualizar nota da fatura',
	ATTACHMENTS_ADDED: 'Anexos adicionados',
	EXTERNAL_LAST_UPDATED_BY: 'Última atualização por',
	EXTERNAL_LAST_UPDATED_DATE: 'Data/hora da última atualização',
	REMOVE: 'Remover',
	EndpointStatus: 'Status',
	NetworkOperator: 'Operador de rede',
	NetworkCountry: 'País da rede',
	NetworkType: 'Tipo de rede',
	TransferStatus: 'Status da transferência de IMSI',
	NetworkEntitlement: 'ID do direito de acesso à rede',
	RecredentialTransferStatus: 'Status de recredenciamento',
	M2MDateAdded: 'Data de M2M adicionada',
	M2MInitialActivationDate: 'Data de ativação de M2M',
	LAST_UPDATED_BY: 'Última atualização por',
	LAST_UPDATED: 'Última atualização',
	INVOICE_NOTE_FILE_ERROR: 'Ocorreu um erro no sistema de backend e alguns de seus arquivos não foram carregados.',

	BATCH_HISTORY: 'Histórico de lotes',
	// DIAGNOSTIC HLRHSSDetails
	GET_HLR_HSS_DETAILS: 'Obter detalhes de HLR/HSS',
	APN_IDENTIFIER: 'Identificador do APN.',
	IP_ADDRESS_TYPE: 'Tipo de endereço de IP',
	QOS_PROFILE: 'Perfil de QOS',
	PDN_GW_DYNAMIC_ALLOCATION: 'Alocação dinâmica de PDN GW',
	VPLMN_ADDRESS_ALLOWED: 'Endereço de VPLMN permitido',
	MAX_UPLOAD: 'Upload máx.',
	MAX_DOWNLOAD: 'Download máx.',
	SUBSCRIBER_PROFILE: 'Perfil do assinante',
	ENDPOINT_ID_ICCID: 'ID do endpoint - ICCID',
	BARRING_STATUS: 'Status de bloqueio',
	NETWORK_ACCESS: 'Acesso à rede',
	CURRENT_VLR_NUMBER: 'Número atual de VLR',
	PREVIOUS_VLR_NUMBER: 'Número anterior de VLR ',
	CURRENT_SGSN_NUMBER: 'Número atual de SGSN',
	PREVIOUS_SGSN_NUMBER: 'Número anterior de SGSN',
	ROAMING_RESTRICTION: 'Restrição de roaming',
	DATA_3G_ENABLED: 'Dados 3G habilitados',
	NETWORK_STATUS: 'Status da rede',
	APN_LIST: 'Lista de APN',

	// DATA HISTORY
	DATA_HISTORY: 'Histórico de dados',
	DATA_VOLUME_DOWN_KB: 'Downlink do volume de dados (KB)',
	DATA_VOLUME_UP_KB: 'Uplink do volume da dados (KB)',
	DURATION_MINUTES: 'Duração (minutos)',
	SESSION_CHARGING_ID: 'ID da sessão de carregamento',
	ACCESS_POINT_NAME: 'APN',
	DEVICE_IP_V4: 'Endereço IPv4 do dispositivo',
	DEVICE_IP_V6: 'Endereço IPv6 do dispositivo',
	RATING_ZONE_NAME: 'Nome da zona de tarifação',
	RATING_GROUP_ID_NAME: 'Nome da ID do grupo de tarifação',
	RATING_GROUP_ID: 'ID do grupo de tarifação',

	// SMS HISTORY WING
	MESSAGE_HISTORY: 'Histórico de mensagens',
	PORTAL_MESSAGES: 'Mensagens do portal',
	SMS_LOG_ID: 'ID do log de SMS',
	DATE_SENT_RECEIVED: 'Data de envio/recebimento',
	SENT_FROM: 'Enviado de',
	SENT_TO: 'Enviado para',
	MESSAGE_TYPE: 'Tipo de mensagem',
	ZONE_MODEL: 'Modelo da zona',

	CTD_DATA_USAGE: 'Uso de dados de CTD (Megabytes)',
	CTD_SMS_MO_MT_USAGE: 'Uso de SMS de CTD (MSG)',
	CTD_VOICE_USAGE: 'Uso de voz de CTD (Min.)',

	ACCOUNTS: 'Contas',
	MUST_BE_BETWEEN_CHARACTERS_LONG: 'Deve ter entre {min} e {max} caracteres',
	INVALID_DOMAIN_FORMAT: 'Formato de domínio inválido',
	LAST_UPDATE_DATE_TIME: 'Data/hora da última atualização',
	LAST_UPDATED_BY_USER: 'Última atualização pelo usuário',
	INSESSION: 'Sessão em andamento',
	PLEASE_WAIT_THIS_MAY_TAKE_A_FEW_MINUTES: 'Aguarde. Isso pode levar alguns minutos, dependendo do tamanho do arquivo.',
	NO_RESULTS: 'Sem resultados',
	SELECTED_RATE_PLAN: 'Plano de tarifas selecionado',
	HSPA: 'Hspa',
	LTE: 'Lte',
	VLR: 'Vlr',
	SGSN: 'Sgsn',
	EXPORT: 'Exportar',
	FILE: 'Arquivo',
	ERROR_CODE: 'Código do erro',
	ERROR_TYPE: 'Tipo de erro',
	AT_LINE: 'Na linha',
	EXPORT_TICKETS: 'Exportar tíquetes',
	EXPORT_USER_USAGE_DATA: 'Exportar dados de uso dos usuários',
	PLACEHOLDER_MODEL: 'AT&T Z431',
	NO_BATCH_ACTIONS_AVAILABLE: 'Nenhuma ação de lotes disponível',
	NO_BATCH_ACTIONS_AVAILABLE_SUBTITLE: 'Não há ações de lotes para exibir nesta tabela no momento',
	NO_BATCH_REPORTS_AVAILABLE: 'Nenhum relatório de lotes disponível',
	NO_BATCH_REPORTS_AVAILABLE_SUBTITLE: 'Não há relatórios de lotes para exibir nesta tabela no momento',
	MOBILE_ORIGINATING: 'Celular de origem',
	MOBILE_TERMINATING: 'Celular de encerramento',
	NA: 'NA',
	GMNA: 'GM América do Norte',
	POD3: 'ACC Pod3',
	POD19: 'Enterprise',
	TABS_ADD_ICON: 'Criar tabela de endpoints personalizados',
	USAGE_SUMMARIES: 'Resumo de uso',
	EMPTY_SPACE: ' ',
	// planos de tarifas de endpoints
	'ENDPOINTS_RATE_PLAN.RATE_PLAN_TYPE': 'Tipo do plano de tarifas',
	'ENDPOINTS_RATE_PLAN.INCLUDED_TEST_DATA': 'Teste de dados incluído',
	'ENDPOINTS_RATE_PLAN.BILLING_PLATFORM': 'Plataforma de cobrança',
	'ENDPOINTS_RATE_PLAN.ACTIVATION_FEE': 'Taxa de ativação',
	'ENDPOINTS_RATE_PLAN.INCLUDED_TEST_SMS': 'Teste de SMS incluído',
	'ENDPOINTS_RATE_PLAN.BILLING_ACCOUNT_ID': 'ID da conta de cobrança',
	'ENDPOINTS_RATE_PLAN.MSU_PRICE': 'Preço de MSU',
	'ENDPOINTS_RATE_PLAN.INCLUDED_TEST_VOICE': 'Teste de voz incluído',
	'ENDPOINTS_RATE_PLAN.ZONE_MODEL_ID': 'ID do modelo da zona',
	'ENDPOINTS_RATE_PLAN.TYPE': 'Tipo',
	'ENDPOINTS_RATE_PLAN.INCLUDED_MSU': 'MSU incluído',
	'ENDPOINTS_RATE_PLAN.VALID_FROM': 'Válido De',
	'ENDPOINTS_RATE_PLAN.DEFAULT_RATE_PLAN': 'Plano de tarifas padrão',
	'ENDPOINTS_RATE_PLAN.BASE_RATE_PLAN': 'Plano de tarifas básico',
	'ENDPOINTS_RATE_PLAN.POSTPAID': 'Pré-pago',
	'ENDPOINTS_RATE_PLAN.APPROVED': 'Aprovado',
	'ENDPOINTS_RATE_PLAN.MONTHLY': 'MENSALMENTE',
	PASSWORDS_DO_NOT_MATCH: 'As senhas não correspondem',
	NO_WHITE_SPACE_ONLY: 'O texto deve conter letras e/ou números',
	NO_RATE_PLAN_SELECTED: 'nenhum plano de tarifas selecionado',
	NO_RATE_PLANS_UNDERLYING_PLATFORM: 'Há um erro na plataforma subjacente e a alteração do plano de tarifas não está disponível no momento.',
	ENDPOINT_PROVISIONED_STATE_CHANGE_SUCCESS: 'ID do endpoint {args1} foi provisionada com êxito. Status de IMSI mudou para {args2}',
	ENDPOINT_PROVISIONED_STATE_CHANGE_FAIL: 'ID do endpoint {args1} falhou no provisionamento depois da alteração do status de IMSI. Obter suporte',

	// AUTH SIGN IN CALLBACK
	AUTHENTICATION_FAILED: 'Falha da autenticação',
	AUTHENTICATION_IN_PROGRESS: 'Autenticação em andamento. Aguarde...',
	MEGABYTES: 'Megabytes',
	TICKETS: 'Tíquetes',
	USER_GROUPS: 'Grupos de usuários',
	RECENT_SESSIONS: 'Sessões recentes',
	ACTIVE_SESSION_SESSION_START_TIME: 'HORÁRIO DE INÍCIO DA SESSÃO',
	ACTIVE_SESSION_TOTAL_VOLUME: 'VOLUME TOTAL',
	ACTIVE_SESSION_TRANSMITTING: 'TRANSMITINDO',
	ACTIVE_SESSION_RECEIVING: 'RECEBENDO',
	CURRENTLY_ACTIVE_SESSION: 'Sessão atualmente ativa',
	ACTIVE_SESSION_CARRIER: 'OPERADORA',
	ACTIVE_SESSION_IP_ADDRESS: 'Endereço de IP',
	RESET_TO_DEFAULT: 'Redefinir para o padrão',
	DATE_TIME: 'Data/hora',
	SESSION_END: 'Final da sessão',
	SEND: 'Enviar',
	SEND_SMS_TO_THIS_DEVICE: 'Enviar SMS para esse dispositivo.',
	TYPE_A_MESSAGE: 'Digite uma mensagem para ser enviada para este dispositivo usando um serviço de mensagens curtas. Tarifas padrão podem ser cobradas.',
	MESSAGES_WILL_APPEAR: 'As mensagens serão exibidas como foram enviadas e constarão dos relatórios de uso de SMS. Tarifas podem ser cobradas.',
	SENT_MESSAGES_HISTORY: 'Histórico de mensagens enviadas',
	CHARACTERS_REMAINING: '{remaining} caracteres restantes...',
	EXPORT_SESSIONS_DATA_FOR_ID: 'Sessões recentes de {id}',
	EXPORT_DATA: 'Exportar dados',
	BYTE_ABBREVIATION: 'B',
	UTC_TIME_ZONE: ' UTC ',
	SEND_SMS_TO_ENDPOINT: 'Enviar SMS para o endpoint',
	SMS: 'SMS',
	OFFPEAK_USAGE_FLAG: 'Sinalizador de uso fora do horário de pico',
	DATA_USAGE_TOTAL: 'Uso de dados - Total bruto',
	DATA_USAGE_UPLINK: 'Uso de dados - Uplink bruto',
	DATA_USAGE_DOWNLINK: 'Uso de dados - Downlink bruto',
	DATA_USAGE_ROUNDED: 'Uso de dados- Arredondado',
	DEVICE_IP_ADDRESS: 'Endereço de IP do dispositivo',
	SESSION_DURATION: 'Duração da sessão',
	RECORD_SEQUENCE_NUMBER: 'Número da sequência de registro',
	CHARGING_ID: 'ID de cobrança',
	SESSION_CLOSE_CAUSE: 'Causa do encerramento da sessão',
	TECH_TYPE: 'Tipo Tec',
	PGW_ADDRESS: 'Endereço de PGW',
	TIME_OF_FIRST_USAGE: 'Hora do primeiro uso',
	TIME_OF_LAST_USAGE: 'Hora do último uso',
	TIME_USAGE_DURATION: 'Tempo da duração do uso',
	STREAM_ID: 'ID do fluxo',
	CELL_GLOBAL_IDENTIFIER: 'CGI (Identificador global de célula)',
	SERVICE_AREA_IDENTIFIER: 'SAI (Identificador da área de serviço)',
	ROUTING_AREA_IDENTIFIER: 'RAI (Identificador da área de roteamento)',
	TRACKING_AREA_IDENTITY: 'TAI (Identidade da área de rastreamento)',
	E_UTRAN_CGI: 'ECGI (E-UTRAN CGI)',
	LOCATION_AREA_IDENTIFIER: 'LAI (Identificador da área de localização)',
	SERVING_SGSN: 'Atendendo SGSN',
	COUNTRY_LASTEST_SESSION: 'País (sessão mais recentes)',
	CARRIER_LASTEST_SESSION: 'Operadora (sessão mais recentes)',
	BILLABLE_FLAG: 'Sinalizador faturável',
	CYCLE_MONTH: 'Mês do ciclo',
	CYCLE_YEAR: 'Ano do ciclo',
	ASSIGNED_RATE_PLAN_ID: 'ID do plano de tarifas atribuído',
	ASSIGNED_RATING_ZONE_ID: 'ID da zona de tarifação atribuída',
	RATE_PLAN_VERSION_ID: 'ID da versão do plano de tarifas',
	SERVICE_TYPE: 'Tipo de serviço',
	TRAFFIC_DETAIL_ID: 'ID do detalhe de tráfego',
	RECORD_RECEIVED_DATE: 'Data de recebimento do registro',
	RECORD_OPEN_TIME: 'Hora de abertura do registro',
	OPERATOR_NETWORK: 'Rede do operador',
	OPERATOR_ACCOUNT_ID: 'ID da conta do operador',
	TAP_CODE: 'Código de TAP',
	USAGE_HISTORY: 'Histórico do uso',
	DATA: 'Dados',
	VOICE: 'Voz',
	HLR_HSS_DETAILS_FOR_ENDPOINT: 'Detalhes de HLR/HSS para ID do endpoint{id}',
	NEED_HELP: 'Precisa de ajuda?',
	MB: ' MB',
	CONNECTION_STATUS: 'Status da conexão',
	MESSAGE_CONTENT: 'Conteúdo da mensagem',
	B: 'B',
	CYCLE_TO_DATE_USAGE_TOTAL: 'Total de uso do ciclo até o momento',
	AVERAGE_DAILY_USE: 'Média do uso diário',
	DATA_USAGE_DOWNLINK_CHART: 'Downlink',
	DATA_USAGE_UPLINK_CHART: 'Uplink',
	GDSP: 'GDSP',
	SECOND: 'Segundo',
	SECONDS: 'Segundos',
	DURATION_RAW: 'Duração - Bruta',
	DURATION_ROUNDED: 'Duração - Arredondada',
	USAGE_TYPE: 'Tipo de Uso',
	SELECT_BILLING_CYCLE: 'Selecionar ciclo de cobrança',
	RANGE: 'Faixa',
	CUSTOM_FIELDS_VALUES_UPDATED: 'Os valores do campo personalizado foram atualizados',
	CUSTOM_FIELDS_VALUES_UPDATED_ERROR: 'Os valores do campo personalizado não foram atualizados',
	SXMACC: 'SXMACC',
	CHARTS_BILLING_CYCLE_ABBREVIATION: 'BC {billcycle}',
	DATE_RANGE_INPUT_LABEL: 'De: {startDate} Até: {endDate}',
	UH_OH_THERE_WERE_SOME_PROBLEMS_TRYING_TO_LOG_YOU_IN_PLEASE_RETRY_AGAIN_IN_A_FEW_MOMENTS: 'Opa! Ocorreram problemas na tentativa de fazer seu logon. Tente novamente em alguns momentos. Se o problema persistir, consulte a equipe de suporte.',

	//  LANGUAGE OPTIONS
	ENGLISH: 'Inglês dos EUA',
	FRANCAIS: 'Francês (França)',
	ESPANOL: 'Espanhol (Espanha)',
	ITALIANO: 'Italiano (Itália)',
	NEDERLANDS: 'Holandês (Holanda)',
	DEUTCHE: 'Alemão (Alemanha)',
	PORTUGUES: 'Português (Brasil)',
	CHINESE: '中文 (中国)',

	//  COUNTRIES
	COUNTRY_UNITED_STATES: 'Estados Unidos',
	COUNTRY_AFGHANISTAN: 'Afeganistão',
	COUNTRY_ALAND_ISLANDS: 'Ilhas Alanda',
	COUNTRY_ALBANIA: 'Albânia',
	COUNTRY_ALGERIA: 'Algéria',
	COUNTRY_AMERICAN_SAMOA: 'Samoa Americana',
	COUNTRY_ANDORRA: 'Andorra',
	COUNTRY_ANGOLA: 'Angola',
	COUNTRY_ANGUILLA: 'Anguilla',
	COUNTRY_ANTARCTICA: 'Antártica',
	COUNTRY_ANTIGUA_AND_BARBUDA: 'Antígua e Barbuda',
	COUNTRY_ARGENTINA: 'Argentina',
	COUNTRY_ARMENIA: 'Armênia',
	COUNTRY_ARUBA: 'Aruba',
	COUNTRY_AUSTRALIA: 'Austrália',
	COUNTRY_AUSTRIA: 'Áustria',
	COUNTRY_AZERBAIJAN: 'Azerbaijão',
	COUNTRY_BAHAMAS_THE: 'Bahamas',
	COUNTRY_BAHRAIN: 'Barém ',
	COUNTRY_BANGLADESH: 'Bangladesh',
	COUNTRY_BARBADOS: 'Barbados',
	COUNTRY_BELARUS: 'Bielorrússia',
	COUNTRY_BELGIUM: 'Bélgica',
	COUNTRY_BELIZE: 'Belize',
	COUNTRY_BENIN: 'Benim',
	COUNTRY_BERMUDA: 'Bermudas',
	COUNTRY_BHUTAN: 'Butão',
	COUNTRY_BOLIVIA: 'Bolívia',
	COUNTRY_BOSNIA_AND_HERZEGOVINA: 'Bósnia e Herzegovina',
	COUNTRY_BOTSWANA: 'Botsuana',
	COUNTRY_BOUVET_ISLAND: 'Ilha Bouvet',
	COUNTRY_BRAZIL: 'Brasil',
	COUNTRY_BRITISH_INDIAN_OCEAN_TERRITORY: 'Território Britânico do Oceano Índico',
	COUNTRY_BRUNEI: 'Brunei',
	COUNTRY_BULGARIA: 'Bulgária',
	COUNTRY_BURKINA_FASO: 'Burkina Faso',
	COUNTRY_BURUNDI: 'Burundi',
	COUNTRY_CAMBODIA: 'Camboja',
	COUNTRY_CAMEROON: 'Camarões',
	COUNTRY_CANADA: 'Canadá',
	COUNTRY_CAPE_VERDE: 'Cabo Verde',
	COUNTRY_CAYMAN_ISLANDS: 'Ilhas Caiman',
	COUNTRY_CENTRAL_AFRICAN_REPUBLIC: 'República Central Africana',
	COUNTRY_CHAD: 'Chade',
	COUNTRY_CHILE: 'Chile',
	COUNTRY_CHINA: 'China',
	COUNTRY_CHRISTMAS_ISLAND: 'Ilha Christmas',
	COUNTRY_COCOS_KEELING_ISLANDS: 'Ilhas Cocos (Keeling)',
	COUNTRY_COLOMBIA: 'Colômbia',
	COUNTRY_COMOROS: 'Comores',
	COUNTRY_CONGO: 'Congo',
	COUNTRY_CONGO_THE_DEMOCRATIC_REPUBLIC_OF_THE: 'Congo, República Democrática do',
	COUNTRY_COOK_ISLANDS: 'Ilhas Cook',
	COUNTRY_COSTA_RICA: 'Costa Rica',
	COUNTRY_COTE_D__IVOIRE_IVORY_COAST_: 'Costa do Marfim',
	COUNTRY_CROATIA_HRVATSKA_: 'Croácia (Hrvatska)',
	COUNTRY_CUBA: 'Cuba',
	COUNTRY_CYPRUS: 'Chipre',
	COUNTRY_CZECH_REPUBLIC: ' República Tcheca',
	COUNTRY_DENMARK: 'Dinamarca',
	COUNTRY_DJIBOUTI: 'Djibouti',
	COUNTRY_DOMINICA: 'Domínica',
	COUNTRY_DOMINICAN_REPUBLIC: 'República Dominicana',
	COUNTRY_EAST_TIMOR: 'Timor-Leste',
	COUNTRY_ECUADOR: 'Equador',
	COUNTRY_EGYPT: 'Egito',
	COUNTRY_EL_SALVADOR: 'El Salvador',
	COUNTRY_EQUATORIAL_GUINEA: 'Guiné Equatorial',
	COUNTRY_ERITREA: 'Eritreia',
	COUNTRY_ESTONIA: 'Estônia',
	COUNTRY_ETHIOPIA: 'Etiópia',
	COUNTRY_FALKLAND_ISLANDS: 'Ilhas Falkland',
	COUNTRY_FAROE_ISLANDS: 'Ilhas Faroe',
	COUNTRY_FIJI_ISLANDS: 'Ilhas Fiji',
	COUNTRY_FINLAND: 'Finlândia',
	COUNTRY_FRANCE: 'França',
	COUNTRY_FRENCH_GUIANA: 'Guiana Francesa',
	COUNTRY_FRENCH_POLYNESIA: 'Polinésia Francesa',
	COUNTRY_FRENCH_SOUTHERN_TERRITORIES: 'Territórios Austrais Franceses',
	COUNTRY_GABON: 'Gabão',
	COUNTRY_GAMBIA_THE: 'Gâmbia',
	COUNTRY_GEORGIA: 'Geórgia',
	COUNTRY_GERMANY: 'Alemanha',
	COUNTRY_GHANA: 'Gana',
	COUNTRY_GIBRALTAR: 'Gibraltar',
	COUNTRY_GREECE: 'Grécia',
	COUNTRY_GREENLAND: 'Groenlândia',
	COUNTRY_GRENADA: 'Granada',
	COUNTRY_GUADELOUPE: 'Guadelupe',
	COUNTRY_GUAM: 'Guam',
	COUNTRY_GUATEMALA: 'Guatemala',
	COUNTRY_GUERNSEY_AND_ALDERNEY: 'Guernsey e Alderney',
	COUNTRY_GUINEA: 'Guiné',
	COUNTRY_GUINEA_BISSAU: 'Guiné Bissau',
	COUNTRY_GUYANA: 'Guiana',
	COUNTRY_HAITI: 'Haiti',
	COUNTRY_HEARD_AND_MCDONALD_ISLANDS: 'Ilha Heard e Ilhas McDonald',
	COUNTRY_HONDURAS: 'Honduras',
	'COUNTRY_HONG_KONG_S.A.R.': 'Hong Kong (Região Administrativa Especial)',
	COUNTRY_HUNGARY: 'Hungria',
	COUNTRY_ICELAND: 'Islândia',
	COUNTRY_INDIA: 'Índia',
	COUNTRY_INDONESIA: 'Indonésia',
	COUNTRY_IRAN: 'Irã',
	COUNTRY_IRAQ: 'Iraque',
	COUNTRY_IRELAND: 'Irlanda',
	COUNTRY_ISRAEL: 'Israel',
	COUNTRY_ITALY: 'Itália',
	COUNTRY_JAMAICA: 'Jamaica',
	COUNTRY_JAPAN: 'Japão',
	COUNTRY_JERSEY: 'Jersey',
	COUNTRY_JORDAN: 'Jordânia',
	COUNTRY_KAZAKHSTAN: 'Cazaquistão',
	COUNTRY_KENYA: 'Quênia',
	COUNTRY_KIRIBATI: 'Kiribati',
	COUNTRY_KOREA_NORTH: 'Coreia do Norte',
	COUNTRY_KOREA_SOUTH: 'Coreia do Sul',
	COUNTRY_KUWAIT: 'Kuwait',
	COUNTRY_KYRGYZSTAN: 'Quirguistão',
	COUNTRY_LAOS: 'Laos',
	COUNTRY_LATVIA: 'Letônia (Látvia)',
	COUNTRY_LEBANON: 'Líbano',
	COUNTRY_LESOTHO: 'Lesoto',
	COUNTRY_LIBERIA: 'Libéria',
	COUNTRY_LIBYA: 'Líbia',
	COUNTRY_LIECHTENSTEIN: 'Liechtenstein',
	COUNTRY_LITHUANIA: 'Lituânia',
	COUNTRY_LUXEMBOURG: 'Luxemburgo',
	'COUNTRY_MACAU_S.A.R.': 'Macau (Região Administrativa Especial)',
	COUNTRY_MACEDONIA: 'Macedônia',
	COUNTRY_MADAGASCAR: 'Madagascar',
	COUNTRY_MALAWI: 'Malavi',
	COUNTRY_MALAYSIA: 'Malásia',
	COUNTRY_MALDIVES: 'Maldivas',
	COUNTRY_MALI: 'Mali',
	COUNTRY_MALTA: 'Malta',
	COUNTRY_MAN_ISLE_OF_: 'Ilha de Man',
	COUNTRY_MARSHALL_ISLANDS: 'Ilhas Marshall',
	COUNTRY_MARTINIQUE: 'Martinica',
	COUNTRY_MAURITANIA: 'Mauritânia',
	COUNTRY_MAURITIUS: 'Maurício',
	COUNTRY_MAYOTTE: 'Maiote',
	COUNTRY_MEXICO: 'México',
	COUNTRY_MICRONESIA: 'Micronésia',
	COUNTRY_MOLDOVA: 'Moldávia',
	COUNTRY_MONACO: 'Mônaco',
	COUNTRY_MONGOLIA: 'Mongólia',
	COUNTRY_MONTSERRAT: 'Monserrate',
	COUNTRY_MOROCCO: 'Marrocos',
	COUNTRY_MOZAMBIQUE: 'Moçambique',
	COUNTRY_MYANMAR: 'Mianmar',
	COUNTRY_NAMIBIA: 'Namíbia',
	COUNTRY_NAURU: 'Nauru',
	COUNTRY_NEPAL: 'Nepal',
	COUNTRY_NETHERLANDS: 'Países Baixos',
	COUNTRY_NETHERLANDS_ANTILLES: 'Antilhas dos Países Baixos',
	COUNTRY_NEW_CALEDONIA: 'Nova Caledônia',
	COUNTRY_NEW_ZEALAND: 'Nova Zelândia',
	COUNTRY_NICARAGUA: 'Nicarágua',
	COUNTRY_NIGER: 'Níger',
	COUNTRY_NIGERIA: 'Nigéria',
	COUNTRY_NIUE: 'Niue',
	COUNTRY_NORFOLK_ISLAND: 'Ilha Norfolk',
	COUNTRY_NORTHERN_MARIANA_ISLANDS: 'Ilhas Mariana do Norte',
	COUNTRY_NORWAY: 'Noruega',
	COUNTRY_OMAN: 'Omã',
	COUNTRY_PAKISTAN: 'Paquistão',
	COUNTRY_PALAU: 'Palau',
	COUNTRY_PALESTINIAN_TERRITORY_OCCUPIED: 'Territórios Palestinos Ocupados',
	COUNTRY_PANAMA: 'Panamá',
	COUNTRY_PAPUA_NEW_GUINEA: 'Papua-Nova Guiné',
	COUNTRY_PARAGUAY: 'Paraguai',
	COUNTRY_PERU: 'Peru',
	COUNTRY_PHILIPPINES: 'Filipinas',
	COUNTRY_PITCAIRN_ISLAND: 'Ilhas Pitcairn',
	COUNTRY_POLAND: 'Polônia',
	COUNTRY_PORTUGAL: 'Portugal',
	COUNTRY_PUERTO_RICO: 'Porto Rico',
	COUNTRY_QATAR: 'Catar',
	COUNTRY_REUNION: 'Reunião',
	COUNTRY_ROMANIA: 'Romênia',
	COUNTRY_RUSSIA: 'Rússia',
	COUNTRY_RWANDA: 'Ruanda',
	COUNTRY_SAINT_HELENA: 'Santa Helena',
	COUNTRY_SAINT_KITTS_AND_NEVIS: 'São Cristóvão e Nevis',
	COUNTRY_SAINT_LUCIA: 'Santa Lúcia',
	COUNTRY_SAINT_PIERRE_AND_MIQUELON: 'Saint Pierre e Miquelon',
	COUNTRY_SAINT_VINCENT_AND_THE_GRENADINES: 'São Vicente e Granadinas',
	COUNTRY_SAMOA: 'Samoa',
	COUNTRY_SAN_MARINO: 'São Marino',
	COUNTRY_SAO_TOME_AND_PRINCIPE: 'São Tomé e Príncipe',
	COUNTRY_SAUDI_ARABIA: 'Arábia Saudita',
	COUNTRY_SENEGAL: 'Senegal',
	COUNTRY_SERBIA: 'Sérvia',
	COUNTRY_SEYCHELLES: 'Seicheles',
	COUNTRY_SIERRA_LEONE: 'Serra Leoa',
	COUNTRY_SINGAPORE: 'Cingapura',
	COUNTRY_SLOVAKIA: 'Eslováquia',
	COUNTRY_SLOVENIA: 'Eslovênia',
	COUNTRY_SOLOMON_ISLANDS: 'Ilhas Salomão',
	COUNTRY_SOMALIA: 'Somália',
	COUNTRY_SOUTH_AFRICA: 'África do Sul',
	COUNTRY_SOUTH_GEORGIA: 'Geórgia do Sul',
	COUNTRY_SOUTH_SUDAN: 'Sudão do Sul',
	COUNTRY_SPAIN: 'Espanha',
	COUNTRY_SRI_LANKA: 'Sri Lanka',
	COUNTRY_SUDAN: 'Sudão',
	COUNTRY_SURINAME: 'Suriname',
	COUNTRY_SVALBARD_AND_JAN_MAYEN_ISLANDS: 'Svalbard e Jan Mayen Islands',
	COUNTRY_SWAZILAND: 'Suazilândia',
	COUNTRY_SWEDEN: 'Suécia',
	COUNTRY_SWITZERLAND: 'Suíça',
	COUNTRY_SYRIA: 'Síria',
	COUNTRY_TAIWAN: 'Taiwan',
	COUNTRY_TAJIKISTAN: 'Tajiquistão',
	COUNTRY_TANZANIA: 'Tanzânia',
	COUNTRY_THAILAND: 'Tailândia',
	COUNTRY_TOGO: 'Togo',
	COUNTRY_TOKELAU: 'Tokelau',
	COUNTRY_TONGA: 'Tonga',
	COUNTRY_TRINIDAD_AND_TOBAGO: 'Trinidade e Tobago',
	COUNTRY_TUNISIA: 'Tunísia',
	COUNTRY_TURKEY: 'Turquia',
	COUNTRY_TURKMENISTAN: 'Turcomenistão',
	COUNTRY_TURKS_AND_CAICOS_ISLANDS: 'Ilhas Turcas e Caicos',
	COUNTRY_TUVALU: 'Tuvalu',
	COUNTRY_UGANDA: 'Uganda',
	COUNTRY_UKRAINE: 'Ucrânia',
	COUNTRY_UNITED_ARAB_EMIRATES: 'Emirados Árabes Unidos',
	COUNTRY_UNITED_KINGDOM: 'Reino Unido',
	COUNTRY_UNITED_STATES_MINOR_OUTLYING_ISLANDS: 'Ilhas Menores Distantes dos Estados Unidos',
	COUNTRY_URUGUAY: 'Uruguai',
	COUNTRY_UZBEKISTAN: 'Uzbequistão',
	COUNTRY_VANUATU: 'Vanuatu',
	COUNTRY_VATICAN_CITY_STATE_HOLY_SEE_: 'Estado da Cidade do Vaticano (Santa Fé)',
	COUNTRY_VENEZUELA: 'Venezuela',
	COUNTRY_VIETNAM: 'Vietnã',
	COUNTRY_VIRGIN_ISLANDS_BRITISH_: 'Ilhas Virgens (Reino Unido)',
	COUNTRY_VIRGIN_ISLANDS_US_: 'Ilhas Virgens (EUA)',
	COUNTRY_WALLIS_AND_FUTUNA_ISLANDS: 'Ilhas Wallis e Futuna',
	COUNTRY_WESTERN_SAHARA: 'Saara Ocidental',
	COUNTRY_YEMEN: 'Iêmen',
	COUNTRY_ZAMBIA: 'Zâmbia',
	COUNTRY_ZIMBABWE: 'Zimbábue',

	//  STATE/PROVINCE
	STATE_ANDAMAN_AND_NICOBAR_ISLANDS: 'Ilhas Andaman e Nicobar',
	STATE_ANDHRA_PRADESH: 'Andhra Pradesh',
	STATE_ARUNACHAL_PRADESH: 'Arunachal Prad',
	STATE_ASSAM: 'Assam',
	STATE_BIHAR: 'Bihar',
	STATE_CHANDIGARH: 'Chandigarh',
	STATE_CHHATTISGARH: 'Chhattisgarh',
	STATE_DADRA_AND_NAGAR_HAVELI: 'Dadra e Nagar Haveli',
	STATE_DAMAN_AND_DIU: 'Daman e Diu',
	STATE_DELHI: 'Déli',
	STATE_GOA: 'Goa',
	STATE_GUJARAT: 'Gujarat',
	STATE_HARYANA: 'Haryana',
	STATE_HIMACHAL_PRADESH: 'Himachal Prade',
	STATE_JAMMU_AND_KASHMIR: 'Jammu e Kashmir',
	STATE_JHARKHAND: 'Jharkhand',
	STATE_KARNATAKA: 'Karnataka',
	STATE_KENMORE: 'Kenmore',
	STATE_KERALA: 'Kerala',
	STATE_LAKSHADWEEP: 'Lakshadweep',
	STATE_MADHYA_PRADESH: 'Madhya Pradesh',
	STATE_MAHARASHTRA: 'Maharashtra',
	STATE_MANIPUR: 'Manipur',
	STATE_MEGHALAYA: 'Meghalaya',
	STATE_MIZORAM: 'Mizoram',
	STATE_NAGALAND: 'Nagaland',
	STATE_NARORA: 'Narora',
	STATE_NATWAR: 'Natwar',
	STATE_ODISHA: 'Odisha',
	STATE_PASCHIM_MEDINIPUR: 'Paschim Medinipur',
	STATE_PONDICHERRY: 'Pondicherry',
	STATE_PUNJAB: 'Punjabi',
	STATE_RAJASTHAN: 'Rajasthan',
	STATE_SIKKIM: 'Sikkim',
	STATE_TAMIL_NADU: 'Tamil Nadu',
	STATE_TELANGANA: 'Telangana',
	STATE_TRIPURA: 'Tripura',
	STATE_UTTAR_PRADESH: 'Uttar Pradesh',
	STATE_UTTARAKHAND: 'Uttarakhand',
	STATE_VAISHALI: 'Vaishali',
	STATE_WEST_BENGAL: 'Bengala Ocidental',
	STATE_BADAKHSHAN: 'Badakhshan',
	STATE_BADGIS: 'Badgis',
	STATE_BAGLAN: 'Baglan',
	STATE_BALKH: 'Balkh',
	STATE_BAMIYAN: 'Bamiyan',
	STATE_FARAH: 'Farah',
	STATE_FARYAB: 'Faryab',
	STATE_GAWR: 'Gawr',
	STATE_GAZNI: 'Gazni',
	STATE_HERAT: 'Herat',
	STATE_ALABAMA: 'Alabama',
	STATE_ALASKA: 'Alasca',
	STATE_ARIZONA: 'Arizona',
	STATE_ARKANSAS: 'Arkansas',
	STATE_GEORGIA: 'Geórgia',
	STATE_NEW_YORK: 'Nova York',
	STATE_VERMONT: 'Vermont',
	STATE_WASHINGTON: 'Washington',
	STATE_HILMAND: 'Hilmand',
	STATE_JAWZJAN: 'Jawzjan',
	STATE_KABUL: 'Cabul',
	STATE_KAPISA: 'Kapisa',
	STATE_KHAWST: 'Khawst',
	STATE_KUNAR: 'Kunar',
	STATE_LAGMAN: 'Lagman',
	STATE_LAWGHAR: 'Lawghar',
	STATE_NANGARHAR: 'Nangarhar',
	STATE_NIMRUZ: 'Nimruz',
	STATE_NURISTAN: 'Nuristan',
	STATE_PAKTIKA: 'Paktika',
	STATE_PAKTIYA: 'Paktiya',
	STATE_PARWAN: 'Parwan',
	STATE_QANDAHAR: 'Candar',
	STATE_QUNDUZ: 'Qunduz',
	STATE_SAMANGAN: 'Samangan',
	STATE_SAR_E_PUL: 'Sar-e Pul',
	STATE_TAKHAR: 'Takhar',
	STATE_URUZGAN: 'Uruzgan',
	STATE_WARDAG: 'Wardag',
	STATE_ZABUL: 'Zabul',
	STATE_BERAT: 'Berat',
	STATE_BULQIZE: 'Bulqize',
	STATE_DELVINE: 'Delvine',
	STATE_DEVOLL: 'Devoll',
	STATE_DIBRE: 'Dibre',
	STATE_DURRES: 'Durres',
	STATE_ELBASAN: 'Elbasan',
	STATE_FIER: 'Fier',
	STATE_GJIROKASTER: 'Gjirokaster',
	STATE_GRAMSH: 'Gramsh',
	STATE_HAS: 'Has',
	STATE_KAVAJE: 'Kavaje',
	STATE_KOLONJE: 'Kolonje',
	STATE_KORCE: 'Korce',
	STATE_KRUJE: 'Kruje',
	STATE_KUCOVE: 'Kucove',
	STATE_KUKES: 'Kukes',
	STATE_KURBIN: 'Kurbin',
	STATE_LEZHE: 'Lezhe',
	STATE_LIBRAZHD: 'Librazhd',
	STATE_LUSHNJE: 'Lushnje',
	STATE_MALLAKASTER: 'Mallakaster',
	STATE_MALSI_E_MADHE: 'Malsi e Madhe',
	STATE_MAT: 'Mat',
	STATE_MIRDITE: 'Mirdite',
	STATE_PEQIN: 'Peqin',
	STATE_PERMET: 'Permet',
	STATE_POGRADEC: 'Pogradec',
	STATE_PUKE: 'Puke',
	STATE_SARANDE: 'Sarande',
	STATE_SHKODER: 'Shkoder',
	STATE_SKRAPAR: 'Skrapar',
	STATE_TEPELENE: 'Tepelene',
	STATE_TIRANE: 'Tirane',
	STATE_TROPOJE: 'Tropoje',
	STATE_VLORE: 'Vlore',
	STATE_AYN_DAFLAH: 'Ayn Daflah',
	STATE_AYN_TAMUSHANAT: 'Ayn Tamushanat',
	STATE_ADRAR: 'Adrar',
	STATE_ALGIERS: 'Argel',
	STATE_ANNABAH: 'Annabah',
	STATE_BASHSHAR: 'Bashshar',
	STATE_BATNAH: 'Batnah',
	STATE_BIJAYAH: 'Bijayah',
	STATE_BISKRAH: 'Biskrah',
	STATE_BLIDAH: 'Blidah',
	STATE_BUIRAH: 'Buirah',
	STATE_BUMARDAS: 'Bumardas',
	STATE_BURJ_BU_ARRIRIJ: 'Burj Bu Arririj',
	STATE_GHALIZAN: 'Ghalizan',
	STATE_GHARDAYAH: 'Ghardayah',
	STATE_ILIZI: 'Ilizi',
	STATE_JIJILI: 'Jijili',
	STATE_JILFAH: 'Jilfah',
	STATE_KHANSHALAH: 'Khanshalah',
	STATE_MASILAH: 'Masilah',
	STATE_MIDYAH: 'Midyah',
	STATE_MILAH: 'Milah',
	STATE_MUASKAR: 'Muaskar',
	STATE_MUSTAGHANAM: 'Mustaghanam',
	STATE_NAAMA: 'Naama',
	STATE_ORAN: 'Oran',
	STATE_OUARGLA: 'Ouargla',
	STATE_QALMAH: 'Qalmah',
	STATE_QUSTANTINAH: 'Qustantinah',
	STATE_SAKIKDAH: 'Sakikdah',
	STATE_SATIF: 'Satif',
	STATE_SAYDA: 'Sayda',
	STATE_SIDI_BAN_AL___ABBAS: "Sidi ban-al-'Abbas",
	STATE_SUQ_AHRAS: 'Suq Ahras',
	STATE_TAMANGHASAT: 'Tamanghasat',
	STATE_TIBAZAH: 'Tibazah',
	STATE_TIBISSAH: 'Tibissah',
	STATE_TILIMSAN: 'Tilimsan',
	STATE_TINDUF: 'Tinduf',
	STATE_TISAMSILT: 'Tisamsilt',
	STATE_TIYARAT: 'Tiyarat',
	STATE_TIZI_WAZU: 'Tizi Wazu',
	STATE_UMM_AL_BAWAGHI: 'Umm-al-Bawaghi',
	STATE_WAHRAN: 'Wahran',
	STATE_WARQLA: 'Warqla',
	STATE_WILAYA_D_ALGER: 'Wilaya d’Alger',
	STATE_WILAYA_DE_BEJAIA: 'Wilaya de Bejaia',
	STATE_WILAYA_DE_CONSTANTINE: 'Wilaya de Constantine',
	STATE_AL_AGHWAT: 'al-Aghwat',
	STATE_AL_BAYADH: 'al-Bayadh',
	STATE_AL_JAZA__IR: "al-Jaza'ir",
	STATE_AL_WAD: 'al-Wad',
	STATE_ASH_SHALIF: 'ash-Shalif',
	STATE_AT_TARIF: 'at-Tarif',
	STATE_EASTERN: 'Oriental',
	STATE_MANU__A: "Manu'a",
	STATE_SWAINS_ISLAND: 'Ilha Swains',
	STATE_WESTERN: 'Ocidental',
	STATE_ANDORRA_LA_VELLA: 'Andorra la Vella',
	STATE_CANILLO: 'Canillo',
	STATE_ENCAMP: 'Encamp',
	STATE_LA_MASSANA: 'La Massana',
	STATE_LES_ESCALDES: 'Les Escaldes',
	STATE_ORDINO: 'Ordino',
	STATE_SANT_JULIA_DE_LORIA: 'Sant Julia de Loria',
	STATE_BENGO: 'Bengo',
	STATE_BENGUELA: 'Benguela',
	STATE_BIE: 'Bie',
	STATE_CABINDA: 'Cabinda',
	STATE_CUNENE: 'Cunene',
	STATE_HUAMBO: 'Huambo',
	STATE_HUILA: 'Huila',
	STATE_KUANDO_KUBANGO: 'Kuando-Kubango',
	STATE_KWANZA_NORTE: 'Kwanza Norte',
	STATE_KWANZA_SUL: 'Kwanza Sul',
	STATE_LUANDA: 'Luanda',
	STATE_LUNDA_NORTE: 'Lunda Norte',
	STATE_LUNDA_SUL: 'Lunda Sul',
	STATE_MALANJE: 'Malanje',
	STATE_MOXICO: 'Moxico',
	STATE_NAMIBE: 'Namibe',
	STATE_UIGE: 'Uige',
	STATE_ZAIRE: 'Zaire',
	STATE_OTHER_PROVINCES: 'Outras províncias',
	STATE_SECTOR_CLAIMED_BY_ARGENTINA_CH: 'Setor reivindicado pela Argentina/Ch',
	STATE_SECTOR_CLAIMED_BY_ARGENTINA_UK: 'Setor reivindicado pela Argentina/RU',
	STATE_SECTOR_CLAIMED_BY_AUSTRALIA: 'Setor reivindicado pela Austrália',
	STATE_SECTOR_CLAIMED_BY_FRANCE: 'Setor reivindicado pela França',
	STATE_SECTOR_CLAIMED_BY_NEW_ZEALAND: 'Setor reivindicado pela Nova Zelândia',
	STATE_SECTOR_CLAIMED_BY_NORWAY: 'Setor reivindicado pela Noruega',
	STATE_UNCLAIMED_SECTOR: 'Setor não reivindicado',
	STATE_BARBUDA: 'Barbuda',
	STATE_SAINT_GEORGE: 'Saint George',
	STATE_SAINT_JOHN: 'Saint John',
	STATE_SAINT_MARY: 'Saint Mary',
	STATE_SAINT_PAUL: 'Saint Paul',
	STATE_SAINT_PETER: 'Saint Peter',
	STATE_SAINT_PHILIP: 'Saint Philip',
	STATE_BUENOS_AIRES: 'Buenos Aires',
	STATE_CATAMARCA: 'Catamarca',
	STATE_CHACO: 'Chaco',
	STATE_CHUBUT: 'Chubut',
	STATE_CORDOBA: 'Córdoba',
	STATE_CORRIENTES: 'Corrientes',
	STATE_DISTRITO_FEDERAL: 'Distrito Federal',
	STATE_ENTRE_RIOS: 'Entre Rios',
	STATE_FORMOSA: 'Formosa',
	STATE_JUJUY: 'Jujuy',
	STATE_LA_PAMPA: 'La Pampa',
	STATE_HIDD: 'Hidd',
	STATE_LA_RIOJA: 'La Rioja',
	STATE_MENDOZA: 'Mendoza',
	STATE_MISIONES: 'Misiones',
	STATE_NEUQUEN: 'Neuquen',
	STATE_RIO_NEGRO: 'Rio Negro',
	STATE_SALTA: 'Salta',
	STATE_SAN_JUAN: 'San Juan',
	STATE_SAN_LUIS: 'San Luis',
	STATE_SANTA_CRUZ: 'Santa Cruz',
	STATE_SANTA_FE: 'Santa Fé',
	STATE_SANTIAGO_DEL_ESTERO: 'Santiago del Estero',
	STATE_TIERRA_DEL_FUEGO: 'Terra do Fogo',
	STATE_TUCUMAN: 'Tucumán',
	STATE_ARAGATSOTN: 'Aragatsotn',
	STATE_ARARAT: 'Ararat',
	STATE_ARMAVIR: 'Armavir',
	STATE_GEGHARKUNIK: 'Gegharkunik',
	STATE_KOTAIK: 'Kotaik',
	STATE_LORI: 'Lori',
	STATE_SHIRAK: 'Shirak',
	STATE_STEPANAKERT: 'Stepanakert',
	STATE_SYUNIK: 'Syunik',
	STATE_TAVUSH: 'Tavush',
	STATE_VAYOTS_DZOR: 'Vayots Dzor',
	STATE_YEREVAN: 'Yerevan',
	STATE_ARUBA: 'Aruba',
	STATE_AUCKLAND: 'Auckland',
	STATE_AUSTRALIAN_CAPITAL_TERRITORY: 'Território da Capital Australiana',
	STATE_BALGOWLAH: 'Balgowlah',
	STATE_BALMAIN: 'Balmain',
	STATE_BANKSTOWN: 'Bankstown',
	STATE_BAULKHAM_HILLS: 'Baulkham Hills',
	STATE_BONNET_BAY: 'Bonnet Bay',
	STATE_CAMBERWELL: 'Camberwell',
	STATE_CAROLE_PARK: 'Carole Park',
	STATE_CASTLE_HILL: 'Castle Hill',
	STATE_CAULFIELD: 'Caulfield',
	STATE_CHATSWOOD: 'Chatswood',
	STATE_CHELTENHAM: 'Cheltenham',
	STATE_CHERRYBROOK: 'Cherrybrook',
	STATE_CLAYTON: 'Clayton',
	STATE_COLLINGWOOD: 'Collingwood',
	STATE_FRENCHS_FOREST: 'Frenchs Forest',
	STATE_HAWTHORN: 'Hawthorn',
	STATE_JANNNALI: 'Jannnali',
	STATE_KNOXFIELD: 'Knoxfield',
	STATE_MELBOURNE: 'Melbourne',
	STATE_NEW_SOUTH_WALES: 'Nova Gales do Sul',
	STATE_NORTHERN_TERRITORY: 'Northern Territory',
	STATE_PERTH: 'Perth',
	STATE_QUEENSLAND: 'Queensland',
	STATE_SOUTH_AUSTRALIA: 'Austrália do Sul',
	STATE_TASMANIA: 'Tasmânia',
	STATE_TEMPLESTOWE: 'Templestowe',
	STATE_VICTORIA: 'Victoria',
	STATE_WERRIBEE_SOUTH: 'Werribee south',
	STATE_WESTERN_AUSTRALIA: 'Austrália Ocidental',
	STATE_WHEELER: 'Wheeler',
	STATE_BUNDESLAND_SALZBURG: 'Bundesland Salzburg',
	STATE_BUNDESLAND_STEIERMARK: 'Bundesland Steiermark',
	STATE_BUNDESLAND_TIROL: 'Bundesland Tirol',
	STATE_BURGENLAND: 'Burgenland',
	STATE_CARINTHIA: 'Carinthia',
	STATE_KARNTEN: 'Karnten',
	STATE_LIEZEN: 'Liezen',
	STATE_LOWER_AUSTRIA: 'Baixa Áustria',
	STATE_NIEDEROSTERREICH: 'Niederosterreich',
	STATE_OBEROSTERREICH: 'Oberosterreich',
	STATE_SALZBURG: 'Salzburgo',
	STATE_SCHLESWIG_HOLSTEIN: 'Schleswig-Holstein',
	STATE_STEIERMARK: 'Steiermark',
	STATE_STYRIA: 'Styria',
	STATE_TIROL: 'Tirol',
	STATE_UPPER_AUSTRIA: 'Alta Áustria',
	STATE_VORARLBERG: 'Vorarlberg',
	STATE_WIEN: 'Wien',
	STATE_ABSERON: 'Abseron',
	STATE_BAKI_SAHARI: 'Baki Sahari',
	STATE_GANCA: 'Ganca',
	STATE_GANJA: 'Ganja',
	STATE_KALBACAR: 'Kalbacar',
	STATE_LANKARAN: 'Lankaran',
	STATE_MIL_QARABAX: 'Mil-Qarabax',
	STATE_MUGAN_SALYAN: 'Mugan-Salyan',
	STATE_NAGORNI_QARABAX: 'Nagorni-Qarabax',
	STATE_NAXCIVAN: 'Naxcivan',
	STATE_PRIARAKS: 'Priaraks',
	STATE_QAZAX: 'Qazax',
	STATE_SAKI: 'Saki',
	STATE_SIRVAN: 'Sirvan',
	STATE_XACMAZ: 'Xacmaz',
	STATE_ABACO: 'Ábaco',
	STATE_ACKLINS_ISLAND: 'Ilha Acklins',
	STATE_ANDROS: 'Andros',
	STATE_BERRY_ISLANDS: 'Berry Islands',
	STATE_BIMINIS: 'Biminis',
	STATE_CAT_ISLAND: 'Ilha Cat',
	STATE_CROOKED_ISLAND: 'Ilha Crooked',
	STATE_ELEUTHERA: 'Eleuthera',
	STATE_EXUMA_AND_CAYS: 'Exuma e Cays',
	STATE_GRAND_BAHAMA: 'Grand Bahama',
	STATE_INAGUA_ISLANDS: 'Inagua Islands',
	STATE_LONG_ISLAND: 'Long Island',
	STATE_MAYAGUANA: 'Mayaguana',
	STATE_NEW_PROVIDENCE: 'Nova Providência',
	STATE_RAGGED_ISLAND: 'Ragged Island',
	STATE_RUM_CAY: 'Rum Cay',
	STATE_SAN_SALVADOR: 'San Salvador',
	STATE_ISA: 'Isa',
	STATE_BADIYAH: 'Badiyah',
	STATE_JIDD_HAFS: 'Jidd Hafs',
	STATE_MAHAMA: 'Mahama',
	STATE_MANAMA: 'Manama',
	STATE_SITRAH: 'Sitrah',
	STATE_AL_MANAMAH: 'al-Manamah',
	STATE_AL_MUHARRAQ: 'al-Muharraq',
	STATE_AR_RIFA__A: "ar-Rifa'a",
	STATE_BAGAR_HAT: 'Bagar Hat',
	STATE_BANDARBAN: 'Bandarban',
	STATE_BARGUNA: 'Barguna',
	STATE_BARISAL: 'Barisal',
	STATE_BHOLA: 'Bhola',
	STATE_BOGORA: 'Bogora',
	STATE_BRAHMAN_BARIYA: 'Brahman Bariya',
	STATE_CHANDPUR: 'Chandpur',
	STATE_CHATTAGAM: 'Chattagam',
	STATE_CHITTAGONG_DIVISION: 'Chittagong Division',
	STATE_CHUADANGA: 'Chuadanga',
	STATE_DHAKA: 'Dhaka',
	STATE_DINAJPUR: 'Dinajpur',
	STATE_FARIDPUR: 'Faridpur',
	STATE_FENI: 'Feni',
	STATE_GAYBANDA: 'Gaybanda',
	STATE_GAZIPUR: 'Gazipur',
	STATE_GOPALGANJ: 'Gopalganj',
	STATE_HABIGANJ: 'Habiganj',
	STATE_JAIPUR_HAT: 'Jaipur Hat',
	STATE_JAMALPUR: 'Jamalpur',
	STATE_JESSOR: 'Jessor',
	STATE_JHALAKATI: 'Jhalakati',
	STATE_JHANAYDAH: 'Jhanaydah',
	STATE_KHAGRACHHARI: 'Khagrachhari',
	STATE_KHULNA: 'Khulna',
	STATE_KISHORGANJ: 'Kishorganj',
	STATE_KOKS_BAZAR: 'Koks Bazar',
	STATE_KOMILLA: 'Komilla',
	STATE_KURIGRAM: 'Kurigram',
	STATE_KUSHTIYA: 'Kushtiya',
	STATE_LAKSHMIPUR: 'Lakshmipur',
	STATE_LALMANIR_HAT: 'Lalmanir Hat',
	STATE_MADARIPUR: 'Madaripur',
	STATE_MAGURA: 'Magura',
	STATE_MAIMANSINGH: 'Maimansingh',
	STATE_MANIKGANJ: 'Manikganj',
	STATE_MAULVI_BAZAR: 'Maulvi Bazar',
	STATE_MEHERPUR: 'Meherpur',
	STATE_MUNSHIGANJ: 'Munshiganj',
	STATE_NARAL: 'Naral',
	STATE_NARAYANGANJ: 'Narayanganj',
	STATE_NARSINGDI: 'Narsingdi',
	STATE_NATOR: 'Nator',
	STATE_NAUGAON: 'Naugaon',
	STATE_NAWABGANJ: 'Nawabganj',
	STATE_NETRAKONA: 'Netrakona',
	STATE_NILPHAMARI: 'Nilphamari',
	STATE_NOAKHALI: 'Noakhali',
	STATE_PABNA: 'Pabna',
	STATE_PANCHAGARH: 'Panchagarh',
	STATE_PATUAKHALI: 'Patuakhali',
	STATE_PIROJPUR: 'Pirojpur',
	STATE_RAJBARI: 'Rajbari',
	STATE_RAJSHAHI: 'Rajshahi',
	STATE_RANGAMATI: 'Rangamati',
	STATE_RANGPUR: 'Rangpur',
	STATE_SATKHIRA: 'Satkhira',
	STATE_SHARIATPUR: 'Shariatpur',
	STATE_SHERPUR: 'Sherpur',
	STATE_SILHAT: 'Silhat',
	STATE_SIRAJGANJ: 'Sirajganj',
	STATE_SUNAMGANJ: 'Sunamganj',
	STATE_TANGAYAL: 'Tangayal',
	STATE_THAKURGAON: 'Thakurgaon',
	STATE_CHRIST_CHURCH: 'Christ Church',
	STATE_SAINT_ANDREW: 'Saint Andrew',
	STATE_SAINT_JAMES: 'Saint James',
	STATE_SAINT_JOSEPH: 'Saint Joseph',
	STATE_SAINT_LUCY: 'Saint Lucy',
	STATE_SAINT_MICHAEL: 'Saint Michael',
	STATE_SAINT_THOMAS: 'Saint Thomas',
	STATE_BREST: 'Brest',
	STATE_HOMJEL: 'Homjel',
	STATE_HRODNA: 'Hrodna',
	STATE_MAHILJOW: 'Mahiljow',
	STATE_MAHILYOWSKAYA_VOBLASTS: 'Mahilyowskaya Voblasts',
	STATE_MINSK: 'Minsk',
	STATE_MINSKAJA_VOBLASTS: 'Minskaja Voblasts',
	STATE_PETRIK: 'Petrik',
	STATE_VICEBSK: 'Vicebsk',
	STATE_ANTWERPEN: 'Antuérpia',
	STATE_BERCHEM: 'Berchem',
	STATE_BRABANT: 'Brabant',
	STATE_BRABANT_WALLON: 'Brabant Wallon',
	STATE_BRUSSEL: 'Bruxelas',
	STATE_EAST_FLANDERS: 'East Flanders',
	STATE_HAINAUT: 'Hainaut',
	STATE_LIEGE: 'Liege',
	STATE_LIMBURG: 'Limburg',
	STATE_LUXEMBOURG: 'Luxemburgo',
	STATE_NAMUR: 'Namur',
	STATE_ONTARIO: 'Ontário',
	STATE_OOST_VLAANDEREN: 'Oost-Vlaanderen',
	STATE_PROVINCIE_BRABANT: 'Provincie Brabant',
	STATE_VLAAMS_BRABANT: 'Vlaams-Brabant',
	STATE_WALLONNE: 'Wallonne',
	STATE_WEST_VLAANDEREN: 'West-Vlaanderen',
	STATE_BELIZE: 'Belize',
	STATE_CAYO: 'Cayo',
	STATE_COROZAL: 'Corozal',
	STATE_ORANGE_WALK: 'Orange Walk',
	STATE_STANN_CREEK: 'Stann Creek',
	STATE_TOLEDO: 'Toledo',
	STATE_ALIBORI: 'Alibori',
	STATE_ATACORA: 'Atacora',
	STATE_ATLANTIQUE: 'Atlantique',
	STATE_BORGOU: 'Borgou',
	STATE_COLLINES: 'Collines',
	STATE_COUFFO: 'Couffo',
	STATE_DONGA: 'Donga',
	STATE_LITTORAL: 'Littoral',
	STATE_MONO: 'Mono',
	STATE_OUEME: 'Oueme',
	STATE_PLATEAU: 'Plateau',
	STATE_ZOU: 'Zou',
	STATE_HAMILTON: 'Hamilton',
	STATE_BUMTHANG: 'Bumthang',
	STATE_CHHUKHA: 'Chhukha',
	STATE_CHIRANG: 'Chirang',
	STATE_DAGA: 'Daga',
	STATE_GEYLEGPHUG: 'Geylegphug',
	STATE_HA: 'Ha',
	STATE_LHUNTSHI: 'Lhuntshi',
	STATE_MONGAR: 'Mongar',
	STATE_PEMAGATSEL: 'Pemagatsel',
	STATE_PUNAKHA: 'Punakha',
	STATE_RINPUNG: 'Rinpung',
	STATE_SAMCHI: 'Samchi',
	STATE_SAMDRUP_JONGKHAR: 'Samdrup Jongkhar',
	STATE_SHEMGANG: 'Shemgang',
	STATE_TASHIGANG: 'Tashigang',
	STATE_TIMPHU: 'Timphu',
	STATE_TONGSA: 'Tongsa',
	STATE_WANGDIPHODRANG: 'Wangdiphodrang',
	STATE_BENI: 'Beni',
	STATE_CHUQUISACA: 'Chuquisaca',
	STATE_COCHABAMBA: 'Cochabamba',
	STATE_LA_PAZ: 'La Paz',
	STATE_ORURO: 'Oruro',
	STATE_PANDO: 'Pando',
	STATE_POTOSI: 'Potosi',
	STATE_TARIJA: 'Tarija',
	STATE_FEDERACIJA_BOSNA_I_HERCEGOVINA: 'Federação da Bósnia e Herzegovina',
	STATE_REPUBLIKA_SRPSKA: 'República Sérvia',
	STATE_CENTRAL_BOBONONG: 'Central Bobonong',
	STATE_CENTRAL_BOTETI: 'Central Boteti',
	STATE_CENTRAL_MAHALAPYE: 'Central Mahalapye',
	STATE_CENTRAL_SEROWE_PALAPYE: 'Central Serowe-Palapye',
	STATE_CENTRAL_TUTUME: 'Central Tutume',
	STATE_CHOBE: 'Chobe',
	STATE_FRANCISTOWN: 'Francistown',
	STATE_GABORONE: 'Gaborone',
	STATE_GHANZI: 'Ghanzi',
	STATE_JWANENG: 'Jwaneng',
	STATE_KGALAGADI_NORTH: 'Kgalagadi North',
	STATE_KGALAGADI_SOUTH: 'Kgalagadi South',
	STATE_KGATLENG: 'Kgatleng',
	STATE_KWENENG: 'Kweneng',
	STATE_LOBATSE: 'Lobatse',
	STATE_NGAMILAND: 'Ngamiland',
	STATE_NGWAKETSE: 'Ngwaketse',
	STATE_NORTH_EAST: 'Nordeste',
	STATE_OKAVANGO: 'Okavango',
	STATE_ORAPA: 'Orapa',
	STATE_SELIBE_PHIKWE: 'Selibe Phikwe',
	STATE_SOUTH_EAST: 'Sudeste',
	STATE_SOWA: 'Sowa',
	STATE_BOUVET_ISLAND: 'Ilha Bouvet',
	STATE_ACRE: 'Acre',
	STATE_ALAGOAS: 'Alagoas',
	STATE_AMAPA: 'Amapá',
	STATE_AMAZONAS: 'Amazonas',
	STATE_BAHIA: 'Bahia',
	STATE_CEARA: 'Ceará',
	STATE_ESPIRITO_SANTO: 'Espírito Santo',
	STATE_ESTADO_DE_SAO_PAULO: 'Estado de São Paulo',
	STATE_GOIAS: 'Goiás',
	STATE_MARANHAO: 'Maranhão',
	STATE_MATO_GROSSO: 'Mato Grosso',
	STATE_MATO_GROSSO_DO_SUL: 'Mato Grosso do Sul',
	STATE_MINAS_GERAIS: 'Minas Gerais',
	STATE_PARA: 'Pará',
	STATE_PARAIBA: 'Paraíba',
	STATE_PARANA: 'Paraná',
	STATE_PERNAMBUCO: 'Pernambuco',
	STATE_PIAUI: 'Piauí',
	STATE_RIO_GRANDE_DO_NORTE: 'Rio Grande do Norte',
	STATE_RIO_GRANDE_DO_SUL: 'Rio Grande do Sul',
	STATE_RIO_DE_JANEIRO: 'Rio de Janeiro',
	STATE_RONDONIA: 'Rondônia',
	STATE_RORAIMA: 'Roraima',
	STATE_SANTA_CATARINA: 'Santa Catarina',
	STATE_SAO_PAULO: 'São Paulo',
	STATE_SERGIPE: 'Sergipe',
	STATE_TOCANTINS: 'Tocantins',
	STATE_BRITISH_INDIAN_OCEAN_TERRITORY: 'Território Britânico do Oceano Índico',
	STATE_BELAIT: 'Belait',
	STATE_BRUNEI_MUARA: 'Brunei-Muara',
	STATE_TEMBURONG: 'Temburong',
	STATE_TUTONG: 'Tutong',
	STATE_BLAGOEVGRAD: 'Blagoevgrad',
	STATE_BURGAS: 'Burgas',
	STATE_DOBRICH: 'Dobrich',
	STATE_GABROVO: 'Gabrovo',
	STATE_HASKOVO: 'Haskovo',
	STATE_JAMBOL: 'Jambol',
	STATE_KARDZHALI: 'Kardzhali',
	STATE_KJUSTENDIL: 'Kjustendil',
	STATE_LOVECH: 'Lovech',
	STATE_MONTANA: 'Montana',
	STATE_OBLAST_SOFIYA_GRAD: 'Oblast Sofiya-Grad',
	STATE_PAZARDZHIK: 'Pazardzhik',
	STATE_PERNIK: 'Pernik',
	STATE_PLEVEN: 'Pleven',
	STATE_PLOVDIV: 'Plovdiv',
	STATE_RAZGRAD: 'Razgrad',
	STATE_RUSE: 'Ruse',
	STATE_SHUMEN: 'Shumen',
	STATE_SILISTRA: 'Silistra',
	STATE_SLIVEN: 'Sliven',
	STATE_SMOLJAN: 'Smoljan',
	STATE_SOFIJA_GRAD: 'Sofija grad',
	STATE_SOFIJSKA_OBLAST: 'Sofijska oblast',
	STATE_STARA_ZAGORA: 'Stara Zagora',
	STATE_TARGOVISHTE: 'Targovishte',
	STATE_VARNA: 'Varna',
	STATE_VELIKO_TARNOVO: 'Veliko Tarnovo',
	STATE_VIDIN: 'Vidin',
	STATE_VRACA: 'Vraca',
	STATE_YABLANIZA: 'Yablaniza',
	STATE_BALE: 'Bale',
	STATE_BAM: 'Bam',
	STATE_BAZEGA: 'Bazega',
	STATE_BOUGOURIBA: 'Bougouriba',
	STATE_BOULGOU: 'Boulgou',
	STATE_BOULKIEMDE: 'Boulkiemde',
	STATE_COMOE: 'Comoe',
	STATE_GANZOURGOU: 'Ganzourgou',
	STATE_GNAGNA: 'Gnagna',
	STATE_GOURMA: 'Gourma',
	STATE_HOUET: 'Houet',
	STATE_IOBA: 'Ioba',
	STATE_KADIOGO: 'Kadiogo',
	STATE_KENEDOUGOU: 'Kenedougou',
	STATE_KOMANDJARI: 'Komandjari',
	STATE_KOMPIENGA: 'Kompienga',
	STATE_KOSSI: 'Kossi',
	STATE_KOURITENGA: 'Kouritenga',
	STATE_KOURWEOGO: 'Kourweogo',
	STATE_LERABA: 'Leraba',
	STATE_MOUHOUN: 'Mouhoun',
	STATE_NAHOURI: 'Nahouri',
	STATE_NAMENTENGA: 'Namentenga',
	STATE_NOUMBIEL: 'Noumbiel',
	STATE_OUBRITENGA: 'Oubritenga',
	STATE_OUDALAN: 'Oudalan',
	STATE_PASSORE: 'Passore',
	STATE_PONI: 'Poni',
	STATE_SANGUIE: 'Sanguie',
	STATE_SANMATENGA: 'Sanmatenga',
	STATE_SENO: 'Seno',
	STATE_SISSILI: 'Sissili',
	STATE_SOUM: 'Soum',
	STATE_SOUROU: 'Sourou',
	STATE_TAPOA: 'Tapoa',
	STATE_TUY: 'Tuy',
	STATE_YATENGA: 'Yatenga',
	STATE_ZONDOMA: 'Zondoma',
	STATE_ZOUNDWEOGO: 'Zoundweogo',
	STATE_BUBANZA: 'Bubanza',
	STATE_BUJUMBURA: 'Bujumbura',
	STATE_BURURI: 'Bururi',
	STATE_CANKUZO: 'Cankuzo',
	STATE_CIBITOKE: 'Cibitoke',
	STATE_GITEGA: 'Gitega',
	STATE_KARUZI: 'Karuzi',
	STATE_KAYANZA: 'Kayanza',
	STATE_KIRUNDO: 'Kirundo',
	STATE_MAKAMBA: 'Makamba',
	STATE_MURAMVYA: 'Muramvya',
	STATE_MUYINGA: 'Muyinga',
	STATE_NGOZI: 'Ngozi',
	STATE_RUTANA: 'Rutana',
	STATE_RUYIGI: 'Ruyigi',
	STATE_BANTEAY_MEAN_CHEY: 'Banteay Mean Chey',
	STATE_BAT_DAMBANG: 'Bat Dambang',
	STATE_KAMPONG_CHAM: 'Kampong Cham',
	STATE_KAMPONG_CHHNANG: 'Kampong Chhnang',
	STATE_KAMPONG_SPOEU: 'Kampong Spoeu',
	STATE_KAMPONG_THUM: 'Kampong Thum',
	STATE_KAMPOT: 'Kampot',
	STATE_KANDAL: 'Kandal',
	STATE_KAOH_KONG: 'Kaoh Kong',
	STATE_KRACHEH: 'Kracheh',
	STATE_KRONG_KAEB: 'Krong Kaeb',
	STATE_KRONG_PAILIN: 'Krong Pailin',
	STATE_KRONG_PREAH_SIHANOUK: 'Krong Preah Sihanouk',
	STATE_MONDOL_KIRI: 'Mondol Kiri',
	STATE_OTDAR_MEAN_CHEY: 'Otdar Mean Chey',
	STATE_PHNUM_PENH: 'Phnum Penh',
	STATE_POUSAT: 'Pousat',
	STATE_PREAH_VIHEAR: 'Preah Vihear',
	STATE_PREY_VEAENG: 'Prey Veaeng',
	STATE_ROTANAK_KIRI: 'Rotanak Kiri',
	STATE_SIEM_REAB: 'Siem Reab',
	STATE_STUENG_TRAENG: 'Stueng Traeng',
	STATE_SVAY_RIENG: 'Svay Rieng',
	STATE_TAKAEV: 'Takaev',
	STATE_ADAMAOUA: 'Adamaoua',
	STATE_CENTRE: 'Centro',
	STATE_EST: 'Leste',
	STATE_NORD: 'Norte',
	STATE_NORD_EXTREME: 'Extremo Norte',
	STATE_NORDOUEST: 'Noroeste',
	STATE_OUEST: 'Oeste',
	STATE_SUD: 'Sul',
	STATE_SUDOUEST: 'Sudoeste',
	STATE_ALBERTA: 'Alberta',
	STATE_BRITISH_COLUMBIA: 'Colúmbia Britânica',
	STATE_MANITOBA: 'Manitoba',
	STATE_NEW_BRUNSWICK: 'Nova Brunswick',
	STATE_NEWFOUNDLAND_AND_LABRADOR: 'Terra Nova e Labrador',
	STATE_NORTHWEST_TERRITORIES: 'Territórios do Noroeste',
	STATE_NOVA_SCOTIA: 'Nova Escócia',
	STATE_NUNAVUT: 'Nunavut',
	STATE_PRINCE_EDWARD_ISLAND: ' Ilha do Príncipe Eduardo',
	STATE_QUEBEC: 'Quebec',
	STATE_SASKATCHEWAN: 'Saskatchewan',
	STATE_YUKON: 'Yukon',
	STATE_BOAVISTA: 'Boavista',
	STATE_BRAVA: 'Brava',
	STATE_FOGO: 'Fogo',
	STATE_MAIO: 'Maio',
	STATE_SAL: 'Sal',
	STATE_SANTO_ANTAO: 'Santo Antão',
	STATE_SAO_NICOLAU: 'São Nicolau',
	STATE_SAO_TIAGO: 'São Tiago',
	STATE_SAO_VICENTE: 'São Vicente',
	STATE_GRAND_CAYMAN: 'Grand Cayman',
	STATE_BAMINGUI_BANGORAN: 'Bamingui-Bangoran',
	STATE_BANGUI: 'Bangui',
	STATE_BASSE_KOTTO: 'Basse-Kotto',
	STATE_HAUT_MBOMOU: 'Haut-Mbomou',
	STATE_HAUTE_KOTTO: 'Haute-Kotto',
	STATE_KEMO: 'Kemo',
	STATE_LOBAYE: 'Lobaye',
	STATE_MAMBERE_KADEI: 'Mambere-Kadei',
	STATE_MBOMOU: 'Mbomou',
	STATE_NANA_GRIBIZI: 'Nana-Gribizi',
	STATE_NANA_MAMBERE: 'Nana-Mambere',
	STATE_OMBELLA_MPOKO: 'Ombella Mpoko',
	STATE_OUAKA: 'Ouaka',
	STATE_OUHAM: 'Ouham',
	STATE_OUHAM_PENDE: 'Ouham-Pende',
	STATE_SANGHA_MBAERE: 'Sangha-Mbaere',
	STATE_VAKAGA: 'Vakaga',
	STATE_BATHA: 'Batha',
	STATE_BILTINE: 'Biltine',
	STATE_BOURKOU_ENNEDI_TIBESTI: 'Bourkou-Ennedi-Tibesti',
	STATE_CHARI_BAGUIRMI: 'Chari-Baguirmi',
	STATE_GUERA: 'Guera',
	STATE_KANEM: 'Kanem',
	STATE_LAC: 'Lac',
	STATE_LOGONE_OCCIDENTAL: 'Logone Ocidental',
	STATE_LOGONE_ORIENTAL: 'Logone Oriental',
	STATE_MAYO_KEBBI: 'Mayo-Kebbi',
	STATE_MOYEN_CHARI: 'Moyen-Chari',
	STATE_OUADDAI: 'Ouaddai',
	STATE_SALAMAT: 'Salamat',
	STATE_TANDJILE: 'Tandjile',
	STATE_AISEN: 'Aisen',
	STATE_ANTOFAGASTA: 'Antofagasta',
	STATE_ARAUCANIA: 'Araucânia',
	STATE_ATACAMA: 'Atacama',
	STATE_BIO_BIO: 'Bio Bio',
	STATE_COQUIMBO: 'Coquimbo',
	STATE_LIBERTADOR_GENERAL_BERNARDO_O: 'Libertador General Bernardo O',
	STATE_LOS_LAGOS: 'Los Lagos',
	STATE_MAGELLANES: 'Magellanes',
	STATE_MAULE: 'Maule',
	STATE_METROPOLITANA: 'Metropolitana',
	STATE_METROPOLITANA_DE_SANTIAGO: 'Metropolitana de Santiago',
	STATE_TARAPACA: 'Tarapaca',
	STATE_VALPARAISO: 'Valparaíso',
	STATE_ANHUI: 'Anhui',
	STATE_ANHUI_PROVINCE: 'Anhui Province',
	STATE_ANHUI_SHENG: 'Anhui Sheng',
	STATE_AOMEN: 'Aomen',
	STATE_BEIJING: 'Pequim',
	STATE_BEIJING_SHI: 'Pequim',
	STATE_CHONGQING: 'Chongqing',
	STATE_FUJIAN: 'Fujian',
	STATE_FUJIAN_SHENG: 'Fujian Sheng',
	STATE_GANSU: 'Gansu',
	STATE_GUANGDONG: 'Guangdong',
	STATE_GUANGDONG_SHENG: 'Guangdong Sheng',
	STATE_GUANGXI: 'Guangxi',
	STATE_GUIZHOU: 'Guizhou',
	STATE_HAINAN: 'Hainan',
	STATE_HEBEI: 'Hebei',
	STATE_HEILONGJIANG: 'Heilongjiang',
	STATE_HENAN: 'Henan',
	STATE_HUBEI: 'Hubei',
	STATE_HUNAN: 'Hunan',
	STATE_JIANGSU: 'Jiangsu',
	STATE_JIANGSU_SHENG: 'Jiangsu Sheng',
	STATE_JIANGXI: 'Jiangxi',
	STATE_JILIN: 'Jilin',
	STATE_LIAONING: 'Liaoning',
	STATE_LIAONING_SHENG: 'Liaoning Sheng',
	STATE_NEI_MONGGOL: 'Nei Monggol',
	STATE_NINGXIA_HUI: 'Ningxia Hui',
	STATE_QINGHAI: 'Qinghai',
	STATE_SHAANXI: 'Shaanxi',
	STATE_SHANDONG: 'Shandong',
	STATE_SHANDONG_SHENG: 'Shandong Sheng',
	STATE_SHANGHAI: 'Xangai',
	STATE_SHANXI: 'Shanxi',
	STATE_SICHUAN: 'Sichuan',
	STATE_TIANJIN: 'Tianjin',
	STATE_XIANGGANG: 'Xianggang',
	STATE_XINJIANG: 'Xinjiang',
	STATE_XIZANG: 'Xizang',
	STATE_YUNNAN: 'Yunnan',
	STATE_ZHEJIANG: 'Zhejiang',
	STATE_ZHEJIANG_SHENG: 'Zhejiang Sheng',
	STATE_CHRISTMAS_ISLAND: 'Ilha Christmas',
	STATE_COCOS_KEELING_ISLANDS: 'Ilhas Cocos (Keeling)',
	STATE_ANTIOQUIA: 'Antióquia',
	STATE_ARAUCA: 'Araucânia',
	STATE_ATLANTICO: 'Atlântico',
	STATE_BOGOTA: 'Bogotá',
	STATE_BOLIVAR: 'Bolívar',
	STATE_BOYACA: 'Boyaca',
	STATE_CALDAS: 'Caldas',
	STATE_CAQUETA: 'Caqueta',
	STATE_CASANARE: 'Casanare',
	STATE_CAUCA: 'Cauca',
	STATE_CESAR: 'Cesar',
	STATE_CHOCO: 'Choco',
	STATE_CUNDINAMARCA: 'Cundinamarca',
	STATE_GUAINIA: 'Guainia',
	STATE_GUAVIARE: 'Guaviare',
	STATE_LA_GUAJIRA: 'La Guajira',
	STATE_MAGDALENA: 'Magdalena',
	STATE_META: 'Meta',
	STATE_NARINO: 'Narino',
	STATE_NORTE_DE_SANTANDER: 'Norte de Santander',
	STATE_PUTUMAYO: 'Putumayo',
	STATE_QUINDIO: 'Quindio',
	STATE_RISARALDA: 'Risaralda',
	STATE_SAN_ANDRES_Y_PROVIDENCIA: 'San Andrés e Providencia',
	STATE_SANTANDER: 'Norte de Santander',
	STATE_SUCRE: 'Sucre',
	STATE_TOLIMA: 'Tolima',
	STATE_VALLE_DEL_CAUCA: 'Valle del Cauca',
	STATE_VAUPES: 'Vaupes',
	STATE_VICHADA: 'Vichada',
	STATE_MWALI: 'Mwali',
	STATE_NJAZIDJA: 'Njazidja',
	STATE_NZWANI: 'Nzwani',
	STATE_BOUENZA: 'Bouenza',
	STATE_BRAZZAVILLE: 'Brazzaville',
	STATE_CUVETTE: 'Cuvette',
	STATE_KOUILOU: 'Kouilou',
	STATE_LEKOUMOU: 'Lekoumou',
	STATE_LIKOUALA: 'Likouala',
	STATE_NIARI: 'Niari',
	STATE_PLATEAUX: 'Plateaux',
	STATE_POOL: 'Pool',
	STATE_SANGHA: 'Sangha',
	STATE_BANDUNDU: 'Bandundu',
	STATE_BAS_CONGO: 'Baixo Congo',
	STATE_EQUATEUR: 'Equador',
	STATE_HAUT_CONGO: 'Alto Congo',
	STATE_KASAI_OCCIDENTAL: 'Kasai-Ocidental',
	STATE_KASAI_ORIENTAL: 'Kasai-Oriental',
	STATE_KATANGA: 'Katanga',
	STATE_KINSHASA: 'Kinshasa',
	STATE_MANIEMA: 'Maniema',
	STATE_NORD_KIVU: 'Kivu do Norte',
	STATE_SUD_KIVU: 'Kivu do Sul',
	STATE_AITUTAKI: 'Aitutaki',
	STATE_ATIU: 'Atiu',
	STATE_MANGAIA: 'Mangaia',
	STATE_MANIHIKI: 'Manihiki',
	STATE_MAUKE: 'Mauke',
	STATE_MITIARO: 'Mitiaro',
	STATE_NASSAU: 'Nassau',
	STATE_PUKAPUKA: 'Pukapuka',
	STATE_RAKAHANGA: 'Rakahanga',
	STATE_RAROTONGA: 'Rarotonga',
	STATE_TONGAREVA: 'Tongareva',
	STATE_ALAJUELA: 'Alajuela',
	STATE_CARTAGO: 'Cartago',
	STATE_GUANACASTE: 'Guanacaste',
	STATE_HEREDIA: 'Heredia',
	STATE_LIMON: 'Limon',
	STATE_PUNTARENAS: 'Puntarenas',
	STATE_SAN_JOSE: 'San José',
	STATE_ABIDJAN: 'Abidjan',
	STATE_AGNEBY: 'Agneby',
	STATE_BAFING: 'Bafing',
	STATE_DENGUELE: 'Denguele',
	STATE_DIX_HUIT_MONTAGNES: 'Dix-huit Montagnes',
	STATE_FROMAGER: 'Fromager',
	STATE_HAUT_SASSANDRA: 'Haut-Sassandra',
	STATE_LACS: 'Lacs',
	STATE_LAGUNES: 'Lagunes',
	STATE_MARAHOUE: 'Marahoue',
	STATE_MOYEN_CAVALLY: 'Moyen-Cavally',
	STATE_MOYEN_COMOE: 'Moyen-Comoe',
	STATE_N__ZI_COMOE: "N'zi-Comoe",
	STATE_SASSANDRA: 'Sassandra',
	STATE_SAVANES: 'Savanes',
	STATE_SUD_BANDAMA: 'Sud-Bandama',
	STATE_SUD_COMOE: 'Sud-Comoe',
	STATE_VALLEE_DU_BANDAMA: 'Vallee du Bandama',
	STATE_WORODOUGOU: 'Worodougou',
	STATE_ZANZAN: 'Zanzan',
	STATE_BJELOVAR_BILOGORA: 'Bjelovar-Bilogora',
	STATE_DUBROVNIK_NERETVA: 'Dubrovnik-Neretva',
	STATE_GRAD_ZAGREB: 'Grad Zagreb',
	STATE_ISTRA: 'Istra',
	STATE_KARLOVAC: 'Karlovac',
	STATE_KOPRIVNICA_KRIZHEVCI: 'Koprivnica-Krizhevci',
	STATE_KRAPINA_ZAGORJE: 'Krapina-Zagorje',
	STATE_LIKA_SENJ: 'Lika-Senj',
	STATE_MEDHIMURJE: 'Medhimurje',
	STATE_MEDIMURSKA_ZUPANIJA: 'Medimurska Zupanija',
	STATE_OSIJEK_BARANJA: 'Osijek-Baranja',
	STATE_OSJECKO_BARANJSKA_ZUPANIJA: 'Osjecko-Baranjska Zupanija',
	STATE_POZHEGA_SLAVONIJA: 'Pozhega-Slavonija',
	STATE_PRIMORJE_GORSKI_KOTAR: 'Primorje-Gorski Kotar',
	STATE_SHIBENIK_KNIN: 'Shibenik-Knin',
	STATE_SISAK_MOSLAVINA: 'Sisak-Moslavina',
	STATE_SLAVONSKI_BROD_POSAVINA: 'Slavonski Brod-Posavina',
	STATE_SPLIT_DALMACIJA: 'Split-Dalmacija',
	STATE_VARAZHDIN: 'Varazhdin',
	STATE_VIROVITICA_PODRAVINA: 'Virovitica-Podravina',
	STATE_VUKOVAR_SRIJEM: 'Vukovar-Srijem',
	STATE_ZADAR: 'Zadar',
	STATE_ZAGREB: 'Zagreb',
	STATE_CAMAGUEY: 'Camaguey',
	STATE_CIEGO_DE_AVILA: 'Ciego de Avila',
	STATE_CIENFUEGOS: 'Cienfuegos',
	STATE_CIUDAD_DE_LA_HABANA: 'Cidade de Havana',
	STATE_GRANMA: 'Granma',
	STATE_GUANTANAMO: 'Guantánamo',
	STATE_HABANA: 'Havana',
	STATE_HOLGUIN: 'Holguin',
	STATE_ISLA_DE_LA_JUVENTUD: 'Isla de la Juventud',
	STATE_LA_HABANA: 'La Habana',
	STATE_LAS_TUNAS: 'Las Tunas',
	STATE_MATANZAS: 'Matanzas',
	STATE_PINAR_DEL_RIO: 'Pinar del Rio',
	STATE_SANCTI_SPIRITUS: 'Sancti Spiritus',
	STATE_SANTIAGO_DE_CUBA: 'Santiago de Cuba',
	STATE_VILLA_CLARA: 'Villa Clara',
	STATE_GOVERNMENT_CONTROLLED_AREA: 'Área sob controle do governo',
	STATE_LIMASSOL: 'Limassol',
	STATE_NICOSIA_DISTRICT: 'Distrito de Nicosia',
	STATE_PAPHOS: 'Paphos',
	STATE_TURKISH_CONTROLLED_AREA: 'Área sob controle turco',
	STATE_CENTRAL_BOHEMIAN: 'Central Bohemian',
	STATE_FRYCOVICE: 'Frycovice',
	STATE_JIHOCESKY_KRAJ: 'Jihocesky Kraj',
	STATE_JIHOCHESKY: 'Jihochesky',
	STATE_JIHOMORAVSKY: 'Jihomoravsky',
	STATE_KARLOVARSKY: 'Karlovarsky',
	STATE_KLECANY: 'Klecany',
	STATE_KRALOVEHRADECKY: 'Kralovehradecky',
	STATE_LIBERECKY: 'Liberecky',
	STATE_LIPOV: 'Lipov',
	STATE_MORAVSKOSLEZSKY: 'Moravskoslezsky',
	STATE_OLOMOUCKY: 'Olomoucky',
	STATE_OLOMOUCKY_KRAJ: 'Olomoucky Kraj',
	STATE_PARDUBICKY: 'Pardubicky',
	STATE_PLZENSKY: 'Plzensky',
	STATE_PRAHA: 'Praha',
	STATE_RAJHRAD: 'Rajhrad',
	STATE_SMIRICE: 'Smirice',
	STATE_SOUTH_MORAVIAN: 'South Moravian',
	STATE_STRAZ_NAD_NISOU: 'Straz nad Nisou',
	STATE_STREDOCHESKY: 'Stredochesky',
	STATE_UNICOV: 'Unicov',
	STATE_USTECKY: 'Ustecky',
	STATE_VALLETTA: 'Valletta',
	STATE_VELESIN: 'Velesin',
	STATE_VYSOCHINA: 'Vysochina',
	STATE_ZLINSKY: 'Zlinsky',
	STATE_BORNHOLM: 'Bornholm',
	STATE_FREDERIKSBORG: 'Frederiksborg',
	STATE_FYN: 'Fyn',
	STATE_HOVEDSTADEN: 'Hovedstaden',
	STATE_KOBENHAVN: 'Copenhague',
	STATE_KOBENHAVNS_AMT: 'Kobenhavns Amt',
	STATE_KOBENHAVNS_KOMMUNE: 'Kobenhavns Kommune',
	STATE_NORDJYLLAND: 'Nordjylland',
	STATE_RIBE: 'Ribe',
	STATE_RINGKOBING: 'Ringkobing',
	STATE_ROERVIG: 'Roervig',
	STATE_ROSKILDE: 'Roskilde',
	STATE_ROSLEV: 'Roslev',
	STATE_SJAELLAND: 'Sjaelland',
	STATE_SOEBORG: 'Soeborg',
	STATE_SONDERJYLLAND: 'Sonderjylland',
	STATE_STORSTROM: 'Storstrom',
	STATE_SYDDANMARK: 'Syddanmark',
	STATE_TOELLOESE: 'Toelloese',
	STATE_VEJLE: 'Vejle',
	STATE_VESTSJALLAND: 'Vestsjalland',
	STATE_VIBORG: 'Viborg',
	STATE_ALI_SABIH: 'Ali Sabih',
	STATE_DIKHIL: 'Dikhil',
	STATE_JIBUTI: 'Jibuti',
	STATE_TAJURAH: 'Tajurah',
	STATE_UBUK: 'Ubuk',
	STATE_SAINT_DAVID: 'Saint David',
	STATE_SAINT_LUKE: 'Saint Luke',
	STATE_SAINT_MARK: 'Saint Mark',
	STATE_SAINT_PATRICK: 'Saint Patrick',
	STATE_AZUA: 'Azua',
	STATE_BAHORUCO: 'Bahoruco',
	STATE_BARAHONA: 'Barahona',
	STATE_DAJABON: 'Dajabon',
	STATE_DISTRITO_NACIONAL: 'Distrito Nacional',
	STATE_DUARTE: 'Duarte',
	STATE_EL_SEYBO: 'El Seybo',
	STATE_ELIAS_PINA: 'Elias Pina',
	STATE_ESPAILLAT: 'Espaillat',
	STATE_HATO_MAYOR: 'Hato Mayor',
	STATE_INDEPENDENCIA: 'Independência',
	STATE_LA_ALTAGRACIA: 'La Altagracia',
	STATE_LA_ROMANA: 'La Roma',
	STATE_LA_VEGA: 'La Vega',
	STATE_MARIA_TRINIDAD_SANCHEZ: 'Maria Trinidad Sanchez',
	STATE_MONSENOR_NOUEL: 'Monsenor Nouel',
	STATE_MONTE_CRISTI: 'Monte Cristi',
	STATE_MONTE_PLATA: 'Monte Plata',
	STATE_PEDERNALES: 'Pedernales',
	STATE_PERAVIA: 'Peravia',
	STATE_PUERTO_PLATA: 'Puerto Plata',
	STATE_SALCEDO: 'Salcedo',
	STATE_SAMANA: 'Samana',
	STATE_SAN_CRISTOBAL: 'San Cristobal',
	STATE_SAN_PEDRO_DE_MACORIS: 'San Pedro de Macoris',
	STATE_SANCHEZ_RAMIREZ: 'Sanchez Ramirez',
	STATE_SANTIAGO: 'Santiago',
	STATE_SANTIAGO_RODRIGUEZ: 'Santiago Rodriguez',
	STATE_VALVERDE: 'Valverde',
	STATE_AILEU: 'Aileu',
	STATE_AINARO: 'Ainaro',
	STATE_AMBENO: 'Ambeno',
	STATE_BAUCAU: 'Baucau',
	STATE_BOBONARO: 'Bobonaro',
	STATE_COVA_LIMA: 'Cova Lima',
	STATE_DILI: 'Dili',
	STATE_ERMERA: 'Ermera',
	STATE_LAUTEM: 'Lautem',
	STATE_LIQUICA: 'Liquica',
	STATE_MANATUTO: 'Manatuto',
	STATE_MANUFAHI: 'Manufahi',
	STATE_VIQUEQUE: 'Viqueque',
	STATE_AZUAY: 'Azuay',
	STATE_CANAR: 'Canar',
	STATE_CARCHI: 'Carchi',
	STATE_CHIMBORAZO: 'Chimborazo',
	STATE_COTOPAXI: 'Cotopaxi',
	STATE_EL_ORO: 'El Oro',
	STATE_ESMERALDAS: 'Esmeraldas',
	STATE_GALAPAGOS: 'Galápagos',
	STATE_GUAYAS: 'Guayas',
	STATE_IMBABURA: 'Imbabura',
	STATE_LOJA: 'Loja',
	STATE_LOS_RIOS: 'Los Rios',
	STATE_MANABI: 'Manabi',
	STATE_MORONA_SANTIAGO: 'Morona Santiago',
	STATE_NAPO: 'Napo',
	STATE_ORELLANA: 'Orellana',
	STATE_PASTAZA: 'Pastaza',
	STATE_PICHINCHA: 'Pichincha',
	STATE_SUCUMBIOS: 'Sucumbios',
	STATE_TUNGURAHUA: 'Tungurahua',
	STATE_ZAMORA_CHINCHIPE: 'Zamora Chinchipe',
	STATE_ASWAN: 'Aswan',
	STATE_ASYUT: 'Asyut',
	STATE_BANI_SUWAYF: 'Bani Suwayf',
	STATE_BUR_SA__ID: "Bur Sa'id",
	STATE_CAIRO: 'Cairo',
	STATE_DUMYAT: 'Dumyat',
	STATE_KAFR_ASH_SHAYKH: 'Kafr-ash-Shaykh',
	STATE_MATRUH: 'Matruh',
	STATE_MUHAFAZAT_AD_DAQAHLIYAH: 'Muhafazat ad Daqahliyah',
	STATE_MUHAFAZAT_AL_FAYYUM: 'Muhafazat al Fayyum',
	STATE_MUHAFAZAT_AL_GHARBIYAH: 'Muhafazat al Gharbiyah',
	STATE_MUHAFAZAT_AL_ISKANDARIYAH: 'Muhafazat al Iskandariyah',
	STATE_MUHAFAZAT_AL_QAHIRAH: 'Muhafazat al Qahirah',
	STATE_QINA: 'Qina',
	STATE_SAWHAJ: 'Sawhaj',
	STATE_SINA_AL_JANUBIYAH: 'Sina al-Janubiyah',
	STATE_SINA_ASH_SHAMALIYAH: 'Sina ash-Shamaliyah',
	STATE_AD_DAQAHLIYAH: 'ad-Daqahliyah',
	STATE_AL_BAHR_AL_AHMAR: 'al-Bahr-al-Ahmar',
	STATE_AL_BUHAYRAH: 'al-Buhayrah',
	STATE_AL_FAYYUM: 'al-Fayyum',
	STATE_AL_GHARBIYAH: 'al-Gharbiyah',
	STATE_AL_ISKANDARIYAH: 'al-Iskandariyah',
	STATE_AL_ISMAILIYAH: 'al-Ismailiyah',
	STATE_AL_JIZAH: 'al-Jizah',
	STATE_AL_MINUFIYAH: 'al-Minufiyah',
	STATE_AL_MINYA: 'al-Minya',
	STATE_AL_QAHIRA: 'al-Qahira',
	STATE_AL_QALYUBIYAH: 'al-Qalyubiyah',
	STATE_AL_UQSUR: 'al-Uqsur',
	STATE_AL_WADI_AL_JADID: 'al-Wadi al-Jadid',
	STATE_AS_SUWAYS: 'as-Suways',
	STATE_ASH_SHARQIYAH: 'ash-Sharqiyah',
	STATE_AHUACHAPAN: 'Ahuachapan',
	STATE_CABANAS: 'Cabanas',
	STATE_CHALATENANGO: 'Chalatenango',
	STATE_CUSCATLAN: 'Cuscatlan',
	STATE_LA_LIBERTAD: 'La Libertad',
	STATE_LA_UNION: 'La Union',
	STATE_MORAZAN: 'Morazan',
	STATE_SAN_MIGUEL: 'San Miguel',
	STATE_SAN_VICENTE: 'San Vicente',
	STATE_SANTA_ANA: 'Santa Ana',
	STATE_SONSONATE: 'Sonsonate',
	STATE_USULUTAN: 'Usulutan',
	STATE_ANNOBON: 'Annobon',
	STATE_BIOKO_NORTE: 'Bioko Norte',
	STATE_BIOKO_SUR: 'Bioko Sur',
	STATE_CENTRO_SUR: 'Centro Sur',
	STATE_KIE_NTEM: 'Kie-Ntem',
	STATE_LITORAL: 'Litoral',
	STATE_WELE_NZAS: 'Wele-Nzas',
	STATE_ANSEBA: 'Anseba',
	STATE_DEBUB: 'Debub',
	STATE_DEBUB_KEIH_BAHRI: 'Debub-Keih-Bahri',
	STATE_GASH_BARKA: 'Gash-Barka',
	STATE_MAEKEL: 'Maekel',
	STATE_SEMIEN_KEIH_BAHRI: 'Semien-Keih-Bahri',
	STATE_HARJU: 'Harju',
	STATE_HIIU: 'Hiiu',
	STATE_IDA_VIRU: 'Ida-Viru',
	STATE_JARVA: 'Jarva',
	STATE_JOGEVA: 'Jogeva',
	STATE_LAANE: 'Laane',
	STATE_LAANE_VIRU: 'Laane-Viru',
	STATE_PARNU: 'Parnu',
	STATE_POLVA: 'Polva',
	STATE_RAPLA: 'Rapla',
	STATE_SAARE: 'Saare',
	STATE_TARTU: 'Tartu',
	STATE_VALGA: 'Valga',
	STATE_VILJANDI: 'Viljandi',
	STATE_VORU: 'Voru',
	STATE_ADDIS_ABEBA: 'Addis Abeba',
	STATE_AFAR: 'Afar',
	STATE_AMHARA: 'Amhara',
	STATE_BENISHANGUL: 'Benishangul',
	STATE_DIREDAWA: 'Diredawa',
	STATE_GAMBELLA: 'Gambella',
	STATE_HARAR: 'Harar',
	STATE_JIGJIGA: 'Jigjiga',
	STATE_MEKELE: 'Mekele',
	STATE_OROMIA: 'Oromia',
	STATE_SOMALI: 'Somali',
	STATE_SOUTHERN: 'Sul',
	STATE_TIGRAY: 'Tigray',
	STATE_FALKLAND_ISLANDS: 'Ilhas Falkland',
	STATE_SOUTH_GEORGIA: 'Geórgia do Sul',
	STATE_KLAKSVIK: 'Klaksvik',
	STATE_NOR_ARA_EYSTUROY: 'Nor ara Eysturoy',
	STATE_NOR_OY: 'Nor oy',
	STATE_SANDOY: 'Sandoy',
	STATE_STREYMOY: 'Streymoy',
	STATE_SU_UROY: 'Su uroy',
	STATE_SY_RA_EYSTUROY: 'Sy ra Eysturoy',
	STATE_TORSHAVN: 'Torshavn',
	STATE_VAGA: 'Vaga',
	STATE_CENTRAL: 'Central',
	STATE_NORTHERN: 'Norte',
	STATE_SOUTH_PACIFIC: 'Pacífico Sul',
	STATE_AHVENANMAA: 'Ahvenanmaa',
	STATE_ETELA_KARJALA: 'Etela-Karjala',
	STATE_ETELA_POHJANMAA: 'Etela-Pohjanmaa',
	STATE_ETELA_SAVO: 'Etela-Savo',
	STATE_ETELA_SUOMEN_LAANI: 'Etela-Suomen Laani',
	STATE_ITA_SUOMEN_LAANI: 'Ita-Suomen Laani',
	STATE_ITA_UUSIMAA: 'Ita-Uusimaa',
	STATE_KAINUU: 'Kainuu',
	STATE_KANTA_HAME: 'Kanta-Hame',
	STATE_KESKI_POHJANMAA: 'Keski-Pohjanmaa',
	STATE_KESKI_SUOMI: 'Keski-Suomi',
	STATE_KYMENLAAKSO: 'Kymenlaakso',
	STATE_LANSI_SUOMEN_LAANI: 'Lansi-Suomen Laani',
	STATE_LAPPI: 'Lappi',
	STATE_NORTHERN_SAVONIA: 'Savônia do Norte',
	STATE_OSTROBOTHNIA: 'Ostrobothnia',
	STATE_OULUN_LAANI: 'Oulun Laani',
	STATE_PAIJAT_HAME: 'Paijat-Hame',
	STATE_PIRKANMAA: 'Pirkanmaa',
	STATE_POHJANMAA: 'Pohjanmaa',
	STATE_POHJOIS_KARJALA: 'Pohjois-Karjala',
	STATE_POHJOIS_POHJANMAA: 'Pohjois-Pohjanmaa',
	STATE_POHJOIS_SAVO: 'Pohjois-Savo',
	STATE_SAARIJARVI: 'Saarijarvi',
	STATE_SATAKUNTA: 'Satakunta',
	STATE_SOUTHERN_SAVONIA: 'Savônia do Sul',
	STATE_TAVASTIA_PROPER: 'Tavastia Proper',
	STATE_ULEABORGS_LAN: 'Uleaborgs Lan',
	STATE_UUSIMAA: 'Uusimaa',
	STATE_VARSINAIS_SUOMI: 'Varsinais-Suomi',
	STATE_AIN: 'Ain',
	STATE_AISNE: 'Aisne',
	STATE_ALBI_LE_SEQUESTRE: 'Albi Le Sequestre',
	STATE_ALLIER: 'Allier',
	STATE_ALPES_COTE_DAZUR: 'Alpes-Cote dAzur',
	STATE_ALPES_MARITIMES: 'Alpes-Marítimo',
	STATE_ALPES_DE_HAUTE_PROVENCE: 'Alpes-de-Haute-Provence',
	STATE_ALSACE: 'Alsácia',
	STATE_AQUITAINE: 'Aquitaine',
	STATE_ARDECHE: 'Ardeche',
	STATE_ARDENNES: 'Ardennes',
	STATE_ARIEGE: 'Ariege',
	STATE_AUBE: 'Aube',
	STATE_AUDE: 'Aude',
	STATE_AUVERGNE: 'Auvergne',
	STATE_AVEYRON: 'Aveyron',
	STATE_BAS_RHIN: 'Bas-Rhin',
	STATE_BASSE_NORMANDIE: 'Basse-Normandie',
	STATE_BOUCHES_DU_RHONE: 'Bouches-du-Rhone',
	STATE_BOURGOGNE: 'Bourgogne',
	STATE_BRETAGNE: 'Bretagne',
	STATE_BRITTANY: 'Brittany',
	STATE_BURGUNDY: 'Burgundy',
	STATE_CALVADOS: 'Calvados',
	STATE_CANTAL: 'Cantal',
	STATE_CEDEX: 'Cedex',
	STATE_CHARENTE: 'Charente',
	STATE_CHARENTE_MARITIME: 'Charente-Maritime',
	STATE_CHER: 'Cher',
	STATE_CORREZE: 'Correze',
	STATE_CORSE_DU_SUD: 'Corse-du-Sud',
	STATE_COTE_D__OR: "Cote-d'Or",
	STATE_COTES_D__ARMOR: "Cotes-d'Armor",
	STATE_CREUSE: 'Creuse',
	STATE_CROLLES: 'Crolles',
	STATE_DEUX_SEVRES: 'Deux-Sevres',
	STATE_DORDOGNE: 'Dordogne',
	STATE_DOUBS: 'Doubs',
	STATE_DROME: 'Drome',
	STATE_ESSONNE: 'Essonne',
	STATE_EURE: 'Eure',
	STATE_EURE_ET_LOIR: 'Eure-et-Loir',
	STATE_FEUCHEROLLES: 'Feucherolles',
	STATE_FINISTERE: 'Finistere',
	STATE_FRANCHE_COMTE: 'Franche-Comte',
	STATE_GARD: 'Gard',
	STATE_GERS: 'Gers',
	STATE_GIRONDE: 'Gironde',
	STATE_HAUT_RHIN: 'Haut-Rhin',
	STATE_HAUTE_CORSE: 'Haute-Corse',
	STATE_HAUTE_GARONNE: 'Haute-Garonne',
	STATE_HAUTE_LOIRE: 'Haute-Loire',
	STATE_HAUTE_MARNE: 'Haute-Marne',
	STATE_HAUTE_SAONE: 'Haute-Saone',
	STATE_HAUTE_SAVOIE: 'Haute-Savoie',
	STATE_HAUTE_VIENNE: 'Haute-Vienne',
	STATE_HAUTES_ALPES: 'Hautes-Alpes',
	STATE_HAUTES_PYRENEES: 'Hautes-Pyrenees',
	STATE_HAUTS_DE_SEINE: 'Hauts-de-Seine',
	STATE_HERAULT: 'Herault',
	STATE_ILE_DE_FRANCE: 'Ile-de-France',
	STATE_ILLE_ET_VILAINE: 'Ille-et-Vilaine',
	STATE_INDRE: 'Indre',
	STATE_INDRE_ET_LOIRE: 'Indre-et-Loire',
	STATE_ISERE: 'Isere',
	STATE_JURA: 'Jura',
	STATE_KLAGENFURT: 'Klagenfurt',
	STATE_LANDES: 'Landes',
	STATE_LANGUEDOC_ROUSSILLON: 'Languedoc-Roussillon',
	STATE_LARCAY: 'Larcay',
	STATE_LE_CASTELLET: 'Le Castellet',
	STATE_LE_CREUSOT: 'Le Creusot',
	STATE_LIMOUSIN: 'Limousin',
	STATE_LOIR_ET_CHER: 'Loir-et-Cher',
	STATE_LOIRE: 'Loire',
	STATE_LOIRE_ATLANTIQUE: 'Loire-Atlantique',
	STATE_LOIRET: 'Loiret',
	STATE_LORRAINE: 'Lorraine',
	STATE_LOT: 'Lot',
	STATE_LOT_ET_GARONNE: 'Lot-et-Garonne',
	STATE_LOWER_NORMANDY: 'Baixa Normandia',
	STATE_LOZERE: 'Lozere',
	STATE_MAINE_ET_LOIRE: 'Maine-et-Loire',
	STATE_MANCHE: 'Manche',
	STATE_MARNE: 'Marne',
	STATE_MAYENNE: 'Mayenne',
	STATE_MEURTHE_ET_MOSELLE: 'Meurthe-et-Moselle',
	STATE_MEUSE: 'Meuse',
	STATE_MIDI_PYRENEES: 'Midi-Pyrenees',
	STATE_MORBIHAN: 'Morbihan',
	STATE_MOSELLE: 'Moselle',
	STATE_NIEVRE: 'Nievre',
	STATE_NORD_PAS_DE_CALAIS: 'Nord-Pas-de-Calais',
	STATE_OISE: 'Oise',
	STATE_ORNE: 'Orne',
	STATE_PARIS: 'Paris',
	STATE_PAS_DE_CALAIS: 'Pas-de-Calais',
	STATE_PAYS_DE_LA_LOIRE: 'Pays de la Loire',
	STATE_PICARDY: 'Picardy',
	STATE_PUY_DE_DOME: 'Puy-de-Dome',
	STATE_PYRENEES_ATLANTIQUES: 'Pyrenees-Atlantiques',
	STATE_PYRENEES_ORIENTALES: 'Pyrenees-Orientales',
	STATE_QUELMES: 'Quelmes',
	STATE_RHONE: 'Rhone',
	STATE_RHONE_ALPES: 'Rhone-Alpes',
	STATE_SAINT_OUEN: 'Saint Ouen',
	STATE_SAINT_VIATRE: 'Saint Viatre',
	STATE_SAONE_ET_LOIRE: 'Saone-et-Loire',
	STATE_SARTHE: 'Sarthe',
	STATE_SAVOIE: 'Savoie',
	STATE_SEINE_MARITIME: 'Seine-Maritime',
	STATE_SEINE_SAINT_DENIS: 'Seine-Saint-Denis',
	STATE_SEINE_ET_MARNE: 'Seine-et-Marne',
	STATE_SOMME: 'Somme',
	STATE_SOPHIA_ANTIPOLIS: 'Sophia Antipolis',
	STATE_SOUVANS: 'Souvans',
	STATE_TARN: 'Tarn',
	STATE_TARN_ET_GARONNE: 'Tarn-et-Garonne',
	STATE_TERRITOIRE_DE_BELFORT: 'Territoire de Belfort',
	STATE_TREIGNAC: 'Treignac',
	STATE_UPPER_NORMANDY: 'Alta Normandia',
	STATE_VAL_D__OISE: "Val-d'Oise",
	STATE_VAL_DE_MARNE: 'Val-de-Marne',
	STATE_VAR: 'Var',
	STATE_VAUCLUSE: 'Vaucluse',
	STATE_VELLISE: 'Vellise',
	STATE_VENDEE: 'Vendee',
	STATE_VIENNE: 'Vienne',
	STATE_VOSGES: 'Vosges',
	STATE_YONNE: 'Yonne',
	STATE_YVELINES: 'Yvelines',
	STATE_CAYENNE: 'Cayenne',
	STATE_SAINT_LAURENT_DU_MARONI: 'Saint-Laurent-du-Maroni',
	STATE_ILES_DU_VENT: 'Iles du Vent',
	STATE_ILES_SOUS_LE_VENT: 'Iles sous le Vent',
	STATE_MARQUESAS: 'Marquesas',
	STATE_TUAMOTU: 'Tuamotu',
	STATE_TUBUAI: 'Tubuai',
	STATE_AMSTERDAM: 'Amsterdã',
	STATE_CROZET_ISLANDS: 'Ilhas Crozet',
	STATE_KERGUELEN: 'Kerguelen',
	STATE_ESTUAIRE: 'Estuaire',
	STATE_HAUT_OGOOUE: 'Haut-Ogooue',
	STATE_MOYEN_OGOOUE: 'Moyen-Ogooue',
	STATE_NGOUNIE: 'Ngounie',
	STATE_NYANGA: 'Nyanga',
	STATE_OGOOUE_IVINDO: 'Ogooue-Ivindo',
	STATE_OGOOUE_LOLO: 'Ogooue-Lolo',
	STATE_OGOOUE_MARITIME: 'Ogooue-Maritime',
	STATE_WOLEU_NTEM: 'Woleu-Ntem',
	STATE_BANJUL: 'Banjul',
	STATE_BASSE: 'Basse',
	STATE_BRIKAMA: 'Brikama',
	STATE_JANJANBUREH: 'Janjanbureh',
	STATE_KANIFING: 'Kanifing',
	STATE_KEREWAN: 'Kerewan',
	STATE_KUNTAUR: 'Kuntaur',
	STATE_MANSAKONKO: 'Mansakonko',
	STATE_ABHASIA: 'Abhasia',
	STATE_AJARIA: 'Ajaria',
	STATE_GURIA: 'Guria',
	STATE_IMERETI: 'Imereti',
	STATE_KAHETI: 'Kaheti',
	STATE_KVEMO_KARTLI: 'Kvemo Kartli',
	STATE_MCHETA_MTIANETI: 'Mcheta-Mtianeti',
	STATE_RACHA: 'Racha',
	STATE_SAMAGRELO_ZEMO_SVANETI: 'Samagrelo-Zemo Svaneti',
	STATE_SAMCHE_ZHAVAHETI: 'Samche-Zhavaheti',
	STATE_SHIDA_KARTLI: 'Shida Kartli',
	STATE_TBILISI: 'Tbilisi',
	STATE_BADEN_WURTTEMBERG: 'Baden-Wurttemberg',
	STATE_BAVARIA: 'Bavária',
	STATE_BAYERN: 'Bayern',
	STATE_BEILSTEIN_WURTT: 'Beilstein Wurtt',
	STATE_BERLIN: 'Berlim',
	STATE_BRANDENBURG: 'Brandenburgo',
	STATE_BREMEN: 'Bremen',
	STATE_DREISBACH: 'Dreisbach',
	STATE_FREISTAAT_BAYERN: 'Freistaat Bayern',
	STATE_HAMBURG: 'Hamburgo',
	STATE_HANNOVER: 'Hanôver',
	STATE_HEROLDSTATT: 'Heroldstatt',
	STATE_HESSEN: 'Hessen',
	STATE_KORTENBERG: 'Kortenberg',
	STATE_LAASDORF: 'Laasdorf',
	STATE_LAND_BADEN_WURTTEMBERG: 'Land Baden-Wurttemberg',
	STATE_LAND_BAYERN: 'Land Bayern',
	STATE_LAND_BRANDENBURG: 'Land Brandenburg',
	STATE_LAND_HESSEN: 'Land Hessen',
	STATE_LAND_MECKLENBURG_VORPOMMERN: 'Land Mecklenburg-Vorpommern',
	STATE_LAND_NORDRHEIN_WESTFALEN: 'Land Nordrhein-Westfalen',
	STATE_LAND_RHEINLAND_PFALZ: 'Land Rheinland-Pfalz',
	STATE_LAND_SACHSEN: 'Land Sachsen',
	STATE_LAND_SACHSEN_ANHALT: 'Land Sachsen-Anhalt',
	STATE_LAND_THURINGEN: 'Land Thuringen',
	STATE_LOWER_SAXONY: 'Baixa Saxônia',
	STATE_MECKLENBURG_VORPOMMERN: 'Mecklenburg-Vorpommern',
	STATE_MULFINGEN: 'Mulfingen',
	STATE_MUNICH: 'Munique',
	STATE_NEUBEUERN: 'Neubeuern',
	STATE_NIEDERSACHSEN: 'Niedersachsen',
	STATE_NOORD_HOLLAND: 'Holanda do Norte',
	STATE_NORDRHEIN_WESTFALEN: 'Land Nordrhein-Westfalen',
	STATE_NORTH_RHINE_WESTPHALIA: 'North Rhine-Westphalia',
	STATE_OSTERODE: 'Osterode',
	STATE_RHEINLAND_PFALZ: 'Land Rheinland-Pfalz',
	STATE_RHINELAND_PALATINATE: 'Rhineland-Palatinate',
	STATE_SAARLAND: 'Saarland',
	STATE_SACHSEN: 'Sachsen',
	STATE_SACHSEN_ANHALT: 'Sachsen-Anhalt',
	STATE_SAXONY: 'Saxônia',
	STATE_THURINGIA: 'Thuringia',
	STATE_WEBLING: 'Webling',
	STATE_WEINSTRABE: 'Weinstrabe',
	STATE_SCHLOBBORN: 'Schlobborn',
	STATE_ASHANTI: 'Ashanti',
	STATE_BRONG_AHAFO: 'Brong-Ahafo',
	STATE_GREATER_ACCRA: 'Área Metropolitana de Acra',
	STATE_UPPER_EAST: 'Upper East',
	STATE_UPPER_WEST: 'Upper West',
	STATE_VOLTA: 'Volta',
	STATE_GIBRALTAR: 'Gibraltar',
	STATE_ACHARNES: 'Acharnes',
	STATE_AHAIA: 'Ahaia',
	STATE_AITOLIA_KAI_AKARNANIA: 'Aitolia kai Akarnania',
	STATE_ARGOLIS: 'Argólida',
	STATE_ARKADIA: 'Arkadia',
	STATE_ARTA: 'Arta',
	STATE_ATTICA: 'Attica',
	STATE_ATTIKI: 'Attiki',
	STATE_AYION_OROS: 'Ayion Oros',
	STATE_CRETE: 'Creta',
	STATE_DODEKANISOS: 'Dodekanisos',
	STATE_DRAMA: 'Drama',
	STATE_EVIA: 'Evia',
	STATE_EVRITANIA: 'Euritânia',
	STATE_EVROS: 'Evros',
	STATE_EVVOIA: 'Evvoia',
	STATE_FLORINA: 'Florina',
	STATE_FOKIS: 'Fokis',
	STATE_FTHIOTIS: 'Fthiotis',
	STATE_GREVENA: 'Grevena',
	STATE_HALANDRI: 'Halandri',
	STATE_HALKIDIKI: 'Halkidiki',
	STATE_HANIA: 'Hania',
	STATE_HERAKLION: 'Heraklion',
	STATE_HIOS: 'Hios',
	STATE_ILIA: 'Ilia',
	STATE_IMATHIA: 'Imathia',
	STATE_IOANNINA: 'Ioannina',
	STATE_IRAKLION: 'Iraklion',
	STATE_KARDITSA: 'Karditsa',
	STATE_KASTORIA: 'Kastoria',
	STATE_KAVALA: 'Kavala',
	STATE_KEFALLINIA: 'Kefallinia',
	STATE_KERKIRA: 'Kerkira',
	STATE_KIKLADES: 'Kiklades',
	STATE_KILKIS: 'Kilkis',
	STATE_KORINTHIA: 'Coríntia',
	STATE_KOZANI: 'Kozani',
	STATE_LAKONIA: 'Lakonia',
	STATE_LARISA: 'Larisa',
	STATE_LASITHI: 'Lasithi',
	STATE_LESVOS: 'Lesvos',
	STATE_LEVKAS: 'Levkas',
	STATE_MAGNISIA: 'Magnisia',
	STATE_MESSINIA: 'Messinia',
	STATE_NOMOS_ATTIKIS: 'Nomos Attikis',
	STATE_NOMOS_ZAKYNTHOU: 'Nomos Zakynthou',
	STATE_PELLA: 'Pella',
	STATE_PIERIA: 'Pieria',
	STATE_PIRAIOS: 'Piraios',
	STATE_PREVEZA: 'Preveza',
	STATE_RETHIMNI: 'Rethimni',
	STATE_RODOPI: 'Rodopi',
	STATE_SAMOS: 'Samos',
	STATE_SERRAI: 'Serrai',
	STATE_THESPROTIA: 'Thesprotia',
	STATE_THESSALONIKI: 'Thessaloniki',
	STATE_TRIKALA: 'Trikala',
	STATE_VOIOTIA: 'Voiotia',
	STATE_WEST_GREECE: 'Grécia Ocidental',
	STATE_XANTHI: 'Xanthi',
	STATE_ZAKINTHOS: 'Zakinthos',
	STATE_AASIAAT: 'Aasiaat',
	STATE_AMMASSALIK: 'Ammassalik',
	STATE_ILLOQQORTOORMIUT: 'Illoqqortoormiut',
	STATE_ILULISSAT: 'Ilulissat',
	STATE_IVITTUUT: 'Ivittuut',
	STATE_KANGAATSIAQ: 'Kangaatsiaq',
	STATE_MANIITSOQ: 'Maniitsoq',
	STATE_NANORTALIK: 'Nanortalik',
	STATE_NARSAQ: 'Narsaq',
	STATE_NUUK: 'Nuuk',
	STATE_PAAMIUT: 'Paamiut',
	STATE_QAANAAQ: 'Qaanaaq',
	STATE_QAQORTOQ: 'Qaqortoq',
	STATE_QASIGIANNGUIT: 'Qasigiannguit',
	STATE_QEQERTARSUAQ: 'Qeqertarsuaq',
	STATE_SISIMIUT: 'Sisimiut',
	STATE_UDENFOR_KOMMUNAL_INDDELING: 'Municípios externos',
	STATE_UPERNAVIK: 'Upernavik',
	STATE_UUMMANNAQ: 'Uummannaq',
	STATE_CARRIACOU_PETITE_MARTINIQUE: 'Carriacou-Petite Martinique',
	STATE_SAINT_DAVIDS: 'Saint Davids',
	STATE_SAINT_GEORGE__S: "Saint George's",
	STATE_BASSE_TERRE: 'Basse-Terre',
	STATE_GRANDE_TERRE: 'Grande-Terre',
	STATE_ILES_DES_SAINTES: 'Iles des Saintes',
	STATE_LA_DESIRADE: 'La Desirade',
	STATE_MARIE_GALANTE: 'Marie-Galante',
	STATE_SAINT_BARTHELEMY: 'São Bartolomeu',
	STATE_SAINT_MARTIN: 'Ilha de São Martinho',
	STATE_AGANA_HEIGHTS: 'Agana Heights',
	STATE_AGAT: 'Agat',
	STATE_BARRIGADA: 'Barrigada',
	STATE_CHALAN_PAGO_ORDOT: 'Chalan-Pago-Ordot',
	STATE_DEDEDO: 'Dededo',
	STATE_HAGATNA: 'Hagatna',
	STATE_INARAJAN: 'Inarajan',
	STATE_MANGILAO: 'Mangilao',
	STATE_MERIZO: 'Merizo',
	STATE_MONGMONG_TOTO_MAITE: 'Mongmong-Toto-Maite',
	STATE_SANTA_RITA: 'Santa Rita',
	STATE_SINAJANA: 'Sinajana',
	STATE_TALOFOFO: 'Talofofo',
	STATE_TAMUNING: 'Tamuning',
	STATE_YIGO: 'Yigo',
	STATE_YONA: 'Yona',
	STATE_ALTA_VERAPAZ: 'Alta Verapaz',
	STATE_BAJA_VERAPAZ: 'Baixa Verapaz',
	STATE_CHIMALTENANGO: 'Chimaltenango',
	STATE_CHIQUIMULA: 'Chiquimula',
	STATE_EL_PROGRESO: 'El Progreso',
	STATE_ESCUINTLA: 'Escuintla',
	STATE_GUATEMALA: 'Guatemala',
	STATE_HUEHUETENANGO: 'Huehuetenango',
	STATE_IZABAL: 'Izabal',
	STATE_JALAPA: 'Jalapa',
	STATE_JUTIAPA: 'Jutiapa',
	STATE_PETEN: 'Peten',
	STATE_QUEZALTENANGO: 'Quezaltenango',
	STATE_QUICHE: 'Quiche',
	STATE_RETALHULEU: 'Retalhuleu',
	STATE_SACATEPEQUEZ: 'Sacatepequez',
	STATE_SAN_MARCOS: 'San Marcos',
	STATE_SANTA_ROSA: 'Santa Rosa',
	STATE_SOLOLA: 'Solola',
	STATE_SUCHITEPEQUEZ: 'Suchitepequez',
	STATE_TOTONICAPAN: 'Totonicapan',
	STATE_ZACAPA: 'Zacapa',
	STATE_ALDERNEY: 'Alderney',
	STATE_CASTEL: 'Castel',
	STATE_FOREST: 'Forest',
	STATE_SAINT_PETER_PORT: 'Saint Peter Port',
	STATE_SAINT_PIERRE_DU_BOIS: 'Saint Pierre du Bois',
	STATE_SAINT_SAMPSON: 'Saint Sampson',
	STATE_SAINT_SAVIOUR: 'Saint Saviour',
	STATE_SARK: 'Sark',
	STATE_TORTEVAL: 'Torteval',
	STATE_VALE: 'Vale',
	STATE_BEYLA: 'Beyla',
	STATE_BOFFA: 'Boffa',
	STATE_BOKE: 'Boke',
	STATE_CONAKRY: 'Conakry',
	STATE_COYAH: 'Coyah',
	STATE_DABOLA: 'Dabola',
	STATE_DALABA: 'Dalaba',
	STATE_DINGUIRAYE: 'Dinguiraye',
	STATE_FARANAH: 'Faranah',
	STATE_FORECARIAH: 'Forecariah',
	STATE_FRIA: 'Fria',
	STATE_GAOUAL: 'Gaoual',
	STATE_GUECKEDOU: 'Gueckedou',
	STATE_KANKAN: 'Kankan',
	STATE_KEROUANE: 'Kerouane',
	STATE_KINDIA: 'Kindia',
	STATE_KISSIDOUGOU: 'Kissidougou',
	STATE_KOUBIA: 'Koubia',
	STATE_KOUNDARA: 'Koundara',
	STATE_KOUROUSSA: 'Kouroussa',
	STATE_LABE: 'Labe',
	STATE_LOLA: 'Lola',
	STATE_MACENTA: 'Macenta',
	STATE_MALI: 'Mali',
	STATE_MAMOU: 'Mamou',
	STATE_MANDIANA: 'Mandiana',
	STATE_NZEREKORE: 'Nzerekore',
	STATE_PITA: 'Pita',
	STATE_SIGUIRI: 'Siguiri',
	STATE_TELIMELE: 'Telimele',
	STATE_TOUGUE: 'Tougue',
	STATE_YOMOU: 'Yomou',
	STATE_BAFATA: 'Bafata',
	STATE_BISSAU: 'Bissau',
	STATE_BOLAMA: 'Bolama',
	STATE_CACHEU: 'Cacheu',
	STATE_GABU: 'Gabu',
	STATE_OIO: 'Oio',
	STATE_QUINARA: 'Quinara',
	STATE_TOMBALI: 'Tombali',
	STATE_BARIMA_WAINI: 'Barima-Waini',
	STATE_CUYUNI_MAZARUNI: 'Cuyuni-Mazaruni',
	STATE_DEMERARA_MAHAICA: 'Demerara-Mahaica',
	STATE_EAST_BERBICE_CORENTYNE: 'East Berbice-Corentyne',
	STATE_ESSEQUIBO_ISLANDS_WEST_DEMERAR: 'Ilhas Essequibo-Demerara Ocidental',
	STATE_MAHAICA_BERBICE: 'Mahaica-Berbice',
	STATE_POMEROON_SUPENAAM: 'Pomeroon-Supenaam',
	STATE_POTARO_SIPARUNI: 'Potaro-Siparuni',
	STATE_UPPER_DEMERARA_BERBICE: 'Alto Demerara-Berbice',
	STATE_UPPER_TAKUTU_UPPER_ESSEQUIBO: 'Alto Takutu-Alto Essequibo',
	STATE_ARTIBONITE: 'Artibonite',
	STATE_GRAND__ANSE: "Grand'Anse",
	STATE_NORD_EST: 'Nordeste',
	STATE_NORD_OUEST: 'Noroeste',
	STATE_SUD_EST: 'Sudeste',
	STATE_HEARD_AND_MCDONALD_ISLANDS: 'Ilha Heard e Ilhas McDonald',
	STATE_ATLANTIDA: 'Atlântida',
	STATE_CHOLUTECA: 'Choluteca',
	STATE_COLON: 'Colon',
	STATE_COMAYAGUA: 'Comayagua',
	STATE_COPAN: 'Copan',
	STATE_CORTES: 'Cortes',
	STATE_DISTRITO_CENTRAL: 'Distrito Central',
	STATE_EL_PARAISO: 'El Paraíso',
	STATE_FRANCISCO_MORAZAN: 'Francisco Morazan',
	STATE_GRACIAS_A_DIOS: 'Gracias a Dios',
	STATE_INTIBUCA: 'Intibuca',
	STATE_ISLAS_DE_LA_BAHIA: 'Ilhas da Bahia',
	STATE_LEMPIRA: 'Lempira',
	STATE_OCOTEPEQUE: 'Ocotepeque',
	STATE_OLANCHO: 'Olancho',
	STATE_SANTA_BARBARA: 'Santa Bárbara',
	STATE_VALLE: 'Valle',
	STATE_YORO: 'Yoro',
	STATE_HONG_KONG: 'Hong Kong',
	STATE_BACS_KISKUN: 'Bacs-Kiskun',
	STATE_BARANYA: 'Baranya',
	STATE_BEKES: 'Bekes',
	STATE_BORSOD_ABAUJ_ZEMPLEN: 'Borsod-Abauj-Zemplen',
	STATE_BUDAPEST: 'Budapeste',
	STATE_CSONGRAD: 'Csongrad',
	STATE_FEJER: 'Fejer',
	STATE_GYOR_MOSON_SOPRON: 'Gyor-Moson-Sopron',
	STATE_CORK: 'Cork',
	STATE_HAJDU_BIHAR: 'Hajdu-Bihar',
	STATE_HEVES: 'Heves',
	STATE_JASZ_NAGYKUN_SZOLNOK: 'Jasz-Nagykun-Szolnok',
	STATE_KOMAROM_ESZTERGOM: 'Komarom-Esztergom',
	STATE_NOGRAD: 'Nograd',
	STATE_PEST: 'Pest',
	STATE_SOMOGY: 'Somogy',
	STATE_SZABOLCS_SZATMAR_BEREG: 'Szabolcs-Szatmar-Bereg',
	STATE_TOLNA: 'Tolna',
	STATE_VAS: 'Vas',
	STATE_VESZPREM: 'Veszprem',
	STATE_ZALA: 'Zala',
	STATE_AUSTURLAND: 'Austurland',
	STATE_GULLBRINGUSYSLA: 'Gullbringusysla',
	STATE_HOFU_BORGARSVA_I: 'Hofu borgarsva i',
	STATE_NOR_URLAND_EYSTRA: 'Nor urland Eystra',
	STATE_NOR_URLAND_VESTRA: 'Nor urland Vestra',
	STATE_SU_URLAND: 'Su urland',
	STATE_SU_URNES: 'Su urnes',
	STATE_VESTFIR_IR: 'Vestfir ir',
	STATE_VESTURLAND: 'Vesturland',
	STATE_ACEH: 'Aceh',
	STATE_BALI: 'Bali',
	STATE_BANGKA_BELITUNG: 'Bangka-Belitung',
	STATE_BANTEN: 'Banten',
	STATE_BENGKULU: 'Bengkulu',
	STATE_GANDARIA: 'Gandaria',
	STATE_GORONTALO: 'Gorontalo',
	STATE_JAKARTA: 'Jacarta',
	STATE_JAMBI: 'Jambi',
	STATE_JAWA_BARAT: 'Java Ocidental',
	STATE_JAWA_TENGAH: 'Java Central',
	STATE_JAWA_TIMUR: 'Java Oriental',
	STATE_KALIMANTAN_BARAT: 'Kalimantan Ocidental',
	STATE_KALIMANTAN_SELATAN: 'Kalimantan do Sul',
	STATE_KALIMANTAN_TENGAH: 'Kalimantan Central',
	STATE_KALIMANTAN_TIMUR: 'Kalimantan Oriental',
	STATE_KENDAL: 'Kendal',
	STATE_LAMPUNG: 'Lampung',
	STATE_MALUKU: 'Maluku',
	STATE_MALUKU_UTARA: 'Maluku Utara',
	STATE_NUSA_TENGGARA_BARAT: 'Nusa Tenggara Ocidental',
	STATE_NUSA_TENGGARA_TIMUR: 'Nusa Tenggara Oriental',
	STATE_PAPUA: 'Papua',
	STATE_RIAU: 'Riau',
	STATE_RIAU_KEPULAUAN: 'Riau Kepulauan',
	STATE_SOLO: 'Solo',
	STATE_SULAWESI_SELATAN: 'Celebes do Sul',
	STATE_SULAWESI_TENGAH: 'Celebes Central',
	STATE_SULAWESI_TENGGARA: 'Celebes do Sudeste',
	STATE_SULAWESI_UTARA: 'Celebes do Norte',
	STATE_SUMATERA_BARAT: 'Sumatra Ocidental',
	STATE_SUMATERA_SELATAN: 'Sumatra do Sul',
	STATE_SUMATERA_UTARA: 'Sumatra do Norte',
	STATE_YOGYAKARTA: 'Yogyakarta',
	STATE_ARDABIL: 'Ardabil',
	STATE_AZARBAYJAN_E_BAKHTARI: 'Azarbayjan-e Bakhtari',
	STATE_AZARBAYJAN_E_KHAVARI: 'Azarbayjan-e Khavari',
	STATE_BUSHEHR: 'Bushehr',
	STATE_CHAHAR_MAHAL_E_BAKHTIARI: 'Chahar Mahal-e Bakhtiari',
	STATE_ESFAHAN: 'Esfahan',
	STATE_FARS: 'Fars',
	STATE_GILAN: 'Gilan',
	STATE_GOLESTAN: 'Golestan',
	STATE_HAMADAN: 'Hamadan',
	STATE_HORMOZGAN: 'Hormozgan',
	STATE_ILAM: 'Ilam',
	STATE_KERMAN: 'Kerman',
	STATE_KERMANSHAH: 'Kermanshah',
	STATE_KHORASAN: 'Coração',
	STATE_KHUZESTAN: 'Cuzistão',
	STATE_KOHGILUYEH_E_BOYERAHMAD: 'Kohgiluyeh-e Boyerahmad',
	STATE_KORDESTAN: 'Curdistãon',
	STATE_LORESTAN: 'Lorestão',
	STATE_MARKAZI: 'Markazi',
	STATE_MAZANDARAN: 'Mazandaran',
	STATE_OSTAN_E_ESFAHAN: 'Ostan-e Esfahan',
	STATE_QAZVIN: 'Qazvin',
	STATE_QOM: 'Qom',
	STATE_SEMNAN: 'Semnan',
	STATE_SISTAN_E_BALUCHESTAN: 'Sistão-Baluchistão',
	STATE_TEHRAN: 'Teerã',
	STATE_YAZD: 'Yazd',
	STATE_ZANJAN: 'Zanjan',
	STATE_BABIL: 'Babil',
	STATE_BAGHDAD: 'Bagdá',
	STATE_DAHUK: 'Dahuk',
	STATE_DHI_QAR: 'Dhi Qar',
	STATE_DIYALA: 'Diyala',
	STATE_ERBIL: 'Erbil',
	STATE_IRBIL: 'Irbil',
	STATE_KARBALA: 'Karbala',
	STATE_KURDISTAN: 'Curdistão',
	STATE_MAYSAN: 'Maysan',
	STATE_NINAWA: 'Ninawa',
	STATE_SALAH_AD_DIN: 'Salah-ad-Din',
	STATE_WASIT: 'Wasit',
	STATE_AL_ANBAR: 'al-Anbar',
	STATE_AL_BASRAH: 'al-Basrah',
	STATE_AL_MUTHANNA: 'al-Muthanna',
	STATE_AL_QADISIYAH: 'al-Qadisiyah',
	STATE_AN_NAJAF: 'an-Najaf',
	STATE_AS_SULAYMANIYAH: 'as-Sulaymaniyah',
	STATE_AT_TA__MIM: "at-Ta'mim",
	STATE_ARMAGH: 'Armagh',
	STATE_CARLOW: 'Carlow',
	STATE_CAVAN: 'Cavan',
	STATE_CLARE: 'Clare',
	STATE_DONEGAL: 'Donegal',
	STATE_DUBLIN: 'Dublin',
	STATE_GALWAY: 'Galway',
	STATE_KERRY: 'Kerry',
	STATE_KILDARE: 'Kildare',
	STATE_KILKENNY: 'Kilkenny',
	STATE_LAOIS: 'Laois',
	STATE_LEINSTER: 'Leinster',
	STATE_LEITRIM: 'Leitrim',
	STATE_LIMERICK: 'Limerick',
	STATE_LOCH_GARMAN: 'Loch Garman',
	STATE_LONGFORD: 'Longford',
	STATE_LOUTH: 'Louth',
	STATE_MAYO: 'Mayo',
	STATE_MEATH: 'Meath',
	STATE_MONAGHAN: 'Monaghan',
	STATE_OFFALY: 'Offaly',
	STATE_ROSCOMMON: 'Roscommon',
	STATE_SLIGO: 'Sligo',
	STATE_TIPPERARY_NORTH_RIDING: 'Condado de Tipperary Norte',
	STATE_TIPPERARY_SOUTH_RIDING: 'Condado de Tipperary Sul',
	STATE_ULSTER: 'Ulster',
	STATE_WATERFORD: 'Waterford',
	STATE_WESTMEATH: 'Westmeath',
	STATE_WEXFORD: 'Wexford',
	STATE_WICKLOW: 'Wicklow',
	STATE_BEIT_HANANIA: 'Beit Hanania',
	STATE_BEN_GURION_AIRPORT: 'Aeroporto Ben Gurion',
	STATE_BETHLEHEM: 'Belém',
	STATE_CAESAREA: 'Caesarea',
	STATE_GAZA: 'Gaza',
	STATE_HADARON: 'Hadaron',
	STATE_HAIFA_DISTRICT: 'Distrito de Haifa',
	STATE_HAMERKAZ: 'Hamerkaz',
	STATE_HAZAFON: 'Hazafon',
	STATE_HEBRON: 'Hebron',
	STATE_JAFFA: 'Jaffa',
	STATE_JERUSALEM: 'Jerusalém',
	STATE_KHEFA: 'Khefa',
	STATE_KIRYAT_YAM: 'Kiryat Yam',
	STATE_LOWER_GALILEE: 'Baixa Galileia',
	STATE_QALQILYA: 'Qalqilya',
	STATE_TALME_ELAZAR: 'Talme Elazar',
	STATE_TEL_AVIV: 'Tel Aviv',
	STATE_TSAFON: 'Tsafon',
	STATE_UMM_EL_FAHEM: 'Umm El Fahem',
	STATE_YERUSHALAYIM: 'Yerushalayim',
	STATE_ABRUZZI: 'Abruzzi',
	STATE_ABRUZZO: 'Abruzzo',
	STATE_AGRIGENTO: 'Agrigento',
	STATE_ALESSANDRIA: 'Alexandria',
	STATE_ANCONA: 'Ancona',
	STATE_AREZZO: 'Arezzo',
	STATE_ASCOLI_PICENO: 'Ascoli Piceno',
	STATE_ASTI: 'Asti',
	STATE_AVELLINO: 'Avellino',
	STATE_BARI: 'Bari',
	STATE_BASILICATA: 'Basilicata',
	STATE_BELLUNO: 'Belluno',
	STATE_BENEVENTO: 'Benevento',
	STATE_BERGAMO: 'Bérgamo',
	STATE_BIELLA: 'Biella',
	STATE_BOLOGNA: 'Bolonha',
	STATE_BOLZANO: 'Bolzano',
	STATE_BRESCIA: 'Brescia',
	STATE_BRINDISI: 'Brindisi',
	STATE_CALABRIA: 'Calábria',
	STATE_CAMPANIA: 'Campania',
	STATE_CARTOCETO: 'Cartoceto',
	STATE_CASERTA: 'Caserta',
	STATE_CATANIA: 'Catânia',
	STATE_CHIETI: 'Chieti',
	STATE_COMO: 'Como',
	STATE_COSENZA: 'Cosenza',
	STATE_CREMONA: 'Cremona',
	STATE_CUNEO: 'Cuneo',
	STATE_EMILIA_ROMAGNA: 'Emília Romanha',
	STATE_FERRARA: 'Ferrara',
	STATE_FIRENZE: 'Florença',
	STATE_FLORENCE: 'Florença',
	STATE_FORLI_CESENA: 'Forli-Cesena',
	STATE_FRIULI_VENEZIA_GIULIA: 'Friul-Veneza Júlia',
	STATE_FROSINONE: 'Frosinone',
	STATE_GENOA: 'Gênova',
	STATE_GORIZIA: 'Gorizia',
	STATE_L__AQUILA: "L'Aquila",
	STATE_LAZIO: 'Lazio',
	STATE_LECCE: 'Lecce',
	STATE_LECCO: 'Lecco',
	STATE_LECCO_PROVINCE: 'Província de Lecco',
	STATE_LIGURIA: 'Ligúria',
	STATE_LODI: 'Lodi',
	STATE_LOMBARDIA: 'Lombardia',
	STATE_LOMBARDY: 'Lombardia',
	STATE_MACERATA: 'Macerata',
	STATE_MANTOVA: 'Mantova',
	STATE_MARCHE: 'Marche',
	STATE_MESSINA: 'Messina',
	STATE_MILAN: 'Milão',
	STATE_MODENA: 'Módena',
	STATE_MOLISE: 'Molise',
	STATE_MOLTENO: 'Molteno',
	STATE_MONTENEGRO: 'Montenegro',
	STATE_MONZA_AND_BRIANZA: 'Monza e Brianza',
	STATE_NAPLES: 'Nápoles',
	STATE_NOVARA: 'Novara',
	STATE_PADOVA: 'Pádova',
	STATE_PARMA: 'Parma',
	STATE_PAVIA: 'Pavia',
	STATE_RUMPHI: 'Rumphi',
	STATE_PERUGIA: 'Perúgia',
	STATE_PESARO_URBINO: 'Pesaro-Urbino',
	STATE_PIACENZA: 'Piacenza',
	STATE_PIEDMONT: 'Piedmont',
	STATE_PIEMONTE: 'Piemonte',
	STATE_PISA: 'Pisa',
	STATE_PORDENONE: 'Pordenone',
	STATE_POTENZA: 'Potenza',
	STATE_PUGLIA: 'Apúlia',
	STATE_REGGIO_EMILIA: 'Reggio Emilia',
	STATE_RIMINI: 'Rimini',
	STATE_ROMA: 'Roma',
	STATE_SALERNO: 'Salerno',
	STATE_SARDEGNA: 'Sardenha',
	STATE_SASSARI: 'Sassari',
	STATE_SAVONA: 'Savona',
	STATE_SICILIA: 'Sicíilia',
	STATE_SIENA: 'Siena',
	STATE_SONDRIO: 'Sondrio',
	STATE_SOUTH_TYROL: 'Tirol do Sul',
	STATE_TARANTO: 'Taranto',
	STATE_TERAMO: 'Teramo',
	STATE_TORINO: 'Torino',
	STATE_TOSCANA: 'Toscana',
	STATE_TRAPANI: 'Trapani',
	STATE_TRENTINO_ALTO_ADIGE: 'Trentino-Alto Ádige',
	STATE_TRENTO: 'Trento',
	STATE_TREVISO: 'Treviso',
	STATE_UDINE: 'Udine',
	STATE_UMBRIA: 'Úmbria',
	STATE_VALLE_D__AOSTA: "Valle d'Aosta",
	STATE_VARESE: 'Varese',
	STATE_VENETO: 'Veneza',
	STATE_VENEZIA: 'Veneza',
	STATE_VERBANO_CUSIO_OSSOLA: 'Verbano-Cusio-Ossola',
	STATE_VERCELLI: 'Vercelli',
	STATE_VERONA: 'Verona',
	STATE_VICENZA: 'Vicenza',
	STATE_VITERBO: 'Viterbo',
	STATE_BUXORO_VILOYATI: 'Buxoro Viloyati',
	STATE_CLARENDON: 'Clarendon',
	STATE_HANOVER: 'Hanôver',
	STATE_KINGSTON: 'Kingston',
	STATE_MANCHESTER: 'Manchester',
	STATE_PORTLAND: 'Portland',
	STATE_SAINT_ANDREWS: 'Saint Andrews',
	STATE_SAINT_ANN: 'Saint Ann',
	STATE_SAINT_CATHERINE: 'Saint Catherine',
	STATE_SAINT_ELIZABETH: 'Saint Elizabeth',
	STATE_TRELAWNEY: 'Trelawney',
	STATE_WESTMORELAND: 'Westmoreland',
	STATE_AICHI: 'Aichi',
	STATE_AKITA: 'Akita',
	STATE_AOMORI: 'Aomori',
	STATE_CHIBA: 'Chiba',
	STATE_EHIME: 'Ehime',
	STATE_FUKUI: 'Fukui',
	STATE_FUKUOKA: 'Fukuoka',
	STATE_FUKUSHIMA: 'Fukushima',
	STATE_GIFU: 'Gifu',
	STATE_GUMMA: 'Gumma',
	STATE_HIROSHIMA: 'Hiroshima',
	STATE_HOKKAIDO: 'Hokkaido',
	STATE_HYOGO: 'Hyogo',
	STATE_IBARAKI: 'Ibaraki',
	STATE_ISHIKAWA: 'Ishikawa',
	STATE_IWATE: 'Iwate',
	STATE_KAGAWA: 'Kagawa',
	STATE_KAGOSHIMA: 'Kagoshima',
	STATE_KANAGAWA: 'Kanagawa',
	STATE_KANTO: 'Kanto',
	STATE_KOCHI: 'Kochi',
	STATE_KUMAMOTO: 'Kumamoto',
	STATE_KYOTO: 'Quioto',
	STATE_MIE: 'Mie',
	STATE_MIYAGI: 'Miyagi',
	STATE_MIYAZAKI: 'Miyazaki',
	STATE_NAGANO: 'Nagano',
	STATE_NAGASAKI: 'Nagasaki',
	STATE_NARA: 'Nara',
	STATE_NIIGATA: 'Niigata',
	STATE_OITA: 'Oita',
	STATE_OKAYAMA: 'Okayama',
	STATE_OKINAWA: 'Okinawa',
	STATE_OSAKA: 'Osaka',
	STATE_SAGA: 'Saga',
	STATE_SAITAMA: 'Saitama',
	STATE_SHIGA: 'Shiga',
	STATE_SHIMANE: 'Shimane',
	STATE_SHIZUOKA: 'Shizuoka',
	STATE_TOCHIGI: 'Tochigi',
	STATE_TOKUSHIMA: 'Tokushima',
	STATE_TOKYO: 'Tóquio',
	STATE_TOTTORI: 'Tottori',
	STATE_TOYAMA: 'Toyama',
	STATE_WAKAYAMA: 'Wakayama',
	STATE_YAMAGATA: 'Yamagata',
	STATE_YAMAGUCHI: 'Yamaguchi',
	STATE_YAMANASHI: 'Yamanashi',
	STATE_GROUVILLE: 'Grouville',
	STATE_SAINT_BRELADE: 'Saint Brelade',
	STATE_SAINT_CLEMENT: 'Saint Clement',
	STATE_SAINT_HELIER: 'Saint Helier',
	STATE_SAINT_LAWRENCE: 'Saint Lawrence',
	STATE_SALIMA: 'Salima',
	STATE_TRINITY: 'Trinity',
	STATE_AJLUN: 'Ajlun',
	STATE_AMMAN: 'Amã',
	STATE_IRBID: 'Irbid',
	STATE_JARASH: 'Jarash',
	STATE_MA__AN: "Ma'an",
	STATE_MADABA: 'Madaba',
	STATE_AL___AQABAH: "al-'Aqabah",
	STATE_AL_BALQA: 'al-Balqa',
	STATE_AL_KARAK: 'al-Karak',
	STATE_AL_MAFRAQ: 'al-Mafraq',
	STATE_AT_TAFILAH: 'at-Tafilah',
	STATE_AZ_ZARQA: 'az-Zarqa',
	STATE_AKMECET: 'Akmecet',
	STATE_AKMOLA: 'Akmola',
	STATE_AKTOBE: 'Aktobe',
	STATE_ALMATI: 'Almati',
	STATE_ATIRAU: 'Atirau',
	STATE_BATIS_KAZAKSTAN: 'Cazaquistão Ocidental',
	STATE_BURLINSKY_REGION: 'Região de Burlinsky',
	STATE_KARAGANDI: 'Karagandi',
	STATE_KOSTANAY: 'Kostanay',
	STATE_MANKISTAU: 'Mankistau',
	STATE_ONTUSTIK_KAZAKSTAN: 'Cazaquistão do Sul',
	STATE_PAVLODAR: 'Pavlodar',
	STATE_SIGIS_KAZAKSTAN: 'Cazaquistão do Leste',
	STATE_SOLTUSTIK_KAZAKSTAN: 'Cazaquistão do Sul',
	STATE_TARAZ: 'Taraz',
	STATE_COAST: 'Costa',
	STATE_NAIROBI: 'Nairóbi',
	STATE_NORTH_EASTERN: 'Nordeste',
	STATE_NYANZA: 'Nyanza',
	STATE_RIFT_VALLEY: 'Vale do Rift',
	STATE_ABAIANG: 'Abaiang',
	STATE_ABEMANA: 'Abemana',
	STATE_ARANUKA: 'Aranuka',
	STATE_ARORAE: 'Narora',
	STATE_BANABA: 'Banaba',
	STATE_BERU: 'Beru',
	STATE_BUTARITARI: 'Butaritari',
	STATE_KIRITIMATI: 'Kiritimati',
	STATE_KURIA: 'Kuria',
	STATE_MAIANA: 'Maiana',
	STATE_MAKIN: 'Makin',
	STATE_MARAKEI: 'Marakei',
	STATE_NIKUNAU: 'Nikunau',
	STATE_NONOUTI: 'Nonouti',
	STATE_ONOTOA: 'Onotoa',
	STATE_PHOENIX_ISLANDS: 'Ilhas Fênix',
	STATE_TABITEUEA_NORTH: 'Tabiteuea do Norte',
	STATE_TABITEUEA_SOUTH: 'Tabiteuea do Sul',
	STATE_TABUAERAN: 'Tabuaeran',
	STATE_TAMANA: 'Tamana',
	STATE_TARAWA_NORTH: 'Tarawa do Norte',
	STATE_TARAWA_SOUTH: 'Tarawa do Sul',
	STATE_TERAINA: 'Teraina',
	STATE_CHAGANGDO: 'Chagangdo',
	STATE_HAMGYEONGBUKTO: 'Hamgyeongbukto',
	STATE_HAMGYEONGNAMDO: 'Hamgyeongnamdo',
	STATE_HWANGHAEBUKTO: 'Hwanghaebukto',
	STATE_HWANGHAENAMDO: 'Hwanghaenamdo',
	STATE_KAESEONG: 'Kaeseong',
	STATE_KANGWEON: 'Kangweon',
	STATE_NAMPO: 'Nampo',
	STATE_PYEONGANBUKTO: 'Pyeonganbukto',
	STATE_PYEONGANNAMDO: 'Pyeongannamdo',
	STATE_PYEONGYANG: 'Pyeongyang',
	STATE_YANGGANG: 'Yanggang',
	STATE_BUSAN: 'Busan',
	STATE_CHEJU: 'Cheju',
	STATE_CHOLLABUK: 'Chollabuk',
	STATE_CHOLLANAM: 'Chollanam',
	STATE_CHUNGBUK: 'Chungbuk',
	STATE_CHUNGCHEONGBUK: 'Chungcheongbuk',
	STATE_CHUNGCHEONGNAM: 'Chungcheongnam',
	STATE_CHUNGNAM: 'Chungnam',
	STATE_DAEGU: 'Daegu',
	STATE_GANGWON_DO: 'Gangwon-do',
	STATE_GOYANG_SI: 'Goyang-si',
	STATE_GYEONGGI_DO: 'Gyeonggi-do',
	STATE_GYEONGSANG: 'Gyeongsang',
	STATE_GYEONGSANGNAM_DO: 'Gyeongsangnam-do',
	STATE_INCHEON: 'Incheon',
	STATE_JEJU_SI: 'Jeju-Si',
	STATE_JEONBUK: 'Jeonbuk',
	STATE_KWANGJU: 'Kwangju',
	STATE_KYEONGGI: 'Kyeonggi',
	STATE_KYEONGSANGBUK: 'Kyeongsangbuk',
	STATE_KYEONGSANGNAM: 'Kyeongsangnam',
	STATE_KYONGGI_DO: 'Kyonggi-do',
	STATE_KYUNGBUK_DO: 'Kyungbuk-Do',
	STATE_KYUNGGI_DO: 'Kyunggi-Do',
	STATE_PUSAN: 'Pusan',
	STATE_SEOUL: 'Seul',
	STATE_SUDOGWON: 'Sudogwon',
	STATE_TAEGU: 'Taegu',
	STATE_TAEJEON: 'Taejeon',
	STATE_TAEJON_GWANGYOKSI: 'Taejon-gwangyoksi',
	STATE_ULSAN: 'Ulsan',
	STATE_WONJU: 'Wonju',
	STATE_GWANGYOKSI: 'gwangyoksi',
	STATE_AL_ASIMAH: 'Al Asimah',
	STATE_HAWALLI: 'Hawalli',
	STATE_MISHREF: 'Mishref',
	STATE_QADESIYA: 'Qadesiya',
	STATE_SAFAT: 'Safat',
	STATE_SALMIYA: 'Salmiya',
	STATE_AL_AHMADI: 'al-Ahmadi',
	STATE_AL_FARWANIYAH: 'al-Farwaniyah',
	STATE_AL_JAHRA: 'al-Jahra',
	STATE_AL_KUWAYT: 'al-Kuwayt',
	STATE_BATKEN: 'Batken',
	STATE_BISHKEK: 'Bishkek',
	STATE_CHUI: 'Chui',
	STATE_ISSYK_KUL: 'Issyk-Kul',
	STATE_JALAL_ABAD: 'Jalal-Abad',
	STATE_NARYN: 'Naryn',
	STATE_OSH: 'Osh',
	STATE_TALAS: 'Talas',
	STATE_ATTOPU: 'Attopu',
	STATE_BOKEO: 'Bokeo',
	STATE_BOLIKHAMSAY: 'Bolikhamsay',
	STATE_CHAMPASAK: 'Champasak',
	STATE_HOUAPHANH: 'Houaphanh',
	STATE_KHAMMOUANE: 'Khammouane',
	STATE_LUANG_NAM_THA: 'Luang Nam Tha',
	STATE_LUANG_PRABANG: 'Luang Prabang',
	STATE_OUDOMXAY: 'Oudomxay',
	STATE_PHONGSALY: 'Phongsaly',
	STATE_SARAVAN: 'Saravan',
	STATE_SAVANNAKHET: 'Savannakhet',
	STATE_SEKONG: 'Sekong',
	STATE_VIANGCHAN_PREFECTURE: 'Viangchan Prefecture',
	STATE_VIANGCHAN_PROVINCE: 'Viangchan Province',
	STATE_XAIGNABURY: 'Xaignabury',
	STATE_XIANG_KHUANG: 'Xiang Khuang',
	STATE_AIZKRAUKLES: 'Aizkraukles',
	STATE_ALUKSNES: 'Aluksnes',
	STATE_BALVU: 'Balvu',
	STATE_BAUSKAS: 'Bauskas',
	STATE_CESU: 'Cesu',
	STATE_DAUGAVPILS: 'Daugavpils',
	STATE_DAUGAVPILS_CITY: 'Cidade de Daugavpils',
	STATE_DOBELES: 'Dobeles',
	STATE_GULBENES: 'Gulbenes',
	STATE_JEKABSPILS: 'Jekabspils',
	STATE_JELGAVA: 'Jelgava',
	STATE_JELGAVAS: 'Jelgavas',
	STATE_JURMALA_CITY: 'Cidade de Jurmala',
	STATE_KRASLAVAS: 'Kraslavas',
	STATE_KULDIGAS: 'Kuldigas',
	STATE_LIEPAJA: 'Liepaja',
	STATE_LIEPAJAS: 'Liepajas',
	STATE_LIMBAZHU: 'Limbazhu',
	STATE_LUDZAS: 'Ludzas',
	STATE_MADONAS: 'Madonas',
	STATE_OGRES: 'Ogres',
	STATE_PREILU: 'Preilu',
	STATE_REZEKNE: 'Rezekne',
	STATE_REZEKNES: 'Rezeknes',
	STATE_RIGA: 'Riga',
	STATE_RIGAS: 'Rigas',
	STATE_SALDUS: 'Saldus',
	STATE_TALSU: 'Talsu',
	STATE_TUKUMA: 'Tukuma',
	STATE_VALKAS: 'Valkas',
	STATE_VALMIERAS: 'Valmieras',
	STATE_VENTSPILS: 'Ventspils',
	STATE_VENTSPILS_CITY: 'Cidade de Ventspils',
	STATE_BEIRUT: 'Beirute',
	STATE_JABAL_LUBNAN: 'Jabal Lubnan',
	STATE_MOHAFAZAT_LIBAN_NORD: 'Mohafazat Liban-Nord',
	STATE_MOHAFAZAT_MONT_LIBAN: 'Mohafazat Mon-Nord',
	STATE_SIDON: 'Sidon',
	STATE_AL_BIQA: 'al-Biqa',
	STATE_AL_JANUB: 'al-Janub',
	STATE_AN_NABATIYAH: 'an-Nabatiyah',
	STATE_ASH_SHAMAL: 'ash-Shamal',
	STATE_BEREA: 'Berea',
	STATE_BUTHA_BUTHE: 'Butha-Buthe',
	STATE_LERIBE: 'Leribe',
	STATE_MAFETENG: 'Mafeteng',
	STATE_MASERU: 'Maseru',
	STATE_MOHALE__S_HOEK: "Mohale's Hoek",
	STATE_MOKHOTLONG: 'Mokhotlong',
	STATE_QACHA__S_NEK: "Qacha's Nek",
	STATE_QUTHING: 'Quthing',
	STATE_THABA_TSEKA: 'Thaba-Tseka',
	STATE_BOMI: 'Bomi',
	STATE_BONG: 'Bong',
	STATE_GRAND_BASSA: 'Grand Bassa',
	STATE_GRAND_CAPE_MOUNT: 'Grand Cape Mount',
	STATE_GRAND_GEDEH: 'Grand Gedeh',
	STATE_LOFFA: 'Loffa',
	STATE_MARGIBI: 'Margibi',
	STATE_MARYLAND_AND_GRAND_KRU: 'Maryland e Grand Kru',
	STATE_MONTSERRADO: 'Montserrado',
	STATE_NIMBA: 'Nimba',
	STATE_RIVERCESS: 'Rivercess',
	STATE_SINOE: 'Sinoe',
	STATE_AJDABIYA: 'Ajdabiya',
	STATE_FEZZAN: 'Fezzan',
	STATE_BANGHAZI: 'Banghazi',
	STATE_DARNAH: 'Darnah',
	STATE_GHADAMIS: 'Ghadamis',
	STATE_GHARYAN: 'Gharyan',
	STATE_MISRATAH: 'Misratah',
	STATE_MURZUQ: 'Murzuq',
	STATE_SABHA: 'Sabha',
	STATE_SAWFAJJIN: 'Sawfajjin',
	STATE_SURT: 'Surt',
	STATE_TARABULUS: 'Tarabulus',
	STATE_TARHUNAH: 'Tarhunah',
	STATE_TRIPOLITANIA: 'Tripolitania',
	STATE_TUBRUQ: 'Tubruq',
	STATE_YAFRAN: 'Yafran',
	STATE_ZLITAN: 'Zlitan',
	STATE_AL___AZIZIYAH: "al-'Aziziyah",
	STATE_AL_FATIH: 'al-Fatih',
	STATE_AL_JABAL_AL_AKHDAR: 'al-Jabal al Akhdar',
	STATE_AL_JUFRAH: 'al-Jufrah',
	STATE_AL_KHUMS: 'al-Khums',
	STATE_AL_KUFRAH: 'al-Kufrah',
	STATE_AN_NUQAT_AL_KHAMS: 'an-Nuqat al-Khams',
	STATE_ASH_SHATI: 'ash-Shati',
	STATE_AZ_ZAWIYAH: 'az-Zawiyah',
	STATE_BALZERS: 'Balzers',
	STATE_ESCHEN: 'Eschen',
	STATE_GAMPRIN: 'Gamprin',
	STATE_MAUREN: 'Mauren',
	STATE_PLANKEN: 'Planken',
	STATE_RUGGELL: 'Ruggell',
	STATE_SCHAAN: 'Schaan',
	STATE_SCHELLENBERG: 'Schellenberg',
	STATE_TRIESEN: 'Triesen',
	STATE_TRIESENBERG: 'Triesenberg',
	STATE_VADUZ: 'Vaduz',
	STATE_ALYTAUS: 'Alytaus',
	STATE_ANYKSCIAI: 'Anyksciai',
	STATE_KAUNO: 'Kauno',
	STATE_KLAIPEDOS: 'Klaipedos',
	STATE_MARIJAMPOLES: 'Marijampoles',
	STATE_PANEVEZHIO: 'Panevezhio',
	STATE_PANEVEZYS: 'Panevezys',
	STATE_SHIAULIU: 'Shiauliu',
	STATE_TAURAGES: 'Taurages',
	STATE_TELSHIU: 'Telshiu',
	STATE_TELSIAI: 'Telsiai',
	STATE_UTENOS: 'Utenos',
	STATE_VILNIAUS: 'Vilniaus',
	STATE_CAPELLEN: 'Capellen',
	STATE_CLERVAUX: 'Clervaux',
	STATE_DIEKIRCH: 'Diekirch',
	STATE_ECHTERNACH: 'Echternach',
	STATE_ESCH_SUR_ALZETTE: 'Esch-sur-Alzette',
	STATE_GREVENMACHER: 'Grevenmacher',
	STATE_MERSCH: 'Mersch',
	STATE_REDANGE: 'Redange',
	STATE_REMICH: 'Remich',
	STATE_VIANDEN: 'Vianden',
	STATE_WILTZ: 'Wiltz',
	STATE_MACAU: 'Macau',
	STATE_BEROVO: 'Berovo',
	STATE_BITOLA: 'Bitola',
	STATE_BROD: 'Brod',
	STATE_DEBAR: 'Debar',
	STATE_DELCHEVO: 'Delchevo',
	STATE_DEMIR_HISAR: 'Demir Hisar',
	STATE_GEVGELIJA: 'Gevgelija',
	STATE_GOSTIVAR: 'Gostivar',
	STATE_KAVADARCI: 'Kavadarci',
	STATE_KICHEVO: 'Kichevo',
	STATE_KOCHANI: 'Kochani',
	STATE_KRATOVO: 'Kratovo',
	STATE_KRIVA_PALANKA: 'Kriva Palanka',
	STATE_KRUSHEVO: 'Krushevo',
	STATE_KUMANOVO: 'Kumanovo',
	STATE_NEGOTINO: 'Negotino',
	STATE_OHRID: 'Ohrid',
	STATE_PRILEP: 'Prilep',
	STATE_PROBISHTIP: 'Probishtip',
	STATE_RADOVISH: 'Radovish',
	STATE_RESEN: 'Resen',
	STATE_SHTIP: 'Shtip',
	STATE_SKOPJE: 'Skopje',
	STATE_STRUGA: 'Struga',
	STATE_STRUMICA: 'Strumica',
	STATE_SVETI_NIKOLE: 'Sveti Nikole',
	STATE_TETOVO: 'Tetovo',
	STATE_VALANDOVO: 'Valandovo',
	STATE_VELES: 'Veles',
	STATE_VINICA: 'Vinica',
	STATE_ANTANANARIVO: 'Antananarivo',
	STATE_ANTSIRANANA: 'Antsiranana',
	STATE_FIANARANTSOA: 'Fianarantsoa',
	STATE_MAHAJANGA: 'Mahajanga',
	STATE_TOAMASINA: 'Toamasina',
	STATE_TOLIARY: 'Toliary',
	STATE_BALAKA: 'Balaka',
	STATE_BLANTYRE_CITY: 'Cidade de Blantyre',
	STATE_CHIKWAWA: 'Chikwawa',
	STATE_CHIRADZULU: 'Chiradzulu',
	STATE_CHITIPA: 'Chitipa',
	STATE_DEDZA: 'Dedza',
	STATE_DOWA: 'Dowa',
	STATE_KARONGA: 'Karonga',
	STATE_KASUNGU: 'Kasungu',
	STATE_LILONGWE_CITY: 'Cidade de Lilongwe',
	STATE_MACHINGA: 'Machinga',
	STATE_MANGOCHI: 'Mangochi',
	STATE_MCHINJI: 'Mchinji',
	STATE_MULANJE: 'Mulanje',
	STATE_MWANZA: 'Mwanza',
	STATE_MZIMBA: 'Mzimba',
	STATE_MZUZU_CITY: 'Cidade de Mzuzu',
	STATE_NKHATA_BAY: 'Nkhata Bay',
	STATE_NKHOTAKOTA: 'Nkhotakota',
	STATE_NSANJE: 'Nsanje',
	STATE_NTCHEU: 'Ntcheu',
	STATE_NTCHISI: 'Ntchisi',
	STATE_PHALOMBE: 'Phalombe',
	STATE_THYOLO: 'Thyolo',
	STATE_ZOMBA_MUNICIPALITY: 'Zomba Municipality',
	STATE_JOHOR: 'Johor',
	STATE_KEDAH: 'Kedah',
	STATE_KELANTAN: 'Kelantan',
	STATE_KUALA_LUMPUR: 'Kuala Lumpur',
	STATE_LABUAN: 'Labuan',
	STATE_MELAKA: 'Melaka',
	STATE_NEGERI_JOHOR: 'Negeri Johor',
	STATE_NEGERI_SEMBILAN: 'Negeri Sembilan',
	STATE_PAHANG: 'Pahang',
	STATE_PENANG: 'Penang',
	STATE_PERAK: 'Perak',
	STATE_PERLIS: 'Perlis',
	STATE_PULAU_PINANG: 'Pulau Pinang',
	STATE_SABAH: 'Sabah',
	STATE_SARAWAK: 'Sarawak',
	STATE_SELANGOR: 'Selangor',
	STATE_SEMBILAN: 'Negeri Sembilan',
	STATE_TERENGGANU: 'Terengganu',
	STATE_ALIF_ALIF: 'Alif Alif',
	STATE_ALIF_DHAAL: 'Alif Dhaal',
	STATE_BAA: 'Baa',
	STATE_DHAAL: 'Dhaal',
	STATE_FAAF: 'Faaf',
	STATE_GAAF_ALIF: 'Gaaf Alif',
	STATE_GAAF_DHAAL: 'Gaaf Dhaal',
	STATE_GHAVIYANI: 'Ghaviyani',
	STATE_HAA_ALIF: 'Haa Alif',
	STATE_HAA_DHAAL: 'Haa Dhaal',
	STATE_KAAF: 'Kaaf',
	STATE_LAAM: 'Laam',
	STATE_LHAVIYANI: 'Lhaviyani',
	STATE_MALE: 'Male',
	STATE_MIIM: 'Miim',
	STATE_NUUN: 'Nuun',
	STATE_RAA: 'Raa',
	STATE_SHAVIYANI: 'Shaviyani',
	STATE_SIIN: 'Siin',
	STATE_THAA: 'Thaa',
	STATE_VAAV: 'Vaav',
	STATE_BAMAKO: 'Bamako',
	STATE_GAO: 'Gao',
	STATE_KAYES: 'Kayes',
	STATE_KIDAL: 'Kidal',
	STATE_KOULIKORO: 'Koulikoro',
	STATE_MOPTI: 'Mopti',
	STATE_SEGOU: 'Segou',
	STATE_SIKASSO: 'Sikasso',
	STATE_TOMBOUCTOU: 'Tombouctou',
	STATE_GOZO_AND_COMINO: 'Gozo e Comino',
	STATE_INNER_HARBOUR: 'Inner Harbour',
	STATE_OUTER_HARBOUR: 'Outer Harbour',
	STATE_SOUTH_EASTERN: 'Sudeste',
	STATE_CASTLETOWN: 'Castletown',
	STATE_DOUGLAS: 'Douglas',
	STATE_LAXEY: 'Laxey',
	STATE_ONCHAN: 'Onchan',
	STATE_PEEL: 'Peel',
	STATE_PORT_ERIN: 'Port Erin',
	STATE_PORT_SAINT_MARY: 'Saint Mary',
	STATE_RAMSEY: 'Ramsey',
	STATE_AILINLAPLAP: 'Ailinlaplap',
	STATE_AILUK: 'Ailuk',
	STATE_ARNO: 'Arno',
	STATE_AUR: 'Aur',
	STATE_BIKINI: 'Bikini',
	STATE_EBON: 'Ebon',
	STATE_ENEWETAK: 'Enewetak',
	STATE_JABAT: 'Jabat',
	STATE_JALUIT: 'Jaluit',
	STATE_KILI: 'Kili',
	STATE_KWAJALEIN: 'Kwajalein',
	STATE_LAE: 'Lae',
	STATE_LIB: 'Lib',
	STATE_LIKIEP: 'Likiep',
	STATE_MAJURO: 'Majuro',
	STATE_MALOELAP: 'Maloelap',
	STATE_MEJIT: 'Mejit',
	STATE_MILI: 'Mili',
	STATE_NAMORIK: 'Namorik',
	STATE_NAMU: 'Namu',
	STATE_RONGELAP: 'Rongelap',
	STATE_UJAE: 'Ujae',
	STATE_UTRIK: 'Utrik',
	STATE_WOTHO: 'Wotho',
	STATE_WOTJE: 'Wotje',
	STATE_FORT_DE_FRANCE: 'Fort-de-France',
	STATE_LA_TRINITE: 'La Trinite',
	STATE_LE_MARIN: 'Le Marin',
	STATE_SAINT_PIERRE: 'Saint-Pierre',
	STATE_ASSABA: 'Assaba',
	STATE_BRAKNA: 'Brakna',
	STATE_DHAKHLAT_NAWADIBU: 'Dhakhlat Nawadibu',
	STATE_HUDH_AL_GHARBI: 'Hudh-al-Gharbi',
	STATE_HUDH_ASH_SHARQI: 'Hudh-ash-Sharqi',
	STATE_INSHIRI: 'Inshiri',
	STATE_NAWAKSHUT: 'Nawakshut',
	STATE_QIDIMAGHA: 'Qidimagha',
	STATE_QURQUL: 'Qurqul',
	STATE_TAQANT: 'Taqant',
	STATE_TIRIS_ZAMMUR: 'Tiris Zammur',
	STATE_TRARZA: 'Trarza',
	STATE_BLACK_RIVER: 'Black River',
	STATE_EAU_COULEE: 'Eau Coulee',
	STATE_FLACQ: 'Flacq',
	STATE_FLOREAL: 'Floreal',
	STATE_GRAND_PORT: 'Grand Port',
	STATE_MOKA: 'Moka',
	STATE_PAMPLEMPOUSSES: 'Pamplempousses',
	STATE_PLAINES_WILHELM: 'Plaines Wilhelm',
	STATE_PORT_LOUIS: 'Port Louis',
	STATE_RIVIERE_DU_REMPART: 'Riviere du Rempart',
	STATE_RODRIGUES: 'Rodrigues',
	STATE_ROSE_HILL: 'Rose Hill',
	STATE_SAVANNE: 'Savanne',
	STATE_MAYOTTE: 'Maiote',
	STATE_PAMANZI: 'Pamanzi',
	STATE_AGUASCALIENTES: 'Aguascalientes',
	STATE_BAJA_CALIFORNIA: 'Baixa Califórnia',
	STATE_BAJA_CALIFORNIA_SUR: 'Baixa Califórnia do Sul',
	STATE_CAMPECHE: 'Campeche',
	STATE_CHIAPAS: 'Chiapas',
	STATE_CHIHUAHUA: 'Chihuahua',
	STATE_COAHUILA: 'Coahuila',
	STATE_COLIMA: 'Colima',
	STATE_DURANGO: 'Durango',
	STATE_ESTADO_DE_MEXICO: 'Estado do México',
	STATE_GUANAJUATO: 'Guanajuato',
	STATE_GUERRERO: 'Guerrero',
	STATE_HIDALGO: 'Hidalgo',
	STATE_JALISCO: 'Jalisco',
	STATE_MEXICO: 'México',
	STATE_MICHOACAN: 'Michoacan',
	STATE_MORELOS: 'Morelos',
	STATE_NAYARIT: 'Nayarit',
	STATE_NUEVO_LEON: 'Nuevo Leon',
	STATE_OAXACA: 'Oaxaca',
	STATE_PUEBLA: 'Puebla',
	STATE_QUERETARO: 'Querétaro',
	STATE_QUINTANA_ROO: 'Quintana Roo',
	STATE_SAN_LUIS_POTOSI: 'San Luis Potosi',
	STATE_SINALOA: 'Sinaloa',
	STATE_SONORA: 'Sonora',
	STATE_TABASCO: 'Tabasco',
	STATE_TAMAULIPAS: 'Tamaulipas',
	STATE_TLAXCALA: 'Tlaxcala',
	STATE_VERACRUZ: 'Veracruz',
	STATE_YUCATAN: 'Yucatan',
	STATE_ZACATECAS: 'Zacatecas',
	STATE_CHUUK: 'Chuuk',
	STATE_KUSAIE: 'Kusaie',
	STATE_POHNPEI: 'Pohnpei',
	STATE_YAP: 'Yap',
	STATE_BALTI: 'Balti',
	STATE_CAHUL: 'Cahul',
	STATE_CHISINAU: 'Chisinau',
	STATE_CHISINAU_ORAS: 'Chisinau Oras',
	STATE_EDINET: 'Edinet',
	STATE_GAGAUZIA: 'Gagauzia',
	STATE_LAPUSNA: 'Lapusna',
	STATE_ORHEI: 'Orhei',
	STATE_SOROCA: 'Soroca',
	STATE_TARACLIA: 'Taraclia',
	STATE_TIGHINA: 'Tighina',
	STATE_TRANSNISTRIA: 'Transnistria',
	STATE_UNGHENI: 'Ungheni',
	STATE_FONTVIEILLE: 'Fontvieille',
	STATE_LA_CONDAMINE: 'La Condamine',
	STATE_MONACO_VILLE: 'Monaco-Ville',
	STATE_MONTE_CARLO: 'Monte Carlo',
	STATE_ARHANGAJ: 'Arhangaj',
	STATE_BAJAN_OLGIJ: 'Bajan-Olgij',
	STATE_BAJANHONGOR: 'Bajanhongor',
	STATE_BULGAN: 'Bulgan',
	STATE_DARHAN_UUL: 'Darhan-Uul',
	STATE_DORNOD: 'Dornod',
	STATE_DORNOGOVI: 'Dornogovi',
	STATE_DUNDGOVI: 'Dundgovi',
	STATE_GOVI_ALTAJ: 'Govi-Altaj',
	STATE_GOVISUMBER: 'Govisumber',
	STATE_HENTIJ: 'Hentij',
	STATE_HOVD: 'Hovd',
	STATE_HOVSGOL: 'Hovsgol',
	STATE_OMNOGOVI: 'Omnogovi',
	STATE_ORHON: 'Orhon',
	STATE_OVORHANGAJ: 'Ovorhangaj',
	STATE_SELENGE: 'Selenge',
	STATE_SUHBAATAR: 'Suhbaatar',
	STATE_TOV: 'Tov',
	STATE_ULAANBAATAR: 'Ulaanbaatar',
	STATE_UVS: 'Uvs',
	STATE_ZAVHAN: 'Zavhan',
	STATE_MONTSERRAT: 'Monserrate',
	STATE_AGADIR: 'Agadir',
	STATE_CASABLANCA: 'Casablanca',
	STATE_CHAOUIA_OUARDIGHA: 'Chaouia-Ouardigha',
	STATE_DOUKKALA_ABDA: 'Doukkala-Abda',
	STATE_FES_BOULEMANE: 'Fes-Boulemane',
	STATE_GHARB_CHRARDA_BENI_HSSEN: 'Gharb-Chrarda-Beni Hssen',
	STATE_GUELMIM: 'Guelmim',
	STATE_KENITRA: 'Kenitra',
	STATE_MARRAKECH_TENSIFT_AL_HAOUZ: 'Marrakech-Tensift-Al Haouz',
	STATE_MEKNES_TAFILALET: 'Meknes-Tafilalet',
	STATE_ORIENTAL: 'Oriental',
	STATE_OUJDA: 'Oujda',
	STATE_PROVINCE_DE_TANGER: 'Province de Tanger',
	STATE_RABAT_SALE_ZAMMOUR_ZAER: 'Rabat-Sale-Zammour-Zaer',
	STATE_SALA_AL_JADIDA: 'Sala Al Jadida',
	STATE_SETTAT: 'Settat',
	STATE_SOUSS_MASSA_DRAA: 'Souss Massa-Draa',
	STATE_TADLA_AZILAL: 'Tadla-Azilal',
	STATE_TANGIER_TETOUAN: 'Tangier-Tetouan',
	STATE_TAZA_AL_HOCEIMA_TAOUNATE: 'Taza-Al Hoceima-Taounate',
	STATE_WILAYA_DE_CASABLANCA: 'Wilaya de Casablanca',
	STATE_WILAYA_DE_RABAT_SALE: 'Wilaya de Rabat-Sale',
	STATE_CABO_DELGADO: 'Cabo Delgado',
	STATE_INHAMBANE: 'Inhambane',
	STATE_MANICA: 'Manica',
	STATE_MAPUTO: 'Maputo',
	STATE_MAPUTO_PROVINCIA: 'Maputo Provincia',
	STATE_NAMPULA: 'Nampula',
	STATE_NIASSA: 'Niassa',
	STATE_SOFALA: 'Sofala',
	STATE_TETE: 'Tete',
	STATE_ZAMBEZIA: 'Zambezia',
	STATE_AYEYARWADY: 'Ayeyarwady',
	STATE_BAGO: 'Bago',
	STATE_CHIN: 'Chin',
	STATE_KACHIN: 'Kachin',
	STATE_KAYAH: 'Kayah',
	STATE_KAYIN: 'Kayin',
	STATE_MAGWAY: 'Magway',
	STATE_MANDALAY: 'Mandalay',
	STATE_MON: 'Mon',
	STATE_NAY_PYI_TAW: 'Nay Pyi Taw',
	STATE_RAKHINE: 'Rakhine',
	STATE_SAGAING: 'Sagaing',
	STATE_SHAN: 'Shan',
	STATE_TANINTHARYI: 'Tanintharyi',
	STATE_YANGON: 'Yangon',
	STATE_CAPRIVI: 'Caprivi',
	STATE_ERONGO: 'Erongo',
	STATE_HARDAP: 'Hardap',
	STATE_KARAS: 'Karas',
	STATE_KAVANGO: 'Kavango',
	STATE_KHOMAS: 'Khomas',
	STATE_KUNENE: 'Kunene',
	STATE_OHANGWENA: 'Ohangwena',
	STATE_OMAHEKE: 'Omaheke',
	STATE_OMUSATI: 'Omusati',
	STATE_OSHANA: 'Oshana',
	STATE_OSHIKOTO: 'Oshikoto',
	STATE_OTJOZONDJUPA: 'Otjozondjupa',
	STATE_YAREN: 'Yaren',
	STATE_BAGMATI: 'Bagmati',
	STATE_BHERI: 'Bheri',
	STATE_DHAWALAGIRI: 'Dhawalagiri',
	STATE_GANDAKI: 'Gandaki',
	STATE_JANAKPUR: 'Janakpur',
	STATE_KARNALI: 'Karnali',
	STATE_KOSHI: 'Koshi',
	STATE_LUMBINI: 'Lumbini',
	STATE_MAHAKALI: 'Mahakali',
	STATE_MECHI: 'Mechi',
	STATE_NARAYANI: 'Narayani',
	STATE_RAPTI: 'Rapti',
	STATE_SAGARMATHA: 'Sagarmatha',
	STATE_SETI: 'Seti',
	STATE_BONAIRE: 'Bonaire',
	STATE_CURACAO: 'Curaçau',
	STATE_SABA: 'Saba',
	STATE_SINT_EUSTATIUS: 'Sint Eustatius',
	STATE_SINT_MAARTEN: 'São Martinho',
	STATE_BENELUX: 'Benelux',
	STATE_DRENTHE: 'Drenthe',
	STATE_FLEVOLAND: 'Flevolândia',
	STATE_FRIESLAND: 'Frísia',
	STATE_GELDERLAND: 'Guéldria',
	STATE_GRONINGEN: 'Groningen',
	STATE_NOORD_BRABANT: 'Brabante do Norte',
	STATE_OVERIJSSEL: 'Overijssel',
	STATE_SOUTH_HOLLAND: 'Holanda do Sul',
	STATE_UTRECHT: 'Utrecht',
	STATE_ZEELAND: 'Zelândia',
	STATE_ZUID_HOLLAND: 'Holanda do Sul',
	STATE_ILES: 'Iles',
	STATE_AREA_OUTSIDE_REGION: 'Área externa',
	STATE_BAY_OF_PLENTY: 'Baía de Plenty',
	STATE_CANTERBURY: 'Canterbury',
	STATE_CHRISTCHURCH: 'Christchurch',
	STATE_GISBORNE: 'Gisborne',
	STATE_HAWKE__S_BAY: "Hawke's Bay",
	STATE_MANAWATU_WANGANUI: 'Manawatu-Wanganui',
	STATE_MARLBOROUGH: 'Marlborough',
	STATE_NELSON: 'Nelson',
	STATE_NORTHLAND: 'Northland',
	STATE_OTAGO: 'Otago',
	STATE_RODNEY: 'Rodney',
	STATE_SOUTHLAND: 'Southland',
	STATE_TARANAKI: 'Taranaki',
	STATE_TASMAN: 'Tasman',
	STATE_WAIKATO: 'Waikato',
	STATE_WELLINGTON: 'Wellington',
	STATE_WEST_COAST: 'Costa Oeste',
	STATE_ATLANTICO_NORTE: 'Atlântico Norte',
	STATE_ATLANTICO_SUR: 'Atlântico Sul',
	STATE_BOACO: 'Boaco',
	STATE_CARAZO: 'Carazo',
	STATE_CHINANDEGA: 'Chinandega',
	STATE_CHONTALES: 'Chontales',
	STATE_ESTELI: 'Esteli',
	STATE_GRANADA: 'Granada',
	STATE_JINOTEGA: 'Jinotega',
	STATE_LEON: 'Leon',
	STATE_MADRIZ: 'Madriz',
	STATE_MANAGUA: 'Manágua',
	STATE_MASAYA: 'Masaya',
	STATE_MATAGALPA: 'Matagalpa',
	STATE_NUEVA_SEGOVIA: 'Nueva Segovia',
	STATE_RIO_SAN_JUAN: 'Rio San Juan',
	STATE_RIVAS: 'Rivas',
	STATE_AGADEZ: 'Agadez',
	STATE_DIFFA: 'Diffa',
	STATE_DOSSO: 'Dosso',
	STATE_MARADI: 'Maradi',
	STATE_NIAMEY: 'Niamey',
	STATE_TAHOUA: 'Tahoua',
	STATE_TILLABERY: 'Tillabery',
	STATE_ZINDER: 'Zinder',
	STATE_ABIA: 'Abia',
	STATE_ABUJA_FEDERAL_CAPITAL_TERRITOR: 'Território da Capital Federal Abuja',
	STATE_ADAMAWA: 'Adamawa',
	STATE_AKWA_IBOM: 'Akwa Ibom',
	STATE_ANAMBRA: 'Anambra',
	STATE_BAUCHI: 'Bauchi',
	STATE_BAYELSA: 'Bayelsa',
	STATE_BENUE: 'Benue',
	STATE_BORNO: 'Borno',
	STATE_CROSS_RIVER: 'Cross River',
	STATE_DELTA: 'Delta',
	STATE_EBONYI: 'Ebonyi',
	STATE_EDO: 'Edo',
	STATE_EKITI: 'Ekiti',
	STATE_ENUGU: 'Enugu',
	STATE_GOMBE: 'Gombe',
	STATE_IMO: 'Imo',
	STATE_JIGAWA: 'Jigawa',
	STATE_KADUNA: 'Kaduna',
	STATE_KANO: 'Kano',
	STATE_KATSINA: 'Katsina',
	STATE_KEBBI: 'Kebbi',
	STATE_KOGI: 'Kogi',
	STATE_KWARA: 'Kwara',
	STATE_LAGOS: 'Lagos',
	STATE_NASSARAWA: 'Nassarawa',
	STATE_NIGER: 'Níger',
	STATE_OGUN: 'Ogun',
	STATE_ONDO: 'Ondo',
	STATE_OSUN: 'Osun',
	STATE_OYO: 'Oyo',
	STATE_RIVERS: 'Rivers',
	STATE_SOKOTO: 'Sokoto',
	STATE_TARABA: 'Taraba',
	STATE_YOBE: 'Yobe',
	STATE_ZAMFARA: 'Zamfara',
	STATE_NIUE: 'Niue',
	STATE_NORFOLK_ISLAND: 'Ilha Norfolk',
	STATE_NORTHERN_ISLANDS: 'Northern Islands',
	STATE_ROTA: 'Rota',
	STATE_SAIPAN: 'Saipan',
	STATE_TINIAN: 'Tinian',
	STATE_AKERSHUS: 'Akershus',
	STATE_AUST_AGDER: 'Aust Agder',
	STATE_BERGEN: 'Bergen',
	STATE_BUSKERUD: 'Buskerud',
	STATE_FINNMARK: 'Finnmark',
	STATE_HEDMARK: 'Hedmark',
	STATE_HORDALAND: 'Hordalândia',
	STATE_MOERE_OG_ROMSDAL: 'Moere e Romsdal',
	STATE_NORD_TRONDELAG: 'Nord-Trøndelag',
	STATE_NORDLAND: 'Nordland',
	STATE_OESTFOLD: 'Oestfold',
	STATE_OPPLAND: 'Oppland',
	STATE_OSLO: 'Oslo',
	STATE_ROGALAND: 'Rogaland',
	STATE_SOER_TROENDELAG: 'Soer Troendelag',
	STATE_SOGN_OG_FJORDANE: 'Sogn e Fjordane',
	STATE_STAVERN: 'Stavern',
	STATE_SYKKYLVEN: 'Sykkylven',
	STATE_TELEMARK: 'Telemark',
	STATE_TROMS: 'Troms',
	STATE_VEST_AGDER: 'Vest Agder',
	STATE_VESTFOLD: 'Vestfold',
	'STATE_ÃƑÆ’Ã‚ËŒSTFOLD': 'ÃƒÆ’Ã‚Ëœstfold',
	STATE_AL_BURAIMI: 'Al Buraimi',
	STATE_DHUFAR: 'Dhufar',
	STATE_MASQAT: 'Masqat',
	STATE_MUSANDAM: 'Musandam',
	STATE_RUSAYL: 'Rusayl',
	STATE_WADI_KABIR: 'Wadi Kabir',
	STATE_AD_DAKHILIYAH: 'ad-Dakhiliyah',
	STATE_ADH_DHAHIRAH: 'adh-Dhahirah',
	STATE_AL_BATINAH: 'al-Batinah',
	STATE_BALUCHISTAN: 'Baluchistão',
	STATE_FEDERAL_CAPITAL_AREA: 'Área da Capital Federal',
	STATE_FEDERALLY_ADMINISTERED_TRIBAL: 'Território Federal das Áreas Tribais',
	STATE_NORTH_WEST_FRONTIER: 'Fronteira do Noroeste',
	STATE_NORTHERN_AREAS: 'Áreas Norte',
	STATE_SIND: 'Sind',
	STATE_AIMELIIK: 'Aimeliik',
	STATE_AIRAI: 'Airai',
	STATE_ANGAUR: 'Angaur',
	STATE_HATOBOHEI: 'Hatobohei',
	STATE_KAYANGEL: 'Kayangel',
	STATE_KOROR: 'Koror',
	STATE_MELEKEOK: 'Melekeok',
	STATE_NGARAARD: 'Ngaraard',
	STATE_NGARDMAU: 'Ngardmau',
	STATE_NGAREMLENGUI: 'Ngaremlengui',
	STATE_NGATPANG: 'Ngatpang',
	STATE_NGCHESAR: 'Ngchesar',
	STATE_NGERCHELONG: 'Ngerchelong',
	STATE_NGIWAL: 'Ngiwal',
	STATE_PELELIU: 'Peleliu',
	STATE_SONSOROL: 'Sonsorol',
	STATE_ARIHA: 'Ariha',
	STATE_BAYT_LAHM: 'Bayt Lahm',
	STATE_DAYR_AL_BALAH: 'Dayr-al-Balah',
	STATE_GHAZZAH: 'Ghazzah',
	STATE_GHAZZAH_ASH_SHAMALIYAH: 'Ghazzah ash-Shamaliyah',
	STATE_JANIN: 'Janin',
	STATE_KHAN_YUNIS: 'Khan Yunis',
	STATE_NABULUS: 'Nabulus',
	STATE_QALQILYAH: 'Qalqilyah',
	STATE_RAFAH: 'Rafah',
	STATE_RAM_ALLAH_WAL_BIRAH: 'Ram Allah wal-Birah',
	STATE_SALFIT: 'Salfit',
	STATE_TUBAS: 'Tubas',
	STATE_TULKARM: 'Tulkarm',
	STATE_AL_KHALIL: 'al-Khalil',
	STATE_AL_QUDS: 'al-Quds',
	STATE_BOCAS_DEL_TORO: 'Bocas del Toro',
	STATE_CHIRIQUI: 'Chiriqui',
	STATE_COCLE: 'Cocle',
	STATE_DARIEN: 'Darien',
	STATE_EMBERA: 'Embera',
	STATE_HERRERA: 'Herrera',
	STATE_KUNA_YALA: 'Kuna Yala',
	STATE_LOS_SANTOS: 'Los Santos',
	STATE_NGOBE_BUGLE: 'Ngobe Bugle',
	STATE_PANAMA: 'Panamá',
	STATE_VERAGUAS: 'Veraguas',
	STATE_EAST_NEW_BRITAIN: 'Nova Bretanha Oriental',
	STATE_EAST_SEPIK: 'Sepik Leste',
	STATE_EASTERN_HIGHLANDS: 'Terra Altas Orientais',
	STATE_ENGA: 'Enga',
	STATE_FLY_RIVER: 'Rio Fly',
	STATE_GULF: 'Gulf',
	STATE_MADANG: 'Madang',
	STATE_MANUS: 'Manus',
	STATE_MILNE_BAY: 'Baía de Milne',
	STATE_MOROBE: 'Morobe',
	STATE_NATIONAL_CAPITAL_DISTRICT: 'Distrito da Capital Nacional',
	STATE_NEW_IRELAND: 'Nova Irlanda',
	STATE_NORTH_SOLOMONS: 'North Solomons',
	STATE_ORO: 'Oro',
	STATE_SANDAUN: 'Sandaun',
	STATE_SIMBU: 'Simbu',
	STATE_SOUTHERN_HIGHLANDS: 'Terras Altas do Sul',
	STATE_WEST_NEW_BRITAIN: 'Nova Bretanha Ocidental',
	STATE_WESTERN_HIGHLANDS: 'Terras Altas Ocidentais',
	STATE_ALTO_PARAGUAY: 'Alto Paraguai',
	STATE_ALTO_PARANA: 'Alto Paraná',
	STATE_AMAMBAY: 'Amambay',
	STATE_ASUNCION: 'Assunção',
	STATE_BOQUERON: 'Boqueron',
	STATE_CAAGUAZU: 'Caaguazu',
	STATE_CAAZAPA: 'Caazapa',
	STATE_CANENDIYU: 'Canendiyu',
	STATE_CONCEPCION: 'Concepción',
	STATE_CORDILLERA: 'Cordillera',
	STATE_GUAIRA: 'Guaíra',
	STATE_ITAPUA: 'Itapuã',
	STATE_NEEMBUCU: 'Neembucu',
	STATE_PARAGUARI: 'Paraguari',
	STATE_PRESIDENTE_HAYES: 'Presidente Hayes',
	STATE_SAN_PEDRO: 'San Pedro',
	STATE_ANCASH: 'Ancash',
	STATE_APURIMAC: 'Apurimac',
	STATE_AREQUIPA: 'Arequipa',
	STATE_AYACUCHO: 'Ayacucho',
	STATE_CAJAMARCA: 'Cajamarca',
	STATE_CUSCO: 'Cusco',
	STATE_HUANCAVELICA: 'Huancavelica',
	STATE_HUANUCO: 'Huanuco',
	STATE_ICA: 'Ica',
	STATE_JUNIN: 'Junin',
	STATE_LAMBAYEQUE: 'Lambayeque',
	STATE_LIMA_Y_CALLAO: 'Lima e Callao',
	STATE_LORETO: 'Loreto',
	STATE_MADRE_DE_DIOS: 'Madre de Dios',
	STATE_MOQUEGUA: 'Moquegua',
	STATE_PASCO: 'Pasco',
	STATE_PIURA: 'Piura',
	STATE_PUNO: 'Puno',
	STATE_SAN_MARTIN: 'San Martin',
	STATE_TACNA: 'Tacna',
	STATE_TUMBES: 'Tumbes',
	STATE_UCAYALI: 'Ucayali',
	STATE_BATANGAS: 'Batangas',
	STATE_BICOL: 'Bicol',
	STATE_BULACAN: 'Bulacão',
	STATE_CAGAYAN: 'Cagayan',
	STATE_CARAGA: 'Caraga',
	STATE_CENTRAL_LUZON: 'Luzon Central',
	STATE_CENTRAL_MINDANAO: 'Central Mindanao',
	STATE_CENTRAL_VISAYAS: 'Visayas Central',
	STATE_DAVAO: 'Davao',
	STATE_EASTERN_VISAYAS: 'Visayas Oriental',
	STATE_GREATER_METROPOLITAN_AREA: 'Grande Área Metropolitana',
	STATE_ILOCOS: 'Ilocos',
	STATE_LAGUNA: 'Laguna',
	STATE_LUZON: 'Luzon',
	STATE_MACTAN: 'Mactan',
	STATE_METROPOLITAN_MANILA_AREA: 'Área Metropolitana de Manila',
	STATE_MUSLIM_MINDANAO: 'Mindanau Muçulmano',
	STATE_NORTHERN_MINDANAO: 'Mindanau do Norte',
	STATE_SOUTHERN_MINDANAO: 'Mindanau do Sul',
	STATE_SOUTHERN_TAGALOG: 'Southern Tagalog',
	STATE_WESTERN_MINDANAO: 'Mindanau Ocidental',
	STATE_WESTERN_VISAYAS: 'Visayas Ocidental',
	STATE_PITCAIRN_ISLAND: 'Ilha Pitcairn',
	STATE_BIALE_BLOTA: 'Biale Blota',
	STATE_DOBROSZYCE: 'Dobroszyce',
	STATE_DOLNOSLASKIE: 'Dolnoslaskie',
	STATE_DZIEKANOW_LESNY: 'Dziekanow Lesny',
	STATE_HOPOWO: 'Hopowo',
	STATE_KARTUZY: 'Kartuzy',
	STATE_KOSCIAN: 'Koscian',
	STATE_KRAKOW: 'Cracóvia',
	STATE_KUJAWSKO_POMORSKIE: 'Kujawsko-Pomorskie',
	STATE_LODZKIE: 'Lodzkie',
	STATE_LUBELSKIE: 'Lubelskie',
	STATE_LUBUSKIE: 'Lubuskie',
	STATE_MALOMICE: 'Malomice',
	STATE_MALOPOLSKIE: 'Malopolskie',
	STATE_MAZOWIECKIE: 'Mazowieckie',
	STATE_MIRKOW: 'Mirkow',
	STATE_OPOLSKIE: 'Opolskie',
	STATE_OSTROWIEC: 'Ostrowiec',
	STATE_PODKARPACKIE: 'Podkarpackie',
	STATE_PODLASKIE: 'Podlaskie',
	STATE_POLSKA: 'Polônia',
	STATE_POMORSKIE: 'Pomorskie',
	STATE_POZNAN: 'Poznan',
	STATE_PRUSZKOW: 'Pruszkow',
	STATE_RYMANOWSKA: 'Rymanowska',
	STATE_RZESZOW: 'Rzeszow',
	STATE_SLASKIE: 'Slaskie',
	STATE_STARE_POLE: 'Stare Pole',
	STATE_SWIETOKRZYSKIE: 'Swietokrzyskie',
	STATE_WARMINSKO_MAZURSKIE: 'Warminsko-Mazurskie',
	STATE_WARSAW: 'Varsóvia',
	STATE_WEJHEROWO: 'Wejherowo',
	STATE_WIELKOPOLSKIE: 'Grande Polônia',
	STATE_WROCLAW: 'Wroclaw',
	STATE_ZACHODNIO_POMORSKIE: 'Zachodnio-Pomorskie',
	STATE_ZUKOWO: 'Zukowo',
	STATE_ABRANTES: 'Abrantes',
	STATE_ACORES: 'Açores',
	STATE_ALENTEJO: 'Alentejo',
	STATE_ALGARVE: 'Algarve',
	STATE_BRAGA: 'Braga',
	STATE_CENTRO: 'Centro',
	STATE_DISTRITO_DE_LEIRIA: 'Distrito de Leiria',
	STATE_DISTRITO_DE_VIANA_DO_CASTELO: 'Distrito de Viana do Castelo',
	STATE_DISTRITO_DE_VILA_REAL: 'Distrito de Vila Real',
	STATE_DISTRITO_DO_PORTO: 'Distrito do Porto',
	STATE_LISBOA_E_VALE_DO_TEJO: 'Lisboa e Vale do Tejo',
	STATE_MADEIRA: 'Madeira',
	STATE_NORTE: 'Norte',
	STATE_PAIVAS: 'Paivas',
	STATE_ARECIBO: 'Arecibo',
	STATE_BAYAMON: 'Bayamon',
	STATE_CAROLINA: 'Carolina',
	STATE_FLORIDA: 'Flórida',
	STATE_GUAYAMA: 'Guayama',
	STATE_HUMACAO: 'Humacao',
	STATE_MAYAGUEZ_AGUADILLA: 'Mayaguez-Aguadilla',
	STATE_PONCE: 'Ponce',
	STATE_SALINAS: 'Salinas',
	STATE_DOHA: 'Doha',
	STATE_JARIAN_AL_BATNAH: 'Jarian-al-Batnah',
	STATE_UMM_SALAL: 'Umm Salal',
	STATE_AD_DAWHAH: 'ad-Dawhah',
	STATE_AL_GHUWAYRIYAH: 'al-Ghuwayriyah',
	STATE_AL_JUMAYLIYAH: 'al-Jumayliyah',
	STATE_AL_KHAWR: 'al-Khawr',
	STATE_AL_WAKRAH: 'al-Wakrah',
	STATE_AR_RAYYAN: 'ar-Rayyan',
	STATE_SAINT_BENOIT: 'Saint-Benoit',
	STATE_SAINT_DENIS: 'Saint-Denis',
	STATE_ALBA: 'Alba',
	STATE_ARAD: 'Arad',
	STATE_ARGES: 'Arges',
	STATE_BACAU: 'Bacau',
	STATE_BIHOR: 'Bihor',
	STATE_BISTRITA_NASAUD: 'Bistrita-Nasaud',
	STATE_BOTOSANI: 'Botosani',
	STATE_BRAILA: 'Braila',
	STATE_BRASOV: 'Brasov',
	STATE_BUCURESTI: 'Bucuresti',
	STATE_BUZAU: 'Buzau',
	STATE_CALARASI: 'Calarasi',
	STATE_CARAS_SEVERIN: 'Caras-Severin',
	STATE_CLUJ: 'Cluj',
	STATE_CONSTANTA: 'Constanta',
	STATE_COVASNA: 'Covasna',
	STATE_DAMBOVITA: 'Dambovita',
	STATE_DOLJ: 'Dolj',
	STATE_GALATI: 'Galati',
	STATE_GIURGIU: 'Giurgiu',
	STATE_GORJ: 'Gorj',
	STATE_HARGHITA: 'Harghita',
	STATE_HUNEDOARA: 'Hunedoara',
	STATE_IALOMITA: 'Ialomita',
	STATE_IASI: 'Iasi',
	STATE_ILFOV: 'Ilfov',
	STATE_MARAMURES: 'Maramures',
	STATE_MEHEDINTI: 'Mehedinti',
	STATE_MURES: 'Mures',
	STATE_NEAMT: 'Neamt',
	STATE_OLT: 'Olt',
	STATE_PRAHOVA: 'Prahova',
	STATE_SALAJ: 'Salaj',
	STATE_SATU_MARE: 'Satu Mare',
	STATE_SIBIU: 'Sibiu',
	STATE_SONDELOR: 'Sondelor',
	STATE_SUCEAVA: 'Suceava',
	STATE_TELEORMAN: 'Teleorman',
	STATE_TIMIS: 'Timis',
	STATE_TULCEA: 'Tulcea',
	STATE_VALCEA: 'Valcea',
	STATE_VASLUI: 'Vaslui',
	STATE_VRANCEA: 'Vrancea',
	STATE_ADYGEJA: 'Adygeja',
	STATE_AGA: 'Aga',
	STATE_ALANIJA: 'Alanija',
	STATE_ALTAJ: 'Altaj',
	STATE_AMUR: 'Amur',
	STATE_ARHANGELSK: 'Arhangelsk',
	STATE_ASTRAHAN: 'Astrahan',
	STATE_BASHKORTOSTAN: 'Bashkortostan',
	STATE_BELGOROD: 'Belgorod',
	STATE_BRJANSK: 'Brjansk',
	STATE_BURJATIJA: 'Burjatija',
	STATE_CHECHENIJA: 'Chechênia',
	STATE_CHELJABINSK: 'Cheljabinsk',
	STATE_CHITA: 'Chita',
	STATE_CHUKOTKA: 'Chukotka',
	STATE_CHUVASHIJA: 'Chuvashija',
	STATE_DAGESTAN: 'Daguestão',
	STATE_EVENKIJA: 'Evenkija',
	STATE_GORNO_ALTAJ: 'Gorno-Altaj',
	STATE_HABAROVSK: 'Habarovsk',
	STATE_HAKASIJA: 'Hakasija',
	STATE_HANTY_MANSIJA: 'Hanty-Mansija',
	STATE_INGUSETIJA: 'Ingusetija',
	STATE_IRKUTSK: 'Irkutsk',
	STATE_IVANOVO: 'Ivanovo',
	STATE_JAMALO_NENETS: 'Jamalo-Nenets',
	STATE_JAROSLAVL: 'Jaroslavl',
	STATE_JEVREJ: 'Jevrej',
	STATE_KABARDINO_BALKARIJA: 'Kabardino-Balkarija',
	STATE_KALININGRAD: 'Kaliningrad',
	STATE_KALMYKIJA: 'Kalmykija',
	STATE_KALUGA: 'Kaluga',
	STATE_KAMCHATKA: 'Kamchatka',
	STATE_KARACHAJ_CHERKESSIJA: 'Karachaj-Cherkessija',
	STATE_KARELIJA: 'Karelija',
	STATE_KEMEROVO: 'Kemerovo',
	STATE_KHABAROVSKIY_KRAY: 'Khabarovskiy Kray',
	STATE_KIROV: 'Kirov',
	STATE_KOMI: 'Komi',
	STATE_KOMI_PERMJAKIJA: 'Komi-Permjakija',
	STATE_KORJAKIJA: 'Korjakija',
	STATE_KOSTROMA: 'Kostroma',
	STATE_KRASNODAR: 'Krasnodar',
	STATE_KRASNOJARSK: 'Krasnojarsk',
	STATE_KRASNOYARSKIY_KRAY: 'Krasnoyarskiy Kray',
	STATE_KURGAN: 'Kurgan',
	STATE_KURSK: 'Kursk',
	STATE_LENINGRAD: 'Leningrado',
	STATE_LIPECK: 'Lipeck',
	STATE_MAGADAN: 'Magadan',
	STATE_MARIJ_EL: 'Marij El',
	STATE_MORDOVIJA: 'Mordovija',
	STATE_MOSCOW: 'Moscou',
	STATE_MOSKOVSKAJA_OBLAST: 'Moskovskaja Oblast',
	STATE_MOSKOVSKAYA_OBLAST: 'Moskovskaya Oblast',
	STATE_MOSKVA: 'Moscovo',
	STATE_MURMANSK: 'Murmansk',
	STATE_NENETS: 'Nenets',
	STATE_NIZHNIJ_NOVGOROD: 'Nizhnij Novgorod',
	STATE_NOVGOROD: 'Novgorod',
	STATE_NOVOKUSNEZK: 'Novokusnezk',
	STATE_NOVOSIBIRSK: 'Novosibirsk',
	STATE_OMSK: 'Omsk',
	STATE_ORENBURG: 'Orenburg',
	STATE_ORJOL: 'Orjol',
	STATE_PENZA: 'Penza',
	STATE_PERM: 'Perm',
	STATE_PRIMORJE: 'Primorje',
	STATE_PSKOV: 'Pskov',
	STATE_PSKOVSKAYA_OBLAST: 'Pskovskaya Oblast',
	STATE_RJAZAN: 'Rjazan',
	STATE_ROSTOV: 'Rostov',
	STATE_SAHA: 'Saha',
	STATE_SAHALIN: 'Sahalin',
	STATE_SAMARA: 'Samara',
	STATE_SAMARSKAYA: 'Samarskaya',
	STATE_SANKT_PETERBURG: 'São Petersburgo',
	STATE_SARATOV: 'Saratov',
	STATE_SMOLENSK: 'Smolensk',
	STATE_STAVROPOL: 'Stavropol',
	STATE_SVERDLOVSK: 'Sverdlovsk',
	STATE_TAJMYRIJA: 'Tajmyrija',
	STATE_TAMBOV: 'Tambov',
	STATE_TATARSTAN: 'Tatarstan',
	STATE_TJUMEN: 'Tjumen',
	STATE_TOMSK: 'Tomsk',
	STATE_TULA: 'Tula',
	STATE_TVER: 'Tver',
	STATE_TYVA: 'Tyva',
	STATE_UDMURTIJA: 'Udmurtija',
	STATE_ULJANOVSK: 'Uljanovsk',
	STATE_ULYANOVSKAYA_OBLAST: 'Ulyanovskaya Oblast',
	STATE_UST_ORDA: 'Ust-Orda',
	STATE_VLADIMIR: 'Vladimir',
	STATE_VOLGOGRAD: 'Volgograd',
	STATE_VOLOGDA: 'Vologda',
	STATE_VORONEZH: 'Voronezh',
	STATE_BUTARE: 'Butare',
	STATE_BYUMBA: 'Byumba',
	STATE_CYANGUGU: 'Cyangugu',
	STATE_GIKONGORO: 'Gikongoro',
	STATE_GISENYI: 'Gisenyi',
	STATE_GITARAMA: 'Gitarama',
	STATE_KIBUNGO: 'Kibungo',
	STATE_KIBUYE: 'Kibuye',
	STATE_KIGALI_NGALI: 'Kigali-ngali',
	STATE_RUHENGERI: 'Ruhengeri',
	STATE_ASCENSION: 'Ascensão',
	STATE_GOUGH_ISLAND: 'Ilha de Gonçalo Álvares',
	STATE_SAINT_HELENA: 'Santa Helena',
	STATE_TRISTAN_DA_CUNHA: 'Tristão da Cunha',
	STATE_CHRIST_CHURCH_NICHOLA_TOWN: 'Christ Church Nichola Town',
	STATE_SAINT_ANNE_SANDY_POINT: 'Saint Anne Sandy Point',
	STATE_SAINT_GEORGE_BASSETERRE: 'Saint George Basseterre',
	STATE_SAINT_GEORGE_GINGERLAND: 'Saint George Gingerland',
	STATE_SAINT_JAMES_WINDWARD: 'Saint James Windward',
	STATE_SAINT_JOHN_CAPESTERRE: 'Saint John Capesterre',
	STATE_SAINT_JOHN_FIGTREE: 'Saint John Figtree',
	STATE_SAINT_MARY_CAYON: 'Saint Mary Cayon',
	STATE_SAINT_PAUL_CAPESTERRE: 'Saint Paul Capesterre',
	STATE_SAINT_PAUL_CHARLESTOWN: 'Saint Paul Charlestown',
	STATE_SAINT_PETER_BASSETERRE: 'Saint Peter Basseterre',
	STATE_SAINT_THOMAS_LOWLAND: 'Saint Thomas Lowland',
	STATE_SAINT_THOMAS_MIDDLE_ISLAND: 'Saint Thomas Middle Island',
	STATE_TRINITY_PALMETTO_POINT: 'Trinity Palmetto Point',
	STATE_ANSE_LA_RAYE: 'Anse-la-Raye',
	STATE_CANARIES: 'Canárias',
	STATE_CASTRIES: 'Castries',
	STATE_CHOISEUL: 'Choiseul',
	STATE_DENNERY: 'Dennery',
	STATE_GROS_INLET: 'Gros Inlet',
	STATE_LABORIE: 'Laborie',
	STATE_MICOUD: 'Micoud',
	STATE_SOUFRIERE: 'Soufriere',
	STATE_VIEUX_FORT: 'Vieux Fort',
	STATE_MIQUELON_LANGLADE: 'Miquelon-Langlade',
	STATE_CHARLOTTE: 'Charlotte',
	STATE_GRENADINES: 'Granadinas',
	STATE_A__ANA: "A'ana",
	STATE_AIGA_I_LE_TAI: 'Aiga-i-le-Tai',
	STATE_ATUA: 'Atua',
	STATE_FA__ASALELEAGA: "Fa'asaleleaga",
	STATE_GAGA__EMAUGA: "Gaga'emauga",
	STATE_GAGAIFOMAUGA: 'Gagaifomauga',
	STATE_PALAULI: 'Palauli',
	STATE_SATUPA__ITEA: "Satupa'itea",
	STATE_TUAMASAGA: 'Tuamasaga',
	STATE_VA__A_O_FONOTI: "Va'a-o-Fonoti",
	STATE_VAISIGANO: 'Vaisigano',
	STATE_ACQUAVIVA: 'Acquaviva',
	STATE_BORGO_MAGGIORE: 'Borgo Maggiore',
	STATE_CHIESANUOVA: 'Chiesanuova',
	STATE_DOMAGNANO: 'Domagnano',
	STATE_FAETANO: 'Faetano',
	STATE_FIORENTINO: 'Fiorentino',
	STATE_MONTEGIARDINO: 'Montegiardino',
	STATE_SAN_MARINO: 'São Marino',
	STATE_SERRAVALLE: 'Serravalle',
	STATE_AGUA_GRANDE: 'Água Grande',
	STATE_CANTAGALO: 'Cantagalo',
	STATE_LEMBA: 'Lemba',
	STATE_LOBATA: 'Lobata',
	STATE_ME_ZOCHI: 'Me-Zochi',
	STATE_PAGUE: 'Pague',
	STATE_AL_KHOBAR: 'Al-Khobar',
	STATE_ASEER: 'Aseer',
	STATE_ASIR: 'Asir',
	STATE_CENTRAL_PROVINCE: 'Província Central',
	STATE_EASTERN_PROVINCE: 'Província Oriental',
	STATE_HA__IL: "Ha'il",
	STATE_JAWF: 'Jawf',
	STATE_JIZAN: 'Jizan',
	STATE_MAKKAH: 'Makkah',
	STATE_NAJRAN: 'Najran',
	STATE_QASIM: 'Qasim',
	STATE_TABUK: 'Tabuk',
	STATE_WESTERN_PROVINCE: 'Província Ocidental',
	STATE_AL_BAHAH: 'al-Bahah',
	STATE_AL_HUDUD_ASH_SHAMALIYAH: 'al-Hudud-ash-Shamaliyah',
	STATE_AL_MADINAH: 'al-Madinah',
	STATE_AR_RIYAD: 'ar-Riyad',
	STATE_DAKAR: 'Dakar',
	STATE_DIOURBEL: 'Diourbel',
	STATE_FATICK: 'Fatick',
	STATE_KAOLACK: 'Kaolack',
	STATE_KOLDA: 'Kolda',
	STATE_LOUGA: 'Louga',
	STATE_SAINT_LOUIS: 'Saint-Louis',
	STATE_TAMBACOUNDA: 'Tambacounda',
	STATE_THIES: 'Thies',
	STATE_ZIGUINCHOR: 'Ziguinchor',
	STATE_CENTRAL_SERBIA: 'Sérvia Central',
	STATE_KOSOVO_AND_METOHIJA: 'Kosovo e Metohija',
	STATE_VOJVODINA: 'Vojvodina',
	STATE_ANSE_BOILEAU: 'Anse Boileau',
	STATE_ANSE_ROYALE: 'Anse Royale',
	STATE_CASCADE: 'Cascade',
	STATE_TAKAMAKA: 'Takamaka',
	STATE_SINGAPORE: 'Cingapura',
	STATE_BANSKOBYSTRICKY: 'Banskobystricky',
	STATE_BRATISLAVSKY: 'Bratislavsky',
	STATE_KOSICKY: 'Kosicky',
	STATE_NITRIANSKY: 'Nitriansky',
	STATE_PRESOVSKY: 'Presovsky',
	STATE_TRENCIANSKY: 'Trenciansky',
	STATE_TRNAVSKY: 'Trnavsky',
	STATE_ZILINSKY: 'Zilinsky',
	STATE_BENEDIKT: 'Benedikt',
	STATE_GORENJSKA: 'Gorenjska',
	STATE_GORISHKA: 'Gorishka',
	STATE_JUGOVZHODNA_SLOVENIJA: 'Eslovênia do Sudeste ',
	STATE_KOROSHKA: 'Koroshka',
	STATE_NOTRANJSKO_KRASHKA: 'Notranjsko-krashka',
	STATE_OBALNO_KRASHKA: 'Obalno-krashka',
	STATE_OBCINA_DOMZALE: 'Obcina Domzale',
	STATE_OBCINA_VITANJE: 'Obcina Vitanje',
	STATE_OSREDNJESLOVENSKA: 'Osrednjeslovenska',
	STATE_PODRAVSKA: 'Podravska',
	STATE_POMURSKA: 'Pomurska',
	STATE_SAVINJSKA: 'Savinjska',
	STATE_SLOVENIAN_LITTORAL: 'Litoral Esloveno',
	STATE_SPODNJEPOSAVSKA: 'Spodnjeposavska',
	STATE_ZASAVSKA: 'Zasavska',
	STATE_GUADALCANAL: 'Guadalcanal',
	STATE_ISABEL: 'Isabel',
	STATE_MAKIRA_AND_ULAWA: 'Makira e Ulawa',
	STATE_MALAITA: 'Malaita',
	STATE_RENNELL_AND_BELLONA: 'Rennell e Bellona',
	STATE_TEMOTU: 'Temotu',
	STATE_AWDAL: 'Awdal',
	STATE_BAKOL: 'Bakol',
	STATE_BANADIR: 'Banadir',
	STATE_BAY: 'Bay',
	STATE_GALGUDUG: 'Galgudug',
	STATE_GEDO: 'Gedo',
	STATE_HIRAN: 'Hiran',
	STATE_JUBBADA_HOSE: 'Jubbada Hose',
	STATE_JUBBADHA_DEXE: 'Jubbadha Dexe',
	STATE_MUDUG: 'Mudug',
	STATE_NUGAL: 'Nugal',
	STATE_SANAG: 'Sanag',
	STATE_SHABELLAHA_DHEXE: 'Shabellaha Dhexe',
	STATE_SHABELLAHA_HOSE: 'Shabellaha Hose',
	STATE_TOGDHER: 'Togdher',
	STATE_WOQOYI_GALBED: 'Woqoyi Galbed',
	STATE_EASTERN_CAPE: 'Visayas Oriental',
	STATE_FREE_STATE: 'Free State',
	STATE_GAUTENG: 'Gauteng',
	STATE_KEMPTON_PARK: 'Kempton Park',
	STATE_KRAMERVILLE: 'Kramerville',
	STATE_KWAZULU_NATAL: 'KwaZulu Natal',
	STATE_LIMPOPO: 'Limpopo',
	STATE_MPUMALANGA: 'Mpumalanga',
	STATE_NORTH_WEST: 'Nordeste',
	STATE_NORTHERN_CAPE: 'Cabo Setentrional',
	STATE_PAROW: 'Parow',
	STATE_TABLE_VIEW: 'Table View',
	STATE_UMTENTWENI: 'Umtentweni',
	STATE_WESTERN_CAPE: 'Cabo Ocidental',
	STATE_CENTRAL_EQUATORIA: 'Equatória Central',
	STATE_A_CORUNA: 'A Coruna',
	STATE_ALACANT: 'Alacant',
	STATE_ALAVA: 'Alava',
	STATE_ALBACETE: 'Albacete',
	STATE_ALMERIA: 'Alméria',
	STATE_ANDALUCIA: 'Andaluzia',
	STATE_ASTURIAS: 'Astúrias',
	STATE_AVILA: 'Ávila',
	STATE_BADAJOZ: 'Badajoz',
	STATE_BALEARS: 'Balears',
	STATE_BARCELONA: 'Barcelona',
	STATE_BERTAMIRANS: 'Bertamirans',
	STATE_BISCAY: 'Biscaia',
	STATE_BURGOS: 'Burgos',
	STATE_CACERES: 'Cáceres',
	STATE_CADIZ: 'Cádiz',
	STATE_CANTABRIA: 'Cantábria',
	STATE_CASTELLO: 'Castello',
	STATE_CATALUNYA: 'Catalunha',
	STATE_CEUTA: 'Ceuta',
	STATE_CIUDAD_REAL: 'Cidade Real',
	STATE_COMUNIDAD_AUTONOMA_DE_CANARIAS: 'Comunidade Autônoma de Canárias',
	STATE_COMUNIDAD_AUTONOMA_DE_CATALUNA: 'Comunidade Autônoma de Catalunha',
	STATE_COMUNIDAD_AUTONOMA_DE_GALICIA: 'Comunidade Autônoma de Galícia',
	STATE_COMUNIDAD_AUTONOMA_DE_LAS_ISLA: 'Comunidade Autônoma de las Islas',
	STATE_COMUNIDAD_AUTONOMA_DEL_PRINCIP: 'Comunidad Autonoma del Princip',
	STATE_COMUNIDAD_VALENCIANA: 'ComunidadeValenciana',
	STATE_CUENCA: 'Cuenca',
	STATE_GIPUZKOA: 'Gipuzkoa',
	STATE_GIRONA: 'Girona',
	STATE_GUADALAJARA: 'Guadalajara',
	STATE_GUIPUZCOA: 'Guipuzcoa',
	STATE_HUELVA: 'Huelva',
	STATE_HUESCA: 'Huesca',
	STATE_JAEN: 'Jaen',
	STATE_LAS_PALMAS: 'Las Palmas',
	STATE_LERIDA: 'Lerida',
	STATE_LLEIDA: 'Lleida',
	STATE_LUGO: 'Lugo',
	STATE_MADRID: 'Madri',
	STATE_MALAGA: 'Málaga',
	STATE_MELILLA: 'Melilla',
	STATE_MURCIA: 'Múrcia',
	STATE_NAVARRA: 'Navarra',
	STATE_OURENSE: 'Ourense',
	STATE_PAIS_VASCO: 'País Basco',
	STATE_PALENCIA: 'Palência',
	STATE_PONTEVEDRA: 'Pontevedra',
	STATE_SALAMANCA: 'Salamanca',
	STATE_SANTA_CRUZ_DE_TENERIFE: 'Santa Cruz de Tenerife',
	STATE_SEGOVIA: 'Segóvia',
	STATE_SEVILLA: 'Sevilha',
	STATE_SORIA: 'Soria',
	STATE_TARRAGONA: 'Tarragona',
	STATE_TENERIFE: 'Tenerife',
	STATE_TERUEL: 'Teruel',
	STATE_VALENCIA: 'Valência',
	STATE_VALLADOLID: 'Valladolid',
	STATE_VIZCAYA: 'Vizcaya',
	STATE_ZAMORA: 'Zamora',
	STATE_ZARAGOZA: 'Zaragoza',
	STATE_AMPARAI: 'Amparai',
	STATE_ANURADHAPURAYA: 'Anuradhapuraya',
	STATE_BADULLA: 'Badulla',
	STATE_BORALESGAMUWA: 'Boralesgamuwa',
	STATE_COLOMBO: 'Colombo',
	STATE_GALLA: 'Galla',
	STATE_GAMPAHA: 'Gampaha',
	STATE_HAMBANTOTA: 'Hambantota',
	STATE_KALATURA: 'Kalatura',
	STATE_KEGALLA: 'Kegalle',
	STATE_KILINOCHCHI: 'Kilinochchi',
	STATE_KURUNEGALA: 'Kurunegala',
	STATE_MADAKALPUWA: 'Madakalpuwa',
	STATE_MAHA_NUWARA: 'Maha Nuwara',
	STATE_MALWANA: 'Malwana',
	STATE_MANNARAMA: 'Mannarama',
	STATE_MATALE: 'Matale',
	STATE_MATARA: 'Matara',
	STATE_MONARAGALA: 'Monaragala',
	STATE_MULLAITIVU: 'Mullaitivu',
	STATE_NORTH_EASTERN_PROVINCE: 'Província do Nordeste',
	STATE_NORTH_WESTERN_PROVINCE: 'Província do Noroeste',
	STATE_NUWARA_ELIYA: 'Nuwara Eliya',
	STATE_POLONNARUWA: 'Polonnaruwa',
	STATE_PUTTALAMA: 'Puttalama',
	STATE_RATNAPURAYA: 'Ratnapuraya',
	STATE_SOUTHERN_PROVINCE: 'Província do Sul',
	STATE_TIRIKUNAMALAYA: 'Tirikunamalaya',
	STATE_TUSCANY: 'Toscânia',
	STATE_VAVUNIYAWA: 'Vavuniyawa',
	STATE_YAPANAYA: 'Yapanaya',
	STATE_KADAWATHA: 'kadawatha',
	STATE_A__ALI_AN_NIL: "A'ali-an-Nil",
	STATE_BAHR_AL_JABAL: 'Bahr-al-Jabal',
	STATE_GHARB_BAHR_AL_GHAZAL: 'Gharb Bahr-al-Ghazal',
	STATE_GHARB_DARFUR: 'Gharb Darfur',
	STATE_GHARB_KURDUFAN: 'Gharb Kurdufan',
	STATE_GHARB_AL_ISTIWA__IYAH: "Gharb-al-Istiwa'iyah",
	STATE_JANUB_DARFUR: 'Janub Darfur',
	STATE_JANUB_KURDUFAN: 'Janub Kurdufan',
	STATE_JUNQALI: 'Junqali',
	STATE_KASSALA: 'Kassala',
	STATE_NAHR_AN_NIL: 'Nahr-an-Nil',
	STATE_SHAMAL_BAHR_AL_GHAZAL: 'Shamal Bahr-al-Ghazal',
	STATE_SHAMAL_DARFUR: 'Shamal Darfur',
	STATE_SHAMAL_KURDUFAN: 'Shamal Kurdufan',
	STATE_SHARQ_AL_ISTIWA__IYAH: "Sharq-al-Istiwa'iyah",
	STATE_SINNAR: 'Sinnar',
	STATE_WARAB: 'Warab',
  STATE_WILAYAT_AL_KHARTUM: 'Wilayat al Khartum',
	STATE_AL_BUHAYRAT: 'al-Buhayrat',
	STATE_AL_JAZIRAH: 'al-Jazirah',
	STATE_AL_KHARTUM: 'al-Khartum',
	STATE_AL_QADARIF: 'al-Qadarif',
	STATE_AL_WAHDAH: 'al-Wahdah',
	STATE_AN_NIL_AL_ABYAD: 'an-Nil-al-Abyad',
	STATE_AN_NIL_AL_AZRAQ: 'an-Nil-al-Azraq',
	STATE_ASH_SHAMALIYAH: 'ash-Shamaliyah',
	STATE_BROKOPONDO: 'Brokopondo',
	STATE_COMMEWIJNE: 'Commewijne',
	STATE_CORONIE: 'Coronie',
	STATE_MAROWIJNE: 'Marowijne',
	STATE_NICKERIE: 'Nickerie',
	STATE_PARAMARIBO: 'Paramaribo',
	STATE_SARAMACCA: 'Saramacca',
	STATE_WANICA: 'Wanica',
	STATE_SVALBARD: 'Svalbard',
	STATE_HHOHHO: 'Hhohho',
	STATE_LUBOMBO: 'Lubombo',
	STATE_MANZINI: 'Manzini',
	STATE_SHISELWENI: 'Shiselweni',
	STATE_ALVSBORGS_LAN: 'Alvsborgs Lan',
	STATE_ANGERMANLAND: 'Angermanland',
	STATE_BLEKINGE: 'Blekinge',
	STATE_BOHUSLAN: 'Bohuslan',
	STATE_DALARNA: 'Dalarna',
	STATE_GAVLEBORG: 'Gavleborg',
	STATE_GOTLAND: 'Gotland',
	STATE_HALLAND: 'Halland',
	STATE_JAMTLAND: 'Jamtland',
	STATE_JONKOPING: 'Jonkoping',
	STATE_KALMAR: 'Kalmar',
	STATE_KRISTIANSTADS: 'Kristianstads',
	STATE_KRONOBERG: 'Kronoberg',
	STATE_NORRBOTTEN: 'Norrbotten',
	STATE_OREBRO: 'Orebro',
	STATE_OSTERGOTLAND: 'Ostergotland',
	STATE_SALTSJO_BOO: 'Saltsjo-Boo',
	STATE_SKANE: 'Skane',
	STATE_SMALAND: 'Smaland',
	STATE_SODERMANLAND: 'Sodermanland',
	STATE_STOCKHOLM: 'Estocolmo',
	STATE_UPPSALA: 'Uppsala',
	STATE_VARMLAND: 'Varmland',
	STATE_VASTERBOTTEN: 'Vasterbotten',
	STATE_VASTERGOTLAND: 'Vastergotland',
	STATE_VASTERNORRLAND: 'Vasternorrland',
	STATE_VASTMANLAND: 'Vastmanland',
	STATE_VASTRA_GOTALAND: 'Vastra Gotaland',
	STATE_AARGAU: 'Aargau',
	STATE_APPENZELL_INNER_RHODEN: 'Appenzell Inner-Rhoden',
	STATE_APPENZELL_AUSSER_RHODEN: 'Appenzell-Ausser Rhoden',
	STATE_BASEL_LANDSCHAFT: 'Basel-Landschaft',
	STATE_BASEL_STADT: 'Basel-Stadt',
	STATE_BERN: 'Berna',
	STATE_CANTON_TICINO: 'Canton Ticino',
	STATE_FRIBOURG: 'Friburgo',
	STATE_GENEVE: 'Genebra',
	STATE_GLARUS: 'Glarus',
	STATE_GRAUBUNDEN: 'Graubunden',
	STATE_HEERBRUGG: 'Heerbrugg',
	STATE_KANTON_AARGAU: 'Kanton Aargau',
	STATE_LUZERN: 'Lucerna',
	STATE_MORBIO_INFERIORE: 'Morbio Inferiore',
	STATE_MUHEN: 'Muhen',
	STATE_NEUCHATEL: 'Neuchatel',
	STATE_NIDWALDEN: 'Nidwalden',
	STATE_OBWALDEN: 'Obwalden',
	STATE_SANKT_GALLEN: 'Sankt Gallen',
	STATE_SCHAFFHAUSEN: 'Schaffhausen',
	STATE_SCHWYZ: 'Schwyz',
	STATE_SOLOTHURN: 'Solothurn',
	STATE_THURGAU: 'Thurgau',
	STATE_TICINO: 'Ticino',
	STATE_URI: 'Uri',
	STATE_VALAIS: 'Valais',
	STATE_VAUD: 'Vaud',
	STATE_VAUFFELIN: 'Vauffelin',
	STATE_ZUG: 'Zug',
	STATE_ZURICH: 'Zurique',
	STATE_ALEPPO: 'Aleppo',
	STATE_DAR__A: "Dar'a",
	STATE_DAYR_AZ_ZAWR: 'Dayr-az-Zawr',
	STATE_DIMASHQ: 'Dimashq',
	STATE_HALAB: 'Halab',
	STATE_HAMAH: 'Hamah',
	STATE_HIMS: 'Hims',
	STATE_IDLIB: 'Idlib',
	STATE_MADINAT_DIMASHQ: 'Madinat Dimashq',
	STATE_TARTUS: 'Tartus',
	STATE_AL_HASAKAH: 'al-Hasakah',
	STATE_AL_LADHIQIYAH: 'al-Ladhiqiyah',
	STATE_AL_QUNAYTIRAH: 'al-Qunaytirah',
	STATE_AR_RAQQAH: 'ar-Raqqah',
	STATE_AS_SUWAYDA: 'as-Suwayda',
	STATE_CHANGHWA: 'Changhwa',
	STATE_CHIAYI_HSIEN: 'Chiayi Hsien',
	STATE_CHIAYI_SHIH: 'Chiayi Shih',
	STATE_EASTERN_TAIPEI: 'Taipé Oriental',
	STATE_HSINCHU_HSIEN: 'Hsinchu Hsien',
	STATE_HSINCHU_SHIH: 'Hsinchu Shih',
	STATE_HUALIEN: 'Hualien',
	STATE_ILAN: 'Ilan',
	STATE_KAOHSIUNG_HSIEN: 'Kaohsiung Hsien',
	STATE_KAOHSIUNG_SHIH: 'Kaohsiung Shih',
	STATE_KEELUNG_SHIH: 'Keelung Shih',
	STATE_KINMEN: 'Kinmen',
	STATE_MIAOLI: 'Miaoli',
	STATE_NANTOU: 'Nantou',
	STATE_NORTHERN_TAIWAN: 'Northern Taiwan',
	STATE_PENGHU: 'Penghu',
	STATE_PINGTUNG: 'Pingtung',
	STATE_TAICHUNG: 'Taichung',
	STATE_TAICHUNG_HSIEN: 'Taichung Hsien',
	STATE_TAICHUNG_SHIH: 'Taichung Shih',
	STATE_TAINAN_HSIEN: 'Tainan Hsien',
	STATE_TAINAN_SHIH: 'Tainan Shih',
	STATE_TAIPEI_HSIEN: 'Taipei Hsien',
	STATE_TAIPEI_SHIH___TAIPEI_HSIEN: 'Taipei Shih / Taipei Hsien',
	STATE_TAITUNG: 'Taitung',
	STATE_TAOYUAN: 'Taoyuan',
	STATE_YILAN: 'Yilan',
	STATE_YUN_LIN_HSIEN: 'Yun-Lin Hsien',
	STATE_YUNLIN: 'Yunlin',
	STATE_DUSHANBE: 'Dushanbe',
	STATE_GORNO_BADAKHSHAN: 'Badakhshan',
	STATE_KAROTEGIN: 'Karotegin',
	STATE_KHATLON: 'Khatlon',
	STATE_SUGHD: 'Sughd',
	STATE_ARUSHA: 'Arusha',
	STATE_DAR_ES_SALAAM: 'Dar es Salaam',
	STATE_DODOMA: 'Dodoma',
	STATE_IRINGA: 'Iringa',
	STATE_KAGERA: 'Kagera',
	STATE_KIGOMA: 'Kigoma',
	STATE_KILIMANJARO: 'Kilimanjaro',
	STATE_LINDI: 'Lindi',
	STATE_MARA: 'Mara',
	STATE_MBEYA: 'Mbeya',
	STATE_MOROGORO: 'Morogoro',
	STATE_MTWARA: 'Mtwara',
	STATE_PWANI: 'Pwani',
	STATE_RUKWA: 'Rukwa',
	STATE_RUVUMA: 'Ruvuma',
	STATE_SHINYANGA: 'Shinyanga',
	STATE_SINGIDA: 'Singida',
	STATE_TABORA: 'Tabora',
	STATE_TANGA: 'Tanga',
	STATE_ZANZIBAR_AND_PEMBA: 'Zanzibar e Pemba',
	STATE_AMNAT_CHAROEN: 'Amnat Charoen',
	STATE_ANG_THONG: 'Ang Thong',
	STATE_BANGKOK: 'Bangcoc',
	STATE_BURI_RAM: 'Buri Ram',
	STATE_CHACHOENGSAO: 'Chachoengsao',
	STATE_CHAI_NAT: 'Chai Nat',
	STATE_CHAIYAPHUM: 'Chaiyaphum',
	STATE_CHANGWAT_CHAIYAPHUM: 'Changwat Chaiyaphum',
	STATE_CHANTHABURI: 'Chanthaburi',
	STATE_CHIANG_MAI: 'Chiang Mai',
	STATE_CHIANG_RAI: 'Chiang Rai',
	STATE_CHON_BURI: 'Chon Buri',
	STATE_CHUMPHON: 'Chumphon',
	STATE_KALASIN: 'Kalasin',
	STATE_KAMPHAENG_PHET: 'Kamphaeng Phet',
	STATE_KANCHANABURI: 'Kanchanaburi',
	STATE_KHON_KAEN: 'Khon Kaen',
	STATE_KRABI: 'Krabi',
	STATE_KRUNG_THEP: 'Krung Thep',
	STATE_LAMPANG: 'Lampang',
	STATE_LAMPHUN: 'Lamphun',
	STATE_LOEI: 'Loei',
	STATE_LOP_BURI: 'Lop Buri',
	STATE_MAE_HONG_SON: 'Mae Hong Son',
	STATE_MAHA_SARAKHAM: 'Maha Sarakham',
	STATE_MUKDAHAN: 'Mukdahan',
	STATE_NAKHON_NAYOK: 'Nakhon Nayok',
	STATE_NAKHON_PATHOM: 'Nakhon Pathom',
	STATE_NAKHON_PHANOM: 'Nakhon Phanom',
	STATE_NAKHON_RATCHASIMA: 'Nakhon Ratchasima',
	STATE_NAKHON_SAWAN: 'Nakhon Sawan',
	STATE_NAKHON_SI_THAMMARAT: 'Nakhon Si Thammarat',
	STATE_NAN: 'Nan',
	STATE_NARATHIWAT: 'Narathiwat',
	STATE_NONG_BUA_LAM_PHU: 'Nong Bua Lam Phu',
	STATE_NONG_KHAI: 'Nong Khai',
	STATE_NONTHABURI: 'Nonthaburi',
	STATE_PATHUM_THANI: 'Pathum Thani',
	STATE_PATTANI: 'Pattani',
	STATE_PHANGNGA: 'Phangnga',
	STATE_PHATTHALUNG: 'Phatthalung',
	STATE_PHAYAO: 'Phayao',
	STATE_PHETCHABUN: 'Phetchabun',
	STATE_PHETCHABURI: 'Phetchaburi',
	STATE_PHICHIT: 'Phichit',
	STATE_PHITSANULOK: 'Phitsanulok',
	STATE_PHRA_NAKHON_SI_AYUTTHAYA: 'Phra Nakhon Si Ayutthaya',
	STATE_PHRAE: 'Phrae',
	STATE_PHUKET: 'Phuket',
	STATE_PRACHIN_BURI: 'Prachin Buri',
	STATE_PRACHUAP_KHIRI_KHAN: 'Prachuap Khiri Khan',
	STATE_RANONG: 'Ranong',
	STATE_RATCHABURI: 'Ratchaburi',
	STATE_RAYONG: 'Rayong',
	STATE_ROI_ET: 'Roi Et',
	STATE_SA_KAEO: 'Sa Kaeo',
	STATE_SAKON_NAKHON: 'Sakon Nakhon',
	STATE_SAMUT_PRAKAN: 'Samut Prakan',
	STATE_SAMUT_SAKHON: 'Samut Sakhon',
	STATE_SAMUT_SONGKHRAN: 'Samut Songkhran',
	STATE_SARABURI: 'Saraburi',
	STATE_SATUN: 'Satun',
	STATE_SI_SA_KET: 'Si Sa Ket',
	STATE_SING_BURI: 'Sing Buri',
	STATE_SONGKHLA: 'Songkhla',
	STATE_SUKHOTHAI: 'Sukhothai',
	STATE_SUPHAN_BURI: 'Suphan Buri',
	STATE_SURAT_THANI: 'Surat thani',
	STATE_SURIN: 'Surin',
	STATE_TAK: 'Tak',
	STATE_TRANG: 'Trang',
	STATE_TRAT: 'Trat',
	STATE_UBON_RATCHATHANI: 'Ubon Ratchathani',
	STATE_UDON_THANI: 'Udon Thani',
	STATE_UTHAI_THANI: 'Uthai Thani',
	STATE_UTTARADIT: 'Uttaradit',
	STATE_YALA: 'Yala',
	STATE_YASOTHON: 'Yasothon',
	STATE_KARA: 'Kara',
	STATE_MARITIME: 'Maritime',
	STATE_ATAFU: 'Atafu',
	STATE_FAKAOFO: 'Fakaofo',
	STATE_NUKUNONU: 'Nukunonu',
	STATE_EUA: 'Eua',
	STATE_HA__APAI: "Ha'apai",
	STATE_NIUAS: 'Niuas',
	STATE_TONGATAPU: 'Tongatapu',
	STATE_VAVA__U: "Vava'u",
	STATE_ARIMA_TUNAPUNA_PIARCO: 'Arima-Tunapuna-Piarco',
	STATE_CARONI: 'Caroni',
	STATE_CHAGUANAS: 'Chaguanas',
	STATE_COUVA_TABAQUITE_TALPARO: 'Couva-Tabaquite-Talparo',
	STATE_DIEGO_MARTIN: 'Diego Martin',
	STATE_GLENCOE: 'Glencoe',
	STATE_PENAL_DEBE: 'Penal Debe',
	STATE_POINT_FORTIN: 'Point Fortin',
	STATE_PORT_OF_SPAIN: 'Porto da Espanha',
	STATE_PRINCES_TOWN: 'Princes Town',
	STATE_SAN_FERNANDO: 'San Fernando',
	STATE_SANGRE_GRANDE: 'Sangre Grande',
	STATE_SIPARIA: 'Siparia',
	STATE_TOBAGO: 'Tobago',
	STATE_ARYANAH: 'Aryanah',
	STATE_BAJAH: 'Bajah',
	STATE_BIN___ARUS: "Bin 'Arus",
	STATE_BINZART: 'Binzart',
	STATE_GOUVERNORAT_DE_ARIANA: 'Gouvernorat de Ariana',
	STATE_GOUVERNORAT_DE_NABEUL: 'Gouvernorat de Nabeul',
	STATE_GOUVERNORAT_DE_SOUSSE: 'Gouvernorat de Sousse',
	STATE_HAMMAMET_YASMINE: 'Hammamet Yasmine',
	STATE_JUNDUBAH: 'Jundubah',
	STATE_MADANIYIN: 'Madaniyin',
	STATE_MANUBAH: 'Manubah',
	STATE_MONASTIR: 'Monastir',
	STATE_NABUL: 'Nabul',
	STATE_QABIS: 'Qabis',
	STATE_QAFSAH: 'Qafsah',
	STATE_QIBILI: 'Qibili',
	STATE_SAFAQIS: 'Safaqis',
	STATE_SFAX: 'Sfax',
	STATE_SIDI_BU_ZAYD: 'Sidi Bu Zayd',
	STATE_SILYANAH: 'Silyanah',
	STATE_SUSAH: 'Susah',
	STATE_TATAWIN: 'Tatawin',
	STATE_TAWZAR: 'Tawzar',
	STATE_TUNIS: 'Tunísia',
	STATE_ZAGHWAN: 'Zaghwan',
	STATE_AL_KAF: 'al-Kaf',
	STATE_AL_MAHDIYAH: 'al-Mahdiyah',
	STATE_AL_MUNASTIR: 'al-Munastir',
	STATE_AL_QASRAYN: 'al-Qasrayn',
	STATE_AL_QAYRAWAN: 'al-Qayrawan',
	STATE_ADANA: 'Adana',
	STATE_ADIYAMAN: 'Adiyaman',
	STATE_AFYON: 'Afyon',
	STATE_AGRI: 'Agri',
	STATE_AKSARAY: 'Aksaray',
	STATE_AMASYA: 'Amasya',
	STATE_ANKARA: 'Ankara',
	STATE_ANTALYA: 'Antalya',
	STATE_ARDAHAN: 'Ardahan',
	STATE_ARTVIN: 'Artvin',
	STATE_AYDIN: 'Aydin',
	STATE_BALIKESIR: 'Balikesir',
	STATE_BARTIN: 'Bartin',
	STATE_BATMAN: 'Batman',
	STATE_BAYBURT: 'Bayburt',
	STATE_BILECIK: 'Bilecik',
	STATE_BINGOL: 'Bingol',
	STATE_BITLIS: 'Bitlis',
	STATE_BOLU: 'Bolu',
	STATE_BURDUR: 'Burdur',
	STATE_BURSA: 'Bursa',
	STATE_CANAKKALE: 'Canakkale',
	STATE_CANKIRI: 'Cankiri',
	STATE_CORUM: 'Corum',
	STATE_DENIZLI: 'Denizli',
	STATE_DIYARBAKIR: 'Diyarbakir',
	STATE_DUZCE: 'Duzce',
	STATE_EDIRNE: 'Edirne',
	STATE_ELAZIG: 'Elazig',
	STATE_ERZINCAN: 'Erzincan',
	STATE_ERZURUM: 'Erzurum',
	STATE_ESKISEHIR: 'Eskisehir',
	STATE_GAZIANTEP: 'Gaziantep',
	STATE_GIRESUN: 'Giresun',
	STATE_GUMUSHANE: 'Gumushane',
	STATE_HAKKARI: 'Hakkari',
	STATE_HATAY: 'Hatay',
	STATE_ICEL: 'Icel',
	STATE_IGDIR: 'Igdir',
	STATE_ISPARTA: 'Isparta',
	STATE_ISTANBUL: 'Istambul',
	STATE_IZMIR: 'Izmir',
	STATE_KAHRAMANMARAS: 'Kahramanmaras',
	STATE_KARABUK: 'Karabuk',
	STATE_KARAMAN: 'Karaman',
	STATE_KARS: 'Kars',
	STATE_KARSIYAKA: 'Karsiyaka',
	STATE_KASTAMONU: 'Kastamonu',
	STATE_KAYSERI: 'Kayseri',
	STATE_KILIS: 'Kilis',
	STATE_KIRIKKALE: 'Kirikkale',
	STATE_KIRKLARELI: 'Kirklareli',
	STATE_KIRSEHIR: 'Kirsehir',
	STATE_KOCAELI: 'Kocaeli',
	STATE_KONYA: 'Konya',
	STATE_KUTAHYA: 'Kutahya',
	STATE_LEFKOSA: 'Lefkosa',
	STATE_MALATYA: 'Malatya',
	STATE_MANISA: 'Manisa',
	STATE_MARDIN: 'Mardin',
	STATE_MUGLA: 'Mugla',
	STATE_MUS: 'Mus',
	STATE_NEVSEHIR: 'Nevsehir',
	STATE_NIGDE: 'Nigde',
	STATE_ORDU: 'Ordu',
	STATE_OSMANIYE: 'Osmaniye',
	STATE_RIZE: 'Rize',
	STATE_SAKARYA: 'Sakarya',
	STATE_SAMSUN: 'Samsun',
	STATE_SANLIURFA: 'Sanliurfa',
	STATE_SIIRT: 'Siirt',
	STATE_SINOP: 'Sinop',
	STATE_SIRNAK: 'Sirnak',
	STATE_SIVAS: 'Sivas',
	STATE_TEKIRDAG: 'Tekirdag',
	STATE_TOKAT: 'Tokat',
	STATE_TRABZON: 'Trabzon',
	STATE_TUNCELI: 'Tunceli',
	STATE_USAK: 'Usak',
	STATE_VAN: 'Van',
	STATE_YALOVA: 'Yalova',
	STATE_YOZGAT: 'Yozgat',
	STATE_ZONGULDAK: 'Zonguldak',
	STATE_AHAL: 'Ahal',
	STATE_ASGABAT: 'Asgabat',
	STATE_BALKAN: 'Balkan',
	STATE_DASOGUZ: 'Dasoguz',
	STATE_LEBAP: 'Lebap',
	STATE_MARI: 'Mari',
	STATE_GRAND_TURK: 'Grand Turk',
	STATE_SOUTH_CAICOS_AND_EAST_CAICOS: 'Caicos Sul e Caicos Leste',
	STATE_FUNAFUTI: 'Funafuti',
	STATE_NANUMANGA: 'Nanumanga',
	STATE_NANUMEA: 'Nanumea',
	STATE_NIUTAO: 'Niutao',
	STATE_NUI: 'Nui',
	STATE_NUKUFETAU: 'Nukufetau',
	STATE_NUKULAELAE: 'Nukulaelae',
	STATE_VAITUPU: 'Vaitupu',
	STATE_CHERKAS__KA: "Cherkas'ka",
	STATE_CHERNIHIVS__KA: "Chernihivs'ka",
	STATE_CHERNIVETS__KA: "Chernivets'ka",
	STATE_CRIMEA: 'Crimeia',
	STATE_DNIPROPETROVSKA: 'Dnipropetrovska',
	STATE_DONETS__KA: "Donets'ka",
	STATE_IVANO_FRANKIVS__KA: "Ivano-Frankivs'ka",
	STATE_KHARKIV: 'Kharkiv',
	STATE_KHARKOV: 'Kharkov',
	STATE_KHERSONSKA: 'Khersonska',
	STATE_KHMEL__NYTS__KA: "Khmel'nyts'ka",
	STATE_KIROVOHRAD: 'Kirovohrad',
	STATE_KRYM: 'Krym',
	STATE_KYYIV: 'Kyyiv',
	STATE_KYYIVS__KA: "Kyyiv'ka",
	STATE_L__VIVS__KA: "L'vivs'ka",
	STATE_LUHANS__KA: "Luhans'ka",
	STATE_MYKOLAYIVS__KA: "Mykolayivs'ka",
	STATE_ODES__KA: "Odes'ka",
	STATE_ODESSA: 'Odessa',
	STATE_POLTAVS__KA: "Poltavs'ka",
	STATE_RIVNENS__KA: "Rivnens'ka",
	STATE_SEVASTOPOL: 'Sevastopol',
	STATE_SUMS__KA: "Sums'ka",
	STATE_TERNOPIL__S__KA: "Ternopil's'ka",
	STATE_VOLYNS__KA: "Volyns'ka",
	STATE_VYNNYTS__KA: "Vynnyts'ka",
	STATE_ZAKARPATS__KA: "Zakarpats'ka",
	STATE_ZAPORIZHIA: 'Zaporizhia',
	STATE_ZHYTOMYRS__KA: "Zhytomyrs'ka",
	STATE_ABU_ZABI: 'Abu Zabi',
	STATE_AJMAN: 'Ajman',
	STATE_DUBAI: 'Dubai',
	STATE_RAS_AL_KHAYMAH: 'Ras al-Khaymah',
	STATE_SHARJAH: 'Sharjah',
	STATE_SHARJHA: 'Sharjha',
	STATE_UMM_AL_QAYWAYN: 'Umm al Qaywayn',
	STATE_AL_FUJAYRAH: 'al-Fujayrah',
	STATE_ASH_SHARIQAH: 'ash-Shariqah',
	STATE_ABERDEEN: 'Aberdeen',
	STATE_ABERDEENSHIRE: 'Aberdeenshire',
	STATE_ARGYLL: 'Argyll',
	STATE_BEDFORDSHIRE: 'Bedfordshire',
	STATE_BELFAST: 'Belfast',
	STATE_BERKSHIRE: 'Berkshire',
	STATE_BIRMINGHAM: 'Birmingham',
	STATE_BRECHIN: 'Brechin',
	STATE_BRIDGNORTH: 'Bridgnorth',
	STATE_BRISTOL: 'Bristol',
	STATE_BUCKINGHAMSHIRE: 'Buckinghamshire',
	STATE_CAMBRIDGE: 'Cambridge',
	STATE_CAMBRIDGESHIRE: 'Cambridgeshire',
	STATE_CHANNEL_ISLANDS: 'Ilhas do Canal',
	STATE_CHESHIRE: 'Cheshire',
	STATE_CLEVELAND: 'Cleveland',
	STATE_CO_FERMANAGH: 'Co. Fermanagh',
	STATE_CONWY: 'Conwy',
	STATE_CORNWALL: 'Cornualha',
	STATE_COVENTRY: 'Coventry',
	STATE_CRAVEN_ARMS: 'Craven Arms',
	STATE_CUMBRIA: 'Cumbria',
	STATE_DENBIGHSHIRE: 'Denbighshire',
	STATE_DERBY: 'Derby',
	STATE_DERBYSHIRE: 'Derbyshire',
	STATE_DEVON: 'Devon',
	STATE_DIAL_CODE_DUNGANNON: 'Código de área Dungannon',
	STATE_DIDCOT: 'Didcot',
	STATE_DORSET: 'Dorset',
	STATE_DUNBARTONSHIRE: 'Dunbartonshire',
	STATE_DURHAM: 'Durham',
	STATE_EAST_DUNBARTONSHIRE: 'East Dunbartonshire',
	STATE_EAST_LOTHIAN: 'East Lothian',
	STATE_EAST_MIDLANDS: 'East Midlands',
	STATE_EAST_SUSSEX: 'East Sussex',
	STATE_EAST_YORKSHIRE: 'East Yorkshire',
	STATE_ENGLAND: 'Inglaterra',
	STATE_ESSEX: 'Essex',
	STATE_FERMANAGH: 'Fermanagh',
	STATE_FIFE: 'Fife',
	STATE_FLINTSHIRE: 'Flintshire',
	STATE_FULHAM: 'Fulham',
	STATE_GAINSBOROUGH: 'Gainsborough',
	STATE_GLOCESTERSHIRE: 'Glocestershire',
	STATE_GWENT: 'Gwent',
	STATE_HAMPSHIRE: 'Hampshire',
	STATE_HANTS: 'Hants',
	STATE_HEREFORDSHIRE: 'Herefordshire',
	STATE_HERTFORDSHIRE: 'Hertfordshire',
	STATE_IRELAND: 'Irlanda',
	STATE_ISLE_OF_MAN: 'Ilha De Man',
	STATE_ISLE_OF_WIGHT: 'Ilha de Wight',
	STATE_KENFORD: 'Kenford',
	STATE_KENT: 'Kent',
	STATE_KILMARNOCK: 'Kilmarnock',
	STATE_LANARKSHIRE: 'Lanarkshire',
	STATE_LANCASHIRE: 'Lancashire',
	STATE_LEICESTERSHIRE: 'Leicestershire',
	STATE_LINCOLNSHIRE: 'Lincolnshire',
	STATE_LLANYMYNECH: 'Llanymynech',
	STATE_LONDON: 'Londres',
	STATE_LUDLOW: 'Ludlow',
	STATE_MAYFAIR: 'Mayfair',
	STATE_MERSEYSIDE: 'Merseyside',
	STATE_MID_GLAMORGAN: 'Mid Glamorgan',
	TATE_MIDDLESEX: 'Middlesex',
	STATE_MILDENHALL: 'Mildenhall',
	STATE_MONMOUTHSHIRE: 'Monmouthshire',
	STATE_NEWTON_STEWART: 'Newton Stewart',
	STATE_NORFOLK: 'Norfolk',
	STATE_NORTH_HUMBERSIDE: 'North Humberside',
	STATE_NORTH_YORKSHIRE: 'North Yorkshire',
	STATE_NORTHAMPTONSHIRE: 'Northamptonshire',
	STATE_NORTHANTS: 'Northants',
	STATE_NORTHERN_IRELAND: 'Irlanda do Norte',
	STATE_NORTHUMBERLAND: 'Northumberland',
	STATE_NOTTINGHAMSHIRE: 'Nottinghamshire',
	STATE_OXFORD: 'Oxford',
	STATE_POWYS: 'Powys',
	STATE_ROOS_SHIRE: 'Roos-shire',
	STATE_SUSSEX: 'SUSSEX',
	STATE_SCOTLAND: 'Escócia',
	STATE_SCOTTISH_BORDERS: 'Fronteiras Escocesas',
	STATE_SHROPSHIRE: 'Shropshire',
	STATE_SOMERSET: 'Somerset',
	STATE_SOUTH_GLAMORGAN: 'South Glamorgan',
	STATE_SOUTH_WALES: 'Gales do Sul',
	STATE_SOUTH_YORKSHIRE: 'South Yorkshire',
	STATE_SOUTHWELL: 'Southwell',
	STATE_STAFFORDSHIRE: 'Staffordshire',
	STATE_STRABANE: 'Strabane',
	STATE_SUFFOLK: 'Suffolk',
	STATE_SURREY: 'Surrey',
	STATE_TWICKENHAM: 'Twickenham',
	STATE_TYNE_AND_WEAR: 'Tyne and Wear',
	STATE_TYRONE: 'Tyrone',
	STATE_UTAH: 'Utah',
	STATE_WALES: 'Gales',
	STATE_WARWICKSHIRE: 'Warwickshire',
	STATE_WEST_LOTHIAN: 'West Lothian',
	STATE_WEST_MIDLANDS: 'West Midlands',
	STATE_WEST_SUSSEX: 'West Sussex',
	STATE_WEST_YORKSHIRE: 'West Yorkshire',
	STATE_WHISSENDINE: 'Whissendine',
	STATE_WILTSHIRE: 'Wiltshire',
	STATE_WOKINGHAM: 'Wokingham',
	STATE_WORCESTERSHIRE: 'Worcestershire',
	STATE_WREXHAM: 'Wrexham',
	STATE_WURTTEMBERG: 'Wurttemberg',
	STATE_YORKSHIRE: 'Yorkshire',
	STATE_CALIFORNIA: 'Califórnia',
	STATE_COLORADO: 'Colorado',
	STATE_CONNECTICUT: 'Connecticut',
	STATE_DELAWARE: 'Delaware',
	STATE_DISTRICT_OF_COLUMBIA: 'Distrito de Colúmbia',
	STATE_HAWAII: 'Havaí',
	STATE_IDAHO: 'Idaho',
	STATE_ILLINOIS: 'Illinois',
	STATE_INDIANA: 'Indiana',
	STATE_IOWA: 'Iowa',
	STATE_KANSAS: 'Kansas',
	STATE_KENTUCKY: 'Kentucky',
	STATE_LOUISIANA: 'Louisiana',
	STATE_MAINE: 'Maine',
	STATE_MARYLAND: 'Maryland',
	STATE_MASSACHUSETTS: 'Massachusetts',
	STATE_MICHIGAN: 'Michigan',
	STATE_MINNESOTA: 'Minnesota',
	STATE_MISSISSIPPI: 'Mississippi',
	STATE_MISSOURI: 'Missouri',
	STATE_NEBRASKA: 'Nebraska',
	STATE_NEVADA: 'Nevada',
	STATE_NEW_HAMPSHIRE: 'New Hampshire',
	STATE_NEW_JERSEY: 'Nova Jersey',
	STATE_NEW_MEXICO: 'Novo México',
	STATE_NORTH_CAROLINA: 'Carolina do Norte',
	STATE_NORTH_DAKOTA: 'Dakota do Norte',
	STATE_OHIO: 'Ohio',
	STATE_OKLAHOMA: 'Oklahoma',
	STATE_OREGON: 'Oregon',
	STATE_PENNSYLVANIA: 'Pensilvânia',
	STATE_RHODE_ISLAND: 'Rhode Island',
	STATE_SOUTH_CAROLINA: 'Carolina do Sul',
	STATE_SOUTH_DAKOTA: 'Dakota do Sul',
	STATE_TENNESSEE: 'Tennessee',
	STATE_TEXAS: 'Texas',
	STATE_VIRGINIA: 'Virgínia',
	STATE_WEST_VIRGINIA: 'Virgínia Ocidental',
	STATE_WISCONSIN: 'Wisconsin',
	STATE_WYOMING: 'Wyoming',
	STATE_UNITED_STATES_MINOR_OUTLYING_I: 'Ilhas Menores Afastadas dos Estados Unidos',
	STATE_ARTIGAS: 'Artigas',
	STATE_CANELONES: 'Canelones',
	STATE_CERRO_LARGO: 'Cerro Largo',
	STATE_COLONIA: 'Colônia',
	STATE_DURAZNO: 'Durazno',
	STATE_FLORES: 'Flores',
	STATE_LAVALLEJA: 'Lavalleja',
	STATE_MALDONADO: 'Maldonado',
	STATE_MONTEVIDEO: 'Montevidéu',
	STATE_PAYSANDU: 'Paysandu',
	STATE_RIVERA: 'Rivera',
	STATE_ROCHA: 'Rocha',
	STATE_SALTO: 'Salto',
	STATE_SORIANO: 'Soriano',
	STATE_TACUAREMBO: 'Tacuarembo',
	STATE_TREINTA_Y_TRES: 'Treinta y Tres',
	STATE_ANDIJON: 'Andijon',
	STATE_BUHORO: 'Buhoro',
	STATE_CIZAH: 'Cizah',
	STATE_FARGONA: 'Fargona',
	STATE_HORAZM: 'Horazm',
	STATE_KASKADAR: 'Kaskadar',
	STATE_KORAKALPOGISTON: 'Korakalpogiston',
	STATE_NAMANGAN: 'Namangan',
	STATE_NAVOI: 'Navoi',
	STATE_SAMARKAND: 'Samarkand',
	STATE_SIRDARE: 'Sirdare',
	STATE_SURHONDAR: 'Surhondar',
	STATE_TOSKENT: 'Toskent',
	STATE_MALAMPA: 'Malampa',
	STATE_PENAMA: 'Penama',
	STATE_SANMA: 'Sanma',
	STATE_SHEFA: 'Shefa',
	STATE_TAFEA: 'Tafea',
	STATE_TORBA: 'Torba',
	STATE_VATICAN_CITY_STATE_HOLY_SEE_: 'Estado da Cidade do Vaticano (Santa Fé)',
	STATE_ANZOATEGUI: 'Anzoategui',
	STATE_APURE: 'Apure',
	STATE_ARAGUA: 'Aragua',
	STATE_BARINAS: 'Barinas',
	STATE_CARABOBO: 'Carabobo',
	STATE_COJEDES: 'Cojedes',
	STATE_DELTA_AMACURO: 'Delta Amacuro',
	STATE_FALCON: 'Falcon',
	STATE_GUARICO: 'Guarico',
	STATE_LARA: 'Lara',
	STATE_MERIDA: 'Merida',
	STATE_MIRANDA: 'Miranda',
	STATE_MONAGAS: 'Monagas',
	STATE_NUEVA_ESPARTA: 'Nova Esparta',
	STATE_PORTUGUESA: 'Portuguesa',
	STATE_TACHIRA: 'Tachira',
	STATE_TRUJILLO: 'Trujillo',
	STATE_VARGAS: 'Vargas',
	STATE_YARACUY: 'Yaracuy',
	STATE_ZULIA: 'Zulia',
	STATE_BAC_GIANG: 'Bac Giang',
	STATE_BINH_DINH: 'Binh Dinh',
	STATE_BINH_DUONG: 'Binh Duong',
	STATE_DA_NANG: 'Da Nang',
	STATE_DONG_BANG_SONG_CUU_LONG: 'Dong Bang Song Cuu Long',
	STATE_DONG_BANG_SONG_HONG: 'Dong Bang Song Hong',
	STATE_DONG_NAI: 'Dong Nai',
	STATE_DONG_NAM_BO: 'Dong Nam Bo',
	STATE_DUYEN_HAI_MIEN_TRUNG: 'Duyen Hai Mien Trung',
	STATE_HANOI: 'Hanói',
	STATE_HUNG_YEN: 'Hung Yen',
	STATE_KHU_BON_CU: 'Khu Bon Cu',
	STATE_LONG_AN: 'Long An',
	STATE_MIEN_NUI_VA_TRUNG_DU: 'Mien Nui Va Trung Du',
	STATE_THAI_NGUYEN: 'Thai Nguyen',
	STATE_THANH_PHO_HO_CHI_MINH: 'Thanh Pho Ho Chi Minh',
	STATE_THU_DO_HA_NOI: 'Thu Do Ha Noi',
	STATE_TINH_CAN_THO: 'Tinh Can Tho',
	STATE_TINH_DA_NANG: 'Tinh Da Nang',
	STATE_TINH_GIA_LAI: 'Tinh Gia Lai',
	STATE_ANEGADA: 'Anegada',
	STATE_JOST_VAN_DYKE: 'Jost van Dyke',
	STATE_TORTOLA: 'Tortola',
	STATE_SAINT_CROIX: 'Saint Croix',
	STATE_ALO: 'Alo',
	STATE_SINGAVE: 'Singave',
	STATE_WALLIS: 'Wallis',
	STATE_BU_JAYDUR: 'Bu Jaydur',
	STATE_WAD_ADH_DHAHAB: 'Wad-adh-Dhahab',
	STATE_AL___AYUN: "al-'Ayun",
	STATE_AS_SAMARAH: 'as-Samarah',
	STATE_ADAN: 'Adan',
	STATE_ABYAN: 'Abyan',
	STATE_DHAMAR: 'Dhamar',
	STATE_HADRAMAUT: 'Hadramaut',
	STATE_HAJJAH: 'Hajjah',
	STATE_HUDAYDAH: 'Hudaydah',
	STATE_IBB: 'Ibb',
	STATE_LAHIJ: 'Lahij',
	STATE_MA__RIB: "Ma'rib",
	STATE_MADINAT_SAN__A: "Madinat San'a",
	STATE_SA__DAH: "Sa'dah",
	STATE_SANA: 'Sana',
	STATE_SHABWAH: 'Shabwah',
	STATE_TA__IZZ: "Ta'izz",
	STATE_AL_BAYDA: 'al-Bayda',
	STATE_AL_HUDAYDAH: 'al-Hudaydah',
	STATE_AL_JAWF: 'al-Jawf',
	STATE_AL_MAHRAH: 'al-Mahrah',
	STATE_AL_MAHWIT: 'al-Mahwit',
	STATE_COPPERBELT: 'Copperbelt',
	STATE_LUAPALA: 'Luapala',
	STATE_LUSAKA: 'Lusaka',
	STATE_NORTH_WESTERN: 'Noroeste',
	STATE_BULAWAYO: 'Bulawayo',
	STATE_HARARE: 'Harare',
	STATE_MANICALAND: 'Manicaland',
	STATE_MASHONALAND_CENTRAL: 'Mashonaland Central',
	STATE_MASHONALAND_EAST: 'Mashonaland East',
	STATE_MASHONALAND_WEST: 'Mashonaland West',
	STATE_MASVINGO: 'Masvingo',
	STATE_MATABELELAND_NORTH: 'Matabeleland North',
	STATE_MATABELELAND_SOUTH: 'Matabeleland South',
	STATE_MIDLANDS: 'Midlands',
	STATE_AARHUS: 'Aarhus'
};
