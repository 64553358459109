import onboardingLocalization from '../components/Onboarding/localization/en-US';
import ticketingLocalization from '../components/Ticketing/localization/en-US';
import supportLocalization from '../components/Support/localization/en-US';
import automationLocalization from '../components/Automation/localization/en-US';
import sharedLocalization from '../components/Shared/localization/en-US';
import analyticsLocalization from '../components/AnalyticsInvoices/localization/en-US';
import failoverLocalization from '../components/Failover/localization/en-US';
import reportsLocalization from '../components/Reports/localization/en-US';
import eulaLocalization from '../components/Eula/localization/en-US';
import accountOverviewLocalization from '../components/AccountOverview/localization/en-US';
import analyticsChartsLocalization from '../components/Analytics/localization/en-US';
import batchHistoryLocalization from '../components/BatchHistory/localization/en-US';
import errorLocalization from '../components/ErrorPage/localization/en-US';
import userFeedbackLocalization from '../components/UserFeedback/localization/en-US';
import swaggerLocalization from '../components/Swagger/localization/en-US';
import securityFeatures from '../components/SecurityFeatures/localization/en-US';
import releaseNotesLocalization from '../components/ReleaseNotes/localization/en-US';
import endpointsLocalization from '../components/Endpoint/localization/en-US';
import singleEndpointLocalization from '../components/SingleEndpoint/localization/en-US';
import automationZonesLocalization from '../components/AutomationZones/localization/en-US';

export default {
	...automationLocalization,
	...sharedLocalization,
	...analyticsLocalization,
	...supportLocalization,
	...onboardingLocalization,
	...ticketingLocalization,
	...failoverLocalization,
	...reportsLocalization,
	...accountOverviewLocalization,
	...eulaLocalization,
	...analyticsChartsLocalization,
	...batchHistoryLocalization,
	...errorLocalization,
	...userFeedbackLocalization,
	...swaggerLocalization,
	...securityFeatures,
	...endpointsLocalization,
	...releaseNotesLocalization,
	...singleEndpointLocalization,
	...automationZonesLocalization,
	// GLOBAL SETTINGS
	DISPLAY_DATE_FORMAT: 'MM/dd/yyyy',
	DATE_FORMAT_CODE: 'MM/dd/yyyy',

	// SIDEBAR
	HOME: 'Home',
	ENDPOINTS: 'Endpoints',
	Endpoints: 'Endpoints',
	REPORTS: 'Reports',
	INVOICES: 'Invoices',
	AUTOMATION: 'Automation',
	PLATFORM_STATUS: 'Platform Status',
	SUPPORT: 'Support',
	COLLAPSE_SIDEBAR: 'Collapse sidebar',
	COMPANIES: 'Companies',
	FAQ: 'Frequently Asked Questions',
	HELP_SUPPORT: 'Help & Support',
	BATCH_UPDATES: 'Batch Updates',
	FAQS: 'FAQs',
	RELEASE_NOTES: 'Release Notes',
	SYSTEM_NOTIFICATIONS: 'System Notifications',
	ACCOUNT_SETTINGS: 'Account Settings',
	COMPANY_ACCOUNTS: 'Company Account',
	CUSTOM_FIELDS: 'Custom Fields',
	COMPANY_DETAILS: 'Company Details',
	TESTING_ICON: 'Testing icon',
	ENTITIES: 'Entities',
	IOTGW_FAILOVER: 'IoTGW Failover',
	ACCESS_MANAGEMENT: 'Access Management',
	SIM_ORDERS: 'SIM Orders',
	USER_MANAGEMENT: 'User Management',

	// TOPBAR
	MORE_IOT: 'MORE IoT',
	MORE_ATT: 'MORE AT&T',
	LEARN_ABOUT_OFFERS: 'Learn About Offers',
	BUY_DEVICES: 'Buy Devices',
	ATT_SERVICES: 'AT&T Services',
	USE_TOOLS_APPS: 'Use Tools & Apps',
	IOT_PLATFORMS_PRODUCTS_APPS_AND_APIS: "IoT Platforms, Products, Apps and API's",
	AMOC: 'AMOC',
	M2X: 'M2X',
	FLOW_DESIGNER: 'Flow Designer',
	IOT_STARTER_KITS: 'IoT Starter Kits',
	IOT_DATA_PLANS: 'IoT Data Plans',
	ENTERPRISE: 'Enterprise',
	BUSINESS: 'Business',
	CONSUMER: 'Consumer',

	// PROFILE MENU
	PROFILE: 'Profile',
	APPS: 'Apps',
	LOGOUT: 'Logout',
	LOG_IN: 'Log in',

	// FOOTER
	IOT_CUSTOMER_SOLUTIONS: 'IoT customer solutions',
	IOT_PLATFORM_AND_SERVICES: 'IoT platform and services',
	NETWORK_SOLUTIONS: 'Network solutions',
	PARTNERSHIP_PROGRAM: 'Partnership program',
	BUSINESS_SUPPORT: 'Business support',
	ABOUT_ATT: 'About AT&T',
	ABOUT_ATT_IOT: 'About AT&T IoT',
	NEWS: 'News',
	NETWORKING_EXCHANGE_BLOG: 'Networking exchange blog',
	BUSINESS_COMMUNITY: 'Business community',
	YOUTUBE_CHANNEL: 'YouTube channel',
	LEGAL_PRIVACY_ADVERTISING_ACCESSIBILITY:
		'Legal, privacy, advertising, accessibility',
	LEGAL_POLICY_CENTER: 'Legal policy center',
	PRIVACY_POLICY: 'Privacy policy',
	TERMS_OF_USE: 'Terms of use',
	BROADBAND_DETAILS: 'Broadband details',
	YOUR_CHOICES: 'Your choices',
	ADVERTISING_CHOICES: 'Advertising choices',
	ACCESSIBILITY: 'Accessibility',
	COPYRIGHT_ATT_INTELECTUAL_PROPERTY: '©{year} AT&T Intellectual Property. ',
	COPYRIGHT_MESSAGE:
		'All rights reserved. AT&T, Globe logo, Mobilizing Your World, and DIRECTV are registered trademarks of AT&T intellectual Property and/or affiliated companies. All other marks are the property of their respective owners.',
	CONNECT_WITH_ATT: 'Connect with AT&T',
	CURRENT_SESSION_USAGE: 'Current session usage',
	ENDPOINT_NETWORK_AND_LOCATION: 'Endpoint network and location',

	// NETWORK ENTITLEMENTS
	ENDPOINT_DETAILS_SETUP: 'Endpoint details setup',
	CHANGE_NETWORK_ENTITLEMENTS: 'Change Network Entitlements',
	SCHEDULE_NETWORK_ENTITLEMENT_SUCCESSFUL:
		'Network entitlement for multiple SIMs is successfully scheduled',
	SIM_NETWORK_ENTITLEMENT_CHANGE_FOR: 'SIM network entitlement change for ',
	MULTIPLE_NETWORK_ENTITLEMENT_CHANGE_SCHEDULE_ERROR:
		'Multiple SIM network entitlement change could not be scheduled successfully',
	states: 'Status',
	networkOperators: 'Network Operator',
	countries: 'Network Country',
	SELECT_NETWORK_ENTITLEMENT: 'Select Network Entitlement',
	ACCOUNT_NAME: 'Account Name',
	APN: 'APN',
	DESCRIPTION: 'Description',
	ROAMING: 'Roaming',
	CREATE_DATE: 'Create Date',
	LAST_UPDATE_DATE: 'Last Update Date',
	APN_LTE: 'APN LTE',
	SMS_MT: 'SMS MT',
	SMS_MO: 'SMS MO',
	VOICE_MO: 'Voice MO',
	VOICE_MT: 'Voice MT',
	INTERNATIONAL_VOICE: 'International Voice',
	FIXED_IP_ENABLED: 'Fixed IP Enabled',
	LTE_DATA: 'LTE Data',
	LTE_ROAMING_RESTRICT_SET_ID: 'Roaming LTE',
	LTE_APNS: 'LTE APNs',
	CAMEL_SUBSCRIPTION_INFO: 'Camel',
	CAMEL_SERVICE_TYPE: 'Camel Service Type',
	M2M_PLATFORM: 'M2M Platform',
	APNS_ID: 'APN ID',
	APNS_NAME: 'Name',
	APNS_LTE: 'LTE',
	APNS_PDPINDEX: 'PDP Index',
	APNS_DESCRIPTION: 'APN Description',
	APNS_FIXEDIP: 'Fixed IP',
	APNS_SECURITY: 'Security',
	APNS_SUBSCRIPTION: 'Subscription',
	APNS_CREATEDATE: 'Create Date',
	APNS_LASTUPDATEDATE: 'Last Update Date',
	APNS_LASTUSER: 'Last user',
	APNS_APNTYPE: 'APN Type',
	APNS_CREATIONTIMESTAMP: 'Creation Timestamp',
	APNS_BMSYNCSTATUS: 'BM Sync Status',
	APNS_HLRPROFILEREFERENCE: 'HLRHSSDetails Profile Reference',
	APNS_IPADDRESSALLOCATIONTYPE: 'IP Address Allocation Type',
	APNS_ISLOCALBREAKOUTENABLEDINDICATOR: 'Is Local Breakout Enabled Indicator',
	APNS_LASTUPDATETIMESTAMP: 'Last Update Timestamp',
	APNS_OUCCONNECTIONTYPE: 'OUC Connection Type',
	APNS_SECURITYPROFILESUPPORTEDINDICATOR:
		'Security Profile Supported Indicator',
	APNS_STATUS: 'Status',
	APNS_VPNENABLEDINDICATOR: 'VPN Enabled Indicator',
	NETWORK_ENTITLEMENT_CHANGE_IS_SUCCESSFULLY_SUBMITTED:
		'Network Entitlement change is successfully submitted.',
	ONCE_YOU_INITIATE_THIS_CHANGE:
		'Once you initiate this change, the selected endpoint’s network entitlement will be ',
	FRIENDLY_NAME: 'Friendly name',
	NAME: 'Name',
	// Network Entitlement Details
	APN_DETAILS_ID: 'ID',
	APN_DETAILS_NAME: 'Name',
	APN_DETAILS_LTE: 'LTE',
	APN_DETAILS_PDPINDEX: 'PDP Index',
	APN_DETAILS_DESCRIPTION: 'Description',
	APN_DETAILS_FIXEDIP: 'Fixed IP',
	APN_DETAILS_SECURITY: 'Security',
	APN_DETAILS_SUBSCRIPTION: 'Subscription',

	// BUTTON LABELS
	CANCEL: 'Cancel',
	CHANGE: 'Change',
	TROUBLESHOOT: 'Troubleshoot',
	CHANGE_STATUS: 'Change status',
	CLOSE: 'Close',
	APPLY_CHANGE: 'Apply change',
	DONE: 'Done',
	SAVE: 'Save',
	CONTINUE: 'Continue',
	SAVEDASHBOARD: 'Save Dashboard',
	SAVE_AND_CONTINUE: 'Save and Continue',
	SAVE_CHANGES: 'Save Changes',
	PULL_DATA: 'Pull data',
	I_DO_NOT_AGREE: 'I do not agree',
	I_AGREE: 'I agree',
	LOADING: 'Loading',
	UPLOAD_LOGO: 'Upload logo',
	ADD_NEW_CUSTOMER: 'Add New Customer',
	EDIT: 'Edit',
	DELETE: 'Delete',
	NEXT: 'Next',

	// NOTIFICATIONS
	ENDPOINT_ID: 'Endpoint ID ',
	PROVISIONING_ERROR: 'Provisioning error',
	FAILED_TO_PROVISION_AFTER_IMSI_STATUS_CHANGE:
		' failed to provision after IMSI status change. ',
	GET_SUPPORT: 'Get support',
	PROVISIONING_SUCCESS: 'Provisioning success',
	IS_SUCCESSFULLY_PROVISIONED: ' is successfully provisioned. ',
	IMSI_STATUS_IS_CHANGED_TO: 'IMSI status is changed to ',
	PENDING_STATUS_CHANGE: 'Pending change!',
	STATUS_CHANGE_FOR: 'There is a Scheduled Activity for this Endpoint',
	IS_PENDING: ' is pending',
	CUSTOM_FIELD_EDIT_SUCCESS: 'Custom field edit success',
	CUSTOM_FIELD_EDIT_ERROR: 'Custom field edit error',
	CUSTOM_FIELD_VALUES_UPDATED:
		'One or more custom fields are successfully updated',
	CUSTOM_FIELD_VALUES_UPDATED_ERROR:
		'One or more custom fields could not be updated at this time. Please try again.',
	CUSTOM_FIELD_LABEL_SUCCESS_TITLE: 'Custom field label edit success!',
	CUSTOM_FIELD_LABEL_SUCCESS_MESSAGE:
		'One or more custom field labels are successfully updated.',
	CUSTOM_FIELD_LABEL_ERROR_TITLE: 'Custom field label edit error!',
	CUSTOM_FIELD_LABEL_ERROR_MESSAGE:
		'One or more custom field labels could not be updated at this time. Please try again.',
	TABLE_COLUMNS_CHANGE_SUCCESS_TITLE: 'Endpoint table settings change success!',
	TABLE_COLUMNS_CHANGE_SUCCESS_MESSAGE:
		'Endpoint table settings have been successfully updated.',
	TABLE_COLUMNS_CHANGE_ERROR_TITLE: 'Endpoint table columns change error!',
	TABLE_COLUMNS_CHANGE_ERROR_MESSAGE:
		'Endpoint table columns could not be updated this time. Please try again.',
	MC10515: 'Wrong file name',
	MC10516: 'File data error',
	MC10517: 'File does not contain header or the file is empty',
	DOWNLOAD_REPORT_ERROR_TITLE: 'Download Error',
	DOWNLOAD_REPORT_ERROR_MESSAGE: 'Error while trying to download your file',
	FETCH_REPORT_ERROR_TITLE: 'No data',
	FETCH_REPORT_ERROR_MESSAGE:
		'There is no data that matches search/filter request',
	MARK_READ: 'Mark read',
	VALIDATION_FAILED: 'Validation failed',
	ACTION_COMPLETED_SUCCESSFULLY: 'Action completed successfully',
	ACTION_COULD_NOT_BE_COMPLETED: 'This action could not be completed',

	// SINGLE ENDPOINT
	ENDPOINT_DETAILS: 'Endpoint details',
	DETAILS: 'Details',
	DIAGNOSTIC: 'Diagnostic',
	CHANGES: 'Changes',
	SESSIONS: 'Sessions',
	END_USER: 'End User',
	LIFE_CYCLE: 'Life Cycle',
	SEND_SMS: 'Send SMS',
	OTA: 'OTA',
	RATING: 'Rating',
	CARRIER_INFORMATION: 'Carrier Information',
	M2M_PLATFORM_STATUS_DESCRIPTION: 'M2M Platform Status Description',
	VIEW_NOTE: 'View Note',
	SE_NOTES: 'Notes',
	CHANGE_NOTES: 'Change Notes',
	ENTER_NOTE_HERE: 'Enter Note here (2000 Characters Max)',
	ADD_NOTE_BELOW: 'Add Note below: ',
	CHANGING_NOTES: 'Changing Notes',
	MULTIPLE_NOTES_CHANGE_ERROR:
		'Changing Notes for multiple Endpoints could not be initiated successfully',
	CHANGING_NOTES_NOT_ALLOWED: 'Changing Notes are not allowed',
	ENDPOINTS_SUCCESSFULLY_CHANGED: 'Endpoints is successfully changed',
	ENDPOINT_DETAILS_SECOND_CARD_NO_DATA:
		'The data for this endpoint is temporary unavailable',

	// DATA OVERVIEW
	ICCID: 'ICCID',
	IMSI: 'IMSI',
	NETWORK_OPERATOR: 'Network Operator',
	NETWORK_COUNTRY: 'Network Country',
	MSISDN: 'MSISDN',
	DUAL_MODE: 'Dual Mode',
	CONNECTED_DEVICE: 'Connected Device',
	IMEI: 'IMEI',
	STATUS: 'Status',
	STATUS_CHANGE_PENDING: 'There is a Scheduled Activity for this Endpoint',
	RATE_PLAN: 'Rate Plan',
	NETWORK_ENTITLEMENT: 'Network Entitlement',
	SOURCE_PLATFORM: 'M2M Platform',
	SERVICE_PROVIDER: 'M2M Platform',
	NETWORK_ENTITLEMENT_ID: 'Network Entitlement ID',

	// ENDPOINT STATUS
	CURRENT_ENDPOINT_STATUS: 'Current Endpoint Status',

	// Change SIM parameter modal
	CHANGE_SIM_PARAMETER: 'Change SIM parameter',
	SELECT_ATT_STATE: 'SELECT AT&T STATE',
	CURRENT: 'CURRENT',

	BY: 'By ',
	SCHEDULING: 'Scheduling...',

	// EDIT TABLE MODAL
	EDIT_TABLE_PARAMETERS: 'Edit Table Parameters',
	EDIT_CUSTOM_FIELD_LABELS: 'Edit custom field labels',
	CUSTOM_LABEL: 'Custom Label {labelIndex}',
	VALUE: 'Value',
	SET_AS_REQUIRED: 'Set as required',
	SELECT_A_MINIMUM_OF_4_TO_APPLY_TO_THE_TABLE:
		'Select a minimum of 2 to apply to the table. ',
	SELECTED: ' selected',
	CLEAR_ALL: 'Reset to Default',

	EOD: 'Enterprise on Demand (EOD)',
	ACC: 'AT&T Control Center (ACC)',
	GBCM: 'Speedcast',
	MC: 'IoT Console (IOT-C)',
	DCP: 'Device Connection Platform (DCP)',
	CCIP: 'Control Center Integration Program (CCIP)',
	AIS: 'AIS',
	TWM: 'Taiwan Mobile (TWM)',
	ATT: 'AT&T',
	AUTOMATION_ACC: 'ACC',
	AUTOMATION_DCP: 'DCP',
	// AT&T: "AT&T",
	WING: 'Nokia (WING)',
	VIVO: 'Vivo Control Center',

	eod: 'Enterprise on Demand (EOD)',
	acc: 'AT&T Control Center (ACC)',
	gbcm: 'Speedcast',
	mc: 'IoT Console (IOT-C)',
	dcp: 'Device Connection Platform (DCP)',
	ccip: 'Control Center Integration Program (CCIP)',
	ais: 'AIS',
	twm: 'Taiwan Mobile (TWM)',
	att: 'AT&T',
	// at&t: "AT&T",
	wing: 'Nokia (WING)',
	vivo: 'Vivo Control Center',

	SELECTED_FILTERS: 'Selected Filters',

	BY_WEEK: 'By week',
	BY_DAY: 'By day',
	BY_HOUR: 'By hour',
	BYTES_SENT: 'MB sent',
	BYTES_RECEIVED: 'MB received',
	TOTAL_MINS: 'Minutes total',
	monthly: 'Monthly',
	daily: 'Daily',
	MONTHLY: 'Monthly',
	DAILY: 'Daily',

	// ACTION BAR
	SHOWING: 'Showing ',
	OF: ' of ',

	// REPORTS
	TRANSFER: 'Transfer',
	USAGE: 'Usage',
	DOWNLOAD: 'Download',
	REPOST: 'Repost',
	REPOSTING: 'Reposting',
	OTHER: 'Other',

	// INPUT ERRORS
	REQUIRED: 'Required',
	INVALID_COMPANY_NAME: 'Invalid company name',
	INVALID_EMAIL_ADDRESS: 'Invalid email address',
	PHONE_MUST_BE_A_NUMBER: 'Phone must be a number',
	WRONG_PHONE_FORMAT: 'Wrong phone format',
	ONLY_LETTERS_AND_NUMBERS_ARE_ALLOWED: 'Only letters and numbers are allowed',
	ONLY_LETTERS_ARE_ALLOWED: 'Only letters are allowed',
	ONLY_LETTERS_AND_SPACES_ARE_ALLOWED: 'Only letters and spaces are allowed',
	ONLY_NUMBERS_ARE_ALLOWED: 'Only numbers are allowed',
	ONLY_POSITIVE_NUMBERS_ARE_ALLOWED: 'Only numbers greater than 0 are allowed',
	NUMBER_HAS_TO_BE_LESS_THAN: 'Number has to be less than {propName}',
	NUMBER_HAS_TO_BE_LESS_THAN_OR_EQUAL_TO:
		'Number has to be less than or equal to {propName}',
	NUMBER_HAS_TO_BE_GREATER_THAN: 'Number has to be greater than {propName}',
	NUMBER_HAS_TO_BE_GREATER_THAN_OR_EQUAL_TO:
		'Number has to be greater than or equal to {propName}',
	NUMBER_HAS_TO_BE_IN_INCREMENTAL_STEPS:
		'Number has to be in incremental steps of {increment}',
	INVALID_FORMAT: 'Invalid format',
	MAX_CHARACTERS_EXCEEDED: 'Character limit exceeded',
	MUST_BE_LESS_THAN_OR_EQUAL_TO_CHARACTERS:
		'Must be less than or equal to {max} characters',
	MUST_BE_CHARACTERS_LONG: 'Must be {number} characters long',
	NO_ONLY_WHITE_SPACE: 'Cannot be only white spaces',
	OUT_OF_RANGE: 'Out of range',
	NOT_BEFORE: 'Date is not allowed to be before {date}',
	INVALID_VERSION: 'Only major.minor.patch format allowed, ie. x.y.z',

	/* ------ OLD BUT STILL WORKING - DO NOT TOUCH - WORK IN PROGRESS --------- */
	ACCOUNT_OVERVIEW: 'Account Overview',
	ACCOUNT_OVERVIEW_ADMIN_DASHBOARD: 'Account Overview Admin Dashboard',
	BILLING: 'Billing',

	LOG_OUT: 'Log out',

	DATA_PLAN: 'Data plan',
	ACTIVESESSION: 'ACTIVE SESSION',
	IPADDRESS: 'IP ADDRESS',
	ANALYTICS: 'Analytics',
	NETWORK: 'NETWORK',

	ASSET_ID: 'Asset ID',
	SESSION_START: 'Session Start',
	DATA_VOLUME: 'Data Volume',
	DURATION: 'Duration (Min)',
	'DATE&TIME': 'Date & Time',
	NOTE: 'Note',
	LOGINTITLE: 'Log In',
	LOGINSUBTITLE: 'Please enter your email and password.',
	ENDPOINTS_INVENTORY_STATUS: 'ENDPOINTS INVENTORY STATUS',
	INVENTORY_BY_CARRIER: 'INVENTORY BY CARRIER',
	INVENTORY_BY_COUNTRY: 'INVENTORY BY COUNTRY',
	ACCOUNT_USERS: 'ACCOUNT USERS',
	USER_AUTOMATION_STATUS: 'USER AUTOMATION STATUS',
	AUTOMATION_PROCESS_STATUS: 'AUTOMATION PROCESS STATUS',
	NETWORK_TYPE: 'Network Type',
	DEVICE_NAME: 'Device Name',
	DEVICE_ID: 'Device ID',
	DEVICE_MODEL_NUMBER: 'Device Model #',
	ROAMING_RESTRICTED: 'ROAMING RESTRICTED',
	INVOICE_NUMBER: 'INVOICE NUMBER',
	DATE: 'Date',
	SCHEDULE_DATE: 'Date',
	ISSUED_BY: 'ISSUED BY',
	PAYMENT_STATUS: 'PAYMENT STATUS',
	REPORT_NAME: 'Report Name',
	DATE_CREATED: 'Date created',
	CREATED_BY: 'Created by',
	PAY_NOW: 'PAY NOW',
	EDIT_GRAPHS_VIEW: 'Edit Graphs view',
	EDIT_ENDPOINTS_LIST_VIEW: 'Edit Endpoints list view',
	CHANGE_LANGUAGE: 'Change language',
	CHANGE_LANGUAGE_ICON: 'EN',
	NOT_APPLICABLE_FOR_THIS_ENDPOINT: 'Not Applicable For This Endpoint',
	CREATE_NEW_RULE: 'Create new rule',
	EDIT_RULE: 'Edit rule',
	CREATE_NEW_RULE_EXP:
		'Automation allows you to perform batch actions on your account.',
	CREATE_NEW_RULE_ACTION:
		'Please select "Create new rule" button to create new automated action',
	RULE_NAME: 'Rule name',
	CATEGORY: 'Category',
	TRIGGER: 'Trigger',
	ACTION: 'ACTION',
	BULK_ACTIONS: 'Batch actions',
	MULTI_ENDPOINT_ACTIONS: 'Multi-Endpoint Actions',
	SET_ENDPOINT_STATE: 'Set endpoint state',
	CHANGE_DATA_PLAN: 'Change data plan',
	SWAP_OPERATOR: 'Swap operator',
	MULTI_EDIT: 'Multi edit',
	SEARCH_FOR_RULES: 'Search for rules',
	SEARCH_FOR_INVOICES: 'Search for invoices',
	SEARCH_FOR_REPORTS: 'Search report by name',
	SEARCH_ENDPOINTS: 'Search endpoints',
	PAYABLE_INVOICES: 'PAYABLE INVOICES',
	RECEIVED_PAYMENTS: 'RECEIVED PAYMENTS',
	PAYMENT_METHODS: 'PAYMENT METHODS',
	BILLERS: 'BILLERS',
	TIME_PERIOD: 'Time period',
	FROM_DATEPICKER: 'From',
	TO_DATEPICKER: 'To',
	ENDPOINT_UI_TOOLTIP: 'Identification number for Endpoint or device',
	OPERATOR_TOOLTIP: 'Operator the endpoint and profile belong to',
	NETWORK_TYPE_TOOLTIP: 'Operator connectivity technology',
	COUNTRY_TOOLTIP: 'Country where endpoint originated',
	ROAMING_RESTRICTED_TOOLTIP: 'If endpoint is restricted for roaming',
	STATUS_TOOLTIP: 'Current endpoint state',
	INITIATE_RULE: 'INITIATE RULE',
	NEW_AUTOMATION_RULE: 'NEW AUTOMATION RULE',
	ENDPOINT_PROVISIONING: 'ENDPOINT PROVISIONING',
	ENDPOINT_PROFILE: 'ENDPOINT PROFILE',
	ENDPOINT_SECURITY: 'ENDPOINT SECURITY',
	ENDPOINT_SUBSCRIPTION: 'ENDPOINT SUBSCRIPTION',
	NEW_RULE: 'NEW RULE',
	CONDITION: 'CONDITION',
	ENDPOINT_RANGE: 'Endpoint range is',
	RANGE_START: 'RANGE START',
	RANGE_END: 'RANGE END',
	ASSIGN_ENDPOINT: 'Assign endpoint to operator',
	OPERATOR_NAME: 'OPERATOR NAME',
	ACCOUNT: 'ACCOUNT',
	ADD_ACTION: 'ADD ACTION',
	RUN_IMMEDIATELY: 'RUN IMMEDIATELY',
	TIME: 'TIME',
	SELECT_DATE: 'SELECT DATE',
	SELECT_TIME: 'SELECT TIME',
	EVENT_TRIGGER: 'EVENT TRIGGER',
	EVENT_TYPE: 'EVENT TYPE',
	EVENT_LOCATION: 'EVENT LOCATION',
	DATA_PLAN_NAME: 'DATA PLAN NAME',
	BACK: 'Back',
	ENDPOINT_USAGE: 'Endpoint Usage',
	COUNTRY_AND_OPERATOR: 'Country and Operator',
	PAYMENTS_HISTORY: 'Payments history',
	SPENDING_THIS_YEAR: 'Spending this year',
	ENDPOINT_DATA: 'ENDPOINT DATA',
	CURRENT_SESSION: 'CURRENT SESSION',
	VIEW_LESS: 'View less',
	VIEW_MORE: 'View more',
	ENDPOINT_STATUS: 'Endpoint status',
	ACTIVATION_DATE: 'Activation date',
	PRIMARY_IMS: 'Primary IMS',

	SERVICE_PROVIDER_MODE: 'SERVICE PROVIDER MODE',
	SUPER_USER_MODE_MESSAGE: '{mode}: {companyFriendlyName}.',
	SUPER_USER_MODE_MOBILE: '{companyFriendlyName}',
	POLICY: 'Policy',
	COMMUNICATION_PLAN: 'Communication plan',
	COPY_RULE: 'Copy rule',
	SECURE_ENDPOINT_ID: 'Secure endpoint ID',
	CUSTOMER: 'Customer',
	INVENTORY_STATUS: 'INVENTORY STATUS',
	RESOLUTION_NOT_SUPPORTED_FIRST_LINE:
		'Small screen view is disabled in the demo.',
	RESOLUTION_NOT_SUPPORTED_SECOND_LINE:
		'For best result use Desktop Chrome browser maximized, or in full-screen mode',
	CHECKING_MOVE_ELIGIBILITY: 'CHECKING MOVE ELIGIBILITY',
	CHANGE_OPERATOR: 'CHANGE OPERATOR',
	SCHEDULE_CHANGE: 'SCHEDULE CHANGE',
	SCHEDULE_CHANGE_BUTTON: 'Schedule change',
	INITIATE_CHANGE: 'Initiate change',
	CONFIRM_CHANGE: 'Confirm change',
	CONFIRM_SIM_STATE_CHANGE: 'Confirm SIM state change',
	IN_SESSION: 'IN SESSION',
	ENTITLEMENTS: 'Entitlements',
	LOCALIZE_SERVICE: 'Localize Service',
	CHANGE_ENDPOINT_OPERATOR: 'CHANGE ENDPOINT OPERATOR',
	PENDING_CHANGES_ENDPOINT:
		'There are some pending changes for this endpoint, starting on ',
	SEE_HISTORY_FOR_DETAILS: 'See history below for details.',
	CHANGE_ENDPOINT_DATA_PLAN: 'Change endpoint data plan',
	IS_SELECTED: ' is selected.',
	PLEASE_SELECT_DIFFERENT_OPERATOR_TO_CHANGE_OPERATOR:
		'Please select different operator below to change operator:',
	GENERATE_REPORT: 'GENERATE REPORT',
	REPORT_TYPE: 'REPORT TYPE',
	DATA_PLAN_SUMMARIES: 'Data plan summaries',
	CHARGE_SUMMARIES: 'Charge summaries',
	INVOICE_REPORT: 'Invoice report',
	PRORATION_REPORT: 'Proration report',
	GROSS_ACTIVATIONS: 'Gross activations',
	MONTHLY_SUBSCRIBERS: 'Monthly subscribers',
	VIEW_YOUR_ACCOUT_SNAPSHOT: 'View your account snapshot.',
	LAUNCH_DASHBOARD: 'LAUNCH DASHBOARD',
	VISUAL_DASHBOARD_VIEW_EXP:
		'Visual dashboard view offers you a glimpse into the current status of your account and device activity.',
	VIEW_CURRENT_STATUS_AND_HISTORICAL_DATA:
		'View current status and historical data via timeline.',
	IF: 'IF',
	AND: 'AND',
	OR: 'OR',
	TO: 'TO',
	FROM: 'FROM',
	INVOICES_STATUS: 'INVOICES STATUS',
	filter_category_m2mAccount: 'M2mAccount',
	PLEASE_CONFIRM_ENDPOINT_DATA_CHANGE: 'Please confirm endpoint data change.',
	SET_NEW_OPERATOR_AS_ACTIVE: 'SET NEW OPERATOR AS ACTIVE',
	SET_NEW_PLAN_AS_ACTIVE: 'SET NEW PLAN AS ACTIVE',
	PLEASE_SELECT_DATE_TO_SET_UP_YOUR_CHANGES_AND_CONFIRM:
		'Please select date to set up your changes and confirm.',
	DATE_REQUIRED: 'Date (Required)',
	ADDITIONAL_COMMENT: 'Additional comment',
	TYPE_YOUR_CUSTOM_NOTES_HERE: 'Type your custom notes here…',
	CONFIRM_CHANGES: 'Confirm changes',
	CHECKING_MOVE_ELIGIBILITY_LOADER: 'Checking move eligibility...',
	ELIGIBLE: 'ELIGIBLE',
	CHECKING: 'CHECKING',
	PLEASE_SELECT_DIFFERENT_DATA_PLAN_TO_CHANGE_DATA_PLAN:
		'Please select different plan below to change data plan:',
	CONFIRM: 'Confirm',
	ENDPOINT_RANGE_IS: 'Endpoint range is:',
	ENDPOINT_ID_IS: 'Endpoint ID is:',
	ENDPOINT_ID_IS_GREATER_THAN: 'Endpoint ID is greater than:',
	ENDPOINT_ID_IS_LESS_THAN: 'Endpoint ID is less than:',
	ENDPOINT_STATE_CHANGE: 'Endpoint state change',
	ENDPOINT_DATA_PLAN: 'Endpoint data plan',
	ENDPOINT_SECURITY_POLICY_CHANGE: 'Endpoint security policy change',
	ASSIGN_ENDPOINT_TO_OPERATOR: 'Assign endpoint to operator',
	MOVE_TO_ANOTHER_ACCOUNT: 'Move to another account',
	ADD_CUSTOM_FIELD_TO_ENDPOINT_DETAILS: 'Add custom field to endpoint details',
	CREATE_NEW_ACTION: 'Create new action',
	AT_T: 'AT&T IoT (ACC)',
	AT_T_EOD: 'AT&T IoT (ACC)',
	GLOBECOMM: 'Speedcast',
	TAIWAN_MOBILE: 'Taiwan Mobile (TWM)',
	'0001': '***0001',
	'0002': '***0002',
	'0003': '***0003',
	'0004': '***0004',
	OTA_EVENT: 'OTA Event',
	WI_FI: 'Wi-Fi',
	THAILAND: 'Thailand (THA)',
	TAIWAN: 'Taiwan (TWN)',
	UNITED_STATES: 'United States (USA)',
	DATA_PLAN_S: 'Data Plan S',
	DATA_PLAN_M: 'Data Plan M',
	DATA_PLAN_L: 'Data Plan L',
	DATA_PLAN_XL: 'Data Plan XL',
	// TO DO: Update Endpoint statuses to correct values
	ACTIVATED: 'Activated',
	'TEST READY': 'Test ready',
	DEACTIVATED: 'Deactivated',
	PURGED: 'Purged',
	RETIRED: 'Retired',
	//
	USER: 'User',
	ON: 'on',
	MADE: 'made',
	CHANGE_STATUS_ACTION: 'Change status',
	CHANGE_DATA_PLAN_ACTION: 'Change data plan',
	LOCALIZE_SERVICE_ACTION: 'Localized service',
	IMSI_TRANSFER: 'IMSI Transfer Status',
	TRANSFER_STATUS: 'IMSI Transfer Status',
	COUNTRY_OPERATOR: 'Network Country',

	PROFILE_NAME: 'Profile Name',
	NUMBER: 'Number',
	DATE_MODIFIED: 'Date modified',

	ENTITLEMENT_PLAN_ID: 'Entitlement plan ID',
	ENTITLEMENT_PLAN_NAME: 'Entitlement plan name',
	ACCOUNT_ID: 'Account ID',
	ACCOUNT_I_D: 'Account ID',
	IMEI_NAME: 'IMEI name',
	VIN: 'VIN',
	CHIP_ID: 'Chip ID',
	URL_SP: 'url SP',
	ADD_SINGLE_ENDPOINT: 'Add single endpoint',
	UPLOAD_ICCID_BULK_FILE: 'Upload ICCID batch file',
	DOWNLOAD_BULK_FILE: 'Download batch file',
	ENDPOINTS_SELECTED: 'endpoints selected',
	SELECTED_ENDPOINTS: 'selected endpoints',
	NEW_STATUS: 'New status',
	MULTIPLE_ENDPOINT_EDIT: 'Multiple endpoint edit',
	CLEAR_VALUES_FOR_ALL_ENDPOINTS: 'Clear values for all endpoints',
	KEEP_ORIGINAL_ENDPOINT_VALUES: 'Keep original endpoint values',
	ACTIVE: 'Activated',
	INACTIVE: 'Inactive',
	TERMINATE: 'Terminated',
	TEST_READY: 'Test ready',
	TEST: 'Test',
	SELECT_OPTION_OR_TYPE_IN: 'Select option or type in',
	CLEAR_ALL_VALUES_FOR_ENDPOINTS: 'Clear all values for endpoints',
	BULK_STATUS_UPDATE: 'Batch status update',
	TOTAL_ENDPOINTS: 'Total endpoints',
	TOTAL_FILES: 'Total files',
	ENDPOINT_STATUS_UPDATED: 'Endpoint status updated',
	MODIFIED_ENDPOINTS: 'Modified endpoints',
	SOMETHING_WENT_WRONG: 'Something went wrong',
	ENDPOINT_STATUS_IS_UPDATING: 'Endpoint status is updating',
	PLEASE_WAIT: 'Please wait',
	SWAP_SIM: 'Swap SIM',
	SWAP_SIMS: 'Swap SIMS',
	SIM: 'SIM',
	NEW: 'New',
	OLD: 'Old',
	CHANGE_ORDER: 'Change order',
	ITEM: 'Item',
	ADDITIONAL_INFO: 'Additional info',
	UPDATE_LABEL: 'Update label',
	ENDPOINT_LABEL_IS_UPDATING: 'Endpoint label is updating',
	ENDPOINT_LABEL_UPDATED: 'Endpoint label updated',
	ENDPOINT_FIELDS_ARE_UPDATING: 'Endpoint fields are updating',
	ENDPOINT_FIELDS_UPDATED: 'Endpoint fields updated',
	EDIT_CUSTOM_FIELDS: 'Edit custom fields',
	EDIT_CUSTOM_LABEL: 'Edit custom label',
	CHANGE_ENTITLEMENT: 'Change network entitlement',
	CHANGE_NETWORK_ENTITLEMENT: 'Change Network Entitlement',
	CURRENT_NETWORK_ENTITLEMENT: 'Current Network Entitlement',
	NETWORK_ENTITLEMENT_UPDATED_SUCCESSFULLY:
		'Network Entitlement updated successfully!',
	LIST_VIEW_UPDATED: 'Endpoints list view updated',
	NETWORK_ENTITLEMENT_UPDATING: 'Network entitlement is updating. Please wait',
	LIST_VIEW_UPDATING: 'Endpoints list view is updating. Please wait',
	NEW_NETWORK_ENTITLEMENT: 'New Network Entitlement',
	NEW_NETWORK_ENTITLEMENT_DETAILS: 'New Network Entitlement Details',
	TABLE_COLUMNS: 'Choose number of columns to display on the table.',
	TABLE_COLUMNS_OR: 'Choose number of columns to display on the table or',
	COLUMN: 'Column',
	COLUMNS: 'Columns',
	SELECT: 'Select',
	DATA_TYPE: 'data type',
	ADVANCED_SEARCH: 'Advanced search',
	SEARCH_RESULTS: 'Search Results',
	SUBMIT: 'Submit',
	SEARCH_PARAMETER: 'Search parameter',
	ADD_ANOTHER_SEARCH_PARAMETER: 'Add another search parameter',
	STATUS_DATE_RANGE: 'Status date range',
	SIM_STATUS_STATE: 'SIM status (state)',
	NEW_PARAMETER_ACTION: 'And / Or',
	PLEASE_SELECT_VALUES_TO_FILTER_ENDPOINTS:
		'Select from the available parameters then enter the criteria to filter endpoints by.',
	RESET_SEARCH: 'Reset search',
	QUICK_SEARCH: 'Quick search',
	ORIGINAL_SETTINGS: 'Reset to original settings',
	MONTH: 'Month',
	DAY: 'Day',
	REFRESH_ENDPOINTS_PAGE: 'Refresh Endpoints Table/Page',
	CLEAR_SELECTED_FILTERS_TOOLTIP: 'Clear selected filters',
	SELECT_FILTERS_TOOLTIP: 'Select Filters',
	EDIT_TABLE_PARAMETERS_TOOLTIP: 'Edit Table Parameters',

  REPORT_DATE: 'Date',
  PROCESSED_ON: 'Processed On',

	START_NEW_SEARCH: 'Start new search',
	SUCCESS: 'Success',
	FAILED: 'Failed',
	RESULT_ACTION: 'Action',
	REPORT_DETAILS: 'Report details',
	REPORT_LIST: 'List of Reports in',
	VIEW_DETAILS: 'View details',
	DOWNLOAD_PDF: 'Download',
	FILE_NAME: 'File name',
	BATCH_FILES: 'Batch files',
	BATCH_FILE_NAME: 'Batch file name',
	BATCH_FILE_ACTIONS: 'Batch file actions',
	BATCH_FILE_UPLOAD: 'Batch Actions',
	CHANGE_SIM_STATE: 'Change SIM State',
	SELECT_SIM_STATE: 'Select SIM state',
	SIMS_SELECTED: 'SIMs selected',
	SIM_SELECTED: 'SIM selected',
	ONCE_YOU_INITIATE_CHANGE:
		'Once you initiate this change, the selected SIMS will be',
	PROCEED_ACTION: 'Would you like to proceed with this action?',
	CAN_SCHEDULE: 'Or you can schedule the change for',
	CURRENT_STATES: 'Current states',
	SCHEDULE_SUCCESSFUL: 'Multiple SIM state change is successfully scheduled!',
	SIM_STATE_CHANGE_FOR: 'SIM state change for',
	SIMS_IS_SUCCESSFULLY_SCHEDULED_FOR: 'SIMS is successfully scheduled for',
	SIMS_SUCCESSFULLY_REQUESTED: 'SIMS status change successfully requested!',
	MULTIPLE_SIM_STATE_CHANGE_SCHEDULE_ERROR:
		'Multiple SIM state change could not be scheduled successfully!',
	MULTIPLE_SIM_STATE_CHANGE_ERROR:
		'Multiple SIM state change could not be requested successfully!',
	SELECTED_TIP:
		'Tip: If you have selected SIMs that are in different states, you will only be able to change to a state that is available for all selected SIMs.',
	VIEW_RULES: 'View state change rules for this account',
	BATCH_FILE_UPLOADING: 'Batch file uploading',
	DATE_UPLOADED: 'Date uploaded',
	SIMPLE_SEARCH: 'Simple search',
	NUMBER_OF_COLUMNS: 'Number of columns',
	SELECT_SEARCH_PARAMETER: 'Select search parameter',

	DROPDOWN_BACK_TO_BUSINESS: 'Back to Business',
	'DROPDOWN_CONTACT_AT&T': 'Contact AT&T IoT',
	DROPDOWN_MC: 'IoT Console',
	DROPDOWN_M2X: 'M2X',
	DROPDOWN_FD: 'Flow Designer',
	DROPDOWN_SK: 'IoT Starter Kit',
	DROPDOWN_DP: 'IoT Data Plans',
	MY_INFORMATION: 'My information',
	MY_APPLICATIONS: 'My applications',
	TOPBAR_IDENTITY: 'AT&T IoT Identity',
	ERROR: 'Error',
	PAGE_NOT_FOUND_MESSAGE:
		'It seems we can’t find the page you are looking for.',
	'RETURN TO HOME PAGE': 'RETURN TO HOME PAGE',
	INTERNAL_SERVER_ERROR: 'Internal Server Error',
	ACTIVE_ENDPOINT_PROFILE: 'ACTIVE ENDPOINT PROFILE',
	CREATE_OR_EDIT: 'Create or edit custom label for this Endpoint',
	CREATE_CUSTOM_LABELS: 'Create custom labels',
	SNAP: 'Snap',
	SNAP_ERROR_MESSAGE:
		'Unknown error occurred. If this error persist, please contact support.',

	// report detail definitions
	REPORT_DETAIL_DEFINITIONS: 'REPORT DETAIL DEFINITIONS',
	REPORT_DETAIL_DEFINITIONS_MODAL_TITLE: 'Report detail definitions',
	REPORT_DETAIL_DEFINITIONS_DESC:
		'Select button below to view all report detail definitions.',
	VIEW_DEFINITIONS_BUTTON: 'VIEW DEFINITIONS',

	// definition types
	'DATA SESSIONS DETAIL DEFINITION': 'Data Sessions Detail Definition',
	'IMSI CHARGE DETAIL XML FIELDS': 'IMSI Charge Detail XML Fields',
	'IMSI DETAIL DEFINITIONS': 'IMSI Detail Definitions',
	'LBS USAGE DETAILS': 'LBS Usage Details',
	'DATA LAGG DETAIL DEFINITIONS': 'Data Lagg Detail Definitions',
	'SMS CALL DETAIL DEFINITIONS': 'SMS Call Detail Definitions',
	'TAX DETAIL DEFINITIONS': 'Tax Detail Definitions',
	'SIM ACTIVITY DETAIL DEFINITION': 'SIM Activity Detail Definition',

	// Data Lagg Detail Definitions - KEYS -------------
	CUST_ID_KEY: 'CUST_ID',
	IMSI_KEY: 'IMSI',
	ICCID_KEY: 'ICCID',
	MSISDN_KEY: 'MSISDN',
	ON_NET_KB_USAGE_KEY: 'ON_NET_KB_USAGE',
	ROAMING_KB_USAGE_KEY: 'ROAMING_KB_USAGE',
	CAN_KB_USAGE_KEY: 'CAN_KB_USAGE',
	INT_KB_ROUNDED_KEY: 'INT_KB_ROUNDED',
	TOTAL_KB_USAGE_KEY: 'TOTAL_KB_USAGE',

	// Data Lagg Detail Definitions - DESCRIPTIONS
	CUST_ID_DESC: 'EOD Customer ID.',
	IMSI_DESC:
		'International Mobile Station Identifier. This is the number that the network uses to identify user.',
	ICCID_DESC:
		'Integrated Circuit Card Identifier. This is the number printed on the SIM card.',
	MSISDN_DESC:
		'Mobile Station ISDN Number (phone number) assigned to that SIM.',
	ON_NET_KB_USAGE_DESC: 'Total On Net Usage (KB)',
	ROAMING_KB_USAGE_DESC: 'Total Domestic Off Net Usage (KB).',
	CAN_KB_USAGE_DESC: 'Total Canada Usage (KB).',
	INT_KB_ROUNDED_DESC: 'Total International Usage (KB).',
	TOTAL_KB_USAGE_DESC: 'Total Usage (KB).',

	// Data Sessions Detail Definition - KEYS ----------------
	IMPORT_DATE_KEY: 'Import Date',
	// IMSI - use already defined key-desc
	// ICCID - use already defined key-desc
	GGSN_IP_KEY: 'GGSN IP',
	SGSN_IP_KEY: 'SGSN IP',
	APN_NAME_KEY: 'APN NAME',
	PDP_IP_KEY: 'PDP IP',
	UPLINK_VOLUME_KEY: 'UPLINK VOLUME',
	DOWNLINK_VOLUME_KEY: 'DOWNLINK VOLUME',
	TRANSACTION_START_TIME_KEY: 'TRANSACTION START TIME',
	DURATION_KEY: 'DURATION',
	SERVED_MSISDN_KEY: 'SERVED MSISDN',
	BID_KEY: 'BID',
	SERVING_LOCATION_KEY: 'SERVING LOCATION',
	NETWORK_KEY: 'NETWORK',
	ROUNDING_DATE_KEY: 'ROUNDING DATE',

	// Data Sessions Detail Definition - DESCRIPTIONS
	IMPORT_DATE_DESC:
		'The date and time that EOD received this record. This is used to determine what billing date this record will apply to.',
	// IMSI - use already defined key-desc
	// ICCID - use already defined key-desc
	GGSN_IP_DESC:
		'Gateway General Packet Radio Service (GPRS) Support Node. The device that sits between the Public Land Mobile Network (PLMN) and external networks to provide border gateway such as security and accounting.',
	SGSN_IP_DESC:
		'Serving General Packet Radio Service (GPRS) Support Node. The device that sits at the same hierarchical level as the mobile switching center/visitor location register (MSC/VLR) to perform mobility management and manage the logical link to mobile terminals.',
	APN_NAME_DESC:
		'The name of APN that was used by this device for this transaction.',
	PDP_IP_DESC: 'The IP assigned to the device at the time of the transaction.',
	UPLINK_VOLUME_DESC:
		'The number of bytes (in octets) that were passed from the device for this detail record.',
	DOWNLINK_VOLUME_DESC:
		'The number of bytes (in octets) that were passed to the device for this detail record.',
	TRANSACTION_START_TIME_DESC:
		'The actual start time of transaction. This date will differ from the “Import Date” and “Routing Date” depending on when the record was received by EOD.',
	DURATION_DESC: 'The amount of time (in seconds) for that data record.',
	SERVED_MSISDN_DESC:
		'The Mobile Station ISDN Number (phone number) assigned to that SIM.',
	BID_DESC:
		'Billing Identifier. This is used by EOD to determine the market that the transaction took place in.',
	SERVING_LOCATION_DESC:
		'The name of the location where this data record occurred.',
	NETWORK_DESC: 'Cingular, Roaming/Off-Network, International, or Canada.',
	ROUNDING_DATE_DESC:
		'The date used by EOD to determine what other records this record will be grouped with to calculate the daily rounding of kilobytes.',
	SESSION_HISTORY_DCP_NOT_APPLICABLE: 'Not Applicable.',

	// IMSI Charge Detail XML Fields - KEYS AND DESC ------------
	CUSTOMER_KEY: 'CUSTOMER',
	CUSTOMER_DESC: 'Customer Prefix',
	STARTCYCLE_KEY: 'STARTCYCLE',
	STARTCYCLE_DECS: 'Start of Billing Cycle',
	ENDCYCLE_KEY: 'ENDCYCLE',
	ENDCYCLE_DESC: 'End of Billing Cycle',
	WCO_KEY: 'WCO',
	WCO_DESC: 'Wireless Connectivity Offer',
	EQUIPMENTORDERS_KEY: 'EQUIPMENTORDERS',
	EQUIPMENTORDERS_DESC: 'Ordering of Equipment',
	ACCOUNTOCC_KEY: 'ACCOUNTOCC',
	ACCOUNTOCC_DESC:
		'Other charges and Credits on account level (Taxes and surcharges only)',
	ACCOUNTTAXES_KEY: 'ACCOUNTTAXES',
	ACCOUNTTAXES_DESC: 'Government Taxes and Fees (taxes only) on account level',
	// ICCID - REUSE
	// IMSI - REUSE
	// MSISDN -REUSE
	RATEPLAN_KEY: 'RATEPLAN',
	RATEPLAN_DESC: 'Rate plan being billed on.',
	ACTIVATIONSTATE_KEY: 'ACTIVATIONSTATE',
	ACTIVATIONSTATE_DESC: 'State of device.',
	CUSTFILED1_KEY: 'CUSTFILED1',
	CUSTFILED1_DESC: 'Customer Field 1',
	CUSTFILED2_KEY: 'CUSTFILED2',
	CUSTFILED2_DESC: 'Customer Field 2',
	CUSTFILED3_KEY: 'CUSTFILED3',
	CUSTFILED3_DESC: 'Customer Field 3',
	CUSTFILED4_KEY: 'CUSTFILED4',
	CUSTFILED4_DESC: 'Customer Field 4',
	CUSTFILED5_KEY: 'CUSTFILED5',
	CUSTFILED5_DESC: 'Customer Field 5',
	ATTPOINTER_KEY: 'ATTPOINTER',
	ATTPOINTER_DESC: 'Internal Pointer',
	CUSTPOINTER_KEY: 'CUSTPOINTER',
	CUSTPOINTER_DESC: 'Customer Pointer',
	DATAMRC_KEY: 'DATAMRC',
	DATAMRC_DESC: 'Data MRC',
	DATAUSAGE_KEY: 'DATAUSAGE',
	DATAUSAGE_DESC: 'Sum of all Data usage charges',
	SMSMRC_KEY: 'SMSMRC',
	SMSMRC_DESC: 'SMS MRC',
	SMSUSAGE_KEY: 'SMSUSAGE',
	SMSUSAGE_DESC: 'Sum of all SMS usage charges – excluding batch SMS charge',
	VOICEMRC_KEY: 'VOICEMRC',
	VOICEMRC_DESC: 'Voice MRC',
	VOICEUSAGE_KEY: 'VOICEUSAGE',
	VOICEUSAGE_DESC: 'Sum of all Voice usage charges',
	DEVICEACTIVITYFEE_KEY: 'DEVICEACTIVITYFEE',
	DEVICEACTIVITYFEE_DESC:
		'Device Fees (Activation Fees, Deactivation Fees, Reactivation Fees, One-Time Plan Change Fees)',
	OCC_KEY: 'OCC',
	OCC_DESC:
		'Other Charges and Credits on subscriber level (Taxes and surcharges only)',
	TAXES_KEY: 'TAXES',
	TAXES_DESC: 'Government Taxes and Fees (taxes only) on subscriber level',

	// IMSI DETAIL KEYS VALUE -----------------------
	// ICCID - REUSE
	// MSISDN -REUSE
	PLAN_CODE_KEY: 'PLAN_CODE',
	PLAN_CODE_DESC: 'The Plan Code assigned by customer to each SIM.',
	ACT_ID_KEY: 'ACT_ID',
	ACT_ID_DESC:
		'The Action performed by the customer to each SIM (1 = Activate, 2 = Deactivate)',
	INCLUDED_USAGE_KEY: 'INCLUDED_USAGE',
	INCLUDED_USAGE_DESC: 'Total Included Usage (KB)',
	// ON_NET_KB_USAGE - REUSE
	// ROAMING_KB_USAGE - REUSE
	// CAN_KB_USAGE - REUSE
	// INT_KB_ROUNDED - REUSE
	// TOTAL_KB_USAGE - REUSE
	ACCESS_CHG_KEY: 'ACCESS_CHG',
	ACCESS_CHG_DESC: 'Monthly Recurring Access Charge.',
	ACTIVATION_CHG_KEY: 'ACTIVATION_CHG',
	ACTIVATION_CHG_DESC: 'Activation Charge.',
	BEGPERIOD_KEY: 'BEGPERIOD',
	BEGPERIOD_DESC: 'Start of Billing Cycle.',
	ENDPERIOD_KEY: 'ENDPERIOD',
	ENDPERIOD_DESC: 'End of Billing Cycle.',
	CUST_PREFIX_KEY: 'CUST_PREFIX',
	CUST_PREFIX_DESC: 'Customer Identifier.',
	CUST_FIELD_1_KEY: 'CUST_FIELD_1',
	CUST_FIELD_1_DESC: 'Customer Field 1.',
	CUST_FIELD_2_KEY: 'CUST_FIELD_2',
	CUST_FIELD_2_DESC: 'Customer Field 2.',
	CUST_FIELD_3_KEY: 'CUST_FIELD_3',
	CUST_FIELD_3_DESC: 'Customer Field 3.',
	CUST_FIELD_4_KEY: 'CUST_FIELD_4',
	CUST_FIELD_4_DESC: 'Customer Field 4.',
	CUST_FIELD_5_KEY: 'CUST_FIELD_5',
	CUST_FIELD_5_DESC: 'Customer Field 5.',
	INTERNAL_POINTER_KEY: 'INTERNAL POINTER',
	INTERNAL_POINTER_DESC: 'AT&T Internal Pointer.',
	CUSTOMER_POINTER_KEY: 'CUSTOMER POINTER',
	CUSTOMER_POINTER_DESC: 'Customer Pointer.',

	// LBS USAGE DEFINITION KEYS-VALUE
	RECORDINGENTITY_KEY: 'RECORDINGENTITY',
	RECORDINGENTITY_DESC:
		'Unique pointer to Agg records. (Reserved for aggregator use).',
	LCSCLIENTEXTERNALID_KEY: 'LCSCLIENTEXTERNALID',
	LCSCLIENTEXTERNALID_DESC: 'LCS Client ExternalID (Not for use at this time).',
	TARGETIMSI_KEY: 'TARGETIMSI',
	TARGETIMSI_DESC:
		'International Mobile Station Identifier. This is the number that the network uses to identify the user.',
	TARGETMSISDN_KEY: 'TARGETMSISDN',
	TARGETMSISDN_DESC:
		'MSDN located if ATT SIM, XXXXXXXXXX if non ATT SIM (The Mobile Station ISDN Number “phone number” assigned to that SIM).',
	RESULTCODE_KEY: 'RESULTCODE',
	RESULTCODE_DESC:
		'Success or failure of locate. 0 = success/ only 0 and 1 used to start as Agg and Med resultCodes may differ.',
	UTCRECORDOPENINGTIME_KEY: 'UTCRECORDOPENINGTIME',
	UTCRECORDOPENINGTIME_DESC:
		'Not for use at this time (UTC Recordopening time).',
	INFORMATION_1_KEY: 'INFORMATION_1',
	INFORMATION_1_DESC:
		'Application ID 16 digit EAG issued AppID (“1” in position2 = Technocom, “2” in position2 = LocAid Id)',
	INFORMATION_3_KEY: 'INFORMATION_3',
	INFORMATION_3_DESC:
		'(Horizontal) Accuracy Requested: Accuracy in meters desired. <=800 is AGPS. (800 to denote AGPS request or 801 to denote CID/ECID request for nonATT successful locates and 0 for failures (as pre response)).',
	INFORMATION_4_KEY: 'INFORMATION_4 ',
	INFORMATION_4_DESC:
		'(Horizontal) Accuracy Returned: Accuracy in meters actually returned. (800 to denote AGPS request or 801 to denote CID/ECID request for nonATT successful locates and 0 for failures (as per response)).',
	PART_DATE_KEY: 'PART_DATE',
	PART_DATE_DESC:
		'Date of entry into CDR transmission file by Agg. (PST: YYYY-MM-DD HH:mm:ss)',

	// SMS CALL DEFIONITIONS - KEYS AND DESC
	// IMSI
	// SERVED MSISDN
	SMS_TYPE_KEY: 'SMS TYPE',
	SMS_TYPE_DESC: 'Mobile Originated (MO) or Mobile Terminated (MT).',
	CALLED_NUMBER_KEY: 'CALLED NUMBER',
	CALLED_NUMBER_DESC: 'The Number that was dialed.',
	START_TIME_KEY: 'START TIME',
	START_TIME_DESC: 'The Date and Time when the transaction occurred.',
	// NETWORK
	PLMN_KEY: 'PLMN',
	PLMN_DESC:
		'Public Land Mobile Network List is a billing identifier used by EOD to determine the market where the transaction took place.',
	CARRIER_KEY: 'CARRIER',
	CARRIER_DESC: 'The mobility market owner for the associated PLMN.',

	// SIM ACTIVITY DEFINITIONS - KEYS AND DESC
	// ICCID
	// MSISDN
	ACTION_DATE_KEY: 'ACTION_DATE',
	ACTION_DATE_DESC: 'Date and Time action was completed.',
	ACT_KEY: 'ACT',
	ACT_DESC:
		'The Action performed by the customer to each SIM (ACTV = Activate, KILL = Deactivate, CHNG = Network Profile Change, RATE = RatePlan Change, CPPU = Change Primary Place of Use).',
	PLAN_CODE_KEY_2: 'PLAN_CODE',
	PLAN_CODE_DESC_2:
		'The alpha-code of the Plan that was used for this transaction.',
	LOGIN_KEY: 'LOGIN',
	LOGIN_DESC: 'User associated transaction.',

	// TAX DETAIL DEFINITIONS - KEYS AND DESC
	PPU_ADDRES_KEY: 'PPU_ADDRES',
	PPU_ADDRES_DESC:
		'Place of Primary Use – Where the customer primarily uses their service(s).',
	SERVICE_KEY: 'SERVICE',
	SERVICE_DESC: 'The product or offering being taxed.',
	INVOICE_SECTION_KEY: 'INVOICE SECTION',
	INVOICE_SECTION_DESC:
		'TX – Government Fees and Taxes OC – Other Charges and Credits.',
	AUTHORITY_LEVEL_KEY: 'AUTHORITY LEVEL',
	AUTHORITY_LEVEL_DESC:
		'Level associated with the Invoice Text F – Federal S – State Co – Country Ci – City DCo – District Country DCi – District City',
	INVOICE_TEXT_KEY: 'INVOICE TEXT',
	INVOICE_TEXT_DESC:
		'Text associated with the charge printed on the customers invoice.',
	CHARGE_AMMOUNT_KEY: 'CHARGE AMMOUNT',
	CHARGE_AMMOUNT_DESC: 'The amount that has had taxes applied toward it.',
	AMMOUNT_KEY: 'AMOUNT',
	AMMOUNT_DESC: 'The amount computed tax for an associated taxable charge.',
	BATCH_FORMATS: 'Batch formats',

	ACTIVATION: 'Activation',
	TERMINATION: 'Termination',
	NETWORK_ENTITLEMENT_CHANGE: 'Network Entitlement Change',
	SIM_SWAP: 'SIM Swap',
	CUSTOM_FIELD_UPDATES: 'Custom field updates',
	CUSTOM_FIELD_VALUE: 'Custom field value',
	BATCH_FILE_UPLOAD_SUCCESS: 'Batch files successfully received!',
	BATCH_FILE_UPLOAD_SUCCESS_MESSAGE:
		'Changes are pending as the system completes your updates',
	BATCH_FILE_SYSTEM_ERROR: 'Batch file system error!',
	BATCH_FILE_SYSTEM_ERROR_MESSAGE:
		'There was a problem processing your updates. Please try again later.',
	TYPE_IN_OR_SELECT:
		'Please type in new value or select option from the dropdown to change specific endpoint parameter.',

	SUBMITTED_STATUS: 'Submitted',
	SCHEDULED_STATUS: 'Scheduled',
	IN_PROGRESS_STATUS: 'In-Progress',
	COMPLETED_STATUS: 'Completed',
	'IN-PROGRESS_STATUS': 'In Progress',
	COMPLETED: 'Completed',
	CANCELLED: 'Cancelled',
	ERROR_STATUS: 'Error',

	FILE_NAME_CANNOT_CONTAIN_SPACES: 'File name cannot contain spaces',
	DRAG_FILE_OR_CLICK_TO_UPLOAD: 'Drag file or click to upload',
	FORBIDDEN_ACCESS: 'Forbidden Access',
	REQUEST_TIMED_OUT: 'Request timed out',
	TERMINATE_ACTION: 'Terminate SIMS',
	ACTIVATE_ACTION: 'Activate SIMS',
	SWAP_SIMS_ACTION: 'Swap SIMS',
	CUSTOM_FIELDS_ACTION: 'Add or update custom fields',
	REQUIRED_CUSTOM_FIELD: 'Elevated permissions required!',
	FIELD_IS_REQUIRED_ACTION:
		'Only administrators can modify custom field types as they have a system-wide configuration impact. Contact your administrator to update custom fields options.',
	PERMANENT_CUSTOM_FIELD_DELETION: 'Permanent custom field deletion!',
	DELETING_THIS_CUSTOM_FIELD:
		'Deleting this custom field will delete its all related data! This action cannot be undone!',
	DELETE_FIELD: 'Delete field',

	WARNING: 'Warning',
	ARE_YOU_SURE: 'Are you sure?',
	NETWORK_ENTITLEMENT_CHANGE_ACTION: 'SIM Network Entitlement Change',
	WHEN_SAVING: 'When saving your file, follow the format above.',
	EXAMPLE: 'Example',

	SELECT_NEW_STATE: 'Select new state',
	403: '403',

	AUTOMATION_PROCESS: 'Automation Process',
	BATCH_UPLOAD: 'Batch upload',
	DIAGRAM_COULD_NOT_BE: 'Diagram could not be',
	LOADED_AT_THIS_TIME: 'loaded at this time.',
	BATCH_ACTIONS: 'Batch Actions',
	BATCH_ACTION: 'Batch action',
	BATCH_ACTIONS_SUBMITTED: 'The file uploaded successfully for processing',
	BATCH_ACTIONS_SCHEDULED:
		'The file uploaded successfully, scheduled for processing',
	BATCH_ACTIONS_IN_PROGRESS: 'Processing underway',
	BATCH_ACTIONS_COMPLETED: 'The file processed successfully',
	BATCH_ACTIONS_ERROR: 'An error occurred during processing',
	BATCH_REPORTING: 'Batch Summary',
	BATCH_TEXT:
		'To process your changes in batch, please select an action to perform and upload your batch txt file. Example templates are available for formatting.',
	START_BATCH_PROCESS: 'Start batch process',
	DOWNLOAD_TEMPLATE: 'Download template file',
	AUTOMATION_RULES: 'Automation rules',
	ACTION_FORMAT: 'Action format',
	VIEW_MORE_DETAILS: 'View more details',
	UPLOAD_FILE_NOTE: 'Upload files here for selected batch action: ',
	UPLOAD_FILE: 'Upload file',

	UNSUPPORTED_FILE_TYPE: 'Unsupported file type',
	INVALID_FILE_NAME_FORMAT:
		'File name format should be actionName_MM-DD-YYYY_HH-mm-ss',
	DOWNLOAD_REPORT_FAILED: 'Download failed, please try again.',
	PLEASE_TRY_AGAIN: 'Please try again.',

	// REPORT DETAIL DEFINITION
	REPORT_SOURCE: 'Report Source',
	DATA_SESSION_DETAIL_DEFINITION: 'Data Session Detail Definition',
	IMSI_DETAIL_DEFINITION: 'IMSI Detail Definition',
	SMS_DETAIL_DEFINITION: 'SMS Detail Definition',
	SIM_DETAIL_DEFINITION: 'SIM Detail Definition',
	USAGE_DATA_DETAILS: 'Usage Data Detail',
	USAGE_SMS_DETAILS: 'Usage SMS Detail',
	POOLED_USAGE: 'Pooled Usage',
	USAGE_SUMMARY: 'Usage Summary',
	SUBSCRIBER_SNAPSHOT: 'Subscriber Snapshot',
	NEW_SUBSCRIBERS: 'New Subscribers',
	TOP_DATA_USAGE: 'Top Data Usage',
	TOP_SMS_USAGE: 'Top SMS Usage',
	PROVOSIONING_METRIC: 'Provisioning Metric',

	// USAGE DATA DETAIL - KEYS AND DESC
	DATA_TRAFFIC_DETAIL_ID_KEY: 'Data Traffic Detail ID',
	DATA_TRAFFIC_DETAIL_ID_DESC: 'Unique identifier for data usage records',
	// "ICCID_KEY"
	ICCID_DESC_SHORT: 'ICCID identifying the SIM',
	// "MSISDN_KEY"
	MSISDN_DESC_SHORT: 'MSISDN identifying the SIM',
	// "IMSI_KEY"
	IMSI_DESC_SHORT: 'IMSI identifying the SIM',
	ACCOUNT_ID_KEY: 'Account ID',
	ACCOUNT_ID_DESC: 'Jasper account id number',
	BILLABLE_FLAG_KEY: 'Billable Flag',
	BILLABLE_FLAG_DESC: 'Indicator of billable traffic based on SIM state',
	BILLING_CYCLE_KEY: 'Billing Cycle',
	BILLING_CYCLE_DESC: 'Billing cycle in which the usage occurred',
	SIM_STATE_KEY: 'SIM State',
	SIM_STATE_DESC: 'SIM status assigned to the SIM at the end of report period',
	SERVICE_TYPE_KEY: 'Service Type',
	SERVICE_TYPE_DESC:
		'Type of wireless service reported (e.g. Data, SMS MO, SMS MT, Voice)',
	ASSIGNED_RATE_PLAN_ID_KEY: 'Assigned Rate Plan ID',
	ASSIGNED_RATE_PLAN_ID_DESC:
		'Rate plan assigned to the SIM at the time the usage was reported',
	ASSIGNED_RATING_ZONE_ID_KEY: 'Assigned Rating Zone ID',
	ASSIGNED_RATING_ZONE_ID_DESC:
		'Rate Plan Zone to which the usage is attributed based on the rate plan assignment at the time the usage was reported',
	OFFPEAK_USAGE_FLAG_KEY: 'Off-Peak Usage Flag',
	OFFPEAK_USAGE_FLAG_DESC:
		'Whether the usage occurred during off-peak hours defined for the account',
	RECORD_RECEIVED_DATE_KEY: 'Record Received Date',
	RECORD_RECEIVED_DATE_DESC: 'Date/Time the accounting record was received',
	DATA_USAGE_RAW_TOTAL_KEY: 'Data Usage - Raw Total',
	DATA_USAGE_RAW_TOTAL_DESC: 'Unrounded data usage for the period - bytes',
	DATA_USAGE_RAW_UPLINK_KEY: 'Data Usage - Raw Uplink',
	DATA_USAGE_RAW_UPLINK_DESC:
		'Unrounded data usage for the period - Uplink bytes',
	DATA_USAGE_RAW_DOWNLINK_KEY: 'Data Usage - Raw Downlink',
	DATA_USAGE_RAW_DOWNLINK_DESC:
		'Unrounded data usage for the period - Downlink bytes',
	DATA_USAGE_ROUNDED_KEY: 'Data Usage - Rounded',
	DATA_USAGE_ROUNDED_DESC:
		'Rounded data usage for the period - bytes; Rounding treatment is defined per rate plan',
	APN_DESC: 'Access Point Name for the session',
	DEVICE_IP_ADDRESS_KEY: 'Device IP Address',
	DEVICE_IP_ADDRESS_DESC:
		'The IP address assigned to the device for the corresponding data session',
	OPERATOR_NETWORK_KEY: 'Operator Network',
	OPERATOR_NETWORK_DESC:
		'The visited operator network on which the usage occurred',
	RECORD_OPEN_TIME_KEY: 'Record Open Time',
	RECORD_OPEN_TIME_DESC: 'Date/Time the session or partial session started',
	SESSION_DURATION_KEY: 'Session Duration',
	SESSION_DURATION_DESC: 'Duration of the data session - seconds',
	RECORD_SEQUENCE_NUMBER_KEY: 'Record Sequence Number',
	RECORD_SEQUENCE_NUMBER_DESC:
		'Sequence number of the record within the parent session if the record represents a partial session',
	CHARGINGID_KEY: 'ChargingID',
	CHARGINGID_DESC:
		'An identifier of the session to which a usage record applies',
	SESSION_CLOSE_CAUSE_KEY: 'Session Close Cause',
	SESSION_CLOSE_CAUSE_DESC: 'Numeric code representing session close reason',
	TAP_CODE_KEY: 'TAP Code',
	TAP_CODE_DESC: 'A TAP code for the visited Network',
	OPERATOR_ACCOUND_ID_KEY: 'Operator Account ID',
	OPERATOR_ACCOUND_ID_DESC: 'Id used by the operator to identify an account',
	RATE_PLAN_VERSION_ID_KEY: 'Rate Plan Version ID',
	RATE_PLAN_VERSION_ID_DESC:
		'Specific version of the rate plan, being used for billing purposes at this point in time',
	STREAM_ID_KEY: 'Stream ID',
	STREAM_ID_DESC:
		'A numeric identifier to describe different data streams identified by GGSN/PGW',
	CGI_KEY: 'CGI (Cell Global Identifier)',
	CGI_DESC: 'Part of location information as described in TS29.274',
	SAI_KEY: 'SAI (Service Area Identifier)',
	SAI_DESC: 'Part of location information as described in TS29.274',
	RAI_KEY: 'RAI (Routing Area Identifier)',
	RAI_DESC: 'Part of location information as described in TS29.274',
	TAI_KEY: 'TAI (Tracking Area Identity)',
	TAI_DESC: 'Part of location information as described in TS29.274',
	ECGI_KEY: 'ECGI(E-UTRAN CGI)',
	ECGI_DESC: 'Part of location information as described in TS29.274',
	LAI_KEY: 'LAI (Location Area Identifier)',
	LAI_DESC: 'Part of location information as described in TS29.274',
	SERVING_SGSN_KEY: 'Serving SGSN',
	SERVING_SGSN_DESC: 'Address of the serving switch',
	CALL_TECHNOLOGY_TYPE_KEY: 'Call Technology Type',
	CALL_TECHNOLOGY_TYPE_DESC:
		'Numeric indicator to identify radio access technology used for this usage',

	// USAGE SMS DETAIL - KEYS AND DESC
	SMS_TRAFFIC_DETAIL_ID_KEY: 'Data Traffic Detail ID',
	SMS_TRAFFIC_DETAIL_ID_DESC: 'Unique identifier for data usage records',
	// "ICCID_KEY"
	// "ICCID_DESC"
	// "MSISDN_KEY"
	// "MSISDN_DESC"
	// "IMSI_KEY"
	// "IMSI_DESC"
	// "ACCOUNT_ID_KEY"
	// "ACCOUNT_ID_DESC"
	// "BILLABLE_FLAG_KEY"
	// "BILLABLE_FLAG_DESC"
	// "BILLING_CYCLE_KEY"
	// "BILLING_CYCLE_DESC"
	// "SIM_STATE_KEY"
	// "SIM_STATE_DESC"
	// "SERVICE_TYPE_KEY"
	// "SERVICE_TYPE_DESC"
	// "ASSIGNED_RATE_PLAN_ID_KEY"
	// "ASSIGNED_RATE_PLAN_ID_DESC"
	// "ASSIGNED_RATING_ZONE_ID_KEY"
	// "ASSIGNED_RATING_ZONE_ID_DESC"
	RECORD_OPEN_TIME_SMS_KEY: 'Record Open Time',
	RECORD_OPEN_TIME_SMS_DESC:
		'Date/Time an MO SMS message was sent; Date/Time an MT message was received',
	// "RECORD_RECEIVED_DATE_KEY": "Record Received Date",
	// "RECORD_RECEIVED_DATE_DESC": "Date/Time the accounting record was received",
	NETWORK_NODE_KEY: 'Network Node',
	NETWORK_NODE_DESC: 'Address of the serving network node for the message',
	FROM_KEY: 'From',
	FROM_DESC: 'Source MSISDN or short code',
	TO_KEY: 'To',
	TO_DESC: 'Destination MSISDN or short code',
	// "OPERATOR_NETWORK_KEY": "Operator Network",
	// "OPERATOR_NETWORK_DESC": "The visited operator network on which the usage occurred",
	// "TAP_CODE_KEY": "TAP Code",
	// "TAP_CODE_DESC": "A TAP code for the visited Network",
	// "OPERATOR_ACCOUND_ID_KEY": "Operator Account ID",
	// "OPERATOR_ACCOUND_ID_DESC": "Id used by the operator to identify an account",
	// "RATE_PLAN_VERSION_ID_KEY": "Rate Plan Version ID",
	// "RATE_PLAN_VERSION_ID_DESC": "Specific version of the rate plan,
	// being used for billing purposes at this point in time",

	// POOLED USAGE - KEYS AND DESC
	// "ACCOUNT_ID_KEY"
	// "ACCOUNT_ID_DESC
	// "BILLING_CYCLE_KEY" />,
	// "BILLING_CYCLE_DESC" />
	INVOICE_ID_KEY: 'Invoice ID',
	INVOICE_ID_DESC: 'ID of the invoice to which the charges apply',
	RATE_PLAN_ID_KEY: 'Rate Plan ID',
	RATE_PLAN_ID_DESC:
		'ID of rate plan assigned to the SIM at the end of the report period',
	RATING_ZONE_ID_KEY: 'Rating Zone ID',
	RATING_ZONE_ID_DESC:
		'Rating zone (defined on the rate plan) in which the usage occurred',
	TOTAL_DATA_USAGE_CHARGES_KEY: 'Total Data Usage Charges',
	TOTAL_DATA_USAGE_CHARGES_DESC:
		'Total data usage charges in this rating zone, beyond any pooled data usage included with the subscription fee',
	TOTAL_SMS_MO_USAGE_CHARGES_KEY: 'Total SMS MO Usage Charges',
	TOTAL_SMS_MO_USAGE_CHARGES_DESC:
		'Total SMS MO usage charges in this rating zone, beyond any pooled SMS usage included with the subscription fee',
	TOTAL_SMS_MT_USAGE_CHARGES_KEY: 'Total SMS MT Usage Charges',
	TOTAL_SMS_MT_USAGE_CHARGES_DESC:
		'Total SMS MT usage charges in this rating zone, beyond any pooled SMS usage included with the subscription fee',
	TOTAL_SMS_USAGE_CHARGES_KEY: 'Total SMS Usage Charges',
	TOTAL_SMS_USAGE_CHARGES_DESC:
		'Total SMS usage charges in this rating zone, beyond any pooled SMS usage included with the subscription fee',
	TOTAL_VOICE_MO_USAGE_CHARGES_KEY: 'Total Voice MO Usage Charges',
	TOTAL_VOICE_MO_USAGE_CHARGES_DESC:
		'Total MO voice usage charges for this SIM in this rating zone, beyond any voice usage included with the subscription fee',
	TOTAL_VOICE_MT_USAGE_CHARGES_KEY: 'Total Voice MT Usage Charges',
	TOTAL_VOICE_MT_USAGE_CHARGES_DESC:
		'Total MT voice usage charges for this SIM in this rating zone, beyond any voice usage included with the subscription fee',
	TOTAL_USAGE_CHARGES_KEY: 'Total Usage Charges',
	TOTAL_USAGE_CHARGES_DESC:
		'Total charges for usage in this rating zone beyond any pooled usage included with the subscription fee',

	// USAGE SUMMARY - KEYS AND DESC
	// "ACCOUNT_ID_KEY"
	// "ACCOUNT_ID_DESC"
	OPERATOR_ID_KEY: 'Operator ID',
	OPERATOR_ID_DESC: 'Jasper operator id number',
	// "BILLING_CYCLE_KEY"
	// "BILLING_CYCLE_DESC"
	// "ICCID_KEY"
	// "ICCID_DESC"
	// "IMSI_KEY"
	// "IMSI_DESC"
	// "MSISDN_KEY"
	// "MSISDN_DESC"
	TOTAL_DATA_USAGE_KEY: 'Total Data Usage',
	TOTAL_DATA_USAGE_DESC:
		'Total data usage for the billing cycle for this device',
	TOTAL_VOICE_USAGE_KEY: 'Total Voice Usage',
	TOTAL_VOICE_USAGE_DESC:
		'Total voice usage for the billing cycle for this device',
	TOTAL_SMS_USAGE_KEY: 'Total SMS Usage',
	TOTAL_SMS_USAGE_DESC: 'Total SMS usage for the billing cycle for this device',
	TOTAL_CSD_USAGE_KEY: 'Total CSD Usage',
	TOTAL_CSD_USAGE_DESC: 'Total csd usage for the billing cycle for this device',
	RESERVED_FIELD_KEY: 'Reserved field',
	RESERVED_FIELD_DESC: 'Reserved for future use',
	DEVICE_ID_KEY: 'Device ID',
	DEVICE_ID_DESC: 'Custom field available to operators',
	MODEM_ID_KEY: 'Modem ID',
	MODEM_ID_DESC: 'Custom field available to operators',
	END_CONSUMER_ID_KEY: 'End Consumer ID',
	END_CONSUMER_ID_DESC: 'Identifier of the end consumer',
	USAGE_CUSTOMER_KEY: 'Customer',
	USAGE_CUSTOMER_DESC: 'Customer name',
	CUSTOM1_KEY: 'Custom 1',
	CUSTOM1_DEVICE_DESC: 'Device level custom field 1',
	CUSTOM2_KEY: 'Custom 2',
	CUSTOM2_DEVICE_DESC: 'Device level custom field 1',
	CUSTOM3_KEY: 'Custom 3',
	CUSTOM3_DEVICE_DESC: 'Device level custom field 1',

	// SUBSCRIBER SNAPSHOT - KEYS AND DESC
	SUBSCRIBER_SIM_STATE_KEY: 'SIM State',
	SUBSCRIBER_SIM_STATE_DESC: 'SIM state at end of reporting period',
	// "RATE_PLAN_ID_KEY"
	SUBSCRIBER_RATE_PLAN_ID_DESC:
		'Rate Plan assigned to the SIM at end of reporting period',
	SUBSCRIBER_CUSTOMER_KEY: 'Customer',
	SUBSCRIBER_CUSTOMER_DESC:
		'Customer assigned to the SIM at end of reporting period',
	// "DEVICE_ID_KEY",
	SUBSCRIBER_DEVICE_ID_DESC: 'Value at end of reporting period',
	// "MODEM_ID_KEY"

	SUBSCRIBER_MODEM_ID_DESC: 'Value at end of reporting period',
	COMMUNICATION_PLAN_ID_KEY: 'Communication Plan ID',
	COMMUNICATION_PLAN_ID_DESC: 'Value at end of reporting period',
	INITIAL_ACTIVATION_DATE_KEY: 'Initial Activation Date',
	INITIAL_ACTIVATION_DATE_DESC:
		'Date and time SIM was first activated in the current account',
	CUSTOM_SIM_DESC: 'SIM level custom field available to Accounts',
	LINE_PAYMENT_STATUS_KEY: 'Line Payment Status',
	LINE_PAYMENT_STATUS_DESC:
		'Possible values are Current, Suspended, Cancelled, Final Cancellation',
	OPERATOR_ACCOUNT_ID_KEY: 'Operator Account Id',
	OPERATOR_ACCOUNT_ID_DESC: 'Id used by the operator to identify an account',
	CUSTOM4_KEY: 'Custom 4',
	CUSTOM5_KEY: 'Custom 5',
	CUSTOM6_KEY: 'Custom 6',
	CUSTOM7_KEY: 'Custom 7',
	CUSTOM8_KEY: 'Custom 8',
	CUSTOM9_KEY: 'Custom 9',
	CUSTOM10_KEY: 'Custom 10',

	ICCID_DESC_SHORT2: 'Device ICCID',
	IMSI_DESC_SHORT2: 'Device IMSI',
	RATE_PLAN_KEY: 'Rateplan',
	RATE_PLAN_DESC: 'EOD plan_code, Control Center ICCID blank',
	SIM_STATE_KEY2: 'Simstate',
	SIM_STATE_DESC2: 'ACTIVE, INACTIVE, etc., if available',
	BEGBILLINGCYCLEDATE_KEY: 'Begbillcycledate',
	BEGBILLINGCYCLEDATE_DESC:
		'Date the billing cycle began if EOD, blank if Control Center',
	ENDBILLINGCYCLEDATE_KEY: 'Endbillcycledate',
	ENDBILLINGCYCLEDATE_DESC:
		'Date the billing cycle ended if EOD, blank if Control Center',
	TOTALDATAUSAGE_KEY: 'Totaldatausage',
	TOTALDATAUSAGE_DESC: 'Period data usage in kilobytes',
	TOTALINTERNATIONALUSAGE_KEY: 'Totalinternationalusage',
	TOTALINTERNATIONALUSAGE_DESC:
		'Period international data usage in kilobytes if EOD, blank if Control Center',
	CUSTOMERNAME_KEY: 'Customername',
	CUSTOMERNAME_DESC: 'Name of Customer',
	CUSTOMFIELD1_KEY: 'Customfield1',
	CUSTOMERNAME1_DESC: 'EOD and Control Center can populate',
	CUSTOMFIELD2_KEY: 'Customfield2',
	CUSTOMERNAME2_DESC: 'EOD and Control Center can populate',
	CUSTOMFIELD3_KEY: 'Customfield3',
	CUSTOMERNAME3_DESC: 'EOD and Control Center can populate',
	CUSTOMFIELD4_KEY: 'Customfield4',
	CUSTOMERNAME4_DESC: 'EOD or Control Center DeviceID',
	CUSTOMFIELD5_KEY: 'Customfield5',
	CUSTOMERNAME5_DESC: 'EOD or Control Center ModemID',
	M2MPLATFORM_KEY: 'M2MPlatform',
	M2MPLATFORM_DESC: '“EOD” or “Control Center”',
	BILLINGCYCLE_KEY: 'Billingcycle',
	BILLINGCYCLE_DESC: 'Control Center JPO field to populate',
	CUSTOMERID_KEY: 'Customerid',
	CUSTOMERID_DESC: 'For EOD, customer prefix; For Control Center, Customer ID',
	PROVISIONINGACTIVITY_KEY: 'ProvisioningActivity ',
	PROVISIONINGACTIVITY_DESC:
		'One of “activation”, “inactive”, “terminate”, “changeNetworkEntitlement”, “changeRatePlan”, “updateCustomFields”',
	COUNT_KEY: 'Count',
	COUNT_DESC: 'Number of times the provisioning activity was called',
	SUCCESS_KEY: 'Success',
	SUCCESS_DESC:
		'Number of times the provisioning activity was called successfully',
	FAILURE_KEY: 'Failure',
	FAILURE_DESC:
		'Number of times the provisioning activity was called unsuccessfully',
	AVGTIMEMS_KEY: 'AvgTimeMs',
	AVGTIMEMS_DESC:
		'Number of milliseconds the provisioning call took on average',
	LONGESTTIMEMS_KEY: 'LongestTimeMs',
	LONGESTTIMEMS_DESC:
		'Number of milliseconds the slowest call of that provisioning activity took',
	SHORTESTTIMEMS_KEY: 'ShortestTimeMs',
	SHORTESTTIMEMS_DESC:
		'Number of milliseconds the fastest call of that provisioning activity took',

	REPORT_SOURCE_DESC: 'Select report source to see different definitions',

	SIM_SWAP_SUCCESSFUL: 'SIM swap successful',
	SIM_SWAP_IN_PROGRESS: 'SIM swap in progress',
	REFRESH_PENDING_STATUS: 'Refresh endpoint to check if the state has changed',
	TEMPLATE_NOT_AVAILABLE: 'Template not available.',
	CUSTOM_FIELDS_UPDATE_SUCCESSFUL: 'Custom fields update successful',
	CUSTOM_FIELDS_UPDATE_IN_PROGRESS: 'Custom fields update in progress',
	IOT_APPS: 'IOT APPS',
	INVOICES_DATE: 'Invoices date',
	PLATFORM: 'Platform',
	FREQUENCY: 'Frequency',
	M2M_ACC_ID: 'M2M Account ID',
	REPORT: 'Report',

	// Onboarding
	ADD_NEW_PLATFORM: 'Add new platform',
	BUSINESS_NAME: 'Business name',
	ADMINISTRATOR: 'Administrator',
	MCC: 'MCC',
	MNC: 'IoT Console (IOT-C)',
	OPERATOR: 'Operator',
	POD: 'POD',
	RATE_PLANS: 'Rate plans',
	RATE_PLAN_NAME: 'Rate Plan Name',
	RATE_PLAN_ID: 'Rate Plan',
	RATE_PLAN_ID_PLAN: 'Rate Plan ID',
	COMMUNICATION_PLANE_NAME: 'Communication Plan Name',
	ADD_ANOTHER_RATE_PLAN: 'Add another rate plan',
	ADD_ANOTHER_COMMUNICATION_PLAN: 'Add another communication plan',
	ADD_ANOTHER_PROFILE_NAME: 'Add another profile name',
	SIM_STATE_MAPPING: 'SIM state mappings',
	ADD_RATE_PLAN: 'Add Rate Plan',
	ACC_RULE: 'ACC Rule',
	MC_RULE: 'IOT-C Rule',
	WHAT_IS_THIS_TITLE: 'What is this?',
	PLAN_PROFILES: 'Plan profiles',
	COMPANY: 'Company',
	COMPANY_NAME: 'Company Name',
	COMPANY_DOMAIN: 'Company Domain',
	PHONE_NUMBER: 'Phone number',
	CITY: 'City',
	CITY_TOWN: 'City/Town',
	STATE_PROVINCE: 'State/Province',
	COUNTRY: 'Country',
	ZIP_CODE: 'ZIP Code',
	ZIP_POSTAL_CODE: 'ZIP/Postal code',
	STREET: 'Street',
	STREET_ADDRESS: 'Street Address',
	LAST_NAME: 'Last name',
	FIRST_NAME: 'First name',
	ACTIONS: 'Actions',
	PLEASE_SELECT_COUPLE_OF_SIMS: 'Please select a few of SIMS.',
	COMPANY_IDENTITY: 'Company identity',
	ADD_USERS_OPTIONAL: 'Add users (optional)',
	REVIEW_AND_CREATE: 'Review and create',
	PROJECTS_LIST: 'Projects list',
	MULTI_NETWORK_CONNECT_ONBOARDING_M2M_PLATFORMS:
		'IoT Console Onboarding - M2M Platforms',
	MULTI_NETWORK_CONNECT: 'IoT Console',
	CUSTOMERS: 'Customers',
	COMPANY_EMAIL_ADDRESS: 'Company email address',
	CONTACT_EMAIL_ADDRESS: 'Contact email address',
	CONTACT_PERSON_FULL_NAME: 'Contact person full name',
	CONTACT_PERSON: 'Contact person',
	ASSIGN_ANOTHER_PLATFORM: 'Assign another platform',
	USER_INFO: 'User info',
	SELECT_USER_TYPE: 'Select user type',
	USER_TYPE: 'User type',
	USER_NOTE: 'User will receive an invitation email to create their account',
	ADD_USER: 'Add user',
	ADD_ANOTHER_USER: 'Add another user',
	WHAT_IS_THIS_MESSAGE1:
		'Customer must be loaded into the M2M platforms before IoT Console account can be created. Once you have the Account ID you can continue.',
	WHAT_IS_THIS_MESSAGE2:
		'Small business customers and start-ups using IoT Data Plans or IoT Starter Kits can be added automatically by filling out this form. Upon account creation, come back here to enable the customer access to IoT Console portal.',
	SELECT_PLATFORM: 'Select platform',
	EMAIL_EXISTS_OTHER: 'This email already exist (Other user)',
	EMAIL_EXISTS_ADMIN: 'This email already exist (Admin)',
	YOU_HAVE_SUCCESSFULLY_CREATED_A_MULTI_NETWORK_CONNECT_ACCOUNT_FOR:
		'You have successfully created a IoT Console account for:',
	THE_IOT_GATEWAY_NUMBER_ASSOCIATED_WITH_THIS_ACCOUNT:
		'The IoT Gateway number associated with this account:',
	COMMUNICATION_PROFILE_NAME: 'Communication profile name',
	ADD_NETWORK_ENTITLEMENT: 'Add network entitlement',
	EDIT_NETWORK_ENTITLEMENT: 'Edit network entitlement',
	PLATFORMS: 'Platforms',
	NO_NETWORK_ENTITLEMENTS: 'No network entitlements',
	NO_RATE_PLANS: 'No rate plans',
	EDIT_RATE_PLAN: 'Edit Rate Plan',
	ERROR_WHILE_ADDING_NETWORK_ENTITLEMENT:
		'Error while adding network entitlement',
	ERROR_WHILE_EDITING_NETWORK_ENTITLEMENT:
		'Error while editing network entitlement',
	ERROR_WHILE_ADDING_RATE_PLAN: 'Error while adding rate plan',
	ERROR_WHILE_EDITING_RATE_PLAN: 'Error while editing rate plan',
	ERROR_WHILE_ADDING_COMPANY: 'Error while adding company',
	ERROR_WHILE_EDITING_COMPANY: 'Error while editing company',
	ERROR_WHILE_ADDING_EDITING_USER: 'Error while adding/editing user',
	ERROR_WHILE_ADDING_EDITING_PLATFORM: 'Error while adding/editing platform',
	ERROR_WHILE_ADDING_PLATFORM: 'Error while adding platform',
	ADD_PROFILE_NAME: 'Add Profile Name',
	ADD_PROFILE_ID: 'Add Profile ID',
	EDIT_PLATFORM: 'Edit platform',
	SIM_STATE_CHANGE_IS_SUCCESSFULLY_SCHEDULED:
		'SIM state change is successfully scheduled!',
	SIM_STATE_CHANGE_IS_SUCCESSFULLY_SCHEDULED_FOR:
		'SIM state change is successfully scheduled for',
	SIM_STATE_CHANGE_IS_SUCCESSFULLY_SCHEDULED_ERROR:
		'SIM state change could not be scheduled successfully!',
	SIM_STATE_CHANGED_TO: 'You have chosen to change SIM state to',
	CHANGE_LATER: 'You can also schedule the change for later.',
	SCHEDULED_CHANGE_WILL_HAPPEN:
		'Scheduled change will happen at the beginning of the date you select.',
	TAP_INITIATE_CHANGE: "Tap 'Initiate change' to take immediate action.",
	SIM_STATE_CHANGE: 'SIM state change',
	SCHEDULE_SIM_STATE_CHANGE: 'Schedule SIM state change',
	SYSTEM_CONFIRMATION: 'System confirmation',
	CHOOSE_NETWORK_ENTITLEMENT: 'Choose Network Entitlement',
	HISTORY: 'Batch History',

	serviceProvider: 'Platform',
	reportFrequency: 'Frequency',

	// Onboarding
	ENDPOINT_SESSION_NO_RECORD: 'There is no data for the selected period.',
	TIME_START: 'Time start',
	TIME_END: 'Time end',
	SESSION_DETAILS: 'Session details',
	CARRIER: 'Carrier',
	DEVICE_IP: 'Device IP',

	// chart datasets
	inventoryByStatus: 'Inventory by status',
	inventoryByOperator: 'Inventory by operator',
	inventoryByCountry: 'Inventory by country',
	inventoryByStatusDescription:
		'This will display the number of active and inactive endpoints.',
	inventoryByOperatorDescription:
		'This will display the operators and the number of endpoints with each operator.',
	inventoryByCountryDescription:
		'This will display a country name and number of endpoints operating in that country.',
	// prompt modal
	addPromptHeaderInfo: 'Apply changes?',
	addChartPromptHeader: 'Dashboard changes are not yet saved',
	addChartPromptFirst:
		'If you wish to save changes to your dashboard view tap `Save Dashboard`, otherwise `cancel` to revert to previous view.',
	addChartPromptNotification: 'Apply changes?',

	// CHART Descriptions
	DASHBOARD_CHART_countByState_SATELLITEDescription:
		'Shows status details of Satellite endpoints (devices) across multiple M2M platforms',
	DASHBOARD_CHART_countByState_CELLULARDescription:
		'Shows status details of Cellular endpoints (devices) across multiple M2M platforms',
	DASHBOARD_CHART_countByNetworkCarrier_SATELLITEDescription:
		'Shows percentage split of Satellite endpoints (devices) across multiple network carriers',
	DASHBOARD_CHART_countByNetworkCarrier_CELLULARDescription:
		'Shows percentage split of Cellular endpoints (devices) across multiple network carriers',
	DASHBOARD_CHART_countByTechnologyDescription:
		'Shows breakdown total by percentage across Cellular and Satellite endpoints (devices) in the account',
	DASHBOARD_CHART_countByM2mPlatform_SATELLITEDescription:
		'Shows breakdown total by percentage across Satellite endpoints (devices) across multiple M2M platforms',
	DASHBOARD_CHART_countByM2mPlatform_CELLULARDescription:
		'Shows breakdown total by percentage across Cellular endpoints (devices) across multiple M2M platforms',
	DASHBOARD_CHART_countByIMSI_SATELLITEDescription:
		'Shows breakdown total by percentage across Cellular endpoints (devices) across multiple M2M platforms',
	DASHBOARD_CHART_countByIMSI_CELLULARDescription:
		'Shows breakdown total by percentage across Satellite endpoints (devices) across multiple M2M platforms',
	'DASHBOARD_CHART_IMSI Transfer Status_CELLULARDescription':
		'Shows transfer (Re-IMSI) status totals by percentage between two M2M platforms.',
	DASHBOARD_CHART_countByIMSITransferStatus_CELLULARDescription:
		'Shows transfer (Re-IMSI) status totals by percentage between two M2M platforms.',
	DASHBOARD_CHART_countByIMSITransferStatus_SATELLITEDescription:
		'Shows transfer (Re-IMSI) status totals by percentage between two M2M platforms.',
	DASHBOARD_CHART_summaryOfMonthlyEndpointUsageDescription:
		'Shows monthly data usage summary across Cellular and Satellite endpoints (devices)',
	DASHBOARD_CHART_countByStatebyPlatformACC_CELLULARDescription:
		'Shows status details of Cellular endpoints (devices) for ACC platform',
	DASHBOARD_CHART_countByStatebyPlatformEOD_CELLULARDescription:
		'Shows status details of Cellular endpoints (devices) for EOD platform',
	DASHBOARD_CHART_countByStatebyPlatformGBCM_SATELLITEDescription:
		'Shows status details of Satellite endpoints (devices) for GBCM platform',
	DASHBOARD_CHART_countByStatebyPlatformDCP_CELLULARDescription:
		'Shows status details of Cellular endpoints (devices) for DCP platform',
	DASHBOARD_CHART_countByStatebyPlatformCCIP_CELLULARDescription:
		'Shows status details of Cellular endpoints (devices) for CCIP platform',
	DASHBOARD_CHART_countByStatebyPlatformVCC_CELLULARDescription:
		'Shows status details of Cellular endpoints (devices) for VCC platform',
	DASHBOARD_CHART_countByStatebyPlatformGDSP_CELLULARDescription:
		'Shows status details of Cellular endpoints (devices) for GDSP platform',
	DASHBOARD_CHART_countByStatebyPlatformWING_CELLULARDescription:
		'Shows status details of Cellular endpoints (devices) for WING platform',
	DASHBOARD_CHART_summaryOfMonthlyEndpointUsage_CELLULARDescription:
		'Shows monthly data usage summary across Cellular and Satellite endpoints (devices)',
	DASHBOARD_CHART_countByProfile_CELLULARDescription:
		'Shows status details of Cellular endpoints (devices) across multiple profiles',

	// Pie Chart custom tooltip id
	PIE_CHART_TOOLTIP_Active: 'Activated',
	PIE_CHART_TOOLTIP_Test: 'Test',
	PIE_CHART_TOOLTIP_Inactive: 'Inactive',
	PIE_CHART_TOOLTIP_Terminate: 'Terminated',

	// Globecomm
	DEVICE_MODULE_NUMBER: 'Device Module Number',
	DEVICE_MANUFACTURER: 'Device Manufacturer',
	DEVICE_STATUS: 'Device status',
	DEVICE_NETWORK_TYPE: 'Device Network Type',
	MODULE_IMEI: 'Module IMEI',
	SIM_STATUS: 'SIM status',
	MODULE_STATUS: 'Module Status',
	TRESHOLD_OF_NOTIFICATION_BY_DATA_USAGE:
		'Threshold of Notification by Data Usage',
	TRESHOLD_OF_SUSPENSION_BY_DATA_USAGE: 'Threshold of Suspension by Data Usage',
	NOTIFICATION_INTERVAL: 'Notification Interval',
	DATA_USAGE: 'Cycle to Date Data Usage',
	MANUFACTURER: 'Manufacturer',
	MODEL_NUMBER: 'Model Number',
	TYPE: 'Type',
	NETWORK_NAME: 'Network Name',
	SUPPORTED_NETWORKS: 'Supported Networks',
	RENEWAL_PERIOD: 'Renewal Period',
	ACTIVATION_FEE: 'Activation Fee',
	RECCURING_FEE: 'Recurring Fee',
	OVERAGE_FEE: 'Overage Fee',
	INCLUDED_BYTES: 'Included Bytes',
	MINIMUM_BILLABLE_BYTES: 'Minimum Billable Bytes',
	NOTIFICATION_CONTACT: 'Notification Contact',
	NOTIFICATION_BYTE_CAP: 'Notification Byte Cap',
	NOTIFICATION_REFRESH: 'Notification Refresh',
	OBSOLETE: 'Obsolete',
	HARDWARE: 'Hardware',
	SPECIFICATIONS: 'Specifications',
	SPECIFICATION: 'Specification',
	MODULE: 'Module',
	NO_HISTORY_DATA: 'No history records for this endpoint',

	// Dashboard default message
	NO_GRAPHS: 'ADDED GRAPHS WILL BE DISPLAYED HERE',
	dashboardDefault: 'ADDED GRAPHS WILL BE DISPLAYED HERE',
	REMOVE_CHART: 'Remove graph',
	CARD_DROPDOWN_TITLE: 'Graph type',
	ADD_WIDGET_BUTTON: 'Add Graph',
	ADD_WIDGET_BUTTON_MOBILE: 'Add',

	APP_NAME: 'IoT Console',
	NETWORK_TYPE_CHECK:
		'We are sorry, multiple endpoint state change is not allowed for endpoints from different network types or different service providers.',
	SERVICE_PROVIDER_CHECK:
		'We are sorry, multiple endpoint change is not allowed for endpoints from multiple platforms or with different network entitlements.',

	// USER FEEDBACK MODAL
	feedbackTitle: 'User feedback',
	feedbackPageLabel: 'Your feedback is related to',
	feedbackFeedLabel: 'In your own words how can we make things better?',
	feedbackFeedPlaceholder: 'Your feedback goes here...',
	feedbackContactLabel: 'If needed, can we contact you about this?',
	feedbackEmailLabel: 'Email',
	feedbackPhoneLabel: 'Phone',
	feedbackMetaLabel: 'Metadata',
	feedbackCancelButton: 'Cancel',
	feedbackSendButton: 'Send feedback',
	feedbackSuccessThanks: 'Thank you for the feedback.',
	feedbackSuccessMessage:
		'Your feedback will make our product better. We will carefully evaluate your comments and act upon it.',
	feedbackSuccessMessageSub:
		'We may also contact you for more information regarding your suggestion.',
	feedbackSuccessMessageSign: 'IoT Console team',
	feedbackSuccessDoneButton: 'Done',
	feedbackTriggerTitle: 'Got feedback?',
	feedbackTriggerSubtitle: 'Tell us here',
	feedbackThankYou:
		'Thank you {user} for participating in the user feedback and helping us in making better product.',
	feedbackSelect: 'You must select related topic from the list above.',
	feedbackEnter: 'You must enter feedback.',
	feedbackIcon: 'Success feedback submit icon',
	feedbackAdditionalFile: 'Additional file',
	feedbackFileUploadFail: 'Fail upload failed',
	feedbackMinLength: 'Please enter at least 10 characters.',
	FEEDBACK_FILE_UPLOAD_EXTENSIONS:
		'Upload files with .jpg or .png extensions only',
	// Changes tab

	FIELD_NAME: 'Field name',

	DELETE_TAB: 'Delete tab',
	TABLE_NAME: 'Table Name',
	EDIT_TABLE: 'Edit Table Parameters',
	CUSTOM_FIELD_LABEL: 'Custom Field Label',
	EDIT_TABLE_VIEW_NAME: 'Edit table view name',
	PLEASE_ENTER_TABLE_NAME: 'Please enter table name',
	DEVICE_TYPE: 'Device Type',
	CELLULAR_COLUMNS: 'Cellular Specific Parameters',
	SATELLITE_SPECIFIC_COLUMNS: 'Satellite Specific Parameters',

	// TICKETING
	TICKET_ID: 'Ticket ID',
	CATEGORY_NAME: 'Category',
	CATEGORY_TICKETING: 'Category',
	SUBCATEGORY_OPTIONAL: 'Subcategory (optional)',
	SELECT_SUBCATEGORY_OPTIONAL: 'Select subcategory (optional)',
	ROAMING_ISSUE: 'Roaming issue',
	SEVERITY: 'Severity',
	PRIORITY: 'Priority',
	ACCOUNT_NUMBER: 'Account number',
	CREATED_BY_TICKETING: 'Created by',
	MULTI_NETWORK_CONNECT_ID: 'IoT Console ID',
	ENDPOINT_MODEL: 'Endpoint model',
	WHAT_IS_THE_ERROR_MESSAGE: 'What is the error message?',
	HOW_MANY_BARS_ARE_SHOWING: 'How many bars are showing?',
	DESCRIBE_ENDPOINT_STATE_HERE: 'Describe endpoint state here...',
	WAS_THE_TROUBLESHOOTING_DONE: 'Was the troubleshooting done?',
	DESCRIBE_TROUBLESHOOTING_DETAILS_HERE:
		'Describe troubleshooting details here…',
	DO_YOU_HAVE_ANY_ADDITIONAL_NOTES: 'Do you have any additional notes?',
	ADDITIONAL_NOTES: 'Additional notes',
	DASHBOARD_CHART_M2MSIMStatus_SATELLITE:
		'endpoints by IOT-C state (satellite)',
	DASHBOARD_CHART_M2MSIMStatus_CELLULAR: 'endpoints by IOT-C state (cellular)',
	DASHBOARD_CHART_Country_SATELLITE:
		'Endpoints by Current Location (satellite)',
	DASHBOARD_CHART_Countrys_CELLULAR: 'Endpoints by Current Location (cellular)',
	DASHBOARD_CHART_IMSI_SATELLITE: 'Endpoints by M2M Platform (satellite)',
	DASHBOARD_CHART_IMSI_CELLULAR: 'Endpoints by M2M Platform (cellular)',
	DASHBOARD_CHART_Network_SATELLITE: 'Endpoints by Network Carrier (satellite)',
	DASHBOARD_CHART_Network_CELLULAR: 'Endpoints by Network Carrier (cellular)',
	DASHBOARD_CHART_Technology: 'Endpoints by Technology',
	'DASHBOARD_CHART_IMSI Transfer Status_SATELLITE':
		'Endpoints by Transfer Status (satellite)',
	'DASHBOARD_CHART_IMSI Transfer Status_CELLULAR':
		'Endpoints by Transfer Status (cellular)',
	DASHBOARD_CHART_countByIMSITransferStatus_SATELLITE:
		'Endpoints by Transfer Status (satellite)',
	DASHBOARD_CHART_countByIMSITransferStatus_CELLULAR:
		'Endpoints by Transfer Status (cellular)',
	DASHBOARD_CHART_summaryOfMonthlyEndpointUsage_CELLULAR:
		'Total Data Usage for All ACC Endpoints',
	DASHBOARD_CHART_countByState_SATELLITE: 'Endpoints by Status (satellite)',
	DASHBOARD_CHART_countByState_CELLULAR: 'Endpoints by Status (cellular)',
	DASHBOARD_CHART_countByServiceType_SATELLITE:
		'Endpoints by Technology (satellite)',
	DASHBOARD_CHART_countByServiceType_CELLULAR:
		'Endpoints by Technology (cellular)',
	DASHBOARD_CHART_countByRatePlan_SATELLITE:
		'Endpoints by Rate Plan (satellite)',
	DASHBOARD_CHART_countByRatePlan_CELLULAR: 'Endpoints by Rate Plan (cellular)',
	DASHBOARD_CHART_countByNetworkEntitlement_SATELLITE:
		'Endpoints by Network Entitlement (satellite)',
	DASHBOARD_CHART_countByNetworkEntitlement_CELLULAR:
		'Endpoints by Network Entitlement (cellular)',
	DASHBOARD_CHART_countByNetworkCarrier_SATELLITE:
		'Endpoints by Network Carrier (satellite)',
	DASHBOARD_CHART_countByNetworkCarrier_CELLULAR:
		'Endpoints by Network Carrier (cellular)',
	DASHBOARD_CHART_countByTechnology: 'Endpoints by Technology',
	DASHBOARD_CHART_countByM2MPlatform_SATELLITE:
		'Endpoints by M2M Platform (satellite)',
	DASHBOARD_CHART_countByM2MPlatform_CELLULAR:
		'Endpoints by M2M Platform (cellular)',
	DASHBOARD_CHART_countByIMSI_SATELLITE: 'Endpoints by M2M Platform',
	DASHBOARD_CHART_countByIMSI_CELLULAR: 'Endpoints by M2M Platform',
	DASHBOARD_CHART_countByM2mPlatform_SATELLITE: 'Endpoints by M2M Platform',
	DASHBOARD_CHART_countByM2mPlatform_CELLULAR: 'Endpoints by M2M Platform',
	DASHBOARD_CHART_countByStatebyPlatformACC_CELLULAR:
		'Endpoints by Status (ACC)',
	DASHBOARD_CHART_countByStatebyPlatformEOD_CELLULAR:
		'Endpoints by Status (EOD)',
	DASHBOARD_CHART_countByStatebyPlatformGBCM_SATELLITE:
		'Endpoints by Status (GBCM)',
	DASHBOARD_CHART_countByStatebyPlatformDCP_CELLULAR:
		'Endpoints by Status (DCP)',
	DASHBOARD_CHART_countByStatebyPlatformCCIP_CELLULAR:
		'Endpoints by Status (CCIP)',
	DASHBOARD_CHART_countByStatebyPlatformVCC_CELLULAR:
		'Endpoints by Status (VCC)',
	DASHBOARD_CHART_countByStatebyPlatformGDSP_CELLULAR:
		'Endpoints by Status (GDSP)',
	DASHBOARD_CHART_countByStatebyPlatformWING_CELLULAR:
		'Endpoints by Status (WING)',
	DASHBOARD_CHART_countByProfile_CELLULAR: 'Endpoints by Profile',
	TICKET_STATUS: 'Ticket status',
	ENDPOINT_LOCATION: 'Endpoint location',
	ISSUE: 'Issue',
	SUGGESTIONS: 'Suggestions',
	PHONE: 'Phone',
	FAILED_TO_PROVISION: 'Failed to provision',
	SUBMITTED_BY: 'Submitted by',
	NEW_TICKET: 'New Ticket',
	ENDPOINT: 'Endpoint',
	ERROR_MESSAGE: 'Error message',
	ATTACHED_FILE: 'Attached file',
	TICKETING: 'Ticketing',
	GET_HELP_WITH_THIS_ENDPOINT: 'Get help with this endpoint',
	CATEGORY_AND_SUGGESTIONS: 'Category & Suggestions',
	CONFIRMATION: 'Confirmation',
	RECORDED_STATUS: 'Recorded status',
	ENDPOINT_NAME: 'Endpoint ID',
	CONTACT_PHONE: 'Contact phone',
	CONTACT_EMAIL: 'Contact email',
	SELECT_SUBCATEGORY: 'Select subcategory',
	FILE_UPLOADED: 'File uploaded',
	FAQ_SUPPORT_INFO:
		'Find quick answers to frequently asked questions by our users.',
	USER_GUIDE_SUPPORT_INFO:
		'Read detailed step-by-step instructions for portal functions. The guide is tailored to your account type.',
	TICKETING_SUPPORT_INFO:
		'If you experience service issues and diagnostics tests do not provide a resolution path, you can create a trouble ticket. ',
	MUST_BE: 'Must be ',
	LESS_THAN: 'less than ',
	CHARACTERS: ' characters',
	LOADING_DATA: 'Loading data',
	GPRS: 'GPRS',

	//  Support
	CONTENT: 'Content',
	CREATE_NEW_NOTIFICATION: 'Create New Notification',
	EDIT_NOTIFICATION: 'Edit Notification',
	EDIT_FORM_TO_CREATE_NOTIFICATION: 'Edit form to create new notification.',
	NOTIFICATION_TYPE: 'Notification type?',
	SELECT_NOTIFICATION_PLACEHOLDER: 'Select notification placeholder',
	CHOOSE_WHICH_USER_GROUPS_TO_NOTIFY: 'Choose which user groups to notify:',
	NOTIFY_ALL_GROUPS: 'Notify all groups',
	MANAGER: 'Manager',
	DEVELOPER: 'Developer',
	ADD_NOTIFICATION_CONTENT: 'Add notification content',
	SUSPEND: 'Suspend',
	SUSPENDED: 'Suspended',
	DEACTIVE: 'Deactive',
	PROVISIONED: 'Provisioned',
	ENDPOINT_COUNTRY: 'Endpoint country',
	ENDPOINT_STATE: 'Endpoint state',
	ENDPOINT_CITY: 'Endpoint city',
	ENDPOINT_STREET_ADDRESS: 'Endpoint street address',
	AS_OF: 'Processed as of',
	VIEW_ALL_NOTIFICATIONS: 'View All Notifications',
	NOTIFICATION_SCOPE: 'Notification scope',
	NOTIFICATION1: 'Info',
	NOTIFICATION2: 'Error',
	NOTIFICATION3: 'Warning',
	NOTIFICATION4: 'Confirmation',
	GLOBAL_NOTIFICATION: 'Global notification',
	COMPANY_RELATED_NOTIFICATION: 'Company related notification',
	MUST_BE_MORE_THAN: 'Must be more than ',
	MUST_BE_LESS_THAN: 'Must be less than ',
	MUST_BE_LESS_THAN_OR_EQUAL_TO: 'Must be less than or equal to ',
	GLOBAL: 'Global',
	COMPANY_RELATED: 'Company related',
	RECENT_NOTIFICATIONS: 'System Alerts and Notifications',

	networkEntitlement: 'Network Entitlement ID',
	networkType: 'Network Type',
	transferStatus: 'IMSI Transfer Status',
	rateplan: 'Rate Plan',
	FAILURE: 'Failure',
	PENDING_REIMSI_ATTEMPT: 'Pending Re-IMSI Attempt',
	REIMSI_QUEUED: 'Re-IMSI Queued',
	PENDING_REIMSI_DELIVERED: 'Pending Re-IMSI Delivered',
	'PENDING_RE-IMSI_DELIVERY_FAILED': 'Pending Re-IMSI Delivery Failed',

	'PENDING REIMSI ATTEMPT': 'Pending RE-IMSI Attempt',
	'REIMSI PENDING ATTEMPT': 'Pending RE-IMSI Attempt',
	'PENDING REIMSI DELIVERED': 'Pending Transfer Delivered',
	'Re-IMSI Delivery Failed': 'Transfer Delivery Failed',
	'REIMSI FAILED': 'Re-IMSI Failed',
	'REIMSI QUEUED': 'Re-IMSI Queued',
	'REIMSI PEDING ATEMPT': 'Pending Re-IMSI Attempt',
	'REIMSI PENDING ATEMPT': 'Pending Re-IMSI Attempt',
	'REIMSI PENDING DELIVERED': 'Pending Re-IMSI Delivered',
	'REIMSI SUCCESS': 'Re-IMSI Success',
	failure: 'failure',

	'ID_PENDING REIMSI ATTEMPT': 'Pending RE-IMSI Attempt',
	'ID_REIMSI PENDING ATTEMPT': 'Pending RE-IMSI Attempt',
	'ID_PENDING REIMSI DELIVERED': 'Pending Transfer Delivered',
	'ID_Re-IMSI Delivery Failed': 'Transfer Delivery Failed',
	'ID_REIMSI FAILED': 'Re-IMSI Failed',
	'ID_REIMSI QUEUED': 'Re-IMSI Queued',
	'ID_REIMSI PEDING ATEMPT': 'Pending Re-IMSI Attempt',
	'ID_REIMSI PENDING ATEMPT': 'Pending Re-IMSI Attempt',
	'ID_REIMSI PENDING DELIVERED': 'Pending Re-IMSI Delivered',
	'ID_REIMSI SUCCESS': 'Re-IMSI Success',

	CONSUMED_BYTES: 'Consumed megabytes',
	CONSUMED_SMSES: 'Consumed SMS',
	TO_DATE: 'to date',

	CHART_EXISTS_IN_SETTINGS:
		'This graph already exists in your settings. Only one type of graph is allowed.',
	NO_CHART_DATA: 'Missing Graph Data',
	STATES_MESSAGE: 'No state available.',
	SELECT_RATE_PLAN: 'Select Rate plan',
	CHANGE_RATE_PLAN: 'Change Rate Plan',
	CURRENT_RATE_PLAN_FOR_ENDPOINT_ID: 'Current Rate Plan for Endpoint ID ',
	IS: 'is',
	CONFIRM_RATE_PLAN_CHANGE: 'Confirm rate plan change',
	RATE_PLAN_WILL_BE:
		'Once you initiate this change, the selected endpoint’s rate plan will be',
	WOULD_YOU_LIKE_TO_INITIATE_CHANGE_NOW:
		'Would you like to initiate change now?',
	RATE_PLAN_CHANGE_IS_SUCCESSFULLY_SCHEDULED:
		'Rate plan change is successfully scheduled',
	FOR: 'for',
	RATE_PLAN_CHANGE_IS_SUCCESSFULLY_UPDATED:
		' Rate plan change is successfully updated',
	RATE_PLAN_CHANGE_COULD_NOT_BE_SCHEDULED_SUCCESSFULLY:
		'Rate plan change could not be scheduled successfully',
	RATE_PLAN_CHANGE_COULD_NOT_BE_CARRIED_OUT_SUCCESSFULLY:
		'Rate plan change could not be carried out successfully',
	ENDPOINT_RATE_PLAN: 'Rate plan',
	DOWNLOAD_TEMPLATE_BATCH: 'Download template',
	BATCH_INSTRUCTIONS_NOTE: 'Important: Please read {linkToInstructions} before downloading/uploading bulk files.',
	CONFIRM_BATCH_FILE_UPLOAD: 'Confirm batch file upload',
	SCHEDULED_ACTION_ON: 'Scheduled Action on',
	FILE_MUST_BE_SUMITTED_WITH_CSV:
		'Files must be submitted with .csv extension.',
	MAXIMUM_SIMS_PER_UPLOAD: 'Maximum SIMs per upload is 10,000.',
	SIMS_HAVE: 'SIMs have',
	CHANGE_FOR_ALL_SELECTED_SIMS:
		'Select different rate plan to apply change for all selected SIMs',
	YOU_HAVE_CHOSEN_TO_CHANGE_THE_RATE_PLAN_OF_THE:
		'You have chosen to change the rate plan of the',
	SELECTED_SIMS_TO: 'selected SIMs to',
	SIMS_RATE_PLAN_SUCCESSFULLY_REQUESTED: 'SIM rate plans successfully updated',
	SCHEDULE_RATE_PLAN_SUCCESSFUL:
		'Rate plan for multiple SIMs is successfully scheduled',
	SIM_RATE_PLAN_CHANGE_FOR: 'SIM rate plan change for',
	MULTIPLE_RATE_PLAN_CHANGE_SCHEDULE_ERROR:
		'Multiple SIM rate plan change could not be scheduled successfully',
	MULTIPLE_RATE_PLAN_CHANGE_ERROR:
		'Multiple SIM rate plan change could not be requested successfully',
	SAME_RATE_PLAN_SELECTED: 'Same rate plan selected',
	REASON: 'reason',

	API_DOCUMENTATION: 'API Documentation',
	'Cellular - Active': 'Cellular - Activated',
	'Satellite - Active': 'Satellite - Activated',
	'Cellular - Terminate': 'Cellular - Terminated',
	'Satellite - Terminate': 'Satellite - Terminated',
	CHANGE_RATE_PLAN_NOT_ALLOWED: 'Change rate plan is not allowed',
	reimsiqueued: 'Re-IMSI Queued',
	reimsifailed: 'Re-IMSI Failed',
	reimsisuccess: 'Re-IMSI Success',
	reimsipendingattempt: 'Pending Re-IMSI Attempt',
	reimsipendingdelivered: 'Pending Re-IMSI Delivered',
	imsiPendingMessage:
		'If Device is in Pending status for more than 72 hours, please contact Customer Support',
	ADD_ANOTHER_ACCOUNT: 'Add another account',
	FILTER_DOES_NOT_HAVE_VALID_DATA: 'API error, please contact support.',
	FAILOVER: 'Failover',
	CANCEL_PENDING_FAIL_TITLE: 'Pending change cancellation fail',
	CANCEL_PENDING_FAIL_TEXT:
		'Pending change cancellation could not be successfully completed this time',
	CANCEL_PENDING_SUCCESS_TITLE: 'Pending change cancellation success',
	CANCEL_PENDING_SUCCESS_TEXT:
		'Pending change cancellation is successfully completed',
	CANCEL_CHANGE: 'Cancel change',
	CAUTION: 'Caution',
	CANCEL_PROMPT_TITLE: 'Are you sure you want to cancel pending change?',
	CANCEL_PROMPT_TEXT: 'This action cannot be undone.',
	CONFIRM_CANCELLATION: 'Confirm cancellation',
	NO_RATE_PLANS_AVAILABLE: 'No rate plans available',
	BULK_UPLOAD: 'Batch Upload',
	MULTI_ENDPOINT_ACTION: 'Multi-Endpoint Action',
	PLEASE_SELECT_MORE_SIMSS: 'Please select two or more Endpoints',
	M2M_ACCOUNT_ID: 'M2M Account Id',
	REPORTS_PLATFORM: 'M2M Platform',
	ENDPOINT_CHART_SECOND_CARD: 'Recent sessions',
	ENDPOINT_CHART_SECOND_CARD_ON: 'On',
	ENDPOINT_CHART_THIRD_CARD: 'Endpoint network location',
	NO_AVAILABLE_FILTERS: 'No available filters.',
	NO_AVAILABLE_FILTERS_SUBTITLE:
		'There are No Available Filters to show you right now',

	// Developer API
	GETTING_STARTED: 'Getting started',
	API_SANDBOX: 'API Sandbox',
	DEVELOPER_API: 'Developer API',
	GETTING_STARTED_WITH_API_T: 'Getting started with API',
	GETTING_STARTED_WITH_API_P:
		'Before you can make your first API call, your source IP address (server) must be whitelisted. Source IP is typically configured during onboarding process when API access is created. If you receive any errors it is likely related to blocked access to AT&T IoT Gateway.',
	GETTING_STARTED_WITH_API_UL_T:
		'Please ensure you have taken the following steps to get started with development:',
	GETTING_STARTED_WITH_API_LI1:
		'- You have set up a publicly accessible server and provided us its IP address during onboarding.',
	GETTING_STARTED_WITH_API_LI2:
		'- Your Source IP is whitelisted to access AT&T IoT Gateway.',
	GETTING_STARTED_WITH_API_LI3:
		'- Your company Administrator has given you API username and password that was created when API service was enabled.',
	GETTING_STARTED_WITH_API_LI4:
		'- You have access to your resource server to execute curl commands, or use connection apps written in your preferred language below.',
	GET_OAUTH_TOKEN_T: 'Get OAuth access token',
	GET_OAUTH_TOKEN_P:
		'Get OAuth token from AT&T IoT Identity using username and password supplied by your administrator using your preferred method below. Token is valid for 7 days.',
	GETTING_STARTED_WITH_API_CONNECTORS_T: 'API connectors',
	GETTING_STARTED_WITH_API_CONNECTORS_P:
		'Select from the examples below how you wish to connect to our API.',
	TEST_CONNECTION_T: 'Test connection to the IoT Gateway',
	TEST_CONNECTION_P: 'Curl example:',
	GETTING_STARTED_WITH_API_NEXT_STEP_T: 'Desired Result',
	GETTING_STARTED_WITH_API_NEXT_STEP_P:
		'If you get HTTP Response Code 200 OK, you are all set to begin your development.',
	GETTING_STARTED_WITH_API_ERRORS_T: 'Troubleshooting Connection Errors',
	GETTING_STARTED_WITH_API_ERRORS_P:
		'If you get any errors, first check to see if your authetication is properly set in the HTTPHeader, and the Access Token has not expired and needs to be refreshed. Also, ping AT&T IoT Gateway to check if your source IP is white-listed. If you get unexpected results you may file a troubleshooting ticket in support section.',
	API: 'API',
	SANDBOX_SPECIFIES_THE_LIST:
		'The Swagger sandbox below specifies the list of resources that are available in the REST API and the operations that can be called on those resources. The document also specifies the list of parameters to an operation, including the name and type of the parameters, whether the parameters are required or optional, and information about acceptable values for those parameters.',
	JSON_SCHEMA_INCLUDES:
		'JSON Schema is included that describes the structure of the request body that is sent to an operation in a REST API, along with the JSON schema that describes the structure of any response bodies that are returned from an operation.',

	GBCM_OPERATOR: 'Speedcast',
	ATT_OPERATOR: 'AT&T',

	CREATE_CUSTOM_REPORT: 'Create Custom Report',
	YOU_MUST_CREATE_FILTER_BEFORE_EXPORT:
		'You must create a filter before a list can be exported.',
	CREATE_REPORT: 'Create Report',
	CHART_LEGEND_Active: 'Activated',
	CHART_LEGEND_Terminate: 'Terminated',
	WE_ARE_SORRY_THERE_IS_NO_DATA_TO_EXPORT:
		'We are sorry, there is no data to export',
	EXPORT_FILTERED_DATA: 'Export filtered data',
	ID_DASHBOARD_CHARTS_summaryofmonthlyendpointusage:
		'Summary of monthly endpoint usage',
	ID: 'ID',
	TAB: 'Tab',
	SORT_BY: 'Sort by',
	SELECT_CHART_TYPE_TO_ADD: 'Select graph type to add',
	RECREDENTIAL_STATUS: 'Re-Credential Status',
	RECREDENTIAL_TRANSFER_STATUS: 'Re-Credential Status',
	recredentialTransferStatus: 'Re-Credential Status',
	M2MACCOUNTID: 'M2M Account ID',
	HOLDING_ACCOUNT_ID: 'Holding Account ID',
	RE_CREDENTIAL: 'Re-Credential',
	SELECT_OPERATOR: 'Select operator',
	RE_CREDENTIAL_SIM: 'Re-credential SIM',
	CURRENT_PLATFORM: 'Current platform',
	YOU_HAVE_CHOSEN_TO_RECREDENTIAL_THIS_SIM:
		'You have chosen to re-credential this SIM to',
	YOU_HAVE_CHOSEN_TO_RECREDENTIAL_THESE_SIMS:
		'You have chosen to re-credential these SIMs to',
	YOU_MAY_INTIATE_THE_CHANGE_NOW: 'You may initiate the change now',
	RE_CREDENTIALING_STATUS: 'RE-Credentialing status',
	RE_CREDENTIALING_IS_SUCCESSFULLY_INITIATED:
		'Re-Credentialing is successfully initiated',
	RE_CREDENTIALING_IS_SUCCESSFULLY_SCHEDULED:
		'Re-Credentialing is successfully scheduled',
	RE_CREDENTIALING_IS_SUCCESSFULLY_SCHEDULED_FOR:
		'Re-Credentialing is successfully scheduled for',
	RE_CREDENTIALING_COULD_NOT_BE_INITIATED_SUCCESSFULLY:
		'Re-Credentialing could not be initiated successfully',
	RE_CREDENTIALING_COULD_NOT_BE_SCHEDULED_SUCCESSFULLY:
		'Re-Credentialing could not be scheduled successfully',
	ENDPOINT_HISTORY: 'Endpoint History',
	SHIPPED_DATE: 'Shipped date',
	DIAGNOSTIC_BUTTON: 'Start Diagnostics',
	DEVICE_REGISTRATION_INFO_BUTTON: 'Device Registration Info',
	ENDPOINT_REGISTRATION_DETAILS: 'Endpoint registration details',
	eventTime: 'Date and time stamp',
	carrierName: 'Carrier name',
	registrationType: 'Registration type',
	NO_DATA_TO_DISPLAY_AT_THE_MOMENT: 'No data to display at the moment',
	status: 'Status',
	iccid: 'ICCID',
	carrier: 'Carrier',
	ATTACHED_CARRIER_NAME: 'Attached Carrier Name',
	M2MPlatform: 'M2M Platform',
	simState: 'Getting device status',
	'Endpoint Provisioning Information': 'Endpoint Provisioning Information',
	'M2M Platform': 'M2M Platform',
	'Network Connection': 'Network Connection',
	simStatusM2M: 'Getting device status',
	billingStatus: 'Getting device status',
	EndpointProvisioningInformation: 'Endpoint Provisioning Information',
	trafficAllowed: 'Get device provisioning information',
	permitPassingTraffic: 'Get device provisioning information',
	verifySimState: 'Get device information',
	NetworkConnection: 'Network Connection',
	verifyConnection: 'Get device network',
	successfullyConnectedToNetwork_inSession: 'Get device network',
	verifyIPSession: 'Get device IP',
	dataSessionCurrentlySuccessful: 'Get device IP',
	dd_inventory: 'Inventory',
	dd_activated: 'Activated',
	dd_active: 'Activated',
	dd_inactivated: 'Inactive',
	dd_inactive: 'Inactive',
	dd_deactivated: 'Deactivated',
	dd_retired: 'Retired',
	dd_purged: 'Purged',
	dd_testready: 'Test Ready',
	dd_activationready: 'Activation Ready',
	dd_replaced: 'Replaced',
	dd_trial: 'Trial',
	dd_start: 'Start',
	dd_transfer: 'Transfer',
	dd_globalintransitsource: 'Global In Transit Source',
	dd_globalintransitdestination: 'Global In Transit Destination',
	dd_globaltransferred: 'Global Transferred',
	dd_other: 'Other',
	dd_permitpassingtraffic: 'Passing Traffic Permitted',
	dd_notpermitpassingtraffic: 'Passing Traffic Not Permitted',
	dd_allowedToUseWirelessNetworkResources: 'Allowed TollSec Wireless Resources',
	dd_barredFromNetwork: 'Barred From Network',
	dd_trafficnotallowed_simstate: 'Traffic Not Allowed SIM State',
	dd_suspendedByServiceProvider: 'Suspended by Service Provider',
	dd_suspendedDueToUsageLimit: 'Suspended Due to Usage Limit',
	dd_trafficNotAllowed_others: 'Traffic Not Allowed (other)',
	dd_successfullyconnectedtonetwork_insession:
		'Successfully Connected to Network (In Session)',
	dd_notsuccessfullyconnectedtonetwork_insession:
		'Not Connected to Network (In Session)',
	dd_validregistration_validnetworkactivity:
		'Valid Registration (Valid Network Activity)',
	dd_validregistration_nonetworkactivity:
		'Valid Registration (No Network Activity)',
	dd_validgsmregistrationonly: 'Valid GSM Registration Only',
	dd_novalidregistration_hasactivityinlast24h:
		'No Valid Registration (Has Activity in Last 24 Hours)',
	dd_novalidregistration_hasauthrequestinlast24h_nolocupdate:
		'No Valid Registration (Has Auth Request in Last 24 Hours)',
	dd_novalidregistration_haslocupdatesmorethan24hago:
		'No Valid Registration (Has Local Updates More Than 24 Hours Ago)',
	dd_novalidregistration_hasauthrequestmorethan24hago_noactivity:
		'No Valid Registration (Has Auth Request More Than 24 Hours Ago)',
	dd_novalidregistration_nonetworkactivity:
		'More Than 24 Hours Ago (No Network Activity)',
	dd_datasessioncurrentlysuccessful:
		'Device Not Connected Currently. Prior IP Sessions Successful.',
	dd_notdatasessioncurrentlysuccessful: 'Data Session Currently Not Successful',
	dd_datasessionpastsuccessful: 'Data Session Past Successful',
	dd_connectionattemptfailed_invalidcredentials:
		'Connection Attempt Failed (Invalid Credentials)',
	dd_nodatasessionsuccessfulhistory: 'No Data Session Successful History',
	dd_: '',
	dd_allowedtousewirelessnetworkresources: 'Allowed TollSec Wireless Resources',
	attached: 'Attached',
	detached: 'Detached',
	RECREDENTIALED: 'Re-credentialed',
	RECREDENTIALING: 'Re-credentialing',
	CHANGE_RECREDENTIAL: 'Re-credential only allowed for the same platforms',
	RECREDENTIAL_DESCRIPTION:
		'Tip: When you re-credential and select multiple SIMs, you can only select one re-credential platform for multiple SIMs selected',
	RECREDENTIAL_NOT_ALLOWED: 'Re-credential is not allowed',
	RECREDENTIAL_SUCCESSFULLY_REQUESTED: 'SIMs is successfully initiated',
	MULTIPLE_RECREDENTIAL_CHANGE_ERROR:
		'Re-credentialing for multiple SIMs could not be initiated successfully',
	MOBILE_NETWORK_REGISTRATION_HISTORY: 'Mobile network registration history',
	ENDPOINT_DAILY_USAGE: 'Endpoint daily usage',
	ENDPOINT_CYCLE_USAGE: 'Endpoint Cycle to Date Usage',
	CONFIRM_RE_CREDENTIALING: 'Confirm Re-credentialing',
	PENDING_RECREDENTIAL: 'This endpoint is pending re-credential',
	ENDPOINTS_ARE: 'endpoints are selected for the export',
	ENDPOINT_IS: 'endpoint is selected for the export',
	CUSTOM_REPORT_WILL_BE_AVAILABLE:
		'This custom report will be available in the reports section',
	YOU_CAN_APPEND_CUSTOM_NAME: 'You can append custom name to the report here',
	CUSTOM_REPORT_NAME_APPENDIX: 'Custom report name appendix',
	ADD_CUSTOM_REPORT_NAME_APPENDIX:
		'Add custom report name appendix (only digits and letters allowed)',
	CUSTOM_REPORT: 'Custom report',
	CUSTOM_REPORT_CREATED: 'Custom report successfully created',
	GO_TO_REPORTS: 'Go to reports',
	DIAGNOSTICS_FOR_THIS_ENDPOINT:
		'Diagnostics for this endpoint is currently unavailable',
	ENDPOINT_DATA_INFORMATION: 'Endpoint data information',
	LAST_SESSION: 'Last Session',
	CARRIER_ATTACHED: 'Carrier Attached',
	APN_DCP: 'APN',
	IP_ADDRESS: 'IP Address',
	LAST_SENT_SMS_MO: 'Date & Time of Last sent SMS MO',
	LAST_SENT_SMS_MT: 'Date & Time of Last sent SMS MT',
	TEST_DETAILS: 'Test details',
	CHECK_BLOCKED_STATUS: 'Check blocked status',
	BLOCKED_STATUS: 'Blocked status',
	RETRY_TEST: 'Retry test',
	MOBILE_NETWORK_TEST: 'Mobile network test',
	CHECK_BLOCKED_SUB: 'Check Blocked Sub',
	TROUBLESHOOT_DCP: 'Troubleshoot DCP',
	locationUpdate: 'Location Update',
	pdpStatus: 'PDP Status',
	cancelLocation: 'Cancel Location',
	CANCEL_LOCATION: 'Cancel Location',

	DASHBOARD_CHART_CountofDailyEndpointSMSbyPlatformDCP_CELLULARDescription:
		'Shows total number of SMS messages sent per day on monthly basis across DCP platform',
	DASHBOARD_CHART_CountofDailyEndpointSMSbyPlatformDCP_CELLULAR:
		'SMS per day (DCP)',
	DASHBOARD_CHART_CountofMonthlyEndpointSMSbyPlatformDCP_CELLULAR:
		'SMS per month (DCP)',
	DASHBOARD_CHART_SumofDailyEndpointUsagebyPlatformDCP_CELLULAR:
		'Total Daily Endpoint usage (DCP)',
	DASHBOARD_CHART_SumofDailyEndpointUsagebyPlatformACC_CELLULAR:
		'Daily Data Usage (ACC)',
	DASHBOARD_CHART_SumofMonthlyEndpointUsagebyPlatformDCP_CELLULAR:
		'Monthly Data Usage (DCP)',
	DASHBOARD_CHART_CountofMonthlyEndpointSMSbyPlatformDCP_CELLULARDescription:
		'Shows total number of SMS messages sent per month across DCP platform',
	DASHBOARD_CHART_SumofDailyEndpointUsagebyPlatformDCP_CELLULARDescription:
		'Shows total data usage per day on monthly basis across DCP platform',
	DASHBOARD_CHART_SumofDailyEndpointUsagebyPlatformACC_CELLULARDescription:
		'Shows total data usage per day on monthly basis across ACC platform',
	DASHBOARD_CHART_SumofMonthlyEndpointUsagebyPlatformDCP_CELLULARDescription:
		'Shows total data usage per month across DCP platform',
	DASHBOARD_CHART_CountofMonthlyEndpointSMSbyPlatformCCIP_CELLULAR:
		'SMS per Month (CCIP)',
	DASHBOARD_CHART_CountofMonthlyEndpointSMSbyPlatformCCIP_CELLULARDescription:
		'Shows total number of SMS messages sent per month across CCIP platform',
	DASHBOARD_CHART_CountofDailyEndpointSMSbyPlatformCCIP_CELLULAR:
		'SMS per day (CCIP)',
	DASHBOARD_CHART_CountofDailyEndpointSMSbyPlatformCCIP_CELLULARDescription:
		'Shows total number of SMS messages sent per day across CCIP platform',
	DASHBOARD_CHART_SumofDailyEndpointUsagebyPlatformCCIP_CELLULAR:
		'Total Daily Endpoint usage (CCIP)',
	DASHBOARD_CHART_SumofDailyEndpointUsagebyPlatformCCIP_CELLULARDescription:
		'Shows total data usage per day on monthly basis across CCIP platform',
	DASHBOARD_CHART_SumofMonthlyEndpointUsagebyPlatformCCIP_CELLULAR:
		'Monthly Data Usage (CCIP)',
	DASHBOARD_CHART_SumofMonthlyEndpointUsagebyPlatformCCIP_CELLULARDescription:
		'Shows total data usage per month across CCIP platform',
	DASHBOARD_CHART_countByCountry_CELLULAR: 'Endpoints by Country',
	DASHBOARD_CHART_countByCountry_CELLULARDescription:
		'Shows number of endpoints across multiple Countries.',
	DASHBOARD_CHART_countByRoamingCountry_CELLULAR:
		'Endpoints by Roaming Country (cellular)',
	DASHBOARD_CHART_countByRoamingCountry_CELLULARDescription:
		'Shows number of endpoints by Roaming Country.',
	DASHBOARD_CHART_countByRoamingCarrier_CELLULAR:
		'Endpoints by Roaming Carrier (cellular)',
	DASHBOARD_CHART_countByRoamingCarrier_CELLULARDescription:
		'Shows number of Cellular endpoints by Roaming Carrier.',
	DASHBOARD_CHART_countByRoamingCarrier_SATELLITE:
		'Endpoints by Roaming Carrier (satellite)',
	DASHBOARD_CHART_countByRoamingCarrier_SATELLITEDescription:
		'Shows number of Satellite endpoints by Roaming Carrier.',

	SMS_HISTORY: 'SMS History',
	MAXIMUM_NUMBER_OF_CHARACTERS: 'Maximum number of characters: ',
	SEND_SMS_MESSAGE: 'Send SMS',
	SMS_NOTIFICATION_TITLE: 'SMS Notification',
	SMS_NOTIFICATION_MESSAGE: 'Response error: ',
	SMS_NOTIFICATION_MESSAGE_SUCCESS: 'SMS Successfully sent',

	REVIEW: 'Review',
	PLATFORM_DETAILS: 'Platform Details',

	UPDATING: 'Updating',
	AUTOMATION_RULE_SUCCESSFULLY_DELETED: 'Automation rule successfully deleted',
	AUTOMATION_DELETE_ERROR:
		'Failed to delete automation rule, please try again.',
	FETCH_DATA_ERROR: 'Unable to fetch data',
	CHANGED_BY: 'Changed By',
	EMAIL_SUB: 'Send email',
	CTD_USAGE: 'Cycle To Date Data Usage',
	PAST24H_DATA_USAGE_EXCEEDED: 'Data Usage Exceeded in Past 24 Hours',
	SMS_USAGE: 'Cycle To Date SMS Usage',
	EXCEEDLIMIT: 'Exceeds',
	APPROACHLIMIT: 'Approaches',
	trigger: 'Trigger',
	TWENTYFOURHOURS: 'Last 24 Hours',
	CTD: 'Cycle to Date',
	ADD_RULE_SUCCESS: 'Rule created',
	ADD_RULE_ERROR: 'Failed to create automation rule, please try again.',
	EDIT_RULE_SUCCESS: 'Rule edited',
	EDIT_RULE_ERROR: 'Failed to edit automation rule, please try again.',
	ACC_AUTOMATION: 'ACC Automation',
	ACC_ACCOUNT_ID: 'ACC Account ID',
	CREATE_NEW_AUTOMATION_RULE: 'Create New Automation Rule',
	DCP_AUTOMATION: 'DCP Automation',
	DCP_ACCOUNT_ID: 'DCP Account ID',
	ACC_ACCOUNT: 'ACC account',
	RULE: 'Rule',
	ACC_IF: 'If',
	TRESHOLD: 'Threshold',
	THEN: 'Then',
	ACC_RULE_NAME: 'Rule name',
	SAVE_AND_ACTIVATE: 'Save & Activate',
	DCP_ACCOUNT: 'DCP account',
	SELECT_BY: 'Select by',
	DCP_BYTES_SENT: 'bytes sent',
	SMSES_SENT: 'SMSes sent',
	SMSES: 'SMSes',
	DASHBOARD_FEEDBACK: 'Dashboard',
	MUST_BE_BETWEEN: 'Must be between ',
	COMPANIES_STEPS: 'Companies Steps',
	USER_NAME: 'USER NAME',
	ID_DASHBOARD_CHARTS_countofmonthlyendpointsmsbyplatformdcp: 'SMS per Month',
	ID_DASHBOARD_CHARTS_countofdailyendpointsmsbyplatformdcp: 'SMS per Day',
	ID_DASHBOARD_CHARTS_sumofdailyendpointusagebyplatformdcp:
		'Total Daily Endpoint Usage',
	ID_DASHBOARD_CHARTS_sumofdailyendpointusagebyplatformacc: 'Daily Data Usage',
	ID_DASHBOARD_CHARTS_sumofmonthlyendpointusagebyplatformdcp:
		'Monthly Data Usage',
	DASHBOARD_CHART_DeviceType_SATELLITE: 'Device Type (satellite)',
	DASHBOARD_CHART_DeviceType_CELLULAR: 'Device Type (cellular)',
	DASHBOARD_CHART_DeviceType_SATELLITEDescription:
		'Shows total number of devices across different types',
	DASHBOARD_CHART_DeviceType_CELLULARDescription:
		'Shows total number of devices across different types',
	AUTOMATION_ALERTS: 'Automation alerts',
	STATE: 'State',
	COMPANY_EMAIL: 'Company email',
	CUSTOM_FIELD_LABELS: 'Custom Field Labels',
	MY_COMPANY: 'My Company',
	AUTOMATION_ACCOUNT: 'Account',
	UPDATE_CUSTOM_FIELD_LABELS_SUCCESS: 'Custom Labels Settings Saved',
	UPDATE_CUSTOM_FIELD_LABELS_ERROR: 'Could Not Save Custom Label Settings',
	WRITE_SMS_MESSAGE_HERE: 'Write message here...',
	RE_CREDENTIALING_ALLOWED: 'Re-credentialing not allowed.',
	DELETE_AUTOMATION_RULE_QUESTION: 'Delete Automation Rule?',
	DELETE_AUTOMATION_RULE_MESSAGE:
		'Are you sure you would like to delete automation rule ',
	PLATFORM_SUCCESSFULLY_DELETED: 'Platform successfully deleted',
	PLATFORM_DELETE_ERROR: 'Failed to delete platform, please try again.',
	CELLULAR: 'Cellular',
	SATELLITE: 'Satellite',
	SAVE_CUSTOM_LABELS: 'Save Custom Labels',

	// ccip
	ENDPOINT_PROVISIONING_INFORMATION: 'Endpoint provisioning information',
	CCIP_3G: '3G',
	VOICE_ENABLED: 'Voice Enabled',
	DATA_ENABLED: 'Data Enabled',
	SMS_ENABLED: 'SMS Enabled',
	VOICE_ROAMING_ENABLED: 'Voice Roaming Enabled',
	DATA_ROAMING_ENABLED: 'Data Roaming Enabled',
	CCIP_LTE: 'Lte',
	LTE_ENABLED: 'LTE Enabled',
	ROAMING_ENABLED: 'Roaming Enabled',
	MOBILE_NETWORK_ACCESS: 'Mobile network access',
	VOICE_ONLY: 'Voice only',
	REGISTERED_ON_NETWORK: 'Registered on Network',
	REGISTERED_ON_3G_NETWORK: 'Registered on 3G Network',
	THIS_EMAIL_ADDRESS_IS_ALREADY_IN_USE: 'This email address is already in use',
	THIS_EMAIL_ADDRESS_DOES_NOT_EXIST: 'This email address does not exist',
	USER_SUCCESSFULLY_DELETED: 'User successfully deleted',
	USER_DELETE_ERROR: 'Failed to delete user, please try again.',
	CATEGORIES: 'Categories',
	TRIGGERS: 'Triggers',
	FETCH_DATA_LOADING_MESSAGE: 'Please wait while we load the data',
	M2M_ACCOUNT_ID_PLATFORM: 'M2M Account ID',
	M2MACCOUNTS: 'M2M Account',

	FROM_VALUE: 'FROM VALUE',
	TO_VALUE: 'TO VALUE',

	SEARCH_REPORTS: 'Search reports',
	SEARCH_INVOICES: 'Search invoices',

	CUSTOM_DATA_FIELDS: 'Custom data fields',
	ENDPOINT_ACTIONS: 'Endpoint Actions for',
	RE_CREDENTIAL_NOT_ALLOWED: 'Re-Credential not allowed',
	EDIT_ENDPOINT: 'Endpoint Actions',
	CURRENT_SIM_STATE_FOR_ENDPOINT_ID: 'Current Sim State For Endpoint ID',

	NETWORK_ENTITLEMENT_NAME: 'Network Entitlement Name',
	BLOCK_PREMIUM_NUMBERS: 'Block Premium Numbers',
	BLOCK_INTERNATIONAL_MO: 'Block International SMS MO Except Home Country',
	HLR_TEMPLATE: 'HLR Template',
	HOTLINE_NUMBER: 'Hotline Number',
	FACETIME: 'FACETIME',
	VOLTE: 'Volte',
	GDSP_SESSION_ACTIVE: 'Active',
	GDSP_SESSION_BYTES_IN: 'Bytes In',
	GDSP_SESSION_BYTES_OUT: 'Bytes Out',
	GDSP_SESSION_LAST_IP_ADDRESS: 'Last Ip Address',
	GDSP_SESSION_LAST_STARTED_TIMESTAMP: 'Last Started Timestamp',
	GDSP_SESSION_LAST_UPDATED_TIMESTAMP: 'Last Updated Timestamp',
	GDSP_SESSION_LAST_TERMINATED_TIMESTAMP: 'Last Terminated Timestamp',
	GDSP_SESSION_LAST_DATA_TRANSMITED_TIMESTAMP:
		'Last Data Transmitted Timestamp',
	GDSP_SESSION_PREVIOUS_NORMAL_TIMESTAMP: 'Previous Normal Timestamp',
	GDSP_SESSION_LAST_SMS_MO_TIMESTAMP: 'Last SMS MO Timestamp',
	GDSP_SESSION_LAST_SMS_MT_TIMESTAMP: 'Last SMS MT Timestamp',
	GDSP_SESSION_LAST_SMS_MT_STATUS: 'Last SMS MT Status',
	GDSP_SESSION_LAST_SMS_MO_STATUS: 'Last SMS MO Status',
	GDSP_SESSION_LAST_WAKEUP_REQUEST_TIMESTAMP: 'Last Wakeup Request Timestamp',
	GDSP_SESSION_LAST_WAKEUP_REQUEST_TYPE: 'Last Wakeup Request Type',
	GDSP_SESSION_LAST_WAKEUP_STATUS: 'Last Wakeup Status',
	GDSP_SESSION_LAST_WAKEUP_STATUS_TIMESTAMP: 'Last Wakeup Status Timestamp',
	GDSP_SESSION_LAST_NOTIFICATION_FAILURE_TIMESTAMP:
		'Last Notification Failure Timestamp',
	GDSP_SESSION_LAST_NOTIFICATION_FAILURE_REASON:
		'Last Notification Failure Reason',

	CUSTOM_FIELD_1: 'Custom Field 1',
	CUSTOM_FIELD_2: 'Custom Field 2',
	CUSTOM_FIELD_3: 'Custom Field 3',
	CUSTOM_FIELD_4: 'Custom Field 4',
	CUSTOM_FIELD_5: 'Custom Field 5',
	CUSTOM_FIELD_6: 'Custom Field 6',
	CUSTOM_FIELD_7: 'Custom Field 7',
	CUSTOM_FIELD_8: 'Custom Field 8',
	CUSTOM_FIELD_9: 'Custom Field 9',
	CUSTOM_FIELD_10: 'Custom Field 10',

	/* ----- GDSP ------- */
	REGISTRATION_STATUS: 'Registration Status',
	NETWORK_CONNECTIONS: 'Network Connections',
	PROVISIONED_DATE_AND_TIME: 'Provisioned Date & Time',

	// GDSP SIM STATE STATUS
	M2M_PLATFORM_STATUS_A: 'Active Live',
	M2M_PLATFORM_STATUS_B: 'Inactive New',
	M2M_PLATFORM_STATUS_C: 'Active Sleep',
	M2M_PLATFORM_STATUS_D: 'Inactive Stopped',
	M2M_PLATFORM_STATUS_I: 'Migrated from other GDSP Environment',
	M2M_PLATFORM_STATUS_N: 'Not provisioned',
	M2M_PLATFORM_STATUS_O: 'Migrated to other GDSP Environment',
	M2M_PLATFORM_STATUS_R: 'Active Ready',
	M2M_PLATFORM_STATUS_S: 'Active Suspend',
	M2M_PLATFORM_STATUS_T: 'Active Test',
	M2M_PLATFORM_STATUS_U: 'Active Standby',
	M2M_PLATFORM_STATUS_V: 'Inactive Transformed',
	M2M_PLATFORM_STATUS_W: 'Suspend Regulatory',
	M2M_PLATFORM_STATUS_Z: 'Terminated',
	M2M_PLATFORM_STATUS_NOT_AVAILABLE: 'Not Available',

	// GDSP Session tab Status Codes
	// lastWakeupStatus
	LASTWAKEUPSTATUS_IR: 'Wakeup call invalid',
	LASTWAKEUPSTATUS_FI: 'Wakeup initiation failed',
	LASTWAKEUPSTATUS_SI: 'Wakeup initiation succeeded',
	LASTWAKEUPSTATUS_F: 'Wakeup delivery failed',
	LASTWAKEUPSTATUS_S: 'Wakeup delivery succeeded',
	TOOLTIP_LASTWAKEUPSTATUS_IR: 'A bad API request',
	TOOLTIP_LASTWAKEUPSTATUS_FI: 'Failure from the SMSC',
	TOOLTIP_LASTWAKEUPSTATUS_SI: 'Successful submission to the SMSC',
	TOOLTIP_LASTWAKEUPSTATUS_F:
		'SMSC has issued a delivery notification indicating failure',
	TOOLTIP_LASTWAKEUPSTATUS_S:
		'SMSC has issued a delivery notification indicating success',

	// lastSmsMtStatus
	LASTSMSMTSTATUS_IR: 'Wakeup call invalid',
	LASTSMSMTSTATUS_FI: 'Wakeup initiation failed',
	LASTSMSMTSTATUS_SI: 'Wakeup initiation succeeded',
	LASTSMSMTSTATUS_F: 'Wakeup delivery failed',
	LASTSMSMTSTATUS_S: 'Wakeup delivery succeeded',
	TOOLTIP_LASTSMSMTSTATUS_IR: 'A bad API request',
	TOOLTIP_LASTSMSMTSTATUS_FI: 'Failure from the SMSC',
	TOOLTIP_LASTSMSMTSTATUS_SI: 'Successful submission to the SMSC',
	TOOLTIP_LASTSMSMTSTATUS_F:
		'SMSC has issued a delivery notification indicating failure',
	TOOLTIP_LASTSMSMTSTATUS_S:
		'SMSC has issued a delivery notification indicating success',

	/* ----- GDSP END ------- */

	/* API ERROR CODES  */

	CODE_400203: 'A required parameter is missing',
	CODE_400204: 'A parameter does not have the correct length',
	CODE_401205: 'Token invalid or expired',
	CODE_400207:
		'A parameter does not have valid value from the possible value options',
	CODE_400208: 'A parameter does not have the correct format',
	CODE_400209: 'Duplicate parameter',
	CODE_400299: 'Bad request',
	CODE_400301: 'Errors from downstream systems',
	CODE_401301: 'Downstream systems credential failure',
	CODE_404301: 'DeviceID not found in downstream system',
	CODE_404302: 'Downstream system AccountID invalid or not found',
	CODE_502100: 'Change not allowed, device is already scheduled',
	CODE_400100:
		'Invalid Request: Batch Action not permitted for Satellite devices',
	CODE_404201: 'File does not exist',
	CODE_401202: 'Username or password is invalid',
	CODE_400206: 'Filename format is invalid',
	CODE_502200: 'Batch file error',
	CODE_502202: 'Database error in retrieval',
	CODE_502203: 'Database error when making changes',
	CODE_502204: 'Error in creating customer',
	CODE_502301: 'Error processing PUSH API',
	CODE_404303: 'DeviceID not Found in PUSH API notification',
	CODE_502501: 'Error in processing SFTP reports',
	CODE_undefined: ' ',
	CODE_INTEGRATION_TIMEOUT:
		'Unable to reach underlying platform. Please try again later.',
	CODE_VTM_SVC_40003: 'The entered value was not recognized',
	CODE_VTM_SVC_40004: 'Unable to create the support ticket',
	CODE_VTM_SVC_40005: 'Unable to create the support ticket',
	CODE_VTM_SVC_40409: 'Ticket does not exist: It may have been removed due to AT&T data retention policy',
	CODE_VTM_SVC_40411: 'Ticket does not exist or it is closed by an agent: It may have been removed due to AT&T data retention policy',
	CODE_VTM_SVC_40027: 'Cannot update canceled or closed support ticket: This ticket is closed or canceled and cannot be updated.',
	CODE_VTM_SVC_40035: 'Unable to add engagement: Engagement ticket already exist',
	CODE_VTM_SVC_50001: 'Something went wrong: Try again. If the problem persists, please report your issue by calling AT&T',
	CODE_VTM_SVC_40038: 'Unable to update the support ticket: Circuit details are the same',
	CODE_VTM_SVC_40039: 'Cannot create the support ticket: Asset info is missing in the system',
	CODE_VTM_SVC_40040: 'Cannot add a PVC value to support ticket: pvcID details already exist in the system',
	CODE_VTM_SVC_40041: 'Unable to update support ticket: clkNumber does not exist',
	CODE_VTM_SVC_40042: 'Unable to update support ticket: pvcID does not exist',
	CODE_VTM_SVC_40043: 'Unable to retrieve ticket history: Please try again later',
	CODE_VTM_SVC_40010: 'Unable to create the support ticket: Field exceeded maximum of 199 characters. Please correct the length and try again',
	CODE_VTM_SVC_40053: 'Unable to create support ticket: Case ID does not have Ticket Role defined. or is invalid support role',
	CODE_EOD_11: 'Unable to process the request: Please try again in a few minutes.',
	CODE_EOD_59: 'Cannot deactivate device without static IP: Before a device can be deactivated (put on hold), it needs to have a static IP. You can still deactivate this device by deprovisioning it (purge) from the system.',
	CODE_EOD_60: 'Cannot deactivate inactive device',
	CODE_EOD_46: 'The SIM is already in the provisioning queue',
	CODE_EOD_30: 'The SIM cannot be suspended using a past date',
	CODE_EOD_32: 'Internal system error: Contact customer support or create a trouble ticket to get help with this issue',
	CODE_EOD_41: 'Cannot change profile for suspended devices: Contact customer support or create a trouble ticket to get help with this issue',
	CODE_EOD_45: 'Invalid SIM or SIM does not belong to your account: Contact customer support or create a trouble ticket to get help with this issue',
	CODE_EOD_16: 'SIM has reached the maximum number of Rate Plan changes allowed during the Billing Cycle: Could not complete the Rate Plan change request. Please try again in the next billing cycle.',
	CODE_EOD_17: 'SIM has reached the maximum number Rate Plan changes allowed for the day: Could not complete the Rate Plan change request. Please try again tomorrow.',
	CODE_EOD_25: 'The device is currently in a suspended state and so cannot resume before the suspended date',
	CODE_EOD_27: 'The SIM cannot be suspended using a past date',
	CODE_EOD_3: 'Device is already inactive',
	CODE_EOD_401: 'Unable to authorize user performing the batch action with device platform.',

	/* API ERROR CODES  */

	SERVICE_STATUS: '360L Status',
	SERVICESTATUS: '360L Status',
	OEM: 'Sub Accounts',
	ROAMING_COUNTRY: 'Roaming Country',
	ROAMING_CARRIER: 'Roaming Carrier',
	ROAMINGCOUNTRY: 'Roaming Country',
	ROAMINGCARRIER: 'Roaming Carrier',
	OEM_TOOLTIP: 'Linked 360L Accounts',
	SIM_STATE: 'SIM State',

	NO_DATA_ENDPOINT_HISTORY: 'No Endpoint History',
	PRINT_PDF: 'Print PDF',
	USER_GUIDE: 'User Guide',
	USER_GUIDE_MENU: 'User Guide Menu',
	FAQ_MENU: 'FAQs Menu',
	TABLE_OF_CONTENTS: 'Table of Contents',
	RATE_PLAN_TYPE: 'Rate Plan Type',
	SUBSCRIPTION_PRICE: 'Subscription Price',
	ACCOUNT_CHARGE: 'Account Charge',
	PAYMENT_TYPE: 'Payment Type',
	TERM: 'Term',
	USAGE_LIMIT: 'Usage Limit',
	TIERING_FLAG: 'Tiering Flag',

	RATE_PLAN_CHANGE: 'Rate Plan change',
	RATE_PLAN_CHANGED_TO: 'You have chosen to change Rate plan to',
	SCHEDULE_RATE_PLAN_CHANGE: 'Schedule Rate plan change',
	RATE_PLAN_CHANGE_IS_SUCCESSFULLY_SCHEDULED_ERROR:
		'Rate plan change could not be scheduled successfully!',
	RATE_PLAN_CHANGE_IS_SUCCESSFULLY_SCHEDULED_FOR:
		'Rate plan change is successfully scheduled for',
	RATE_PLAN_IS_CHANGED_TO: 'Rate plan is changed to ',
	FAILED_TO_PROVISION_AFTER_RATE_PLAN_CHANGE:
		' failed to provision after Rate plan change. ',
	SEND_EMAIL_TO_CONSUMER: 'Send email to Consumer',
	PUSH_API: 'Push API',
	INITIATE_OTA_SWAP: 'Initiate OTA Swap',
	UPDATE_PCRF: 'Update PCRF',
	CHANGE_DEVICES_NETWORK_ENTITLEMENT: "Change Device's Network Entitlement",
	CHANGE_DEVICES_SIM_STATE: "Change Device's SIM State",
	CHANGE_DEVICES_RATE_PLAN: "Change Device's Rate Plan",
	NUMBER_OF_DAYS: 'Number of days',
	NUMBER_OF_HOURS: 'Number of hours(max 24h)',
	AT: 'At',
	FILTER: 'FILTER',
	FOLLOW_UP_WITH: 'Follow up with',
	CUSTOM: 'Custom',
	ELIGIBLE_RATE_PLAN: 'Eligble Rate Plan',
	CHOOSE_RATE_PLAN: 'Choose Rate Plan',
	CUSTOMER_ID: 'Customer ID',
	INVALID_URL_FORMAT: 'Invalid URL format',
	INVALID_PORT_NUMBER: 'Invalid PORT number',
	INVALID_INPUT: 'Invalid Input',
	RATE_PLAN_CHANGE_SUPPORT:
		'Error on rate plan, please contact Customer Support.',
	NETWORK_ENTITLEMENT_PLACEHOLDER: 'XYX GPRS/LTE/SMS/IMS RES 165',
	M2M_SUB_ACCOUNT_ID: 'M2M Sub Account ID',

	/* SIM ORDERING */
	SIM_ORDERING: 'SIM Ordering',
	CREATE_SIM_ORDER: 'Create SIM Order',
	NEW_SIM_ORDER: 'New Order',
	EXISTING_SIM_ORDER: 'Order ID: {id}',
	M2M_ACCOUNT: 'M2M Account',
	M2M_ACCOUNT_PLACEHOLDER: 'Select your M2M Account',
	ORDER_ID: 'Order ID',
	ORDER_NUMBER_ID: 'Order Number ID',
	ORDER_CONFIRMATION_NUMBER: 'Order Confirmation Number',
	ORDER_DATE: 'Order Date',
	PURCHASE_ORDER_NUMBER: 'PO #',
	SKU: 'SKU',
	SKU_PLACEHOLDER: 'Select SKU',
	UNIT_PRICE: 'Unit Price',
	QUANTITY: 'Quantity',
	QUANTITY_LIMIT_TEMPORARILY_REACHED: 'Quantity limit temporarily reached',
	MIN_MAX_QUANTITY: 'Min {min} / Max {available} {max}',
	INCREMENT_QUANTITY: 'Increment {increment}',
	ORDER_STATUS: 'Order Status',
	ORDER_STATUS_PENDING: 'Pending',
	ORDER_STATUS_RECEIVED: 'Received',
	ORDER_STATUS_SHIPPED: 'Shipped',
	ORDER_STATUS_COMPLETED: 'Completed',
	ORDER_STATUS_SUCCESSFUL: 'Successful',
	ORDER_STATUS_FAILED: 'Failed',
	CONTACT_NAME: 'Contact Name',
	TELEPHONE_NUMBER: 'Telephone Number',
	TARGET_IMPLEMENTATION_DATE: 'Target Implementation Date',
	EXPEDITED: 'Expedited',
	SHIPPING_ADDRESS: 'Shipping Address',
	ADDRESS_LINE_1: 'Address Line 1',
	ADDRESS_LINE_2: 'Address Line 2',
	SHIPPING_NOTES: 'Shipping Notes',
	SHIPPING_NUMBER: 'Shipping Number',
	EXPEDITED_SHIPPING: 'Expedited Shipping',
	TOTAL_PRICE: 'Total Price',
	SUBMIT_ORDER_REQUEST: 'Submit Order Request',
	SUBMIT_ORDER_REQUEST_CONFIRMATION:
		'Are you sure you want to submit this order?',
	EDIT_ORDER_REQUEST: 'Edit Order Request',
	EDIT_ORDER_REQUEST_CONFIRMATION: 'Are you sure you want to save changes?',
	SAVE_UPDATES: 'Save Updates',
	ADD_SIM_ORDER_SUCCESS: 'SIM order created',
	ADD_SIM_ORDER_ERROR: 'Failed to create sim order, please try again.',
	EDIT_SIM_ORDER_SUCCESS: 'SIM order edited',
	EDIT_SIM_ORDER_ERROR: 'Failed to edit sim order, please try again.',
	PO_TOOLTIP: 'PO # will be generated upon SIM Order submission',

	FILTERS: 'Filters',
	FILTER_CATEGORY_STATUS: 'Status',
	FILTER_CATEGORY_PLATFORM: 'M2M Platform',
	FILTER_CATEGORY_SKU: 'SKU',
	FILTER_CATEGORY_EXPEDITED: 'Expedited',
	FILTER_VALUE_PENDING: 'Pending',
	FILTER_VALUE_RECEIVED: 'Received',
	FILTER_VALUE_SHIPPED: 'Shipped',
	FILTER_VALUE_COMPLETED: 'Completed',
	FILTER_VALUE_YES: 'Yes',
	FILTER_VALUE_NO: 'No',

	SIM_ORDERING_TITLE: 'SIM Ordering',
	SIM_ORDERING_SUBTITLE:
		'Please select below which SIMs are available to order.',
	SIM_ORDERING_CONTRACT_RESTRAINTS: 'Contract restraints',
	SIM_ORDERING_CONTRACT_RESTRAINTS_SUBTITLE:
		'Please set restraints on the SIM Order Process.',
	SIM_ORDERING_MIN_QUANTITY_ALLOWED: 'Min Quantity Allowed',
	SIM_ORDERING_MIN_QUANTITY_ALLOWED_PLACEOLDER: 'Min Quantity',
	SIM_ORDERING_MAX_QUANTITY_ALLOWED: 'Max Quantity Allowed',
	SIM_ORDERING_PER_PERIOD: 'Per Period',
	SIM_ORDERING_PER_PERIOD_PLACEHOLDER: 'Select a Time Period',
	SIM_ORDERING_MAX_QUANTITY_ALLOWED_PLACEOLDER: 'Max Quantity',
	SIM_ORDERING_SIM_SKU: 'SIM SKU',
	SIM_ORDERING_SIM_SKU_PLACEHOLDER: 'Enter SIM SKU Here',
	SIM_ORDERING_UNIT_PRICE: 'Unit Price',
	SIM_ORDERING_UNIT_PRICE_PLACEHOLDER: 'Unit Price',
	SIM_ORDERING_ALLOWED_TO_ORDER: 'Allowed to Order',
	SIM_ORDERING_SKU_DESCRIPTION: 'Description',
	SIM_ORDERING_SKU_DESCRIPTION_PLACEHOLDER: 'Enter SKU Description',
	SIM_ORDERING_SKU_ADD_SKU: '+ Add Another SIM SKU',
	SIM_ORDERING_VALIDATOR_MAX_QUANTITY: 'max quantity',
	SIM_ORDERING_VALIDATOR_MIN_QUANTITY: 'min quantity',
	ADD_RESTRAINTS_SUCCESS: 'Contract restraints added',
	ADD_RESTRAINTS_ERROR: 'Failed to add contract restraints',
	UPDATE_RESTRAINTS_SUCCESS: 'Contract restraints updated',
	UPDATE_RESTRAINTS_ERROR: 'Failed to update contract restraints',
	ADD_SIM_ORDERING_SKUS_SUCCESS: 'SIM Ordering SKUs updated',
	ADD_SIM_ORDERING_SKUS_ERROR: 'Failed to add SIM Ordering SKUs',

	COMPANY_REPORTS: 'Reports',
	COMPANY_REPORTS_SETTINGS_SUCCESSFULLY_UPDATED:
		'Company Reports Settings Saved',
	ERROR_WHILE_UPDATING_COMPANY_REPORTS: 'Error While Updating Company Reports',
	ERROR_WHILE_FETCHING_COMPANY_REPORTS:
		'Error While Fetching Reports Configuration',
	COMPANY_REPORTS_SUBTITLE:
		'Please select below which reports you want to receive.',
	DAILY_REPORTS: 'DAILY REPORTS',
	MONTHLY_REPORTS: 'MONTHLY REPORTS',
	PREFERENCES_MENU: 'Preferences Menu',
	PREFERENCES: 'Preferences',
	REFERENCE_REPORTS: 'Reference Reports',
	M2M_DATE_ADDED: 'M2M Date Added',
	M2M_INITIAL_ACTIVATION_DATE: 'M2M Activation Date',
	NOTES: 'Notes',
	SAVE_NOTE: 'Save Note',
	NOTE_TEXTAREA_PLACEHOLDER: 'Enter your note here (2000 characters max)',
	ADD_NOTE_SUCCESS: 'Note saved',
	ADD_NOTE_ERROR: 'Failed to save note, please try again.',
	DELETE_NOTE_SUCCESS: 'Note deleted',
	DELETE_NOTE_ERROR: 'Failed to delete note, please try again.',
	DELETE_NOTE: 'Delete note',
	TICKETING_STATUS: 'Ticketing status',
	UPDATE_NOTE_STATUS: 'Update note status',
	LAST_UPDATE: 'Last update',
	SUBMISSION_LOCATION: 'Submission location',
	RULE_STATE: 'Rule State',
	RULE_STATE_ACTIVE: 'Active',
	YES: 'Yes',
	NO: 'No',
	// ANALYTICS
	CHART_CATEGORIES: 'Graph categories',
	GROUP_DATA_BY: 'Group data by',
	CHART_OPTIONS: 'Graph options',
	CREATE_NEW_CHART: 'Create New Graph',
	EDIT_CHART: 'Edit Graph',
	DISCLAIMER: 'Disclaimer: ',
	DATE_RANGE: 'DATE RANGE',
	SHOW: 'SHOW',
	CYCLE: 'CYCLE',
	INCREMENT_BY: 'INCREMENT BY',
	SELECT_CUSTOM_DATE_RANGE: 'Select custom date range',

	// Billing
	INVOICE: 'Invoice',
	INVOICE_DATE: 'Invoice date',
	CUSTOMER_NAME: 'Customer name',
	DOCUMENT_ID: 'Document ID',
	COUNTRY_NAME: 'Country name',
	TABLE_COLUMNS_INVOICES_CHANGE_SUCCESS_TITLE:
		'Invoices table settings change success!',
	TABLE_COLUMNS_INVOICES_CHANGE_SUCCESS_MESSAGE:
		'Invoices table settings have been successfully updated.',
	TABLE_COLUMNS_INVOICES_CHANGE_ERROR_TITLE:
		'Invoices table columns change error!',
	TABLE_COLUMNS_INVOICES_CHANGE_ERROR_MESSAGE:
		'Invoices table columns could not be updated this time. Please try again.',
	INVOICE_DETAILS: 'Invoice Details',

	// TICKETING
	TICKET_CREATED_DATE: 'Ticket created date',
	UPDATED_NOTE_STATUS_DATE: 'Updated note status date',
	LAST_UPDATED_DATE: 'Last updated date',
	UPDATE_NOTE: 'Update note',
	TABLE_TICKETS_COLUMNS_CHANGE_SUCCESS_TITLE:
		'Tickets table settings change success!',
	TABLE_TICKETS_COLUMNS_CHANGE_SUCCESS_MESSAGE:
		'Tickets table settings have been successfully updated.',
	TABLE_TICKETS_COLUMNS_CHANGE_ERROR_TITLE:
		'Tickets table columns change error!',
	TABLE_TICKETS_COLUMNS_CHANGE_ERROR_MESSAGE:
		'Tickets table columns could not be updated this time. Please try again.',

	TODAY: 'Today',
	PREVIOUS_DAY: 'Previous day',
	LAST_10_DAYS: 'Last 10 days',
	LAST_30_DAYS: 'Last 30 days',
	CUSTOM_RANGE: 'Custom range',
	CURRENT_CYCLE_TO_DATE: 'Current cycle to date',
	HISTORICAL: 'Historical',
	WEEK: 'Week',
	SUM_TOTALS: 'Sum totals',
	AVERAGE_TOTALS: 'Average totals',
	LINE_GRAPH: 'Line graph',
	BAR_GRAPH: 'Bar graph',
	WORLD_MAP_GRAPH: 'World Map graph',
	TOTAL_DATA_USAGE: 'Total Data Usage',
	ENDPOINT_COUNT_TOTALS: 'Endpoint Count Totals',
	NO_USAGE_SIMS: 'No Usage SIMs',
	TOTAL: 'Total',
	AVERAGE_USAGE: 'Average',
	DIRECTION_INDEX: 'Direction Index',
	PIE_CHART: 'Pie graph',
	ERROR_WHILE_ADDING_TICKET: 'Error while adding ticket',

	SELECT_M2M_PLATFORM: 'Select your M2M Platform',
	SELECT_M2M_PLATFORM_ERROR:
		'Please select M2M Platform first in order to populate data',
	SELECT_YOUR_ACCOUNT: 'Select your Account',
	CHOOSE_M2M_PLATFORM: 'Choose your M2M Platform',
	CHOOSE_YOUR_ACCOUNT: 'Choose your Account',

	TOTAL_VOICE_USAGE: 'Total Voice Usage',
	TOTAL_SMS_USAGE: 'Total SMS Usage',
	SAVE_AND_CLOSE: 'Save and Close',
	// Security Features
	SECURITY_TABS_1: 'IMEI Whitelist',
	SECURITY_TABS_2: 'SMS Whitelist',
	SECURITY_TABS_3: 'VOICE Whitelist',
	SECURITY_TABS_4: 'IP, URL, Port Blacklist',
	SECURITY_TABS_5: 'IP Range Whitelist',
	SECURITY_FEATURES_1: 'IMEI',
	SECURITY_FEATURES_2: 'SMS',
	SECURITY_FEATURES_3: 'VOICE',
	SECURITY_FEATURES_4: 'IP, URL, PORT',
	SECURITY_FEATURES_5: 'IP RANGE',
	SECURITY_FEATURES_SUCCESSFULLY_ADD: 'Successfully changed data',
	SECURITY_FEATURES_ERROR_ADD: 'Error while updating data',
	SECURITY_FEATURES_ADD_1: 'Add new IMEI to the Whitelist',
	SECURITY_FEATURES_ADD_2: 'Add new SMS to the Whitelist',
	SECURITY_FEATURES_ADD_3: 'Add new VOICE to the Whitelist',
	SECURITY_FEATURES_ADD_4: ' Add new IP, URL, PORT to the Blacklist',
	SECURITY_FEATURES_ADD_5: 'Add new IP Range to the Whitelist',
	SECURITY_FEATURES_EDIT_1: 'Edit IMEI Whitelist',
	SECURITY_FEATURES_EDIT_2: 'Edit SMS Whitelist',
	SECURITY_FEATURES_EDIT_3: 'Edit VOICE Whitelist',
	SECURITY_FEATURES_EDIT_4: ' Edit IP,URL,PORT Blacklist',
	SECURITY_FEATURES_EDIT_5: 'Edit IP Range Whitelist',
	SECURITY_FEATURES_M2M: 'Select M2M Platform',
	SECURITY_FEATURES_REMOVE: 'Remove from Whitelist',
	UPDATE: 'Update',
	SECURITY_FEATURES: 'Security Features',
	M2M_ACCOUNT_NAME: 'M2M ACCOUNT NAME',
	DATA_SUBMITTED: 'DATA SUBMITTED',
	FILTER_CATEGORY_M2MACCOUNT: 'M2m Account',
	SECURITY_FEATURES_SUCCESSFULLY_EXPORT: 'Successfully exported',
	SECURITY_FEATURES_FAIL_EXPORT: 'Export failed',
	COUNTRYCODE: 'Country',
	MINUTE: 'Minute',
	MINUTES: 'Minutes',
	CHART_LEFT_AXIS_MINUTE: 'min',
	CHART_LEFT_AXIS_MINUTES: 'mins',
	CONSUMED_MINUTE: 'Consumed Minutes',
	CONSUMED_MB: 'Consumed Megabtyes',
	CONSUMED_GB: 'Consumed Gigabytes',
	CONSUMED_TB: 'Consumed Terabytes',
	CONSUMED_PB: 'Consumed Petabytes',
	CONSUMED_SMS: 'Consumed SMS',
	NO_DESCRIPTION_FOR_GRAPH: 'No description for this graph',
	GRAPHS: 'Current Available Graphs',
	CONSUMED_COUNT: 'Number of Endpoints',
	DOWNLOAD_CHART_AS_PNG: 'Download PNG',
	CUSTOMER_INVOICE_ID: 'Customer Id',
	INVOICE_ID: 'Invoice Id',
	CUSTOMER_INVOICE_NAME: 'Sub-Account',
	INVOICE_COUNTRY: 'Country',
	ACCESSTECHNOLOGY: 'Access Technology',
	RATINGZONE: 'Rating Zone',
	RATEPLAN: 'Rate Plan',
	CHOOSE_FILTERS_ON_ANALYTICS_MODAL:
		'Please select a category in the first step',
	NO_FILTERS_ON_ANALYTICS_MODAL: 'No filters available for this category',
	BILLING_CYCLE: 'Billing Cycle',
	FETCHING_DATA: 'Please wait. Fetching data...',
	NO_DATA_RECEIVED: 'No data received',
	NO_FILTERS_AVAILABLE: 'No Filters Available',
	DATE_SUBMITTED: 'Date Submitted',
	NO_REPORTS_DEFINED: 'No Reports Defined',
	APPLY: 'Apply',
	OPEN_CHART_SETTINGS_PAGE: 'Graph Settings',
	CUSTOMIZE_CHART_DATA: 'Customize graph data',
	ESTIMATED_AVERAGE_USAGE: 'Estimated Average Usage',
	DELETE_DEFINED_CHART_SETTING_QUESTION: 'Delete Defined Chart Setting?',
	DELETE_DEFINED_CHART_SETTING_MESSAGE:
		'Are you sure you would like to delete setting',
	TOTAL_COUNT_BY_SEVERITY: 'Tickets by Severity',
	TOTAL_COUNT_BY_STATUS: 'Tickets by Status',
	INVALID_IP_ADDRESS: 'Invalid IP Address',
	INVOICENUMBER: 'Invoice Number',
	CUSTOMERNAME: 'Customer Name',
	COUNTRYNAME: 'Country Name',
	CUSTOMERID: 'Customer ID',
	DEVICE_WAKE_UP: 'Device Wake Up',
	DEVICE_WAKE_UP_NOTIFICATION_TITLE: 'Device Wake Up',
	DEVICE_WAKE_UP_NOTIFICATION_MESSAGE_SUCCESS: 'Device Wake Up Successful',
	DEVICE_WAKE_UP_NOTIFICATION_MESSAGE_FAIL: 'Device Wake Up Failed',

	FILES_FAILED_TO_UPLOAD_MESSAGE: 'Files failed to upload ',
	ADD_ANOTHER_TRESHOLD: 'Add Another Threshold',
	PIE_CHART_TOTAL: 'Total',
	ADD_ATTACHMENT: 'Add Attachment',
	TICKET_FILE_UPLOAD_ERROR:
		'There was an error on the backend system and some of your files have not been updated.',
	FILE_TO_LARGE_TO_UPLOAD:
		'File is too large to upload. Maximum file size is 3MB.',
	RETRY: 'Retry',
	NO_CATEGORIES: 'There are no categories available for this account',
	ZONE_NAME: 'Zone Name',
	POOL_ID: 'Pool ID',

	// NOTES INVOICES
	ADD_NEW_NOTE: 'Add Note',
	EDIT_NOTE: 'Edit Note',
	ADD_NOTE_PLACEHOLDER: 'Enter your note here',
	INVOICE_NOTE_SUCCESSFULLY_UPDATED: 'Invoice Note Successfully Updated',
	INVOICE_NOTE_UPDATE_FAIL: 'Invoice Update Failed',
	ATTACHMENTS_ADDED: 'Attachments Added',
	EXTERNAL_LAST_UPDATED_BY: 'Last Updated By',
	EXTERNAL_LAST_UPDATED_DATE: 'Last Updated Date/Time',
	REMOVE: 'Remove',
	EndpointStatus: 'Status',
	NetworkOperator: 'Network Operator',
	NetworkCountry: 'Network Country',
	NetworkType: 'Network Type',
	TransferStatus: 'IMSI Transfer Status',
	NetworkEntitlement: 'Network Entitlement ID',
	RecredentialTransferStatus: 'Re-Credential Status',
	M2MDateAdded: 'M2M Date Added',
	M2MInitialActivationDate: 'M2M Activation Date',
	LAST_UPDATED_BY: 'Last updated by',
	LAST_UPDATED: 'Last updated',
	INVOICE_NOTE_FILE_ERROR:
		'There was an error on the backend system and some of your files have not been uploaded.',

	BATCH_HISTORY: 'Batch History',
	// DIAGNOSTIC HLRHSSDetails
	GET_HLR_HSS_DETAILS: 'Get HLR/HSS Details',
	APN_IDENTIFIER: 'APN Identifier',
	IP_ADDRESS_TYPE: 'IP Address type',
	QOS_PROFILE: 'QOS Profile',
	PDN_GW_DYNAMIC_ALLOCATION: 'PDN GW Dynamic allocation',
	VPLMN_ADDRESS_ALLOWED: 'VPLMN Address allowed',
	MAX_UPLOAD: 'Max Upload',
	MAX_DOWNLOAD: 'Max Download',
	SUBSCRIBER_PROFILE: 'Subscriber profile',
	ENDPOINT_ID_ICCID: 'Endpoint ID - ICCID',
	BARRING_STATUS: 'Barring Status',
	NETWORK_ACCESS: 'Network Access',
	CURRENT_VLR_NUMBER: 'Current VLR Number',
	PREVIOUS_VLR_NUMBER: 'Previous VLR Number',
	CURRENT_SGSN_NUMBER: 'Current SGSN Number',
	PREVIOUS_SGSN_NUMBER: 'Previous SGSN Number',
	ROAMING_RESTRICTION: 'Roaming Restriction',
	DATA_3G_ENABLED: '3G Data Enabled',
	NETWORK_STATUS: 'Network Status',
	APN_LIST: 'APN List',

	// DATA HISTORY
	DATA_HISTORY: 'Data History',
	DATA_VOLUME_DOWN_KB: 'Data Volume Downlink (KB)',
	DATA_VOLUME_UP_KB: 'Data Volume Uplink (KB)',
	DURATION_MINUTES: 'Duration (Minutes)',
	SESSION_CHARGING_ID: 'Session Charging ID',
	ACCESS_POINT_NAME: 'APN',
	DEVICE_IP_V4: 'Device IPv4 Address',
	DEVICE_IP_V6: 'Device IPv6 Address',
	RATING_ZONE_NAME: 'Rating Zone Name',
	RATING_GROUP_ID_NAME: 'Rating Group ID Name',
	RATING_GROUP_ID: 'Rating Group ID',

	// SMS HISTORY WING
	MESSAGE_HISTORY: 'Message History',
	PORTAL_MESSAGES: 'Portal Messages',
	SMS_LOG_ID: 'SMS Log ID',
	DATE_SENT_RECEIVED: 'Date Sent/Received',
	SENT_FROM: 'Sent From',
	SENT_TO: 'Sent To',
	MESSAGE_TYPE: 'Message Type',
	ZONE_MODEL: 'Zone Model',

	CTD_DATA_USAGE: 'CTD Data Usage (Megabytes)',
	CTD_SMS_MO_MT_USAGE: 'CTD SMS Usage (MSG)',
	CTD_VOICE_USAGE: 'CTD Voice Usage (Min)',

	ACCOUNTS: 'Accounts',
	MUST_BE_BETWEEN_CHARACTERS_LONG:
		'Must be between {min} and {max} characters long',
	INVALID_DOMAIN_FORMAT: 'Invalid domain format',
	LAST_UPDATE_DATE_TIME: 'Last Updated Date/Time',
	LAST_UPDATED_BY_USER: 'Last Updated By User',
	INSESSION: 'In Session',
	PLEASE_WAIT_THIS_MAY_TAKE_A_FEW_MINUTES:
		'Please wait. This may take a few minutes, depending on the file size.',
	NO_RESULTS: 'No results',
	SELECTED_RATE_PLAN: 'Selected Rate Plan',
	HSPA: 'Hspa',
	LTE: 'Lte',
	VLR: 'Vlr',
	SGSN: 'Sgsn',
	EXPORT: 'Export',
	FILE: 'File',
	ERROR_CODE: 'Error Code',
	ERROR_TYPE: 'Error Type',
	AT_LINE: 'At Line',
	EXPORT_TICKETS: 'Export tickets',
	EXPORT_USER_USAGE_DATA: 'Export users usage data',
	PLACEHOLDER_MODEL: 'AT&T Z431',
	NO_BATCH_ACTIONS_AVAILABLE: 'No Batch Actions Available',
	NO_BATCH_ACTIONS_AVAILABLE_SUBTITLE:
		'There are No Batch Actions for this Table to show you right now',
	NO_BATCH_REPORTS_AVAILABLE: 'No Batch Summary Available',
	NO_BATCH_REPORTS_AVAILABLE_SUBTITLE:
		'There is No Batch Summary for this Table to show you right now',
	MOBILE_ORIGINATING: 'Mobile Originating',
	MOBILE_TERMINATING: 'Mobile Terminating',
	NA: 'NA',
	GMNA: 'GM North America',
	POD3: 'ACC Pod3',
	POD19: 'Enterprise',
	TABS_ADD_ICON: 'Create Custom Endpoints Table',
	USAGE_SUMMARIES: 'Usage Summary',
	EMPTY_SPACE: ' ',
	// endpoints rate plans
	'ENDPOINTS_RATE_PLAN.RATE_PLAN_TYPE': 'Rate Plan Type',
	'ENDPOINTS_RATE_PLAN.USAGE_LIMIT': 'Data Usage Limit',
	'ENDPOINTS_RATE_PLAN.INCLUDED_TEST_DATA': 'Included Test Data',
	'ENDPOINTS_RATE_PLAN.BILLING_PLATFORM': 'Billing Platform',
	'ENDPOINTS_RATE_PLAN.INCLUDED_USAGE': 'Included Data Usage',
	'ENDPOINTS_RATE_PLAN.ACTIVATION_FEE': 'Activation Fee',
	'ENDPOINTS_RATE_PLAN.INCLUDED_TEST_SMS': 'Included Test SMS',
	'ENDPOINTS_RATE_PLAN.BILLING_ACCOUNT_ID': 'Billing Account ID',
	'ENDPOINTS_RATE_PLAN.MSU_PRICE': 'MSU Price',
	'ENDPOINTS_RATE_PLAN.INCLUDED_TEST_VOICE': 'Included Test Voice',
	'ENDPOINTS_RATE_PLAN.ZONE_MODEL_ID': 'Zone Model ID',
	'ENDPOINTS_RATE_PLAN.TYPE': 'Type',
	'ENDPOINTS_RATE_PLAN.INCLUDED_MSU': 'Included MSU',
	'ENDPOINTS_RATE_PLAN.VALID_FROM': 'Valid From',
	'ENDPOINTS_RATE_PLAN.TIERED_PLAN': 'Tiered Plan',
	'ENDPOINTS_RATE_PLAN.DEFAULT_PLAN': 'Default Plan',
	'ENDPOINTS_RATE_PLAN.PAYMENT_TYPE': 'Payment Type',
	'ENDPOINTS_RATE_PLAN.DEFAULT_RATE_PLAN': 'Default Rate Plan',
	'ENDPOINTS_RATE_PLAN.BASE_RATE_PLAN': 'Base Rate Plan',
	'ENDPOINTS_RATE_PLAN.POSTPAID': 'Postpaid',
	'ENDPOINTS_RATE_PLAN.APPROVED': 'Approved',
	'ENDPOINTS_RATE_PLAN.MONTHLY': 'MONTHLY',
	'ENDPOINTS_RATE_PLAN.MRC': 'MRC',
	'ENDPOINTS_RATE_PLAN.PER_SUBSCRIBER': 'Per Subscriber',
	'ENDPOINTS_RATE_PLAN.CURRENCY': 'Currency',
	PASSWORDS_DO_NOT_MATCH: 'Passwords do not match',
	NO_WHITE_SPACE_ONLY: 'Text should contain letters and/or numbers',
	NO_RATE_PLAN_SELECTED: 'no Rate plan selected',
	NO_RATE_PLANS_UNDERLYING_PLATFORM:
		'There is an error on underlying platform and rate plan change is not available at the moment',
	ENDPOINT_PROVISIONED_STATE_CHANGE_SUCCESS:
		'Endpoint ID {args1} is successfully provisioned. IMSI status is changed to {args2}',
	ENDPOINT_PROVISIONED_STATE_CHANGE_FAIL:
		'Endpoint ID {args1} failed to provision after IMSI status change. Get support',

	// AUTH SIGN IN CALLBACK
	AUTHENTICATION_FAILED: 'Authentication failed',
	AUTHENTICATION_IN_PROGRESS: 'Authentication in progress. Please wait...',
	MEGABYTES: 'Megabytes',
	TICKETS: 'Tickets',
	USER_GROUPS: 'User groups',
	RECENT_SESSIONS: 'Recent Sessions',
	ACTIVE_SESSION_SESSION_START_TIME: 'SESSION START TIME',
	ACTIVE_SESSION_TOTAL_VOLUME: 'TOTAL VOLUME',
	ACTIVE_SESSION_TRANSMITTING: 'TRANSMITTING',
	ACTIVE_SESSION_RECEIVING: 'RECEIVING',
	CURRENTLY_ACTIVE_SESSION: 'Currently Active Session',
	ACTIVE_SESSION_CARRIER: 'CARRIER',
	ACTIVE_SESSION_IP_ADDRESS: 'IP ADDRESS',
	RESET_TO_DEFAULT: 'Reset To Default',
	DATE_TIME: 'Date/Time',
	SESSION_END: 'Session End',
	SEND: 'Send',
	SEND_SMS_TO_THIS_DEVICE: 'Send an SMS to this device.',
	TYPE_A_MESSAGE:
		'Type a message you wish to send to this device using short message service. Standard rates may apply.',
	MESSAGES_WILL_APPEAR:
		'Messages will appear as sent and will appear in SMS Usage reports. Rates may apply.',
	SENT_MESSAGES_HISTORY: 'Sent messages history',
	CHARACTERS_REMAINING: '{remaining} characters remaining...',
	EXPORT_SESSIONS_DATA_FOR_ID: 'Recent Sessions for {id}',
	EXPORT_DATA: 'Export Data',
	BYTE_ABBREVIATION: 'B',
	UTC_TIME_ZONE: ' UTC ',
	SEND_SMS_TO_ENDPOINT: 'Send SMS to Endpoint',
	SMS: 'SMS',
	OFFPEAK_USAGE_FLAG: 'OffPeak Usage Flag',
	DATA_USAGE_TOTAL: 'Data Usage - Raw Total',
	DATA_USAGE_UPLINK: 'Data Usage - Raw Uplink',
	DATA_USAGE_DOWNLINK: 'Data Usage - Raw Downlink',
	DATA_USAGE_ROUNDED: 'Data Usage - Rounded',
	DEVICE_IP_ADDRESS: 'Device IP Address',
	SESSION_DURATION: 'Session Duration',
	RECORD_SEQUENCE_NUMBER: 'Record Sequence Number',
	CHARGING_ID: 'Charging ID',
	SESSION_CLOSE_CAUSE: 'Session Close Cause',
	TECH_TYPE: 'Tech Type',
	TECH_TYPE_SUB_TYPE: 'Tech Type Subcategory',
	PGW_ADDRESS: 'PGW Address',
	TIME_OF_FIRST_USAGE: 'Time Of First Usage',
	TIME_OF_LAST_USAGE: 'Time Of Last Usage',
	TIME_USAGE_DURATION: 'Time Usage Duration',
	STREAM_ID: 'Stream ID',
	CELL_GLOBAL_IDENTIFIER: 'CGI (Cell Global Identifier)',
	SERVICE_AREA_IDENTIFIER: 'SAI (Service Area Identifier)',
	ROUTING_AREA_IDENTIFIER: 'RAI (Routing Area Identifier)',
	TRACKING_AREA_IDENTITY: 'TAI (Tracking Area Identity)',
	E_UTRAN_CGI: 'ECGI (E-UTRAN CGI)',
	LOCATION_AREA_IDENTIFIER: 'LAI (Location Area Identifier)',
	SERVING_SGSN: 'Serving SGSN',
	COUNTRY_LASTEST_SESSION: 'Country (latest session)',
	CARRIER_LASTEST_SESSION: 'Carrier (latest session)',
	BILLABLE_FLAG: 'Billable Flag',
	CYCLE_MONTH: 'Cycle Month',
	CYCLE_YEAR: 'Cycle Year',
	ASSIGNED_RATE_PLAN_ID: 'Assigned Rate Plan ID',
	ASSIGNED_RATING_ZONE_ID: 'Assigned Rating Zone ID',
	RATE_PLAN_VERSION_ID: 'Rate Plan Version ID',
	SERVICE_TYPE: 'Service Type',
	TRAFFIC_DETAIL_ID: 'Traffic Detail ID',
	RECORD_RECEIVED_DATE: 'Record Received Date',
	RECORD_OPEN_TIME: 'Recored Open Time',
	OPERATOR_NETWORK: 'Operator Network',
	OPERATOR_ACCOUNT_ID: 'Operator Account ID',
	TAP_CODE: 'TAP Code',
	USAGE_HISTORY: 'Usage History',
	DATA: 'Data',
	VOICE: 'Voice',
	HLR_HSS_DETAILS_FOR_ENDPOINT: 'HLR/HSS details for endpoint ID {id}',
	NEED_HELP: 'Need Help?',
	MB: ' MB',
	CONNECTION_STATUS: 'Connection Status',
	MESSAGE_CONTENT: 'Message Content',
	B: 'B',
	CYCLE_TO_DATE_USAGE_TOTAL: 'Cycle to Date Usage Total',
	AVERAGE_DAILY_USE: 'Average Daily Use',
	DATA_USAGE_DOWNLINK_CHART: 'Downlink',
	DATA_USAGE_UPLINK_CHART: 'Uplink',
	GDSP: 'GDSP',
	SECOND: 'Second',
	SECONDS: 'Seconds',
	DURATION_RAW: 'Duration - Raw',
	DURATION_ROUNDED: 'Duration - Rounded',
	USAGE_TYPE: 'Usage Type',
	SELECT_BILLING_CYCLE: 'Select Billing Cycle',
	RANGE: 'Range',
	CUSTOM_FIELDS_VALUES_UPDATED: 'Custom Field Values have been updated',
	CUSTOM_FIELDS_VALUES_UPDATED_ERROR:
		'Custom Field Values have failed to update',
	SXMACC: 'SXMACC',
	CHARTS_BILLING_CYCLE_ABBREVIATION: 'BC {billcycle}',
	DATE_RANGE_INPUT_LABEL: 'From: {startDate} To: {endDate}',
	UH_OH_THERE_WERE_SOME_PROBLEMS_TRYING_TO_LOG_YOU_IN_PLEASE_RETRY_AGAIN_IN_A_FEW_MOMENTS:
		'Uh oh, there were some problems trying to log you in. Please retry again in a few moments. If the issue persists, please reach out to the support team.',

	//  LANGUAGE OPTIONS
	ENGLISH: 'English (USA)',
	FRANCAIS: 'Français (France)',
	ESPANOL: 'Español (España)',
	ITALIANO: 'Italiano (Italia)',
	NEDERLANDS: 'Netherlands (Holland)',
	DEUTCHE: 'Deutsche (Deutschland)',
	PORTUGUES: 'Português (Brasil)',
	CHINESE: '中文 (中国)',

	//  COUNTRIES
	COUNTRY_UNITED_STATES: 'United States',
	COUNTRY_AFGHANISTAN: 'Afghanistan',
	COUNTRY_ALAND_ISLANDS: 'Aland Islands',
	COUNTRY_ALBANIA: 'Albania',
	COUNTRY_ALGERIA: 'Algeria',
	COUNTRY_AMERICAN_SAMOA: 'American Samoa',
	COUNTRY_ANDORRA: 'Andorra',
	COUNTRY_ANGOLA: 'Angola',
	COUNTRY_ANGUILLA: 'Anguilla',
	COUNTRY_ANTARCTICA: 'Antarctica',
	COUNTRY_ANTIGUA_AND_BARBUDA: 'Antigua And Barbuda',
	COUNTRY_ARGENTINA: 'Argentina',
	COUNTRY_ARMENIA: 'Armenia',
	COUNTRY_ARUBA: 'Aruba',
	COUNTRY_AUSTRALIA: 'Australia',
	COUNTRY_AUSTRIA: 'Austria',
	COUNTRY_AZERBAIJAN: 'Azerbaijan',
	COUNTRY_BAHAMAS_THE: 'Bahamas The',
	COUNTRY_BAHRAIN: 'Bahrain',
	COUNTRY_BANGLADESH: 'Bangladesh',
	COUNTRY_BARBADOS: 'Barbados',
	COUNTRY_BELARUS: 'Belarus',
	COUNTRY_BELGIUM: 'Belgium',
	COUNTRY_BELIZE: 'Belize',
	COUNTRY_BENIN: 'Benin',
	COUNTRY_BERMUDA: 'Bermuda',
	COUNTRY_BHUTAN: 'Bhutan',
	COUNTRY_BOLIVIA: 'Bolivia',
	COUNTRY_BOSNIA_AND_HERZEGOVINA: 'Bosnia and Herzegovina',
	COUNTRY_BOTSWANA: 'Botswana',
	COUNTRY_BOUVET_ISLAND: 'Bouvet Island',
	COUNTRY_BRAZIL: 'Brazil',
	COUNTRY_BRITISH_INDIAN_OCEAN_TERRITORY: 'British Indian Ocean Territory',
	COUNTRY_BRUNEI: 'Brunei',
	COUNTRY_BULGARIA: 'Bulgaria',
	COUNTRY_BURKINA_FASO: 'Burkina Faso',
	COUNTRY_BURUNDI: 'Burundi',
	COUNTRY_CAMBODIA: 'Cambodia',
	COUNTRY_CAMEROON: 'Cameroon',
	COUNTRY_CANADA: 'Canada',
	COUNTRY_CAPE_VERDE: 'Cape Verde',
	COUNTRY_CAYMAN_ISLANDS: 'Cayman Islands',
	COUNTRY_CENTRAL_AFRICAN_REPUBLIC: 'Central African Republic',
	COUNTRY_CHAD: 'Chad',
	COUNTRY_CHILE: 'Chile',
	COUNTRY_CHINA: 'China',
	COUNTRY_CHRISTMAS_ISLAND: 'Christmas Island',
	COUNTRY_COCOS_KEELING_ISLANDS: 'Cocos (Keeling) Islands',
	COUNTRY_COLOMBIA: 'Colombia',
	COUNTRY_COMOROS: 'Comoros',
	COUNTRY_CONGO: 'Congo',
	COUNTRY_CONGO_THE_DEMOCRATIC_REPUBLIC_OF_THE:
		'Congo The Democratic Republic Of The',
	COUNTRY_COOK_ISLANDS: 'Cook Islands',
	COUNTRY_COSTA_RICA: 'Costa Rica',
	COUNTRY_COTE_D__IVOIRE_IVORY_COAST_: "Cote D'Ivoire (Ivory Coast)",
	COUNTRY_CROATIA_HRVATSKA_: 'Croatia (Hrvatska)',
	COUNTRY_CUBA: 'Cuba',
	COUNTRY_CYPRUS: 'Cyprus',
	COUNTRY_CZECH_REPUBLIC: 'Czech Republic',
	COUNTRY_DENMARK: 'Denmark',
	COUNTRY_DJIBOUTI: 'Djibouti',
	COUNTRY_DOMINICA: 'Dominica',
	COUNTRY_DOMINICAN_REPUBLIC: 'Dominican Republic',
	COUNTRY_EAST_TIMOR: 'East Timor',
	COUNTRY_ECUADOR: 'Ecuador',
	COUNTRY_EGYPT: 'Egypt',
	COUNTRY_EL_SALVADOR: 'El Salvador',
	COUNTRY_EQUATORIAL_GUINEA: 'Equatorial Guinea',
	COUNTRY_ERITREA: 'Eritrea',
	COUNTRY_ESTONIA: 'Estonia',
	COUNTRY_ETHIOPIA: 'Ethiopia',
	COUNTRY_FALKLAND_ISLANDS: 'Falkland Islands',
	COUNTRY_FAROE_ISLANDS: 'Faroe Islands',
	COUNTRY_FIJI_ISLANDS: 'Fiji Islands',
	COUNTRY_FINLAND: 'Finland',
	COUNTRY_FRANCE: 'France',
	COUNTRY_FRENCH_GUIANA: 'French Guiana',
	COUNTRY_FRENCH_POLYNESIA: 'French Polynesia',
	COUNTRY_FRENCH_SOUTHERN_TERRITORIES: 'French Southern Territories',
	COUNTRY_GABON: 'Gabon',
	COUNTRY_GAMBIA_THE: 'Gambia The',
	COUNTRY_GEORGIA: 'Georgia',
	COUNTRY_GERMANY: 'Germany',
	COUNTRY_GHANA: 'Ghana',
	COUNTRY_GIBRALTAR: 'Gibraltar',
	COUNTRY_GREECE: 'Greece',
	COUNTRY_GREENLAND: 'Greenland',
	COUNTRY_GRENADA: 'Grenada',
	COUNTRY_GUADELOUPE: 'Guadeloupe',
	COUNTRY_GUAM: 'Guam',
	COUNTRY_GUATEMALA: 'Guatemala',
	COUNTRY_GUERNSEY_AND_ALDERNEY: 'Guernsey and Alderney',
	COUNTRY_GUINEA: 'Guinea',
	COUNTRY_GUINEA_BISSAU: 'Guinea-Bissau',
	COUNTRY_GUYANA: 'Guyana',
	COUNTRY_HAITI: 'Haiti',
	COUNTRY_HEARD_AND_MCDONALD_ISLANDS: 'Heard and McDonald Islands',
	COUNTRY_HONDURAS: 'Honduras',
	'COUNTRY_HONG_KONG_S.A.R.': 'Hong Kong S.A.R.',
	COUNTRY_HUNGARY: 'Hungary',
	COUNTRY_ICELAND: 'Iceland',
	COUNTRY_INDIA: 'India',
	COUNTRY_INDONESIA: 'Indonesia',
	COUNTRY_IRAN: 'Iran',
	COUNTRY_IRAQ: 'Iraq',
	COUNTRY_IRELAND: 'Ireland',
	COUNTRY_ISRAEL: 'Israel',
	COUNTRY_ITALY: 'Italy',
	COUNTRY_JAMAICA: 'Jamaica',
	COUNTRY_JAPAN: 'Japan',
	COUNTRY_JERSEY: 'Jersey',
	COUNTRY_JORDAN: 'Jordan',
	COUNTRY_KAZAKHSTAN: 'Kazakhstan',
	COUNTRY_KENYA: 'Kenya',
	COUNTRY_KIRIBATI: 'Kiribati',
	COUNTRY_KOREA_NORTH: 'Korea North',
	COUNTRY_KOREA_SOUTH: 'Korea South',
	COUNTRY_KUWAIT: 'Kuwait',
	COUNTRY_KYRGYZSTAN: 'Kyrgyzstan',
	COUNTRY_LAOS: 'Laos',
	COUNTRY_LATVIA: 'Latvia',
	COUNTRY_LEBANON: 'Lebanon',
	COUNTRY_LESOTHO: 'Lesotho',
	COUNTRY_LIBERIA: 'Liberia',
	COUNTRY_LIBYA: 'Libya',
	COUNTRY_LIECHTENSTEIN: 'Liechtenstein',
	COUNTRY_LITHUANIA: 'Lithuania',
	COUNTRY_LUXEMBOURG: 'Luxembourg',
	'COUNTRY_MACAU_S.A.R.': 'Macau S.A.R.',
	COUNTRY_MACEDONIA: 'Macedonia',
	COUNTRY_MADAGASCAR: 'Madagascar',
	COUNTRY_MALAWI: 'Malawi',
	COUNTRY_MALAYSIA: 'Malaysia',
	COUNTRY_MALDIVES: 'Maldives',
	COUNTRY_MALI: 'Mali',
	COUNTRY_MALTA: 'Malta',
	COUNTRY_MAN_ISLE_OF_: 'Man (Isle of)',
	COUNTRY_MARSHALL_ISLANDS: 'Marshall Islands',
	COUNTRY_MARTINIQUE: 'Martinique',
	COUNTRY_MAURITANIA: 'Mauritania',
	COUNTRY_MAURITIUS: 'Mauritius',
	COUNTRY_MAYOTTE: 'Mayotte',
	COUNTRY_MEXICO: 'Mexico',
	COUNTRY_MICRONESIA: 'Micronesia',
	COUNTRY_MOLDOVA: 'Moldova',
	COUNTRY_MONACO: 'Monaco',
	COUNTRY_MONGOLIA: 'Mongolia',
	COUNTRY_MONTSERRAT: 'Montserrat',
	COUNTRY_MOROCCO: 'Morocco',
	COUNTRY_MOZAMBIQUE: 'Mozambique',
	COUNTRY_MYANMAR: 'Myanmar',
	COUNTRY_NAMIBIA: 'Namibia',
	COUNTRY_NAURU: 'Nauru',
	COUNTRY_NEPAL: 'Nepal',
	COUNTRY_NETHERLANDS: 'Netherlands',
	COUNTRY_NETHERLANDS_ANTILLES: 'Netherlands Antilles',
	COUNTRY_NEW_CALEDONIA: 'New Caledonia',
	COUNTRY_NEW_ZEALAND: 'New Zealand',
	COUNTRY_NICARAGUA: 'Nicaragua',
	COUNTRY_NIGER: 'Niger',
	COUNTRY_NIGERIA: 'Nigeria',
	COUNTRY_NIUE: 'Niue',
	COUNTRY_NORFOLK_ISLAND: 'Norfolk Island',
	COUNTRY_NORTHERN_MARIANA_ISLANDS: 'Northern Mariana Islands',
	COUNTRY_NORWAY: 'Norway',
	COUNTRY_OMAN: 'Oman',
	COUNTRY_PAKISTAN: 'Pakistan',
	COUNTRY_PALAU: 'Palau',
	COUNTRY_PALESTINIAN_TERRITORY_OCCUPIED: 'Palestinian Territory Occupied',
	COUNTRY_PANAMA: 'Panama',
	COUNTRY_PAPUA_NEW_GUINEA: 'Papua new Guinea',
	COUNTRY_PARAGUAY: 'Paraguay',
	COUNTRY_PERU: 'Peru',
	COUNTRY_PHILIPPINES: 'Philippines',
	COUNTRY_PITCAIRN_ISLAND: 'Pitcairn Island',
	COUNTRY_POLAND: 'Poland',
	COUNTRY_PORTUGAL: 'Portugal',
	COUNTRY_PUERTO_RICO: 'Puerto Rico',
	COUNTRY_QATAR: 'Qatar',
	COUNTRY_REUNION: 'Reunion',
	COUNTRY_ROMANIA: 'Romania',
	COUNTRY_RUSSIA: 'Russia',
	COUNTRY_RWANDA: 'Rwanda',
	COUNTRY_SAINT_HELENA: 'Saint Helena',
	COUNTRY_SAINT_KITTS_AND_NEVIS: 'Saint Kitts And Nevis',
	COUNTRY_SAINT_LUCIA: 'Saint Lucia',
	COUNTRY_SAINT_PIERRE_AND_MIQUELON: 'Saint Pierre and Miquelon',
	COUNTRY_SAINT_VINCENT_AND_THE_GRENADINES: 'Saint Vincent And The Grenadines',
	COUNTRY_SAMOA: 'Samoa',
	COUNTRY_SAN_MARINO: 'San Marino',
	COUNTRY_SAO_TOME_AND_PRINCIPE: 'Sao Tome and Principe',
	COUNTRY_SAUDI_ARABIA: 'Saudi Arabia',
	COUNTRY_SENEGAL: 'Senegal',
	COUNTRY_SERBIA: 'Serbia',
	COUNTRY_SEYCHELLES: 'Seychelles',
	COUNTRY_SIERRA_LEONE: 'Sierra Leone',
	COUNTRY_SINGAPORE: 'Singapore',
	COUNTRY_SLOVAKIA: 'Slovakia',
	COUNTRY_SLOVENIA: 'Slovenia',
	COUNTRY_SOLOMON_ISLANDS: 'Solomon Islands',
	COUNTRY_SOMALIA: 'Somalia',
	COUNTRY_SOUTH_AFRICA: 'South Africa',
	COUNTRY_SOUTH_GEORGIA: 'South Georgia',
	COUNTRY_SOUTH_SUDAN: 'South Sudan',
	COUNTRY_SPAIN: 'Spain',
	COUNTRY_SRI_LANKA: 'Sri Lanka',
	COUNTRY_SUDAN: 'Sudan',
	COUNTRY_SURINAME: 'Suriname',
	COUNTRY_SVALBARD_AND_JAN_MAYEN_ISLANDS: 'Svalbard And Jan Mayen Islands',
	COUNTRY_SWAZILAND: 'Swaziland',
	COUNTRY_SWEDEN: 'Sweden',
	COUNTRY_SWITZERLAND: 'Switzerland',
	COUNTRY_SYRIA: 'Syria',
	COUNTRY_TAIWAN: 'Taiwan',
	COUNTRY_TAJIKISTAN: 'Tajikistan',
	COUNTRY_TANZANIA: 'Tanzania',
	COUNTRY_THAILAND: 'Thailand',
	COUNTRY_TOGO: 'Togo',
	COUNTRY_TOKELAU: 'Tokelau',
	COUNTRY_TONGA: 'Tonga',
	COUNTRY_TRINIDAD_AND_TOBAGO: 'Trinidad And Tobago',
	COUNTRY_TUNISIA: 'Tunisia',
	COUNTRY_TURKEY: 'Turkey',
	COUNTRY_TURKMENISTAN: 'Turkmenistan',
	COUNTRY_TURKS_AND_CAICOS_ISLANDS: 'Turks And Caicos Islands',
	COUNTRY_TUVALU: 'Tuvalu',
	COUNTRY_UGANDA: 'Uganda',
	COUNTRY_UKRAINE: 'Ukraine',
	COUNTRY_UNITED_ARAB_EMIRATES: 'United Arab Emirates',
	COUNTRY_UNITED_KINGDOM: 'United Kingdom',
	COUNTRY_UNITED_STATES_MINOR_OUTLYING_ISLANDS:
		'United States Minor Outlying Islands',
	COUNTRY_URUGUAY: 'Uruguay',
	COUNTRY_UZBEKISTAN: 'Uzbekistan',
	COUNTRY_VANUATU: 'Vanuatu',
	COUNTRY_VATICAN_CITY_STATE_HOLY_SEE_: 'Vatican City State (Holy See)',
	COUNTRY_VENEZUELA: 'Venezuela',
	COUNTRY_VIETNAM: 'Vietnam',
	COUNTRY_VIRGIN_ISLANDS_BRITISH_: 'Virgin Islands (British)',
	COUNTRY_VIRGIN_ISLANDS_US_: 'Virgin Islands (US)',
	COUNTRY_WALLIS_AND_FUTUNA_ISLANDS: 'Wallis And Futuna Islands',
	COUNTRY_WESTERN_SAHARA: 'Western Sahara',
	COUNTRY_YEMEN: 'Yemen',
	COUNTRY_ZAMBIA: 'Zambia',
	COUNTRY_ZIMBABWE: 'Zimbabwe',

	//  STATE/PROVINCE
	STATE_ANDAMAN_AND_NICOBAR_ISLANDS: 'Andaman and Nicobar Islands',
	STATE_ANDHRA_PRADESH: 'Andhra Pradesh',
	STATE_ARUNACHAL_PRADESH: 'Arunachal Pradesh',
	STATE_ASSAM: 'Assam',
	STATE_BIHAR: 'Bihar',
	STATE_CHANDIGARH: 'Chandigarh',
	STATE_CHHATTISGARH: 'Chhattisgarh',
	STATE_DADRA_AND_NAGAR_HAVELI: 'Dadra and Nagar Haveli',
	STATE_DAMAN_AND_DIU: 'Daman and Diu',
	STATE_DELHI: 'Delhi',
	STATE_GOA: 'Goa',
	STATE_GUJARAT: 'Gujarat',
	STATE_HARYANA: 'Haryana',
	STATE_HIMACHAL_PRADESH: 'Himachal Pradesh',
	STATE_JAMMU_AND_KASHMIR: 'Jammu and Kashmir',
	STATE_JHARKHAND: 'Jharkhand',
	STATE_KARNATAKA: 'Karnataka',
	STATE_KENMORE: 'Kenmore',
	STATE_KERALA: 'Kerala',
	STATE_LAKSHADWEEP: 'Lakshadweep',
	STATE_MADHYA_PRADESH: 'Madhya Pradesh',
	STATE_MAHARASHTRA: 'Maharashtra',
	STATE_MANIPUR: 'Manipur',
	STATE_MEGHALAYA: 'Meghalaya',
	STATE_MIZORAM: 'Mizoram',
	STATE_NAGALAND: 'Nagaland',
	STATE_NARORA: 'Narora',
	STATE_NATWAR: 'Natwar',
	STATE_ODISHA: 'Odisha',
	STATE_PASCHIM_MEDINIPUR: 'Paschim Medinipur',
	STATE_PONDICHERRY: 'Pondicherry',
	STATE_PUNJAB: 'Punjab',
	STATE_RAJASTHAN: 'Rajasthan',
	STATE_SIKKIM: 'Sikkim',
	STATE_TAMIL_NADU: 'Tamil Nadu',
	STATE_TELANGANA: 'Telangana',
	STATE_TRIPURA: 'Tripura',
	STATE_UTTAR_PRADESH: 'Uttar Pradesh',
	STATE_UTTARAKHAND: 'Uttarakhand',
	STATE_VAISHALI: 'Vaishali',
	STATE_WEST_BENGAL: 'West Bengal',
	STATE_BADAKHSHAN: 'Badakhshan',
	STATE_BADGIS: 'Badgis',
	STATE_BAGLAN: 'Baglan',
	STATE_BALKH: 'Balkh',
	STATE_BAMIYAN: 'Bamiyan',
	STATE_FARAH: 'Farah',
	STATE_FARYAB: 'Faryab',
	STATE_GAWR: 'Gawr',
	STATE_GAZNI: 'Gazni',
	STATE_HERAT: 'Herat',
	STATE_ALABAMA: 'Alabama',
	STATE_ALASKA: 'Alaska',
	STATE_ARIZONA: 'Arizona',
	STATE_ARKANSAS: 'Arkansas',
	STATE_GEORGIA: 'Georgia',
	STATE_NEW_YORK: 'New York',
	STATE_VERMONT: 'Vermont',
	STATE_WASHINGTON: 'Washington',
	STATE_HILMAND: 'Hilmand',
	STATE_JAWZJAN: 'Jawzjan',
	STATE_KABUL: 'Kabul',
	STATE_KAPISA: 'Kapisa',
	STATE_KHAWST: 'Khawst',
	STATE_KUNAR: 'Kunar',
	STATE_LAGMAN: 'Lagman',
	STATE_LAWGHAR: 'Lawghar',
	STATE_NANGARHAR: 'Nangarhar',
	STATE_NIMRUZ: 'Nimruz',
	STATE_NURISTAN: 'Nuristan',
	STATE_PAKTIKA: 'Paktika',
	STATE_PAKTIYA: 'Paktiya',
	STATE_PARWAN: 'Parwan',
	STATE_QANDAHAR: 'Qandahar',
	STATE_QUNDUZ: 'Qunduz',
	STATE_SAMANGAN: 'Samangan',
	STATE_SAR_E_PUL: 'Sar-e Pul',
	STATE_TAKHAR: 'Takhar',
	STATE_URUZGAN: 'Uruzgan',
	STATE_WARDAG: 'Wardag',
	STATE_ZABUL: 'Zabul',
	STATE_BERAT: 'Berat',
	STATE_BULQIZE: 'Bulqize',
	STATE_DELVINE: 'Delvine',
	STATE_DEVOLL: 'Devoll',
	STATE_DIBRE: 'Dibre',
	STATE_DURRES: 'Durres',
	STATE_ELBASAN: 'Elbasan',
	STATE_FIER: 'Fier',
	STATE_GJIROKASTER: 'Gjirokaster',
	STATE_GRAMSH: 'Gramsh',
	STATE_HAS: 'Has',
	STATE_KAVAJE: 'Kavaje',
	STATE_KOLONJE: 'Kolonje',
	STATE_KORCE: 'Korce',
	STATE_KRUJE: 'Kruje',
	STATE_KUCOVE: 'Kucove',
	STATE_KUKES: 'Kukes',
	STATE_KURBIN: 'Kurbin',
	STATE_LEZHE: 'Lezhe',
	STATE_LIBRAZHD: 'Librazhd',
	STATE_LUSHNJE: 'Lushnje',
	STATE_MALLAKASTER: 'Mallakaster',
	STATE_MALSI_E_MADHE: 'Malsi e Madhe',
	STATE_MAT: 'Mat',
	STATE_MIRDITE: 'Mirdite',
	STATE_PEQIN: 'Peqin',
	STATE_PERMET: 'Permet',
	STATE_POGRADEC: 'Pogradec',
	STATE_PUKE: 'Puke',
	STATE_SARANDE: 'Sarande',
	STATE_SHKODER: 'Shkoder',
	STATE_SKRAPAR: 'Skrapar',
	STATE_TEPELENE: 'Tepelene',
	STATE_TIRANE: 'Tirane',
	STATE_TROPOJE: 'Tropoje',
	STATE_VLORE: 'Vlore',
	STATE_AYN_DAFLAH: 'Ayn Daflah',
	STATE_AYN_TAMUSHANAT: 'Ayn Tamushanat',
	STATE_ADRAR: 'Adrar',
	STATE_ALGIERS: 'Algiers',
	STATE_ANNABAH: 'Annabah',
	STATE_BASHSHAR: 'Bashshar',
	STATE_BATNAH: 'Batnah',
	STATE_BIJAYAH: 'Bijayah',
	STATE_BISKRAH: 'Biskrah',
	STATE_BLIDAH: 'Blidah',
	STATE_BUIRAH: 'Buirah',
	STATE_BUMARDAS: 'Bumardas',
	STATE_BURJ_BU_ARRIRIJ: 'Burj Bu Arririj',
	STATE_GHALIZAN: 'Ghalizan',
	STATE_GHARDAYAH: 'Ghardayah',
	STATE_ILIZI: 'Ilizi',
	STATE_JIJILI: 'Jijili',
	STATE_JILFAH: 'Jilfah',
	STATE_KHANSHALAH: 'Khanshalah',
	STATE_MASILAH: 'Masilah',
	STATE_MIDYAH: 'Midyah',
	STATE_MILAH: 'Milah',
	STATE_MUASKAR: 'Muaskar',
	STATE_MUSTAGHANAM: 'Mustaghanam',
	STATE_NAAMA: 'Naama',
	STATE_ORAN: 'Oran',
	STATE_OUARGLA: 'Ouargla',
	STATE_QALMAH: 'Qalmah',
	STATE_QUSTANTINAH: 'Qustantinah',
	STATE_SAKIKDAH: 'Sakikdah',
	STATE_SATIF: 'Satif',
	STATE_SAYDA: 'Sayda',
	STATE_SIDI_BAN_AL___ABBAS: "Sidi ban-al-'Abbas",
	STATE_SUQ_AHRAS: 'Suq Ahras',
	STATE_TAMANGHASAT: 'Tamanghasat',
	STATE_TIBAZAH: 'Tibazah',
	STATE_TIBISSAH: 'Tibissah',
	STATE_TILIMSAN: 'Tilimsan',
	STATE_TINDUF: 'Tinduf',
	STATE_TISAMSILT: 'Tisamsilt',
	STATE_TIYARAT: 'Tiyarat',
	STATE_TIZI_WAZU: 'Tizi Wazu',
	STATE_UMM_AL_BAWAGHI: 'Umm-al-Bawaghi',
	STATE_WAHRAN: 'Wahran',
	STATE_WARQLA: 'Warqla',
	STATE_WILAYA_D_ALGER: 'Wilaya d Alger',
	STATE_WILAYA_DE_BEJAIA: 'Wilaya de Bejaia',
	STATE_WILAYA_DE_CONSTANTINE: 'Wilaya de Constantine',
	STATE_AL_AGHWAT: 'al-Aghwat',
	STATE_AL_BAYADH: 'al-Bayadh',
	STATE_AL_JAZA__IR: "al-Jaza'ir",
	STATE_AL_WAD: 'al-Wad',
	STATE_ASH_SHALIF: 'ash-Shalif',
	STATE_AT_TARIF: 'at-Tarif',
	STATE_EASTERN: 'Eastern',
	STATE_MANU__A: "Manu'a",
	STATE_SWAINS_ISLAND: 'Swains Island',
	STATE_WESTERN: 'Western',
	STATE_ANDORRA_LA_VELLA: 'Andorra la Vella',
	STATE_CANILLO: 'Canillo',
	STATE_ENCAMP: 'Encamp',
	STATE_LA_MASSANA: 'La Massana',
	STATE_LES_ESCALDES: 'Les Escaldes',
	STATE_ORDINO: 'Ordino',
	STATE_SANT_JULIA_DE_LORIA: 'Sant Julia de Loria',
	STATE_BENGO: 'Bengo',
	STATE_BENGUELA: 'Benguela',
	STATE_BIE: 'Bie',
	STATE_CABINDA: 'Cabinda',
	STATE_CUNENE: 'Cunene',
	STATE_HUAMBO: 'Huambo',
	STATE_HUILA: 'Huila',
	STATE_KUANDO_KUBANGO: 'Kuando-Kubango',
	STATE_KWANZA_NORTE: 'Kwanza Norte',
	STATE_KWANZA_SUL: 'Kwanza Sul',
	STATE_LUANDA: 'Luanda',
	STATE_LUNDA_NORTE: 'Lunda Norte',
	STATE_LUNDA_SUL: 'Lunda Sul',
	STATE_MALANJE: 'Malanje',
	STATE_MOXICO: 'Moxico',
	STATE_NAMIBE: 'Namibe',
	STATE_UIGE: 'Uige',
	STATE_ZAIRE: 'Zaire',
	STATE_OTHER_PROVINCES: 'Other Provinces',
	STATE_SECTOR_CLAIMED_BY_ARGENTINA_CH: 'Sector claimed by Argentina/Ch',
	STATE_SECTOR_CLAIMED_BY_ARGENTINA_UK: 'Sector claimed by Argentina/UK',
	STATE_SECTOR_CLAIMED_BY_AUSTRALIA: 'Sector claimed by Australia',
	STATE_SECTOR_CLAIMED_BY_FRANCE: 'Sector claimed by France',
	STATE_SECTOR_CLAIMED_BY_NEW_ZEALAND: 'Sector claimed by New Zealand',
	STATE_SECTOR_CLAIMED_BY_NORWAY: 'Sector claimed by Norway',
	STATE_UNCLAIMED_SECTOR: 'Unclaimed Sector',
	STATE_BARBUDA: 'Barbuda',
	STATE_SAINT_GEORGE: 'Saint George',
	STATE_SAINT_JOHN: 'Saint John',
	STATE_SAINT_MARY: 'Saint Mary',
	STATE_SAINT_PAUL: 'Saint Paul',
	STATE_SAINT_PETER: 'Saint Peter',
	STATE_SAINT_PHILIP: 'Saint Philip',
	STATE_BUENOS_AIRES: 'Buenos Aires',
	STATE_CATAMARCA: 'Catamarca',
	STATE_CHACO: 'Chaco',
	STATE_CHUBUT: 'Chubut',
	STATE_CORDOBA: 'Cordoba',
	STATE_CORRIENTES: 'Corrientes',
	STATE_DISTRITO_FEDERAL: 'Distrito Federal',
	STATE_ENTRE_RIOS: 'Entre Rios',
	STATE_FORMOSA: 'Formosa',
	STATE_JUJUY: 'Jujuy',
	STATE_LA_PAMPA: 'La Pampa',
	STATE_HIDD: 'Hidd',
	STATE_LA_RIOJA: 'La Rioja',
	STATE_MENDOZA: 'Mendoza',
	STATE_MISIONES: 'Misiones',
	STATE_NEUQUEN: 'Neuquen',
	STATE_RIO_NEGRO: 'Rio Negro',
	STATE_SALTA: 'Salta',
	STATE_SAN_JUAN: 'San Juan',
	STATE_SAN_LUIS: 'San Luis',
	STATE_SANTA_CRUZ: 'Santa Cruz',
	STATE_SANTA_FE: 'Santa Fe',
	STATE_SANTIAGO_DEL_ESTERO: 'Santiago del Estero',
	STATE_TIERRA_DEL_FUEGO: 'Tierra del Fuego',
	STATE_TUCUMAN: 'Tucuman',
	STATE_ARAGATSOTN: 'Aragatsotn',
	STATE_ARARAT: 'Ararat',
	STATE_ARMAVIR: 'Armavir',
	STATE_GEGHARKUNIK: 'Gegharkunik',
	STATE_KOTAIK: 'Kotaik',
	STATE_LORI: 'Lori',
	STATE_SHIRAK: 'Shirak',
	STATE_STEPANAKERT: 'Stepanakert',
	STATE_SYUNIK: 'Syunik',
	STATE_TAVUSH: 'Tavush',
	STATE_VAYOTS_DZOR: 'Vayots Dzor',
	STATE_YEREVAN: 'Yerevan',
	STATE_ARUBA: 'Aruba',
	STATE_AUCKLAND: 'Auckland',
	STATE_AUSTRALIAN_CAPITAL_TERRITORY: 'Australian Capital Territory',
	STATE_BALGOWLAH: 'Balgowlah',
	STATE_BALMAIN: 'Balmain',
	STATE_BANKSTOWN: 'Bankstown',
	STATE_BAULKHAM_HILLS: 'Baulkham Hills',
	STATE_BONNET_BAY: 'Bonnet Bay',
	STATE_CAMBERWELL: 'Camberwell',
	STATE_CAROLE_PARK: 'Carole Park',
	STATE_CASTLE_HILL: 'Castle Hill',
	STATE_CAULFIELD: 'Caulfield',
	STATE_CHATSWOOD: 'Chatswood',
	STATE_CHELTENHAM: 'Cheltenham',
	STATE_CHERRYBROOK: 'Cherrybrook',
	STATE_CLAYTON: 'Clayton',
	STATE_COLLINGWOOD: 'Collingwood',
	STATE_FRENCHS_FOREST: 'Frenchs Forest',
	STATE_HAWTHORN: 'Hawthorn',
	STATE_JANNNALI: 'Jannnali',
	STATE_KNOXFIELD: 'Knoxfield',
	STATE_MELBOURNE: 'Melbourne',
	STATE_NEW_SOUTH_WALES: 'New South Wales',
	STATE_NORTHERN_TERRITORY: 'Northern Territory',
	STATE_PERTH: 'Perth',
	STATE_QUEENSLAND: 'Queensland',
	STATE_SOUTH_AUSTRALIA: 'South Australia',
	STATE_TASMANIA: 'Tasmania',
	STATE_TEMPLESTOWE: 'Templestowe',
	STATE_VICTORIA: 'Victoria',
	STATE_WERRIBEE_SOUTH: 'Werribee south',
	STATE_WESTERN_AUSTRALIA: 'Western Australia',
	STATE_WHEELER: 'Wheeler',
	STATE_BUNDESLAND_SALZBURG: 'Bundesland Salzburg',
	STATE_BUNDESLAND_STEIERMARK: 'Bundesland Steiermark',
	STATE_BUNDESLAND_TIROL: 'Bundesland Tirol',
	STATE_BURGENLAND: 'Burgenland',
	STATE_CARINTHIA: 'Carinthia',
	STATE_KARNTEN: 'Karnten',
	STATE_LIEZEN: 'Liezen',
	STATE_LOWER_AUSTRIA: 'Lower Austria',
	STATE_NIEDEROSTERREICH: 'Niederosterreich',
	STATE_OBEROSTERREICH: 'Oberosterreich',
	STATE_SALZBURG: 'Salzburg',
	STATE_SCHLESWIG_HOLSTEIN: 'Schleswig-Holstein',
	STATE_STEIERMARK: 'Steiermark',
	STATE_STYRIA: 'Styria',
	STATE_TIROL: 'Tirol',
	STATE_UPPER_AUSTRIA: 'Upper Austria',
	STATE_VORARLBERG: 'Vorarlberg',
	STATE_WIEN: 'Wien',
	STATE_ABSERON: 'Abseron',
	STATE_BAKI_SAHARI: 'Baki Sahari',
	STATE_GANCA: 'Ganca',
	STATE_GANJA: 'Ganja',
	STATE_KALBACAR: 'Kalbacar',
	STATE_LANKARAN: 'Lankaran',
	STATE_MIL_QARABAX: 'Mil-Qarabax',
	STATE_MUGAN_SALYAN: 'Mugan-Salyan',
	STATE_NAGORNI_QARABAX: 'Nagorni-Qarabax',
	STATE_NAXCIVAN: 'Naxcivan',
	STATE_PRIARAKS: 'Priaraks',
	STATE_QAZAX: 'Qazax',
	STATE_SAKI: 'Saki',
	STATE_SIRVAN: 'Sirvan',
	STATE_XACMAZ: 'Xacmaz',
	STATE_ABACO: 'Abaco',
	STATE_ACKLINS_ISLAND: 'Acklins Island',
	STATE_ANDROS: 'Andros',
	STATE_BERRY_ISLANDS: 'Berry Islands',
	STATE_BIMINIS: 'Biminis',
	STATE_CAT_ISLAND: 'Cat Island',
	STATE_CROOKED_ISLAND: 'Crooked Island',
	STATE_ELEUTHERA: 'Eleuthera',
	STATE_EXUMA_AND_CAYS: 'Exuma and Cays',
	STATE_GRAND_BAHAMA: 'Grand Bahama',
	STATE_INAGUA_ISLANDS: 'Inagua Islands',
	STATE_LONG_ISLAND: 'Long Island',
	STATE_MAYAGUANA: 'Mayaguana',
	STATE_NEW_PROVIDENCE: 'New Providence',
	STATE_RAGGED_ISLAND: 'Ragged Island',
	STATE_RUM_CAY: 'Rum Cay',
	STATE_SAN_SALVADOR: 'San Salvador',
	STATE_ISA: 'Isa',
	STATE_BADIYAH: 'Badiyah',
	STATE_JIDD_HAFS: 'Jidd Hafs',
	STATE_MAHAMA: 'Mahama',
	STATE_MANAMA: 'Manama',
	STATE_SITRAH: 'Sitrah',
	STATE_AL_MANAMAH: 'al-Manamah',
	STATE_AL_MUHARRAQ: 'al-Muharraq',
	STATE_AR_RIFA__A: "ar-Rifa'a",
	STATE_BAGAR_HAT: 'Bagar Hat',
	STATE_BANDARBAN: 'Bandarban',
	STATE_BARGUNA: 'Barguna',
	STATE_BARISAL: 'Barisal',
	STATE_BHOLA: 'Bhola',
	STATE_BOGORA: 'Bogora',
	STATE_BRAHMAN_BARIYA: 'Brahman Bariya',
	STATE_CHANDPUR: 'Chandpur',
	STATE_CHATTAGAM: 'Chattagam',
	STATE_CHITTAGONG_DIVISION: 'Chittagong Division',
	STATE_CHUADANGA: 'Chuadanga',
	STATE_DHAKA: 'Dhaka',
	STATE_DINAJPUR: 'Dinajpur',
	STATE_FARIDPUR: 'Faridpur',
	STATE_FENI: 'Feni',
	STATE_GAYBANDA: 'Gaybanda',
	STATE_GAZIPUR: 'Gazipur',
	STATE_GOPALGANJ: 'Gopalganj',
	STATE_HABIGANJ: 'Habiganj',
	STATE_JAIPUR_HAT: 'Jaipur Hat',
	STATE_JAMALPUR: 'Jamalpur',
	STATE_JESSOR: 'Jessor',
	STATE_JHALAKATI: 'Jhalakati',
	STATE_JHANAYDAH: 'Jhanaydah',
	STATE_KHAGRACHHARI: 'Khagrachhari',
	STATE_KHULNA: 'Khulna',
	STATE_KISHORGANJ: 'Kishorganj',
	STATE_KOKS_BAZAR: 'Koks Bazar',
	STATE_KOMILLA: 'Komilla',
	STATE_KURIGRAM: 'Kurigram',
	STATE_KUSHTIYA: 'Kushtiya',
	STATE_LAKSHMIPUR: 'Lakshmipur',
	STATE_LALMANIR_HAT: 'Lalmanir Hat',
	STATE_MADARIPUR: 'Madaripur',
	STATE_MAGURA: 'Magura',
	STATE_MAIMANSINGH: 'Maimansingh',
	STATE_MANIKGANJ: 'Manikganj',
	STATE_MAULVI_BAZAR: 'Maulvi Bazar',
	STATE_MEHERPUR: 'Meherpur',
	STATE_MUNSHIGANJ: 'Munshiganj',
	STATE_NARAL: 'Naral',
	STATE_NARAYANGANJ: 'Narayanganj',
	STATE_NARSINGDI: 'Narsingdi',
	STATE_NATOR: 'Nator',
	STATE_NAUGAON: 'Naugaon',
	STATE_NAWABGANJ: 'Nawabganj',
	STATE_NETRAKONA: 'Netrakona',
	STATE_NILPHAMARI: 'Nilphamari',
	STATE_NOAKHALI: 'Noakhali',
	STATE_PABNA: 'Pabna',
	STATE_PANCHAGARH: 'Panchagarh',
	STATE_PATUAKHALI: 'Patuakhali',
	STATE_PIROJPUR: 'Pirojpur',
	STATE_RAJBARI: 'Rajbari',
	STATE_RAJSHAHI: 'Rajshahi',
	STATE_RANGAMATI: 'Rangamati',
	STATE_RANGPUR: 'Rangpur',
	STATE_SATKHIRA: 'Satkhira',
	STATE_SHARIATPUR: 'Shariatpur',
	STATE_SHERPUR: 'Sherpur',
	STATE_SILHAT: 'Silhat',
	STATE_SIRAJGANJ: 'Sirajganj',
	STATE_SUNAMGANJ: 'Sunamganj',
	STATE_TANGAYAL: 'Tangayal',
	STATE_THAKURGAON: 'Thakurgaon',
	STATE_CHRIST_CHURCH: 'Christ Church',
	STATE_SAINT_ANDREW: 'Saint Andrew',
	STATE_SAINT_JAMES: 'Saint James',
	STATE_SAINT_JOSEPH: 'Saint Joseph',
	STATE_SAINT_LUCY: 'Saint Lucy',
	STATE_SAINT_MICHAEL: 'Saint Michael',
	STATE_SAINT_THOMAS: 'Saint Thomas',
	STATE_BREST: 'Brest',
	STATE_HOMJEL: 'Homjel',
	STATE_HRODNA: 'Hrodna',
	STATE_MAHILJOW: 'Mahiljow',
	STATE_MAHILYOWSKAYA_VOBLASTS: 'Mahilyowskaya Voblasts',
	STATE_MINSK: 'Minsk',
	STATE_MINSKAJA_VOBLASTS: 'Minskaja Voblasts',
	STATE_PETRIK: 'Petrik',
	STATE_VICEBSK: 'Vicebsk',
	STATE_ANTWERPEN: 'Antwerpen',
	STATE_BERCHEM: 'Berchem',
	STATE_BRABANT: 'Brabant',
	STATE_BRABANT_WALLON: 'Brabant Wallon',
	STATE_BRUSSEL: 'Brussel',
	STATE_EAST_FLANDERS: 'East Flanders',
	STATE_HAINAUT: 'Hainaut',
	STATE_LIEGE: 'Liege',
	STATE_LIMBURG: 'Limburg',
	STATE_LUXEMBOURG: 'Luxembourg',
	STATE_NAMUR: 'Namur',
	STATE_ONTARIO: 'Ontario',
	STATE_OOST_VLAANDEREN: 'Oost-Vlaanderen',
	STATE_PROVINCIE_BRABANT: 'Provincie Brabant',
	STATE_VLAAMS_BRABANT: 'Vlaams-Brabant',
	STATE_WALLONNE: 'Wallonne',
	STATE_WEST_VLAANDEREN: 'West-Vlaanderen',
	STATE_BELIZE: 'Belize',
	STATE_CAYO: 'Cayo',
	STATE_COROZAL: 'Corozal',
	STATE_ORANGE_WALK: 'Orange Walk',
	STATE_STANN_CREEK: 'Stann Creek',
	STATE_TOLEDO: 'Toledo',
	STATE_ALIBORI: 'Alibori',
	STATE_ATACORA: 'Atacora',
	STATE_ATLANTIQUE: 'Atlantique',
	STATE_BORGOU: 'Borgou',
	STATE_COLLINES: 'Collines',
	STATE_COUFFO: 'Couffo',
	STATE_DONGA: 'Donga',
	STATE_LITTORAL: 'Littoral',
	STATE_MONO: 'Mono',
	STATE_OUEME: 'Oueme',
	STATE_PLATEAU: 'Plateau',
	STATE_ZOU: 'Zou',
	STATE_HAMILTON: 'Hamilton',
	STATE_BUMTHANG: 'Bumthang',
	STATE_CHHUKHA: 'Chhukha',
	STATE_CHIRANG: 'Chirang',
	STATE_DAGA: 'Daga',
	STATE_GEYLEGPHUG: 'Geylegphug',
	STATE_HA: 'Ha',
	STATE_LHUNTSHI: 'Lhuntshi',
	STATE_MONGAR: 'Mongar',
	STATE_PEMAGATSEL: 'Pemagatsel',
	STATE_PUNAKHA: 'Punakha',
	STATE_RINPUNG: 'Rinpung',
	STATE_SAMCHI: 'Samchi',
	STATE_SAMDRUP_JONGKHAR: 'Samdrup Jongkhar',
	STATE_SHEMGANG: 'Shemgang',
	STATE_TASHIGANG: 'Tashigang',
	STATE_TIMPHU: 'Timphu',
	STATE_TONGSA: 'Tongsa',
	STATE_WANGDIPHODRANG: 'Wangdiphodrang',
	STATE_BENI: 'Beni',
	STATE_CHUQUISACA: 'Chuquisaca',
	STATE_COCHABAMBA: 'Cochabamba',
	STATE_LA_PAZ: 'La Paz',
	STATE_ORURO: 'Oruro',
	STATE_PANDO: 'Pando',
	STATE_POTOSI: 'Potosi',
	STATE_TARIJA: 'Tarija',
	STATE_FEDERACIJA_BOSNA_I_HERCEGOVINA: 'Federacija Bosna i Hercegovina',
	STATE_REPUBLIKA_SRPSKA: 'Republika Srpska',
	STATE_CENTRAL_BOBONONG: 'Central Bobonong',
	STATE_CENTRAL_BOTETI: 'Central Boteti',
	STATE_CENTRAL_MAHALAPYE: 'Central Mahalapye',
	STATE_CENTRAL_SEROWE_PALAPYE: 'Central Serowe-Palapye',
	STATE_CENTRAL_TUTUME: 'Central Tutume',
	STATE_CHOBE: 'Chobe',
	STATE_FRANCISTOWN: 'Francistown',
	STATE_GABORONE: 'Gaborone',
	STATE_GHANZI: 'Ghanzi',
	STATE_JWANENG: 'Jwaneng',
	STATE_KGALAGADI_NORTH: 'Kgalagadi North',
	STATE_KGALAGADI_SOUTH: 'Kgalagadi South',
	STATE_KGATLENG: 'Kgatleng',
	STATE_KWENENG: 'Kweneng',
	STATE_LOBATSE: 'Lobatse',
	STATE_NGAMILAND: 'Ngamiland',
	STATE_NGWAKETSE: 'Ngwaketse',
	STATE_NORTH_EAST: 'North East',
	STATE_OKAVANGO: 'Okavango',
	STATE_ORAPA: 'Orapa',
	STATE_SELIBE_PHIKWE: 'Selibe Phikwe',
	STATE_SOUTH_EAST: 'South East',
	STATE_SOWA: 'Sowa',
	STATE_BOUVET_ISLAND: 'Bouvet Island',
	STATE_ACRE: 'Acre',
	STATE_ALAGOAS: 'Alagoas',
	STATE_AMAPA: 'Amapa',
	STATE_AMAZONAS: 'Amazonas',
	STATE_BAHIA: 'Bahia',
	STATE_CEARA: 'Ceara',
	STATE_ESPIRITO_SANTO: 'Espirito Santo',
	STATE_ESTADO_DE_SAO_PAULO: 'Estado de Sao Paulo',
	STATE_GOIAS: 'Goias',
	STATE_MARANHAO: 'Maranhao',
	STATE_MATO_GROSSO: 'Mato Grosso',
	STATE_MATO_GROSSO_DO_SUL: 'Mato Grosso do Sul',
	STATE_MINAS_GERAIS: 'Minas Gerais',
	STATE_PARA: 'Para',
	STATE_PARAIBA: 'Paraiba',
	STATE_PARANA: 'Parana',
	STATE_PERNAMBUCO: 'Pernambuco',
	STATE_PIAUI: 'Piaui',
	STATE_RIO_GRANDE_DO_NORTE: 'Rio Grande do Norte',
	STATE_RIO_GRANDE_DO_SUL: 'Rio Grande do Sul',
	STATE_RIO_DE_JANEIRO: 'Rio de Janeiro',
	STATE_RONDONIA: 'Rondonia',
	STATE_RORAIMA: 'Roraima',
	STATE_SANTA_CATARINA: 'Santa Catarina',
	STATE_SAO_PAULO: 'Sao Paulo',
	STATE_SERGIPE: 'Sergipe',
	STATE_TOCANTINS: 'Tocantins',
	STATE_BRITISH_INDIAN_OCEAN_TERRITORY: 'British Indian Ocean Territory',
	STATE_BELAIT: 'Belait',
	STATE_BRUNEI_MUARA: 'Brunei-Muara',
	STATE_TEMBURONG: 'Temburong',
	STATE_TUTONG: 'Tutong',
	STATE_BLAGOEVGRAD: 'Blagoevgrad',
	STATE_BURGAS: 'Burgas',
	STATE_DOBRICH: 'Dobrich',
	STATE_GABROVO: 'Gabrovo',
	STATE_HASKOVO: 'Haskovo',
	STATE_JAMBOL: 'Jambol',
	STATE_KARDZHALI: 'Kardzhali',
	STATE_KJUSTENDIL: 'Kjustendil',
	STATE_LOVECH: 'Lovech',
	STATE_MONTANA: 'Montana',
	STATE_OBLAST_SOFIYA_GRAD: 'Oblast Sofiya-Grad',
	STATE_PAZARDZHIK: 'Pazardzhik',
	STATE_PERNIK: 'Pernik',
	STATE_PLEVEN: 'Pleven',
	STATE_PLOVDIV: 'Plovdiv',
	STATE_RAZGRAD: 'Razgrad',
	STATE_RUSE: 'Ruse',
	STATE_SHUMEN: 'Shumen',
	STATE_SILISTRA: 'Silistra',
	STATE_SLIVEN: 'Sliven',
	STATE_SMOLJAN: 'Smoljan',
	STATE_SOFIJA_GRAD: 'Sofija grad',
	STATE_SOFIJSKA_OBLAST: 'Sofijska oblast',
	STATE_STARA_ZAGORA: 'Stara Zagora',
	STATE_TARGOVISHTE: 'Targovishte',
	STATE_VARNA: 'Varna',
	STATE_VELIKO_TARNOVO: 'Veliko Tarnovo',
	STATE_VIDIN: 'Vidin',
	STATE_VRACA: 'Vraca',
	STATE_YABLANIZA: 'Yablaniza',
	STATE_BALE: 'Bale',
	STATE_BAM: 'Bam',
	STATE_BAZEGA: 'Bazega',
	STATE_BOUGOURIBA: 'Bougouriba',
	STATE_BOULGOU: 'Boulgou',
	STATE_BOULKIEMDE: 'Boulkiemde',
	STATE_COMOE: 'Comoe',
	STATE_GANZOURGOU: 'Ganzourgou',
	STATE_GNAGNA: 'Gnagna',
	STATE_GOURMA: 'Gourma',
	STATE_HOUET: 'Houet',
	STATE_IOBA: 'Ioba',
	STATE_KADIOGO: 'Kadiogo',
	STATE_KENEDOUGOU: 'Kenedougou',
	STATE_KOMANDJARI: 'Komandjari',
	STATE_KOMPIENGA: 'Kompienga',
	STATE_KOSSI: 'Kossi',
	STATE_KOURITENGA: 'Kouritenga',
	STATE_KOURWEOGO: 'Kourweogo',
	STATE_LERABA: 'Leraba',
	STATE_MOUHOUN: 'Mouhoun',
	STATE_NAHOURI: 'Nahouri',
	STATE_NAMENTENGA: 'Namentenga',
	STATE_NOUMBIEL: 'Noumbiel',
	STATE_OUBRITENGA: 'Oubritenga',
	STATE_OUDALAN: 'Oudalan',
	STATE_PASSORE: 'Passore',
	STATE_PONI: 'Poni',
	STATE_SANGUIE: 'Sanguie',
	STATE_SANMATENGA: 'Sanmatenga',
	STATE_SENO: 'Seno',
	STATE_SISSILI: 'Sissili',
	STATE_SOUM: 'Soum',
	STATE_SOUROU: 'Sourou',
	STATE_TAPOA: 'Tapoa',
	STATE_TUY: 'Tuy',
	STATE_YATENGA: 'Yatenga',
	STATE_ZONDOMA: 'Zondoma',
	STATE_ZOUNDWEOGO: 'Zoundweogo',
	STATE_BUBANZA: 'Bubanza',
	STATE_BUJUMBURA: 'Bujumbura',
	STATE_BURURI: 'Bururi',
	STATE_CANKUZO: 'Cankuzo',
	STATE_CIBITOKE: 'Cibitoke',
	STATE_GITEGA: 'Gitega',
	STATE_KARUZI: 'Karuzi',
	STATE_KAYANZA: 'Kayanza',
	STATE_KIRUNDO: 'Kirundo',
	STATE_MAKAMBA: 'Makamba',
	STATE_MURAMVYA: 'Muramvya',
	STATE_MUYINGA: 'Muyinga',
	STATE_NGOZI: 'Ngozi',
	STATE_RUTANA: 'Rutana',
	STATE_RUYIGI: 'Ruyigi',
	STATE_BANTEAY_MEAN_CHEY: 'Banteay Mean Chey',
	STATE_BAT_DAMBANG: 'Bat Dambang',
	STATE_KAMPONG_CHAM: 'Kampong Cham',
	STATE_KAMPONG_CHHNANG: 'Kampong Chhnang',
	STATE_KAMPONG_SPOEU: 'Kampong Spoeu',
	STATE_KAMPONG_THUM: 'Kampong Thum',
	STATE_KAMPOT: 'Kampot',
	STATE_KANDAL: 'Kandal',
	STATE_KAOH_KONG: 'Kaoh Kong',
	STATE_KRACHEH: 'Kracheh',
	STATE_KRONG_KAEB: 'Krong Kaeb',
	STATE_KRONG_PAILIN: 'Krong Pailin',
	STATE_KRONG_PREAH_SIHANOUK: 'Krong Preah Sihanouk',
	STATE_MONDOL_KIRI: 'Mondol Kiri',
	STATE_OTDAR_MEAN_CHEY: 'Otdar Mean Chey',
	STATE_PHNUM_PENH: 'Phnum Penh',
	STATE_POUSAT: 'Pousat',
	STATE_PREAH_VIHEAR: 'Preah Vihear',
	STATE_PREY_VEAENG: 'Prey Veaeng',
	STATE_ROTANAK_KIRI: 'Rotanak Kiri',
	STATE_SIEM_REAB: 'Siem Reab',
	STATE_STUENG_TRAENG: 'Stueng Traeng',
	STATE_SVAY_RIENG: 'Svay Rieng',
	STATE_TAKAEV: 'Takaev',
	STATE_ADAMAOUA: 'Adamaoua',
	STATE_CENTRE: 'Centre',
	STATE_EST: 'Est',
	STATE_NORD: 'Nord',
	STATE_NORD_EXTREME: 'Nord Extreme',
	STATE_NORDOUEST: 'Nordouest',
	STATE_OUEST: 'Ouest',
	STATE_SUD: 'Sud',
	STATE_SUDOUEST: 'Sudouest',
	STATE_ALBERTA: 'Alberta',
	STATE_BRITISH_COLUMBIA: 'British Columbia',
	STATE_MANITOBA: 'Manitoba',
	STATE_NEW_BRUNSWICK: 'New Brunswick',
	STATE_NEWFOUNDLAND_AND_LABRADOR: 'Newfoundland and Labrador',
	STATE_NORTHWEST_TERRITORIES: 'Northwest Territories',
	STATE_NOVA_SCOTIA: 'Nova Scotia',
	STATE_NUNAVUT: 'Nunavut',
	STATE_PRINCE_EDWARD_ISLAND: 'Prince Edward Island',
	STATE_QUEBEC: 'Quebec',
	STATE_SASKATCHEWAN: 'Saskatchewan',
	STATE_YUKON: 'Yukon',
	STATE_BOAVISTA: 'Boavista',
	STATE_BRAVA: 'Brava',
	STATE_FOGO: 'Fogo',
	STATE_MAIO: 'Maio',
	STATE_SAL: 'Sal',
	STATE_SANTO_ANTAO: 'Santo Antao',
	STATE_SAO_NICOLAU: 'Sao Nicolau',
	STATE_SAO_TIAGO: 'Sao Tiago',
	STATE_SAO_VICENTE: 'Sao Vicente',
	STATE_GRAND_CAYMAN: 'Grand Cayman',
	STATE_BAMINGUI_BANGORAN: 'Bamingui-Bangoran',
	STATE_BANGUI: 'Bangui',
	STATE_BASSE_KOTTO: 'Basse-Kotto',
	STATE_HAUT_MBOMOU: 'Haut-Mbomou',
	STATE_HAUTE_KOTTO: 'Haute-Kotto',
	STATE_KEMO: 'Kemo',
	STATE_LOBAYE: 'Lobaye',
	STATE_MAMBERE_KADEI: 'Mambere-Kadei',
	STATE_MBOMOU: 'Mbomou',
	STATE_NANA_GRIBIZI: 'Nana-Gribizi',
	STATE_NANA_MAMBERE: 'Nana-Mambere',
	STATE_OMBELLA_MPOKO: 'Ombella Mpoko',
	STATE_OUAKA: 'Ouaka',
	STATE_OUHAM: 'Ouham',
	STATE_OUHAM_PENDE: 'Ouham-Pende',
	STATE_SANGHA_MBAERE: 'Sangha-Mbaere',
	STATE_VAKAGA: 'Vakaga',
	STATE_BATHA: 'Batha',
	STATE_BILTINE: 'Biltine',
	STATE_BOURKOU_ENNEDI_TIBESTI: 'Bourkou-Ennedi-Tibesti',
	STATE_CHARI_BAGUIRMI: 'Chari-Baguirmi',
	STATE_GUERA: 'Guera',
	STATE_KANEM: 'Kanem',
	STATE_LAC: 'Lac',
	STATE_LOGONE_OCCIDENTAL: 'Logone Occidental',
	STATE_LOGONE_ORIENTAL: 'Logone Oriental',
	STATE_MAYO_KEBBI: 'Mayo-Kebbi',
	STATE_MOYEN_CHARI: 'Moyen-Chari',
	STATE_OUADDAI: 'Ouaddai',
	STATE_SALAMAT: 'Salamat',
	STATE_TANDJILE: 'Tandjile',
	STATE_AISEN: 'Aisen',
	STATE_ANTOFAGASTA: 'Antofagasta',
	STATE_ARAUCANIA: 'Araucania',
	STATE_ATACAMA: 'Atacama',
	STATE_BIO_BIO: 'Bio Bio',
	STATE_COQUIMBO: 'Coquimbo',
	STATE_LIBERTADOR_GENERAL_BERNARDO_O: 'Libertador General Bernardo O',
	STATE_LOS_LAGOS: 'Los Lagos',
	STATE_MAGELLANES: 'Magellanes',
	STATE_MAULE: 'Maule',
	STATE_METROPOLITANA: 'Metropolitana',
	STATE_METROPOLITANA_DE_SANTIAGO: 'Metropolitana de Santiago',
	STATE_TARAPACA: 'Tarapaca',
	STATE_VALPARAISO: 'Valparaiso',
	STATE_ANHUI: 'Anhui',
	STATE_ANHUI_PROVINCE: 'Anhui Province',
	STATE_ANHUI_SHENG: 'Anhui Sheng',
	STATE_AOMEN: 'Aomen',
	STATE_BEIJING: 'Beijing',
	STATE_BEIJING_SHI: 'Beijing Shi',
	STATE_CHONGQING: 'Chongqing',
	STATE_FUJIAN: 'Fujian',
	STATE_FUJIAN_SHENG: 'Fujian Sheng',
	STATE_GANSU: 'Gansu',
	STATE_GUANGDONG: 'Guangdong',
	STATE_GUANGDONG_SHENG: 'Guangdong Sheng',
	STATE_GUANGXI: 'Guangxi',
	STATE_GUIZHOU: 'Guizhou',
	STATE_HAINAN: 'Hainan',
	STATE_HEBEI: 'Hebei',
	STATE_HEILONGJIANG: 'Heilongjiang',
	STATE_HENAN: 'Henan',
	STATE_HUBEI: 'Hubei',
	STATE_HUNAN: 'Hunan',
	STATE_JIANGSU: 'Jiangsu',
	STATE_JIANGSU_SHENG: 'Jiangsu Sheng',
	STATE_JIANGXI: 'Jiangxi',
	STATE_JILIN: 'Jilin',
	STATE_LIAONING: 'Liaoning',
	STATE_LIAONING_SHENG: 'Liaoning Sheng',
	STATE_NEI_MONGGOL: 'Nei Monggol',
	STATE_NINGXIA_HUI: 'Ningxia Hui',
	STATE_QINGHAI: 'Qinghai',
	STATE_SHAANXI: 'Shaanxi',
	STATE_SHANDONG: 'Shandong',
	STATE_SHANDONG_SHENG: 'Shandong Sheng',
	STATE_SHANGHAI: 'Shanghai',
	STATE_SHANXI: 'Shanxi',
	STATE_SICHUAN: 'Sichuan',
	STATE_TIANJIN: 'Tianjin',
	STATE_XIANGGANG: 'Xianggang',
	STATE_XINJIANG: 'Xinjiang',
	STATE_XIZANG: 'Xizang',
	STATE_YUNNAN: 'Yunnan',
	STATE_ZHEJIANG: 'Zhejiang',
	STATE_ZHEJIANG_SHENG: 'Zhejiang Sheng',
	STATE_CHRISTMAS_ISLAND: 'Christmas Island',
	STATE_COCOS_KEELING_ISLANDS: 'Cocos (Keeling) Islands',
	STATE_ANTIOQUIA: 'Antioquia',
	STATE_ARAUCA: 'Arauca',
	STATE_ATLANTICO: 'Atlantico',
	STATE_BOGOTA: 'Bogota',
	STATE_BOLIVAR: 'Bolivar',
	STATE_BOYACA: 'Boyaca',
	STATE_CALDAS: 'Caldas',
	STATE_CAQUETA: 'Caqueta',
	STATE_CASANARE: 'Casanare',
	STATE_CAUCA: 'Cauca',
	STATE_CESAR: 'Cesar',
	STATE_CHOCO: 'Choco',
	STATE_CUNDINAMARCA: 'Cundinamarca',
	STATE_GUAINIA: 'Guainia',
	STATE_GUAVIARE: 'Guaviare',
	STATE_LA_GUAJIRA: 'La Guajira',
	STATE_MAGDALENA: 'Magdalena',
	STATE_META: 'Meta',
	STATE_NARINO: 'Narino',
	STATE_NORTE_DE_SANTANDER: 'Norte de Santander',
	STATE_PUTUMAYO: 'Putumayo',
	STATE_QUINDIO: 'Quindio',
	STATE_RISARALDA: 'Risaralda',
	STATE_SAN_ANDRES_Y_PROVIDENCIA: 'San Andres y Providencia',
	STATE_SANTANDER: 'Santander',
	STATE_SUCRE: 'Sucre',
	STATE_TOLIMA: 'Tolima',
	STATE_VALLE_DEL_CAUCA: 'Valle del Cauca',
	STATE_VAUPES: 'Vaupes',
	STATE_VICHADA: 'Vichada',
	STATE_MWALI: 'Mwali',
	STATE_NJAZIDJA: 'Njazidja',
	STATE_NZWANI: 'Nzwani',
	STATE_BOUENZA: 'Bouenza',
	STATE_BRAZZAVILLE: 'Brazzaville',
	STATE_CUVETTE: 'Cuvette',
	STATE_KOUILOU: 'Kouilou',
	STATE_LEKOUMOU: 'Lekoumou',
	STATE_LIKOUALA: 'Likouala',
	STATE_NIARI: 'Niari',
	STATE_PLATEAUX: 'Plateaux',
	STATE_POOL: 'Pool',
	STATE_SANGHA: 'Sangha',
	STATE_BANDUNDU: 'Bandundu',
	STATE_BAS_CONGO: 'Bas-Congo',
	STATE_EQUATEUR: 'Equateur',
	STATE_HAUT_CONGO: 'Haut-Congo',
	STATE_KASAI_OCCIDENTAL: 'Kasai-Occidental',
	STATE_KASAI_ORIENTAL: 'Kasai-Oriental',
	STATE_KATANGA: 'Katanga',
	STATE_KINSHASA: 'Kinshasa',
	STATE_MANIEMA: 'Maniema',
	STATE_NORD_KIVU: 'Nord-Kivu',
	STATE_SUD_KIVU: 'Sud-Kivu',
	STATE_AITUTAKI: 'Aitutaki',
	STATE_ATIU: 'Atiu',
	STATE_MANGAIA: 'Mangaia',
	STATE_MANIHIKI: 'Manihiki',
	STATE_MAUKE: 'Mauke',
	STATE_MITIARO: 'Mitiaro',
	STATE_NASSAU: 'Nassau',
	STATE_PUKAPUKA: 'Pukapuka',
	STATE_RAKAHANGA: 'Rakahanga',
	STATE_RAROTONGA: 'Rarotonga',
	STATE_TONGAREVA: 'Tongareva',
	STATE_ALAJUELA: 'Alajuela',
	STATE_CARTAGO: 'Cartago',
	STATE_GUANACASTE: 'Guanacaste',
	STATE_HEREDIA: 'Heredia',
	STATE_LIMON: 'Limon',
	STATE_PUNTARENAS: 'Puntarenas',
	STATE_SAN_JOSE: 'San Jose',
	STATE_ABIDJAN: 'Abidjan',
	STATE_AGNEBY: 'Agneby',
	STATE_BAFING: 'Bafing',
	STATE_DENGUELE: 'Denguele',
	STATE_DIX_HUIT_MONTAGNES: 'Dix-huit Montagnes',
	STATE_FROMAGER: 'Fromager',
	STATE_HAUT_SASSANDRA: 'Haut-Sassandra',
	STATE_LACS: 'Lacs',
	STATE_LAGUNES: 'Lagunes',
	STATE_MARAHOUE: 'Marahoue',
	STATE_MOYEN_CAVALLY: 'Moyen-Cavally',
	STATE_MOYEN_COMOE: 'Moyen-Comoe',
	STATE_N__ZI_COMOE: "N'zi-Comoe",
	STATE_SASSANDRA: 'Sassandra',
	STATE_SAVANES: 'Savanes',
	STATE_SUD_BANDAMA: 'Sud-Bandama',
	STATE_SUD_COMOE: 'Sud-Comoe',
	STATE_VALLEE_DU_BANDAMA: 'Vallee du Bandama',
	STATE_WORODOUGOU: 'Worodougou',
	STATE_ZANZAN: 'Zanzan',
	STATE_BJELOVAR_BILOGORA: 'Bjelovar-Bilogora',
	STATE_DUBROVNIK_NERETVA: 'Dubrovnik-Neretva',
	STATE_GRAD_ZAGREB: 'Grad Zagreb',
	STATE_ISTRA: 'Istra',
	STATE_KARLOVAC: 'Karlovac',
	STATE_KOPRIVNICA_KRIZHEVCI: 'Koprivnica-Krizhevci',
	STATE_KRAPINA_ZAGORJE: 'Krapina-Zagorje',
	STATE_LIKA_SENJ: 'Lika-Senj',
	STATE_MEDHIMURJE: 'Medhimurje',
	STATE_MEDIMURSKA_ZUPANIJA: 'Medimurska Zupanija',
	STATE_OSIJEK_BARANJA: 'Osijek-Baranja',
	STATE_OSJECKO_BARANJSKA_ZUPANIJA: 'Osjecko-Baranjska Zupanija',
	STATE_POZHEGA_SLAVONIJA: 'Pozhega-Slavonija',
	STATE_PRIMORJE_GORSKI_KOTAR: 'Primorje-Gorski Kotar',
	STATE_SHIBENIK_KNIN: 'Shibenik-Knin',
	STATE_SISAK_MOSLAVINA: 'Sisak-Moslavina',
	STATE_SLAVONSKI_BROD_POSAVINA: 'Slavonski Brod-Posavina',
	STATE_SPLIT_DALMACIJA: 'Split-Dalmacija',
	STATE_VARAZHDIN: 'Varazhdin',
	STATE_VIROVITICA_PODRAVINA: 'Virovitica-Podravina',
	STATE_VUKOVAR_SRIJEM: 'Vukovar-Srijem',
	STATE_ZADAR: 'Zadar',
	STATE_ZAGREB: 'Zagreb',
	STATE_CAMAGUEY: 'Camaguey',
	STATE_CIEGO_DE_AVILA: 'Ciego de Avila',
	STATE_CIENFUEGOS: 'Cienfuegos',
	STATE_CIUDAD_DE_LA_HABANA: 'Ciudad de la Habana',
	STATE_GRANMA: 'Granma',
	STATE_GUANTANAMO: 'Guantanamo',
	STATE_HABANA: 'Habana',
	STATE_HOLGUIN: 'Holguin',
	STATE_ISLA_DE_LA_JUVENTUD: 'Isla de la Juventud',
	STATE_LA_HABANA: 'La Habana',
	STATE_LAS_TUNAS: 'Las Tunas',
	STATE_MATANZAS: 'Matanzas',
	STATE_PINAR_DEL_RIO: 'Pinar del Rio',
	STATE_SANCTI_SPIRITUS: 'Sancti Spiritus',
	STATE_SANTIAGO_DE_CUBA: 'Santiago de Cuba',
	STATE_VILLA_CLARA: 'Villa Clara',
	STATE_GOVERNMENT_CONTROLLED_AREA: 'Government controlled area',
	STATE_LIMASSOL: 'Limassol',
	STATE_NICOSIA_DISTRICT: 'Nicosia District',
	STATE_PAPHOS: 'Paphos',
	STATE_TURKISH_CONTROLLED_AREA: 'Turkish controlled area',
	STATE_CENTRAL_BOHEMIAN: 'Central Bohemian',
	STATE_FRYCOVICE: 'Frycovice',
	STATE_JIHOCESKY_KRAJ: 'Jihocesky Kraj',
	STATE_JIHOCHESKY: 'Jihochesky',
	STATE_JIHOMORAVSKY: 'Jihomoravsky',
	STATE_KARLOVARSKY: 'Karlovarsky',
	STATE_KLECANY: 'Klecany',
	STATE_KRALOVEHRADECKY: 'Kralovehradecky',
	STATE_LIBERECKY: 'Liberecky',
	STATE_LIPOV: 'Lipov',
	STATE_MORAVSKOSLEZSKY: 'Moravskoslezsky',
	STATE_OLOMOUCKY: 'Olomoucky',
	STATE_OLOMOUCKY_KRAJ: 'Olomoucky Kraj',
	STATE_PARDUBICKY: 'Pardubicky',
	STATE_PLZENSKY: 'Plzensky',
	STATE_PRAHA: 'Praha',
	STATE_RAJHRAD: 'Rajhrad',
	STATE_SMIRICE: 'Smirice',
	STATE_SOUTH_MORAVIAN: 'South Moravian',
	STATE_STRAZ_NAD_NISOU: 'Straz nad Nisou',
	STATE_STREDOCHESKY: 'Stredochesky',
	STATE_UNICOV: 'Unicov',
	STATE_USTECKY: 'Ustecky',
	STATE_VALLETTA: 'Valletta',
	STATE_VELESIN: 'Velesin',
	STATE_VYSOCHINA: 'Vysochina',
	STATE_ZLINSKY: 'Zlinsky',
	STATE_BORNHOLM: 'Bornholm',
	STATE_FREDERIKSBORG: 'Frederiksborg',
	STATE_FYN: 'Fyn',
	STATE_HOVEDSTADEN: 'Hovedstaden',
	STATE_KOBENHAVN: 'Kobenhavn',
	STATE_KOBENHAVNS_AMT: 'Kobenhavns Amt',
	STATE_KOBENHAVNS_KOMMUNE: 'Kobenhavns Kommune',
	STATE_NORDJYLLAND: 'Nordjylland',
	STATE_RIBE: 'Ribe',
	STATE_RINGKOBING: 'Ringkobing',
	STATE_ROERVIG: 'Roervig',
	STATE_ROSKILDE: 'Roskilde',
	STATE_ROSLEV: 'Roslev',
	STATE_SJAELLAND: 'Sjaelland',
	STATE_SOEBORG: 'Soeborg',
	STATE_SONDERJYLLAND: 'Sonderjylland',
	STATE_STORSTROM: 'Storstrom',
	STATE_SYDDANMARK: 'Syddanmark',
	STATE_TOELLOESE: 'Toelloese',
	STATE_VEJLE: 'Vejle',
	STATE_VESTSJALLAND: 'Vestsjalland',
	STATE_VIBORG: 'Viborg',
	STATE_ALI_SABIH: 'Ali Sabih',
	STATE_DIKHIL: 'Dikhil',
	STATE_JIBUTI: 'Jibuti',
	STATE_TAJURAH: 'Tajurah',
	STATE_UBUK: 'Ubuk',
	STATE_SAINT_DAVID: 'Saint David',
	STATE_SAINT_LUKE: 'Saint Luke',
	STATE_SAINT_MARK: 'Saint Mark',
	STATE_SAINT_PATRICK: 'Saint Patrick',
	STATE_AZUA: 'Azua',
	STATE_BAHORUCO: 'Bahoruco',
	STATE_BARAHONA: 'Barahona',
	STATE_DAJABON: 'Dajabon',
	STATE_DISTRITO_NACIONAL: 'Distrito Nacional',
	STATE_DUARTE: 'Duarte',
	STATE_EL_SEYBO: 'El Seybo',
	STATE_ELIAS_PINA: 'Elias Pina',
	STATE_ESPAILLAT: 'Espaillat',
	STATE_HATO_MAYOR: 'Hato Mayor',
	STATE_INDEPENDENCIA: 'Independencia',
	STATE_LA_ALTAGRACIA: 'La Altagracia',
	STATE_LA_ROMANA: 'La Romana',
	STATE_LA_VEGA: 'La Vega',
	STATE_MARIA_TRINIDAD_SANCHEZ: 'Maria Trinidad Sanchez',
	STATE_MONSENOR_NOUEL: 'Monsenor Nouel',
	STATE_MONTE_CRISTI: 'Monte Cristi',
	STATE_MONTE_PLATA: 'Monte Plata',
	STATE_PEDERNALES: 'Pedernales',
	STATE_PERAVIA: 'Peravia',
	STATE_PUERTO_PLATA: 'Puerto Plata',
	STATE_SALCEDO: 'Salcedo',
	STATE_SAMANA: 'Samana',
	STATE_SAN_CRISTOBAL: 'San Cristobal',
	STATE_SAN_PEDRO_DE_MACORIS: 'San Pedro de Macoris',
	STATE_SANCHEZ_RAMIREZ: 'Sanchez Ramirez',
	STATE_SANTIAGO: 'Santiago',
	STATE_SANTIAGO_RODRIGUEZ: 'Santiago Rodriguez',
	STATE_VALVERDE: 'Valverde',
	STATE_AILEU: 'Aileu',
	STATE_AINARO: 'Ainaro',
	STATE_AMBENO: 'Ambeno',
	STATE_BAUCAU: 'Baucau',
	STATE_BOBONARO: 'Bobonaro',
	STATE_COVA_LIMA: 'Cova Lima',
	STATE_DILI: 'Dili',
	STATE_ERMERA: 'Ermera',
	STATE_LAUTEM: 'Lautem',
	STATE_LIQUICA: 'Liquica',
	STATE_MANATUTO: 'Manatuto',
	STATE_MANUFAHI: 'Manufahi',
	STATE_VIQUEQUE: 'Viqueque',
	STATE_AZUAY: 'Azuay',
	STATE_CANAR: 'Canar',
	STATE_CARCHI: 'Carchi',
	STATE_CHIMBORAZO: 'Chimborazo',
	STATE_COTOPAXI: 'Cotopaxi',
	STATE_EL_ORO: 'El Oro',
	STATE_ESMERALDAS: 'Esmeraldas',
	STATE_GALAPAGOS: 'Galapagos',
	STATE_GUAYAS: 'Guayas',
	STATE_IMBABURA: 'Imbabura',
	STATE_LOJA: 'Loja',
	STATE_LOS_RIOS: 'Los Rios',
	STATE_MANABI: 'Manabi',
	STATE_MORONA_SANTIAGO: 'Morona Santiago',
	STATE_NAPO: 'Napo',
	STATE_ORELLANA: 'Orellana',
	STATE_PASTAZA: 'Pastaza',
	STATE_PICHINCHA: 'Pichincha',
	STATE_SUCUMBIOS: 'Sucumbios',
	STATE_TUNGURAHUA: 'Tungurahua',
	STATE_ZAMORA_CHINCHIPE: 'Zamora Chinchipe',
	STATE_ASWAN: 'Aswan',
	STATE_ASYUT: 'Asyut',
	STATE_BANI_SUWAYF: 'Bani Suwayf',
	STATE_BUR_SA__ID: "Bur Sa'id",
	STATE_CAIRO: 'Cairo',
	STATE_DUMYAT: 'Dumyat',
	STATE_KAFR_ASH_SHAYKH: 'Kafr-ash-Shaykh',
	STATE_MATRUH: 'Matruh',
	STATE_MUHAFAZAT_AD_DAQAHLIYAH: 'Muhafazat ad Daqahliyah',
	STATE_MUHAFAZAT_AL_FAYYUM: 'Muhafazat al Fayyum',
	STATE_MUHAFAZAT_AL_GHARBIYAH: 'Muhafazat al Gharbiyah',
	STATE_MUHAFAZAT_AL_ISKANDARIYAH: 'Muhafazat al Iskandariyah',
	STATE_MUHAFAZAT_AL_QAHIRAH: 'Muhafazat al Qahirah',
	STATE_QINA: 'Qina',
	STATE_SAWHAJ: 'Sawhaj',
	STATE_SINA_AL_JANUBIYAH: 'Sina al-Janubiyah',
	STATE_SINA_ASH_SHAMALIYAH: 'Sina ash-Shamaliyah',
	STATE_AD_DAQAHLIYAH: 'ad-Daqahliyah',
	STATE_AL_BAHR_AL_AHMAR: 'al-Bahr-al-Ahmar',
	STATE_AL_BUHAYRAH: 'al-Buhayrah',
	STATE_AL_FAYYUM: 'al-Fayyum',
	STATE_AL_GHARBIYAH: 'al-Gharbiyah',
	STATE_AL_ISKANDARIYAH: 'al-Iskandariyah',
	STATE_AL_ISMAILIYAH: 'al-Ismailiyah',
	STATE_AL_JIZAH: 'al-Jizah',
	STATE_AL_MINUFIYAH: 'al-Minufiyah',
	STATE_AL_MINYA: 'al-Minya',
	STATE_AL_QAHIRA: 'al-Qahira',
	STATE_AL_QALYUBIYAH: 'al-Qalyubiyah',
	STATE_AL_UQSUR: 'al-Uqsur',
	STATE_AL_WADI_AL_JADID: 'al-Wadi al-Jadid',
	STATE_AS_SUWAYS: 'as-Suways',
	STATE_ASH_SHARQIYAH: 'ash-Sharqiyah',
	STATE_AHUACHAPAN: 'Ahuachapan',
	STATE_CABANAS: 'Cabanas',
	STATE_CHALATENANGO: 'Chalatenango',
	STATE_CUSCATLAN: 'Cuscatlan',
	STATE_LA_LIBERTAD: 'La Libertad',
	STATE_LA_UNION: 'La Union',
	STATE_MORAZAN: 'Morazan',
	STATE_SAN_MIGUEL: 'San Miguel',
	STATE_SAN_VICENTE: 'San Vicente',
	STATE_SANTA_ANA: 'Santa Ana',
	STATE_SONSONATE: 'Sonsonate',
	STATE_USULUTAN: 'Usulutan',
	STATE_ANNOBON: 'Annobon',
	STATE_BIOKO_NORTE: 'Bioko Norte',
	STATE_BIOKO_SUR: 'Bioko Sur',
	STATE_CENTRO_SUR: 'Centro Sur',
	STATE_KIE_NTEM: 'Kie-Ntem',
	STATE_LITORAL: 'Litoral',
	STATE_WELE_NZAS: 'Wele-Nzas',
	STATE_ANSEBA: 'Anseba',
	STATE_DEBUB: 'Debub',
	STATE_DEBUB_KEIH_BAHRI: 'Debub-Keih-Bahri',
	STATE_GASH_BARKA: 'Gash-Barka',
	STATE_MAEKEL: 'Maekel',
	STATE_SEMIEN_KEIH_BAHRI: 'Semien-Keih-Bahri',
	STATE_HARJU: 'Harju',
	STATE_HIIU: 'Hiiu',
	STATE_IDA_VIRU: 'Ida-Viru',
	STATE_JARVA: 'Jarva',
	STATE_JOGEVA: 'Jogeva',
	STATE_LAANE: 'Laane',
	STATE_LAANE_VIRU: 'Laane-Viru',
	STATE_PARNU: 'Parnu',
	STATE_POLVA: 'Polva',
	STATE_RAPLA: 'Rapla',
	STATE_SAARE: 'Saare',
	STATE_TARTU: 'Tartu',
	STATE_VALGA: 'Valga',
	STATE_VILJANDI: 'Viljandi',
	STATE_VORU: 'Voru',
	STATE_ADDIS_ABEBA: 'Addis Abeba',
	STATE_AFAR: 'Afar',
	STATE_AMHARA: 'Amhara',
	STATE_BENISHANGUL: 'Benishangul',
	STATE_DIREDAWA: 'Diredawa',
	STATE_GAMBELLA: 'Gambella',
	STATE_HARAR: 'Harar',
	STATE_JIGJIGA: 'Jigjiga',
	STATE_MEKELE: 'Mekele',
	STATE_OROMIA: 'Oromia',
	STATE_SOMALI: 'Somali',
	STATE_SOUTHERN: 'Southern',
	STATE_TIGRAY: 'Tigray',
	STATE_FALKLAND_ISLANDS: 'Falkland Islands',
	STATE_SOUTH_GEORGIA: 'South Georgia',
	STATE_KLAKSVIK: 'Klaksvik',
	STATE_NOR_ARA_EYSTUROY: 'Nor ara Eysturoy',
	STATE_NOR_OY: 'Nor oy',
	STATE_SANDOY: 'Sandoy',
	STATE_STREYMOY: 'Streymoy',
	STATE_SU_UROY: 'Su uroy',
	STATE_SY_RA_EYSTUROY: 'Sy ra Eysturoy',
	STATE_TORSHAVN: 'Torshavn',
	STATE_VAGA: 'Vaga',
	STATE_CENTRAL: 'Central',
	STATE_NORTHERN: 'Northern',
	STATE_SOUTH_PACIFIC: 'South Pacific',
	STATE_AHVENANMAA: 'Ahvenanmaa',
	STATE_ETELA_KARJALA: 'Etela-Karjala',
	STATE_ETELA_POHJANMAA: 'Etela-Pohjanmaa',
	STATE_ETELA_SAVO: 'Etela-Savo',
	STATE_ETELA_SUOMEN_LAANI: 'Etela-Suomen Laani',
	STATE_ITA_SUOMEN_LAANI: 'Ita-Suomen Laani',
	STATE_ITA_UUSIMAA: 'Ita-Uusimaa',
	STATE_KAINUU: 'Kainuu',
	STATE_KANTA_HAME: 'Kanta-Hame',
	STATE_KESKI_POHJANMAA: 'Keski-Pohjanmaa',
	STATE_KESKI_SUOMI: 'Keski-Suomi',
	STATE_KYMENLAAKSO: 'Kymenlaakso',
	STATE_LANSI_SUOMEN_LAANI: 'Lansi-Suomen Laani',
	STATE_LAPPI: 'Lappi',
	STATE_NORTHERN_SAVONIA: 'Northern Savonia',
	STATE_OSTROBOTHNIA: 'Ostrobothnia',
	STATE_OULUN_LAANI: 'Oulun Laani',
	STATE_PAIJAT_HAME: 'Paijat-Hame',
	STATE_PIRKANMAA: 'Pirkanmaa',
	STATE_POHJANMAA: 'Pohjanmaa',
	STATE_POHJOIS_KARJALA: 'Pohjois-Karjala',
	STATE_POHJOIS_POHJANMAA: 'Pohjois-Pohjanmaa',
	STATE_POHJOIS_SAVO: 'Pohjois-Savo',
	STATE_SAARIJARVI: 'Saarijarvi',
	STATE_SATAKUNTA: 'Satakunta',
	STATE_SOUTHERN_SAVONIA: 'Southern Savonia',
	STATE_TAVASTIA_PROPER: 'Tavastia Proper',
	STATE_ULEABORGS_LAN: 'Uleaborgs Lan',
	STATE_UUSIMAA: 'Uusimaa',
	STATE_VARSINAIS_SUOMI: 'Varsinais-Suomi',
	STATE_AIN: 'Ain',
	STATE_AISNE: 'Aisne',
	STATE_ALBI_LE_SEQUESTRE: 'Albi Le Sequestre',
	STATE_ALLIER: 'Allier',
	STATE_ALPES_COTE_DAZUR: 'Alpes-Cote dAzur',
	STATE_ALPES_MARITIMES: 'Alpes-Maritimes',
	STATE_ALPES_DE_HAUTE_PROVENCE: 'Alpes-de-Haute-Provence',
	STATE_ALSACE: 'Alsace',
	STATE_AQUITAINE: 'Aquitaine',
	STATE_ARDECHE: 'Ardeche',
	STATE_ARDENNES: 'Ardennes',
	STATE_ARIEGE: 'Ariege',
	STATE_AUBE: 'Aube',
	STATE_AUDE: 'Aude',
	STATE_AUVERGNE: 'Auvergne',
	STATE_AVEYRON: 'Aveyron',
	STATE_BAS_RHIN: 'Bas-Rhin',
	STATE_BASSE_NORMANDIE: 'Basse-Normandie',
	STATE_BOUCHES_DU_RHONE: 'Bouches-du-Rhone',
	STATE_BOURGOGNE: 'Bourgogne',
	STATE_BRETAGNE: 'Bretagne',
	STATE_BRITTANY: 'Brittany',
	STATE_BURGUNDY: 'Burgundy',
	STATE_CALVADOS: 'Calvados',
	STATE_CANTAL: 'Cantal',
	STATE_CEDEX: 'Cedex',
	STATE_CHARENTE: 'Charente',
	STATE_CHARENTE_MARITIME: 'Charente-Maritime',
	STATE_CHER: 'Cher',
	STATE_CORREZE: 'Correze',
	STATE_CORSE_DU_SUD: 'Corse-du-Sud',
	STATE_COTE_D__OR: "Cote-d'Or",
	STATE_COTES_D__ARMOR: "Cotes-d'Armor",
	STATE_CREUSE: 'Creuse',
	STATE_CROLLES: 'Crolles',
	STATE_DEUX_SEVRES: 'Deux-Sevres',
	STATE_DORDOGNE: 'Dordogne',
	STATE_DOUBS: 'Doubs',
	STATE_DROME: 'Drome',
	STATE_ESSONNE: 'Essonne',
	STATE_EURE: 'Eure',
	STATE_EURE_ET_LOIR: 'Eure-et-Loir',
	STATE_FEUCHEROLLES: 'Feucherolles',
	STATE_FINISTERE: 'Finistere',
	STATE_FRANCHE_COMTE: 'Franche-Comte',
	STATE_GARD: 'Gard',
	STATE_GERS: 'Gers',
	STATE_GIRONDE: 'Gironde',
	STATE_HAUT_RHIN: 'Haut-Rhin',
	STATE_HAUTE_CORSE: 'Haute-Corse',
	STATE_HAUTE_GARONNE: 'Haute-Garonne',
	STATE_HAUTE_LOIRE: 'Haute-Loire',
	STATE_HAUTE_MARNE: 'Haute-Marne',
	STATE_HAUTE_SAONE: 'Haute-Saone',
	STATE_HAUTE_SAVOIE: 'Haute-Savoie',
	STATE_HAUTE_VIENNE: 'Haute-Vienne',
	STATE_HAUTES_ALPES: 'Hautes-Alpes',
	STATE_HAUTES_PYRENEES: 'Hautes-Pyrenees',
	STATE_HAUTS_DE_SEINE: 'Hauts-de-Seine',
	STATE_HERAULT: 'Herault',
	STATE_ILE_DE_FRANCE: 'Ile-de-France',
	STATE_ILLE_ET_VILAINE: 'Ille-et-Vilaine',
	STATE_INDRE: 'Indre',
	STATE_INDRE_ET_LOIRE: 'Indre-et-Loire',
	STATE_ISERE: 'Isere',
	STATE_JURA: 'Jura',
	STATE_KLAGENFURT: 'Klagenfurt',
	STATE_LANDES: 'Landes',
	STATE_LANGUEDOC_ROUSSILLON: 'Languedoc-Roussillon',
	STATE_LARCAY: 'Larcay',
	STATE_LE_CASTELLET: 'Le Castellet',
	STATE_LE_CREUSOT: 'Le Creusot',
	STATE_LIMOUSIN: 'Limousin',
	STATE_LOIR_ET_CHER: 'Loir-et-Cher',
	STATE_LOIRE: 'Loire',
	STATE_LOIRE_ATLANTIQUE: 'Loire-Atlantique',
	STATE_LOIRET: 'Loiret',
	STATE_LORRAINE: 'Lorraine',
	STATE_LOT: 'Lot',
	STATE_LOT_ET_GARONNE: 'Lot-et-Garonne',
	STATE_LOWER_NORMANDY: 'Lower Normandy',
	STATE_LOZERE: 'Lozere',
	STATE_MAINE_ET_LOIRE: 'Maine-et-Loire',
	STATE_MANCHE: 'Manche',
	STATE_MARNE: 'Marne',
	STATE_MAYENNE: 'Mayenne',
	STATE_MEURTHE_ET_MOSELLE: 'Meurthe-et-Moselle',
	STATE_MEUSE: 'Meuse',
	STATE_MIDI_PYRENEES: 'Midi-Pyrenees',
	STATE_MORBIHAN: 'Morbihan',
	STATE_MOSELLE: 'Moselle',
	STATE_NIEVRE: 'Nievre',
	STATE_NORD_PAS_DE_CALAIS: 'Nord-Pas-de-Calais',
	STATE_OISE: 'Oise',
	STATE_ORNE: 'Orne',
	STATE_PARIS: 'Paris',
	STATE_PAS_DE_CALAIS: 'Pas-de-Calais',
	STATE_PAYS_DE_LA_LOIRE: 'Pays de la Loire',
	STATE_PICARDY: 'Picardy',
	STATE_PUY_DE_DOME: 'Puy-de-Dome',
	STATE_PYRENEES_ATLANTIQUES: 'Pyrenees-Atlantiques',
	STATE_PYRENEES_ORIENTALES: 'Pyrenees-Orientales',
	STATE_QUELMES: 'Quelmes',
	STATE_RHONE: 'Rhone',
	STATE_RHONE_ALPES: 'Rhone-Alpes',
	STATE_SAINT_OUEN: 'Saint Ouen',
	STATE_SAINT_VIATRE: 'Saint Viatre',
	STATE_SAONE_ET_LOIRE: 'Saone-et-Loire',
	STATE_SARTHE: 'Sarthe',
	STATE_SAVOIE: 'Savoie',
	STATE_SEINE_MARITIME: 'Seine-Maritime',
	STATE_SEINE_SAINT_DENIS: 'Seine-Saint-Denis',
	STATE_SEINE_ET_MARNE: 'Seine-et-Marne',
	STATE_SOMME: 'Somme',
	STATE_SOPHIA_ANTIPOLIS: 'Sophia Antipolis',
	STATE_SOUVANS: 'Souvans',
	STATE_TARN: 'Tarn',
	STATE_TARN_ET_GARONNE: 'Tarn-et-Garonne',
	STATE_TERRITOIRE_DE_BELFORT: 'Territoire de Belfort',
	STATE_TREIGNAC: 'Treignac',
	STATE_UPPER_NORMANDY: 'Upper Normandy',
	STATE_VAL_D__OISE: "Val-d'Oise",
	STATE_VAL_DE_MARNE: 'Val-de-Marne',
	STATE_VAR: 'Var',
	STATE_VAUCLUSE: 'Vaucluse',
	STATE_VELLISE: 'Vellise',
	STATE_VENDEE: 'Vendee',
	STATE_VIENNE: 'Vienne',
	STATE_VOSGES: 'Vosges',
	STATE_YONNE: 'Yonne',
	STATE_YVELINES: 'Yvelines',
	STATE_CAYENNE: 'Cayenne',
	STATE_SAINT_LAURENT_DU_MARONI: 'Saint-Laurent-du-Maroni',
	STATE_ILES_DU_VENT: 'Iles du Vent',
	STATE_ILES_SOUS_LE_VENT: 'Iles sous le Vent',
	STATE_MARQUESAS: 'Marquesas',
	STATE_TUAMOTU: 'Tuamotu',
	STATE_TUBUAI: 'Tubuai',
	STATE_AMSTERDAM: 'Amsterdam',
	STATE_CROZET_ISLANDS: 'Crozet Islands',
	STATE_KERGUELEN: 'Kerguelen',
	STATE_ESTUAIRE: 'Estuaire',
	STATE_HAUT_OGOOUE: 'Haut-Ogooue',
	STATE_MOYEN_OGOOUE: 'Moyen-Ogooue',
	STATE_NGOUNIE: 'Ngounie',
	STATE_NYANGA: 'Nyanga',
	STATE_OGOOUE_IVINDO: 'Ogooue-Ivindo',
	STATE_OGOOUE_LOLO: 'Ogooue-Lolo',
	STATE_OGOOUE_MARITIME: 'Ogooue-Maritime',
	STATE_WOLEU_NTEM: 'Woleu-Ntem',
	STATE_BANJUL: 'Banjul',
	STATE_BASSE: 'Basse',
	STATE_BRIKAMA: 'Brikama',
	STATE_JANJANBUREH: 'Janjanbureh',
	STATE_KANIFING: 'Kanifing',
	STATE_KEREWAN: 'Kerewan',
	STATE_KUNTAUR: 'Kuntaur',
	STATE_MANSAKONKO: 'Mansakonko',
	STATE_ABHASIA: 'Abhasia',
	STATE_AJARIA: 'Ajaria',
	STATE_GURIA: 'Guria',
	STATE_IMERETI: 'Imereti',
	STATE_KAHETI: 'Kaheti',
	STATE_KVEMO_KARTLI: 'Kvemo Kartli',
	STATE_MCHETA_MTIANETI: 'Mcheta-Mtianeti',
	STATE_RACHA: 'Racha',
	STATE_SAMAGRELO_ZEMO_SVANETI: 'Samagrelo-Zemo Svaneti',
	STATE_SAMCHE_ZHAVAHETI: 'Samche-Zhavaheti',
	STATE_SHIDA_KARTLI: 'Shida Kartli',
	STATE_TBILISI: 'Tbilisi',
	STATE_BADEN_WURTTEMBERG: 'Baden-Wurttemberg',
	STATE_BAVARIA: 'Bavaria',
	STATE_BAYERN: 'Bayern',
	STATE_BEILSTEIN_WURTT: 'Beilstein Wurtt',
	STATE_BERLIN: 'Berlin',
	STATE_BRANDENBURG: 'Brandenburg',
	STATE_BREMEN: 'Bremen',
	STATE_DREISBACH: 'Dreisbach',
	STATE_FREISTAAT_BAYERN: 'Freistaat Bayern',
	STATE_HAMBURG: 'Hamburg',
	STATE_HANNOVER: 'Hannover',
	STATE_HEROLDSTATT: 'Heroldstatt',
	STATE_HESSEN: 'Hessen',
	STATE_KORTENBERG: 'Kortenberg',
	STATE_LAASDORF: 'Laasdorf',
	STATE_LAND_BADEN_WURTTEMBERG: 'Land Baden-Wurttemberg',
	STATE_LAND_BAYERN: 'Land Bayern',
	STATE_LAND_BRANDENBURG: 'Land Brandenburg',
	STATE_LAND_HESSEN: 'Land Hessen',
	STATE_LAND_MECKLENBURG_VORPOMMERN: 'Land Mecklenburg-Vorpommern',
	STATE_LAND_NORDRHEIN_WESTFALEN: 'Land Nordrhein-Westfalen',
	STATE_LAND_RHEINLAND_PFALZ: 'Land Rheinland-Pfalz',
	STATE_LAND_SACHSEN: 'Land Sachsen',
	STATE_LAND_SACHSEN_ANHALT: 'Land Sachsen-Anhalt',
	STATE_LAND_THURINGEN: 'Land Thuringen',
	STATE_LOWER_SAXONY: 'Lower Saxony',
	STATE_MECKLENBURG_VORPOMMERN: 'Mecklenburg-Vorpommern',
	STATE_MULFINGEN: 'Mulfingen',
	STATE_MUNICH: 'Munich',
	STATE_NEUBEUERN: 'Neubeuern',
	STATE_NIEDERSACHSEN: 'Niedersachsen',
	STATE_NOORD_HOLLAND: 'Noord-Holland',
	STATE_NORDRHEIN_WESTFALEN: 'Nordrhein-Westfalen',
	STATE_NORTH_RHINE_WESTPHALIA: 'North Rhine-Westphalia',
	STATE_OSTERODE: 'Osterode',
	STATE_RHEINLAND_PFALZ: 'Rheinland-Pfalz',
	STATE_RHINELAND_PALATINATE: 'Rhineland-Palatinate',
	STATE_SAARLAND: 'Saarland',
	STATE_SACHSEN: 'Sachsen',
	STATE_SACHSEN_ANHALT: 'Sachsen-Anhalt',
	STATE_SAXONY: 'Saxony',
	STATE_THURINGIA: 'Thuringia',
	STATE_WEBLING: 'Webling',
	STATE_WEINSTRABE: 'Weinstrabe',
	STATE_SCHLOBBORN: 'schlobborn',
	STATE_ASHANTI: 'Ashanti',
	STATE_BRONG_AHAFO: 'Brong-Ahafo',
	STATE_GREATER_ACCRA: 'Greater Accra',
	STATE_UPPER_EAST: 'Upper East',
	STATE_UPPER_WEST: 'Upper West',
	STATE_VOLTA: 'Volta',
	STATE_GIBRALTAR: 'Gibraltar',
	STATE_ACHARNES: 'Acharnes',
	STATE_AHAIA: 'Ahaia',
	STATE_AITOLIA_KAI_AKARNANIA: 'Aitolia kai Akarnania',
	STATE_ARGOLIS: 'Argolis',
	STATE_ARKADIA: 'Arkadia',
	STATE_ARTA: 'Arta',
	STATE_ATTICA: 'Attica',
	STATE_ATTIKI: 'Attiki',
	STATE_AYION_OROS: 'Ayion Oros',
	STATE_CRETE: 'Crete',
	STATE_DODEKANISOS: 'Dodekanisos',
	STATE_DRAMA: 'Drama',
	STATE_EVIA: 'Evia',
	STATE_EVRITANIA: 'Evritania',
	STATE_EVROS: 'Evros',
	STATE_EVVOIA: 'Evvoia',
	STATE_FLORINA: 'Florina',
	STATE_FOKIS: 'Fokis',
	STATE_FTHIOTIS: 'Fthiotis',
	STATE_GREVENA: 'Grevena',
	STATE_HALANDRI: 'Halandri',
	STATE_HALKIDIKI: 'Halkidiki',
	STATE_HANIA: 'Hania',
	STATE_HERAKLION: 'Heraklion',
	STATE_HIOS: 'Hios',
	STATE_ILIA: 'Ilia',
	STATE_IMATHIA: 'Imathia',
	STATE_IOANNINA: 'Ioannina',
	STATE_IRAKLION: 'Iraklion',
	STATE_KARDITSA: 'Karditsa',
	STATE_KASTORIA: 'Kastoria',
	STATE_KAVALA: 'Kavala',
	STATE_KEFALLINIA: 'Kefallinia',
	STATE_KERKIRA: 'Kerkira',
	STATE_KIKLADES: 'Kiklades',
	STATE_KILKIS: 'Kilkis',
	STATE_KORINTHIA: 'Korinthia',
	STATE_KOZANI: 'Kozani',
	STATE_LAKONIA: 'Lakonia',
	STATE_LARISA: 'Larisa',
	STATE_LASITHI: 'Lasithi',
	STATE_LESVOS: 'Lesvos',
	STATE_LEVKAS: 'Levkas',
	STATE_MAGNISIA: 'Magnisia',
	STATE_MESSINIA: 'Messinia',
	STATE_NOMOS_ATTIKIS: 'Nomos Attikis',
	STATE_NOMOS_ZAKYNTHOU: 'Nomos Zakynthou',
	STATE_PELLA: 'Pella',
	STATE_PIERIA: 'Pieria',
	STATE_PIRAIOS: 'Piraios',
	STATE_PREVEZA: 'Preveza',
	STATE_RETHIMNI: 'Rethimni',
	STATE_RODOPI: 'Rodopi',
	STATE_SAMOS: 'Samos',
	STATE_SERRAI: 'Serrai',
	STATE_THESPROTIA: 'Thesprotia',
	STATE_THESSALONIKI: 'Thessaloniki',
	STATE_TRIKALA: 'Trikala',
	STATE_VOIOTIA: 'Voiotia',
	STATE_WEST_GREECE: 'West Greece',
	STATE_XANTHI: 'Xanthi',
	STATE_ZAKINTHOS: 'Zakinthos',
	STATE_AASIAAT: 'Aasiaat',
	STATE_AMMASSALIK: 'Ammassalik',
	STATE_ILLOQQORTOORMIUT: 'Illoqqortoormiut',
	STATE_ILULISSAT: 'Ilulissat',
	STATE_IVITTUUT: 'Ivittuut',
	STATE_KANGAATSIAQ: 'Kangaatsiaq',
	STATE_MANIITSOQ: 'Maniitsoq',
	STATE_NANORTALIK: 'Nanortalik',
	STATE_NARSAQ: 'Narsaq',
	STATE_NUUK: 'Nuuk',
	STATE_PAAMIUT: 'Paamiut',
	STATE_QAANAAQ: 'Qaanaaq',
	STATE_QAQORTOQ: 'Qaqortoq',
	STATE_QASIGIANNGUIT: 'Qasigiannguit',
	STATE_QEQERTARSUAQ: 'Qeqertarsuaq',
	STATE_SISIMIUT: 'Sisimiut',
	STATE_UDENFOR_KOMMUNAL_INDDELING: 'Udenfor kommunal inddeling',
	STATE_UPERNAVIK: 'Upernavik',
	STATE_UUMMANNAQ: 'Uummannaq',
	STATE_CARRIACOU_PETITE_MARTINIQUE: 'Carriacou-Petite Martinique',
	STATE_SAINT_DAVIDS: 'Saint Davids',
	STATE_SAINT_GEORGE__S: "Saint George's",
	STATE_BASSE_TERRE: 'Basse-Terre',
	STATE_GRANDE_TERRE: 'Grande-Terre',
	STATE_ILES_DES_SAINTES: 'Iles des Saintes',
	STATE_LA_DESIRADE: 'La Desirade',
	STATE_MARIE_GALANTE: 'Marie-Galante',
	STATE_SAINT_BARTHELEMY: 'Saint Barthelemy',
	STATE_SAINT_MARTIN: 'Saint Martin',
	STATE_AGANA_HEIGHTS: 'Agana Heights',
	STATE_AGAT: 'Agat',
	STATE_BARRIGADA: 'Barrigada',
	STATE_CHALAN_PAGO_ORDOT: 'Chalan-Pago-Ordot',
	STATE_DEDEDO: 'Dededo',
	STATE_HAGATNA: 'Hagatna',
	STATE_INARAJAN: 'Inarajan',
	STATE_MANGILAO: 'Mangilao',
	STATE_MERIZO: 'Merizo',
	STATE_MONGMONG_TOTO_MAITE: 'Mongmong-Toto-Maite',
	STATE_SANTA_RITA: 'Santa Rita',
	STATE_SINAJANA: 'Sinajana',
	STATE_TALOFOFO: 'Talofofo',
	STATE_TAMUNING: 'Tamuning',
	STATE_YIGO: 'Yigo',
	STATE_YONA: 'Yona',
	STATE_ALTA_VERAPAZ: 'Alta Verapaz',
	STATE_BAJA_VERAPAZ: 'Baja Verapaz',
	STATE_CHIMALTENANGO: 'Chimaltenango',
	STATE_CHIQUIMULA: 'Chiquimula',
	STATE_EL_PROGRESO: 'El Progreso',
	STATE_ESCUINTLA: 'Escuintla',
	STATE_GUATEMALA: 'Guatemala',
	STATE_HUEHUETENANGO: 'Huehuetenango',
	STATE_IZABAL: 'Izabal',
	STATE_JALAPA: 'Jalapa',
	STATE_JUTIAPA: 'Jutiapa',
	STATE_PETEN: 'Peten',
	STATE_QUEZALTENANGO: 'Quezaltenango',
	STATE_QUICHE: 'Quiche',
	STATE_RETALHULEU: 'Retalhuleu',
	STATE_SACATEPEQUEZ: 'Sacatepequez',
	STATE_SAN_MARCOS: 'San Marcos',
	STATE_SANTA_ROSA: 'Santa Rosa',
	STATE_SOLOLA: 'Solola',
	STATE_SUCHITEPEQUEZ: 'Suchitepequez',
	STATE_TOTONICAPAN: 'Totonicapan',
	STATE_ZACAPA: 'Zacapa',
	STATE_ALDERNEY: 'Alderney',
	STATE_CASTEL: 'Castel',
	STATE_FOREST: 'Forest',
	STATE_SAINT_PETER_PORT: 'Saint Peter Port',
	STATE_SAINT_PIERRE_DU_BOIS: 'Saint Pierre du Bois',
	STATE_SAINT_SAMPSON: 'Saint Sampson',
	STATE_SAINT_SAVIOUR: 'Saint Saviour',
	STATE_SARK: 'Sark',
	STATE_TORTEVAL: 'Torteval',
	STATE_VALE: 'Vale',
	STATE_BEYLA: 'Beyla',
	STATE_BOFFA: 'Boffa',
	STATE_BOKE: 'Boke',
	STATE_CONAKRY: 'Conakry',
	STATE_COYAH: 'Coyah',
	STATE_DABOLA: 'Dabola',
	STATE_DALABA: 'Dalaba',
	STATE_DINGUIRAYE: 'Dinguiraye',
	STATE_FARANAH: 'Faranah',
	STATE_FORECARIAH: 'Forecariah',
	STATE_FRIA: 'Fria',
	STATE_GAOUAL: 'Gaoual',
	STATE_GUECKEDOU: 'Gueckedou',
	STATE_KANKAN: 'Kankan',
	STATE_KEROUANE: 'Kerouane',
	STATE_KINDIA: 'Kindia',
	STATE_KISSIDOUGOU: 'Kissidougou',
	STATE_KOUBIA: 'Koubia',
	STATE_KOUNDARA: 'Koundara',
	STATE_KOUROUSSA: 'Kouroussa',
	STATE_LABE: 'Labe',
	STATE_LOLA: 'Lola',
	STATE_MACENTA: 'Macenta',
	STATE_MALI: 'Mali',
	STATE_MAMOU: 'Mamou',
	STATE_MANDIANA: 'Mandiana',
	STATE_NZEREKORE: 'Nzerekore',
	STATE_PITA: 'Pita',
	STATE_SIGUIRI: 'Siguiri',
	STATE_TELIMELE: 'Telimele',
	STATE_TOUGUE: 'Tougue',
	STATE_YOMOU: 'Yomou',
	STATE_BAFATA: 'Bafata',
	STATE_BISSAU: 'Bissau',
	STATE_BOLAMA: 'Bolama',
	STATE_CACHEU: 'Cacheu',
	STATE_GABU: 'Gabu',
	STATE_OIO: 'Oio',
	STATE_QUINARA: 'Quinara',
	STATE_TOMBALI: 'Tombali',
	STATE_BARIMA_WAINI: 'Barima-Waini',
	STATE_CUYUNI_MAZARUNI: 'Cuyuni-Mazaruni',
	STATE_DEMERARA_MAHAICA: 'Demerara-Mahaica',
	STATE_EAST_BERBICE_CORENTYNE: 'East Berbice-Corentyne',
	STATE_ESSEQUIBO_ISLANDS_WEST_DEMERAR: 'Essequibo Islands-West Demerar',
	STATE_MAHAICA_BERBICE: 'Mahaica-Berbice',
	STATE_POMEROON_SUPENAAM: 'Pomeroon-Supenaam',
	STATE_POTARO_SIPARUNI: 'Potaro-Siparuni',
	STATE_UPPER_DEMERARA_BERBICE: 'Upper Demerara-Berbice',
	STATE_UPPER_TAKUTU_UPPER_ESSEQUIBO: 'Upper Takutu-Upper Essequibo',
	STATE_ARTIBONITE: 'Artibonite',
	STATE_GRAND__ANSE: "Grand'Anse",
	STATE_NORD_EST: 'Nord-Est',
	STATE_NORD_OUEST: 'Nord-Ouest',
	STATE_SUD_EST: 'Sud-Est',
	STATE_HEARD_AND_MCDONALD_ISLANDS: 'Heard and McDonald Islands',
	STATE_ATLANTIDA: 'Atlantida',
	STATE_CHOLUTECA: 'Choluteca',
	STATE_COLON: 'Colon',
	STATE_COMAYAGUA: 'Comayagua',
	STATE_COPAN: 'Copan',
	STATE_CORTES: 'Cortes',
	STATE_DISTRITO_CENTRAL: 'Distrito Central',
	STATE_EL_PARAISO: 'El Paraiso',
	STATE_FRANCISCO_MORAZAN: 'Francisco Morazan',
	STATE_GRACIAS_A_DIOS: 'Gracias a Dios',
	STATE_INTIBUCA: 'Intibuca',
	STATE_ISLAS_DE_LA_BAHIA: 'Islas de la Bahia',
	STATE_LEMPIRA: 'Lempira',
	STATE_OCOTEPEQUE: 'Ocotepeque',
	STATE_OLANCHO: 'Olancho',
	STATE_SANTA_BARBARA: 'Santa Barbara',
	STATE_VALLE: 'Valle',
	STATE_YORO: 'Yoro',
	STATE_HONG_KONG: 'Hong Kong',
	STATE_BACS_KISKUN: 'Bacs-Kiskun',
	STATE_BARANYA: 'Baranya',
	STATE_BEKES: 'Bekes',
	STATE_BORSOD_ABAUJ_ZEMPLEN: 'Borsod-Abauj-Zemplen',
	STATE_BUDAPEST: 'Budapest',
	STATE_CSONGRAD: 'Csongrad',
	STATE_FEJER: 'Fejer',
	STATE_GYOR_MOSON_SOPRON: 'Gyor-Moson-Sopron',
	STATE_CORK: 'Cork',
	STATE_HAJDU_BIHAR: 'Hajdu-Bihar',
	STATE_HEVES: 'Heves',
	STATE_JASZ_NAGYKUN_SZOLNOK: 'Jasz-Nagykun-Szolnok',
	STATE_KOMAROM_ESZTERGOM: 'Komarom-Esztergom',
	STATE_NOGRAD: 'Nograd',
	STATE_PEST: 'Pest',
	STATE_SOMOGY: 'Somogy',
	STATE_SZABOLCS_SZATMAR_BEREG: 'Szabolcs-Szatmar-Bereg',
	STATE_TOLNA: 'Tolna',
	STATE_VAS: 'Vas',
	STATE_VESZPREM: 'Veszprem',
	STATE_ZALA: 'Zala',
	STATE_AUSTURLAND: 'Austurland',
	STATE_GULLBRINGUSYSLA: 'Gullbringusysla',
	STATE_HOFU_BORGARSVA_I: 'Hofu borgarsva i',
	STATE_NOR_URLAND_EYSTRA: 'Nor urland eystra',
	STATE_NOR_URLAND_VESTRA: 'Nor urland vestra',
	STATE_SU_URLAND: 'Su urland',
	STATE_SU_URNES: 'Su urnes',
	STATE_VESTFIR_IR: 'Vestfir ir',
	STATE_VESTURLAND: 'Vesturland',
	STATE_ACEH: 'Aceh',
	STATE_BALI: 'Bali',
	STATE_BANGKA_BELITUNG: 'Bangka-Belitung',
	STATE_BANTEN: 'Banten',
	STATE_BENGKULU: 'Bengkulu',
	STATE_GANDARIA: 'Gandaria',
	STATE_GORONTALO: 'Gorontalo',
	STATE_JAKARTA: 'Jakarta',
	STATE_JAMBI: 'Jambi',
	STATE_JAWA_BARAT: 'Jawa Barat',
	STATE_JAWA_TENGAH: 'Jawa Tengah',
	STATE_JAWA_TIMUR: 'Jawa Timur',
	STATE_KALIMANTAN_BARAT: 'Kalimantan Barat',
	STATE_KALIMANTAN_SELATAN: 'Kalimantan Selatan',
	STATE_KALIMANTAN_TENGAH: 'Kalimantan Tengah',
	STATE_KALIMANTAN_TIMUR: 'Kalimantan Timur',
	STATE_KENDAL: 'Kendal',
	STATE_LAMPUNG: 'Lampung',
	STATE_MALUKU: 'Maluku',
	STATE_MALUKU_UTARA: 'Maluku Utara',
	STATE_NUSA_TENGGARA_BARAT: 'Nusa Tenggara Barat',
	STATE_NUSA_TENGGARA_TIMUR: 'Nusa Tenggara Timur',
	STATE_PAPUA: 'Papua',
	STATE_RIAU: 'Riau',
	STATE_RIAU_KEPULAUAN: 'Riau Kepulauan',
	STATE_SOLO: 'Solo',
	STATE_SULAWESI_SELATAN: 'Sulawesi Selatan',
	STATE_SULAWESI_TENGAH: 'Sulawesi Tengah',
	STATE_SULAWESI_TENGGARA: 'Sulawesi Tenggara',
	STATE_SULAWESI_UTARA: 'Sulawesi Utara',
	STATE_SUMATERA_BARAT: 'Sumatera Barat',
	STATE_SUMATERA_SELATAN: 'Sumatera Selatan',
	STATE_SUMATERA_UTARA: 'Sumatera Utara',
	STATE_YOGYAKARTA: 'Yogyakarta',
	STATE_ARDABIL: 'Ardabil',
	STATE_AZARBAYJAN_E_BAKHTARI: 'Azarbayjan-e Bakhtari',
	STATE_AZARBAYJAN_E_KHAVARI: 'Azarbayjan-e Khavari',
	STATE_BUSHEHR: 'Bushehr',
	STATE_CHAHAR_MAHAL_E_BAKHTIARI: 'Chahar Mahal-e Bakhtiari',
	STATE_ESFAHAN: 'Esfahan',
	STATE_FARS: 'Fars',
	STATE_GILAN: 'Gilan',
	STATE_GOLESTAN: 'Golestan',
	STATE_HAMADAN: 'Hamadan',
	STATE_HORMOZGAN: 'Hormozgan',
	STATE_ILAM: 'Ilam',
	STATE_KERMAN: 'Kerman',
	STATE_KERMANSHAH: 'Kermanshah',
	STATE_KHORASAN: 'Khorasan',
	STATE_KHUZESTAN: 'Khuzestan',
	STATE_KOHGILUYEH_E_BOYERAHMAD: 'Kohgiluyeh-e Boyerahmad',
	STATE_KORDESTAN: 'Kordestan',
	STATE_LORESTAN: 'Lorestan',
	STATE_MARKAZI: 'Markazi',
	STATE_MAZANDARAN: 'Mazandaran',
	STATE_OSTAN_E_ESFAHAN: 'Ostan-e Esfahan',
	STATE_QAZVIN: 'Qazvin',
	STATE_QOM: 'Qom',
	STATE_SEMNAN: 'Semnan',
	STATE_SISTAN_E_BALUCHESTAN: 'Sistan-e Baluchestan',
	STATE_TEHRAN: 'Tehran',
	STATE_YAZD: 'Yazd',
	STATE_ZANJAN: 'Zanjan',
	STATE_BABIL: 'Babil',
	STATE_BAGHDAD: 'Baghdad',
	STATE_DAHUK: 'Dahuk',
	STATE_DHI_QAR: 'Dhi Qar',
	STATE_DIYALA: 'Diyala',
	STATE_ERBIL: 'Erbil',
	STATE_IRBIL: 'Irbil',
	STATE_KARBALA: 'Karbala',
	STATE_KURDISTAN: 'Kurdistan',
	STATE_MAYSAN: 'Maysan',
	STATE_NINAWA: 'Ninawa',
	STATE_SALAH_AD_DIN: 'Salah-ad-Din',
	STATE_WASIT: 'Wasit',
	STATE_AL_ANBAR: 'al-Anbar',
	STATE_AL_BASRAH: 'al-Basrah',
	STATE_AL_MUTHANNA: 'al-Muthanna',
	STATE_AL_QADISIYAH: 'al-Qadisiyah',
	STATE_AN_NAJAF: 'an-Najaf',
	STATE_AS_SULAYMANIYAH: 'as-Sulaymaniyah',
	STATE_AT_TA__MIM: "at-Ta'mim",
	STATE_ARMAGH: 'Armagh',
	STATE_CARLOW: 'Carlow',
	STATE_CAVAN: 'Cavan',
	STATE_CLARE: 'Clare',
	STATE_DONEGAL: 'Donegal',
	STATE_DUBLIN: 'Dublin',
	STATE_GALWAY: 'Galway',
	STATE_KERRY: 'Kerry',
	STATE_KILDARE: 'Kildare',
	STATE_KILKENNY: 'Kilkenny',
	STATE_LAOIS: 'Laois',
	STATE_LEINSTER: 'Leinster',
	STATE_LEITRIM: 'Leitrim',
	STATE_LIMERICK: 'Limerick',
	STATE_LOCH_GARMAN: 'Loch Garman',
	STATE_LONGFORD: 'Longford',
	STATE_LOUTH: 'Louth',
	STATE_MAYO: 'Mayo',
	STATE_MEATH: 'Meath',
	STATE_MONAGHAN: 'Monaghan',
	STATE_OFFALY: 'Offaly',
	STATE_ROSCOMMON: 'Roscommon',
	STATE_SLIGO: 'Sligo',
	STATE_TIPPERARY_NORTH_RIDING: 'Tipperary North Riding',
	STATE_TIPPERARY_SOUTH_RIDING: 'Tipperary South Riding',
	STATE_ULSTER: 'Ulster',
	STATE_WATERFORD: 'Waterford',
	STATE_WESTMEATH: 'Westmeath',
	STATE_WEXFORD: 'Wexford',
	STATE_WICKLOW: 'Wicklow',
	STATE_BEIT_HANANIA: 'Beit Hanania',
	STATE_BEN_GURION_AIRPORT: 'Ben Gurion Airport',
	STATE_BETHLEHEM: 'Bethlehem',
	STATE_CAESAREA: 'Caesarea',
	STATE_GAZA: 'Gaza',
	STATE_HADARON: 'Hadaron',
	STATE_HAIFA_DISTRICT: 'Haifa District',
	STATE_HAMERKAZ: 'Hamerkaz',
	STATE_HAZAFON: 'Hazafon',
	STATE_HEBRON: 'Hebron',
	STATE_JAFFA: 'Jaffa',
	STATE_JERUSALEM: 'Jerusalem',
	STATE_KHEFA: 'Khefa',
	STATE_KIRYAT_YAM: 'Kiryat Yam',
	STATE_LOWER_GALILEE: 'Lower Galilee',
	STATE_QALQILYA: 'Qalqilya',
	STATE_TALME_ELAZAR: 'Talme Elazar',
	STATE_TEL_AVIV: 'Tel Aviv',
	STATE_TSAFON: 'Tsafon',
	STATE_UMM_EL_FAHEM: 'Umm El Fahem',
	STATE_YERUSHALAYIM: 'Yerushalayim',
	STATE_ABRUZZI: 'Abruzzi',
	STATE_ABRUZZO: 'Abruzzo',
	STATE_AGRIGENTO: 'Agrigento',
	STATE_ALESSANDRIA: 'Alessandria',
	STATE_ANCONA: 'Ancona',
	STATE_AREZZO: 'Arezzo',
	STATE_ASCOLI_PICENO: 'Ascoli Piceno',
	STATE_ASTI: 'Asti',
	STATE_AVELLINO: 'Avellino',
	STATE_BARI: 'Bari',
	STATE_BASILICATA: 'Basilicata',
	STATE_BELLUNO: 'Belluno',
	STATE_BENEVENTO: 'Benevento',
	STATE_BERGAMO: 'Bergamo',
	STATE_BIELLA: 'Biella',
	STATE_BOLOGNA: 'Bologna',
	STATE_BOLZANO: 'Bolzano',
	STATE_BRESCIA: 'Brescia',
	STATE_BRINDISI: 'Brindisi',
	STATE_CALABRIA: 'Calabria',
	STATE_CAMPANIA: 'Campania',
	STATE_CARTOCETO: 'Cartoceto',
	STATE_CASERTA: 'Caserta',
	STATE_CATANIA: 'Catania',
	STATE_CHIETI: 'Chieti',
	STATE_COMO: 'Como',
	STATE_COSENZA: 'Cosenza',
	STATE_CREMONA: 'Cremona',
	STATE_CUNEO: 'Cuneo',
	STATE_EMILIA_ROMAGNA: 'Emilia-Romagna',
	STATE_FERRARA: 'Ferrara',
	STATE_FIRENZE: 'Firenze',
	STATE_FLORENCE: 'Florence',
	STATE_FORLI_CESENA: 'Forli-Cesena',
	STATE_FRIULI_VENEZIA_GIULIA: 'Friuli-Venezia Giulia',
	STATE_FROSINONE: 'Frosinone',
	STATE_GENOA: 'Genoa',
	STATE_GORIZIA: 'Gorizia',
	STATE_L__AQUILA: "L'Aquila",
	STATE_LAZIO: 'Lazio',
	STATE_LECCE: 'Lecce',
	STATE_LECCO: 'Lecco',
	STATE_LECCO_PROVINCE: 'Lecco Province',
	STATE_LIGURIA: 'Liguria',
	STATE_LODI: 'Lodi',
	STATE_LOMBARDIA: 'Lombardia',
	STATE_LOMBARDY: 'Lombardy',
	STATE_MACERATA: 'Macerata',
	STATE_MANTOVA: 'Mantova',
	STATE_MARCHE: 'Marche',
	STATE_MESSINA: 'Messina',
	STATE_MILAN: 'Milan',
	STATE_MODENA: 'Modena',
	STATE_MOLISE: 'Molise',
	STATE_MOLTENO: 'Molteno',
	STATE_MONTENEGRO: 'Montenegro',
	STATE_MONZA_AND_BRIANZA: 'Monza and Brianza',
	STATE_NAPLES: 'Naples',
	STATE_NOVARA: 'Novara',
	STATE_PADOVA: 'Padova',
	STATE_PARMA: 'Parma',
	STATE_PAVIA: 'Pavia',
	STATE_RUMPHI: 'Rumphi',
	STATE_PERUGIA: 'Perugia',
	STATE_PESARO_URBINO: 'Pesaro-Urbino',
	STATE_PIACENZA: 'Piacenza',
	STATE_PIEDMONT: 'Piedmont',
	STATE_PIEMONTE: 'Piemonte',
	STATE_PISA: 'Pisa',
	STATE_PORDENONE: 'Pordenone',
	STATE_POTENZA: 'Potenza',
	STATE_PUGLIA: 'Puglia',
	STATE_REGGIO_EMILIA: 'Reggio Emilia',
	STATE_RIMINI: 'Rimini',
	STATE_ROMA: 'Roma',
	STATE_SALERNO: 'Salerno',
	STATE_SARDEGNA: 'Sardegna',
	STATE_SASSARI: 'Sassari',
	STATE_SAVONA: 'Savona',
	STATE_SICILIA: 'Sicilia',
	STATE_SIENA: 'Siena',
	STATE_SONDRIO: 'Sondrio',
	STATE_SOUTH_TYROL: 'South Tyrol',
	STATE_TARANTO: 'Taranto',
	STATE_TERAMO: 'Teramo',
	STATE_TORINO: 'Torino',
	STATE_TOSCANA: 'Toscana',
	STATE_TRAPANI: 'Trapani',
	STATE_TRENTINO_ALTO_ADIGE: 'Trentino-Alto Adige',
	STATE_TRENTO: 'Trento',
	STATE_TREVISO: 'Treviso',
	STATE_UDINE: 'Udine',
	STATE_UMBRIA: 'Umbria',
	STATE_VALLE_D__AOSTA: "Valle d'Aosta",
	STATE_VARESE: 'Varese',
	STATE_VENETO: 'Veneto',
	STATE_VENEZIA: 'Venezia',
	STATE_VERBANO_CUSIO_OSSOLA: 'Verbano-Cusio-Ossola',
	STATE_VERCELLI: 'Vercelli',
	STATE_VERONA: 'Verona',
	STATE_VICENZA: 'Vicenza',
	STATE_VITERBO: 'Viterbo',
	STATE_BUXORO_VILOYATI: 'Buxoro Viloyati',
	STATE_CLARENDON: 'Clarendon',
	STATE_HANOVER: 'Hanover',
	STATE_KINGSTON: 'Kingston',
	STATE_MANCHESTER: 'Manchester',
	STATE_PORTLAND: 'Portland',
	STATE_SAINT_ANDREWS: 'Saint Andrews',
	STATE_SAINT_ANN: 'Saint Ann',
	STATE_SAINT_CATHERINE: 'Saint Catherine',
	STATE_SAINT_ELIZABETH: 'Saint Elizabeth',
	STATE_TRELAWNEY: 'Trelawney',
	STATE_WESTMORELAND: 'Westmoreland',
	STATE_AICHI: 'Aichi',
	STATE_AKITA: 'Akita',
	STATE_AOMORI: 'Aomori',
	STATE_CHIBA: 'Chiba',
	STATE_EHIME: 'Ehime',
	STATE_FUKUI: 'Fukui',
	STATE_FUKUOKA: 'Fukuoka',
	STATE_FUKUSHIMA: 'Fukushima',
	STATE_GIFU: 'Gifu',
	STATE_GUMMA: 'Gumma',
	STATE_HIROSHIMA: 'Hiroshima',
	STATE_HOKKAIDO: 'Hokkaido',
	STATE_HYOGO: 'Hyogo',
	STATE_IBARAKI: 'Ibaraki',
	STATE_ISHIKAWA: 'Ishikawa',
	STATE_IWATE: 'Iwate',
	STATE_KAGAWA: 'Kagawa',
	STATE_KAGOSHIMA: 'Kagoshima',
	STATE_KANAGAWA: 'Kanagawa',
	STATE_KANTO: 'Kanto',
	STATE_KOCHI: 'Kochi',
	STATE_KUMAMOTO: 'Kumamoto',
	STATE_KYOTO: 'Kyoto',
	STATE_MIE: 'Mie',
	STATE_MIYAGI: 'Miyagi',
	STATE_MIYAZAKI: 'Miyazaki',
	STATE_NAGANO: 'Nagano',
	STATE_NAGASAKI: 'Nagasaki',
	STATE_NARA: 'Nara',
	STATE_NIIGATA: 'Niigata',
	STATE_OITA: 'Oita',
	STATE_OKAYAMA: 'Okayama',
	STATE_OKINAWA: 'Okinawa',
	STATE_OSAKA: 'Osaka',
	STATE_SAGA: 'Saga',
	STATE_SAITAMA: 'Saitama',
	STATE_SHIGA: 'Shiga',
	STATE_SHIMANE: 'Shimane',
	STATE_SHIZUOKA: 'Shizuoka',
	STATE_TOCHIGI: 'Tochigi',
	STATE_TOKUSHIMA: 'Tokushima',
	STATE_TOKYO: 'Tokyo',
	STATE_TOTTORI: 'Tottori',
	STATE_TOYAMA: 'Toyama',
	STATE_WAKAYAMA: 'Wakayama',
	STATE_YAMAGATA: 'Yamagata',
	STATE_YAMAGUCHI: 'Yamaguchi',
	STATE_YAMANASHI: 'Yamanashi',
	STATE_GROUVILLE: 'Grouville',
	STATE_SAINT_BRELADE: 'Saint Brelade',
	STATE_SAINT_CLEMENT: 'Saint Clement',
	STATE_SAINT_HELIER: 'Saint Helier',
	STATE_SAINT_LAWRENCE: 'Saint Lawrence',
	STATE_SALIMA: 'Salima',
	STATE_TRINITY: 'Trinity',
	STATE_AJLUN: 'Ajlun',
	STATE_AMMAN: 'Amman',
	STATE_IRBID: 'Irbid',
	STATE_JARASH: 'Jarash',
	STATE_MA__AN: "Ma'an",
	STATE_MADABA: 'Madaba',
	STATE_AL___AQABAH: "al-'Aqabah",
	STATE_AL_BALQA: 'al-Balqa',
	STATE_AL_KARAK: 'al-Karak',
	STATE_AL_MAFRAQ: 'al-Mafraq',
	STATE_AT_TAFILAH: 'at-Tafilah',
	STATE_AZ_ZARQA: 'az-Zarqa',
	STATE_AKMECET: 'Akmecet',
	STATE_AKMOLA: 'Akmola',
	STATE_AKTOBE: 'Aktobe',
	STATE_ALMATI: 'Almati',
	STATE_ATIRAU: 'Atirau',
	STATE_BATIS_KAZAKSTAN: 'Batis Kazakstan',
	STATE_BURLINSKY_REGION: 'Burlinsky Region',
	STATE_KARAGANDI: 'Karagandi',
	STATE_KOSTANAY: 'Kostanay',
	STATE_MANKISTAU: 'Mankistau',
	STATE_ONTUSTIK_KAZAKSTAN: 'Ontustik Kazakstan',
	STATE_PAVLODAR: 'Pavlodar',
	STATE_SIGIS_KAZAKSTAN: 'Sigis Kazakstan',
	STATE_SOLTUSTIK_KAZAKSTAN: 'Soltustik Kazakstan',
	STATE_TARAZ: 'Taraz',
	STATE_COAST: 'Coast',
	STATE_NAIROBI: 'Nairobi',
	STATE_NORTH_EASTERN: 'North Eastern',
	STATE_NYANZA: 'Nyanza',
	STATE_RIFT_VALLEY: 'Rift Valley',
	STATE_ABAIANG: 'Abaiang',
	STATE_ABEMANA: 'Abemana',
	STATE_ARANUKA: 'Aranuka',
	STATE_ARORAE: 'Arorae',
	STATE_BANABA: 'Banaba',
	STATE_BERU: 'Beru',
	STATE_BUTARITARI: 'Butaritari',
	STATE_KIRITIMATI: 'Kiritimati',
	STATE_KURIA: 'Kuria',
	STATE_MAIANA: 'Maiana',
	STATE_MAKIN: 'Makin',
	STATE_MARAKEI: 'Marakei',
	STATE_NIKUNAU: 'Nikunau',
	STATE_NONOUTI: 'Nonouti',
	STATE_ONOTOA: 'Onotoa',
	STATE_PHOENIX_ISLANDS: 'Phoenix Islands',
	STATE_TABITEUEA_NORTH: 'Tabiteuea North',
	STATE_TABITEUEA_SOUTH: 'Tabiteuea South',
	STATE_TABUAERAN: 'Tabuaeran',
	STATE_TAMANA: 'Tamana',
	STATE_TARAWA_NORTH: 'Tarawa North',
	STATE_TARAWA_SOUTH: 'Tarawa South',
	STATE_TERAINA: 'Teraina',
	STATE_CHAGANGDO: 'Chagangdo',
	STATE_HAMGYEONGBUKTO: 'Hamgyeongbukto',
	STATE_HAMGYEONGNAMDO: 'Hamgyeongnamdo',
	STATE_HWANGHAEBUKTO: 'Hwanghaebukto',
	STATE_HWANGHAENAMDO: 'Hwanghaenamdo',
	STATE_KAESEONG: 'Kaeseong',
	STATE_KANGWEON: 'Kangweon',
	STATE_NAMPO: 'Nampo',
	STATE_PYEONGANBUKTO: 'Pyeonganbukto',
	STATE_PYEONGANNAMDO: 'Pyeongannamdo',
	STATE_PYEONGYANG: 'Pyeongyang',
	STATE_YANGGANG: 'Yanggang',
	STATE_BUSAN: 'Busan',
	STATE_CHEJU: 'Cheju',
	STATE_CHOLLABUK: 'Chollabuk',
	STATE_CHOLLANAM: 'Chollanam',
	STATE_CHUNGBUK: 'Chungbuk',
	STATE_CHUNGCHEONGBUK: 'Chungcheongbuk',
	STATE_CHUNGCHEONGNAM: 'Chungcheongnam',
	STATE_CHUNGNAM: 'Chungnam',
	STATE_DAEGU: 'Daegu',
	STATE_GANGWON_DO: 'Gangwon-do',
	STATE_GOYANG_SI: 'Goyang-si',
	STATE_GYEONGGI_DO: 'Gyeonggi-do',
	STATE_GYEONGSANG: 'Gyeongsang',
	STATE_GYEONGSANGNAM_DO: 'Gyeongsangnam-do',
	STATE_INCHEON: 'Incheon',
	STATE_JEJU_SI: 'Jeju-Si',
	STATE_JEONBUK: 'Jeonbuk',
	STATE_KWANGJU: 'Kwangju',
	STATE_KYEONGGI: 'Kyeonggi',
	STATE_KYEONGSANGBUK: 'Kyeongsangbuk',
	STATE_KYEONGSANGNAM: 'Kyeongsangnam',
	STATE_KYONGGI_DO: 'Kyonggi-do',
	STATE_KYUNGBUK_DO: 'Kyungbuk-Do',
	STATE_KYUNGGI_DO: 'Kyunggi-Do',
	STATE_PUSAN: 'Pusan',
	STATE_SEOUL: 'Seoul',
	STATE_SUDOGWON: 'Sudogwon',
	STATE_TAEGU: 'Taegu',
	STATE_TAEJEON: 'Taejeon',
	STATE_TAEJON_GWANGYOKSI: 'Taejon-gwangyoksi',
	STATE_ULSAN: 'Ulsan',
	STATE_WONJU: 'Wonju',
	STATE_GWANGYOKSI: 'gwangyoksi',
	STATE_AL_ASIMAH: 'Al Asimah',
	STATE_HAWALLI: 'Hawalli',
	STATE_MISHREF: 'Mishref',
	STATE_QADESIYA: 'Qadesiya',
	STATE_SAFAT: 'Safat',
	STATE_SALMIYA: 'Salmiya',
	STATE_AL_AHMADI: 'al-Ahmadi',
	STATE_AL_FARWANIYAH: 'al-Farwaniyah',
	STATE_AL_JAHRA: 'al-Jahra',
	STATE_AL_KUWAYT: 'al-Kuwayt',
	STATE_BATKEN: 'Batken',
	STATE_BISHKEK: 'Bishkek',
	STATE_CHUI: 'Chui',
	STATE_ISSYK_KUL: 'Issyk-Kul',
	STATE_JALAL_ABAD: 'Jalal-Abad',
	STATE_NARYN: 'Naryn',
	STATE_OSH: 'Osh',
	STATE_TALAS: 'Talas',
	STATE_ATTOPU: 'Attopu',
	STATE_BOKEO: 'Bokeo',
	STATE_BOLIKHAMSAY: 'Bolikhamsay',
	STATE_CHAMPASAK: 'Champasak',
	STATE_HOUAPHANH: 'Houaphanh',
	STATE_KHAMMOUANE: 'Khammouane',
	STATE_LUANG_NAM_THA: 'Luang Nam Tha',
	STATE_LUANG_PRABANG: 'Luang Prabang',
	STATE_OUDOMXAY: 'Oudomxay',
	STATE_PHONGSALY: 'Phongsaly',
	STATE_SARAVAN: 'Saravan',
	STATE_SAVANNAKHET: 'Savannakhet',
	STATE_SEKONG: 'Sekong',
	STATE_VIANGCHAN_PREFECTURE: 'Viangchan Prefecture',
	STATE_VIANGCHAN_PROVINCE: 'Viangchan Province',
	STATE_XAIGNABURY: 'Xaignabury',
	STATE_XIANG_KHUANG: 'Xiang Khuang',
	STATE_AIZKRAUKLES: 'Aizkraukles',
	STATE_ALUKSNES: 'Aluksnes',
	STATE_BALVU: 'Balvu',
	STATE_BAUSKAS: 'Bauskas',
	STATE_CESU: 'Cesu',
	STATE_DAUGAVPILS: 'Daugavpils',
	STATE_DAUGAVPILS_CITY: 'Daugavpils City',
	STATE_DOBELES: 'Dobeles',
	STATE_GULBENES: 'Gulbenes',
	STATE_JEKABSPILS: 'Jekabspils',
	STATE_JELGAVA: 'Jelgava',
	STATE_JELGAVAS: 'Jelgavas',
	STATE_JURMALA_CITY: 'Jurmala City',
	STATE_KRASLAVAS: 'Kraslavas',
	STATE_KULDIGAS: 'Kuldigas',
	STATE_LIEPAJA: 'Liepaja',
	STATE_LIEPAJAS: 'Liepajas',
	STATE_LIMBAZHU: 'Limbazhu',
	STATE_LUDZAS: 'Ludzas',
	STATE_MADONAS: 'Madonas',
	STATE_OGRES: 'Ogres',
	STATE_PREILU: 'Preilu',
	STATE_REZEKNE: 'Rezekne',
	STATE_REZEKNES: 'Rezeknes',
	STATE_RIGA: 'Riga',
	STATE_RIGAS: 'Rigas',
	STATE_SALDUS: 'Saldus',
	STATE_TALSU: 'Talsu',
	STATE_TUKUMA: 'Tukuma',
	STATE_VALKAS: 'Valkas',
	STATE_VALMIERAS: 'Valmieras',
	STATE_VENTSPILS: 'Ventspils',
	STATE_VENTSPILS_CITY: 'Ventspils City',
	STATE_BEIRUT: 'Beirut',
	STATE_JABAL_LUBNAN: 'Jabal Lubnan',
	STATE_MOHAFAZAT_LIBAN_NORD: 'Mohafazat Liban-Nord',
	STATE_MOHAFAZAT_MONT_LIBAN: 'Mohafazat Mont-Liban',
	STATE_SIDON: 'Sidon',
	STATE_AL_BIQA: 'al-Biqa',
	STATE_AL_JANUB: 'al-Janub',
	STATE_AN_NABATIYAH: 'an-Nabatiyah',
	STATE_ASH_SHAMAL: 'ash-Shamal',
	STATE_BEREA: 'Berea',
	STATE_BUTHA_BUTHE: 'Butha-Buthe',
	STATE_LERIBE: 'Leribe',
	STATE_MAFETENG: 'Mafeteng',
	STATE_MASERU: 'Maseru',
	STATE_MOHALE__S_HOEK: "Mohale's Hoek",
	STATE_MOKHOTLONG: 'Mokhotlong',
	STATE_QACHA__S_NEK: "Qacha's Nek",
	STATE_QUTHING: 'Quthing',
	STATE_THABA_TSEKA: 'Thaba-Tseka',
	STATE_BOMI: 'Bomi',
	STATE_BONG: 'Bong',
	STATE_GRAND_BASSA: 'Grand Bassa',
	STATE_GRAND_CAPE_MOUNT: 'Grand Cape Mount',
	STATE_GRAND_GEDEH: 'Grand Gedeh',
	STATE_LOFFA: 'Loffa',
	STATE_MARGIBI: 'Margibi',
	STATE_MARYLAND_AND_GRAND_KRU: 'Maryland and Grand Kru',
	STATE_MONTSERRADO: 'Montserrado',
	STATE_NIMBA: 'Nimba',
	STATE_RIVERCESS: 'Rivercess',
	STATE_SINOE: 'Sinoe',
	STATE_AJDABIYA: 'Ajdabiya',
	STATE_FEZZAN: 'Fezzan',
	STATE_BANGHAZI: 'Banghazi',
	STATE_DARNAH: 'Darnah',
	STATE_GHADAMIS: 'Ghadamis',
	STATE_GHARYAN: 'Gharyan',
	STATE_MISRATAH: 'Misratah',
	STATE_MURZUQ: 'Murzuq',
	STATE_SABHA: 'Sabha',
	STATE_SAWFAJJIN: 'Sawfajjin',
	STATE_SURT: 'Surt',
	STATE_TARABULUS: 'Tarabulus',
	STATE_TARHUNAH: 'Tarhunah',
	STATE_TRIPOLITANIA: 'Tripolitania',
	STATE_TUBRUQ: 'Tubruq',
	STATE_YAFRAN: 'Yafran',
	STATE_ZLITAN: 'Zlitan',
	STATE_AL___AZIZIYAH: "al-'Aziziyah",
	STATE_AL_FATIH: 'al-Fatih',
	STATE_AL_JABAL_AL_AKHDAR: 'al-Jabal al Akhdar',
	STATE_AL_JUFRAH: 'al-Jufrah',
	STATE_AL_KHUMS: 'al-Khums',
	STATE_AL_KUFRAH: 'al-Kufrah',
	STATE_AN_NUQAT_AL_KHAMS: 'an-Nuqat al-Khams',
	STATE_ASH_SHATI: 'ash-Shati',
	STATE_AZ_ZAWIYAH: 'az-Zawiyah',
	STATE_BALZERS: 'Balzers',
	STATE_ESCHEN: 'Eschen',
	STATE_GAMPRIN: 'Gamprin',
	STATE_MAUREN: 'Mauren',
	STATE_PLANKEN: 'Planken',
	STATE_RUGGELL: 'Ruggell',
	STATE_SCHAAN: 'Schaan',
	STATE_SCHELLENBERG: 'Schellenberg',
	STATE_TRIESEN: 'Triesen',
	STATE_TRIESENBERG: 'Triesenberg',
	STATE_VADUZ: 'Vaduz',
	STATE_ALYTAUS: 'Alytaus',
	STATE_ANYKSCIAI: 'Anyksciai',
	STATE_KAUNO: 'Kauno',
	STATE_KLAIPEDOS: 'Klaipedos',
	STATE_MARIJAMPOLES: 'Marijampoles',
	STATE_PANEVEZHIO: 'Panevezhio',
	STATE_PANEVEZYS: 'Panevezys',
	STATE_SHIAULIU: 'Shiauliu',
	STATE_TAURAGES: 'Taurages',
	STATE_TELSHIU: 'Telshiu',
	STATE_TELSIAI: 'Telsiai',
	STATE_UTENOS: 'Utenos',
	STATE_VILNIAUS: 'Vilniaus',
	STATE_CAPELLEN: 'Capellen',
	STATE_CLERVAUX: 'Clervaux',
	STATE_DIEKIRCH: 'Diekirch',
	STATE_ECHTERNACH: 'Echternach',
	STATE_ESCH_SUR_ALZETTE: 'Esch-sur-Alzette',
	STATE_GREVENMACHER: 'Grevenmacher',
	STATE_MERSCH: 'Mersch',
	STATE_REDANGE: 'Redange',
	STATE_REMICH: 'Remich',
	STATE_VIANDEN: 'Vianden',
	STATE_WILTZ: 'Wiltz',
	STATE_MACAU: 'Macau',
	STATE_BEROVO: 'Berovo',
	STATE_BITOLA: 'Bitola',
	STATE_BROD: 'Brod',
	STATE_DEBAR: 'Debar',
	STATE_DELCHEVO: 'Delchevo',
	STATE_DEMIR_HISAR: 'Demir Hisar',
	STATE_GEVGELIJA: 'Gevgelija',
	STATE_GOSTIVAR: 'Gostivar',
	STATE_KAVADARCI: 'Kavadarci',
	STATE_KICHEVO: 'Kichevo',
	STATE_KOCHANI: 'Kochani',
	STATE_KRATOVO: 'Kratovo',
	STATE_KRIVA_PALANKA: 'Kriva Palanka',
	STATE_KRUSHEVO: 'Krushevo',
	STATE_KUMANOVO: 'Kumanovo',
	STATE_NEGOTINO: 'Negotino',
	STATE_OHRID: 'Ohrid',
	STATE_PRILEP: 'Prilep',
	STATE_PROBISHTIP: 'Probishtip',
	STATE_RADOVISH: 'Radovish',
	STATE_RESEN: 'Resen',
	STATE_SHTIP: 'Shtip',
	STATE_SKOPJE: 'Skopje',
	STATE_STRUGA: 'Struga',
	STATE_STRUMICA: 'Strumica',
	STATE_SVETI_NIKOLE: 'Sveti Nikole',
	STATE_TETOVO: 'Tetovo',
	STATE_VALANDOVO: 'Valandovo',
	STATE_VELES: 'Veles',
	STATE_VINICA: 'Vinica',
	STATE_ANTANANARIVO: 'Antananarivo',
	STATE_ANTSIRANANA: 'Antsiranana',
	STATE_FIANARANTSOA: 'Fianarantsoa',
	STATE_MAHAJANGA: 'Mahajanga',
	STATE_TOAMASINA: 'Toamasina',
	STATE_TOLIARY: 'Toliary',
	STATE_BALAKA: 'Balaka',
	STATE_BLANTYRE_CITY: 'Blantyre City',
	STATE_CHIKWAWA: 'Chikwawa',
	STATE_CHIRADZULU: 'Chiradzulu',
	STATE_CHITIPA: 'Chitipa',
	STATE_DEDZA: 'Dedza',
	STATE_DOWA: 'Dowa',
	STATE_KARONGA: 'Karonga',
	STATE_KASUNGU: 'Kasungu',
	STATE_LILONGWE_CITY: 'Lilongwe City',
	STATE_MACHINGA: 'Machinga',
	STATE_MANGOCHI: 'Mangochi',
	STATE_MCHINJI: 'Mchinji',
	STATE_MULANJE: 'Mulanje',
	STATE_MWANZA: 'Mwanza',
	STATE_MZIMBA: 'Mzimba',
	STATE_MZUZU_CITY: 'Mzuzu City',
	STATE_NKHATA_BAY: 'Nkhata Bay',
	STATE_NKHOTAKOTA: 'Nkhotakota',
	STATE_NSANJE: 'Nsanje',
	STATE_NTCHEU: 'Ntcheu',
	STATE_NTCHISI: 'Ntchisi',
	STATE_PHALOMBE: 'Phalombe',
	STATE_THYOLO: 'Thyolo',
	STATE_ZOMBA_MUNICIPALITY: 'Zomba Municipality',
	STATE_JOHOR: 'Johor',
	STATE_KEDAH: 'Kedah',
	STATE_KELANTAN: 'Kelantan',
	STATE_KUALA_LUMPUR: 'Kuala Lumpur',
	STATE_LABUAN: 'Labuan',
	STATE_MELAKA: 'Melaka',
	STATE_NEGERI_JOHOR: 'Negeri Johor',
	STATE_NEGERI_SEMBILAN: 'Negeri Sembilan',
	STATE_PAHANG: 'Pahang',
	STATE_PENANG: 'Penang',
	STATE_PERAK: 'Perak',
	STATE_PERLIS: 'Perlis',
	STATE_PULAU_PINANG: 'Pulau Pinang',
	STATE_SABAH: 'Sabah',
	STATE_SARAWAK: 'Sarawak',
	STATE_SELANGOR: 'Selangor',
	STATE_SEMBILAN: 'Sembilan',
	STATE_TERENGGANU: 'Terengganu',
	STATE_ALIF_ALIF: 'Alif Alif',
	STATE_ALIF_DHAAL: 'Alif Dhaal',
	STATE_BAA: 'Baa',
	STATE_DHAAL: 'Dhaal',
	STATE_FAAF: 'Faaf',
	STATE_GAAF_ALIF: 'Gaaf Alif',
	STATE_GAAF_DHAAL: 'Gaaf Dhaal',
	STATE_GHAVIYANI: 'Ghaviyani',
	STATE_HAA_ALIF: 'Haa Alif',
	STATE_HAA_DHAAL: 'Haa Dhaal',
	STATE_KAAF: 'Kaaf',
	STATE_LAAM: 'Laam',
	STATE_LHAVIYANI: 'Lhaviyani',
	STATE_MALE: 'Male',
	STATE_MIIM: 'Miim',
	STATE_NUUN: 'Nuun',
	STATE_RAA: 'Raa',
	STATE_SHAVIYANI: 'Shaviyani',
	STATE_SIIN: 'Siin',
	STATE_THAA: 'Thaa',
	STATE_VAAV: 'Vaav',
	STATE_BAMAKO: 'Bamako',
	STATE_GAO: 'Gao',
	STATE_KAYES: 'Kayes',
	STATE_KIDAL: 'Kidal',
	STATE_KOULIKORO: 'Koulikoro',
	STATE_MOPTI: 'Mopti',
	STATE_SEGOU: 'Segou',
	STATE_SIKASSO: 'Sikasso',
	STATE_TOMBOUCTOU: 'Tombouctou',
	STATE_GOZO_AND_COMINO: 'Gozo and Comino',
	STATE_INNER_HARBOUR: 'Inner Harbour',
	STATE_OUTER_HARBOUR: 'Outer Harbour',
	STATE_SOUTH_EASTERN: 'South Eastern',
	STATE_CASTLETOWN: 'Castletown',
	STATE_DOUGLAS: 'Douglas',
	STATE_LAXEY: 'Laxey',
	STATE_ONCHAN: 'Onchan',
	STATE_PEEL: 'Peel',
	STATE_PORT_ERIN: 'Port Erin',
	STATE_PORT_SAINT_MARY: 'Port Saint Mary',
	STATE_RAMSEY: 'Ramsey',
	STATE_AILINLAPLAP: 'Ailinlaplap',
	STATE_AILUK: 'Ailuk',
	STATE_ARNO: 'Arno',
	STATE_AUR: 'Aur',
	STATE_BIKINI: 'Bikini',
	STATE_EBON: 'Ebon',
	STATE_ENEWETAK: 'Enewetak',
	STATE_JABAT: 'Jabat',
	STATE_JALUIT: 'Jaluit',
	STATE_KILI: 'Kili',
	STATE_KWAJALEIN: 'Kwajalein',
	STATE_LAE: 'Lae',
	STATE_LIB: 'Lib',
	STATE_LIKIEP: 'Likiep',
	STATE_MAJURO: 'Majuro',
	STATE_MALOELAP: 'Maloelap',
	STATE_MEJIT: 'Mejit',
	STATE_MILI: 'Mili',
	STATE_NAMORIK: 'Namorik',
	STATE_NAMU: 'Namu',
	STATE_RONGELAP: 'Rongelap',
	STATE_UJAE: 'Ujae',
	STATE_UTRIK: 'Utrik',
	STATE_WOTHO: 'Wotho',
	STATE_WOTJE: 'Wotje',
	STATE_FORT_DE_FRANCE: 'Fort-de-France',
	STATE_LA_TRINITE: 'La Trinite',
	STATE_LE_MARIN: 'Le Marin',
	STATE_SAINT_PIERRE: 'Saint-Pierre',
	STATE_ASSABA: 'Assaba',
	STATE_BRAKNA: 'Brakna',
	STATE_DHAKHLAT_NAWADIBU: 'Dhakhlat Nawadibu',
	STATE_HUDH_AL_GHARBI: 'Hudh-al-Gharbi',
	STATE_HUDH_ASH_SHARQI: 'Hudh-ash-Sharqi',
	STATE_INSHIRI: 'Inshiri',
	STATE_NAWAKSHUT: 'Nawakshut',
	STATE_QIDIMAGHA: 'Qidimagha',
	STATE_QURQUL: 'Qurqul',
	STATE_TAQANT: 'Taqant',
	STATE_TIRIS_ZAMMUR: 'Tiris Zammur',
	STATE_TRARZA: 'Trarza',
	STATE_BLACK_RIVER: 'Black River',
	STATE_EAU_COULEE: 'Eau Coulee',
	STATE_FLACQ: 'Flacq',
	STATE_FLOREAL: 'Floreal',
	STATE_GRAND_PORT: 'Grand Port',
	STATE_MOKA: 'Moka',
	STATE_PAMPLEMPOUSSES: 'Pamplempousses',
	STATE_PLAINES_WILHELM: 'Plaines Wilhelm',
	STATE_PORT_LOUIS: 'Port Louis',
	STATE_RIVIERE_DU_REMPART: 'Riviere du Rempart',
	STATE_RODRIGUES: 'Rodrigues',
	STATE_ROSE_HILL: 'Rose Hill',
	STATE_SAVANNE: 'Savanne',
	STATE_MAYOTTE: 'Mayotte',
	STATE_PAMANZI: 'Pamanzi',
	STATE_AGUASCALIENTES: 'Aguascalientes',
	STATE_BAJA_CALIFORNIA: 'Baja California',
	STATE_BAJA_CALIFORNIA_SUR: 'Baja California Sur',
	STATE_CAMPECHE: 'Campeche',
	STATE_CHIAPAS: 'Chiapas',
	STATE_CHIHUAHUA: 'Chihuahua',
	STATE_COAHUILA: 'Coahuila',
	STATE_COLIMA: 'Colima',
	STATE_DURANGO: 'Durango',
	STATE_ESTADO_DE_MEXICO: 'Estado de Mexico',
	STATE_GUANAJUATO: 'Guanajuato',
	STATE_GUERRERO: 'Guerrero',
	STATE_HIDALGO: 'Hidalgo',
	STATE_JALISCO: 'Jalisco',
	STATE_MEXICO: 'Mexico',
	STATE_MICHOACAN: 'Michoacan',
	STATE_MORELOS: 'Morelos',
	STATE_NAYARIT: 'Nayarit',
	STATE_NUEVO_LEON: 'Nuevo Leon',
	STATE_OAXACA: 'Oaxaca',
	STATE_PUEBLA: 'Puebla',
	STATE_QUERETARO: 'Queretaro',
	STATE_QUINTANA_ROO: 'Quintana Roo',
	STATE_SAN_LUIS_POTOSI: 'San Luis Potosi',
	STATE_SINALOA: 'Sinaloa',
	STATE_SONORA: 'Sonora',
	STATE_TABASCO: 'Tabasco',
	STATE_TAMAULIPAS: 'Tamaulipas',
	STATE_TLAXCALA: 'Tlaxcala',
	STATE_VERACRUZ: 'Veracruz',
	STATE_YUCATAN: 'Yucatan',
	STATE_ZACATECAS: 'Zacatecas',
	STATE_CHUUK: 'Chuuk',
	STATE_KUSAIE: 'Kusaie',
	STATE_POHNPEI: 'Pohnpei',
	STATE_YAP: 'Yap',
	STATE_BALTI: 'Balti',
	STATE_CAHUL: 'Cahul',
	STATE_CHISINAU: 'Chisinau',
	STATE_CHISINAU_ORAS: 'Chisinau Oras',
	STATE_EDINET: 'Edinet',
	STATE_GAGAUZIA: 'Gagauzia',
	STATE_LAPUSNA: 'Lapusna',
	STATE_ORHEI: 'Orhei',
	STATE_SOROCA: 'Soroca',
	STATE_TARACLIA: 'Taraclia',
	STATE_TIGHINA: 'Tighina',
	STATE_TRANSNISTRIA: 'Transnistria',
	STATE_UNGHENI: 'Ungheni',
	STATE_FONTVIEILLE: 'Fontvieille',
	STATE_LA_CONDAMINE: 'La Condamine',
	STATE_MONACO_VILLE: 'Monaco-Ville',
	STATE_MONTE_CARLO: 'Monte Carlo',
	STATE_ARHANGAJ: 'Arhangaj',
	STATE_BAJAN_OLGIJ: 'Bajan-Olgij',
	STATE_BAJANHONGOR: 'Bajanhongor',
	STATE_BULGAN: 'Bulgan',
	STATE_DARHAN_UUL: 'Darhan-Uul',
	STATE_DORNOD: 'Dornod',
	STATE_DORNOGOVI: 'Dornogovi',
	STATE_DUNDGOVI: 'Dundgovi',
	STATE_GOVI_ALTAJ: 'Govi-Altaj',
	STATE_GOVISUMBER: 'Govisumber',
	STATE_HENTIJ: 'Hentij',
	STATE_HOVD: 'Hovd',
	STATE_HOVSGOL: 'Hovsgol',
	STATE_OMNOGOVI: 'Omnogovi',
	STATE_ORHON: 'Orhon',
	STATE_OVORHANGAJ: 'Ovorhangaj',
	STATE_SELENGE: 'Selenge',
	STATE_SUHBAATAR: 'Suhbaatar',
	STATE_TOV: 'Tov',
	STATE_ULAANBAATAR: 'Ulaanbaatar',
	STATE_UVS: 'Uvs',
	STATE_ZAVHAN: 'Zavhan',
	STATE_MONTSERRAT: 'Montserrat',
	STATE_AGADIR: 'Agadir',
	STATE_CASABLANCA: 'Casablanca',
	STATE_CHAOUIA_OUARDIGHA: 'Chaouia-Ouardigha',
	STATE_DOUKKALA_ABDA: 'Doukkala-Abda',
	STATE_FES_BOULEMANE: 'Fes-Boulemane',
	STATE_GHARB_CHRARDA_BENI_HSSEN: 'Gharb-Chrarda-Beni Hssen',
	STATE_GUELMIM: 'Guelmim',
	STATE_KENITRA: 'Kenitra',
	STATE_MARRAKECH_TENSIFT_AL_HAOUZ: 'Marrakech-Tensift-Al Haouz',
	STATE_MEKNES_TAFILALET: 'Meknes-Tafilalet',
	STATE_ORIENTAL: 'Oriental',
	STATE_OUJDA: 'Oujda',
	STATE_PROVINCE_DE_TANGER: 'Province de Tanger',
	STATE_RABAT_SALE_ZAMMOUR_ZAER: 'Rabat-Sale-Zammour-Zaer',
	STATE_SALA_AL_JADIDA: 'Sala Al Jadida',
	STATE_SETTAT: 'Settat',
	STATE_SOUSS_MASSA_DRAA: 'Souss Massa-Draa',
	STATE_TADLA_AZILAL: 'Tadla-Azilal',
	STATE_TANGIER_TETOUAN: 'Tangier-Tetouan',
	STATE_TAZA_AL_HOCEIMA_TAOUNATE: 'Taza-Al Hoceima-Taounate',
	STATE_WILAYA_DE_CASABLANCA: 'Wilaya de Casablanca',
	STATE_WILAYA_DE_RABAT_SALE: 'Wilaya de Rabat-Sale',
	STATE_CABO_DELGADO: 'Cabo Delgado',
	STATE_INHAMBANE: 'Inhambane',
	STATE_MANICA: 'Manica',
	STATE_MAPUTO: 'Maputo',
	STATE_MAPUTO_PROVINCIA: 'Maputo Provincia',
	STATE_NAMPULA: 'Nampula',
	STATE_NIASSA: 'Niassa',
	STATE_SOFALA: 'Sofala',
	STATE_TETE: 'Tete',
	STATE_ZAMBEZIA: 'Zambezia',
	STATE_AYEYARWADY: 'Ayeyarwady',
	STATE_BAGO: 'Bago',
	STATE_CHIN: 'Chin',
	STATE_KACHIN: 'Kachin',
	STATE_KAYAH: 'Kayah',
	STATE_KAYIN: 'Kayin',
	STATE_MAGWAY: 'Magway',
	STATE_MANDALAY: 'Mandalay',
	STATE_MON: 'Mon',
	STATE_NAY_PYI_TAW: 'Nay Pyi Taw',
	STATE_RAKHINE: 'Rakhine',
	STATE_SAGAING: 'Sagaing',
	STATE_SHAN: 'Shan',
	STATE_TANINTHARYI: 'Tanintharyi',
	STATE_YANGON: 'Yangon',
	STATE_CAPRIVI: 'Caprivi',
	STATE_ERONGO: 'Erongo',
	STATE_HARDAP: 'Hardap',
	STATE_KARAS: 'Karas',
	STATE_KAVANGO: 'Kavango',
	STATE_KHOMAS: 'Khomas',
	STATE_KUNENE: 'Kunene',
	STATE_OHANGWENA: 'Ohangwena',
	STATE_OMAHEKE: 'Omaheke',
	STATE_OMUSATI: 'Omusati',
	STATE_OSHANA: 'Oshana',
	STATE_OSHIKOTO: 'Oshikoto',
	STATE_OTJOZONDJUPA: 'Otjozondjupa',
	STATE_YAREN: 'Yaren',
	STATE_BAGMATI: 'Bagmati',
	STATE_BHERI: 'Bheri',
	STATE_DHAWALAGIRI: 'Dhawalagiri',
	STATE_GANDAKI: 'Gandaki',
	STATE_JANAKPUR: 'Janakpur',
	STATE_KARNALI: 'Karnali',
	STATE_KOSHI: 'Koshi',
	STATE_LUMBINI: 'Lumbini',
	STATE_MAHAKALI: 'Mahakali',
	STATE_MECHI: 'Mechi',
	STATE_NARAYANI: 'Narayani',
	STATE_RAPTI: 'Rapti',
	STATE_SAGARMATHA: 'Sagarmatha',
	STATE_SETI: 'Seti',
	STATE_BONAIRE: 'Bonaire',
	STATE_CURACAO: 'Curacao',
	STATE_SABA: 'Saba',
	STATE_SINT_EUSTATIUS: 'Sint Eustatius',
	STATE_SINT_MAARTEN: 'Sint Maarten',
	STATE_BENELUX: 'Benelux',
	STATE_DRENTHE: 'Drenthe',
	STATE_FLEVOLAND: 'Flevoland',
	STATE_FRIESLAND: 'Friesland',
	STATE_GELDERLAND: 'Gelderland',
	STATE_GRONINGEN: 'Groningen',
	STATE_NOORD_BRABANT: 'Noord-Brabant',
	STATE_OVERIJSSEL: 'Overijssel',
	STATE_SOUTH_HOLLAND: 'South Holland',
	STATE_UTRECHT: 'Utrecht',
	STATE_ZEELAND: 'Zeeland',
	STATE_ZUID_HOLLAND: 'Zuid-Holland',
	STATE_ILES: 'Iles',
	STATE_AREA_OUTSIDE_REGION: 'Area Outside Region',
	STATE_BAY_OF_PLENTY: 'Bay of Plenty',
	STATE_CANTERBURY: 'Canterbury',
	STATE_CHRISTCHURCH: 'Christchurch',
	STATE_GISBORNE: 'Gisborne',
	STATE_HAWKE__S_BAY: "Hawke's Bay",
	STATE_MANAWATU_WANGANUI: 'Manawatu-Wanganui',
	STATE_MARLBOROUGH: 'Marlborough',
	STATE_NELSON: 'Nelson',
	STATE_NORTHLAND: 'Northland',
	STATE_OTAGO: 'Otago',
	STATE_RODNEY: 'Rodney',
	STATE_SOUTHLAND: 'Southland',
	STATE_TARANAKI: 'Taranaki',
	STATE_TASMAN: 'Tasman',
	STATE_WAIKATO: 'Waikato',
	STATE_WELLINGTON: 'Wellington',
	STATE_WEST_COAST: 'West Coast',
	STATE_ATLANTICO_NORTE: 'Atlantico Norte',
	STATE_ATLANTICO_SUR: 'Atlantico Sur',
	STATE_BOACO: 'Boaco',
	STATE_CARAZO: 'Carazo',
	STATE_CHINANDEGA: 'Chinandega',
	STATE_CHONTALES: 'Chontales',
	STATE_ESTELI: 'Esteli',
	STATE_GRANADA: 'Granada',
	STATE_JINOTEGA: 'Jinotega',
	STATE_LEON: 'Leon',
	STATE_MADRIZ: 'Madriz',
	STATE_MANAGUA: 'Managua',
	STATE_MASAYA: 'Masaya',
	STATE_MATAGALPA: 'Matagalpa',
	STATE_NUEVA_SEGOVIA: 'Nueva Segovia',
	STATE_RIO_SAN_JUAN: 'Rio San Juan',
	STATE_RIVAS: 'Rivas',
	STATE_AGADEZ: 'Agadez',
	STATE_DIFFA: 'Diffa',
	STATE_DOSSO: 'Dosso',
	STATE_MARADI: 'Maradi',
	STATE_NIAMEY: 'Niamey',
	STATE_TAHOUA: 'Tahoua',
	STATE_TILLABERY: 'Tillabery',
	STATE_ZINDER: 'Zinder',
	STATE_ABIA: 'Abia',
	STATE_ABUJA_FEDERAL_CAPITAL_TERRITOR: 'Abuja Federal Capital Territor',
	STATE_ADAMAWA: 'Adamawa',
	STATE_AKWA_IBOM: 'Akwa Ibom',
	STATE_ANAMBRA: 'Anambra',
	STATE_BAUCHI: 'Bauchi',
	STATE_BAYELSA: 'Bayelsa',
	STATE_BENUE: 'Benue',
	STATE_BORNO: 'Borno',
	STATE_CROSS_RIVER: 'Cross River',
	STATE_DELTA: 'Delta',
	STATE_EBONYI: 'Ebonyi',
	STATE_EDO: 'Edo',
	STATE_EKITI: 'Ekiti',
	STATE_ENUGU: 'Enugu',
	STATE_GOMBE: 'Gombe',
	STATE_IMO: 'Imo',
	STATE_JIGAWA: 'Jigawa',
	STATE_KADUNA: 'Kaduna',
	STATE_KANO: 'Kano',
	STATE_KATSINA: 'Katsina',
	STATE_KEBBI: 'Kebbi',
	STATE_KOGI: 'Kogi',
	STATE_KWARA: 'Kwara',
	STATE_LAGOS: 'Lagos',
	STATE_NASSARAWA: 'Nassarawa',
	STATE_NIGER: 'Niger',
	STATE_OGUN: 'Ogun',
	STATE_ONDO: 'Ondo',
	STATE_OSUN: 'Osun',
	STATE_OYO: 'Oyo',
	STATE_RIVERS: 'Rivers',
	STATE_SOKOTO: 'Sokoto',
	STATE_TARABA: 'Taraba',
	STATE_YOBE: 'Yobe',
	STATE_ZAMFARA: 'Zamfara',
	STATE_NIUE: 'Niue',
	STATE_NORFOLK_ISLAND: 'Norfolk Island',
	STATE_NORTHERN_ISLANDS: 'Northern Islands',
	STATE_ROTA: 'Rota',
	STATE_SAIPAN: 'Saipan',
	STATE_TINIAN: 'Tinian',
	STATE_AKERSHUS: 'Akershus',
	STATE_AUST_AGDER: 'Aust Agder',
	STATE_BERGEN: 'Bergen',
	STATE_BUSKERUD: 'Buskerud',
	STATE_FINNMARK: 'Finnmark',
	STATE_HEDMARK: 'Hedmark',
	STATE_HORDALAND: 'Hordaland',
	STATE_MOERE_OG_ROMSDAL: 'Moere og Romsdal',
	STATE_NORD_TRONDELAG: 'Nord Trondelag',
	STATE_NORDLAND: 'Nordland',
	STATE_OESTFOLD: 'Oestfold',
	STATE_OPPLAND: 'Oppland',
	STATE_OSLO: 'Oslo',
	STATE_ROGALAND: 'Rogaland',
	STATE_SOER_TROENDELAG: 'Soer Troendelag',
	STATE_SOGN_OG_FJORDANE: 'Sogn og Fjordane',
	STATE_STAVERN: 'Stavern',
	STATE_SYKKYLVEN: 'Sykkylven',
	STATE_TELEMARK: 'Telemark',
	STATE_TROMS: 'Troms',
	STATE_VEST_AGDER: 'Vest Agder',
	STATE_VESTFOLD: 'Vestfold',
	STATE_AL_BURAIMI: 'Al Buraimi',
	STATE_DHUFAR: 'Dhufar',
	STATE_MASQAT: 'Masqat',
	STATE_MUSANDAM: 'Musandam',
	STATE_RUSAYL: 'Rusayl',
	STATE_WADI_KABIR: 'Wadi Kabir',
	STATE_AD_DAKHILIYAH: 'ad-Dakhiliyah',
	STATE_ADH_DHAHIRAH: 'adh-Dhahirah',
	STATE_AL_BATINAH: 'al-Batinah',
	STATE_BALUCHISTAN: 'Baluchistan',
	STATE_FEDERAL_CAPITAL_AREA: 'Federal Capital Area',
	STATE_FEDERALLY_ADMINISTERED_TRIBAL: 'Federally administered Tribal',
	STATE_NORTH_WEST_FRONTIER: 'North-West Frontier',
	STATE_NORTHERN_AREAS: 'Northern Areas',
	STATE_SIND: 'Sind',
	STATE_AIMELIIK: 'Aimeliik',
	STATE_AIRAI: 'Airai',
	STATE_ANGAUR: 'Angaur',
	STATE_HATOBOHEI: 'Hatobohei',
	STATE_KAYANGEL: 'Kayangel',
	STATE_KOROR: 'Koror',
	STATE_MELEKEOK: 'Melekeok',
	STATE_NGARAARD: 'Ngaraard',
	STATE_NGARDMAU: 'Ngardmau',
	STATE_NGAREMLENGUI: 'Ngaremlengui',
	STATE_NGATPANG: 'Ngatpang',
	STATE_NGCHESAR: 'Ngchesar',
	STATE_NGERCHELONG: 'Ngerchelong',
	STATE_NGIWAL: 'Ngiwal',
	STATE_PELELIU: 'Peleliu',
	STATE_SONSOROL: 'Sonsorol',
	STATE_ARIHA: 'Ariha',
	STATE_BAYT_LAHM: 'Bayt Lahm',
	STATE_DAYR_AL_BALAH: 'Dayr-al-Balah',
	STATE_GHAZZAH: 'Ghazzah',
	STATE_GHAZZAH_ASH_SHAMALIYAH: 'Ghazzah ash-Shamaliyah',
	STATE_JANIN: 'Janin',
	STATE_KHAN_YUNIS: 'Khan Yunis',
	STATE_NABULUS: 'Nabulus',
	STATE_QALQILYAH: 'Qalqilyah',
	STATE_RAFAH: 'Rafah',
	STATE_RAM_ALLAH_WAL_BIRAH: 'Ram Allah wal-Birah',
	STATE_SALFIT: 'Salfit',
	STATE_TUBAS: 'Tubas',
	STATE_TULKARM: 'Tulkarm',
	STATE_AL_KHALIL: 'al-Khalil',
	STATE_AL_QUDS: 'al-Quds',
	STATE_BOCAS_DEL_TORO: 'Bocas del Toro',
	STATE_CHIRIQUI: 'Chiriqui',
	STATE_COCLE: 'Cocle',
	STATE_DARIEN: 'Darien',
	STATE_EMBERA: 'Embera',
	STATE_HERRERA: 'Herrera',
	STATE_KUNA_YALA: 'Kuna Yala',
	STATE_LOS_SANTOS: 'Los Santos',
	STATE_NGOBE_BUGLE: 'Ngobe Bugle',
	STATE_PANAMA: 'Panama',
	STATE_VERAGUAS: 'Veraguas',
	STATE_EAST_NEW_BRITAIN: 'East New Britain',
	STATE_EAST_SEPIK: 'East Sepik',
	STATE_EASTERN_HIGHLANDS: 'Eastern Highlands',
	STATE_ENGA: 'Enga',
	STATE_FLY_RIVER: 'Fly River',
	STATE_GULF: 'Gulf',
	STATE_MADANG: 'Madang',
	STATE_MANUS: 'Manus',
	STATE_MILNE_BAY: 'Milne Bay',
	STATE_MOROBE: 'Morobe',
	STATE_NATIONAL_CAPITAL_DISTRICT: 'National Capital District',
	STATE_NEW_IRELAND: 'New Ireland',
	STATE_NORTH_SOLOMONS: 'North Solomons',
	STATE_ORO: 'Oro',
	STATE_SANDAUN: 'Sandaun',
	STATE_SIMBU: 'Simbu',
	STATE_SOUTHERN_HIGHLANDS: 'Southern Highlands',
	STATE_WEST_NEW_BRITAIN: 'West New Britain',
	STATE_WESTERN_HIGHLANDS: 'Western Highlands',
	STATE_ALTO_PARAGUAY: 'Alto Paraguay',
	STATE_ALTO_PARANA: 'Alto Parana',
	STATE_AMAMBAY: 'Amambay',
	STATE_ASUNCION: 'Asuncion',
	STATE_BOQUERON: 'Boqueron',
	STATE_CAAGUAZU: 'Caaguazu',
	STATE_CAAZAPA: 'Caazapa',
	STATE_CANENDIYU: 'Canendiyu',
	STATE_CONCEPCION: 'Concepcion',
	STATE_CORDILLERA: 'Cordillera',
	STATE_GUAIRA: 'Guaira',
	STATE_ITAPUA: 'Itapua',
	STATE_NEEMBUCU: 'Neembucu',
	STATE_PARAGUARI: 'Paraguari',
	STATE_PRESIDENTE_HAYES: 'Presidente Hayes',
	STATE_SAN_PEDRO: 'San Pedro',
	STATE_ANCASH: 'Ancash',
	STATE_APURIMAC: 'Apurimac',
	STATE_AREQUIPA: 'Arequipa',
	STATE_AYACUCHO: 'Ayacucho',
	STATE_CAJAMARCA: 'Cajamarca',
	STATE_CUSCO: 'Cusco',
	STATE_HUANCAVELICA: 'Huancavelica',
	STATE_HUANUCO: 'Huanuco',
	STATE_ICA: 'Ica',
	STATE_JUNIN: 'Junin',
	STATE_LAMBAYEQUE: 'Lambayeque',
	STATE_LIMA_Y_CALLAO: 'Lima y Callao',
	STATE_LORETO: 'Loreto',
	STATE_MADRE_DE_DIOS: 'Madre de Dios',
	STATE_MOQUEGUA: 'Moquegua',
	STATE_PASCO: 'Pasco',
	STATE_PIURA: 'Piura',
	STATE_PUNO: 'Puno',
	STATE_SAN_MARTIN: 'San Martin',
	STATE_TACNA: 'Tacna',
	STATE_TUMBES: 'Tumbes',
	STATE_UCAYALI: 'Ucayali',
	STATE_BATANGAS: 'Batangas',
	STATE_BICOL: 'Bicol',
	STATE_BULACAN: 'Bulacan',
	STATE_CAGAYAN: 'Cagayan',
	STATE_CARAGA: 'Caraga',
	STATE_CENTRAL_LUZON: 'Central Luzon',
	STATE_CENTRAL_MINDANAO: 'Central Mindanao',
	STATE_CENTRAL_VISAYAS: 'Central Visayas',
	STATE_DAVAO: 'Davao',
	STATE_EASTERN_VISAYAS: 'Eastern Visayas',
	STATE_GREATER_METROPOLITAN_AREA: 'Greater Metropolitan Area',
	STATE_ILOCOS: 'Ilocos',
	STATE_LAGUNA: 'Laguna',
	STATE_LUZON: 'Luzon',
	STATE_MACTAN: 'Mactan',
	STATE_METROPOLITAN_MANILA_AREA: 'Metropolitan Manila Area',
	STATE_MUSLIM_MINDANAO: 'Muslim Mindanao',
	STATE_NORTHERN_MINDANAO: 'Northern Mindanao',
	STATE_SOUTHERN_MINDANAO: 'Southern Mindanao',
	STATE_SOUTHERN_TAGALOG: 'Southern Tagalog',
	STATE_WESTERN_MINDANAO: 'Western Mindanao',
	STATE_WESTERN_VISAYAS: 'Western Visayas',
	STATE_PITCAIRN_ISLAND: 'Pitcairn Island',
	STATE_BIALE_BLOTA: 'Biale Blota',
	STATE_DOBROSZYCE: 'Dobroszyce',
	STATE_DOLNOSLASKIE: 'Dolnoslaskie',
	STATE_DZIEKANOW_LESNY: 'Dziekanow Lesny',
	STATE_HOPOWO: 'Hopowo',
	STATE_KARTUZY: 'Kartuzy',
	STATE_KOSCIAN: 'Koscian',
	STATE_KRAKOW: 'Krakow',
	STATE_KUJAWSKO_POMORSKIE: 'Kujawsko-Pomorskie',
	STATE_LODZKIE: 'Lodzkie',
	STATE_LUBELSKIE: 'Lubelskie',
	STATE_LUBUSKIE: 'Lubuskie',
	STATE_MALOMICE: 'Malomice',
	STATE_MALOPOLSKIE: 'Malopolskie',
	STATE_MAZOWIECKIE: 'Mazowieckie',
	STATE_MIRKOW: 'Mirkow',
	STATE_OPOLSKIE: 'Opolskie',
	STATE_OSTROWIEC: 'Ostrowiec',
	STATE_PODKARPACKIE: 'Podkarpackie',
	STATE_PODLASKIE: 'Podlaskie',
	STATE_POLSKA: 'Polska',
	STATE_POMORSKIE: 'Pomorskie',
	STATE_POZNAN: 'Poznan',
	STATE_PRUSZKOW: 'Pruszkow',
	STATE_RYMANOWSKA: 'Rymanowska',
	STATE_RZESZOW: 'Rzeszow',
	STATE_SLASKIE: 'Slaskie',
	STATE_STARE_POLE: 'Stare Pole',
	STATE_SWIETOKRZYSKIE: 'Swietokrzyskie',
	STATE_WARMINSKO_MAZURSKIE: 'Warminsko-Mazurskie',
	STATE_WARSAW: 'Warsaw',
	STATE_WEJHEROWO: 'Wejherowo',
	STATE_WIELKOPOLSKIE: 'Wielkopolskie',
	STATE_WROCLAW: 'Wroclaw',
	STATE_ZACHODNIO_POMORSKIE: 'Zachodnio-Pomorskie',
	STATE_ZUKOWO: 'Zukowo',
	STATE_ABRANTES: 'Abrantes',
	STATE_ACORES: 'Acores',
	STATE_ALENTEJO: 'Alentejo',
	STATE_ALGARVE: 'Algarve',
	STATE_BRAGA: 'Braga',
	STATE_CENTRO: 'Centro',
	STATE_DISTRITO_DE_LEIRIA: 'Distrito de Leiria',
	STATE_DISTRITO_DE_VIANA_DO_CASTELO: 'Distrito de Viana do Castelo',
	STATE_DISTRITO_DE_VILA_REAL: 'Distrito de Vila Real',
	STATE_DISTRITO_DO_PORTO: 'Distrito do Porto',
	STATE_LISBOA_E_VALE_DO_TEJO: 'Lisboa e Vale do Tejo',
	STATE_MADEIRA: 'Madeira',
	STATE_NORTE: 'Norte',
	STATE_PAIVAS: 'Paivas',
	STATE_ARECIBO: 'Arecibo',
	STATE_BAYAMON: 'Bayamon',
	STATE_CAROLINA: 'Carolina',
	STATE_FLORIDA: 'Florida',
	STATE_GUAYAMA: 'Guayama',
	STATE_HUMACAO: 'Humacao',
	STATE_MAYAGUEZ_AGUADILLA: 'Mayaguez-Aguadilla',
	STATE_PONCE: 'Ponce',
	STATE_SALINAS: 'Salinas',
	STATE_DOHA: 'Doha',
	STATE_JARIAN_AL_BATNAH: 'Jarian-al-Batnah',
	STATE_UMM_SALAL: 'Umm Salal',
	STATE_AD_DAWHAH: 'ad-Dawhah',
	STATE_AL_GHUWAYRIYAH: 'al-Ghuwayriyah',
	STATE_AL_JUMAYLIYAH: 'al-Jumayliyah',
	STATE_AL_KHAWR: 'al-Khawr',
	STATE_AL_WAKRAH: 'al-Wakrah',
	STATE_AR_RAYYAN: 'ar-Rayyan',
	STATE_SAINT_BENOIT: 'Saint-Benoit',
	STATE_SAINT_DENIS: 'Saint-Denis',
	STATE_ALBA: 'Alba',
	STATE_ARAD: 'Arad',
	STATE_ARGES: 'Arges',
	STATE_BACAU: 'Bacau',
	STATE_BIHOR: 'Bihor',
	STATE_BISTRITA_NASAUD: 'Bistrita-Nasaud',
	STATE_BOTOSANI: 'Botosani',
	STATE_BRAILA: 'Braila',
	STATE_BRASOV: 'Brasov',
	STATE_BUCURESTI: 'Bucuresti',
	STATE_BUZAU: 'Buzau',
	STATE_CALARASI: 'Calarasi',
	STATE_CARAS_SEVERIN: 'Caras-Severin',
	STATE_CLUJ: 'Cluj',
	STATE_CONSTANTA: 'Constanta',
	STATE_COVASNA: 'Covasna',
	STATE_DAMBOVITA: 'Dambovita',
	STATE_DOLJ: 'Dolj',
	STATE_GALATI: 'Galati',
	STATE_GIURGIU: 'Giurgiu',
	STATE_GORJ: 'Gorj',
	STATE_HARGHITA: 'Harghita',
	STATE_HUNEDOARA: 'Hunedoara',
	STATE_IALOMITA: 'Ialomita',
	STATE_IASI: 'Iasi',
	STATE_ILFOV: 'Ilfov',
	STATE_MARAMURES: 'Maramures',
	STATE_MEHEDINTI: 'Mehedinti',
	STATE_MURES: 'Mures',
	STATE_NEAMT: 'Neamt',
	STATE_OLT: 'Olt',
	STATE_PRAHOVA: 'Prahova',
	STATE_SALAJ: 'Salaj',
	STATE_SATU_MARE: 'Satu Mare',
	STATE_SIBIU: 'Sibiu',
	STATE_SONDELOR: 'Sondelor',
	STATE_SUCEAVA: 'Suceava',
	STATE_TELEORMAN: 'Teleorman',
	STATE_TIMIS: 'Timis',
	STATE_TULCEA: 'Tulcea',
	STATE_VALCEA: 'Valcea',
	STATE_VASLUI: 'Vaslui',
	STATE_VRANCEA: 'Vrancea',
	STATE_ADYGEJA: 'Adygeja',
	STATE_AGA: 'Aga',
	STATE_ALANIJA: 'Alanija',
	STATE_ALTAJ: 'Altaj',
	STATE_AMUR: 'Amur',
	STATE_ARHANGELSK: 'Arhangelsk',
	STATE_ASTRAHAN: 'Astrahan',
	STATE_BASHKORTOSTAN: 'Bashkortostan',
	STATE_BELGOROD: 'Belgorod',
	STATE_BRJANSK: 'Brjansk',
	STATE_BURJATIJA: 'Burjatija',
	STATE_CHECHENIJA: 'Chechenija',
	STATE_CHELJABINSK: 'Cheljabinsk',
	STATE_CHITA: 'Chita',
	STATE_CHUKOTKA: 'Chukotka',
	STATE_CHUVASHIJA: 'Chuvashija',
	STATE_DAGESTAN: 'Dagestan',
	STATE_EVENKIJA: 'Evenkija',
	STATE_GORNO_ALTAJ: 'Gorno-Altaj',
	STATE_HABAROVSK: 'Habarovsk',
	STATE_HAKASIJA: 'Hakasija',
	STATE_HANTY_MANSIJA: 'Hanty-Mansija',
	STATE_INGUSETIJA: 'Ingusetija',
	STATE_IRKUTSK: 'Irkutsk',
	STATE_IVANOVO: 'Ivanovo',
	STATE_JAMALO_NENETS: 'Jamalo-Nenets',
	STATE_JAROSLAVL: 'Jaroslavl',
	STATE_JEVREJ: 'Jevrej',
	STATE_KABARDINO_BALKARIJA: 'Kabardino-Balkarija',
	STATE_KALININGRAD: 'Kaliningrad',
	STATE_KALMYKIJA: 'Kalmykija',
	STATE_KALUGA: 'Kaluga',
	STATE_KAMCHATKA: 'Kamchatka',
	STATE_KARACHAJ_CHERKESSIJA: 'Karachaj-Cherkessija',
	STATE_KARELIJA: 'Karelija',
	STATE_KEMEROVO: 'Kemerovo',
	STATE_KHABAROVSKIY_KRAY: 'Khabarovskiy Kray',
	STATE_KIROV: 'Kirov',
	STATE_KOMI: 'Komi',
	STATE_KOMI_PERMJAKIJA: 'Komi-Permjakija',
	STATE_KORJAKIJA: 'Korjakija',
	STATE_KOSTROMA: 'Kostroma',
	STATE_KRASNODAR: 'Krasnodar',
	STATE_KRASNOJARSK: 'Krasnojarsk',
	STATE_KRASNOYARSKIY_KRAY: 'Krasnoyarskiy Kray',
	STATE_KURGAN: 'Kurgan',
	STATE_KURSK: 'Kursk',
	STATE_LENINGRAD: 'Leningrad',
	STATE_LIPECK: 'Lipeck',
	STATE_MAGADAN: 'Magadan',
	STATE_MARIJ_EL: 'Marij El',
	STATE_MORDOVIJA: 'Mordovija',
	STATE_MOSCOW: 'Moscow',
	STATE_MOSKOVSKAJA_OBLAST: 'Moskovskaja Oblast',
	STATE_MOSKOVSKAYA_OBLAST: 'Moskovskaya Oblast',
	STATE_MOSKVA: 'Moskva',
	STATE_MURMANSK: 'Murmansk',
	STATE_NENETS: 'Nenets',
	STATE_NIZHNIJ_NOVGOROD: 'Nizhnij Novgorod',
	STATE_NOVGOROD: 'Novgorod',
	STATE_NOVOKUSNEZK: 'Novokusnezk',
	STATE_NOVOSIBIRSK: 'Novosibirsk',
	STATE_OMSK: 'Omsk',
	STATE_ORENBURG: 'Orenburg',
	STATE_ORJOL: 'Orjol',
	STATE_PENZA: 'Penza',
	STATE_PERM: 'Perm',
	STATE_PRIMORJE: 'Primorje',
	STATE_PSKOV: 'Pskov',
	STATE_PSKOVSKAYA_OBLAST: 'Pskovskaya Oblast',
	STATE_RJAZAN: 'Rjazan',
	STATE_ROSTOV: 'Rostov',
	STATE_SAHA: 'Saha',
	STATE_SAHALIN: 'Sahalin',
	STATE_SAMARA: 'Samara',
	STATE_SAMARSKAYA: 'Samarskaya',
	STATE_SANKT_PETERBURG: 'Sankt-Peterburg',
	STATE_SARATOV: 'Saratov',
	STATE_SMOLENSK: 'Smolensk',
	STATE_STAVROPOL: 'Stavropol',
	STATE_SVERDLOVSK: 'Sverdlovsk',
	STATE_TAJMYRIJA: 'Tajmyrija',
	STATE_TAMBOV: 'Tambov',
	STATE_TATARSTAN: 'Tatarstan',
	STATE_TJUMEN: 'Tjumen',
	STATE_TOMSK: 'Tomsk',
	STATE_TULA: 'Tula',
	STATE_TVER: 'Tver',
	STATE_TYVA: 'Tyva',
	STATE_UDMURTIJA: 'Udmurtija',
	STATE_ULJANOVSK: 'Uljanovsk',
	STATE_ULYANOVSKAYA_OBLAST: 'Ulyanovskaya Oblast',
	STATE_UST_ORDA: 'Ust-Orda',
	STATE_VLADIMIR: 'Vladimir',
	STATE_VOLGOGRAD: 'Volgograd',
	STATE_VOLOGDA: 'Vologda',
	STATE_VORONEZH: 'Voronezh',
	STATE_BUTARE: 'Butare',
	STATE_BYUMBA: 'Byumba',
	STATE_CYANGUGU: 'Cyangugu',
	STATE_GIKONGORO: 'Gikongoro',
	STATE_GISENYI: 'Gisenyi',
	STATE_GITARAMA: 'Gitarama',
	STATE_KIBUNGO: 'Kibungo',
	STATE_KIBUYE: 'Kibuye',
	STATE_KIGALI_NGALI: 'Kigali-ngali',
	STATE_RUHENGERI: 'Ruhengeri',
	STATE_ASCENSION: 'Ascension',
	STATE_GOUGH_ISLAND: 'Gough Island',
	STATE_SAINT_HELENA: 'Saint Helena',
	STATE_TRISTAN_DA_CUNHA: 'Tristan da Cunha',
	STATE_CHRIST_CHURCH_NICHOLA_TOWN: 'Christ Church Nichola Town',
	STATE_SAINT_ANNE_SANDY_POINT: 'Saint Anne Sandy Point',
	STATE_SAINT_GEORGE_BASSETERRE: 'Saint George Basseterre',
	STATE_SAINT_GEORGE_GINGERLAND: 'Saint George Gingerland',
	STATE_SAINT_JAMES_WINDWARD: 'Saint James Windward',
	STATE_SAINT_JOHN_CAPESTERRE: 'Saint John Capesterre',
	STATE_SAINT_JOHN_FIGTREE: 'Saint John Figtree',
	STATE_SAINT_MARY_CAYON: 'Saint Mary Cayon',
	STATE_SAINT_PAUL_CAPESTERRE: 'Saint Paul Capesterre',
	STATE_SAINT_PAUL_CHARLESTOWN: 'Saint Paul Charlestown',
	STATE_SAINT_PETER_BASSETERRE: 'Saint Peter Basseterre',
	STATE_SAINT_THOMAS_LOWLAND: 'Saint Thomas Lowland',
	STATE_SAINT_THOMAS_MIDDLE_ISLAND: 'Saint Thomas Middle Island',
	STATE_TRINITY_PALMETTO_POINT: 'Trinity Palmetto Point',
	STATE_ANSE_LA_RAYE: 'Anse-la-Raye',
	STATE_CANARIES: 'Canaries',
	STATE_CASTRIES: 'Castries',
	STATE_CHOISEUL: 'Choiseul',
	STATE_DENNERY: 'Dennery',
	STATE_GROS_INLET: 'Gros Inlet',
	STATE_LABORIE: 'Laborie',
	STATE_MICOUD: 'Micoud',
	STATE_SOUFRIERE: 'Soufriere',
	STATE_VIEUX_FORT: 'Vieux Fort',
	STATE_MIQUELON_LANGLADE: 'Miquelon-Langlade',
	STATE_CHARLOTTE: 'Charlotte',
	STATE_GRENADINES: 'Grenadines',
	STATE_A__ANA: "A'ana",
	STATE_AIGA_I_LE_TAI: 'Aiga-i-le-Tai',
	STATE_ATUA: 'Atua',
	STATE_FA__ASALELEAGA: "Fa'asaleleaga",
	STATE_GAGA__EMAUGA: "Gaga'emauga",
	STATE_GAGAIFOMAUGA: 'Gagaifomauga',
	STATE_PALAULI: 'Palauli',
	STATE_SATUPA__ITEA: "Satupa'itea",
	STATE_TUAMASAGA: 'Tuamasaga',
	STATE_VA__A_O_FONOTI: "Va'a-o-Fonoti",
	STATE_VAISIGANO: 'Vaisigano',
	STATE_ACQUAVIVA: 'Acquaviva',
	STATE_BORGO_MAGGIORE: 'Borgo Maggiore',
	STATE_CHIESANUOVA: 'Chiesanuova',
	STATE_DOMAGNANO: 'Domagnano',
	STATE_FAETANO: 'Faetano',
	STATE_FIORENTINO: 'Fiorentino',
	STATE_MONTEGIARDINO: 'Montegiardino',
	STATE_SAN_MARINO: 'San Marino',
	STATE_SERRAVALLE: 'Serravalle',
	STATE_AGUA_GRANDE: 'Agua Grande',
	STATE_CANTAGALO: 'Cantagalo',
	STATE_LEMBA: 'Lemba',
	STATE_LOBATA: 'Lobata',
	STATE_ME_ZOCHI: 'Me-Zochi',
	STATE_PAGUE: 'Pague',
	STATE_AL_KHOBAR: 'Al Khobar',
	STATE_ASEER: 'Aseer',
	STATE_ASIR: 'Asir',
	STATE_CENTRAL_PROVINCE: 'Central Province',
	STATE_EASTERN_PROVINCE: 'Eastern Province',
	STATE_HA__IL: "Ha'il",
	STATE_JAWF: 'Jawf',
	STATE_JIZAN: 'Jizan',
	STATE_MAKKAH: 'Makkah',
	STATE_NAJRAN: 'Najran',
	STATE_QASIM: 'Qasim',
	STATE_TABUK: 'Tabuk',
	STATE_WESTERN_PROVINCE: 'Western Province',
	STATE_AL_BAHAH: 'al-Bahah',
	STATE_AL_HUDUD_ASH_SHAMALIYAH: 'al-Hudud-ash-Shamaliyah',
	STATE_AL_MADINAH: 'al-Madinah',
	STATE_AR_RIYAD: 'ar-Riyad',
	STATE_DAKAR: 'Dakar',
	STATE_DIOURBEL: 'Diourbel',
	STATE_FATICK: 'Fatick',
	STATE_KAOLACK: 'Kaolack',
	STATE_KOLDA: 'Kolda',
	STATE_LOUGA: 'Louga',
	STATE_SAINT_LOUIS: 'Saint-Louis',
	STATE_TAMBACOUNDA: 'Tambacounda',
	STATE_THIES: 'Thies',
	STATE_ZIGUINCHOR: 'Ziguinchor',
	STATE_CENTRAL_SERBIA: 'Central Serbia',
	STATE_KOSOVO_AND_METOHIJA: 'Kosovo and Metohija',
	STATE_VOJVODINA: 'Vojvodina',
	STATE_ANSE_BOILEAU: 'Anse Boileau',
	STATE_ANSE_ROYALE: 'Anse Royale',
	STATE_CASCADE: 'Cascade',
	STATE_TAKAMAKA: 'Takamaka',
	STATE_SINGAPORE: 'Singapore',
	STATE_BANSKOBYSTRICKY: 'Banskobystricky',
	STATE_BRATISLAVSKY: 'Bratislavsky',
	STATE_KOSICKY: 'Kosicky',
	STATE_NITRIANSKY: 'Nitriansky',
	STATE_PRESOVSKY: 'Presovsky',
	STATE_TRENCIANSKY: 'Trenciansky',
	STATE_TRNAVSKY: 'Trnavsky',
	STATE_ZILINSKY: 'Zilinsky',
	STATE_BENEDIKT: 'Benedikt',
	STATE_GORENJSKA: 'Gorenjska',
	STATE_GORISHKA: 'Gorishka',
	STATE_JUGOVZHODNA_SLOVENIJA: 'Jugovzhodna Slovenija',
	STATE_KOROSHKA: 'Koroshka',
	STATE_NOTRANJSKO_KRASHKA: 'Notranjsko-krashka',
	STATE_OBALNO_KRASHKA: 'Obalno-krashka',
	STATE_OBCINA_DOMZALE: 'Obcina Domzale',
	STATE_OBCINA_VITANJE: 'Obcina Vitanje',
	STATE_OSREDNJESLOVENSKA: 'Osrednjeslovenska',
	STATE_PODRAVSKA: 'Podravska',
	STATE_POMURSKA: 'Pomurska',
	STATE_SAVINJSKA: 'Savinjska',
	STATE_SLOVENIAN_LITTORAL: 'Slovenian Littoral',
	STATE_SPODNJEPOSAVSKA: 'Spodnjeposavska',
	STATE_ZASAVSKA: 'Zasavska',
	STATE_GUADALCANAL: 'Guadalcanal',
	STATE_ISABEL: 'Isabel',
	STATE_MAKIRA_AND_ULAWA: 'Makira and Ulawa',
	STATE_MALAITA: 'Malaita',
	STATE_RENNELL_AND_BELLONA: 'Rennell and Bellona',
	STATE_TEMOTU: 'Temotu',
	STATE_AWDAL: 'Awdal',
	STATE_BAKOL: 'Bakol',
	STATE_BANADIR: 'Banadir',
	STATE_BAY: 'Bay',
	STATE_GALGUDUG: 'Galgudug',
	STATE_GEDO: 'Gedo',
	STATE_HIRAN: 'Hiran',
	STATE_JUBBADA_HOSE: 'Jubbada Hose',
	STATE_JUBBADHA_DEXE: 'Jubbadha Dexe',
	STATE_MUDUG: 'Mudug',
	STATE_NUGAL: 'Nugal',
	STATE_SANAG: 'Sanag',
	STATE_SHABELLAHA_DHEXE: 'Shabellaha Dhexe',
	STATE_SHABELLAHA_HOSE: 'Shabellaha Hose',
	STATE_TOGDHER: 'Togdher',
	STATE_WOQOYI_GALBED: 'Woqoyi Galbed',
	STATE_EASTERN_CAPE: 'Eastern Cape',
	STATE_FREE_STATE: 'Free State',
	STATE_GAUTENG: 'Gauteng',
	STATE_KEMPTON_PARK: 'Kempton Park',
	STATE_KRAMERVILLE: 'Kramerville',
	STATE_KWAZULU_NATAL: 'KwaZulu Natal',
	STATE_LIMPOPO: 'Limpopo',
	STATE_MPUMALANGA: 'Mpumalanga',
	STATE_NORTH_WEST: 'North West',
	STATE_NORTHERN_CAPE: 'Northern Cape',
	STATE_PAROW: 'Parow',
	STATE_TABLE_VIEW: 'Table View',
	STATE_UMTENTWENI: 'Umtentweni',
	STATE_WESTERN_CAPE: 'Western Cape',
	STATE_CENTRAL_EQUATORIA: 'Central Equatoria',
	STATE_A_CORUNA: 'A Coruna',
	STATE_ALACANT: 'Alacant',
	STATE_ALAVA: 'Alava',
	STATE_ALBACETE: 'Albacete',
	STATE_ALMERIA: 'Almeria',
	STATE_ANDALUCIA: 'Andalucia',
	STATE_ASTURIAS: 'Asturias',
	STATE_AVILA: 'Avila',
	STATE_BADAJOZ: 'Badajoz',
	STATE_BALEARS: 'Balears',
	STATE_BARCELONA: 'Barcelona',
	STATE_BERTAMIRANS: 'Bertamirans',
	STATE_BISCAY: 'Biscay',
	STATE_BURGOS: 'Burgos',
	STATE_CACERES: 'Caceres',
	STATE_CADIZ: 'Cadiz',
	STATE_CANTABRIA: 'Cantabria',
	STATE_CASTELLO: 'Castello',
	STATE_CATALUNYA: 'Catalunya',
	STATE_CEUTA: 'Ceuta',
	STATE_CIUDAD_REAL: 'Ciudad Real',
	STATE_COMUNIDAD_AUTONOMA_DE_CANARIAS: 'Comunidad Autonoma de Canarias',
	STATE_COMUNIDAD_AUTONOMA_DE_CATALUNA: 'Comunidad Autonoma de Cataluna',
	STATE_COMUNIDAD_AUTONOMA_DE_GALICIA: 'Comunidad Autonoma de Galicia',
	STATE_COMUNIDAD_AUTONOMA_DE_LAS_ISLA: 'Comunidad Autonoma de las Isla',
	STATE_COMUNIDAD_AUTONOMA_DEL_PRINCIP: 'Comunidad Autonoma del Princip',
	STATE_COMUNIDAD_VALENCIANA: 'Comunidad Valenciana',
	STATE_CUENCA: 'Cuenca',
	STATE_GIPUZKOA: 'Gipuzkoa',
	STATE_GIRONA: 'Girona',
	STATE_GUADALAJARA: 'Guadalajara',
	STATE_GUIPUZCOA: 'Guipuzcoa',
	STATE_HUELVA: 'Huelva',
	STATE_HUESCA: 'Huesca',
	STATE_JAEN: 'Jaen',
	STATE_LAS_PALMAS: 'Las Palmas',
	STATE_LERIDA: 'Lerida',
	STATE_LLEIDA: 'Lleida',
	STATE_LUGO: 'Lugo',
	STATE_MADRID: 'Madrid',
	STATE_MALAGA: 'Malaga',
	STATE_MELILLA: 'Melilla',
	STATE_MURCIA: 'Murcia',
	STATE_NAVARRA: 'Navarra',
	STATE_OURENSE: 'Ourense',
	STATE_PAIS_VASCO: 'Pais Vasco',
	STATE_PALENCIA: 'Palencia',
	STATE_PONTEVEDRA: 'Pontevedra',
	STATE_SALAMANCA: 'Salamanca',
	STATE_SANTA_CRUZ_DE_TENERIFE: 'Santa Cruz de Tenerife',
	STATE_SEGOVIA: 'Segovia',
	STATE_SEVILLA: 'Sevilla',
	STATE_SORIA: 'Soria',
	STATE_TARRAGONA: 'Tarragona',
	STATE_TENERIFE: 'Tenerife',
	STATE_TERUEL: 'Teruel',
	STATE_VALENCIA: 'Valencia',
	STATE_VALLADOLID: 'Valladolid',
	STATE_VIZCAYA: 'Vizcaya',
	STATE_ZAMORA: 'Zamora',
	STATE_ZARAGOZA: 'Zaragoza',
	STATE_AMPARAI: 'Amparai',
	STATE_ANURADHAPURAYA: 'Anuradhapuraya',
	STATE_BADULLA: 'Badulla',
	STATE_BORALESGAMUWA: 'Boralesgamuwa',
	STATE_COLOMBO: 'Colombo',
	STATE_GALLA: 'Galla',
	STATE_GAMPAHA: 'Gampaha',
	STATE_HAMBANTOTA: 'Hambantota',
	STATE_KALATURA: 'Kalatura',
	STATE_KEGALLA: 'Kegalla',
	STATE_KILINOCHCHI: 'Kilinochchi',
	STATE_KURUNEGALA: 'Kurunegala',
	STATE_MADAKALPUWA: 'Madakalpuwa',
	STATE_MAHA_NUWARA: 'Maha Nuwara',
	STATE_MALWANA: 'Malwana',
	STATE_MANNARAMA: 'Mannarama',
	STATE_MATALE: 'Matale',
	STATE_MATARA: 'Matara',
	STATE_MONARAGALA: 'Monaragala',
	STATE_MULLAITIVU: 'Mullaitivu',
	STATE_NORTH_EASTERN_PROVINCE: 'North Eastern Province',
	STATE_NORTH_WESTERN_PROVINCE: 'North Western Province',
	STATE_NUWARA_ELIYA: 'Nuwara Eliya',
	STATE_POLONNARUWA: 'Polonnaruwa',
	STATE_PUTTALAMA: 'Puttalama',
	STATE_RATNAPURAYA: 'Ratnapuraya',
	STATE_SOUTHERN_PROVINCE: 'Southern Province',
	STATE_TIRIKUNAMALAYA: 'Tirikunamalaya',
	STATE_TUSCANY: 'Tuscany',
	STATE_VAVUNIYAWA: 'Vavuniyawa',
	STATE_YAPANAYA: 'Yapanaya',
	STATE_KADAWATHA: 'kadawatha',
	STATE_A__ALI_AN_NIL: "A'ali-an-Nil",
	STATE_BAHR_AL_JABAL: 'Bahr-al-Jabal',
	STATE_GHARB_BAHR_AL_GHAZAL: 'Gharb Bahr-al-Ghazal',
	STATE_GHARB_DARFUR: 'Gharb Darfur',
	STATE_GHARB_KURDUFAN: 'Gharb Kurdufan',
	STATE_GHARB_AL_ISTIWA__IYAH: "Gharb-al-Istiwa'iyah",
	STATE_JANUB_DARFUR: 'Janub Darfur',
	STATE_JANUB_KURDUFAN: 'Janub Kurdufan',
	STATE_JUNQALI: 'Junqali',
	STATE_KASSALA: 'Kassala',
	STATE_NAHR_AN_NIL: 'Nahr-an-Nil',
	STATE_SHAMAL_BAHR_AL_GHAZAL: 'Shamal Bahr-al-Ghazal',
	STATE_SHAMAL_DARFUR: 'Shamal Darfur',
	STATE_SHAMAL_KURDUFAN: 'Shamal Kurdufan',
	STATE_SHARQ_AL_ISTIWA__IYAH: "Sharq-al-Istiwa'iyah",
	STATE_SINNAR: 'Sinnar',
	STATE_WARAB: 'Warab',
	STATE_WILAYAT_AL_KHARTUM: 'Wilayat al Khartum',
	STATE_AL_BUHAYRAT: 'al-Buhayrat',
	STATE_AL_JAZIRAH: 'al-Jazirah',
	STATE_AL_KHARTUM: 'al-Khartum',
	STATE_AL_QADARIF: 'al-Qadarif',
	STATE_AL_WAHDAH: 'al-Wahdah',
	STATE_AN_NIL_AL_ABYAD: 'an-Nil-al-Abyad',
	STATE_AN_NIL_AL_AZRAQ: 'an-Nil-al-Azraq',
	STATE_ASH_SHAMALIYAH: 'ash-Shamaliyah',
	STATE_BROKOPONDO: 'Brokopondo',
	STATE_COMMEWIJNE: 'Commewijne',
	STATE_CORONIE: 'Coronie',
	STATE_MAROWIJNE: 'Marowijne',
	STATE_NICKERIE: 'Nickerie',
	STATE_PARAMARIBO: 'Paramaribo',
	STATE_SARAMACCA: 'Saramacca',
	STATE_WANICA: 'Wanica',
	STATE_SVALBARD: 'Svalbard',
	STATE_HHOHHO: 'Hhohho',
	STATE_LUBOMBO: 'Lubombo',
	STATE_MANZINI: 'Manzini',
	STATE_SHISELWENI: 'Shiselweni',
	STATE_ALVSBORGS_LAN: 'Alvsborgs Lan',
	STATE_ANGERMANLAND: 'Angermanland',
	STATE_BLEKINGE: 'Blekinge',
	STATE_BOHUSLAN: 'Bohuslan',
	STATE_DALARNA: 'Dalarna',
	STATE_GAVLEBORG: 'Gavleborg',
	STATE_GOTLAND: 'Gotland',
	STATE_HALLAND: 'Halland',
	STATE_JAMTLAND: 'Jamtland',
	STATE_JONKOPING: 'Jonkoping',
	STATE_KALMAR: 'Kalmar',
	STATE_KRISTIANSTADS: 'Kristianstads',
	STATE_KRONOBERG: 'Kronoberg',
	STATE_NORRBOTTEN: 'Norrbotten',
	STATE_OREBRO: 'Orebro',
	STATE_OSTERGOTLAND: 'Ostergotland',
	STATE_SALTSJO_BOO: 'Saltsjo-Boo',
	STATE_SKANE: 'Skane',
	STATE_SMALAND: 'Smaland',
	STATE_SODERMANLAND: 'Sodermanland',
	STATE_STOCKHOLM: 'Stockholm',
	STATE_UPPSALA: 'Uppsala',
	STATE_VARMLAND: 'Varmland',
	STATE_VASTERBOTTEN: 'Vasterbotten',
	STATE_VASTERGOTLAND: 'Vastergotland',
	STATE_VASTERNORRLAND: 'Vasternorrland',
	STATE_VASTMANLAND: 'Vastmanland',
	STATE_VASTRA_GOTALAND: 'Vastra Gotaland',
	STATE_AARGAU: 'Aargau',
	STATE_APPENZELL_INNER_RHODEN: 'Appenzell Inner-Rhoden',
	STATE_APPENZELL_AUSSER_RHODEN: 'Appenzell-Ausser Rhoden',
	STATE_BASEL_LANDSCHAFT: 'Basel-Landschaft',
	STATE_BASEL_STADT: 'Basel-Stadt',
	STATE_BERN: 'Bern',
	STATE_CANTON_TICINO: 'Canton Ticino',
	STATE_FRIBOURG: 'Fribourg',
	STATE_GENEVE: 'Geneve',
	STATE_GLARUS: 'Glarus',
	STATE_GRAUBUNDEN: 'Graubunden',
	STATE_HEERBRUGG: 'Heerbrugg',
	STATE_KANTON_AARGAU: 'Kanton Aargau',
	STATE_LUZERN: 'Luzern',
	STATE_MORBIO_INFERIORE: 'Morbio Inferiore',
	STATE_MUHEN: 'Muhen',
	STATE_NEUCHATEL: 'Neuchatel',
	STATE_NIDWALDEN: 'Nidwalden',
	STATE_OBWALDEN: 'Obwalden',
	STATE_SANKT_GALLEN: 'Sankt Gallen',
	STATE_SCHAFFHAUSEN: 'Schaffhausen',
	STATE_SCHWYZ: 'Schwyz',
	STATE_SOLOTHURN: 'Solothurn',
	STATE_THURGAU: 'Thurgau',
	STATE_TICINO: 'Ticino',
	STATE_URI: 'Uri',
	STATE_VALAIS: 'Valais',
	STATE_VAUD: 'Vaud',
	STATE_VAUFFELIN: 'Vauffelin',
	STATE_ZUG: 'Zug',
	STATE_ZURICH: 'Zurich',
	STATE_ALEPPO: 'Aleppo',
	STATE_DAR__A: "Dar'a",
	STATE_DAYR_AZ_ZAWR: 'Dayr-az-Zawr',
	STATE_DIMASHQ: 'Dimashq',
	STATE_HALAB: 'Halab',
	STATE_HAMAH: 'Hamah',
	STATE_HIMS: 'Hims',
	STATE_IDLIB: 'Idlib',
	STATE_MADINAT_DIMASHQ: 'Madinat Dimashq',
	STATE_TARTUS: 'Tartus',
	STATE_AL_HASAKAH: 'al-Hasakah',
	STATE_AL_LADHIQIYAH: 'al-Ladhiqiyah',
	STATE_AL_QUNAYTIRAH: 'al-Qunaytirah',
	STATE_AR_RAQQAH: 'ar-Raqqah',
	STATE_AS_SUWAYDA: 'as-Suwayda',
	STATE_CHANGHWA: 'Changhwa',
	STATE_CHIAYI_HSIEN: 'Chiayi Hsien',
	STATE_CHIAYI_SHIH: 'Chiayi Shih',
	STATE_EASTERN_TAIPEI: 'Eastern Taipei',
	STATE_HSINCHU_HSIEN: 'Hsinchu Hsien',
	STATE_HSINCHU_SHIH: 'Hsinchu Shih',
	STATE_HUALIEN: 'Hualien',
	STATE_ILAN: 'Ilan',
	STATE_KAOHSIUNG_HSIEN: 'Kaohsiung Hsien',
	STATE_KAOHSIUNG_SHIH: 'Kaohsiung Shih',
	STATE_KEELUNG_SHIH: 'Keelung Shih',
	STATE_KINMEN: 'Kinmen',
	STATE_MIAOLI: 'Miaoli',
	STATE_NANTOU: 'Nantou',
	STATE_NORTHERN_TAIWAN: 'Northern Taiwan',
	STATE_PENGHU: 'Penghu',
	STATE_PINGTUNG: 'Pingtung',
	STATE_TAICHUNG: 'Taichung',
	STATE_TAICHUNG_HSIEN: 'Taichung Hsien',
	STATE_TAICHUNG_SHIH: 'Taichung Shih',
	STATE_TAINAN_HSIEN: 'Tainan Hsien',
	STATE_TAINAN_SHIH: 'Tainan Shih',
	STATE_TAIPEI_HSIEN: 'Taipei Hsien',
	STATE_TAIPEI_SHIH___TAIPEI_HSIEN: 'Taipei Shih / Taipei Hsien',
	STATE_TAITUNG: 'Taitung',
	STATE_TAOYUAN: 'Taoyuan',
	STATE_YILAN: 'Yilan',
	STATE_YUN_LIN_HSIEN: 'Yun-Lin Hsien',
	STATE_YUNLIN: 'Yunlin',
	STATE_DUSHANBE: 'Dushanbe',
	STATE_GORNO_BADAKHSHAN: 'Gorno-Badakhshan',
	STATE_KAROTEGIN: 'Karotegin',
	STATE_KHATLON: 'Khatlon',
	STATE_SUGHD: 'Sughd',
	STATE_ARUSHA: 'Arusha',
	STATE_DAR_ES_SALAAM: 'Dar es Salaam',
	STATE_DODOMA: 'Dodoma',
	STATE_IRINGA: 'Iringa',
	STATE_KAGERA: 'Kagera',
	STATE_KIGOMA: 'Kigoma',
	STATE_KILIMANJARO: 'Kilimanjaro',
	STATE_LINDI: 'Lindi',
	STATE_MARA: 'Mara',
	STATE_MBEYA: 'Mbeya',
	STATE_MOROGORO: 'Morogoro',
	STATE_MTWARA: 'Mtwara',
	STATE_PWANI: 'Pwani',
	STATE_RUKWA: 'Rukwa',
	STATE_RUVUMA: 'Ruvuma',
	STATE_SHINYANGA: 'Shinyanga',
	STATE_SINGIDA: 'Singida',
	STATE_TABORA: 'Tabora',
	STATE_TANGA: 'Tanga',
	STATE_ZANZIBAR_AND_PEMBA: 'Zanzibar and Pemba',
	STATE_AMNAT_CHAROEN: 'Amnat Charoen',
	STATE_ANG_THONG: 'Ang Thong',
	STATE_BANGKOK: 'Bangkok',
	STATE_BURI_RAM: 'Buri Ram',
	STATE_CHACHOENGSAO: 'Chachoengsao',
	STATE_CHAI_NAT: 'Chai Nat',
	STATE_CHAIYAPHUM: 'Chaiyaphum',
	STATE_CHANGWAT_CHAIYAPHUM: 'Changwat Chaiyaphum',
	STATE_CHANTHABURI: 'Chanthaburi',
	STATE_CHIANG_MAI: 'Chiang Mai',
	STATE_CHIANG_RAI: 'Chiang Rai',
	STATE_CHON_BURI: 'Chon Buri',
	STATE_CHUMPHON: 'Chumphon',
	STATE_KALASIN: 'Kalasin',
	STATE_KAMPHAENG_PHET: 'Kamphaeng Phet',
	STATE_KANCHANABURI: 'Kanchanaburi',
	STATE_KHON_KAEN: 'Khon Kaen',
	STATE_KRABI: 'Krabi',
	STATE_KRUNG_THEP: 'Krung Thep',
	STATE_LAMPANG: 'Lampang',
	STATE_LAMPHUN: 'Lamphun',
	STATE_LOEI: 'Loei',
	STATE_LOP_BURI: 'Lop Buri',
	STATE_MAE_HONG_SON: 'Mae Hong Son',
	STATE_MAHA_SARAKHAM: 'Maha Sarakham',
	STATE_MUKDAHAN: 'Mukdahan',
	STATE_NAKHON_NAYOK: 'Nakhon Nayok',
	STATE_NAKHON_PATHOM: 'Nakhon Pathom',
	STATE_NAKHON_PHANOM: 'Nakhon Phanom',
	STATE_NAKHON_RATCHASIMA: 'Nakhon Ratchasima',
	STATE_NAKHON_SAWAN: 'Nakhon Sawan',
	STATE_NAKHON_SI_THAMMARAT: 'Nakhon Si Thammarat',
	STATE_NAN: 'Nan',
	STATE_NARATHIWAT: 'Narathiwat',
	STATE_NONG_BUA_LAM_PHU: 'Nong Bua Lam Phu',
	STATE_NONG_KHAI: 'Nong Khai',
	STATE_NONTHABURI: 'Nonthaburi',
	STATE_PATHUM_THANI: 'Pathum Thani',
	STATE_PATTANI: 'Pattani',
	STATE_PHANGNGA: 'Phangnga',
	STATE_PHATTHALUNG: 'Phatthalung',
	STATE_PHAYAO: 'Phayao',
	STATE_PHETCHABUN: 'Phetchabun',
	STATE_PHETCHABURI: 'Phetchaburi',
	STATE_PHICHIT: 'Phichit',
	STATE_PHITSANULOK: 'Phitsanulok',
	STATE_PHRA_NAKHON_SI_AYUTTHAYA: 'Phra Nakhon Si Ayutthaya',
	STATE_PHRAE: 'Phrae',
	STATE_PHUKET: 'Phuket',
	STATE_PRACHIN_BURI: 'Prachin Buri',
	STATE_PRACHUAP_KHIRI_KHAN: 'Prachuap Khiri Khan',
	STATE_RANONG: 'Ranong',
	STATE_RATCHABURI: 'Ratchaburi',
	STATE_RAYONG: 'Rayong',
	STATE_ROI_ET: 'Roi Et',
	STATE_SA_KAEO: 'Sa Kaeo',
	STATE_SAKON_NAKHON: 'Sakon Nakhon',
	STATE_SAMUT_PRAKAN: 'Samut Prakan',
	STATE_SAMUT_SAKHON: 'Samut Sakhon',
	STATE_SAMUT_SONGKHRAN: 'Samut Songkhran',
	STATE_SARABURI: 'Saraburi',
	STATE_SATUN: 'Satun',
	STATE_SI_SA_KET: 'Si Sa Ket',
	STATE_SING_BURI: 'Sing Buri',
	STATE_SONGKHLA: 'Songkhla',
	STATE_SUKHOTHAI: 'Sukhothai',
	STATE_SUPHAN_BURI: 'Suphan Buri',
	STATE_SURAT_THANI: 'Surat Thani',
	STATE_SURIN: 'Surin',
	STATE_TAK: 'Tak',
	STATE_TRANG: 'Trang',
	STATE_TRAT: 'Trat',
	STATE_UBON_RATCHATHANI: 'Ubon Ratchathani',
	STATE_UDON_THANI: 'Udon Thani',
	STATE_UTHAI_THANI: 'Uthai Thani',
	STATE_UTTARADIT: 'Uttaradit',
	STATE_YALA: 'Yala',
	STATE_YASOTHON: 'Yasothon',
	STATE_KARA: 'Kara',
	STATE_MARITIME: 'Maritime',
	STATE_ATAFU: 'Atafu',
	STATE_FAKAOFO: 'Fakaofo',
	STATE_NUKUNONU: 'Nukunonu',
	STATE_EUA: 'Eua',
	STATE_HA__APAI: "Ha'apai",
	STATE_NIUAS: 'Niuas',
	STATE_TONGATAPU: 'Tongatapu',
	STATE_VAVA__U: "Vava'u",
	STATE_ARIMA_TUNAPUNA_PIARCO: 'Arima-Tunapuna-Piarco',
	STATE_CARONI: 'Caroni',
	STATE_CHAGUANAS: 'Chaguanas',
	STATE_COUVA_TABAQUITE_TALPARO: 'Couva-Tabaquite-Talparo',
	STATE_DIEGO_MARTIN: 'Diego Martin',
	STATE_GLENCOE: 'Glencoe',
	STATE_PENAL_DEBE: 'Penal Debe',
	STATE_POINT_FORTIN: 'Point Fortin',
	STATE_PORT_OF_SPAIN: 'Port of Spain',
	STATE_PRINCES_TOWN: 'Princes Town',
	STATE_SAN_FERNANDO: 'San Fernando',
	STATE_SANGRE_GRANDE: 'Sangre Grande',
	STATE_SIPARIA: 'Siparia',
	STATE_TOBAGO: 'Tobago',
	STATE_ARYANAH: 'Aryanah',
	STATE_BAJAH: 'Bajah',
	STATE_BIN___ARUS: "Bin 'Arus",
	STATE_BINZART: 'Binzart',
	STATE_GOUVERNORAT_DE_ARIANA: 'Gouvernorat de Ariana',
	STATE_GOUVERNORAT_DE_NABEUL: 'Gouvernorat de Nabeul',
	STATE_GOUVERNORAT_DE_SOUSSE: 'Gouvernorat de Sousse',
	STATE_HAMMAMET_YASMINE: 'Hammamet Yasmine',
	STATE_JUNDUBAH: 'Jundubah',
	STATE_MADANIYIN: 'Madaniyin',
	STATE_MANUBAH: 'Manubah',
	STATE_MONASTIR: 'Monastir',
	STATE_NABUL: 'Nabul',
	STATE_QABIS: 'Qabis',
	STATE_QAFSAH: 'Qafsah',
	STATE_QIBILI: 'Qibili',
	STATE_SAFAQIS: 'Safaqis',
	STATE_SFAX: 'Sfax',
	STATE_SIDI_BU_ZAYD: 'Sidi Bu Zayd',
	STATE_SILYANAH: 'Silyanah',
	STATE_SUSAH: 'Susah',
	STATE_TATAWIN: 'Tatawin',
	STATE_TAWZAR: 'Tawzar',
	STATE_TUNIS: 'Tunis',
	STATE_ZAGHWAN: 'Zaghwan',
	STATE_AL_KAF: 'al-Kaf',
	STATE_AL_MAHDIYAH: 'al-Mahdiyah',
	STATE_AL_MUNASTIR: 'al-Munastir',
	STATE_AL_QASRAYN: 'al-Qasrayn',
	STATE_AL_QAYRAWAN: 'al-Qayrawan',
	STATE_ADANA: 'Adana',
	STATE_ADIYAMAN: 'Adiyaman',
	STATE_AFYON: 'Afyon',
	STATE_AGRI: 'Agri',
	STATE_AKSARAY: 'Aksaray',
	STATE_AMASYA: 'Amasya',
	STATE_ANKARA: 'Ankara',
	STATE_ANTALYA: 'Antalya',
	STATE_ARDAHAN: 'Ardahan',
	STATE_ARTVIN: 'Artvin',
	STATE_AYDIN: 'Aydin',
	STATE_BALIKESIR: 'Balikesir',
	STATE_BARTIN: 'Bartin',
	STATE_BATMAN: 'Batman',
	STATE_BAYBURT: 'Bayburt',
	STATE_BILECIK: 'Bilecik',
	STATE_BINGOL: 'Bingol',
	STATE_BITLIS: 'Bitlis',
	STATE_BOLU: 'Bolu',
	STATE_BURDUR: 'Burdur',
	STATE_BURSA: 'Bursa',
	STATE_CANAKKALE: 'Canakkale',
	STATE_CANKIRI: 'Cankiri',
	STATE_CORUM: 'Corum',
	STATE_DENIZLI: 'Denizli',
	STATE_DIYARBAKIR: 'Diyarbakir',
	STATE_DUZCE: 'Duzce',
	STATE_EDIRNE: 'Edirne',
	STATE_ELAZIG: 'Elazig',
	STATE_ERZINCAN: 'Erzincan',
	STATE_ERZURUM: 'Erzurum',
	STATE_ESKISEHIR: 'Eskisehir',
	STATE_GAZIANTEP: 'Gaziantep',
	STATE_GIRESUN: 'Giresun',
	STATE_GUMUSHANE: 'Gumushane',
	STATE_HAKKARI: 'Hakkari',
	STATE_HATAY: 'Hatay',
	STATE_ICEL: 'Icel',
	STATE_IGDIR: 'Igdir',
	STATE_ISPARTA: 'Isparta',
	STATE_ISTANBUL: 'Istanbul',
	STATE_IZMIR: 'Izmir',
	STATE_KAHRAMANMARAS: 'Kahramanmaras',
	STATE_KARABUK: 'Karabuk',
	STATE_KARAMAN: 'Karaman',
	STATE_KARS: 'Kars',
	STATE_KARSIYAKA: 'Karsiyaka',
	STATE_KASTAMONU: 'Kastamonu',
	STATE_KAYSERI: 'Kayseri',
	STATE_KILIS: 'Kilis',
	STATE_KIRIKKALE: 'Kirikkale',
	STATE_KIRKLARELI: 'Kirklareli',
	STATE_KIRSEHIR: 'Kirsehir',
	STATE_KOCAELI: 'Kocaeli',
	STATE_KONYA: 'Konya',
	STATE_KUTAHYA: 'Kutahya',
	STATE_LEFKOSA: 'Lefkosa',
	STATE_MALATYA: 'Malatya',
	STATE_MANISA: 'Manisa',
	STATE_MARDIN: 'Mardin',
	STATE_MUGLA: 'Mugla',
	STATE_MUS: 'Mus',
	STATE_NEVSEHIR: 'Nevsehir',
	STATE_NIGDE: 'Nigde',
	STATE_ORDU: 'Ordu',
	STATE_OSMANIYE: 'Osmaniye',
	STATE_RIZE: 'Rize',
	STATE_SAKARYA: 'Sakarya',
	STATE_SAMSUN: 'Samsun',
	STATE_SANLIURFA: 'Sanliurfa',
	STATE_SIIRT: 'Siirt',
	STATE_SINOP: 'Sinop',
	STATE_SIRNAK: 'Sirnak',
	STATE_SIVAS: 'Sivas',
	STATE_TEKIRDAG: 'Tekirdag',
	STATE_TOKAT: 'Tokat',
	STATE_TRABZON: 'Trabzon',
	STATE_TUNCELI: 'Tunceli',
	STATE_USAK: 'Usak',
	STATE_VAN: 'Van',
	STATE_YALOVA: 'Yalova',
	STATE_YOZGAT: 'Yozgat',
	STATE_ZONGULDAK: 'Zonguldak',
	STATE_AHAL: 'Ahal',
	STATE_ASGABAT: 'Asgabat',
	STATE_BALKAN: 'Balkan',
	STATE_DASOGUZ: 'Dasoguz',
	STATE_LEBAP: 'Lebap',
	STATE_MARI: 'Mari',
	STATE_GRAND_TURK: 'Grand Turk',
	STATE_SOUTH_CAICOS_AND_EAST_CAICOS: 'South Caicos and East Caicos',
	STATE_FUNAFUTI: 'Funafuti',
	STATE_NANUMANGA: 'Nanumanga',
	STATE_NANUMEA: 'Nanumea',
	STATE_NIUTAO: 'Niutao',
	STATE_NUI: 'Nui',
	STATE_NUKUFETAU: 'Nukufetau',
	STATE_NUKULAELAE: 'Nukulaelae',
	STATE_VAITUPU: 'Vaitupu',
	STATE_CHERKAS__KA: "Cherkas'ka",
	STATE_CHERNIHIVS__KA: "Chernihivs'ka",
	STATE_CHERNIVETS__KA: "Chernivets'ka",
	STATE_CRIMEA: 'Crimea',
	STATE_DNIPROPETROVSKA: 'Dnipropetrovska',
	STATE_DONETS__KA: "Donets'ka",
	STATE_IVANO_FRANKIVS__KA: "Ivano-Frankivs'ka",
	STATE_KHARKIV: 'Kharkiv',
	STATE_KHARKOV: 'Kharkov',
	STATE_KHERSONSKA: 'Khersonska',
	STATE_KHMEL__NYTS__KA: "Khmel'nyts'ka",
	STATE_KIROVOHRAD: 'Kirovohrad',
	STATE_KRYM: 'Krym',
	STATE_KYYIV: 'Kyyiv',
	STATE_KYYIVS__KA: "Kyyivs'ka",
	STATE_L__VIVS__KA: "L'vivs'ka",
	STATE_LUHANS__KA: "Luhans'ka",
	STATE_MYKOLAYIVS__KA: "Mykolayivs'ka",
	STATE_ODES__KA: "Odes'ka",
	STATE_ODESSA: 'Odessa',
	STATE_POLTAVS__KA: "Poltavs'ka",
	STATE_RIVNENS__KA: "Rivnens'ka",
	STATE_SEVASTOPOL: 'Sevastopol',
	STATE_SUMS__KA: "Sums'ka",
	STATE_TERNOPIL__S__KA: "Ternopil's'ka",
	STATE_VOLYNS__KA: "Volyns'ka",
	STATE_VYNNYTS__KA: "Vynnyts'ka",
	STATE_ZAKARPATS__KA: "Zakarpats'ka",
	STATE_ZAPORIZHIA: 'Zaporizhia',
	STATE_ZHYTOMYRS__KA: "Zhytomyrs'ka",
	STATE_ABU_ZABI: 'Abu Zabi',
	STATE_AJMAN: 'Ajman',
	STATE_DUBAI: 'Dubai',
	STATE_RAS_AL_KHAYMAH: 'Ras al-Khaymah',
	STATE_SHARJAH: 'Sharjah',
	STATE_SHARJHA: 'Sharjha',
	STATE_UMM_AL_QAYWAYN: 'Umm al Qaywayn',
	STATE_AL_FUJAYRAH: 'al-Fujayrah',
	STATE_ASH_SHARIQAH: 'ash-Shariqah',
	STATE_ABERDEEN: 'Aberdeen',
	STATE_ABERDEENSHIRE: 'Aberdeenshire',
	STATE_ARGYLL: 'Argyll',
	STATE_BEDFORDSHIRE: 'Bedfordshire',
	STATE_BELFAST: 'Belfast',
	STATE_BERKSHIRE: 'Berkshire',
	STATE_BIRMINGHAM: 'Birmingham',
	STATE_BRECHIN: 'Brechin',
	STATE_BRIDGNORTH: 'Bridgnorth',
	STATE_BRISTOL: 'Bristol',
	STATE_BUCKINGHAMSHIRE: 'Buckinghamshire',
	STATE_CAMBRIDGE: 'Cambridge',
	STATE_CAMBRIDGESHIRE: 'Cambridgeshire',
	STATE_CHANNEL_ISLANDS: 'Channel Islands',
	STATE_CHESHIRE: 'Cheshire',
	STATE_CLEVELAND: 'Cleveland',
	STATE_CO_FERMANAGH: 'Co Fermanagh',
	STATE_CONWY: 'Conwy',
	STATE_CORNWALL: 'Cornwall',
	STATE_COVENTRY: 'Coventry',
	STATE_CRAVEN_ARMS: 'Craven Arms',
	STATE_CUMBRIA: 'Cumbria',
	STATE_DENBIGHSHIRE: 'Denbighshire',
	STATE_DERBY: 'Derby',
	STATE_DERBYSHIRE: 'Derbyshire',
	STATE_DEVON: 'Devon',
	STATE_DIAL_CODE_DUNGANNON: 'Dial Code Dungannon',
	STATE_DIDCOT: 'Didcot',
	STATE_DORSET: 'Dorset',
	STATE_DUNBARTONSHIRE: 'Dunbartonshire',
	STATE_DURHAM: 'Durham',
	STATE_EAST_DUNBARTONSHIRE: 'East Dunbartonshire',
	STATE_EAST_LOTHIAN: 'East Lothian',
	STATE_EAST_MIDLANDS: 'East Midlands',
	STATE_EAST_SUSSEX: 'East Sussex',
	STATE_EAST_YORKSHIRE: 'East Yorkshire',
	STATE_ENGLAND: 'England',
	STATE_ESSEX: 'Essex',
	STATE_FERMANAGH: 'Fermanagh',
	STATE_FIFE: 'Fife',
	STATE_FLINTSHIRE: 'Flintshire',
	STATE_FULHAM: 'Fulham',
	STATE_GAINSBOROUGH: 'Gainsborough',
	STATE_GLOCESTERSHIRE: 'Glocestershire',
	STATE_GWENT: 'Gwent',
	STATE_HAMPSHIRE: 'Hampshire',
	STATE_HANTS: 'Hants',
	STATE_HEREFORDSHIRE: 'Herefordshire',
	STATE_HERTFORDSHIRE: 'Hertfordshire',
	STATE_IRELAND: 'Ireland',
	STATE_ISLE_OF_MAN: 'Isle Of Man',
	STATE_ISLE_OF_WIGHT: 'Isle of Wight',
	STATE_KENFORD: 'Kenford',
	STATE_KENT: 'Kent',
	STATE_KILMARNOCK: 'Kilmarnock',
	STATE_LANARKSHIRE: 'Lanarkshire',
	STATE_LANCASHIRE: 'Lancashire',
	STATE_LEICESTERSHIRE: 'Leicestershire',
	STATE_LINCOLNSHIRE: 'Lincolnshire',
	STATE_LLANYMYNECH: 'Llanymynech',
	STATE_LONDON: 'London',
	STATE_LUDLOW: 'Ludlow',
	STATE_MAYFAIR: 'Mayfair',
	STATE_MERSEYSIDE: 'Merseyside',
	STATE_MID_GLAMORGAN: 'Mid Glamorgan',
	TATE_MIDDLESEX: 'Middlesex',
	STATE_MILDENHALL: 'Mildenhall',
	STATE_MONMOUTHSHIRE: 'Monmouthshire',
	STATE_NEWTON_STEWART: 'Newton Stewart',
	STATE_NORFOLK: 'Norfolk',
	STATE_NORTH_HUMBERSIDE: 'North Humberside',
	STATE_NORTH_YORKSHIRE: 'North Yorkshire',
	STATE_NORTHAMPTONSHIRE: 'Northamptonshire',
	STATE_NORTHANTS: 'Northants',
	STATE_NORTHERN_IRELAND: 'Northern Ireland',
	STATE_NORTHUMBERLAND: 'Northumberland',
	STATE_NOTTINGHAMSHIRE: 'Nottinghamshire',
	STATE_OXFORD: 'Oxford',
	STATE_POWYS: 'Powys',
	STATE_ROOS_SHIRE: 'Roos-shire',
	STATE_SUSSEX: 'SUSSEX',
	STATE_SCOTLAND: 'Scotland',
	STATE_SCOTTISH_BORDERS: 'Scottish Borders',
	STATE_SHROPSHIRE: 'Shropshire',
	STATE_SOMERSET: 'Somerset',
	STATE_SOUTH_GLAMORGAN: 'South Glamorgan',
	STATE_SOUTH_WALES: 'South Wales',
	STATE_SOUTH_YORKSHIRE: 'South Yorkshire',
	STATE_SOUTHWELL: 'Southwell',
	STATE_STAFFORDSHIRE: 'Staffordshire',
	STATE_STRABANE: 'Strabane',
	STATE_SUFFOLK: 'Suffolk',
	STATE_SURREY: 'Surrey',
	STATE_TWICKENHAM: 'Twickenham',
	STATE_TYNE_AND_WEAR: 'Tyne and Wear',
	STATE_TYRONE: 'Tyrone',
	STATE_UTAH: 'Utah',
	STATE_WALES: 'Wales',
	STATE_WARWICKSHIRE: 'Warwickshire',
	STATE_WEST_LOTHIAN: 'West Lothian',
	STATE_WEST_MIDLANDS: 'West Midlands',
	STATE_WEST_SUSSEX: 'West Sussex',
	STATE_WEST_YORKSHIRE: 'West Yorkshire',
	STATE_WHISSENDINE: 'Whissendine',
	STATE_WILTSHIRE: 'Wiltshire',
	STATE_WOKINGHAM: 'Wokingham',
	STATE_WORCESTERSHIRE: 'Worcestershire',
	STATE_WREXHAM: 'Wrexham',
	STATE_WURTTEMBERG: 'Wurttemberg',
	STATE_YORKSHIRE: 'Yorkshire',
	STATE_CALIFORNIA: 'California',
	STATE_COLORADO: 'Colorado',
	STATE_CONNECTICUT: 'Connecticut',
	STATE_DELAWARE: 'Delaware',
	STATE_DISTRICT_OF_COLUMBIA: 'District of Columbia',
	STATE_HAWAII: 'Hawaii',
	STATE_IDAHO: 'Idaho',
	STATE_ILLINOIS: 'Illinois',
	STATE_INDIANA: 'Indiana',
	STATE_IOWA: 'Iowa',
	STATE_KANSAS: 'Kansas',
	STATE_KENTUCKY: 'Kentucky',
	STATE_LOUISIANA: 'Louisiana',
	STATE_MAINE: 'Maine',
	STATE_MARYLAND: 'Maryland',
	STATE_MASSACHUSETTS: 'Massachusetts',
	STATE_MICHIGAN: 'Michigan',
	STATE_MINNESOTA: 'Minnesota',
	STATE_MISSISSIPPI: 'Mississippi',
	STATE_MISSOURI: 'Missouri',
	STATE_NEBRASKA: 'Nebraska',
	STATE_NEVADA: 'Nevada',
	STATE_NEW_HAMPSHIRE: 'New Hampshire',
	STATE_NEW_JERSEY: 'New Jersey',
	STATE_NEW_MEXICO: 'New Mexico',
	STATE_NORTH_CAROLINA: 'North Carolina',
	STATE_NORTH_DAKOTA: 'North Dakota',
	STATE_OHIO: 'Ohio',
	STATE_OKLAHOMA: 'Oklahoma',
	STATE_OREGON: 'Oregon',
	STATE_PENNSYLVANIA: 'Pennsylvania',
	STATE_RHODE_ISLAND: 'Rhode Island',
	STATE_SOUTH_CAROLINA: 'South Carolina',
	STATE_SOUTH_DAKOTA: 'South Dakota',
	STATE_TENNESSEE: 'Tennessee',
	STATE_TEXAS: 'Texas',
	STATE_VIRGINIA: 'Virginia',
	STATE_WEST_VIRGINIA: 'West Virginia',
	STATE_WISCONSIN: 'Wisconsin',
	STATE_WYOMING: 'Wyoming',
	STATE_UNITED_STATES_MINOR_OUTLYING_I: 'United States Minor Outlying I',
	STATE_ARTIGAS: 'Artigas',
	STATE_CANELONES: 'Canelones',
	STATE_CERRO_LARGO: 'Cerro Largo',
	STATE_COLONIA: 'Colonia',
	STATE_DURAZNO: 'Durazno',
	STATE_FLORES: 'Flores',
	STATE_LAVALLEJA: 'Lavalleja',
	STATE_MALDONADO: 'Maldonado',
	STATE_MONTEVIDEO: 'Montevideo',
	STATE_PAYSANDU: 'Paysandu',
	STATE_RIVERA: 'Rivera',
	STATE_ROCHA: 'Rocha',
	STATE_SALTO: 'Salto',
	STATE_SORIANO: 'Soriano',
	STATE_TACUAREMBO: 'Tacuarembo',
	STATE_TREINTA_Y_TRES: 'Treinta y Tres',
	STATE_ANDIJON: 'Andijon',
	STATE_BUHORO: 'Buhoro',
	STATE_CIZAH: 'Cizah',
	STATE_FARGONA: 'Fargona',
	STATE_HORAZM: 'Horazm',
	STATE_KASKADAR: 'Kaskadar',
	STATE_KORAKALPOGISTON: 'Korakalpogiston',
	STATE_NAMANGAN: 'Namangan',
	STATE_NAVOI: 'Navoi',
	STATE_SAMARKAND: 'Samarkand',
	STATE_SIRDARE: 'Sirdare',
	STATE_SURHONDAR: 'Surhondar',
	STATE_TOSKENT: 'Toskent',
	STATE_MALAMPA: 'Malampa',
	STATE_PENAMA: 'Penama',
	STATE_SANMA: 'Sanma',
	STATE_SHEFA: 'Shefa',
	STATE_TAFEA: 'Tafea',
	STATE_TORBA: 'Torba',
	STATE_VATICAN_CITY_STATE_HOLY_SEE_: 'Vatican City State (Holy See)',
	STATE_ANZOATEGUI: 'Anzoategui',
	STATE_APURE: 'Apure',
	STATE_ARAGUA: 'Aragua',
	STATE_BARINAS: 'Barinas',
	STATE_CARABOBO: 'Carabobo',
	STATE_COJEDES: 'Cojedes',
	STATE_DELTA_AMACURO: 'Delta Amacuro',
	STATE_FALCON: 'Falcon',
	STATE_GUARICO: 'Guarico',
	STATE_LARA: 'Lara',
	STATE_MERIDA: 'Merida',
	STATE_MIRANDA: 'Miranda',
	STATE_MONAGAS: 'Monagas',
	STATE_NUEVA_ESPARTA: 'Nueva Esparta',
	STATE_PORTUGUESA: 'Portuguesa',
	STATE_TACHIRA: 'Tachira',
	STATE_TRUJILLO: 'Trujillo',
	STATE_VARGAS: 'Vargas',
	STATE_YARACUY: 'Yaracuy',
	STATE_ZULIA: 'Zulia',
	STATE_BAC_GIANG: 'Bac Giang',
	STATE_BINH_DINH: 'Binh Dinh',
	STATE_BINH_DUONG: 'Binh Duong',
	STATE_DA_NANG: 'Da Nang',
	STATE_DONG_BANG_SONG_CUU_LONG: 'Dong Bang Song Cuu Long',
	STATE_DONG_BANG_SONG_HONG: 'Dong Bang Song Hong',
	STATE_DONG_NAI: 'Dong Nai',
	STATE_DONG_NAM_BO: 'Dong Nam Bo',
	STATE_DUYEN_HAI_MIEN_TRUNG: 'Duyen Hai Mien Trung',
	STATE_HANOI: 'Hanoi',
	STATE_HUNG_YEN: 'Hung Yen',
	STATE_KHU_BON_CU: 'Khu Bon Cu',
	STATE_LONG_AN: 'Long An',
	STATE_MIEN_NUI_VA_TRUNG_DU: 'Mien Nui Va Trung Du',
	STATE_THAI_NGUYEN: 'Thai Nguyen',
	STATE_THANH_PHO_HO_CHI_MINH: 'Thanh Pho Ho Chi Minh',
	STATE_THU_DO_HA_NOI: 'Thu Do Ha Noi',
	STATE_TINH_CAN_THO: 'Tinh Can Tho',
	STATE_TINH_DA_NANG: 'Tinh Da Nang',
	STATE_TINH_GIA_LAI: 'Tinh Gia Lai',
	STATE_ANEGADA: 'Anegada',
	STATE_JOST_VAN_DYKE: 'Jost van Dyke',
	STATE_TORTOLA: 'Tortola',
	STATE_SAINT_CROIX: 'Saint Croix',
	STATE_ALO: 'Alo',
	STATE_SINGAVE: 'Singave',
	STATE_WALLIS: 'Wallis',
	STATE_BU_JAYDUR: 'Bu Jaydur',
	STATE_WAD_ADH_DHAHAB: 'Wad-adh-Dhahab',
	STATE_AL___AYUN: "al-'Ayun",
	STATE_AS_SAMARAH: 'as-Samarah',
	STATE_ADAN: 'Adan',
	STATE_ABYAN: 'Abyan',
	STATE_DHAMAR: 'Dhamar',
	STATE_HADRAMAUT: 'Hadramaut',
	STATE_HAJJAH: 'Hajjah',
	STATE_HUDAYDAH: 'Hudaydah',
	STATE_IBB: 'Ibb',
	STATE_LAHIJ: 'Lahij',
	STATE_MA__RIB: "Ma'rib",
	STATE_MADINAT_SAN__A: "Madinat San'a",
	STATE_SA__DAH: "Sa'dah",
	STATE_SANA: 'Sana',
	STATE_SHABWAH: 'Shabwah',
	STATE_TA__IZZ: "Ta'izz",
	STATE_AL_BAYDA: 'al-Bayda',
	STATE_AL_HUDAYDAH: 'al-Hudaydah',
	STATE_AL_JAWF: 'al-Jawf',
	STATE_AL_MAHRAH: 'al-Mahrah',
	STATE_AL_MAHWIT: 'al-Mahwit',
	STATE_COPPERBELT: 'Copperbelt',
	STATE_LUAPALA: 'Luapala',
	STATE_LUSAKA: 'Lusaka',
	STATE_NORTH_WESTERN: 'North-Western',
	STATE_BULAWAYO: 'Bulawayo',
	STATE_HARARE: 'Harare',
	STATE_MANICALAND: 'Manicaland',
	STATE_MASHONALAND_CENTRAL: 'Mashonaland Central',
	STATE_MASHONALAND_EAST: 'Mashonaland East',
	STATE_MASHONALAND_WEST: 'Mashonaland West',
	STATE_MASVINGO: 'Masvingo',
	STATE_MATABELELAND_NORTH: 'Matabeleland North',
	STATE_MATABELELAND_SOUTH: 'Matabeleland South',
	STATE_MIDLANDS: 'Midlands',
	STATE_AARHUS: 'Aarhus',
	AUTOMATION_ZONES: 'Automation Zones'
};
