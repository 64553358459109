import onboardingLocalization from '../components/Onboarding/localization/fr-FR';
import ticketingLocalization from '../components/Ticketing/localization/fr-FR';
import supportLocalization from '../components/Support/localization/fr-FR';
import automationLocalization from '../components/Automation/localization/fr-FR';
import sharedLocalization from '../components/Shared/localization/fr-FR';
import analyticsLocalization from '../components/AnalyticsInvoices/localization/fr-FR';
import failoverLocalization from '../components/Failover/localization/fr-FR';
import reportsLocalization from '../components/Reports/localization/fr-FR';
import eulaLocalization from '../components/Eula/localization/fr-FR';
import accountOverviewLocalization from '../components/AccountOverview/localization/fr-FR';
import analyticsChartsLocalization from '../components/Analytics/localization/fr-FR';
import batchHistoryLocalization from '../components/BatchHistory/localization/fr-FR';
import errorLocalization from '../components/ErrorPage/localization/fr-FR';
import userFeedbackLocalization from '../components/UserFeedback/localization/fr-FR';
import swaggerLocalization from '../components/Swagger/localization/fr-FR';
import securityFeatures from '../components/SecurityFeatures/localization/fr-FR';
import releaseNotesLocalization from '../components/ReleaseNotes/localization/fr-FR';
import endpointsLocalization from '../components/Endpoint/localization/fr-FR';
import singleEndpointLocalization from '../components/SingleEndpoint/localization/fr-FR';

export default {
	...automationLocalization,
	...sharedLocalization,
	...analyticsLocalization,
	...supportLocalization,
	...onboardingLocalization,
	...ticketingLocalization,
	...failoverLocalization,
	...reportsLocalization,
	...accountOverviewLocalization,
	...eulaLocalization,
	...analyticsChartsLocalization,
	...batchHistoryLocalization,
	...errorLocalization,
	...userFeedbackLocalization,
	...swaggerLocalization,
	...securityFeatures,
	...endpointsLocalization,
	...releaseNotesLocalization,
	...singleEndpointLocalization,
	// GLOBAL SETTINGS
	DISPLAY_DATE_FORMAT: 'MM/jj/aaaa',

	// SIDEBAR
	HOME: 'Accueil',
	ENDPOINTS: 'Points terminaux',
	Endpoints: 'Points terminaux',
	REPORTS: 'Rapports',
	INVOICES: 'Factures',
	AUTOMATION: 'Automatisation',
	PLATFORM_STATUS: 'État plateforme',
	SUPPORT: 'Assistance',
	COLLAPSE_SIDEBAR: 'Réduire barre latérale',
	COMPANIES: 'Sociétés',
	FAQ: 'Foire aux questions',
	HELP_SUPPORT: 'Aide et assistance',
	BATCH_UPDATES: 'Mises à jour de lots',
	FAQS: 'FAQ',
	RELEASE_NOTES: 'Notes de version',
	SYSTEM_NOTIFICATIONS: 'Notifications système',
	ACCOUNT_SETTINGS: 'Paramètres du compte',
	COMPANY_ACCOUNTS: 'Compte société',
	CUSTOM_FIELDS: 'Champs personnalisés',
	COMPANY_DETAILS: 'Détails société',
	TESTING_ICON: 'Icône de test',
	ENTITIES: 'Entités',
	IOTGW_FAILOVER: 'Panne passerelle IoT',
	ACCESS_MANAGEMENT: 'Gestion de l’accès',
	SIM_ORDERS: 'Commandes de SIM',
	USER_MANAGEMENT: 'Gestion utilisateur',

	// TOPBAR
	MORE_IOT: 'PLUS sur IoT',
	MORE_ATT: 'PLUS sur AT&T',
	LEARN_ABOUT_OFFERS: 'En savoir plus sur les offres',
	BUY_DEVICES: 'Acheter appareils',
	ATT_SERVICES: 'Services AT&T',
	USE_TOOLS_APPS: 'Utiliser outils et apps',
	IOT_PLATFORMS_PRODUCTS_APPS_AND_APIS: 'Plateformes, produits, apps et API IoT',
	AMOC: 'AMOC',
	M2X: 'M2X',
	FLOW_DESIGNER: 'Concepteur de flux',
	IOT_STARTER_KITS: 'Kits démarrage IoT',
	IOT_DATA_PLANS: 'Forfait données IoT',
	ENTERPRISE: 'Entreprise',
	BUSINESS: 'Entreprise',
	CONSUMER: 'Consommateur',

	// PROFILE MENU
	PROFILE: 'Profil',
	APPS: 'Apps',
	LOGOUT: 'Déconnexion',
	LOG_IN: 'Connexion',

	// FOOTER
	IOT_CUSTOMER_SOLUTIONS: 'Solutions client IoT',
	IOT_PLATFORM_AND_SERVICES: 'Plateforme et services IoT',
	NETWORK_SOLUTIONS: 'Solutions réseau',
	PARTNERSHIP_PROGRAM: 'Programme partenariat',
	BUSINESS_SUPPORT: 'Assistance entreprise',
	ABOUT_ATT: 'À propos d’AT&T',
	ABOUT_ATT_IOT: 'À propos d’AT&T IoT',
	NEWS: 'Nouvelles',
	NETWORKING_EXCHANGE_BLOG: 'Blogue échange et réseautage',
	BUSINESS_COMMUNITY: 'Communauté d’entreprises',
	YOUTUBE_CHANNEL: 'Chaîne YouTube',
	LEGAL_PRIVACY_ADVERTISING_ACCESSIBILITY: 'Juridique, confidentialité, publicité, accessibilité',
	LEGAL_POLICY_CENTER: 'Centre des politiques juridiques',
	PRIVACY_POLICY: 'Politique de confidentialité',
	TERMS_OF_USE: 'Conditions générales d’utilisation',
	BROADBAND_DETAILS: 'Détails largeur de bande',
	YOUR_CHOICES: 'Vos choix',
	ADVERTISING_CHOICES: 'Choix publicité',
	ACCESSIBILITY: 'Accessibilité',
	COPYRIGHT_ATT_INTELECTUAL_PROPERTY: '©{year} Propriété intellectuelle AT&T. ',
	COPYRIGHT_MESSAGE: 'Tous droits réservés. AT&T, le logo du globe, Mobilizing Your World et DIRECTV sont des marques déposées de propriété intellectuelle d’AT&T ou de ses sociétés affiliées. Toutes les autres marques appartiennent à leurs propriétaires respectifs.',
	CONNECT_WITH_ATT: 'Se connecter avec AT&T',
	CURRENT_SESSION_USAGE: 'Utilisation session actuelle',
	ENDPOINT_NETWORK_AND_LOCATION: 'Réseau et emplacement point terminal',

	// NETWORK ENTITLEMENTS
	ENDPOINT_DETAILS_SETUP: 'Configuration détails point terminal',
	NETWORK_ENTITLEMENT_DETAILS: 'Détails des droits d’utiliser le réseau',
	CHANGE_NETWORK_ENTITLEMENTS: 'Changer les droits d’utiliser le réseau',
	CONFIRM_NETWORK_ENTITLEMENT_CHANGE: 'Confirmer le changement de droits d’utiliser le réseau',
	NETWORK_ENTITLEMENT_CHANGE_STATUS: 'État du changement de droits d’utiliser le réseau',
	NETWORK_ENTITLEMENT_CHANGE_TOOLTIP_SUPPORT: 'Erreur de droits d’utiliser le réseau, veuillez contacter l’assistance à la clientèle.',
	NETWORK_ENTITLEMENT_CHANGE_NOT_ALLOWED: 'Désolé, le changement de droits d’utiliser le réseau pour des points terminaux multiples n’est pas autorisé pour les points terminaux ayant des types de réseau ou des fournisseurs de services différents',
	SCHEDULE_NETWORK_ENTITLEMENT_SUCCESSFUL: 'Programmation de droits d’utiliser le réseau pour plusieurs SIM réussie',
	SIMS_NETWORK_ENTITLEMENT_SUCCESSFULLY_UPDATED: 'Mise à jour de droits d’utiliser le réseau SIM réussie',
	SIM_NETWORK_ENTITLEMENT_CHANGE_FOR: 'Changement de droits d’utiliser le réseau SIM pour ',
	MULTIPLE_NETWORK_ENTITLEMENT_CHANGE_SCHEDULE_ERROR: 'Échec de la programmation du changement de droits d’utiliser le réseau pour plusieurs SIM',
	MULTIPLE_NETWORK_ENTITLEMENT_CHANGE_ERROR: 'Échec de la demande de changement de droits d’utiliser le réseau pour plusieurs SIM',
	states: 'État',
	networkOperators: 'Opérateur réseau',
	countries: 'Pays réseau',
	SELECT_NETWORK_ENTITLEMENT: 'Sélectionner droit d’utiliser le réseau',
	ACCOUNT_NAME: 'Nom de compte',
	APN: 'APN',
	DESCRIPTION: 'Description',
	ROAMING: 'Itinérance',
	CREATE_DATE: 'Date création',
	LAST_UPDATE_DATE: 'Date dernière mise à jour',
	APN_LTE: 'APN LTE',
	SMS_MT: 'SMS arrivée',
	SMS_MO: 'SMS départ',
	VOICE_MO: 'Voix départ',
	VOICE_MT: 'Voix arrivée',
	INTERNATIONAL_VOICE: 'Voix international',
	FIXED_IP_ENABLED: 'IP fixe activée',
	LTE_DATA: 'Données LTE',
	LTE_ROAMING_RESTRICT_SET_ID: 'Itinérance LTE',
	LTE_APNS: 'APN LTE',
	CAMEL_SUBSCRIPTION_INFO: 'Camel',
	CAMEL_SERVICE_TYPE: 'Type de service Camel',
	M2M_PLATFORM: 'Plateforme machine-machine',
	APNS_ID: 'ID APN',
	APNS_NAME: 'Nom',
	APNS_LTE: 'LTE',
	APNS_PDPINDEX: 'Indice PDP',
	APNS_DESCRIPTION: 'Description APN',
	APNS_FIXEDIP: 'IP fixe',
	APNS_SECURITY: 'Sécurité',
	APNS_SUBSCRIPTION: 'Abonnement',
	APNS_CREATEDATE: 'Date création',
	APNS_LASTUPDATEDATE: 'Date dernière mise à jour',
	APNS_LASTUSER: 'Dernier utilisateur',
	APNS_APNTYPE: 'Type APN',
	APNS_CREATIONTIMESTAMP: 'Création horodatage',
	APNS_BMSYNCSTATUS: 'État Sync BM',
	APNS_HLRPROFILEREFERENCE: 'Référence profil HLRHSSDetails',
	APNS_IPADDRESSALLOCATIONTYPE: 'Type attribution adresse IP',
	APNS_ISLOCALBREAKOUTENABLEDINDICATOR: 'Indicateur « Local Breakout est-il activé »',
	APNS_LASTUPDATETIMESTAMP: 'Dernière mise à jour horodatage',
	APNS_OUCCONNECTIONTYPE: 'Type connexion OUC',
	APNS_SECURITYPROFILESUPPORTEDINDICATOR: 'Indicateur profil de sécurité pris en charge',
	APNS_STATUS: 'État',
	APNS_VPNENABLEDINDICATOR: 'Indicateur VPN activé',
	CURRENTLY_ASSIGNED_TO_THIS_ENDPOINT: 'Actuellement affecté à ce point terminal',
	CURRENTLY_ASSIGNED_TO_SELECTED_ENDPOINTS: 'Actuellement affecté aux points terminaux sélectionnés',
	ELIGIBLE_TO_USE_WITH_SELECTED_ENDPOINTS: 'Utilisation admissible avec les points terminaux sélectionnés',
	ELIGIBLE_TO_USE_WITH_THIS_ENDPOINT: 'Utilisation admissible avec ce point terminal',
	ELIGIBLE_NETWORK_ENTITLEMENTS: 'Droits d’utiliser le réseau admissibles',
	NETWORK_ENTITLEMENT_CHANGE_IS_SUCCESSFULLY_SUBMITTED: 'Envoi réussi du changement de droits d’utiliser le réseau',
	NETWORK_ENTITLEMENT_CHANGE_COULD_NOT_BE_CARRIED_OUT_SUCCESSFULLY: 'Le changement de droits d’utiliser le réseau n’a pas pu être exécuté.',
	NETWORK_ENTITLEMENT_CHANGE_IS_SUCCESSFULLY_SCHEDULED: 'Programmation réussie du changement de droits d’utiliser le réseau',
	NETWORK_ENTITLEMENT_CHANGE_IS_SUCCESSFULLY_SCHEDULED_FOR: 'Programmation réussie du changement de droits d’utiliser le réseau pour ',
	NETWORK_ENTITLEMENT_CHANGE_COULD_fNOT_BE_SCHEDULED_SUCCESSFULLY: 'Le changement de droits d’utiliser le réseau n’a pas pu être programmé.',
	ONCE_YOU_INITIATE_THIS_CHANGE: 'Après l’exécution de ce changement, le droit d’utiliser le réseau des points terminaux sélectionnés sera ',
	WOULD_YOU_LIKE_TO_PROCEED_WITH_THIS_ACTION: 'Souhaitez-vous exécuter cette action ?',
	OR_YOU_CAN_SCHEDULE_THE_CHANGE_FOR: 'Vous pouvez aussi programmer le changement pour : ',
	FRIENDLY_NAME: 'Diminutif',
	NAME: 'Nom',
	// Détails des droits d’utiliser le réseau
	APN_DETAILS_ID: 'ID',
	APN_DETAILS_NAME: 'Nom',
	APN_DETAILS_LTE: 'LTE',
	APN_DETAILS_PDPINDEX: 'Indice PDP',
	APN_DETAILS_DESCRIPTION: 'Description',
	APN_DETAILS_FIXEDIP: 'IP fixe',
	APN_DETAILS_SECURITY: 'Sécurité',
	APN_DETAILS_SUBSCRIPTION: 'Abonnement',

	// BUTTON LABELS
	CANCEL: 'Annuler',
	CHANGE: 'Changer',
	TROUBLESHOOT: 'Dépanner',
	CHANGE_STATUS: 'Changer l’état',
	CLOSE: 'Fermer',
	APPLY_CHANGE: 'Appliquer changement',
	DONE: 'Terminé',
	SAVE: 'Enregistrer',
	CONTINUE: 'Continuer',
	SAVEDASHBOARD: 'Enregistrer tableau de bord',
	SAVE_AND_CONTINUE: 'Enregistrer et continuer',
	SAVE_CHANGES: 'Enregistrer changements',
	PULL_DATA: 'Extraire données',
	I_DO_NOT_AGREE: 'Je n’accepte pas',
	I_AGREE: 'J’accepte',
	LOADING: 'Chargement',
	UPLOAD_LOGO: 'Téléverser logo',
	ADD_NEW_CUSTOMER: 'Ajouter nouveau client',
	EDIT: 'Modifier',
	DELETE: 'Supprimer',
	NEXT: 'Suivant',

	// NOTIFICATIONS
	ENDPOINT_ID: 'ID point terminal',
	PROVISIONING_ERROR: 'Erreur de provisionnement',
	FAILED_TO_PROVISION_AFTER_IMSI_STATUS_CHANGE: ' échec du provisionnement après le changement d’état IMSI. ',
	GET_SUPPORT: 'Obtenir assistance',
	PROVISIONING_SUCCESS: 'Provisionnement réussi',
	IS_SUCCESSFULLY_PROVISIONED: 'a bien été provisionné.',
	IMSI_STATUS_IS_CHANGED_TO: 'État IMSI changé à ',
	PENDING_STATUS_CHANGE: 'Changement en attente !',
	STATUS_CHANGE_FOR: 'Il y a une activité programmée pour ce point terminal',
	IS_PENDING: ' est en attente',
	CUSTOM_FIELD_EDIT_SUCCESS: 'Modification réussie du champ personnalisé',
	CUSTOM_FIELD_EDIT_ERROR: 'Erreur de modification du champ personnalisé',
	CUSTOM_FIELD_VALUES_UPDATED: 'Mise à jour réussie d’un ou plusieurs champs personnalisés',
	CUSTOM_FIELD_VALUES_UPDATED_ERROR: 'Impossible de mettre à jour un ou plusieurs champs personnalisés. Veuillez réessayer.',
	CUSTOM_FIELD_LABEL_SUCCESS_TITLE: 'Modification réussie de l’étiquette de champ personnalisé !',
	CUSTOM_FIELD_LABEL_SUCCESS_MESSAGE: 'Mise à jour réussie d’une ou plusieurs étiquettes de champ personnalisé',
	CUSTOM_FIELD_LABEL_ERROR_TITLE: 'Erreur de modification de l’étiquette de champ personnalisé !',
	CUSTOM_FIELD_LABEL_ERROR_MESSAGE: 'Impossible de mettre à jour une ou plusieurs étiquettes de champ personnalisé. Veuillez réessayer.',
	TABLE_COLUMNS_CHANGE_SUCCESS_TITLE: 'Changement réussi des paramètres de la table du point terminal !',
	TABLE_COLUMNS_CHANGE_SUCCESS_MESSAGE: 'Mise à jour réussie des paramètres de la table du point terminal !',
	TABLE_COLUMNS_CHANGE_ERROR_TITLE: 'Erreur de changement des colonnes de la table du point terminal !',
	TABLE_COLUMNS_CHANGE_ERROR_MESSAGE: 'Impossible de mettre à jour les colonnes de la table du point terminal. Veuillez réessayer.',
	MC10515: 'Nom du fichier erroné',
	MC10516: 'Erreur de données fichier',
	MC10517: 'Le fichier ne contient pas l’en-tête ou il est vide',
	DOWNLOAD_REPORT_ERROR_TITLE: 'Erreur de téléchargement',
	DOWNLOAD_REPORT_ERROR_MESSAGE: 'Erreur lors de l’essai du téléchargement de votre fichier',
	FETCH_REPORT_ERROR_TITLE: 'Aucune donnée',
	FETCH_REPORT_ERROR_MESSAGE: 'Aucune donnée ne correspond à la requête recherche/filtre',
	MARK_READ: 'Marquer lu',
	VALIDATION_FAILED: 'Échec de la validation',
	ACTION_COMPLETED_SUCCESSFULLY: 'Exécution de l’action réussie',
	ACTION_COULD_NOT_BE_COMPLETED: 'Impossible d’exécuter cette action',

	// SINGLE ENDPOINT
	ENDPOINT_DETAILS: 'Détails point terminal',
	DETAILS: 'Détails',
	DIAGNOSTIC: 'Diagnostic',
	CHANGES: 'Changements',
	SESSIONS: 'Sessions',
	END_USER: 'Utilisateur final',
	LIFE_CYCLE: 'Cycle de vie',
	SEND_SMS: 'Envoyer SMS',
	OTA: 'OTA',
	RATING: 'Tarification',
	CARRIER_INFORMATION: 'Renseignements opérateur',
	M2M_PLATFORM_STATUS_DESCRIPTION: 'Description état plateforme machine-machine',
	VIEW_NOTE: 'Afficher note',
	SE_NOTES: 'Notes',
	CHANGE_NOTES: 'Changer notes',
	ENTER_NOTE_HERE: 'Saisir la note ici (2 000 caractères max.)',
	ADD_NOTE_BELOW: 'Ajouter note ci-dessous : ',
	CHANGING_NOTES: 'Changement des notes',
	MULTIPLE_NOTES_CHANGE_ERROR: 'Échec du changement des notes pour plusieurs points terminaux',
	CHANGING_NOTES_NOT_ALLOWED: 'Changement des notes non autorisé',
	ENDPOINTS_SUCCESSFULLY_CHANGED: 'Changement réussi des points terminaux',
	ENDPOINT_DETAILS_SECOND_CARD_NO_DATA: 'Données provisoirement non disponibles pour ce point terminal',

	// DATA OVERVIEW
	ICCID: 'Identifiant de carte SIM',
	IMSI: 'IMSI',
	NETWORK_OPERATOR: 'Opérateur réseau',
	NETWORK_COUNTRY: 'Pays réseau',
	MSISDN: 'Nº réseau numérique à intégration de services d’abonné mobile',
	DUAL_MODE: 'Bimode',
	CONNECTED_DEVICE: 'Appareil connecté',
	IMEI: 'IMEI',
	STATUS: 'État',
	STATUS_CHANGE_PENDING: 'Il y a une activité programmée pour ce point terminal',
	RATE_PLAN: 'Forfait',
	NETWORK_ENTITLEMENT: 'Droit d’utiliser le réseau',
	SOURCE_PLATFORM: 'Plateforme machine-machine',
	SERVICE_PROVIDER: 'Plateforme machine-machine',
	NETWORK_ENTITLEMENT_ID: 'ID droit d’utiliser le réseau',

	// ENDPOINT STATUS
	CURRENT_ENDPOINT_STATUS: 'État du point terminal actuel',

	// Changer paramètre SIM modal
	CHANGE_SIM_PARAMETER: 'Changer paramètre SIM',
	SELECT_ATT_STATE: 'SÉLECTIONNER ÉTAT AT&T',
	CURRENT: 'ACTUEL',

	BY: 'Par ',
	SCHEDULING: 'Programmation en cours…',

	// EDIT TABLE MODAL
	EDIT_TABLE_PARAMETERS: 'Modifier paramètres de la table',
	EDIT_CUSTOM_FIELD_LABELS: 'Modifier étiquettes de champ personnalisées',
	CUSTOM_LABEL: 'Étiquette personnalisée {labelIndex}',
	VALUE: 'Valeur',
	SET_AS_REQUIRED: 'Définir tel que requis',
	SELECT_A_MINIMUM_OF_4_TO_APPLY_TO_THE_TABLE: 'Sélectionner un minimum de 2 à appliquer à la table. ',
	SELECTED: ' sélectionnés',
	CLEAR_ALL: 'Remettre à la valeur par défaut',

	EOD: 'Enterprise on Demand (EOD)',
	ACC: 'Centre de contrôle AT&T (ACC)',
	GBCM: 'Speedcast',
	MC: 'IoT Console (IOT-C)',
	DCP: 'Plateforme de connexion d’appareils',
	CCIP: 'Programme d’intégration du centre de contrôle (CCIP)',
	AIS: 'AIS',
	TWM: 'Taiwan Mobile (TWM)',
	ATT: 'AT&T',
	AUTOMATION_ACC: 'ACC',
	AUTOMATION_DCP: 'Plate-forme de connexion d’appareils',
	// AT&T : 'AT&T',
	WING: 'Nokia (WING)',
	VIVO: 'Centre de contrôle Vivo',

	eod: 'Enterprise on Demand (EOD)',
	acc: 'Centre de contrôle AT&T (ACC)',
	gbcm: 'Speedcast',
	mc: 'IoT Console (IOT-C)',
	dcp: 'Plateforme de connexion d’appareils',
	ccip: 'Programme d’intégration du centre de contrôle (CCIP)',
	ais: 'AIS',
	twm: 'Taiwan Mobile (TWM)',
	att: 'AT&T',
	// at&t : 'AT&T',
	wing: 'Nokia (WING)',
	vivo: 'Centre de contrôle Vivo',

	SELECTED_FILTERS: 'Filtres sélectionnés',

	BY_WEEK: 'Par semaine',
	BY_DAY: 'Par jour',
	BY_HOUR: 'Par heure',
	BYTES_SENT: 'Mo envoyés',
	BYTES_RECEIVED: 'Mo reçus',
	TOTAL_MINS: 'Total minutes',
	monthly: 'Mensuel',
	daily: 'Quotidien',
	MONTHLY: 'Mensuel',
	DAILY: 'Quotidien',

	// ACTION BAR
	SHOWING: 'Montre ',
	OF: ' de ',

	// REPORTS
	TRANSFER: 'Transfert',
	USAGE: 'Utilisation',
	DOWNLOAD: 'Téléchargement',
	OTHER: 'Autre',

	// INPUT ERRORS
	REQUIRED: 'Requis',
	INVALID_COMPANY_NAME: 'Nom de société non valide',
	INVALID_EMAIL_ADDRESS: 'Adresse e-mail non valide',
	PHONE_MUST_BE_A_NUMBER: 'N° tél. doit être un nombre',
	WRONG_PHONE_FORMAT: 'Mauvais format de n° tél.',
	ONLY_LETTERS_AND_NUMBERS_ARE_ALLOWED: 'Seuls les caractères alphanumériques sont autorisés',
	ONLY_LETTERS_ARE_ALLOWED: 'Seules les lettres sont autorisées',
	ONLY_LETTERS_AND_SPACES_ARE_ALLOWED: 'Seuls les lettres et les espaces sont autorisées',
	ONLY_NUMBERS_ARE_ALLOWED: 'Seuls les chiffres sont autorisés',
	ONLY_POSITIVE_NUMBERS_ARE_ALLOWED: 'Seuls les nombres supérieurs à 0 sont autorisés',
	NUMBER_HAS_TO_BE_LESS_THAN: 'Le nombre doit être inférieur à {propName}',
	NUMBER_HAS_TO_BE_LESS_THAN_OR_EQUAL_TO: 'Le nombre doit être inférieur ou égal à {propName}',
	NUMBER_HAS_TO_BE_GREATER_THAN: 'Le nombre doit être supérieur à {propName}',
	NUMBER_HAS_TO_BE_GREATER_THAN_OR_EQUAL_TO: 'Le nombre doit être supérieur ou égal à {propName}',
	NUMBER_HAS_TO_BE_IN_INCREMENTAL_STEPS: 'Le nombre doit être augmenté par incréments de {increment}',
	INVALID_FORMAT: 'Format non valide',
	MAX_CHARACTERS_EXCEEDED: 'Nbre limite de caractères dépassé',
	MUST_BE_LESS_THAN_OR_EQUAL_TO_CHARACTERS: 'Nbre de caractères doit être inférieur ou égal à {max}',
	MUST_BE_CHARACTERS_LONG: 'Doit comprendre {number} caractères',
	NO_ONLY_WHITE_SPACE: 'Ne peut pas être uniquement composé d’espaces',
	OUT_OF_RANGE: 'Hors plage',
	NOT_BEFORE: 'La date ne peut être antérieure à {date}',
	INVALID_VERSION: 'Format uniquement permis majeur.mineur.correctif, c.-à-d. x.y.z',

	/* ------ OLD BUT STILL WORKING - DO NOT TOUCH - WORK IN PROGRESS --------- */
	ACCOUNT_OVERVIEW: 'Aperçu du compte',
	ACCOUNT_OVERVIEW_ADMIN_DASHBOARD: 'Tableau de bord adm Aperçu du compte',
	BILLING: 'Facturation',

	LOG_OUT: 'Déconnexion',

	DATA_PLAN: 'Forfait données',
	ACTIVESESSION: 'SESSION ACTIVE',
	IPADDRESS: 'ADRESSE IP',
	ANALYTICS: 'Analyse',
	NETWORK: 'RÉSEAU',

	ASSET_ID: 'ID ressource',
	SESSION_START: 'Démarrage session',
	DATA_VOLUME: 'Volume données',
	DURATION: 'Durée (min)',
	'DATE&TIME': 'Date et heure',
	NOTE: 'Note',
	LOGINTITLE: 'Connexion',
	LOGINSUBTITLE: 'Veuillez saisir vos adresse e-mail et mot de passe.',
	ENDPOINTS_INVENTORY_STATUS: 'ÉTAT INVENTAIRE DES POINTS TERMINAUX',
	INVENTORY_BY_CARRIER: 'INVENTAIRE PAR OPÉRATEUR',
	INVENTORY_BY_COUNTRY: 'INVENTAIRE PAR PAYS',
	ACCOUNT_USERS: 'UTILISATEURS DU COMPTE',
	USER_AUTOMATION_STATUS: 'ÉTAT AUTOMATISATION UTILISATEUR',
	AUTOMATION_PROCESS_STATUS: 'ÉTAT PROCESSUS AUTOMATISATION',
	NETWORK_TYPE: 'Type de réseau',
	DEVICE_NAME: 'Nom de l’appareil',
	DEVICE_ID: 'ID appareil',
	DEVICE_MODEL_NUMBER: 'N° modèle appareil',
	ROAMING_RESTRICTED: 'ITINÉRANCE RESTREINTE',
	INVOICE_NUMBER: 'NUMÉRO DE FACTURE',
	DATE: 'Date',
	SCHEDULE_DATE: 'Date',
	ISSUED_BY: 'ÉMIS PAR',
	PAYMENT_STATUS: 'ÉTAT PAIEMENT',
	REPORT_NAME: 'Nom du rapport',
	DATE_CREATED: 'Date création',
	CREATED_BY: 'Créé par',
	PAY_NOW: 'PAYER MAINTENANT',
	EDIT_GRAPHS_VIEW: 'Modifier vue des graphiques',
	EDIT_ENDPOINTS_LIST_VIEW: 'Modifier vue liste points terminaux',
	CHANGE_LANGUAGE: 'Changer la langue',
	CHANGE_LANGUAGE_ICON: 'ANGLAIS',
	NOT_APPLICABLE_FOR_THIS_ENDPOINT: 'Non applicable à ce point terminal',
	CREATE_NEW_RULE: 'Créer nouvelle règle',
	EDIT_RULE: 'Modifier règle',
	CREATE_NEW_RULE_EXP: 'L’automatisation vous permet d’exécuter des actions en bloc sur votre compte.',
	CREATE_NEW_RULE_ACTION: 'Veuillez sélectionner le bouton « Créer nouvelle règle » pour créer une nouvelle action automatisée',
	RULE_NAME: 'Nom de la règle',
	CATEGORY: 'Catégorie',
	TRIGGER: 'Déclencheur',
	ACTION: 'ACTION',
	BULK_ACTIONS: 'Actions en bloc',
	MULTI_ENDPOINT_ACTIONS: 'Actions sur plusieurs points terminaux',
	SET_ENDPOINT_STATE: 'Définir état point terminal',
	CHANGE_DATA_PLAN: 'Changer forfait données',
	SWAP_OPERATOR: 'Changer opérateur',
	MULTI_EDIT: 'Modifications multiples',
	SEARCH_FOR_RULES: 'Rechercher règles',
	SEARCH_FOR_INVOICES: 'Rechercher factures',
	SEARCH_FOR_REPORTS: 'Rechercher rapport par nom',
	SEARCH_ENDPOINTS: 'Rechercher points terminaux',
	PAYABLE_INVOICES: 'FACTURES EXIGIBLES',
	RECEIVED_PAYMENTS: 'PAIEMENTS REÇUS',
	PAYMENT_METHODS: 'MÉTHODES DE PAIEMENT',
	BILLERS: 'FACTURIERS',
	TIME_PERIOD: 'Période',
	FROM_DATEPICKER: 'De',
	TO_DATEPICKER: 'À',
	ENDPOINT_UI_TOOLTIP: 'N° identification de point terminal ou d’appareil',
	OPERATOR_TOOLTIP: 'Opérateur auquel le point terminal et le profil appartiennent',
	NETWORK_TYPE_TOOLTIP: 'Technologie de connectivité de l’opérateur',
	COUNTRY_TOOLTIP: 'Pays d’origine du point terminal',
	ROAMING_RESTRICTED_TOOLTIP: 'Si l’itinérance du point terminal est restreinte',
	STATUS_TOOLTIP: 'État actuel du point terminal',
	INITIATE_RULE: 'APPLIQUER RÈGLE',
	NEW_AUTOMATION_RULE: 'NOUVELLE RÈGLE D’AUTOMATISATION',
	ENDPOINT_PROVISIONING: 'PROVISIONNEMENT DU POINT TERMINAL',
	ENDPOINT_PROFILE: 'PROFIL POINT TERMINAL',
	ENDPOINT_SECURITY: 'SÉCURITÉ POINT TERMINAL',
	ENDPOINT_SUBSCRIPTION: 'ABONNEMENT POINT TERMINAL',
	NEW_RULE: 'NOUVELLE RÈGLE',
	CONDITION: 'CONDITION',
	ENDPOINT_RANGE: 'Portée du point terminal :',
	RANGE_START: 'DÉBUT PORTÉE',
	RANGE_END: 'FIN PORTÉE',
	ASSIGN_ENDPOINT: 'Affecter point terminal à l’opérateur',
	OPERATOR_NAME: 'NOM DE L’OPÉRATEUR',
	ACCOUNT: 'COMPTE',
	ADD_ACTION: 'AJOUTER ACTION',
	RUN_IMMEDIATELY: 'EXÉCUTER IMMÉDIATEMENT',
	TIME: 'HEURE',
	SELECT_DATE: 'SÉLECTIONNER DATE',
	SELECT_TIME: 'SÉLECTIONNER HEURE',
	EVENT_TRIGGER: 'DÉCLENCHEUR D’ÉVÉNEMENT',
	EVENT_TYPE: 'TYPE ÉVÉNEMENT',
	EVENT_LOCATION: 'LIEU ÉVÉNEMENT',
	DATA_PLAN_NAME: 'NOM FORFAIT DONNÉES',
	LOGIN_VALIDATION_WRONG_CREDENTIALS_HEADER: 'Authentifiants inexacts !',
	LOGIN_VALIDATION_WRONG_CREDENTIALS_BODY: 'Le mot de passe ne correspond pas à l’adresse e-mail fournie.',
	EMAIL: 'E-mail',
	PASSWORD: 'mot de passe',
	FORGOT_PASSWORD: 'Mot de passe oublié ?',
	REMEMBER_ME: 'Mémoriser mes informations',
	LOGIN_BUTTON: 'CONNEXION',
	BACK: 'Retour',
	ENDPOINT_USAGE: 'Utilisation point terminal',
	COUNTRY_AND_OPERATOR: 'Pays et opérateur',
	PAYMENTS_HISTORY: 'Historique des paiements',
	SPENDING_THIS_YEAR: 'Dépenses cette année',
	ENDPOINT_DATA: 'DONNÉES POINT TERMINAL',
	CURRENT_SESSION: 'SESSION ACTUELLE',
	VIEW_LESS: 'Afficher moins',
	VIEW_MORE: 'Afficher plus',
	ENDPOINT_STATUS: 'État du point terminal',
	ACTIVATION_DATE: 'Date d’activation',
	PRIMARY_IMS: 'IMS principal',

	SERVICE_PROVIDER_MODE: 'MODE FOURNISSEUR DE SERVICES',
	SUPER_USER_MODE_MESSAGE: '{mode}: {companyFriendlyName}.',
	SUPER_USER_MODE_MOBILE: '{companyFriendlyName}',
	POLICY: 'Politique',
	COMMUNICATION_PLAN: 'Forfait communication',
	COPY_RULE: 'Copier règle',
	SECURE_ENDPOINT_ID: 'Sécuriser ID point terminal',
	CUSTOMER: 'Client',
	INVENTORY_STATUS: 'ÉTAT DE L’INVENTAIRE',
	RESOLUTION_NOT_SUPPORTED_FIRST_LINE: 'Affichage du petit écran désactivé dans la démo.',
	RESOLUTION_NOT_SUPPORTED_SECOND_LINE: 'Pour de meilleurs résultats, utilisez le navigateur Desktop Chrome maximisé ou le mode plein écran',
	CHECKING_MOVE_ELIGIBILITY: 'VÉRIFICATION ADMISSIBILITÉ AU TRANSFERT',
	CHANGE_OPERATOR: 'CHANGER OPÉRATEUR',
	SCHEDULE_CHANGE: 'PROGRAMMER CHANGEMENT',
	SCHEDULE_CHANGE_BUTTON: 'Programmer changement',
	INITIATE_CHANGE: 'Lancer changement',
	CONFIRM_CHANGE: 'Confirmer changement',
	CONFIRM_SIM_STATE_CHANGE: 'Confirmer changement état SIM',
	IN_SESSION: 'EN SESSION',
	ENTITLEMENTS: 'Droits',
	LOCALIZE_SERVICE: 'Localiser le service',
	CHANGE_ENDPOINT_OPERATOR: 'CHANGER OPÉRATEUR DU POINT TERMINAL',
	PENDING_CHANGES_ENDPOINT: 'Des changements sont en attente pour ce point terminal à partir de ',
	SEE_HISTORY_FOR_DETAILS: 'Pour les détails, voir l’historique ci-dessous.',
	CHANGE_ENDPOINT_DATA_PLAN: 'Changer forfait données du point terminal',
	IS_SELECTED: ' est sélectionné',
	PLEASE_SELECT_DIFFERENT_OPERATOR_TO_CHANGE_OPERATOR: 'Sélectionnez un autre opérateur ci-dessous pour changer opérateur :',
	GENERATE_REPORT: 'GÉNÉRER RAPPORT',
	REPORT_TYPE: 'TYPE RAPPORT',
	DATA_PLAN_SUMMARIES: 'Synthèses forfaits données',
	CHARGE_SUMMARIES: 'Synthèses des frais',
	INVOICE_REPORT: 'Rapport de factures',
	PRORATION_REPORT: 'Rapports calculs proportionnels',
	GROSS_ACTIVATIONS: 'Activations brutes',
	MONTHLY_SUBSCRIBERS: 'Abonnés mensuels',
	VIEW_YOUR_ACCOUT_SNAPSHOT: 'Afficher aperçu de votre compte.',
	LAUNCH_DASHBOARD: 'LANCER LE TABLEAU DE BORD',
	VISUAL_DASHBOARD_VIEW_EXP: 'La vue du tableau de bord visuel fournit un aperçu de l’état actuel de l’activité de votre compte et de vos appareils.',
	VIEW_CURRENT_STATUS_AND_HISTORICAL_DATA: 'Afficher l’état actuel et les données historiques dans l’ordre chronologique.',
	IF: 'SI',
	AND: 'ET',
	OR: 'OU',
	TO: 'AU',
	FROM: 'DU',
	INVOICES_STATUS: 'ÉTAT FACTURES',
	filter_category_m2mAccount: 'M2mAccount',
	PLEASE_CONFIRM_ENDPOINT_DATA_CHANGE: 'Veuillez confirmer le changement données point terminal.',
	SET_NEW_OPERATOR_AS_ACTIVE: 'DÉFINIR LE NOUVEL OPÉRATEUR À ACTIF',
	SET_NEW_PLAN_AS_ACTIVE: 'DÉFINIR LE NOUVEAU FORFAIT À ACTIF',
	PLEASE_SELECT_DATE_TO_SET_UP_YOUR_CHANGES_AND_CONFIRM: 'Veuillez sélectionner la date pour appliquer vos changements et confirmer.',
	DATE_REQUIRED: 'Date (requise)',
	ADDITIONAL_COMMENT: 'Commentaire supplémentaire',
	TYPE_YOUR_CUSTOM_NOTES_HERE: 'Saisissez vos notes personnalisées ici…',
	CONFIRM_CHANGES: 'Confirmer changements',
	CHECKING_MOVE_ELIGIBILITY_LOADER: 'Vérification de l’admissibilité au transfert',
	ELIGIBLE: 'ADMISSIBLE',
	CHECKING: 'VÉRIFICATION',
	PLEASE_SELECT_DIFFERENT_DATA_PLAN_TO_CHANGE_DATA_PLAN: 'Sélectionnez un autre forfait ci-dessous pour changer de forfait :',
	CONFIRM: 'Confirmer',
	ENDPOINT_RANGE_IS: 'Portée du point terminal :',
	ENDPOINT_ID_IS: 'ID point terminal :',
	ENDPOINT_ID_IS_GREATER_THAN: 'ID point terminal est supérieur à :',
	ENDPOINT_ID_IS_LESS_THAN: 'ID point terminal est inférieur à :',
	ENDPOINT_STATE_CHANGE: 'Changement d’état du point terminal',
	ENDPOINT_DATA_PLAN: 'Forfait données du point terminal',
	ENDPOINT_SECURITY_POLICY_CHANGE: 'Changement de politique de sécurité du point terminal',
	ASSIGN_ENDPOINT_TO_OPERATOR: 'Affecter point terminal à l’opérateur',
	MOVE_TO_ANOTHER_ACCOUNT: 'Transférer à un autre compte',
	ADD_CUSTOM_FIELD_TO_ENDPOINT_DETAILS: 'Ajouter un champ personnalisé aux détails du point terminal',
	CREATE_NEW_ACTION: 'Créer nouvelle action',
	AT_T: 'AT&T IoT (ACC)',
	AT_T_EOD: 'AT&T IoT (ACC)',
	GLOBECOMM: 'Speedcast',
	TAIWAN_MOBILE: 'Taiwan Mobile (TWM)',
	'0001': '***0001',
	'0002': '***0002',
	'0003': '***0003',
	'0004': '***0004',
	OTA_EVENT: 'Événement OTA',
	WI_FI: 'Wi-Fi',
	THAILAND: 'Thaïlande (THA)',
	TAIWAN: 'Taïwan (TWN)',
	UNITED_STATES: 'États-Unis (USA)',
	DATA_PLAN_S: 'Forfait données S',
	DATA_PLAN_M: 'Forfait données M',
	DATA_PLAN_L: 'Forfait données L',
	DATA_PLAN_XL: 'Forfait données XL',
	// TO DO: Update Endpoint statuses to correct values
	ACTIVATED: 'Activé',
	'TEST READY': 'Prêt pour test',
	DEACTIVATED: 'Désactivé',
	PURGED: 'Purgé',
	RETIRED: 'Mis hors service',
	//
	USER: 'Utilisateur',
	ON: 'le',
	MADE: 'fait',
	CHANGE_STATUS_ACTION: 'Changer l’état',
	CHANGE_DATA_PLAN_ACTION: 'Changer forfait données',
	LOCALIZE_SERVICE_ACTION: 'Service localisé',
	IMSI_TRANSFER: 'État du transfert IMSI',
	TRANSFER_STATUS: 'État du transfert IMSI',
	COUNTRY_OPERATOR: 'Pays réseau',

	PROFILE_NAME: 'Nom de profil',
	NUMBER: 'Numéro',
	DATE_MODIFIED: 'Date modification',

	ENTITLEMENT_PLAN_ID: 'ID forfait droit',
	ENTITLEMENT_PLAN_NAME: 'Nom forfait droit',
	ACCOUNT_ID: 'ID compte',
	ACCOUNT_I_D: 'ID compte',
	IMEI_NAME: 'Nom IMEI',
	VIN: 'VIN',
	CHIP_ID: 'ID puce',
	URL_SP: 'URL SP',
	ADD_SINGLE_ENDPOINT: 'Ajouter un seul point terminal',
	UPLOAD_ICCID_BULK_FILE: 'Téléverser fichier en nombre ICCID',
	DOWNLOAD_BULK_FILE: 'Télécharger fichier en nombre',
	ENDPOINTS_SELECTED: 'points terminaux sélectionnés',
	SELECTED_ENDPOINTS: 'points terminaux sélectionnés',
	NEW_STATUS: 'Nouvel état',
	MULTIPLE_ENDPOINT_EDIT: 'Modification de plusieurs points terminaux',
	CLEAR_VALUES_FOR_ALL_ENDPOINTS: 'Effacer valeurs de tous les points terminaux',
	KEEP_ORIGINAL_ENDPOINT_VALUES: 'Conserver valeurs originales points terminaux',
	ACTIVE: 'Activé',
	INACTIVE: 'Inactif',
	TERMINATE: 'Résilié',
	TEST_READY: 'Prêt pour test',
	TEST: 'Test',
	SELECT_OPTION_OR_TYPE_IN: 'Sélectionner l’option ou la saisir',
	CLEAR_ALL_VALUES_FOR_ENDPOINTS: 'Effacer toutes les valeurs pour les points terminaux',
	BULK_STATUS_UPDATE: 'Mettre à jour état en bloc',
	TOTAL_ENDPOINTS: 'Total points terminaux',
	TOTAL_FILES: 'Total fichiers',
	ENDPOINT_STATUS_UPDATED: 'État point terminal mis à jour',
	MODIFIED_ENDPOINTS: 'Points terminaux modifiés',
	SOMETHING_WENT_WRONG: 'Un problème est survenu',
	ENDPOINT_STATUS_IS_UPDATING: 'Mise à jour état du point terminal en cours',
	PLEASE_WAIT: 'Veuillez patienter',
	SWAP_SIM: 'Changer de SIM',
	SWAP_SIMS: 'Changer de SIM',
	SIM: 'SIM',
	NEW: 'Nouvelle',
	OLD: 'Ancienne',
	CHANGE_ORDER: 'Changer l’ordre',
	ITEM: 'Article',
	ADDITIONAL_INFO: 'Info supplémentaire',
	UPDATE_LABEL: 'Mettre à jour étiquette',
	ENDPOINT_LABEL_IS_UPDATING: 'Mise à jour étiquette point terminal en cours',
	ENDPOINT_LABEL_UPDATED: 'Étiquette point terminal mise à jour',
	ENDPOINT_FIELDS_ARE_UPDATING: 'Mise à jour champs point terminal en cours',
	ENDPOINT_FIELDS_UPDATED: 'Champs point terminal mis à jour',
	EDIT_CUSTOM_FIELDS: 'Modifier champs personnalisés',
	EDIT_CUSTOM_LABEL: 'Modifier étiquette personnalisée',
	CHANGE_ENTITLEMENT: 'Changer le droit d’utiliser le réseau',
	CHANGE_NETWORK_ENTITLEMENT: 'Changer le droit d’utiliser le réseau',
	CURRENT_NETWORK_ENTITLEMENT: 'Droit d’utiliser le réseau actuel',
	NETWORK_ENTITLEMENT_UPDATED_SUCCESSFULLY: 'Mise à jour réussie du droit d’utiliser le réseau !',
	LIST_VIEW_UPDATED: 'Liste points terminaux mise à jour',
	NETWORK_ENTITLEMENT_UPDATING: 'Mise à jour du droit d’utiliser le réseau. Veuillez patienter',
	LIST_VIEW_UPDATING: 'Mise à jour liste points terminaux en cours. Veuillez patienter',
	NEW_NETWORK_ENTITLEMENT: 'Nouveau droit d’utiliser le réseau',
	NEW_NETWORK_ENTITLEMENT_DETAILS: 'Détails nouveau droit d’utiliser le réseau',
	TABLE_COLUMNS: 'Choisir le nombre de colonnes à afficher sur la table.',
	TABLE_COLUMNS_OR: 'Choisir le nombre de colonnes à afficher sur la table ou',
	COLUMN: 'Colonne',
	COLUMNS: 'Colonnes',
	SELECT: 'Sélectionner',
	DATA_TYPE: 'type données',
	ADVANCED_SEARCH: 'Recherche avancée',
	SEARCH_RESULTS: 'Résultats recherche',
	SUBMIT: 'Envoyer',
	SEARCH_PARAMETER: 'Paramètre de recherche',
	ADD_ANOTHER_SEARCH_PARAMETER: 'Ajouter autre paramètre de recherche',
	STATUS_DATE_RANGE: 'Plage de dates par état',
	SIM_STATUS_STATE: 'État SIM',
	NEW_PARAMETER_ACTION: 'Et / Ou',
	PLEASE_SELECT_VALUES_TO_FILTER_ENDPOINTS: 'Sélectionner dans paramètres disponibles, puis saisir critères pour filtrer points terminaux.',
	RESET_SEARCH: 'Réinitialiser recherche',
	QUICK_SEARCH: 'Recherche rapide',
	ORIGINAL_SETTINGS: 'Réinitialiser aux paramètres d’origine',
	MONTH: 'Mois',
	DAY: 'Jour',
	REFRESH_ENDPOINTS_PAGE: 'Actualiser la page/table points terminaux',
	CLEAR_SELECTED_FILTERS_TOOLTIP: 'Effacer filtres sélectionnés',
	SELECT_FILTERS_TOOLTIP: 'Sélectionner filtres',
	EDIT_TABLE_PARAMETERS_TOOLTIP: 'Modifier paramètres de la table',

	REPORT_DATE: 'Date',

	START_NEW_SEARCH: 'Commencer nouvelle recherche',
	SUCCESS: 'Réussi',
	FAILED: 'Échec',
	RESULT_ACTION: 'Action',
	REPORT_DETAILS: 'Détails du rapport',
	REPORT_LIST: 'Liste des rapports dans',
	VIEW_DETAILS: 'Afficher détails',
	DOWNLOAD_PDF: 'Télécharger',
	FILE_NAME: 'Nom du fichier',
	BATCH_FILES: 'Fichiers séquentiels',
	BATCH_FILE_NAME: 'Nom fichier séquentiel',
	BATCH_FILE_ACTIONS: 'Actions sur fichier séquentiel',
	BATCH_FILE_UPLOAD: 'Actions en bloc',
	CHANGE_SIM_STATE: 'Changer état SIM',
	SELECT_SIM_STATE: 'Sélectionner état SIM',
	SIMS_SELECTED: 'SIM sélectionnées',
	SIM_SELECTED: 'SIM sélectionnée',
	ONCE_YOU_INITIATE_CHANGE: 'Une fois le changement exécuté, les SIM sélectionnées seront',
	PROCEED_ACTION: 'Souhaitez-vous exécuter cette action ?',
	CAN_SCHEDULE: 'Vous pouvez aussi programmer le changement pour',
	CURRENT_STATES: 'États actuels',
	SCHEDULE_SUCCESSFUL: 'Programmation réussie du changement d’état de plusieurs SIM',
	SIM_STATE_CHANGE_FOR: 'Changement état de SIM pour',
	SIMS_IS_SUCCESSFULLY_SCHEDULED_FOR: 'Programmation réussie des SIM pour',
	SIMS_SUCCESSFULLY_REQUESTED: 'Demande réussie de changement d’état de SIM',
	MULTIPLE_SIM_STATE_CHANGE_SCHEDULE_ERROR: 'Échec de la programmation du changement d’état de plusieurs SIM',
	MULTIPLE_SIM_STATE_CHANGE_ERROR: 'Échec de la demande de changement d’état de plusieurs SIM',
	SELECTED_TIP: 'Astuce : si les SIM sélectionnées ont des états différents, vous ne pourrez changer que pour un état disponible pour toutes les SIM sélectionnées.',
	VIEW_RULES: 'Afficher les règles de changement d’état pour ce compte',
	BATCH_FILE_UPLOADING: 'Téléversement fichier séquentiel',
	DATE_UPLOADED: 'Date téléversement',
	SIMPLE_SEARCH: 'Recherche simple',
	NUMBER_OF_COLUMNS: 'Nombre de colonnes',
	SELECT_SEARCH_PARAMETER: 'Sélectionner paramètre de recherche',

	DROPDOWN_BACK_TO_BUSINESS: 'Retour à l’entreprise',
	'DROPDOWN_CONTACT_AT&T': 'Contacter AT&T IoT',
	DROPDOWN_MC: 'IoT Console',
	DROPDOWN_M2X: 'M2X',
	DROPDOWN_FD: 'Concepteur de flux',
	DROPDOWN_SK: 'Kit démarrage IoT',
	DROPDOWN_DP: 'Forfaits données IoT',
	MY_INFORMATION: 'Mes renseignements',
	MY_APPLICATIONS: 'Mes applications',
	TOPBAR_IDENTITY: 'AT&T IoT Identity',
	ERROR: 'Erreur',
	PAGE_NOT_FOUND_MESSAGE: 'Il semble que la page que vous recherchez soit introuvable.',
	'RETURN TO HOME PAGE': 'RETOUR À LA PAGE D’ACCUEIL',
	INTERNAL_SERVER_ERROR: 'Erreur serveur interne',
	ACTIVE_ENDPOINT_PROFILE: 'PROFIL POINT TERMINAL ACTIF',
	CREATE_OR_EDIT: 'Créer ou modifier étiquette personnalisée pour ce point terminal',
	CREATE_CUSTOM_LABELS: 'Créer étiquettes personnalisées',
	SNAP: 'Flûte',
	SNAP_ERROR_MESSAGE: 'Une erreur inconnue s’est produite. Si cette erreur persiste, veuillez contacter l’assistance.',

	// définitions des détails de rapport
	REPORT_DETAIL_DEFINITIONS: 'DÉFINITIONS DES DÉTAILS DE RAPPORT',
	REPORT_DETAIL_DEFINITIONS_MODAL_TITLE: 'Définitions des détails de rapport',
	REPORT_DETAIL_DEFINITIONS_DESC: 'Sélectionnez bouton ci-dessous pour afficher toutes les définitions des détails de rapport.',
	VIEW_DEFINITIONS_BUTTON: 'AFFICHER DÉFINITIONS',

	// definition types
	'DATA SESSIONS DETAIL DEFINITION': 'Définition des détails de sessions données',
	'IMSI CHARGE DETAIL XML FIELDS': 'Champs XML de détails frais IMSI',
	'IMSI DETAIL DEFINITIONS': 'Définitions des détails IMSI',
	'LBS USAGE DETAILS': 'Détails utilisation LBS',
	'DATA LAGG DETAIL DEFINITIONS': 'Définitions des détails lagg données',
	'SMS CALL DETAIL DEFINITIONS': 'Définitions des détails appels SMS',
	'TAX DETAIL DEFINITIONS': 'Définitions des détails taxes',
	'SIM ACTIVITY DETAIL DEFINITION': 'Définition des détails activités SIM',

	// Data Lagg Detail Definitions - KEYS -------------
	CUST_ID_KEY: 'CUST_ID',
	IMSI_KEY: 'IMSI',
	ICCID_KEY: 'Identifiant de carte SIM',
	MSISDN_KEY: 'MSISDN',
	ON_NET_KB_USAGE_KEY: 'ON_NET_KB_USAGE',
	ROAMING_KB_USAGE_KEY: 'ROAMING_KB_USAGE',
	CAN_KB_USAGE_KEY: 'CAN_KB_USAGE',
	INT_KB_ROUNDED_KEY: 'INT_KB_ROUNDED',
	TOTAL_KB_USAGE_KEY: 'TOTAL_KB_USAGE',

	// Data Lagg Detail Definitions - DESCRIPTIONS
	CUST_ID_DESC: 'ID client EOD.',
	IMSI_DESC: 'ID abonné international de la station mobile. Il s’agit du numéro que le réseau utilise pour identifier l’utilisateur.',
	ICCID_DESC: 'Identifiant de carte à circuit intégré. Il s’agit du numéro imprimé sur la carte SIM.',
	MSISDN_DESC: 'Numéro ISDN de station mobile (nº téléphone) affecté à cette SIM.',
	ON_NET_KB_USAGE_DESC: 'Total utilisation sur réseau (Ko)',
	ROAMING_KB_USAGE_DESC: 'Total utilisation nationale hors réseau (Ko).',
	CAN_KB_USAGE_DESC: 'Total utilisation au Canada (Ko).',
	INT_KB_ROUNDED_DESC: 'Total utilisation internationale (Ko).',
	TOTAL_KB_USAGE_DESC: 'Total utilisation (Ko).',

	// Data Sessions Detail Definition - KEYS ----------------
	IMPORT_DATE_KEY: 'Date importation',
	// IMSI - use already defined key-desc
	// ICCID - use already defined key-desc
	GGSN_IP_KEY: 'IP GGSI',
	SGSN_IP_KEY: 'IP SGSN',
	APN_NAME_KEY: 'NOM APN',
	PDP_IP_KEY: 'IP PDP',
	UPLINK_VOLUME_KEY: 'VOLUME LIAISON MONTANTE',
	DOWNLINK_VOLUME_KEY: 'VOLUME LIAISON DESCENDANTE',
	TRANSACTION_START_TIME_KEY: 'HEURE DÉBUT TRANSACTION',
	DURATION_KEY: 'DURÉE',
	SERVED_MSISDN_KEY: 'N° RÉSEAU NUMÉRIQUE À INTÉGRATION DE SERVICES D’ABONNÉ MOBILE SERVI',
	BID_KEY: 'BID',
	SERVING_LOCATION_KEY: 'LIEU DE DESSERTE',
	NETWORK_KEY: 'RÉSEAU',
	ROUNDING_DATE_KEY: 'DATE ARRONDI',

	// Data Sessions Detail Definition - DESCRIPTIONS
	IMPORT_DATE_DESC: 'Date et heure auxquelles EOD a reçu cet enregistrement. Sert à déterminer à quelle date de facturation s’appliquera cet enregistrement.',
	// IMSI - use already defined key-desc
	// ICCID - use already defined key-desc
	GGSN_IP_DESC: 'Nœud assistance Gateway General Packet Radio Service (GPRS). Dispositif situé entre le réseau mobile terrestre public (RMTP) et les réseaux externes pour fournir une passerelle frontière comme la sécurité et la comptabilité.',
	SGSN_IP_DESC: 'Desserte de nœud assistance Gateway General Packet Radio Service (GPRS). Dispositif situé au même niveau hiérarchique que le centre de commutation mobile/enregistreur de localisation de visiteurs (MSC/VLR) qui gère la mobilité et la liaison logique aux terminaux mobiles.',
	APN_NAME_DESC: 'Nom de l’APN utilisé par ce dispositif pour cette transaction.',
	PDP_IP_DESC: 'Adresse IP affectée à l’appareil au moment de la transaction.',
	UPLINK_VOLUME_DESC: 'Nombre d’octets passés à partir de cet appareil pour cet enregistrement de détail.',
	DOWNLINK_VOLUME_DESC: 'Nombre d’octets passés vers cet appareil pour cet enregistrement de détail.',
	TRANSACTION_START_TIME_DESC: 'Heure de début réelle de la transaction. Cette date diffèrera de la « date d’importation » et de la « date d’acheminement », selon le moment auquel l’enregistrement a été reçu par EOD.',
	DURATION_DESC: 'Durée (en secondes) de cet enregistrement de données.',
	SERVED_MSISDN_DESC: 'Numéro ISDN de station mobile (numéro de téléphone) affecté à cette SIM.',
	BID_DESC: 'Identifiant de facturation. Utilisé par EOD pour déterminer dans quel marché la transaction a eu lieu.',
	SERVING_LOCATION_DESC: 'Nom de l’endroit où cet enregistrement de données a eu lieu.',
	NETWORK_DESC: 'Cingular, itinérance/hors réseau, international ou Canada.',
	ROUNDING_DATE_DESC: 'Date utilisée par EOD pour déterminer avec quels autres enregistrements celui-ci sera regroupé pour calculer l’arrondi quotidien en ko.',
	SESSION_HISTORY_DCP_NOT_APPLICABLE: 'Sans objet',

	// IMSI Charge Detail XML Fields - KEYS AND DESC ------------
	CUSTOMER_KEY: 'CLIENT',
	CUSTOMER_DESC: 'Préfixe du client',
	STARTCYCLE_KEY: 'STARTCYCLE',
	STARTCYCLE_DECS: 'Début du cycle de facturation',
	ENDCYCLE_KEY: 'ENDCYCLE',
	ENDCYCLE_DESC: 'Fin du cycle de facturation',
	WCO_KEY: 'WCO',
	WCO_DESC: 'Offre de connectivité sans fil',
	EQUIPMENTORDERS_KEY: 'EQUIPMENTORDERS',
	EQUIPMENTORDERS_DESC: 'Commandes d’équipement',
	ACCOUNTOCC_KEY: 'ACCOUNTOCC',
	ACCOUNTOCC_DESC: 'Autres frais et crédits niveau compte (taxes et surtaxes uniquement)',
	ACCOUNTTAXES_KEY: 'ACCOUNTTAXES',
	ACCOUNTTAXES_DESC: 'Taxes et frais gouvernementaux (taxes uniquement) niveau compte',
	// ICCID - REUSE
	// IMSI - REUSE
	// MSISDN -REUSE
	RATEPLAN_KEY: 'RATEPLAN',
	RATEPLAN_DESC: 'Forfait imputé',
	ACTIVATIONSTATE_KEY: 'ACTIVATIONSTATE',
	ACTIVATIONSTATE_DESC: 'État de l’appareil',
	CUSTFILED1_KEY: 'CUSTFILED1',
	CUSTFILED1_DESC: 'Champ 1 du client',
	CUSTFILED2_KEY: 'CUSTFILED2',
	CUSTFILED2_DESC: 'Champ 2 du client',
	CUSTFILED3_KEY: 'CUSTFILED3',
	CUSTFILED3_DESC: 'Champ 3 du client',
	CUSTFILED4_KEY: 'CUSTFILED4',
	CUSTFILED4_DESC: 'Champ 4 du client',
	CUSTFILED5_KEY: 'CUSTFILED5',
	CUSTFILED5_DESC: 'Champ 5 du client',
	ATTPOINTER_KEY: 'ATTPOINTER',
	ATTPOINTER_DESC: 'Pointeur interne',
	CUSTPOINTER_KEY: 'CUSTPOINTER',
	CUSTPOINTER_DESC: 'Pointeur du client',
	DATAMRC_KEY: 'DATAMRC',
	DATAMRC_DESC: 'Données MRC',
	DATAUSAGE_KEY: 'DATAUSAGE',
	DATAUSAGE_DESC: 'Total de tous les frais d’utilisation données',
	SMSMRC_KEY: 'SMSMRC',
	SMSMRC_DESC: 'SMS MRC',
	SMSUSAGE_KEY: 'SMSUSAGE',
	SMSUSAGE_DESC: 'Total de tous les frais d’utilisation SMS – sauf frais SMS en bloc',
	VOICEMRC_KEY: 'VOICEMRC',
	VOICEMRC_DESC: 'Voix MRC',
	VOICEUSAGE_KEY: 'VOICEUSAGE',
	VOICEUSAGE_DESC: 'Total de tous les frais d’utilisation voix',
	DEVICEACTIVITYFEE_KEY: 'DEVICEACTIVITYFEE',
	DEVICEACTIVITYFEE_DESC: 'Frais liés aux appareils (frais activation, désactivation, réactivation, frais uniques de changement de forfait)',
	OCC_KEY: 'OCC',
	OCC_DESC: 'Autres frais et crédits niveau de abonné (taxes et surtaxes uniquement)',
	TAXES_KEY: 'TAXES',
	TAXES_DESC: 'Taxes et frais gouvernementaux (taxes uniquement) niveau abonné',

	// IMSI DETAIL KEYS VALUE -----------------------
	// ICCID - REUSE
	// MSISDN -REUSE
	PLAN_CODE_KEY: 'PLAN_CODE',
	PLAN_CODE_DESC: 'Code de forfait affecté par client à chaque SIM.',
	ACT_ID_KEY: 'ACT_ID',
	ACT_ID_DESC: 'Action exécutée par le client sur chaque SIM (1 = activer, 2 = désactiver)',
	INCLUDED_USAGE_KEY: 'INCLUDED_USAGE',
	INCLUDED_USAGE_DESC: 'Utilisation totale incluse (Ko)',
	// ON_NET_KB_USAGE - REUSE
	// ROAMING_KB_USAGE - REUSE
	// CAN_KB_USAGE - REUSE
	// INT_KB_ROUNDED - REUSE
	// TOTAL_KB_USAGE - REUSE
	ACCESS_CHG_KEY: 'ACCESS_CHG',
	ACCESS_CHG_DESC: 'Frais d’accès mensuels récurrents.',
	ACTIVATION_CHG_KEY: 'ACTIVATION_CHG',
	ACTIVATION_CHG_DESC: 'Frais d’activation.',
	BEGPERIOD_KEY: 'BEGPERIOD',
	BEGPERIOD_DESC: 'Début du cycle facturation.',
	ENDPERIOD_KEY: 'ENDPERIOD',
	ENDPERIOD_DESC: 'Fin du cycle facturation.',
	CUST_PREFIX_KEY: 'CUST_PREFIX',
	CUST_PREFIX_DESC: 'Identifiant client.',
	CUST_FIELD_1_KEY: 'CUST_FIELD_1',
	CUST_FIELD_1_DESC: 'Champ 1 du client.',
	CUST_FIELD_2_KEY: 'CUST_FIELD_2',
	CUST_FIELD_2_DESC: 'Champ 2 du client.',
	CUST_FIELD_3_KEY: 'CUST_FIELD_3',
	CUST_FIELD_3_DESC: 'Champ 3 du client.',
	CUST_FIELD_4_KEY: 'CUST_FIELD_4',
	CUST_FIELD_4_DESC: 'Champ 4 du client.',
	CUST_FIELD_5_KEY: 'CUST_FIELD_5',
	CUST_FIELD_5_DESC: 'Champ 5 du client.',
	INTERNAL_POINTER_KEY: 'POINTEUR INTERNE',
	INTERNAL_POINTER_DESC: 'Pointeur interne AT&T.',
	CUSTOMER_POINTER_KEY: 'POINTEUR DU CLIENT',
	CUSTOMER_POINTER_DESC: 'Pointeur du client.',

	// LBS USAGE DEFINITION KEYS-VALUE
	RECORDINGENTITY_KEY: 'RECORDINGENTITY',
	RECORDINGENTITY_DESC: 'Pointeur unique vers enregistrements agrég. (Réservé à l’agrégateur).',
	LCSCLIENTEXTERNALID_KEY: 'LCSCLIENTEXTERNALID',
	LCSCLIENTEXTERNALID_DESC: 'LCS Client ExternalID (Not for use at this time).',
	TARGETIMSI_KEY: 'TARGETIMSI',
	TARGETIMSI_DESC: 'ID abonné international de la station mobile. Il s’agit du numéro que le réseau utilise pour identifier l’utilisateur.',
	TARGETMSISDN_KEY: 'TARGETMSISDN',
	TARGETMSISDN_DESC: 'MSIDN localisé si SIM AT&T ; XXXXXXXXXX si SIM non AT&T (le numéro ISDN de station mobile, « nº de téléphone » affecté à cette SIM).',
	RESULTCODE_KEY: 'RESULTCODE',
	RESULTCODE_DESC: 'Réussite ou échec de la localisation. 0 = réussite/ seuls 0 et 1 utilisés pour commencer car les codes de résultats Agg et Med peuvent différer.',
	UTCRECORDOPENINGTIME_KEY: 'UTCRECORDOPENINGTIME',
	UTCRECORDOPENINGTIME_DESC: 'Ne pas utiliser pour le moment (heure d’ouverture enregist. UTC).',
	INFORMATION_1_KEY: 'INFORMATION_1',
	INFORMATION_1_DESC: 'Application ID 16 digit EAG issued AppID (“1” in position2 = Technocom, “2” in position2 = LocAid Id)',
	INFORMATION_3_KEY: 'INFORMATION_3',
	INFORMATION_3_DESC: '(Horizontal) Précision requise : Précision en mètres souhaitée. <=800 est AGPS. (800 pour indiquer demande AGPS ou 801 pour indiquer demande CID/ECID pour les localisations réussies non ATT et 0 pour les échecs (selon la réponse)).',
	INFORMATION_4_KEY: 'INFORMATION_4 ',
	INFORMATION_4_DESC: '(Horizontal) Précision retournée : Précision en mètres réellement retournée. (800 pour indiquer demande AGPS ou 801 pour indiquer demande CID/ECID pour les localisations réussies non ATT et 0 pour les échecs (selon la réponse)).',
	PART_DATE_KEY: 'PART_DATE',
	PART_DATE_DESC: 'Date de saisie dans le fichier de transmission CDR par agr. (PST : AAAA-MM-JJ HH:mm:ss)',

	// SMS CALL DEFIONITIONS - KEYS AND DESC
	// IMSI
	// SERVED MSISDN
	SMS_TYPE_KEY: 'TYPE SMS',
	SMS_TYPE_DESC: 'Mobile de départ (MO) ou mobile d’arrivée (MT).',
	CALLED_NUMBER_KEY: 'NUMÉRO APPELÉ',
	CALLED_NUMBER_DESC: 'Le numéro qui a été composé.',
	START_TIME_KEY: 'HEURE DÉBUT',
	START_TIME_DESC: 'Date et heure auxquelles la transaction a été exécutée.',
	// NETWORK
	PLMN_KEY: 'RMTP',
	PLMN_DESC: 'La Liste Réseau mobile terrestre public est un identifiant de facturation utilisé par EOD pour déterminer dans quel marché la transaction a eu lieu.',
	CARRIER_KEY: 'OPÉRATEUR',
	CARRIER_DESC: 'Le propriétaire du marché de la mobilité pour le RMTP associé.',

	// SIM ACTIVITY DEFINITIONS - KEYS AND DESC
	// ICCID
	// MSISDN
	ACTION_DATE_KEY: 'ACTION_DATE',
	ACTION_DATE_DESC: 'Date et heure auxquelles la transaction a été exécutée.',
	ACT_KEY: 'ACT',
	ACT_DESC: 'Action exécutée par le client sur chaque SIM (ACTV = activer, KILL = désactiver, CHNG = changement du profil réseau, RATE = changement du forfait, CPPU = changement du lieu principal d’utilisation).',
	PLAN_CODE_KEY_2: 'PLAN_CODE',
	PLAN_CODE_DESC_2: 'Code alphabétique du forfait utilisé pour cette transaction.',
	LOGIN_KEY: 'CONNEXION',
	LOGIN_DESC: 'Transaction associée à l’utilisateur',

	// TAX DETAIL DEFINITIONS - KEYS AND DESC
	PPU_ADDRES_KEY: 'PPU_ADDRES',
	PPU_ADDRES_DESC: 'Lieu d’utilisation principal – Endroit où le client utilise principalement ses services.',
	SERVICE_KEY: 'SERVICE',
	SERVICE_DESC: 'Produit ou offre qui est taxé(e).',
	INVOICE_SECTION_KEY: 'SECTION FACTURE',
	INVOICE_SECTION_DESC: 'TX – Taxes et frais gouvernementaux. OC – Autres frais et crédits.',
	AUTHORITY_LEVEL_KEY: 'NIVEAU AUTORITÉ',
	AUTHORITY_LEVEL_DESC: 'Niveau associé au texte de la facture F – Fédéral S – État Co – Pays Ci – Ville DCo – Comté du district DCi – Ville du district',
	INVOICE_TEXT_KEY: 'TEXTE FACTURE',
	INVOICE_TEXT_DESC: 'Texte associé aux frais indiqués sur la facture imprimée des clients.',
	CHARGE_AMMOUNT_KEY: 'MONTANT DES FRAIS',
	CHARGE_AMMOUNT_DESC: 'Le montant qui a été assujetti à des taxes.',
	AMMOUNT_KEY: 'MONTANT',
	AMMOUNT_DESC: 'Montant de taxe calculé pour les frais taxables associés.',
	BATCH_FORMATS: 'Formats en lots',

	ACTIVATION: 'Activation',
	TERMINATION: 'Résiliation',
	NETWORK_ENTITLEMENT_CHANGE: 'Changement de droits d’utiliser le réseau',
	SIM_SWAP: 'Changement de SIM',
	CUSTOM_FIELD_UPDATES: 'Mises à jour champs personnalisés',
	CUSTOM_FIELD_VALUE: 'Valeur de champ personnalisé',
	BATCH_FILE_UPLOAD_SUCCESS: 'Réception réussie des fichiers séquentiels !',
	BATCH_FILE_UPLOAD_SUCCESS_MESSAGE: 'Changements en attente pendant que le système effectue mises à jour',
	BATCH_FILE_SYSTEM_ERROR: 'Erreur système de fichier séquentiel !',
	BATCH_FILE_SYSTEM_ERROR_MESSAGE: 'Un problème est survenu au cours du traitement de vos mises à jour. Veuillez réessayer ultérieurement.',
	TYPE_IN_OR_SELECT: 'Saisir nouvelle valeur ou sélectionner option dans liste déroulante pour changer le paramètre d’un point terminal particulier.',

	SUBMITTED_STATUS: 'Envoyé',
	SCHEDULED_STATUS: 'Programmé',
	IN_PROGRESS_STATUS: 'En cours',
	COMPLETED_STATUS: 'Exécuté',
	'IN-PROGRESS_STATUS': 'En cours',
	COMPLETED: 'Exécuté',
	CANCELLED: 'Annulé',
	ERROR_STATUS: 'Erreur',

	FILE_NAME_CANNOT_CONTAIN_SPACES: 'Le nom du fichier ne doit pas comprendre d’espaces',
	DRAG_FILE_OR_CLICK_TO_UPLOAD: 'Faire glisser le fichier ou cliquer pour téléverser',
	FORBIDDEN_ACCESS: 'Accès interdit',
	REQUEST_TIMED_OUT: 'Délai expiré',
	TERMINATE_ACTION: 'Résilier SMS',
	ACTIVATE_ACTION: 'Activer SMS',
	SWAP_SIMS_ACTION: 'Changer de SIM',
	CUSTOM_FIELDS_ACTION: 'Ajouter ou mettre à jour champs personnalisés',
	REQUIRED_CUSTOM_FIELD: 'Autorisations de plus haut niveau requises !',
	FIELD_IS_REQUIRED_ACTION: 'Seuls les administrateurs peuvent modifier les types de champs personnalisés, car ils ont un impact sur la configuration de tout le système. Contactez votre administrateur pour mettre à jour les options des champs personnalisés.',
	PERMANENT_CUSTOM_FIELD_DELETION: 'Suppression définitive de champ personnalisé !',
	DELETING_THIS_CUSTOM_FIELD: 'La suppression de ce champ personnalisé supprimera toutes les données connexes ! Cette action ne peut pas être annulée !',
	DELETE_FIELD: 'Supprimer champ',

	WARNING: 'Avertissement',
	ARE_YOU_SURE: 'Voulez-vous vraiment ?',
	NETWORK_ENTITLEMENT_CHANGE_ACTION: 'Changement de droits d’utiliser le réseau SIM',
	WHEN_SAVING: 'Pour enregistrer votre fichier, utilisez le format ci-dessus.',
	EXAMPLE: 'Exemple',

	SELECT_NEW_STATE: 'Sélectionner nouvel État',
	403: '403',

	AUTOMATION_PROCESS: 'Processus d’automatisation',
	BATCH_UPLOAD: 'Téléversement par lots',
	DIAGRAM_COULD_NOT_BE: 'Impossible de charger le graphique',
	LOADED_AT_THIS_TIME: 'pour le moment.',
	BATCH_ACTIONS: 'Actions par lots',
	BATCH_ACTION: 'Action par lots',
	BATCH_ACTIONS_SUBMITTED: 'Téléversement du fichier réussi pour le traitement',
	BATCH_ACTIONS_SCHEDULED: 'Téléversement du fichier réussi, programmé pour le traitement',
	BATCH_ACTIONS_IN_PROGRESS: 'Traitement en cours',
	BATCH_ACTIONS_COMPLETED: 'Traitement du fichier réussi',
	BATCH_ACTIONS_ERROR: 'Une erreur s’est produite lors du traitement',
	BATCH_REPORTING: 'Rapports par lots',
	BATCH_TEXT: 'Pour traiter vos changements par lots, sélectionnez l’action à exécuter et téléversez votre fichier texte séquentiel. Exemples de modèles disponibles pour le formatage.',
	START_BATCH_PROCESS: 'Lancer traitement par lots',
	DOWNLOAD_TEMPLATE: 'Télécharger fichier modèle',
	AUTOMATION_RULES: 'Règles d’automatisation',
	ACTION_FORMAT: 'Format de l’action',
	VIEW_MORE_DETAILS: 'Afficher plus de détails',
	UPLOAD_FILE_NOTE: 'Téléverser fichiers ici pour action par lots sélectionnée :',
	UPLOAD_FILE: 'Téléverser fichier',

	UNSUPPORTED_FILE_TYPE: 'Type de fichier non pris en charge',
	INVALID_FILE_NAME_FORMAT: 'Le format du nom de fichier devrait être NomAction_MM-JJ-AAAA_HH-mm-ss',
	DOWNLOAD_REPORT_FAILED: 'Le téléchargement a échoué, veuillez réessayer.',
	PLEASE_TRY_AGAIN: 'Veuillez réessayer.',

	// REPORT DETAIL DEFINITION
	REPORT_SOURCE: 'Source du rapport',
	DATA_SESSION_DETAIL_DEFINITION: 'Définition détails session données',
	IMSI_DETAIL_DEFINITION: 'Définition détails IMSI',
	SMS_DETAIL_DEFINITION: 'Définition détails SMS',
	SIM_DETAIL_DEFINITION: 'Définition détails SIM',
	USAGE_DATA_DETAILS: 'Détails utilisation données',
	USAGE_SMS_DETAILS: 'Détails utilisation SMS',
	POOLED_USAGE: 'Utilisation groupée',
	USAGE_SUMMARY: 'Synthèse d’utilisation',
	SUBSCRIBER_SNAPSHOT: 'Aperçu abonné',
	NEW_SUBSCRIBERS: 'Nouveaux abonnés',
	TOP_DATA_USAGE: 'Utilisation données principale',
	TOP_SMS_USAGE: 'Utilisation SMS principale',
	PROVOSIONING_METRIC: 'Provisionnement en métrique',

	// USAGE DATA DETAIL - KEYS AND DESC
	DATA_TRAFFIC_DETAIL_ID_KEY: 'ID détails trafic de données',
	DATA_TRAFFIC_DETAIL_ID_DESC: 'ID unique pour enregistrements utilisation données',
	// "ICCID_KEY"
	ICCID_DESC_SHORT: 'Identifiant de carte SIM désignant la SIM',
	// "MSISDN_KEY"
	MSISDN_DESC_SHORT: 'N° réseau numérique à intégration de services d’abonné mobile identifiant la SIM',
	// "IMSI_KEY"
	IMSI_DESC_SHORT: 'Identité internationale d’abonné mobile identifiant la SIM',
	ACCOUNT_ID_KEY: 'ID compte',
	ACCOUNT_ID_DESC: 'Numéro ID compte Jasper',
	BILLABLE_FLAG_KEY: 'Indicateur Facturable',
	BILLABLE_FLAG_DESC: 'Indicateur de trafic facturable, dépend de l’État de la SIM',
	BILLING_CYCLE_KEY: 'Cycle de facturation',
	BILLING_CYCLE_DESC: 'Cycle de facturation au cours duquel l’utilisation a été effectuée',
	SIM_STATE_KEY: 'État SIM',
	SIM_STATE_DESC: 'État SIM affecté à la SIM à la fin de la période visée',
	SERVICE_TYPE_KEY: 'Type de service',
	SERVICE_TYPE_DESC: 'Type service sans fil déclaré (p. ex. données, SMS départ, SMS arrivée, voix)',
	ASSIGNED_RATE_PLAN_ID_KEY: 'ID du forfait affecté',
	ASSIGNED_RATE_PLAN_ID_DESC: 'Forfait affecté à la SIM au moment où l’utilisation a été déclarée',
	ASSIGNED_RATING_ZONE_ID_KEY: 'ID de zone de tarification affectée',
	ASSIGNED_RATING_ZONE_ID_DESC: 'Zone du forfait à laquelle l’utilisation est attribuée en fonction du forfait affecté au moment où l’utilisation a été déclarée',
	OFFPEAK_USAGE_FLAG_KEY: 'Indicateur Utilisation hors pointe',
	OFFPEAK_USAGE_FLAG_DESC: 'Indique si l’utilisation est effectuée pendant les heures hors pointe définies pour le compte',
	RECORD_RECEIVED_DATE_KEY: 'Date réception de l’enregistrement',
	RECORD_RECEIVED_DATE_DESC: 'Date/heure de réception de l’enregistrement comptable',
	DATA_USAGE_RAW_TOTAL_KEY: 'Utilisation données - Total brut',
	DATA_USAGE_RAW_TOTAL_DESC: 'Utilisation non arrondie de données pour la période – octets',
	DATA_USAGE_RAW_UPLINK_KEY: 'Utilisation données – Liaison montante, brut',
	DATA_USAGE_RAW_UPLINK_DESC: 'Utilisation non arrondie de données pour la période – Liaison montante, octets',
	DATA_USAGE_RAW_DOWNLINK_KEY: 'Utilisation données – Liaison descendante, brut',
	DATA_USAGE_RAW_DOWNLINK_DESC: 'Utilisation données non arrondie pour la période – Liaison descendante, octets',
	DATA_USAGE_ROUNDED_KEY: 'Utilisation données – Arrondie',
	DATA_USAGE_ROUNDED_DESC: 'Utilisation données arrondie pour la période – octets ; la façon d’arrondir dépend du forfait',
	APN_DESC: 'Nom du point d’accès pour la session',
	DEVICE_IP_ADDRESS_KEY: 'Adresse IP appareil',
	DEVICE_IP_ADDRESS_DESC: 'Adresse IP affectée à l’appareil pour la session de données correspondante',
	OPERATOR_NETWORK_KEY: 'Réseau de l’opérateur',
	OPERATOR_NETWORK_DESC: 'Réseau de l’opérateur visité sur lequel l’utilisation a été effectuée',
	RECORD_OPEN_TIME_KEY: 'Heure début enregistrement',
	RECORD_OPEN_TIME_DESC: 'Date/heure auxquelles la session ou la session partielle a débuté',
	SESSION_DURATION_KEY: 'Durée de la session',
	SESSION_DURATION_DESC: 'Durée de la session de données – secondes',
	RECORD_SEQUENCE_NUMBER_KEY: 'Numéro d’ordre de l’enregistrement',
	RECORD_SEQUENCE_NUMBER_DESC: 'Numéro d’ordre de l’enregistrement dans la session parente si l’enregistrement représente une session partielle',
	CHARGINGID_KEY: 'ChargingID',
	CHARGINGID_DESC: 'Identifiant de la session à laquelle s’applique un enregistrement d’utilisation',
	SESSION_CLOSE_CAUSE_KEY: 'Cause de fermeture de session',
	SESSION_CLOSE_CAUSE_DESC: 'Code numérique indiquant la raison de la fermeture de la session',
	TAP_CODE_KEY: 'Code TAP',
	TAP_CODE_DESC: 'Code TAP du réseau visité',
	OPERATOR_ACCOUND_ID_KEY: 'ID compte opérateur',
	OPERATOR_ACCOUND_ID_DESC: 'ID utilisé par l’opérateur pour identifier un compte',
	RATE_PLAN_VERSION_ID_KEY: 'ID de version du forfait',
	RATE_PLAN_VERSION_ID_DESC: 'Version particulière du forfait actuellement utilisée aux fins de facturation',
	STREAM_ID_KEY: 'ID de flux',
	STREAM_ID_DESC: 'Identifiant numérique décrivant les différents flux de données identifiés par GGSN/PGW',
	CGI_KEY: 'CGI (identifiant mondial de cellule)',
	CGI_DESC: 'Partie de l’information de localisation, tel que décrit dans TS29.274',
	SAI_KEY: 'SAI (identifiant de zone desservie)',
	SAI_DESC: 'Partie de l’information de localisation, tel que décrit dans TS29.274',
	RAI_KEY: 'RAI (identifiant de zone d’acheminement)',
	RAI_DESC: 'Partie de l’information de localisation, tel que décrit dans TS29.274',
	TAI_KEY: 'TAI (identité de la zone de suivi)',
	TAI_DESC: 'Partie de l’information de localisation, tel que décrit dans TS29.274',
	ECGI_KEY: 'ECGI(E-UTRAN CGI)',
	ECGI_DESC: 'Partie de l’information de localisation, tel que décrit dans TS29.274',
	LAI_KEY: 'LAI (Identifiant de zone de localisation)',
	LAI_DESC: 'Partie de l’information de localisation, tel que décrit dans TS29.274',
	SERVING_SGSN_KEY: 'SGSN desservi',
	SERVING_SGSN_DESC: 'Adresse du commutateur desservi',
	CALL_TECHNOLOGY_TYPE_KEY: 'Type de technologie d’appel',
	CALL_TECHNOLOGY_TYPE_DESC: 'Indicateur numérique identifiant la technologie d’accès radio utilisée pour cette utilisation',

	// USAGE SMS DETAIL - KEYS AND DESC
	SMS_TRAFFIC_DETAIL_ID_KEY: 'ID détails trafic de données',
	SMS_TRAFFIC_DETAIL_ID_DESC: 'ID unique pour enregistrements utilisation données',
	// "ICCID_KEY"
	// "ICCID_DESC"
	// "MSISDN_KEY"
	// "MSISDN_DESC"
	// "IMSI_KEY"
	// "IMSI_DESC"
	// "ACCOUNT_ID_KEY"
	// "ACCOUNT_ID_DESC"
	// "BILLABLE_FLAG_KEY"
	// "BILLABLE_FLAG_DESC"
	// "BILLING_CYCLE_KEY"
	// "BILLING_CYCLE_DESC"
	// "SIM_STATE_KEY"
	// "SIM_STATE_DESC"
	// "SERVICE_TYPE_KEY"
	// "SERVICE_TYPE_DESC"
	// "ASSIGNED_RATE_PLAN_ID_KEY"
	// "ASSIGNED_RATE_PLAN_ID_DESC"
	// "ASSIGNED_RATING_ZONE_ID_KEY"
	// "ASSIGNED_RATING_ZONE_ID_DESC"
	RECORD_OPEN_TIME_SMS_KEY: 'Heure début enregistrement',
	RECORD_OPEN_TIME_SMS_DESC: 'Date/heure auxquelles un message SMS de départ a été envoyé ; Date/heure auxquelles un message SMS d’arrivée a été reçu',
	// "RECORD_RECEIVED_DATE_KEY": "Record Received Date",
	// "RECORD_RECEIVED_DATE_DESC": "Date/Time the accounting record was received",
	NETWORK_NODE_KEY: 'Nœud de réseau',
	NETWORK_NODE_DESC: 'Adresse du nœud de réseau desservi pour le message',
	FROM_KEY: 'De',
	FROM_DESC: 'N° réseau numérique à intégration de services d’abonné mobile source ou code abrégé',
	TO_KEY: 'À',
	TO_DESC: 'N° réseau numérique à intégration de services d’abonné mobile destination ou code abrégé',
	// "OPERATOR_NETWORK_KEY": "Operator Network",
	// "OPERATOR_NETWORK_DESC": "The visited operator network on which the usage occurred",
	// "TAP_CODE_KEY": "TAP Code",
	// "TAP_CODE_DESC": "A TAP code for the visited Network",
	// "OPERATOR_ACCOUND_ID_KEY": "Operator Account ID",
	// "OPERATOR_ACCOUND_ID_DESC": "Id used by the operator to identify an account",
	// "RATE_PLAN_VERSION_ID_KEY": "Rate Plan Version ID",
	// "RATE_PLAN_VERSION_ID_DESC": "Specific version of the rate plan,
	// being used for billing purposes at this point in time",

	// POOLED USAGE - KEYS AND DESC
	// "ACCOUNT_ID_KEY"
	// "ACCOUNT_ID_DESC
	// "BILLING_CYCLE_KEY" />,
	// "BILLING_CYCLE_DESC"
	INVOICE_ID_KEY: 'ID facture',
	INVOICE_ID_DESC: 'ID de la facture à laquelle s’appliquent les frais',
	RATE_PLAN_ID_KEY: 'ID forfait',
	RATE_PLAN_ID_DESC: 'ID du forfait affecté à la SIM à la fin de la période visée',
	RATING_ZONE_ID_KEY: 'ID zone de tarification',
	RATING_ZONE_ID_DESC: 'Zone de tarification (définie dans le forfait) dans laquelle l’utilisation a été effectuée',
	TOTAL_DATA_USAGE_CHARGES_KEY: 'Total des frais d’utilisation de données',
	TOTAL_DATA_USAGE_CHARGES_DESC: 'Total des frais d’utilisation de données dans cette zone de tarification, en plus de toute utilisation groupée de données incluse dans les frais d’abonnement',
	TOTAL_SMS_MO_USAGE_CHARGES_KEY: 'Total des frais d’utilisation SMS de départ',
	TOTAL_SMS_MO_USAGE_CHARGES_DESC: 'Total des frais d’utilisation de SMS de départ dans cette zone de tarification, en plus de toute utilisation groupée de SMS incluse dans les frais d’abonnement',
	TOTAL_SMS_MT_USAGE_CHARGES_KEY: 'Total des frais d’utilisation SMS d’arrivée',
	TOTAL_SMS_MT_USAGE_CHARGES_DESC: 'Total des frais d’utilisation de SMS d’arrivée dans cette zone de tarification, en plus de toute utilisation groupée de SMS incluse dans les frais d’abonnement',
	TOTAL_SMS_USAGE_CHARGES_KEY: 'Total des frais d’utilisation de SMS',
	TOTAL_SMS_USAGE_CHARGES_DESC: 'Total des frais d’utilisation de SMS dans cette zone de tarification, en plus de toute utilisation groupée de SMS incluse dans les frais d’abonnement',
	TOTAL_VOICE_MO_USAGE_CHARGES_KEY: 'Total des frais d’utilisation voix de départ',
	TOTAL_VOICE_MO_USAGE_CHARGES_DESC: 'Total des frais d’utilisation de voix départ pour cette SIM dans cette zone de tarification, en plus de toute utilisation groupée de voix incluse dans les frais d’abonnement',
	TOTAL_VOICE_MT_USAGE_CHARGES_KEY: 'Total des frais d’utilisation voix d’arrivée',
	TOTAL_VOICE_MT_USAGE_CHARGES_DESC: 'Total des frais d’utilisation de voix arrivée pour cette SIM dans cette zone de tarification, en plus de toute utilisation groupée de voix incluse dans les frais d’abonnement',
	TOTAL_USAGE_CHARGES_KEY: 'Total des frais d’utilisation',
	TOTAL_USAGE_CHARGES_DESC: 'Total des frais d’utilisation dans cette zone de tarification, en plus de toute utilisation groupée incluse dans les frais d’abonnement',

	// USAGE SUMMARY - KEYS AND DESC
	// "ACCOUNT_ID_KEY"
	// "ACCOUNT_ID_DESC"
	OPERATOR_ID_KEY: 'ID opérateur',
	OPERATOR_ID_DESC: 'N° d’identification de l’opérateur Jasper',
	// "BILLING_CYCLE_KEY"
	// "BILLING_CYCLE_DESC"
	// "ICCID_KEY"
	// "ICCID_DESC"
	// "IMSI_KEY"
	// "IMSI_DESC"
	// "MSISDN_KEY"
	// "MSISDN_DESC"
	TOTAL_DATA_USAGE_KEY: 'Utilisation totale, données',
	TOTAL_DATA_USAGE_DESC: 'Utilisation totale de données pour le cycle de facturation de cet appareil',
	TOTAL_VOICE_USAGE_KEY: 'Utilisation totale, voix',
	TOTAL_VOICE_USAGE_DESC: 'Utilisation totale voix pour le cycle de facturation de cet appareil',
	TOTAL_SMS_USAGE_KEY: 'Utilisation totale, SMS',
	TOTAL_SMS_USAGE_DESC: 'Utilisation totale SMS pour le cycle de facturation de cet appareil',
	TOTAL_CSD_USAGE_KEY: 'Utilisation totale, CSD',
	TOTAL_CSD_USAGE_DESC: 'Utilisation totale CSD pour le cycle de facturation de cet appareil',
	RESERVED_FIELD_KEY: 'Champ réservé',
	RESERVED_FIELD_DESC: 'Réservé pour utilisation future',
	DEVICE_ID_KEY: 'ID appareil',
	DEVICE_ID_DESC: 'Champ personnalisé disponible pour les opérateurs',
	MODEM_ID_KEY: 'ID modem',
	MODEM_ID_DESC: 'Champ personnalisé disponible pour les opérateurs',
	END_CONSUMER_ID_KEY: 'ID consommateur final',
	END_CONSUMER_ID_DESC: 'Identifiant du consommateur final',
	USAGE_CUSTOMER_KEY: 'Client',
	USAGE_CUSTOMER_DESC: 'Nom du client',
	CUSTOM1_KEY: 'Personnalisé 1',
	CUSTOM1_DEVICE_DESC: 'Champ personnalisé 1 niveau appareil',
	CUSTOM2_KEY: 'Personnalisé 2',
	CUSTOM2_DEVICE_DESC: 'Champ personnalisé 1 niveau appareil',
	CUSTOM3_KEY: 'Personnalisé 3',
	CUSTOM3_DEVICE_DESC: 'Champ personnalisé 1 niveau appareil',

	// SUBSCRIBER SNAPSHOT - KEYS AND DESC
	SUBSCRIBER_SIM_STATE_KEY: 'État SIM',
	SUBSCRIBER_SIM_STATE_DESC: 'État SIM à la fin de la période visée',
	// "RATE_PLAN_ID_KEY"
	SUBSCRIBER_RATE_PLAN_ID_DESC: 'Forfait affecté à la SIM à la fin de la période visée',
	SUBSCRIBER_CUSTOMER_KEY: 'Client',
	SUBSCRIBER_CUSTOMER_DESC: 'Client affecté à la SIM à la fin de la période visée',
	// "DEVICE_ID_KEY",
	SUBSCRIBER_DEVICE_ID_DESC: 'Valeur à la fin de la période visée',
	// "MODEM_ID_KEY"

	SUBSCRIBER_MODEM_ID_DESC: 'Valeur à la fin de la période visée',
	COMMUNICATION_PLAN_ID_KEY: 'ID forfait communication',
	COMMUNICATION_PLAN_ID_DESC: 'Valeur à la fin de la période visée',
	INITIAL_ACTIVATION_DATE_KEY: 'Date activation initiale',
	INITIAL_ACTIVATION_DATE_DESC: 'Date et heure activation initiale de la SIM dans le compte actuel',
	CUSTOM_SIM_DESC: 'Champ personnalisé niveau SIM disponible pour les comptes',
	LINE_PAYMENT_STATUS_KEY: 'État ligne paiement',
	LINE_PAYMENT_STATUS_DESC: 'Valeurs possibles : Actuel, Suspendu, Annulé, Annulation définitive',
	OPERATOR_ACCOUNT_ID_KEY: 'ID compte opérateur',
	OPERATOR_ACCOUNT_ID_DESC: 'ID utilisé par l’opérateur pour identifier un compte',
	CUSTOM4_KEY: 'Personnalisé 4',
	CUSTOM5_KEY: 'Personnalisé 5',
	CUSTOM6_KEY: 'Personnalisé 6',
	CUSTOM7_KEY: 'Personnalisé 7',
	CUSTOM8_KEY: 'Personnalisé 8',
	CUSTOM9_KEY: 'Personnalisé 9',
	CUSTOM10_KEY: 'Personnalisé 10',

	ICCID_DESC_SHORT2: 'Identifiant de carte SIM de l’appareil',
	IMSI_DESC_SHORT2: 'Identité internationale d’abonné mobile de l’appareil',
	RATE_PLAN_KEY: 'Forfait',
	RATE_PLAN_DESC: 'EOD plan_code, identifiant de carte SIM de centre de contrôle vide',
	SIM_STATE_KEY2: 'État SIM',
	SIM_STATE_DESC2: 'ACTIVE, INACTIVE, etc., le cas échéant',
	BEGBILLINGCYCLEDATE_KEY: 'Begbillcycledate',
	BEGBILLINGCYCLEDATE_DESC: 'Date début cycle de facturation si EOD, vide si Centre de contrôle',
	ENDBILLINGCYCLEDATE_KEY: 'Begbillcycledate',
	ENDBILLINGCYCLEDATE_DESC: 'Date fin cycle de facturation si EOD, vide si Centre de contrôle',
	TOTALDATAUSAGE_KEY: 'Totaldatausage',
	TOTALDATAUSAGE_DESC: 'Utilisation données pour la période, en Ko',
	TOTALINTERNATIONALUSAGE_KEY: 'Totalinternationalusage',
	TOTALINTERNATIONALUSAGE_DESC: 'Utilisation données internationales pour la période, en Ko si EOD, vide si Centre de contrôle',
	CUSTOMERNAME_KEY: 'Customername',
	CUSTOMERNAME_DESC: 'Nom du client',
	CUSTOMFIELD1_KEY: 'Customfield1',
	CUSTOMERNAME1_DESC: 'EOD et Centre de contrôle peuvent être remplis',
	CUSTOMFIELD2_KEY: 'Customfield2',
	CUSTOMERNAME2_DESC: 'EOD et Centre de contrôle peuvent être remplis',
	CUSTOMFIELD3_KEY: 'Customfield3',
	CUSTOMERNAME3_DESC: 'EOD et Centre de contrôle peuvent être remplis',
	CUSTOMFIELD4_KEY: 'Customfield4',
	CUSTOMERNAME4_DESC: 'ID de l’appareil pour EOD ou Centre de contrôle',
	CUSTOMFIELD5_KEY: 'Customfield5',
	CUSTOMERNAME5_DESC: 'ID du modem pour EOD ou Centre de contrôle',
	M2MPLATFORM_KEY: 'M2MPlatform',
	M2MPLATFORM_DESC: '« EOD » ou « Centre de contrôle »',
	BILLINGCYCLE_KEY: 'Billingcycle',
	BILLINGCYCLE_DESC: 'Champ Control Center JPO à remplir',
	CUSTOMERID_KEY: 'Customerid',
	CUSTOMERID_DESC: 'Pour EOD, préfixe du client ; pour Centre de contrôle, ID client',
	PROVISIONINGACTIVITY_KEY: 'ProvisioningActivity ',
	PROVISIONINGACTIVITY_DESC: 'Soit « activation », « inactive », « résilier », « changer droit d’utiliser le réseau », « changer forfait » ou « mettre à jour champs personnalisés »',
	COUNT_KEY: 'Nombre',
	COUNT_DESC: 'Nbre de fois que l’activité de provisionnement a été appelée',
	SUCCESS_KEY: 'Réussi',
	SUCCESS_DESC: 'Nbre de fois que l’appel de l’activité de provisionnement a réussi',
	FAILURE_KEY: 'Échec',
	FAILURE_DESC: 'Nbre de fois que l’appel de l’activité de provisionnement a échoué',
	AVGTIMEMS_KEY: 'AvgTimeMs',
	AVGTIMEMS_DESC: 'Nbre de millisecondes que l’appel de provisionnement a pris en moyenne',
	LONGESTTIMEMS_KEY: 'LongestTimeMs',
	LONGESTTIMEMS_DESC: 'Nbre de millisecondes que l’appel le plus lent de cette activité de provisionnement a pris',
	SHORTESTTIMEMS_KEY: 'ShortestTimeMs',
	SHORTESTTIMEMS_DESC: 'Nbre de millisecondes que l’appel le plus rapide de cette activité de provisionnement a pris',

	REPORT_SOURCE_DESC: 'Sélectionner la source du rapport pour voir différentes définitions',

	SIM_SWAP_SUCCESSFUL: 'Changement de SIM réussi',
	SIM_SWAP_IN_PROGRESS: 'Changement de SIM en cours',
	REFRESH_PENDING_STATUS: 'Actualiser le point terminal pour vérifier si l’état a changé',
	TEMPLATE_NOT_AVAILABLE: 'Modèle non disponible.',
	CUSTOM_FIELDS_UPDATE_SUCCESSFUL: 'Mises à jour des champs personnalisés réussie',
	CUSTOM_FIELDS_UPDATE_IN_PROGRESS: 'Mises à jour des champs personnalisés en cours',
	IOT_APPS: 'APPS IOT',
	INVOICES_DATE: 'Date des factures',
	PLATFORM: 'Plateforme',
	FREQUENCY: 'Fréquence',
	M2M_ACC_ID: 'ID compte machine-machine',
	REPORT: 'Rapport',

	// Onboarding
	ADD_NEW_PLATFORM: 'Ajouter nouvelle plateforme',
	BUSINESS_NAME: 'Nom d’entreprise',
	ADMINISTRATOR: 'Administrateur',
	MCC: 'Indicatif de pays de la station mobile (IPSM)',
	MNC: 'IoT Console (IOT-C)',
	OPERATOR: 'Opérateur',
	POD: 'POD',
	RATE_PLANS: 'Forfaits',
	RATE_PLAN_NAME: 'Nom de forfait',
	RATE_PLAN_ID: 'Forfait',
	RATE_PLAN_ID_PLAN: 'ID du forfait',
	COMMUNICATION_PLANE_NAME: 'Nom de forfait communication',
	ADD_ANOTHER_RATE_PLAN: 'Ajouter autre forfait',
	ADD_ANOTHER_COMMUNICATION_PLAN: 'Ajouter autre forfait communication',
	ADD_ANOTHER_PROFILE_NAME: 'Ajouter autre nom de profil',
	SIM_STATE_MAPPING: 'Mappages états SIM',
	ADD_RATE_PLAN: 'Ajouter forfait',
	ACC_RULE: 'Règle ACC',
	MC_RULE: 'Règle IOT-C',
	WHAT_IS_THIS_TITLE: 'Qu’est-ce que c’est ?',
	PLAN_PROFILES: 'Profils forfait',
	COMPANY: 'Société',
	COMPANY_NAME: 'Nom de société',
	COMPANY_DOMAIN: 'Domaine société',
	PHONE_NUMBER: 'Numéro de téléphone',
	CITY: 'Ville',
	CITY_TOWN: 'Ville',
	STATE_PROVINCE: 'État/Province',
	COUNTRY: 'Pays',
	ZIP_CODE: 'Code postal',
	ZIP_POSTAL_CODE: 'Code postal',
	STREET: 'Rue',
	STREET_ADDRESS: 'Adresse',
	LAST_NAME: 'Nom',
	FIRST_NAME: 'Prénom',
	ACTIONS: 'Actions',
	PLEASE_SELECT_COUPLE_OF_SIMS: 'Veuillez sélectionner quelques SIM.',
	COMPANY_IDENTITY: 'Identité de la société',
	ADD_USERS_OPTIONAL: 'Ajouter utilisateurs (facultatif)',
	REVIEW_AND_CREATE: 'Examiner et créer',
	PROJECTS_LIST: 'Liste de projets',
	MULTI_NETWORK_CONNECT_ONBOARDING_M2M_PLATFORMS: 'Provisionnement IoT Console – plateformes machine-machine',
	MULTI_NETWORK_CONNECT: 'IoT Console',
	CUSTOMERS: 'Clients',
	COMPANY_EMAIL_ADDRESS: 'Adresse e-mail société',
	CONTACT_EMAIL_ADDRESS: 'Adresse e-mail contact',
	CONTACT_PERSON_FULL_NAME: 'Nom complet contact',
	CONTACT_PERSON: 'Contact',
	ASSIGN_ANOTHER_PLATFORM: 'Affecter une autre plateforme',
	USER_INFO: 'Info utilisateur',
	SELECT_USER_TYPE: 'Sélectionner type utilisateur',
	USER_TYPE: 'Type utilisateur',
	USER_NOTE: 'L’utilisateur recevra un e-mail d’invitation à créer son compte',
	ADD_USER: 'Ajouter utilisateur',
	ADD_ANOTHER_USER: 'Ajouter autre utilisateur',
	WHAT_IS_THIS_MESSAGE1: 'Le client doit être chargé dans les plateformes machine-machine avant que le compte IoT Console puisse être créé. Lorsque vous avez l’ID du compte, vous pouvez continuer.',
	WHAT_IS_THIS_MESSAGE2: 'Vous pouvez ajouter automatiquement les petites entreprises et start-ups clientes utilisant des forfaits de données ou des kits de démarrage IoT en remplissant ce formulaire. Après la création du compte, revenez ici pour activer l’accès du client au portail IoT Console.',
	SELECT_PLATFORM: 'Sélectionner plateforme',
	EMAIL_EXISTS_OTHER: 'Cette adresse e-mail existe déjà (autre utilisateur)',
	EMAIL_EXISTS_ADMIN: 'Cette adresse e-mail existe déjà (admin.)',
	YOU_HAVE_SUCCESSFULLY_CREATED_A_MULTI_NETWORK_CONNECT_ACCOUNT_FOR: 'Création de compte IoT Console  réussie pour :',
	THE_IOT_GATEWAY_NUMBER_ASSOCIATED_WITH_THIS_ACCOUNT: 'Numéro de passerelle IoT associé à ce compte :',
	COMMUNICATION_PROFILE_NAME: 'Nom de profil de communication',
	ADD_NETWORK_ENTITLEMENT: 'Ajouter droit d’utiliser le réseau',
	EDIT_NETWORK_ENTITLEMENT: 'Modifier droit d’utiliser le réseau',
	PLATFORMS: 'Plateformes',
	NO_NETWORK_ENTITLEMENTS: 'Aucun droit d’utiliser le réseau',
	NO_RATE_PLANS: 'Aucun forfait',
	EDIT_RATE_PLAN: 'Modifier forfait',
	ERROR_WHILE_ADDING_NETWORK_ENTITLEMENT: 'Erreur lors de l’ajout du droit d’utiliser le réseau',
	ERROR_WHILE_EDITING_NETWORK_ENTITLEMENT: 'Erreur lors de la modification du droit d’utiliser le réseau',
	ERROR_WHILE_ADDING_RATE_PLAN: 'Erreur lors de l’ajout du forfait',
	ERROR_WHILE_EDITING_RATE_PLAN: 'Erreur lors de la modification du forfait',
	ERROR_WHILE_ADDING_COMPANY: 'Erreur lors de l’ajout de la société',
	ERROR_WHILE_EDITING_COMPANY: 'Erreur lors de la modification de la société',
	ERROR_WHILE_ADDING_EDITING_USER: 'Erreur lors de l’ajout/la modification de l’utilisateur',
	ERROR_WHILE_ADDING_EDITING_PLATFORM: 'Erreur lors de l’ajout/la modification de la plateforme',
	ERROR_WHILE_ADDING_PLATFORM: 'Erreur lors de l’ajout de la plateforme',
	ADD_PROFILE_NAME: 'Ajouter un nom de profil',
	ADD_PROFILE_ID: 'Ajouter un ID de profil',
	EDIT_PLATFORM: 'Modifier plateforme',
	SIM_STATE_CHANGE_IS_SUCCESSFULLY_SCHEDULED: 'Programmation réussie du changement de l’état SIM',
	SIM_STATE_CHANGE_IS_SUCCESSFULLY_SCHEDULED_FOR: 'Programmation réussie du changement de l’état SIM pour',
	SIM_STATE_CHANGE_IS_SUCCESSFULLY_SCHEDULED_ERROR: 'Échec de la programmation du changement de l’état SIM',
	SIM_STATE_CHANGED_TO: 'Vous avez choisi de changer l’état SIM à',
	CHANGE_LATER: 'Vous pouvez aussi programmer le changement pour plus tard.',
	SCHEDULED_CHANGE_WILL_HAPPEN: 'La programmation du changement s’exécutera au début de la date sélectionnée.',
	TAP_INITIATE_CHANGE: 'Saisir « Lancer changement » pour une action immédiate.',
	SIM_STATE_CHANGE: 'Changement état SIM',
	SCHEDULE_SIM_STATE_CHANGE: 'Programmer le changement état SIM',
	SYSTEM_CONFIRMATION: 'Confirmation du système',
	CHOOSE_NETWORK_ENTITLEMENT: 'Choisir droit d’utiliser le réseau',
	HISTORY: 'Historique des lots',

	serviceProvider: 'Plateforme',
	reportFrequency: 'Fréquence',

	// Onboarding
	ENDPOINT_SESSION_NO_RECORD: 'Aucune donnée pour la période sélectionnée.',
	TIME_START: 'Début période',
	TIME_END: 'Fin période',
	SESSION_DETAILS: 'Détails session',
	CARRIER: 'Opérateur',
	DEVICE_IP: 'IP de l’appareil',

	// chart datasets
	inventoryByStatus: 'Inventaire par état',
	inventoryByOperator: 'Inventaire par opérateur',
	inventoryByCountry: 'Inventaire par pays',
	inventoryByStatusDescription: 'Affichera le nombre de points terminaux actifs et inactifs.',
	inventoryByOperatorDescription: 'Affichera les opérateurs et leur nombre respectif de points terminaux actifs et inactifs.',
	inventoryByCountryDescription: 'Affichera un nom de pays et le nombre de points terminaux exploités dans ce pays.',
	// prompt modal
	addPromptHeaderInfo: 'Appliquer les changements ?',
	addChartPromptHeader: 'Les changements au tableau de bord ne sont pas encore enregistrés',
	addChartPromptFirst: 'Pour enregistrer les changements à la vue de votre tableau de bord, saisissez « Enregistrer tableau de bord » ou « Annuler » pour revenir à la vue précédente.',
	addChartPromptNotification: 'Appliquer les changements ?',

	// CHART Descriptions
	DASHBOARD_CHART_countByState_SATELLITEDescription: 'Montre les détails de l’état des points terminaux Satellite (appareils) parmi plusieurs plateformes machine-machine',
	DASHBOARD_CHART_countByState_CELLULARDescription: 'Montre les détails de l’état des points terminaux Cellulaire (appareils) parmi plusieurs plateformes machine-machine',
	DASHBOARD_CHART_countByNetworkCarrier_SATELLITEDescription: 'Montre le pourcentage de répartition des points terminaux Satellite (appareils) parmi plusieurs opérateurs de réseau',
	DASHBOARD_CHART_countByNetworkCarrier_CELLULARDescription: 'Montre le pourcentage de répartition des points terminaux Cellulaire (appareils) parmi plusieurs opérateurs de réseau',
	DASHBOARD_CHART_countByTechnologyDescription: 'Montre le total ventilé par pourcentage parmi les points terminaux Cellulaire et Satellite (appareils) dans le compte',
	DASHBOARD_CHART_countByM2mPlatform_SATELLITEDescription: 'Montre le total ventilé par pourcentage parmi les points terminaux Satellite (appareils) parmi plusieurs plateformes machine-machine',
	DASHBOARD_CHART_countByM2mPlatform_CELLULARDescription: 'Montre le total ventilé par pourcentage parmi les points terminaux Cellulaire (appareils) parmi plusieurs plateformes machine-machine',
	DASHBOARD_CHART_countByIMSI_SATELLITEDescription: 'Montre le total ventilé par pourcentage parmi les points terminaux Cellulaire (appareils) parmi plusieurs plateformes machine-machine',
	DASHBOARD_CHART_countByIMSI_CELLULARDescription: 'Montre le total ventilé par pourcentage parmi les points terminaux Satellite (appareils) parmi plusieurs plateformes machine-machine',
	'DASHBOARD_CHART_IMSI Transfer Status_CELLULARDescription': 'Montre les totaux de l’état du transfert (Re-IMSI) par pourcentage entre deux plateformes machine-machine.',
	DASHBOARD_CHART_countByIMSITransferStatus_CELLULARDescription: 'Montre les totaux de l’état du transfert (Re-IMSI) par pourcentage entre deux plateformes machine-machine.',
	DASHBOARD_CHART_countByIMSITransferStatus_SATELLITEDescription: 'Montre les totaux de l’état du transfert (Re-IMSI) par pourcentage entre deux plateformes machine-machine.',
	DASHBOARD_CHART_summaryOfMonthlyEndpointUsageDescription: 'Montre la synthèse de l’utilisation mensuelle des données parmi les points terminaux Cellulaire et Satellite (appareils)',
	DASHBOARD_CHART_countByStatebyPlatformACC_CELLULARDescription: 'Montre les détails de l’état des points terminaux Cellulaire (appareils) pour la plateforme ACC',
	DASHBOARD_CHART_countByStatebyPlatformEOD_CELLULARDescription: 'Montre les détails de l’état des points terminaux Cellulaire (appareils) pour la plateforme EOD',
	DASHBOARD_CHART_countByStatebyPlatformGBCM_SATELLITEDescription: 'Montre les détails de l’état des points terminaux Satellite (appareils) pour la plateforme GBCM',
	DASHBOARD_CHART_countByStatebyPlatformDCP_CELLULARDescription: 'Montre les détails de l’état des points terminaux Cellulaire (appareils) pour la plateforme de connexion d’appareils',
	DASHBOARD_CHART_countByStatebyPlatformCCIP_CELLULARDescription: 'Montre les détails de l’état des points terminaux Cellulaire (appareils) pour la plateforme CCIP',
	DASHBOARD_CHART_countByStatebyPlatformVCC_CELLULARDescription: 'Montre les détails de l’état des points terminaux Cellulaire (appareils) pour la plateforme VCC',
	DASHBOARD_CHART_countByStatebyPlatformGDSP_CELLULARDescription: 'Montre les détails de l’état des points terminaux Cellulaires (appareils) pour la plateforme GDSP',
	DASHBOARD_CHART_countByStatebyPlatformWING_CELLULARDescription: 'Montre les détails de l’état des points terminaux Cellulaire (appareils) pour la plateforme WING',
	DASHBOARD_CHART_summaryOfMonthlyEndpointUsage_CELLULARDescription: 'Montre la synthèse de l’utilisation mensuelle des données parmi les points terminaux Cellulaire et Satellite (appareils)',
	DASHBOARD_CHART_countByProfile_CELLULARDescription: 'Montre les détails de l’état des points terminaux Cellulaire (appareils) parmi plusieurs profils',

	// Pie Chart custom tooltip id
	PIE_CHART_TOOLTIP_Active: 'Activé',
	PIE_CHART_TOOLTIP_Test: 'Test',
	PIE_CHART_TOOLTIP_Inactive: 'Inactif',
	PIE_CHART_TOOLTIP_Terminate: 'Résilié',

	// Globecomm
	DEVICE_MODULE_NUMBER: 'N° de module de l’appareil',
	DEVICE_MANUFACTURER: 'Fabricant de l’appareil',
	DEVICE_STATUS: 'État de l’appareil',
	DEVICE_NETWORK_TYPE: 'Type réseau de l’appareil',
	MODULE_IMEI: 'IMEI module',
	SIM_STATUS: 'État SIM',
	MODULE_STATUS: 'État module',
	TRESHOLD_OF_NOTIFICATION_BY_DATA_USAGE: 'Seuil de notification par utilisation de données',
	TRESHOLD_OF_SUSPENSION_BY_DATA_USAGE: 'Seuil de suspension par utilisation de données',
	NOTIFICATION_INTERVAL: 'Intervalle de notification',
	DATA_USAGE: 'Utilisation données du cycle à ce jour',
	MANUFACTURER: 'Fabricant',
	MODEL_NUMBER: 'Numéro modèle',
	TYPE: 'Type',
	NETWORK_NAME: 'Nom du réseau',
	SUPPORTED_NETWORKS: 'Réseaux pris en charge',
	RENEWAL_PERIOD: 'Période de renouvellement',
	ACTIVATION_FEE: 'Frais d’activation',
	RECCURING_FEE: 'Frais récurrents',
	OVERAGE_FEE: 'Frais de dépassement',
	INCLUDED_BYTES: 'Octets inclus',
	MINIMUM_BILLABLE_BYTES: 'Nbre min. octets facturables',
	NOTIFICATION_CONTACT: 'Notification au contact',
	NOTIFICATION_BYTE_CAP: 'Notification du plafond octets',
	NOTIFICATION_REFRESH: 'Actualisation de la notification',
	OBSOLETE: 'Obsolète',
	HARDWARE: 'Matériel',
	SPECIFICATIONS: 'Spécifications',
	SPECIFICATION: 'Spécification',
	MODULE: 'Module',
	NO_HISTORY_DATA: 'Aucun enregistrement historique pour ce point terminal',

	// Message par défaut du tableau de bord
	NO_GRAPHS: 'LES GRAPHIQUES AJOUTÉS FIGURERONT ICI',
	dashboardDefault: 'LES GRAPHIQUES AJOUTÉS FIGURERONT ICI',
	REMOVE_CHART: 'Supprimer graphique',
	CARD_DROPDOWN_TITLE: 'Type graphique',
	ADD_WIDGET_BUTTON: 'Ajouter graphique',
	ADD_WIDGET_BUTTON_MOBILE: 'Ajouter',

	APP_NAME: 'IoT Console',
	NETWORK_TYPE_CHECK: 'Désolé, le changement d’état de points terminaux multiples n’est pas autorisé pour les points terminaux ayant des types de réseau ou des fournisseurs de services différents.',
	SERVICE_PROVIDER_CHECK: 'Désolé, le changement de points terminaux multiples n’est pas autorisé pour les points terminaux de multiples plateformes ou ayant des droits d’utiliser le réseau différents.',

	// USER FEEDBACK MODAL
	feedbackTitle: 'Commentaires de l’utilisateur',
	feedbackPageLabel: 'Vos commentaires concernent',
	feedbackFeedLabel: 'À votre avis, comment pouvons-nous améliorer les choses ?',
	feedbackFeedPlaceholder: 'Vos commentaires vont ici…',
	feedbackContactLabel: 'Au besoin, pouvons-nous vous contacter à ce sujet ?',
	feedbackEmailLabel: 'E-mail',
	feedbackPhoneLabel: 'Téléphone',
	feedbackMetaLabel: 'Métadonnées',
	feedbackCancelButton: 'Annuler',
	feedbackSendButton: 'Envoyer commentaires',
	feedbackSuccessThanks: 'Merci pour vos commentaires.',
	feedbackSuccessMessage: 'Vos commentaires permettront d’améliorer le produit. Nous évaluerons scrupuleusement vos commentaires et agirons en conséquence.',
	feedbackSuccessMessageSub: 'Nous pourrions également vous contacter à propos de votre suggestion.',
	feedbackSuccessMessageSign: 'Équipe IoT Console',
	feedbackSuccessDoneButton: 'Terminé',
	feedbackTriggerTitle: 'Des commentaires ?',
	feedbackTriggerSubtitle: 'Faites-nous-en part ici',
	feedbackThankYou: 'Merci {user} d’avoir fourni vos commentaires d’utilisateur et de nous aider à améliorer le produit.',
	feedbackSelect: 'Vous devez sélectionner le sujet correspondant dans la liste ci-dessus.',
	feedbackEnter: 'Vous devez saisir les commentaires.',
	feedbackIcon: 'Icône d’envoi réussi des commentaires',
	feedbackAdditionalFile: 'Fichier supplémentaire',
	feedbackFileUploadFail: 'Échec de téléversement du fichier',
	feedbackMinLength: 'Veuillez saisir au moins 10 caractères.',
	FEEDBACK_FILE_UPLOAD_EXTENSIONS: 'Téléverser les fichiers avec des extensions .jpg ou .png uniquement',
	// Changes tab

	FIELD_NAME: 'Nom du champ',

	DELETE_TAB: 'Supprimer onglet',
	TABLE_NAME: 'Nom de la table',
	EDIT_TABLE: 'Modifier paramètres de la table',
	CUSTOM_FIELD_LABEL: 'Étiquette de champ personnalisé',
	EDIT_TABLE_VIEW_NAME: 'Modifier nom de vue de la table',
	PLEASE_ENTER_TABLE_NAME: 'Veuillez saisir le nom de la table',
	DEVICE_TYPE: 'Type d’appareil',
	CELLULAR_COLUMNS: 'Paramètres spécifiques cellulaire',
	SATELLITE_SPECIFIC_COLUMNS: 'Paramètres spécifiques satellite',

	// TICKETING
	TICKET_ID: 'ID billet',
	CATEGORY_NAME: 'Catégorie',
	CATEGORY_TICKETING: 'Catégorie',
	SUBCATEGORY_OPTIONAL: 'Sous-catégorie (facultatif)',
	SELECT_SUBCATEGORY_OPTIONAL: 'Sélectionner sous-catégorie (facultatif)',
	ROAMING_ISSUE: 'Problème d’itinérance',
	SEVERITY: 'Gravité',
	PRIORITY: 'Priorité',
	ACCOUNT_NUMBER: 'Numéro de compte',
	CREATED_BY_TICKETING: 'Créé par',
	MULTI_NETWORK_CONNECT_ID: 'ID IoT Console',
	ENDPOINT_MODEL: 'Modèle point terminal',
	WHAT_IS_THE_ERROR_MESSAGE: 'Quel est le message d’erreur ?',
	HOW_MANY_BARS_ARE_SHOWING: 'Combien de barres sont affichées ?',
	DESCRIBE_ENDPOINT_STATE_HERE: 'Décrivez l’état du point terminal ici…',
	WAS_THE_TROUBLESHOOTING_DONE: 'Le dépannage a-t-il été effectué ?',
	DESCRIBE_TROUBLESHOOTING_DETAILS_HERE: 'Fournissez les détails du dépannage ici…',
	DO_YOU_HAVE_ANY_ADDITIONAL_NOTES: 'Avez-vous des notes supplémentaires ?',
	ADDITIONAL_NOTES: 'Notes supplémentaires',
	DASHBOARD_CHART_M2MSIMStatus_SATELLITE: 'points terminaux par état IOT-C (satellite)',
	DASHBOARD_CHART_M2MSIMStatus_CELLULAR: 'points terminaux par état IOT-C (cellulaire)',
	DASHBOARD_CHART_Country_SATELLITE: 'Points terminaux par emplacement actuel (satellite)',
	DASHBOARD_CHART_Countrys_CELLULAR: 'Points terminaux par emplacement actuel (cellulaire)',
	DASHBOARD_CHART_IMSI_SATELLITE: 'Points terminaux par plateforme machine-machine (satellite)',
	DASHBOARD_CHART_IMSI_CELLULAR: 'Points terminaux par plateforme machine-machine (cellulaire)',
	DASHBOARD_CHART_Network_SATELLITE: 'Points terminaux par opérateur de réseau (satellite)',
	DASHBOARD_CHART_Network_CELLULAR: 'Points terminaux par opérateur de réseau (cellulaire)',
	DASHBOARD_CHART_Technology: 'Points terminaux par technologie',
	'DASHBOARD_CHART_IMSI Transfer Status_SATELLITE': 'Points terminaux par état de transfert (satellite)',
	'DASHBOARD_CHART_IMSI Transfer Status_CELLULAR': 'Points terminaux par état de transfert (cellulaire)',
	DASHBOARD_CHART_countByIMSITransferStatus_SATELLITE: 'Points terminaux par état de transfert (satellite)',
	DASHBOARD_CHART_countByIMSITransferStatus_CELLULAR: 'Points terminaux par état de transfert (cellulaire)',
	DASHBOARD_CHART_summaryOfMonthlyEndpointUsage_CELLULAR: 'Utilisation totale données pour tous les points terminaux ACC',
	DASHBOARD_CHART_countByState_SATELLITE: 'Points terminaux par état (satellite)',
	DASHBOARD_CHART_countByState_CELLULAR: 'Points terminaux par état (cellulaire)',
	DASHBOARD_CHART_countByServiceType_SATELLITE: 'Points terminaux par technologie (satellite)',
	DASHBOARD_CHART_countByServiceType_CELLULAR: 'Points terminaux par technologie (cellulaire)',
	DASHBOARD_CHART_countByRatePlan_SATELLITE: 'Points terminaux par forfait (satellite)',
	DASHBOARD_CHART_countByRatePlan_CELLULAR: 'Points terminaux par forfait (cellulaire)',
	DASHBOARD_CHART_countByNetworkEntitlement_SATELLITE: 'Points terminaux par droit d’utiliser le réseau (satellite)',
	DASHBOARD_CHART_countByNetworkEntitlement_CELLULAR: 'Points terminaux par droit d’utiliser le réseau (cellulaire)',
	DASHBOARD_CHART_countByNetworkCarrier_SATELLITE: 'Points terminaux par opérateur de réseau (satellite)',
	DASHBOARD_CHART_countByNetworkCarrier_CELLULAR: 'Points terminaux par opérateur de réseau (cellulaire)',
	DASHBOARD_CHART_countByTechnology: 'Points terminaux par technologie',
	DASHBOARD_CHART_countByM2MPlatform_SATELLITE: 'Points terminaux par plateforme machine-machine (satellite)',
	DASHBOARD_CHART_countByM2MPlatform_CELLULAR: 'Points terminaux par plateforme machine-machine (cellulaire)',
	DASHBOARD_CHART_countByIMSI_SATELLITE: 'Points terminaux par plateforme machine-machine',
	DASHBOARD_CHART_countByIMSI_CELLULAR: 'Points terminaux par plateforme machine-machine',
	DASHBOARD_CHART_countByM2mPlatform_SATELLITE: 'Points terminaux par plateforme machine-machine',
	DASHBOARD_CHART_countByM2mPlatform_CELLULAR: 'Points terminaux par plateforme machine-machine',
	DASHBOARD_CHART_countByStatebyPlatformACC_CELLULAR: 'Points terminaux par état (ACC)',
	DASHBOARD_CHART_countByStatebyPlatformEOD_CELLULAR: 'Points terminaux par état (EOD)',
	DASHBOARD_CHART_countByStatebyPlatformGBCM_SATELLITE: 'Points terminaux par état (GBCM)',
	DASHBOARD_CHART_countByStatebyPlatformDCP_CELLULAR: 'Points terminaux par état (Plate-forme de connexion d’appareils)',
	DASHBOARD_CHART_countByStatebyPlatformCCIP_CELLULAR: 'Points terminaux par état (CCIP)',
	DASHBOARD_CHART_countByStatebyPlatformVCC_CELLULAR: 'Points terminaux par état (VCC)',
	DASHBOARD_CHART_countByStatebyPlatformGDSP_CELLULAR: 'Points terminaux par état (GDSP)',
	DASHBOARD_CHART_countByStatebyPlatformWING_CELLULAR: 'Points terminaux par état (WING)',
	DASHBOARD_CHART_countByProfile_CELLULAR: 'Points terminaux par profil',
	TICKET_STATUS: 'État billet',
	ENDPOINT_LOCATION: 'Localisation du point terminal',
	ISSUE: 'Problème',
	SUGGESTIONS: 'Suggestions',
	PHONE: 'Téléphone',
	FAILED_TO_PROVISION: 'Échec du provisionnement',
	SUBMITTED_BY: 'Envoyé par',
	NEW_TICKET: 'Nouveau billet',
	ENDPOINT: 'Point terminal',
	ERROR_MESSAGE: 'Message d’erreur',
	ATTACHED_FILE: 'Fichier joint',
	TICKETING: 'Établissement de billets',
	GET_HELP_WITH_THIS_ENDPOINT: 'Aide pour ce point terminal',
	CATEGORY_AND_SUGGESTIONS: 'Catégorie et suggestions',
	CONFIRMATION: 'Confirmation',
	RECORDED_STATUS: 'État enregistré',
	ENDPOINT_NAME: 'ID point terminal',
	CONTACT_PHONE: 'Téléphone contact',
	CONTACT_EMAIL: 'E-mail contact',
	SELECT_SUBCATEGORY: 'Sélectionner sous-catégorie',
	FILE_UPLOADED: 'Fichier téléversé',
	FAQ_SUPPORT_INFO: 'Trouver des réponses rapides aux questions courantes de nos utilisateurs.',
	USER_GUIDE_SUPPORT_INFO: 'Lire les instructions détaillées étape par étape sur les fonctions du portail. Le guide est adapté à votre type de compte.',
	TICKETING_SUPPORT_INFO: 'Si vous éprouvez des problèmes de service que les tests de diagnostic n’aident pas résoudre, vous pouvez créer un billet de dérangement. ',
	MUST_BE: 'Doit comprendre ',
	LESS_THAN: 'moins de ',
	CHARACTERS: ' caractères',
	LOADING_DATA: 'Chargement données',
	GPRS: 'GPRS',

	//  Assistance
	CONTENT: 'Contenu',
	CREATE_NEW_NOTIFICATION: 'Créer nouvelle notification',
	EDIT_NOTIFICATION: 'Modifier notification',
	EDIT_FORM_TO_CREATE_NOTIFICATION: 'Modifier formulaire pour créer nouvelle notification',
	NOTIFICATION_TYPE: 'Type de notification ?',
	SELECT_NOTIFICATION_PLACEHOLDER: 'Sélectionner espace réservé de la notification',
	CHOOSE_WHICH_USER_GROUPS_TO_NOTIFY: 'Choisir les groupes d’utilisateurs à notifier :',
	NOTIFY_ALL_GROUPS: 'Notifier tous les groupes',
	MANAGER: 'Gestionnaire',
	DEVELOPER: 'Développeur',
	ADD_NOTIFICATION_CONTENT: 'Ajouter contenu de notification',
	SUSPEND: 'Suspendre',
	SUSPENDED: 'Suspendu',
	DEACTIVE: 'Désactivé',
	PROVISIONED: 'Provisionné',
	ENDPOINT_COUNTRY: 'Pays point terminal',
	ENDPOINT_STATE: 'État point terminal',
	ENDPOINT_CITY: 'Ville point terminal',
	ENDPOINT_STREET_ADDRESS: 'Rue point terminal',
	AS_OF: 'Traité en date du',
	VIEW_ALL_NOTIFICATIONS: 'Afficher toutes les notifications',
	NOTIFICATION_SCOPE: 'Portée de la notification',
	NOTIFICATION1: 'Info',
	NOTIFICATION2: 'Erreur',
	NOTIFICATION3: 'Avertissement',
	NOTIFICATION4: 'Confirmation',
	GLOBAL_NOTIFICATION: 'Notification globale',
	COMPANY_RELATED_NOTIFICATION: 'Notification liée à la société',
	MUST_BE_MORE_THAN: 'Doit comprendre plus de ',
	MUST_BE_LESS_THAN: 'Doit comprendre moins de ',
	MUST_BE_LESS_THAN_OR_EQUAL_TO: 'Nbre de caractères doit être inférieur ou égal à ',
	GLOBAL: 'Globale',
	COMPANY_RELATED: 'Liée à la société',
	RECENT_NOTIFICATIONS: 'Alertes et notifications du système',

	networkEntitlement: 'ID droit d’utiliser le réseau',
	networkType: 'Type de réseau',
	transferStatus: 'État du transfert IMSI',
	rateplan: 'Forfait',
	FAILURE: 'Échec',
	PENDING_REIMSI_ATTEMPT: 'Tentative Re-IMSI en attente',
	REIMSI_QUEUED: 'Re-IMSI en file d’attente',
	PENDING_REIMSI_DELIVERED: 'Re-IMSI en attente exécuté',
	'PENDING_RE-IMSI_DELIVERY_FAILED': 'Échec de l’exécution Re-IMSI',

	'PENDING REIMSI ATTEMPT': 'Tentative Re-IMSI en attente',
	'REIMSI PENDING ATTEMPT': 'Tentative Re-IMSI en attente',
	'PENDING REIMSI DELIVERED': 'Transfert en attente exécuté ',
	'Re-IMSI Delivery Failed': 'Échec de l’exécution du transfert',
	'REIMSI FAILED': 'Échec Re-IMSI',
	'REIMSI QUEUED': 'Re-IMSI en file d’attente',
	'REIMSI PEDING ATEMPT': 'Tentative Re-IMSI en attente',
	'REIMSI PENDING ATEMPT': 'Tentative Re-IMSI en attente',
	'REIMSI PENDING DELIVERED': 'Re-IMSI en attente exécuté',
	'REIMSI SUCCESS': 'Re-IMSI réussi',
	failure: 'échec',

	'ID_PENDING REIMSI ATTEMPT': 'Tentative Re-IMSI en attente',
	'ID_REIMSI PENDING ATTEMPT': 'Tentative Re-IMSI en attente',
	'ID_PENDING REIMSI DELIVERED': 'Transfert en attente exécuté ',
	'ID_Re-IMSI Delivery Failed': 'Échec de l’exécution du transfert',
	'ID_REIMSI FAILED': 'Échec Re-IMSI',
	'ID_REIMSI QUEUED': 'Re-IMSI en file d’attente',
	'ID_REIMSI PEDING ATEMPT': 'Tentative Re-IMSI en attente',
	'ID_REIMSI PENDING ATEMPT': 'Tentative Re-IMSI en attente',
	'ID_REIMSI PENDING DELIVERED': 'Re-IMSI en attente exécuté',
	'ID_REIMSI SUCCESS': 'Re-IMSI réussi',

	CONSUMED_BYTES: 'Mégaoctets consommés',
	CONSUMED_SMSES: 'SMS consommés',
	TO_DATE: 'à ce jour',

	CHART_EXISTS_IN_SETTINGS: 'Ce graphique existe déjà dans vos paramètres. Un seul type de graphique permis.',
	NO_CHART_DATA: 'Données de graphique manquantes',
	STATES_MESSAGE: 'Aucun état disponible.',
	SELECT_RATE_PLAN: 'Sélectionner forfait',
	CHANGE_RATE_PLAN: 'Changer forfait',
	CURRENT_RATE_PLAN_FOR_ENDPOINT_ID: 'Forfait actuel pour point terminal ID ',
	IS: 'est',
	CONFIRM_RATE_PLAN_CHANGE: 'Confirmer changement de forfait',
	RATE_PLAN_WILL_BE: 'Une fois le changement exécuté, le forfait du point terminal sélectionné sera',
	WOULD_YOU_LIKE_TO_INITIATE_CHANGE_NOW: 'Souhaitez-vous procéder au changement maintenant ?',
	RATE_PLAN_CHANGE_IS_SUCCESSFULLY_SCHEDULED: 'Programmation réussie du changement de forfait',
	FOR: 'pour',
	RATE_PLAN_CHANGE_IS_SUCCESSFULLY_UPDATED: 'Mise à jour réussie du changement de forfait',
	RATE_PLAN_CHANGE_COULD_NOT_BE_SCHEDULED_SUCCESSFULLY: 'Échec de la programmation du changement de forfait',
	RATE_PLAN_CHANGE_COULD_NOT_BE_CARRIED_OUT_SUCCESSFULLY: 'Échec de l’exécution du changement de forfait',
	ENDPOINT_RATE_PLAN: 'Forfait',
	DOWNLOAD_TEMPLATE_BATCH: 'Télécharger modèle',
	CONFIRM_BATCH_FILE_UPLOAD: 'Confirmer téléversement du fichier séquentiel',
	SCHEDULED_ACTION_ON: 'Action programmée sur',
	FILE_MUST_BE_SUMITTED_WITH_CSV: 'Le fichier doit être envoyé avec une extension .csv.',
	MAXIMUM_SIMS_PER_UPLOAD: 'Le nbre maximum de SIM par téléversement est de 10 000.',
	SIMS_HAVE: 'SIM ont',
	CHANGE_FOR_ALL_SELECTED_SIMS: 'Sélectionnez un autre forfait pour appliquer le changement à toutes les SIM sélectionnées',
	YOU_HAVE_CHOSEN_TO_CHANGE_THE_RATE_PLAN_OF_THE: 'Vous avez choisi de changer le forfait des',
	SELECTED_SIMS_TO: 'SIM sélectionnées pour',
	SIMS_RATE_PLAN_SUCCESSFULLY_REQUESTED: 'Mise à jour réussie des forfaits SIM',
	SCHEDULE_RATE_PLAN_SUCCESSFUL: 'Programmation du forfait réussie pour plusieurs SIM',
	SIM_RATE_PLAN_CHANGE_FOR: 'Changement forfait SIM pour',
	MULTIPLE_RATE_PLAN_CHANGE_SCHEDULE_ERROR: 'Échec de la programmation du changement de forfait pour plusieurs SIM',
	MULTIPLE_RATE_PLAN_CHANGE_ERROR: 'Échec de la demande de changement de forfait pour plusieurs SIM',
	SAME_RATE_PLAN_SELECTED: 'Même forfait sélectionné',
	REASON: 'raison',

	API_DOCUMENTATION: 'Documentation API',
	'Cellular - Active': 'Cellulaire – Activé',
	'Satellite - Active': 'Satellite – Activé',
	'Cellular - Terminate': 'Cellulaire – Résilié',
	'Satellite - Terminate': 'Satellite – Résilié',
	CHANGE_RATE_PLAN_NOT_ALLOWED: 'Changement de forfait non permis',
	reimsiqueued: 'Re-IMSI en file d’attente',
	reimsifailed: 'Échec Re-IMSI',
	reimsisuccess: 'Re-IMSI réussi',
	reimsipendingattempt: 'Tentative Re-IMSI en attente',
	reimsipendingdelivered: 'Re-IMSI en attente exécuté',
	imsiPendingMessage: 'Si l’appareil est à l’état En attente pendant plus de 72 heures, veuillez contacter l’Assistance à la clientèle',
	ADD_ANOTHER_ACCOUNT: 'Ajouter un autre compte',
	FILTER_DOES_NOT_HAVE_VALID_DATA: 'Erreur API, veuillez consulter l’assistance.',
	FAILOVER: 'Panne',
	CANCEL_PENDING_FAIL_TITLE: 'Échec de l’annulation du changement en attente',
	CANCEL_PENDING_FAIL_TEXT: 'Impossible d’exécuter l’annulation du changement en attente pour le moment',
	CANCEL_PENDING_SUCCESS_TITLE: 'Annulation réussie du changement en attente',
	CANCEL_PENDING_SUCCESS_TEXT: 'Exécution réussie de l’annulation du changement en attente',
	CANCEL_CHANGE: 'Annuler changement',
	CAUTION: 'Attention',
	CANCEL_PROMPT_TITLE: 'Voulez-vous vraiment annuler le changement en attente ?',
	CANCEL_PROMPT_TEXT: 'Cette action ne peut pas être annulée.',
	CONFIRM_CANCELLATION: 'Confirmer annulation',
	NO_RATE_PLANS_AVAILABLE: 'Aucun forfait disponible',
	BULK_UPLOAD: 'Téléversement groupé',
	MULTI_ENDPOINT_ACTION: 'Action sur plusieurs points terminaux',
	PLEASE_SELECT_MORE_SIMSS: 'Veuillez sélectionner deux points terminaux ou plus',
	M2M_ACCOUNT_ID: 'ID compte machine-machine',
	REPORTS_PLATFORM: 'Plateforme machine-machine',
	ENDPOINT_CHART_SECOND_CARD: 'Notifications récentes',
	ENDPOINT_CHART_SECOND_CARD_ON: 'Le',
	ENDPOINT_CHART_THIRD_CARD: 'Emplacement point terminal',
	NO_AVAILABLE_FILTERS: 'Aucun filtre disponible.',
	NO_AVAILABLE_FILTERS_SUBTITLE: 'Aucun filtre disponible à vous montrer actuellement',

	// Développeur API
	GETTING_STARTED: 'Pour démarrer',
	API_SANDBOX: 'Sandbox API',
	DEVELOPER_API: 'Développeur API',
	GETTING_STARTED_WITH_API_T: 'Pour démarrer avec API',
	GETTING_STARTED_WITH_API_P: 'Avant d’effectuer votre premier appel API, votre adresse IP source (serveur) doit figurer sur la liste blanche. L’adresse IP source est généralement configurée pendant le processus de provisionnement, lorsque l’accès API est créé. Si vous recevez des messages d’erreurs, il sont certainement liés à un blocage de l’accès à la passerelle AT&T IoT.',
	GETTING_STARTED_WITH_API_UL_T: 'Veuillez vous assurer que vous avez exécuté les étapes suivantes pour démarrer le développement :',
	GETTING_STARTED_WITH_API_LI1: '– Vous avez établi un serveur accessible par le public et vous nous avez fourni son adresse IP au cours du provisionnement.',
	GETTING_STARTED_WITH_API_LI2: '– Votre adresse IP source a été inscrite sur la liste blanche aux fins d’accès à la passerelle AT&T IoT.',
	GETTING_STARTED_WITH_API_LI3: '– L’administrateur de votre société vous a fourni vos nom d’utilisateur et mot de passe API qui ont été créés lorsque le service API a été activé.',
	GETTING_STARTED_WITH_API_LI4: '– Vous avez accès à votre serveur de ressources pour exécuter des commandes cURL ou utiliser des apps de connexion écrites dans votre langage préféré ci-dessous.',
	GET_OAUTH_TOKEN_T: 'Obtenir le jeton d’accès OAuth',
	GET_OAUTH_TOKEN_P: 'Obtenez le jeton OAuth d’AT&T IoT Identity en utilisant le nom d’utilisateur et le mot de passe fournis par votre administrateur selon votre méthode préférée ci-dessous. Le jeton est valide pendant 7 jours.',
	GETTING_STARTED_WITH_API_CONNECTORS_T: 'Connecteurs API',
	GETTING_STARTED_WITH_API_CONNECTORS_P: 'Choisissez parmi les exemples ci-dessous la façon dont vous souhaitez vous connecter à notre API.',
	TEST_CONNECTION_T: 'Testez la connexion à la passerelle IoT',
	TEST_CONNECTION_P: 'Exemple de cURL :',
	GETTING_STARTED_WITH_API_NEXT_STEP_T: 'Résultat souhaité',
	GETTING_STARTED_WITH_API_NEXT_STEP_P: 'Si vous obtenez le code de réponse HTTP 200 OK, vous êtes prêt à commencer le développement.',
	GETTING_STARTED_WITH_API_ERRORS_T: 'Dépannage des erreurs de connexion',
	GETTING_STARTED_WITH_API_ERRORS_P: 'Si vous obtenez des erreurs, vérifiez d’abord si votre authentification est correctement définie dans l’en-tête HTTP et si le jeton d’accès n’a pas expiré et doit être régénéré. Sondez aussi par PING la passerelle AT&T IoT pour vérifier si votre adresse IP source figure sur la liste blanche. Si vous obtenez des résultats inattendus, vous pouvez remplir un billet de dérangement dans la section de l’assistance.',
	API: 'API',
	SANDBOX_SPECIFIES_THE_LIST: 'Le sandbox Swagger ci-dessous fournit la liste des ressources disponibles dans l’API REST (transfert d’état représentatif) et les opérations qui peuvent être exécutées avec ces ressources. Le document fournit aussi la liste des paramètres pour une opération, y compris leur nom et leur type, qu’ils soient requis ou optionnels, ainsi que les renseignements sur leurs valeurs acceptables.',
	JSON_SCHEMA_INCLUDES: 'JSON Schema est inclus. Il décrit la structure du corps de la demande qui est envoyée à une opération dans une API REST. JSON Schema décrit aussi la structure de tout corps de réponse renvoyé par une opération.',

	GBCM_OPERATOR: 'Speedcast',
	ATT_OPERATOR: 'AT&T',

	CREATE_CUSTOM_REPORT: 'Créer rapport personnalisé',
	YOU_MUST_CREATE_FILTER_BEFORE_EXPORT: 'Vous devez créer un filtre avant qu’une liste puisse être exportée.',
	CREATE_REPORT: 'Créer rapport',
	CHART_LEGEND_Active: 'Activé',
	CHART_LEGEND_Terminate: 'Résilié',
	WE_ARE_SORRY_THERE_IS_NO_DATA_TO_EXPORT: 'Désolé, il n’y a pas de données à exporter',
	EXPORT_FILTERED_DATA: 'Exporter données filtrées',
	ID_DASHBOARD_CHARTS_summaryofmonthlyendpointusage: 'Synthèse d’utilisation mensuelle du point terminal',
	ID: 'ID',
	TAB: 'Onglet',
	SORT_BY: 'Trié par',
	SELECT_CHART_TYPE_TO_ADD: 'Sélectionner type graphique à ajouter',
	RECREDENTIAL_STATUS: 'État réaffectation',
	RECREDENTIAL_TRANSFER_STATUS: 'État réaffectation',
	recredentialTransferStatus: 'État réaffectation',
	M2MACCOUNTID: 'ID compte machine-machine',
	HOLDING_ACCOUNT_ID: 'ID compte avoirs',
	RE_CREDENTIAL: 'Réaffectation',
	SELECT_OPERATOR: 'Sélectionner opérateur',
	RE_CREDENTIAL_SIM: 'Réaffectation SIM',
	CURRENT_PLATFORM: 'Plateforme actuelle',
	YOU_HAVE_CHOSEN_TO_RECREDENTIAL_THIS_SIM: 'Vous avez choisi de réaffecter cette SIM à',
	YOU_HAVE_CHOSEN_TO_RECREDENTIAL_THESE_SIMS: 'Vous avez choisi de réaffecter ces SIM à',
	YOU_MAY_INTIATE_THE_CHANGE_NOW: 'Vous pouvez procéder au changement maintenant',
	RE_CREDENTIALING_STATUS: 'État réaffectation',
	RE_CREDENTIALING_IS_SUCCESSFULLY_INITIATED: 'Exécution de la réaffectation réussie',
	RE_CREDENTIALING_IS_SUCCESSFULLY_SCHEDULED: 'Programmation réussie de la réaffectation',
	RE_CREDENTIALING_IS_SUCCESSFULLY_SCHEDULED_FOR: 'Programmation réussie de la réaffectation pour',
	RE_CREDENTIALING_COULD_NOT_BE_INITIATED_SUCCESSFULLY: 'Échec de l’exécution de la réaffectation',
	RE_CREDENTIALING_COULD_NOT_BE_SCHEDULED_SUCCESSFULLY: 'Échec de la programmation de la réaffectation',
	ENDPOINT_HISTORY: 'Historique de point terminal',
	SHIPPED_DATE: 'Date d’envoi',
	DIAGNOSTIC_BUTTON: 'Commencer le diagnostic',
	DEVICE_REGISTRATION_INFO_BUTTON: 'Info enregistrement appareil',
	ENDPOINT_REGISTRATION_DETAILS: 'Détails d’enregistrement point terminal',
	eventTime: 'Date/heure horodatage',
	carrierName: 'Nom opérateur',
	registrationType: 'Type enregistrement',
	NO_DATA_TO_DISPLAY_AT_THE_MOMENT: 'Aucune donnée à afficher actuellement',
	status: 'État',
	iccid: 'Identifiant de carte SIM',
	carrier: 'Opérateur',
	ATTACHED_CARRIER_NAME: 'Nom opérateur',
	M2MPlatform: 'Plateforme machine-machine',
	simState: 'Obtention état de l’appareil',
	'Endpoint Provisioning Information': 'Info provisionnement du point terminal',
	'M2M Platform': 'Plateforme machine-machine',
	'Network Connection': 'Connexion réseau',
	simStatusM2M: 'Obtention état de l’appareil',
	billingStatus: 'Obtention état de l’appareil',
	EndpointProvisioningInformation: 'Info provisionnement du point terminal',
	trafficAllowed: 'Obtention information provisionnement appareil',
	permitPassingTraffic: 'Obtention information provisionnement appareil',
	verifySimState: 'Obtention information appareil',
	NetworkConnection: 'Connexion réseau',
	verifyConnection: 'Obtenir réseau appareil',
	successfullyConnectedToNetwork_inSession: 'Obtenir réseau appareil',
	verifyIPSession: 'Obtenir IP appareil',
	dataSessionCurrentlySuccessful: 'Obtenir IP appareil',
	dd_inventory: 'Inventaire',
	dd_activated: 'Activé',
	dd_active: 'Activé',
	dd_inactivated: 'Inactif',
	dd_inactive: 'Inactif',
	dd_deactivated: 'Désactivé',
	dd_retired: 'Mis hors service',
	dd_purged: 'Purgé',
	dd_testready: 'Prêt pour test',
	dd_activationready: 'Prêt pour activation',
	dd_replaced: 'Remplacé',
	dd_trial: 'Essai',
	dd_start: 'Début',
	dd_transfer: 'Transfert',
	dd_globalintransitsource: 'Source en transit, global',
	dd_globalintransitdestination: 'Destination en transit, global',
	dd_globaltransferred: 'Transféré, global',
	dd_other: 'Autre',
	dd_permitpassingtraffic: 'Trafic passant autorisé',
	dd_notpermitpassingtraffic: 'Trafic passant non autorisé',
	dd_allowedToUseWirelessNetworkResources: 'Ressources sans fil TollSec autorisées',
	dd_barredFromNetwork: 'Interdit de réseau',
	dd_trafficnotallowed_simstate: 'Trafic non autorisé – état SIM',
	dd_suspendedByServiceProvider: 'Suspendu par fournisseur de services',
	dd_suspendedDueToUsageLimit: 'Suspendu en raison de la limite utilisation',
	dd_trafficNotAllowed_others: 'Trafic non autorisé (autre)',
	dd_successfullyconnectedtonetwork_insession: 'Connexion au réseau réussie (session ouverte)',
	dd_notsuccessfullyconnectedtonetwork_insession: 'Non connecté au réseau (session ouverte)',
	dd_validregistration_validnetworkactivity: 'Enregistrement valide (activité réseau valide)',
	dd_validregistration_nonetworkactivity: 'Enregistrement valide (aucune activité réseau)',
	dd_validgsmregistrationonly: 'Enregistrement GSM valide seulement',
	dd_novalidregistration_hasactivityinlast24h: 'Aucun enregistrement valide (activité durant dernières 24 h)',
	dd_novalidregistration_hasauthrequestinlast24h_nolocupdate: 'Aucun enregistrement valide (demande autor. durant dernières 24 h)',
	dd_novalidregistration_haslocupdatesmorethan24hago: 'Aucun enregistrement valide (mises à jour locales de plus de 24 h)',
	dd_novalidregistration_hasauthrequestmorethan24hago_noactivity: 'Aucun enregistrement valide (demande autor. de plus de 24 h)',
	dd_novalidregistration_nonetworkactivity: 'Il y a plus de 24 heures (aucune activité réseau)',
	dd_datasessioncurrentlysuccessful: 'Appareil actuellement non connecté. Sessions IP précédentes réussies.',
	dd_notdatasessioncurrentlysuccessful: 'Échec de la session de données actuelle',
	dd_datasessionpastsuccessful: 'Session de données antérieure réussie',
	dd_connectionattemptfailed_invalidcredentials: 'Échec de la tentative de connexion (authentifiants non valides)',
	dd_nodatasessionsuccessfulhistory: 'Aucun historique de session de données réussie',
	dd_: '',
	dd_allowedtousewirelessnetworkresources: 'Ressources sans fil TollSec autorisées',
	attached: 'Attaché',
	detached: 'Détaché',
	RECREDENTIALED: 'Réaffecté',
	RECREDENTIALING: 'Réaffectation',
	CHANGE_RECREDENTIAL: 'Réaffectation autorisée uniquement pour les mêmes plateformes',
	RECREDENTIAL_DESCRIPTION: 'Astuce : lorsque vous réaffectez et sélectionnez plusieurs SIM, vous pouvez seulement sélectionner une plateforme de réaffectation pour les SIM sélectionnées',
	RECREDENTIAL_NOT_ALLOWED: 'Réaffectation non autorisée',
	RECREDENTIAL_SUCCESSFULLY_REQUESTED: 'Activation des SIM réussie',
	MULTIPLE_RECREDENTIAL_CHANGE_ERROR: 'Échec de l’exécution de la réaffectation de plusieurs SIM',
	MOBILE_NETWORK_REGISTRATION_HISTORY: 'Historique enregistrement réseau mobile',
	ENDPOINT_DAILY_USAGE: 'Utilisation quotidienne point terminal',
	ENDPOINT_CYCLE_USAGE: 'Utilisation cycle à ce jour du point terminal',
	CONFIRM_RE_CREDENTIALING: 'Confirmer la réaffectation',
	PENDING_RECREDENTIAL: 'Ce point terminal est en attente de réaffectation',
	ENDPOINTS_ARE: 'points terminaux sélectionnés pour l’exportation',
	ENDPOINT_IS: 'point terminal sélectionné pour l’exportation',
	CUSTOM_REPORT_WILL_BE_AVAILABLE: 'Ce rapport personnalisé sera disponible dans la section des rapports',
	YOU_CAN_APPEND_CUSTOM_NAME: 'Vous pouvez ajouter le nom personnalisé au rapport ici',
	CUSTOM_REPORT_NAME_APPENDIX: 'Annexe du nom du rapport personnalisé',
	ADD_CUSTOM_REPORT_NAME_APPENDIX: 'Ajouter l’annexe du nom du rapport personnalisé (caractères alphanumériques uniquement)',
	CUSTOM_REPORT: 'Rapport personnalisé',
	CUSTOM_REPORT_CREATED: 'Création du rapport personnalisé réussie',
	GO_TO_REPORTS: 'Aller aux rapports',
	DIAGNOSTICS_FOR_THIS_ENDPOINT: 'Diagnostic actuellement non disponible pour ce point terminal',
	ENDPOINT_DATA_INFORMATION: 'Info données point terminal',
	LAST_SESSION: 'Dernière session',
	CARRIER_ATTACHED: 'Opérateur joint',
	APN_DCP: 'APN',
	IP_ADDRESS: 'Adresse IP',
	LAST_SENT_SMS_MO: 'Date et heure envoi dernier SMS départ',
	LAST_SENT_SMS_MT: 'Date et heure envoi dernier SMS arrivée',
	TEST_DETAILS: 'Détails du test',
	CHECK_BLOCKED_STATUS: 'Vérifier état bloqué',
	BLOCKED_STATUS: 'État bloqué',
	RETRY_TEST: 'Réessayer test',
	MOBILE_NETWORK_TEST: 'Test réseau mobile',
	CHECK_BLOCKED_SUB: 'Vérifier abonnement bloqué',
	TROUBLESHOOT_DCP: 'Dépanner plateforme de connexion d’appareils',
	locationUpdate: 'Mise à jour emplacement',
	pdpStatus: 'État PDP',
	cancelLocation: 'Annuler emplacement',
	CANCEL_LOCATION: 'Annuler emplacement',

	DASHBOARD_CHART_CountofDailyEndpointSMSbyPlatformDCP_CELLULARDescription: 'Montre le nbre de SMS envoyés par jour pendant le mois à l’échelle de la plateforme de connexion d’appareils',
	DASHBOARD_CHART_CountofDailyEndpointSMSbyPlatformDCP_CELLULAR: 'SMS par jour (plateforme de connexion d’appareils)',
	DASHBOARD_CHART_CountofMonthlyEndpointSMSbyPlatformDCP_CELLULAR: 'SMS par mois (plate-forme de connexion d’appareils)',
	DASHBOARD_CHART_SumofDailyEndpointUsagebyPlatformDCP_CELLULAR: 'Utilisation quotidienne totale point terminal (plateforme de connexion d’appareils)',
	DASHBOARD_CHART_SumofDailyEndpointUsagebyPlatformACC_CELLULAR: 'Utilisation quotidienne, données (ACC)',
	DASHBOARD_CHART_SumofMonthlyEndpointUsagebyPlatformDCP_CELLULAR: 'Utilisation mensuelle, données (plateforme de connexion d’appareils)',
	DASHBOARD_CHART_CountofMonthlyEndpointSMSbyPlatformDCP_CELLULARDescription: 'Montre le nbre de SMS envoyés par mois à l’échelle de la plateforme de connexion d’appareils',
	DASHBOARD_CHART_SumofDailyEndpointUsagebyPlatformDCP_CELLULARDescription: 'Montre l’utilisation quotidienne totale de données pendant le mois à l’échelle de la plateforme de connexion d’appareils',
	DASHBOARD_CHART_SumofDailyEndpointUsagebyPlatformACC_CELLULARDescription: 'Montre l’utilisation quotidienne totale de données pendant le mois à l’échelle de la plateforme ACC',
	DASHBOARD_CHART_SumofMonthlyEndpointUsagebyPlatformDCP_CELLULARDescription: 'Montre l’utilisation mensuelle totale de données à l’échelle de la plateforme de connexion d’appareils',
	DASHBOARD_CHART_CountofMonthlyEndpointSMSbyPlatformCCIP_CELLULAR: 'SMS par mois (CCIP)',
	DASHBOARD_CHART_CountofMonthlyEndpointSMSbyPlatformCCIP_CELLULARDescription: 'Montre le nbre de SMS envoyés par mois à l’échelle de la plateforme CCIP',
	DASHBOARD_CHART_CountofDailyEndpointSMSbyPlatformCCIP_CELLULAR: 'SMS par jour (CCIP)',
	DASHBOARD_CHART_CountofDailyEndpointSMSbyPlatformCCIP_CELLULARDescription: 'Montre le nbre de SMS envoyés quotidiennement à l’échelle de la plateforme CCIP',
	DASHBOARD_CHART_SumofDailyEndpointUsagebyPlatformCCIP_CELLULAR: 'Utilisation quotidienne totale du point terminal (CCIP)',
	DASHBOARD_CHART_SumofDailyEndpointUsagebyPlatformCCIP_CELLULARDescription: 'Montre l’utilisation quotidienne totale de données pendant le mois à l’échelle de la plateforme CCIP',
	DASHBOARD_CHART_SumofMonthlyEndpointUsagebyPlatformCCIP_CELLULAR: 'Utilisation mensuelle, données (CCIP)',
	DASHBOARD_CHART_SumofMonthlyEndpointUsagebyPlatformCCIP_CELLULARDescription: 'Montre l’utilisation mensuelle totale de données à l’échelle de la plateforme CCIP',
	DASHBOARD_CHART_countByCountry_CELLULAR: 'Points terminaux par pays',
	DASHBOARD_CHART_countByCountry_CELLULARDescription: 'Montre le nombre de points terminaux parmi plusieurs pays.',
	DASHBOARD_CHART_countByRoamingCountry_CELLULAR: 'Points terminaux par pays d’itinérance (cellulaire)',
	DASHBOARD_CHART_countByRoamingCountry_CELLULARDescription: 'Montre le nombre de points terminaux par pays d’itinérance.',
	DASHBOARD_CHART_countByRoamingCarrier_CELLULAR: 'Points terminaux par opérateur d’itinérance (cellulaire)',
	DASHBOARD_CHART_countByRoamingCarrier_CELLULARDescription: 'Montre le nbre points terminaux (cellulaire), par opérateur d’itinérance.',
	DASHBOARD_CHART_countByRoamingCarrier_SATELLITE: 'Points terminaux par opérateur d’itinérance (satellite)',
	DASHBOARD_CHART_countByRoamingCarrier_SATELLITEDescription: 'Montre le nbre points terminaux (satellite), par opérateur d’itinérance.',

	SMS_HISTORY: 'Historique SMS',
	MAXIMUM_NUMBER_OF_CHARACTERS: 'Nombre maximum numéro de caractères : ',
	SEND_SMS_MESSAGE: 'Envoyer SMS',
	SMS_NOTIFICATION_TITLE: 'Notification SMS',
	SMS_NOTIFICATION_MESSAGE: 'Erreur de réponse',
	SMS_NOTIFICATION_MESSAGE_SUCCESS: 'Envoi réussi du SMS',

	REVIEW: 'Examiner',
	PLATFORM_DETAILS: 'Détails plateforme',

	UPDATING: 'Mise à jour',
	AUTOMATION_RULE_SUCCESSFULLY_DELETED: 'Suppression réussie de la règle d’automatisation',
	AUTOMATION_DELETE_ERROR: 'La suppression de la règle d’automatisation a échoué. Veuillez réessayer.',
	FETCH_DATA_ERROR: 'Impossible d’obtenir les données',
	CHANGED_BY: 'Changé par',
	EMAIL_SUB: 'Envoyer e-mail',
	CTD_USAGE: 'Utilisation données du cycle à ce jour',
	PAST24H_DATA_USAGE_EXCEEDED: 'Utilisation des données dépassée dans les dernières 24 h',
	SMS_USAGE: 'Utilisation SMS du cycle à ce jour',
	EXCEEDLIMIT: 'Dépasse',
	APPROACHLIMIT: 'Approche',
	trigger: 'Déclencheur',
	TWENTYFOURHOURS: 'Dernières 24 heures',
	CTD: 'Cycle jusqu’à ce jour',
	ADD_RULE_SUCCESS: 'Règle créée',
	ADD_RULE_ERROR: 'La création de la règle d’automatisation a échoué. Veuillez réessayer.',
	EDIT_RULE_SUCCESS: 'Règle modifiée',
	EDIT_RULE_ERROR: 'La modification de la règle d’automatisation a échoué. Veuillez réessayer.',
	ACC_AUTOMATION: 'Automatisation ACC',
	ACC_ACCOUNT_ID: 'ID compte ACC',
	CREATE_NEW_AUTOMATION_RULE: 'Créer nouvelle règle d’automatisation',
	DCP_AUTOMATION: 'Automatisation plateforme de connexion d’appareils',
	DCP_ACCOUNT_ID: 'ID compte plateforme de connexion d’appareils',
	ACC_ACCOUNT: 'Compte ACC',
	RULE: 'Règle',
	ACC_IF: 'Si',
	TRESHOLD: 'Seuil',
	THEN: 'Alors',
	ACC_RULE_NAME: 'Nom de la règle',
	SAVE_AND_ACTIVATE: 'Enregistrer et activer',
	DCP_ACCOUNT: 'Compte plateforme de connexion d’appareils',
	SELECT_BY: 'Sélectionner par',
	DCP_BYTES_SENT: 'octets envoyés',
	SMSES_SENT: 'SMS envoyés',
	SMSES: 'SMS',
	DASHBOARD_FEEDBACK: 'Tableau de bord',
	MUST_BE_BETWEEN: 'Doit se situer entre ',
	COMPANIES_STEPS: 'Étapes sociétés',
	USER_NAME: 'NOM D’UTILISATEUR',
	ID_DASHBOARD_CHARTS_countofmonthlyendpointsmsbyplatformdcp: 'SMS par mois',
	ID_DASHBOARD_CHARTS_countofdailyendpointsmsbyplatformdcp: 'SMS par jour',
	ID_DASHBOARD_CHARTS_sumofdailyendpointusagebyplatformdcp: 'Utilisation quotidienne totale du point terminal',
	ID_DASHBOARD_CHARTS_sumofdailyendpointusagebyplatformacc: 'Utilisation quotidienne, données',
	ID_DASHBOARD_CHARTS_sumofmonthlyendpointusagebyplatformdcp: 'Utilisation mensuelle, données',
	DASHBOARD_CHART_DeviceType_SATELLITE: 'Type d’appareil (satellite)',
	DASHBOARD_CHART_DeviceType_CELLULAR: 'Type d’appareil (cellulaire)',
	DASHBOARD_CHART_DeviceType_SATELLITEDescription: 'Montre le nombre total d’appareils parmi les différents types',
	DASHBOARD_CHART_DeviceType_CELLULARDescription: 'Montre le nombre total d’appareils parmi les différents types',
	AUTOMATION_ALERTS: 'Alertes d’automatisation',
	STATE: 'État',
	COMPANY_EMAIL: 'E-mail société',
	CUSTOM_FIELD_LABELS: 'Étiquettes champs personnalisés',
	MY_COMPANY: 'Ma société',
	AUTOMATION_ACCOUNT: 'Compte',
	UPDATE_CUSTOM_FIELD_LABELS_SUCCESS: 'Paramètres d’étiquettes personnalisées enregistrés',
	UPDATE_CUSTOM_FIELD_LABELS_ERROR: 'Impossible d’enregistrer paramètres d’étiquettes personnalisées',
	WRITE_SMS_MESSAGE_HERE: 'Écrire message ici…',
	RE_CREDENTIALING_ALLOWED: 'Réaffectation non autorisée.',
	DELETE_AUTOMATION_RULE_QUESTION: 'Supprimer règle d’automatisation ?',
	DELETE_AUTOMATION_RULE_MESSAGE: 'Voulez-vous vraiment supprimer la règle d’automatisation ',
	PLATFORM_SUCCESSFULLY_DELETED: 'Suppression réussie de la plateforme',
	PLATFORM_DELETE_ERROR: 'La suppression de la règle d’automatisation a échoué. Veuillez réessayer.',
	CELLULAR: 'Cellulaire',
	SATELLITE: 'Satellite',
	SAVE_CUSTOM_LABELS: 'Enregistrer étiquettes personnalisées',

	// ccip
	ENDPOINT_PROVISIONING_INFORMATION: 'Info provisionnement du point terminal',
	CCIP_3G: '3G',
	VOICE_ENABLED: 'Voix activée',
	DATA_ENABLED: 'Données activées',
	SMS_ENABLED: 'SMS activés',
	VOICE_ROAMING_ENABLED: 'Itinérance voix activée',
	DATA_ROAMING_ENABLED: 'Itinérance données activée',
	CCIP_LTE: 'LTE',
	LTE_ENABLED: 'LTE activée',
	ROAMING_ENABLED: 'Itinérance activée',
	MOBILE_NETWORK_ACCESS: 'Accès réseau mobile',
	VOICE_ONLY: 'Voix seulement',
	REGISTERED_ON_NETWORK: 'Enregistré sur le réseau',
	REGISTERED_ON_3G_NETWORK: 'Enregistré sur le réseau 3G',
	THIS_EMAIL_ADDRESS_IS_ALREADY_IN_USE: 'Cette adresse e-mail est déjà utilisée',
	THIS_EMAIL_ADDRESS_DOES_NOT_EXIST: 'Cette adresse e-mail n’existe pas',
	USER_SUCCESSFULLY_DELETED: 'Suppression réussie de l’utilisateur',
	USER_DELETE_ERROR: 'La suppression de l’utilisateur a échoué. Veuillez réessayer.',
	CATEGORIES: 'Catégories',
	TRIGGERS: 'Déclencheurs',
	FETCH_DATA_LOADING_MESSAGE: 'Veuillez patienter pendant que nous chargeons les données',
	M2M_ACCOUNT_ID_PLATFORM: 'ID compte machine-machine',
	M2MACCOUNTS: 'Compte machine-machine',

	FROM_VALUE: 'DE LA VALEUR',
	TO_VALUE: 'À LA VALEUR',

	SEARCH_REPORTS: 'Rechercher rapports',
	SEARCH_INVOICES: 'Rechercher factures',

	CUSTOM_DATA_FIELDS: 'Champs de données personnalisés',
	ENDPOINT_ACTIONS: 'Actions point terminal pour',
	RE_CREDENTIAL_NOT_ALLOWED: 'Réaffectation non autorisée.',
	EDIT_ENDPOINT: 'Actions point terminal',
	CURRENT_SIM_STATE_FOR_ENDPOINT_ID: 'État actuel SIM pour ID point terminal',

	NETWORK_ENTITLEMENT_NAME: 'Nom droit d’utiliser le réseau',
	BLOCK_PREMIUM_NUMBERS: 'Bloquer numéros surfacturés',
	BLOCK_INTERNATIONAL_MO: 'Bloquer SMS de départ international sauf pays d’accueil',
	HLR_TEMLATE: 'Modèle détails HLRHSS',
	HOTLINE_NUMBER: 'Numéro d’urgence',
	FACETIME: 'FACETIME',
	VOLTE: 'Volte',
	GDSP_SESSION_ACTIVE: 'Actif',
	GDSP_SESSION_BYTES_IN: 'Octets en entrée',
	GDSP_SESSION_BYTES_OUT: 'Octets en sortie',
	GDSP_SESSION_LAST_IP_ADDRESS: 'Dernière adresse IP',
	GDSP_SESSION_LAST_STARTED_TIMESTAMP: 'Dernier démarrage horodatage',
	GDSP_SESSION_LAST_UPDATED_TIMESTAMP: 'Dernière mise à jour horodatage',
	GDSP_SESSION_LAST_TERMINATED_TIMESTAMP: 'Dernière résiliation horodatage',
	GDSP_SESSION_LAST_DATA_TRANSMITED_TIMESTAMP: 'Horodatage dernières données transmises',
	GDSP_SESSION_PREVIOUS_NORMAL_TIMESTAMP: 'Horodatage précédent normal',
	GDSP_SESSION_LAST_SMS_MO_TIMESTAMP: 'Horodatage dernier SMS de départ',
	GDSP_SESSION_LAST_SMS_MT_TIMESTAMP: 'Horodatage dernier SMS d’arrivée',
	GDSP_SESSION_LAST_SMS_MT_STATUS: 'État dernier SMS d’arrivée',
	GDSP_SESSION_LAST_SMS_MO_STATUS: 'État dernier SMS de départ',
	GDSP_SESSION_LAST_WAKEUP_REQUEST_TIMESTAMP: 'Horodatage dernière demande réveil',
	GDSP_SESSION_LAST_WAKEUP_REQUEST_TYPE: 'Type dernière demande réveil',
	GDSP_SESSION_LAST_WAKEUP_STATUS: 'État dernier réveil',
	GDSP_SESSION_LAST_WAKEUP_STATUS_TIMESTAMP: 'Horodatage état dernier réveil',
	GDSP_SESSION_LAST_NOTIFICATION_FAILURE_TIMESTAMP: 'Horodatage échec dernière notification',
	GDSP_SESSION_LAST_NOTIFICATION_FAILURE_REASON: 'Raison échec dernière notification',

	CUSTOM_FIELD_1: 'Champ personnalisé 1',
	CUSTOM_FIELD_2: 'Champ personnalisé 2',
	CUSTOM_FIELD_3: 'Champ personnalisé 3',
	CUSTOM_FIELD_4: 'Champ personnalisé 4',
	CUSTOM_FIELD_5: 'Champ personnalisé 5',
	CUSTOM_FIELD_6: 'Champ personnalisé 6',
	CUSTOM_FIELD_7: 'Champ personnalisé 7',
	CUSTOM_FIELD_8: 'Champ personnalisé 8',
	CUSTOM_FIELD_9: 'Champ personnalisé 9',
	CUSTOM_FIELD_10: 'Champ personnalisé 10',

	/* ----- GDSP ------- */
	REGISTRATION_STATUS: 'État enregistrement',
	NETWORK_CONNECTIONS: 'Connexions réseau',
	PROVISIONED_DATE_AND_TIME: 'Date et heure provisionnement',

	// GDSP SIM STATE STATUS
	M2M_PLATFORM_STATUS_A: 'En service actif',
	M2M_PLATFORM_STATUS_B: 'Nouveau inactif',
	M2M_PLATFORM_STATUS_C: 'Veille actif',
	M2M_PLATFORM_STATUS_D: 'Arrêté inactif',
	M2M_PLATFORM_STATUS_I: 'Migré à partir d’un autre environnement GDSP',
	M2M_PLATFORM_STATUS_N: 'Non provisionné',
	M2M_PLATFORM_STATUS_O: 'Migré vers autre environnement GDSP',
	M2M_PLATFORM_STATUS_R: 'Prêt actif',
	M2M_PLATFORM_STATUS_S: 'Suspendu actif',
	M2M_PLATFORM_STATUS_T: 'Test actif',
	M2M_PLATFORM_STATUS_U: 'En attente actif',
	M2M_PLATFORM_STATUS_V: 'Transformé inactif',
	M2M_PLATFORM_STATUS_W: 'Suspendu réglementaire',
	M2M_PLATFORM_STATUS_Z: 'Résilié',
	M2M_PLATFORM_STATUS_NOT_AVAILABLE: 'Non disponible.',

	// GDSP Session tab Status Codes
	// lastWakeupStatus
	LASTWAKEUPSTATUS_IR: 'Appel réveil non valide',
	LASTWAKEUPSTATUS_FI: 'Échec de l’initiation réveil',
	LASTWAKEUPSTATUS_SI: 'Initiation réveil réussie',
	LASTWAKEUPSTATUS_F: 'Échec de l’exécution réveil',
	LASTWAKEUPSTATUS_S: 'Exécution réveil réussie',
	TOOLTIP_LASTWAKEUPSTATUS_IR: 'Mauvaise requête API',
	TOOLTIP_LASTWAKEUPSTATUS_FI: 'Échec du centre de gestion de SMS',
	TOOLTIP_LASTWAKEUPSTATUS_SI: 'Envoi réussi au centre de gestion de SMS',
	TOOLTIP_LASTWAKEUPSTATUS_F: 'Le centre de gestion de SMS a émis une notification d’échec d’exécution',
	TOOLTIP_LASTWAKEUPSTATUS_S: 'Le centre de gestion de SMS a émis une notification d’exécution réussie',

	// lastSmsMtStatus
	LASTSMSMTSTATUS_IR: 'Appel réveil non valide',
	LASTSMSMTSTATUS_FI: 'Échec de l’initiation réveil',
	LASTSMSMTSTATUS_SI: 'Initiation réveil réussie',
	LASTSMSMTSTATUS_F: 'Échec de l’exécution réveil',
	LASTSMSMTSTATUS_S: 'Exécution réveil réussie',
	TOOLTIP_LASTSMSMTSTATUS_IR: 'Mauvaise requête API',
	TOOLTIP_LASTSMSMTSTATUS_FI: 'Échec du centre de gestion de SMS',
	TOOLTIP_LASTSMSMTSTATUS_SI: 'Envoi réussi au centre de gestion de SMS',
	TOOLTIP_LASTSMSMTSTATUS_F: 'Le centre de gestion de SMS a émis une notification d’échec d’exécution',
	TOOLTIP_LASTSMSMTSTATUS_S: 'Le centre de gestion de SMS a émis une notification d’exécution réussie',

	/* ----- GDSP END ------- */

	/* API ERROR CODES  */

	CODE_400203: 'Un paramètre requis est manquant',
	CODE_400204: 'Un paramètre n’a pas la longueur correcte',
	CODE_401205: 'Jeton non valide ou expiré',
	CODE_400207: 'Un paramètre n’a pas la valeur valide des options valeur possibles',
	CODE_400208: 'Un paramètre n’a pas le format correct',
	CODE_400209: 'Reproduire paramètre',
	CODE_400299: 'Mauvaise requête',
	CODE_400301: 'Erreurs des systèmes en aval',
	CODE_401301: 'Échec des authentifiants des systèmes en aval',
	CODE_404301: 'ID appareil introuvable dans système en aval',
	CODE_404302: 'ID compte non valide ou introuvable dans système en aval',
	CODE_502100: 'Changement non autorisé, appareil déjà programmé',
	CODE_400100: 'Requête non valide. Action par lots non permise pour appareils satellite',
	CODE_404201: 'Fichier inexistant',
	CODE_401202: 'Nom d’utilisateur ou mot de passe invalide',
	CODE_400206: 'Format de nom de fichier invalide',
	CODE_502200: 'Erreur de fichier séquentiel',
	CODE_502202: 'Erreur base de données lors de l’extraction',
	CODE_502203: 'Erreur base de données lors des changements',
	CODE_502204: 'Erreur lors de la création du client',
	CODE_502301: 'Erreur lors du traitement API de type PUSH',
	CODE_404303: 'ID appareil introuvable dans notification API de type PUSH',
	CODE_502501: 'Erreur lors du traitement des rapports SFTP',
	CODE_undefined: ' ',
	CODE_INTEGRATION_TIMEOUT: 'Plateforme sous-jacente injoignable. Veuillez réessayer ultérieurement.',
	CODE_VTM_SVC_40003: 'The entered value was not recognized',
	CODE_VTM_SVC_40004: 'Unable to create the support ticket',
	CODE_VTM_SVC_40005: 'Unable to create the support ticket',
	CODE_VTM_SVC_40409: 'Ticket does not exist: It may have been removed due to AT&T data retention policy',
	CODE_VTM_SVC_40411: 'Ticket does not exist or it is closed by an agent: It may have been removed due to AT&T data retention policy',
	CODE_VTM_SVC_40027: 'Cannot update canceled or closed support ticket: This ticket is closed or canceled and cannot be updated.',
	CODE_VTM_SVC_40035: 'Unable to add engagement: Engagement ticket already exist',
	CODE_VTM_SVC_50001: 'Something went wrong: Try again. If the problem persists, please report your issue by calling AT&T',
	CODE_VTM_SVC_40038: 'Unable to update the support ticket: Circuit details are the same',
	CODE_VTM_SVC_40039: 'Cannot create the support ticket: Asset info is missing in the system',
	CODE_VTM_SVC_40040: 'Cannot add a PVC value to support ticket: pvcID details already exist in the system',
	CODE_VTM_SVC_40041: 'Unable to update support ticket: clkNumber does not exist',
	CODE_VTM_SVC_40042: 'Unable to update support ticket: pvcID does not exist',
	CODE_VTM_SVC_40043: 'Unable to retrieve ticket history: Please try again later',
	CODE_VTM_SVC_40010: 'Unable to create the support ticket: Field exceeded maximum of 199 characters. Please correct the length and try again',
	CODE_VTM_SVC_40053: 'Unable to create support ticket: Case ID does not have Ticket Role defined. or is invalid support role',
	CODE_EOD_11: 'Unable to process the request: Please try again in a few minutes.',
	CODE_EOD_59: 'Cannot deactivate device without static IP: Before a device can be deactivated (put on hold), it needs to have a static IP. You can still deactivate this device by deprovisioning it (purge) from the system.',
	CODE_EOD_60: 'Cannot deactivate inactive device',
	CODE_EOD_46: 'The SIM is already in the provisioning queue',
	CODE_EOD_30: 'The SIM cannot be suspended using a past date',
	CODE_EOD_32: 'Internal system error: Contact customer support or create a trouble ticket to get help with this issue',
	CODE_EOD_41: 'Cannot change profile for suspended devices: Contact customer support or create a trouble ticket to get help with this issue',
	CODE_EOD_45: 'Invalid SIM or SIM does not belong to your account: Contact customer support or create a trouble ticket to get help with this issue',
	CODE_EOD_16: 'SIM has reached the maximum number of Rate Plan changes allowed during the Billing Cycle: Could not complete the Rate Plan change request. Please try again in the next billing cycle.',
	CODE_EOD_17: 'SIM has reached the maximum number Rate Plan changes allowed for the day: Could not complete the Rate Plan change request. Please try again tomorrow.',
	CODE_EOD_25: 'The device is currently in a suspended state and so cannot resume before the suspended date',
	CODE_EOD_27: 'The SIM cannot be suspended using a past date',
	CODE_EOD_3: 'Device is already inactive',
	CODE_EOD_401: 'Unable to authorize user performing the batch action with device platform.',

	/* API ERROR CODES  */

	SERVICE_STATUS: 'État 360L',
	SERVICESTATUS: 'État 360L',
	OEM: 'Sous-comptes',
	ROAMING_COUNTRY: 'Pays itinérance',
	ROAMING_CARRIER: 'Opérateur itinérance',
	ROAMINGCOUNTRY: 'Pays itinérance',
	ROAMINGCARRIER: 'Opérateur itinérance',
	OEM_TOOLTIP: 'Comptes 360L liés',
	SIM_STATE: 'État SIM',

	NO_DATA_ENDPOINT_HISTORY: 'Aucun historique de point terminal',
	PRINT_PDF: 'Imprimer PDF',
	USER_GUIDE: 'Guide de l’utilisateur',
	USER_GUIDE_MENU: 'Menu Guide de l’utilisateur',
	FAQ_MENU: 'Menu FAQ',
	TABLE_OF_CONTENTS: 'Table des matières',
	RATE_PLAN_TYPE: 'Type de forfait',
	SUBSCRIPTION_PRICE: 'Prix abonnement',
	ACCOUNT_CHARGE: 'Frais de compte',
	PAYMENT_TYPE: 'Type paiement',
	TERM: 'Période',
	USAGE_LIMIT: 'Limite utilisation',
	TIERING_FLAG: 'Indicateur de niveau',

	RATE_PLAN_CHANGE: 'Changement de forfait',
	RATE_PLAN_CHANGED_TO: 'Vous avez choisi de changer le forfait à',
	SCHEDULE_RATE_PLAN_CHANGE: 'Programmer changement de forfait',
	RATE_PLAN_CHANGE_IS_SUCCESSFULLY_SCHEDULED_ERROR: 'Échec de la programmation du changement de forfait !',
	RATE_PLAN_CHANGE_IS_SUCCESSFULLY_SCHEDULED_FOR: 'Programmation réussie du changement de forfait pour',
	RATE_PLAN_IS_CHANGED_TO: 'État forfait changé à ',
	FAILED_TO_PROVISION_AFTER_RATE_PLAN_CHANGE: ' échec provisionnement après le changement de forfait',
	SEND_EMAIL_TO_CONSUMER: 'Envoyer e-mail au client',
	PUSH_API: 'API de type push',
	INITIATE_OTA_SWAP: 'Lancer changement OTA',
	UPDATE_PCRF: 'Mettre à jour PCRF',
	CHANGE_DEVICES_NETWORK_ENTITLEMENT: 'Changer le droit d’utiliser le réseau de l’appareil',
	CHANGE_DEVICES_SIM_STATE: 'Changer état SIM de l’appareil',
	CHANGE_DEVICES_RATE_PLAN: 'Changer forfait de l’appareil',
	NUMBER_OF_DAYS: 'Nombre de jours',
	NUMBER_OF_HOURS: 'Nombre d’heures (max. 24 h)',
	AT: 'À',
	FILTER: 'FILTRE',
	FOLLOW_UP_WITH: 'Suivi avec',
	CUSTOM: 'Personnalisé',
	ELIGIBLE_RATE_PLAN: 'Forfait admissible',
	CHOOSE_RATE_PLAN: 'Choisir forfait',
	CUSTOMER_ID: 'ID client',
	INVALID_URL_FORMAT: 'Format URL non valide',
	INVALID_PORT_NUMBER: 'Numéro PORT non valide',
	INVALID_INPUT: 'Entrée non valide',
	RATE_PLAN_CHANGE_SUPPORT: 'Erreur de forfait, veuillez contacter l’assistance à la clientèle.',
	NETWORK_ENTITLEMENT_PLACEHOLDER: 'XYX GPRS/LTE/SMS/IMS RES 165',
	M2M_SUB_ACCOUNT_ID: 'ID sous-compte machine-machine',

	/* SIM ORDERING */
	SIM_ORDERING: 'Commande de SIM',
	CREATE_SIM_ORDER: 'Créer commande de SIM',
	NEW_SIM_ORDER: 'Nouvelle commande',
	EXISTING_SIM_ORDER: 'ID commande : {id}',
	M2M_ACCOUNT: 'Compte machine-machine',
	M2M_ACCOUNT_PLACEHOLDER: 'Sélectionner compte machine-machine',
	ORDER_ID: 'ID commande',
	ORDER_NUMBER_ID: 'ID numéro commande',
	ORDER_CONFIRMATION_NUMBER: 'Numéro confirmation commande',
	ORDER_DATE: 'Date commande',
	PURCHASE_ORDER_NUMBER: 'N° BC',
	SKU: 'UGS',
	SKU_PLACEHOLDER: 'Sélectionner UGS',
	UNIT_PRICE: 'Prix unitaire',
	QUANTITY: 'Quantité',
	QUANTITY_LIMIT_TEMPORARILY_REACHED: 'Quantité limite provisoirement atteinte',
	MIN_MAX_QUANTITY: 'Min. {min} / Max. {available} {max}',
	INCREMENT_QUANTITY: 'Incrément {increment}',
	ORDER_STATUS: 'État commande',
	ORDER_STATUS_PENDING: 'En attente',
	ORDER_STATUS_RECEIVED: 'Reçu',
	ORDER_STATUS_SHIPPED: 'Expédié',
	ORDER_STATUS_COMPLETED: 'Exécuté',
	ORDER_STATUS_SUCCESSFUL: 'Réussi',
	ORDER_STATUS_FAILED: 'Échec',
	CONTACT_NAME: 'Nom du contact',
	TELEPHONE_NUMBER: 'Numéro de téléphone',
	TARGET_IMPLEMENTATION_DATE: 'Date mise en œuvre cible',
	EXPEDITED: 'Accéléré',
	SHIPPING_ADDRESS: 'Adresse d’expédition',
	ADDRESS_LINE_1: 'Ligne d’adresse 1',
	ADDRESS_LINE_2: 'Ligne d’adresse 2',
	SHIPPING_NOTES: 'Notes sur l’expédition',
	SHIPPING_NUMBER: 'Numéro de l’expédition',
	EXPEDITED_SHIPPING: 'Expédition accélérée',
	TOTAL_PRICE: 'Prix total',
	SUBMIT_ORDER_REQUEST: 'Envoyer requête commande',
	SUBMIT_ORDER_REQUEST_CONFIRMATION: 'Voulez-vous vraiment envoyer cette commande ?',
	EDIT_ORDER_REQUEST: 'Modifier requête commande',
	EDIT_ORDER_REQUEST_CONFIRMATION: 'Voulez-vous vraiment enregistrer les changements ?',
	SAVE_UPDATES: 'Enregistrer mises à jour',
	ADD_SIM_ORDER_SUCCESS: 'Commande de SIM créée',
	ADD_SIM_ORDER_ERROR: 'La création de la commande de SIM a échoué, veuillez réessayer.',
	EDIT_SIM_ORDER_SUCCESS: 'Commande de SIM modifiée',
	EDIT_SIM_ORDER_ERROR: 'La modification de la commande de SIM a échoué, veuillez réessayer.',
	PO_TOOLTIP: 'Le n° BC sera généré après l’envoi de la commande de SIM',

	FILTERS: 'Filtres',
	FILTER_CATEGORY_STATUS: 'État',
	FILTER_CATEGORY_PLATFORM: 'Plateforme machine-machine',
	FILTER_CATEGORY_SKU: 'UGS',
	FILTER_CATEGORY_EXPEDITED: 'Accéléré',
	FILTER_VALUE_PENDING: 'En attente',
	FILTER_VALUE_RECEIVED: 'Reçu',
	FILTER_VALUE_SHIPPED: 'Expédié',
	FILTER_VALUE_COMPLETED: 'Exécuté',
	FILTER_VALUE_YES: 'Oui',
	FILTER_VALUE_NO: 'Non',

	SIM_ORDERING_TITLE: 'Commande de SIM',
	SIM_ORDERING_SUBTITLE: 'Veuillez sélectionner les SIM disponibles pour la commande ci-dessous.',
	SIM_ORDERING_CONTRACT_RESTRAINTS: 'Restrictions du contrat',
	SIM_ORDERING_CONTRACT_RESTRAINTS_SUBTITLE: 'Veuillez définir les restrictions sur le processus de commande de SIM.',
	SIM_ORDERING_MIN_QUANTITY_ALLOWED: 'Quantité min. autorisée',
	SIM_ORDERING_MIN_QUANTITY_ALLOWED_PLACEOLDER: 'Quantité min.',
	SIM_ORDERING_MAX_QUANTITY_ALLOWED: 'Quantité max. autorisée',
	SIM_ORDERING_PER_PERIOD: 'Par période',
	SIM_ORDERING_PER_PERIOD_PLACEHOLDER: 'Sélectionner une période',
	SIM_ORDERING_MAX_QUANTITY_ALLOWED_PLACEOLDER: 'Quantité max.',
	SIM_ORDERING_SIM_SKU: 'UGS SIM',
	SIM_ORDERING_SIM_SKU_PLACEHOLDER: 'Saisir UGS SIM ici',
	SIM_ORDERING_UNIT_PRICE: 'Prix unitaire',
	SIM_ORDERING_UNIT_PRICE_PLACEHOLDER: 'Prix unitaire',
	SIM_ORDERING_ALLOWED_TO_ORDER: 'Autorisé à commander',
	SIM_ORDERING_SKU_DESCRIPTION: 'Description',
	SIM_ORDERING_SKU_DESCRIPTION_PLACEHOLDER: 'Saisir description UGS',
	SIM_ORDERING_SKU_ADD_SKU: '+ Ajouter une autre UGS SIM',
	SIM_ORDERING_VALIDATOR_MAX_QUANTITY: 'quantité max.',
	SIM_ORDERING_VALIDATOR_MIN_QUANTITY: 'quantité min.',
	ADD_RESTRAINTS_SUCCESS: 'Restrictions du contrat ajoutées',
	ADD_RESTRAINTS_ERROR: 'L’ajout des restrictions du contrat a échoué',
	UPDATE_RESTRAINTS_SUCCESS: 'Restrictions du contrat mises à jour',
	UPDATE_RESTRAINTS_ERROR: 'La mise à jour des restrictions du contrat a échoué',
	ADD_SIM_ORDERING_SKUS_SUCCESS: 'UGS commandes SIM mises à jour',
	ADD_SIM_ORDERING_SKUS_ERROR: 'L’ajout des UGS commandes SIM a échoué',

	COMPANY_REPORTS: 'Rapports',
	COMPANY_REPORTS_SETTINGS_SUCCESSFULLY_UPDATED: 'Paramètres des rapports de la société mis à jour',
	ERROR_WHILE_UPDATING_COMPANY_REPORTS: 'Erreur lors de la mise à jour des rapports de la société',
	ERROR_WHILE_FETCHING_COMPANY_REPORTS: 'Erreur lors de la récupération de la configuration des rapports',
	COMPANY_REPORTS_SUBTITLE: 'Sélectionnez ci-dessous les rapports que vous souhaitez recevoir.',
	DAILY_REPORTS: 'RAPPORTS QUOTIDIENS',
	MONTHLY_REPORTS: 'RAPPORTS MENSUELS',
	PREFERENCES_MENU: 'Menu Préférences',
	PREFERENCES: 'Préférences',
	REFERENCE_REPORTS: 'Rapports de référence',
	M2M_DATE_ADDED: 'Date ajout machine-machine',
	M2M_INITIAL_ACTIVATION_DATE: 'Date activation machine-machine',
	NOTES: 'Notes',
	SAVE_NOTE: 'Enregistrer note',
	NOTE_TEXTAREA_PLACEHOLDER: 'Saisissez votre note ici (2 000 caractères max.)',
	ADD_NOTE_SUCCESS: 'Note enregistrée',
	ADD_NOTE_ERROR: 'L’enregistrement de la note a échoué. Veuillez réessayer.',
	DELETE_NOTE_SUCCESS: 'Note supprimée',
	DELETE_NOTE_ERROR: 'La suppression de la note a échoué. Veuillez réessayer.',
	DELETE_NOTE: 'Supprimer note',
	TICKETING_STATUS: 'État établissement de billets',
	UPDATE_NOTE_STATUS: 'Mettre à jour note',
	LAST_UPDATE: 'Dernière mise à jour',
	SUBMISSION_LOCATION: 'Endroit de l’envoi',
	RULE_STATE: 'État de la règle',
	RULE_STATE_ACTIVE: 'Actif',
	YES: 'Oui',
	NO: 'Non',
	// ANALYTICS
	CHART_CATEGORIES: 'Catégories de graphiques',
	GROUP_DATA_BY: 'Regrouper données par',
	CHART_OPTIONS: 'Options graphique',
	CREATE_NEW_CHART: 'Créer nouveau graphique',
	EDIT_CHART: 'Modifier graphique',
	DISCLAIMER: 'Déni de responsabilité : ',
	DATE_RANGE: 'PLAGE DATES',
	SHOW: 'MONTRER',
	CYCLE: 'CYCLE',
	INCREMENT_BY: 'INCRÉMENTER PAR',
	SELECT_CUSTOM_DATE_RANGE: 'Sélectionner plage de dates personnalisée',

	// Facturation
	INVOICE: 'Facture',
	INVOICE_DATE: 'Date facture',
	CUSTOMER_NAME: 'Nom du client',
	DOCUMENT_ID: 'ID document',
	COUNTRY_NAME: 'Nom du pays',
	TABLE_COLUMNS_INVOICES_CHANGE_SUCCESS_TITLE: 'Changement des paramètres de la table des factures réussi !',
	TABLE_COLUMNS_INVOICES_CHANGE_SUCCESS_MESSAGE: 'Mise à jour des paramètres de la table des factures réussie !',
	TABLE_COLUMNS_INVOICES_CHANGE_ERROR_TITLE: 'Erreur dans changement des colonnes de la table des factures !',
	TABLE_COLUMNS_INVOICES_CHANGE_ERROR_MESSAGE: 'Impossible de mettre à jour les colonnes de la table des factures. Veuillez réessayer.',
	INVOICE_DETAILS: 'Détails facture',

	// TICKETING
	TICKET_CREATED_DATE: 'Date création du billet',
	UPDATED_NOTE_STATUS_DATE: 'Date état note mise à jour',
	LAST_UPDATED_DATE: 'Date dernière mise à jour',
	UPDATE_NOTE: 'Mettre à jour note',
	TABLE_TICKETS_COLUMNS_CHANGE_SUCCESS_TITLE: 'Changement des paramètres de la table des billets réussi !',
	TABLE_TICKETS_COLUMNS_CHANGE_SUCCESS_MESSAGE: 'Mise à jour des paramètres de la table des billets réussie !',
	TABLE_TICKETS_COLUMNS_CHANGE_ERROR_TITLE: 'Erreur dans changement des colonnes de la table des billets !',
	TABLE_TICKETS_COLUMNS_CHANGE_ERROR_MESSAGE: 'Impossible de mettre à jour les colonnes de la table des billets. Veuillez réessayer.',

	TODAY: 'Aujourd’hui',
	PREVIOUS_DAY: 'Jour précédent',
	LAST_10_DAYS: 'Derniers 10 jours',
	LAST_30_DAYS: 'Derniers 30 jours',
	CUSTOM_RANGE: 'Personnaliser plage',
	CURRENT_CYCLE_TO_DATE: 'Cycle actuel jusqu’à ce jour',
	HISTORICAL: 'Historique',
	WEEK: 'Semaine',
	SUM_TOTALS: 'Ajouter les totaux',
	AVERAGE_TOTALS: 'Faire la moyenne des totaux',
	LINE_GRAPH: 'Graphique linéaire',
	BAR_GRAPH: 'Graphique à barres',
	WORLD_MAP_GRAPH: 'Graphique de carte du monde',
	TOTAL_DATA_USAGE: 'Utilisation totale, données',
	ENDPOINT_COUNT_TOTALS: 'Totaux des point terminaux',
	NO_USAGE_SIMS: 'Aucune utilisation SIM',
	TOTAL: 'Total',
	AVERAGE_USAGE: 'Moyenne',
	DIRECTION_INDEX: 'Indice de direction',
	PIE_CHART: 'Graphique en secteurs',
	ERROR_WHILE_ADDING_TICKET: 'Erreur lors de l’ajout du billet',

	SELECT_M2M_PLATFORM: 'Sélectionnez votre plateforme machine-machine',
	SELECT_M2M_PLATFORM_ERROR: 'Veuillez d’abord sélectionner la plateforme machine-machine pour obtenir les données',
	SELECT_YOUR_ACCOUNT: 'Sélectionnez votre compte',
	CHOOSE_M2M_PLATFORM: 'Choisissez votre plateforme machine-machine',
	CHOOSE_YOUR_ACCOUNT: 'Choisissez votre compte',

	TOTAL_VOICE_USAGE: 'Utilisation totale, voix',
	TOTAL_SMS_USAGE: 'Utilisation totale, SMS',
	SAVE_AND_CLOSE: 'Enregistrer et fermer',
	// Éléments de sécurité
	SECURITY_TABS_1: 'Liste blanche IMEI',
	SECURITY_TABS_2: 'Liste blanche SMS',
	SECURITY_TABS_3: 'Liste blanche VOIX',
	SECURITY_TABS_4: 'Liste noire IP, URL, Port',
	SECURITY_TABS_5: 'Liste blanche plage IP',
	SECURITY_FEATURES_1: 'IMEI',
	SECURITY_FEATURES_2: 'SMS',
	SECURITY_FEATURES_3: 'VOIX',
	SECURITY_FEATURES_4: 'IP, URL, PORT',
	SECURITY_FEATURES_5: 'PLAGE IP',
	SECURITY_FEATURES_SUCCESSFULLY_ADD: 'Changement des données réussi',
	SECURITY_FEATURES_ERROR_ADD: 'Erreur lors de la mise à jour des données',
	SECURITY_FEATURES_ADD_1: 'Ajouter nouveau IMEI à liste blanche',
	SECURITY_FEATURES_ADD_2: 'Ajouter nouveau SMS à liste blanche',
	SECURITY_FEATURES_ADD_3: 'Ajouter nouvelle VOIX à liste blanche',
	SECURITY_FEATURES_ADD_4: 'Ajouter nouveaux IP, URL, PORT à liste noire',
	SECURITY_FEATURES_ADD_5: 'Ajouter nouvelle plage IP à liste blanche',
	SECURITY_FEATURES_EDIT_1: 'Modifier liste blanche IMEI',
	SECURITY_FEATURES_EDIT_2: 'Modifier liste blanche SMS',
	SECURITY_FEATURES_EDIT_3: 'Modifier liste blanche VOIX',
	SECURITY_FEATURES_EDIT_4: 'Modifier liste noire IP, URL, PORT',
	SECURITY_FEATURES_EDIT_5: 'Modifier liste blanche plage IP',
	SECURITY_FEATURES_M2M: 'Sélectionner plateforme machine-machine',
	SECURITY_FEATURES_REMOVE: 'Supprimer de la liste blanche',
	UPDATE: 'Mettre à jour',
	SECURITY_FEATURES: 'Éléments de sécurité',
	M2M_ACCOUNT_NAME: 'NOM DE COMPTE MACHINE-MACHINE',
	DATA_SUBMITTED: 'DONNÉES ENVOYÉES',
	FILTER_CATEGORY_M2MACCOUNT: 'Compte machine-machine',
	SECURITY_FEATURES_SUCCESSFULLY_EXPORT: 'Exportation réussie',
	SECURITY_FEATURES_FAIL_EXPORT: 'Échec de l’exportation',
	COUNTRYCODE: 'Pays',
	MINUTE: 'Minute',
	MINUTES: 'Minutes',
	CHART_LEFT_AXIS_MINUTE: 'min',
	CHART_LEFT_AXIS_MINUTES: 'minutes',
	CONSUMED_MINUTE: 'Minutes consommées',
	CONSUMED_MB: 'Mégaoctets consommés',
	CONSUMED_GB: 'Gigaoctets consommés',
	CONSUMED_TB: 'Téraoctets consommés',
	CONSUMED_PB: 'Pétaoctets consommés',
	CONSUMED_SMS: 'SMS consommés',
	NO_DESCRIPTION_FOR_GRAPH: 'Aucune description pour ce graphique',
	GRAPHS: 'Graphiques actuels disponibles',
	CONSUMED_COUNT: 'Nombre de points terminaux',
	DOWNLOAD_CHART_AS_PNG: 'Télécharger PNG',
	CUSTOMER_INVOICE_ID: 'ID client',
	INVOICE_ID: 'ID facture',
	CUSTOMER_INVOICE_NAME: 'Sous-compte',
	INVOICE_COUNTRY: 'Pays',
	ACCESSTECHNOLOGY: 'Technologie d’accès',
	RATINGZONE: 'Zone de tarification',
	RATEPLAN: 'Forfait',
	CHOOSE_FILTERS_ON_ANALYTICS_MODAL: 'Veuillez sélectionner une catégorie au cours de la première étape',
	NO_FILTERS_ON_ANALYTICS_MODAL: 'Aucun filtre disponible pour cette catégorie',
	BILLING_CYCLE: 'Cycle facturation',
	FETCHING_DATA: 'Veuillez patienter. Extraction des données en cours…',
	NO_DATA_RECEIVED: 'Aucune donnée reçue',
	NO_FILTERS_AVAILABLE: 'Aucun filtre disponible',
	DATE_SUBMITTED: 'Date d’envoi',
	NO_REPORTS_DEFINED: 'Aucun rapport disponible',
	APPLY: 'Appliquer',
	OPEN_CHART_SETTINGS_PAGE: 'Paramètres graphique',
	CUSTOMIZE_CHART_DATA: 'Personnaliser données de graphique',
	ESTIMATED_AVERAGE_USAGE: 'Utilisation moyenne estimée',
	DELETE_DEFINED_CHART_SETTING_QUESTION: 'Supprimer paramètre de diagramme défini ?',
	DELETE_DEFINED_CHART_SETTING_MESSAGE: 'Voulez-vous vraiment supprimer le paramètre',
	TOTAL_COUNT_BY_SEVERITY: 'Billets par gravité',
	TOTAL_COUNT_BY_STATUS: 'Billets par état',
	INVALID_IP_ADDRESS: 'Adresse IP non valide',
	INVOICENUMBER: 'Numéro de facture',
	CUSTOMERNAME: 'Nom du client',
	COUNTRYNAME: 'Nom du pays',
	CUSTOMERID: 'ID client',
	DEVICE_WAKE_UP: 'Réveil appareil',
	DEVICE_WAKE_UP_NOTIFICATION_TITLE: 'Réveil appareil',
	DEVICE_WAKE_UP_NOTIFICATION_MESSAGE_SUCCESS: 'Réveil appareil réussi',
	DEVICE_WAKE_UP_NOTIFICATION_MESSAGE_FAIL: 'Échec du réveil appareil',

	FILES_FAILED_TO_UPLOAD_MESSAGE: 'Échec du téléversement des fichiers : ',
	ADD_ANOTHER_TRESHOLD: 'Ajouter un autre seuil',
	PIE_CHART_TOTAL: 'Total',
	ADD_ATTACHMENT: 'Ajouter pièce jointe',
	TICKET_FILE_UPLOAD_ERROR: 'Une erreur s’est produite sur le système dorsal et certains de vos fichiers n’ont pas été mis à jour.',
	FILE_TO_LARGE_TO_UPLOAD: 'Fichier trop gros pour le téléversement. Taille maximum du fichier 3 Mo.',
	RETRY: 'Réessayer',
	NO_CATEGORIES: 'Aucune catégorie disponible pour ce compte',
	ZONE_NAME: 'Nom de zone',
	POOL_ID: 'ID groupe',

	// NOTES INVOICES
	ADD_NEW_NOTE: 'Ajouter note',
	EDIT_NOTE: 'Modifier note',
	ADD_NOTE_PLACEHOLDER: 'Saisissez votre note ici',
	INVOICE_NOTE_SUCCESSFULLY_UPDATED: 'Mise à jour réussie de la note sur la facture',
	INVOICE_NOTE_UPDATE_FAIL: 'Échec de mise à jour facture',
	ATTACHMENTS_ADDED: 'Pièces jointes ajoutées',
	EXTERNAL_LAST_UPDATED_BY: 'Dernière mise à jour par',
	EXTERNAL_LAST_UPDATED_DATE: 'Date/heure dernière mise à jour',
	REMOVE: 'Supprimer',
	EndpointStatus: 'État',
	NetworkOperator: 'Opérateur réseau',
	NetworkCountry: 'Pays réseau',
	NetworkType: 'Type de réseau',
	TransferStatus: 'État du transfert IMSI',
	NetworkEntitlement: 'ID droit d’utiliser le réseau',
	RecredentialTransferStatus: 'État réaffectation',
	M2MDateAdded: 'Date ajout machine-machine',
	M2MInitialActivationDate: 'Date activation machine-machine',
	LAST_UPDATED_BY: 'Dernière mise à jour par',
	LAST_UPDATED: 'Dernière mise à jour',
	INVOICE_NOTE_FILE_ERROR: 'Une erreur s’est produite sur le système dorsal et certains de vos fichiers n’ont pas été téléversés.',

	BATCH_HISTORY: 'Historique des lots',
	// DIAGNOSTIC HLRHSSDetails
	GET_HLR_HSS_DETAILS: 'Obtenir les détails HLR/HSS',
	APN_IDENTIFIER: 'Identifiant APN.',
	IP_ADDRESS_TYPE: 'Type adresse IP',
	QOS_PROFILE: 'Profil QOS',
	PDN_GW_DYNAMIC_ALLOCATION: 'Attribution dynamique passerelle PDN',
	VPLMN_ADDRESS_ALLOWED: 'Adresse VPLMN autorisée',
	MAX_UPLOAD: 'Téléversement max.',
	MAX_DOWNLOAD: 'Téléchargement max.',
	SUBSCRIBER_PROFILE: 'Profil abonné',
	ENDPOINT_ID_ICCID: 'ID point terminal – Identifiant de carte SIM',
	BARRING_STATUS: 'État prescription',
	NETWORK_ACCESS: 'Accès réseau',
	CURRENT_VLR_NUMBER: 'Numéro VLR actuel',
	PREVIOUS_VLR_NUMBER: 'Numéro VLR précédent',
	CURRENT_SGSN_NUMBER: 'Numéro SGSN actuel',
	PREVIOUS_SGSN_NUMBER: 'Numéro SGSN précédent',
	ROAMING_RESTRICTION: 'Restriction de l’itinérance',
	DATA_3G_ENABLED: 'Données 3G activées',
	NETWORK_STATUS: 'État réseau',
	APN_LIST: 'Liste APN',

	// DATA HISTORY
	DATA_HISTORY: 'Historique données',
	DATA_VOLUME_DOWN_KB: 'Volume données liaison descendante (Ko)',
	DATA_VOLUME_UP_KB: 'Volume données liaison montante (Ko)',
	DURATION_MINUTES: 'Durée (minutes)',
	SESSION_CHARGING_ID: 'ID facturation de session',
	ACCESS_POINT_NAME: 'APN',
	DEVICE_IP_V4: 'Adresse IPv4 de l’appareil',
	DEVICE_IP_V6: 'Adresse IPv6 de l’appareil',
	RATING_ZONE_NAME: 'Nom zone de tarification',
	RATING_GROUP_ID_NAME: 'Nom ID groupe de tarification',
	RATING_GROUP_ID: 'ID groupe de tarification',

	// SMS HISTORY WING
	MESSAGE_HISTORY: 'Historique du message',
	PORTAL_MESSAGES: 'Messages portail',
	SMS_LOG_ID: 'ID journal SMS',
	DATE_SENT_RECEIVED: 'Date d’envoi/de réception',
	SENT_FROM: 'Envoyé de',
	SENT_TO: 'Envoyé à',
	MESSAGE_TYPE: 'Type message',
	ZONE_MODEL: 'Modèle de zone',

	CTD_DATA_USAGE: 'Utilisation données CTD (mégaoctets)',
	CTD_SMS_MO_MT_USAGE: 'Utilisation SMS CTD (messages)',
	CTD_VOICE_USAGE: 'Utilisation voix CTD (min)',

	ACCOUNTS: 'Comptes',
	MUST_BE_BETWEEN_CHARACTERS_LONG: 'Doit comprendre de {min} à {max} caractères',
	INVALID_DOMAIN_FORMAT: 'Format domaine non valide',
	LAST_UPDATE_DATE_TIME: 'Date/heure dernière mise à jour',
	LAST_UPDATED_BY_USER: 'Dernière mise à jour par utilisateur',
	INSESSION: 'En session',
	PLEASE_WAIT_THIS_MAY_TAKE_A_FEW_MINUTES: 'Veuillez patienter. Cela peut prendre quelques minutes, en fonction de la taille du fichier.',
	NO_RESULTS: 'Aucun résultat',
	SELECTED_RATE_PLAN: 'Forfait sélectionné',
	HSPA: 'Hspa',
	LTE: 'Lte',
	VLR: 'Vlr',
	SGSN: 'Sgsn',
	EXPORT: 'Exporter',
	FILE: 'Fichier',
	ERROR_CODE: 'Code erreur',
	ERROR_TYPE: 'Type erreur',
	AT_LINE: 'À la ligne',
	EXPORT_TICKETS: 'Exporter les billets',
	EXPORT_USER_USAGE_DATA: 'Exporter les données d’utilisation des utilisateurs',
	PLACEHOLDER_MODEL: 'AT&T Z431',
	NO_BATCH_ACTIONS_AVAILABLE: 'Aucune action par lots disponible',
	NO_BATCH_ACTIONS_AVAILABLE_SUBTITLE: 'Aucune action par lots à montrer actuellement pour cette table',
	NO_BATCH_REPORTS_AVAILABLE: 'Aucun rapport par lots disponible',
	NO_BATCH_REPORTS_AVAILABLE_SUBTITLE: 'Aucun rapport par lots à montrer actuellement pour cette table',
	MOBILE_ORIGINATING: 'Mobile de départ',
	MOBILE_TERMINATING: 'Mobile d’arrivée',
	NA: 'S.O.',
	GMNA: 'GM Amérique du Nord',
	POD3: 'ACC Pod3',
	POD19: 'Entreprise',
	TABS_ADD_ICON: 'Créer table de points terminaux personnalisée',
	USAGE_SUMMARIES: 'Synthèse d’utilisation',
	EMPTY_SPACE: ' ',
	// endpoints rate plans
	'ENDPOINTS_RATE_PLAN.RATE_PLAN_TYPE': 'Type de forfait',
	'ENDPOINTS_RATE_PLAN.INCLUDED_TEST_DATA': 'Données de test incluses',
	'ENDPOINTS_RATE_PLAN.BILLING_PLATFORM': 'Plateforme de facturation',
	'ENDPOINTS_RATE_PLAN.ACTIVATION_FEE': 'Frais d’activation',
	'ENDPOINTS_RATE_PLAN.INCLUDED_TEST_SMS': 'SMS de test inclus',
	'ENDPOINTS_RATE_PLAN.BILLING_ACCOUNT_ID': 'ID compte facturation',
	'ENDPOINTS_RATE_PLAN.MSU_PRICE': 'Tarif MSU',
	'ENDPOINTS_RATE_PLAN.INCLUDED_TEST_VOICE': 'Test voix inclus',
	'ENDPOINTS_RATE_PLAN.ZONE_MODEL_ID': 'ID de modèle de zone',
	'ENDPOINTS_RATE_PLAN.TYPE': 'Type',
	'ENDPOINTS_RATE_PLAN.INCLUDED_MSU': 'MSU inclus',
	'ENDPOINTS_RATE_PLAN.VALID_FROM': 'Valide à partir du',
	'ENDPOINTS_RATE_PLAN.DEFAULT_RATE_PLAN': 'Forfait par défaut',
	'ENDPOINTS_RATE_PLAN.BASE_RATE_PLAN': 'Forfait de base',
	'ENDPOINTS_RATE_PLAN.POSTPAID': 'Postpayé',
	'ENDPOINTS_RATE_PLAN.APPROVED': 'Approuvé',
	'ENDPOINTS_RATE_PLAN.MONTHLY': 'MENSUEL',
	PASSWORDS_DO_NOT_MATCH: 'Les mots de passe ne correspondent pas',
	NO_WHITE_SPACE_ONLY: 'Le texte doit uniquement contenir des chiffres et/ou des lettres',
	NO_RATE_PLAN_SELECTED: 'aucun forfait sélectionné',
	NO_RATE_PLANS_UNDERLYING_PLATFORM: 'Présence d’une erreur sur la plateforme sous-jacente et changement de forfait non disponible pour le moment',
	ENDPOINT_PROVISIONED_STATE_CHANGE_SUCCESS: 'Le point terminal ID {args1} a bien été provisionné. État IMSI changé à {args2}',
	ENDPOINT_PROVISIONED_STATE_CHANGE_FAIL: 'Échec du provisionnement du point terminal ID {args1} après le changement d’état IMSI. Obtenir assistance',

	// AUTH SIGN IN CALLBACK
	AUTHENTICATION_FAILED: 'Échec authentification',
	AUTHENTICATION_IN_PROGRESS: 'Authentification en cours. Veuillez patienter...',
	MEGABYTES: 'Mégaoctets',
	TICKETS: 'Billets',
	USER_GROUPS: 'Groupes d’utilisateurs',
	RECENT_SESSIONS: 'Sessions récentes',
	ACTIVE_SESSION_SESSION_START_TIME: 'HEURE DÉBUT SESSION',
	ACTIVE_SESSION_TOTAL_VOLUME: 'VOLUME TOTAL',
	ACTIVE_SESSION_TRANSMITTING: 'TRANSMISSION EN COURS',
	ACTIVE_SESSION_RECEIVING: 'RÉCEPTION EN COURS',
	CURRENTLY_ACTIVE_SESSION: 'Session actuellement active',
	ACTIVE_SESSION_CARRIER: 'OPÉRATEUR',
	ACTIVE_SESSION_IP_ADDRESS: 'ADRESSE IP',
	RESET_TO_DEFAULT: 'Remettre à la valeur par défaut',
	DATE_TIME: 'Date/heure',
	SESSION_END: 'Fin session',
	SEND: 'Envoyer',
	SEND_SMS_TO_THIS_DEVICE: 'Envoyer un SMS à cet appareil.',
	TYPE_A_MESSAGE: 'Taper le message que vous souhaitez envoyer à cet appareil à l’aide du service de minimessages. Des frais courants peuvent être facturés.',
	MESSAGES_WILL_APPEAR: 'Les messages apparaîtront comme envoyés et figureront dans les rapports d’utilisation SMS. Des frais peuvent être facturés.',
	SENT_MESSAGES_HISTORY: 'Historique des messages envoyés',
	CHARACTERS_REMAINING: '{remaining} caractères restants...',
	EXPORT_SESSIONS_DATA_FOR_ID: 'Sessions récentes pour {id}',
	EXPORT_DATA: 'Exporter données',
	BYTE_ABBREVIATION: 'B',
	UTC_TIME_ZONE: ' UTC ',
	SEND_SMS_TO_ENDPOINT: 'Envoyer SMS au point terminal',
	SMS: 'SMS',
	OFFPEAK_USAGE_FLAG: 'Indicateur Utilisation hors pointe',
	DATA_USAGE_TOTAL: 'Utilisation données - Total brut',
	DATA_USAGE_UPLINK: 'Utilisation données – Liaison montante, brut',
	DATA_USAGE_DOWNLINK: 'Utilisation données – Liaison descendante, brut',
	DATA_USAGE_ROUNDED: 'Utilisation données – Arrondie',
	DEVICE_IP_ADDRESS: 'Adresse IP appareil',
	SESSION_DURATION: 'Durée de la session',
	RECORD_SEQUENCE_NUMBER: 'Numéro d’ordre de l’enregistrement',
	CHARGING_ID: 'ID de facturation',
	SESSION_CLOSE_CAUSE: 'Cause de fermeture de session',
	TECH_TYPE: 'Type tech',
	PGW_ADDRESS: 'Adresse PGW',
	TIME_OF_FIRST_USAGE: 'Temps de première utilisation',
	TIME_OF_LAST_USAGE: 'Temps de dernière utilisation',
	TIME_USAGE_DURATION: 'Durée d’utilisation',
	STREAM_ID: 'ID de flux',
	CELL_GLOBAL_IDENTIFIER: 'CGI (identifiant mondial de cellule)',
	SERVICE_AREA_IDENTIFIER: 'SAI (identifiant de zone desservie)',
	ROUTING_AREA_IDENTIFIER: 'RAI (identifiant de zone d’acheminement)',
	TRACKING_AREA_IDENTITY: 'TAI (identité de la zone de suivi)',
	E_UTRAN_CGI: 'ECGI (CGI E-UTRAN)',
	LOCATION_AREA_IDENTIFIER: 'LAI (Identifiant de zone de localisation)',
	SERVING_SGSN: 'SGSN desservi',
	COUNTRY_LASTEST_SESSION: 'Pays (dernière session)',
	CARRIER_LASTEST_SESSION: 'Opérateur (dernière session)',
	BILLABLE_FLAG: 'Indicateur Facturable',
	CYCLE_MONTH: 'Mois du cycle',
	CYCLE_YEAR: 'Année du cycle',
	ASSIGNED_RATE_PLAN_ID: 'ID du forfait affecté',
	ASSIGNED_RATING_ZONE_ID: 'ID de zone de tarification affectée',
	RATE_PLAN_VERSION_ID: 'ID de version du forfait',
	SERVICE_TYPE: 'Type de service',
	TRAFFIC_DETAIL_ID: 'ID détails trafic',
	RECORD_RECEIVED_DATE: 'Date réception de l’enregistrement',
	RECORD_OPEN_TIME: 'Heure début enregistrement',
	OPERATOR_NETWORK: 'Réseau de l’opérateur',
	OPERATOR_ACCOUNT_ID: 'ID compte opérateur',
	TAP_CODE: 'Code TAP',
	USAGE_HISTORY: 'Historique d’utilisation',
	DATA: 'Données',
	VOICE: 'Voix',
	HLR_HSS_DETAILS_FOR_ENDPOINT: 'Détails HLR/HSS pour point terminal ID {id}',
	NEED_HELP: 'Besoin d’aide ?',
	MB: ' Mo',
	CONNECTION_STATUS: 'État connexion',
	MESSAGE_CONTENT: 'Contenu message',
	B: 'B',
	CYCLE_TO_DATE_USAGE_TOTAL: 'Utilisation totale cycle jusqu’à ce jour',
	AVERAGE_DAILY_USE: 'Utilisation quotidienne moyenne',
	DATA_USAGE_DOWNLINK_CHART: 'Liaison descendante',
	DATA_USAGE_UPLINK_CHART: 'Liaison montante',
	GDSP: 'GDSP',
	SECOND: 'Seconde',
	SECONDS: 'Secondes',
	DURATION_RAW: 'Durée - brute',
	DURATION_ROUNDED: 'Durée - arrondie',
	USAGE_TYPE: 'Type utilisation',
	SELECT_BILLING_CYCLE: 'Sélection cycle de facturation',
	RANGE: 'Plage',
	CUSTOM_FIELDS_VALUES_UPDATED: 'Valeurs de champs personnalisés mises à jour',
	CUSTOM_FIELDS_VALUES_UPDATED_ERROR: 'Échec de mise à jour des valeurs de champs personnalisés',
	SXMACC: 'SXMACC',
	CHARTS_BILLING_CYCLE_ABBREVIATION: 'BC {billcycle}',
	DATE_RANGE_INPUT_LABEL: 'Du : {startDate} au : {endDate}',
	UH_OH_THERE_WERE_SOME_PROBLEMS_TRYING_TO_LOG_YOU_IN_PLEASE_RETRY_AGAIN_IN_A_FEW_MOMENTS: 'Des problèmes sont apparus lors de votre connexion. Veuillez réessayer ultérieurement. Si le problème persiste, veuillez vous adresser à l’équipe d’assistance.',

	//  LANGUAGE OPTIONS
	ENGLISH: 'English (USA)',
	FRANCAIS: 'Français (France)',
	ESPANOL: 'Español (España)',
	ITALIANO: 'Italiano (Italia)',
	NEDERLANDS: 'Netherlands (Holland)',
	DEUTCHE: 'Deutsche (Deutschland)',
	PORTUGUES: 'Português (Brasil)',
	CHINESE: '中文 (中国)',

	//  COUNTRIES
	COUNTRY_UNITED_STATES: 'États-Unis',
	COUNTRY_AFGHANISTAN: 'Afghanistan',
	COUNTRY_ALAND_ISLANDS: 'Îles d’Åland',
	COUNTRY_ALBANIA: 'Albanie',
	COUNTRY_ALGERIA: 'Algérie',
	COUNTRY_AMERICAN_SAMOA: 'Samoa américaine',
	COUNTRY_ANDORRA: 'Andorre',
	COUNTRY_ANGOLA: 'Angola',
	COUNTRY_ANGUILLA: 'Anguilla',
	COUNTRY_ANTARCTICA: 'Antarctique',
	COUNTRY_ANTIGUA_AND_BARBUDA: 'Antigua-et-Barbuda',
	COUNTRY_ARGENTINA: 'Argentine',
	COUNTRY_ARMENIA: 'Arménie',
	COUNTRY_ARUBA: 'Aruba',
	COUNTRY_AUSTRALIA: 'Australie',
	COUNTRY_AUSTRIA: 'Autriche',
	COUNTRY_AZERBAIJAN: 'Azerbaïdjan',
	COUNTRY_BAHAMAS_THE: 'Bahamas',
	COUNTRY_BAHRAIN: 'Bahreïn',
	COUNTRY_BANGLADESH: 'Bangladesh',
	COUNTRY_BARBADOS: 'Barbade',
	COUNTRY_BELARUS: 'Bélarus',
	COUNTRY_BELGIUM: 'Belgique',
	COUNTRY_BELIZE: 'Bélize',
	COUNTRY_BENIN: 'Bénin',
	COUNTRY_BERMUDA: 'Bermudes',
	COUNTRY_BHUTAN: 'Bhoutan',
	COUNTRY_BOLIVIA: 'Bolivie',
	COUNTRY_BOSNIA_AND_HERZEGOVINA: 'Bosnie-Herzégovine',
	COUNTRY_BOTSWANA: 'Botswana',
	COUNTRY_BOUVET_ISLAND: 'Île Bouvet',
	COUNTRY_BRAZIL: 'Brésil',
	COUNTRY_BRITISH_INDIAN_OCEAN_TERRITORY: 'Territoire britannique de l’océan Indien',
	COUNTRY_BRUNEI: 'Brunei',
	COUNTRY_BULGARIA: 'Bulgarie',
	COUNTRY_BURKINA_FASO: 'Burkina Faso',
	COUNTRY_BURUNDI: 'Burundi',
	COUNTRY_CAMBODIA: 'Cambodge',
	COUNTRY_CAMEROON: 'Cameroun',
	COUNTRY_CANADA: 'Canada',
	COUNTRY_CAPE_VERDE: 'Cap-Vert',
	COUNTRY_CAYMAN_ISLANDS: 'Îles Caïmans',
	COUNTRY_CENTRAL_AFRICAN_REPUBLIC: 'République centrafricaine',
	COUNTRY_CHAD: 'Tchad',
	COUNTRY_CHILE: 'Chili',
	COUNTRY_CHINA: 'Chine',
	COUNTRY_CHRISTMAS_ISLAND: 'Île Christmas',
	COUNTRY_COCOS_KEELING_ISLANDS: 'Îles Cocos (Keeling)',
	COUNTRY_COLOMBIA: 'Colombie',
	COUNTRY_COMOROS: 'Comores',
	COUNTRY_CONGO: 'Congo',
	COUNTRY_CONGO_THE_DEMOCRATIC_REPUBLIC_OF_THE: 'République démocratique du Congo',
	COUNTRY_COOK_ISLANDS: 'Îles Cook',
	COUNTRY_COSTA_RICA: 'Costa Rica',
	COUNTRY_COTE_D__IVOIRE_IVORY_COAST_: 'Côte D’Ivoire (Ivory Coast)',
	COUNTRY_CROATIA_HRVATSKA_: 'Croatie (Hrvatska)',
	COUNTRY_CUBA: 'Cuba',
	COUNTRY_CYPRUS: 'Chypre',
	COUNTRY_CZECH_REPUBLIC: 'République tchèque',
	COUNTRY_DENMARK: 'Danemark',
	COUNTRY_DJIBOUTI: 'Djibouti',
	COUNTRY_DOMINICA: 'Dominique',
	COUNTRY_DOMINICAN_REPUBLIC: 'République dominicaine',
	COUNTRY_EAST_TIMOR: 'Timor-Oriental',
	COUNTRY_ECUADOR: 'Équateur',
	COUNTRY_EGYPT: 'Égypte',
	COUNTRY_EL_SALVADOR: 'El Salvador',
	COUNTRY_EQUATORIAL_GUINEA: 'Guinée équatoriale',
	COUNTRY_ERITREA: 'Érythrée',
	COUNTRY_ESTONIA: 'Estonie',
	COUNTRY_ETHIOPIA: 'Éthiopie',
	COUNTRY_FALKLAND_ISLANDS: 'Îles Malouines',
	COUNTRY_FAROE_ISLANDS: 'Îles Féroé',
	COUNTRY_FIJI_ISLANDS: 'Fidji',
	COUNTRY_FINLAND: 'Finlande',
	COUNTRY_FRANCE: 'France',
	COUNTRY_FRENCH_GUIANA: 'Guyane française',
	COUNTRY_FRENCH_POLYNESIA: 'Polynésie française',
	COUNTRY_FRENCH_SOUTHERN_TERRITORIES: 'Terres australes françaises',
	COUNTRY_GABON: 'Gabon',
	COUNTRY_GAMBIA_THE: 'Gambie',
	COUNTRY_GEORGIA: 'Géorgie',
	COUNTRY_GERMANY: 'Allemagne',
	COUNTRY_GHANA: 'Ghana',
	COUNTRY_GIBRALTAR: 'Gibraltar',
	COUNTRY_GREECE: 'Grèce',
	COUNTRY_GREENLAND: 'Groenland',
	COUNTRY_GRENADA: 'Grenade',
	COUNTRY_GUADELOUPE: 'Guadeloupe',
	COUNTRY_GUAM: 'Guam',
	COUNTRY_GUATEMALA: 'Guatemala',
	COUNTRY_GUERNSEY_AND_ALDERNEY: 'Guernesey et Aurigny',
	COUNTRY_GUINEA: 'Guinée',
	COUNTRY_GUINEA_BISSAU: 'Guinée-Bissau',
	COUNTRY_GUYANA: 'Guyane',
	COUNTRY_HAITI: 'Haïti',
	COUNTRY_HEARD_AND_MCDONALD_ISLANDS: 'Îles Heard et McDonald',
	COUNTRY_HONDURAS: 'Honduras',
	'COUNTRY_HONG_KONG_S.A.R.': 'Hong Kong',
	COUNTRY_HUNGARY: 'Hongrie',
	COUNTRY_ICELAND: 'Islande',
	COUNTRY_INDIA: 'Inde',
	COUNTRY_INDONESIA: 'Indonésie',
	COUNTRY_IRAN: 'Iran',
	COUNTRY_IRAQ: 'Irak',
	COUNTRY_IRELAND: 'Irlande',
	COUNTRY_ISRAEL: 'Israël',
	COUNTRY_ITALY: 'Italie',
	COUNTRY_JAMAICA: 'Jamaïque',
	COUNTRY_JAPAN: 'Japon',
	COUNTRY_JERSEY: 'Jersey',
	COUNTRY_JORDAN: 'Jordanie',
	COUNTRY_KAZAKHSTAN: 'Kazakhstan',
	COUNTRY_KENYA: 'Kenya',
	COUNTRY_KIRIBATI: 'Kiribati',
	COUNTRY_KOREA_NORTH: 'Corée du Nord',
	COUNTRY_KOREA_SOUTH: 'Corée du Sud',
	COUNTRY_KUWAIT: 'Koweït',
	COUNTRY_KYRGYZSTAN: 'Kirghizistan',
	COUNTRY_LAOS: 'Laos',
	COUNTRY_LATVIA: 'Lettonie',
	COUNTRY_LEBANON: 'Liban',
	COUNTRY_LESOTHO: 'Lesotho',
	COUNTRY_LIBERIA: 'Libéria',
	COUNTRY_LIBYA: 'Libye',
	COUNTRY_LIECHTENSTEIN: 'Liechtenstein',
	COUNTRY_LITHUANIA: 'Lithuanie',
	COUNTRY_LUXEMBOURG: 'Luxembourg',
	'COUNTRY_MACAU_S.A.R.': 'Macau',
	COUNTRY_MACEDONIA: 'Macédoine',
	COUNTRY_MADAGASCAR: 'Madagascar',
	COUNTRY_MALAWI: 'Malawi',
	COUNTRY_MALAYSIA: 'Malaisie',
	COUNTRY_MALDIVES: 'Maldives',
	COUNTRY_MALI: 'Mali',
	COUNTRY_MALTA: 'Malte',
	COUNTRY_MAN_ISLE_OF_: 'Île de Man',
	COUNTRY_MARSHALL_ISLANDS: 'Îles Marshall',
	COUNTRY_MARTINIQUE: 'Martinique',
	COUNTRY_MAURITANIA: 'Mauritanie',
	COUNTRY_MAURITIUS: 'Maurice',
	COUNTRY_MAYOTTE: 'Mayotte',
	COUNTRY_MEXICO: 'Mexique',
	COUNTRY_MICRONESIA: 'Micronésie',
	COUNTRY_MOLDOVA: 'Moldova',
	COUNTRY_MONACO: 'Monaco',
	COUNTRY_MONGOLIA: 'Mongolie',
	COUNTRY_MONTSERRAT: 'Montserrat',
	COUNTRY_MOROCCO: 'Maroc',
	COUNTRY_MOZAMBIQUE: 'Mozambique',
	COUNTRY_MYANMAR: 'Myanmar',
	COUNTRY_NAMIBIA: 'Namibie',
	COUNTRY_NAURU: 'Nauru',
	COUNTRY_NEPAL: 'Népal',
	COUNTRY_NETHERLANDS: 'Pays-Bas',
	COUNTRY_NETHERLANDS_ANTILLES: 'Antilles néerlandaises',
	COUNTRY_NEW_CALEDONIA: 'Nouvelle-Calédonie',
	COUNTRY_NEW_ZEALAND: 'Nouvelle-Zélande',
	COUNTRY_NICARAGUA: 'Nicaragua',
	COUNTRY_NIGER: 'Niger',
	COUNTRY_NIGERIA: 'Nigéria',
	COUNTRY_NIUE: 'Niue',
	COUNTRY_NORFOLK_ISLAND: 'Île Norfolk',
	COUNTRY_NORTHERN_MARIANA_ISLANDS: 'Îles Mariannes du Nord',
	COUNTRY_NORWAY: 'Norvège',
	COUNTRY_OMAN: 'Oman',
	COUNTRY_PAKISTAN: 'Pakistan',
	COUNTRY_PALAU: 'Palaos',
	COUNTRY_PALESTINIAN_TERRITORY_OCCUPIED: 'Territoires palestiniens occupés',
	COUNTRY_PANAMA: 'Panama',
	COUNTRY_PAPUA_NEW_GUINEA: 'Papouasie-Nouvelle-Guinée',
	COUNTRY_PARAGUAY: 'Paraguay',
	COUNTRY_PERU: 'Pérou',
	COUNTRY_PHILIPPINES: 'Philippines',
	COUNTRY_PITCAIRN_ISLAND: 'Île Pitcairn',
	COUNTRY_POLAND: 'Pologne',
	COUNTRY_PORTUGAL: 'Portugal',
	COUNTRY_PUERTO_RICO: 'Puerto Rico',
	COUNTRY_QATAR: 'Qatar',
	COUNTRY_REUNION: 'La Réunion',
	COUNTRY_ROMANIA: 'Roumanie',
	COUNTRY_RUSSIA: 'Russie',
	COUNTRY_RWANDA: 'Rwanda',
	COUNTRY_SAINT_HELENA: 'Sainte-Hélène',
	COUNTRY_SAINT_KITTS_AND_NEVIS: 'Saint-Kitts-et-Nevis',
	COUNTRY_SAINT_LUCIA: 'Sainte-Lucie',
	COUNTRY_SAINT_PIERRE_AND_MIQUELON: 'Saint-Pierre et Miquelon',
	COUNTRY_SAINT_VINCENT_AND_THE_GRENADINES: 'Saint-Vincent-et-les Grenadines',
	COUNTRY_SAMOA: 'Samoa',
	COUNTRY_SAN_MARINO: 'Saint-Marin',
	COUNTRY_SAO_TOME_AND_PRINCIPE: 'Sao Tomé-et-Principe',
	COUNTRY_SAUDI_ARABIA: 'Arabie saoudite',
	COUNTRY_SENEGAL: 'Sénégal',
	COUNTRY_SERBIA: 'Serbie',
	COUNTRY_SEYCHELLES: 'Seychelles',
	COUNTRY_SIERRA_LEONE: 'Sierra Leone',
	COUNTRY_SINGAPORE: 'Singapour',
	COUNTRY_SLOVAKIA: 'Slovaquie',
	COUNTRY_SLOVENIA: 'Slovénie',
	COUNTRY_SOLOMON_ISLANDS: 'Îles Salomon',
	COUNTRY_SOMALIA: 'Somalie',
	COUNTRY_SOUTH_AFRICA: 'Afrique du Sud',
	COUNTRY_SOUTH_GEORGIA: 'Géorgie du Sud',
	COUNTRY_SOUTH_SUDAN: 'Soudan du Sud',
	COUNTRY_SPAIN: 'Espagne',
	COUNTRY_SRI_LANKA: 'Sri Lanka',
	COUNTRY_SUDAN: 'Soudan',
	COUNTRY_SURINAME: 'Suriname',
	COUNTRY_SVALBARD_AND_JAN_MAYEN_ISLANDS: 'Svalbard et île de Jan Mayen',
	COUNTRY_SWAZILAND: 'Eswatini',
	COUNTRY_SWEDEN: 'Suède',
	COUNTRY_SWITZERLAND: 'Suisse',
	COUNTRY_SYRIA: 'Syrie',
	COUNTRY_TAIWAN: 'Taïwan',
	COUNTRY_TAJIKISTAN: 'Tadjikistan',
	COUNTRY_TANZANIA: 'Tanzanie',
	COUNTRY_THAILAND: 'Thaïlande',
	COUNTRY_TOGO: 'Togo',
	COUNTRY_TOKELAU: 'Tokelau',
	COUNTRY_TONGA: 'Tonga',
	COUNTRY_TRINIDAD_AND_TOBAGO: 'Trinité-et-Tobago',
	COUNTRY_TUNISIA: 'Tunisie',
	COUNTRY_TURKEY: 'Turquie',
	COUNTRY_TURKMENISTAN: 'Turkménistan',
	COUNTRY_TURKS_AND_CAICOS_ISLANDS: 'Îles Turks et Caicos',
	COUNTRY_TUVALU: 'Tuvalu',
	COUNTRY_UGANDA: 'Ouganda',
	COUNTRY_UKRAINE: 'Ukraine',
	COUNTRY_UNITED_ARAB_EMIRATES: 'Émirats arabes unis',
	COUNTRY_UNITED_KINGDOM: 'Royaume-Uni',
	COUNTRY_UNITED_STATES_MINOR_OUTLYING_ISLANDS: 'Îles mineures éloignées des États-Unis',
	COUNTRY_URUGUAY: 'Uruguay',
	COUNTRY_UZBEKISTAN: 'Ouzbékistan',
	COUNTRY_VANUATU: 'Vanuatu',
	COUNTRY_VATICAN_CITY_STATE_HOLY_SEE_: 'État de la Cité du Vatican (Saint-Siège)',
	COUNTRY_VENEZUELA: 'Vénézuéla',
	COUNTRY_VIETNAM: 'Vietnam',
	COUNTRY_VIRGIN_ISLANDS_BRITISH_: 'Îles vierges (britanniques)',
	COUNTRY_VIRGIN_ISLANDS_US_: 'Îles vierges (américaines)',
	COUNTRY_WALLIS_AND_FUTUNA_ISLANDS: 'Îles Wallis-et-Futuna',
	COUNTRY_WESTERN_SAHARA: 'Sahara-Occidental',
	COUNTRY_YEMEN: 'Yémen',
	COUNTRY_ZAMBIA: 'Zambie',
	COUNTRY_ZIMBABWE: 'Zimbabwe',

	//  ÉTAT/PROVINCE
	STATE_ANDAMAN_AND_NICOBAR_ISLANDS: 'Îles Andaman et Nicobar',
	STATE_ANDHRA_PRADESH: 'Andhra Pradesh',
	STATE_ARUNACHAL_PRADESH: 'Arunachal Pradesh',
	STATE_ASSAM: 'Assam',
	STATE_BIHAR: 'Bihar',
	STATE_CHANDIGARH: 'Chandigarh',
	STATE_CHHATTISGARH: 'Chhattisgarh',
	STATE_DADRA_AND_NAGAR_HAVELI: 'Dadra et Nagar Haveli',
	STATE_DAMAN_AND_DIU: 'Daman et Diu',
	STATE_DELHI: 'Delhi',
	STATE_GOA: 'Goa',
	STATE_GUJARAT: 'Gujarat',
	STATE_HARYANA: 'Haryana',
	STATE_HIMACHAL_PRADESH: 'Himachal Pradesh',
	STATE_JAMMU_AND_KASHMIR: 'Jammu-et-Cachemire',
	STATE_JHARKHAND: 'Jharkhand',
	STATE_KARNATAKA: 'Karnataka',
	STATE_KENMORE: 'Kenmore',
	STATE_KERALA: 'Kerala',
	STATE_LAKSHADWEEP: 'Lakshadweep',
	STATE_MADHYA_PRADESH: 'Madhya Pradesh',
	STATE_MAHARASHTRA: 'Maharashtra',
	STATE_MANIPUR: 'Manipur',
	STATE_MEGHALAYA: 'Meghalaya',
	STATE_MIZORAM: 'Mizoram',
	STATE_NAGALAND: 'Nagaland',
	STATE_NARORA: 'Narora',
	STATE_NATWAR: 'Natwar',
	STATE_ODISHA: 'Odisha',
	STATE_PASCHIM_MEDINIPUR: 'Paschim Medinipur',
	STATE_PONDICHERRY: 'Puducherry',
	STATE_PUNJAB: 'Punjab',
	STATE_RAJASTHAN: 'Rajasthan',
	STATE_SIKKIM: 'Sikkim',
	STATE_TAMIL_NADU: 'Tamil Nadu',
	STATE_TELANGANA: 'Telangana',
	STATE_TRIPURA: 'Tripura',
	STATE_UTTAR_PRADESH: 'Uttar Pradesh',
	STATE_UTTARAKHAND: 'Uttarakhand',
	STATE_VAISHALI: 'Vaishali',
	STATE_WEST_BENGAL: 'Bengale-Occidental',
	STATE_BADAKHSHAN: 'Badakhshan',
	STATE_BADGIS: 'Badghis',
	STATE_BAGLAN: 'Baghlan',
	STATE_BALKH: 'Balkh',
	STATE_BAMIYAN: 'Bamian',
	STATE_FARAH: 'Farah',
	STATE_FARYAB: 'Faryab',
	STATE_GAWR: 'Ghowr',
	STATE_GAZNI: 'Ghazni',
	STATE_HERAT: 'Herat',
	STATE_ALABAMA: 'Alabama',
	STATE_ALASKA: 'Alaska',
	STATE_ARIZONA: 'Arizona',
	STATE_ARKANSAS: 'Arkansas',
	STATE_GEORGIA: 'Géorgie',
	STATE_NEW_YORK: 'New York',
	STATE_VERMONT: 'Vermont',
	STATE_WASHINGTON: 'Washington',
	STATE_HILMAND: 'Helmand',
	STATE_JAWZJAN: 'Jowzjan',
	STATE_KABUL: 'Kaboul',
	STATE_KAPISA: 'Kapisa',
	STATE_KHAWST: 'Khowst',
	STATE_KUNAR: 'Konar',
	STATE_LAGMAN: 'Laghman',
	STATE_LAWGHAR: 'Lowgar',
	STATE_NANGARHAR: 'Nangarhar',
	STATE_NIMRUZ: 'Nimruz',
	STATE_NURISTAN: 'Nuristan',
	STATE_PAKTIKA: 'Paktika',
	STATE_PAKTIYA: 'Paktia',
	STATE_PARWAN: 'Parvan',
	STATE_QANDAHAR: 'Kandahar',
	STATE_QUNDUZ: 'Kondoz',
	STATE_SAMANGAN: 'Samangan',
	STATE_SAR_E_PUL: 'Sar-e Pol',
	STATE_TAKHAR: 'Takhar',
	STATE_URUZGAN: 'Oruzgan',
	STATE_WARDAG: 'Vardak',
	STATE_ZABUL: 'Zabol',
	STATE_BERAT: 'Berat',
	STATE_BULQIZE: 'Bulqizë',
	STATE_DELVINE: 'Delvinë',
	STATE_DEVOLL: 'Devoll',
	STATE_DIBRE: 'Dibër',
	STATE_DURRES: 'Durrës',
	STATE_ELBASAN: 'Elbasan',
	STATE_FIER: 'Fier',
	STATE_GJIROKASTER: 'Gjirokastër',
	STATE_GRAMSH: 'Gramsh',
	STATE_HAS: 'Has',
	STATE_KAVAJE: 'Kavajë',
	STATE_KOLONJE: 'Kolonjë',
	STATE_KORCE: 'Korçë',
	STATE_KRUJE: 'Krujë',
	STATE_KUCOVE: 'Kuçovë',
	STATE_KUKES: 'Kukës',
	STATE_KURBIN: 'Kurbin',
	STATE_LEZHE: 'Lezhë',
	STATE_LIBRAZHD: 'Librazhd',
	STATE_LUSHNJE: 'Lushnjë',
	STATE_MALLAKASTER: 'Mallakastër',
	STATE_MALSI_E_MADHE: 'Malësi e Madhe',
	STATE_MAT: 'Mat',
	STATE_MIRDITE: 'Mirditë',
	STATE_PEQIN: 'Peqin',
	STATE_PERMET: 'Përmet',
	STATE_POGRADEC: 'Pogradec',
	STATE_PUKE: 'Pukë',
	STATE_SARANDE: 'Sarandë',
	STATE_SHKODER: 'Shkodër',
	STATE_SKRAPAR: 'Skrapar',
	STATE_TEPELENE: 'Tepelenë',
	STATE_TIRANE: 'Tirana',
	STATE_TROPOJE: 'Tropojë',
	STATE_VLORE: 'Vlorë',
	STATE_AYN_DAFLAH: 'Aïn Defla',
	STATE_AYN_TAMUSHANAT: 'Aïn Témouchent',
	STATE_ADRAR: 'Adrar',
	STATE_ALGIERS: 'Alger',
	STATE_ANNABAH: 'Annaba',
	STATE_BASHSHAR: 'Béchar',
	STATE_BATNAH: 'Batna',
	STATE_BIJAYAH: 'Béjaïa',
	STATE_BISKRAH: 'Biskra',
	STATE_BLIDAH: 'Blida',
	STATE_BUIRAH: 'Bouira',
	STATE_BUMARDAS: 'Boumerdès',
	STATE_BURJ_BU_ARRIRIJ: 'Bordj Bou Arreridj',
	STATE_GHALIZAN: 'Relizane',
	STATE_GHARDAYAH: 'Ghardaïa',
	STATE_ILIZI: 'Illizi',
	STATE_JIJILI: 'Jijel',
	STATE_JILFAH: 'Djelfa',
	STATE_KHANSHALAH: 'Khenchela',
	STATE_MASILAH: 'M’Sila',
	STATE_MIDYAH: 'Médéa',
	STATE_MILAH: 'Mila',
	STATE_MUASKAR: 'Mascara',
	STATE_MUSTAGHANAM: 'Mostaganem',
	STATE_NAAMA: 'Naâma',
	STATE_ORAN: 'Oran',
	STATE_OUARGLA: 'Ouargla',
	STATE_QALMAH: 'Guelma',
	STATE_QUSTANTINAH: 'Constantine',
	STATE_SAKIKDAH: 'Skikda',
	STATE_SATIF: 'Sétif',
	STATE_SAYDA: 'Saïda',
	STATE_SIDI_BAN_AL___ABBAS: 'Sidi Bel Abbès',
	STATE_SUQ_AHRAS: 'Souk Ahras',
	STATE_TAMANGHASAT: 'Tamanrasset',
	STATE_TIBAZAH: 'Tipaza',
	STATE_TIBISSAH: 'Tébessa',
	STATE_TILIMSAN: 'Tlemcen',
	STATE_TINDUF: 'Tindouf',
	STATE_TISAMSILT: 'Tissemsilt',
	STATE_TIYARAT: 'Tiaret',
	STATE_TIZI_WAZU: 'Tizi Ouzou',
	STATE_UMM_AL_BAWAGHI: 'Oum El Bouaghi',
	STATE_WAHRAN: 'Oran',
	STATE_WARQLA: 'Ouargla',
	STATE_WILAYA_D_ALGER: 'Province d’Alger',
	STATE_WILAYA_DE_BEJAIA: 'Province de Béjaïa',
	STATE_WILAYA_DE_CONSTANTINE: 'Province de Constantine',
	STATE_AL_AGHWAT: 'Laghouat',
	STATE_AL_BAYADH: 'El Bayadh',
	STATE_AL_JAZA__IR: 'Alger',
	STATE_AL_WAD: 'El Oued',
	STATE_ASH_SHALIF: 'Chlef',
	STATE_AT_TARIF: 'El Taref',
	STATE_EASTERN: 'oriental',
	STATE_MANU__A: 'Manu’a',
	STATE_SWAINS_ISLAND: 'Île de Swains',
	STATE_WESTERN: 'occidental',
	STATE_ANDORRA_LA_VELLA: 'Andorre-la-Vieille',
	STATE_CANILLO: 'Canillo',
	STATE_ENCAMP: 'Encamp',
	STATE_LA_MASSANA: 'La Massana',
	STATE_LES_ESCALDES: 'Escaldes-Engordany',
	STATE_ORDINO: 'Ordino',
	STATE_SANT_JULIA_DE_LORIA: 'Sant Julia de Loria',
	STATE_BENGO: 'Bengo',
	STATE_BENGUELA: 'Benguela',
	STATE_BIE: 'Bié',
	STATE_CABINDA: 'Cabinda',
	STATE_CUNENE: 'Kunene',
	STATE_HUAMBO: 'Huambo',
	STATE_HUILA: 'Huila',
	STATE_KUANDO_KUBANGO: 'Kwando-Kubango',
	STATE_KWANZA_NORTE: 'Kwanza-Nord',
	STATE_KWANZA_SUL: 'Kwanza-Sud',
	STATE_LUANDA: 'Luanda',
	STATE_LUNDA_NORTE: 'Lunda-Nord',
	STATE_LUNDA_SUL: 'Lunda-Sud',
	STATE_MALANJE: 'Malanje',
	STATE_MOXICO: 'Moxico',
	STATE_NAMIBE: 'Namibe',
	STATE_UIGE: 'Uíge',
	STATE_ZAIRE: 'Zaire',
	STATE_OTHER_PROVINCES: 'Autres provinces',
	STATE_SECTOR_CLAIMED_BY_ARGENTINA_CH: 'Secteur revendiqué par l’Argentine/le Chili',
	STATE_SECTOR_CLAIMED_BY_ARGENTINA_UK: 'Secteur revendiqué par l’Argentine/le RU',
	STATE_SECTOR_CLAIMED_BY_AUSTRALIA: 'Secteur revendiqué par l’Australie',
	STATE_SECTOR_CLAIMED_BY_FRANCE: 'Secteur revendiqué par la France',
	STATE_SECTOR_CLAIMED_BY_NEW_ZEALAND: 'Secteur revendiqué par la Nouvelle-Zélande',
	STATE_SECTOR_CLAIMED_BY_NORWAY: 'Secteur revendiqué par la Norvège',
	STATE_UNCLAIMED_SECTOR: 'Secteur non revendiqué',
	STATE_BARBUDA: 'Barbuda',
	STATE_SAINT_GEORGE: 'Saint-Georges',
	STATE_SAINT_JOHN: 'Saint-John',
	STATE_SAINT_MARY: 'Sainte-Marie',
	STATE_SAINT_PAUL: 'Saint-Paul',
	STATE_SAINT_PETER: 'Saint-Pierre',
	STATE_SAINT_PHILIP: 'Saint-Philippe',
	STATE_BUENOS_AIRES: 'Buenos Aires',
	STATE_CATAMARCA: 'Catamarca',
	STATE_CHACO: 'Chaco',
	STATE_CHUBUT: 'Chubut',
	STATE_CORDOBA: 'Cordoba',
	STATE_CORRIENTES: 'Corrientes',
	STATE_DISTRITO_FEDERAL: 'Distrito Federal',
	STATE_ENTRE_RIOS: 'Entre Rios',
	STATE_FORMOSA: 'Formosa',
	STATE_JUJUY: 'Jujuy',
	STATE_LA_PAMPA: 'La Pampa',
	STATE_HIDD: 'Hidd',
	STATE_LA_RIOJA: 'La Rioja',
	STATE_MENDOZA: 'Mendoza',
	STATE_MISIONES: 'Misiones',
	STATE_NEUQUEN: 'Neuquén',
	STATE_RIO_NEGRO: 'Río Negro',
	STATE_SALTA: 'Salta',
	STATE_SAN_JUAN: 'San Juan',
	STATE_SAN_LUIS: 'San Luis',
	STATE_SANTA_CRUZ: 'Santa Cruz',
	STATE_SANTA_FE: 'Santa Fe',
	STATE_SANTIAGO_DEL_ESTERO: 'Santiago del Estero',
	STATE_TIERRA_DEL_FUEGO: 'Terre de Feu',
	STATE_TUCUMAN: 'Tucumán',
	STATE_ARAGATSOTN: 'Aragatsotn',
	STATE_ARARAT: 'Ararat',
	STATE_ARMAVIR: 'Armavir',
	STATE_GEGHARKUNIK: 'Gegharkunik',
	STATE_KOTAIK: 'Kotayk',
	STATE_LORI: 'Lorri',
	STATE_SHIRAK: 'Shirak',
	STATE_STEPANAKERT: 'Stepanakert',
	STATE_SYUNIK: 'Syunik',
	STATE_TAVUSH: 'Tavush',
	STATE_VAYOTS_DZOR: 'Vayots Dzor',
	STATE_YEREVAN: 'Erevan',
	STATE_ARUBA: 'Aruba',
	STATE_AUCKLAND: 'Auckland',
	STATE_AUSTRALIAN_CAPITAL_TERRITORY: 'Territoire de la capitale de l’Australie',
	STATE_BALGOWLAH: 'Balgowlah',
	STATE_BALMAIN: 'Balmain',
	STATE_BANKSTOWN: 'Bankstown',
	STATE_BAULKHAM_HILLS: 'Baulkham Hills',
	STATE_BONNET_BAY: 'Bonnet Bay',
	STATE_CAMBERWELL: 'Camberwell',
	STATE_CAROLE_PARK: 'Carole Park',
	STATE_CASTLE_HILL: 'Castle Hill',
	STATE_CAULFIELD: 'Caulfield',
	STATE_CHATSWOOD: 'Chatswood',
	STATE_CHELTENHAM: 'Cheltenham',
	STATE_CHERRYBROOK: 'Cherrybrook',
	STATE_CLAYTON: 'Clayton',
	STATE_COLLINGWOOD: 'Collingwood',
	STATE_FRENCHS_FOREST: 'Frenchs Forest',
	STATE_HAWTHORN: 'Hawthorn',
	STATE_JANNNALI: 'Jannali',
	STATE_KNOXFIELD: 'Knoxfield',
	STATE_MELBOURNE: 'Melbourne',
	STATE_NEW_SOUTH_WALES: 'Nouvelle-Galles du Sud',
	STATE_NORTHERN_TERRITORY: 'Territoire du Nord',
	STATE_PERTH: 'Perth',
	STATE_QUEENSLAND: 'Queensland',
	STATE_SOUTH_AUSTRALIA: 'Australie-Méridionale',
	STATE_TASMANIA: 'Tasmanie',
	STATE_TEMPLESTOWE: 'Templestowe',
	STATE_VICTORIA: 'Victoria',
	STATE_WERRIBEE_SOUTH: 'Werribee South',
	STATE_WESTERN_AUSTRALIA: 'Australie-Occidentale',
	STATE_WHEELER: 'Wheeler',
	STATE_BUNDESLAND_SALZBURG: 'État de Salzbourg',
	STATE_BUNDESLAND_STEIERMARK: 'État de Styrie',
	STATE_BUNDESLAND_TIROL: 'État de Tyrol',
	STATE_BURGENLAND: 'Burgenland',
	STATE_CARINTHIA: 'Carinthie',
	STATE_KARNTEN: 'Carinthie',
	STATE_LIEZEN: 'Liezen',
	STATE_LOWER_AUSTRIA: 'Basse-Autriche',
	STATE_NIEDEROSTERREICH: 'Basse-Autriche',
	STATE_OBEROSTERREICH: 'Haute-Autriche',
	STATE_SALZBURG: 'Salzbourg',
	STATE_SCHLESWIG_HOLSTEIN: 'Schleswig-Holstein',
	STATE_STEIERMARK: 'Styrie',
	STATE_STYRIA: 'Styrie',
	STATE_TIROL: 'Tyrol',
	STATE_UPPER_AUSTRIA: 'Haute-Autriche',
	STATE_VORARLBERG: 'Vorarlberg',
	STATE_WIEN: 'Vienne',
	STATE_ABSERON: 'Abseron',
	STATE_BAKI_SAHARI: 'Bakou',
	STATE_GANCA: 'Ganca',
	STATE_GANJA: 'Gandja',
	STATE_KALBACAR: 'Kalbajar',
	STATE_LANKARAN: 'Lankaran',
	STATE_MIL_QARABAX: 'Mil-Karabakh',
	STATE_MUGAN_SALYAN: 'Mughan-Salyan',
	STATE_NAGORNI_QARABAX: 'Haut-Karabakh',
	STATE_NAXCIVAN: 'Nakhitchevan',
	STATE_PRIARAKS: 'Priaraks',
	STATE_QAZAX: 'Kazakh',
	STATE_SAKI: 'Saki',
	STATE_SIRVAN: 'Sirvan',
	STATE_XACMAZ: 'Khatchmaz',
	STATE_ABACO: 'Abaco',
	STATE_ACKLINS_ISLAND: 'Île d’Acklins',
	STATE_ANDROS: 'Andros',
	STATE_BERRY_ISLANDS: 'Îles Berry',
	STATE_BIMINIS: 'Îles Bimini',
	STATE_CAT_ISLAND: 'Cat Island',
	STATE_CROOKED_ISLAND: 'Crooked Island',
	STATE_ELEUTHERA: 'Eleuthera',
	STATE_EXUMA_AND_CAYS: 'Exuma et Cays',
	STATE_GRAND_BAHAMA: 'Grand Bahama',
	STATE_INAGUA_ISLANDS: 'Îles Inagua',
	STATE_LONG_ISLAND: 'Long Island',
	STATE_MAYAGUANA: 'Mayaguana',
	STATE_NEW_PROVIDENCE: 'New Providence',
	STATE_RAGGED_ISLAND: 'Ragged Island',
	STATE_RUM_CAY: 'Rum Cay',
	STATE_SAN_SALVADOR: 'San Salvador',
	STATE_ISA: 'Isa',
	STATE_BADIYAH: 'Badiyah',
	STATE_JIDD_HAFS: 'Jidd Hafs',
	STATE_MAHAMA: 'Mahama',
	STATE_MANAMA: 'Manama',
	STATE_SITRAH: 'Sitra',
	STATE_AL_MANAMAH: 'Manama',
	STATE_AL_MUHARRAQ: 'Muharraq',
	STATE_AR_RIFA__A: 'Riffa',
	STATE_BAGAR_HAT: 'Bagar Hat',
	STATE_BANDARBAN: 'Bandarban',
	STATE_BARGUNA: 'Barguna',
	STATE_BARISAL: 'Barisal',
	STATE_BHOLA: 'Bhola',
	STATE_BOGORA: 'Bogra',
	STATE_BRAHMAN_BARIYA: 'Brahmanbaria',
	STATE_CHANDPUR: 'Chandpur',
	STATE_CHATTAGAM: 'Chattagam',
	STATE_CHITTAGONG_DIVISION: 'Division de Chittagong',
	STATE_CHUADANGA: 'Chuadanga',
	STATE_DHAKA: 'Dhaka',
	STATE_DINAJPUR: 'Dinajpur',
	STATE_FARIDPUR: 'Faridpur',
	STATE_FENI: 'Feni',
	STATE_GAYBANDA: 'Gaybandha',
	STATE_GAZIPUR: 'Gazipur',
	STATE_GOPALGANJ: 'Gopalganj',
	STATE_HABIGANJ: 'Habiganj',
	STATE_JAIPUR_HAT: 'Joypurhat',
	STATE_JAMALPUR: 'Jamalpur',
	STATE_JESSOR: 'Jessore',
	STATE_JHALAKATI: 'Jhalakathi',
	STATE_JHANAYDAH: 'Jhenaidah',
	STATE_KHAGRACHHARI: 'Khagrachhari',
	STATE_KHULNA: 'Khulna',
	STATE_KISHORGANJ: 'Kishoreganj',
	STATE_KOKS_BAZAR: 'Cox’s Bazar',
	STATE_KOMILLA: 'Comilla',
	STATE_KURIGRAM: 'Kurigram',
	STATE_KUSHTIYA: 'Kushtia',
	STATE_LAKSHMIPUR: 'Lakshmipur',
	STATE_LALMANIR_HAT: 'Lalmonirhat',
	STATE_MADARIPUR: 'Madaripur',
	STATE_MAGURA: 'Magura',
	STATE_MAIMANSINGH: 'Mymensingh',
	STATE_MANIKGANJ: 'Manikganj',
	STATE_MAULVI_BAZAR: 'Moulvibazar',
	STATE_MEHERPUR: 'Meherpur',
	STATE_MUNSHIGANJ: 'Munshiganj',
	STATE_NARAL: 'Narail',
	STATE_NARAYANGANJ: 'Narayanganj',
	STATE_NARSINGDI: 'Narsingdi',
	STATE_NATOR: 'Natore',
	STATE_NAUGAON: 'Naogaon',
	STATE_NAWABGANJ: 'Nawabganj',
	STATE_NETRAKONA: 'Netrakona',
	STATE_NILPHAMARI: 'Nilphamari',
	STATE_NOAKHALI: 'Noakhali',
	STATE_PABNA: 'Pabna',
	STATE_PANCHAGARH: 'Panchagarh',
	STATE_PATUAKHALI: 'Patuakhali',
	STATE_PIROJPUR: 'Pirojpur',
	STATE_RAJBARI: 'Rajbari',
	STATE_RAJSHAHI: 'Rajshahi',
	STATE_RANGAMATI: 'Rangamati',
	STATE_RANGPUR: 'Rangpur',
	STATE_SATKHIRA: 'Satkhira',
	STATE_SHARIATPUR: 'Shariatpur',
	STATE_SHERPUR: 'Sherpur',
	STATE_SILHAT: 'Sylhet',
	STATE_SIRAJGANJ: 'Sirajganj',
	STATE_SUNAMGANJ: 'Sunamganj',
	STATE_TANGAYAL: 'Tangail',
	STATE_THAKURGAON: 'Thakurgaon',
	STATE_CHRIST_CHURCH: 'Christ Church',
	STATE_SAINT_ANDREW: 'Saint Andrew',
	STATE_SAINT_JAMES: 'Saint James',
	STATE_SAINT_JOSEPH: 'Saint Joseph',
	STATE_SAINT_LUCY: 'Saint Lucy',
	STATE_SAINT_MICHAEL: 'Saint Michael',
	STATE_SAINT_THOMAS: 'Saint Thomas',
	STATE_BREST: 'Brest',
	STATE_HOMJEL: 'Gomel',
	STATE_HRODNA: 'Grodno',
	STATE_MAHILJOW: 'Mahilyow',
	STATE_MAHILYOWSKAYA_VOBLASTS: 'Mahilyowskaya Voblasts',
	STATE_MINSK: 'Minsk',
	STATE_MINSKAJA_VOBLASTS: 'Minskaja Voblasts',
	STATE_PETRIK: 'Petrik',
	STATE_VICEBSK: 'Vicebsk',
	STATE_ANTWERPEN: 'Anvers',
	STATE_BERCHEM: 'Berchem',
	STATE_BRABANT: 'Brabant',
	STATE_BRABANT_WALLON: 'Brabant wallon',
	STATE_BRUSSEL: 'Bruxelles',
	STATE_EAST_FLANDERS: 'Flandre-Orientale',
	STATE_HAINAUT: 'Hainaut',
	STATE_LIEGE: 'Liège',
	STATE_LIMBURG: 'Limbourg',
	STATE_LUXEMBOURG: 'Luxembourg',
	STATE_NAMUR: 'Namur',
	STATE_ONTARIO: 'Ontario',
	STATE_OOST_VLAANDEREN: 'Flandre-Orientale',
	STATE_PROVINCIE_BRABANT: 'Province du Brabant',
	STATE_VLAAMS_BRABANT: 'Brabant flamand',
	STATE_WALLONNE: 'Région wallonne',
	STATE_WEST_VLAANDEREN: 'Flandre-Occidentale',
	STATE_BELIZE: 'Bélize',
	STATE_CAYO: 'Cayo',
	STATE_COROZAL: 'Corozal',
	STATE_ORANGE_WALK: 'Orange Walk',
	STATE_STANN_CREEK: 'Stann Creek',
	STATE_TOLEDO: 'Toledo',
	STATE_ALIBORI: 'Alibori',
	STATE_ATACORA: 'Atacora',
	STATE_ATLANTIQUE: 'Atlantique',
	STATE_BORGOU: 'Borgou',
	STATE_COLLINES: 'Collines',
	STATE_COUFFO: 'Couffo',
	STATE_DONGA: 'Donga',
	STATE_LITTORAL: 'Littoral',
	STATE_MONO: 'Mono',
	STATE_OUEME: 'Ouémé',
	STATE_PLATEAU: 'Plateau',
	STATE_ZOU: 'Zou',
	STATE_HAMILTON: 'Hamilton',
	STATE_BUMTHANG: 'Bumthang',
	STATE_CHHUKHA: 'Chhukha',
	STATE_CHIRANG: 'Chirang',
	STATE_DAGA: 'Dagana',
	STATE_GEYLEGPHUG: 'Geylegphug',
	STATE_HA: 'Ha',
	STATE_LHUNTSHI: 'Lhuntshi',
	STATE_MONGAR: 'Mongar',
	STATE_PEMAGATSEL: 'Pemagatsel',
	STATE_PUNAKHA: 'Punakha',
	STATE_RINPUNG: 'Rinpung',
	STATE_SAMCHI: 'Samchi',
	STATE_SAMDRUP_JONGKHAR: 'Samdrup Jongkhar',
	STATE_SHEMGANG: 'Shemgang',
	STATE_TASHIGANG: 'Tashigang',
	STATE_TIMPHU: 'Thimphu',
	STATE_TONGSA: 'Tongsa',
	STATE_WANGDIPHODRANG: 'Wangdi Phodrang',
	STATE_BENI: 'Beni',
	STATE_CHUQUISACA: 'Chuquisaca',
	STATE_COCHABAMBA: 'Cochabamba',
	STATE_LA_PAZ: 'La Paz',
	STATE_ORURO: 'Oruro',
	STATE_PANDO: 'Pando',
	STATE_POTOSI: 'Potosi',
	STATE_TARIJA: 'Tarija',
	STATE_FEDERACIJA_BOSNA_I_HERCEGOVINA: 'Fédération de Bosnie-et-Herzégovine',
	STATE_REPUBLIKA_SRPSKA: 'République serbe',
	STATE_CENTRAL_BOBONONG: 'Central Bobonong',
	STATE_CENTRAL_BOTETI: 'Central Boteti',
	STATE_CENTRAL_MAHALAPYE: 'Central Mahalapye',
	STATE_CENTRAL_SEROWE_PALAPYE: 'Central Serowe-Palapye',
	STATE_CENTRAL_TUTUME: 'Central Tutume',
	STATE_CHOBE: 'Chobe',
	STATE_FRANCISTOWN: 'Francistown',
	STATE_GABORONE: 'Gaborone',
	STATE_GHANZI: 'Ghanzi',
	STATE_JWANENG: 'Jwaneng',
	STATE_KGALAGADI_NORTH: 'Kgalagadi North',
	STATE_KGALAGADI_SOUTH: 'Kgalagadi South',
	STATE_KGATLENG: 'Kgatleng',
	STATE_KWENENG: 'Kweneng',
	STATE_LOBATSE: 'Lobatse',
	STATE_NGAMILAND: 'Ngamiland',
	STATE_NGWAKETSE: 'Ngwaketse',
	STATE_NORTH_EAST: 'North East',
	STATE_OKAVANGO: 'Okavango',
	STATE_ORAPA: 'Orapa',
	STATE_SELIBE_PHIKWE: 'Selibe Phikwe',
	STATE_SOUTH_EAST: 'South East',
	STATE_SOWA: 'Sowa',
	STATE_BOUVET_ISLAND: 'Île Bouvet',
	STATE_ACRE: 'Acre',
	STATE_ALAGOAS: 'Alagoas',
	STATE_AMAPA: 'Amapá',
	STATE_AMAZONAS: 'Amazonas',
	STATE_BAHIA: 'Bahia',
	STATE_CEARA: 'Ceará',
	STATE_ESPIRITO_SANTO: 'Espírito Santo',
	STATE_ESTADO_DE_SAO_PAULO: 'État de São Paulo',
	STATE_GOIAS: 'Goiás',
	STATE_MARANHAO: 'Maranhão',
	STATE_MATO_GROSSO: 'Mato Grosso',
	STATE_MATO_GROSSO_DO_SUL: 'Mato Grosso do Sul',
	STATE_MINAS_GERAIS: 'Minas Gerais',
	STATE_PARA: 'Pará',
	STATE_PARAIBA: 'Paraíba',
	STATE_PARANA: 'Paraná',
	STATE_PERNAMBUCO: 'Pernambuco',
	STATE_PIAUI: 'Piauí',
	STATE_RIO_GRANDE_DO_NORTE: 'Rio Grande do Norte',
	STATE_RIO_GRANDE_DO_SUL: 'Rio Grande do Sul',
	STATE_RIO_DE_JANEIRO: 'Rio de Janeiro',
	STATE_RONDONIA: 'Rondônia',
	STATE_RORAIMA: 'Roraima',
	STATE_SANTA_CATARINA: 'Santa Catarina',
	STATE_SAO_PAULO: 'São Paulo',
	STATE_SERGIPE: 'Sergipe',
	STATE_TOCANTINS: 'Tocantins',
	STATE_BRITISH_INDIAN_OCEAN_TERRITORY: 'Territoire britannique de l’océan Indien',
	STATE_BELAIT: 'Belait',
	STATE_BRUNEI_MUARA: 'Brunei-Muara',
	STATE_TEMBURONG: 'Temburong',
	STATE_TUTONG: 'Tutong',
	STATE_BLAGOEVGRAD: 'Blagoevgrad',
	STATE_BURGAS: 'Burgas',
	STATE_DOBRICH: 'Dobrich',
	STATE_GABROVO: 'Gabrovo',
	STATE_HASKOVO: 'Haskovo',
	STATE_JAMBOL: 'Yambol',
	STATE_KARDZHALI: 'Kardzhali',
	STATE_KJUSTENDIL: 'Kyustendil',
	STATE_LOVECH: 'Lovech',
	STATE_MONTANA: 'Montana',
	STATE_OBLAST_SOFIYA_GRAD: 'Oblast Sofia-ville',
	STATE_PAZARDZHIK: 'Pazardzhik',
	STATE_PERNIK: 'Pernik',
	STATE_PLEVEN: 'Pleven',
	STATE_PLOVDIV: 'Plovdiv',
	STATE_RAZGRAD: 'Razgrad',
	STATE_RUSE: 'Ruse',
	STATE_SHUMEN: 'Shumen',
	STATE_SILISTRA: 'Silistra',
	STATE_SLIVEN: 'Sliven',
	STATE_SMOLJAN: 'Smolyan',
	STATE_SOFIJA_GRAD: 'Ville de Sofia',
	STATE_SOFIJSKA_OBLAST: 'Oblast Sofia',
	STATE_STARA_ZAGORA: 'Stara Zagora',
	STATE_TARGOVISHTE: 'Targovichte',
	STATE_VARNA: 'Varna',
	STATE_VELIKO_TARNOVO: 'Veliko Tarnovo',
	STATE_VIDIN: 'Vidin',
	STATE_VRACA: 'Vratsa',
	STATE_YABLANIZA: 'Yablanitsa',
	STATE_BALE: 'Balé',
	STATE_BAM: 'Bam',
	STATE_BAZEGA: 'Bazèga',
	STATE_BOUGOURIBA: 'Bougouriba',
	STATE_BOULGOU: 'Boulgou',
	STATE_BOULKIEMDE: 'Boulkiemdé',
	STATE_COMOE: 'Comoé',
	STATE_GANZOURGOU: 'Ganzourgou',
	STATE_GNAGNA: 'Gnagna',
	STATE_GOURMA: 'Gourma',
	STATE_HOUET: 'Houet',
	STATE_IOBA: 'Ioba',
	STATE_KADIOGO: 'Kadiogo',
	STATE_KENEDOUGOU: 'Kénédougou',
	STATE_KOMANDJARI: 'Komondjari',
	STATE_KOMPIENGA: 'Kompienga',
	STATE_KOSSI: 'Kossi',
	STATE_KOURITENGA: 'Kouritenga',
	STATE_KOURWEOGO: 'Kourwéogo',
	STATE_LERABA: 'Léraba',
	STATE_MOUHOUN: 'Mouhoun',
	STATE_NAHOURI: 'Nahouri',
	STATE_NAMENTENGA: 'Namentenga',
	STATE_NOUMBIEL: 'Noumbiel',
	STATE_OUBRITENGA: 'Oubritenga',
	STATE_OUDALAN: 'Oudalan',
	STATE_PASSORE: 'Passoré',
	STATE_PONI: 'Poni',
	STATE_SANGUIE: 'Sanguié',
	STATE_SANMATENGA: 'Sanmatenga',
	STATE_SENO: 'Séno',
	STATE_SISSILI: 'Sissili',
	STATE_SOUM: 'Soum',
	STATE_SOUROU: 'Sourou',
	STATE_TAPOA: 'Tapoa',
	STATE_TUY: 'Tuy',
	STATE_YATENGA: 'Yatenga',
	STATE_ZONDOMA: 'Zondoma',
	STATE_ZOUNDWEOGO: 'Zoundwéogo',
	STATE_BUBANZA: 'Bubanza',
	STATE_BUJUMBURA: 'Bujumbura',
	STATE_BURURI: 'Bururi',
	STATE_CANKUZO: 'Cankuzo',
	STATE_CIBITOKE: 'Cibitoke',
	STATE_GITEGA: 'Gitega',
	STATE_KARUZI: 'Karuzi',
	STATE_KAYANZA: 'Kayanza',
	STATE_KIRUNDO: 'Kirundo',
	STATE_MAKAMBA: 'Makamba',
	STATE_MURAMVYA: 'Muramvya',
	STATE_MUYINGA: 'Muyinga',
	STATE_NGOZI: 'Ngozi',
	STATE_RUTANA: 'Rutana',
	STATE_RUYIGI: 'Ruyigi',
	STATE_BANTEAY_MEAN_CHEY: 'Banteay Mean Chey',
	STATE_BAT_DAMBANG: 'Baat Dambang',
	STATE_KAMPONG_CHAM: 'Kampong Chaam',
	STATE_KAMPONG_CHHNANG: 'Kampong Chhnang',
	STATE_KAMPONG_SPOEU: 'Kampong Spueu',
	STATE_KAMPONG_THUM: 'Kampong Thum',
	STATE_KAMPOT: 'Kampot',
	STATE_KANDAL: 'Kandaal',
	STATE_KAOH_KONG: 'Kaoh Kong',
	STATE_KRACHEH: 'Kracheh',
	STATE_KRONG_KAEB: 'Province Kep',
	STATE_KRONG_PAILIN: 'Province Pailin',
	STATE_KRONG_PREAH_SIHANOUK: 'Province Preah Sihanouk',
	STATE_MONDOL_KIRI: 'Mondol Kiri',
	STATE_OTDAR_MEAN_CHEY: 'Otdar Mean Chey',
	STATE_PHNUM_PENH: 'Phnom Penh',
	STATE_POUSAT: 'Pousaat',
	STATE_PREAH_VIHEAR: 'Preah Vihear',
	STATE_PREY_VEAENG: 'Prey Veaeng',
	STATE_ROTANAK_KIRI: 'Rotanak Kiri',
	STATE_SIEM_REAB: 'Siem Reab',
	STATE_STUENG_TRAENG: 'Stueng Traeng',
	STATE_SVAY_RIENG: 'Svaay Rieng',
	STATE_TAKAEV: 'Taakaev',
	STATE_ADAMAOUA: 'Adamaoua',
	STATE_CENTRE: 'Centre',
	STATE_EST: 'Est',
	STATE_NORD: 'Nord',
	STATE_NORD_EXTREME: 'Extrême-Nord',
	STATE_NORDOUEST: 'Nord-Ouest',
	STATE_OUEST: 'Ouest',
	STATE_SUD: 'Sud',
	STATE_SUDOUEST: 'Sud-Ouest',
	STATE_ALBERTA: 'Alberta',
	STATE_BRITISH_COLUMBIA: 'Colombie-Britannique',
	STATE_MANITOBA: 'Manitoba',
	STATE_NEW_BRUNSWICK: 'Nouveau-Brunswick',
	STATE_NEWFOUNDLAND_AND_LABRADOR: 'Terre-Neuve-et-Labrador',
	STATE_NORTHWEST_TERRITORIES: 'Territoires du Nord-Ouest',
	STATE_NOVA_SCOTIA: 'Nouvelle-Écosse',
	STATE_NUNAVUT: 'Nunavut',
	STATE_PRINCE_EDWARD_ISLAND: 'Île-du-Prince-Édouard',
	STATE_QUEBEC: 'Québec',
	STATE_SASKATCHEWAN: 'Saskatchewan',
	STATE_YUKON: 'Yukon',
	STATE_BOAVISTA: 'Boa Vista',
	STATE_BRAVA: 'Brava',
	STATE_FOGO: 'Fogo',
	STATE_MAIO: 'Maio',
	STATE_SAL: 'Sal',
	STATE_SANTO_ANTAO: 'Santo Antão',
	STATE_SAO_NICOLAU: 'São Nicolau',
	STATE_SAO_TIAGO: 'São Tiago',
	STATE_SAO_VICENTE: 'São Vicente',
	STATE_GRAND_CAYMAN: 'Grand Caïman',
	STATE_BAMINGUI_BANGORAN: 'Bamingui-Bangoran',
	STATE_BANGUI: 'Bangui',
	STATE_BASSE_KOTTO: 'Basse-Kotto',
	STATE_HAUT_MBOMOU: 'Haut-Mbomou',
	STATE_HAUTE_KOTTO: 'Haute-Kotto',
	STATE_KEMO: 'Kémo',
	STATE_LOBAYE: 'Lobaye',
	STATE_MAMBERE_KADEI: 'Mambéré-Kadéï',
	STATE_MBOMOU: 'Mbomou',
	STATE_NANA_GRIBIZI: 'Nana-Gribizi',
	STATE_NANA_MAMBERE: 'Nana-Mambéré',
	STATE_OMBELLA_MPOKO: 'Ombella-Mpoko',
	STATE_OUAKA: 'Ouaka',
	STATE_OUHAM: 'Ouham',
	STATE_OUHAM_PENDE: 'Ouham-Pendé',
	STATE_SANGHA_MBAERE: 'Sangha-Mbaéré',
	STATE_VAKAGA: 'Vakaga',
	STATE_BATHA: 'Batha',
	STATE_BILTINE: 'Biltine',
	STATE_BOURKOU_ENNEDI_TIBESTI: 'Bourkou-Ennedi-Tibesti',
	STATE_CHARI_BAGUIRMI: 'Chari-Baguirmi',
	STATE_GUERA: 'Guéra',
	STATE_KANEM: 'Kanem',
	STATE_LAC: 'Lac',
	STATE_LOGONE_OCCIDENTAL: 'Logone Occidental',
	STATE_LOGONE_ORIENTAL: 'Logone Oriental',
	STATE_MAYO_KEBBI: 'Mayo-Kebbi',
	STATE_MOYEN_CHARI: 'Moyen-Chari',
	STATE_OUADDAI: 'Ouaddaï',
	STATE_SALAMAT: 'Salamat',
	STATE_TANDJILE: 'Tandjilé',
	STATE_AISEN: 'Aisén',
	STATE_ANTOFAGASTA: 'Antofagasta',
	STATE_ARAUCANIA: 'La Araucanía',
	STATE_ATACAMA: 'Atacama',
	STATE_BIO_BIO: 'Biobío',
	STATE_COQUIMBO: 'Coquimbo',
	STATE_LIBERTADOR_GENERAL_BERNARDO_O: 'Libertador General Bernardo O’Higgins',
	STATE_LOS_LAGOS: 'Los Lagos',
	STATE_MAGELLANES: 'Magallanes',
	STATE_MAULE: 'Maule',
	STATE_METROPOLITANA: 'Metropolitana',
	STATE_METROPOLITANA_DE_SANTIAGO: 'Metropolitana de Santiago',
	STATE_TARAPACA: 'Tarapacá',
	STATE_VALPARAISO: 'Valparaíso',
	STATE_ANHUI: 'Anhui',
	STATE_ANHUI_PROVINCE: 'Province d’Anhui',
	STATE_ANHUI_SHENG: 'Anhui Sheng',
	STATE_AOMEN: 'Aomen',
	STATE_BEIJING: 'Beijing',
	STATE_BEIJING_SHI: 'Beijing Shi',
	STATE_CHONGQING: 'Chongqing',
	STATE_FUJIAN: 'Fujian',
	STATE_FUJIAN_SHENG: 'Fujian Sheng',
	STATE_GANSU: 'Gansu',
	STATE_GUANGDONG: 'Guangdong',
	STATE_GUANGDONG_SHENG: 'Guangdong Sheng',
	STATE_GUANGXI: 'Guangxi',
	STATE_GUIZHOU: 'Guizhou',
	STATE_HAINAN: 'Hainan',
	STATE_HEBEI: 'Hebei',
	STATE_HEILONGJIANG: 'Heilongjiang',
	STATE_HENAN: 'Henan',
	STATE_HUBEI: 'Hubei',
	STATE_HUNAN: 'Hunan',
	STATE_JIANGSU: 'Jiangsu',
	STATE_JIANGSU_SHENG: 'Jiangsu Sheng',
	STATE_JIANGXI: 'Jiangxi',
	STATE_JILIN: 'Jilin',
	STATE_LIAONING: 'Liaoning',
	STATE_LIAONING_SHENG: 'Liaoning Sheng',
	STATE_NEI_MONGGOL: 'Nei Monggol',
	STATE_NINGXIA_HUI: 'Ningxia Hui',
	STATE_QINGHAI: 'Qinghai',
	STATE_SHAANXI: 'Shaanxi',
	STATE_SHANDONG: 'Shandong',
	STATE_SHANDONG_SHENG: 'Shandong Sheng',
	STATE_SHANGHAI: 'Shanghai',
	STATE_SHANXI: 'Shanxi',
	STATE_SICHUAN: 'Sichuan',
	STATE_TIANJIN: 'Tianjin',
	STATE_XIANGGANG: 'Xianggang',
	STATE_XINJIANG: 'Xinjiang',
	STATE_XIZANG: 'Xizang',
	STATE_YUNNAN: 'Yunnan',
	STATE_ZHEJIANG: 'Zhejiang',
	STATE_ZHEJIANG_SHENG: 'Zhejiang Sheng',
	STATE_CHRISTMAS_ISLAND: 'Île Christmas',
	STATE_COCOS_KEELING_ISLANDS: 'Îles Cocos (Keeling)',
	STATE_ANTIOQUIA: 'Antioquia',
	STATE_ARAUCA: 'Arauca',
	STATE_ATLANTICO: 'Atlántico',
	STATE_BOGOTA: 'Bogotá',
	STATE_BOLIVAR: 'Bolívar',
	STATE_BOYACA: 'Boyacá',
	STATE_CALDAS: 'Caldas',
	STATE_CAQUETA: 'Caquetá',
	STATE_CASANARE: 'Casanare',
	STATE_CAUCA: 'Cauca',
	STATE_CESAR: 'Cesar',
	STATE_CHOCO: 'Chocó',
	STATE_CUNDINAMARCA: 'Cundinamarca',
	STATE_GUAINIA: 'Guainía',
	STATE_GUAVIARE: 'Guaviare',
	STATE_LA_GUAJIRA: 'La Guajira',
	STATE_MAGDALENA: 'Magdalena',
	STATE_META: 'Meta',
	STATE_NARINO: 'Nariño',
	STATE_NORTE_DE_SANTANDER: 'Norte de Santander',
	STATE_PUTUMAYO: 'Putumayo',
	STATE_QUINDIO: 'Quindío',
	STATE_RISARALDA: 'Risaralda',
	STATE_SAN_ANDRES_Y_PROVIDENCIA: 'San Andres y Providencia',
	STATE_SANTANDER: 'Santander',
	STATE_SUCRE: 'Sucre',
	STATE_TOLIMA: 'Tolima',
	STATE_VALLE_DEL_CAUCA: 'Valle del Cauca',
	STATE_VAUPES: 'Vaupés',
	STATE_VICHADA: 'Vichada',
	STATE_MWALI: 'Mohéli',
	STATE_NJAZIDJA: 'Ngazidja',
	STATE_NZWANI: 'Ndzuwani',
	STATE_BOUENZA: 'Bouenza',
	STATE_BRAZZAVILLE: 'Brazzaville',
	STATE_CUVETTE: 'Cuvette',
	STATE_KOUILOU: 'Kouilou',
	STATE_LEKOUMOU: 'Lékoumou',
	STATE_LIKOUALA: 'Likouala',
	STATE_NIARI: 'Niari',
	STATE_PLATEAUX: 'Plateaux',
	STATE_POOL: 'Pool',
	STATE_SANGHA: 'Sangha',
	STATE_BANDUNDU: 'Bandundu',
	STATE_BAS_CONGO: 'Bas-Congo',
	STATE_EQUATEUR: 'Équateur',
	STATE_HAUT_CONGO: 'Haut-Congo',
	STATE_KASAI_OCCIDENTAL: 'Kasaï-Occidental',
	STATE_KASAI_ORIENTAL: 'Kasaï-Oriental',
	STATE_KATANGA: 'Katanga',
	STATE_KINSHASA: 'Kinshasa',
	STATE_MANIEMA: 'Maniema',
	STATE_NORD_KIVU: 'Nord-Kivu',
	STATE_SUD_KIVU: 'Sud-Kivu',
	STATE_AITUTAKI: 'Aitutaki',
	STATE_ATIU: 'Atiu',
	STATE_MANGAIA: 'Mangaia',
	STATE_MANIHIKI: 'Manihiki',
	STATE_MAUKE: 'Mauke',
	STATE_MITIARO: 'Mitiaro',
	STATE_NASSAU: 'Nassau',
	STATE_PUKAPUKA: 'Pukapuka',
	STATE_RAKAHANGA: 'Rakahanga',
	STATE_RAROTONGA: 'Rarotonga',
	STATE_TONGAREVA: 'Tongareva',
	STATE_ALAJUELA: 'Alajuela',
	STATE_CARTAGO: 'Cartago',
	STATE_GUANACASTE: 'Guanacaste',
	STATE_HEREDIA: 'Heredia',
	STATE_LIMON: 'Limón',
	STATE_PUNTARENAS: 'Puntarenas',
	STATE_SAN_JOSE: 'San José',
	STATE_ABIDJAN: 'Abidjan',
	STATE_AGNEBY: 'Agnéby',
	STATE_BAFING: 'Bafing',
	STATE_DENGUELE: 'Denguélé',
	STATE_DIX_HUIT_MONTAGNES: 'Dix-huit Montagnes',
	STATE_FROMAGER: 'Fromager',
	STATE_HAUT_SASSANDRA: 'Haut-Sassandra',
	STATE_LACS: 'Lacs',
	STATE_LAGUNES: 'Lagunes',
	STATE_MARAHOUE: 'Marahoué',
	STATE_MOYEN_CAVALLY: 'Moyen-Cavally',
	STATE_MOYEN_COMOE: 'Moyen-Comoé',
	STATE_N__ZI_COMOE: 'N’zi-Comoé',
	STATE_SASSANDRA: 'Sassandra',
	STATE_SAVANES: 'Savanes',
	STATE_SUD_BANDAMA: 'Sud-Bandama',
	STATE_SUD_COMOE: 'Sud-Comoé',
	STATE_VALLEE_DU_BANDAMA: 'Vallée du Bandama',
	STATE_WORODOUGOU: 'Worodougou',
	STATE_ZANZAN: 'Zanzan',
	STATE_BJELOVAR_BILOGORA: 'Bjelovar-Bilogora',
	STATE_DUBROVNIK_NERETVA: 'Dubrovnik-Neretva',
	STATE_GRAD_ZAGREB: 'Ville de Zagreb',
	STATE_ISTRA: 'Istrie',
	STATE_KARLOVAC: 'Karlovac',
	STATE_KOPRIVNICA_KRIZHEVCI: 'Koprivnica-Križevci',
	STATE_KRAPINA_ZAGORJE: 'Krapina-Zagorje',
	STATE_LIKA_SENJ: 'Lika-Senj',
	STATE_MEDHIMURJE: 'Međimurje',
	STATE_MEDIMURSKA_ZUPANIJA: 'Međimurska Županija',
	STATE_OSIJEK_BARANJA: 'Osijek-Baranja',
	STATE_OSJECKO_BARANJSKA_ZUPANIJA: 'Osijek-Baranja Županija',
	STATE_POZHEGA_SLAVONIJA: 'Požega-Slavonie',
	STATE_PRIMORJE_GORSKI_KOTAR: 'Primorje-Gorski Kotar',
	STATE_SHIBENIK_KNIN: 'Šibenik-Knin',
	STATE_SISAK_MOSLAVINA: 'Sisak-Moslavina',
	STATE_SLAVONSKI_BROD_POSAVINA: 'Slavonski Brod-Posavina',
	STATE_SPLIT_DALMACIJA: 'Split-Dalmatie',
	STATE_VARAZHDIN: 'Varaždin',
	STATE_VIROVITICA_PODRAVINA: 'Virovitica-Podravina',
	STATE_VUKOVAR_SRIJEM: 'Vukovar-Srijem',
	STATE_ZADAR: 'Zadar',
	STATE_ZAGREB: 'Zagreb',
	STATE_CAMAGUEY: 'Camagüey',
	STATE_CIEGO_DE_AVILA: 'Ciego de Ávila',
	STATE_CIENFUEGOS: 'Cienfuegos',
	STATE_CIUDAD_DE_LA_HABANA: 'Ville de La Havane',
	STATE_GRANMA: 'Granma',
	STATE_GUANTANAMO: 'Guantánamo',
	STATE_HABANA: 'Havane',
	STATE_HOLGUIN: 'Holguin',
	STATE_ISLA_DE_LA_JUVENTUD: 'Isla de la Juventud',
	STATE_LA_HABANA: 'La Havane',
	STATE_LAS_TUNAS: 'Las Tunas',
	STATE_MATANZAS: 'Matanzas',
	STATE_PINAR_DEL_RIO: 'Pinar del Río',
	STATE_SANCTI_SPIRITUS: 'Sancti Spíritus',
	STATE_SANTIAGO_DE_CUBA: 'Santiago de Cuba',
	STATE_VILLA_CLARA: 'Villa Clara',
	STATE_GOVERNMENT_CONTROLLED_AREA: 'Zone contrôlée par le Gouvernement',
	STATE_LIMASSOL: 'Limassol',
	STATE_NICOSIA_DISTRICT: 'District de Nicosie',
	STATE_PAPHOS: 'Paphos',
	STATE_TURKISH_CONTROLLED_AREA: 'Zone contrôlée par la Turquie',
	STATE_CENTRAL_BOHEMIAN: 'Bohême centrale',
	STATE_FRYCOVICE: 'Fryčovice',
	STATE_JIHOCESKY_KRAJ: 'Région de Bohême du Sud',
	STATE_JIHOCHESKY: 'Bohême du Sud',
	STATE_JIHOMORAVSKY: 'Moravie du Sud',
	STATE_KARLOVARSKY: 'Karlovy Vary',
	STATE_KLECANY: 'Klecany',
	STATE_KRALOVEHRADECKY: 'Hradec Králové',
	STATE_LIBERECKY: 'Liberec',
	STATE_LIPOV: 'Lipov',
	STATE_MORAVSKOSLEZSKY: 'Moravie-Silésie',
	STATE_OLOMOUCKY: 'Olomouc',
	STATE_OLOMOUCKY_KRAJ: 'Région d’Olomouc',
	STATE_PARDUBICKY: 'Pardubice',
	STATE_PLZENSKY: 'Plzeň',
	STATE_PRAHA: 'Prague',
	STATE_RAJHRAD: 'Rajhrad',
	STATE_SMIRICE: 'Smiřice',
	STATE_SOUTH_MORAVIAN: 'Moravie du Sud',
	STATE_STRAZ_NAD_NISOU: 'Stráž nad Nisou',
	STATE_STREDOCHESKY: 'Bohême centrale',
	STATE_UNICOV: 'Uničov',
	STATE_USTECKY: 'Ústecký',
	STATE_VALLETTA: 'Valletta',
	STATE_VELESIN: 'Velešín',
	STATE_VYSOCHINA: 'Vysočina',
	STATE_ZLINSKY: 'Zlín',
	STATE_BORNHOLM: 'Bornholm',
	STATE_FREDERIKSBORG: 'Frederiksø',
	STATE_FYN: 'Fyn',
	STATE_HOVEDSTADEN: 'Hovedstaden',
	STATE_KOBENHAVN: 'Copenhague',
	STATE_KOBENHAVNS_AMT: 'Comté de Copenhague',
	STATE_KOBENHAVNS_KOMMUNE: 'Municipalité de Copenhague',
	STATE_NORDJYLLAND: 'Jutland du Nord',
	STATE_RIBE: 'Ribe',
	STATE_RINGKOBING: 'Ringkøbing',
	STATE_ROERVIG: 'Rørvig',
	STATE_ROSKILDE: 'Roskilde',
	STATE_ROSLEV: 'Roslev',
	STATE_SJAELLAND: 'Zélande',
	STATE_SOEBORG: 'Søborg',
	STATE_SONDERJYLLAND: 'Jutland du Sud',
	STATE_STORSTROM: 'Storstrøms',
	STATE_SYDDANMARK: 'Danemark du Sud',
	STATE_TOELLOESE: 'Tølløse',
	STATE_VEJLE: 'Vejle',
	STATE_VESTSJALLAND: 'Vestsjalland',
	STATE_VIBORG: 'Viborg',
	STATE_ALI_SABIH: 'Ali Sabieh',
	STATE_DIKHIL: 'Dikhil',
	STATE_JIBUTI: 'Djibouti',
	STATE_TAJURAH: 'Tadjourah',
	STATE_UBUK: 'Obock',
	STATE_SAINT_DAVID: 'Saint David',
	STATE_SAINT_LUKE: 'Saint Luke',
	STATE_SAINT_MARK: 'Saint Mark',
	STATE_SAINT_PATRICK: 'Saint Patrick',
	STATE_AZUA: 'Azua',
	STATE_BAHORUCO: 'Bahoruco',
	STATE_BARAHONA: 'Barahona',
	STATE_DAJABON: 'Dajabón',
	STATE_DISTRITO_NACIONAL: 'District national',
	STATE_DUARTE: 'Duarte',
	STATE_EL_SEYBO: 'El Seybo',
	STATE_ELIAS_PINA: 'Elías Piña',
	STATE_ESPAILLAT: 'Espaillat',
	STATE_HATO_MAYOR: 'Hato Mayor',
	STATE_INDEPENDENCIA: 'Independencia',
	STATE_LA_ALTAGRACIA: 'La Altagracia',
	STATE_LA_ROMANA: 'La Romana',
	STATE_LA_VEGA: 'La Vega',
	STATE_MARIA_TRINIDAD_SANCHEZ: 'María Trinidad Sánchez',
	STATE_MONSENOR_NOUEL: 'Monsenor Nouel',
	STATE_MONTE_CRISTI: 'Monte Cristi',
	STATE_MONTE_PLATA: 'Monte Plata',
	STATE_PEDERNALES: 'Pedernales',
	STATE_PERAVIA: 'Peravia',
	STATE_PUERTO_PLATA: 'Puerto Plata',
	STATE_SALCEDO: 'Salcedo',
	STATE_SAMANA: 'Samaná',
	STATE_SAN_CRISTOBAL: 'San Cristóbal',
	STATE_SAN_PEDRO_DE_MACORIS: 'San Pedro de Macorís',
	STATE_SANCHEZ_RAMIREZ: 'Sánchez Ramírez',
	STATE_SANTIAGO: 'Santiago',
	STATE_SANTIAGO_RODRIGUEZ: 'Santiago Rodríguez',
	STATE_VALVERDE: 'Valverde',
	STATE_AILEU: 'Aileu',
	STATE_AINARO: 'Ainaro',
	STATE_AMBENO: 'Ambeno',
	STATE_BAUCAU: 'Baucau',
	STATE_BOBONARO: 'Bobonaro',
	STATE_COVA_LIMA: 'Cova Lima',
	STATE_DILI: 'Dili',
	STATE_ERMERA: 'Ermera',
	STATE_LAUTEM: 'Lautém',
	STATE_LIQUICA: 'Liquiçá',
	STATE_MANATUTO: 'Manatuto',
	STATE_MANUFAHI: 'Manufahi',
	STATE_VIQUEQUE: 'Viqueque',
	STATE_AZUAY: 'Azuay',
	STATE_CANAR: 'Cañar',
	STATE_CARCHI: 'Carchi',
	STATE_CHIMBORAZO: 'Chimborazo',
	STATE_COTOPAXI: 'Cotopaxi',
	STATE_EL_ORO: 'El Oro',
	STATE_ESMERALDAS: 'Esmeraldas',
	STATE_GALAPAGOS: 'Galápagos',
	STATE_GUAYAS: 'Guayas',
	STATE_IMBABURA: 'Imbabura',
	STATE_LOJA: 'Loja',
	STATE_LOS_RIOS: 'Los Ríos',
	STATE_MANABI: 'Manabí',
	STATE_MORONA_SANTIAGO: 'Morona Santiago',
	STATE_NAPO: 'Napo',
	STATE_ORELLANA: 'Orellana',
	STATE_PASTAZA: 'Pastaza',
	STATE_PICHINCHA: 'Pichincha',
	STATE_SUCUMBIOS: 'Sucumbíos',
	STATE_TUNGURAHUA: 'Tungurahua',
	STATE_ZAMORA_CHINCHIPE: 'Zamora Chinchipe',
	STATE_ASWAN: 'Assouan',
	STATE_ASYUT: 'Assiout',
	STATE_BANI_SUWAYF: 'Beni Souef',
	STATE_BUR_SA__ID: 'Port-Saïd',
	STATE_CAIRO: 'Le Caire',
	STATE_DUMYAT: 'Damiette',
	STATE_KAFR_ASH_SHAYKH: 'Kafr el-Cheik',
	STATE_MATRUH: 'Matruh',
	STATE_MUHAFAZAT_AD_DAQAHLIYAH: 'Dakhleya',
	STATE_MUHAFAZAT_AL_FAYYUM: 'Gouvernorat de Fayoum',
	STATE_MUHAFAZAT_AL_GHARBIYAH: 'Gouvernorat de Gharbeya',
	STATE_MUHAFAZAT_AL_ISKANDARIYAH: 'Gouvernorat d’Alexandrie',
	STATE_MUHAFAZAT_AL_QAHIRAH: 'Gouvernorat du Caire',
	STATE_QINA: 'Qena',
	STATE_SAWHAJ: 'Sohag',
	STATE_SINA_AL_JANUBIYAH: 'Sinaï Sud',
	STATE_SINA_ASH_SHAMALIYAH: 'Sinaï Nord',
	STATE_AD_DAQAHLIYAH: 'Dakhleya',
	STATE_AL_BAHR_AL_AHMAR: 'Mer Rouge',
	STATE_AL_BUHAYRAH: 'Beheira',
	STATE_AL_FAYYUM: 'Fayoum',
	STATE_AL_GHARBIYAH: 'Gharbeya',
	STATE_AL_ISKANDARIYAH: 'Alexandrie',
	STATE_AL_ISMAILIYAH: 'Ismaïlia',
	STATE_AL_JIZAH: 'Gizeh',
	STATE_AL_MINUFIYAH: 'Menufeya',
	STATE_AL_MINYA: 'Minya',
	STATE_AL_QAHIRA: 'Le Caire',
	STATE_AL_QALYUBIYAH: 'Qalyubiya',
	STATE_AL_UQSUR: 'Louxor',
	STATE_AL_WADI_AL_JADID: 'Nouvelle-Vallée',
	STATE_AS_SUWAYS: 'Suez',
	STATE_ASH_SHARQIYAH: 'Ach-Charqiya',
	STATE_AHUACHAPAN: 'Ahuachapán',
	STATE_CABANAS: 'Cabañas',
	STATE_CHALATENANGO: 'Chalatenango',
	STATE_CUSCATLAN: 'Cuscatlán',
	STATE_LA_LIBERTAD: 'La Libertad',
	STATE_LA_UNION: 'La Unión',
	STATE_MORAZAN: 'Morazán',
	STATE_SAN_MIGUEL: 'San Miguel',
	STATE_SAN_VICENTE: 'San Vicente',
	STATE_SANTA_ANA: 'Santa Ana',
	STATE_SONSONATE: 'Sonsonate',
	STATE_USULUTAN: 'Usulután',
	STATE_ANNOBON: 'Annobon',
	STATE_BIOKO_NORTE: 'Bioko Nord',
	STATE_BIOKO_SUR: 'Bioko Sud',
	STATE_CENTRO_SUR: 'Centro Sud',
	STATE_KIE_NTEM: 'Kié-Ntem',
	STATE_LITORAL: 'Littoral',
	STATE_WELE_NZAS: 'Wele-Nzas',
	STATE_ANSEBA: 'Anseba',
	STATE_DEBUB: 'Debub',
	STATE_DEBUB_KEIH_BAHRI: 'Debub-Keih-Bahri',
	STATE_GASH_BARKA: 'Gash-Barka',
	STATE_MAEKEL: 'Maekel',
	STATE_SEMIEN_KEIH_BAHRI: 'Semien-Keih-Bahri',
	STATE_HARJU: 'Harju',
	STATE_HIIU: 'Hiiu',
	STATE_IDA_VIRU: 'Viru-Est',
	STATE_JARVA: 'Järva',
	STATE_JOGEVA: 'Jõgeva',
	STATE_LAANE: 'Lääne',
	STATE_LAANE_VIRU: 'Viru-Ouest',
	STATE_PARNU: 'Pärnu',
	STATE_POLVA: 'Põlva',
	STATE_RAPLA: 'Pärnu',
	STATE_SAARE: 'Saare',
	STATE_TARTU: 'Tartu',
	STATE_VALGA: 'Valga',
	STATE_VILJANDI: 'Viljandi',
	STATE_VORU: 'Võru',
	STATE_ADDIS_ABEBA: 'Addis Abeba',
	STATE_AFAR: 'Afar',
	STATE_AMHARA: 'Amhara',
	STATE_BENISHANGUL: 'Benishangul',
	STATE_DIREDAWA: 'Dire Dawa',
	STATE_GAMBELLA: 'Gambella',
	STATE_HARAR: 'Harar',
	STATE_JIGJIGA: 'Jigjiga',
	STATE_MEKELE: 'Mekele',
	STATE_OROMIA: 'Oromia',
	STATE_SOMALI: 'Somali',
	STATE_SOUTHERN: 'Région des nations, nationalités et peuples du Sud',
	STATE_TIGRAY: 'Tigré',
	STATE_FALKLAND_ISLANDS: 'Îles Malouines',
	STATE_SOUTH_GEORGIA: 'Géorgie du Sud',
	STATE_KLAKSVIK: 'Klaksvík',
	STATE_NOR_ARA_EYSTUROY: 'Nor ara Eysturoy',
	STATE_NOR_OY: 'Nor oy',
	STATE_SANDOY: 'Sandoy',
	STATE_STREYMOY: 'Streymoy',
	STATE_SU_UROY: 'Su uroy',
	STATE_SY_RA_EYSTUROY: 'Sy ra Eysturoy',
	STATE_TORSHAVN: 'Torshavn',
	STATE_VAGA: 'Vaga',
	STATE_CENTRAL: 'central',
	STATE_NORTHERN: 'du Nord',
	STATE_SOUTH_PACIFIC: 'Pacifique Sud',
	STATE_AHVENANMAA: 'Åland',
	STATE_ETELA_KARJALA: 'Carélie du Sud',
	STATE_ETELA_POHJANMAA: 'Ostrobotnie du Sud',
	STATE_ETELA_SAVO: 'Savonie du Sud',
	STATE_ETELA_SUOMEN_LAANI: 'Finlande du Sud',
	STATE_ITA_SUOMEN_LAANI: 'Finlande orientale',
	STATE_ITA_UUSIMAA: 'Uusimaa orientale',
	STATE_KAINUU: 'Kainuu',
	STATE_KANTA_HAME: 'Kanta-Häme',
	STATE_KESKI_POHJANMAA: 'Ostrobotnie centrale',
	STATE_KESKI_SUOMI: 'Finlande centrale',
	STATE_KYMENLAAKSO: 'Vallée de la Kymi',
	STATE_LANSI_SUOMEN_LAANI: 'Finlande occidentale',
	STATE_LAPPI: 'Laponie',
	STATE_NORTHERN_SAVONIA: 'Savonie du Nord',
	STATE_OSTROBOTHNIA: 'Ostrobotnie',
	STATE_OULUN_LAANI: 'Province d’Oulu',
	STATE_PAIJAT_HAME: 'Päijät-Häme',
	STATE_PIRKANMAA: 'Pirkanmaa',
	STATE_POHJANMAA: 'Ostrobotnie',
	STATE_POHJOIS_KARJALA: 'Carélie du Nord',
	STATE_POHJOIS_POHJANMAA: 'Ostrobotnie du Nord',
	STATE_POHJOIS_SAVO: 'Savonie du Nord',
	STATE_SAARIJARVI: 'Saarijärvi',
	STATE_SATAKUNTA: 'Satakunta',
	STATE_SOUTHERN_SAVONIA: 'Savonie du Sud',
	STATE_TAVASTIA_PROPER: 'Kanta-Häme',
	STATE_ULEABORGS_LAN: 'Province d’Oulu',
	STATE_UUSIMAA: 'Uusimaa',
	STATE_VARSINAIS_SUOMI: 'Finlande du Sud-Ouest',
	STATE_AIN: 'Ain',
	STATE_AISNE: 'Aisne',
	STATE_ALBI_LE_SEQUESTRE: 'Albi-Le Séquestre',
	STATE_ALLIER: 'Allier',
	STATE_ALPES_COTE_DAZUR: 'Alpes-Côte d’Azur',
	STATE_ALPES_MARITIMES: 'Alpes-Maritimes',
	STATE_ALPES_DE_HAUTE_PROVENCE: 'Alpes-de-Haute-Provence',
	STATE_ALSACE: 'Alsace',
	STATE_AQUITAINE: 'Aquitaine',
	STATE_ARDECHE: 'Ardèche',
	STATE_ARDENNES: 'Ardennes',
	STATE_ARIEGE: 'Ariège',
	STATE_AUBE: 'Aube',
	STATE_AUDE: 'Aude',
	STATE_AUVERGNE: 'Auvergne',
	STATE_AVEYRON: 'Aveyron',
	STATE_BAS_RHIN: 'Bas-Rhin',
	STATE_BASSE_NORMANDIE: 'Basse-Normandie',
	STATE_BOUCHES_DU_RHONE: 'Bouches-du-Rhône',
	STATE_BOURGOGNE: 'Bourgogne',
	STATE_BRETAGNE: 'Bretagne',
	STATE_BRITTANY: 'Bretagne',
	STATE_BURGUNDY: 'Bourgogne',
	STATE_CALVADOS: 'Calvados',
	STATE_CANTAL: 'Cantal',
	STATE_CEDEX: 'Cedex',
	STATE_CHARENTE: 'Charente',
	STATE_CHARENTE_MARITIME: 'Charente-Maritime',
	STATE_CHER: 'Cher',
	STATE_CORREZE: 'Corrèze',
	STATE_CORSE_DU_SUD: 'Corse-du-Sud',
	STATE_COTE_D__OR: 'Côte-d’Or',
	STATE_COTES_D__ARMOR: 'Côtes-d’Armor',
	STATE_CREUSE: 'Creuse',
	STATE_CROLLES: 'Crolles',
	STATE_DEUX_SEVRES: 'Deux-Sèvres',
	STATE_DORDOGNE: 'Dordogne',
	STATE_DOUBS: 'Doubs',
	STATE_DROME: 'Drôme',
	STATE_ESSONNE: 'Essonne',
	STATE_EURE: 'Eure',
	STATE_EURE_ET_LOIR: 'Eure-et-Loir',
	STATE_FEUCHEROLLES: 'Feucherolles',
	STATE_FINISTERE: 'Finistère',
	STATE_FRANCHE_COMTE: 'Franche-Comté',
	STATE_GARD: 'Gard',
	STATE_GERS: 'Gers',
	STATE_GIRONDE: 'Gironde',
	STATE_HAUT_RHIN: 'Haut-Rhin',
	STATE_HAUTE_CORSE: 'Haute-Corse',
	STATE_HAUTE_GARONNE: 'Haute-Garonne',
	STATE_HAUTE_LOIRE: 'Haute-Loire',
	STATE_HAUTE_MARNE: 'Haute-Marne',
	STATE_HAUTE_SAONE: 'Haute-Saône',
	STATE_HAUTE_SAVOIE: 'Haute-Savoie',
	STATE_HAUTE_VIENNE: 'Haute-Vienne',
	STATE_HAUTES_ALPES: 'Hautes-Alpes',
	STATE_HAUTES_PYRENEES: 'Hautes-Pyrénées',
	STATE_HAUTS_DE_SEINE: 'Hauts-de-Seine',
	STATE_HERAULT: 'Hérault',
	STATE_ILE_DE_FRANCE: 'Île-de-France',
	STATE_ILLE_ET_VILAINE: 'Ille-et-Vilaine',
	STATE_INDRE: 'Indre',
	STATE_INDRE_ET_LOIRE: 'Indre-et-Loire',
	STATE_ISERE: 'Isère',
	STATE_JURA: 'Jura',
	STATE_KLAGENFURT: 'Klagenfurt',
	STATE_LANDES: 'Landes',
	STATE_LANGUEDOC_ROUSSILLON: 'Languedoc-Roussillon',
	STATE_LARCAY: 'Larçay',
	STATE_LE_CASTELLET: 'Le Castellet',
	STATE_LE_CREUSOT: 'Le Creusot',
	STATE_LIMOUSIN: 'Limousin',
	STATE_LOIR_ET_CHER: 'Loir-et-Cher',
	STATE_LOIRE: 'Loire',
	STATE_LOIRE_ATLANTIQUE: 'Loire-Atlantique',
	STATE_LOIRET: 'Loiret',
	STATE_LORRAINE: 'Lorraine',
	STATE_LOT: 'Lot',
	STATE_LOT_ET_GARONNE: 'Lot-et-Garonne',
	STATE_LOWER_NORMANDY: 'Basse-Normandie',
	STATE_LOZERE: 'Lozère',
	STATE_MAINE_ET_LOIRE: 'Maine-et-Loire',
	STATE_MANCHE: 'Manche',
	STATE_MARNE: 'Marne',
	STATE_MAYENNE: 'Mayenne',
	STATE_MEURTHE_ET_MOSELLE: 'Meurthe-et-Moselle',
	STATE_MEUSE: 'Meuse',
	STATE_MIDI_PYRENEES: 'Midi-Pyrénées',
	STATE_MORBIHAN: 'Morbihan',
	STATE_MOSELLE: 'Moselle',
	STATE_NIEVRE: 'Nièvre',
	STATE_NORD_PAS_DE_CALAIS: 'Nord-Pas-de-Calais',
	STATE_OISE: 'Oise',
	STATE_ORNE: 'Orne',
	STATE_PARIS: 'Paris',
	STATE_PAS_DE_CALAIS: 'Pas-de-Calais',
	STATE_PAYS_DE_LA_LOIRE: 'Pays de la Loire',
	STATE_PICARDY: 'Picardie',
	STATE_PUY_DE_DOME: 'Puy-de-Dôme',
	STATE_PYRENEES_ATLANTIQUES: 'Pyrénées-Atlantiques',
	STATE_PYRENEES_ORIENTALES: 'Pyrénées-Orientales',
	STATE_QUELMES: 'Quelmes',
	STATE_RHONE: 'Rhône',
	STATE_RHONE_ALPES: 'Rhône-Alpes',
	STATE_SAINT_OUEN: 'Saint-Ouen',
	STATE_SAINT_VIATRE: 'Saint-Viâtre',
	STATE_SAONE_ET_LOIRE: 'Saône-et-Loire',
	STATE_SARTHE: 'Sarthe',
	STATE_SAVOIE: 'Savoie',
	STATE_SEINE_MARITIME: 'Seine-Maritime',
	STATE_SEINE_SAINT_DENIS: 'Seine-Saint-Denis',
	STATE_SEINE_ET_MARNE: 'Seine-et-Marne',
	STATE_SOMME: 'Somme',
	STATE_SOPHIA_ANTIPOLIS: 'Sophia Antipolis',
	STATE_SOUVANS: 'Souvans',
	STATE_TARN: 'Tarn',
	STATE_TARN_ET_GARONNE: 'Tarn-et-Garonne',
	STATE_TERRITOIRE_DE_BELFORT: 'Territoire de Belfort',
	STATE_TREIGNAC: 'Treignac',
	STATE_UPPER_NORMANDY: 'Haute-Normandie',
	STATE_VAL_D__OISE: 'Val-d’Oise',
	STATE_VAL_DE_MARNE: 'Val-de-Marne',
	STATE_VAR: 'Var',
	STATE_VAUCLUSE: 'Vaucluse',
	STATE_VELLISE: 'Viesly',
	STATE_VENDEE: 'Vendée',
	STATE_VIENNE: 'Vienne',
	STATE_VOSGES: 'Vosges',
	STATE_YONNE: 'Yonne',
	STATE_YVELINES: 'Yvelines',
	STATE_CAYENNE: 'Cayenne',
	STATE_SAINT_LAURENT_DU_MARONI: 'Saint-Laurent-du-Maroni',
	STATE_ILES_DU_VENT: 'Îles du Vent',
	STATE_ILES_SOUS_LE_VENT: 'Îles Sous-le-Vent',
	STATE_MARQUESAS: 'Marquises',
	STATE_TUAMOTU: 'Tuamotu',
	STATE_TUBUAI: 'Tubuaï',
	STATE_AMSTERDAM: 'Amsterdam',
	STATE_CROZET_ISLANDS: 'Îles Crozet',
	STATE_KERGUELEN: 'Kerguelen',
	STATE_ESTUAIRE: 'Estuaire',
	STATE_HAUT_OGOOUE: 'Haut-Ogooué',
	STATE_MOYEN_OGOOUE: 'Moyen-Ogooué',
	STATE_NGOUNIE: 'Ngounié',
	STATE_NYANGA: 'Nyanga',
	STATE_OGOOUE_IVINDO: 'Ogooué-Ivindo',
	STATE_OGOOUE_LOLO: 'Ogooué-Lolo',
	STATE_OGOOUE_MARITIME: 'Ogooué-Maritime',
	STATE_WOLEU_NTEM: 'Woleu-Ntem',
	STATE_BANJUL: 'Banjul',
	STATE_BASSE: 'Basse',
	STATE_BRIKAMA: 'Brikama',
	STATE_JANJANBUREH: 'Janjanbureh',
	STATE_KANIFING: 'Kanifing',
	STATE_KEREWAN: 'Kerewan',
	STATE_KUNTAUR: 'Kuntaur',
	STATE_MANSAKONKO: 'Mansakonko',
	STATE_ABHASIA: 'Abkhazie',
	STATE_AJARIA: 'Adjarie',
	STATE_GURIA: 'Gourie',
	STATE_IMERETI: 'Iméréthie',
	STATE_KAHETI: 'Karthlie',
	STATE_KVEMO_KARTLI: 'Karthlie inférieure',
	STATE_MCHETA_MTIANETI: 'Mtskheta-Mtianeti',
	STATE_RACHA: 'Ratcha',
	STATE_SAMAGRELO_ZEMO_SVANETI: 'Mingrélie-Haute Svanétie',
	STATE_SAMCHE_ZHAVAHETI: 'Samtskhé-Djavakhétie',
	STATE_SHIDA_KARTLI: 'Karthlie intérieure',
	STATE_TBILISI: 'Tbilissi',
	STATE_BADEN_WURTTEMBERG: 'Bade-Wurtemberg',
	STATE_BAVARIA: 'Bavière',
	STATE_BAYERN: 'Bayern',
	STATE_BEILSTEIN_WURTT: 'Beilstein Wurtt',
	STATE_BERLIN: 'Berlin',
	STATE_BRANDENBURG: 'Brandebourg',
	STATE_BREMEN: 'Brême',
	STATE_DREISBACH: 'Dreisbach',
	STATE_FREISTAAT_BAYERN: 'État libre de Bavière',
	STATE_HAMBURG: 'Hambourg',
	STATE_HANNOVER: 'Hanovre',
	STATE_HEROLDSTATT: 'Heroldstatt',
	STATE_HESSEN: 'Hesse',
	STATE_KORTENBERG: 'Kortenberg',
	STATE_LAASDORF: 'Laasdorf',
	STATE_LAND_BADEN_WURTTEMBERG: 'Land de Bade-Wurtemberg',
	STATE_LAND_BAYERN: 'Land de Bayern',
	STATE_LAND_BRANDENBURG: 'Land de Brandebourg',
	STATE_LAND_HESSEN: 'Land de Hesse',
	STATE_LAND_MECKLENBURG_VORPOMMERN: 'Land de Mecklembourg-Poméranie-Occidentale',
	STATE_LAND_NORDRHEIN_WESTFALEN: 'Land de Rhénanie-du-Nord-Westphalie',
	STATE_LAND_RHEINLAND_PFALZ: 'Land de Rhénanie-Palatinat',
	STATE_LAND_SACHSEN: 'Land de Saxe',
	STATE_LAND_SACHSEN_ANHALT: 'Land de Saxe-Anhalt',
	STATE_LAND_THURINGEN: 'Land de Thuringe',
	STATE_LOWER_SAXONY: 'Basse-Saxe',
	STATE_MECKLENBURG_VORPOMMERN: 'Mecklembourg-Poméranie-Occidentale',
	STATE_MULFINGEN: 'Mulfingen',
	STATE_MUNICH: 'Munich',
	STATE_NEUBEUERN: 'Neubeuern',
	STATE_NIEDERSACHSEN: 'Basse-Saxe',
	STATE_NOORD_HOLLAND: 'Hollande-Septentrionale',
	STATE_NORDRHEIN_WESTFALEN: 'Rhénanie-du-Nord-Westphalie',
	STATE_NORTH_RHINE_WESTPHALIA: 'Rhénanie-du-Nord-Westphalie',
	STATE_OSTERODE: 'Osterode',
	STATE_RHEINLAND_PFALZ: 'Rhénanie-Palatinat',
	STATE_RHINELAND_PALATINATE: 'Rhénanie-Palatinat',
	STATE_SAARLAND: 'Sarre',
	STATE_SACHSEN: 'Saxe',
	STATE_SACHSEN_ANHALT: 'Saxe-Anhalt',
	STATE_SAXONY: 'Saxe',
	STATE_THURINGIA: 'Thuringe',
	STATE_WEBLING: 'Webling',
	STATE_WEINSTRABE: 'Weinstrasse',
	STATE_SCHLOBBORN: 'Schlossborn',
	STATE_ASHANTI: 'Achanti',
	STATE_BRONG_AHAFO: 'Brong-Ahafo',
	STATE_GREATER_ACCRA: 'Banlieue d’Accra',
	STATE_UPPER_EAST: 'Nord-Est',
	STATE_UPPER_WEST: 'Nord-Ouest',
	STATE_VOLTA: 'Volta',
	STATE_GIBRALTAR: 'Gibraltar',
	STATE_ACHARNES: 'Acharnes',
	STATE_AHAIA: 'Achaïe',
	STATE_AITOLIA_KAI_AKARNANIA: 'Étolie-Acarnanie',
	STATE_ARGOLIS: 'Argolide',
	STATE_ARKADIA: 'Arcadie',
	STATE_ARTA: 'Arta',
	STATE_ATTICA: 'Attique',
	STATE_ATTIKI: 'Attiki',
	STATE_AYION_OROS: 'Mont Athos',
	STATE_CRETE: 'Crète',
	STATE_DODEKANISOS: 'Dodécanèse',
	STATE_DRAMA: 'Drama',
	STATE_EVIA: 'Eubée',
	STATE_EVRITANIA: 'Eurytanie',
	STATE_EVROS: 'Évros',
	STATE_EVVOIA: 'Eubée',
	STATE_FLORINA: 'Florina',
	STATE_FOKIS: 'Phocide',
	STATE_FTHIOTIS: 'Phthiotide',
	STATE_GREVENA: 'Grevena',
	STATE_HALANDRI: 'Chalandri',
	STATE_HALKIDIKI: 'Chalcidique',
	STATE_HANIA: 'La Canée',
	STATE_HERAKLION: 'Héraklion',
	STATE_HIOS: 'Chios',
	STATE_ILIA: 'Ilia',
	STATE_IMATHIA: 'Imathie',
	STATE_IOANNINA: 'Ioannina',
	STATE_IRAKLION: 'Héraklion',
	STATE_KARDITSA: 'Karditsa',
	STATE_KASTORIA: 'Kastoria',
	STATE_KAVALA: 'Kavála',
	STATE_KEFALLINIA: 'Céphalonie',
	STATE_KERKIRA: 'Corfou',
	STATE_KIKLADES: 'Cyclades',
	STATE_KILKIS: 'Kilkís',
	STATE_KORINTHIA: 'Corinthie',
	STATE_KOZANI: 'Kozáni',
	STATE_LAKONIA: 'Laconie',
	STATE_LARISA: 'Larissa',
	STATE_LASITHI: 'Lasithi',
	STATE_LESVOS: 'Lesbos',
	STATE_LEVKAS: 'Leucade',
	STATE_MAGNISIA: 'Magnisía',
	STATE_MESSINIA: 'Messénie',
	STATE_NOMOS_ATTIKIS: 'Nome d’Attique',
	STATE_NOMOS_ZAKYNTHOU: 'Nome de Zakynthos',
	STATE_PELLA: 'Pélla',
	STATE_PIERIA: 'Piérie',
	STATE_PIRAIOS: 'Le Pirée',
	STATE_PREVEZA: 'Preveza',
	STATE_RETHIMNI: 'Réthymnon',
	STATE_RODOPI: 'Rhodope',
	STATE_SAMOS: 'Samos',
	STATE_SERRAI: 'Serrès',
	STATE_THESPROTIA: 'Thesprotie',
	STATE_THESSALONIKI: 'Thessalonique',
	STATE_TRIKALA: 'Trikala',
	STATE_VOIOTIA: 'Béotie',
	STATE_WEST_GREECE: 'Attique de l’Ouest',
	STATE_XANTHI: 'Xanthi',
	STATE_ZAKINTHOS: 'Zakynthos',
	STATE_AASIAAT: 'Aasiaat',
	STATE_AMMASSALIK: 'Tasiilaq',
	STATE_ILLOQQORTOORMIUT: 'Illoqqortoormiut',
	STATE_ILULISSAT: 'Ilulissat',
	STATE_IVITTUUT: 'Ivittuut',
	STATE_KANGAATSIAQ: 'Kangaatsiaq',
	STATE_MANIITSOQ: 'Maniitsoq',
	STATE_NANORTALIK: 'Nanortalik',
	STATE_NARSAQ: 'Narsaq',
	STATE_NUUK: 'Nuuk',
	STATE_PAAMIUT: 'Paamiut',
	STATE_QAANAAQ: 'Qaanaaq',
	STATE_QAQORTOQ: 'Qaqortoq',
	STATE_QASIGIANNGUIT: 'Qasigiannguit',
	STATE_QEQERTARSUAQ: 'Qeqertarsuaq',
	STATE_SISIMIUT: 'Sisimiut',
	STATE_UDENFOR_KOMMUNAL_INDDELING: 'En-dehors des municipalités',
	STATE_UPERNAVIK: 'Upernavik',
	STATE_UUMMANNAQ: 'Uummannaq',
	STATE_CARRIACOU_PETITE_MARTINIQUE: 'Carriacou-Petite Martinique',
	STATE_SAINT_DAVIDS: 'Saint David',
	STATE_SAINT_GEORGE__S: 'Saint George',
	STATE_BASSE_TERRE: 'Basse-Terre',
	STATE_GRANDE_TERRE: 'Grande-Terre',
	STATE_ILES_DES_SAINTES: 'Îles des Saintes',
	STATE_LA_DESIRADE: 'La Désirade',
	STATE_MARIE_GALANTE: 'Marie-Galante',
	STATE_SAINT_BARTHELEMY: 'Saint-Barthélemy',
	STATE_SAINT_MARTIN: 'Saint-Martin',
	STATE_AGANA_HEIGHTS: 'Agana Heights',
	STATE_AGAT: 'Agat',
	STATE_BARRIGADA: 'Barrigada',
	STATE_CHALAN_PAGO_ORDOT: 'Chalan-Pago-Ordot',
	STATE_DEDEDO: 'Dededo',
	STATE_HAGATNA: 'Hagåtña',
	STATE_INARAJAN: 'Inarajan',
	STATE_MANGILAO: 'Mangilao',
	STATE_MERIZO: 'Merizo',
	STATE_MONGMONG_TOTO_MAITE: 'Mongmong-Toto-Maite',
	STATE_SANTA_RITA: 'Santa Rita',
	STATE_SINAJANA: 'Sinajana',
	STATE_TALOFOFO: 'Talofofo',
	STATE_TAMUNING: 'Tamuning',
	STATE_YIGO: 'Yigo',
	STATE_YONA: 'Yona',
	STATE_ALTA_VERAPAZ: 'Alta Verapaz',
	STATE_BAJA_VERAPAZ: 'Baja Verapaz',
	STATE_CHIMALTENANGO: 'Chimaltenango',
	STATE_CHIQUIMULA: 'Chiquimula',
	STATE_EL_PROGRESO: 'El Progreso',
	STATE_ESCUINTLA: 'Escuintla',
	STATE_GUATEMALA: 'Guatemala',
	STATE_HUEHUETENANGO: 'Huehuetenango',
	STATE_IZABAL: 'Izabal',
	STATE_JALAPA: 'Jalapa',
	STATE_JUTIAPA: 'Jutiapa',
	STATE_PETEN: 'Petén',
	STATE_QUEZALTENANGO: 'Quetzaltenango',
	STATE_QUICHE: 'Quiché',
	STATE_RETALHULEU: 'Retalhuleu',
	STATE_SACATEPEQUEZ: 'Sacatepéquez',
	STATE_SAN_MARCOS: 'San Marcos',
	STATE_SANTA_ROSA: 'Santa Rosa',
	STATE_SOLOLA: 'Sololá',
	STATE_SUCHITEPEQUEZ: 'Suchitepéquez',
	STATE_TOTONICAPAN: 'Totonicapán',
	STATE_ZACAPA: 'Zacapa',
	STATE_ALDERNEY: 'Aurigny',
	STATE_CASTEL: 'Sainte-Marie-du-Câtel',
	STATE_FOREST: 'La Forêt',
	STATE_SAINT_PETER_PORT: 'Saint-Pierre-Port',
	STATE_SAINT_PIERRE_DU_BOIS: 'Saint-Pierre-du-Bois',
	STATE_SAINT_SAMPSON: 'Saint-Samson',
	STATE_SAINT_SAVIOUR: 'Saint-Sauveur',
	STATE_SARK: 'Sercq',
	STATE_TORTEVAL: 'Torteval',
	STATE_VALE: 'Le Valle',
	STATE_BEYLA: 'Beyla',
	STATE_BOFFA: 'Boffa',
	STATE_BOKE: 'Boké',
	STATE_CONAKRY: 'Conakry',
	STATE_COYAH: 'Coyah',
	STATE_DABOLA: 'Dabola',
	STATE_DALABA: 'Dalaba',
	STATE_DINGUIRAYE: 'Dinguiraye',
	STATE_FARANAH: 'Faranah',
	STATE_FORECARIAH: 'Forécariah',
	STATE_FRIA: 'Fria',
	STATE_GAOUAL: 'Gaoual',
	STATE_GUECKEDOU: 'Gueckedou',
	STATE_KANKAN: 'Kankan',
	STATE_KEROUANE: 'Kérouané',
	STATE_KINDIA: 'Kindia',
	STATE_KISSIDOUGOU: 'Kissidougou',
	STATE_KOUBIA: 'Koubia',
	STATE_KOUNDARA: 'Koundara',
	STATE_KOUROUSSA: 'Kouroussa',
	STATE_LABE: 'Labé',
	STATE_LOLA: 'Lola',
	STATE_MACENTA: 'Macenta',
	STATE_MALI: 'Mali',
	STATE_MAMOU: 'Mamou',
	STATE_MANDIANA: 'Mandiana',
	STATE_NZEREKORE: 'N’Zérékoré',
	STATE_PITA: 'Pita',
	STATE_SIGUIRI: 'Siguiri',
	STATE_TELIMELE: 'Télimélé',
	STATE_TOUGUE: 'Tougué',
	STATE_YOMOU: 'Yomou',
	STATE_BAFATA: 'Bafatá',
	STATE_BISSAU: 'Bissau',
	STATE_BOLAMA: 'Bolama',
	STATE_CACHEU: 'Cacheu',
	STATE_GABU: 'Gabú',
	STATE_OIO: 'Oio',
	STATE_QUINARA: 'Quinara',
	STATE_TOMBALI: 'Tombali',
	STATE_BARIMA_WAINI: 'Barima-Waini',
	STATE_CUYUNI_MAZARUNI: 'Cuyuni-Mazaruni',
	STATE_DEMERARA_MAHAICA: 'Demerara-Mahaica',
	STATE_EAST_BERBICE_CORENTYNE: 'Berbice Oriental-Courantyne',
	STATE_ESSEQUIBO_ISLANDS_WEST_DEMERAR: 'Îles d’Essequibo-Demerara occidental',
	STATE_MAHAICA_BERBICE: 'Mahaica-Berbice',
	STATE_POMEROON_SUPENAAM: 'Pomeroon-Supenaam',
	STATE_POTARO_SIPARUNI: 'Potaro-Siparuni',
	STATE_UPPER_DEMERARA_BERBICE: 'Haut-Demerara-Berbice',
	STATE_UPPER_TAKUTU_UPPER_ESSEQUIBO: 'Haut-Takutu-Haut-Essequibo',
	STATE_ARTIBONITE: 'Artibonite',
	STATE_GRAND__ANSE: 'Grand’Anse',
	STATE_NORD_EST: 'Nord-Est',
	STATE_NORD_OUEST: 'Nord-Ouest',
	STATE_SUD_EST: 'Sud-Est',
	STATE_HEARD_AND_MCDONALD_ISLANDS: 'Îles Heard et McDonald',
	STATE_ATLANTIDA: 'Atlántida',
	STATE_CHOLUTECA: 'Choluteca',
	STATE_COLON: 'Colón',
	STATE_COMAYAGUA: 'Comayagua',
	STATE_COPAN: 'Copán',
	STATE_CORTES: 'Cortés',
	STATE_DISTRITO_CENTRAL: 'District central',
	STATE_EL_PARAISO: 'El Paraíso',
	STATE_FRANCISCO_MORAZAN: 'Francisco Morazán',
	STATE_GRACIAS_A_DIOS: 'Gracias a Dios',
	STATE_INTIBUCA: 'Intibucá',
	STATE_ISLAS_DE_LA_BAHIA: 'Islas de la Bahía',
	STATE_LEMPIRA: 'Lempira',
	STATE_OCOTEPEQUE: 'Ocotepeque',
	STATE_OLANCHO: 'Olancho',
	STATE_SANTA_BARBARA: 'Santa Bárbara',
	STATE_VALLE: 'Valle',
	STATE_YORO: 'Yoro',
	STATE_HONG_KONG: 'Hong Kong',
	STATE_BACS_KISKUN: 'Bács-Kiskun',
	STATE_BARANYA: 'Baranya',
	STATE_BEKES: 'Békés',
	STATE_BORSOD_ABAUJ_ZEMPLEN: 'Borsod-Abaúj-Zemplén',
	STATE_BUDAPEST: 'Budapest',
	STATE_CSONGRAD: 'Csongrád',
	STATE_FEJER: 'Fejér',
	STATE_GYOR_MOSON_SOPRON: 'Győr-Moson-Sopron',
	STATE_CORK: 'Cork',
	STATE_HAJDU_BIHAR: 'Hajdú-Bihar',
	STATE_HEVES: 'Heves',
	STATE_JASZ_NAGYKUN_SZOLNOK: 'Jász-Nagykun-Szolnok',
	STATE_KOMAROM_ESZTERGOM: 'Komárom-Esztergom',
	STATE_NOGRAD: 'Nógrád',
	STATE_PEST: 'Pest',
	STATE_SOMOGY: 'Somogy',
	STATE_SZABOLCS_SZATMAR_BEREG: 'Szabolcs-Szatmár-Bereg',
	STATE_TOLNA: 'Tolna',
	STATE_VAS: 'Vas',
	STATE_VESZPREM: 'Veszprém',
	STATE_ZALA: 'Zala',
	STATE_AUSTURLAND: 'Est',
	STATE_GULLBRINGUSYSLA: 'Gullbringusýsla',
	STATE_HOFU_BORGARSVA_I: 'Région de la capitale',
	STATE_NOR_URLAND_EYSTRA: 'Nord-Est',
	STATE_NOR_URLAND_VESTRA: 'Nord-Ouest',
	STATE_SU_URLAND: 'Sud',
	STATE_SU_URNES: 'Péninsule méridionale',
	STATE_VESTFIR_IR: 'Fjords de l’Ouest',
	STATE_VESTURLAND: 'Ouest',
	STATE_ACEH: 'Aceh',
	STATE_BALI: 'Bali',
	STATE_BANGKA_BELITUNG: 'Bangka-Belitung',
	STATE_BANTEN: 'Banten',
	STATE_BENGKULU: 'Bengkulu',
	STATE_GANDARIA: 'Gandaria',
	STATE_GORONTALO: 'Gorontalo',
	STATE_JAKARTA: 'Jakarta',
	STATE_JAMBI: 'Jambi',
	STATE_JAWA_BARAT: 'Java occidental',
	STATE_JAWA_TENGAH: 'Java central',
	STATE_JAWA_TIMUR: 'Java oriental',
	STATE_KALIMANTAN_BARAT: 'Kalimantan occidental',
	STATE_KALIMANTAN_SELATAN: 'Kalimantan du Sud',
	STATE_KALIMANTAN_TENGAH: 'Kalimantan central',
	STATE_KALIMANTAN_TIMUR: 'Kalimantan oriental',
	STATE_KENDAL: 'Kendal',
	STATE_LAMPUNG: 'Lampung',
	STATE_MALUKU: 'Moluques',
	STATE_MALUKU_UTARA: 'Moluques du Nord',
	STATE_NUSA_TENGGARA_BARAT: 'Petites îles de la Sonde occidentales',
	STATE_NUSA_TENGGARA_TIMUR: 'Petites îles de la Sonde orientales',
	STATE_PAPUA: 'Papouasie',
	STATE_RIAU: 'Riau',
	STATE_RIAU_KEPULAUAN: 'Îles Riau',
	STATE_SOLO: 'Surakarta',
	STATE_SULAWESI_SELATAN: 'Sulawesi du Sud',
	STATE_SULAWESI_TENGAH: 'Sulawesi central',
	STATE_SULAWESI_TENGGARA: 'Sulawesi du Sud-Est',
	STATE_SULAWESI_UTARA: 'Sulawesi du Nord',
	STATE_SUMATERA_BARAT: 'Sumatra occidental',
	STATE_SUMATERA_SELATAN: 'Sumatra du Sud',
	STATE_SUMATERA_UTARA: 'Sumatra du Nord',
	STATE_YOGYAKARTA: 'Yogyakarta',
	STATE_ARDABIL: 'Ardabil',
	STATE_AZARBAYJAN_E_BAKHTARI: 'Azerbaïdjan oriental',
	STATE_AZARBAYJAN_E_KHAVARI: 'Azerbaïdjan occidental',
	STATE_BUSHEHR: 'Bouchehr',
	STATE_CHAHAR_MAHAL_E_BAKHTIARI: 'Tchaharmahal-et-Bakhtiari',
	STATE_ESFAHAN: 'Ispahan',
	STATE_FARS: 'Fars',
	STATE_GILAN: 'Gilan',
	STATE_GOLESTAN: 'Golestan',
	STATE_HAMADAN: 'Hamadan',
	STATE_HORMOZGAN: 'Hormozgan',
	STATE_ILAM: 'Ilam',
	STATE_KERMAN: 'Kerman',
	STATE_KERMANSHAH: 'Kermanchah',
	STATE_KHORASAN: 'Khorassan',
	STATE_KHUZESTAN: 'Khouzistan',
	STATE_KOHGILUYEH_E_BOYERAHMAD: 'Kohguilouyeh-et-Bouyer-Ahmad',
	STATE_KORDESTAN: 'Kurdistan',
	STATE_LORESTAN: 'Lorestan',
	STATE_MARKAZI: 'Markazi',
	STATE_MAZANDARAN: 'Mazandaran',
	STATE_OSTAN_E_ESFAHAN: 'Province d’Ispahan',
	STATE_QAZVIN: 'Qazvin',
	STATE_QOM: 'Qom',
	STATE_SEMNAN: 'Semnan',
	STATE_SISTAN_E_BALUCHESTAN: 'Sistan-et-Baloutchistan',
	STATE_TEHRAN: 'Téhéran',
	STATE_YAZD: 'Yazd',
	STATE_ZANJAN: 'Zanjan',
	STATE_BABIL: 'Babil',
	STATE_BAGHDAD: 'Bagdad',
	STATE_DAHUK: 'Dahuk',
	STATE_DHI_QAR: 'Dhi Qar',
	STATE_DIYALA: 'Diyala',
	STATE_ERBIL: 'Erbil',
	STATE_IRBIL: 'Erbil',
	STATE_KARBALA: 'Karbala',
	STATE_KURDISTAN: 'Kurdistan',
	STATE_MAYSAN: 'Maysan',
	STATE_NINAWA: 'Ninive',
	STATE_SALAH_AD_DIN: 'Salah ad-Din',
	STATE_WASIT: 'Wasit',
	STATE_AL_ANBAR: 'Al-Anbâr',
	STATE_AL_BASRAH: 'Al-Basra',
	STATE_AL_MUTHANNA: 'Al-Muthanna',
	STATE_AL_QADISIYAH: 'Al-Qadisiyya',
	STATE_AN_NAJAF: 'An-Najaf',
	STATE_AS_SULAYMANIYAH: 'As-Sulaymaniya',
	STATE_AT_TA__MIM: 'Kirkouk',
	STATE_ARMAGH: 'Armagh',
	STATE_CARLOW: 'Carlow',
	STATE_CAVAN: 'Cavan',
	STATE_CLARE: 'Clare',
	STATE_DONEGAL: 'Donegal',
	STATE_DUBLIN: 'Dublin',
	STATE_GALWAY: 'Galway',
	STATE_KERRY: 'Kerry',
	STATE_KILDARE: 'Kildare',
	STATE_KILKENNY: 'Kilkenny',
	STATE_LAOIS: 'Laois',
	STATE_LEINSTER: 'Leinster',
	STATE_LEITRIM: 'Leitrim',
	STATE_LIMERICK: 'Limerick',
	STATE_LOCH_GARMAN: 'Loch Garman',
	STATE_LONGFORD: 'Longford',
	STATE_LOUTH: 'Louth',
	STATE_MAYO: 'Mayo',
	STATE_MEATH: 'Meath',
	STATE_MONAGHAN: 'Monaghan',
	STATE_OFFALY: 'Offaly',
	STATE_ROSCOMMON: 'Roscommon',
	STATE_SLIGO: 'Sligo',
	STATE_TIPPERARY_NORTH_RIDING: 'Tipperary North Riding',
	STATE_TIPPERARY_SOUTH_RIDING: 'Tipperary South Riding',
	STATE_ULSTER: 'Ulster',
	STATE_WATERFORD: 'Waterford',
	STATE_WESTMEATH: 'Westmeath',
	STATE_WEXFORD: 'Wexford',
	STATE_WICKLOW: 'Wicklow',
	STATE_BEIT_HANANIA: 'Beit Hanina',
	STATE_BEN_GURION_AIRPORT: 'Aéroport Ben Gourion',
	STATE_BETHLEHEM: 'Bethléem',
	STATE_CAESAREA: 'Césarée',
	STATE_GAZA: 'Gaza',
	STATE_HADARON: 'District du Sud',
	STATE_HAIFA_DISTRICT: 'District d’Haïfa',
	STATE_HAMERKAZ: 'District du Centre',
	STATE_HAZAFON: 'District du Nord',
	STATE_HEBRON: 'Hébron',
	STATE_JAFFA: 'Jaffa',
	STATE_JERUSALEM: 'Jérusalem',
	STATE_KHEFA: 'Khefa',
	STATE_KIRYAT_YAM: 'Kiryat Yam',
	STATE_LOWER_GALILEE: 'Basse Galilée',
	STATE_QALQILYA: 'Qalqilya',
	STATE_TALME_ELAZAR: 'Talme Elazar',
	STATE_TEL_AVIV: 'Tel Aviv',
	STATE_TSAFON: 'District du Nord',
	STATE_UMM_EL_FAHEM: 'Umm al-Fahm',
	STATE_YERUSHALAYIM: 'Jérusalem',
	STATE_ABRUZZI: 'Les Abruzzes',
	STATE_ABRUZZO: 'Les Abruzzes',
	STATE_AGRIGENTO: 'Agrigente',
	STATE_ALESSANDRIA: 'Alexandrie',
	STATE_ANCONA: 'Ancône',
	STATE_AREZZO: 'Arezzo',
	STATE_ASCOLI_PICENO: 'Ascoli Piceno',
	STATE_ASTI: 'Asti',
	STATE_AVELLINO: 'Avelline',
	STATE_BARI: 'Bari',
	STATE_BASILICATA: 'Basilicate',
	STATE_BELLUNO: 'Bellune',
	STATE_BENEVENTO: 'Bénévent',
	STATE_BERGAMO: 'Bergame',
	STATE_BIELLA: 'Bielle',
	STATE_BOLOGNA: 'Bologne',
	STATE_BOLZANO: 'Bolzano',
	STATE_BRESCIA: 'Brescia',
	STATE_BRINDISI: 'Brindisi',
	STATE_CALABRIA: 'Calabre',
	STATE_CAMPANIA: 'Campanie',
	STATE_CARTOCETO: 'Cartoceto',
	STATE_CASERTA: 'Caserte',
	STATE_CATANIA: 'Catane',
	STATE_CHIETI: 'Chieti',
	STATE_COMO: 'Côme',
	STATE_COSENZA: 'Cosence',
	STATE_CREMONA: 'Crémone',
	STATE_CUNEO: 'Cuneo',
	STATE_EMILIA_ROMAGNA: 'Émilie-Romagne',
	STATE_FERRARA: 'Ferrare',
	STATE_FIRENZE: 'Florence',
	STATE_FLORENCE: 'Florence',
	STATE_FORLI_CESENA: 'Forlì-Césène',
	STATE_FRIULI_VENEZIA_GIULIA: 'Frioul-Vénétie Julienne',
	STATE_FROSINONE: 'Frosinone',
	STATE_GENOA: 'Gênes',
	STATE_GORIZIA: 'Goritz',
	STATE_L__AQUILA: 'L’Aquila',
	STATE_LAZIO: 'Latium',
	STATE_LECCE: 'Lecce',
	STATE_LECCO: 'Lecce',
	STATE_LECCO_PROVINCE: 'Province de Lecce',
	STATE_LIGURIA: 'Ligurie',
	STATE_LODI: 'Lodi',
	STATE_LOMBARDIA: 'Lombardie',
	STATE_LOMBARDY: 'Lombardie',
	STATE_MACERATA: 'Macerata',
	STATE_MANTOVA: 'Mantoue',
	STATE_MARCHE: 'Marches',
	STATE_MESSINA: 'Messine',
	STATE_MILAN: 'Milan',
	STATE_MODENA: 'Modène',
	STATE_MOLISE: 'Molise',
	STATE_MOLTENO: 'Molteno',
	STATE_MONTENEGRO: 'Monénégro',
	STATE_MONZA_AND_BRIANZA: 'Monza et Brianza',
	STATE_NAPLES: 'Naples',
	STATE_NOVARA: 'Novare',
	STATE_PADOVA: 'Padoue',
	STATE_PARMA: 'Parme',
	STATE_PAVIA: 'Pavie',
	STATE_RUMPHI: 'Rumphi',
	STATE_PERUGIA: 'Pérouse',
	STATE_PESARO_URBINO: 'Pesaro-Urbino',
	STATE_PIACENZA: 'Plaisance',
	STATE_PIEDMONT: 'Piémont',
	STATE_PIEMONTE: 'Piémont',
	STATE_PISA: 'Pise',
	STATE_PORDENONE: 'Pordenone',
	STATE_POTENZA: 'Potenza',
	STATE_PUGLIA: 'Pouilles',
	STATE_REGGIO_EMILIA: 'Reggio d’Émilie',
	STATE_RIMINI: 'Rimini',
	STATE_ROMA: 'Rome',
	STATE_SALERNO: 'Salerne',
	STATE_SARDEGNA: 'Sardaigne',
	STATE_SASSARI: 'Sassari',
	STATE_SAVONA: 'Savone',
	STATE_SICILIA: 'Sicile',
	STATE_SIENA: 'Sienne',
	STATE_SONDRIO: 'Sondrio',
	STATE_SOUTH_TYROL: 'Tyrol du Sud',
	STATE_TARANTO: 'Tarente',
	STATE_TERAMO: 'Teramo',
	STATE_TORINO: 'Turin',
	STATE_TOSCANA: 'Toscane',
	STATE_TRAPANI: 'Trapani',
	STATE_TRENTINO_ALTO_ADIGE: 'Trentin-Haut-Adige',
	STATE_TRENTO: 'Trente',
	STATE_TREVISO: 'Trévise',
	STATE_UDINE: 'Udine',
	STATE_UMBRIA: 'Ombrie',
	STATE_VALLE_D__AOSTA: 'Val d’Aoste',
	STATE_VARESE: 'Varèse',
	STATE_VENETO: 'Vénétie',
	STATE_VENEZIA: 'Venise',
	STATE_VERBANO_CUSIO_OSSOLA: 'Verbano-Cusio-Ossola',
	STATE_VERCELLI: 'Verceil',
	STATE_VERONA: 'Vérone',
	STATE_VICENZA: 'Vicence',
	STATE_VITERBO: 'Viterbe',
	STATE_BUXORO_VILOYATI: 'Boukhara',
	STATE_CLARENDON: 'Clarendon',
	STATE_HANOVER: 'Hanovre',
	STATE_KINGSTON: 'Kingston',
	STATE_MANCHESTER: 'Manchester',
	STATE_PORTLAND: 'Portland',
	STATE_SAINT_ANDREWS: 'Saint-André',
	STATE_SAINT_ANN: 'Sainte-Anne',
	STATE_SAINT_CATHERINE: 'Sainte-Catherine',
	STATE_SAINT_ELIZABETH: 'Sainte-Elizabeth',
	STATE_TRELAWNEY: 'Trelawny',
	STATE_WESTMORELAND: 'Westmoreland',
	STATE_AICHI: 'Aichi',
	STATE_AKITA: 'Akita',
	STATE_AOMORI: 'Aomori',
	STATE_CHIBA: 'Chiba',
	STATE_EHIME: 'Ehime',
	STATE_FUKUI: 'Fukui',
	STATE_FUKUOKA: 'Fukuoka',
	STATE_FUKUSHIMA: 'Fukushima',
	STATE_GIFU: 'Gifu',
	STATE_GUMMA: 'Gunma',
	STATE_HIROSHIMA: 'Hiroshima',
	STATE_HOKKAIDO: 'Hokkaidō',
	STATE_HYOGO: 'Hyōgo',
	STATE_IBARAKI: 'Ibaraki',
	STATE_ISHIKAWA: 'Ishikawa',
	STATE_IWATE: 'Iwate',
	STATE_KAGAWA: 'Kagawa',
	STATE_KAGOSHIMA: 'Kagoshima',
	STATE_KANAGAWA: 'Kanagawa',
	STATE_KANTO: 'Kantō',
	STATE_KOCHI: 'Kōchi',
	STATE_KUMAMOTO: 'Kumamoto',
	STATE_KYOTO: 'Kyoto',
	STATE_MIE: 'Mie',
	STATE_MIYAGI: 'Miyagi',
	STATE_MIYAZAKI: 'Miyazaki',
	STATE_NAGANO: 'Nagano',
	STATE_NAGASAKI: 'Nagasaki',
	STATE_NARA: 'Nara',
	STATE_NIIGATA: 'Niigata',
	STATE_OITA: 'Oita',
	STATE_OKAYAMA: 'Okayama',
	STATE_OKINAWA: 'Okinawa',
	STATE_OSAKA: 'Osaka',
	STATE_SAGA: 'Saga',
	STATE_SAITAMA: 'Saitama',
	STATE_SHIGA: 'Shiga',
	STATE_SHIMANE: 'Shimane',
	STATE_SHIZUOKA: 'Shizuoka',
	STATE_TOCHIGI: 'Tochigi',
	STATE_TOKUSHIMA: 'Tokushima',
	STATE_TOKYO: 'Tokyo',
	STATE_TOTTORI: 'Tottori',
	STATE_TOYAMA: 'Toyama',
	STATE_WAKAYAMA: 'Wakayama',
	STATE_YAMAGATA: 'Yamagata',
	STATE_YAMAGUCHI: 'Yamaguchi',
	STATE_YAMANASHI: 'Yamanashi',
	STATE_GROUVILLE: 'Grouville',
	STATE_SAINT_BRELADE: 'Saint-Brélade',
	STATE_SAINT_CLEMENT: 'Saint-Clément',
	STATE_SAINT_HELIER: 'Saint-Hélier',
	STATE_SAINT_LAWRENCE: 'Saint-Laurent',
	STATE_SALIMA: 'Salima',
	STATE_TRINITY: 'La Trinité',
	STATE_AJLUN: 'Ajlun',
	STATE_AMMAN: 'Amman',
	STATE_IRBID: 'Irbid',
	STATE_JARASH: 'Jarash',
	STATE_MA__AN: 'Ma’an',
	STATE_MADABA: 'Madaba',
	STATE_AL___AQABAH: 'al-’Aqabah',
	STATE_AL_BALQA: 'al-Balqa',
	STATE_AL_KARAK: 'al-Karak',
	STATE_AL_MAFRAQ: 'al-Mafraq',
	STATE_AT_TAFILAH: 'at-Tafilah',
	STATE_AZ_ZARQA: 'az-Zarqa',
	STATE_AKMECET: 'Akmecet',
	STATE_AKMOLA: 'Akmola',
	STATE_AKTOBE: 'Aktobe',
	STATE_ALMATI: 'Almati',
	STATE_ATIRAU: 'Atirau',
	STATE_BATIS_KAZAKSTAN: 'Batis Kazakstan',
	STATE_BURLINSKY_REGION: 'Région de Burlinsky',
	STATE_KARAGANDI: 'Karagandi',
	STATE_KOSTANAY: 'Kostanay',
	STATE_MANKISTAU: 'Mankistau',
	STATE_ONTUSTIK_KAZAKSTAN: 'Kazakhstan méridional (Turkistan)',
	STATE_PAVLODAR: 'Pavlodar',
	STATE_SIGIS_KAZAKSTAN: 'Kazakhstan oriental',
	STATE_SOLTUSTIK_KAZAKSTAN: 'Kazakhstan septentrional',
	STATE_TARAZ: 'Taraz',
	STATE_COAST: 'Côte',
	STATE_NAIROBI: 'Nairobi',
	STATE_NORTH_EASTERN: 'Nord-orientale',
	STATE_NYANZA: 'Nyanza',
	STATE_RIFT_VALLEY: 'Vallée du Rift',
	STATE_ABAIANG: 'Abaiang',
	STATE_ABEMANA: 'Abemana',
	STATE_ARANUKA: 'Aranuka',
	STATE_ARORAE: 'Arorae',
	STATE_BANABA: 'Banaba',
	STATE_BERU: 'Beru',
	STATE_BUTARITARI: 'Butaritari',
	STATE_KIRITIMATI: 'Kiritimati',
	STATE_KURIA: 'Kuria',
	STATE_MAIANA: 'Maiana',
	STATE_MAKIN: 'Makin',
	STATE_MARAKEI: 'Marakei',
	STATE_NIKUNAU: 'Nikunau',
	STATE_NONOUTI: 'Nonouti',
	STATE_ONOTOA: 'Onotoa',
	STATE_PHOENIX_ISLANDS: 'Îles Phœnix',
	STATE_TABITEUEA_NORTH: 'Tabiteuea-Nord',
	STATE_TABITEUEA_SOUTH: 'Tabiteuea-Sud',
	STATE_TABUAERAN: 'Tabuaeran',
	STATE_TAMANA: 'Tamana',
	STATE_TARAWA_NORTH: 'Tarawa-Nord',
	STATE_TARAWA_SOUTH: 'Tarawa-Sud',
	STATE_TERAINA: 'Teraina',
	STATE_CHAGANGDO: 'Jagang',
	STATE_HAMGYEONGBUKTO: 'Hamgyong du Nord',
	STATE_HAMGYEONGNAMDO: 'Hamgyong du Sud',
	STATE_HWANGHAEBUKTO: 'Hwanghae du Nord',
	STATE_HWANGHAENAMDO: 'Hwanghae du Sud',
	STATE_KAESEONG: 'Kaesong',
	STATE_KANGWEON: 'Kangwon',
	STATE_NAMPO: 'Nampo',
	STATE_PYEONGANBUKTO: 'Pyongan du Nord',
	STATE_PYEONGANNAMDO: 'Pyongan du Sud',
	STATE_PYEONGYANG: 'Pyongan',
	STATE_YANGGANG: 'Ryanggang',
	STATE_BUSAN: 'Pusan',
	STATE_CHEJU: 'Jeju',
	STATE_CHOLLABUK: 'Jeolla du Nord',
	STATE_CHOLLANAM: 'Jeolla du Sud',
	STATE_CHUNGBUK: 'Chungcheong du Nord',
	STATE_CHUNGCHEONGBUK: 'Chungcheong du Nord',
	STATE_CHUNGCHEONGNAM: 'Chungcheong du Sud',
	STATE_CHUNGNAM: 'Chungcheong du Sud',
	STATE_DAEGU: 'Daegu',
	STATE_GANGWON_DO: 'Gangwon',
	STATE_GOYANG_SI: 'Goyang',
	STATE_GYEONGGI_DO: 'Gyeonggi',
	STATE_GYEONGSANG: 'Gyeongsang',
	STATE_GYEONGSANGNAM_DO: 'Gyeongsang du Sud',
	STATE_INCHEON: 'Incheon',
	STATE_JEJU_SI: 'Ville de Jeju',
	STATE_JEONBUK: 'Jeolla du Nord',
	STATE_KWANGJU: 'Gwangju',
	STATE_KYEONGGI: 'Gyeonggi',
	STATE_KYEONGSANGBUK: 'Gyeongsang du Nord',
	STATE_KYEONGSANGNAM: 'Gyeongsang du Sud',
	STATE_KYONGGI_DO: 'Gyeonggi',
	STATE_KYUNGBUK_DO: 'Gyeongsang du Nord',
	STATE_KYUNGGI_DO: 'Gyeonggi',
	STATE_PUSAN: 'Pusan',
	STATE_SEOUL: 'Séoul',
	STATE_SUDOGWON: 'Région de la capitale de Séoul',
	STATE_TAEGU: 'Daegu',
	STATE_TAEJEON: 'Daejeon',
	STATE_TAEJON_GWANGYOKSI: 'Daejon-gwangyoksi',
	STATE_ULSAN: 'Ulsan',
	STATE_WONJU: 'Wonju',
	STATE_GWANGYOKSI: 'gwangyoksi',
	STATE_AL_ASIMAH: 'Al-Asimah',
	STATE_HAWALLI: 'Hawalli',
	STATE_MISHREF: 'Mishref',
	STATE_QADESIYA: 'Qadesiya',
	STATE_SAFAT: 'Safat',
	STATE_SALMIYA: 'Salmiya',
	STATE_AL_AHMADI: 'Al-Ahmadi',
	STATE_AL_FARWANIYAH: 'Al-Farwaniyah',
	STATE_AL_JAHRA: 'Al-Jahra',
	STATE_AL_KUWAYT: 'Koweït',
	STATE_BATKEN: 'Batken',
	STATE_BISHKEK: 'Bishkek',
	STATE_CHUI: 'Chui',
	STATE_ISSYK_KUL: 'Issyk-Kul',
	STATE_JALAL_ABAD: 'Jalal-Abad',
	STATE_NARYN: 'Naryn',
	STATE_OSH: 'Osh',
	STATE_TALAS: 'Talas',
	STATE_ATTOPU: 'Attapeu',
	STATE_BOKEO: 'Bokeo',
	STATE_BOLIKHAMSAY: 'Borikhamxay',
	STATE_CHAMPASAK: 'Champassak',
	STATE_HOUAPHANH: 'Houaphan',
	STATE_KHAMMOUANE: 'Khammouane',
	STATE_LUANG_NAM_THA: 'Luang Namtha',
	STATE_LUANG_PRABANG: 'Luang Prabang',
	STATE_OUDOMXAY: 'Oudomxay',
	STATE_PHONGSALY: 'Phongsaly',
	STATE_SARAVAN: 'Saravane',
	STATE_SAVANNAKHET: 'Savannakhet',
	STATE_SEKONG: 'Sékong',
	STATE_VIANGCHAN_PREFECTURE: 'Préfecture de Vientiane',
	STATE_VIANGCHAN_PROVINCE: 'Province de Vientiane',
	STATE_XAIGNABURY: 'Xaignabouli',
	STATE_XIANG_KHUANG: 'Xieng Khouang',
	STATE_AIZKRAUKLES: 'Aizkraukle',
	STATE_ALUKSNES: 'Alūksne',
	STATE_BALVU: 'Balvu',
	STATE_BAUSKAS: 'Bauskas',
	STATE_CESU: 'Cēsis',
	STATE_DAUGAVPILS: 'Dunebourg',
	STATE_DAUGAVPILS_CITY: 'Ville de Dunebourg',
	STATE_DOBELES: 'Dobele',
	STATE_GULBENES: 'Gulbene',
	STATE_JEKABSPILS: 'Jekabpils',
	STATE_JELGAVA: 'Jelgava',
	STATE_JELGAVAS: 'Jelgava',
	STATE_JURMALA_CITY: 'Ville de Jurmala',
	STATE_KRASLAVAS: 'Kraslava',
	STATE_KULDIGAS: 'Kuldiga',
	STATE_LIEPAJA: 'Liepaja',
	STATE_LIEPAJAS: 'Liepaja',
	STATE_LIMBAZHU: 'Limbazhu',
	STATE_LUDZAS: 'Ludza',
	STATE_MADONAS: 'Madona',
	STATE_OGRES: 'Ogre',
	STATE_PREILU: 'Preili',
	STATE_REZEKNE: 'Rezekne',
	STATE_REZEKNES: 'Rezekne',
	STATE_RIGA: 'Riga',
	STATE_RIGAS: 'Riga',
	STATE_SALDUS: 'Saldus',
	STATE_TALSU: 'Talsu',
	STATE_TUKUMA: 'Tukuma',
	STATE_VALKAS: 'Valka',
	STATE_VALMIERAS: 'Valmiera',
	STATE_VENTSPILS: 'Ventspils',
	STATE_VENTSPILS_CITY: 'Ville de Ventspils',
	STATE_BEIRUT: 'Beyrouth',
	STATE_JABAL_LUBNAN: 'Mont-Liban',
	STATE_MOHAFAZAT_LIBAN_NORD: 'Gouvernorat du Liban-Nord',
	STATE_MOHAFAZAT_MONT_LIBAN: 'Gouvernorat du Mont-Liban',
	STATE_SIDON: 'Sidon',
	STATE_AL_BIQA: 'Bekaa',
	STATE_AL_JANUB: 'Gouvernorat du Liban-Sud',
	STATE_AN_NABATIYAH: 'Nabatieh',
	STATE_ASH_SHAMAL: 'Ash Shamal',
	STATE_BEREA: 'Berea',
	STATE_BUTHA_BUTHE: 'Butha-Buthe',
	STATE_LERIBE: 'Leribe',
	STATE_MAFETENG: 'Mafeteng',
	STATE_MASERU: 'Maseru',
	STATE_MOHALE__S_HOEK: 'Mohale’s Hoek',
	STATE_MOKHOTLONG: 'Mokhotlong',
	STATE_QACHA__S_NEK: 'Qacha’s Nek',
	STATE_QUTHING: 'Quthing',
	STATE_THABA_TSEKA: 'Thaba-Tseka',
	STATE_BOMI: 'Bomi',
	STATE_BONG: 'Bong',
	STATE_GRAND_BASSA: 'Grand Bassa',
	STATE_GRAND_CAPE_MOUNT: 'Grand Cape Mount',
	STATE_GRAND_GEDEH: 'Grand Gedeh',
	STATE_LOFFA: 'Loffa',
	STATE_MARGIBI: 'Margibi',
	STATE_MARYLAND_AND_GRAND_KRU: 'Maryland et Grand Kru',
	STATE_MONTSERRADO: 'Montserrado',
	STATE_NIMBA: 'Nimba',
	STATE_RIVERCESS: 'Rivercess',
	STATE_SINOE: 'Sinoe',
	STATE_AJDABIYA: 'Ajdabiya',
	STATE_FEZZAN: 'Fezzan',
	STATE_BANGHAZI: 'Benghazi',
	STATE_DARNAH: 'Derna',
	STATE_GHADAMIS: 'Ghadamès',
	STATE_GHARYAN: 'Gharyan',
	STATE_MISRATAH: 'Misrata',
	STATE_MURZUQ: 'Mourzouq',
	STATE_SABHA: 'Sabha',
	STATE_SAWFAJJIN: 'Sawfajjin',
	STATE_SURT: 'Syrte',
	STATE_TARABULUS: 'Tripoli',
	STATE_TARHUNAH: 'Tarhuna',
	STATE_TRIPOLITANIA: 'Tripolitaine',
	STATE_TUBRUQ: 'Tobrouk',
	STATE_YAFRAN: 'Yefren',
	STATE_ZLITAN: 'Zliten',
	STATE_AL___AZIZIYAH: 'Al-’Aziziyah',
	STATE_AL_FATIH: 'Al Fateh',
	STATE_AL_JABAL_AL_AKHDAR: 'Al Jabal al Akhdar',
	STATE_AL_JUFRAH: 'Jufra',
	STATE_AL_KHUMS: 'Al Khums',
	STATE_AL_KUFRAH: 'Al Koufrah',
	STATE_AN_NUQAT_AL_KHAMS: 'An Nuqat al Khams',
	STATE_ASH_SHATI: 'Ach Chatii',
	STATE_AZ_ZAWIYAH: 'Az Zaouiyah',
	STATE_BALZERS: 'Balzers',
	STATE_ESCHEN: 'Eschen',
	STATE_GAMPRIN: 'Gamprin',
	STATE_MAUREN: 'Mauren',
	STATE_PLANKEN: 'Planken',
	STATE_RUGGELL: 'Ruggell',
	STATE_SCHAAN: 'Schaan',
	STATE_SCHELLENBERG: 'Schellenberg',
	STATE_TRIESEN: 'Triesen',
	STATE_TRIESENBERG: 'Triesenberg',
	STATE_VADUZ: 'Vaduz',
	STATE_ALYTAUS: 'Alytus',
	STATE_ANYKSCIAI: 'Anyksciai',
	STATE_KAUNO: 'Kauno',
	STATE_KLAIPEDOS: 'Klaipedos',
	STATE_MARIJAMPOLES: 'Marijampoles',
	STATE_PANEVEZHIO: 'Panevezhio',
	STATE_PANEVEZYS: 'Panevezys',
	STATE_SHIAULIU: 'Shiauliu',
	STATE_TAURAGES: 'Taurages',
	STATE_TELSHIU: 'Telshiu',
	STATE_TELSIAI: 'Telsiai',
	STATE_UTENOS: 'Utenos',
	STATE_VILNIAUS: 'Vilnius',
	STATE_CAPELLEN: 'Capellen',
	STATE_CLERVAUX: 'Clervaux',
	STATE_DIEKIRCH: 'Diekirch',
	STATE_ECHTERNACH: 'Echternach',
	STATE_ESCH_SUR_ALZETTE: 'Esch-sur-Alzette',
	STATE_GREVENMACHER: 'Grevenmacher',
	STATE_MERSCH: 'Mersch',
	STATE_REDANGE: 'Redange',
	STATE_REMICH: 'Remich',
	STATE_VIANDEN: 'Vianden',
	STATE_WILTZ: 'Wiltz',
	STATE_MACAU: 'Macau',
	STATE_BEROVO: 'Berovo',
	STATE_BITOLA: 'Bitola',
	STATE_BROD: 'Brod',
	STATE_DEBAR: 'Debar',
	STATE_DELCHEVO: 'Delchevo',
	STATE_DEMIR_HISAR: 'Demir Hisar',
	STATE_GEVGELIJA: 'Gevgelija',
	STATE_GOSTIVAR: 'Gostivar',
	STATE_KAVADARCI: 'Kavadarci',
	STATE_KICHEVO: 'Kichevo',
	STATE_KOCHANI: 'Kochani',
	STATE_KRATOVO: 'Kratovo',
	STATE_KRIVA_PALANKA: 'Kriva Palanka',
	STATE_KRUSHEVO: 'Krushevo',
	STATE_KUMANOVO: 'Kumanovo',
	STATE_NEGOTINO: 'Negotino',
	STATE_OHRID: 'Ohrid',
	STATE_PRILEP: 'Prilep',
	STATE_PROBISHTIP: 'Probishtip',
	STATE_RADOVISH: 'Radovish',
	STATE_RESEN: 'Resen',
	STATE_SHTIP: 'Shtip',
	STATE_SKOPJE: 'Skopje',
	STATE_STRUGA: 'Struga',
	STATE_STRUMICA: 'Strumica',
	STATE_SVETI_NIKOLE: 'Sveti Nikole',
	STATE_TETOVO: 'Tetovo',
	STATE_VALANDOVO: 'Valandovo',
	STATE_VELES: 'Veles',
	STATE_VINICA: 'Vinica',
	STATE_ANTANANARIVO: 'Antananarivo',
	STATE_ANTSIRANANA: 'Antsiranana',
	STATE_FIANARANTSOA: 'Fianarantsoa',
	STATE_MAHAJANGA: 'Mahajanga',
	STATE_TOAMASINA: 'Toamasina',
	STATE_TOLIARY: 'Toliary',
	STATE_BALAKA: 'Balaka',
	STATE_BLANTYRE_CITY: 'Ville de Blantyre',
	STATE_CHIKWAWA: 'Chikwawa',
	STATE_CHIRADZULU: 'Chiradzulu',
	STATE_CHITIPA: 'Chitipa',
	STATE_DEDZA: 'Dedza',
	STATE_DOWA: 'Dowa',
	STATE_KARONGA: 'Karonga',
	STATE_KASUNGU: 'Kasungu',
	STATE_LILONGWE_CITY: 'Ville de Lilongwe',
	STATE_MACHINGA: 'Machinga',
	STATE_MANGOCHI: 'Mangochi',
	STATE_MCHINJI: 'Mchinji',
	STATE_MULANJE: 'Mulanje',
	STATE_MWANZA: 'Mwanza',
	STATE_MZIMBA: 'Mzimba',
	STATE_MZUZU_CITY: 'Ville de Mzuzu',
	STATE_NKHATA_BAY: 'Baie de Nkhata',
	STATE_NKHOTAKOTA: 'Nkhotakota',
	STATE_NSANJE: 'Nsanje',
	STATE_NTCHEU: 'Ntcheu',
	STATE_NTCHISI: 'Ntchisi',
	STATE_PHALOMBE: 'Phalombe',
	STATE_THYOLO: 'Thyolo',
	STATE_ZOMBA_MUNICIPALITY: 'Municipalité de Zomba',
	STATE_JOHOR: 'Johor',
	STATE_KEDAH: 'Kedah',
	STATE_KELANTAN: 'Kelantan',
	STATE_KUALA_LUMPUR: 'Kuala Lumpur',
	STATE_LABUAN: 'Labuan',
	STATE_MELAKA: 'Melaka',
	STATE_NEGERI_JOHOR: 'Negeri Johor',
	STATE_NEGERI_SEMBILAN: 'Negeri Sembilan',
	STATE_PAHANG: 'Pahang',
	STATE_PENANG: 'Penang',
	STATE_PERAK: 'Perak',
	STATE_PERLIS: 'Perlis',
	STATE_PULAU_PINANG: 'Pulau Pinang',
	STATE_SABAH: 'Sabah',
	STATE_SARAWAK: 'Sarawak',
	STATE_SELANGOR: 'Selangor',
	STATE_SEMBILAN: 'Sembilan',
	STATE_TERENGGANU: 'Terengganu',
	STATE_ALIF_ALIF: 'Alif Alif',
	STATE_ALIF_DHAAL: 'Alif Dhaal',
	STATE_BAA: 'Baa',
	STATE_DHAAL: 'Dhaal',
	STATE_FAAF: 'Faaf',
	STATE_GAAF_ALIF: 'Gaaf Alif',
	STATE_GAAF_DHAAL: 'Gaaf Dhaal',
	STATE_GHAVIYANI: 'Ghaviyani',
	STATE_HAA_ALIF: 'Haa Alif',
	STATE_HAA_DHAAL: 'Haa Dhaal',
	STATE_KAAF: 'Kaaf',
	STATE_LAAM: 'Laam',
	STATE_LHAVIYANI: 'Lhaviyani',
	STATE_MALE: 'Male',
	STATE_MIIM: 'Miim',
	STATE_NUUN: 'Nuun',
	STATE_RAA: 'Raa',
	STATE_SHAVIYANI: 'Shaviyani',
	STATE_SIIN: 'Siin',
	STATE_THAA: 'Thaa',
	STATE_VAAV: 'Vaav',
	STATE_BAMAKO: 'Bamako',
	STATE_GAO: 'Gao',
	STATE_KAYES: 'Kayes',
	STATE_KIDAL: 'Kidal',
	STATE_KOULIKORO: 'Koulikoro',
	STATE_MOPTI: 'Mopti',
	STATE_SEGOU: 'Ségou',
	STATE_SIKASSO: 'Sikasso',
	STATE_TOMBOUCTOU: 'Tombouctou',
	STATE_GOZO_AND_COMINO: 'Gozo et Comino',
	STATE_INNER_HARBOUR: 'Inner Harbour',
	STATE_OUTER_HARBOUR: 'Outer Harbour',
	STATE_SOUTH_EASTERN: 'South Eastern',
	STATE_CASTLETOWN: 'Castletown',
	STATE_DOUGLAS: 'Douglas',
	STATE_LAXEY: 'Laxey',
	STATE_ONCHAN: 'Onchan',
	STATE_PEEL: 'Peel',
	STATE_PORT_ERIN: 'Port Erin',
	STATE_PORT_SAINT_MARY: 'Port Saint Mary',
	STATE_RAMSEY: 'Ramsey',
	STATE_AILINLAPLAP: 'Ailinlaplap',
	STATE_AILUK: 'Ailuk',
	STATE_ARNO: 'Arno',
	STATE_AUR: 'Aur',
	STATE_BIKINI: 'Bikini',
	STATE_EBON: 'Ebon',
	STATE_ENEWETAK: 'Enewetak',
	STATE_JABAT: 'Jabat',
	STATE_JALUIT: 'Jaluit',
	STATE_KILI: 'Kili',
	STATE_KWAJALEIN: 'Kwajalein',
	STATE_LAE: 'Lae',
	STATE_LIB: 'Lib',
	STATE_LIKIEP: 'Likiep',
	STATE_MAJURO: 'Majuro',
	STATE_MALOELAP: 'Maloelap',
	STATE_MEJIT: 'Mejit',
	STATE_MILI: 'Mili',
	STATE_NAMORIK: 'Namorik',
	STATE_NAMU: 'Namu',
	STATE_RONGELAP: 'Rongelap',
	STATE_UJAE: 'Ujae',
	STATE_UTRIK: 'Utrik',
	STATE_WOTHO: 'Wotho',
	STATE_WOTJE: 'Wotje',
	STATE_FORT_DE_FRANCE: 'Fort-de-France',
	STATE_LA_TRINITE: 'La Trinité',
	STATE_LE_MARIN: 'Le Marin',
	STATE_SAINT_PIERRE: 'Saint-Pierre',
	STATE_ASSABA: 'Assaba',
	STATE_BRAKNA: 'Brakna',
	STATE_DHAKHLAT_NAWADIBU: 'Dakhlet Nouadhibou',
	STATE_HUDH_AL_GHARBI: 'Hodh El Gharbi',
	STATE_HUDH_ASH_SHARQI: 'Hodh Ech Chargui',
	STATE_INSHIRI: 'Inchiri',
	STATE_NAWAKSHUT: 'Nouakchott',
	STATE_QIDIMAGHA: 'Guidimakha',
	STATE_QURQUL: 'Gorgol',
	STATE_TAQANT: 'Tagant',
	STATE_TIRIS_ZAMMUR: 'Tiris Zemmour',
	STATE_TRARZA: 'Trarza',
	STATE_BLACK_RIVER: 'Rivière Noire',
	STATE_EAU_COULEE: 'Eau Coulée',
	STATE_FLACQ: 'Flacq',
	STATE_FLOREAL: 'Floréal',
	STATE_GRAND_PORT: 'Grand Port',
	STATE_MOKA: 'Moka',
	STATE_PAMPLEMPOUSSES: 'Pamplemousses',
	STATE_PLAINES_WILHELM: 'Plaines Wilhelm',
	STATE_PORT_LOUIS: 'Port-Louis',
	STATE_RIVIERE_DU_REMPART: 'Rivière du Rempart',
	STATE_RODRIGUES: 'Rodrigues',
	STATE_ROSE_HILL: 'Rose Hill',
	STATE_SAVANNE: 'Savanne',
	STATE_MAYOTTE: 'Mayotte',
	STATE_PAMANZI: 'Pamanzi',
	STATE_AGUASCALIENTES: 'Aguascalientes',
	STATE_BAJA_CALIFORNIA: 'Basse-Californie',
	STATE_BAJA_CALIFORNIA_SUR: 'Basse-Californie-du-Sud',
	STATE_CAMPECHE: 'Campeche',
	STATE_CHIAPAS: 'Chiapas',
	STATE_CHIHUAHUA: 'Chihuahua',
	STATE_COAHUILA: 'Coahuila',
	STATE_COLIMA: 'Colima',
	STATE_DURANGO: 'Durango',
	STATE_ESTADO_DE_MEXICO: 'État de Mexico',
	STATE_GUANAJUATO: 'Guanajuato',
	STATE_GUERRERO: 'Guerrero',
	STATE_HIDALGO: 'Hidalgo',
	STATE_JALISCO: 'Jalisco',
	STATE_MEXICO: 'Mexico',
	STATE_MICHOACAN: 'Michoacán',
	STATE_MORELOS: 'Morelos',
	STATE_NAYARIT: 'Nayarit',
	STATE_NUEVO_LEON: 'Nuevo León',
	STATE_OAXACA: 'Oaxaca',
	STATE_PUEBLA: 'Puebla',
	STATE_QUERETARO: 'Querétaro',
	STATE_QUINTANA_ROO: 'Quintana Roo',
	STATE_SAN_LUIS_POTOSI: 'San Luis Potosí',
	STATE_SINALOA: 'Sinaloa',
	STATE_SONORA: 'Sonora',
	STATE_TABASCO: 'Tabasco',
	STATE_TAMAULIPAS: 'Tamaulipas',
	STATE_TLAXCALA: 'Tlaxcala',
	STATE_VERACRUZ: 'Veracruz',
	STATE_YUCATAN: 'Yucatán',
	STATE_ZACATECAS: 'Zacatecas',
	STATE_CHUUK: 'Chuuk',
	STATE_KUSAIE: 'Kosrae',
	STATE_POHNPEI: 'Pohnpei',
	STATE_YAP: 'Yap',
	STATE_BALTI: 'Balti',
	STATE_CAHUL: 'Cahul',
	STATE_CHISINAU: 'Chisinau',
	STATE_CHISINAU_ORAS: 'Chisinau Oras',
	STATE_EDINET: 'Edinet',
	STATE_GAGAUZIA: 'Gagauzia',
	STATE_LAPUSNA: 'Lapusna',
	STATE_ORHEI: 'Orhei',
	STATE_SOROCA: 'Soroca',
	STATE_TARACLIA: 'Taraclia',
	STATE_TIGHINA: 'Tighina',
	STATE_TRANSNISTRIA: 'Transnistria',
	STATE_UNGHENI: 'Ungheni',
	STATE_FONTVIEILLE: 'Fontvieille',
	STATE_LA_CONDAMINE: 'La Condamine',
	STATE_MONACO_VILLE: 'Monaco-Ville',
	STATE_MONTE_CARLO: 'Monte-Carlo',
	STATE_ARHANGAJ: 'Arkhangai',
	STATE_BAJAN_OLGIJ: 'Bayan-Ölgii',
	STATE_BAJANHONGOR: 'Bayankhongor',
	STATE_BULGAN: 'Bulgan',
	STATE_DARHAN_UUL: 'Darkhan-Uul',
	STATE_DORNOD: 'Dornod',
	STATE_DORNOGOVI: 'Dornogovi',
	STATE_DUNDGOVI: 'Dundgovi',
	STATE_GOVI_ALTAJ: 'Govi-Altai',
	STATE_GOVISUMBER: 'Govisümber',
	STATE_HENTIJ: 'Khentii',
	STATE_HOVD: 'Khovd',
	STATE_HOVSGOL: 'Khövsgöl',
	STATE_OMNOGOVI: 'Ömnögovi',
	STATE_ORHON: 'Orkhon',
	STATE_OVORHANGAJ: 'Övörkhangai',
	STATE_SELENGE: 'Selenge',
	STATE_SUHBAATAR: 'Sükhbaatar',
	STATE_TOV: 'Töv',
	STATE_ULAANBAATAR: 'Oulan-Bator',
	STATE_UVS: 'Uvs',
	STATE_ZAVHAN: 'Zavkhan',
	STATE_MONTSERRAT: 'Montserrat',
	STATE_AGADIR: 'Agadir',
	STATE_CASABLANCA: 'Casablanca',
	STATE_CHAOUIA_OUARDIGHA: 'Chaouia-Ouardigha',
	STATE_DOUKKALA_ABDA: 'Doukkala-Abda',
	STATE_FES_BOULEMANE: 'Fès-Boulemane',
	STATE_GHARB_CHRARDA_BENI_HSSEN: 'Gharb-Chrarda-Beni Hssen',
	STATE_GUELMIM: 'Guelmim',
	STATE_KENITRA: 'Kénitra',
	STATE_MARRAKECH_TENSIFT_AL_HAOUZ: 'Marrakech-Tensift-Al Haouz',
	STATE_MEKNES_TAFILALET: 'Meknès-Tafilalet',
	STATE_ORIENTAL: 'Oriental',
	STATE_OUJDA: 'Oujda',
	STATE_PROVINCE_DE_TANGER: 'Province de Tanger',
	STATE_RABAT_SALE_ZAMMOUR_ZAER: 'Rabat-Salé-Zammour-Zaër',
	STATE_SALA_AL_JADIDA: 'Sala Al-Jadida',
	STATE_SETTAT: 'Settat',
	STATE_SOUSS_MASSA_DRAA: 'Souss Massa-Draa',
	STATE_TADLA_AZILAL: 'Tadla-Azilal',
	STATE_TANGIER_TETOUAN: 'Tanger-Tétouan',
	STATE_TAZA_AL_HOCEIMA_TAOUNATE: 'Taza-Al Hoceïma-Taounate',
	STATE_WILAYA_DE_CASABLANCA: 'Wilaya de Casablanca',
	STATE_WILAYA_DE_RABAT_SALE: 'Wilaya de Rabat-Salé',
	STATE_CABO_DELGADO: 'Cabo Delgado',
	STATE_INHAMBANE: 'Inhambane',
	STATE_MANICA: 'Manica',
	STATE_MAPUTO: 'Maputo',
	STATE_MAPUTO_PROVINCIA: 'Province de Maputo',
	STATE_NAMPULA: 'Nampula',
	STATE_NIASSA: 'Niassa',
	STATE_SOFALA: 'Sofala',
	STATE_TETE: 'Tete',
	STATE_ZAMBEZIA: 'Zambezia',
	STATE_AYEYARWADY: 'Ayeyarwady',
	STATE_BAGO: 'Bago',
	STATE_CHIN: 'Chin',
	STATE_KACHIN: 'Kachin',
	STATE_KAYAH: 'Kayah',
	STATE_KAYIN: 'Kayin',
	STATE_MAGWAY: 'Magway',
	STATE_MANDALAY: 'Mandalay',
	STATE_MON: 'Mon',
	STATE_NAY_PYI_TAW: 'Nay Pyi Taw',
	STATE_RAKHINE: 'Rakhine',
	STATE_SAGAING: 'Sagaing',
	STATE_SHAN: 'Shan',
	STATE_TANINTHARYI: 'Tanintharyi',
	STATE_YANGON: 'Yangon',
	STATE_CAPRIVI: 'Caprivi',
	STATE_ERONGO: 'Erongo',
	STATE_HARDAP: 'Hardap',
	STATE_KARAS: 'Karas',
	STATE_KAVANGO: 'Kavango',
	STATE_KHOMAS: 'Khomas',
	STATE_KUNENE: 'Kunene',
	STATE_OHANGWENA: 'Ohangwena',
	STATE_OMAHEKE: 'Omaheke',
	STATE_OMUSATI: 'Omusati',
	STATE_OSHANA: 'Oshana',
	STATE_OSHIKOTO: 'Oshikoto',
	STATE_OTJOZONDJUPA: 'Otjozondjupa',
	STATE_YAREN: 'Yaren',
	STATE_BAGMATI: 'Bagmati',
	STATE_BHERI: 'Bheri',
	STATE_DHAWALAGIRI: 'Dhawalagiri',
	STATE_GANDAKI: 'Gandaki',
	STATE_JANAKPUR: 'Janakpur',
	STATE_KARNALI: 'Karnali',
	STATE_KOSHI: 'Koshi',
	STATE_LUMBINI: 'Lumbini',
	STATE_MAHAKALI: 'Mahakali',
	STATE_MECHI: 'Mechi',
	STATE_NARAYANI: 'Narayani',
	STATE_RAPTI: 'Rapti',
	STATE_SAGARMATHA: 'Sagarmatha',
	STATE_SETI: 'Seti',
	STATE_BONAIRE: 'Bonaire',
	STATE_CURACAO: 'Curaçao',
	STATE_SABA: 'Saba',
	STATE_SINT_EUSTATIUS: 'Saint-Eustache',
	STATE_SINT_MAARTEN: 'Saint-Martin',
	STATE_BENELUX: 'Bénélux',
	STATE_DRENTHE: 'Drenthe',
	STATE_FLEVOLAND: 'Flevoland',
	STATE_FRIESLAND: 'Frise',
	STATE_GELDERLAND: 'Gueldre',
	STATE_GRONINGEN: 'Groningue',
	STATE_NOORD_BRABANT: 'Brabant-Septentrional',
	STATE_OVERIJSSEL: 'Overijssel',
	STATE_SOUTH_HOLLAND: 'Hollande-Méridionale ',
	STATE_UTRECHT: 'Utrecht',
	STATE_ZEELAND: 'Zélande',
	STATE_ZUID_HOLLAND: 'Hollande-Méridionale',
	STATE_ILES: 'Îles',
	STATE_AREA_OUTSIDE_REGION: 'Zone en-dehors de la région',
	STATE_BAY_OF_PLENTY: 'Bay of Plenty',
	STATE_CANTERBURY: 'Canterbury',
	STATE_CHRISTCHURCH: 'Christchurch',
	STATE_GISBORNE: 'Gisborne',
	STATE_HAWKE__S_BAY: 'Hawke’s Bay',
	STATE_MANAWATU_WANGANUI: 'Manawatu-Wanganui',
	STATE_MARLBOROUGH: 'Marlborough',
	STATE_NELSON: 'Nelson',
	STATE_NORTHLAND: 'Northland',
	STATE_OTAGO: 'Otago',
	STATE_RODNEY: 'Rodney',
	STATE_SOUTHLAND: 'Southland',
	STATE_TARANAKI: 'Taranaki',
	STATE_TASMAN: 'Tasman',
	STATE_WAIKATO: 'Waikato',
	STATE_WELLINGTON: 'Wellington',
	STATE_WEST_COAST: 'West Coast',
	STATE_ATLANTICO_NORTE: 'Atlantique Nord',
	STATE_ATLANTICO_SUR: 'Atlantique Sud',
	STATE_BOACO: 'Boaco',
	STATE_CARAZO: 'Carazo',
	STATE_CHINANDEGA: 'Chinandega',
	STATE_CHONTALES: 'Chontales',
	STATE_ESTELI: 'Estelí',
	STATE_GRANADA: 'Granada',
	STATE_JINOTEGA: 'Jinotega',
	STATE_LEON: 'León',
	STATE_MADRIZ: 'Madriz',
	STATE_MANAGUA: 'Managua',
	STATE_MASAYA: 'Masaya',
	STATE_MATAGALPA: 'Matagalpa',
	STATE_NUEVA_SEGOVIA: 'Nueva Segovia',
	STATE_RIO_SAN_JUAN: 'Río San Juan',
	STATE_RIVAS: 'Rivas',
	STATE_AGADEZ: 'Agadez',
	STATE_DIFFA: 'Diffa',
	STATE_DOSSO: 'Dosso',
	STATE_MARADI: 'Maradi',
	STATE_NIAMEY: 'Niamey',
	STATE_TAHOUA: 'Tahoua',
	STATE_TILLABERY: 'Tillabéri',
	STATE_ZINDER: 'Zinder',
	STATE_ABIA: 'Abia',
	STATE_ABUJA_FEDERAL_CAPITAL_TERRITOR: 'Territoire de la capitale fédérale Abuja',
	STATE_ADAMAWA: 'Adamawa',
	STATE_AKWA_IBOM: 'Akwa Ibom',
	STATE_ANAMBRA: 'Anambra',
	STATE_BAUCHI: 'Bauchi',
	STATE_BAYELSA: 'Bayelsa',
	STATE_BENUE: 'Benue',
	STATE_BORNO: 'Borno',
	STATE_CROSS_RIVER: 'Cross River',
	STATE_DELTA: 'Delta',
	STATE_EBONYI: 'Ebonyi',
	STATE_EDO: 'Edo',
	STATE_EKITI: 'Ekiti',
	STATE_ENUGU: 'Enugu',
	STATE_GOMBE: 'Gombe',
	STATE_IMO: 'Imo',
	STATE_JIGAWA: 'Jigawa',
	STATE_KADUNA: 'Kaduna',
	STATE_KANO: 'Kano',
	STATE_KATSINA: 'Katsina',
	STATE_KEBBI: 'Kebbi',
	STATE_KOGI: 'Kogi',
	STATE_KWARA: 'Kwara',
	STATE_LAGOS: 'Lagos',
	STATE_NASSARAWA: 'Nassarawa',
	STATE_NIGER: 'Niger',
	STATE_OGUN: 'Ogun',
	STATE_ONDO: 'Ondo',
	STATE_OSUN: 'Osun',
	STATE_OYO: 'Oyo',
	STATE_RIVERS: 'Rivers',
	STATE_SOKOTO: 'Sokoto',
	STATE_TARABA: 'Taraba',
	STATE_YOBE: 'Yobe',
	STATE_ZAMFARA: 'Zamfara',
	STATE_NIUE: 'Niue',
	STATE_NORFOLK_ISLAND: 'Île Norfolk',
	STATE_NORTHERN_ISLANDS: 'Îles du Nord',
	STATE_ROTA: 'Rota',
	STATE_SAIPAN: 'Saipan',
	STATE_TINIAN: 'Tinian',
	STATE_AKERSHUS: 'Akershus',
	STATE_AUST_AGDER: 'Aust-Agder',
	STATE_BERGEN: 'Bergen',
	STATE_BUSKERUD: 'Buskerud',
	STATE_FINNMARK: 'Finnmark',
	STATE_HEDMARK: 'Hedmark',
	STATE_HORDALAND: 'Hordaland',
	STATE_MOERE_OG_ROMSDAL: 'Møre og Romsdal',
	STATE_NORD_TRONDELAG: 'Nord-Trøndelag',
	STATE_NORDLAND: 'Nordland',
	STATE_OESTFOLD: 'Østfold',
	STATE_OPPLAND: 'Oppland',
	STATE_OSLO: 'Oslo',
	STATE_ROGALAND: 'Rogaland',
	STATE_SOER_TROENDELAG: 'Soer-Troendelag',
	STATE_SOGN_OG_FJORDANE: 'Sogn og Fjordane',
	STATE_STAVERN: 'Stavern',
	STATE_SYKKYLVEN: 'Sykkylven',
	STATE_TELEMARK: 'Telemark',
	STATE_TROMS: 'Troms',
	STATE_VEST_AGDER: 'Vest-Agder',
	STATE_VESTFOLD: 'Vestfold',
	'STATE_ÃƑÆ’Ã‚ËŒSTFOLD': 'ÃƒÆ’Ã‚Ëœstfold',
	STATE_AL_BURAIMI: 'Al Buraimi',
	STATE_DHUFAR: 'Dhufar',
	STATE_MASQAT: 'Masqat',
	STATE_MUSANDAM: 'Musandam',
	STATE_RUSAYL: 'Rusayl',
	STATE_WADI_KABIR: 'Wadi Kabir',
	STATE_AD_DAKHILIYAH: 'Ad-Dākhilīyah',
	STATE_ADH_DHAHIRAH: 'Adh-Dhahira',
	STATE_AL_BATINAH: 'Al-Batina',
	STATE_BALUCHISTAN: 'Baluchistan',
	STATE_FEDERAL_CAPITAL_AREA: 'Zone de la capitale fédérale',
	STATE_FEDERALLY_ADMINISTERED_TRIBAL: 'Zones tribales sous administration fédérale',
	STATE_NORTH_WEST_FRONTIER: 'Frontière Nord-Ouest',
	STATE_NORTHERN_AREAS: 'Zones du Nord',
	STATE_SIND: 'Sind',
	STATE_AIMELIIK: 'Aimeliik',
	STATE_AIRAI: 'Airai',
	STATE_ANGAUR: 'Angaur',
	STATE_HATOBOHEI: 'Hatobohei',
	STATE_KAYANGEL: 'Kayangel',
	STATE_KOROR: 'Koror',
	STATE_MELEKEOK: 'Melekeok',
	STATE_NGARAARD: 'Ngaraard',
	STATE_NGARDMAU: 'Ngardmau',
	STATE_NGAREMLENGUI: 'Ngaremlengui',
	STATE_NGATPANG: 'Ngatpang',
	STATE_NGCHESAR: 'Ngchesar',
	STATE_NGERCHELONG: 'Ngerchelong',
	STATE_NGIWAL: 'Ngiwal',
	STATE_PELELIU: 'Peleliu',
	STATE_SONSOROL: 'Sonsorol',
	STATE_ARIHA: 'Ariha',
	STATE_BAYT_LAHM: 'Bethléem',
	STATE_DAYR_AL_BALAH: 'Deir el-Balah',
	STATE_GHAZZAH: 'Gaza',
	STATE_GHAZZAH_ASH_SHAMALIYAH: 'Bande de Gaza',
	STATE_JANIN: 'Jénine',
	STATE_KHAN_YUNIS: 'Khan Yunis',
	STATE_NABULUS: 'Naplouse',
	STATE_QALQILYAH: 'Qalqilya',
	STATE_RAFAH: 'Rafah',
	STATE_RAM_ALLAH_WAL_BIRAH: 'Ramallah Al-Bireh',
	STATE_SALFIT: 'Salfit',
	STATE_TUBAS: 'Tubas',
	STATE_TULKARM: 'Tulkarem',
	STATE_AL_KHALIL: 'Al Khalil',
	STATE_AL_QUDS: 'Jérusalem',
	STATE_BOCAS_DEL_TORO: 'Bocas del Toro',
	STATE_CHIRIQUI: 'Chiriquí',
	STATE_COCLE: 'Coclé',
	STATE_DARIEN: 'Darién',
	STATE_EMBERA: 'Emberà',
	STATE_HERRERA: 'Herrera',
	STATE_KUNA_YALA: 'Guna Yala',
	STATE_LOS_SANTOS: 'Los Santos',
	STATE_NGOBE_BUGLE: 'Ngöbe-Buglé',
	STATE_PANAMA: 'Panama',
	STATE_VERAGUAS: 'Veraguas',
	STATE_EAST_NEW_BRITAIN: 'Nouvelle-Bretagne orientale',
	STATE_EAST_SEPIK: 'Sepik oriental',
	STATE_EASTERN_HIGHLANDS: 'Hautes-Terres orientales',
	STATE_ENGA: 'Enga',
	STATE_FLY_RIVER: 'Fly River',
	STATE_GULF: 'Golfe',
	STATE_MADANG: 'Madang',
	STATE_MANUS: 'Manus',
	STATE_MILNE_BAY: 'Milne Bay',
	STATE_MOROBE: 'Morobe',
	STATE_NATIONAL_CAPITAL_DISTRICT: 'District de la capitale nationale',
	STATE_NEW_IRELAND: 'Nouvelle-Irlande',
	STATE_NORTH_SOLOMONS: 'Salomon du Nord',
	STATE_ORO: 'Oro',
	STATE_SANDAUN: 'Sandaun',
	STATE_SIMBU: 'Simbu',
	STATE_SOUTHERN_HIGHLANDS: 'Hautes-Terres méridionales',
	STATE_WEST_NEW_BRITAIN: 'Nouvelle-Bretagne occidentale',
	STATE_WESTERN_HIGHLANDS: 'Hautes-Terres occidentales',
	STATE_ALTO_PARAGUAY: 'Alto Paraguay',
	STATE_ALTO_PARANA: 'Alto Paranà',
	STATE_AMAMBAY: 'Amambay',
	STATE_ASUNCION: 'Asuncion',
	STATE_BOQUERON: 'Boquerón',
	STATE_CAAGUAZU: 'Caaguazú',
	STATE_CAAZAPA: 'Caazapá',
	STATE_CANENDIYU: 'Canindeyú',
	STATE_CONCEPCION: 'Concepción',
	STATE_CORDILLERA: 'Cordillera',
	STATE_GUAIRA: 'Guairá',
	STATE_ITAPUA: 'Itapúa',
	STATE_NEEMBUCU: 'Ñeembucú',
	STATE_PARAGUARI: 'Paraguarí',
	STATE_PRESIDENTE_HAYES: 'Presidente Hayes',
	STATE_SAN_PEDRO: 'San Pedro',
	STATE_ANCASH: 'Ancash',
	STATE_APURIMAC: 'Apurimac',
	STATE_AREQUIPA: 'Arequipa',
	STATE_AYACUCHO: 'Ayacucho',
	STATE_CAJAMARCA: 'Cajamarca',
	STATE_CUSCO: 'Cusco',
	STATE_HUANCAVELICA: 'Huancavelica',
	STATE_HUANUCO: 'Huanuco',
	STATE_ICA: 'Ica',
	STATE_JUNIN: 'Junin',
	STATE_LAMBAYEQUE: 'Lambayeque',
	STATE_LIMA_Y_CALLAO: 'Lima et Callao',
	STATE_LORETO: 'Loreto',
	STATE_MADRE_DE_DIOS: 'Madre de Dios',
	STATE_MOQUEGUA: 'Moquegua',
	STATE_PASCO: 'Pasco',
	STATE_PIURA: 'Piura',
	STATE_PUNO: 'Puno',
	STATE_SAN_MARTIN: 'San Martin',
	STATE_TACNA: 'Tacna',
	STATE_TUMBES: 'Tumbes',
	STATE_UCAYALI: 'Ucayali',
	STATE_BATANGAS: 'Batangas',
	STATE_BICOL: 'Bicol',
	STATE_BULACAN: 'Bulacan',
	STATE_CAGAYAN: 'Cagayan',
	STATE_CARAGA: 'Caraga',
	STATE_CENTRAL_LUZON: 'Central Luzon',
	STATE_CENTRAL_MINDANAO: 'Central Mindanao',
	STATE_CENTRAL_VISAYAS: 'Visayas centrales',
	STATE_DAVAO: 'Davao',
	STATE_EASTERN_VISAYAS: 'Visayas orientales',
	STATE_GREATER_METROPOLITAN_AREA: 'Régions métropolitaines',
	STATE_ILOCOS: 'Ilocos',
	STATE_LAGUNA: 'Laguna',
	STATE_LUZON: 'Luzon',
	STATE_MACTAN: 'Mactan',
	STATE_METROPOLITAN_MANILA_AREA: 'Région métropolitaine de Manille',
	STATE_MUSLIM_MINDANAO: 'Mindanao musulman',
	STATE_NORTHERN_MINDANAO: 'Mindanao du Nord',
	STATE_SOUTHERN_MINDANAO: 'Mindanao du Sud',
	STATE_SOUTHERN_TAGALOG: 'Tagalog du Sud',
	STATE_WESTERN_MINDANAO: 'Mindanao de l’Ouest',
	STATE_WESTERN_VISAYAS: 'Visayas occidentales',
	STATE_PITCAIRN_ISLAND: 'Île Pitcairn',
	STATE_BIALE_BLOTA: 'Biale Blota',
	STATE_DOBROSZYCE: 'Dobroszyce',
	STATE_DOLNOSLASKIE: 'Basse-Silésie',
	STATE_DZIEKANOW_LESNY: 'Dziekanów Leśny',
	STATE_HOPOWO: 'Hopowo',
	STATE_KARTUZY: 'Kartuzy',
	STATE_KOSCIAN: 'Kościan',
	STATE_KRAKOW: 'Cracovie',
	STATE_KUJAWSKO_POMORSKIE: 'Cujavie-Poméranie',
	STATE_LODZKIE: 'Lodz',
	STATE_LUBELSKIE: 'Lublin',
	STATE_LUBUSKIE: 'Lubuskie',
	STATE_MALOMICE: 'Małomice',
	STATE_MALOPOLSKIE: 'Petite-Pologne',
	STATE_MAZOWIECKIE: 'Mazovie',
	STATE_MIRKOW: 'Mirków',
	STATE_OPOLSKIE: 'Opole',
	STATE_OSTROWIEC: 'Ostrowiec',
	STATE_PODKARPACKIE: 'Basses-Carpates',
	STATE_PODLASKIE: 'Podlachie',
	STATE_POLSKA: 'Pologne',
	STATE_POMORSKIE: 'Poméranie',
	STATE_POZNAN: 'Poznan',
	STATE_PRUSZKOW: 'Pruszków',
	STATE_RYMANOWSKA: 'Rymanowska',
	STATE_RZESZOW: 'Rzeszów',
	STATE_SLASKIE: 'Silésie',
	STATE_STARE_POLE: 'Stare Pole',
	STATE_SWIETOKRZYSKIE: 'Sainte-Croix',
	STATE_WARMINSKO_MAZURSKIE: 'Varmie-Mazurie',
	STATE_WARSAW: 'Varsovie',
	STATE_WEJHEROWO: 'Wejherowo',
	STATE_WIELKOPOLSKIE: 'Grande-Pologne',
	STATE_WROCLAW: 'Wroclaw',
	STATE_ZACHODNIO_POMORSKIE: 'Poméranie occidentale',
	STATE_ZUKOWO: 'Żukowo',
	STATE_ABRANTES: 'Abrantes',
	STATE_ACORES: 'Açores',
	STATE_ALENTEJO: 'Alentejo',
	STATE_ALGARVE: 'Algarve',
	STATE_BRAGA: 'Braga',
	STATE_CENTRO: 'Centre',
	STATE_DISTRITO_DE_LEIRIA: 'District de Leiria',
	STATE_DISTRITO_DE_VIANA_DO_CASTELO: 'District de Viana do Castelo',
	STATE_DISTRITO_DE_VILA_REAL: 'District de Vila Real',
	STATE_DISTRITO_DO_PORTO: 'District de Porto',
	STATE_LISBOA_E_VALE_DO_TEJO: 'Lisbonne-et-Val-de-Tage',
	STATE_MADEIRA: 'Madère',
	STATE_NORTE: 'Nord',
	STATE_PAIVAS: 'Paiva',
	STATE_ARECIBO: 'Arecibo',
	STATE_BAYAMON: 'Bayamón',
	STATE_CAROLINA: 'Carolina',
	STATE_FLORIDA: 'Florida',
	STATE_GUAYAMA: 'Guayama',
	STATE_HUMACAO: 'Humacao',
	STATE_MAYAGUEZ_AGUADILLA: 'Mayagüez-Aguadilla',
	STATE_PONCE: 'Ponce',
	STATE_SALINAS: 'Salinas',
	STATE_DOHA: 'Doha',
	STATE_JARIAN_AL_BATNAH: 'Jariyan Al Batnah',
	STATE_UMM_SALAL: 'Umm Salal',
	STATE_AD_DAWHAH: 'Ad Dawhah',
	STATE_AL_GHUWAYRIYAH: 'Al Ghuwariyah',
	STATE_AL_JUMAYLIYAH: 'Al Jemailiya',
	STATE_AL_KHAWR: 'Al Khawr',
	STATE_AL_WAKRAH: 'Al Wakrah',
	STATE_AR_RAYYAN: 'Al Rayyan',
	STATE_SAINT_BENOIT: 'Saint-Benoît',
	STATE_SAINT_DENIS: 'Saint-Denis',
	STATE_ALBA: 'Székesfehérvár',
	STATE_ARAD: 'Arad',
	STATE_ARGES: 'Argeș',
	STATE_BACAU: 'Bacău',
	STATE_BIHOR: 'Bihor',
	STATE_BISTRITA_NASAUD: 'Bistrița-Năsăud',
	STATE_BOTOSANI: 'Botoșani',
	STATE_BRAILA: 'Brăila',
	STATE_BRASOV: 'Brașov',
	STATE_BUCURESTI: 'Bucureşti',
	STATE_BUZAU: 'Buzău',
	STATE_CALARASI: 'Calarasi',
	STATE_CARAS_SEVERIN: 'Caraș-Severin',
	STATE_CLUJ: 'Cluj',
	STATE_CONSTANTA: 'Constanța',
	STATE_COVASNA: 'Covasna',
	STATE_DAMBOVITA: 'Dâmbovița',
	STATE_DOLJ: 'Dolj',
	STATE_GALATI: 'Galați',
	STATE_GIURGIU: 'Giurgiu',
	STATE_GORJ: 'Gorj',
	STATE_HARGHITA: 'Harghita',
	STATE_HUNEDOARA: 'Hunedoara',
	STATE_IALOMITA: 'Ialomita',
	STATE_IASI: 'Iași',
	STATE_ILFOV: 'Ilfov',
	STATE_MARAMURES: 'Maramureș',
	STATE_MEHEDINTI: 'Mehedinți',
	STATE_MURES: 'Mureș',
	STATE_NEAMT: 'Neamț',
	STATE_OLT: 'Olt',
	STATE_PRAHOVA: 'Prahova',
	STATE_SALAJ: 'Sălaj',
	STATE_SATU_MARE: 'Satu Mare',
	STATE_SIBIU: 'Sibiu',
	STATE_SONDELOR: 'Sondelor',
	STATE_SUCEAVA: 'Suceava',
	STATE_TELEORMAN: 'Teleorman',
	STATE_TIMIS: 'Timiș',
	STATE_TULCEA: 'Tulcea',
	STATE_VALCEA: 'Vâlcea',
	STATE_VASLUI: 'Vaslui',
	STATE_VRANCEA: 'Vrancea',
	STATE_ADYGEJA: 'Adyguée',
	STATE_AGA: 'Aga',
	STATE_ALANIJA: 'Alanya',
	STATE_ALTAJ: 'Altaï',
	STATE_AMUR: 'Amour',
	STATE_ARHANGELSK: 'Arkhangelsk',
	STATE_ASTRAHAN: 'Astrakhan',
	STATE_BASHKORTOSTAN: 'Bachkirie',
	STATE_BELGOROD: 'Belgorod',
	STATE_BRJANSK: 'Briansk',
	STATE_BURJATIJA: 'Bouriatie',
	STATE_CHECHENIJA: 'Tchétchénie',
	STATE_CHELJABINSK: 'Tcheliabinsk',
	STATE_CHITA: 'Tchita',
	STATE_CHUKOTKA: 'Tchoukotka',
	STATE_CHUVASHIJA: 'Tchouvachie',
	STATE_DAGESTAN: 'Daghestan',
	STATE_EVENKIJA: 'Evenkija',
	STATE_GORNO_ALTAJ: 'Gorno-Altaïsk',
	STATE_HABAROVSK: 'Khabarovsk',
	STATE_HAKASIJA: 'Khakassie',
	STATE_HANTY_MANSIJA: 'Hanty-Mansija',
	STATE_INGUSETIJA: 'Ingouchie',
	STATE_IRKUTSK: 'Irkoutsk',
	STATE_IVANOVO: 'Ivanovo',
	STATE_JAMALO_NENETS: 'Yamal-Nénètses',
	STATE_JAROSLAVL: 'Iaroslavl',
	STATE_JEVREJ: 'Oblast autonome juif',
	STATE_KABARDINO_BALKARIJA: 'Kabardino-Balkarie',
	STATE_KALININGRAD: 'Kaliningrad',
	STATE_KALMYKIJA: 'Kalmoukie',
	STATE_KALUGA: 'Kalouga',
	STATE_KAMCHATKA: 'Kamtchatka',
	STATE_KARACHAJ_CHERKESSIJA: 'Karatchaïévo-Tcherkessie',
	STATE_KARELIJA: 'Carélie',
	STATE_KEMEROVO: 'Kemerovo',
	STATE_KHABAROVSKIY_KRAY: 'Kraï de Khabarovsk',
	STATE_KIROV: 'Kirov',
	STATE_KOMI: 'Komi',
	STATE_KOMI_PERMJAKIJA: 'Komi-Permyatsky',
	STATE_KORJAKIJA: 'Koriakie',
	STATE_KOSTROMA: 'Kostroma',
	STATE_KRASNODAR: 'Krasnodar',
	STATE_KRASNOJARSK: 'Krasnoïarsk',
	STATE_KRASNOYARSKIY_KRAY: 'Kraï de Krasnoïarsk',
	STATE_KURGAN: 'Kourgan',
	STATE_KURSK: 'Koursk',
	STATE_LENINGRAD: 'Léningrad',
	STATE_LIPECK: 'Lipetsk',
	STATE_MAGADAN: 'Magadan',
	STATE_MARIJ_EL: 'Mari El',
	STATE_MORDOVIJA: 'Mordovie',
	STATE_MOSCOW: 'Moscou',
	STATE_MOSKOVSKAJA_OBLAST: 'Oblast de Moscou',
	STATE_MOSKOVSKAYA_OBLAST: 'Oblast de Moscou',
	STATE_MOSKVA: 'Moscou',
	STATE_MURMANSK: 'Mourmansk',
	STATE_NENETS: 'Nénètses',
	STATE_NIZHNIJ_NOVGOROD: 'Nijni Novgorod',
	STATE_NOVGOROD: 'Novgorod',
	STATE_NOVOKUSNEZK: 'Novokouznetsk',
	STATE_NOVOSIBIRSK: 'Novossibirsk',
	STATE_OMSK: 'Omsk',
	STATE_ORENBURG: 'Orenbourg',
	STATE_ORJOL: 'Orel',
	STATE_PENZA: 'Penza',
	STATE_PERM: 'Perm',
	STATE_PRIMORJE: 'Primorié',
	STATE_PSKOV: 'Pskov',
	STATE_PSKOVSKAYA_OBLAST: 'Oblast de Pskov',
	STATE_RJAZAN: 'Riazan',
	STATE_ROSTOV: 'Rostov',
	STATE_SAHA: 'Sakha',
	STATE_SAHALIN: 'Sakhaline',
	STATE_SAMARA: 'Samara',
	STATE_SAMARSKAYA: 'Samara',
	STATE_SANKT_PETERBURG: 'Saint-Pétersbourg',
	STATE_SARATOV: 'Saratov',
	STATE_SMOLENSK: 'Smolensk',
	STATE_STAVROPOL: 'Stavropol',
	STATE_SVERDLOVSK: 'Sverdlovsk',
	STATE_TAJMYRIJA: 'Tajmyrija',
	STATE_TAMBOV: 'Tambov',
	STATE_TATARSTAN: 'Tatarstan',
	STATE_TJUMEN: 'Tioumen',
	STATE_TOMSK: 'Tomsk',
	STATE_TULA: 'Toula',
	STATE_TVER: 'Tver',
	STATE_TYVA: 'Touva',
	STATE_UDMURTIJA: 'Oudmourtie',
	STATE_ULJANOVSK: 'Oulianovsk',
	STATE_ULYANOVSKAYA_OBLAST: 'Oblast d’Oulianovsk',
	STATE_UST_ORDA: 'Ust-Orda',
	STATE_VLADIMIR: 'Vladimir',
	STATE_VOLGOGRAD: 'Volgograd',
	STATE_VOLOGDA: 'Vologda',
	STATE_VORONEZH: 'Voronej',
	STATE_BUTARE: 'Butare',
	STATE_BYUMBA: 'Byumba',
	STATE_CYANGUGU: 'Cyangugu',
	STATE_GIKONGORO: 'Gikongoro',
	STATE_GISENYI: 'Gisenyi',
	STATE_GITARAMA: 'Gitarama',
	STATE_KIBUNGO: 'Kibungo',
	STATE_KIBUYE: 'Kibuye',
	STATE_KIGALI_NGALI: 'Kigali rural',
	STATE_RUHENGERI: 'Ruhengeri',
	STATE_ASCENSION: 'Ascension',
	STATE_GOUGH_ISLAND: 'Île Gough',
	STATE_SAINT_HELENA: 'Sainte-Hélène',
	STATE_TRISTAN_DA_CUNHA: 'Tristan da Cunha',
	STATE_CHRIST_CHURCH_NICHOLA_TOWN: 'Christ Church Nichola Town',
	STATE_SAINT_ANNE_SANDY_POINT: 'Sainte-Anne Sandy Point ',
	STATE_SAINT_GEORGE_BASSETERRE: ' Saint-George Basseterre',
	STATE_SAINT_GEORGE_GINGERLAND: 'Saint-Georges Gingerland',
	STATE_SAINT_JAMES_WINDWARD: 'Saint-James Windward',
	STATE_SAINT_JOHN_CAPESTERRE: 'Saint-John Capisterre',
	STATE_SAINT_JOHN_FIGTREE: 'Saint-John Figtree',
	STATE_SAINT_MARY_CAYON: 'Sainte-Mary Cayon',
	STATE_SAINT_PAUL_CAPESTERRE: 'Saint-Paul Capisterre',
	STATE_SAINT_PAUL_CHARLESTOWN: 'Saint-Paul Charlestown',
	STATE_SAINT_PETER_BASSETERRE: 'Saint-Peter Basseterre',
	STATE_SAINT_THOMAS_LOWLAND: 'Saint-Thomas Lowland',
	STATE_SAINT_THOMAS_MIDDLE_ISLAND: 'Saint-Thomas Middle Island',
	STATE_TRINITY_PALMETTO_POINT: 'Trinity Palmetto Point',
	STATE_ANSE_LA_RAYE: 'Anse-la-Raye',
	STATE_CANARIES: 'Canaries',
	STATE_CASTRIES: 'Castries',
	STATE_CHOISEUL: 'Choiseul',
	STATE_DENNERY: 'Dennery',
	STATE_GROS_INLET: 'Gros Inlet',
	STATE_LABORIE: 'Laborie',
	STATE_MICOUD: 'Micoud',
	STATE_SOUFRIERE: 'Soufrière',
	STATE_VIEUX_FORT: 'Vieux Fort',
	STATE_MIQUELON_LANGLADE: 'Miquelon-Langlade',
	STATE_CHARLOTTE: 'Charlotte',
	STATE_GRENADINES: 'Grenadines',
	STATE_A__ANA: 'A’ana',
	STATE_AIGA_I_LE_TAI: 'Aiga-i-le-Tai',
	STATE_ATUA: 'Atua',
	STATE_FA__ASALELEAGA: 'Fa’asaleleaga',
	STATE_GAGA__EMAUGA: 'Gaga’emauga',
	STATE_GAGAIFOMAUGA: 'Gagaifomauga',
	STATE_PALAULI: 'Palauli',
	STATE_SATUPA__ITEA: 'Satupa’itea',
	STATE_TUAMASAGA: 'Tuamasaga',
	STATE_VA__A_O_FONOTI: 'Va’a-o-Fonoti',
	STATE_VAISIGANO: 'Vaisigano',
	STATE_ACQUAVIVA: 'Acquaviva',
	STATE_BORGO_MAGGIORE: 'Borgo Maggiore',
	STATE_CHIESANUOVA: 'Chiesanuova',
	STATE_DOMAGNANO: 'Domagnano',
	STATE_FAETANO: 'Faetano',
	STATE_FIORENTINO: 'Fiorentino',
	STATE_MONTEGIARDINO: 'Montegiardino',
	STATE_SAN_MARINO: 'Saint-Marin',
	STATE_SERRAVALLE: 'Serravalle',
	STATE_AGUA_GRANDE: 'Água Grande',
	STATE_CANTAGALO: 'Cantagalo',
	STATE_LEMBA: 'Lembá',
	STATE_LOBATA: 'Lobata',
	STATE_ME_ZOCHI: 'Mé-Zóchi',
	STATE_PAGUE: 'Pague',
	STATE_AL_KHOBAR: 'Al Khobar',
	STATE_ASEER: 'Asir',
	STATE_ASIR: 'Asir',
	STATE_CENTRAL_PROVINCE: 'Province centrale',
	STATE_EASTERN_PROVINCE: 'Province orientale',
	STATE_HA__IL: 'Ha’il',
	STATE_JAWF: 'Al Jawf',
	STATE_JIZAN: 'Jizan',
	STATE_MAKKAH: 'La Mecque',
	STATE_NAJRAN: 'Najran',
	STATE_QASIM: 'Al Qasim',
	STATE_TABUK: 'Tabuk',
	STATE_WESTERN_PROVINCE: 'Province occidentale',
	STATE_AL_BAHAH: 'Al Bahah',
	STATE_AL_HUDUD_ASH_SHAMALIYAH: 'Al-Hudud ach-Chamaliya',
	STATE_AL_MADINAH: 'Médine',
	STATE_AR_RIYAD: 'Riyad',
	STATE_DAKAR: 'Dakar',
	STATE_DIOURBEL: 'Diourbel',
	STATE_FATICK: 'Fatick',
	STATE_KAOLACK: 'Kaolack',
	STATE_KOLDA: 'Kolda',
	STATE_LOUGA: 'Louga',
	STATE_SAINT_LOUIS: 'Saint-Louis',
	STATE_TAMBACOUNDA: 'Tambacounda',
	STATE_THIES: 'Thiès',
	STATE_ZIGUINCHOR: 'Ziguinchor',
	STATE_CENTRAL_SERBIA: 'Serbie centrale',
	STATE_KOSOVO_AND_METOHIJA: 'Kosovo-et-Métochie',
	STATE_VOJVODINA: 'Voïvodine',
	STATE_ANSE_BOILEAU: 'Anse Boileau',
	STATE_ANSE_ROYALE: 'Anse Royale',
	STATE_CASCADE: 'Cascade',
	STATE_TAKAMAKA: 'Takamaka',
	STATE_SINGAPORE: 'Singapour',
	STATE_BANSKOBYSTRICKY: 'Banská Bystrica',
	STATE_BRATISLAVSKY: 'Bratislava',
	STATE_KOSICKY: 'Košice',
	STATE_NITRIANSKY: 'Nitra',
	STATE_PRESOVSKY: 'Prešov',
	STATE_TRENCIANSKY: 'Trenčín',
	STATE_TRNAVSKY: 'Trnava',
	STATE_ZILINSKY: 'Žilina',
	STATE_BENEDIKT: 'Benedikt',
	STATE_GORENJSKA: 'Haute-Carniole',
	STATE_GORISHKA: 'Goriška',
	STATE_JUGOVZHODNA_SLOVENIJA: 'Jugovzhodna Slovenija',
	STATE_KOROSHKA: 'Koroška',
	STATE_NOTRANJSKO_KRASHKA: 'Notranjsko-kraška',
	STATE_OBALNO_KRASHKA: 'Obalno-kraška',
	STATE_OBCINA_DOMZALE: 'Obcina Domzale',
	STATE_OBCINA_VITANJE: 'Obcina Vitanje',
	STATE_OSREDNJESLOVENSKA: 'Osrednjeslovenska',
	STATE_PODRAVSKA: 'Podravska',
	STATE_POMURSKA: 'Pomurska',
	STATE_SAVINJSKA: 'Savinjska',
	STATE_SLOVENIAN_LITTORAL: 'Littoral slovène',
	STATE_SPODNJEPOSAVSKA: 'Spodnjeposavska',
	STATE_ZASAVSKA: 'Zasavska',
	STATE_GUADALCANAL: 'Guadalcanal',
	STATE_ISABEL: 'Isabel',
	STATE_MAKIRA_AND_ULAWA: 'Makira et Ulawa',
	STATE_MALAITA: 'Malaita',
	STATE_RENNELL_AND_BELLONA: 'Rennell et Bellona',
	STATE_TEMOTU: 'Temotu',
	STATE_AWDAL: 'Awdal',
	STATE_BAKOL: 'Bakol',
	STATE_BANADIR: 'Banadir',
	STATE_BAY: 'Bay',
	STATE_GALGUDUG: 'Galgudug',
	STATE_GEDO: 'Gedo',
	STATE_HIRAN: 'Hiran',
	STATE_JUBBADA_HOSE: 'Jubbada Hose',
	STATE_JUBBADHA_DEXE: 'Jubbadha Dexe',
	STATE_MUDUG: 'Mudug',
	STATE_NUGAL: 'Nugal',
	STATE_SANAG: 'Sanag',
	STATE_SHABELLAHA_DHEXE: 'Shabellaha Dhexe',
	STATE_SHABELLAHA_HOSE: 'Shabellaha Hose',
	STATE_TOGDHER: 'Togdher',
	STATE_WOQOYI_GALBED: 'Woqoyi Galbed',
	STATE_EASTERN_CAPE: 'Le Cap-Oriental',
	STATE_FREE_STATE: 'État-Libre',
	STATE_GAUTENG: 'Gauteng',
	STATE_KEMPTON_PARK: 'Kempton Park',
	STATE_KRAMERVILLE: 'Kramerville',
	STATE_KWAZULU_NATAL: 'KwaZulu-Natal',
	STATE_LIMPOPO: 'Limpopo',
	STATE_MPUMALANGA: 'Mpumalanga',
	STATE_NORTH_WEST: 'Nord-Ouest',
	STATE_NORTHERN_CAPE: 'Cap-Nord',
	STATE_PAROW: 'Parow',
	STATE_TABLE_VIEW: 'Table View',
	STATE_UMTENTWENI: 'Umtentweni',
	STATE_WESTERN_CAPE: 'Cap-Occidental',
	STATE_CENTRAL_EQUATORIA: 'Équatoria-Central',
	STATE_A_CORUNA: 'A Coruna',
	STATE_ALACANT: 'Alicante',
	STATE_ALAVA: 'Alava',
	STATE_ALBACETE: 'Albacete',
	STATE_ALMERIA: 'Almería',
	STATE_ANDALUCIA: 'Andalousie',
	STATE_ASTURIAS: 'Asturies',
	STATE_AVILA: 'Ávila',
	STATE_BADAJOZ: 'Badajoz',
	STATE_BALEARS: 'Îles Baléares',
	STATE_BARCELONA: 'Barcelone',
	STATE_BERTAMIRANS: 'Bertamiráns',
	STATE_BISCAY: 'Biscaye',
	STATE_BURGOS: 'Burgos',
	STATE_CACERES: 'Cáceres',
	STATE_CADIZ: 'Cadix',
	STATE_CANTABRIA: 'Cantabrie',
	STATE_CASTELLO: 'Castellón',
	STATE_CATALUNYA: 'Catalogne',
	STATE_CEUTA: 'Ceuta',
	STATE_CIUDAD_REAL: 'Ciudad Real',
	STATE_COMUNIDAD_AUTONOMA_DE_CANARIAS: 'Communauté autonome des Canaries',
	STATE_COMUNIDAD_AUTONOMA_DE_CATALUNA: 'Communauté autonome de Catalogne',
	STATE_COMUNIDAD_AUTONOMA_DE_GALICIA: 'Communauté autonome de Galice',
	STATE_COMUNIDAD_AUTONOMA_DE_LAS_ISLA: 'Communauté autonome des Îles',
	STATE_COMUNIDAD_AUTONOMA_DEL_PRINCIP: 'Communauté autonome de Príncipe',
	STATE_COMUNIDAD_VALENCIANA: 'Communauté valencienne',
	STATE_CUENCA: 'Cuenca',
	STATE_GIPUZKOA: 'Gipuscoa',
	STATE_GIRONA: 'Gérone',
	STATE_GUADALAJARA: 'Guadalajara',
	STATE_GUIPUZCOA: 'Gipuscoa',
	STATE_HUELVA: 'Huelva',
	STATE_HUESCA: 'Huesca',
	STATE_JAEN: 'Jaén',
	STATE_LAS_PALMAS: 'Las Palmas',
	STATE_LERIDA: 'Lérida',
	STATE_LLEIDA: 'Lleida',
	STATE_LUGO: 'Lugo',
	STATE_MADRID: 'Madrid',
	STATE_MALAGA: 'Malaga',
	STATE_MELILLA: 'Melilla',
	STATE_MURCIA: 'Murcie',
	STATE_NAVARRA: 'Navarre',
	STATE_OURENSE: 'Ourense',
	STATE_PAIS_VASCO: 'Pays basque',
	STATE_PALENCIA: 'Palencia',
	STATE_PONTEVEDRA: 'Pontevedra',
	STATE_SALAMANCA: 'Salamanque',
	STATE_SANTA_CRUZ_DE_TENERIFE: 'Santa Cruz de Tenerife',
	STATE_SEGOVIA: 'Ségovie',
	STATE_SEVILLA: 'Séville',
	STATE_SORIA: 'Soria',
	STATE_TARRAGONA: 'Tarragone',
	STATE_TENERIFE: 'Ténérife',
	STATE_TERUEL: 'Teruel',
	STATE_VALENCIA: 'Valence',
	STATE_VALLADOLID: 'Valladolid',
	STATE_VIZCAYA: 'Vizcaya',
	STATE_ZAMORA: 'Zamora',
	STATE_ZARAGOZA: 'Saragosse',
	STATE_AMPARAI: 'Ampara',
	STATE_ANURADHAPURAYA: 'Anuradhapuraya',
	STATE_BADULLA: 'Badulla',
	STATE_BORALESGAMUWA: 'Boralesgamuwa',
	STATE_COLOMBO: 'Colombo',
	STATE_GALLA: 'Galla',
	STATE_GAMPAHA: 'Gampaha',
	STATE_HAMBANTOTA: 'Hambantota',
	STATE_KALATURA: 'Kalatura',
	STATE_KEGALLA: 'Kegalla',
	STATE_KILINOCHCHI: 'Kilinochchi',
	STATE_KURUNEGALA: 'Kurunegala',
	STATE_MADAKALPUWA: 'Madakalpuwa',
	STATE_MAHA_NUWARA: 'Maha Nuwara',
	STATE_MALWANA: 'Malwana',
	STATE_MANNARAMA: 'Mannarama',
	STATE_MATALE: 'Matale',
	STATE_MATARA: 'Matara',
	STATE_MONARAGALA: 'Monaragala',
	STATE_MULLAITIVU: 'Mullaitivu',
	STATE_NORTH_EASTERN_PROVINCE: 'Province Nord-orientale',
	STATE_NORTH_WESTERN_PROVINCE: 'Province Nord-occidentale',
	STATE_NUWARA_ELIYA: 'Nuwara Eliya',
	STATE_POLONNARUWA: 'Polonnaruwa',
	STATE_PUTTALAMA: 'Puttalama',
	STATE_RATNAPURAYA: 'Ratnapuraya',
	STATE_SOUTHERN_PROVINCE: 'Province méridionale',
	STATE_TIRIKUNAMALAYA: 'Tirikunamalaya',
	STATE_TUSCANY: 'Tuscany',
	STATE_VAVUNIYAWA: 'Vavuniyawa',
	STATE_YAPANAYA: 'Yapanaya',
	STATE_KADAWATHA: 'Kadawatha',
	STATE_A__ALI_AN_NIL: 'A’ali-an-Nil',
	STATE_BAHR_AL_JABAL: 'Bahr-al-Jabal',
	STATE_GHARB_BAHR_AL_GHAZAL: 'Bahr el-Ghazal occidental',
	STATE_GHARB_DARFUR: 'Darfour occidental',
	STATE_GHARB_KURDUFAN: 'Kordofan occidental',
	STATE_GHARB_AL_ISTIWA__IYAH: 'Gharb-al-Istiwa’iyah',
	STATE_JANUB_DARFUR: 'Darfour du Sud',
	STATE_JANUB_KURDUFAN: 'Kordofan du Sud',
	STATE_JUNQALI: 'Jonglei',
	STATE_KASSALA: 'Kassala',
	STATE_NAHR_AN_NIL: 'Nahr-an-Nil',
	STATE_SHAMAL_BAHR_AL_GHAZAL: 'Bahr el-Ghazal septentrional',
	STATE_SHAMAL_DARFUR: 'Darfour du Nord',
	STATE_SHAMAL_KURDUFAN: 'Kordofan du Nord',
	STATE_SHARQ_AL_ISTIWA__IYAH: 'Sharq-al-Istiwa’iyah',
	STATE_SINNAR: 'Sinnar',
	STATE_WARAB: 'Warab',
  STATE_WILAYAT_AL_KHARTUM: 'Khartoum',
	STATE_AL_BUHAYRAT: 'Lacs',
	STATE_AL_JAZIRAH: 'Al Jazirah',
	STATE_AL_KHARTUM: 'Khartoum',
	STATE_AL_QADARIF: 'Al Qadarif',
	STATE_AL_WAHDAH: 'Unité',
	STATE_AN_NIL_AL_ABYAD: 'Nil Blanc',
	STATE_AN_NIL_AL_AZRAQ: 'Nil Bleu',
	STATE_ASH_SHAMALIYAH: 'Ash Shamaliyah',
	STATE_BROKOPONDO: 'Brokopondo',
	STATE_COMMEWIJNE: 'Commewijne',
	STATE_CORONIE: 'Coronie',
	STATE_MAROWIJNE: 'Marowijne',
	STATE_NICKERIE: 'Nickerie',
	STATE_PARAMARIBO: 'Paramaribo',
	STATE_SARAMACCA: 'Saramacca',
	STATE_WANICA: 'Wanica',
	STATE_SVALBARD: 'Svalbard',
	STATE_HHOHHO: 'Hhohho',
	STATE_LUBOMBO: 'Lubombo',
	STATE_MANZINI: 'Manzini',
	STATE_SHISELWENI: 'Shiselweni',
	STATE_ALVSBORGS_LAN: 'Alvsborgs Lan',
	STATE_ANGERMANLAND: 'Angermanland',
	STATE_BLEKINGE: 'Blekinge',
	STATE_BOHUSLAN: 'Bohuslan',
	STATE_DALARNA: 'Dalarna',
	STATE_GAVLEBORG: 'Gavleborg',
	STATE_GOTLAND: 'Gotland',
	STATE_HALLAND: 'Halland',
	STATE_JAMTLAND: 'Jamtland',
	STATE_JONKOPING: 'Jonkoping',
	STATE_KALMAR: 'Kalmar',
	STATE_KRISTIANSTADS: 'Kristianstads',
	STATE_KRONOBERG: 'Kronoberg',
	STATE_NORRBOTTEN: 'Norrbotten',
	STATE_OREBRO: 'Orebro',
	STATE_OSTERGOTLAND: 'Ostergotland',
	STATE_SALTSJO_BOO: 'Saltsjo-Boo',
	STATE_SKANE: 'Skane',
	STATE_SMALAND: 'Smaland',
	STATE_SODERMANLAND: 'Sodermanland',
	STATE_STOCKHOLM: 'Stockholm',
	STATE_UPPSALA: 'Uppsala',
	STATE_VARMLAND: 'Varmland',
	STATE_VASTERBOTTEN: 'Vasterbotten',
	STATE_VASTERGOTLAND: 'Vastergotland',
	STATE_VASTERNORRLAND: 'Vasternorrland',
	STATE_VASTMANLAND: 'Vastmanland',
	STATE_VASTRA_GOTALAND: 'Vastra Gotaland',
	STATE_AARGAU: 'Argovie',
	STATE_APPENZELL_INNER_RHODEN: 'Appenzell Rhodes-Intérieures',
	STATE_APPENZELL_AUSSER_RHODEN: 'Appenzell Rhodes-Extérieures',
	STATE_BASEL_LANDSCHAFT: 'Bâle-Campagne',
	STATE_BASEL_STADT: 'Ville de Bâle',
	STATE_BERN: 'Berne',
	STATE_CANTON_TICINO: 'Canton du Tessin',
	STATE_FRIBOURG: 'Fribourg',
	STATE_GENEVE: 'Genève',
	STATE_GLARUS: 'Glaris',
	STATE_GRAUBUNDEN: 'Grisons',
	STATE_HEERBRUGG: 'Heerbrugg',
	STATE_KANTON_AARGAU: 'Canton d’Argovie',
	STATE_LUZERN: 'Lucerne',
	STATE_MORBIO_INFERIORE: 'Morbio Inferiore',
	STATE_MUHEN: 'Muhen',
	STATE_NEUCHATEL: 'Neuchâtel',
	STATE_NIDWALDEN: 'Nidwald',
	STATE_OBWALDEN: 'Obwald',
	STATE_SANKT_GALLEN: 'Saint-Gall',
	STATE_SCHAFFHAUSEN: 'Schaffhouse',
	STATE_SCHWYZ: 'Schwytz',
	STATE_SOLOTHURN: 'Soleure',
	STATE_THURGAU: 'Thurgovie',
	STATE_TICINO: 'Tessin',
	STATE_URI: 'Uri',
	STATE_VALAIS: 'Valais',
	STATE_VAUD: 'Vaud',
	STATE_VAUFFELIN: 'Vauffelin',
	STATE_ZUG: 'Zoug',
	STATE_ZURICH: 'Zurich',
	STATE_ALEPPO: 'Alep',
	STATE_DAR__A: 'Deraa',
	STATE_DAYR_AZ_ZAWR: 'Deir ez-Zur',
	STATE_DIMASHQ: 'Damas',
	STATE_HALAB: 'Alep',
	STATE_HAMAH: 'Hama',
	STATE_HIMS: 'Homs',
	STATE_IDLIB: 'Idlib',
	STATE_MADINAT_DIMASHQ: 'Ville de Damas',
	STATE_TARTUS: 'Tartous',
	STATE_AL_HASAKAH: 'Hassaké',
	STATE_AL_LADHIQIYAH: 'Lattaquié',
	STATE_AL_QUNAYTIRAH: 'Quneitra',
	STATE_AR_RAQQAH: 'Raqqa',
	STATE_AS_SUWAYDA: 'Soueïda',
	STATE_CHANGHWA: 'Changhua',
	STATE_CHIAYI_HSIEN: 'Chiayi Hsien',
	STATE_CHIAYI_SHIH: 'Chiayi Shih',
	STATE_EASTERN_TAIPEI: 'Taipei Est',
	STATE_HSINCHU_HSIEN: 'Hsinchu Hsien',
	STATE_HSINCHU_SHIH: 'Hsinchu Shih',
	STATE_HUALIEN: 'Hualien',
	STATE_ILAN: 'Ilan',
	STATE_KAOHSIUNG_HSIEN: 'Kaohsiung Hsien',
	STATE_KAOHSIUNG_SHIH: 'Kaohsiung Shih',
	STATE_KEELUNG_SHIH: 'Keelung Shih',
	STATE_KINMEN: 'Kinmen',
	STATE_MIAOLI: 'Miaoli',
	STATE_NANTOU: 'Nantou',
	STATE_NORTHERN_TAIWAN: 'Nord de Taïwan',
	STATE_PENGHU: 'Penghu',
	STATE_PINGTUNG: 'Pingtung',
	STATE_TAICHUNG: 'Taichung',
	STATE_TAICHUNG_HSIEN: 'Taichung Hsien',
	STATE_TAICHUNG_SHIH: 'Taichung Shih',
	STATE_TAINAN_HSIEN: 'Tainan Hsien',
	STATE_TAINAN_SHIH: 'Tainan Shih',
	STATE_TAIPEI_HSIEN: 'Taipei Hsien',
	STATE_TAIPEI_SHIH___TAIPEI_HSIEN: 'Taipei Shih / Taipei Hsien',
	STATE_TAITUNG: 'Taitung',
	STATE_TAOYUAN: 'Taoyuan',
	STATE_YILAN: 'Yilan',
	STATE_YUN_LIN_HSIEN: 'Yun-Lin Hsien',
	STATE_YUNLIN: 'Yunlin',
	STATE_DUSHANBE: 'Dushanbe',
	STATE_GORNO_BADAKHSHAN: 'Gorno-Badakhshan',
	STATE_KAROTEGIN: 'Karotegin',
	STATE_KHATLON: 'Khatlon',
	STATE_SUGHD: 'Sughd',
	STATE_ARUSHA: 'Arusha',
	STATE_DAR_ES_SALAAM: 'Dar es Salaam',
	STATE_DODOMA: 'Dodoma',
	STATE_IRINGA: 'Iringa',
	STATE_KAGERA: 'Kagera',
	STATE_KIGOMA: 'Kigoma',
	STATE_KILIMANJARO: 'Kilimandjaro',
	STATE_LINDI: 'Lindi',
	STATE_MARA: 'Mara',
	STATE_MBEYA: 'Mbeya',
	STATE_MOROGORO: 'Morogoro',
	STATE_MTWARA: 'Mtwara',
	STATE_PWANI: 'Pwani',
	STATE_RUKWA: 'Rukwa',
	STATE_RUVUMA: 'Ruvuma',
	STATE_SHINYANGA: 'Shinyanga',
	STATE_SINGIDA: 'Singida',
	STATE_TABORA: 'Tabora',
	STATE_TANGA: 'Tanga',
	STATE_ZANZIBAR_AND_PEMBA: 'Zanzibar et Pemba',
	STATE_AMNAT_CHAROEN: 'Amnat Charoen',
	STATE_ANG_THONG: 'Ang Thong',
	STATE_BANGKOK: 'Bangkok',
	STATE_BURI_RAM: 'Buri Ram',
	STATE_CHACHOENGSAO: 'Chachoengsao',
	STATE_CHAI_NAT: 'Chai Nat',
	STATE_CHAIYAPHUM: 'Chaiyaphum',
	STATE_CHANGWAT_CHAIYAPHUM: 'Changwat Chaiyaphum',
	STATE_CHANTHABURI: 'Chanthaburi',
	STATE_CHIANG_MAI: 'Chiang Mai',
	STATE_CHIANG_RAI: 'Chiang Rai',
	STATE_CHON_BURI: 'Chon Buri',
	STATE_CHUMPHON: 'Chumphon',
	STATE_KALASIN: 'Kalasin',
	STATE_KAMPHAENG_PHET: 'Kamphaeng Phet',
	STATE_KANCHANABURI: 'Kanchanaburi',
	STATE_KHON_KAEN: 'Khon Kaen',
	STATE_KRABI: 'Krabi',
	STATE_KRUNG_THEP: 'Krung Thep',
	STATE_LAMPANG: 'Lampang',
	STATE_LAMPHUN: 'Lamphun',
	STATE_LOEI: 'Loei',
	STATE_LOP_BURI: 'Lop Buri',
	STATE_MAE_HONG_SON: 'Mae Hong Son',
	STATE_MAHA_SARAKHAM: 'Maha Sarakham',
	STATE_MUKDAHAN: 'Mukdahan',
	STATE_NAKHON_NAYOK: 'Nakhon Nayok',
	STATE_NAKHON_PATHOM: 'Nakhon Pathom',
	STATE_NAKHON_PHANOM: 'Nakhon Phanom',
	STATE_NAKHON_RATCHASIMA: 'Nakhon Ratchasima',
	STATE_NAKHON_SAWAN: 'Nakhon Sawan',
	STATE_NAKHON_SI_THAMMARAT: 'Nakhon Si Thammarat',
	STATE_NAN: 'Nan',
	STATE_NARATHIWAT: 'Narathiwat',
	STATE_NONG_BUA_LAM_PHU: 'Nong Bua Lam Phu',
	STATE_NONG_KHAI: 'Nong Khai',
	STATE_NONTHABURI: 'Nonthaburi',
	STATE_PATHUM_THANI: 'Pathum Thani',
	STATE_PATTANI: 'Pattani',
	STATE_PHANGNGA: 'Phangnga',
	STATE_PHATTHALUNG: 'Phatthalung',
	STATE_PHAYAO: 'Phayao',
	STATE_PHETCHABUN: 'Phetchabun',
	STATE_PHETCHABURI: 'Phetchaburi',
	STATE_PHICHIT: 'Phichit',
	STATE_PHITSANULOK: 'Phitsanulok',
	STATE_PHRA_NAKHON_SI_AYUTTHAYA: 'Phra Nakhon Si Ayutthaya',
	STATE_PHRAE: 'Phrae',
	STATE_PHUKET: 'Phuket',
	STATE_PRACHIN_BURI: 'Prachin Buri',
	STATE_PRACHUAP_KHIRI_KHAN: 'Prachuap Khiri Khan',
	STATE_RANONG: 'Ranong',
	STATE_RATCHABURI: 'Ratchaburi',
	STATE_RAYONG: 'Rayong',
	STATE_ROI_ET: 'Roi Et',
	STATE_SA_KAEO: 'Sa Kaeo',
	STATE_SAKON_NAKHON: 'Sakon Nakhon',
	STATE_SAMUT_PRAKAN: 'Samut Prakan',
	STATE_SAMUT_SAKHON: 'Samut Sakhon',
	STATE_SAMUT_SONGKHRAN: 'Samut Songkhran',
	STATE_SARABURI: 'Saraburi',
	STATE_SATUN: 'Satun',
	STATE_SI_SA_KET: 'Si Sa Ket',
	STATE_SING_BURI: 'Sing Buri',
	STATE_SONGKHLA: 'Songkhla',
	STATE_SUKHOTHAI: 'Sukhothai',
	STATE_SUPHAN_BURI: 'Suphan Buri',
	STATE_SURAT_THANI: 'Surat Thani',
	STATE_SURIN: 'Surin',
	STATE_TAK: 'Tak',
	STATE_TRANG: 'Trang',
	STATE_TRAT: 'Trat',
	STATE_UBON_RATCHATHANI: 'Ubon Ratchathani',
	STATE_UDON_THANI: 'Udon Thani',
	STATE_UTHAI_THANI: 'Uthai Thani',
	STATE_UTTARADIT: 'Uttaradit',
	STATE_YALA: 'Yala',
	STATE_YASOTHON: 'Yasothon',
	STATE_KARA: 'Kara',
	STATE_MARITIME: 'Maritime',
	STATE_ATAFU: 'Atafu',
	STATE_FAKAOFO: 'Fakaofo',
	STATE_NUKUNONU: 'Nukunonu',
	STATE_EUA: 'Eua',
	STATE_HA__APAI: 'Ha’apai',
	STATE_NIUAS: 'Niuas',
	STATE_TONGATAPU: 'Tongatapu',
	STATE_VAVA__U: 'Vava’u',
	STATE_ARIMA_TUNAPUNA_PIARCO: 'Arima-Tunapuna-Piarco',
	STATE_CARONI: 'Caroni',
	STATE_CHAGUANAS: 'Chaguanas',
	STATE_COUVA_TABAQUITE_TALPARO: 'Couva-Tabaquite-Talparo',
	STATE_DIEGO_MARTIN: 'Diego Martin',
	STATE_GLENCOE: 'Glencoe',
	STATE_PENAL_DEBE: 'Penal Debe',
	STATE_POINT_FORTIN: 'Point Fortin',
	STATE_PORT_OF_SPAIN: 'Port d’Espagne',
	STATE_PRINCES_TOWN: 'Princes Town',
	STATE_SAN_FERNANDO: 'San Fernando',
	STATE_SANGRE_GRANDE: 'Sangre Grande',
	STATE_SIPARIA: 'Siparia',
	STATE_TOBAGO: 'Tobago',
	STATE_ARYANAH: 'Aryanah',
	STATE_BAJAH: 'Bajah',
	STATE_BIN___ARUS: 'Bin’Arus',
	STATE_BINZART: 'Binzart',
	STATE_GOUVERNORAT_DE_ARIANA: 'Gouvernorat de l’Ariana',
	STATE_GOUVERNORAT_DE_NABEUL: 'Gouvernorat de Nabeul',
	STATE_GOUVERNORAT_DE_SOUSSE: 'Gouvernorat de Sousse',
	STATE_HAMMAMET_YASMINE: 'Hammamet Yasmine',
	STATE_JUNDUBAH: 'Jundubah',
	STATE_MADANIYIN: 'Madaniyin',
	STATE_MANUBAH: 'Manubah',
	STATE_MONASTIR: 'Monastir',
	STATE_NABUL: 'Nabul',
	STATE_QABIS: 'Qabis',
	STATE_QAFSAH: 'Qafsah',
	STATE_QIBILI: 'Qibili',
	STATE_SAFAQIS: 'Safaqis',
	STATE_SFAX: 'Sfax',
	STATE_SIDI_BU_ZAYD: 'Sidi Bu Zayd',
	STATE_SILYANAH: 'Silyanah',
	STATE_SUSAH: 'Susah',
	STATE_TATAWIN: 'Tatawin',
	STATE_TAWZAR: 'Tawzar',
	STATE_TUNIS: 'Tunis',
	STATE_ZAGHWAN: 'Zaghwan',
	STATE_AL_KAF: 'al-Kaf',
	STATE_AL_MAHDIYAH: 'al-Mahdiyah',
	STATE_AL_MUNASTIR: 'al-Munastir',
	STATE_AL_QASRAYN: 'al-Qasrayn',
	STATE_AL_QAYRAWAN: 'al-Qayrawan',
	STATE_ADANA: 'Adana',
	STATE_ADIYAMAN: 'Adiyaman',
	STATE_AFYON: 'Afyon',
	STATE_AGRI: 'Agri',
	STATE_AKSARAY: 'Aksaray',
	STATE_AMASYA: 'Amasya',
	STATE_ANKARA: 'Ankara',
	STATE_ANTALYA: 'Antalya',
	STATE_ARDAHAN: 'Ardahan',
	STATE_ARTVIN: 'Artvin',
	STATE_AYDIN: 'Aydin',
	STATE_BALIKESIR: 'Balikesir',
	STATE_BARTIN: 'Bartin',
	STATE_BATMAN: 'Batman',
	STATE_BAYBURT: 'Bayburt',
	STATE_BILECIK: 'Bilecik',
	STATE_BINGOL: 'Bingol',
	STATE_BITLIS: 'Bitlis',
	STATE_BOLU: 'Bolu',
	STATE_BURDUR: 'Burdur',
	STATE_BURSA: 'Bursa',
	STATE_CANAKKALE: 'Canakkale',
	STATE_CANKIRI: 'Cankiri',
	STATE_CORUM: 'Corum',
	STATE_DENIZLI: 'Denizli',
	STATE_DIYARBAKIR: 'Diyarbakir',
	STATE_DUZCE: 'Duzce',
	STATE_EDIRNE: 'Edirne',
	STATE_ELAZIG: 'Elazig',
	STATE_ERZINCAN: 'Erzincan',
	STATE_ERZURUM: 'Erzurum',
	STATE_ESKISEHIR: 'Eskisehir',
	STATE_GAZIANTEP: 'Gaziantep',
	STATE_GIRESUN: 'Giresun',
	STATE_GUMUSHANE: 'Gumushane',
	STATE_HAKKARI: 'Hakkari',
	STATE_HATAY: 'Hatay',
	STATE_ICEL: 'Icel',
	STATE_IGDIR: 'Igdir',
	STATE_ISPARTA: 'Isparta',
	STATE_ISTANBUL: 'Istanbul',
	STATE_IZMIR: 'Izmir',
	STATE_KAHRAMANMARAS: 'Kahramanmaras',
	STATE_KARABUK: 'Karabuk',
	STATE_KARAMAN: 'Karaman',
	STATE_KARS: 'Kars',
	STATE_KARSIYAKA: 'Karsiyaka',
	STATE_KASTAMONU: 'Kastamonu',
	STATE_KAYSERI: 'Kayseri',
	STATE_KILIS: 'Kilis',
	STATE_KIRIKKALE: 'Kirikkale',
	STATE_KIRKLARELI: 'Kirklareli',
	STATE_KIRSEHIR: 'Kirsehir',
	STATE_KOCAELI: 'Kocaeli',
	STATE_KONYA: 'Konya',
	STATE_KUTAHYA: 'Kutahya',
	STATE_LEFKOSA: 'Lefkosa',
	STATE_MALATYA: 'Malatya',
	STATE_MANISA: 'Manisa',
	STATE_MARDIN: 'Mardin',
	STATE_MUGLA: 'Mugla',
	STATE_MUS: 'Mus',
	STATE_NEVSEHIR: 'Nevsehir',
	STATE_NIGDE: 'Nigde',
	STATE_ORDU: 'Ordu',
	STATE_OSMANIYE: 'Osmaniye',
	STATE_RIZE: 'Rize',
	STATE_SAKARYA: 'Sakarya',
	STATE_SAMSUN: 'Samsun',
	STATE_SANLIURFA: 'Sanliurfa',
	STATE_SIIRT: 'Siirt',
	STATE_SINOP: 'Sinop',
	STATE_SIRNAK: 'Sirnak',
	STATE_SIVAS: 'Sivas',
	STATE_TEKIRDAG: 'Tekirdag',
	STATE_TOKAT: 'Tokat',
	STATE_TRABZON: 'Trabzon',
	STATE_TUNCELI: 'Tunceli',
	STATE_USAK: 'Usak',
	STATE_VAN: 'Van',
	STATE_YALOVA: 'Yalova',
	STATE_YOZGAT: 'Yozgat',
	STATE_ZONGULDAK: 'Zonguldak',
	STATE_AHAL: 'Ahal',
	STATE_ASGABAT: 'Asgabat',
	STATE_BALKAN: 'Balkan',
	STATE_DASOGUZ: 'Dasoguz',
	STATE_LEBAP: 'Lebap',
	STATE_MARI: 'Mari',
	STATE_GRAND_TURK: 'Grand Turk',
	STATE_SOUTH_CAICOS_AND_EAST_CAICOS: 'Caicos Sud et Caicos Est',
	STATE_FUNAFUTI: 'Funafuti',
	STATE_NANUMANGA: 'Nanumanga',
	STATE_NANUMEA: 'Nanumea',
	STATE_NIUTAO: 'Niutao',
	STATE_NUI: 'Nui',
	STATE_NUKUFETAU: 'Nukufetau',
	STATE_NUKULAELAE: 'Nukulaelae',
	STATE_VAITUPU: 'Vaitupu',
	STATE_CHERKAS__KA: 'Cherkasy',
	STATE_CHERNIHIVS__KA: 'Chernihiv',
	STATE_CHERNIVETS__KA: 'Chernivtsi',
	STATE_CRIMEA: 'Crimée',
	STATE_DNIPROPETROVSKA: 'Dnipro',
	STATE_DONETS__KA: 'Donetsk',
	STATE_IVANO_FRANKIVS__KA: 'Ivano-Frankivsk',
	STATE_KHARKIV: 'Kharkiv',
	STATE_KHARKOV: 'Kharkiv',
	STATE_KHERSONSKA: 'Kherson',
	STATE_KHMEL__NYTS__KA: 'Khmelnytskyi',
	STATE_KIROVOHRAD: 'Kirovohrad',
	STATE_KRYM: 'Krym',
	STATE_KYYIV: 'Kyyiv',
	STATE_KYYIVS__KA: 'Kiev',
	STATE_L__VIVS__KA: 'Lviv',
	STATE_LUHANS__KA: 'Luhansk',
	STATE_MYKOLAYIVS__KA: 'Mykolaiv',
	STATE_ODES__KA: 'Odessa',
	STATE_ODESSA: 'Odessa',
	STATE_POLTAVS__KA: 'Poltava',
	STATE_RIVNENS__KA: 'Rivne',
	STATE_SEVASTOPOL: 'Sébastopol',
	STATE_SUMS__KA: 'Sumy',
	STATE_TERNOPIL__S__KA: 'Ternopil',
	STATE_VOLYNS__KA: 'Volyn',
	STATE_VYNNYTS__KA: 'Vinnytsia',
	STATE_ZAKARPATS__KA: 'Zakarpattia',
	STATE_ZAPORIZHIA: 'Zaporizhzhia',
	STATE_ZHYTOMYRS__KA: 'Zhytomyr',
	STATE_ABU_ZABI: 'Abu Dhabi',
	STATE_AJMAN: 'Ajman',
	STATE_DUBAI: 'Dubaï',
	STATE_RAS_AL_KHAYMAH: 'Ras al Khaimah',
	STATE_SHARJAH: 'Sharjah',
	STATE_SHARJHA: 'Sharjah',
	STATE_UMM_AL_QAYWAYN: 'Umm al-Quwain',
	STATE_AL_FUJAYRAH: 'Al-Fujairah',
	STATE_ASH_SHARIQAH: 'Ash Shariqah',
	STATE_ABERDEEN: 'Aberdeen',
	STATE_ABERDEENSHIRE: 'Aberdeenshire',
	STATE_ARGYLL: 'Argyll',
	STATE_BEDFORDSHIRE: 'Bedfordshire',
	STATE_BELFAST: 'Belfast',
	STATE_BERKSHIRE: 'Berkshire',
	STATE_BIRMINGHAM: 'Birmingham',
	STATE_BRECHIN: 'Brechin',
	STATE_BRIDGNORTH: 'Bridgnorth',
	STATE_BRISTOL: 'Bristol',
	STATE_BUCKINGHAMSHIRE: 'Buckinghamshire',
	STATE_CAMBRIDGE: 'Cambridge',
	STATE_CAMBRIDGESHIRE: 'Cambridgeshire',
	STATE_CHANNEL_ISLANDS: 'Îles Anglo-Normandes',
	STATE_CHESHIRE: 'Cheshire',
	STATE_CLEVELAND: 'Cleveland',
	STATE_CO_FERMANAGH: 'Fermanagh',
	STATE_CONWY: 'Conwy',
	STATE_CORNWALL: 'Cornouailles',
	STATE_COVENTRY: 'Coventry',
	STATE_CRAVEN_ARMS: 'Craven Arms',
	STATE_CUMBRIA: 'Cumbria',
	STATE_DENBIGHSHIRE: 'Denbighshire',
	STATE_DERBY: 'Derby',
	STATE_DERBYSHIRE: 'Derbyshire',
	STATE_DEVON: 'Devon',
	STATE_DIAL_CODE_DUNGANNON: 'Indicatif d’accès direct Dungannon',
	STATE_DIDCOT: 'Didcot',
	STATE_DORSET: 'Dorset',
	STATE_DUNBARTONSHIRE: 'Dunbartonshire',
	STATE_DURHAM: 'Durham',
	STATE_EAST_DUNBARTONSHIRE: 'Dunbartonshire de l’Est',
	STATE_EAST_LOTHIAN: 'East Lothian',
	STATE_EAST_MIDLANDS: 'Midlands de l’Est',
	STATE_EAST_SUSSEX: 'Sussex de l’Est',
	STATE_EAST_YORKSHIRE: 'Yorkshire de l’Est',
	STATE_ENGLAND: 'Angleterre',
	STATE_ESSEX: 'Essex',
	STATE_FERMANAGH: 'Fermanagh',
	STATE_FIFE: 'Fife',
	STATE_FLINTSHIRE: 'Flintshire',
	STATE_FULHAM: 'Fulham',
	STATE_GAINSBOROUGH: 'Gainsborough',
	STATE_GLOCESTERSHIRE: 'Glocestershire',
	STATE_GWENT: 'Gwent',
	STATE_HAMPSHIRE: 'Hampshire',
	STATE_HANTS: 'Hants',
	STATE_HEREFORDSHIRE: 'Herefordshire',
	STATE_HERTFORDSHIRE: 'Hertfordshire',
	STATE_IRELAND: 'Irlande',
	STATE_ISLE_OF_MAN: 'Île de Man',
	STATE_ISLE_OF_WIGHT: 'Île de Wight',
	STATE_KENFORD: 'Kenford',
	STATE_KENT: 'Kent',
	STATE_KILMARNOCK: 'Kilmarnock',
	STATE_LANARKSHIRE: 'Lanarkshire',
	STATE_LANCASHIRE: 'Lancashire',
	STATE_LEICESTERSHIRE: 'Leicestershire',
	STATE_LINCOLNSHIRE: 'Lincolnshire',
	STATE_LLANYMYNECH: 'Llanymynech',
	STATE_LONDON: 'Londres',
	STATE_LUDLOW: 'Ludlow',
	STATE_MAYFAIR: 'Mayfair',
	STATE_MERSEYSIDE: 'Merseyside',
	STATE_MID_GLAMORGAN: 'Mid Glamorgan',
	TATE_MIDDLESEX: 'Middlesex',
	STATE_MILDENHALL: 'Mildenhall',
	STATE_MONMOUTHSHIRE: 'Monmouthshire',
	STATE_NEWTON_STEWART: 'Newton Stewart',
	STATE_NORFOLK: 'Norfolk',
	STATE_NORTH_HUMBERSIDE: 'North Humberside',
	STATE_NORTH_YORKSHIRE: 'Yorkshire du Nord',
	STATE_NORTHAMPTONSHIRE: 'Northamptonshire',
	STATE_NORTHANTS: 'Northants',
	STATE_NORTHERN_IRELAND: 'Irlande du Nord',
	STATE_NORTHUMBERLAND: 'Northumberland',
	STATE_NOTTINGHAMSHIRE: 'Nottinghamshire',
	STATE_OXFORD: 'Oxford',
	STATE_POWYS: 'Powys',
	STATE_ROOS_SHIRE: 'Roos-shire',
	STATE_SUSSEX: 'SUSSEX',
	STATE_SCOTLAND: 'Écosse',
	STATE_SCOTTISH_BORDERS: 'Frontières écossaises',
	STATE_SHROPSHIRE: 'Shropshire',
	STATE_SOMERSET: 'Somerset',
	STATE_SOUTH_GLAMORGAN: 'Glamorgan du Sud',
	STATE_SOUTH_WALES: 'Galles du Sud',
	STATE_SOUTH_YORKSHIRE: 'Yorkshire du Nord',
	STATE_SOUTHWELL: 'Southwell',
	STATE_STAFFORDSHIRE: 'Staffordshire',
	STATE_STRABANE: 'Strabane',
	STATE_SUFFOLK: 'Suffolk',
	STATE_SURREY: 'Surrey',
	STATE_TWICKENHAM: 'Twickenham',
	STATE_TYNE_AND_WEAR: 'Tyne and Wear',
	STATE_TYRONE: 'Tyrone',
	STATE_UTAH: 'Utah',
	STATE_WALES: 'Pays de Galles',
	STATE_WARWICKSHIRE: 'Warwickshire',
	STATE_WEST_LOTHIAN: 'West Lothian',
	STATE_WEST_MIDLANDS: 'Midlands de l’Ouest',
	STATE_WEST_SUSSEX: 'Sussex de l’Ouest',
	STATE_WEST_YORKSHIRE: 'Yorkshire de l’Ouest',
	STATE_WHISSENDINE: 'Whissendine',
	STATE_WILTSHIRE: 'Wiltshire',
	STATE_WOKINGHAM: 'Wokingham',
	STATE_WORCESTERSHIRE: 'Worcestershire',
	STATE_WREXHAM: 'Wrexham',
	STATE_WURTTEMBERG: 'Wurttemberg',
	STATE_YORKSHIRE: 'Yorkshire',
	STATE_CALIFORNIA: 'Californie',
	STATE_COLORADO: 'Colorado',
	STATE_CONNECTICUT: 'Connecticut',
	STATE_DELAWARE: 'Delaware',
	STATE_DISTRICT_OF_COLUMBIA: 'District de Columbia',
	STATE_HAWAII: 'Hawaï',
	STATE_IDAHO: 'Idaho',
	STATE_ILLINOIS: 'Illinois',
	STATE_INDIANA: 'Indiana',
	STATE_IOWA: 'Iowa',
	STATE_KANSAS: 'Kansas',
	STATE_KENTUCKY: 'Kentucky',
	STATE_LOUISIANA: 'Louisiane',
	STATE_MAINE: 'Maine',
	STATE_MARYLAND: 'Maryland',
	STATE_MASSACHUSETTS: 'Massachusetts',
	STATE_MICHIGAN: 'Michigan',
	STATE_MINNESOTA: 'Minnesota',
	STATE_MISSISSIPPI: 'Mississippi',
	STATE_MISSOURI: 'Missouri',
	STATE_NEBRASKA: 'Nebraska',
	STATE_NEVADA: 'Nevada',
	STATE_NEW_HAMPSHIRE: 'New Hampshire',
	STATE_NEW_JERSEY: 'New Jersey',
	STATE_NEW_MEXICO: 'New Mexico',
	STATE_NORTH_CAROLINA: 'Caroline du Nord',
	STATE_NORTH_DAKOTA: 'Dakota du Nord',
	STATE_OHIO: 'Ohio',
	STATE_OKLAHOMA: 'Oklahoma',
	STATE_OREGON: 'Oregon',
	STATE_PENNSYLVANIA: 'Pennsylvanie',
	STATE_RHODE_ISLAND: 'Rhode Island',
	STATE_SOUTH_CAROLINA: 'Caroline du Sud',
	STATE_SOUTH_DAKOTA: 'Dakota du Sud',
	STATE_TENNESSEE: 'Tennessee',
	STATE_TEXAS: 'Texas',
	STATE_VIRGINIA: 'Virginie',
	STATE_WEST_VIRGINIA: 'Virginie-Occidentale',
	STATE_WISCONSIN: 'Wisconsin',
	STATE_WYOMING: 'Wyoming',
	STATE_UNITED_STATES_MINOR_OUTLYING_I: 'Îles mineures éloignées des États-Unis',
	STATE_ARTIGAS: 'Artigas',
	STATE_CANELONES: 'Canelones',
	STATE_CERRO_LARGO: 'Cerro Largo',
	STATE_COLONIA: 'Colonia',
	STATE_DURAZNO: 'Durazno',
	STATE_FLORES: 'Flores',
	STATE_LAVALLEJA: 'Lavalleja',
	STATE_MALDONADO: 'Maldonado',
	STATE_MONTEVIDEO: 'Montevideo',
	STATE_PAYSANDU: 'Paysandu',
	STATE_RIVERA: 'Rivera',
	STATE_ROCHA: 'Rocha',
	STATE_SALTO: 'Salto',
	STATE_SORIANO: 'Soriano',
	STATE_TACUAREMBO: 'Tacuarembo',
	STATE_TREINTA_Y_TRES: 'Treinta y Tres',
	STATE_ANDIJON: 'Andijon',
	STATE_BUHORO: 'Buhoro',
	STATE_CIZAH: 'Cizah',
	STATE_FARGONA: 'Fargona',
	STATE_HORAZM: 'Horazm',
	STATE_KASKADAR: 'Kaskadar',
	STATE_KORAKALPOGISTON: 'Korakalpogiston',
	STATE_NAMANGAN: 'Namangan',
	STATE_NAVOI: 'Navoi',
	STATE_SAMARKAND: 'Samarkand',
	STATE_SIRDARE: 'Sirdare',
	STATE_SURHONDAR: 'Surhondar',
	STATE_TOSKENT: 'Toskent',
	STATE_MALAMPA: 'Malampa',
	STATE_PENAMA: 'Penama',
	STATE_SANMA: 'Sanma',
	STATE_SHEFA: 'Shefa',
	STATE_TAFEA: 'Tafea',
	STATE_TORBA: 'Torba',
	STATE_VATICAN_CITY_STATE_HOLY_SEE_: 'État de la Cité du Vatican (Saint-Siège)',
	STATE_ANZOATEGUI: 'Anzoategui',
	STATE_APURE: 'Apure',
	STATE_ARAGUA: 'Aragua',
	STATE_BARINAS: 'Barinas',
	STATE_CARABOBO: 'Carabobo',
	STATE_COJEDES: 'Cojedes',
	STATE_DELTA_AMACURO: 'Delta Amacuro',
	STATE_FALCON: 'Falcon',
	STATE_GUARICO: 'Guarico',
	STATE_LARA: 'Lara',
	STATE_MERIDA: 'Merida',
	STATE_MIRANDA: 'Miranda',
	STATE_MONAGAS: 'Monagas',
	STATE_NUEVA_ESPARTA: 'Nueva Esparta',
	STATE_PORTUGUESA: 'Portuguesa',
	STATE_TACHIRA: 'Tachira',
	STATE_TRUJILLO: 'Trujillo',
	STATE_VARGAS: 'Vargas',
	STATE_YARACUY: 'Yaracuy',
	STATE_ZULIA: 'Zulia',
	STATE_BAC_GIANG: 'Bac Giang',
	STATE_BINH_DINH: 'Binh Dinh',
	STATE_BINH_DUONG: 'Binh Duong',
	STATE_DA_NANG: 'Da Nang',
	STATE_DONG_BANG_SONG_CUU_LONG: 'Dong Bang Song Cuu Long',
	STATE_DONG_BANG_SONG_HONG: 'Dong Bang Song Hong',
	STATE_DONG_NAI: 'Dong Nai',
	STATE_DONG_NAM_BO: 'Dong Nam Bo',
	STATE_DUYEN_HAI_MIEN_TRUNG: 'Duyen Hai Mien Trung',
	STATE_HANOI: 'Hanoï',
	STATE_HUNG_YEN: 'Hung Yen',
	STATE_KHU_BON_CU: 'Khu Bon Cu',
	STATE_LONG_AN: 'Long An',
	STATE_MIEN_NUI_VA_TRUNG_DU: 'Mien Nui Va Trung Du',
	STATE_THAI_NGUYEN: 'Thai Nguyen',
	STATE_THANH_PHO_HO_CHI_MINH: 'Thanh Pho Ho Chi Minh',
	STATE_THU_DO_HA_NOI: 'Thu Do Ha Noi',
	STATE_TINH_CAN_THO: 'Tinh Can Tho',
	STATE_TINH_DA_NANG: 'Tinh Da Nang',
	STATE_TINH_GIA_LAI: 'Tinh Gia Lai',
	STATE_ANEGADA: 'Anegada',
	STATE_JOST_VAN_DYKE: 'Jost van Dyke',
	STATE_TORTOLA: 'Tortola',
	STATE_SAINT_CROIX: 'Sainte-Croix',
	STATE_ALO: 'Alo',
	STATE_SINGAVE: 'Singave',
	STATE_WALLIS: 'Wallis',
	STATE_BU_JAYDUR: 'Bu Jaydur',
	STATE_WAD_ADH_DHAHAB: 'Wad-adh-Dhahab',
	STATE_AL___AYUN: 'Laâyoune',
	STATE_AS_SAMARAH: 'As Samarah',
	STATE_ADAN: 'Adan',
	STATE_ABYAN: 'Abyan',
	STATE_DHAMAR: 'Dhamar',
	STATE_HADRAMAUT: 'Hadramaut',
	STATE_HAJJAH: 'Hajjah',
	STATE_HUDAYDAH: 'Hudaydah',
	STATE_IBB: 'Ibb',
	STATE_LAHIJ: 'Lahij',
	STATE_MA__RIB: 'Marib',
	STATE_MADINAT_SAN__A: 'Madinat San’a',
	STATE_SA__DAH: 'Sa’dah',
	STATE_SANA: 'Sana',
	STATE_SHABWAH: 'Shabwah',
	STATE_TA__IZZ: 'Ta’izz',
	STATE_AL_BAYDA: 'Al Bayda’',
	STATE_AL_HUDAYDAH: 'Al Hudaydah',
	STATE_AL_JAWF: 'Al Jawf',
	STATE_AL_MAHRAH: 'Al Mahrah',
	STATE_AL_MAHWIT: 'Al Mahwit',
	STATE_COPPERBELT: 'Copperbelt',
	STATE_LUAPALA: 'Luapala',
	STATE_LUSAKA: 'Lusaka',
	STATE_NORTH_WESTERN: 'Nord-ouest',
	STATE_BULAWAYO: 'Bulawayo',
	STATE_HARARE: 'Harare',
	STATE_MANICALAND: 'Manicaland',
	STATE_MASHONALAND_CENTRAL: 'Mashonaland central',
	STATE_MASHONALAND_EAST: 'Mashonaland oriental',
	STATE_MASHONALAND_WEST: 'Mashonaland occidental',
	STATE_MASVINGO: 'Masvingo',
	STATE_MATABELELAND_NORTH: 'Matabeleland septentrional',
	STATE_MATABELELAND_SOUTH: 'Matabeleland méridional',
	STATE_MIDLANDS: 'Midlands',
	STATE_AARHUS: 'Aarhus'
};
